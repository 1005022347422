import { I18n } from "@meraki/core/i18n";
import { Input, List, Toggle } from "@meraki/magnetic/components";
import { useNavigation } from "@react-navigation/native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { StagedSwitchPort } from "~/api/schemas/SwitchPort";
import { ObjectUpdater } from "~/shared/hooks/useObjectUpdater";
import { SharedScreensPropMap } from "~/shared/navigation/Types";

interface SwitchPortEditScreenProps {
  linkNegotiationCapabilities: string[];
  updateByKey: ObjectUpdater<Partial<StagedSwitchPort>>;
  port: StagedSwitchPort;
}

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "SwitchPortEditWrapper">;

export const BasicSwitchportEdit = ({
  linkNegotiationCapabilities,
  updateByKey,
  port,
}: SwitchPortEditScreenProps) => {
  const navigation = useNavigation<Props["navigation"]>();

  const { linkNegotiation, name, enabled, poeEnabled } = port;

  const selectLinkSpeed = () => {
    navigation.navigate("Selection", {
      title: I18n.t("LINK_NEGOTIATION_SELECTION.TITLE"),
      description: I18n.t("LINK_NEGOTIATION_SELECTION.DESCRIPTION"),
      initialValue: linkNegotiation,
      onSelect: updateByKey("linkNegotiation"),
      options: linkNegotiationCapabilities.map((option) => ({ label: option, value: option })),
    });
  };

  return (
    <List label={I18n.t("SWITCH_PORT_EDIT.BASIC")}>
      <List.Item title="">
        <Input
          onChangeText={updateByKey("name")}
          placeholder={I18n.t("SWITCH_PORT_EDIT.NAME.PLACEHOLDER")}
          label={I18n.t("SWITCH_PORT_EDIT.NAME.TITLE")}
          value={name ?? undefined}
        />
      </List.Item>
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.ENABLED")}
        rightAccessory={
          <Toggle
            checked={enabled}
            onValueChange={updateByKey("enabled")}
            testID={"SWITCH_PORT_EDIT.ENABLED"}
          />
        }
      />
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.POE")}
        rightAccessory={
          <Toggle
            checked={poeEnabled}
            onValueChange={updateByKey("poeEnabled")}
            testID={"SWITCH_PORT_EDIT.POE"}
          />
        }
      />
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.LINK_NEGOTIATION.TITLE")}
        description={linkNegotiation}
        onPress={selectLinkSpeed}
      />
    </List>
  );
};
