import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { Heading, TabView, TabViewMethods } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { SupportCase, useOrgNetworks, useSupportCases } from "@meraki/shared/api";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useRef } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { SupportCasesListTab } from "../components/SupportCasesListTab";
import { SupportCasesListContext } from "./SupportCasesListContext";

interface FavoriteSupportCasesStoreState {
  favoriteCaseIds: string[];
  setFavoriteCaseIds: (favoriteCaseIds: string[]) => void;
}

export const useFavoriteSupportCasesStore = create<FavoriteSupportCasesStoreState>()(
  persist(
    (set) => ({
      favoriteCaseIds: [] as string[],
      setFavoriteCaseIds: (favoriteCaseIds) =>
        set((_) => ({
          favoriteCaseIds,
        })),
    }),
    {
      name: "favoriteSupportCasesStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

const CASE_TABS = {
  open: {
    title: I18n.t("SUPPORT_CENTER.GOv3.TAB_TITLES.OPEN"),
    view: <SupportCasesListTab status="open" />,
    testID: "OPEN_CASES_TAB",
  },
  closed: {
    title: I18n.t("SUPPORT_CENTER.GOv3.TAB_TITLES.CLOSED"),
    view: <SupportCasesListTab status="closed" />,
    testID: "CLOSED_CASES_TAB",
  },
};

interface OpenClosedCases {
  openCases: SupportCase[];
  closedCases: SupportCase[];
}

export function SupportCasesListScreen() {
  const tabViewRef = useRef<TabViewMethods>(null);
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const userEmail = useCurrentUserEmail();

  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId) ?? data[0];
      },
    },
  );
  const { data: allSupportCases } = useSupportCases(
    { encryptedNetworkId: network?.eid, userEmail },
    {
      select(data) {
        return Object.values(data).filter((supportCase) => {
          return (
            supportCase.suppliedEmail === userEmail &&
            supportCase.organization.id === organizationId
          );
        });
      },
    },
  );

  const handleGoToTab = (key: string) => {
    tabViewRef.current?.goToTab(key);
  };

  const { openCases, closedCases } =
    allSupportCases?.reduce(
      (prev, supportCase): OpenClosedCases => {
        if (supportCase.status === "Closed") {
          return { ...prev, closedCases: [...prev.closedCases, supportCase] };
        } else {
          return { ...prev, openCases: [...prev.openCases, supportCase] };
        }
      },
      { openCases: [], closedCases: [] } as OpenClosedCases,
    ) ?? {};
  return (
    <SupportCasesListContext.Provider
      value={{
        openCases,
        closedCases,
        goToTab: handleGoToTab,
      }}
    >
      <Screen.View>
        <Box paddingVertical="none" padding="sm">
          <Heading size="h1">{I18n.t("SUPPORT_CENTER.GOv3.TITLE")}</Heading>
        </Box>
        <TabView tabs={CASE_TABS} ref={tabViewRef} />
      </Screen.View>
    </SupportCasesListContext.Provider>
  );
}
