import { walledGardenDocumentationURL } from "@meraki/go/links";
import { isEmpty } from "lodash";
import { memo, PureComponent } from "react";
import { ScrollView, SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { WalledGardenRange } from "~/go/types/SplashTypes";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { addWalledGardenRange, editWalledGardenRange } from "~/lib/SplashUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import InputModal from "~/shared/components/InputModal";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { StatusType } from "~/shared/constants/Status";
import { CloseButton, DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

import { NetworkScreensPropMap } from "../navigation/Types";

interface WalledGardenRowProps {
  range: WalledGardenRange;
  deleteIcon: React.ReactNode;
  onPress: () => void;
}

const WalledGardenRow = memo<WalledGardenRowProps>(function WalledGardenRow(props) {
  const { range, deleteIcon, onPress } = props;

  return (
    <ListRow
      value={
        range.isValid
          ? ""
          : I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.WALLED_GARDEN_INVALID_ENTRY")
      }
      icon={deleteIcon}
      onPress={onPress}
      rowStyles={styles.rowStyles}
      leftStyle={range.isValid ? styles.leftStylesFull : styles.leftStylesPartial}
      rightStyle={range.isValid ? {} : styles.rightStyles}
      rightTextStyle={styles.rightTextStyle}
      labelStyle={range.isValid ? {} : styles.invalidRow}
    >
      {range.range}
    </ListRow>
  );
});

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SplashWalledGarden">;

export interface SplashWalledGardenState {
  stagedRanges: WalledGardenRange[];

  showAddModal: boolean;
  addModalCurrentRanges: string;

  // values used for the edit/add modal
  showEditModal: boolean;
  editModalCurrentRange: string;
  editModalCurrentIndex?: number;
  editModalAlert?: { message: string; status: StatusType };
  isEditMode: boolean;
}

export class SplashWalledGardenScreenComponent extends PureComponent<
  Props,
  SplashWalledGardenState
> {
  private blurListenerUnsubscribe: () => void;

  static defaultProps = {
    currentRanges: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      // defaultProps sets this to []
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stagedRanges: props.currentRanges!,

      showAddModal: false,
      addModalCurrentRanges: "",

      showEditModal: false,
      editModalCurrentRange: "",
      editModalCurrentIndex: undefined,
      editModalAlert: undefined,
      isEditMode: false,
    };

    this.setNavOptions();
    const { navigation } = props;
    this.blurListenerUnsubscribe = navigation.addListener("blur", this.componentWillUnmount);
  }

  componentWillUnmount() {
    const { updateWalledGardenRanges } = this.props;
    const { stagedRanges } = this.state;

    let ranges = stagedRanges;
    if (this.hasInvalidRanges(stagedRanges)) {
      ranges = stagedRanges.filter((range) => range.isValid);
    }
    updateWalledGardenRanges(ranges);
    this.blurListenerUnsubscribe();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { isEditMode, stagedRanges } = this.state;

    navigation.setOptions({
      headerLeft: () => (
        <CloseButton
          onPress={() => {
            if (this.hasInvalidRanges(stagedRanges)) {
              this.onCancelWithInvalidEntries();
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => this.setState({ isEditMode: true })} />;
        } else {
          return <DoneButton onPress={() => this.setState({ isEditMode: false })} />;
        }
      },
    });
  }

  onCancelWithInvalidEntries = () => {
    const { navigation, updateWalledGardenRanges } = this.props;
    const { stagedRanges } = this.state;

    showAlert(
      I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.INVALID_WARNING_TITLE"),
      I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.INVALID_WARNING_SUBTITLE"),
      () => {
        const filteredRanges = stagedRanges.filter((range) => range.isValid);
        updateWalledGardenRanges(filteredRanges);
        navigation.goBack();
      },
      {
        positiveText: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.INVALID_WARNING_POSITIVE_TEXT"),
        negativeText: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.INVALID_WARNING_NEGATIVE_TEXT"),
        onNegativePress: () => {
          /* do nothing */
        },
      },
    );
  };

  hasInvalidRanges = (ranges: WalledGardenRange[]) =>
    ranges.filter((range) => !range.isValid).length > 0;

  showAddModal = () => {
    this.setState({
      addModalCurrentRanges: "",
      showAddModal: true,
    });
  };

  editRule = (index: number) => {
    const { stagedRanges } = this.state;

    this.setState({
      editModalCurrentIndex: index,
      editModalAlert: undefined,
    });

    const currentRange = index !== undefined ? stagedRanges[index].range : "";
    this.setState({
      showEditModal: true,
      editModalCurrentRange: currentRange,
    });
  };

  renderHeader = () => (
    <DefaultHeader
      hasHelplink
      onPress={walledGardenDocumentationURL}
      title={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_TITLE")}
      description={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_DESCRIPTION")}
    />
  );

  renderSectionHeader = () => {
    const { ssidName } = this.props;

    return (
      <SectionListHeader
        heading={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_SECTION_HEADER", {
          ssidName,
        })}
        onPress={this.showAddModal}
        withHorizontalMargin
      />
    );
  };

  renderSectionFooter = ({ section }: { section: SectionListData<WalledGardenRange> }) => {
    if (isEmpty(section.data)) {
      return (
        <View style={styles.rowStyles}>
          <MkiText screenStyles={styles.noSettingsStyle}>
            {I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_SECTION_FOOTER")}
          </MkiText>
        </View>
      );
    }
    return null;
  };

  deleteRange = (deletedIndex: number) => {
    const { stagedRanges } = this.state;

    const updatedRanges = stagedRanges.filter((_, index: number) => index !== deletedIndex);
    this.setState({ stagedRanges: updatedRanges });
  };

  renderRow = (range: WalledGardenRange, index: number) => {
    const { isEditMode } = this.state;
    const onPress = () => this.deleteRange(index);

    return (
      <WalledGardenRow
        range={range}
        onPress={() => this.editRule(index)}
        deleteIcon={<DeleteButton show={isEditMode} onPress={onPress} />}
      />
    );
  };

  render() {
    const {
      stagedRanges,
      showAddModal,
      addModalCurrentRanges,
      showEditModal,
      editModalCurrentRange,
      editModalAlert,
    } = this.state;

    const data = {
      0: stagedRanges,
    };
    return (
      <FullScreenContainerView>
        <InputModal
          value={addModalCurrentRanges}
          placeholder={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_PLACEHOLDER")}
          title={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_ADD_TITLE")}
          subtitle={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_ADD_SUBTITLE")}
          primaryButtonText={I18n.t(
            "SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_ADD_BUTTON",
          )}
          onChangeText={(newValue: string) => this.setState({ addModalCurrentRanges: newValue })}
          onExit={() => this.setState({ addModalCurrentRanges: "", showAddModal: false })}
          onPrimaryPress={() => this.setState(addWalledGardenRange)}
          autoCapitalize="none"
          visible={showAddModal}
          multiline
        />
        <InputModal
          value={editModalCurrentRange}
          title={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_EDIT_TITLE")}
          visible={showEditModal}
          onChangeText={(newValue: string) => this.setState({ editModalCurrentRange: newValue })}
          primaryButtonText={I18n.t(
            "SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_EDIT_BUTTON",
          )}
          onPrimaryPress={() => this.setState(editWalledGardenRange)}
          onSubmitEditing={() => this.setState(editWalledGardenRange)}
          onExit={() => this.setState({ editModalCurrentRange: "", showEditModal: false })}
          autoCapitalize="none"
          placeholder={I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.SCREEN_MODAL_PLACEHOLDER")}
          alert={editModalAlert}
        />
        <ScrollView keyboardShouldPersistTaps="handled">
          <MkiTable<WalledGardenRange>
            data={data}
            ListHeaderComponent={this.renderHeader}
            renderSectionHeader={this.renderSectionHeader}
            renderSectionFooter={this.renderSectionFooter}
            renderRow={this.renderRow}
            keyExtractor={(item, index) => `${item.range},${index}`}
            hasSeparators={false}
          />
        </ScrollView>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  invalidRow: {
    color: MkiColors.errorTextColor,
  },
  noSettingsStyle: {
    color: MkiColors.secondaryTextColor,
  },
  leftStylesFull: {
    width: "100%",
  },
  leftStylesPartial: {
    width: "60%",
  },
  rightStyles: {
    width: "40%",
    alignItems: "flex-start",
  },
  rightTextStyle: {
    color: MkiColors.invalidWalledGardenEntry,
  },
});

export default SplashWalledGardenScreenComponent;
