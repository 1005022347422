import z from "zod";

export const SupportCaseInputSchema = z
  .object({
    subject: z.string(),
    description: z.string(),
    related_devices: z.string(),
    // enterprise params
    captcha_shown: z.boolean().optional(),
    mki_captcha_provider_response: z.string().optional(),
    orgid: z.string().optional(),
    // go params
    email_override: z.string().optional(),
    Customer_Assigned_Priority__c: z.string().optional(),
    callback_number: z.string().optional(),
  })
  .describe("SupportCaseInputSchema");

export const SupportCaseResponseSchema = z
  .object({
    success: z.boolean(),
    need_captcha: z.boolean(),
    invalid_captcha: z.boolean(),
  })
  .describe("CaseResponseSchema");

export const SupportCaseSchema = z
  .object({
    caseNumber: z.string(),
    caseThreadId: z.string(),
    contactEmail: z.string(),
    contactName: z.string(),
    createdDate: z.string(),
    customerAssignedPriority: z.string().nullable(),
    description: z.string().nullable(),
    id: z.string(),
    lastModifiedDate: z.string(),
    organization: z.object({
      id: z.string(),
      name: z.string(),
      shard_id: z.string(),
      url: z.string(),
    }),
    organizationNumber: z.string(),
    ownerName: z.string(),
    status: z.string(),
    statusDetails: z.string().nullable(),
    subject: z.string(),
    suppliedEmail: z.string().nullable(),
    suppliedName: z.string().nullable(),
  })
  .describe("SupportCaseSchema");

export const CloseCaseResponseSchema = z
  .object({
    success: z.boolean(),
  })
  .describe("CloseCaseResponseSchema");

export const SupportCaseListSchema = z.array(SupportCaseSchema).describe("SupportCaseListSchema");

export type CaseResponse = z.infer<typeof SupportCaseResponseSchema>;

export type SupportCase = z.infer<typeof SupportCaseSchema>;

export type SupportCaseInput = z.infer<typeof SupportCaseInputSchema>;
