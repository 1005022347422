export enum Modes {
  none = "none",
  spoke = "spoke",
  hub = "hub",
}

export interface HubSettings {
  hubId: string;
  useDefaultRoute: boolean;
}

export interface SubnetSettings {
  localSubnet: string;
  useVpn: boolean;
}

export interface SiteToSiteSettings {
  mode: Modes;
  hubs?: HubSettings[];
  subnets?: SubnetSettings[];
}
