import { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import LiveBackground from "~/go/components/LiveBackground";
import { themeColors } from "~/lib/themeHelper";
import { CustomGradientProps, IconSizeTypes } from "~/shared/components/icons";
import { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";
import GeneralStatus, { StatusType } from "~/shared/constants/Status";
import { useTheme } from "~/shared/hooks/useTheme";

export interface StatusIconProps {
  status?: StatusType;
  customIcon?: CustomIcon;
  screenStyles?: StyleProp<ViewStyle>;
  isHollow?: boolean;
  timestamp?: number;
  testID?: string;
}

interface CustomIcon {
  icon: (props: MerakiIconProps) => React.ReactElement | null;
  name: string;
  size: IconSizeTypes;
  hasGradient: boolean;
  customGradient?: CustomGradientProps;
  color?: string;
}

const StatusIcon = ({
  customIcon,
  status,
  screenStyles,
  isHollow,
  timestamp,
  testID,
}: StatusIconProps) => {
  const theme = useTheme();
  const colors = themeColors(theme).status;
  const statusColor =
    status && colors[status] ? colors[status].color : colors[GeneralStatus.dormant].color;

  if (customIcon) {
    return (
      <customIcon.icon
        name={customIcon.name}
        size={customIcon.size}
        color={customIcon.color || statusColor}
        style={screenStyles}
        hasGradient={customIcon.hasGradient}
        customGradient={customIcon.customGradient}
        testID={testID}
      />
    );
  }

  const statusIcon = (
    <View
      testID={testID}
      style={[
        styles.statusBlob,
        isHollow ? [styles.hollow, { borderColor: statusColor }] : { backgroundColor: statusColor },
        screenStyles,
      ]}
    />
  );

  return timestamp ? (
    <LiveBackground timestamp={timestamp}>{statusIcon}</LiveBackground>
  ) : (
    statusIcon
  );
};

const STATUS_DIAM = SPACING.medium;
const styles = StyleSheet.create({
  statusBlob: {
    width: STATUS_DIAM,
    height: STATUS_DIAM,
    borderRadius: STATUS_DIAM / 2,
  },
  hollow: {
    borderWidth: SPACING.tiny,
  },
});

export default memo(StatusIcon);
