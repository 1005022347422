export interface InventoryDevice {
  id: number;
  mac: string;
  serial: string;
  networkId: string;
  model: string;
  claimedAt: number;
  publicIp: string;
  orderNumber: string;
  name: string;
}

export enum UsedState {
  unused = "unused",
}
