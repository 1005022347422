import { CounterSetRule } from "@meraki/shared/api";
import {
  BAR_GRAPH_COLOR_PROPS,
  Options,
  processRusage as libProcessRusage,
  RUsageApplicationData,
} from "@meraki/shared/formatters";
import { reduce } from "lodash";

import { pieColors } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { UsageRowProps } from "~/shared/rows/UsageRow.go";
import { ApplicationTypes } from "~/shared/types/ApplicationTypes";
import { ClientApplicationUsage } from "~/shared/types/ApplicationUsages";

export const processClientRusage = (applicationUsages: ClientApplicationUsage, set: any) => {
  let totalUsage = 0;

  // 1. Get an array of usages: [{name: "google", usage: 14560}, ... ]
  // 2. Add percentage of total usage to each entry, and ignore any existing colors
  // 3. Sort by descending usage
  const uncoloredEntries = reduce(
    applicationUsages,
    (result: any, usageInfo: any, application: any) => {
      const ruleId = set.ruleIdsByName[application];
      const usage = usageInfo.received + usageInfo.sent;
      totalUsage += usage;

      result.push({
        children: set.rules[ruleId]?.name || "-",
        color: set.rules[ruleId]?.color,
        usage,
      });

      return result;
    },
    [],
  )
    .map((e: any) => Object.assign({}, e, { percent: e.usage / totalUsage, color: undefined }))
    .sort((a: any, b: any) => b.usage - a.usage);
  // 4. Assign colors and return
  return assignColors(uncoloredEntries);
};

export const processRusage = (
  rusage: { [_: string]: number[] },
  rules: { [_: string]: CounterSetRule },
  options: Options = {},
) => {
  const processedRusage = libProcessRusage(rusage, rules, options);
  processedRusage.forEach((usageData) => {
    switch (usageData.label) {
      case ApplicationTypes.miscSecWeb:
        if (__MERAKI_GO__) {
          usageData.label = I18n.t("APPLICATION_NAME_SWAPS.SEC_WEB");
        }
        break;
      case ApplicationTypes.miscWeb:
        if (__MERAKI_GO__) {
          usageData.label = I18n.t("APPLICATION_NAME_SWAPS.MISC_WEB");
        }
        break;
      case ApplicationTypes.other:
        usageData.label = I18n.t("APPLICATION_USAGE.OTHER");
        break;
    }
  });

  return assignColors(processedRusage);
};

export const assignColors = (usages: RUsageApplicationData[] | UsageRowProps[]) => {
  // go through and assign colors to non-explicitly-assigned groups
  // taken from Dashboard: private/javascripts/graph_utils#_assign_slice_colors
  let color;
  let colorIndex = 0;
  const numColors = pieColors.length;

  for (let i = 0; i < usages.length; i += 1) {
    if (!usages[i].color) {
      color = pieColors[colorIndex % numColors];
      for (let j = i - 3; j < i + 4; j += 1) {
        if (j >= 0 && j !== i && usages[j] && usages[j].color === color) {
          colorIndex += 1;
          color = pieColors[colorIndex % numColors];
          j = i - 4;
        }
      }
      usages[i].color = color as (typeof BAR_GRAPH_COLOR_PROPS)[number];
      colorIndex += 1;
    } else if (usages[i].color === pieColors[colorIndex % numColors]) {
      colorIndex += 1;
    } else {
      usages[i].color = usages[i].color;
    }
  }

  return usages;
};

export const getTotalUsage = (usage: any) => usage.sent + usage.recv;
