import { I18n } from "@meraki/core/i18n";
import { ExportTemplateRequest, useExportTemplate } from "@meraki/shared/api";
import { getPermission } from "@meraki/shared/permissions";
import { StackActions, useNavigation } from "@react-navigation/native";
import { StyleSheet } from "react-native";
import { PERMISSIONS } from "react-native-permissions";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { saveExportedTemplate } from "~/lib/ExportTemplateUtils";
import { isAndroid } from "~/lib/PlatformUtils";

interface ExportTemplateButtonProps extends ExportTemplateRequest {
  disabled: boolean;
  filename: string;
  combinedNetworksList: string;
}

const ExportTemplateButton = (props: ExportTemplateButtonProps) => {
  const {
    disabled,
    filename,
    combinedNetworksList,
    networkId,
    type,
    cleanNames,
    ssidId,
    vlanId,
    keepDhcp,
  } = props;
  const variables = {
    networkId,
    type,
    cleanNames,
    ssidId,
    vlanId,
    keepDhcp,
  };

  const exportTemplate = useExportTemplate(variables);
  const navigation = useNavigation();

  const exportNetwork = async () => {
    try {
      if (isAndroid()) {
        await getPermission(PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE).catch((err) =>
          console.log(err),
        );
      }

      const templateString = JSON.stringify(exportTemplate.data);
      await saveExportedTemplate(filename, templateString).then(() =>
        showAlert(
          I18n.t("SUCCESS"),
          I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.SUCCESS_MESSAGE", { combinedNetworksList }),
          () => navigation.dispatch(StackActions.popToTop()),
        ),
      );
    } catch (e) {
      showAlert(I18n.t("ERROR"), I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.FAIL_MESSAGE"), () =>
        navigation.dispatch(StackActions.popToTop()),
      );
    }
  };

  return (
    <RoundedButton
      buttonType={ButtonType.primary}
      onPress={exportNetwork}
      containerStyles={styles.button}
      testID="EXPORT_TEMPLATE_BUTTON"
      disabled={disabled || exportTemplate.isLoading || filename === ""}
    >
      {I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.BUTTON")}
    </RoundedButton>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: SPACING.extraExtraLarge,
  },
});

export default ExportTemplateButton;
