import { I18n } from "@meraki/core/i18n";
import {
  ANY,
  L3FirewallRule,
  Policy,
  Protocol,
  useL3FirewallRules,
  useUpdateL3FirewallRules,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import RadioSelectionRow from "~/go/rows/RadioSelectionRow";
import { showAlert } from "~/lib/AlertUtils";
import { removeElementAtIndex } from "~/lib/arrayHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import { MkiText } from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";

import { HardwareStackPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "L3FirewallRule">;

interface CidrConfigurationProps {
  cidr: string;
  setCidr: (newCidr: string) => void;
  showPort: boolean;
  port: string;
  setPort: (newPort: string) => void;
}

const CidrConfiguration = ({ cidr, setCidr, showPort, port, setPort }: CidrConfigurationProps) => {
  return (
    <View style={styles.cidrConfiguration}>
      <View style={styles.cidrContainer}>
        <MkiText textStyle="secondary">{I18n.t("L3_FIREWALL_RULE.CIDR.TITLE")}</MkiText>
        <IPAddressTextInput
          cidrAddress={cidr}
          editable
          onAddressChange={setCidr}
          includeSubnetMask={true}
          leaveEditableBlank={false}
        />
        <MkiText textStyle={"smallSecondary"}>
          {I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_CIDR")}
        </MkiText>
      </View>
      {showPort && (
        <View style={styles.portContainer}>
          <MkiText textStyle="secondary">{I18n.t("L3_FIREWALL_RULE.CIDR.PORT.TITLE")}</MkiText>
          <MkiTextInput
            value={port === ANY ? "" : port}
            placeholder={I18n.t("L3_FIREWALL_RULE.CIDR.PORT.PLACEHOLDER")}
            onChangeText={setPort}
            testID="CIDR.PORT_INPUT"
          />
          <MkiText textStyle={"smallSecondary"}>
            {I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_PORT")}
          </MkiText>
        </View>
      )}
    </View>
  );
};

const L3FirewallRuleScreen = (props: Props) => {
  const { rules, idx } = props;

  const queryClient = useQueryClient();

  const navigation = useNavigation<Props["navigation"]>();
  const networkId = useCurrentNetworkId();
  const updateFirewallRules = useUpdateL3FirewallRules();
  const firewallRule = idx != null ? rules[idx] : undefined;
  const [otherRules, _] = useState(removeElementAtIndex<L3FirewallRule>(rules, idx));
  const [stagedComment, setStagedComment] = useState(firewallRule?.comment ?? "");
  const [commentError, setCommentError] = useState("");
  const [stagedDestCidr, setStagedDestCidr] = useState(
    firewallRule?.destCidr && firewallRule?.destCidr.toLowerCase() !== ANY
      ? firewallRule?.destCidr
      : undefined,
  );
  const [stagedSrcCidr, setStagedSrcCidr] = useState(
    firewallRule?.srcCidr && firewallRule?.srcCidr.toLowerCase() !== ANY
      ? firewallRule?.srcCidr
      : undefined,
  );
  const [stagedDestPort, setStagedDestPort] = useState(
    firewallRule?.destPort && firewallRule?.destPort.toLowerCase() !== ANY
      ? firewallRule?.destPort
      : undefined,
  );
  const [stagedSrcPort, setStagedSrcPort] = useState(
    firewallRule?.srcPort && firewallRule?.srcPort.toLowerCase() !== ANY
      ? firewallRule?.srcPort
      : undefined,
  );
  const [stagedPolicy, setStagedPolicy] = useState(firewallRule?.policy ?? "allow");
  const [stagedProtocol, setStagedProtocol] = useState(firewallRule?.protocol ?? "Any");

  const ruleEntries = {
    Source: [
      {
        cidr: stagedSrcCidr,
        port: stagedSrcPort,
        setCidr: setStagedSrcCidr,
        setPort: setStagedSrcPort,
      },
    ],
    Destination: [
      {
        cidr: stagedDestCidr,
        port: stagedDestPort,
        setCidr: setStagedDestCidr,
        setPort: setStagedDestPort,
      },
    ],
  };

  const onSave = useCallback(() => {
    const convertEmptyStringToAny = (str: any) => (str === "" || str === undefined ? ANY : str);

    if (!stagedComment) {
      setCommentError(I18n.t("L3_FIREWALL_RULE.COMMENT.ERROR_EMPTY"));
      return;
    }

    const rule = {
      comment: stagedComment,
      srcCidr: convertEmptyStringToAny(stagedSrcCidr),
      srcPort: convertEmptyStringToAny(stagedSrcPort),
      destCidr: convertEmptyStringToAny(stagedDestCidr),
      destPort: convertEmptyStringToAny(stagedDestPort),
      policy: stagedPolicy,
      protocol: stagedProtocol,
      syslogEnabled: false,
    };

    updateFirewallRules.mutate(
      {
        networkId,
        params: { rules: [...otherRules, rule] },
      },
      {
        onSuccess: () => navigation.goBack(),
        onError: (error) => {
          showAlert(I18n.t("ERROR"), error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT"));
        },
        onSettled: () => {
          queryClient.refetchQueries({ queryKey: useL3FirewallRules.queryKeyRoot });
        },
      },
    );
  }, [
    navigation,
    networkId,
    otherRules,
    queryClient,
    stagedComment,
    stagedDestCidr,
    stagedDestPort,
    stagedPolicy,
    stagedProtocol,
    stagedSrcCidr,
    stagedSrcPort,
    updateFirewallRules,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} />,
    });
  }, [navigation, onSave]);

  return (
    <FullScreenContainerView withScroll screenStyles={styles.container}>
      <View style={styles.titleContainer}>
        <MkiText textStyle={"secondary"}>{I18n.t("L3_FIREWALL_RULE.COMMENT.TITLE")}</MkiText>
        <MkiTextInput
          value={stagedComment}
          onChangeText={(newValue: string) => {
            setStagedComment(newValue);

            if (stagedComment && commentError) {
              setCommentError("");
            }
          }}
          placeholder={I18n.t("L3_FIREWALL_RULE.COMMENT.PLACEHOLDER")}
          testID="COMMENT_INPUT"
        />
        <MkiText textStyle="error">{commentError}</MkiText>
      </View>
      <MkiText textStyle={"subheading"} screenStyles={styles.titleStyle}>
        {I18n.t("L3_FIREWALL_RULE.POLICY.TITLE")}
      </MkiText>
      <RadioSelectionRow
        screenStyles={styles.radioSelection}
        options={[
          { label: I18n.t("L3_FIREWALL_RULE.POLICY.ALLOW"), value: Policy.allow },
          { label: I18n.t("L3_FIREWALL_RULE.POLICY.DENY"), value: Policy.deny },
        ]}
        onSelect={(policy: any) => setStagedPolicy(policy)}
        formHorizontal
        radioStyle={styles.radioContainer}
        selectedValue={stagedPolicy}
        testID="POLICY"
      />
      <MkiText textStyle={"subheading"} screenStyles={styles.titleStyle}>
        {I18n.t("L3_FIREWALL_RULE.PROTOCOL.TITLE")}
      </MkiText>
      <RadioSelectionRow
        screenStyles={styles.radioSelection}
        options={[
          { label: I18n.t("L3_FIREWALL_RULE.PROTOCOL.TCP"), value: Protocol.tcp },
          { label: I18n.t("L3_FIREWALL_RULE.PROTOCOL.UDP"), value: Protocol.udp },
          { label: I18n.t("L3_FIREWALL_RULE.PROTOCOL.ANY"), value: Protocol.any },
        ]}
        formHorizontal
        onSelect={(protocol: any) => setStagedProtocol(protocol)}
        selectedValue={stagedProtocol}
        radioStyle={styles.radioContainer}
        testID="PROTOCOL"
      />
      <MkiTable
        data={ruleEntries}
        renderSectionHeader={({ section }: any) => (
          <MkiText textStyle={"subheading"} screenStyles={styles.titleStyle}>
            {section.key}
          </MkiText>
        )}
        renderItem={({ item }: any) => (
          <CidrConfiguration
            cidr={item.cidr}
            setCidr={item.setCidr}
            showPort={stagedProtocol !== "Any"}
            port={item.port}
            setPort={item.setPort}
          />
        )}
        testID="CIDR.TABLE"
      />
      <LoadingSpinner visible={updateFirewallRules.isLoading} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: SPACING.default,
  },
  titleStyle: {
    color: MkiColors.primaryButton,
  },
  titleContainer: {
    flex: 1,
    paddingVertical: SPACING.default,
  },
  radioContainer: {
    flex: 0,
    margin: SPACING.default,
  },
  radioSelection: {
    flex: 1,
    marginTop: 0,
  },
  cidrConfiguration: {
    flex: 1,
    paddingVertical: SPACING.default,
    justifyContent: "space-around",
  },
  cidrContainer: {
    paddingVertical: SPACING.small,
  },
  portContainer: {
    paddingVertical: SPACING.small,
  },
});

export default L3FirewallRuleScreen;
