import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function NegativeBordered({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} testID={testID}>
      <Path
        clipRule="evenodd"
        d="M14.2674 0.757282L20.7674 4.54895C22.1499 5.3554 23 6.83546 23 8.43595V15.5641C23 17.1646 22.1499 18.6447 20.7674 19.4511L14.2674 23.2428C12.8663 24.0601 11.1337 24.0601 9.73258 23.2428L3.23258 19.4511C1.8501 18.6447 1 17.1646 1 15.5641V8.43595C1 6.83545 1.8501 5.3554 3.23258 4.54895L9.73258 0.757282C11.1337 -0.0600454 12.8663 -0.0600437 14.2674 0.757282Z"
        fill={theme.color.negative.border.weak.base}
        fillRule="evenodd"
      />
      <Path
        d="M10.7403 2.48484C11.5187 2.03077 12.4813 2.03077 13.2597 2.48484L19.7597 6.27651C20.5277 6.72453 21 7.54679 21 8.43595V15.5641C21 16.4533 20.5277 17.2755 19.7597 17.7235L13.2597 21.5152C12.4813 21.9693 11.5187 21.9693 10.7403 21.5152L4.24032 17.7235C3.47228 17.2755 3 16.4533 3 15.5641V8.43595C3 7.54679 3.47228 6.72453 4.24032 6.27651L10.7403 2.48484Z"
        fill={theme.color.negative.icon.base}
      />
      <Path
        clipRule="evenodd"
        d="M11 12.5L11 8.25C11 7.69772 11.4477 7.25 12 7.25C12.5523 7.25 13 7.69772 13 8.25L13 12.5C13 13.0523 12.5523 13.5 12 13.5C11.4477 13.5 11 13.0523 11 12.5Z"
        fill={theme.color.negative.iconIn.base}
        fillRule="evenodd"
      />
      <Circle cx="12" cy="16" fill={theme.color.negative.iconIn.base} r="1.25" />
    </Svg>
  );
}
