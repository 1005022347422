import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Warning({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
        fill={theme.color.warning.icon.base}
      />
      <Path
        d="M7.2 4.8a.8.8 0 1 1 1.6 0v3.4a.8.8 0 0 1-1.6 0V4.8Z"
        fill={theme.color.warning.iconIn.base}
      />
      <Rect fill={theme.color.warning.iconIn.base} height="2" rx="1" width="2" x="7" y="10" />
    </Svg>
  );
}
