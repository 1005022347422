import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import Touchable from "~/shared/components/Touchable";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface ClearInputButtonProps {
  onClear: () => void;
  clearTestID?: string;
  buttonStyles?: StyleProp<ViewStyle>;
  clearButtonColor?: string;
}

const ClearInputButton = ({
  onClear,
  clearTestID,
  buttonStyles = null,
  clearButtonColor,
}: ClearInputButtonProps) => {
  const colors = useThemeColors();
  return (
    <Touchable onPress={onClear} transparentBackground testID={"CLEAR_INPUT_BTN"}>
      <View style={[clearButtonStyles.clearButton, buttonStyles]}>
        <MerakiIcon
          style={{ color: clearButtonColor ?? colors.closeButton }}
          testID={clearTestID}
          name="close"
          size="s"
        />
      </View>
    </Touchable>
  );
};

const clearButtonStyles = StyleSheet.create({
  clearButton: {
    paddingVertical: SPACING.small,
    paddingLeft: SPACING.default,
  },
});

export default ClearInputButton;
