import { ReduxAction } from "../actions";
import {
  NETWORK_TRACK_SETTINGS_SUCCESS,
  SET_CURRENT_CLUSTER,
  SET_CURRENT_LOCALE,
  SET_CURRENT_NETWORK,
  SET_CURRENT_ORGANIZATION,
  SET_CURRENT_SHARD_ID,
  SET_LATEST_SUPPORT_SUBMIT_TIME,
  WIPE_REDUX,
} from "../constants/ActionTypes";
import { Clusters } from "../types/Networks";

export const DETOX = process.env.E2E || false;

export enum ClientTrackingMethod {
  mac = "MAC address",
  ip = "IP address",
  tbuci = "Unique client identifier",
}
export interface MkiconfState {
  currentNetwork?: string;
  currentOrganization?: string;
  // Some request logic depends on currentShardId being null
  // Defaulting this to n1 shard created a bug for Go
  currentShardId: number | null;
  currentCluster: Clusters;
  currentLocale?: string;
  currentNetworkClientTrackingMethod: ClientTrackingMethod;
  latestSupportCaseSubmitTime?: number;
}

const initialState = {
  currentCluster: DETOX ? "ephemeral" : "default",
  currentNetworkClientTrackingMethod: ClientTrackingMethod.mac,
  currentShardId: null,
} as const;

export function mkiconf(state: MkiconfState = initialState, action: ReduxAction): MkiconfState {
  switch (action.type) {
    case SET_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.networkId,
      };
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.organization,
      };
    case SET_CURRENT_SHARD_ID:
      return {
        ...state,
        currentShardId: action.shardId,
      };
    case SET_CURRENT_CLUSTER:
      return {
        ...state,
        currentCluster: action.cluster,
      };
    case SET_CURRENT_LOCALE:
      return {
        ...state,
        currentLocale: action.locale,
      };

    case NETWORK_TRACK_SETTINGS_SUCCESS: {
      const { response } = action;
      const { clientTrackingMethod } = response;
      return {
        ...state,
        currentNetworkClientTrackingMethod: clientTrackingMethod,
      };
    }
    case WIPE_REDUX:
      return { ...initialState, currentLocale: state.currentLocale };

    case SET_LATEST_SUPPORT_SUBMIT_TIME: {
      return {
        ...state,
        latestSupportCaseSubmitTime: action.time.getTime(),
      };
    }
    default:
      return state;
  }
}
