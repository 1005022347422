import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const DHCPSubnetSchema = z
  .object({
    subnet: z.string(),
    vlanId: z.number(),
    usedCount: z.number(),
    freeCount: z.number(),
  })
  .describe("DHCPSubnetSchema");

export const DHCPSubnetsSchema = z.array(DHCPSubnetSchema).describe("DHCPSubnetsSchema");

export type DHCPSubnet = z.infer<typeof DHCPSubnetSchema>;
export type DHCPSubnets = z.infer<typeof DHCPSubnetsSchema>;

interface DHCPSubnetsRequest {
  serial?: string;
}

const buildUrl = ({ serial }: DHCPSubnetsRequest) => {
  return `/api/v1/devices/${serial}/appliance/dhcp/subnets`;
};
const fetchDHCPSubnets = ({ serial }: DHCPSubnetsRequest) => {
  return request(DHCPSubnetsSchema, "GET", buildUrl({ serial }));
};

export const useDHCPSubnets = createQuery({
  baseQueryKey: buildUrl({ serial: `{serial}` }),
  queryFn: (parameters: DHCPSubnetsRequest) => fetchDHCPSubnets(parameters),
  requiredVariables: ["serial"],
});
