import { Notification } from "@meraki/magnetic/components";

import { getIsReadOnlyUser } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

export default function ReadOnlyNotification() {
  const isReadOnlyUser = useAppSelector(getIsReadOnlyUser);

  return isReadOnlyUser ? (
    <Notification.Inline status="informational" message="Read-Only View" />
  ) : null;
}
