import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useReducer } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useSwitchSettings } from "~/api/queries/switches/useSwitchSettings";
import { KEYBOARD_TYPE } from "~/constants/MkiConstants";
import IPAddressInputRow from "~/go//rows/IPAddressInputRow";
import InputRow from "~/go/rows/InputRow";
import RadioSelectionRow from "~/go/rows/RadioSelectionRow";
import SubnetMaskSelectionRow, { INITIAL_SUBNET_MASK } from "~/go/rows/SubnetMaskSelectionRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { subnetMaskFromDottedDecimal } from "~/lib/IPAddressUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import useActions from "~/shared/hooks/redux/useActions";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import { IPConfiguration } from "~/shared/types/Device";
import { StaticIPForm, StaticIPResponse } from "~/shared/types/IPAddress";

import { HardwareStackPropMap } from "../navigation/Types";

const radioOptions = [
  { label: I18n.t("STATIC_IP_CONFIGURATION.DHCP"), value: IPConfiguration.dhcp },
  { label: I18n.t("STATIC_IP_CONFIGURATION.STATIC_IP"), value: IPConfiguration.staticIp },
];

const initialStaticIPForm = {
  usingStaticIp: false,
  vlan: "",
  staticIp: "",
  staticSubnetMask: INITIAL_SUBNET_MASK,
  staticGatewayIp: "",
  primaryStaticDns: "",
  secondaryStaticDns: "",
};

type StaticIPFormActionType = "PARSE_RESPONSE" | "UPDATE_FIELDS";
interface StaticIPFormAction {
  type: StaticIPFormActionType;
  response?: StaticIPResponse;
  updates?: Partial<StaticIPForm>;
}

function StaticIPFormReducer(state: StaticIPForm, action: StaticIPFormAction) {
  switch (action.type) {
    case "PARSE_RESPONSE": {
      const { response } = action;
      const wan1 = response?.wan1;
      if (!wan1) {
        return state;
      }

      const { staticDns, vlan, staticSubnetMask, ...rest } = wan1;

      return {
        ...state,
        ...rest,
        vlan: vlan ? vlan.toString() : "",
        staticSubnetMask: staticSubnetMask
          ? subnetMaskFromDottedDecimal(staticSubnetMask).toString()
          : INITIAL_SUBNET_MASK,
        primaryStaticDns: staticDns?.[0] ?? "",
        secondaryStaticDns: staticDns?.[1] ?? "",
      };
    }
    case "UPDATE_FIELDS": {
      return { ...state, ...action.updates };
    }
  }
}

function useStaticIPFormUpdate(
  fieldName: keyof StaticIPForm,
  dispatch: (action: StaticIPFormAction) => void,
) {
  return useCallback(
    (update: string) => {
      dispatch({
        type: "UPDATE_FIELDS",
        updates: {
          [fieldName]: update,
        },
      });
    },
    [dispatch, fieldName],
  );
}

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "StaticIPConfiguration"> &
  PendingComponent;

function StaticIPConfigurationScreen({ serialNumber, setReqPending, handleError }: Props) {
  const actions = useActions();
  const switchSettingData = useSwitchSettings().data;

  const [formState, dispatch] = useReducer(StaticIPFormReducer, initialStaticIPForm);

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    const onSave = async () => {
      setReqPending(true);

      try {
        await actions.updateManagementInterface(serialNumber, formState);
        navigation.goBack();
      } catch (err) {
        if (typeof err === "string") {
          handleError(err);
        }
      } finally {
        setReqPending(false);
      }
    };

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} />,
    });
  }, [actions, formState, handleError, navigation, serialNumber, setReqPending]);

  const {
    usingStaticIp,
    vlan,
    staticIp,
    staticSubnetMask,
    staticGatewayIp,
    primaryStaticDns,
    secondaryStaticDns,
  } = formState;

  useEffect(() => {
    setReqPending(true);

    actions
      .getManagementInterface(serialNumber)
      .then(({ response }) => {
        dispatch({
          type: "PARSE_RESPONSE",
          response,
        });
        setReqPending(false);
      })
      .catch(handleError);
  }, [actions, handleError, serialNumber, setReqPending]);

  const updateUsingStaticIp = useCallback((value: IPConfiguration) => {
    dispatch({
      type: "UPDATE_FIELDS",
      updates: {
        usingStaticIp: value === IPConfiguration.staticIp,
      },
    });
  }, []);

  const updateVlan = useStaticIPFormUpdate("vlan", dispatch);
  const updateStaticIp = useStaticIPFormUpdate("staticIp", dispatch);
  const updateStaticSubnetMask = useStaticIPFormUpdate("staticSubnetMask", dispatch);
  const updateStaticGatewayIp = useStaticIPFormUpdate("staticGatewayIp", dispatch);
  const updatePrimaryStaticDns = useStaticIPFormUpdate("primaryStaticDns", dispatch);
  const updateSecondaryStaticDns = useStaticIPFormUpdate("secondaryStaticDns", dispatch);

  // Go sets default manage vlan to 1
  const dhcpManageVlan = switchSettingData?.vlan || 1;
  const selectedRadioOption = usingStaticIp ? IPConfiguration.staticIp : IPConfiguration.dhcp;

  return (
    <FullScreenContainerView withScroll>
      <RadioSelectionRow
        title={I18n.t("STATIC_IP_CONFIGURATION.TYPE")}
        onSelect={updateUsingStaticIp}
        options={radioOptions}
        selectedValue={selectedRadioOption}
      />

      <InputRow
        keyboardType={KEYBOARD_TYPE.numeric}
        value={vlan}
        placeholder={
          usingStaticIp
            ? I18n.t("STATIC_IP_CONFIGURATION.STATIC_VLAN_PLACEHOLDER")
            : I18n.t("STATIC_IP_CONFIGURATION.DHCP_VLAN_PLACEHOLDER", {
                manageVlan: dhcpManageVlan,
              })
        }
        onChangeText={updateVlan}
      >
        {I18n.t("STATIC_IP_CONFIGURATION.VLAN")}
      </InputRow>

      {usingStaticIp && (
        <>
          <IPAddressInputRow
            title={I18n.t("STATIC_IP_CONFIGURATION.IP")}
            editable
            cidrAddress={staticIp}
            onAddressChange={updateStaticIp}
            leaveEditableBlank={false}
          />

          <SubnetMaskSelectionRow
            value={staticSubnetMask}
            onApplySubnetMask={updateStaticSubnetMask}
          />

          <IPAddressInputRow
            title={I18n.t("STATIC_IP_CONFIGURATION.GATEWAY")}
            editable
            cidrAddress={staticGatewayIp}
            onAddressChange={updateStaticGatewayIp}
            leaveEditableBlank={false}
          />

          <IPAddressInputRow
            title={I18n.t("STATIC_IP_CONFIGURATION.PRIMARY_DNS")}
            editable
            cidrAddress={primaryStaticDns}
            onAddressChange={updatePrimaryStaticDns}
            leaveEditableBlank={false}
          />

          <IPAddressInputRow
            title={I18n.t("STATIC_IP_CONFIGURATION.SECONDARY_DNS")}
            editable
            cidrAddress={secondaryStaticDns}
            onAddressChange={updateSecondaryStaticDns}
            leaveEditableBlank={false}
          />
        </>
      )}
    </FullScreenContainerView>
  );
}

export default withPendingComponent(StaticIPConfigurationScreen);
