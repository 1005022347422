import {
  GET_UMBRELLA_STATUS_FAILURE,
  GET_UMBRELLA_STATUS_REQUEST,
  GET_UMBRELLA_STATUS_SUCCESS,
  UMBRELLA_ACTIVITIES_FOR_IP_FAILURE,
  UMBRELLA_ACTIVITIES_FOR_IP_REQUEST,
  UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS,
  UMBRELLA_ACTIVITY_FAILURE,
  UMBRELLA_ACTIVITY_REQUEST,
  UMBRELLA_ACTIVITY_SUCCESS,
  UMBRELLA_FETCHING_FAILURE,
  UMBRELLA_FETCHING_REQUEST,
  UMBRELLA_FETCHING_SUCCESS,
  UMBRELLA_HIGH_USAGE_IPS_FAILURE,
  UMBRELLA_HIGH_USAGE_IPS_REQUEST,
  UMBRELLA_HIGH_USAGE_IPS_SUCCESS,
  UMBRELLA_OAUTH_TOKEN_FAILURE,
  UMBRELLA_OAUTH_TOKEN_REQUEST,
  UMBRELLA_OAUTH_TOKEN_SUCCESS,
  UMBRELLA_PROTECTION_GET_FAILURE,
  UMBRELLA_PROTECTION_GET_REQUEST,
  UMBRELLA_PROTECTION_GET_SUCCESS,
  UMBRELLA_PROTECTION_SET_FAILURE,
  UMBRELLA_PROTECTION_SET_REQUEST,
  UMBRELLA_PROTECTION_SET_SUCCESS,
  UMBRELLA_TOP_DESTINATIONS_BY_IP_FAILURE,
  UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST,
  UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS,
  UMBRELLA_TOTAL_REQUESTS_FAILURE,
  UMBRELLA_TOTAL_REQUESTS_REQUEST,
  UMBRELLA_TOTAL_REQUESTS_SUCCESS,
} from "@meraki/shared/redux";
import { get, merge } from "lodash";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { TOP_IPS_LIMIT } from "~/constants/Umbrella";
import { umbrellaURL } from "~/env";
import { calculateFromWithTimespan, determineActivityPageQuery } from "~/lib/UmbrellaUtils";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkState,
  getActivitiesByIp,
  getCurrentOrgEid,
  getSecurityEvents,
  shouldShowUmbrellaUISelector,
  timespanState,
} from "~/selectors";
import { ApiError } from "~/shared/types/Error";
import { URLPrepends } from "~/shared/types/Networks";
import { ApiAction, AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";
import { UmbrellaVerdict } from "~/shared/types/Umbrella";
import * as UmbrellaApiRequests from "~/shared/types/UmbrellaApiRequests";

const UMBRELLA_REDIRECTION_ERROR = "UMBRELLA_REDIRECTION_ERROR";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
function getUmbrellaOAuthToken(): AppThunk<Promise<string>> {
  return (dispatch) => {
    const queryParams = {
      api_key_name: "reporting",
    };
    return dispatch({
      [CALL_API]: {
        types: [
          UMBRELLA_OAUTH_TOKEN_REQUEST,
          UMBRELLA_OAUTH_TOKEN_SUCCESS,
          UMBRELLA_OAUTH_TOKEN_FAILURE,
        ],
        endpoint: `/manage/security/umbrella_oauth2_token`,
        config: {
          method: Method.get,
          queryParams,
        },
      },
    } as ApiAction<{ token: string }>).then((response) => get(response, "response.token"));
  };
}

function withUmbrellaOAuthToken(action: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) => {
    const parseError = (error: ApiError) => {
      const errorResponse = error.response;
      const errorMessage = error.message;

      if (errorResponse && !errorResponse.url.startsWith(umbrellaURL())) {
        return {
          message: UMBRELLA_REDIRECTION_ERROR,
          endpoint: errorResponse.url,
        };
      }

      return errorMessage;
    };

    let headers = {};
    return dispatch(getUmbrellaOAuthToken())
      .then((accessToken) => {
        headers = merge({}, action.config ? action.config.headers : {}, {
          Authorization: `Bearer ${accessToken}`,
        });

        const actionWithOAuthToken = {
          ...action,
          config: {
            ...action.config,
            headers,
          },
          prepend: URLPrepends.umbrella,
          endpoint: `/reports/v2${action.endpoint}`,
          parseError,
        };

        return dispatch<Promise<ApiResponseAction<any>>>({
          [CALL_API]: actionWithOAuthToken,
        });
      })
      .catch((error: any) => {
        if (typeof error === "object" && error.message === UMBRELLA_REDIRECTION_ERROR) {
          return dispatch({
            [CALL_API]: {
              ...action,
              config: {
                method: action.config.method,
                headers,
              },
              endpoint: error.endpoint,
            },
          });
        }

        return error;
      });
  };
}

export function getUmbrellaActivity(
  now: number,
  resetActivity = false,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const timespan = timespanState(state);
    const securityEvents = getSecurityEvents(state);

    const queryParams = determineActivityPageQuery(timespan, resetActivity, now, securityEvents);

    return dispatch(
      withUmbrellaOAuthToken({
        types: [UMBRELLA_ACTIVITY_REQUEST, UMBRELLA_ACTIVITY_SUCCESS, UMBRELLA_ACTIVITY_FAILURE],
        endpoint: `/activity`,
        config: {
          method: Method.get,
          queryParams,
        },
        meta: {
          resetActivity,
        },
      }),
    );
  };
}

export function getTotalRequestCount(now: number): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const timespan = timespanState(state);

    const queryParams: UmbrellaApiRequests.UmbrellaRequestParamsActivity = {
      from: calculateFromWithTimespan(now, timespan),
      to: now,
      verdict: UmbrellaVerdict.blocked,
    };

    return dispatch(
      withUmbrellaOAuthToken({
        types: [
          UMBRELLA_TOTAL_REQUESTS_REQUEST,
          UMBRELLA_TOTAL_REQUESTS_SUCCESS,
          UMBRELLA_TOTAL_REQUESTS_FAILURE,
        ],
        endpoint: `/total-requests`,
        config: {
          method: Method.get,
          queryParams,
        },
      }),
    );
  };
}

export function getHighUsageIps(now: number): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const timespan = timespanState(state);

    const queryParams: UmbrellaApiRequests.UmbrellaRequestParamsActivity = {
      from: calculateFromWithTimespan(now, timespan),
      to: now,
      limit: TOP_IPS_LIMIT,
      verdict: UmbrellaVerdict.blocked,
    };

    return dispatch(
      withUmbrellaOAuthToken({
        types: [
          UMBRELLA_HIGH_USAGE_IPS_REQUEST,
          UMBRELLA_HIGH_USAGE_IPS_SUCCESS,
          UMBRELLA_HIGH_USAGE_IPS_FAILURE,
        ],
        endpoint: `/top-ips/internal`,
        config: {
          method: Method.get,
          queryParams,
        },
      }),
    );
  };
}

export function getTopDestinationsForIp(
  ipAddress: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const timespan = timespanState(state);

    const now = Date.now();
    const queryParams = {
      from: calculateFromWithTimespan(now, timespan),
      to: now,
      limit: TOP_IPS_LIMIT,
      verdict: UmbrellaVerdict.blocked,
      offset: 0,
      ip: ipAddress,
    };

    return dispatch(
      withUmbrellaOAuthToken({
        types: [
          UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST,
          UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS,
          UMBRELLA_TOP_DESTINATIONS_BY_IP_FAILURE,
        ],
        endpoint: `/top-destinations/dns`,
        config: {
          method: Method.get,
          queryParams,
        },
        meta: {
          ip: ipAddress,
        },
      }),
    );
  };
}

export function getActivitiesForIp(
  now: number,
  resetActivity = false,
  ipAddress: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const timespan = timespanState(state);
    const securityEvents = getActivitiesByIp(state)[ipAddress];

    const queryParams = determineActivityPageQuery(
      timespan,
      resetActivity,
      now,
      securityEvents,
      ipAddress,
    );

    return dispatch(
      withUmbrellaOAuthToken({
        types: [
          UMBRELLA_ACTIVITIES_FOR_IP_REQUEST,
          UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS,
          UMBRELLA_ACTIVITIES_FOR_IP_FAILURE,
        ],
        endpoint: `/activity`,
        config: {
          method: Method.get,
          queryParams,
        },
        meta: {
          resetActivity,
          ip: ipAddress,
        },
      }),
    );
  };
}

export function umbrellaFetchingRequest() {
  return {
    type: UMBRELLA_FETCHING_REQUEST,
  };
}

export function umbrellaFetchingSuccess() {
  return {
    type: UMBRELLA_FETCHING_SUCCESS,
  };
}

export function umbrellaFetchingFailure() {
  return {
    type: UMBRELLA_FETCHING_FAILURE,
  };
}

export function fetchUmbrellaForHomeScreen(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    const now = Date.now();
    await Promise.all([dispatch(getTotalRequestCount(now)), dispatch(getHighUsageIps(now))]);
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getUmbrellaStatus(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const orgEid = getCurrentOrgEid(getState());

    return dispatch({
      [CALL_API]: {
        types: [
          GET_UMBRELLA_STATUS_REQUEST,
          GET_UMBRELLA_STATUS_SUCCESS,
          GET_UMBRELLA_STATUS_FAILURE,
        ],
        endpoint: `/o/${orgEid}/manage/security/go_umbrella_status`,
        config: {
          method: Method.get,
        },
      },
    });
  };
}

export function checkAndFetchUmbrellaAction(
  umbrellaAction: () => Promise<any>,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(umbrellaFetchingRequest());
      await dispatch(getUmbrellaStatus());
      await dispatch(fetchUmbrellaProtection());

      const state = getState();
      if (shouldShowUmbrellaUISelector(state)) {
        await umbrellaAction();
      }

      dispatch(umbrellaFetchingSuccess());
    } catch (error) {
      dispatch(umbrellaFetchingFailure());
    }
  };
}

export function fetchUmbrellaProtection(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [
          UMBRELLA_PROTECTION_GET_REQUEST,
          UMBRELLA_PROTECTION_GET_SUCCESS,
          UMBRELLA_PROTECTION_GET_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/umbrella/protection`,
        config: {
          method: Method.get,
        },
      },
    });
  };
}

export function setUmbrellaProtection(
  policyId?: string,
  excludedDomains: string[] = [],
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    const requestBody = {
      excludedDomains,
      umbrellaPolicyId: policyId,
      umbrellaProtectionEnabled: true,
    };

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          UMBRELLA_PROTECTION_SET_REQUEST,
          UMBRELLA_PROTECTION_SET_SUCCESS,
          UMBRELLA_PROTECTION_SET_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/umbrella/protection/applyProtectionAttempts`,
        config: {
          method: Method.post,
          body: JSON.stringify(requestBody),
        },
        meta: requestBody,
      }),
    ).catch((error: object) => Promise.reject(get(error, "request.0", error)));
  };
}
