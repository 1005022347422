import Svg, { G, Path } from "react-native-svg";

import { IconProps } from "./types";

export function Stacked({ color, x, y }: IconProps) {
  return (
    <G x={x} y={y}>
      <Svg fill="none" height="26" viewBox="0 0 27 26" width="27">
        <Path
          clipRule="evenodd"
          d="M2.1875 5.3125C2.1875 4.79473 2.60723 4.375 3.125 4.375H16.875C17.3928 4.375 17.8125 4.79473 17.8125 5.3125C17.8125 5.83027 17.3928 6.25 16.875 6.25H3.125C2.60723 6.25 2.1875 5.83027 2.1875 5.3125Z"
          fill={color}
          fillRule="evenodd"
        />
        <Path
          clipRule="evenodd"
          d="M2.1875 8.4375C2.1875 7.91973 2.60723 7.5 3.125 7.5H16.875C17.3928 7.5 17.8125 7.91973 17.8125 8.4375C17.8125 8.95527 17.3928 9.375 16.875 9.375H3.125C2.60723 9.375 2.1875 8.95527 2.1875 8.4375Z"
          fill={color}
          fillRule="evenodd"
        />
        <Path
          clipRule="evenodd"
          d="M2.18811 11.5625C2.18811 11.0447 2.60784 10.625 3.12561 10.625H16.8751C17.3929 10.625 17.8126 11.0447 17.8126 11.5625C17.8126 12.0803 17.3929 12.5 16.8751 12.5H3.12561C2.60784 12.5 2.18811 12.0803 2.18811 11.5625Z"
          fill={color}
          fillRule="evenodd"
        />
        <Path
          clipRule="evenodd"
          d="M2.18811 14.6875C2.18811 14.1697 2.60784 13.75 3.12561 13.75H16.8751C17.3929 13.75 17.8126 14.1697 17.8126 14.6875C17.8126 15.2053 17.3929 15.625 16.8751 15.625H3.12561C2.60784 15.625 2.18811 15.2053 2.18811 14.6875Z"
          fill={color}
          fillRule="evenodd"
        />
      </Svg>
    </G>
  );
}
