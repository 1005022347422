import * as WakeOnLan from "../handlers/WakeOnLan";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useWakeOnLan(deviceId: string): LiveTool<boolean> {
  const msg = useLiveBroker<BaseDeviceSubProps, WakeOnLan.Message>({
    type: "WakeOnLan",
    deviceId,
  });

  return useCachedFormatter(msg, WakeOnLan.formatMsg);
}
