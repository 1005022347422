import { notify } from "@meraki/core/errors";
import { currentLanguage } from "@meraki/core/i18n";
import { fromUnixTime } from "date-fns";
import {
  cs,
  de,
  enUS,
  es,
  fr,
  frCA,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru,
  sk,
  zhCN,
} from "date-fns/locale";
// This is the only place we should be using this date formatting function so we are ignoring the rule here.
// eslint-disable-next-line no-restricted-imports
import { formatInTimeZone, getTimezoneOffset as getTimezoneOffsetDateFns } from "date-fns-tz";
import { getTimeZone as getDeviceTimeZone } from "react-native-localize";

import { getTimezone } from "./getTimezone";

export type DateFormatOptions = {
  timeZone?: string;
  dateFormat?: keyof typeof dateFormats;
  timeFormat?: keyof typeof timeFormats;
  timeZoneFormat?: keyof typeof timeZoneFormats;
  unixTimestamp?: boolean;
};

const dateFormats = {
  longDateWithDayOfWeek: "ccc, PP",
  longDateFullMonth: "PPP",
  longDate: "PP",
  shortDate: "MMM d",
  shortDateFullMonth: "MMMM d",
  date: "P",
  dateNoYear: "MM/dd",
  hide: "",
};

const timeFormats = {
  shortTime: "p",
  shortTime24HourForce: "HH:mm",
  longTime: "pp",
  longTime24HourForce: "HH:mm:ss",
  hourTime: "ha",
  hide: "",
};

const timeZoneFormats = {
  show: "zzz",
  hide: "",
};

const availableLocales = {
  en: enUS,
  cs,
  de,
  es,
  fr,
  frca: frCA,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru,
  sk,
  zh: zhCN,
};

function getLocale(locale: string) {
  // This odd TS trickery just makes TS happy with `locale` being one of the types in the object. If for some reason it is not we simply default to enUS.
  return availableLocales[locale as keyof typeof availableLocales] ?? enUS;
}

function addCommaIfNeeded(options?: DateFormatOptions) {
  return (options?.dateFormat === "shortDate" || options?.dateFormat === "shortDateFullMonth") &&
    !!options?.timeFormat
    ? ","
    : "";
}

let defaultTimezone: string | undefined;
export function setTimezone(timezone: string | undefined) {
  defaultTimezone = timezone;
}

function isNumeric(str: string | number | Date) {
  if (typeof str !== "string") return false;

  return (
    // @ts-expect-error - We are using the `isNaN` function here to check if the string is a number. isNaN thinks it only works on numbers which is usually true...
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  );
}

export function formatDate(date: string | Date | number | undefined, options?: DateFormatOptions) {
  const format = `${dateFormats[options?.dateFormat ?? "date"]}${addCommaIfNeeded(options)} ${
    timeFormats[options?.timeFormat ?? "hide"]
  } ${timeZoneFormats[options?.timeZoneFormat ?? "hide"]}`.trim();

  const timeZone = getTimezone(options?.timeZone ?? defaultTimezone ?? getDeviceTimeZone());

  // The undefined date fix is to handle the case where the date is undefined. This is a bit of functionality that worked with the original `formatDate` function so we are keeping it for now.
  const undefinedDateFixed = typeof date === "undefined" ? new Date() : date;
  const stringDateFixed =
    typeof undefinedDateFixed === "string" && isNumeric(undefinedDateFixed)
      ? parseFloat(undefinedDateFixed)
      : undefinedDateFixed;

  const adjustedDate =
    (options?.unixTimestamp ?? true) && typeof stringDateFixed === "number"
      ? fromUnixTime(stringDateFixed)
      : stringDateFixed;

  try {
    return formatInTimeZone(adjustedDate, timeZone, format, {
      ...(options || {}),
      locale: getLocale(currentLanguage()),
    });
  } catch (e) {
    // We don't really want to crash the app if we fail to render a date...
    if (__DEV__) {
      // ...except in development where we want to know about it.
      throw e;
    }

    // In production we just want to log the error and move on.
    notify(e as Error);

    return "";
  }
}

export function createDateFormatter(options?: DateFormatOptions) {
  return (date: string | Date | number | undefined, instanceOptions?: DateFormatOptions) =>
    formatDate(date, { ...(options || {}), ...(instanceOptions || {}) });
}

export function getTimezoneOffset() {
  const timeZone = getTimezone(defaultTimezone ?? getDeviceTimeZone());
  return getTimezoneOffsetDateFns(timeZone);
}
