import { StyleSheet, View } from "react-native";

import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface TextInputBox {
  header: string;
  value: string;
  maxMinWidth: number;
  onChangeText: (newValue: string) => void;
  borderColor?: string;
  testID?: string;
}

// TODO: UDG-3699 - Should be removed once component library is ready
const TextInputBox = ({
  header,
  value,
  maxMinWidth,
  onChangeText,
  borderColor,
  testID,
}: TextInputBox) => {
  const colors = useThemeColors();

  return (
    <View style={[styles.container, { maxWidth: maxMinWidth }]} testID={testID}>
      <MkiText textStyle="smallSecondary">{header}</MkiText>
      <MkiTextInput
        value={value}
        screenStyles={[
          styles.inputField,
          {
            borderColor: borderColor ?? colors.text.placeholder.color,
          },
        ]}
        keyboardType={KEYBOARD_TYPE.numeric}
        underlineColorAndroid="transparent"
        onChangeText={onChangeText}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  inputField: {
    maxHeight: 50,
    borderWidth: SPACING.tiny,
    borderRadius: SPACING.small,
    paddingHorizontal: SPACING.small,
  },
});

export default TextInputBox;
