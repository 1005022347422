import { ErrorMessage } from "@hookform/error-message";
import { FlashListProps, List, Notification } from "@meraki/magnetic/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type OptionalFields = "checkedRadioValue" | "onRadioValueChanged";
type getItemDataRtnType<T> = ReturnType<FlashListProps<T>["getItemData"]>;
type getItemDataRadioRtn<T> = getItemDataRtnType<T> & { kind: "radio" };
type PartialRadioRtn<T> = Partial<Pick<getItemDataRadioRtn<T>, OptionalFields>>;

type getItemDataRtnTypeWithoutRadioRtn<T> = Exclude<getItemDataRtnType<T>, getItemDataRadioRtn<T>>;
type getItemDataRtnTypeWithPartialRadioRtn<T> = PartialRadioRtn<T> &
  Omit<getItemDataRadioRtn<T>, OptionalFields>;

type getItemData<T> = (
  ...params: Parameters<FlashListProps<T>["getItemData"]>
) => getItemDataRtnTypeWithoutRadioRtn<T> | getItemDataRtnTypeWithPartialRadioRtn<T>;

type FormFlashListProps<T> = Omit<FlashListProps<T>, "getItemData"> &
  FormItemProps &
  (
    | {
        label?: string;
        groupBy?: never;
        sortGroupBy?: never;
        getItemData: getItemData<T>;
      }
    | {
        label?: never;
        groupBy?: (item: T) => string;
        sortGroupBy?: (a: { label: string; data: T[] }, b: { label: string; data: T[] }) => number;
        getItemData: getItemData<T>;
      }
  );

export function FormFlashList<T>({ name, rules, getItemData, ...rest }: FormFlashListProps<T>) {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value } }) => (
          <List.FlashList
            {...rest}
            getItemData={(...args) => {
              const itemData = getItemData(...args);
              switch (itemData.kind) {
                case "radio":
                  return {
                    ...itemData,
                    checkedRadioValue: value,
                    onRadioValueChanged: (value: string | number | null) => {
                      itemData.onRadioValueChanged?.(value);
                      onChange(value);
                    },
                  };
              }

              return itemData;
            }}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
}
