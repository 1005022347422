import z from "zod";

export const IdentityPskInputSchema = z
  .object({
    name: z.string(),
    groupPolicyId: z.string(),
    passphrase: z.string(),
    expiresAt: z.string().optional().nullable(),
  })
  .describe("IdentityPskInputSchema");

export const IdentityPskSchema = IdentityPskInputSchema.extend({
  id: z.string(),
}).describe("IdentityPskSchema");

export const IdentityPskResponseSchema = IdentityPskSchema.extend({
  wifiPersonalNetworkId: z.string().optional(),
  email: z.string().optional(),
}).describe("IdentityPskResponseSchema");

export const IdentityPsksResponseSchema = z
  .array(IdentityPskResponseSchema)
  .describe("IdentityPsksResponseSchema");

export const DeleteIdentityPskSchema = z.undefined().describe("DeleteIdentityPskSchema");

export type IdentityPskInput = z.infer<typeof IdentityPskInputSchema>;
export type IdentityPsk = z.infer<typeof IdentityPskSchema>;
export type IdentityPskResponse = z.infer<typeof IdentityPskResponseSchema>;
export type IdentityPsksResponse = z.infer<typeof IdentityPsksResponseSchema>;
