import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const SwitchPortStatusSchema = z
  .object({
    portId: z.string(),
    status: z.string(),
    enabled: z.boolean(),
    errors: z.array(z.string()),
    warnings: z.array(z.string()),
    powerUsageInWh: z.number().optional(),
    speed: z.string().optional(),
    isUplink: z.boolean(),
    usageInKb: z.object({
      recv: z.number(),
      sent: z.number(),
      total: z.number(),
    }),
    trafficInKbps: z.object({
      sent: z.number(),
      recv: z.number(),
      total: z.number(),
    }),
    cdp: z
      .object({
        nativeVlan: z.number().optional(),
        address: z.string().optional(),
        capabilities: z.string().optional(),
        deviceId: z.string().optional(),
        managementAddress: z.string().optional(),
        platform: z.string().optional(),
        portId: z.string().optional(),
        systemName: z.string().optional(),
        version: z.string().optional(),
        vtpManagementDomain: z.string().optional(),
      })
      .optional(),
    lldp: z
      .object({
        managementVlan: z.number().optional(),
        portVlan: z.number().optional(),
        chassisId: z.string().optional(),
        managementAddress: z.string().optional(),
        portDescription: z.string().optional(),
        portId: z.string().optional(),
        systemCapabilities: z.string().optional(),
        systemDescription: z.string().optional(),
        systemName: z.string().optional(),
      })
      .optional(),
  })
  .describe("SwitchPortStatusSchema");

interface SwitchPortStatusesRequest {
  serialNumber?: string;
}

interface SwitchPortStatusRequest extends SwitchPortStatusesRequest {
  portId?: string;
}

export const SwitchPortsStatusesSchema = z.array(SwitchPortStatusSchema);

export type SwitchPortsStatuses = z.infer<typeof SwitchPortsStatusesSchema>;
export type SwitchPortStatus = z.infer<typeof SwitchPortStatusSchema>;

const buildUrl = ({ serialNumber }: SwitchPortStatusesRequest) => {
  return `/api/v1/devices/${serialNumber}/switch/ports/statuses`;
};

const fetchSwitchPortStatuses = ({
  serialNumber,
}: SwitchPortStatusesRequest): Promise<SwitchPortsStatuses> => {
  return request(SwitchPortsStatusesSchema, "GET", buildUrl({ serialNumber }));
};

export const useSwitchPortStatuses = createQuery({
  baseQueryKey: buildUrl({ serialNumber: `{serial}` }),
  queryFn: (parameters: SwitchPortStatusesRequest) => fetchSwitchPortStatuses(parameters),
  requiredVariables: ["serialNumber"],
});

export const useSwitchPortStatus = ({ portId, ...rest }: SwitchPortStatusRequest) => {
  return useSwitchPortStatuses(rest, {
    select: (statuses) => {
      return statuses?.find((ports) => ports.portId === portId);
    },
    enabled: Boolean(portId),
  });
};
