import { I18n } from "@meraki/core/i18n";
import { BottomSheet, BottomSheetMethods, Button } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  BatchRequestAction,
  DeviceTagsSchema,
  Ssid,
  useActionBatchMutationWrapper,
  useDevices,
} from "@meraki/shared/api";
import { queryClient, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { ForwardedRef, forwardRef, useCallback } from "react";
import { z } from "zod";

type BroadcastingBottomSheetProps = {
  ssid: Ssid;
};

const ALL_APS = "availableOnAllAps";

export const BroadcastingBottomSheet = forwardRef(function BroadcastingBottomSheet(
  { ssid }: BroadcastingBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const ssidTag = `ssid-${ssid.number}`;
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();

  const { data: wirelessDevices, isLoading: isLoadingDevices } = useDevices(
    { organizationId, networkId },
    {
      select: (data) => data.filter(({ productType }) => productType === "wireless"),
    },
  );

  const updateSsid = useUpdateSsid();
  const batchUpdateDevices = useActionBatchMutationWrapper();

  const methods = useForm<Record<string, boolean>>({
    values: {
      availableOnAllAps: ssid.availableOnAllAps,
      ...(wirelessDevices?.reduce<Record<string, boolean>>((result, device) => {
        result[device.serial] = ssid.availableOnAllAps || device.tags.includes(ssidTag);
        return result;
      }, {}) || {}),
    },
  });

  const dismiss = useCallback(() => {
    if (typeof ref !== "function") {
      ref?.current?.dismiss();
    }
  }, [ref]);

  const selectAll = methods.watch(ALL_APS);
  const onSelect = useCallback(
    (serial: string) => (checked: boolean) => {
      if (!checked && selectAll) {
        methods.setValue(ALL_APS, checked);
      } else if (checked && !selectAll) {
        const values = methods.getValues();

        delete values[ALL_APS];
        delete values[serial];

        if (Object.values(values).every((isChecked) => isChecked)) {
          methods.setValue(ALL_APS, checked);
        }
      }
    },
    [methods, selectAll],
  );

  const onSelectAll = useCallback(
    (checked: boolean) => {
      wirelessDevices?.forEach((device) => {
        methods.setValue(device.serial, checked);
      });
    },
    [methods, wirelessDevices],
  );

  const submit = useCallback(
    async (data: Record<string, boolean>) => {
      const availableOnAllAps = data[ALL_APS];

      try {
        if (!availableOnAllAps) {
          const actions: BatchRequestAction[] = [];

          wirelessDevices?.forEach((device) => {
            const checked = data[device.serial];
            const tagsSet = new Set(device.tags);

            if (tagsSet.has(ssidTag) !== checked) {
              if (checked) {
                tagsSet.add(ssidTag);
              } else {
                tagsSet.delete(ssidTag);
              }

              actions.push({
                resource: `/devices/${device.serial}`,
                operation: "update",
                body: { tags: Array.from(tagsSet) },
              });
            }
          });

          if (actions.length > 0) {
            await batchUpdateDevices.mutateAsync(
              {
                organizationId,
                actions: actions,
                actionTypeSchema: z.object({ tags: DeviceTagsSchema }),
              },
              {
                onError: (error) => showErrorAlert(error.errors?.[0]),
                onSuccess: () =>
                  queryClient.invalidateQueries({
                    queryKey: useDevices.queryKey({ organizationId }),
                  }),
              },
            );
          }
        }

        updateSsid.mutate(
          {
            networkId,
            ssidNumber: ssid.number,
            body: {
              availableOnAllAps,
              availabilityTags: [ssidTag],
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useSsids.queryKey({ networkId }) });
              dismiss();
            },
            onError: (error) => showErrorAlert(String(error.errors?.[0])),
          },
        );
      } catch (error) {
        // onError will handle it
      }
    },
    [
      batchUpdateDevices,
      dismiss,
      networkId,
      organizationId,
      ssid.number,
      ssidTag,
      updateSsid,
      wirelessDevices,
    ],
  );

  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref}>
      <BottomSheet.Header
        title={I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.TITLE")}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => dismiss()}
      />
      <BottomSheet.ScrollView>
        <Form {...methods}>
          <BottomSheet.FlashList
            label={I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.SELECT_APS")}
            data={[
              {
                name: I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.ALL_AVAILABLE"),
                serial: ALL_APS,
              },
              ...(wirelessDevices || []),
            ]}
            getItemData={({ name, serial }) => {
              return {
                title: name || serial,
                leftAccessory: (
                  <Form.Checkbox
                    name={serial}
                    onValueChange={serial === ALL_APS ? onSelectAll : onSelect(serial)}
                    testID={`BROADCAST.AP_${serial}`}
                  />
                ),
              };
            }}
            loading={isLoadingDevices}
            emptyState={{ title: I18n.t("NO_DEVICES") }}
            testID="BROADCAST.AP_LIST"
          />
        </Form>
        <Box padding="sm" paddingBottom="none">
          <Button
            kind="primary"
            text={I18n.t("SAVE")}
            onPress={methods.handleSubmit(submit)}
            testID="BROADCAST.SAVE"
          />
        </Box>
      </BottomSheet.ScrollView>
    </BottomSheet.Modal>
  );
});
