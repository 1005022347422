import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { processRusage } from "@meraki/shared/formatters";
import { get } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import BroadcastingAccessPointsCard from "~/go/components/BroadcastingAccessPointsCard";
import IconSectionHeader from "~/go/components/IconSectionHeader";
import LocationAnalyticsSummaryCard from "~/go/components/locationAnalytics/LocationAnalyticsSummaryCard";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import ShareSSIDNameHeader from "~/go/components/ShareSSIDNameHeader";
import SsidClientUsageSummary from "~/go/components/SsidClientUsageSummary";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { Features } from "~/go/types/ContextHelpTypes";
import { SummaryCardData } from "~/go/types/LocationAnalyticsTypes";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import { showActionSheet, showAlert, showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { getLocationAnalyticsProps } from "~/lib/LocationAnalyticsUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { validateName } from "~/lib/SSIDUtils";
import { sizeSelect } from "~/lib/themeHelper";
import { calculateTimespanForPresence } from "~/lib/timeHelper";
import {
  clientsLoadingState,
  counterSetsState,
  currentNetworkState,
  encryptedNetworkIdSelector,
  filteredClients,
  isLocationAnalyticsEnabled,
  locationAnalyticsSummaryCardDataSelector,
  slimSsidsByIdSelector,
  slimSsidsSelector,
  ssidUseblocksLoadingState,
  ssidUseblocksState,
  timespanState,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import HiddenPWText from "~/shared/components/HiddenPWText";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import LoadingView from "~/shared/components/LoadingView";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import ShowHidePasswordButton from "~/shared/components/ShowHidePasswordButton";
import TimePicker from "~/shared/components/TimePicker";
import TopItemsList from "~/shared/components/TopItemsList";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { SettingsButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import ListRow from "~/shared/rows/ListRow";
import UsageRow from "~/shared/rows/UsageRow.go";
import { Client } from "~/shared/types/Client";
import { SSID, SSIDAuthMode } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type RenderedApplicationRow = {
  children: string;
  usage: number;
  percent: number;
  disclosureRow?: boolean;
};

type ReduxProps = {
  clients: Client[];
  ssids: SSID[];
  counterSets: object;
  encryptedId: string;
  isLoadingClients: boolean;
  isLocationAnalyticsEnabled: boolean;
  networkId: string;
  presenceSummaryData: SummaryCardData | null;
  ssid: SSID;
  ssidUseblocksLoading: boolean;
  timespan: number;
  useblocks: boolean;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDDetails"> &
  ReduxProps &
  BasicActions &
  WithCancelablePromiseProps;

interface SSIDDetailsScreenState {
  reqPending: boolean;
  showPassword: boolean;
}

export class SSIDDetailsScreen extends PureComponent<Props, SSIDDetailsScreenState> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, ssid } = this.props;

    navigation.setOptions({
      headerRight: () => (
        <SettingsButton
          testID="SSID_SETTINGS_BUTTON"
          onPress={() => {
            navigation.navigate("SSIDConfigure", {
              ssidNumber: ssid.number,
            });
          }}
        />
      ),
    });
  }

  state = {
    reqPending: false,
    showPassword: false,
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps: Props) {
    const { timespan } = this.props;
    if (prevProps.timespan !== timespan) {
      this.getData(true);
    }
  }

  onRefresh = () => this.getData(true);

  getData = async (force = false) => {
    const {
      actions,
      networkId,
      encryptedId,
      useblocks,
      clients,
      presenceSummaryData,
      timespan,
      ssid,
      cancelablePromise,
    } = this.props;
    const reqs: Promise<unknown>[] = [];

    if (clients.length === 0 || force) {
      // reqs.push(actions.getClientPoliciesNewEndpoint());
      reqs.push(actions.getClients(ssid.number));
    }
    if (!ssid || force) {
      reqs.push(actions.getSsids(networkId));
    }
    if (!useblocks || force) {
      reqs.push(actions.getSsidUseblocks(networkId, encryptedId, ssid.number, timespan));
    }
    if (!presenceSummaryData || force) {
      const { t0, t1 } = calculateTimespanForPresence(timespan);
      reqs.push(actions.getPresenceData(t0, t1, timespan));
      reqs.push(actions.getWirelessSettings(networkId));
    }

    if (reqs.length > 0) {
      try {
        await cancelablePromise(Promise.all(reqs));
      } catch (error) {
        showNetworkErrorWithRetry(() => this.getData(true));
      }
    }
  };

  deleteSsid = () => {
    const { navigation, actions, networkId, ssid } = this.props;
    this.setState({ reqPending: true });
    actions
      .deleteSsid(networkId, ssid.number)
      .then(() => {
        this.setState({ reqPending: false });
        navigation.goBack();
      })
      .catch(() => {
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        this.setState({ reqPending: false });
      });
  };

  displayShareScreen = () => {
    const { navigation, ssid } = this.props;

    navigation.navigate("SSIDShare", {
      name: ssid.name,
      encryptionMode: ssid.encryptionMode,
      passphrase: ssid.psk,
      ssid,
    });
  };

  showDeleteConfirmation = () => {
    showActionSheet([I18n.t("SSID_DETAILS.DELETE_NETWORK")], this.deleteSsid, {
      title: I18n.t("SSID_DETAILS.DELETE_CONFIRMATION"),
      destructiveButtonIndex: 0,
    });
  };

  saveName(name: string) {
    const { actions, networkId, ssid, ssids } = this.props;

    const otherSSIDs = ssids.filter((cssid) => cssid.number !== ssid.number);
    const error = validateName(name, otherSSIDs);
    if (error) {
      return Promise.reject(error);
    }
    return actions
      .setSsid(networkId, { ...ssid, name })
      .catch((err: unknown) => Promise.reject(err || I18n.t("SERVER_ERROR_TEXT")));
  }

  onPressToggleShowPW = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  renderPassword() {
    const { ssid } = this.props;
    if (ssid.authMode !== SSIDAuthMode.psk) {
      return null;
    }

    const renderToggleButton = <ShowHidePasswordButton onPress={this.onPressToggleShowPW} />;

    const lockIcon = (
      <MerakiIcon
        name="lock"
        size="s"
        color={MkiColors.secondaryTextColor}
        containerStyle={styles.lockIcon}
        testID="SSID_DETAILS.PSK_LOCK"
      />
    );
    const { showPassword } = this.state;
    const {
      ssid: { psk },
    } = this.props;

    return (
      <ListRow icon={lockIcon} rowStyles={styles.passwordContainer} accessory={renderToggleButton}>
        <HiddenPWText psk={psk} showPassword={showPassword} />
      </ListRow>
    );
  }

  renderApplications() {
    const { counterSets, ssid, ssidNumber, ssidUseblocksLoading, useblocks, navigation } =
      this.props;

    const rusage = nestedValueExists(useblocks, ["rusage", "19"], null);
    const rules = nestedValueExists(counterSets, ["19", "rules"], null);
    const onPressSeeAll = () =>
      navigation.navigate("ApplicationUsageList", {
        ssidNumber,
        ssidName: ssid.name,
      });

    const renderRow = (rowData: RenderedApplicationRow, rowID: number) => {
      const testID = `SSIDDetailsApplicationsCard - ${rowID}`;
      return rowData.disclosureRow ? (
        <DisclosureRow {...rowData} testID={testID} />
      ) : (
        <UsageRow {...rowData} testID={testID} />
      );
    };
    let applicationRows: RenderedApplicationRow[] = [];

    if (!ssidUseblocksLoading) {
      if (!rusage || !rules) {
        return null;
      }

      const applicationUsages = processRusage(rusage, rules, { noGroup: true });
      const totalUsage = applicationUsages.reduce((total, app) => total + app.totalUsage, 0);
      // remove the "Other" section under usages
      applicationRows = applicationUsages.slice(0, -1).map((usage) => ({
        children: usage.label,
        usage: usage.totalUsage,
        percent: usage.totalUsage / totalUsage,

        onPress: () => {
          if (!ssid.number) {
            errorMonitor.notify(
              "Trying to navigate to ApplicationUsageDetails with an undefined ssid number",
            );
            return;
          }
          navigation.navigate("ApplicationUsageDetails", {
            applicationId: nestedValueExists(usage, ["hiUse", "hiUseAppId"], {}),
            ssidName: ssid.name,
            ssidNumber: ssid.number,
          });
        },
      }));
    }

    // TODO: going from applications for this SSID to applications across the network
    // may be confusing? How do we want to show this information to the user?
    return (
      <LoadingView isLoading={ssidUseblocksLoading}>
        <View style={styles.container} testID="SSIDDetailsApplicationsCard">
          <IconSectionHeader
            icon="applications"
            header={I18n.t("HOME.APPLICATIONS.TITLE")}
            subtitle={I18n.t("HOME.APPLICATIONS.SUBTITLE")}
          />
          <TopItemsList<RenderedApplicationRow>
            customRenderRow={renderRow}
            items={applicationRows}
            maxItemsToDisplay={3}
            onPressSeeAll={onPressSeeAll}
            testID="SSIDDetailsApplicationsCard"
          />
        </View>
      </LoadingView>
    );
  }

  navigateToLocationAnalytics = (ssidName: string) => {
    const { navigation } = this.props;

    navigation.navigate("LocationAnalytics", {
      ssidName,
    });
  };

  presentAPAvailabilityModal = () => {
    const { ssid, navigation } = this.props;
    navigation.navigate("AccessPointAvailability", {
      ssidNumber: ssid.number,
    });
  };

  renderLocationAnalytics(
    presenceSummaryData: SummaryCardData | null,
    ssid: SSID,
    timespan: number,
  ) {
    if (!presenceSummaryData) {
      return null;
    }

    const ssidName = get(ssid, "name");
    const locationAnalyticsProps = getLocationAnalyticsProps(ssid, presenceSummaryData);

    return (
      <View style={styles.container}>
        <LocationAnalyticsSummaryCard
          {...locationAnalyticsProps}
          timespan={timespan}
          onPress={() => this.navigateToLocationAnalytics(ssidName)}
          context={Features.visitorInfo}
        />
      </View>
    );
  }

  render() {
    const {
      navigation,
      actions,
      isLoadingClients,
      ssid,
      presenceSummaryData,
      ssidNumber,
      timespan,
      isLocationAnalyticsEnabled,
    } = this.props;
    const { reqPending } = this.state;

    return (
      <FullScreenContainerView>
        <RefreshControlScrollView
          testID="SSIDDetailsScreen ScrollView"
          keyboardShouldPersistTaps="handled"
          refreshing={isLoadingClients}
          onRefresh={this.onRefresh}
        >
          <TouchableOpacity onPress={this.displayShareScreen}>
            <ShareSSIDNameHeader name={ssid.name} enabled={ssid.enabled} />
          </TouchableOpacity>
          {this.renderPassword()}
          <TimePicker timespan={timespan} setTimespan={actions.setTimespan} />
          <View style={styles.container} testID="SSIDDetailsDevicesCard">
            <IconSectionHeader
              icon="mobile"
              header={`${capitalizeFirstLetter(I18n.t("DEVICES_WORD"))}`}
              subtitle={I18n.t("HOME.DEVICES.SUBTITLE")}
              context={Features.deviceInfo}
            />
            <SsidClientUsageSummary
              navigate={navigation.navigate}
              ssidNumber={ssidNumber}
              filter={CUSTOM_FILTERS.SSID_CLIENTS(ssidNumber)}
              testID="SSIDClientUsageSummary"
            />
          </View>
          <BroadcastingAccessPointsCard
            ssidNumber={ssidNumber}
            onPress={this.presentAPAvailabilityModal}
          />
          {this.renderApplications()}
          {isLocationAnalyticsEnabled
            ? this.renderLocationAnalytics(presenceSummaryData, ssid, timespan)
            : null}
          <View style={styles.footerContainer}>
            <RoundedButton
              testID={"SSID_DELETE_NETWORK"}
              onPress={this.showDeleteConfirmation}
              buttonType={ButtonType.destructive}
              screenStyles={styles.button}
              disabled={reqPending}
            >
              {I18n.t("SSID_DETAILS.DELETE_NETWORK")}
            </RoundedButton>
          </View>
        </RefreshControlScrollView>
        <LoadingSpinner visible={reqPending} />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    margin: SPACING.default,
  },
  passwordContainer: {
    marginHorizontal: SPACING.default,
    paddingRight: SPACING.small,
    paddingLeft: SPACING.small,
  },
  lockIcon: {
    marginLeft: 6,
    marginRight: 10,
    alignSelf: "center",
  },
  footerContainer: {
    marginVertical: SPACING.large,
    marginHorizontal: SPACING.default,
  },
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SSIDDetails"],
): ReduxProps {
  return {
    encryptedId: errorMonitor.notifyNonOptional(
      "param 'encryptedId' undefined for SSIDDetailsScreen",
      encryptedNetworkIdSelector(state),
    ),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDDetailsScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsByIdSelector(state)[props.ssidNumber] || {},
    ssids: slimSsidsSelector(state),
    useblocks: ssidUseblocksState(state, props),
    counterSets: counterSetsState(state),
    timespan: timespanState(state),
    ssidUseblocksLoading: ssidUseblocksLoadingState(state),
    isLoadingClients: clientsLoadingState(state),
    isLocationAnalyticsEnabled: isLocationAnalyticsEnabled(state),
    clients: filteredClients(state, {
      customFilter: CUSTOM_FILTERS.SSID_CLIENTS(props.ssidNumber),
    }),
    // Update where timespan comes from after we figure out how we want to handle redux for compare
    presenceSummaryData: locationAnalyticsSummaryCardDataSelector(state, timespanState(state)),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
)(SSIDDetailsScreen);
