import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const ConfigDataSchema = z.object({
  roiArr: z.array(z.string()),
  last_alert: z.number(),
  sensitivity: z.number(),
  min_duration: z.number(),
  image_enabled: z.boolean(),
  people_enabled: z.boolean(),
});

export const MotionAlertConfigSchema = z
  .object({
    id: z.string(),
    type: z.string(),
    enabled: z.boolean(),
    created_at: z.date(),
    updated_at: z.date(),
    organization_id: z.string().nullable(),
    node_group_id: z.string().nullable(),
    schedule_id: z.string().nullable(),
    video_channel_id: z.string(),
    config_data: ConfigDataSchema,
  })
  .describe("MotionAlertConfigSchema");

export const MotionAlertConfigsSchema = z
  .array(MotionAlertConfigSchema)
  .describe("MotionAlertConfigsSchema");

export type MotionAlertConfig = z.infer<typeof MotionAlertConfigSchema>;

export type MotionAlertConfigs = z.infer<typeof MotionAlertConfigsSchema>;

export type ConfigData = z.infer<typeof ConfigDataSchema>;

interface MotionAlertPushSubscriptionRequest {
  eid?: string;
}

function buildUrl({ eid }: MotionAlertPushSubscriptionRequest) {
  return `/n/${eid}/manage/cameras/motion_alert_configs_json`;
}

function fetchMotionAlertConfigs({
  eid,
}: MotionAlertPushSubscriptionRequest): Promise<MotionAlertConfigs> {
  return request(MotionAlertConfigsSchema, "GET", buildUrl({ eid }));
}

export const useMotionAlertConfigs = createQuery<
  MotionAlertPushSubscriptionRequest,
  MotionAlertConfigs
>({
  baseQueryKey: buildUrl({ eid: "{eid}" }),
  queryFn: fetchMotionAlertConfigs,
});
