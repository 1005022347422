import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const VisionChannelSchema = z
  .object({
    cloud_proxy_livestream_path: z.string(),
    id: z.string(),
    livestream_playlist_filename: z.string(),
    local_lan_host: z.string().url(),
    local_lan_livestream_path: z.string(),
    motion_based_retention_rois: z.unknown().nullable(),
    singularity_host: z.string(),
    snapshot_url: z.string().url(),
  })
  .describe("VisionChannelSchema");

export const VisionConfigSchema = z
  .object({
    aperture: z.number(),
    bitrate: z.number(),
    brightness: z.number(),
    contrast: z.number(),
    crop: z.object({
      h: z.number(),
      w: z.number(),
      x: z.number(),
      y: z.number(),
    }),
    external_rtsp_enabled: z.nullable(z.boolean()),
    features: z
      .object({
        hasAttributeDetection: z.boolean(),
        hasCustomAnalytics: z.boolean(),
        hasPeopleDetection: z.boolean(),
        hasVehicleDetection: z.boolean(),
      })
      .optional(),
    focus: z.number(),
    fov_rotation: z.nullable(z.number()),
    frame_rate: z.number(),
    has_ir_led: z.boolean(),
    hdr: z.boolean(),
    id: z.string(),
    motion_recap_enabled: z.nullable(z.boolean()),
    mount_vertical: z.boolean(),
    pandora: z.object({
      artifactId: z.nullable(z.string()),
      isEnabled: z.nullable(z.boolean()),
      parameters: z.nullable(z.unknown()),
    }),
    record_audio: z.boolean(),
    rotation: z.number(),
    saturation: z.number(),
    updated_at: z.string(),
    video_height: z.number(),
    video_width: z.nullable(z.number()),
    zoom: z.number(),
  })
  .describe("VisionConfigSchema");

export const VisionCameraSchema = z
  .object({
    id: z.string(),
    network_id: z.string(),
    organization_id: z.string(),
    has_cloud_archive: z.boolean(),
    ip: z.string(),
    is_demo: z.boolean(),
    is_demo_v2: z.boolean(),
    local_lan_address: z.string().nullable().optional(),
    local_streaming_disabled: z.boolean(),
    mac: z.string(),
    model: z.string(),
    product_short_description: z.string(),
    name: z.string(),
    notes: z.string(),
    position: z.object({ lat: z.number(), lng: z.number() }).nullable(),
    serial: z.string(),
    status: z.string(),
    tags: z.array(z.string()),
    type: z.string(),
    channel: VisionChannelSchema,
    config: VisionConfigSchema,
    firmware_build: z.string(),
    firmware_version: z.string(),
  })
  .describe("VisionCameraSchema");

export const VisionCamerasSchema = z.array(VisionCameraSchema);

export type VisionCameras = z.infer<typeof VisionCamerasSchema>;

interface VisionCameraRequest {
  serial?: string;
  networkEid?: string;
}

const buildQueryParams = ({ serial }: VisionCameraRequest) => ({
  serial,
});

function buildUrl(networkEid: VisionCameraRequest["networkEid"]) {
  return `/n/${networkEid}/manage/vision/cameras`;
}

const fetchVisionCameras = (variables: VisionCameraRequest): Promise<VisionCameras> => {
  return request(VisionCamerasSchema, "GET", buildUrl(variables.networkEid), {
    queryParams: buildQueryParams(variables),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useVisionCameras = createQuery<VisionCameraRequest, VisionCameras, Error>({
  baseQueryKey: buildUrl("{networkEid}"),
  queryFn: (variables) => {
    return fetchVisionCameras(variables);
  },
  requiredVariables: ["networkEid"],
});

export default useVisionCameras;
