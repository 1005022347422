import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import withCodePush from "~/hocs/CodePush";
import { LoadingScreen as MagneticLoadingScreen } from "~/migrationZone/shared/nav/screens/LoadingScreen";
import MkiSpinner from "~/shared/components/MkiSpinner";
import { MkiText } from "~/shared/components/MkiText";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface LoadingScreenProps {
  actions?: (() => Promise<any>)[];
  promises?: (() => Promise<any>)[];
  execParallelActions?: boolean;
  execParallelPromises?: boolean;
  onFinishLoad?: () => void;
  onActionsError?: (error: Error) => void;
  onPromisesError?: (error: Error) => void;
  devMessage?: string;
}

const LoadingScreen = ({
  actions,
  execParallelActions,
  execParallelPromises,
  onFinishLoad,
  onActionsError,
  onPromisesError,
  promises,
  devMessage,
}: LoadingScreenProps) => {
  const colors = useThemeColors();
  const dispatch = useAppDispatch();
  const [pendingPromises, setPendingPromises] = useState({
    actions: true,
    promises: true,
  });

  useEffect(() => {
    const hasPendingPromises = Object.values(pendingPromises).some(Boolean);
    if (hasPendingPromises) {
      return;
    }

    onFinishLoad?.();
  }, [pendingPromises, onFinishLoad]);

  useEffect(() => {
    async function execActions() {
      if (actions?.length) {
        // @ts-ignore As of upgrading to react-native 0.71.4 action() seems to have the wrong type. This should be looked at eventually.
        const actionThunks = actions.map((action) => async () => await dispatch(action()));

        try {
          if (execParallelActions) {
            await Promise.all(actionThunks.map((thunk) => thunk()));
          } else {
            for (const thunk of actionThunks) {
              await thunk();
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            onActionsError?.(error);
          }
        }
      }

      setPendingPromises((prevPendingPromises) => ({
        ...prevPendingPromises,
        actions: false,
      }));
    }

    async function execPromises() {
      if (promises) {
        try {
          if (execParallelPromises) {
            await Promise.all(promises.map((promise) => promise()));
          } else {
            for (const promise of promises) {
              await promise();
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            onPromisesError?.(error);
          }
        }
      }

      setPendingPromises((prevPendingPromises) => ({
        ...prevPendingPromises,
        promises: false,
      }));
    }

    execActions();
    execPromises();
  }, [
    actions,
    dispatch,
    execParallelActions,
    execParallelPromises,
    onActionsError,
    onPromisesError,
    promises,
  ]);

  return (
    <View style={[styles.fullScreen, { backgroundColor: colors.navigation.backgroundPrimary }]}>
      <MkiSpinner animating />
      {__DEV__ && devMessage && <MkiText>DEBUG: {devMessage}</MkiText>}
    </View>
  );
};

const styles = StyleSheet.create({
  fullScreen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const ExistingLoadingScreen = withCodePush(LoadingScreen);

// TODO: fix types
export default withMagneticReplacementAdapter<any>(ExistingLoadingScreen, MagneticLoadingScreen);
