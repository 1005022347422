import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  ApplianceSettings,
  useApplianceSettings,
  useUpdateApplianceSettings,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";

type EditPrefixForm = {
  prefix: string;
};
interface DynamicDnsBottomSheetProps {
  applianceSettings?: ApplianceSettings;
  onClose: () => void;
}

export const DynamicDnsBottomSheet = ({
  applianceSettings,
  onClose,
}: DynamicDnsBottomSheetProps) => {
  const networkId = useCurrentNetworkId();

  const { refetch: refetchSettings, isRefetching: settingsAreRefetching } = useApplianceSettings({
    networkId,
  });

  const { mutate: updateSettings, isLoading: isUpdating } = useUpdateApplianceSettings();

  const saveIsLoading = settingsAreRefetching || isUpdating;

  const methods = useForm<EditPrefixForm>({
    values: {
      prefix: applianceSettings?.dynamicDns.prefix ?? "",
    },
  });

  if (!applianceSettings) {
    return <Loader.Spinner animate={true} testID="LOADING_SPINNER" />;
  }

  const donePress = () => {
    methods.reset();
    onClose();
  };

  const onSave = () => {
    updateSettings(
      {
        networkId,
        body: {
          ...applianceSettings,
          dynamicDns: { enabled: true, prefix: methods.getValues("prefix") },
        },
      },
      {
        async onSuccess() {
          await refetchSettings();
          onClose();
        },
        onError(error) {
          if (error !== null && typeof error === "object" && "errors" in error) {
            showErrorAlert(String(error["errors"]));
          }
        },
      },
    );
  };

  const onSubmit = methods.handleSubmit(onSave);

  return (
    <>
      <BottomSheet.Header
        title={I18n.t("HARDWARE_SETTINGS.DYNAMIC_DNS.TITLE")}
        onResetPress={donePress}
        resetLabel={I18n.t("RESET")}
        cancelLabel={I18n.t("CANCEL")}
        onCancelPress={onClose}
      />
      <BottomSheet.Content>
        <Box gap="sm">
          <Form {...methods}>
            <Form.Input
              rules={{ required: I18n.t("HARDWARE_SETTINGS.DYNAMIC_DNS.GOv3.PREFIX_EMPTY_ERROR") }}
              name={"prefix"}
              label={I18n.t("HARDWARE_SETTINGS.DYNAMIC_DNS.GOv3.INPUT_LABEL")}
              showClear={false}
              testID="DNS_PREFIX_INPUT"
            />
          </Form>
          <Button
            text={I18n.t("SAVE")}
            onPress={onSubmit}
            disabled={!methods.formState.isDirty}
            loading={saveIsLoading}
            testID="SAVE_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </>
  );
};
