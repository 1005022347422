import { defaultTimespans, DefaultTimespansKeys } from "@meraki/shared/redux";
import { get, isEmpty } from "lodash";

import {
  getAverageLengthSummaryLabel,
  getCaptureRateSummaryLabel,
  getNumberVisitorsSummaryLabel,
  getNumNewVisitorsSummaryLabel,
  getReturningVisitorsSummaryLabel,
  SUMMARY_CARD_DATA_KEY,
} from "~/constants/LocationAnalyticsConstants";
import { SECONDS_IN_AN_HOUR, TIMESTAMP_KEY, UTC_TIMEZONE } from "~/constants/MkiConstants";
import {
  EngagementGraphDataPoint,
  EngagementSummaryData,
  LoyaltyGraphDataPoint,
  LoyaltySummaryData,
  ProximityGraphDataPoint,
  ProximitySummaryData,
  SummaryCardData,
  SummaryCardSummaryData,
  SummaryValue,
  TimeSeriesDataPoint,
  TranslatedGraphData,
} from "~/go/types/LocationAnalyticsTypes";
import I18n from "~/i18n/i18n";
import { chartDateFormatter } from "~/lib/formatHelper";
import {
  addTimespanToDate,
  getEndOfYesterdayAsDate,
  subtractTimespanFromDate,
} from "~/lib/timeHelper";
import type { MkiLegendElementProps } from "~/shared/components/MkiLegendElement";
import {
  ColorOptions,
  DataKey,
  DataKeyColors,
  SelectedElements,
} from "~/shared/types/MkiChartTypes";
import { SSID } from "~/shared/types/Models";
import { DateRange } from "~/shared/types/TimeTypes";

export function updateSelectedElements(
  selectedElements: SelectedElements,
  legendElements: MkiLegendElementProps[],
  label: string,
): SelectedElements {
  const newSelectedElements =
    selectedElements.size == legendElements.length
      ? new Set<string>()
      : new Set<string>(selectedElements);
  newSelectedElements.has(label)
    ? newSelectedElements.delete(label)
    : newSelectedElements.add(label);
  return newSelectedElements.size == 0
    ? new Set<string>(legendElements.map((elem) => elem.label))
    : newSelectedElements;
}

export const getTimespanFromData = (data: TimeSeriesDataPoint[], provideSpacing = false) => {
  if (isEmpty(data)) {
    return undefined;
  }
  const firstDataPoint = data[0];
  const lastDataPoint = data[data.length - 1];

  const dataDelta =
    data.length > 2
      ? (lastDataPoint.timestamp - firstDataPoint.timestamp) / data.length
      : SECONDS_IN_AN_HOUR;
  return [firstDataPoint.timestamp, lastDataPoint.timestamp + (provideSpacing ? dataDelta : 0)];
};

export const makeDateRangeValid = (
  dateRange: DateRange,
  comparisonTimespan: DefaultTimespansKeys,
) => {
  const timespanLength = defaultTimespans[comparisonTimespan].value;
  const { startDate, endDate } = dateRange;
  const shiftedEndDate = addTimespanToDate(startDate, timespanLength);
  if (shiftedEndDate.valueOf() > getEndOfYesterdayAsDate().valueOf()) {
    const shiftedStartDate = subtractTimespanFromDate(endDate, timespanLength);
    return { startDate: shiftedStartDate, endDate };
  } else {
    return { startDate, endDate: shiftedEndDate };
  }
};

export const isDateRangeEmpty = (dateRange: DateRange | undefined): boolean => {
  return !dateRange || !dateRange.startDate || !dateRange.endDate;
};

export const checkIfDateRangesDiffer = (
  dateRange: DateRange,
  secondDateRange: DateRange,
): boolean => {
  const dateRangeEmpty = isDateRangeEmpty(dateRange);
  const secondDateRangeEmpty = isDateRangeEmpty(secondDateRange);

  if (dateRangeEmpty && secondDateRangeEmpty) {
    return false;
  }
  if (dateRangeEmpty !== secondDateRangeEmpty) {
    return true;
  }

  const startDateDiffers = dateRange.startDate.valueOf() !== secondDateRange.startDate.valueOf();
  const endDateDiffers = dateRange.endDate.valueOf() !== secondDateRange.endDate.valueOf();
  return startDateDiffers || endDateDiffers;
};

export const labelProximitySummaryData = (
  summaryData: ProximitySummaryData,
): SummaryValue[] | undefined => {
  if (!summaryData) {
    return undefined;
  }
  const { numVisitors, captureRate } = summaryData;
  return [
    {
      ...getNumberVisitorsSummaryLabel(),
      value: numVisitors,
    },
    {
      ...getCaptureRateSummaryLabel(),
      value: captureRate,
    },
  ];
};

export const labelEngagementSummaryData = (
  summaryData: EngagementSummaryData,
): SummaryValue[] | undefined => {
  if (!summaryData) {
    return undefined;
  }
  const { averageLength } = summaryData;
  return [
    {
      ...getAverageLengthSummaryLabel(),
      value: averageLength,
    },
  ];
};

export const labelLoyaltySummaryData = (
  summaryData: LoyaltySummaryData,
): SummaryValue[] | undefined => {
  if (!summaryData) {
    return undefined;
  }
  const { numNewVisitors, returningRate } = summaryData;
  return [
    {
      ...getNumNewVisitorsSummaryLabel(),
      value: numNewVisitors,
    },
    {
      ...getReturningVisitorsSummaryLabel(),
      value: returningRate,
    },
  ];
};

export const labelSummaryCardSummaryData = (
  summaryData: SummaryCardSummaryData,
): SummaryValue[] | undefined => {
  if (!summaryData) {
    return undefined;
  }
  return [
    {
      ...getNumberVisitorsSummaryLabel(),
      value: summaryData.numVisitors,
    },
    {
      ...getAverageLengthSummaryLabel(),
      value: summaryData.averageLength,
    },
  ];
};

export const translateProximityData = (
  graphData: ProximityGraphDataPoint[],
): TranslatedGraphData | undefined => {
  if (!graphData) {
    return undefined;
  }
  return graphData.map((dataPoint) => {
    const { timestamp, connected, passerby, visitors } = dataPoint;
    return {
      [TIMESTAMP_KEY]: timestamp,
      [I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.CONNECTED")]: connected,
      [I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.PASSERSBY")]: passerby,
      [I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.VISITORS")]: visitors,
    };
  });
};

export const translateEngagementData = (
  graphData: EngagementGraphDataPoint[],
): TranslatedGraphData | undefined => {
  if (!graphData) {
    return undefined;
  }
  return graphData.map((dataPoint) => {
    const { timestamp, fiveToTwentyMin, twentyToSixtyMin, oneHrToSixHrs, sixPlusHrs } = dataPoint;
    return {
      [TIMESTAMP_KEY]: timestamp,
      [I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.FIVE_TWENTY_MIN")]: fiveToTwentyMin,
      [I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.TWENTY_SIXTY_MIN")]: twentyToSixtyMin,
      [I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.ONE_SIX_HRS")]: oneHrToSixHrs,
      [I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.SIX_PLUS_HRS")]: sixPlusHrs,
    };
  });
};

export const translateLoyaltyData = (
  graphData: LoyaltyGraphDataPoint[],
): TranslatedGraphData | undefined => {
  if (!graphData) {
    return undefined;
  }
  return graphData.map((dataPoint) => {
    const { timestamp, daily, weekly, occasionally, firstTime } = dataPoint;
    return {
      [TIMESTAMP_KEY]: timestamp,
      [I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.DAILY")]: daily,
      [I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.WEEKLY")]: weekly,
      [I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.OCCASIONAL")]: occasionally,
      [I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.FIRST_TIME")]: firstTime,
    };
  });
};

export const generateColoredDataKeys = (
  dataKeys: DataKey[],
  dataKeyColors: DataKeyColors[],
  colorType: ColorOptions = ColorOptions.standard,
) => {
  return dataKeys.map((dataKey, idx) => {
    return { ...dataKey, color: dataKeyColors[idx][colorType] };
  });
};

// The x-axis for Location Analytics graphs is formatted in UTC time due to the fact that
// the backend handles all of the timestamped data as if it occurred at the same time UTC
// (i.e. if an event occurs at 9:00AM local time, it is recorded as if it occured at 9:00 UTC).
// So the data we receive from the /presence endpoint is in UTC time even though it occured
// at the correct local time. For more discussions on why this is done, see:
// https://docs.ikarem.io/display/EngBackendTeam/Location+Analytics+Handoff+Document
export const getChartXAxisFormatter = (timespan: any) => (t: any) =>
  chartDateFormatter(t, timespan, UTC_TIMEZONE);

export const getLocationAnalyticsProps = (ssid: SSID, presenceSummaryData: SummaryCardData) => {
  const { summaryData, graphData, domain } = presenceSummaryData;
  const labeledSummaryData = labelSummaryCardSummaryData(summaryData);
  const ssidName = get(ssid, "name", "");
  return {
    summaryData: labeledSummaryData,
    graphData,
    domain,
    ssidName,
    dataKey: SUMMARY_CARD_DATA_KEY,
  };
};
