import { BaseDeviceSubProps } from "../types/BaseSubProps";
import DataMsg from "../types/DataMsg";

export type ReadImageSettingsProps = BaseDeviceSubProps;

interface ImageSettings {
  aperture: number;
  crop_x: number;
  crop_y: number;
  crop_w: number;
  crop_h: number;
  focus: number;
  hdr: boolean;
  overlay_rectangles: Record<string, unknown> | { overlay_rectanges: unknown[] };
  rotation: number;
  zoom: number;
}

interface ReadImageSettingsSuccessDataMessage {
  image_settings: ImageSettings;
}

interface ReadImageSettingsFailureDataMessage {
  failed: true | "Unable to reach camera";
  unsupportedFirmware: boolean;
}

export interface ReadImageSettingsMessage extends DataMsg {
  data: ReadImageSettingsSuccessDataMessage | ReadImageSettingsFailureDataMessage;
}

export type ReadImageSettingsSuccessResult = {
  aperture: ImageSettings["aperture"];
  cropX: ImageSettings["crop_x"];
  cropY: ImageSettings["crop_y"];
  cropW: ImageSettings["crop_w"];
  cropH: ImageSettings["crop_h"];
  focus: ImageSettings["focus"];
  hdr: ImageSettings["hdr"];
  overlayRectanges: ImageSettings["overlay_rectangles"];
  rotation: ImageSettings["rotation"];
  zoom: ImageSettings["zoom"];
};

export type ReadImageSettingsResult =
  | ReadImageSettingsSuccessResult
  | ReadImageSettingsFailureDataMessage
  | undefined;

export function formatReadImageSettingsMessage(
  message?: ReadImageSettingsMessage,
): ReadImageSettingsResult {
  const data = message?.data;

  if (!data) {
    return undefined;
  }

  if ("failed" in data) {
    return data;
  }

  const {
    aperture,
    crop_x,
    crop_y,
    crop_w,
    crop_h,
    hdr,
    focus,
    overlay_rectangles,
    rotation,
    zoom,
  } = data.image_settings;

  return {
    aperture,
    cropX: crop_x,
    cropY: crop_y,
    cropW: crop_w,
    cropH: crop_h,
    hdr,
    focus,
    overlayRectanges: overlay_rectangles,
    rotation,
    zoom,
  };
}
