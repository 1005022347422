import { memo } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader, { DefaultHeaderProps } from "~/go/components/DefaultHeader";

export interface FormSectionProps extends DefaultHeaderProps {
  children: JSX.Element[] | JSX.Element;
}

const FormSection: React.FC<FormSectionProps> = (props: FormSectionProps) => {
  const { children, description, title } = props;

  return (
    <View style={styles.container}>
      <DefaultHeader {...{ title, description }} style={styles.header} />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: SPACING.default,
  },
  header: {
    marginHorizontal: 0,
  },
});

export default memo(FormSection);
