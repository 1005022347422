import Svg, { Path, Rect } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function ChevronLeftIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Rect
        x="24"
        width="24"
        height="24"
        rx="12"
        transform="rotate(90 24 0)"
        fill="#494A4A"
        fillOpacity="0.4"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8466 5.77838C15.1761 6.10788 15.1761 6.64212 14.8466 6.97162L9.81824 12L14.8466 17.0284C15.1761 17.3579 15.1761 17.8921 14.8466 18.2216C14.5171 18.5511 13.9829 18.5511 13.6534 18.2216L8.02838 12.5966C7.69887 12.2671 7.69887 11.7329 8.02838 11.4034L13.6534 5.77838C13.9829 5.44887 14.5171 5.44887 14.8466 5.77838Z"
        fill="white"
      />
    </Svg>
  );
}
export default ChevronLeftIcon;
