import {
  CLEAR_VERIFIED_EMAIL,
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESEND_CONFIRM_EMAIL_FAILURE,
  RESEND_CONFIRM_EMAIL_REQUEST,
  RESEND_CONFIRM_EMAIL_SUCCESS,
  RESEND_VERIFY_EMAIL_FAILURE,
  RESEND_VERIFY_EMAIL_REQUEST,
  RESEND_VERIFY_EMAIL_SUCCESS,
  SEND_HARDWARE_MODELS_TO_MARKETO_FAILURE,
  SEND_HARDWARE_MODELS_TO_MARKETO_REQUEST,
  SEND_HARDWARE_MODELS_TO_MARKETO_SUCCESS,
  SEND_TO_MARKETO_FAILURE,
  SEND_TO_MARKETO_REQUEST,
  SEND_TO_MARKETO_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { COUNTRIES } from "~/constants/MkiConstants";
import { RegistrationForm } from "~/go/types/RegistrationTypes";
import { makeFormBody } from "~/lib/RequestUtils";
import { ApiAction, CALL_API } from "~/middleware/api";
import { URLPrepends } from "~/shared/types/Networks";
import { Method } from "~/shared/types/RequestTypes";

function getToken(url: string) {
  return new URL(url).searchParams.get("token");
}

export function register(registrationData: RegistrationForm): ApiAction {
  const formBody = makeFormBody(registrationData);

  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE],
      endpoint: "/go/app_signup",
      prepend: URLPrepends.portal,
      config: {
        noHeaderFill: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          "Meraki-Go-Proxy-Region": COUNTRIES[registrationData.country].region,
        },
        method: "POST",
        body: formBody,
      },
    },
  };
}

export function sendToMarketo(
  registrationData: RegistrationForm,
  marketoToken: string,
  forceCreate = false,
) {
  const marketoLead = {
    Email: registrationData.email,
    FirstName: registrationData.first_name,
    LastName: registrationData.last_name,
    Industry: registrationData.industry,
    Company: registrationData.company,
    companySizeGoTemp: registrationData.company_size,
    MarketingOptinRequest: registrationData.tips,
    consentCountry: registrationData.country,
    goStatusTemp: "User",
  };
  const marketoBody = {
    action: forceCreate ? "createOnly" : "createOrUpdate",
    lookupField: "email",
    input: [marketoLead],
  };

  return {
    [CALL_API]: {
      types: [SEND_TO_MARKETO_REQUEST, SEND_TO_MARKETO_SUCCESS, SEND_TO_MARKETO_FAILURE],
      prepend: URLPrepends.marketo,
      endpoint: "/rest/v1/leads.json",
      config: {
        noHeaderFill: true,
        headers: {
          Authorization: `bearer ${marketoToken}`,
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "*/*",
        },
        body: JSON.stringify(marketoBody),
        method: Method.post,
      },
    },
  };
}

export function verifyEmail(url: string): ApiAction {
  return {
    [CALL_API]: {
      types: [VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE],
      endpoint: "/go/verify_email",
      prepend: URLPrepends.portal,
      config: {
        method: Method.get,
        queryParams: { token: getToken(url) },
        headers: {
          Accept: "application/json",
        },
      },
    },
  };
}

export function clearVerifiedEmail() {
  return {
    type: CLEAR_VERIFIED_EMAIL,
  };
}

export function resendVerificationEmail(url: string) {
  return wrapApiActionWithCSRF({
    types: [RESEND_VERIFY_EMAIL_REQUEST, RESEND_VERIFY_EMAIL_SUCCESS, RESEND_VERIFY_EMAIL_FAILURE],
    endpoint: "/go/resend_verify_email",
    prepend: URLPrepends.portal,
    config: {
      method: Method.post,
      queryParams: { token: getToken(url) },
    },
  });
}

export function confirmEmail(url: string): ApiAction {
  return {
    [CALL_API]: {
      types: [CONFIRM_EMAIL_REQUEST, CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAILURE],
      endpoint: "/go/confirm_email_change",
      prepend: URLPrepends.portal,
      config: {
        method: Method.get,
        queryParams: { token: getToken(url) },
        headers: {
          Accept: "application/json",
        },
      },
    },
  };
}

export function resendConfirmationEmail(url: string) {
  return wrapApiActionWithCSRF({
    types: [
      RESEND_CONFIRM_EMAIL_REQUEST,
      RESEND_CONFIRM_EMAIL_SUCCESS,
      RESEND_CONFIRM_EMAIL_FAILURE,
    ],
    endpoint: "/go/resend_confirm_email_change",
    prepend: URLPrepends.portal,
    config: {
      method: Method.post,
      queryParams: { token: getToken(url) },
      headers: {
        Accept: "application/json",
      },
    },
  });
}

export function fetchResetPasswordShard(url: string): ApiAction {
  return {
    [CALL_API]: {
      types: [PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE],
      endpoint: "/go/password_reset",
      prepend: URLPrepends.portal,
      config: {
        method: Method.get,
        queryParams: { token: getToken(url) },
        headers: {
          Accept: "application/json",
        },
      },
    },
  };
}

export function resetPassword(url: string, password: string, password_confirmation: string) {
  return wrapApiActionWithCSRF({
    types: [PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE],
    endpoint: "/go/password_reset",
    config: {
      method: Method.post,
      queryParams: { token: getToken(url) },
      body: JSON.stringify({
        password,
        password_confirmation,
      }),
      headers: {
        Accept: "application/json",
      },
    },
  });
}

export function sendHardwareModelsToMarketo() {
  return wrapApiActionWithCSRF({
    types: [
      SEND_HARDWARE_MODELS_TO_MARKETO_SUCCESS,
      SEND_HARDWARE_MODELS_TO_MARKETO_REQUEST,
      SEND_HARDWARE_MODELS_TO_MARKETO_FAILURE,
    ],
    endpoint: "/mobile/send_hardware_models_to_marketo",
    config: {
      method: Method.post,
    },
  });
}
