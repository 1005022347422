import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { VictoryDatableProps } from "victory-native";

import { CHART_Y_AXIS_FORMAT } from "~/constants/LocationAnalyticsConstants";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import IconSectionHeader from "~/go/components/IconSectionHeader";
import { SummaryValue } from "~/go/types/LocationAnalyticsTypes";
import I18n from "~/i18n/i18n";
import { getChartXAxisFormatter } from "~/lib/LocationAnalyticsUtils";
import { defineStyleKey, themeColors } from "~/lib/themeHelper";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiStackedBarChart from "~/shared/components/MkiStackedBarChart";
import MkiText from "~/shared/components/MkiText";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import { DataKey, Domain } from "~/shared/types/MkiChartTypes";

export interface LocationAnalyticsSummaryCardProps {
  summaryData?: SummaryValue[];
  graphData?: VictoryDatableProps["data"];
  dataKey: DataKey;
  domain: Domain;
  timespan: number;
  loading?: boolean;
  onHomeScreen?: boolean;
  context?: string;
  onPress: () => void;
}

export class LocationAnalyticsSummaryCard extends PureComponent<LocationAnalyticsSummaryCardProps> {
  renderLoading = () => (
    <View style={styles.loadingContainer}>
      <MkiSpinner />
    </View>
  );

  renderHeader() {
    const { onHomeScreen, context } = this.props;
    return !onHomeScreen ? (
      <IconSectionHeader
        icon="people"
        header={I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.HEADING")}
        subtitle={I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.SUBHEADING")}
        context={context}
      />
    ) : null;
  }

  renderSummaryData() {
    const { summaryData, onPress } = this.props;

    const statusBoxes = summaryData
      ? summaryData.map((summaryValue, idx) => {
          const { label, value, units } = summaryValue;
          return (
            <QuickStatusBox
              testID={`locationAnalyticsSummaryValue - ${idx}`}
              key={label}
              value={value}
              category={units}
              subText={label}
              onPress={onPress}
            />
          );
        })
      : null;
    return <View style={styles.summaryContainer}>{statusBoxes}</View>;
  }

  renderPopularTimesGraph() {
    const { graphData, dataKey, domain, timespan, onPress } = this.props;
    return (
      <TouchableOpacity onPress={onPress}>
        <MkiStackedBarChart
          pointerEvents="none"
          data={graphData}
          dataKeys={[dataKey]}
          domain={domain}
          xAxisFormatter={getChartXAxisFormatter(timespan)}
          yAxisFormatter={CHART_Y_AXIS_FORMAT}
        />
      </TouchableOpacity>
    );
  }

  render() {
    const { loading, onHomeScreen } = this.props;
    const { theme } = useTheme.getState();
    if (loading) {
      return this.renderLoading();
    }

    const containerStyle = [
      styles.container,
      defineStyleKey("borderBottomColor", themeColors(theme).border.borderColor),
      { borderBottomWidth: !onHomeScreen ? StyleSheet.hairlineWidth : 0 },
    ];

    return (
      <View testID="LocationAnalyticsSummaryCard" style={containerStyle}>
        {this.renderHeader()}
        {this.renderSummaryData()}
        <MkiText textStyle="subheading" screenStyles={styles.graphTitle}>
          {I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.GRAPH_TITLE")}
        </MkiText>
        {this.renderPopularTimesGraph()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: SPACING.default,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  summaryContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
  graphTitle: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.small,
  },
});

export default LocationAnalyticsSummaryCard;
