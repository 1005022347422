import { I18n } from "@meraki/core/i18n";
import { verifyEmail } from "@meraki/shared/api";
import SimpleToast from "react-native-simple-toast";

export const handleVerifyEmailUrl = async ({ url }: { url: string }) => {
  const [uri, params] = url.split("?");
  const verifyEmailRegex = /https:\/\/link\.meraki\.com\/([0-9]+)\/login\/confirm_email/;

  if (uri?.match(verifyEmailRegex)) {
    const key = params?.split("=")[1];
    if (key) {
      try {
        await verifyEmail({ key });
        SimpleToast.showWithGravity(
          I18n.t("VERIFY_EMAIL.SUCCESS"),
          SimpleToast.SHORT,
          SimpleToast.TOP,
        );
      } catch (e) {
        SimpleToast.showWithGravity(
          I18n.t("VERIFY_EMAIL.FAILURE"),
          SimpleToast.SHORT,
          SimpleToast.TOP,
        );
      }
    }
  }
};
