// @ts-expect-error TS(7016): Could not find a declaration file for module 'http... Remove this comment to see the full error message
import LinkHeader from "http-link-header";

export interface LinkHeader {
  first?: string;
  next?: string;
  prev?: string;
  last?: string;
}

interface LinkHeaderRel {
  uri: string;
  rel: string;
}

export function parseLinkHeader(headers: Headers): LinkHeader {
  const linkHeader = headers.get("link");

  if (linkHeader == null) {
    return {};
  }

  const parsedLinkHeader = LinkHeader.parse(linkHeader)?.refs?.reduce(
    (allLinks: LinkHeader, link: LinkHeaderRel) => ({
      ...allLinks,
      [link.rel]: link.uri,
    }),
    {},
  );

  return parsedLinkHeader;
}
