import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Loader } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { RouteProp, useRoute } from "@react-navigation/native";
import { WebView } from "react-native-webview";

export function ClickThroughPreviewScreen() {
  const route = useRoute<RouteProp<SsidGroupProps, "ClickThroughPreview">>();
  const { params: props } = route;
  const { previewURL } = props;

  return (
    <Screen.View>
      <WebView
        startInLoadingState
        source={{ uri: previewURL }}
        originWhitelist={["*"]}
        renderLoading={() => <Loader.Spinner animate />}
        testID="CLICK_THROUGH_PREVIEW_WEBVIEW"
      />
    </Screen.View>
  );
}
