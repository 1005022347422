import { z } from "zod";

import { constructZodUnionFromArray } from "../../../util/zod";

export const MODES = [
  "captcha",
  "signup",
  "two_factor",
  "sms",
  "one_time_password",
  // todo: we should get rid of two_factor_onboarding because it is not a real mode,
  // but used on Go to launch in to setting up 2FA, and shoudln't be part of the auth flow
  "two_factor_onboarding",
] as const;

export type AuthTypes = (typeof MODES)[number];

export const OrgChooseResponse = z.object({
  mode: z.literal("org_choose"),
  eid: z.string(),
  orgs: z.array(
    z.object({
      eid: z.string(),
      id: z.string(),
      name: z.string(),
      two_factor_auth_enabled: z.boolean(),
    }),
  ),
});

const ModesResponse = z.object({
  mode: constructZodUnionFromArray([...MODES]),
  error: z.string().optional(),
});

const LoginDetailsResponse = z.object({
  success: z.boolean(),
  org_eid: z.string(),
  user: z.object({
    id: z.string(),
    path: z.string(),
  }),
});

export const LoginResponse = z
  .union([LoginDetailsResponse, ModesResponse, OrgChooseResponse])
  .describe("LoginResponseSchema");

export type ModesResponseType = z.infer<typeof ModesResponse>;
export type LoginDetailsResponseType = z.infer<typeof LoginDetailsResponse>;
export type OrgChooseResponseType = z.infer<typeof OrgChooseResponse>;
export type LoginResponseType =
  | LoginDetailsResponseType
  | ModesResponseType
  | OrgChooseResponseType;
