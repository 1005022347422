import { I18n } from "@meraki/core/i18n";
import { Tag } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";

const BAR_HEIGHT = 4;

type SignalBarProps = {
  num: number;
  bars: number;
};
function SignalBar({ num, bars }: SignalBarProps) {
  return (
    <Box
      backgroundColor="default.bg.strong.base"
      width={5}
      height={num <= bars ? BAR_HEIGHT * num : 1}
    />
  );
}

function getStatusFromBars(bars: number) {
  switch (bars) {
    case 1:
      return "negative";
    case 2:
      return "warning";
    case 3:
    case 4:
    case 5:
      return "positive";
    default:
      return "inactive";
  }
}

function getStatusMessageFromBars(bars: number) {
  switch (bars) {
    case 1:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_POOR");
    case 2:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_OK");
    case 3:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_GOOD");
    case 4:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_GREAT");
    case 5:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_EXCELLENT");
    default:
      return I18n.t("CELLULAR_GATEWAY_UPLINK.STATUS_NO_SIGNAL");
  }
}

export type SignalBarsProps = {
  bars: number;
};

export function SignalBars({ bars }: SignalBarsProps) {
  return (
    <Box flexDirection="row" gap="xs" alignItems="flex-end">
      <Tag type="status" status={getStatusFromBars(bars)}>
        {getStatusMessageFromBars(bars)}
      </Tag>
      <Box flexDirection="row" alignItems="flex-end" height={BAR_HEIGHT * 5} marginBottom="2xs">
        {[1, 2, 3, 4, 5].map((bar) => (
          <SignalBar num={bar} bars={bars} />
        ))}
      </Box>
    </Box>
  );
}
