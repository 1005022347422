import { I18n } from "@meraki/core/i18n";
import { Checkbox, List } from "@meraki/magnetic/components";
import { useConfiguredSsids } from "@meraki/shared/api";
import { ALL_SSIDS, useCurrentNetworkId } from "@meraki/shared/redux";
import { useController } from "react-hook-form";

export interface SSIDRowListForm {
  ssidNumbers: number[];
}

export const SSIDRowList = () => {
  const {
    field: { value, onChange },
  } = useController<SSIDRowListForm>({ name: "ssidNumbers" });

  const networkId = useCurrentNetworkId();
  const { data: availableSSIDs, isLoading: SSIDsLoading } = useConfiguredSsids({ networkId });

  if (!(Array.isArray(value) && Array.isArray(availableSSIDs))) {
    return null;
  }

  const selectedSSIDs = new Set(value);
  const updateSelectedSSID = (ssidNumber: number) => {
    if (ssidNumber === ALL_SSIDS) {
      if (selectedSSIDs.size === availableSSIDs.length) {
        onChange([]);
      } else {
        onChange(availableSSIDs.map(({ number }) => number));
      }
    } else {
      if (selectedSSIDs.has(ssidNumber)) {
        selectedSSIDs.delete(ssidNumber);
      } else {
        selectedSSIDs.add(ssidNumber);
      }

      onChange(Array.from(selectedSSIDs));
    }
  };
  const renderSSIDRows =
    availableSSIDs.map((ssid) => {
      return (
        <List.Item
          title={ssid.name}
          leftAccessory={
            <Checkbox
              checked={selectedSSIDs.has(ssid.number)}
              onValueChange={() => updateSelectedSSID(ssid.number)}
              testID={`NETWORK_ROW.${ssid.number}.CHECKBOX.${
                selectedSSIDs.has(ssid.number) ? "SELECTED" : "UNSELECTED"
              }`}
            />
          }
          onPress={() => updateSelectedSSID(ssid.number)}
          testID={`NETWORK_ROW.${ssid.number}`}
          key={`NETWORK_ROW.${ssid.number}`}
          hidePressable
        />
      );
    }) ?? [];

  if (availableSSIDs.length > 1) {
    renderSSIDRows.unshift(
      <List.Item
        title={I18n.t("SSIDS_ROW_LIST.ALL_SSIDS", { ssidTotal: availableSSIDs.length })}
        leftAccessory={
          <Checkbox
            checked={selectedSSIDs.size === availableSSIDs.length}
            onValueChange={() => updateSelectedSSID(ALL_SSIDS)}
            testID="SELECT_ALL_CHECKBOX"
          />
        }
        onPress={() => updateSelectedSSID(ALL_SSIDS)}
        testID={`NETWORK_ROW.SELECT_ALL`}
        key={`NETWORK_ROW.${ALL_SSIDS}`}
        hidePressable
      />,
    );
  }
  return (
    <List label={I18n.t("SSIDS_ROW_LIST.TITLE")} loading={SSIDsLoading}>
      {renderSSIDRows}
    </List>
  );
};
