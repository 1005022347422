import { I18n } from "@meraki/core/i18n";
import { DefaultTabStack as LibDefaultTabStack } from "@meraki/go/navigation";
import { useMagneticDesignSystem } from "@meraki/magnetic/adapter";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { isWeb } from "~/lib/PlatformUtils";
import { getBottomTabConfig } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

import HardwareStack from "./HardwareStack";
import HealthStack from "./HealthStack";
import HomeStack from "./HomeStack";
import NetworksStack from "./NetworksStack";
import SettingsStack from "./SettingsStack";
import TabBarIcon from "./TabBarIcon";

const RouteNameToPathName = {
  HomeStack: "home",
  NetworksStack: "networks",
  HardwareStack: "hardware",
  HealthStack: "health",
  SettingsStack: "settings",
};

const Tab = createBottomTabNavigator();

const DefaultTabStack = () => {
  const magneticTheming = useMagneticDesignSystem();
  const bottomTabBarConfig = useAppSelector(getBottomTabConfig);

  if (magneticTheming) {
    return <LibDefaultTabStack />;
  }

  return (
    <Tab.Navigator
      screenOptions={({ route }) => {
        const hideBottomTabBar = bottomTabBarConfig.visibility === "hidden" || isWeb();

        return {
          headerShown: false,
          tabBarStyle: { display: hideBottomTabBar ? "none" : "flex" },
          tabBarIcon: TabBarIcon,
          tabBarBadge: bottomTabBarConfig.tabs?.[route.name]?.badgeText,
          tabBarBadgeStyle: bottomTabBarConfig.tabs?.[route.name]?.badgeStyle,
        };
      }}
      screenListeners={({ route }) => {
        if (isWeb()) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const pathName = RouteNameToPathName[route.name];

          if (pathName) {
            history.replaceState(null, "", `#${pathName}`);
          }
        }

        return {};
      }}
    >
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{ title: I18n.t("ROOT_TITLES.HOME"), tabBarTestID: "BOTTOM_TABS.HOME" }}
      />
      <Tab.Screen
        name="NetworksStack"
        component={NetworksStack}
        options={{ title: I18n.t("ROOT_TITLES.NETWORKS"), tabBarTestID: "BOTTOM_TABS.NETWORKS" }}
      />
      <Tab.Screen
        name="HealthStack"
        component={HealthStack}
        options={{ title: I18n.t("ROOT_TITLES.HEALTH"), tabBarTestID: "BOTTOM_TABS.HEALTH" }}
      />
      <Tab.Screen
        name="HardwareStack"
        component={HardwareStack}
        options={{ title: I18n.t("ROOT_TITLES.HARDWARE"), tabBarTestID: "BOTTOM_TABS.HARDWARE" }}
      />
      <Tab.Screen
        name="SettingsStack"
        component={SettingsStack}
        options={{ title: I18n.t("ROOT_TITLES.SETTINGS"), tabBarTestID: "BOTTOM_TABS.SETTINGS" }}
      />
    </Tab.Navigator>
  );
};

export default DefaultTabStack;
