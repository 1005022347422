import { Tag } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ScrollView } from "react-native";

type Filter<T> = {
  value: T;
  label?: string;
};

type ExtractOptions<
  T extends Record<TKey, Filter<TValue>[]>,
  TKey extends string,
  TValue extends string,
> = {
  [K in keyof T]: T[K] extends Filter<infer Z>[] ? Z[] : never;
};

type FilterTagListProps<
  TFilters extends Record<TKey, Filter<TValue>[]>,
  TKey extends string,
  TValue extends string,
> = {
  filters: TFilters;
  onUpdateFilters: (filters: ExtractOptions<TFilters, TKey, TValue>) => void;
};

export function FilterTagList<
  TFilters extends Record<TKey, Filter<TValue>[]>,
  TKey extends string,
  TValue extends string,
>({ filters, onUpdateFilters }: FilterTagListProps<TFilters, TKey, TValue>) {
  const filterKeys = Object.keys(filters) as (keyof TFilters)[];
  const hasFilters = filterKeys.some((fk) => filters[fk].length > 0);

  if (!hasFilters) return null;

  return (
    <Box>
      <ScrollView
        horizontal
        contentContainerStyle={{ paddingHorizontal: 15, paddingVertical: 15, gap: 10 }}
      >
        {filterKeys.map((key) => {
          // This is because TS thinks `key` could be `symbol`. Its being odd...
          const strKey = `${key.toString()}`;

          const items = filters[key];

          return items.map((filter) => (
            <Tag
              key={`${strKey}_${filter.value}`}
              type="removable"
              onPress={() => {
                onUpdateFilters(
                  filterKeys.reduce(
                    (acc, updateKey) => {
                      const items =
                        updateKey === key
                          ? filters[updateKey].filter((i) => i.value !== filter.value)
                          : filters[updateKey];

                      return { ...acc, [updateKey]: items.map((i) => i.value) };
                    },
                    {} as ExtractOptions<TFilters, TKey, TValue>,
                  ),
                );
              }}
            >
              {filter.label ?? filter.value}
            </Tag>
          ));
        })}
      </ScrollView>
    </Box>
  );
}
