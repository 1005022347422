import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const ProductTypeSchema = z
  .union([
    z.literal("wireless"),
    z.literal("appliance"),
    z.literal("switch"),
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("sensor"),
    z.literal("systemsManager"),
  ] as const)
  .describe("ProductTypeSchema");

const DestinationsSchema = z
  .object({
    email: z.object({
      sentAt: z.string(),
    }),
    push: z.object({
      sentAt: z.string(),
    }),
    sms: z.object({
      sentAt: z.string(),
    }),
    webhook: z.object({
      sentAt: z.string(),
    }),
  })
  .partial();

export const NetworkEventSchema = z
  .object({
    alertTypeId: z.string(),
    alertData: z.nullable(z.object({}).passthrough()),
    alertType: z.string(),
    occurredAt: z.string(),
    device: z.object({
      name: z.string().optional(),
      productType: ProductTypeSchema.optional(),
      serial: z.string().optional(),
      mac: z.string().optional(),
      model: z.string().optional(),
    }),
    destinations: DestinationsSchema,
  })
  .describe("NetworkEventSchema");

export const NetworkEventsSchema = z.array(NetworkEventSchema);

export type NetworkEvent = z.infer<typeof NetworkEventSchema>;
export type NetworkEvents = z.infer<typeof NetworkEventsSchema>;
export type NetworkEventProductType = z.infer<typeof ProductTypeSchema>;

type NetworkEventRequest = {
  networkId?: string;
};

function buildUrl({ networkId }: NetworkEventRequest) {
  return `/api/v1/networks/${networkId}/alerts/history`;
}

function fetchNetworkEvent({ networkId }: NetworkEventRequest): Promise<NetworkEvents> {
  if (!networkId) {
    throw new Error("Undefined networkId when fetching notifications");
  }
  return request(NetworkEventsSchema, "GET", buildUrl({ networkId }));
}

export const useNetworkEvent = createQuery<NetworkEventRequest, NetworkEvents>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchNetworkEvent(variables),
  requiredVariables: ["networkId"],
});
