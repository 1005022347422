import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const RuleTypeSchema = z.union([
  z.literal("application"),
  z.literal("applicationCategory"),
  z.literal("host"),
  z.literal("port"),
  z.literal("ipRange"),
]);

export const ApplianceL7FirewallRuleSchema = z.object({
  policy: z.literal("deny"),
  type: RuleTypeSchema,
  value: z.string(),
});

export const ApplianceL7FirewallRulesSchema = z
  .object({
    rules: z.array(ApplianceL7FirewallRuleSchema),
  })
  .describe("ApplianceL7FirewallRulesSchema");

export type ApplianceL7FirewallRule = z.infer<typeof ApplianceL7FirewallRuleSchema>;
export type ApplianceL7FirewallRules = z.infer<typeof ApplianceL7FirewallRulesSchema>;

interface ApplianceL7FirewallRulesRequest {
  networkId?: string;
}

interface SetApplianceL7FirewallRulesRequest {
  networkId?: string;
  rules: ApplianceL7FirewallRule[];
}

const buildUrl = ({ networkId }: ApplianceL7FirewallRulesRequest) => {
  return `/api/v1/networks/${networkId}/appliance/firewall/l7FirewallRules`;
};

const fetchApplianceL7FirewallRules = ({ networkId }: ApplianceL7FirewallRulesRequest) => {
  return request(ApplianceL7FirewallRulesSchema, "GET", buildUrl({ networkId }));
};

const putApplianceL7FirewallRules = ({ networkId, rules }: SetApplianceL7FirewallRulesRequest) => {
  return request(ApplianceL7FirewallRulesSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify({ rules }),
  });
};

export const useApplianceL7FirewallRules = createQuery<
  ApplianceL7FirewallRulesRequest,
  ApplianceL7FirewallRules,
  APIResponseError
>({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: ApplianceL7FirewallRulesRequest) =>
    fetchApplianceL7FirewallRules(parameters),
  requiredVariables: ["networkId"],
});

export const useSetApplianceL7FirewallRules = createMutation<
  SetApplianceL7FirewallRulesRequest,
  ApplianceL7FirewallRules,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => putApplianceL7FirewallRules(variables),
});
