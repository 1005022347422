import { request } from "../../api/request/request";
import { LoginSecurity, LoginSecuritySchema } from "../../schemas/LoginSecurity";
import { createQuery } from "../createQuery";

interface LoginSecurityRequest {
  organizationId: string | undefined;
}

function buildUrl({ organizationId }: LoginSecurityRequest) {
  return `/api/v1/organizations/${organizationId}/loginSecurity`;
}

export function fetchLoginSecurity(variables: LoginSecurityRequest): Promise<LoginSecurity> {
  return request(LoginSecuritySchema, "GET", buildUrl(variables));
}

export const useLoginSecurity = createQuery<LoginSecurityRequest, LoginSecurity>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (variables) => fetchLoginSecurity(variables),
  requiredVariables: ["organizationId"],
});

export default useLoginSecurity;
