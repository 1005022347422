import { RootState } from "~/shared/types/Redux";

export const getList = (state: RootState, listId: string) => state?.pagination?.[listId];

export const getPaginationData = (state: RootState, listId: string) =>
  getList(state, listId)?.data ?? [];

export const getIsLastPage = (state: RootState, listId: string) =>
  getList(state, listId)?.isLastPage ?? false;

export const getIsFirstPage = (state: RootState, listId: string) =>
  getList(state, listId)?.isFirstPage ?? false;
