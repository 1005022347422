import { useHeaderHeight } from "@react-navigation/elements";
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";

import { isAndroid, isWeb } from "~/lib/PlatformUtils";

interface PaddedKeyboardAvoidingViewProps extends KeyboardAvoidingViewProps {
  disableVerticalOffset?: boolean;
}

function PaddedKeyboardAvoidingView(props: PaddedKeyboardAvoidingViewProps) {
  const { disableVerticalOffset, ...rest } = props;
  const headerHeight = useHeaderHeight();
  const offset = disableVerticalOffset ? 0 : (StatusBar.currentHeight ?? 0) + headerHeight;

  if (isWeb()) {
    return <View style={styles.keyboardAvoidingView} {...props} />;
  }

  return (
    <KeyboardAvoidingView
      testID="KEYBOARD_AVOIDING_VIEW"
      behavior={isAndroid() ? undefined : "padding"}
      style={styles.keyboardAvoidingView}
      keyboardVerticalOffset={offset}
      {...rest}
    />
  );
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
});

export default PaddedKeyboardAvoidingView;
