const RNFetchBlob = {
  ios: {
    openDocument: () => {},
  },
  android: {
    actionViewIntent: () => {},
  },
};

export default RNFetchBlob;
