import { Theme } from "./types";

export const baseTheme: Partial<Theme> = {
  SizeFontLg: 28,
  SizeFontHeightLineLg: 38,
  SizeFontMd: 18,
  SizeFontHeightLineMd: 24,
  SizeFontSm: 16,
  SizeFontHeightLineSm: 22,
  SizeFontXs: 14,
  SizeFontHeightLineXs: 20,
  SizeFont2xs: 12,
  SizeFontHeightLine2xs: 18,
  SizeFont3xs: 10,
  SizeFontHeightLine3xs: 18,

  SizeInteractPaddingVertMd: 10,
  SizeInteractPaddingHorizMd: 15,

  SizeContainBorderStroke: 1,
  SizeContainBorderStrokeStrong: 2,
};
