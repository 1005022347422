import { I18n } from "@meraki/core/i18n";
import { Heading, List, Loader, Text, Toggle } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSiteToSiteVPNSettings, useUpdateSiteToSiteVPNSettings } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { Alert } from "react-native";

type Mode = "hub" | "spoke" | "none";
export const SiteToSiteVPNScreen = () => {
  const networkId = useCurrentNetworkId();

  const { data, refetch } = useSiteToSiteVPNSettings({ networkId });
  const updateSiteToSiteVPNSettings = useUpdateSiteToSiteVPNSettings();

  const allTogglesOn = data?.subnets?.every((subnet) => subnet.useVpn);

  const changeAllToggles = (enabled: boolean) => {
    data?.subnets?.forEach((subnet) => {
      onToggleChange(enabled, subnet.localSubnet);
    });
  };

  const onToggleChange = (enabled: boolean, subnet: string) => {
    if (data) {
      let mode: Mode = enabled ? "hub" : "none";
      if (data?.subnets?.some(({ localSubnet, useVpn }) => localSubnet !== subnet && useVpn)) {
        mode = "hub";
      }
      updateSiteToSiteVPNSettings.mutate(
        {
          networkId,
          params: {
            mode: mode,
            subnets: [
              {
                localSubnet: subnet,
                useVpn: enabled,
              },
            ],
          },
        },
        {
          onSuccess: () => {
            refetch();
          },
          onError: (error) => {
            Alert.alert(String(error["errors"]));
          },
        },
      );
    }
  };

  return (
    <Screen addDefaultPadding>
      <Box bottomDividerBorder paddingBottom="xs">
        <Heading size="h1">{I18n.t("SITE_TO_SITE_VPN.TITLE")}</Heading>
      </Box>
      {updateSiteToSiteVPNSettings.isLoading && <Loader.Spinner animate />}
      {data?.subnets?.length && (
        <Box flexDirection="row" justifyContent="space-between" paddingHorizontal="sm">
          <Text size="p2">
            {I18n.t("SITE_TO_SITE_VPN.DESCRIPTION", { subnets: data?.subnets?.length })}
          </Text>
          <Toggle
            checked={!!allTogglesOn}
            onValueChange={(newValue: boolean) => {
              changeAllToggles(newValue);
            }}
            disabled={updateSiteToSiteVPNSettings.isLoading}
            testID={`TOGGLE.${allTogglesOn ? "ENABLED" : "DISABLED"}`}
          />
        </Box>
      )}
      <List.FlashList
        data={data?.subnets}
        getItemData={(subnet) => {
          return {
            title: subnet.localSubnet,
            leftAccessory: <Icon name="TreeStructure" />,
            rightAccessory: (
              <Toggle
                checked={subnet.useVpn}
                onValueChange={(newValue: boolean) => {
                  onToggleChange(newValue, subnet.localSubnet);
                }}
                testID={`${subnet.localSubnet}_TOGGLE.${subnet.useVpn ? "ENABLED" : "DISABLED"}`}
              />
            ),
          };
        }}
        emptyState={{ status: "informational", title: I18n.t("SITE_TO_SITE_VPN.EMPTY") }}
        paddingLeft="none"
        paddingRight="none"
        paddingBottom="none"
        paddingTop="none"
      />
    </Screen>
  );
};
