import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { SupportScreensPropMap } from "~/go/navigation/Types";
import ContactSupportScreen from "~/go/screens/ContactSupportScreen";
import NestedTableModal from "~/shared/screens/NestedTableModal";

import SearchSubjectScreen from "../screens/SearchSubjectScreen";
import { addHardwareScreens } from "./AddHardwareScreens";
import { useDefaultScreenOptions } from "./Options";

const ForwardedSearchSubjectScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SupportScreensPropMap, "SearchSubject">
  >()(SearchSubjectScreen);
const ForwardedContactSupportScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SupportScreensPropMap, "ContactSupport">
  >()(ContactSupportScreen);
const ForwardedNestedTableModal =
  withForwardedNavigationParams<ForwardedStackScreenProps<SupportScreensPropMap, "NestedTable">>()(
    NestedTableModal,
  );

const Stack = createNativeStackNavigator<SupportScreensPropMap>();

export const ContactSupportStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="SearchSubject"
        component={ForwardedSearchSubjectScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SEARCH_SUBJECT.TITLE") }}
      />
      <Stack.Screen
        name="ContactSupport"
        component={ForwardedContactSupportScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("CONTACT_SUPPORT") }}
      />
      <Stack.Screen
        name="NestedTable"
        component={ForwardedNestedTableModal}
        options={{ presentation: "fullScreenModal" }}
      />
      {addHardwareScreens(Stack)}
    </Stack.Navigator>
  );
};
