// Represents all of the react-level errors that can occur during onboarding.
export const OnboardingError = {
  CantMakePsk: "3000",
  APIVersionTimeout: "3001",
  FetchAPIVersionFailed: "3002",
  PreviousStatusTimeout: "3003",
  PreviousStatusFailed: "3004",
  FetchCertTimeout: "3005",
  FetchCertFailed: "3006",
  SendCredentialsTimeout: "3007",
  SendCredentialsFailed: "3008",
  SendCredentialsJsonDecodingFailed: "3009",
  FailedToAssignWirelessProfiles: "3010",
  BadWirelessProfileSecret: "3011",
  BadEAPIdentity: "3012",
  UnsupportedWirelessAuth: "3013",
  FetchPreviousStatusJsonDecodingFailure: "3014",
  SimulatorNotSupported: "1007",
  LocalNetworkPermissionDenied: "1019",
} as const;

export const PreviousOnboardingError = {
  // The camera couldn't find any of the given SSIDs nearby
  SSIDsIncorrect: "4000",
  // The camera finds the nearby network, but the password to the network is incorrect
  PasswordIncorrect: "4001",
  // After the camera connects to the nearby network, it can fail to be assigned an IP if the network is misconfigured
  IPConfigFailed: "4002",
};
