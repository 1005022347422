import {
  InfiniteData,
  QueryClient,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { evaluateEnabled } from "./evaluateEnabled";
import { getQueryKeyAsArray } from "./util";

type InfiniteQueryHooks<TData, TError> = {
  onSuccess?: (data: InfiniteData<TData>, queryClient: QueryClient) => void;
  onError?: (err: TError, queryClient: QueryClient) => void;
  onSettled?: (
    data: InfiniteData<TData> | undefined,
    error: TError | null,
    queryClient: QueryClient,
  ) => void;
};

type CreateInfiniteQueryOptions<
  TVariables,
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  "queryKey" | "queryFn" | "select" //| "onError" | "onSettled" | "onSuccess"
> &
  InfiniteQueryHooks<TData, TError> & {
    baseQueryKey: string | string[];
    queryFn: (
      variables: TVariables,
      context: QueryFunctionContext<TQueryKey>,
    ) => TQueryFnData | Promise<TQueryFnData>;
    requiredVariables?: ReadonlyArray<keyof TVariables>;
  };

type EnhancedInfiniteQueryOptions<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Pick<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  "select" | "enabled" | "refetchInterval" | "refetchIntervalInBackground"
> &
  InfiniteQueryHooks<TData, TError>;

export function buildInfiniteQueryKey<TVariables, TQueryKey>(
  baseQueryKey: string | string[],
  variables: TVariables,
): TQueryKey {
  const baseQueryKeyAsArray = getQueryKeyAsArray(baseQueryKey);
  return ["infinite", ...baseQueryKeyAsArray, variables] as TQueryKey;
}

export function buildInfiniteQueryKeyFetcher<TVariables, TQueryKey>(
  baseQueryKey: string | string[],
) {
  return (variables: TVariables) =>
    buildInfiniteQueryKey<TVariables, TQueryKey>(baseQueryKey, variables);
}

export function createInfiniteQuery<
  TVariables = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>({
  baseQueryKey,
  queryFn,
  onError,
  onSuccess,
  onSettled,
  requiredVariables,
  ...options
}: CreateInfiniteQueryOptions<TVariables, TQueryFnData, TError, unknown, TQueryKey>) {
  const useEnhancedQuery = <TData = TQueryFnData>(
    variables: TVariables,
    enhancedOptions?: EnhancedInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  ) => {
    const queryClient = useQueryClient();

    const enabled = evaluateEnabled({
      variables,
      requiredVariables,
      enabled: enhancedOptions?.enabled ?? options.enabled,
    });

    return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
      ...options,
      ...(enhancedOptions ?? {}),
      enabled,
      onError(err) {
        enhancedOptions?.onError?.(err, queryClient);
        onError?.(err, queryClient);
      },
      onSuccess(data) {
        enhancedOptions?.onSuccess?.(data, queryClient);
        onSuccess?.(data, queryClient);
      },
      onSettled(data, error) {
        enhancedOptions?.onSettled?.(data, error, queryClient);
        onSettled?.(data, error, queryClient);
      },
      queryKey: buildInfiniteQueryKey(baseQueryKey, variables),
      queryFn: (context) => queryFn(variables, context),
    });
  };

  useEnhancedQuery.queryKeyRoot = ["infinite", ...getQueryKeyAsArray(baseQueryKey)];
  useEnhancedQuery.queryKey = buildInfiniteQueryKeyFetcher<TVariables, TQueryKey>(baseQueryKey);
  useEnhancedQuery.queryFn =
    (variables: TVariables) => (context: QueryFunctionContext<TQueryKey, TQueryFnData>) =>
      queryFn(variables, context);

  return useEnhancedQuery;
}
