import I18n from "~/i18n/i18n";

export const stringEqualsNumber = (s: string, n: number) => parseInt(s, 10) === n;

const URL_TEST_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}((\.[a-z]{2,6})|(:\d{1,}))\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const JSON_FILE_REGEX = /^[^~)('!*<>:;,?"*|/\\\.]+$/;

export const stringIsURL = (string: string): boolean => URL_TEST_REGEX.test(string);

export const removeNonDigits = (text: string): string => {
  if (!text) {
    return text;
  }
  return text.replace(/\D+/g, "");
};

// splits a newline separated string into a list and cleans entries.
// also filters out all empty entries.
export const stringToList = (s: string): string[] =>
  s
    .split("\n")
    .map((s) => s.trim())
    .filter((s) => s !== "");

export const stringIsEmail = (text: string): boolean =>
  typeof text === "string" && EMAIL_REGEX.test(text);

export const noneIfFalsey = (text?: string): string => text || I18n.t("NONE");

export const getInitials = (fullString: string, maxInitials = 2): string => {
  if (!fullString) {
    return "";
  }

  if (maxInitials == null) {
    maxInitials = 2;
  }

  return fullString
    .split(" ")
    .slice(0, maxInitials)
    .reduce((accumulator, currentValue) => accumulator + currentValue[0], "");
};

export const validFilename = (filename: any) => {
  return JSON_FILE_REGEX.test(filename);
};
