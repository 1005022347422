import { I18n } from "@meraki/core/i18n";
import { isIpReservationConflicting } from "@meraki/shared/ip-address";
import { Address4 } from "ip-address";
import { PureComponent } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { DeviceReservation } from "~/go/types/NetworksTypes";
import { showAlert } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import { CancelButton, TextAddButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";

import { SettingsStackProps } from "../navigation/Types";

export interface ManualDeviceInputScreenProps {
  onAddClient: (landIp: string, clientData: DeviceReservation) => void;
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ManualDeviceInput">;

interface ManualDeviceInputScreenState {
  localIp: string;
  reserveIpSwitchEnabled: boolean;
}

export class ManualDeviceInputScreen extends PureComponent<Props, ManualDeviceInputScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      localIp: "",
      reserveIpSwitchEnabled: false,
    };
    setupAndroidLayoutAnimation();
    this.setNavOptions(true);
  }

  componentDidUpdate() {
    const { localIp } = this.state;
    const isSaveDisabled = Address4.isValid(localIp) !== true;
    this.setNavOptions(isSaveDisabled);
  }

  setNavOptions(isSaveDisabled: boolean) {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => (
        <TextAddButton onPress={this.onAddButtonPress} disabled={isSaveDisabled} />
      ),
    });
  }

  onAddButtonPress = () => {
    const { reserveIpSwitchEnabled, localIp } = this.state;
    const { navigation, gxVlan, onAddClient } = this.props;

    if (localIp === "" || !Address4.isValid(localIp)) {
      showAlert(I18n.t("ERROR"), I18n.t("MANUAL_DEVICE_INPUT.ENTER_RESERVED_IP_ERROR"));
      return;
    }

    const ipError = isIpReservationConflicting(localIp, gxVlan?.subnet, gxVlan?.applianceIp);
    if (ipError) {
      showAlert(I18n.t("ERROR"), ipError);
      return;
    }

    onAddClient(localIp, {
      manualIpReservation: reserveIpSwitchEnabled ? localIp : null,
      reservedClientDetails: null,
    });
    navigation.goBack();
  };

  onChangeText = (newText: string, name?: string) => {
    if (name) {
      this.setState((oldState) => ({ ...oldState, [name]: newText }));
    }
  };

  renderIpInput = () => {
    const { gxVlan } = this.props;

    return (
      <IPAddressTextInput
        cidrAddress={gxVlan?.subnet}
        title={I18n.t("MANUAL_DEVICE_INPUT.LOCAL_IP_ADDRESS")}
        name="localIp"
        onAddressChange={this.onChangeText}
        testID="IP_ADDRESS"
      />
    );
  };

  onSwitchValueChange = (value: boolean) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    this.setState({ reserveIpSwitchEnabled: value });
  };

  renderReserveIpAddressRadio = () => {
    const { reserveIpSwitchEnabled } = this.state;

    return (
      <View>
        <SwitchRow
          value={reserveIpSwitchEnabled}
          onValueChange={this.onSwitchValueChange}
          subtitle={I18n.t("MANUAL_DEVICE_INPUT.RESERVE_IP_DESCRIPTION")}
          screenStyles={[styles.switch]}
          testID="MANUAL_DEVICE_INPUT.ENABLED"
        >
          {I18n.t("MANUAL_DEVICE_INPUT.RESERVE_IP_TITLE")}
        </SwitchRow>
      </View>
    );
  };

  render() {
    return (
      <FullScreenContainerView withScroll screenStyles={[styles.container]}>
        {this.renderIpInput()}
        {this.renderReserveIpAddressRadio()}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: SPACING.default,
  },
  switch: {
    marginHorizontal: 0,
  },
});

export default ManualDeviceInputScreen;
