import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { compose } from "redux";

import TopClientsList from "~/go/components/TopClientsList";
import DeviceListRow from "~/go/rows/DeviceListRow";
import withLLDPData from "~/hocs/LLDPData";
import { getDeviceStatus } from "~/lib/DeviceUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import { ExtendedClient } from "~/shared/types/Client";
import Device_DeprecatedType from "~/shared/types/Device";

const DISPLAY_MAX_LLDP = 1;
const DISPLAY_MAX_CLIENTS = 3;

type Props = {
  navigate: (screen: string, params: Record<string, unknown>) => void;
  deviceClients: Record<string, ExtendedClient>; // I'm like 99% sure this isn't being used
  portNumber: number;
  serialNumber: string;
  lldpClient?: ExtendedClient;
  lldpDevice: Device_DeprecatedType;
  lldpMac?: string;
  isConnected?: boolean;
  heading?: string;
};

export class PortConnectedDevicesComponent extends PureComponent<Props> {
  onDevicePress = () => {
    this.showHardwareDetails();
  };

  showHardwareDetails() {
    const { lldpDevice, navigate } = this.props;

    navigate("HardwareDetails", {
      serialNumber: lldpDevice.serial,
    });
  }

  showClientDetails() {
    const { lldpClient, navigate } = this.props;

    navigate("ClientDetails", {
      id: lldpClient?.id,
    });
  }

  renderTopClients() {
    const { deviceClients } = this.props;
    return this.renderClients(Object.values(deviceClients), DISPLAY_MAX_CLIENTS);
  }

  renderLLDPDevice() {
    const { lldpDevice } = this.props;

    return (
      <DeviceListRow
        onPress={this.onDevicePress}
        status={getDeviceStatus(lldpDevice)}
        deviceId={lldpDevice.id}
        device={lldpDevice}
        model={lldpDevice.model}
        isHardwired={lldpDevice.is_gateway}
        showDisclosure
      >
        {lldpDevice.name || lldpDevice.serial || ""}
      </DeviceListRow>
    );
  }

  renderLLDPClient() {
    const { lldpClient } = this.props;
    return this.renderClients(lldpClient ? [lldpClient] : [], DISPLAY_MAX_LLDP);
  }

  renderClients(clients: ExtendedClient[], maxClients: number) {
    const { navigate } = this.props;
    return (
      <TopClientsList
        clients={clients}
        ignoreFilter
        maxClientsToDisplay={maxClients}
        prioritizeOnlineClients
        navigate={navigate}
        renderAllClientsButton
        hideUsage
      />
    );
  }

  renderBody() {
    const { deviceClients, lldpClient, lldpDevice, isConnected = false } = this.props;

    if (!isConnected) {
      return noDevices;
    }

    if (lldpDevice) {
      return this.renderLLDPDevice();
    }
    if (lldpClient) {
      return this.renderLLDPClient();
    }

    if (!isEmpty(deviceClients)) {
      return this.renderTopClients();
    }

    return noDevices;
  }

  render() {
    const { heading = capitalizeFirstLetter(I18n.t("PORTS.DEVICES")) } = this.props;
    return <SummaryCard heading={heading}>{this.renderBody()}</SummaryCard>;
  }
}

const styles = StyleSheet.create({
  noDeviceContainer: {
    alignItems: "center",
  },
});

const noDevices = (
  <View style={styles.noDeviceContainer}>
    <MkiText textStyle="smallSecondary">
      {I18n.t("PORTS.NO_CONNECTED_CLIENTS", { client_word: I18n.t("DEVICES_WORD") })}
    </MkiText>
  </View>
);

export default compose<any>(withLLDPData)(PortConnectedDevicesComponent);
