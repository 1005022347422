import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { groupBy } from "lodash";
import { useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useSupportCase } from "~/api/queries/cases/useSupportCases";
import { SupportCase } from "~/api/schemas/SupportCases";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLES } from "~/constants/MkiTextStyles";
import InfoBannerExpandable from "~/go/components/supportCase/InfoBannerExpandable";
import SupportCaseListRowWrapper from "~/go/components/supportCase/SupportCaseListRowWrapper";
import SupportCaseStatus from "~/go/components/supportCase/SupportCaseStatus";
import { filterData } from "~/lib/SearchUtils";
import { themeColors } from "~/lib/themeHelper";
import { currentOrganizationName } from "~/selectors";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import SearchBar from "~/shared/components/SearchBar";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";
import DropDownRow from "~/shared/rows/DropDownRow";
import { SupportCaseStatusOptions } from "~/shared/types/SupportCaseTypes";

import NewSupportCasesButton from "../components/supportCase/NewSupportCaseButton";
import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SupportCasesList">;
interface TableData {
  orgName: string;
  openCasesForOrg: SupportCase[];
  closedCasesForOrg: SupportCase[];
}

const SupportCasesListScreen = () => {
  const supportCaseQuery = useSupportCase();
  const navigation = useNavigation<Props["navigation"]>();
  const theme = useTheme();
  const organizationName = useAppSelector(currentOrganizationName);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <NewSupportCasesButton />,
    });
  }, [navigation]);

  const allSupportCases = supportCaseQuery.data ?? [];

  const [searchText, setSearchText] = useState<string>();

  const allCasesByOrg = groupBy(allSupportCases, "organization.name");
  const currentOrgCases: TableData[] = [];
  const otherOrgCases: TableData[] = [];
  for (const orgName in allCasesByOrg) {
    const supportCaseDataForOrg = {
      orgName: orgName,
      openCasesForOrg: allCasesByOrg[orgName].filter(
        (supportCase) => supportCase.status != SupportCaseStatusOptions.closed,
      ),
      closedCasesForOrg: allCasesByOrg[orgName].filter(
        (supportCase) => supportCase.status === SupportCaseStatusOptions.closed,
      ),
    };
    if (orgName === organizationName) {
      currentOrgCases.push(supportCaseDataForOrg);
    } else {
      otherOrgCases.push(supportCaseDataForOrg);
    }
  }

  const filteredCases = (supportCases: SupportCase[]) => {
    return filterData(supportCases ?? [], ["caseNumber", "subject"], searchText);
  };

  const onSearchChange = (text?: string) => {
    setSearchText(text);
  };

  const renderSearchBar = () => {
    return (
      <SearchBar
        testID="SEARCHBAR"
        value={searchText}
        placeholder={I18n.t("SUPPORT_CENTER.SEARCH_PLACEHOLDER")}
        onChangeText={onSearchChange}
        withSearchBarBorder={true}
      />
    );
  };

  const renderSectionHeader = (title: string) => {
    return (
      <View style={styles.header}>
        <MkiText textStyle="label" screenStyles={styles.heading} testID="SECTION_HEADER">
          {title}
        </MkiText>
      </View>
    );
  };

  const renderRow = (rowData: TableData, isOther = true) => {
    return (
      <>
        <DropDownRow
          openInitially={rowData.openCasesForOrg.length > 0 || rowData.closedCasesForOrg.length > 0}
          title={rowData.orgName}
          titleTextStyle={[
            TEXT_STYLES.default,
            themeColors(theme).text.default,
            styles.dropDownHeader,
          ]}
          testID={`SUPPORT_CASES_${isOther ? "OTHER_ORG" : "CURRENT_ORG"}`}
          noMargin
        >
          {filteredCases(rowData.openCasesForOrg).length > 0 && (
            <>
              <View style={styles.statusHeader}>
                <SupportCaseStatus status={"Open"} />
              </View>
              {renderCaseList(filteredCases(rowData.openCasesForOrg), "OPEN")}
            </>
          )}
          {filteredCases(rowData.closedCasesForOrg).length > 0 && (
            <>
              <View style={styles.statusHeader}>
                <SupportCaseStatus status={"Closed"} />
              </View>
              {renderCaseList(filteredCases(rowData.closedCasesForOrg), "CLOSED")}
            </>
          )}
        </DropDownRow>
      </>
    );
  };

  const renderCurrentOrgCases = () => {
    return currentOrgCases[0] ? (
      renderRow(currentOrgCases[0], false)
    ) : (
      <View style={styles.noDataSectionContainer} testID="NO_DATA_OVERALL">
        <MkiText textStyle="secondary"> {I18n.t("SUPPORT_CENTER.NO_DATA")}</MkiText>
      </View>
    );
  };

  const renderOtherOrgCases = () => {
    return (
      <>
        {renderSectionHeader(I18n.t("SUPPORT_CENTER.ORGANIZATION.OTHERS"))}
        <MkiTable<TableData>
          // @ts-ignore inconsistent second param
          renderRow={renderRow}
          data={otherOrgCases}
          emptyComponent={
            <View style={styles.noDataSectionContainer} testID="NO_DATA_OVERALL">
              <MkiText textStyle="secondary"> {I18n.t("SUPPORT_CENTER.NO_DATA")}</MkiText>
            </View>
          }
        />
      </>
    );
  };
  const renderCaseList = (groupOfCases: SupportCase[], testIdSuffix: string) => {
    return groupOfCases.length > 0 ? (
      <View style={styles.caseList}>
        {groupOfCases.map((supportCase, index) => (
          <SupportCaseListRowWrapper
            supportCase={supportCase}
            rowId={index}
            testIdSuffix={testIdSuffix}
            key={`SUPPORT_CASES.CASE_ROW_WRAPPER.${testIdSuffix} - ${index}`}
          />
        ))}
      </View>
    ) : (
      <View style={styles.noDataSectionContainer} testID={`NO_DATA_${testIdSuffix}`}>
        <MkiText textStyle="secondary"> {I18n.t("SUPPORT_CENTER.NO_DATA")}</MkiText>
      </View>
    );
  };

  return (
    <FullscreenContainerView stickyHeaderIndices={[2]}>
      <RefreshControlScrollView
        onRefresh={() => supportCaseQuery.refetch()}
        refreshing={supportCaseQuery.isFetching || supportCaseQuery.isLoading}
      >
        <InfoBannerExpandable
          alertText={I18n.t("SUPPORT_CENTER.SUPPORT_INFO.LABEL")}
          expandedText={I18n.t("SUPPORT_CENTER.SUPPORT_INFO.DESCRIPTION")}
          screenStyles={styles.infoBanner}
          textStyle={"defaultSpacedLines"}
          testID="BUSINESS_HOURS"
        />
        {renderSearchBar()}
        {renderSectionHeader(I18n.t("SUPPORT_CENTER.ORGANIZATION.CURRENT"))}
        {renderCurrentOrgCases()}
        {renderOtherOrgCases()}
      </RefreshControlScrollView>
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
    marginHorizontal: SPACING.default,
  },
  heading: {
    marginTop: SPACING.small,
    paddingVertical: SPACING.small,
  },
  noDataSectionContainer: {
    paddingVertical: SPACING.default,
    alignItems: "center",
  },
  caseList: {
    marginHorizontal: SPACING.default,
  },
  statusHeader: {
    marginHorizontal: SPACING.default,
    flexDirection: "row",
    paddingTop: SPACING.small,
  },
  infoBanner: {
    marginTop: SPACING.small,
    marginHorizontal: SPACING.default,
  },
  dropDownHeader: {
    marginTop: SPACING.small,
  },
});

export default SupportCasesListScreen;
