import {
  CableTestStatus,
  LiveToolCommand,
  LiveToolContext,
  LiveToolStatus,
  useCableTest,
} from "@meraki/react-live-broker";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { CABLE_TEST_TIMEOUT, SPACING } from "~/constants/MkiConstants";
import CableTestFail from "~/go/components/liveTools/cableTest/CableTestFail";
import CableTestInProgress from "~/go/components/liveTools/cableTest/CableTestInProgress";
import CableTestPass from "~/go/components/liveTools/cableTest/CableTestPass";
import CableTestStart from "~/go/components/liveTools/cableTest/CableTestStart";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import { CloseButton } from "~/shared/navigation/Buttons";
import { Timeout } from "~/shared/types/TimeoutType";

// TODO: Fix mocking for React.useState for jest and rm this optional prop
interface CableTestTestingProps {
  initialCommand?: LiveToolCommand;
}

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "CableTest"> &
  CableTestTestingProps;

interface CableTestProps extends Props {
  children: React.JSXElementConstructor<{ cableTestStatus?: CableTestStatus }>;
}
const CableTest = ({ deviceId, portNumber, children: Component }: CableTestProps) => {
  const cableTestStatus = useCableTest(deviceId, [portNumber.toString()]);
  return <Component cableTestStatus={cableTestStatus} />;
};

export function CableTestScreen(props: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const { initialCommand } = props;
  const [command, setCommand] = useState<LiveToolCommand>(initialCommand ?? "stop");
  const [status, setStatus] = useState<LiveToolStatus>();
  const timeoutRef = useRef<Timeout>();
  const [isTimedOut, setTimedOut] = useState<boolean>();

  const cancelTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setTimedOut(false);
  };

  const onStart = () => {
    setCommand(command === "start" ? "restart" : "start");
  };

  const onStop = () => {
    setCommand("stop");
  };

  const onStatusChange = (cableTestStatus: LiveToolStatus) => {
    switch (cableTestStatus) {
      case LiveToolStatus.running:
        timeoutRef.current = setTimeout(() => setTimedOut(true), CABLE_TEST_TIMEOUT);
        break;
      case LiveToolStatus.completed:
      case LiveToolStatus.idle:
      case LiveToolStatus.error:
        cancelTimeout();
        break;
    }

    setStatus(cableTestStatus);
  };

  return (
    <FullScreenContainerView>
      <ScrollView contentContainerStyle={styles.container}>
        <LiveToolContext.Provider value={{ command, onStatusChange }}>
          <CableTest {...props}>
            {({ cableTestStatus }) => {
              if (command === "stop") {
                // @ts-expect-error TS(2322) FIXME: Type '{ onStartPress: () => void; testID: string; ... Remove this comment to see the full error message
                return <CableTestStart onStartPress={onStart} testID={"CABLE_TEST.START"} />;
              }

              if (!cableTestStatus) {
                return (
                  // @ts-expect-error TS(2322) FIXME: Type '{ onStopPress: () => void; testID: string; }... Remove this comment to see the full error message
                  <CableTestInProgress onStopPress={onStop} testID={"CABLE_TEST.IN_PROGRESS"} />
                );
              }

              const { linkType, pair1, pair2, pair3, pair4 } = cableTestStatus;
              const subGigabitLinkPassed = pair1 && pair2;
              const gigabitLinkPassed =
                linkType === "1Gfdx" && subGigabitLinkPassed && pair3 && pair4;

              const cableTestPassed = subGigabitLinkPassed || gigabitLinkPassed;

              if (!cableTestPassed || isTimedOut || status === LiveToolStatus.error) {
                // @ts-expect-error TS(2322) FIXME: Type '{ onRestartPress: () => void; testID: string... Remove this comment to see the full error message
                return <CableTestFail onRestartPress={onStart} testID={"CABLE_TEST.FAIL"} />;
              }

              // @ts-expect-error TS(2322) FIXME: Type '{ onRestartPress: () => void; testID: string... Remove this comment to see the full error message
              return <CableTestPass onRestartPress={onStart} testID={"CABLE_TEST.PASS"} />;
            }}
          </CableTest>
        </LiveToolContext.Provider>
      </ScrollView>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    paddingTop: SPACING.default,
    paddingHorizontal: SPACING.default,
  },
});

export default CableTestScreen;
