import { CloakContext } from "@meraki/core/cloak";
import { ComponentType, forwardRef } from "react";

import { AdapterContext } from "./AdapterContext";

export function withMagneticReplacementAdapter<TWrappedComponentProps extends object>(
  WrappedComponent: ComponentType<TWrappedComponentProps>,
  MagneticReplacementComponent: ComponentType<TWrappedComponentProps>,
) {
  const MagneticAdaptedComponent = forwardRef((props: TWrappedComponentProps, ref) => {
    return (
      <CloakContext.Consumer>
        {(_cloakInfo) => (
          <AdapterContext.Consumer>
            {(magneticEnabled) => {
              if (!magneticEnabled) {
                return <WrappedComponent {...props} ref={ref} />;
              }

              return <MagneticReplacementComponent {...props} ref={ref} />;
            }}
          </AdapterContext.Consumer>
        )}
      </CloakContext.Consumer>
    );
  });

  MagneticAdaptedComponent.displayName =
    WrappedComponent.displayName ?? WrappedComponent.name ?? "Component";
  // @ts-ignore Ignoring this because with react 18 there is a new `PropsWithoutRef` wrapping type that throws this typing off. DM-3379
  MagneticAdaptedComponent.defaultProps = WrappedComponent.defaultProps;

  return MagneticAdaptedComponent;
}
