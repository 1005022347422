import { I18n } from "@meraki/core/i18n";
import { Text } from "@meraki/magnetic/components";
import {
  useResendOTP,
  useSubmitOTP,
  verifyIsLoginResponse,
  verifyIsModeResponse,
} from "@meraki/shared/api";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useDispatch } from "react-redux";

import { setShardFromPath } from "../libs/LoginUtils";
import { OTPScreen } from "./OTPScreen";

const redactEmail = (email: string) => {
  let shouldRedact = true;
  return [...email]
    .map((char, idx) => {
      if (char === "@") {
        shouldRedact = false;
      }
      return idx === 0 || !shouldRedact ? char : "*";
    })
    .join("");
};

export const OTPSignupScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<LoginGroupProps>>();
  const { params: props } = useRoute<RouteProp<LoginGroupProps, "OTPSignup">>();
  const { email } = props;

  const submitOTPAuth = useSubmitOTP();
  const resendOTP = useResendOTP();
  const dispatch = useDispatch();

  const handleSubmit = (code: string) => {
    submitOTPAuth.mutate(
      { code },
      {
        onSuccess: (response) => {
          if (verifyIsModeResponse(response) && response.error) {
            showErrorAlert(response.error);
          }
          if (verifyIsLoginResponse(response) && response.success) {
            dispatch(setShardFromPath(response));
            navigation.navigate("VerifyEmail", { response, email });
          }
        },
      },
    );
  };

  const resendOTPCode = () => {
    resendOTP.mutate(undefined, {
      onSuccess: () => {
        showErrorAlert(
          I18n.t("TWO_FACTOR_AUTH.EMAIL"),
          I18n.t("TWO_FACTOR_AUTH.EMAIL_RESENT_SUCCESS_TITLE"),
        );
      },
    });
  };

  return (
    <OTPScreen
      submitOTPAuth={handleSubmit}
      resendOTPCode={resendOTPCode}
      isLoading={submitOTPAuth.isLoading}
      isResendingCode={resendOTP.isLoading}
      analytics={[
        {
          event: "onPressResend" as const,
          eventName: "resend_otp_code",
          params: { src: "signup" },
        },
        {
          event: "onPressVerify" as const,
          eventName: "verify_otp_code",
          params: { src: "signup" },
        },
      ]}
    >
      <Text size="p1">{I18n.t("CREATE_ACCOUNT.OTP", { email: redactEmail(email) })}</Text>
    </OTPScreen>
  );
};
