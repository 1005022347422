import * as errorMonitor from "@meraki/core/errors";
import { isEmpty } from "lodash";
import { Platform } from "react-native";
import z, { ZodError, ZodTypeAny } from "zod";

import { Method } from "../coreTypes";
import { CSRFTokenSchema, requestNeedsCSRF } from "../csrf";
import { apiFetch } from "../fetch/apiFetch";
import { useRuntimeSchemaValidation } from "../useRuntimeSchemaValidation";
import { formatURLQueryParams, safeParseResponseJson } from "../util";
import { generateHeaders } from "./generateHeaders";
import { logAndThrowResponseError } from "./logResponseError";

// Re-export some functions we want on our public API.
export { setHeadersBuilder } from "./generateHeaders";

type UriBuilderFunction = (endpoint: string) => string;

let uriBuilder: UriBuilderFunction = (endpoint) => endpoint;
export function setUriBuilder(callback: UriBuilderFunction) {
  uriBuilder = callback;
}

class HTTPResponseTypeError extends ZodError {
  endpoint: string;
  schemaName: string | undefined;
  method: Method;
  constructor(ze: ZodError, endpoint: string, schemaName: string | undefined, method: Method) {
    super(ze.issues);
    this.name = "HTTPResponseTypeError";
    this.endpoint = endpoint;
    this.schemaName = schemaName;
    this.method = method;
  }

  get message() {
    return `${super.message}\non endpoint: ${this.method} ${this.endpoint}\nwith schema: ${this.schemaName}`;
  }
}

const TOKEN_ENDPOINT = "/mobile/token";

export const request = async <S extends ZodTypeAny>(
  schema: S,
  method: Method,
  endpoint: string,
  opts?: {
    queryParams?: Record<string, string | string[] | number | boolean | unknown[] | undefined>;
    /**
     * Please avoid the usage of this parameter. If you find yourself in a situation where you need to use this parameter reach out to Mobile Platforms
     */
    unencodedQueryParams?: Record<
      string,
      string | string[] | number | boolean | unknown[] | undefined
    >;
    headers?: Record<string, string>;
    body?: string | FormData;
    baseUrl?: string;
    skipCSRF?: boolean;
  },
): Promise<z.infer<S>> => {
  let url = opts?.baseUrl ? opts.baseUrl + endpoint : uriBuilder(endpoint);
  const csrfToken =
    requestNeedsCSRF(method) && endpoint !== TOKEN_ENDPOINT && !opts?.skipCSRF
      ? await request(CSRFTokenSchema, "POST", TOKEN_ENDPOINT, { baseUrl: opts?.baseUrl })
      : undefined;
  let searchParams = formatURLQueryParams(opts?.queryParams ? opts.queryParams : {}, undefined);
  if (opts?.unencodedQueryParams) {
    const options = { unencoded: true };
    const join = opts?.queryParams ? "&" : "?";
    searchParams += `${join}${formatURLQueryParams(
      opts?.unencodedQueryParams,
      undefined,
      options,
    )}`;
  }

  if (Platform.OS === "web") {
    url = `${url}?${searchParams}`;
  } else {
    const urlObject = new URL(url);
    urlObject.search = searchParams;
    url = urlObject.toString();
  }

  const headers = new Headers(generateHeaders(method, csrfToken, opts?.headers));

  const response = await apiFetch(url, {
    method,
    body: opts?.body,
    headers,
  });

  // A response is not ok if it has a response code outside the range of 200-299
  if (!response.ok) {
    const responseJson = await safeParseResponseJson(response);
    logAndThrowResponseError({ method, response, responseJson, schema });
  }

  // Allows for 204 responses to be successful since they don't returrn a response body
  if (response.status === 204) {
    return {};
  }

  // Check to make sure we are not parsing text as json
  if (response.headers.get("Content-Type")?.includes("text/plain")) {
    return await response.text();
  }

  // Check if the empty response is expected
  if (response.body == null && schema.safeParse(response.body).success) {
    return undefined;
  }

  const json: unknown = await response.json();

  const { enabled, flagEnabled } = useRuntimeSchemaValidation.getState();
  if (!enabled && !flagEnabled) {
    return json as z.infer<S>;
  }

  const parsed = await schema.safeParseAsync(json);

  if (!parsed.success) {
    // api sometimes returns a 200 with:
    // a json object containing an error key and value
    // an empty json obj
    if ((json instanceof Object && Object.hasOwnProperty.call(json, "error")) || isEmpty(json)) {
      logAndThrowResponseError({
        method,
        response,
        responseJson: json,
        schema,
        shouldNotify: true,
      });
    }

    const error = new HTTPResponseTypeError(parsed.error, endpoint, schema.description, method);
    if (__DEV__) {
      // Log error with console since since the thrown error is not logged in tests.
      console.warn("Throwing", error);
      throw error;
    }

    errorMonitor.notify(error, schema.description);
    return json as z.infer<S>;
  }

  return parsed.data;
};
