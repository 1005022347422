import RNFS from "react-native-fs";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Share from "react-native-share";

import { isAndroid, isIOS } from "~/lib/PlatformUtils";

export const ANDROID_EXPORT_SAVE_PATH = `${RNFS.ExternalStorageDirectoryPath}/Download`;
export const IOS_EXPORT_SAVE_PATH = `${RNFS.DocumentDirectoryPath}`;

export enum ExportType {
  ssid = "ssid",
  vlan = "vlan",
}

export const exportTypeSelect = <E, T = E>(
  options: { ssid: E; vlan: T },
  type: ExportType,
): E | T => {
  if (type === ExportType.ssid) {
    return options.ssid;
  } else if (type === ExportType.vlan) {
    return options.vlan;
  } else {
    throw new Error("Invalid Export Type");
  }
};

export const saveExportedTemplate = async (fileName: string, exportTemplate: string) => {
  try {
    if (isIOS() || isAndroid()) {
      const savePath = `${
        isIOS() ? IOS_EXPORT_SAVE_PATH : ANDROID_EXPORT_SAVE_PATH
      }/${fileName}.json`;

      await RNFS.writeFile(savePath, exportTemplate, "utf8");
      await Share.open({
        url: `file://${savePath}`,
        saveToFiles: true,
      });
    }
  } catch (e) {
    console.log(e);
  }
};
