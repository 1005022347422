import { DateFormatOptions, formatDate } from "@meraki/core/date";
import { ScaleDataValue } from "echarts";
import { useCallback } from "react";

export const SECONDS_IN_A_DAY = 86400;
export const MILLISECONDS_IN_A_DAY = SECONDS_IN_A_DAY * 1000;

export function useCachedTimestampFormatter(timespan: number): (value: ScaleDataValue) => string {
  return useCallback(
    (value: ScaleDataValue) => {
      const dateFormatNew: DateFormatOptions =
        timespan > SECONDS_IN_A_DAY
          ? { dateFormat: "dateNoYear", unixTimestamp: false }
          : { dateFormat: "hide", timeFormat: "shortTime", unixTimestamp: false };

      return formatDate(value, dateFormatNew);
    },
    [timespan],
  );
}
