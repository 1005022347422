import { I18n } from "@meraki/core/i18n";
import {
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_MEBIBITS_INTEGERED,
  TRAFFIC_SHAPING_USAGE_INCREMENTS,
} from "@meraki/go/traffic-shaping";
import {
  GroupPolicies,
  GroupPolicy,
  queryClient,
  useCreateGroupPolicy,
  useGroupPolicies,
} from "@meraki/shared/api";
import { kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import { useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import {
  useCreateIpsksForSsid,
  useDeleteIpskForSsid,
  useUpdateIpsksForSsid,
} from "~/api/queries/ssids/useIdentityPsk";
import { SPACING } from "~/constants/MkiConstants";
import DatePickerRow from "~/go/components/identityPsk/DatePickerRow";
import { GroupPolicySlider } from "~/go/components/identityPsk/GroupPolicySlider";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import { formatTransferBits } from "~/lib/formatHelper";
import { getUsageLimitPolicyName } from "~/lib/GroupPolicyUtils";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { BandwidthSettings } from "~/shared/types/GroupPolicyTypes";

export interface GroupPolicyState {
  [groupPolicyId: string]: GroupPolicy;
}

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "ConfigureIpsk">;

const ConfigureIpskScreen = (props: Props) => {
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const { ipsk, ssidNumber } = props;

  const ipskId = ipsk ? ipsk.id : "0";
  const [name, setName] = useState<string>(ipsk ? ipsk?.name : "");
  const [passphrase, setPassphrase] = useState<string>(ipsk ? ipsk?.passphrase : "");
  const [doesExpire, setDoesExpire] = useState<boolean>(ipsk && ipsk?.expiresAt ? true : false);
  const [expiresAt, setExpiresAt] = useState<Date | undefined>(
    ipsk && ipsk?.expiresAt ? new Date(ipsk.expiresAt) : undefined,
  );

  const orderGroupPoliciesById = (groupPolicies?: GroupPolicies) => {
    const orderedPolicies: GroupPolicyState = {};
    if (!groupPolicies) {
      return orderedPolicies;
    }
    groupPolicies.forEach((groupPolicy: GroupPolicy) => {
      orderedPolicies[groupPolicy.groupPolicyId] = groupPolicy;
    });
    return orderedPolicies;
  };

  const getPoliciesByIndex = (groupPoliciesById?: GroupPolicyState) => {
    const policyIDs: string[] = new Array(TRAFFIC_SHAPING_USAGE_INCREMENTS + 1).fill("");
    if (!groupPoliciesById) {
      return policyIDs;
    }
    Object.entries(groupPoliciesById).forEach(([policyID, policy]) => {
      if (policy.bandwidth?.bandwidthLimits?.limitDown != null) {
        const policyLimit =
          TRAFFIC_SHAPING_MEBIBITS_INTEGERED[policy.bandwidth.bandwidthLimits.limitDown];
        if (typeof policyLimit === "number") {
          const index = TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(policyLimit);

          if (index !== -1) {
            policyIDs[index] = policyID;
          }
        }
      }
    }, {});

    return policyIDs;
  };

  const groupPolicyQuery = useGroupPolicies(
    { networkId },
    {
      select: (data) => orderGroupPoliciesById(data),
    },
  );
  const groupPoliciesById = groupPolicyQuery.data ?? {};

  const policyIDsByIndex = getPoliciesByIndex(groupPoliciesById);
  const currentLimitIndex =
    ipsk && policyIDsByIndex.indexOf(ipsk.groupPolicyId) !== -1
      ? policyIDsByIndex.indexOf(ipsk.groupPolicyId)
      : 0;
  const [limitIndex, setLimitIndex] = useState<number>(0);
  const [limitSpeed, setLimitSpeed] = useState<string>(
    formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex]),
  );

  useEffect(() => {
    setLimitIndex(currentLimitIndex);
    setLimitSpeed(formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[currentLimitIndex]));
  }, [currentLimitIndex]);

  // initialize our react query functions
  const createIpsk = useCreateIpsksForSsid(ssidNumber);
  const updateIpsk = useUpdateIpsksForSsid(ssidNumber, ipskId);
  const deleteIpsk = useDeleteIpskForSsid(ssidNumber, ipskId);
  const createGroupPolicy = useCreateGroupPolicy();

  const onSaveSuccess = () => {
    showAlert(
      I18n.t("CONFIGURE_IPSK.SUCCESS_ALERT.TITLE"),
      ipsk
        ? I18n.t("CONFIGURE_IPSK.SUCCESS_ALERT.MESSAGE_UPDATE")
        : I18n.t("CONFIGURE_IPSK.SUCCESS_ALERT.MESSAGE_NEW"),
      navigation.goBack,
    );

    analytics.logEvent(ipsk ? FIREBASE_EVENTS.updateIPSKGroup : FIREBASE_EVENTS.createIPSKGroup, {
      has_expiration: !!(doesExpire && expiresAt),
    });
  };

  const errorHandler = (error: unknown) => {
    if (error !== null && typeof error === "object" && error.hasOwnProperty("errors")) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const errorMessage = cleanErrorMessage(error["errors"][0]);
      showAlert("CONFIGURE_IPSK.ERROR", errorMessage);
    } else {
      throw error;
    }
  };

  const saveIPSK = (policyID: string) => {
    const newIpskValues: any = {
      name,
      groupPolicyId: policyID,
      passphrase,
    };

    if (doesExpire && expiresAt) {
      newIpskValues["expiresAt"] = expiresAt.toISOString();
    }

    // updating existing
    if (ipsk) {
      if (!doesExpire) {
        newIpskValues["expiresAt"] = null;
      }

      updateIpsk.mutate(
        { ...newIpskValues, id: ipskId },
        {
          onSuccess: () => {
            onSaveSuccess();
          },
          onError: errorHandler,
        },
      );
    }
    //creating new
    else {
      createIpsk.mutate(newIpskValues, {
        onSuccess: () => {
          onSaveSuccess();
        },
        onError: errorHandler,
      });
    }
  };

  const onSaveChanges = () => {
    if (policyIDsByIndex[limitIndex]) {
      saveIPSK(policyIDsByIndex[limitIndex]);
    } else {
      // if no policy then, create new - taken from BlockClientScreen
      const translatedLimit = kilobitsToMebibitsInt(TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex]);
      const bandwithSettings = {
        settings: BandwidthSettings.custom,
        bandwidthLimits: {
          limitUp: translatedLimit,
          limitDown: translatedLimit,
        },
      };
      createGroupPolicy.mutate(
        {
          networkId: networkId,
          name: getUsageLimitPolicyName(limitSpeed),
          bandwidth: bandwithSettings,
        },
        {
          onSuccess: (data) => {
            saveIPSK(data.groupPolicyId);
            queryClient.refetchQueries({ queryKey: useGroupPolicies.queryKeyRoot });
          },
          onError: errorHandler,
        },
      );
    }
  };

  const cleanErrorMessage = (initialErrorMessage: string) => {
    return initialErrorMessage.replace("WPA2-PSK key", "Passphrase");
  };

  const isDirty = (): boolean => {
    if (ipsk) {
      return (
        name !== ipsk.name ||
        passphrase !== ipsk.passphrase ||
        limitIndex !== policyIDsByIndex.indexOf(ipsk.groupPolicyId) ||
        (ipsk.expiresAt && !doesExpire) ||
        (!ipsk.expiresAt && expiresAt && doesExpire) ||
        (expiresAt && ipsk.expiresAt ? !isEqual(expiresAt, ipsk.expiresAt) : false)
      );
    } else {
      return name !== "" && passphrase !== "";
    }
  };

  useLayoutEffect(() =>
    navigation.setOptions({
      headerTitle: `${ipsk ? I18n.t("CONFIGURE_IPSK.EDIT") : I18n.t("CONFIGURE_IPSK.NEW")} ${I18n.t(
        "CONFIGURE_IPSK.PASSWORD",
      )}`,
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSaveChanges} disabled={!isDirty()} />,
    }),
  );

  const setLimit = (newLimitIndex: any) => {
    setLimitIndex(newLimitIndex);
    setLimitSpeed(formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[newLimitIndex]));
  };

  const cleanAndSetExpiresAt = (date: Date): void => {
    date.setMinutes(0);
    setExpiresAt(date);
  };

  const renderExpirationSection = () => {
    return (
      <View>
        <View style={styles.limitationsHeader}>
          <MkiText textStyle="secondary">{I18n.t("CONFIGURE_IPSK.EXPIRE_TITLE")}</MkiText>
          <MkiText textStyle="smallSecondary">{I18n.t("CONFIGURE_IPSK.EXPIRE_SUBHEADER")}</MkiText>
        </View>
        <SwitchRow
          subtitle={I18n.t("CONFIGURE_IPSK.EXPIRE_SWITCH_SUBHEADER")}
          value={doesExpire}
          onValueChange={() => setDoesExpire(!doesExpire)}
          screenStyles={styles.expirationRow}
          testID={`EXPIRE.SWITCH_ROW`}
        >
          <MkiText>{I18n.t("CONFIGURE_IPSK.EXPIRE_SWITCH_TITLE")}</MkiText>
        </SwitchRow>
        {doesExpire ? (
          <DatePickerRow expiresAt={expiresAt} onConfirm={cleanAndSetExpiresAt} />
        ) : (
          <View style={styles.emptySpace}></View>
        )}
      </View>
    );
  };

  const showDeleteConfirmation = async () => {
    await showActionSheet(
      [I18n.t("CONFIGURE_IPSK.DELETE_ALERT.TITLE", { name })],
      () => {
        deleteIpsk.mutate(undefined, {
          onSuccess: () => {
            analytics.logEvent(FIREBASE_EVENTS.deleteIPSKGroup);
            navigation.goBack();
          },
          onError: errorHandler,
        });
      },
      {
        title: I18n.t("CONFIGURE_IPSK.DELETE_ALERT.MESSAGE"),
      },
    );
  };

  const renderDeleteSection = () => {
    return (
      <View style={styles.footerContainer}>
        <MkiText textStyle="secondary">{I18n.t("CONFIGURE_IPSK.DELETE_TITLE")}</MkiText>
        <MkiText textStyle="smallSecondary">{I18n.t("CONFIGURE_IPSK.DELETE_SUBHEADER")}</MkiText>
        <RoundedButton
          onPress={showDeleteConfirmation}
          buttonType={ButtonType.destructive}
          testID={"IPSK_DELETE_BUTTON"}
        >
          {I18n.t("CONFIGURE_IPSK.DELETE_BUTTON")}
        </RoundedButton>
      </View>
    );
  };

  return (
    <FullscreenContainerView withScroll>
      <InputRow
        value={name}
        description={I18n.t("CONFIGURE_IPSK.NAME_DESCRIPTION")}
        placeholder={I18n.t("CONFIGURE_IPSK.NAME_PLACEHOLDER")}
        onChangeText={(newName: any) => setName(newName)}
        testID={"IPSK_NAME"}
        clearTestID={"CLEAR_NAME"}
      >
        {I18n.t("CONFIGURE_IPSK.NAME")}
      </InputRow>
      <InputRow
        value={passphrase}
        description={I18n.t("CONFIGURE_IPSK.PASSWORD_DESCRIPTION")}
        placeholder={I18n.t("CONFIGURE_IPSK.PASSWORD_PLACEHOLDER")}
        onChangeText={(newPassphrase: any) => setPassphrase(newPassphrase)}
        testID={"IPSK_PASSWORD"}
        secureTextEntry={true}
        revealable={true}
        clearTestID={"CLEAR_NAME"}
      >
        {I18n.t("CONFIGURE_IPSK.PASSWORD")}
      </InputRow>
      <GroupPolicySlider onValueChange={setLimit} limitIndex={limitIndex} limitSpeed={limitSpeed} />
      {renderExpirationSection()}
      {ipsk && renderDeleteSection()}
      <LoadingSpinner
        visible={
          createIpsk.isLoading ||
          updateIpsk.isLoading ||
          deleteIpsk.isLoading ||
          createGroupPolicy.isLoading ||
          groupPolicyQuery.isLoading
        }
      />
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  limitationsHeader: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
  expirationRow: {
    paddingTop: SPACING.small,
    paddingBottom: SPACING.tiny,
  },
  emptySpace: {
    //ensures consistent spacing between Expiration and Delete sections
    paddingVertical: 20.1,
  },
  footerContainer: {
    justifyContent: "flex-end",
    paddingVertical: SPACING.large,
    paddingHorizontal: SPACING.default,
  },
});

export default ConfigureIpskScreen;
