import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { Button, Card, List, SearchBar, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useOrgNetworks, useTopAnnouncements } from "@meraki/shared/api";
import { isValidUrl } from "@meraki/shared/formatters";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useSearchTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import { Linking, Pressable, RefreshControl } from "react-native";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type SearchHistory = {
  searches: string[];
  addSearch: (_: string) => void;
  removeSearch: (_: string) => void;
};

const useSearchHistory = create<SearchHistory>()(
  persist(
    (set) => ({
      searches: [],
      addSearch: (newSearch: string) =>
        set((state) => ({ searches: [newSearch, ...state.searches].slice(0, 10) })),
      removeSearch: (removed: string) =>
        set((state) => ({ searches: state.searches.filter((search) => search !== removed) })),
    }),
    {
      name: "SearchHistory",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export const HelpScreen = () => {
  const organizationId = useCurrentOrganizationId();

  const {
    data: networks,
    refetch: refetchNetworks,
    isRefetching: networksIsRefetching,
  } = useOrgNetworks({ organizationId });
  const {
    data: communityData,
    refetch: refetchAnnouncements,
    isRefetching: announcementsIsRefetching,
  } = useTopAnnouncements({});
  const devicesNavigation = useNavigation<NativeStackNavigationProp<HelpStackProps>>();

  const { searches, addSearch, removeSearch } = useSearchHistory();
  const [currentSearch, setCurrentSearch] = useState("");
  const matchingTags = useSearchTags(currentSearch);

  const [showSearches, setShowSearches] = useState(false);

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={networksIsRefetching || announcementsIsRefetching}
          onRefresh={() => {
            refetchNetworks();
            refetchAnnouncements();
          }}
        />
      }
      addDefaultPadding
    >
      <SearchBar
        testID="HELP_SEARCH_BAR"
        onBlur={() => (currentSearch === "" ? setShowSearches(false) : undefined)}
        onFocus={() => setShowSearches(currentSearch !== "")}
        onChangeText={(newText) => {
          setCurrentSearch(newText);
          setShowSearches(newText !== "");
        }}
        value={currentSearch}
        onSubmitEditing={() => {
          if (currentSearch !== "") {
            addSearch(currentSearch);
          } else {
            setShowSearches(false);
          }
          devicesNavigation.navigate("SearchResults", { query: currentSearch });
          setCurrentSearch("");
        }}
      />
      {showSearches ? (
        <Box>
          <List.FlashList
            paddingLeft="none"
            paddingRight="none"
            data={[
              ...searches.map((search) => ({ search, type: I18n.t("HELP.HISTORY") })),
              ...matchingTags.map((tag) => ({ tag, type: I18n.t("HELP.TAGS") })),
            ]}
            groupBy={(datum) => datum.type}
            getItemData={(datum) => {
              if ("tag" in datum) {
                return {
                  title: datum.tag,
                  onPress: () => {
                    setCurrentSearch(datum.tag);
                    devicesNavigation.navigate("SearchResults", { query: datum.tag });
                  },
                  hidePressable: true,
                };
              } else {
                return {
                  title: datum.search,
                  onPress: () => {
                    setCurrentSearch(datum.search);
                    devicesNavigation.navigate("SearchResults", { query: datum.search });
                  },
                  rightAccessory: (
                    <Button.Icon icon="XCircle" onPress={() => removeSearch(datum.search)} />
                  ),
                  hidePressable: true,
                };
              }
            }}
          />
        </Box>
      ) : (
        <>
          <Box justifyContent="space-between" flexDirection="row" gap="sm">
            <Card padding="lg" flex={1}>
              <Pressable
                onPress={() => {
                  devicesNavigation.navigate("DevicesList");
                }}
              >
                <Box flexDirection="row" alignItems="center" gap="sm">
                  <Icon name="TreeStructure" />
                  <Text weight="bold" size="p1">
                    {I18n.t("HELP.DEVICES")}
                  </Text>
                  <Text color="light" size="p1">
                    {networks?.length}
                  </Text>
                </Box>
              </Pressable>
            </Card>
          </Box>
          <List label={I18n.t("HELP.COMMUNITY_RESOURCES")}>
            <List.Item
              title={communityData?.data.items[0]?.subject}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    const url = String(communityData?.data.items[0]?.view_href);
                    if (isValidUrl(url)) Linking.openURL(url);
                  }}
                />
              }
            />
            <List.Item
              title={communityData?.data.items[1]?.subject}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    const url = String(communityData?.data.items[1]?.view_href);
                    if (isValidUrl(url)) Linking.openURL(url);
                  }}
                />
              }
            />
            <List.Item
              title={communityData?.data.items[2]?.subject}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    const url = String(communityData?.data.items[2]?.view_href);
                    if (isValidUrl(url)) Linking.openURL(url);
                  }}
                />
              }
            />
          </List>

          <List label={I18n.t("HELP.DOCUMENTATION")}>
            <List.Item
              title={I18n.t("HELP.TROUBLESHOOTING")}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    Linking.openURL("https://documentation.meraki.com/Go/Troubleshooting");
                  }}
                />
              }
            />
            <List.Item
              title={I18n.t("HELP.PRODUCT_DATASHEETS")}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    Linking.openURL("https://documentation.meraki.com/Go/Meraki_Go_Datasheets");
                  }}
                />
              }
            />
            <List.Item
              title={I18n.t("HELP.FAQ")}
              rightAccessory={
                <Button.Icon
                  icon="ArrowSquareOut"
                  onPress={() => {
                    Linking.openURL("https://www.meraki-go.com/faqs/");
                  }}
                />
              }
            />
          </List>
          <List>
            <List.Item
              title={I18n.t("WISH.FEEDBACK")}
              description={I18n.t("WISH.DESCRIPTION")}
              rightAccessory={
                <Pressable onPress={() => devicesNavigation.navigate("Wish")}>
                  <Text size="p1" color="accent.a.hover">
                    {I18n.t("WISH.MAKE_A_WISH")}
                  </Text>
                </Pressable>
              }
              leftAccessory={<Icon name="MagicWand" />}
            />
          </List>
        </>
      )}
    </Screen>
  );
};
