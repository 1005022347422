import { I18n } from "@meraki/core/i18n";
import { useAdmins } from "@meraki/shared/api";
import { otpAppUrl } from "@meraki/shared/links";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, COUNTRIES, KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import PickerModal from "~/go/components/PickerModal";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import {
  currentOrganization,
  currentUserState,
  getHasBetaFeatures,
  getLastTwoDigitOfBackupTFA,
  isTwoFactorAuthEnforced,
} from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import CountryIcon from "~/shared/components/icons/CountryIcon";
import InputModal from "~/shared/components/InputModal";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CloseButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { Alert } from "~/shared/types/AlertTypes";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { TwoFactorScreensPropMap } from "../navigation/Types";

const PHONE_NUMBER_REGEX = /^\d{4,13}$/;
const CODE_REGEX = /^\d{6}$/;

export enum BackupStatus {
  start = "start",
  reauthed = "reauthed",
  smsSent = "smsSent",
  enabled = "enabled",
}
const BACKUP_STATUS_WITH_PASSWORD = [BackupStatus.start, BackupStatus.enabled];

type ReduxProps = {
  backupTwoFactor: string;
  isTwoFactorEnforced: boolean;
  hasMobileBetaFeatures: boolean;
};

type Props = ForwardedNativeStackScreenProps<TwoFactorScreensPropMap, "IntroTwoFactor"> &
  BaseOnboardingScreenProps &
  ReduxProps &
  BasicActions &
  WithCancelablePromiseProps &
  PendingComponent;

export interface IntroTwoFactorScreenState {
  showDisableTwoFactorModal: boolean;
  modalAlert?: Alert;
  disableTwoFactorLoading: boolean;
  stagedPassword: string;
  showBackupSMSModal: boolean;
  backupStatus: BackupStatus;
  stagedPhoneNumber: string;
  stagedCode: string;
  setupBackupSMSInProgress: boolean;
  country: string;
  showCountryCodePicker: boolean;
}

const getCountryRows = (hasMobileBetaFeatures: boolean) => {
  return Object.entries(COUNTRIES)
    .filter(([countryCode, { phoneCode }]) => {
      return phoneCode && (hasMobileBetaFeatures || countryCode !== "JP");
    })
    .map(([countryCode, { name }]) => {
      return {
        label: I18n.t(name),
        value: countryCode,
      };
    });
};

const getCountryPhoneCode = (country: string) => {
  const { phoneCode, emoji } = COUNTRIES[country];
  return {
    phoneCode,
    emoji,
  };
};

function IntroTwoFactorScreenFC(props: Props & IntroTwoFactorScreenState) {
  const organizationId = useAppSelector(currentOrganization)?.id ?? "";
  const userEmail = useAppSelector(currentUserState);
  const { data } = useAdmins({ organizationId });
  const twoFactorEnabled =
    data?.find((admin) => admin.email.toLowerCase() === userEmail.toLowerCase())
      ?.twoFactorAuthEnabled ?? false;
  return <IntroTwoFactorScreen {...props} twoFactorEnabled={twoFactorEnabled} />;
}

export class IntroTwoFactorScreen extends BaseOnboardingScreen<Props, IntroTwoFactorScreenState> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions = () => {
    const { navigation, presentedModally, actions, forcingTwoFactor, twoFactorEnabled } =
      this.props;

    if (presentedModally) {
      navigation.setOptions({
        headerLeft: () => (
          <CloseButton
            onPress={() => {
              if (forcingTwoFactor && !twoFactorEnabled) {
                actions.logoutUser();
              } else {
                navigation.goBack();
              }
            }}
          />
        ),
      });
    }
  };

  state: IntroTwoFactorScreenState = {
    stagedPassword: "",
    stagedPhoneNumber: "",
    stagedCode: "",
    backupStatus: BackupStatus.start,
    showDisableTwoFactorModal: false,
    modalAlert: undefined,
    disableTwoFactorLoading: false,
    showBackupSMSModal: false,
    setupBackupSMSInProgress: false,
    country: "US",
    showCountryCodePicker: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { backupTwoFactor } = this.props;
    if (backupTwoFactor) {
      this.setState({ backupStatus: BackupStatus.enabled });
    }
  }

  onSecondaryPress = () => {
    const { secondaryStageConfig } = this;
    const { completeOnboarding } = this.props;

    if (secondaryStageConfig.nextStage === OnboardingStage.exit) {
      completeOnboarding();
    } else {
      this.pushOnboardingScreen(secondaryStageConfig.nextStage);
    }
  };

  handleError = (error: string) => {
    this.setState({ modalAlert: { message: error, status: GoStatus.bad } });
  };

  handleErrorAlert = (error: string) => showAlert(I18n.t("ERROR"), error);

  getData = async () => {
    const { actions, setReqPending, forcingTwoFactor, cancelablePromise } = this.props;

    setReqPending(true);
    try {
      const reqs = forcingTwoFactor
        ? [actions.getTwoFactorInfo()]
        : [actions.getTwoFactorInfo(), actions.fetchLoginSecurity()];
      await cancelablePromise(Promise.all(reqs));
    } catch (error) {
      if (!forcingTwoFactor) {
        if (typeof error === "string") {
          this.handleErrorAlert(error);
        }
      }
    }
    setReqPending(false);
  };

  enableTwoFactor = async () => {
    const { actions, setReqPending } = this.props;

    setReqPending(true);
    try {
      await actions.enableTwoFactor();
    } catch (error) {
      if (typeof error === "string") {
        this.handleErrorAlert(error);
      }
    }
    setReqPending(false);
  };

  disableTwoFactor = async () => {
    const { actions, navigation, isOnboarding } = this.props;
    const { stagedPassword } = this.state;

    this.setState({ disableTwoFactorLoading: true });
    try {
      const reauthResponse = await actions.reauthforTwoFactor(stagedPassword);
      if (!reauthResponse) {
        throw I18n.t("ERROR");
      }
      const { success, message } = reauthResponse.response;
      if (success) {
        await actions.disableTwoFactor();
      } else {
        throw message;
      }
      this.setState({ disableTwoFactorLoading: false });

      if (isOnboarding) {
        this.onSecondaryPress();
      }
      navigation.popToTop();
    } catch (error) {
      if (typeof error === "string") {
        this.handleErrorAlert(error);
      }
    }
    this.setState({ disableTwoFactorLoading: false });
  };

  setOrgWideEnforcement = async (enforceTwoFactorAuth: boolean) => {
    const { actions, setReqPending } = this.props;

    setReqPending(true);
    try {
      await actions.updateLoginSecurity({ enforceTwoFactorAuth });
    } catch (error) {
      if (typeof error === "string") {
        this.handleErrorAlert(error);
      }
    }
    setReqPending(false);
  };

  processBackupSMS = async () => {
    const { actions } = this.props;
    const { backupStatus, stagedPassword, stagedPhoneNumber, stagedCode, country } = this.state;
    const newPhoneNumber = getCountryPhoneCode(country).phoneCode + stagedPhoneNumber;
    this.setState({ setupBackupSMSInProgress: true, modalAlert: undefined });
    try {
      switch (backupStatus) {
        case BackupStatus.start:
          const reauthResponse = await actions.reauthforTwoFactor(stagedPassword);
          if (!reauthResponse) {
            throw I18n.t("ERROR");
          }

          const reauthJSON = reauthResponse.response;
          if (!reauthJSON.success) {
            throw reauthJSON.message ?? I18n.t("ERROR");
          }
          this.setState({ backupStatus: BackupStatus.reauthed, stagedPassword: "" });
          break;
        case BackupStatus.reauthed:
          if (!PHONE_NUMBER_REGEX.test(stagedPhoneNumber)) {
            throw I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.INVALID_PHONE");
          }

          await actions.sendTwoFactorBackupCode(newPhoneNumber);
          this.setState({ backupStatus: BackupStatus.smsSent });
          break;
        case BackupStatus.smsSent:
          if (!CODE_REGEX.test(stagedCode)) {
            throw I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.INVALID_CODE");
          }

          await actions.verifyTwoFactorWorks(stagedCode, newPhoneNumber, true);
          const enableSMSResponse = await actions.enableBackup();
          if (!enableSMSResponse) {
            throw I18n.t("ERROR");
          }

          const enableSMSJSON = enableSMSResponse.response;
          if (!enableSMSJSON.success) {
            throw enableSMSJSON.error ?? I18n.t("ERROR");
          }

          this.setState({
            backupStatus: BackupStatus.enabled,
            stagedPhoneNumber: "",
            stagedCode: "",
            showBackupSMSModal: false,
          });
          await actions.getTwoFactorInfo();
          break;
        case BackupStatus.enabled:
          const reauthForDisableResponse = await actions.reauthforTwoFactor(stagedPassword);
          if (!reauthForDisableResponse) {
            throw I18n.t("ERROR");
          }

          const reauthForDisableJSON = reauthForDisableResponse.response;
          if (reauthForDisableJSON.success) {
            await actions.removeBackup();
          } else {
            throw reauthForDisableJSON.message ?? I18n.t("ERROR");
          }

          this.setState({
            backupStatus: BackupStatus.start,
            stagedPassword: "",
            showBackupSMSModal: false,
          });
          await actions.getTwoFactorInfo();
          break;
      }
    } catch (error) {
      if (typeof error === "string") {
        this.handleErrorAlert(error);
      }
    } finally {
      this.setState({ setupBackupSMSInProgress: false });
    }
  };

  renderDisableHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("INTRO_TWO_FACTOR.DISABLE.HEADER.TITLE")}
        description={I18n.t("INTRO_TWO_FACTOR.DISABLE.HEADER.DESCRIPTION")}
      />
    );
  };

  renderDisableButton = () => {
    const { isTwoFactorEnforced } = this.props;
    return (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType={ButtonType.secondary}
          disabled={isTwoFactorEnforced}
          onPress={() => this.setState({ showDisableTwoFactorModal: true })}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("INTRO_TWO_FACTOR.DISABLE.DISABLE_BUTTON")}
        </RoundedButton>
      </View>
    );
  };

  renderOrgWideTFAToggle = () => {
    const { isTwoFactorEnforced } = this.props;
    return (
      <SwitchRow
        subtitle={I18n.t("INTRO_TWO_FACTOR.DISABLE.TOGGLE.SUBTITLE")}
        value={isTwoFactorEnforced}
        onValueChange={this.setOrgWideEnforcement}
        screenStyles={styles.orgWideToggle}
        testID="TWO_FACTOR.ORG_WIDE_TOGGLE"
      >
        {I18n.t("INTRO_TWO_FACTOR.DISABLE.TOGGLE.TITLE")}
      </SwitchRow>
    );
  };

  renderBackupPhoneRow = () => {
    const { backupTwoFactor } = this.props;

    return (
      <SwitchRow
        subtitle={
          backupTwoFactor
            ? I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLED.SUBTITLE", { lastTwoDigits: backupTwoFactor })
            : I18n.t("INTRO_TWO_FACTOR.BACKUP.DISABLED.SUBTITLE", {
                lastTwoDigits: backupTwoFactor,
              })
        }
        value={!!backupTwoFactor}
        onValueChange={() => this.setState({ showBackupSMSModal: true })}
        screenStyles={styles.orgWideToggle}
        testID="TWO_FACTOR.BACKUP_TOGGLE"
      >
        {backupTwoFactor
          ? I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLED.TITLE")
          : I18n.t("INTRO_TWO_FACTOR.BACKUP.DISABLED.TITLE")}
      </SwitchRow>
    );
  };

  renderCannotDisableTFAText = () => {
    return (
      <MkiText screenStyles={styles.cannotDisableText} textStyle="smallSecondary">
        {I18n.t("INTRO_TWO_FACTOR.DISABLE.CANNOT_DISABLE_TEXT")}
      </MkiText>
    );
  };

  renderTwoFactorDisableModal = () => {
    const { stagedPassword, modalAlert, disableTwoFactorLoading, showDisableTwoFactorModal } =
      this.state;
    return (
      <InputModal
        value={stagedPassword}
        title={I18n.t("INTRO_TWO_FACTOR.DISABLE.DISABLE_MODAL.TITLE")}
        subtitle={I18n.t("INTRO_TWO_FACTOR.DISABLE.DISABLE_MODAL.SUBTITLE")}
        primaryButtonText={I18n.t("INTRO_TWO_FACTOR.DISABLE.DISABLE_MODAL.PRIMARY_BUTTON")}
        visible={showDisableTwoFactorModal}
        onPrimaryPress={this.disableTwoFactor}
        onChangeText={(stagedPassword: string) => this.setState({ stagedPassword })}
        onExit={() => this.setState({ stagedPassword: "", showDisableTwoFactorModal: false })}
        alert={modalAlert}
        loading={disableTwoFactorLoading}
        secureTextEntry
      />
    );
  };

  getModalSubtitleForBackupModal = () => {
    const { backupStatus } = this.state;

    switch (backupStatus) {
      case BackupStatus.start:
        return I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.REAUTH");
      case BackupStatus.reauthed:
        return I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.PHONE_NUMBER");
      case BackupStatus.smsSent:
        return I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.VERIFY");
      default:
        return I18n.t("INTRO_TWO_FACTOR.BACKUP.DISABLE_MODAL.SUBTITLE");
    }
  };

  getStagedValueForBackup = () => {
    const { backupStatus, stagedPassword, stagedPhoneNumber, stagedCode } = this.state;

    switch (backupStatus) {
      case BackupStatus.reauthed:
        return stagedPhoneNumber;
      case BackupStatus.smsSent:
        return stagedCode;
      default:
        return stagedPassword;
    }
  };

  getValueMaxLengthForBackup = () => {
    const { backupStatus } = this.state;

    switch (backupStatus) {
      case BackupStatus.reauthed:
        return 13;
      case BackupStatus.smsSent:
        return 6;
      default:
        return undefined;
    }
  };

  setPlaceholderText = (): string => {
    const { backupStatus } = this.state;

    switch (backupStatus) {
      case BackupStatus.reauthed:
        return I18n.t("INTRO_TWO_FACTOR.PLACEHOLDER.PHONE");
      case BackupStatus.smsSent:
        return I18n.t("INTRO_TWO_FACTOR.PLACEHOLDER.SMS_CODE");
      default:
        return I18n.t("INTRO_TWO_FACTOR.PLACEHOLDER.PASSWORD");
    }
  };

  setStagedValueForBackup = (newValue: string) => {
    const { backupStatus } = this.state;
    switch (backupStatus) {
      case BackupStatus.reauthed:
        this.setState({ stagedPhoneNumber: newValue });
        break;
      case BackupStatus.smsSent:
        this.setState({ stagedCode: newValue });
        break;
      default:
        this.setState({ stagedPassword: newValue });
        break;
    }
  };

  renderBackupSMSModal = () => {
    const { showBackupSMSModal, backupStatus, setupBackupSMSInProgress, modalAlert, country } =
      this.state;

    const isBackupEnabled = backupStatus === BackupStatus.enabled;

    const isPasswordInput = BACKUP_STATUS_WITH_PASSWORD.includes(backupStatus);
    const keyboardType = isPasswordInput ? KEYBOARD_TYPE.default : KEYBOARD_TYPE.numeric;

    return (
      <InputModal
        placeholderTextColor={MkiColors.whiteTextFaded}
        placeholder={this.setPlaceholderText()}
        value={this.getStagedValueForBackup()}
        maxLength={this.getValueMaxLengthForBackup()}
        title={
          isBackupEnabled
            ? I18n.t("INTRO_TWO_FACTOR.BACKUP.DISABLE_MODAL.TITLE")
            : I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.TITLE")
        }
        subtitle={this.getModalSubtitleForBackupModal()}
        keyboardType={keyboardType}
        primaryButtonText={
          isBackupEnabled
            ? I18n.t("INTRO_TWO_FACTOR.BACKUP.DISABLE_MODAL.PRIMARY_BUTTON")
            : I18n.t("INTRO_TWO_FACTOR.BACKUP.ENABLE_MODAL.PRIMARY_BUTTON")
        }
        visible={showBackupSMSModal}
        onPrimaryPress={this.processBackupSMS}
        onChangeText={this.setStagedValueForBackup}
        onExit={() =>
          this.setState({
            stagedPassword: "",
            stagedPhoneNumber: "",
            stagedCode: "",
            modalAlert: undefined,
            showBackupSMSModal: false,
          })
        }
        alert={modalAlert}
        loading={setupBackupSMSInProgress}
        loginStyle={isPasswordInput}
        secureTextEntry={isPasswordInput}
        icon={
          backupStatus === BackupStatus.reauthed ? (
            <CountryIcon
              onPress={() => this.setState({ showCountryCodePicker: true })}
              countryPhoneCode={getCountryPhoneCode(country)}
            />
          ) : undefined
        }
      />
    );
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("INTRO_TWO_FACTOR.ENABLE.HEADER.TITLE")}
        description={I18n.t("INTRO_TWO_FACTOR.ENABLE.HEADER.DESCRIPTION")}
      />
    );
  };

  renderForceTFAInfoModal = () => {
    const { forcingTwoFactor } = this.props;
    if (!forcingTwoFactor) {
      return null;
    }

    return (
      <BannerAlert
        testID="TFA_REQUIRED.ALERT"
        alertType={GoStatus.bad}
        alertText={I18n.t("INTRO_TWO_FACTOR.TFA_REQUIRED.ALERT")}
        screenStyles={styles.forceTwoFactorBanner}
      />
    );
  };

  renderReturnText = () => {
    return (
      <View>
        <MkiText textStyle="secondary" screenStyles={styles.returnText}>
          {I18n.t("INTRO_TWO_FACTOR.ENABLE.COME_BACK_TO_APP")}
        </MkiText>
      </View>
    );
  };

  renderNoteOnOrgText = () => {
    return (
      <View>
        <MkiText textStyle="secondary" screenStyles={styles.entireOrgText}>
          <MkiText textStyle="secondary" screenStyles={styles.noteText}>
            {I18n.t("INTRO_TWO_FACTOR.ENABLE.NOTE")}
          </MkiText>
          {I18n.t("INTRO_TWO_FACTOR.ENABLE.ENABLE_ON_ENTIRE_ORG")}
        </MkiText>
      </View>
    );
  };

  renderGetAuthApp = () => (
    <View style={styles.buttonContainer}>
      <RoundedButton
        buttonType={ButtonType.secondary}
        onPress={otpAppUrl}
        screenStyles={styles.buttonStyle}
      >
        {I18n.t("INTRO_TWO_FACTOR.ENABLE.GET_APP_BUTTON")}
      </RoundedButton>
    </View>
  );

  renderCountryCodeModal = () => {
    const { country, showCountryCodePicker } = this.state;
    const { hasMobileBetaFeatures } = this.props;
    return (
      <PickerModal
        selectedValue={country}
        visible={showCountryCodePicker}
        title={I18n.t("INTRO_TWO_FACTOR.COUNTRY_CODE_MODAL.TITLE")}
        items={getCountryRows(hasMobileBetaFeatures)}
        onValueSelect={(value) =>
          this.setState({ showCountryCodePicker: false, country: value as string })
        }
        onExit={() => this.setState({ showCountryCodePicker: false })}
      />
    );
  };

  renderEnableTwoFactorFlow = () => {
    return (
      <>
        {this.renderForceTFAInfoModal()}
        {this.renderHeader()}
        {this.renderGetAuthApp()}
        {this.renderReturnText()}
        {this.renderNoteOnOrgText()}
      </>
    );
  };

  renderDisableTwoFactorFlow = () => {
    return (
      <>
        {this.renderDisableHeader()}
        {this.renderOrgWideTFAToggle()}
        {this.renderBackupPhoneRow()}
        {this.renderCannotDisableTFAText()}
        {this.renderDisableButton()}
        {this.renderBackupSMSModal()}
        {this.renderCountryCodeModal()}
      </>
    );
  };

  renderBody = () => {
    const { twoFactorEnabled } = this.props;
    return (
      <FullScreenContainerView withScroll>
        {!twoFactorEnabled ? this.renderEnableTwoFactorFlow() : this.renderDisableTwoFactorFlow()}
        {this.renderTwoFactorDisableModal()}
      </FullScreenContainerView>
    );
  };

  renderSkipButton = () => {
    const { isOnboarding, forcingTwoFactor, twoFactorEnabled } = this.props;

    if (!isOnboarding || (twoFactorEnabled && !forcingTwoFactor)) {
      return undefined;
    }

    return (
      <RoundedButton buttonType={ButtonType.secondary} onPress={() => this.onSecondaryPress()}>
        {I18n.t("SKIP")}
      </RoundedButton>
    );
  };

  getFooterData = () => {
    const { twoFactorEnabled } = this.props;
    return {
      buttons: twoFactorEnabled
        ? [this.renderSkipButton()]
        : [
            <RoundedButton key="NEXT_STAGE" onPress={() => this.onPrimaryPress()}>
              {this.nextStageConfig.nextButtonText}
            </RoundedButton>,
            this.renderSkipButton(),
          ],
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.introTwoFactor);

  secondaryStageConfig = getNextOnboardingStageConfig(OnboardingStage.introTwoFactor, {
    skipTwoFactor: true,
  });
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.extraLarge,
    marginBottom: SPACING.extraLarge,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  returnText: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
  },
  noteText: {
    fontWeight: "bold",
  },
  entireOrgText: {
    paddingHorizontal: SPACING.default,
    marginTop: SPACING.extraLarge,
  },
  forceTwoFactorBanner: {
    paddingHorizontal: SPACING.default,
    marginHorizontal: SPACING.default,
    width: undefined,
  },
  cannotDisableText: {
    paddingHorizontal: SPACING.default,
    paddingTop: SPACING.large,
  },
  orgWideToggle: {
    marginTop: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  const reduxTwoFactorState = isTwoFactorAuthEnforced(state);
  const isTwoFactorEnforced = reduxTwoFactorState === undefined ? false : reduxTwoFactorState;
  return {
    backupTwoFactor: getLastTwoDigitOfBackupTFA(state),
    isTwoFactorEnforced,
    hasMobileBetaFeatures: getHasBetaFeatures(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withPendingComponent,
)(IntroTwoFactorScreenFC);
