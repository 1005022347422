import Svg, { Path, Rect } from "react-native-svg";

import { useThemeColors } from "~/shared/hooks/useTheme";

export const ElekidEmptyStateIcon = () => {
  const colors = useThemeColors();

  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Path
        d="M17 30.5L18.5 23L12.5 20.75L23 9.5L21.5 17L27.5 19.25L17 30.5Z"
        fill={colors.status.dormant.color}
      />
      <Rect
        x="1"
        y="1"
        width="38"
        height="38"
        rx="19"
        stroke={colors.status.dormant.color}
        strokeWidth="2"
      />
    </Svg>
  );
};

export default ElekidEmptyStateIcon;
