import { I18n } from "@meraki/core/i18n";
import { Dimensions, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING, WINDOW } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { hasGXWithVPNSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import IndicatorViewPager from "~/shared/components/viewPager/IndicatorViewPager";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  hasGXWithVPN: boolean;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "UmbrellaExplainer"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

enum UmbrellaExplainerSlide {
  SECURITY_GATEWAY = "ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.SECURITY_GATEWAY",
  BLOCK_WEBSITES = "ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.BLOCK_WEBSITES",
  BLOCK_DOWNLOADS = "ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.BLOCK_DOWNLOADS",
}

export class UmbrellaExplainer extends BaseOnboardingScreen<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.umbrellaExplainer);
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.close} />,
    });
  }

  getSlideText = (slide: UmbrellaExplainerSlide) => {
    const { isOnboarding } = this.props;
    let slideText = I18n.t(slide);

    if (slide === UmbrellaExplainerSlide.SECURITY_GATEWAY && isOnboarding) {
      slideText =
        slideText.slice(0, -1) + I18n.t("ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.TAP_NEXT");
    }

    return slideText;
  };

  renderPage = (slide: UmbrellaExplainerSlide, name: string) => {
    const size = Dimensions.get(WINDOW).width / 3;
    return (
      <View style={styles.slideContainer} key={slide}>
        <View style={styles.slideImageContainer}>
          <MerakiIcon size={size} name={name} />
        </View>
        <MkiText textStyle="smallSecondary" screenStyles={styles.slide}>
          {this.getSlideText(slide)}
        </MkiText>
      </View>
    );
  };

  // TODO: We should look into using images instead of SVGs for these slides so that the
  // image sized can be consistent between the onboarding screens.
  getPages = () => [
    this.renderPage(UmbrellaExplainerSlide.SECURITY_GATEWAY, "go-globe"),
    this.renderPage(UmbrellaExplainerSlide.BLOCK_WEBSITES, "go-search"),
    this.renderPage(UmbrellaExplainerSlide.BLOCK_DOWNLOADS, "go-malware"),
  ];

  renderBody = () => {
    const { isOnboarding } = this.props;
    const style = isOnboarding ? styles.flexView : styles.modalView;

    return <IndicatorViewPager style={style}>{this.getPages()}</IndicatorViewPager>;
  };

  getFooterData = () => {
    const { isOnboarding } = this.props;
    return {
      buttons: isOnboarding
        ? [
            <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
              {this.nextStageConfig.nextButtonText}
            </RoundedButton>,
          ]
        : [],
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.umbrellaExplainer, {
    hasGXWithVPN: this.props.hasGXWithVPN,
  });
}

const styles = StyleSheet.create({
  flexView: {
    flex: 1,
  },
  modalView: {
    flex: 1,
    marginBottom: SPACING.default,
  },
  slideContainer: {
    alignItems: "center",
    paddingVertical: SPACING.extraLarge,
    paddingHorizontal: SPACING.default,
  },
  slideImageContainer: {
    padding: SPACING.default,
  },
  slide: {
    marginTop: SPACING.default,
    textAlign: "center",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasGXWithVPN: hasGXWithVPNSelector(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(UmbrellaExplainer);
