import {
  registerFetchErrorListener,
  safeParseResponseJson,
  setHeadersBuilder,
  setUriBuilder,
} from "@meraki/shared/api";
import { IDLE_TIMEOUT_SESSION_EXPIRED, WIPE_REDUX } from "@meraki/shared/redux";

import { makeFullURL } from "~/api/util/url";
import { analytics } from "~/lib/FirebaseModules";
import { currentShardIdState, getCurrentCluster } from "~/selectors";
import { store } from "~/store/configureStore";

import { buildAppHeaders, IDLE_TIMEOUT_ERROR, LOGIN_ERROR_PLEASE_TRY_AGAIN } from "./migrationUtil";

export const buildUrl = (endpoint: string) => {
  const reduxState = store.getState();
  const cluster = getCurrentCluster(reduxState);
  const shardId = currentShardIdState(reduxState);

  return makeFullURL({
    endpoint,
    shardId,
    cluster,
  });
};

interface ErrorResponse {
  errors: string[];
}

export async function handleLoginErrorResponse(response: Response) {
  const responseJson: ErrorResponse = await safeParseResponseJson(response);
  if (responseJson?.errors?.includes(LOGIN_ERROR_PLEASE_TRY_AGAIN)) {
    store.dispatch({ type: WIPE_REDUX });
  }
}

export async function handleIdleTimeoutResponse(response: Response) {
  const responseJson: ErrorResponse = await safeParseResponseJson(response);
  if (responseJson?.errors?.includes(IDLE_TIMEOUT_ERROR)) {
    analytics.logEvent(IDLE_TIMEOUT_ERROR);
    store.dispatch({ type: WIPE_REDUX });
    store.dispatch({ type: IDLE_TIMEOUT_SESSION_EXPIRED });
  }
}

export default function initApiMigration() {
  registerFetchErrorListener(handleIdleTimeoutResponse);
  registerFetchErrorListener(handleLoginErrorResponse);

  setUriBuilder(buildUrl);

  setHeadersBuilder(buildAppHeaders);
}
