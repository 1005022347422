import { RUSAGE_KEY } from "@meraki/shared/api";
import { processRusage, RUsageApplicationData } from "@meraki/shared/formatters";
import { createSelector } from "reselect";

import { counterSetsState } from "~/selectors/entities";
import { currentNetworkState, ruleUseblocksState, ssidUseblocks } from "~/selectors/getters";
import { networkUseblocksSelector } from "~/selectors/networks";
import { timespanState } from "~/selectors/preferences";
import { RootState } from "~/shared/types/Redux";

export const applicationUseblocksSelector = createSelector(
  currentNetworkState,
  ruleUseblocksState,
  timespanState,
  (_: RootState, props: any) => props.applicationId,
  (networkId, ruleUseblocks, timespan, applicationId) =>
    networkId ? ruleUseblocks?.[networkId]?.[timespan]?.[RUSAGE_KEY]?.[applicationId] : undefined,
);

export const ssidUseblocksState = createSelector(
  timespanState,
  currentNetworkState,
  ssidUseblocks,
  (_: RootState, props: any) => props.ssidNumber,
  (timespan, currentNetwork, ssidUseblocksState, ssidNumber) => {
    if (!Number.isInteger(ssidNumber)) {
      return null;
    }
    const id = `${currentNetwork}:${ssidNumber}:${timespan}`;
    return ssidUseblocksState.items[id];
  },
);

export const applicationUsageSelector = createSelector(
  networkUseblocksSelector,
  ssidUseblocksState,
  counterSetsState,
  (_, props) => props.groupApplications || false,
  (networkUsage, ssidUsage, counterSets, grouped) => {
    const useBlocks = ssidUsage || networkUsage;
    const rusage = useBlocks?.rusage?.["19"];
    const rules = counterSets?.["19"]?.rules;
    if (!rusage || !rules) {
      return [];
    }

    const applicationUsages = processRusage(rusage, rules, { noGroup: !grouped });
    const totalUsage = applicationUsages.reduce(
      (total: any, app: any) => total + app.totalUsage,
      0,
    );
    return applicationUsages.map((app: RUsageApplicationData) => ({
      id: app?.hiUse?.hiUseAppId,
      group: app.group,
      children: app.label,
      usage: app.totalUsage,
      percent: app.totalUsage / totalUsage,
      color: app?.color,
    }));
  },
);
