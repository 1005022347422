import { ScreenInfo, ScreenScope, screensMap, Tag, TAGS, tagsMap } from "./registerTags";

type SearchResultOptions = {
  type?: string;
};

export const useSearchTags = (partialTag: string) => {
  return TAGS.filter((tag) => tag.includes(partialTag.toLowerCase()));
};

export const useSearchTagsMap = (
  tag: string,
  scope: ScreenScope,
  options?: SearchResultOptions,
) => {
  const navigations = tagsMap[tag as Tag];

  if (navigations) {
    return navigations.reduce<(ScreenInfo & SearchResultOptions)[]>((result, navigation) => {
      const foundScreen = screensMap[navigation];
      if (foundScreen && (foundScreen.scope == null || foundScreen.scope === scope)) {
        result.push({ ...foundScreen, ...options });
      }
      return result;
    }, []);
  }

  return [];
};
