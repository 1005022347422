import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Input,
  List,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, Ssid, useDevices, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useRef, useState } from "react";
import { Alert, RefreshControl } from "react-native";

import { BroadcastingBottomSheet } from "../../components/BroadcastingBottomSheet";
import { useSSIDContext } from "../SSIDContext";

export const SettingsTab = () => {
  const { ssid } = useSSIDContext();
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const {
    data: wirelessDevices,
    refetch,
    isRefetching,
  } = useDevices(
    { organizationId, networkId },
    {
      select: (data) => data.filter(({ productType }) => productType === "wireless"),
    },
  );

  const updateSsid = useUpdateSsid();

  const [displayMore, setDisplayMore] = useState<boolean>(false);
  const broadcastingBottomSheetRef = useRef<BottomSheetMethods>(null);
  const vlanIDBottomSheetRef = useRef<BottomSheetMethods>(null);
  const [vlanID, setVlanID] = useState(ssid?.defaultVlanId);

  const helperText =
    !vlanID || vlanID < 1 || vlanID > 4096 ? I18n.t("VLAN_TAGGING.VLAN_ID.INVALID") : "";

  const updateSettings = useCallback(
    (body: Partial<Ssid>) => {
      updateSsid.mutate(
        { networkId, ssidNumber: ssid?.number, body },
        {
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: useSsids.queryKeyRoot });
            vlanIDBottomSheetRef.current?.dismiss();
          },
          onError: (error) => {
            Alert.alert(String(error["errors"]));
          },
        },
      );
    },
    [networkId, ssid?.number, updateSsid],
  );

  const numberOfBroadcastingAPs =
    wirelessDevices?.filter(({ tags }) => ssid?.availabilityTags?.some((tag) => tags.includes(tag)))
      ?.length ?? 0;

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={isRefetching}
          onRefresh={() => {
            refetch();
          }}
        />
      }
      addDefaultPadding
    >
      <List>
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.WIRELESS_MODE.TITLE")}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.ipAssignmentMode}
            </Text>
          }
          onPress={() => navigation.navigate("WirelessMode", { ssidNumber: ssid?.number })}
          testID={"SSID.WIRELESS_MODE"}
        />
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.RADIO_SETTINGS.TITLE")}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.bandSelection}
            </Text>
          }
          onPress={() => navigation.navigate("RadioSettings", { ssidNumber: ssid?.number })}
        />
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.WPA_SETTINGS.TITLE")}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.wpaEncryptionMode}
            </Text>
          }
          onPress={() => navigation.navigate("WPASettings", { ssidNumber: ssid?.number })}
          testID={"SSID.WPA_SETTINGS"}
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.TITLE")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.availableOnAllAps
                ? I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.ALL_AVAILABLE")
                : numberOfBroadcastingAPs <= 1
                  ? I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.ONE_AP", {
                      numberOfAPs: numberOfBroadcastingAPs,
                    })
                  : I18n.t("SSID_SCREEN.TABS.SETTINGS.BROADCAST.MANY_AP", {
                      numberOfAPs: numberOfBroadcastingAPs,
                    })}
            </Text>
          }
          onPress={() => broadcastingBottomSheetRef?.current?.present()}
          testID={"SSID.BROADCASTING"}
        />
        <List.Item
          title={"Schedules"}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {"Enabled"}
            </Text>
          }
          onPress={() => navigation.navigate("Schedules", { ssidNumber: ssid?.number })}
          testID={"SSID.BROADCASTING"}
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.VLAN_TAGGING.TITLE")}
          description={I18n.t("SSID_SCREEN.TABS.SETTINGS.VLAN_TAGGING.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              checked={ssid?.useVlanTagging ?? false}
              onChange={() => updateSettings({ useVlanTagging: !ssid?.useVlanTagging })}
            />
          }
          testID={"SSID.VLAN_TAGGING"}
        >
          <List>
            <List.Item
              title={ssid?.defaultVlanId ?? "Not Added"}
              rightAccessory={
                <Button.Icon
                  icon="Pencil"
                  onPress={() => vlanIDBottomSheetRef.current?.present()}
                  testID="EDIT_VLAN"
                />
              }
            />
          </List>
        </List.Item>
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.SETTINGS.DEVICES_COMMUNICATION.TITLE")}
          description={I18n.t("SSID_SCREEN.TABS.SETTINGS.DEVICES_COMMUNICATION.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              checked={ssid?.c2cEnabled ?? false}
              onChange={() => updateSettings({ c2cEnabled: !ssid?.c2cEnabled })}
            />
          }
          testID={"SSID.DEVICES_COMMUNICATION"}
        />
        <List.Item
          title={""}
          onPress={() => setDisplayMore(!displayMore)}
          hidePressable
          leftAccessory={
            <Button text={I18n.t("LEARN_MORE")} kind="tertiary" testID="LEARN_MORE_BUTTON" />
          }
          rightAccessory={<Icon name={displayMore ? "CaretUp" : "CaretDown"} />}
          children={displayMore ? <Text size="p2">{""}</Text> : null}
        />
      </List>
      {ssid && <BroadcastingBottomSheet ssid={ssid} ref={broadcastingBottomSheetRef} />}
      <BottomSheet.Modal ref={vlanIDBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("IP_CONFIG.VLAN_ID")}
          onCancelPress={() => vlanIDBottomSheetRef.current?.dismiss()}
          onResetPress={() => setVlanID(ssid?.defaultVlanId)}
        />
        <BottomSheet.Content>
          <Box padding="xs">
            <Input
              value={vlanID}
              onChangeText={setVlanID}
              additionalContext={helperText}
              placeholder={I18n.t("VLAN_TAGGING.VLAN_ID.PLACEHOLDER")}
              keyboardType="numeric"
              testID="VLAN_ID_INPUT"
              errored={!!helperText}
            />
          </Box>
          <Box padding="xs">
            <Button
              text={I18n.t("SAVE")}
              onPress={() => updateSettings({ useVlanTagging: true, defaultVlanId: vlanID })}
              disabled={Boolean(helperText)}
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
