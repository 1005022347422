import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const RadioStatusSchema = z.object({
  band: z.number(),
  channelSettings: z.object({
    baseChannel: z.number(),
    width: z.number(),
  }),
  powerSettings: z.object({
    transmitPower: z.number(),
    mode: z.optional(z.union([z.literal("sp"), z.literal("lpi")])),
  }),
});

export const RadioStatusResponseSchema = z.array(RadioStatusSchema).describe("RadioStatusSchema");

export type RadioStatus = z.infer<typeof RadioStatusResponseSchema>;

interface RadioStatusRequest {
  serial: string;
}

function buildUrl({ serial }: RadioStatusRequest) {
  return `/api/v1/devices/${serial}/wireless/radio/status`;
}

const fetchRadioStatus = ({ serial }: RadioStatusRequest): Promise<RadioStatus> =>
  request(RadioStatusResponseSchema, "GET", buildUrl({ serial }));

export const useRadioStatus = createQuery<RadioStatusRequest, RadioStatus>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchRadioStatus(variables),
});
