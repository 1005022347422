import { Platform } from "react-native";
import z from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const PasswordResetRequestResponseSchema = z
  .object({
    success: z.boolean(),
  })
  .describe("PasswordResetRequestResponseSchema");

export type PasswordResetRequestResponse = z.infer<typeof PasswordResetRequestResponseSchema>;

interface PasswordResetRequest {
  email: string;
}

type PasswordResetError = {
  error: string;
  status?: string;
};

const URL = "/go/password_reset_request";
const baseUrl =
  Platform.OS === "web" ? "/account" : __DEV__ ? undefined : "https://account.meraki-go.com";

function resetPassword(body: PasswordResetRequest): Promise<PasswordResetRequestResponse> {
  return request(PasswordResetRequestResponseSchema, "POST", URL, {
    baseUrl,
    headers: {
      "Meraki-Go-Proxy-Email": `${body.email}`,
    },
    body: JSON.stringify(body),
    skipCSRF: true,
  });
}

export const usePasswordResetRequest = createMutation<
  PasswordResetRequest,
  PasswordResetRequestResponse,
  PasswordResetError
>({
  baseMutationKey: URL,
  mutationFn: (variable: PasswordResetRequest) => resetPassword(variable),
});
