import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function PlaneIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19.3688 20.0907L4.36875 17.3625C4.19495 17.3316 4.03761 17.2404 3.92443 17.105C3.81125 16.9695 3.74949 16.7984 3.75 16.6219V7.37815C3.74949 7.20162 3.81125 7.03057 3.92443 6.8951C4.03761 6.75963 4.19495 6.66842 4.36875 6.63753L19.3688 3.9094C19.4768 3.89019 19.5878 3.89494 19.6939 3.92332C19.8 3.95171 19.8985 4.00303 19.9825 4.07367C20.0666 4.14431 20.1341 4.23253 20.1803 4.33212C20.2265 4.43171 20.2503 4.54024 20.25 4.65003V19.35C20.2503 19.4598 20.2265 19.5683 20.1803 19.6679C20.1341 19.7675 20.0666 19.8557 19.9825 19.9264C19.8985 19.997 19.8 20.0483 19.6939 20.0767C19.5878 20.1051 19.4768 20.1099 19.3688 20.0907Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1.5 12H22.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default PlaneIcon;
