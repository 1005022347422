import { z } from "zod";

import { request } from "../../api/request/request";
import { ProductType } from "../../schemas";
import { StatusSchema } from "../../schemas/Device";
import { createQuery } from "../createQuery";

export const SensorMetricsSchema = z.union([
  z.literal("apparentPower"),
  z.literal("battery"),
  z.literal("button"),
  z.literal("co2"),
  z.literal("current"),
  z.literal("door"),
  z.literal("downstreamPower"),
  z.literal("eco2"),
  z.literal("energy"),
  z.literal("frequency"),
  z.literal("humidity"),
  z.literal("indoorAirQuality"),
  z.literal("noise"),
  z.literal("pm25"),
  z.literal("powerFactor"),
  z.literal("realPower"),
  z.literal("remoteLockoutSwitch"),
  z.literal("temperature"),
  z.literal("tvoc"),
  z.literal("voltage"),
  z.literal("water"),
]);

export type SensorMetricsSchemaType = z.infer<typeof SensorMetricsSchema>;

export const SensorAlertingMetricsSchema = z.union([
  z.literal("realCo2"),
  z.literal("iaqIndex"),
  z.literal("ambientNoise"),
  z.literal("frequency"),
  z.literal("humidity"),
  z.literal("pm25"),
  z.literal("temperature"),
  z.literal("tvoc"),
  z.literal("mainsApparentPower"),
  z.literal("mainsVoltageRms"),
  z.literal("mainsApparentPower"),
  z.literal("mainsFrequency"),
  z.literal("mainsInstPowerFactor"),
  z.literal("mainsRealPower"),
  z.literal("mainsCurrentRms"),
  z.literal("battery"),
  z.literal("button"),
  z.literal("door"),
  z.literal("water"),
]);

export type SensorAlertingMetricsType = z.infer<typeof SensorAlertingMetricsSchema>;

export const DeviceTagsSchema = z.array(z.string());
export const BaseDeviceSchema = z.object({
  id: z.string(),
  name: z.optional(z.nullable(z.string())),
  lat: z.number(),
  lng: z.number(),
  address: z.string(),
  notes: z.string().optional(),
  tags: DeviceTagsSchema,
  networkId: z.string(),
  serial: z.string(),
  model: z.string(),
  mac: z.string(),
  lanIp: z.optional(z.nullable(z.string())),
  ip: z.optional(z.nullable(z.string())),
  wan1Ip: z.optional(z.nullable(z.string())),
  wan2Ip: z.optional(z.nullable(z.string())),
  firmware: z.string(),
  networkEid: z.string(),
  imei: z.optional(z.number()),
  is_gateway: z.optional(z.boolean()),
  status: z.optional(StatusSchema),
});

export const SensorSchema = BaseDeviceSchema.extend({
  productType: z.literal("sensor"),
  sensor: z.object({
    alertProfileIds: z.array(z.number()),
    alertingOn: z.array(SensorAlertingMetricsSchema),
    metrics: z.array(SensorMetricsSchema),
  }),
});

export const DeviceSchema = z
  .discriminatedUnion("productType", [
    BaseDeviceSchema.extend({ productType: z.literal("wireless") }),
    BaseDeviceSchema.extend({ productType: z.literal("appliance") }),
    BaseDeviceSchema.extend({ productType: z.literal("switch") }),
    BaseDeviceSchema.extend({ productType: z.literal("camera") }),
    BaseDeviceSchema.extend({ productType: z.literal("cellularGateway") }),
    BaseDeviceSchema.extend({ productType: z.literal("cloudGateway") }),
    BaseDeviceSchema.extend({ productType: z.literal("systemsManager") }),
    SensorSchema,
  ])
  .describe("DeviceSchema");

export const DevicesSchema = z.array(DeviceSchema).describe("DevicesSchema");

export type Device = z.infer<typeof DeviceSchema>;
export type Devices = z.infer<typeof DevicesSchema>;

interface DevicesRequest {
  organizationId?: string;
  networkId?: string;
  serial?: Device["serial"];
  productTypes?: ProductType[];
}

function buildUrl({ organizationId }: { organizationId: DevicesRequest["organizationId"] }) {
  return `/api/v1/organizations/${organizationId}/devices`;
}

const fetchDevices = ({ organizationId, networkId, serial, productTypes }: DevicesRequest) => {
  return request(DevicesSchema, "GET", buildUrl({ organizationId }), {
    queryParams: { networkIds: networkId && [networkId], serial, productTypes },
  });
};

export const useDevices = createQuery<DevicesRequest, Devices>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: fetchDevices,
  requiredVariables: ["organizationId"],
});
