import { TranslationKeys } from "@meraki/core/i18n";

export const TAGS = [
  // device
  "device",
  "access point",
  "switch",
  "router",
  "wireless",
  "wired",
  "firmware",

  // network
  "ssid",
  "vlan",
  "network",
  "clients",
  "security",
  "applications",
  "vpn",
  "site-to-site",
  "speed",
  "firewall",
  "traffic",
  "forwarding",
  "qos",

  // alert
  "notifications",
  "alerts",
  "events",
  "tracked",

  // setting
  "account",
  "admins",
  "administrators",
  "block",
  "password",
  "reset",
] as const;

export type Tag = (typeof TAGS)[number];

export type ScreenScope = "organization" | "network";

export type ScreenInfo = {
  title: TranslationKeys;
  subtitle?: TranslationKeys;
  tab?: string;
  scope?: ScreenScope;
  screen: string;
};

type TagsMap = {
  [key in Tag]?: string[];
};

type ScreensMap = {
  [key: string]: ScreenInfo;
};

export let tagsMap: TagsMap = {};
export let screensMap: ScreensMap = {};

// this should only be used in tests
export const resetMaps = () => {
  tagsMap = {};
  screensMap = {};
};

export const registerTags = (screenInfo: ScreenInfo, screenTags: Tag[]) => {
  if (screenInfo.screen in screensMap) {
    throw new Error(`Duplicated screen: ${screenInfo.screen}`);
  } else {
    screensMap[screenInfo.screen] = screenInfo;
  }

  for (const tag of screenTags) {
    tagsMap[tag] = [...(tagsMap[tag] ?? []), screenInfo.screen];
  }
};
