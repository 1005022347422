import {
  REMOVE_PENDING_APP_LINK,
  SET_PENDING_APP_LINK,
  SET_SELECTED_VLAN_ID,
} from "@meraki/shared/redux";

export function setPendingAppLink(
  screen: string,
  params?: Record<string, string | number | undefined>,
) {
  return {
    type: SET_PENDING_APP_LINK,
    payload: {
      screen,
      params,
    },
  } as const;
}

export function removePendingAppLink() {
  return {
    type: REMOVE_PENDING_APP_LINK,
  } as const;
}

export function setSelectedVlanId(vlanId: number) {
  return {
    type: SET_SELECTED_VLAN_ID,
    payload: {
      vlanId,
    },
  } as const;
}
