import { useMagneticDesignSystem } from "@meraki/magnetic/adapter";
import { DebugNavigator } from "@meraki/shared/debugging-tools";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import {
  checkForOnboardingPolling,
  cometInit,
  detectIdleTimeInWeb,
  fetchCSRFToken,
  initAppLinking,
  setCurrentLocale,
} from "~/actions";
import LoadingScreen from "~/enterprise/screens/LoadingScreen";
import { configureFirebase } from "~/lib/FirebaseUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { shouldShowOnboardingSelector } from "~/selectors";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useAnalyticsAndErrorContextSynchronizer } from "~/shared/hooks/useAnalyticsAndErrorContextSynchronizer";
import { useRuntimeSchemaValidationWatcher } from "~/shared/hooks/useRuntimeSchemaValidationWatcher";
import { useTimeZoneSync } from "~/shared/hooks/useTimeZoneSync";
import createBottomSheetStack from "~/shared/navigation/BottomSheetStack";
import useLoginInitState from "~/shared/navigation/useLoginInitState";

import DefaultTabStack from "./DefaultTabStack";
import { LeftDrawer } from "./LeftDrawer";
import { handleAppLink } from "./Linking";
import LoginStack from "./LoginStack";
import OnboardingStack from "./OnboardingStack";
import ResetTwoFactorStack from "./ResetTwoFactorStack";
import SideNavBar from "./SideNavBar";
import TwoFactorStack from "./TwoFactorStack";

const RootNavigator = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

const LoggedInBottomSheetStack = isWeb()
  ? () => (
      <SideNavBar>
        <DefaultTabStack />
      </SideNavBar>
    )
  : createBottomSheetStack(DefaultTabStack);

export default function RootStack() {
  const dispatch = useAppDispatch();
  const [loginInitState, setPostLoginInitComplete] = useLoginInitState();

  const magneticTheming = useMagneticDesignSystem();

  useAnalyticsAndErrorContextSynchronizer();
  useTimeZoneSync();
  useRuntimeSchemaValidationWatcher();

  const shouldShowOnboarding = useAppSelector(shouldShowOnboardingSelector);

  switch (loginInitState) {
    case "notAuthenticated":
      return <LoginStack />;
    case "twoFactorReset":
      return <ResetTwoFactorStack />;
    case "twoFactor":
      return <TwoFactorStack />;
    case "fetchingUserAccessRoles":
      return <LoadingScreen devMessage={"Updating user access roles"} />;
    case "localeChanging":
      return <LoadingScreen devMessage={"Locale changed, remounting"} />;
    case "notComplete":
      return (
        <LoadingScreen
          devMessage={"Waiting on post login init actions"}
          actions={[
            fetchCSRFToken,
            cometInit,
            checkForOnboardingPolling,
            detectIdleTimeInWeb,
            setCurrentLocale,
          ]}
          promises={[configureFirebase]}
          execParallelActions
          onActionsError={(error: unknown) => console.warn("post login init actions err", error)}
          onPromiseError={(error: unknown) => console.warn("post login init promises err", error)}
          onFinishLoad={async () => {
            if (!isWeb()) {
              await dispatch(initAppLinking(handleAppLink));
            }
            setPostLoginInitComplete(true);
          }}
        />
      );
  }

  if (shouldShowOnboarding) {
    return <OnboardingStack />;
  }

  if (magneticTheming) {
    return (
      <RootNavigator.Navigator screenOptions={{ headerShown: false }}>
        <RootNavigator.Screen name="Root" component={DrawerNavigator} />
        <RootNavigator.Screen
          name="DebuggingView"
          component={DebugNavigator}
          options={{ presentation: "modal" }}
        />
      </RootNavigator.Navigator>
    );
  }

  return LoggedInBottomSheetStack();
}

function DrawerNavigator() {
  return (
    <Drawer.Navigator initialRouteName="DrawerDefault" drawerContent={LeftDrawer}>
      <Drawer.Screen
        name="DrawerDefault"
        component={LoggedInBottomSheetStack}
        options={{ headerShown: false }}
      />
    </Drawer.Navigator>
  );
}
