import { I18n } from "@meraki/core/i18n";
import { NetworkUsageblocks } from "@meraki/shared/api";
import { memo, useMemo, useState } from "react";

import MkiColors from "~/constants/MkiColors";
import { LINE_CHART_PADDING, TIMESTAMP_KEY } from "~/constants/MkiConstants";
import { Features } from "~/go/types/ContextHelpTypes";
import { addZeroes, chartDateFormatter, useblocksGraphseries } from "~/lib/formatHelper";
import { calculateMaxAndDomain, ChartDomain, formatBitsAxis } from "~/lib/MkiChartUtils";
import { calculateTimespan } from "~/lib/timeHelper";
import ExpandableSummaryCard from "~/shared/components/ExpandableSummaryCard";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import MkiMultilineChart from "~/shared/components/MkiMultilineChart";
import { DataKey } from "~/shared/types/MkiChartTypes";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

interface UsagesStats {
  data: number[];
  domain: ChartDomain;
  maxUsage: number;
}

interface UsageChartProps {
  networkUsageIsFetching: boolean;
  ssidUsageIsFetching: boolean;
  showingAllNetworks: boolean;
  timezone: string;
  timespan: number;
  networkUsage: NetworkUsageblocks;
  ssidUsage: NetworkUsageblocks;
  dragging?: boolean;
}

const getUsagesFromUseBlocks = (useblocks: any, timespan: any): UsagesStats => {
  const UPLOAD = I18n.t("USAGE_CHART.UPLOAD");
  const DOWNLOAD = I18n.t("USAGE_CHART.DOWNLOAD");

  const { t0, t1 } = calculateTimespan(timespan);

  let downloadData = [];
  let uploadData = [];
  let domain: ChartDomain = { x: [0, 0] };
  let maxUsage = 0;
  const downloadBlocks: number[][] = [];
  const uploadBlocks: number[][] = [];
  const usageData = useblocks?.netuseblocks || [];
  if (!usageData) {
    return {
      data: [],
      maxUsage: 0,
      domain,
    };
  }
  usageData.map((data: any) => {
    const [timestamp, download, upload]: [number, number, number] = data;
    uploadBlocks.push([timestamp, upload]);
    downloadBlocks.push([timestamp, download]);
  });

  if (useblocks?.t0 && useblocks?.t1) {
    // @ts-expect-error TS(2322) FIXME: Type '{ [x: string]: number; timestamp: number; }[... Remove this comment to see the full error message
    downloadData = useblocksGraphseries(downloadBlocks, useblocks.t0, useblocks.t1).map(
      ([timestamp, usage]) => ({
        timestamp,
        [DOWNLOAD]: usage,
      }),
    );

    // @ts-expect-error TS(2322) FIXME: Type '{ [x: string]: number; timestamp: number; }[... Remove this comment to see the full error message
    uploadData = useblocksGraphseries(uploadBlocks, useblocks.t0, useblocks.t1).map(
      ([timestamp, usage]) => ({
        timestamp,
        [UPLOAD]: usage,
      }),
    );
    const t0Millis = t0 * 1000;
    const t1Millis = t1 * 1000;
    // @ts-expect-error TS(2345): Argument of type '{ [x: string]: number; timestamp... Remove this comment to see the full error message
    const maxAndDomainDownload = calculateMaxAndDomain(downloadData, t0Millis, t1Millis, DOWNLOAD);
    // @ts-expect-error TS(2345): Argument of type '{ [x: string]: number; timestamp... Remove this comment to see the full error message
    const maxAndDomainUpload = calculateMaxAndDomain(uploadData, t0Millis, t1Millis, UPLOAD);
    const xDomains = [...maxAndDomainDownload.domain.x, ...maxAndDomainUpload.domain.x];
    let yDomains = [
      ...(maxAndDomainDownload.domain?.y || []),
      ...(maxAndDomainUpload.domain?.y || []),
    ];
    if (yDomains.length === 0) {
      yDomains = [0, 1];
    }
    maxUsage = Math.max(maxAndDomainDownload.max, maxAndDomainUpload.max);
    domain = {
      x: [Math.min(...xDomains), Math.max(...xDomains)],
      y: [Math.min(...yDomains), Math.max(...yDomains)],
    };

    downloadData = addZeroes(downloadData, t0, t1, TIMESTAMP_KEY, DOWNLOAD);
    uploadData = addZeroes(uploadData, t0, t1, TIMESTAMP_KEY, UPLOAD);
  }

  return {
    data: [...downloadData, ...uploadData],
    maxUsage,
    domain,
  };
};

export const UsageChart = memo(function UsageChart(props: UsageChartProps) {
  const {
    networkUsageIsFetching,
    ssidUsageIsFetching,
    networkUsage,
    ssidUsage,
    timezone,
    timespan,
    showingAllNetworks,
    dragging,
  } = props;

  const UPLOAD = I18n.t("USAGE_CHART.UPLOAD");
  const DOWNLOAD = I18n.t("USAGE_CHART.DOWNLOAD");

  const [selectedElements, setSelectedElements] = useState(new Set<string>([DOWNLOAD, UPLOAD]));
  const [usageData, setUsageData] = useState<UsagesStats>({
    data: [],
    maxUsage: 0,
    domain: { x: [0, 0] },
  });

  useMemo(() => {
    const useblocks = showingAllNetworks ? networkUsage : ssidUsage;

    setUsageData(getUsagesFromUseBlocks(useblocks, timespan));
  }, [timespan, showingAllNetworks, networkUsage, ssidUsage]);

  const onUpdateSelected = (label: string) => {
    const newSelectedElements = new Set([...selectedElements]);
    if (newSelectedElements.has(label)) {
      newSelectedElements.delete(label);
    } else {
      newSelectedElements.add(label);
    }
    setSelectedElements(newSelectedElements);
  };

  const computeDataKeys = () => {
    const dataKeys: DataKey[] = [];
    if (selectedElements.has(UPLOAD)) {
      dataKeys.push({ x: TIMESTAMP_KEY, y: UPLOAD, color: MkiColors.secondaryGraphLine });
    }
    if (selectedElements.has(DOWNLOAD)) {
      dataKeys.push({ x: TIMESTAMP_KEY, y: DOWNLOAD, color: MkiColors.primaryGraphLine });
    }
    return dataKeys;
  };

  const usageLoading = showingAllNetworks ? networkUsageIsFetching : ssidUsageIsFetching;

  const { data, maxUsage, domain } = usageData;

  return (
    <ExpandableSummaryCard
      heading={I18n.t("HOME.USAGE.TITLE")}
      subheading={I18n.t("HOME.USAGE.SUBTITLE")}
      loading={usageLoading}
      savePreferencesKey={ExpandableCardKeys.usageChart}
      dragging={dragging}
      context={Features.networkUsage}
    >
      <MkiMultilineChart
        domain={domain}
        data={data}
        dataKeys={computeDataKeys()}
        xAxisFormatter={(t: any) => chartDateFormatter(t, timespan, timezone, true)}
        yAxisFormatter={formatBitsAxis(maxUsage)}
        padding={LINE_CHART_PADDING}
        disableXAxisOffsetY
        hasFill
      />
      <MkiGraphLegend
        legendValues={[
          { label: UPLOAD, colors: [MkiColors.secondaryGraphLine] },
          { label: DOWNLOAD, colors: [MkiColors.primaryGraphLine] },
        ]}
        selectedElements={selectedElements}
        onLabelSelected={onUpdateSelected}
      />
    </ExpandableSummaryCard>
  );
});

export default UsageChart;
