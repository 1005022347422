import { Vlan } from "@meraki/shared/api";
import { createContext, useContext } from "react";

type VlanDetailsContextValue = {
  vlan?: Vlan;
  goToTab?: (key: string) => void;
};

export const VlanDetailsContext = createContext<VlanDetailsContextValue>({
  goToTab: () => undefined,
});

export function useVlanDetails() {
  return useContext(VlanDetailsContext);
}
