import { StyleSheet } from "react-native";

import { CombinedAppliancePort } from "~/api/queries/appliances/useCombinedAppliancePorts";
import { SPACING } from "~/constants/MkiConstants";
import GXPortIcon from "~/go/components/portDiagrams/portIcons/GXPortIcon";
import PortListRow, { PortListRowProps } from "~/go/rows/ports/PortListRow";
import I18n from "~/i18n/i18n";
import { isPortEnabled, isUplinkPort } from "~/lib/GXPortUtils";
import { portTitle } from "~/lib/SwitchPortUtils";
import { PortIconSizes } from "~/shared/components/portDiagrams/portIcons/PortIcon";

export interface GXPortListRowProps extends PortListRowProps {
  livePortData: CombinedAppliancePort;
}

export class GXPortListRow extends PortListRow<GXPortListRowProps> {
  title = () => {
    const { livePortData } = this.props;
    const portNumber = livePortData?.number;
    const isUplink = isUplinkPort(livePortData);

    return portTitle(isUplink, portNumber);
  };

  subtitle = () => {
    const { livePortData } = this.props;

    return isPortEnabled(livePortData) ? undefined : I18n.t("DISABLED");
  };

  portIcon = () => {
    const { livePortData } = this.props;

    return (
      <GXPortIcon
        livePortData={livePortData}
        screenStyles={styles.iconStyle}
        size={PortIconSizes.extraSmall}
      />
    );
  };
}

const styles = StyleSheet.create({
  iconStyle: {
    marginRight: SPACING.default,
  },
});

export default GXPortListRow;
