import { I18n } from "@meraki/core/i18n";
import { communityUrl } from "@meraki/go/links";
import { LiveSwitchPort } from "@meraki/react-live-broker";
import { get } from "lodash";
import { memo, PureComponent } from "react";
import { LayoutAnimation, ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { PortTypes } from "~/constants/PortLayouts";
import InlineAlert from "~/go/components/InlineAlert";
import PortAlertingCard from "~/go/components/port/PortAlertingCard";
import PortConnectedDevices from "~/go/components/port/PortConnectedDevices";
import PortTroubleshootingTools, {
  PortTroubleshootingToolsProps,
} from "~/go/components/port/PortTroubleshootingTools";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showAlert, showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { withNodeSubscription } from "~/lib/liveBroker";
import { getAlertPortMessage } from "~/lib/SwitchPortMessageUtils";
import { switchportIsAlerting, switchportIsConnected } from "~/lib/SwitchPortStatusUtils";
import {
  currentTrafficLabels,
  DASH,
  getChassisId,
  getPokeHosts,
  isSFPPortType,
  mergePortData,
  portTitle,
  processLiveSwitchPortDataForPortNumber,
  rollingLiveTraffic,
} from "~/lib/SwitchPortUtils";
import {
  clientsByMac,
  deviceByIdSelector,
  deviceClientsForSerialByMac,
  devicesByMacSelector,
  switchPortForSerial,
  switchPortTagsForPort,
  timespanState,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import SwitchFront from "~/shared/components/portDiagrams/SwitchFront";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import SummaryCard from "~/shared/components/SummaryCard";
import SummaryList from "~/shared/components/SummaryList";
import TagsInputCard from "~/shared/components/TagsInputCard";
import { SettingsButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import WrappingRow from "~/shared/rows/WrappingRow";
import { Client } from "~/shared/types/Client";
import Device_DeprecatedType from "~/shared/types/Device";
import { SwitchPort } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type PortInfoCardProps = {
  switchPort: SwitchPort;
};

type SwitchPortDetailsRow = {
  label: string;
  value: string;
};

const PortInfoCard = memo(function PortInfoCard(props: PortInfoCardProps) {
  const { switchPort } = props;
  // @ts-expect-error TS(2339) FIXME: Property 'liveStatus' does not exist on type 'Swit... Remove this comment to see the full error message
  const { is_uplink: isUplink, liveStatus, type } = switchPort;

  // TODO: Consolidate our left / right column styled
  // tables into a single component.
  const renderRow = (rowData: SwitchPortDetailsRow) => (
    <ListRow
      value={rowData.value}
      rowStyles={styles.rowStyles}
      rightStyle={styles.rightContent}
      leftStyle={styles.leftContent}
      labelStyle={styles.productInfoLabel}
    >
      {rowData.label}
    </ListRow>
  );

  const typeLabel = () => {
    if (isUplink) {
      return I18n.t("PORTS.UPLINK");
    }
    if (isSFPPortType(type)) {
      return PortTypes.sfp;
    }
    return I18n.t("PORTS.LAN_ACCESS");
  };

  const content = [
    { label: I18n.t("PORTS.TYPE"), value: typeLabel() },
    { label: I18n.t("PORTS.LINK_SPEED"), value: liveStatus || DASH },
  ];

  return (
    <SummaryList<SwitchPortDetailsRow>
      heading={I18n.t("PORTS.PORT_INFO")}
      contentRows={content}
      customRenderRow={renderRow}
      disableBottomBorder
    />
  );
});

type VlanInfoCardProps = {
  switchPort: SwitchPort;
};

type VlanContentRows = {
  label: string;
  testID?: string;
  value: string | number;
};

const VlanInfoCard = memo(function VlanInfoCard(props: VlanInfoCardProps) {
  const { switchPort } = props;
  const {
    is_trunk: isTrunk,
    vid: vlan,
    native_vid: nativeVid,
    allowed_vlans: allowedVlans,
    voice_vid: voiceVid,
  } = switchPort;

  const getVlanValue = (value?: string | number) => value || I18n.t("NONE");

  const renderRow = (rowData: VlanContentRows) => (
    <ListRow
      value={rowData.value}
      rowStyles={styles.rowStyles}
      rightStyle={styles.rightContent}
      leftStyle={styles.leftContent}
      labelStyle={styles.productInfoLabel}
      testID={rowData.testID}
    >
      {rowData.label}
    </ListRow>
  );

  const vlanOrNativeRow = () => {
    if (isTrunk) {
      return {
        label: I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NATIVE_VLAN"),
        value: getVlanValue(nativeVid),
        testID: "VLAN_DETAILS.NATIVE_VLAN",
      };
    } else {
      return {
        label: I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.VLAN"),
        value: getVlanValue(vlan),
        testID: "VLAN_DETAILS.VLAN",
      };
    }
  };

  const allowedOrVoiceRow = () => {
    if (isTrunk) {
      const outputAllowedVlans = allowedVlans ? allowedVlans.replace(/,/g, ", ") : allowedVlans;
      return {
        label: I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.ALLOWED_VLANS"),
        value: getVlanValue(outputAllowedVlans),
        testID: "VLAN_DETAILS.ALLOWED_VLANS",
      };
    } else {
      return {
        label: I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.VOICE_VLAN"),
        value: getVlanValue(voiceVid),
        testID: "VLAN_DETAILS.VOICE_VLAN",
      };
    }
  };

  const content = [
    {
      label: I18n.t("PORTS.VLAN_INFO.PORT_MODE"),
      value: isTrunk
        ? I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NAME")
        : I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.NAME"),
    },
    vlanOrNativeRow(),
    allowedOrVoiceRow(),
  ];

  return (
    <SummaryList<VlanContentRows>
      heading={I18n.t("PORTS.VLAN_INFO.TITLE")}
      contentRows={content}
      customRenderRow={renderRow}
    />
  );
});

type OptionalPortTroubleshootingToolsProps = {
  hide?: boolean;
} & PortTroubleshootingToolsProps;

const OptionalPortTroubleshootingTools = memo(function OptionalPortTroubleshootingTools(
  props: OptionalPortTroubleshootingToolsProps,
) {
  const { hide, hosts, portNumber, device, enabled, navigate, lldpMac } = props;
  if (hide) {
    return null;
  }
  return (
    <PortTroubleshootingTools
      hosts={hosts}
      device={device}
      enabled={enabled}
      navigate={navigate}
      portNumber={portNumber}
      lldpMac={lldpMac}
    />
  );
});

type LiveInfoCardProps = {
  hide?: boolean;
  curTraffic?: string;
  units?: string;
  powerConsumption: number;
};

const LiveInfoCard = memo(function LiveInfoCard(props: LiveInfoCardProps) {
  const { hide, curTraffic, units, powerConsumption } = props;
  if (hide) {
    return null;
  }
  const powerConsumptionLabel = powerConsumption ? powerConsumption / 1000 : DASH;
  const powerConsumptionUnits = powerConsumption ? I18n.t("PORTS.POWER_UNITS") : null;
  return (
    <SummaryCard heading={I18n.t("PORTS.LIVE_INFO")}>
      <WrappingRow>
        <QuickStatusBox
          value={curTraffic}
          subText={I18n.t("PORTS.CURRENT_USAGE")}
          category={units}
        />
        <QuickStatusBox
          value={powerConsumptionLabel}
          subText={I18n.t("PORTS.POWER")}
          category={powerConsumptionUnits}
        />
      </WrappingRow>
    </SummaryCard>
  );
});

type PortDisabledCard = {
  visible: boolean;
  enablePort: () => void;
};

const PortDisabledCard = memo(function PortDisabledCard(props: PortDisabledCard) {
  const { visible, enablePort } = props;
  return (
    <InlineAlert
      dismissible={false}
      visible={visible}
      alertTitle={I18n.t("PORTS.DISABLED_PORT.TITLE")}
      alertMessage={I18n.t("PORTS.DISABLED_PORT.MESSAGE")}
      primaryButtonText={I18n.t("PORTS.DISABLED_PORT.ENABLE_PORT")}
      onPrimaryPress={enablePort}
      secondaryButtonText={I18n.t("LEARN_MORE")}
      onSecondaryPress={communityUrl} // TODO: Replace with specific link
      screenStyles={styles.inlineAlertStyle}
      testID="PORT_CARD_DISABLED"
    />
  );
});

type ConnectedDevicesProps = {
  serialNumber: string;
  navigate: unknown;
  portNumber: number;
  switchPort: SwitchPort;
};

const ConnectedDevices = memo(function ConnectedDevices(props: ConnectedDevicesProps) {
  const { serialNumber, navigate, portNumber, switchPort } = props;

  if (!switchPort.enabled || switchPort.is_uplink) {
    return null;
  }
  const isConnected = switchportIsConnected(switchPort);

  return (
    <PortConnectedDevices
      lldpMac={getChassisId(switchPort)}
      serialNumber={serialNumber}
      isConnected={isConnected}
      connectedClients={switchPort.connectedClients}
      {...{ navigate, portNumber }}
    />
  );
});

type PortUplinkCardProps = {
  visible: boolean;
  onExit: () => void;
};

const PortUplinkCard = memo(function PortUplinkCard(props: PortUplinkCardProps) {
  const { visible, onExit } = props;
  return (
    <InlineAlert
      visible={visible}
      onExit={onExit}
      alertTitle={I18n.t("PORTS.INTERNET_PORT.TITLE")}
      alertMessage={I18n.t("PORTS.INTERNET_PORT.GS_MESSAGE")}
      primaryButtonText={I18n.t("LEARN_MORE")}
      onPrimaryPress={communityUrl} // TODO: Replace with Uplink specific links
      screenStyles={styles.inlineAlertStyle}
      testID="PORT_CARD_UPLINK"
    />
  );
});

type PortSFPCardPorts = {
  visible: boolean;
  onExit: () => void;
};

const PortSFPCard = memo(function PortSFPCard(props: PortSFPCardPorts) {
  const { visible, onExit } = props;
  return (
    <InlineAlert
      visible={visible}
      onExit={onExit}
      alertTitle={I18n.t("PORTS.SFP_PORT.TITLE")}
      alertMessage={I18n.t("PORTS.SFP_PORT.MESSAGE")}
      primaryButtonText={I18n.t("LEARN_MORE")}
      onPrimaryPress={communityUrl} // TODO: Replace with SFP specific links
      screenStyles={styles.inlineAlertStyle}
      testID="PORT_CARD_SFP"
    />
  );
});

type ReduxProps = {
  switchPort: SwitchPort;
  switchPortTags: string[];
  device: Device_DeprecatedType;
  deviceClientsByMac: Record<string, Client>;
  clientsByMac: Record<string, Client>;
  devicesByMac: Record<string, Device_DeprecatedType>;
  timespan: number;
};

type LivebrokerProps = {
  liveSwitchPort: LiveSwitchPort;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "SwitchPortsDetails"> &
  ReduxProps &
  LivebrokerProps &
  BasicActions;

type SwitchPortsDetailsScreenComponentState = {
  hasUnreadAlert: boolean;
  hasUnreadMessage: boolean;
  liveTraffic: unknown[];
  reqPending: boolean;
};

export class SwitchPortsDetailsScreenComponent extends PureComponent<
  Props,
  SwitchPortsDetailsScreenComponentState
> {
  static getDerivedStateFromSwitchPortCardProps(
    props: Props,
    state: SwitchPortsDetailsScreenComponentState,
  ) {
    const { liveSwitchPort } = props;
    if (!liveSwitchPort) {
      return null;
    }
    const { timestamp, tx_bytes: txBytes, rx_bytes: rxBytes } = liveSwitchPort;
    const { liveTraffic } = state;
    if (!liveTraffic) {
      return null;
    }
    const rolling = rollingLiveTraffic(liveTraffic, timestamp, txBytes, rxBytes);
    return { liveTraffic: rolling };
  }

  constructor(props: Props) {
    super(props);
    setupAndroidLayoutAnimation();

    this.state = {
      hasUnreadAlert: true,
      hasUnreadMessage: true,
      liveTraffic: [],
      reqPending: false,
    };

    this.setNavOptions();
  }

  componentDidMount() {
    this.loadData(true);
  }

  setNavOptions() {
    const { navigation, switchPort, portNumber, serialNumber } = this.props;
    const isUplink = switchPort?.is_uplink === true;

    navigation.setOptions({
      headerTitle: switchPort?.name ?? portTitle(isUplink, portNumber),
      headerRight: isUplink
        ? undefined
        : () => (
            <SettingsButton
              onPress={() => {
                navigation.navigate("SwitchPortSettings", {
                  serialNumber,
                  portIds: [switchPort.id],
                  onDismiss: () => this.loadData(true),
                });
              }}
            />
          ),
    });
  }

  loadData(force = false) {
    const { device, actions, switchPort, serialNumber, deviceClientsByMac, timespan } = this.props;

    const reqs = [];
    if (!switchPort || force) {
      reqs.push(actions.getSwitchPortsJson(device.id, serialNumber));
    }
    if (!deviceClientsByMac || force) {
      reqs.push(actions.getDeviceClients(serialNumber, timespan));
    }

    if (reqs.length > 0) {
      this.handleRequests(reqs);
    }
  }

  handleRequests = (reqs: unknown[]) => {
    const reqDone = () => this.setState({ reqPending: false });
    const handleError = () => {
      this.setState({ reqPending: false });
      showNetworkErrorWithRetry(() => this.loadData(true));
    };
    this.setState({ reqPending: true });
    return Promise.all(reqs).then(reqDone, handleError);
  };

  closeCard(key: "hasUnreadAlert" | "hasUnreadMessage") {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    // @ts-expect-error it thinks key is type "string"
    this.setState({ [key]: false });
  }

  enablePort = () => {
    const { device, switchPort, actions } = this.props;

    this.setState({ reqPending: true });

    actions.updateSwitchPorts(device.serial, switchPort.id, { enabled: true })?.then(
      () => this.setState({ reqPending: false }),
      (error: unknown) => this.handleError(error),
    );
  };

  handleError(error: unknown) {
    this.setState({ reqPending: false });
    showAlert(I18n.t("ERROR"), error);
  }

  renderSpinnerIfNeeded() {
    const { reqPending } = this.state;
    return reqPending ? <LoadingSpinner visible /> : null;
  }

  render() {
    const { reqPending } = this.state;
    const {
      serialNumber,
      switchPort,
      liveSwitchPort,
      device,
      portNumber,
      deviceClientsByMac,
      clientsByMac,
      switchPortTags,
      devicesByMac,
      navigation,
    } = this.props;
    const portData = mergePortData(switchPort, liveSwitchPort, deviceClientsByMac);

    if (!portData) {
      return <LoadingSpinner visible />;
    }

    const {
      enabled,
      is_uplink: isUplink,
      power_consumption_mw: powerConsumption,
      type,
      connectedClients,
    } = portData;

    const { liveTraffic, hasUnreadAlert, hasUnreadMessage } = this.state;
    const { curTraffic, units } = currentTrafficLabels(liveTraffic);

    const onExitAlert = () => this.closeCard("hasUnreadAlert");
    const onExitMessage = () => this.closeCard("hasUnreadMessage");
    const showContactSupport = () => navigation.navigate("SearchSubject");

    const isSFPPort = isSFPPortType(type);
    const alerting = switchportIsAlerting(portData);

    const troubleshootingEnabled = !isUplink && !isSFPPort;
    const shouldHideTroubleshootingTools = isUplink || !enabled;

    const sfpCardEnabled = enabled && hasUnreadMessage && isSFPPort;
    const lldpMac = getChassisId(portData);

    const alertMessage = getAlertPortMessage(portData);

    return (
      <FullScreenContainerView>
        <ScrollView testID="PORT_DETAILS_SCROLL_VIEW">
          {/* @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'. */}
          <SwitchFront serialNumber={serialNumber} selectedPorts={[portNumber]} />
          <PortAlertingCard
            alertTitle={I18n.t("PORTS.ALERTING_PORT.TITLE", {
              port_word: portTitle(isUplink, portNumber),
            })}
            alertMessage={alertMessage}
            visible={hasUnreadAlert && alerting}
            onExit={onExitAlert}
            showContactSupport={showContactSupport}
          />
          <ConnectedDevices navigate={navigation.navigate} {...this.props} />
          <PortUplinkCard visible={hasUnreadMessage && isUplink} onExit={onExitMessage} />
          <OptionalPortTroubleshootingTools
            hide={shouldHideTroubleshootingTools}
            hosts={getPokeHosts(lldpMac, connectedClients, clientsByMac, devicesByMac)}
            device={device}
            enabled={troubleshootingEnabled}
            navigate={navigation.navigate}
            portNumber={portNumber}
            lldpMac={lldpMac}
          />
          <LiveInfoCard
            hide={!enabled}
            curTraffic={curTraffic}
            units={units}
            powerConsumption={powerConsumption}
          />
          <PortSFPCard visible={sfpCardEnabled} onExit={onExitMessage} />
          <TagsInputCard title={I18n.t("PORTS.PORT_TAGS.TITLE")} tags={switchPortTags} isReadOnly />
          <PortDisabledCard visible={!enabled && !reqPending} enablePort={this.enablePort} />
          <PortInfoCard switchPort={portData} />
          <VlanInfoCard switchPort={portData} />
        </ScrollView>
        {this.renderSpinnerIfNeeded()}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  productInfoLabel: {
    color: MkiColors.secondaryTextColor,
  },
  inlineAlertStyle: {
    marginVertical: SPACING.default,
  },
});

const processMessage = (message: any, props: HardwareStackPropMap["SwitchPortsDetails"]) => ({
  liveSwitchPort: processLiveSwitchPortDataForPortNumber(get(message, "data"), props.portNumber),
});

function mapStateToProps(
  state: RootState,
  props: HardwareStackPropMap["SwitchPortsDetails"],
): ReduxProps {
  const switchPort = switchPortForSerial(state, props.serialNumber, props.portNumber);

  return {
    switchPort,
    switchPortTags: switchPortTagsForPort(state, switchPort?.id),
    device: deviceByIdSelector(state, { id: props.deviceId }),
    deviceClientsByMac: deviceClientsForSerialByMac(state, props.serialNumber),
    clientsByMac: clientsByMac(state),
    devicesByMac: devicesByMacSelector(state),
    timespan: timespanState(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withNodeSubscription({
    type: "SwitchPortStats",
    handler: processMessage,
    deviceId: (ownProps: any) => ownProps.deviceId,
  }),
)(SwitchPortsDetailsScreenComponent);
