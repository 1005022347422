import { ClientTypes } from "~/enterprise/types/ClientTypes";
import {
  ExtendedClient,
  ExtendedClientWithSM,
  ExtendedSM,
  NetworkClientOrSMDevice,
} from "~/shared/types/Client";
import Device from "~/shared/types/Device";

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value);
};

export const isText = (value: unknown): value is string | number => {
  return isString(value) || isNumber(value);
};

export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
};

// Since the deviceByIdSelector may return an empty object but cast it as a device, we
// can't rely on TS to know if it really is a device object or empty object
// Additionally, it's possible for a device to exist in Redux but not have the right
// fields if it is returned from nodes/json, which only provides the id, serial,
// and sensor settings
export function isDevice(device?: Device | NetworkClientOrSMDevice): device is Device {
  return !!device && "id" in device && "model" in device;
}

export function isNotifiableError(value: unknown): value is string | Error {
  if (isString(value)) {
    return true;
  }
  if (value instanceof Error) {
    return true;
  }
  if (typeof value === "object" && value !== null) {
    if ("name" in value && "message" in value) {
      return true;
    }
  }
  return false;
}

export const parseString = (str: string) => {
  const number = parseFloat(str);
  if (!isNaN(number)) {
    return number;
  }

  switch (str.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
  }

  return str;
};

// When you have a TS union type, array methods, like find() and filter() would seem to prove which member
// of the union each item is. But TS does not narrow the type through these array methods.
// discriminate() narrows the type.
//
// Note the function returned by discrimate should be passed in directly to the array method.
// Works for narrowing: [].find(discriminate("type", "foo"))
// Does not work fo narrowing: [].find((obj) => discriminate("type", "foo")(obj))
// https://stackoverflow.com/a/59054248
export function discriminate<K extends PropertyKey, V extends string | number | boolean>(
  discriminantKey: K,
  discriminantValue: V,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <T extends Record<K, any>>(
    obj: T & Record<K, V extends T[K] ? T[K] : V>,
  ): obj is Extract<T, Record<K, V>> => obj[discriminantKey] === discriminantValue;
}

export const verifyIsNetworkClient = (
  client?: NetworkClientOrSMDevice,
): client is ExtendedClient => {
  return client?.clientType === ClientTypes.network;
};

export const verifyIsSMDevice = (client?: NetworkClientOrSMDevice): client is ExtendedSM => {
  return client?.clientType === ClientTypes.sm;
};

export const verifyIsNetworkClientSM = (
  client?: NetworkClientOrSMDevice,
): client is ExtendedClientWithSM => {
  return client?.clientType === ClientTypes.smAndNetwork;
};
