enum NFOs {
  hasGoWebappBeta = "bb0c55fb37acc41dea37d70fc0e26e9233051def9e93402e9b12917357eada05",
  hasMobileAlphaFeatures = "71d37dfe6b7851e57f4e0eeacef548e08d04ccf96a5a6978c903f3464064afdc",
  hasMobileBetaFeatures = "0af52ceb5012cca87de124cc575bfab0bbccb6100a2cd528b8a18971a7046924",
  hasCameraEggplant = "5307d9f3e5d0a22ea2bc8cfb17a5b12fa1a29b19d85b2dad14a9fc13c2b5a5a9",
  hasCameraWpEndpoint = "cd4399a6f8bc4ebfacade5677728d64616ef725df90b5d1be3bdb25da8133bf0",
  hasAlphaAPI = "424702a8e6a55a9a149b1ec0aa456b7ad30eff2bd629204b8d3f425aa439e448",
  hasBetaAPI = "a17f414637ecd6fc42453211252e440d0187e492cdbadbd38496c07cf8b2b2cb",
  hasMobileSalesFeatures = "794dff559736f11e507afc4f3c23469e4ea4e412a81a66651038d3dd2460227d",
  hasCameraScrub = "98c27631f890353e6b391c8260c484133c4b870475ed5389c8f8b1b17fb7ecec",
}

export default NFOs;
