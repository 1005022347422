import { I18n } from "@meraki/core/i18n";
import { List, SearchBar, Toggle } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  Admin,
  AuthUser,
  useAdmins,
  useAuthUsers,
  useAuthUsersByEmail,
  useDisableClientVPN,
  useEnableClientVPN,
} from "@meraki/shared/api";
import { CLIENT_VPN_SEARCH_FIELDS, filterData } from "@meraki/shared/filters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useState } from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";

export const ClientVPNAdminAccessListScreen = () => {
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();
  const { data: admins } = useAdmins({ organizationId });
  const { data: authUsers, refetch } = useAuthUsers({ networkId });
  const authsOnNetworkByEmail = useAuthUsersByEmail(networkId);
  const enableClientVPN = useEnableClientVPN();
  const disableClientVPN = useDisableClientVPN();
  const currentUser = useCurrentUserEmail();

  const [searchText, setSearchText] = useState("");
  const searchedAdmins = filterData(admins ?? [], CLIENT_VPN_SEARCH_FIELDS, searchText) as Admin[];

  // Put the current user in the first of order
  const sortAdmins = () => {
    if (!searchedAdmins) {
      return [];
    }
    return searchedAdmins.slice().sort(({ email }) => (email === currentUser ? -1 : 1));
  };

  const isClientVPNEnabledOnAdmin = (email: string) => {
    return authsOnNetworkByEmail.data && authsOnNetworkByEmail.data[email]?.clientVPNEnabled;
  };

  const getRowSuffixForVerified = (email: string, orgAccess?: string) => {
    if (email === currentUser) {
      return I18n.t("ADMIN.SUFFIX.CURRENT_USER");
    } else if (orgAccess === "read-only") {
      return I18n.t("ADMIN.SUFFIX.READ_ONLY");
    }
    return "";
  };

  const getAuthId = (adminEmail: string) => {
    const authUser = authUsers?.find(
      (user: AuthUser) => user.email === adminEmail && user.accountType === "Client VPN",
    );
    return authUser?.id ?? "";
  };

  const enableToggleVPN = (adminEmail: string) => {
    enableClientVPN.mutate(
      { email: adminEmail, isAdmin: true, networkId: networkId },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          refetch();
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  const disableToggleVPN = (adminEmail: string) => {
    disableClientVPN.mutate(
      { id: getAuthId(adminEmail), networkId: networkId },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("CLIENT_VPN.ADMIN.SAVED"), Toast.SHORT, Toast.TOP);
          refetch();
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding>
      <Box flexDirection="row" alignItems="center" gap="sm" bottomDividerBorder>
        <Box flex={1} paddingBottom="sm">
          <SearchBar
            placeholder={I18n.t("SEARCH")}
            value={searchText}
            onChangeText={setSearchText}
            testID="SEARCHBAR"
          />
        </Box>
      </Box>

      <List.FlashList
        paddingTop="none"
        paddingBottom="none"
        paddingLeft="none"
        paddingRight="none"
        data={sortAdmins()}
        getItemData={(admin) => {
          const enabled = !!isClientVPNEnabledOnAdmin(admin.email);
          const suffix =
            admin.accountStatus === "unverified"
              ? I18n.t("ADMIN.SUFFIX.WAITING")
              : getRowSuffixForVerified(admin.email, admin.orgAccess);
          return {
            title: admin.name + suffix,
            description: admin.email,
            rightAccessory: (
              <Toggle
                checked={enabled}
                onValueChange={() =>
                  enabled ? disableToggleVPN(admin.email) : enableToggleVPN(admin.email)
                }
                testID={`${admin.email}.CLIENT_VPN.${enabled ? "ENABLED" : "DISABLED"}`}
              />
            ),
          };
        }}
        emptyState={{
          title: admins?.length
            ? I18n.t("CLIENT_VPN.ADMIN.EMPTY_SEARCH")
            : I18n.t("CLIENT_VPN.ADMIN.EMPTY"),
        }}
      />
    </Screen>
  );
};
