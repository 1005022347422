import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { applicationUsageScreens } from "~/go/navigation/ApplicationUsageStack";
import { clientScreens } from "~/go/navigation/ClientScreens";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import BlockContentScreen from "~/go/screens/BlockContentScreen";
import BlockWebsiteScreen from "~/go/screens/BlockWebsiteScreen";
import ConfigureIpskScreen from "~/go/screens/ConfigureIpskScreen";
import ConfigureVlanScreen from "~/go/screens/ConfigureVlanScreen";
import DeviceReservationScreen from "~/go/screens/DeviceReservationScreen";
import DHCPOptionScreen from "~/go/screens/DHCPOptionScreen";
import DHCPServerScreen from "~/go/screens/DHCPServerScreen";
import DNSServersScreen from "~/go/screens/DNSServersScreen";
import LocalAddressingScreen from "~/go/screens/LocalAddressingScreen";
import LocationAnalyticsDateScreen from "~/go/screens/LocationAnalyticsDateScreen";
import LocationAnalyticsScreen from "~/go/screens/LocationAnalyticsScreen";
import NetworksListScreen from "~/go/screens/NetworksListScreen";
import ExportTemplateScreen from "~/go/screens/networkTemplate/ExportTemplateScreen";
import ImportTemplateScreen from "~/go/screens/networkTemplate/ImportTemplateScreen";
import RadioSettingsScreen from "~/go/screens/RadioSettingsScreen";
import ReservedAddressesScreen from "~/go/screens/ReservedAddressesScreen";
import ReserveIPRangeScreen from "~/go/screens/ReserveIPRangeScreen";
import SplashCustomURLSetupScreen from "~/go/screens/SplashCustomURLSetupScreen";
import { SplashPagePreviewScreen } from "~/go/screens/SplashPagePreviewScreen";
import SplashPageSetupScreen from "~/go/screens/SplashPageSetupScreen";
import SplashSelectScreen from "~/go/screens/SplashSelectScreen";
import SplashWalledGardenScreen from "~/go/screens/SplashWalledGardenScreen";
import SSIDRadioSettingsScreen from "~/go/screens/SSIDRadioSettingsScreen";
import AccessPointAvailabilityScreen from "~/go/screens/ssids/AccessPointAvailabilityScreen";
import ClientIsolationScreen from "~/go/screens/ssids/ClientIsolationScreen";
import CreateSSIDScreen from "~/go/screens/ssids/CreateSSIDScreen";
import NATBridgeScreen from "~/go/screens/ssids/NATBridgeScreen";
import SSIDConfigureScreen from "~/go/screens/ssids/SSIDConfigureScreen";
import SSIDDetailsScreen from "~/go/screens/ssids/SSIDDetailsScreen";
import SSIDPasswordScreen from "~/go/screens/ssids/SSIDPasswordScreen";
import SSIDSchedulesScreen from "~/go/screens/ssids/SSIDSchedulesScreen";
import SSIDSharePDFScreen from "~/go/screens/ssids/SSIDSharePDFScreen";
import SSIDShareScreen from "~/go/screens/ssids/SSIDShareScreen";
import SSIDsListScreen from "~/go/screens/ssids/SSIDsListScreen";
import VlanTaggingScreen from "~/go/screens/ssids/VlanTaggingScreen";
import WPASettingsScreen from "~/go/screens/ssids/WPASettingsScreen";
import { CancelButton } from "~/shared/navigation/Buttons";

const ForwardedNetworksListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "NetworksList">>()(
    NetworksListScreen,
  );
const ForwardedSSIDsListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDsList">>()(
    SSIDsListScreen,
  );
const ForwardedSSIDDetailsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDDetails">>()(
    SSIDDetailsScreen,
  );
const ForwardedSSIDConfigureScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDConfigure">
  >()(SSIDConfigureScreen);
const ForwardedSSIDShareScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDShare">>()(
    SSIDShareScreen,
  );
const ForwardedSSIDSharePDFScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDSharePDF">>()(
    SSIDSharePDFScreen,
  );
const ForwardedSplashSelectScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SplashSelect">>()(
    SplashSelectScreen,
  );
const ForwardedSSIDSchedulesScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDSchedules">
  >()(SSIDSchedulesScreen);
const ForwardedAccessPointAvailabilityScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<NetworkScreensPropMap, "AccessPointAvailability">
>()(AccessPointAvailabilityScreen);
const ForwardedNATBridgeScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "NATBridge">>()(
    NATBridgeScreen,
  );
const ForwardedSSIDRadioSettingsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDRadioSettings">
  >()(SSIDRadioSettingsScreen);
const ForwardedWPASettingsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "WPASettings">>()(
    WPASettingsScreen,
  );
const ForwardedVlanTaggingScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "VlanTagging">>()(
    VlanTaggingScreen,
  );
const ForwardedSSIDPasswordScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "SSIDPassword">>()(
    SSIDPasswordScreen,
  );
const ForwardedBlockWebsiteScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "BlockWebsite">>()(
    BlockWebsiteScreen,
  );
const ForwardedSplashCustomURLSetupScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<NetworkScreensPropMap, "SplashCustomURLSetup">
>()(SplashCustomURLSetupScreen);
const ForwardedSplashPagePreviewScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SplashPagePreview">
  >()(SplashPagePreviewScreen);
const ForwardedSplashPageSetupScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SplashPageSetup">
  >()(SplashPageSetupScreen);
const ForwardedSplashWalledGardenScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "SplashWalledGarden">
  >()(SplashWalledGardenScreen);
const ForwardedClientIsolationScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ClientIsolation">
  >()(ClientIsolationScreen);
const ForwardedCreateSSIDScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "CreateSSID">>()(
    CreateSSIDScreen,
  );
const ForwardedDeviceReservationScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "DeviceReservation">
  >()(DeviceReservationScreen);
const ForwardedDHCPServerScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "DHCPServer">>()(
    DHCPServerScreen,
  );
const ForwardedDNSServersScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "DNSServers">>()(
    DNSServersScreen,
  );
const ForwardedReservedAddressesScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ReservedAddresses">
  >()(ReservedAddressesScreen);
const ForwardedLocalAddressingScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "LocalAddressing">
  >()(LocalAddressingScreen);
const ForwardedLocationAnalyticsDateScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<NetworkScreensPropMap, "LocationAnalyticsDate">
>()(LocationAnalyticsDateScreen);
const ForwardedLocationAnalyticsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "LocationAnalytics">
  >()(LocationAnalyticsScreen);
const ForwardedDHCPOptionScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "DHCPOption">>()(
    DHCPOptionScreen,
  );
const ForwardedRadioSettingsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "RadioSettings">
  >()(RadioSettingsScreen);
const ForwardedReserveIPRangeScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ReserveIPRange">
  >()(ReserveIPRangeScreen);
const ForwardedConfigureVlanScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ConfigureVlan">
  >()(ConfigureVlanScreen);
const ForwardedBlockContentScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<NetworkScreensPropMap, "BlockContent">>()(
    BlockContentScreen,
  );
const ForwardedConfigureIpskScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ConfigureIpsk">
  >()(ConfigureIpskScreen);
const ForwardedExportTemplateScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ExportTemplate">
  >()(ExportTemplateScreen);
const ForwardedImportTemplateScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<NetworkScreensPropMap, "ImportTemplate">
  >()(ImportTemplateScreen);

function NavCancelButton() {
  const navigation = useNavigation();
  return (
    <CancelButton
      onPress={() => {
        navigation.goBack();
      }}
    />
  );
}

export function NetworkScreens<T extends NetworkScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="NetworksList"
        component={ForwardedNetworksListScreen}
        options={{ headerTitle: I18n.t("NETWORKS.TITLE") }}
      />
      <Stack.Screen
        name="SSIDsList"
        component={ForwardedSSIDsListScreen}
        options={{ headerTitle: I18n.t("NETWORKS.TITLE") }}
      />
      <Stack.Screen
        name="CreateSSID"
        component={ForwardedCreateSSIDScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="ConfigureVlan"
        component={ForwardedConfigureVlanScreen}
        options={({ route }) => ({
          presentation: "fullScreenModal",
          headerTitle: route.params?.vlan ? I18n.t("CONFIGURE_VLAN.TITLE_CONFIGURE") : undefined,
        })}
      />
      <Stack.Screen
        name="ConfigureIpsk"
        component={ForwardedConfigureIpskScreen}
        options={{ presentation: "fullScreenModal" }}
      />

      <Stack.Screen
        name="LocalAddressing"
        component={ForwardedLocalAddressingScreen}
        options={{ headerTitle: I18n.t("LOCAL_ADDRESSING.TITLE") }}
      />
      <Stack.Screen
        name="ReservedAddresses"
        component={ForwardedReservedAddressesScreen}
        options={{ headerTitle: I18n.t("RESERVED_ADDRESSES.TITLE") }}
      />
      <Stack.Screen
        name="ReserveIPRange"
        component={ForwardedReserveIPRangeScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("RESERVE_IP_RANGE.TITLE") }}
      />
      <Stack.Screen
        name="DeviceReservation"
        component={ForwardedDeviceReservationScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("DEVICE_RESERVATIONS.TITLE"),
        }}
      />
      <Stack.Screen
        name="DHCPServer"
        component={ForwardedDHCPServerScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="DHCPOption"
        component={ForwardedDHCPOptionScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="DNSServers"
        component={ForwardedDNSServersScreen}
        options={{ presentation: "fullScreenModal" }}
      />

      <Stack.Screen
        name="ExportTemplate"
        component={ForwardedExportTemplateScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.TITLE"),

          headerLeft: () => <NavCancelButton />,
        }}
      />

      <Stack.Screen
        name="ImportTemplate"
        component={ForwardedImportTemplateScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.TITLE"),
        }}
      />

      <Stack.Screen name="SSIDDetails" component={ForwardedSSIDDetailsScreen} />
      <Stack.Screen
        name="SSIDConfigure"
        component={ForwardedSSIDConfigureScreen}
        options={{ headerTitle: I18n.t("SETTINGS.TITLE") }}
      />
      <Stack.Screen name="SSIDShare" component={ForwardedSSIDShareScreen} />
      <Stack.Screen
        name="SSIDSharePDF"
        component={ForwardedSSIDSharePDFScreen}
        options={{ headerTitle: I18n.t("SSID_SHARE_PDF.TITLE") }}
      />
      <Stack.Screen
        name="SSIDPassword"
        component={ForwardedSSIDPasswordScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="SplashSelect"
        component={ForwardedSplashSelectScreen}
        options={{ headerTitle: I18n.t("SPLASH_CONFIG.TITLE") }}
      />
      <Stack.Screen
        name="SplashPageSetup"
        component={ForwardedSplashPageSetupScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SPLASH_CONFIG.TITLE") }}
      />

      {/** verify */}
      <Stack.Screen
        name="SplashPagePreview"
        component={ForwardedSplashPagePreviewScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SPLASH_CONFIG.SPLASH_PREVIEW_TITLE"),
        }}
      />
      <Stack.Screen
        name="SplashWalledGarden"
        component={ForwardedSplashWalledGardenScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.TITLE"),
        }}
      />
      {/** verify */}

      <Stack.Screen
        name="SplashCustomURLSetup"
        component={ForwardedSplashCustomURLSetupScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SPLASH_CONFIG.TITLE") }}
      />
      <Stack.Screen
        name="SSIDSchedules"
        component={ForwardedSSIDSchedulesScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.TITLE"),
        }}
      />
      <Stack.Screen
        name="AccessPointAvailability"
        component={ForwardedAccessPointAvailabilityScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SSID_CONFIGURATION.PER_AP_AVAILABILITY.TITLE"),
        }}
      />
      <Stack.Screen
        name="BlockContent"
        component={ForwardedBlockContentScreen}
        options={{ headerTitle: I18n.t("SETTINGS.USAGE.BLOCKING.TITLE") }}
      />
      <Stack.Screen
        name="BlockWebsite"
        component={ForwardedBlockWebsiteScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("BLOCK_CONTENT.TITLE") }}
      />
      {applicationUsageScreens(Stack)}
      <Stack.Screen
        name="NATBridge"
        component={ForwardedNATBridgeScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="SSIDRadioSettings"
        component={ForwardedSSIDRadioSettingsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("RADIO_SETTINGS.TITLE") }}
      />
      <Stack.Screen
        name="RadioSettings"
        component={ForwardedRadioSettingsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("RADIO_SETTINGS.TITLE") }}
      />
      <Stack.Screen
        name="WPASettings"
        component={ForwardedWPASettingsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("WPA_SETTINGS.HEADER") }}
      />
      <Stack.Screen
        name="VlanTagging"
        component={ForwardedVlanTaggingScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="LocationAnalyticsDate"
        component={ForwardedLocationAnalyticsDateScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("LOCATION_ANALYTICS.TITLE"),
        }}
      />
      <Stack.Screen
        name="LocationAnalytics"
        component={ForwardedLocationAnalyticsScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("LOCATION_ANALYTICS.TITLE"),
        }}
      />
      <Stack.Screen
        name="ClientIsolation"
        component={ForwardedClientIsolationScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      {clientScreens(Stack)}
    </>
  );
}
