import DataMsg from "../types/DataMsg";

export interface Message extends DataMsg {
  data: {
    ok: boolean;
    completed: boolean;
  };
}

export function formatMsg(message?: Message): boolean | undefined {
  const rebootCommandSent = message?.data?.ok;

  if (rebootCommandSent === undefined) {
    return undefined;
  }

  return rebootCommandSent;
}
