import { I18n } from "@meraki/core/i18n";
import { useUpdateVlan, useVlan, useVlans, VlanPayload } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import SectionListHeader from "~/go/components/SectionListHeader";
import { Features } from "~/go/types/ContextHelpTypes";
import { DHCPHandling } from "~/go/types/NetworksTypes";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { themeColors } from "~/lib/themeHelper";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { DHCPOption, DHCPOptions } from "~/shared/types/Vlans";

export const DHCPServerScreen = () => {
  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);
  const theme = useTheme();

  const { data: gxVlan } = useVlan({ networkId, vlanId });
  const dhcpServerEnabled = gxVlan?.dhcpHandling === DHCPHandling.on;
  const colors = themeColors(theme);

  const [selectedValue, setSelectedValue] = useState(gxVlan?.dhcpHandling ?? DHCPHandling.off);
  const [dhcpOptions, setDhcpOptions] = useState<DHCPOption[]>(gxVlan?.dhcpOptions ?? []);
  const [dhcpOptionsChanged, setDhcpOptionsChanged] = useState(false);
  const [reqPending, setReqPending] = useState(false);

  const updateVlan = useUpdateVlan();
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const save = useCallback(async () => {
    try {
      setReqPending(true);
      const newVlan: VlanPayload = {
        ...gxVlan,
        dhcpOptions,
        dhcpHandling: selectedValue,
      };

      await updateVlan.mutateAsync(
        { networkId, vlanId, vlan: newVlan },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot });
            navigation.goBack();
          },
          onError: (error) => {
            showAlert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
          },
        },
      );
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [dhcpOptions, gxVlan, navigation, networkId, queryClient, selectedValue, updateVlan, vlanId]);

  const close = useCallback(() => {
    if ((selectedValue === DHCPHandling.on) !== dhcpServerEnabled || dhcpOptionsChanged) {
      showSaveWarning(save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  }, [dhcpOptionsChanged, dhcpServerEnabled, navigation, save, selectedValue]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={close} />,
      headerRight: () => <SaveButton onPress={save} />,
    });
  }, [close, navigation, save]);

  const addNewOption = (option: DHCPOption) => {
    setDhcpOptions([...dhcpOptions, option]);
    setDhcpOptionsChanged(true);
  };

  const updateOption = (rowId: number) => (option: DHCPOption) => {
    const newOptions = [...dhcpOptions];
    newOptions[rowId] = option;

    setDhcpOptions(newOptions);
    setDhcpOptionsChanged(true);
  };

  const removeOption = (rowId: number) => () => {
    const newOptions = [...dhcpOptions];
    newOptions.splice(rowId, 1);

    setDhcpOptions(newOptions);
    setDhcpOptionsChanged(true);
  };

  const openOptionModal = (optionInfo?: { rowData: DHCPOption; rowId: number }) => {
    if (optionInfo == null) {
      navigation.navigate("DHCPOption", {
        onSave: addNewOption,
      });
    } else {
      const { rowData, rowId } = optionInfo;

      navigation.navigate("DHCPOption", {
        option: rowData,
        onSave: updateOption(rowId),
        onRemove: removeOption(rowId),
      });
    }
  };

  const getOptionLabel = (code: string) => {
    switch (code) {
      case DHCPOptions.timeOffset:
        return I18n.t("DHCP_OPTION.OPTIONS.TIME_OFFSET");
      case DHCPOptions.interface:
        return I18n.t("DHCP_OPTION.OPTIONS.INTERFACE");
      case DHCPOptions.ntp:
        return I18n.t("DHCP_OPTION.OPTIONS.NTP");
      case DHCPOptions.tftp:
        return I18n.t("DHCP_OPTION.OPTIONS.TFTP");
    }

    return I18n.t("DHCP_OPTION.OPTIONS.CUSTOM");
  };

  const options = [
    {
      label: I18n.t("DHCP_SERVER.ADDRESS_DISTRIBUTION.SECURITY_GATEWAY"),
      value: DHCPHandling.on,
      context: Features.runDHCPServer,
    },
    {
      label: I18n.t("DHCP_SERVER.ADDRESS_DISTRIBUTION.CUSTOM"),
      value: DHCPHandling.off,
      context: Features.useOtherDHCP,
    },
  ];

  return (
    <FullScreenContainerView>
      <DefaultHeader
        title={I18n.t("DHCP_SERVER.HEADER")}
        description={I18n.t("DHCP_SERVER.MESSAGE")}
        context={Features.dhcpServer}
      />
      <RadioSelectionList
        onSelect={(value: any) => setSelectedValue(value)}
        options={options}
        selectedValue={selectedValue}
        screenStyles={styles.contentContainer}
      />
      {selectedValue === DHCPHandling.on && (
        <MkiTable<DHCPOption>
          data={dhcpOptions}
          renderRow={(rowData: DHCPOption, rowId: number) => (
            <SimpleDisclosureRow
              key={rowId}
              testID={`DHCP_OPTION_ROW_${rowId}`}
              subtitle={rowData.value}
              disclosureIcon={
                <MerakiIcon name="edit" size="s" color={colors.navigation.secondary} />
              }
              onPress={() => openOptionModal({ rowData, rowId })}
            >
              {getOptionLabel(rowData.code)}
            </SimpleDisclosureRow>
          )}
          ListHeaderComponent={
            <SectionListHeader
              heading={I18n.t("DHCP_SERVER.OPTIONS")}
              onPress={() => openOptionModal()}
              testID="DHCP_SERVER.OPTIONS_TABLE"
              withHorizontalMargin
            />
          }
        />
      )}
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    margin: SPACING.small,
    paddingHorizontal: SPACING.small,
  },
});

export default DHCPServerScreen;
