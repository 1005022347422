import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Heading,
  Input,
  List,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useManagementInterface,
  useMutateManagementInterface,
} from "@meraki/shared/api";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useEffect, useRef, useState } from "react";

export const IPConfigScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "IPConfig">>();
  const { params: props } = route;
  const { device } = props;

  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const [vlanID, setVlanID] = useState("");

  const { data: mgmentInterface } = useManagementInterface(
    {
      serial: device.serial,
    },
    {
      enabled: device !== undefined,
    },
  );

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: useManagementInterface.queryKey({ serial: device.serial }),
      queryFn: useManagementInterface.queryFn({ serial: device.serial }),
    });
  });

  const isUsingStaticIp = mgmentInterface?.wan1?.usingStaticIp;

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button.Nav
            leadingIcon="CaretLeft"
            text={device.name || device.serial}
            onPress={() => navigation.goBack()}
          />
        ),
      }),
    [device, navigation],
  );

  const mutateVlan = useMutateManagementInterface();

  const saveVlanId = () => {
    mutateVlan.mutate(
      {
        body: {
          wan1: {
            vlan: vlanID === "" ? null : Number(vlanID),
            usingStaticIp: false,
          },
        },
        serial: device.serial,
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useManagementInterface.queryKeyRoot });
        },
      },
    );
    bottomSheetRef.current?.dismiss();
    setVlanID("");
  };

  const toggleChange = () => {
    mutateVlan.mutate(
      {
        body: {
          wan1: {
            usingStaticIp: !mgmentInterface?.wan1?.usingStaticIp,
            vlan: mgmentInterface?.wan1?.vlan ?? 1,
          },
        },
        serial: device.serial,
      },
      {
        onError: () => {
          navigation.navigate("AddStaticIP", { device });
        },
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useManagementInterface.queryKeyRoot });
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding>
      <Box paddingVertical="none" padding="sm" bottomDividerBorder>
        <Heading size="h1">{I18n.t("IP_CONFIG.HEADER")}</Heading>
      </Box>
      <List>
        <List.Item
          title={I18n.t("IP_CONFIG.DHCP")}
          description={I18n.t("IP_CONFIG.DHCP_DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={<Toggle checked={!isUsingStaticIp} onValueChange={toggleChange} />}
        >
          <List>
            <List.Item
              title={
                !mgmentInterface?.wan1?.usingStaticIp
                  ? mgmentInterface?.wan1?.vlan ?? I18n.t("DEFAULT")
                  : I18n.t("IP_CONFIG.NOT_ENABLED")
              }
              description={
                !mgmentInterface?.wan1?.usingStaticIp ? I18n.t("IP_CONFIG.VLAN_ID") : null
              }
              rightAccessory={
                <Button
                  text={I18n.t("IP_CONFIG.EDIT")}
                  kind="tertiary"
                  onPress={() => bottomSheetRef.current?.present()}
                  testID="DHCP_TEST"
                />
              }
            />
          </List>
        </List.Item>
      </List>
      <List>
        <List.Item
          title={I18n.t("IP_CONFIG.STATIC_IP")}
          description={I18n.t("IP_CONFIG.STATIC_IP_DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={<Toggle checked={!!isUsingStaticIp} onValueChange={toggleChange} />}
        >
          <List>
            <List.Item
              title={
                mgmentInterface?.wan1?.usingStaticIp
                  ? mgmentInterface?.wan1?.vlan ?? I18n.t("DEFAULT")
                  : I18n.t("IP_CONFIG.NOT_ENABLED")
              }
              description={mgmentInterface?.wan1?.staticIp}
              rightAccessory={
                <Button
                  text={
                    mgmentInterface?.wan1?.staticIp
                      ? I18n.t("IP_CONFIG.EDIT")
                      : I18n.t("IP_CONFIG.ADD")
                  }
                  kind="tertiary"
                  onPress={() => navigation.navigate("AddStaticIP", { device })}
                />
              }
            />
          </List>
        </List.Item>
      </List>

      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("IP_CONFIG.DHCP")}
          onCancelPress={() => {
            setVlanID("");
            bottomSheetRef.current?.dismiss();
          }}
          onResetPress={() => setVlanID("")}
        />
        <BottomSheet.Content>
          <Card>
            <Input
              label={I18n.t("IP_CONFIG.VLAN_ID")}
              onChangeText={setVlanID}
              value={vlanID}
              placeholder={I18n.t("IP_CONFIG.VLAN_ID_PLACEHOLDER")}
              inputMode="numeric"
            />
            <Button text={I18n.t("IP_CONFIG.SAVE")} onPress={saveVlanId} />
          </Card>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
