import { RUSAGE_KEY } from "@meraki/shared/api";
import { compact } from "lodash";
import { createSelector } from "reselect";

import { clientName } from "~/lib/ClientUtils";
import { verifyIsSMDevice } from "~/lib/TypeHelper";
import { clientsSelector } from "~/selectors/clients";
import { counterSetsState } from "~/selectors/entities";
import { getClientApplicationUsages } from "~/selectors/getters";
import { RootState } from "~/shared/types/Redux";

export const makeApplicationTotalUsageSelector = () =>
  createSelector(
    counterSetsState,
    getClientApplicationUsages,
    (_: RootState, props: any) => props.applicationId,
    (counterSet, clientApplicationUsages, applicationId) => {
      const applicationName = counterSet?.[RUSAGE_KEY]?.rules?.[applicationId]?.name;
      if (applicationName == null) {
        return 0;
      }

      return Object.values(clientApplicationUsages).reduce((total, appUses) => {
        const appUse = appUses?.[applicationName] || { received: 0, sent: 0 };
        if (appUse == null) {
          return total;
        }

        return total + appUse.received + appUse.sent;
      }, 0);
    },
  );

export type ClientApplicationType = {
  id: string;
  children: string;
  ssidNumber: string | number | undefined;
  usage: number;
  percent: number;
};

export const makeClientsByApplicationSelector = () =>
  createSelector(
    counterSetsState,
    clientsSelector,
    getClientApplicationUsages,
    makeApplicationTotalUsageSelector(),
    (_, props) => props.applicationId,
    (
      counterSet,
      clients,
      clientApplicationUsages,
      totalUsage,
      applicationId,
    ): ClientApplicationType[] => {
      const applicationName = counterSet?.[RUSAGE_KEY]?.rules?.[applicationId]?.name;
      if (applicationName == null) {
        return [];
      }

      // @ts-expect-error: Type is not assignable to type 'ClientApplicationType[]'...
      return compact(
        clients.map((client) => {
          const appUse = clientApplicationUsages?.[client.id]?.[applicationName];
          if (appUse == null) {
            return null;
          }

          const usage = appUse.received + appUse.sent;
          return {
            id: client.id,
            children: verifyIsSMDevice(client) ? client.name : clientName(client),
            ssidNumber: client.ssid,
            usage,
            percent: usage / totalUsage,
          };
        }),
      ).sort((a: any, b: any) => b.percent - a.percent);
    },
  );

export const makeClientsByApplicationForSSIDSelector = (ssidNumber: any) =>
  createSelector(makeClientsByApplicationSelector(), (clients) =>
    clients.filter(
      (client: any) => client.ssidNumber !== undefined && client.ssidNumber === ssidNumber,
    ),
  );
