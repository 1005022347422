import { Ssid, useSsids, useVlans, VlanExport } from "@meraki/shared/api";
import { calculateSubnetRange, getApplianceIpFromSubnet } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import ImportTemplateButton from "~/go/components/networkTemplate/ImportTemplateButton";
import SsidNetworkCard from "~/go/components/networkTemplate/SsidNetworkCard";
import VlanNetworkCard from "~/go/components/networkTemplate/VlanNetworkCard";
import { PickerModalItems } from "~/go/components/PickerModal";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { ExportType, exportTypeSelect } from "~/lib/ExportTemplateUtils";
import {
  getVlanErrors,
  importFormatSsidId,
  importFormatVlanId,
  StagedVlanMap,
} from "~/lib/ImportTemplateUtils";
import { getNumberAvailableSSIDs } from "~/lib/SSIDUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import useObjectUpdater from "~/shared/hooks/useObjectUpdater";
import { CancelButton } from "~/shared/navigation/Buttons";

const IMPASSABLE_ERRORS = ["ip_overlap", "default_id"];

export interface ErrorMap {
  [key: string]: string[];
}

export type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "ImportTemplate">;

const ImportTemplateScreen = ({ importedNetworks }: Props) => {
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();

  const { data: existingSsids, isFetching: isSsidFetching } = useSsids({ networkId });
  const { data: existingVlans, isFetching: isVlanFetching } = useVlans({ networkId });

  const [selectedSsids, setSelectedSsids] = useState<string[]>([]);
  // initiated as ""[] in order to keep track of which imported ssids will be
  // overwritting which existing ssid, where the array index === existingSsid number
  const [selectedExistingSsids, setSelectedExistingSsids] = useState<string[]>(["", "", "", ""]);
  const [selectedVlans, setSelectedVlans] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorMap>({});

  const { ssids: importedSsids, vlans: importedVlans } = importedNetworks;

  // used to keep track of diff of imported vlans, instantiated w/ modifyable fields
  const [stagedVlanObject, _, updateStagedVlanObjectByKey] = useObjectUpdater<StagedVlanMap>(
    importedVlans?.reduce((prev, vlan) => {
      const subnetRange = vlan.subnet ? calculateSubnetRange(vlan.subnet) : [];

      return {
        ...prev,
        [vlan.id]: { id: vlan.id, subnet: vlan.subnet, subnetRange: subnetRange } ?? [],
      };
    }, {}) ?? {},
  );

  const existingVlanSubnetRanges =
    existingVlans?.reduce((result, { subnet }) => {
      if (subnet) {
        const ranges = calculateSubnetRange(subnet);
        if (ranges != null) {
          result.push(ranges);
        }
      }
      return result;
    }, [] as number[][]) ?? [];

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <CancelButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const disabledImportButton = useCallback(() => {
    const hasErrors = Object.values(errors).some((vlanErrors) =>
      vlanErrors.some((error) => IMPASSABLE_ERRORS.includes(error)),
    );

    const emptyVlanField = Object.entries(stagedVlanObject).some(
      ([_, stagedValue]) => stagedValue.id.toString() === "" || stagedValue.subnet === "",
    );

    const numSelectedExistingSsid = selectedExistingSsids.reduce(
      (sum, val) => (val !== "" ? (sum += 1) : sum),
      0,
    );
    const selectedSsidPaired =
      numSelectedExistingSsid > 0 ? selectedSsids.length === numSelectedExistingSsid : false;

    const someVlanSelected = selectedVlans.length !== 0;

    return hasErrors || emptyVlanField || !(someVlanSelected || selectedSsidPaired);
  }, [errors, selectedExistingSsids, selectedSsids.length, selectedVlans.length, stagedVlanObject]);

  const rowTapHandler = (type: ExportType, id: string) => {
    const [selectedIds, setSelectedIds] = exportTypeSelect(
      {
        ssid: [selectedSsids, setSelectedSsids],
        vlan: [selectedVlans, setSelectedVlans],
      },
      type,
    );

    if (selectedIds.includes(id)) {
      const filteredIds = selectedIds.filter((selectedId) => selectedId != id);
      setSelectedIds(filteredIds);
    } else {
      setSelectedIds([...selectedIds, id].sort());
    }
  };

  const getSsidOptions = useCallback(() => {
    const options = [] as PickerModalItems[];
    existingSsids?.forEach((existingSsid, index) => {
      if (existingSsid.number !== undefined) {
        if (selectedExistingSsids[index] === "") {
          options.push({
            label: existingSsid.name,
            testID: `SSID_${existingSsid.number}.OPTION`,
            value: existingSsid.number,
          });
        }
      }
    });
    return options;
  }, [existingSsids, selectedExistingSsids]);

  const onSsidOverwrite = (ssidId: string, ids: number[]) => {
    const newSelected = [...selectedExistingSsids];
    ids.forEach((id) => {
      if (selectedExistingSsids[id] != "") {
        newSelected[id] = "";
      } else {
        newSelected[id] = ssidId;
      }
    });
    setSelectedExistingSsids((_) => newSelected);
  };

  const getLocalAndSetScreenVlanErrorState = useCallback(
    (
      currentVlanId: number,
      vlanId: string,
      vlanIpAddress: string,
      forceClearVlanError: boolean,
    ) => {
      if (forceClearVlanError) {
        setErrors((previousErrors) => ({ ...previousErrors, [currentVlanId]: [] }));
        return [];
      }

      const errorsArr = existingVlans
        ? getVlanErrors(currentVlanId, vlanId, vlanIpAddress, existingVlans, stagedVlanObject)
        : [];

      setErrors((previousErrors) => ({ ...previousErrors, [currentVlanId]: errorsArr }));
      return errorsArr;
    },
    [existingVlans, stagedVlanObject],
  );

  const getModifiedSsids = () => {
    const modifiedSsids = [] as Ssid[];
    importedSsids?.forEach((ssid) => {
      const ssidId = importFormatSsidId(ssid);
      if (selectedSsids.includes(ssidId) && selectedExistingSsids.includes(ssidId)) {
        const existingSsidNumber = selectedExistingSsids.indexOf(ssidId);
        modifiedSsids.push({ ...ssid, number: existingSsidNumber });
      }
    });
    return modifiedSsids;
  };

  const getModifiedVlans = () => {
    const filtered = importedVlans?.filter((vlan) =>
      selectedVlans.includes(importFormatVlanId(vlan)),
    );
    const modifiedVlans = [] as VlanExport[];
    filtered?.forEach((vlan) => {
      const stagedUpdates = stagedVlanObject[vlan.id];
      modifiedVlans.push({
        ...vlan,
        id: stagedUpdates.id,
        subnet: stagedUpdates.subnet,
        applianceIp: getApplianceIpFromSubnet(stagedUpdates.subnet),
      });
    });
    return modifiedVlans;
  };

  return (
    <FullScreenContainerView withScroll>
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <FlatList
            data={importedSsids}
            keyExtractor={(item: Ssid) => `wireless${item.number}`}
            renderItem={({ item }) => (
              <SsidNetworkCard
                ssid={item}
                overMaxSsids={getNumberAvailableSSIDs(existingSsids ?? []) < selectedSsids.length}
                ssidOptions={getSsidOptions()}
                existingSsids={existingSsids ?? []}
                selectedExistingSsids={selectedExistingSsids}
                onSsidOverwrite={onSsidOverwrite}
                isSelected={selectedSsids.includes(
                  `${item.name}.${item.number ?? -1}`.replace(/\s/g, ""),
                )}
                tapHandler={rowTapHandler}
              />
            )}
            testID="SSID_LIST.TABLE"
          />
          <FlatList
            data={importedVlans}
            keyExtractor={(item: VlanExport) => `wired${item.id}`}
            renderItem={({ item }) => (
              <VlanNetworkCard
                vlan={item}
                existingVlanSubnetRanges={existingVlanSubnetRanges}
                stagedValueMap={stagedVlanObject}
                stagedVlanUpdater={updateStagedVlanObjectByKey(item.id)}
                isSelected={selectedVlans.includes(`${item.name}.${item.id}`.replace(/\s/g, ""))}
                tapHandler={rowTapHandler}
                getLocalVlanErrorState={getLocalAndSetScreenVlanErrorState}
              />
            )}
            testID="VLAN_LIST.TABLE"
          />
        </View>
        <ImportTemplateButton
          ssids={getModifiedSsids()}
          vlans={getModifiedVlans()}
          existingVlans={existingVlans}
          disabled={disabledImportButton()}
        />
      </View>
      <LoadingSpinner visible={isSsidFetching || isVlanFetching} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingHorizontal: SPACING.default,
  },
  contentContainer: {
    flex: 1,
  },
});

export default ImportTemplateScreen;
