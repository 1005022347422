import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const AlertSettingsTypes = {
  ampMalwareDetected: "ampMalwareDetected",
  ampMalwareBlocked: "ampMalwareBlocked",
  applianceDown: "applianceDown",
  dhcpNoLeases: "dhcpNoLeases",
  rogueDhcp: "rogueDhcp",
  ipConflict: "ipConflict",
  ip6Conflict: "ip6Conflict",
  dhcp6naRenumber: "dhcp6naRenumber",
  dhcp6pdRenumber: "dhcp6pdRenumber",
  cellularUpDown: "cellularUpDown",
  clientConnectivity: "clientConnectivity",
  settingsChanged: "settingsChanged",
  usageAlert: "usageAlert",
  weeklyUmbrella: "weeklyUmbrella",
  prefixStarvation: "prefixStarvation",
  portDown: "portDown",
  powerSupplyDown: "powerSupplyDown",
  rpsBackup: "rpsBackup",
  udldError: "udldError",
  portError: "portError",
  portSpeed: "portSpeed",
  cameraDown: "cameraDown",
  switchDown: "switchDown",
  switchCriticalTemperature: "switchCriticalTemperature",
  gatewayDown: "gatewayDown",
  gatewayToRepeater: "gatewayToRepeater",
  repeaterDown: "repeaterDown",
  snr: "snr",
  highWirelessUsage: "highWirelessUsage",
  onboarding: "onboarding",
  weeklyPresence: "weeklyPresence",
  uplinkIp6Conflict: "uplinkIp6Conflict",
  failoverEvent: "failoverEvent",
  portScheduleSuppression: "portScheduleSuppression",
  newDhcpServer: "newDhcpServer",
  motionAlert: "motionAlert",
  nodeHardwareFailure: "nodeHardwareFailure",
  sensorUsbPowerCable: "sensorUsbPowerCable",
  sensorBatteryCover: "sensorBatteryCover",
  sensorMagneticTampering: "sensorMagneticTampering",
  sensorWaterCable: "sensorWaterCable",
  sensorProbeCable: "sensorProbeCable",
  sensorBatteryPercentage: "sensorBatteryPercentage",
  sensorDown: "sensorDown",
  vpnConnectivityChange: "vpnConnectivityChange",
  vrrp: "vrrp",
  rogueAp: "rogueAp",
  cloudArchiveAlert: "cloudArchiveAlert",
  pccExpiredApnsCert: "pccExpiredApnsCert",
  pccEnrollment: "pccEnrollment",
  geofencingIn: "geofencingIn",
  geofencingOut: "geofencingOut",
  pccUnmanaged: "pccUnmanaged",
  pccOutage: "pccOutage",
  swFound: "swFound",
  customMotionAlertRecipients: "customMotionAlertRecipients",
} as const;

export const AlertSettingsTypesSchema = z.union([
  z.literal(AlertSettingsTypes.ampMalwareDetected),
  z.literal(AlertSettingsTypes.ampMalwareBlocked),
  z.literal(AlertSettingsTypes.applianceDown),
  z.literal(AlertSettingsTypes.dhcpNoLeases),
  z.literal(AlertSettingsTypes.rogueDhcp),
  z.literal(AlertSettingsTypes.ipConflict),
  z.literal(AlertSettingsTypes.ip6Conflict),
  z.literal(AlertSettingsTypes.dhcp6naRenumber),
  z.literal(AlertSettingsTypes.dhcp6pdRenumber),
  z.literal(AlertSettingsTypes.cellularUpDown),
  z.literal(AlertSettingsTypes.clientConnectivity),
  z.literal(AlertSettingsTypes.settingsChanged),
  z.literal(AlertSettingsTypes.usageAlert),
  z.literal(AlertSettingsTypes.weeklyUmbrella),
  z.literal(AlertSettingsTypes.prefixStarvation),
  z.literal(AlertSettingsTypes.portDown),
  z.literal(AlertSettingsTypes.powerSupplyDown),
  z.literal(AlertSettingsTypes.rpsBackup),
  z.literal(AlertSettingsTypes.udldError),
  z.literal(AlertSettingsTypes.portError),
  z.literal(AlertSettingsTypes.portSpeed),
  z.literal(AlertSettingsTypes.switchDown),
  z.literal(AlertSettingsTypes.switchCriticalTemperature),
  z.literal(AlertSettingsTypes.gatewayDown),
  z.literal(AlertSettingsTypes.gatewayToRepeater),
  z.literal(AlertSettingsTypes.repeaterDown),
  z.literal(AlertSettingsTypes.snr),
  z.literal(AlertSettingsTypes.highWirelessUsage),
  z.literal(AlertSettingsTypes.onboarding),
  z.literal(AlertSettingsTypes.weeklyPresence),
  z.literal(AlertSettingsTypes.uplinkIp6Conflict),
  z.literal(AlertSettingsTypes.failoverEvent),
  z.literal(AlertSettingsTypes.portScheduleSuppression),
  z.literal(AlertSettingsTypes.newDhcpServer),
  z.literal(AlertSettingsTypes.motionAlert),
  z.literal(AlertSettingsTypes.cameraDown),
  z.literal(AlertSettingsTypes.nodeHardwareFailure),
  z.literal(AlertSettingsTypes.sensorUsbPowerCable),
  z.literal(AlertSettingsTypes.sensorBatteryCover),
  z.literal(AlertSettingsTypes.sensorMagneticTampering),
  z.literal(AlertSettingsTypes.sensorWaterCable),
  z.literal(AlertSettingsTypes.sensorProbeCable),
  z.literal(AlertSettingsTypes.sensorBatteryPercentage),
  z.literal(AlertSettingsTypes.sensorDown),
  z.literal(AlertSettingsTypes.rogueAp),
  z.literal(AlertSettingsTypes.vpnConnectivityChange),
  z.literal(AlertSettingsTypes.vrrp),
  z.literal(AlertSettingsTypes.cloudArchiveAlert),
  z.literal(AlertSettingsTypes.pccExpiredApnsCert),
  z.literal(AlertSettingsTypes.pccEnrollment),
  z.literal(AlertSettingsTypes.geofencingIn),
  z.literal(AlertSettingsTypes.geofencingOut),
  z.literal(AlertSettingsTypes.pccUnmanaged),
  z.literal(AlertSettingsTypes.pccOutage),
  z.literal(AlertSettingsTypes.swFound),
  z.literal(AlertSettingsTypes.customMotionAlertRecipients),
]);
export type AlertSettingsType = z.infer<typeof AlertSettingsTypesSchema>;

export const AlertDestinationsSchema = z.object({
  allAdmins: z.boolean(),
  emails: z.array(z.string()),
  mobileAdminIds: z.array(z.string()).optional(),
  snmp: z.boolean().optional(),
});

export const AlertClientSchema = z.object({
  mac: z.string(),
  name: z.string(),
});

const Filters = z.object({
  timeout: z.number().optional(),
  selector: z.string().optional(),
  period: z.number().optional(),
  threshold: z.number().optional(),
  clients: z.array(AlertClientSchema).optional(),
});

export const AlertSettingsItemSchema = z.object({
  alertDestinations: AlertDestinationsSchema,
  enabled: z.boolean().optional(),
  filters: Filters.optional(),
  type: AlertSettingsTypesSchema,
});
export type AlertSettingsItem = z.infer<typeof AlertSettingsItemSchema>;

export type AlertDestinations = z.infer<typeof AlertDestinationsSchema>;

export type AlertClient = z.infer<typeof AlertClientSchema>;

export const AlertSettingsSchema = z
  .object({
    alerts: z.array(AlertSettingsItemSchema),
    defaultDestinations: AlertDestinationsSchema,
  })
  .describe("AlertSettingsSchema");
export type AlertSettings = z.infer<typeof AlertSettingsSchema>;

export const AlertSettingsPayloadSchema = z
  .object({
    alerts: z.array(AlertSettingsItemSchema),
    defaultDestinations: AlertDestinationsSchema.optional(),
  })
  .describe("AlertSettingsPayloadSchema");
export type AlertSettingsPayload = z.infer<typeof AlertSettingsPayloadSchema>;

interface AlertSettingsRequest {
  networkId?: string;
}

interface UpdateAlertSettingsRequest {
  networkId?: string;
  alertSettings: AlertSettingsPayload;
}

interface AlertSettingsError {
  errors?: string[];
}

function buildUrl({ networkId }: AlertSettingsRequest) {
  return `/api/v1/networks/${networkId}/alerts/settings`;
}

function fetchAlertSettings({ networkId }: AlertSettingsRequest): Promise<AlertSettings> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching the alert settings");
  }

  return request(AlertSettingsSchema, "GET", buildUrl({ networkId }));
}

function updateAlertSettings({
  networkId,
  alertSettings,
}: UpdateAlertSettingsRequest): Promise<AlertSettings> {
  if (!networkId) {
    throw new Error("Undefined network id when updating the alert settings");
  }

  return request(AlertSettingsSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify(alertSettings),
  });
}

export const useAlertSettings = createQuery<
  AlertSettingsRequest,
  AlertSettings,
  AlertSettingsError
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: ({ networkId }) => fetchAlertSettings({ networkId }),
  requiredVariables: ["networkId"],
});

export const useUpdateAlertSettings = createMutation<
  UpdateAlertSettingsRequest,
  AlertSettings,
  AlertSettingsError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: UpdateAlertSettingsRequest) => updateAlertSettings(options),
});
