import {
  CLEAR_PAGINATION,
  GET_NEXT_PAGE_FAILURE,
  GET_NEXT_PAGE_REQUEST,
  GET_NEXT_PAGE_SUCCESS,
  GET_PREV_PAGE_FAILURE,
  GET_PREV_PAGE_REQUEST,
  GET_PREV_PAGE_SUCCESS,
} from "@meraki/shared/redux";

import { CALL_API } from "~/middleware/api";
import { getList } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function fetchNextPage(listId: string): AppThunk<void | Promise<any>> {
  return (dispatch, getState) => {
    const state = getState();

    const listData = getList(state, listId);
    if (!listData?.nextPage) {
      return;
    }

    const nextPage = listData.nextPage;
    const refreshId = listData.refreshId;

    return dispatch({
      [CALL_API]: {
        types: [GET_NEXT_PAGE_REQUEST, GET_NEXT_PAGE_SUCCESS, GET_NEXT_PAGE_FAILURE],
        endpoint: nextPage,
        config: {
          method: Method.get,
        },
        meta: {
          listId,
          refreshId,
        },
      },
    });
  };
}

export function fetchPrevPage(listId: string): AppThunk<void | Promise<any>> {
  return (dispatch, getState) => {
    const state = getState();

    const listData = getList(state, listId);
    if (!listData?.prevPage) {
      return;
    }

    const prevPage = listData.prevPage;
    const refreshId = listData.refreshId;

    return dispatch({
      [CALL_API]: {
        types: [GET_PREV_PAGE_REQUEST, GET_PREV_PAGE_SUCCESS, GET_PREV_PAGE_FAILURE],
        endpoint: prevPage,
        config: {
          method: Method.get,
        },
        meta: {
          listId,
          refreshId,
        },
      },
    });
  };
}

export function clearPagination(listId: string) {
  return {
    type: CLEAR_PAGINATION,
    meta: {
      listId,
    },
  };
}
