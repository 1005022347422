import { I18n } from "@meraki/core/i18n";
import { Button, Input, Notification, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { LoginResponseType, verifyIsLoginResponse } from "@meraki/shared/api";
import { useAppForeground } from "@meraki/shared/navigation";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { platformSelect } from "~/lib/PlatformUtils";
import { errorMessageState, smsBackupState, smsPrimaryState, smsSecondaryState } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import Clipboard from "~/shared/lib/clipboard";
import { SMSTypes } from "~/shared/types/AuthTypes";

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "TwoFactorAuth">;

export function TwoFactorAuthScreen() {
  const navigation = useNavigation<Props["navigation"]>();

  const { submitTwoFactorAuth, resendSMSCode } = useActions();

  const errorMessage = useAppSelector(errorMessageState);
  const smsBackup = useAppSelector(smsBackupState);
  const smsPrimary = useAppSelector(smsPrimaryState);
  const smsSecondary = useAppSelector(smsSecondaryState);

  const [isLoading, setIsLoading] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [showPaste, setShowPaste] = useState(false);
  const [showNeedPastePermission, setShowNeedPastePermission] = useState(false);

  useEffect(() => {
    Clipboard.hasNumber()?.then((hasNumber) => setShowPaste(hasNumber));
  }, []);

  const createMessage = () => {
    if (smsPrimary) {
      const smsNumber = smsPrimary || smsBackup;
      return I18n.t("TWO_FACTOR_AUTH.SMS", { smsNumber });
    } else {
      return I18n.t("TWO_FACTOR_AUTH.APP");
    }
  };

  const handleResendCodePress = () => {
    showActionSheet(
      [
        I18n.t("TWO_FACTOR_AUTH.RESEND.PRIMARY", { smsPrimary }),
        ...(smsSecondary ? [I18n.t("TWO_FACTOR_AUTH.RESEND.SECONDARY", { smsSecondary })] : []),
      ],
      (buttonIndex: number) =>
        resendSMSCode(buttonIndex === 0 ? SMSTypes.primary : SMSTypes.secondary),
      {
        title: I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE"),
        message: I18n.t("TWO_FACTOR_AUTH.RESEND.MESSAGE"),
      },
    );
  };

  const handleVerifyPress = () => {
    setIsLoading(true);
    submitTwoFactorAuth(pinCode, false)
      .then((response?: LoginResponseType) => {
        // if everything succeeded during login and there is no followup, then nothing is returned
        // from the loginUser call and we have nothing to handle post login
        if (!response) {
          return;
        }

        if (verifyIsLoginResponse(response)) {
          navigation.navigate("VerifyEmail", { response });
          return;
        }

        const { mode } = response;

        if (mode === "org_choose") {
          navigation.navigate("OrgChoose", { initialLogin: true });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        showAlert(I18n.t("ERROR"), error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useAppForeground(async () => {
    const hasNumber = await Clipboard.hasNumber();
    setShowPaste(!!hasNumber);
  });

  const otpProps = platformSelect({
    ios: { textContentType: "oneTimeCode" as const },
    android: { autoComplete: "sms-otp" as const },
  });

  return (
    <Screen.View addDefaultPadding gap="md">
      {showNeedPastePermission && (
        <Notification.Inline
          status="warning"
          message={I18n.t("TWO_FACTOR_AUTH.PASTE_WARNING")}
          onDismiss={() => setShowNeedPastePermission(false)}
        />
      )}
      <Text size="p1">{createMessage()}</Text>
      {showPaste && (
        <Button
          text={I18n.t("TWO_FACTOR_AUTH.PASTE_FROM_CLIPBOARD")}
          kind="tertiary"
          onPress={async () => {
            const text = await Clipboard.getString();
            /* 
              Clipboard.getString() prompts the user to see if they want to allow us to access their clipboard contents
              If they deny, `text` will be "", so we let them know via a notification that we need permissions if they 
              want to use the function of the button.
            */
            if (text === "") {
              setShowNeedPastePermission(true);
            } else {
              setShowNeedPastePermission(false);
              setPinCode(text);
            }
          }}
        />
      )}
      <Input
        label={I18n.t("TWO_FACTOR_AUTH.CODE")}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType="numeric"
        value={pinCode}
        onChangeText={setPinCode}
        disabled={isLoading}
        {...otpProps}
      />
      {errorMessage && <Notification.Inline status="negative" message={errorMessage} />}
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        onPress={handleVerifyPress}
        loading={isLoading}
      />
      {!!smsPrimary && (
        <Button
          kind="secondary"
          text={I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
          onPress={handleResendCodePress}
        />
      )}
    </Screen.View>
  );
}
