import { BaseDeviceSubProps } from "../../types/BaseSubProps";
import DataMsg from "../../types/DataMsg";

export interface Props extends BaseDeviceSubProps {
  server: string;
  host: string;
  command: "dns";
}

export interface Message extends DataMsg {
  data: {
    chunk: string;
    completed: boolean;
  };
}

export function formatMsg(message?: Message): string | undefined {
  const result = message?.data?.chunk;
  return result;
}
