import z from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const ANY = "any";

export enum Protocol {
  tcp = "tcp",
  udp = "udp",
  any = "any",
}

export enum Policy {
  allow = "allow",
  deny = "deny",
}

export const L3FirewallRuleSchema = z
  .object({
    comment: z.string(),
    policy: z.enum(["allow", "deny"]),
    protocol: z.enum(["tcp", "udp", "Any"]),
    destPort: z.string(),
    destCidr: z.string(),
    srcPort: z.string(),
    srcCidr: z.string(),
    syslogEnabled: z.boolean(),
  })
  .describe("L3FirewallRuleSchema");

export const L3FirewallRulesSchema = z
  .object({
    rules: z.array(L3FirewallRuleSchema),
  })
  .describe("L3FirewallRulesSchema");

export type L3FirewallRule = z.infer<typeof L3FirewallRuleSchema>;

export type L3FirewallRules = z.infer<typeof L3FirewallRulesSchema>;

interface L3FirewallRulesError {
  errors?: string[];
}

interface L3FirewallRuleRequest {
  networkId?: string;
}

type L3FirewallRulePutRequest = L3FirewallRuleRequest & {
  params: L3FirewallRules;
};

function buildUrl({ networkId }: L3FirewallRuleRequest) {
  return `/api/v1/networks/${networkId}/appliance/firewall/l3FirewallRules`;
}

function fetchL3FirewallRules(variables: L3FirewallRuleRequest): Promise<L3FirewallRules> {
  return request(L3FirewallRulesSchema, "GET", buildUrl(variables));
}

function updateL3FirewallRules(variables: L3FirewallRulePutRequest): Promise<L3FirewallRules> {
  return request(L3FirewallRulesSchema, "PUT", buildUrl(variables), {
    body: JSON.stringify(variables.params),
  });
}

export const useL3FirewallRules = createQuery<
  L3FirewallRuleRequest,
  L3FirewallRules,
  L3FirewallRulesError
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchL3FirewallRules(variables),
  requiredVariables: ["networkId"],
});

export const useUpdateL3FirewallRules = createMutation<
  L3FirewallRulePutRequest,
  L3FirewallRules,
  L3FirewallRulesError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: L3FirewallRulePutRequest) => updateL3FirewallRules(options),
});
