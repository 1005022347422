import { MS_IN_A_SECOND } from "@meraki/shared/redux";
import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface GoUmbrellaActivityRequest {
  accessToken: string;
  timespan: number;
}

export const Category = z
  .object({
    id: z.number(),
    type: z.string(),
    label: z.string(),
    integration: z.boolean(),
    deprecated: z.boolean(),
  })
  .describe("Category");

export const UmbrellaDataSchema = z
  .object({
    returncode: z.number(),
    externalip: z.string(),
    allapplications: z.array(z.any()).nullable(),
    date: z.string(),
    internalip: z.string(),
    time: z.string(),
    querytype: z.string(),
    policycategories: z.array(Category),
    type: z.string(),
    categories: z.array(Category),
    verdict: z.string(),
    device: z.object({
      id: z.number().nullable(),
    }),
    domain: z.string(),
    timestamp: z.number(),
    blockedapplications: z.array(z.any()),
    allowedapplications: z.array(z.any()),
    identities: z.array(
      z.object({
        id: z.number(),
        type: z.object({
          id: z.number(),
          type: z.string(),
          label: z.string(),
        }),
        label: z.string(),
        deleted: z.boolean(),
      }),
    ),
    threats: z.array(z.any()),
    message: z.string().nullable().optional(),
  })
  .describe("UmbrellaDataSchema");

export type UmbrellaData = z.infer<typeof UmbrellaDataSchema>;

export const UmbrellaActivitySchema = z
  .object({
    meta: z.any(),
    data: z.array(UmbrellaDataSchema),
  })
  .describe("UmbrellaActivitySchema");

export type UmbrellaActivity = z.infer<typeof UmbrellaActivitySchema>;

const UMBRELLA_BASE_URL = "https://api.umbrella.com/reports/v2";
const TOP_IPS_LIMIT = 5000;

const buildUrl = () => {
  return `/activity`;
};

const fetchGoUmbrellaActivity = ({ timespan, accessToken }: GoUmbrellaActivityRequest) => {
  const to = Date.now();
  const from = to - timespan * MS_IN_A_SECOND * 31;

  return request(UmbrellaActivitySchema, "GET", buildUrl(), {
    headers: { Authorization: `Bearer ${accessToken}` },
    baseUrl: UMBRELLA_BASE_URL,
    queryParams: { from, to, limit: TOP_IPS_LIMIT, offset: 0, verdict: "blocked" },
  });
};

export const useUmbrellaActivity = createQuery({
  baseQueryKey: buildUrl(),
  queryFn: (options: GoUmbrellaActivityRequest) => fetchGoUmbrellaActivity(options),
  requiredVariables: ["timespan", "accessToken"],
});
