import { NumeralInternal } from "./NumeralInternal";
import { PercentNumeral } from "./PercentNumeral";
import { TimeNumeral } from "./TimeNumeral";

export { NumeralProps } from "./types";

type NumeralType = typeof NumeralInternal & {
  Time: typeof TimeNumeral;
  Percent: typeof PercentNumeral;
};

export const Numeral: NumeralType = Object.assign(NumeralInternal, {
  Time: TimeNumeral,
  Percent: PercentNumeral,
});
