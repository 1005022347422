/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum SharedColors {
  trueBlack = "#000000",
  gray10 = "#222325",
  gray10Lighter = "#2A2B2D",
  gray10Darker = "#1B1B1E",
  gray20 = "#3D3E40",
  gray20Lighter = "#444447",
  gray20Darker = "#353538",
  gray30 = "#575859",
  gray30Lighter = "#5F5F61",
  gray30Darker = "#4F5052",
  gray40 = "#707173",
  gray40Lighter = "#78787A",
  gray40Darker = "#69696B",
  gray50 = "#898A8C",
  gray50Lighter = "#919194",
  gray50Darker = "#828285",
  gray60 = "#A2A3A6",
  gray60Lighter = "#AAABAD",
  gray60Darker = "#9B9B9E",
  gray70 = "#BDBEBF",
  gray70Lighter = "#C5C5C7",
  gray70Darker = "#B4B5B8",
  gray80 = "#D7D7D9",
  gray80Lighter = "#DEDFE0",
  gray80Darker = "#CFCFD1",
  gray88 = "#E0E0E0",
  gray90 = "#F2F2F2",
  gray90Lighter = "#FAFAFA",
  gray90Darker = "#EBEBEB",
  grayFaded80 = "rgba(0, 0, 0, 0.80)",
  grayFaded35 = "rgba(0, 0, 0, 0.35)",
  grayFaded15 = "rgba(0, 0, 0, 0.15)",
  lpink = "#F2AAC2",
  red = "#E64C2E",
  redDarker = "#C72909",
  redDarkest = "#991F07",
  redLighter = "#F38379",
  redLightest = "#FAE1DC",
  chartMagenta = "#E91E63",
  chartLineBlue = "#049FD9",
  thresholdRed = "#B73000",
  errorRed = "#FC5635",
  orange = "#FFA500",
  yellow = "#FFBF3F",
  yellowDarker = "#F3A200",
  yellowLighter = "#FFDF9E",
  yellowLightest = "#FFF5E1",
  green = "#67B346",
  greenDarker = "#539138",
  greenDarkest = "#539138",
  greenLighter = "#9ED188",
  greenLightest = "#DEF3D6",
  mgreen = "#008C8D",
  lblue = "#40B7D6",
  blue = "#1470CC",
  blueDarker = "#0F5499",
  blueDarkest = "#0B3C6E",
  blueLighter = "#AACEF3",
  blueLightest = "#DCEBFA",
  chartBlue1 = "#2196F3",
  chartBlue2 = "#3F51B5",
  tagBlue1 = "#E8EFFE",
  tagBlue2 = "#C6D9FC",
  tagBlue3 = "#6D94ED",
  mapPinBlue = "#00A9E0",
  mapRadiusBlue = "rgba(0, 0, 255, 0.2)",
  purple = "#586EDC",
  purpleDarker = "#3E52B3",
  purpleDarkest = "#31408C",
  purpleLighter = "#BFCAFF",
  purpleLightest = "#E9ECFA",
  purpleLightestTransparent = "rgba(88, 110, 220, 0.15)",
  purpleSpinnerTransparent = "rgba(102, 101, 246, 0.50)",
  chartPurple = "#673AB7",
  teal = "#45B6E6",
  tealLighter = "#AADDF3",
  tealLightestTransparent = "rgba(69, 182, 230, 0.15)",
  white = "#FFFFFF",
  whiteFaded90 = "rgba(255, 255, 255, 0.90)",
  whiteFaded80 = "rgba(255, 255, 255, 0.80)",
  whiteFaded75 = "rgba(255, 255, 255, 0.75)",
  whiteFaded50 = "rgba(255, 255, 255, 0.50)",
  whiteFaded05 = "rgba(255, 255, 255, 0.05)",
  transparent = "transparent",
}

export default SharedColors;
