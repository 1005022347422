import { I18n } from "@meraki/core/i18n";
import { launchSupportEmailUrl } from "@meraki/go/links";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { LoginResponseType, verifyIsLoginResponse } from "@meraki/shared/api";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useEmailOtpBackup } from "~/api/mutations/useEmailOtpBackup";
import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { RoundedButtonType } from "~/enterprise/components/RoundedButton";
import InfoModal from "~/go/components/InfoModal";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { appSelect, isWeb } from "~/lib/PlatformUtils";
import { TwoFactorAuthScreen as MagneticTwoFactorAuthScreen } from "~/migrationZone/enterprise/auth/screens/TwoFactorAuthScreen/TwoFactorAuthScreen";
import {
  currentUserState,
  errorMessageState,
  smsBackupState,
  smsPrimaryState,
  smsSecondaryState,
} from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import TouchableText from "~/shared/components/TouchableText";
import GeneralStatus from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import InputRow from "~/shared/rows/InputRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import { SMSTypes } from "~/shared/types/AuthTypes";

const RESEND_CONTENT = {
  title: I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE"),
  message: I18n.t("TWO_FACTOR_AUTH.RESEND.MESSAGE"),
};

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "TwoFactorAuth">;

const TwoFactorAuthScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();

  const actions = useActions();
  const emailOtpBackup = useEmailOtpBackup();

  const errorMessage = useAppSelector(errorMessageState);
  const userEmail = useAppSelector(currentUserState);
  const smsBackup = useAppSelector(smsBackupState);
  const smsPrimary = useAppSelector(smsPrimaryState);
  const smsSecondary = useAppSelector(smsSecondaryState);

  const [pinCode, setPinCode] = useState("");
  const [remember, setRemember] = useState(false);
  const [reqPending, setReqPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [backupEmailSent, setBackupEmailSent] = useState(false);
  const [backupCodeSent, setBackupCodeSent] = useState(false);

  const setRememberMe = (toggleValue: boolean) => {
    setRemember(toggleValue);
    actions.storeTFARememberMe(toggleValue);
  };

  const sendEmailBackupOTP = () => {
    emailOtpBackup.mutate();
    setShowModal(false);
    actions.setResetTFAFlow();
    setBackupEmailSent(true);
  };

  const sendCodeViaBackupSMS = () => {
    if (smsBackup && !backupCodeSent) {
      actions.resendSMSCode(SMSTypes.primary);
      setShowModal(false);
      setBackupCodeSent(true);
    } else {
      launchSupportEmailUrl({ showErrorWithContact: true });
    }
  };

  const renderResendSMS = () => {
    if (!smsPrimary) {
      return null;
    }
    const onPress = () =>
      showActionSheet(
        [
          I18n.t("TWO_FACTOR_AUTH.RESEND.PRIMARY", { smsPrimary }),
          ...(smsSecondary ? [I18n.t("TWO_FACTOR_AUTH.RESEND.SECONDARY", { smsSecondary })] : []),
        ],
        (buttonIndex: any) =>
          actions.resendSMSCode(buttonIndex === 0 ? SMSTypes.primary : SMSTypes.secondary),
        {
          title: RESEND_CONTENT.title,
          message: RESEND_CONTENT.message,
        },
      );

    // TODO: show some indication of whether or not this post succeeded
    return (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType={RoundedButtonType.secondary}
          buttonStyle={styles.resendCodeContainer}
          onPress={onPress}
        >
          {I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
        </RoundedButton>
      </View>
    );
  };

  const renderErrorMessage = () => {
    return appSelect({
      enterprise: <MkiText>{errorMessage || ""}</MkiText>,
      go: null,
    });
  };

  const renderLostTFAModal = () => {
    const shouldShowSMSButton = smsBackup && !backupCodeSent;
    const translationKeyPostfix = shouldShowSMSButton ? "_BACKUP" : "";
    return appSelect({
      enterprise: null,
      go: (
        <InfoModal visible={showModal} onExit={() => setShowModal(false)}>
          <View style={styles.lostTFAModal}>
            <MkiText textStyle="heading" screenStyles={styles.lostTFATitle}>
              {I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.TITLE")}
            </MkiText>
            <MkiText textStyle="subheading" screenStyles={styles.lostTFABody}>
              {I18n.t(`TWO_FACTOR_AUTH.LOST_TFA_MODAL.BODY${translationKeyPostfix}`, {
                lastTwoDigits: smsBackup,
              })}
            </MkiText>
            {shouldShowSMSButton && (
              <RoundedButton
                buttonType={RoundedButtonType.primary}
                onPress={sendCodeViaBackupSMS}
                testID="LOST_TFA_MODAL.PRIMARY"
              >
                {I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.BUTTON_BACKUP")}
              </RoundedButton>
            )}
            {!backupEmailSent && (
              <RoundedButton
                buttonType={
                  shouldShowSMSButton ? RoundedButtonType.secondary : RoundedButtonType.primary
                }
                onPress={sendEmailBackupOTP}
                testID="LOST_TFA_MODAL.SECONDARY"
              >
                {I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.EMAIL_BACKUP")}
              </RoundedButton>
            )}
          </View>
        </InfoModal>
      ),
    });
  };

  const renderLostTFAButton = () => {
    return appSelect({
      enterprise: null,
      go: (
        <TouchableText
          screenStyles={styles.lostTFAText}
          onPress={() => setShowModal(true)}
          text={I18n.t("TWO_FACTOR_AUTH.LOST_TFA_BUTTON")}
          testID="LOAST_TFA_BUTTON"
        />
      ),
    });
  };

  const pushOrgChoose = (response?: LoginResponseType) => {
    // if everything succeeded during login and there is no followup, then nothing is returned
    // from the loginUser call and we have nothing to handle post login
    if (!response) {
      return;
    }

    if (verifyIsLoginResponse(response)) {
      navigation.navigate("VerifyEmail", { response });
      return;
    }

    const { mode } = response;

    if (mode === "org_choose") {
      navigation.navigate("OrgChoose", { initialLogin: true });
    }
  };

  const submitTFA = () => {
    setReqPending(true);
    actions
      .submitTwoFactorAuth(pinCode, remember)
      .then((response) => {
        pushOrgChoose(response);
        setReqPending(false);
      })
      .catch((error) => {
        showAlert(I18n.t("ERROR"), error);
      })
      .finally(() => {
        setReqPending(false);
      });
  };

  const createMessage = () => {
    if (smsPrimary || backupCodeSent) {
      const smsNumber = smsPrimary || smsBackup;
      return I18n.t("TWO_FACTOR_AUTH.SMS", { smsNumber });
    } else if (backupEmailSent) {
      return I18n.t("TWO_FACTOR_AUTH.EMAIL");
    } else {
      return I18n.t("TWO_FACTOR_AUTH.APP");
    }
  };

  const message = createMessage();

  return (
    <FullScreenContainerView>
      {renderErrorMessage()}
      {backupEmailSent && __MERAKI_GO__ && (
        <BannerAlert
          testID="INPUT_MODAL.ALERT"
          alertType={GeneralStatus.good}
          alertText={I18n.t("TWO_FACTOR_AUTH.EMAIL_SENT_ALERT", { userEmail })}
          screenStyles={styles.emailConfirmation}
        />
      )}
      <MkiText screenStyles={styles.instructionsText}>{message}</MkiText>
      <InputRow
        value={pinCode}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType={KEYBOARD_TYPE.numeric}
        onChangeText={setPinCode}
      >
        {I18n.t("TWO_FACTOR_AUTH.CODE")}
      </InputRow>
      {isWeb() && (
        <SwitchRow onValueChange={setRememberMe} value={remember}>
          {I18n.t("TWO_FACTOR_AUTH.REMEMBER")}
        </SwitchRow>
      )}
      <RoundedButton
        buttonType={RoundedButtonType.primary}
        buttonStyle={appSelect({
          enterprise: styles.verifyButtonEnterpriseContainer,
          go: styles.verifyButtonGoContainer,
        })}
        onPress={submitTFA}
      >
        {I18n.t("TWO_FACTOR_AUTH.VERIFY")}
      </RoundedButton>
      {renderResendSMS()}
      {renderLostTFAButton()}
      {renderLostTFAModal()}
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  instructionsText: {
    textAlign: "left",
    width: "80%",
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.large,
  },
  emailConfirmation: {
    margin: SPACING.default,
    width: undefined,
  },
  buttonContainer: {
    marginTop: SPACING.small,
  },
  verifyButtonEnterpriseContainer: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.default,
  },
  verifyButtonGoContainer: {
    marginHorizontal: SPACING.default,
  },
  resendCodeContainer: {
    marginHorizontal: SPACING.default,
  },
  lostTFAModal: {
    margin: SPACING.default,
  },
  lostTFAText: {
    paddingTop: SPACING.default,
    marginLeft: SPACING.default,
  },
  lostTFATitle: {
    marginBottom: SPACING.large,
  },
  lostTFABody: {
    color: MkiColors.formFieldLabel,
    padding: SPACING.default,
  },
});

const MagnetizedTwoFactorAuthScreen = withMagneticReplacementAdapter(
  TwoFactorAuthScreen,
  MagneticTwoFactorAuthScreen,
);

export default MagnetizedTwoFactorAuthScreen;
