import { Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken, mapColorToken, useMagneticTheme } from "@meraki/magnetic/themes";
import SvgChart from "@wuba/react-native-echarts/svgChart";
import Color from "color";
import * as echarts from "echarts/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

export type GaugeDatum = {
  value: number | string;
  label: string;
};

type ChartSize = "sm" | "md" | "lg";
interface GaugeProps {
  testID?: string;
  size?: ChartSize;
  data: GaugeDatum[];
  splitNumber?: number;
  loading?: boolean;
  detailFormatter?: (value: object | string | number) => string;
  min?: ((value: { min: number; max: number }) => number) | number;
  max?: ((value: { min: number; max: number }) => number) | number;
}

const defaultLineColor: ColorToken[] = [
  "accent.a.base",
  "accent.b.base",
  "accent.c.base",
  "accent.d.base",
  "accent.e.base",
  "accent.f.base",
  "accent.g.base",
  "accent.h.base",
  "accent.i.base",
  "accent.j.base",
];

const getAspectRatio = (width: number, size: ChartSize) => {
  switch (size) {
    case "sm":
      return width >= 480 ? 0.15 : 0.3;
    case "md":
      return width >= 480 ? 0.3 : 0.6;
    case "lg":
      return width >= 480 ? 0.5 : 0.8;
  }
};

const CHART_COLORS: Record<string, ColorToken> = {
  axis: "default.border.strong.base",
} as const;

export function Gauge({
  testID,
  size = "md",
  data,
  splitNumber = 12,
  loading = false,
  detailFormatter,
  min = 0,
  max = 100,
}: GaugeProps) {
  const theme = useMagneticTheme();
  const chartRef = useRef(null);
  const [chartInstance, setChart] = useState<echarts.EChartsType | null>(null);

  const [width, setWidth] = useState<number | null>(null);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  // This effect is responsible for the basic setup of the chart instance.
  useEffect(() => {
    if (!width) return;

    const chart = echarts.init(chartRef.current, "light", {
      renderer: "svg",
      width: !!width && width > 0 ? width : undefined,
      height: !!width && width > 0 ? width * getAspectRatio(width, size) : undefined,
    });

    setChart(chart);

    return () => chart.dispose();
  }, [size, width]);

  // This effect is responsible for setting the data related options
  useEffect(() => {
    chartInstance?.setOption({
      series: {
        min,
        max,
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        splitNumber,
        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "65%",
          width: 10,
          offsetCenter: [0, "5%"],
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 5,
          },
        },
        axisTick: {
          splitNumber: 2,
          lineStyle: {
            width: 2,
            color: mapColorToken(CHART_COLORS.axis, theme),
          },
        },
        splitLine: {
          length: 4,
          lineStyle: {
            width: 3,
            color: mapColorToken(CHART_COLORS.axis, theme),
          },
        },
        axisLabel: {
          distance: 12,
          color: mapColorToken(CHART_COLORS.axis, theme),
          fontSize: 8,
        },
        progress: {
          show: true,
          overlap: true,
          roundCap: true,
        },
        title: {
          fontSize: 12,
        },
        detail: {
          fontSize: 10,
          formatter: detailFormatter,
        },
        data: data.map((datum, i) => {
          const offset = 30 * (i + 1);

          return {
            value: datum.value,
            name: datum.label,
            title: {
              offsetCenter: ["0%", `${offset}%`],
            },
            detail: {
              offsetCenter: ["0%", `${offset + 10}%`],
            },
            itemStyle: {
              color: mapColorToken(defaultLineColor[i], theme),
            },
          };
        }),
      },
    });
  }, [chartInstance, theme, data, detailFormatter, min, max, splitNumber]);

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <SvgChart ref={chartRef} useRNGH />
      {loading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Color(theme.color.default.bg.weak.base).alpha(0.8).hexa(),
          }}
        >
          <Loader.Spinner />
        </View>
      )}
    </Box>
  );
}
