import { LinearGradientProps, StopProps } from "react-native-svg";

export interface CustomGradientProps extends Pick<LinearGradientProps, "x1" | "x2" | "y1" | "y2"> {
  colorLeft: StopProps["stopColor"];
  colorRight: StopProps["stopColor"];
}

export interface FillProps {
  renderGradient?: ({ id }: { id: string }) => JSX.Element;
  customGradient?: CustomGradientProps;
  gradientId: string;
}

export const DEFAULT_ICON_SIZES = {
  xxs: 16,
  xs: 20,
  s: 24,
  sm: 28,
  m: 30,
  l: 40,
  xl: 80,
};

export const DEFAULT_ICON_SIZES_KEYS = Object.keys(DEFAULT_ICON_SIZES);

export type IconSizeTypes = keyof typeof DEFAULT_ICON_SIZES;
