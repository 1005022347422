import {
  ORG_BY_ID_GET_SUCCESS,
  ORG_GET_SUCCESS,
  SET_ORGANIZATION_NAME_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import Organization from "~/api/models/Organization";

const initialState: Organization[] = [];

const orgs = (state = initialState, action: any) => {
  const { type, response } = action;

  switch (type) {
    case ORG_GET_SUCCESS: {
      const existingIds = new Set(state.map((org) => org.id));
      return [...state, ...response.filter((org: Organization) => !existingIds.has(org.id))];
    }
    case ORG_BY_ID_GET_SUCCESS: {
      return [...state.filter((org) => org.id !== response.id), response];
    }
    case ORG_BY_ID_GET_SUCCESS: {
      return [...state.filter((org) => org.id !== response.id), response];
    }
    case SET_ORGANIZATION_NAME_SUCCESS: {
      const { id } = response;
      const newOrgsArray = state.slice();
      let orgIndex = newOrgsArray.findIndex((org) => org.id === id);

      if (orgIndex === -1) {
        orgIndex = 0;
      }

      newOrgsArray[orgIndex] = {
        ...newOrgsArray[orgIndex],
        ...response,
      };

      return newOrgsArray;
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default orgs;
