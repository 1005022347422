import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import { showSaveWarning } from "~/lib/AlertUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import PortVlanConfig from "~/shared/components/ports/PortVlanConfig";
import useEditAppliancePort from "~/shared/hooks/appliances/useEditAppliancePort";
import { CancelButton, DoneButton } from "~/shared/navigation/Buttons";

import { HardwareStackPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "AppliancePortsVlan">;

const AppliancePortsVlanScreen = ({ appliancePort, portIds, onSave }: Props) => {
  const [stagedAppliancePort, _, updateStagedAppliancePortByKey, hasDiff] =
    useEditAppliancePort(appliancePort);
  const isSingleAppliancePort = portIds.length === 1;
  const description = isSingleAppliancePort
    ? I18n.t("VLAN_SWITCH_PORTS.HEADER.DESCRIPTION.SINGLE", { port: portIds[0] })
    : I18n.t("VLAN_SWITCH_PORTS.HEADER.DESCRIPTION.MANY", {
        list_of_ports: portIds.join(", "),
      });

  const navigation = useNavigation<Props["navigation"]>();

  useLayoutEffect(() => {
    const doneCallback = () => {
      onSave(stagedAppliancePort);
      navigation.goBack();
    };

    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (hasDiff) {
              showSaveWarning(doneCallback, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <DoneButton onPress={doneCallback} disabled={!hasDiff} />,
    });
  }, [hasDiff, navigation, onSave, portIds, stagedAppliancePort]);

  return (
    <>
      <DefaultHeader
        title={I18n.t("VLAN_SWITCH_PORTS.HEADER.TITLE")}
        description={description}
        style={styles.header}
      />
      <FullScreenContainerView withScroll screenStyles={styles.contentContainer}>
        <PortVlanConfig
          port={stagedAppliancePort}
          updatePortByKey={updateStagedAppliancePortByKey}
        />
      </FullScreenContainerView>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingBottom: SPACING.default,
  },
  contentContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
  },
});

export default AppliancePortsVlanScreen;
