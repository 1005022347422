import { I18n } from "@meraki/core/i18n";

import { ConnectionType } from "~/shared/types/Device";

export const Scopes = {
  filterOptions: "SORT_FILTER.FILTER_OPTIONS",
  clientsListFilters: "CLIENTS_LIST.FILTER_VALUES",
} as const;
type Scope = (typeof Scopes)[keyof typeof Scopes];

const TRANSLATION_MAPPING = {
  [Scopes.filterOptions]: {
    status: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
    isOnline: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
    productType: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.PRODUCT_TYPE"),
    clientType: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.CLIENT_TYPE"),
    connectionType: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.CONNECTION_TYPE"),
    severity: () => I18n.t("SORT_FILTER.FILTER_OPTIONS.SEVERITY"),
  },
  [Scopes.clientsListFilters]: {
    networkOnly: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.NETWORK_CLIENT"),
    systemsManager: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.SM_DEVICE"),
    online: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.ONLINE"),
    offline: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.OFFLINE"),
    [ConnectionType.none]: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.NONE"),
    [ConnectionType.vpn]: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.VPN"),
    [ConnectionType.wired]: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.WIRED"),
    [ConnectionType.wireless]: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.WIRELESS"),
    isHighUsage: () => I18n.t("CLIENTS_LIST.FILTER_VALUES.HIGH_USAGE"),
  },
};

export const APP_KEY = __MERAKI_GO__ ? "GO" : "ENTERPRISE";

export const translationHelper = (scope: Scope, key: string) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const term = TRANSLATION_MAPPING[scope][key];
  return term?.() ?? "";
};
