import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import AddHardwareScreen from "../screens/onboardingFullstack/AddHardwareScreen";
import QRLocationScreen from "../screens/onboardingFullstack/QRLocationScreen";
import ScannedHardwareScreen from "../screens/onboardingFullstack/ScannedHardwareScreen";
import { AddHardwareScreensPropMap } from "./Types";

const ForwardedAddHardwareScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<AddHardwareScreensPropMap, "AddHardware">
  >()(AddHardwareScreen);
const ForwardedQRLocationScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<AddHardwareScreensPropMap, "QRLocation">
  >()(QRLocationScreen);
const ForwardedScannedHardwareScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<AddHardwareScreensPropMap, "ScannedHardware">
  >()(ScannedHardwareScreen);

export function addHardwareScreens<T extends AddHardwareScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="AddHardware"
        component={ForwardedAddHardwareScreen}
        options={({ route }) => {
          return {
            presentation: route.params?.presentedModally ? "fullScreenModal" : undefined,
            headerTitle: I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.TITLE"),
          };
        }}
      />
      <Stack.Screen
        name="QRLocation"
        component={ForwardedQRLocationScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.TITLE"),
        }}
      />
      <Stack.Screen
        name="ScannedHardware"
        component={ForwardedScannedHardwareScreen}
        options={{ headerTitle: I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.TITLE") }}
      />
    </>
  );
}
