import { I18n } from "@meraki/core/i18n";
import { ClientList, ClientType, Device, FailedConnection, Ssid } from "@meraki/shared/api";
import { DisplayableFailedConnection } from "@meraki/shared/navigation-type";
import { get } from "lodash";

const unknownText = I18n.t("UNKNOWN");

export const associationStages = {
  assoc: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.ASSOC"),
  auth: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.AUTH"),
  dhcp: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.DHCP"),
  dns: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.DNS"),
};
export interface ClientByMac {
  [mac: string]: ClientType;
}
export interface DeviceBySerial {
  [serial: string]: Device;
}

export const formatClientsByMac = (data: ClientType[] | ClientList) =>
  Object.entries(data).reduce((prev, [_, client]) => {
    if (!prev[client.mac]) {
      prev[client.mac] = {
        ...client,
      };
    }
    return prev;
  }, {} as ClientByMac);

export const formatDevicesBySerial = (data: Device[]) =>
  Object.entries(data).reduce((prev, [_, device]) => {
    if (!prev[device.serial]) {
      prev[device.serial] = {
        ...device,
      };
    }
    return prev;
  }, {} as DeviceBySerial);

export const formatFailedConnections = (
  failedConnectionData?: FailedConnection[],
  clientData?: ClientByMac,
  deviceData?: DeviceBySerial,
  ssidData?: Ssid[],
): DisplayableFailedConnection[] =>
  failedConnectionData?.map((failedConnection) => {
    const client = clientData?.[failedConnection.clientMac];
    const device = deviceData?.[failedConnection.serial];
    const ssid = ssidData?.find((ssid) => ssid.number === failedConnection.ssidNumber);
    const clientId = client?.id ?? "";
    const clientName = client?.description || failedConnection.clientMac;
    const ssidName = ssid?.name ?? unknownText;
    const deviceName = client?.recentDeviceName ?? device?.name ?? device?.mac ?? unknownText;
    const deviceMac = client?.recentDeviceMac ?? device?.mac ?? unknownText;
    const fullFailureStep = get(associationStages, failedConnection.failureStep);

    return {
      ...failedConnection,
      failureStep: fullFailureStep,
      clientName,
      ssidName,
      apName: deviceName,
      apMac: deviceMac,
      id: clientId,
    };
  }) ?? [];
