import { I18n } from "@meraki/core/i18n";
import {
  queryClient,
  useGetWirelessSettings,
  useSetIPv6Bridging,
  useSetMeshing,
  useSetStealthMode,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";

import { showAlert } from "~/lib/AlertUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import SwitchRow, { SwitchRowProps } from "~/shared/rows/SwitchRow";

export enum ToggleTypes {
  meshing = "meshing",
  ipv6Bridging = "ipv6",
  stealthMode = "stealth",
}

export interface WirelessSettingsRowData {
  label: string;
  switch: SwitchRowProps;
}

export function WirelessSettingsScreen() {
  const networkId = useCurrentNetworkId();
  const {
    data: wirelessSettings,
    isLoading,
    isFetching,
  } = useGetWirelessSettings(
    { networkId },
    {
      enabled: Boolean(networkId),
    },
  );
  const setStealthMode = useSetStealthMode();
  const setMeshing = useSetMeshing();
  const setIPv6Bridging = useSetIPv6Bridging();

  const handleError = (errors: string[]) => {
    showAlert(I18n.t("ERROR"), errors.join(","));
  };
  const handleOnSuccess = () => {
    queryClient.invalidateQueries({ queryKey: useGetWirelessSettings.queryKeyRoot });
  };
  const getToggles = () => {
    if (!wirelessSettings) {
      return [];
    }
    const { ledLightsOn, meshingEnabled, ipv6BridgeEnabled } = wirelessSettings;
    const stealthModeEnabled = !ledLightsOn;
    const data = [
      {
        label: I18n.t("WIRELESS_SETTINGS.TOGGLE_STEALTH_MODE.TOGGLE_TITLE"),
        switch: {
          subtitle: I18n.t("WIRELESS_SETTINGS.TOGGLE_STEALTH_MODE.TOGGLE_SUBTITLE"),
          value: stealthModeEnabled,
          onValueChange: (isEnabled: boolean) =>
            setStealthMode.mutate(
              { networkId, isEnabled },
              { onSuccess: handleOnSuccess, onError: (error) => handleError(error.errors) },
            ),
          testID: "TOGGLE_STEALTH_MODE",
        },
      },
      {
        label: I18n.t("WIRELESS_SETTINGS.TOGGLE_MESHING.TOGGLE_TITLE"),
        switch: {
          subtitle: I18n.t("WIRELESS_SETTINGS.TOGGLE_MESHING.TOGGLE_SUBTITLE"),
          value: meshingEnabled,
          onValueChange: (isEnabled: boolean) =>
            setMeshing.mutate(
              { networkId, isEnabled },
              { onSuccess: handleOnSuccess, onError: (error) => handleError(error.errors) },
            ),
          testID: "TOGGLE_MESHING",
        },
      },
      {
        label: I18n.t("WIRELESS_SETTINGS.TOGGLE_IPV6_BRIDGE_MODE.TOGGLE_TITLE"),
        switch: {
          subtitle: I18n.t("WIRELESS_SETTINGS.TOGGLE_IPV6_BRIDGE_MODE.TOGGLE_SUBTITLE"),
          value: ipv6BridgeEnabled,
          onValueChange: (isEnabled: boolean) =>
            setIPv6Bridging.mutate(
              { networkId, isEnabled },
              { onSuccess: handleOnSuccess, onError: (error) => handleError(error.errors) },
            ),
          testID: "TOGGLE_IPV6_BRIDGE_MODE",
        },
      },
    ];
    return data;
  };

  const renderRow = (rowData: WirelessSettingsRowData) => {
    const { subtitle, value, onValueChange, testID } = rowData.switch;
    return (
      <SwitchRow subtitle={subtitle} value={value} onValueChange={onValueChange} testID={testID}>
        {rowData.label}
      </SwitchRow>
    );
  };

  return (
    <>
      <FullScreenContainerView withScroll>
        <MkiTable data={getToggles()} renderRow={renderRow} testID="WIRELESS_SETTINGS.TABLE" />
      </FullScreenContainerView>
      <LoadingSpinner
        visible={
          setStealthMode.isLoading ||
          setMeshing.isLoading ||
          setIPv6Bridging.isLoading ||
          isLoading ||
          isFetching
        }
      />
    </>
  );
}

export default WirelessSettingsScreen;
