import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { List, Text, Toggle } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import {
  PortVlanTypes,
  queryClient,
  useAllAppliancePorts,
  useUpdateAppliancePort,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import Toast from "react-native-simple-toast";

import { useAppliancePortDetails } from "../AppliancePortDetailsContext";

const showToast = (message: string) => Toast.showWithGravity(message, Toast.SHORT, Toast.BOTTOM);

export const AppliancePortSettingsTab = () => {
  const { appliancePort, device } = useAppliancePortDetails();
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  const networkId = useCurrentNetworkId();
  const portId = appliancePort?.number?.toString();

  const updateAppliancePort = useUpdateAppliancePort();

  const [stagedEnabled, setStagedEnabled] = useState<boolean>(!!appliancePort?.enabled);
  const updateToggle = (enabled: boolean) => {
    if (!portId) {
      showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
      return;
    }

    setStagedEnabled(enabled);

    updateAppliancePort.mutate(
      {
        networkId,
        portId,
        appliancePort: { enabled },
      },
      {
        onError: () => {
          setStagedEnabled(stagedEnabled);
          showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useAllAppliancePorts.queryKeyRoot });
          showToast(
            enabled
              ? I18n.t("PORTS.SETTINGS.ENABLED.ENABLED")
              : I18n.t("PORTS.SETTINGS.ENABLED.DISABLED"),
          );
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding testID="SETTINGS_TAB">
      <List>
        <List.Item
          title={
            stagedEnabled
              ? I18n.t("PORTS.SETTINGS.ENABLED.ENABLED")
              : I18n.t("PORTS.SETTINGS.ENABLED.DISABLED")
          }
          rightAccessory={
            <Toggle
              checked={!!stagedEnabled}
              onValueChange={updateToggle}
              testID="SETTINGS_TAB.ENABLED_TOGGLE"
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.TITLE")}
          leftAccessory={<Icon name="ArrowsLeftRight" />}
          rightAccessory={
            appliancePort && (
              <Text size="p1" color="light">
                {appliancePort.type === PortVlanTypes.trunk
                  ? I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.TRUNK")
                  : I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.ACCESS")}
              </Text>
            )
          }
          onPress={() =>
            navigation.navigate("VLANConfiguration", {
              appliancePortIds: appliancePort ? [appliancePort.number] : [],
              serial: device?.serial,
            })
          }
          testID="SETTINGS_TAB.VLAN_CONFIG"
        />
      </List>
    </Screen>
  );
};
