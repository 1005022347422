import { Status } from "@meraki/shared/api";

import I18n from "~/i18n/i18n";
import { getDeviceStatus, getIsMeshAccessPoint } from "~/lib/DeviceUtils";
import { DeviceStatus, StatusType } from "~/shared/constants/Status";
import Device from "~/shared/types/Device";

export function determineHardwareDetails(device: Device) {
  const deviceStatus = getDeviceStatus(device) || DeviceStatus.offline;
  let description = "";

  if (deviceStatus === DeviceStatus.alerting) {
    description = I18n.t("ALERTING");
  } else if (deviceStatus === DeviceStatus.online) {
    description = I18n.t("ONLINE");
    if (getIsMeshAccessPoint(device)) {
      description = I18n.t("MESHED_GR_STATUS.AP_DESCRIPTION");
    }
  } else if (deviceStatus === DeviceStatus.offline) {
    description = I18n.t("OFFLINE");
  }

  return { description, deviceStatus };
}

export function determineHardwareDetailsByStatus(status: StatusType, device: Device) {
  const deviceStatus = getDeviceStatus(device) || status || DeviceStatus.offline;
  let description = "";

  if (deviceStatus === DeviceStatus.alerting) {
    description = I18n.t("ALERTING");
  } else if (deviceStatus === DeviceStatus.online) {
    description = I18n.t("ONLINE");
    if (getIsMeshAccessPoint(device)) {
      return {
        description: I18n.t("MESHED_GR_STATUS.AP_DESCRIPTION"),
        deviceStatus: DeviceStatus.online,
      };
    }
  } else if (deviceStatus === DeviceStatus.offline || deviceStatus === DeviceStatus.dormant) {
    description = I18n.t("OFFLINE");
  }

  return { description, deviceStatus };
}

export function getHardwareDescription(device: Device, status?: Status) {
  let description = "";
  if (status === "alerting") {
    description = I18n.t("DEVICE_STATUS.ALERTING");
  } else if (status === "online") {
    description = I18n.t("DEVICE_STATUS.ONLINE");
    if (getIsMeshAccessPoint(device)) {
      return {
        description: I18n.t("MESHED_GR_STATUS.AP_DESCRIPTION"),
        status,
      };
    }
  } else if (status === "offline" || !status) {
    description = I18n.t("DEVICE_STATUS.OFFLINE");
  } else if (status === "dormant") {
    description = I18n.t("DEVICE_STATUS.DORMANT");
  }

  return { description, status };
}
