import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import _ from "lodash";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface APBannerStoreState {
  untestedAPs: string[]; //list of serials of untested APs
  removeAllUntestedAps: () => void;
  addUntestedAp: (serial: string) => void;
  removeUntestedAp: (serial: string) => void;
}

export const useAPBanner = create<APBannerStoreState>()(
  persist(
    (set) => ({
      untestedAPs: [],
      removeAllUntestedAps: () =>
        set((_) => ({
          untestedAPs: [],
        })),
      addUntestedAp: (serial) =>
        set((state) => ({
          ...state.untestedAPs,
          untestedAPs: [...state.untestedAPs, serial],
        })),
      removeUntestedAp: (serial) =>
        set((state) => ({
          ...state.untestedAPs,
          untestedAPs: _.remove(state.untestedAPs, (ap) => ap !== serial),
        })),
    }),
    {
      name: "APBannerStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);
