import {
  ADD_SUPPORT_CASE_COMMENT_FAILURE,
  ADD_SUPPORT_CASE_COMMENT_REQUEST,
  ADD_SUPPORT_CASE_COMMENT_SUCCESS,
  CREATE_FEEDBACK_CASE_FAILURE,
  CREATE_FEEDBACK_CASE_REQUEST,
  CREATE_FEEDBACK_CASE_SUCCESS,
  CREATE_SUPPORT_CASE_FAILURE,
  CREATE_SUPPORT_CASE_REQUEST,
  CREATE_SUPPORT_CASE_SUCCESS,
  GET_SUPPORT_CASE_INFO_FAILURE,
  GET_SUPPORT_CASE_INFO_REQUEST,
  GET_SUPPORT_CASE_INFO_SUCCESS,
  GET_SUPPORT_CASES_FAILURE,
  GET_SUPPORT_CASES_REQUEST,
  GET_SUPPORT_CASES_SUCCESS,
  UPDATE_SUPPORT_CASE_FAILURE,
  UPDATE_SUPPORT_CASE_REQUEST,
  UPDATE_SUPPORT_CASE_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { DETOX } from "~/env";
import { ApiResponseAction } from "~/middleware/api";
import {
  currentUserState,
  encryptedNetworkIdSelector,
  getCurrentNetwork,
  isCameraOnlyAdmin,
} from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const createSupportCase =
  (supportCase: any): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          CREATE_SUPPORT_CASE_REQUEST,
          CREATE_SUPPORT_CASE_SUCCESS,
          CREATE_SUPPORT_CASE_FAILURE,
        ],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/support/new_case`,
        config: {
          method: Method.post,
          body: JSON.stringify(supportCase),
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const getSupportCases =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [GET_SUPPORT_CASES_REQUEST, GET_SUPPORT_CASES_SUCCESS, GET_SUPPORT_CASES_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/support/case_list`,
        config: {
          method: Method.get,
        },
        meta: {
          userEmail: currentUserState(getState()),
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const closeSupportCase =
  (userClosed: boolean, caseNumber: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          UPDATE_SUPPORT_CASE_REQUEST,
          UPDATE_SUPPORT_CASE_SUCCESS,
          UPDATE_SUPPORT_CASE_FAILURE,
        ],
        endpoint: `/n/${encryptedNetworkIdSelector(
          getState(),
        )}/manage/support/update_case?case_number=${caseNumber}&user_closed=${userClosed}`,
        config: {
          method: Method.post,
        },
        meta: {
          userClosed,
          caseNumber,
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const addSupportCaseComment =
  (commentText: string, caseNumber: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          ADD_SUPPORT_CASE_COMMENT_REQUEST,
          ADD_SUPPORT_CASE_COMMENT_SUCCESS,
          ADD_SUPPORT_CASE_COMMENT_FAILURE,
        ],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/support/add_case_comment`,
        config: {
          method: Method.post,
          body: JSON.stringify({
            comment_text: commentText,
            case_number: caseNumber,
          }),
        },
        meta: {
          commentText,
          caseNumber,
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const getSupportCaseInfo =
  (caseNumber: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          GET_SUPPORT_CASE_INFO_REQUEST,
          GET_SUPPORT_CASE_INFO_SUCCESS,
          GET_SUPPORT_CASE_INFO_FAILURE,
        ],
        endpoint: `/n/${encryptedNetworkIdSelector(
          getState(),
        )}/manage/support/case_info?case_number=${caseNumber}`,
        config: {
          method: Method.get,
        },
        meta: {
          caseNumber,
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const createFeedbackCase =
  (feedbackCase: any): AppThunk<Promise<void | ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    if (DETOX) {
      return Promise.resolve();
    }

    const localeEid = encryptedNetworkIdSelector(getState());
    const isCoA = isCameraOnlyAdmin(getState());
    const cameraEid = getCurrentNetwork(getState())?.ngIds?.camera?.ngEid;

    // frontend fix for DM-5785 and MV-19722: camera-only admins who have their permissions
    // set to "live video" are unable to send wishes without this fix because hitting the endpoint
    // with the locale id fails. This is a workaround that hits the same endpoint using the camera
    // node group eid. With this fix they can now send wishes as expected. The ticket MV-19722
    // captures work to fix this in the backend so that don't need a special edge case here.
    const eid = isCoA ? cameraEid : localeEid;

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          CREATE_FEEDBACK_CASE_REQUEST,
          CREATE_FEEDBACK_CASE_SUCCESS,
          CREATE_FEEDBACK_CASE_FAILURE,
        ],
        endpoint: `/n/${eid}/manage/support/wish`,
        config: {
          method: Method.post,
          body: JSON.stringify({ page_name: "underdog_app", ...feedbackCase }),
        },
      }),
    );
  };
