import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface NFOsRequest {
  organizationId: string | undefined;
}

const NFOSchema = z.object({
  enabled: z.boolean(),
  feature: z.string(),
});

export const NFOsSchema = z.object({
  features: z.array(NFOSchema),
});

export type NFOs = z.infer<typeof NFOsSchema>;

const buildUrl = ({ organizationId }: NFOsRequest) => {
  return `/testbed/api/v0/organizations/${organizationId}/nfos/org_nfos`;
};

export const fetchNFOs = ({ organizationId }: NFOsRequest) => {
  return request(NFOsSchema, "GET", buildUrl({ organizationId }));
};

export const useNFOs = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: NFOsRequest) => fetchNFOs(parameters),
});
