import {
  CAMERA_CONFIG_GET_FAILURE,
  CAMERA_CONFIG_GET_REQUEST,
  CAMERA_CONFIG_GET_SUCCESS,
  CAMERA_CONFIG_UPDATE_FAILURE,
  CAMERA_CONFIG_UPDATE_REQUEST,
  CAMERA_CONFIG_UPDATE_SUCCESS,
  CAMERA_PLAYER_RELEASED,
  CAMERA_WIRELESS_PROFILE_CREATE_FAILURE,
  CAMERA_WIRELESS_PROFILE_CREATE_REQUEST,
  CAMERA_WIRELESS_PROFILE_CREATE_SUCCESS,
  CAMERA_WIRELESS_PROFILE_UPDATE_FAILURE,
  CAMERA_WIRELESS_PROFILE_UPDATE_REQUEST,
  CAMERA_WIRELESS_PROFILE_UPDATE_SUCCESS,
  CAMERA_WIRELESS_PROFILES_GET_FAILURE,
  CAMERA_WIRELESS_PROFILES_GET_REQUEST,
  CAMERA_WIRELESS_PROFILES_GET_SUCCESS,
  CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE,
  CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST,
  CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS,
  DEWARPED_TOGGLED,
  FETCH_ONBOARDING_STATUS_FAILURE,
  FETCH_ONBOARDING_STATUS_REQUEST,
  FETCH_ONBOARDING_STATUS_SUCCESS,
  GENERATE_SNAPSHOT_FAILURE,
  GENERATE_SNAPSHOT_REQUEST,
  GENERATE_SNAPSHOT_SUCCESS,
  MOTION_EVENTS_FAILURE,
  MOTION_EVENTS_REQUEST,
  MOTION_EVENTS_SUCCESS,
  MOTION_SEARCH_FILTER_SET,
  PEOPLE_DETECTION_SET,
  PLAY_HISTORICAL_VIDEO,
  PLAY_LIVE_VIDEO,
  SET_CLOCK_START_TS,
  SET_CURRENT_VIDEO_SETTING_PAGE,
  SET_INITIAL_DELTA,
  SET_MOTION_EVENTS_LOADING_STATUS,
  SET_MOTION_SEARCH_INTERVAL,
  SET_MOTION_SEARCH_PAGE,
  SET_OFFSET,
  SET_ONBOARDING_STARTED_FAILURE,
  SET_ONBOARDING_STARTED_REQUEST,
  SET_ONBOARDING_STARTED_SUCCESS,
  SET_PLAYBACK_RATE,
  SET_PLAYBACK_STATUS,
  SET_REGION_OF_INTEREST,
  SET_SELECTED_MOTION_EVENT_TIMESTAMP,
  SET_VIDEO_IS_LOADING,
  SET_VIDEO_MODE,
  SET_VIDEO_SETTING_MENU_VISIBILITY,
  UPDATE_OFFSET,
  VIDEO_CHANNEL_FAILURE,
  VIDEO_CHANNEL_REQUEST,
  VIDEO_CHANNEL_SUCCESS,
  VIDEO_MUTE_TOGGLED,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { GeneratedSnapshotResponse } from "~/api/models/Camera";
import { WirelessOnboardingStatusResponse } from "~/api/models/WirelessOnboardingStatusResponse";
import { Device } from "~/api/schemas/Device";
import { FULL_REGION_OF_INTEREST } from "~/enterprise/constants/Camera";
import {
  PlaybackRateId,
  PointData,
  RoiArr,
  SettingPageId,
  VideoMode,
  WirelessProfilesSet,
} from "~/enterprise/types/Cameras";
import { ApiAction, ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkState,
  getCameraNodeGroupEid,
  getCurrentOrganization,
  getTimezoneOffsetMS,
} from "~/selectors";
import {
  SSIDAuthMode,
  SSIDEncryptionMode,
  WirelessProfile,
  WPAWirelessProfileCreate,
  WPAWirelessProfileUpdate,
} from "~/shared/types/Models";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const fetchVideoChannel = (encryptedNetworkId: string, deviceId: string): ApiAction => ({
  [CALL_API]: {
    types: [VIDEO_CHANNEL_REQUEST, VIDEO_CHANNEL_SUCCESS, VIDEO_CHANNEL_FAILURE],
    endpoint: `/n/${encryptedNetworkId}/manage/video/video_channel`,
    config: {
      method: Method.get,
      queryParams: {
        node_id: deviceId,
      },
    },
  },
});

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const fetchCameraConfig = (encryptedNetworkId: string, deviceId: string): ApiAction => ({
  [CALL_API]: {
    types: [CAMERA_CONFIG_GET_REQUEST, CAMERA_CONFIG_GET_SUCCESS, CAMERA_CONFIG_GET_FAILURE],
    endpoint: `/n/${encryptedNetworkId}/manage/cameras/camera_config?node_id=${deviceId}`,
    config: { method: Method.get },
  },
});

export const fetchWirelessProfiles = (): AppThunk<Promise<ApiResponseAction<any> | void>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch({
        [CALL_API]: {
          types: [
            CAMERA_WIRELESS_PROFILES_GET_REQUEST,
            CAMERA_WIRELESS_PROFILES_GET_SUCCESS,
            CAMERA_WIRELESS_PROFILES_GET_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/camera/wirelessProfiles`,
          config: {
            method: Method.get,
          },
        },
      });
    }
    return Promise.resolve();
  };
};

export const assignCameraWirelessProfilesInDashboard = (
  cameraSerial: string,
  wirelessProfiles: WirelessProfile[],
): AppThunk<Promise<ApiResponseAction<any>>> => {
  const primaryProfileId = wirelessProfiles[0]?.id;
  const secondaryProfileId = wirelessProfiles[1]?.id ?? null;
  const wirelessProfileSet: WirelessProfilesSet = {
    ids: { primary: primaryProfileId, secondary: secondaryProfileId },
  };

  return (dispatch) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST,
          CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS,
          CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE,
        ],
        endpoint: `/api/v1/devices/${cameraSerial}/camera/wirelessProfiles`,
        config: {
          method: Method.put,
          body: JSON.stringify({ ...wirelessProfileSet }),
        },
      }),
    );
  };
};

type SharedSSIDFields = {
  number?: string;
  name: string;
};

type PskSSIDFields = SharedSSIDFields & {
  authMode: SSIDAuthMode.psk;
  encryptionMode: SSIDEncryptionMode.wpa;
  psk: string;
};

type EnterpriseSSIDFields = SharedSSIDFields & {
  authMode: SSIDAuthMode.radius802;
  encryptionMode: SSIDEncryptionMode.eap;
};

type Identity = { username: string; password: string };

type CreateWirelessProfileRequestBody = {
  name: string;
} & ({ ssid: PskSSIDFields } | { ssid: EnterpriseSSIDFields; identity: Identity });

const buildCreateWirelessProfileRequestBody = (
  profile: WPAWirelessProfileCreate,
): CreateWirelessProfileRequestBody =>
  "psk" in profile.auth
    ? {
        name: profile.name,
        ssid: {
          name: profile.ssidName,
          authMode: SSIDAuthMode.psk,
          encryptionMode: SSIDEncryptionMode.wpa,
          psk: profile.auth.psk,
        },
      }
    : {
        name: profile.name,
        ssid: {
          name: profile.ssidName,
          authMode: SSIDAuthMode.radius802,
          encryptionMode: SSIDEncryptionMode.eap,
        },
        identity: {
          username: profile.auth.username,
          password: profile.auth.password,
        },
      };

export const createWirelessProfile =
  (profile: WPAWirelessProfileCreate): AppThunk<Promise<ApiResponseAction<WirelessProfile>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF<WirelessProfile>({
        types: [
          CAMERA_WIRELESS_PROFILE_CREATE_REQUEST,
          CAMERA_WIRELESS_PROFILE_CREATE_SUCCESS,
          CAMERA_WIRELESS_PROFILE_CREATE_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/camera/wirelessProfiles`,
        config: {
          method: Method.post,
          body: JSON.stringify(buildCreateWirelessProfileRequestBody(profile)),
        },
      }),
    );
  };

type UpdateWirelessProfileRequestBody = CreateWirelessProfileRequestBody & { id: number };

const buildUpdateWirelessProfileRequestBody = (
  profile: WPAWirelessProfileUpdate,
): UpdateWirelessProfileRequestBody => ({
  id: profile.id,
  ...buildCreateWirelessProfileRequestBody(profile),
});

export const updateWirelessProfile =
  (profile: WPAWirelessProfileUpdate): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          CAMERA_WIRELESS_PROFILE_UPDATE_REQUEST,
          CAMERA_WIRELESS_PROFILE_UPDATE_SUCCESS,
          CAMERA_WIRELESS_PROFILE_UPDATE_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/camera/wirelessProfiles/${profile.id}`,
        config: {
          method: Method.put,
          body: JSON.stringify(buildUpdateWirelessProfileRequestBody(profile)),
        },
      }),
    );
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const updateCameraConfig =
  (deviceId: string, configChanges: any): AppThunk<Promise<any>> =>
  (dispatch, getState) => {
    const cameraNetwork = getCameraNodeGroupEid(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          CAMERA_CONFIG_UPDATE_REQUEST,
          CAMERA_CONFIG_UPDATE_SUCCESS,
          CAMERA_CONFIG_UPDATE_FAILURE,
        ],
        endpoint: `/n/${cameraNetwork}/manage/cameras/update_user_settings`,
        config: {
          method: Method.put,
          body: JSON.stringify({
            id: deviceId,
            ...configChanges,
          }),
        },
      }),
    );
  };

export const setOnboardingStartedInDashboard =
  (serial: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const orgId = getCurrentOrganization(getState());
    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          SET_ONBOARDING_STARTED_REQUEST,
          SET_ONBOARDING_STARTED_SUCCESS,
          SET_ONBOARDING_STARTED_FAILURE,
        ],
        endpoint: `/api/v1/organizations/${orgId}/camera/onboarding/statuses`,
        config: {
          method: Method.put,
          body: JSON.stringify({ serial: serial, wireless_credentials_sent: true }),
        },
      }),
    );
  };

export const fetchOnboardingStatusInDashboard =
  (serial: string): AppThunk<Promise<ApiResponseAction<WirelessOnboardingStatusResponse>>> =>
  (dispatch, getState) => {
    const orgId = getCurrentOrganization(getState());

    return dispatch(
      wrapApiActionWithCSRF<WirelessOnboardingStatusResponse>({
        types: [
          FETCH_ONBOARDING_STATUS_REQUEST,
          FETCH_ONBOARDING_STATUS_SUCCESS,
          FETCH_ONBOARDING_STATUS_FAILURE,
        ],
        endpoint: `/api/v1/organizations/${orgId}/camera/onboarding/statuses`,
        config: {
          method: Method.get,
          queryParams: {
            serials: [serial],
          },
        },
      }),
    );
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 *
 * Fetch motion events for the specified time range
 * @param nodeId {string}: camera to fetch events for
 * @param start {number}: start of the range
 * @param end {number}: end of the range
 * @param roi {RegionOfInterest}: region of interest
 * @param withImages {boolean}: include image url's if true
 * @param withPeople {boolean}: include person detection info if true
 * @param unconsolidateMotion {boolean}: include sub-event motion blocks if true
 * @param update {boolean}: whether to merge new events with existing ones or to override them
 * @return {Promise}
 */
export const fetchMotionEvents =
  (
    nodeId: Device["id"],
    start: number,
    end: number,
    roiArr: RoiArr | null,
    options: {
      withImages: boolean;
      withPeople: boolean;
      unconsolidateMotion: boolean;
      update: boolean;
    },
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const { withImages, withPeople, unconsolidateMotion, update } = options;
    const roi = roiArr === null ? FULL_REGION_OF_INTEREST : roiArr;

    return dispatch({
      [CALL_API]: {
        types: [MOTION_EVENTS_REQUEST, MOTION_EVENTS_SUCCESS, MOTION_EVENTS_FAILURE],
        meta: {
          nodeId,
          update,
        },
        endpoint: `/n/${getCameraNodeGroupEid(getState())}/manage/cameras/motion_search`,
        config: {
          method: Method.get,
          queryParams: {
            id: nodeId,
            start: start,
            end: end,
            roiArr: roi,
            with_images: withImages,
            with_people: withPeople,
            unconsolidate_motion: unconsolidateMotion,
            version: 2,
          },
        },
      },
    });
  };

export const setRegionOfInterest = (
  nodeId: string,
  roiArr: RoiArr | null,
  polygonPoints: PointData[] | null,
) => ({
  type: SET_REGION_OF_INTEREST,
  payload: {
    nodeId,
    roiArr,
    polygonPoints,
  },
});

export const setMotionSearchInterval = (nodeId: string, startTime?: number, endTime?: number) => ({
  type: SET_MOTION_SEARCH_INTERVAL,
  payload: {
    nodeId,
    startTime,
    endTime,
  },
});

export const setMotionEventsPage = (nodeId: string, currentPage: number, totalPages: number) => ({
  type: SET_MOTION_SEARCH_PAGE,
  payload: {
    nodeId,
    currentPage,
    totalPages,
  },
});

export const setMotionEventsLoadingStatus = (nodeId: string, isLoading: boolean) => ({
  type: SET_MOTION_EVENTS_LOADING_STATUS,
  payload: {
    nodeId,
    isLoading,
  },
});

export const setSelectedMotionEventTimestamp = (nodeId: string, timestamp: number | null) => ({
  type: SET_SELECTED_MOTION_EVENT_TIMESTAMP,
  payload: {
    nodeId,
    timestamp,
  },
});

export const setVideoSettingMenuVisibility = (visible: boolean) => ({
  type: SET_VIDEO_SETTING_MENU_VISIBILITY,
  payload: {
    visible,
  },
});

export const setCurrentVideoSettingPage = (pageId: SettingPageId) => ({
  type: SET_CURRENT_VIDEO_SETTING_PAGE,
  payload: {
    pageId,
  },
});

export const setPlaybackRate = (rateId: PlaybackRateId) => ({
  type: SET_PLAYBACK_RATE,
  payload: {
    rateId,
  },
});

export const setVideoMode = (videoMode: VideoMode) => ({
  type: SET_VIDEO_MODE,
  payload: {
    videoMode,
  },
});

export const setPlaybackStatus = (isPlaying: boolean) => ({
  type: SET_PLAYBACK_STATUS,
  payload: {
    isPlaying,
  },
});

export const setOffset = (offset: number) => ({
  type: SET_OFFSET,
  payload: {
    offset,
  },
});

export const updateOffset = (offset: number) => ({
  type: UPDATE_OFFSET,
  payload: {
    offset,
  },
});

export const setClockStartTS = (clockStartTS: number) => ({
  type: SET_CLOCK_START_TS,
  payload: {
    clockStartTS,
  },
});

export const setInitialDelta = (initialDelta: number) => ({
  type: SET_INITIAL_DELTA,
  payload: {
    initialDelta,
  },
});

export const setIsLoading = (isLoading: boolean) => ({
  type: SET_VIDEO_IS_LOADING,
  payload: {
    isLoading,
  },
});

export const storedVideoRequested = (timestamp: number): AppThunk => {
  return (dispatch, getState) => {
    const timeZoneOffset = getTimezoneOffsetMS(getState());
    const clockStartTS = timestamp - timeZoneOffset;

    return dispatch({
      type: PLAY_HISTORICAL_VIDEO,
      payload: {
        offset: 0,
        isPlaying: false,
        isLoading: true,
        videoMode: "stored",
        clockStartTS,
        initialDelta: 0,
      },
    });
  };
};

export const resetCameraStatus = (nodeId: string) => ({
  type: CAMERA_PLAYER_RELEASED,
  payload: {
    nodeId,
  },
});

export const playLiveVideo = (nodeId: string) => {
  return {
    type: PLAY_LIVE_VIDEO,
    payload: {
      nodeId,
    },
  };
};

/**
 * Generates a snapshot of what the camera sees at the specified time and return a link to that image.
 * @param cameraSerial Serial number of the camera
 * @param timestamp [optional] The snapshot will be taken from this time on the camera. The timestamp is expected
 *                  to be in ISO 8601 format. If no timestamp is specified, we will assume current time.
 * @param fullFrame [optional] If set to "true" the snapshot will be taken at full sensor
 *                  resolution.
 * @returns {GeneratedSnapshotResponse}
 */
export const generateSnapshot =
  (
    cameraSerial: string,
    timestamp?: string,
    fullFrame?: boolean,
  ): AppThunk<Promise<ApiResponseAction<GeneratedSnapshotResponse>>> =>
  (dispatch) => {
    return dispatch(
      wrapApiActionWithCSRF<GeneratedSnapshotResponse>({
        types: [GENERATE_SNAPSHOT_REQUEST, GENERATE_SNAPSHOT_SUCCESS, GENERATE_SNAPSHOT_FAILURE],
        endpoint: `/api/v1/devices/${cameraSerial}/camera/generateSnapshot`,
        config: {
          method: Method.post,
          queryParams: {
            ...(timestamp && { timestamp }),
            fullFrame,
          },
        },
      }),
    );
  };

export const setMotionSearchFilters = (
  nodeId: string,
  withPeople: boolean,
  minEventLength: number,
  sensitivity: number,
) => ({
  type: MOTION_SEARCH_FILTER_SET,
  payload: {
    nodeId,
    withPeople,
    minEventLength,
    sensitivity,
  },
});

export const setPeopleDetection = (deviceId: Device["id"], withPeople: boolean) => ({
  type: PEOPLE_DETECTION_SET,
  payload: { deviceId, withPeople },
});

export const dewarpedToggled = (isDewarped: boolean) => ({
  type: DEWARPED_TOGGLED,
  payload: {
    isDewarped,
  },
});

export const videoMuteToggled = (isMuted: boolean) => ({
  type: VIDEO_MUTE_TOGGLED,
  payload: {
    isMuted,
  },
});
