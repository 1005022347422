import Svg, { Path } from "react-native-svg";

function MagneticCellularGatewayIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M4 10.5C4 9.67157 4.67157 9 5.5 9H18.5C19.3284 9 20 9.67157 20 10.5V20.5C20 21.3284 19.3284 22 18.5 22H5.5C4.67157 22 4 21.3284 4 20.5V10.5Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        d="M7 2C7 1.44772 7.44772 1 8 1C8.55228 1 9 1.44772 9 2V8H7V2Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        d="M15 2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2V8H15V2Z"
        fill="#8F8F8F"
      />
    </Svg>
  );
}

export default MagneticCellularGatewayIcon;
