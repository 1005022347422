import { ProhibitInset } from "phosphor-react-native";
import { G } from "react-native-svg";

import { IconProps } from "./types";

export function Blocking({ x, y, color }: IconProps) {
  return (
    <G x={x} y={y}>
      <ProhibitInset weight="bold" color={color} size={20} />
    </G>
  );
}
