import { ScrollView, StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import SkeletonLine from "~/shared/components/SkeletonLine";

interface SkeletonListProps {
  placeholderHeight?: number;
  numElements?: number;
}

const SkeletonList = ({ placeholderHeight = 100, numElements = 5 }: SkeletonListProps) => {
  return (
    <ScrollView testID="SKELETON_LIST" contentContainerStyle={styles.loadingScrollView}>
      {Array(numElements)
        .fill(0)
        .map((_, idx) => (
          <SkeletonLine key={idx} height={placeholderHeight} withMarginBottom />
        ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  loadingScrollView: {
    margin: SPACING.default,
  },
});

export default SkeletonList;
