import { ColorToken, getColorFromTokenName, useMagneticTheme } from "@meraki/magnetic/themes";
import { Text, TextProps as RNTextProps, TextStyle } from "react-native";

const SIZE_MAP = {
  h1: "Lg",
  h2: "Md",
  h3: "Sm",
  h4: "2xs",
} as const;

type HeadingSize = keyof typeof SIZE_MAP;

interface InternalProps {
  size: HeadingSize;
  color?: ColorToken;
  textAlign?: TextStyle["textAlign"];
}

export interface HeadingProps
  extends Omit<InternalProps, "size">,
    Omit<
      RNTextProps,
      "style" | "onPress" | "onPressIn" | "onPressOut" | "onLongPress" | "suppressHighlighting"
    > {
  size?: HeadingSize;
}

function useStyles({ size, color, textAlign }: InternalProps): TextStyle {
  const theme = useMagneticTheme();

  return {
    fontFamily: "Sharp Sans",
    color: color ? getColorFromTokenName(color, theme) : theme.color.default.text.strong.base,
    fontWeight: theme.TypographyWeightBold.toString() as TextStyle["fontWeight"],
    fontSize: theme[`SizeFont${SIZE_MAP[size]}`],
    lineHeight: theme[`SizeFontHeightLine${SIZE_MAP[size]}`],
    letterSpacing: size === "h4" ? 1 : undefined,
    textTransform: size === "h4" ? "uppercase" : undefined,
    textAlign,
  };
}

export function Heading({ size = "h2", color, textAlign, children, ...rest }: HeadingProps) {
  const themedStyles = useStyles({ size, color, textAlign });

  return (
    <Text {...rest} style={themedStyles}>
      {children}
    </Text>
  );
}
