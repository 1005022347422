import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import { MMKV } from "react-native-mmkv";

// We export this from a shared library as MMKV expects be a single instance to have everything work right. This lets all libs use it easily.
export const storage = new MMKV(
  Platform.OS === "web"
    ? undefined
    : {
        id: "mki-store",
        encryptionKey: DeviceInfo.getUniqueId(),
      },
);

export const asyncStorageAdapter = {
  setItem: (key: string, value: string) => {
    storage.set(key, value);
  },
  getItem: (key: string) => {
    return storage.getString(key) ?? null;
  },
  removeItem: (key: string) => {
    storage.delete(key);
    return Promise.resolve();
  },
};
