import { I18n } from "@meraki/core/i18n";
import { EmptyState } from "@meraki/magnetic/components";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, useNavigation } from "@react-navigation/native";

export const EmptyStateSelectNetwork = () => {
  const drawerNavigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  return (
    <EmptyState
      status="informational"
      title={I18n.t("CLIENTS_LIST.ORG_SCOPE")}
      action={{
        kind: "secondary",
        onPress: drawerNavigation.openDrawer,
        text: I18n.t("CLIENTS_LIST.SELECT_NETWORK"),
      }}
    />
  );
};
