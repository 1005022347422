import * as errorMonitor from "@meraki/core/errors";
import { ZodTypeAny } from "zod";

import { Method } from "../coreTypes";

type LogAndThrowResponseErrorArg = {
  method: Method;
  response: Response;
  responseJson: unknown;
  schema: ZodTypeAny;
  shouldNotify?: boolean;
};

export function logAndThrowResponseError({
  method,
  response,
  responseJson,
  schema,
  shouldNotify,
}: LogAndThrowResponseErrorArg): void {
  // Send a message to Sentry about the error response
  const responseJsonMessage = responseJson ? `and "${JSON.stringify(responseJson)}" ` : "";
  const message = `Received ${response.status} ${responseJsonMessage}when performing ${method} request related to ${schema.description}`;

  if (shouldNotify) {
    errorMonitor.notify(message, schema.description);
  }

  // Throw the response body in case it contains an error message that we may be a condition for rendering some error UI
  // useQuery will catch this error and the json will appear as a query.error property
  throw responseJson ?? message;
}
