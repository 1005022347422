import { I18n } from "@meraki/core/i18n";
import { launchSupportEmailUrl } from "@meraki/go/links";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, KEYBOARD_TYPE, MS_IN_A_SECOND, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { ResetTwoFactorScreensPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import { makeCancelablePromise } from "~/lib/RequestUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import TouchableText from "~/shared/components/TouchableText";
import useActions from "~/shared/hooks/redux/useActions";
import { CloseButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<ResetTwoFactorScreensPropMap, "VerifyResetTwoFactor">;

export const VerifyResetTwoFactorScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [failureCount, setFailureCount] = useState(0);

  const actions = useActions();

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await makeCancelablePromise(actions.getTwoFactorInfo());
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    }
    setLoading(false);
  }, [setLoading, actions]);

  useEffect(() => {
    getData();
  }, [getData]);

  const logoutUser = useCallback(async () => {
    try {
      setLoading(true);
      await actions.logoutUser();
    } catch (error) {
      await actions.wipeRedux();
    }
    setLoading(false);
  }, [actions, setLoading]);

  useEffect(() => {
    if (verified) {
      setTimeout(logoutUser, MS_IN_A_SECOND * 3);
    }
  }, [logoutUser, verified]);

  const verifyPin = async () => {
    try {
      setLoading(true);
      await actions.verifyTwoFactorWorks(pinCode);
      setVerified(true);
    } catch (error) {
      showAlert(
        I18n.t("ERROR"),
        I18n.t("VERIFY_RESET_TWO_FACTOR.INVALID_PIN_ERROR"),
        () => undefined,
        {
          positiveText: I18n.t("VERIFY_RESET_TWO_FACTOR.TRY_AGAIN"),
        },
      );
      setFailureCount(failureCount + 1);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <FullScreenContainerView screenStyles={styles.scrollView}>
        {verified ? (
          <View style={styles.checkCircleStyle} testID={"VERIFICATION_CHECK"}>
            <MerakiIcon name="check-circle" size="xl" color={MkiColors.confirmAlertSuccess} />
            <MkiText textStyle="secondary" screenStyles={styles.successMessage}>
              {I18n.t("VERIFY_RESET_TWO_FACTOR.VERIFICATION_SUCCESS")}
            </MkiText>
          </View>
        ) : (
          <>
            <MkiText screenStyles={styles.messageStyle}>
              {I18n.t("VERIFY_RESET_TWO_FACTOR.VERIFY_DESCRIPTION")}
            </MkiText>
            <View style={styles.codeBoxStyle}>
              <MkiTextInput
                value={pinCode}
                textAlign={"center"}
                screenStyles={styles.codeBoxStyle}
                placeholder={I18n.t("VERIFY_RESET_TWO_FACTOR.ENTER_CODE")}
                keyboardType={KEYBOARD_TYPE.numeric}
                onChangeText={(pinCode: any) =>
                  pinCode.length <= 6 ? setPinCode(pinCode) : undefined
                }
              />
            </View>
            <View style={styles.buttonContainer}>
              <RoundedButton
                buttonType={ButtonType.secondary}
                onPress={verifyPin}
                testID={"VERIFY_BUTTON"}
                screenStyles={styles.buttonStyle}
              >
                {I18n.t("VERIFY_RESET_TWO_FACTOR.VERIFY")}
              </RoundedButton>
            </View>
          </>
        )}
        {!verified && failureCount >= 2 && (
          <TouchableText
            onPress={() =>
              showAlert(
                I18n.t("VERIFY_RESET_TWO_FACTOR.CODE_NOT_WORKING_ALERT.TITLE"),
                I18n.t("VERIFY_RESET_TWO_FACTOR.CODE_NOT_WORKING_ALERT.MESSAGE"),
                () => launchSupportEmailUrl({ showErrorWithContact: true }),
                {
                  positiveText: I18n.t(
                    "VERIFY_RESET_TWO_FACTOR.CODE_NOT_WORKING_ALERT.EMAIL_SUPPORT",
                  ),
                  negativeText: I18n.t("VERIFY_RESET_TWO_FACTOR.CODE_NOT_WORKING_ALERT.CANCEL"),
                },
              )
            }
            text={I18n.t("VERIFY_RESET_TWO_FACTOR.CODE_NOT_WORKING_ALERT.TITLE")}
            testID="CODE_NOT_WORKING"
          />
        )}
        <LoadingSpinner visible={loading}></LoadingSpinner>
      </FullScreenContainerView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
    marginHorizontal: SPACING.default,
  },
  messageStyle: {
    marginBottom: SPACING.default,
  },
  codeBoxStyle: {},
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.large,
  },
  successMessage: {
    marginHorizontal: SPACING.default,
    textAlign: "center",
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  checkCircleStyle: {
    flex: 1,
    alignItems: "center",
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
});

export default VerifyResetTwoFactorScreen;
