import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { FAILED_WIRELESS_CLIENTS_SEARCH_KEY } from "@meraki/shared/filters";
import { DisplayableFailedConnection } from "@meraki/shared/navigation-type";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import { WirelessHealthStackProps } from "~/enterprise/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import {
  currentNetworkState,
  getFilteredDisplayableFailedConnections,
  timespanState,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import FailedConnectionRow from "~/shared/rows/FailedConnectionRow";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { RootState } from "../types/Redux";

type ReduxProps = {
  networkId: string;
  timespan: number;
  displayableFailedConnections: DisplayableFailedConnection[];
};

type Props = ForwardedNativeStackScreenProps<
  WirelessHealthStackProps,
  "FailedWirelessConnectionsList"
> &
  BasicActions &
  ReduxProps;

interface FailedWirelessConnectionsListScreenState {
  isRequesting: boolean;
}

export class FailedWirelessConnectionsListScreen extends PureComponent<
  Props,
  FailedWirelessConnectionsListScreenState
> {
  state = { isRequesting: false };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { actions, networkId, timespan } = this.props;
    const { isRequesting } = this.state;
    const { getFailedConnections } = actions;

    if (isRequesting) {
      return;
    }
    this.setState({ isRequesting: true });

    const wirelessHealthParams = { networkId, timespan };

    try {
      await getFailedConnections(wirelessHealthParams);
    } catch (error) {
      showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      this.setState({ isRequesting: false });
    }
  };

  onFailedConnectionPressed = (failedConnection: DisplayableFailedConnection) => {
    const { navigation } = this.props;
    navigation.navigate("FailedWirelessConnection", {
      failedConnection,
    });
  };

  renderFailedConnectionRow = (displayableFailedConnection: DisplayableFailedConnection) => {
    return (
      <FailedConnectionRow
        displayableFailedConnection={displayableFailedConnection}
        onPress={this.onFailedConnectionPressed}
      />
    );
  };

  getKeyFromFailedConnection = (
    displayableFailedConnection: DisplayableFailedConnection,
    _: number,
  ) => {
    const { clientMac, ts } = displayableFailedConnection;
    return `${clientMac}_${Date.parse(ts)}`;
  };

  render() {
    const { displayableFailedConnections, actions, route } = this.props;
    const { setSearchText, clearSearchText } = actions;
    const { isRequesting } = this.state;

    return (
      <MkiTable
        data={displayableFailedConnections}
        keyExtractor={this.getKeyFromFailedConnection}
        renderRow={this.renderFailedConnectionRow}
        onRefresh={this.getData}
        loading={isRequesting}
        searchPlaceholder={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.SEARCH")}
        searchPrefill={route.params?.searchPrefill ?? ""}
        onSearchChange={(value) => setSearchText(FAILED_WIRELESS_CLIENTS_SEARCH_KEY, value)}
        onSearchClearPressed={() => clearSearchText(FAILED_WIRELESS_CLIENTS_SEARCH_KEY)}
      />
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "networkId is undefined for FailedWirelessConnectionsListScreen",
      currentNetworkState(state),
    ),
    timespan: timespanState(state),
    displayableFailedConnections: getFilteredDisplayableFailedConnections(state),
  };
}

export default connect(
  mapStateToProps,
  basicMapDispatchToProps,
)(FailedWirelessConnectionsListScreen);
