import { I18n } from "@meraki/core/i18n";
import { LoginNextStepsScreen } from "@meraki/shared/auth";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useLayoutEffect } from "react";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { DefaultScreenOptions } from "~/go/navigation/Options";
import { twoFactorScreens } from "~/go/navigation/TwoFactorScreens";
import { LoginStackProps } from "~/go/navigation/Types";
import CreateAccountScreen from "~/go/screens/CreateAccountScreen";
import LoginScreen from "~/go/screens/LoginScreen";
import PasswordResetScreen from "~/go/screens/PasswordResetScreen";
import WelcomeScreen from "~/go/screens/WelcomeScreen";
import { isWeb } from "~/lib/PlatformUtils";
import { currentUserState } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import ChooseClusterScreen from "~/shared/screens/ChooseClusterScreen";
import OrgChooseScreen from "~/shared/screens/OrgChooseScreen";
import OTPAuthScreen from "~/shared/screens/OTPAuthScreen";
import SpSamlLogin from "~/shared/screens/SpSamlLogin";
import TwoFactorAuthScreen from "~/shared/screens/TwoFactorAuthScreen";

const Stack = createNativeStackNavigator<LoginStackProps>();

const ForwardedLoginScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "Login">>()(LoginScreen);

const ForwardedSpSamlLoginScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "SpSamlLogin">>()(
    SpSamlLogin,
  );
const ForwardedTwoFactorAuthScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "TwoFactorAuth">>()(
    TwoFactorAuthScreen,
  );
const ForwardedOrgChooseScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "OrgChoose">>()(
    OrgChooseScreen,
  );
const ForwardedCreateAccountScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "CreateAccount">>()(
    CreateAccountScreen,
  );
const ForwardedChooseClusterScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "ChooseCluster">>()(
    ChooseClusterScreen,
  );
const ForwardedWelcomeScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "Welcome">>()(
    WelcomeScreen,
  );
const ForwardedOTPAuthScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "OTPAuth">>()(
    OTPAuthScreen,
  );
const ForwardedPasswordResetScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "PasswordReset">>()(
    PasswordResetScreen,
  );
const ForwardedLoginNextStepsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<LoginStackProps, "LoginNextSteps">>()(
    LoginNextStepsScreen,
  );

export default function LoginStack() {
  const initialLogin = !useAppSelector(currentUserState);

  useLayoutEffect(() => {
    if (isWeb()) {
      history.replaceState(null, "", "#login");
    }
  }, []);

  return (
    <Stack.Navigator screenOptions={DefaultScreenOptions}>
      <Stack.Screen
        name="Login"
        component={ForwardedLoginScreen}
        options={{ headerShown: false }}
        initialParams={{ isFirstLaunch: initialLogin }}
      />
      <Stack.Screen name="SpSamlLogin" component={ForwardedSpSamlLoginScreen} />
      <Stack.Screen
        name="TwoFactorAuth"
        component={ForwardedTwoFactorAuthScreen}
        options={{ headerTitle: I18n.t("LOGIN.VERIFICATION") }}
      />
      <Stack.Screen
        name="OTPAuth"
        component={ForwardedOTPAuthScreen}
        options={{ headerTitle: I18n.t("LOGIN.VERIFICATION") }}
      />
      <Stack.Screen
        name="OrgChoose"
        component={ForwardedOrgChooseScreen}
        options={{ headerTitle: I18n.t("ORGANIZATION.CHOOSE") }}
      />
      <Stack.Screen
        name="CreateAccount"
        component={ForwardedCreateAccountScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("CREATE_ACCOUNT.TITLE") }}
      />
      <Stack.Screen
        name="ChooseCluster"
        component={ForwardedChooseClusterScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="Welcome"
        component={ForwardedWelcomeScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PasswordReset"
        component={ForwardedPasswordResetScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("PASSWORD_RESET.TITLE") }}
      />
      <Stack.Screen name="LoginNextSteps" component={ForwardedLoginNextStepsScreen} />
      {twoFactorScreens(Stack)}
    </Stack.Navigator>
  );
}
