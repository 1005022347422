import { FilterPickerCard } from "./FilterPickerCard";
import { PickerCard } from "./PickerCard";
import { TimePickerCard } from "./TimePickerCard";
export * from "./Picker";
export { PickerCardProps } from "./PickerCard";

const CompoundPickerCard = Object.assign(PickerCard, {
  Filter: FilterPickerCard,
  Time: TimePickerCard,
});

export { CompoundPickerCard as PickerCard };
