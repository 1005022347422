import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { DAY } from "@meraki/shared/redux";
import { get } from "lodash";

import { MS_IN_A_SECOND, SECONDS_IN_A_MINUTE } from "~/constants/MkiConstants";
import { ACTIVITY_FETCH_SIZE } from "~/constants/Umbrella";
import { TbuciHistory } from "~/shared/types/Tbuci";
import {
  CategoryLabel,
  CategoryType,
  SecurityEventListSection,
  UmbrellaActivity,
  UmbrellaVerdict,
} from "~/shared/types/Umbrella";
import { UmbrellaRequestParamsActivity } from "~/shared/types/UmbrellaApiRequests";

// This buffer is to account for the time between when an IP is assigned
// to a new device and when LittleTable records that update. This is one minute in seconds
const ONE_MINUTE_BUFFER = SECONDS_IN_A_MINUTE;
export const ID_NOT_FOUND = "ID_NOT_FOUND";

export function mapClientId(
  currentTbuciHistory: TbuciHistory,
  ip?: string,
  timestampInMs?: number,
) {
  if (
    !currentTbuciHistory ||
    !ip ||
    !currentTbuciHistory[ip] ||
    typeof timestampInMs !== "number"
  ) {
    return ID_NOT_FOUND;
  }

  const timestamp = timestampInMs / MS_IN_A_SECOND;
  const numberOfClients = currentTbuciHistory[ip].length;

  for (let index = 0; index < numberOfClients; index++) {
    const current = currentTbuciHistory[ip][index];
    const prev = currentTbuciHistory[ip][index - 1];
    const next = currentTbuciHistory[ip][index + 1];

    const [leftBound, rightBound] = [
      prev ? prev.timestamp - ONE_MINUTE_BUFFER : -Infinity,
      next ? next.timestamp - ONE_MINUTE_BUFFER : Infinity,
    ];

    if (timestamp >= leftBound && timestamp < rightBound) {
      return current.id;
    }
  }

  return ID_NOT_FOUND;
}

export function calculateFromWithTimespan(
  to: number = Date.now(),
  timespan: number = DAY.value,
): number {
  return to - timespan * MS_IN_A_SECOND;
}

export function determineActivityPageQuery(
  timespan: number = DAY.value,
  resetActivity = true,
  now: number = Date.now(),
  securityEvents: UmbrellaActivity[] = [],
  ipAddress?: string,
): UmbrellaRequestParamsActivity {
  let to: number;
  let offset: number;

  if (resetActivity) {
    to = now;
    offset = 0;
  } else {
    to =
      securityEvents.length > 0 && securityEvents[0].timestamp ? securityEvents[0].timestamp : now;
    offset = securityEvents.length;
  }

  const queryParams: UmbrellaRequestParamsActivity = {
    from: calculateFromWithTimespan(to, timespan),
    to,
    offset,
    limit: ACTIVITY_FETCH_SIZE,
    verdict: UmbrellaVerdict.blocked,
  };

  if (ipAddress) {
    queryParams.ip = ipAddress;
  }

  return queryParams;
}

export const makeRowsFromActivities = (securityEvents: UmbrellaActivity[], timezone: string) => {
  if (!securityEvents || !timezone) {
    return {};
  }

  const sections = securityEvents.reduce(
    (accum: SecurityEventListSection, securityEvent: UmbrellaActivity) => {
      if (!securityEvent.timestamp) {
        return accum;
      }
      const dateKey = formatDate(securityEvent.timestamp, {
        dateFormat: "longDateWithDayOfWeek",
        timeZone: timezone,
        unixTimestamp: false,
      });

      if (!accum[dateKey]) {
        return { ...accum, [dateKey]: [securityEvent] };
      }
      return { ...accum, [dateKey]: [...accum[dateKey], securityEvent] };
    },
    {},
  );
  return sections;
};

export const eventDescriptionForSecurityEvent = (securityEvent: UmbrellaActivity): string => {
  const type = get(securityEvent, "policycategories[0].type");
  const label = get(securityEvent, "policycategories[0].label");

  if (type === CategoryType.content && label) {
    return I18n.t("SECURITY_EVENT_DETAILS.CONTENT_EVENT", { categoryLabel: label });
  } else if (type === CategoryType.security) {
    switch (label) {
      case CategoryLabel.malware:
        return I18n.t("SECURITY_EVENT_DETAILS.SECURITY_EVENT.MALWARE");
      case CategoryLabel.phishing:
        return I18n.t("SECURITY_EVENT_DETAILS.SECURITY_EVENT.PHISHING");
      case CategoryLabel.command:
        return I18n.t("SECURITY_EVENT_DETAILS.SECURITY_EVENT.COMMAND");
    }
  }

  return "";
};
