export enum FontFamily {
  default = "ciscosans",
}

enum FontSize {
  xxLarge = 32,
  xLarge = 28,
  large = 24,
  sectionHeading = 20,
  default = 16,
  medium = 14,
  small = 13,
  smaller = 11,
  xSmall = 9,
}

export const FONT_SIZES = {
  announcement: FontSize.xxLarge,
  bigNumber: FontSize.xLarge,
  heading: FontSize.large,
  sectionHeading: FontSize.sectionHeading,
  default: FontSize.default,
  subheading: FontSize.default,
  captionLabel: FontSize.small,
  small: FontSize.small,
  smaller: FontSize.smaller,
  medium: FontSize.medium,
  graphLabel: FontSize.xSmall,
};
