import { omit } from "lodash";
import { Insets, StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { withBadge } from "react-native-elements";
import { IconProps } from "react-native-vector-icons/Icon";

import { iconLibraryForIconName, iconLibraryMapping } from "~/lib/IconUtils";
import { platformSelect } from "~/lib/PlatformUtils";
import Touchable from "~/shared/components/Touchable";

/* eslint-disable path-alias/no-relative */
import ArrowBackIcon from "./ArrowBackIcon";
import Backward10Icon from "./camera/Backward10Icon";
import ChevronLeftIcon from "./camera/ChevronLeftIcon";
import CloseIcon from "./camera/CloseIcon";
import CloudIcon from "./camera/CloudIcon";
import ExitFullScreenIcon from "./camera/ExitFullScreenIcon";
import ExportIcon from "./camera/ExportIcon";
import FailedToLoadIcon from "./camera/FailedToLoadIcon";
import Forward10Icon from "./camera/Forward10Icon";
import FullScreenIcon from "./camera/FullScreenIcon";
import InfoIcon from "./camera/InfoIcon";
import KebabIcon from "./camera/KebabIcon";
import LanIcon from "./camera/LanIcon";
import LogsIcon from "./camera/LogsIcon";
import MotionSearchIcon from "./camera/MotionSearchIcon";
import MutedIcon from "./camera/MutedIcon";
import PauseIcon from "./camera/PauseIcon";
import PeopleFilterIcon from "./camera/PeopleFilterIcon";
import PeopleFilterSelectedIcon from "./camera/PeopleFilterSelectedIcon";
import PeopleQuickFilterIcon from "./camera/PeopleQuickFilterIcon";
import PlaneIcon from "./camera/PlaneIcon";
import PlayIcon from "./camera/PlayIcon";
import SettingsIcon from "./camera/SettingsIcon";
import SlidersIcon from "./camera/SlidersIcon";
import SphereIcon from "./camera/SphereIcon";
import ToolsIcon from "./camera/ToolsIcon";
import UnmutedIcon from "./camera/UnmutedIcon";
// shared icons
import CameraIcon from "./CameraIcon";
import ChartIcon from "./ChartIcon";
import CheckedCircleFilled from "./CheckCircleFilled";
import CheckmarkIcon from "./CheckmarkIcon";
import DewarpIcon from "./DewarpIcon";
import GlobeIcon from "./GlobeIcon";
import HamburgerIcon from "./HamburgerIcon";
import LEDIcon from "./LEDIcon";
import ListIcon from "./ListIcon";
import MagneticCameraIcon from "./magnetic/MagneticCameraIcon";
import MagneticCellularGatewayIcon from "./magnetic/MagneticCellularGatewayIcon";
import MagneticEnvironmentalIcon from "./magnetic/MagneticEnvironmentalIcon";
import MagneticNegativeIcon from "./magnetic/MagneticNegativeIcon";
import MagneticSecurityIcon from "./magnetic/MagneticSecurityIcon";
import MagneticSwitchIcon from "./magnetic/MagneticSwitchIcon";
import MagneticSystemsManagerIcon from "./magnetic/MagneticSystemsManagerIcon";
import MagneticWirelessIcon from "./magnetic/MagneticWirelessIcon";
import MalwareIcon from "./MalwareIcon";
import hardwareIllustrations from "./MkiGoHardwareIllustrations";
import NetworkIcon from "./NetworkIcon";
import PortOutlineIcon from "./PortOutlineIcon";
// go icons
import SearchIcon from "./SearchIcon";
import AirIcon from "./sensor/AirIcon";
import ButtonIcon from "./sensor/ButtonIcon";
import DoorIcon from "./sensor/DoorIcon";
import PowerIcon from "./sensor/PowerIcon";
import TemperatureIcon from "./sensor/TemperatureIcon";
import WaterIcon from "./sensor/WaterIcon";
import AlertingIcon from "./sensors/AlertingIcon";
import ElekidEmptyStateIcon from "./sensors/ElekidEmptyStateIcon";
import ElekidLoadingIcon from "./sensors/ElekidLoadingIcon";
import ElekidOffIcon from "./sensors/ElekidOffIcon";
import ElekidOnIcon from "./sensors/ElekidOnIcon";
import ElekidPowerControlIcon from "./sensors/ElekidPowerControlIcon";
import NotAlertingIcon from "./sensors/NotAlertingIcon";
import AndroidLogoIcon from "./systemManager/AndroidLogoIcon";
import AppleLogoIcon from "./systemManager/AppleLogoIcon";
import SystemManagerIcon from "./systemManager/SystemManagerIcon";
import WindowsLogoIcon from "./systemManager/WindowsLogoIcon";
import { CustomGradientProps, DEFAULT_ICON_SIZES, IconSizeTypes } from "./types";
import WifiIcon from "./WifiIcon";
import WirelessIcon from "./WirelessIcon";
/* eslint-enable path-alias/no-relative */

const hardware = Object.keys(hardwareIllustrations).reduce(
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  (o, key) => ({ ...o, [key]: () => hardwareIllustrations[key] }),
  {},
);

const mapping = {
  ...hardware,
  "arrow-back": ArrowBackIcon,
  "people-filter": PeopleFilterIcon,
  "people-filter-selected": PeopleFilterSelectedIcon,
  "people-quick-filter": PeopleQuickFilterIcon,
  camera: CameraIcon,
  magneticCamera: MagneticCameraIcon,
  magneticWireless: MagneticWirelessIcon,
  magneticSwitch: MagneticSwitchIcon,
  magneticCellularGateway: MagneticCellularGatewayIcon,
  magneticSystemsManager: MagneticSystemsManagerIcon,
  magneticEnvironmental: MagneticEnvironmentalIcon,
  magneticSecurity: MagneticSecurityIcon,
  magneticNegative: MagneticNegativeIcon,
  checkmark: CheckmarkIcon,
  checkedCircleFilled: CheckedCircleFilled,
  hamburger: HamburgerIcon,
  network: NetworkIcon,
  chart: ChartIcon,
  list: ListIcon,
  wifi: WifiIcon, // this wifi icon has a gradient on it and should only be used for Meraki GO
  blinkingLED: LEDIcon,
  solidLED: LEDIcon,
  dewarp: DewarpIcon,
  "go-search": SearchIcon,
  "go-malware": MalwareIcon,
  "go-globe": GlobeIcon,
  export: ExportIcon,
  "full-screen": FullScreenIcon,
  "backward-10": Backward10Icon,
  cloud: CloudIcon,
  "exit-full-screen": ExitFullScreenIcon,
  "failed-to-load": FailedToLoadIcon,
  "forward-10": Forward10Icon,
  info: InfoIcon,
  kebab: KebabIcon,
  lan: LanIcon,
  "motion-search": MotionSearchIcon,
  plane: PlaneIcon,
  sphere: SphereIcon,
  pause: PauseIcon,
  play: PlayIcon,
  "sliders-camera": SlidersIcon,
  "close-thin": CloseIcon,
  logs: LogsIcon,
  settings: SettingsIcon,
  tools: ToolsIcon,
  "mv-chevron-left": ChevronLeftIcon,
  water: WaterIcon,
  air: AirIcon,
  door: DoorIcon,
  power: PowerIcon,
  temperature: TemperatureIcon,
  button: ButtonIcon,
  "elekid-empty": ElekidEmptyStateIcon,
  "elekid-on": ElekidOnIcon,
  "elekid-off": ElekidOffIcon,
  "elekid-loading": ElekidLoadingIcon,
  "power-control": ElekidPowerControlIcon,
  alerting: AlertingIcon,
  "not-alerting": NotAlertingIcon,
  unmuted: UnmutedIcon,
  muted: MutedIcon,
  "apple-logo": AppleLogoIcon,
  "android-logo": AndroidLogoIcon,
  wireless: WirelessIcon,
  wired: PortOutlineIcon,
  "windows-logo": WindowsLogoIcon,
  "system-manager": SystemManagerIcon,
} as const;

export type IconNames = keyof typeof mapping;

export interface MerakiIconProps extends Omit<IconProps, "size" | "style"> {
  /** It would be nice to have an enum type for all the icons so we could have code completion
   *  However we would need a way to extract that from react-native-vector-icon
   */
  name: keyof typeof mapping | string;
  size?: number | IconSizeTypes;
  hitSlop?: Insets;
  disableHeightWidth?: boolean;
  hasGradient?: boolean;
  style?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
  renderGradient?: () => JSX.Element;
  customGradient?: CustomGradientProps;
  testID?: string;
  hasBadge?: boolean;
}

export const MerakiIcon = (props: MerakiIconProps) => {
  const { name, size, hasBadge, ...rest } = props;
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  let Icon = mapping[name];

  let iconSize = size;
  if (typeof size === "string") {
    iconSize = DEFAULT_ICON_SIZES[size];
  }

  const newProps = {
    name,
    size: iconSize,
    ...rest,
  };

  if (!Icon) {
    const iconType = iconLibraryForIconName(name);
    if (!iconType) {
      return null;
    }
    Icon = iconLibraryMapping[iconType];
  }

  if (hasBadge) {
    Icon = withBadge(null)(Icon);
  }

  return renderIcon(Icon, newProps);
};

const renderIcon = (Icon: React.ElementType, props: MerakiIconProps) => {
  const { hitSlop, containerStyle, onPress, testID } = props;

  const basicStyle = onPress
    ? platformSelect({ android: containerStyle, ios: null })
    : containerStyle;

  const icon = <Icon {...omit(props, ["testID"])} />;
  const wrappedIcon = (
    <View style={containerStyle ? basicStyle : {}} testID={testID}>
      {icon}
    </View>
  );

  return onPress ? (
    <Touchable
      onPress={onPress}
      transparentBackground
      style={platformSelect({ ios: containerStyle, android: null, web: null })}
      hitSlop={hitSlop}
      testID={testID ? `${testID}.TOUCHABLE` : undefined}
    >
      {wrappedIcon}
    </Touchable>
  ) : (
    wrappedIcon
  );
};

export default MerakiIcon;
