import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, List, Modal, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useAuthUsers, useDisableClientVPN } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Alert } from "react-native";

export const ClientVPNViewGuestScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const route = useRoute<RouteProp<ConfigureStackProps, "ClientVPNViewGuest">>();
  const { params: props } = route;
  const networkId = useCurrentNetworkId();
  const { refetch } = useAuthUsers({ networkId }, { enabled: false });

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const mutateGuest = useDisableClientVPN();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const deleteGuest = () => {
    mutateGuest.mutate(
      { id: props.id, networkId },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          setShowConfirmationModal(false);
          refetch();
          navigation.goBack();
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding>
      <List testID="ViewGuestList">
        <List.Item title={I18n.t("GUEST.NAME")} value={props.name} />
        <List.Item title={I18n.t("GUEST.EMAIL")} value={props.email} />
      </List>

      <Box paddingVertical="lg">
        <Button
          text="Delete Guest"
          kind="secondaryDestructive"
          onPress={() => setShowConfirmationModal(true)}
          testID="deleteGuest"
        ></Button>
      </Box>

      <Modal
        visible={showConfirmationModal}
        title={I18n.t("GUEST.DELETE.CONFIRM")}
        okText={I18n.t("GUEST.DELETE.DELETE")}
        onOk={deleteGuest}
        cancelText={I18n.t("CANCEL")}
        onCancel={() => setShowConfirmationModal(false)}
        onClose={() => setShowConfirmationModal(false)}
      >
        <Text>{I18n.t("GUEST.DELETE.MESSAGE")}</Text>
      </Modal>
    </Screen>
  );
};
