import { memo } from "react";

import MerakiIcon from "~/shared/components/icons";
import { useThemeColors } from "~/shared/hooks/useTheme";
import { ClientIconType } from "~/shared/types/ClientIconType";

export interface ClientIconProps {
  on: boolean;
  type: ClientIconType;
}

/** @deprecated use ~/shared/components/icons/ClientIcon and DeviceTypeIcon instead  */
const ClientIcon = ({ on, type }: ClientIconProps) => {
  const currentThemeColors = useThemeColors().status;
  const colorStyle = {
    color: on ? currentThemeColors.online.color : currentThemeColors.dormant.color,
  };

  // use old icons instead of new icons on old page
  const solidIconType = (type: string) => {
    if (type.slice(-5) === "-logo") {
      return type.slice(0, -5);
    }
    if (type === "wired") {
      return "port";
    }
    return type;
  };

  if (!type) {
    return null;
  }

  return (
    <MerakiIcon name={solidIconType(type)} size={24} style={colorStyle} color={colorStyle.color} />
  );
};
export default memo(ClientIcon);
