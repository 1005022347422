import { queryClient } from "@meraki/shared/api";
import { useMutation, useQuery } from "@tanstack/react-query";

import casesKeys from "~/api/queries/cases/keys";
import {
  CaseAttachmentInput,
  CaseInfoResponse,
  CaseInteraction,
  SupportCaseCommentResponseSchema,
  SupportCaseInfoAttachmentResponseSchema,
  SupportCaseInfoResponseSchema,
} from "~/api/schemas/SupportCaseInfo";
import { validatedMerakiRequest } from "~/api/util/request";
import { encryptedNetworkIdSelector } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const supportCaseInfoKeys = {
  supportCaseInfo: (caseNumber: string) => [...casesKeys.cases, caseNumber] as const,
};

const fetchSupportCaseInfo = (encryptedNetworkId: string, caseNumber: string) => {
  return validatedMerakiRequest(
    SupportCaseInfoResponseSchema,
    "GET",
    `/n/${encryptedNetworkId}/manage/support/case_info`,
    { queryParams: { case_number: caseNumber } },
  );
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSupportCaseInfo = (caseNumber: string) => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector) || "";

  return useQuery({
    queryKey: supportCaseInfoKeys.supportCaseInfo(caseNumber),
    queryFn: () => fetchSupportCaseInfo(encryptedNetworkId, caseNumber),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useAddSupportCaseCommentMutation = (caseNumber: string) => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector);
  const keyToCaseInfo = supportCaseInfoKeys.supportCaseInfo(caseNumber);

  return useMutation({
    mutationFn: (newComment: CaseInteraction) => {
      return validatedMerakiRequest(
        SupportCaseCommentResponseSchema,
        "POST",
        `/n/${encryptedNetworkId}/manage/support/add_case_comment`,
        {
          body: JSON.stringify({ comment_text: newComment.commentBody, case_number: caseNumber }),
        },
      );
    },
    onMutate: async (newInteraction: CaseInteraction) => {
      await queryClient.cancelQueries(keyToCaseInfo);
      const previousInfo = queryClient.getQueryData<CaseInfoResponse>(keyToCaseInfo);

      queryClient.setQueryData(keyToCaseInfo, {
        ...previousInfo,
        interactions: [newInteraction, ...(previousInfo?.interactions ?? [])],
      });

      return { previousInfo };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousInfo != null) {
        queryClient.setQueryData(keyToCaseInfo, context.previousInfo);
      }
    },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useAttachFileToSupportCase = () => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector);

  return useMutation({
    mutationFn: (caseAttachment: CaseAttachmentInput) =>
      validatedMerakiRequest(
        SupportCaseInfoAttachmentResponseSchema,
        "POST",
        `/n/${encryptedNetworkId}/manage/support/upload_case_attachment`,
        { body: JSON.stringify({ ...caseAttachment, from_new_page: true }) },
      ),
  });
};
