import { memo, ReactNode } from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { TouchableHighlightProps } from "react-native/Libraries/Components/Touchable/TouchableHighlight";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import { ROW_SPACINGS } from "~/constants/RowConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import MkiText, { MkiTextProps } from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import { useThemeColors } from "~/shared/hooks/useTheme";

// TODO: UDG-1701
// add ability to change spacing based on if a value is set or not.

export type EllipsizeMode = "head" | "middle" | "tail";

// If an onPress or onLongPress are passed in we'll make it touchable
export type ListRowProps = Pick<TouchableWithoutFeedbackProps, "onPress" | "onLongPress"> & {
  children?: ReactNode;
  context?: string;
  numberOfLines?: number; // Used to control if we ellipsize labels or not
  ellipsizeMode?: EllipsizeMode; // No clip because it's iOS only
  subtitle1?: string | null; // Subtitles will work with non-text labels too,
  subtitle2?: string | null; // just make sure you add margins to your label component
  icon?: React.ReactNode; // Can be any component really, shows up on the left side
  value?: string | number | React.ReactNode; // Value on the right side
  accessory?: React.ReactNode; // Accessories are things like arrows, checkmarks, toggles
  rowStyles?: ViewProps["style"];
  rightStyle?: StyleProp<ViewStyle>;
  rightTextStyle?: MkiTextProps["screenStyles"];
  leftStyle?: StyleProp<ViewStyle>;
  labelStyle?: MkiTextProps["screenStyles"];
  underlayColor?: TouchableHighlightProps["underlayColor"];
  touchableStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

const EMPTY_OBJ = {};
const renderText = (
  text: string | number | null | undefined,
  textStyle: TEXT_STYLE_NAME,
  screenStyles: StyleProp<TextStyle>,
  numberOfLines: ListRowProps["numberOfLines"] = undefined,
  ellipsizeMode: ListRowProps["ellipsizeMode"] = undefined,
) => {
  if (!text) {
    return null;
  }
  const textComponent = (
    <MkiText
      textStyle={textStyle}
      screenStyles={screenStyles}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
    >
      {text}
    </MkiText>
  );

  return textComponent;
};

const renderLeftSide = (
  props: ListRowProps & { themeColors: ReturnType<typeof useThemeColors> },
) => {
  const {
    children,
    subtitle1,
    subtitle2,
    icon,
    numberOfLines,
    ellipsizeMode,
    leftStyle,
    labelStyle,
    context,
  } = props;
  const labelContent =
    typeof children === "string"
      ? renderText(
          children,
          "default",
          labelStyle ?? EMPTY_OBJ,
          numberOfLines,
          ellipsizeMode ?? "middle",
        )
      : children;
  const contentClasses: ViewStyle[] = [styles.leftContent];
  if (icon) {
    contentClasses.push(styles.leftContentIconMargin);
  }

  const contextLeftStyling: ViewStyle[] = [styles.leftContainer];
  if (!context) {
    contextLeftStyling.push(leftStyle ?? EMPTY_OBJ);
    contentClasses.push(styles.leftContentNoContext);
  }
  if (context) {
    contentClasses.push(styles.leftContentContext);
    contextLeftStyling.push(styles.leftContainerContext);
  }
  return (
    <View style={contextLeftStyling}>
      {icon}
      <View style={contentClasses}>
        <View style={styles.horizontalContainer}>
          {labelContent}
          {context && <ContextHelp context={context}></ContextHelp>}
        </View>
        {renderText(subtitle1, "small", styles.subtitle)}
        {renderText(subtitle2, "small", styles.subtitle)}
      </View>
    </View>
  );
};

const renderRightSide = (
  props: ListRowProps & { themeColors: ReturnType<typeof useThemeColors> },
) => {
  const {
    value,
    accessory,
    numberOfLines,
    ellipsizeMode,
    rightStyle,
    rightTextStyle,
    themeColors,
  } = props;
  if (!value && !accessory) {
    return null;
  }

  const rightContent =
    typeof value === "string" || typeof value === "number"
      ? renderText(
          value,
          "default",
          [styles.value, themeColors.text.default, rightTextStyle ?? EMPTY_OBJ],
          numberOfLines,
          ellipsizeMode ?? "middle",
        )
      : value;
  return (
    <View style={[styles.rightContainer, rightStyle ?? EMPTY_OBJ]}>
      {rightContent}
      {accessory}
    </View>
  );
};

export const ListRow = memo(function ListRow(props: ListRowProps) {
  const { onPress, onLongPress, rowStyles, touchableStyles, underlayColor, testID } = props;
  const themeColors = useThemeColors();

  const backgroundColor = themeColors.navigation.backgroundPrimary;
  const containerStyles: ViewStyle[] = [
    { backgroundColor },
    styles.container,
    rowStyles ?? EMPTY_OBJ,
  ];

  const rowProps: ViewProps = { style: containerStyles };
  if (!onPress && !onLongPress) {
    rowProps.testID = testID;
  }

  const row = (
    <View {...rowProps}>
      {renderLeftSide({ ...props, themeColors })}
      {renderRightSide({ ...props, themeColors })}
    </View>
  );

  if (onPress || onLongPress) {
    return (
      <Touchable
        style={touchableStyles}
        underlayColor={underlayColor}
        onPress={onPress}
        onLongPress={onLongPress}
        testID={testID}
      >
        {row}
      </Touchable>
    );
  }
  return row;
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: ROW_SPACINGS.listRow.bottomPadding,
  },
  leftContainer: {
    width: "60%",
    flexDirection: "row",
  },
  leftContent: {
    flex: platformSelect({
      mobile: undefined,
      web: 1,
    }),
    flexDirection: "column",
    justifyContent: "center",
  },
  leftContentIconMargin: {
    marginLeft: 4,
  },
  rightContainer: {
    flex: platformSelect({
      mobile: undefined,
      web: 1,
    }),
    width: "40%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: SPACING.small,
  },
  subtitle: {
    color: MkiColors.secondaryTextColor,
  },
  value: {
    color: appSelect({
      enterprise: TEXT_COLORS.row,
      go: MkiColors.textColor,
    }),
    marginRight: SPACING.small,
    textAlign: "right",
  },
  leftContentNoContext: {
    alignItems: "flex-start",
  },
  leftContentContext: {
    flex: 1,
    justifyContent: "space-between",
    marginEnd: SPACING.small,
    ...platformSelect({
      ios: {
        marginTop: -SPACING.meager,
      },
      android: {
        marginTop: SPACING.tiny,
      },
      web: {
        marginTop: -SPACING.meager,
      },
    }),
  },
  leftContainerContext: {
    flex: 1,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...platformSelect({
      ios: {},
      android: {
        marginEnd: SPACING.small,
      },
    }),
  },
});

export default ListRow;
