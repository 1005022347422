import { CHART_Y_AXIS_FORMAT } from "~/constants/LocationAnalyticsConstants";
import LocationDataCard from "~/go/components/locationAnalytics/LocationDataCard";
import I18n from "~/i18n/i18n";
import { getChartXAxisFormatter } from "~/lib/LocationAnalyticsUtils";
import MkiMultilineChart from "~/shared/components/MkiMultilineChart";
import { DataKey } from "~/shared/types/MkiChartTypes";

export default class ProximityDataCard extends LocationDataCard {
  heading = I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.HEADING");
  subheading = I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.SUBHEADING");

  renderChart = (chartDataKeys: DataKey[]) => {
    const { graphData, domain, timespan } = this.props;

    return (
      <MkiMultilineChart
        domain={domain}
        data={graphData}
        dataKeys={chartDataKeys}
        xAxisFormatter={getChartXAxisFormatter(timespan)}
        yAxisFormatter={CHART_Y_AXIS_FORMAT}
      />
    );
  };
}
