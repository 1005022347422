export enum PredefiendDevicePolicyID {
  allowed = "0",
  blocked = "1",
  normal = "-1",
}

export const PredefiendDevicePolicyIDs: string[] = Object.values(PredefiendDevicePolicyID);

export enum DevicePolicies {
  whitelisted = "Whitelisted",
  blocked = "Blocked",
  normal = "Normal",
}

export enum PolicyType {
  appliance = "appliance",
  ssid = "ssid",
}

interface SSIDOnClientPolicy {
  number: number;
}

export interface AssignedPolicy {
  groupPolicyId?: string; // custom policy only
  name: string;
  type: PolicyType;
  ssid: SSIDOnClientPolicy;
}

export interface ClientPolicy {
  mac: string;
  name: string;
  clientId: string;
  assigned: AssignedPolicy[];
}

// policy object created by ClientUtils
export enum PolicyTypeOnClient {
  wired = "wired",
  wireless = "wireless",
}

// policy object created by ClientUtils
export interface PolicyOnClient {
  policy: string;
  type?: PolicyTypeOnClient;
  ssidName?: string;
  ssidNumber?: number;
}

type MacAddressesByPolicy = {
  [key in DevicePolicies]: string[];
};
export interface DevicePolicyMapping {
  [id: number]: MacAddressesByPolicy;
}

export interface GroupPolicy {
  group_number: string | number;
  name: string;
  bandwidth_limits_enabled?: boolean;
  block_https_search_mode?: string;
  bonjour_forwarding_enabled?: boolean | null;
  bonjour_forwarding_settings_json?: string | null;
  download_bandwidth_limit?: number;
  downtime_scheduled_now?: boolean | null;
  enable_scheduling?: boolean;
  filter_blacklist?: string | null;
  filter_blacklist_policy?: string | null;
  filter_categories?: string | null;
  filter_category_policy?: string | null;
  filter_whitelist?: string | null;
  filter_whitelist_policy?: string | null;
  firewall_enabled?: boolean;
  firewall_layer7_rules?: string;
  firewall_rules?: string;
  force_proxy_enabled?: boolean | null;
  force_proxy_expr?: string | null;
  force_proxy_ip?: string | null;
  force_proxy_port?: number | null;
  foreign_ap_alert_enabled?: boolean | null;
  foreign_ap_alert_specified?: boolean | null;
  full_list_cf_mode?: string | null;
  id?: number;
  ip_reputation_filtering?: {
    threshold: string;
    enabled: boolean;
  };
  node_group_id?: number;
  override_ssid_bandwidth_limits: boolean | null;
  override_ssid_bonjour_settings: boolean | null;
  override_ssid_firewall_rules: boolean | null;
  override_ssid_splash_captive_portal_enabled: boolean | null;
  override_ssid_splash_enabled: boolean | null;
  override_ssid_splash_url: boolean | null;
  override_ssid_vlan_tag: boolean | null;
  safe_search_mode?: string | null;
  schedule_json?: string;
  security_filtering_mode?: null;
  splash_captive_portal_enabled?: null;
  splash_enabled?: boolean;
  splash_url?: string | null;
  ssli_enabled?: boolean | null;
  ta2_disabled?: boolean | null;
  threatgrid_settings_json?: string;
  upload_bandwidth_limit?: number;
  vlan_tag?: number;
  vlan_tagging_enabled?: boolean;
  youtube_for_schools_mode?: string | null;
  youtube_restriction_level?: string | null;
}

export interface GroupPoliciesByNumber {
  [groupNumber: string]: GroupPolicy;
}

export interface CustomPolicy {
  wired?: string;
  [ssidNum: number]: string | undefined;
}

export interface PolicyFormData {
  access?: string;
  group_policy?: string;
  custom_policy?: CustomPolicy;
  ids?: string[];
}
