import { AlertProps } from "@meraki/magnetic/icons";
import { useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface IconBannerProps {
  bannerType: AlertProps["status"];
  alertText: string;
  screenStyles: StyleProp<ViewStyle>;
  testID: string;
  canClose?: boolean;
  textStyle?: TEXT_STYLE_NAME;
}

const IconBanner = (props: IconBannerProps) => {
  const { bannerType, alertText, screenStyles, testID, textStyle, canClose } = props;
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const themecolors = useThemeColors().iconBanner;

  const backgroundColor = { backgroundColor: themecolors?.[bannerType].backgroundColor };
  const iconStyles = [{ color: themecolors?.[bannerType].color }, styles.iconPadding];

  const alertTextStyle = textStyle ? textStyle : "default";

  // TODO: UDG-3699 - first MerakiIcon needs to be switched to magnetic Alert

  return isBannerVisible ? (
    <View testID={testID} style={[styles.infoBannerContainer, backgroundColor, screenStyles]}>
      <View style={styles.iconPadding}>
        <MerakiIcon
          name="magneticNegative"
          size={20}
          testID={`${bannerType.toUpperCase()}_ALERT_ICON`}
        />
      </View>
      <MkiText textStyle={alertTextStyle} screenStyles={styles.alertText}>
        {alertText}
      </MkiText>
      {canClose && (
        <MerakiIcon
          name="close"
          size="xs"
          style={iconStyles}
          onPress={() => setIsBannerVisible(!isBannerVisible)}
          testID="CLOSE_ICON"
        />
      )}
    </View>
  ) : null;
};
const styles = StyleSheet.create({
  infoBannerContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingVertical: SPACING.small,
    paddingHorizontal: SPACING.medium,
    borderRadius: BUTTON_SIZING.borderRadius.default,
  },
  iconPadding: {
    paddingTop: SPACING.meager,
  },
  alertText: {
    flex: 1,
    textAlign: "left",
    paddingHorizontal: SPACING.medium,
  },
});

export default IconBanner;
