import {
  CLIENT_PING_FAILURE,
  CLIENT_PING_REQUEST,
  CLIENT_PING_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { AppThunk } from "~/shared/types/Redux";
import { Accept, ApiActionConfig, ContentType, Method } from "~/shared/types/RequestTypes";

const REQ_ID_KEY = "reqid";
const CONTENT_TYPE_KEY = "Content-Type";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getClientPing(
  encryptedNetworkId: string,
  clientId: string,
  clientMac: string,
  reqid: number | undefined,
): AppThunk<Promise<ApiResponseAction<any>>> {
  const config: ApiActionConfig = {
    method: Method.post,
    queryParams: { client: clientMac },
  };

  if (reqid) {
    const formData = new FormData();
    formData.append(REQ_ID_KEY, `${reqid}`);
    config.body = formData;
    config.headers = {
      Accept: Accept.json,
      [CONTENT_TYPE_KEY]: ContentType.multipart,
    };
    config.noHeaderFill = true;
  }
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [CLIENT_PING_REQUEST, CLIENT_PING_SUCCESS, CLIENT_PING_FAILURE],
        endpoint: `/n/${encryptedNetworkId}/manage/nodes/clientarping_data/${clientId}`,
        config,
      }),
    );
}
