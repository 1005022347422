import { I18n } from "@meraki/core/i18n";
import { UmbrellaPolicyNames } from "@meraki/shared/api";

export const getSecurityItemListDescriptions = () => [
  {
    TITLE: I18n.t("UMBRELLA.ITEMS.MALWARE.TITLE"),
    DESCRIPTION: I18n.t("UMBRELLA.ITEMS.MALWARE.DESCRIPTION"),
  },
  {
    TITLE: I18n.t("UMBRELLA.ITEMS.PHISHING.TITLE"),
    DESCRIPTION: I18n.t("UMBRELLA.ITEMS.PHISHING.DESCRIPTION"),
  },
  {
    TITLE: I18n.t("UMBRELLA.ITEMS.CONTENT_FILTERING.TITLE"),
    DESCRIPTION: I18n.t("UMBRELLA.ITEMS.CONTENT_FILTERING.DESCRIPTION"),
  },
  {
    TITLE: I18n.t("UMBRELLA.ITEMS.COMMAND_AND_CONTROL_CALLBACKS.TITLE"),
    DESCRIPTION: I18n.t("UMBRELLA.ITEMS.COMMAND_AND_CONTROL_CALLBACKS.DESCRIPTION"),
  },
];

export const ACTIVITY_FETCH_SIZE = 100;
export const TOP_IPS_LIMIT = 5000;
export const SUPPORTED_UMBRELLA_POLICIES = [
  UmbrellaPolicyNames.securityOnly,
  UmbrellaPolicyNames.securityAndBasicContent,
  UmbrellaPolicyNames.securityAndModerateContent,
];

export const getUmbrellaPolicyTranslations = (policyName?: UmbrellaPolicyNames) => {
  switch (policyName) {
    case UmbrellaPolicyNames.securityOnly:
      return {
        title: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_ONLY.TITLE"),
        message: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_ONLY.MESSAGE"),
      };
    case UmbrellaPolicyNames.securityAndBasicContent:
      return {
        title: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_AND_BASIC_CONTENT.TITLE"),
        message: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_AND_BASIC_CONTENT.MESSAGE"),
      };
    case UmbrellaPolicyNames.securityAndModerateContent:
      return {
        title: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_AND_MODERATE_CONTENT.TITLE"),
        message: I18n.t("UMBRELLA.POLICY.POLICIES.SECURITY_AND_MODERATE_CONTENT.MESSAGE"),
      };
    default:
      return {
        title: "",
        message: "",
      };
  }
};
