import I18n from "~/i18n/i18n";
import GeneralStatus from "~/shared/constants/Status";

// TODO: remove "f" handling
export const SPLASH_F = "f";

// This is what dashboard uses to determine difference between normal, custom URL and none.
export const SPLASH_TYPES = {
  customURL: "admin-splash-with-clickthrough",
  clickThrough: "splash2",
  none: "off",
};

// This is what we use to determine the page locally
export const SPLASH_PAGE_ID = {
  none: "none",
  clickThrough: "clickThrough",
  customURL: "customURL",
};

// Used in splash access control.
export const CAPTIVE_PORTAL_METHODS = {
  none: "none",
  clickThrough: "click-through",
};

export const STATE_TYPES = {
  staged: "stagedStates",
  custom: "customStates",
  modal: "showModalStates",
};

export const SPLASH_IMAGE_DIMENSIONS = {
  width: 200,
  height: 100,
};

// All Meraki Go customers use this theme. This doesn't need any changes unless
// we add theme select/creation in the future to Go (no current plans to do so).
export const splash2themeId = "6961b3f08ec7dcb6eaa3fb0814e4722e4a83b6b8";

export const SPLASH_TIMEOUT_OPTIONS = [
  1800, 3600, 7200, 14400, 28800, 86400, 259200, 604800, 2592000, 7776000,
];

export const errorAlerts = () => ({
  incorrectURLError: {
    message: I18n.t("SPLASH_CONFIG.INCORRECT_URL"),
    status: GeneralStatus.bad,
  },
  emptyCustomURLError: {
    message: I18n.t("SPLASH_CONFIG.CONFIGURE.CUSTOM_URL.EMPTY_CUSTOM_URL"),
    status: GeneralStatus.bad,
  },
  emptyRedirectURLError: {
    message: I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.EMPTY_REDIRECT_URL"),
    status: GeneralStatus.bad,
  },
  walledGardenWhitespaceError: {
    message: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.WHITESPACE_ERROR"),
    status: GeneralStatus.bad,
  },
  walledGardenIPError: {
    message: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.INVALID_URL"),
    status: GeneralStatus.bad,
  },
  walledGardenNoValueError: {
    message: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.NO_VALUE"),
    status: GeneralStatus.bad,
  },
});
