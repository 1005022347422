import LDClient, { LDClient as LDClientType } from "launchdarkly-js-client-sdk";

import { ContextOptions } from "../types";
import { getIdentityContext, LDFlag, LDFlags } from "./LaunchDarkly.base";
import { Provider } from "./Provider";
import { BooleanKeys, NumberKeys, ObjectKeys, StringKeys } from "./typeHelpers";

export class LaunchDarkly extends Provider {
  client: LDClientType | null = null;

  async init(key?: string) {
    if (!key) {
      return;
    }

    this.client = LDClient.initialize(
      key,
      {
        kind: "user",
        anonymous: true,
      },
      { privateAttributes: ["email"] },
    );
  }

  setContext(context: ContextOptions): void {
    this.client?.identify(getIdentityContext(context));
  }

  async getBool(flag: BooleanKeys<LDFlag>): Promise<boolean> {
    return Promise.resolve(this.client?.variation(flag, LDFlags[flag]) as boolean);
  }

  getDefaultBool(flag: BooleanKeys<LDFlag>): boolean {
    return LDFlags[flag];
  }

  async getString(flag: StringKeys<LDFlag>): Promise<string> {
    return Promise.resolve(this.client?.variation(flag, LDFlags[flag]) as string);
  }

  getDefaultString(flag: StringKeys<LDFlag>): string {
    return LDFlags[flag];
  }

  async getNumber(flag: NumberKeys<LDFlag>): Promise<number> {
    return Promise.resolve(this.client?.variation(flag, LDFlags[flag]) as number);
  }

  getDefaultNumber(flag: NumberKeys<LDFlag>): number {
    return LDFlags[flag];
  }

  async getJson<TFlag extends ObjectKeys<LDFlag>, TReturnType extends LDFlag[TFlag]>(
    flag: TFlag,
  ): Promise<TReturnType> {
    return Promise.resolve(this.client?.variation(flag, LDFlags[flag]) as TReturnType);
  }

  getDefaultJson<TFlag extends ObjectKeys<LDFlag>, TReturnType extends LDFlag[TFlag]>(
    flag: TFlag,
  ): TReturnType {
    return LDFlags[flag] as TReturnType;
  }

  registerFlagListener(flag: keyof LDFlag, callback: (flag: string) => void): () => void {
    this.client?.on(`change:${flag}`, callback);

    return () => {
      this.client?.off(`change:${flag}`, callback);
    };
  }

  track(metricKey: string) {
    this.client?.track(metricKey);
  }
}
