import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { FontWeight } from "~/constants/MkiTextStyles";
import RoundedButton from "~/go/components/RoundedButton";
import { GoSharedScreensPropMap } from "~/go/navigation/Types";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";

type Props = ForwardedNativeStackScreenProps<GoSharedScreensPropMap, "WhatsNewDetail">;

export class WhatsNewDetailScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, title } = this.props;

    navigation.setOptions({
      headerTitle: title,
    });
  }

  onPress = () => {
    const { navigation, showDoc, featureScreen, presentedModally, screenProps } = this.props;

    if (featureScreen) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '[string, { presentedModally: boo... Remove this comment to see the full error message
      navigation.navigate(featureScreen, { ...screenProps, presentedModally });
    } else if (showDoc) {
      showDoc();
    }
  };

  render() {
    const { showDoc, featureScreen, message } = this.props;

    return (
      <FullScreenContainerView>
        <ScrollView style={styles.scrollView} testID="WHATS_NEW_DETAIL_SCREEN">
          <MkiText textStyle="subheading" screenStyles={styles.heading}>
            {I18n.t("WHATS_NEW.FEATURE_DETAILS")}
          </MkiText>
          <MkiText textStyle="secondary">{message}</MkiText>
        </ScrollView>
        {(featureScreen || showDoc) && (
          <View style={styles.buttonContainer}>
            <RoundedButton screenStyles={styles.roundedButton} onPress={this.onPress}>
              {featureScreen
                ? I18n.t("WHATS_NEW.OPEN_FEATURE")
                : I18n.t("SETTINGS.FEATURE.DOCS.TITLE")}
            </RoundedButton>
          </View>
        )}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  scrollView: {
    padding: SPACING.default,
  },
  heading: {
    fontWeight: FontWeight.BOLD,
    marginBottom: SPACING.default,
  },
  buttonContainer: {
    marginVertical: SPACING.large,
    marginHorizontal: SPACING.default,
  },
  roundedButton: {
    borderRadius: BUTTON_SIZING.borderRadius.default,
  },
});

export default WhatsNewDetailScreen;
