import { I18n } from "@meraki/core/i18n";
import { useUpdateVlan, useVlan, useVlans, VlanPayload } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { useLayoutEffect, useState } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";

import { RESERVATION_TYPE } from "~/constants/LocalAddressingConstants";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { FixedIPAssignment, IPRange } from "~/go/types/NetworksTypes";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

const HEADERS = {
  [RESERVATION_TYPE.reservedIpRanges]: I18n.t("RESERVED_ADDRESSES.IP_RANGES_SECTION_HEADER"),
  [RESERVATION_TYPE.deviceReservations]: I18n.t(
    "RESERVED_ADDRESSES.DEVICES_RESERVATIONS_SECTION_HEADER",
  ),
};

export const ReservedAddressesScreen = () => {
  setupAndroidLayoutAnimation();

  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);

  const [isEditMode, setIsEditMode] = useState(false);
  const [reqPending, setReqPending] = useState(false);

  const { data: gxVlan } = useVlan({ networkId, vlanId });

  const updateVlan = useUpdateVlan();
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        isEditMode ? (
          <DoneButton
            onPress={() => {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              setIsEditMode(false);
            }}
          />
        ) : (
          <EditButton
            onPress={() => {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              setIsEditMode(true);
            }}
          />
        ),
    });
  }, [isEditMode, navigation]);

  const updateVlanWithFixedIPAdssignments = async (
    macAddress: string,
    deviceName: string,
    ipAddress: string,
  ) => {
    const updatedVlanData = {
      fixedIpAssignments: {
        ...gxVlan?.fixedIpAssignments,
        [macAddress]: {
          name: deviceName,
          ip: ipAddress,
        },
      },
    };

    await updateVlan.mutateAsync(
      { networkId, vlanId, vlan: updatedVlanData },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot }),
        onError: (error) => {
          showAlert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
        },
      },
    );
  };

  const isReservedIP = (kind: string) => kind === RESERVATION_TYPE.reservedIpRanges;
  const isReservedDevice = (kind: string) => kind === RESERVATION_TYPE.deviceReservations;

  const getOnnSectionHeaderPress = (section: any) => {
    return isReservedIP(section.key)
      ? () => navigation.navigate("ReserveIPRange")
      : () => {
          showActionSheet(
            [
              I18n.t("RESERVED_ADDRESSES.SELECT_FROM_DEVICE_LIST"),
              I18n.t("RESERVED_ADDRESSES.ENTER_MANUALLY"),
            ],
            (id: number) => {
              switch (id) {
                case 0:
                  navigation.navigate("ClientList", {
                    title: I18n.t("RESERVED_ADDRESSES.DEVICES_TITLE"),
                    showCancelButton: true,
                    subnet: gxVlan?.subnet,
                    onPressSave: updateVlanWithFixedIPAdssignments,
                  });
                  break;
                case 1:
                  navigation.navigate("DeviceReservation", {
                    onPressSave: updateVlanWithFixedIPAdssignments,
                  });
                  break;
              }
            },
            {
              title: I18n.t("RESERVED_ADDRESSES.ACTION_SHEET_TITLE"),
            },
          );
        };
  };

  const deleteReservedIp = async (deletedIndex: number, kind: string) => {
    if (gxVlan) {
      try {
        setReqPending(true);
        const updatedVlanData: VlanPayload = {};

        if (isReservedDevice(kind)) {
          const deletedKey = Object.keys(gxVlan.fixedIpAssignments)[deletedIndex];
          updatedVlanData.fixedIpAssignments = omit(gxVlan.fixedIpAssignments, deletedKey);
        } else {
          const newReservedIpRanges = gxVlan.reservedIpRanges.filter(
            (_, index) => index !== deletedIndex,
          );

          // UDG-3544: Validate/consolidate Vlan types
          updatedVlanData.reservedIpRanges = newReservedIpRanges;
        }

        await updateVlan.mutateAsync(
          { networkId, vlanId, vlan: updatedVlanData },
          {
            onSuccess: () => queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot }),
            onError: (error) => {
              showAlert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
            },
          },
        );
      } catch (error) {
        console.warn(error);
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
      } finally {
        setReqPending(false);
      }
    }
  };

  const data = {
    [RESERVATION_TYPE.reservedIpRanges]:
      gxVlan?.reservedIpRanges?.map((item) => ({
        // UDG-3544: Validate/consolidate Vlan types
        ...item,
        kind: RESERVATION_TYPE.reservedIpRanges,
      })) ?? [],
    [RESERVATION_TYPE.deviceReservations]: Object.values(gxVlan?.fixedIpAssignments ?? {}).map(
      (item) => ({
        ...item,
        kind: RESERVATION_TYPE.deviceReservations,
      }),
    ),
  };

  return (
    <FullScreenContainerView>
      <MkiTable<IPRange>
        data={data}
        ListHeaderComponent={
          <View style={styles.rowStyles}>
            <MkiText screenStyles={styles.headerStyle}>
              {I18n.t("RESERVED_ADDRESSES.SUBTITLE")}
            </MkiText>
          </View>
        }
        renderSectionHeader={({ section }: any) => (
          <SectionListHeader
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            heading={HEADERS[section.key]}
            onPress={getOnnSectionHeaderPress(section)}
            withHorizontalMargin
          />
        )}
        renderSectionFooter={({ section }: any) =>
          section.data.length > 0 ? null : (
            <View style={styles.rowStyles}>
              <MkiText textStyle="smallSecondary">
                {isReservedIP(section.key)
                  ? I18n.t("RESERVED_ADDRESSES.NO_RESERVED_IPS")
                  : I18n.t("RESERVED_ADDRESSES.NO_FIXED_IPS")}
              </MkiText>
            </View>
          )
        }
        keyExtractor={(_: any, index: number) => `${index}`}
        renderRow={(item: IPRange | FixedIPAssignment, index: number) => (
          <ListRow
            icon={
              <DeleteButton show={isEditMode} onPress={() => deleteReservedIp(index, item.kind)} />
            }
            subtitle1={"ip" in item ? item.ip : `${item.start} - ${item.end}`}
            rowStyles={styles.rowStyles}
          >
            {"comment" in item ? item.comment : item.name}
          </ListRow>
        )}
      />
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
});

export default ReservedAddressesScreen;
