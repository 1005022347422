import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import { createQuery } from "../../createQuery";
import { AuthTypes, LoginResponse, LoginResponseType } from "./types";

type LoginRequest = {
  email: string;
  password: string;
  token?: string;
};

type LoginError = {
  mode: "login";
  error?: string;
};

export type SamlLoginResponse = {
  mode: "success";
  organizationId: string;
  error: null;
};

export type StandardLoginResponse =
  | {
      mode: AuthTypes | "success" | "org_choose";
      error: string | null;
      success: boolean;
      org_eid: string | null;
      user: {
        id: string;
        path: string;
        is_meraki_sso_admin?: boolean;
      } | null;
      primary?: string;
      secondary?: string;
      orgs?: {
        eid: string;
        id: string;
        name: string;
        two_factor_auth_enabled: boolean;
      }[];
      eid?: string;
    }
  | undefined;

export type SamlOrPasswordLoginResponse = StandardLoginResponse | SamlLoginResponse;

export const standardizeLoginResponse = (response?: LoginResponseType): StandardLoginResponse => {
  if (!response) {
    return undefined;
  }
  if ("mode" in response) {
    if (response.mode === "org_choose") {
      return {
        ...response,
        success: false,
        error: null,
        org_eid: response.eid,
        user: null,
      };
    }
    return {
      ...response,
      success: false,
      error: response.error ?? null,
      org_eid: null,
      user: null,
    };
  }
  return {
    mode: "success" as const,
    success: true,
    error: null,
    org_eid: response.org_eid,
    user: response.user,
  };
};

const createBody = (formData: LoginRequest) => {
  const { token, ...rest } = formData;
  const data: Record<string, string | boolean> = { ...rest };
  if (token) {
    data["captcha_shown"] = true;
    data["mki_captcha_provider_response"] = token;
    data["mki_captcha_provider_id"] = "hcaptcha";
  }
  return data;
};

const performLogin = (queryParams: LoginRequest): Promise<LoginResponseType> =>
  request(LoginResponse, "POST", "/login/login", {
    queryParams: createBody(queryParams),
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useLogin = createMutation<LoginRequest, LoginResponseType, LoginError>({
  baseMutationKey: "/login/login",
  mutationFn: (variables) => performLogin(variables),
});

type OrgChooseRequest = {
  eid: string;
};

const orgChoose = (queryParams: OrgChooseRequest): Promise<LoginResponseType> =>
  request(LoginResponse, "GET", "/login/org_choose", {
    queryParams: {
      ...queryParams,
      remember_user: 1,
    },
  });

export const useOrgChoose = createQuery<OrgChooseRequest, LoginResponseType>({
  baseQueryKey: "/login/org_choose",
  queryFn: (variables) => orgChoose(variables),
});

const UserResponseSchema = z
  .object({
    company_name: z.string(),
    email: z.string(),
    id: z.string(),
    is_meraki_sso_admin: z.boolean(),
    name: z.string(),
    organization_id: z.string(),
  })
  .describe("UserResponseSchema");

type UserResponse = z.infer<typeof UserResponseSchema>;

const fetchUser = (): Promise<UserResponse> => request(UserResponseSchema, "GET", "/users");

export const useFetchUsers = createQuery<undefined, UserResponse>({
  baseQueryKey: "/users",
  queryFn: () => fetchUser(),
});
