import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const DeviceByNetworkSchema = z
  .object({
    id: z.string(),
    lat: z.number(),
    lng: z.number(),
    address: z.string(),
    serial: z.string(),
    mac: z.string(),
    lanIp: z.any(),
    networkEid: z.string(),
    hashedIp4: z.string(),
    tags: z.array(z.string()),
    url: z.string(),
    networkId: z.string(),
    name: z.string().nullable(),
    details: z.array(z.any()),
    model: z.string(),
    firmware: z.string(),
    floorPlanId: z.string().nullable(),
  })
  .describe("DeviceSchema");

export type DeviceByNetwork = z.infer<typeof DeviceByNetworkSchema>;

const DevicesByNetwork = z.array(DeviceByNetworkSchema).describe("DeviceByNetworkSchema");

export type DevicesByNetwork = z.infer<typeof DevicesByNetwork>;

type DeviceRequest = {
  networkId?: string;
};

function buildUrl({ networkId }: DeviceRequest) {
  return `/api/v1/networks/${networkId}/devices`;
}

function fetchDevice({ networkId }: DeviceRequest): Promise<DevicesByNetwork> {
  return request(DevicesByNetwork, "GET", buildUrl({ networkId }));
}
export const useDevicesByNetwork = createQuery<DeviceRequest, DevicesByNetwork>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchDevice(variables),
  requiredVariables: ["networkId"],
});
