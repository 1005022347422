import { ReactNode } from "react";
import { View } from "react-native";

export interface ShiftByProps {
  children: ReactNode;
  x?: number;
  y?: number;
}

export function ShiftBy({ children, x = 0, y = 0 }: ShiftByProps) {
  return <View style={{ transform: [{ translateX: x }, { translateY: y }] }}>{children}</View>;
}
