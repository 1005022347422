import { I18n } from "@meraki/core/i18n";
import { List, Toggle } from "@meraki/magnetic/components";
import { useNavigation } from "@react-navigation/native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { StagedSwitchPort } from "~/api/schemas/SwitchPort";
import { STP_GUARD_OPTIONS } from "~/lib/SwitchPortUtils";
import TagsInputCard from "~/shared/components/TagsInputCard";
import { ObjectUpdater } from "~/shared/hooks/useObjectUpdater";
import { SharedScreensPropMap } from "~/shared/navigation/Types";

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "SwitchPortEditWrapper">;

interface SwitchPortEditScreenProps {
  updateByKey: ObjectUpdater<Partial<StagedSwitchPort>>;
  port: StagedSwitchPort;
}

export const AdditionalConfigurations = ({ port, updateByKey }: SwitchPortEditScreenProps) => {
  const navigation = useNavigation<Props["navigation"]>();

  const { tags, rstpEnabled, isolationEnabled, stpGuard } = port;

  const selectSTPGuard = () => {
    navigation.navigate("Selection", {
      title: I18n.t("STP_GUARD_SELECTION.TITLE"),
      description: I18n.t("STP_GUARD_SELECTION.DESCRIPTION"),
      initialValue: stpGuard,
      onSelect: updateByKey("stpGuard"),
      options: STP_GUARD_OPTIONS,
    });
  };

  // TODO DM-4241: TagsInputCard needs to be magnetized
  return (
    <List label={I18n.t("SWITCH_PORT_EDIT.ADDITIONAL_CONFIGURATIONS")}>
      <List.Item title="">
        <TagsInputCard
          onUpdate={updateByKey("tags")}
          title={I18n.t("SWITCH_PORT_EDIT.TAGS.TITLE")}
          tags={tags}
          placeholder={I18n.t("SWITCH_PORT_EDIT.TAGS.PLACEHOLDER")}
        />
      </List.Item>
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.ISOLATION")}
        rightAccessory={
          <Toggle
            onValueChange={updateByKey("isolationEnabled")}
            testID={"SWITCH_PORT_EDIT.ISOLATION"}
            checked={isolationEnabled}
          />
        }
      />
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.RSTP_GUARD")}
        rightAccessory={
          <Toggle
            onValueChange={updateByKey("rstpEnabled")}
            testID={"SWITCH_PORT_EDIT.RSTP_GUARD"}
            checked={rstpEnabled}
          />
        }
      />
      <List.Item
        title={I18n.t("SWITCH_PORT_EDIT.STP_GUARD")}
        description={STP_GUARD_OPTIONS.find((option) => option.value === port.stpGuard)?.label}
        onPress={selectSTPGuard}
      />
    </List>
  );
};
