import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  Loader,
  Notification,
  Tag,
  Text,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  ApplianceTrafficShapingRule,
  queryClient,
  RulePriorityTypes,
  useApplianceTrafficShapingRules,
  useSsidTrafficShapingRules,
  useUpdateApplianceTrafficShapingRules,
  useUpdateSsidTrafficShapingRules,
} from "@meraki/shared/api";
import { Picker } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import { formatTransferBitsPerSecond, kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useEffect, useRef } from "react";
import { Alert, Pressable } from "react-native";

import {
  DSCPTagPicker,
  DSCPTagPickerForm,
  getDscpTagDescriptionTranslations,
} from "../components/DSCPTagPicker";
import { getLimitIndex, LimitUsageCard } from "../components/LimitUsageCard";
import { LAYER_7_GROUP_FILTERS, LAYER_7_GROUPS } from "../constants/Layer7";
import { PCP_TAG_OPTIONS, TRAFFIC_SHAPING_LIMIT_VALUES } from "../constants/TrafficShaping";

type StagedTrafficShapingRule = {
  applications: string[];
  limitIndex?: number;
  priority?: ApplianceTrafficShapingRule["priority"];
  pcpTag?: number | null;
} & DSCPTagPickerForm;

export function TrafficShapingRuleScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const route = useRoute<RouteProp<ConfigureStackProps, "TrafficShapingRule">>();
  const { params: props } = route;
  const { rules, ruleIndex, ssidNumber } = props;
  const rule = ruleIndex != null ? rules.rules[ruleIndex] : undefined;

  const networkId = useCurrentNetworkId();
  const {
    mutate: updateApplianceTrafficShapingRules,
    isLoading: isUpdatingApplianceTrafficShapingRules,
  } = useUpdateApplianceTrafficShapingRules();
  const { mutate: updateSsidTrafficShapingRules, isLoading: isUpdatingSsidTrafficShapingRules } =
    useUpdateSsidTrafficShapingRules();

  const methods = useForm<StagedTrafficShapingRule>({
    values: {
      applications:
        rule?.definitions?.reduce<StagedTrafficShapingRule["applications"]>(
          (result, definition) => {
            if (definition.type === "applicationCategory") {
              result.push(definition.value.id);
            }
            return result;
          },
          [],
        ) ?? [],
      limitIndex: getLimitIndex(rule?.perClientBandwidthLimits?.bandwidthLimits?.limitDown),
      dscpTag: rule?.dscpTagValue ?? null,
      pcpTag: rule && "pcpTagValue" in rule ? rule.pcpTagValue ?? null : null,
      priority: rule && "priority" in rule ? rule.priority : undefined,
    },
  });

  const onSubmit = useCallback(
    ({ applications, limitIndex, dscpTag, pcpTag, priority }: StagedTrafficShapingRule) => {
      const newRules = rules == null ? [] : [...rules.rules];
      if (ruleIndex != null) {
        newRules.splice(ruleIndex, 1);
      }

      const definitions = applications.map((application) => ({
        type: "applicationCategory" as const,
        value: {
          id: application,
          name: LAYER_7_GROUPS[application]?.keyName,
        },
      }));

      const perClientBandwidthLimits: ApplianceTrafficShapingRule["perClientBandwidthLimits"] = {
        settings: "network default",
      };
      const limitInMB = limitIndex == null ? limitIndex : TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex];
      const limitInMiB = kilobitsToMebibitsInt(limitInMB);
      if (limitInMiB != null) {
        perClientBandwidthLimits.settings = "custom";
        perClientBandwidthLimits.bandwidthLimits = {
          limitUp: limitInMiB,
          limitDown: limitInMiB,
        };
      }

      if (networkId) {
        if (ssidNumber != null && "trafficShapingEnabled" in rules) {
          newRules.push({
            definitions,
            perClientBandwidthLimits,
            dscpTagValue: dscpTag,
            pcpTagValue: pcpTag,
          });

          updateSsidTrafficShapingRules(
            {
              networkId,
              ssidNumber: ssidNumber.toString(),
              rules: {
                ...rules,
                rules: newRules,
              },
            },
            {
              onError: (error) => Alert.alert(error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT")),
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: useSsidTrafficShapingRules.queryKey({ networkId, ssidNumber }),
                });
                navigation.goBack();
              },
            },
          );
        } else {
          newRules.push({
            definitions,
            perClientBandwidthLimits,
            dscpTagValue: dscpTag,
            priority,
          });

          updateApplianceTrafficShapingRules(
            {
              networkId,
              rules: {
                ...rules,
                rules: newRules,
              },
            },
            {
              onError: (error) => Alert.alert(error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT")),
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: useApplianceTrafficShapingRules.queryKey({ networkId }),
                });
                navigation.goBack();
              },
            },
          );
        }
      }
    },
    [
      navigation,
      networkId,
      ruleIndex,
      rules,
      ssidNumber,
      updateApplianceTrafficShapingRules,
      updateSsidTrafficShapingRules,
    ],
  );

  const onDelete = useCallback(() => {
    const newRules = rules == null ? [] : [...rules.rules];
    if (ruleIndex != null) {
      newRules.splice(ruleIndex, 1);
    }

    if (networkId) {
      if (ssidNumber != null && "trafficShapingEnabled" in rules) {
        updateSsidTrafficShapingRules(
          {
            networkId,
            ssidNumber: ssidNumber.toString(),
            rules: {
              ...rules,
              rules: newRules,
            },
          },
          {
            onError: (error) => Alert.alert(error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT")),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: useSsidTrafficShapingRules.queryKey({ networkId, ssidNumber }),
              });
              navigation.goBack();
            },
          },
        );
      } else {
        updateApplianceTrafficShapingRules(
          {
            networkId,
            rules: {
              ...rules,
              rules: newRules,
            },
          },
          {
            onError: (error) => Alert.alert(error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT")),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: useApplianceTrafficShapingRules.queryKey({ networkId }),
              });
              navigation.goBack();
            },
          },
        );
      }
    }
  }, [
    navigation,
    networkId,
    ruleIndex,
    rules,
    ssidNumber,
    updateApplianceTrafficShapingRules,
    updateSsidTrafficShapingRules,
  ]);

  const applications = methods.watch("applications");
  const limitIndex = methods.watch("limitIndex");
  const dscpTag = methods.watch("dscpTag");
  const pcpTag = methods.watch("pcpTag");

  const allOtherAppCategories = rules?.rules?.flatMap((rule, index) =>
    rule.definitions.reduce<string[]>((result, { type, value }) => {
      if (type === "applicationCategory" && ruleIndex !== index) {
        result.push(value.id);
      }

      return result;
    }, []),
  );

  methods.register("applications", {
    validate: (value, _) => {
      const conflicts: string[] = [];
      value.forEach((applicationId) => {
        if (allOtherAppCategories.includes(applicationId)) {
          const name = LAYER_7_GROUPS[applicationId]?.name;
          if (name) {
            conflicts.push(I18n.t(name));
          }
        }
      });

      return conflicts.length > 0
        ? I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.CONFLICTING_CATEGORIES", {
            conflicts: conflicts.join(", "),
          })
        : undefined;
    },
  });

  const applicationFormState = methods.getFieldState("applications");

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button.Nav
          text={I18n.t("CANCEL")}
          onPress={() => navigation.goBack()}
          analytics={{
            event: "onPress",
            eventName: "traffic_shaping_rule_cancel",
            params: {
              isNew: !!rule,
              isDirty: methods.formState.isDirty,
            },
          }}
        />
      ),
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSubmit)}
          disabled={!methods.formState.isDirty}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSubmit, rule]);

  const limit = limitIndex == null ? limitIndex : TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex];
  const usageLimitBottomSheetRef = useRef<BottomSheetMethods>(null);
  const appCategoryBottomSheetRef = useRef<BottomSheetMethods>(null);
  const dscpTagBottomSheetRef = useRef<BottomSheetMethods>(null);
  const pcpTagBottomSheetRef = useRef<BottomSheetMethods>(null);

  const dscpTagDescriptionTranslations = getDscpTagDescriptionTranslations();
  const pcpTagDescriptionTranslations: Record<string, string> = {
    "0": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.0"),
    "1": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.1"),
    "2": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.2"),
    "3": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.3"),
    "4": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.4"),
    "5": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.5"),
    "6": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.6"),
    "7": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.7"),
    null: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.TAG_DESCRIPTION.null"),
  };
  const appCategoryTranslations = {
    BUSINESS: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.FILTERS.BUSINESS"),
    ENTERTAINMENT: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.FILTERS.ENTERTAINMENT"),
    NETWORK: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.FILTERS.NETWORK"),
    SOCIAL: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.FILTERS.SOCIAL"),
    WORK: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.FILTERS.WORK"),
  };

  return (
    <Screen addDefaultPadding>
      {(isUpdatingApplianceTrafficShapingRules || isUpdatingSsidTrafficShapingRules) && (
        <Loader.Spinner />
      )}
      <Form {...methods}>
        <Box>
          {applicationFormState.error?.message && (
            <Notification.Inline status="negative" message={applicationFormState?.error?.message} />
          )}

          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
            paddingLeft="sm"
          >
            <Heading size="h4">
              {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.LABEL")}
            </Heading>
            <Button
              text={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.ADD")}
              kind="tertiary"
              onPress={() => appCategoryBottomSheetRef.current?.present()}
              testID="TRAFFIC_SHAPING_RULE.ADD_CATEGORY"
            />
          </Box>
          <Box
            backgroundColor="default.bg.weak.base"
            borderRadius="sm"
            borderColor="default.border.base"
            borderWidth="medium"
            padding="sm"
            gap="2xs"
            flexDirection="row"
            flexWrap="wrap"
          >
            {applications.length === 0 ? (
              <Text color="light">
                {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.EMPTY")}
              </Text>
            ) : (
              applications.map((application, applicationIndex) => {
                return (
                  <Tag
                    key={application}
                    type="removable"
                    onPress={() => {
                      const newApplications = [...applications];
                      newApplications.splice(applicationIndex, 1);
                      methods.setValue("applications", newApplications, { shouldValidate: true });
                    }}
                    testID={`TRAFFIC_SHAPING_RULE.CATEGORY.${application}`}
                  >
                    {application in LAYER_7_GROUPS
                      ? I18n.t(LAYER_7_GROUPS[application]?.name ?? "")
                      : ""}
                  </Tag>
                );
              })
            )}
          </Box>
          <Box paddingHorizontal="xs" paddingVertical="2xs">
            <Text color="light">
              {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.DESCRIPTION")}
            </Text>
          </Box>
        </Box>
        <Box>
          <Box paddingLeft="sm">
            <Heading size="h4">
              {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.USAGE_LIMIT.LABEL")}
            </Heading>
          </Box>
          <Pressable
            onPress={() => usageLimitBottomSheetRef?.current?.present()}
            testID="TRAFFIC_SHAPING_RULE.USAGE_LIMIT"
          >
            <Box
              borderRadius="sm"
              borderColor="default.border.base"
              borderWidth="strong"
              padding="sm"
              backgroundColor="default.bg.weak.base"
            >
              <Text color="light">
                {limit
                  ? formatTransferBitsPerSecond(limit)
                  : I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.USAGE_LIMIT.EMPTY")}
              </Text>
            </Box>
          </Pressable>
          <Box paddingHorizontal="xs" paddingVertical="2xs">
            <Text color="light">
              {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DESCRIPTION_OPTIONAL")}
            </Text>
          </Box>
        </Box>
        <Box>
          <Box paddingLeft="sm">
            <Heading size="h4">{I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.LABEL")}</Heading>
          </Box>
          <Pressable
            testID="TRAFFIC_SHAPING_RULE.DSCP_TAG"
            onPress={() => dscpTagBottomSheetRef?.current?.present()}
          >
            <Box
              borderRadius="sm"
              borderColor="default.border.base"
              borderWidth="strong"
              padding="sm"
              backgroundColor="default.bg.weak.base"
            >
              <Text color="light">{dscpTagDescriptionTranslations[String(dscpTag)]}</Text>
            </Box>
          </Pressable>
          <Box paddingHorizontal="xs" paddingVertical="2xs">
            <Text color="light">
              {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DESCRIPTION_OPTIONAL")}
            </Text>
          </Box>
        </Box>
        <Box>
          {ssidNumber != null ? (
            <>
              <Box paddingLeft="sm">
                <Heading size="h4">{I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PCP.LABEL")}</Heading>
              </Box>
              <Pressable
                onPress={() => pcpTagBottomSheetRef?.current?.present()}
                testID="TRAFFIC_SHAPING_RULE.PCP_TAG"
              >
                <Box
                  borderRadius="sm"
                  borderColor="default.border.base"
                  borderWidth="strong"
                  padding="sm"
                  backgroundColor="default.bg.weak.base"
                >
                  <Text color="light">{pcpTagDescriptionTranslations[String(pcpTag)]}</Text>
                </Box>
              </Pressable>
              <Box paddingHorizontal="xs" paddingVertical="2xs">
                <Text color="light">
                  {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DESCRIPTION_OPTIONAL")}
                </Text>
              </Box>
            </>
          ) : (
            <Form.FlashList
              name="priority"
              rules={{ required: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PRIORITY.REQUIRED") }}
              label={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.PRIORITY.LABEL")}
              data={Object.values(RulePriorityTypes)}
              getItemData={(priorityValue) => ({
                kind: "radio",
                title: priorityValue,
                radioValue: priorityValue,
                testID: `TRAFFIC_SHAPING_RULE.PRIORITY.${priorityValue}`,
              })}
              paddingLeft="none"
              paddingRight="none"
              testID="TRAFFIC_SHAPING_RULE.PRIORITY"
            />
          )}
        </Box>
        {rule && (
          <Button
            text={I18n.t("DELETE")}
            kind="primaryDestructive"
            onPress={() => onDelete()}
            testID="TRAFFIC_SHAPING_RULE.DELETE"
          />
        )}
        <BottomSheet.Modal ref={usageLimitBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
          <BottomSheet.Header
            title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.USAGE_LIMIT.LABEL")}
            onCancelPress={() => {
              methods.resetField("limitIndex");
              usageLimitBottomSheetRef?.current?.dismiss();
            }}
            onResetPress={() => methods.resetField("limitIndex")}
          />
          <BottomSheet.Content>
            <Box gap="xs">
              <Form {...methods}>
                <LimitUsageCard
                  title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.USAGE_LIMIT.SUBTITLE")}
                />
              </Form>
              <Button
                text={I18n.t("SAVE")}
                onPress={() => usageLimitBottomSheetRef?.current?.dismiss()}
                testID="LIMIT_USAGE.SAVE_BUTTON"
                disabled={!methods.formState.dirtyFields}
              />
            </Box>
          </BottomSheet.Content>
        </BottomSheet.Modal>
        <Picker.WithFilter<string, string>
          ref={appCategoryBottomSheetRef}
          snapPoints={["95%"]}
          multiSelect
          supportMultipleFilterTags
          enableContentPanningGesture={false}
          title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.LABEL")}
          onCancelPress={() => {
            methods.resetField("applications");
            appCategoryBottomSheetRef.current?.close();
          }}
          onResetPress={() => {
            methods.resetField("applications");
          }}
          options={Object.entries(LAYER_7_GROUPS).map(([key, category]) => ({
            label: I18n.t(category.displayName || category.name),
            value: key,
            tags: category.tags,
          }))}
          filterTags={LAYER_7_GROUP_FILTERS.map((filter) => ({
            label: appCategoryTranslations[filter],
            value: filter,
          }))}
          selectedOptions={methods.getValues("applications")}
          onSelectOption={(value) => {
            const appCategoryFilters = methods.getValues("applications");
            const updatedValues = appCategoryFilters.includes(value?.toString() ?? "")
              ? appCategoryFilters.filter((f) => f !== value) // Deslecting
              : [...appCategoryFilters, value];

            methods.setValue("applications", updatedValues, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          footer={
            <Button
              text={I18n.t("SAVE")}
              onPress={() => appCategoryBottomSheetRef?.current?.dismiss()}
              disabled={!methods.formState.dirtyFields.applications}
              testID="APP_CATEGORY.SAVE_BUTTON"
            />
          }
          testID="APP_CATEGORY_LIST"
        />
        <DSCPTagPicker ref={dscpTagBottomSheetRef} />
        <Picker<number | null>
          ref={pcpTagBottomSheetRef}
          snapPoints={["CONTENT_HEIGHT"]}
          options={PCP_TAG_OPTIONS.map((value) => ({
            value,
            label: value == null ? I18n.t("NONE") : value.toString(),
            description: pcpTagDescriptionTranslations[String(value)],
          }))}
          selectedOption={{
            value: pcpTag ?? null,
            label: String(pcpTag),
          }}
          onSelectOption={({ value }) => methods.setValue("pcpTag", value, { shouldDirty: true })}
          testID="PCP_TAG_LIST"
        />
      </Form>
    </Screen>
  );
}
