import { Box } from "@meraki/magnetic/layout";
import { Pressable, PressableProps } from "react-native";

import { Text } from "../Text/Text";
import { Checked } from "./icons/Checked";
import { Indeterminate } from "./icons/Indeterminate";
import { Unchecked } from "./icons/Unchecked";

export type CheckboxProps = Omit<
  PressableProps,
  "style" | "onPress" | "onPressIn" | "onPressOut"
> & {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  children?: string;
  onValueChange?: (checked: boolean) => void;
};

export function Checkbox({
  checked,
  indeterminate,
  disabled,
  children,
  onValueChange,
  ...rest
}: CheckboxProps) {
  let Visual = checked ? Checked : Unchecked;

  if (indeterminate) {
    Visual = Indeterminate;
  }

  const handlePress = () => {
    onValueChange?.(!checked);
  };

  return (
    <Box flexDirection="row" alignItems="center" gap="2xs">
      <Pressable {...rest} disabled={disabled} onPress={handlePress}>
        <Visual disabled={disabled} />
      </Pressable>
      <Text size="p3" color={disabled ? "default.text.disabled" : "default.text.base"}>
        {children}
      </Text>
    </Box>
  );
}
