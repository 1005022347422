import { createBottomSheetNavigator } from "@th3rdwave/react-navigation-bottom-sheet";
import { FullWindowOverlay } from "react-native-screens";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import mkiColors from "~/constants/MkiColors";
import { isIOS } from "~/lib/PlatformUtils";

import MkiActionSheet from "../components/MkiActionSheet";
import { BottomSheetStackParamList, SharedScreensPropMap } from "./Types";

const BottomSheet = createBottomSheetNavigator<BottomSheetStackParamList>();

const ForwardedActionSheet = withForwardedNavigationParams<
  ForwardedStackScreenProps<SharedScreensPropMap, "ActionSheet">
>()((props) => {
  if (!isIOS()) {
    // TODO: DM-3723 styling looks slightly off on android bottom sheet
    return (
      <>
        <MkiActionSheet {...props} />
      </>
    );
  }
  return (
    <FullWindowOverlay>
      <MkiActionSheet {...props} />
    </FullWindowOverlay>
  );
});

const createBottomSheetStack = (defaultComponent: React.ComponentType<unknown>) => {
  return function BottomSheetStack() {
    return (
      <BottomSheet.Navigator
        screenOptions={{
          snapPoints: ["100%"],
          backgroundStyle: {
            backgroundColor: mkiColors.fullTransparent,
          },
          handleIndicatorStyle: { backgroundColor: mkiColors.fullTransparent },
          enablePanDownToClose: false,
        }}
      >
        <BottomSheet.Screen name="BottomSheetDefault" component={defaultComponent} />
        <BottomSheet.Screen name="ActionSheet" component={ForwardedActionSheet} />
      </BottomSheet.Navigator>
    );
  };
};

export default createBottomSheetStack;
