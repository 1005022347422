import { PureComponent } from "react";

import ValueUnitText from "~/shared/components/ValueUnitText";

export interface PercentageTextProps {
  ratio: number;
}

export default class PercentageText extends PureComponent<PercentageTextProps> {
  render() {
    const { ratio } = this.props;
    const percentage = isNaN(ratio) ? undefined : Math.round(ratio * 100);

    return <ValueUnitText value={percentage} unit="%" />;
  }
}
