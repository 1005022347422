import { useTheme } from "@meraki/core/theme";
import { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPINNER } from "~/enterprise/constants/Colors";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiSpinner from "~/shared/components/MkiSpinner";

type Props = {
  visible?: boolean;
  screenStyles?: StyleProp<ViewStyle>;
};

export const LoadingSpinner = memo(function LoadingSpinner({
  screenStyles = {},
  visible = false,
}: Props) {
  const { theme } = useTheme();
  if (!visible) {
    return null;
  }

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        styles.container,
        themeColors(theme).spinnerColor,
        screenStyles,
      ]}
    >
      <MkiSpinner />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: appSelect({
      enterprise: SPINNER.background,
      go: MkiColors.spinnerBackgroundWhite,
    }),
  },
});

export default LoadingSpinner;
