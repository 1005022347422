import { getNextValidIPv4Subnet } from "@meraki/shared/ip-address";

import { SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";

export const createUpdateParams = (
  portNumbers: number[],
  nativeVlanGUID: string,
  enabled: boolean,
) => {
  if (!portNumbers) {
    return undefined;
  }

  return portNumbers.reduce((accumulator, num) => {
    const key = `builtin_${num}`;
    const payload = {
      [key]: {
        allowed_vlans: ["all"],
        enabled: enabled ? "true" : "false",
        is_trunk: "true",
        native_vlan_guid: nativeVlanGUID,
      },
    };
    return { ...accumulator, ...payload };
  }, {});
};

export default createUpdateParams;

export function getUpdatedDefaultVLANPayload(orgSiteToSiteSettings: SiteToSiteSettings[]) {
  const validVlanInfo = getNextValidIPv4Subnet(orgSiteToSiteSettings);

  const updatedDefaultVlanNEW = {
    name: "Default",
    applianceIp: validVlanInfo.applianceIp,
    subnet: validVlanInfo.subnet,
  };

  return updatedDefaultVlanNEW;
}
