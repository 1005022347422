import { saveCookies } from "@meraki/core/cookies";
import CookieManager from "@react-native-cookies/cookies";

type FetchErrorListenerCallback = (response: Response) => void;

let fetchErrorListeners: FetchErrorListenerCallback[] = [];

export function registerFetchErrorListener(callback: FetchErrorListenerCallback) {
  fetchErrorListeners.push(callback);

  return () => (fetchErrorListeners = fetchErrorListeners.filter((fel) => fel !== callback));
}

export function removeAllFetchErrorListeners() {
  fetchErrorListeners = [];
}

export const apiFetch = async (uri: RequestInfo | URL, options?: RequestInit) => {
  const response = await fetch(uri, options);

  // If we don't get an ok response notify all our global listeners, if there are any...
  if (!response.ok) {
    fetchErrorListeners.forEach((fn) => fn(response));
  }

  // Save off our cookies from the response so that we have our auth cookie and such for later calls
  if (typeof uri === "string") {
    const cookies = await CookieManager.get(uri);
    saveCookies(cookies || {});
  }

  return response;
};
