import { REMOTE_CONFIG_UPDATE } from "@meraki/shared/redux";

import {
  FormattedConfig,
  parseDefaultConfig,
  parseRemoteConfig,
  remoteConfigDefaultValues,
} from "~/lib/FirebaseUtils";

const initialState: Record<string, FormattedConfig> = {
  ...parseDefaultConfig(remoteConfigDefaultValues),
};

export default function remoteConfig(state = initialState, action: any) {
  switch (action.type) {
    case REMOTE_CONFIG_UPDATE:
      const { values } = action.response;
      return {
        ...state,
        ...parseRemoteConfig(values),
      };
    default:
      return state;
  }
}
