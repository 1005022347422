import { I18n } from "@meraki/core/i18n";
import { Clusters } from "@meraki/shared/redux";

export const ClusterNames: Record<Clusters, string> = {
  default: I18n.t("CLUSTERS.DEFAULT"),
  china: I18n.t("CLUSTERS.CHINA"),
  ephemeral: I18n.t("CLUSTERS.EPHEMERAL"),
  sandbox: I18n.t("CLUSTERS.SANDBOX"),
  devel: I18n.t("CLUSTERS.DEVEL"),
};

export function getClusterOptions() {
  return (Object.keys(ClusterNames) as Clusters[]).map((cluster) => ({
    key: cluster,
    cluster: ClusterNames[cluster],
  }));
}
