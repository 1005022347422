// HACK: This is a quick and dirty work around to allow us to show the bio auth exactly once
// automatically without being in the context of a React component.
// This should not persist the impending auth refactor.
let autoShowBioAuth = true;

export function getAutoShowBioAuth() {
  return autoShowBioAuth;
}

export function setAutoShowBioAuth(value: boolean) {
  autoShowBioAuth = value;
}
