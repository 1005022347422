import { I18n } from "@meraki/core/i18n";
import { List } from "@meraki/magnetic/components";
import { SwitchPortStatus } from "@meraki/shared/api";

import { CombinedAppliancePort } from "../utils/PortUtils";

interface PortInfoCardProps {
  portInfo: SwitchPortStatus | CombinedAppliancePort;
  linkNegotiation?: string;
}

export const PortInfoCard = ({ portInfo, linkNegotiation }: PortInfoCardProps) => {
  let typeText;
  if ("portId" in portInfo) {
    portInfo as SwitchPortStatus;
    typeText = portInfo.isUplink ? I18n.t("PORTS.UPLINK") : I18n.t("PORTS.LAN_ACCESS");
  } else {
    portInfo as CombinedAppliancePort;
    typeText = portInfo.number === 1 ? I18n.t("PORTS.UPLINK") : I18n.t("PORTS.LAN_ACCESS");
  }

  return (
    <List label={I18n.t("PORTS.PORT_INFO")} testID="PORT_INFO_CARD">
      <List.Item title={I18n.t("PORTS.TYPE")} value={typeText} />
      <List.Item
        title={I18n.t("PORTS.LINK_SPEED")}
        value={linkNegotiation ?? portInfo.speed ?? I18n.t("UNKNOWN")}
      />
    </List>
  );
};
