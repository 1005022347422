import { I18n } from "@meraki/core/i18n";

import { WirelessHealthReduxState } from "~/shared/types/WirelessHealth";

export const associationStages = {
  assoc: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.ASSOC"),
  auth: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.AUTH"),
  dhcp: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.DHCP"),
  dns: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_STAGES.DNS"),
} as const;

export const getFullStageName = (name: keyof typeof associationStages) => associationStages[name];

export const initialState: WirelessHealthReduxState = {
  connectionStats: {},
  latencyStats: {},
  failedConnections: {},
  channelUtilization: {},
};
