import * as errorMonitor from "@meraki/core/errors";
import { ProductType } from "@meraki/shared/api";
import { getProductType } from "@meraki/shared/devices";
import {
  BATCH_UPDATE_DEVICE_FAILURE,
  BATCH_UPDATE_DEVICE_REQUEST,
  BATCH_UPDATE_DEVICE_SUCCESS,
  DEVICE_CLIENTS_FAILURE,
  DEVICE_CLIENTS_REQUEST,
  DEVICE_CLIENTS_SUCCESS,
  DEVICE_LLDP_FAILURE,
  DEVICE_LLDP_REQUEST,
  DEVICE_LLDP_SUCCESS,
  FETCH_DEVICE_BY_SERIAL_FAILURE,
  FETCH_DEVICE_BY_SERIAL_REQUEST,
  FETCH_DEVICE_BY_SERIAL_SUCCESS,
  FETCH_SINGLE_DEVICE_DETAILS_FAILURE,
  FETCH_SINGLE_DEVICE_DETAILS_REQUEST,
  FETCH_SINGLE_DEVICE_DETAILS_SUCCESS,
  GET_DHCP_SUBNET_FAILURE,
  GET_DHCP_SUBNET_REQUEST,
  GET_DHCP_SUBNET_SUCCESS,
  GET_HARDWARE_RADIO_SETTINGS_FAILURE,
  GET_HARDWARE_RADIO_SETTINGS_REQUEST,
  GET_HARDWARE_RADIO_SETTINGS_SUCCESS,
  INVENTORY_CLAIM_FAILURE,
  INVENTORY_CLAIM_REQUEST,
  INVENTORY_CLAIM_SUCCESS,
  LSP_CONNECTION_FAILURE,
  LSP_CONNECTION_REQUEST,
  LSP_CONNECTION_SUCCESS,
  MANAGEMENT_INTERFACE_FAILURE,
  MANAGEMENT_INTERFACE_REQUEST,
  MANAGEMENT_INTERFACE_SUCCESS,
  NODE_IMAGE_DELETE_FAILURE,
  NODE_IMAGE_DELETE_REQUEST,
  NODE_IMAGE_DELETE_SUCCESS,
  NODE_IMAGE_UPLOAD_FAILURE,
  NODE_IMAGE_UPLOAD_REQUEST,
  NODE_IMAGE_UPLOAD_SUCCESS,
  NODES_JSON_FAILURE,
  NODES_JSON_REQUEST,
  NODES_JSON_SUCCESS,
  REMOVE_NODE_FAILURE,
  REMOVE_NODE_REQUEST,
  REMOVE_NODE_SUCCESS,
  SET_HARDWARE_RADIO_SETTINGS_FAILURE,
  SET_HARDWARE_RADIO_SETTINGS_REQUEST,
  SET_HARDWARE_RADIO_SETTINGS_SUCCESS,
  UPDATE_MANAGEMENT_INTERFACE_FAILURE,
  UPDATE_MANAGEMENT_INTERFACE_REQUEST,
  UPDATE_MANAGEMENT_INTERFACE_SUCCESS,
} from "@meraki/shared/redux";

import { batch } from "~/actions/actionBatches";
import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { setCurrentNetwork, setOrg } from "~/actions/intermediate";
import { getInventoryDevice } from "~/actions/inventory";
import { getNetwork } from "~/actions/networks";
import { fetchOrg } from "~/api/actions/orgs";
import { Device as PublicApiDevice } from "~/api/schemas/Device";
import { createWan1Body } from "~/lib/IPAddressUtils";
import { ApiAction, ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkState,
  deviceByIdSelector,
  getCurrentOrganization,
  getNetworkNodeGroupEids,
  getOrgNetworkById,
  getSingleOrgById,
} from "~/selectors";
import Device from "~/shared/types/Device";
import { StaticIPForm } from "~/shared/types/IPAddress";
import { URLPrepends } from "~/shared/types/Networks";
import { NodeContainer } from "~/shared/types/Node";
import { RadioSettings } from "~/shared/types/RadioSettingsTypes";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

const SENSOR_FIELDS = [
  "supports_humidity",
  "supports_temperature",
  "supports_water_detection",
  "supports_button_release",
  "supports_door",
  "has_probe",
  "supports_co2",
  "supports_tvoc",
  "supports_pm25",
  "supports_ambient_noise",
  "supports_iaq_index",
  "supports_power",
];

const WIRELESS_FIELDS = ["is_gateway", "channels"];

export function updateDevicePhoto(nodeSerial: string, nodeId: string, imagePath: string): AppThunk {
  const formdata = new FormData();
  formdata.append("image", {
    // @ts-ignore THere is a typing issue here as of react-native 0.71.4 upgrade. DM-
    uri: imagePath,
    type: "image/jpeg",
    name: "image",
    filename: `${nodeId}-mounting-photo.jpg`,
  });

  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [NODE_IMAGE_UPLOAD_REQUEST, NODE_IMAGE_UPLOAD_SUCCESS, NODE_IMAGE_UPLOAD_FAILURE],
        endpoint: `/nodes/${nodeId}/node_images`,

        config: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          method: "POST",
          body: formdata,
          noHeaderFill: true,
        },
        meta: {
          nodeSerial,
        },
      }),
    );
}

export function deleteDevicePhoto(
  nodeSerial: string,
  imageId: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [NODE_IMAGE_DELETE_REQUEST, NODE_IMAGE_DELETE_SUCCESS, NODE_IMAGE_DELETE_FAILURE],
        endpoint: `/node_images/${imageId}`,
        config: {
          method: Method.delete,
        },
        meta: {
          nodeSerial,
        },
      }),
    );
}

export function getManagementInterface(nodeSerial: string): ApiAction {
  return {
    [CALL_API]: {
      types: [
        MANAGEMENT_INTERFACE_REQUEST,
        MANAGEMENT_INTERFACE_SUCCESS,
        MANAGEMENT_INTERFACE_FAILURE,
      ],
      endpoint: `/api/v1/devices/${nodeSerial}/managementInterface`,
      config: {
        method: Method.get,
      },
      meta: {
        nodeSerial,
      },
    },
  };
}

export function getRadioSettings(serialNumber: string) {
  return wrapApiActionWithCSRF({
    types: [
      GET_HARDWARE_RADIO_SETTINGS_REQUEST,
      GET_HARDWARE_RADIO_SETTINGS_SUCCESS,
      GET_HARDWARE_RADIO_SETTINGS_FAILURE,
    ],
    endpoint: `/api/v1/devices/${serialNumber}/wireless/radio/settings`,
    config: {
      method: Method.get,
    },
    meta: {
      serialNumber,
    },
  });
}

export function setRadioSettings(serialNumber: string, radioSettings: RadioSettings) {
  return wrapApiActionWithCSRF({
    types: [
      SET_HARDWARE_RADIO_SETTINGS_REQUEST,
      SET_HARDWARE_RADIO_SETTINGS_SUCCESS,
      SET_HARDWARE_RADIO_SETTINGS_FAILURE,
    ],
    endpoint: `/api/v1/devices/${serialNumber}/wireless/radio/settings`,
    config: {
      method: Method.put,
      body: JSON.stringify(radioSettings),
    },
    meta: {
      serialNumber,
    },
  });
}

export function getDeviceClients(serialNumber: string, timespan = 120) {
  return {
    [CALL_API]: {
      types: [DEVICE_CLIENTS_REQUEST, DEVICE_CLIENTS_SUCCESS, DEVICE_CLIENTS_FAILURE],
      endpoint: `/api/v1/devices/${serialNumber}/clients`,
      config: {
        method: Method.get,
        queryParams: { timespan },
      },
      meta: { id: serialNumber },
    },
  };
}

export function updateManagementInterface(serialNumber: string, formState: StaticIPForm) {
  const wan1Body = createWan1Body(formState);

  return wrapApiActionWithCSRF({
    types: [
      UPDATE_MANAGEMENT_INTERFACE_REQUEST,
      UPDATE_MANAGEMENT_INTERFACE_SUCCESS,
      UPDATE_MANAGEMENT_INTERFACE_FAILURE,
    ],
    endpoint: `/api/v1/devices/${serialNumber}/managementInterface`,
    config: {
      method: Method.put,
      body: JSON.stringify({ wan1: wan1Body }),
    },
  });
}

export function getDeviceLLDPData(serialNumber: string): ApiAction {
  return {
    [CALL_API]: {
      types: [DEVICE_LLDP_REQUEST, DEVICE_LLDP_SUCCESS, DEVICE_LLDP_FAILURE],
      endpoint: `/api/v1/devices/${serialNumber}/lldpCdp`,
      meta: {
        serialNumber,
      },
      config: {
        method: Method.get,
      },
    },
  };
}

export function getDHCPSubnets(serial: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [GET_DHCP_SUBNET_REQUEST, GET_DHCP_SUBNET_SUCCESS, GET_DHCP_SUBNET_FAILURE],
        endpoint: `/api/v1/devices/${serial}/appliance/dhcp/subnets`,
        config: {
          method: Method.get,
        },
        meta: {
          networkId,
        },
      },
    });
  };
}

/**
 * TODO: DM-2441: fully implement this to fetch is_gateway
 * on devices list page
 */
export function fetchIsGateway(): AppThunk<Promise<void | ApiResponseAction<any>[]>> {
  return (dispatch, getState) => {
    const nodeGroupEids = getNetworkNodeGroupEids(getState(), true);
    if (!nodeGroupEids) {
      return Promise.resolve();
    }

    const queryParams = ["is_gateway"];
    const requests: Promise<ApiResponseAction<any>>[] = nodeGroupEids.map((eid) =>
      dispatch(fetchNodesJSON(eid, queryParams)),
    );

    return Promise.all(requests);
  };
}

export function fetchSingleDeviceStatus(
  encryptedNetworkId: string,
  nodeId: number | string,
): AppThunk<Promise<ApiResponseAction<NodeContainer<{ serial: string }>>>> {
  return (dispatch, getState) => {
    const device = deviceByIdSelector(getState(), { id: nodeId });
    const { serial } = device;
    const queryParams = [
      "alerts",
      "config_fetch_at#",
      "config_new_at#",
      "config_status",
      "has_firmware_eco",
      "has_tagged_firmware_eco",
      "ip",
    ];

    if (getProductType(device.model) === "wireless") {
      queryParams.concat(WIRELESS_FIELDS);
    }

    if (getProductType(device.model) === "sensor") {
      queryParams.concat(SENSOR_FIELDS);
    }
    return dispatch(fetchNodesJSON(encryptedNetworkId, queryParams, serial, nodeId));
  };
}

export function fetchSingleDeviceDetails(serial: string): ApiAction {
  if (serial === undefined) {
    errorMonitor.notify("undefined serial for fetchSingleDeviceDetails");
  }
  return {
    [CALL_API]: {
      types: [
        FETCH_SINGLE_DEVICE_DETAILS_REQUEST,
        FETCH_SINGLE_DEVICE_DETAILS_SUCCESS,
        FETCH_SINGLE_DEVICE_DETAILS_FAILURE,
      ],
      endpoint: `/api/v1/devices/${serial}`,
      config: {
        method: Method.get,
      },
    },
  };
}

function fetchDeviceBySerial(serial: string): ApiAction {
  if (serial === undefined) {
    errorMonitor.notify("undefined serial for fetchDeviceBySerial");
  }
  return {
    [CALL_API]: {
      types: [
        FETCH_DEVICE_BY_SERIAL_REQUEST,
        FETCH_DEVICE_BY_SERIAL_SUCCESS,
        FETCH_DEVICE_BY_SERIAL_FAILURE,
      ],
      endpoint: `/api/v1/devices/${serial}`,
      config: {
        method: Method.get,
      },
    },
  };
}

export function fetchByDeviceSerialString(serial: string): AppThunk<Promise<Device>> {
  if (serial === undefined) {
    errorMonitor.notify("undefined serial for fetchByDeviceSerialString");
  }
  return async (dispatch) => {
    const fetchedDevice = (await dispatch(fetchDeviceBySerial(serial))).response;
    return fetchedDevice;
  };
}

export function fetchDeviceById(
  deviceId: number,
  ngEid: string,
): AppThunk<Promise<ApiResponseAction<Device>>> {
  return async (dispatch) => {
    const { response } = await dispatch(fetchNodesJSON(ngEid, ["serial"], undefined, deviceId));
    const { serial } = response.nodes[deviceId];
    if (serial === undefined) {
      errorMonitor.notify("undefined serial for fetchDeviceById");
    }

    // Get device data needed to populate device details screen.
    return await dispatch(fetchDeviceBySerial(serial));
  };
}

export function fetchAndSwitchToDeviceNetwork(
  deviceId: number,
  ngEid: string,
): AppThunk<Promise<Device>> {
  return async (dispatch) => {
    const fetchedDevice = (await dispatch(fetchDeviceById(deviceId, ngEid))).response;
    await dispatch(switchToNetwork(fetchedDevice.networkId));

    return fetchedDevice;
  };
}

// TODO: merge with setCurrentNetwork
export function switchToNetwork(networkId: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (networkId === currentNetworkState(getState())) {
      return;
    }
    const { organizationId } =
      getOrgNetworkById(getState(), networkId) ?? (await dispatch(getNetwork(networkId))).response;
    await dispatch(switchToOrg(organizationId));
    dispatch(setCurrentNetwork(networkId));
  };
}

// TODO: merge with setOrg
export function switchToOrg(orgId: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (orgId === getCurrentOrganization(getState())) {
      return;
    }

    dispatch(
      setOrg(getSingleOrgById(getState(), orgId) ?? (await dispatch(fetchOrg(orgId))).response),
    );
  };
}

/**
 * Loads devices into redux state with by id with serial and sensor fields.
 * Client pages are currently reliant on this to accurately pull device data but once we migrate
 * those pages away from Redux we should deprecate this
 */
export function getDeviceIds(): AppThunk<Promise<void | ApiResponseAction<any>[]>> {
  return (dispatch, getState) => {
    const nodeGroupEids = getNetworkNodeGroupEids(getState(), true);
    if (!nodeGroupEids) {
      return Promise.resolve();
    }
    const queryParams = [...SENSOR_FIELDS, "serial"];
    const requests: Promise<ApiResponseAction<any>>[] = nodeGroupEids.map((eid) =>
      dispatch(fetchNodesJSON(eid, queryParams)),
    );

    return Promise.all(requests);
  };
}

/**
 * Deprecated
 * TODO eventually: see if we can migrate the use of this function over to getOrgDeviceStatuses()
 * and fetchSingleDeviceStatus()
 */
export function getDeviceStatuses(): AppThunk<Promise<void | ApiResponseAction<any>[]>> {
  return (dispatch, getState) => {
    const nodeGroupEids = getNetworkNodeGroupEids(getState(), true);
    if (!nodeGroupEids) {
      return Promise.resolve();
    }

    const queryParams = [
      "alerts",
      "config_fetch_at#",
      "config_new_at#",
      "config_status",
      "has_firmware_eco",
      "has_tagged_firmware_eco",
      "ip",
      "serial",
      ...SENSOR_FIELDS,
      ...WIRELESS_FIELDS,
    ];
    const requests: Promise<ApiResponseAction<any>>[] = nodeGroupEids.map((eid) =>
      dispatch(fetchNodesJSON(eid, queryParams)),
    );

    return Promise.all(requests);
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
function fetchNodesJSON(
  encryptedNetworkId: string,
  params: string[],
  serial?: string,
  nodeId?: number | string,
): ApiAction {
  const queryParams: any = { f: [...params] };

  if (nodeId) {
    queryParams["ids"] = nodeId;
  }

  return {
    [CALL_API]: {
      types: [NODES_JSON_REQUEST, NODES_JSON_SUCCESS, NODES_JSON_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/nodes/json`,
      config: {
        method: "GET",
        queryParams: queryParams,
      },
      meta: {
        serial,
      },
    },
  };
}

export function checkLSPConnection(
  serial?: string,
  productType?: ProductType,
): Promise<void> | AppThunk<Promise<ApiResponseAction<any>>> {
  let prepend: URLPrepends;

  switch (productType) {
    case "appliance":
      prepend = URLPrepends.lspWired;
      break;
    case "switch":
      prepend = URLPrepends.lspSwitch;
      break;
    case "wireless":
      prepend = URLPrepends.lspWireless;
      break;
    default:
      if (serial == null) {
        prepend = URLPrepends.lspAny;
        break;
      }
      return Promise.resolve();
  }

  //@ts-ignore
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [LSP_CONNECTION_REQUEST, LSP_CONNECTION_SUCCESS, LSP_CONNECTION_FAILURE],
        prepend,
        endpoint: `/index.json`,
        meta: {
          serial,
        },
        config: {
          method: Method.get,
        },
      },
    }).catch((error: any) => {
      // Ignoring the error from API middleware when there is no LSP connection.
      // API middlware will throw an error due to response includes "success: false"
      console.warn(error);
    });
  };
}

export function removeDevice(serial: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [REMOVE_NODE_REQUEST, REMOVE_NODE_SUCCESS, REMOVE_NODE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/devices/remove`,
        config: {
          method: Method.post,
          body: JSON.stringify({ serial }),
        },
        meta: { serial },
      }),
    );
  };
}

export function updateMultipleDevices(deviceChanges: {
  [serial: string]: Object;
}): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return (dispatch) => {
    const actions: any[] = [];

    for (const [serial, body] of Object.entries(deviceChanges)) {
      actions.push({
        resource: `/devices/${serial}`,
        operation: "update",
        body,
      });
    }

    if (actions.length === 0) {
      return Promise.resolve();
    }

    return dispatch(
      batch({
        types: [
          BATCH_UPDATE_DEVICE_REQUEST,
          BATCH_UPDATE_DEVICE_SUCCESS,
          BATCH_UPDATE_DEVICE_FAILURE,
        ],
        config: {
          method: Method.post,
          body: JSON.stringify({
            confirmed: true,
            synchronous: true,
            actions,
          }),
        },
        meta: { deviceChanges },
      }),
    );
  };
}

export function claimDevice(organizationId: string, serial: string) {
  return wrapApiActionWithCSRF({
    types: [INVENTORY_CLAIM_REQUEST, INVENTORY_CLAIM_SUCCESS, INVENTORY_CLAIM_FAILURE],
    endpoint: `/api/v1/organizations/${organizationId}/claim`,
    config: {
      method: "POST",
      body: JSON.stringify({ serials: [serial] }),
    },
  });
}

export const claimAndFetchDevice = (serial: string): AppThunk<Promise<PublicApiDevice>> => {
  return async (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());
    if (organizationId) {
      await dispatch(claimDevice(organizationId, serial));
    } else {
      errorMonitor.notify("organizationId not defined for claimAndFetchDevice");
    }
    const { response } = await dispatch(getInventoryDevice(serial));
    return response;
  };
};
