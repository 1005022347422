import { Icon, Status, StatusType } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useState } from "react";
import { Pressable } from "react-native";

import { Button, ButtonProps } from "../Button/Button";
import { Card, CardProps } from "../Card/Card";
import { EmptyState } from "../EmptyState/EmptyState";
import { Tag } from "../Tag/Tag";
import { Text, TextProps } from "../Text/Text";

export type ActivityType = {
  title: TextProps["children"];
  status?: StatusType;
  subtitle?: TextProps["children"];
  paragraphText?: TextProps["children"];
  tag?: string;
  collapsible?: boolean;
  actions?: ButtonProps[];
};

export type ActivityTimelineProps = {
  activity: ActivityType[];
  title?: TextProps["children"];
  numbered?: boolean;
} & CardProps;

function ExpandedContent({
  actions,
  paragraphText,
}: {
  actions?: ButtonProps[];
  paragraphText?: TextProps["children"];
}) {
  return (
    <Box paddingTop="sm" paddingLeft="xl">
      <Text size="p2">{paragraphText}</Text>
      {!!actions && (
        <Box flexDirection="row" alignSelf="flex-end" gap="md" paddingTop="sm">
          {actions.map((action) => (
            <Button {...action} />
          ))}
        </Box>
      )}
    </Box>
  );
}

function ActivityStep({
  activityStep,
  numbered,
  index,
  last,
  open,
  toggleOpen,
  collapsible,
}: {
  activityStep: ActivityType;
  numbered: ActivityTimelineProps["numbered"];
  index: number;
  last: boolean;
  open: boolean;
  toggleOpen: () => void;
  collapsible: boolean;
}) {
  const { title, tag, subtitle, paragraphText, actions, status } = activityStep;

  const hasAdditionalContent = paragraphText || actions?.length;

  return (
    <Pressable accessibilityRole="button" onPress={toggleOpen}>
      <Box
        borderBottomWidth={last || !actions ? undefined : "strong"}
        borderColor="default.border.base"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        paddingLeft="sm"
      >
        <Box marginVertical="md">
          <Box flexDirection="row" alignItems="flex-start">
            <Box paddingRight="sm">
              {typeof status === "string" ? (
                <Status size={25} status={status} key={`${status}${index}`} />
              ) : (
                <Box
                  borderColor="default.border.base"
                  backgroundColor="interact.icon.base"
                  borderWidth="strong"
                  borderRadius="lg"
                  height={20}
                  width={20}
                  key={`$default${index}`}
                />
              )}
            </Box>
            {collapsible && (
              <Box paddingRight="sm">
                <Icon name={open ? "CaretUp" : "CaretDown"} />
              </Box>
            )}
            {numbered && (
              <Box marginRight="md">
                <Text size="p1" weight="semiBold">{`${index + 1})`}</Text>
              </Box>
            )}
            <Box flexDirection="column">
              <Box flexDirection="row" paddingBottom="xs">
                <Text size="p1" weight="semiBold" numberOfLines={1}>
                  {title}
                </Text>
                <Box marginLeft="sm">{tag && <Tag>{tag}</Tag>}</Box>
              </Box>
              {subtitle && (
                <Text numberOfLines={1} color="default.text.medium.base">
                  {subtitle}
                </Text>
              )}
            </Box>
          </Box>
          {open && hasAdditionalContent && (
            <ExpandedContent paragraphText={paragraphText} actions={actions} />
          )}
        </Box>
      </Box>
    </Pressable>
  );
}

export function ActivityTimeline({ activity, title, numbered, ...rest }: ActivityTimelineProps) {
  const [open, setIsOpen] = useState<boolean[]>(new Array(activity.length).fill(false));

  // as per magnetic, if any of the steps has additional content to display, we want to
  // make all of them expanadable for consistency's sake
  const collapsible = !!activity.find((step) => step.paragraphText || step.actions?.length);

  return (
    <Card {...rest}>
      {title && <Card.Header title={`${title}`} />}
      {activity.length === 0 ? (
        <EmptyState />
      ) : (
        <Box flexDirection="column" flex={1}>
          {activity.map((activityStep, index) => {
            return (
              <ActivityStep
                activityStep={activityStep}
                numbered={numbered}
                index={index}
                last={index === activity.length - 1}
                open={open[index]}
                collapsible={collapsible}
                toggleOpen={() => {
                  const newArray = [...open];
                  newArray[index] = !open[index];
                  setIsOpen(newArray);
                }}
              />
            );
          })}
        </Box>
      )}
    </Card>
  );
}
