import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

import { SPACING } from "~/constants/MkiConstants";
import TopClientsList from "~/go/components/TopClientsList";
import { clientWordForCount } from "~/lib/formatHelper";
import { filteredClients } from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import { GoStatus } from "~/shared/constants/Status";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { ExtendedClient } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";
import { basicMapDispatchToProps } from "~/store";

const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

type ReduxProps = {
  clients: ExtendedClient[];
};

type SsidClientUsageSummaryProps = {
  filter?: () => void;
  loading?: boolean;
  navigate: (screen: string, params: Record<string, any>) => void;
  ssidNumber?: number;
  testID?: string;
};

type Props = ReduxProps & SsidClientUsageSummaryProps;

class SsidClientUsageSummary extends PureComponent<Props> {
  static defaultProps = {
    filter: CUSTOM_FILTERS.DEFAULT,
    loading: false,
    ssidNumber: -1,
  };

  pushFilteredClientsList() {
    const { filter, ssidNumber, navigate } = this.props;
    navigate("ClientList", {
      title: I18n.t("CLIENTS_LIST.TITLE", { client_word: I18n.t("DEVICES_WORD") }),
      customFilter: filter,
      ssidNumber,
    });
  }

  render() {
    const { clients, loading, navigate, ssidNumber, filter, testID } = this.props;

    if (loading) {
      return renderLoading();
    }

    const numHighUsageClients = CUSTOM_FILTERS.HIGH_USAGE_CLIENT(clients).length;
    return (
      <View>
        <View style={styles.quickStatusBoxes}>
          <QuickStatusBox
            value={numHighUsageClients}
            subText={`${I18n.t("CLIENTS_OVERVIEW.HIGH")} ${clientWordForCount(
              numHighUsageClients,
            )}`}
            status={numHighUsageClients === 0 ? null : GoStatus.bad}
            onPress={
              numHighUsageClients === 0
                ? null
                : () =>
                    navigate("ClientList", {
                      title: `${I18n.t("CLIENTS_OVERVIEW.HIGH")} ${clientWordForCount(
                        numHighUsageClients,
                      )}`,
                      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                      customFilter: (c) => CUSTOM_FILTERS.HIGH_USAGE_CLIENT(filter(c)),
                      ssidNumber,
                    })
            }
          />
          <QuickStatusBox
            value={clients.length}
            subText={`${I18n.t("CLIENTS_OVERVIEW.UNIQUE")} ${clientWordForCount(clients.length)}`}
            onPress={clients.length === 0 ? null : () => this.pushFilteredClientsList()}
          />
        </View>
        <TopClientsList
          filter={filter}
          maxClientsToDisplay={3}
          prioritizeOnlineClients
          navigate={navigate}
          ssidNumber={ssidNumber}
          renderAllClientsButton
          testID={testID}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  quickStatusBoxes: {
    width: "100%",
    justifyContent: "space-around",
    flexDirection: "row",
    marginTop: SPACING.small,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
});

const mapStateToProps = () => {
  return (state: RootState, props: SsidClientUsageSummaryProps): ReduxProps => ({
    clients: filteredClients(state, { customFilter: props.filter }),
  });
};

export default connect(mapStateToProps, basicMapDispatchToProps)(SsidClientUsageSummary);
