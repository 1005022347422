import {
  DELETE_SSID_REQUEST,
  GET_UMBRELLA_STATUS_REQUEST,
  INVENTORY_REMOVE_REQUEST,
  LT_REQUEST,
  NETWORK_CLAIM_DEVICE_REQUEST,
  ONBAORDING_BATCH_CLAIM_REQUEST,
  SET_SSID_REQUEST,
  SSIDS_REQUEST,
  UDG_NODES_STATUS_REQUEST,
  UMBRELLA_ACTIVITIES_FOR_IP_REQUEST,
  UMBRELLA_ACTIVITY_REQUEST,
  UMBRELLA_HIGH_USAGE_IPS_REQUEST,
  UMBRELLA_PROTECTION_GET_REQUEST,
  UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST,
  UMBRELLA_TOTAL_REQUESTS_REQUEST,
  UPDATE_DEVICE_REQUEST,
} from "@meraki/shared/redux";

export const TRANSACTIONS_TO_SAVE = {
  [SSIDS_REQUEST]: true,
  [SET_SSID_REQUEST]: true,
  [DELETE_SSID_REQUEST]: true,
  [INVENTORY_REMOVE_REQUEST]: true,
  [UPDATE_DEVICE_REQUEST]: true,
  [UDG_NODES_STATUS_REQUEST]: true,
  [ONBAORDING_BATCH_CLAIM_REQUEST]: true,
  [NETWORK_CLAIM_DEVICE_REQUEST]: true,
  [UMBRELLA_ACTIVITY_REQUEST]: true,
  [UMBRELLA_TOTAL_REQUESTS_REQUEST]: true,
  [UMBRELLA_HIGH_USAGE_IPS_REQUEST]: true,
  [UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST]: true,
  [UMBRELLA_ACTIVITIES_FOR_IP_REQUEST]: true,
  [GET_UMBRELLA_STATUS_REQUEST]: true,
  [UMBRELLA_PROTECTION_GET_REQUEST]: true,
  [LT_REQUEST]: true,
};
