import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { isNil } from "lodash";

type UseDependentQueryOptions<
  Deps extends readonly [...any],
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn"> & {
  dependencies: Deps;
  queryFn: (
    q: QueryFunctionContext<TQueryKey>,
  ) => (
    ...args: { [I in keyof Deps]: NonNullable<Deps[I]> }
  ) => TQueryFnData | Promise<TQueryFnData>;
};

/**
 * Since it is impossible to link the existence of the parameter types in dependent queries
 * (i.e. the truthiness of the enabled option cannot assert the presence of query data)
 * the appropriate way to handle the eventuality is to reject the promise in your queryFn
 * This helper encapsulates that behavior.
 * @param opts standard UseQueryOptions with two disctinctions: the presence of a dependencies array,
 * and the requirement that the query fn is a thunked function that takes the values of that array
 * stripped of undefined
 * The dependencies option must be passed `as const` to get proper typing on heterogeneous tuples
 */
export const useDependentQuery = <
  Deps extends readonly [...any],
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  opts: UseDependentQueryOptions<Deps, TQueryFnData, TError, TData, TQueryKey>,
) => {
  const depsPresent = !opts.dependencies.some(isNil);
  const enabled = depsPresent && (isNil(opts.enabled) ? true : opts.enabled);
  return useQuery({
    ...opts,
    enabled,
    queryFn: enabled
      ? // This any assertion is where we have to do some sleight of hand since we have no
        // way of telling TS that our runtime check via enabled has made sure all members of the
        // array are defined
        (ctx) => opts.queryFn(ctx)(...(opts.dependencies as any))
      : () => {
          return Promise.reject(
            new Error(
              `Dependent query triggered while dependency was null or undefined: ${JSON.stringify({
                dependencies: opts.dependencies,
                enabledOpt: opts.enabled,
              })}. This should never happen and something has gone wrong with the useDependentQuery util.`,
            ),
          );
        },
  });
};

/**
 * In our custom hooks the key or query fn should not be able to be exposed as a parameter to the
 * calling component as those are intrinsic to the hook. As such, use this type when accepting
 * modifying parameters to your hooks.
 */
export type UseQueryOptionsWithoutFnOrKey<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn" | "queryKey">;
