import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { createRef, useCallback, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useFormattedTopology } from "~/api/queries/topology/useFormattedTopology";
import NextStepsMessage from "~/go/components/NextStepsMessage";
import TopologyLayer from "~/go/components/topology/TopologyLayer";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import { getNodeHierarchyByLinks } from "~/lib/TopologyUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import ViewPager from "~/shared/components/viewPager/ViewPager";
import { CloseButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "Topology">;

const TopologyScreen = () => {
  const networkId = useCurrentNetworkId();
  const formatedTopologyQuery = useFormattedTopology({ networkId });

  const { rootIds, nodes, links, portMappings } = formatedTopologyQuery.data ?? {};
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  if (formatedTopologyQuery.error) {
    showAlert(I18n.t("ERROR"), formatedTopologyQuery.error);
  }

  const nodeHierarchy = getNodeHierarchyByLinks(links, rootIds);
  const maxLayerIndex = nodeHierarchy.length - 1;
  const pagerRefs = useRef(nodeHierarchy.map(() => createRef<ViewPager>()));
  const [nodeIndexes, setNodeIndexes] = useState(nodeHierarchy.map(() => 0));

  const [lastVisibleLayerIndex, setLastVisibleLayerIndex] = useState(
    nodeHierarchy.reduce((_lastVisibleLayerIndex, nodeIds, layerIndex) => {
      if (_lastVisibleLayerIndex === layerIndex) {
        const firstNodeInLayer = nodeIds[0];

        if (isEmpty(links?.[layerIndex]?.[firstNodeInLayer])) {
          _lastVisibleLayerIndex++;
        }
      }

      return _lastVisibleLayerIndex;
    }, 0),
  );

  const onPageSelected = useCallback(
    (layerIndex: number, nodeIndex: number) => {
      const parentLayerIndex = layerIndex - 1;
      const childLayerIndex = layerIndex + 1;
      const currentNodeId = nodeHierarchy[layerIndex][nodeIndex];

      setNodeIndexes((prevIndexes) => {
        const newNodeIndexs = [...prevIndexes];
        newNodeIndexs[layerIndex] = nodeIndex;
        return newNodeIndexs;
      });

      if (parentLayerIndex >= 0) {
        const parentNodeIndex = nodeIndexes[parentLayerIndex];
        const parentNodeId = nodeHierarchy[parentLayerIndex][parentNodeIndex];
        const newParentNodeId = Object.entries(links?.[parentLayerIndex] ?? {}).find(
          ([_, childrenNodeIds]) => childrenNodeIds.includes(currentNodeId),
        )?.[0];

        if (parentNodeId != null && newParentNodeId !== parentNodeId) {
          const newParentNodeIndex = nodeHierarchy[parentLayerIndex].findIndex(
            (nodeId) => nodeId === newParentNodeId,
          );

          if (nodeIndexes[parentLayerIndex] !== newParentNodeIndex) {
            pagerRefs?.current?.[parentLayerIndex]?.current?.setPage(newParentNodeIndex);
          }
        }
      }

      if (childLayerIndex <= maxLayerIndex) {
        const childNodeIndex = nodeIndexes[childLayerIndex];
        const childNodeId = nodeHierarchy[childLayerIndex][childNodeIndex];
        const childNodeIds = links?.[layerIndex]?.[currentNodeId] ?? [];

        let showChildLayer = true;
        if (isEmpty(childNodeIds)) {
          showChildLayer = false;
        } else if (!childNodeIds.includes(childNodeId)) {
          const newChildNodeIndex = nodeHierarchy[childLayerIndex].findIndex(
            (childNodeId) => childNodeId === childNodeIds[0],
          );

          if (nodeIndexes[childLayerIndex] !== newChildNodeIndex) {
            pagerRefs.current?.[childLayerIndex]?.current?.setPage(newChildNodeIndex);
            setNodeIndexes((prevIndexes) => {
              const newNodeIndexs = [...prevIndexes];
              newNodeIndexs[childLayerIndex] = newChildNodeIndex;
              return newNodeIndexs;
            });
          }
        }

        setLastVisibleLayerIndex(showChildLayer ? childLayerIndex : layerIndex);
      }
    },
    [links, maxLayerIndex, nodeHierarchy, nodeIndexes],
  );

  const renderBody = () => {
    if (formatedTopologyQuery.isLoading) {
      return <LoadingSpinner visible={formatedTopologyQuery.isLoading} />;
    }

    const layersToRender = nodeHierarchy.filter(
      (_, layerIndex) => layerIndex <= lastVisibleLayerIndex,
    );

    if (isEmpty(layersToRender)) {
      return <NextStepsMessage message={I18n.t("TOPOLOGY.NO_DATA")} />;
    } else {
      return layersToRender.map((currentLayer, layerIndex) => (
        <View testID={`LAYER.VIEW - ${layerIndex}`} key={`LAYER.VIEW - ${layerIndex}`}>
          <TopologyLayer
            layer={currentLayer}
            layerIndex={layerIndex}
            maxLayerIndex={maxLayerIndex}
            onPageSelected={onPageSelected}
            pagerRefs={pagerRefs}
            nodeIndexes={nodeIndexes}
            links={links}
            nodes={nodes}
            portMappings={portMappings}
            testID={`LAYER - ${layerIndex}`}
            key={`LAYER - ${layerIndex}`}
          />
        </View>
      ));
    }
  };

  return (
    <FullScreenContainerView testID="TOPOLOGY_SCREEN">
      <ViewPager passOrientation="vertical" style={styles.flex}>
        {renderBody()}
      </ViewPager>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  flex: {
    flexGrow: 1,
  },
});

export default TopologyScreen;
