import Svg, { Path } from "react-native-svg";

function MagneticSecurityIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        opacity="0.4"
        d="M20 14V5.65753C20 5.2575 19.7616 4.89787 19.3939 4.74029L14.3939 2.59743C13.7341 2.31463 13 2.79866 13 3.51658V20.4835C13 21.2014 13.7258 21.665 14.3499 21.3102C16.3841 20.1541 20 17.5936 20 14Z"
        fill="#8F8F8F"
      />
      <Path
        d="M4 5.65752V14C4 17.5936 7.61593 20.1541 9.65009 21.3102C10.2742 21.665 11 21.2014 11 20.4835V3.51658C11 2.79866 10.2659 2.31463 9.60608 2.59744L4.60608 4.74029C4.2384 4.89787 4 5.2575 4 5.65752Z"
        fill="#8F8F8F"
      />
    </Svg>
  );
}

export default MagneticSecurityIcon;
