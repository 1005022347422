import { DefaultTimespansKeys } from "@meraki/shared/redux";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  ENGAGEMENT_CARD_COLORS,
  getEngagementCardDataKeys,
  getLoyaltyCardDataKeys,
  getProximityCardDataKeys,
  LOYALTY_CARD_COLORS,
  PROXIMITY_CARD_COLORS,
} from "~/constants/LocationAnalyticsConstants";
import { MS_IN_A_SECOND, SECONDS_IN_A_DAY } from "~/constants/MkiConstants";
import EngagementDataCard from "~/go/components/locationAnalytics/EngagementDataCard";
import LoyaltyDataCard from "~/go/components/locationAnalytics/LoyaltyDataCard";
import ProximityDataCard from "~/go/components/locationAnalytics/ProximityDataCard";
import {
  DataKeysPair,
  EngagementCardData,
  LoyaltyCardData,
  ProximityCardData,
} from "~/go/types/LocationAnalyticsTypes";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import {
  generateColoredDataKeys,
  labelEngagementSummaryData,
  labelLoyaltySummaryData,
  labelProximitySummaryData,
  translateEngagementData,
  translateLoyaltyData,
  translateProximityData,
} from "~/lib/LocationAnalyticsUtils";
import {
  calculateTimespanForPresence,
  convertToUTCEndOfDay,
  convertToUTCStartOfDay,
  getEndOfYesterdayAsDate,
  getStartOfYesterdayAsDate,
} from "~/lib/timeHelper";
import {
  engagementCardDataSelector,
  isLocationAnalyticsEnabled,
  loyaltyCardDataSelector,
  proximityCardDataSelector,
} from "~/selectors";
import MkiDateRangeText from "~/shared/components/MkiDateRangeText";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import TimePicker from "~/shared/components/TimePicker";
import { CloseButton } from "~/shared/navigation/Buttons";
import { ColorOptions, DataKey, DataKeyColors } from "~/shared/types/MkiChartTypes";
import { RootState } from "~/shared/types/Redux";
import { DateRange } from "~/shared/types/TimeTypes";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { NetworkScreensPropMap } from "../navigation/Types";

export interface LocationAnalyticsScreenProps {
  ssidName?: string;
  proximityData: ProximityCardData | null;
  engagementData: EngagementCardData | null;
  loyaltyData: LoyaltyCardData | null;
  isLocationAnalyticsEnabled: boolean;
  comparedProximityData?: ProximityCardData;
  comparedEngagementData?: EngagementCardData;
  comparedLoyaltyData?: LoyaltyCardData;
  initialTimespan?: number;
}

type ReduxProps = {
  proximityData: ProximityCardData | null;
  engagementData: EngagementCardData | null;
  loyaltyData: LoyaltyCardData | null;
  isLocationAnalyticsEnabled: boolean;
  comparedProximityData?: ProximityCardData;
  comparedEngagementData?: EngagementCardData;
  comparedLoyaltyData?: LoyaltyCardData;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "LocationAnalytics"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

interface LocationAnalyticsScreenState {
  timespan: number;
  dateRange: DateRange;
  comparedDateRange?: DateRange;
  comparisonTimespan?: DefaultTimespansKeys;
  proximityDataKeys: DataKeysPair;
  engagementDataKeys: DataKeysPair;
  loyaltyDataKeys: DataKeysPair;
}

const timespanOrDatesDidChange = (
  prevState: LocationAnalyticsScreenState,
  state: LocationAnalyticsScreenState,
) => {
  const { timespan, dateRange } = state;
  const { startDate, endDate } = dateRange;
  const timespanDiffers = prevState.timespan !== timespan;
  const startDateDiffers = prevState.dateRange.startDate !== startDate;
  const endDateDiffers = prevState.dateRange.endDate !== endDate;
  return timespanDiffers || startDateDiffers || endDateDiffers;
};

export class LocationAnalyticsScreenComponent extends PureComponent<
  Props,
  LocationAnalyticsScreenState
> {
  constructor(props: Props) {
    super(props);
    const defaultDateRange = {
      startDate: getStartOfYesterdayAsDate(),
      endDate: getEndOfYesterdayAsDate(),
    };
    this.state = {
      timespan: props.initialTimespan || SECONDS_IN_A_DAY,
      dateRange: defaultDateRange,
      comparedDateRange: defaultDateRange,
      proximityDataKeys: this.computeDataKeyPair(getProximityCardDataKeys(), PROXIMITY_CARD_COLORS),
      engagementDataKeys: this.computeDataKeyPair(
        getEngagementCardDataKeys(),
        ENGAGEMENT_CARD_COLORS,
      ),
      loyaltyDataKeys: this.computeDataKeyPair(getLoyaltyCardDataKeys(), LOYALTY_CARD_COLORS),
    };

    this.setNavOptions();
  }

  componentDidMount() {
    this.getData(true);
  }

  willAppear() {
    const { navigation, isLocationAnalyticsEnabled } = this.props;

    if (!isLocationAnalyticsEnabled) {
      navigation.goBack();
      return;
    }
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  componentDidUpdate(
    _prevProps: LocationAnalyticsScreenProps,
    prevState: LocationAnalyticsScreenState,
  ) {
    const { comparisonTimespan } = this.state;
    if (prevState.comparisonTimespan !== comparisonTimespan) {
      this.updateDataKeys();
    }
    if (timespanOrDatesDidChange(prevState, this.state)) {
      this.getData(true);
    }
  }

  setTimespan = (timespan: number) => {
    const { comparisonTimespan } = this.state;
    if (timespan && comparisonTimespan) {
      this.setState({ timespan, comparisonTimespan: undefined });
    } else {
      this.setState({ timespan });
    }
  };

  onRefresh = () => this.getData(false);

  getData = (shouldUseReqPending: boolean) => {
    const { actions, setReqPending } = this.props;
    const { timespan, dateRange } = this.state;

    const { t0, t1 } =
      timespan || !dateRange
        ? calculateTimespanForPresence(timespan)
        : {
            t0: convertToUTCStartOfDay(dateRange.startDate),
            t1: convertToUTCEndOfDay(dateRange.endDate),
          };

    if (shouldUseReqPending) {
      setReqPending(true);
    }

    return actions
      .getPresenceData(t0, t1)
      .then(() => {
        if (shouldUseReqPending) {
          setReqPending(false);
        }
      })
      .catch((error: string) => {
        if (shouldUseReqPending) {
          setReqPending(false);
        }
        showAlert(I18n.t("ERROR"), error);
      });
  };

  onStartDateConfirm = (date: Date) => {
    const { dateRange } = this.state;
    this.setState({ dateRange: { startDate: date, endDate: dateRange.endDate } });
  };

  onEndDateConfirm = (date: Date) => {
    const { dateRange } = this.state;
    this.setState({ dateRange: { startDate: dateRange.startDate, endDate: date } });
  };

  onConfirm = (
    dateRange: DateRange,
    comparedDateRange?: DateRange,
    comparisonTimespan?: DefaultTimespansKeys,
  ) => {
    this.setState({ dateRange, comparedDateRange, comparisonTimespan });
  };

  navigateToCustomDateScreen = () => {
    const { navigation } = this.props;
    const { dateRange, comparedDateRange, comparisonTimespan } = this.state;
    navigation.navigate("LocationAnalyticsDate", {
      initialDateRange: dateRange,
      initialComparedDateRange: comparedDateRange,
      initialComparisonTimespan: comparisonTimespan,
      onConfirm: this.onConfirm,
    });
  };

  renderCustomDateButton = () => {
    const { timespan, dateRange, comparedDateRange, comparisonTimespan } = this.state;
    if (timespan) {
      return null;
    }
    return (
      <MkiDateRangeText
        dateRange={dateRange}
        comparedDateRange={comparisonTimespan ? comparedDateRange : undefined}
        onPress={this.navigateToCustomDateScreen}
        testID="LocationAnalyticsScreen MkiDateRangeText"
      />
    );
  };

  computePrimaryDataKeys = (
    dataKeys: DataKey[],
    dataColors: DataKeyColors[],
    comparisonTimespan?: DefaultTimespansKeys,
  ) => {
    return comparisonTimespan
      ? generateColoredDataKeys(dataKeys, dataColors, ColorOptions.primaryCompared)
      : generateColoredDataKeys(dataKeys, dataColors);
  };

  computeSecondaryDataKeys = (
    dataKeys: DataKey[],
    dataColors: DataKeyColors[],
    comparisonTimespan?: DefaultTimespansKeys,
  ) => {
    return comparisonTimespan
      ? generateColoredDataKeys(dataKeys, dataColors, ColorOptions.secondaryCompared)
      : undefined;
  };

  computeDataKeyPair = (
    dataKeys: DataKey[],
    dataColors: DataKeyColors[],
    comparisonTimespan?: DefaultTimespansKeys,
  ) => ({
    dataKeys: this.computePrimaryDataKeys(dataKeys, dataColors, comparisonTimespan),
    comparedDataKeys: this.computeSecondaryDataKeys(dataKeys, dataColors, comparisonTimespan),
  });

  updateDataKeys = () => {
    const { comparisonTimespan } = this.state;
    this.setState({
      proximityDataKeys: this.computeDataKeyPair(
        getProximityCardDataKeys(),
        PROXIMITY_CARD_COLORS,
        comparisonTimespan,
      ),
      engagementDataKeys: this.computeDataKeyPair(
        getEngagementCardDataKeys(),
        ENGAGEMENT_CARD_COLORS,
        comparisonTimespan,
      ),
      loyaltyDataKeys: this.computeDataKeyPair(
        getLoyaltyCardDataKeys(),
        LOYALTY_CARD_COLORS,
        comparisonTimespan,
      ),
    });
  };

  computeTimespanForGraph = () => {
    const { timespan, dateRange } = this.state;
    const { startDate, endDate } = dateRange;
    return timespan ? timespan : (endDate.valueOf() - startDate.valueOf()) / MS_IN_A_SECOND;
  };

  renderNullCard = (Component: React.ClassType<any, any, any>, testID: string) => {
    return (
      <Component
        key={`${testID}-NullCard`}
        testID={testID}
        timespan={this.computeTimespanForGraph()}
      />
    );
  };

  renderProximityCard = (
    proximityData: ProximityCardData,
    comparedProximityData?: ProximityCardData,
  ) => {
    if (!proximityData) {
      return this.renderNullCard(ProximityDataCard, "ProximityDataCard");
    }
    const { proximityDataKeys } = this.state;
    const { dataKeys, comparedDataKeys } = proximityDataKeys;
    const { summaryData, graphData, domain } = proximityData;
    const translatedGraphData = translateProximityData(graphData);
    const labeledSummaryData = labelProximitySummaryData(summaryData);
    const comparedLabeledSummaryData = comparedProximityData
      ? labelProximitySummaryData(comparedProximityData.summaryData)
      : undefined;
    return (
      <ProximityDataCard
        testID="ProximityDataCard"
        summaryData={labeledSummaryData}
        graphData={translatedGraphData}
        dataKeys={dataKeys}
        comparedSummaryData={comparedLabeledSummaryData}
        comparedDataKeys={comparedDataKeys}
        domain={domain}
        timespan={this.computeTimespanForGraph()}
      />
    );
  };

  renderEngagementCard = (
    engagementData: EngagementCardData,
    comparedEngagementData?: EngagementCardData,
  ) => {
    if (!engagementData) {
      return this.renderNullCard(EngagementDataCard, "EngagementDataCard");
    }
    const { engagementDataKeys } = this.state;
    const { dataKeys, comparedDataKeys } = engagementDataKeys;
    const { summaryData, graphData, domain } = engagementData;
    const translatedGraphData = translateEngagementData(graphData);
    const labeledSummaryData = labelEngagementSummaryData(summaryData);
    const comparedLabeledSummaryData = comparedEngagementData
      ? labelEngagementSummaryData(comparedEngagementData.summaryData)
      : undefined;
    return (
      <EngagementDataCard
        testID="EngagementDataCard"
        summaryData={labeledSummaryData}
        graphData={translatedGraphData}
        dataKeys={dataKeys}
        comparedSummaryData={comparedLabeledSummaryData}
        comparedDataKeys={comparedDataKeys}
        domain={domain}
        timespan={this.computeTimespanForGraph()}
      />
    );
  };

  renderLoyaltyCard = (loyaltyData: LoyaltyCardData, comparedLoyaltyData?: LoyaltyCardData) => {
    if (!loyaltyData) {
      return this.renderNullCard(LoyaltyDataCard, "LoyaltyDataCard");
    }
    const { loyaltyDataKeys } = this.state;
    const { dataKeys, comparedDataKeys } = loyaltyDataKeys;
    const { summaryData, graphData, domain } = loyaltyData;
    const translatedGraphData = translateLoyaltyData(graphData);
    const labeledSummaryData = labelLoyaltySummaryData(summaryData);
    const comparedLabeledSummaryData = comparedLoyaltyData
      ? labelLoyaltySummaryData(comparedLoyaltyData.summaryData)
      : undefined;
    return (
      <LoyaltyDataCard
        testID="LoyaltyDataCard"
        summaryData={labeledSummaryData}
        graphData={translatedGraphData}
        dataKeys={dataKeys}
        comparedSummaryData={comparedLabeledSummaryData}
        comparedDataKeys={comparedDataKeys}
        domain={domain}
        timespan={this.computeTimespanForGraph()}
      />
    );
  };

  render() {
    const {
      proximityData,
      engagementData,
      loyaltyData,
      comparedProximityData,
      comparedEngagementData,
      comparedLoyaltyData,
    } = this.props;
    const { timespan } = this.state;

    return (
      <RefreshControlScrollView
        onRefresh={this.onRefresh}
        testID="LocationAnalyticsScreen ScrollView"
      >
        <TimePicker
          timespan={timespan}
          setTimespan={this.setTimespan}
          showCustom
          testID="LocationAnalyticsScreen TimePicker"
        />
        {this.renderCustomDateButton()}
        {proximityData && this.renderProximityCard(proximityData, comparedProximityData)}
        {engagementData && this.renderEngagementCard(engagementData, comparedEngagementData)}
        {loyaltyData && this.renderLoyaltyCard(loyaltyData, comparedLoyaltyData)}
      </RefreshControlScrollView>
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    proximityData: proximityCardDataSelector(state),
    engagementData: engagementCardDataSelector(state),
    loyaltyData: loyaltyCardDataSelector(state),
    isLocationAnalyticsEnabled: isLocationAnalyticsEnabled(state),
    // TODO: Update the selectors for the comparison mode
    comparedProximityData: undefined,
    comparedEngagementData: undefined,
    comparedLoyaltyData: undefined,
  };
}

export default compose<any>(
  withPendingComponent,
  connect(mapStateToProps, basicMapDispatchToProps),
)(LocationAnalyticsScreenComponent);
