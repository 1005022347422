import { I18n } from "@meraki/core/i18n";
import { Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";

export function ThemePreview() {
  return (
    <Box gap="sm">
      <Box>
        <Heading>{I18n.t("THEME_SELECT.SAMPLE.HEADER.TITLE")}</Heading>
        <Text size="p2" color="light">
          {I18n.t("THEME_SELECT.SAMPLE.HEADER.DESCRIPTION")}
        </Text>
      </Box>
      <Box
        backgroundColor="default.bg.base"
        padding="sm"
        borderColor="default.border.base"
        borderWidth="medium"
        borderRadius="md"
      >
        <Box
          backgroundColor="default.bg.weak.base"
          padding="sm"
          flexDirection="row"
          borderRadius="md"
        >
          <Box flex={1}>
            <Heading>{I18n.t("THEME_SELECT.SAMPLE.ROW.TITLE")}</Heading>
            <Text size="p2" color="light">
              {I18n.t("THEME_SELECT.SAMPLE.ROW.DESCRIPTION")}
            </Text>
          </Box>
          <Box>
            <Box flexDirection="row" gap="2xs" alignItems="center">
              <Status status="positive" />
              <Text size="p2">{I18n.t("THEME_SELECT.SAMPLE.ROW.POSITIVE")}</Text>
            </Box>
            <Box flexDirection="row" gap="2xs" alignItems="center">
              <Status status="warning" />
              <Text size="p2">{I18n.t("THEME_SELECT.SAMPLE.ROW.WARNING")}</Text>
            </Box>
            <Box flexDirection="row" gap="2xs" alignItems="center">
              <Status status="neutral" />
              <Text size="p2">{I18n.t("THEME_SELECT.SAMPLE.ROW.NEUTRAL")}</Text>
            </Box>
            <Box flexDirection="row" gap="2xs" alignItems="center">
              <Status status="negative" />
              <Text size="p2">{I18n.t("THEME_SELECT.SAMPLE.ROW.NEGATIVE")}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
