import { I18n } from "@meraki/core/i18n";

import { NumeralInternal } from "./NumeralInternal";
import { NumeralProps } from "./types";

const UNIT_MAP = {
  hour: "TIMEUNITS_SHORTHAND.HOUR",
  minute: "TIMEUNITS_SHORTHAND.MINUTE",
  second: "TIMEUNITS_SHORTHAND.SECOND",
  millisecond: "TIMEUNITS_SHORTHAND.MILLISECOND",
};

export type TimeNumeralProps = Omit<NumeralProps, "units"> & {
  units: keyof typeof UNIT_MAP;
};

export function TimeNumeral({ units, ...rest }: TimeNumeralProps) {
  const reslovedUnit = Object.keys(UNIT_MAP).includes(units) ? I18n.t(UNIT_MAP[units]) : undefined;

  if (!reslovedUnit) {
    return null;
  }

  return <NumeralInternal {...rest} units={reslovedUnit} />;
}
