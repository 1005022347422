import { I18n } from "@meraki/core/i18n";
import { List } from "@meraki/magnetic/components";
import { SwitchPort } from "@meraki/shared/api";

interface VlanInfoCardProps {
  switchPort: SwitchPort;
}

export const VlanInfoCard = ({ switchPort }: VlanInfoCardProps) => {
  const mode =
    switchPort.type === "trunk"
      ? I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NAME")
      : I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.NAME");

  return (
    <List label={I18n.t("PORTS.VLAN_INFO.TITLE")} testID="VLAN_INFO_CARD">
      <List.Item title={I18n.t("PORTS.VLAN_INFO.PORT_MODE")} value={mode} />
      <List.Item
        title={
          switchPort.type === "trunk"
            ? I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NATIVE_VLAN")
            : I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.VLAN")
        }
        value={switchPort.vlan ?? I18n.t("NONE")}
      />
      {switchPort.type === "trunk" ? (
        <List.Item
          title={I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.ALLOWED_VLANS")}
          value={switchPort.allowedVlans}
        />
      ) : (
        <List.Item
          title={I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.VOICE_VLAN")}
          value={switchPort.voiceVlan ?? I18n.t("NONE")}
        />
      )}
    </List>
  );
};
