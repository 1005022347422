import {
  ADD_NODES_SUCCESS,
  LOGIN_SUCCESS,
  NODES_SUCCESS,
  ONBAORDING_BATCH_CLAIM_SUCCESS,
  ONBOARDING_ADD_NODE,
  ONBOARDING_SET_SKIPPED,
  ONBOARDING_SET_STAGE,
  SET_REDUX_SSIDS,
} from "@meraki/shared/redux";
import { Middleware } from "redux";

import { CUSTOM_DIMENSIONS, CUSTOM_METRICS } from "~/lib/AnalyticsTracker";
import {
  onboardingAddNodeActionEvent,
  onboardingTimeTakenPayload,
  scannedHardwareCountPayload,
} from "~/lib/AnalyticsUtils";
import { FIREBASE_EVENTS, sendMilestoneEvent, sendOnboardingEvent } from "~/lib/FirebaseUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import timer from "~/lib/TimerTracker";
import { onboardingIsComplete, onboardingStage } from "~/selectors";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";

const analyticsMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  timer.trackTimer(action);

  switch (action.type) {
    case ADD_NODES_SUCCESS:
    case ONBAORDING_BATCH_CLAIM_SUCCESS:
      sendMilestoneEvent(FIREBASE_EVENTS.hardwareAdded);
      return next(action);
    case NODES_SUCCESS: {
      const modelCountPayload = getMetricsAndModelCounts(getModelCountsFromAction(action));
      if (modelCountPayload) {
        sendMilestoneEvent(FIREBASE_EVENTS.hardwareFetched, {
          customDimensions: {
            [CUSTOM_DIMENSIONS.model]: "model",
          },
          customMetrics: { ...modelCountPayload },
        });
      }
      return next(action);
    }
    case SET_REDUX_SSIDS:
      sendMilestoneEvent(FIREBASE_EVENTS.ssidUpdated);
      return next(action);
    case LOGIN_SUCCESS:
      sendMilestoneEvent(FIREBASE_EVENTS.loginSuccess);
      return next(action);
    case ONBOARDING_ADD_NODE:
      const actionEvent = onboardingAddNodeActionEvent(store.getState(), action.serial);
      if (actionEvent) {
        sendOnboardingEvent(actionEvent);
      }
      return next(action);
    case ONBOARDING_SET_SKIPPED:
      if (action.skipped) {
        const state = store.getState();
        const payload = onboardingTimeTakenPayload(state);
        const currentStage = onboardingStage(state);

        if (payload) {
          sendOnboardingEvent(FIREBASE_EVENTS.skipped(currentStage), payload);
        }
      }
      return next(action);
    case ONBOARDING_SET_STAGE:
      if (action.stage === OnboardingStage.scannedHardware) {
        const state = store.getState();
        const isComplete = onboardingIsComplete(state);
        if (isComplete) {
          return next(action);
        }

        const payload = scannedHardwareCountPayload(state);
        if (payload) {
          sendOnboardingEvent(FIREBASE_EVENTS.finishedAddHardware, payload);
        }
      } else if (action.stage === OnboardingStage.exit) {
        const state = store.getState();
        const isComplete = onboardingIsComplete(state);
        if (isComplete) {
          return next(action);
        }

        const payload = onboardingTimeTakenPayload(state);
        if (payload) {
          sendOnboardingEvent(FIREBASE_EVENTS.successful, payload);
        }
      }
      return next(action);
    default:
      return next(action);
  }
};

export default analyticsMiddleware;

// Takes an object of model numbers and counts, Ex. { MR74: 1, MR33: 1 }
// Returns a Google Analytics custom metrics payload, Ex. { 1: 1, 2: 1 }
function getMetricsAndModelCounts(payload: any) {
  const gaPayload = Object.keys(payload).reduce((counts, key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const value = CUSTOM_METRICS[key];
    if (!value) {
      return counts;
    }
    return {
      ...counts,
      [value]: payload[key],
    };
  }, {});
  return gaPayload;
}

// Returns an object consisting of model numbers and counts, Ex. { MR74: 1, MR33: 1 }
function getModelCountsFromAction(action: any) {
  const nodes = nestedValueExists(action, ["response", "entities", "nodes"], {});
  const modelCounts = Object.keys(nodes).reduce((counts, key) => {
    const { model } = nodes[key];
    if (!model) {
      return counts;
    }
    if (Object.prototype.hasOwnProperty.call(counts, model)) {
      return {
        ...counts,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [model]: counts[model] + 1,
      };
    }
    return {
      ...counts,
      [model]: 1,
    };
  }, {});
  return modelCounts;
}
