import { I18n } from "@meraki/core/i18n";
import { useUpdateVlan, useVlan, useVlans } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { showAlert } from "~/lib/AlertUtils";
import { validateIpRange } from "~/lib/IPAddressUtils";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";

export const ReserveIPRangeScreen = () => {
  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);

  const [comment, setComment] = useState("");
  const [startIp, setStartIp] = useState("");
  const [endIp, setEndIp] = useState("");
  const [reqPending, setReqPending] = useState(false);

  const { data: gxVlan } = useVlan({ networkId, vlanId });

  const updateVlan = useUpdateVlan();
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const updateVlanWithReservedIPRanges = useCallback(async () => {
    try {
      setReqPending(true);

      if (gxVlan) {
        const { applianceIp, subnet, reservedIpRanges } = gxVlan;

        const ipError = validateIpRange(startIp, endIp, subnet, applianceIp);
        if (ipError) {
          showAlert(I18n.t("ERROR"), ipError);
          return;
        }

        const updatedVlanData = {
          reservedIpRanges: [
            ...reservedIpRanges,
            {
              comment,
              start: startIp,
              end: endIp,
            },
          ],
        };

        await updateVlan.mutateAsync(
          { networkId, vlanId, vlan: updatedVlanData },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot });
              navigation.goBack();
            },
            onError: (error) => {
              showAlert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
            },
          },
        );
      }
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [comment, endIp, gxVlan, navigation, networkId, queryClient, startIp, updateVlan, vlanId]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={updateVlanWithReservedIPRanges} />,
    });
  }, [navigation, updateVlanWithReservedIPRanges]);

  return (
    <FullScreenContainerView withScroll defaultPadding>
      <MkiText screenStyles={styles.subtitleStyle}>{I18n.t("RESERVE_IP_RANGE.SUBTITLE")}</MkiText>
      <MkiText textStyle="smallSecondary" screenStyles={styles.commentTitle}>
        {I18n.t("NAME")}
      </MkiText>
      <UnderlinedTextInput
        placeholder={I18n.t("RESERVE_IP_RANGE.PLACEHOLDER")}
        value={comment}
        onChangeText={setComment}
        returnKeyType={RETURN_KEY.done}
        editable
        showClearButton
      />
      <View>
        <MkiText textStyle="smallSecondary" screenStyles={styles.ipAddressInputStyle}>
          {I18n.t("RESERVE_IP_RANGE.FIRST_IP")}
        </MkiText>
        <IPAddressTextInput
          cidrAddress={gxVlan?.subnet}
          onAddressChange={setStartIp}
          testID="FIRST_IP"
        />
      </View>
      <View>
        <MkiText textStyle="smallSecondary" screenStyles={styles.ipAddressInputStyle}>
          {I18n.t("RESERVE_IP_RANGE.LAST_IP")}
        </MkiText>
        <IPAddressTextInput
          cidrAddress={gxVlan?.subnet}
          onAddressChange={setEndIp}
          testID="LAST_IP"
        />
      </View>
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  subtitleStyle: {
    color: MkiColors.secondaryTextColor,
  },
  ipAddressInputStyle: {
    marginTop: SPACING.large,
  },
  commentTitle: {
    marginTop: SPACING.large,
  },
});

export default ReserveIPRangeScreen;
