import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { PokeDeviceStackProps } from "~/go/navigation/Types";
import PokeDeviceSelectionScreen from "~/go/screens/PokeDeviceSelectionScreen";
import { capitalizeFirstLetter } from "~/lib/formatHelper";

import PokeDevice from "../screens/liveTools/ping/PokeDevice";
import { useDefaultScreenOptions } from "./Options";

const ForwardedPokeDevice =
  withForwardedNavigationParams<ForwardedStackScreenProps<PokeDeviceStackProps, "PokeDevice">>()(
    PokeDevice,
  );
const ForwardedPokeDeviceSelectionScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<PokeDeviceStackProps, "PokeDeviceSelection">
  >()(PokeDeviceSelectionScreen);

const Stack = createNativeStackNavigator<PokeDeviceStackProps>();

const PokeDeviceStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="PokeDeviceSelection"
        component={ForwardedPokeDeviceSelectionScreen}
        options={{
          headerTitle: I18n.t("POKE_DEVICE.CHOOSE.TITLE", {
            client_word: capitalizeFirstLetter(I18n.t("DEVICE_WORD")),
          }),
        }}
      />
      <Stack.Screen
        name="PokeDevice"
        component={ForwardedPokeDevice}
        options={{ headerTitle: I18n.t("POKE_DEVICE.TITLE") }}
      />
    </Stack.Navigator>
  );
};

export default PokeDeviceStack;
