import { Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import SvgChart from "@wuba/react-native-echarts/svgChart";
import Color from "color";
import * as echarts from "echarts/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

export type TreeDatum = {
  name: string; // shows up as label
  value?: number | string; // shows up on tooltip
  symbol?: string;
  lineStyle?: { color?: string };
  itemStyle?: { color?: string };
  children?: TreeDatum[];
};

type ChartSize = "sm" | "md" | "lg";
interface TreeProps {
  testID?: string;
  size?: ChartSize;
  data: TreeDatum;
  orientation?: "horizontal" | "vertical";
  loading?: boolean;
}

const getAspectRatio = (width: number, size: ChartSize) => {
  switch (size) {
    case "sm":
      return 0.5;
    case "md":
      return 0.8;
    case "lg":
      return 1;
  }
};

export function Tree({
  testID,
  size = "md",
  data,
  loading = false,
  orientation = "horizontal",
}: TreeProps) {
  const theme = useMagneticTheme();
  const chartRef = useRef(null);
  const [chartInstance, setChart] = useState<echarts.EChartsType | null>(null);

  const [width, setWidth] = useState<number | null>(null);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width * 2);
    },
    [setWidth],
  );

  // This effect is responsible for the basic setup of the chart instance.
  useEffect(() => {
    if (!width) return;

    const chart = echarts.init(chartRef.current, "light", {
      renderer: "svg",
      width: !!width && width > 0 ? width : undefined,
      height: !!width && width > 0 ? width * getAspectRatio(width, size) : undefined,
    });

    setChart(chart);

    return () => chart.dispose();
  }, [size, width]);

  // This effect is responsible for setting the data related options
  useEffect(() => {
    chartInstance?.setOption({
      tooltip: {
        trigger: "item",
        formatter: "{c}", // display item value,
        position: "top",
      },
      series: [
        {
          type: "tree",
          data: [data],
          left: "30%",
          zoom: 2,
          orient: orientation,
          roam: true, // free to move / zoom
          initialTreeDepth: -1, // expand all
          expandAndCollapse: true,
          animationDurationUpdate: 750,
          symbolSize: 12,
          label: {
            position: "left",
            verticalAlign: "middle",
            align: "right",
            distance: 1,
          },
          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
              distance: 1,
            },
          },
        },
      ],
      label: {
        color: theme.color.default.text.base,
      },
    });
  }, [chartInstance, theme, data, orientation]);

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <SvgChart ref={chartRef} useRNGH />
      {loading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Color(theme.color.default.bg.weak.base).alpha(0.8).hexa(),
          }}
        >
          <Loader.Spinner />
        </View>
      )}
    </Box>
  );
}
