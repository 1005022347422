import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  List,
  RefreshControl,
} from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { APBanner, useAPBanner } from "@meraki/shared/access-point-placement";
import {
  ProductType,
  useClaimDevices,
  useDeviceBySerial,
  useDeviceStatuses,
  useInventoryDevices,
  useOrgNetworks,
} from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import {
  AddSerialModal,
  DEVICE_STATUS_MAP,
  DeviceTopologyTree,
  getProductType,
  getProductTypeTranslation,
  GoProductTypes,
  SerialScanner,
} from "@meraki/shared/devices";
import { getDeviceSearchResults } from "@meraki/shared/filters";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import _ from "lodash";
import { capitalize, isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import Toast from "react-native-simple-toast";

export function DevicesListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "DevicesList">>();
  const { params: props } = route;
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);
  const actionBottomSheetRef = useRef<BottomSheetMethods>(null);
  const serialScannerBottomSheetRef = useRef<BottomSheetMethods>(null);
  const assignBottomSheetRef = useRef<BottomSheetMethods>(null);
  const topologyBottomSheetRef = useRef<BottomSheetMethods>(null);

  const {
    data: inventoryDevices,
    isLoading,
    isFetching,
    refetch,
    isRefetching,
  } = useInventoryDevices(
    {
      organizationId,
      networkIds: networkId ? [networkId] : undefined,
    },
    {
      select(data) {
        return networkId ? data.filter((device) => device.networkId === networkId) : data;
      },
    },
  );

  const {
    data: devices,
    refetch: refetchDevices,
    isRefetching: devicesIsRefetching,
  } = useDeviceStatuses({
    organizationId,
    networkIds: networkId ? [networkId] : undefined,
  });

  const { untestedAPs } = useAPBanner();

  const untestedAPdevices = devices?.filter((device) => untestedAPs.includes(device.serial));

  const showAPBanner =
    untestedAPs.length > 0 && !untestedAPdevices?.some((device) => device.status !== "online");

  const deviceMap = _.keyBy(devices, "serial");

  const [serial, setSerial] = useState("");
  const [scannedSerial, setScannedSerial] = useState("");

  const { data: networks } = useOrgNetworks({ organizationId });

  const networkMap = _.keyBy(networks, "id");
  const { mutate: claimDevices, isLoading: isClaimingDevices } = useClaimDevices();

  const { data: scannedDevice } = useDeviceBySerial(
    { serial: scannedSerial },
    { enabled: Boolean(scannedSerial) },
  );

  const { addUntestedAp } = useAPBanner();

  if (getProductType(scannedDevice?.model) === "wireless") {
    addUntestedAp(scannedSerial);
    setScannedSerial("");
  }

  const processClaim = (serial: string) => {
    if (networkId) {
      claim(serial, networkId);
    } else {
      setSerial(serial);
      assignBottomSheetRef.current?.present();
    }
  };

  const claim = (serial: string, networkId: string) => {
    claimDevices(
      {
        networkId,
        serials: [serial],
      },
      {
        onSuccess: () => {
          refetch();
          Toast.showWithGravity(
            I18n.t("DEVICES.ADD_SUCCESS_TEXT", { single_device_word: I18n.t("DEVICE_WORD") }),
            Toast.SHORT,
            Toast.BOTTOM,
          );
          setScannedSerial(serial);
          setSerial("");
        },
        onError: (error) => showErrorAlert(String(error?.errors)),
        onSettled: () => {
          refetch();
          assignBottomSheetRef.current?.dismiss();
        },
      },
    );
  };

  const [productTypeFilters, setProductTypeFilters] = useState<ProductType[]>(
    props?.productTypeFilters ?? [],
  );
  const [searchText, setSearchText] = useState("");
  const [filteredDevices, setFilteredDevices] = useState(inventoryDevices);

  const searchedDevices = getDeviceSearchResults(filteredDevices, searchText);
  const [isSerialModalOpen, setIsSerialModalOpen] = useState(false);

  const updateFilteredDeviceWithTypeFilter = useCallback(
    ({ productTypes }: { productTypes: ProductType[] }) => {
      if (isEmpty(productTypes)) {
        setFilteredDevices(inventoryDevices);
      } else {
        setFilteredDevices(
          inventoryDevices?.filter((device) => productTypes.includes(device.productType)),
        );
      }
      setProductTypeFilters(productTypes);
    },
    [inventoryDevices],
  );

  useEffect(() => {
    if (!isLoading) {
      updateFilteredDeviceWithTypeFilter({ productTypes: productTypeFilters });
      navigation.setOptions({
        headerRight: () => (
          <>
            <Button.Icon icon="Stack" onPress={() => topologyBottomSheetRef.current?.present()} />
            <Button.Icon icon="Plus" onPress={() => actionBottomSheetRef.current?.present()} />
          </>
        ),
      });
    }
  }, [isLoading, navigation, productTypeFilters, updateFilteredDeviceWithTypeFilter]);

  return (
    <Screen
      gap="none"
      refreshControl={
        <RefreshControl
          refreshing={isRefetching || devicesIsRefetching}
          onRefresh={() => {
            refetch();
            refetchDevices();
          }}
        />
      }
    >
      <Box flexDirection="row" paddingTop="sm" alignItems="center" gap="sm">
        <SearchFilterSection
          placeholder={I18n.t("SEARCH")}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFilterPress={() => filterBottomSheetRef.current?.present()}
        />
      </Box>
      <FilterTagList
        filters={{
          productTypes:
            productTypeFilters?.map((productType) => ({
              value: productType,
              label: capitalize(getProductTypeTranslation(productType)),
            })) ?? [],
        }}
        onUpdateFilters={updateFilteredDeviceWithTypeFilter}
      />
      {showAPBanner && (
        <Box padding="sm">
          <APBanner />
        </Box>
      )}
      <List.FlashList
        data={searchedDevices}
        onRefresh={refetch}
        emptyState={{
          title: inventoryDevices?.length
            ? I18n.t("HARDWARE_EMPTY_STATE.MESSAGE_SEARCH")
            : I18n.t("HARDWARE_EMPTY_STATE.MESSAGE"),
        }}
        getItemData={(device) => {
          return {
            title: device.name || device.serial || device.mac || "",
            leftAccessory: (
              <Status
                status={
                  device.networkId
                    ? DEVICE_STATUS_MAP[deviceMap[device.serial]?.status || "offline"]
                    : "neutral"
                }
              />
            ),
            description: device?.networkId
              ? networkMap[device.networkId]?.name
              : I18n.t("DEVICE_LIST.UNCLAIMED"),
            rightAccessory: !device.networkId && (
              <Button
                text={I18n.t("DEVICE_LIST.CLAIM")}
                kind="tertiary"
                onPress={() => {
                  setSerial(device.serial);
                  assignBottomSheetRef.current?.present();
                }}
              />
            ),
            onPress: device.networkId
              ? () => navigation.navigate("DeviceDetails", { serial: device.serial })
              : undefined,
          };
        }}
        groupBy={(device) => {
          return getProductTypeTranslation(device.productType);
        }}
        loading={isFetching || isLoading || isRefetching}
      />

      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          productTypes: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.PRODUCT_TYPE"),
            options: GoProductTypes?.map((pt) => ({
              value: pt,
              label: capitalize(getProductTypeTranslation(pt)),
            })),
          },
        }}
        initialFilters={{
          productTypes: productTypeFilters ?? [],
        }}
        onUpdateFilters={({ productTypes }) => {
          updateFilteredDeviceWithTypeFilter({ productTypes });
          setProductTypeFilters(productTypes);
        }}
      />
      <BottomSheet.Modal ref={actionBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Content>
          <List>
            <List.Item
              title={I18n.t("DEVICE_LIST.NEW_DEVICE_SERIAL")}
              testID="ENTER_DEVICE_SERIAL"
              onPress={() => {
                setIsSerialModalOpen(true);
                actionBottomSheetRef.current?.dismiss();
              }}
            />
            <List.Item
              title={I18n.t("DEVICE_LIST.NEW_DEVICE_BARCODE")}
              testID="SCAN_DEVICE_SERIAL"
              onPress={() => {
                serialScannerBottomSheetRef.current?.present();
                actionBottomSheetRef.current?.dismiss();
              }}
            />
          </List>
        </BottomSheet.Content>
      </BottomSheet.Modal>
      <AddSerialModal
        visible={isSerialModalOpen}
        loading={isClaimingDevices}
        onClose={() => setIsSerialModalOpen(false)}
        onSubmit={(serial) => {
          processClaim(serial);
          setIsSerialModalOpen(false);
        }}
      />
      <BottomSheet.Modal ref={serialScannerBottomSheetRef} snapPoints={["95%"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("DEVICE_LIST.NEW_DEVICE_BARCODE")}
          onCancelPress={() => serialScannerBottomSheetRef.current?.dismiss()}
        />
        <SerialScanner
          isProcessingSerial={isClaimingDevices}
          onSerialScan={(serial) => {
            processClaim(serial);
            serialScannerBottomSheetRef.current?.dismiss();
          }}
        />
      </BottomSheet.Modal>
      <BottomSheet.Modal ref={assignBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header title={I18n.t("DEVICES.ASSIGN")} />
        <BottomSheet.Content>
          <List.FlashList
            paddingRight="none"
            paddingLeft="none"
            paddingBottom="none"
            paddingTop="none"
            data={networks}
            getItemData={(network) => ({
              title: network.name,
              onPress: () => claim(serial, network.id),
            })}
            emptyState={{
              title: I18n.t("NETWORK_SUMMARY.EMPTY"),
            }}
          />
        </BottomSheet.Content>
      </BottomSheet.Modal>
      <BottomSheet.Modal ref={topologyBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("TOPOLOGY.TITLE")}
          onCancelPress={() => topologyBottomSheetRef.current?.dismiss()}
        />
        <BottomSheet.Content>
          <DeviceTopologyTree />
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
