import { CSRF_TOKEN_FAILURE, CSRF_TOKEN_REQUEST, CSRF_TOKEN_SUCCESS } from "@meraki/shared/redux";
import { merge } from "lodash";

import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { xMerakiUserAgentInfo } from "~/shared/constants/CustomUserAgent";
import { ApiAction, AppThunk } from "~/shared/types/Redux";

export function fetchCSRFToken(tempShardId?: number | null): ApiAction<{ csrf_token: string }> {
  return {
    [CALL_API]: {
      types: [CSRF_TOKEN_REQUEST, CSRF_TOKEN_SUCCESS, CSRF_TOKEN_FAILURE],
      endpoint: "/mobile/token",
      tempShardId: tempShardId?.toString(),
      config: {
        method: "POST",
        headers: {
          "X-Dashboard-Mobile-API-Level": "8",
          ...xMerakiUserAgentInfo,
          "X-Requested-With": "XMLHttpRequest",
        },
      },
    },
  } as const;
}

export function wrapApiActionWithCSRF<T = any>(
  action: ApiAction<T>[typeof CALL_API],
): AppThunk<Promise<ApiResponseAction<T>>> {
  return async (dispatch) => {
    const { response } = await dispatch(fetchCSRFToken());
    const headers = merge({}, action.config ? action.config.headers : {}, {
      "X-CSRF-Token": response.csrf_token,
    });
    const actionWithCSRFToken: ApiAction<T>[typeof CALL_API] = {
      ...action,
      config: {
        ...action.config,
        headers,
      },
    };

    return await dispatch({ [CALL_API]: actionWithCSRFToken } as ApiAction<T>);
  };
}
