import { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import MerakiIcon from "~/shared/components/icons";

type Props = {
  selected: boolean;
  screenStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

const CheckBox = memo(function CheckBox(props: Props) {
  const { selected, screenStyles, testID } = props;
  const boxStyle = [styles.square, screenStyles];
  if (selected) {
    boxStyle.push(styles.selected);
  }
  const optionalCheck = selected ? (
    <MerakiIcon
      name="check-thick"
      size="xs"
      color={MkiColors.whiteBackground}
      containerStyle={styles.iconStyle}
    />
  ) : null;
  return (
    <View
      style={boxStyle}
      testID={`${testID ? `${testID}_` : ""}CHECKBOX${selected ? "_SELECTED" : ""}`}
    >
      {optionalCheck}
    </View>
  );
});

const checkDimension = 20;

const styles = StyleSheet.create({
  square: {
    alignItems: "center",
    justifyContent: "center",
    width: checkDimension,
    height: checkDimension,
    borderWidth: 1,
    borderColor: MkiColors.disabledStatus,
    borderRadius: 2,
  },
  selected: {
    backgroundColor: MkiColors.primaryButton,
    borderWidth: 0,
    borderRadius: 2,
  },
  iconStyle: { alignSelf: "center" },
});

export default CheckBox;
