import { useTheme } from "@meraki/core/theme";
import { forwardRef, PureComponent } from "react";
import {
  ReturnKeyTypeOptions,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from "react-native";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { platformSelect } from "~/lib/PlatformUtils";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import { isString } from "~/lib/TypeHelper";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";

type InputRowProps = {
  testID?: string;
  clearTestID?: string;
  loginStyle?: boolean;
  value?: string;
  multiline?: boolean;
  multilineMaxHeight?: number;
  placeholder?: string;
  autoCapitalize?: "none" | "sentences" | "words" | "characters";
  autoCorrect?: boolean;
  keyboardType?: (typeof KEYBOARD_TYPE)[keyof typeof KEYBOARD_TYPE];
  onChangeText?: (text: string) => void;
  secureTextEntry?: boolean;
  revealable?: boolean;
  enablesReturnKeyAutomatically?: boolean;
  editable?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  context?: string;
  onSubmitEditing?: () => void;
  returnKeyType?: ReturnKeyTypeOptions;
  children: React.ReactNode;
  description?: string;
  enableBioAuth?: boolean;
  onBioAuthPress?: () => void;
  containerStyles?: StyleProp<ViewStyle>;
};

type InputRowState = {
  borderBottomColor: string;
};

export class InputRow extends PureComponent<InputRowProps, InputRowState> {
  static defaultProps = {
    testID: undefined,
    clearTestID: undefined,
    value: undefined,
    multiline: false,
    multilineMaxHeight: 200,
    loginStyle: false,
    placeholder: "",
    autoCapitalize: "none",
    autoCorrect: false,
    keyboardType: KEYBOARD_TYPE.default,
    secureTextEntry: false,
    revealable: false,
    enablesReturnKeyAutomatically: false,
    editable: true,
    theme: "light",
    onFocus: () => {
      /* do nothing */
    },
    onBlur: () => {
      /* do nothing */
    },
  };

  constructor(props: InputRowProps) {
    super(props);
    this.state = {
      borderBottomColor: this.inactiveInputUnderline(),
    };
  }

  onFocus = () => {
    const { onFocus } = this.props;
    this.setState({ borderBottomColor: MkiColors.activeInputUnderline });
    onFocus?.();
  };

  onBlur = () => {
    const { onBlur } = this.props;
    this.setState({ borderBottomColor: this.inactiveInputUnderline() });
    onBlur?.();
  };

  private textInput: TextInput | null = null;
  focus() {
    this.textInput?.focus();
  }

  inactiveInputUnderline() {
    return MkiColors.inactiveInputUnderline;
  }

  render() {
    const { borderBottomColor } = this.state;

    const {
      value,
      onChangeText,
      testID,
      loginStyle,
      multiline,
      multilineMaxHeight,
      placeholder,
      autoCapitalize,
      autoCorrect,
      keyboardType,
      secureTextEntry,
      revealable,
      enablesReturnKeyAutomatically,
      onSubmitEditing,
      returnKeyType,
      children,
      editable,
      clearTestID,
      description,
      enableBioAuth,
      onBioAuthPress,
      context,
      containerStyles,
    } = this.props;

    const { theme } = useTheme.getState();

    const headerTextColor = styles.defaultHeaderColor;
    const offset = loginStyle ? 0 : SPACING.default;
    const topMargin = sizeSelect({
      default: SPACING.large,
      small: loginStyle ? SPACING.small : SPACING.large,
    });
    const maxHeight = multiline ? multilineMaxHeight : null;
    const screenStyles: StyleProp<ViewStyle>[] = [
      styles.textInput,
      // @ts-expect-error TS(2322) FIXME: Type '{ color: string; } | undefined' is not assig... Remove this comment to see the full error message
      themeColors(theme).text ? themeColors(theme).text.default : undefined,
    ];
    if (!editable) {
      screenStyles.push(styles.disabledText);
    }
    if (loginStyle) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ color: SharedColors | GoColors... Remove this comment to see the full error message
      screenStyles.push(styles.loginColor);
    }

    const label = isString(children) ? (
      <MkiText textStyle="small" screenStyles={[headerTextColor, { paddingLeft: offset }]}>
        {children}
      </MkiText>
    ) : (
      children
    );

    return (
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      <View style={{ marginTop: topMargin, ...containerStyles }}>
        <View style={styles.labelContainer}>
          {context ? (
            <View style={styles.horizontalContainer}>
              {label}
              <ContextHelp context={context} />
            </View>
          ) : (
            label
          )}
          {description && (
            <MkiText textStyle="smallSecondary" screenStyles={{ paddingLeft: offset }}>
              {description}
            </MkiText>
          )}
        </View>
        <View
          style={[
            styles.textInputContainer,
            { marginHorizontal: offset },
            { borderBottomColor },
            { maxHeight },
          ]}
        >
          <MkiTextInput
            testID={testID}
            ref={(ref) => {
              this.textInput = ref;
            }}
            screenStyles={screenStyles}
            placeholder={placeholder}
            value={value}
            multiline={multiline}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            keyboardType={keyboardType}
            onChangeText={onChangeText}
            secureTextEntry={secureTextEntry}
            onFocus={this.onFocus}
            onSubmitEditing={onSubmitEditing}
            revealable={revealable}
            onBlur={this.onBlur}
            underlineColorAndroid="transparent"
            returnKeyType={returnKeyType}
            enablesReturnKeyAutomatically={enablesReturnKeyAutomatically}
            editable={editable}
            showClearButton={editable}
            clearTestID={clearTestID}
            enableBioAuth={enableBioAuth}
            onBioAuthPress={onBioAuthPress}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    padding: 0,
  },
  textInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
  },
  labelContainer: {
    marginTop: SPACING.small,
    marginBottom: SPACING.tiny,
  },
  defaultHeaderColor: {
    color: MkiColors.formFieldLabel,
  },
  disabledText: {
    opacity: 0.5,
  },
  loginColor: {
    color: MkiColors.textColor,
  },
  horizontalContainer: {
    ...platformSelect({
      ios: {
        marginEnd: SPACING.small,
        marginVertical: -SPACING.small,
      },
      android: {
        marginEnd: SPACING.default,
      },
      web: {
        marginEnd: SPACING.default,
      },
    }),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const InputRowWithRef = forwardRef<InputRow, InputRowProps>((props, ref) => (
  <InputRow {...props} ref={ref} />
));

InputRowWithRef.displayName = "InputRowWithRef";

export default InputRowWithRef;
