import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { StyleSheet } from "react-native";

import { CLIENT, SPACING } from "~/constants/MkiConstants";
import { clientOS } from "~/lib/ClientUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import SummaryList from "~/shared/components/SummaryList";
import CopyableTwoColumnRow from "~/shared/rows/CopyableTwoColumnRow";
import { ExtendedClient, NetworkClientType } from "~/shared/types/Client";

interface RowContent {
  label: string;
  value: string;
}

interface ClientDetailsCardProps {
  client?: ExtendedClient | NetworkClientType;
  loading?: boolean;
  disableBottomBorder?: boolean;
}

const renderRow = (rowData: RowContent) => (
  <CopyableTwoColumnRow title={rowData.label} value={rowData.value} />
);

const ClientDetailsCard = (props: ClientDetailsCardProps) => {
  const { client, loading, disableBottomBorder } = props;

  if (client == null || isEmpty(client)) {
    return null;
  }

  const content: RowContent[] = [
    { label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.OS"), value: clientOS(client) },
    { label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.MAC"), value: client.mac },
  ];

  if (client.ip6 && __MERAKI_GO__) {
    client.ip &&
      content.push({ label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.IPV4"), value: client.ip });
    content.push({ label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.IPV6"), value: client.ip6 });
  } else {
    client.ip &&
      content.push({ label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.IP"), value: client.ip });
  }

  if (client.vlan && !__MERAKI_GO__) {
    content.push({ label: I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.VLAN"), value: client.vlan });
  }

  const headingContainerStyles = appSelect({ enterprise: {}, go: styles.goHeading });
  const containerStyles = appSelect({ enterprise: {}, go: styles.goContainer });

  return (
    <SummaryList<RowContent>
      heading={I18n.t("CLIENT_DETAILS.INFO_TITLE", {
        client_word: capitalizeFirstLetter(I18n.t(CLIENT)),
      })}
      contentRows={content}
      customRenderRow={renderRow}
      headingContainerStyles={headingContainerStyles}
      containerStyles={containerStyles}
      loading={loading}
      disableBottomBorder={disableBottomBorder}
    />
  );
};

const styles = StyleSheet.create({
  goHeading: {
    marginLeft: 0,
  },
  goContainer: {
    marginHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
  },
});

export default ClientDetailsCard;
