import { useTheme } from "@meraki/core/theme";
import { formatKibibytesPerSecond } from "@meraki/shared/formatters";
import { PureComponent } from "react";
import { Dimensions, EmitterSubscription, ScaledSize, View, ViewProps } from "react-native";
import { VictoryAxisProps } from "victory-axis";
import { VictoryDatableProps } from "victory-core";
import { VictoryAxis, VictoryChart, VictoryChartProps, VictoryLabel } from "victory-native";

import MkiColors from "~/constants/MkiColors";
import {
  DEFAULT_CARD_PADDING_HORIZONTAL,
  DEFAULT_CHART_HEIGHT,
  SPACING,
  WINDOW,
} from "~/constants/MkiConstants";
import { CHART_FONT_SIZES, CISCO_SANS_FONT } from "~/constants/MkiFonts";
import { mkiChartTheme, noDataLabelYOffset } from "~/constants/MkiVictoryTheme";
import I18n from "~/i18n/i18n";
import { formatMonthDay } from "~/lib/formatHelper";
import { isEmptyMkiChartData } from "~/lib/MkiChartUtils";
import { themeColors } from "~/lib/themeHelper";
import { AxisFormatter, DataKey, Domain } from "~/shared/types/MkiChartTypes";

type DomainPropType = VictoryChartProps["domain"];

export interface MkiChartProps {
  containerComponent?: VictoryChartProps["containerComponent"];
  children?: React.ReactNode;
  chartWidth?: number | null;
  chartHeight?: number;
  data: VictoryDatableProps["data"];
  dataKeys: DataKey[];
  disableXAxisOffsetY?: boolean;
  domain: Domain | undefined;
  padding?: VictoryChartProps["padding"];
  hasFill?: boolean;
  xAxisFormatter: AxisFormatter;
  yAxisFormatter: AxisFormatter;
  yAxisProps?: VictoryAxisProps;
  pointerEvents?: ViewProps["pointerEvents"];
}

type Props = MkiChartProps;

export interface MkiChartState {
  normalizedChartWidth: number;
}

export class MkiChart extends PureComponent<Props, MkiChartState> {
  static defaultProps: MkiChartProps = {
    chartHeight: DEFAULT_CHART_HEIGHT,
    chartWidth: null,
    dataKeys: [],
    data: [],
    disableXAxisOffsetY: false,
    domain: undefined,
    xAxisFormatter: formatMonthDay,
    yAxisFormatter: formatKibibytesPerSecond,
    yAxisProps: {},
    pointerEvents: undefined,
  };

  private eventListener?: EmitterSubscription;

  constructor(props: Props) {
    super(props);
    const { chartWidth } = props;
    const windowWidth = Dimensions.get(WINDOW).width;
    const normalizedChartWidth = chartWidth || windowWidth - DEFAULT_CARD_PADDING_HORIZONTAL;
    this.state = { normalizedChartWidth };
  }

  componentDidMount() {
    this.eventListener = Dimensions.addEventListener("change", this.handleWindowChange);
  }

  componentWillUnmount() {
    this.eventListener?.remove();
  }

  handleWindowChange = (newDims: { window: ScaledSize }) => {
    const { chartWidth } = this.props;
    const normalizedChartWidth =
      chartWidth || newDims.window.width - DEFAULT_CARD_PADDING_HORIZONTAL;

    this.setState({ normalizedChartWidth });
  };

  renderNoDataLabel() {
    const { normalizedChartWidth } = this.state;
    return (
      <VictoryLabel
        x={normalizedChartWidth / 2}
        y={noDataLabelYOffset}
        style={graphStyles.axisLabelText}
        text={I18n.t("USAGE_CHART.NO_DATA")}
      />
    );
  }

  render() {
    const {
      chartHeight,
      children,
      containerComponent,
      data,
      disableXAxisOffsetY,
      domain,
      xAxisFormatter,
      yAxisFormatter,
      yAxisProps,
      padding,
      pointerEvents,
    } = this.props;
    const { theme } = useTheme.getState();
    const { normalizedChartWidth } = this.state;
    const emptyData = isEmptyMkiChartData(data);
    // Marking pointEvents as "none" prevents an error on Android
    // that prevents scroll events when touching the chart.
    let yAxisGridColor = {};
    const strokeColor = themeColors(theme).graph?.gridColor;
    if (strokeColor) {
      yAxisGridColor = { grid: { stroke: strokeColor } };
    }

    return (
      <View pointerEvents={pointerEvents}>
        <VictoryChart
          containerComponent={containerComponent}
          domain={domain as DomainPropType}
          height={chartHeight}
          padding={padding}
          theme={mkiChartTheme}
          width={normalizedChartWidth}
        >
          <VictoryAxis
            dependentAxis
            tickFormat={yAxisFormatter}
            style={{ ...graphStyles.yAxis, ...yAxisGridColor }}
            {...yAxisProps}
          />
          <VictoryAxis
            tickFormat={xAxisFormatter}
            orientation="bottom"
            offsetY={disableXAxisOffsetY ? null : mkiChartTheme.chart.padding.bottom}
            style={graphStyles.xAxis}
          />
          {emptyData ? this.renderNoDataLabel() : children}
        </VictoryChart>
      </View>
    );
  }
}

const graphStyles = {
  axisLabelText: {
    fontFamily: CISCO_SANS_FONT.fontFamily,
    fontSize: CHART_FONT_SIZES.CHART_AXIS_LABEL.default,
    fill: MkiColors.secondaryTextColor,
    padding: SPACING.small,
  },
  yAxis: {
    axis: {
      stroke: "transparent",
    },
    grid: {
      stroke: MkiColors.referenceLineColor,
    },
  },
  xAxis: {
    axis: {
      stroke: MkiColors.lineChartBorderColor,
    },
  },
};

export default MkiChart;
