import z from "zod";

import { request } from "../../api/request/request";
import { ProductTypeSchema } from "../../schemas";
import { StatusSchema } from "../../schemas/Device";
import { createQuery } from "../createQuery";

export const TopologyNodeKeys = {
  device: "device",
  unknown: "unknown",
  stack: "stack",
  discovered: "discovered",
} as const;

export type TopologyNodeTypes = (typeof TopologyNodeKeys)[keyof typeof TopologyNodeKeys];

export const DeviceClientDataSchema = z.object({
  counts: z.object({
    total: z.number(),
  }),
});

export const DeviceUplinkDataSchema = z.object({
  vlanId: z.number().nullable(),
  ipv4: z.object({
    address: z.string().nullable(),
    gateway: z.object({
      address: z.string().nullable(),
      mac: z.string().nullable(),
    }),
    nameservers: z.object({
      addresses: z.array(z.string().nullable()),
    }),
  }),
  pppoe: z
    .object({
      enabled: z.boolean(),
    })
    .optional(),
});

export const DeviceDataSchema = z.object({
  serial: z.string(),
  name: z.string(),
  model: z.string(),
  productType: ProductTypeSchema,
  status: StatusSchema,
  lastReportedAt: z.string(),
  clients: DeviceClientDataSchema,
  switch: z
    .object({
      ports: z.object({
        counts: z.object({
          byStatus: z.object({
            active: z.number(),
          }),
        }),
      }),
    })
    .optional(),
  uplinks: z.array(DeviceUplinkDataSchema),
});

export const TopologyNodeTypesSchema = z.union([
  z.literal(TopologyNodeKeys.device),
  z.literal(TopologyNodeKeys.unknown),
  z.literal(TopologyNodeKeys.stack),
  z.literal(TopologyNodeKeys.discovered),
]);

export const LldpNodeSchema = z.object({
  systemName: z.string(),
  chassisId: z.string(),
  serial: z.string().optional(),
  systemDescription: z.string(),
  systemCapabilities: z.array(z.string()),
  managementAddress: z.string().nullable(),
});

export const CdpNodeSchema = z.object({
  platform: z.string(),
  deviceId: z.string(),
  serial: z.string().optional(),
  address: z.string(),
  capabilities: z.array(z.string()),
  managementAddress: z.string().nullable(),
});

export const baseNodeSchema = z.object({
  derivedId: z.string(),
  mac: z.string(),
  type: TopologyNodeTypesSchema,
  device: DeviceDataSchema.optional(),
  discovered: z
    .object({
      lldp: LldpNodeSchema.nullable(),
      cdp: CdpNodeSchema.nullable(),
    })
    .optional(),
  root: z.boolean().optional(),
});

export type DeviceData = z.infer<typeof DeviceDataSchema>;
export type DeviceClientData = z.infer<typeof DeviceClientDataSchema>;

export type NodeType = z.infer<typeof baseNodeSchema> & {
  stack?: {
    id: string;
    name: string;
    members: NodeType[];
    clients: DeviceClientData;
  };
};

export const NodeSchema: z.ZodType<NodeType> = baseNodeSchema.extend({
  stack: z
    .object({
      id: z.string(),
      name: z.string(),
      members: z.lazy(() => NodeSchema.array()),
      clients: DeviceClientDataSchema,
    })
    .optional(),
});

const LinkEnd = z.object({
  node: z.object({
    derivedId: z.string(),
    type: z.string(),
  }),
  device: z
    .object({
      serial: z.string(),
      name: z.string(),
    })
    .optional(),
  discovered: z.object({
    lldp: z
      .object({
        portId: z.string(),
        portDescription: z.string(),
      })
      .nullable(),
    cdp: z
      .object({
        portId: z.string(),
        nativeVlan: z.number().nullable(),
      })
      .nullable(),
  }),
});

export const LinkSchema = z.object({
  ends: z.array(LinkEnd),
  lastReportedAt: z.string().nullable(),
});

export const TopologyResponseSchema = z
  .object({
    nodes: z.array(NodeSchema),
    links: z.array(LinkSchema),
  })
  .describe("TopologySchema");

export type NodeSchemaType = z.infer<typeof NodeSchema>;
export type LinkType = z.infer<typeof LinkSchema>;
export type TopologyResponse = z.infer<typeof TopologyResponseSchema>;

export interface TopologyRequest {
  networkId?: string;
}

function buildUrl({ networkId }: TopologyRequest) {
  return `/api/v1/networks/${networkId}/topology/linkLayer`;
}

export function fetchTopology(variables: TopologyRequest): Promise<TopologyResponse> {
  return request(TopologyResponseSchema, "GET", buildUrl(variables));
}

export const useTopology = createQuery<TopologyRequest, TopologyResponse>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchTopology(variables),
  requiredVariables: ["networkId"],
});
