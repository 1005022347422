import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { hasHardwareAsync, isEnrolledAsync } from "expo-local-authentication";
import { useEffect, useState } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface BioauthStoreState {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

export const useBioauthEnabled = create<BioauthStoreState>()(
  persist(
    (set) => {
      return {
        enabled: false,
        setEnabled: (enabled) =>
          set((_) => ({
            enabled,
          })),
      };
    },
    {
      name: "bioauthEnabled",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export const useHasBioauth = () => {
  const [hasBio, setHasBio] = useState(false);

  useEffect(() => {
    const setBio = async () => {
      const isEnrolled = await isEnrolledAsync();
      const hasHardware = await hasHardwareAsync();
      setHasBio(isEnrolled && hasHardware);
    };
    setBio();
  }, []);

  return hasBio;
};
