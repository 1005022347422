import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function AccessPoint({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <G clipPath="url(#clip0_10_4471)">
        <Path
          d="M12 20.625C12.8284 20.625 13.5 19.9534 13.5 19.125C13.5 18.2966 12.8284 17.625 12 17.625C11.1716 17.625 10.5 18.2966 10.5 19.125C10.5 19.9534 11.1716 20.625 12 20.625Z"
          fill={color1}
        />
        <Path
          d="M21.75 8.73657C19.0009 6.48193 15.5554 5.24973 12 5.24973C8.4446 5.24973 4.99908 6.48193 2.25 8.73657"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M18.75 12.0937C16.8288 10.5729 14.4503 9.74536 12 9.74536C9.54969 9.74536 7.17118 10.5729 5.25 12.0937"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M15.75 15.4687C14.6603 14.6761 13.3475 14.2491 12 14.2491C10.6525 14.2491 9.33969 14.6761 8.25 15.4687"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_10_4471">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
