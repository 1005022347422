import { I18n } from "@meraki/core/i18n";
import { defaultTimespans } from "@meraki/shared/redux";
import { formatRelative } from "date-fns";

export const getTimespanName = (timespan: number) => {
  const data = Object.values(defaultTimespans);
  const selectedData = data.find((d) => timespan === d?.value);
  return selectedData && I18n.t(selectedData.label);
};

export const formatTimespanRelative = (timespan: string) => {
  const formatted = formatRelative(new Date(timespan), Date.now()).split(" at ")[0];
  return `${formatted?.[0]?.toUpperCase()}${formatted?.substring(1)}`;
};
