import { I18n } from "@meraki/core/i18n";
import { ThemeName, useTheme } from "@meraki/core/theme";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { ThemeSelectScreen as MagneticThemeSelectScreen } from "@meraki/shared/theme-select";
import { PureComponent } from "react";
import { Appearance, Platform, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import { SettingsStackProps } from "~/enterprise/navigation/Types";
import DefaultHeader from "~/go/components/DefaultHeader";
import { showSaveWarning } from "~/lib/AlertUtils";
import { themeColors } from "~/lib/themeHelper";
import { getHasBetaFeatures } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import ThemeProps from "~/shared/themes/ThemeProps";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { CancelButton, SaveButton } from "../navigation/Buttons";
import { RootState } from "../types/Redux";

interface RadioOptions {
  label: string;
  value: ThemeName;
  sublabel?: string;
}

const radioOptions = (hasAlphaBetaNFOs: boolean) => {
  const options: RadioOptions[] = [];
  options.push({
    label: I18n.t("THEME_SELECT.LIGHT_THEME.LABEL"),
    value: "light",
  });
  options.push({
    label: I18n.t("THEME_SELECT.DARK_THEME.LABEL"),
    value: "dark",
  });
  if (!__MERAKI_GO__) {
    options.push({
      label: I18n.t("THEME_SELECT.LIGHT_DEUTERANOMALY_THEME.LABEL"),
      value: "lightDeuteranomaly",
    });
    options.push({
      label: I18n.t("THEME_SELECT.DARK_DEUTERANOMALY_THEME.LABEL"),
      value: "darkDeuteranomaly",
    });
  }
  if (hasAlphaBetaNFOs) {
    options.push({
      label: I18n.t("THEME_SELECT.AMOLED_THEME.LABEL"),
      sublabel: I18n.t("THEME_SELECT.AMOLED_THEME.DESCRIPTION"),
      value: "amoled",
    });
  }
  options.push({
    label: I18n.t("THEME_SELECT.SYSTEM_THEME.LABEL"),
    value: "system",
  });
  return options;
};

const ThemePreviewComponent = ({ theme }: ThemeProps) => {
  const borderColor = themeColors(theme).navigation.primary;
  return (
    <>
      <DefaultHeader
        title={I18n.t("THEME_SELECT.SAMPLE.HEADER.TITLE")}
        description={I18n.t("THEME_SELECT.SAMPLE.HEADER.DESCRIPTION")}
        style={styles.titleBottomOffset}
      />
      <View style={[styles.themedContainer, { borderColor }]}>
        <SimpleDisclosureRow theme={theme} subtitle={I18n.t("THEME_SELECT.SAMPLE.ROW.DESCRIPTION")}>
          {I18n.t("THEME_SELECT.SAMPLE.ROW.TITLE")}
        </SimpleDisclosureRow>
      </View>
    </>
  );
};

type ReduxProps = {
  hasAlphaBetaNFOs: boolean;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ThemeSelect"> &
  BasicActions &
  ReduxProps;

interface ThemeSelectState {
  selectedTheme: ThemeName;
}

export class ThemeSelectScreen extends PureComponent<Props, ThemeSelectState> {
  constructor(props: Props) {
    super(props);
    this.state = { selectedTheme: useTheme.getState().theme };
  }

  componentDidUpdate() {
    this.updateSaveButton();
  }

  componentDidMount() {
    this.updateSaveButton();
  }

  updateSaveButton = () => {
    const { navigation } = this.props;
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={this.close} />,
      headerRight: () => <SaveButton onPress={this.save} disabled={!this.hasChanges()} />,
    });
  };

  close = () => {
    const { navigation } = this.props;
    if (this.hasChanges()) {
      showSaveWarning(this.save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  };

  save = () => {
    const { selectedTheme } = this.state;
    useTheme.getState().setTheme(selectedTheme);

    if (Platform.OS === "web") {
      const osTheme = selectedTheme === "system" ? Appearance.getColorScheme() : selectedTheme;
      document.cookie = `theme=${osTheme};`;
      window.location.reload();
    }
  };

  hasChanges = () => {
    const { selectedTheme } = this.state;
    const { theme } = useTheme.getState();
    return selectedTheme !== theme;
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("THEME_SELECT.HEADER.TITLE")}
        description={I18n.t("THEME_SELECT.HEADER.DESCRIPTION")}
      />
    );
  };

  renderRadioSelection = () => {
    const { hasAlphaBetaNFOs } = this.props;
    const { selectedTheme } = this.state;
    return (
      <RadioSelectionList
        options={radioOptions(hasAlphaBetaNFOs)}
        onSelect={(newTheme) => this.setState({ selectedTheme: newTheme as ThemeName })}
        selectedValue={selectedTheme}
        screenStyles={styles.contentContainer}
        radioStyle={styles.radioSpacing}
      />
    );
  };

  render() {
    const { selectedTheme } = this.state;
    return (
      <FullScreenContainerView withScroll>
        {this.renderHeader()}
        {this.renderRadioSelection()}
        <ThemePreviewComponent theme={selectedTheme} />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    margin: SPACING.default,
  },
  radioSpacing: {
    marginVertical: SPACING.default,
  },
  titleBottomOffset: {
    paddingBottom: SPACING.default,
  },
  themedContainer: {
    marginHorizontal: SPACING.default,
    borderWidth: SPACING.tiny,
    margin: SPACING.tiny,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasAlphaBetaNFOs: getHasBetaFeatures(state),
  };
}

const ConnectedThemeSelectScreen = compose(connect(mapStateToProps, basicMapDispatchToProps))(
  ThemeSelectScreen,
);

const MagnetizedThemeSelectScreen = withMagneticReplacementAdapter(
  ConnectedThemeSelectScreen,
  MagneticThemeSelectScreen,
);

export default MagnetizedThemeSelectScreen;
