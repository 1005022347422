import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

const NetworkType = z.union([
  z.literal("camera"),
  z.literal("cellular_gateway"),
  z.literal("sensor"),
  z.literal("switch"),
  z.literal("wired"),
  z.literal("wireless"),
]);

export const AdministeredOrgNodeGroupSchema = z
  .object({
    id: z.string(),
    n: z.string(),
    t: z.string(),
    eid: z.string(),
    locale_id: z.string(),
    config_template_ng_id: z.nullable(z.string()),
    network_type: NetworkType,
    network_tags: z.nullable(z.string()),
    time_zone: z.string(),
    node_group_mtime: z.number(),
    node_group_ctime: z.number(),
    can_read: z.boolean(),
    can_write: z.boolean(),
    has_wireless: z.boolean(),
    has_wired: z.boolean(),
    has_vm_concentrator: z.boolean(),
    has_pcc: z.boolean(),
    has_switch: z.boolean(),
    has_phone: z.boolean(),
    is_virtual: z.boolean(),
    is_config_template: z.boolean(),
    has_sensor: z.boolean(),
    has_cellular_gateway: z.boolean(),
    is_template_child: z.boolean(),
  })
  .describe("AdministeredOrgNodeGroupSchema");

export const LocaleSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    is_config_template: z.boolean(),
    switch: z.string().optional(),
    camera: z.string().optional(),
    wireless: z.string().optional(),
    wired: z.string().optional(),
    sensor: z.string().optional(),
    tag: z.string().optional(),
    is_template_child: z.boolean(),
  })
  .describe("LocaleSchema");

export const AdministeredOrgsSchema = z
  .record(
    z.object({
      id: z.string(),
      eid: z.string(),
      uid: z.string(),
      org_admin_type: z.nullable(z.string()),
      name: z.string(),
      num_networks: z.number(),
      num_visible_networks: z.number(),
      shard_id: z.number(),
      licensed_product_edition_name: z.string(),
      license_expires_on: z.number(),
      block_meraki_admins: z.boolean(),
      licensing_mode: z.number(),
      is_demo: z.boolean(),
      node_groups: z.record(AdministeredOrgNodeGroupSchema),
      locales: z.record(LocaleSchema),
    }),
  )
  .describe("AdministeredOrgsSchema");

export type AdministeredOrgNodeGroup = z.infer<typeof AdministeredOrgNodeGroupSchema>;

export type AdministeredOrgs = z.infer<typeof AdministeredOrgsSchema>;

export function getNodeGroupFromAdministeredOrgs(
  administeredOrgs: AdministeredOrgs,
  nodeGroupEncryptedId: string,
): AdministeredOrgNodeGroup | undefined {
  const org = Object.values(administeredOrgs).find(
    (o) => o.node_groups[nodeGroupEncryptedId] !== undefined,
  );

  return org?.node_groups[nodeGroupEncryptedId];
}

export function getOrgAdminType(
  administeredOrgs: AdministeredOrgs,
  nodeGroupEncryptedId?: string,
): string | null | undefined {
  if (!nodeGroupEncryptedId) {
    return;
  }
  const organization = Object.values(administeredOrgs).find(
    (org) => org.node_groups[nodeGroupEncryptedId] !== undefined,
  );

  return organization?.org_admin_type;
}

type AdministeredOrgsRequest = {
  nodeGroupEncryptedId?: string;
};

export function buildAdministeredOrgUrl({ nodeGroupEncryptedId }: AdministeredOrgsRequest) {
  return `/n/${nodeGroupEncryptedId}/manage/organization/administered_orgs`;
}

export function fetchAdministeredOrgs({ nodeGroupEncryptedId }: AdministeredOrgsRequest) {
  return request(AdministeredOrgsSchema, "GET", buildAdministeredOrgUrl({ nodeGroupEncryptedId }));
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/get-organizations/
 */
export const useAdministeredOrgs = createQuery({
  baseQueryKey: buildAdministeredOrgUrl({ nodeGroupEncryptedId: `{nodeGroupEncryptedId}` }),
  queryFn: (parameters: AdministeredOrgsRequest) => fetchAdministeredOrgs(parameters),
  requiredVariables: ["nodeGroupEncryptedId"],
});
