import { useTheme } from "@meraki/core/theme";
import { lazy, PureComponent, Suspense } from "react";
import { Modal, NativeScrollEvent, ScrollView, StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import termsHTML from "~/constants/Terms";
import ExitButton from "~/go/components/ExitButton";
import RoundedButton from "~/go/components/RoundedButton";
import { isWeb } from "~/lib/PlatformUtils";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";

const RenderHtml = lazy(() => import("react-native-render-html"));

const SCROLL_THROTTLE = 50;
const PADDING_TO_BOTTOM = 50;

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - PADDING_TO_BOTTOM;
};

export interface AgreementInfoModalProps {
  forceScroll?: boolean;
  visible: boolean;
  title: string;
  buttonText: string;
  onExit: () => void;
  onPress: () => void;
}

export interface AgreementInfoModalState {
  didLoadCorrectly: boolean;
  buttonDisabled: boolean;
}

export class AgreementInfoModal extends PureComponent<
  AgreementInfoModalProps,
  AgreementInfoModalState
> {
  state = {
    didLoadCorrectly: false,
    buttonDisabled: true,
  };

  shouldDisableButton = () => {
    const { buttonDisabled } = this.state;
    return buttonDisabled;
  };

  closeModal = () => {
    const { onExit } = this.props;

    this.setState({ buttonDisabled: true });
    onExit();
  };

  renderTitleAndExit = () => {
    const { title } = this.props;
    const { theme } = useTheme.getState();

    const titleColor = themeColors(theme).text.heading;

    return (
      <View style={styles.titleExitContainer}>
        <View style={styles.titleExitRow}>
          <MkiText textStyle="subheading" screenStyles={titleColor}>
            {title}
          </MkiText>
          <ExitButton onExit={this.closeModal} />
        </View>
      </View>
    );
  };

  renderHTML = () => {
    const { theme } = useTheme.getState();

    const colors = themeColors(theme).text;
    const tagsStyles = {
      h2: {
        color: colors.heading.color,
      },
      p: {
        color: colors.default.color,
      },
    };
    const classesStyles = {
      "mkiui-secondary-navy": {
        color: colors.heading.color,
      },
    };
    return (
      <Suspense
        fallback={
          <View style={styles.webViewPrimaryButtonContainer}>
            <LoadingSpinner visible={true} />
          </View>
        }
      >
        <ScrollView
          onScroll={({ nativeEvent }) => {
            const { buttonDisabled } = this.state;
            if (buttonDisabled && isCloseToBottom(nativeEvent)) {
              this.setState({ buttonDisabled: false });
            }
          }}
          scrollEventThrottle={SCROLL_THROTTLE}
          testID={"REGISTER.AGREEMENT_MODAL.SCROLL"}
        >
          <RenderHtml source={termsHTML()} tagsStyles={tagsStyles} classesStyles={classesStyles} />
        </ScrollView>
      </Suspense>
    );
  };

  renderTermsAndAgreeButton = () => {
    const { onPress, buttonText } = this.props;

    return (
      <View style={styles.webViewPrimaryButtonContainer}>
        {this.renderHTML()}
        <RoundedButton
          disabled={this.shouldDisableButton()}
          onPress={onPress}
          testID="AGREEMENT_MODAL.BUTTON"
          screenStyles={styles.primaryButton}
        >
          {buttonText}
        </RoundedButton>
      </View>
    );
  };

  render() {
    const { visible } = this.props;
    const { theme } = useTheme.getState();

    const deviceCardStyle = isWeb()
      ? styles.webCard
      : sizeSelect({
          default: styles.phoneCard,
          extraLarge: styles.tabletCard,
        });

    const modalCardBackground = {
      backgroundColor: themeColors(theme).navigation.backgroundPrimary,
    };

    return (
      <Modal transparent animationType="fade" visible={visible} onRequestClose={this.closeModal}>
        <View style={styles.modalBackground}>
          <View style={[styles.modalCard, deviceCardStyle, modalCardBackground]}>
            {this.renderTitleAndExit()}
            {this.renderTermsAndAgreeButton()}
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MkiColors.modalBackgroundColor,
  },
  modalCard: {
    height: "75%",
    borderRadius: 6,
  },
  phoneCard: {
    alignSelf: "stretch",
    marginHorizontal: SPACING.default,
  },
  tabletCard: {
    width: "60%",
  },
  webCard: {
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  titleExitContainer: {
    paddingVertical: SPACING.default,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  titleExitRow: {
    marginHorizontal: SPACING.default,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  webViewPrimaryButtonContainer: {
    flex: 1,
    margin: SPACING.default,
  },
  primaryButton: {
    alignContent: "center",
    marginHorizontal: SPACING.extraLarge,
    marginTop: SPACING.small,
  },
});

export default AgreementInfoModal;
