import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function SecureConnect({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M3 2.828V9.9c0 8.69 7.292 11.566 8.744 12.053a.73.73 0 0 0 .512 0C13.708 21.467 21 18.59 21 9.9V2.828c0-.22-.086-.43-.24-.586A.814.814 0 0 0 20.182 2H3.818a.813.813 0 0 0-.578.242.833.833 0 0 0-.24.586ZM12.5 5c-1.59 0-2.987.824-3.787 2.069A4.501 4.501 0 0 0 9.5 16h5a4.5 4.5 0 0 0 2.021-8.521A4.5 4.5 0 0 0 12.5 5Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M17 11.502a2.588 2.588 0 0 1-.63 1.706 2.244 2.244 0 0 1-1.705.792h-5.33a2.325 2.325 0 0 1-1.28-.41 2.35 2.35 0 0 1-.845-1.053c-.14-.327-.21-.68-.209-1.035a2.441 2.441 0 0 1 .655-1.735 2.406 2.406 0 0 1 1.68-.764c.135 0 .27.013.403.037C10.102 8.005 10.95 7 12.368 7c1.37 0 2.233 1.013 2.616 2.026.575.1 1.095.406 1.465.86a2.46 2.46 0 0 1 .55 1.616Z"
        fill={color2}
      />
    </Svg>
  );
}
