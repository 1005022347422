import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { IconProps } from "@meraki/magnetic/icons";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { useCallback } from "react";
import { StyleProp, View, ViewProps, ViewStyle } from "react-native";

import { Option, ViewSwitcherOption } from "./Option";

type ViewSwitcherProps = Omit<ViewProps, "style"> &
  AnalyticsProp<"onChange"> & {
    options: ViewSwitcherOption[];
    value?: string;
    onChange: (value: string) => void;

    // Temporary work around for a problem when trying to use a view switcher in a row
    addFlexToOptions?: boolean;
  };

function getContainerStyle(_: Theme): StyleProp<ViewStyle> {
  return {
    flexDirection: "row",
  };
}

function getRandomStyles(theme: Theme): StyleProp<ViewStyle> {
  return {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: theme.color.control.border.weak.base,
  };
}

function getOptionValue(option: ViewSwitcherOption): string {
  return typeof option === "string" ? option : option.value;
}
function getOptionLabel(option: ViewSwitcherOption): string | undefined {
  return typeof option === "string" ? option : option.label;
}
function getOptionIcon(option: ViewSwitcherOption): IconProps["name"] | undefined {
  return typeof option === "string" ? undefined : option.icon;
}
function getOptionTestID(option: ViewSwitcherOption): string | undefined {
  return typeof option === "string" ? undefined : option.testID;
}

export function ViewSwitcher({
  value,
  options,
  onChange,
  analytics,
  addFlexToOptions = true,
  ...rest
}: ViewSwitcherProps) {
  const theme = useMagneticTheme();
  const trackEvent = useAnalytics(analytics);

  const handleChange = useCallback(
    (value: string) => {
      trackEvent("onChange", {
        view: value,
      });

      onChange?.(value);
    },
    [trackEvent, onChange],
  );

  if (options.length === 0) return null;

  return (
    <View {...rest} style={getContainerStyle(theme)}>
      <View style={getRandomStyles(theme)} />
      {options.map((option, idx) => {
        const optionValue = getOptionValue(option);
        const optionLabel = getOptionLabel(option);
        const optionIcon = getOptionIcon(option);
        const optionTestID = getOptionTestID(option) ?? `${optionValue}_view`;

        const isFirst = idx === 0;
        const isLast = idx === options.length - 1;
        const isSelected = optionValue === value;
        const isLeftOfSelected = !isLast ? getOptionValue(options[idx + 1]) === value : false;
        const isRightOfSelected = !isFirst ? getOptionValue(options[idx - 1]) === value : false;

        const positionMeta = {
          first: isFirst,
          last: isLast,
          leftOfSelected: isLeftOfSelected,
          rightOfSelected: isRightOfSelected,
        };

        return (
          <Option
            key={optionValue}
            value={optionValue}
            icon={optionIcon}
            label={optionLabel}
            testID={optionTestID}
            onChange={handleChange}
            selected={isSelected}
            positionMeta={positionMeta}
            addFlexToOptions={addFlexToOptions}
          />
        );
      })}
    </View>
  );
}
