import { I18n } from "@meraki/core/i18n";
import { RouteName, RouteProps } from "@meraki/go/navigation-type";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useBottomTabConfig, useCurrentNetworkId } from "@meraki/shared/redux";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Platform } from "react-native";

import AlertsStack from "./stacks/AlertsStack";
import ConfigureStack from "./stacks/ConfigureStack";
import HelpStack from "./stacks/HelpStack";
import InsightsStack from "./stacks/InsightsStack";
import MonitorStack from "./stacks/MonitorStack";
import TabBarIcon from "./TabBarIcon";

const RouteNameToPathName: Record<RouteName, string> = {
  MonitorTab: "monitor",
  InsightsTab: "insights",
  HelpTab: "help",
  ConfigureTab: "configure",
  AlertsTab: "alerts",
};

const isWeb = Platform.OS === "web";
const Tab = createBottomTabNavigator<RouteProps>();

export const DefaultTabStack = () => {
  const theme = useMagneticTheme();
  const bottomTabBarConfig = useBottomTabConfig();
  const currentNetwork = useCurrentNetworkId();

  return (
    <Tab.Navigator
      screenOptions={({ route }) => {
        const hideBottomTabBar = bottomTabBarConfig.visibility === "hidden" || isWeb;

        return {
          headerShown: false,
          tabBarStyle: { display: hideBottomTabBar ? "none" : "flex" },
          tabBarLabelStyle: {
            fontFamily: "Inter",
            fontSize: theme.SizeFont2xs,
            lineHeight: theme.SizeFontHeightLine2xs,
          },
          tabBarIconStyle: {
            transform: [{ translateY: 3 }],
          },
          tabBarIcon: TabBarIcon,
          tabBarActiveTintColor: theme.color.brandAccent.icon.medium.active,
          tabBarBadge: bottomTabBarConfig.tabs?.[route.name]?.badgeText,
          tabBarBadgeStyle: bottomTabBarConfig.tabs?.[route.name]?.badgeStyle,
        };
      }}
      screenListeners={({ route }) => {
        if (isWeb) {
          window.history.replaceState(null, "", `#${RouteNameToPathName[route.name]}`);
        }

        return {};
      }}
    >
      <Tab.Screen
        name="MonitorTab"
        component={MonitorStack}
        options={{ title: I18n.t("ROOT_TITLES.MONITOR"), tabBarTestID: "BOTTOM_TABS.MONITOR" }}
      />
      {currentNetwork && (
        <Tab.Screen
          name="InsightsTab"
          component={InsightsStack}
          options={{ title: I18n.t("ROOT_TITLES.INSIGHTS"), tabBarTestID: "BOTTOM_TABS.INSIGHTS" }}
        />
      )}
      <Tab.Screen
        name="HelpTab"
        component={HelpStack}
        options={{ title: I18n.t("ROOT_TITLES.HELP"), tabBarTestID: "BOTTOM_TABS.HELP" }}
      />
      <Tab.Screen
        name="ConfigureTab"
        component={ConfigureStack}
        options={{ title: I18n.t("ROOT_TITLES.CONFIGURE"), tabBarTestID: "BOTTOM_TABS.CONFIGURE" }}
      />
      <Tab.Screen
        name="AlertsTab"
        component={AlertsStack}
        options={{ title: I18n.t("ROOT_TITLES.ALERTS"), tabBarTestID: "BOTTOM_TABS.ALERTS" }}
      />
    </Tab.Navigator>
  );
};
