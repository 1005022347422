import { I18n } from "@meraki/core/i18n";
import { PickerOption } from "@meraki/shared/components";
import { formatDistanceToNowStrict } from "date-fns";

export const SUPPORT_CASE_DEFAULT_NAME = "Dashboard";

export const SUPPORT_CASE_SUBJECTS_MERAKI: PickerOption<string>[] = Object.entries(
  I18n.t("SUPPORT.GOv3.SUBJECT.OPTIONS.MERAKI", { returnObjects: true }),
).map(([_, value]) => {
  return { label: value, value: value };
});

export const SUPPORT_CASE_SUBJECTS_CISCO_CX: PickerOption<string>[] = Object.entries(
  I18n.t("SUPPORT.GOv3.SUBJECT.OPTIONS.CX", { returnObjects: true }),
).map(([_, value]) => {
  return { label: value, value: value };
});

export const fromSupportCaseTsToDate = (utc: string) => {
  // Case date are from a private endpoint, which are not standardized.

  let finalTime;
  if (utc) {
    // Formatted to ISO because react native's Date() cannot process non-RFC2822/ISO dates.
    const temp = utc.replace(/\//g, "-").split(" +")[0]?.split(" ");
    const date = `${temp?.[0]}T${temp?.[1]}Z`;

    finalTime = new Date(date);
  } else {
    // for local support case comment additions
    finalTime = Date.now();
  }
  return finalTime;
};

export const shorthandTimeFromNow = (dateTime: Date | number) => {
  if (!dateTime) {
    return;
  }

  const fromNowString = formatDistanceToNowStrict(dateTime);
  const [time, unit] = fromNowString.split(" ");

  if (!time || !unit) {
    return;
  }

  const UNIT_TRANSLATIONS: Record<string, string> = {
    DAY: I18n.t("TIMEUNITS_SHORTHAND.DAY"),
    HOUR: I18n.t("TIMEUNITS_SHORTHAND.HOUR"),
    MILLISECOND: I18n.t("TIMEUNITS_SHORTHAND.MILLISECOND"),
    MINUTE: I18n.t("TIMEUNITS_SHORTHAND.MINUTE"),
    MONTH: I18n.t("TIMEUNITS_SHORTHAND.MONTH"),
    SECOND: I18n.t("TIMEUNITS_SHORTHAND.SECOND"),
    YEAR: I18n.t("TIMEUNITS_SHORTHAND.YEAR"),
  };

  const transformedUnit = unit?.endsWith("s") ? unit.slice(0, -1) : unit;

  return time + UNIT_TRANSLATIONS[transformedUnit.toUpperCase()];
};

export const cleanSupportMessageText = (
  messageBody: string | undefined | null,
  type: "preview" | "bubble",
) => {
  // cascading email replies will only show new content
  const pattern = new RegExp(`On .* wrote:\n\n&gt;`);
  if (messageBody !== undefined && messageBody !== null) {
    let dirtyText = messageBody;

    dirtyText = dirtyText.split(pattern)[0] ?? dirtyText;
    dirtyText = dirtyText.split("\n--")[0] ?? dirtyText;
    dirtyText = dirtyText.split("| .ılı.ılı.")[0] ?? dirtyText;

    return type === "preview" ? dirtyText.replace(/[\r\n]+/g, " ").trim() : dirtyText.trim();
  }
  return;
};
