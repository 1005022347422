import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, List, PortDiagram } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useSwitchPortStatuses } from "@meraki/react-live-broker";
import { useSwitchPorts } from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import {
  FilterConfiguration,
  FiltersState,
  getSwitchPortSearchResults,
  PortFilterCategories,
  PortFilterOptions,
  SwitchPortStatusAndFilter,
  useFilteredState,
} from "@meraki/shared/filters";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useMemo, useRef, useState } from "react";

import {
  deriveSwitchPortStatus,
  getPortFilterTranslation,
  portListDescription,
} from "../../utils/PortUtils";

export const switchPortFilterConfiguration: FilterConfiguration<
  PortFilterCategories,
  PortFilterOptions,
  SwitchPortStatusAndFilter
> = (item) => {
  return {
    status: {
      online: item.state === "fullSpeed",
      offline: item.state === "warning",
      disabled: item.state === "disabled",
      disconnected: item.state === "disconnected",
    },
    usage: {
      uplink: item.indicator === "uplink",
      poe: item.indicator === "active",
    },
  };
};

export const SwitchPortsListScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "SwitchPortsList">>();
  const { device, statusFilters } = route.params;

  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);

  const { data: apiPortData, isLoading } = useSwitchPorts({
    serial: device.serial,
  });
  const livePortData = useSwitchPortStatuses(device.id);

  const portStatuses = useMemo(
    () => deriveSwitchPortStatus(apiPortData ?? [], livePortData),
    [apiPortData, livePortData],
  );

  const [searchText, setSearchText] = useState("");

  const {
    setFilters,
    filters,
    filteredData: filteredPorts,
  } = useFilteredState<PortFilterCategories, PortFilterOptions, SwitchPortStatusAndFilter>(
    statusFilters ?? {},
    portStatuses,
    { filterConfiguration: switchPortFilterConfiguration },
  );

  const searchedPorts = getSwitchPortSearchResults(filteredPorts, searchText);

  return (
    <Screen.View gap="none">
      <SearchFilterSection
        placeholder={I18n.t("SEARCH")}
        searchText={searchText}
        onSearchTextChange={setSearchText}
        onFilterPress={() => filterBottomSheetRef.current?.present()}
      />
      <FilterTagList
        filters={{
          status:
            filters.status != null
              ? Array.from(filters.status).map((filter) => ({
                  value: filter,
                  label: getPortFilterTranslation(filter),
                }))
              : [],
          usage:
            filters.usage != null
              ? Array.from(filters.usage).map((filter) => ({
                  value: filter,
                  label: getPortFilterTranslation(filter),
                }))
              : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<PortFilterCategories, PortFilterOptions> = {};

          if (filters.status.length > 0) {
            formattedFilters.status = new Set(filters.status);
          }
          if (filters.usage.length > 0) {
            formattedFilters.usage = new Set(filters.usage);
          }

          setFilters(formattedFilters);
        }}
      />
      <List.FlashList
        data={searchedPorts}
        loading={isLoading || livePortData === undefined}
        emptyState={{
          title: I18n.t("PORT_EMPTY_STATE.MESSAGE_SEARCH"),
        }}
        getItemData={(port) => {
          return {
            title: `${port.name ?? I18n.t("PORTS.NUMBER", { port_number: port.portId })}${
              port.indicator === "uplink" ? ` - ${I18n.t("PORTS.INTERNET_CONNECTION")}` : ""
            }`,
            leftAccessory: (
              <PortDiagram.Port
                size="sm"
                {...({ state: port.state, indicator: port.indicator } ?? { state: "disconnected" })}
              />
            ),
            description: portListDescription(port),
            onPress: () => {
              navigation.navigate("SwitchPortDetails", {
                serial: device.serial,
                portNumber: port.portId,
              });
            },
            testID: `SWITCH_PORT.${port.portId}`,
          };
        }}
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          status: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
            options: [
              { value: "online", label: I18n.t("PORT_FILTERS.ONLINE") },
              { value: "offline", label: I18n.t("PORT_FILTERS.OFFLINE") },
              { value: "disabled", label: I18n.t("PORT_FILTERS.DISABLED") },
              { value: "disconnected", label: I18n.t("PORT_FILTERS.DISCONNECTED") },
            ],
          },
          usage: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.USAGE"),
            options: [
              { value: "uplink", label: I18n.t("PORT_FILTERS.UPLINK") },
              { value: "poe", label: I18n.t("PORT_FILTERS.POE") },
            ],
          },
        }}
        initialFilters={{
          status: filters.status != null ? Array.from(filters.status) : [],
          usage: filters.usage != null ? Array.from(filters.usage) : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<PortFilterCategories, PortFilterOptions> = {};

          if (filters.status.length > 0) {
            formattedFilters.status = new Set(filters.status);
          }
          if (filters.usage.length > 0) {
            formattedFilters.usage = new Set(filters.usage);
          }

          setFilters(formattedFilters);
        }}
      />
    </Screen.View>
  );
};
