import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Organization({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M11 18.379a1 1 0 0 0 2 0v-1h5v1a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1h-6v-1.5h-2v1.5H5a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1h5v1Z"
        fill={color1}
      />
      <Path
        d="M10.94 4.44a1.5 1.5 0 0 1 2.12 0l2.88 2.878a1.5 1.5 0 0 1 0 2.121l-2.88 2.879a1.5 1.5 0 0 1-2.12 0L8.06 9.439a1.5 1.5 0 0 1 0-2.121l2.88-2.879Z"
        fill={color2}
      />
    </Svg>
  );
}
