import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import {
  AddSSIDScreen,
  ClickThroughPreviewScreen,
  ClickThroughScreen,
  ExternallyHostedScreen,
  GeneratePDFScreen,
  LandingPageScreen,
  PasswordSettingsScreen,
  RadioSettingsScreen,
  SchedulesScreen,
  SSIDScreen,
  UniquePasswordScreen,
  WirelessModeScreen,
  WirelessSettingsScreen,
  WPASettingsScreen,
} from "@meraki/go/ssid";
import { Button } from "@meraki/magnetic/components";
import { ModalScreenOptions, useNetworkBackButton } from "@meraki/shared/navigation";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function SsidGroup<T extends SsidGroupProps>(Stack: StackType<T>) {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();

  const optionsWithNetworkButton = useNetworkBackButton();

  return (
    <>
      <Stack.Screen
        name="Schedules"
        component={SchedulesScreen}
        options={{ headerTitle: I18n.t("SCHEDULED_SSID.TITLE") }}
      />
      <Stack.Screen
        name="UniquePassword"
        component={UniquePasswordScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("CONFIGURE_IPSK.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="ExternallyHosted"
        component={ExternallyHostedScreen}
        options={{ headerTitle: I18n.t("EXTERNALLY_HOSTED_SCREEN.TITLE") }}
      />
      <Stack.Screen
        name="ClickThrough"
        component={ClickThroughScreen}
        options={{
          ...ModalScreenOptions,
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
          headerTitle: I18n.t("CLICK_THROUGH_SCREEN.TITLE"),
        }}
      />
      <Stack.Screen
        name="ClickThroughPreview"
        component={ClickThroughPreviewScreen}
        options={{
          ...ModalScreenOptions,
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
          headerTitle: I18n.t("CLICK_THROUGH_SCREEN.PREVIEW_TITLE"),
        }}
      />
      <Stack.Screen name="LandingPage" component={LandingPageScreen} />
      <Stack.Screen name="WirelessMode" component={WirelessModeScreen} />
      <Stack.Screen name="Ssid" component={SSIDScreen} options={{ headerShadowVisible: false }} />
      <Stack.Screen
        name="AddSsid"
        component={AddSSIDScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("CONFIGURE.CREATE.SSID.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="RadioSettings"
        component={RadioSettingsScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="WPASettings"
        component={WPASettingsScreen}
        options={{ headerShadowVisible: false }}
      />

      <Stack.Screen
        name="WirelessSettings"
        component={WirelessSettingsScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen name="GeneratePDF" component={GeneratePDFScreen} />
      <Stack.Screen
        name="PasswordSettings"
        component={PasswordSettingsScreen}
        options={{ headerShadowVisible: false }}
      />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "CONFIGURE.CREATE.SSID.TITLE",
    tab: "ConfigureTab",
    screen: "AddSsid",
  },
  ["ssid"],
);
