import { RadioButton, RadioButtonProps } from "../RadioButton/RadioButton";
import { ListItem, ListItemProps } from "./ListItem";

export type RadioListItemProps<T extends string | number | null> = Omit<
  ListItemProps,
  "leftAccessory" | "onPress" | "hidePressable"
> & {
  radioValue: RadioButtonProps<T>["value"];
  checkedRadioValue: RadioButtonProps<T>["checkedValue"];
  onRadioValueChange: RadioButtonProps<T>["onValueChange"];
};

export function RadioListItem<T extends string | number | null>({
  radioValue,
  checkedRadioValue,
  onRadioValueChange,
  testID,
  ...rest
}: RadioListItemProps<T>) {
  return (
    <ListItem
      {...rest}
      onPress={() => onRadioValueChange?.(radioValue)}
      hidePressable
      leftAccessory={
        <RadioButton
          testID={testID}
          value={radioValue}
          checkedValue={checkedRadioValue}
          onValueChange={onRadioValueChange}
        />
      }
    />
  );
}
