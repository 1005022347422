import { NavigationRouteContext } from "@react-navigation/native";
import { useContext } from "react";

import { isWeb } from "~/lib/PlatformUtils";

import TabIcon from "./TabIcon";

export type TabBarIconProps = {
  routeName?: string;
  color?: string;
  focused: boolean;
  overrideIcon?: React.ReactNode;
};

const TabBarIcon = ({ routeName, color, focused, overrideIcon }: TabBarIconProps) => {
  // Sharing with web, useRoute will throw if undefined
  const derivedRouteName = useContext(NavigationRouteContext)?.name ?? routeName;

  switch (derivedRouteName) {
    case "HomeStack":
      return (
        <TabIcon
          icon={
            isWeb()
              ? require("~/images/tabBar/Home-default-M_web.png")
              : require("~/images/tabBar/Home-default-M.png")
          }
          focusedIcon={
            isWeb()
              ? require("~/images/tabBar/Home-selected-M_web.png")
              : require("~/images/tabBar/Home-selected-M.png")
          }
          focused={focused}
          tintColor={color}
        />
      );
    case "NetworksStack":
      return (
        <TabIcon
          icon={
            isWeb()
              ? require("~/images/tabBar/Network-default-M_web.png")
              : require("~/images/tabBar/Network-default-M.png")
          }
          focusedIcon={
            isWeb()
              ? require("~/images/tabBar/Network-selected-M_web.png")
              : require("~/images/tabBar/Network-selected-M.png")
          }
          focused={focused}
          tintColor={color}
        />
      );
    case "HealthStack":
      return (
        <TabIcon
          icon={
            isWeb()
              ? require("~/images/tabBar/Health-default-M_web.png")
              : require("~/images/tabBar/Health-default-M.png")
          }
          focusedIcon={
            isWeb()
              ? require("~/images/tabBar/Health-selected-M_web.png")
              : require("~/images/tabBar/Health-selected-M.png")
          }
          focused={focused}
          tintColor={color}
        />
      );
    case "HardwareStack":
      return (
        <TabIcon
          icon={
            isWeb()
              ? require("~/images/tabBar/Hardware-default-M_web.png")
              : require("~/images/tabBar/Hardware-default-M.png")
          }
          focusedIcon={
            isWeb()
              ? require("~/images/tabBar/Hardware-selected-M_web.png")
              : require("~/images/tabBar/Hardware-selected-M.png")
          }
          focused={focused}
          tintColor={color}
        />
      );
    case "SettingsStack":
      return (
        <TabIcon
          icon={
            isWeb()
              ? overrideIcon ?? require("~/images/tabBar/Settings-default-M_web.png")
              : require("~/images/tabBar/Settings-default-M.png")
          }
          focusedIcon={
            isWeb()
              ? require("~/images/tabBar/Settings-selected-M_web.png")
              : require("~/images/tabBar/Settings-selected-M.png")
          }
          focused={focused}
          tintColor={color}
        />
      );
    default:
      return null;
  }
};

export default TabBarIcon;
