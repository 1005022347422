import { formatDate } from "@meraki/core/date";
import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { get, isEmpty } from "lodash";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import { DeviceScreensPropMap } from "~/enterprise/navigation/Types";
import { HealthStackProps } from "~/go/navigation/Types";
import { Entry, key, value } from "~/lib/EntryUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { clientsByMac, getNetworkTimezone } from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { DisplayableFailedConnection } from "~/shared/types/WirelessHealth";

import { Client } from "../types/Client";
import { RootState } from "../types/Redux";

type ReduxProps = {
  timezone: string;
  clientIds: { [mac: string]: Client };
};

type Props = ForwardedNativeStackScreenProps<DeviceScreensPropMap, "FailedWirelessConnection"> &
  ReduxProps;

interface ScopedFailedWirelessConnectionsProps {
  serial?: string;
  clientMac?: string;
}

const SCOPED_PROP_INDEX = 2;

export class FailedWirelessConnectionScreen extends PureComponent<Props> {
  getFormattedChildren = (entry: Entry<string | string[]>) => {
    const title = entry[key];
    const text = entry[value];

    const textIsArray = Array.isArray(text);
    const valueText = textIsArray ? text[1] : text;

    const result = [
      <MkiText textStyle="smallSecondary">{title}</MkiText>,
      <MkiText>{valueText}</MkiText>,
    ];

    if (textIsArray) {
      const subtitleText = text[0];
      result.push(<MkiText textStyle="smallSecondary">{subtitleText}</MkiText>);
    }

    return result;
  };

  onFailedConnectionScopePressed = (scopedProps: ScopedFailedWirelessConnectionsProps) => {
    const { navigation } = this.props;
    const { serial, clientMac } = scopedProps;
    const { clientIds } = this.props;

    if (serial !== undefined) {
      if (__MERAKI_GO__) {
        type GoNavigation = ForwardedNativeStackScreenProps<
          HealthStackProps,
          "FailedWirelessConnection"
        >["navigation"];
        (navigation as unknown as GoNavigation).navigate("HardwareDetails", {
          serialNumber: serial,
        });
      } else {
        navigation.navigate("DeviceDetails", {
          serial,
          initialPage: "uplink",
        });
      }
    } else if (clientMac !== undefined) {
      const id = clientIds?.[clientMac]?.id;
      navigation.navigate("ClientDetails", {
        id,
        initialPage: 1,
      });
    }
  };

  renderDisclosureRow = (entry: Entry<string | string[]>) => {
    const scopedProps = get(entry, SCOPED_PROP_INDEX)!;

    return (
      <SimpleDisclosureRow onPress={() => this.onFailedConnectionScopePressed(scopedProps)}>
        {this.getFormattedChildren(entry)}
      </SimpleDisclosureRow>
    );
  };

  renderRow = (entry: Entry<string | string[]>) => {
    switch (entry[key]) {
      case I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.AP"):
      case I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.CLIENT"):
      case capitalizeFirstLetter(I18n.t("DEVICE_WORD")):
        return this.renderDisclosureRow(entry);
    }

    return <SimpleDisclosureRow>{this.getFormattedChildren(entry)}</SimpleDisclosureRow>;
  };

  getData = (failedConnection: DisplayableFailedConnection): string[][] => {
    const { apName, apMac, clientName, clientMac, ssidName, failureStep, ts, type, serial } =
      failedConnection;

    const tsInMillis = Date.parse(ts);

    const apRowString = apName === apMac ? apMac : [apMac, apName];
    const clientRowString = clientName === clientMac ? clientMac : [clientMac, clientName];

    return [
      [
        appSelect({
          enterprise: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.CLIENT"),
          go: capitalizeFirstLetter(I18n.t("DEVICE_WORD")),
        }),
        clientRowString,
        { clientMac },
      ],
      [
        I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.AP"),
        apRowString,
        { serial },
      ],
      [
        appSelect({
          enterprise: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.SSID"),
          go: I18n.t("NETWORK_WORD"),
        }),
        ssidName,
      ],
      [
        I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.REASON"),
        [failureStep, type],
      ],
      [
        I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.TIMESTAMP"),
        formatDate(tsInMillis, { dateFormat: "longDate", timeFormat: "longTime" }),
      ],
    ];
  };

  getKeyFromRow = (entry: Entry<string>, _: number) => {
    return entry[key];
  };

  render() {
    const { failedConnection } = this.props;
    if (isEmpty(failedConnection)) {
      return null;
    }

    return (
      <MkiTable
        // @ts-ignore typing on this is really weird
        data={this.getData(failedConnection)}
        keyExtractor={this.getKeyFromRow}
        renderRow={this.renderRow}
      />
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    timezone: errorMonitor.notifyNonOptional(
      "param `timezone` undefined for FailedWirelessConnectionScreen",
      getNetworkTimezone(state),
    ),
    clientIds: errorMonitor.notifyNonOptional(
      "param `clientIds` undefined for FailedWirelessConnectionScreen",
      clientsByMac(state),
    ),
  };
}

export default connect(mapStateToProps)(FailedWirelessConnectionScreen);
