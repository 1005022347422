// @ts-expect-error TS(7016): Could not find a declaration file for module '@hca... Remove this comment to see the full error message
import ConfirmHcaptcha from "@hcaptcha/react-native-hcaptcha";
import { useCurrentCluster } from "@meraki/shared/redux";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

import { captchaSiteKey } from "../env";

const HCAPTCHA_TOKEN_EXPIRATION_TIME = 120000;

export type CaptchaModalMethods = {
  show: () => void;
};

type CaptchaModalProps = {
  onCaptchaComplete: (token?: string) => void;
  onCaptchaCancelled?: () => void;
};

// NOTE: A newer version of "@hcaptcha/react-native-hcaptcha" has types! This will go away with that update
// DM-4096
type CaptchaMessageEvent = {
  nativeEvent: {
    data: string;
  };
};

export const CaptchaModal = forwardRef<CaptchaModalMethods, CaptchaModalProps>(
  function CaptchaModal({ onCaptchaComplete, onCaptchaCancelled }, ref) {
    const captchaRef = useRef<ConfirmHcaptcha>(null);

    const currentCluster = useCurrentCluster();

    const [showCaptcha, setShowCaptcha] = useState(true);

    useImperativeHandle(ref, () => ({
      show: () => {
        captchaRef.current?.show();
      },
    }));

    const resetCaptcha = useCallback(() => {
      setShowCaptcha(false);
      setTimeout(() => {
        setShowCaptcha(true);
      }, 1000);
    }, [setShowCaptcha]);

    const handleCaptchaMessage = useCallback(
      (event: CaptchaMessageEvent) => {
        if (event && event.nativeEvent.data) {
          if (event.nativeEvent.data === "cancel") {
            resetCaptcha();
            onCaptchaCancelled?.();
          } else if (["error", "expired"].includes(event.nativeEvent.data)) {
            resetCaptcha();
          } else {
            onCaptchaComplete(event.nativeEvent.data);
            setShowCaptcha(false);
            setTimeout(() => {
              // TODO: expired token
            }, HCAPTCHA_TOKEN_EXPIRATION_TIME);
          }
        }
      },
      [onCaptchaCancelled, onCaptchaComplete, resetCaptcha],
    );

    if (!showCaptcha) {
      return null;
    }

    return (
      <ConfirmHcaptcha
        ref={captchaRef}
        siteKey={captchaSiteKey(currentCluster)}
        baseUrl={"https://hcaptcha.com"}
        onMessage={handleCaptchaMessage}
        showLoading
      />
    );
  },
);
