import { ColorToken } from "@meraki/magnetic/themes";
import { SingleBarGraphData } from "@meraki/shared/components";
import { BAR_GRAPH_COLOR_PROPS } from "@meraki/shared/formatters";
import { millisecondsToSeconds } from "date-fns";

import { GO_HEALTH_COLOR_STATUSES } from "~/constants/MkiConstants";
import { Outage } from "~/enterprise/types/VpnTypes";
import GeneralStatus from "~/shared/constants/Status";

const GOOD_STATUS = 4;

export interface PeerGraphData {
  percentage: number;
  color?: (typeof BAR_GRAPH_COLOR_PROPS)[number];
}

const getColor = (status: number) =>
  status === GOOD_STATUS ? GeneralStatus.good : GeneralStatus.bad;

export const getPeerGraphData = (outageData: Outage[] | null, timespan: number) => {
  // if no outage data available for peer, display grey (empty) bar
  if (!outageData || outageData.length < 1) {
    return [];
  }

  const now = millisecondsToSeconds(Date.now());
  const t0 = now - timespan;

  const outageDataInTimespan = outageData.filter((point) => point.ts > t0);

  // if no data available within selected timespan, display solid bar
  if (!outageDataInTimespan.length) {
    return [{ percentage: 1, color: getColor(outageData.slice(-1)[0].status) }];
  }

  const data: PeerGraphData[] = [];
  let status = outageDataInTimespan[0].status;
  let prevTimespan = t0;

  outageDataInTimespan.forEach((point) => {
    if (status != point.status) {
      data.push({
        percentage: (point.ts - prevTimespan) / timespan,
        color: getColor(status),
      });
      prevTimespan = point.ts;
      status = point.status;
    }
  });

  data.push({
    percentage: (now - prevTimespan) / timespan,
    color: getColor(status),
  });

  return data;
};

const unmagnetizedColor = (magneticColor: ColorToken) =>
  magneticColor === "positive.icon.base"
    ? GO_HEALTH_COLOR_STATUSES.good
    : magneticColor === "negative.icon.base"
      ? GO_HEALTH_COLOR_STATUSES.bad
      : GO_HEALTH_COLOR_STATUSES.offline;

export const goMapPeerGraphDataToNonMagneticColors = (
  peerGraphData: SingleBarGraphData[],
): PeerGraphData[] => {
  return peerGraphData.map((dataPoint) => {
    return {
      percentage: dataPoint.percentage,
      color: dataPoint.color
        ? unmagnetizedColor(dataPoint.color)
        : GO_HEALTH_COLOR_STATUSES.offline,
    };
  });
};
