import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Text, Toggle } from "@meraki/magnetic/components";
import { Card } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import { useSsid, useSsidSplashSettings, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { convertFrequency } from "../utils/splashUtils";

export function LandingPageScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "LandingPage">>();
  const networkId = useCurrentNetworkId();
  const updateSsid = useUpdateSsid();

  const queryClient = useQueryClient();
  const { params: props } = route;
  const { data: splashSettings } = useSsidSplashSettings({
    networkId,
    ssidNumber: props?.ssidNumber,
  });
  const { data: ssid } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  useEffect(
    () =>
      navigation.setOptions({
        headerTitle: ssid?.name,
      }),
    [navigation, ssid?.name],
  );

  const onSetDirectAccess = () => {
    updateSsid.mutate(
      {
        networkId,
        ssidNumber: props?.ssidNumber,
        body: {
          splashPage: "None",
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSsidSplashSettings.queryKeyRoot });
        },
      },
    );
  };

  const isDirectAccess = splashSettings?.splashMethod === "None";
  const isClickThrough =
    splashSettings?.splashMethod === "Click-through splash page" && !splashSettings?.useSplashUrl;
  const isExternal =
    splashSettings?.splashMethod === "Click-through splash page" && splashSettings?.useSplashUrl;

  const splashTimeout = (splashSettings?.splashTimeout ?? 1800) * 60;

  return (
    <Screen addDefaultPadding>
      <Heading size="h1">{I18n.t("LANDING_PAGE_SCREEN.TITLE")}</Heading>
      <Text size="p1">{I18n.t("LANDING_PAGE_SCREEN.DESCRIPTION")}</Text>

      <List>
        <List.Item
          title={I18n.t("LANDING_PAGE_SCREEN.DIRECT_ACCESS.TITLE")}
          description={I18n.t("LANDING_PAGE_SCREEN.DIRECT_ACCESS.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              testID="DIRECT_ACCESS_TOGGLE"
              checked={isDirectAccess}
              onValueChange={(value) => {
                if (value) {
                  onSetDirectAccess();
                }
              }}
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("LANDING_PAGE_SCREEN.CLICK_THROUGH.TITLE")}
          description={I18n.t("LANDING_PAGE_SCREEN.CLICK_THROUGH.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              checked={isClickThrough}
              testID="CLICK_THROUGH_TOGGLE"
              onValueChange={(enabled) =>
                enabled
                  ? navigation.navigate("ClickThrough", { ssidNumber: props?.ssidNumber })
                  : onSetDirectAccess()
              }
            />
          }
        >
          {isClickThrough && (
            <Card padding="xs">
              <List.Item
                title={splashSettings?.redirectUrl}
                rightAccessory={
                  <Button
                    text="Edit"
                    kind="tertiary"
                    onPress={() =>
                      navigation.navigate("ClickThrough", { ssidNumber: props?.ssidNumber })
                    }
                  />
                }
                last
              >
                <Text color="light">{convertFrequency(splashTimeout, true)}</Text>
              </List.Item>
            </Card>
          )}
        </List.Item>
      </List>
      <List>
        <List.Item
          title={I18n.t("LANDING_PAGE_SCREEN.EXTERNALLY_HOSTED.TITLE")}
          description={I18n.t("LANDING_PAGE_SCREEN.EXTERNALLY_HOSTED.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              checked={isExternal}
              onValueChange={(enabled) => {
                enabled
                  ? navigation.navigate("ExternallyHosted", { ssidNumber: props?.ssidNumber })
                  : onSetDirectAccess();
              }}
            />
          }
        >
          {isExternal && (
            <Card padding="xs">
              <List.Item
                title={splashSettings?.splashUrl}
                rightAccessory={
                  <Button
                    text="Edit"
                    kind="tertiary"
                    onPress={() =>
                      navigation.navigate("ExternallyHosted", { ssidNumber: props?.ssidNumber })
                    }
                  />
                }
                last={true}
              >
                <Text color="light">{convertFrequency(splashTimeout, true)}</Text>
              </List.Item>
            </Card>
          )}
        </List.Item>
      </List>
    </Screen>
  );
}
