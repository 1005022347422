import PortTypes from "~/constants/PortLayouts";

export function generatePortBlock(
  type: PortTypes,
  numPorts: number,
  portsPerBlock: number,
  rows: number,
) {
  return Array(numPorts / portsPerBlock).fill({ type, num: portsPerBlock, rows });
}
