import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import { WebView } from "react-native-webview";

import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import { CloseButton } from "~/shared/navigation/Buttons";

import { NetworkScreensPropMap } from "../navigation/Types";

export const SplashPagePreviewScreen = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<NetworkScreensPropMap, "SplashPagePreview">>();
  const { params: props } = route;
  const { previewURL } = props;

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={() => navigation.goBack()} />,
    });
  }, [navigation]);

  return (
    <FullScreenContainerView>
      <WebView
        startInLoadingState
        source={{ uri: previewURL }}
        originWhitelist={["*"]}
        renderLoading={() => <LoadingSpinner visible />}
        testID="SPLASH_PREVIEW_WEBVIEW"
      />
    </FullScreenContainerView>
  );
};
