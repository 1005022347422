export const CSRF_TOKEN_REQUEST = "CSRF_TOKEN_REQUEST";
export const CSRF_TOKEN_SUCCESS = "CSRF_TOKEN_SUCCESS";
export const CSRF_TOKEN_FAILURE = "CSRF_TOKEN_FAILURE";
export const DEVICE_USAGE_REQUEST = "DEVICE_USAGE_REQUEST";
export const DEVICE_USAGE_SUCCESS = "DEVICE_USAGE_SUCCESS";
export const DEVICE_USAGE_FAILURE = "DEVICE_USAGE_FAILURE";
export const DEVICE_CLIENTS_REQUEST = "DEVICE_CLIENTS_REQUEST";
export const DEVICE_CLIENTS_SUCCESS = "DEVICE_CLIENTS_SUCCESS";
export const DEVICE_CLIENTS_FAILURE = "DEVICE_CLIENTS_FAILURE";
export const DEVICE_UPLINK_REQUEST = "DEVICE_UPLINK_REQUEST";
export const DEVICE_UPLINK_SUCCESS = "DEVICE_UPLINK_SUCCESS";
export const DEVICE_UPLINK_FAILURE = "DEVICE_UPLINK_FAILURE";
export const DEVICE_LLDP_REQUEST = "DEVICE_LLDP_REQUEST";
export const DEVICE_LLDP_SUCCESS = "DEVICE_LLDP_SUCCESS";
export const DEVICE_LLDP_FAILURE = "DEVICE_LLDP_FAILURE";
export const SHOW_CAPTCHA = "SHOW_CAPTCHA";
export const SET_RESET_TFA = "SET_RESET_TFA";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const REQUEST_PASSWORD_RESET_REQUEST = "REQUEST_PASSWORD_RESET_REQUEST";
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";
export const REQUEST_PASSWORD_RESET_FAILURE = "REQUEST_PASSWORD_RESET_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const NETWORK_TRACK_SETTINGS_REQUEST = "NETWORK_TRACK_SETTINGS_REQUEST";
export const NETWORK_TRACK_SETTINGS_SUCCESS = "NETWORK_TRACK_SETTINGS_SUCCESS";
export const NETWORK_TRACK_SETTINGS_FAILURE = "NETWORK_TRACK_SETTINGS_FAILURE";
export const NETWORK_WARM_SPARE_REQUEST = "NETWORK_WARM_SPARE_REQUEST";
export const NETWORK_WARM_SPARE_SUCCESS = "NETWORK_WARM_SPARE_SUCCESS";
export const NETWORK_WARM_SPARE_FAILURE = "NETWORK_WARM_SPARE_FAILURE";
export const NODES_REQUEST = "NODES_REQUEST";
export const NODES_SUCCESS = "NODES_SUCCESS";
export const NODES_FAILURE = "NODES_FAILURE";
export const SET_CURRENT_NETWORK = "SET_CURRENT_NETWORK";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_CURRENT_SHARD_ID = "SET_CURRENT_SHARD_ID";
export const SET_CLIENT_INFO = "SET_CLIENT_INFO";
export const SET_LATEST_SUPPORT_SUBMIT_TIME = "SET_LATEST_SUPPORT_SUBMIT_TIME";
export const SET_SM_CLIENT_INFO = "SET_SM_CLIENT_INFO";
export const UPDATE_EXPANDABLE_CARDS = "UPDATE_EXPANDABLE_CARDS";
export const SMS_AUTH_RETRY_REQUEST = "SMS_AUTH_RETRY_REQUEST";
export const SMS_AUTH_RETRY_SUCCESS = "SMS_AUTH_RETRY_SUCCESS";
export const SMS_AUTH_RETRY_FAILURE = "SMS_AUTH_RETRY_FAILURE";
export const GET_TWO_FACTOR_SECRET_REQUEST = "GET_TWO_FACTOR_SECRET_REQUEST";
export const GET_TWO_FACTOR_SECRET_SUCCESS = "GET_TWO_FACTOR_SECRET_SUCCESS";
export const GET_TWO_FACTOR_SECRET_FAILURE = "GET_TWO_FACTOR_SECRET_FAILURE";
export const ENABLE_TWO_FACTOR_REQUEST = "ENABLE_TWO_FACTOR_REQUEST";
export const ENABLE_TWO_FACTOR_SUCCESS = "ENABLE_TWO_FACTOR_SUCCESS";
export const ENABLE_TWO_FACTOR_FAILURE = "ENABLE_TWO_FACTOR_FAILURE";
export const VERIFY_TWO_FACTOR_REQUEST = "VERIFY_TWO_FACTOR_REQUEST";
export const VERIFY_TWO_FACTOR_SUCCESS = "VERIFY_TWO_FACTOR_SUCCESS";
export const VERIFY_TWO_FACTOR_FAILURE = "VERIFY_TWO_FACTOR_FAILURE";
export const REAUTHENTICATE_FOR_TWO_FACTOR_REQUEST = "REAUTHENTICATE_FOR_TWO_FACTOR_REQUEST";
export const REAUTHENTICATE_FOR_TWO_FACTOR_SUCCESS = "REAUTHENTICATE_FOR_TWO_FACTOR_SUCCESS";
export const REAUTHENTICATE_FOR_TWO_FACTOR_FAILURE = "REAUTHENTICATE_FOR_TWO_FACTOR_FAILURE";
export const DISABLE_TWO_FACTOR_REQUEST = "DISABLE_TWO_FACTOR_REQUEST";
export const DISABLE_TWO_FACTOR_SUCCESS = "DISABLE_TWO_FACTOR_SUCCESS";
export const DISABLE_TWO_FACTOR_FAILURE = "DISABLE_TWO_FACTOR_FAILURE";
export const TWO_FACTOR_REQUEST = "TWO_FACTOR_REQUEST";
export const TWO_FACTOR_SUCCESS = "TWO_FACTOR_SUCCESS";
export const TWO_FACTOR_FAILURE = "TWO_FACTOR_FAILURE";
export const ORG_CHOOSE_REQUEST = "ORG_CHOOSE_REQUEST";
export const ORG_CHOOSE_SUCCESS = "ORG_CHOOSE_SUCCESS";
export const ORG_CHOOSE_FAILURE = "ORG_CHOOSE_FAILURE";
export const ORG_CHOSEN = "ORG_CHOSEN";
export const ORG_OVERVIEW_REQUEST = "ORG_OVERVIEW_REQUEST";
export const ORG_OVERVIEW_SUCCESS = "ORG_OVERVIEW_SUCCESS";
export const ORG_OVERVIEW_FAILURE = "ORG_OVERVIEW_FAILURE";
export const CLIENT_LIST_JSON_REQUEST = "CLIENT_LIST_JSON_REQUEST";
export const CLIENT_LIST_JSON_SUCCESS = "CLIENT_LIST_JSON_SUCCESS";
export const CLIENT_LIST_JSON_FAILURE = "CLIENT_LIST_JSON_FAILURE";
export const SM_DEVICE_LIST_JSON_REQUEST = "SM_DEVICE_LIST_JSON_REQUEST";
export const SM_DEVICE_LIST_JSON_SUCCESS = "SM_DEVICE_LIST_JSON_SUCCESS";
export const SM_DEVICE_LIST_JSON_FAILURE = "SM_DEVICE_LIST_JSON_FAILURE";
export const CLEAR_SM_DEVICES = "CLEAR_SM_DEVICES";
export const CLIENTS_AND_USEBLOCKS_START = "CLIENTS_AND_USEBLOCKS_START";
export const CLIENTS_AND_USEBLOCKS_FINISH = "CLIENTS_AND_USEBLOCKS_FINISH";
export const NETWORK_USEBLOCKS_REQUEST = "NETWORK_USEBLOCKS_REQUEST";
export const NETWORK_USEBLOCKS_SUCCESS = "NETWORK_USEBLOCKS_SUCCESS";
export const NETWORK_USEBLOCKS_FAILURE = "NETWORK_USEBLOCKS_FAILURE";
export const COUNTER_SETS_REQUEST = "COUNTER_SETS_REQUEST";
export const COUNTER_SETS_SUCCESS = "COUNTER_SETS_SUCCESS";
export const COUNTER_SETS_FAILURE = "COUNTER_SETS_FAILURE";
export const SSID_GROUP_POLICY_REQUEST = "SSID_GROUP_POLICY_REQUEST";
export const SSID_GROUP_POLICY_SUCCESS = "SSID_GROUP_POLICY_SUCCESS";
export const SSID_GROUP_POLICY_FAILURE = "SSID_GROUP_POLICY_FAILURE";
export const EVENT_DATA_REQUEST = "EVENT_DATA_REQUEST";
export const EVENT_DATA_SUCCESS = "EVENT_DATA_SUCCESS";
export const EVENT_DATA_FAILURE = "EVENT_DATA_FAILURE";
export const CONNECTIVITY_DATA_REQUEST = "CONNECTIVITY_DATA_REQUEST";
export const CONNECTIVITY_DATA_SUCCESS = "CONNECTIVITY_DATA_SUCCESS";
export const CONNECTIVITY_DATA_FAILURE = "CONNECTIVITY_DATA_FAILURE";
export const DELETE_CONNECTIVITY_EVENTS = "DELETE_CONNECTIVITY_EVENTS ";
export const STORE_TFA_REMEMBER_ME = "STORE_TFA_REMEMBER_ME";
export const WIPE_REDUX = "WIPE_REDUX";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_BIOMETRIC_AUTH = "SET_BIOMETRIC_AUTH";
export const SET_NESTED_MODAL_DATA = "SET_NESTED_MODAL_DATA";
export const CLEAR_NESTED_MODAL_DATA = "CLEAR_NESTED_MODAL_DATA";
export const SET_MANY_POLICIES_REQUEST = "SET_MANY_POLICES_REQUEST";
export const SET_MANY_POLICIES_SUCCESS = "SET_MANY_POLICES_SUCCESS";
export const SET_MANY_POLICIES_FAILURE = "SET_MANY_POLICES_FAILURE";
export const SET_TIMESPAN = "SET_TIMESPAN";
export const SET_CLIENTS_LIST_TIMESPAN = "SET_CLIENTS_LIST_TIMESPAN";
export const SET_WIRELESS_TIMESPAN = "SET_WIRELESS_TIMESPAN";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
export const SET_ONBOARDING_WIRE_VS_MESH = "SET_ONBOARDING_WIRE_VS_MESH";
export const SET_ONBOARDING_UNCONFIGURED = "SET_ONBOARDING_UNCONFIGURED";
export const ADD_ONBOARDING_SCANNED = "ADD_ONBOARDING_SCANNED";
export const NETWORK_HEALTH_REQUEST = "NETWORK_HEALTH_REQUEST";
export const NETWORK_HEALTH_SUCCESS = "NETWORK_HEALTH_SUCCESS";
export const NETWORK_HEALTH_FAILURE = "NETWORK_HEALTH_FAILURE";
export const SSIDS_REQUEST = "SSIDS_REQUEST";
export const SSIDS_SUCCESS = "SSIDS_SUCCESS";
export const SSIDS_FAILURE = "SSIDS_FAILURE";
export const SET_SSID_REQUEST = "SET_SSID_REQUEST";
export const SET_SSID_SUCCESS = "SET_SSID_SUCCESS";
export const SET_SSID_FAILURE = "SET_SSID_FAILURE";
export const DELETE_SSID_REQUEST = "DELETE_SSID_REQUEST";
export const DELETE_SSID_SUCCESS = "DELETE_SSID_SUCCESS";
export const DELETE_SSID_FAILURE = "DELETE_SSID_FAILURE";
export const SET_REDUX_SSIDS = "SET_REDUX_SSIDS";
export const RULE_USEBLOCKS_REQUEST = "RULE_USEBLOCKS_REQUEST";
export const RULE_USEBLOCKS_SUCCESS = "RULE_USEBLOCKS_SUCCESS";
export const RULE_USEBLOCKS_FAILURE = "RULE_USEBLOCKS_FAILURE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const CLEAR_SEARCH_TEXT = "CLEAR_SEARCH_TEXT";
export const INVENTORY_CLAIM_REQUEST = "INVENTORY_CLAIM_REQUEST";
export const INVENTORY_CLAIM_SUCCESS = "INVENTORY_CLAIM_SUCCESS";
export const INVENTORY_CLAIM_FAILURE = "INVENTORY_CLAIM_FAILURE";
export const NETWORK_CLAIM_DEVICE_REQUEST = "NETWORK_CLAIM_DEVICE_REQUEST";
export const NETWORK_CLAIM_DEVICE_SUCCESS = "NETWORK_CLAIM_DEVICE_SUCCESS";
export const NETWORK_CLAIM_DEVICE_FAILURE = "NETWORK_CLAIM_DEVICE_FAILURE";
export const INVENTORY_REMOVE_REQUEST = "INVENTORY_REMOVE_REQUEST";
export const INVENTORY_REMOVE_SUCCESS = "INVENTORY_REMOVE_SUCCESS";
export const INVENTORY_REMOVE_FAILURE = "INVENTORY_REMOVE_FAILURE";
export const CREATE_NETWORK_REQUEST = "CREATE_NETWORK_REQUEST";
export const CREATE_NETWORK_SUCCESS = "CREATE_NETWORK_SUCCESS";
export const CREATE_NETWORK_FAILURE = "CREATE_NETWORK_FAILURE";
export const CREATE_GO_NETWORK_REQUEST = "CREATE_GO_NETWORK_REQUEST";
export const CREATE_GO_NETWORK_SUCCESS = "CREATE_GO_NETWORK_SUCCESS";
export const CREATE_GO_NETWORK_FAILURE = "CREATE_GO_NETWORK_FAILURE";
export const ADD_NODES_REQUEST = "ADD_NODES_REQUEST";
export const ADD_NODES_SUCCESS = "ADD_NODES_SUCCESS";
export const ADD_NODES_FAILURE = "ADD_NODES_FAILURE";
export const REMOVE_NODE_REQUEST = "REMOVE_NODE_REQUEST";
export const REMOVE_NODE_SUCCESS = "REMOVE_NODE_SUCCESS";
export const REMOVE_NODE_FAILURE = "REMOVE_NODE_FAILURE";
export const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_FAILURE = "UPDATE_DEVICE_FAILURE";
export const SET_DEVICE = "SET_DEVICE";
export const SET_DEFAULT_NETWORK = "SET_DEFAULT_NETWORK";
export const LB_INIT = "LB_INIT";
export const LB_CONNECTED = "LB_CONNECTED";
export const LB_DISCONNECT = "LB_DISCONNECT";
export const LB_DISCONNECTED = "LB_DISCONNECTED";
export const LB_UPDATE_SUBSCRIPTION = "LB_UPDATE_SUBSCRIPTION";
export const LB_HANDSHAKE = "LB_HANDSHAKE";
export const LB_ADD_PUBLISH_DATA = "LB_ADD_PUBLISH_DATA";
export const LB_REMOVE_PUBLISH_DATA = "LB_REMOVE_PUBLISH_DATA";
export const SWITCH_PORTS_REQUEST = "SWITCH_PORTS_REQUEST";
export const SWITCH_PORTS_SUCCESS = "SWITCH_PORTS_SUCCESS";
export const SWITCH_PORTS_FAILURE = "SWITCH_PORTS_FAILURE";
export const SWITCH_PORTS_JSON_REQUEST = "SWITCH_PORTS_JSON_REQUEST";
export const SWITCH_PORTS_JSON_SUCCESS = "SWITCH_PORTS_JSON_SUCCESS";
export const SWITCH_PORTS_JSON_FAILURE = "SWITCH_PORTS_JSON_FAILURE";
export const GX_PORTS_UPDATE = "GX_PORTS_UPDATE";
export const NODES_JSON_REQUEST = "NODES_JSON_REQUEST";
export const NODES_JSON_SUCCESS = "NODES_JSON_SUCCESS";
export const NODES_JSON_FAILURE = "NODES_JSON_FAILURE";
export const MANAGEMENT_INTERFACE_REQUEST = "MANAGEMENT_INTERFACE_REQUEST";
export const MANAGEMENT_INTERFACE_SUCCESS = "MANAGEMENT_INTERFACE_SUCCESS";
export const MANAGEMENT_INTERFACE_FAILURE = "MANAGEMENT_INTERFACE_FAILURE";
export const CLIENT_PING_REQUEST = "CLIENT_PING_REQUEST";
export const CLIENT_PING_SUCCESS = "CLIENT_PING_SUCCESS";
export const CLIENT_PING_FAILURE = "CLIENT_PING_FAILURE";
export const ADMINS_REQUEST = "ADMINS_REQUEST";
export const ADMINS_SUCCESS = "ADMINS_SUCCESS";
export const ADMINS_FAILURE = "ADMINS_FAILURE";
export const SET_ADMIN_REQUEST = "SET_ADMIN_REQUEST";
export const SET_ADMIN_SUCCESS = "SET_ADMIN_SUCCESS";
export const SET_ADMIN_FAILURE = "SET_ADMIN_FAILURE";
export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const LOCALE_USAGE_REQUEST = "LOCALE_USAGE_REQUEST";
export const LOCALE_USAGE_SUCCESS = "LOCALE_USAGE_SUCCESS";
export const LOCALE_USAGE_FAILURE = "LOCALE_USAGE_FAILURE";
export const GET_WIRELESS_SETTINGS_REQUEST = "GET_WIRELESS_SETTINGS_REQUEST";
export const GET_WIRELESS_SETTINGS_SUCCESS = "GET_WIRELESS_SETTINGS_SUCCESS";
export const GET_WIRELESS_SETTINGS_FAILURE = "GET_WIRELESS_SETTINGS_FAILURE";
export const SET_LOCATION_ANALYTICS_ENABLED_REQUEST = "SET_LOCATION_ANALYTICS_ENABLED_REQUEST";
export const SET_LOCATION_ANALYTICS_ENABLED_SUCCESS = "SET_LOCATION_ANALYTICS_ENABLED_SUCCESS";
export const SET_LOCATION_ANALYTICS_ENABLED_FAILURE = "SET_LOCATION_ANALYTICS_ENABLED_FAILURE";
export const GET_FIRMWARE_UPGRADES_REQUEST = "GET_FIRMWARE_UPGRADES_REQUEST";
export const GET_FIRMWARE_UPGRADES_SUCCESS = "GET_FIRMWARE_UPGRADES_SUCCESS";
export const GET_FIRMWARE_UPGRADES_FAILURE = "GET_FIRMWARE_UPGRADES_FAILURE";
export const SET_FIRMWARE_UPGRADES_REQUEST = "SET_FIRMWARE_UPGRADES_REQUEST";
export const SET_FIRMWARE_UPGRADES_SUCCESS = "SET_FIRMWARE_UPGRADES_SUCCESS";
export const SET_FIRMWARE_UPGRADES_FAILURE = "SET_FIRMWARE_UPGRADES_FAILURE";
export const SSID_USEBLOCKS_REQUEST = "SSID_USEBLOCKS_REQUEST";
export const SSID_USEBLOCKS_SUCCESS = "SSID_USEBLOCKS_SUCCESS";
export const SSID_USEBLOCKS_FAILURE = "SSID_USEBLOCKS_FAILURE";
export const UPDATE_SPLASH_PAGE_REQUEST = "UPDATE_SPLASH_PAGE_REQUEST";
export const UPDATE_SPLASH_PAGE_SUCCESS = "UPDATE_SPLASH_PAGE_SUCCESS";
export const UPDATE_SPLASH_PAGE_FAILURE = "UPDATE_SPLASH_PAGE_FAILURE";
export const UPDATE_SPLASH_LOGO_REQUEST = "UPDATE_SPLASH_LOGO_REQUEST";
export const UPDATE_SPLASH_LOGO_SUCCESS = "UPDATE_SPLASH_LOGO_SUCCESS";
export const UPDATE_SPLASH_LOGO_FAILURE = "UPDATE_SPLASH_LOGO_FAILURE";
export const SPLASH_PREVIEW_REQUEST = "SPLASH_PREVIEW_REQUEST";
export const SPLASH_PREVIEW_SUCCESS = "SPLASH_PREVIEW_SUCCESS";
export const SPLASH_PREVIEW_FAILURE = "SPLASH_PREVIEW_FAILURE";
export const UPDATE_ACCESS_CONTROL_REQUEST = "UPDATE_ACCESS_CONTROL_REQUEST";
export const UPDATE_ACCESS_CONTROL_SUCCESS = "UPDATE_ACCESS_CONTROL_SUCCESS";
export const UPDATE_ACCESS_CONTROL_FAILURE = "UPDATE_ACCESS_CONTROL_FAILURE";
export const SET_HOME_SSID = "SET_HOME_SSID";
export const ALERT_SETTINGS_REQUEST = "ALERT_SETTINGS_REQUEST";
export const ALERT_SETTINGS_SUCCESS = "ALERT_SETTINGS_SUCCESS";
export const ALERT_SETTINGS_FAILURE = "ALERT_SETTINGS_FAILURE";
export const SET_ALERT_SETTINGS_REQUEST = "SET_ALERT_SETTINGS_REQUEST";
export const SET_ALERT_SETTINGS_SUCCESS = "SET_ALERT_SETTINGS_SUCCESS";
export const SET_ALERT_SETTINGS_FAILURE = "SET_ALERT_SETTINGS_FAILURE";
export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";
export const UPDATE_SM_CLIENT_REQUEST = "UPDATE_SM_CLIENT_REQUEST";
export const UPDATE_SM_CLIENT_SUCCESS = "UPDATE_SM_CLIENT_SUCCESS";
export const UPDATE_SM_CLIENT_FAILURE = "UPDATE_SM_CLIENT_FAILURE";
export const UDG_NODES_STATUS_REQUEST = "UDG_NODES_STATUS_REQUEST";
export const UDG_NODES_STATUS_SUCCESS = "UDG_NODES_STATUS_SUCCESS";
export const UDG_NODES_STATUS_FAILURE = "UDG_NODES_STATUS_FAILURE";
export const LT_REQUEST = "LT_REQUEST";
export const LT_SUCCESS = "LT_SUCCESS";
export const LT_FAILURE = "LT_FAILURE";
export const LT_CYCLE_PORT_REQUEST = "CYCLE_PORT_REQUEST";
export const LT_CYCLE_PORT_SUCCESS = "CYCLE_PORT_SUCCESS";
export const LT_CYCLE_PORT_FAILURE = "CYCLE_PORT_FAILURE";
export const SM_LOCK_REQUEST = "SM_LOCK_REQUEST";
export const SM_LOCK_SUCCESS = "SM_LOCK_SUCCESS";
export const SM_LOCK_FAILURE = "SM_LOCK_FAILURE";
export const SM_WIPE_REQUEST = "SM_WIPE_REQUEST";
export const SM_WIPE_SUCCESS = "SM_WIPE_SUCCESS";
export const SM_WIPE_FAILURE = "SM_WIPE_FAILURE";
export const SM_CHECK_IN_REQUEST = "SM_CHECK_IN_REQUEST";
export const SM_CHECK_IN_SUCCESS = "SM_CHECK_IN_SUCCESS";
export const SM_CHECK_IN_FAILURE = "SM_CHECK_IN_FAILURE";
export const REGISTER_SESSION_REQUEST = "REGISTER_SESSION_REQUEST";
export const REGISTER_SESSION_SUCCESS = "REGISTER_SESSION_SUCCESS";
export const REGISTER_SESSION_FAILURE = "REGISTER_SESSION_FAILURE";
export const CREATE_SUPPORT_CASE_REQUEST = "CREATE_SUPPORT_CASE_REQUEST";
export const CREATE_SUPPORT_CASE_SUCCESS = "CREATE_SUPPORT_CASE_SUCCESS";
export const CREATE_SUPPORT_CASE_FAILURE = "CREATE_SUPPORT_CASE_FAILURE";
export const CREATE_FEEDBACK_CASE_REQUEST = "CREATE_FEEDBACK_CASE_REQUEST";
export const CREATE_FEEDBACK_CASE_SUCCESS = "CREATE_FEEDBACK_CASE_SUCCESS";
export const CREATE_FEEDBACK_CASE_FAILURE = "CREATE_FEEDBACK_CASE_FAILURE";
export const VLANS_GET_REQUEST = "VLANS_GET_REQUEST";
export const VLANS_GET_SUCCESS = "VLANS_GET_SUCCESS";
export const VLANS_GET_FAIL = "VLANS_GET_FAIL";
export const VLAN_UPDATE_REQUEST = "VLAN_UPDATE_REQUEST";
export const VLAN_UPDATE_SUCCESS = "VLAN_UPDATE_SUCCESS";
export const VLAN_UPDATE_FAILURE = "VLAN_UPDATE_FAILURE";
export const OTP_AUTH_REQUEST = "OTP_AUTH_REQUEST";
export const OTP_AUTH_SUCCESS = "OTP_AUTH_SUCCESS";
export const OTP_AUTH_FAILURE = "OTP_AUTH_FAILURE";
export const OTP_AUTH_RETRY_REQUEST = "OTP_AUTH_RETRY_REQUEST";
export const OTP_AUTH_RETRY_SUCCESS = "OTP_AUTH_RETRY_SUCCESS";
export const OTP_AUTH_RETRY_FAILURE = "OTP_AUTH_RETRY_FAILURE";
export const ONBOARDING_ADD_NODE = "ONBOARDING_ADD_NODE";
export const ONBAORDING_BATCH_CLAIM_REQUEST = "ONBAORDING_BATCH_CLAIM_REQUEST";
export const ONBAORDING_BATCH_CLAIM_SUCCESS = "ONBAORDING_BATCH_CLAIM_SUCCESS";
export const ONBAORDING_BATCH_CLAIM_FAILURE = "ONBAORDING_BATCH_CLAIM_FAILURE";
export const ONBOARDING_SET_FLOW = "ONBOARDING_SET_FLOW";
export const ONBOARDING_SET_STAGE = "ONBOARDING_SET_STAGE";
export const ONBOARDING_SET_SKIPPED = "ONBOARDING_SET_SKIPPED";
export const ONBOARDING_SET_STATUS = "ONBOARDING_SET_STATUS";
export const ONBOARDING_SET_NODES = "ONBOARDING_SET_NODES";
export const ONBOARDING_SET_IS_COMPLETE = "ONBOARDING_SET_IS_COMPLETE";
export const ONBOARDING_SET_IS_FORCED = "ONBOARDING_SET_IS_FORCED";
export const SET_ONBOARDING_DEVICE_EDITS = "SET_ONBOARDING_DEVICE_EDITS";
export const CLEAR_ONBOARDING_DEVICE_EDITS = "CLEAR_ONBOARDING_DEVICE_EDITS";
export const SET_DEVICE_ONBOARDING_CONTINUED = "SET_DEVICE_ONBOARDING_CONTINUED";
export const SET_DEVICE_ONBOARDING_COMPLETE = "SET_DEVICE_ONBOARDING_COMPLETE";
export const WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS = "WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS";
export const WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW = "WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW";
export const GET_LOGIN_SECURITY_REQUEST = "GET_LOGIN_SECURITY_REQUEST";
export const GET_LOGIN_SECURITY_SUCCESS = "GET_LOGIN_SECURITY_SUCCESS";
export const GET_LOGIN_SECURITY_FAILURE = "GET_LOGIN_SECURITY_FAILURE";
export const SET_LOGIN_SECURITY_REQUEST = "SET_LOGIN_SECURITY_REQUEST";
export const SET_LOGIN_SECURITY_SUCCESS = "SET_LOGIN_SECURITY_SUCCESS";
export const SET_LOGIN_SECURITY_FAILURE = "SET_LOGIN_SECURITY_FAILURE";
export const UMBRELLA_ACTIVITY_REQUEST = "UMBRELLA_ACTIVITY_REQUEST";
export const UMBRELLA_ACTIVITY_SUCCESS = "UMBRELLA_ACTIVITY_SUCCESS";
export const UMBRELLA_ACTIVITY_FAILURE = "UMBRELLA_ACTIVITY_FAILURE";
export const UMBRELLA_OAUTH_TOKEN_REQUEST = "UMBRELLA_OAUTH_TOKEN_REQUEST";
export const UMBRELLA_OAUTH_TOKEN_SUCCESS = "UMBRELLA_OAUTH_TOKEN_SUCCESS";
export const UMBRELLA_OAUTH_TOKEN_FAILURE = "UMBRELLA_OAUTH_TOKEN_FAILURE";
export const DOCUMENTATION_SEARCH_REQUEST = "DOCUMENTATION_SEARCH_REQUEST";
export const DOCUMENTATION_SEARCH_SUCCESS = "DOCUMENTATION_SEARCH_SUCCESS";
export const DOCUMENTATION_SEARCH_FAILURE = "DOCUMENTATION_SEARCH_FAILURE";
export const COMMUNITY_SEARCH_REQUEST = "COMMUNITY_SEARCH_REQUEST";
export const COMMUNITY_SEARCH_SUCCESS = "COMMUNITY_SEARCH_SUCCESS";
export const COMMUNITY_SEARCH_FAILURE = "COMMUNITY_SEARCH_FAILURE";
export const COMMUNITY_SEARCH_PARENT_REQUEST = "COMMUNITY_SEARCH_PARENT_REQUEST";
export const COMMUNITY_SEARCH_PARENT_SUCCESS = "COMMUNITY_SEARCH_PARENT_SUCCESS";
export const COMMUNITY_SEARCH_PARENT_FAILURE = "COMMUNITY_SEARCH_PARENT_FAILURE";
export const MX_GET_L7_FIREWALL_RULES_REQUEST = "MX_GET_L7_FIREWALL_RULES_REQUEST";
export const MX_GET_L7_FIREWALL_RULES_SUCCESS = "MX_GET_L7_FIREWALL_RULES_SUCCESS";
export const MX_GET_L7_FIREWALL_RULES_FAILURE = "MX_GET_L7_FIREWALL_RULES_FAILURE";
export const MX_PUT_L7_FIREWALL_RULES_REQUEST = "MX_PUT_L7_FIREWALL_RULES_REQUEST";
export const MX_PUT_L7_FIREWALL_RULES_SUCCESS = "MX_PUT_L7_FIREWALL_RULES_SUCCESS";
export const MX_PUT_L7_FIREWALL_RULES_FAILURE = "MX_PUT_L7_FIREWALL_RULES_FAILURE";
export const MX_TRAFFIC_SHAPING_GET_REQUEST = "MX_TRAFFIC_SHAPING_GET_REQUEST";
export const MX_TRAFFIC_SHAPING_GET_SUCCESS = "MX_TRAFFIC_SHAPING_GET_SUCCESS";
export const MX_TRAFFIC_SHAPING_GET_FAILURE = "MX_TRAFFIC_SHAPING_GET_FAILURE";
export const MX_TRAFFIC_SHAPING_PUT_REQUEST = "MX_TRAFFIC_SHAPING_PUT_REQUEST";
export const MX_TRAFFIC_SHAPING_PUT_SUCCESS = "MX_TRAFFIC_SHAPING_PUT_SUCCESS";
export const MX_TRAFFIC_SHAPING_PUT_FAILURE = "MX_TRAFFIC_SHAPING_PUT_FAILURE";
export const PRESENCE_DATA_REQUEST = "PRESENCE_DATA_REQUEST";
export const PRESENCE_DATA_SUCCESS = "PRESENCE_DATA_SUCCESS";
export const PRESENCE_DATA_FAILURE = "PRESENCE_DATA_FAILURE";
export const UMBRELLA_TOTAL_REQUESTS_REQUEST = "UMBRELLA_TOTAL_REQUESTS_REQUEST";
export const UMBRELLA_TOTAL_REQUESTS_SUCCESS = "UMBRELLA_TOTAL_REQUESTS_SUCCESS";
export const UMBRELLA_TOTAL_REQUESTS_FAILURE = "UMBRELLA_TOTAL_REQUESTS_FAILURE";
export const UMBRELLA_HIGH_USAGE_IPS_REQUEST = "UMBRELLA_HIGH_USAGE_IPS_REQUEST";
export const UMBRELLA_HIGH_USAGE_IPS_SUCCESS = "UMBRELLA_HIGH_USAGE_IPS_SUCCESS";
export const UMBRELLA_HIGH_USAGE_IPS_FAILURE = "UMBRELLA_HIGH_USAGE_IPS_FAILURE";
export const UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST = "UMBRELLA_TOP_DESTINATIONS_BY_IP_REQUEST";
export const UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS = "UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS";
export const UMBRELLA_TOP_DESTINATIONS_BY_IP_FAILURE = "UMBRELLA_TOP_DESTINATIONS_BY_IP_FAILURE";
export const UMBRELLA_ACTIVITIES_FOR_IP_REQUEST = "UMBRELLA_ACTIVITIES_FOR_IP_REQUEST";
export const UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS = "UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS";
export const UMBRELLA_ACTIVITIES_FOR_IP_FAILURE = "UMBRELLA_ACTIVITIES_FOR_IP_FAILURE";
export const NODE_IMAGE_UPLOAD_REQUEST = "NODE_IMAGE_UPLOAD_REQUEST";
export const NODE_IMAGE_UPLOAD_SUCCESS = "NODE_IMAGE_UPLOAD_SUCCESS";
export const NODE_IMAGE_UPLOAD_FAILURE = "NODE_IMAGE_UPLOAD_FAILURE";
export const NODE_IMAGE_DELETE_REQUEST = "NODE_IMAGE_DELETE_REQUEST";
export const NODE_IMAGE_DELETE_SUCCESS = "NODE_IMAGE_DELETE_SUCCESS";
export const NODE_IMAGE_DELETE_FAILURE = "NODE_IMAGE_DELETE_FAILURE";
export const UMBRELLA_FETCHING_REQUEST = "UMBRELLA_FETCHING_REQUEST";
export const UMBRELLA_FETCHING_SUCCESS = "UMBRELLA_FETCHING_SUCCESS";
export const UMBRELLA_FETCHING_FAILURE = "UMBRELLA_FETCHING_FAILURE";
export const ONBOARDING_TIMING_START = "ONBOARDING_TIMING_START";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const SEND_TO_MARKETO_REQUEST = "SEND_TO_MARKETO_REQUEST";
export const SEND_TO_MARKETO_SUCCESS = "SEND_TO_MARKETO_SUCCESS";
export const SEND_TO_MARKETO_FAILURE = "SEND_TO_MARKETO_FAILURE";
export const FINISHED_ADDITIONAL_LOGIN_REQUESTS = "FINISHED_ADDITIONAL_LOGIN_REQUESTS";
export const GET_UMBRELLA_STATUS_REQUEST = "GET_UMBRELLA_STATUS_REQUEST";
export const GET_UMBRELLA_STATUS_SUCCESS = "GET_UMBRELLA_STATUS_SUCCESS";
export const GET_UMBRELLA_STATUS_FAILURE = "GET_UMBRELLA_STATUS_FAILURE";
export const CONNECTION_STATS_REQUEST = "CONNECTION_STATS_REQUEST";
export const CONNECTION_STATS_SUCCESS = "CONNECTION_STATS_SUCCESS";
export const CONNECTION_STATS_FAILURE = "CONNECTION_STATS_FAILURE";
export const LATENCY_STATS_REQUEST = "LATENCY_STATS_REQUEST";
export const LATENCY_STATS_SUCCESS = "LATENCY_STATS_SUCCESS";
export const LATENCY_STATS_FAILURE = "LATENCY_STATS_FAILURE";
export const FAILED_CONNECTIONS_REQUEST = "FAILED_CONNECTIONS_REQUEST";
export const FAILED_CONNECTIONS_SUCCESS = "FAILED_CONNECTIONS_SUCCESS";
export const FAILED_CONNECTIONS_FAILURE = "FAILED_CONNECTIONS_FAILURE";
export const TIMEZONE_GET_REQUEST = "TIMEZONE_GET_REQUEST";
export const TIMEZONE_GET_SUCCESS = "TIMEZONE_GET_SUCCESS";
export const TIMEZONE_GET_FAILURE = "TIMEZONE_GET_FAILURE";
export const FLOORPLANS_REQUEST = "FLOORPLANS_REQUEST";
export const FLOORPLANS_SUCCESS = "FLOORPLANS_SUCCESS";
export const FLOORPLANS_FAILURE = "FLOORPLANS_FAILURE";
export const NODE_GROUP_REQUEST = "NODE_GROUP_REQUEST";
export const NODE_GROUP_SUCCESS = "NODE_GROUP_SUCCESS";
export const NODE_GROUP_FAILURE = "NODE_GROUP_FAILURE";
export const ORG_GET_REQUEST = "ORG_GET_REQUEST";
export const ORG_GET_SUCCESS = "ORG_GET_SUCCESS";
export const ORG_GET_FAILURE = "ORG_GET_FAILURE";
export const ORG_BY_ID_GET_REQUEST = "ORG_BY_ID_GET_REQUEST";
export const ORG_BY_ID_GET_SUCCESS = "ORG_BY_ID_GET_SUCCESS";
export const ORG_BY_ID_GET_FAILURE = "ORG_BY_ID_GET_FAILURE";
export const CLIENTS_OVERVIEW_FETCH_REQUEST = "CLIENTS_OVERVIEW_FETCH_REQUEST";
export const CLIENTS_OVERVIEW_FETCH_SUCCESS = "CLIENTS_OVERVIEW_FETCH_SUCCESS";
export const CLIENTS_OVERVIEW_FETCH_FAILURE = "CLIENTS_OVERVIEW_FETCH_FAILURE";
export const ORG_NETWORKS_GET_REQUEST = "ORG_NETWORKS_GET_REQUEST";
export const ORG_NETWORKS_GET_SUCCESS = "ORG_NETWORKS_GET_SUCCESS";
export const ORG_NETWORKS_GET_FAILURE = "ORG_NETWORKS_GET_FAILURE";
export const ORG_DEVICE_STATUSES_REQUEST = "ORG_DEVICE_STATUSES_REQUEST";
export const ORG_DEVICE_STATUSES_SUCCESS = "ORG_DEVICE_STATUSES_SUCCESS";
export const ORG_DEVICE_STATUSES_FAILURE = "ORG_DEVICE_STATUSES_FAILURE";
export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const CLEAR_VERIFIED_EMAIL = "CLEAR_VERIFIED_EMAIL";
export const SET_ORGANIZATION_NAME_REQUEST = "SET_ORGANIZATION_NAME_REQUEST";
export const SET_ORGANIZATION_NAME_SUCCESS = "SET_ORGANIZATION_NAME_SUCCESS";
export const SET_ORGANIZATION_NAME_FAILURE = "SET_ORGANIZATION_NAME_FAILURE";
export const GET_MSP_ORGANIZATION_DATA_REQUEST = "GET_MSP_ORGANIZATION_DATA_REQUEST";
export const GET_MSP_ORGANIZATION_DATA_SUCCESS = "GET_MSP_ORGANIZATION_DATA_SUCCESS";
export const GET_MSP_ORGANIZATION_DATA_FAILURE = "GET_MSP_ORGANIZATION_DATA_FAILURE";
export const GET_ORG_SP_IDP_REQUEST = "GET_ORG_SP_IDP_REQUEST";
export const GET_ORG_SP_IDP_SUCCESS = "GET_ORG_SP_IDP_SUCCESS";
export const GET_ORG_SP_IDP_FAILURE = "GET_ORG_SP_IDP_FAILURE";
export const INITIAL_ORG_CHOOSE_REQUEST = "INITIAL_ORG_CHOOSE_REQUEST";
export const INITIAL_ORG_CHOOSE_SUCCESS = "INITIAL_ORG_CHOOSE_SUCCESS";
export const INITIAL_ORG_CHOOSE_FAILURE = "INITIAL_ORG_CHOOSE_FAILURE";
export const UMBRELLA_PROTECTION_GET_REQUEST = "UMBRELLA_PROTECTION_GET_REQUEST";
export const UMBRELLA_PROTECTION_GET_SUCCESS = "UMBRELLA_PROTECTION_GET_SUCCESS";
export const UMBRELLA_PROTECTION_GET_FAILURE = "UMBRELLA_PROTECTION_GET_FAILURE";
export const UMBRELLA_PROTECTION_SET_REQUEST = "UMBRELLA_PROTECTION_SET_REQUEST";
export const UMBRELLA_PROTECTION_SET_SUCCESS = "UMBRELLA_PROTECTION_SET_SUCCESS";
export const UMBRELLA_PROTECTION_SET_FAILURE = "UMBRELLA_PROTECTION_SET_FAILURE";
export const UMBRELLA_PROTECTION_STATUS_GET_REQUEST = "UMBRELLA_PROTECTION_STATUS_GET_REQUEST";
export const UMBRELLA_PROTECTION_STATUS_GET_SUCCESS = "UMBRELLA_PROTECTION_STATUS_GET_SUCCESS";
export const UMBRELLA_PROTECTION_STATUS_GET_FAILURE = "UMBRELLA_PROTECTION_STATUS_GET_FAILURE";
export const VIDEO_CHANNEL_REQUEST = "VIDEO_CHANNEL_REQUEST";
export const VIDEO_CHANNEL_SUCCESS = "VIDEO_CHANNEL_SUCCESS";
export const VIDEO_CHANNEL_FAILURE = "VIDEO_CHANNEL_FAILURE";
export const HISTORICAL_VIDEO_FILE_REQUEST = "HISTORICAL_VIDEO_FILE_REQUEST";
export const HISTORICAL_VIDEO_FILE_SUCCESS = "HISTORICAL_VIDEO_FILE_SUCCESS";
export const HISTORICAL_VIDEO_FILE_FAILURE = "HISTORICAL_VIDEO_FILE_FAILURE";
export const GENERATE_SNAPSHOT_REQUEST = "GENERATE_SNAPSHOT_REQUEST";
export const GENERATE_SNAPSHOT_SUCCESS = "GENERATE_SNAPSHOT_SUCCESS";
export const GENERATE_SNAPSHOT_FAILURE = "GENERATE_SNAPSHOT_FAILURE";
export const MOTION_EVENTS_REQUEST = "MOTION_EVENTS_REQUEST";
export const MOTION_EVENTS_SUCCESS = "MOTION_EVENTS_SUCCESS";
export const MOTION_EVENTS_FAILURE = "MOTION_EVENTS_FAILURE";
export const MOTION_SEARCH_FILTER_SET = "MOTION_SEARCH_FILTER_SET";
export const PEOPLE_DETECTION_SET = "PEOPLE_DETECTION_SET";
export const SET_MOTION_SEARCH_INTERVAL = "SET_MOTION_SEARCH_INTERVAL";
export const SET_MOTION_SEARCH_PAGE = "SET_MOTION_SEARCH_PAGE";
export const SET_MOTION_EVENTS_LOADING_STATUS = "SET_MOTION_EVENTS_LOADING_STATUS";
export const SET_REGION_OF_INTEREST = "SET_REGION_OF_INTEREST";
export const SET_SELECTED_MOTION_EVENT_TIMESTAMP = "SET_SELECTED_MOTION_EVENT_TIMESTAMP";
export const SET_VIDEO_SETTING_MENU_VISIBILITY = "SET_VIDEO_SETTING_MENU_VISIBILITY";
export const SET_CURRENT_VIDEO_SETTING_PAGE = "SET_CURRENT_VIDEO_SETTING_PAGE";
export const SET_PLAYBACK_RATE = "SET_PLAYBACK_RATE";
export const SET_VIDEO_MODE = "SET_VIDEO_MODE";
export const SET_PLAYBACK_STATUS = "SET_PLAYBACK_STATUS";
export const SET_OFFSET = "SET_OFFSET";
export const UPDATE_OFFSET = "UPDATE_OFFSET";
export const SET_CLOCK_START_TS = "SET_CLOCK_START_TS";
export const SET_INITIAL_DELTA = "SET_INITIAL_DELTA";
export const SET_VIDEO_IS_LOADING = "SET_VIDEO_IS_LOADING";
export const CAMERA_PLAYER_RELEASED = "CAMERA_PLAYER_RELEASED";
export const PLAY_LIVE_VIDEO = "PLAY_LIVE_VIDEO";
export const PLAY_HISTORICAL_VIDEO = "PLAY_HISTORICAL_VIDEO";
export const CAMERA_CONFIG_GET_REQUEST = "CAMERA_CONFIG_GET_REQUEST";
export const CAMERA_CONFIG_GET_SUCCESS = "CAMERA_CONFIG_GET_SUCCESS";
export const CAMERA_CONFIG_GET_FAILURE = "CAMERA_CONFIG_GET_FAILURE";
export const CAMERA_CONFIG_UPDATE_REQUEST = "CAMERA_CONFIG_UPDATE_REQUEST";
export const CAMERA_CONFIG_UPDATE_SUCCESS = "CAMERA_CONFIG_UPDATE_SUCCESS";
export const CAMERA_CONFIG_UPDATE_FAILURE = "CAMERA_CONFIG_UPDATE_FAILURE";
export const CAMERA_WIRELESS_PROFILES_GET_FAILURE = "CAMERA_WIRELESS_PROFILES_GET_FAILURE";
export const CAMERA_WIRELESS_PROFILES_GET_REQUEST = "CAMERA_WIRELESS_PROFILES_GET_REQUEST";
export const CAMERA_WIRELESS_PROFILES_GET_SUCCESS = "CAMERA_WIRELESS_PROFILES_GET_SUCCESS";
export const CAMERA_WIRELESS_PROFILE_CREATE_FAILURE = "CAMERA_WIRELESS_PROFILE_CREATE_FAILURE";
export const CAMERA_WIRELESS_PROFILE_CREATE_REQUEST = "CAMERA_WIRELESS_PROFILE_CREATE_REQUEST";
export const CAMERA_WIRELESS_PROFILE_CREATE_SUCCESS = "CAMERA_WIRELESS_PROFILE_CREATE_SUCCESS";
export const CAMERA_WIRELESS_PROFILE_UPDATE_FAILURE = "CAMERA_WIRELESS_PROFILE_UPDATE_FAILURE";
export const CAMERA_WIRELESS_PROFILE_UPDATE_REQUEST = "CAMERA_WIRELESS_PROFILE_UPDATE_REQUEST";
export const CAMERA_WIRELESS_PROFILE_UPDATE_SUCCESS = "CAMERA_WIRELESS_PROFILE_UPDATE_SUCCESS";
export const CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST = "CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST";
export const CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS = "CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS";
export const CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE = "CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE";
export const DEWARPED_TOGGLED = "DEWARPED_TOGGLED";
export const VIDEO_MUTE_TOGGLED = "VIDEO_MUTE_TOGGLED";
export const SENSOR_EVENTS_REQUEST = "SENSOR_EVENTS_REQUEST";
export const SENSOR_EVENTS_SUCCESS = "SENSOR_EVENTS_SUCCESS";
export const SENSOR_EVENTS_FAILURE = "SENSOR_EVENTS_FAILURE";
export const SENSOR_ALERT_PROFILES_REQUEST = "SENSOR_ALERT_PROFILES_REQUEST";
export const SENSOR_ALERT_PROFILES_SUCCESS = "SENSOR_ALERT_PROFILES_SUCCESS";
export const SENSOR_ALERT_PROFILES_FAILURE = "SENSOR_ALERT_PROFILES_FAILURE";
export const TEST_SENSOR_ALERT_REQUEST = "TEST_SENSOR_ALERT_REQUEST";
export const TEST_SENSOR_ALERT_SUCCESS = "TEST_SENSOR_ALERT_SUCCESS";
export const TEST_SENSOR_ALERT_FAILURE = "TEST_SENSOR_ALERT_FAILURE";
export const DOOR_RELATED_EVENTS_REQUEST = "DOOR_RELATED_EVENTS_REQUEST";
export const DOOR_RELATED_EVENTS_SUCCESS = "DOOR_RELATED_EVENTS_SUCCESS";
export const DOOR_RELATED_EVENTS_FAILURE = "DOOR_RELATED_EVENTS_FAILURE";
export const WATER_RELATED_EVENTS_REQUEST = "WATER_RELATED_EVENTS_REQUEST";
export const WATER_RELATED_EVENTS_SUCCESS = "WATER_RELATED_EVENTS_SUCCESS";
export const WATER_RELATED_EVENTS_FAILURE = "WATER_RELATED_EVENTS_FAILURE";
export const SENSORS_REQUEST = "SENSORS_REQUEST";
export const SENSORS_SUCCESS = "SENSORS_SUCCESS";
export const SENSORS_FAILURE = "SENSORS_FAILURE";
export const SENSOR_ROLES_REQUEST = "SENSOR_ROLES_REQUEST";
export const SENSOR_ROLES_SUCCESS = "SENSOR_ROLES_SUCCESS";
export const SENSOR_ROLES_FAILURE = "SENSOR_ROLES_FAILURE";
export const SENSOR_STATS_COUNT_BY_VALUE_REQUEST = "SENSOR_STATS_COUNT_BY_VALUE_REQUEST";
export const SENSOR_STATS_COUNT_BY_VALUE_SUCCESS = "SENSOR_STATS_COUNT_BY_VALUE_SUCCESS";
export const SENSOR_STATS_COUNT_BY_VALUE_FAILURE = "SENSOR_STATS_COUNT_BY_VALUE_FAILURE";
export const SENSOR_STATS_LATEST_REQUEST = "SENSOR_STATS_LATEST_REQUEST";
export const SENSOR_STATS_LATEST_SUCCESS = "SENSOR_STATS_LATEST_SUCCESS";
export const SENSOR_STATS_LATEST_FAILURE = "SENSOR_STATS_LATEST_FAILURE";
export const LATEST_WATER_DETECTION_STAT_REQUEST = "LATEST_WATER_DETECTION_STAT_REQUEST";
export const LATEST_WATER_DETECTION_STAT_SUCCESS = "LATEST_WATER_DETECTION_STAT_SUCCESS";
export const LATEST_WATER_DETECTION_STAT_FAILURE = "LATEST_WATER_DETECTION_STAT_FAILURE";
export const CLEAR_SENSOR_STAT_METRIC = "CLEAR_SENSOR_STAT_METRIC";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";
export const SET_UPDATE_REQUIRED = "SET_UPDATE_REQUIRED";
export const RESET_UPDATE_REQUIRED = "RESET_UPDATE_REQUIRED";
export const REMOTE_CONFIG_UPDATE = "REMOTE_CONFIG_UPDATE";
export const READ_IN_WHATS_NEW = "READ_IN_WHATS_NEW";
export const READ_NEW_FEATURE = "READ_NEW_FEATURE";
export const READ_WHATS_NEW = "READ_WHATS_NEW";
export const SET_CURRENT_CLUSTER = "SET_CURRENT_CLUSTER";
export const GET_APPLIANCE_PORTS_REQUEST = "GET_APPLIANCE_PORTS_REQUEST";
export const GET_APPLIANCE_PORTS_SUCCESS = "GET_APPLIANCE_PORTS_SUCCESS";
export const GET_APPLIANCE_PORTS_FAILURE = "GET_APPLIANCE_PORTS_FAILURE";
export const UPDATE_APPLIANCE_PORT_REQUEST = "UPDATE_APPLIANCE_PORT_REQUEST";
export const UPDATE_APPLIANCE_PORT_SUCCESS = "UPDATE_APPLIANCE_PORT_SUCCESS";
export const UPDATE_APPLIANCE_PORT_FAILURE = "UPDATE_APPLIANCE_PORT_FAILURE";
export const INCREASE_APP_SURVEY_DISMISSALS = "INCREASE_APP_SURVEY_DISMISSALS";
export const INCREASE_HOME_VISITS = "INCREASE_HOME_VISITS";
export const GET_PREV_PAGE_REQUEST = "GET_PREV_PAGE_REQUEST";
export const GET_PREV_PAGE_SUCCESS = "GET_PREV_PAGE_SUCCESS";
export const GET_PREV_PAGE_FAILURE = "GET_PREV_PAGE_FAILURE";
export const GET_NEXT_PAGE_REQUEST = "GET_NEXT_PAGE_REQUEST";
export const GET_NEXT_PAGE_SUCCESS = "GET_NEXT_PAGE_SUCCESS";
export const GET_NEXT_PAGE_FAILURE = "GET_NEXT_PAGE_FAILURE";
export const GET_UNUSED_INVENTORY_REQUEST = "GET_UNUSED_INVENTORY_REQUEST";
export const GET_UNUSED_INVENTORY_SUCCESS = "GET_UNUSED_INVENTORY_SUCCESS";
export const GET_UNUSED_INVENTORY_FAILURE = "GET_UNUSED_INVENTORY_FAILURE";
export const CLEAR_PAGINATION = "CLEAR_PAGINATION";
export const UPDATE_TIME_ZONE_REQUEST = "UPDATE_TIME_ZONE_REQUEST";
export const UPDATE_TIME_ZONE_SUCCESS = "UPDATE_TIME_ZONE_SUCCESS";
export const UPDATE_TIME_ZONE_FAILURE = "UPDATE_TIME_ZONE_FAILURE";
export const GET_CLIENT_POLICIES_REQUEST = "GET_CLIENT_POLICIES_REQUEST";
export const GET_CLIENT_POLICIES_SUCCESS = "GET_CLIENT_POLICIES_SUCCESS";
export const GET_CLIENT_POLICIES_FAILURE = "GET_CLIENT_POLICIES_FAILURE";
export const GET_SSID_L7_FIREWALL_RULES_REQUEST = "GET_SSID_L7_FIREWALL_RULES_REQUEST";
export const GET_SSID_L7_FIREWALL_RULES_SUCCESS = "GET_SSID_L7_FIREWALL_RULES_SUCCESS";
export const GET_SSID_L7_FIREWALL_RULES_FAILURE = "GET_SSID_L7_FIREWALL_RULES_FAILURE";
export const UPDATE_SSID_L7_FIREWALL_RULES_REQUEST = "UPDATE_SSID_L7_FIREWALL_RULES_REQUEST";
export const UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS = "UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS";
export const UPDATE_SSID_L7_FIREWALL_RULES_FAILURE = "UPDATE_SSID_L7_FIREWALL_RULES_FAILURE";
export const SET_USER_DATA = "SET_USER_DATA";
export const USER_DATA_PROFILE_SETTINGS_GET_FAILURE = "USER_DATA_PROFILE_SETTINGS_GET_FAILURE";
export const USER_DATA_PROFILE_SETTINGS_GET_REQUEST = "USER_DATA_PROFILE_SETTINGS_GET_REQUEST";
export const USER_DATA_PROFILE_SETTINGS_GET_SUCCESS = "USER_DATA_PROFILE_SETTINGS_GET_SUCCESS";
export const GET_INVENTORY_DEVICE_REQUEST = "GET_INVENTORY_DEVICE_REQUEST";
export const GET_INVENTORY_DEVICE_SUCCESS = "GET_INVENTORY_DEVICE_SUCCESS";
export const GET_INVENTORY_DEVICE_FAILURE = "GET_INVENTORY_DEVICE_FAILURE";
export const VLANS_DELETE_REQUEST = "VLANS_DELETE_REQUEST";
export const VLANS_DELETE_SUCCESS = "VLANS_DELETE_SUCCESS";
export const VLANS_DELETE_FAILURE = "VLANS_DELETE_FAILURE";
export const VLANS_CREATE_REQUEST = "VLANS_CREATE_REQUEST";
export const VLANS_CREATE_SUCCESS = "VLANS_CREATE_SUCCESS";
export const VLANS_CREATE_FAILURE = "VLANS_CREATE_FAILURE";
export const SET_CURRENT_LOCALE = "SET_CURRENT_LOCALE";
export const SET_SSID_QR_CODE = "SET_SSID_QR_CODE";
export const UPDATE_PDF_INFO = "UPDATE_PDF_INFO";
export const GET_SSID_TRAFFIC_SHAPING_RULES_REQUEST = "GET_SSID_TRAFFIC_SHAPING_RULES_REQUEST";
export const GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS = "GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS";
export const GET_SSID_TRAFFIC_SHAPING_RULES_FAILURE = "GET_SSID_TRAFFIC_SHAPING_RULES_FAILURE";
export const UPDATE_SSID_TRAFFIC_SHAPING_RULES_REQUEST =
  "UPDATE_SSID_TRAFFIC_SHAPING_RULES_REQUEST";
export const UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS =
  "UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS";
export const UPDATE_SSID_TRAFFIC_SHAPING_RULES_FAILURE =
  "UPDATE_SSID_TRAFFIC_SHAPING_RULES_FAILURE";
export const GET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST = "GET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST";
export const GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS = "GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS";
export const GET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE = "GET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE";
export const SET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST = "SET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST";
export const SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS = "SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS";
export const SET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE = "SET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE";
export const SEARCH_INVENTORY_REQUEST = "SEARCH_INVENTORY_REQUEST";
export const SEARCH_INVENTORY_SUCCESS = "SEARCH_INVENTORY_SUCCESS";
export const SEARCH_INVENTORY_FAILURE = "SEARCH_INVENTORY_FAILURE";
export const GET_SSID_SPLASH_SETTINGS_REQUEST = "GET_SSID_SPLASH_SETTINGS_REQUEST";
export const GET_SSID_SPLASH_SETTINGS_SUCCESS = "GET_SSID_SPLASH_SETTINGS_SUCCESS";
export const GET_SSID_SPLASH_SETTINGS_FAILURE = "GET_SSID_SPLASH_SETTINGS_FAILURE";
export const UPDATE_SSID_SPLASH_SETTINGS_REQUEST = "UPDATE_SSID_SPLASH_SETTINGS_REQUEST";
export const UPDATE_SSID_SPLASH_SETTINGS_SUCCESS = "UPDATE_SSID_SPLASH_SETTINGS_SUCCESS";
export const UPDATE_SSID_SPLASH_SETTINGS_FAILURE = "UPDATE_SSID_SPLASH_SETTINGS_FAILURE";
export const SET_SELECTED_VLAN_ID = "SET_SELECTED_VLAN_ID";
export const SET_PENDING_APP_LINK = "SET_PENDING_APP_LINK";
export const REMOVE_PENDING_APP_LINK = "REMOVE_PENDING_APP_LINK";
export const SET_BOTTOM_TAB_BAR = "SET_BOTTOM_TAB_BAR";
export const TOGGLE_BOTTOM_TAB_VISIBILITY = "TOGGLE_BOTTOM_TAB_VISIBILITY";
export const ORG_WIDE_DEVICES_REQUEST = "ORG_WIDE_DEVICES_REQUEST";
export const ORG_WIDE_DEVICES_SUCCESS = "ORG_WIDE_DEVICES_SUCCESS";
export const ORG_WIDE_DEVICES_FAILURE = "ORG_WIDE_DEVICES_FAILURE";
export const GET_SSID_SCHEDULES_REQUEST = "GET_SSID_SCHEDULES_REQUEST";
export const GET_SSID_SCHEDULES_SUCCESS = "GET_SSID_SCHEDULES_SUCCESS";
export const GET_SSID_SCHEDULES_FAILURE = "GET_SSID_SCHEDULES_FAILURE";
export const GET_NFOS_REQUEST = "GET_NFOS_REQUEST";
export const GET_NFOS_SUCCESS = "GET_NFOS_SUCCESS";
export const GET_NFOS_FAILURE = "GET_NFOS_FAILURE";
export const UPDATE_SSID_SCHEDULES_REQUEST = "UPDATE_SSID_SCHEDULES_REQUEST";
export const UPDATE_SSID_SCHEDULES_SUCCESS = "UPDATE_SSID_SCHEDULES_SUCCESS";
export const UPDATE_SSID_SCHEDULES_FAILURE = "UPDATE_SSID_SCHEDULES_FAILURE";
export const GET_HARDWARE_RADIO_SETTINGS_REQUEST = "GET_HARDWARE_RADIO_SETTINGS_REQUEST";
export const GET_HARDWARE_RADIO_SETTINGS_SUCCESS = "GET_HARDWARE_RADIO_SETTINGS_SUCCESS";
export const GET_HARDWARE_RADIO_SETTINGS_FAILURE = "GET_HARDWARE_RADIO_SETTINGS_FAILURE";
export const SET_HARDWARE_RADIO_SETTINGS_REQUEST = "SET_HARDWARE_RADIO_SETTINGS_REQUEST";
export const SET_HARDWARE_RADIO_SETTINGS_SUCCESS = "SET_HARDWARE_RADIO_SETTINGS_SUCCESS";
export const SET_HARDWARE_RADIO_SETTINGS_FAILURE = "SET_HARDWARE_RADIO_SETTINGS_FAILURE";
export const REFRESH_ROOT_UI = "REFRESH_ROOT_UI";
export const CLIENT_USAGE_HISTORIES_REQUEST = "CLIENT_USAGE_HISTORIES_REQUEST";
export const CLIENT_USAGE_HISTORIES_SUCCESS = "CLIENT_USAGE_HISTORIES_SUCCESS";
export const CLIENT_USAGE_HISTORIES_FAILURE = "CLIENT_USAGE_HISTORIES_FAILURE";
export const CLIENT_APPLICATION_USAGES_REQUEST = "CLIENT_APPLICATION_USAGES_REQUEST";
export const CLIENT_APPLICATION_USAGES_SUCCESS = "CLIENT_APPLICATION_USAGES_SUCCESS";
export const CLIENT_APPLICATION_USAGES_FAILURE = "CLIENT_APPLICATION_USAGES_FAILURE";
export const GET_NETWORK_ACCESS_ROLES_REQUEST = "GET_NETWORK_ACCESS_ROLES_REQUEST";
export const GET_NETWORK_ACCESS_ROLES_SUCCESS = "GET_NETWORK_ACCESS_ROLES_SUCCESS";
export const GET_NETWORK_ACCESS_ROLES_FAILURE = "GET_NETWORK_ACCESS_ROLES_FAILURE";
export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";
export const GET_NODE_GROUPS_REQUEST = "GET_NODE_GROUPS_REQUEST";
export const GET_NODE_GROUPS_SUCCESS = "GET_NODE_GROUPS_SUCCESS";
export const GET_NODE_GROUPS_FAILURE = "GET_NODE_GROUPS_FAILURE";
export const GET_SUPPORT_CASES_REQUEST = "GET_SUPPORT_CASES_REQUEST";
export const GET_SUPPORT_CASES_SUCCESS = "GET_SUPPORT_CASES_SUCCESS";
export const GET_SUPPORT_CASES_FAILURE = "GET_SUPPORT_CASES_FAILURE";
export const UPDATE_MANAGEMENT_INTERFACE_REQUEST = "UPDATE_MANAGEMENT_INTERFACE_REQUEST";
export const UPDATE_MANAGEMENT_INTERFACE_SUCCESS = "UPDATE_MANAGEMENT_INTERFACE_SUCCESS";
export const UPDATE_MANAGEMENT_INTERFACE_FAILURE = "UPDATE_MANAGEMENT_INTERFACE_FAILURE";
export const UPDATE_SWITCH_PORT_REQUEST = "UPDATE_SWITCH_PORT_REQUEST";
export const UPDATE_SWITCH_PORT_SUCCESS = "UPDATE_SWITCH_PORT_SUCCESS";
export const UPDATE_SWITCH_PORT_FAILURE = "UPDATE_SWITCH_PORT_FAILURE";
export const BATCH_UPDATE_SWITCH_PORT_REQUEST = "BATCH_UPDATE_SWITCH_PORT_REQUEST";
export const BATCH_UPDATE_SWITCH_PORT_SUCCESS = "BATCH_UPDATE_SWITCH_PORT_SUCCESS";
export const BATCH_UPDATE_SWITCH_PORT_FAILURE = "BATCH_UPDATE_SWITCH_PORT_FAILURE";
export const BATCH_UPDATE_DEVICE_REQUEST = "BATCH_UPDATE_DEVICE_REQUEST";
export const BATCH_UPDATE_DEVICE_SUCCESS = "BATCH_UPDATE_DEVICE_SUCCESS";
export const BATCH_UPDATE_DEVICE_FAILURE = "BATCH_UPDATE_DEVICE_FAILURE";
export const GET_DHCP_SUBNET_REQUEST = "GET_DHCP_SUBNET_REQUEST";
export const GET_DHCP_SUBNET_SUCCESS = "GET_DHCP_SUBNET_SUCCESS";
export const GET_DHCP_SUBNET_FAILURE = "GET_DHCP_SUBNET_FAILURE";
export const GET_L3_FIREWALL_RULES_REQUEST = "GET_L3_FIREWALL_RULES_REQUEST";
export const GET_L3_FIREWALL_RULES_SUCCESS = "GET_L3_FIREWALL_RULES_SUCCESS";
export const GET_L3_FIREWALL_RULES_FAILURE = "GET_L3_FIREWALL_RULES_FAILURE";
export const UPDATE_L3_FIREWALL_RULES_REQUEST = "UPDATE_L3_FIREWALL_RULES_REQUEST";
export const UPDATE_L3_FIREWALL_RULES_SUCCESS = "UPDATE_L3_FIREWALL_RULES_SUCCESS";
export const UPDATE_L3_FIREWALL_RULES_FAILURE = "UPDATE_L3_FIREWALL_RULES_FAILURE";
export const CHANGE_VLAN_ID_REQUEST = "CHANGE_VLAN_ID_REQUEST";
export const CHANGE_VLAN_ID_SUCCESS = "CHANGE_VLAN_ID_SUCCESS";
export const CHANGE_VLAN_ID_FAILURE = "CHANGE_VLAN_ID_FAILURE";
export const USER_DATA_TEMPERATURE_UNIT_PREFERENCE_TOGGLED =
  "USER_DATA_TEMPERATURE_UNIT_PREFERENCE_TOGGLED";
export const UPDATE_WARN_INVALID_BRIDGE_MODE = "UPDATE_WARN_INVALID_BRIDGE_MODE";
export const LSP_CONNECTION_REQUEST = "LSP_CONNECTION_REQUEST";
export const LSP_CONNECTION_SUCCESS = "LSP_CONNECTION_SUCCESS";
export const LSP_CONNECTION_FAILURE = "LSP_CONNECTION_FAILURE";
export const ASK_FOR_BIO_AUTH = "ASK_FOR_BIO_AUTH";
export const HIDE_ASK_FOR_BIO_AUTH = "HIDE_ASK_FOR_BIO_AUTH";
export const HIDE_MESSAGE_BANNER = "HIDE_MESSAGE_BANNER";
export const SET_ONBOARDING_STARTED_REQUEST = "SET_ONBOARDING_STARTED_REQUEST";
export const SET_ONBOARDING_STARTED_SUCCESS = "SET_ONBOARDING_STARTED_SUCCESS";
export const SET_ONBOARDING_STARTED_FAILURE = "SET_ONBOARDING_STARTED_FAILURE";
export const FETCH_ONBOARDING_STATUS_REQUEST = "FETCH_ONBOARDING_STATUS_REQUEST";
export const FETCH_ONBOARDING_STATUS_SUCCESS = "FETCH_ONBOARDING_STATUS_SUCCESS";
export const FETCH_ONBOARDING_STATUS_FAILURE = "FETCH_ONBOARDING_STATUS_FAILURE";
export const FETCH_SINGLE_DEVICE_DETAILS_REQUEST = "FETCH_SINGLE_DEVICE_DETAILS_REQUEST";
export const FETCH_SINGLE_DEVICE_DETAILS_SUCCESS = "FETCH_SINGLE_DEVICE_DETAILS_SUCCESS";
export const FETCH_SINGLE_DEVICE_DETAILS_FAILURE = "FETCH_SINGLE_DEVICE_DETAILS_FAILURE";
export const FETCH_DEVICE_BY_SERIAL_REQUEST = "FETCH_DEVICE_BY_SERIAL_REQUEST";
export const FETCH_DEVICE_BY_SERIAL_SUCCESS = "FETCH_DEVICE_BY_SERIAL_SUCCESS";
export const FETCH_DEVICE_BY_SERIAL_FAILURE = "FETCH_DEVICE_BY_SERIAL_FAILURE";
export const SET_IS_SAML_USER = "SET_IS_SAML_USER";
export const SET_SSO_SUBDOMAIN = "SET_SSO_SUBDOMAIN";
export const TOPOLOGY_REQUEST = "TOPOLOGY_REQUEST";
export const TOPOLOGY_SUCCESS = "TOPOLOGY_SUCCESS";
export const TOPOLOGY_FAILURE = "TOPOLOGY_FAILURE";
export const SET_SELECTED_EXPORT_EVENT_TIMESTAMP = "SET_SELECTED_EXPORT_EVENT_TIMESTAMP";
export const INITIATE_EXPORT_REQUEST = "INITIATE_EXPORT_REQUEST";
export const INITIATE_EXPORT_SUCCESS = "INITIATE_EXPORT_SUCCESS";
export const INITIATE_EXPORT_FAILURE = "INITIATE_EXPORT_FAILURE";
export const FETCH_EXPORTS_REQUEST = "FETCH_EXPORTS_REQUEST";
export const FETCH_EXPORTS_SUCCESS = "FETCH_EXPORTS_SUCCESS";
export const FETCH_EXPORTS_FAILURE = "FETCH_EXPORTS_FAILURE";
export const SET_EXPORT_TRIGGERED_TO_IDLE = "SET_EXPORT_TRIGGERED_TO_IDLE";
export const SET_CAMERA_GRID_PREFERRED = "SET_CAMERA_GRID_PREFERRED";
export const SET_CAMERA_LIST_PREFERRED = "SET_CAMERA_LIST_PREFERRED";
export const DELETE_CLIENT_PII_REQUEST = "DELETE_CLIENT_PII_REQUEST";
export const DELETE_CLIENT_PII_SUCCESS = "DELETE_CLIENT_PII_SUCCESS";
export const DELETE_CLIENT_PII_FAILURE = "DELETE_CLIENT_PII_FAILURE";
export const DELETE_NETWORK_REQUEST = "DELETE_NETWORK_REQUEST";
export const DELETE_NETWORK_SUCCESS = "DELETE_NETWORK_SUCCESS";
export const DELETE_NETWORK_FAILURE = "DELETE_NETWORK_FAILURE";
export const GET_NETWORK_REQUEST = "GET_NETWORK_REQUEST";
export const GET_NETWORK_SUCCESS = "GET_NETWORK_SUCCESS";
export const GET_NETWORK_FAILURE = "GET_NETWORK_FAILURE";
export const UPDATE_NETWORK_REQUEST = "UPDATE_NETWORK_REQUEST";
export const UPDATE_NETWORK_SUCCESS = "UPDATE_NETWORK_SUCCESS";
export const UPDATE_NETWORK_FAILURE = "UPDATE_NETWORK_FAILURE";
export const DELETE_ORGANIZATION_REQUEST = "DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILURE = "DELETE_ORGANIZATION_FAILURE";
export const ORG_VPN_STATUS_REQUEST = "ORG_VPN_STATUS_REQUEST";
export const ORG_VPN_STATUS_SUCCESS = "ORG_VPN_STATUS_SUCCESS";
export const ORG_VPN_STATUS_FAILURE = "ORG_VPN_STATUS_FAILURE";
export const ORG_VPN_STAT_REQUEST = "ORG_VPN_STAT_REQUEST";
export const ORG_VPN_STAT_SUCCESS = "ORG_VPN_STAT_SUCCESS";
export const ORG_VPN_STAT_FAILURE = "ORG_VPN_STAT_FAILURE";
export const DETECTING_IDLE_TIME = "DETECTING_IDLE_TIME";
export const WIRELESS_CLIENT_CONNECTIVITY_EVENTS_REQUEST =
  "WIRELESS_CLIENT_CONNECTIVITY_EVENTS_REQUEST";
export const WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS =
  "WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS";
export const WIRELESS_CLIENT_CONNECTIVITY_EVENTS_FAILURE =
  "WIRELESS_CLIENT_CONNECTIVITY_EVENTS_FAILURE";
export const VPN_PEER_STATUS_REQUEST = "VPN_PEER_STATUS_REQUEST";
export const VPN_PEER_STATUS_SUCCESS = "VPN_PEER_STATUS_SUCCESS";
export const VPN_PEER_STATUS_FAILURE = "VPN_PEER_STATUS_FAILURE";
export const VPN_PEER_OUTAGE_REQUEST = "VPN_PEER_OUTAGE_REQUEST";
export const VPN_PEER_OUTAGE_SUCCESS = "VPN_PEER_OUTAGE_SUCCESS";
export const VPN_PEER_OUTAGE_FAILURE = "VPN_PEER_OUTAGE_FAILURE";
export const VPN_USAGE_DATA_REQUEST = "VPN_USAGE_DATA_REQUEST";
export const VPN_USAGE_DATA_SUCCESS = "VPN_USAGE_DATA_SUCCESS";
export const VPN_USAGE_DATA_FAILURE = "VPN_USAGE_DATA_FAILURE";
export const GET_NETWORK_SETTINGS_REQUEST = "GET_NETWORK_SETTINGS_REQUEST";
export const GET_NETWORK_SETTINGS_FAILURE = "GET_NETWORK_SETTINGS_FAILURE";
export const GET_NETWORK_SETTINGS_SUCCESS = "GET_NETWORK_SETTINGS_SUCCESS";
export const SET_NETWORK_SETTINGS_REQUEST = "SET_NETWORK_SETTINGS_REQUEST";
export const SET_NETWORK_SETTINGS_SUCCESS = "SET_NETWORK_SETTINGS_SUCCESS";
export const SET_NETWORK_SETTINGS_FAILURE = "SET_NETWORK_SETTINGS_FAILURE";
export const CONFIRM_EMAIL_REQUEST = "CONFIRM_EMAIL_REQUEST";
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";
export const RESEND_VERIFY_EMAIL_REQUEST = "RESEND_VERIFY_EMAIL_REQUEST";
export const RESEND_VERIFY_EMAIL_SUCCESS = "RESEND_VERIFY_EMAIL_SUCCESS";
export const RESEND_VERIFY_EMAIL_FAILURE = "RESEND_VERIFY_EMAIL_FAILURE";
export const RESEND_CONFIRM_EMAIL_REQUEST = "RESEND_CONFIRM_EMAIL_REQUEST";
export const RESEND_CONFIRM_EMAIL_SUCCESS = "RESEND_CONFIRM_EMAIL_SUCCESS";
export const RESEND_CONFIRM_EMAIL_FAILURE = "RESEND_CONFIRM_EMAIL_FAILURE";
export const FETCH_PASSWORD_RESET_SHARD_REQUEST = "FETCH_PASSWORD_RESET_SHARD";
export const FETCH_PASSWORD_RESET_SHARD_SUCCESS = "FETCH_PASSWORD_RESET_SHARD";
export const FETCH_PASSWORD_RESET_SHARD_FAILURE = "FETCH_PASSWORD_RESET_SHARD";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const GET_IPSEC_VPN_SETTINGS_REQUEST = "GET_IPSEC_VPN_SETTINGS_REQUEST";
export const GET_IPSEC_VPN_SETTINGS_SUCCESS = "GET_IPSEC_VPN_SETTINGS_SUCCESS";
export const GET_IPSEC_VPN_SETTINGS_FAILURE = "GET_IPSEC_VPN_SETTINGS_FAILURE";
export const UPDATE_IPSEC_VPN_SETTINGS_REQUEST = "UPDATE_IPSEC_VPN_SETTINGS_REQUEST";
export const UPDATE_IPSEC_VPN_SETTINGS_SUCCESS = "UPDATE_IPSEC_VPN_SETTINGS_SUCCESS";
export const UPDATE_IPSEC_VPN_SETTINGS_FAILURE = "UPDATE_IPSEC_VPN_SETTINGS_FAILURE";
export const SEND_LAST_LOGIN_TO_MARKETO_SUCCESS = "SEND_LAST_LOGIN_TO_MARKETO_SUCCESS";
export const SEND_LAST_LOGIN_TO_MARKETO_REQUEST = "SEND_LAST_LOGIN_TO_MARKETO_REQUEST";
export const SEND_LAST_LOGIN_TO_MARKETO_FAILURE = "SEND_LAST_LOGIN_TO_MARKETO_FAILURE";
export const CREATE_GO_ORG_REQUEST = "CREATE_GO_ORG_REQUEST";
export const CREATE_GO_ORG_SUCCESS = "CREATE_GO_ORG_SUCCESS";
export const CREATE_GO_ORG_FAILURE = "CREATE_GO_ORG_FAILURE";
export const SEND_HARDWARE_MODELS_TO_MARKETO_SUCCESS = "SEND_HARDWARE_MODELS_TO_MARKETO_SUCCESS";
export const SEND_HARDWARE_MODELS_TO_MARKETO_REQUEST = "SEND_HARDWARE_MODELS_TO_MARKETO_REQUEST";
export const SEND_HARDWARE_MODELS_TO_MARKETO_FAILURE = "SEND_HARDWARE_MODELS_TO_MARKETO_FAILURE";
export const GET_AUTH_USERS_SUCCESS = "GET_AUTH_USERS_SUCCESS";
export const GET_AUTH_USERS_REQUEST = "GET_AUTH_USERS_REQUEST";
export const GET_AUTH_USERS_FAILURE = "GET_AUTH_USERS_FAILURE";
export const ENABLE_CLIENT_VPN_AUTH_REQUEST = "ENABLE_CLIENT_VPN_AUTH_REQUEST";
export const ENABLE_CLIENT_VPN_AUTH_SUCCESS = "ENABLE_CLIENT_VPN_AUTH_SUCCESS";
export const ENABLE_CLIENT_VPN_AUTH_FAILURE = "ENABLE_CLIENT_VPN_AUTH_FAILURE";
export const DISABLE_CLIENT_VPN_AUTH_REQUEST = "DISABLE_CLIENT_VPN_AUTH_REQUEST";
export const DISABLE_CLIENT_VPN_AUTH_SUCCESS = "DISABLE_CLIENT_VPN_AUTH_SUCCESS";
export const DISABLE_CLIENT_VPN_AUTH_FAILURE = "DISABLE_CLIENT_VPN_AUTH_FAILURE";
export const REASSIGN_LICENSE_REQUEST = "REASSIGN_LICENSE_REQUEST";
export const REASSIGN_LICENSE_SUCCESS = "REASSIGN_LICENSE_SUCCESS";
export const REASSIGN_LICENSE_FAILURE = "REASSIGN_LICENSE_FAILURE";
export const GET_SITE_TO_SITE_VPN_SETTINGS_REQUEST = "GET_SITE_TO_SITE_VPN_SETTINGS_REQUEST";
export const GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS = "GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS";
export const GET_SITE_TO_SITE_VPN_SETTINGS_FAILURE = "GET_SITE_TO_SITE_VPN_SETTINGS_FAILURE";
export const UPDATE_SITE_TO_SITE_VPN_SETTINGS_REQUEST = "UPDATE_SITE_TO_SITE_VPN_SETTINGS_REQUEST";
export const UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS = "UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS";
export const UPDATE_SITE_TO_SITE_VPN_SETTINGS_FAILURE = "UPDATE_SITE_TO_SITE_VPN_SETTINGS_FAILURE";
export const UPDATE_AUTH_USERS_SUCCESS = "UPDATE_AUTH_USERS_SUCCESS";
export const UPDATE_AUTH_USERS_REQUEST = "UPDATE_AUTH_USERS_REQUEST";
export const UPDATE_AUTH_USERS_FAILURE = "UPDATE_AUTH_USERS_FAILURE";
export const SET_SPEED_TEST_AGREEMENT = "SET_SPEED_TEST_AGREEMENT";
export const SEND_TWO_FACTOR_CODE_TO_BACKUP_REQUEST = "SEND_TWO_FACTOR_CODE_TO_BACKUP_REQUEST";
export const SEND_TWO_FACTOR_CODE_TO_BACKUP_SUCCESS = "SEND_TWO_FACTOR_CODE_TO_BACKUP_SUCCESS";
export const SEND_TWO_FACTOR_CODE_TO_BACKUP_FAILURE = "SEND_TWO_FACTOR_CODE_TO_BACKUP_FAILURE";
export const ENABLE_TWO_FACTOR_BACKUP_REQUEST = "ENABLE_TWO_FACTOR_BACKUP_REQUEST";
export const ENABLE_TWO_FACTOR_BACKUP_SUCCESS = "ENABLE_TWO_FACTOR_BACKUP_SUCCESS";
export const ENABLE_TWO_FACTOR_BACKUP_FAILURE = "ENABLE_TWO_FACTOR_BACKUP_FAILURE";
export const DISABLE_TWO_FACTOR_BACKUP_REQUEST = "DISABLE_TWO_FACTOR_BACKUP_REQUEST";
export const DISABLE_TWO_FACTOR_BACKUP_SUCCESS = "DISABLE_TWO_FACTOR_BACKUP_SUCCESS";
export const DISABLE_TWO_FACTOR_BACKUP_FAILURE = "DISABLE_TWO_FACTOR_BACKUP_FAILURE";
export const SET_SSO_ACCESS_ORG = "SET_SSO_ACCESS_ORG";
export const GET_SUPPORT_CASE_INFO_REQUEST = "GET_SUPPORT_CASE_INFO_REQUEST";
export const GET_SUPPORT_CASE_INFO_SUCCESS = "GET_SUPPORT_CASE_INFO_SUCCESS";
export const GET_SUPPORT_CASE_INFO_FAILURE = "GET_SUPPORT_CASE_INFO_FAILURE";
export const ADD_SUPPORT_CASE_COMMENT_REQUEST = "ADD_SUPPORT_CASE_COMMENT_REQUEST";
export const ADD_SUPPORT_CASE_COMMENT_SUCCESS = "ADD_SUPPORT_CASE_COMMENT_SUCCESS";
export const ADD_SUPPORT_CASE_COMMENT_FAILURE = "ADD_SUPPORT_CASE_COMMENT_FAILURE";
export const UPDATE_SUPPORT_CASE_REQUEST = "UPDATE_SUPPORT_CASE_REQUEST";
export const UPDATE_SUPPORT_CASE_SUCCESS = "UPDATE_SUPPORT_CASE_SUCCESS";
export const UPDATE_SUPPORT_CASE_FAILURE = "UPDATE_SUPPORT_CASE_FAILURE";
export const USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE =
  "USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE";
export const USER_TOGGLED_MAGNETIC_ALPHA_FEATURE = "USER_TOGGLED_MAGNETIC_ALPHA_FEATURE";
export const USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE = "USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE";
export const IDLE_TIMEOUT_SESSION_EXPIRED = "IDLE_TIMEOUT_SESSION_EXPIRED";
export const RESET_IDLE_TIMEOUT_SESSION_EXPIRED = "RESET_IDLE_TIMEOUT_SESSION_EXPIRED";
export const SET_HOME_SCREEN_CARDS_ORDER = "SET_HOME_SCREEN_CARDS_ORDER";
export const UPDATE_VISIBLE_INLINE_ALERTS = "UPDATE_VISIBLE_INLINE_ALERTS";
export const SET_SSO_IN_PROGRESS = "SET_SSO_IN_PROGRESS";
export const UNPROMPT_SET_BIOAUTH = "UNPROMPT_SET_BIOAUTH";
export const PROMPT_SET_BIOAUTH = "PROMPT_SET_BIOAUTH";
export const SET_SHOW_ALERT_BANNER = "SET_SHOW_ALERT_BANNER";
