import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ encryptedNetworkId }: NetworkUsageblocksRequest) => {
  return `/n/${encryptedNetworkId}/manage/usage/network_useblocks`;
};

interface NetworkUsageblocksRequest {
  encryptedNetworkId?: string;
}

interface NetworkUsageblocksQueryParams {
  timespan?: number;
  t0?: string;
  t1?: string;
  ssid?: number;
}

type NetworkUsageblocksType = NetworkUsageblocksRequest & NetworkUsageblocksQueryParams;

export const ApplicationUsageUploadDownloadSchema = z
  .record(z.string(), z.tuple([z.number(), z.number()]))
  .optional();

export const RUsageSchema = z.object({
  "19": ApplicationUsageUploadDownloadSchema,
  "20": ApplicationUsageUploadDownloadSchema,
  "21": ApplicationUsageUploadDownloadSchema,
  "31": ApplicationUsageUploadDownloadSchema,
});

export const RUsageSrcSchema = z.object({
  "19": z.record(z.string(), z.string()).optional(),
  "20": z.record(z.string(), z.string()).optional(),
  "21": z.record(z.string(), z.string()).optional(),
  "31": z.record(z.string(), z.string()).optional(),
});

export const NetUseBlocksSchema = z.tuple([z.number(), z.number(), z.number()]);

export const RUSAGE_SOURCE = "rusage_src";
export const RUSAGE_KEY = 19;

export const NetworkUsageblocksSchema = z.object({
  t0: z.number(),
  t1: z.number(),
  rusage: RUsageSchema.optional(),
  rusage_src: RUsageSrcSchema,
  fullt1: z.number().nullable(),
  netuseblocks: z.array(NetUseBlocksSchema),
});

export type RUsage = z.infer<typeof RUsageSchema>;
export type NetworkUsageblocks = z.infer<typeof NetworkUsageblocksSchema>;

const fetchNetworkUsageblocks = ({
  encryptedNetworkId,
  t0,
  t1,
  timespan,
  ssid,
  ...queryParams
}: NetworkUsageblocksType) => {
  // manage backend has this
  /**
   * t1 = opts[:t1] || Time.now
   * t0 = t1 - opts[:timespan]
   */

  const resolvedQueryParams = {
    ...queryParams,
    t0,
    t1,
    ssid,
    timespan: t0 && t1 ? Number.parseInt(t1) - Number.parseInt(t0) : timespan,
  };

  return request(NetworkUsageblocksSchema, "GET", buildUrl({ encryptedNetworkId }), {
    queryParams: resolvedQueryParams,
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useNetworkUsageblocks = createQuery({
  baseQueryKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  queryFn: (parameters: NetworkUsageblocksType) => fetchNetworkUsageblocks(parameters),
  requiredVariables: ["encryptedNetworkId"],
});
