import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Informational({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        d="M8 12.9C10.7062 12.9 12.9 10.7062 12.9 8C12.9 5.2938 10.7062 3.1 8 3.1C5.2938 3.1 3.1 5.2938 3.1 8C3.1 10.7062 5.2938 12.9 8 12.9ZM8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM6.7002 7.78267C6.7002 7.34084 7.05837 6.98267 7.5002 6.98267H8.0002C8.44202 6.98267 8.8002 7.34084 8.8002 7.78267V10.2582C9.09338 10.3769 9.3002 10.6643 9.3002 11C9.3002 11.4419 8.94202 11.8 8.5002 11.8H8.0002C7.55837 11.8 7.2002 11.4419 7.2002 11V8.52451C6.90701 8.40584 6.7002 8.1184 6.7002 7.78267ZM9 5.5C9 6.05228 8.55228 6.5 8 6.5C7.44772 6.5 7 6.05228 7 5.5C7 4.94772 7.44772 4.5 8 4.5C8.55228 4.5 9 4.94772 9 5.5Z"
        fill={theme.color.info.icon.base}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
