/**
 * Under the hood, "status" has one of 4 states: online, offline, alerting, or dormant.
 * Over the years, we have used many different terms in DashboardMobile to refer to each of these states
 * with little consistency in when to use which. The following 3 constants DeviceStatus, GeneralStatus, and GoStatus
 * are the first iteration in moving our statuses to a consistent structure with consistent colours.
 */

// DeviceStatus should be used for the status of any hardware device,
// and should match 1:1 with the statuses returned from an endpoint

const Status = {
  online: "online",
  offline: "offline",
  alerting: "alerting",
  dormant: "dormant",
} as const;

export const DeviceStatus = {
  online: Status.online,
  offline: Status.offline,
  alerting: Status.alerting,
  dormant: Status.dormant,
} as const;

// GeneralStatus is a catchall for any kind of status in the app that is not a device - e.g. in overview cards,
// system manager devices, clients, etc
export const GeneralStatus = {
  good: Status.online,
  bad: Status.offline,
  alerting: Status.alerting,
  dormant: Status.dormant,
} as const;

// Go has its own wording that we will maintain consistent for the time being
export const GoStatus = {
  good: Status.online,
  bad: Status.offline,
  offline: Status.dormant,
  warning: Status.alerting,
} as const;

export type StatusType =
  | (typeof Status)[keyof typeof Status]
  | (typeof GoStatus)[keyof typeof GoStatus];

export default GeneralStatus;
