import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import PortTypes, { PortStatus } from "~/constants/PortLayouts";
import Port, { usePortHeight } from "~/shared/components/portDiagrams/Port";

interface PortsStatuses {
  [portId: string]: PortStatus;
}

interface PortBlockProps {
  offset?: number;
  numPorts: number;
  portType: PortTypes;
  numRows: number;
  portsStatuses: PortsStatuses;
  panelWidth: number;
  selectedPorts?: string[];
  style?: StyleProp<ViewStyle>;
  hidePortStatusIcons?: boolean;
}

const PortBlock = ({
  offset = 0,
  numPorts,
  portType,
  numRows,
  portsStatuses,
  panelWidth,
  selectedPorts,
  style,
  hidePortStatusIcons,
}: PortBlockProps): JSX.Element => {
  const portHeight = usePortHeight(PortTypes.rj45, panelWidth);

  return (
    <View style={[styles.wrap, { height: (portHeight + 1) * numRows }, style]}>
      {Array(numPorts)
        .fill(0)
        .map((_, idx) => {
          const portId = (idx + 1 + offset).toString();
          return (
            <Port
              style={styles.port}
              key={portId}
              portId={portId}
              portType={portType}
              status={portsStatuses[portId]}
              panelWidth={panelWidth}
              fadedOut={selectedPorts && !selectedPorts.includes(portId)}
              hideStatusIcon={hidePortStatusIcons}
            />
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "column",
    flexWrap: "wrap",
    alignSelf: "flex-end",
  },
  port: {
    margin: 0.5,
  },
});

export default PortBlock;
