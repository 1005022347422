import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { useSelector } from "react-redux";

import { SPACING } from "~/constants/MkiConstants";
import InputRow from "~/go/rows/InputRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { getNetworkSettings } from "~/selectors";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";

import { SettingsStackProps } from "../navigation/Types";

const FAKE_PASSWORD = "        ";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "LSPPassword"> & PendingComponent;

const LSPPasswordScreen = ({ handleError, setReqPending, reqPending }: Props) => {
  const { fetchNetworkSettings, setNetworkSettings } = useActions();
  const { cancelablePromise } = useCancelablePromise();
  const settings = useSelector(getNetworkSettings);
  const username = settings?.localStatusPage?.authentication?.username;

  const [password, setPassword] = useState(username == null ? "" : FAKE_PASSWORD);
  const [revealable, setRevealable] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const navigation = useNavigation<Props["navigation"]>();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => (
        <SaveButton
          onPress={async () => {
            setReqPending(true);
            try {
              if (password.length < 8 || !/[^A-Za-z0-9 ]/.test(password)) {
                throw I18n.t("LSP_PASSWORD.WEAK_PASSWORD_ERROR");
              }

              await setNetworkSettings({
                localStatusPage: {
                  authentication: {
                    password: password ? password : null,
                  },
                },
              });
              setEnableSaveButton(false);
            } catch (error) {
              if (typeof error === "string") {
                handleError(error);
              }
            } finally {
              setReqPending(false);
            }
          }}
          disabled={!enableSaveButton}
        />
      ),
    });
  }, [handleError, navigation, password, setNetworkSettings, setReqPending, enableSaveButton]);

  useEffect(() => {
    setReqPending(true);
    cancelablePromise(fetchNetworkSettings())
      .catch(handleError)
      .finally(() => setReqPending(false));
  }, [cancelablePromise, fetchNetworkSettings, handleError, setReqPending]);

  const updatePassword = (newPassword: string) => {
    setPassword(newPassword);
    setRevealable(true);
    setEnableSaveButton(true);
  };

  const removeFakePassword = useCallback(() => {
    if (password === FAKE_PASSWORD) {
      setPassword("");
    }
  }, [password]);

  return (
    <FullscreenContainerView>
      <MkiText textStyle="secondary" screenStyles={styles.description}>
        {I18n.t("LSP_PASSWORD.DESCRIPTION")}
      </MkiText>
      <InputRow
        editable={false}
        placeholder={I18n.t("LSP_PASSWORD.USERNAME_PLACEHOLDER")}
        onChangeText={() => {}}
        value={username}
      >
        {I18n.t("LSP_PASSWORD.USERNAME")}
      </InputRow>
      <InputRow
        editable={!reqPending}
        value={password}
        onChangeText={updatePassword}
        revealable={revealable}
        onFocus={removeFakePassword}
        testID="LSP_PASSWORD.PASSWORD_INPUT"
        secureTextEntry
      >
        {I18n.t("LSP_PASSWORD.PASSWORD")}
      </InputRow>
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  description: {
    padding: SPACING.default,
  },
});

export default withPendingComponent(LSPPasswordScreen);
