import { Ssid } from "@meraki/shared/api";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import GoColors from "~/go/constants/GoColors";
import { ExportType } from "~/lib/ExportTemplateUtils";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import ListRow from "~/shared/rows/ListRow";

interface SsidExportRowProps {
  ssid: Ssid;
  isSelected: boolean;
  tapHandler: (type: ExportType, id: number) => void;
}

const SsidExportRow = ({ ssid, isSelected, tapHandler }: SsidExportRowProps) => {
  const iconColor = ssid.enabled ? GoColors.purple : GoColors.navyLighter;

  return (
    <ListRow
      rowStyles={styles.rowStyle}
      testID={`SSID_EXPORT_ROW.${ssid.number}`}
      icon={
        <View style={styles.iconView}>
          <StatusIcon
            testID="SSID_EXPORT_ROW.WIFI_ICON"
            customIcon={{
              icon: MerakiIcon,
              name: "wifi",
              size: "s",
              color: iconColor,
              hasGradient: false,
            }}
            screenStyles={styles.icon}
          />
        </View>
      }
      rightStyle={styles.rightAccessory}
      accessory={<CheckBox selected={isSelected} />}
      onPress={() => tapHandler(ExportType.ssid, ssid.number ?? -1)}
    >
      {ssid.name}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyle: {
    paddingVertical: SPACING.small,
  },
  icon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  iconView: {
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  rightAccessory: {
    paddingRight: SPACING.large,
  },
});

export default SsidExportRow;
