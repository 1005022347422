import { DimensionDefinitionLoose, DimensionLoose } from "echarts";
import { isEmpty } from "lodash";

export type ChartData = object[] | (string | number)[][] | { [key: string]: number[] };

export const getDimensionName = (dimension: DimensionDefinitionLoose): DimensionLoose | string => {
  return dimension ? (typeof dimension === "string" ? dimension : dimension.name ?? "") : "";
};

export const getIsEmpty = (data: ChartData) => {
  // if there is no data at all
  if (isEmpty(data)) {
    return true;
  }

  // if there is one data point
  // data type is an object
  if (!Array.isArray(data)) {
    return Object.values(data)[0]?.length === 1;
  }

  // data type is an array
  // an array of objects
  if (!Array.isArray(Object.values(data)[0])) {
    return data.length <= 1;
  }

  // an array of arrays
  if (Array.isArray(Object.values(data)[0])) {
    return data.length <= 2;
  }

  // How we got here is a mystery since all the above if statements should handle the possible cases.
  // Therefore we assume the data is empty.
  return true;
};
