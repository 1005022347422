import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { List, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useNetworkDeviceEvents } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";

const CLIENT_VPN_EVENT_TYPE = "wired_only_client_vpn";
const VPN_EVENT_TYPE = "wired_only_non_meraki_vpn";

export function ClientVPNEventLogScreen() {
  const networkId = useCurrentNetworkId();
  const { data, refetch, isRefetching, isLoading } = useNetworkDeviceEvents({
    networkId,
    productType: "appliance",
    includedEventTypes: [CLIENT_VPN_EVENT_TYPE, VPN_EVENT_TYPE],
  });

  const clientVPNEvents = data?.pages.flatMap((page) => page.events) ?? [];

  return (
    <Screen testID="CLIENT_VPN_EVENT_LOG_SCREEN">
      <List.FlashList
        data={clientVPNEvents}
        onRefresh={refetch}
        emptyState={{
          title: data?.pages[0]?.message ?? undefined,
        }}
        getItemData={({ description, occurredAt, eventData }) => {
          const { msg, local_ip: localIp, user_id: userId, remote_ip: remoteIp } = eventData;
          return {
            title: description,
            description: formatDate(occurredAt, {
              dateFormat: "shortDate",
              timeFormat: "shortTime",
            }),
            children: (
              <Box>
                {msg && (
                  <Text color="light" testID="CLIENT_VPN.EVENT_LOG.MESSAGE">
                    {I18n.t("CLIENT_VPN.EVENT_LOG.MESSAGE", {
                      msg,
                    })}
                  </Text>
                )}
                {localIp && (
                  <Text color="light" testID="CLIENT_VPN.EVENT_LOG.LOCAL_IP">
                    {I18n.t("CLIENT_VPN.EVENT_LOG.LOCAL_IP", {
                      localIp,
                    })}
                  </Text>
                )}
                {userId && (
                  <Text color="light" testID="CLIENT_VPN.EVENT_LOG.USER_ID">
                    {I18n.t("CLIENT_VPN.EVENT_LOG.USER_ID", {
                      userId,
                    })}
                  </Text>
                )}
                {remoteIp && (
                  <Text color="light" testID="CLIENT_VPN.EVENT_LOG.REMOTE_IP">
                    {I18n.t("CLIENT_VPN.EVENT_LOG.REMOTE_IP", {
                      remoteIp,
                    })}
                  </Text>
                )}
              </Box>
            ),
          };
        }}
        loading={isLoading || isRefetching}
        testID="EVENT_LOG_LIST"
      />
    </Screen>
  );
}
