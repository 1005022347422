import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { StyleSheet } from "react-native";
import * as RNLocalize from "react-native-localize";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import MkiDatePicker, { DateTimePickerModes } from "~/shared/components/MkiDatePicker";
import MkiText from "~/shared/components/MkiText";

interface DatePickerRowProps {
  expiresAt: Date | undefined;
  onConfirm: (date: Date) => void;
}

const DatePickerRow = (props: DatePickerRowProps) => {
  const { expiresAt, onConfirm } = props;

  const formattedLocalExpiresAt = expiresAt
    ? formatDate(expiresAt, {
        dateFormat: "date",
        timeFormat: "shortTime24HourForce",
        timeZone: RNLocalize.getTimeZone(),
      })
    : I18n.t("DATE_FORMATS.ABBR_MONTH_DAY_YEAR_HOUR_MINUTE_24_HOUR");

  const textStyle = expiresAt ? "default" : "secondary";
  const dateLabel = <MkiText textStyle={textStyle}>{formattedLocalExpiresAt}</MkiText>;

  return (
    <MkiDatePicker
      testID={`DATE_PICKER.${textStyle.toUpperCase()}`}
      mode={DateTimePickerModes.datetime}
      date={expiresAt}
      format={{ dateFormat: "longDate", timeFormat: "shortTime24HourForce" }}
      customLabel={dateLabel}
      onConfirm={onConfirm}
      minimumDate={new Date()}
      minuteInterval={30}
      containerStyles={styles.pickerContainer}
      icon={
        <MerakiIcon
          name="edit"
          size="s"
          color={MkiColors.secondaryButton}
          containerStyle={styles.pencilIcon}
          testID="ICON.EDIT"
        />
      }
    />
  );
};

const styles = StyleSheet.create({
  pickerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: SPACING.default,
    borderBottomWidth: 0,
  },
  pencilIcon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
});

export default DatePickerRow;
