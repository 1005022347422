import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import * as RNLocalize from "react-native-localize";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import { formatTimeStringToDate } from "~/lib/formatHelper";
import ButtonGroup from "~/shared/components/ButtonGroup";
import MkiDatePicker, { DateTimePickerModes } from "~/shared/components/MkiDatePicker";
import MkiText from "~/shared/components/MkiText";
import { ScheduleRow } from "~/shared/types/Models";

const TO = "to";
const FROM = "from";

const DEFAULT_FROM = "12:00 am";
const DEFAULT_TO = "12:00 am";

interface OutageScheduleRowProps {
  onChange: (changes: Omit<ScheduleRow, "day">) => void;
  active?: boolean;
  from?: string;
  to?: string;
}

export function OutageScheduleRow(props: OutageScheduleRowProps) {
  const { active, from, to, onChange } = props;

  const [isActive, setIsActive] = useState(active === undefined ? true : active);
  const [isAllDay, setIsAllDay] = useState(false);
  const [fromValue, setFromValue] = useState(from || DEFAULT_FROM);
  const [fromDate, setFromDate] = useState(formatTimeStringToDate(fromValue));
  const [toValue, setToValue] = useState(to || DEFAULT_TO);
  const [toDate, setToDate] = useState(formatTimeStringToDate(toValue));

  useEffect(() => {
    if (active !== undefined && active !== isActive) {
      setIsActive(active);
    }
    if (from && from !== fromValue) {
      setFromValue(from);
      setFromDate(formatTimeStringToDate(from));
    }
    if (to && to !== toValue) {
      setToValue(to);
      setToDate(formatTimeStringToDate(to));
    }
    // updating the dependency array here with anything except these 3 variables
    // causes the screen to crash because it causes the below
    // onChange function in useEffect to call itself repeatedly until the screen
  }, [to, from, active]);

  useEffect(() => {
    const changes = {
      from: fromValue,
      to: toValue,
      isActive,
    };
    onChange(changes);
    // updating the dependency array here causes this screen to crash the app
    // because onChange comes from the SSIDSchedulesScreen and gets repeatedly rerendered
  }, [fromValue, isActive, toValue]);

  useEffect(() => setIsAllDay(fromValue === toValue), [fromValue, toValue]);

  const onChangeDate = (date: Date, interval: string) => {
    if (interval === FROM) {
      setFromDate(date);
      setFromValue(
        formatDate(date, {
          dateFormat: "hide",
          timeFormat: "shortTime",
          timeZone: RNLocalize.getTimeZone(),
        }),
      );
    } else if (interval === TO) {
      setToDate(date);
      setToValue(
        formatDate(date, {
          dateFormat: "hide",
          timeFormat: "shortTime",
          timeZone: RNLocalize.getTimeZone(),
        }),
      );
    }
  };

  const onChangeAllDay = () => {
    const setAllDayTimes = !isAllDay;
    if (setAllDayTimes) {
      onChangeDate(formatTimeStringToDate(DEFAULT_FROM), FROM);
      onChangeDate(formatTimeStringToDate(DEFAULT_TO), TO);
    }
    setIsAllDay(setAllDayTimes);
  };

  const renderAvailabilityRow = () => {
    return (
      <ButtonGroup
        onPress={() => {
          setIsActive(!isActive);
        }}
        selectedIndex={isActive ? 0 : 1}
        buttons={[
          I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_ROW.AVAILABLE"),
          I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_ROW.UNAVAILABLE"),
        ]}
      />
    );
  };

  const renderStartTimeButton = () => {
    return (
      <View style={styles.timeRowContainer}>
        <MkiText textStyle="secondary">
          {I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_ROW.START_TIME")}
        </MkiText>
        <View style={styles.timeButtonContainer}>{renderStartTimeModal()}</View>
      </View>
    );
  };

  const renderEndTimeButton = () => {
    return (
      <View style={styles.timeRowContainer}>
        <MkiText textStyle="secondary">
          {I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_ROW.END_TIME")}
        </MkiText>
        <View style={styles.timeButtonContainer}>{renderEndTimeModal()}</View>
      </View>
    );
  };
  const renderAllDayCheckbox = () => {
    return (
      <TouchableOpacity onPress={onChangeAllDay}>
        <View style={styles.checkBoxView}>
          <CheckBox selected={isAllDay} screenStyles={styles.checkBoxStyle} />
          <MkiText textStyle="secondary">
            {I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_ROW.ALL_DAY")}
          </MkiText>
        </View>
      </TouchableOpacity>
    );
  };

  const renderStartTimeModal = () => {
    return (
      <MkiDatePicker
        disabled={isAllDay}
        mode={DateTimePickerModes.time}
        format={{ dateFormat: "hide", timeFormat: "shortTime" }}
        date={fromDate}
        dateLabel={fromDate}
        minuteInterval={15}
        onConfirm={(date: Date) => {
          onChangeDate(date, FROM);
        }}
        containerStyles={styles.datetimeContainer}
        disabledContainerStyles={styles.datetimeContainer}
      />
    );
  };

  const renderEndTimeModal = () => {
    return (
      <MkiDatePicker
        disabled={isAllDay}
        mode={DateTimePickerModes.time}
        format={{ dateFormat: "hide", timeFormat: "shortTime" }}
        date={toDate}
        dateLabel={toDate}
        minuteInterval={15}
        onConfirm={(date: Date) => {
          onChangeDate(date, TO);
        }}
        containerStyles={styles.datetimeContainer}
        disabledContainerStyles={styles.datetimeContainer}
      />
    );
  };

  return (
    <View>
      <View style={styles.outageContainer}>
        {renderAvailabilityRow()}
        <View style={styles.timesRowContainer}>
          {renderStartTimeButton()}
          {renderEndTimeButton()}
        </View>
      </View>
      {renderAllDayCheckbox()}
    </View>
  );
}

const styles = StyleSheet.create({
  outageContainer: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  datetimeContainer: {
    borderBottomWidth: 0,
    marginVertical: 0,
  },
  timeButtonContainer: {
    alignItems: "center",
    flexGrow: 1,
    borderBottomColor: MkiColors.primaryButton,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.small,
  },
  timeRowContainer: {
    flexGrow: 1,
    flexDirection: "row",
  },
  timesRowContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    marginVertical: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  checkBoxStyle: {
    marginHorizontal: SPACING.default,
  },
  checkBoxView: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export default OutageScheduleRow;
