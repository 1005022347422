import { View } from "react-native";

import { Counter, CounterProps } from "./Counter";

export type StackedCounterProps = {
  negative?: number;
  warning?: number;
  positive?: number;
  general?: number;
  testID?: string;
};

type PossibleStatuses = Exclude<CounterProps["status"], undefined>;

const ALERT_ORDER_REVERSED: Array<PossibleStatuses> = [
  "general",
  "positive",
  "warning",
  "negative",
];
const BADGE_OFFSET = 5;

export function StackedCounter({
  negative,
  warning,
  positive,
  general,
  testID,
}: StackedCounterProps) {
  const valueMap = {
    negative,
    warning,
    positive,
    general,
  };
  const alertTotal = (general ?? 0) + (positive ?? 0) + (warning ?? 0) + (negative ?? 0);

  const filteredStatuses = ALERT_ORDER_REVERSED.filter((status) => (valueMap[status] ?? 0) > 0);

  return (
    <View
      style={{
        alignSelf: "flex-start",
        paddingLeft: BADGE_OFFSET * (filteredStatuses.length - 1),
      }}
      testID={testID}
    >
      {filteredStatuses.map((status, index) => {
        const first = index === 0;

        if (first) {
          return <Counter status={status} value={alertTotal} key={status} />;
        }

        return (
          <View style={{ position: "absolute", right: BADGE_OFFSET * index }} key={status}>
            <Counter status={status} value={alertTotal} />
          </View>
        );
      })}
    </View>
  );
}
