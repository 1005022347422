import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const VpnOutageSchema = z.array(
  z.tuple([
    z.number(), // timestamp
    z.number(), // status
  ]),
);

export const SubnetSchema = z.object({
  name: z.string(),
  subnet: z.string(),
  vlan_id: z.number(),
  vpn_mode: z.string(),
  gateway_ip: z.string().nullable(),
});

export const VpnDetailsPeerSchema = z.object({
  id: z.string(),
  usage: z.number(),
  avg_lat: z.number(),
  downtime: z.number(),
  peer_status: z.number().optional(),
  good: z.number().optional().nullable(),
  bad: z.number().optional().nullable(),
  none: z.number().optional().nullable(),
});

export const VpnDetailsSchema = z.object({
  details: z.object({
    firmware_version: z.object({
      major: z.number(),
      minor: z.number(),
    }),
    ipsec_peer_count: z.number(),
    ipsec_peers: z.array(z.any()),
    noun: z.string(),
    peer_count: z.number(),
    peers: z.array(VpnDetailsPeerSchema),
    subnet_count: z.number(),
    subnets: z.array(SubnetSchema),
    supports_ipsec_status: z.boolean(),
    uplink_count: z.number(),
  }),
  details_timeseries: z.array(z.array(z.number().or(z.array(z.number())))),
  outages: VpnOutageSchema,
});

export type PeerDetail = z.infer<typeof VpnDetailsPeerSchema>;
export type VpnDetails = z.infer<typeof VpnDetailsSchema>;

type VpnDetailsRequest = {
  networkEid?: string;
};

type VpnDetailsRequestParams = VpnDetailsRequest & {
  timespan: number;
};

function buildUrl({ networkEid }: VpnDetailsRequest) {
  return `/n/${networkEid}/manage/vpn/fetch_details/${networkEid}`;
}

function fetchVpnDetails({ networkEid, timespan }: VpnDetailsRequestParams): Promise<VpnDetails> {
  if (!networkEid) {
    throw new Error("Undefined networkEid when fetching VPN details");
  }

  const t1 = Date.now();
  const t0 = t1 - timespan;

  return request(VpnDetailsSchema, "GET", buildUrl({ networkEid }), { queryParams: { t0, t1 } });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useVpnDetails = createQuery<VpnDetailsRequestParams, VpnDetails>({
  baseQueryKey: buildUrl({ networkEid: "{networkEid}" }),
  queryFn: (variables) => fetchVpnDetails(variables),
  requiredVariables: ["networkEid"],
});
