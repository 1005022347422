import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { SSIDRowList, SSIDRowListForm } from "@meraki/go/ssid";
import {
  getLimitIndex,
  getUsageLimitPolicyName,
  LimitUsageCard,
  LimitUsageCardForm,
  TRAFFIC_SHAPING_LIMIT_VALUES,
} from "@meraki/go/traffic-shaping";
import { BottomSheet, Button } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  AssignedSsidPolicy,
  BuiltInPolicyNames,
  ClientType,
  CustomGroupPolicyName,
  PoliciesByClient,
  PoliciesBySsid,
  queryClient,
  useConfiguredSsids,
  useCreateGroupPolicy,
  useCreateProvisionClients,
  useGroupPolicies,
  usePoliciesByClient,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { formatTransferBits, kilobitsToMebibitsInt, toList } from "@meraki/shared/formatters";
import { showDeleteConfirmAlert, showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { Alert } from "react-native";

interface LimitUsageBottomSheetProps {
  client: ClientType;
  clientPolicies?: PoliciesByClient;
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

type LimitUsageBottomSheetForm = SSIDRowListForm & LimitUsageCardForm;

export function LimitUsageBottomSheet({
  client,
  clientPolicies,
  bottomSheetRef,
}: LimitUsageBottomSheetProps) {
  const networkId = useCurrentNetworkId();

  const { data: groupPolicies } = useGroupPolicies({ networkId });
  const { data: availableSSIDs } = useConfiguredSsids({ networkId });

  const { mutateAsync: createGroupPolicy, isLoading: isLoadingCreateGroupPolicy } =
    useCreateGroupPolicy();
  const { mutate: updateClientPolicy, isLoading: isLoadingUpdateClientPolicy } =
    useCreateProvisionClients();

  const existingSsidPolicies: AssignedSsidPolicy[] = [];
  const limitedSsids: number[] = [];
  const blockedSsids: number[] = [];
  let groupPolicyId: string | undefined = undefined;
  clientPolicies?.assigned?.forEach((policy) => {
    if (policy.type === "ssid") {
      // Go allows only one custom group policy per client
      if (policy.groupPolicyId) {
        if (groupPolicyId === undefined) {
          groupPolicyId = policy.groupPolicyId;
        }

        limitedSsids.push(policy.ssid.number);
      } else {
        blockedSsids.push(policy.ssid.number);
      }

      existingSsidPolicies.push(policy);
    }
  });
  const groupPolicy = groupPolicies?.find((policy) => policy.groupPolicyId === groupPolicyId);

  const methods = useForm<LimitUsageBottomSheetForm>({
    defaultValues: {
      limitIndex: undefined,
      ssidNumbers: [],
    },
    values: {
      limitIndex: getLimitIndex(groupPolicy?.bandwidth?.bandwidthLimits.limitDown),
      ssidNumbers: limitedSsids,
    },
  });

  //create a group policy if it doesn't already exist
  //then use the provision endpoint to assign that group policy
  //to the client "per connection"
  const submit = methods.handleSubmit(({ limitIndex, ssidNumbers }) => {
    const onSave = async () => {
      const policiesBySsid = existingSsidPolicies.reduce<PoliciesBySsid>((result, policy) => {
        // only set built-in policies first
        const builtInPolicyName = BuiltInPolicyNames.find((name) => name === policy.name);
        if (builtInPolicyName) {
          result[policy.ssid.number] = { devicePolicy: builtInPolicyName };
        }

        return result;
      }, {});

      try {
        if (ssidNumbers.length > 0 && limitIndex != null) {
          const limitInMB = TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex];
          const limitInMiB = kilobitsToMebibitsInt(limitInMB);
          if (limitInMB == null || limitInMiB == null) {
            return;
          }

          const existingGroupPolicy = groupPolicies?.find(
            (policy) => policy.bandwidth?.bandwidthLimits.limitDown === limitInMiB,
          );

          let newGroupPolicyId = existingGroupPolicy?.groupPolicyId;
          if (!existingGroupPolicy && ssidNumbers.length > 0) {
            await createGroupPolicy(
              {
                networkId: networkId,
                name: getUsageLimitPolicyName(formatTransferBits(limitInMB)),
                bandwidth: {
                  settings: "custom",
                  bandwidthLimits: {
                    limitUp: limitInMiB,
                    limitDown: limitInMiB,
                  },
                },
              },
              {
                onSuccess: (data) => {
                  if (data.groupPolicyId != null) {
                    newGroupPolicyId = data.groupPolicyId;
                  }
                },
                onError: (error) => {
                  Alert.alert(String(error["errors"]));
                },
              },
            );
          }

          if (newGroupPolicyId == null) {
            return;
          }

          ssidNumbers.forEach((ssidNumber) => {
            policiesBySsid[ssidNumber] = {
              devicePolicy: CustomGroupPolicyName,
              groupPolicyId: newGroupPolicyId,
            };
          });
        }
      } catch (error) {
        // let onError handle error
      } finally {
        updateClientPolicy(
          {
            networkId,
            body: {
              devicePolicy: "Per connection",
              clients: [{ mac: client.mac, clientId: client.id }],
              policiesBySsid,
            },
          },
          {
            onError: (error) => showErrorAlert(String(error["errors"]), I18n.t("ERROR")),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: usePoliciesByClient.queryKey({ networkId }),
              });
              bottomSheetRef?.current?.dismiss();
            },
          },
        );
      }
    };

    const isCurrentlyBlocked = blockedSsids.filter((ssid) => ssidNumbers.includes(ssid));
    const unlimitedSsids = limitedSsids.filter((ssid) => !ssidNumbers.includes(ssid));
    const newLimitedSsids = ssidNumbers.filter((ssid) => !limitedSsids.includes(ssid));
    const isCurrentlyBlockedSsidNames: string[] = [];
    const selectedSsidNames: string[] = [];
    const unlimitedSsidNames: string[] = [];
    const newLimitedSsidNames: string[] = [];

    availableSSIDs?.forEach((ssid) => {
      if (isCurrentlyBlocked.includes(ssid.number)) {
        isCurrentlyBlockedSsidNames.push(ssid.name);
      } else if (ssidNumbers.includes(ssid.number)) {
        selectedSsidNames.push(ssid.name);
      }
      if (unlimitedSsids.includes(ssid.number)) {
        unlimitedSsidNames.push(ssid.name);
      }
      if (newLimitedSsids.includes(ssid.number)) {
        newLimitedSsidNames.push(ssid.name);
      }
    });

    const description = () => {
      const clientName = client.description ?? "";

      if (ssidNumbers.length === 0) {
        return I18n.t("THROTTLE_CLIENT.WARNING.REMOVE_ALL_LIMIT", {
          client_name: clientName,
        });
      }
      if (isCurrentlyBlocked.length > 0) {
        return I18n.t("THROTTLE_CLIENT.WARNING.UNBLOCK_AND_LIMIT", {
          client_name: clientName,
          blocked_ssids: toList(isCurrentlyBlockedSsidNames),
          ssid_names: toList(newLimitedSsidNames),
        });
      } else if (unlimitedSsids.length > 0 && newLimitedSsids.length > 0) {
        return I18n.t("THROTTLE_CLIENT.WARNING.REMOVE_LIMIT_AND_LIMIT", {
          client_name: clientName,
          limited_ssids: toList(unlimitedSsidNames),
          ssid_names: toList(newLimitedSsidNames),
        });
      } else if (unlimitedSsids.length > 0) {
        return I18n.t("THROTTLE_CLIENT.WARNING.REMOVE_LIMIT", {
          client_name: clientName,
          ssid_names: toList(unlimitedSsidNames),
        });
      } else {
        return I18n.t("THROTTLE_CLIENT.WARNING.LIMIT", {
          client_name: clientName,
          ssid_names: toList(selectedSsidNames),
        });
      }
    };

    showDeleteConfirmAlert(I18n.t("SSID_DETAILS.CONFIRMATION"), description(), onSave);
  });

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("THROTTLE_CLIENT.HEADING")}
        cancelLabel={I18n.t("CANCEL")}
        resetLabel={I18n.t("RESET")}
        onResetPress={() => methods.reset()}
        onCancelPress={() => {
          methods.reset();
          bottomSheetRef?.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Form {...methods}>
          <Box paddingBottom="sm">
            <LimitUsageCard title={I18n.t("THROTTLE_CLIENT.SUBTITLE")} />
          </Box>
          <Box paddingBottom="sm">
            <SSIDRowList />
          </Box>
        </Form>
        <Button
          text={I18n.t("SAVE")}
          onPress={submit}
          testID="SAVE_BUTTON"
          disabled={!methods.formState.isDirty}
          loading={isLoadingCreateGroupPolicy || isLoadingUpdateClientPolicy}
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
