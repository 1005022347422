import { DNSServers } from "~/go/types/NetworksTypes";

export const SUBNET_PLACEHOLDER = ["172", "16", "10", "0", "24"] as const;
export const DEFAULT_CLIENT_VPN_SUBNET = "172.16.10.0/24";

export const DNS_PROVIDER_OPTIONS = [
  {
    label: "Google",
    value: DNSServers.google_dns,
  },
  {
    label: "Open DNS",
    value: DNSServers.opendns,
  },
  {
    label: "Custom",
    value: DNSServers.custom,
  },
];
export const VPN_TYPE = "L2TP/IPSec PSK";

export type DNSProvider = DNSServers.google_dns | DNSServers.opendns | DNSServers.custom;

export type IpsecSettings = {
  enabled: boolean;
  hostname?: string;
  sharedSecret?: string;
  ipv4?: {
    subnet: string;
    nameservers: {
      provider: DNSProvider;
      addresses?: string[];
    };
  };
};
