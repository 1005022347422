import { z } from "zod";

import { request } from "../../../api/request/request";
import {
  BatchRequest,
  buildBatchMutationUrl,
  createActionBatchSchema,
} from "../../actionBatch/useActionBatch";
import { createMutation } from "../../createMutation";
import { SwitchPort, SwitchPortSchema } from "./useSwitchPorts";

export const BatchUpdateSwitchPortsSchema = createActionBatchSchema(SwitchPortSchema.partial());

type BatchUpdateSwitchPorts = z.infer<typeof BatchUpdateSwitchPortsSchema>;

interface UpdateSwitchPortsRequest extends BatchRequest {
  serial: string;
  switchPorts: SwitchPort[];
}

export const StagedSwitchPortSchema = SwitchPortSchema.omit({
  portId: true,
  linkNegotiationCapabilities: true,
}).describe("StagedSwitchPortSchema");

const buildUrl = (serial: string, portId: string) => {
  return `/devices/${serial}/switch/ports/${portId}`;
};

const mutateSwitchPorts = async ({
  organizationId,
  serial,
  switchPorts,
}: UpdateSwitchPortsRequest) => {
  const actions = switchPorts.map((port) => ({
    resource: buildUrl(serial, port.portId),
    operation: "update",
    body: StagedSwitchPortSchema.strip().parse(port),
  }));

  const response = await request(
    BatchUpdateSwitchPortsSchema,
    "POST",
    buildBatchMutationUrl({ organizationId }),
    {
      body: JSON.stringify({
        confirmed: true,
        synchronous: true,
        actions,
      }),
    },
  );

  if (!response.status.completed) {
    throw Error(`${response.status.errors} when updating switch ports`);
  }
  return response;
};

export const useUpdateSwitchPorts = createMutation<
  UpdateSwitchPortsRequest,
  BatchUpdateSwitchPorts,
  Error
>({
  baseMutationKey: buildBatchMutationUrl({
    organizationId: "{organizationId}",
  }),
  mutationFn: (variables) => mutateSwitchPorts(variables),
});
