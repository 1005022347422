import {
  ALERT_SETTINGS_FAILURE,
  ALERT_SETTINGS_REQUEST,
  ALERT_SETTINGS_SUCCESS,
  SET_ALERT_SETTINGS_FAILURE,
  SET_ALERT_SETTINGS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

const initialState = {
  isFetching: false,
  items: {},
};

export default function alertSettings(state = initialState, action: any) {
  switch (action.type) {
    case ALERT_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_ALERT_SETTINGS_SUCCESS:
    case ALERT_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [action.meta.networkId]: action.response?.alerts
            ?.filter((alert: any) => alert.type !== "sensorAlert")
            .reduce(
              (result: any, alert: any) => ({
                ...result,
                [alert.type]: alert,
              }),
              {},
            ),
        },
      };
    case SET_ALERT_SETTINGS_FAILURE:
    case ALERT_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
