const deviceKeys = {
  clients: ["clients"] as const,
  lldpCdp: ["lldpCdp"] as const,
  details: ["details"] as const,
  events: ["events"] as const,
  networkDevicesStatusOverview: ["networkDevicesStatusOverview"] as const,
  organizationDeviceAvailabilities: ["organizationDeviceAvailabilities"] as const,
};

export default deviceKeys;
