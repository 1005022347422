import { PureComponent } from "react";

import { CancelablePromise, makeCancelablePromise } from "~/lib/RequestUtils";
import { WrappedScreenComponent } from "~/shared/types/Hocs";

export interface WithCancelablePromiseProps<T extends object = object> {
  cancelablePromise: (request: Promise<unknown>) => Promise<{ response: T }>;
}

function withCancelablePromise<T extends object, P extends object>(
  WrappedComponent: WrappedScreenComponent<P>,
) {
  return class WithCancelablePromise extends PureComponent<P & WithCancelablePromiseProps<T>> {
    cancelableRequest: CancelablePromise | undefined;

    componentWillUnmount() {
      if (this.cancelableRequest) {
        this.cancelableRequest.cancel();
      }
    }

    cancelablePromise = (request: Promise<unknown>): Promise<void> => {
      this.cancelableRequest = makeCancelablePromise(request);
      return this.cancelableRequest.promise;
    };

    render() {
      return <WrappedComponent {...this.props} cancelablePromise={this.cancelablePromise} />;
    }
  };
}

export default withCancelablePromise;
