import {
  CREATE_GO_NETWORK_FAILURE,
  CREATE_GO_NETWORK_REQUEST,
  CREATE_GO_NETWORK_SUCCESS,
  DELETE_NETWORK_FAILURE,
  DELETE_NETWORK_REQUEST,
  DELETE_NETWORK_SUCCESS,
  GET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE,
  GET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST,
  GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
  GET_NETWORK_FAILURE,
  GET_NETWORK_REQUEST,
  GET_NETWORK_SETTINGS_FAILURE,
  GET_NETWORK_SETTINGS_REQUEST,
  GET_NETWORK_SETTINGS_SUCCESS,
  GET_NETWORK_SUCCESS,
  MX_GET_L7_FIREWALL_RULES_FAILURE,
  MX_GET_L7_FIREWALL_RULES_REQUEST,
  MX_GET_L7_FIREWALL_RULES_SUCCESS,
  MX_PUT_L7_FIREWALL_RULES_FAILURE,
  MX_PUT_L7_FIREWALL_RULES_REQUEST,
  MX_PUT_L7_FIREWALL_RULES_SUCCESS,
  MX_TRAFFIC_SHAPING_GET_FAILURE,
  MX_TRAFFIC_SHAPING_GET_REQUEST,
  MX_TRAFFIC_SHAPING_GET_SUCCESS,
  MX_TRAFFIC_SHAPING_PUT_FAILURE,
  MX_TRAFFIC_SHAPING_PUT_REQUEST,
  MX_TRAFFIC_SHAPING_PUT_SUCCESS,
  NETWORK_TRACK_SETTINGS_FAILURE,
  NETWORK_TRACK_SETTINGS_REQUEST,
  NETWORK_TRACK_SETTINGS_SUCCESS,
  SET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE,
  SET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST,
  SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
  SET_NETWORK_SETTINGS_FAILURE,
  SET_NETWORK_SETTINGS_REQUEST,
  SET_NETWORK_SETTINGS_SUCCESS,
  UPDATE_NETWORK_FAILURE,
  UPDATE_NETWORK_REQUEST,
  UPDATE_NETWORK_SUCCESS,
  UPDATE_TIME_ZONE_FAILURE,
  UPDATE_TIME_ZONE_REQUEST,
  UPDATE_TIME_ZONE_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import Network from "~/api/models/Network";
import { ApplicationRule, SecurityTrafficShapingSettings } from "~/go/types/NetworksTypes";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, getNetworkTimezone } from "~/selectors";
import { FirewallLayerRule } from "~/shared/types/FirewallLayerRule";
import { NetworkSettings } from "~/shared/types/Networks";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function updateTimeZone(timeZone: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_TIME_ZONE_REQUEST, UPDATE_TIME_ZONE_SUCCESS, UPDATE_TIME_ZONE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}`,
        config: {
          method: Method.put,
          body: JSON.stringify({ timeZone }),
        },
        meta: {
          networkId,
          timeZone,
        },
      }),
    );
  };
}

export const getNetworkClientTrackingMethod = (networkId: any) => {
  return {
    [CALL_API]: {
      types: [
        NETWORK_TRACK_SETTINGS_REQUEST,
        NETWORK_TRACK_SETTINGS_SUCCESS,
        NETWORK_TRACK_SETTINGS_FAILURE,
      ],
      endpoint: `/api/v1/networks/${networkId}/appliance/settings`,
      config: {
        method: Method.get,
      },
    },
  };
};

export function getGXLayer7FirewallRules(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            MX_GET_L7_FIREWALL_RULES_REQUEST,
            MX_GET_L7_FIREWALL_RULES_SUCCESS,
            MX_GET_L7_FIREWALL_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/firewall/l7FirewallRules`,
          config: {
            method: Method.get,
          },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
}

export function updateGXLayer7FirewallRules(
  rules?: FirewallLayerRule[],
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            MX_PUT_L7_FIREWALL_RULES_REQUEST,
            MX_PUT_L7_FIREWALL_RULES_SUCCESS,
            MX_PUT_L7_FIREWALL_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/firewall/l7FirewallRules`,
          config: {
            method: Method.put,
            body: JSON.stringify({ rules }),
          },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
}

export function getGXTrafficShapingRules(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            MX_TRAFFIC_SHAPING_GET_REQUEST,
            MX_TRAFFIC_SHAPING_GET_SUCCESS,
            MX_TRAFFIC_SHAPING_GET_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/trafficShaping/rules`,
          config: {
            method: Method.get,
          },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
}

export function updateGXTrafficShapingRules(options: {
  rules?: ApplicationRule[];
  defaultRulesEnabled?: boolean;
}): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            MX_TRAFFIC_SHAPING_PUT_REQUEST,
            MX_TRAFFIC_SHAPING_PUT_SUCCESS,
            MX_TRAFFIC_SHAPING_PUT_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/trafficShaping/rules`,
          config: {
            method: Method.put,
            body: JSON.stringify(options),
          },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
}

export const getGXTrafficShapingSettings = (): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            GET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST,
            GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
            GET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/trafficShaping`,
          config: { method: Method.get },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const setGXTrafficShapingSettings = (
  settings: SecurityTrafficShapingSettings,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    if (networkId) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            SET_GX_TRAFFIC_SHAPING_SETTINGS_REQUEST,
            SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
            SET_GX_TRAFFIC_SHAPING_SETTINGS_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/appliance/trafficShaping`,
          config: {
            method: Method.put,
            body: JSON.stringify(settings),
          },
          meta: {
            networkId,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export function updateNetwork(updateParams: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    return dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_NETWORK_REQUEST, UPDATE_NETWORK_SUCCESS, UPDATE_NETWORK_FAILURE],
        endpoint: `/api/v1/networks/${networkId}`,
        config: {
          method: Method.put,
          body: JSON.stringify(updateParams),
        },
        meta: {
          networkId,
        },
      }),
    );
  };
}

export function deleteNetwork(networkId: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [DELETE_NETWORK_REQUEST, DELETE_NETWORK_SUCCESS, DELETE_NETWORK_FAILURE],
        endpoint: `/api/v1/networks/${networkId}`,
        config: { method: Method.delete },
      }),
    );
}

export function fetchNetworkSettings(): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId == null) {
      return Promise.resolve();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          GET_NETWORK_SETTINGS_REQUEST,
          GET_NETWORK_SETTINGS_SUCCESS,
          GET_NETWORK_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/settings`,
        config: {
          method: Method.get,
        },
        meta: {
          networkId,
        },
      }),
    );
  };
}

export function createGoNetwork(name: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const timezone = getNetworkTimezone(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [CREATE_GO_NETWORK_SUCCESS, CREATE_GO_NETWORK_REQUEST, CREATE_GO_NETWORK_FAILURE],
        endpoint: `/go/create_network`,
        config: {
          method: Method.post,
          body: JSON.stringify({
            name,
            time_zone: timezone,
          }),
        },
      }),
    );
  };
}

export function setNetworkSettings(
  settings: NetworkSettings,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId == null) {
      return Promise.reject();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          SET_NETWORK_SETTINGS_REQUEST,
          SET_NETWORK_SETTINGS_SUCCESS,
          SET_NETWORK_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/settings`,
        config: {
          method: Method.put,
          body: JSON.stringify(settings),
        },
        meta: {
          networkId,
        },
      }),
    );
  };
}

export const getNetwork = (networkId: string): AppThunk<Promise<ApiResponseAction<Network>>> => {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        types: [GET_NETWORK_REQUEST, GET_NETWORK_SUCCESS, GET_NETWORK_FAILURE],
        endpoint: `/api/v1/networks/${networkId}`,
        config: {
          method: Method.get,
        },
      },
    });
};
