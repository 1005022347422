export const deviceIllustrationColors = {
  topFillColor: "#EEEFF0",
  outlineFillColor: "#FFFFFF",
  sideFillColor: "#D3D6D8",
  otherSideFillColor: "#E4E7E8",
  logoFillColor: "#8f9ca1",
  activePortFillColor: "#8f9ca1",
  inactivePortFillColor: "#D3D6D8",
};

export const svgLayoutStyle = { flex: 1 };
