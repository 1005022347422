import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useCallback } from "react";

import I18n from "~/i18n/i18n";
import { calculateTimespanForPresence } from "~/lib/timeHelper";
import {
  currentOrganization,
  encryptedNetworkIdSelector,
  getNetworkHasClients,
  getNetworkHasDevices,
  homeSSIDState,
  isLocationAnalyticsEnabled as locationAnalyticsEnabled,
  networkTypesSelector,
} from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

interface UseGetHomeScreenDataProps {
  showingAllNetworks: boolean;
}

export const useGetHomeScreenData = ({ showingAllNetworks }: UseGetHomeScreenDataProps) => {
  const homeSSID = useAppSelector(homeSSIDState);
  const networkTypes = useAppSelector(networkTypesSelector);
  const networkHasClients = useAppSelector(getNetworkHasClients);
  const networkHasDevices = useAppSelector(getNetworkHasDevices);
  const timespan = useGlobalTimespan();
  const networkId = useCurrentNetworkId() || "";
  const encryptedId = useAppSelector(encryptedNetworkIdSelector);
  const currentOrg = useAppSelector(currentOrganization);
  const isLocationAnalyticsEnabled = useAppSelector(locationAnalyticsEnabled);

  const actions = useActions();

  const getData = useCallback(async () => {
    if (!currentOrg && !encryptedId) {
      await actions.wipeRedux();
      await actions.setAuthFail(I18n.t("LOGIN.UNSUCCESSFUL"));
    }

    const orgId = currentOrg?.id || "";
    if (!encryptedId) {
      await actions.fetchOrgNetworks(orgId);
    }

    const reqs: Promise<unknown>[] = [];

    if (encryptedId) {
      if (networkHasDevices) {
        reqs.push(actions.loadNodesAndStatuses(networkId));
      }
      if (networkHasClients) {
        if (showingAllNetworks) {
          reqs.push(actions.getNetworkUseblocks(networkId, encryptedId, timespan));
        } else {
          reqs.push(actions.getSsidUseblocks(networkId, encryptedId, homeSSID, timespan));
        }

        const ssidNumber = showingAllNetworks ? -1 : homeSSID;

        reqs.push(actions.getCounterSets(networkId, encryptedId));
        actions.getClientsAndUseblocks(timespan, ssidNumber);
      }
      if (networkTypes.hasWireless) {
        reqs.push(actions.getSsids(networkId));
      }
      if (isLocationAnalyticsEnabled) {
        const { t0, t1 } = calculateTimespanForPresence(timespan);
        reqs.push(actions.getPresenceData(t0, t1, timespan));
        reqs.push(actions.getWirelessSettings(networkId));
      }
      reqs.push(actions.fetchOrgNetworks(orgId));
      reqs.push(actions.fetchAdmins());
      reqs.push(actions.fetchIpsecVPNSettings());
      reqs.push(actions.fetchOrgs());
      reqs.push(actions.fetchOrgNFOs());
      reqs.push(actions.checkAndFetchUmbrellaAction(actions.fetchUmbrellaForHomeScreen));
    }

    if (reqs.length > 0) {
      return Promise.all(reqs);
    }
    return Promise.resolve();
  }, [
    actions,
    currentOrg,
    encryptedId,
    homeSSID,
    isLocationAnalyticsEnabled,
    networkHasClients,
    networkHasDevices,
    networkId,
    networkTypes.hasWireless,
    showingAllNetworks,
    timespan,
  ]);

  return getData;
};
