import { PureComponent } from "react";
import { StyleSheet } from "react-native";

import { DISABLED_OPACITY, SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import MerakiIcon from "~/shared/components/icons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

export interface PortListRowProps {
  onPress: (rowData: unknown) => void;
  selectMode: boolean;
  selected: boolean;
  selectable: boolean;
  testID?: string;
}

abstract class PortListRow<P extends PortListRowProps> extends PureComponent<P> {
  abstract title: () => string;

  abstract subtitle: () => string | undefined;

  abstract portIcon: () => JSX.Element;

  icon = () => {
    const { selectMode, selected, selectable } = this.props;

    // do not mark as selected if unselectable
    const selectedOverride = selectable ? selected : false;

    // TODO: Find a way to extract checkbox selection logic into
    // CheckBoxList, or similar, component.
    return selectMode ? (
      <CheckBox selected={selectedOverride} screenStyles={styles.iconStyle} />
    ) : (
      this.portIcon()
    );
  };

  accessories = () => <MerakiIcon name="chevron-right" size="s" />;

  render() {
    const { onPress, selectMode, selectable, testID } = this.props;

    const rowOnPress = selectable ? onPress : undefined;
    const screenStyles = selectable ? {} : styles.disabled;

    return (
      <DisclosureRow
        onPress={rowOnPress}
        icon={this.icon()}
        subtitle={this.subtitle()}
        renderAccessory={!selectMode}
        accessory={this.accessories()}
        screenStyles={screenStyles}
        testID={testID}
      >
        {this.title()}
      </DisclosureRow>
    );
  }
}

const styles = StyleSheet.create({
  iconStyle: {
    marginRight: SPACING.default,
    marginVertical: SPACING.small,
  },
  disabled: {
    opacity: DISABLED_OPACITY,
  },
});

export default PortListRow;
