import { ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface NetworkCardProps {
  name: string;
  type: "ssid" | "vlan";
  isSelected: boolean;
  dropDown: boolean;
  children: ReactNode;
  onRowPress: () => void;
  onDropDownPress: () => void;
  testID?: string;
}

const NetworkCard = ({
  name,
  type,
  isSelected,
  dropDown,
  children,
  onRowPress,
  onDropDownPress,
  testID,
}: NetworkCardProps) => {
  const colors = useThemeColors();

  // TODO: UDG-3699 - Should be mostly replaced once component library is ready
  return (
    <View style={[styles.container, { borderColor: colors.text.disabled.color }]} testID={testID}>
      <View style={[styles.rowContainer, styles.rowBasic]}>
        <TouchableOpacity style={styles.rowBasic} onPress={onRowPress} testID={`${testID}.ROW`}>
          <CheckBox selected={isSelected} />
          <View style={[styles.titleStyle, styles.rowBasic]}>
            <MkiText textStyle="defaultBold" ellipsizeMode="tail" truncation={20}>
              {name}
            </MkiText>
            <MkiText textStyle="smallLight">{` ${type === "ssid" ? "SSID" : "Vlan"}`}</MkiText>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={onDropDownPress} testID={`${testID}.DROP_DOWN_CHEVRON`}>
          <MerakiIcon
            name={dropDown ? "chevron-up" : "chevron-down"}
            size={"xxs"}
            color={colors.icon.stroke}
          />
        </TouchableOpacity>
      </View>
      {dropDown && children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: SPACING.medium,
    marginVertical: SPACING.meager,
    borderWidth: 1,
    borderRadius: SPACING.small,
  },
  rowContainer: {
    flexGrow: 1,
    justifyContent: "space-between",
    paddingVertical: SPACING.small,
  },
  rowBasic: {
    flexDirection: "row",
    alignItems: "center",
  },

  titleStyle: {
    paddingLeft: SPACING.medium,
  },
});

export default NetworkCard;
