import { BaseDeviceSubProps } from "../types/BaseSubProps";
import DataMsg from "../types/DataMsg";
import { capitalizeFirstLetter } from "../utils";

export interface Props extends BaseDeviceSubProps {
  ports: string;
  portIds: string[];
}

interface PortResponse {
  port: number;
  curlink: string;
  veriphy_link: boolean;
  st0: string;
  st1: string;
  st2: string;
  st3: string;
  len0: number;
  len1: number;
  len2: number;
  len3: number;
}

export interface Message extends DataMsg {
  data: {
    ports: [PortResponse];
  };
}

export interface Status {
  status: boolean;
  pair1: boolean;
  pair2: boolean;
  pair3: boolean;
  pair4: boolean;
  linkType: string;
  length: number;
}

export function formatMsg(message?: Message): Status | undefined {
  const port = message?.data?.ports?.[0];
  if (!port) {
    return undefined;
  }

  const { curlink, st0, st1, st2, st3, len0, len1, len2, len3 } = port;

  const mapStatusToBool = (status: string) => status === "0:ok";
  const pair1 = mapStatusToBool(st0);
  const pair2 = mapStatusToBool(st1);
  const pair3 = mapStatusToBool(st2);
  const pair4 = mapStatusToBool(st3);

  const isSFP = curlink === "sfp";
  const getCableStatus = (curlink: string) => {
    switch (curlink) {
      case "down":
        return false;
      case "sfp": // TODO: verify with someone bout sfp status
      case "1Gfdx":
        return pair1 && pair2 && pair3 && pair4;
      default:
        // 100/10 Mb devices don't use pairs st2 and st3
        return pair1 && pair2;
    }
  };

  // Rationale on web dashboard is that we have +/- 3
  // precision and know there's a cable so might as well rtn 1
  let length = getCableStatus(curlink) ? 1 : 0;

  const validNumPairs = Number(pair1) + Number(pair2) + Number(pair3) + Number(pair4);
  if (!isSFP && validNumPairs > 0) {
    const totalValidLengths = len0 + len1 + len2 + len3;
    length = Math.round(totalValidLengths / validNumPairs);
  }

  return {
    status: getCableStatus(curlink),
    linkType: capitalizeFirstLetter(curlink),
    pair1: pair1,
    pair2: pair2,
    pair3: pair3,
    pair4: pair4,
    length,
  };
}
