import { AnyAction } from "redux";

import {
  LOGIN_SUCCESS,
  SET_CURRENT_USER,
  SET_HOME_SSID,
  SET_TIMESPAN,
  WIPE_REDUX,
} from "../constants/ActionTypes";

interface LoginReduxAction extends AnyAction {
  type: typeof LOGIN_SUCCESS;
}

interface SetCurrentUserReduxAction extends AnyAction {
  type: typeof SET_CURRENT_USER;
}

interface WipeReduxAction extends AnyAction {
  type: typeof WIPE_REDUX;
}

export const setHomeSSID = (homeSSID: number) => ({
  type: SET_HOME_SSID,
  homeSSID,
});

export const setGlobalTimespan = (timespan: number, skipReload = false) => ({
  type: SET_TIMESPAN,
  timespan,
  meta: {
    skipReload,
  },
});

type SetHomeSSIDAction = ReturnType<typeof setHomeSSID> & AnyAction;

type SetGlobalTimespanAction = ReturnType<typeof setGlobalTimespan> & AnyAction;

type PoorlyTypedPreferenceActions = LoginReduxAction | SetCurrentUserReduxAction | WipeReduxAction;

export type PreferenceAction =
  | SetHomeSSIDAction
  | SetGlobalTimespanAction
  | PoorlyTypedPreferenceActions;
