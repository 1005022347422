import { I18n } from "@meraki/core/i18n";
import {
  BottomSheetMethods,
  Button,
  EmptyState,
  Heading,
  TabView,
  TabViewMethods,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useAlertSettings, useClients, useNetwork, usePoliciesByClient } from "@meraki/shared/api";
import { ClientGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";
import { Alert } from "react-native";

import { BlockBottomSheet } from "../components/BlockBottomSheet";
import { EditBottomSheet } from "../components/EditBottomSheet";
import { LimitUsageBottomSheet } from "../components/LimitUsageBottomSheet";
import { TrackBottomSheet } from "../components/TrackBottomSheet";
import { ClientDetailsContext } from "./ClientDetailsContext";
import { DetailsTab } from "./tabs/DetailsTab";
import { InsightsTab } from "./tabs/InsightsTab";
import { SettingsTab } from "./tabs/SettingsTab";

const CLIENT_TABS = {
  details: {
    title: I18n.t("TAB_TITLES.DETAILS"),
    view: <DetailsTab />,
    testID: "DETAILS_TABVIEW_TAB",
  },
  insight: {
    title: I18n.t("TAB_TITLES.INSIGHTS"),
    view: <InsightsTab />,
    testID: "INSIGHTS_TABVIEW_TAB",
  },
  settings: {
    title: I18n.t("TAB_TITLES.SETTINGS"),
    view: <SettingsTab />,
    testID: "SETTINGS_TABVIEW_TAB",
  },
};

export const ClientDetailsScreen = () => {
  const route = useRoute<RouteProp<ClientGroupProps, "ClientDetails">>();
  const navigation = useNavigation<NativeStackNavigationProp<ClientGroupProps>>();
  const { params: props } = route;
  const currentNetworkId = useCurrentNetworkId();
  const networkId = props.networkId ?? currentNetworkId;
  const timespan = useGlobalTimespan();
  const tabViewRef = useRef<TabViewMethods>(null);
  const blockBottomSheetRef = useRef<BottomSheetMethods>(null);

  const { data: trackedClients } = useAlertSettings(
    { networkId },
    {
      select: (data) =>
        data.alerts.find((alert) => alert.type === "clientConnectivity")?.filters?.clients,
    },
  );
  const { data: supportsTracking } = useNetwork(
    { networkId },
    {
      enabled: Boolean(networkId),
      select: (data) => data.productTypes.some((type) => type === "appliance"),
    },
  );
  const limitUsageBottomSheetRef = useRef<BottomSheetMethods>(null);
  const editBottomSheetRef = useRef<BottomSheetMethods>(null);
  const trackBottomSheetRef = useRef<BottomSheetMethods>(null);
  const handleGoToTab = useCallback((key: string) => {
    tabViewRef.current?.goToTab(key);
  }, []);

  const handleOpenBlockBottomSheet = useCallback(() => {
    blockBottomSheetRef.current?.present();
  }, []);

  const handleOpenLimitBottomSheet = useCallback(() => {
    limitUsageBottomSheetRef.current?.present();
  }, []);

  const { data: client } = useClients(
    { networkId, timespan },
    {
      select: (data) => data?.find((client) => client.id === props.id),
    },
  );
  const { data: clientPolicies } = usePoliciesByClient(
    { networkId, timespan },
    {
      select(policies) {
        return policies.find((clientPolicy) => clientPolicy.clientId === client?.id);
      },
    },
  );

  const clientIsTracked =
    (client &&
      !!trackedClients?.find((trackedClient) => trackedClient.name === client.description)) ??
    false;

  useEffect(() => {
    const showClientTracking = () => {
      if (!supportsTracking) {
        Alert.alert(
          I18n.t("TRACK_CLIENT.WARNING.HEADING"),
          I18n.t("TRACK_CLIENT.WARNING.SUBHEADING"),
        );
      } else {
        trackBottomSheetRef.current?.present();
      }
    };
    navigation.setOptions({
      headerRight: () => (
        <>
          <Button.Icon
            icon="Pencil"
            onPress={() => editBottomSheetRef.current?.present()}
            testID="EDIT_CLIENT_BUTTON"
          />
          <Button.Icon
            icon={clientIsTracked ? "EyeSlash" : "Eye"}
            onPress={showClientTracking}
            testID={`TRACK_CLIENT_BUTTON.${clientIsTracked ? "SLASH" : "EYE"}`}
          />
        </>
      ),
    });
  }, [clientIsTracked, navigation, supportsTracking]);
  return (
    <ClientDetailsContext.Provider
      value={{
        client: client,
        goToTab: handleGoToTab,
        openBlockBottomSheet: handleOpenBlockBottomSheet,
        openLimitBottomSheet: handleOpenLimitBottomSheet,
        clientPolicies: clientPolicies,
      }}
    >
      <Screen.View testID="CLIENT_DETAILS_SCREEN">
        {!client ? (
          <EmptyState title={I18n.t("DEVICE_NOT_FOUND")} />
        ) : (
          <>
            <Box paddingVertical="none" padding="sm">
              <Heading size="h1">{client.description || client.mac}</Heading>
            </Box>
            <TabView tabs={CLIENT_TABS} ref={tabViewRef} />
            <BlockBottomSheet
              client={client}
              clientPolicies={clientPolicies}
              bottomSheetRef={blockBottomSheetRef}
            />
            <LimitUsageBottomSheet
              client={client}
              clientPolicies={clientPolicies}
              bottomSheetRef={limitUsageBottomSheetRef}
            />
            <EditBottomSheet client={client} bottomSheetRef={editBottomSheetRef} />
            <TrackBottomSheet
              client={client}
              trackedClients={trackedClients || []}
              bottomSheetRef={trackBottomSheetRef}
            />
          </>
        )}
      </Screen.View>
    </ClientDetailsContext.Provider>
  );
};
