import * as errorMonitor from "@meraki/core/errors";
import type { UnionToIntersection } from "@meraki/core/typescript";
import lib from "cometd";
import { AnyAction, applyMiddleware, createStore, Middleware, StoreEnhancer } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { isJestEnv } from "~/env";
import { isWeb } from "~/lib/PlatformUtils";
import analytics from "~/middleware/analytics";
import api from "~/middleware/api";
import { rootReducer } from "~/reducers";
import { rootPersistConfig } from "~/shared/lib/RootPersistConfig";

const cometd = new lib.CometD();
cometd.websocketEnabled = false;
// callback-polling uses JSONP and assumes there's a document object,
// which isn't true in our case. Un-registering it will prevent cometd
// from attempting to connect via this method.
cometd.unregisterTransport("callback-polling");
// onListenerException supports:
// (exception, subscriptionHandle, isListener, message) => {}
cometd.onListenerException = (e, subscriptionHandle, isListener) => {
  if (typeof e === "string") {
    errorMonitor.notify(new Error(e));
  } else if (e instanceof Error) {
    errorMonitor.notify(e);
  }
  if (isListener) {
    cometd.removeListener(subscriptionHandle);
  } else {
    cometd.unsubscribe(subscriptionHandle);
  }
};

const appThunk: ThunkMiddleware<
  ReturnType<typeof rootReducer>,
  AnyAction,
  lib.CometD
> = thunk.withExtraArgument(cometd);

const middlewaresArray = [appThunk, api, analytics] as const;

type ExtractMiddleware<T> = T extends Middleware<infer T> ? T : never;

function createStoreEnhancer(): StoreEnhancer<{
  dispatch: UnionToIntersection<ExtractMiddleware<(typeof middlewaresArray)[number]>>;
}> {
  const middlewares = [...middlewaresArray];
  if (__DEV__) {
    if (isWeb()) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require("@redux-devtools/extension").composeWithDevTools(
        applyMiddleware(...middlewares),
      );
    }
    // The Jest environment doesn't have Flipper
    if (!isJestEnv) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      middlewares.push(require("redux-flipper").default());
    }
  }

  return applyMiddleware(...middlewares);
}

export const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  createStoreEnhancer(),
);

export function setupStorePersistor() {
  return persistStore(store);
}

// Prefer AppDispatch over redux's Dispatch because AppDispatch will understand what actions
// exist in our app.
export type AppDispatch = typeof store.dispatch;

export const testables = {
  appThunk,
  cometd,
};
