import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { getProductType } from "@meraki/shared/devices";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { RefreshControl, ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { BASE_POLICIES, SPACING } from "~/constants/MkiConstants";
import ClientApplicationUsageCard from "~/go/components/ClientApplicationUsageCard";
import ClientUsageGraphCard from "~/go/components/ClientUsageGraphCard";
import TopSecurityEventsList from "~/go/components/TopSecurityEventsList";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import { showAlert, showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { deriveStatusWithLivebroker } from "~/lib/ClientUtils";
import { andCommaSeparatedString, getConnectedNum } from "~/lib/formatHelper";
import { withClientSubscription } from "~/lib/liveBroker";
import { PerfScreenNames, ScreenTrace, TraceTypes } from "~/lib/PerformanceUtils";
import { getPolicyAffectedNetworkNames } from "~/lib/SSIDUtils";
import { themeColors } from "~/lib/themeHelper";
import { calculateTimespan } from "~/lib/timeHelper";
import { ApiResponseAction } from "~/middleware/api";
import {
  clientsByMac,
  counterSetsState,
  currentNetworkState,
  encryptedNetworkIdSelector,
  getActivitiesByIp,
  getBlockedNetworksForClient,
  getClientApplicationUsages,
  getClientDataByMac,
  getClientUsageHistories,
  getLimitedNetworksForClient,
  getNetworkTimezone,
  getTrackedClientsList,
  gxDeviceSelector,
  networkGroupPolicies,
  shouldShowUmbrellaUISelector,
  slimSsidsByIdSelector,
  ssidUseblocksLoadingState,
  timespanState,
} from "~/selectors";
import ClientConnectionCard from "~/shared/components/ClientConnectionCard";
import ClientDetailsCard from "~/shared/components/ClientDetailsCard";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import DetailsHeader from "~/shared/components/header/DetailsHeader";
import NoClientData from "~/shared/components/NoClientData";
import TimePicker from "~/shared/components/TimePicker";
import { GoStatus } from "~/shared/constants/Status";
import { SettingsButton } from "~/shared/navigation/Buttons";
import { TrackedClientList } from "~/shared/types/AlertTypes";
import { ClientApplicationUsage } from "~/shared/types/ApplicationUsages";
import { BlockedNetworks, ExtendedClient, LimitedNetworks } from "~/shared/types/Client";
import { GroupPoliciesByNumber } from "~/shared/types/ClientPolicy";
import { ApplicationCounterSet } from "~/shared/types/CounterSets";
import Device from "~/shared/types/Device";
import { SSIDsByNumber } from "~/shared/types/Models";
import { ProductType } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { UmbrellaActivityByIp } from "~/shared/types/Umbrella";
import { Useblock } from "~/shared/types/Useblocks";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  clientMac?: string;
  timespan: number;
  device: Device;
  ssidsByNumber: SSIDsByNumber;
  client?: ExtendedClient;
  securityAppliance: Device | undefined;
  blockedNetworks: BlockedNetworks;
  limitedNetworks: LimitedNetworks;
  usageHistory: Useblock;
  applicationUsages: ClientApplicationUsage;
  counterSets: ApplicationCounterSet;
  groupPolicies: GroupPoliciesByNumber;
  networkId: string;
  encryptedId: string;
  clientsAreFetching: boolean;
  localeUsageIsFetching: boolean;
  ssidUsageIsFetching: boolean;
  timezone: string;
  shouldShowUmbrellaUI: boolean;
  securityEventsByIp: UmbrellaActivityByIp;
  trackedClientsList: TrackedClientList[];
};

type Association2Props = {
  associated: any;
  associatedAt: number;
  associatedBand: number;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "ClientDetails"> &
  ReduxProps &
  BasicActions &
  Association2Props &
  WithCancelablePromiseProps;

interface ClientDetailsScreenState {
  reqPending: boolean;
  refreshing: boolean;
  cachedDevice?: Device;
  cachedClient?: ExtendedClient;
}

export class ClientDetailsScreen extends PureComponent<Props, ClientDetailsScreenState> {
  private perfTrace: any;

  constructor(props: Props) {
    super(props);
    this.perfTrace = new ScreenTrace(PerfScreenNames.clientDetailsScreen, TraceTypes.totalLoad);

    this.state = {
      reqPending: false,
      refreshing: false,
      cachedDevice: undefined,
      cachedClient: undefined,
    };
  }

  componentDidMount() {
    this.updateNavBar();
    this.getData();
  }

  componentDidUpdate(prevProps: Props, prevState: ClientDetailsScreenState) {
    if (this.hasNoData(prevProps, prevState) !== this.hasNoData(this.props, this.state)) {
      this.updateNavBar();
    }

    const { timespan } = this.props;
    if (prevProps.timespan !== timespan) {
      this.getData(true, false);
    }
  }

  onRefresh = () => {
    const { reqPending } = this.state;
    if (reqPending) {
      return;
    }
    return this.getData(true, true);
  };

  getData = async (force = false, refreshing = false) => {
    const {
      ssidsByNumber,
      groupPolicies,
      actions,
      networkId,
      encryptedId,
      counterSets,
      usageHistory,
      applicationUsages,
      timespan,
      device: { serial },
    } = this.props;
    const {
      getNetworkSsidsAndPolicies,
      getSsids,
      getClientUsage,
      getCounterSets,
      loadNodes,
      getActivitiesForIp,
      getWirelessClientConnectivityEvents,
    } = actions;
    const client = this.client();

    const { response: device }: { response: Device } =
      await actions.fetchSingleDeviceDetails(serial);

    if (client == null || device == null) {
      this.perfTrace.stopTime();
      return;
    }

    const reqs: Promise<ApiResponseAction<any> | void | any[]>[] = [];

    const shouldFetchUsage = isEmpty(usageHistory) || isEmpty(applicationUsages);

    if (shouldFetchUsage || force) {
      reqs.push(getClientUsage([client.id], timespan));
    }

    if (client.wireless || client.mxWireless) {
      reqs.push(getWirelessClientConnectivityEvents(client));
    }

    // Non client specific calls
    if (!isEmpty(counterSets) || force) {
      reqs.push(
        getCounterSets(networkId, encryptedId).catch(() => {
          // This request can silently fail if a network has usage tracking disable
        }),
      );
    }

    if (isEmpty(ssidsByNumber) || force) {
      reqs.push(getSsids(networkId));
    }

    if (isEmpty(groupPolicies) || force) {
      reqs.push(getNetworkSsidsAndPolicies(encryptedId));
    }

    if (!device.serial || force) {
      reqs.push(loadNodes(networkId));
    }

    if (this.shouldShowSecurityEvents() && client.hashedIp4) {
      const now = Date.now();
      reqs.push(getActivitiesForIp(now, true, client.hashedIp4));
    }

    if (reqs.length > 0) {
      this.setState({ reqPending: true, refreshing: refreshing });
      Promise.all(reqs)
        .catch(() => showNetworkErrorWithRetry(() => this.getData(true, false)))
        .then(() => {
          this.setState({ reqPending: false, refreshing: false });
          this.perfTrace.stopTime();
        });
    } else {
      this.perfTrace.stopTime();
    }
    this.getAlertSettings();
  };

  getAlertSettings = async () => {
    const { actions } = this.props;
    try {
      await actions.fetchAlertSettings();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }
  };

  isLoading = (props: any, state: any) => {
    const { localeUsageIsFetching, ssidUsageIsFetching, clientsAreFetching } = props;
    return localeUsageIsFetching || ssidUsageIsFetching || clientsAreFetching || state.reqPending;
  };

  // hasNoData requires props and state so it can be used for comparing
  // before / after values in functions like componentDidUpdate.
  hasNoData = (props: any, state: any) => isEmpty(props.client) && !this.isLoading(props, state);

  client = () => {
    const { client } = this.props;
    const { cachedClient } = this.state;
    return isEmpty(client) ? cachedClient : client;
  };

  device = () => {
    const { device } = this.props;
    const { cachedDevice } = this.state;
    return isEmpty(device) ? cachedDevice : device;
  };

  pushSwitchPortDetailsScreen(serialNumber: string, portNumber: number, deviceId: string) {
    const { navigation } = this.props;
    navigation.navigate("SwitchPortsDetails", {
      serialNumber,
      portNumber,
      deviceId,
    });
  }

  pushGXPortDetailsScreen(portNumber: any, deviceSerial: any) {
    const { navigation, device } = this.props;
    navigation.navigate("GXPortDetails", {
      deviceSerial,
      portNumber,
      device,
    });
  }

  pushSSIDDetailsScreen(ssidNumber: number) {
    const { navigation } = this.props;
    navigation.navigate("SSIDDetails", { ssidNumber });
  }

  pushDeviceDetailsScreen(serialNumber: string) {
    const { navigation } = this.props;
    navigation.navigate("HardwareDetails", { serialNumber });
  }

  // We need to cache the client or SM device data before changing timespan.
  // After the timespan is cahnged, this data is cleared from redux and would disappear
  // from the screen unless we do this caching.
  cacheProps = () => {
    const { device, client } = this.props;
    const cachedData: { cachedDevice?: Device; cachedClient?: ExtendedClient } = {};

    if (!isEmpty(device)) {
      cachedData.cachedDevice = device;
    }

    if (!isEmpty(client)) {
      cachedData.cachedClient = client;
    }

    if (!isEmpty(cachedData)) {
      this.setState({ ...cachedData });
    }
  };

  onTimeChosen = (timespan: number) => {
    const { actions } = this.props;
    const { setTimespan } = actions;
    this.cacheProps();
    setTimespan(timespan);
  };

  savePolicy = (data: any) => {
    const { client, actions, encryptedId } = this.props;
    const getSelectedPolicy = (entry: any) => entry.next.entries.find((e: any) => e.selected).value;
    const formData: any = {};

    if (BASE_POLICIES.includes(data[0].value)) {
      formData.access = data[0].label.toLowerCase();
    } else if (data[0].value === "group") {
      formData.access = "group";
      formData.group_policy = getSelectedPolicy(data[0]);
    } else {
      formData.access = "custom";
      const customPolicy: any = {};
      data[0].next.entries.forEach((e: any) => {
        if ("ssidNum" in e) {
          customPolicy[e.ssidNum] = getSelectedPolicy(e);
        } else if (e.value === "wired") {
          customPolicy.wired = getSelectedPolicy(e);
        }
      });
      formData.custom_policy = customPolicy;
    }
    // client should always be defined here but typescript doesn't know that
    if (client) {
      formData.ids = [client.id];
      actions.setManyPolicies(encryptedId, formData);
    }
  };

  clearNavBar() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: undefined,
    });
  }

  updateNavBar() {
    if (this.hasNoData(this.props, this.state)) {
      this.clearNavBar();
    } else {
      this.setRightNavBar();
    }
  }

  setRightNavBar = () => {
    const { navigation, id } = this.props;
    navigation.setOptions({
      headerRight: () => (
        <SettingsButton
          testID="CLIENT_SETTINGS_BUTTON"
          onPress={() => {
            navigation.navigate("BlockClient", { id });
          }}
        />
      ),
    });
  };

  nodeName = (device: any) =>
    !isEmpty(device)
      ? // TOOD: Figure out how to show the date / time when a client was blocked from SSIDs
        device.name || device.mac || I18n.t("UNKNOWN")
      : I18n.t("DELETED_HARDWARE");

  renderCustomHeader = () => {
    const { ssidsByNumber, associated, securityAppliance, blockedNetworks, limitedNetworks } =
      this.props;
    const ssids = Object.values(ssidsByNumber);
    const device = this.device();
    const client = this.client();

    if (client == null) {
      return null;
    }

    const clientNotBlocked =
      !blockedNetworks.wired && Object.values(blockedNetworks.ssids).every((blocked) => !blocked);
    const clientNotLimited = Object.values(limitedNetworks.ssids).every(
      (limited) => limited == null,
    );

    // node info
    const connectedNodeOnPress = device?.serial
      ? () => this.pushDeviceDetailsScreen(device.serial)
      : undefined;

    // connection info
    let connectionType;
    let connectionName: any;
    let connectionOnPress;
    let alert;
    if (clientNotBlocked && clientNotLimited) {
      if (client.wireless) {
        connectionType = "none";
      } else if (client.mxWireless) {
        connectionType = "wireless";
        connectionName = client.ssidName;
      } else if (client.userSrc === "client_vpn") {
        connectionType = "vpn";
        connectionName = client.user;
      } else {
        connectionType = "wired";
        connectionName = getConnectedNum(client.connectedBy);
        // TODO: support MR as well
        if (device && typeof connectionName === "number") {
          const productType = getProductType(device.model);
          if (productType === ProductType.switch) {
            connectionOnPress = () =>
              this.pushSwitchPortDetailsScreen(device.serial, connectionName, device.id);
          } else if (productType === ProductType.appliance) {
            connectionOnPress = () => this.pushGXPortDetailsScreen(connectionName, device.serial);
          }
        } else {
          connectionOnPress = () => undefined;
        }
      }
    } else {
      if (!clientNotBlocked) {
        const blockedByMessage =
          blockedNetworks.wired &&
          (Object.values(blockedNetworks.ssids) || []).every((blocked) => blocked)
            ? I18n.t("BLOCK_CLIENT.ALL_NETWORKS.LONG")
            : I18n.t("BLOCK_CLIENT.SOME_NETWORKS.LONG", {
                networks: `${andCommaSeparatedString(
                  getPolicyAffectedNetworkNames(ssids, securityAppliance, blockedNetworks),
                )}`,
              });

        alert = { alertType: GoStatus.bad, alertText: blockedByMessage };
      }

      if (!clientNotLimited) {
        const limitedByMessage = Object.values(limitedNetworks.ssids).every(
          (customPolicy) => customPolicy != null,
        )
          ? I18n.t("THROTTLE_CLIENT.ALL_NETWORKS.LONG")
          : I18n.t("THROTTLE_CLIENT.SOME_NETWORKS.LONG", {
              networks: `${andCommaSeparatedString(
                getPolicyAffectedNetworkNames(ssids, securityAppliance, limitedNetworks),
              )}`,
            });

        alert = {
          alertType: GoStatus.warning,
          alertText: !clientNotBlocked
            ? `${alert?.alertText}\n${limitedByMessage}`
            : limitedByMessage,
        };
      }
    }

    const description = (
      <EditableNameHeader
        testID="CLIENT_NAME"
        title={client.description}
        entity={I18n.t("DEVICE_WORD")}
        containerStyles={styles.clientNameHeader}
      />
    );

    const derivedStatus = deriveStatusWithLivebroker(client, associated);

    const headerComponent = (
      <DetailsHeader
        description={description}
        status={derivedStatus}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        connectionType={connectionType}
        connectionName={connectionName}
        connectionOnPress={connectionOnPress}
        connectedNode={this.nodeName(device)}
        connectedNodeOnPress={connectedNodeOnPress}
        alert={alert}
        iconSize="m"
      />
    );

    return headerComponent;
  };

  renderDetails() {
    const { ssidsByNumber, clientsAreFetching, associated, associatedAt, associatedBand } =
      this.props;
    const { reqPending } = this.state;
    const device = this.device();
    const client = this.client();
    const { theme } = useTheme.getState();

    return (
      <ScrollView
        testID="ClientDetailsScreen ScrollView"
        keyboardShouldPersistTaps="handled"
        refreshControl={
          <RefreshControl
            refreshing={reqPending}
            onRefresh={this.onRefresh}
            tintColor={themeColors(theme).spinner?.color}
          />
        }
      >
        {this.renderCustomHeader()}
        {client && (
          <ClientConnectionCard
            client={client}
            ssidsByNumber={ssidsByNumber}
            device={device}
            isOnline={associated}
            associatedAt={associatedAt}
            associatedBand={associatedBand}
            loading={clientsAreFetching}
          />
        )}
        <ClientDetailsCard
          client={client}
          loading={clientsAreFetching}
          disableBottomBorder={true}
        />
        {this.renderClientUsages()}
      </ScrollView>
    );
  }

  shouldShowSecurityEvents = () => {
    const { shouldShowUmbrellaUI } = this.props;
    return shouldShowUmbrellaUI;
  };

  renderClientUsages = () => {
    const {
      timespan,
      counterSets,
      localeUsageIsFetching,
      ssidUsageIsFetching,
      clientsAreFetching,
      usageHistory,
      applicationUsages,
      timezone,
    } = this.props;
    const client = this.client();
    if (client == null || !client?.lastSeen) {
      return null;
    }

    const { t0, t1 } = calculateTimespan(timespan);
    return (
      <>
        <TimePicker timespan={timespan} setTimespan={this.onTimeChosen} />
        {this.shouldShowSecurityEvents() && this.renderSecurityEvents()}
        <ClientUsageGraphCard
          client={client}
          usageHistory={usageHistory}
          timespan={timespan}
          loading={clientsAreFetching || localeUsageIsFetching || ssidUsageIsFetching}
          t0={t0}
          t1={t1}
          timezone={timezone}
        />
        <ClientApplicationUsageCard
          applicationUsages={applicationUsages}
          counterSets={counterSets}
          loading={localeUsageIsFetching || ssidUsageIsFetching}
        />
      </>
    );
  };

  renderSecurityEvents() {
    const { securityEventsByIp, timezone } = this.props;
    const client = this.client();
    const hashedIP4 = client?.hashedIp4;
    if (client == null || hashedIP4 == null) {
      return null;
    }

    const securityEventsForClient = securityEventsByIp[hashedIP4];
    return (
      <TopSecurityEventsList
        securityEvents={securityEventsForClient}
        timezone={timezone}
        client={client}
      />
    );
  }

  render() {
    const { timespan } = this.props;
    if (this.hasNoData(this.props, this.state)) {
      return <NoClientData timespan={timespan} />;
    }

    return this.renderDetails();
  }
}

const styles = StyleSheet.create({
  clientNameHeader: {
    /**
     * There is a bug that doesn't allow paddingLeft overrides
     * https://github.com/facebook/react-native/issues/16309
     * so I have to reset the padding to zero and then reapply
     * the padding to the other sides so that the client name
     * lines up correctly.
     */
    padding: 0,
    paddingRight: SPACING.default,
    paddingVertical: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: HardwareStackPropMap["ClientDetails"],
): ReduxProps {
  const { id } = props;
  const { clientId, client, device } = getClientDataByMac(state, { id });

  const networkClient = client as ExtendedClient; // go does not support SM

  return {
    client: networkClient,
    device,
    ssidsByNumber: slimSsidsByIdSelector(state),
    clientIds: clientsByMac(state),
    securityAppliance: gxDeviceSelector(state),
    blockedNetworks: getBlockedNetworksForClient(state, { client }),
    limitedNetworks: getLimitedNetworksForClient(state, { client }),
    // @ts-expect-error TS(2739) FIXME: Type 'number[][]' is missing the following propert... Remove this comment to see the full error message
    usageHistory: getClientUsageHistories(state)[clientId],
    applicationUsages: getClientApplicationUsages(state)[clientId],
    timespan: timespanState(state),
    counterSets: counterSetsState(state),
    //@ts-ignore
    groupPolicies: networkGroupPolicies(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for ClientDetailsScreen",
      currentNetworkState(state),
    ),
    encryptedId: errorMonitor.notifyNonOptional(
      "param 'encryptedId' undefined for ClientDetailsScreen",
      encryptedNetworkIdSelector(state),
    ),
    clientsAreFetching: state.loading.clientsFetching,
    localeUsageIsFetching: state.localeUsage.isFetching,
    ssidUsageIsFetching: ssidUseblocksLoadingState(state),
    timezone: errorMonitor.notifyNonOptional(
      "param 'timezone' undefined for ClientDetailsScreen",
      getNetworkTimezone(state),
    ),
    securityEventsByIp: getActivitiesByIp(state),
    shouldShowUmbrellaUI: shouldShowUmbrellaUISelector(state),
    trackedClientsList: getTrackedClientsList(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withClientSubscription({
    type: "Association2",
    handler: ({ data }: any) => {
      const { associated, assoc_at, band } = data;
      return {
        associated,
        associatedAt: assoc_at,
        associatedBand: band,
        data,
      };
    },
    mac: (ownProps: Props) => ownProps?.client?.mac,
    nodeGroupId: (ownProps: Props) => ownProps?.client?.networkId,
  }),
  withCancelablePromise,
)(ClientDetailsScreen);
