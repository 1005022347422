import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useUpdateVlan, useVlan, Vlan } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Pressable } from "react-native";

const OPTIONS = ["TIME_OFFSET", "INTERFACE", "NTP", "TFTP", "CUSTOM"] as const;
type Option = (typeof OPTIONS)[number];

const TYPES = ["text", "ip", "hex", "integer"] as const;
type Type = (typeof TYPES)[number];

const OPTION_BY_CODE: Record<string, Option> = {
  "2": "TIME_OFFSET",
  "26": "INTERFACE",
  "42": "NTP",
  "66": "TFTP",
};

const CODE_BY_OPTION: Record<Option, string> = {
  TIME_OFFSET: "2",
  INTERFACE: "26",
  NTP: "42",
  TFTP: "66",
  CUSTOM: "",
};

const TYPE_BY_OPTION: Record<Option, Type> = {
  TIME_OFFSET: "integer",
  INTERFACE: "integer",
  NTP: "ip",
  TFTP: "text",
  CUSTOM: "text",
};

const HEX_REGEX = /^([a-zA-Z0-9]{2}:)*[a-zA-Z0-9]{2}$/;

interface DHCPOptionFormData {
  option: Option;
  code: string;
  type: Type;
  value: string;
}

export function DHCPOptionScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "DHCPOption">>();
  const { params: props } = route;
  const { vlan, optionIndex } = props;

  const dhcpOptions = useMemo(() => [...(vlan.dhcpOptions ?? [])], [vlan.dhcpOptions]);
  const dhcpOption = optionIndex == null ? undefined : dhcpOptions[optionIndex];
  const methods = useForm<DHCPOptionFormData>({
    values: {
      option: OPTION_BY_CODE[String(dhcpOption?.code)] ?? "CUSTOM",
      code: dhcpOption?.code ?? "",
      type: dhcpOption?.type ?? "text",
      value: dhcpOption?.value ?? "",
    },
  });

  const networkId = useCurrentNetworkId();
  const { mutate: updateVlan, isLoading } = useUpdateVlan();

  const update = useCallback(
    (newOptions: Vlan["dhcpOptions"]) => {
      updateVlan(
        {
          networkId,
          vlanId: vlan.id,
          vlan: { dhcpOptions: newOptions },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: useVlan.queryKey({ networkId, vlanId: vlan.id }),
            });
            navigation.goBack();
          },
          onError: (error) => showErrorAlert(String(error["errors"])),
        },
      );
    },
    [navigation, networkId, updateVlan, vlan],
  );

  const onSave = useCallback(
    ({ code, type, value }: DHCPOptionFormData) => {
      if (networkId) {
        const newDHCPOption = {
          code,
          type,
          value,
        };

        if (optionIndex == null) {
          dhcpOptions.push(newDHCPOption);
        } else {
          dhcpOptions[optionIndex] = newDHCPOption;
        }

        update(dhcpOptions);
      }
    },
    [dhcpOptions, networkId, optionIndex, update],
  );

  const onDelete = () => {
    if (networkId && optionIndex != null) {
      dhcpOptions.splice(optionIndex, 1);
    }

    update(dhcpOptions);
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSave)}
          disabled={!methods.formState.isDirty}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSave]);

  const optionBottomsheetRef = useRef<BottomSheetMethods>(null);
  const typeBottomsheetRef = useRef<BottomSheetMethods>(null);

  const option = methods.watch("option");
  const type = methods.watch("type");
  const isCustom = option === "CUSTOM";
  return (
    <Screen.View>
      <Box paddingHorizontal="xs" height={"95%"}>
        {isLoading && <Loader.Spinner />}
        <Form {...methods}>
          <Box gap="xs" flex={1}>
            <Box paddingHorizontal="xs" paddingTop="md">
              <Text>{I18n.t("DHCP_OPTION.DESCRIPTION")}</Text>
            </Box>
            <Box>
              <Box paddingBottom="2xs" paddingLeft="xs">
                <Text weight="semiBold">{I18n.t("DHCP_OPTION.INPUTS.OPTION")}</Text>
              </Box>
              <Pressable
                onPress={() => optionBottomsheetRef?.current?.present()}
                testID="DHCP_OPTION.OPTION"
              >
                <Box
                  padding="sm"
                  marginHorizontal="2xs"
                  backgroundColor="default.bg.weak.base"
                  borderColor="control.border.base"
                  borderRadius="sm"
                  borderWidth="strong"
                >
                  <Text>
                    {option ? I18n.t(`DHCP_OPTION.OPTIONS.${option}`) : I18n.t("NOT_ADDED")}
                  </Text>
                </Box>
              </Pressable>
              <Box paddingHorizontal="xs" paddingTop="2xs">
                <Text color="light">{I18n.t("DHCP_OPTION.INPUTS.OPTION_DESCRIPTION")}</Text>
              </Box>
            </Box>
            <Form.Input
              name="code"
              rules={{
                required: I18n.t("DHCP_OPTION.INPUTS.CODE_REQUIRED"),
                validate: (newCode) =>
                  isNaN(newCode) ? I18n.t("DHCP_OPTION.INPUTS.CODE_INVALID") : undefined,
              }}
              label={I18n.t("DHCP_OPTION.INPUTS.CODE")}
              placeholder={I18n.t("NOT_ADDED")}
              disabled={option !== "CUSTOM"}
              keyboardType="number-pad"
              testID="DHCP_OPTION.CODE"
            />
            <Box>
              <Box paddingBottom="2xs" paddingLeft="xs">
                <Text size="p3" weight="semiBold">
                  {I18n.t("DHCP_OPTION.INPUTS.TYPE")}
                </Text>
              </Box>
              <Pressable
                onPress={() => isCustom && typeBottomsheetRef?.current?.present()}
                testID="DHCP_OPTION.TYPE"
              >
                <Box
                  padding="sm"
                  marginHorizontal="2xs"
                  backgroundColor={`control.bg.weak.${isCustom ? "base" : "disabled"}`}
                  borderColor={`control.border.${isCustom ? "base" : "disabled"}`}
                  borderRadius="sm"
                  borderWidth="strong"
                >
                  <Text>
                    {type ? I18n.t(`DHCP_OPTION.TYPES.${type.toUpperCase()}`) : I18n.t("NOT_ADDED")}
                  </Text>
                </Box>
              </Pressable>
            </Box>
            <Form.Input
              name="value"
              rules={{
                required: I18n.t("DHCP_OPTION.INPUTS.VALUE_REQUIRED"),
                validate: (newValue) => {
                  let isValid = true;
                  switch (type) {
                    case "ip":
                      isValid = Address4.isValid(newValue);
                      break;
                    case "integer":
                      isValid = !isNaN(newValue);
                      break;
                    case "hex":
                      isValid = HEX_REGEX.test(newValue);
                      break;
                  }

                  return isValid ? undefined : I18n.t("DHCP_OPTION.INPUTS.VALUE_INVALID");
                },
              }}
              label={I18n.t("DHCP_OPTION.INPUTS.VALUE")}
              placeholder={I18n.t("NOT_ADDED")}
              keyboardType={type === "integer" || type === "ip" ? "numeric" : "default"}
              testID="DHCP_OPTION.VALUE"
            />
          </Box>
          <Form.Picker<Option>
            ref={optionBottomsheetRef}
            name="option"
            snapPoints={["CONTENT_HEIGHT"]}
            options={OPTIONS.map((value) => ({
              label: I18n.t(`DHCP_OPTION.OPTIONS.${value}`),
              value,
            }))}
            onSelectOption={({ value: newOption }) => {
              methods.setValue("code", CODE_BY_OPTION[newOption], { shouldValidate: true });
              methods.setValue("type", TYPE_BY_OPTION[newOption]);
            }}
          />
          <Form.Picker<Type>
            ref={typeBottomsheetRef}
            name="type"
            snapPoints={["CONTENT_HEIGHT"]}
            options={TYPES.slice(0, 3).map((value) => ({
              label: I18n.t(`DHCP_OPTION.TYPES.${value.toUpperCase()}`),
              value,
            }))}
          />
        </Form>
        {optionIndex !== undefined ? (
          <Button
            text={I18n.t("DELETE")}
            kind="secondaryDestructive"
            onPress={onDelete}
            disabled={isLoading}
            testID="DELETE_BUTTON"
          />
        ) : null}
      </Box>
    </Screen.View>
  );
}
