import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const CloseSupportCaseSchema = z
  .object({ success: z.boolean() })
  .describe("CloseCaseResponseSchema");

const buildUrl = ({ encryptedNetworkId }: CloseSupportCaseRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/update_case`;
};

interface CloseSupportCaseRequest {
  encryptedNetworkId?: string;
}

interface MutateCloseSupportCaseRequest extends CloseSupportCaseRequest {
  caseNumber: string;
  userClosed: boolean;
}

export type CloseSupportCase = z.infer<typeof CloseSupportCaseSchema>;

const closeSupportCase = ({
  encryptedNetworkId,
  caseNumber,
  userClosed,
}: MutateCloseSupportCaseRequest) => {
  return request(CloseSupportCaseSchema, "POST", buildUrl({ encryptedNetworkId }), {
    queryParams: { case_number: caseNumber, user_closed: userClosed },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCloseSupportCase = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: MutateCloseSupportCaseRequest) => closeSupportCase({ ...parameters }),
});
