import {
  ColorGreen4,
  ColorGreen5,
  ColorGreen8,
  ColorGreen9,
  ColorGreen10,
  ColorNeutral1,
  ColorNeutral5,
  ColorNeutral6,
  ColorNeutral9,
  ColorNeutral11,
  ColorNeutral15,
  ColorNeutral16,
  ColorRed4,
  ColorRed5,
  ColorRed8,
  ColorRed10,
  ColorYellow4,
  ColorYellow5,
  ColorYellow9,
  ColorYellow10,
} from "@magnetic/design-tokens";

import { PortState } from "../types";

export type PortColorSet = {
  boxBackground: string;
  boxBorder: string;
  iconBackground: string;
  portBackground: string;
  portBorder: string;
};

type StatusColorMap = Record<"idle" | "selected", PortColorSet>;

export const PortColorMap: Record<PortState, StatusColorMap> = {
  disabled: {
    idle: {
      boxBackground: ColorNeutral5,
      boxBorder: "transparent",
      portBackground: ColorNeutral6,
      portBorder: ColorNeutral11,
      iconBackground: ColorNeutral15,
    },
    selected: {
      boxBackground: ColorNeutral6,
      boxBorder: ColorNeutral11,
      portBackground: ColorNeutral9,
      portBorder: ColorNeutral11,
      iconBackground: ColorNeutral15,
    },
  },
  disconnected: {
    idle: {
      boxBackground: ColorNeutral5,
      boxBorder: "transparent",
      portBackground: ColorNeutral15,
      portBorder: ColorNeutral15,
      iconBackground: ColorNeutral1,
    },
    selected: {
      boxBackground: ColorNeutral6,
      boxBorder: ColorNeutral11,
      portBackground: ColorNeutral16,
      portBorder: ColorNeutral16,
      iconBackground: ColorNeutral1,
    },
  },
  fullSpeed: {
    idle: {
      boxBackground: ColorGreen4,
      boxBorder: "transparent",
      portBackground: ColorGreen9,
      portBorder: ColorGreen9,
      iconBackground: ColorNeutral1,
    },
    selected: {
      boxBackground: ColorGreen5,
      boxBorder: ColorGreen10,
      portBackground: ColorGreen10,
      portBorder: ColorGreen10,
      iconBackground: ColorNeutral1,
    },
  },
  reducedSpeed: {
    idle: {
      boxBackground: ColorGreen8,
      boxBorder: "transparent",
      portBackground: ColorGreen9,
      portBorder: ColorNeutral1,
      iconBackground: ColorNeutral1,
    },
    selected: {
      boxBackground: ColorGreen10,
      boxBorder: ColorGreen10,
      portBackground: ColorGreen10,
      portBorder: ColorNeutral1,
      iconBackground: ColorNeutral1,
    },
  },
  warning: {
    idle: {
      boxBackground: ColorYellow4,
      boxBorder: "transparent",
      portBackground: ColorYellow9,
      portBorder: ColorYellow9,
      iconBackground: ColorNeutral1,
    },
    selected: {
      boxBackground: ColorYellow5,
      boxBorder: ColorYellow10,
      portBackground: ColorYellow10,
      portBorder: ColorYellow10,
      iconBackground: ColorNeutral1,
    },
  },
  critical: {
    idle: {
      boxBackground: ColorRed4,
      boxBorder: "transparent",
      portBackground: ColorRed8,
      portBorder: ColorRed8,
      iconBackground: ColorNeutral1,
    },
    selected: {
      boxBackground: ColorRed5,
      boxBorder: ColorRed10,
      portBackground: ColorRed10,
      portBorder: ColorRed10,
      iconBackground: ColorNeutral1,
    },
  },
};
