import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { BottomSheet, Button, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { Share } from "react-native";
import QRCode from "react-native-qrcode-svg";

import { useSSIDContext } from "../screens/SSIDContext";

interface ShareBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export function ShareBottomSheet({ bottomSheetRef }: ShareBottomSheetProps) {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();

  const { ssid } = useSSIDContext();
  const [qrCodeData, setQrCodeData] = useState();

  if (!ssid) {
    return null;
  }

  const getSsidQRString = () => {
    const { name, encryptionMode, psk } = ssid;
    const components = [`WIFI:S:${name}`];

    if (encryptionMode) {
      components.push(`;T:${encryptionMode.toUpperCase()}`);
    }

    if (psk) {
      components.push(`;P:${psk}`);
    }

    components.push(";;");
    return components.join("");
  };

  const share = () => {
    const { name, psk } = ssid;
    const options = {
      name: name,
      passphrase: psk,
    };

    bottomSheetRef?.current?.dismiss();
    Share.share({
      message: psk
        ? I18n.t("SSID_SHARE.PASSWORD_MESSAGE", options)
        : I18n.t("SSID_SHARE.NO_PASSWORD_MESSAGE", options),
    }).finally(() => bottomSheetRef?.current?.present());
  };

  const showPDF = () => {
    bottomSheetRef?.current?.dismiss();
    navigation.navigate("GeneratePDF", {
      ssidName: ssid.name,
      ssidPsk: ssid.psk,
      QRString: String(qrCodeData),
      ssidNumber: ssid.number,
    });
  };

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("SSID_SHARE.SHARE_BUTTON")}
        resetLabel={I18n.t("DONE")}
        onResetPress={() => {
          bottomSheetRef?.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Box alignItems="center" paddingBottom="md">
          <Box paddingBottom="sm" testID="QR_CODE_CONTAINER">
            <Text size="p2" textAlign="center">
              {I18n.t("SSID_SHARE.SCAN_INSTRUCTIONS")}
            </Text>
          </Box>
          <QRCode
            value={getSsidQRString()}
            size={200}
            color="black"
            backgroundColor="white"
            quietZone={15}
            getRef={(c) => c?.toDataURL(setQrCodeData)}
          />
        </Box>
        <Box paddingBottom="sm">
          <Button
            text={I18n.t("SSID_SHARE.SHARE_BUTTON")}
            testID="DOWNLOAD_BUTTON"
            onPress={share}
          />
        </Box>
        <Button
          text={I18n.t("SSID_SHARE.SHARE_PDF_BUTTON")}
          testID="DOWNLOAD_PDF_BUTTON"
          kind="secondary"
          onPress={() => showPDF()}
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
