import { I18n } from "@meraki/core/i18n";
import { Button, Input, Notification, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { standardizeLoginResponse, useSubmit2FA } from "@meraki/shared/api";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useAppForeground } from "@meraki/shared/navigation";
import Clipboard from "@react-native-clipboard/clipboard";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Platform } from "react-native";

import { PostLoginProps } from "./Types";

export const TwoFactorAuth = ({ setLatestResponse }: PostLoginProps) => {
  const navigation = useNavigation();
  const [pinCode, setPinCode] = useState("");
  const [showPaste, setShowPaste] = useState(false);
  const [showNeedPastePermission, setShowNeedPastePermission] = useState(false);

  const submit2FA = useSubmit2FA();

  useEffect(() => {
    Clipboard.hasNumber()?.then((hasNumber) => setShowPaste(hasNumber));
  }, []);

  useAppForeground(async () => {
    const hasNumber = await Clipboard.hasNumber();
    setShowPaste(!!hasNumber);
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: I18n.t("TWO_FACTOR_AUTH.TFA_TITLE"),
    });
  });

  const handleSubmit2fa = () => {
    submit2FA.mutate(
      { code: pinCode },
      {
        onSuccess: (response) => {
          setLatestResponse(standardizeLoginResponse(response));
        },
        onError: (error) => {
          showErrorAlert(I18n.t("ERROR"), error as string);
        },
      },
    );
  };

  const otpProps = Platform.select({
    ios: { textContentType: "oneTimeCode" as const },
    android: { autoComplete: "sms-otp" as const },
  });

  return (
    <Box gap="md">
      {showNeedPastePermission && (
        <Notification.Inline
          status="warning"
          message={I18n.t("TWO_FACTOR_AUTH.PASTE_WARNING")}
          onDismiss={() => setShowNeedPastePermission(false)}
        />
      )}
      <Text size="p1">{I18n.t("TWO_FACTOR_AUTH.APP")}</Text>
      {showPaste && (
        <Button
          text={I18n.t("TWO_FACTOR_AUTH.PASTE_FROM_CLIPBOARD")}
          kind="tertiary"
          onPress={async () => {
            const text = await Clipboard.getString();
            /* 
              Clipboard.getString() prompts the user to see if they want to allow us to access their clipboard contents
              If they deny, `text` will be "", so we let them know via a notification that we need permissions if they 
              want to use the function of the button.
            */
            if (text === "") {
              setShowNeedPastePermission(true);
            } else {
              setShowNeedPastePermission(false);
              setPinCode(text);
            }
          }}
        />
      )}
      <Input
        label={I18n.t("TWO_FACTOR_AUTH.CODE")}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType="numeric"
        value={pinCode}
        onChangeText={setPinCode}
        disabled={submit2FA.isLoading}
        {...otpProps}
      />
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        onPress={handleSubmit2fa}
        loading={submit2FA.isLoading}
      />
    </Box>
  );
};
