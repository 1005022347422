import z from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const AFCPositioningSchema = z
  .object({
    name: z.string(),
    network: z.object({
      id: z.string(),
    }),
    serial: z.string(),
    elevation: z.object({
      height: z.number().nullable(),
      uncertainty: z.number(),
    }),
    gps: z.object({
      antenna: z.object({
        cableLength: z.number(),
      }),
    }),
  })
  .describe("AFCPositioningSchema");

export type AFCPositioning = z.infer<typeof AFCPositioningSchema>;

const UpdateAFCPositioningSchema = AFCPositioningSchema.deepPartial();
type AFCPositioningRequest = {
  serial: string;
};
type UpdateAFCPositioningRequest = {
  body: z.infer<typeof UpdateAFCPositioningSchema>;
} & AFCPositioningRequest;

function buildUrl({ serial }: AFCPositioningRequest) {
  return `/api/v1/devices/${serial}/wireless/radio/afc/positioning`;
}

const fetchAFCPositioning = ({ serial }: AFCPositioningRequest): Promise<AFCPositioning> =>
  request(AFCPositioningSchema, "GET", buildUrl({ serial }));

export const useAFCPositioning = createQuery<AFCPositioningRequest, AFCPositioning>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchAFCPositioning(variables),
});

const updateAFCPositioning = (variables: UpdateAFCPositioningRequest) =>
  request(AFCPositioningSchema, "PUT", buildUrl({ serial: variables.serial }), {
    body: JSON.stringify(variables.body),
  });

export const useUpdateAFCPositioning = createMutation({
  baseMutationKey: buildUrl({ serial: "{serial}" }),
  mutationFn: (parameters: UpdateAFCPositioningRequest) => updateAFCPositioning({ ...parameters }),
});
