import { I18n } from "@meraki/core/i18n";
import { TrafficShapingGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button, Heading, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  APIResponseError,
  queryClient,
  SwitchQoSRuleBody,
  useCreateSwitchQoSRule,
  useDeleteSwitchQoSRule,
  useSwitchQoSRules,
  useUpdateSwitchQoSRule,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";
import { Pressable } from "react-native";

import { DSCPTagPicker, DSCPTagPickerForm } from "../components/DSCPTagPicker";

interface RuleForm extends DSCPTagPickerForm {
  vlan: string | null;
  protocol: string;
  srcPortRange: string;
  dstPortRange: string;
}

const ANY = "ANY";
const PROTOCOL_OPTIONS = ["TCP", "UDP", ANY];
const PORT_RANGE_REGEX = /^\d*-?\d*$/;
const PORT_REGEX = /^\d*$/;

const getPortString = (portRange?: string | null, port?: number | null) => {
  if (portRange != null) {
    return portRange;
  } else if (port != null) {
    return port.toString();
  }

  return "";
};

const validatePortRange = (portRange: string) => {
  if (portRange.length > 0 && !PORT_RANGE_REGEX.test(portRange)) {
    return "invalid port or port range";
  }

  return;
};

export function SwitchQoSRuleScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<TrafficShapingGroupProps>>();
  const route = useRoute<RouteProp<TrafficShapingGroupProps, "SwitchQoSRule">>();
  const { params: props } = route;
  const rule = props?.rule;

  const networkId = useCurrentNetworkId();

  const createSwitchQoSRule = useCreateSwitchQoSRule();
  const updateSwitchQoSRule = useUpdateSwitchQoSRule();
  const deleteSwitchQosRule = useDeleteSwitchQoSRule();

  const methods = useForm<RuleForm>({
    values: {
      vlan: rule?.vlan == null ? null : String(rule?.vlan),
      protocol: rule?.protocol ?? "",
      dscpTag: (rule?.dscp === -1 ? null : rule?.dscp) ?? null,
      srcPortRange: getPortString(rule?.srcPortRange, rule?.srcPort),
      dstPortRange: getPortString(rule?.dstPortRange, rule?.dstPort),
    },
  });

  const onError = (error: APIResponseError) =>
    showErrorAlert(String(error["errors"] ?? I18n.t("SERVER_ERROR_TEXT")));
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: useSwitchQoSRules.queryKey({ networkId }) });
    navigation.goBack();
  }, [navigation, networkId]);

  const onSubmit = useCallback(
    ({ vlan, protocol, dscpTag, srcPortRange, dstPortRange }: RuleForm) => {
      const body: SwitchQoSRuleBody = {
        protocol,
        vlan: vlan ? Number(vlan) : null,
        dscp: dscpTag === null ? -1 : dscpTag,
      };

      if (protocol !== ANY) {
        if (PORT_REGEX.test(srcPortRange)) {
          body.srcPort = Number(srcPortRange);
        } else if (PORT_RANGE_REGEX.test(srcPortRange)) {
          body.srcPortRange = srcPortRange;
        }

        if (PORT_REGEX.test(dstPortRange)) {
          body.dstPort = Number(dstPortRange);
        } else if (PORT_RANGE_REGEX.test(dstPortRange)) {
          body.dstPortRange = dstPortRange;
        }
      }

      if (rule) {
        updateSwitchQoSRule.mutate({ networkId, ruleId: rule.id, body }, { onError, onSuccess });
      } else {
        createSwitchQoSRule.mutate({ networkId, body }, { onError, onSuccess });
      }
    },
    [createSwitchQoSRule, networkId, onSuccess, rule, updateSwitchQoSRule],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          disabled={!methods.formState.isDirty}
          onPress={methods.handleSubmit(onSubmit)}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSubmit]);

  const dscpTagBottomSheetRef = useRef<BottomSheetMethods>(null);
  const dscpTag = methods.watch("dscpTag");
  const protocol = methods.watch("protocol");

  const protocolTranslations: Record<string, string> = {
    ANY: I18n.t("PROTOCOL.ANY.LABEL"),
    TCP: I18n.t("PROTOCOL.TCP.LABEL"),
    UDP: I18n.t("PROTOCOL.UDP.LABEL"),
  };
  return (
    <Screen>
      <Form {...methods}>
        {(updateSwitchQoSRule.isLoading || createSwitchQoSRule.isLoading) && (
          <Loader.Spinner animate />
        )}
        <Box paddingHorizontal="sm" gap="xs">
          <Form.Input
            name="vlan"
            label={I18n.t("CONFIGURE.SWITCH_QOS.RULE.VLAN.LABEL")}
            placeholder={I18n.t("CONFIGURE.SWITCH_QOS.RULE.VLAN.PLACEHOLDER")}
            additionalContext={I18n.t("CONFIGURE.SWITCH_QOS.RULE.VLAN.HELPER_TEXT")}
            keyboardType="number-pad"
            testID="QOS_RULE.VLAN"
          />
          <Box>
            <Box paddingLeft="xs" paddingBottom="2xs">
              <Heading size="h4">{I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.LABEL")}</Heading>
            </Box>
            <Pressable
              testID="QOS_RULE.DSCP_TAG"
              onPress={() => dscpTagBottomSheetRef?.current?.present()}
            >
              <Box
                borderRadius="sm"
                borderColor="default.border.base"
                borderWidth="strong"
                padding="sm"
                backgroundColor="default.bg.weak.base"
              >
                <Text color="light">
                  {I18n.t(`CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.${dscpTag}`)}
                </Text>
              </Box>
            </Pressable>
            <Box paddingHorizontal="xs" paddingVertical="2xs">
              <Text color="light">
                {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DESCRIPTION_OPTIONAL")}
              </Text>
            </Box>
          </Box>
        </Box>
        <Form.FlashList
          name="protocol"
          rules={{ required: I18n.t("CONFIGURE.SWITCH_QOS.RULE.PROTOCOL.REQUIRED") }}
          label={I18n.t("CONFIGURE.SWITCH_QOS.RULE.PROTOCOL.LABEL")}
          data={PROTOCOL_OPTIONS}
          getItemData={(protocol) => ({
            kind: "radio",
            title: protocolTranslations[protocol],
            description:
              protocol === ANY
                ? I18n.t("CONFIGURE.SWITCH_QOS.RULE.PROTOCOL.ANY.DESCRIPTION")
                : undefined,
            radioValue: protocol,
            testID: `QOS_RULE.PROTOCOL.${protocol}`,
          })}
          paddingTop="none"
          paddingBottom="none"
        />
        <Box paddingHorizontal="sm" gap="xs">
          <Form.Input
            name="srcPortRange"
            rules={{ validate: validatePortRange }}
            label={I18n.t("CONFIGURE.SWITCH_QOS.RULE.PORTS.SOURCE")}
            placeholder={I18n.t("CONFIGURE.SWITCH_QOS.RULE.PORTS.PLACEHOLDER")}
            keyboardType="numbers-and-punctuation"
            disabled={protocol === "ANY"}
            testID="QOS_RULE.SOURCE_PORT_RANGE"
          />
          <Form.Input
            name="dstPortRange"
            rules={{ validate: validatePortRange }}
            label={I18n.t("CONFIGURE.SWITCH_QOS.RULE.PORTS.DESTINIATION")}
            placeholder={I18n.t("CONFIGURE.SWITCH_QOS.RULE.PORTS.PLACEHOLDER")}
            keyboardType="numbers-and-punctuation"
            disabled={protocol === "ANY"}
            testID="QOS_RULE.DESTINIATION_PORT_RANGE"
          />
        </Box>
        <DSCPTagPicker ref={dscpTagBottomSheetRef} />
        {rule != null && (
          <Box marginTop="xl" padding="sm">
            <Button
              kind="primaryDestructive"
              text={I18n.t("DELETE")}
              onPress={() =>
                deleteSwitchQosRule.mutate({ networkId, ruleId: rule?.id }, { onError, onSuccess })
              }
              loading={deleteSwitchQosRule.isLoading}
              testID="QOS_RULE.DELETE"
            />
          </Box>
        )}
      </Form>
    </Screen>
  );
}
