import { I18n } from "@meraki/core/i18n";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import { Button, Checkbox, EmptyState, List, SearchBar, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  AlertClient,
  queryClient,
  useAlertSettings,
  useClients,
  useSsids,
  useUpdateAlertSettings,
} from "@meraki/shared/api";
import { filterData } from "@meraki/shared/filters";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { Alert } from "react-native";

type Props = RouteProp<AlertsStackProps, "TrackedClients">;

type TrackedClient = {
  mac: string;
  name: string;
  status: string;
  ssidName: string;
};

export function TrackedClientsListScreen() {
  const timespan = useGlobalTimespan();
  const navigation = useNavigation<NativeStackNavigationProp<AlertsStackProps>>();
  const route = useRoute<Props>();
  const { params: props } = route;

  const currentNetworkId = useCurrentNetworkId();
  const networkId = props?.networkId ?? currentNetworkId;

  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [clientsToUntrack, setClientsToUntrack] = useState<Set<string>>(new Set([]));

  const trackedClients: TrackedClient[] = [];

  const { data: ssids } = useSsids({ networkId });

  const { data: rawClients } = useClients({ networkId, timespan });

  const { data: alertSettings, isLoading } = useAlertSettings(
    { networkId },
    {
      select: (data) => {
        const connectivityAlert = data.alerts.find((alert) => alert.type === "clientConnectivity");
        connectivityAlert?.filters?.clients?.forEach((trackedClient) => {
          const thisClient = rawClients?.find((client) => client.mac === trackedClient.mac);
          const thisSsid = ssids?.find((ssid) => ssid.number === thisClient?.connectedBy);
          trackedClients.push({
            mac: trackedClient.mac,
            name: trackedClient.name,
            status: thisClient?.status ?? "Offline",
            ssidName: thisSsid?.name ?? "",
          });
        });
        return connectivityAlert;
      },
    },
  );

  const { mutate: updateAlertSettings } = useUpdateAlertSettings();

  const searchedTrackedClients = filterData(
    trackedClients || [],
    ["name"],
    searchText,
  ) as TrackedClient[];

  const saveSettings = () => {
    if (!alertSettings) {
      Alert.alert(I18n.t("TRACK_CLIENT.EMPTY"));
      return;
    }
    const newClients: AlertClient[] = [];
    trackedClients.forEach((client) => {
      if (!clientsToUntrack.has(client.mac)) {
        newClients.push({ mac: client.mac, name: client.name });
      }
    });

    const newAlertSettings = {
      alerts: [
        {
          filters: {
            clients: newClients,
          },
          enabled: alertSettings.enabled,
          type: alertSettings.type,
          alertDestinations: alertSettings.alertDestinations,
        },
      ],
    };

    updateAlertSettings(
      { networkId, alertSettings: newAlertSettings },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          setClientsToUntrack(new Set([]));
          setIsEdit(!isEdit);
        },
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useAlertSettings.queryKeyRoot });
        },
      },
    );
  };

  const onUntrackPress = () => {
    if (!isEdit) {
      setIsEdit(!isEdit);
    } else {
      showDeleteConfirmAlert(
        I18n.t("TRACK_CLIENT.CONFIRMATION_TITLE"),
        I18n.t("TRACK_CLIENT.CONFIRMATION_SUBTITLE"),
        saveSettings,
      );
    }
  };

  useEffect(() =>
    navigation.setOptions({
      headerLeft: () => (
        <Button.Nav
          text={isEdit ? I18n.t("CANCEL") : I18n.t("DONE")}
          onPress={!isEdit ? () => navigation.goBack() : () => setIsEdit(!isEdit)}
        />
      ),
      headerRight: () => (
        <Button.Nav
          text={isEdit ? I18n.t("TRACK_CLIENT.DONT_TRACK") : I18n.t("EDIT")}
          onPress={onUntrackPress}
          disabled={(isEdit && clientsToUntrack.size < 1) || trackedClients.length < 1}
          testID={isEdit ? "DONT_TRACK_BUTTON" : "EDIT_BUTTON"}
        />
      ),
    }),
  );

  const updateClientsToUntrack = (mac: string) => {
    const currentClients = new Set(clientsToUntrack);
    if (mac === "selectAll") {
      if (currentClients.size === searchedTrackedClients.length) {
        setClientsToUntrack(new Set([]));
      } else {
        const allClients: string[] = [];
        searchedTrackedClients.forEach((client) => allClients.push(client.mac));
        setClientsToUntrack(new Set(allClients));
      }
    } else {
      if (currentClients.has(mac)) {
        currentClients.delete(mac);
        setClientsToUntrack(currentClients);
      } else {
        setClientsToUntrack(currentClients.add(mac));
      }
    }
  };

  if (!alertSettings) {
    <EmptyState title={I18n.t("TRACK_CLIENT.EMPTY")} />;
  }
  return (
    <Screen.View>
      <Box paddingHorizontal="sm" paddingTop="sm">
        <SearchBar
          placeholder={I18n.t("TRACK_CLIENT.SEARCH_PLACEHOLDER")}
          value={searchText}
          onChangeText={setSearchText}
          testID="TRACKED_CLIENT_SEARCH"
        />
      </Box>
      <Box paddingHorizontal="md">
        <Text size="p1" testID={`HELP_TEXT.${isEdit ? "UNTRACK" : "EDIT"}`}>
          {isEdit
            ? I18n.t("TRACK_CLIENT.DONT_TRACK_HELP_TEXT")
            : I18n.t("TRACK_CLIENT.EDIT_HELP_TEXT")}
        </Text>
      </Box>
      {isEdit && searchedTrackedClients.length > 0 && (
        <Box flexDirection="row" paddingLeft="lg">
          <Checkbox
            checked={clientsToUntrack.size === searchedTrackedClients.length}
            onValueChange={() => updateClientsToUntrack("selectAll")}
            testID="SELECT_ALL_CHECKBOX"
          />
          <Box paddingLeft="xs">
            <Text size="p1">{I18n.t("TRACK_CLIENT.SELECT_ALL")}</Text>
          </Box>
        </Box>
      )}
      <List.FlashList
        data={searchedTrackedClients}
        paddingTop="none"
        label={
          !isEdit
            ? I18n.t("TRACK_CLIENT.LIST_LABEL", { numOfClients: searchedTrackedClients.length })
            : undefined
        }
        loading={isLoading}
        getItemData={(data) => {
          return {
            title: data.name,
            description: I18n.t("TRACK_CLIENT.LAST_CONNECTED", { ssidName: data.ssidName }),
            leftAccessory: isEdit ? (
              <Checkbox
                checked={clientsToUntrack.has(data.mac)}
                onValueChange={() => updateClientsToUntrack(data.mac)}
              />
            ) : (
              <Status size={20} status={data.status === "Online" ? "positive" : "negative"} />
            ),
            testID: `TRACKED_CLIENT_LIST_ITEM.${data.mac}`,
          };
        }}
        emptyState={{
          title:
            trackedClients.length > 0
              ? I18n.t("TRACK_CLIENT.EMPTY_SEARCH")
              : I18n.t("TRACK_CLIENT.EMPTY"),
        }}
        testID="TRACKED_CLIENT_LIST"
      />
    </Screen.View>
  );
}
