import z from "zod";

export const LoginSecuritySchema = z
  .object({
    accountLockoutAttempts: z.number().optional().nullable(),
    idleTimeoutMinutes: z.number().optional().nullable(),
    numDifferentPasswords: z.number(),
    passwordExpirationDays: z.number().optional().nullable(),
    enforceAccountLockout: z.boolean(),
    enforceDifferentPasswords: z.boolean(),
    enforceIdleTimeout: z.boolean(),
    enforceLoginIpRanges: z.boolean(),
    enforcePasswordExpiration: z.boolean(),
    enforceStrongPasswords: z.boolean(),
    enforceTwoFactorAuth: z.boolean(),
    apiAuthentication: z.object({
      ipRestrictionsForKeys: z.object({
        enabled: z.boolean(),
        ranges: z.array(z.string()),
      }),
    }),
    loginIpRanges: z.array(z.string()),
  })
  .describe("LoginSecuritySchema");

export const NewLoginSecuritySchema = LoginSecuritySchema.partial();

export type LoginSecurity = z.infer<typeof LoginSecuritySchema>;
export type NewLoginSecurity = z.infer<typeof NewLoginSecuritySchema>;
