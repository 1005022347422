// NOTE: Turning this off for now as this is needing a lot of improvement to get TS working right. We will revisit.
/* eslint-disable @typescript-eslint/ban-ts-comment */

import "web/font.css";

import * as ReactNative from "react-native";
// @ts-ignore
import FontAwesomeIconFont from "react-native-vector-icons/Fonts/FontAwesome.ttf";
// @ts-ignore
import Ionicons from "react-native-vector-icons/Fonts/Ionicons.ttf";
// @ts-ignore
import MaterialCommunityIcons from "react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf";
// @ts-ignore
import MaterialIconsIconFont from "react-native-vector-icons/Fonts/MaterialIcons.ttf";

// Need to stub these prop types since react-native-web does not expose them
// TODO: These should be fixed by either adding them to react-native-web or
// removing them from our app.
// @ts-ignore
ReactNative.Image.propTypes = {
  source: {},
};
// @ts-ignore
ReactNative.View.propTypes = {};
// @ts-ignore
ReactNative.Text.propTypes = {};

// Resolves images to base64
// @ts-ignore
ReactNative.Image.resolveAssetSource = (data) => {
  return data;
};

// @ts-ignore
ReactNative.TextInput.propTypes = {};

// @ts-ignore
ReactNative.NativeModules.RNPermissions = {};
// @ts-ignore
ReactNative.NativeModules.DialogAndroid = {
  defaults: {},
  dismiss: () => undefined,
  show: () => undefined,
};

// This logic loads in icon stylesheets

const iconSets = [
  [MaterialIconsIconFont, "MaterialIcons"],
  [FontAwesomeIconFont, "FontAwesome"],
  [Ionicons, "Ionicons"],
  [MaterialCommunityIcons, "MaterialCommunityIcons"],
];

iconSets.forEach((set) => {
  const iconFontStyles = `@font-face {
    src: url(${set[0]});
    font-family: ${set[1]};
  }`;

  const style = document.createElement("style");
  style.type = "text/css";
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  document.head.appendChild(style);
});

/* eslint-enable @typescript-eslint/ban-ts-comment */
