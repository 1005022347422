import { I18n } from "@meraki/core/i18n";
import { DeviceIllustration } from "@meraki/go/device-illustration";
import { BottomSheet, BottomSheetMethods, Button, Loader, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useClients, useReboot } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import { Alert } from "react-native";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

interface RebootBottomSheetProps {
  serial: string;
  model: string;
}

export const RebootBottomSheet = forwardRef(function RebootBottomSheet(
  { serial, model }: RebootBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const networkId = useCurrentNetworkId();

  const { mutate, isLoading } = useReboot();
  const { data: clients, isLoading: clientsLoading } = useClients({ networkId });

  const [rebootInitiated, setRebootInitiated] = useState<boolean>(false);
  const opacity = useSharedValue(1);

  const rebootDevice = useCallback(() => {
    setRebootInitiated(true);
    mutate(
      { serial },
      {
        onError() {
          setRebootInitiated(false);
          Alert.alert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        },
        onSuccess() {
          opacity.value = withRepeat(
            withTiming(0, { duration: 1000, easing: Easing.ease }),
            30,
            true,
            (finished?: boolean) => {
              if (finished) {
                opacity.value = 1;
              }
            },
          );
        },
      },
    );
  }, [mutate, opacity, serial]);

  const onDismiss = () => {
    setRebootInitiated(false);
  };

  const showText = () => {
    const online = clients?.filter((c) => c.recentDeviceSerial === serial) ?? [];

    return I18n.t("LIVE_TOOLS.CONFIRM_REBOOT", {
      count: online.length,
    });
  };

  const animatedStyles = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);

  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref} onDismiss={onDismiss}>
      <BottomSheet.Header
        title={I18n.t("LIVE_TOOLS.REBOOT")}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => {
          if (typeof ref !== "function") {
            ref?.current?.dismiss();
            onDismiss();
          }
        }}
      />
      <BottomSheet.Content>
        <Box gap="sm" alignItems="center">
          <Animated.View style={animatedStyles}>
            <DeviceIllustration model={model} size={"medium"} />
          </Animated.View>
          {clientsLoading ? (
            <Loader.Spinner />
          ) : (
            <Text>{rebootInitiated ? I18n.t("LIVE_TOOLS.REBOOT_INITIATED") : showText()}</Text>
          )}

          <Button
            text={rebootInitiated ? I18n.t("CLOSE") : I18n.t("LIVE_TOOLS.CONFIRM")}
            loading={isLoading}
            disabled={isLoading}
            kind="secondary"
            onPress={() => {
              if (rebootInitiated) {
                if (typeof ref !== "function") {
                  ref?.current?.dismiss();
                  onDismiss();
                }
              } else {
                rebootDevice();
              }
            }}
            testID="REBOOT_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
});
