import { PureComponent, ReactNode } from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { themeColors } from "~/lib/themeHelper";
import { isNumber } from "~/lib/TypeHelper";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiText from "~/shared/components/MkiText";
import SkeletonLine from "~/shared/components/SkeletonLine";
import Table from "~/shared/components/Table";
import { useTheme } from "~/shared/hooks/useTheme";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

export interface SummaryCardProps {
  children: ReactNode;
  disclosureRows?: SummaryCardDisclosureRow[];
  hideBottomBorder?: boolean;
  onPress?: () => void;
  testID?: string;
  title?: string;
  disableBodyPadding?: boolean;
  loading?: boolean;
  skeletonHeight?: number;
  numberOfLoaders?: number;
}

export interface SummaryCardDisclosureRow {
  label: string;
  onPress: () => void;
  testID?: string;
}

const SummaryDisclosureRow = (rowData: SummaryCardDisclosureRow) => {
  const { onPress, testID, label } = rowData;
  const color = themeColors(useTheme()).navigation.primary;

  return (
    <SimpleDisclosureRow onPress={onPress} style={{ color }} testID={testID}>
      {label.toUpperCase()}
    </SimpleDisclosureRow>
  );
};

const SummaryBottomBorder = ({ hideBottomBorder }: { hideBottomBorder?: boolean }) => {
  if (hideBottomBorder) {
    return null;
  }

  return (
    <View style={styles.bottomBorder}>
      <MkiRowSeparator />
    </View>
  );
};

const DEFAULT_PLACEHOLDER_HEIGHT = 65;

class SummaryCard extends PureComponent<SummaryCardProps> {
  renderLoading = () => {
    const { skeletonHeight, numberOfLoaders } = this.props;

    const height = isNumber(skeletonHeight) ? skeletonHeight : DEFAULT_PLACEHOLDER_HEIGHT;
    const totalLoaders = isNumber(numberOfLoaders) ? numberOfLoaders : 1;

    const lines: React.ReactElement[] = [];
    for (let i = 0; i < totalLoaders; i++) {
      lines.push(<SkeletonLine key={`skeletonLine-${i}`} height={height} withMarginBottom />);
    }

    return <>{lines}</>;
  };

  renderTitle = () => {
    const { title } = this.props;

    return title ? (
      <View style={styles.titleContainer}>
        <MkiText textStyle="subheader" screenStyles={styles.titleText}>
          {title}
        </MkiText>
      </View>
    ) : null;
  };

  renderBody = () => {
    const { children } = this.props;
    return <View style={styles.body}>{children}</View>;
  };

  renderRow = (rowData: SummaryCardDisclosureRow) => <SummaryDisclosureRow {...rowData} />;

  renderDisclosureRows = () => {
    const { disclosureRows } = this.props;
    if (!disclosureRows?.length) {
      return null;
    }

    return (
      <Table
        entries={disclosureRows}
        renderRow={this.renderRow}
        separators={false}
        topPadding={false}
      />
    );
  };

  renderLoadingContainer = () => {
    const { loading, disableBodyPadding } = this.props;
    let renderChild = this.renderBody;

    if (loading) {
      renderChild = this.renderLoading;
    }

    const enablePadding = !disableBodyPadding || loading;

    return (
      <>
        <View style={enablePadding ? styles.bodyContainerWithPadding : undefined}>
          {renderChild()}
        </View>
        {this.renderDisclosureRows()}
      </>
    );
  };

  renderContainer = () => {
    const { testID, hideBottomBorder } = this.props;

    return (
      <View style={styles.container} testID={testID}>
        {this.renderTitle()}
        {this.renderLoadingContainer()}
        <SummaryBottomBorder hideBottomBorder={hideBottomBorder} />
      </View>
    );
  };

  render() {
    const { onPress, loading } = this.props;

    return (
      <TouchableWithoutFeedback onPress={loading ? undefined : onPress}>
        {this.renderContainer()}
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    marginBottom: SPACING.default,
  },
  bottomBorder: {
    marginHorizontal: SPACING.default,
  },
  container: {
    flex: 1,
    paddingTop: SPACING.default,
  },
  bodyContainerWithPadding: {
    paddingHorizontal: SPACING.default,
  },
  titleContainer: {
    paddingHorizontal: SPACING.default,
    marginBottom: SPACING.small,
  },
  titleText: {
    paddingVertical: SPACING.small,
  },
});

export default SummaryCard;
