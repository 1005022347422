import z from "zod";

export const BookmarkSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  serial: z.string(),
  exportJobId: z.string().nullable(),
  networkId: z.string(),
  description: z.string().nullable(),
  startTs: z.string(),
  endTs: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const Meta = z.object({
  counts: z.object({
    items: z.object({
      total: z.number(),
      remaining: z.number(),
    }),
  }),
});

export const BookmarksSchema = z
  .object({
    items: z.array(BookmarkSchema),
    meta: Meta,
  })
  .describe("BookmarkSchema");

export type Bookmark = z.infer<typeof BookmarkSchema>;
export type Bookmarks = z.infer<typeof BookmarksSchema>;

// TODO: implement useBookmarks once controller is ready
