import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { evaluateEnabled } from "./evaluateEnabled";
import { getQueryKeyAsArray } from "./util";

type CreateQueryOptions<
  TVariables,
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "queryKey" | "queryFn" | "select" | "onError" | "onSettled" | "onSuccess"
> & {
  baseQueryKey: string | string[];
  queryFn: (
    variables: TVariables,
    context: QueryFunctionContext<TQueryKey>,
  ) => TQueryFnData | Promise<TQueryFnData>;
  requiredVariables?: ReadonlyArray<keyof TVariables>;
};

type EnhancedQueryOptions<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Pick<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "select" | "enabled" | "refetchInterval" | "refetchIntervalInBackground" | "refetchOnMount"
> & {
  meta?: {
    alertTitle?: string;
    alertMessage?: string;
  };
};

export function buildQueryKey<TVariables, TQueryKey>(
  baseQueryKey: string | string[],
  variables: TVariables,
): TQueryKey {
  const baseQueryKeyAsArray = getQueryKeyAsArray(baseQueryKey);
  return [...baseQueryKeyAsArray, variables] as TQueryKey;
}

export function buildQueryKeyFetcher<TVariables, TQueryKey>(baseQueryKey: string | string[]) {
  return (variables: TVariables) => buildQueryKey<TVariables, TQueryKey>(baseQueryKey, variables);
}

export function createQuery<
  TVariables = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>({
  baseQueryKey,
  queryFn,
  requiredVariables,
  ...options
}: CreateQueryOptions<TVariables, TQueryFnData, TError, unknown, TQueryKey>) {
  const useEnhancedQuery = <TData = TQueryFnData>(
    variables: TVariables,
    enhancedOptions?: EnhancedQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  ) => {
    const enabled = evaluateEnabled({
      variables,
      requiredVariables,
      enabled: enhancedOptions?.enabled ?? options.enabled,
    });

    return useQuery<TQueryFnData, TError, TData, TQueryKey>({
      ...options,
      ...(enhancedOptions ?? {}),
      enabled,
      queryKey: buildQueryKey(baseQueryKey, variables),
      queryFn: (context) => queryFn(variables, context),
    });
  };

  useEnhancedQuery.queryKeyRoot = getQueryKeyAsArray(baseQueryKey);
  useEnhancedQuery.queryKey = buildQueryKeyFetcher<TVariables, TQueryKey>(baseQueryKey);
  useEnhancedQuery.queryFn =
    (variables: TVariables) => (context: QueryFunctionContext<TQueryKey, TQueryFnData>) =>
      queryFn(variables, context);
  useEnhancedQuery.useQueries = <TData = TQueryFnData>(
    variables: TVariables,
    options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  ): UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> => ({
    queryKey: useEnhancedQuery.queryKey(variables),
    queryFn: useEnhancedQuery.queryFn(variables),
    ...(options ?? {}),
  });

  return useEnhancedQuery;
}
