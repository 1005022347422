import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function Forward10Icon(props: Props) {
  const { size = BUTTON_SIZING.width.default } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M22.6363 3.89196L21.1256 5.07321C19.0658 2.44018 15.8622 0.75 12.2649 0.75C6.0533 0.75 1.02562 5.77232 1.01759 11.9866C1.00955 18.2063 6.04795 23.25 12.2649 23.25C17.1212 23.25 21.2596 20.1696 22.8346 15.8545C22.8747 15.742 22.8158 15.6161 22.7033 15.5786L21.1846 15.0562C21.1316 15.0381 21.0736 15.0414 21.0231 15.0654C20.9725 15.0894 20.9334 15.1323 20.914 15.1848C20.8658 15.3188 20.8122 15.4527 20.756 15.5839C20.2926 16.6821 19.6283 17.6679 18.7819 18.5143C17.9423 19.3555 16.9482 20.0267 15.8542 20.4911C14.7212 20.9705 13.5131 21.2143 12.2703 21.2143C11.0247 21.2143 9.81937 20.9705 8.68634 20.4911C7.59127 20.0286 6.59684 19.3572 5.75866 18.5143C4.91679 17.6748 4.24629 16.6795 3.78455 15.5839C3.30509 14.4482 3.06134 13.2429 3.06134 11.9973C3.06134 10.7518 3.30509 9.54643 3.78455 8.41071C4.24794 7.3125 4.91223 6.32679 5.75866 5.48036C6.60509 4.63393 7.5908 3.96964 8.68634 3.50357C9.81937 3.02411 11.0274 2.78036 12.2703 2.78036C13.5158 2.78036 14.7212 3.02411 15.8542 3.50357C16.9493 3.96601 17.9437 4.63746 18.7819 5.48036C19.0471 5.74554 19.2962 6.02679 19.5265 6.32143L17.914 7.58036C17.8821 7.60504 17.8578 7.63822 17.8439 7.6761C17.8301 7.71397 17.8271 7.75499 17.8355 7.79444C17.8439 7.8339 17.8633 7.87018 17.8914 7.89912C17.9195 7.92806 17.9552 7.94848 17.9944 7.95804L22.6979 9.10982C22.8319 9.14196 22.9631 9.04018 22.9631 8.90357L22.9846 4.05804C22.9819 3.88125 22.7756 3.78214 22.6363 3.89196Z"
        fill="white"
      />
      <Path
        d="M10.1566 15.6875H8.39616V10.0833L6.66699 10.5885V9.25521L9.99512 8.10417H10.1566V15.6875Z"
        fill="white"
      />
      <Path
        d="M17.1753 12.5885C17.1753 13.6128 16.9444 14.4028 16.4826 14.9583C16.0243 15.5139 15.3767 15.7917 14.5399 15.7917C13.6962 15.7917 13.0434 15.5122 12.5816 14.9531C12.1198 14.3941 11.8889 13.6059 11.8889 12.5885V11.2031C11.8889 10.1788 12.118 9.38889 12.5764 8.83333C13.0382 8.27778 13.6892 8 14.5295 8C15.3698 8 16.0208 8.27951 16.4826 8.83854C16.9444 9.39757 17.1753 10.1875 17.1753 11.2083V12.5885ZM15.4201 10.9792C15.4201 10.434 15.3489 10.0278 15.2066 9.76042C15.0642 9.48958 14.8385 9.35417 14.5295 9.35417C14.2274 9.35417 14.0069 9.47917 13.868 9.72917C13.7326 9.97917 13.6597 10.3576 13.6493 10.8646V12.8021C13.6493 13.3681 13.7205 13.783 13.8628 14.0469C14.0052 14.3073 14.2309 14.4375 14.5399 14.4375C14.8385 14.4375 15.059 14.3108 15.2014 14.0573C15.3437 13.8003 15.4166 13.3993 15.4201 12.8542V10.9792Z"
        fill="white"
      />
    </Svg>
  );
}

export default Forward10Icon;
