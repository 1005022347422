import {
  CLEAR_PAGINATION,
  GET_NEXT_PAGE_SUCCESS,
  GET_PREV_PAGE_SUCCESS,
  GET_UNUSED_INVENTORY_SUCCESS,
  SEARCH_INVENTORY_SUCCESS,
  SENSOR_EVENTS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

interface PaginationState {
  [listId: string]: {
    currentPage: number;
    data: any[];
    nextPage: string | null;
    prevPage: string | null;
    isLastPage: boolean;
    isFirstPage: boolean;
    refreshId: number;
  };
}

export const initialState = {};

export default function pagination(state: PaginationState = initialState, action: any) {
  const listId = action?.meta?.listId;
  const nextPage = action?.linkHeader?.next;
  const prevPage = action?.linkHeader?.prev;

  switch (action.type) {
    case SENSOR_EVENTS_SUCCESS:
    case GET_UNUSED_INVENTORY_SUCCESS:
    case SEARCH_INVENTORY_SUCCESS: {
      let refreshId = state?.[listId]?.refreshId || 0;
      refreshId += 1;

      return {
        ...state,
        [listId]: {
          data: action.response,
          nextPage,
          prevPage,
          isFirstPage: checkIsFirstPage(prevPage),
          isLastPage: checkIsLastPage(nextPage),
          refreshId,
        },
      };
    }
    case GET_PREV_PAGE_SUCCESS:
    case GET_NEXT_PAGE_SUCCESS: {
      const refreshId = action?.meta?.refreshId;
      const currentRefreshId = state?.[listId]?.refreshId;

      if (refreshId !== currentRefreshId) {
        return state;
      }

      const oldData = state?.[listId]?.data;
      const newData = action?.response;
      if (!oldData || !newData) {
        return state;
      }

      return {
        ...state,
        [listId]: {
          ...state?.[listId],
          data: [...oldData, ...newData],
          nextPage,
          prevPage,
          isFirstPage: checkIsFirstPage(prevPage),
          isLastPage: checkIsLastPage(nextPage),
        },
      };
    }
    case CLEAR_PAGINATION: {
      const newState = { ...state };
      delete newState[listId];

      return newState;
    }
    case WIPE_REDUX: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}

function checkIsLastPage(nextPage: string | undefined) {
  return nextPage == null;
}

function checkIsFirstPage(firstPage: string | undefined) {
  return firstPage == null;
}
