import { I18n } from "@meraki/core/i18n";
import { Box } from "@meraki/magnetic/layout";
import { Form } from "@meraki/shared/form";
import { useFormContext } from "react-hook-form";

import { FIVE_GHZ_CHANNELS, TWO_GHZ_CHANNELS, WirelessBandTypes } from "../../utils/RadioUtils";

interface ChannelCardProps {
  band: WirelessBandTypes;
}

export const ChannelCard = ({ band }: ChannelCardProps) => {
  const methods = useFormContext();
  const auto = I18n.t("RADIO_SETTINGS.GOv3.AUTO");

  const baseChannelOptions = band === "twoFourGhzSettings" ? TWO_GHZ_CHANNELS : FIVE_GHZ_CHANNELS;

  const channelOptions = [{ label: auto, value: null }, ...baseChannelOptions];

  return (
    <Box flex={1} testID="CHANNEL_CARD">
      <Form.FlashList
        name={`${band}.channel`}
        paddingLeft="none"
        paddingRight="none"
        paddingBottom="none"
        label={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL.TITLE")}
        data={channelOptions}
        getItemData={(channel) => ({
          kind: "radio",
          title: channel.label,
          radioValue: channel.value,
          testID: `CHANNEL_ROW.${channel.value}${
            methods.getValues(`${band}.channel`) === channel.value ? ".SELECTED" : ""
          }`,
        })}
        testID="CHANNEL_LIST"
      />
    </Box>
  );
};
