import { Informational } from "./icons/Informational";
import { InformationalBordered } from "./icons/InformationalBordered";
import { Negative } from "./icons/Negative";
import { NegativeBordered } from "./icons/NegativeBordered";
import { Positive } from "./icons/Positive";
import { PositiveBordered } from "./icons/PositiveBordered";
import { Warning } from "./icons/Warning";
import { WarningBordered } from "./icons/WarningBordered";

const ICON_MAP = {
  negative: { base: Negative, bordered: NegativeBordered },
  positive: { base: Positive, bordered: PositiveBordered },
  warning: { base: Warning, bordered: WarningBordered },
  informational: { base: Informational, bordered: InformationalBordered },
};

export interface AlertProps {
  status: keyof typeof ICON_MAP;
  size?: number;
  bordered?: boolean;
  testID?: string;
}

export function Alert({ status, size, bordered, testID }: AlertProps) {
  const Icon = ICON_MAP[status][bordered ? "bordered" : "base"];

  if (!Icon) return null;

  return <Icon width={size ?? 24} height={size ?? 24} testID={testID} />;
}
