import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function SystemsManager({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <G clip-path="url(#clip0_10_4527)">
        <Path
          d="M22.5 18V9.375C22.5 8.54657 21.8284 7.875 21 7.875H15.375C14.5466 7.875 13.875 8.54657 13.875 9.375V18C13.875 18.8284 14.5466 19.5 15.375 19.5H21C21.8284 19.5 22.5 18.8284 22.5 18Z"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10.5 19.5H8.25"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M13.875 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V6C2.25 5.60218 2.40804 5.22064 2.68934 4.93934C2.97064 4.65804 3.35218 4.5 3.75 4.5H17.25C17.6478 4.5 18.0294 4.65804 18.3107 4.93934C18.592 5.22064 18.75 5.60218 18.75 6V7.875"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M17.625 11.625H18.75"
          stroke={color1}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_10_4527">
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
