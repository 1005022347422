import { I18n } from "@meraki/core/i18n";

import MkiColors from "~/constants/MkiColors";
import { OnboardingFooterStatus, OnboardingNodeStatus } from "~/shared/types/OnboardingTypes";

export const CLAIM_SUCCESS_MESSAGE = "success";

export const defaultOnboardingStatusCounts = {
  [OnboardingNodeStatus.finished]: 0,
  [OnboardingNodeStatus.connecting]: 0,
  [OnboardingNodeStatus.upgrading]: 0,
  [OnboardingNodeStatus.unstarted]: 0,
};

export const getOnboardingFooterStatuses = () => ({
  [OnboardingFooterStatus.hardwareConnectFailed]: {
    gradientStartColor: MkiColors.onboardingStatusBarFailed,
    gradientEndColor: MkiColors.onboardingStatusBarFailed,
    text: I18n.t("ONBOARDING_FULLSTACK.FOOTER_STATUS.HARDWARE_CONNECT_FAILED"),
    textColor: MkiColors.onboardingFooterStatusTextError,
  },
  [OnboardingFooterStatus.hardwareUnstarted]: {
    gradientStartColor: MkiColors.onboardingStatusBarGradientStart,
    gradientEndColor: MkiColors.onboardingStatusBarGradientEnd,
    text: I18n.t("ONBOARDING_FULLSTACK.FOOTER_STATUS.HARDWARE_UNSTARTED"),
    textColor: MkiColors.onboardingFooterStatusText,
  },
  [OnboardingFooterStatus.hardwareConnecting]: {
    gradientStartColor: MkiColors.onboardingStatusBarGradientStart,
    gradientEndColor: MkiColors.onboardingStatusBarGradientEnd,
    text: I18n.t("ONBOARDING_FULLSTACK.FOOTER_STATUS.HARDWARE_CONNECTING"),
    textColor: MkiColors.onboardingFooterStatusText,
  },
  [OnboardingFooterStatus.hardwareUpgrading]: {
    gradientStartColor: MkiColors.onboardingStatusBarGradientStart,
    gradientEndColor: MkiColors.onboardingStatusBarGradientEnd,
    text: I18n.t("ONBOARDING_FULLSTACK.FOOTER_STATUS.HARDWARE_UPGRADING"),
    textColor: MkiColors.onboardingFooterStatusText,
  },
  [OnboardingFooterStatus.hardwareFinished]: {
    gradientStartColor: MkiColors.onboardingStatusBarGradientStart,
    gradientEndColor: MkiColors.onboardingStatusBarGradientEnd,
    text: I18n.t("ONBOARDING_FULLSTACK.FOOTER_STATUS.HARDWARE_FINISHED"),
    textColor: MkiColors.onboardingFooterStatusText,
  },
});
