import { I18n } from "@meraki/core/i18n";
import {
  Button,
  ButtonProps,
  Heading,
  InlineNotificationProps,
  Loader,
  Notification,
  Text,
} from "@meraki/magnetic/components";
import { Icon, Status, StatusProps } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";

const CONNECTION_TYPE_ICON_MAP = {
  // wireless: "wifi",
  wireless: "WifiHigh",
  //   vpn: "vpn",
  vpn: "Broadcast",
  //   wired: "sitemap",
  wired: "PlugsConnected",
} as const;

export type ConnectionDetailHeaderProps = {
  title?: string;
  status?: StatusProps["status"];
  repeater?: StatusProps["repeater"];
  loading?: boolean;

  connectionType?: keyof typeof CONNECTION_TYPE_ICON_MAP;
  connectionName?: string;
  onConnectionNamePress?: ButtonProps["onPress"];
  connectionNode?: string;
  onConnectionNodePress?: ButtonProps["onPress"];

  alert?: InlineNotificationProps;
};

const getConnectionLabel = (
  connectionType: ConnectionDetailHeaderProps["connectionType"],
  connectionName: string,
  skipDisplayPort?: boolean,
): string => {
  if (connectionType === "wired" && !skipDisplayPort) {
    return I18n.t("DETAILS_HEADER.CONNECTED_TO_PORT", { port_name: connectionName });
  }
  if (connectionType === "vpn") {
    return I18n.t("DETAILS_HEADER.CLIENT_VPN", { connection_name: connectionName });
  }
  return `${connectionName}`;
};

export function ConnectionDetailHeader({
  title,
  status,
  repeater,
  loading,
  connectionType,
  connectionName,
  onConnectionNamePress,
  connectionNode,
  onConnectionNodePress,
  alert,
}: ConnectionDetailHeaderProps) {
  if (loading) {
    return (
      <Box>
        <Loader.Skeleton color="strong" height={80} />
      </Box>
    );
  }

  const shouldShowConnectionInfo = !!connectionType && !!connectionName && !!connectionNode;

  return (
    <Box paddingHorizontal="sm" gap="xs">
      <Box>
        <Box flexDirection="row" alignItems="center" gap="2xs" paddingRight="md">
          {!!status && <Status status={status} repeater={repeater} />}
          <Heading size="h1" numberOfLines={1}>
            {title}
          </Heading>
        </Box>
        {shouldShowConnectionInfo && (
          <Box flexDirection="row" alignItems="center" gap="2xs">
            <Icon name={CONNECTION_TYPE_ICON_MAP[connectionType]} size={18} />
            {onConnectionNamePress ? (
              <Button
                kind="tertiary"
                text={getConnectionLabel(connectionType, connectionName)}
                onPress={onConnectionNamePress}
              />
            ) : (
              <Text size="p2">{getConnectionLabel(connectionType, connectionName)}</Text>
            )}
            <Text size="p2">{I18n.t("DETAILS_HEADER.VIA")}</Text>
            {onConnectionNodePress ? (
              <Button kind="tertiary" text={connectionNode} onPress={onConnectionNodePress} />
            ) : (
              <Text size="p2">{connectionNode}</Text>
            )}
          </Box>
        )}
      </Box>
      {!!alert && <Notification.Inline {...alert} />}
    </Box>
  );
}
