import { currentLanguage, I18n } from "@meraki/core/i18n";

export const WirelessBandTypesArray = ["twoFourGhzSettings", "fiveGhzSettings"] as const;
export type WirelessBandTypes = (typeof WirelessBandTypesArray)[number];

interface RadioOptions {
  label: string;
  value: number | string | null;
}

export const DEFAULT_TWO_FOUR_SETTINGS = {
  targetPower: null,
  channel: null,
};
export const DEFAULT_FIVE_SETTINGS = {
  targetPower: null,
  channel: null,
  channelWidth: null,
};

export interface RadioSettingsPowerLimits {
  max: number;
  min: number;
}

// trying to re-create IS_ON_JAPANESE from monolith
// channel 14 is only allowed in Japan
export const TWO_GHZ_CHANNELS: RadioOptions[] = currentLanguage().includes("ja")
  ? [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10", value: 10 },
      { label: "11", value: 11 },
      { label: "14", value: 14 },
    ]
  : [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10", value: 10 },
      { label: "11", value: 11 },
    ];

export const FIVE_GHZ_CHANNELS: RadioOptions[] = [
  { label: "36", value: 36 },
  { label: "40", value: 40 },
  { label: "44", value: 44 },
  { label: "48", value: 48 },
  { label: "52", value: 52 },
  { label: "56", value: 56 },
  { label: "60", value: 60 },
  { label: "64", value: 64 },
  { label: "100", value: 100 },
  { label: "104", value: 104 },
  { label: "108", value: 108 },
  { label: "112", value: 112 },
  { label: "132", value: 132 },
  { label: "136", value: 136 },
  { label: "140", value: 140 },
  { label: "144", value: 144 },
  { label: "149", value: 149 },
  { label: "153", value: 153 },
  { label: "157", value: 157 },
  { label: "161", value: 161 },
];
export const FIVE_GHZ_CHANNEL_WIDTHS: RadioOptions[] = [
  { label: "20 MHz", value: "20" },
  { label: "40 MHz", value: "40" },
  { label: "80 MHz", value: "80" },
];

// 5 to 30
export const TWO_GHZ_POWER_LIMITS: RadioSettingsPowerLimits = {
  min: 5,
  max: 30,
};
// 8 to 30
export const FIVE_GHZ_POWER_LIMITS: RadioSettingsPowerLimits = {
  min: 8,
  max: 30,
};

export const getChannelLabel = (channel?: number | null) =>
  channel ? channel.toString() : I18n.t("RADIO_SETTINGS.GOv3.AUTO");

export const getChannelWidthLabel = (channelWidth?: number | string | null) =>
  channelWidth ? `${channelWidth} MHz` : I18n.t("RADIO_SETTINGS.GOv3.AUTO");

export const getPowerLabel = (power?: number | null) =>
  power ? `${power} dBm` : I18n.t("RADIO_SETTINGS.GOv3.AUTO");
