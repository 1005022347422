import { z } from "zod";

import { request } from "../../../api/request/request";
import { APIResponseError } from "../../../schemas";
import { createMutation } from "../../createMutation";
import {
  AppliancePort,
  AppliancePortRequest,
  AppliancePortSchema,
  buildSingleUrl,
} from "./useAppliancePorts";

export const StagedAppliancePortSchema = AppliancePortSchema.omit({
  number: true,
}).describe("StagedAppliancePortSchema");

export const DisabledAppliancePortSchema = z
  .object({
    enabled: z.literal(false),
  })
  .describe("DisabledAppliancePortSchema");

export type StagedAppliancePort = z.infer<typeof StagedAppliancePortSchema>;
export type DisabledAppliancePort = z.infer<typeof DisabledAppliancePortSchema>;

export const createEditableAppliancePort = (appliancePort: AppliancePort): StagedAppliancePort => {
  return StagedAppliancePortSchema.strip().parse(appliancePort);
};
export const createDisabledAppliancePort = (
  appliancePort: AppliancePort,
): DisabledAppliancePort => {
  return DisabledAppliancePortSchema.strip().parse(appliancePort);
};

interface AppliancePortMutationRequest {
  networkId?: string;
  portId: string;
  appliancePort: Partial<AppliancePort>;
}

function buildMutationUrl({ networkId, portId }: AppliancePortRequest) {
  return `/api/v1/networks/${networkId}/appliance/ports/${portId}`;
}

const mutateAppliancePort = ({
  networkId,
  portId,
  appliancePort,
}: AppliancePortMutationRequest) => {
  return request(AppliancePortSchema, "PUT", buildMutationUrl({ networkId, portId }), {
    body: JSON.stringify(appliancePort),
  });
};

export const useUpdateAppliancePort = createMutation<
  AppliancePortMutationRequest,
  AppliancePort,
  APIResponseError
>({
  baseMutationKey: buildSingleUrl({ networkId: "{networkId}", portId: "{portId}" }),
  mutationFn: (variables) => {
    return mutateAppliancePort(variables);
  },
});
