import { I18n } from "@meraki/core/i18n";
import _ from "lodash";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { capitalizeFirstLetter, clientWordForCount } from "~/lib/formatHelper";
import ExpandableSummaryCard from "~/shared/components/ExpandableSummaryCard";
import MkiText from "~/shared/components/MkiText";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import GeneralStatus from "~/shared/constants/Status";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { Client, ExtendedClient } from "~/shared/types/Client";
import { CounterSet } from "~/shared/types/CounterSet";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

const getTotalUsage = (clients: ExtendedClient[]) =>
  clients.reduce((total, c) => total + (c.totalUsage ?? 0), 0);

interface ClientsOverviewProps {
  clients: ExtendedClient[];
  counterSets?: CounterSet;
  navigate: (screen: string, params?: Record<string, unknown>) => void;
  loading?: boolean;
  customFilter?: (x: any) => Client[];
  ssidNumber?: number;
  context?: string;
  dragging?: boolean;
}

const ClientsOverview = (props: ClientsOverviewProps) => {
  const { loading, context, dragging } = props;

  const renderOverview = () => {
    const {
      clients: allClients,
      counterSets,
      customFilter = CUSTOM_FILTERS.DEFAULT,
      ssidNumber,
      navigate,
    } = props;

    const clients = customFilter(allClients);
    if (clients.length === 0) {
      return <MkiText screenStyles={styles.noClientsText}>{I18n.t("NO_DEVICES")}</MkiText>;
    }

    const highUsageNum = CUSTOM_FILTERS.HIGH_USAGE_CLIENT(clients).length;
    const uniqueNum = clients.length;
    const averageUsage = getTotalUsage(clients) / uniqueNum;

    const highUsageOnPress = () =>
      navigate("ClientList", {
        title: `${I18n.t("CLIENTS_OVERVIEW.HIGH")} ${clientWordForCount(highUsageNum)}`,
        customFilter: (c: any) => CUSTOM_FILTERS.HIGH_USAGE_CLIENT(customFilter(c)),
        ssidNumber,
      });

    return (
      <View style={styles.statusBoxes}>
        <QuickStatusBox
          value={highUsageNum}
          subText={`${I18n.t("CLIENTS_OVERVIEW.HIGH")} ${clientWordForCount(highUsageNum)}`}
          status={highUsageNum === 0 ? null : GeneralStatus.bad}
          onPress={highUsageNum === 0 ? null : highUsageOnPress}
        />
        <QuickStatusBox
          value={uniqueNum}
          subText={`${I18n.t("CLIENTS_OVERVIEW.UNIQUE")} ${clientWordForCount(uniqueNum)}`}
          onPress={() =>
            navigate("ClientList", {
              title: `${capitalizeFirstLetter(I18n.t("DEVICES_WORD"))}`,
              ssidNumber,
              customFilter,
            })
          }
          testID="CLIENTS_OVERVIEW.UNIQUE"
        />
        <QuickStatusBox
          value={averageUsage}
          subText={I18n.t("CLIENTS_OVERVIEW.AVERAGE")}
          isData
          onPress={_.isEmpty(counterSets) ? null : () => navigate("ApplicationUsageList")}
        />
      </View>
    );
  };

  return (
    <ExpandableSummaryCard
      heading={`${capitalizeFirstLetter(I18n.t("DEVICES_WORD"))}`}
      subheading={I18n.t("HOME.DEVICES.SUBTITLE")}
      loading={loading}
      context={context}
      savePreferencesKey={ExpandableCardKeys.clientsOverview}
      dragging={dragging}
    >
      {renderOverview()}
    </ExpandableSummaryCard>
  );
};

const styles = StyleSheet.create({
  noClientsText: {
    color: MkiColors.secondaryTextColor,
  },
  statusBoxes: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default memo(ClientsOverview);
