import { setHomeSSID, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { MAX_SSIDS, SPACING } from "~/constants/MkiConstants";
import { DETOX } from "~/env";
import AskForBioAuthBanner from "~/go/components/AskForBioAuthBanner";
import WhatsNewBanner from "~/go/components/WhatsNewBanner";
import { useGetHomeScreenData } from "~/go/hooks/useGetHomeScreenData";
import { useUpdateHardwareBadge } from "~/go/hooks/useUpdateHardwareBadge";
import withCodePush from "~/hocs/CodePush";
import I18n from "~/i18n/i18n";
import { sendSetHomeNetworkEvent } from "~/lib/FirebaseUtils";
import { isDeepEmpty, nestedValueExists } from "~/lib/objectHelper";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { askForRating } from "~/lib/RateUtils";
import { nextSSID } from "~/lib/SSIDUtils";
import {
  countUnreadFeatures,
  currentOrganization,
  getNetworkName,
  getShouldAskForRating,
  hasUpdatedWhatsNew,
  homeSSIDState,
  makeHardwareListDevices,
  slimSsidsSelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import TimePicker from "~/shared/components/TimePicker";
import useActions from "~/shared/hooks/redux/useActions";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import ListRow from "~/shared/rows/ListRow";

import { DraggableHomeScreenCards } from "../components/DraggableHomeScreenCards";
import { HomeStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HomeStackProps, "Home">;

export const Home = () => {
  const [reqPending, setReqPending] = useState(true);
  const dispatch = useAppDispatch();

  const homeSSID = useAppSelector(homeSSIDState);
  const filter = makeHardwareListDevices();

  const ssids = useAppSelector(slimSsidsSelector);
  const timespan = useGlobalTimespan();
  const currentNetworkName = useAppSelector(getNetworkName);
  const currentOrg = useAppSelector(currentOrganization);
  const devicesByType = useAppSelector((state) =>
    filter(state, { customFilter: CUSTOM_FILTERS.DEFAULT }),
  );
  const hasWhatsNewUpdated = useAppSelector(hasUpdatedWhatsNew);
  const numOfUnreadFeatures = useAppSelector(countUnreadFeatures);
  const shouldAskForRating = useAppSelector(getShouldAskForRating);

  const actions = useActions();

  const navigation = useNavigation<Props["navigation"]>();

  const showingAllNetworks = useCallback(() => homeSSID === MAX_SSIDS, [homeSSID]);

  const checkAskForRating = useCallback(() => {
    if (!isWeb() && shouldAskForRating) {
      askForRating();
    }

    actions.increaseHomeVisits();
  }, [actions, shouldAskForRating]);

  useUpdateHardwareBadge();

  const getData = useGetHomeScreenData({ showingAllNetworks: showingAllNetworks() });

  useEffect(() => {
    setReqPending(true);
  }, [homeSSID, setReqPending, timespan]);

  useEffect(() => {
    if (reqPending) {
      getData().finally(() => setReqPending(false));
    }
  }, [getData, reqPending]);

  useEffect(() => {
    if (!DETOX) {
      checkAskForRating();
    }
  }, [checkAskForRating]);

  useEffect(() => {
    const isSelectedNetworkConfigured = nestedValueExists(ssids, [homeSSID, "configured"], false);

    if (!showingAllNetworks() && !isSelectedNetworkConfigured) {
      dispatch(setHomeSSID(MAX_SSIDS));
    }
  }, [ssids, homeSSID, showingAllNetworks, actions, dispatch]);

  const hasDevices = () => {
    if (!reqPending && isDeepEmpty(devicesByType)) {
      return false;
    }
    return true;
  };

  const showOrgChooseModal = (visibleBackButton = true) => {
    sendSetHomeNetworkEvent(homeSSID);
    navigation.navigate("OrgChoose", {
      showLocationTitle: true,
      allowClose: visibleBackButton,
    });
  };

  const renderLocationPicker = () => {
    return (
      <SafeAreaView>
        <ListRow
          testID="LOCATION_PICKER"
          icon={
            <MerakiIcon
              name={"global"}
              size="m"
              containerStyle={styles.globeIcon}
              color={isWeb() ? MkiColors.selectedTabBarIcon : MkiColors.defaultIconColor}
            />
          }
          subtitle2={currentNetworkName}
          rowStyles={styles.locationPicker}
          accessory={
            !isWeb() && <MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />
          }
          onPress={() => showOrgChooseModal()}
        >
          {currentOrg?.name}
        </ListRow>
      </SafeAreaView>
    );
  };

  // TODO: Extract this into separate component shared with LEDColorLegend
  const renderHeading = () => {
    const name = showingAllNetworks()
      ? I18n.t("HOME.ALL_NETWORKS")
      : nestedValueExists(ssids, [homeSSID, "name"], "");

    let leftArrow: ReactNode | undefined;
    let rightArrow: ReactNode | undefined;

    // Don't show arrows if no configured ssids are enabled
    if (ssids.length > 0 && ssids.some((ssid: any) => ssid.configured && ssid.enabled)) {
      // cycle through all ssids
      const onPress = (offset = 1) => {
        let newSsid = nextSSID(homeSSID, offset);
        // skip ssids that aren't configured or enabled. Always choose the "all networks"
        while (newSsid !== MAX_SSIDS && !(ssids[newSsid].configured && ssids[newSsid].enabled)) {
          newSsid = nextSSID(newSsid, offset);
        }

        dispatch(setHomeSSID(newSsid));
      };
      const hitSlop = { top: 10, bottom: 10, left: 20, right: 20 };
      leftArrow = (
        <MerakiIcon
          name="chevron-left"
          size="s"
          onPress={() => onPress(-1)}
          hitSlop={hitSlop}
          color={MkiColors.chevronColor}
          testID="SSID_FITLER.LEFT"
        />
      );
      rightArrow = (
        <MerakiIcon
          name="chevron-right"
          size="s"
          onPress={() => onPress()}
          hitSlop={hitSlop}
          color={MkiColors.chevronColor}
          testID="SSID_FITLER.RIGHT"
        />
      );
    }

    return (
      <View>
        {renderLocationPicker()}
        <View style={styles.networkPicker}>
          {leftArrow}
          <View style={styles.networkName}>
            <MkiText textStyle="heading" numberOfLines={1} ellipsizeMode="tail">
              {name}
            </MkiText>
          </View>
          {rightArrow}
        </View>
      </View>
    );
  };

  const showWhatsNewListScreen = () => {
    actions.readWhatsNew();
    sendSetHomeNetworkEvent(homeSSID);
    navigation.navigate("WhatsNewList");
  };

  const shouldHideWhatsNew = () => {
    return !hasWhatsNewUpdated || numOfUnreadFeatures == 0;
  };

  const renderTimePicker = () => {
    if (!hasDevices()) {
      return <View style={styles.emptyDevices} />;
    }

    return (
      <TimePicker
        timespan={timespan}
        setTimespan={actions.setTimespan}
        disabled={reqPending}
        testID="TIME_PICKER"
      />
    );
  };

  return (
    <FullScreenContainerView ignoreKeyboardAvoiding>
      <View>
        {renderHeading()}
        {renderTimePicker()}
      </View>
      <DraggableHomeScreenCards setReqPending={setReqPending} reqPending={reqPending} />
      <WhatsNewBanner
        shouldHideWhatsNew={shouldHideWhatsNew()}
        numOfUnreadFeatures={numOfUnreadFeatures}
        showWhatsNewListScreen={showWhatsNewListScreen}
      />
      <AskForBioAuthBanner shouldHideWhatsNew={shouldHideWhatsNew()} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  emptyDevices: {
    marginBottom: 0,
  },
  globeIcon: {
    margin: SPACING.default,
  },
  locationPicker: platformSelect({
    mobile: {
      paddingHorizontal: SPACING.large,
      paddingVertical: SPACING.meager,
    },
    web: {
      paddingHorizontal: SPACING.large,
      paddingTop: SPACING.meager,
      paddingBottom: SPACING.small,
    },
  }),
  networkPicker: {
    marginTop: SPACING.small,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  networkName: {
    alignItems: "center",
  },
});

export default compose(withCodePush)(Home);
