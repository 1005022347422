import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

const buildUrl = ({ serial }: BlinkLEDRequest) => {
  return `/api/v1/devices/${serial}/blinkLeds`;
};

interface BlinkLEDRequest {
  serial: string;
}

interface MutateBlinkLEDRequest extends BlinkLEDRequest {
  body?: BlinkLED;
}

export const BlinkLEDSchema = z
  .object({
    duration: z.number(),
    period: z.number(),
    duty: z.number(),
  })
  .optional();

export type BlinkLED = z.infer<typeof BlinkLEDSchema>;

const createBlinkLED = ({ serial, body }: MutateBlinkLEDRequest) => {
  return request(BlinkLEDSchema, "POST", buildUrl({ serial }), {
    body: JSON.stringify(body),
  });
};

export const useCreateBlinkLED = createMutation({
  baseMutationKey: buildUrl({ serial: `{serial}` }),
  mutationFn: (parameters: MutateBlinkLEDRequest) => createBlinkLED({ ...parameters }),
});
