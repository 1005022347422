import { I18n } from "@meraki/core/i18n";
import { launchAmazonUrl } from "@meraki/go/links";
import { getProductTypeTranslation } from "@meraki/shared/devices";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import HardwareListRow from "~/go/components/HardwareListRow";
import InlineAlert from "~/go/components/InlineAlert";
import LicensesModal from "~/go/components/LicensesModal";
import NextStepsMessage from "~/go/components/NextStepsMessage";
import SectionListHeader from "~/go/components/SectionListHeader";
import StatusLegend from "~/go/components/StatusLegend";
import { Features } from "~/go/types/ContextHelpTypes";
import { showAlert } from "~/lib/AlertUtils";
import { isDeepEmpty } from "~/lib/objectHelper";
import { isWeb } from "~/lib/PlatformUtils";
import {
  getCurrentNetwork,
  getFetchingNodes,
  getIsAmericanTimezone,
  hasCompleteSuite,
  makeHardwareListDevices,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import {
  AddButton,
  HeaderButtonGroup,
  LicensesButton,
  RefreshButton,
  TopologyButton,
} from "~/shared/navigation/Buttons";
import Device from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

import { useUpdateHardwareBadge } from "../hooks/useUpdateHardwareBadge";
import { HardwareStackPropMap } from "../navigation/Types";

interface DevicesByType {
  security?: Device[];
  switch?: Device[];
  wireless?: Device[];
}

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "HardwareList">;

export const HardwareListScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const actions = useActions();

  const deviceFilter = makeHardwareListDevices();
  const devicesByType = useAppSelector((state) => deviceFilter(state, {}));
  const orgId = useCurrentOrganizationId();
  const network = useAppSelector(getCurrentNetwork);
  const fetchingNodes = useAppSelector(getFetchingNodes);
  const doesHaveCompleteSuite = useAppSelector(hasCompleteSuite);
  const isAmericanTimezone = useAppSelector(getIsAmericanTimezone);

  const [showUpsell, setShowUpsell] = useState(true);
  const [showLicenses, setShowLicenses] = useState(false);
  const [reqPending, setReqPending] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  useUpdateHardwareBadge();

  const loadData = useCallback(async () => {
    if (orgId != null && network != null) {
      setReqPending(true);

      try {
        setTimestamp(Date.now());
        await actions.loadNodesAndStatuses(network.id);
      } catch (error) {
        showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
      } finally {
        setReqPending(false);
      }
    }
  }, [actions, network, orgId]);

  const startAddHardware = useCallback(
    () =>
      navigation.navigate("OnboardingStack", {
        screen: "AddHardware",
        params: { isOnboarding: false, presentedModally: true },
      }),
    [navigation],
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <RefreshButton onPress={loadData} />,
      headerRight: () => (
        <HeaderButtonGroup>
          {isWeb() ? (
            <LicensesButton onPress={() => setShowLicenses(true)} />
          ) : (
            <TopologyButton onPress={() => navigation.navigate("Topology")} />
          )}
          <AddButton onPress={startAddHardware} />
        </HeaderButtonGroup>
      ),
    });
  }, [loadData, navigation, startAddHardware]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const launchLED = () => navigation.navigate("LEDColorLegend", {});

  const renderSectionHeader = ({ section }: any) => {
    let actionText: string | undefined = undefined;
    let onPress: (() => void) | undefined;

    if (section.key === ProductType.switch && section.data.length > 1) {
      actionText = I18n.t("PORTS.SEE_ALL");
      onPress = () => navigation.navigate("SwitchPortsList", { showAllPorts: true });
    }

    return (
      <SectionListHeader
        heading={getProductTypeTranslation(section.key)}
        onPress={onPress}
        actionText={actionText}
        withHorizontalMargin
      />
    );
  };

  const renderRow = (rowData: Device, _: any) => {
    const { serial } = rowData;
    const params = {
      serialNumber: serial,
      loadHardwareListData: loadData,
    };

    return (
      <HardwareListRow
        deviceId={rowData.id}
        onPress={() => navigation.navigate("HardwareDetails", params)}
        testID={`HARDWARE_LIST_ROW.${serial}`}
        timestamp={timestamp}
        showStatusIcon
      />
    );
  };

  const renderNextStepMessage = () => {
    return (
      <RefreshControlScrollView
        refreshing={fetchingNodes || reqPending}
        onRefresh={loadData}
        style={styles.container}
        testID="HARDWARE_LIST_EMPTY_SCREEN_SCROLL_VIEW"
      >
        <NextStepsMessage
          message={I18n.t("HARDWARE_EMPTY_STATE.MESSAGE")}
          onPress={startAddHardware}
          buttonText={I18n.t("HARDWARE_EMPTY_STATE.BUTTON")}
        />
      </RefreshControlScrollView>
    );
  };

  const renderFooter = () => {
    const alertMessage = isAmericanTimezone
      ? I18n.t("DEVICES.UPSELL.MESSAGE_WITH_AMAZON")
      : I18n.t("DEVICES.UPSELL.MESSAGE_WITHOUT_AMAZON");
    const primaryButtonText = isAmericanTimezone ? I18n.t("DEVICES.UPSELL.AMAZON_LINK") : undefined;
    const onPrimaryPress = isAmericanTimezone ? launchAmazonUrl : undefined;

    return (
      <View>
        <SectionListHeader
          heading={I18n.t("LEGEND")}
          context={Features.statusDetails}
          withHorizontalMargin
        />
        <StatusLegend onPress={launchLED} />
        <InlineAlert
          visible={showUpsell && !doesHaveCompleteSuite}
          onExit={() => setShowUpsell(false)}
          alertTitle={I18n.t("DEVICES.UPSELL.TITLE")}
          alertMessage={alertMessage}
          screenStyles={styles.inlineAlert}
          primaryButtonText={primaryButtonText}
          onPrimaryPress={onPrimaryPress}
          testID="HARDWARE_LIST_UPSELL_CARD"
          preferenceKey="DEVICES_UPSELL"
        />
      </View>
    );
  };

  const renderTable = () => (
    <MkiTable<Device>
      data={devicesByType}
      keyExtractor={(item: any) => item.serial}
      renderRow={renderRow}
      renderSectionHeader={renderSectionHeader}
      ListFooterComponent={renderFooter}
      refreshing={fetchingNodes || reqPending}
      onRefresh={loadData}
      scrollViewTestID="HARDWARE_LIST_TABLE_SCROLL_VIEW"
    />
  );

  return (
    <View style={styles.container}>
      {fetchingNodes || !isDeepEmpty(devicesByType) ? renderTable() : renderNextStepMessage()}
      {isWeb() && <LicensesModal visible={showLicenses} onDismiss={() => setShowLicenses(false)} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inlineAlert: {
    marginVertical: SPACING.default,
  },
});

export default HardwareListScreen;
