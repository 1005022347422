import { createSelector } from "reselect";

import { currentNetworkState } from "~/selectors/getters";
import { RootState } from "~/shared/types/Redux";

const emptyObject = {};
const getNetworkCounterSets = (state: RootState) =>
  state?.entities?.networkCounterSets || emptyObject;

// counter sets
export const counterSetsState = createSelector(
  currentNetworkState,
  getNetworkCounterSets,
  (currentNetworkState, networkCounterSets) =>
    (currentNetworkState ? networkCounterSets[currentNetworkState] : undefined) || emptyObject,
);
