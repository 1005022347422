import { createContext, ReactNode, useContext } from "react";

export const AdapterContext = createContext<boolean>(false);

export interface AdapterProviderProps {
  magneticEnabled: boolean;
  children: ReactNode;
}

export function AdapterProvider({ magneticEnabled, children }: AdapterProviderProps) {
  return <AdapterContext.Provider value={magneticEnabled}>{children}</AdapterContext.Provider>;
}

export function useMagneticDesignSystem() {
  const magneticEnabled = useContext(AdapterContext);

  return magneticEnabled;
}
