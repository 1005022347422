import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken, Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { ReactNode } from "react";
import { AccessibilityProps, Pressable, StyleProp, View, ViewStyle } from "react-native";

import { Text } from "../Text/Text";
import { AccordionGroup } from "./AccordionGroup";
import { Group } from "./Group";

type AllowedAccessibilityProps = Pick<AccessibilityProps, "role" | "accessibilityHint">;

export type CollapseProps = {
  title: string;
  date?: string;
  children: ReactNode;
  onToggle?: () => void;
  open?: boolean;
  type?: "stacked" | "contained" | "borderless";
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;

  backgroundColor?: ColorToken;

  // Calculated props (generally)
  first?: boolean;
  last?: boolean;
} & AllowedAccessibilityProps;

function getContainerStyle(
  { type, first, last, backgroundColor }: Partial<CollapseProps>,
  theme: Theme,
): StyleProp<ViewStyle> {
  switch (type) {
    case "contained":
      return {
        backgroundColor: backgroundColor ?? theme.color.default.bg.weak.base,
        borderColor: theme.color.control.border.base,
        borderTopWidth: first ? theme.SizeContainBorderStroke : undefined,
        borderLeftWidth: theme.SizeContainBorderStroke,
        borderRightWidth: theme.SizeContainBorderStroke,
        borderBottomWidth: theme.SizeContainBorderStroke,
        borderTopLeftRadius: first ? theme.SizeContainBorderRadius : undefined,
        borderTopRightRadius: first ? theme.SizeContainBorderRadius : undefined,
        borderBottomLeftRadius: last ? theme.SizeContainBorderRadius : undefined,
        borderBottomRightRadius: last ? theme.SizeContainBorderRadius : undefined,
      };
    case "borderless":
      return {
        backgroundColor: backgroundColor ?? theme.color.default.bg.weak.base,
        borderColor: theme.color.control.border.base,
        borderTopWidth:
          typeof last === "undefined" || first ? theme.SizeContainBorderStroke : undefined,
        borderBottomWidth: theme.SizeContainBorderStroke,
      };
    case "stacked":
    default:
      return {
        backgroundColor: backgroundColor ?? theme.color.default.bg.weak.base,
        borderColor: theme.color.control.border.base,
        borderWidth: theme.SizeContainBorderStroke,
        borderRadius: theme.SizeContainBorderRadius,
      };
  }
}

export function Collapse({
  accessibilityHint,
  title,
  date,
  onToggle,
  open,
  type = "stacked",
  leftAccessory,
  rightAccessory,
  role,
  first,
  last,
  backgroundColor,
  children,
}: CollapseProps) {
  const theme = useMagneticTheme();

  return (
    <View
      accessibilityHint={accessibilityHint}
      aria-expanded={open}
      role={role}
      style={getContainerStyle({ type, first, last, backgroundColor }, theme)}
    >
      <Pressable accessibilityRole="button" onPress={onToggle}>
        <Box
          flexDirection="row"
          alignItems="center"
          bottomDividerBorder={open && type === "stacked"}
          padding="sm"
          gap="2xs"
        >
          {leftAccessory && <Box alignSelf="flex-start">{leftAccessory}</Box>}
          <Box flex={1}>
            {date && (
              <Text size="p3" color="light">
                {date}
              </Text>
            )}
            <Text size="p2" weight="semiBold">
              {title}
            </Text>
          </Box>
          {rightAccessory}
          <Icon name={open ? "CaretUp" : "CaretDown"} />
        </Box>
      </Pressable>
      {open && (
        <Box padding="sm" paddingTop={type === "stacked" ? undefined : "none"}>
          {children}
        </Box>
      )}
    </View>
  );
}

Collapse.Group = Group;
Collapse.AccordionGroup = AccordionGroup;
