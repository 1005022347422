import { FirebaseApp } from "firebase/app";
import { getPerformance, trace } from "firebase/performance";

export function setupPerformance(app: FirebaseApp) {
  const performance = getPerformance(app);

  return {
    ...performance,
    startTrace: (identifier: string) => {
      const t = trace(performance, identifier);
      t.start();
      return t;
    },
    setPerformanceCollectionEnabled: (enabled: boolean) => {
      performance.dataCollectionEnabled = enabled;
    },
  };
}
