import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import MerakiIcon from "~/shared/components/icons";

interface ExitButtonProps {
  onExit: () => void;
}
export default function ExitButton({ onExit }: ExitButtonProps) {
  return (
    <View style={styles.iconContainer}>
      <MerakiIcon
        testID="AGREEMENT_MODAL.CANCEL"
        name="close"
        size="s"
        color={MkiColors.secondaryButton}
        onPress={onExit}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: "flex-end",
  },
});
