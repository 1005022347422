import { SupportCase } from "@meraki/shared/api";
import { createContext, useContext } from "react";

type SupportCasesListContextValue = {
  openCases?: SupportCase[];
  closedCases?: SupportCase[];
  goToTab?: (key: string) => void;
};

export const SupportCasesListContext = createContext<SupportCasesListContextValue>({
  goToTab: () => undefined,
});

export function useSupportCasesListContext() {
  return useContext(SupportCasesListContext);
}
