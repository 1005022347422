import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, List, Loader, Text, Toggle } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  AlertClient,
  AlertDestinations,
  AlertSettingsTypes,
  ClientType,
  queryClient,
  useAlertSettings,
  useUpdateAlertSettings,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useEffect, useState } from "react";
import { Alert } from "react-native";

interface TrackBottomSheetProps {
  client: ClientType;
  trackedClients: AlertClient[];
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export function TrackBottomSheet({
  client,
  trackedClients,
  bottomSheetRef,
}: TrackBottomSheetProps) {
  const networkId = useCurrentNetworkId();

  const currentTrackingStatus =
    !!(client && trackedClients.find((trackedClient) => trackedClient.mac === client.mac)) || false;
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    setIsTracked(currentTrackingStatus);
  }, [currentTrackingStatus]);

  const { data: alertDestinationsForTracking } = useAlertSettings(
    { networkId },
    {
      select: (data) =>
        data.alerts.find((alert) => alert.type === "clientConnectivity")?.alertDestinations,
    },
  );
  const { mutate: updateAlertSettings } = useUpdateAlertSettings();

  if (!client) {
    return (
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <Loader.Spinner animate={true} />
      </BottomSheet.Modal>
    );
  }

  const updateClientTracking = () => {
    let updatedClients: AlertClient[] = [];
    const alertDestinations: AlertDestinations = alertDestinationsForTracking || {
      allAdmins: false,
      emails: [],
    };
    if (isTracked) {
      updatedClients = [...trackedClients, { mac: client.mac, name: client?.description || "" }];
    } else {
      updatedClients = trackedClients.filter((trackedClient) => trackedClient.mac !== client.mac);
    }

    const alertSettings = {
      alerts: [
        {
          filters: {
            clients: [...updatedClients],
          },
          enabled: isTracked,
          type: AlertSettingsTypes.clientConnectivity,
          alertDestinations: alertDestinations,
        },
      ],
    };
    updateAlertSettings(
      { networkId, alertSettings },
      {
        onError: (error) => {
          if (error !== null && typeof error === "object" && "errors" in error) {
            Alert.alert(String(error["errors"]));
          }
        },

        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useAlertSettings.queryKeyRoot });
          bottomSheetRef?.current?.dismiss();
        },
      },
    );
  };
  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("TRACK_CLIENT.HEADING")}
        cancelLabel={I18n.t("CANCEL")}
        resetLabel={"Reset"}
        onCancelPress={() => {
          setIsTracked(currentTrackingStatus);
          bottomSheetRef?.current?.dismiss();
        }}
        onResetPress={() => {
          setIsTracked(currentTrackingStatus);
        }}
      />
      <BottomSheet.Content>
        <Box padding="xs">
          <Text size="p1">{I18n.t("TRACK_CLIENT.SUBTITLE")}</Text>
        </Box>
        <Box padding="xs">
          <Text size="p1" color="light">
            {I18n.t("TRACK_CLIENT.NOTE")}
          </Text>
        </Box>
        <List>
          <List.Item
            title={I18n.t("TRACK_CLIENT.TITLE")}
            rightAccessory={
              <Toggle
                onValueChange={setIsTracked}
                checked={isTracked}
                testID={`TRACK_CLIENT_TOGGLE.${isTracked ? "SELECTED" : "UNSELECTED"}`}
              />
            }
          />
        </List>
        <Box paddingTop="sm">
          <Button
            text={I18n.t("SAVE")}
            onPress={updateClientTracking}
            disabled={isTracked === currentTrackingStatus}
            testID="SAVE_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
