import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";

import FormItem from "~/go/components/FormItem";
import RadioSelectionList from "~/shared/components/RadioSelectionList";

export type IPOptions = "tcp" | "udp";

interface IPRadioSelectionProps {
  selectedValue: IPOptions;
  onSelect: (value: IPOptions) => void;
}

interface IPRadioSelectionState {
  selectedValue: IPOptions;
}

const options = [
  {
    label: I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.IP.TCP"),
    value: "tcp" as const,
  },
  {
    label: I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.IP.UDP"),
    value: "udp" as const,
  },
];

export class IPRadioSelection extends PureComponent<IPRadioSelectionProps, IPRadioSelectionState> {
  constructor(props: IPRadioSelectionProps) {
    super(props);
    this.state = {
      selectedValue: props.selectedValue,
    };
  }

  didSelectValue = (value: IPOptions) => {
    const { onSelect } = this.props;

    this.setState({ selectedValue: value });
    if (onSelect) {
      onSelect(value);
    }
  };

  render() {
    const { selectedValue } = this.state;

    return (
      <FormItem title={I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.IP.TITLE")}>
        <RadioSelectionList
          onSelect={this.didSelectValue}
          options={options}
          selectedValue={selectedValue}
          testID="IP_RADIO_SLECTION_LIST"
        />
      </FormItem>
    );
  }
}

export default IPRadioSelection;
