import { groupBy, mapValues } from "lodash";
import { createSelector } from "reselect";

import { eventsState } from "~/selectors/getters";
import { ClientEvent } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";

const clientEvents = createSelector(eventsState, (events) => {
  if (!events) {
    return events;
  }

  return mapValues(groupBy(events, "clientId"), (e) => groupBy(e, "occurredAt"));
});

export const getClientLogs = createSelector(
  clientEvents,
  (_: RootState, props: { id: string }) => props.id,
  (events, clientId) => events?.[clientId],
);

export const getClientConnectedEvents = createSelector(eventsState, (events) => {
  const connectivityEvents = events.filter(
    (thisEvent) => thisEvent.type === "client_connectivity" && thisEvent.eventData.connected,
  );
  return connectivityEvents as ClientEvent[];
});

export const getClientDisconnectedEvents = createSelector(eventsState, (events) => {
  const connectivityEvents = events.filter(
    (thisEvent) => thisEvent.type === "client_connectivity" && !thisEvent.eventData.connected,
  );
  return connectivityEvents as ClientEvent[];
});

export const eventTestables = {
  eventsState: eventsState,
  clientEvents: clientEvents,
};
