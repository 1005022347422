import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, LINE_HEIGHT, SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/constants/MkiFonts";
import InfoCard from "~/go/components/InfoCard";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { getVisibleInlineAlertForID } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import WrappingButtonRow from "~/shared/components/WrappingButtonRow";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const InlineAlert = (props: InlineAlertProps) => {
  const {
    visible,
    dismissible,
    onExit,
    alertTitle,
    alertMessage,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryPress,
    onSecondaryPress,
    screenStyles,
    testID,
    preferenceKey,
  } = props;

  const actions = useActions();
  const isInlineAlertVisible = useAppSelector((state) =>
    getVisibleInlineAlertForID(state, preferenceKey),
  );
  const isVisible = isInlineAlertVisible && visible;

  if (!isVisible) {
    return null;
  }

  const secondaryButton =
    secondaryButtonText && onSecondaryPress ? (
      <RoundedButton
        onPress={onSecondaryPress}
        buttonType={ButtonType.tertiary}
        screenStyles={styles.linkButton}
        textStyles={styles.buttonText}
      >
        {secondaryButtonText}
      </RoundedButton>
    ) : null;

  const onPressExit = () => {
    preferenceKey && actions.hidelineAlert(preferenceKey);
    onExit?.();
  };

  return (
    <InfoCard
      dismissible={dismissible}
      onExit={onPressExit}
      cardTitle={alertTitle}
      visible={isVisible}
      screenStyles={[styles.card, screenStyles]}
      testID={testID}
    >
      <MkiText screenStyles={styles.messageText}>{alertMessage}</MkiText>
      {primaryButtonText && (
        <WrappingButtonRow>
          <RoundedButton
            onPress={onPrimaryPress ? onPrimaryPress : () => {}}
            buttonType={ButtonType.secondary}
            screenStyles={styles.roundedButton}
            textStyles={styles.buttonText}
            testID={`${testID ?? "INLINE_ALERT"}.PRIMARY_BUTTON`}
          >
            {primaryButtonText}
          </RoundedButton>
          {secondaryButton}
        </WrappingButtonRow>
      )}
    </InfoCard>
  );
};

export interface InlineAlertProps {
  visible: boolean;
  dismissible?: boolean;
  onExit?: () => void;
  alertTitle: string;
  alertMessage: string | React.ReactElement;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryPress?: () => void;
  onSecondaryPress?: () => void;
  screenStyles?: StyleProp<ViewStyle>;
  testID?: string;
  preferenceKey?: string;
}

InlineAlert.defaultProps = {
  onExit: undefined,
  dismissible: true,
  testID: undefined,
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: SPACING.default,
  },
  messageText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    lineHeight: LINE_HEIGHT.default,
  },
  roundedButton: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  linkButton: {
    paddingHorizontal: SPACING.default,
  },
  buttonText: {
    fontSize: FONT_SIZES.CARD_BUTTON.default,
    textAlign: "left",
  },
});

export default InlineAlert;
