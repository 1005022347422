import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import ListRow from "~/shared/rows/ListRow";

export interface BandwidthRowData {
  title: string;
  subtitle: string;
  deleteIcon?: React.ReactNode;
  testID?: string;
  onPress: () => void;
}

const BandwidthRow = (props: BandwidthRowData) => {
  const { title, subtitle, deleteIcon, testID, onPress } = props;
  return (
    <ListRow
      subtitle1={subtitle}
      icon={deleteIcon}
      onPress={onPress}
      rowStyles={styles.rowStyles}
      leftStyle={styles.leftStyle}
      testID={testID}
    >
      {title}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  leftStyle: {
    width: "100%",
  },
});

export default BandwidthRow;
