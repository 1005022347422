import { I18n } from "@meraki/core/i18n";
import { Button, Heading, Input, Notification } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSSO } from "@meraki/shared/api";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { Clusters } from "@meraki/shared/redux";
import CookieManager from "@react-native-cookies/cookies";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { addDays, formatISO } from "date-fns";
import * as WebBrowser from "expo-web-browser";
import { isEmpty } from "lodash";
import { SetStateAction, useEffect, useState } from "react";
import { Image, KeyboardAvoidingView, Linking, StyleSheet } from "react-native";
import { useDispatch } from "react-redux";

import { cookieDomain, shardURL, ssoAuthCookie } from "~/env";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { normalizeAuthData } from "~/lib/SpSamlUtils";
import { getCurrentCluster, getSsoSubdomain } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useAnalytics from "~/shared/hooks/useAnalytics";

const SSO_SUCCESS_URL = `meraki://sso/success`;
const DOCUMENTATION_URL = "https://cs.co/meraki-sp-saml-guide";

// This is non authoratative. The backend will enforce cookie expiry.
const AUTH_COOKIE_DAYS_TO_LIVE = 31;

const setNewAuthCookie = async (
  auth: string,
  shardId: string,
  cluster: Clusters,
): Promise<void> => {
  const formattedExpiry = formatISO(addDays(Date.now(), AUTH_COOKIE_DAYS_TO_LIVE));
  const newCookie = {
    name: ssoAuthCookie(cluster),
    value: auth,
    domain: cookieDomain(cluster),
    path: "/",
    expires: formattedExpiry,
  };
  await CookieManager.set(shardURL(shardId, cluster), newCookie, false);
};

export function SpSamlLoginScreen() {
  const actions = useActions();
  const logEvent = useAnalytics();
  const navigation = useNavigation<NativeStackNavigationProp<LoginGroupProps>>();
  const dispatch = useDispatch();

  const lastValidSsoSubdomain = useAppSelector(getSsoSubdomain);
  const cluster = useAppSelector(getCurrentCluster);
  const shouldUseNewLogin = useBoolFlag("ld", "use-shared-auth");

  const [isProcessing, setIsProcessing] = useState(false);
  const [subdomain, setSubdomain] = useState(lastValidSsoSubdomain || "");
  const [webBrowserResult, setWebBrowserResult] = useState<
    null | WebBrowser.WebBrowserAuthSessionResult | WebBrowser.WebBrowserResult
  >(null);

  const { data, refetch: fetchSSO, isFetching, error } = useSSO({ subdomain });
  const authNRequest = data?.sp_saml_idp_auth_request?.saml_request_url;

  const _handleAuthBrowserSessionPrompt = async (url: string) => {
    // TODO: https://jira.ikarem.io/browse/DM-5640
    const formattedUrl = url.trim();
    if (isWeb()) {
      // directly open IdP
      window.open(formattedUrl, "_self");
    } else {
      const webBrowserResult: SetStateAction<
        null | WebBrowser.WebBrowserResult | WebBrowser.WebBrowserAuthSessionResult
      > = await WebBrowser.openAuthSessionAsync(formattedUrl, SSO_SUCCESS_URL, {
        showInRecents: true,
        createTask: true,
      });
      setWebBrowserResult(webBrowserResult);
    }
  };

  useEffect(() => {
    if (!!authNRequest && !isEmpty(authNRequest)) {
      _handleAuthBrowserSessionPrompt(authNRequest);
    }
  }, [authNRequest]);

  useEffect(() => {
    if (webBrowserResult && webBrowserResult.type === "success") {
      const authData = normalizeAuthData(webBrowserResult.url);
      if (!!authData) {
        setIsProcessing(true);
        const { auth, shardId, orgId } = authData;
        if (!auth || !shardId || !orgId) {
          throw new Error(I18n.t("SPSAML.REDIRECT_ERROR"));
        }
        setNewAuthCookie(auth, shardId, cluster)
          .then(async () => {
            if (shouldUseNewLogin) {
              if (subdomain !== "meraki") {
                // meraki admin login is a special case where the shard/org we land on is not actually the shard/org
                // that the user is set up on, so trying to hit the `/users` endpoint on n7 actually fails, we
                // want to hit it on n1 where the user is set up. In all other cases, we need to set the shardId
                // here in order to have the `/users` endpoint succeed
                dispatch({ type: "SET_CURRENT_SHARD_ID", shardId });
              }
              navigation.navigate("LoginNextSteps", {
                response: {
                  mode: "success",
                  organizationId: orgId,
                  error: null,
                },
                email: "", // we get the email for saml users later in the flow, but making it optional to pass
                // to LoginNextSteps causes a lot of ts complexity because LoginNextSteps is indifferent to whether
                // a login is coming from saml or password.
              });
              return;
            }

            await actions.setInitialSamlLoginEntities(orgId);
            actions.setValidSsoSubdomain(subdomain);
            logEvent("saml_sso_succeess");
            setIsProcessing(false);
          })
          .catch((err) => {
            console.log(err);
            logEvent("saml_sso_failure");
            setIsProcessing(false);
          });
      }
    }
  }, [
    webBrowserResult,
    actions,
    logEvent,
    cluster,
    subdomain,
    dispatch,
    navigation,
    shouldUseNewLogin,
  ]);

  return (
    <Screen.View addDefaultPadding>
      <KeyboardAvoidingView
        style={styles.keyboardAvoidingView}
        behavior={platformSelect({
          ios: "padding",
          android: "height",
        })}
      >
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={require("~/images/logos/ciscoMeraki/cisco-meraki.png")}
        />
        <Box flex={1} justifyContent="center" gap="lg">
          <Heading size="h1">{I18n.t("LOGIN.SIGN_IN")}</Heading>
          <Box gap="sm">
            <Input
              label={I18n.t("SPSAML.SUBDOMAIN_ENTRY_TITLE")}
              placeholder={I18n.t("SPSAML.SSO_SUBDOMAIN_PLACEHOLDER")}
              returnKeyType="next"
              autoCorrect={false}
              autoCapitalize="none"
              value={subdomain}
              onChangeText={setSubdomain}
              onSubmitEditing={() => fetchSSO()}
              disabled={isProcessing || isFetching}
            />
            {!!error?.error && <Notification.Inline status="negative" message={error.error} />}
            <Box gap="lg">
              <Button
                text={I18n.t("SPSAML.LOGIN_CONTINUE")}
                onPress={() => fetchSSO()}
                loading={isProcessing || isFetching}
              />
              <Button
                kind="tertiary"
                text={I18n.t("SPSAML.SETUP_LINK")}
                onPress={() => Linking.openURL(DOCUMENTATION_URL)}
              />
            </Box>
          </Box>
        </Box>
      </KeyboardAvoidingView>
    </Screen.View>
  );
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
  logo: {
    height: 36,
    width: 130,
  },
});
