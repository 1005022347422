import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { TwoFactorScreensPropMap } from "~/go/navigation/Types";

import { onboardingSetupCompleteScreen } from "./OnboardingScreens";
import { useDefaultScreenOptions } from "./Options";
import { twoFactorScreens } from "./TwoFactorScreens";

const Stack = createNativeStackNavigator<TwoFactorScreensPropMap>();

const TwoFactorStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      {twoFactorScreens(Stack)}
      {onboardingSetupCompleteScreen(Stack)}
    </Stack.Navigator>
  );
};

export default TwoFactorStack;
