import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { getHideMessageBanner } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import SlideUpContainer from "~/shared/components/SlideUpContainer";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import ListRow from "~/shared/rows/ListRow";

interface MessageBannerProps {
  onPress: () => void;
  message: string;
}

function MessageBanner({ onPress, message }: MessageBannerProps) {
  const shouldHideMessageBanner = useAppSelector(getHideMessageBanner);
  const actions = useActions();

  if (shouldHideMessageBanner) {
    return null;
  }

  const chevronRight = <MerakiIcon name="chevron-right" size="s" color={MkiColors.primaryButton} />;

  const onPressBanner = () => {
    actions.hideMessageBanner();
    onPress();
  };

  return (
    <SlideUpContainer testID={"MESSAGE_BANNER"} screenStyles={styles.containerStyle}>
      <ListRow
        onPress={onPressBanner}
        rowStyles={styles.rowStyle}
        accessory={chevronRight}
        underlayColor={MkiColors.primaryButtonLight}
      >
        <MkiText textStyle="activeTab">{message}</MkiText>
      </ListRow>
    </SlideUpContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderTopColor: MkiColors.goPurple,
    borderTopWidth: SPACING.tiny,
  },
  rowStyle: {
    backgroundColor: MkiColors.primaryButtonLight,
    paddingHorizontal: SPACING.default,
  },
});

export default MessageBanner;
