import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { appSelect } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";

interface SummaryListHeading {
  testID?: string;
  children?: React.ReactNode;
  headingContainerStyles?: StyleProp<ViewStyle>;
  headingTextStyle?: StyleProp<TextStyle>;
  subheading?: React.ReactElement | null;
  disableHeadingTop?: boolean;
}

const SummaryListHeading = ({
  children,
  headingContainerStyles,
  headingTextStyle,
  subheading,
  disableHeadingTop,
}: SummaryListHeading) => {
  const headingStyle = appSelect({
    go: "subheading",
    enterprise: "subheader",
  } as const);

  const containerStyle = [styles.heading, headingContainerStyles];

  if (!disableHeadingTop) {
    containerStyle.push(styles.headingTopMargin);
  }

  return (
    <View style={containerStyle}>
      <MkiText textStyle={headingStyle} screenStyles={headingTextStyle}>
        {children}
      </MkiText>
      {subheading}
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    marginLeft: 16,
  },
  headingTopMargin: {
    marginTop: 16,
  },
});

export default SummaryListHeading;
