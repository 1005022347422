import { Box } from "@meraki/magnetic/layout";
import { forwardRef } from "react";

import { Text } from "../Text/Text";
import { BottomSheetMethods, BottomSheetProps } from "./core/types";

function Noop() {
  if (__DEV__) {
    return (
      <Box>
        <Text>Bottom Sheet is not currently supported on web!</Text>
      </Box>
    );
  }

  return null;
}

type BottomSheetType = React.ForwardRefExoticComponent<
  BottomSheetProps & React.RefAttributes<BottomSheetMethods>
> & {
  Header: typeof Noop;
  Content: typeof Noop;
  ScrollView: typeof Noop;
  FlashList: typeof Noop;
  Modal: typeof Noop;
};

// NOTE: This component is not supported on web. DM-2728
export const BottomSheet: BottomSheetType = Object.assign(
  forwardRef<BottomSheetMethods, BottomSheetProps>(function BottomSheet(_: BottomSheetProps, __) {
    return <Noop />;
  }),
  {
    Header: Noop,
    Content: Noop,
    ScrollView: Noop,
    FlashList: Noop,
    Modal: Noop,
  },
);
