import { omit } from "lodash";
import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";
export const ClientsOverviewSchema = z
  .object({
    counts: z.object({
      total: z.number(),
      withHeavyUsage: z.number(),
    }),
    usages: z.object({
      average: z.number(),
      withHeavyUsageAverage: z.number(),
    }),
  })
  .describe("ClientsOverviewSchema");

export type ClientsOverview = z.infer<typeof ClientsOverviewSchema>;
interface ClientsOverviewRequest {
  networkId?: string;
  timespan?: number;
  t0?: string;
  t1?: string;
}

const buildQueryUrl = ({ networkId }: ClientsOverviewRequest) => {
  return `/api/v1/networks/${networkId}/clients/overview`;
};

export const fetchClientsOverview = ({ networkId, ...queryParams }: ClientsOverviewRequest) => {
  const { t0, t1 } = queryParams;
  const resolvedQueryParams =
    t0 && t1 ? omit(queryParams, "timespan") : omit(queryParams, ["t0", "t1"]);

  return request(ClientsOverviewSchema, "GET", buildQueryUrl({ networkId }), {
    queryParams: resolvedQueryParams,
  });
};

export const useClientsOverview = createQuery<ClientsOverviewRequest, ClientsOverview>({
  baseQueryKey: buildQueryUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchClientsOverview(variables),
  requiredVariables: ["networkId"],
});
