import * as updatedDevConfig from "./dev-config";
import * as defaultDevConfig from "./dev-config.default";

export const config = {
  ...defaultDevConfig.config,
  ...updatedDevConfig?.config,
};

export const FEATURE_FLAGS = {
  ...defaultDevConfig.FEATURE_FLAGS,
  ...updatedDevConfig?.FEATURE_FLAGS,
};
