import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useGetAllIpsksForSsid } from "~/api/queries/ssids/useIdentityPsk";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { NESTED_TABLE_MODAL_ROWTYPE } from "~/constants/RowConstants";
import ConfigHeader from "~/go/components/ConfigHeader";
import EditableSsidNameHeader from "~/go/components/EditableSsidNameHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import NestedTableModalRow from "~/go/rows/NestedTableModalRow";
import { SSIDIdentityPskRow } from "~/go/rows/SSIDIdentityPskRow";
import SSIDPasswordField from "~/go/rows/SSIDPasswordField";
import { showAlert, showNetworkErrorWithRetry, showSaveWarning } from "~/lib/AlertUtils";
import { supportsIpsk, validatePSK } from "~/lib/SSIDUtils";
import { productTypeDevicesSelector, ssidsForCurrentNetwork } from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import { GoStatus } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import { SSIDAuthMode } from "~/shared/types/Models";
import { ProductType } from "~/shared/types/Networks";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDPassword">;

const SSIDPasswordScreen = (props: Props) => {
  const { ssidNumber } = props;
  const actions = useActions();
  const navigation = useNavigation<Props["navigation"]>();
  const networkId = useCurrentNetworkId();
  const ssid = useAppSelector(ssidsForCurrentNetwork)[ssidNumber];
  // move to selector folder
  const broadcastingDevices = useAppSelector((state) => {
    const { availabilityTags, availableOnAllAps } = ssid;
    let accessPoints = productTypeDevicesSelector(state, ProductType.wireless);
    if (!availableOnAllAps) {
      accessPoints = accessPoints.filter((device) => device.tags?.includes(availabilityTags[0]));
    }
    return accessPoints;
  });

  const isIpskSupported = supportsIpsk(broadcastingDevices);
  // UDG-3515: Verify and update ssidNumber to be a number
  // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const allIpsksQuery = useGetAllIpsksForSsid(ssidNumber);
  const { authMode, psk } = ssid;

  const [reqPending, setReqPending] = useState(false);
  const [pskValue, setPskValue] = useState<string>(psk ? psk : "");
  const [authModeValue, setAuthModeValue] = useState<
    SSIDAuthMode.open | SSIDAuthMode.psk | SSIDAuthMode.ipsk
  >(authMode);
  const [bannerAlert, setBannerAlert] = useState<string>("");

  const validateIpsk = () => {
    if (authModeValue === SSIDAuthMode.ipsk && !isIpskSupported) {
      setBannerAlert(
        I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.INVALID_BROADCAST"),
      );
      setAuthModeValue(SSIDAuthMode.open);
    }
  };

  useEffect(() => {
    validateIpsk();
  });

  const saveChanges = useCallback(async () => {
    const error = authModeValue === SSIDAuthMode.psk ? validatePSK(pskValue) : null;

    if (error) {
      showAlert(
        I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_INVALID"),
        error,
      );
      return;
    }

    if (authModeValue === SSIDAuthMode.ipsk && !allIpsksQuery.data?.length) {
      showAlert(
        I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.CONFIG_INVALID"),
        I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.NO_IPSKS"),
      );
      return;
    }

    setReqPending(true);

    try {
      await actions.setSsid(networkId, {
        number: ssid.number,
        authMode: authModeValue,
        ...(authModeValue === SSIDAuthMode.psk ? { psk: pskValue, encryptionMode: "wpa" } : {}),
      });
    } catch {
      showNetworkErrorWithRetry(saveChanges);
      return;
    } finally {
      setReqPending(false);
    }

    navigation.goBack();
  }, [
    actions,
    allIpsksQuery.data?.length,
    authModeValue,
    navigation,
    networkId,
    pskValue,
    ssid.number,
  ]);

  useLayoutEffect(() => {
    const shouldShowSaveWarning =
      authMode != authModeValue || (authMode === SSIDAuthMode.psk && psk != pskValue);

    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (shouldShowSaveWarning) {
              showSaveWarning(saveChanges, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={saveChanges} />,
    });
  }, [authMode, authModeValue, navigation, psk, pskValue, saveChanges]);

  return (
    <FullScreenContainerView withScroll>
      <EditableSsidNameHeader title={ssid.name} entity="network" enabled={ssid.enabled} />
      <ConfigHeader
        title={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.TITLE")}
        description={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.SUBTITLE")}
      />
      {bannerAlert != "" && (
        <BannerAlert
          alertType={GoStatus.warning}
          alertText={bannerAlert}
          screenStyles={styles.warningBanner}
          testID="INVALID_BROADCAST_BANNER"
        />
      )}
      <NestedTableModalRow
        onPress={() => setAuthModeValue(SSIDAuthMode.open)}
        rowType={
          authModeValue === SSIDAuthMode.open ? NESTED_TABLE_MODAL_ROWTYPE.isSelected : undefined
        }
        testID="SSID_PASSWORD.OPEN"
      >
        {I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.OPEN_LABEL")}
      </NestedTableModalRow>
      <View style={styles.rowBottom} />
      <NestedTableModalRow
        onPress={() => setAuthModeValue(SSIDAuthMode.psk)}
        rowType={
          authModeValue === SSIDAuthMode.psk ? NESTED_TABLE_MODAL_ROWTYPE.isSelected : undefined
        }
        testID="SSID_PASSWORD.PASSWORD"
      >
        <SSIDPasswordField
          styles={styles.rowSpacing}
          psk={pskValue}
          onChangeText={(newPsk) => setPskValue(newPsk)}
          onSubmitEditing={saveChanges}
        />
      </NestedTableModalRow>
      <View style={styles.rowBottom} />
      <NestedTableModalRow
        onPress={
          isIpskSupported
            ? () => setAuthModeValue(SSIDAuthMode.ipsk)
            : () =>
                setBannerAlert(
                  I18n.t(
                    "SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.INVALID_BROADCAST",
                  ),
                )
        }
        rowType={
          authModeValue === SSIDAuthMode.ipsk ? NESTED_TABLE_MODAL_ROWTYPE.isSelected : undefined
        }
        testID={`SSID_PASSWORD.IPSK${!isIpskSupported ? "_DISABLED" : ""}`}
      >
        <SSIDIdentityPskRow
          selected={authModeValue === SSIDAuthMode.ipsk}
          disabled={!isIpskSupported}
          ipskValues={allIpsksQuery.data}
          ssidNumber={ssidNumber}
          loading={allIpsksQuery.isLoading}
        />
      </NestedTableModalRow>
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  rowSpacing: {
    marginVertical: SPACING.tiny,
  },
  warningBanner: {
    margin: SPACING.default,
    width: undefined,
  },
  rowBottom: {
    marginHorizontal: SPACING.default,
    borderBottomColor: MkiColors.bottomBorderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default SSIDPasswordScreen;
