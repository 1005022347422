import {
  ApplicationRule,
  SecurityTrafficShapingSettings,
  WebBlockingRule,
} from "~/go/types/NetworksTypes";
import { Rule } from "~/shared/types/ApplianceTypes";
import { VlanResponse } from "~/shared/types/Vlans";

// TODO: remove "environmental" type once issue with this endpoint is resolved:
// /api/v1/organizations/${orgId}/networks
export enum ProductType {
  appliance = "appliance",
  wireless = "wireless",
  switch = "switch",
  phone = "phone",
  camera = "camera",
  environmental = "environmental",
  sensor = "sensor",
  cellularGateway = "cellularGateway",
  systemsManager = "systemsManager",
}
export interface NetworkSettings {
  localStatusPageEnabled?: boolean;
  remoteStatusPageEnabled?: boolean;
  secureConnect?: {
    enabled: boolean;
  };
  localStatusPage?: {
    authentication: {
      enabled?: boolean;
      username?: string;
      password?: string | null;
    };
  };
}

export interface Network {
  defaultRulesEnabled: boolean;
  id: string;
  name: string;
  status: string;
  can_read: boolean;
  can_write: boolean;
  config_template_ng_id?: string;
  eid: string;
  enrollment_string?: string;
  has_cellular_gateway: boolean;
  has_pcc: boolean;
  has_phone: boolean;
  has_sensor: boolean;
  has_switch: boolean;
  has_vm_concentrator: boolean;
  has_wired: boolean;
  has_wireless: boolean;
  is_admin: boolean;
  is_config_template: boolean;
  is_virtual: boolean;
  locale_id: string;
  n: string;
  network_tags?: string;
  network_type: string;
  node_group_ctime: number;
  node_group_mtime: number;
  t: string;
  time_zone: string;
  trafficShapingRules?: ApplicationRule[];
  trafficShapingSettings?: SecurityTrafficShapingSettings;
  webBlockingRules?: WebBlockingRule[];
  dhcpSubnets: {
    [id: number]: DHCPSubnet;
  };
  vlans: VlanResponse[];
  organizationId: string;
  l3FirewallRules: Rule[];
  settings: NetworkSettings;
}

export interface NetworksState {
  [id: string]: Network;
}

export interface Networks {
  [id: string]: Network;
}

export enum URLPrepends {
  portal = "portal",
  shard = "shard",
  api = "api",
  documentation = "documentation",
  community = "community",
  lspWired = "lspWired",
  lspSwitch = "lspSwitch",
  lspWireless = "lspWireless",
  lspAny = "lspAny",
  marketo = "marketo",
  umbrella = "umbrella",
}

export interface DHCPSubnet {
  subnet: string;
  vlanId: number;
  usedCount: number;
  freeCount: number;
}

export interface NetworkTypesWithId {
  hasPcc?: string;
  hasPhone?: string;
  hasCamera?: string;
  hasSwitch?: string;
  hasWired?: string;
  hasWireless?: string;
  hasSensor?: string;
}

export type NetworkHealthData = {
  entries: number[][];
  t0: number;
  t1: number;
};
