import { Loader } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";

import { PortInfoCard } from "../../../components/PortInfoCard";
import { useAppliancePortDetails } from "../AppliancePortDetailsContext";

export const AppliancePortDetailsTab = () => {
  const { appliancePort, device } = useAppliancePortDetails();

  if (!device || !appliancePort) {
    return (
      <Screen testID="DETAILS_TAB">
        <Loader.Spinner testID="DETAILS_LOADING_SPINNER" />
      </Screen>
    );
  }

  return (
    <Screen addDefaultPadding testID="DETAILS_TAB">
      <Box paddingBottom="sm" gap="sm">
        <PortInfoCard portInfo={appliancePort} />
      </Box>
    </Screen>
  );
};
