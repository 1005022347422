import { PureComponent } from "react";
import { StyleSheet } from "react-native";

import { ApiResponseAction } from "~/middleware/api";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import GeneralStatus from "~/shared/constants/Status";

type Props = {
  title: string;
  entity: string;
  save?: () => Promise<ApiResponseAction<void>>;
  enabled?: boolean;
  connection?: "wireless" | "wired";
  showStatusIcon?: boolean;
};

export default class EditableSsidNameHeader extends PureComponent<Props> {
  static defaultProps = {
    showStatusIcon: true,
    save: null,
    enabled: false,
    connection: "wireless",
  };

  render() {
    const { enabled } = this.props;
    // TODO: Handle wired connection state where this.props.connection !== "wireless"
    const gradientIcon = {
      icon: MerakiIcon,
      name: "wifi",
      size: "l",
      hasGradient: !!enabled,
    } as const;
    const statusIcon = (
      <StatusIcon
        status={enabled ? GeneralStatus.good : GeneralStatus.dormant}
        customIcon={gradientIcon}
        screenStyles={styles.connection}
      />
    );

    return <EditableNameHeader icon={statusIcon} shouldFlex testID="SSID_NAME" {...this.props} />;
  }
}

const styles = StyleSheet.create({
  connection: {
    marginRight: 6,
    marginLeft: 6,
  },
});
