import { useTheme } from "@meraki/core/theme";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { platformSelect } from "~/lib/PlatformUtils";
import { defineStyleKey, themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

type SectionListHeaderProps = {
  heading?: string;
  icon: string;
  onPress?: () => void;
  context?: string;
  withHorizontalMargin?: boolean;
  actionText?: string;
  testID?: string;
};

export const SectionListHeader = (props: SectionListHeaderProps) => {
  const { heading, actionText, onPress, testID, context, withHorizontalMargin, icon } = props;
  let actionButton;

  const { theme } = useTheme();

  if (onPress) {
    const buttonTestID = "SECTION_LIST_HEADER_BUTTON";

    actionButton = actionText ? (
      <Touchable transparentBackground onPress={onPress}>
        <MkiText textStyle="label" screenStyles={styles.actionText}>
          {actionText}
        </MkiText>
      </Touchable>
    ) : (
      <MerakiIcon
        name={icon}
        size="s"
        color={MkiColors.primaryButton}
        onPress={onPress}
        testID={testID ? buttonTestID.concat(`.${testID}`) : buttonTestID}
      />
    );
  }
  const containerStyles: StyleProp<ViewStyle>[] = withHorizontalMargin
    ? [styles.container, styles.withHorizontalMargin]
    : [styles.container];

  containerStyles.push(
    defineStyleKey("borderBottomColor", themeColors(theme).border?.strongBorderColor),
  );
  return (
    <View style={containerStyles}>
      {context && (
        <View style={styles.horizontalContainer}>
          <MkiText textStyle="label" screenStyles={styles.headingStyle}>
            {heading}
          </MkiText>
          <ContextHelp context={context} />
        </View>
      )}
      {!context && (
        <MkiText textStyle="label" screenStyles={styles.headingStyle}>
          {heading}
        </MkiText>
      )}
      {actionButton}
    </View>
  );
};

SectionListHeader.defaultProps = {
  icon: "add",
  onPress: undefined,
  withHorizontalMargin: false,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: SPACING.small,
    marginTop: SPACING.large,
    marginBottom: SPACING.small,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
  },
  headingStyle: { flex: 1 },
  withHorizontalMargin: {
    marginHorizontal: SPACING.default,
  },
  actionText: {
    color: MkiColors.primaryButton,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ ios: { marginVertical: number;... Remove this comment to see the full error message
    ...platformSelect({
      ios: {
        marginVertical: -SPACING.small,
      },
      web: {
        flex: 1,
      },
    }),
  },
});

export default SectionListHeader;
