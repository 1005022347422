import { Alert } from "@meraki/magnetic/icons";
import { useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface InfoBannerProps {
  alertText: string;
  expandedText: string;
  screenStyles: StyleProp<ViewStyle>;
  testID: string;
  isOpen?: boolean;
  textStyle?: TEXT_STYLE_NAME;
}

const InfoBannerExpandable = (props: InfoBannerProps) => {
  const { alertText, expandedText, screenStyles, testID, isOpen, textStyle } = props;
  const [isBannerExpanded, setIsBannerExpanded] = useState(!!isOpen);

  const themecolors = useThemeColors().iconBanner?.informational;
  const backgroundColor = { backgroundColor: themecolors?.backgroundColor };
  const iconColor = { color: themecolors?.color };
  const iconName = isBannerExpanded ? "expand-less" : "expand-more";

  const alertTextStyle = textStyle ? textStyle : "default";

  return (
    <View testID={testID} style={[styles.infoBannerContainer, backgroundColor, screenStyles]}>
      <Alert status="informational" size={20} testID="INFO_ICON" />
      <View style={styles.textContainer}>
        <MkiText textStyle={alertTextStyle} screenStyles={styles.textStyling}>
          {alertText}
        </MkiText>
        {isBannerExpanded && (
          <MkiText
            textStyle={alertTextStyle}
            screenStyles={styles.textStyling}
            testID="EXPANDED_TEXT"
          >
            {expandedText}
          </MkiText>
        )}
      </View>
      <MerakiIcon
        name={iconName}
        size="s"
        style={iconColor}
        onPress={() => setIsBannerExpanded(!isBannerExpanded)}
        testID={iconName.toUpperCase()}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  infoBannerContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingVertical: SPACING.small,
    paddingHorizontal: SPACING.medium,
    borderRadius: BUTTON_SIZING.borderRadius.default,
  },
  textContainer: {
    flex: 1,
  },
  textStyling: {
    textAlign: "left",
    paddingHorizontal: SPACING.medium,
  },
});

export default InfoBannerExpandable;
