import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import {
  AddVlanScreen,
  DHCPOptionScreen,
  DHCPServerScreen,
  DnsServerScreen,
  EditVlanScreen,
  ReservedAddressesListScreen,
  ReserveDeviceScreen,
  ReserveIpAddressScreen,
  VlanDetailsScreen,
} from "@meraki/go/vlan";
import { Button } from "@meraki/magnetic/components";
import { ModalScreenOptions } from "@meraki/shared/navigation";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function VlanGroup<T extends VlanGroupProps>(Stack: StackType<T>) {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  return (
    <>
      <Stack.Screen
        name="DHCPOption"
        component={DHCPOptionScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("DHCP_OPTION.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="ReserveDevice"
        component={ReserveDeviceScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("DEVICE_RESERVATIONS.GOv3.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen name="DnsServer" component={DnsServerScreen} />
      <Stack.Screen
        name="AddVlan"
        component={AddVlanScreen}
        options={{
          presentation: "modal",
          animation: "slide_from_bottom",
          headerTitle: I18n.t("CONFIGURE.CREATE.VLAN.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="DHCPServer"
        component={DHCPServerScreen}
        options={{ headerShadowVisible: false }}
        //TO DO: Add header when UDG-3918 merges
      />
      <Stack.Screen name="EditVlan" component={EditVlanScreen} />
      <Stack.Screen
        name="ReserveIpAddress"
        component={ReserveIpAddressScreen}
        options={{
          presentation: "modal",
          animation: "slide_from_bottom",
          headerTitle: I18n.t("RESERVE_IP_RANGE.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen name="ReservedAddressesList" component={ReservedAddressesListScreen} />
      <Stack.Screen name="Vlan" component={VlanDetailsScreen} />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "CONFIGURE.CREATE.VLAN.TITLE",
    tab: "ConfigureTab",
    screen: "AddVlan",
  },
  ["network", "vlan"],
);
