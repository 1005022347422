import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export interface WirelessLatencyRequest {
  networkId?: string;
}

export interface WirelessLatencyRequestParams extends WirelessLatencyRequest {
  timespan?: number;
}

export const TrafficSchema = z.object({
  avg: z.number(),
  rawDistribution: z.object({
    0: z.number(),
    1: z.number(),
    2: z.number(),
    4: z.number(),
    8: z.number(),
    16: z.number(),
    32: z.number(),
    64: z.number(),
    128: z.number(),
    256: z.number(),
    512: z.number(),
    1024: z.number(),
    2048: z.number(),
  }),
});

export const WirelessLatencySchema = z.object({
  backgroundTraffic: TrafficSchema,
  bestEffortTraffic: TrafficSchema,
  videoTraffic: TrafficSchema,
  voiceTraffic: TrafficSchema,
});

export type WirelessLatencyResponse = z.infer<typeof WirelessLatencySchema>;

const buildUrl = ({ networkId }: WirelessLatencyRequest) => {
  return `/api/v1/networks/${networkId}/wireless/latencyStats`;
};

const fetchWirelessLatency = ({
  networkId,
  timespan,
}: WirelessLatencyRequestParams): Promise<WirelessLatencyResponse> => {
  return request(WirelessLatencySchema, "GET", buildUrl({ networkId }), {
    queryParams: { timespan },
  });
};

export const useWirelessLatency = createQuery<
  WirelessLatencyRequestParams,
  WirelessLatencyResponse
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: fetchWirelessLatency,
  requiredVariables: ["networkId"],
});
