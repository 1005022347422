import { AuthTypes, LoginContext } from "@meraki/shared/api";
import {
  CSRF_TOKEN_SUCCESS,
  DELETE_ORGANIZATION_SUCCESS,
  FINISHED_ADDITIONAL_LOGIN_REQUESTS,
  GET_LOGIN_SECURITY_SUCCESS,
  GET_TWO_FACTOR_SECRET_SUCCESS,
  IDLE_TIMEOUT_SESSION_EXPIRED,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  ORG_CHOSEN,
  OTP_AUTH_FAILURE,
  OTP_AUTH_REQUEST,
  OTP_AUTH_RETRY_SUCCESS,
  OTP_AUTH_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
  REQUEST_PASSWORD_RESET_REQUEST,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_IDLE_TIMEOUT_SESSION_EXPIRED,
  RESET_LOGIN_STATE,
  RESET_UPDATE_REQUIRED,
  SET_LOGIN_SECURITY_SUCCESS,
  SET_SSO_IN_PROGRESS,
  SET_UPDATE_REQUIRED,
  SHOW_CAPTCHA,
  SMS_AUTH_RETRY_SUCCESS,
  STORE_TFA_REMEMBER_ME,
  TWO_FACTOR_FAILURE,
  TWO_FACTOR_REQUEST,
  TWO_FACTOR_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { LoginOrg, LoginSecurityByOrg } from "~/shared/types/AuthTypes";

export interface AuthReduxState {
  isFetching: boolean;
  isResettingPassword: boolean;
  isSSOInProgress: boolean;
  idleTimeoutSessionExpired: boolean;
  isAuthenticated: boolean;
  showCaptcha: boolean;
  errorMessage: string;
  resetTwoFactor: boolean;
  twoFactorSecret: string | null;
  twoFactorUsername: string | null;
  lastTwoDigitOfBackup: string | null;
  smsBackup: string | null;
  smsPrimary: string | null;
  smsSecondary: string | null;
  updateRequired: boolean;
  requiresTwoFactor: boolean;
  loginOrgs: LoginOrg[];
  loginSecurity: LoginSecurityByOrg;
  rememberMe: boolean;
  rememberMeTimestamp: number;
  loginContext?: LoginContext;
}

export const initialState: AuthReduxState = {
  isFetching: false,
  isSSOInProgress: false,
  isResettingPassword: false,
  idleTimeoutSessionExpired: false,
  isAuthenticated: false,
  resetTwoFactor: false,
  showCaptcha: false,
  errorMessage: "",
  twoFactorSecret: null,
  twoFactorUsername: null,
  lastTwoDigitOfBackup: null,
  smsBackup: null,
  smsPrimary: null,
  smsSecondary: null,
  updateRequired: false,
  requiresTwoFactor: false,
  loginOrgs: [],
  loginSecurity: {},
  rememberMe: false,
  rememberMeTimestamp: 0,
  loginContext: undefined,
};

const MONTH_IN_MS = 2592000000;

export default function auth(state: AuthReduxState = initialState, action: any) {
  const { type, response, meta } = action;

  switch (type) {
    case SET_LOGIN_SECURITY_SUCCESS:
    case GET_LOGIN_SECURITY_SUCCESS: {
      const { organizationId } = meta;
      const nextState = { ...state };
      const loginSecurity = { ...nextState.loginSecurity } || {};

      loginSecurity[organizationId] = response;
      nextState.loginSecurity = loginSecurity;

      return nextState;
    }
    case CSRF_TOKEN_SUCCESS:
      return {
        ...state,
        requiresTwoFactor:
          state.requiresTwoFactor || action?.response?.state?.destination === "sms_auth_config",
        errorMessage: action?.response?.state?.options?.error || "",
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
        showCaptcha: false,
      };
    case STORE_TFA_REMEMBER_ME:
      return { ...state, rememberMe: response, rememberMeTimestamp: response ? Date.now() : 0 };
    case OTP_AUTH_SUCCESS:
    case TWO_FACTOR_SUCCESS:
    case LOGIN_SUCCESS: {
      const newState = { ...state };
      const { orgs, data, primary, secondary, error } = response;
      const { mode }: { mode: AuthTypes & "org_choose" } = response;

      if (mode === "sms") {
        newState.smsPrimary = primary;
        newState.smsSecondary = secondary;
      }

      if (__MERAKI_GO__ && mode === "two_factor") {
        newState.smsBackup = data?.primary;
      }

      if (mode === "org_choose") {
        const isRemembered =
          state.rememberMe && state.rememberMeTimestamp + MONTH_IN_MS > Date.now();
        newState.requiresTwoFactor =
          !isRemembered && orgs.some((org: any) => org.two_factor_auth_enabled);
      }

      newState.loginOrgs = orgs;
      newState.errorMessage = error || "";
      newState.isFetching = false;

      return newState;
    }
    case ORG_CHOSEN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action?.error,
      };
    case OTP_AUTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      };
    case SHOW_CAPTCHA:
      return {
        ...state,
        showCaptcha: true,
      };
    case DELETE_ORGANIZATION_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        requiresTwoFactor: false,
        loginComplete: false,
        idleTimeoutSessionExpired: false,
      };
    case REQUEST_PASSWORD_RESET_REQUEST:
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isResettingPassword: true,
      };
    case REQUEST_PASSWORD_RESET_SUCCESS:
    case REQUEST_PASSWORD_RESET_FAILURE:
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isResettingPassword: false,
      };
    case GET_TWO_FACTOR_SECRET_SUCCESS:
      return {
        ...state,
        twoFactorSecret: response.secret,
        twoFactorUsername: response.auth_username,
        lastTwoDigitOfBackup: response.last_two_digits_primary,
      };
    case OTP_AUTH_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case TWO_FACTOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: "",
      };
    case OTP_AUTH_FAILURE:
    case TWO_FACTOR_FAILURE:
    case SMS_AUTH_RETRY_SUCCESS:
    case OTP_AUTH_RETRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: response?.error || "",
      };
    case "SET_RESET_TFA":
      return {
        ...state,
        resetTwoFactor: true,
      };
    case FINISHED_ADDITIONAL_LOGIN_REQUESTS:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_LOGIN_STATE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        requiresTwoFactor: false,
      };
    case SET_UPDATE_REQUIRED:
      return {
        ...state,
        updateRequired: true,
      };
    case IDLE_TIMEOUT_SESSION_EXPIRED:
      return {
        ...state,
        idleTimeoutSessionExpired: true,
      };
    case RESET_IDLE_TIMEOUT_SESSION_EXPIRED:
      return {
        ...state,
        idleTimeoutSessionExpired: false,
      };
    case RESET_UPDATE_REQUIRED:
      return {
        ...state,
        updateRequired: false,
      };
    case SET_SSO_IN_PROGRESS:
      return {
        ...state,
        isSSOInProgress: action.isInProgress,
      };
    case "STORE_VERIFY_EMAIL_CONTEXT":
      return {
        ...state,
        loginContext: action.context,
      };
    case "CLEAR_VERIFY_EMAIL_CONTEXT":
      return {
        ...state,
        loginContext: undefined,
      };
    case WIPE_REDUX:
      return {
        ...initialState,
        updateRequired: state.updateRequired,
        idleTimeoutSessionExpired: state.idleTimeoutSessionExpired,
        rememberMe: state.rememberMe,
        rememberMeTimestamp: state.rememberMeTimestamp,
      };
    default:
      return state;
  }
}
