import * as RebootControl from "../handlers/RebootControl";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useRebootControl(deviceId: string): LiveTool<boolean> {
  const msg = useLiveBroker<BaseDeviceSubProps, RebootControl.Message>({
    type: "RebootControl",
    deviceId,
  });

  return useCachedFormatter(msg, RebootControl.formatMsg);
}
