import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface AdminsJsonRequest {
  orgEid?: string;
}

export const StandardSchema = z.object({
  name: z.string(),
  uid: z.optional(z.string()),
  support_password: z.optional(z.string()),
});

export const AdminsJsonSchema = z
  .object({
    standard: z.array(z.optional(StandardSchema)),
  })
  .describe("AdminsJSONchema");

type Admin = z.infer<typeof AdminsJsonSchema>;
export type AdminJson = z.infer<typeof StandardSchema>;

function buildUrl({ orgEid }: AdminsJsonRequest) {
  return `/o/${orgEid}/manage/organization/admins.json`;
}

function fetchAdminsJson(variables: AdminsJsonRequest) {
  return request(AdminsJsonSchema, "GET", buildUrl(variables));
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useAdminJson = createQuery<AdminsJsonRequest, Admin>({
  baseQueryKey: buildUrl({ orgEid: "{orgEid}" }),
  queryFn: fetchAdminsJson,
});
