import { ColorToken } from "@meraki/magnetic/themes";

const GRAPH_COLORS = [
  "accent.a.base",
  "accent.b.base",
  "accent.c.base",
  "accent.d.base",
  "accent.e.base",
  "accent.f.base",
  "accent.g.base",
  "accent.h.base",
  "accent.i.base",
  "accent.j.base",
  "accent.k.base",
] as const;

const MAX = GRAPH_COLORS.length;

export const getIndexToGraphColorToken = (index: number): ColorToken => {
  return GRAPH_COLORS[index % MAX] || "dormant.icon.base";
};
