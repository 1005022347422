import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const IdentityPskInputSchema = z
  .object({
    name: z.string(),
    groupPolicyId: z.string().optional(),
    passphrase: z.string(),
    expiresAt: z.string().optional().nullable(),
  })
  .describe("IdentityPskInputSchema");

export const IdentityPskSchema = IdentityPskInputSchema.extend({
  id: z.string(),
}).describe("IdentityPskSchema");

export const IdentityPskResponseSchema = IdentityPskSchema.extend({
  wifiPersonalNetworkId: z.string().optional(),
  email: z.string().optional(),
}).describe("IdentityPskResponseSchema");

export const IdentityPsksResponseSchema = z
  .array(IdentityPskResponseSchema)
  .describe("IdentityPsksResponseSchema");

export const DeleteIdentityPskSchema = z.undefined().describe("DeleteIdentityPskSchema");

export type IdentityPskInput = z.infer<typeof IdentityPskInputSchema>;
export type IdentityPsk = z.infer<typeof IdentityPskSchema>;
export type IdentityPskResponse = z.infer<typeof IdentityPskResponseSchema>;
export type IdentityPsksResponse = z.infer<typeof IdentityPsksResponseSchema>;

export interface IdentityPskRequest {
  networkId?: string;
  ssidNumber?: string;
  id?: string;
}

export interface IdentityPskPostRequest extends IdentityPskRequest {
  body: IdentityPskInput;
}

export interface IdentityPskPutRequest extends IdentityPskRequest {
  body: Partial<IdentityPsk>;
}

function buildAllUrl({ networkId, ssidNumber }: IdentityPskRequest) {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks`;
}

export function fetchAllIdentityPsks(variables: IdentityPskRequest): Promise<IdentityPsksResponse> {
  return request(IdentityPsksResponseSchema, "GET", buildAllUrl(variables));
}

export function createIdentityPsk({
  body,
  ...variables
}: IdentityPskPostRequest): Promise<IdentityPskResponse> {
  if (body.expiresAt == null) {
    delete body.expiresAt;
  }

  return request(IdentityPskResponseSchema, "POST", buildAllUrl(variables), {
    body: JSON.stringify(body),
  });
}

export const useIdentityPsks = createQuery<IdentityPskRequest, IdentityPsksResponse>({
  baseQueryKey: buildUrl({ networkId: "{networkId}", ssidNumber: "{ssidNumber}" }),
  queryFn: (variables) => {
    return fetchAllIdentityPsks(variables);
  },
  requiredVariables: ["networkId", "ssidNumber"],
});

export const useCreateIdentiyPsk = createMutation<
  IdentityPskPostRequest,
  IdentityPskResponse,
  APIResponseError
>({
  baseMutationKey: buildAllUrl({
    networkId: "{networkId}",
    ssidNumber: "{ssidNumber}",
  }),
  mutationFn: (variables) => createIdentityPsk(variables),
});

function buildUrl({ networkId, ssidNumber, id }: IdentityPskRequest) {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks/${id}`;
}

export function fetchIdentityPsk(variables: IdentityPskRequest): Promise<IdentityPskResponse> {
  return request(IdentityPskResponseSchema, "GET", buildUrl(variables));
}

export function updateIdentityPsk({
  body,
  ...variables
}: IdentityPskPutRequest): Promise<IdentityPskResponse> {
  return request(IdentityPskResponseSchema, "PUT", buildUrl(variables), {
    body: JSON.stringify(body),
  });
}

export function deleteIdentityPsk(variables: IdentityPskRequest) {
  return request(IdentityPskResponseSchema, "DELETE", buildUrl(variables));
}

export const useIdentityPsk = createQuery<IdentityPskRequest, IdentityPskResponse>({
  baseQueryKey: buildUrl({
    networkId: "{networkId}",
    ssidNumber: "{ssidNumber}",
    id: "{id}",
  }),
  queryFn: (variables) => {
    return fetchIdentityPsk(variables);
  },
});

export const useUpdateIdentityPsk = createMutation<
  IdentityPskPutRequest,
  IdentityPskResponse,
  APIResponseError
>({
  baseMutationKey: buildUrl({
    networkId: "{networkId}",
    ssidNumber: "{ssidNumber}",
    id: "{id}",
  }),
  mutationFn: (variables) => updateIdentityPsk(variables),
});

export const useDeleteIdentityPsk = createMutation<
  IdentityPskRequest,
  IdentityPskResponse,
  APIResponseError
>({
  baseMutationKey: buildUrl({
    networkId: "{networkId}",
    ssidNumber: "{ssidNumber}",
    id: "{id}",
  }),
  mutationFn: (variables) => deleteIdentityPsk(variables),
});
