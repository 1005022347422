import z from "zod";

const SplashMethodsObject = {
  none: "None",
  clickThrough: "Click-through splash page",
  billing: "Billing",
  radiusMeraki: "Password-protected with Meraki RADIUS",
  radiusCustom: "Password-protected with custom RADIUS",
  activeDirectory: "Password-protected with Active Directory",
  ldap: "Password-protected with LDAP",
  sms: "SMS authentication",
  pcc: "Systems Manager Sentry",
  facebook: "Facebook Wi-Fi",
  oauth: "Google OAuth",
  sponsor: "Sponsored guest",
  ciscoISE: "Cisco ISE",
} as const;

export const SplashMethodsSchema = z.union([
  z.literal(SplashMethodsObject.none),
  z.literal(SplashMethodsObject.clickThrough),
  z.literal(SplashMethodsObject.billing),
  z.literal(SplashMethodsObject.radiusMeraki),
  z.literal(SplashMethodsObject.radiusCustom),
  z.literal(SplashMethodsObject.activeDirectory),
  z.literal(SplashMethodsObject.ldap),
  z.literal(SplashMethodsObject.sms),
  z.literal(SplashMethodsObject.pcc),
  z.literal(SplashMethodsObject.facebook),
  z.literal(SplashMethodsObject.oauth),
  z.literal(SplashMethodsObject.sponsor),
  z.literal(SplashMethodsObject.ciscoISE),
]);

export type SplashMethods = z.infer<typeof SplashMethodsSchema>;
