import { colorPalette } from "../colorPalette";
import { Theme } from "../types";
import { enterpriseDarkTheme } from "./darkTheme";

export const enterpriseAmoledTheme: Theme = {
  ...enterpriseDarkTheme,

  color: {
    ...enterpriseDarkTheme.color,

    default: {
      ...enterpriseDarkTheme.color.default,

      bg: {
        ...enterpriseDarkTheme.color.default.bg,

        base: colorPalette.neutral[0],
      },
    },
  },

  mode: "dark",
};
