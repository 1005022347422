import { CloakProvider } from "@meraki/core/cloak";
import * as errorMonitor from "@meraki/core/errors";
import {
  goAmoledTheme,
  goDarkTheme,
  goLegacyDarkTheme,
  goLegacyLightTheme,
  goLightTheme,
  MagneticThemeProvider,
  ThemeConfiguration,
} from "@meraki/magnetic/themes";
import { queryClient } from "@meraki/shared/api";
import { featureFlagClient } from "@meraki/shared/feature-flags";
import { QueryClientProvider } from "@tanstack/react-query";
import { useLayoutEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import { registerCometListeners } from "~/actions";
import { fetchRemoteConfig } from "~/actions/remoteConfig";
import { readInWhatsNew } from "~/actions/whatsNew";
import LoadingScreen from "~/enterprise/screens/LoadingScreen";
import { DETOX, isJestEnv, LAUNCH_DARKLY_KEY, SENTRY_DSN } from "~/env";
import { handleAppLink } from "~/go/navigation/Linking";
import RootStack from "~/go/navigation/RootStack";
import { FIREBASE_EVENTS, sendMilestoneEvent } from "~/lib/FirebaseUtils";
import { isNightly, isWeb } from "~/lib/PlatformUtils";
import { MagneticProvider } from "~/shared/components/providers/MagneticProvider";
import SecurityComponent from "~/shared/components/SecurityComponent";
import AppNavigationContainer from "~/shared/navigation/AppNavigationContainer";
import AppRegistryWrapper from "~/shared/navigation/AppRegistryWrapper";
import AppStatusBar from "~/shared/navigation/AppStatusBar";
import { initAppLinkListener } from "~/shared/navigation/Linking";
import { setupStorePersistor, store } from "~/store/configureStore";

import initApiMigration from "./api/initApiLibrary";

const THEME_CONFIGURATION: ThemeConfiguration = {
  light: goLegacyLightTheme,
  lightDeuteranomaly: goLightTheme,
  dark: goLegacyDarkTheme,
  darkDeuteranomaly: goDarkTheme,
  amoled: goAmoledTheme,
};

// Setup our migration to the new api library..
initApiMigration();

featureFlagClient.init({
  launchDarklyKey: LAUNCH_DARKLY_KEY,
});

if (!(isJestEnv || DETOX)) {
  errorMonitor.init({ dsn: SENTRY_DSN, tracesSampleRate: isNightly ? 1 : 0.5 });
}

const persistor = setupStorePersistor();

sendMilestoneEvent(FIREBASE_EVENTS.appLaunch);

AppRegistryWrapper(() => {
  const [initialized, setInitialized] = useState(false);

  useLayoutEffect(() => {
    if (isWeb()) {
      if (!initialized) {
        document.getElementById("loader-container")?.remove();
      }

      initAppLinkListener(handleAppLink);
    }
  }, [initialized]);

  return (
    <Provider store={store}>
      <PersistGate
        loading={<LoadingScreen devMessage={"Waiting on redux-persist"} />}
        persistor={persistor}
      >
        <CloakProvider>
          <MagneticThemeProvider themeConfiguration={THEME_CONFIGURATION}>
            <MagneticProvider>
              <AppStatusBar />
              <SecurityComponent>
                {!initialized ? (
                  <LoadingScreen
                    devMessage={"Waiting on startup init actions"}
                    actions={[fetchRemoteConfig, registerCometListeners, readInWhatsNew]}
                    execParallelActions
                    onFinishLoad={() => setInitialized(true)}
                  />
                ) : (
                  <QueryClientProvider client={queryClient}>
                    <AppNavigationContainer
                      documentTitle={{
                        formatter: (options, route) => {
                          const tabTitle = options?.title ?? route?.name;
                          return `Meraki Go${tabTitle ? ` - ${tabTitle}` : ""}`;
                        },
                      }}
                      handlePendingAppLink
                    >
                      <RootStack />
                    </AppNavigationContainer>
                  </QueryClientProvider>
                )}
              </SecurityComponent>
            </MagneticProvider>
          </MagneticThemeProvider>
        </CloakProvider>
      </PersistGate>
    </Provider>
  );
});
