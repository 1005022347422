import {
  ADMINS_FAILURE,
  ADMINS_REQUEST,
  ADMINS_SUCCESS,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DISABLE_CLIENT_VPN_AUTH_SUCCESS,
  ENABLE_CLIENT_VPN_AUTH_SUCCESS,
  GET_AUTH_USERS_SUCCESS,
  SET_ADMIN_FAILURE,
  SET_ADMIN_REQUEST,
  SET_ADMIN_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { omit } from "lodash";

import { AdminsItems, Authorization, AuthorizationsByEmail } from "~/shared/types/AdminTypes";

interface AdminsReduxState {
  isFetching: boolean;
  isSaving: boolean;
  items?: AdminsItems;
  authorizations: AuthorizationsByEmail;
}

const initialState: AdminsReduxState = {
  isFetching: false,
  isSaving: false,
  items: {},
  authorizations: {},
};

function parseAuthUserFromResponse(authUserResponse: any): Authorization[] {
  return authUserResponse.authorizations.map((auth: any) => {
    return {
      id: authUserResponse.id,
      type: authUserResponse.accountType,
      ssidNumber: auth.ssidNumber,
      expiresAt: auth.expiresAt,
    };
  });
}

export default function admins(state: AdminsReduxState = initialState, action: any) {
  switch (action.type) {
    case ADMINS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADMINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.response.entities.admins,
      };
    case ADMINS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case SET_ADMIN_REQUEST:
    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        isSaving: true,
      };
    case SET_ADMIN_SUCCESS:
      return {
        ...state,
        isSaving: false,
        items: {
          ...state.items,
          [action.response.id]: action.response,
        },
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isSaving: false,
        items: omit(state.items, [action.meta.id]),
      };
    case SET_ADMIN_FAILURE:
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        isSaving: false,
      };
    case GET_AUTH_USERS_SUCCESS:
      return {
        ...state,
        authorizations:
          action?.response?.reduce((result: any, authUserResponse: any) => {
            result[authUserResponse.email] = parseAuthUserFromResponse(authUserResponse);
            return result;
          }, {}) ?? {},
      };
    case ENABLE_CLIENT_VPN_AUTH_SUCCESS:
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          [action.meta.email]: parseAuthUserFromResponse(action.response),
        },
      };
    case DISABLE_CLIENT_VPN_AUTH_SUCCESS:
      return {
        ...state,
        authorizations: omit(state.authorizations, action.meta.email),
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
