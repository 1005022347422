import { defaultTimespans, DefaultTimespansKeys } from "@meraki/shared/redux";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import {
  addTimespanToDate,
  getEndOfYesterdayAsDate,
  getOneYearAgoAsDate,
  subtractTimespanFromDate,
} from "~/lib/timeHelper";
import MkiDatePicker from "~/shared/components/MkiDatePicker";
import MkiText from "~/shared/components/MkiText";
import { DateRange } from "~/shared/types/TimeTypes";

export interface MkiDateRangePickerProps {
  dateRange: DateRange;
  comparisonTimespan?: DefaultTimespansKeys;
  onDateRangeChange: (dateRange: DateRange) => void;
  testID?: string;
}

class MkiDateRangePicker extends PureComponent<MkiDateRangePickerProps> {
  computeStartDateMaxDate = () => {
    const { dateRange, comparisonTimespan } = this.props;
    if (!comparisonTimespan) {
      return dateRange.endDate || getEndOfYesterdayAsDate();
    }
    return subtractTimespanFromDate(
      getEndOfYesterdayAsDate(),
      defaultTimespans[comparisonTimespan].value,
    );
  };

  onStartDateConfirm = (date: Date) => {
    const { dateRange, comparisonTimespan, onDateRangeChange } = this.props;
    if (!comparisonTimespan) {
      onDateRangeChange({ ...dateRange, startDate: date });
    } else {
      const startDate = date;
      const endDate = addTimespanToDate(startDate, defaultTimespans[comparisonTimespan].value);
      onDateRangeChange({ startDate, endDate });
    }
  };

  onEndDateConfirm = (date: Date) => {
    const { dateRange, onDateRangeChange } = this.props;
    onDateRangeChange({ ...dateRange, endDate: date });
  };

  render() {
    const { dateRange, comparisonTimespan, testID } = this.props;
    const endDateMinDate = dateRange.startDate || getOneYearAgoAsDate();
    const containerStyle = comparisonTimespan
      ? [styles.dateInputRowContainer, styles.addBottomBorder]
      : styles.dateInputRowContainer;
    return (
      <View style={containerStyle} testID={testID}>
        <View style={styles.leftContainer}>
          <MkiText textStyle="smallSecondary">{I18n.t("DATE_PICKER.START_DATE")}</MkiText>
          <MkiDatePicker
            date={dateRange.startDate}
            dateLabel={dateRange.startDate}
            minimumDate={getOneYearAgoAsDate()}
            maximumDate={this.computeStartDateMaxDate()}
            onConfirm={this.onStartDateConfirm}
            testID={`${testID} Start`}
          />
        </View>
        <View style={styles.rightContainer}>
          <MkiText textStyle="smallSecondary">{I18n.t("DATE_PICKER.END_DATE")}</MkiText>
          <MkiDatePicker
            date={dateRange.endDate}
            dateLabel={dateRange.endDate}
            minimumDate={endDateMinDate}
            maximumDate={getEndOfYesterdayAsDate()}
            onConfirm={this.onEndDateConfirm}
            disabled={comparisonTimespan !== undefined}
            testID={`${testID} End`}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  dateInputRowContainer: {
    flexDirection: "row",
    marginHorizontal: SPACING.default,
    marginVertical: SPACING.default,
  },
  addBottomBorder: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.bottomBorderColor,
    paddingBottom: SPACING.default,
  },
  leftContainer: {
    flex: 1,
    marginRight: SPACING.small,
  },
  rightContainer: {
    flex: 1,
    marginLeft: SPACING.small,
  },
});

export default MkiDateRangePicker;
