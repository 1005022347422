import { Box, ShiftBy } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { Slider as RNSlider } from "@react-native-assets/slider";
import { View } from "react-native";

import { Text } from "../Text/Text";

export type SliderProps = {
  minimumValue: number;
  maximumValue: number;
  value?: number;
  onValueChange?: (value: number) => void;
  valueFormatter?: (value: number) => number | string;
  step?: number;
  // We invert this because other components in magnetic use "disabled" vs "enabled" and we want to standardize it
  disabled?: boolean;
  testID?: string;
  hideTicks?: boolean;
};

const TRACK_HEIGHT = 8;
const THUMB_SIZE = 24;

function getMarkSkip(step: number) {
  if (step <= 1) return 5;
  return step;
}

export function Slider({
  minimumValue,
  maximumValue,
  step,
  disabled,
  hideTicks,
  valueFormatter,
  ...rest
}: SliderProps) {
  const theme = useMagneticTheme();

  return (
    <Box>
      <RNSlider
        {...rest}
        minimumValue={minimumValue}
        maximumValue={maximumValue}
        enabled={!disabled}
        step={step}
        minimumTrackTintColor={theme.color.control.bg.strong.base}
        maximumTrackTintColor={theme.color.default.border.medium.base}
        trackHeight={TRACK_HEIGHT}
        thumbSize={THUMB_SIZE}
        thumbTintColor={theme.color.inverse.icon.strong.base}
        thumbStyle={{ borderWidth: 2, borderColor: theme.color.control.border.strong.base }}
        CustomMark={({ value }) =>
          (value % getMarkSkip(step ?? 0) === 0 || value === maximumValue) &&
          (step ?? 0) > 0 &&
          hideTicks !== true ? (
            <View
              style={{
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: 4,
                  height: 4,
                  borderRadius: 4,
                  backgroundColor: theme.color.control.iconIn.strong.disabled,
                }}
              />
              <View style={{ position: "absolute", top: THUMB_SIZE / 2 + TRACK_HEIGHT / 2 }}>
                <Text>{valueFormatter?.(value) ?? value}</Text>
              </View>
            </View>
          ) : null
        }
      />
      {!step || step === 0 ? (
        <Box flexDirection="row" justifyContent="space-between">
          <ShiftBy x={-(THUMB_SIZE / 6)}>
            <Text>{valueFormatter?.(minimumValue) ?? minimumValue}</Text>
          </ShiftBy>
          <ShiftBy x={THUMB_SIZE / 2}>
            <Text>{valueFormatter?.(maximumValue) ?? maximumValue}</Text>
          </ShiftBy>
        </Box>
      ) : (
        <Box height={18} />
      )}
    </Box>
  );
}
