import {
  CONNECTIVITY_DATA_SUCCESS,
  DELETE_CONNECTIVITY_EVENTS,
  EVENT_DATA_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { isEqual, unionWith } from "lodash";

import { ClientEvent } from "~/shared/types/Client";
import { NetworkEvent } from "~/shared/types/NetworkEvent";

const initialState: NetworkEvent[] = [];

const events = (state = initialState, action: any) => {
  const { type, response, payload } = action;

  switch (type) {
    case EVENT_DATA_SUCCESS: {
      return unionWith(state, response.events, isEqual);
    }
    case CONNECTIVITY_DATA_SUCCESS: {
      response.events.forEach((thisEvent: ClientEvent) => {
        if (thisEvent.eventData == undefined || thisEvent.eventData.connected == undefined) {
          return;
        } else {
          thisEvent.eventData.connected = thisEvent.eventData?.connected == "true";
        }
      });
      const eventsList = response.events.filter(
        (thisEvent: ClientEvent) =>
          !(thisEvent.type == "client_connectivity" && thisEvent.clientDescription == null),
      );
      //replace (instead of unionWith) state everytime the tracked clients screen is accessed
      return [...eventsList];
    }
    case DELETE_CONNECTIVITY_EVENTS: {
      const eventsList = state.filter((thisEvent) => thisEvent.eventData.mac != payload.mac);
      return [...eventsList];
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default events;
