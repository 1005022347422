import { StyleSheet, ViewProps } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import MkiSwitch, { MkiSwitchProps } from "~/shared/components/MkiSwitch";
import ListRow, { ListRowProps } from "~/shared/rows/ListRow";

export type SwitchRowProps = {
  subtitle?: ListRowProps["subtitle1"];
  screenStyles?: ViewProps["style"];
} & Pick<ListRowProps, "children" | "testID" | "onPress"> &
  Pick<MkiSwitchProps, "value" | "onValueChange" | "disabled" | "context">;

export const SwitchRow = (props: SwitchRowProps) => {
  const {
    children,
    disabled = false,
    onValueChange,
    subtitle,
    testID,
    value = false,
    screenStyles,
    context,
    onPress,
  } = props;

  return (
    <ListRow
      rowStyles={[styles.rowStyles, screenStyles]}
      subtitle1={subtitle}
      rightStyle={styles.rightStyle}
      leftStyle={styles.leftStyle}
      testID={testID}
      onPress={onPress}
      accessory={
        <MkiSwitch
          value={value}
          onValueChange={onValueChange}
          disabled={disabled}
          testID={testID ? `${testID}.SWITCH` : testID}
          context={context}
        />
      }
    >
      {children}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyles: appSelect({
    enterprise: {
      height: 44,
      paddingHorizontal: SPACING.default,
    },
    go: {
      paddingVertical: SPACING.default,
      marginHorizontal: SPACING.default,
    },
  }),
  leftStyle: appSelect({
    enterprise: {},
    go: {
      width: "77%",
    },
  }),
  rightStyle: appSelect({
    enterprise: {},
    go: {
      width: "15%",
    },
  }),
});

export default SwitchRow;
