import { GET_NFOS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";
import { Reducer } from "redux";

import { OrganizationNFO } from "~/shared/types/Organizations";

type NfosState = Record<string, OrganizationNFO[]>;

const initialState = {};

const nfos: Reducer<NfosState> = (state = initialState, action) => {
  const { type, response, meta } = action;

  switch (type) {
    case GET_NFOS_SUCCESS:
      const { organizationId } = meta;
      const { features } = response;

      return {
        ...state,
        [organizationId]: features,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default nfos;
