export const TRAFFIC_SHAPING_USAGE_INCREMENTS = 9;

// .5 megabits
export const MINIMUM_LIMIT_VALUE = 500000;
export const MINIMUM_MEBIBITS_LIMIT_VALUE = 48;

export const TRAFFIC_SHAPING_LIMIT_VALUES = [
  MINIMUM_LIMIT_VALUE,
  1000000, // 1 megabit
  2500000, // 2.5 megabits
  5000000, // 5 megabits
  10000000, // 10 megabits
  20000000, // 20 megabits
  50000000, // 50 megabits
  100000000, // 100 megabits
  200000000, // 200 megabits
  300000000, // 300 megabits
];

export const TRAFFIC_SHAPING_MEBIBITS_INTEGERED: { [key: number]: number } = {
  [MINIMUM_MEBIBITS_LIMIT_VALUE]: 50000,
  97: 100000,
  244: 250000,
  488: 500000,
  976: 1000000,
  1953: 2000000,
  2441: 2500000,
  4882: 5000000,
  9765: 10000000,
  19531: 20000000,
  24414: 25000000,
  48828: 50000000,
  97656: 100000000,
  195312: 200000000,
  292968: 300000000,
};

export const DSCP_TAG_GROUP_FILTERS = ["ASSURED_FORWARDING", "CLASS_SELECTORS", "OTHER"] as const;

export type DSCP_TAG_GROUP_FILTERS_TYPE = (typeof DSCP_TAG_GROUP_FILTERS)[number];

export const DSCP_TAG_OPTIONS = [
  {
    value: null,
    tags: [],
  },
  {
    value: 10,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 12,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 14,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 18,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 20,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 22,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 26,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 28,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 30,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 34,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 36,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 38,
    tags: ["ASSURED_FORWARDING"],
  },
  {
    value: 0,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 8,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 16,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 24,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 32,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 40,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 48,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 56,
    tags: ["CLASS_SELECTORS"],
  },
  {
    value: 44,
    tags: ["OTHER"],
  },
  {
    value: 46,
    tags: ["OTHER"],
  },
];

export const PCP_TAG_OPTIONS = [null, 0, 1, 2, 3, 4, 5, 6, 7];

export const PRIORITY_TO_NUM = {
  high: 2,
  normal: 1,
  low: 0,
};
