import { browserName, fullBrowserVersion, osName, osVersion } from "react-device-detect";

const WebInfo = {
  getBuildNumber: () => `web-${process.env.build_number}`,
  getVersion: () => `${process.env.app_version}`,
  getSystemVersion: () => `${osVersion}/${fullBrowserVersion}`,
  getSystemName: () => `${osName}/${browserName}`,
  isLandscapeSync: () => false,
  getBundleId: () => "com.meraki.web",
  getUniqueId: () => "web",
};

export default WebInfo;
