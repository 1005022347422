import Svg, { Circle, G, Path } from "react-native-svg";

const SearchIcon = (props: any) => (
  <Svg width={75} height={76} viewBox="0 0 75 76" {...props}>
    <G fill="none" fillRule="evenodd">
      <G>
        <Circle fill="#6665F6" cx={37.5} cy={38} r={37.5} />
        <Circle fill="#FFF" cx={36} cy={37.9} r={21.1} />
        <Path
          d="M70.3,75.2 C69.3,75.2 68.4,74.8 67.6,74.1 L50.3,56.8 C48.8,55.3 48.8,52.9 50.3,51.4 C51.8,49.9 54.2,49.9 55.7,51.4 L73,68.7 C74.5,70.2 74.5,72.6 73,74.1 C72.3,74.8 71.3,75.2 70.3,75.2 Z"
          fill="#BFCAFF"
        />
        <Path
          d="M37.1,18.8 C47.7,18.8 56.3,27.4 56.3,38 C56.3,48.6 47.7,57.2 37.1,57.2 C26.5,57.2 17.9,48.6 17.9,38 C17.9,27.4 26.5,18.8 37.1,18.8 Z M37.1,13 C23.2,13 12,24.2 12,38.1 C12,52 23.2,63.1 37.1,63.1 C51,63.1 62.2,51.9 62.2,38 C62.2,24.1 50.9,13 37.1,13 Z"
          fill="#BFCAFF"
          fillRule="nonzero"
        />
      </G>
      <G transform="translate(24.000000, 24.000000)">
        <Path
          d="M26.4991282,2.74306839 C26.2680325,1.86321627 25.2858762,1.29390018 24.3037198,1.50092421 C23.4948852,1.65619224 22.9749201,2.22550832 22.8593723,2.95009242 L15.3487649,1.75970425 L15.3487649,1.65619224 C15.3487649,0.724584104 14.5399303,0 13.5,0 C12.4600697,0 11.6512351,0.724584104 11.6512351,1.65619224 L11.6512351,1.70794824 L4.14062772,2.89833641 C4.02507992,2.22550832 3.50511479,1.60443623 2.69628015,1.44916821 C1.7141238,1.24214418 0.674193548,1.81146026 0.500871839,2.69131238 C-0.192414996,5.43438078 -0.307962802,10.5064695 1.07861087,15.2162662 C2.92737576,21.5304991 7.02932287,25.8780037 12.8644871,27.896488 C12.8644871,27.896488 13.2111305,28 13.5,28 C13.7888695,28 14.1355129,27.896488 14.1355129,27.896488 C19.9706771,25.9297597 24.0148503,21.5304991 25.9213891,15.2162662 C27.3079628,10.5582255 27.192415,5.48613678 26.4991282,2.74306839 Z"
          fill="#6665F6"
        />
        <Path
          d="M16.9023256,13.5 L19.4139535,11.1976744 C20.1953488,10.4813953 20.1953488,9.25348837 19.4139535,8.5372093 C18.6325581,7.82093023 17.2930233,7.82093023 16.5116279,8.5372093 L14,10.8395349 L11.4883721,8.5372093 C10.7069767,7.82093023 9.36744186,7.82093023 8.58604651,8.5372093 C7.80465116,9.25348837 7.80465116,10.4813953 8.58604651,11.1976744 L11.0976744,13.5 L8.58604651,15.8023256 C7.80465116,16.5186047 7.80465116,17.7465116 8.58604651,18.4627907 C9.36744186,19.1790698 10.7069767,19.1790698 11.4883721,18.4627907 L14,16.1604651 L16.5116279,18.4627907 C17.2930233,19.1790698 18.6325581,19.1790698 19.4139535,18.4627907 C20.1953488,17.7465116 20.1953488,16.5186047 19.4139535,15.8023256 L16.9023256,13.5 Z"
          fill="#FFF"
        />
      </G>
    </G>
  </Svg>
);

export default SearchIcon;
