import { ProductType } from "@meraki/shared/api";
import { getProductType } from "@meraki/shared/devices";
import { launchLSP } from "@meraki/shared/links";
import { useNavigation } from "@react-navigation/native";
import { memo } from "react";

import LiveToolsButton from "~/go/components/liveTools/LiveToolsButton";
import TroubleshootingToolsCard from "~/go/components/TroubleshootingToolsCard";
import I18n from "~/i18n/i18n";
import { showActionSheet } from "~/lib/AlertUtils";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import Device_DeprecatedType from "~/shared/types/Device";

type Props = {
  device: Device_DeprecatedType;
};

const HardwareTroubleshootingTools = memo(function HardwareTroubleshootingTools(props: Props) {
  const { device } = props;

  const navigation = useNavigation();

  const showPing = () => {
    showActionSheet(
      [
        I18n.t("LIVE_TOOLS.TEST_CONNECTION_TO", { name: I18n.t("MERAKI_GO") }),
        I18n.t("LIVE_TOOLS.TEST_CONNECTION_TO", { name: I18n.t("LIVE_TOOLS.A_WEBSITE") }),
      ],
      (id: number) => {
        if (id === 0) {
          navigation.navigate("PingHost", {
            device,
          });
        } else if (id === 1) {
          navigation.navigate("PingWebsite", {
            device,
          });
        }
      },
      { title: I18n.t("LIVE_TOOLS.TEST_CONNECTION") },
    );
  };

  const showBlinkModal = () => {
    navigation.navigate("BlinkLED", {
      id: device.id,
      serialNumber: device.serial,
    });
  };

  const showRestartModal = () => {
    navigation.navigate("Restart", {
      device,
      customFilter: CUSTOM_FILTERS.DEVICE_CLIENTS(device.id),
    });
  };

  const launchTroubleshootingTools = () => {
    navigation.navigate("Troubleshooting", {
      device,
    });
  };

  return (
    <TroubleshootingToolsCard onMoreToolsPress={launchTroubleshootingTools}>
      <LiveToolsButton
        testID="TEST_CONNECTION_BUTTON"
        label={I18n.t("LIVE_TOOLS.TEST_CONNECTION")}
        name="global"
        onPress={showPing}
      />
      {device.lspConnected && (
        <LiveToolsButton
          testID="LSP_BUTTON"
          label={I18n.t("LIVE_TOOLS.LSP")}
          name="file-search"
          onPress={() => launchLSP(getProductType(device?.model) as ProductType)}
        />
      )}
      <LiveToolsButton
        testID="BLINK_LED_BUTTON"
        label={I18n.t("LIVE_TOOLS.BLINK_LED")}
        name="blink"
        onPress={showBlinkModal}
      />
      <LiveToolsButton
        testID="REBOOT_BUTTON"
        label={I18n.t("REBOOT")}
        name="reboot"
        onPress={showRestartModal}
      />
    </TroubleshootingToolsCard>
  );
});

export default HardwareTroubleshootingTools;
