import { Informational } from "./icons/Informational";
import { Negative } from "./icons/Negative";
import { Positive } from "./icons/Positive";

const ICON_MAP = {
  negative: Negative,
  positive: Positive,
  informational: Informational,
};

export interface EmptyProps {
  status: keyof typeof ICON_MAP;
  size?: number;
}

// Calculated by dividing 135 (default width) by 120 (default height)
const ICON_ASPECT_RATIO = 1.125;

export function Empty({ status, size }: EmptyProps) {
  const Icon = ICON_MAP[status];

  if (!Icon) return null;

  return <Icon width={size ? size * ICON_ASPECT_RATIO : 135} height={size ?? 120} />;
}
