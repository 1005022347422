import { I18n } from "@meraki/core/i18n";
import { DisplayableFailedConnection } from "@meraki/shared/navigation-type";
import { formatDistance } from "date-fns";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

import { appSelect } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

export interface FailedConnectionRowProps {
  displayableFailedConnection: DisplayableFailedConnection;
  onPress: (failedConnections: DisplayableFailedConnection) => void;
}

const SubText = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return <MkiText textStyle="smallSecondary">{children}</MkiText>;
};

const FailedConnectionRow = memo(
  ({ displayableFailedConnection, onPress }: FailedConnectionRowProps) => {
    if (!displayableFailedConnection || !onPress) {
      return null;
    }

    const { apName, clientName, failureStep, ssidName, ts } = displayableFailedConnection;
    const relativeTime = formatDistance(Date.parse(ts), Date.now(), { addSuffix: true });

    const reasonFormatted = I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.REASON", {
      reason: failureStep,
    });
    const ssidFormatted = appSelect({
      enterprise: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.SSID", {
        ssid: ssidName,
      }),
      go: ssidName,
    });
    const apFormatted = I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.AP", {
      ap: apName,
    });

    return (
      <SimpleDisclosureRow onPress={() => onPress(displayableFailedConnection)}>
        <MkiText>{clientName}</MkiText>
        <SubText>{reasonFormatted}</SubText>
        <SubText>{apFormatted}</SubText>
        <View style={styles.timeAndSSID}>
          <SubText>{ssidFormatted}</SubText>
          <SubText>{relativeTime}</SubText>
        </View>
      </SimpleDisclosureRow>
    );
  },
);

FailedConnectionRow.displayName = "FailedConnectRow";

export default FailedConnectionRow;

const styles = StyleSheet.create({
  timeAndSSID: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
