import { Loader } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { Platform } from "react-native";
import { WebView } from "react-native-webview";

export function OpenSourceLicensesScreen() {
  return (
    <Screen.View>
      <WebView
        startInLoadingState
        source={{ uri: "https://web.meraki-go.com/licenses" }}
        newWindow={Platform.OS === "web"}
        renderLoading={() => <Loader.Spinner animate />}
        testID="openSourceLicensesWebView"
      />
    </Screen.View>
  );
}
