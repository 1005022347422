import { StyleProp, StyleSheet, TextStyle, TouchableOpacity } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import MkiText from "~/shared/components/MkiText";

export interface TouchableTextProps {
  text: string;
  onPress: () => void;
  textStyle?: TEXT_STYLE_NAME;
  screenStyles?: StyleProp<TextStyle>;
  testID?: string;
}
export const TouchableText: React.FC<TouchableTextProps> = ({
  text,
  testID,
  onPress,
  screenStyles,
  textStyle = "small",
}) => {
  const component = (
    <TouchableOpacity testID={testID} onPress={onPress}>
      <MkiText textStyle={textStyle} screenStyles={[styles.touchableTextStyle, screenStyles]}>
        {text}
      </MkiText>
    </TouchableOpacity>
  );

  return component;
};

const styles = StyleSheet.create({
  touchableTextStyle: {
    backgroundColor: MkiColors.fullTransparent,
    color: MkiColors.primaryButton,
  },
});

export default TouchableText;
