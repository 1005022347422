import { Theme } from "@meraki/magnetic/themes";
import { hoursToMilliseconds } from "date-fns";
import z from "zod";

import { request } from "../../../api/request/request";
import { createInfiniteQuery } from "../../createInfiniteQuery";
import { createQuery } from "../../createQuery";

// Endpoint documentation: https://docs.ikarem.io/display/EngMVTeam/Motion+Events+-+Search+API
const AttributeColorSchema = z.union([
  z.literal("blackGray"),
  z.literal("whiteGray"),
  z.literal("red"),
  z.literal("orange"),
  z.literal("yellow"),
  z.literal("green"),
  z.literal("blue"),
  z.literal("purple"),
  z.literal("pink"),
  z.literal("brown"),
]);

const BaseMotionEventSchema = z.object({
  duration_ms: z.number(),
  has_object_maps: z.boolean(),
  has_people: z.boolean(),
  has_vehicles: z.boolean(),
  img_refresh_url: z.array(z.string()),
  img_url: z.array(z.string()),
  intensity_frame: z.number(),
  intensity_roi: z.number().nullable(),
  motion: z.record(z.array(z.number())),
  // Although the endpoint's query params are milliseconds, the response data timestamps are seconds.
  ts: z.number(),
  unconsolidated_motion: z.record(z.array(z.array(z.number()))).optional(),
});

export const AttributeSchema = z.object({
  object_class: z.union([z.literal("person"), z.literal("vehicle")]),
  object_id: z.string(),
  sub_attributes: z.number(),
  // People
  bottom: z.number().optional(),
  // People
  bottom_labels: z.array(AttributeColorSchema).optional(),
  // People
  top: z.number().optional(),
  // People
  top_labels: z.array(AttributeColorSchema).optional(),
  // Vehicles
  labels: z.array(AttributeColorSchema),
  // Vehicles
  type: z.number().optional(),
});

const MotionEventSchema = BaseMotionEventSchema.extend({
  subevents: z
    .array(
      BaseMotionEventSchema.pick({
        duration_ms: true,
        has_people: true,
        has_vehicles: true,
        img_refresh_url: true,
        img_url: true,
        motion: true,
        ts: true,
      }).extend({
        attributes: z.array(AttributeSchema),
        // Although the endpoint's query params are milliseconds, the response data timestamps are seconds.
        end_ts: z.number(),
      }),
    )
    .optional(),
});

export const MotionEventsResponseSchema = z.array(MotionEventSchema);

export type AttributeColor = keyof Theme["color"]["attributeSearch"];

export type MotionEventsResponse = z.infer<typeof MotionEventsResponseSchema>;

// Actually required when we make the API call
type UrlPathParts = { cameraNodeGroupEncryptedId: string };

type QueryParams = {
  id: string;
  start: number;
  end: number;
  roiArr?: {
    [index: string]: {
      top: number;
      left: number;
      width: number;
      height: number;
    };
  };
  with_images: boolean;
  with_people: boolean;
  with_vehicles: boolean;
  include_attributes: boolean;
  unconsolidate_motion?: boolean;
  verbose_subevents: boolean;
  version: 2;
  order?: "ASC" | "DESC";
  per_page?: number;
  ending_before?: string;
};

// Possibly undefined values that could be passed in as query variables

type UrlPathVariables = { cameraNodeGroupEncryptedId: string | undefined };

type QueryParamVariables = Partial<
  Pick<QueryParams, "id" | "start" | "end" | "roiArr" | "ending_before">
>;

type QueryVariables = UrlPathVariables & QueryParamVariables;

const requiredVariables = ["id", "cameraNodeGroupEncryptedId"] as const;

function buildUrl({ cameraNodeGroupEncryptedId }: UrlPathParts) {
  return `/n/${cameraNodeGroupEncryptedId}/manage/cameras/motion_search`;
}

export const FULL_REGION_OF_INTEREST = { "0": { top: 0, left: 0, width: 60, height: 34 } };

function fetchMotionEvents(
  variables: QueryVariables & { cameraNodeGroupEncryptedId: string; id: string },
) {
  const {
    cameraNodeGroupEncryptedId,
    ending_before,
    start = Date.now() - hoursToMilliseconds(48),
    end = Date.now(),
    roiArr,
    ...rest
  } = variables;
  const queryParams: QueryParams = {
    ...rest,
    start,
    end,
    ending_before,
    // People detection does not work if you use version 2 and don't include a roiArr param
    roiArr: roiArr ?? FULL_REGION_OF_INTEREST,
    order: "DESC",
    per_page: 40,
    verbose_subevents: true,
    version: 2,
    include_attributes: true,
    with_images: true,
    with_people: true,
    with_vehicles: true,
  };
  return request(MotionEventsResponseSchema, "GET", buildUrl({ cameraNodeGroupEncryptedId }), {
    // TODO: Our typing for queryParams hates the default roi shape, but it is in fact working for
    // motion search. I get a 500 is I make it an array in the code here.
    // @ts-ignore
    queryParams,
  });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useMotionEvents = createQuery<UrlPathVariables & QueryParamVariables>({
  baseQueryKey: buildUrl({ cameraNodeGroupEncryptedId: "{cameraNodeGroupEncryptedId}" }),

  queryFn: (variables) => {
    const { cameraNodeGroupEncryptedId, id } = variables;
    if (typeof cameraNodeGroupEncryptedId === "undefined" || typeof id === "undefined") {
      throw new Error("Undefined required variables");
    }
    return fetchMotionEvents({
      ...variables,
      cameraNodeGroupEncryptedId,
      id,
    });
  },
  requiredVariables,
});

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useInfiniteMotionEvents = createInfiniteQuery<
  UrlPathVariables & QueryParamVariables,
  MotionEventsResponse
>({
  baseQueryKey: buildUrl({ cameraNodeGroupEncryptedId: "{cameraNodeGroupEncryptedId}" }),
  getNextPageParam(lastPage) {
    const lastTs = lastPage?.at(-1)?.ts;
    return lastTs;
  },
  queryFn: (variables, query) => {
    const { cameraNodeGroupEncryptedId, id } = variables;
    if (typeof cameraNodeGroupEncryptedId === "undefined" || typeof id === "undefined") {
      throw new Error("Undefined required variables");
    }
    return fetchMotionEvents({
      ...variables,
      ...(query.pageParam
        ? {
            ending_before: query.pageParam,
          }
        : {}),
      cameraNodeGroupEncryptedId,
      id,
    });
  },
  requiredVariables,
});
