export type FixAction = (() => void) | undefined;

export enum SuggestionMessageKeys {
  createNewVLAN = "CREATE_VLAN",
  enableDHCP = "ENABLE_DHCP",
}

interface Suggestion {
  action: FixAction;
  messageKey: SuggestionMessageKeys;
  messageOptions?: { vlanID: number };
}

export type FixActionSuggestion = Suggestion | undefined;
