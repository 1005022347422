import { AnalyticsObject, useAnalytics } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { Button, Input } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useState } from "react";

type Props = {
  submitOTPAuth: (pinCode: string) => void;
  resendOTPCode: () => void;
  isLoading: boolean;
  isResendingCode: boolean;
  children: React.ReactNode;
  analytics: AnalyticsObject<"onPressVerify" | "onPressResend">[];
};

export function OTPScreen({
  submitOTPAuth,
  resendOTPCode,
  isLoading,
  isResendingCode,
  children,
  analytics,
}: Props) {
  const [pinCode, setPinCode] = useState("");
  const trackEvent = useAnalytics(analytics);

  return (
    <Screen addDefaultPadding gap="md">
      {children}
      <Input
        label={I18n.t("TWO_FACTOR_AUTH.CODE")}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType="numeric"
        value={pinCode}
        onChangeText={setPinCode}
        disabled={isLoading || isResendingCode}
      />
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        onPress={() => {
          trackEvent("onPressVerify");
          submitOTPAuth(pinCode);
        }}
        loading={isLoading}
        disabled={isResendingCode}
      />
      <Button
        kind="secondary"
        text={I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
        onPress={() => {
          trackEvent("onPressResend");
          resendOTPCode();
        }}
        loading={isResendingCode}
        disabled={isLoading}
      />
    </Screen>
  );
}
