import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheet, BottomSheetMethods, List, Loader, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useApplianceSettings, useManagementInterface } from "@meraki/shared/api";
import { useUmbrellaProtection } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef } from "react";

import { DynamicDnsBottomSheet } from "../../components/DynamicDnsBottomSheet";
import { useDeviceDetails } from "../DeviceDetailsContext";

export const SettingsTab = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  const { device: deviceDetails } = useDeviceDetails();
  const { data: applianceSettings } = useApplianceSettings(
    {
      networkId: deviceDetails?.networkId,
    },
    {
      enabled: deviceDetails?.productType === "appliance",
    },
  );
  const { data: protection } = useUmbrellaProtection(
    {
      networkId: deviceDetails?.networkId,
    },
    {
      enabled: deviceDetails?.productType === "appliance",
    },
  );
  const { data: mgmentInterface } = useManagementInterface(
    {
      serial: deviceDetails?.serial ?? "",
    },
    {
      enabled: deviceDetails !== undefined && deviceDetails.productType === "appliance",
    },
  );

  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const selectedRadioOption = mgmentInterface?.wan1?.usingStaticIp
    ? I18n.t("DEVICE_SETTINGS_TAB.STATIC_IP")
    : I18n.t("DEVICE_SETTINGS_TAB.DHCP");

  if (!deviceDetails) {
    return (
      <Screen testID="SETTINGS_TAB">
        <Loader.Spinner testID="SETTINGS_LOADING_SPINNER" />
      </Screen>
    );
  }

  return (
    <Screen addDefaultPadding>
      <Box paddingBottom="sm" gap="sm">
        <List>
          {deviceDetails?.productType === "wireless" && (
            <List.Item
              title={I18n.t("DEVICE_SETTINGS_TAB.RADIO_SETTINGS")}
              onPress={() =>
                navigation.navigate("WirelessRadioSettings", { device: deviceDetails })
              }
              leftAccessory={<Icon name="Gear" />}
            ></List.Item>
          )}
          <List.Item
            title={I18n.t("DEVICE_SETTINGS_TAB.IP_CONFIG")}
            rightAccessory={
              <Text size="p1" color="interact.text.base">
                {selectedRadioOption}
              </Text>
            }
            onPress={() => {
              navigation.navigate("IPConfig", { device: deviceDetails });
            }}
            leftAccessory={<Icon name="Gear" />}
          ></List.Item>
        </List>
        {deviceDetails?.productType === "appliance" && (
          <>
            <List>
              <List.Item
                title={I18n.t("DEVICE_SETTINGS_TAB.SECURITY_PROTECTION")}
                rightAccessory={
                  <Text size="p1" color="interact.text.base">
                    {protection?.umbrellaProtectionEnabled
                      ? I18n.t("DEVICE_SETTINGS_TAB.ACTIVE")
                      : I18n.t("DEVICE_SETTINGS_TAB.INACTIVE")}
                  </Text>
                }
                onPress={() => {
                  navigation.navigate("SecurityProtection", {
                    deviceLabel: deviceDetails.name || deviceDetails.serial,
                  });
                }}
                leftAccessory={<Icon name="Gear" />}
              ></List.Item>
            </List>
            <List>
              <List.Item
                title={I18n.t("DEVICE_SETTINGS_TAB.DYNAMIC_DNS")}
                description={applianceSettings?.dynamicDns.url}
                onPress={() => {
                  bottomSheetRef.current?.present();
                }}
                rightAccessory={
                  <Text size="p1" color="interact.text.base">
                    {"Change"}
                  </Text>
                }
                leftAccessory={<Icon name="Gear" />}
                testID="DYNAMIC_DNS_ROW"
              />
            </List>
          </>
        )}
      </Box>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <DynamicDnsBottomSheet
          applianceSettings={applianceSettings}
          onClose={() => bottomSheetRef.current?.close()}
        />
      </BottomSheet.Modal>
    </Screen>
  );
};
