import { I18n } from "@meraki/core/i18n";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status, StatusType } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useWirelessLatency } from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { isEmpty } from "lodash";

export function WirelessLatencyCard() {
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data, isLoading } = useWirelessLatency({ networkId, timespan });

  const avg = data?.bestEffortTraffic?.avg;

  let statusName: StatusType = "disabled";
  let latencyText = "";
  if (avg) {
    if (avg < 32) {
      statusName = "positive";
      latencyText = I18n.t("LATENCY_STATS.STATUS_TEXT.POSITIVE");
    } else if (avg <= 256) {
      statusName = "warning";
      latencyText = I18n.t("LATENCY_STATS.STATUS_TEXT.WARNING");
    } else {
      statusName = "negative";
      latencyText = I18n.t("LATENCY_STATS.STATUS_TEXT.NEGATIVE");
    }
  }

  return (
    <Card loading={isLoading} loadingContentHeight={60} testID="WIRELESS_LATENCY_CARD">
      <Card.Header title={I18n.t("LATENCY_STATS.HEADING")} />
      {!isEmpty(data) ? (
        <Card.Content>
          <Card.Well>
            <Box flexDirection="row" alignItems="flex-end">
              <Heading size="h2">{avg}</Heading>
              <Text size="p3">{I18n.t("TIMEUNITS_SHORTHAND.MILLISECOND").toUpperCase()}</Text>
            </Box>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text weight="bold">{latencyText}</Text>
              <Status status={statusName} />
            </Box>
          </Card.Well>
        </Card.Content>
      ) : (
        <Card.Content gap="xs">
          <Card.Well>
            <Text>{I18n.t("LATENCY_STATS.NO_DATA")}</Text>
          </Card.Well>
        </Card.Content>
      )}
    </Card>
  );
}
