import { Platform } from "react-native";
import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const DocumentationResultSchema = z
  .object({
    author: z.string(),
    content: z.string(),
    "date.modified": z.string().optional(),
    id: z.string(),
    mime: z.string(),
    preview: z.string(),
    rank: z.string(),
    tag: z.string(),
    title: z.string(),
    type: z.string(),
    uri: z.string(),
    "uri.track": z.string(),
    page: z.object({
      "@guid": z.string(),
      path: z.string(),
      rating: z
        .object({
          "@count": z.string(),
          "@score": z.string(),
        })
        .or(z.string()),
      title: z.string(),
      "uri.ui": z.string(),
    }),
  })
  .describe("DocumentationResultSchema");

export const DocumentationSchema = z
  .object({
    "@count": z.string(),
    "@count.recommendations": z.string(),
    "@querycount": z.string(),
    "@queryid": z.string(),
    "@ranking": z.string(),
    "@sessionid": z.string(),
    result: z.array(DocumentationResultSchema),
  })
  .describe("DocumentationSchema");

export type Documentation = z.infer<typeof DocumentationSchema>;
export type DocumentationResult = z.infer<typeof DocumentationResultSchema>;

const buildQueryParams = (query: string) => ({
  "dream.out.format": "json",
  constraint: "tag:go",
  q: query,
});

const URL = "/@api/deki/site/query";
const baseUrl = Platform.OS === "web" ? "/documentation" : "https://documentation.meraki.com";

export function fetchDocumentationData(query: string): Promise<Documentation> {
  return request(DocumentationSchema, "GET", URL, {
    baseUrl,
    queryParams: buildQueryParams(query),
  });
}

export const useDocumentationData = createQuery({
  baseQueryKey: URL,
  queryFn: (query: string) => {
    return fetchDocumentationData(query);
  },
});
