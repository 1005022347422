import {
  INITIATE_EXPORT_FAILURE,
  INITIATE_EXPORT_REQUEST,
  INITIATE_EXPORT_SUCCESS,
  SET_EXPORT_TRIGGERED_TO_IDLE,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { VideoChannel } from "~/api/schemas/VideoChannel";
import { ApiResponseAction } from "~/middleware/api";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const initiateExport =
  (
    t0: number,
    t1: number,
    scheduledFor: number,
    networkId: string,
    channel: VideoChannel,
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    const channel_id = channel.id;

    const data = {
      t0: t0,
      t1: t1,
      scheduledForTs: scheduledFor,
      video_channel_id: channel_id,
    };

    return dispatch(
      wrapApiActionWithCSRF({
        types: [INITIATE_EXPORT_REQUEST, INITIATE_EXPORT_SUCCESS, INITIATE_EXPORT_FAILURE],
        endpoint: `/n/${networkId}/manage/video/export`,
        config: {
          method: Method.post,
          body: JSON.stringify(data),
        },
      }),
    );
  };

export const resetExportTriggered = (): AppThunk => (dispatch) => {
  return dispatch({
    type: SET_EXPORT_TRIGGERED_TO_IDLE,
  });
};
