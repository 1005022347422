export interface Props {
  deviceId: string;
}

export interface Message {
  data: {
    stp_root_priority: number;
    stp_root_name: string;
    is_stp_root_stack: boolean;
    is_stp_root_faulty: boolean;
    stp_root_mac: string;
    ports: Ports;
  };
}
export interface Port {
  rx_bcast_pkts: number;
  tx_bcast_pkts: number;
  tx_pkts: number;
  rx_jabbers: number;
  tx_pause: number;
  rx_stp_tcs: number;
  tx_mcast_pkts: number;
  advertised_power_consumption_mw: number;
  module_product_id: string | null;
  module_slot_id: string | null;
  power_mode: number;
  rx_bytes: number;
  power_consumption_mw: number;
  udld_neighbor_id: string;
  rx_pause: number;
  stp_flags: number;
  rx_mcast_pkts: number;
  tx_bytes: number;
  timestamp?: string;
  stp_cost: number;
  curr_status: number;
  total_millijoules_consumed: number;
  using_poe: boolean;
  udld_status: number;
  is_uplink: boolean;
  rx_pkts: number;
  rx_frags: number;
  rx_crc_align_err: number;
  udld_neighbor_port: string;
  error_flags: number;
  tx_drops: number;
  rx_drops: number;
  authd_user: {
    vlan: number;
    name: string;
    mac: string | null;
  };
  rx_undersize_pkts: number;
  tx_collisions: number;
  rx_oversize_pkts: number;
  guest_vid: number;
  status: string;
}

export interface Ports {
  [portNum: string]: Port;
}

export function formatMsg(message?: Message): Ports | undefined {
  const ports = message?.data?.ports;
  return ports;
}
