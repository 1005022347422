import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import InfoModal from "~/go/components/InfoModal";
import I18n from "~/i18n/i18n";
import { removeWebAlertComponent } from "~/lib/WebAlertUtils";
import { MkiText } from "~/shared/components/MkiText";

interface WebActionSheetProps {
  title?: string;
  message?: string;
  items: string[];
  onPress: (index: number) => void;
}

export default class WebActionSheet extends PureComponent<WebActionSheetProps> {
  static defaultProps = {
    items: [],
    onPress: () => {},
  };

  onClose = (index?: number) => {
    const { onPress } = this.props;
    // unmount itself
    removeWebAlertComponent();

    if (index != null) {
      onPress(index);
    }
  };

  renderButton(buttonLabel: string, key: string | number, onPress = () => {}) {
    return (
      <TouchableOpacity key={`ACTION_${key}`} style={styles.buttonContainer} onPress={onPress}>
        <View style={styles.buttonStyle}>
          <MkiText textStyle="label" screenStyles={styles.buttonText}>
            {buttonLabel}
          </MkiText>
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    const { items, message, title } = this.props;

    return (
      <InfoModal visible={true} onExit={this.onClose}>
        <MkiText textStyle="title" screenStyles={styles.titleText}>
          {title}
        </MkiText>
        <MkiText textStyle="heading" screenStyles={styles.messageText}>
          {message}
        </MkiText>
        {items.map((label, index) => this.renderButton(label, index, () => this.onClose(index)))}
        {this.renderButton(I18n.t("CANCEL"), "cancel", () => this.onClose())}
      </InfoModal>
    );
  }
}

const styles = StyleSheet.create({
  titleText: {
    fontSize: 16,
  },
  messageText: {
    fontSize: 14,
  },
  buttonText: {
    color: MkiColors.primaryButton,
    fontSize: 12,
  },
  buttonContainer: {
    paddingVertical: SPACING.small,
    alignSelf: "stretch",
  },
  buttonStyle: {
    backgroundColor: MkiColors.fullTransparent,
    paddingHorizontal: SPACING.default,
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
});
