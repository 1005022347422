import DeviceInfo from "react-native-device-info";

import { appSelect, flavorSelect } from "~/lib/PlatformUtils";

const baseAgent = "DashboardMobile";

const customUserAgent = appSelect({
  enterprise: `${baseAgent}.Enterprise/RN`,
  go: `${baseAgent}.Go`,
});

export const xMerakiUserAgent = {
  "X-Meraki-User-Agent": customUserAgent,
};

export const xMerakiUserAgentVersion = {
  "X-Meraki-User-Agent-Version": DeviceInfo.getVersion(),
};

export const xMerakiUserAgentBuildNumber = {
  "X-Meraki-User-Agent-Build-Number": DeviceInfo.getBuildNumber(),
};

export const xMerakiAppFlavor = {
  "X-Meraki-User-Agent-Mobile-App-Flavor": flavorSelect({
    nightly: "nightly",
    debug: "debug",
    production: "production",
  }),
};

export const xMerakiUserAgentInfo = {
  ...xMerakiUserAgent,
  ...xMerakiUserAgentVersion,
  ...xMerakiUserAgentBuildNumber,
  ...xMerakiAppFlavor,
};
