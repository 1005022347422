import React, { forwardRef } from "react";
import { View } from "react-native";

export const PROVIDER_GOOGLE = "google";

export interface MapViewProps {}

const Camera = forwardRef(({ torch, testID }: { torch: string; testID?: string }, _ref) => (
  <View testID={`${testID}.TORCH_${torch}`} />
));

// @ts-ignore allow this function to exist with the forwardRef component
Camera.getCameraPermissionStatus = () => Promise.resolve("granted");
// @ts-ignore allow this function to exist with the forwardRef component
Camera.requestCameraPermission = () => Promise.resolve("granted");

export const useCameraDevice = () => {};
export const useCameraDevices = () => {};
export const useFrameProcessor = () => {};
export const useCodeScanner = () => {};
export { Camera };
