export enum Locale {
  en = "en",
  de = "de",
  es = "es",
  fr = "fr",
  frca = "frca",
  it = "it",
  ja = "ja",
  ko = "ko",
  nl = "nl",
  pl = "pl",
  pt = "pt",
  ru = "ru",
  zh = "zh",
  hu = "hu",
  cs = "cs",
  sk = "sk",
}

export enum LocaleUpper {
  en = "EN",
  de = "DE",
  es = "ES",
  fr = "FR",
  frca = "FRCA",
  it = "IT",
  ja = "JA",
  ko = "KO",
  nl = "NL",
  pl = "PL",
  pt = "PT",
  ru = "RU",
  zh = "ZH",
  hu = "HU",
  cs = "CS",
  sk = "SK",
}

export enum MomentLocale {
  en = "en",
  de = "de",
  es = "es",
  fr = "fr",
  frca = "fr-ca",
  it = "it",
  ja = "ja",
  ko = "ko",
  nl = "nl",
  pl = "pl",
  pt = "pt",
  ru = "ru",
  zh = "zh-cn",
  hu = "hu",
  cs = "cs",
  sk = "sk",
}
