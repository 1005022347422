import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { Card } from "@meraki/magnetic/components";
import { useCounterSets, useNetworkUsageblocks, useOrgNetwork } from "@meraki/shared/api";
import { ApplicationUsageChart } from "@meraki/shared/components";
import { formatUseNetblocksRusageWithCounterSet } from "@meraki/shared/formatters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

type ApplicationUsageCardProps = {
  t0?: string;
  t1?: string;
};

export function ApplicationUsageCard({ t0, t1 }: ApplicationUsageCardProps) {
  const navigation = useNavigation<NativeStackNavigationProp<InsightsStackProps>>();
  const timespan = useGlobalTimespan();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { data: network, isFetching: isFetchingNetworks } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const encryptedNetworkId = network?.eid;

  const { data: networkUseblocks, isLoading } = useNetworkUsageblocks({
    t0,
    t1,
    timespan,
    encryptedNetworkId,
  });
  const { data: counterSets, isLoading: isCounterSetsLoading } = useCounterSets({
    encryptedNetworkId,
  });

  const formattedRusage = Object.values(
    formatUseNetblocksRusageWithCounterSet(networkUseblocks, counterSets),
  )
    .map((usage) => ({ value: usage.download + usage.upload, name: usage.name }))
    .sort((a, b) => b.value - a.value);

  return (
    <Card
      loading={isLoading || isFetchingNetworks || isCounterSetsLoading}
      testID="APPLICATION_USAGE_CARD"
      onPress={() => navigation.navigate("ApplicationUsageList")}
    >
      <Card.Header title={I18n.t("HOME.TOP_APPLICATIONS")} />
      <ApplicationUsageChart data={formattedRusage.slice(0, 5)} testID="APPLICATION_USAGE" />
    </Card>
  );
}
