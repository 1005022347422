import {
  GET_APPLIANCE_PORTS_FAILURE,
  GET_APPLIANCE_PORTS_REQUEST,
  GET_APPLIANCE_PORTS_SUCCESS,
  GX_PORTS_UPDATE,
  UPDATE_APPLIANCE_PORT_FAILURE,
  UPDATE_APPLIANCE_PORT_REQUEST,
  UPDATE_APPLIANCE_PORT_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { currentNetworkState } from "~/selectors";
import { GXPort } from "~/shared/types/ApplianceTypes";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function setGXportData(liveData: GXPort, serial: string) {
  return {
    type: GX_PORTS_UPDATE,
    liveData,
    serial,
  };
}

export function getAppliancePorts(serial: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          GET_APPLIANCE_PORTS_REQUEST,
          GET_APPLIANCE_PORTS_SUCCESS,
          GET_APPLIANCE_PORTS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/ports`,
        meta: {
          serial,
        },
        config: {
          method: Method.get,
        },
      }),
    );
  };
}

// UDG-2465: This endpoint does not work as expected if the requests are done
// concurrently. They must be done sequentially to avoid a race condition on
// the backend.
export function updateAppliancePort(
  serial: string,
  portNumber: number,
  updatedPortSettings: Partial<GXPort>,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          UPDATE_APPLIANCE_PORT_REQUEST,
          UPDATE_APPLIANCE_PORT_SUCCESS,
          UPDATE_APPLIANCE_PORT_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/ports/${portNumber}`,
        config: {
          method: Method.put,
          body: JSON.stringify(updatedPortSettings),
        },
        meta: {
          serial,
        },
      }),
    );
  };
}
