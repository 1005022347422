import { useTheme } from "@meraki/core/theme";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { defineStyleKey, themeColors } from "~/lib/themeHelper";

interface ContainerWithFooterProps {
  container: React.ReactNode;
  footer: React.ReactNode;
}

export const ContainerWithFooter = memo<ContainerWithFooterProps>((props) => {
  const { container, footer } = props;
  const { theme } = useTheme();

  if (footer != null) {
    const borderStyle = defineStyleKey("borderTopColor", themeColors(theme).border.borderColor);
    return (
      <View style={styles.container}>
        {container}
        <View style={[styles.footer, borderStyle]}>{footer}</View>
      </View>
    );
  } else {
    return <View style={styles.container}>{container}</View>;
  }
});

ContainerWithFooter.displayName = "ContainerWithFooter";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
  },
  footer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: MkiColors.borderColor,
    justifyContent: "center",
  },
});

export default ContainerWithFooter;
