import { I18n } from "@meraki/core/i18n";
import { List, Toggle } from "@meraki/magnetic/components";
import { Heading, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useGetWirelessSettings,
  useSetIPv6Bridging,
  useSetMeshing,
  useSetStealthMode,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";

export const WirelessSettingsScreen = () => {
  const networkId = useCurrentNetworkId();
  const { data: wirelessSettings, isLoading } = useGetWirelessSettings(
    { networkId },
    {
      enabled: Boolean(networkId),
    },
  );

  const setStealthMode = useSetStealthMode();
  const setMeshing = useSetMeshing();
  const setIPv6Bridging = useSetIPv6Bridging();

  return (
    <Screen addDefaultPadding>
      <Heading>{I18n.t("WIRELESS_SETTINGS.TITLE")}</Heading>
      <Text>{I18n.t("WIRELESS_SETTINGS.DESCRIPTION")}</Text>
      <List loading={isLoading}>
        <List.Item
          title={I18n.t("WIRELESS_SETTINGS.TOGGLE_STEALTH_MODE.TOGGLE_TITLE")}
          description={I18n.t("WIRELESS_SETTINGS.TOGGLE_STEALTH_MODE.TOGGLE_SUBTITLE")}
          testID={"TOGGLE_STEALTH_MODE"}
          rightAccessory={
            <Toggle
              checked={!wirelessSettings?.ledLightsOn}
              disabled={isLoading}
              onChange={() => {
                setStealthMode.mutate(
                  { networkId, isEnabled: wirelessSettings?.ledLightsOn ?? false },
                  {
                    onSuccess: () =>
                      queryClient.refetchQueries({ queryKey: useGetWirelessSettings.queryKeyRoot }),
                  },
                );
              }}
            />
          }
        />
        <List.Item
          title={I18n.t("WIRELESS_SETTINGS.TOGGLE_MESHING.TOGGLE_TITLE")}
          description={I18n.t("WIRELESS_SETTINGS.TOGGLE_MESHING.TOGGLE_SUBTITLE")}
          testID={"TOGGLE_MESHING"}
          rightAccessory={
            <Toggle
              checked={!!wirelessSettings?.meshingEnabled}
              disabled={isLoading}
              onChange={() => {
                setMeshing.mutate(
                  { networkId, isEnabled: !wirelessSettings?.meshingEnabled },
                  {
                    onSuccess: () =>
                      queryClient.refetchQueries({ queryKey: useGetWirelessSettings.queryKeyRoot }),
                  },
                );
              }}
            />
          }
        />
        <List.Item
          title={I18n.t("WIRELESS_SETTINGS.TOGGLE_IPV6_BRIDGE_MODE.TOGGLE_TITLE")}
          description={I18n.t("WIRELESS_SETTINGS.TOGGLE_IPV6_BRIDGE_MODE.TOGGLE_SUBTITLE")}
          testID={"TOGGLE_BRIDGE_MODE"}
          rightAccessory={
            <Toggle
              checked={!!wirelessSettings?.ipv6BridgeEnabled}
              disabled={isLoading}
              onChange={() => {
                setIPv6Bridging.mutate(
                  { networkId, isEnabled: !wirelessSettings?.ipv6BridgeEnabled },
                  {
                    onSuccess: () =>
                      queryClient.refetchQueries({ queryKey: useGetWirelessSettings.queryKeyRoot }),
                  },
                );
              }}
            />
          }
        />
      </List>
    </Screen>
  );
};
