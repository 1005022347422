export enum PortTypes {
  ethernet = "ethernet",
  qsfp = "QSFP",
  sfp = "SFP",
  stacking = "stacking",
  rj45 = "rj45",
  access = "access",
  trunk = "trunk",
}

export enum PortStatus {
  disabled,
  active,
  alerting,
  poe,
  uplink,
  disconnected,
}

export enum DeviceChassis {
  rack,
  desktop,
}

export interface PortRow {
  portType: PortTypes;
  portRange: number[];
}

export interface PortLayout {
  topRow: PortRow | null;
  bottomRow: PortRow | null;
}

interface PortBlockLayout {
  type: PortTypes;
  num: number;
  rows: number;
}

export interface PortBlockData {
  chassis: DeviceChassis;
  blocks: PortBlockLayout[];
}

export function standard5PortLayout(portType: PortTypes): PortLayout[] {
  return [
    {
      topRow: null,
      bottomRow: { portType, portRange: [1, 2, 3, 4, 5] },
    },
  ];
}

export default PortTypes;
