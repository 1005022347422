import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Camera({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 25 24" width={width} height={height} fill="none">
      <Path
        d="M3 4.75C3 4.33579 3.33579 4 3.75 4H21.25C21.6642 4 22 4.33579 22 4.75C22 5.16421 21.6642 5.5 21.25 5.5H3.75C3.33579 5.5 3 5.16421 3 4.75Z"
        fill={color1}
      />
      <Path
        d="M20.75 13C20.75 17.6433 17.0614 21.25 12.5 21.25C7.93863 21.25 4.25 17.6433 4.25 13V9C4.25 8.86193 4.36193 8.75 4.5 8.75H20.5C20.6381 8.75 20.75 8.86193 20.75 9V13Z"
        stroke={color1}
        strokeWidth={1.5}
      />
      <Circle cx="12.5" cy="14.5" r="2.25" strokeWidth={1.5} stroke={color1} />
    </Svg>
  );
}
