import { NodeType, TopologyRequest, TopologyResponse, useTopology } from "@meraki/shared/api";

export type ExtendedNode = NodeType & {
  name: string;
  root?: boolean;
  upstream?: string | null;
  downstream: string[];
};

export type TopologyMap = {
  [nodeId: string]: ExtendedNode;
};

export const formatTopologyData = ({ nodes, links }: TopologyResponse): TopologyMap => {
  const topologyMap: TopologyMap = {};

  nodes.forEach((node) => {
    topologyMap[node.derivedId] = {
      ...node,
      name: node.device?.name ?? node.mac,
      root: node.root,
      upstream: node.root ? null : undefined,
      downstream: [],
    };
  });

  links.forEach(({ ends }) => {
    const upstream = ends?.[0]?.node?.derivedId;
    const downstream = ends?.[1]?.node?.derivedId;

    if (upstream && downstream) {
      const upstreamNode = topologyMap[upstream];
      const downstreamNode = topologyMap[downstream];

      if (upstreamNode) {
        upstreamNode.downstream.push(downstream);
      }

      if (downstreamNode) {
        downstreamNode.upstream = upstream;
      }
    }
  });

  return topologyMap;
};

export const useTopologyMap = (variables: TopologyRequest) => {
  return useTopology(variables, {
    select: formatTopologyData,
  });
};

const findUpstreamPath = (topologyMap: TopologyMap, deviceId: string) => {
  const path: ExtendedNode[] = [];
  let nodeInMap: ExtendedNode | undefined = topologyMap[deviceId];

  while (nodeInMap && !nodeInMap.root) {
    path.push(nodeInMap);

    nodeInMap = nodeInMap.upstream ? topologyMap[nodeInMap.upstream] : undefined;
  }

  return path;
};

export const useFindUpstreamPath = (networkId: string | undefined, deviceId: string) => {
  const { data: topologyMap } = useTopologyMap({ networkId });

  return findUpstreamPath(topologyMap ?? {}, deviceId);
};

export const testables = {
  findUpstreamPath,
  formatTopologyData,
};
