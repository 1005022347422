import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { CaseInteraction } from "~/api/schemas/SupportCaseInfo";
import { SupportCase } from "~/api/schemas/SupportCases";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { cleanSupportMessageText } from "~/lib/StringUtils";
import { fromSupportCaseTsToDate, shorthandTimeFromNow } from "~/lib/timeHelper";
import MerakiIcon from "~/shared/components/icons";
import { MkiText } from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface SupportCaseListRowProps {
  supportCase: SupportCase;
  interactions: CaseInteraction[];
  onPress: () => void;
  testID: string;
}

const SupportCaseListRow = (props: SupportCaseListRowProps) => {
  const { supportCase, interactions, onPress, testID } = props;
  const { subject, description, createdDate } = supportCase;

  const chevronColor = useThemeColors().navigation.primary;

  const latestInteraction = interactions?.[0];
  const dirtyPreviewText = latestInteraction
    ? latestInteraction.textBody ?? latestInteraction.commentBody
    : description;
  const cleanedPreviewText = cleanSupportMessageText(dirtyPreviewText, "preview");

  const timeSinceLastInteraction = shorthandTimeFromNow(
    // @ts-expect-error TS(2345): Argument of type 'number | Date' is not assignable... Remove this comment to see the full error message
    fromSupportCaseTsToDate(interactions?.[0]?.createdDate ?? createdDate),
  );

  return (
    <Touchable
      underlayColor={MkiColors.lightPlaceholderTextColor}
      onPress={onPress}
      testID={testID}
    >
      <View style={styles.rowContainer}>
        <View style={styles.headerContainer}>
          <MkiText
            textStyle="default"
            screenStyles={styles.leftSide}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {subject}
          </MkiText>
          <MkiText textStyle="smallSecondary">
            {I18n.t("SUPPORT_CENTER.TIME_SINCE", { time: timeSinceLastInteraction })}
          </MkiText>
        </View>
        <View style={styles.previewContainer}>
          <MkiText
            textStyle="smallSecondary"
            screenStyles={styles.leftSide}
            ellipsizeMode="tail"
            numberOfLines={2}
          >
            {cleanedPreviewText}
          </MkiText>
          <MerakiIcon name={"chevron-right"} size="s" color={chevronColor} />
        </View>
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    paddingVertical: SPACING.medium,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  leftSide: {
    flex: 1,
    paddingRight: SPACING.medium,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  previewContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default SupportCaseListRow;
