import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { launchGoWebApp } from "@meraki/go/links";
import {
  License,
  queryClient,
  UmbrellaPolicies,
  UmbrellaPolicy,
  UmbrellaProtection,
  UmbrellaProtectionUpdateJobStatus,
  useAddGoLicense,
  useGetLicenses,
  useUmbrellaProtection,
  useUmbrellaProtectionUpdateJob,
  useUpdateUmbrellaProtection,
} from "@meraki/shared/api";
import { addSerialHyphens } from "@meraki/shared/devices";
import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLES } from "~/constants/MkiTextStyles";
import {
  getSecurityItemListDescriptions,
  getUmbrellaPolicyTranslations,
} from "~/constants/Umbrella";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import InlineAlert from "~/go/components/InlineAlert";
import RoundedButton from "~/go/components/RoundedButton";
import { Features } from "~/go/types/ContextHelpTypes";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { getOnboardingScreen } from "~/lib/OnboardingFullstackUtils";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import InputModal from "~/shared/components/InputModal";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import { GoStatus } from "~/shared/constants/Status";
import { useTheme } from "~/shared/hooks/useTheme";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import DropDownRow from "~/shared/rows/DropDownRow";
import ListRow from "~/shared/rows/ListRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import { Alert } from "~/shared/types/AlertTypes";
import { LicenseStatuses } from "~/shared/types/License";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";

const REFETCH_INTERVAL = 500;

interface ErrorMessage {
  error: string;
}
interface TableData {
  description: string;
  title: string;
}
interface SubscriptionStatusData {
  value: string;
  label: string;
  context?: Features;
}

interface UmbrellaSettingsScreenProps {
  networkId: string;
  serialNumber: string;
  license?: License;
  isUmbrellaAccountReady: boolean;
  protection: UmbrellaProtection;
  policies: UmbrellaPolicies;
  timezone: string;
}

export function UmbrellaSettingsScreen({
  networkId,
  serialNumber,
  license,
  isUmbrellaAccountReady,
  protection,
  policies,
}: UmbrellaSettingsScreenProps) {
  const navigation = useNavigation();
  const theme = useTheme();

  const currentUmbrellaPolicy = policies.find((policy) =>
    protection.umbrellaPolicyId ? policy.id === protection.umbrellaPolicyId : policy.default,
  );
  const isEnabledInitial = protection.umbrellaProtectionEnabled;

  const addLicense = useAddGoLicense();
  const updateProtection = useUpdateUmbrellaProtection();

  const [didCloseAlert, setDidCloseAlert] = useState<boolean>(isEnabledInitial);
  const [excludedURLs, setExcludedURLs] = useState<string[]>(protection.excludedDomains);
  const [isEnabled, setIsEnabled] = useState<boolean>(isEnabledInitial);
  const [selectedPolicy, setSelectedPolicy] = useState<UmbrellaPolicy | undefined>(
    currentUmbrellaPolicy,
  );
  const [showLicenseModal, setShowLicenseModal] = useState<boolean>(false);
  const [stagedLicenseKey, setStagedLicenseKey] = useState<string>("");
  const [licenseAlert, setLicenseAlert] = useState<Alert | undefined>(undefined);
  const [updateJobId, setUpdateJobId] = useState<string>("");
  const [reqPending, setReqPending] = useState<boolean>(false);

  const { data: updateJob, refetch } = useUmbrellaProtectionUpdateJob(
    { networkId, updateJobId },
    { enabled: false },
  );

  const hasChanged = useCallback(() => {
    return !(
      isEnabled === isEnabledInitial &&
      isEqual(selectedPolicy?.id, currentUmbrellaPolicy?.id) &&
      isEqual(excludedURLs, protection.excludedDomains)
    );
  }, [
    currentUmbrellaPolicy,
    excludedURLs,
    isEnabled,
    isEnabledInitial,
    protection.excludedDomains,
    selectedPolicy,
  ]);

  useEffect(() => {
    const saveAndDismiss = () => {
      setReqPending(true);
      updateProtection.mutate(
        {
          networkId,
          umbrellaProtection: {
            umbrellaProtectionEnabled: isEnabled,
            umbrellaPolicyId: selectedPolicy?.id || null,
            excludedDomains: excludedURLs,
          },
        },
        {
          onSuccess: ({ id }) => {
            setUpdateJobId(id);
          },
          onError: (error) => {
            showAlert(
              I18n.t("ERROR"),
              error.errors ? error.errors.join(",") : I18n.t("SERVER_ERROR_TEXT"),
            );
          },
        },
      );
    };

    const changed = hasChanged();

    navigation.setOptions({
      headerLeft: () => (
        <CloseButton
          onPress={() => {
            if (isUmbrellaAccountReady && changed) {
              showSaveWarning(saveAndDismiss, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: isUmbrellaAccountReady
        ? () => <SaveButton onPress={saveAndDismiss} disabled={!changed} />
        : undefined,
    });
  }, [
    excludedURLs,
    hasChanged,
    isEnabled,
    isUmbrellaAccountReady,
    navigation,
    networkId,
    reqPending,
    selectedPolicy,
    updateProtection,
  ]);

  useEffect(() => {
    if (updateJobId) {
      if (updateJob?.status === UmbrellaProtectionUpdateJobStatus.complete) {
        queryClient.invalidateQueries({ queryKey: useUmbrellaProtection.queryKeyRoot });
        navigation.goBack();
        return;
      }

      setTimeout(() => refetch(), REFETCH_INTERVAL);
    }
  }, [navigation, refetch, reqPending, updateJob, updateJobId]);

  const onChangeEnabled = (isEnabled: boolean) => setIsEnabled(isEnabled);
  const openLicenseModal = () => setShowLicenseModal(true);
  const closeLicenseModal = () => setShowLicenseModal(false);

  const onSubscriptionButtonPressed = () => {
    if (isWeb()) {
      openLicenseModal();
    } else {
      launchGoWebApp();
    }
  };

  const getTableData = (): TableData[] =>
    getSecurityItemListDescriptions().map((item) => ({
      title: item.TITLE,
      description: item.DESCRIPTION,
    }));

  const closeAlertCard = () => setDidCloseAlert(true);

  const pushUmbrellaExplainer = () => {
    const screen = getOnboardingScreen(OnboardingStage.umbrellaExplainer);

    // @ts-expect-error TS(2345) FIXME: Argument of type '[keyof UmbrellaScreensPropMap | ... Remove this comment to see the full error message
    navigation.navigate(screen, { isOnboarding: false });
  };

  const pushUmbrellaExcludedURLs = () => {
    navigation.navigate("UmbrellaExcludedURLs", {
      passedExcludedURLs: excludedURLs,
      updateURLs: updateURLs,
    });
  };

  const pushUmbrellaPolicies = () => {
    navigation.navigate("UmbrellaPolicies", {
      selectedPolicy,
      umbrellaPolicies: policies,
      updateSelectedPolicy: updateSelectedPolicy,
    });
  };

  const updateURLs = (newURLs: string[]) => {
    setExcludedURLs(newURLs);
  };

  const updateSelectedPolicy = (newSelectedPolicy: UmbrellaPolicy) => {
    setSelectedPolicy(newSelectedPolicy);
  };

  const renderRow = (rowData: TableData, rowId: number) => {
    const backgroundColor = themeColors(theme).navigation.backgroundPrimary;

    return (
      <DropDownRow
        title={rowData.title}
        titleTextStyle={[TEXT_STYLES.default, themeColors(theme).text.default]}
        testID={`SECURITY_ITEM_DESC_ROW_${rowId}`}
        noMargin
      >
        <MkiText
          textStyle="smallSecondary"
          screenStyles={[{ backgroundColor }, styles.itemDescriptionText]}
        >
          {rowData.description}
        </MkiText>
      </DropDownRow>
    );
  };

  const renderUmbrellaRows = () => {
    const { title } = getUmbrellaPolicyTranslations(selectedPolicy?.name);

    return (
      <>
        <SwitchRow
          subtitle={I18n.t("UMBRELLA.ENABLE.DESCRIPTION")}
          value={isEnabled}
          onValueChange={onChangeEnabled}
          testID="UMBRELLA_PROTECTION"
        >
          {I18n.t("UMBRELLA.ENABLE.TITLE")}
        </SwitchRow>
        <DisclosureRow
          rowStyle={!isEnabled && styles.disabledRow}
          onPress={isEnabled ? pushUmbrellaPolicies : undefined}
          subtitle={title}
          testID="UMBRELLA_POLICY_ROW"
        >
          {I18n.t("UMBRELLA.POLICY.TITLE")}
        </DisclosureRow>
        <DisclosureRow
          rowStyle={!isEnabled && styles.disabledRow}
          onPress={isEnabled ? pushUmbrellaExcludedURLs : undefined}
          testID="UMBRELLA_EXCLUDED_URL_ROW"
        >
          {I18n.t("UMBRELLA.EXCLUDED_URLS.TITLE")}
        </DisclosureRow>
      </>
    );
  };

  const renderSubscriptionUpsell = () => (
    <>
      <MkiText screenStyles={styles.subscriptionTitle}>{I18n.t("UMBRELLA.ENABLE.TITLE")}</MkiText>
      <MkiText textStyle="small" screenStyles={styles.subscriptionText}>
        {I18n.t("UMBRELLA.SUBSCRIPTION.DESCRIPTION")}
      </MkiText>
      {renderSubscriptionButton()}
    </>
  );

  const renderListRow = (rowData: SubscriptionStatusData) => {
    return (
      <ListRow
        value={rowData.value}
        rowStyles={styles.subscriptionRow}
        rightStyle={styles.subscriptionRightContent}
        leftStyle={styles.subscriptionLeftContent}
        labelStyle={styles.subscriptionLabel}
        underlayColor="transparent"
      >
        {rowData.context && (
          <View style={styles.horizontalContainer}>
            <MkiText>{rowData.label}</MkiText>
            <View style={styles.contextButtonPadding}>
              <ContextHelp context={rowData.context} />
            </View>
          </View>
        )}
        {!rowData.context && <MkiText>{rowData.label}</MkiText>}
      </ListRow>
    );
  };

  const displayableDate = (date: string) => {
    return formatDate(date, { dateFormat: "longDateWithDayOfWeek" });
  };

  const renderSubscriptionButton = () => (
    <RoundedButton
      onPress={onSubscriptionButtonPressed}
      screenStyles={styles.subscriptionButton}
      testID="SUBSCRIPTION.ADD_KEY_BUTTON"
    >
      {I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.BUTTON")}
    </RoundedButton>
  );

  const renderSubscriptionStatus = () => {
    if (license == null) {
      return null;
    }

    const contents = [
      {
        value: displayableDate(license.activationDate || ""),
        label: I18n.t("UMBRELLA.SUBSCRIPTION.ACTIVATION"),
      },
      {
        value: displayableDate(license.expirationDate || ""),
        label: I18n.t("UMBRELLA.SUBSCRIPTION.EXPIRATION"),
        context: Features.securityLicenseExpiration,
      },
    ];

    return (
      <>
        <SummaryList<SubscriptionStatusData>
          contentRows={contents}
          customRenderRow={renderListRow}
          disableBottomBorder
          testID="UMBRELLA_SUBSCRIPTION"
        />
        {license.state === LicenseStatuses.expiring && renderSubscriptionButton()}
      </>
    );
  };

  const changeLicenseKey = (key: string) => {
    let newKey = key;
    const oldKey = stagedLicenseKey;
    if (newKey.length > 0) {
      if (newKey.length > oldKey.length) {
        newKey = addSerialHyphens(newKey);
      }
    }

    setStagedLicenseKey(newKey);
  };

  const addSubscription = () => {
    addLicense.mutate(
      { key: stagedLicenseKey, GXDeviceSerial: serialNumber },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
          setLicenseAlert({
            message: I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SUCCESS_MESSAGE"),
            status: GoStatus.good,
            primaryButtonText: I18n.t("CLOSE"),
            primaryButtonPress: closeLicenseModal,
          });
        },
        onError: (error) => {
          const errorMessage = error as ErrorMessage;
          setLicenseAlert({
            message: errorMessage.error ? errorMessage.error : I18n.t("SERVER_ERROR_TEXT"),
            status: GoStatus.bad,
          });
        },
      },
    );
  };

  const renderHeader = () => {
    return (
      <View>
        {renderSubscriptionStatus()}
        {isUmbrellaAccountReady && license != null
          ? renderUmbrellaRows()
          : renderSubscriptionUpsell()}
      </View>
    );
  };

  return (
    <>
      <MkiTable<TableData>
        data={getTableData()}
        ListHeaderComponent={renderHeader}
        renderRow={renderRow}
        keyExtractor={(item: TableData) => `${item.title}`}
        loading={updateProtection.isLoading}
      />
      <InlineAlert
        alertTitle={I18n.t("ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.TITLE")}
        alertMessage={I18n.t("ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.DESCRIPTION")}
        onExit={closeAlertCard}
        onPrimaryPress={pushUmbrellaExplainer}
        primaryButtonText={I18n.t("LEARN_MORE")}
        screenStyles={styles.inlineAlert}
        visible={isUmbrellaAccountReady && !didCloseAlert}
      />
      <InputModal
        value={stagedLicenseKey}
        title={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
        subtitle={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.ENTER_KEY")}
        visible={showLicenseModal}
        primaryButtonText={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
        placeholder={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.PLACEHOLDER")}
        onChangeText={changeLicenseKey}
        onPrimaryPress={addSubscription}
        onExit={closeLicenseModal}
        alert={licenseAlert}
        autoCapitalize="characters"
        loading={addLicense.isLoading}
      />
      <LoadingSpinner visible={reqPending} />
    </>
  );
}

const styles = StyleSheet.create({
  itemDescriptionText: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
  },
  inlineAlert: {
    marginVertical: SPACING.small,
    marginHorizontal: SPACING.default,
  },
  disabledRow: {
    opacity: 0.5,
  },
  subscriptionTitle: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.default,
  },
  subscriptionText: {
    marginHorizontal: SPACING.default,
    marginVertical: SPACING.default,
  },
  subscriptionRow: {
    marginHorizontal: SPACING.default,
    paddingVertical: SPACING.small,
  },
  subscriptionLabel: {
    color: MkiColors.secondaryTextColor,
  },
  subscriptionRightContent: {
    width: "60%",
  },
  subscriptionLeftContent: {
    width: "40%",
  },
  subscriptionButton: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.extraExtraLarge,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  contextButtonPadding: {
    ...platformSelect({
      ios: {},
      android: {
        marginStart: SPACING.small,
      },
    }),
  },
});

export default UmbrellaSettingsScreen;
