import { I18n } from "@meraki/core/i18n";
import { Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { SupportCaseInteraction } from "@meraki/shared/api";

import {
  fromSupportCaseTsToDate,
  shorthandTimeFromNow,
  SUPPORT_CASE_DEFAULT_NAME,
} from "../utils/SupportCaseUtils";

interface SupportCaseMessageProps {
  possibleUserNames: string[];
  comment: SupportCaseInteraction;
  testID?: string;
}

export const SupportCaseMessage = ({
  possibleUserNames,
  comment,
  testID,
}: SupportCaseMessageProps) => {
  const timeSinceLastInteraction = shorthandTimeFromNow(
    fromSupportCaseTsToDate(comment.createdDate),
  );
  const senderName = comment.fromName ?? comment.createdByName;
  const isUser =
    senderName && [...possibleUserNames, SUPPORT_CASE_DEFAULT_NAME].includes(senderName)
      ? true
      : false;

  return (
    <Box paddingTop="sm">
      <Box
        flex={1}
        flexDirection="row"
        backgroundColor={`control.bg.weak.${isUser ? "base" : "active"}`}
        borderColor={`control.${isUser ? "border.weak.base" : "bg.weak.hover"}`}
        borderWidth="medium"
        borderRadius="lg"
        padding="sm"
        testID={testID}
      >
        <Box flex={1}>
          <Text size="p2">{comment.commentBody}</Text>
          <Box flexDirection="row" paddingTop="2xs">
            <Text color="light">{`${
              isUser
                ? I18n.t("SUPPORT_CENTER.MESSAGE.YOU")
                : I18n.t("SUPPORT_CENTER.MESSAGE.SUPPORT")
            }${I18n.t("SUPPORT_CENTER.TIME_SINCE", {
              time: timeSinceLastInteraction,
            })}`}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
