export enum LicenseTypes {
  gxUMB = "GX-UMB",
}

export enum LicenseStatuses {
  active = "active",
  expired = "expired",
  expiring = "expiring",
  unused = "unused",
  unusedActive = "unusedActive",
  recentlyQueued = "recentlyQueued",
}

export const ActiveStatuses = ["active", "expiring", "unusedActive"];
export const ActiveAndQueuedStatuses = ["active", "expiring", "recentlyQueued"];

export const InactiveStatuses = ["expired", "unused", "unusedActive", "recentlyQueued"];

export interface License {
  activationDate: string;
  claimDate: string;
  deviceSerial: string;
  durationInDays: number;
  expirationDate: string;
  headLicenseId: string | null;
  headLicenseKey?: string;
  id: string;
  licenseKey: string;
  licenseType: LicenseTypes;
  networkId: string;
  orderNumber: string;
  permanentlyQueuedLicenses: string[];
  seatCount?: number;
  state: LicenseStatuses;
  totalDurationInDays: number;
}

interface LicensesOnLocale {
  [id: string]: License;
}

export interface LicensesOnOrg {
  [id: string]: LicensesOnLocale;
}

export interface LicensesState {
  [id: string]: LicensesOnOrg;
}
