import { I18n } from "@meraki/core/i18n";
import { StyleSheet } from "react-native";

import { CombinedAppliancePort } from "~/api/queries/appliances/useCombinedAppliancePorts";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { isPortEnabled, isUplinkPort } from "~/lib/GXPortUtils";
import { DASH } from "~/lib/SwitchPortUtils";
import SummaryList from "~/shared/components/SummaryList";
import ListRow from "~/shared/rows/ListRow";

interface PortInfoListProps {
  port: CombinedAppliancePort;
}

const PortInfoList = (props: PortInfoListProps) => {
  const { port } = props;

  const portLabelRow = (rowData: { label: string; value: string }) => (
    <ListRow
      value={rowData.value}
      rowStyles={styles.rowStyles}
      rightStyle={styles.rightContent}
      leftStyle={styles.leftContent}
      labelStyle={styles.productInfoLabel}
      testID={`PORT_ROW.${rowData.label}`}
    >
      {rowData.label}
    </ListRow>
  );

  const typeLabel = isUplinkPort(port) ? I18n.t("PORTS.UPLINK") : I18n.t("PORTS.LAN_ACCESS");

  const speed = port.speed;
  const speedLabel = port && isPortEnabled(port) ? speed || DASH : DASH;

  const content = [
    { label: I18n.t("PORTS.TYPE"), value: typeLabel },
    { label: I18n.t("PORTS.LINK_SPEED"), value: speedLabel },
  ];

  return (
    <SummaryList
      heading={I18n.t("PORTS.PORT_INFO")}
      contentRows={content}
      customRenderRow={portLabelRow}
    />
  );
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  productInfoLabel: {
    color: MkiColors.secondaryTextColor,
  },
});

export default PortInfoList;
