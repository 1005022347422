import { combineReducers } from "redux";

import clientsOverview from "~/api/reducers/clientsOverview";
import orgNetworks from "~/api/reducers/orgNetworks";
import orgs from "~/api/reducers/orgs";

const api = combineReducers({
  clientsOverview,
  orgs,
  orgNetworks,
});

export default api;
