import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  Input,
  List,
  Notification,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Card } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  Ssid,
  useDeviceStatuses,
  useIdentityPsks,
  useSsid,
  useUpdateSsid,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Pressable } from "react-native";
import Toast from "react-native-simple-toast";

const IPSK_SUPPORTED_MODELS = ["GR12", "GR62", "MR36H"];
export const MAX_IPSK_LIMIT = 25;

export function PasswordSettingsScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "PasswordSettings">>();
  const networkId = useCurrentNetworkId();
  const updateSsid = useUpdateSsid();
  const [psk, setPsk] = useState<string>("");
  const [showWarning, setShowWarning] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const { params: props } = route;
  const { data: ssid, refetch } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  const organizationId = useCurrentOrganizationId();
  const { data: devices } = useDeviceStatuses({
    organizationId,
    networkIds: networkId ? [networkId] : undefined,
  });

  const { data: identityPsks } = useIdentityPsks({
    networkId,
    ssidNumber: props?.ssidNumber?.toString(),
  });

  let broadcastingDevices = devices?.filter((device) => device.productType === "wireless");
  if (!ssid?.availableOnAllAps && ssid?.availabilityTags) {
    const tag = ssid?.availabilityTags[0];
    broadcastingDevices = broadcastingDevices?.filter((device) => device.tags?.includes(tag));
  }

  const isIpskSupported = broadcastingDevices?.every((device) =>
    IPSK_SUPPORTED_MODELS.includes(device.model),
  );

  const helperText =
    psk.length < 8 || psk.length > 64
      ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.PASSWORD_PLACEHOLDER")
      : "";
  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button
            kind="tertiary"
            leadingIcon={"CaretLeft"}
            text={ssid?.name ?? ""}
            onPress={() => navigation.goBack()}
          />
        ),
      }),
    [navigation, ssid?.name],
  );

  const updateSettings = useCallback(
    (body: Partial<Ssid>, onSuccess?: () => void) => {
      updateSsid.mutate(
        { networkId, ssidNumber: props?.ssidNumber, body },
        {
          onSuccess: () => {
            refetch();
            setShowWarning(false);
            onSuccess?.();
            Toast.showWithGravity(
              I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.SUCCESS_TOAST"),
              Toast.SHORT,
              Toast.TOP,
            );
          },
          onError: (error) => Alert.alert(String(error["errors"])),
        },
      );
    },
    [networkId, props?.ssidNumber, refetch, updateSsid],
  );

  const onIPSKTogglePress = () => {
    if (!isIpskSupported) {
      setShowWarning(true);
    }
  };
  return (
    <Screen addDefaultPadding>
      <Box bottomDividerBorder paddingBottom="sm">
        <Heading size="h1">{I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.TITLE")}</Heading>
      </Box>
      <Text size="p1">{I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.SUBTITLE")}</Text>
      <Card>
        <Card.Header
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.OPEN_LABEL")}
          leftAccessory={<Icon name="Lock" />}
          rightAccessory={
            <Toggle
              checked={ssid?.authMode === "open"}
              onValueChange={() => {
                if (ssid?.authMode !== "open") {
                  updateSettings({ authMode: "open" });
                } else {
                  bottomSheetRef.current?.present();
                }
              }}
              testID={`OPEN${ssid?.authMode === "open" ? ".SELECTED" : ""}`}
            />
          }
        />
        <Card.Content paddingLeft="xl" paddingRight="xs">
          <Text size="p2" color="light">
            {I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.OPEN_DESCRIPTION")}
          </Text>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.PASSWORD_LABEL")}
          leftAccessory={<Icon name="Lock" />}
          rightAccessory={
            <Toggle
              checked={ssid?.authMode === "psk"}
              onChange={() => {
                if (ssid?.authMode === "psk") {
                  updateSettings({ authMode: "open" });
                } else {
                  bottomSheetRef.current?.present();
                }
              }}
              testID={`PSK${ssid?.authMode === "psk" ? ".SELECTED" : ""}`}
            />
          }
        />
        <Card.Content>
          <Box paddingLeft="xl" paddingBottom="sm" paddingRight="xs">
            <Text size="p2" color="light">
              {I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.PASSWORD_DESCRIPTION")}
            </Text>
          </Box>
          <List>
            <List.Item
              title={
                ssid?.psk
                  ? showPassword
                    ? ssid.psk
                    : "**********"
                  : I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.NOT_ADDED")
              }
              rightAccessory={
                <>
                  <Button.Icon
                    icon={showPassword ? "EyeClosed" : "Eye"}
                    onPress={() => setShowPassword(!showPassword)}
                  />
                  <Button.Icon
                    icon="Pencil"
                    onPress={() => bottomSheetRef.current?.present()}
                    testID="EDIT_VLAN"
                  />
                </>
              }
            />
          </List>
        </Card.Content>
      </Card>
      {showWarning && (
        <Notification.Inline
          status="warning"
          message={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.IPSK.INVALID_BROADCAST")}
        />
      )}
      <Card>
        <Card.Header
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.IPSK.LABEL")}
          leftAccessory={<Icon name="Lock" />}
          rightAccessory={
            <Pressable onPress={() => onIPSKTogglePress()}>
              <Toggle
                checked={ssid?.authMode === "ipsk-without-radius"}
                disabled={!isIpskSupported}
                onChange={() => {
                  updateSettings(
                    ssid?.authMode === "ipsk-without-radius"
                      ? { authMode: "open" }
                      : { authMode: "ipsk-without-radius", encryptionMode: "wpa" },
                  );
                }}
                testID={`IPSK${
                  !isIpskSupported
                    ? ".DISABLED"
                    : ssid?.authMode === "ipsk-without-radius"
                      ? ".SELECTED"
                      : ""
                }`}
              />
            </Pressable>
          }
        />
        <Box paddingLeft="xl" paddingBottom="sm" paddingRight="xs">
          <Text size="p2" color="light">
            {I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.IPSK.DESCRIPTION")}
          </Text>
        </Box>

        {ssid?.authMode === "ipsk-without-radius" && (
          <Box
            paddingHorizontal="xs"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text size="p2" weight="bold">
              {I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.IPSK.PASSWORDS")}
            </Text>
            <Button
              text={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.ADD")}
              kind="tertiary"
              onPress={() =>
                navigation.navigate("UniquePassword", { ssidNumber: props?.ssidNumber })
              }
              disabled={!identityPsks || identityPsks.length >= MAX_IPSK_LIMIT}
            ></Button>
          </Box>
        )}
        <List.FlashList
          paddingTop="none"
          paddingRight="none"
          paddingLeft="none"
          data={identityPsks}
          getItemData={(ipsk) => {
            return {
              title: ipsk.name,
              description: ipsk.expiresAt
                ? new Date(ipsk.expiresAt).toLocaleDateString()
                : I18n.t("CONFIGURE_IPSK.DOES_NOT_EXPIRE"),
              onPress: () =>
                navigation.navigate("UniquePassword", { ssidNumber: props?.ssidNumber, ipsk }),
            };
          }}
          emptyState={{
            title: I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.NOT_ADDED"),
          }}
        />
      </Card>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.PASSWORD_LABEL")}
          onCancelPress={() => {
            setPsk("");
            bottomSheetRef.current?.dismiss();
          }}
          onResetPress={() => setPsk("")}
        />
        <BottomSheet.Content>
          <Box padding="xs">
            <Input
              value={psk}
              onChangeText={setPsk}
              additionalContext={helperText}
              testID="PSK_INPUT"
            />
          </Box>
          <Box padding="xs">
            <Button
              text={I18n.t("SAVE")}
              onPress={() =>
                updateSettings(
                  { authMode: "psk", psk, encryptionMode: "wpa" },
                  () => bottomSheetRef.current?.dismiss(),
                )
              }
              disabled={Boolean(helperText)}
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
