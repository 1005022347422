import { ThemeName, useTheme as useThemeStore } from "@meraki/core/theme";
import { useColorScheme } from "react-native";

import { themeColors, themeMode } from "~/lib/themeHelper";
import ThemeInterface from "~/shared/themes/ThemeInterface";

export const useTheme = (): ThemeName => {
  const { theme } = useThemeStore();
  return theme;
};

export const useThemeColors = (): ThemeInterface => {
  const theme = useTheme();
  const osTheme = useColorScheme() ?? "light";
  return themeColors(theme === "system" ? osTheme : theme);
};

export const useThemeMode = () => {
  const theme = useTheme();
  return themeMode(theme);
};
