import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Notification } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  SsidSplashSettings,
  useDevices,
  useOrganization,
  useSsid,
  useSsidSplashSettings,
  useUpdateSsid,
  useUpdateSsidSplashSettings,
} from "@meraki/shared/api";
import { PhotoSelect } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import { launchUrl, useSplashPagePreview } from "@meraki/shared/links";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentShardId,
} from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";

import { convertFrequency, SPLASH_TIMEOUT_OPTIONS } from "../utils/splashUtils";

type ClickThroughSettingsForm = {
  timeoutFrequency: number;
  welcomeMessage: string;
  redirectUrl: string;
};

const getLogoURL = (
  shardId: number,
  organizationName?: string,
  encryptedNetworkId?: string,
  splashSettings?: SsidSplashSettings,
) => {
  if (organizationName && encryptedNetworkId && splashSettings?.splashLogo?.md5) {
    return `https://n${shardId}.meraki.com/${organizationName}/n/${encryptedNetworkId}/public/image/${splashSettings.splashLogo.md5}?extension=${splashSettings.splashLogo.extension}`;
  }

  return;
};

export function ClickThroughScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "ClickThrough">>();
  const { params: props } = route;

  const shardId = useCurrentShardId();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();

  const { data: organization, isLoading: isLoadingOrganization } = useOrganization({
    organizationId,
  });
  const { data: encryptedNetworkId, isLoading: isLoadingEId } = useDevices(
    { organizationId, networkId },
    { select: (data) => data.find((device) => device.productType === "wireless")?.networkEid },
  );
  const { data: splashSettings } = useSsidSplashSettings({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  const updateSSIDSplashSettings = useUpdateSsidSplashSettings();
  const updateSsid = useUpdateSsid();

  const [stagedImage, setStagedImage] = useState<string | undefined>();
  const [photo, setPhoto] = useState<string | undefined>();
  const [extension, setExtension] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const methods = useForm<ClickThroughSettingsForm>({
    defaultValues: {
      timeoutFrequency: splashSettings?.splashTimeout ?? 1800,
      welcomeMessage: splashSettings?.welcomeMessage ?? "",
      redirectUrl: splashSettings?.redirectUrl ?? "",
    },
  });

  useEffect(() => {
    setStagedImage(getLogoURL(shardId, organization?.name, encryptedNetworkId, splashSettings));
    methods.setValue("timeoutFrequency", (splashSettings?.splashTimeout ?? 1) * 60);
    methods.setValue("welcomeMessage", splashSettings?.welcomeMessage ?? "");
    methods.setValue("redirectUrl", splashSettings?.redirectUrl ?? "");
  }, [encryptedNetworkId, methods, organization?.name, shardId, splashSettings]);

  const onSave = useCallback(
    ({ timeoutFrequency, welcomeMessage, redirectUrl }: ClickThroughSettingsForm) => {
      const useRedirectUrl = Boolean(redirectUrl);
      const splashLogo = photo
        ? {
            image: {
              contents: photo,
              format: extension,
            },
          }
        : undefined;

      updateSSIDSplashSettings.mutate(
        {
          networkId,
          ssidNumber: props?.ssidNumber,
          body: {
            useSplashUrl: false,
            useRedirectUrl: useRedirectUrl,
            splashTimeout: timeoutFrequency / 60,
            welcomeMessage,
            redirectUrl: useRedirectUrl ? redirectUrl : undefined,
            splashLogo: splashLogo,
          },
        },
        {
          onSuccess: () => {
            updateSsid.mutate(
              {
                networkId,
                ssidNumber: props?.ssidNumber,
                body: {
                  splashPage: "Click-through splash page",
                },
              },
              {
                onSuccess: () => navigation.goBack(),
                onError: (e) => setErrorMessage(e.errors[0]),
                onSettled: () => {
                  queryClient.refetchQueries({ queryKey: useSsid.queryKeyRoot });
                  queryClient.refetchQueries({ queryKey: useSsidSplashSettings.queryKeyRoot });
                },
              },
            );
          },
          onError: (e) => setErrorMessage(e.errors[0]),
        },
      );
    },
    [
      updateSSIDSplashSettings,
      networkId,
      props?.ssidNumber,
      photo,
      extension,
      updateSsid,
      navigation,
    ],
  );

  useEffect(
    () =>
      navigation.setOptions({
        headerRight: () => (
          <Button.Nav text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSave)} />
        ),
      }),
    [methods, navigation, onSave],
  );

  const previewURL = useSplashPagePreview(
    organization?.name,
    encryptedNetworkId,
    props?.ssidNumber,
  );
  const isWeb = Platform.OS === "web";
  const onPreview = () => {
    if (isWeb) {
      launchUrl(previewURL);
    } else {
      navigation.navigate("ClickThroughPreview", { previewURL });
    }
  };

  return (
    <Screen addDefaultPadding>
      {errorMessage && (
        <Notification.Inline
          status="negative"
          message={errorMessage}
          onDismiss={() => setErrorMessage(undefined)}
        />
      )}
      <Button
        kind="secondary"
        text={I18n.t("CLICK_THROUGH_SCREEN.PREVIEW_BUTTON")}
        onPress={() => onPreview()}
        disabled={methods.formState.isDirty || !!photo}
        testID="CLICK_THROUGH.PREVIEW"
      />
      <Card
        loading={
          isLoadingOrganization ||
          isLoadingEId ||
          updateSSIDSplashSettings.isLoading ||
          updateSsid.isLoading
        }
      >
        <PhotoSelect
          sourceUri={stagedImage}
          onPhotoSelect={({ content, extension }) => {
            setStagedImage(`data:image/${extension};base64,${content}`);
            setPhoto(content);
            setExtension(extension);
          }}
          testID="CLICK_THROUGH.PHOTO"
        />
        {(Boolean(stagedImage) || Boolean(photo)) && (
          <Box position="absolute" right={0}>
            <Button.Icon
              icon="XCircle"
              onPress={() => {
                setStagedImage(undefined);
                setExtension(undefined);
                setPhoto(undefined);
              }}
              testID="LOGO.CLEAR_IMAGE"
            />
          </Box>
        )}
      </Card>
      <Form {...methods}>
        <Form.PickerCard
          name="timeoutFrequency"
          options={SPLASH_TIMEOUT_OPTIONS.map((timeout) => ({
            label: convertFrequency(timeout, false),
            value: timeout,
          }))}
          onSelectOption={({ value }) => {
            methods.setValue("timeoutFrequency", value);
          }}
        />
        <Form.Input
          disabled={updateSSIDSplashSettings.isLoading || updateSsid.isLoading}
          label={I18n.t("CLICK_THROUGH_SCREEN.CUSTOM_MESSAGE.TITLE")}
          name="welcomeMessage"
          testID="CLICK_THROUGH.WELCOME_MESSAGE"
        />
        <Form.Input
          disabled={updateSSIDSplashSettings.isLoading || updateSsid.isLoading}
          label={I18n.t("CLICK_THROUGH_SCREEN.REDIRECT_URL.TITLE")}
          name="redirectUrl"
        />
      </Form>
    </Screen>
  );
}
