import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const BuiltInRuleTypes = {
  application: "application",
  applicationCategory: "applicationCategory",
} as const;

const BuiltInRuleTypeSchema = z.union([
  z.literal(BuiltInRuleTypes.application),
  z.literal(BuiltInRuleTypes.applicationCategory),
]);

const BuiltInRuleSchema = z.object({
  type: BuiltInRuleTypeSchema,
  value: z.object({
    id: z.string(),
    name: z.string().optional(),
  }),
});

const CustomRuleTypes = {
  host: "host",
  port: "port",
  ipRange: "ipRange",
  localNet: "localNet",
} as const;

const CustomRuleTypeSchema = z.union([
  z.literal(CustomRuleTypes.host),
  z.literal(CustomRuleTypes.port),
  z.literal(CustomRuleTypes.ipRange),
  z.literal(CustomRuleTypes.localNet),
]);

const CustomRuleSchema = z.object({
  type: CustomRuleTypeSchema,
  value: z.string(),
});

const PerClientBandwidthLimitsSettingsSchema = z.union([
  z.literal("network default"),
  z.literal("ignore"),
  z.literal("custom"),
]);

export const SSIDTrafficShapingRuleSchema = z
  .object({
    definitions: z.array(z.union([BuiltInRuleSchema, CustomRuleSchema])),
    perClientBandwidthLimits: z.object({
      settings: PerClientBandwidthLimitsSettingsSchema,
      bandwidthLimits: z
        .object({
          limitUp: z.number(),
          limitDown: z.number(),
        })
        .optional(),
    }),
    dscpTagValue: z.number().nullable(),
    pcpTagValue: z.number().optional().nullable(),
  })
  .describe("SSIDTrafficShapingRuleSchema");

export const SsidTrafficShapingRulesSchema = z
  .object({
    trafficShapingEnabled: z.boolean(),
    defaultRulesEnabled: z.boolean(),
    rules: z.array(SSIDTrafficShapingRuleSchema),
  })
  .describe("SSIDTrafficShapingRulesSchema");

export type SsidTrafficShapingRule = z.infer<typeof SSIDTrafficShapingRuleSchema>;
export type SsidTrafficShapingRules = z.infer<typeof SsidTrafficShapingRulesSchema>;

interface SsidTrafficShapingRulesBuildUrlRequest {
  networkId?: string;
  ssidNumber: string;
}

interface SsidTrafficShapingRulesRequest {
  networkId?: string;
  ssidNumber?: number;
}

interface MutateSsidTrafficShapingRulesRequest extends SsidTrafficShapingRulesBuildUrlRequest {
  rules: SsidTrafficShapingRules;
}

const buildUrl = ({ networkId, ssidNumber }: SsidTrafficShapingRulesBuildUrlRequest) => {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/trafficShaping/rules`;
};

const fetchSsidTrafficShapingRules = ({
  networkId,
  ssidNumber,
}: SsidTrafficShapingRulesRequest) => {
  if (ssidNumber === undefined) {
    throw new Error("SSID Number is not defined");
  }
  return request(
    SsidTrafficShapingRulesSchema,
    "GET",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
  );
};

export const useSsidTrafficShapingRules = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  queryFn: (parameters: SsidTrafficShapingRulesRequest) => fetchSsidTrafficShapingRules(parameters),
  requiredVariables: ["networkId", "ssidNumber"],
});

const updateSsidTrafficShapingRules = ({
  networkId,
  ssidNumber,
  rules,
}: MutateSsidTrafficShapingRulesRequest) => {
  return request(SsidTrafficShapingRulesSchema, "PUT", buildUrl({ networkId, ssidNumber }), {
    body: JSON.stringify(rules),
  });
};

export const useUpdateSsidTrafficShapingRules = createMutation<
  MutateSsidTrafficShapingRulesRequest,
  SsidTrafficShapingRules,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  mutationFn: (parameters) => updateSsidTrafficShapingRules({ ...parameters }),
});
