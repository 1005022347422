import { I18n } from "@meraki/core/i18n";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  List,
  Loader,
  Slider,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  AlertSettingsItem,
  queryClient,
  useAlertSettings,
  useCurrentAdminUser,
  useGetWirelessSettings,
  useOrgNetwork,
  useUmbrellaProtection,
  useUpdateAlertSettings,
} from "@meraki/shared/api";
import { useCurrentOrganizationId, useCurrentUserEmail } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import { Alert } from "react-native";
import { Pressable } from "react-native";
import { useDispatch } from "react-redux";

import {
  findSliderIndexForGB,
  findSliderIndexForKB,
  getGBForSliderIndex,
  getKBForSliderIndex,
  HardwareDownTypes,
  MAX_THRESHOLD_INDEX,
  THRESHOLD_INDEX_STEPS,
} from "../utils/AlertsUtils";

type AlertSettingsCardProp = {
  alert: AlertSettingsItem;
  networkId: string;
  pushNotificationsEnabled: boolean;
};

type SettingType = "push" | "email" | "filter";

export const AlertSettingsCard = ({
  alert,
  networkId,
  pushNotificationsEnabled,
}: AlertSettingsCardProp) => {
  const navigation = useNavigation<NativeStackNavigationProp<AlertsStackProps>>();
  const organizationId = useCurrentOrganizationId();
  const email = useCurrentUserEmail();
  const { data: network } = useOrgNetwork({ organizationId, networkId });

  const { mutate: updateAlertSettings, isLoading } = useUpdateAlertSettings();

  const hasGR = network?.productTypes.includes("wireless") ?? false;
  const hasGX = network?.productTypes.includes("appliance") ?? false;
  const hasGS = network?.productTypes.includes("switch") ?? false;
  const dispatch = useDispatch();

  const showBanner = () => {
    if (pushNotificationsEnabled) return;
    dispatch({ type: "SET_SHOW_ALERT_BANNER", showAlertBanner: true });
  };

  const { data: locationAnalyticsEnabled } = useGetWirelessSettings(
    { networkId },
    {
      select: (data) => {
        return data.locationAnalyticsEnabled;
      },
    },
  );
  const isLocationAnalyticsEnabled = (locationAnalyticsEnabled ?? false) && hasGR;
  const hasHardware = hasGR || hasGS || hasGX;

  const { data: umbrellaProtection } = useUmbrellaProtection(
    { networkId },
    {
      select: (data) => {
        return data.umbrellaProtectionEnabled;
      },
    },
  );
  const umbrellaEnabled = umbrellaProtection ?? false;
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const { data: user } = useCurrentAdminUser({ organizationId, email });

  const [pushEnabled, setPushEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(
    alert.alertDestinations.emails.includes(email) ?? false,
  );

  useEffect(() => {
    setPushEnabled(alert.alertDestinations.mobileAdminIds?.includes(String(user?.id)) ?? false);
  }, [alert.alertDestinations.mobileAdminIds, user?.id]);

  const isClientTracking = alert?.type === "clientConnectivity";
  const isUsage = alert?.type === "usageAlert";

  const initialSliderIndex =
    getGBForSliderIndex(findSliderIndexForKB(alert.filters?.threshold)) ?? 0;

  const [sliderIndex, setSliderIndex] = useState(initialSliderIndex);

  const isToggleDisabled = () => {
    switch (alert.type) {
      case "clientConnectivity":
        return !hasGX;
      case "gatewayDown":
      case "applianceDown":
        return !hasHardware;
      case "usageAlert":
        return false;
      case "weeklyPresence":
        return !isLocationAnalyticsEnabled;
      case "weeklyUmbrella":
        return !umbrellaEnabled;
      default:
        return true;
    }
  };

  const getTitleTranslationKey = () => {
    switch (alert.type) {
      case "clientConnectivity":
        return "TRACK_CLIENT";
      case "gatewayDown":
      case "applianceDown":
        return "DEVICE_OFFLINE";
      case "usageAlert":
        return "NETWORK_USAGE";
      case "weeklyPresence":
        return "WEEKLY_PRESENCE";
      case "weeklyUmbrella":
        return "SECURITY_INSIGHTS";
      default:
        return "";
    }
  };

  const onToggleChange = (enabled: boolean, type: SettingType) => {
    const hardwareDownAlerts: AlertSettingsItem[] = [];
    let emailDestinations: string[] = alert.alertDestinations.emails;
    let pushDestinations: string[] = alert.alertDestinations.mobileAdminIds ?? [];

    if (type === "push") {
      enabled
        ? pushDestinations.push(user?.id ?? "")
        : (pushDestinations =
            alert.alertDestinations.mobileAdminIds?.filter(
              (mobileAdminId) => mobileAdminId !== user?.id,
            ) ?? []);
    }
    if (type === "email") {
      enabled
        ? emailDestinations.push(email)
        : (emailDestinations = alert.alertDestinations.emails.filter(
            (thisEmail) => thisEmail !== email,
          ));
    }

    const alertEnabled = pushDestinations.length > 0 || emailDestinations.length > 0;

    const alertDestinations = {
      ...alert.alertDestinations,
      emails: emailDestinations,
      mobileAdminIds: pushDestinations,
    };
    let alertSettingsItems: AlertSettingsItem[] = [
      {
        filters: alert.filters,
        enabled: alertEnabled,
        type: alert.type,
        alertDestinations: alertDestinations,
      },
    ];
    if (alert.type === "gatewayDown" || alert.type === "applianceDown") {
      HardwareDownTypes.forEach((alertType) => {
        hardwareDownAlerts.push({
          filters: alert.filters,
          enabled: alertEnabled,
          type: alertType,
          alertDestinations: alertDestinations,
        });
      });
      alertSettingsItems = hardwareDownAlerts;
    }
    if (type === "filter") {
      alertSettingsItems = [
        {
          filters: {
            ...alert.filters,
            threshold: getKBForSliderIndex(findSliderIndexForGB(sliderIndex)),
          },
          enabled: alert.enabled,
          type: alert.type,
          alertDestinations: alert.alertDestinations,
        },
      ];
    }
    const alertSettings = { alerts: alertSettingsItems };

    updateAlertSettings(
      { networkId, alertSettings },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          if (type === "email") {
            setEmailEnabled(enabled);
          } else if (type === "push") {
            setPushEnabled(enabled);
          }
          bottomSheetRef.current?.dismiss();
        },

        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useAlertSettings.queryKeyRoot });
        },
      },
    );
  };

  return (
    <Card>
      <Card.Header
        title={I18n.t(`ALERT_SETTINGS.${getTitleTranslationKey()}`)}
        rightAccessory={isLoading && <Loader.Spinner />}
      />
      <Text color="light">{I18n.t(`ALERT_SETTINGS.${getTitleTranslationKey()}_TEXT`)}</Text>
      <List>
        <List.Item
          title={I18n.t("ALERT_SETTINGS.EMAIL_NOTIF")}
          rightAccessory={
            <Toggle
              checked={emailEnabled}
              onValueChange={(newValue: boolean) => {
                onToggleChange(newValue, "email");
              }}
              disabled={isToggleDisabled()}
              testID={`${getTitleTranslationKey()}_TOGGLE.EMAIL.${emailEnabled ? "ON" : "OFF"}.${
                isToggleDisabled() ? "DISABLED" : "ENABLED"
              }`}
            />
          }
        />
        <List.Item
          title={I18n.t("ALERT_SETTINGS.PUSH_NOTIF")}
          rightAccessory={
            <Pressable onPress={() => showBanner()}>
              <Toggle
                checked={pushEnabled}
                onValueChange={(newValue: boolean) => {
                  onToggleChange(newValue, "push");
                }}
                disabled={isToggleDisabled() || !pushNotificationsEnabled}
                testID={`${getTitleTranslationKey()}_TOGGLE.PUSH.${pushEnabled ? "ON" : "OFF"}.${
                  isToggleDisabled() || !pushNotificationsEnabled ? "DISABLED" : "ENABLED"
                }`}
              />
            </Pressable>
          }
        />
        {isClientTracking && (
          <List.Item
            title={I18n.t("ALERT_SETTINGS.TRACK_DEVICES")}
            rightAccessory={
              <Button
                text={
                  alert.filters?.clients?.length === 1
                    ? I18n.t("ALERT_SETTINGS.CLIENT")
                    : I18n.t("ALERT_SETTINGS.CLIENTS", {
                        numTrackedClients: alert.filters?.clients?.length,
                      })
                }
                kind="tertiary"
                onPress={() => navigation.navigate("TrackedClients", { networkId })}
              />
            }
          />
        )}
        {isUsage && (
          <List.Item
            title={I18n.t("ALERT_SETTINGS.USAGE_LIMIT")}
            rightAccessory={
              <Button
                text={I18n.t("ALERT_SETTINGS.GB_IN_20", {
                  usage: initialSliderIndex,
                })}
                kind="tertiary"
                onPress={() => bottomSheetRef.current?.present()}
                testID="USAGE_SLIDER_BUTTON"
              />
            }
          />
        )}
      </List>
      {isUsage && (
        <BottomSheet.Modal
          ref={bottomSheetRef}
          snapPoints={["CONTENT_HEIGHT"]}
          index={0}
          onDismiss={() => {
            setSliderIndex(initialSliderIndex);
          }}
        >
          <BottomSheet.Header
            title={I18n.t("ALERT_SETTINGS.USAGE_LIMIT")}
            onCancelPress={() => {
              setSliderIndex(initialSliderIndex);
              bottomSheetRef.current?.dismiss();
            }}
            onResetPress={() => setSliderIndex(initialSliderIndex)}
          />
          <BottomSheet.Content>
            <Card>
              <Box flexDirection="row" justifyContent="space-between">
                <Text>{I18n.t("ALERT_SETTINGS.USAGE_IN_20")}</Text>
                <Text color="interact.text.base">{sliderIndex.toString()} GB</Text>
              </Box>
              <Slider
                minimumValue={0}
                maximumValue={Number(getGBForSliderIndex(MAX_THRESHOLD_INDEX))}
                step={getGBForSliderIndex(THRESHOLD_INDEX_STEPS)}
                onValueChange={(idx) => setSliderIndex(idx)}
                value={sliderIndex}
                testID="USAGE_SLIDER"
              />
            </Card>
            <Box paddingVertical="sm">
              <Button text={I18n.t("SAVE")} onPress={() => onToggleChange(false, "filter")} />
            </Box>
          </BottomSheet.Content>
        </BottomSheet.Modal>
      )}
    </Card>
  );
};
