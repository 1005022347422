import z from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

interface CreateGoOrganizationRequest {
  body: {
    name: string;
    timeZone: string;
  };
}

type CreateGoNetworkError = {
  error: string;
};

export const CreateGoNetworkSchema = z.object({
  success: z.boolean(),
  name: z.string(),
  time_zone: z.string(),
});

export type CreateGoNetwork = z.infer<typeof CreateGoNetworkSchema>;

function createGoNetwork({ body }: CreateGoOrganizationRequest): Promise<CreateGoNetwork> {
  return request(CreateGoNetworkSchema, "POST", "/go/create_network", {
    body: JSON.stringify({
      name: body.name,
      time_zone: body.timeZone,
    }),
  });
}

export const useCreateGoNetwork = createMutation<
  CreateGoOrganizationRequest,
  CreateGoNetwork,
  CreateGoNetworkError
>({
  baseMutationKey: "/go/create_network",
  mutationFn: (params: CreateGoOrganizationRequest) => createGoNetwork(params),
});
