import { I18n } from "@meraki/core/i18n";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status, StatusType } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useFailedConnections } from "@meraki/shared/api";
import { FailedConnectionGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const MAX_FAILED_CONNECTIONS = 10;

export function FailedConnectionsCard() {
  const navigation = useNavigation<NativeStackNavigationProp<FailedConnectionGroupProps>>();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data, isLoading } = useFailedConnections({ networkId, timespan });
  const total = data?.length ?? 0;

  let status: StatusType = "positive";
  let failedConnectionsText = I18n.t("FAILED_CONNECTIONS_CARD.STATUS_TEXT.POSITIVE");
  if (total >= MAX_FAILED_CONNECTIONS) {
    status = "negative";
    failedConnectionsText = I18n.t("FAILED_CONNECTIONS_CARD.STATUS_TEXT.NEGATIVE");
  } else if (total > 0) {
    status = "warning";
    failedConnectionsText = I18n.t("FAILED_CONNECTIONS_CARD.STATUS_TEXT.WARNING");
  }

  return (
    <Card
      loading={isLoading}
      loadingContentHeight={60}
      onPress={() => navigation.navigate("FailedWirelessConnectionsList")}
      testID="FAILED_CONNECTIONS_CARD"
    >
      <Card.Header title={I18n.t("FAILED_CONNECTIONS_CARD.HEADING")} />
      <Card.Content>
        <Card.Well>
          <Heading size="h2">{total}</Heading>
          <Box flexDirection="row" alignItems="center" gap="2xs">
            <Text weight="bold">{failedConnectionsText}</Text>
            <Status status={status} />
          </Box>
        </Card.Well>
      </Card.Content>
    </Card>
  );
}
