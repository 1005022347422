import { TextStyle, ViewStyle } from "react-native";

export interface Domain {
  x?: number[];
  y?: number[];
}
interface DataCoords {
  x: string;
  y: string;
}

export interface DataStyle {
  color?: string;
  strokeWidth?: number;
  strokeDasharray?: number;
}

export type DataKey = DataCoords & DataStyle;

export enum ColorOptions {
  standard,
  primaryCompared,
  secondaryCompared,
}

// The structure of each element is [standardColor, primaryComparedColor, secondaryComparedColor]
export type DataKeyColors = [string, string, string];

export type AxisFormatter = (...args: any[]) => string;

export type SelectedElements = Set<string>;

export type LegendElementStyle = {
  elementContainer: ViewStyle;
  selectedLabel: TextStyle;
  selectedColorHolder: ViewStyle;
  colorsContainer: ViewStyle;
  comparisonPadding: ViewStyle;
  colorHolder: ViewStyle;
  label: TextStyle;
};
