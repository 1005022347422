import { createDateFormatter, formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { Card, Loader, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";
import { DeviceStatus, useDeviceById, useOrgNetworks } from "@meraki/shared/api";
import { SingleBarGraph, SingleBarGraphData } from "@meraki/shared/components";
import { DEVICE_STATUS_MAP } from "@meraki/shared/devices";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";

import { getStatusTranslations } from "../constants/Status";

const getGraphColor = (value: number): ColorToken | undefined => {
  switch (value) {
    case 0:
      return "dormant.bg.base";
    case 1:
      return "negative.bg.base";
    case 2:
      return "severeWarning.bg.base";
    case 3:
      return "warning.bg.base";
    case 4:
      return "positive.bg.base";
    default:
      return "negative.bg.base";
  }
};

export const getGraphData = (uptimeData?: number[][]) => {
  if (uptimeData === undefined || uptimeData.length <= 0) {
    return { graphValues: [] as SingleBarGraphData[], minTime: 0, midTime: 0, maxTime: 0 };
  }

  const minTime = uptimeData?.[0]?.[0] ?? 0;
  const maxTime = Date.now();
  const midTime = maxTime - (maxTime - minTime) / 2;
  const totalTime = maxTime - minTime;

  const graphValues: SingleBarGraphData[] = uptimeData.map((uptime, index) => {
    const currentTime = uptime[0] ?? 0;
    const currentValue = uptime[1] ?? 0;

    const nextTime = uptimeData[index + 1]?.[0] ?? maxTime;
    const percentage = (nextTime - currentTime) / totalTime;
    return {
      percentage: percentage,
      color: getGraphColor(currentValue),
    };
  });
  return { graphValues, minTime, midTime, maxTime };
};

interface ConnectivityCardProps {
  deviceStatus: DeviceStatus;
  deviceId: string;
}

const graphDateFormatter = createDateFormatter({
  dateFormat: "shortDate",
  timeFormat: "shortTime",
  unixTimestamp: false,
});

export const ConnectivityCard = ({ deviceStatus, deviceId }: ConnectivityCardProps) => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId);
      },
    },
  );
  const nodeGroupEncryptedId = network?.ngIds?.[deviceStatus.productType]?.ngEid;
  const { data: uptimeData, isLoading } = useDeviceById(
    { id: deviceId, nodeGroupEncryptedId },
    {
      select(data) {
        return data.nodes?.[deviceId]?.upseries;
      },
    },
  );

  const uptimeGraphData = getGraphData(uptimeData);

  const textStatus = deviceStatus.status ?? "dormant";
  const status = DEVICE_STATUS_MAP[deviceStatus.status || "dormant"];
  const dateString = deviceStatus?.lastReportedAt
    ? formatDate(deviceStatus?.lastReportedAt, { dateFormat: "date", timeFormat: "shortTime" })
    : I18n.t("UNKNOWN");

  const statusTranslations = getStatusTranslations();

  return (
    <Card loadingContentHeight={30} testID="CONNECTIVITY_CARD">
      <Box flexDirection="row" gap="sm">
        <Box marginTop="2xs">
          <Status status={status} size={20} testID={`STATUS_ICON_${status}`} />
        </Box>
        <Box flexDirection="column">
          <Text size="p1">{statusTranslations[textStatus]}</Text>
          <Text size="p2" color="light" testID="LAST_REPORTED_AT">
            {I18n.t("CONNECTIVITY_CARD.LAST_REPORTED_AT", { dateString })}
          </Text>
        </Box>
      </Box>
      {isLoading ? (
        <Loader.Skeleton />
      ) : uptimeGraphData && uptimeGraphData?.graphValues.length > 0 ? (
        <Box>
          <SingleBarGraph data={uptimeGraphData?.graphValues ?? []} testID="CONNECTIVITY_GRAPH" />
          <Box flex={1} flexDirection={"row"} justifyContent="space-between">
            <Text size="p4">{graphDateFormatter(uptimeGraphData?.minTime ?? 0)}</Text>
            <Text size="p4">{graphDateFormatter(uptimeGraphData?.midTime ?? 0)}</Text>
            <Text size="p4">{graphDateFormatter(uptimeGraphData?.maxTime ?? 0)}</Text>
          </Box>
        </Box>
      ) : null}
    </Card>
  );
};
