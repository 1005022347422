import { I18n } from "@meraki/core/i18n";
import { Collapse, Text } from "@meraki/magnetic/components";
import { Checkbox, ViewSwitcher } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { DateTimeInput } from "@meraki/shared/components";
import { MILLISECONDS_IN_A_DAY } from "@meraki/shared/formatters";
import { MS_IN_A_SECOND } from "@meraki/shared/redux";
import { useCallback, useEffect, useReducer, useState } from "react";

export interface ScheduleChangeState {
  startTime: number;
  endTime: number;
  isAvailable: boolean;
  isAllDay: boolean;
}

interface ScheduledSSIDCardProps {
  dayOfWeek: string;
  initialValue?: {
    start: number;
    end: number;
    isAvailable: boolean;
  };

  onChange: ({ startTime, endTime, isAvailable, isAllDay }: ScheduleChangeState) => void;
}

export function ScheduledSSIDCard({ dayOfWeek, onChange, initialValue }: ScheduledSSIDCardProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const [{ startTime, endTime, isAvailable, isAllDay }, updateCard] = useReducer(
    (state: ScheduleChangeState, partial: Partial<ScheduleChangeState>) => ({
      ...state,
      ...partial,
    }),
    {
      startTime: initialValue?.start ?? 0,
      endTime: initialValue?.end ?? 0,
      isAvailable: initialValue?.isAvailable ?? true,
      isAllDay:
        initialValue?.start === initialValue?.end ||
        (initialValue?.end ?? 0) - (initialValue?.start ?? 0) === MILLISECONDS_IN_A_DAY,
    },
  );

  useEffect(() => {
    updateCard({
      startTime: initialValue?.start ?? 0,
      endTime: initialValue?.end ?? 0,
      isAvailable: initialValue?.isAvailable ?? true,
      isAllDay:
        initialValue?.start === initialValue?.end ||
        (initialValue?.end ?? 0) - (initialValue?.start ?? 0) === MILLISECONDS_IN_A_DAY,
    });
  }, [initialValue]);

  const updateMethod = useCallback(() => {
    let trueStartTime = startTime;
    let trueEndTime = endTime;
    if (isAllDay) {
      trueStartTime = 0;
      trueEndTime = MILLISECONDS_IN_A_DAY;
    }
    onChange?.({
      startTime: trueStartTime,
      endTime: trueEndTime,
      isAvailable,
      isAllDay: isAllDay,
    });
  }, [startTime, endTime, isAvailable, isAllDay, onChange]);

  return (
    <Collapse title={dayOfWeek} onToggle={() => setIsCollapsed(!isCollapsed)} open={isCollapsed}>
      <Box paddingVertical="sm">
        <ViewSwitcher
          options={["Available", "Unavailable"]}
          value={
            isAvailable
              ? I18n.t("SCHEDULED_SSID.CARD.AVAILABLE")
              : I18n.t("SCHEDULED_SSID.CARD.UNAVAILABLE")
          }
          onChange={(available) => {
            updateCard({ isAvailable: available === "Available" });
            updateMethod();
          }}
        />
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          paddingVertical="sm"
        >
          <DateTimeInput
            initialDate={new Date(startTime)}
            onConfirm={(time: Date) => {
              const timeChosen = time.getTime();
              updateCard({
                startTime: timeChosen,
                isAllDay:
                  timeChosen === endTime ||
                  (timeChosen === 0 && (endTime === 0 || endTime === MS_IN_A_SECOND)),
              });
              updateMethod();
            }}
            mode="time"
          />
          <Icon name="ArrowRight" />
          <DateTimeInput
            initialDate={new Date(endTime)}
            onConfirm={(time: Date) => {
              updateCard({ endTime: time.getTime() });
              const timeChosen = time.getTime();
              updateCard({
                endTime: timeChosen,
                isAllDay:
                  timeChosen === startTime ||
                  (startTime === 0 && (timeChosen === 0 || timeChosen === MS_IN_A_SECOND)),
              });
              updateMethod();
            }}
            mode="time"
          />
        </Box>
        <Box flex={1} flexDirection="row-reverse" paddingVertical="sm">
          <Checkbox
            checked={isAllDay}
            onValueChange={(isChecked) => {
              updateCard({ isAllDay: isChecked });
              updateMethod();
            }}
          />
          <Box paddingHorizontal="sm">
            <Text>{I18n.t("SCHEDULED_SSID.CARD.ALL_DAY")}</Text>
          </Box>
        </Box>
      </Box>
    </Collapse>
  );
}
