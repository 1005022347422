import { DeviceStatus, DeviceStatusesSchema } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useQuery } from "@tanstack/react-query";

import organizationsKeys from "~/api/queries/organizations/keys";
import { DEFAULT_REFETCH_INTERVAL } from "~/api/queries/queryDefaults";
import { validatedMerakiRequest } from "~/api/util/request";

const orgDeviceStatusesKeys = {
  deviceStatuses: (orgId?: string) =>
    [...organizationsKeys.organizations(orgId), "deviceStatuses"] as const,
};

const fetchOrgDeviceStatuses = (orgId: string): Promise<DeviceStatus[]> => {
  return validatedMerakiRequest(
    DeviceStatusesSchema,
    "GET",
    `/api/v1/organizations/${orgId}/devices/statuses`,
  );
};

const useOrgDeviceStatuses = () => {
  const orgId = useCurrentOrganizationId();
  return useQuery<DeviceStatus[], Error>({
    queryKey: orgDeviceStatusesKeys.deviceStatuses(orgId),
    queryFn: async () => {
      if (!orgId) {
        throw new Error("Org is not set");
      }
      return await fetchOrgDeviceStatuses(orgId);
    },
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });
};

export default useOrgDeviceStatuses;
