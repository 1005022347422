import { I18n } from "@meraki/core/i18n";
import { SelectClientBottomSheet } from "@meraki/go/client";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, SwitchPort, useSwitchPorts, useUpdateSwitchPort } from "@meraki/shared/api";
import { MAC_REGEX } from "@meraki/shared/filters";
import { Form, useForm } from "@meraki/shared/form";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";
import { Alert } from "react-native";

export interface MACAllowListForm {
  deviceMac: string;
}

export function MACAllowListConfigureScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "MACAllowListConfigure">>();
  const selectClientBottomSheetRef = useRef<BottomSheetMethods>(null);
  const updateSwitchPort = useUpdateSwitchPort();
  const { switchPortId, serial } = route.params;
  const { data: switchPort } = useSwitchPorts(
    {
      serial,
    },
    {
      select(data) {
        return data.find((port) => port.portId === switchPortId);
      },
    },
  );
  const methods = useForm<MACAllowListForm>({
    defaultValues: {
      deviceMac: "",
    },
  });

  const onSave = useCallback(
    ({ deviceMac }: MACAllowListForm) => {
      const macAllowList = [...(switchPort?.macAllowList ?? []), deviceMac];
      updateSwitchPort.mutate(
        {
          serial,
          portId: switchPortId,
          switchPort: { accessPolicyType: "MAC allow list", macAllowList } as SwitchPort,
        },
        {
          onSuccess() {
            queryClient.invalidateQueries({ queryKey: useSwitchPorts.queryKey({ serial }) });
            navigation.goBack();
          },
          onError(error) {
            Alert.alert(I18n.t("ERROR"), String(error.errors));
          },
        },
      );
    },
    [navigation, serial, switchPort?.macAllowList, switchPortId, updateSwitchPort],
  );

  useEffect(() =>
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSave)} />
      ),
    }),
  );

  return (
    <Screen addDefaultPadding>
      <Box>
        <Box alignItems="flex-end" paddingBottom="none">
          <Button
            kind="tertiary"
            text={I18n.t("MAC_ALLOW_LIST.SELECT_FROM_DEVICE_LIST")}
            trailingIcon="Plus"
            onPress={() => selectClientBottomSheetRef?.current?.present()}
            testID="MAC_ALLOW_LIST.SELECT_DEVICE"
          />
        </Box>
        <Form {...methods}>
          <Form.Input
            label={I18n.t("MANUAL_DEVICE_INPUT.DEVICE_MAC_ADDRESS")}
            name="deviceMac"
            rules={{
              required: I18n.t("MANUAL_DEVICE_INPUT.MAC_REQUIRED"),
              validate: (newValue: string) => {
                if (!MAC_REGEX.test(newValue)) {
                  return I18n.t("MANUAL_DEVICE_INPUT.MAC_INVALID");
                }
                if (switchPort?.macAllowList?.includes(newValue)) {
                  return I18n.t("MANUAL_DEVICE_INPUT.MAC_ALREADY_ALLOWED");
                }
                return undefined;
              },
            }}
            additionalContext={I18n.t("MANUAL_DEVICE_INPUT.MAC_PLACEHOLDER")}
            placeholder={I18n.t("MANUAL_DEVICE_INPUT.MAC_PLACEHOLDER")}
            testID="MAC_ADDRESS_INPUT"
          />
        </Form>
      </Box>
      <SelectClientBottomSheet
        snapPoints={["90%"]}
        onSelect={({ mac }) => {
          methods.setValue("deviceMac", mac, { shouldDirty: true, shouldValidate: true });
        }}
        ref={selectClientBottomSheetRef}
      />
    </Screen>
  );
}
