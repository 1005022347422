import { I18n } from "@meraki/core/i18n";
import { Screen } from "@meraki/magnetic/layout";
import {
  useCounterSets,
  useDevices,
  useGetWirelessSettings,
  useNetworkUsageblocks,
  useOrgNetwork,
  useSsids,
} from "@meraki/shared/api";
import { PickerCard } from "@meraki/shared/components";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
  useHomeSSID,
} from "@meraki/shared/redux";
import { RefreshControl } from "react-native";

import { ApplicationUsageCard } from "../components/ApplicationUsageCard";
import { NetworkUsageCard } from "../components/NetworkUsageCard";
import { PresenceReportCard } from "../components/PresenceReportCard";
import { SSIDPickerCard } from "../components/SSIDPickerCard";

export const InsightsScreen = () => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { data: network, refetch: refetchNetwork } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const encryptedNetworkId = network?.eid;
  const timespan = useGlobalTimespan();
  const ssid = useHomeSSID();

  const {
    data: hasAccessPoints,
    refetch: refetchAccessPoints,
    isRefetching: isRefetchingAccessPoints,
  } = useDevices(
    {
      organizationId,
      networkId,
      productTypes: ["wireless"],
    },
    {
      select: (data) => data.length > 0,
    },
  );

  const {
    data: wirelessSettings,
    refetch: refetchWirelessSettings,
    isRefetching: isRefetchingWirelessSettings,
  } = useGetWirelessSettings({ networkId }, { enabled: hasAccessPoints });

  const { refetch: refetchSSIDs } = useSsids({ networkId });

  const { isRefetching: isRefetchingNetworkUseBlocks, refetch: refetchNetworkUsage } =
    useNetworkUsageblocks({
      encryptedNetworkId,
      timespan,
      ssid,
    });

  const { refetch: refetchCounterSets, isRefetching: isRefetchingCounterSets } = useCounterSets({
    encryptedNetworkId,
  });
  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={
            isRefetchingWirelessSettings ||
            isRefetchingNetworkUseBlocks ||
            isRefetchingCounterSets ||
            isRefetchingAccessPoints
          }
          onRefresh={() => {
            refetchWirelessSettings();
            refetchAccessPoints();
            refetchSSIDs();
            refetchNetwork();
            refetchNetworkUsage();
            refetchCounterSets();
          }}
        />
      }
      addDefaultPadding
    >
      <PickerCard.Time title={I18n.t("TIMEPICKER_TITLE")} />
      <SSIDPickerCard />
      {hasAccessPoints && wirelessSettings?.locationAnalyticsEnabled && <PresenceReportCard />}
      <NetworkUsageCard />
      <ApplicationUsageCard />
    </Screen>
  );
};
