import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import {
  SESSION_LENGTH,
  TOTAL_SESSION_TIME_IDX,
  useOrgNetwork,
  usePresenceReport,
} from "@meraki/shared/api";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { formatDistance, secondsToMilliseconds } from "date-fns";

export const PresenceReportCard = () => {
  const navigation = useNavigation<NativeStackNavigationProp<InsightsStackProps, "Insights">>();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data: network, isFetching: isFetchingNetworks } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const wirelessNetworkEid = network?.ngIds?.wireless?.ngEid;

  const { data: presenceReport, isFetching: isFetchingReport } = usePresenceReport(
    {
      organizationId,
      networkEid: wirelessNetworkEid ?? "",
      timespan,
    },
    { enabled: !!wirelessNetworkEid },
  );

  let numOfVisitors = 0;
  let totalVisitTime = 0;
  presenceReport?.forEach(([_, datum]) => {
    numOfVisitors += datum.proximity.visitors;
    totalVisitTime += datum.sessions.reduce(
      (total, session) =>
        session.length < SESSION_LENGTH ? total : total + session[TOTAL_SESSION_TIME_IDX],
      0,
    );
  });

  return (
    <Card
      loading={isFetchingNetworks || isFetchingReport}
      testID="PRESENCE_REPORT_CARD"
      onPress={() => navigation.navigate("PresenceReport", {})}
    >
      <Card.Header title={I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.HEADING")} />
      <Card.Content flexDirection="row" gap="xs">
        <Card.Well flex={1}>
          <Heading>{numOfVisitors}</Heading>
          <Card.Content flexDirection="row" alignItems="center" gap="2xs">
            <Text size="p3" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.VISITORS")}
            </Text>
          </Card.Content>
        </Card.Well>
        <Card.Well flex={1}>
          <Heading>
            {formatDistance(
              0,
              secondsToMilliseconds(
                totalVisitTime && numOfVisitors && totalVisitTime / numOfVisitors,
              ),
              { includeSeconds: true },
            )}
          </Heading>
          <Card.Content flexDirection="row" alignItems="center" gap="2xs">
            <Text size="p3" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.AVERAGE_VISIT_LENGTH")}
            </Text>
          </Card.Content>
        </Card.Well>
      </Card.Content>
    </Card>
  );
};
