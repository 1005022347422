import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import InputRow from "~/go/rows/InputRow";
import { platformSelect } from "~/lib/PlatformUtils";
import MkiSegmentedControl from "~/shared/components/MkiSegmentedControl";
import MkiText from "~/shared/components/MkiText";

interface PhoneSectionProps {
  showNumber: boolean;
  number: string;
  onTabPress: (index: number) => void;
  onChangeNumber: (text: string) => void;
  onSubmitEditing: () => void;
}

const PhoneSection = (props: PhoneSectionProps) => {
  const { showNumber, number, onTabPress, onChangeNumber, onSubmitEditing } = props;
  return (
    <View testID="PHONE_SECTION">
      <View style={styles.sectionContainer}>
        <MkiText textStyle="small" screenStyles={styles.label} testID="SEGMENT_DESCRIPTION">
          {I18n.t("SUPPORT.SECTION.PHONE_TOGGLE")}
        </MkiText>
        <MkiSegmentedControl
          values={[
            I18n.t("SUPPORT.CONTACT_OPTIONS.PHONE"),
            I18n.t("SUPPORT.CONTACT_OPTIONS.EMAIL_ONLY"),
          ]}
          selectedIndex={showNumber ? 0 : 1}
          onTabPress={onTabPress}
        />
      </View>
      {showNumber && (
        <InputRow
          onChangeText={onChangeNumber}
          placeholder={I18n.t("SUPPORT.PLACEHOLDER.NUMBER")}
          value={number}
          returnKeyType={RETURN_KEY.next}
          onSubmitEditing={onSubmitEditing}
          keyboardType={platformSelect({
            ios: KEYBOARD_TYPE.numbersAndPunctuation,
            android: KEYBOARD_TYPE.phonePad,
          })}
          testID="PHONE_NUMBER_INPUT"
        >
          {I18n.t("SUPPORT.SECTION.PHONE")}
        </InputRow>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    color: MkiColors.formFieldLabel,
  },
  sectionContainer: {
    marginTop: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.small,
  },
});

export default PhoneSection;
