import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, List, Toggle } from "@meraki/magnetic/components";
import { Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { queryClient, useSSIDSchedules, useUpdateSSIDSchedules } from "@meraki/shared/api";
import {
  dateFormatter,
  DAYS,
  invertRange,
  mergeOfflineTimes,
  OfflineRange,
  SECONDS_IN_A_DAY,
  Weekday,
} from "@meraki/shared/formatters";
import { showOkayAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useReducer, useState } from "react";

import { ScheduleChangeState, ScheduledSSIDCard } from "../components/ScheduledSSIDCard";

interface ReducerState {
  enabled: boolean;
  offlineTimes: { [_ in Weekday]: OfflineRange[] };
}

export function SchedulesScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "Schedules">>();
  const { params: props } = route;
  const { ssidNumber } = props;

  const networkId = useCurrentNetworkId();

  const { data: schedules } = useSSIDSchedules({ networkId, ssidNumber: ssidNumber });
  const updateSSID = useUpdateSSIDSchedules();
  const [availabilities, setAvailabilities] = useState(
    dateFormatter(schedules?.rangesInSeconds ?? []),
  );

  const [{ enabled, offlineTimes }, updateState] = useReducer(
    (state: ReducerState, partial: Partial<ReducerState>) => ({
      ...state,
      ...partial,
    }),
    {
      enabled: false,
      offlineTimes: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
    },
  );

  const updateOfflineTimes = useCallback(
    (day: Weekday, { startTime, endTime, isAllDay, isAvailable }: ScheduleChangeState) => {
      const newOfflineTimes = { ...offlineTimes };
      let start = startTime;
      let end = endTime;
      if (isAllDay && isAvailable) {
        newOfflineTimes[day] = [];
        updateState({ offlineTimes: { ...newOfflineTimes } });
        return;
      }
      if (isAllDay) {
        start = 0;
        end = SECONDS_IN_A_DAY * 1000;
      }
      if (isAvailable) {
        const offlineRange = invertRange(start / 1000, end / 1000);
        newOfflineTimes[day] = offlineRange;
      } else {
        newOfflineTimes[day] = [{ start: start / 1000, end: end / 1000 }];
      }
      updateState({ offlineTimes: { ...newOfflineTimes } });
    },
    [offlineTimes],
  );

  const onSave = useCallback(() => {
    const offsettedRanges = [];
    for (const day in offlineTimes) {
      const weekday = day as Weekday;
      const ranges = offlineTimes[weekday];
      const dayOffset = DAYS.indexOf(weekday) * SECONDS_IN_A_DAY;
      offsettedRanges.push(
        ...ranges.map((range) => ({
          start: range.start + dayOffset,
          end: range.end + dayOffset,
        })),
      );
    }
    updateSSID.mutate(
      {
        ssidNumber,
        networkId,
        body: {
          enabled,
          rangesInSeconds: mergeOfflineTimes(offsettedRanges),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSSIDSchedules.queryKeyRoot });
          navigation.goBack();
        },
        onError: (error) => showOkayAlert(I18n.t("ERROR"), error.errors[0] ?? ""),
      },
    );
  }, [offlineTimes, enabled, navigation, networkId, ssidNumber, updateSSID]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <Button.Nav text={I18n.t("SAVE")} onPress={onSave} />,
    });
  }, [navigation, onSave]);

  useEffect(() => {
    setAvailabilities(dateFormatter(schedules?.rangesInSeconds ?? []));
  }, [schedules]);

  useEffect(() => {
    const daysForOfflineTimes: { [day in Weekday]: OfflineRange[] } = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    };
    for (const day in availabilities) {
      const weekday = day as Weekday;
      const schedule = availabilities?.[weekday];
      if (!schedule) {
        daysForOfflineTimes[weekday] = [];
      } else if (schedule.isAvailable) {
        const { start, end } = schedule;
        const offlineRange = invertRange(start / 1000, end / 1000);
        daysForOfflineTimes[weekday] = offlineRange;
      } else {
        const { start, end } = schedule;
        daysForOfflineTimes[weekday] = [{ start: start / 1000, end: end / 1000 }];
      }
    }
    updateState({ offlineTimes: { ...daysForOfflineTimes } });
  }, [availabilities]);

  useEffect(() => {
    updateState({ enabled: schedules?.enabled ?? false });
  }, [schedules]);

  return (
    <Screen addDefaultPadding>
      <Text size="p1">{I18n.t("SCHEDULED_SSID.DESCRIPTION")}</Text>
      <List>
        <List.Item
          title={I18n.t("SCHEDULED_SSID.ENABLED")}
          rightAccessory={<Toggle checked={enabled} />}
        />
      </List>
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.SUNDAY")}
        onChange={(data) => {
          updateOfflineTimes("sunday", data);
        }}
        initialValue={availabilities?.["sunday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.MONDAY")}
        onChange={(data) => updateOfflineTimes("monday", data)}
        initialValue={availabilities?.["monday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.TUESDAY")}
        onChange={(data) => updateOfflineTimes("tuesday", data)}
        initialValue={availabilities?.["tuesday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.WEDNESDAY")}
        onChange={(data) => updateOfflineTimes("wednesday", data)}
        initialValue={availabilities?.["wednesday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.THURSDAY")}
        onChange={(data) => updateOfflineTimes("thursday", data)}
        initialValue={availabilities?.["thursday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.FRIDAY")}
        onChange={(data) => updateOfflineTimes("friday", data)}
        initialValue={availabilities?.["friday"]}
      />
      <ScheduledSSIDCard
        dayOfWeek={I18n.t("DAYS_OF_THE_WEEK.SATURDAY")}
        onChange={(data) => updateOfflineTimes("saturday", data)}
        initialValue={availabilities?.["saturday"]}
      />
    </Screen>
  );
}
