import z from "zod";

import { request } from "../../../api/request/request";
import { PortVlanSchema } from "../../../schemas";
import { createQuery } from "../../createQuery";

export const AppliancePortSchema = PortVlanSchema.partial()
  .extend({
    number: z.number(),
    enabled: z.boolean(),
    dropUntaggedTraffic: z.boolean(),
    accessPolicy: z.string().optional(),
    allowedVlans: z.string().optional(),
    vlan: z.number().nullable().optional(),
  })
  .describe("AppliancePortSchema");

export const UpdateAppliancePortsSchema = AppliancePortSchema.partial();

export const AppliancePortsSchema = z.array(AppliancePortSchema);

export type AppliancePort = z.infer<typeof AppliancePortSchema>;

export interface AppliancePortsRequest {
  networkId?: string;
}

export interface AppliancePortRequest extends AppliancePortsRequest {
  portId: string;
}

function buildAllUrl({ networkId }: AppliancePortsRequest) {
  return `/api/v1/networks/${networkId}/appliance/ports`;
}

export function buildSingleUrl({ networkId, portId }: AppliancePortRequest) {
  return `/api/v1/networks/${networkId}/appliance/ports/${portId}`;
}

function fetchAppliancePort(variables: AppliancePortRequest): Promise<AppliancePort> {
  return request(AppliancePortSchema, "GET", buildSingleUrl(variables));
}

function fetchAllAppliancePorts(variables: AppliancePortsRequest): Promise<AppliancePort[]> {
  return request(AppliancePortsSchema, "GET", buildAllUrl(variables));
}

export const useAllAppliancePorts = createQuery<AppliancePortsRequest, AppliancePort[]>({
  baseQueryKey: buildAllUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchAllAppliancePorts(variables),
  requiredVariables: ["networkId"],
});

export const useAppliancePort = createQuery<AppliancePortRequest, AppliancePort>({
  baseQueryKey: buildSingleUrl({ networkId: "{networkId}", portId: "{portId}" }),
  queryFn: (variables) => fetchAppliancePort(variables),
  requiredVariables: ["networkId", "portId"],
});
