import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { Features } from "~/go/types/ContextHelpTypes";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { currentNetworkState, isLocationAnalyticsEnabled } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import SwitchRow from "~/shared/rows/SwitchRow";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { SettingsStackProps } from "../navigation/Types";

type ReduxProps = {
  networkId: string;
  locationAnalyticsEnabled: boolean;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ToggleLocationAnalytics"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

export interface ToggleLocationAnalyticsState {
  showAlert: boolean;
}

export class ToggleLocationAnalyticsScreen extends PureComponent<
  Props,
  ToggleLocationAnalyticsState
> {
  constructor(props: Props) {
    super(props);
    this.state = { showAlert: true };
  }

  componentDidMount() {
    this.getLocationAnalytics();
  }

  handleError = (error: unknown) => {
    showAlert(I18n.t("ERROR"), error);
  };

  getLocationAnalytics = async () => {
    const { actions, setReqPending, networkId } = this.props;

    setReqPending(true);
    try {
      await actions.getWirelessSettings(networkId);
    } catch (error) {
      this.handleError(error);
    }
    setReqPending(false);
  };

  saveToggle = async (isEnabled: boolean) => {
    const { actions, setReqPending, networkId } = this.props;

    setReqPending(true);
    try {
      await actions.setLocationAnalyticsEnabled(networkId, isEnabled);
    } catch (error) {
      this.handleError(error);
    }
    setReqPending(false);
  };

  renderLocationAnalyticsSwitch = () => {
    const { locationAnalyticsEnabled } = this.props;
    return (
      <SwitchRow
        subtitle={I18n.t("TOGGLE_LOCATION_ANALYTICS.TOGGLE_SUBTITLE")}
        value={locationAnalyticsEnabled}
        onValueChange={this.saveToggle}
        testID="YOUR_ACCOUNT.LOCATION_ANALYTICS"
        context={Features.visitorInfoDetailed}
      >
        {I18n.t("TOGGLE_LOCATION_ANALYTICS.TOGGLE_TITLE")}
      </SwitchRow>
    );
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  render() {
    return (
      <FullScreenContainerView withScroll>
        {this.renderLocationAnalyticsSwitch()}
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    locationAnalyticsEnabled: isLocationAnalyticsEnabled(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for ToggleLocationAnalyticsScreen",
      currentNetworkState(state),
    ),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(ToggleLocationAnalyticsScreen);
