export const spaceSeparatedStringToArray = (string: any) => {
  if (!string || string === "") {
    return [];
  }
  return string.split(" ").filter((result: any) => result !== "");
};

export const getDuplicatesForArray = (array: any) => {
  if (!array) {
    return [];
  }
  return array.filter(
    (element: any, index: any, inputArray: any) => inputArray.indexOf(element) !== index,
  );
};

export const getHumanReadableList = (array: any, joinWord = "and") => {
  switch (array.length) {
    case 0:
      return "";
    case 1:
      return array[0];
    case 2:
      return array.join(` ${joinWord} `);
    default:
      return `${array.slice(0, -1).join(", ")} ${joinWord} ${array[array.length - 1]}`;
  }
};

export const removeElementAtIndex = <T>(arr: T[], idx?: number) => {
  const output: T[] = [];
  if (!arr) {
    return [];
  }

  [...arr].forEach((element, i) => {
    if (i != idx) {
      output.push(element);
    }
  });
  return output;
};
