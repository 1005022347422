import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useQuery } from "@tanstack/react-query";

import networksKeys from "~/api/queries/networks/keys";
import { Network, NetworkSchema } from "~/api/schemas/Network";
import { useDependentQuery } from "~/api/util/query";
import { validatedMerakiRequest } from "~/api/util/request";

const networkKeys = {
  network: (networkId?: string) => [...networksKeys.networks, networkId] as const,
};

const fetchNetwork = (networkId: string): Promise<Network> => {
  return validatedMerakiRequest(NetworkSchema, "GET", `/api/v1/networks/${networkId}`);
};

const useNetwork = (networkId: string) => {
  return useQuery({
    queryKey: networkKeys.network(networkId),
    queryFn: () => fetchNetwork(networkId),
  });
};

export const useCurrentNetwork = () => {
  const networkId = useCurrentNetworkId();
  return useDependentQuery({
    dependencies: [networkId] as const,
    queryKey: networkKeys.network(networkId),
    queryFn: () => (networkId_) => fetchNetwork(networkId_),
  });
};

export default useNetwork;
