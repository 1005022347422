import DataMsg from "../types/DataMsg";

interface UplinkStats {
  in: number;
  out: number;
  combined: number;
}

export type UplinkUsage = [number, UplinkStats];
export type UplinkUsages = Record<string, UplinkUsage[]>;

export interface Message extends DataMsg {
  data: {
    cellular: UplinkUsage[];
    wan0: UplinkUsage[];
    wan1: UplinkUsage[];
  };
}

export function formatMsg(message?: Message): UplinkUsages | undefined {
  const data = message?.data;
  return data;
}
