import { oneHour, oneMonth, tenMinutes } from "@meraki/shared/redux";
import { isEmpty } from "lodash";
import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export interface ChannelUtilizationDataPoint {
  start_ts: string;
  end_ts: string;
  non_wifi: number;
  wifi: number;
  utilization: number;
}
export interface ChannelUtilizationStats {
  mac: string;
  serial: string;
  wifi0: ChannelUtilizationDataPoint[];
  wifi1: ChannelUtilizationDataPoint[];
}

interface ChannelUtilizationRequest {
  organizationId?: string;
}

export interface ChannelUtilizationRequestParams extends ChannelUtilizationRequest {
  timespan?: number;
  networkIds: string[];
  serials: string[];
  interval: number;
}

const ChannelUtilizationPerBand = z.object({
  band: z.enum(["2.4", "5"]),
  nonWifi: z.object({
    percentage: z.number(),
  }),
  wifi: z.object({
    percentage: z.number(),
  }),
  total: z.object({
    percentage: z.number(),
  }),
});

type ChannelUtilizationPerBand = z.infer<typeof ChannelUtilizationPerBand>;

const ChannelUtilizationPerDevice = z.object({
  endTs: z.string(),
  startTs: z.string(),
  mac: z.string(),
  serial: z.string(),
  byBand: z.array(ChannelUtilizationPerBand),
});

export type ChannelUtilizationPerInterval = z.infer<typeof ChannelUtilizationPerDevice>;

export const ChannelUtilizationSchema = z
  .array(ChannelUtilizationPerDevice)
  .describe("ChannelUtilizationSchema");

export type ChannelUtilization = z.infer<typeof ChannelUtilizationSchema>;

const buildUrl = ({ organizationId }: ChannelUtilizationRequest) => {
  return `/api/v1/organizations/${organizationId}/wireless/devices/channelUtilization/history/byDevice/byInterval`;
};

const fetchChannelUtilizations = ({
  organizationId,
  ...queryParams
}: ChannelUtilizationRequestParams): Promise<ChannelUtilization> => {
  const { timespan, interval } = queryParams;
  if (timespan && timespan >= oneMonth && interval <= tenMinutes) {
    if (__DEV__) {
      console.warn(
        "useChannelUtilization - Using an interval of less than 10 minutes with a timespan of a month or greater is not supported. Setting interval to one hour",
      );
    }
    queryParams.interval = oneHour;
  }
  return request(ChannelUtilizationSchema, "GET", buildUrl({ organizationId }), { queryParams });
};

export const useChannelUtilization = createQuery<
  ChannelUtilizationRequestParams,
  ChannelUtilization
>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (parameters: ChannelUtilizationRequestParams) => fetchChannelUtilizations(parameters),
});

export const filterChannelUtilizationByDevice = (utilizations: ChannelUtilizationPerInterval[]) => {
  if (!utilizations || utilizations.length === 0) {
    return;
  }

  const wifiUtilizationsByBand: Record<
    ChannelUtilizationPerBand["band"],
    ChannelUtilizationDataPoint[]
  > = {
    "2.4": [],
    "5": [],
  };

  let mac = "";
  let serial = "";
  utilizations.forEach((utilization, idx) => {
    if (idx === 0) {
      mac = utilization.mac;
      serial = utilization.serial;
    }
    utilization.byBand.forEach(({ band, total, wifi, nonWifi }) => {
      wifiUtilizationsByBand[band].push({
        utilization: total.percentage,
        wifi: wifi.percentage,
        non_wifi: nonWifi.percentage,
        start_ts: utilization.startTs,
        end_ts: utilization.endTs,
      });
    });
  });

  const reducedResponse: ChannelUtilizationStats = {
    mac,
    serial,
    wifi0: wifiUtilizationsByBand["2.4"],
    wifi1: wifiUtilizationsByBand["5"],
  };
  return reducedResponse;
};

export const useChannelUtilizationByDevice = ({
  organizationId,
  networkIds,
  serials,
  timespan,
}: ChannelUtilizationRequestParams) => {
  const interval = timespan && timespan >= oneMonth ? oneHour : tenMinutes;
  return useChannelUtilization(
    { organizationId, networkIds, serials, timespan, interval },
    {
      select: (data) => filterChannelUtilizationByDevice(data ?? []),
      enabled: !isEmpty(organizationId) && !isEmpty(networkIds) && !isEmpty(serials),
    },
  );
};
