import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import { appSelect } from "~/lib/PlatformUtils";
import BannerAlert, { BannerAlertType } from "~/shared/components/BannerAlert";
import DetailsHeaderConnections from "~/shared/components/header/DetailsHeaderConnections";
import MerakiIcon, { IconSizeTypes } from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import GeneralStatus, { StatusType } from "~/shared/constants/Status";
import { useThemeColors } from "~/shared/hooks/useTheme";
import ThemeInterface from "~/shared/themes/ThemeInterface";
import { ConnectionType } from "~/shared/types/Device";

interface DetailsHeaderProps {
  status?: StatusType;
  connectionName?: string;
  description?: string | React.ReactNode; // what appears in the header
  connectionType?: ConnectionType;
  alert?: BannerAlertType;
  CustomHeader?: React.ReactElement;
  connectedNode?: string;
  connectedNodeOnPress?: () => void;
  connectionOnPress?: () => void;
  isMeshDevice?: boolean;
  testID?: string;
  iconSize?: IconSizeTypes;
  skipDisplayPort?: boolean;
}

interface DetailsHeaderTitleProps {
  status?: StatusType;
  isGateway?: boolean;
  label: string | React.ReactNode;
}

const DetailsHeaderTitle = ({ status, isGateway, label }: DetailsHeaderTitleProps) => (
  <View style={styles.description}>
    {status && (
      <StatusIcon
        testID={`${status}-icon`}
        status={status}
        screenStyles={styles.statusIcon}
        isHollow={isGateway}
      />
    )}
    {label}
  </View>
);

const getAlertIconStyling = (type: StatusType, statusColors: ThemeInterface["status"]) => {
  if (type === GeneralStatus.alerting) {
    return { name: "warning", color: statusColors.alerting.color };
  }
  return { name: "block", color: statusColors.offline.color };
};

const AlertBanner = ({ alert }: { alert: BannerAlertType }) => {
  const { status } = useThemeColors();
  const icon = getAlertIconStyling(alert.alertType, status);
  return (
    <BannerAlert
      testID="banner-alert"
      alertType={alert.alertType}
      alertText={alert.alertText}
      textAlign="left"
      customIcon={
        <MerakiIcon
          testID={`${icon.name}-icon-${status}`}
          name={icon.name}
          size="xs"
          color={icon.color}
          containerStyle={styles.bannerIcon}
        />
      }
    />
  );
};

const DetailsHeader = (props: DetailsHeaderProps) => {
  const {
    status,
    connectionType,
    connectionName,
    alert,
    CustomHeader,
    connectedNode,
    testID,
    isMeshDevice,
    description,
    connectedNodeOnPress,
    connectionOnPress,
    iconSize,
    skipDisplayPort,
  } = props;

  const descriptionStyle: TEXT_STYLE_NAME = appSelect({
    enterprise: "header",
    go: "heading",
  });

  const descriptionLabel =
    typeof description === "string" ? (
      <MkiText textStyle={descriptionStyle}>{description}</MkiText>
    ) : (
      description
    );

  const shouldShowConnectionInfo =
    connectionType && connectionName && connectedNode && connectionType !== ConnectionType.none;

  return (
    <View style={styles.viewStyle} testID={testID}>
      <DetailsHeaderTitle label={descriptionLabel} status={status} isGateway={isMeshDevice} />
      {CustomHeader}
      {shouldShowConnectionInfo && (
        <DetailsHeaderConnections
          connectionName={connectionName}
          connectedNode={connectedNode}
          connectionType={connectionType}
          connectedNodeOnPress={connectedNodeOnPress}
          connectionOnPress={connectionOnPress}
          iconSize={iconSize}
          skipDisplayPort={skipDisplayPort}
        />
      )}
      {alert && alert?.alertText && <AlertBanner alert={alert} />}
    </View>
  );
};

const styles = StyleSheet.create({
  viewStyle: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "stretch",
    marginHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
    ...appSelect({
      enterprise: {},
      go: {
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: MkiColors.bottomBorderColor,
      },
    }),
  },
  description: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: SPACING.default,
  },
  statusIcon: {
    marginRight: SPACING.small,
  },
  bannerIcon: {
    marginRight: SPACING.medium,
  },
});

export default DetailsHeader;
