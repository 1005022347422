import { formatDate } from "@meraki/core/date";
import { DAY } from "@meraki/shared/redux";
import { PureComponent } from "react";
import { Dimensions, StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING, UPTIME_COLOR_STATUSES, WINDOW } from "~/constants/MkiConstants";
import { computeBarGraph } from "~/lib/graphUtils";
import { isWeb } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";
import SingleBarGraph from "~/shared/components/SingleBarGraph";
import { NetworkHealthData } from "~/shared/types/Networks";

const DEFAULT_GRAPH_WIDTH_OFFSET = 40;
const DEFAULT_GRAPH_HEIGHT = 12;

interface UptimeGraphProps {
  graphHeight: number;
  graphWidthOffset: number;
  timespan: number;
  data: NetworkHealthData;
}

type UptimeGraphState = {
  windowWidth: number;
};

export default class UptimeGraph extends PureComponent<UptimeGraphProps, UptimeGraphState> {
  private eventListener: any;

  static defaultProps = {
    data: undefined,
    graphHeight: DEFAULT_GRAPH_HEIGHT,
    graphWidthOffset: DEFAULT_GRAPH_WIDTH_OFFSET,
  };

  state = { windowWidth: Dimensions.get(WINDOW).width * (isWeb() ? 0.5 : 1) };

  UNSAFE_componentWillMount() {
    this.eventListener = Dimensions.addEventListener("change", this.handleWindowChange);
  }

  componentWillUnmount() {
    this.eventListener?.remove();
  }

  handleWindowChange = (newDims: any) =>
    this.setState({ windowWidth: newDims.window.width * (isWeb() ? 0.5 : 1) });

  generateGraphLabel(timestamp: any) {
    const { timespan } = this.props;
    const label =
      timespan > DAY.value
        ? formatDate(timestamp, { dateFormat: "shortDate", unixTimestamp: false })
        : formatDate(timestamp, {
            dateFormat: "hide",
            timeFormat: "shortTime",
            unixTimestamp: false,
          });
    return (
      <MkiText textStyle="small" screenStyles={styles.chartLabels}>
        {label}
      </MkiText>
    );
  }

  render() {
    const { windowWidth } = this.state;
    const { graphHeight, graphWidthOffset, data: entryData } = this.props;
    const graphStyles = {
      width: windowWidth - graphWidthOffset,
      height: graphHeight,
    };

    if (!entryData || entryData.entries.length === 0) {
      return <SingleBarGraph data={[]} graphStyles={graphStyles} roundCorners />;
    }

    const { entries, t0, t1 } = entryData;
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ t0: number; t1: number; data: ... Remove this comment to see the full error message
    const data = computeBarGraph({
      t0,
      t1,
      data: entries,
      width: graphStyles.width,
    }).map((d) => ({
      percentage: (d.xaxis.to - d.xaxis.from) / (t1 - t0),
      color: UPTIME_COLOR_STATUSES[d.status],
    }));

    return (
      <View style={styles.graphWithLabels}>
        <SingleBarGraph data={data} graphStyles={graphStyles} roundCorners />
        <View style={styles.graphLabels}>
          {this.generateGraphLabel(t0)}
          {this.generateGraphLabel((t0 + t1) / 2)}
          {this.generateGraphLabel(t1)}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  graphWithLabels: {
    flexDirection: "column",
    paddingTop: 5,
  },
  graphLabels: {
    paddingHorizontal: SPACING.meager,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: SPACING.small,
  },
  chartLabels: {
    color: MkiColors.secondaryTextColor,
  },
});
