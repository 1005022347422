import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import InfoModal from "~/go/components/InfoModal";
import I18n from "~/i18n/i18n";
import { removeWebAlertComponent } from "~/lib/WebAlertUtils";
import { MkiText } from "~/shared/components/MkiText";

interface WebAlertProps {
  title: string;
  message: string;
  cancelable?: boolean;
  positiveText?: string;
  negativeText?: string;
  onPress?: () => void;
  onNegativePress?: () => void;
}

export default class WebAlert extends PureComponent<WebAlertProps> {
  onClose = () => {
    // unmount itself
    removeWebAlertComponent();
  };

  onPrimaryPress = () => {
    const { onPress } = this.props;
    onPress?.();
    this.onClose();
  };

  onSecondaryPress = () => {
    const { onNegativePress } = this.props;
    if (onNegativePress) {
      onNegativePress();
    }

    this.onClose();
  };

  renderButton(buttonLabel?: string, onPress = () => {}) {
    return (
      buttonLabel && (
        <TouchableOpacity style={styles.buttonContainer} onPress={onPress}>
          <View style={styles.buttonStyle}>
            <MkiText textStyle="label" screenStyles={styles.buttonText}>
              {buttonLabel}
            </MkiText>
          </View>
        </TouchableOpacity>
      )
    );
  }

  render() {
    const { title, message, positiveText, negativeText } = this.props;

    return (
      <InfoModal visible={true} onExit={this.onClose}>
        <MkiText textStyle="title" screenStyles={styles.titleText}>
          {title}
        </MkiText>
        <MkiText textStyle="heading" screenStyles={styles.messageText}>
          {message}
        </MkiText>
        <View style={styles.buttonWrapper}>
          {this.renderButton(positiveText || I18n.t("OK"), this.onPrimaryPress)}
          {this.renderButton(negativeText, this.onSecondaryPress)}
        </View>
      </InfoModal>
    );
  }
}

const styles = StyleSheet.create({
  titleText: {
    fontSize: 16,
  },
  messageText: {
    fontSize: 14,
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonText: {
    color: MkiColors.primaryButton,
    fontSize: 12,
  },
  buttonContainer: {
    paddingVertical: SPACING.small,
    alignSelf: "stretch",
  },
  buttonStyle: {
    backgroundColor: MkiColors.fullTransparent,
    paddingHorizontal: SPACING.default,
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
});
