import { isEmpty, isEqual } from "lodash";

// This method determines if a specific nested value exists
//  within an object. If an fVal is inlcuded, it will be returned
//  in the case that the nested value doesn't exist, and the nested
//  value will be returned in the case that it does. Otherwise, the
//  funciton will simply return true or false.

export function nestedValueExists(obj: any, keys: any, fVal?: any) {
  let temp = obj;
  const retVal = fVal === undefined ? false : fVal;
  if (!temp) {
    return retVal;
  }
  for (let i = 0; i < keys.length; i += 1) {
    const val = temp[keys[i]];
    if (val === null || val === undefined) {
      return retVal;
    }
    temp = val;
  }

  return fVal === undefined ? true : temp;
}

/*
@parameters
Object | required
Specifies a generic Javascript object.

@return
An object of key-values, sorted by key.
If no keys exist on the object, the object is returned.
*/
export function sortObjectByKeys<T extends object>(obj: T): T | object {
  const sortedData: any = {};
  const keys = Object.keys(obj).sort();
  keys.forEach((key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    sortedData[key] = obj[key];
  });
  return isEmpty(sortedData) ? obj : sortedData;
}

/*
@parameters
Object | required
Specifies a generic Javascript object.

@parameters
NormalizeKey | required
Specifies a generic Javascript object.

@return
The object, normalized by the specified key.
If no key is specified, the object is returned.

@deprecated Use 'keyBy' from lodash instead since it is typed
*/
export function normalizeObjectByKey(object: object, normalizeKey: any) {
  if (!object) {
    return undefined;
  }
  if (!normalizeKey) {
    return object;
  }
  const working: any = {};
  Object.entries(object).forEach(([, value]) => {
    if (value[normalizeKey]) {
      working[value[normalizeKey]] = value;
    }
  });
  return working;
}

export function normalizeObjectToKeyValue(object: any, normalizeKey: any, value: any) {
  if (!object || Array.isArray(object) || !normalizeKey || !value || isEmpty(object)) {
    return undefined;
  }

  const working: any = {};
  for (const objectKey in object) {
    const objectValue = object[objectKey];
    working[objectValue[normalizeKey]] = objectValue[value];
  }

  return working;
}

export const mergeObjectsByUpdatedTimestamp = (
  obj1: any,
  timestamp1: any,
  obj2: any,
  timestamp2: any,
) => {
  if (!obj1 && !obj2) {
    return undefined;
  }
  if (!obj1) {
    return obj2;
  }
  if (!obj2) {
    return obj1;
  }

  let newerData;
  let olderData;

  if (timestamp1 > timestamp2) {
    newerData = obj1;
    olderData = obj2;
  } else {
    newerData = obj2;
    olderData = obj1;
  }

  return { ...olderData, ...newerData };
};

// Traverses one layer into an object, inspecting for emptiness
export const isDeepEmpty = (nestedObject: any) => {
  if (isEmpty(nestedObject)) {
    return true;
  }
  let hasData = false;
  hasData = Object.values(nestedObject).some((value) => {
    return !isEmpty(value);
  });

  return !hasData;
};

// Index each object in an array by the value of a property contained within that object.
// Objects that do not contain the given "key" will not be added to the indexed array.
export function indexObjectsByKey(objects: object[], key: string) {
  if (isEmpty(objects) || !key) {
    return undefined;
  }
  return objects.reduce((accumulator, elem: any) => {
    return nestedValueExists(elem, [key])
      ? {
          ...accumulator,
          [elem[key]]: elem,
        }
      : accumulator;
  }, {});
}

export function shallowDiffChanges(oldObject: any, newObject: any, keys?: any) {
  if (!newObject) {
    return {};
  }

  const resolvedKeys = keys ? keys : Object.keys(newObject);
  const diff: any = {};

  resolvedKeys.forEach((key: string) => {
    if (!isEqual(oldObject?.[key], newObject[key])) {
      diff[key] = newObject[key];
    }
  });

  return diff;
}
