import * as errorMonitor from "@meraki/core/errors";
import { Box } from "@meraki/magnetic/layout";
import {
  ColorToken,
  getColorFromTokenName,
  Theme,
  useMagneticTheme,
} from "@meraki/magnetic/themes";
import { isEqual } from "lodash";
import { memo, useCallback, useState } from "react";
import { LayoutChangeEvent } from "react-native";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

import { getIndexToGraphColorToken } from "./SingleBarGraphUtils";

export interface SingleBarGraphData {
  percentage: number;
  color?: ColorToken;
}

interface SegmentProps {
  data: SingleBarGraphData[];
  width: number;
  height: number;
  theme: Theme;
}

const Segments = ({ data, width, height, theme }: SegmentProps) => {
  let startPoint = 0;

  const barSegments = data.map((datum, idx) => {
    /* Steps to build the chart
     * 1. multiply the percentage parameter by the overall width and adjust it accordingly
     * 2. build the shape "d" using the start point
     * 3. set the fill color
     */

    if (typeof datum.percentage !== "number") {
      // Temporary until we find all cases where d values are dynamic
      errorMonitor.notify("Invalid datum percentage");
    }

    const datumPercentage = datum.percentage || 0;
    const datumWidth = Math.max(Math.round(width * datumPercentage), 1);
    const dParam = `M ${startPoint} 0 H ${datumWidth + startPoint} V ${height} H ${startPoint} Z`;
    const fillColor = getColorFromTokenName(datum.color || getIndexToGraphColorToken(idx), theme);
    // reason for the random is beacuse react doesn't allow two children with the same key, but
    // it is possible for two bar segments to have the same percentage and color.
    const shapeElement = (
      <Path
        key={`percent:${datum.percentage}${Math.random() / 1000000}color:${fillColor}`}
        d={dParam}
        fill={fillColor}
      />
    );

    /* Finishing steps
     * 1. set the startPoint for the next segment
     * 2. return the next shape in the barSegements array
     */
    startPoint += datumWidth;
    return shapeElement;
  });

  return <G>{barSegments}</G>;
};

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

const CHART_HEIGHT = 12;

type Props = {
  data: SingleBarGraphData[];
  testID?: string;
};

export const SingleBarGraph = memo(function SingleBarGraph({ data, testID }: Props) {
  const theme = useMagneticTheme();
  const [width, setWidth] = useState<number | null>(null);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      {!!width && width > 0 && (
        <Svg width={Math.round(width)} height={CHART_HEIGHT}>
          <Defs>
            <ClipPath id="chartMask">
              <Rect
                width={width}
                height={CHART_HEIGHT}
                rx={CHART_HEIGHT / 2}
                ry={CHART_HEIGHT / 2}
              />
            </ClipPath>
          </Defs>
          <G clipPath="url(#chartMask)">
            <Rect width={width} height={CHART_HEIGHT} fill={theme.color.default.bg.base} />
            <Segments data={data} width={width} height={CHART_HEIGHT} theme={theme} />
          </G>
        </Svg>
      )}
    </Box>
  );
}, areEqual);
