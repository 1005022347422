import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { SSIDRowList, SSIDRowListForm } from "@meraki/go/ssid";
import { Button, List, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  ApplianceL7FirewallRule,
  queryClient,
  useApplianceL7FirewallRules,
  useConfiguredSsids,
  useNetwork,
  useSetApplianceL7FirewallRules,
  useSetSsidL7FirewallRules,
  useSsidL7FirewallRules,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { getHostFromUrl, isValidUrl, toList } from "@meraki/shared/formatters";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { capitalize } from "lodash";
import { useCallback, useEffect } from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";

type Props = RouteProp<ConfigureStackProps, "BlockWebsite">;

interface BlockWebsiteScreenForm extends SSIDRowListForm {
  url: string;
  isConfiguredEverywhere: boolean;
}

export const BlockWebsiteScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<ConfigureStackProps, "BlockWebsite">>();
  const route = useRoute<Props>();
  const { params: props } = route;

  const networkId = useCurrentNetworkId();
  const { data: currentNetwork } = useNetwork({ networkId });
  const { data: ssids } = useConfiguredSsids({ networkId });
  const ssidRulesResults = useQueries({
    queries:
      ssids?.map(({ number: ssidNumber }) =>
        useSsidL7FirewallRules.useQueries({ networkId, ssidNumber: ssidNumber }),
      ) ?? [],
  });
  const ssidFirewallRules = ssidRulesResults.flatMap(({ data }) => data);

  const { data: applianceFirewallRules } = useApplianceL7FirewallRules({ networkId });
  const networkHasAppliance = currentNetwork?.productTypes.includes("appliance") ?? false;

  const { mutate: updateApplianceFirewallRules } = useSetApplianceL7FirewallRules();
  const { mutate: updateSsidFirewallRules } = useSetSsidL7FirewallRules();

  const methods = useForm<BlockWebsiteScreenForm>({
    defaultValues: {
      url: props.url,
      isConfiguredEverywhere: props.isConfiguredEverywhere,
      ssidNumbers: (props.isConfiguredEverywhere ? [] : props.ssidNumbers) ?? [],
    },
  });

  const onSave = useCallback(
    ({ url, isConfiguredEverywhere, ssidNumbers }: BlockWebsiteScreenForm) => {
      const ssidsToBlockOn = ssidNumbers.filter((ssid) => !props?.ssidNumbers?.includes(ssid));
      const ssidsToUnblockOn =
        props?.ssidNumbers?.filter((ssid) => !ssidNumbers.includes(ssid)) ?? [];

      const onSuccess = () => {
        const ssidNames: string[] = [];

        ssids?.forEach((ssid) => {
          if (ssidsToBlockOn.includes(ssid.number)) {
            ssidNames.push(ssid.name);
          }
        });
        queryClient.invalidateQueries({ queryKey: useApplianceL7FirewallRules.queryKeyRoot });
        queryClient.invalidateQueries({ queryKey: useSsidL7FirewallRules.queryKeyRoot });
        ssidNames.length > 0 &&
          Toast.showWithGravity(
            I18n.t("BLOCK_CONTENT.SUCCESS", {
              website: capitalize(getHostFromUrl(url)),
              ssids: toList(ssidNames),
            }),
            Toast.SHORT,
            Toast.BOTTOM,
          );
        navigation.goBack();
      };
      const newRule: ApplianceL7FirewallRule = { policy: "deny", type: "host", value: url };
      if (isConfiguredEverywhere) {
        // remove the rule from the ssids (if exists) and configure it on the appliance
        props.ssidNumbers?.forEach((ssid) => {
          if (ssidFirewallRules[ssid]?.rules.find((rule) => rule.value === url)) {
            const updatedSsidRules = ssidFirewallRules[ssid]?.rules.filter(
              (rule) => rule.value !== url,
            );
            updateSsidFirewallRules(
              {
                networkId,
                ssidNumber: ssid.toString(),
                rules: updatedSsidRules ?? [],
              },
              {
                onSuccess,
                onError: (error) => {
                  Alert.alert(String(error["errors"]));
                },
              },
            );
          }
        });
        const updatedApplianceRules = applianceFirewallRules
          ? [...applianceFirewallRules.rules, newRule]
          : [newRule];
        updateApplianceFirewallRules(
          { networkId, rules: updatedApplianceRules },
          {
            onSuccess,
            onError: (error) => {
              Alert.alert(String(error["errors"]));
            },
          },
        );
      } else {
        // remove the rule from the appliance if exists
        if (applianceFirewallRules?.rules.find((rule) => rule.value === url)) {
          const updatedApplianceRules = applianceFirewallRules?.rules.filter(
            (rule) => rule.value !== url,
          );
          updateApplianceFirewallRules(
            { networkId, rules: updatedApplianceRules ?? [] },
            {
              onSuccess,
              onError: (error) => {
                Alert.alert(String(error["errors"]));
              },
            },
          );
        }

        // configure the rule on blocked ssids
        ssidsToBlockOn.forEach((ssid) => {
          const currentRules = ssidFirewallRules[ssid]?.rules;
          if (currentRules?.find((rule) => rule.value === url)) {
            const thisSsid = ssids?.find((rawSsid) => rawSsid.number === ssid);
            Alert.alert(I18n.t("BLOCK_CONTENT.DUPLICATE_WEBSITE", { name: thisSsid?.name }));
          } else {
            const updatedSsidRules = currentRules ? [...currentRules, newRule] : [newRule];
            updateSsidFirewallRules(
              {
                networkId,
                ssidNumber: ssid.toString(),
                rules: updatedSsidRules,
              },
              {
                onSuccess,
                onError: (error) => {
                  Alert.alert(String(error["errors"]));
                },
              },
            );
          }
        });
        // remove the rule from unblockedssids
        ssidsToUnblockOn.forEach((ssid) => {
          const updatedSsidRules = ssidFirewallRules[ssid]?.rules.filter(
            (rule) => rule.value !== url,
          );
          updateSsidFirewallRules(
            {
              networkId,
              ssidNumber: ssid.toString(),
              rules: updatedSsidRules ?? [],
            },
            {
              onSuccess,
              onError: (error) => {
                Alert.alert(String(error["errors"]));
              },
            },
          );
        });
      }
    },
    [
      applianceFirewallRules,
      navigation,
      networkId,
      props.ssidNumbers,
      ssidFirewallRules,
      ssids,
      updateApplianceFirewallRules,
      updateSsidFirewallRules,
    ],
  );

  const onDelete = () => {
    if (props.isConfiguredEverywhere) {
      const updatedApplianceRules = applianceFirewallRules?.rules.filter(
        (rule) => rule.value !== props.url,
      );
      updateApplianceFirewallRules(
        { networkId, rules: updatedApplianceRules ?? [] },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: useApplianceL7FirewallRules.queryKeyRoot });
            queryClient.invalidateQueries({ queryKey: useSsidL7FirewallRules.queryKeyRoot });
            navigation.goBack();
          },
          onError: (error) => {
            Alert.alert(String(error["errors"]));
          },
        },
      );
    } else {
      props.ssidNumbers?.forEach((ssid) => {
        const updatedSsidRules = ssidFirewallRules[ssid]?.rules.filter(
          (rule) => rule.value !== props.url,
        );
        updateSsidFirewallRules(
          {
            networkId,
            ssidNumber: ssid.toString(),
            rules: updatedSsidRules ?? [],
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useApplianceL7FirewallRules.queryKeyRoot });
              queryClient.invalidateQueries({ queryKey: useSsidL7FirewallRules.queryKeyRoot });
              navigation.goBack();
            },
            onError: (error) => {
              Alert.alert(String(error["errors"]));
            },
          },
        );
      });
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSave)} />
      ),
    });
  }, [methods, navigation, onSave]);

  const watchedIsConfiguredEverywhere = methods.watch("isConfiguredEverywhere");
  return (
    <Screen addDefaultPadding>
      <Text size="p1">
        {I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.BLOCK_WEBSITE.DESCRIPTION")}
      </Text>
      <Form {...methods}>
        <Form.Input
          name="url"
          rules={{
            required: I18n.t("BLOCK_CONTENT.EMPTY_URL"),
            validate: (url) => {
              if (!isValidUrl(url)) {
                return I18n.t("BLOCK_CONTENT.INVALID_URL", { url });
              }
              return undefined;
            },
          }}
          additionalContext={I18n.t("BLOCK_CONTENT.SUBDOMAINS")}
          autoCapitalize="none"
          placeholder={I18n.t("BLOCK_CONTENT.BLOCK_WEBSITE_PLACEHOLDER")}
          testID="BLOCK_WEBSITE_URL_INPUT"
        />
        {networkHasAppliance && (
          <List>
            <List.Item
              title={I18n.t("BLOCK_CONTENT.BLOCK_EVERYWHERE")}
              description={I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.ALL_NETWORKS")}
              rightAccessory={
                <Form.Toggle
                  name="isConfiguredEverywhere"
                  testID={`BLOCK_WEBSITE_EVERYWHERE_TOGGLE_${
                    watchedIsConfiguredEverywhere ? "ON" : "OFF"
                  }`}
                />
              }
            />
          </List>
        )}
        {(!watchedIsConfiguredEverywhere || !networkHasAppliance) && <SSIDRowList />}
      </Form>
      {!props.isAdd && (
        <Button
          text={I18n.t("BLOCK_CONTENT.REMOVE")}
          kind="secondaryDestructive"
          onPress={() => {
            showDeleteConfirmAlert(
              I18n.t("BLOCK_CONTENT.DELETE_CONFIRM.TITLE", {
                domain: capitalize(getHostFromUrl(props.url ?? "")),
              }),
              I18n.t("BLOCK_CONTENT.DELETE_CONFIRM.SUBTITLE"),
              onDelete,
            );
          }}
          testID="BLOCK_WEBSITE_REMOVE_BUTTON"
        />
      )}
    </Screen>
  );
};
