import { TopNetworkSummariesByStatus } from "@meraki/shared/api";

import { NETWORK_STATUSES, PRODUCT_TYPES } from "./constants/SearchValues";
import { filterData } from "./SearchUtils";

interface NetworkFilter {
  statuses: typeof NETWORK_STATUSES;
  productTypes: typeof PRODUCT_TYPES;
}

export const filterNetworks = (
  networks: TopNetworkSummariesByStatus | undefined,
  searchText: string,
  filters: NetworkFilter,
) => {
  if (!networks) {
    return [];
  }

  const searchFilteredNetworks = filterData(
    networks || [],
    ["name", "tags"],
    searchText,
  ) as TopNetworkSummariesByStatus;

  if (Object.values(filters).every((arr) => arr.length === 0)) {
    return searchFilteredNetworks;
  }

  return searchFilteredNetworks.filter((network) => {
    const containsProductType = filters.productTypes.some((pt) =>
      network.productTypes.includes(pt),
    );
    const containsStatus = filters.statuses.includes(network.statuses.overall);
    return containsProductType || containsStatus;
  });
};
