import { z } from "zod";

import { request } from "../../api/request/request";
import { queryClient } from "../../queryClient";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface UmbrellaProtectionRequest {
  networkId?: string;
}

export const UmbrellaProtectionSchema = z
  .object({
    umbrellaProtectionEnabled: z.boolean(),
    umbrellaPolicyId: z.number().nullable(),
    excludedDomains: z.array(z.string()),
  })
  .describe("UmbrellaProtectionSchema");

export type UmbrellaProtection = z.infer<typeof UmbrellaProtectionSchema>;

interface UpdateUmbrellaProtectionRequest {
  networkId?: string;
  umbrellaProtection: UmbrellaProtection;
}

interface UmbrellaProtectionUpdateJobRequest {
  networkId?: string;
  updateJobId?: string;
}

export enum UmbrellaProtectionUpdateJobStatus {
  scheduled = "scheduled",
  new = "new",
  ready = "ready",
  running = "running",
  complete = "complete",
  failed = "failed",
}

export const UmbrellaProtectionUpdateJobStatusSchema = z.union([
  z.literal(UmbrellaProtectionUpdateJobStatus.scheduled),
  z.literal(UmbrellaProtectionUpdateJobStatus.new),
  z.literal(UmbrellaProtectionUpdateJobStatus.ready),
  z.literal(UmbrellaProtectionUpdateJobStatus.running),
  z.literal(UmbrellaProtectionUpdateJobStatus.complete),
  z.literal(UmbrellaProtectionUpdateJobStatus.failed),
] as const);

export const UmbrellaProtectionUpdateJobSchema = z
  .object({
    id: z.string(),
    status: UmbrellaProtectionUpdateJobStatusSchema,
    data: UmbrellaProtectionSchema.partial(),
  })
  .describe("UmbrellaProtectionUpdateJobSchema");

export type UmbrellaProtectionUpdateJob = z.infer<typeof UmbrellaProtectionUpdateJobSchema>;

interface UpdateUmbrellaProtectionError {
  errors?: string[];
}

const buildUrl = ({ networkId }: UmbrellaProtectionRequest) => {
  return `/api/v1/networks/${networkId}/appliance/umbrella/protection`;
};

const fetchUmbrellaProtection = ({ networkId }: UmbrellaProtectionRequest) => {
  return request(UmbrellaProtectionSchema, "GET", buildUrl({ networkId }));
};

const buildUrlForUpdate = ({ networkId, updateJobId }: UmbrellaProtectionUpdateJobRequest) => {
  if (!networkId) {
    throw new Error("Undefined network id when trying to update umbrealla protection");
  }
  return `/api/v1/networks/${networkId}/appliance/umbrella/protection/applyProtectionAttempts${
    updateJobId ? `/${updateJobId}` : ""
  }`;
};

function updateUmbrellaProtection({
  networkId,
  umbrellaProtection,
}: UpdateUmbrellaProtectionRequest): Promise<UmbrellaProtectionUpdateJob> {
  return request(UmbrellaProtectionUpdateJobSchema, "POST", buildUrlForUpdate({ networkId }), {
    body: JSON.stringify(umbrellaProtection),
  });
}

const fetchUmbrellaProtectionUpdateJob = async ({
  networkId,
  updateJobId,
}: UmbrellaProtectionUpdateJobRequest) => {
  const response = await request(
    UmbrellaProtectionUpdateJobSchema,
    "GET",
    buildUrlForUpdate({ networkId, updateJobId }),
  );

  if (response.status === UmbrellaProtectionUpdateJobStatus.complete) {
    queryClient.invalidateQueries({ queryKey: useUmbrellaProtection.queryKeyRoot });
  }

  return response;
};

export const useUmbrellaProtection = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: UmbrellaProtectionRequest) => fetchUmbrellaProtection(parameters),
  requiredVariables: ["networkId"],
});

export const useUpdateUmbrellaProtection = createMutation<
  UpdateUmbrellaProtectionRequest,
  UmbrellaProtectionUpdateJob,
  UpdateUmbrellaProtectionError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: UpdateUmbrellaProtectionRequest) => updateUmbrellaProtection(options),
});

export const useUmbrellaProtectionUpdateJob = createQuery({
  baseQueryKey: buildUrlForUpdate({ networkId: `{networkId}`, updateJobId: `{updateJobId}` }),
  queryFn: (options: UmbrellaProtectionUpdateJobRequest) =>
    fetchUmbrellaProtectionUpdateJob(options),
  requiredVariables: ["networkId"],
});
