import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { OnboardingFooterProps } from "~/go/components/onboarding/OnboardingFooter";
import RequestNotificationCard from "~/go/components/RequestNotificationCard";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { showAlert } from "~/lib/AlertUtils";
import {
  getNextOnboardingStageConfig,
  getPluginHardwareMap,
  nodesStatusToFooterStatus,
} from "~/lib/OnboardingFullstackUtils";
import { hasConfiguredWiFiSelector, onboardingDevices } from "~/selectors";
import { ExitButton } from "~/shared/navigation/Buttons";
import Device from "~/shared/types/Device";
import { OnboardingScannedDevices, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  onboardingDevices: Device[];
  hasConfiguredWiFi: boolean;
  scannedHardwareMap: OnboardingScannedDevices;
};

type Props = ForwardedNativeStackScreenProps<
  OnboardingStackProps,
  "OnboardingNotificationsRequest"
> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

export class OnboardingNotificationsRequestScreen extends BaseOnboardingScreen<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.notificationsRequest);
  }

  onClosePress = () => {
    const { hasConfiguredWiFi } = this.props;
    if (hasConfiguredWiFi || !this.hasScannedWifi()) {
      this.close();
    } else if (this.noScannedHardware()) {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.MESSAGE"),
        this.onAddHardwarePress,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.CANCEL_BUTTON"),
        },
      );
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.MESSAGE"),
        this.onWiFiSetupPress,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.CANCEL_BUTTON"),
        },
      );
    }
  };

  onAddHardwarePress = () => {
    this.pushOnboardingScreen(OnboardingStage.addHardware);
  };

  onWiFiSetupPress = () => {
    this.pushOnboardingScreen(OnboardingStage.wifiSetup);
  };

  showConnectAndUpgrade = () => {
    const { navigation } = this.props;
    navigation.navigate("ConnectAndUpgradeStatus");
  };

  nextButtonLocalizedKey = () => {
    if (this.noScannedHardware()) {
      return "BUTTON_EXIT";
    } else if (!this.hasScannedWifi()) {
      return "BUTTON_FINISH";
    }
    return "NEXT_BUTTON";
  };

  noScannedHardware = () => {
    const { onboardingDevices } = this.props;
    return isEmpty(onboardingDevices);
  };

  renderBody = () => {
    return <RequestNotificationCard onResult={(_) => this.onPrimaryPress()} onboarding />;
  };

  getFooterData = () => {
    const { status } = this.props;
    const footerPayload: OnboardingFooterProps = {
      buttons: [
        <RoundedButton
          key="nextButton"
          onPress={() => this.onPrimaryPress()}
          buttonType={ButtonType.primary}
        >
          {this.nextStageConfig.nextButtonText}
        </RoundedButton>,
      ],
    };

    if (this.noScannedHardware()) {
      return footerPayload;
    }

    footerPayload.status = nodesStatusToFooterStatus(status);
    footerPayload.onStatusPress = this.showConnectAndUpgrade;
    return footerPayload;
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.notificationsRequest, {
    noScannedHardware: this.noScannedHardware(),
    hasScannedWifi: this.hasScannedWifi(),
  });
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasConfiguredWiFi: hasConfiguredWiFiSelector(state),
    onboardingDevices: onboardingDevices(state),
    scannedHardwareMap: getPluginHardwareMap(onboardingDevices(state)),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(OnboardingNotificationsRequestScreen);
