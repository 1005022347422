import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { getPermission, LOCATION_PERMISSION_WHEN_IN_USE } from "@meraki/shared/permissions";
import NetInfo, { NetInfoStateType } from "@react-native-community/netinfo";
import { Linking, NativeModules } from "react-native";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AndroidWifiManager from "react-native-android-wifi";
import DeviceInfo from "react-native-device-info";

import { PERMISSIONS_ERRORS } from "~/constants/MkiConstants";
import { showAlert } from "~/lib/AlertUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { isIOS, platformSelect } from "~/lib/PlatformUtils";

// default to {} for android because IOSWifiManager.* is still evaluated in all the
//   Platform.select functions in this file on Android
const IOSWifiManager = NativeModules.IOSWifiManager || {};
const AndroidActivityStarter = NativeModules.ActivityStarter || {};
const CONNECT_INTERVAL = 2000; // 2 seconds
const MAX_WAIT = 30000; // 30 seconds, determined via testing
const SYSTEM_VERSION_REGEX = /^\d+\.?\d*/;
const SYSTEM_VERSION_PREFIX_REGEX = /^\d+/;

export const openSettings = () =>
  platformSelect({
    ios: () => Linking.openURL(IOSWifiManager.settingsURL),
    android: AndroidActivityStarter.navigateToWifiSettings,
  })();

// What SSID is the device currently connected to?
export const getSsid = () => {
  if (DeviceInfo.isEmulatorSync()) {
    return Promise.resolve("dev current ssid");
  }
  return platformSelect({
    ios: IOSWifiManager.currentSSID,
    android: () =>
      new Promise((resolve, reject) =>
        AndroidWifiManager.getSSID((ssid: any) =>
          ssid ? resolve(ssid) : reject(I18n.t("WIFI_UTILS.ERRORS.NOT_CONNECTED_TO_SSID")),
        ),
      ),
  })();
};

export const connectToSSID = (ssid: string, password?: string) =>
  platformSelect({
    ios: (ssid: string, password?: string) => {
      if (!canAutoConnectToWifi()) {
        throw I18n.t("WIFI_UTILS.ERRORS.OLD_IOS");
      }
      return password
        ? IOSWifiManager.connectToProtectedSSID(ssid, password, false)
        : IOSWifiManager.connectToSSID(ssid);
    },
    android: (ssid: string, password?: string) =>
      getPermission(LOCATION_PERMISSION_WHEN_IN_USE).then(
        () =>
          new Promise<void>((resolve, reject) =>
            AndroidWifiManager.reScanAndLoadWifiList(
              () => {
                AndroidWifiManager.findAndConnect(ssid, password, (found: any) =>
                  found ? resolve() : reject(I18n.t("WIFI_UTILS.ERRORS.SSID_NOT_FOUND")),
                );
              },
              () => {
                reject(I18n.t("WIFI_UTILS.ERRORS.SCAN_FAILED"));
              },
            ),
          ),
        //@ts-ignore
        // () =>
        // ts-ignoring for now until verified that if bug
        showAlert(
          I18n.t("WIFI_UTILS.LOCATION_REASON.TITLE"),
          I18n.t("WIFI_UTILS.LOCATION_REASON.MESSAGE"),
        ),
      ),
  })(ssid, password);

export const canAutoConnectToWifi = () => {
  const matchedVersion = DeviceInfo.getSystemVersion().match(SYSTEM_VERSION_REGEX);
  if (!matchedVersion) {
    return false;
  }
  // If iOS, must be >= 11
  // No need for Android version check, as our minimum SDK is 21 and supports auto connect
  if (isIOS() && Number(matchedVersion[0]) < 11) {
    return false;
  }
  return true;
};

export const isUserDenialError = (error: any) =>
  nestedValueExists(error, ["userInfo", "NSLocalizedDescription"], null) ===
    I18n.t("WIFI_UTILS.ERRORS.USER_DENIED") || error === PERMISSIONS_ERRORS.locationDenied;

/*
 *  ssid (string): name of ssid to connect to
 *  password (string, optional): WPA key
 *  options (object, optional):
 *    - maxWait (num, milliseconds): How long should we wait until failing the ssid
 *        connection? Defaults to 1 minute
 *
 *  Returns a promise that resolves if the ssid could be connected to within the given time
 *    and rejects if any error happens in the process
 */
export const waitForConnection = (
  ssid: string,
  password?: string,
  options = { maxWait: MAX_WAIT },
) =>
  new Promise((resolve, reject) => {
    // TODO: remove try/catch and handle errors properly
    try {
      let tryConnect = true;
      if (canAutoConnectToWifi()) {
        // start trying to connect to the ssid
        const connectLoop = () =>
          connectToSSID(ssid, password).catch((error: any) => {
            // Don't try to reconnect if user denies location or auto-connect.
            if (tryConnect && !isUserDenialError(error)) {
              setTimeout(connectLoop, CONNECT_INTERVAL);
            } else {
              reject(error);
            }
          });
        connectLoop();
      }

      const unsubscribe = NetInfo.addEventListener((state) => {
        if (state.type == NetInfoStateType.wifi) {
          getSsid().then((currentSsid: any) =>
            currentSsid === ssid ? resolve(currentSsid) : null,
          );
        }
      });

      // Should the connect action timeout after some time?
      if (options.maxWait) {
        // This timeout will always end up executing if a maxWait is set, so the listener will be
        // removed even though the reject() will do nothing if a different resolve/reject has
        // already been called.
        setTimeout(() => {
          tryConnect = false;
          unsubscribe();
          reject(I18n.t("WIFI_UTILS.ERRORS.MAX_WAIT_EXCEEDED"));
        }, options.maxWait);
      }
    } catch (error) {
      if (typeof error === "string") {
        errorMonitor.notify(error);
      }
      reject(error);
    }
  });

export const checkAndroidWifiStatus = (): Promise<boolean> => {
  const androidVersion = Number(DeviceInfo.getSystemVersion().match(SYSTEM_VERSION_PREFIX_REGEX));

  if (androidVersion >= 10) {
    return NetInfo.fetch()
      .then((state) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const isWifiEnabled = state?.["isWifiEnabled"];
        return isWifiEnabled;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
  return Promise.resolve(true);
};
