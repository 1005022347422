export enum SensorEventTypes {
  water_cable_connection = "mt_water_cable_connection",
  water_detection = "mt_water_detection",
  door = "mt_door",
  door_tamper = "mt_door_tamper",
}

export const WATER_RELATED_EVENT_TYPES = [
  SensorEventTypes.water_cable_connection,
  SensorEventTypes.water_detection,
];

export const WATER_DETECTED_VAL = "1.0";
export const WATER_ABSENT_VAL = "0.0";

export const DOOR_RELATED_EVENT_TYPES = [SensorEventTypes.door, SensorEventTypes.door_tamper];
