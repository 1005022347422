import { I18n } from "@meraki/core/i18n";
import { otpAuthUrl } from "@meraki/shared/links";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { makeCancelablePromise } from "~/lib/RequestUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import { getTwoFactorAuthInfo } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton } from "~/shared/navigation/Buttons";
import DropDownRow from "~/shared/rows/DropDownRow";

import { ResetTwoFactorScreensPropMap } from "../navigation/Types";

const InfoRow = (props: { label: string; value: string }) => {
  const { label, value } = props;

  return (
    <View style={styles.infoView}>
      <MkiText screenStyles={styles.infoRowLabel}>{label}</MkiText>
      <MkiText selectable>{value}</MkiText>
    </View>
  );
};

type Props = ForwardedNativeStackScreenProps<ResetTwoFactorScreensPropMap, "ResetTwoFactor">;

export const ResetTwoFactorScreen = () => {
  const [loading, setLoading] = useState(false);
  const actions = useActions();

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    const logoutUser = async () => {
      try {
        setLoading(true);
        await actions.logoutUser();
      } catch (error) {
        await actions.wipeRedux();
      }
      setLoading(false);
    };

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={logoutUser} />,
    });
  }, [actions, navigation]);

  const twoFactorInfo = useAppSelector(getTwoFactorAuthInfo);
  const { twoFactorUsername, twoFactorSecret } = twoFactorInfo;

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await makeCancelablePromise(actions.getTwoFactorInfo());
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    }
    setLoading(false);
  }, [setLoading, actions]);

  useEffect(() => {
    getData();
  }, [getData]);

  const pushVerifyScreen = () => {
    navigation.navigate("VerifyResetTwoFactor");
  };

  return (
    <View style={styles.container}>
      <FullScreenContainerView withScroll screenStyles={styles.scrollView}>
        <MkiText screenStyles={styles.messageStyle}>
          {I18n.t("RESET_TWO_FACTOR.DESCRIPTION")}
        </MkiText>
        {isWeb() ? (
          <View style={styles.qrContainer}>
            <QRCode
              value={`otpauth://totp/${twoFactorUsername}?secret=${twoFactorSecret}`}
              size={200}
              color="black"
              backgroundColor="white"
              quietZone={SPACING.tiny}
            />
          </View>
        ) : (
          <View style={styles.buttonContainer}>
            <RoundedButton
              buttonType={ButtonType.secondary}
              onPress={() => otpAuthUrl(twoFactorUsername, twoFactorSecret)}
              screenStyles={styles.buttonStyle}
            >
              {I18n.t("RESET_TWO_FACTOR.OPEN_OTP_LINK")}
            </RoundedButton>
          </View>
        )}
        <View style={{ marginVertical: SPACING.default }}>
          <DropDownRow
            titleTextStyle={styles.dropDownTitle}
            noMargin
            title={I18n.t("RESET_TWO_FACTOR.EXPANDABLE.TITLE")}
          >
            <View>
              <MkiText textStyle="secondary" screenStyles={styles.itemDescriptionText}>
                {I18n.t("RESET_TWO_FACTOR.EXPANDABLE.DESCRIPTION")}
              </MkiText>
              <InfoRow
                label={I18n.t("RESET_TWO_FACTOR.EXPANDABLE.ACCOUNT_NAME")}
                value={twoFactorUsername}
              />
              <InfoRow
                label={I18n.t("RESET_TWO_FACTOR.EXPANDABLE.SECRET")}
                value={twoFactorSecret}
              />
            </View>
          </DropDownRow>
        </View>
        <LoadingSpinner visible={loading}></LoadingSpinner>
      </FullScreenContainerView>
      <RoundedButton onPress={pushVerifyScreen} screenStyles={styles.logoutButton}>
        {I18n.t("RESET_TWO_FACTOR.VERIFY")}
      </RoundedButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
  },
  dropDownTitle: {
    fontSize: normalizedFontSize(16),
  },
  scrollView: {
    flex: 1,
    marginHorizontal: SPACING.default,
  },
  messageStyle: {
    marginBottom: SPACING.default,
  },
  infoView: {
    paddingHorizontal: SPACING.default,
    paddingVertical: SPACING.small,
  },
  infoRowLabel: {
    color: MkiColors.secondaryTextColor,
    fontSize: normalizedFontSize(13),
  },
  itemDescriptionText: {
    paddingHorizontal: SPACING.default,
  },
  logoutButton: {
    margin: SPACING.large,
  },
  qrContainer: {
    alignSelf: "center",
    margin: SPACING.large,
  },
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.large,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
});

export default ResetTwoFactorScreen;
