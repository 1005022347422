import {
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setCurrentScreen,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { FirebaseApp } from "firebase/app";

// Kept for mobile compatibility
export const analyticsLog: unknown[] = [];

export function setupAnalytics(app: FirebaseApp) {
  const analytics = getAnalytics(app);

  return {
    logEvent: (key: string, options?: Record<string, unknown>) => logEvent(analytics, key, options),
    setUserId: (userId: string | null) => {
      setUserId(analytics, userId);
    },
    setAnalyticsCollectionEnabled: (enabled: boolean) => {
      setAnalyticsCollectionEnabled(analytics, enabled);
    },
    setUserProperty: (key: string, val: unknown) => {
      setUserProperties(analytics, { [key]: val });
    },
    logScreenView: (params: { screen_name?: string; screen_class?: string }) => {
      const { screen_class, screen_name } = params;
      const derivedScreenName = screen_name || screen_class;

      if (derivedScreenName) {
        setCurrentScreen(analytics, derivedScreenName);
        logEvent(analytics, "screen_view");

        logEvent(analytics, "page_view", { page_title: derivedScreenName });
      }
    },
  };
}
