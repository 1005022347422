import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";
import { VpnOutageSchema } from "./useVpnDetails";

export const PeerOutageSchema = z.record((z.string(), VpnOutageSchema));

export type PeerOutageData = z.infer<typeof PeerOutageSchema>;

type PeerOutageRequest = {
  networkEid?: string;
};

type PeerOutageRequestParams = PeerOutageRequest & {
  timespan: number;
  peerEids: string[];
};

function buildUrl({ networkEid }: PeerOutageRequest) {
  return `/n/${networkEid}/manage/vpn/peer_outages`;
}

function fetchPeerOutage({
  networkEid,
  timespan,
  peerEids,
}: PeerOutageRequestParams): Promise<PeerOutageData> {
  if (!networkEid) {
    throw new Error("Undefined networkEid when fetching VPN peer outage data");
  }

  const ids = peerEids.join("+");

  return request(PeerOutageSchema, "GET", buildUrl({ networkEid }), {
    queryParams: { timespan, source: networkEid },
    unencodedQueryParams: { ids: ids },
  });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const usePeerOutageData = createQuery<PeerOutageRequestParams, PeerOutageData>({
  baseQueryKey: buildUrl({ networkEid: "{networkEid}" }),
  queryFn: (variables) => fetchPeerOutage(variables),
  requiredVariables: ["networkEid"],
});
