import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const WishInputSchema = z
  .object({
    page_name: z.string(),
    wish_hash: z.string(),
    wish: z.string(),
  })
  .describe("WishInputSchema");

export const WishResponseSchema = z
  .object({
    success: z.string(),
  })
  .describe("WishResponseSchema");

export type WishInput = z.infer<typeof WishInputSchema>;
export type WishResponse = z.infer<typeof WishResponseSchema>;

interface WishRequest {
  encryptedNetworkId?: string;
}

interface MutateWishRequest extends WishRequest {
  body: WishInput;
}

const buildUrl = ({ encryptedNetworkId }: WishRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/wish`;
};

const createWish = ({ encryptedNetworkId, body }: MutateWishRequest) => {
  return request(WishResponseSchema, "POST", buildUrl({ encryptedNetworkId }), {
    body: JSON.stringify(body),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCreateWish = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: MutateWishRequest) => createWish({ ...parameters }),
});
