import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import StatusIcon, { StatusIconProps } from "~/shared/components/StatusIcon";

export interface StatusIconWrapperProps extends StatusIconProps {
  children: React.ReactNode;
}

class StatusIconWrapper extends PureComponent<StatusIconWrapperProps> {
  render() {
    const { children, ...rest } = this.props;

    return (
      <View style={styles.container}>
        <StatusIcon screenStyles={styles.statusIcon} {...rest} />
        <View>{children}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
  },
  statusIcon: {
    marginRight: SPACING.meager,
  },
});

export default StatusIconWrapper;
