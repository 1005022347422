import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import { VPN_TYPE } from "~/go/types/ClientVPN";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import MkiText from "~/shared/components/MkiText";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "ClientVPNInstruction"> &
  BasicActions &
  BaseOnboardingScreenProps;

export class ClientVPNInstructionScreen extends BaseOnboardingScreen<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.close} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.clientVPNInstruction);
  }

  renderBody = () => {
    const { sharedSecret } = this.props;

    return (
      <View style={styles.bodyContainer}>
        <View style={styles.row}>
          <MkiText textStyle="default">
            {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.INSTRUCTION.TITLE")}
          </MkiText>
          <MkiText textStyle="secondary" screenStyles={styles.message}>
            {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.INSTRUCTION.MESSAGE")}
          </MkiText>
        </View>
        <View style={styles.row}>
          <MkiText textStyle="default">
            {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.DETAILS.TITLE")}
          </MkiText>
          <InputRow value={VPN_TYPE} testID="CLIENT_VPN_INSTRUCTION.TYPE" editable={false}>
            <MkiText textStyle="default" screenStyles={styles.row}>
              {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.DETAILS.TYPE")}
            </MkiText>
          </InputRow>
          <InputRow
            value={sharedSecret}
            testID="CLIENT_VPN_INSTRUCTION.SECRET"
            editable={false}
            revealable
            secureTextEntry
          >
            <MkiText textStyle="default" screenStyles={styles.row}>
              {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.DETAILS.SECRET")}
            </MkiText>
          </InputRow>
        </View>
      </View>
    );
  };

  getFooterData = () => {
    const buttons: React.ReactNode[] = [
      <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    ];

    return { buttons };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.clientVPNInstruction);
}

const styles = StyleSheet.create({
  bodyContainer: {
    marginVertical: SPACING.default,
  },
  message: {
    marginTop: SPACING.small,
  },
  row: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
});

export default compose<any>(
  connect(null, basicMapDispatchToProps),
  withOnboardingStatus,
)(ClientVPNInstructionScreen);
