import { formatDate } from "@meraki/core/date";
import * as errorMonitor from "@meraki/core/errors";
import { useTheme } from "@meraki/core/theme";
import { subWeeks } from "date-fns";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { SettingsStackProps } from "~/go/navigation/Types";
import TrackedClientRow from "~/go/rows/TrackedClientRow";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { getChangedTrackedClients, showAlert } from "~/lib/AlertUtils";
import { clientName } from "~/lib/ClientUtils";
import {
  clientsState,
  currentNetworkState,
  getClientConnectedEvents,
  getClientDisconnectedEvents,
  getNetworkTimezone,
  getTrackedClientsList,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { CloseButton, DoneButton, EditButton } from "~/shared/navigation/Buttons";
import { TrackedClientList } from "~/shared/types/AlertTypes";
import { Client, ClientEvent, ClientList } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  trackedClientsList: TrackedClientList[];
  rawClients: ClientList;
  timezone: string;
  networkId: string;
  clientConnectedEvents: ClientEvent[];
  clientDisconnectedEvents: ClientEvent[];
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "TrackedClients"> &
  ReduxProps &
  BasicActions &
  WithConnectedClientsProps &
  PendingComponent &
  WithCancelablePromiseProps;

interface TrackedClientsScreenState {
  isEditMode: boolean;
}

interface RowData {
  name: string | null;
  isOnline?: boolean;
  mac: string;
  lastSeen: Date | null;
  subtitle: string;
}

export class TrackedClientsScreen extends PureComponent<Props, TrackedClientsScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { isEditMode } = this.state;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => this.setState({ isEditMode: true })} />;
        } else {
          return <DoneButton onPress={() => this.setState({ isEditMode: false })} />;
        }
      },
    });
  }

  onRefresh = () => {
    this.getTrackedClientData();
    this.getAlertSettings();
    this.getConnectivityEvents();
  };

  componentDidMount() {
    this.getAlertSettings();
    this.getTrackedClientData();
    this.getConnectivityEvents();
    this.getClientData();
    this.removeUnreachableClients();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  removeUnreachableClients = () => {
    const { trackedClientsList, rawClients } = this.props;
    const listOfReachableClientMacs: string[] = Object.values(rawClients).map((client: Client) => {
      return client.mac;
    });
    trackedClientsList.forEach((thisClient) => {
      if (listOfReachableClientMacs.includes(thisClient.mac)) {
        return;
      } else {
        this.updateTrackedClientsList(thisClient.mac);
      }
    });
  };

  getClientData = async () => {
    const { actions, cancelablePromise } = this.props;
    try {
      await cancelablePromise(actions.getClients());
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }
  };

  updateTrackedClientsList = async (clientMac: string) => {
    const { actions, cancelablePromise, setReqPending, trackedClientsList } = this.props;
    setReqPending(true);

    const clients: TrackedClientList[] = trackedClientsList.filter(
      (removeClient) => removeClient.mac !== clientMac,
    );

    const enabled = clients.length > 0;
    const changedTrackedClients = getChangedTrackedClients(enabled, clients);
    try {
      await cancelablePromise(actions.updateAlertSettings(changedTrackedClients));
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }

    setReqPending(false);
    actions.deleteConnectivityEvents(clientMac.toUpperCase());
  };

  getConnectivityEvents = async () => {
    const { actions, networkId } = this.props;
    const oneWeekAgo = subWeeks(Date.now(), 1).toISOString();
    const params = {
      networkId,
      startingAfter: oneWeekAgo,
      perPage: 100,
      productType: "appliance",
      includedEventTypes: ["client_connectivity"],
    };
    try {
      await actions.getNetworkEvents(params, true);
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }
  };

  renderTrackedRow = (data: RowData) => {
    const { name, isOnline, subtitle, mac } = data;
    const { theme } = useTheme.getState();
    const { isEditMode } = this.state;
    const onPress = () => this.updateTrackedClientsList(mac);
    return (
      <TrackedClientRow
        name={name}
        isOnline={isOnline}
        isEditMode={isEditMode}
        onPress={onPress}
        theme={theme}
        subtitle={subtitle}
      />
    );
  };

  makeFormattedDate = (timestamp: Date | null, isConnected: boolean) => {
    if (timestamp == null) {
      return I18n.t("TRACK_CLIENT.NO_DATA");
    }

    const date = formatDate(timestamp, { dateFormat: "longDate", timeFormat: "shortTime" });
    const prefixText = isConnected
      ? I18n.t("TRACK_CLIENT.CONNECTED")
      : I18n.t("TRACK_CLIENT.DISCONNECTED");
    return prefixText + date;
  };

  getConnectedLastSeen = (mac: string): Date | null => {
    const { clientConnectedEvents } = this.props;
    const clientConnectivityEvents = clientConnectedEvents.filter(
      (thisEvent) => thisEvent.eventData.mac === mac,
    );
    if (clientConnectivityEvents.length === 0) {
      return null;
    } else {
      clientConnectivityEvents.sort((a, b) => Date.parse(b.occurredAt) - Date.parse(a.occurredAt));
      return new Date(clientConnectivityEvents[0].occurredAt);
    }
  };

  getDisconnectedLastSeen = (mac: string, lastSeen: Date): Date => {
    const { clientDisconnectedEvents } = this.props;
    const clientDisconnectivityEvents = clientDisconnectedEvents.filter(
      (thisEvent) => thisEvent.eventData.mac === mac,
    );
    if (clientDisconnectivityEvents.length === 0) {
      return lastSeen;
    } else {
      clientDisconnectivityEvents.sort(
        (a, b) => Date.parse(b.occurredAt) - Date.parse(a.occurredAt),
      );
      const eventOccuredAt = new Date(clientDisconnectivityEvents[0].occurredAt);
      return eventOccuredAt >= lastSeen ? eventOccuredAt : lastSeen;
    }
  };

  getAlertSettings = async () => {
    const { actions } = this.props;
    try {
      await actions.fetchAlertSettings();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }
  };

  getLastSeen = (client: Client) => {
    const { isClientConnected } = this.props;
    return !isClientConnected(client)
      ? this.getDisconnectedLastSeen(client.mac.toUpperCase(), new Date(client.lastSeen))
      : this.getConnectedLastSeen(client.mac.toUpperCase());
  };

  getTrackedClientData() {
    const { rawClients, trackedClientsList, isClientConnected } = this.props;
    const clientData: RowData[] = [];
    Object.values(rawClients).map((element: Client) => {
      if (trackedClientsList.find((thisClient) => thisClient.mac === element.mac)) {
        const thisClient = {
          name: clientName(element),
          isOnline: isClientConnected(element),
          mac: element.mac,
          lastSeen: this.getLastSeen(element),
          subtitle: I18n.t("TRACK_CLIENT.NO_DATA"),
        };
        thisClient.subtitle = this.makeFormattedDate(thisClient.lastSeen, thisClient.isOnline);
        clientData.push(thisClient);
      }
    });
    clientData.sort((a, b) => {
      if (a.lastSeen === b.lastSeen) {
        return 0;
      } else if (a.lastSeen === null) {
        return 1;
      } else if (b.lastSeen === null) {
        return -1;
      } else {
        return a.lastSeen < b.lastSeen ? 1 : -1;
      }
    });
    return clientData;
  }

  renderEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <MkiText>{I18n.t("TRACK_CLIENT.NO_CLIENTS")}</MkiText>
      </View>
    );
  };

  render() {
    return (
      <>
        <MkiText textStyle="label" screenStyles={styles.subheading}>
          {I18n.t("TRACK_CLIENT.PAGE_SUBTITLE")}
        </MkiText>
        <MkiRowSeparator withHorizontalMargins withCustomWidth />
        <FullScreenContainerView>
          <MkiTable<RowData>
            data={this.getTrackedClientData()}
            renderRow={this.renderTrackedRow}
            ListEmptyComponent={this.renderEmptyComponent}
            onRefresh={this.onRefresh}
          />
        </FullScreenContainerView>
      </>
    );
  }
}
const styles = StyleSheet.create({
  subheading: {
    color: MkiColors.secondaryTextColor,
    marginHorizontal: SPACING.default,
    marginTop: SPACING.medium,
    marginBottom: SPACING.small,
  },
  emptyContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    rawClients: clientsState(state),
    trackedClientsList: getTrackedClientsList(state),
    timezone: errorMonitor.notifyNonOptional(
      "param 'timezone' undefined for TrackedClientsScreen",
      getNetworkTimezone(state),
    ),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for TrackedClientsScreen",
      currentNetworkState(state),
    ),
    clientConnectedEvents: getClientConnectedEvents(state),
    clientDisconnectedEvents: getClientDisconnectedEvents(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withConnectedClients,
  withCancelablePromise,
  withPendingComponent,
)(TrackedClientsScreen);
