import { useTheme } from "@meraki/core/theme";
import { omit } from "lodash";
import { forwardRef, PureComponent } from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputEndEditingEventData,
  TextInputFocusEventData,
  View,
} from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import FormItem, { FormItemProps } from "~/go/components/FormItem";
import { platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiTextInput, { MkiTextInputProps } from "~/shared/components/MkiTextInput";

export interface UnderlinedTextInputProps extends MkiTextInputProps, FormItemProps {
  onChangeText?: (text: string, name?: string) => void;
  name?: string;
  showClearButton?: boolean;
  rightAccessory?: JSX.Element;
  isError?: boolean;
}

export interface UnderlinedTextInputState {
  isFocused: boolean;
}

export class UnderlinedTextInput extends PureComponent<
  UnderlinedTextInputProps,
  UnderlinedTextInputState
> {
  private textInput: TextInput | null = null;

  constructor(props: UnderlinedTextInputProps) {
    super(props);
    this.state = { isFocused: false };
  }

  focus = () => {
    this.textInput?.focus();
  };

  onFocus = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    if (onFocus) {
      onFocus(event);
    }
  };

  onEndEditing = (event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
    const { onEndEditing } = this.props;
    this.setState({ isFocused: false });
    if (onEndEditing) {
      onEndEditing(event);
    }
  };

  containerStyles = () => {
    const { editable = true, isError } = this.props;
    const { theme } = useTheme.getState();
    const { isFocused } = this.state;
    const borderBottomColor = themeColors(theme).text?.subheader?.color;
    const themedBottomBorder = borderBottomColor
      ? {
          borderBottomColor,
        }
      : {};
    return [
      styles.inputContainer,
      themedBottomBorder,
      editable ? {} : styles.inputContainerDisabled,
      isFocused ? styles.inputContainerFocused : {},
      isError ? styles.inputContainerError : {},
    ];
  };

  onChangeTextWithName = (text: string) => {
    const { onChangeText, name } = this.props;

    if (onChangeText) {
      onChangeText(text, name);
    }
  };

  render() {
    const { editable = true, title, footer, rightAccessory, style, testID, value } = this.props;
    const { theme } = useTheme.getState();

    const disabledTextStyle = editable
      ? undefined
      : [styles.inputTextDisabled, themeColors(theme).text?.disabled];
    const passedProps = omit(this.props, ["onChangeText", "name"]);
    const inputTextStyle = themeColors(theme).text?.default;
    return (
      <FormItem {...{ title, footer }}>
        <View style={this.containerStyles()}>
          <MkiTextInput
            {...passedProps}
            value={value}
            onFocus={this.onFocus}
            onBlur={this.onEndEditing}
            onEndEditing={this.onEndEditing}
            screenStyles={[styles.inputText, inputTextStyle, style, disabledTextStyle]}
            underlineColorAndroid="transparent"
            onChangeText={this.onChangeTextWithName}
            ref={(ref) => {
              this.textInput = ref;
            }}
            testID={testID}
          />
          {rightAccessory}
        </View>
      </FormItem>
    );
  }
}

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: platformSelect({
      android: 0,
      ios: SPACING.small,
    }),
    borderBottomColor: MkiColors.textColor,
    borderBottomWidth: 1,
  },
  inputContainerDisabled: {
    borderBottomWidth: 0,
  },
  inputContainerFocused: {
    borderBottomColor: MkiColors.activeInputUnderline,
  },
  inputContainerError: {
    borderBottomColor: MkiColors.errorTextColor,
  },
  inputText: {
    flexGrow: 1,
  },
  inputTextDisabled: {
    color: MkiColors.secondaryTextColor,
  },
});

const UnderlinedTextInputWithRef = forwardRef<UnderlinedTextInput, UnderlinedTextInputProps>(
  (props, ref) => <UnderlinedTextInput {...props} ref={ref} />,
);

UnderlinedTextInputWithRef.displayName = "UnderlinedTextInputWithRef";

export default UnderlinedTextInputWithRef;
