import { CLIENTS_OVERVIEW_FETCH_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

import ClientsOverview from "~/api/models/ClientsOverview";

const initialState: Partial<ClientsOverview> = {};

const clientsOverview = (state = initialState, action: any) => {
  const { type, response } = action;

  switch (type) {
    case CLIENTS_OVERVIEW_FETCH_SUCCESS: {
      return response;
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default clientsOverview;
