import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { Ssid, SsidSchema } from "./useSsid";

export interface SsidDeleteRequest {
  networkId?: string;
  ssidNumber: string;
}

function buildUrl({ networkId, ssidNumber }: SsidDeleteRequest) {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/reset`;
}

export function deleteSsids({ networkId, ssidNumber }: SsidDeleteRequest): Promise<Ssid> {
  if (!networkId) {
    throw new Error("Undefined network id when deleting Ssids");
  }
  return request(SsidSchema, "POST", buildUrl({ networkId, ssidNumber }));
}

export const useDeleteSsid = createMutation<SsidDeleteRequest, Ssid, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}", ssidNumber: "{ssidNumber}" }),
  mutationFn: (variables) => deleteSsids(variables),
});
