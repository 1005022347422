import localforage from "localforage";
import { Storage } from "redux-persist";

import { appSelect } from "~/lib/PlatformUtils";

localforage.setDriver(localforage.INDEXEDDB);

interface RootPersistConfig {
  key: string;
  storage: Storage;
  blacklist?: string[];
  whitelist?: string[];
  transforms?: any;
}

const baseConfig = {
  key: "root",
  storage: localforage,
};

const goConfig = {
  blacklist: ["navigation", "liveBroker", "pagination"],
};

const enterpriseConfig = {
  whitelist: [
    "auth",
    "api",
    "mkiconf",
    "networks",
    "onboarding",
    "preferences",
    "registration",
    "userData",
    "timespans",
  ],
};

export const rootPersistConfig: RootPersistConfig = {
  ...baseConfig,
  ...appSelect({
    go: goConfig,
    enterprise: enterpriseConfig,
  }),
};
