export enum ClientTypes {
  sm = "systemsManager",
  network = "networkOnly",
  smAndNetwork = "smAndNetwork",
}

export enum ClientTab {
  network = 0,
  systemsManager = 1,
}
