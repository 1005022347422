export enum Features {
  noHelp = "noHelp",
  clientTracking = "clientTracking",
  deviceInfo = "deviceInfo",
  guestNetworks = "guestNetworks",
  visitorInfo = "visitorInfo",
  visitorInfoDetailed = "visitorInfoDetailed",
  clientVPNTroubleshooting = "clientVPNTroubleshooting",
  hiddenNetworks = "hiddenNetworks",
  bridgeVsNATMode = "bridgeVsNATMode",
  natIsolation = "natIsolation",
  gxIPAddress = "gxIPAddress",
  subnetting = "subnetting",
  vlanID = "vlanID",
  defaultQoS = "defaultQoS",
  automaticFirewall = "automaticFirewall",
  statusDetails = "statusDetails",
  dhcpServer = "dhcpServer",
  usageLimits = "usageLimits",
  securityLicenseExpiration = "securityLicenseExpiration",
  deviceIPAddress = "deviceIPAddress",
  runDHCPServer = "runDHCPServer",
  useOtherDHCP = "useOtherDHCP",
  siteToSiteVPNHealthOverview = "siteToSiteVPNHealthOverview",
  networkUsage = "networkUsage",
}

export enum HelpIcons {
  help = "help",
  info = "info",
}

interface ContextHelpBase {
  message: string;
  title: string;
  icon: string;
  showDoc: Function;
}

export type ContextHelpImport = {
  [key in Features]: ContextHelpBase;
};
