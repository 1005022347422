import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import MkiText from "~/shared/components/MkiText";

interface CircleViewProps {
  topText: string;
  bottomText: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

function CircleView({ topText, bottomText, onPress, style }: CircleViewProps) {
  const isButton = typeof onPress === "function";

  const containerStyles: StyleProp<ViewStyle>[] = [];

  if (isButton) {
    containerStyles.push(styles.circleButton);
  } else {
    containerStyles.push(styles.circle);
  }

  if (style) {
    containerStyles.push(style);
  }

  const circle = (
    <View style={containerStyles}>
      <MkiText textStyle={isButton ? "bigNumber" : "heading"} screenStyles={styles.centeredText}>
        {topText}
      </MkiText>
      <MkiText
        textStyle="smallSecondary"
        screenStyles={[styles.centeredText, isButton ? styles.purpleText : null]}
      >
        {bottomText}
      </MkiText>
    </View>
  );

  if (onPress) {
    return <TouchableOpacity onPress={onPress}>{circle}</TouchableOpacity>;
  }

  return circle;
}
const styles = StyleSheet.create({
  circleButton: {
    justifyContent: "center",
    height: 116,
    width: 116,
    borderRadius: 58,
    borderWidth: 1,
    borderColor: MkiColors.goPurple,
    backgroundColor: MkiColors.primaryButtonLighter,
  },
  circle: {
    justifyContent: "center",
    height: 116,
    width: 116,
    borderRadius: 58,
    backgroundColor: MkiColors.localAddressingBox,
  },
  centeredText: {
    textAlign: "center",
  },
  purpleText: {
    color: MkiColors.goPurple,
  },
});

export default CircleView;
