// TODO: Add unit tests for this file
import { I18n } from "@meraki/core/i18n";
import { NativeModules } from "react-native";
import { sha256 } from "react-native-sha256";

import { Device as ZodDevice } from "~/api/schemas/Device";
import {
  OnboardingError,
  PreviousOnboardingError,
} from "~/enterprise/components/camera/wirelessOnboarding/OnboardingError";
import { CAMERA_HOTSPOT_CONFIGURE_SSID_PREFIX } from "~/enterprise/constants/Strings";
import Device, { GenericDevice } from "~/shared/types/Device";
import {
  OnboardingCredentials,
  OnboardingCredentialsEAP,
  OnboardingCredentialsPSK,
  SSIDEncryptionMode,
  WirelessProfile,
} from "~/shared/types/Models";

const HotspotManager = NativeModules.HotspotManager;

export function connectToHotspot(ssid: string, password: string): Promise<string> {
  return HotspotManager.connectTo(ssid, password);
}

export function disconnectFromHotspot(ssid: string): Promise<string> {
  return HotspotManager.disconnectFrom(ssid);
}

export async function getHotspotPSK(device: Device): Promise<string> {
  try {
    const result = await sha256(device.serial.toUpperCase()).then((hash: string) =>
      hash.slice(0, -1),
    );

    return result;
  } catch (error) {
    // Throw our custom error number
    throw new Error(OnboardingError.CantMakePsk);
  }
}

export const getHotspotSSID = (device: Device) => {
  if (!device?.mac) {
    return undefined;
  }
  return `${CAMERA_HOTSPOT_CONFIGURE_SSID_PREFIX}${device.mac.toUpperCase()}`;
};

export const isEggplantWizardCamera = (device: GenericDevice | ZodDevice | undefined) => {
  return device?.serial?.startsWith("Q2NV") ?? false;
};

export const getReadableErrorMessage = (error?: Error) => {
  let errorTitle: string = I18n.t("CAMERA_ONBOARDING.FAILED_TO_CONFIGURE_ERROR_TITLE");
  let errorDescription: string = I18n.t("CAMERA_ONBOARDING.FAILED_TO_CONFIGURE_ERROR_DESCRIPTION");

  if (!error) {
    return { title: errorTitle, description: errorDescription };
  }

  switch (error.message) {
    case PreviousOnboardingError.SSIDsIncorrect:
    case PreviousOnboardingError.PasswordIncorrect:
      errorTitle = I18n.t("CAMERA_ONBOARDING.WE_FOUND_SOMETHING");
      errorDescription = `${I18n.t(
        "CAMERA_ONBOARDING.FAILED_TO_CONNECT_TO_NETWORK_DESCRIPTION",
      )}\n\n${I18n.t("CAMERA_ONBOARDING.FAILURE_PROMPT")}`;
      break;
    case PreviousOnboardingError.IPConfigFailed:
      errorTitle = I18n.t("CAMERA_ONBOARDING.WE_FOUND_SOMETHING");
      errorDescription = `${I18n.t("CAMERA_ONBOARDING.FAILED_TO_GET_IP_DESCRIPTION")}\n\n${I18n.t(
        "CAMERA_ONBOARDING.FAILURE_PROMPT",
      )}`;
      break;
  }

  if (error.message == "1007") {
    // This will never shown to users... unless they manage to run this app on a simulator
    errorDescription = "Unsupported on simulator";
  }

  return { title: errorTitle, description: errorDescription };
};

export const buildCredentialPostBody = (
  profiles: WirelessProfile[],
  certificateString: string,
  deviceSerial: string,
): Promise<OnboardingCredentials[]> => {
  const bodyPromises: Promise<OnboardingCredentials>[] = [];

  profiles.forEach((profile, index) => {
    const promise = buildBodyFromWirelessProfile(
      profile,
      certificateString,
      // The camera expects profiles ordered correctly in the array, with the highest 'priority' value, 3, at the front of the array
      3 - index,
      deviceSerial,
    );
    bodyPromises.push(promise);
  });

  return Promise.all(bodyPromises);
};

const buildBodyFromWirelessProfile = (
  profile: WirelessProfile,
  certificateString: string,
  priority: number,
  deviceSerial: string,
): Promise<OnboardingCredentials> => {
  return new Promise((resolve, reject) => {
    const secretToEncrypt = profile.ssid.psk || profile.identity?.password;

    if (!secretToEncrypt) {
      throw new Error(OnboardingError.BadWirelessProfileSecret);
    }

    HotspotManager.encrypt(secretToEncrypt, certificateString, deviceSerial)
      .then((encryptedSecret: string) => {
        switch (profile.ssid.encryptionMode) {
          case SSIDEncryptionMode.wpa:
            const wpaCredential: OnboardingCredentialsPSK = {
              key_mgmt: "WPA-PSK",
              ssid: profile.ssid.name,
              priority: priority,
              psk: encryptedSecret,
            };

            resolve(wpaCredential);
            break;

          case SSIDEncryptionMode.eap:
            if (!profile.identity) {
              throw new Error(OnboardingError.BadEAPIdentity);
            }

            const eapCredential: OnboardingCredentialsEAP = {
              key_mgmt: "WPA-EAP",
              ssid: profile.ssid.name,
              priority: priority,
              identity: profile.identity.username,
              password: encryptedSecret,
            };
            resolve(eapCredential);
            break;

          default:
            throw new Error(OnboardingError.UnsupportedWirelessAuth);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export function isPreviousOnboardingError(error: Error) {
  return Object.values(PreviousOnboardingError).includes(error.message);
}
