import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { ScrollView, Share, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { FIREBASE_EVENTS, sendMilestoneEvent } from "~/lib/FirebaseUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { sizeSelect } from "~/lib/themeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import HorizontalDivider from "~/shared/components/HorizontalDivider";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import WiFiQRCode from "~/shared/components/WiFiQRCode";
import { CloseButton, PDFShareButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDShare">;

class SSIDShareScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, ssid } = this.props;

    if (!isWeb()) {
      navigation.setOptions({
        headerLeft: () => <CloseButton onPress={navigation.goBack} />,
        headerRight: () => (
          <PDFShareButton
            onPress={() => {
              navigation.navigate("SSIDSharePDF", { ssidNumber: ssid.number });
            }}
          />
        ),
      });
    }
  }

  share = () => {
    const { name, passphrase } = this.props;
    const translationOptions = { name, passphrase };

    sendMilestoneEvent(FIREBASE_EVENTS.shareSSIDButtonTap);
    Share.share({
      message: passphrase
        ? I18n.t("SSID_SHARE.PASSWORD_MESSAGE", translationOptions)
        : I18n.t("SSID_SHARE.NO_PASSWORD_MESSAGE", translationOptions),
    })
      // @ts-expect-error activityType does not exist on "share" :(
      .then(({ action, activityType }) => {
        if (action === Share.sharedAction) {
          sendMilestoneEvent(FIREBASE_EVENTS.shareSSIDShared, { activityType });
        } else {
          sendMilestoneEvent(FIREBASE_EVENTS.shareSSIDDismissed);
        }
      })
      .catch((e) => console.warn(e?.message));
  };

  render() {
    const { name, encryptionMode, passphrase, ssid, navigation } = this.props;
    return (
      <FullScreenContainerView>
        <ScrollView>
          <View style={styles.scrollContentContainer}>
            <View style={styles.container}>
              <StatusIcon
                customIcon={{
                  icon: MerakiIcon,
                  name: "wifi",
                  size: "l",
                  hasGradient: true,
                }}
              />
              <MkiText textStyle="heading" screenStyles={styles.nameScreenStyles}>
                {name}
              </MkiText>
            </View>

            {isWeb() ? (
              <RoundedButton
                buttonType="primary"
                testID="DOWNLOAD_BUTTON"
                onPress={() => {
                  navigation.navigate("SSIDSharePDF", {
                    ssidNumber: ssid.number,
                  });
                }}
                containerStyles={styles.shareButton}
              >
                {I18n.t("SSID_SHARE.SHARE_PDF_BUTTON")}
              </RoundedButton>
            ) : (
              <RoundedButton
                buttonType="primary"
                testID="SHARE_BUTTON"
                onPress={this.share}
                containerStyles={styles.shareButton}
              >
                {I18n.t("SSID_SHARE.SHARE_BUTTON")}
              </RoundedButton>
            )}

            <HorizontalDivider text={I18n.t("OR").toUpperCase()} />

            <View style={styles.container}>
              <MkiText testID="SCAN_INSTRUCTIONS">{I18n.t("SSID_SHARE.SCAN_INSTRUCTIONS")}</MkiText>
            </View>
            <View style={styles.container}>
              <WiFiQRCode
                name={name}
                encryptionMode={encryptionMode}
                passphrase={passphrase}
                ssidNumber={ssid.number}
              />
            </View>
          </View>
        </ScrollView>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginVertical: SPACING.default,
  },
  nameScreenStyles: {
    textAlign: "center",
  },
  scrollContentContainer: {
    alignItems: "center",
    paddingHorizontal: sizeSelect({
      default: SPACING.extraLarge,
      small: SPACING.large,
    }),
  },
  shareButton: {
    marginBottom: SPACING.default,
  },
});

export default SSIDShareScreen;
