import { colorPalette } from "../colorPalette";
import { Theme } from "../types";
import { goDarkTheme } from "./darkTheme";

export const goAmoledTheme: Theme = {
  ...goDarkTheme,

  color: {
    ...goDarkTheme.color,

    default: {
      ...goDarkTheme.color.default,

      bg: {
        ...goDarkTheme.color.default.bg,

        base: colorPalette.neutral[0],
      },
    },
  },

  mode: "dark",
};
