import { usePortStatuses, useSwitchPortStatuses } from "@meraki/react-live-broker";
import { getProductType } from "@meraki/shared/devices";
import { isEmpty } from "lodash";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { getApplianceLayout } from "~/lib/ApplianceUtils";
import { isGXModel } from "~/lib/DeviceUtils";
import { portWordForCount } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { switchportAlertingCount, switchportIsConnected } from "~/lib/SwitchPortStatusUtils";
import { getSwitchLayout } from "~/lib/SwitchUtils";
import { switchPortsForSerial } from "~/selectors";
import ApplianceFront from "~/shared/components/portDiagrams/ApplianceFront";
import SwitchFront from "~/shared/components/portDiagrams/SwitchFront";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import SummaryCard from "~/shared/components/SummaryCard";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useThemeColors } from "~/shared/hooks/useTheme";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import WrappingRow from "~/shared/rows/WrappingRow";
import { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

interface PortStatusBoxProps {
  activePortCount: number;
  activePortLabel: string;
  pushActivePortList: () => void;
  alertingPortCount: number;
  alertingPortLabel: string;
  pushAlertingPortList: () => void;
}

const PortStatusBox = ({
  activePortCount,
  activePortLabel,
  pushActivePortList,
  alertingPortCount,
  alertingPortLabel,
  pushAlertingPortList,
}: PortStatusBoxProps) => (
  <WrappingRow>
    <QuickStatusBox
      value={activePortCount}
      subText={activePortLabel}
      onPress={activePortCount === 0 ? undefined : pushActivePortList}
      testID={`ACTIVE_PORTS_${activePortCount}`}
    />
    <QuickStatusBox
      value={alertingPortCount}
      subText={alertingPortLabel}
      onPress={alertingPortCount === 0 ? undefined : pushAlertingPortList}
      testID={`ALERTING_PORTS_${activePortCount}`}
    />
  </WrappingRow>
);

interface SwitchPortStatusBoxProps {
  navigate: (screen: string, props: unknown) => void;
  device: DeviceWithNodeGroupInfo;
}

const SwitchPortStatusBox = ({ navigate, device }: SwitchPortStatusBoxProps) => {
  const serialNumber = device.serial;
  const switchPorts = useAppSelector((state) => switchPortsForSerial(state, serialNumber));
  const liveSwitchPortData = useSwitchPortStatuses(device.id);

  const pushPortsScreen = (title: string, filter = CUSTOM_FILTERS.ALL_PORTS) => {
    navigate("SwitchPortsList", { title, serialNumber, filter, device });
  };

  const getGSActivePortCount = () => {
    if (liveSwitchPortData === undefined) {
      return 0;
    }
    if (isEmpty(liveSwitchPortData)) {
      return 0;
    }

    const connectedPorts = Object.values(liveSwitchPortData).filter((port) =>
      switchportIsConnected(port),
    );

    return connectedPorts.length;
  };

  const getGSAlertingPortCount = () => switchportAlertingCount(switchPorts);

  const activePortLabel = () =>
    I18n.t("PORTS.ACTIVE_PORT", { port_word: portWordForCount(getGSActivePortCount()) });

  const alertingPortLabel = () =>
    I18n.t("PORTS.ALERTING_PORT.TITLE", {
      port_word: portWordForCount(getGSAlertingPortCount()),
    });

  const pushActivePortList = () => {
    pushPortsScreen(activePortLabel(), CUSTOM_FILTERS.ACTIVE_PORT);
  };

  const pushAlertingPortList = () => {
    pushPortsScreen(alertingPortLabel(), CUSTOM_FILTERS.ALERTING_PORT);
  };

  return (
    <PortStatusBox
      activePortCount={getGSActivePortCount()}
      activePortLabel={activePortLabel()}
      pushActivePortList={pushActivePortList}
      alertingPortCount={getGSAlertingPortCount()}
      alertingPortLabel={alertingPortLabel()}
      pushAlertingPortList={pushAlertingPortList}
    />
  );
};

interface AppliancePortStatusBoxProps {
  navigate: (screen: string, props: unknown) => void;
  device: DeviceWithNodeGroupInfo;
}

// GX doesn't actually report alerting port
const APPLIANCE_ALERTING_PORT_COUNT = 0;
const AppliancePortStatusBox = ({ navigate, device }: AppliancePortStatusBoxProps) => {
  const serialNumber = device.serial;

  const liveGXPortData = usePortStatuses(device.id);

  const pushPortsScreen = (title: string, filter = CUSTOM_FILTERS.ALL_PORTS) => {
    navigate("GXPortsList", { title, serialNumber, filter, device });
  };

  const getGXActivePortCount = () => {
    if (liveGXPortData === undefined) {
      return 0;
    }
    if (isEmpty(liveGXPortData)) {
      return 0;
    }

    const connectedPorts = liveGXPortData.filter((port) => port.carrier);
    return connectedPorts.length;
  };

  const activePortLabel = () =>
    I18n.t("PORTS.ACTIVE_PORT", { port_word: portWordForCount(getGXActivePortCount()) });

  const alertingPortLabel = I18n.t("PORTS.ALERTING_PORT.TITLE", {
    port_word: portWordForCount(APPLIANCE_ALERTING_PORT_COUNT),
  });

  const pushActivePortList = () => {
    pushPortsScreen(activePortLabel(), CUSTOM_FILTERS.ACTIVE_GX_PORT);
  };

  const pushAlertingPortList = () => {
    pushPortsScreen(alertingPortLabel, CUSTOM_FILTERS.ALERTING_PORT);
  };

  return (
    <PortStatusBox
      activePortCount={getGXActivePortCount()}
      activePortLabel={activePortLabel()}
      pushActivePortList={pushActivePortList}
      alertingPortCount={APPLIANCE_ALERTING_PORT_COUNT}
      alertingPortLabel={alertingPortLabel}
      pushAlertingPortList={pushAlertingPortList}
    />
  );
};

interface PortSummaryCardProps {
  navigate: (screen: string, props: unknown) => void;
  device: DeviceWithNodeGroupInfo;
}

export const PortSummaryCard = (props: PortSummaryCardProps) => {
  const { navigate, device } = props;

  const serialNumber = device.serial;

  const themeColors = useThemeColors();

  const renderSeperator = () => {
    const containerStyle = appSelect({
      enterprise: styles.container,
      go: [styles.container, { borderBottomColor: themeColors.border?.borderColor }],
    });
    return <View style={containerStyle} />;
  };

  switch (getProductType(device.model)) {
    case ProductType.appliance:
      try {
        getApplianceLayout(device.model);
      } catch (e) {
        return null;
      }
      break;
    case ProductType.switch:
      try {
        getSwitchLayout(device.model);
      } catch (e) {
        return null;
      }
      break;
    default:
      return null;
  }

  return (
    <View testID="PORT_SUMMARY_CARD">
      <SummaryCard
        heading={I18n.t("PORTS.TITLE")}
        contentContainerStyles={styles.portsContainer}
        disclosureRows={[
          {
            label: I18n.t("PORTS.SEE_ALL").toUpperCase(),
            onPress: () =>
              navigate(isGXModel(device) ? "GXPortsList" : "SwitchPortsList", {
                device,
                serialNumber,
                title: I18n.t("PORTS.TITLE"),
                filter: CUSTOM_FILTERS.ALL_PORTS,
              }),
            testID: "PORTS_SEE_ALL",
          },
        ]}
      >
        {isGXModel(device) ? (
          <ApplianceFront serialNumber={serialNumber} />
        ) : (
          <SwitchFront serialNumber={serialNumber} />
        )}
        {isGXModel(device) ? (
          <AppliancePortStatusBox navigate={navigate} device={device} />
        ) : (
          <SwitchPortStatusBox navigate={navigate} device={device} />
        )}
      </SummaryCard>
      {renderSeperator()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
    marginHorizontal: SPACING.default,
  },
  portsContainer: {
    borderBottomWidth: 0,
    marginHorizontal: 0,
    alignItems: "center",
  },
});

export default PortSummaryCard;
