import {
  ClientTrackingMethod,
  CONNECTIVITY_DATA_FAILURE,
  CONNECTIVITY_DATA_REQUEST,
  CONNECTIVITY_DATA_SUCCESS,
  DELETE_CONNECTIVITY_EVENTS,
  EVENT_DATA_FAILURE,
  EVENT_DATA_REQUEST,
  EVENT_DATA_SUCCESS,
} from "@meraki/shared/redux";

import { getNetworkClientTrackingMethod } from "~/actions/networks";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkClientTrackingMethod, getNetworkProductTypes } from "~/selectors";
import { ProductType } from "~/shared/types/Networks";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export interface EventRequestParams {
  networkId?: string;
  productType?: string | ProductType; // move to just ProductType after refactoring ClientLogs
  productTypes?: ProductType[];
  trackingMethod?: ClientTrackingMethod;
  deviceSerial?: string;
  clientMac?: string;
  clientIp?: string;
  endingBefore?: string;
  startingAfter?: string;
  perPage?: number;
  includedEventTypes?: string[];
}

function getResolvedEventsParams(params: EventRequestParams, trackingMethod: ClientTrackingMethod) {
  const resolvedParams = <EventRequestParams>{ ...params };

  switch (trackingMethod) {
    case ClientTrackingMethod.ip:
      delete resolvedParams.clientMac;
      break;
    case ClientTrackingMethod.mac:
    case ClientTrackingMethod.tbuci:
      delete resolvedParams.clientIp;
      break;
  }

  return resolvedParams;
}

export const deleteConnectivityEvents = (mac: string) => ({
  type: DELETE_CONNECTIVITY_EVENTS,
  payload: {
    mac,
  },
});

export function getNetworkEvents(
  params: EventRequestParams,
  getConnectivityEvents = false,
): AppThunk<Promise<ApiResponseAction<any>>> {
  const { networkId } = params;

  return async (dispatch, getState) => {
    const productTypes = getNetworkProductTypes(getState());
    if (productTypes?.includes(ProductType.appliance)) {
      try {
        // @ts-expect-error TS(2769): No overload matches this call.
        await dispatch(getNetworkClientTrackingMethod(networkId));
      } catch (e) {
        return Promise.reject("Failed to fetch the network client tracking method");
      }
    }

    const trackingMethod = currentNetworkClientTrackingMethod(getState());
    let actionTypes: [string, string, string] = [
      EVENT_DATA_REQUEST,
      EVENT_DATA_SUCCESS,
      EVENT_DATA_FAILURE,
    ];
    if (getConnectivityEvents) {
      actionTypes = [
        CONNECTIVITY_DATA_REQUEST,
        CONNECTIVITY_DATA_SUCCESS,
        CONNECTIVITY_DATA_FAILURE,
      ];
    }
    return dispatch({
      [CALL_API]: {
        types: actionTypes,
        endpoint: `/api/v1/networks/${networkId}/events`,
        config: {
          method: Method.get,
          queryParams: getResolvedEventsParams(params, trackingMethod),
        },
      },
    });
  };
}
