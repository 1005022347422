import { memo } from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import { platformSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import ListRow from "~/shared/rows/ListRow";

type Props = {
  renderAccessory?: boolean;
  children: React.ReactElement | string;
  onPress?: () => void;
  iconSize?: number;
  isFullButton?: boolean;
  value?: string;
  rowStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  subtitle?: string | null;
  icon?: React.ReactElement | null;
  testID?: string;
};

const DisclosureRow = memo(
  ({
    isFullButton = false,
    iconSize = 20,
    style,
    value,
    icon = null,
    renderAccessory = true,
    subtitle = null,
    testID,
    rowStyle = {},
    children,
    onPress,
  }: Props) => {
    const fullButtonStyle = isFullButton ? styles.fullButtonStyle : {};
    const iconName = platformSelect({
      ios: "chevron-forward",
      android: "md-arrow-forward",
    });

    const accessory = (
      <MerakiIcon
        name={iconName}
        size={iconSize}
        style={[styles.accessory, styles.fullButtonStyle]}
      />
    );

    const row = (
      <View style={[styles.noSeparatorStyle, style]}>
        <ListRow
          value={value}
          icon={icon}
          accessory={renderAccessory ? accessory : null}
          subtitle1={subtitle}
          testID={testID}
          leftStyle={styles.leftStyle}
          rightStyle={styles.rightStyle}
        >
          <MkiText screenStyles={[fullButtonStyle, rowStyle]}>{children}</MkiText>
        </ListRow>
      </View>
    );

    if (onPress) {
      return <Touchable onPress={onPress}>{row}</Touchable>;
    }

    return row;
  },
);

DisclosureRow.displayName = "DisclosureRow";

const styles = StyleSheet.create({
  leftStyle: {
    width: "70%",
  },
  rightStyle: {
    width: "30%",
  },
  accessory: {
    marginRight: 20,
  },
  fullButtonStyle: {
    color: TEXT_COLORS.link,
  },
  noSeparatorStyle: {
    paddingLeft: SPACING.default,
    paddingRight: 0,
  },
});

export default DisclosureRow;
