import { memo } from "react";
import { StyleSheet, View } from "react-native";

import mkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import MerakiIcon from "~/shared/components/icons";
import { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";
import MkiText from "~/shared/components/MkiText";

type Props = {
  iconName?: string;
  iconSize?: MerakiIconProps["size"];
  iconColor?: string;
  message: string;
  onPress?: () => void;
  buttonText?: string;
  deleteText?: string;
  deleteAction?: () => void;
  disabled?: boolean;
  testID?: string;
};

const NextStepsMessage = memo(function NextStepsMessage(props: Props) {
  const {
    iconName = "alert",
    iconSize = "xl",
    iconColor = mkiColors.goPurple,
    message,
    onPress,
    buttonText,
    deleteText,
    deleteAction = () => null,
    disabled,
    testID,
  } = props;
  return (
    <View style={styles.container} testID={testID}>
      <MerakiIcon name={iconName} size={iconSize} color={iconColor} />
      <MkiText textStyle="heading" screenStyles={styles.text}>
        {message}
      </MkiText>
      {buttonText && (
        <RoundedButton buttonType={ButtonType.primary} onPress={onPress}>
          {buttonText}
        </RoundedButton>
      )}
      {deleteText && (
        <RoundedButton onPress={deleteAction} buttonType="destructive" disabled={disabled}>
          {deleteText}
        </RoundedButton>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingHorizontal: SPACING.large,
    paddingVertical: SPACING.extraLarge,
  },
  text: {
    textAlign: "center",
    paddingTop: SPACING.small,
    paddingBottom: SPACING.default,
  },
});

export default NextStepsMessage;
