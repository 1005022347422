import { I18n } from "@meraki/core/i18n";
import { FirmwareUpgrades } from "@meraki/shared/api";
import { getProductType } from "@meraki/shared/devices";
import compact from "lodash/compact";
import { StyleProp, StyleSheet, TextStyle } from "react-native";

import { ApplianceSettings } from "~/api/schemas/ApplianceSettings";
import { Device } from "~/api/schemas/Device";
import { getConfigMessage } from "~/lib/ConfigUtils";
import { isDeviceWithNodeGroupInfo } from "~/lib/DeviceUtils";
import { getFirmwareMessage, getMinimalFirmwareMessage } from "~/lib/FirmwareUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { noneIfFalsey } from "~/lib/stringHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import Device_DeprecatedType, { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

export interface NodeInfoCardContent {
  label: string;
  value?: string | string[];
  rightTextStyle?: StyleProp<TextStyle>;
  isButton?: boolean;
  requiresSupport?: boolean;
  disableBottomBorder?: boolean;
}

export const nodeInfoCardContentForDevice = (
  device: DeviceWithNodeGroupInfo | Device | Device_DeprecatedType,
  options: {
    imei?: number;
    timezone?: string;
    applianceSettings?: ApplianceSettings;
    firmwareInfo?: FirmwareUpgrades;
    hasAlphaFeatures?: boolean;
    publicIp?: string;
    rfProfile?: string;
  },
): NodeInfoCardContent[] => {
  const { applianceSettings, timezone, imei, firmwareInfo, hasAlphaFeatures, publicIp, rfProfile } =
    options;
  const productType = getProductType(device.model) || "";
  const availableFirmware =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    firmwareInfo?.products && firmwareInfo?.products[productType]?.availableVersions;
  const currentFirmware =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    firmwareInfo?.products && firmwareInfo?.products[productType]?.currentVersion;

  const latestFirmware = availableFirmware?.filter((firmware: any) => {
    return firmware.id > currentFirmware.id;
  });

  let hasPublicIpContent = false;

  const content: NodeInfoCardContent[] = [
    { label: I18n.t("NODE_INFO.MODEL"), value: device.model },
    { label: I18n.t("NODE_INFO.SERIAL"), value: device.serial },
    { label: I18n.t("NODE_INFO.MAC_ADDRESS"), value: device.mac },
  ];

  if (productType === ProductType.cellularGateway) {
    content.push({
      label: I18n.t("NODE_INFO.IMEI"),
      value: noneIfFalsey(imei?.toString()),
    });
  }

  // If we're looking at an MX we want to show the WAN IPs
  if (isDeviceWithNodeGroupInfo(device) && device.wan1Ip && productType === ProductType.appliance) {
    const wan1Label = appSelect({
      enterprise: I18n.t("NODE_INFO.WAN_1_IP"),
      go: I18n.t("NODE_INFO.WAN_IP"),
    });

    content.push({ label: wan1Label, value: device.wan1Ip });

    if (device.wan2Ip) {
      content.push({ label: I18n.t("NODE_INFO.WAN_2_IP"), value: device.wan2Ip });
    }

    if (device.ip && device.ip !== device.wan1Ip) {
      hasPublicIpContent = true;
      content.push({
        label: I18n.t("NODE_INFO.PUBLIC_IP"),
        value: noneIfFalsey(device.ip),
      });
    }
  } else if (isDeviceWithNodeGroupInfo(device) && productType !== ProductType.sensor) {
    if (productType !== ProductType.appliance) {
      content.push({
        label: I18n.t("NODE_INFO.LAN_IP"),
        value: noneIfFalsey(device.lanIp),
      });
    }
    if (device.ip) {
      content.push({
        label: I18n.t("NODE_INFO.PUBLIC_IP"),
        value: noneIfFalsey(device.ip),
      });
      hasPublicIpContent = true;
    }
  }
  if (publicIp && !hasPublicIpContent) {
    content.push({
      label: I18n.t("NODE_INFO.PUBLIC_IP"),
      value: noneIfFalsey(publicIp),
    });
  }
  if (isDeviceWithNodeGroupInfo(device) && device.channels && compact(device.channels).length > 0) {
    // wireless only
    content.push({
      label: I18n.t("NODE_INFO.CHANNELS"),
      value: compact(device.channels).join(", "),
    });
  }

  if (!__MERAKI_GO__ && device.tags) {
    content.push({ label: I18n.t("NODE_INFO.TAGS"), value: device.tags });
  }

  if (device.notes) {
    content.push({ label: I18n.t("NODE_INFO.NOTES"), value: device.notes });
  }

  if (isDeviceWithNodeGroupInfo(device) && device.config_status && timezone) {
    content.push({
      label: I18n.t("NODE_INFO.CONFIG"),
      value: getConfigMessage(
        device.config_status,
        device["config_new_at#"],
        device["config_fetch_at#"],
      ),
    });
  }
  const isConfiguredVersion = device?.firmware === currentFirmware?.firmware;
  if (isDeviceWithNodeGroupInfo(device) && device.firmwareDetails) {
    const {
      hasEco,
      firmwareDetails: { has_available_upgrade, pending_version, current_version },
    } = device;

    content.push({
      label: I18n.t("FIRMWARE.TITLE"),
      value: getFirmwareMessage(has_available_upgrade, pending_version, current_version, hasEco),
    });
  }
  // will only be displayed for Go
  else if (device?.firmware != null) {
    if (__MERAKI_GO__ && hasAlphaFeatures) {
      if (isConfiguredVersion) {
        content.push({
          label: I18n.t("FIRMWARE_UPDATES.CURRENT"),
          value: getMinimalFirmwareMessage(device.firmware, device.model),
          disableBottomBorder: true,
        });
        if (latestFirmware.length != 0) {
          content.push({
            label: I18n.t("FIRMWARE_UPDATES.LATEST"),
            value: getMinimalFirmwareMessage(latestFirmware[0].firmware, device.model),
            disableBottomBorder: true,
          });
          content.push({
            label: I18n.t("FIRMWARE_UPDATES.SCHEDULE"),
            isButton: true,
          });
        } else {
          content.push({
            label: I18n.t("FIRMWARE_UPDATES.LATEST"),
            value: getMinimalFirmwareMessage(currentFirmware.firmware, device.model),
          });
        }
      } else {
        content.push({
          label: I18n.t("FIRMWARE.TITLE"),
          value: getMinimalFirmwareMessage(device.firmware, device.model),
          disableBottomBorder: true,
        });
        content.push({
          label: I18n.t("RESOURCES_RESULTS.OPEN_CASE_BUTTON"),
          isButton: true,
          requiresSupport: true,
        });
      }
    } else {
      content.push({
        label: I18n.t("FIRMWARE.TITLE"),
        value: device.firmware,
      });
    }
  }

  if (productType !== ProductType.cellularGateway && applianceSettings?.dynamicDns) {
    const { dynamicDns } = applianceSettings;
    const { url } = dynamicDns;
    content.push({
      label: I18n.t("NODE_INFO.HOSTNAME"),
      value: url,
      rightTextStyle: styles.ddns,
    });
  }
  if (rfProfile) {
    content.push({ label: I18n.t("NODE_INFO.RF_PROFILE"), value: rfProfile });
  }
  return content;
};

const styles = StyleSheet.create({
  ddns: {
    fontSize: normalizedFontSize(14),
    textAlign: "left",
  },
});

export default nodeInfoCardContentForDevice;
