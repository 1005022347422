import { I18n } from "@meraki/core/i18n";
import { Card, Checkbox, Heading, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { Form } from "@meraki/shared/form";
import { useFormContext, useWatch } from "react-hook-form";

import {
  FIVE_GHZ_POWER_LIMITS,
  TWO_GHZ_POWER_LIMITS,
  WirelessBandTypes,
} from "../../utils/RadioUtils";

interface TargetPowerCardProps {
  band: WirelessBandTypes;
}

export const TargetPowerCard = ({ band }: TargetPowerCardProps) => {
  const methods = useFormContext();
  const autoWord = I18n.t("RADIO_SETTINGS.GOv3.AUTO");
  const { min, max } = band === "twoFourGhzSettings" ? TWO_GHZ_POWER_LIMITS : FIVE_GHZ_POWER_LIMITS;

  const targetPower = useWatch({ name: `${band}.targetPower` });

  return (
    <Card testID="TARGET_POWER_CARD">
      <Card.Content gap="sm">
        <Box flexDirection="row" justifyContent="space-between" alignItems="center">
          <Heading>{I18n.t("RADIO_SETTINGS.HARDWARE.TARGET_POWER.LABEL")}</Heading>
          <Box flexDirection="row" maxWidth={"50%"}>
            <Text>{`${targetPower === null ? autoWord : `${targetPower} dBm`}`}</Text>
          </Box>
        </Box>

        <Box flex={1}>
          <Form.Slider
            name={`${band}.targetPower`}
            step={1}
            minimumValue={min}
            maximumValue={max}
            testID="TARGET_POWER_SLIDER"
          />
        </Box>

        <Box
          flexDirection="row"
          alignItems="center"
          gap="sm"
          testID="TARGET_POWER_AUTO_CHECKBOX_ROW"
        >
          <Checkbox
            checked={targetPower === null}
            onValueChange={(value) => {
              if (value === true) {
                methods.setValue(`${band}.targetPower`, null);
              } else {
                methods.setValue(`${band}.targetPower`, min);
              }
            }}
            testID={`TARGET_POWER_AUTO_CHECKBOX${targetPower === null ? ".SELECTED" : ""}`}
          />
          <Text size="p1">{I18n.t("RADIO_SETTINGS.GOv3.SET_TO_AUTO")}</Text>
        </Box>
      </Card.Content>
    </Card>
  );
};
