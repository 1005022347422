import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, EmptyState, List, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useDevices, usePoliciesByClient } from "@meraki/shared/api";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useClientDetails } from "../ClientDetailsContext";

export const DetailsTab = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const { client, openBlockBottomSheet, openLimitBottomSheet } = useClientDetails();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data: clientPolicies } = usePoliciesByClient(
    { networkId, timespan },
    {
      select(policies) {
        return policies.find((clientPolicy) => clientPolicy.clientId === client?.id);
      },
    },
  );
  const blockedWirelessPolicies = clientPolicies?.assigned.filter(
    (policyObj) => policyObj.type === "ssid" && policyObj.name === "Blocked",
  );
  const { data: device } = useDevices(
    {
      organizationId,
      networkId,
      serial: client?.recentDeviceSerial,
    },
    {
      enabled: Boolean(client?.recentDeviceSerial) && Boolean(client?.recentDeviceConnection),
      select: (data) => data[0],
    },
  );

  const isWirelessConnection = client?.recentDeviceConnection === "Wireless";
  const isSwitch = device?.productType === "switch";
  const isConnected = client?.status === "Online";

  return (
    <Screen addDefaultPadding testID="DETAILS_TAB">
      {!client ? (
        <EmptyState title={I18n.t("DEVICE_NOT_FOUND")} />
      ) : (
        <>
          <Box paddingBottom="sm" gap="sm" flexDirection="row">
            <Button
              text={I18n.t("THROTTLE_CLIENT.TITLE")}
              kind="secondary"
              onPress={openLimitBottomSheet}
              testID="LIMIT_CLIENT_BUTTON"
            />
            <Button
              text={
                blockedWirelessPolicies && blockedWirelessPolicies?.length > 0
                  ? I18n.t("BLOCK_CLIENT.TITLE_UNBLOCK")
                  : I18n.t("BLOCK_CLIENT.TITLE")
              }
              kind="secondary"
              onPress={openBlockBottomSheet}
              testID="BLOCK_CLIENT_BUTTON"
            />
          </Box>
          <Card>
            <Box flexDirection="row" gap="sm">
              <Status
                size={20}
                status={isConnected ? "positive" : "negative"}
                testID={isConnected ? "CONNECTED_ICON" : "DISCONNECTED_ICON"}
              />
              <Box>
                <Card.Header
                  title={
                    isConnected
                      ? I18n.t("CLIENT_DETAILS.CONNECTED")
                      : I18n.t("CLIENT_DETAILS.NOT_CONNECTED")
                  }
                />
                <Card.Content>
                  {isConnected ? (
                    <Text size="p3">
                      {I18n.t("CLIENT_DETAILS.CONNECTED_SINCE") +
                        ": " +
                        formatDate(client.firstSeen, {
                          dateFormat: "shortDate",
                          timeFormat: "longTime",
                        })}
                    </Text>
                  ) : (
                    <Text size="p3">
                      {I18n.t("CLIENT_DETAILS.LAST_SEEN") +
                        ": " +
                        formatDate(client.lastSeen, {
                          dateFormat: "shortDate",
                          timeFormat: "longTime",
                        })}
                    </Text>
                  )}
                </Card.Content>
              </Box>
            </Box>
          </Card>
          {isWirelessConnection ? (
            <List label={I18n.t("CLIENT_DETAILS.CONNECTION.WIRELESS.HEADING")}>
              <List.Item
                title={I18n.t("CLIENT_DETAILS.CONNECTION.WIRELESS.DEVICE_LABEL")}
                value={client.recentDeviceName}
                onPress={() =>
                  navigation.navigate("DeviceDetails", {
                    serial: client.recentDeviceSerial,
                  })
                }
              />
              <List.Item
                title={I18n.t("CLIENT_DETAILS.LAST_SEEN")}
                value={formatDate(client.lastSeen, {
                  dateFormat: "shortDate",
                  timeFormat: "longTime",
                })}
              />
            </List>
          ) : (
            <List label={I18n.t("CLIENT_DETAILS.CONNECTION.MX.HEADING_WIRED")}>
              <List.Item
                title={I18n.t(`PRODUCT_LINES.${isSwitch ? "GS" : "GX"}.one`)}
                value={client.recentDeviceName}
                onPress={() =>
                  navigation.navigate("DeviceDetails", { serial: client.recentDeviceSerial })
                }
              />
              <List.Item
                title={I18n.t("CLIENT_DETAILS.CONNECTION.SWITCH.CONNECTION_TYPE")}
                value={client.switchport}
                onPress={() => null}
              />
              <List.Item
                title={I18n.t("CLIENT_DETAILS.LAST_SEEN")}
                value={formatDate(client.lastSeen, {
                  dateFormat: "shortDate",
                  timeFormat: "longTime",
                })}
              />
            </List>
          )}
          <List label={I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.HEADING")}>
            <List.Item
              title={I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.OS")}
              value={client.os || client.manufacturer || I18n.t("UNKNOWN")}
            />
            <List.Item title={I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.MAC")} value={client.mac} />
            <List.Item title={I18n.t("CLIENT_DETAILS.CLIENT_ATTRIBUTES.IP")} value={client.ip} />
          </List>
        </>
      )}
    </Screen>
  );
};
