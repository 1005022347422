import { I18n } from "@meraki/core/i18n";
import { Vlan } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import GoColors from "~/go/constants/GoColors";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import useActions from "~/shared/hooks/redux/useActions";
import ListRow from "~/shared/rows/ListRow";

interface WiredNetworkRowProps {
  vlan: Vlan;
}

const WiredNetworkRow = ({ vlan }: WiredNetworkRowProps) => {
  const actions = useActions();
  const navigation = useNavigation();

  const onPress = () => {
    actions.setSelectedVlanId(vlan.id);
    navigation.navigate("LocalAddressing");
  };

  return (
    <ListRow
      testID={`WIRED_NETWORK_ROW.${vlan.id}`}
      icon={
        <StatusIcon
          customIcon={{
            icon: MerakiIcon,
            name: "sitemap",
            size: "m",
            color: GoColors.navyLighter,
            hasGradient: false,
          }}
          screenStyles={styles.icon}
          testID="WIRED_NETWORK_ROW.LAN_ICON"
        />
      }
      subtitle1={I18n.t("WIRED_ROW.SUBTITLE")}
      rightStyle={styles.rightAccessory}
      accessory={<MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />}
      onPress={onPress}
    >
      {vlan.name}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  rightAccessory: {
    paddingRight: SPACING.default,
  },
});

export default WiredNetworkRow;
