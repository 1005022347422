import { SENSORS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";
import { keyBy, pickBy } from "lodash";
import { AnyAction } from "redux";

import { WipeReduxAction } from "~/actions";
import { Network } from "~/api/schemas/Network";
import { Sensor } from "~/shared/types/Sensors";

type ReduxSensor = Sensor & { networkId: Network["id"] };
interface SensorsState {
  [sensorSerial: string]: ReduxSensor;
}

const initialState: SensorsState = {};

interface SensorSuccessAction extends AnyAction {
  type: typeof SENSORS_SUCCESS;
  meta: { networkId: Network["id"] };
  response: {
    count: number;
    sensors: Sensor[];
  };
}

export default function sensors(
  state = initialState,
  action: SensorSuccessAction | WipeReduxAction,
): SensorsState {
  switch (action.type) {
    case SENSORS_SUCCESS: {
      const { networkId } = action.meta;
      const nextState = pickBy(state, (sensor: ReduxSensor) => sensor.networkId === networkId);
      const sensorsWithNetworkId = action.response.sensors.map((sensor: Sensor) => ({
        ...sensor,
        networkId,
      }));
      const sensorsBySerial = keyBy(sensorsWithNetworkId, "serial");
      return {
        ...nextState,
        ...sensorsBySerial,
      };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
