import { useNavigation } from "@react-navigation/native";

import { FixActionSuggestion, SuggestionMessageKeys } from "~/go/types/FixNetworkEvent";
import { DHCPHandling } from "~/go/types/NetworksTypes";
import { isDHCPProblem } from "~/lib/NetworkEventUtils";
import { gxNetworkSelector } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { NetworkEvent } from "~/shared/types/NetworkEvent";

const useFixSuggestionForNetworkEvent = (event: NetworkEvent): FixActionSuggestion => {
  const navigation = useNavigation();
  const { setSelectedVlanId } = useActions();
  const network = useAppSelector(gxNetworkSelector);

  let suggestion: FixActionSuggestion = undefined;
  if (isDHCPProblem(event.type) && network?.vlans != null && event.eventData.vlan != null) {
    const targetVlanID = parseInt(event.eventData.vlan);
    const targetVlan = network.vlans.find(({ id }) => id === targetVlanID);

    if (targetVlan == null) {
      suggestion = {
        action: () => navigation.navigate("ConfigureVlan"),
        messageKey: SuggestionMessageKeys.createNewVLAN,
        messageOptions: { vlanID: targetVlanID },
      };
    } else if (targetVlan.dhcpHandling !== DHCPHandling.on) {
      suggestion = {
        action: () => {
          setSelectedVlanId(targetVlanID);
          navigation.navigate("DHCPServer");
        },
        messageKey: SuggestionMessageKeys.enableDHCP,
        messageOptions: { vlanID: targetVlanID },
      };
    }
  }

  return suggestion;
};

export default useFixSuggestionForNetworkEvent;
