import MkiColors from "~/constants/MkiColors";
import { TIMESTAMP_KEY } from "~/constants/MkiConstants";
import { SummaryLabel } from "~/go/types/LocationAnalyticsTypes";
import I18n from "~/i18n/i18n";
import { DataKey, DataKeyColors } from "~/shared/types/MkiChartTypes";

// Constants for Sessions data, tuple is of the form
// [time_of_session_beginning, time_of_session_end, num_unique_visitors, num_total_visitors, total_visit_time]

export const CHART_Y_AXIS_FORMAT = (t: any) => t;

export const getNumberVisitorsSummaryLabel = (): SummaryLabel => ({
  units: undefined,
  label: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.NUM_VISITORS"),
});

export const getCaptureRateSummaryLabel = (): SummaryLabel => ({
  units: "%",
  label: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.CAPTURE_RATE"),
});

export const getAverageLengthSummaryLabel = (): SummaryLabel => ({
  units: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.AVERAGE_VISIT_LENGTH_UNITS"),
  label: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.AVERAGE_VISIT_LENGTH"),
});

export const getNumNewVisitorsSummaryLabel = (): SummaryLabel => ({
  units: undefined,
  label: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.NEW_VISITORS"),
});

export const getReturningVisitorsSummaryLabel = (): SummaryLabel => ({
  units: "%",
  label: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.RETURNING_VISITORS"),
});

export const SUMMARY_CARD_DATA_KEY: DataKey = { x: TIMESTAMP_KEY, y: "visitors" };

export const getProximityCardDataKeys = (): DataKey[] => [
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.CONNECTED"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.PASSERSBY"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.VISITORS"),
  },
];

export const PROXIMITY_CARD_COLORS: DataKeyColors[] = [
  [MkiColors.pie3, MkiColors.comparePurple, MkiColors.compareTeal],
  [MkiColors.pie2, MkiColors.comparePurpleLight, MkiColors.compareTealLight],
  [MkiColors.pie1, MkiColors.comparePurpleLighter, MkiColors.compareTealLighter],
];

export const getEngagementCardDataKeys = (): DataKey[] => [
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.FIVE_TWENTY_MIN"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.TWENTY_SIXTY_MIN"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.ONE_SIX_HRS"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.SIX_PLUS_HRS"),
  },
];

export const ENGAGEMENT_CARD_COLORS: DataKeyColors[] = [
  [MkiColors.pie4, MkiColors.comparePurple, MkiColors.compareTeal],
  [MkiColors.pie3, MkiColors.comparePurpleLight, MkiColors.compareTealLight],
  [MkiColors.pie2, MkiColors.comparePurpleLighter, MkiColors.compareTealLighter],
  [MkiColors.pie1, MkiColors.comparePurpleLightest, MkiColors.compareTealLightest],
];

export const getLoyaltyCardDataKeys = (): DataKey[] => [
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.DAILY"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.WEEKLY"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.OCCASIONAL"),
  },
  {
    x: TIMESTAMP_KEY,
    y: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.FIRST_TIME"),
  },
];

export const LOYALTY_CARD_COLORS: DataKeyColors[] = [
  [MkiColors.pie4, MkiColors.comparePurple, MkiColors.compareTeal],
  [MkiColors.pie3, MkiColors.comparePurpleLight, MkiColors.compareTealLight],
  [MkiColors.pie2, MkiColors.comparePurpleLighter, MkiColors.compareTealLighter],
  [MkiColors.pie1, MkiColors.comparePurpleLightest, MkiColors.compareTealLightest],
];
