import { storage } from "@meraki/core/secure-storage";
import { createContext, ReactNode, useCallback, useContext, useState } from "react";

const CLOAK_STORAGE_KEY = "@meraki/core/cloak";

type CloakContextValue = {
  enabled: boolean;
  toggle: (enabled: boolean) => void;
};
export const CloakContext = createContext<CloakContextValue>({
  enabled: false,
  toggle: () => undefined,
});

export interface CloakProviderProps {
  children: ReactNode;
}

export function CloakProvider({ children }: CloakProviderProps) {
  const [cloakEnabled, setCloakEnabled] = useState(storage.getBoolean(CLOAK_STORAGE_KEY) ?? false);

  const toggleCloak = useCallback(
    (enabled: boolean) => {
      storage.set(CLOAK_STORAGE_KEY, enabled);
      setCloakEnabled(enabled);
    },
    [setCloakEnabled],
  );

  return (
    <CloakContext.Provider value={{ enabled: cloakEnabled, toggle: toggleCloak }}>
      {children}
    </CloakContext.Provider>
  );
}

export function useCloak() {
  const cloakEnabled = useContext(CloakContext);

  return cloakEnabled;
}
