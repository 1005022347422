import { SSID_USEBLOCKS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

import resolvedState from "~/lib/ReducerUtils";

const initialState = {
  items: {},
};

export default function ssidUseblocks(state = initialState, action: any) {
  switch (action.type) {
    case SSID_USEBLOCKS_SUCCESS: {
      const { networkId, ssidNumber, timespan } = action.meta;
      const nextState = {
        ...state,
        items: {
          ...state.items,
          [`${networkId}:${ssidNumber}:${timespan}`]: action.response,
        },
      };
      return resolvedState(nextState, state);
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
