import * as SwitchPortStats from "../handlers/SwitchPortStats";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useSwitchPortStatuses(deviceId: string): LiveTool<SwitchPortStats.Ports> {
  const msg = useLiveBroker<BaseDeviceSubProps, SwitchPortStats.Message>({
    type: "SwitchPortStats",
    deviceId,
  });

  return useCachedFormatter(msg, SwitchPortStats.formatMsg);
}
