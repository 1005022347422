import {
  calculateSubnetRange,
  doesSubnetRangeOverlaps,
  ip4ToInt,
  isIpReservationConflicting,
} from "@meraki/shared/ip-address";
import { Address4, Address6 } from "ip-address";
import { isEmpty } from "lodash";

import {
  IP_ADDRESS_PART_COUNT,
  IP_ADDRESS_PART_MAX,
  IP_ADDRESS_PART_SIZE,
  IP_ADDRESSES_USABLE,
  IPV4_BIT_SIZE,
  SUBNET_REGEX,
} from "~/constants/IPAddress";
import { BITS_IN_A_BYTE } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { removeNonDigits } from "~/lib/stringHelper";
import { StaticIPForm } from "~/shared/types/IPAddress";

export function extractSubnetMask(cidrAddress: string): number {
  return parseInt(cidrAddress.split("/")[1], 10);
}

export function extractNetworkPrefix(cidrAddress: string): string {
  return cidrAddress.split("/")[0];
}

export function extractNetworkPrefixParts(cidrAddress: string): string[] {
  return extractNetworkPrefix(cidrAddress).split(".");
}

export function dottedDecimalFromSubnetMask(subnetMask: number): string {
  const dottedDecimal: number[] = [];

  const divisionWhole = Math.floor((subnetMask - 1) / 8);
  const cidrFraction = subnetMask - 8 * divisionWhole;

  for (let i = 0; i < IP_ADDRESS_PART_COUNT; i += 1) {
    let workingOctet;
    if (divisionWhole === i) {
      workingOctet = 2 ** 8 - 2 ** (8 - cidrFraction);
    } else if (divisionWhole > i) {
      workingOctet = 2 ** 8 - 1;
    } else {
      workingOctet = 0;
    }
    dottedDecimal.push(workingOctet);
  }
  return dottedDecimal.join(".");
}

// Source: https://ba.net/util/netcalc/netcalc.html
export function subnetMaskFromDottedDecimal(dottedDecimal: string): number {
  const dottedArray = dottedDecimal.split(".");
  let sumofbits = 0;
  for (let i = 0; i < IP_ADDRESS_PART_COUNT; i += 1) {
    const tmpvar = parseInt(dottedArray[i], 10);
    if (isNaN(tmpvar)) {
      return sumofbits;
    }

    const bits = countBits(tmpvar);

    if (isNaN(bits)) {
      return sumofbits;
    }

    sumofbits += bits;
  }

  return sumofbits;
}

// Source: https://ba.net/util/netcalc/netcalc.html
function countBits(num: any) {
  if (num === IP_ADDRESS_PART_MAX) {
    return IP_ADDRESS_PART_SIZE;
  }
  let i = 0;
  let bitpat = IP_ADDRESSES_USABLE;
  while (i < IP_ADDRESS_PART_SIZE) {
    if (num === (bitpat & IP_ADDRESS_PART_MAX)) {
      return i;
    }

    bitpat >>= 1;
    i += 1;
  }

  return Number.NaN;
}

export function formatAddressPart(text: string): string {
  const intTextValue = parseInt(removeNonDigits(text), 10);
  return isNaN(intTextValue) ? "" : String(intTextValue);
}

export function indexOfFirstEditableAddressPart(subnetMask: number | null): number {
  if (subnetMask === null) {
    return 0;
  }

  let correctedSubnetMask = subnetMask;

  // Subnet mask should not exceed 32. This will be rounded down to 32
  if (subnetMask > IPV4_BIT_SIZE) {
    correctedSubnetMask = IPV4_BIT_SIZE;
  }

  // Subnet mask should not be negative. This will be rounded up to 0
  if (subnetMask < 0) {
    correctedSubnetMask = 0;
  }

  return Math.floor(correctedSubnetMask / BITS_IN_A_BYTE);
}

// simply checks to see if the IP satisifies either IPv4 or IPv6
export function isIPValid(ip: string) {
  return Address4.isValid(ip) || Address6.isValid(ip);
}

export function validateIpRange(
  startIp: string,
  endIp: string,
  cidr: string,
  applianceIp: string,
): string | null {
  const startIpError = isIpReservationConflicting(startIp, cidr, applianceIp);
  if (startIpError) {
    return startIpError;
  }

  const endIpError = isIpReservationConflicting(endIp, cidr, applianceIp);
  if (endIpError) {
    return endIpError;
  }

  const startIpBigInt = new Address4(startIp).bigInteger();
  const endIpBigInt = new Address4(endIp).bigInteger();
  const gxReservedAddressBigInt = new Address4(applianceIp).bigInteger();
  if (!startIpBigInt || !endIpBigInt) {
    return I18n.t("RESERVE_IP_RANGE.ENTER_IP_ERROR");
  }

  if (
    startIpBigInt.compareTo(gxReservedAddressBigInt) <= 0 &&
    endIpBigInt.compareTo(gxReservedAddressBigInt) >= 0
  ) {
    return I18n.t("RESERVE_IP_RANGE.GX_ADDRESS_ERROR", { ip_address: applianceIp });
  }

  return null;
}

export function compareIpAddresses(firstIp: string, secondIp: string): undefined | number {
  if (!Address4.isValid(firstIp) || !Address4.isValid(secondIp)) {
    return undefined;
  }
  const firstIpBigInt = new Address4(firstIp).bigInteger();
  const secondIpBigInt = new Address4(secondIp).bigInteger();
  return firstIpBigInt.compareTo(secondIpBigInt);
}

export function calculateNetworkAddress(applianceIp: string, mask: string): string | null {
  try {
    const cidr = `${applianceIp}/${mask}`;
    const address = new Address4(cidr);
    return address.startAddress().correctForm();
  } catch (_) {
    return null;
  }
}

export const ALLOWED_MASKS = [
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];

export const MASK_ROWS = ALLOWED_MASKS.map((mask) => ({ label: mask, value: mask }));

const DOT_REGEX = /\./g;

export function createWan1Body(formState: StaticIPForm) {
  const {
    usingStaticIp,
    vlan,
    staticIp,
    staticSubnetMask,
    staticGatewayIp,
    primaryStaticDns,
    secondaryStaticDns,
  } = formState;

  const staticDns: string[] = [];

  const newVlan = isEmpty(vlan) ? null : vlan;

  if (usingStaticIp) {
    staticDns.push(primaryStaticDns);

    if (!isEmpty(secondaryStaticDns.replace(DOT_REGEX, ""))) {
      staticDns.push(secondaryStaticDns);
    }

    return {
      usingStaticIp,
      vlan: newVlan,
      staticIp,
      staticSubnetMask: dottedDecimalFromSubnetMask(parseInt(staticSubnetMask)),
      staticGatewayIp,
      staticDns,
    };
  }

  return { usingStaticIp, vlan: newVlan };
}

export function isSubnetValid(targetSubnet: string, existingSubnetRanges: number[][]) {
  if (SUBNET_REGEX.test(targetSubnet)) {
    if (existingSubnetRanges.length === 0) {
      return true;
    }

    const targetSubnetRange = calculateSubnetRange(targetSubnet);
    return (
      targetSubnetRange != null &&
      !existingSubnetRanges.some((existingSubnetRange) =>
        doesSubnetRangeOverlaps(targetSubnetRange, existingSubnetRange),
      )
    );
  }

  return false;
}

export function findInvalidAddressesForGivenRange(ipAddresses: string[], subnet: string) {
  const subnetRange = calculateSubnetRange(subnet);

  if (subnetRange == null) {
    return [];
  }

  const [minIp, maxIp] = subnetRange;
  return ipAddresses.filter((ipAddress) => {
    const intIp = ip4ToInt(ipAddress);
    return intIp <= minIp || intIp > maxIp;
  });
}

export function getNetworkIdFromSubnetIp(ipSubnet: string) {
  const ipAddress = ipSubnet.split("/")[0];
  return `${ipAddress.split(".").slice(0, -1)}`;
}

export function isInSubnet(subnet: string, address: string | null) {
  return address && subnet && new Address4(address).isInSubnet(new Address4(subnet));
}
