import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useOrganization } from "@meraki/shared/api";
import { PickerOption } from "@meraki/shared/components";
import { PhotoSelect } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import Toast from "react-native-simple-toast";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import {
  exportToPdf,
  FontWeightOptions,
  generatePdfHTML,
  ImageState,
  pdfOptions,
} from "../utils/pdfUtils";
import { PDFOptionsType } from "../utils/pdfUtils";

interface PDFStoreState {
  pdfOptions: {
    [user: string]: {
      [network: string]: {
        [ssidNumber: number]: PDFOptionsType;
      };
    };
  };
  setPDFOptions: (
    user: string,
    networkId: string,
    ssidNumber: number,
    pdfOptions: PDFOptionsType,
  ) => void;
}

export const usePDFSettingsStore = create<PDFStoreState>()(
  persist(
    (set) => ({
      pdfOptions: {},
      setPDFOptions: (user, networkId, ssidNumber, pdfOptions) =>
        set((state) => ({
          pdfOptions: {
            ...state.pdfOptions,
            [user]: {
              ...(state.pdfOptions[user] ?? {}),
              [networkId]: {
                ...(state.pdfOptions[user]?.[networkId] ?? {}),
                [ssidNumber]: pdfOptions,
              },
            },
          },
        })),
    }),
    {
      name: "pdfSettingsStorage",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

const fontWeightOptions: PickerOption<FontWeightOptions>[] = [
  { label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.LIGHTER"), value: "lighter" },
  { label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.NORMAL"), value: "normal" },
  { label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.BOLD"), value: "bold" },
];

type PDFForm = {
  message: string;
  fontWeight: FontWeightOptions;
};

export function GeneratePDFScreen() {
  const setPDFStore = usePDFSettingsStore((state) => state.setPDFOptions);
  const pdfOptions = usePDFSettingsStore((state) => state.pdfOptions);
  const networkId = useCurrentNetworkId();
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const organizationId = useCurrentOrganizationId();
  const { data: organization } = useOrganization({ organizationId });
  const route = useRoute<RouteProp<SsidGroupProps, "GeneratePDF">>();
  const { params: props } = route;
  const user = useCurrentUserEmail();

  const options = pdfOptions[user]?.[networkId ?? ""]?.[props.ssidNumber];
  const [image, setImage] = useState<ImageState | undefined>(options?.image ?? undefined);

  const methods = useForm<PDFForm>({
    values: {
      message: options?.message ?? "",
      fontWeight: options?.fontWeight ?? "normal",
    },
  });

  useEffect(() => {
    const save = ({ message, fontWeight }: PDFForm) => {
      setPDFStore(user, networkId ?? "", props.ssidNumber, {
        message,
        fontWeight,
        image,
      });
      Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
    };
    const onSubmit = methods.handleSubmit(save);
    navigation.setOptions({
      headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.goBack()} />,
      headerRight: () => <Button.Nav text={I18n.t("SAVE")} onPress={onSubmit} />,
      headerTitle: I18n.t("SSID_SHARE_PDF.TITLE"),
    });
  }, [methods, navigation, image, setPDFStore, user, networkId, props.ssidNumber]);

  const showPDF = () => {
    const pdfOptions: Partial<pdfOptions> = {
      organizationName: organization?.name,
      ssidName: props.ssidName,
      ssidPassword: props.ssidPsk,
      qrCode: props.QRString,
      fontWeight: methods.getValues("fontWeight"),
      customMessage: methods.getValues("message"),
      base64Logo: image?.content,
    };
    const html = generatePdfHTML(pdfOptions);
    const options = {
      html: html,
      fileName: I18n.t("SSID_SHARE_PDF.PDF_NAME"),
      padding: 0,
    };
    exportToPdf(options);
  };

  return (
    <Screen addDefaultPadding>
      <Button
        text={I18n.t("PREVIEW")}
        trailingIcon="Export"
        onPress={() => showPDF()}
        kind="secondary"
        testID="PREVIEW"
      />
      <Form {...methods}>
        <Form.Input
          name="message"
          label={I18n.t("SSID_SHARE_PDF.CUSTOMIZE.CUSTOM_MESSAGE.LABEL")}
          testID="MESSAGE"
        />
        <Text weight="bold">{I18n.t("SSID_SHARE_PDF.CUSTOMIZE.LOGO")}</Text>
        <PhotoSelect
          sourceUri={image?.uri}
          onPhotoSelect={({ content, extension }) => {
            if (content && extension) {
              setImage({
                extension,
                uri: `data:image/${extension};base64,${content}`,
                content,
              });
            }
          }}
        />
        <Form.PickerCard
          name="fontWeight"
          options={fontWeightOptions}
          title={I18n.t("SSID_SHARE_PDF.WEIGHT")}
        />
      </Form>
    </Screen>
  );
}
