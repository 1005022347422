import { I18n } from "@meraki/core/i18n";
import { launchUrl } from "@meraki/shared/links";
import { Platform } from "react-native";

export const documentationErrorMessage = I18n.t("DOCS.ERROR.ENTERPRISE");

export const appStoreUrl = () => {
  if (Platform.OS === "android") {
    return launchUrl("https://play.google.com/store/apps/details?id=com.meraki.Dashboard");
  }
  if (Platform.OS === "ios") {
    return launchUrl(
      "https://apps.apple.com/app/apple-store/id693056161?pt=14097&ct=In%20App%20Update&mt=8",
    );
  }
  return "";
};

export const documentationUrl = () =>
  launchUrl(I18n.t("DOCS.URL.ENTERPRISE"), documentationErrorMessage);
