import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import { memo } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { timespanNameSelector } from "~/selectors";
import ClientIcon, { ClientIconProps } from "~/shared/components/ClientIcon";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import GeneralStatus from "~/shared/constants/Status";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

import { RootState } from "../types/Redux";

export const CLIENT_ROW_SUBTITLE_FORMATS = {
  usage: "usage",
  hideUsage: "hide",
  usageWithTimespan: "withTimespan",
  ip: "ip",
  custom: "custom",
} as const;

const VisibleProps = [
  "children",
  "status",
  "blockedMessage",
  "subtitleFormat",
  "timespanName",
  "usage",
  "ip",
  "customSubtitle",
  "isOnline",
];

type Props = {
  children: string;
  isOnline?: boolean;
  hideOnlineStatus?: boolean;
  blockedMessage?: string;
  limitedMessage?: string;
  timespanName?: string;
  type?: ClientIconProps["type"];
  deleteIcon?: React.ReactElement;
  onPress?: () => void;
  customSubtitle?: string;
  ip?: string;
  subtitleFormat?: (typeof CLIENT_ROW_SUBTITLE_FORMATS)[keyof typeof CLIENT_ROW_SUBTITLE_FORMATS];
  usage?: number;
  screenStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

export const ClientListRowComponent = memo(
  (props: Props) => {
    const {
      blockedMessage,
      limitedMessage,
      children,
      onPress,
      screenStyles,
      hideOnlineStatus,
      testID,
      type,
      isOnline = false,
      deleteIcon,
    } = props;

    let statusIcon;
    const subtitle = renderSubtitle(props);
    if (blockedMessage || limitedMessage) {
      if (blockedMessage) {
        statusIcon = (
          <MerakiIcon
            name="block"
            size="xs"
            color={MkiColors.portAlertingColor}
            containerStyle={styles.blockIcon}
          />
        );
      }

      if (limitedMessage) {
        statusIcon = (
          <MerakiIcon
            name="warning"
            size="xs"
            color={MkiColors.warningStatus}
            containerStyle={styles.blockIcon}
          />
        );
      }
    } else if (!hideOnlineStatus) {
      if (type) {
        statusIcon = <ClientIcon on={isOnline} type={type} />;
      } else {
        const status = isOnline ? GeneralStatus.good : GeneralStatus.dormant;
        statusIcon = <StatusIcon status={status} screenStyles={styles.statusIcon} />;
      }
    }

    return (
      <SimpleDisclosureRow
        onPress={onPress}
        testID={testID}
        subtitle={subtitle}
        style={screenStyles}
        icon={statusIcon}
        deleteIcon={deleteIcon}
      >
        {children}
      </SimpleDisclosureRow>
    );
  },
  (prevProps, nextProps) => {
    for (const visibleProp of VisibleProps) {
      // @ts-expect-error TS(7053):  implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (prevProps[visibleProp] !== nextProps[visibleProp]) {
        return false;
      }
    }
    return true;
  },
);

ClientListRowComponent.displayName = "ClientListRowComponent";

const renderSubtitle = (props: Props) => {
  const {
    blockedMessage,
    limitedMessage,
    subtitleFormat = CLIENT_ROW_SUBTITLE_FORMATS.usage,
    timespanName,
    usage,
    ip,
    customSubtitle,
  } = props;

  if (blockedMessage && limitedMessage) {
    return `${blockedMessage}\n${limitedMessage}`;
  }

  if (blockedMessage) {
    return blockedMessage;
  }

  if (limitedMessage) {
    return limitedMessage;
  }

  if (subtitleFormat === CLIENT_ROW_SUBTITLE_FORMATS.hideUsage) {
    return null;
  }

  if (subtitleFormat === CLIENT_ROW_SUBTITLE_FORMATS.ip) {
    return ip;
  }

  if (subtitleFormat === CLIENT_ROW_SUBTITLE_FORMATS.custom) {
    return customSubtitle;
  }

  if (usage === undefined) {
    return null;
  }

  const { value, unit } = formatAndParseKibibytes(usage);
  const formattedUsage = `${value} ${unit}`;
  switch (subtitleFormat) {
    case CLIENT_ROW_SUBTITLE_FORMATS.usageWithTimespan:
      return I18n.t("TOP_CLIENTS.USAGE_FOR_TIMESPAN", {
        usage: formattedUsage,
        timespan: timespanName,
      });
    case CLIENT_ROW_SUBTITLE_FORMATS.usage:
    default:
      return formattedUsage;
  }
};

const styles = StyleSheet.create({
  statusIcon: {
    marginLeft: SPACING.meager,
    marginRight: SPACING.small,
  },
  blockIcon: {
    marginRight: SPACING.small,
  },
});

const mapState = (state: RootState) => ({
  timespanName: timespanNameSelector(state),
});

export default connect(mapState)(ClientListRowComponent);
