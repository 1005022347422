import MkiColors from "~/constants/MkiColors";
import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";
import SharedColors from "~/shared/constants/SharedColors";
import ThemeInterface from "~/shared/themes/ThemeInterface";

import MagneticColors from "../constants/MagneticColors";

// empty {} to take component defined styles
// This file can contain raw values for now
const Dark: ThemeInterface = {
  navigation: {
    primary: EnterpriseColors.systemBlueDark,
    secondary: SharedColors.white,
    backgroundPrimary: EnterpriseColors.backgroundPrimaryDark,
    backgroundSecondary: EnterpriseColors.backgroundPrimaryElevated,
    tabBorder: SharedColors.gray20Lighter,
    textSecondary: EnterpriseColors.textSecondaryDark,
    textTertiary: EnterpriseColors.textTertiaryDark,
  },
  modal: {
    background: EnterpriseColors.backgroundPrimaryDark,
  },
  status: {
    online: {
      color: EnterpriseColors.systemGreenDark,
      background: SharedColors.greenLightest,
    },
    offline: {
      color: EnterpriseColors.systemRed,
      background: SharedColors.redLightest,
    },
    alerting: {
      color: EnterpriseColors.systemYellowDark,
      background: SharedColors.yellowLightest,
    },
    dormant: {
      color: EnterpriseColors.systemGrey1,
      background: EnterpriseColors.systemGrey1,
    },
  },
  spinner: {
    color: SharedColors.whiteFaded50,
  },
  spinnerColor: {
    backgroundColor: MkiColors.spinnerBackgroundGray,
  },
  graph: {
    gridColor: SharedColors.gray10,
    barChartBackground: SharedColors.gray20,
  },
  locationAnalyticsLegend: {
    selectedLabel: {
      color: SharedColors.white,
    },
    unselectedLabel: {
      color: MkiColors.graphLegendSelectedColor,
    },
  },
  border: {
    borderColor: SharedColors.gray20,
    strongBorderColor: SharedColors.gray70,
  },
  tileBorderColor: SharedColors.gray20,
  text: {
    accordianHeader: {
      color: SharedColors.white,
    },
    title: {
      color: SharedColors.white,
    },
    announcement: {
      color: SharedColors.white,
    },
    header: {
      color: SharedColors.white,
    },
    navigation: {
      color: SharedColors.white,
    },
    label: {
      color: SharedColors.gray70,
    },
    unit: {
      color: SharedColors.white,
    },
    bigNumber: {
      color: SharedColors.white,
    },
    heading: {
      color: SharedColors.white,
    },
    subheader: {
      color: SharedColors.white,
    },
    subheading: {
      color: SharedColors.white,
    },
    subtitle: {
      color: SharedColors.gray40,
    },
    small: {
      color: SharedColors.white,
    },
    smallBold: {
      color: SharedColors.white,
    },
    placeholder: {
      color: SharedColors.gray30,
    },
    disabled: {
      color: SharedColors.gray80,
    },
    default: {
      color: SharedColors.white,
    },
    defaultBold: {
      color: SharedColors.white,
    },
    smallSecondary: {
      color: SharedColors.gray70,
    },
    active: {
      color: SharedColors.gray70,
    },
    inactive: {
      color: SharedColors.gray30,
    },
    pressable: {
      color: EnterpriseColors.systemBlueDark,
    },
    redAlerting: {
      color: EnterpriseColors.red20,
    },
  },
  closeButton: SharedColors.gray70,
  selectedColor: {
    backgroundColor: SharedColors.gray20,
  },
  infoCard: {
    backgroundColor: SharedColors.whiteFaded05,
  },
  localAddressing: {
    backgroundColor: SharedColors.grayFaded35,
  },
  frontPanel: {
    backgroundColor: MkiColors.switchFrontPanelBackgroundColor,
  },
  roundedButtons: {
    destructive: {
      button: { backgroundColor: SharedColors.errorRed },
      buttonText: { color: MkiColors.badStatusBackground },
    },
    secondary: {
      button: {
        backgroundColor: null,
        borderColor: MkiColors.primaryButton,
      },
      buttonText: {},
    },
  },
  selectedRowColor: SharedColors.purpleDarkest,
  searchBar: {
    placeholderText: {
      color: MkiColors.placeholderTextColor,
    },
    searchBarContainer: {
      backgroundColor: EnterpriseColors.backgroundPrimaryDark,
    },
    withBottomBorder: {
      backgroundColor: EnterpriseColors.backgroundPrimaryDark,
    },
    searchBarInput: {
      color: SharedColors.white,
    },
    searchBarInputContainer: {
      backgroundColor: EnterpriseColors.backgroundPrimaryDark,
    },
    searchBarClearIcon: {
      backgroundColor: EnterpriseColors.backgroundPrimaryDark,
      color: SharedColors.white,
    },
    searchIcon: {
      color: SharedColors.white,
    },
  },
  gridItem: {
    borderColor: SharedColors.gray40Darker,
    backgroundColor: EnterpriseColors.backgroundPrimaryElevated,
  },
  utilityBar: {
    color: SharedColors.gray70,
  },
  inactive: {
    color: SharedColors.gray20,
  },
  skeletonBlock: {
    backgroundColor: MkiColors.darkSeletonBlock,
  },
  graphColors: {
    barChartDefaultFill: SharedColors.teal,
    goodBar: EnterpriseColors.green,
    online: EnterpriseColors.green,
    offline: EnterpriseColors.red,
    lightGreenWarningStatus: SharedColors.greenLighter,
    alerting: SharedColors.yellow,
    offlineBar: SharedColors.gray50,
    applicationDetailsClientBar: SharedColors.green,
    unknown: EnterpriseColors.gray70Lighter,
    pie1: SharedColors.teal,
    pie2: SharedColors.chartBlue1,
    pie3: SharedColors.chartBlue2,
    pie4: SharedColors.chartMagenta,
    pie5: SharedColors.chartPurple,
    pie6: SharedColors.tealLighter,
    pie7: SharedColors.gray50,
    lblue: EnterpriseColors.lblue,
    lpink: EnterpriseColors.lpink,
    lgreen: EnterpriseColors.lgreen,
    mblue: EnterpriseColors.mblue,
    lpurple: EnterpriseColors.lpurple,
    morange: EnterpriseColors.morange,
    lorange: EnterpriseColors.lorange,
    mpink: EnterpriseColors.mpink,
    mgreen: EnterpriseColors.mgreen,
    mpurple: EnterpriseColors.mpurple,
    other: SharedColors.teal,
    othergrey: SharedColors.gray70Lighter,
  },
  port: {
    active: EnterpriseColors.systemGreenDark,
    alerting: EnterpriseColors.systemRed,
    disconnected: SharedColors.gray50,
    disabled: SharedColors.gray20,
    poeActive: SharedColors.white,
    uplinkActive: SharedColors.white,
  },
  icon: {
    tabBar: {
      selected: EnterpriseColors.systemGreenDark,
    },
    backgroundColor: EnterpriseColors.neutral16,
    stroke: EnterpriseColors.neutral10,
  },
  mapStatusColors: {
    online: EnterpriseColors.systemGreenDark,
    alerting: SharedColors.orange,
    offline: EnterpriseColors.systemRed,
    dormant: EnterpriseColors.systemGrey1,
  },
  IAQ: {
    excellent: EnterpriseColors.excellent,
    good: EnterpriseColors.good,
    fair: EnterpriseColors.fair,
    poor: EnterpriseColors.poor,
    inadequate: EnterpriseColors.inadequate,
  },
  accordian: {
    alert: {
      background: EnterpriseColors.backgroundPrimaryDark,
      pressed: EnterpriseColors.backgroundPrimaryDark,
      chevron: MagneticColors.neutral1,
      text: MagneticColors.neutral1,
    },
  },
};

export default Dark;
