import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Heading,
  Input,
  List,
  Loader,
  Text,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  UmbrellaProtection,
  useAddGoLicense,
  useDevices,
  useGetLicenses,
  useUmbrellaPolicies,
  useUmbrellaProtection,
  useUmbrellaProtectionUpdateJob,
  useUpdateUmbrellaProtection,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Linking, Platform } from "react-native";

import { EditExcludeBottomSheet } from "../components/EditExcludeBottomSheet";
import {
  ActiveStatuses,
  InactiveStatuses,
  SUPPORTED_UMBRELLA_POLICIES,
} from "../constants/Umbrella";

const WEB_APP_URL = "https://web.meraki-go.com";
const REFETCH_INTERVAL = 500;

export const SecurityProtectionScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "SecurityProtection">>();
  const { params: props } = route;

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  const [stagedLicenseKey, setStagedLicenseKey] = useState<string>("");
  const [updateIndex, setUpdateIndex] = useState<number>(-1);

  const addKeyBottomSheetRef = useRef<BottomSheetMethods>(null);
  const editExludeBottomSheetRef = useRef<BottomSheetMethods>(null);

  const addLicense = useAddGoLicense();

  const { data: device, isLoading: isDeviceFetching } = useDevices(
    { organizationId, networkId },
    {
      select(devices) {
        return devices.find(({ productType }) => productType === "appliance");
      },
    },
  );
  const { data: license, isLoading: isLicenseFetching } = useGetLicenses(
    { organizationId },
    {
      select(licenses) {
        if (networkId && device) {
          const networkLicenses = licenses.filter((license) => {
            return license.networkId === networkId;
          });
          const gxLicenses = networkLicenses
            .filter(({ licenseType }) => licenseType === "GX-UMB")
            .sort((a, b) => {
              const aDate = b.expirationDate ?? 0;
              const bDate = a.expirationDate ?? 0;
              return new Date(aDate).valueOf() - new Date(bDate).valueOf();
            });

          return (
            (gxLicenses.find(({ deviceSerial }) => deviceSerial === device.serial) &&
              gxLicenses.find(({ state }) => state === "active")) ||
            gxLicenses.find(({ state }) => ActiveStatuses.includes(state)) ||
            gxLicenses.find(({ state }) => InactiveStatuses.includes(state))
          );
        }
        return undefined;
      },
    },
  );
  const { data: protection, isLoading: isProtectionFetching } = useUmbrellaProtection({
    networkId,
  });
  const { data: goPolicies, isLoading: isPoliciesFetching } = useUmbrellaPolicies(
    { organizationId },
    {
      select(data) {
        return data.filter(({ name }) => SUPPORTED_UMBRELLA_POLICIES.includes(name));
      },
    },
  );

  const {
    mutate: updateUmbrella,
    isLoading: isUpdateProtectionFetching,
    data: updateResponse,
  } = useUpdateUmbrellaProtection();

  const { data: updateJobData, isFetching: isUpdateJobFetching } = useUmbrellaProtectionUpdateJob(
    { networkId, updateJobId: updateResponse?.id },
    {
      enabled: Boolean(updateResponse?.id),
      refetchInterval(data) {
        return data?.status === "complete" ? false : REFETCH_INTERVAL;
      },
    },
  );

  const defaultPolicy = goPolicies?.find((policy) => policy.default);

  const methods = useForm<UmbrellaProtection>({
    values: protection && {
      ...protection,
      umbrellaPolicyId: protection?.umbrellaPolicyId ?? defaultPolicy?.id ?? null,
    },
  });

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button.Nav
            leadingIcon="CaretLeft"
            text={props.deviceLabel || ""}
            onPress={() => navigation.goBack()}
          />
        ),
        headerRight: () => (
          <Button.Nav
            text={I18n.t("SAVE")}
            onPress={methods.handleSubmit((umbrellaProtection) => {
              updateUmbrella(
                {
                  networkId,
                  umbrellaProtection,
                },
                {
                  onError(error) {
                    showErrorAlert(
                      error.errors ? error.errors.join(",") : I18n.t("SERVER_ERROR_TEXT"),
                    );
                  },
                },
              );
            })}
            disabled={!methods.formState.isDirty}
          />
        ),
      }),
    [props, methods, methods.formState.isDirty, navigation, networkId, updateUmbrella],
  );

  const isWeb = Platform.OS === "web";

  const excluded = methods.watch("excludedDomains");

  const isLoading =
    isDeviceFetching ||
    isLicenseFetching ||
    isProtectionFetching ||
    isPoliciesFetching ||
    isUpdateProtectionFetching ||
    isUpdateJobFetching ||
    (!!updateResponse?.id && updateJobData?.status !== "complete");

  const onSubscriptionButtonPressed = () => {
    if (!isWeb) {
      addKeyBottomSheetRef.current?.present();
    } else {
      Linking.openURL(WEB_APP_URL);
    }
  };

  const addSubscription = useCallback(() => {
    if (device) {
      addLicense.mutate(
        { key: stagedLicenseKey, GXDeviceSerial: device.serial },
        {
          onError: (error) => {
            Alert.alert(error.error ?? I18n.t("SERVER_ERROR_TEXT"));
          },
          onSettled: () => {
            addKeyBottomSheetRef.current?.dismiss();
          },
        },
      );
    }
  }, [device, stagedLicenseKey, addLicense]);

  const addExcludedUrl = (url: string) => {
    const newExcluded = [...excluded, url];
    methods.setValue("excludedDomains", newExcluded, { shouldDirty: true });
  };

  const updateExcludedURL = (url: string) => {
    const newExcluded = excluded;
    newExcluded[updateIndex] = url;
    methods.setValue("excludedDomains", newExcluded, { shouldDirty: true });
    setUpdateIndex(-1);
  };

  const deleteExcludedURL = (domain: string) => {
    const newExcluded = excluded.filter((url) => url !== domain);
    methods.setValue("excludedDomains", newExcluded, { shouldDirty: true });
  };

  return (
    <Screen scrollEnabled addDefaultPadding>
      <Box bottomDividerBorder paddingBottom="sm">
        <Heading size="h1">{I18n.t("UMBRELLA.ENABLE.TITLE")}</Heading>
      </Box>
      {isLoading ? <Loader.Spinner animate={true} testID="LOADING_SPINNER" /> : null}
      {license ? (
        <Box testID="SUBSCRIPTION_ACTIVE" gap="sm">
          <Form {...methods}>
            <List>
              <List.Item
                title={I18n.t("UMBRELLA.SUBSCRIPTION.ACTIVATION")}
                value={
                  license.activationDate
                    ? formatDate(license?.activationDate, { dateFormat: "longDateWithDayOfWeek" })
                    : ""
                }
              />
              <List.Item
                title={I18n.t("UMBRELLA.SUBSCRIPTION.EXPIRATION")}
                value={
                  license.expirationDate
                    ? formatDate(license?.expirationDate, { dateFormat: "longDateWithDayOfWeek" })
                    : ""
                }
              />
            </List>

            <List>
              <List.Item
                title={I18n.t("UMBRELLA.ENABLE.TITLE")}
                description={I18n.t("UMBRELLA.ENABLE.DESCRIPTION")}
                rightAccessory={
                  <Form.Toggle
                    name="umbrellaProtectionEnabled"
                    disabled={isLoading}
                    testID="PROTECTION_TOGGLE"
                  />
                }
              />
            </List>

            <Form.PickerCard
              title={I18n.t("UMBRELLA.POLICY.TITLE")}
              name="umbrellaPolicyId"
              options={goPolicies?.map((policy) => ({ label: policy.name, value: policy.id }))}
              disabled={isLoading}
              testID="SECURITY_POLICY_CARD"
            />
            <Box>
              <Box
                paddingHorizontal="xs"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text size="p2" weight="bold">
                  {I18n.t("UMBRELLA.EXCLUDED_URLS.TITLE")}
                </Text>
                <Button
                  text={I18n.t("ADD")}
                  kind="tertiary"
                  trailingIcon="Plus"
                  onPress={() => {
                    setUpdateIndex(-1);
                    editExludeBottomSheetRef.current?.present();
                  }}
                  disabled={isLoading}
                  testID="EXCLUDED_URL.ADD_BUTTON"
                />
              </Box>
              <List.FlashList
                paddingTop="none"
                paddingLeft="none"
                paddingRight="none"
                paddingBottom="none"
                data={methods.watch("excludedDomains")}
                getItemData={(domain, _, index) => {
                  return {
                    title: domain,
                    rightAccessory: (
                      <Button
                        text={I18n.t("EDIT")}
                        kind="tertiary"
                        onPress={() => {
                          setUpdateIndex(index);
                          editExludeBottomSheetRef.current?.present();
                        }}
                        disabled={isLoading}
                        testID={`EXCLUDED_URL.${index}.EDIT_BUTTON`}
                      />
                    ),
                    rightActions: [
                      {
                        text: I18n.t("DELETE"),
                        onPress: () => deleteExcludedURL(domain),
                        color: "negative.bg.base",
                        textColor: "inverse.text.base",
                      },
                    ],
                  };
                }}
                emptyState={{
                  title: I18n.t("UMBRELLA.EXCLUDE_URL.EMPTY_STATE"),
                }}
                testID="EXCLUDED_URL_LIST"
              />
            </Box>
          </Form>
        </Box>
      ) : (
        <Box testID="NO_SUBSCRIPTION">
          <Text size="p1">{I18n.t("UMBRELLA.SUBSCRIPTION.DESCRIPTION")}</Text>
          <Button
            kind="secondary"
            text={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.BUTTON")}
            onPress={() => onSubscriptionButtonPressed()}
            testID="SUBSCRIPTION_BUTTON"
          />
        </Box>
      )}
      <Box bottomDividerBorder paddingVertical="md">
        <Text size="p1" weight="bold">
          {I18n.t("UMBRELLA.ITEMS.MALWARE.TITLE")}
        </Text>
        <Text size="p1" color="light">
          {I18n.t("UMBRELLA.ITEMS.MALWARE.DESCRIPTION")}
        </Text>
      </Box>
      <Box bottomDividerBorder paddingVertical="sm">
        <Text size="p1" weight="bold">
          {I18n.t("UMBRELLA.ITEMS.PHISHING.TITLE")}
        </Text>
        <Text size="p1" color="light">
          {I18n.t("UMBRELLA.ITEMS.PHISHING.DESCRIPTION")}
        </Text>
      </Box>
      <Box bottomDividerBorder paddingVertical="sm">
        <Text size="p1" weight="bold">
          {I18n.t("UMBRELLA.ITEMS.CONTENT_FILTERING.TITLE")}
        </Text>
        <Text size="p1" color="light">
          {I18n.t("UMBRELLA.ITEMS.CONTENT_FILTERING.DESCRIPTION")}
        </Text>
      </Box>
      <Box paddingVertical="sm">
        <Text size="p1" weight="bold">
          {I18n.t("UMBRELLA.ITEMS.COMMAND_AND_CONTROL_CALLBACKS.TITLE")}
        </Text>
        <Text size="p1" color="light">
          {I18n.t("UMBRELLA.ITEMS.COMMAND_AND_CONTROL_CALLBACKS.DESCRIPTION")}
        </Text>
      </Box>

      <BottomSheet.Modal ref={addKeyBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
          onCancelPress={() => {
            setStagedLicenseKey("");
            addKeyBottomSheetRef.current?.dismiss();
          }}
          onResetPress={() => setStagedLicenseKey("")}
        />
        <BottomSheet.Content>
          <Card>
            <Input
              label={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.ENTER_KEY")}
              onChangeText={setStagedLicenseKey}
              value={stagedLicenseKey}
              placeholder={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.PLACEHOLDER")}
              testID="SUBSCRIPTION_INPUT"
            />
            <Button
              text={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
              onPress={addSubscription}
              testID="ADD_BUTTON"
              disabled={!stagedLicenseKey}
            />
          </Card>
        </BottomSheet.Content>
      </BottomSheet.Modal>
      <EditExcludeBottomSheet
        bottomSheetRef={editExludeBottomSheetRef}
        initialURL={updateIndex === -1 ? "" : excluded[updateIndex] ?? ""}
        onSave={updateIndex === -1 ? addExcludedUrl : updateExcludedURL}
        type={updateIndex === -1 ? "add" : "update"}
      />
    </Screen>
  );
};
