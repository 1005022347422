import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import { LoginResponse, LoginResponseType } from "./types";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const ResendOTPSignupSchema = z.unknown().describe("ResendOTPSignupSchema");
type ResendOTPSignupResultType = z.infer<typeof ResendOTPSignupSchema>;

const resendOTPSignup = () =>
  request(ResendOTPSignupSchema, "POST", "/login/otp_auth_retry", {
    headers,
    queryParams: { retry: "email" },
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useResendOTP = createMutation<void, ResendOTPSignupResultType>({
  baseMutationKey: "/login/otp_auth_retry",
  mutationFn: () => resendOTPSignup(),
});

type SubmitData = {
  code: string;
};

const submitOTP = (formData: SubmitData) =>
  request(LoginResponse, "POST", "/login/do_otp_auth", {
    headers,
    queryParams: formData,
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSubmitOTP = createMutation<SubmitData, LoginResponseType>({
  baseMutationKey: "/login/do_otp_auth",
  mutationFn: (data) => submitOTP(data),
});
