import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, G, Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Network({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color3 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 32 32" width={width} height={height} fill="none">
      <G fill={color1} opacity=".4">
        <Path d="M16.75 14.7V7h-1.5v7.7c.22-.127.477-.2.75-.2s.53.073.75.2ZM16.75 14.7a1.5 1.5 0 0 1 0 2.6l6.67 3.85.75-1.3L17.5 16l6.67-3.85-.75-1.3-6.67 3.85ZM16.75 17.3a1.495 1.495 0 0 1-1.5 0V25h1.5v-7.7ZM15.25 17.3a1.5 1.5 0 0 1-.75-1.3l-6.669 3.85.75 1.3 6.669-3.85ZM14.5 16a1.5 1.5 0 0 1 .75-1.3l-6.669-3.85-.75 1.3L14.5 16Z" />
      </G>
      <Circle cx="16" cy="8" fill={color2} r="1.5" />
      <Circle cx="23.5" cy="11.75" fill={color2} r="1.5" />
      <Circle cx="8.5" cy="11.75" fill={color2} r="1.5" />
      <Circle cx="23.5" cy="20.25" fill={color2} r="1.5" />
      <Circle cx="8.5" cy="20.25" fill={color2} r="1.5" />
      <Circle cx="16" cy="24" fill={color2} r="1.5" />
      <Path
        clipRule="evenodd"
        d="M16 19.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm0-2a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill={color2}
        fillRule="evenodd"
      />
      <Rect height="30" rx="7" stroke={color3} strokeWidth="2" width="30" x="1" y="1" />
    </Svg>
  );
}
