import { I18n } from "@meraki/core/i18n";
import { inRange, isEmpty, isEqual, pick } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { Entry, key, value } from "~/lib/EntryUtils";
import { formatPercent } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { getFullStageName } from "~/lib/WirelessHealthUtils";
import { KEY_SUCCESS } from "~/reducers/wirelessHealth";
import { getConnectionStats } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import GeneralStatus from "~/shared/constants/Status";
import { ConnectionStats } from "~/shared/types/WirelessHealth";

import { RootState } from "../types/Redux";

// lodash.inrange is exclusive of ending range
const GREEN_STATUS_RANGE = [0, 26] as const;
const YELLOW_STATUS_RANGE = [26, 51] as const;
const RED_STATUS_RANGE = [51, 101] as const;

type ReduxProps = {
  connectionStats: ConnectionStats;
};

export interface WirelessHealthAssocOverviewProps extends ReduxProps {
  clientMac?: string;
  serial?: string;
}

const NO_DATA_STATS = {
  assoc: 0,
  auth: 0,
  dhcp: 0,
  dns: 0,
  success: 0,
};

export class WirelessHealthAssocOverview extends PureComponent<WirelessHealthAssocOverviewProps> {
  getDisplayableConnectionStats = (connectionStats: ConnectionStats): Entry<number>[] => {
    const totalConnections = Object.values(connectionStats).reduce((sum, value) => sum + value);

    return Object.entries(connectionStats)
      .filter((entry) => entry[key] !== KEY_SUCCESS)
      .map((entry: Entry<number>) => {
        const numFailures = entry[value] as number;
        return [
          getFullStageName(entry[key] as "auth" | "assoc" | "dhcp" | "dns"),
          numFailures / totalConnections,
        ];
      });
  };

  getStatusFromRatio = (failureRatio: number) => {
    const failurePercentage = Math.round(failureRatio * 100);
    if (inRange(failurePercentage, ...GREEN_STATUS_RANGE)) {
      return GeneralStatus.good;
    }
    if (inRange(failurePercentage, ...YELLOW_STATUS_RANGE)) {
      return GeneralStatus.alerting;
    }
    if (inRange(failurePercentage, ...RED_STATUS_RANGE)) {
      return GeneralStatus.bad;
    }

    return GeneralStatus.dormant;
  };

  renderStageName = (failureStage: string, index: number) => {
    return (
      <MkiText key={`stageName${index}`} screenStyles={styles.verticalMargin}>
        {failureStage}
      </MkiText>
    );
  };

  renderStageNameColumn(failureStages: string[]) {
    return <View style={styles.stageNameColumn}>{failureStages.map(this.renderStageName)}</View>;
  }

  renderStatus = (failureRatio: number, index: number) => {
    return <StatusIcon key={`status${index}`} status={this.getStatusFromRatio(failureRatio)} />;
  };

  renderStatusColumn = (failureRatios: number[]) => {
    return (
      <View style={styles.statusColumn}>
        <View style={styles.statusColumnLine} />
        <View style={styles.statusColumnStatuses}>{failureRatios.map(this.renderStatus)}</View>
      </View>
    );
  };

  renderPercentFailure = (failureRatio: number, index: number) => {
    return (
      <MkiText key={`failure${index}`} screenStyles={styles.verticalMargin}>
        {formatPercent(failureRatio)}{" "}
        {I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.ASSOC_OVERVIEW.FAIL")}
      </MkiText>
    );
  };

  renderPercentFailureColumn = (failureRatios: number[]) => {
    return (
      <View style={styles.percentFailureColumn}>
        {failureRatios.map(this.renderPercentFailure)}
      </View>
    );
  };

  renderPercentPassed = (failureRatio: number, phrase: string, index: number) => {
    return (
      <View key={`percentPassed${index}`} style={styles.passedPercentContainer}>
        <MkiText screenStyles={styles.passedPercentage}>{formatPercent(1 - failureRatio)}</MkiText>
        <MkiText screenStyles={styles.verticalMargin}>{phrase}</MkiText>
      </View>
    );
  };

  renderPassPhrasesColumn = (failureRatios: number[]) => {
    const stageNames = [
      I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.ASSOC"),
      I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.PSK"),
      I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.DHCP"),
      I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.DNS"),
    ];

    return (
      <View style={styles.percentFailureColumn}>
        {failureRatios.map((ratio, idx) => this.renderPercentPassed(ratio, stageNames[idx], idx))}
      </View>
    );
  };

  renderNoDataText = () => {
    return (
      <MkiText
        textStyle={appSelect({
          go: "secondary",
          enterprise: undefined,
        })}
        screenStyles={styles.noDataText}
      >
        {I18n.t("ASSOCIATION_STATS.NO_DATA")}
      </MkiText>
    );
  };

  // TODO: When we want to spend time enhancing the support for larger text/accessibility,
  // We will likely want to change this from 3 columns to a table with individual rows
  // to show the name, status icon, and failure.
  // this current setup does not display as expected when text starts to word wrap. However,
  // for now, since the names are set and should be small, we are holding off for when we
  // focus on the accessibility support.
  render() {
    const { connectionStats: connectionStatsFromProps } = this.props;
    const dataStats = isEmpty(connectionStatsFromProps) ? NO_DATA_STATS : connectionStatsFromProps;
    const connectionStats = this.getDisplayableConnectionStats(dataStats);
    const noDataAvailable =
      isEmpty(connectionStatsFromProps) || isEqual(connectionStatsFromProps, NO_DATA_STATS);
    const stageNames = connectionStats.map((entry: Entry<number>) => entry[key] as string);
    const failureRatios = connectionStats.map((entry: Entry<number>) => entry[value] as number);

    if (__MERAKI_GO__) {
      return (
        <View style={styles.goContainer}>
          <View style={styles.container}>
            {noDataAvailable ? (
              this.renderNoDataText()
            ) : (
              <>
                {this.renderStatusColumn(failureRatios)}
                {this.renderPassPhrasesColumn(failureRatios)}
              </>
            )}
          </View>
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {this.renderStageNameColumn(stageNames)}
        {this.renderStatusColumn(failureRatios)}
        {noDataAvailable ? this.renderNoDataText() : this.renderPercentFailureColumn(failureRatios)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  goContainer: {
    justifyContent: "space-around",
  },
  passedPercentContainer: {
    flex: 1,
    flexDirection: "row",
  },
  passedPercentage: {
    width: 80,
    alignSelf: "center",
  },
  verticalMargin: {
    marginVertical: 5,
  },
  stageNameColumn: {
    alignItems: "flex-end",
    flex: 9,
  },
  statusColumn: {
    alignItems: "center",
    flex: 2,
    flexDirection: "column",
  },
  statusColumnLine: {
    borderLeftWidth: 0.5,
    borderLeftColor: MkiColors.secondaryTextColor,
    marginTop: 15,
    flexGrow: 0.9,
  },
  statusColumnStatuses: {
    justifyContent: "space-around",
    position: "absolute",
    height: "100%",
  },
  percentFailureColumn: {
    flex: 9,
  },
  noDataText: {
    flexBasis: "45%",
    alignSelf: "center",
  },
});

function mapStateToProps(state: RootState, props: WirelessHealthAssocOverviewProps): ReduxProps {
  return {
    connectionStats: getConnectionStats(
      state,
      pick(
        props,
        ...appSelect({
          go: ["networkId"],
          enterprise: ["clientMac", "serial"],
        }),
      ),
    ),
  };
}

export default connect(mapStateToProps)(WirelessHealthAssocOverview);
