import {
  ONBAORDING_BATCH_CLAIM_SUCCESS,
  ONBOARDING_ADD_NODE,
  ONBOARDING_SET_FLOW,
  ONBOARDING_SET_IS_COMPLETE,
  ONBOARDING_SET_IS_FORCED,
  ONBOARDING_SET_NODES,
  ONBOARDING_SET_SKIPPED,
  ONBOARDING_SET_STAGE,
  ONBOARDING_SET_STATUS,
  ONBOARDING_TIMING_START,
  UDG_NODES_STATUS_SUCCESS,
} from "@meraki/shared/redux";
import { get, isEmpty } from "lodash";

import { CLAIM_SUCCESS_MESSAGE } from "~/constants/OnboardingConstants";
import { getNodesStatusTrailing, updateNodesOnboardingStatus } from "~/lib/OnboardingMappings";
import { reducerHelper } from "~/lib/ReducerUtils";
import {
  OnboardingBatchClaimResult,
  OnboardingNodeStatus,
  OnboardingReducer,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";

const initialState: OnboardingReducer = {};

export function filterSuccessfulBatchClaimSerials(batchClaimResult: OnboardingBatchClaimResult) {
  const serials: string[] = [];
  if (isEmpty(batchClaimResult)) {
    return serials;
  }

  Object.entries(batchClaimResult).forEach(([serial, result]) => {
    if (result === CLAIM_SUCCESS_MESSAGE) {
      serials.push(serial);
    }
  });

  return serials;
}

export default function onboarding(state = initialState, action: any) {
  const { user, type } = action;
  switch (type) {
    case ONBOARDING_ADD_NODE: {
      // Onboarding Fullstack Rewrite
      const existingScanned = get(state[user], "nodes", {});
      const mergedScanned = { ...existingScanned, [action.serial]: OnboardingNodeStatus.unstarted };
      return reducerHelper(state, user, {
        nodes: mergedScanned,
      });
    }
    case ONBAORDING_BATCH_CLAIM_SUCCESS: {
      const { response, meta } = action;
      const { user: udgUser } = meta;

      const successfullSerials = filterSuccessfulBatchClaimSerials(response.result);

      const existingScanned = get(state[user], "nodes", {});
      const mergedScanned = { ...existingScanned };
      successfullSerials.forEach((serial) => {
        mergedScanned[serial] = OnboardingNodeStatus.unstarted;
      });

      return reducerHelper(state, udgUser, {
        batchClaimResult: response.result,
        nodes: mergedScanned,
      });
    }
    case ONBOARDING_SET_STAGE: {
      if (action.stage === OnboardingStage.exit) {
        return reducerHelper(state, user, {
          stage: action.stage,
          onboardingStartTime: null,
        });
      }
      return reducerHelper(state, user, {
        stage: action.stage,
      });
    }
    case ONBOARDING_SET_FLOW: {
      return reducerHelper(state, user, {
        flow: action.flow,
      });
    }
    case ONBOARDING_SET_NODES: {
      return reducerHelper(state, user, {
        nodes: action.nodes,
      });
    }
    case ONBOARDING_SET_SKIPPED: {
      if (action.skipped) {
        return reducerHelper(state, user, {
          skipped: action.skipped,
          onboardingStartTime: null,
          batchClaimResult: {},
        });
      }
      return reducerHelper(state, user, {
        skipped: action.skipped,
      });
    }
    case ONBOARDING_SET_STATUS: {
      return reducerHelper(state, user, {
        status: action.status,
      });
    }
    case ONBOARDING_SET_IS_COMPLETE: {
      return reducerHelper(state, user, {
        isComplete: action.isComplete,
        batchClaimResult: {},
      });
    }
    case ONBOARDING_SET_IS_FORCED: {
      return reducerHelper(state, user, {
        isForced: action.isForced,
      });
    }
    case UDG_NODES_STATUS_SUCCESS: {
      const { response, meta } = action;
      const { user: udgUser } = meta;
      const existingNodes = get(state[udgUser], "nodes", {});
      const nodesStatus = updateNodesOnboardingStatus(response, existingNodes);
      const summarizedStatus = getNodesStatusTrailing(existingNodes);

      return reducerHelper(state, udgUser, {
        nodes: nodesStatus,
        status: summarizedStatus,
      });
    }
    case ONBOARDING_TIMING_START: {
      return reducerHelper(state, user, {
        onboardingStartTime: action.onboardingStartTime,
      });
    }
    default: {
      return state;
    }
  }
}
