import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import {
  ContactSupportDeviceFormat,
  ContactSupportDeviceRowDataFormat,
} from "~/go/screens/ContactSupportScreen";
import { normalizedFontSize } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import { RowData } from "~/shared/components/NestedTableRenderRow";
import Touchable from "~/shared/components/Touchable";

interface DevicesSectionProps {
  devices: ContactSupportDeviceFormat[];
  showHardwareList: () => void;
  renderDeviceRow: (
    rowData: RowData<ContactSupportDeviceRowDataFormat>,
    rowId: number,
  ) => JSX.Element;
  removeDevice: (serial: string) => () => void;
}

const DevicesSection = (props: DevicesSectionProps) => {
  const { devices, showHardwareList, renderDeviceRow, removeDevice } = props;
  return (
    <View style={styles.sectionContainer} testID="DEVICE_SECTION">
      <MkiText textStyle="small" screenStyles={styles.label} testID="DEVICES_DESCRIPTION">
        {I18n.t("SUPPORT.SECTION.DEVICES")}
      </MkiText>
      <View style={styles.deviceRowOffset}>
        {devices.map((deviceObj, index) => {
          const { device, isGateway, label, status } = deviceObj;
          return renderDeviceRow(
            {
              device,
              isGateway,
              label,
              model: device.model,
              onClose: removeDevice(device.serial),
              status,
              onPress: () => void 0,
              selected: false,
            },
            index,
          );
        })}
      </View>
      <Touchable onPress={showHardwareList} transparentBackground>
        <View>
          <MkiText screenStyles={styles.selectHardwareButton} testID="SELECT_HARDWARE_TEXT">
            {I18n.t("SUPPORT.SELECT_HARDWARE_BUTTON")}
          </MkiText>
        </View>
      </Touchable>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    color: MkiColors.formFieldLabel,
  },
  sectionContainer: {
    marginTop: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.small,
  },
  deviceRowOffset: {
    marginHorizontal: -SPACING.default,
  },
  selectHardwareButton: {
    alignSelf: "center",
    marginTop: SPACING.default,
    color: MkiColors.primaryButton,
    fontSize: normalizedFontSize(16),
  },
});

export default DevicesSection;
