import { defaultTimespans, DefaultTimespansKeys } from "@meraki/shared/redux";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { checkIfDateRangesDiffer, makeDateRangeValid } from "~/lib/LocationAnalyticsUtils";
import { getEndOfYesterdayAsDate, getStartOfYesterdayAsDate } from "~/lib/timeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiDateRangePicker from "~/shared/components/MkiDateRangePicker";
import MkiSegmentedControl from "~/shared/components/MkiSegmentedControl";
import MkiText from "~/shared/components/MkiText";
import { DateRange } from "~/shared/types/TimeTypes";

import { NetworkScreensPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "LocationAnalyticsDate">;

interface LocationAnalyticsDateScreenState {
  dateRange: DateRange;
  comparedDateRange: DateRange;
  comparisonTimespan?: DefaultTimespansKeys;
}

const timeRangeIsValidAndUpdated = (props: Props, state: LocationAnalyticsDateScreenState) => {
  const { initialDateRange, initialComparedDateRange } = props;
  const { dateRange, comparedDateRange, comparisonTimespan } = state;
  const datesAreDefined = dateRange.startDate && dateRange.endDate;
  let datesDidUpdate = false;
  if (!initialDateRange) {
    datesDidUpdate = true;
  } else {
    datesDidUpdate = checkIfDateRangesDiffer(dateRange, initialDateRange);
  }
  if (comparisonTimespan) {
    const comparedDatesAreDefined = comparedDateRange.startDate && comparedDateRange.endDate;
    let comparedDatesDidUpdate = false;
    if (!initialComparedDateRange) {
      comparedDatesDidUpdate = true;
    } else {
      comparedDatesDidUpdate = checkIfDateRangesDiffer(comparedDateRange, initialComparedDateRange);
    }
    return datesAreDefined && comparedDatesAreDefined && (datesDidUpdate || comparedDatesDidUpdate);
  }
  return datesAreDefined && datesDidUpdate;
};

class LocationAnalyticsDateScreen extends PureComponent<Props, LocationAnalyticsDateScreenState> {
  constructor(props: Props) {
    super(props);
    const { initialDateRange, initialComparedDateRange, initialComparisonTimespan } = props;
    const defaultDateRange = {
      startDate: getStartOfYesterdayAsDate(),
      endDate: getEndOfYesterdayAsDate(),
    };
    this.state = {
      dateRange: initialDateRange || defaultDateRange,
      comparedDateRange: initialComparedDateRange || defaultDateRange,
      comparisonTimespan: initialComparisonTimespan,
    };
  }

  onDateRangeChange = (dateRange: DateRange) => {
    this.setState({ dateRange });
  };
  onComparedDateRangeChange = (comparedDateRange: DateRange) => {
    this.setState({ comparedDateRange });
  };

  onApplyPress = () => {
    const { onConfirm, navigation } = this.props;
    const { dateRange, comparedDateRange, comparisonTimespan } = this.state;
    if (!comparisonTimespan) {
      onConfirm(dateRange);
    } else {
      onConfirm(dateRange, comparedDateRange, comparisonTimespan);
    }
    navigation.goBack();
  };

  onCancelPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
  };

  makeDatesValid = (comparisonTimespan?: DefaultTimespansKeys) => {
    const { dateRange, comparedDateRange } = this.state;
    if (!comparisonTimespan) {
      this.setState({ comparisonTimespan });
      return;
    }
    const newDateRange = makeDateRangeValid(dateRange, comparisonTimespan);

    if (!comparedDateRange.startDate || !comparedDateRange.endDate) {
      comparedDateRange.startDate = getStartOfYesterdayAsDate();
      comparedDateRange.endDate = getEndOfYesterdayAsDate();
    }
    const newComparedDateRange = makeDateRangeValid(comparedDateRange, comparisonTimespan);
    this.setState({
      dateRange: newDateRange,
      comparedDateRange: newComparedDateRange,
      comparisonTimespan,
    });
  };

  onSegmentPress = (segmentIdx: number) => {
    const timespanKeys = Object.keys(defaultTimespans);
    const comparisonTimespan = timespanKeys[segmentIdx] as DefaultTimespansKeys;
    this.makeDatesValid(comparisonTimespan);
  };

  onComparisonSwitchToggle = (enabled: boolean) => {
    const comparisonTimespan = enabled ? ("DAY" as DefaultTimespansKeys) : undefined;
    this.makeDatesValid(comparisonTimespan);
  };

  renderComparisonIntervalSegmentedControl = () => {
    const { comparisonTimespan } = this.state;
    if (!comparisonTimespan) {
      return null;
    }
    const timespanKeys = Object.keys(defaultTimespans);
    const selectedIndex = timespanKeys.findIndex((key) => key === comparisonTimespan);
    return (
      <View style={styles.segmentedControlContainer} testID="ComparisonIntervalSegmentedControl">
        <MkiSegmentedControl
          values={timespanKeys.map((key) =>
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            capitalizeFirstLetter(I18n.t(defaultTimespans[key].label)),
          )}
          onTabPress={this.onSegmentPress}
          selectedIndex={selectedIndex}
        />
      </View>
    );
  };

  renderStartEndDatePickers = () => {
    const { dateRange, comparisonTimespan } = this.state;
    const compareLabel = comparisonTimespan ? (
      <MkiText screenStyles={styles.comparisonText}>
        {I18n.t("LOCATION_ANALYTICS.CUSTOM_DATE.COMPARE")}
      </MkiText>
    ) : null;
    return (
      <View>
        {compareLabel}
        <MkiDateRangePicker
          dateRange={dateRange}
          onDateRangeChange={this.onDateRangeChange}
          comparisonTimespan={comparisonTimespan}
          testID="StartEndDatePickers"
        />
      </View>
    );
  };

  renderComparedStartEndDatePickers = () => {
    const { comparedDateRange, comparisonTimespan } = this.state;
    if (!comparisonTimespan) {
      return null;
    }
    return (
      <View>
        <MkiText screenStyles={styles.comparisonText}>
          {I18n.t("LOCATION_ANALYTICS.CUSTOM_DATE.TO")}
        </MkiText>
        <MkiDateRangePicker
          dateRange={comparedDateRange}
          onDateRangeChange={this.onComparedDateRangeChange}
          comparisonTimespan={comparisonTimespan}
          testID="ComparedStartEndDatePickers"
        />
      </View>
    );
  };

  renderActionButtons = () => {
    return (
      <View style={styles.buttonRowContainer}>
        <RoundedButton
          buttonType={ButtonType.secondary}
          onPress={this.onCancelPress}
          containerStyles={styles.leftContainer}
          testID="CancelButton"
        >
          {I18n.t("CANCEL")}
        </RoundedButton>
        <RoundedButton
          buttonType={ButtonType.primary}
          onPress={this.onApplyPress}
          containerStyles={styles.rightContainer}
          screenStyles={styles.applyButton}
          disabled={!timeRangeIsValidAndUpdated(this.props, this.state)}
          testID="ApplyButton"
        >
          {I18n.t("LOCATION_ANALYTICS.CUSTOM_DATE.APPLY_BUTTON")}
        </RoundedButton>
      </View>
    );
  };

  render() {
    return (
      <FullScreenContainerView withScroll>
        {this.renderComparisonIntervalSegmentedControl()}
        {this.renderStartEndDatePickers()}
        {this.renderComparedStartEndDatePickers()}
        {this.renderActionButtons()}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  buttonRowContainer: {
    flexDirection: "row",
    marginHorizontal: SPACING.default,
  },
  segmentedControlContainer: {
    marginHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
    marginBottom: SPACING.default,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.bottomBorderColor,
  },
  leftContainer: {
    flex: 1,
    marginRight: SPACING.small,
  },
  rightContainer: {
    flex: 1,
    marginLeft: SPACING.small,
  },
  applyButton: {
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
    borderColor: MkiColors.primaryButton,
    borderWidth: BUTTON_SIZING.borderWidth,
  },
  comparisonText: {
    marginLeft: SPACING.default,
    color: MkiColors.goPurple,
  },
});

export default LocationAnalyticsDateScreen;
