import {
  UMBRELLA_PROTECTION_GET_FAILURE,
  UMBRELLA_PROTECTION_GET_REQUEST,
  UMBRELLA_PROTECTION_GET_SUCCESS,
  UMBRELLA_PROTECTION_SET_SUCCESS,
} from "@meraki/shared/redux";

import { CALL_API } from "~/middleware/api";
import { currentNetworkState } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

let newProtection: any;
export function setUmbrellaProtection(
  policyId: number | null,
  excludedDomains: string[] = [],
): AppThunk {
  return (dispatch) => {
    const requestBody = (newProtection = {
      excludedDomains,
      umbrellaPolicyId: policyId,
      umbrellaProtectionEnabled: true,
    });

    return dispatch({
      type: UMBRELLA_PROTECTION_SET_SUCCESS,
      response: { id: "123" },
      meta: requestBody,
    });
  };
}

export function fetchUmbrellaProtection(): AppThunk<void | Promise<any>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (newProtection) {
      dispatch({
        type: UMBRELLA_PROTECTION_GET_SUCCESS,
        response: newProtection,
      });
      return;
    }

    return dispatch({
      [CALL_API]: {
        types: [
          UMBRELLA_PROTECTION_GET_REQUEST,
          UMBRELLA_PROTECTION_GET_SUCCESS,
          UMBRELLA_PROTECTION_GET_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/umbrella/protection`,
        config: {
          method: Method.get,
        },
      },
    });
  };
}
