import z from "zod";

export const PowerException = z.object({
  serial: z.string(),
  powerType: z.string(),
});

export const SwitchSettingsSchema = z
  .object({
    vlan: z.number(),
    useCombinedPower: z.boolean(),
    powerExceptions: z.array(PowerException),
  })
  .describe("SwitchSettingsSchema");

export type SwitchSettings = z.infer<typeof SwitchSettingsSchema>;
