import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Informational({ width, height }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 340 300" width={width} height={height}>
      <Rect
        fill={theme.color.info.icon.weak.base}
        height="160"
        opacity=".4"
        rx="18"
        transform="rotate(25 91.62 109)"
        width="36"
        x="91.619"
        y="109"
      />
      <Rect
        fill={theme.color.info.icon.weak.base}
        height="120"
        opacity=".2"
        rx="18"
        transform="rotate(25 82.714 4)"
        width="36"
        x="82.714"
        y="4"
      />
      <Rect
        fill={theme.color.info.icon.weak.base}
        height="64.254"
        opacity=".3"
        rx="18"
        transform="rotate(25 272.155 160)"
        width="36"
        x="272.155"
        y="160"
      />
      <Path
        d="M85.667 129.666c0-28.718 23.281-52 52-52h60.667c28.718 0 52 23.282 52 52v60.667c0 28.719-23.282 52-52 52h-60.667c-28.719 0-52-23.281-52-52v-60.667Z"
        fill={theme.color.info.icon.base}
      />
      <Path
        d="M159.334 196.833V160a8.667 8.667 0 1 1 17.333 0v36.833a8.667 8.667 0 1 1-17.333 0Z"
        fill={theme.color.info.iconIn.base}
      />
      <Circle cx="168" cy="127.5" fill={theme.color.info.iconIn.base} r="10.833" />
    </Svg>
  );
}
