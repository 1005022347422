import Svg, { Path, Rect } from "react-native-svg";

export function ArrowBackIcon() {
  return (
    <Svg width="44" height="44" viewBox="0 0 44 44" fill="none">
      <Rect width="44" height="44" rx="22" fill="black" opacity="0.7" />
      <Path
        d="M33 22H11"
        stroke="#EEEEEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20 13L11 22L20 31"
        stroke="#EEEEEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default ArrowBackIcon;
