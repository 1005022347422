import { ProductType } from "@meraki/shared/api";

import { PushSubscriptions } from "~/api/schemas/PushSubscriptions";
import { VideoMode } from "~/enterprise/types/Cameras";
import { ClientTab } from "~/enterprise/types/ClientTypes";
import { analytics } from "~/lib/FirebaseModules";

import { AppLinkSource } from "../navigation/Linking";

type DeviceCardStatus = "all" | "red (offline)" | "yellow (alerting)" | "green (online)";
type WirelessHubOverviewInteraction<T> = "whitespace" | "see_all" | T;
type PickerInteraction<T> = { interaction: "onOpen" | "onChange"; value?: T };

/**
 * To add an analytics event add the name of the event as a key to this type
 * and its parameters as the value. The params value should be undefined if
 * the event takes no params.
 */
type EventsToParams = {
  app_deeplink_redirect: { source: AppLinkSource };
  mv_wirelessonboard_endState: undefined;
  mv_wirelessonboard_error: undefined;
  mv_wirelessonboard_start: undefined;
  mv_wirelessonboard_start_continue: undefined;
  mv_wirelessonboard_start_reset: undefined;
  mv_wirelessonboard_start_commit: undefined;
  mv_wirelessonboard_profileedit_open: undefined;
  mv_wirelessonboard_profileedit_apply: undefined;
  mv_playback_speed: { rate: number };
  mv_stream_zoomin: { zoom_level: number };
  mv_stream_zoomout: { zoom_level: number };
  mv_stream_pan: undefined;
  mv_toggle_home_to_list_view: undefined;
  mv_toggle_home_to_grid_view: undefined;
  mv_export_start: { videoMode: VideoMode };
  mv_export_commit: { mki_export_duration: string };
  mv_export_share: undefined;
  context_help: { context: string };
  mt_log_snapshot_redirect: undefined;
  mv_timeline_touchend: undefined;
  mv_timeline_thumbnail_pressed: undefined;
  mv_motion_select: undefined;
  mv_stream_toggle_dewarp_on: undefined;
  mv_stream_toggle_dewarp_off: undefined;
  saml_sso_succeess: undefined;
  saml_sso_failure: undefined;
  mv_motion_filter_roi_adjusted: undefined;
  mv_motion_filter_apply: undefined;
  mv_stream_filter_time_apply: undefined;
  mv_stream_filter_time: undefined;
  app_alert_push_network_toggle_on: { alertName: string };
  app_alert_push_network_toggle_off: { alertName: string };
  app_alert_push_profile_toggle_on: { alertName: string };
  app_alert_push_profile_toggle_off: { alertName: string };
  app_alert_push_motion_alert_toggle_on: { nodeId: string };
  app_alert_push_motion_alert_toggle_off: { nodeId: string };
  app_alert_network_enabled_toggle_on: { alertName: string };
  app_alert_network_enabled_toggle_off: { alertName: string };
  list_filter: { filterCategory: string; filterValue: string };
  list_sort: { sortOption: string; sortDirection: string };
  mv_cameras_empty_redirect: undefined;
  mv_motion_search_api_queried: undefined;
  mv_motion_filter_people_select: undefined;
  mv_videowall_fullscreen_initiated: undefined;
  mv_videowall_fullscreen_dismissed: undefined;
  mv_videowall_thumbnail_select: undefined;
  mv_videowall_empty_redirect: undefined;
  app_received_push_notification: { event_type: string };
  mv_stream_go_live: undefined;
  mv_stream_play: undefined;
  mv_stream_pause: undefined;
  mv_stream_fast_forward10s: undefined;
  mv_stream_rewind10s: undefined;
  historical_video_viewed: { time_gap: number };
  mv_stream_reload_pressed: undefined;
  home_shortcut_selected: {
    device_category: ProductType | "uplinks";
    status: DeviceCardStatus;
  };
  home_card_see_all_clients: { high_usage: boolean };
  home_card_see_all_sm_devices: undefined;
  home_card_press_wireless_health: undefined;
  home_card_see_all_devices: { status: DeviceCardStatus };
  wireless_product_hub_screen: { device_type_shortcut: boolean };
  wireless_product_hub_wireless_overview: {
    status: WirelessHubOverviewInteraction<DeviceCardStatus>;
    combinedNetwork: boolean;
    goodStatus: boolean;
  };
  wireless_product_hub_ssids_overview: {
    interaction: WirelessHubOverviewInteraction<1 | 2 | 3>;
    combinedNetwork: boolean;
    goodStatus: boolean;
  };
  wireless_product_hub_timepicker: PickerInteraction<number>;
  wireless_product_hub_ssid_picker: PickerInteraction<string>;
  wireless_product_hub_top_app_usage: {
    interaction: WirelessHubOverviewInteraction<"legend" | "graph">;
    combinedNetwork: boolean;
    goodStatus: boolean;
  };
  wireless_product_hub_assoc_stages: { combinedNetwork: boolean; goodStatus: boolean };
  home_card_see_application_usage: { clicked_pie_chart: boolean };
  home_card_see_vpns: undefined;
  invite_administrator_screen: undefined;
  view_administrator_screen: undefined;
  edit_administrator_screen: undefined;
  edit_admin_fail: undefined;
  edit_admin_success: undefined;
  add_admin_fail: undefined;
  add_admin_success: undefined;
  delete_admin_fail: undefined;
  delete_admin_success: undefined;
  administrator_list_screen_from_invite: undefined;
  administrator_list_screen_from_edit: undefined;
  edit_selected_networks: undefined;
  add_network_permission_modal: undefined;
  select_networks_screen: undefined;
  home_card_see_ssids: { view: "all" | "single SSID" };
  mt_elekid_power_on: undefined;
  mt_elekid_power_off: undefined;
  mt_elekid_power_cycle: undefined;
  mt_connect: undefined;
  mv_motion_search_initiated: undefined;
  mv_stream_toggle_mute: undefined;
  mv_stream_toggle_unmute: undefined;
  client_list_select_client: { page_version: "old_page" | "new_page" };
  client_list_refresh_page: { page_version: "old_page" | "new_page" };
  client_list_search_page: { page_version: "old_page" | "new_page" };
  client_list_switch_tab: { tab: ClientTab };
  pressed_bottom_tab: { tab: string };
  update_alert_threshold: { settingKey: keyof PushSubscriptions };
  left_drawer: { drawerOpen: boolean };
  left_drawer_change_organization: undefined;
  left_drawer_change_network: undefined;
  view_signup_form: undefined;
  signup: undefined;
  resend_otp_code: { src: "signup" | "login" };
  verify_otp_code: { src: "signup" | "login" };
  resend_email_verification: undefined;
};

export type LogEventFunction = <E extends keyof EventsToParams>(
  event: E,
  ...params: EventsToParams[E] extends undefined ? [] : [EventsToParams[E]]
) => Promise<void>;

/**
 * This will hydrate analytics events with generic parameters from the redux store
 * @returns analytics.logEvent's promise
 */
const logEvent: LogEventFunction = (event, params?) => {
  return analytics.logEvent(event, {
    ...(params ? params : {}),
  });
};

export default logEvent;
