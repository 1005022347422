import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Sensor({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Circle cx="12" cy="12" r="2.25" stroke={color1} strokeWidth="1.5" />
      <Path
        d="M12 19C8.13401 19 5 15.866 5 12"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M12 5C15.866 5 19 8.13401 19 12"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M12 22C6.47715 22 2 17.5228 2 12"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M12 2C17.5228 2 22 6.47715 22 12"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
}
