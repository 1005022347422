import Svg, { G, Rect } from "react-native-svg";
const HAMBURGER_SIZE = 24;
import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

const HamburgerIcon = () => {
  const color = themeColors(useTheme()).text.heading.color;

  return (
    <Svg width={HAMBURGER_SIZE} height={HAMBURGER_SIZE} viewBox="0 0 24 24">
      <G id="icon-/-hamburger" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="icon-/-hamburger">
          <Rect id="bounding-box" x="0" y="0" width="24" height="24"></Rect>
        </G>
        <Rect id="Rectangle" fill={color} x="0" y="4.5" width="17" height="2.5" rx="1.25"></Rect>
        <Rect
          id="Rectangle-Copy"
          fill={color}
          x="0"
          y="17.5"
          width="17"
          height="2.5"
          rx="1.25"
        ></Rect>
        <Rect
          id="Rectangle-Copy-2"
          fill={color}
          x="0"
          y="11"
          width="13"
          height="2.5"
          rx="1.25"
        ></Rect>
      </G>
    </Svg>
  );
};

export default HamburgerIcon;
