import { countBy, get, isEmpty } from "lodash";
import { createSelector } from "reselect";

import {
  FAILED_WIRELESS_CLIENTS_SEARCH_FIELDS,
  FAILED_WIRELESS_CLIENTS_SEARCH_KEY,
} from "~/constants/SearchKeys";
import { clientName } from "~/lib/ClientUtils";
import { enterpriseDeviceName } from "~/lib/DeviceUtils";
import { getMaxValueEntryKey, key } from "~/lib/EntryUtils";
import { filterData } from "~/lib/SearchUtils";
import { getSSIDNameByNodeGroupId } from "~/lib/SSIDUtils";
import { getFullStageName, initialState } from "~/lib/WirelessHealthUtils";
import { clientsByMac } from "~/selectors/clients";
import { clientsState, currentNetworkState, devicesState } from "~/selectors/getters";
import { ssidsForCurrentNetwork } from "~/selectors/mkiconf";
import { searchTextState } from "~/selectors/search";
import Device, { DevicesBySerial } from "~/shared/types/Device";
import { SSIDsByNumber } from "~/shared/types/Models";
import { NetworkNodeContainer } from "~/shared/types/Node";
import { RootState } from "~/shared/types/Redux";
import {
  ChannelUtilizationStats,
  ConnectionStats,
  FailedConnection,
  LatencyStats,
  Traffic,
  WirelessHealthContainerKeys,
  WirelessHealthPath,
  WirelessHealthReduxState,
} from "~/shared/types/WirelessHealth";

export const KEY_SUCCESS = "success";

export interface WirelessHealthSelectorProps {
  networkId: string;
  serial?: string;
  clientMac?: string;
  devicesBySerial?: DevicesBySerial;
  ssidsByNumber?: SSIDsByNumber;
}

// Helpers
const getStats = (
  data: any,
  props: WirelessHealthSelectorProps,
  path?: WirelessHealthContainerKeys | string[],
) => {
  if (!props) {
    return undefined;
  }
  const { networkId, clientMac, serial } = props;

  if (path === undefined) {
    if (serial !== undefined) {
      path = [WirelessHealthContainerKeys.nodes, serial];
    } else if (clientMac !== undefined) {
      path = [WirelessHealthContainerKeys.clients, clientMac];
    } else {
      path = WirelessHealthContainerKeys.summary;
    }
  }

  return get(data, [networkId].concat(path));
};

const propsSelector = (_: WirelessHealthReduxState, props: WirelessHealthSelectorProps) => props;

// Connection Stats
const getConnectionStatsContainer = (
  state: WirelessHealthReduxState = initialState,
): NetworkNodeContainer<ConnectionStats> => get(state, WirelessHealthPath.connectionStats);

const getConnectionStatsSelector = createSelector(
  getConnectionStatsContainer,
  propsSelector,
  (
    container: NetworkNodeContainer<ConnectionStats>,
    props: WirelessHealthSelectorProps,
  ): ConnectionStats => getStats(container, props),
);

const getConnectionStatsFailureRatio = (stat: ConnectionStats) => {
  const successes = get(stat, KEY_SUCCESS);
  if (successes === undefined) {
    return undefined;
  }

  const totalConnections = Object.values(stat).reduce((sum, value) => sum + value);
  const errors = totalConnections - successes;

  return errors / totalConnections;
};

export const getFailedConnectionsRatioSelector = createSelector(
  getConnectionStatsSelector,
  (connectionStats: ConnectionStats) => getConnectionStatsFailureRatio(connectionStats),
);

export const getSuccessConnectionsRatioSelector = createSelector(
  getFailedConnectionsRatioSelector,
  (failedConnectionsRatio?: number) => {
    if (failedConnectionsRatio === undefined) {
      return undefined;
    }
    return 1 - failedConnectionsRatio;
  },
);

export const getMostCommonFailureStepSelector = createSelector(
  getConnectionStatsSelector,
  (connectionStats: ConnectionStats) => {
    if (isEmpty(connectionStats)) {
      return undefined;
    }

    const entries = Object.entries(connectionStats).filter((entry) => entry[key] !== KEY_SUCCESS);
    //@ts-ignore
    return getFullStageName(getMaxValueEntryKey(entries)!);
  },
);

// Latency Stats
const getLatencyStatsContainer = (
  state: WirelessHealthReduxState = initialState,
): NetworkNodeContainer<LatencyStats> => get(state, WirelessHealthPath.latencyStats);

export const getLatencyStatsSelector = createSelector(
  getLatencyStatsContainer,
  propsSelector,
  (
    container: NetworkNodeContainer<LatencyStats>,
    props: WirelessHealthSelectorProps,
  ): LatencyStats => getStats(container, props),
);

export const getAveragePacketLatencySelector = createSelector(
  getLatencyStatsSelector,
  (latencyStats: LatencyStats) => {
    if (isEmpty(latencyStats)) {
      return undefined;
    }

    let avgSum = 0;
    let denom = 0;

    Object.values(latencyStats).forEach((cur: Traffic) => {
      const { avg } = cur;

      if (avg > 0) {
        avgSum += cur.avg;
        denom++;
      }
    });

    if (avgSum === 0 || denom === 0) {
      return undefined;
    }
    return Math.round(avgSum / denom);
  },
);

// Failed Connections
const getFailedConnectionsContainer = (
  state: WirelessHealthReduxState = initialState,
): NetworkNodeContainer<FailedConnection> => get(state, WirelessHealthPath.failedConnections);

export const getFailedConnectionsSelector = createSelector(
  getFailedConnectionsContainer,
  propsSelector,
  (
    container: NetworkNodeContainer<FailedConnection>,
    props: WirelessHealthSelectorProps,
  ): FailedConnection[] => getStats(container, props, WirelessHealthContainerKeys.raw),
);

const filterFailedConnection = (
  failedConnections: FailedConnection[],
  key: string,
  value: string,
) =>
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  failedConnections.filter((failedConnection: FailedConnection) => failedConnection[key] === value);

const filterFailedConnectionByClientMacOrSerial = (
  failedConnections: FailedConnection[],
  clientMac?: string,
  serial?: string,
) => {
  if (clientMac !== undefined) {
    return filterFailedConnection(failedConnections, "clientMac", clientMac);
  } else if (serial !== undefined) {
    return filterFailedConnection(failedConnections, "serial", serial);
  }

  return failedConnections;
};

// Most problematic SSID
export const getMostProblematicSSIDSelector = createSelector(
  getFailedConnectionsSelector,
  propsSelector,
  (failedConnections: FailedConnection[], props: WirelessHealthSelectorProps) => {
    if (!props || isEmpty(props)) {
      return undefined;
    }
    const { ssidsByNumber, clientMac, serial } = props;

    if (isEmpty(failedConnections)) {
      return undefined;
    }

    const filteredFailedConnections = filterFailedConnectionByClientMacOrSerial(
      failedConnections,
      clientMac,
      serial,
    );
    if (isEmpty(filteredFailedConnections)) {
      return undefined;
    }

    const ssidCounts = countBy(filteredFailedConnections, "ssidNumber");
    const mostProblematicSSIDKey = getMaxValueEntryKey(Object.entries(ssidCounts));

    if (mostProblematicSSIDKey === undefined) {
      return undefined;
    }

    return getSSIDNameByNodeGroupId(ssidsByNumber, mostProblematicSSIDKey);
  },
);

// Most problematic AP
export const getMostProblematicAPSelector = createSelector(
  getConnectionStatsContainer,
  getFailedConnectionsSelector,
  propsSelector,
  (
    container: NetworkNodeContainer<ConnectionStats>,
    failedConnections: FailedConnection[],
    props: WirelessHealthSelectorProps,
  ) => {
    if (!props || isEmpty(props)) {
      return undefined;
    }
    const { devicesBySerial, serial, clientMac } = props;
    if (serial !== undefined) {
      return undefined;
    }
    if (isEmpty(devicesBySerial)) {
      return undefined;
    }

    let problematicApEntries;
    if (clientMac !== undefined) {
      if (isEmpty(failedConnections)) {
        return undefined;
      }

      const serialCounts = countBy(
        filterFailedConnectionByClientMacOrSerial(failedConnections, clientMac),
        "serial",
      );
      if (isEmpty(serialCounts)) {
        return undefined;
      }

      problematicApEntries = Object.entries(serialCounts);
    } else {
      const nodes = getStats(container, props, WirelessHealthContainerKeys.nodes);
      if (isEmpty(nodes)) {
        return undefined;
      }

      problematicApEntries = Object.entries(nodes).map((entry) => {
        const [entrySerial, stats] = entry;
        return [entrySerial, getConnectionStatsFailureRatio(stats as ConnectionStats) || 0];
      });
    }

    // @ts-expect-error TS(2345): Argument of type '[string, unknown][] | (string | ... Remove this comment to see the full error message
    const mostProblematicApSerial = getMaxValueEntryKey(problematicApEntries)!;
    return get(devicesBySerial, mostProblematicApSerial);
  },
);

export const getMostProblematicAPIdentifierSelector = createSelector(
  getMostProblematicAPSelector,
  (device?: Device) => {
    if (!device || isEmpty(device)) {
      return undefined;
    }
    return enterpriseDeviceName(device);
  },
);

// Wireless Health - Selector Helpers
const wirelessHealthPropsSelector = (_: RootState, props: any) => props;

const createWirelessHealthSelector: any = (selectorFunction: any) => {
  return createSelector(
    (state) => state.wirelessHealth,
    currentNetworkState,
    wirelessHealthPropsSelector,
    (wirelessHealthState, networkId, props) =>
      selectorFunction(wirelessHealthState, { networkId, ...props }),
  );
};

const getChannelUtilizationContainer = (
  state: WirelessHealthReduxState = initialState,
  props: any,
): NetworkNodeContainer<ChannelUtilizationStats[]> =>
  //@ts-ignore
  get(state, WirelessHealthPath.channelUtilization)?.[props.networkId]?.summary || [];

export const getChannelUtilizationStats: (state: any) => ChannelUtilizationStats[] =
  createWirelessHealthSelector(getChannelUtilizationContainer);

// Wireless Health - Connection Stats
export const getConnectionStats = createWirelessHealthSelector(getConnectionStatsSelector);

export const getFailedConnectionsRatio = createWirelessHealthSelector(
  getFailedConnectionsRatioSelector,
);

export const getSuccessConnectionsRatio = createWirelessHealthSelector(
  getSuccessConnectionsRatioSelector,
);

export const getMostCommonFailureStep = createWirelessHealthSelector(
  getMostCommonFailureStepSelector,
);

// Wireless Health - Latency Stats
export const getLatencyStats = createWirelessHealthSelector(getLatencyStatsSelector);

export const getAveragePacketLatency = createWirelessHealthSelector(
  getAveragePacketLatencySelector,
);

// Wireless Health - FailedConnections
export const getFailedConnections = createWirelessHealthSelector(getFailedConnectionsSelector);

export const getDisplayableFailedConnections = createSelector(
  [getFailedConnections, clientsState, clientsByMac, ssidsForCurrentNetwork, devicesState],
  (failedConnections: any, clients, clientMacMap, ssidsByNumber, devicesBySerial) => {
    if (!failedConnections || !clients || !clientMacMap || !ssidsByNumber || !devicesBySerial) {
      return undefined;
    }

    return Object.values(failedConnections).map((failedConnection: any) => {
      const { clientMac, failureStep, serial, ssidNumber } = failedConnection;

      const client = clientMacMap[clientMac];
      const device = devicesBySerial[serial] || { mac: "unknown" };

      const { mac: apMac } = device;

      const nameOfClient = client ? clientName(client) : clientMac;

      return {
        ...failedConnection,
        apName: enterpriseDeviceName(device),
        apMac,
        clientName: enterpriseDeviceName({ name: nameOfClient, mac: clientMac }),
        failureStep: getFullStageName(failureStep),
        ssidName: getSSIDNameByNodeGroupId(ssidsByNumber, ssidNumber),
      };
    });
  },
);

export const getFilteredDisplayableFailedConnections = createSelector(
  getDisplayableFailedConnections,
  searchTextState,
  (displayableFailedConnections = [], searchText) =>
    filterData(
      displayableFailedConnections,
      FAILED_WIRELESS_CLIENTS_SEARCH_FIELDS,
      searchText(FAILED_WIRELESS_CLIENTS_SEARCH_KEY),
    ),
);

export const getMostProblematicSSIDHelper = createSelector(
  (state) => state.wirelessHealth,
  currentNetworkState,
  ssidsForCurrentNetwork,
  wirelessHealthPropsSelector,
  (wirelessHealthState, networkId, ssidsByNumber, props) =>
    getMostProblematicSSIDSelector(wirelessHealthState, {
      networkId,
      ssidsByNumber,
      ...props,
    }),
);

export const getMostProblematicSSID = createWirelessHealthSelector(getMostProblematicSSIDHelper);

export const getMostProblematicAPHelper = createSelector(
  (state) => state.wirelessHealth,
  currentNetworkState,
  devicesState,
  wirelessHealthPropsSelector,
  (wirelessHealthState, networkId, devicesBySerial, props) =>
    getMostProblematicAPSelector(wirelessHealthState, {
      networkId,
      devicesBySerial,
      ...props,
    }),
);

export const getMostProblematicAP = createWirelessHealthSelector(getMostProblematicAPHelper);

export const getMostProblematicAPIdentifier = createSelector(
  (state) => state.wirelessHealth,
  currentNetworkState,
  devicesState,
  wirelessHealthPropsSelector,
  (wirelessHealthState, networkId, devicesBySerial, props) =>
    getMostProblematicAPIdentifierSelector(wirelessHealthState, {
      networkId,
      devicesBySerial,
      ...props,
    }),
);
