import { analyticsLog, setupAnalytics } from "./analytics";
import { firebase } from "./firebase";
import { setupPerformance } from "./performance";
import { setupRemoteConfig } from "./remoteConfig";

const analytics = setupAnalytics(firebase);
const performance = setupPerformance(firebase);
const remoteConfig = setupRemoteConfig(firebase);

export { analytics, analyticsLog, firebase, performance, remoteConfig };
