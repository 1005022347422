import { FLOORPLANS_FAILURE, FLOORPLANS_REQUEST, FLOORPLANS_SUCCESS } from "@meraki/shared/redux";

import { ApiAction, CALL_API } from "~/middleware/api";
import { Method } from "~/shared/types/RequestTypes";

export function getFloorPlans(networkId: string): ApiAction {
  return {
    [CALL_API]: {
      types: [FLOORPLANS_REQUEST, FLOORPLANS_SUCCESS, FLOORPLANS_FAILURE],
      endpoint: `/api/v1/networks/${networkId}/floorPlans`,
      config: {
        method: Method.get,
      },
      meta: { networkId },
    },
  };
}
