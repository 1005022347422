import {
  ADMINS_SUCCESS,
  FETCH_USER_DATA_SUCCESS,
  GET_NETWORK_ACCESS_ROLES_SUCCESS,
  SET_SSO_ACCESS_ORG,
  SET_USER_DATA,
  USER_DATA_PROFILE_SETTINGS_GET_SUCCESS,
  USER_DATA_TEMPERATURE_UNIT_PREFERENCE_TOGGLED,
  USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
  USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE,
  USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { set } from "lodash/fp";
import { AnyAction } from "redux";

import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import { NetworkAccessRoles } from "~/api/schemas/NetworkAccessRoles";
import { TemperatureUnits } from "~/shared/constants/SensorMetrics";
import { AdminInfo, OrgAccess } from "~/shared/types/AdminTypes";
import { WipeReduxAction } from "~/shared/types/Redux";
import { UserData } from "~/shared/types/UserData";

interface SetClientDeviceTemperatureUnitPreference extends AnyAction {
  type: typeof USER_DATA_TEMPERATURE_UNIT_PREFERENCE_TOGGLED;
  temperatureUnit: TemperatureUnits;
}

interface UserDataProfileSettingsGetSuccessAction extends AnyAction {
  type: typeof USER_DATA_PROFILE_SETTINGS_GET_SUCCESS;
  response: {
    temperature_unit: TemperatureUnits;
  };
}

export interface UserToggledTappableChartsAlphaFeatureAction extends AnyAction {
  type: "USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE";
  payload: { tappableCharts: boolean };
}
export interface UserToggledMagneticAlphaFeature extends AnyAction {
  type: "USER_TOGGLED_MAGNETIC_ALPHA_FEATURE";
  payload: { magneticTheme: boolean };
}

export interface UserToggledClientListAlphaFeatureAction extends AnyAction {
  type: "USER_TOGGLED_CLIENT_LIST_SCREEN_ALPHA_FEATURE";
  payload: { clientListScreen: boolean };
}

export interface UserToggledThumbnailScrubbingFeatureAction extends AnyAction {
  type: "USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE";
  payload: { thumbnailScrubbing: boolean };
}

interface SetUserDataAction extends AnyAction {
  type: typeof SET_USER_DATA;
  user: UserData;
}

interface FetchAdminsAction extends AnyAction {
  type: typeof ADMINS_SUCCESS;
  response: {
    entities: {
      admins: AdminInfo;
    };
  };
}
interface FetchUserDataAction extends AnyAction {
  type: typeof FETCH_USER_DATA_SUCCESS;
  response: {
    id: string;
    name: string;
    email: string;
    organization_id: string;
    company_name: string | null;
    is_meraki_sso_admin: boolean;
  };
}
interface GetNetworkAccessRolesAction extends AnyAction {
  type: typeof GET_NETWORK_ACCESS_ROLES_SUCCESS;
  response: NetworkAccessRoles;
}

interface SetSSOAccessOrg extends AnyAction {
  type: typeof SET_SSO_ACCESS_ORG;
  organization: GoOrgSearchResult;
}

export type UserDataActions =
  | FetchAdminsAction
  | FetchUserDataAction
  | GetNetworkAccessRolesAction
  | SetClientDeviceTemperatureUnitPreference
  | SetSSOAccessOrg
  | SetUserDataAction
  | UserDataProfileSettingsGetSuccessAction
  | UserToggledTappableChartsAlphaFeatureAction
  | UserToggledMagneticAlphaFeature
  | UserToggledClientListAlphaFeatureAction
  | UserToggledThumbnailScrubbingFeatureAction
  | WipeReduxAction;

export enum TemperatureUnitSetBy {
  Api = "Api",
  ClientDevice = "ClientDevice",
}

const initialState = {};

export interface UserDataState extends Partial<UserData> {
  alphaFeatures?: {
    tappableCharts?: boolean;
    magneticTheme?: boolean;
    clientListScreen?: boolean;
    thumbnailScrubbing?: boolean;
  };
  temperatureUnitSetBy?: TemperatureUnitSetBy;
  networkAccessRoles?: NetworkAccessRoles;
  is_meraki_sso_admin?: boolean;
  ssoAccessOrg?: GoOrgSearchResult;
}

const userData = (state: UserDataState = initialState, action: UserDataActions): UserDataState => {
  switch (action.type) {
    case USER_DATA_TEMPERATURE_UNIT_PREFERENCE_TOGGLED: {
      return {
        ...state,
        temperature_unit: action.temperatureUnit,
        temperatureUnitSetBy: TemperatureUnitSetBy.ClientDevice,
      };
    }
    // Called by `setInitialEntries` in response to login success and some other api actions
    // This probably doesn't need to be it's own action since the userData reducer can include
    // cases for the actions that pass a user object to setInitialEntries.
    // https://redux.js.org/style-guide/style-guide#allow-many-reducers-to-respond-to-the-same-action
    case SET_USER_DATA: {
      // Don't merge with existing state since SET_USER_DATA occurs after login, we don't want to risk
      // retaining a different user's data in state.
      // 7/19/23 note - We can accept one alpha feature since that will be fine...
      return {
        ...action.user,
        ...(state?.alphaFeatures?.magneticTheme ? { alphaFeatures: { magneticTheme: true } } : {}),
      };
    }

    case FETCH_USER_DATA_SUCCESS: {
      return { ...state, ...action.response };
    }

    case ADMINS_SUCCESS: {
      const { currentUserEmail } = action.meta;
      const currentAdmin = Object.values(action.response.entities.admins)?.find(
        (admin) => admin.email === currentUserEmail,
      );
      if (!currentAdmin || state?.id === currentAdmin?.id) {
        return {
          ...state,
          isReadOnly: OrgAccess.read === currentAdmin?.orgAccess,
        };
      } else {
        return {
          id: currentAdmin?.id,
          isReadOnly: OrgAccess.read === currentAdmin?.orgAccess,
        };
      }
    }
    // Response comes from getUserProfileSettings action /manage/users/user_profile_settings
    case USER_DATA_PROFILE_SETTINGS_GET_SUCCESS: {
      return mergeUserData(state, action);
    }
    case USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE: {
      const { tappableCharts } = action.payload;
      return {
        ...state,
        alphaFeatures: { ...(state.alphaFeatures ?? {}), tappableCharts },
      };
    }
    case USER_TOGGLED_MAGNETIC_ALPHA_FEATURE: {
      const { magneticTheme } = action.payload;
      return {
        ...state,
        alphaFeatures: { ...(state.alphaFeatures ?? {}), magneticTheme },
      };
    }
    case USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE: {
      return set(["alphaFeatures", "thumbnailScrubbing"], action.payload.thumbnailScrubbing)(state);
    }
    case GET_NETWORK_ACCESS_ROLES_SUCCESS: {
      return { ...state, networkAccessRoles: action.response };
    }
    case SET_SSO_ACCESS_ORG: {
      return { ...state, ssoAccessOrg: action.organization };
    }
    case WIPE_REDUX: {
      return {
        ...initialState,
        ...(state?.alphaFeatures?.magneticTheme ? { alphaFeatures: { magneticTheme: true } } : {}),
      };
    }
    default: {
      return state;
    }
  }
};

function mergeUserData(
  state: UserDataState,
  action: UserDataProfileSettingsGetSuccessAction,
): UserDataState {
  // The temperature unit stored in the backend will always be used until temperature unit
  // is toggled in the mobile app.
  let nextTemperatureUnitState = {
    temperature_unit: action.response.temperature_unit,
    temperatureUnitSetBy: TemperatureUnitSetBy.Api,
  };
  // If the user the user toggled temperature unit through the mobile app, alway prefer that setting.
  if (state.temperatureUnitSetBy === TemperatureUnitSetBy.ClientDevice && state.temperature_unit) {
    nextTemperatureUnitState = {
      temperature_unit: state.temperature_unit,
      temperatureUnitSetBy: TemperatureUnitSetBy.ClientDevice,
    };
  }

  return { ...state, ...nextTemperatureUnitState };
}

export default userData;
