import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Camera({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color2 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color3 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path d="M2.5 5a1 1 0 0 1 1-1h17a1 1 0 1 1 0 2h-17a1 1 0 0 1-1-1Z" fill={color1} />
      <Path
        d="M21 13c0 5.063-4.03 9-9 9s-9-3.938-9-9V9a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4Zm-9 6.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        fill={color2}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <Circle cx="12" cy="14.5" fill={color3} r="3" />
    </Svg>
  );
}
