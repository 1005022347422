import { formatURLQueryParams } from "@meraki/shared/api";
import {
  Clusters,
  LT_REQUEST,
  RESET_UPDATE_REQUIRED,
  SET_CURRENT_SHARD_ID,
  SET_UPDATE_REQUIRED,
  SHOW_CAPTCHA,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { cloneDeep, omit } from "lodash";
import { normalize, schema } from "normalizr";
import { Action, AnyAction, Middleware } from "redux";

import { makeFullURL } from "~/api/util/url";
import { MS_IN_A_SECOND, TIMEOUT_ERROR_KEY } from "~/constants/MkiConstants";
import { TRANSACTIONS_TO_SAVE } from "~/constants/NetworkTransactions";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { LinkHeader, parseLinkHeader } from "~/lib/PaginationUtils";
import { createHeaders } from "~/lib/RequestUtils";
import { getErrorMessageForEndpoint } from "~/lib/statusCodeUtils";
import mkiFetch, { IDLE_TIMEOUT_ERROR } from "~/middleware/MkiFetch";
import {
  currentShardIdState,
  getCurrentCluster,
  isTwoFactorEnabled,
  isUpdateRequired,
  isUserReadOnly,
  userNeedsTFA,
} from "~/selectors";
import { ApiError } from "~/shared/types/Error";
import { URLPrepends } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { ApiActionConfig, Method } from "~/shared/types/RequestTypes";

// examples use Symbol('Call API'), but not all JS runtimes support this yet
export const CALL_API = "@@api/Call API";
export const SESSION_FORBIDDEN = "Session forbidden";
const PLEASE_ENABLE_TFA_RESPONSE = "Please configure SMS two-factor authentication for this user";
const REDIRECT_URL = /login\/dashboard_login/;
const LOGIN_URL = /login\/login/;
const SESSION_EXPIRED = "Session expired";
const UPDATE_APP = "Update Meraki Go App";
const CAPTCHA_REQUIRED = "Too many login attempts. Enter CAPTCHA.";
const CAPTCHA_REQUIRED_GO =
  "Too many login attempts. You need to wait 5 minutes before trying again";
const INVALID_CAPTCHA = "Incorrect CAPTCHA. Please try again.";
const UPDATE_REQUIRED_STATUS = 426;
const RATE_LIMIT_RESPONSE = 429;
const FORBIDDEN = 403;
// 30 seconds
const TIMEOUT_DURATION = 30000;
const RATE_LIMIT_WAIT_DURATION_DEFAULT = 2500;
const NUM_OF_TRANSACTIONS_TO_KEEP = 50;
// properties that need to be removed on transactions
const BLOCKED_PROPERTIES = ["psk", "headers"] as const;

type SanitizedRequest = Omit<Request, (typeof BLOCKED_PROPERTIES)[number]>;
type SanitizedResponse = Omit<Response, (typeof BLOCKED_PROPERTIES)[number]>;
type NetworkTransaction = {
  endpoint: string;
  response: SanitizedResponse;
  request: SanitizedRequest;
};
const networkTransactions: NetworkTransaction[] = [];

export const getNetworkTransactions = () => cloneDeep(networkTransactions);

// FIXME: the json property has an "any" type because we have no way of knowing what the response will
// look like, this should be parameterized as a type variable (and also potentially verified with codec)
type ParsedResponse = { json: any; response: Response; linkHeader?: LinkHeader };

function checkStatus({
  json,
  response,
  linkHeader,
}: ParsedResponse): ParsedResponse | Promise<never> {
  if (response.url && REDIRECT_URL.test(response.url)) {
    // TODO: we shouldn't force users to go through the entire login process again,
    // we should just have them login at whatever screen they're on and put them back
    // where they were
    throw new Error(SESSION_EXPIRED);
  }

  const message = json?.error || json?.errors?.[0] || json?.data?.error;
  if (message) {
    return Promise.reject({ message, response });
  }

  if (response.ok) {
    return { json, response, linkHeader };
  }

  if (response.status === FORBIDDEN) {
    throw new Error(SESSION_FORBIDDEN);
  }

  throw new Error("Invalid response");
}

function sanitizeTransaction(requestOrResponse: Request): SanitizedRequest;
function sanitizeTransaction(requestOrResponse: Response): SanitizedResponse;
function sanitizeTransaction(requestOrResponse: any): any {
  // sanitize an array response
  if (Array.isArray(requestOrResponse)) {
    return requestOrResponse.map((item) => omit(item, BLOCKED_PROPERTIES));
  }

  // saniztize the request body
  if (requestOrResponse?.body != null) {
    const bodyHash = JSON.parse(requestOrResponse.body);
    requestOrResponse = {
      ...requestOrResponse,
      body: omit(bodyHash, BLOCKED_PROPERTIES),
    };
  }

  return omit(requestOrResponse, BLOCKED_PROPERTIES);
}

// exporting for testing
export function addNetworkTransaction(endpoint: any, request: any, response: Response) {
  networkTransactions.push({
    endpoint,
    request: sanitizeTransaction(request),
    response: sanitizeTransaction(response),
  });

  if (networkTransactions.length > NUM_OF_TRANSACTIONS_TO_KEEP) {
    networkTransactions.shift();
  }
}

const HTTP_CHECK = "http";

interface CallApiArgs {
  endpoint: string;
  config: ApiActionConfig;
  prepend?: URLPrepends;
  schema?:
    | schema.Entity[]
    | schema.Entity
    | Record<string, schema.Values>
    | Record<string, schema.Entity[]>;
  callback?: (json: any) => void;
  shardId?: number | string;
  cluster: Clusters;
}

function callApi({ endpoint, config, prepend, schema, callback, shardId, cluster }: CallApiArgs) {
  const { queryParams, unencodedQueryParams, enableTimeout } = config;

  let fullUrl = endpoint.startsWith(HTTP_CHECK)
    ? endpoint
    : makeFullURL({ prepend, endpoint, shardId, cluster });

  const headers = createHeaders(config);

  if (queryParams) {
    fullUrl += `?${formatURLQueryParams(queryParams, undefined)}`;
  }

  if (unencodedQueryParams) {
    const options = { unencoded: true };
    const join = queryParams ? "&" : "?";
    fullUrl += `${join}${formatURLQueryParams(unencodedQueryParams, undefined, options)}`;
  }

  // Only set timeout for login user request
  // TODO: Set timeouts for other endpoints
  const timeout = LOGIN_URL.test(fullUrl) || enableTimeout ? TIMEOUT_DURATION : undefined;

  // TODO: UDG-1563 - we're currently adding additional parsing here to handle the request made
  // for update_splash_image. We should make an api endpoint that returns properly formed data.
  let parsingFunction: (response: Response) => Promise<ParsedResponse>;
  if (config.textResponse || config?.headers?.Accept === "text/html") {
    parsingFunction = (response) =>
      response
        .text()
        .then((responseText) => {
          const result = { result: responseText };
          return { json: result, response };
        })
        .catch(() => ({ json: {}, response }));
  } else if (config.jsonPResponse) {
    parsingFunction = (response) => parseJsonP(response);
  } else {
    parsingFunction = (response) => {
      const linkHeader = parseLinkHeader(response.headers);

      return response
        .json()
        .then((json) => {
          return { json, response, linkHeader };
        })
        .catch(() => ({ json: {}, response }));
    };
  }

  return timeoutPromise(
    mkiFetch(fullUrl, { credentials: "same-origin", ...config, headers })
      .then((response) => parsingFunction(response))
      .then(checkStatus)
      .then(({ json, linkHeader }) => {
        let toReturn = json;

        if (callback !== undefined) {
          toReturn = callback(json);
        }

        if (schema === undefined) {
          return {
            linkHeader,
            response: toReturn,
          };
        }

        return {
          linkHeader,
          response: normalize(toReturn, schema),
        };
      })
      .catch((error) => {
        if (error instanceof Error && error.message === IDLE_TIMEOUT_ERROR) {
          return;
        }
        throw error;
      }),
    timeout,
    new Error(TIMEOUT_ERROR_KEY),
  );
}

const parseJsonP = (response: Response) =>
  response
    .text()
    .then((text) => {
      // Remove JsonP parentheses
      const noParenthesis = text.slice(1, text.length - 1);
      const json = JSON.parse(noParenthesis);
      return { json, response };
    })
    .catch(() => ({ json: {}, response }));

function timeoutPromise(promise: any, timeout: any, error: any) {
  if (!timeout) {
    return promise;
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(error);
    }, timeout);
    promise.then(resolve, reject);
  });
}

export interface ApiAction<R = any> {
  [CALL_API]: {
    types: readonly [string, string, string];
    endpoint: string;
    config: ApiActionConfig;
    meta?: { [K in string]: any };
    prepend?: URLPrepends;
    tempShardId?: string;
    schema?:
      | schema.Entity[]
      | schema.Entity
      | Record<string, schema.Values>
      | Record<string, schema.Entity[]>;
    callback?: (json: any) => void;
    parseError?: (error: ApiError) => string;
  };
}

export type ApiResponseAction<T> = {
  type: string;
  response: T;
  meta: { [K in string]: any };
  linkHeader?: LinkHeader;
};

export interface ApiDispatch<A extends Action = AnyAction> {
  <T extends A>(action: T): T;
  <T = any>(a: ApiAction<T>): Promise<ApiResponseAction<T>>;
}

function isNotApiAction(action: ApiAction | AnyAction): action is AnyAction {
  return action[CALL_API] == null;
}

const apiMiddleware: Middleware<ApiDispatch, RootState> =
  (store) => (next) => (action: ApiAction | AnyAction) => {
    if (isNotApiAction(action)) {
      return next(action);
    }

    const callAPI = action[CALL_API];
    const {
      tempShardId,
      endpoint,
      prepend,
      types,
      config,
      schema,
      callback,
      meta = {},
      parseError,
    } = callAPI;

    if (typeof endpoint !== "string") {
      throw new Error("Specify a string endpoint URL.");
    }
    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error("Expected an array of three action types.");
    }
    if (!types.every((type) => typeof type === "string")) {
      throw new Error("Expected action types to be strings.");
    }

    const [requestType, successType, failureType] = types;
    store.dispatch({ type: requestType, meta });
    const shardId = tempShardId || currentShardIdState(store.getState());
    const cluster = getCurrentCluster(store.getState());
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const saveTransaction = TRANSACTIONS_TO_SAVE[requestType];
    const isLTRequest = requestType === LT_REQUEST;

    const retryOnRateLimit = (retryAfter: any) => {
      const RATE_LIMIT_WAIT_DURATION = retryAfter
        ? Number(retryAfter) * MS_IN_A_SECOND
        : RATE_LIMIT_WAIT_DURATION_DEFAULT;
      return new Promise((resolve) => {
        return setTimeout(() => {
          resolve(
            callApi({ endpoint, config, prepend, schema, callback, shardId, cluster }).then(
              handleCallApiSuccess,
              handleCallApiError,
            ),
          );
        }, RATE_LIMIT_WAIT_DURATION);
      });
    };

    const handleCallApiSuccess = ({ response, linkHeader = {} }: any) => {
      if (saveTransaction) {
        addNetworkTransaction(endpoint, config, response);
      }

      if (response?.shard_id != null) {
        store.dispatch({
          type: SET_CURRENT_SHARD_ID,
          shardId: response.shard_id,
        });
      }

      if (isLTRequest && response?.success == false) {
        return handleCallApiError({ response });
      }

      return store.dispatch({
        meta,
        linkHeader,
        response,
        type: successType,
      });
    };

    const handleCallApiError = (error: any) => {
      const state = store.getState();

      const errorMessage =
        getErrorMessageForEndpoint(endpoint, error) ||
        I18n.t("ENDPOINT_ERRORS.GENERIC_ERROR_MESSAGE");

      const errorStatus = error.response?.status;
      const retryAfter = error.response?.headers?.map?.["retry-after"];
      const shardId = error.response?.shard_id;
      let errorMessageToReturn = parseError ? parseError(error) : errorMessage;

      if (saveTransaction) {
        addNetworkTransaction(endpoint, config, error.response?.json);
      }

      if (shardId != null) {
        store.dispatch({
          type: SET_CURRENT_SHARD_ID,
          shardId,
        });
      }

      if (errorStatus === RATE_LIMIT_RESPONSE && config?.method === Method.get) {
        return retryOnRateLimit(retryAfter);
      }

      if (errorMessage === SESSION_EXPIRED) {
        analytics.logEvent("logout_session_expired");
        store.dispatch({ type: WIPE_REDUX });
      }

      if (
        !meta.skipTwoFactorCheck &&
        (errorMessage === SESSION_FORBIDDEN || errorMessage === PLEASE_ENABLE_TFA_RESPONSE)
      ) {
        if (userNeedsTFA(state) && !isTwoFactorEnabled(state)) {
          showAlert(I18n.t("ERROR"), I18n.t("INTRO_TWO_FACTOR.TFA_REQUIRED.ALERT"), () => {
            analytics.logEvent("logout_tfa_required");
            store.dispatch({ type: WIPE_REDUX });
          });
        } else if (isUserReadOnly(state)) {
          errorMessageToReturn = I18n.t("ADMIN.READ_ONLY.ERROR");
        }
      }

      // todo: we can remove this once the new login flow is out!
      if (
        errorMessage === CAPTCHA_REQUIRED ||
        errorMessage === CAPTCHA_REQUIRED_GO ||
        errorMessage === INVALID_CAPTCHA
      ) {
        store.dispatch({ type: SHOW_CAPTCHA });
      }

      if (isUpdateRequired(state) && errorMessage !== UPDATE_APP) {
        store.dispatch({ type: RESET_UPDATE_REQUIRED });
      } else if (errorStatus === UPDATE_REQUIRED_STATUS) {
        analytics.logEvent("logout_update_required");
        store.dispatch({ type: WIPE_REDUX });
        store.dispatch({ type: SET_UPDATE_REQUIRED });
      }

      store.dispatch({
        type: failureType,
        error: errorMessage,
        meta,
      });

      return Promise.reject(errorMessageToReturn);
    };

    return callApi({ endpoint, config, prepend, schema, callback, shardId, cluster }).then(
      handleCallApiSuccess,
      handleCallApiError,
    );
  };

export default apiMiddleware;
