import {
  GET_IPSEC_VPN_SETTINGS_SUCCESS,
  GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
  ORG_VPN_STAT_FAILURE,
  ORG_VPN_STAT_REQUEST,
  ORG_VPN_STAT_SUCCESS,
  ORG_VPN_STATUS_FAILURE,
  ORG_VPN_STATUS_REQUEST,
  ORG_VPN_STATUS_SUCCESS,
  UPDATE_IPSEC_VPN_SETTINGS_SUCCESS,
  UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
  VPN_PEER_OUTAGE_FAILURE,
  VPN_PEER_OUTAGE_SUCCESS,
  VPN_PEER_STATUS_FAILURE,
  VPN_PEER_STATUS_SUCCESS,
  VPN_USAGE_DATA_FAILURE,
  VPN_USAGE_DATA_SUCCESS,
} from "@meraki/shared/redux";
import { merge } from "lodash";
import { Reducer } from "redux";

import { EidKeyedData, Outage, OutageData, Peer, Usage, Vpn } from "~/enterprise/types/VpnTypes";
import { IpsecSettings } from "~/go/types/ClientVPN";
import { SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";

export type VpnState = {
  vpns: {
    [networkId: string]: Vpn;
  };
  peerStatus: EidKeyedData<Peer>;
  usageData: { [key: string]: Usage[] };
  outageData: EidKeyedData<OutageData>;
  ipsecSettings: { [key: string]: IpsecSettings };
  siteToSiteSettings: { [key: string]: SiteToSiteSettings };
  isFetching: boolean;
};

type Timeseries = [number, number, number, number];

export const initialState: VpnState = {
  vpns: {},
  peerStatus: {},
  usageData: {},
  outageData: {},
  ipsecSettings: {},
  siteToSiteSettings: {},
  isFetching: false,
};

let currentVpns: { [networkId: string]: Vpn } = {};
let newVpns: any = {};

const vpns: Reducer<VpnState> = (state = initialState, action) => {
  const { type, response, meta } = action;
  switch (type) {
    case ORG_VPN_STAT_REQUEST:
    case ORG_VPN_STATUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ORG_VPN_STAT_SUCCESS:
      newVpns = response.reduce((o: { [networkId: string]: Vpn }, vpn: Vpn) => {
        const { networkId, ...rest } = vpn;
        return Object.assign(o, { [networkId]: rest });
      }, {});

      currentVpns = state.vpns;

      // keep statuses in store for VPNs that were present in response call,
      // remove old VPN data
      for (const attr in currentVpns) {
        if (newVpns[attr] === undefined) {
          delete currentVpns[attr];
        }
      }

      return {
        ...state,
        isFetching: false,
        vpns: merge(newVpns, currentVpns),
      };

    case ORG_VPN_STATUS_SUCCESS:
      newVpns = response.reduce((o: { [networkId: string]: Vpn }, vpn: Vpn) => {
        const { networkId, merakiVpnPeers, ...rest } = vpn;
        const body = {
          ...rest,
          merakiVpnPeersReachability: merakiVpnPeers,
        };
        return Object.assign(o, { [networkId]: body });
      }, {});

      currentVpns = state.vpns;

      // keep vpn peers info in store for VPNs that were present in response call,
      // remove old VPN data
      for (const attr in currentVpns) {
        if (newVpns[attr] === undefined) {
          delete currentVpns[attr];
        }
      }

      return {
        ...state,
        isFetching: false,
        vpns: merge(newVpns, currentVpns),
      };
    case VPN_PEER_STATUS_SUCCESS:
      const peerStatus = response.details.peers.reduce((o: object, vpn: Peer) => {
        const { id, peer_status, avg_lat } = vpn;
        return Object.assign(o, { [id]: { peer_status, avg_lat } });
      }, {});

      return {
        ...state,
        peerStatus,
      };
    case VPN_USAGE_DATA_SUCCESS:
      const { eid, timespan } = meta;

      const formattedTimeseries = response[eid].map((data: Timeseries) => ({
        ts: data[0],
        usage: data[1],
        latency50: data[2],
        latency90: data[3],
      }));

      return {
        ...state,
        usageData: {
          [timespan]: formattedTimeseries,
          ...state.usageData,
        },
      };
    case VPN_PEER_OUTAGE_SUCCESS:
      Object.keys(response).forEach((eid) => {
        const outageList = response[eid].map((outage: [number, number]) => ({
          ts: outage[0],
          status: outage[1],
        }));

        response[eid] = outageList.sort(
          (outageA: Outage, outageB: Outage) => outageA.ts - outageB.ts,
        );
      });

      return {
        ...state,
        outageData: response,
      };
    case GET_IPSEC_VPN_SETTINGS_SUCCESS:
    case UPDATE_IPSEC_VPN_SETTINGS_SUCCESS:
      return {
        ...state,
        ipsecSettings: {
          ...state.ipsecSettings,
          [meta.networkId]: response,
        },
      };
    case GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS:
    case UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS:
      return {
        ...state,
        siteToSiteSettings: {
          ...state.siteToSiteSettings,
          [meta.networkId]: response,
        },
      };
    case ORG_VPN_STAT_FAILURE:
    case ORG_VPN_STATUS_FAILURE:
    case VPN_PEER_STATUS_FAILURE:
    case VPN_PEER_OUTAGE_FAILURE:
    case VPN_USAGE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default vpns;
