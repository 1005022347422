import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons/MerakiIcon";
import { MkiText } from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

export interface CountryIconProps {
  onPress?: () => void;
  testID?: string;
  countryPhoneCode: {
    phoneCode?: string;
    emoji?: string;
  };
}

export const CountryIcon = (props: CountryIconProps) => {
  const { onPress, testID, countryPhoneCode } = props;
  const { phoneCode, emoji } = countryPhoneCode;
  const icon = <MerakiIcon name="expand-more" onPress={onPress} style={styles.chevron} />;

  const wrappedIcon = (
    <View testID={testID}>
      <MkiText textStyle="default" screenStyles={styles.subtitle} onPress={onPress}>
        {`${emoji} ${phoneCode} `}
        {icon}
      </MkiText>
    </View>
  );

  return phoneCode && emoji ? (
    <Touchable transparentBackground testID={testID ? `${testID}.TOUCHABLE` : undefined}>
      {wrappedIcon}
    </Touchable>
  ) : null;
};

const styles = StyleSheet.create({
  subtitle: {
    marginRight: SPACING.small,
  },
  chevron: {
    marginBottom: SPACING.tiny,
  },
});

export default CountryIcon;
