import { LogBox } from "react-native";
import DeviceInfo from "react-native-device-info";

const isDetox = process.env.E2E || false;
const isNightly = DeviceInfo.getBundleId().includes("nightly") || false;

if (isDetox) {
  LogBox.ignoreAllLogs();
}

export const config = {
  useDev: false,
  useEphemeral: isDetox,
  ephemeralPrefix: process.env.MKI_USER || process.env.USER || "",
  notifyRenderDebugger: false,
  debuggerMaxAge: 30,
  mkiUser: process.env.MKI_USER || process.env.USER,
  nfoOverrides: {},
  isDevFirebaseEnabled: false,
};

export const FEATURE_FLAGS = {
  showLocationAnalyticsComparison: isNightly || isDetox,
  // Skips the actual hardware interaction part of wireless onboarding in order to "work" on simulators
  stubWirelessOnboaring: false,
};
