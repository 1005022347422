import { Method } from "../coreTypes";

type HeadersBuilderFunction = () => Record<string, string>;
let headersBuilder: HeadersBuilderFunction = () => ({});
export function setHeadersBuilder(callback: HeadersBuilderFunction) {
  headersBuilder = callback;
}

export function generateHeaders(
  method: Method,
  csrfToken?: {
    csrf_token: string;
  },
  additionalHeaders?: Record<string, string>,
) {
  const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders);
  }

  if (method === "POST") {
    headers["X-Requested-With"] = "XMLHttpRequest";
  }

  if (csrfToken) {
    headers["X-CSRF-TOKEN"] = csrfToken.csrf_token;
  }

  return { ...headers, ...(headersBuilder() || {}), ...(additionalHeaders || {}) };
}
