import { PureComponent } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LETTER_SPACING, SPACING } from "~/constants/MkiConstants";
import { PROXIMA_SOFT_FONT } from "~/constants/MkiFonts";
import I18n from "~/i18n/i18n";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import { GoStatus } from "~/shared/constants/Status";
import ListRow from "~/shared/rows/ListRow";

type Props = {
  enabled?: boolean;
  name: string;
  titleStyle?: StyleProp<ViewStyle>;
};

export default class ShareSSIDNameHeader extends PureComponent<Props> {
  render() {
    const { enabled = false, titleStyle, name } = this.props;
    const gradientIcon = {
      icon: MerakiIcon,
      name: "wifi",
      size: "l",
      hasGradient: !!enabled,
    } as const;
    const statusIcon = (
      <StatusIcon
        status={enabled ? GoStatus.good : GoStatus.offline}
        customIcon={gradientIcon}
        screenStyles={styles.connection}
      />
    );
    const shareText = (
      <MkiText testID="SSID_DETAILS.SHARE" textStyle="small" screenStyles={styles.shareText}>
        {I18n.t("SSID_DETAILS.SHARE")}
      </MkiText>
    );

    const nameText = (
      <MkiText textStyle="heading" screenStyles={titleStyle}>
        {name}
      </MkiText>
    );
    return (
      <ListRow icon={statusIcon} accessory={shareText} rowStyles={styles.rowStyles}>
        {nameText}
      </ListRow>
    );
  }
}

const styles = StyleSheet.create({
  connection: {
    marginHorizontal: SPACING.small,
  },
  rowStyles: {
    marginHorizontal: SPACING.default,
    paddingRight: SPACING.small,
  },
  shareText: {
    ...PROXIMA_SOFT_FONT,
    color: MkiColors.primaryButton,
    letterSpacing: LETTER_SPACING.allCaps,
  },
});
