import { OUTDOOR_APS } from "@meraki/shared/devices";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { getTrueModel } from "~/lib/DeviceUtils";
import MerakiIcon from "~/shared/components/icons";
import GeneralStatus, { GoStatus } from "~/shared/constants/Status";

export const PRODUCT_SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
} as const;

const DIMS = {
  small: {
    horizontal: { width: 46, height: 46 },
    vertical: { width: 46, height: 46 },
  },
  medium: {
    horizontal: { width: 68, height: 68 },
    vertical: { width: 68, height: 68 },
  },
  large: {
    horizontal: { width: 120, height: 80 },
    vertical: { width: 120, height: 120 },
  },
};

type Props = {
  status?: (typeof GeneralStatus)[keyof typeof GeneralStatus];
  model: string;
  size?: (typeof PRODUCT_SIZE)[keyof typeof PRODUCT_SIZE];
};

const ProductIcon = memo(function ProductIcon(props: Props) {
  const { status, model, size = PRODUCT_SIZE.medium } = props;

  const trueModel = getTrueModel(model, true);
  const orientation = OUTDOOR_APS.includes(model) ? "vertical" : "horizontal";

  if (!trueModel) {
    if (status == GoStatus.bad) {
      return <MerakiIcon name="alert" size="l" color={MkiColors.badStatus} />;
    } else {
      return (
        <View
          style={[
            {
              width: DIMS[size].horizontal.width,
              height: DIMS[size].horizontal.height,
            },
            styles.placeholder,
          ]}
        />
      );
    }
  }

  const computedStatus = !status || status === GoStatus.offline ? "Offline" : "Online";

  return (
    <View style={DIMS[size][orientation]}>
      <MerakiIcon
        name={`${trueModel}${computedStatus}`}
        containerStyle={styles.icon}
        testID={`${trueModel}${computedStatus}_ICON`}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  icon: {
    flex: 1,
  },
  placeholder: {
    borderWidth: 2,
    borderRadius: 4,
    borderStyle: "dashed",
    borderColor: MkiColors.hardwareIllustrations.sideFillColor,
  },
});

export default ProductIcon;
