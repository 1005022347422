import {
  ADD_SUPPORT_CASE_COMMENT_SUCCESS,
  GET_SUPPORT_CASE_INFO_SUCCESS,
  GET_SUPPORT_CASES_SUCCESS,
  UPDATE_SUPPORT_CASE_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import {
  CaseInfoInteractionTypes,
  Interaction,
  SupportCase,
  SupportCaseComment,
} from "~/shared/types/SupportCaseTypes";

export interface SupportCaseState {
  [caseNumber: string]: SupportCase;
}

const initialState: SupportCaseState = {};

export default function supportCases(state = initialState, action: any) {
  const { type, response } = action;
  switch (type) {
    case GET_SUPPORT_CASES_SUCCESS: {
      const { userEmail } = action.meta;
      const nextState: any = {};

      response.forEach((supportCase: SupportCase) => {
        if (supportCase.suppliedEmail === userEmail) {
          nextState[supportCase.caseNumber] = {
            ...state[supportCase.caseNumber],
            ...supportCase,
          };
        }
      });

      return nextState;
    }
    case UPDATE_SUPPORT_CASE_SUCCESS: {
      const { userClosed, caseNumber } = action.meta;

      if (!response.success) {
        return state;
      } else if (userClosed) {
        return {
          ...state,
          [caseNumber]: {
            ...(state[caseNumber] ?? {}),
            status: "Closed",
            statusDetails: "Closed by Customer",
          },
        };
      } else {
        return {
          ...state,
          [caseNumber]: {
            ...(state[caseNumber] ?? {}),
            status: "Support Action Pending",
            statusDetails: null,
          },
        };
      }
    }
    case ADD_SUPPORT_CASE_COMMENT_SUCCESS: {
      const { commentText, caseNumber } = action.meta;

      if (!response.success) {
        return state;
      } else {
        return {
          ...state,
          [caseNumber]: {
            ...(state[caseNumber] ?? {}),
            comments: [
              {
                commentBody: commentText,
                createdByName: "Dashboard",
              },
              ...(state[caseNumber].comments ?? []),
            ],
          },
        };
      }
    }
    case GET_SUPPORT_CASE_INFO_SUCCESS: {
      const { caseNumber } = action.meta;

      const parsed_comments: SupportCaseComment[] = [];

      response.interactions.forEach((interaction: Interaction) => {
        if (interaction.type == CaseInfoInteractionTypes.comment) {
          parsed_comments.push({
            commentBody: interaction.commentBody || "",
            createdByName: interaction.createdByName || "",
            createdDate: interaction.createdDate,
          });
        }
      });

      return {
        ...state,
        [caseNumber]: {
          ...(state[caseNumber] ?? {}),
          comments: parsed_comments,
        },
      };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
