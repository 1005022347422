import { I18n } from "@meraki/core/i18n";
import {
  FailedWirelessConnectionDetailsScreen,
  FailedWirelessConnectionsListScreen,
} from "@meraki/shared/clients";
import { FailedConnectionGroupProps, StackType } from "@meraki/shared/navigation-type";

export function FailedConnectionGroup<T extends FailedConnectionGroupProps>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="FailedWirelessConnectionsList"
        component={FailedWirelessConnectionsListScreen}
        options={{
          headerTitle: I18n.t(
            "NETWORK_OVERVIEW.WIRELESS_HEALTH.OVERVIEW.SCREEN.TITLE_FAILED_WIRELESS_CONNS",
          ),
        }}
      />
      <Stack.Screen
        name="FailedWirelessConnection"
        component={FailedWirelessConnectionDetailsScreen}
        options={{
          headerTitle: I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.TITLE_SINGULAR"),
        }}
      />
    </>
  );
}
