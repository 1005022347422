import * as CableTest from "../handlers/CableTest";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useCableTest(
  deviceId: string,
  portIds: string[],
): LiveTool<CableTest.Status> {
  const msg = useLiveBroker<CableTest.Props, CableTest.Message>(
    {
      type: "CableTest",
      deviceId,
      ports: portIds.join(),
    },
    true,
  );

  return useCachedFormatter(msg, CableTest.formatMsg);
}
