import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

interface GoUmbrellaStatusRequest {
  orgEid?: string;
}

export const GoUmbrellaStatusSchema = z
  .object({
    hasActiveUmbrellaLicense: z.boolean(),
    hasClaimedUmbrellaLicense: z.boolean(),
    isUmbrellaEnabled: z.boolean(),
    isUmbrellaProvisioned: z.boolean(),
    umbrellaOrganizationId: z.string().nullable(),
  })
  .describe("GoUmbrellaStatusSchema");

export type GoUmbrellaStatus = z.infer<typeof GoUmbrellaStatusSchema>;

const buildUrl = ({ orgEid }: GoUmbrellaStatusRequest) => {
  return `/o/${orgEid}/manage/security/go_umbrella_status`;
};

const fetchGoUmbrellaStatus = ({ orgEid }: GoUmbrellaStatusRequest) => {
  return request(GoUmbrellaStatusSchema, "GET", buildUrl({ orgEid }));
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useUmbrellaStatus = createQuery({
  baseQueryKey: buildUrl({ orgEid: `{orgEid}` }),
  queryFn: (options: GoUmbrellaStatusRequest) => fetchGoUmbrellaStatus(options),
  requiredVariables: ["orgEid"],
});
