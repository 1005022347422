import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Heading,
  List,
  RefreshControl,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  ApplianceTrafficShapingRule,
  ApplianceTrafficShapingRules,
  queryClient,
  SsidTrafficShapingRule,
  SsidTrafficShapingRules,
  useApplianceTrafficShapingRules,
  useConfiguredSsids,
  useSsidTrafficShapingRules,
  useUpdateApplianceTrafficShapingRules,
  useUpdateSsidTrafficShapingRules,
} from "@meraki/shared/api";
import { formatTransferBitsPerSecond } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef, useState } from "react";

import { LAYER_7_GROUPS } from "../constants/Layer7";
import { PRIORITY_TO_NUM, TRAFFIC_SHAPING_MEBIBITS_INTEGERED } from "../constants/TrafficShaping";

const getLimitTranslation = (limit?: number) => {
  let limitTranslation = I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.NO_LIMIT");

  if (limit != null) {
    const limitInMib = TRAFFIC_SHAPING_MEBIBITS_INTEGERED[limit];
    if (limitInMib != null) {
      limitTranslation = formatTransferBitsPerSecond(limitInMib);
    }
  }

  return limitTranslation;
};

const orderRulesByPriority = (rules: ApplianceTrafficShapingRule[]) => {
  return rules.sort(
    (a, b) => PRIORITY_TO_NUM[b.priority ?? "normal"] - PRIORITY_TO_NUM[a.priority ?? "normal"],
  );
};

const orderRulesByPcPTag = (rules: SsidTrafficShapingRule[]) => {
  return rules.sort((a, b) => (b.pcpTagValue ?? 1) - (a.pcpTagValue ?? 1));
};

export function TrafficShapingRulesListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const route = useRoute<RouteProp<ConfigureStackProps, "TrafficShapingRulesList">>();
  const { params: props } = route;
  const { applianceOrSSID, ssidNumber } = props;
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const networkId = useCurrentNetworkId();

  const { data: ssids } = useConfiguredSsids({ networkId });
  const {
    data: applianceTrafficShapingRules,
    refetch: refetchApplianceTrafficShapingRules,
    isRefetching: isRefetchingApplianceTrafficShapingRules,
  } = useApplianceTrafficShapingRules({ networkId });

  const {
    data: ssidTrafficShapingRules,
    refetch: refetchSSIDTrafficShapingRules,
    isRefetching: isRefetchingSSIDTrafficShapingRules,
  } = useSsidTrafficShapingRules({
    networkId,
    ssidNumber: ssidNumber,
  });

  const trafficShapingRules: ApplianceTrafficShapingRules | SsidTrafficShapingRules | undefined =
    applianceOrSSID === "appliance" ? applianceTrafficShapingRules : ssidTrafficShapingRules;
  const rulesArray = trafficShapingRules?.rules ?? [];

  const [isDefault, setIsDefault] = useState(
    applianceTrafficShapingRules?.defaultRulesEnabled ?? true,
  );

  const updateApplianceTrafficShaping = useUpdateApplianceTrafficShapingRules();
  const updateSsidTrafficShaping = useUpdateSsidTrafficShapingRules();

  const updateTrafficShaping = (
    partialRequest: Partial<ApplianceTrafficShapingRules | SsidTrafficShapingRules>,
  ) => {
    updateApplianceTrafficShaping.mutate(
      {
        networkId,
        rules: {
          defaultRulesEnabled: trafficShapingRules?.defaultRulesEnabled ?? false,
          rules: rulesArray,
          ...partialRequest,
        },
      },
      {
        onSuccess: () =>
          queryClient.refetchQueries({
            queryKey: useApplianceTrafficShapingRules.queryKeyRoot,
          }),
      },
    );

    // appliance/network level rule typing applies to all SSIDs
    ssids?.forEach((ssid) => {
      updateSsidTrafficShaping.mutate({
        networkId,
        ssidNumber: ssid.number.toString(),
        rules: {
          defaultRulesEnabled: trafficShapingRules?.defaultRulesEnabled ?? false,
          trafficShapingEnabled: !trafficShapingRules?.defaultRulesEnabled ?? false,
          rules: rulesArray,
          ...partialRequest,
        },
      });
    });
  };

  return (
    <Screen
      addDefaultPadding
      refreshControl={
        <RefreshControl
          refreshing={
            isRefetchingApplianceTrafficShapingRules || isRefetchingSSIDTrafficShapingRules
          }
          onRefresh={() => {
            refetchApplianceTrafficShapingRules();
            refetchSSIDTrafficShapingRules();
          }}
        />
      }
    >
      <Heading size="h1">
        {I18n.t("CONFIGURE.TRAFFIC_SHAPING.TITLE", {
          type:
            applianceOrSSID === "appliance"
              ? I18n.t("CONFIGURE.TRAFFIC_SHAPING.APPLIANCE")
              : I18n.t("CONFIGURE.TRAFFIC_SHAPING.SSID"),
        })}
      </Heading>
      <Box alignItems="flex-start">
        <Text size="p1">
          {applianceOrSSID === "ssid"
            ? `${I18n.t("CONFIGURE.TRAFFIC_SHAPING.DESCRIPTION")} ${I18n.t(
                "CONFIGURE.TRAFFIC_SHAPING.SSID_DISCLAIMER",
              )}`
            : I18n.t("CONFIGURE.TRAFFIC_SHAPING.DESCRIPTION")}
        </Text>
        <Button
          kind="tertiary"
          onPress={() => bottomSheetRef.current?.present()}
          text={I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.TITLE")}
          testID="USAGE_LIMITS_INFO"
        />
      </Box>

      <List>
        <List.Item
          leftAccessory={<Icon name={"DesktopTower"} />}
          title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.DEFAULT_RULES.TITLE")}
          description={I18n.t("CONFIGURE.TRAFFIC_SHAPING.DEFAULT_RULES.DESCRIPTION")}
          rightAccessory={
            <Toggle
              testID="DEFAULT_RULES_ENABLED"
              checked={isDefault}
              onValueChange={(value) => {
                setIsDefault(!isDefault);
                updateTrafficShaping({
                  defaultRulesEnabled: value,
                  trafficShapingEnabled: !value,
                });
              }}
            />
          }
        />
      </List>

      <Card padding="none">
        <List.Item
          leftAccessory={<Icon name={"DesktopTower"} />}
          title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.TITLE")}
          description={I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.DESCRIPTION")}
          rightAccessory={
            <Toggle
              testID="CUSTOM_RULES_ENABLED"
              checked={!isDefault}
              onValueChange={(value) => {
                setIsDefault(!isDefault);
                updateTrafficShaping({
                  defaultRulesEnabled: !value,
                  trafficShapingEnabled: value,
                });
              }}
            />
          }
        />
        <List.Item
          title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.RULES.TITLE")}
          rightAccessory={
            <Button
              testID="ADD_CUSTOM_RULE"
              kind="tertiary"
              onPress={() =>
                navigation.navigate("TrafficShapingRule", {
                  rules: {
                    defaultRulesEnabled: isDefault,
                    trafficShapingEnabled: !isDefault,
                    rules: rulesArray,
                  },
                  ssidNumber: ssidNumber,
                })
              }
              text={I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.RULES.ADD_RULE")}
              trailingIcon="Plus"
              disabled={!!isDefault}
            />
          }
        />
        <List.FlashList
          data={
            applianceOrSSID === "appliance"
              ? orderRulesByPriority(applianceTrafficShapingRules?.rules ?? [])
              : orderRulesByPcPTag(ssidTrafficShapingRules?.rules ?? [])
          }
          getItemData={(item, _, index) => {
            const limit = item.perClientBandwidthLimits?.bandwidthLimits?.limitDown;

            return {
              title: item.definitions
                .reduce<string[]>((result, { type, value }) => {
                  if (type === "applicationCategory") {
                    const foundGroup = LAYER_7_GROUPS[value.id];
                    if (foundGroup) {
                      result.push(I18n.t(foundGroup.name));
                    }
                  }

                  return result;
                }, [])
                .join(", "),
              description: getLimitTranslation(limit),
              rightAccessory: (
                <Button
                  kind="tertiary"
                  onPress={() =>
                    navigation.navigate("TrafficShapingRule", {
                      rules: {
                        defaultRulesEnabled: isDefault,
                        trafficShapingEnabled: !isDefault,
                        rules: rulesArray,
                      },
                      ruleIndex: index,
                      ssidNumber: ssidNumber,
                    })
                  }
                  text={I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.RULES.EDIT_RULE")}
                  disabled={isDefault}
                />
              ),
              testID: `CUSTOM_RULE_${index}`,
            };
          }}
          emptyState={{ title: I18n.t("CONFIGURE.TRAFFIC_SHAPING.CUSTOM_RULES.RULES.NONE_ADDED") }}
        />
      </Card>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.HEADER")} />
        <BottomSheet.Content>
          <Box gap="md">
            <Heading size="h3">{I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.TITLE")}</Heading>
            <Text size="p1">{I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.MESSAGE")}</Text>
            <Button
              text={I18n.t("CONFIGURE.TRAFFIC_SHAPING.USAGE_LIMITS.CLOSE")}
              onPress={() => bottomSheetRef.current?.dismiss()}
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
