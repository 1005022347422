import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { ssidsOnAccessPoint } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import Table from "~/shared/components/Table";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";

const renderRow = (rowData: SSID, onPress: (id: number) => void) => {
  const { name, number } = rowData;
  return <SimpleDisclosureRow onPress={() => onPress(number)}>{name}</SimpleDisclosureRow>;
};

type BroadcastingSSIDsProp = {
  onPress: (id: number) => void;
  device: DeviceWithNodeGroupInfo;
};

export const BroadcastingSSIDsCard = ({ device, onPress }: BroadcastingSSIDsProp) => {
  const ssids = useAppSelector((state) => ssidsOnAccessPoint(state, device));

  const noSSIDsBroadcasted = ssids.length === 0;

  return (
    <View style={styles.container} testID="BROADCASTING_SSID_CARD">
      <SummaryCard
        heading={I18n.t("HOME.BROADCASTING_SSID.TITLE")}
        subheading={I18n.t("HOME.BROADCASTING_SSID.SUBTITLE")}
      >
        {noSSIDsBroadcasted ? (
          <MkiText screenStyles={styles.noSSIDsText} textStyle="secondary">
            {I18n.t("HOME.BROADCASTING_SSID.NO_SSIDS")}
          </MkiText>
        ) : (
          <Table<SSID>
            entries={ssids}
            renderRow={(ssid) => renderRow(ssid, onPress)}
            separators={false}
          />
        )}
      </SummaryCard>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: SPACING.default,
  },
  noSSIDsText: {
    flex: 1,
    paddingTop: SPACING.default,
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

export default BroadcastingSSIDsCard;
