import { SpSamlAuthData } from "~/shared/types/AuthTypes";

const isAuthCookie = (authCookie: string): boolean => {
  const re = /.{250,}/gim;
  return !!authCookie.match(re);
};

const isShardId = (shardId: string): boolean => {
  const re = /^[0-9]{1,4}$/gim;
  return !!shardId.match(re);
};

const isOrgId = (orgId: string): boolean => {
  const re = /^[0-9]{1,40}$/gim;
  return !!orgId.match(re);
};

export const normalizeAuthData = (url: string): SpSamlAuthData => {
  const successUrlRegex = /(^.*\/sso\/success\/)/gim;
  const path = url.replace(successUrlRegex, "");
  const authData = path.split("/");
  const normalizedAuthData: SpSamlAuthData = { auth: null, shardId: null, orgId: null };
  authData.forEach((data, i) => {
    if (isAuthCookie(data)) {
      normalizedAuthData["auth"] = data;
    } else if (i === 0 && isShardId(data)) {
      normalizedAuthData["shardId"] = data;
    } else if (isOrgId(data)) {
      normalizedAuthData["orgId"] = data;
    }
  });
  return normalizedAuthData;
};
