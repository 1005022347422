import { createSelector } from "reselect";

import { whatsNewFilter } from "~/lib/WhatsNewUtils";
import { getGXSerial } from "~/selectors/devices";
import { getWhatsNewState } from "~/selectors/getters";
import { getHasAlphaFeatures } from "~/selectors/nfos";
import { isUmbrellaAccountReadySelector } from "~/selectors/umbrella";
import { WhatsNewFeatures } from "~/shared/types/WhatsNewTypes";

export const getWhatsNew = createSelector(
  getWhatsNewState,
  isUmbrellaAccountReadySelector,
  getHasAlphaFeatures,
  getGXSerial,
  (
    whatsNew: any,
    isUmbrellaAccountReady: boolean,
    hasAlphaFeatures: boolean,
    gxSerial?: string,
  ) => {
    const { updated, ...features } = whatsNew;
    return whatsNewFilter(features, isUmbrellaAccountReady, hasAlphaFeatures, gxSerial);
  },
);

export const countUnreadFeatures = createSelector(getWhatsNew, (whatsNew: WhatsNewFeatures) => {
  return Object.values(whatsNew).filter((breakdown) => !breakdown?.read).length;
});
