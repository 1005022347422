import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { launchMLabPolicy } from "@meraki/go/links";
import { Button, Checkbox, Notification, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useClients, useLocalStatus } from "@meraki/shared/api";
import { Gauge } from "@meraki/shared/components";
import { showAlert } from "@meraki/shared/native-alert";
import { SECONDS_IN_A_DAY, useCurrentNetworkId } from "@meraki/shared/redux";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Pressable } from "react-native";
import { getIpAddressSync } from "react-native-device-info";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { MLabStatus, useMLabSpeedTest } from "../hooks/useMLabSpeedTest";

type Agreement = {
  agreed: boolean;
  setAgreed: (agreed: boolean) => void;
};

export const useAgreement = create<Agreement>()(
  persist(
    (set) => ({
      agreed: false,
      setAgreed: (agreed: boolean) => set((_) => ({ agreed })),
    }),
    {
      name: "SpeedTestAgreement",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export const useConnectedToNetworkByIp = (networkId?: string, targetIp?: string) => {
  return useClients(
    { networkId, timespan: SECONDS_IN_A_DAY },
    {
      select: (data) => {
        if (data.length === 0) {
          return false;
        }
        targetIp = targetIp || getIpAddressSync();
        return data.some((client) => client.ip === targetIp);
      },
    },
  );
};

export const BUTTON_TRANSLATION_KEY_BY_STATUS: Record<MLabStatus, string> = {
  notReady: "BEGIN",
  ready: "BEGIN",
  failed: "BEGIN",
  downloading: "DOWNLOADING",
  uploading: "UPLOADING",
  completed: "COMPLETE",
};

const GAUGE_INCREMENT = 120;

export function SpeedTestScreen() {
  const networkId = useCurrentNetworkId();
  const { data: connectedViaIp, isLoading: isLoadingClients } =
    useConnectedToNetworkByIp(networkId);
  const { status: lspStatus, isLoading: isLoadingLsp, failureReason } = useLocalStatus({});
  const [status, { fetchTestLocations, downloadTester, uploadTester }] = useMLabSpeedTest();

  const { agreed, setAgreed } = useAgreement();
  const [runAnyway, setRunAnyway] = useState(false);
  const [downloadResult, setDownloadResult] = useState(0.0);
  const [uploadResult, setUploadResult] = useState(0.0);
  const [max, setMax] = useState(GAUGE_INCREMENT);

  const showDirectionOfUse = () =>
    showAlert(I18n.t("SPEED_TEST.TITLE"), I18n.t("SPEED_TEST.DIRECTION_OF_USE"), [
      {
        text: I18n.t("CONFIRM"),
        onPress: () => setRunAnyway(true),
        isPreferred: true,
      },
    ]);

  useEffect(() => {
    if (agreed) {
      fetchTestLocations();
    }
  }, [agreed, fetchTestLocations]);

  const startTest = useCallback(async () => {
    // use debounce to avoid too frequent rerender
    await downloadTester(debounce(setDownloadResult, 50));
    await uploadTester(debounce(setUploadResult, 50));
  }, [downloadTester, uploadTester]);

  const getMax = useCallback(
    (newSpeed: number) => {
      if (newSpeed > max) {
        const newMax = Math.ceil(newSpeed / GAUGE_INCREMENT) * GAUGE_INCREMENT;
        setMax(newMax);
        return newMax;
      }

      return max;
    },
    [max],
  );

  const result = useMemo(() => {
    if (status === "downloading") {
      return [
        { value: downloadResult, label: I18n.t("SPEED_TEST.RESULT.DOWNLOAD") },
        { value: 0, label: I18n.t("SPEED_TEST.RESULT.UPLOAD") },
      ];
    }

    if (status === "uploading" || status === "completed") {
      return [
        { value: downloadResult, label: I18n.t("SPEED_TEST.RESULT.DOWNLOAD") },
        { value: uploadResult, label: I18n.t("SPEED_TEST.RESULT.UPLOAD") },
      ];
    }

    return [
      { value: 0, label: I18n.t("SPEED_TEST.RESULT.DOWNLOAD") },
      { value: 0, label: I18n.t("SPEED_TEST.RESULT.UPLOAD") },
    ];
  }, [downloadResult, status, uploadResult]);

  const connectedToNetwork = connectedViaIp || (failureReason === null && lspStatus === "success");
  return (
    <Screen addDefaultPadding>
      <Box alignItems="center">
        <Text>{I18n.t("SPEED_TEST.DESCRIPTION")}</Text>
      </Box>
      <Notification.Inline
        status={connectedToNetwork ? "positive" : "warning"}
        message={I18n.t(
          `SPEED_TEST.CONNECTION.${connectedToNetwork ? "DETECTED" : "NOT_DETECTED"}`,
        )}
      />
      <Gauge
        size="lg"
        min={0}
        max={getMax(downloadResult)}
        data={result}
        detailFormatter={(speed) => `${speed} Mbps`}
        testID="SPEED_TEST.GAUGE"
      />
      <Box flexDirection="row">
        <Checkbox
          checked={agreed}
          onValueChange={(checked) => setAgreed(checked)}
          testID="SPEED_TEST.POLICY.CHECK"
        />
        <Pressable onPress={launchMLabPolicy} testID="SPEED_TEST.POLICY.ACKNOWLEDGEMENT">
          <Box paddingHorizontal="xs">
            <Text>
              <Text>{I18n.t("SPEED_TEST.POLICY.AGREEMENT")}</Text>
              <Text color="control.icon.strong.active">{I18n.t("SPEED_TEST.POLICY.LINK")}</Text>
              <Text>{I18n.t("SPEED_TEST.POLICY.ADDITIONAL_INFO")}</Text>
            </Text>
          </Box>
        </Pressable>
      </Box>
      {!connectedToNetwork && (
        <Box flexDirection="row">
          <Checkbox
            checked={runAnyway}
            onValueChange={(checked) => setRunAnyway(checked)}
            testID="SPEED_TEST.RUN_ANYWAY.CHECK"
          />
          <Pressable onPress={showDirectionOfUse} testID="SPEED_TEST.RUN_ANYWAY.ACKNOWLEDGEMENT">
            <Box paddingHorizontal="xs">
              <Text color="control.icon.strong.active">
                {I18n.t("SPEED_TEST.POLICY.ACKNOWLEDGEMENT")}
              </Text>
            </Box>
          </Pressable>
        </Box>
      )}
      <Button
        text={I18n.t(`SPEED_TEST.BUTTON.${BUTTON_TRANSLATION_KEY_BY_STATUS[status]}`)}
        onPress={startTest}
        loading={isLoadingClients || isLoadingLsp}
        disabled={
          !agreed ||
          !(runAnyway || connectedToNetwork) ||
          status === "notReady" ||
          status === "downloading" ||
          status === "uploading"
        }
        testID="SPEED_TEST.START"
      />
    </Screen>
  );
}
