import Svg, { Path } from "react-native-svg";

export const ChartIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width={30} height={30} viewBox="0 0 23 24" fill="none">
      <Path
        d="M8.625 5.90527H19.4062"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.62553 11.6553H19.4062"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.62553 17.4053H19.4062"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.59375 5.90527H5.03125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.59428 11.6553H5.03122"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.59428 17.4053H5.03122"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChartIcon;
