import { BaseDeviceSubProps } from "../types/BaseSubProps";
import DataMsg from "../types/DataMsg";

export interface Props extends BaseDeviceSubProps {
  host?: string;
}

export interface Message extends DataMsg {
  data: {
    pings: number[] | PingV6[];
    completed: boolean;
  };
}

export interface PingV6 {
  addrV4: string | undefined;
  addrV6: string | undefined;
  latencyV4: number;
  latencyV6: number;
}
export interface FormattedResult {
  latencyV4: FormattedPingStat;
  latencyV6: FormattedPingStat;
}
export interface FormattedPingStat {
  pings: number[];
  numLost: number;
  numSuccess: number;
}

export interface Result extends FormattedPingStat {
  lossRate: number;
  averageLatency: number;
}

export function formatMsg(message?: Message): FormattedResult | undefined {
  const pings = message?.data?.pings;

  if (!pings || pings.length === 0) {
    return undefined;
  }

  const formattedPings = pings.map((ping: number | PingV6) => {
    if (typeof ping === "number") {
      return { latencyV4: <number>ping, latencyV6: -1 };
    } else {
      const { latencyV4, latencyV6 } = <PingV6>ping;
      return { latencyV4: latencyV4 ?? -1, latencyV6: latencyV6 ?? -1 };
    }
  });
  const succeededPingsV4: number[] = formattedPings.map((p) => p.latencyV4).filter((p) => p !== -1);
  const succeededPingsV6: number[] = formattedPings.map((p) => p.latencyV6).filter((p) => p !== -1);
  const numSuccessV4 = succeededPingsV4.length;
  const numLostV4 = formattedPings.length - numSuccessV4;

  const numSuccessV6 = succeededPingsV6.length;
  const numLostV6 = formattedPings.length - numSuccessV6;
  return {
    latencyV4: {
      pings: succeededPingsV4,
      numSuccess: numSuccessV4,
      numLost: numLostV4,
    },
    latencyV6: {
      pings: succeededPingsV6,
      numSuccess: numSuccessV6,
      numLost: numLostV6,
    },
  };
}
