import { I18n } from "@meraki/core/i18n";
import {
  AboutScreen,
  AccountScreen,
  AddAdministratorScreen,
  AdministratorListScreen,
  BlockWebsiteScreen,
  ConfigureScreen,
  CreateOrganizationScreen,
  LimitedBlockedClientsListScreen,
  NetworkConfigureScreen,
  NetworkSelectionScreen,
  OpenSourceLicensesScreen,
  OrgDeleteScreen,
  PasswordResetScreen,
  WebBlockingScreen,
} from "@meraki/go/configure";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  ClientVPNAdminAccessListScreen,
  ClientVPNEventLogScreen,
  ClientVPNGuestListScreen,
  ClientVPNInviteGuestScreen,
  ClientVPNScreen,
  ClientVPNViewGuestScreen,
  SiteToSiteVPNScreen,
} from "@meraki/go/vpn";
import { Button } from "@meraki/magnetic/components";
import {
  AccessPointPlacementGroup,
  LicenseGroup,
  ModalScreenOptions,
  SettingsGroup,
  useDefaultScreenOptions,
  useNetworkBackButton,
  useTabScreenOptions,
} from "@meraki/shared/navigation";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import { ClientGroup } from "../screenGroups/ClientGroup";
import { DeviceGroup } from "../screenGroups/DeviceGroup";
import { SsidGroup } from "../screenGroups/SsidGroup";
import { TrafficShapingGroup } from "../screenGroups/TrafficShapingGroup";
import { VlanGroup } from "../screenGroups/VlanGroup";

const Stack = createNativeStackNavigator<ConfigureStackProps>();

const ConfigureStack = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();

  const defaultScreenOptions = useDefaultScreenOptions();
  const tabScreenOptions = useTabScreenOptions();
  const optionsWithNetworkButton = useNetworkBackButton();

  const currentNetworkId = useCurrentNetworkId();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="Configure"
        component={currentNetworkId ? NetworkConfigureScreen : ConfigureScreen}
        options={tabScreenOptions}
      />
      <Stack.Screen
        name="Administrators"
        component={AdministratorListScreen}
        options={{
          headerTitle: I18n.t("ADMIN.SCREEN_TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CLOSE")} onPress={() => navigation.pop()} />,
          headerRight: () => (
            <Button.Nav
              text={I18n.t("ADD")}
              onPress={() => navigation.navigate("AddAdministrator")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="AddAdministrator"
        component={AddAdministratorScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("ADMIN.ADD.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="LimitedBlockedClients"
        component={LimitedBlockedClientsListScreen}
        options={{
          headerTitle: I18n.t("NETWORK_CONFIGURE.NETWORK.CLIENT_POLICY"),
        }}
      />
      <Stack.Screen
        name="WebBlocking"
        component={WebBlockingScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="BlockWebsite"
        component={BlockWebsiteScreen}
        options={{
          headerTitle: I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.BLOCK_WEBSITE.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="SiteToSiteVPN"
        component={SiteToSiteVPNScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="NetworkSelection"
        component={NetworkSelectionScreen}
        options={{ headerTitle: I18n.t("NETWORKS.TITLE") }}
      />
      <Stack.Screen
        name="ClientVPN"
        component={ClientVPNScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="ClientVPNGuestList"
        component={ClientVPNGuestListScreen}
        options={{
          headerLeft: () => <Button.Nav text={I18n.t("CLOSE")} onPress={() => navigation.pop()} />,
          headerRight: () => (
            <Button.Nav
              text={I18n.t("ADD")}
              onPress={() => navigation.navigate("ClientVPNInviteGuest")}
            />
          ),
          headerTitle: I18n.t("CLIENT_VPN.USER.GUEST_VPN"),
          headerShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="ClientVPNAdminAccessList"
        component={ClientVPNAdminAccessListScreen}
        options={{
          headerLeft: () => <Button.Nav text={I18n.t("CLOSE")} onPress={() => navigation.pop()} />,
          headerTitle: I18n.t("CLIENT_VPN.ADMIN_VPN_ACCESS"),
          headerShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="ClientVPNInviteGuest"
        component={ClientVPNInviteGuestScreen}
        options={{
          headerShadowVisible: false,
          headerTitle: I18n.t("CLIENT_VPN.USER.VIEW_GUEST"),
        }}
      />
      <Stack.Screen
        name="ClientVPNViewGuest"
        component={ClientVPNViewGuestScreen}
        options={{
          headerShadowVisible: false,
          headerTitle: I18n.t("CLIENT_VPN.USER.INVITE_GUEST"),
        }}
      />
      <Stack.Screen
        name="Account"
        component={AccountScreen}
        options={{
          headerShadowVisible: false,
          headerTitle: I18n.t("ACCOUNT_SCREEN.TITLE"),
        }}
      />
      <Stack.Screen
        name="PasswordReset"
        component={PasswordResetScreen}
        options={{ headerTitle: I18n.t("ACCOUNT.CHANGE_PASSWORD") }}
      />
      <Stack.Screen
        name="CreateOrganization"
        component={CreateOrganizationScreen}
        options={{
          headerTitle: I18n.t("CREATE_ORGANIZATION.TITLE"),
          presentation: "modal",
          animation: "slide_from_bottom",
        }}
      />
      <Stack.Screen
        name="OrgDelete"
        component={OrgDeleteScreen}
        options={{
          headerTitle: I18n.t("DELETE_LOCATION.TITLE"),
          presentation: "modal",
          animation: "slide_from_bottom",
        }}
      />
      <Stack.Screen
        name="About"
        component={AboutScreen}
        options={{ headerTitle: I18n.t("SETTINGS_SCREEN.GENERAL.ABOUT.TITLE") }}
      />
      <Stack.Screen
        name="OpenSourceLicenses"
        component={OpenSourceLicensesScreen}
        options={{ headerTitle: I18n.t("OPEN_SOURCE") }}
      />
      {DeviceGroup(Stack)}
      {SsidGroup(Stack)}
      {ClientGroup(Stack)}
      {LicenseGroup(Stack)}
      {TrafficShapingGroup(Stack)}
      {VlanGroup(Stack)}
      {SettingsGroup(Stack)}
      {AccessPointPlacementGroup(Stack)}

      <Stack.Screen
        name="ClientVPNEventLog"
        component={ClientVPNEventLogScreen}
        options={{ headerTitle: I18n.t("CLIENT_VPN.EVENT_LOG.HEADER") }}
      />
    </Stack.Navigator>
  );
};

// search tag registrations
registerTags(
  {
    title: "ACCOUNT_SCREEN.TITLE",
    tab: "ConfigureTab",
    screen: "Account",
  },
  ["account"],
);

registerTags(
  {
    title: "ADMIN.SCREEN_TITLE",
    tab: "ConfigureTab",
    scope: "organization",
    screen: "Administrators",
  },
  ["admins", "administrators"],
);

registerTags(
  {
    title: "NETWORK_CONFIGURE.NETWORK.CLIENT_POLICY",
    tab: "ConfigureTab",
    scope: "network",
    screen: "LimitedBlockedClients",
  },
  ["clients", "block"],
);

registerTags(
  {
    title: "ACCOUNT.CHANGE_PASSWORD",
    tab: "ConfigureTab",
    screen: "PasswordReset",
  },
  ["password", "reset"],
);

registerTags(
  {
    title: "SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "WebBlocking",
  },
  ["network", "block"],
);

registerTags(
  {
    title: "CLIENT_VPN.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "ClientVPN",
  },
  ["network", "vpn"],
);

registerTags(
  {
    title: "SITE_TO_SITE_VPN.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "SiteToSiteVPN",
  },
  ["network", "site-to-site", "vpn"],
);

export default ConfigureStack;
