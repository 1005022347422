import { memo } from "react";
import { StyleProp, StyleSheet, TextStyle, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { isText } from "~/lib/TypeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

export type ArrowDirection = "arrowDown" | "arrowRight";
export interface DisclosureIconProps {
  style?: StyleProp<TextStyle>;
  icon?: React.ReactNode;
  hide?: boolean;
  iconDirection?: ArrowDirection;
  iconColor?: string;
}

const getIconProps = (iconDirection: ArrowDirection | undefined) => {
  return iconDirection && iconDirection === "arrowDown"
    ? {
        name: appSelect({
          enterprise: platformSelect({ ios: "chevron-down", android: "md-arrow-down" }),
          go: "chevron-down",
        }),
        size: 18,
      }
    : {
        name: appSelect({
          enterprise: platformSelect({ ios: "chevron-forward", android: "md-arrow-forward" }),
          go: "chevron-forward",
        }),
        size: 22,
      };
};

const DisclosureIcon = ({ style, icon, hide, iconDirection, iconColor }: DisclosureIconProps) => {
  const navColors = themeColors(useTheme()).navigation;
  if (hide || icon === null) {
    return null;
  }

  const color =
    iconColor ??
    appSelect({
      enterprise: navColors.primary,
      go: navColors.secondary,
    });

  let resolvedIcon = icon ?? <MerakiIcon {...getIconProps(iconDirection)} color={color} />;

  if (isText(icon)) {
    resolvedIcon = <MkiText screenStyles={[{ color }, style]}>{icon}</MkiText>;
  }

  return <View style={styles.accessory}>{resolvedIcon}</View>;
};

const styles = StyleSheet.create({
  accessory: {
    marginHorizontal: SPACING.default,
    alignSelf: "center",
  },
});

export default memo(DisclosureIcon);
