import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import CodePush from "react-native-code-push";
import DeviceInfo from "react-native-device-info";
import { GIT_HASH } from "react-native-dotenv";

export function AboutScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const version = DeviceInfo.getVersion();
  const buildNumber = DeviceInfo.getBuildNumber();
  const { value: hasMobileAlphaFeatures } = useBoolFlag("nfo", "hasMobileAlphaFeatures");
  const [codePushVersion, setCodePushVersion] = useState<string | null>(null);

  useEffect(() => {
    const fetchCodePushVersion = () => {
      CodePush.getUpdateMetadata().then((update) => {
        if (update) {
          setCodePushVersion(update.label);
        }
      });
    };

    fetchCodePushVersion();
  }, []);
  return (
    <Screen addDefaultPadding>
      <List label={I18n.t("SETTINGS_SCREEN.GENERAL.ABOUT.APP_VERSION.TITLE")}>
        <List.Item title={I18n.t("VERSION")} value={version} />
        <List.Item title={I18n.t("BUILD")} value={buildNumber} />
        <List.Item title={I18n.t("REVISION")} value={GIT_HASH || "0"} />
        {hasMobileAlphaFeatures && (
          <List.Item title={I18n.t("CODE_PUSH_UPDATE")} value={codePushVersion ?? I18n.t("NONE")} />
        )}
      </List>
      <List label={I18n.t("LEGAL")}>
        <List.Item
          title={I18n.t("OPEN_SOURCE")}
          description={I18n.t("SETTINGS_SCREEN.GENERAL.ABOUT.OPEN_SOURCE.SUBTITLE")}
          onPress={() => navigation.navigate("OpenSourceLicenses")}
        />
      </List>
    </Screen>
  );
}
