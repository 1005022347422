import Svg, { Circle, Path, SvgProps } from "react-native-svg";

function MagneticNegativeIcon({ width, height }: SvgProps) {
  return (
    <Svg
      width={width ?? 16}
      height={height ?? 16}
      viewBox={`0 0 ${width ?? 16} ${height ?? 16}`}
      fill="none"
    >
      <Path
        d="M7.16022 1.6566C7.67915 1.35389 8.32085 1.35389 8.83978 1.6566L13.1731 4.18438C13.6851 4.48306 14 5.03123 14 5.62401V10.3761C14 10.9689 13.6851 11.5171 13.1731 11.8157L8.83978 14.3435C8.32085 14.6462 7.67915 14.6462 7.16021 14.3435L2.82688 11.8157C2.31485 11.5171 2 10.9689 2 10.3761V5.62401C2 5.03123 2.31485 4.48306 2.82688 4.18438L7.16022 1.6566Z"
        fill="#E05843"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33341 8.33325L7.33341 5.49992C7.33341 5.13173 7.63189 4.83325 8.00008 4.83325C8.36827 4.83325 8.66675 5.13173 8.66675 5.49992L8.66675 8.33325C8.66675 8.70144 8.36827 8.99992 8.00008 8.99992C7.63189 8.99992 7.33341 8.70144 7.33341 8.33325Z"
        fill="white"
      />
      <Circle cx="7.99984" cy="10.6666" r="0.833333" fill="white" />
    </Svg>
  );
}

export default MagneticNegativeIcon;
