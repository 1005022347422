import { I18n } from "@meraki/core/i18n";
import { documentationUrl } from "@meraki/go/links";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LINE_HEIGHT, SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import PingResultDescription, {
  PingResultGroup,
} from "~/go/components/liveTools/ping/PingResultDescription";
import PingResultStats from "~/go/components/liveTools/ping/PingResultStats";
import { getResultGroup, PING_RESULT_GROUP } from "~/lib/PingUtils";
import MkiText from "~/shared/components/MkiText";
import Device_DeprecatedType from "~/shared/types/Device";

type Props = {
  averageLatency?: number;
  portNumber: number;
  device: Device_DeprecatedType;
  lossRate?: number;
  onRestartPress: () => void;
  navigate: (screen: string, params: Record<string, unknown>) => void;
};

type State = {
  showTroubleshooting: boolean;
  resultGroup?: PingResultGroup;
};

class PokeDeviceResults extends PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props) {
    const { averageLatency, lossRate } = props;
    const resultGroup = getResultGroup(averageLatency, lossRate);

    return { resultGroup };
  }

  constructor(props: Props) {
    super(props);
    this.state = { showTroubleshooting: true };
  }

  showTestWebsite() {
    const { device, navigate } = this.props;
    navigate("PingWebsite", {
      device,
    });
  }

  showCableTest() {
    const { portNumber, device, navigate } = this.props;
    navigate("CableTest", {
      deviceId: device.id,
      portNumber: portNumber,
    });
  }

  renderTroubleshooting() {
    const { resultGroup, showTroubleshooting } = this.state;

    let description;
    let primaryButtonText;
    let primaryButtonPress;

    if (resultGroup === PING_RESULT_GROUP.ok) {
      description = I18n.t("POKE_DEVICE.PASS.TROUBLESHOOTING.MESSAGE");
      primaryButtonText = I18n.t("POKE_DEVICE.PASS.TROUBLESHOOTING.TEST_WEBSITE");
      primaryButtonPress = () => this.showTestWebsite();
    } else {
      description = I18n.t("POKE_DEVICE.FAIL.TROUBLESHOOTING.MESSAGE");
      primaryButtonText = I18n.t("POKE_DEVICE.FAIL.TROUBLESHOOTING.TEST_CABLE");
      primaryButtonPress = () => this.showCableTest();
    }

    const secondaryButtonText = I18n.t("LIVE_TOOLS.LEARN_MORE");

    const message = <MkiText screenStyles={styles.messageText}>{description}</MkiText>;
    const onExit = () => this.setState({ showTroubleshooting: false });

    return (
      <InlineAlert
        visible={showTroubleshooting}
        onExit={onExit}
        alertTitle={I18n.t("TROUBLESHOOTING_SUGGESTIONS")}
        screenStyles={styles.alert}
        alertMessage={message}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        onPrimaryPress={primaryButtonPress}
        onSecondaryPress={documentationUrl}
      />
    );
  }

  render() {
    const { averageLatency, lossRate, onRestartPress: onButtonPress } = this.props;
    const { resultGroup } = this.state;

    const messages = {
      [PING_RESULT_GROUP.ok]: I18n.t("POKE_DEVICE.PASS.DESCRIPTION"),
      [PING_RESULT_GROUP.loss]: I18n.t("POKE_DEVICE.FAIL.POOR_PERFORMANCE.DESCRIPTION"),
      [PING_RESULT_GROUP.unacceptable]: I18n.t("POKE_DEVICE.FAIL.COMPLETE_FAIL.DESCRIPTION"),
    };

    return (
      <View>
        <PingResultDescription
          {...{
            messages,
            onButtonPress,
          }}
          //@ts-ignore
          resultGroup={resultGroup}
        />
        <PingResultStats averageLatency={averageLatency ?? 0} lossRate={lossRate ?? 0} />
        {this.renderTroubleshooting()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  alert: {
    marginHorizontal: 0,
  },
  messageText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    lineHeight: LINE_HEIGHT.default,
  },
});

export default PokeDeviceResults;
