import { Camera } from "./icons/Camera";
import { CellularGateway } from "./icons/CellularGateway";
import { Environmental } from "./icons/Environmental";
import { Firmware } from "./icons/Firmware";
import { Insight } from "./icons/Insight";
import { Inventory } from "./icons/Inventory";
import { Licensing } from "./icons/Licensing";
import { Navigation } from "./icons/Navigation";
import { NetworkWide } from "./icons/NetworkWide";
import { Organization } from "./icons/Organization";
import { Overview } from "./icons/Overview";
import { SecureConnect } from "./icons/SecureConnect";
import { Security } from "./icons/Security";
import { Switch } from "./icons/Switch";
import { SystemsManager } from "./icons/SystemsManager";
import { TeleworkerGateway } from "./icons/TeleworkerGateway";
import { Uplinks } from "./icons/Uplinks";
import { Wireless } from "./icons/Wireless";

const ICON_MAP = {
  camera: Camera,
  cellularGateway: CellularGateway,
  environmental: Environmental,
  firmware: Firmware,
  insight: Insight,
  inventory: Inventory,
  licensing: Licensing,
  navigation: Navigation,
  networkWide: NetworkWide,
  organization: Organization,
  overview: Overview,
  secureConnect: SecureConnect,
  security: Security,
  switch: Switch,
  systemsManager: SystemsManager,
  teleworkerGateway: TeleworkerGateway,
  uplinks: Uplinks,
  wireless: Wireless,
};

export interface ProductIconProps {
  name: keyof typeof ICON_MAP;
  color?: boolean;
  size?: number;
}

export function ProductIcon({ name, size, color }: ProductIconProps) {
  const Icon = ICON_MAP[name];

  if (!Icon) return null;

  return <Icon width={size ?? 24} height={size ?? 24} color={color} />;
}
