import { useTheme } from "@meraki/core/theme";
import { memo } from "react";
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RadioForm, { RadioButton, RadioButtonInput } from "react-native-simple-radio-button";

import MkiColors from "~/constants/MkiColors";
import { RADIO_BUTTON_SIZE, SPACING } from "~/constants/MkiConstants";
import { CISCO_SANS_FONT } from "~/constants/MkiFonts";
import { RADIO_COLORS, SCREEN_BACKGROUND_COLORS } from "~/enterprise/constants/Colors";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";

const RADIO_OFFSET = appSelect({
  go: 26,
  enterprise: SPACING.default,
});

type RadioTextContainerProps<T extends string | number | null> = Pick<
  RadioSelectionListProps<T>,
  "radioStyle" | "radioTextStyle"
> &
  Pick<RadioItem<T>, "label" | "sublabel" | "textInputComponent" | "disabled" | "context"> & {
    testID?: string;
    onPress: () => void;
  };

const UnmemoizedRadioTextContainer = <T extends string | number | null>(
  props: RadioTextContainerProps<T>,
) => {
  const {
    label,
    sublabel,
    onPress,
    textInputComponent,
    radioStyle,
    radioTextStyle,
    disabled,
    context,
    testID,
  } = props;

  const { theme } = useTheme();

  const subText = sublabel ? (
    <MkiText screenStyles={[styles.label, styles.subtitleLabel, themeColors(theme).text.subheader]}>
      {sublabel}
    </MkiText>
  ) : null;

  const disabledTextColor = disabled ? themeColors(theme).text.disabled : undefined;

  return (
    <TouchableWithoutFeedback onPress={onPress} disabled={disabled}>
      <View style={[styles.labelContainer, radioStyle]} testID={testID}>
        {context && (
          <View style={styles.horizontalContainer}>
            <MkiText
              screenStyles={[styles.label, styles.titleLabel, radioTextStyle, disabledTextColor]}
            >
              {label}
            </MkiText>
            <ContextHelp context={context} />
          </View>
        )}
        {!context && (
          <MkiText
            screenStyles={[styles.label, styles.titleLabel, radioTextStyle, disabledTextColor]}
          >
            {label}
          </MkiText>
        )}
        {subText}
        {textInputComponent && textInputComponent()}
      </View>
    </TouchableWithoutFeedback>
  );
};

const RadioTextContainer = memo(
  UnmemoizedRadioTextContainer,
) as typeof UnmemoizedRadioTextContainer;

type RadioItem<T> = {
  label?: string;
  sublabel?: string;
  value: T;

  textInputComponent?: () => React.ReactNode;
  disabled?: boolean;
  context?: string;
};

type RadioFormItemProps<T extends string | number | null> = Pick<
  RadioSelectionListProps<T>,
  "radioStyle" | "radioTextStyle" | "onSelect"
> & {
  radioItem: RadioItem<T>;
  index: number;
  selected: boolean;
  testID?: string;
};

const UnmemoizedRadioFormItem = <T extends string | number | null>(
  props: RadioFormItemProps<T>,
) => {
  const { radioItem, index, onSelect, selected, testID, radioStyle, radioTextStyle } = props;
  const { value, label, sublabel, disabled, textInputComponent, context } = radioItem;

  const { theme } = useTheme();

  const onPress = () => onSelect(value, index);
  const navColors = themeColors(theme).navigation;

  const selectedColor = appSelect({
    go: MkiColors.primaryButton,
    enterprise: navColors.primary,
  });
  const unselectedColor = appSelect({
    go: MkiColors.secondaryButton,
    enterprise: RADIO_COLORS.unselected,
  });

  const textColor = selected ? selectedColor : unselectedColor;

  const buttonInputStyles =
    sublabel || textInputComponent
      ? [styles.radioInput, radioStyle ? radioStyle : styles.radioMarginExpanded]
      : {};

  const buttonInnerColor = appSelect({
    go: textColor,
    enterprise: SCREEN_BACKGROUND_COLORS.light,
  });

  const borderWidth = appSelect({
    go: undefined,
    enterprise: selected ? SPACING.meager : 1,
  });

  // TODO: Enterprise, Style RadioButtonInput diameter and inner ring to match mocks.
  return (
    <RadioButton testID={testID} style={styles.buttonContainer}>
      <RadioButtonInput
        obj={radioItem}
        index={index}
        isSelected={selected}
        onPress={onSelect}
        buttonSize={RADIO_BUTTON_SIZE}
        buttonInnerColor={buttonInnerColor}
        buttonOuterColor={textColor}
        buttonWrapStyle={buttonInputStyles}
        borderWidth={borderWidth}
        theme={theme}
        disabled={disabled}
      />
      <RadioTextContainer
        label={label}
        sublabel={sublabel}
        onPress={onPress}
        textInputComponent={textInputComponent}
        radioStyle={radioStyle}
        radioTextStyle={radioTextStyle}
        testID={testID}
        disabled={disabled}
        context={context}
      />
    </RadioButton>
  );
};

export const RadioFormItem = memo(UnmemoizedRadioFormItem) as typeof UnmemoizedRadioFormItem;

export type RadioSelectionListProps<T extends string | number | null> = {
  onSelect: (item: T, index?: number) => void;
  options: RadioItem<T>[];
  selectedValue?: T;
  screenStyles?: StyleProp<ViewStyle>;
  radioStyle?: StyleProp<ViewStyle>;
  radioTextStyle?: StyleProp<TextStyle>;
  testID?: string;
  keyExtractor?: (item: RadioItem<T>) => string;
  formHorizontal?: boolean;
};

const UnmemoizedRadioSelectionList = <T extends string | number | null>(
  props: RadioSelectionListProps<T>,
) => {
  const {
    selectedValue,
    onSelect,
    options = [],
    screenStyles = {},
    radioStyle,
    radioTextStyle,
    testID,
    formHorizontal,
    keyExtractor,
  } = props;

  return (
    <RadioForm formHorizontal={formHorizontal} style={[styles.form, screenStyles]} testID={testID}>
      {options.map((radioItem, index) => {
        const selected = options[index].value === selectedValue;
        const itemTestID = testID
          ? `${testID} - ${index}${selected ? ".SELECTED" : ""}`
          : undefined;
        const key = keyExtractor ? keyExtractor(radioItem) : radioItem.value;
        return (
          <RadioFormItem
            key={key}
            radioItem={radioItem}
            radioTextStyle={radioTextStyle}
            index={index}
            onSelect={onSelect}
            selected={selected}
            testID={itemTestID}
            radioStyle={radioStyle}
          />
        );
      })}
    </RadioForm>
  );
};

export const RadioSelectionList = memo(
  UnmemoizedRadioSelectionList,
) as typeof UnmemoizedRadioSelectionList;

const styles = StyleSheet.create({
  form: {
    alignItems: "flex-start",
  },
  radioInput: {
    alignSelf: "flex-start",
  },
  radioMarginExpanded: {
    marginVertical: RADIO_OFFSET,
  },
  labelContainer: {
    flex: 1,
    flexDirection: "column",
    marginLeft: SPACING.default,
    marginVertical: RADIO_OFFSET,
  },
  label: {
    ...CISCO_SANS_FONT,
  },
  titleLabel: {
    fontSize: normalizedFontSize(16),
  },
  subtitleLabel: {
    color: MkiColors.secondaryTextColor,
    fontSize: normalizedFontSize(13),
    marginTop: SPACING.small,
    marginRight: SPACING.large,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...platformSelect({
      ios: {
        marginVertical: -SPACING.small,
      },
      android: undefined,
    }),
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
  },
});

export default RadioSelectionList;
