import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { LineChart } from "@meraki/magnetic/charts";
import { Card, Heading, RefreshControl, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  SESSION_LENGTH,
  TIME_LOWER_BOUND_IDX,
  TOTAL_SESSION_TIME_IDX,
  UNIQUE_VISITORS_IDX,
  useOrgNetwork,
  usePresenceReport,
} from "@meraki/shared/api";
import { useCachedTimestampFormatter } from "@meraki/shared/formatters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { formatDistance, secondsToMilliseconds } from "date-fns";
import { isEmpty } from "lodash";

const MINUTES_IN_AN_HOUR = 60;
const MINUTES_IN_A_DAY = 24 * MINUTES_IN_AN_HOUR;

const countVisitorsInSessionsBetweenBounds = (
  sessions: number[][],
  lowerBound: number,
  upperBound: number,
): number => {
  if (isEmpty(sessions) || (sessions[0]?.length ?? 0) < SESSION_LENGTH) {
    return 0;
  }
  const sumSessions = (total: number, sess: number[]) => total + (sess[UNIQUE_VISITORS_IDX] ?? 0);
  return sessions
    .filter(
      (sess) =>
        (sess[TIME_LOWER_BOUND_IDX] ?? 0) >= lowerBound &&
        (sess[TIME_LOWER_BOUND_IDX] ?? 0) < upperBound,
    )
    .reduce(sumSessions, 0);
};

export const PresenceReportScreen = () => {
  const route = useRoute<RouteProp<InsightsStackProps, "PresenceReport">>();
  const { params: props } = route;
  const organizationId = useCurrentOrganizationId();
  const currentNetworkId = useCurrentNetworkId();
  const networkId = props.networkId ?? currentNetworkId;
  const timespan = useGlobalTimespan();
  const timespanFormatter = useCachedTimestampFormatter(timespan);

  const {
    data: network,
    isFetching: isFetchingNetworks,
    refetch: refetchNetworks,
    isRefetching: networksIsRefetching,
  } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const wirelessNetworkEid = network?.ngIds?.wireless?.ngEid;

  const {
    data: presenceReport,
    isFetching: isFetchingReport,
    refetch: refetchReport,
    isRefetching: reportIsRefetching,
  } = usePresenceReport(
    {
      organizationId,
      networkEid: wirelessNetworkEid ?? "",
      timespan,
    },
    { enabled: !!wirelessNetworkEid },
  );

  let numOfVisitors = 0;
  let numOfPasserBy = 0;
  let totalVisitTime = 0;
  let newVisitors = 0;
  let repeatVisitors = 0;
  const proximityGraphData: Record<string, number>[] = [];
  const engagementGraphData: Record<string, number>[] = [];
  const loyaltyGraphData: Record<string, number>[] = [];
  presenceReport?.forEach(([timestamp, datum]) => {
    const { visitors, passerby } = datum.proximity;
    const { first_timers: firstTime, daily, weekly, monthly, occasional } = datum.visitors;

    numOfVisitors += visitors;
    numOfPasserBy += passerby;
    newVisitors += firstTime;
    repeatVisitors += daily + weekly + monthly + occasional;
    totalVisitTime += datum.sessions.reduce(
      (total, session) =>
        session.length < SESSION_LENGTH ? total : total + session[TOTAL_SESSION_TIME_IDX],
      0,
    );

    proximityGraphData.push({
      timestamp: secondsToMilliseconds(timestamp),
      ...datum.proximity,
    });

    engagementGraphData.push({
      timestamp: secondsToMilliseconds(timestamp),
      fiveToTwentyMin: countVisitorsInSessionsBetweenBounds(datum.sessions, 5, 20),
      twentyToSixtyMin: countVisitorsInSessionsBetweenBounds(
        datum.sessions,
        20,
        MINUTES_IN_AN_HOUR,
      ),
      oneHrToSixHrs: countVisitorsInSessionsBetweenBounds(
        datum.sessions,
        MINUTES_IN_AN_HOUR,
        6 * MINUTES_IN_AN_HOUR,
      ),
      sixPlusHrs: countVisitorsInSessionsBetweenBounds(
        datum.sessions,
        6 * MINUTES_IN_AN_HOUR,
        MINUTES_IN_A_DAY,
      ),
    });

    loyaltyGraphData.push({
      timestamp: secondsToMilliseconds(timestamp),
      firstTime,
      daily,
      weekly,
      occasionally: monthly + occasional,
    });
  });

  const captureRate = numOfVisitors && (numOfVisitors / (numOfPasserBy + numOfVisitors)) * 100;
  const averageVisitTime = totalVisitTime && numOfVisitors && totalVisitTime / numOfVisitors;
  const returningVisitorsPercentage =
    repeatVisitors && (repeatVisitors / (repeatVisitors + newVisitors)) * 100;

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={networksIsRefetching || reportIsRefetching}
          onRefresh={() => {
            refetchNetworks();
            refetchReport();
          }}
        />
      }
      addDefaultPadding
    >
      <Card loading={isFetchingNetworks || isFetchingReport} testID="PRESENCE_REPORT.PROXIMITY">
        <Card.Header
          title={I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.HEADING")}
          description={I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.SUBHEADING")}
        />
        <Card.Content flexDirection="row" justifyContent="space-evenly">
          <Box justifyContent="center" alignItems="center" gap="none">
            <Heading>{numOfVisitors}</Heading>
            <Text size="p4" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.VISITORS")}
            </Text>
          </Box>
          <Box justifyContent="center" alignItems="center" gap="none">
            <Heading>{`${Math.round(captureRate)}%`}</Heading>
            <Text size="p4" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.CAPTURE_RATE")}
            </Text>
          </Box>
        </Card.Content>
        <Card.Content>
          <LineChart
            size="lg"
            data={proximityGraphData}
            xAxisFormatter={timespanFormatter}
            dimensions={[
              { name: "timestamp" },
              {
                name: "visitors",
                displayName: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.PASSERSBY"),
              },
              {
                name: "passerby",
                displayName: I18n.t("LOCATION_ANALYTICS.PROXIMITY_CARD.VISITORS"),
              },
            ]}
          />
        </Card.Content>
      </Card>
      <Card loading={isFetchingNetworks || isFetchingReport} testID="PRESENCE_REPORT.ENGAGEMENT">
        <Card.Header
          title={I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.HEADING")}
          description={I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.SUBHEADING")}
        />
        <Card.Content flexDirection="row" justifyContent="center" gap="2xs">
          <Box justifyContent="center" alignItems="center" gap="none">
            <Heading>
              {formatDistance(0, secondsToMilliseconds(averageVisitTime), { includeSeconds: true })}
            </Heading>
            <Text size="p4" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.AVERAGE_VISIT_LENGTH")}
            </Text>
          </Box>
        </Card.Content>
        <Card.Content>
          <LineChart
            size="lg"
            data={engagementGraphData}
            xAxisFormatter={timespanFormatter}
            dimensions={[
              { name: "timestamp" },
              {
                name: "fiveToTwentyMin",
                displayName: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.FIVE_TWENTY_MIN"),
              },
              {
                name: "twentyToSixtyMin",
                displayName: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.TWENTY_SIXTY_MIN"),
              },
              {
                name: "oneHrToSixHrs",
                displayName: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.ONE_SIX_HRS"),
              },
              {
                name: "sixPlusHrs",
                displayName: I18n.t("LOCATION_ANALYTICS.ENGAGEMENT_CARD.SIX_PLUS_HRS"),
              },
            ]}
          />
        </Card.Content>
      </Card>
      <Card loading={isFetchingNetworks || isFetchingReport} testID="PRESENCE_REPORT.LOYALTY">
        <Card.Header
          title={I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.HEADING")}
          description={I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.SUBHEADING")}
        />
        <Card.Content flexDirection="row" justifyContent="space-evenly">
          <Box justifyContent="center" alignItems="center" gap="none">
            <Heading>{newVisitors}</Heading>
            <Text size="p4" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.NEW_VISITORS")}
            </Text>
          </Box>
          <Box justifyContent="center" alignItems="center" gap="none">
            <Heading>{`${Math.round(returningVisitorsPercentage)}%`}</Heading>
            <Text size="p4" weight="semiBold">
              {I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.RETURNING_VISITORS")}
            </Text>
          </Box>
        </Card.Content>
        <Card.Content>
          <LineChart
            size="lg"
            data={loyaltyGraphData}
            xAxisFormatter={timespanFormatter}
            dimensions={[
              { name: "timestamp" },
              { name: "firstTime", displayName: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.DAILY") },
              { name: "daily", displayName: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.WEEKLY") },
              { name: "weekly", displayName: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.OCCASIONAL") },
              {
                name: "occasionally",
                displayName: I18n.t("LOCATION_ANALYTICS.LOYALTY_CARD.FIRST_TIME"),
              },
            ]}
          />
        </Card.Content>
      </Card>
    </Screen>
  );
};
