import {
  REASSIGN_LICENSE_FAILURE,
  REASSIGN_LICENSE_REQUEST,
  REASSIGN_LICENSE_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { getCurrentOrganization } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function reassignLicense(
  licenseId: string,
  deviceSerial: string | null,
  networkId?: string,
): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());

    if (organizationId == null) {
      return Promise.resolve();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [REASSIGN_LICENSE_REQUEST, REASSIGN_LICENSE_SUCCESS, REASSIGN_LICENSE_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/licenses/${licenseId}`,
        config: {
          method: Method.put,
          body: JSON.stringify({ deviceSerial }),
        },
        meta: {
          organizationId,
          networkId,
        },
      }),
    );
  };
}
