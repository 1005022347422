import { I18n } from "@meraki/core/i18n";
import { Badge, Card, Heading, Loader, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  OrganizationDeviceStatusOverview,
  useOrganizationDeviceStatusOverview,
} from "@meraki/shared/api";
import { ProductIcon } from "@meraki/shared/product-icons";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { snakeCase } from "lodash";
import React from "react";
import { Pressable } from "react-native";

const ICON_MAP = {
  appliance: "security",
  cellularGateway: "cellularGateway",
  switch: "switch",
  wireless: "wireless",
  camera: "camera",
  sensor: "environmental",
  systemsManager: "systemsManager",
} as const;

interface DeviceTypeCardProps {
  productType: keyof typeof ICON_MAP;
  onPress?: (status: OrganizationDeviceStatusOverview["counts"]["byStatus"]) => void;
  onAlertPress?: (status: OrganizationDeviceStatusOverview["counts"]["byStatus"]) => void;
}

export const DeviceTypeCard = ({ productType, onPress, onAlertPress }: DeviceTypeCardProps) => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  const {
    data: deviceStatuses,
    isLoading,
    isError,
  } = useOrganizationDeviceStatusOverview(
    {
      organizationId,
      networkId: networkId,
      body: { productTypes: [productType] },
    },
    {
      select: (data) => data.counts.byStatus,
    },
  );

  const totalCount = Object.values(deviceStatuses ?? {}).reduce((acc, val) => acc + val, 0);

  if (isLoading) {
    return <Loader.Skeleton color="strong" width="48%" height={77} />;
  }

  if (isError) {
    return (
      <Card
        flexDirection="row"
        alignItems="center"
        flexBasis="48%"
        paddingHorizontal="xs"
        onPress={() => undefined}
      >
        <Text>{I18n.t("ERRORS.DEVICE_HEALTH_SERVER_ERROR")}</Text>
      </Card>
    );
  }

  const currentStatus = deviceStatuses ?? {
    offline: 0,
    online: 0,
    alerting: 0,
    dormant: 0,
  };

  const { offline, alerting } = currentStatus;
  return (
    <Card
      flexDirection="row"
      alignItems="center"
      flexBasis="48%"
      paddingHorizontal="xs"
      onPress={() => onPress?.(currentStatus)}
    >
      <ProductIcon name={ICON_MAP[productType]} />
      <Box flex={1}>
        <Heading size="h3">
          {I18n.t(`PRODUCT_TYPE.${snakeCase(productType).toUpperCase()}`)}
        </Heading>
        <Text size="p2" color="light">
          {totalCount}
        </Text>
      </Box>
      {(offline > 0 || alerting > 0) && (
        <Box flexDirection="row">
          <Pressable onPress={() => onAlertPress?.(currentStatus)}>
            <Badge.StackedCounter negative={offline} warning={alerting} />
          </Pressable>
        </Box>
      )}
    </Card>
  );
};
