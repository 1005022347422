import numeral from "@elastic/numeral";

export const MEGABITS_TO_MEBIBITS = 1.024;
export const KILOBITS_IN_MEGABITS = 1000;

// 1024^N where n is the order given in this enum
// DO NOT CHANGE THE ORDER
export enum BinaryPrefixUnits {
  default = 0,
  kibi = 1,
  mebi = 2,
  gibi = 3,
  pebi = 4,
  exbi = 5,
}

const removeTrailingZeroes = (num: number | string) => {
  return parseFloat(num.toString().replace(/\.0*$/, ""));
};

const removeWhitespace = (str: string) => {
  return str.replace(/\s+/g, "");
};

interface FormatOptions {
  isBits?: boolean;
  scale?: BinaryPrefixUnits;
}

export const formatScaled = (bitsOrBytes: number, options: FormatOptions) => {
  const scaledValue = bitsOrBytes * Math.pow(1024, options.scale ?? BinaryPrefixUnits.default);
  const valueWithUnits = numeral(scaledValue).format(options.isBits ? "0.00bitb" : "0.00bb");
  const { value, unit } = parseValueUnit(removeWhitespace(valueWithUnits));

  return `${removeTrailingZeroes(value)}${unit}`;
};

export const formatTransferBits = (bits: number, format = "0.[0] bitd") =>
  numeral(bits).format(format).replace("bit", "b").replace("k", "K");

export const formatBits = (bits: number, scale = BinaryPrefixUnits.default) => {
  const formattedBits = formatScaled(bits, { scale, isBits: true });
  // We're using binary units but formatting the suffix as decimal :sad:
  return formattedBits.replace("ibit", "b");
};

export const formatBytes = (bytes: number, scale = BinaryPrefixUnits.default) => {
  const formattedBytes = formatScaled(bytes, { scale });
  // We're using binary units but formatting the suffix as decimal :sad:
  return formattedBytes.replace("i", "");
};

export interface ParsedValueUnit {
  value: number;
  unit: string;
}

const ZERO_BITS_RESULT = { value: 0, unit: "b" };
export function parseValueUnit(stringWithUnits: string): ParsedValueUnit {
  const value = stringWithUnits.match(/\d+.?\d+|\d+/)?.[0];
  if (value === undefined) {
    return ZERO_BITS_RESULT;
  }

  const unit = stringWithUnits.replace(value, "").trim();
  return { value: parseFloat(value), unit };
}

export const formatKibibytes = (kibibytes: number) => {
  return formatBytes(kibibytes, BinaryPrefixUnits.kibi);
};

export const formatPerSecond = (value: string) => {
  return `${value}/s`;
};

export const formatAndParseKibibytes = (kibibytes: number) => {
  return parseValueUnit(formatKibibytes(kibibytes));
};

const UNIT_EXPONENT_ORDER = "KMGTPE";
// Bits are y axis scale factors from the VictoryAxis, decimal system
export function formatChartBits(bits: number, max: number): ParsedValueUnit {
  const { unit } = parseValueUnit(formatBits(max));
  if (unit === undefined || unit[0] === undefined) {
    return ZERO_BITS_RESULT;
  }

  const e = UNIT_EXPONENT_ORDER.indexOf(unit[0]);
  // + 1 to account for the bit unit
  const scale = unit === "bit" ? 1 : Math.pow(1000, e + 1);

  const scaledBits = bits / scale;
  const formattedBits = (Math.round(scaledBits * 100) / 100).toFixed(2);

  return { value: removeTrailingZeroes(formattedBits), unit: unit === "bit" ? "b" : unit };
}

export const formatKibibytesPerSecond = (value: number) => {
  return formatPerSecond(formatKibibytes(value));
};

export const formatTransferBitsPerSecond = (value: number) => {
  return formatPerSecond(formatTransferBits(value));
};

export const kilobitsToMebibitsInt = (kiloLimit: number | undefined): number | undefined => {
  if (kiloLimit === undefined || kiloLimit === null) {
    return undefined;
  }
  const megaLimit = kiloLimit * (1 / KILOBITS_IN_MEGABITS);
  return Math.floor(megaLimit * (1 / MEGABITS_TO_MEBIBITS));
};
