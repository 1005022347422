import * as React from "react";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { ScrollView } from "react-native";

import { DraggableListProps } from "./types";

const DraggableList = (props: DraggableListProps) => {
  const { data, renderItem, onReorder, listHeader, listFooter, testID } = props;

  const onDragEnd: OnDragEndResponder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    onReorder(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ScrollView testID={testID}>
        {listHeader}
        <Droppable droppableId={`${testID}.LIST`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {data.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderItem(item, snapshot.isDragging)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {listFooter}
      </ScrollView>
    </DragDropContext>
  );
};

export default DraggableList;
