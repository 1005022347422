import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Input, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { queryClient, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useState } from "react";
import { Alert } from "react-native";

import { useSSIDContext } from "../screens/SSIDContext";

interface PasswordBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export function PasswordBottomSheet({ bottomSheetRef }: PasswordBottomSheetProps) {
  const { ssid } = useSSIDContext();
  const { mutate: updateSsid } = useUpdateSsid();
  const [ssidPassword, setSsidPassword] = useState<string | undefined>(ssid?.psk);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const networkId = useCurrentNetworkId();

  if (!ssid || !ssidPassword) {
    return (
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <Loader.Spinner animate={true} />
      </BottomSheet.Modal>
    );
  }

  const onSave = () => {
    updateSsid(
      {
        networkId,
        ssidNumber: ssid.number,
        body: { ...ssid, psk: ssidPassword },
      },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          Alert.alert(
            I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_UPDATE_SUCCESS"),
          );
        },
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useSsids.queryKeyRoot });
          bottomSheetRef?.current?.dismiss();
        },
      },
    );
  };

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_LABEL")}
        cancelLabel={I18n.t("CANCEL")}
        onCancelPress={() => {
          setSsidPassword(ssid.psk);
          bottomSheetRef?.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Box paddingBottom="sm">
          <Input
            value={ssidPassword}
            onChangeText={setSsidPassword}
            secureTextEntry={passwordHidden}
            rightAccessory={
              <Button.Icon
                icon={passwordHidden ? "EyeSlash" : "Eye"}
                onPress={() => setPasswordHidden(!passwordHidden)}
              />
            }
            testID="EDIT_SSID_PASSWORD_INPUT"
          />
        </Box>
        <Box paddingBottom="sm">
          <Button
            text={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_SAVE")}
            onPress={onSave}
            disabled={ssidPassword === ssid.psk}
            testID="SAVE_BUTTON"
          />
        </Box>
        <Button
          text={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_SETTINGS")}
          kind="secondary"
          onPress={() => undefined}
          testID="CHANGE_SETTINGS_BUTTON"
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
