import {
  FETCH_EXPORTS_FAILURE,
  FETCH_EXPORTS_REQUEST,
  FETCH_EXPORTS_SUCCESS,
} from "@meraki/shared/redux";

import { ApiAction, CALL_API } from "~/middleware/api";
import { Method } from "~/shared/types/RequestTypes";

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const fetchAllExports = (networkId: string, jobIdsToPoll?: string): ApiAction => {
  const url = `/n/${networkId}/manage/dashboard/job_data?&scope=ng`;
  const pollParams = new URLSearchParams();
  const freshGetParams = new URLSearchParams();

  pollParams.append("job_action", "fetch");
  pollParams.append("job_ids[]", jobIdsToPoll ? jobIdsToPoll : "");

  freshGetParams.append("job_action", "find");
  freshGetParams.append("job_class", "VideoExportJob");
  freshGetParams.append("limit", "100");

  const endpoint = jobIdsToPoll
    ? `${url}&${pollParams.toString()}`
    : `${url}&${freshGetParams.toString()}`;

  return {
    [CALL_API]: {
      types: [FETCH_EXPORTS_REQUEST, FETCH_EXPORTS_SUCCESS, FETCH_EXPORTS_FAILURE],
      endpoint: endpoint,
      config: {
        method: Method.get,
      },
      meta: { forPolling: jobIdsToPoll != undefined },
    },
  };
};
