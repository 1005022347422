import { useNavigation } from "@react-navigation/native";

import { useSupportCaseInfo } from "~/api/queries/cases/useSupportCaseInfo";
import { SupportCase } from "~/api/schemas/SupportCases";
import SupportCaseListRow from "~/go/components/supportCase/SupportCaseListRow";
import LoadingSpinner from "~/shared/components/LoadingSpinner";

interface SupportCaseListRowWrapperProps {
  supportCase: SupportCase;
  rowId: number;
  testIdSuffix: string;
}

const SupportCaseListRowWrapper = ({
  supportCase,
  rowId,
  testIdSuffix,
}: SupportCaseListRowWrapperProps) => {
  const navigation = useNavigation();

  const caseNumber = supportCase.caseNumber;
  const CaseInfoInteractionsQuery = useSupportCaseInfo(caseNumber);

  switch (CaseInfoInteractionsQuery.status) {
    case "success":
      return (
        <SupportCaseListRow
          supportCase={supportCase}
          interactions={CaseInfoInteractionsQuery.data.interactions}
          onPress={() =>
            navigation.navigate("SupportCaseDetails", {
              supportCase,
              caseNumber,
            })
          }
          testID={`SUPPORT_CENTER.CASE_ROW.${testIdSuffix} - ${rowId}`}
          key={`SUPPORT_CENTER.CASE_ROW.${testIdSuffix} - ${rowId}`}
        />
      );
    default:
    case "loading":
      return <LoadingSpinner visible={true} />;
  }
};

export default SupportCaseListRowWrapper;
