import { I18n } from "@meraki/core/i18n";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import { ExportType, exportTypeSelect } from "~/lib/ExportTemplateUtils";
import { MkiText } from "~/shared/components/MkiText";

interface SelectAllHeaderProps {
  type: ExportType;
  selectAllTapHandler: (type: ExportType, isBoxFilled: boolean) => void;
  selectedSsids: number[];
  ssidsLength: number;
  selectedVlans: number[];
  vlansLength: number;
}

const SelectAllHeader = (props: SelectAllHeaderProps) => {
  const { type, selectAllTapHandler, selectedSsids, ssidsLength, selectedVlans, vlansLength } =
    props;

  const [listHeaderText, isBoxFilled] = exportTypeSelect(
    {
      ssid: [
        I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.SSID_LIST_HEADER"),
        selectedSsids.length === ssidsLength,
      ],
      vlan: [
        I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.VLAN_LIST_HEADER"),
        selectedVlans.length === vlansLength,
      ],
    },
    type,
  );

  return (
    <TouchableOpacity
      style={styles.headerRow}
      onPress={() => selectAllTapHandler(type, isBoxFilled)}
      testID={`SELECT_ALL_HEADER_${type.toUpperCase()}`}
    >
      <MkiText textStyle="secondary">{listHeaderText}</MkiText>
      <CheckBox selected={isBoxFilled} testID={type.toUpperCase()} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: SPACING.default,
  },
});

export default SelectAllHeader;
