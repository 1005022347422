import { I18n } from "@meraki/core/i18n";
import {
  getPeerGraphDataGo,
  SiteToSiteDetailsScreen as MagneticSiteToSiteDetails,
} from "@meraki/go/monitor";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper/lib/typescript/types";
import { useSelector } from "react-redux";

import { SPACING } from "~/constants/MkiConstants";
import { VPN_KEY } from "~/constants/SearchKeys";
import { MerakiVpnPeer, Reachability } from "~/enterprise/types/VpnTypes";
import { HealthStackProps } from "~/go/navigation/Types";
import { appSelect } from "~/lib/PlatformUtils";
import {
  getNetworkName,
  getNetworkPeersStatus,
  getOutageData,
  getPeerLatency,
  productTypeDevicesSelector,
  timespanState,
} from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import {
  getPeerGraphData,
  goMapPeerGraphDataToNonMagneticColors,
  PeerGraphData,
} from "~/shared/components/PeerGraphUtils";
import RegistryInfoCard from "~/shared/components/RegistryInfoCard";
import SingleBarGraph from "~/shared/components/SingleBarGraph";
import StatusIcon from "~/shared/components/StatusIcon";
import TimePicker from "~/shared/components/TimePicker";
import GeneralStatus from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { ProductType } from "~/shared/types/Networks";

interface MerakiVpnPeerData extends MerakiVpnPeer {
  latency?: number;
  isLoading?: boolean;
  data: PeerGraphData[];
}

const GRAPH_HEIGHT = 12;

const renderPeer = (peer: MerakiVpnPeerData, graphWidth: number) => {
  const { reachability, networkName, data, latency } = peer;

  const screenWidthGraphStyle = { width: graphWidth, height: GRAPH_HEIGHT };

  return appSelect({
    enterprise: (
      <View style={styles.peer}>
        <StatusIcon
          status={reachability === Reachability.reachable ? GeneralStatus.good : GeneralStatus.bad}
          testID={networkName}
        />
        <MkiText textStyle="default" screenStyles={styles.networkNameStyle}>
          {networkName}
        </MkiText>
        <SingleBarGraph data={data} roundCorners />
      </View>
    ),
    go: (
      <View style={styles.peerRow}>
        <View style={styles.peerInfo}>
          <View style={styles.leftSide}>
            <StatusIcon
              status={
                reachability === Reachability.reachable ? GeneralStatus.good : GeneralStatus.bad
              }
              testID={networkName}
            />
            <MkiText
              textStyle="default"
              screenStyles={styles.siteName}
              truncation={15}
              ellipsizeMode="tail"
            >
              {networkName}
            </MkiText>
          </View>
          <View style={styles.rightSide}>
            <MkiText textStyle="smallSecondary">
              {I18n.t("VPN_HEALTH_OVERVIEW.AVERAGE_LATENCY")}
            </MkiText>
            <MkiText textStyle="smallBold">
              {I18n.t("VPN_HEALTH_OVERVIEW.LATENCY_VALUE", { latency: latency })}
            </MkiText>
          </View>
        </View>

        <SingleBarGraph data={data} graphStyles={screenWidthGraphStyle} roundCorners />
      </View>
    ),
  });
};

const Header = (numPeers: any, siteName: any) => {
  const headerText = appSelect({
    enterprise: I18n.t("VPN_DETAILS_SCREEN.OVERVIEW_TAB.SUBHEADER", { numPeers }),
    go: I18n.t("VPN_HEALTH_OVERVIEW.SITES_SCREEN.SUBHEADER", { siteName, numPeers }),
  });

  return (
    <View style={styles.header}>
      <MkiText textStyle="subheader">{headerText}</MkiText>
    </View>
  );
};

export type Props = ForwardedNativeStackScreenProps<HealthStackProps, "PeersOverview">;

type PeerWithData = MerakiVpnPeer & {
  latency: number;
  isLoading: boolean;
  data?: PeerGraphData[];
};

export const PeersOverview = (props: Props) => {
  const { isLoading, onRefresh, isRefreshing } = props;
  // the ?? [] shouldn't be necessary because this should return an empty array
  // but typescript is being dumb
  const peers = useSelector(getNetworkPeersStatus) ?? [];
  const peerLatency = useSelector(getPeerLatency);
  const outageData = useSelector(getOutageData);
  const appliances = useAppSelector((state) =>
    productTypeDevicesSelector(state, ProductType.appliance),
  );
  const applianceId = appliances?.[0]?.id;

  const timespan = useSelector(timespanState);
  const networkName = useSelector(getNetworkName);
  const graphWidth = useWindowDimensions().width - SPACING.large;

  const {
    setSearchText,
    setTimespan,
    fetchVpnStatuses,
    fetchPeerStatuses,
    fetchPeerOutageDataWithTimespan,
  } = useActions();

  const [loadingSiteVpnHealth, setLoadingSiteVpnHealth] = useState(false);
  const loadData = async () => {
    setLoadingSiteVpnHealth(true);
    try {
      await fetchVpnStatuses();
      await fetchPeerStatuses();
      await fetchPeerOutageDataWithTimespan(timespan);
    } catch (error) {
      console.warn(error);
    }
    setLoadingSiteVpnHealth(false);
  };

  const handleTimespanChange = async (index: number) => {
    await setTimespan(index);
    loadData();
    return index;
  };

  const onSearchChange = (value: string) => setSearchText(VPN_KEY, value);

  const peersWithData = peers?.map((peer: MerakiVpnPeer) => ({
    ...peer,
    latency: peerLatency ? peerLatency[peer.networkId] : 100,
    isLoading,
    data: appSelect({
      enterprise: getPeerGraphData(outageData?.[peer.networkId] || null, timespan),
      go: goMapPeerGraphDataToNonMagneticColors(
        getPeerGraphDataGo(outageData?.[peer.networkId] || null, timespan),
      ),
    }),
  }));

  const shouldRenderSearch = appSelect({
    enterprise: onSearchChange,
    go: undefined,
  });

  return (
    <>
      {appSelect({
        go: (
          <TimePicker
            timespan={timespan}
            setTimespan={(index) => handleTimespanChange(index)}
            testID="TIME_PICKER"
          />
        ),
        enterprise: null,
      })}
      {loadingSiteVpnHealth ? (
        <View style={styles.loadingContainer} testID={"LOADING_SPINNER"}>
          <MkiSpinner />
        </View>
      ) : (
        <MkiTable<PeerWithData>
          ListHeaderComponent={Header(peers?.length, networkName)}
          onSearchChange={shouldRenderSearch}
          data={peersWithData}
          keyExtractor={(item: MerakiVpnPeer) => item.networkName}
          renderRow={(peer: any) => renderPeer(peer, graphWidth)}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
        />
      )}
      {appSelect({
        go: <RegistryInfoCard applianceId={applianceId} />,
        enterprise: null,
      })}
    </>
  );
};

const styles = StyleSheet.create({
  peerRow: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.large,
  },
  peerInfo: {
    flex: 1,
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: SPACING.small,
  },
  leftSide: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightSide: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  header: {
    padding: SPACING.default,
  },
  siteName: {
    paddingLeft: SPACING.small,
  },
  peer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: SPACING.large,
  },
  networkNameStyle: {
    width: "45%",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export const testables = {
  renderPeer,
  getPeerGraphData,
  getPeerGraphDataGo,
};

const MagneticScreen = appSelect({
  enterprise: PeersOverview,
  go: withMagneticReplacementAdapter(PeersOverview, MagneticSiteToSiteDetails),
});

export default MagneticScreen;
