import { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { portStatusColor } from "~/lib/SwitchPortStyleUtils";
import MerakiIcon, { DEFAULT_ICON_SIZES } from "~/shared/components/icons";
import { useTheme } from "~/shared/hooks/useTheme";

export enum PortIconTypes {
  rj45 = "port",
  sfp = "port-sfp",
}

export enum PortIconSizes {
  extraSmall = "xs",
  large = "l",
}

export interface PortIconProps {
  alerting?: boolean;
  connected?: boolean;
  enabled?: boolean;
  uplink?: boolean;
  poe?: boolean;
  portType: PortIconTypes;
  screenStyles?: StyleProp<ViewStyle>;
  size: PortIconSizes;
}

const UPLINK_ICON_SCALE = 0.3;
const POE_ICON_SCALE = 0.4;

const PortIcon: React.FC<PortIconProps> = (props: PortIconProps) => {
  const { alerting, connected, enabled, portType, screenStyles, size, poe, uplink } = props;
  const iconSize = DEFAULT_ICON_SIZES[size];

  const theme = useTheme();

  const renderStatusIcon = () => (
    <View style={[styles.statusIcon, { width: iconSize, height: iconSize }]}>
      <MerakiIcon
        name={uplink ? "arrow-up" : "bolt"}
        size={iconSize * (uplink ? UPLINK_ICON_SCALE : POE_ICON_SCALE)}
        color={MkiColors.whiteBackground}
        testID={`PORT_STATUS_ICON.${uplink ? "UPLINK" : "BOLT"}`}
      />
    </View>
  );

  return (
    <View>
      <MerakiIcon
        name={portType}
        size={size}
        color={portStatusColor(!enabled, connected, alerting, theme)}
        containerStyle={screenStyles}
        testID="PORT_ICON"
      />
      {uplink || poe ? renderStatusIcon() : null}
    </View>
  );
};

const styles = StyleSheet.create({
  statusIcon: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
  },
});

PortIcon.defaultProps = {
  alerting: false,
  connected: false,
  enabled: true,
  portType: PortIconTypes.rj45,
  screenStyles: {},
  size: PortIconSizes.large,
};

export default memo(PortIcon);
