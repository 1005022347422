import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const DeviceBySerialSchema = z
  .object({
    id: z.string(),
    name: z.nullable(z.string()),
    lat: z.number(),
    lng: z.number(),
    address: z.string(),
    tags: z.array(z.string()),
    networkId: z.string(),
    serial: z.string(),
    model: z.string(),
    mac: z.string(),
    lanIp: z.optional(z.nullable(z.string())),
    firmware: z.string(),
    networkEid: z.string(),
    imei: z.optional(z.number()),
  })
  .describe("DeviceBySerialSchema");

export const UpdateDeviceBySerialSchema = z
  .object({
    address: z.string().optional(),
    floorPlanId: z.string().optional().nullable(),
    name: z.string().optional(),
    notes: z.string().optional(),
    lat: z.number().optional(),
    lng: z.number().optional(),
    tags: z.array(z.string()).optional(),
    firmware: z.string().optional(),
  })
  .describe("UpdateBySerialSchema");

export const UpdateDeviceBySerialResponseSchema = DeviceBySerialSchema.omit({
  id: true,
  networkEid: true,
  imei: true,
})
  .extend({
    floorPlanId: z.string().optional().nullable(),
    url: z.string().optional().nullable(),
  })
  .describe("UpdateBySerialSchema");

export type DeviceBySerial = z.infer<typeof DeviceBySerialSchema>;
export type UpdatedDeviceBySerialBody = z.infer<typeof UpdateDeviceBySerialSchema>;
export type UpdatedDeviceBySerialResponse = z.infer<typeof UpdateDeviceBySerialResponseSchema>;

interface DeviceBySerialRequest {
  serial?: string;
}

export interface DeviceBySerialPayload extends DeviceBySerial {
  moveMapMarker: boolean;
}

interface UpdateDeviceBySerialRequest {
  serial?: string;
  body: Partial<DeviceBySerialPayload>;
}

function buildUrl({ serial }: DeviceBySerialRequest) {
  return `/api/v1/devices/${serial}`;
}

export function fetchDeviceBySerial(variables: DeviceBySerialRequest): Promise<DeviceBySerial> {
  return request(DeviceBySerialSchema, "GET", buildUrl(variables));
}

export function putDeviceBySerial({
  serial,
  body,
}: UpdateDeviceBySerialRequest): Promise<UpdatedDeviceBySerialResponse> {
  return request(UpdateDeviceBySerialResponseSchema, "PUT", buildUrl({ serial }), {
    body: JSON.stringify(body),
  });
}

export const useDeviceBySerial = createQuery<DeviceBySerialRequest, DeviceBySerial>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchDeviceBySerial(variables),
  requiredVariables: ["serial"],
});

export const useUpdateDeviceBySerial = createMutation<
  UpdateDeviceBySerialRequest,
  UpdatedDeviceBySerialResponse,
  APIResponseError
>({
  baseMutationKey: buildUrl({ serial: "{serial}" }),
  mutationFn: (variables) => putDeviceBySerial(variables),
});
