import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import { Theme } from "~/lib/themeHelper";
import StatusIcon from "~/shared/components/StatusIcon";
import { GoStatus } from "~/shared/constants/Status";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

export interface TrackedClientRowData {
  name: string | null;
  subtitle: string;
  onPress: () => void;
  isOnline?: boolean;
  theme: Theme;
  isEditMode: boolean;
}

const TrackedClientRow = (props: TrackedClientRowData) => {
  const { name, subtitle, isOnline, onPress, isEditMode } = props;
  if (!name || name === "") {
    return null;
  }

  const status = isOnline ? GoStatus.good : GoStatus.offline;
  const icon = <StatusIcon status={status} screenStyles={styles.statusIcon} />;
  const deleteIcon = <DeleteButton show={isEditMode} onPress={onPress} hasCustomStyle={true} />;

  return (
    <SimpleDisclosureRow
      subtitle={subtitle}
      icon={isEditMode ? undefined : icon}
      deleteIcon={deleteIcon}
      deleteStyle={styles.deleteIcon}
    >
      {name}
    </SimpleDisclosureRow>
  );
};

const styles = StyleSheet.create({
  statusIcon: {
    marginStart: SPACING.meager,
    marginEnd: SPACING.small,
    marginTop: SPACING.small,
  },
  deleteIcon: {
    position: "absolute",
    paddingStart: SPACING.meager,
  },
});

export default TrackedClientRow;
