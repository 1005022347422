import { ViewStyle } from "react-native";

export type FlexStyle = Pick<
  ViewStyle,
  | "alignContent"
  | "alignItems"
  | "alignSelf"
  | "flex"
  | "flexBasis"
  | "flexDirection"
  | "flexGrow"
  | "flexShrink"
  | "flexWrap"
  | "justifyContent"
>;

export function mapFlexPropsToStyle({
  alignContent,
  alignItems,
  alignSelf,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  flexWrap,
  justifyContent,
  ...rest
}: FlexStyle) {
  const style = {
    alignContent,
    alignItems,
    alignSelf,
    flex,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    justifyContent,
  };

  (Object.keys(style) as Array<keyof typeof style>).forEach(
    (key) => style[key] === undefined && delete style[key],
  );

  return { style, ...rest };
}
