import {
  fetchAllNetworkAccessRoles,
  Organization,
  queryClient,
  SamlLoginResponse,
  SamlOrPasswordLoginResponse,
  useFetchUsers,
  useNFOs,
  useOrganization,
  useOrganizations,
  useOrgNetworks,
} from "@meraki/shared/api";
import { featureFlagClient } from "@meraki/shared/feature-flags";

import { useAuthStore } from "./authStore";

export const isSamlResponse = (
  response: SamlOrPasswordLoginResponse,
): response is SamlLoginResponse => {
  return !!response && "organizationId" in response;
};

export const fetchOrgAndUser = async (response: SamlOrPasswordLoginResponse, email: string) => {
  if (isSamlResponse(response)) {
    const { organizationId } = response;
    // todo: this endpoint apparently doesn't work for camera only admins
    const organization = await queryClient.fetchQuery(
      useOrganization.useQueries({ organizationId }),
    );
    const user = await queryClient.fetchQuery(useFetchUsers.useQueries(undefined));

    return {
      organization,
      user: {
        ...user,
        email: user.email,
      },
      saml: true,
    };
  }

  const organizations = await queryClient.fetchQuery(useOrganizations.useQueries({}));
  const encryptedOrgId = response?.org_eid;
  const organization =
    organizations?.find((org) => org.eid === encryptedOrgId) ?? organizations?.[0];

  return {
    organization,
    user: {
      id: response?.user?.id,
      email,
    },
    saml: false,
  };
};

export const handleSuccessfulLogin = async (
  orgUserData: {
    organization: Organization | undefined;
    user: { id: string | undefined; email: string };
    saml: boolean;
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // todo: bring the dispatch typing logic into nx redux??
  dispatch: (obj: Record<string, any>) => unknown,
  /* eslint-enable @typescript-eslint/no-explicit-any */
) => {
  const { organization, user, saml } = orgUserData;
  const { email, ...userData } = user;
  const networks = await queryClient.fetchQuery(
    useOrgNetworks.useQueries({ organizationId: organization?.id }),
  );

  const networkId = networks?.[0]?.id;

  if (networkId) {
    const roles = await fetchAllNetworkAccessRoles(networks?.[0]);
    const orgWideView = await featureFlagClient.getBool("ld", "organization-wide-context");

    dispatch({ type: "GET_NETWORK_ACCESS_ROLES_SUCCESS", response: roles });

    const currentNetworkId = orgWideView ? null : networkId;

    dispatch({ type: "SET_DEFAULT_NETWORK", currentNetworkId });
    dispatch({ type: "SET_CURRENT_NETWORK", currentNetworkId });
  }

  const nfos = await queryClient.fetchQuery(
    useNFOs.useQueries({ organizationId: organization?.id }),
  );

  dispatch({ type: "CLEAR_VERIFY_EMAIL_CONTEXT" });
  dispatch({ type: "SET_CURRENT_USER", user: email });
  dispatch({ type: "SET_USER_DATA", user: userData });
  dispatch({ type: "SET_IS_SAML_USER", isSamlUser: saml });

  useAuthStore.getState().setLastUser(saml ? "" : email);

  dispatch({ type: "FINISHED_ADDITIONAL_LOGIN_REQUESTS" });
  dispatch({ type: "ORG_CHOSEN" });

  dispatch({ type: "SET_CURRENT_ORGANIZATION", organization: organization?.id });
  dispatch({ type: "SET_CURRENT_SHARD_ID", shardId: organization?.shardId || 1 });

  dispatch({
    type: "GET_NFOS_SUCCESS",
    response: nfos,
    meta: { organizationId: organization?.id },
  });

  dispatch({ type: "ORG_NETWORKS_GET_SUCCESS", response: networks });
};
