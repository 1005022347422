import { useCurrentNetworkId } from "@meraki/shared/redux";

import useFormattedTopology, { NodeById } from "~/api/queries/topology/useFormattedTopology";
import { WrappedScreenComponent } from "~/shared/types/Hocs";

export interface TopologyNodesProps {
  topologyNodes?: NodeById;
  isTopologyLoading: boolean;
}

const withTopologyNodes = <P extends object>(
  WrappedComponent: WrappedScreenComponent<P> & { options?: unknown },
) => {
  const TopologyNodes = (props: P) => {
    const networkId = useCurrentNetworkId();
    const formattedTopologyQuery = useFormattedTopology({ networkId });

    return (
      <WrappedComponent
        {...props}
        topologyNodes={formattedTopologyQuery.data?.nodes}
        isTopologyLoading={formattedTopologyQuery.isLoading}
      />
    );
  };
  return TopologyNodes;
};

export default withTopologyNodes;
