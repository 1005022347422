import { memo } from "react";

import LiveToolsButton from "~/go/components/liveTools/LiveToolsButton";
import TroubleshootingToolsCard from "~/go/components/TroubleshootingToolsCard";
import I18n from "~/i18n/i18n";
import Device_DeprecatedType from "~/shared/types/Device";

export type PortTroubleshootingToolsProps = {
  hosts: {
    ip: string;
    name: string;
  }[];
  device: Device_DeprecatedType;
  portNumber: number;
  navigate: (screen: string, params: Record<string, unknown>) => void;
  lldpMac?: string;
  enabled?: boolean;
};

const PortTroubleshootingTools = memo(function PortTroubleshootingTools(
  props: PortTroubleshootingToolsProps,
) {
  const { lldpMac, portNumber, hosts, device, enabled = true, navigate } = props;

  const showCableTest = () => {
    navigate("CableTest", {
      portNumber,
      deviceId: device.id,
    });
  };

  const showPokeDevice = () => {
    navigate("PokeDeviceStack", {
      screen: "PokeDevice",
      params: {
        host: hosts[0],
        device,
        portNumber,
      },
    });
  };

  const showPokeSelector = () => {
    navigate("PokeDeviceStack", {
      screen: "PokeDeviceSelection",
      params: {
        portNumber,
        serialNumber: device.serial,
        lldpMac,
        device,
      },
    });
  };

  const determineShowScreen = () => {
    if (hosts.length > 1) {
      showPokeSelector();
    } else {
      showPokeDevice();
    }
  };

  return (
    <TroubleshootingToolsCard>
      <LiveToolsButton
        testID={"CABLE_TEST"}
        {...{ enabled }}
        label={I18n.t("CABLE_TEST.TEST_CABLE")}
        name="port"
        onPress={showCableTest}
      />
      <LiveToolsButton
        testID={"POKE_DEVICE"}
        enabled={enabled && hosts != null}
        label={I18n.t("POKE_DEVICE.TITLE")}
        name="mobile"
        onPress={determineShowScreen}
      />
    </TroubleshootingToolsCard>
  );
});

export default PortTroubleshootingTools;
