import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { List, Loader, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import { getTotalReservedIps } from "@meraki/shared/ip-address";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import _ from "lodash";

import { useVlanDetails } from "../VlanDetailsContext";

interface DnsNameMapType {
  [index: string]: string;
}

const DNS_NAME_MAP: DnsNameMapType = {
  custom: "CUSTOM_DNS",
  google_dns: "GOOGLE_DNS",
  opendns: "OPEN_DNS",
  upstream_dns: "UPSTREAM",
};

export const SettingsTab = () => {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();

  const { vlan } = useVlanDetails();
  if (!vlan) {
    return (
      <Screen testID="SETTINGS_TAB">
        <Loader.Spinner testID="SETTINGS_LOADING_SPINNER" />
      </Screen>
    );
  }

  const totalReservedIps = getTotalReservedIps(vlan);

  const dhcpVal = vlan.dhcpHandling === "Run a DHCP server" ? "DEFAULT" : "CUSTOM";
  const dnsNameservers = vlan.dnsNameservers;
  const dnsName = DNS_NAME_MAP[dnsNameservers] ?? DNS_NAME_MAP.custom;

  return (
    <Screen addDefaultPadding testID="SETTINGS_TAB">
      <List>
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("DHCP_SERVER.HEADER")}
          description={I18n.t("DHCP_SERVER.DESCRIPTION")}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {I18n.t(`DHCP_SERVER.GOv3.ROW_LABELS.${dhcpVal}`)}
            </Text>
          }
          onPress={() => {
            navigation.navigate("DHCPServer", { vlanId: vlan.id });
          }}
          testID="DHCP_SERVER_CARD"
        />
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("LOCAL_ADDRESSING.RESERVED_IP_ADDRESSES")}
          rightAccessory={
            <Text size="p1" color={totalReservedIps > 0 ? "interact.text.base" : "light"}>
              {totalReservedIps}
            </Text>
          }
          onPress={() => {
            navigation.navigate("ReservedAddressesList", { vlanId: vlan.id });
          }}
          testID="RESERVED_IP_ADDRESSES_CARD"
        />
      </List>

      <List>
        <List.Item
          leftAccessory={<Icon name="Gear" />}
          title={I18n.t("DNS_SERVER.GOv3.TITLE")}
          description={I18n.t("DNS_SERVER.GOv3.DESCRIPTION")}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {I18n.t(`DNS_SERVER.GOv3.ROW_LABELS.${dnsName}`)}
            </Text>
          }
          onPress={() => {
            navigation.navigate("DnsServer", { vlanId: vlan.id });
          }}
          testID="DNS_SERVER_CARD"
        />
      </List>
    </Screen>
  );
};
