import {
  CHANGE_VLAN_ID_SUCCESS,
  GET_DHCP_SUBNET_SUCCESS,
  GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
  GET_L3_FIREWALL_RULES_SUCCESS,
  GET_NETWORK_SETTINGS_SUCCESS,
  MX_GET_L7_FIREWALL_RULES_SUCCESS,
  MX_PUT_L7_FIREWALL_RULES_SUCCESS,
  MX_TRAFFIC_SHAPING_GET_SUCCESS,
  MX_TRAFFIC_SHAPING_PUT_SUCCESS,
  SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS,
  SET_NETWORK_SETTINGS_SUCCESS,
  UPDATE_L3_FIREWALL_RULES_SUCCESS,
  VLAN_UPDATE_SUCCESS,
  VLANS_CREATE_SUCCESS,
  VLANS_DELETE_SUCCESS,
  VLANS_GET_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { reducerHelper } from "~/lib/ReducerUtils";
import { setIdentifierOnRules } from "~/lib/TrafficShapingUtils";
import { NetworksState } from "~/shared/types/Networks";

const initialState = {};

export default function networks(state: NetworksState = initialState, action: any) {
  // TODO: how do we properly update data without switching orgs?
  switch (action.type) {
    case VLAN_UPDATE_SUCCESS: {
      const { response, meta } = action;
      const { vlanId, networkId } = meta;
      let network = state[networkId];
      const vlanIndex = network?.vlans?.findIndex?.((vlan) => vlan?.id === vlanId);

      if (vlanIndex != null && vlanIndex > -1) {
        network = {
          ...network,
          vlans: [...network.vlans],
        };

        network.vlans[vlanIndex] = response;
      }

      return reducerHelper(state, networkId, network);
    }
    case VLANS_GET_SUCCESS: {
      const vlans = action.response;

      return reducerHelper(state, action.meta.networkId, {
        vlans,
      });
    }
    case VLANS_DELETE_SUCCESS: {
      const { vlanId, networkId } = action.meta;

      const vlans = state?.[networkId]?.vlans || [];
      const newVlans = vlans.filter((vlan) => vlan.id !== vlanId);

      return reducerHelper(state, networkId, {
        vlans: newVlans,
      });
    }
    case VLANS_CREATE_SUCCESS: {
      const { networkId } = action.meta;

      const vlans = state?.[networkId]?.vlans || [];
      const newVlans = [...vlans, action.response];

      return reducerHelper(state, networkId, {
        vlans: newVlans,
      });
    }
    case CHANGE_VLAN_ID_SUCCESS: {
      const { response, meta } = action;
      const { previousId, networkId } = meta;
      if (!response.status.completed || response.status.failed) {
        return state;
      }

      const vlans = state?.[networkId]?.vlans || [];
      const filteredVlans = vlans.filter((vlan) => vlan.id !== previousId);

      const newVlan = response.actions[1].body;
      const newVlans = [...filteredVlans, newVlan];

      return reducerHelper(state, networkId, {
        vlans: newVlans,
      });
    }
    case GET_DHCP_SUBNET_SUCCESS: {
      const dhcpSubnets = action.response;

      return reducerHelper(state, action.meta.networkId, {
        dhcpSubnets,
      });
    }
    case MX_GET_L7_FIREWALL_RULES_SUCCESS:
    case MX_PUT_L7_FIREWALL_RULES_SUCCESS: {
      const { rules } = action.response;

      return reducerHelper(state, action.meta.networkId, { webBlockingRules: rules });
    }
    case MX_TRAFFIC_SHAPING_GET_SUCCESS:
    case MX_TRAFFIC_SHAPING_PUT_SUCCESS: {
      const { rules, defaultRulesEnabled } = action.response;

      return reducerHelper(state, action.meta.networkId, {
        trafficShapingRules: setIdentifierOnRules(rules),
        defaultRulesEnabled,
      });
    }
    case GET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS:
    case SET_GX_TRAFFIC_SHAPING_SETTINGS_SUCCESS: {
      return reducerHelper(state, action.meta.networkId, {
        trafficShapingSettings: { ...action.response },
      });
    }
    case GET_L3_FIREWALL_RULES_SUCCESS:
    case UPDATE_L3_FIREWALL_RULES_SUCCESS: {
      const { response, meta } = action;
      const { networkId } = meta;

      return {
        ...state,
        [networkId]: {
          ...state?.[networkId],
          l3FirewallRules: response?.rules || [],
        },
      };
    }
    case GET_NETWORK_SETTINGS_SUCCESS:
    case SET_NETWORK_SETTINGS_SUCCESS: {
      const { response, meta } = action;
      const { networkId } = meta;

      return {
        ...state,
        [networkId]: {
          ...state?.[networkId],
          settings: response,
        },
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
