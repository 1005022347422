import "intl-pluralrules";

// Disable the following rule because this is the only place we should be importing this package
// eslint-disable-next-line no-restricted-imports
import InternalI18n from "i18next";
import { hasIn } from "lodash";
// Disable the following rule because this is the only place we should be importing this package
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from "react-i18next";
import { PartialDeep } from "type-fest";

import { Locale, LocaleUpper } from "./Locale";
import cs from "./locales/cs.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import frca from "./locales/frca.json";
import hu from "./locales/hu.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import sk from "./locales/sk.json";
import zh from "./locales/zh.json";
import { TranslationKeys } from "./types";

// Disable the following rule because this is the only place we should be importing this package
// eslint-disable-next-line no-restricted-imports
export { useTranslation } from "react-i18next";

export const translations: Record<string, { translation: PartialDeep<typeof en> }> = {
  it: { translation: it },
  en: { translation: en },
  es: { translation: es },
  de: { translation: de },
  fr: { translation: fr },
  ja: { translation: ja },
  frca: { translation: frca },
  ko: { translation: ko },
  nl: { translation: nl },
  pl: { translation: pl },
  pt: { translation: pt },
  ru: { translation: ru },
  zh: { translation: zh },
  hu: { translation: hu },
  cs: { translation: cs },
  sk: { translation: sk },
};

InternalI18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: translations,
});

export const checkForTranslation = (key: string) => {
  return hasIn(en, key);
};

const locales = Object.values(Locale);

// Example: en-US if exists => enUS if not => en if not => original if not
// Found initially on Android but should have no problem using for all platforms
/* eslint-disable @typescript-eslint/no-explicit-any */
const normalizeLocale = (locale: any) => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const regionDashIndex = locale.search(/-|_/);
  if (regionDashIndex === -1) {
    return locale;
  }

  const localeVariants = [
    locale,
    locale.replace(/-|_/, "").toLowerCase(),
    locale.substr(0, regionDashIndex),
  ];

  for (const l of localeVariants) {
    if (locales.includes(l)) {
      return l;
    }
  }

  return locale;
};

const getUntranslatedLanguage = (locale: Locale) => {
  const language = translations[locale];
  const languagesScope = language?.translation.LANGUAGES;
  const localeUpper = LocaleUpper?.[locale];
  const untranslatedLanguage = languagesScope?.[localeUpper];

  return untranslatedLanguage;
};

export const ORIGINAL_LOCALE = normalizeLocale(InternalI18n.language);

function getLanguageLabel(locale: Locale) {
  switch (locale) {
    case "it":
      return InternalI18n.t("LANGUAGES.IT");
    case "en":
      return InternalI18n.t("LANGUAGES.EN");
    case "es":
      return InternalI18n.t("LANGUAGES.ES");
    case "de":
      return InternalI18n.t("LANGUAGES.DE");
    case "fr":
      return InternalI18n.t("LANGUAGES.FR");
    case "ja":
      return InternalI18n.t("LANGUAGES.JA");
    case "frca":
      return InternalI18n.t("LANGUAGES.FRCA");
    case "ko":
      return InternalI18n.t("LANGUAGES.KO");
    case "nl":
      return InternalI18n.t("LANGUAGES.NL");
    case "pl":
      return InternalI18n.t("LANGUAGES.PL");
    case "pt":
      return InternalI18n.t("LANGUAGES.PT");
    case "ru":
      return InternalI18n.t("LANGUAGES.RU");
    case "zh":
      return InternalI18n.t("LANGUAGES.ZH");
    case "hu":
      return InternalI18n.t("LANGUAGES.HU");
    case "cs":
      return InternalI18n.t("LANGUAGES.CS");
    case "sk":
      return InternalI18n.t("LANGUAGES.SK");
  }

  return "";
}

export const getLanguageOptions = () =>
  locales.map((locale) => ({
    label: getLanguageLabel(locale),
    description: getUntranslatedLanguage(locale),
    value: locale,
  }));
export const IS_ON_JAPANESE = ORIGINAL_LOCALE.includes("ja");

export const onJapaneseLocale = () => normalizeLocale(InternalI18n.language).includes("ja");

export const updateLocale = (locale = ORIGINAL_LOCALE) => {
  InternalI18n.changeLanguage(locale);
};

export interface TranslationOptions
  extends Record<string, string | number | null | undefined | boolean> {
  scope?: never;
}

export type TranslatorFunction = {
  (path: string, options?: TranslationOptions): string; // NOTE: This one will be removed eventually to restrict the path to be the full path.
  (path: TranslationKeys, options?: TranslationOptions): string;
};

type MerakiI18n = {
  t: TranslatorFunction;
};

export const I18n: MerakiI18n = {
  t: InternalI18n.t,
};

export const currentLanguage = () => InternalI18n.language;

export const testables = {
  normalizeLocale: normalizeLocale,
};
