import { I18n } from "@meraki/core/i18n";
import { Button, Modal, Notification, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { usePasswordResetRequest, useResetPassword } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showOkayAlert } from "@meraki/shared/native-alert";
import { useState } from "react";

type ForgotPasswordForm = {
  email: string;
};

export const ForgotPassword = ({
  email: initialEmail,
  merakiGo,
}: {
  email: string;
  merakiGo: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string>();
  const goPasswordReset = usePasswordResetRequest();
  const entPasswordReset = useResetPassword();

  const methods = useForm<ForgotPasswordForm>();

  const submit = methods.handleSubmit(() => {
    const passwordReset = merakiGo ? goPasswordReset : entPasswordReset;
    const email = methods.getValues("email");
    passwordReset.mutate(
      { email },
      {
        onSuccess: () => {
          showOkayAlert(I18n.t("FORGOT_PASSWORD.SUCESS_TITLE"), I18n.t("FORGOT_PASSWORD.SUCCESS"));
          setShowModal(false);
        },
        onError: ({ error: err, status }) => {
          if (status === "500") {
            setError(I18n.t("FORGOT_PASSWORD.ERROR_TOO_MANY_REQUESTS"));
          } else {
            setError(err);
          }
        },
      },
    );
  });

  const onClose = () => {
    setShowModal(false);
    setError("");
  };

  return (
    <Box paddingTop="sm">
      <Button
        text={I18n.t("FORGOT_PASSWORD.QUESTION")}
        kind="tertiary"
        onPress={() => {
          setShowModal(true);
          methods.setValue("email", initialEmail);
        }}
      />
      <Modal
        visible={showModal}
        onClose={onClose}
        title={I18n.t("FORGOT_PASSWORD.QUESTION")}
        onCancel={onClose}
        okText={I18n.t("FORGOT_PASSWORD.BUTTON")}
        onOk={submit}
      >
        <Box gap="xs">
          <Text>{I18n.t("FORGOT_PASSWORD.PROMPT")}</Text>
          <Form {...methods}>
            <Form.Input
              name="email"
              keyboardType="email-address"
              label="Email"
              rules={{ required: I18n.t("LOGIN.EMAIL_EMPTY_ERROR") }}
              returnKeyType="go"
              autoCorrect={false}
              onSubmitEditing={submit}
              autoCapitalize="none"
            />
            {error && <Notification.Inline status="negative" message={error} />}
          </Form>
        </Box>
      </Modal>
    </Box>
  );
};
