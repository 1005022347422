import { I18n } from "@meraki/core/i18n";
import { Button, Heading, Notification, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useThemeMode } from "@meraki/magnetic/themes";
import { standardizeLoginResponse, useLogin } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import { Image, KeyboardAvoidingView, Platform, StyleSheet } from "react-native";
import DeviceInfo from "react-native-device-info";

import { CaptchaModal, CaptchaModalMethods } from "../components/CaptchaModal";
import { ClusterPicker } from "../components/ClusterPicker";
import { ForgotPassword } from "../components/ForgotPassword";
import { useAuthStore } from "../libs/authStore";

type LoginForm = {
  email: string;
  password: string;
};

const getLogo = (merakiGo: boolean, themeMode: "light" | "dark") => {
  if (merakiGo) {
    if (Platform.OS === "web") {
      if (themeMode === "light") {
        return require("../../images/logo-go-light-beta.png");
      }
      return require("../../images/logo-go-dark-beta.png");
    }

    if (themeMode === "light") {
      return require("../../images/logo-go-light.png");
    }
    return require("../../images/logo-go-dark.png");
  }

  return require("../../images/logo-enterprise.png");
};

export const LoginScreen = () => {
  const {
    params: { merakiGo },
  } = useRoute<RouteProp<LoginGroupProps, "SharedLogin">>();

  const navigation = useNavigation<NativeStackNavigationProp<LoginGroupProps>>();
  const login = useLogin();
  const captchaModalRef = useRef<CaptchaModalMethods>(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [error, setError] = useState<string>();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const { lastUser } = useAuthStore();
  const theme = useThemeMode();

  const handleLogin = ({ email, password, token }: LoginForm & { token?: string }) => {
    const body = token ? { email, password, token } : { email, password };

    login.mutate(body, {
      onSuccess: (resp) => {
        const response = standardizeLoginResponse(resp);
        if (response?.mode === "captcha") {
          setShowCaptcha(true);
          return;
        }
        if (response?.error) {
          setError(response.error);
          return;
        }
        navigation.navigate("LoginNextSteps", { response, email });
      },
      onError: (err) => {
        if (err?.error) {
          setError(err.error);
        }
      },
    });
  };

  const methods = useForm<LoginForm>({
    defaultValues: { email: lastUser },
  });

  useEffect(() => {
    if (showCaptcha) {
      captchaModalRef.current?.show();
    }
  }, [showCaptcha]);

  // todo: put these in a library
  const isNightly =
    Platform.OS === "web"
      ? document?.location?.origin?.includes("sandbox.meraki.com")
      : DeviceInfo.getBundleId().includes("nightly");
  const isDebug =
    Platform.OS === "web"
      ? document?.location?.origin?.includes("dev.ikarem.io")
      : DeviceInfo.getBundleId().includes("debug");

  const showSamlButton = !merakiGo || __DEV__ || isNightly || isDebug;

  // todo: add idle timeout error message - needs to be pulled out of redux ??
  // todo: go lets you send support emails if your login fails and your email is verified?? do we want to bring this to the new flow?

  return (
    <Screen.SafeAreaView addDefaultPadding>
      <KeyboardAvoidingView
        style={styles.keyboardAvoidingView}
        behavior={Platform.select({
          ios: "padding",
          android: "height",
        })}
      >
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Image style={styles.logo} resizeMode="contain" source={getLogo(merakiGo, theme)} />
          {merakiGo && (
            <Button
              text={I18n.t("LOGIN.ABOUT_GO")}
              kind="tertiary"
              onPress={() => {
                navigation.navigate("Welcome");
              }}
            />
          )}
        </Box>
        <Box flex={1} justifyContent="center" gap="lg">
          <Heading size="h1">{I18n.t("LOGIN.SIGN_IN")}</Heading>
          <Box flexDirection="row" alignItems="center">
            <Text size="p1">{I18n.t("CREATE_ACCOUNT.SUBTITLE")}</Text>
            <Box paddingLeft="xs">
              <Button
                kind="tertiary"
                text="Create an account"
                onPress={() => {
                  navigation.navigate("CreateAccount");
                }}
                analytics={{
                  event: "onPress",
                  eventName: "view_signup_form",
                }}
              />
            </Box>
          </Box>
          <Box gap="sm">
            {error && <Notification.Inline status="negative" message={error} />}
            <Form {...methods}>
              <Form.Input
                name="email"
                rules={{ required: I18n.t("LOGIN.EMAIL_EMPTY_ERROR") }}
                label={I18n.t("LOGIN.EMAIL_PLACEHOLDER")}
                returnKeyType="next"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="email-address"
                onSubmitEditing={() => methods.setFocus("password")}
              />
              <Form.Input
                name="password"
                rules={{ required: I18n.t("LOGIN.PASSWORD_EMPTY_ERROR") }}
                label={I18n.t("LOGIN.PASSWORD_PLACEHOLDER")}
                returnKeyType="go"
                autoCapitalize="none"
                secureTextEntry={passwordHidden}
                rightAccessory={
                  <Button.Icon
                    icon={passwordHidden ? "EyeSlash" : "Eye"}
                    onPress={() => setPasswordHidden(!passwordHidden)}
                  />
                }
                onSubmitEditing={methods.handleSubmit(handleLogin)}
              />
            </Form>
            <Box gap="2xs" paddingTop="sm">
              <Button
                text={I18n.t("LOGIN.LOGIN")}
                onPress={methods.handleSubmit(handleLogin)}
                loading={login.isLoading}
              />
              {showSamlButton && (
                <>
                  <Box alignSelf="center">
                    <Text color="light">{I18n.t("SPSAML.OR")}</Text>
                  </Box>
                  <Button
                    kind="secondary"
                    text={I18n.t("SPSAML.LOGIN_SSO")}
                    onPress={() => navigation.navigate("SpSamlLogin")}
                  />
                </>
              )}
              <ForgotPassword email={methods.watch("email")} merakiGo={merakiGo} />
            </Box>
          </Box>
        </Box>
      </KeyboardAvoidingView>
      <ClusterPicker />
      {showCaptcha && (
        <CaptchaModal
          ref={captchaModalRef}
          onCaptchaComplete={(token?: string) => {
            setShowCaptcha(false);
            handleLogin({ ...methods.getValues(), token });
          }}
          onCaptchaCancelled={() => {
            setShowCaptcha(false);
          }}
        />
      )}
    </Screen.SafeAreaView>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
  logo: {
    height: 36,
    width: 130,
  },
});
