import DeviceInfo from "react-native-device-info";
import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

const PasswordResetResponseSchema = z
  .object({
    success: z.boolean(),
  })
  .describe("PasswordResetResponseSchema");

type PasswordResetResponse = z.infer<typeof PasswordResetResponseSchema>;

type Form = {
  email: string;
};

type PasswordResetError = {
  error: string;
  status?: string;
};

const resetPassword = (formData: Form) => {
  const udid = DeviceInfo.getUniqueId();
  const headers = {
    "X-Mobile-Device-Id": udid,
  };
  return request(PasswordResetResponseSchema, "POST", "/login/reset_password_submit", {
    headers,
    queryParams: formData,
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useResetPassword = createMutation<Form, PasswordResetResponse, PasswordResetError>({
  baseMutationKey: "/login/reset_password_submit",
  mutationFn: (data) => resetPassword(data),
});
