import { I18n } from "@meraki/core/i18n";
import { Button, Card, Text } from "@meraki/magnetic/components";
import { fiveAndSixRadioBandSettings, twoFourRadioBandSettings } from "@meraki/shared/api";

import {
  getChannelLabel,
  getChannelWidthLabel,
  getPowerLabel,
  WirelessBandTypes,
} from "../../utils/RadioUtils";

interface WirelessRadioBandCardProps {
  type: WirelessBandTypes;
  band: twoFourRadioBandSettings | fiveAndSixRadioBandSettings;
  loading: boolean;
  onEditPress: (type: WirelessBandTypes) => void;
}

export const WirelessRadioBandCard = ({
  type,
  band,
  loading,
  onEditPress,
}: WirelessRadioBandCardProps) => {
  const testIdBase = type === "twoFourGhzSettings" ? "2-4GHZ" : "5GHZ";

  const description =
    type === "twoFourGhzSettings"
      ? I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.SUBTITLE", {
          channel: getChannelLabel(band.channel),
        })
      : I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.SUBTITLE", {
          channel: getChannelLabel(band.channel),
          channel_width: getChannelWidthLabel("channelWidth" in band ? band.channelWidth : null),
        });

  return (
    <Card loading={loading} testID={`${testIdBase}_CARD`}>
      <Card.Header
        title={
          type === "twoFourGhzSettings"
            ? I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.LABEL")
            : I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.LABEL")
        }
      />
      <Card>
        <Card.Header
          title={I18n.t("RADIO_SETTINGS.GOv3.TARGET_POWER", {
            target_power: getPowerLabel(band.targetPower),
          })}
          rightAccessory={
            <Button
              text={I18n.t("EDIT")}
              kind="tertiary"
              onPress={() => onEditPress(type)}
              testID={`${testIdBase}_EDIT_BUTTON`}
            />
          }
        />
        <Text color="light" size="p2">
          {description}
        </Text>
      </Card>
    </Card>
  );
};
