import { EmitterSubscription, Linking } from "react-native";

export type AppLinkSource = "app_link" | "push_notification";

async function handlePendingAppLink(handler: (url: string) => void) {
  const url = await Linking.getInitialURL();
  if (url) {
    handler(url);
  }
}

let subscription: EmitterSubscription | undefined;
export async function initAppLinkListener(handler: (url: string) => void) {
  await handlePendingAppLink(handler);
  subscription?.remove();
  subscription = Linking.addEventListener("url", ({ url }) => {
    handler(url);
  });
}
