import { useTheme } from "@meraki/core/theme";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { Dimensions, EmitterSubscription, ScaledSize, StyleSheet, View } from "react-native";
import {
  VictoryArea,
  VictoryAxis,
  VictoryAxisCommonProps,
  VictoryChart,
  VictoryLabel,
} from "victory-native";

import MkiColors from "~/constants/MkiColors";
import {
  DEFAULT_CARD_PADDING_HORIZONTAL,
  DEFAULT_CHART_HEIGHT,
  FULL_WIDTH_LINE_CHART_PADDING,
  LINE_CHART_PADDING,
  SPACING,
  WINDOW,
} from "~/constants/MkiConstants";
import { lineGraphTheme, noDataLabelYOffset } from "~/constants/MkiVictoryTheme";
import I18n from "~/i18n/i18n";
import { isEmptyChartData } from "~/lib/graphUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";

type MkiLineChartProps<T = number> = {
  fullWidth?: boolean;
  chartWidth?: number;
  chartHeight?: number;
  domain?: {
    x?: number[];
    y?: number[];
  };
  primaryData?: { x: T; y: T }[];
  primaryDataKeys?: {
    x?: string | (() => void);
    y?: string | (() => void);
  };
  secondaryData?: { x: T; y: T }[];
  secondaryDataKeys?: {
    x?: string | (() => void);
    y?: string | (() => void);
  };
  secondaryDataColor?: string;
  xAxisFormatter?: VictoryAxisCommonProps["tickFormat"];
  yAxisFormatter?: VictoryAxisCommonProps["tickFormat"];
};

type MkiLineChartState = {
  windowWidth: number;
};

export class MkiLineChart<T> extends PureComponent<MkiLineChartProps<T>, MkiLineChartState> {
  private eventListener?: EmitterSubscription;

  static defaultProps = {
    chartWidth: null,
    chartHeight: DEFAULT_CHART_HEIGHT,
    domain: undefined,
    primaryDataKeys: {},
    secondaryData: [],
    secondaryDataKeys: {},
    secondaryDataColor: MkiColors.secondaryGraphLine,
  };

  state = { windowWidth: Dimensions.get(WINDOW).width };

  componentDidMount() {
    this.eventListener = Dimensions.addEventListener("change", this.handleWindowChange);
  }

  componentWillUnmount() {
    this.eventListener?.remove();
  }

  handleWindowChange = (newDims: { window: ScaledSize }) =>
    this.setState({ windowWidth: newDims.window.width });

  renderLineGraphs() {
    const { primaryData, primaryDataKeys, secondaryData, secondaryDataKeys, secondaryDataColor } =
      this.props;
    const hasSecondaryData = !isEmpty(secondaryData);
    const primaryDataArea = (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <VictoryArea key="PrimaryVictoryArea" data={primaryData} {...primaryDataKeys} />
    );
    if (hasSecondaryData || !isEmpty(secondaryDataKeys)) {
      const secondaryLineStyle = {
        fill: secondaryDataColor,
        fillOpacity: 0.15,
        stroke: secondaryDataColor,
        strokeWidth: 2,
      };
      const normalizedSecondaryData = hasSecondaryData ? secondaryData : primaryData;
      const secondaryDataArea = (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <VictoryArea
          key="SecondaryVictoryArea"
          data={normalizedSecondaryData}
          {...secondaryDataKeys}
          style={{ data: secondaryLineStyle }}
        />
      );

      return [primaryDataArea, secondaryDataArea];
    }
    return primaryDataArea;
  }

  render() {
    const {
      chartWidth,
      chartHeight,
      primaryData,
      domain,
      xAxisFormatter,
      yAxisFormatter,
      fullWidth,
    } = this.props;
    const { windowWidth } = this.state;
    const { theme } = useTheme.getState();

    let normalizedChartWidth;
    if (fullWidth) {
      normalizedChartWidth = windowWidth;
    } else if (chartWidth != null) {
      normalizedChartWidth = chartWidth;
    } else {
      normalizedChartWidth = windowWidth - DEFAULT_CARD_PADDING_HORIZONTAL;
    }

    const padding = fullWidth ? FULL_WIDTH_LINE_CHART_PADDING : LINE_CHART_PADDING;

    const noDataLabel = (
      <VictoryLabel
        x={normalizedChartWidth / 2}
        y={noDataLabelYOffset}
        style={{ ...styles.noDataLabelStyle, fill: MkiColors.secondaryTextColor }}
        text={I18n.t("USAGE_CHART.NO_DATA")}
      />
    );
    const emptyData = isEmptyChartData(primaryData);
    // Marking pointEvents as "none" prevents an error on Android
    // that prevents scroll events when touching the chart.
    const gridStyle = {
      stroke: themeColors(theme).graph?.gridColor || MkiColors.referenceLineColor,
    };

    return (
      <View pointerEvents="none">
        <VictoryChart
          theme={lineGraphTheme}
          width={normalizedChartWidth}
          height={chartHeight}
          padding={padding}
          // @ts-expect-error TS(2322) FIXME: Type '{ x?: number[] | undefined; y?: number[] | u... Remove this comment to see the full error message
          domain={domain}
          categories={[]} // bypass expensive VictoryChart processing with empty categories
        >
          <VictoryAxis
            dependentAxis
            tickFormat={yAxisFormatter}
            style={{
              axis: {
                stroke: "transparent",
              },
              grid: gridStyle,
            }}
          />
          <VictoryAxis
            tickFormat={xAxisFormatter}
            tickCount={5}
            style={{
              axis: {
                stroke: MkiColors.lineChartBorderColor,
              },
            }}
          />
          {emptyData ? noDataLabel : this.renderLineGraphs()}
        </VictoryChart>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  noDataLabelStyle: {
    fontFamily: "ciscosans",
    fontSize: appSelect({
      enterprise: 16,
      go: normalizedFontSize(16),
    }),
    padding: SPACING.small,
  },
});

export default MkiLineChart;
