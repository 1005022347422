import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface ClientConnectionStatsRequest {
  networkId?: string;
  timespan?: number;
  ssid?: number;
  vlan?: number;
  band?: string;
  apTag?: string;
}

const ClientConnectionStatSchema = z.object({
  assoc: z.number(),
  auth: z.number(),
  dhcp: z.number(),
  dns: z.number(),
  success: z.number(),
});

export const ClientBySerialConnectionStatSchema = z.object({
  mac: z.string(),
  connectionStats: ClientConnectionStatSchema,
});
export const ClientConnectionStatsSchema = z.array(ClientBySerialConnectionStatSchema);

export type ClientConnectionStat = z.infer<typeof ClientConnectionStatSchema>;
export type ClientBySerialConnectionStat = z.infer<typeof ClientBySerialConnectionStatSchema>;
export type ClientConnectionStats = z.infer<typeof ClientConnectionStatsSchema>;

const buildUrl = ({ networkId }: ClientConnectionStatsRequest) => {
  return `/api/v1/networks/${networkId}/wireless/clients/connectionStats`;
};

const fetchClientConnectionStats = ({
  networkId,
  ...queryParams
}: ClientConnectionStatsRequest) => {
  return request(ClientConnectionStatsSchema, "GET", buildUrl({ networkId }), { queryParams });
};

export const useClientConnectionStats = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: ClientConnectionStatsRequest) => fetchClientConnectionStats(parameters),
  requiredVariables: ["networkId"],
});
