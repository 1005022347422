import { TRAFFIC_SHAPING_MEBIBITS_INTEGERED } from "@meraki/go/traffic-shaping";
import { calculateSubnetRange } from "@meraki/shared/ip-address";
import { has } from "lodash";
import { createSelector, ParametricSelector } from "reselect";

import { GLOBAL_BANDWIDTH } from "~/constants/Layer7";
import { DHCPHandling } from "~/go/types/NetworksTypes";
import NetworkUtils from "~/lib/NetworkUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { NetworkUsageSlice } from "~/reducers/networkUseblocks";
import { gxDeviceSelector } from "~/selectors/devices";
import {
  currentNetworkState,
  getWarmSpareSettingsState,
  networkHealthState,
  networksState,
  networkUseblocksState,
} from "~/selectors/getters";
import { selectedVlanId } from "~/selectors/navigation";
import { getOrgNetworks } from "~/selectors/orgNetworks";
import { defaultNetworkState, timespanState } from "~/selectors/preferences";
import GeneralStatus from "~/shared/constants/Status";
import { Rule } from "~/shared/types/ApplianceTypes";
import Device from "~/shared/types/Device";
import WarmSpareSettings from "~/shared/types/models/WarmSpareSettings";
import { DHCPSubnet, Network, NetworksState } from "~/shared/types/Networks";
import { ReduxSelector, RootState } from "~/shared/types/Redux";
import { VlanResponse } from "~/shared/types/Vlans";

const emptyRules: any = [];
const emptyRulesForSubnet: any = [];
const emptyVlans: any = [];

const SECURE_CIDR_IPS = { "192.168.0.0/16": true, "172.16.0.0/12": true, "10.0.0.0/8": true };
const DEFAULT_RULE = "Default rule";

const STATUS_RANKING = [
  GeneralStatus.bad,
  GeneralStatus.alerting,
  GeneralStatus.good,
  GeneralStatus.dormant,
];

export const networkHealthSelector = createSelector(
  currentNetworkState,
  networkHealthState,
  (networkId: string | undefined, networkHealth) =>
    networkId
      ? {
          entries: Object.values<number[]>(networkHealth[networkId] || {}),
          t0: networkHealth.t0,
          t1: networkHealth.t1,
        }
      : undefined,
);

export const networkUseblocksSelector = createSelector(
  currentNetworkState,
  timespanState,
  networkUseblocksState,
  (curNetwork: string | undefined, timespan, useblocks: NetworkUsageSlice) => {
    if (curNetwork && has(useblocks.items, [curNetwork, timespan])) {
      return useblocks.items[curNetwork][timespan];
    }
    return undefined;
  },
);

export const makeNetworkUseblocksSelector = () =>
  createSelector(
    currentNetworkState,
    timespanState,
    networkUseblocksState,
    (curNetwork: string | undefined, timespan, useblocks) =>
      curNetwork ? nestedValueExists(useblocks.items, [curNetwork, timespan], null) : undefined,
  );

export const gxNetworkEidSelector = createSelector(
  gxDeviceSelector,
  (gxDevice?: Device) => gxDevice?.networkEid || "",
);

export const dhcpServerEnabledGoSelector = (state: RootState) =>
  dhcpServerEnabledSelector(state, { vlanId: selectedVlanId(state) });

export const gxNetworkSelector = createSelector(
  networksState,
  currentNetworkState,
  (networks: NetworksState, networkId: string | undefined) => {
    if (!networks || !networkId) {
      return null;
    }
    return networks[networkId];
  },
);

export const dhcpServerEnabledSelector = createSelector(
  gxNetworkSelector,
  (_: RootState, props: any) => props.vlanId,
  (network: Network | null, vlanId: number) =>
    NetworkUtils.networkVlanForId(network, vlanId)?.dhcpHandling === DHCPHandling.on,
);

const dhcpSubnetForVlanId = createSelector(
  gxNetworkSelector,
  selectedVlanId,
  (gxNetwork: Network | null, vlanId: number) => {
    if (!gxNetwork || !gxNetwork?.dhcpSubnets) {
      return undefined;
    }
    return Object.values(gxNetwork.dhcpSubnets).find((subnet) => subnet?.vlanId === vlanId);
  },
);

export const getNumberOfUsedIPs = createSelector(
  dhcpSubnetForVlanId,
  (dhcpSubnet: DHCPSubnet | undefined) => {
    if (!dhcpSubnet) {
      return undefined;
    }
    return dhcpSubnet.usedCount;
  },
);

export const getNumberOfFreeIps = createSelector(
  dhcpSubnetForVlanId,
  (dhcpSubnet: DHCPSubnet | undefined) => {
    if (!dhcpSubnet) {
      return undefined;
    }
    return dhcpSubnet.freeCount;
  },
);

export const securityWebBlockingRules = createSelector(
  gxNetworkSelector,
  (gxNetwork: Network | null) => {
    if (!gxNetwork) {
      return null;
    }
    return gxNetwork.webBlockingRules;
  },
);

export const defaultTrafficShapingRulesEnabled = createSelector(
  gxNetworkSelector,
  (gxNetwork: Network | null) => {
    if (!gxNetwork) {
      return false;
    }
    return gxNetwork.defaultRulesEnabled;
  },
);

export const securityTrafficShapingRules = createSelector(
  gxNetworkSelector,
  (gxNetwork: Network | null) => {
    if (!gxNetwork) {
      return null;
    }
    return gxNetwork.trafficShapingRules;
  },
);

export const securityBandwidthLimits = createSelector(
  gxNetworkSelector,
  (gxNetwork: Network | null) => {
    const globalLimit = gxNetwork?.trafficShapingSettings?.globalBandwidthLimits?.limitUp;

    if (globalLimit == null || globalLimit <= 0) {
      return null;
    }

    return {
      identifier: GLOBAL_BANDWIDTH,
      limit: TRAFFIC_SHAPING_MEBIBITS_INTEGERED[globalLimit],
    };
  },
);

export const vlanForSelectedVlanId: ReduxSelector<VlanResponse | undefined> = createSelector(
  gxNetworkSelector,
  selectedVlanId,
  (gxNetwork: Network | null, vlanId) => {
    return NetworkUtils.networkVlanForId(gxNetwork, vlanId);
  },
);

export const currentNetworkSelector = createSelector(
  getOrgNetworks,
  defaultNetworkState,
  (networks, currentNetworkId) => {
    if (!networks) {
      return undefined;
    }
    let workingNetworkId = currentNetworkId;
    if (
      workingNetworkId &&
      networks.filter((n) => NetworkUtils.idsEqual(n.id, workingNetworkId)).length === 1
    ) {
    } else {
      workingNetworkId = networks.slice().sort((a, b) => a.name.localeCompare(b.name))[0].id;
    }
    return workingNetworkId;
  },
);

export const getNetworkWarmSpareSettings = createSelector(
  getWarmSpareSettingsState,
  currentNetworkState,
  (settingsState: Record<string, WarmSpareSettings>, networkId: string | undefined) => {
    return networkId ? settingsState?.[networkId] : undefined;
  },
);

export const getDeviceVirtualIPs = createSelector(getNetworkWarmSpareSettings, (settings) => {
  return (uplinkInterface: string) => {
    // expected key format WAN 1 => wan1, and WAN 2 => wan2, etc
    const key = uplinkInterface.toLowerCase().replace(/\s/g, "");
    //@ts-ignore
    return settings?.[key]?.ip;
  };
});

const getL3FirewallRules = createSelector(
  gxNetworkSelector,
  (network: Network | null) => network?.l3FirewallRules || emptyRules,
);

export const getL3FirewallRulesWithoutDefault = createSelector(getL3FirewallRules, (rules) =>
  rules.filter((rule: any) => rule.comment !== DEFAULT_RULE),
);

export const getL3FirewallRulesForSubnet = createSelector(
  getL3FirewallRules,
  (_: RootState, subnet: string | undefined) => subnet,
  (rules: Rule[], subnet: string | undefined) => {
    if (rules.length === 0 || !subnet) {
      return emptyRulesForSubnet;
    }

    const rulesForSubnet: Rule[] = [];

    rules.forEach((rule) => {
      if (rule.srcCidr === subnet) {
        rulesForSubnet.push(rule);
      }
    });

    return rulesForSubnet;
  },
);

export const getIsNetworkSecured: ParametricSelector<RootState, string | undefined, boolean> =
  createSelector(getL3FirewallRulesForSubnet, (rulesForSubnet: Rule[]) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return Object.values(rulesForSubnet).some((rule) => SECURE_CIDR_IPS[rule.destCidr]);
  });

export const getAllVlans = createSelector(gxNetworkSelector, (gxNetwork) =>
  (gxNetwork?.vlans ?? emptyVlans).map((v: any) => ({ ...v, isWired: true })),
);

export const getNetworkSettings = createSelector(
  networksState,
  currentNetworkState,
  (networks, networkId) => {
    if (networkId == null) {
      return null;
    }

    return networks?.[networkId]?.settings;
  },
);

export const getAllVlanSubnetRanges = createSelector(getAllVlans, (vlans) =>
  vlans.reduce((result: any, { subnet }: any) => {
    const ranges = calculateSubnetRange(subnet);
    if (ranges != null) {
      result.push(ranges);
    }

    return result;
  }, [] as number[][]),
);
