import { I18n } from "@meraki/core/i18n";
import { Heading, SearchBar, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { forwardRef, useImperativeHandle, useState } from "react";
import { FlatList, Pressable, RefreshControlProps } from "react-native";

import { DrawerSelectorItem, ItemType } from "./DrawerSelectorItem";

export interface DrawerSelectorMethods {
  isOpen: () => boolean;
  open: () => void;
  close: () => void;
}

interface DrawerSelectorProps<T> {
  title: string;
  titleIcon?: React.ReactElement;
  items?: T[];
  renderItemIcon?: (item: T) => React.ReactElement;
  selected?: string;
  refreshControl?: React.ReactElement<RefreshControlProps>;
  onSelect: (id: string) => void;
  testID?: string;
  excludeAllInCount?: boolean;
}

function InternalDrawerSelector<T extends ItemType>(
  {
    title,
    titleIcon,
    items,
    renderItemIcon,
    selected,
    refreshControl,
    onSelect,
    testID,
    excludeAllInCount,
  }: DrawerSelectorProps<T>,
  ref: React.ForwardedRef<DrawerSelectorMethods>,
) {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    isOpen: () => isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const currentOrganization = items?.find(({ id }) => id === selected);
  const filteredOrganizations = items?.filter(({ name }) =>
    name.toLowerCase().includes(search.toLowerCase()),
  );
  return (
    <Box padding="sm" bottomDividerBorder testID={testID}>
      <Pressable onPress={() => setIsOpen(!isOpen)}>
        <Box flexDirection="row" gap="sm" alignItems="center">
          {titleIcon}
          <Box flex={1}>
            <Text size="p2" weight="semiBold" color="light">
              {title}
            </Text>
            <Text size="p1" weight="semiBold">
              {currentOrganization?.name}
            </Text>
          </Box>
          <Box>
            <Icon name={isOpen ? "CaretUp" : "CaretDown"} />
          </Box>
        </Box>
      </Pressable>
      {isOpen && filteredOrganizations && (
        <Box paddingTop="xs" gap="xs">
          <SearchBar value={search} onChangeText={setSearch} />
          <Heading size="h4">
            {I18n.t("DRAWER.NETWORK_LIST.ALL", {
              networkCount:
                items?.length && excludeAllInCount ? items?.length - 1 : items?.length ?? 0,
            })}
          </Heading>
          <FlatList
            refreshControl={refreshControl}
            data={filteredOrganizations}
            renderItem={({ item }) => (
              <DrawerSelectorItem<T>
                item={item}
                selected={item.id === selected}
                renderItemIcon={renderItemIcon}
                onPress={onSelect}
                testID={testID && `${testID}.ITEM.${item.id}`}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
}

export const DrawerSelector = forwardRef(InternalDrawerSelector);
