import { Lightning } from "phosphor-react-native";
import { G } from "react-native-svg";

import { IconProps } from "./types";

export function Active({ x, y, color }: IconProps) {
  return (
    <G x={x} y={y}>
      <Lightning weight="bold" color={color} size={20} />
    </G>
  );
}
