import { useTheme } from "@meraki/core/theme";
import { cloneElement } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { CHECKMARK_SIZE, SPACING } from "~/constants/MkiConstants";
import { NESTED_TABLE_MODAL_ROWTYPE } from "~/constants/RowConstants";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

type NestedTableModalRowProps = (
  | {
      disabled: false;
      onPress: () => void;
    }
  | {
      disabled: true;
      onPress?: () => void;
    }
) & {
  children: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
  rowType?: (typeof NESTED_TABLE_MODAL_ROWTYPE)[keyof typeof NESTED_TABLE_MODAL_ROWTYPE];
  disabled?: boolean;
  accessory?: React.ReactElement;
  rowStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

export const NestedTableModalRow = (props: NestedTableModalRowProps) => {
  const { children, subtitle, rowType, disabled, onPress, accessory, rowStyles, testID } = props;

  const { theme } = useTheme();

  let isSelected = rowType === NESTED_TABLE_MODAL_ROWTYPE.isSelected;
  let isParent = rowType === NESTED_TABLE_MODAL_ROWTYPE.isParent;
  if (rowType === NESTED_TABLE_MODAL_ROWTYPE.isSelectedParent) {
    isSelected = true;
    isParent = true;
  }

  const selectedIcon = isSelected ? (
    <View style={styles.selectedIcon} testID={testID && `${testID}.SELECTED`}>
      <MerakiIcon name="done" size={CHECKMARK_SIZE} color={MkiColors.primaryButton} />
    </View>
  ) : (
    <View style={styles.unselectedIcon} />
  );

  const selectedTextStyle = isSelected ? styles.selectedTextColor : null;
  const disabledTextStyle = disabled && !isSelected ? styles.disabledTextColor : null;
  const childShouldShrink = !subtitle ? styles.childText : null;
  const childComponent =
    typeof children === "string" ? (
      <MkiText
        ellipsizeMode="middle"
        screenStyles={[childShouldShrink, selectedTextStyle, disabledTextStyle]}
      >
        {children}
      </MkiText>
    ) : (
      cloneElement(children, { rowIsSelected: isSelected, rowIsDisabled: disabled })
    );

  const subtitleComponent =
    typeof subtitle === "string" ? (
      <MkiText textStyle="smallSecondary" screenStyles={styles.subtitleText}>
        {subtitle}
      </MkiText>
    ) : (
      subtitle
    );

  let childContent = childComponent;
  if (subtitle) {
    childContent = (
      <View style={styles.childWithSubtitle}>
        {childComponent}
        {subtitleComponent}
      </View>
    );
  }

  const caret = isParent ? (
    <MerakiIcon name="chevron-forward" size={22} color={MkiColors.disclosureArrowColor} />
  ) : null;

  let rightContent;
  if ((accessory && isSelected) || isParent) {
    rightContent = (
      <View style={styles.rightContainer}>
        {accessory}
        {caret}
      </View>
    );
  }
  const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
  const containerStyles = [{ backgroundColor }, styles.container, rowStyles];
  const withAccessoryStyles = accessory ? styles.withAccessory : null;
  const viewContent = (
    <View style={containerStyles}>
      <View style={[styles.leftContainer, withAccessoryStyles]}>
        {selectedIcon}
        {childContent}
      </View>
      {rightContent}
    </View>
  );

  if (disabled) {
    return viewContent;
  }

  return (
    <Touchable onPress={onPress} testID={testID}>
      {viewContent}
    </Touchable>
  );
};
NestedTableModalRow.defaultProps = {
  subtitle: null,
  rowType: null,
  disabled: false,
  onPress: undefined,
  accessory: null,
  rowStyles: {},
  testID: undefined,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: SPACING.default,
  },
  leftContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  childWithSubtitle: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  childText: {
    flexShrink: 1,
  },
  selectedTextColor: {
    color: MkiColors.goPurple,
  },
  disabledTextColor: {
    color: MkiColors.secondaryTextColor,
  },
  subtitleText: {
    marginTop: SPACING.tiny,
  },
  selectedIcon: {
    marginRight: SPACING.small,
    alignSelf: "flex-start",
  },
  unselectedIcon: {
    width: CHECKMARK_SIZE,
    height: CHECKMARK_SIZE,
    marginRight: SPACING.small,
    alignSelf: "flex-start",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flexGrow: 0,
    alignItems: "center",
    marginLeft: SPACING.default,
  },
  withAccessory: {
    flexGrow: 0,
    flexShrink: 1,
  },
});

export default NestedTableModalRow;
