import { I18n } from "@meraki/core/i18n";
import { documentationUrl } from "@meraki/go/links";
import { LAYER_7_GROUPS, TRAFFIC_SHAPING_MEBIBITS_INTEGERED } from "@meraki/go/traffic-shaping";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { GLOBAL_BANDWIDTH, PER_SSID_BANDWIDTH } from "~/constants/Layer7";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import DeleteButton from "~/go/components/DeleteButton";
import InlineAlert from "~/go/components/InlineAlert";
import SectionListHeader from "~/go/components/SectionListHeader";
import { UsageScreensPropMap } from "~/go/navigation/Types";
import BandwidthRow from "~/go/rows/BandwidthRow";
import { Features } from "~/go/types/ContextHelpTypes";
import {
  ApplicationRule,
  BandwidthRule,
  SecurityRule,
  SecurityRulesByNumber,
} from "~/go/types/NetworksTypes";
import { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showActionSheet, showAlert, showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { formatTransferBits } from "~/lib/formatHelper";
import { mergeGXRules, mergeSSIDRules, removeRules, UNLIMITED } from "~/lib/TrafficShapingUtils";
import {
  defaultTrafficShapingRulesEnabled,
  perClientBandwidthLimitsOnSSIDsSelector,
  perSSIDBandwidthLimitsSelector,
  securityBandwidthLimits,
  securityTrafficShapingRules,
  slimSsidsByIdSelector,
  trafficShapingRulesOnSSIDsSelector,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import NoDataFooter from "~/shared/components/NoDataFooter";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { SSID } from "~/shared/types/Models";

const GX = "GX";
const isGXFirewall = (sectionId: SectionType) => sectionId === GX;

type Props = ForwardedNativeStackScreenProps<UsageScreensPropMap, "SetUsage"> &
  PendingComponent &
  WithCancelablePromiseProps;

type SectionType = number | string;

export const SetUsageScreen = ({ ssidNumber, setReqPending }: Props) => {
  const { cancelablePromise } = useCancelablePromise();
  const navigation = useNavigation<Props["navigation"]>();
  const [isEditMode, setIsEditMode] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => setIsEditMode(true)} />;
        } else {
          return <DoneButton onPress={() => setIsEditMode(false)} />;
        }
      },
    });
  }, [isEditMode, navigation]);

  const [showQOSCard, setShowQOSCard] = useState(true);

  const networkId = useCurrentNetworkId();
  const ssidsById = useAppSelector(slimSsidsByIdSelector);
  const ssidApplicationLimits = useAppSelector(trafficShapingRulesOnSSIDsSelector);
  const ssidPerClientBandwidthLimits = useAppSelector(perClientBandwidthLimitsOnSSIDsSelector);
  const perSSIDBandwidthLimits = useAppSelector(perSSIDBandwidthLimitsSelector);
  const gxApplicationLimits = useAppSelector(securityTrafficShapingRules);
  const gxDefaultRulesEnabled = useAppSelector(defaultTrafficShapingRulesEnabled);
  const gxBandwidthLimits = useAppSelector(securityBandwidthLimits);
  const actions = useActions();

  const ssidNumbers = Object.keys(ssidsById);

  const getData = useCallback(async () => {
    setReqPending(true);
    const reqs: Promise<unknown>[] = [actions.getSsids(networkId)];
    if (ssidNumber == null) {
      reqs.push(actions.getGXTrafficShapingSettings(), actions.getGXTrafficShapingRules());
      for (const _ssidNumber of ssidNumbers) {
        reqs.push(actions.getSSIDTrafficShapingRules(parseInt(_ssidNumber)));
      }
    } else {
      reqs.push(actions.getSSIDTrafficShapingRules(ssidNumber));
    }
    try {
      await cancelablePromise(Promise.all(reqs));
    } catch (error) {
      showNetworkErrorWithRetry(getData);
    }
    setReqPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const ssidForSection = (sectionId: SectionType): SSID => {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    return ssidsById[sectionId];
  };

  const presentLimitClientUsageScreen = (ssidNumber: number, ssidName: string) => {
    navigation.navigate("LimitClientUsage", {
      ssidNumber,
      onTitle: I18n.t("ON_FOO_SECTION_HEADER", { section_name: ssidName }),
    });
  };

  const presentLimitSSIDUsageScreen = (ssidNumber: number, ssidName: string) => {
    navigation.navigate("LimitSSIDUsage", {
      ssidNumber,
      onTitle: I18n.t("ON_FOO_SECTION_HEADER", { section_name: ssidName }),
    });
  };

  const presentGXLimitClientUsageScreen = () => {
    navigation.navigate("LimitClientUsage", {
      onTitle: I18n.t("SET_USAGE.EVERYWHERE"),
    });
  };

  const presentApplicationListModal = (ssidNumber: number, ssidName: string) => {
    navigation.navigate("LimitApplicationUsageList", {
      ssidNumber,
      onTitle: I18n.t("ON_FOO_SECTION_HEADER", { section_name: ssidName }),
    });
  };

  const presentGXApplicationListModal = () => {
    navigation.navigate("LimitApplicationUsageList", {
      onTitle: I18n.t("SET_USAGE.EVERYWHERE"),
    });
  };

  const editApplicationLimit = (group: string, editLimit: number, sectionId?: number) => {
    let params;
    if (sectionId == null) {
      params = {
        // @ts-expect-error TS(2345): Argument of type '{ onTitle: string; group: string... Remove this comment to see the full error message
        onTitle: I18n.t("SET_USAGE.EVERYWHERE"),
        group,
        editLimit,
      };
    } else {
      const ssid = ssidForSection(sectionId);
      params = {
        ssidNumber: ssid.number,
        onTitle: ssid.name,
        group,
        editLimit,
      };
    }

    navigation.navigate("LimitApplicationUsage", params);
  };

  const editBandwidthLimit = (editLimit: number, sectionId?: number) => {
    let params;
    if (sectionId == null) {
      params = {
        onTitle: I18n.t("SET_USAGE.EVERYWHERE"),
        // @ts-expect-error TS(2345): Argument of type '{ onTitle: string; group: string... Remove this comment to see the full error message
        group: GLOBAL_BANDWIDTH,
        editLimit,
      };
    } else {
      const ssid = ssidForSection(sectionId);
      params = {
        ssidNumber: ssid.number,
        onTitle: ssid.name,
        editLimit,
      };
    }

    navigation.navigate("LimitClientUsage", params);
  };

  const editSSIDBandwidthLimit = (sectionId: number, editLimit: number) => {
    const ssid = ssidForSection(sectionId);

    navigation.navigate("LimitSSIDUsage", {
      ssidNumber: ssid.number,
      onTitle: ssid.name,
      editLimitDown: editLimit,
    });
  };

  const deleteBandwidthLimit = async (sectionId?: number) => {
    try {
      setReqPending(true);
      if (sectionId == null) {
        await cancelablePromise(
          actions.setGXTrafficShapingSettings({
            globalBandwidthLimits: {
              limitUp: UNLIMITED,
              limitDown: UNLIMITED,
            },
          }),
        );
      } else {
        const ssid = ssidForSection(sectionId);
        await cancelablePromise(
          actions.setSsid(networkId, {
            number: ssid.number,
            perClientBandwidthLimitUp: UNLIMITED,
            perClientBandwidthLimitDown: UNLIMITED,
          }),
        );
      }
    } catch (error) {
      if (typeof error === "string") {
        showAlert(error);
      }
    }
    setReqPending(false);
  };

  const updateDefaultTrafficShaping = async (isEnabled: any) => {
    setReqPending(true);
    try {
      await cancelablePromise(
        actions.updateGXTrafficShapingRules({ defaultRulesEnabled: isEnabled }),
      );
    } catch (error) {
      showAlert(error || I18n.t("SERVER_ERROR_TEXT"));
    }
    setReqPending(false);
  };

  const deleteApplicationLimit = async (rowId: number, sectionId?: number) => {
    setReqPending(true);
    try {
      if (sectionId == null) {
        const updatedRules = removeRules(gxApplicationLimits, rowId);
        await cancelablePromise(actions.updateGXTrafficShapingRules({ rules: updatedRules }));
      } else {
        const ssid = ssidForSection(sectionId);
        if (ssid.number != null) {
          const updatedRules = removeRules(ssidApplicationLimits[`${ssid.number}`], rowId);
          await cancelablePromise(actions.updateSSIDTrafficShapingRules(ssid.number, updatedRules));
        } else {
          throw new Error("Unable to update SSID traffic shaping rules, ssid number is undefined");
        }
      }
    } catch (error) {
      showAlert(error || I18n.t("SERVER_ERROR_TEXT"));
    }
    setReqPending(false);
  };

  const deleteSSIDBandwidthLimit = async (sectionId: number) => {
    try {
      setReqPending(true);
      const ssid = ssidForSection(sectionId);
      await cancelablePromise(
        actions.setSsid(networkId, {
          number: ssid.number,
          perSsidBandwidthLimitUp: UNLIMITED,
          perSsidBandwidthLimitDown: UNLIMITED,
        }),
      );
    } catch (error) {
      if (typeof error === "string") {
        showAlert(error);
      }
    }
    setReqPending(false);
  };

  const hasGlobalBandwidthLimit = (rowData: SecurityRule): rowData is BandwidthRule =>
    rowData.identifier === GLOBAL_BANDWIDTH;

  const hasSSIDBandwidthLimit = (rowData: SecurityRule): rowData is BandwidthRule =>
    rowData.identifier === PER_SSID_BANDWIDTH;

  const renderRow = (
    rowData: SecurityRule,
    rowId: number,
    sectionId: SectionType,
  ): JSX.Element | null => {
    const _sectionId = isGXFirewall(sectionId) ? undefined : (sectionId as number);

    if (_sectionId != null && hasSSIDBandwidthLimit(rowData)) {
      return renderSSIDBandwidthLimitRow(rowData, _sectionId);
    } else if (hasGlobalBandwidthLimit(rowData)) {
      return renderGlobalBandwidthLimitRow(rowData, _sectionId);
    } else {
      return renderApplicationLimitRow(rowData, rowId, _sectionId);
    }
  };

  const renderGlobalBandwidthLimitRow = (rowData: BandwidthRule, sectionId?: number) => {
    let deleteTestID, rowTestID;
    if (sectionId == null) {
      deleteTestID = "DELETE_BANDWIDTH_LIMIT.GX";
      rowTestID = "BANDWIDTH_LIMIT.GX";
    } else {
      const ssid = ssidForSection(sectionId);
      const ssidNumber = ssid?.number;
      if (ssidNumber == null) {
        return null;
      }
      deleteTestID = `DELETE_BANDWIDTH_LIMIT.GR-${ssidNumber}`;
      rowTestID = `BANDWIDTH_LIMIT.GR-${ssidNumber}`;
    }

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => deleteBandwidthLimit(sectionId)}
        testID={deleteTestID}
      />
    );
    return (
      <BandwidthRow
        title={I18n.t("SET_USAGE.PER_CLIENT_BANDWIDTH_LIMIT_TITLE", {
          client_word: I18n.t("DEVICE_WORD"),
        })}
        subtitle={I18n.t("SET_USAGE.LIMIT_SUBTITLE", {
          limit: `${formatTransferBits(rowData.limit)}`,
          client_word: I18n.t("DEVICE_WORD"),
        })}
        deleteIcon={deleteIcon}
        onPress={() => editBandwidthLimit(rowData.limit, sectionId)}
        testID={rowTestID}
      />
    );
  };

  const renderApplicationLimitRow = (
    rowData: ApplicationRule,
    rowId: number,
    sectionId?: number,
  ) => {
    const group = rowData.identifier;
    const groupPayload = LAYER_7_GROUPS[group];
    if (!groupPayload) {
      return null;
    }

    const title = I18n.t(groupPayload.displayName || groupPayload.name);

    let deleteTestID, rowTestID;
    if (sectionId == null) {
      deleteTestID = `DELETE_APPLICATION_LIMIT.GX.${title}`;
      rowTestID = `APPLICATION_LIMIT.GX.${title}`;
    } else {
      const ssid = ssidForSection(sectionId);
      const ssidNumber = ssid?.number;
      if (ssidNumber == null) {
        return null;
      }
      deleteTestID = `DELETE_APPLICATION_LIMIT.GR-${ssidNumber}.${title}`;
      rowTestID = `APPLICATION_LIMIT.GR-${ssidNumber}.${title}`;
    }

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => deleteApplicationLimit(rowId, sectionId)}
        testID={deleteTestID}
      />
    );

    const bandwidthLimit =
      TRAFFIC_SHAPING_MEBIBITS_INTEGERED[
        rowData.perClientBandwidthLimits.bandwidthLimits?.limitUp ?? 0
      ];
    const bandwidthSubtitle =
      rowData.perClientBandwidthLimits.settings == "custom"
        ? I18n.t("SET_USAGE.LIMIT_SUBTITLE", {
            limit: `${formatTransferBits(bandwidthLimit)}`,
            client_word: I18n.t("DEVICE_WORD"),
          })
        : I18n.t("SET_USAGE.QOS_APPLIED");

    // Application limits are stored in mega units,
    // but our row rendering expects kilo units.

    return (
      <BandwidthRow
        title={title}
        subtitle={bandwidthSubtitle}
        deleteIcon={deleteIcon}
        onPress={() => editApplicationLimit(group, bandwidthLimit, sectionId)}
        testID={rowTestID}
      />
    );
  };

  const renderSSIDBandwidthLimitRow = (rowData: BandwidthRule, sectionId: number) => {
    const ssid = ssidForSection(sectionId);
    const { number } = ssid;
    if (number == null) {
      return null;
    }

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => deleteSSIDBandwidthLimit(sectionId)}
        testID={`DELETE_SSID_BANDWIDTH_LIMIT.GR-${number}`}
      />
    );

    return (
      <BandwidthRow
        title={I18n.t("SET_USAGE.PER_SSID_BANDWIDTH_LIMIT_TITLE")}
        // limit in Kbps but row rendering expects bps
        subtitle={I18n.t("SET_USAGE.SSID_LIMIT_SUBTITLE", {
          limit: `${formatTransferBits(rowData.limit)}`,
        })}
        deleteIcon={deleteIcon}
        onPress={() => {
          editSSIDBandwidthLimit(sectionId, rowData.limit);
        }}
        testID={`SSID_BANDWIDTH_LIMIT.GR-${number}`}
      />
    );
  };

  const renderSectionHeader = ({ section }: any) => {
    if (isGXFirewall(section.key)) {
      return renderGXSectionHeader();
    }
    return renderGRSectionHeader(section.key);
  };

  const renderGXSectionHeader = () => {
    const onPress = () =>
      showActionSheet(
        [
          I18n.t("SET_USAGE.SET_LIMIT_FOR_GX_ACTION", { clients_word: I18n.t("DEVICES_WORD") }),
          I18n.t("SET_USAGE.SET_LIMIT_BY_APPLICATION_ACTION"),
        ],
        (id: number) => {
          if (id === 0) {
            presentGXLimitClientUsageScreen();
          } else if (id === 1) {
            presentGXApplicationListModal();
          }
        },
        { title: I18n.t("SET_USAGE.TITLE") },
      );
    return (
      <SectionListHeader
        heading={I18n.t("BLOCK_CONTENT.EVERYWHERE")}
        onPress={onPress}
        withHorizontalMargin
        testID="GX"
      />
    );
  };

  const renderGRSectionHeader = (sectionId: number) => {
    const ssid = ssidForSection(sectionId);
    const { name, number } = ssid;
    if (number === undefined) {
      return null;
    }
    const onPress = () =>
      showActionSheet(
        [
          I18n.t("SET_USAGE.SET_LIMIT_FOR_SSID_ACTION", {
            clients_word: I18n.t("DEVICES_WORD"),
            ssid_name: name,
          }),
          I18n.t("SET_USAGE.SET_LIMIT_BY_APPLICATION_ACTION"),
          I18n.t("SET_USAGE.SET_LIMIT_PER_SSID", { ssid_name: name }),
        ],
        (id: number) => {
          if (id === 0) {
            presentLimitClientUsageScreen(number, name);
          } else if (id === 1) {
            presentApplicationListModal(number, name);
          } else if (id === 2) {
            presentLimitSSIDUsageScreen(number, name);
          }
        },
        { title: I18n.t("SET_USAGE.TITLE") },
      );
    const sectionName = I18n.t("ON_SSID_SECTION_HEADER", {
      section_name: ssid.name,
    });
    return (
      <SectionListHeader
        heading={sectionName}
        onPress={onPress}
        icon={gxDefaultRulesEnabled ? "" : "add"}
        withHorizontalMargin
        testID={`GR-${number}`}
      />
    );
  };

  const hasGXData = () => {
    return gxApplicationLimits || gxBandwidthLimits;
  };

  const ssidTrafficShaping = (): SecurityRulesByNumber => {
    return mergeSSIDRules(
      ssidApplicationLimits,
      ssidPerClientBandwidthLimits,
      perSSIDBandwidthLimits,
      ssidNumber,
    );
  };

  const gxTrafficShaping = (): SecurityRule[] => {
    return mergeGXRules(gxApplicationLimits, gxBandwidthLimits);
  };

  const makeTableData = (): SecurityRulesByNumber => {
    const data = ssidTrafficShaping();
    if (data && hasGXData() && ssidNumber == null) {
      data[GX] = gxTrafficShaping();
    }
    if (gxDefaultRulesEnabled) {
      const defaultData = {};
      for (const k of Object.keys(data)) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defaultData[k] = [];
      }
      return defaultData;
    }
    return data;
  };

  const closeQOSCard = () => setShowQOSCard(false);

  const renderHeader = () => {
    return (
      <>
        <View style={[styles.rowStyles, styles.horizontalContainer]}>
          <MkiText screenStyles={styles.headerStyle}>{I18n.t("SET_USAGE.SUBTITLE")}</MkiText>
          <ContextHelp context={Features.usageLimits} />
        </View>
        <SwitchRow
          context={Features.defaultQoS}
          value={gxDefaultRulesEnabled}
          onValueChange={(val) => updateDefaultTrafficShaping(val)}
        >
          {I18n.t("SET_USAGE.DEFAULT_TRAFFIC_SHAPING.TOGGLE.TITLE")}
        </SwitchRow>
        <InlineAlert
          visible={showQOSCard}
          onExit={closeQOSCard}
          alertTitle={I18n.t("SET_USAGE.QOS.TITLE")}
          alertMessage={I18n.t("SET_USAGE.QOS.MESSAGE")}
          primaryButtonText={I18n.t("LEARN_MORE")}
          onPrimaryPress={() => documentationUrl()}
          screenStyles={styles.qosCard}
        />
      </>
    );
  };

  const renderSectionFooter = ({ section }: any) => {
    return <NoDataFooter data={section.data} noDataString={I18n.t("SET_USAGE.NO_SETTINGS")} />;
  };

  const sortKeyComparator = (key1: string, key2: string) => {
    if (key1 === GX) {
      return -1;
    }
    if (key2 === GX) {
      return 1;
    }
    return parseInt(key1) - parseInt(key2);
  };

  return (
    <View style={styles.container} testID="SET_USAGE_SCREEN">
      <View style={styles.tableContainer}>
        {gxDefaultRulesEnabled ? (
          <ScrollView>
            {renderHeader()}
            <MkiText
              textStyle="secondary"
              screenStyles={styles.rowStyles}
              testID={"DEFAULT_TRAFFIC_SHAPING_ENABLED_MESSAGE"}
            >
              {I18n.t("SET_USAGE.DEFAULT_TRAFFIC_SHAPING.ENABLED_MESSAGE")}
            </MkiText>
          </ScrollView>
        ) : (
          <MkiTable<SecurityRule>
            data={makeTableData()}
            ListHeaderComponent={renderHeader}
            keyExtractor={(item: any): string => item.identifier.toString()}
            // @ts-ignore MkiTable wants sectionId to be optional but its expected to exist here
            renderRow={(rowData: SecurityRule, rowId: number, sectionId: SectionType) =>
              renderRow(rowData, rowId, sectionId)
            }
            renderSectionHeader={({ section }: any) => renderSectionHeader({ section })}
            renderSectionFooter={renderSectionFooter}
            sortKeyComparator={sortKeyComparator}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  qosCard: {
    marginTop: SPACING.small,
  },
});

export default withPendingComponent(SetUsageScreen);
