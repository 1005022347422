import { useMemo } from "react";

import { ScreenTrace, TraceTypes } from "~/lib/PerformanceUtils";

export const usePerfTrace = (
  screenName: string,
  traceType: TraceTypes = TraceTypes.totalLoad,
): [ScreenTrace, () => Promise<void>] => {
  return useMemo(() => {
    const perfTrace = new ScreenTrace(screenName, traceType);
    return [perfTrace, perfTrace.stopTime];
  }, [screenName, traceType]);
};
