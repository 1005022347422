import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useRef, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import {
  useAddSupportCaseCommentMutation,
  useAttachFileToSupportCase,
  useSupportCaseInfo,
} from "~/api/queries/cases/useSupportCaseInfo";
import { useCloseSupportCaseMutation } from "~/api/queries/cases/useSupportCases";
import { CaseInteraction } from "~/api/schemas/SupportCaseInfo";
import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING, SUPPORT_CASE_DEFAULT_NAME } from "~/constants/MkiConstants";
import IconBanner from "~/go/components/IconBanner";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import SupportCaseMessage from "~/go/components/supportCase/SupportCaseMessage";
import SupportCaseStatus from "~/go/components/supportCase/SupportCaseStatus";
import { ALERT_BUTTONS, showAlert } from "~/lib/AlertUtils";
import { formatDateString } from "~/lib/formatHelper";
import { cleanSupportMessageText } from "~/lib/StringUtils";
import { getNetworkTimezone } from "~/selectors";
import AttachFileButton, { FileAttachment } from "~/shared/components/AttachFileButton";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import PaddedKeyboardAvoidingView from "~/shared/components/PaddedKeyboardAvoidingView";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useThemeColors } from "~/shared/hooks/useTheme";
import {
  CaseInfoInteractionTypes,
  SupportCaseStatusOptions,
} from "~/shared/types/SupportCaseTypes";

import { SettingsStackProps } from "../navigation/Types";

const MARGIN_TO_VERTICALLY_CENTER_TEXT_INPUT = -6;

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SupportCaseDetails">;

const SupportCaseDetailsScreen = (props: Props) => {
  const { supportCase, caseNumber } = props;
  const themeColors = useThemeColors();
  const timezone = useAppSelector(getNetworkTimezone);

  const caseInteractionQuery = useSupportCaseInfo(caseNumber);
  const caseInteractions = useSupportCaseInfo(caseNumber).data?.interactions;
  const addCaseCommentMutation = useAddSupportCaseCommentMutation(caseNumber);
  const attachFileToCaseMutation = useAttachFileToSupportCase();
  const closeCaseMutation = useCloseSupportCaseMutation(caseNumber);

  const possibleUserNames = supportCase.suppliedName
    ? [supportCase.contactName, supportCase.suppliedName]
    : [supportCase.contactName];

  const [replyMessage, setReplyMessage] = useState<string>();
  const [file, setFile] = useState<FileAttachment>();
  const messageListRef = useRef<FlatList>(null);
  const navigation = useNavigation<Props["navigation"]>();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: I18n.t("SUPPORT_CASE_DETAILS.TITLE", { caseNumber }),
    });
  }, [caseNumber, navigation]);

  const openCloseCaseButtonAlert = (title: any, message: any) =>
    showAlert(
      title,
      message,
      () => {
        closeCaseMutation.mutate(supportCase.status != SupportCaseStatusOptions.closed, {
          onSettled: navigation.goBack,
        });
      },
      {
        negativeText: ALERT_BUTTONS.cancel,
      },
    );

  const scrollToBottom = () => messageListRef.current?.scrollToEnd({ animated: true });

  const sendComment = () => {
    if (replyMessage) {
      try {
        if (file != null) {
          attachFileToCaseMutation.mutate({
            file: file.content,
            filename: file.name,
            case_number: caseNumber,
          });

          addCaseCommentMutation.mutate({
            type: CaseInfoInteractionTypes.comment,
            createdByName: "Dashboard",
            preview: "",
            createdDate: "",
            commentBody: `${replyMessage}\n\nAttached: ${file.name}`,
          });
        } else {
          addCaseCommentMutation.mutate({
            type: CaseInfoInteractionTypes.comment,
            createdByName: "Dashboard",
            preview: "",
            createdDate: "",
            commentBody: replyMessage,
          });
        }

        setReplyMessage("");
        setFile(undefined);
      } catch (error) {
        showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
      } finally {
      }
    }
  };

  const openCloseCaseButton = () => {
    const buttonTranslation =
      supportCase.status === SupportCaseStatusOptions.closed
        ? I18n.t("SUPPORT_CASE_DETAILS.REOPEN_CASE.BUTTON")
        : I18n.t("SUPPORT_CASE_DETAILS.CLOSE_CASE.BUTTON");
    const messageTranslation =
      supportCase.status === SupportCaseStatusOptions.closed
        ? I18n.t("SUPPORT_CASE_DETAILS.REOPEN_CASE.MESSAGE")
        : I18n.t("SUPPORT_CASE_DETAILS.CLOSE_CASE.MESSAGE");
    return (
      <RoundedButton
        disabled={
          addCaseCommentMutation.isLoading ||
          attachFileToCaseMutation.isLoading ||
          closeCaseMutation.isLoading
        }
        buttonType={ButtonType.secondary}
        onPress={() => openCloseCaseButtonAlert(buttonTranslation, messageTranslation)}
        screenStyles={styles.closeButton}
        testID={`${
          supportCase.status === SupportCaseStatusOptions.closed ? "REOPEN_CASE" : "CLOSE_CASE"
        }_BUTTON`}
      >
        {buttonTranslation}
      </RoundedButton>
    );
  };

  const renderHeader = () => {
    const { subject, status, createdDate } = supportCase;

    const cleanedDate = formatDateString(createdDate, timezone).split(" ")[0];

    return (
      <View style={styles.headerContainer}>
        <MkiText textStyle="heading">{subject}</MkiText>
        <View style={styles.subheaderContainer}>
          <View style={styles.leftSide}>
            <View style={styles.statusContainer}>
              <MkiText textStyle="smallSecondary">
                {I18n.t("SUPPORT_CASE_DETAILS.HEADER.STATUS")}
              </MkiText>
              <SupportCaseStatus status={status} />
            </View>

            <MkiText textStyle="smallSecondary">
              {I18n.t("SUPPORT_CASE_DETAILS.HEADER.CREATED", { createdDate: cleanedDate })}
            </MkiText>
          </View>
          {openCloseCaseButton()}
        </View>
      </View>
    );
  };

  const renderComment = (item: CaseInteraction, index: number) => {
    const { commentBody, textBody } = item;

    const messageText = commentBody ?? textBody;

    const cleanedMessageBody = cleanSupportMessageText(messageText, "bubble");

    if (cleanedMessageBody) {
      return (
        <SupportCaseMessage
          possibleUserNames={possibleUserNames}
          comment={{ ...item, commentBody: cleanedMessageBody }}
          testID={`MESSAGE_BUBBLE.${index}`}
        />
      );
    }
    return <></>;
  };

  const renderBody = () => {
    const { description, createdDate, status } = supportCase;

    let formattedCommentsList = caseInteractions ? [...caseInteractions] : [];
    if (description) {
      const cleanedDescription = description.split("----------")[0].trim();

      const descriptionComment: CaseInteraction = {
        commentBody: cleanedDescription,
        createdDate,
        createdByName: SUPPORT_CASE_DEFAULT_NAME,
        type: CaseInfoInteractionTypes.comment,
        preview: "",
      };

      formattedCommentsList = formattedCommentsList.concat(descriptionComment);
    }

    return (
      <View style={styles.bodyContainer}>
        <FlatList
          ref={messageListRef}
          onLayout={() => scrollToBottom()}
          data={formattedCommentsList.reverse()}
          renderItem={({ item, index }) => renderComment(item, index)}
          keyExtractor={(_, index) => index.toString()}
          ListFooterComponent={
            status == SupportCaseStatusOptions.closed ? null : (
              <IconBanner
                bannerType="informational"
                alertText={I18n.t("SUPPORT_CASE_DETAILS.RESPONSE_TIME_BANNER")}
                screenStyles={styles.bannerPadding}
                canClose={true}
                testID="RESPONSE_BANNER"
              />
            )
          }
        />
      </View>
    );
  };

  const renderMessagingFooter = () => {
    const { status } = supportCase;
    return status == SupportCaseStatusOptions.closed ? null : (
      <View style={styles.footerContainer} testID="MESSAGING_FOOTER">
        <AttachFileButton
          setFile={setFile}
          hasFile={file != null}
          containerStyle={styles.attachButtonContainer}
          testID="ATTACH_FILE_BUTTON"
        />
        <View
          style={[styles.messagingContainer, { backgroundColor: themeColors.border.borderColor }]}
        >
          <View style={styles.inputContainer}>
            <MkiTextInput
              screenStyles={styles.inputField}
              placeholder={I18n.t("SUPPORT_CASE_DETAILS.COMMENT_PLACEHOLDER")}
              value={replyMessage}
              onChangeText={(newText: any) => setReplyMessage(newText)}
              multiline={true}
            />
          </View>
          <View style={styles.sendButtonContainer}>
            <RoundedButton
              disabled={
                addCaseCommentMutation.isLoading ||
                attachFileToCaseMutation.isLoading ||
                closeCaseMutation.isLoading
              }
              buttonType={ButtonType.primary}
              onPress={sendComment}
              screenStyles={styles.sendButton}
              testID="submit button"
            >
              ↑
            </RoundedButton>
          </View>
        </View>
      </View>
    );
  };

  return (
    <PaddedKeyboardAvoidingView>
      {renderHeader()}
      {renderBody()}
      {renderMessagingFooter()}
      <LoadingSpinner
        visible={
          caseInteractionQuery.isFetching ||
          addCaseCommentMutation.isLoading ||
          attachFileToCaseMutation.isLoading ||
          closeCaseMutation.isLoading
        }
      />
    </PaddedKeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  // header
  headerContainer: {
    flexDirection: "column",
    paddingHorizontal: SPACING.default,
  },
  subheaderContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: SPACING.meager,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  leftSide: {
    flexDirection: "column",
  },
  closeButton: {
    paddingHorizontal: SPACING.default,
  },
  // body
  bodyContainer: {
    flex: 1,
    flexDirection: "column",
    alignContent: "flex-end",
    paddingHorizontal: SPACING.default,
  },
  bannerPadding: {
    marginTop: SPACING.large,
  },

  //footer
  footerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: SPACING.default,
    paddingVertical: SPACING.small,
  },
  attachButtonContainer: {
    flex: 1,
  },
  messagingContainer: {
    flex: 8,
    borderRadius: BUTTON_SIZING.borderRadius.large,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: SPACING.small,
  },
  inputField: {
    marginBottom: MARGIN_TO_VERTICALLY_CENTER_TEXT_INPUT,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: SPACING.small,
    maxHeight: 80,
  },
  sendButtonContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  sendButton: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.small,
    paddingVertical: SPACING.tiny,
    marginHorizontal: SPACING.small,
  },
});

export default SupportCaseDetailsScreen;
