import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import { ConfigDataSchema, MotionAlertConfigSchema } from "./useMotionAlertConfig";

export const roiArrSchema = z
  .object({
    top: z.number(),
    bottom: z.number(),
    width: z.number(),
    height: z.number(),
    vWidth: z.number().optional(),
    vHeight: z.number().optional(),
  })
  .describe("MotionAlertConfigSchema");

export const MotionAlertSettingsSchema = MotionAlertConfigSchema.and(ConfigDataSchema).and(
  z.array(roiArrSchema).optional(),
);

export type MotionAlertSettings = z.infer<typeof MotionAlertSettingsSchema>;

type MotionAlertSettingsRequest = {
  eid?: string;
};

export type MutateMotionAlertSettingsRequest = MotionAlertSettingsRequest & {
  body: Partial<MotionAlertSettings>;
};

function buildUrl({ eid }: MotionAlertSettingsRequest) {
  return `/n/${eid}/manage/cameras/update_motion_alert_settings`;
}

function updateMotionAlertSettings({ eid, body }: MutateMotionAlertSettingsRequest) {
  if (!body.id) {
    throw new Error("Missing required parameter: id");
  }
  return request(MotionAlertSettingsSchema, "PUT", buildUrl({ eid }), {
    body: JSON.stringify(body),
  });
}

export const useUpdateMotionAlertSettings = createMutation<
  MutateMotionAlertSettingsRequest,
  MotionAlertSettings
>({
  baseMutationKey: buildUrl({ eid: `{eid}` }),
  mutationFn: (parameters: MutateMotionAlertSettingsRequest) =>
    updateMotionAlertSettings({ ...parameters }),
});
