import { I18n } from "@meraki/core/i18n";
import { Image, ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import MkiText from "~/shared/components/MkiText";
import { OnboardingFlows, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "GetStarted"> &
  BasicActions &
  PendingComponent &
  BaseOnboardingScreenProps;

export class GetStartedScreen extends BaseOnboardingScreen<Props> {
  static renderHeader = () => (
    <MkiText textStyle="heading" screenStyles={styles.headingText}>
      {I18n.t("ONBOARDING_FULLSTACK.GET_STARTED.MESSAGE")}
    </MkiText>
  );

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.getStarted);
    actions.setOnboardingFlow(OnboardingFlows.mainOnboarding);
    actions.setOnboardingIsComplete(false);
    actions.onboardingTimingStart();
  }

  renderBody = () => {
    return (
      <ScrollView testID="GET_STARTED_SCREEN">
        {GetStartedScreen.renderHeader()}
        <Image
          style={styles.image}
          source={require("~/images/onboarding/onboarding-unbox.png")}
          resizeMode="contain"
        />
      </ScrollView>
    );
  };

  getFooterData = () => ({
    buttons: [
      <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    ],
  });

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.getStarted);
}

const styles = StyleSheet.create({
  headingText: {
    textAlign: "center",
    marginHorizontal: SPACING.default,
    marginTop: SPACING.large,
  },
  image: {
    marginTop: SPACING.large,
    alignSelf: "center",
  },
});

export default compose<any>(
  connect(null, basicMapDispatchToProps),
  withPendingComponent,
)(GetStartedScreen);
