import z from "zod";

import { request } from "../../api/request/request";
import { StatusSchema } from "../../schemas/Device";
import { createQuery } from "../createQuery";

const VpnModes = {
  spoke: "spoke",
  hub: "hub",
} as const;

const Reachability = {
  reachable: "reachable",
  unreachable: "unreachable",
  unknown: "unknown",
} as const;

export const VpnModesSchema = z.union([z.literal(VpnModes.spoke), z.literal(VpnModes.hub)]);

export const ReachabilitySchema = z.union([
  z.literal(Reachability.reachable),
  z.literal(Reachability.unreachable),
  z.literal(Reachability.unknown),
]);

export const PeerSchema = z.object({
  networkId: z.string(),
  networkName: z.string(),
  reachability: ReachabilitySchema,
});

export const ThirdPartyPeerSchema = z.object({
  name: z.string(),
  publicIp: z.string(),
  reachability: ReachabilitySchema,
});

export const ExportedSubnetSchema = z.object({
  subnet: z.string(),
  name: z.string(),
});

export const VpnUplinkSchema = z.object({
  interface: z.string(),
  publicIp: z.string(),
});

export const VpnStatusSchema = z.object({
  deviceSerial: z.string(),
  deviceStatus: StatusSchema,
  exportedSubnets: z.array(ExportedSubnetSchema),
  merakiVpnPeers: z.array(PeerSchema),
  networkId: z.string(),
  networkName: z.string(),
  thirdPartyVpnPeers: z.array(ThirdPartyPeerSchema),
  uplinks: z.array(VpnUplinkSchema),
  vpnMode: VpnModesSchema,
});

export const VpnStatusesSchema = z.array(VpnStatusSchema);

export type Peer = z.infer<typeof VpnStatusSchema>;
export type VpnStatus = z.infer<typeof VpnStatusSchema>;
export type VpnStatuses = z.infer<typeof VpnStatusesSchema>;

type VpnStatusesRequest = {
  organizationId?: string;
};

function buildUrl({ organizationId }: VpnStatusesRequest) {
  return `/api/v1/organizations/${organizationId}/appliance/vpn/statuses`;
}

function fetchVpnStatuses({ organizationId }: VpnStatusesRequest): Promise<VpnStatuses> {
  if (!organizationId) {
    throw new Error("Undefined org id when fetching VPN statuses");
  }
  return request(VpnStatusesSchema, "GET", buildUrl({ organizationId }));
}

export const useVpnStatuses = createQuery<VpnStatusesRequest, VpnStatuses>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (variables) => fetchVpnStatuses(variables),
  requiredVariables: ["organizationId"],
});
