import {
  CLEAR_SM_DEVICES,
  SET_SM_CLIENT_INFO,
  SM_DEVICE_LIST_JSON_REQUEST,
  SM_DEVICE_LIST_JSON_SUCCESS,
} from "@meraki/shared/redux";
import { Reducer } from "redux";

import { RequestStatus } from "~/constants/Requests";
import resolvedState from "~/lib/ReducerUtils";
import { SystemsManagerDevice } from "~/shared/types/SystemsManager";

type SystemManagerState = {
  status: RequestStatus;
  devices: {
    [deviceId: string]: SystemsManagerDevice;
  };
};

const initialState: SystemManagerState = {
  status: RequestStatus.neverRun,
  devices: {},
};

export const systemsManager: Reducer<SystemManagerState> = (state = initialState, action) => {
  switch (action.type) {
    case SM_DEVICE_LIST_JSON_SUCCESS:
      const nextState = {
        status: RequestStatus.success,
        devices: {} as any,
      };
      action.response.forEach((device: any) => {
        nextState.devices[device.id] = device;
      });
      return resolvedState(nextState, state);
    case SET_SM_CLIENT_INFO: {
      const { id, name } = action.client;
      return {
        ...state,
        devices: {
          [id]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state[id],
            name,
          },
        },
      };
    }
    case SM_DEVICE_LIST_JSON_REQUEST:
      return {
        ...state,
        status: RequestStatus.pending,
      };
    case CLEAR_SM_DEVICES:
      return initialState;
    default:
      return state;
  }
};

export default systemsManager;
