import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { RefreshControl } from "react-native";

import { showAlert } from "~/lib/AlertUtils";
import { CancelablePromise, makeCancelablePromise } from "~/lib/RequestUtils";
import { themeColors } from "~/lib/themeHelper";
import { Thunk } from "~/store";

/**
 * Abstraction for refreshing, takes in a list of thunks and manages data fetching states
 *
 * @param reqThunks Array of thunks to be evalulated and waited upon
 */
function useGenericRefresh(
  withError: boolean,
  ...reqThunks: Thunk<Promise<unknown>>[]
): React.ReactElement {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const reqThunksRef = useRef<Thunk<Promise<unknown>>[]>(reqThunks);
  const cancelablePromise = useRef<CancelablePromise>();

  useEffect(() => {
    if (!isEqual(reqThunksRef.current, reqThunks)) {
      reqThunksRef.current = reqThunks;
    }
  }, [reqThunks]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    const invokedThunks = reqThunksRef.current.map((thunk) => thunk());
    cancelablePromise.current = makeCancelablePromise(Promise.all(invokedThunks));
    cancelablePromise.current.promise
      .catch((error: string) => {
        if (withError) {
          return showAlert(I18n.t("ERROR"), error);
        }
        throw error;
      })
      .finally(() => setRefreshing(false));

    // ref is needed as the spread operator creates a new array for each comp render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqThunksRef.current]);

  useEffect(() => {
    onRefresh();
    return () => cancelablePromise?.current?.cancel();
  }, [onRefresh]);

  const { theme } = useTheme();
  const tintColor = themeColors(theme).spinner?.color;

  return <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={tintColor} />;
}

export default function useRefreshControl(
  ...reqThunks: Thunk<Promise<unknown>>[]
): React.ReactElement {
  return useGenericRefresh(true, ...reqThunks);
}

export function useRefreshControlNoErrorHandling(
  ...reqThunks: Thunk<Promise<unknown>>[]
): React.ReactElement {
  return useGenericRefresh(false, ...reqThunks);
}
