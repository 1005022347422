import { I18n } from "@meraki/core/i18n";
import { communityQueryUrl, communityResultUrl, documentationQueryUrl } from "@meraki/go/links";
import { useCommunityData, useCommunityTopicsData, useDocumentationData } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ConfigHeader from "~/go/components/ConfigHeader";
import ContainerWithFooter from "~/go/components/ContainerWithFooter";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import InputRow from "~/go/rows/InputRow";
import { showActionSheet } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiSpinner from "~/shared/components/MkiSpinner";
import SummaryList from "~/shared/components/SummaryList";
import WrappingButtonRow from "~/shared/components/WrappingButtonRow";
import useDebounce from "~/shared/hooks/useDebounce";
import { CancelButton } from "~/shared/navigation/Buttons";

const MAX_RESULTS_SHOW = 4;
const ALLOCATED_DOCUMENTATION_COUNT = Math.floor(MAX_RESULTS_SHOW / 2);

const removeTags = (str: string) => {
  return str
    ?.replace(/(<([^>]+)>)/gi, "")
    ?.replace(/&nbsp;/gi, "")
    ?.replace(/w&amp;/gi, "");
};

export const SearchSubjectScreen = () => {
  const navigation = useNavigation();

  const [subject, setSubject] = useState<string>("");
  const query = useDebounce<string>(subject, 1000);

  const [didClickResult, setDidClickResult] = useState(false);

  const { data: topicsData, isLoading: communityTopicsLoading } = useCommunityTopicsData(query, {
    enabled: Boolean(query),
  });
  const { data: communityResponseData, isLoading: communityLoading } = useCommunityData(
    topicsData?.data.items ?? [],
    { enabled: topicsData != undefined && topicsData.data.items.length > 0 },
  );

  const communityData = useMemo(
    () => communityResponseData?.data.items ?? [],
    [communityResponseData],
  );
  const { data: documentationResponseData, isLoading: documentationLoading } = useDocumentationData(
    query,
    { enabled: Boolean(query) },
  );
  const documentationData = useMemo(
    () => documentationResponseData?.result ?? [],
    [documentationResponseData],
  );

  const cancelHandler = useCallback(() => {
    if (didClickResult) {
      analytics.logEvent(FIREBASE_EVENTS.resovledIssueWithClick);
    }
    navigation.goBack();
  }, [didClickResult, navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <CancelButton onPress={cancelHandler} />,
    });
  }, [cancelHandler, navigation]);

  const onChangeInput = (changedText: string) => setSubject(changedText);

  const prioritizeResults = () => {
    const communityResults =
      communityData?.map((data) => ({
        title: data.subject,
        preview: data.search_snippet,
        link: data.view_href,
      })) ?? [];
    const documentationResults =
      documentationData?.map((data) => ({
        title: data.title,
        preview: data.preview,
        link: data.uri,
      })) ?? [];

    let documentationCount = Math.min(
      documentationResults?.length || 0,
      ALLOCATED_DOCUMENTATION_COUNT,
    );
    const communityCount = MAX_RESULTS_SHOW - documentationCount;

    if (communityCount > communityResults.length) {
      documentationCount += communityCount - communityResults.length;
    }

    return documentationResults
      .slice(0, documentationCount)
      .concat(communityResults.slice(0, communityCount));
  };

  const clickResult = (url: string) => {
    analytics.logEvent(FIREBASE_EVENTS.resourceResultClicked);

    setDidClickResult(true);
    communityResultUrl(url);
  };

  const openResultsLink = (chosenResult: number) => {
    switch (chosenResult) {
      case 0:
        documentationQueryUrl(subject);
        break;
      case 1:
        communityQueryUrl(subject);
        break;
    }
  };

  const showMoreResultsAlert = () => {
    showActionSheet(
      [
        I18n.t("SEARCH_SUBJECT.SEE_ALL_RESULTS.DOCUMENTATION_QUERY_LINK"),
        I18n.t("SEARCH_SUBJECT.SEE_ALL_RESULTS.COMMUNITY_QUERY_LINK"),
      ],
      openResultsLink,
    );
  };

  const showContactSupportScreen = () => {
    analytics.logEvent(
      didClickResult
        ? FIREBASE_EVENTS.openedCaseWithClicking
        : FIREBASE_EVENTS.openedCaseWithoutClicking,
    );

    navigation.navigate("ContactSupport", { subject });
  };

  const resultsData = prioritizeResults();
  let rowNumber = -1;

  const shouldShowSpinner = documentationLoading || communityTopicsLoading || communityLoading;
  const rows = resultsData.map((item) => {
    rowNumber += 1;
    return {
      label: item.title,
      subtitle: removeTags(item.preview),
      isDisclosureRow: true,
      rowStyle: styles.searchResultHeader,
      testID: `RESOURCES_RESULTS.RESULT - ${rowNumber}`,
      onPress: () => clickResult(item.link),
    };
  });

  return (
    <ContainerWithFooter
      container={
        <FullScreenContainerView keyboardShouldPersistTaps="handled">
          <ConfigHeader
            title={I18n.t("SEARCH_SUBJECT.HEADER.TITLE")}
            description={I18n.t("SEARCH_SUBJECT.HEADER.SUBTITLE")}
            screenStyles={styles.configHeader}
          />
          <InputRow
            onChangeText={(text: any) => onChangeInput(text)}
            value={subject}
            placeholder={I18n.t("SEARCH_SUBJECT.PLACEHOLDER")}
            testID={"SEARCH_SUBJECT.INPUT"}
            containerStyles={styles.inputRow}
          >
            {I18n.t("SEARCH_SUBJECT.SUBJECT")}
          </InputRow>
          {(documentationData || communityData) &&
            (shouldShowSpinner ? (
              <MkiSpinner style={styles.spinner} />
            ) : (
              <ScrollView testID="RESOURCES_RESULTS.SCROLL_VIEW">
                <SummaryList contentRows={[]} disableBottomBorder disclosureRows={rows} />
              </ScrollView>
            ))}
        </FullScreenContainerView>
      }
      footer={
        <WrappingButtonRow>
          <RoundedButton
            containerStyles={styles.buttonContainer}
            screenStyles={styles.buttonScreenStyle}
            onPress={showMoreResultsAlert}
            disabled={communityData.length === 0 && documentationData.length === 0}
            buttonType={ButtonType.secondary}
            testID={"RESOURCES_RESULTS.SEE_ALL_RESULTS"}
          >
            {I18n.t("SEARCH_SUBJECT.SEE_ALL_RESULTS_BUTTON")}
          </RoundedButton>
          <RoundedButton
            containerStyles={styles.buttonContainer}
            screenStyles={styles.buttonScreenStyle}
            onPress={showContactSupportScreen}
            disabled={subject.length === 0}
            buttonType={ButtonType.primary}
            testID={"RESOURCES_RESULTS.OPEN_CASE"}
          >
            {I18n.t("SEARCH_SUBJECT.OPEN_CASE_BUTTON")}
          </RoundedButton>
        </WrappingButtonRow>
      }
    />
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    paddingVertical: SPACING.default,
    marginHorizontal: SPACING.small,
    marginBottom: SPACING.default,
  },
  inputRow: {
    marginTop: 0,
    paddingBottom: SPACING.default,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
  },
  configHeader: {
    paddingBottom: 0,
  },
  spinner: {
    flex: 1,
  },
  searchResultHeader: {
    color: MkiColors.configHeadingColor,
  },
  buttonScreenStyle: {
    paddingVertical: SPACING.default,
  },
});

export default SearchSubjectScreen;
