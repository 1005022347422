import { I18n } from "@meraki/core/i18n";
import {
  APSummaryScreen,
  ApTestScreen,
  IntroScreen,
  PlacementScoreScreen,
  RoomSelectScreen,
} from "@meraki/shared/access-point-placement";
import { AccessPointPlacementGroupProps, StackType } from "@meraki/shared/navigation-type";

import { ModalScreenOptions } from "../Options";

export function AccessPointPlacementGroup<T extends AccessPointPlacementGroupProps>(
  Stack: StackType<T>,
) {
  return (
    <>
      <Stack.Screen
        name="APTest"
        component={ApTestScreen}
        options={{
          ...ModalScreenOptions,
        }}
      />
      <Stack.Screen
        name="APSummary"
        component={APSummaryScreen}
        options={{
          headerTitle: I18n.t("ACCESS_POINT.SUMMARY.TITLE"),
        }}
      />
      <Stack.Screen name="PlacementScore" component={PlacementScoreScreen} />
      <Stack.Screen
        name="RoomSelect"
        component={RoomSelectScreen}
        options={{ headerTitle: I18n.t("ACCESS_POINT.ROOM_SELECT.TITLE") }}
      />
      <Stack.Screen name="ApPlacementIntro" component={IntroScreen} />
    </>
  );
}
