import Svg, { Path } from "react-native-svg";

import { useThemeColors } from "~/shared/hooks/useTheme";

export const ElekidOnIcon = () => {
  const colors = useThemeColors();

  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Path
        d="M17 30.5L18.5 23L12.5 20.75L23 9.5L21.5 17L27.5 19.25L17 30.5Z"
        fill={colors.status.online.color}
      />
      <Path
        d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C22.0893 2 24.0954 2.35595 25.961 3.01051C26.4391 2.43256 26.9985 1.92439 27.6217 1.50354C25.2727 0.534546 22.6988 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 17.3012 39.4655 14.7273 38.4965 12.3783C38.0756 13.0015 37.5674 13.561 36.9895 14.039C37.6441 15.9046 38 17.9108 38 20Z"
        fill={colors.status.online.color}
      />
      <Path
        d="M32 14.5C35.5899 14.5 38.5 11.5899 38.5 8C38.5 4.41015 35.5899 1.5 32 1.5C28.4101 1.5 25.5 4.41015 25.5 8C25.5 11.5899 28.4101 14.5 32 14.5ZM34.9715 6.80469C35.2318 6.54431 35.2317 6.1222 34.9713 5.86188C34.711 5.60157 34.2888 5.60163 34.0285 5.86201L31.0008 8.89058L29.9712 7.86177C29.7108 7.60152 29.2887 7.60168 29.0284 7.86213C28.7682 8.12258 28.7683 8.54469 29.0288 8.80494L30.5298 10.3049C30.7902 10.5651 31.2123 10.565 31.4725 10.3046L34.9715 6.80469Z"
        fill={colors.status.online.color}
      />
      <Path
        d="M28.4101 14.5 32 14.5ZM34.9715 6.80469C35.2318 6.54431 35.2317 6.1222 34.9713 5.86188C34.711 5.60157 34.2888 5.60163 34.0285 5.86201L31.0008 8.89058L29.9712 7.86177C29.7108 7.60152 29.2887 7.60168 29.0284 7.86213C28.7682 8.12258 28.7683 8.54469 29.0288 8.80494L30.5298 10.3049C30.7902 10.5651 31.2123 10.565 31.4725 10.3046L34.9715 6.80469Z"
        fill={colors.modal.background}
      />
    </Svg>
  );
};

export default ElekidOnIcon;
