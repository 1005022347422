import { GET_INVENTORY_DEVICE_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

interface InventoryState {}

const initialState = {
  selectedDevice: null,
};

export default function inventory(state: InventoryState = initialState, action: any) {
  switch (action.type) {
    case GET_INVENTORY_DEVICE_SUCCESS: {
      return {
        ...state,
        selectedDevice: action.response,
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}

export const testables = {
  initialState,
};
