import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const PortForwardingRuleSchema = z.object({
  lanIp: z.string(),
  allowedIps: z.array(z.string()),
  name: z.string(),
  protocol: z.string(),
  publicPort: z.string(),
  localPort: z.string(),
  uplink: z.string(),
});

export const PortForwardingRulesSchema = z
  .object({
    rules: z.array(PortForwardingRuleSchema),
  })
  .describe("PortForwardingRulesSchema");

export type PortForwardingRules = z.infer<typeof PortForwardingRulesSchema>;

export type PortForwardingRule = z.infer<typeof PortForwardingRuleSchema>;

export interface PortForwardingRulesRequest {
  networkId?: string;
}

export interface PortForwardingRulesUpdateRequest extends PortForwardingRulesRequest {
  rules?: PortForwardingRule[];
}

interface PortForwardingError {
  errors?: string[];
}

function buildUrl({ networkId }: PortForwardingRulesRequest) {
  return `/api/v1/networks/${networkId}/appliance/firewall/portForwardingRules`;
}

function fetchPortForwardingRules(variables: PortForwardingRulesRequest) {
  return request(PortForwardingRulesSchema, "GET", buildUrl(variables));
}

function mutatePortForwardingRules({ rules, networkId }: PortForwardingRulesUpdateRequest) {
  return request(PortForwardingRulesSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify({
      rules,
    }),
  });
}

export const usePortForwardingRules = createQuery<
  PortForwardingRulesRequest,
  PortForwardingRules,
  PortForwardingError
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchPortForwardingRules(variables),
  requiredVariables: ["networkId"],
});

export const useUpdatePortForwardingRules = createMutation<
  PortForwardingRulesUpdateRequest,
  PortForwardingRules,
  PortForwardingError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => mutatePortForwardingRules(variables),
});
