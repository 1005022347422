import { useTheme } from "@meraki/core/theme";
import { createRef, forwardRef, PureComponent } from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from "react-native";

import { BOX_BORDER_RADIUS, SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLES } from "~/constants/MkiTextStyles";
import { themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";

export interface FormTextInputProps extends TextInputProps {
  title?: string;
  screenStyles?: ViewStyle;
  containerStyle?: ViewStyle;
}

interface FormTextInputState {
  isFocused: boolean;
}

export class FormTextInput extends PureComponent<FormTextInputProps, FormTextInputState> {
  private textInput: React.RefObject<TextInput> = createRef();

  state = { isFocused: false };

  focus = () => {
    if (this.textInput?.current?.focus) {
      this.textInput.current.focus();
    }
  };

  onFocus = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    onFocus?.(event);
  };

  onBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    onBlur?.(event);
  };

  renderTitle = () => {
    const { title } = this.props;
    if (!title) {
      return null;
    }
    return (
      <MkiText textStyle="formTextInputTitle" screenStyles={styles.title}>
        {title}
      </MkiText>
    );
  };

  renderTextInput = () => {
    const { isFocused } = this.state;
    const {
      onEndEditing: _onEndEditing,
      onFocus: _onFocus,
      title: _title,
      screenStyles,
      ...rest
    } = this.props;
    const { theme } = useTheme.getState();

    const colors = themeColors(theme);

    const textInputContainerStyle = {
      ...styles.textInputContainer,
      borderColor: isFocused ? colors.navigation.primary : colors.border.borderColor,
    };

    return (
      <MkiTextInput
        placeholderTextColor={colors.text.placeholder.color}
        screenStyles={[
          styles.textInput,
          colors.text.default,
          textInputContainerStyle,
          screenStyles,
        ]}
        {...rest}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={this.textInput}
      />
    );
  };

  render() {
    const { containerStyle } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        {this.renderTitle()}
        {this.renderTextInput()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: SPACING.default,
    paddingTop: SPACING.small,
  },
  title: {
    marginBottom: SPACING.tiny,
  },
  textInputContainer: {
    borderWidth: 1,
    borderRadius: BOX_BORDER_RADIUS,
  },
  textInput: {
    ...TEXT_STYLES.textInput,
    paddingTop: SPACING.default,
    padding: SPACING.default,
  },
});

const FormTextInputWithRef = forwardRef<FormTextInput, FormTextInputProps>((props, ref) => (
  <FormTextInput {...props} ref={ref} />
));

FormTextInputWithRef.displayName = "FormTextInputWithRef";

export default FormTextInputWithRef;
