import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import Touchable from "~/shared/components/Touchable";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface DropDownButtonProps {
  testID: string;
  buttonStyles?: StyleProp<ViewStyle>;
  downDownButtonColor?: string;
  onPress?: () => void;
}

const DropDownButton = ({
  onPress,
  testID,
  buttonStyles = null,
  downDownButtonColor,
}: DropDownButtonProps) => {
  const colors = useThemeColors();
  return (
    <Touchable onPress={onPress} transparentBackground testID={"DROP_DOWN_BUTTON"}>
      <View style={[clearButtonStyles.clearButton, buttonStyles]}>
        <MerakiIcon
          style={{ color: downDownButtonColor ?? colors.text.subtitle?.color }}
          testID={testID}
          name="chevron-down"
          size="xxs"
        />
      </View>
    </Touchable>
  );
};

const clearButtonStyles = StyleSheet.create({
  clearButton: {
    paddingVertical: SPACING.small,
    paddingLeft: SPACING.default,
  },
});

export default DropDownButton;
