import { I18n } from "@meraki/core/i18n";
import { PortVlanTypes, useUpdateAppliancePorts } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { get } from "lodash";
import { useLayoutEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { CombinedAppliancePort } from "~/api/queries/appliances/useCombinedAppliancePorts";
import { errorMessageParser } from "~/api/util/error";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { getBatchAppliancePortUpdateDiff } from "~/lib/GXPortUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import ApplianceFront from "~/shared/components/portDiagrams/ApplianceFront";
import SummaryListHeading from "~/shared/components/SummaryListHeading";
import useEditAppliancePort from "~/shared/hooks/appliances/useEditAppliancePort";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import DropDownRow from "~/shared/rows/DropDownRow";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "AppliancePortSettings">;

const DISMISS_MODAL_TIMEOUT = 500;
const AppliancePortSettingsScreen = ({ serialNumber, selectedPorts, onDismiss }: Props) => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  const portIds = selectedPorts.map(({ number }) => number.toString());
  const [shouldShowSpinner, setShouldShowSpinner] = useState(false);

  const isSingleAppliancePort = portIds.length === 1;
  const [stagedAppliancePort, updateStagedAppliancePort, updateStagedAppliancePortByKey, hasDiff] =
    useEditAppliancePort(get(selectedPorts, 0, {} as CombinedAppliancePort));

  const { mutate } = useUpdateAppliancePorts();

  const navigation = useNavigation();

  useLayoutEffect(() => {
    const title =
      portIds.length === 1
        ? I18n.t("PORTS.NUMBER", { port_number: portIds[0] })
        : `${portIds.length} ${I18n.t("PORTS_WORD")}`;

    const saveCallback = () => {
      setShouldShowSpinner(true);
      mutate(
        {
          organizationId,
          networkId,
          appliancePorts: getBatchAppliancePortUpdateDiff(selectedPorts, stagedAppliancePort),
        },
        {
          onSuccess: () => {
            onDismiss?.();
            setTimeout(navigation.goBack, DISMISS_MODAL_TIMEOUT);
          },
          onError: (error) => {
            const errorMessage = errorMessageParser(error);
            if (errorMessage) {
              showAlert(I18n.t("ERROR"), errorMessage, navigation.goBack);
            }
          },
        },
      );
    };

    navigation.setOptions({
      headerTitle: title,
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (hasDiff) {
              showSaveWarning(saveCallback, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={saveCallback} disabled={!hasDiff} />,
    });
  }, [
    hasDiff,
    mutate,
    navigation,
    networkId,
    onDismiss,
    organizationId,
    portIds,
    selectedPorts,
    stagedAppliancePort,
  ]);

  const { enabled, type } = stagedAppliancePort;

  const vlanSubtitle = isSingleAppliancePort
    ? type === PortVlanTypes.trunk
      ? I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.TRUNK")
      : I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.ACCESS")
    : I18n.t("PORTS.SETTINGS.MULTIPLE_VALUES");

  const selectAppliancePortsVlan = () => {
    navigation.navigate("AppliancePortsVlan", {
      appliancePort: stagedAppliancePort,
      portIds,
      onSave: updateStagedAppliancePort,
    });
  };

  if (shouldShowSpinner) {
    return <MkiSpinner fillHeight />;
  }

  return (
    <FullScreenContainerView>
      <ScrollView>
        <View style={styles.portDiagramContainer}>
          <ApplianceFront serialNumber={serialNumber} selectedPorts={portIds} hidePortStatusIcons />
        </View>
        <MkiText textStyle="smallSecondary" screenStyles={styles.infoText}>
          {`${I18n.t("PORTS.SETTINGS.DESCRIPTION")} ${portIds.join(", ")}`}
        </MkiText>
        <SummaryListHeading headingTextStyle={styles.heading}>
          {I18n.t("PORTS.SETTINGS.SETTINGS")}
        </SummaryListHeading>
        <SwitchRow
          subtitle={I18n.t("PORTS.SETTINGS.ENABLED.DESCRIPTION")}
          value={enabled}
          onValueChange={updateStagedAppliancePortByKey("enabled")}
          testID={"PORT_SETTINGS_ENABLED_ROW"}
        >
          {I18n.t("PORTS.SETTINGS.ENABLED.TITLE")}
        </SwitchRow>
        <DropDownRow
          title={I18n.t("PORTS.SETTINGS.ADVANCED_SETTINGS")}
          testID="ADVANCED_SETTINGS_ROW"
        >
          <SimpleDisclosureRow
            subtitle={vlanSubtitle}
            onPress={selectAppliancePortsVlan}
            testID={"SWITCH_PORT_VLAN"}
          >
            {I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.TITLE")}
          </SimpleDisclosureRow>
        </DropDownRow>
      </ScrollView>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: normalizedFontSize(19),
    color: MkiColors.primaryButton,
  },
  portDiagramContainer: {
    marginBottom: SPACING.small,
  },
  infoText: {
    margin: SPACING.default,
  },
});

export default AppliancePortSettingsScreen;
