import { Text } from "../../../Text/Text";

export type PortNumberProps = {
  selected?: boolean;
  number?: string;
  size?: "md" | "sm";
};

export function PortNumber({ selected, number, size }: PortNumberProps) {
  if (!number) {
    return null;
  }

  return (
    <Text size={size === "md" ? "p2" : "p3"} weight={selected ? "bold" : "regular"}>
      {number}
    </Text>
  );
}
