import z from "zod";

import { request } from "../../api/request/request";
import { ProductTypeSchema } from "../../schemas";
import { createQuery } from "../createQuery";

const PAGINATION_PER_PAGE = 10000; // valid range is 3 - 100000 as of api v1

const NodeGroupIds = z.record(ProductTypeSchema, z.object({ ngId: z.string(), ngEid: z.string() }));

export const OrgNetworkSchema = z.object({
  eid: z.string().optional(),
  enrollmentString: z.string().nullable(),
  id: z.string(),
  isBoundToConfigTemplate: z.boolean().optional(),
  name: z.string(),
  ngIds: NodeGroupIds.optional(),
  notes: z.string().nullable(),
  organizationId: z.string(),
  productTypes: z.array(z.string()),
  tags: z.array(z.string()),
  timeZone: z.string(),
  url: z.string(),
});

const OrgNetworksSchema = z.array(OrgNetworkSchema).describe("OrgNetworksSchema");

export type OrgNetwork = z.infer<typeof OrgNetworkSchema>;
export type OrgNetworks = z.infer<typeof OrgNetworksSchema>;

type OrgNetworksRequest = {
  organizationId?: string;
  perPage?: number;
};

type OrgNetworkRequest = {
  networkId?: string;
} & OrgNetworksRequest;

function buildUrl({ organizationId }: OrgNetworksRequest) {
  return `/api/v1/organizations/${organizationId}/networks`;
}

function fetchOrgNetworks({ organizationId, perPage }: OrgNetworksRequest): Promise<OrgNetworks> {
  return request(OrgNetworksSchema, "GET", buildUrl({ organizationId }), {
    queryParams: {
      perPage: perPage ?? PAGINATION_PER_PAGE,
    },
  });
}

export const useOrgNetworks = createQuery<OrgNetworksRequest, OrgNetworks>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (variables) => fetchOrgNetworks(variables),
  requiredVariables: ["organizationId"],
});

export const useOrgNetwork = ({ organizationId, networkId }: OrgNetworkRequest) => {
  return useOrgNetworks(
    { organizationId },
    {
      enabled: Boolean(organizationId) && Boolean(networkId),
      select: (data) => data?.find((network) => network.id === networkId),
    },
  );
};
