import { I18n } from "@meraki/core/i18n";
import { BottomSheetMethods, Button, Card, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSwitchPortStatus } from "@meraki/shared/api";
import { formatKibibytes } from "@meraki/shared/formatters";
import { useRef } from "react";

import { CableTestBottomSheet } from "../../../components/liveTools/CableTestBottomSheet";
import { PortInfoCard } from "../../../components/PortInfoCard";
import { SwitchPortDeviceClientCard } from "../../../components/SwitchPortDeviceClientCard";
import { SwitchPortTagCard } from "../../../components/SwitchPortTagCard";
import { VlanInfoCard } from "../../../components/VlanInfoCard";
import { useSwitchPortDetails } from "../SwitchPortDetailsContext";

export const SwitchPortDetailsTab = () => {
  const { switchPort, device } = useSwitchPortDetails();
  const { data: switchPortStatus } = useSwitchPortStatus({
    serialNumber: device?.serial,
    portId: switchPort?.portId,
  });

  const cableTestBottomSheetRef = useRef<BottomSheetMethods>(null);

  if (!device || !switchPort || !switchPortStatus) {
    return (
      <Screen testID="DETAILS_TAB">
        <Loader.Spinner testID="DETAILS_LOADING_SPINNER" />
      </Screen>
    );
  }

  return (
    <Screen addDefaultPadding testID="DETAILS_TAB">
      <Box paddingBottom="sm" gap="sm">
        <Box flexDirection="row" gap="md">
          <Button
            kind="secondary"
            text={I18n.t("CABLE_TEST.TEST_CABLE")}
            onPress={() => cableTestBottomSheetRef?.current?.present()}
            testID="TEST_CABLE_BUTTON"
          />
          <Button kind="secondary" text={I18n.t("POKE_DEVICE.TITLE")} testID="POKE_DEVICE_BUTTON" />
        </Box>

        <Card testID="LIVE_INFO_CARD">
          <Card.Header title={I18n.t("PORTS.LIVE_INFO")} />
          <Box flexDirection="row" gap="sm">
            <Card.Well flex={1}>
              <Text size="p1" weight="bold">
                {I18n.t("SWITCH_PORT_DETAILS.STATUS.USAGE.VALUE", {
                  total: formatKibibytes(switchPortStatus?.usageInKb.total ?? 0),
                })}
              </Text>
              <Text weight="bold">{I18n.t("PORTS.CURRENT_USAGE")}</Text>
            </Card.Well>
            {switchPortStatus.powerUsageInWh !== undefined && (
              <Card.Well flex={1}>
                <Text size="p1" weight="bold">
                  {I18n.t("SWITCH_PORT_DETAILS.STATUS.POE_USAGE.VALUE", {
                    value: switchPortStatus.powerUsageInWh,
                  })}
                </Text>
                <Text weight="bold">{"Power consumption"}</Text>
              </Card.Well>
            )}
          </Box>
        </Card>

        <SwitchPortDeviceClientCard switchPortStatus={switchPortStatus} />
        <PortInfoCard portInfo={switchPortStatus} linkNegotiation={switchPort.linkNegotiation} />
        <SwitchPortTagCard />
        <VlanInfoCard switchPort={switchPort} />
        <CableTestBottomSheet
          deviceId={device.id}
          portIds={[switchPort?.portId?.toString()]}
          ref={cableTestBottomSheetRef}
        />
      </Box>
    </Screen>
  );
};
