import {
  SESSION_LENGTH,
  TIME_LOWER_BOUND_IDX,
  TOTAL_SESSION_TIME_IDX,
  UNIQUE_VISITORS_IDX,
} from "@meraki/shared/api";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { SECONDS_IN_A_MINUTE, TIMESTAMP_KEY } from "~/constants/MkiConstants";
import {
  EngagementCardData,
  EngagementGraphDataPoint,
  LocationAnalyticsItem,
  LocationAnalyticState,
  LoyaltyCardData,
  LoyaltyGraphDataPoint,
  PopularTimesDataPoint,
  PresenceDataPoint,
  ProximityCardData,
  ProximityGraphDataPoint,
  Session,
  SummaryCardData,
  TimeSeriesDataPoint,
} from "~/go/types/LocationAnalyticsTypes";
import { getTimespanFromData } from "~/lib/LocationAnalyticsUtils";
import { getLocationAnalyticsState } from "~/selectors/getters";
import { getWirelessNodeGroupEid } from "~/selectors/nodeGroups";
import { Domain } from "~/shared/types/MkiChartTypes";
import { RootState } from "~/shared/types/Redux";

const MINUTES_IN_AN_HOUR = 60;
const MINUTES_IN_A_DAY = 1440;

const makeNonnegativeValue = (value: number): number | null =>
  value >= 0 ? Math.round(value) : null;

const makeValidPercentage = (value: number): number | null =>
  value >= 0 && value <= 100 ? Math.round(value) : null;

// This function checks that the upper bound on the y-domain is positive, and if it is
// non-positive, the y-domain is set to be undefined.  This is so the VictoryChart
// components render a default y-domain from about 0 to 200 in this case rather than
// incorrectly rendering the chart as upside down
const makeValidDomain = (
  graphData: TimeSeriesDataPoint[],
  maxValue: number,
  provideSpacing = true,
): Domain => {
  const yDomain = maxValue > 0 ? [0, maxValue] : undefined;
  return { x: getTimespanFromData(graphData, provideSpacing), y: yDomain };
};

const countVisitorsInSessionsBetweenBounds = (
  sessions: Session[],
  lowerBound: number,
  upperBound: number,
): number => {
  if (isEmpty(sessions) || sessions[0].length < SESSION_LENGTH) {
    return 0;
  }
  const sumSessions = (total: any, sess: any) => total + sess[UNIQUE_VISITORS_IDX];
  return sessions
    .filter(
      (sess) => sess[TIME_LOWER_BOUND_IDX] >= lowerBound && sess[TIME_LOWER_BOUND_IDX] < upperBound,
    )
    .reduce(sumSessions, 0);
};

const getTotalTimeForBar = (dataPoint: PresenceDataPoint): number => {
  const { sessions } = dataPoint.data;
  if (!sessions) {
    return 0;
  }
  return sessions.reduce((total, sess) => {
    if (sess.length < SESSION_LENGTH) {
      return total;
    }
    return total + sess[TOTAL_SESSION_TIME_IDX];
  }, 0);
};

export const getNetworkLocationAnalytics = createSelector(
  getLocationAnalyticsState,
  getWirelessNodeGroupEid,
  (_, timespan?: number) => timespan,
  (
    locationAnalyticsState: LocationAnalyticState,
    networkEid?: string,
    timespan?: number | string,
  ): LocationAnalyticsItem | null => {
    if (!networkEid || !locationAnalyticsState || !locationAnalyticsState.items) {
      return null;
    }
    const networkData = locationAnalyticsState?.items?.[networkEid];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return timespan ? networkData?.[timespan] : networkData;
  },
);

export const proximityGraphDataSelector = createSelector(
  getNetworkLocationAnalytics,
  (data: LocationAnalyticsItem | null): ProximityGraphDataPoint[] | null => {
    if (!data || isEmpty(data.presenceData)) {
      return null;
    }
    return data.presenceData.map((dataPoint) => {
      const { timestamp, data } = dataPoint;
      const { connected, passerby, visitors } = data.proximity;
      return {
        timestamp,
        connected,
        passerby,
        visitors,
      };
    });
  },
);

export const numberOfVisitorsSelector = createSelector(
  proximityGraphDataSelector,
  (proximityData: ProximityGraphDataPoint[] | null): number | null => {
    if (!proximityData) {
      return null;
    }
    const numVisitors = proximityData.reduce((total, dataPoint) => total + dataPoint.visitors, 0);
    return makeNonnegativeValue(numVisitors);
  },
);

export const numberOfPassersbySelector = createSelector(
  proximityGraphDataSelector,
  (proximityData: ProximityGraphDataPoint[] | null): number | null => {
    if (!proximityData) {
      return null;
    }
    const numPassersby = proximityData.reduce((total, dataPoint) => total + dataPoint.passerby, 0);
    return makeNonnegativeValue(numPassersby);
  },
);

export const captureRateSelector = createSelector(
  numberOfVisitorsSelector,
  numberOfPassersbySelector,
  (numVisitors: number | null, numPassersby: number | null): number | null => {
    if (numVisitors == null || numPassersby == null || numPassersby + numVisitors === 0) {
      return null;
    }
    const captureRate = (numVisitors / (numPassersby + numVisitors)) * 100;
    return makeValidPercentage(captureRate);
  },
);

export const proximityDataDomainSelector = createSelector(
  proximityGraphDataSelector,
  (graphData: ProximityGraphDataPoint[] | null): Domain | null => {
    const notNullGraphData = graphData || [];
    if (isEmpty(notNullGraphData)) {
      return null;
    }
    const keys = Object.keys(notNullGraphData[0]).filter((key) => key !== TIMESTAMP_KEY);
    const maxValuePerKey = keys.map((key) =>
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      notNullGraphData.reduce((prevMax, dataPoint) => Math.max(prevMax, dataPoint[key]), 0),
    );
    const maxValue = Math.max(...maxValuePerKey);
    return makeValidDomain(notNullGraphData, maxValue, false);
  },
);

export const proximityCardDataSelector = createSelector(
  numberOfVisitorsSelector,
  captureRateSelector,
  proximityGraphDataSelector,
  proximityDataDomainSelector,
  (
    numVisitors: number | null,
    captureRate: number | null,
    graphData: ProximityGraphDataPoint[] | null,
    domain: Domain | null,
  ): ProximityCardData | null => {
    if (numVisitors == null || captureRate == null || !graphData || !domain) {
      return null;
    }
    return { summaryData: { numVisitors, captureRate }, graphData, domain };
  },
);

export const engagementGraphDataSelector = createSelector(
  getNetworkLocationAnalytics,
  (data: LocationAnalyticsItem | null): EngagementGraphDataPoint[] | null => {
    if (!data || isEmpty(data.presenceData)) {
      return null;
    }
    return data.presenceData.map((dataPoint) => {
      const { timestamp, data } = dataPoint;
      const { sessions } = data;
      return {
        timestamp,
        fiveToTwentyMin: countVisitorsInSessionsBetweenBounds(sessions, 5, 20),
        twentyToSixtyMin: countVisitorsInSessionsBetweenBounds(sessions, 20, MINUTES_IN_AN_HOUR),
        oneHrToSixHrs: countVisitorsInSessionsBetweenBounds(
          sessions,
          MINUTES_IN_AN_HOUR,
          6 * MINUTES_IN_AN_HOUR,
        ),
        sixPlusHrs: countVisitorsInSessionsBetweenBounds(
          sessions,
          6 * MINUTES_IN_AN_HOUR,
          MINUTES_IN_A_DAY,
        ),
      };
    });
  },
);

export const averageVisitLengthSelector = createSelector(
  getNetworkLocationAnalytics,
  numberOfVisitorsSelector,
  (data: LocationAnalyticsItem | null, numVisitors: number | null): number | null => {
    if (!data || isEmpty(data.presenceData) || !numVisitors) {
      return null;
    }
    const totalVisitTime = data.presenceData.reduce(
      (total, dataPoint) => total + getTotalTimeForBar(dataPoint),
      0,
    );
    const averageVisitTime = totalVisitTime / (numVisitors * SECONDS_IN_A_MINUTE);
    return makeNonnegativeValue(averageVisitTime);
  },
);

export const engagementDataDomainSelector = createSelector(
  engagementGraphDataSelector,
  (graphData: EngagementGraphDataPoint[] | null): Domain | null => {
    const notNullGraphData = graphData || [];
    if (isEmpty(notNullGraphData)) {
      return null;
    }
    const maxValue = notNullGraphData.reduce((prevMax, dataPoint) => {
      const { fiveToTwentyMin, twentyToSixtyMin, oneHrToSixHrs, sixPlusHrs } = dataPoint;
      const currValue = fiveToTwentyMin + twentyToSixtyMin + oneHrToSixHrs + sixPlusHrs;
      return Math.max(prevMax, currValue);
    }, 0);
    return makeValidDomain(notNullGraphData, maxValue);
  },
);

export const engagementCardDataSelector = createSelector(
  averageVisitLengthSelector,
  engagementGraphDataSelector,
  engagementDataDomainSelector,
  (
    averageLength: number | null,
    graphData: EngagementGraphDataPoint[] | null,
    domain: Domain | null,
  ): EngagementCardData | null => {
    if (averageLength == null || !graphData || !domain) {
      return null;
    }
    return { summaryData: { averageLength }, graphData, domain };
  },
);

export const loyaltyGraphDataSelector = createSelector(
  getNetworkLocationAnalytics,
  (data: LocationAnalyticsItem | null): LoyaltyGraphDataPoint[] | null => {
    if (!data || isEmpty(data.presenceData)) {
      return null;
    }
    return data.presenceData.map((dataPoint) => {
      const { timestamp, data } = dataPoint;
      const { first_timers, daily, weekly, monthly, occasional } = data.visitors;
      return {
        timestamp,
        firstTime: first_timers,
        daily,
        weekly,
        occasionally: monthly + occasional,
      };
    });
  },
);

export const numberOfNewVisitorsSelector = createSelector(
  loyaltyGraphDataSelector,
  (loyaltyData: LoyaltyGraphDataPoint[] | null): number | null => {
    if (!loyaltyData) {
      return null;
    }
    const numNewVisitors = loyaltyData.reduce((total, dataPoint) => total + dataPoint.firstTime, 0);
    return makeNonnegativeValue(numNewVisitors);
  },
);

export const numberOfRepeatVisitorsSelector = createSelector(
  loyaltyGraphDataSelector,
  (loyaltyData: LoyaltyGraphDataPoint[] | null): number | null => {
    if (!loyaltyData) {
      return null;
    }
    const numRepeatVisitors = loyaltyData.reduce(
      (total, dataPoint) => total + dataPoint.daily + dataPoint.weekly + dataPoint.occasionally,
      0,
    );
    return makeNonnegativeValue(numRepeatVisitors);
  },
);

export const returningVisitorsPercentageSelector = createSelector(
  numberOfNewVisitorsSelector,
  numberOfRepeatVisitorsSelector,
  (numNew: number | null, numRepeat: number | null): number | null => {
    if (numNew == null || numRepeat == null || numNew + numRepeat === 0) {
      return null;
    }
    const returningVisitorsPercentage = (numRepeat / (numRepeat + numNew)) * 100;
    return makeValidPercentage(returningVisitorsPercentage);
  },
);

// TODO: Update domain selector so we compute domains for different subsets of the
// data being selected by user, so graph can resize as we update what data is rendered
export const loyaltyDataDomainSelector = createSelector(
  loyaltyGraphDataSelector,
  (graphData: LoyaltyGraphDataPoint[] | null): Domain | null => {
    const notNullGraphData = graphData || [];
    if (isEmpty(notNullGraphData)) {
      return null;
    }
    const maxValue = notNullGraphData.reduce((prevMax, dataPoint) => {
      const currValue =
        dataPoint.firstTime + dataPoint.daily + dataPoint.weekly + dataPoint.occasionally;
      return Math.max(prevMax, currValue);
    }, 0);
    return makeValidDomain(notNullGraphData, maxValue);
  },
);

export const loyaltyCardDataSelector = createSelector(
  numberOfNewVisitorsSelector,
  returningVisitorsPercentageSelector,
  loyaltyGraphDataSelector,
  loyaltyDataDomainSelector,
  (
    numNewVisitors: number | null,
    returningRate: number | null,
    graphData: LoyaltyGraphDataPoint[] | null,
    domain: Domain | null,
  ): LoyaltyCardData | null => {
    if (numNewVisitors == null || returningRate == null || !graphData || !domain) {
      return null;
    }
    return { summaryData: { numNewVisitors, returningRate }, graphData, domain };
  },
);

export const popularTimesGraphDataSelector = createSelector(
  getNetworkLocationAnalytics,
  (data: LocationAnalyticsItem | null): PopularTimesDataPoint[] | null => {
    if (!data || isEmpty(data.presenceData)) {
      return null;
    }
    return data.presenceData.map((dataPoint) => {
      const { timestamp, data } = dataPoint;
      const { visitors } = data.proximity;
      return {
        timestamp,
        visitors,
      };
    });
  },
);

export const popularTimesGraphDomainSelector = createSelector(
  popularTimesGraphDataSelector,
  (graphData: PopularTimesDataPoint[] | null): Domain | null => {
    const notNullGraphData = graphData || [];
    if (isEmpty(notNullGraphData)) {
      return null;
    }
    const maxValue = notNullGraphData.reduce(
      (prevMax, dataPoint) => Math.max(prevMax, dataPoint.visitors),
      0,
    );
    return makeValidDomain(notNullGraphData, maxValue);
  },
);

export const locationAnalyticsSummaryCardDataSelector: (
  state: RootState,
  timespan?: number,
) => SummaryCardData | null = createSelector(
  numberOfVisitorsSelector,
  averageVisitLengthSelector,
  popularTimesGraphDataSelector,
  popularTimesGraphDomainSelector,
  (
    numVisitors: number | null,
    averageLength: number | null,
    graphData: PopularTimesDataPoint[] | null,
    domain: Domain | null,
  ) => {
    if (numVisitors == null || averageLength == null || !graphData || !domain) {
      return null;
    }
    return { summaryData: { numVisitors, averageLength }, graphData, domain };
  },
);
