import MkiColors from "~/constants/MkiColors";
import { appSelect } from "~/lib/PlatformUtils";
import { Theme, themeColors } from "~/lib/themeHelper";
import { ApplicationTypes } from "~/shared/types/ApplicationTypes";

interface Usage {
  label: string;
  color?: string;
}

const getGoUsageColor = (usage: Usage, theme: Theme) => {
  const currentThemeColors = themeColors(theme).graphColors;
  if (usage?.label === ApplicationTypes.other) {
    return MkiColors.othergrey;
  }
  if (!usage?.color) {
    return MkiColors.barChartDefaultFill;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return currentThemeColors[usage?.color] || MkiColors.barChartDefaultFill;
};

const getEnterpriseUsageColor = (usage: Usage, theme: Theme) => {
  const currentThemeColors = themeColors(theme).graphColors;
  if (usage?.label === ApplicationTypes.other) {
    return currentThemeColors.unknown;
  }
  if (!usage?.color) {
    return currentThemeColors.unknown;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return currentThemeColors[usage?.color] || currentThemeColors.unknown;
};

const getUsageColor = (usage: Usage, theme: Theme) =>
  appSelect({
    go: getGoUsageColor(usage, theme),
    enterprise: getEnterpriseUsageColor(usage, theme),
  });

export default getUsageColor;
