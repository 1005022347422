import { I18n } from "@meraki/core/i18n";
import { showWhatsNewDoc } from "@meraki/go/links";
import { READ_IN_WHATS_NEW, READ_NEW_FEATURE, READ_WHATS_NEW } from "@meraki/shared/redux";

import { WhatsNewImport } from "~/shared/types/WhatsNewTypes";

const getWhatsNew = (): WhatsNewImport => ({
  ipsk: {
    title: I18n.t("WHATS_NEW.IPSK.TITLE"),
    message: I18n.t("WHATS_NEW.IPSK.MESSAGE"),
  },
  backupTFA: {
    title: I18n.t("WHATS_NEW.BACKUP_TFA.TITLE"),
    message: I18n.t("WHATS_NEW.BACKUP_TFA.MESSAGE"),
    featureScreen: "IntroTwoFactor",
  },
  speedTest: {
    title: I18n.t("WHATS_NEW.SPEED_TEST.TITLE"),
    message: I18n.t("WHATS_NEW.SPEED_TEST.MESSAGE"),
    featureScreen: "SpeedTest",
  },
  dhcpOptions: {
    title: I18n.t("WHATS_NEW.DHCP_OPTIONS.TITLE"),
    message: I18n.t("WHATS_NEW.DHCP_OPTIONS.MESSAGE"),
    featureScreen: "LocalAddressing",
  },
  multifactorAuth: {
    title: I18n.t("WHATS_NEW.MULTIFACTOR_AUTH.TITLE"),
    message: I18n.t("WHATS_NEW.MULTIFACTOR_AUTH.MESSAGE"),
    showDoc: showWhatsNewDoc("MULTIFACTOR_AUTH"),
  },
  networkAvailability: {
    title: I18n.t("WHATS_NEW.SCHEDULES.TITLE"),
    message: I18n.t("WHATS_NEW.SCHEDULES.MESSAGE"),
    showDoc: showWhatsNewDoc("SCHEDULES"),
  },
  gxRealtimeUsage: {
    title: I18n.t("WHATS_NEW.GX_REALTIME_USAGE.TITLE"),
    message: I18n.t("WHATS_NEW.GX_REALTIME_USAGE.MESSAGE"),
    featureScreen: "HardwareDetails",
  },
  manualRadioControls: {
    title: I18n.t("WHATS_NEW.MANUAL_RADIO_CONTROLS.TITLE"),
    message: I18n.t("WHATS_NEW.MANUAL_RADIO_CONTROLS.MESSAGE"),
    showDoc: showWhatsNewDoc("MANUAL_RADIO_CONTROLS"),
  },
  darkMode: {
    title: I18n.t("WHATS_NEW.DARK_MODE.TITLE"),
    message: I18n.t("WHATS_NEW.DARK_MODE.MESSAGE"),
    featureScreen: "ThemeSelect",
    presentedModally: true,
  },
  gxSecurityPolicy: {
    title: I18n.t("WHATS_NEW.GX_SECURITY_POLICY.TITLE"),
    message: I18n.t("WHATS_NEW.GX_SECURITY_POLICY.MESSAGE"),
    featureScreen: "UmbrellaSettings",
    presentedModally: true,
  },
  printableQRCode: {
    title: I18n.t("WHATS_NEW.PRINTABLE_QR_CODE.TITLE"),
    message: I18n.t("WHATS_NEW.PRINTABLE_QR_CODE.MESSAGE"),
    showDoc: showWhatsNewDoc("PRINTABLE_QR_CODE"),
  },
  languagePicker: {
    title: I18n.t("WHATS_NEW.LANGUAGE_PICKER.TITLE"),
    message: I18n.t("WHATS_NEW.LANGUAGE_PICKER.MESSAGE"),
    featureScreen: "Account",
  },
  gxVLAN: {
    title: I18n.t("WHATS_NEW.GX_VLAN.TITLE"),
    message: I18n.t("WHATS_NEW.GX_VLAN.MESSAGE"),
    showDoc: showWhatsNewDoc("GX_VLAN"),
  },
  limitedBroadcast: {
    title: I18n.t("WHATS_NEW.LIMITED_BROADCAST.TITLE"),
    message: I18n.t("WHATS_NEW.LIMITED_BROADCAST.MESSAGE"),
    showDoc: showWhatsNewDoc("LIMITED_BROADCAST"),
  },
  deleteLocation: {
    title: I18n.t("WHATS_NEW.DELETE_LOCATION.TITLE"),
    message: I18n.t("WHATS_NEW.DELETE_LOCATION.MESSAGE"),
    showDoc: showWhatsNewDoc("DELETE_LOCATION"),
  },
  contextHelp: {
    title: I18n.t("WHATS_NEW.CONTEXT_HELP.TITLE"),
    message: I18n.t("WHATS_NEW.CONTEXT_HELP.MESSAGE"),
  },
  widget: {
    title: I18n.t("WHATS_NEW.WIDGET.TITLE"),
    message: I18n.t("WHATS_NEW.WIDGET.MESSAGE"),
  },
  networkHealth: {
    title: I18n.t("WHATS_NEW.NETWORK_HEALTH.TITLE"),
    message: I18n.t("WHATS_NEW.NETWORK_HEALTH.MESSAGE"),
    showDoc: showWhatsNewDoc("NETWORK_HEALTH"),
  },
  l3FirewallRules: {
    title: I18n.t("WHATS_NEW.L3_FIREWALL_RULES.TITLE"),
    message: I18n.t("WHATS_NEW.L3_FIREWALL_RULES.MESSAGE"),
    showDoc: showWhatsNewDoc("L3_FIREWALL_RULES"),
  },
});

export function readInWhatsNew() {
  return {
    type: READ_IN_WHATS_NEW,
    whatsNew: getWhatsNew(),
  };
}

export function readWhatsNew() {
  return {
    type: READ_WHATS_NEW,
  };
}

export function readNewFeature(feature: string) {
  return {
    type: READ_NEW_FEATURE,
    feature,
  };
}
