import { Box } from "@meraki/magnetic/layout";
import { Pressable, PressableProps } from "react-native";

import { Text } from "../Text/Text";
import { Checked } from "./icons/Checked";
import { Unchecked } from "./icons/Unchecked";

export type RadioButtonProps<T extends string | number | null> = Omit<
  PressableProps,
  "style" | "onPress" | "onPressIn" | "onPressOut"
> & {
  checkedValue: string | number | null;
  value: T;
  disabled?: boolean;
  children?: string;
  onValueChange?: (value: T) => void;
};

export function RadioButton<T extends string | number | null>({
  checkedValue,
  value,
  disabled,
  children,
  onValueChange,
  testID,
  ...rest
}: RadioButtonProps<T>) {
  const checked = value === checkedValue;
  const Visual = checked ? Checked : Unchecked;

  const handlePress = () => {
    onValueChange?.(value);
  };

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      gap="2xs"
      testID={testID}
      accessibilityRole="radio"
      accessibilityState={{ checked }}
    >
      <Pressable {...rest} disabled={disabled} onPress={handlePress}>
        <Visual disabled={disabled} />
      </Pressable>
      <Text size="p3" color={disabled ? "default.text.disabled" : "default.text.base"}>
        {children}
      </Text>
    </Box>
  );
}
