export enum Features {
  ipsk = "ipsk",
  backupTFA = "backupTFA",
  speedTest = "speedTest",
  dhcpOptions = "dhcpOptions",
  networkHealth = "networkHealth",
  widget = "widget",
  limitedBroadcast = "limitedBroadcast",
  deleteLocation = "deleteLocation",
  contextHelp = "contextHelp",
  multifactorAuth = "multifactorAuth",
  networkAvailability = "networkAvailability",
  gxRealtimeUsage = "gxRealtimeUsage",
  manualRadioControls = "manualRadioControls",
  darkMode = "darkMode",
  gxSecurityPolicy = "gxSecurityPolicy",
  printableQRCode = "printableQRCode",
  languagePicker = "languagePicker",
  gxVLAN = "gxVLAN",
  l3FirewallRules = "l3FirewallRules",
}

interface WhatsNewBase {
  message: string;
  title: string;
  featureScreen?: string;
  presentedModally?: boolean;
  showDoc?: () => void;
  screenProps?: object;
}

export type WhatsNewImport = {
  [key in Features]: WhatsNewBase;
};

export interface WhatsNewBreakdown extends WhatsNewBase {
  read: boolean;
}

type WhatsNewUpdated = {
  updated: boolean;
};

export type WhatsNewFeatures = {
  [key in Features]?: WhatsNewBreakdown;
};

export type WhatsNewReduxState = WhatsNewUpdated & WhatsNewFeatures;
