import { formatDateRange } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { Card, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useNetwork } from "@meraki/shared/api";
import { ClientsOverviewCard } from "@meraki/shared/clients";
import {
  formatAndParseKibibytes,
  formatKibibytes,
  formatTimespanRelative,
} from "@meraki/shared/formatters";
import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";

import { ApplicationUsageCard } from "../components/ApplicationUsageCard";

export const NetworkUsageExceededScreen = () => {
  const { params } = useRoute<RouteProp<InsightsStackProps, "NetworkUsageExceeded">>();
  const { networkId, occurredAt, period, total, threshold } = params;

  const { data: network } = useNetwork({ networkId });

  const occurredAtUTCSeconds = new Date(occurredAt).valueOf();
  const t0 = `${occurredAtUTCSeconds}`;
  const t1 = `${occurredAtUTCSeconds + period * 1000}`;

  const occurredAtDate = formatTimespanRelative(occurredAt);
  const formattedTotalUsage = formatAndParseKibibytes(total);
  const formattedRange = formatDateRange(parseInt(t0), parseInt(t1), {
    unixTimestamp: false,
    hideDate: true,
  });

  return (
    <Screen addDefaultPadding>
      <Card>
        <Box flexDirection="row" alignItems="center">
          <Icon name="WifiHigh" />
          <Box paddingLeft="sm" flexDirection="row" justifyContent="space-between" flex={1}>
            <Text size="p2">{network?.name}</Text>
            <Text size="p2" color="default.text.medium.base">
              {occurredAtDate}; {formattedRange}
            </Text>
          </Box>
        </Box>
      </Card>
      <ClientsOverviewCard
        t0={t0}
        t1={t1}
        initialFilters={{ clientType: new Set(["networkOnly"]) }}
      />
      <Card>
        <Card.Content>
          <Box flexDirection="row" flex={1} justifyContent="space-between">
            <Box flexDirection="row" alignItems="baseline">
              <Text size="p1" weight="bold">
                {`${formattedTotalUsage.value} `}
              </Text>
              <Text size="p2" weight="semiBold" color="light">
                {formattedTotalUsage.unit}
              </Text>
            </Box>
            <Text size="p2" weight="semiBold" color="accent.a.base">
              {`${I18n.t("HOME.USAGE_EXCEEDED.LIMIT")} ${formatKibibytes(threshold)}`}
            </Text>
          </Box>
          <Text size="p2" color="light">
            {formatDateRange(parseInt(t0), parseInt(t1), { unixTimestamp: false })}
          </Text>
        </Card.Content>
      </Card>
      <ApplicationUsageCard t0={t0} t1={t1} />
    </Screen>
  );
};
