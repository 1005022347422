import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { PureComponent } from "react";

import ContainerWithFooter from "~/go/components/ContainerWithFooter";
import OnboardingFooter, {
  OnboardingFooterProps,
} from "~/go/components/onboarding/OnboardingFooter";
import { OnboardingStackProps } from "~/go/navigation/Types";
import { OnboardingDataProps } from "~/hocs/OnboardingData";
import { getOnboardingScreen } from "~/lib/OnboardingFullstackUtils";
import { isWeb } from "~/lib/PlatformUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import {
  NextOnboardingStageConfig,
  OnboardingScannedDevices,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";

export interface BaseOnboardingScreenProps extends OnboardingDataProps {
  scannedHardwareMap: OnboardingScannedDevices;
  isOnboarding: boolean;
  navigation: NativeStackNavigationProp<OnboardingStackProps>;
}

// This class is expected to be used with the OnboardingData HOC.
// If that HOC is not used with this, the OnboardingDataProps will not
// be available here.
abstract class BaseOnboardingScreen<
  P extends BaseOnboardingScreenProps,
  S = {
    /* do nothing */
  },
  SS = {
    /* do nothing */
  },
> extends PureComponent<P, S, SS> {
  private focusListenerUnsubscribe: () => void;
  private blurListenerUnsubscribe: () => void;

  constructor(props: P) {
    super(props);

    const { navigation } = props;

    this.focusListenerUnsubscribe = navigation.addListener("focus", () => {
      this.componentDidAppear?.();
    });

    this.blurListenerUnsubscribe = navigation.addListener("blur", () => {
      this.componentDidDisappear?.();
    });
  }

  componentWillUnmount(): void {
    this.focusListenerUnsubscribe();
    this.blurListenerUnsubscribe();
  }

  protected componentDidAppear(): void {
    /* do nothing */
  }
  protected componentDidDisappear(): void {
    /* do nothing */
  }

  static defaultProps = { isOnboarding: true };

  abstract renderBody: () => JSX.Element;

  abstract getFooterData: () => OnboardingFooterProps;

  abstract nextStageConfig: NextOnboardingStageConfig;

  hasScannedWifi = () => {
    const { scannedHardwareMap } = this.props;

    return scannedHardwareMap && scannedHardwareMap.wireless === true;
  };

  renderFooter = () => <OnboardingFooter {...this.getFooterData()} />;

  close = (initialScreen = false) => {
    const { navigation, isOnboarding, skipOnboarding, cleanupOnboarding } = this.props;

    if (isOnboarding) {
      skipOnboarding();
      cleanupOnboarding();
    } else {
      cleanupOnboarding();

      if (!initialScreen) {
        navigation.popToTop();
      }

      // web doesn't need this call as it brings it back to the prev stack
      // TODO: investigate why
      if (!isWeb()) {
        navigation.goBack();
      }
    }
  };

  onPrimaryPress = (extraArgs?: object) => {
    const { nextStageConfig } = this;
    const { completeOnboarding } = this.props;

    if (nextStageConfig.nextStage === OnboardingStage.exit) {
      completeOnboarding();
    } else {
      this.pushOnboardingScreen(nextStageConfig.nextStage, extraArgs);
    }
  };

  pushOnboardingScreen = (stage: OnboardingStage, extraArgs: object = {}) => {
    const { navigation, isOnboarding } = this.props;
    const screen = getOnboardingScreen(stage);

    // @ts-expect-error TS(2345) FIXME: Argument of type '[keyof AddHardwareScreensPropMap... Remove this comment to see the full error message
    navigation.navigate(screen, { ...extraArgs, isOnboarding });
  };

  render() {
    return (
      <FullScreenContainerView>
        <ContainerWithFooter container={this.renderBody()} footer={this.renderFooter()} />
      </FullScreenContainerView>
    );
  }
}

export default BaseOnboardingScreen;
