import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import HardwareRow, { HardwareRowData, ProductIconSize } from "~/go/components/HardwareRow";
import ConnectAndUpgradeBody from "~/go/components/onboarding/ConnectAndUpgradeBody";
import { OnboardingStackProps } from "~/go/navigation/Types";
import withOnboardingStatus from "~/hocs/OnboardingData";
import {
  getOnboardingHeaderForStatus,
  mapOnboardingNodesToRowData,
} from "~/lib/OnboardingFullstackUtils";
import { devicesState, getOnboardingNodes } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import { CloseButton } from "~/shared/navigation/Buttons";
import { DevicesBySerial } from "~/shared/types/Device";
import { OnboardingNodes } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";

import { BaseOnboardingScreenProps } from "./BaseOnboardingScreen";

type ReduxProps = {
  devices: DevicesBySerial;
  onboardingDevices: OnboardingNodes;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "ConnectAndUpgradeStatus"> &
  ReduxProps &
  BaseOnboardingScreenProps;

export class ConnectAndUpgradeStatusScreen extends PureComponent<Props> {
  static renderRow = (rowData: HardwareRowData) => {
    const { name, model, description, status } = rowData;

    return (
      <HardwareRow
        name={name}
        description={description}
        model={model}
        status={status}
        productIconSize={ProductIconSize.small}
        showStatusIcon
      />
    );
  };

  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  onLEDLegendPress = () => {
    const { navigation } = this.props;

    navigation.navigate("LEDColorLegend", {
      onboarding: true,
    });
  };

  renderTable() {
    const { devices, onboardingDevices } = this.props;
    if (isEmpty(onboardingDevices) || isEmpty(devices)) {
      return null;
    }

    const data = mapOnboardingNodesToRowData(devices, onboardingDevices);
    return (
      <MkiTable<HardwareRowData>
        data={data}
        keyExtractor={(_, index) => `${index}`}
        renderRow={ConnectAndUpgradeStatusScreen.renderRow}
        hasSeparators={false}
      />
    );
  }

  render() {
    const { status } = this.props;
    const headerStatus = getOnboardingHeaderForStatus(status);

    return (
      <FullScreenContainerView withScroll>
        <ConnectAndUpgradeBody
          headerTitle={headerStatus}
          onLEDLegendPress={this.onLEDLegendPress}
        />
        {this.renderTable()}
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    onboardingDevices: getOnboardingNodes(state),
    devices: devicesState(state),
  };
}

export default compose<any>(
  connect(mapStateToProps),
  withOnboardingStatus,
)(ConnectAndUpgradeStatusScreen);
