import { I18n } from "@meraki/core/i18n";
import { TrafficShapingGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, List, RefreshControl, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSwitchQoSRules } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function SwitchQoSRulesListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<TrafficShapingGroupProps>>();

  const networkId = useCurrentNetworkId();
  const { data: rules, refetch, isLoading, isRefetching } = useSwitchQoSRules({ networkId });

  return (
    <Screen refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}>
      <Box paddingTop="md" paddingHorizontal="md" bottomDividerBorder>
        <Heading size="h1">{I18n.t("CONFIGURE.SWITCH_QOS.TITLE")}</Heading>
      </Box>
      <Box paddingHorizontal="md">
        <Text size="p1">{I18n.t("CONFIGURE.SWITCH_QOS.DESCRIPTION")}</Text>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" paddingTop="lg">
          <Heading>{I18n.t("CONFIGURE.SWITCH_QOS.RULES")}</Heading>
          <Box flexDirection="row" alignItems="center">
            <Button
              kind="tertiary"
              text={I18n.t("ADD")}
              trailingIcon="Plus"
              onPress={() => navigation.navigate("SwitchQoSRule")}
              testID="QOS.ADD"
            />
          </Box>
        </Box>
      </Box>
      <List.FlashList
        data={rules}
        getItemData={(rule) => {
          return {
            title: rule.vlan
              ? I18n.t("SWITCH_QOS_LIST.RULE.TITLE", { protocol: rule.protocol, vlan: rule.vlan })
              : I18n.t("SWITCH_QOS_LIST.RULE.TITLE_NO_VLAN", { protocol: rule.protocol }),
            description: I18n.t("SWITCH_QOS_LIST.RULE.SRC_DEST_DSCP", {
              src: rule.srcPort || rule.srcPortRange || I18n.t("SWITCH_QOS_LIST.RULE.ANY"),
              dst: rule.dstPort || rule.dstPortRange || I18n.t("SWITCH_QOS_LIST.RULE.ANY"),
              dscp: rule.dscp === -1 ? "" : rule.dscp ?? "",
            }),
            rightAccessory: (
              <Button
                kind="tertiary"
                text={I18n.t("EDIT")}
                onPress={() => navigation.navigate("SwitchQoSRule", { rule })}
              />
            ),
            testID: `QOS.RULE_${rule.id}`,
          };
        }}
        ListEmptyComponent={
          <Card>
            <Text size="p1">{I18n.t("NOT_ADDED")}</Text>
          </Card>
        }
        loading={isLoading}
        paddingTop="none"
        testID="QOS.RULES_LIST"
      />
    </Screen>
  );
}
