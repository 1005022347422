import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Uplinks({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M8.912 17.742c.09.206.185.404.287.593A6.953 6.953 0 0 1 6 15.463h2.207c.175.842.413 1.612.704 2.28ZM7.966 13.9H5.338A6.93 6.93 0 0 1 5.075 12c0-.659.092-1.296.263-1.899h2.628A18.116 18.116 0 0 0 7.867 12c0 .652.034 1.288.099 1.9Zm.242-5.362H6a6.954 6.954 0 0 1 3.197-2.872c-.102.189-.197.387-.286.591-.291.668-.53 1.439-.704 2.281Zm3.037-3.08c.303-.294.557-.383.755-.383h.003c.197.001.45.09.752.383.306.297.618.766.896 1.405.212.486.395 1.05.542 1.675H9.808c.146-.625.33-1.19.542-1.675.278-.64.59-1.108.895-1.405Zm3.557 12.878a6.954 6.954 0 0 0 3.197-2.872h-2.207a11.72 11.72 0 0 1-.703 2.28c-.09.205-.185.403-.287.592Zm1.233-4.436h2.627c.172-.603.264-1.24.264-1.899s-.092-1.296-.264-1.899h-2.627c.064.612.098 1.248.098 1.898 0 .652-.034 1.288-.098 1.9Zm-.242-5.362h2.206a6.954 6.954 0 0 0-3.196-2.872c.101.189.196.387.286.591.29.668.529 1.439.704 2.281ZM14.569 12c0-.66-.037-1.296-.107-1.898H9.539A16.51 16.51 0 0 0 9.43 12c0 .661.038 1.298.108 1.9h4.923c.07-.602.107-1.239.107-1.9Zm-.918 5.138c.212-.486.395-1.05.541-1.674H9.808c.146.625.33 1.188.542 1.674.278.64.59 1.107.895 1.404.303.294.557.383.755.383.198 0 .453-.089.755-.383.306-.297.618-.765.896-1.404Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12Zm-2.01 0a8.49 8.49 0 1 1-16.98 0 8.49 8.49 0 0 1 16.98 0Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
    </Svg>
  );
}
