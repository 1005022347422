import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { NAV_BAR_HEIGHT, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";

type Props = {
  title?: string;
  subtitle?: string;
  buttonTitle?: string;
  buttonOnPress?: () => void;
  visible?: boolean;
};

const LoadingModal = (props: Props) => {
  const { title, subtitle, visible, buttonTitle, buttonOnPress } = props;
  if (!visible) {
    return null;
  }
  const titleText = title ? (
    <MkiText textStyle="heading" screenStyles={styles.text}>
      {title}
    </MkiText>
  ) : null;
  const subtitleText = subtitle ? (
    <MkiText textStyle="default" screenStyles={styles.text}>
      {subtitle}
    </MkiText>
  ) : null;
  const button = buttonOnPress ? (
    <RoundedButton buttonType="secondary" onPress={buttonOnPress} screenStyles={styles.button}>
      {buttonTitle}
    </RoundedButton>
  ) : null;
  const backgroundColor =
    title || subtitle ? MkiColors.whiteBackground : MkiColors.whiteBackgroundTransparent;
  return (
    <View style={[StyleSheet.absoluteFill, styles.container, { backgroundColor }]}>
      <MkiSpinner />
      {titleText}
      {subtitleText}
      {button}
    </View>
  );
};

LoadingModal.defaultProps = {
  title: null,
  subtitle: null,
  buttonTitle: I18n.t("SKIP"),
  buttonOnPress: undefined,
  visible: false,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    paddingBottom: NAV_BAR_HEIGHT,
  },
  text: {
    marginHorizontal: SPACING.large,
    marginTop: SPACING.large,
    textAlign: "center",
  },
  button: {
    marginHorizontal: SPACING.large,
    marginTop: SPACING.large,
  },
});

export default LoadingModal;
