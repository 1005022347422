import { I18n } from "@meraki/core/i18n";
import { Button, List, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { DeviceScreensPropMap } from "~/enterprise/navigation/Types";
import { showSaveWarning } from "~/lib/AlertUtils";

type Props = ForwardedNativeStackScreenProps<DeviceScreensPropMap, "Selection">;

export const SelectionScreen = (props: Props) => {
  const navigation = useNavigation<Props["navigation"]>();
  const { description, options, initialValue, title, onSelect } = props;
  const [selectedValue, setSelectedValue] = useState(initialValue);

  // TODO DM-4243: this screen is used on the EditSwitchportScreen. The fact that it has a "Save" button on this screen
  // that doesn't actually submit a request causes a confusing state for the user - it appears the readonly users
  // can hit "Save" and their changes actually do update in the flow, until the API request fails. It would
  // probably make more sense for this button to say "confirm", but that would also require changing or
  // removing the "showSaveWarning" message
  useLayoutEffect(() => {
    const hasChanges = selectedValue !== initialValue;
    const save = () => {
      onSelect?.(selectedValue);
      navigation.goBack();
    };

    navigation.setOptions({
      headerTitle: title,
      headerLeft: () => (
        <Button.Nav
          text={I18n.t("CLOSE")}
          onPress={() => {
            if (hasChanges) {
              showSaveWarning(save, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <Button.Nav text={I18n.t("SAVE")} disabled={!hasChanges} onPress={save} />,
    });
  }, [initialValue, navigation, onSelect, selectedValue, title]);

  return (
    <Screen addDefaultPadding>
      <Text size="p2">{description}</Text>
      <List>
        {options.map((option) => (
          <List.RadioItem
            title={option.label}
            description={option.sublabel}
            key={option.value}
            radioValue={option.value}
            checkedRadioValue={selectedValue}
            onRadioValueChange={(value) => setSelectedValue(value)}
            accessibilityHint={`${option.value} has been selected`}
            testID={`RADIO_${option.value}`}
          />
        ))}
      </List>
    </Screen>
  );
};
