import { I18n } from "@meraki/core/i18n";

import { APIError } from "~/api/schemas/APIError";

export const errorMessageParser = (error: unknown, joinWith?: string) => {
  if (!error) {
    return;
  }
  if (error instanceof Error) {
    return error.message;
  } else {
    const result = APIError.safeParse(error);
    if (result.success && result.data.errors.length > 0) {
      if (joinWith) {
        return result.data.errors.join(joinWith);
      }
      return result.data.errors[0];
    } else {
      return I18n.t("SERVER_ERROR_TEXT");
    }
  }
};
