import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { Features } from "~/go/types/ContextHelpTypes";
import { showAlert } from "~/lib/AlertUtils";
import { readContextHelp } from "~/lib/ContextHelperUtils";
import MerakiIcon from "~/shared/components/icons";
import SharedColors from "~/shared/constants/SharedColors";
import useAnalytics from "~/shared/hooks/useAnalytics";

interface ContextHelpProps {
  context: string;
  withHorizontalPadding?: boolean;
}

const ContextHelp = ({ context = Features.noHelp, withHorizontalPadding }: ContextHelpProps) => {
  const { title, message, icon, showDoc } = readContextHelp(context);
  const logEvent = useAnalytics();

  const displayHelp = () => {
    logEvent("context_help", { context });

    showAlert(I18n.t(title), I18n.t(message), showDoc, {
      positiveText: I18n.t("CONTEXT_HELP.LEARN_MORE_LINK"),
      negativeText: I18n.t("CONTEXT_HELP.DISMISS"),
    });
  };

  const iconStyle = withHorizontalPadding ? styles.iconWithHorizontalPadding : null;
  return (
    <View style={styles.centerIcon}>
      <MerakiIcon
        name={icon}
        size="xs"
        containerStyle={iconStyle}
        color={SharedColors.gray60Lighter}
        onPress={displayHelp}
        testID="CONTEXT_HELP.BUTTON"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  iconWithHorizontalPadding: {
    paddingHorizontal: SPACING.small,
  },
  centerIcon: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ContextHelp;
