import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface ApplianceUplinkRequest {
  organizationId?: string;
  networkIds?: string[];
  serials?: string[];
}

function buildUrl(organizationId?: string) {
  return `/api/v1/organizations/${organizationId}/appliance/uplink/statuses`;
}

const UplinkSchema = z.object({
  interface: z.string(),
  status: z.string(),
  ip: z.string(),
  gateway: z.string(),
  publicIp: z.string(),
  primaryDns: z.string(),
  secondaryDns: z.string(),
  ipAssignedBy: z.string(),
});

export const ApplianceUplinkSchema = z
  .object({
    networkId: z.string(),
    serial: z.string(),
    model: z.string(),
    lastReportedAt: z.string(),
    highAvailability: z.object({
      enabled: z.boolean(),
      role: z.string(),
    }),
    uplinks: z.array(UplinkSchema),
  })
  .describe("ApplianceUplinkSchema");

export const ApplianceUplinksSchema = z
  .array(ApplianceUplinkSchema)
  .describe("ApplianceUplinksSchema");

export type ApplianceUplink = z.infer<typeof ApplianceUplinkSchema>;

function fetchDeviceUplinks({
  organizationId,
  ...queryParams
}: ApplianceUplinkRequest): Promise<ApplianceUplink[]> {
  return request(ApplianceUplinksSchema, "GET", buildUrl(organizationId), { queryParams });
}

export const useApplianceUplinks = createQuery<ApplianceUplinkRequest, ApplianceUplink[]>({
  baseQueryKey: buildUrl("{organizationId}"),
  queryFn: (variables) => fetchDeviceUplinks(variables),
  requiredVariables: ["organizationId"],
});
