import * as errorMonitor from "@meraki/core/errors";
import { LivePorts, LiveToolMsg } from "@meraki/react-live-broker";
import { AppliancePort, Vlan } from "@meraki/shared/api";

import { DeviceChassis, PortBlockData, PortStatus, PortTypes } from "~/constants/PortLayouts";
import { AddressSpace, DNSServers, DNSSettings, WebBlockingRule } from "~/go/types/NetworksTypes";
import {
  dottedDecimalFromSubnetMask,
  extractNetworkPrefix,
  extractSubnetMask,
} from "~/lib/IPAddressUtils";
import NetworkUtils from "~/lib/NetworkUtils";
import { VlanResponse } from "~/shared/types/Vlans";

import { generatePortBlock } from "./PortUtils";
import { startsWith } from "./StringUtils";

export const VLAN_ID_GO = 1;

export const vlanIndexForId = (id: number): number => {
  if (id < 1) {
    console.warn("vlanIds should be 1 or greater.");
  }
  return id - 1;
};

export const vlanIdForIndex = (index: number): number => {
  if (index < 0) {
    console.warn("you cannot pass a negative index into the vlan array.");
  }
  return index + 1;
};

export const getGatewaySubnetAndMask = (vlan?: VlanResponse): AddressSpace => {
  if (!vlan) {
    return {
      gatewayIp: "",
      subnetBase: "",
      subnetMask: "",
    };
  }

  const { applianceIp, subnet } = vlan;
  const mask = extractSubnetMask(subnet);

  return {
    gatewayIp: applianceIp,
    subnetBase: extractNetworkPrefix(subnet),
    subnetMask: dottedDecimalFromSubnetMask(mask),
  };
};

export function hasDuplicateUrls(url: string, webBlockingRules?: WebBlockingRule[]) {
  const urlDomain = NetworkUtils.getDomainFromUrl(url);
  return webBlockingRules?.some((item) => NetworkUtils.getDomainFromUrl(item.value) === urlDomain);
}

export const getDNSSettings = (vlan?: Vlan): DNSSettings => {
  const dnsNameservers = vlan?.dnsNameservers;

  if (!dnsNameservers) {
    return {
      dnsServer: DNSServers.custom,
      primaryDNSServer: "",
      secondaryDNSServer: "",
    };
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  } else if (DNSServers[dnsNameservers]) {
    return {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      dnsServer: DNSServers[dnsNameservers],
      primaryDNSServer: "",
      secondaryDNSServer: "",
    };
  } else {
    const customServers = dnsNameservers.split(/\n/);
    return {
      dnsServer: DNSServers.custom,
      primaryDNSServer: customServers[0],
      secondaryDNSServer: customServers[1],
    };
  }
};

const getNumPorts = (model: string) => {
  const modelToUpperCase = model.toUpperCase();
  // TODO: Add regex support for Z3 etc
  const matches = modelToUpperCase.match(/[M|G]X\d+-*(\d*)\w*/);

  if (!matches) {
    const errorMsg = `Unsupported appliance model: ${modelToUpperCase}`;
    errorMonitor.notify(errorMsg, modelToUpperCase);
    throw Error(errorMsg);
  }

  const numPorts = matches[1];

  return Number.parseInt(numPorts);
};

export function getApplianceLayout(model: string): PortBlockData {
  const numPorts = getNumPorts(model);

  // TODO: add support for other appliances later
  if (startsWith(model, ["GX20", "GX50"])) {
    return {
      chassis: DeviceChassis.desktop,
      blocks: generatePortBlock(PortTypes.rj45, 5, 5, 1),
    };
  }

  return {
    chassis: DeviceChassis.desktop,
    blocks: generatePortBlock(PortTypes.rj45, numPorts, 5, 1),
  };
}

export const deriveAppliancePortStatuses = (
  appliancePorts: AppliancePort[],
  livePortStatuses: LiveToolMsg<LivePorts[]>,
) => {
  const portStatuses: Record<string, PortStatus> = {};

  // for some reason port 1 is not included in the api resp
  const uplinkPortActive = livePortStatuses?.[0].carrier;
  portStatuses[1] = uplinkPortActive ? PortStatus.uplink : PortStatus.disconnected;

  appliancePorts?.forEach((port) => {
    const { number: portId } = port;
    const portStatus = livePortStatuses?.[portId - 1];
    if (!port.enabled) {
      portStatuses[portId] = PortStatus.disabled;
    } else if (!portStatus || portStatus.speed === "down") {
      portStatuses[portId] = PortStatus.disconnected;
    } else if (portStatus.using_poe) {
      portStatuses[portId] = PortStatus.poe;
    } else if (portStatus.carrier) {
      portStatuses[portId] = PortStatus.active;
    } else {
      portStatuses[portId] = PortStatus.disconnected;
    }
  });

  return portStatuses;
};
