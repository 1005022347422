import { memo } from "react";
import { InputAccessoryViewProps, StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiInputAccessoryView from "~/shared/components/MkiInputAccessoryView";
import { useTheme } from "~/shared/hooks/useTheme";

const BUTTON_PADDING = SPACING.default;

interface NavigationInputAccessoryViewProps extends InputAccessoryViewProps {
  onPrevPress: () => void;
  onNextPress: () => void;
  onExitPress: () => void;
}

const hitSlop = {
  top: BUTTON_PADDING,
  bottom: BUTTON_PADDING,
  left: BUTTON_PADDING,
  right: BUTTON_PADDING,
};

interface InputAccessoryButtonProps {
  name: string;
  onPress: () => void;
}

const InputAccessoryButton = ({ name, onPress }: InputAccessoryButtonProps) => {
  const navColors = themeColors(useTheme()).navigation;

  const isOnPressDisabled = typeof onPress !== "function";
  const color = isOnPressDisabled ? MkiColors.borderColor : navColors.primary;

  return (
    <MerakiIcon
      containerStyle={styles.inputAccessoryButton}
      name={name}
      size="s"
      onPress={onPress}
      hitSlop={hitSlop}
      color={color}
      disableHeightWidth={isOnPressDisabled}
    />
  );
};

const NavigationInputAccessoryView = ({
  onPrevPress,
  onNextPress,
  onExitPress,
  ...rest
}: NavigationInputAccessoryViewProps) => {
  return (
    <MkiInputAccessoryView {...rest} style={styles.container}>
      <View style={styles.inputAccessoryContainer}>
        <View style={styles.arrowButtonContainer}>
          <InputAccessoryButton name="chevron-left" onPress={onPrevPress} />
          <InputAccessoryButton name="chevron-right" onPress={onNextPress} />
        </View>
        <InputAccessoryButton name="close" onPress={onExitPress} />
      </View>
    </MkiInputAccessoryView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  inputAccessoryContainer: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTopColor: MkiColors.borderColor,
    borderTopWidth: 1,
  },
  arrowButtonContainer: {
    flexDirection: "row",
  },
  inputAccessoryButton: {
    padding: BUTTON_PADDING,
  },
});

export default memo(NavigationInputAccessoryView);
