import { I18n } from "@meraki/core/i18n";
import { launchUmbrellaSecurityUrl } from "@meraki/go/links";
import { License, ProductType } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { formatDistanceToNow } from "date-fns";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import LicenseIcon from "~/go/components/LicenseIcon";
import { useGXUMBLicense } from "~/go/hooks/useFilteredLicenses";
import { IS_ON_JAPANESE } from "~/i18n/i18n";
import { isUmbrellaAccountReadySelector } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import TouchableText from "~/shared/components/TouchableText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { LicenseStatuses } from "~/shared/types/License";

interface LicenseInfoTextsProps {
  license?: License | null;
}

export const LicenseInfoTexts = ({ license }: LicenseInfoTextsProps) => {
  const texts: string[] = [];

  if (license == null) {
    texts.push(I18n.t("UMBRELLA.SUBSCRIPTION.MISSING"));
  } else {
    const { state, activationDate, expirationDate } = license;
    switch (state) {
      case LicenseStatuses.expiring:
      case LicenseStatuses.active:
        texts.push(
          I18n.t("UMBRELLA.SUBSCRIPTION.ACTIVE_FOR", {
            activeDays: formatDistanceToNow(new Date(activationDate || "")),
          }),
        );
        texts.push(
          I18n.t("UMBRELLA.SUBSCRIPTION.LEFT", {
            timeLeft: formatDistanceToNow(new Date(expirationDate || "")),
          }),
        );
        break;
      case LicenseStatuses.expired:
        texts.push(I18n.t("UMBRELLA.SUBSCRIPTION.EXPIRED"));
        texts.push(I18n.t("UMBRELLA.SUBSCRIPTION.LICENSE_REQUIRED"));
        break;
      default:
        texts.push(I18n.t("UMBRELLA.SUBSCRIPTION.INACTIVE"));
        texts.push(I18n.t("UMBRELLA.SUBSCRIPTION.LICENSE_REQUIRED"));
        break;
    }
  }

  return (
    <View style={styles.securityText}>
      {texts.map((text, i) => (
        <MkiText textStyle="secondary" key={`text_${i}`}>
          {text}
        </MkiText>
      ))}
      {!license && (
        <TouchableText
          text={I18n.t("UMBRELLA.SUBSCRIPTION.LEARN_MORE")}
          textStyle="secondary"
          onPress={launchUmbrellaSecurityUrl}
          testID={"SUBSCRIPTION.LEARN_MORE"}
        />
      )}
    </View>
  );
};

interface SecurityLicenseCardProps {
  serialNumber: string;
  productType?: ProductType;
}

export const SecurityLicenseCard = ({ serialNumber, productType }: SecurityLicenseCardProps) => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const isUmbrellaAccountReady = useAppSelector(isUmbrellaAccountReadySelector) as boolean;
  const { data, isFetching, isLoading } = useGXUMBLicense(serialNumber, organizationId, networkId);

  if (productType === "appliance" && (!IS_ON_JAPANESE || isUmbrellaAccountReady)) {
    return (
      <SummaryCard
        heading={I18n.t("UMBRELLA.TITLE")}
        contentContainerStyles={styles.card}
        loading={isFetching || isLoading}
        testID="SECURITY_LICENSE_CARD"
      >
        <View style={styles.licenseInfoContainer}>
          <LicenseIcon license={data} />
          <LicenseInfoTexts license={data} />
        </View>
      </SummaryCard>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  card: {
    borderBottomWidth: 0,
    marginHorizontal: 0,
  },
  securityText: {
    marginRight: SPACING.extraExtraLarge,
  },
  licenseInfoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

export default SecurityLicenseCard;
