import { Box } from "@meraki/magnetic/layout";
import Svg, { Path, Rect } from "react-native-svg";

import { BasePortProps, BaseUsbProps } from "../types";
import { Indicator } from "./Indicator";
import { PortColorMap } from "./portColorMap";
import { PortNumber } from "./PortNumber";

const viewBoxWidth = 60;
const viewBoxHeight = 28;

const smallSizeDelta = 10;

export function Usb({
  flipped,
  state,
  selected,
  number,
  size = "md",
  testID,
}: BasePortProps & BaseUsbProps) {
  const colors = PortColorMap[state ?? "disconnected"][selected ? "selected" : "idle"];

  const PortNumberComponent = <PortNumber number={number} selected={selected} size={size} />;

  return (
    <Box alignItems="center" gap="2xs" testID={testID}>
      {!flipped && PortNumberComponent}
      <Svg
        width={viewBoxWidth - (size === "md" ? 0 : smallSizeDelta)}
        height={viewBoxHeight - (size === "md" ? 0 : smallSizeDelta)}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        fill="none"
        pointerEvents="none"
      >
        <Path
          d="M1 2C1 1.44772 1.44772 1 2 1H58C58.5523 1 59 1.44772 59 2V26C59 26.5523 58.5523 27 58 27H2C1.44772 27 1 26.5523 1 26V2Z"
          fill={colors.boxBackground}
          stroke={colors.boxBorder}
          stroke-width="2"
        />
        <Rect
          x="4.5"
          y="4.5"
          width="51"
          height="9"
          fill={colors.portBackground}
          stroke={colors.portBorder}
        />
        {selected && (
          <Rect
            x="1"
            y="1"
            width="60"
            height="28"
            rx="1"
            stroke={colors.portBackground}
            stroke-width="2"
          />
        )}
        <Indicator
          indicator={state === "warning" ? "plug" : undefined}
          x={17}
          y={1}
          color={colors.iconBackground}
        />
      </Svg>
      {flipped && PortNumberComponent}
    </Box>
  );
}
