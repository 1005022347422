import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function TeleworkerGateway({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M4 16a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H4Zm2 3.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path d="M13.848 7.996a1.665 1.665 0 1 1-3.331 0 1.665 1.665 0 0 1 3.33 0Z" fill={color1} />
      <Path
        d="M8.44 9.697a2.41 2.41 0 0 1 0-3.328.746.746 0 1 0-1.078-1.03 3.9 3.9 0 0 0 0 5.388.746.746 0 1 0 1.078-1.03ZM15.554 6.37a2.41 2.41 0 0 1 0 3.328.746.746 0 1 0 1.078 1.03 3.9 3.9 0 0 0 0-5.388.746.746 0 0 0-1.078 1.03Z"
        fill={color2}
      />
      <Path
        d="M4.791 8.034c0 1.407.551 2.76 1.535 3.766a.746.746 0 0 1-1.066 1.042 6.881 6.881 0 0 1 0-9.617.746.746 0 0 1 1.066 1.042 5.39 5.39 0 0 0-1.535 3.767ZM17.67 4.267a5.39 5.39 0 0 1 0 7.533.746.746 0 1 0 1.066 1.042 6.881 6.881 0 0 0 0-9.617.746.746 0 0 0-1.067 1.042Z"
        fill={color3}
      />
    </Svg>
  );
}
