import { ClientType } from "@meraki/shared/api";

import { RequestStatus } from "~/constants/Requests";
import { ClientTypes } from "~/enterprise/types/ClientTypes";
import { ClientApplicationUsage } from "~/shared/types/ApplicationUsages";
import { ClientPolicy, PolicyOnClient } from "~/shared/types/ClientPolicy";

import { ConnectionType } from "./Device";
import { SystemsManagerDevice } from "./SystemsManager";
import { Useblocks } from "./Useblocks";

export interface ClientVpnDetails {
  connections: {
    [ip: string]: {
      connected_at: number;
      disconnect: number;
      remote_ip: string;
      user: string;
    };
  };
}

export type Client = ClientType;

export type ClientList = Record<string, Client>;

export interface ExtraNetworkClientFields {
  description: string;
  totalUsage?: number;
  os: string;
  switch: boolean;
  wired: boolean;
  wireless: boolean;
  mxWireless: boolean;
  policies: PolicyOnClient[];
  port: string | number;
  ssid?: string | number | null;
  highUsage: boolean;
}

interface ExtraClientFields {
  clientType: ClientTypes;
  smId?: string;
  isOnline?: boolean;
  connectionType: ConnectionType;
  band?: WirelessBand;
}

// three types of clients: network clients, SM devices, and clients that are both
export type ExtendedClient = Client & ExtraNetworkClientFields & ExtraClientFields;
export type ExtendedSM = SystemsManagerDevice & ExtraClientFields;
export type ExtendedClientWithSM = ExtendedClient & ExtendedSM;

export type SMDeviceType = ExtendedSM | ExtendedClientWithSM;
export type NetworkClientType = ExtendedClient | ExtendedClientWithSM;

// any client type
export type NetworkClientOrSMDevice = ExtendedClient | ExtendedSM | ExtendedClientWithSM;

export type ClientConnections = { [k: string]: boolean }[];

export interface ClientUsageHistories {
  [id: string]: Useblocks;
}

export interface ClientApplicationUsages {
  [id: string]: ClientApplicationUsage;
}

export interface ClientState {
  items: ClientList;
  usageHistories: ClientUsageHistories;
  applicationUsages: ClientApplicationUsages;
  clientPolicies: ClientPolicy[];
  status: RequestStatus;
}

export interface DisplayableClient {
  id: string;
  ip: string;
  mac: string;
  description: string;
  usage: {
    sent: number;
    recv: number;
  };
}

export interface ClientEvent {
  description: string;
  clientId: string;
  clientDescription: string;
  deviceSerial: string;
  occurredAt: string;
  eventData: {
    ip: string;
    mac: string;
    connected: boolean | string;
  };
  type: string;
}

export enum WirelessBand {
  two = "2",
  twoFour = "2.4",
  five = "5",
}

export interface BlockedNetworks {
  wired: boolean;
  ssids: {
    [key: number]: boolean;
  };
}

export interface LimitedNetworks {
  ssids: {
    [key: number]: string | undefined;
  };
}
