import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { documentationUrl } from "@meraki/go/links";
import { getIndoorOrOutdoor, getProductType, INDOOR } from "@meraki/shared/devices";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import HardwareDetailsHeader from "~/go/components/HardwareDetailsHeader";
import InlineAlert from "~/go/components/InlineAlert";
import RoundedButton from "~/go/components/RoundedButton";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import { withNodeSubscription } from "~/lib/liveBroker";
import { currentNetworkState, deviceState } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { CloseButton } from "~/shared/navigation/Buttons";
import Device_DeprecatedType from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  device: Device_DeprecatedType;
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "BlinkLED"> &
  ReduxProps &
  BasicActions;

type BlinkLEDModalState = {
  showTroubleshooting: boolean;
  isBlinking?: boolean;
  blink?: NodeJS.Timeout;
};

export class BlinkLEDModal extends PureComponent<Props, BlinkLEDModalState> {
  constructor(props: Props) {
    super(props);
    this.state = { showTroubleshooting: true };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    const { blink } = this.state;
    if (blink) {
      clearInterval(blink);
    }
  }

  handleRequests = (reqs: unknown[]) => {
    const handleError = (err: unknown) => {
      showAlert(I18n.t("ERROR"), err || I18n.t("SERVER_ERROR_TEXT"));
    };
    return Promise.all(reqs).then(undefined, handleError);
  };

  saveHardwareDeviceName = (name: string) => {
    const { actions, device } = this.props;

    return this.handleRequests([actions.updateDevice(device.serial, { name })]);
  };

  showText() {
    const { device } = this.props;
    const { model } = device;

    switch (getProductType(model)) {
      case ProductType.appliance:
        return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.SECURITY_GATEWAY");
      case ProductType.switch:
        return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.SWITCH");
      case ProductType.wireless:
        return getIndoorOrOutdoor(model) === INDOOR
          ? I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.INDOOR")
          : I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.OUTDOOR");
      default:
        return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.DEVICE_BLINK_DEFAULT");
    }
  }

  start() {
    const { device, actions } = this.props;
    const { id, networkEid } = device;
    const blink = setInterval(() => actions.blinkLED(id, networkEid), 5000);
    this.setState({ blink, isBlinking: true });
  }

  stop() {
    const { blink } = this.state;
    if (blink) {
      clearInterval(blink);
    }
    this.setState({ isBlinking: false });
  }

  render() {
    const { navigation, device } = this.props;
    const { isBlinking, showTroubleshooting } = this.state;

    const button = isBlinking ? (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="destructive"
          onPress={() => this.stop()}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("STOP")}
        </RoundedButton>
      </View>
    ) : (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="secondary"
          onPress={() => this.start()}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("RESTART")}
        </RoundedButton>
      </View>
    );

    // TODO: Link to BlinkLED specific documentation
    return (
      <FullScreenContainerView>
        <ScrollView>
          <HardwareDetailsHeader saveName={this.saveHardwareDeviceName} device={device} />
          <MkiText screenStyles={styles.infoText}>{this.showText()}</MkiText>
          {button}
          <InlineAlert
            visible={showTroubleshooting}
            onExit={() => this.setState({ showTroubleshooting: false })}
            alertTitle={I18n.t("LIVE_TOOLS.TROUBLESHOOT_TITLE")}
            alertMessage={I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.TROUBLESHOOTING.BLINK_TEXT")}
            primaryButtonText={I18n.t("LIVE_TOOLS.CONTACT_SUPPORT")}
            secondaryButtonText={I18n.t("LEARN_MORE")}
            onPrimaryPress={() => navigation.navigate("SearchSubject")}
            onSecondaryPress={documentationUrl}
            screenStyles={styles.inlineAlert}
          />
        </ScrollView>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  infoText: {
    color: MkiColors.secondaryTextColor,
    textAlign: "center",
    paddingHorizontal: SPACING.extraLarge,
  },
  inlineAlert: {
    marginVertical: SPACING.extraLarge,
  },
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
});

function mapStateToProps(state: RootState, props: HardwareStackPropMap["BlinkLED"]): ReduxProps {
  return {
    device: deviceState(state, props),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for BlinkLEDModal",
      currentNetworkState(state),
    ),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withNodeSubscription({
    type: "NodeStatus",
    handler: ({ data }: any) => ({ isLive: data.live }),
    deviceId: (ownProps: any) => ownProps.device.id,
  }),
)(BlinkLEDModal);
