import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useLayoutEffect } from "react";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { ResetTwoFactorScreensPropMap } from "~/go/navigation/Types";
import { isWeb } from "~/lib/PlatformUtils";

import ResetTwoFactorScreen from "../screens/ResetTwoFactorScreen";
import VerifyResetTwoFactorScreen from "../screens/VerifyResetTwoFactorScreen";
import { useDefaultScreenOptions } from "./Options";

const ForwardedResetTwoFactorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<ResetTwoFactorScreensPropMap, "ResetTwoFactor">
  >()(ResetTwoFactorScreen);
const ForwardedVerifyResetTwoFactorScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<ResetTwoFactorScreensPropMap, "VerifyResetTwoFactor">
>()(VerifyResetTwoFactorScreen);

const Stack = createNativeStackNavigator<ResetTwoFactorScreensPropMap>();

const ResetTwoFactorStack = () => {
  useLayoutEffect(() => {
    if (isWeb()) {
      history.replaceState(null, "", "#reset-two-factor");
    }
  }, []);

  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="ResetTwoFactor"
        component={ForwardedResetTwoFactorScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("RESET_TWO_FACTOR.TITLE"),
        }}
      />
      <Stack.Screen
        name="VerifyResetTwoFactor"
        component={ForwardedVerifyResetTwoFactorScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("VERIFY_RESET_TWO_FACTOR.TITLE"),
        }}
      />
    </Stack.Navigator>
  );
};

export default ResetTwoFactorStack;
