import { useSplashPagePreview as libUseSplashPagePreview } from "@meraki/shared/links";

import { currentOrganization, getWirelessNodeGroupEid } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

export const useSplashPagePreview = (ssidNumber: number) => {
  const organization = useAppSelector(currentOrganization);
  const encryptedNetworkId = useAppSelector(getWirelessNodeGroupEid);

  return libUseSplashPagePreview(organization.name, encryptedNetworkId, ssidNumber);
};
