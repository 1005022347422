import { MkiVideoSource } from "@meraki/core/mki-video";
import { VideoFile, VideoJson } from "@meraki/shared/api";
import { Dimensions, DimensionValue, useWindowDimensions } from "react-native";

import { VideoChannel } from "~/api/schemas/VideoChannel";
import { SPACING, WINDOW } from "~/constants/MkiConstants";
import { NO_VIDEO_STATUS } from "~/enterprise/constants/Camera";
import { AvailabilityStatus, PointData, RoiArr, StreamType } from "~/enterprise/types/Cameras";
import { defaultMotionSearch } from "~/reducers/cameras";

export interface ViewDimension {
  width: number;
  height: number;
}

export const CAMERA_ASPECT_RATIO = 16 / 9;

export const PERMISSION_ERRORS = {
  CAMERA_ROLL_PERMISSIONS_MISSING: "E_PERMISSION_MISSING",
  CAMERA_PERMISSIONS_MISSING: "E_PICKER_NO_CAMERA_PERMISSION",
} as const;

// type guard for catching errors
export const isPermissionError = (e: unknown): e is { code: string } =>
  typeof e === "object" && e !== null && "code" in e && typeof e["code"] === "string";

export const USER_LIBRARY = "UserLibrary";
export const MERAKI_IMAGE_URL = "https://meraki-na.s3";

export const getContainModeDimensions = (width: number, height: number) => {
  if (width / height < CAMERA_ASPECT_RATIO) {
    return {
      width: width,
      height: width / CAMERA_ASPECT_RATIO,
    };
  } else {
    return {
      width: height * CAMERA_ASPECT_RATIO,
      height: height,
    };
  }
};

export const getMotionEventDimensions = () => {
  const EVENT_SNAPSHOT_MIN_WIDTH = 150;
  const SNAPSHOT_RATIO = 0.6;

  const { width } = Dimensions.get(WINDOW);
  let snapshotWidth = 0;

  if (width / 2 > EVENT_SNAPSHOT_MIN_WIDTH) {
    snapshotWidth = width / 2 - SPACING.default;
  } else {
    snapshotWidth = width - SPACING.default;
  }

  return { width: snapshotWidth, height: snapshotWidth * SNAPSHOT_RATIO };
};

export const getBottomSheetSnapPoints = (
  width: number,
  height: number,
): [number, number] | undefined => {
  if (height > width) {
    const defaultSnapPoint =
      height + SPACING.meager - getContainModeDimensions(width, height).height;
    return [height, defaultSnapPoint];
  }
  return undefined;
};

/**
 * Calculates historical video time elapsed since the user started watching historical video.
 * If rate = 1, historical time elapsed = real time elapsed.
 * If rate > 1, historical time elapsed > real time elapsed, and vice versa.
 * @param startTime: historical video start time
 * @param delta: now - delta = historical video end time
 * @param speed: rate at which the historical video was watched
 * @returns time elapsed
 */
export const getHistoricalTimeElapsed = (startTime: number, delta: number, speed: number) => {
  const endTime = Date.now() - delta;
  return speed * (endTime - startTime);
};

export const getLiveTime = (timeZoneOffset: number): number => {
  return Date.now() - timeZoneOffset;
};
/**
 * Gets a rectangular polygon, horizontally and verticallly centered.
 */
export const getDefaultPolygon = () => {
  const { width } = Dimensions.get(WINDOW);
  const playerWidth = width - SPACING.default;

  const snapshotHeight = playerWidth / CAMERA_ASPECT_RATIO;
  const midWidth = playerWidth / 2;
  const midHeight = snapshotHeight / 2;
  const defaultPolygon: PointData[] = [
    { id: 0, x: midWidth - midHeight / 2, y: midHeight + midHeight / 2 },
    { id: 1, x: midWidth + midHeight / 2, y: midHeight + midHeight / 2 },
    { id: 2, x: midWidth + midHeight / 2, y: midHeight - midHeight / 2 },
    { id: 3, x: midWidth - midHeight / 2, y: midHeight - midHeight / 2 },
  ];
  return defaultPolygon;
};

const FINE_COLS = 60;
const FINE_ROWS = 34;

type BlockCoordinate = {
  centerX: number;
  centerY: number;
  top: number;
  left: number;
};

/**
 * Calculates the center coordinate fo each block on a 34x60 grid based on the witdh of
 * the device and the aspect ratio of the camera live feed.
 * @returns An array of 60 * 34 = 2040 BlockCoordinate
 */
const getAllBlocks = (): BlockCoordinate[] => {
  const blocks: BlockCoordinate[] = [];
  const { width } = Dimensions.get(WINDOW);
  const snapshotHeight = width / CAMERA_ASPECT_RATIO;
  const w = width / FINE_COLS;
  const h = snapshotHeight / FINE_ROWS;

  for (let t = 0; t < FINE_ROWS; t++) {
    for (let l = 0; l < FINE_COLS; l++) {
      blocks.push({ centerX: w / 2 + w * l, centerY: h / 2 + h * t, top: t, left: l });
    }
  }
  return blocks;
};

/**
 * Performs the even-odd-rule Algorithm (a raycasting algorithm) to find out whether a point is in a given polygon.
 * This runs in O(n) where n is the number of edges of the polygon.
 *
 * @param {Array} polygon an array representation of the polygon where polygon[i][0] is the x Value of the i-th point and polygon[i][1] is the y Value.
 * @param {Array} point   an array representation of the point where point[0] is its x Value and point[1] is its y Value
 * @return {boolean} whether the point is in the polygon (not on the edge, just turn < into <= and > into >= for that)
 */
const pointInPolygon = (polygon: number[][], point: number[]): boolean => {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let inPolygon = false;
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] && // One point needs to be above, one below our y coordinate
      // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
      point[0] <
        ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
          (polygon[j][1] - polygon[i][1]) +
          polygon[i][0]
    ) {
      // Invert inPolygon
      inPolygon = !inPolygon;
    }
    j = i;
  }
  //If the number of crossings was odd, the point is in the polygon
  return inPolygon;
};

/**
 * This function returns blocks in a 34x60 array that falls within a given polygon.
 * It also groups some of the blocks into clusters (a big block that spans multiple blocks).
 * Two blocks will fall within the same cluster if they are adjacent to each other and
 * they are in the same row.
 * @param polygonCoord The coordinates of the vertices in the polygon
 * @returns the ROI blocks that fall within the polygon
 */
export const getRoi = (polygonCoord: PointData[]) => {
  const allBlocks = getAllBlocks();
  const polygon: number[][] = [];
  for (let i = 0; i < polygonCoord.length; i++) {
    polygon.push([polygonCoord[i].x, polygonCoord[i].y]);
  }
  const fineBlocks: RoiArr = {};
  let count = -1;
  let currTop = -1;
  let currLeft = -1;
  let currWidth = 0;
  for (let i = 0; i < allBlocks.length; i++) {
    // For each block we check whether it is inside the polygon.
    if (pointInPolygon(polygon, [allBlocks[i].centerX, allBlocks[i].centerY])) {
      // If a point is inide the polygon, group
      if (currTop === allBlocks[i].top && currLeft === allBlocks[i].left - 1) {
        // Group adjancent blocks in the same row in a single cluster
        currWidth++;
        fineBlocks[count.toString()].width = currWidth;
        currLeft = allBlocks[i].left;
      } else {
        // Point is in a new row or is not adjacent to another block in the same row. Creating a new cluster
        count++;
        fineBlocks[count.toString()] = {
          top: allBlocks[i].top,
          left: allBlocks[i].left,
          width: 1,
          height: 1,
        };
        currWidth = 1;
        currLeft = allBlocks[i].left;
        currTop = allBlocks[i].top;
      }
    }
  }
  return fineBlocks;
};

export const getStreamType = (
  localAvailability: AvailabilityStatus,
  cloudAvailability: AvailabilityStatus,
): StreamType => {
  if (localAvailability === "available") {
    return "localLan";
  } else if (cloudAvailability === "available") {
    return "cloudProxy";
  } else {
    return "unknown";
  }
};

export const UNDEFINED_SOURCE = { url: undefined };

export const toLiveSource = (streamType: StreamType, channel?: VideoChannel): MkiVideoSource => {
  if (channel === undefined) {
    return UNDEFINED_SOURCE;
  }

  const { stream_endpoint, id, m3u8_filename, supports_cloud_proxy } = channel;

  switch (streamType) {
    case "localLan": {
      return { url: getLocalUrl(channel) };
    }
    case "cloudProxy":
      if (!supports_cloud_proxy) {
        // Exception for demo cameras
        return { url: `${stream_endpoint}/stream/http/channel/${id}/index.m3u8` };
      }
      return {
        url: `${stream_endpoint}/stream2/http/channel/${id}/${m3u8_filename}/${m3u8_filename}.m3u8`,
      };
    default: {
      return UNDEFINED_SOURCE;
    }
  }
};

// If you query for a file and it doesn't find one, the endpoint constructs a fake video file to return
export const isRealStoredVideoFile = (videoFile?: VideoJson["file"]): videoFile is VideoFile =>
  videoFile !== undefined && "length" in videoFile?.metadata;

export const toStoredSource = (videoFile?: VideoJson["file"]): MkiVideoSource => {
  if (videoFile === undefined) {
    // Undefined urls have no effect in the player, they are not loaded
    return UNDEFINED_SOURCE;
  } else if (!isRealStoredVideoFile(videoFile)) {
    // Puts the player on an error state.
    return { url: NO_VIDEO_STATUS, offsetMs: 0 };
  }

  const {
    start_time,
    requested_ts,
    end_time,
    metadata: { src },
  } = videoFile;

  // Hardcoding fileStartTime and fileEndTime, however names/values will change, just minimizing the amount
  // of future changes while I'm at it.
  return {
    url: src,
    offsetMs: requested_ts - start_time,
    startTime: start_time,
    fileStartTime: start_time,
    endTime: end_time,
    fileEndTime: end_time,
  };
};

export const getLocalUrl = (channel?: VideoChannel) => {
  if (channel === undefined) {
    return undefined;
  }
  const { local_lan_address, local_lan_hls_live_stream_path } = channel;
  return `${local_lan_address}${local_lan_hls_live_stream_path}`;
};

export const testables = {
  pointInPolygon,
  getAllBlocks,
};

export const areDefaultFilters = (
  newRoi: RoiArr | null,
  newMinEventLength: number,
  newWithPeople: boolean,
  newSensitivity: number,
) => {
  const { roi, minEventLength, withPeople, sensitivity } = defaultMotionSearch;

  return (
    newRoi === roi &&
    newMinEventLength === minEventLength &&
    newWithPeople === withPeople &&
    newSensitivity === sensitivity
  );
};

/**
 * Keeps the CAMERA_ASPECT_RATIO while fitting the player to the screen.
 */
export function usePlayerSize() {
  const { width, height } = useWindowDimensions();
  const playerSize: { width: DimensionValue; height: DimensionValue } =
    width / height > CAMERA_ASPECT_RATIO
      ? { height: "100%", width: "auto" }
      : { width: "100%", height: "auto" };
  return playerSize;
}
