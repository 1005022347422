import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import SSIDCreateForm from "~/go/components/ssid/SSIDCreateForm";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import withSSIDCreate, { SSIDCreateProps } from "~/hocs/SSIDCreate";
import { showAlert } from "~/lib/AlertUtils";
import {
  getNextOnboardingStageConfig,
  nodesStatusToFooterStatus,
} from "~/lib/OnboardingFullstackUtils";
import { currentNetworkState, hasConfiguredWiFiSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

export interface WiFiSetupCreateScreenProps
  extends BaseOnboardingScreenProps,
    SSIDCreateProps,
    PendingComponent,
    BasicActions {}

type ReduxProps = {
  hasConfiguredWiFi: boolean;
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "WiFiSetupCreate"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps &
  SSIDCreateProps &
  PendingComponent;

interface WiFISetupCreateScreenState {
  name: string;
  password: string;
  isCreatingInProgress: boolean;
}

export class WiFiSetupCreateScreenComponent extends BaseOnboardingScreen<
  Props,
  WiFISetupCreateScreenState
> {
  constructor(props: Props) {
    super(props);
    this.state = { name: "", password: "", isCreatingInProgress: false };

    this.setNavOptions();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { actions, networkId, setReqPending } = this.props;

    setReqPending(true);
    actions
      .getSsids(networkId)
      .then(() => setReqPending(false))
      .catch(this.failedToGetSSIDInfo);
  };

  failedToGetSSIDInfo = () => {
    const { navigation } = this.props;
    showAlert(
      I18n.t("ERROR"),
      I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE.FAILED_TO_GET_SSID_INFO"),
      () => navigation.navigate("SetupComplete"),
    );
  };

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.wifiSetup);
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  onClosePress = () => {
    const { hasConfiguredWiFi } = this.props;
    if (hasConfiguredWiFi) {
      this.close();
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.MESSAGE"),
        undefined,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.CANCEL_BUTTON"),
        },
      );
    }
  };

  onNextPress = () => {
    const { createSSID, handleError } = this.props;
    const { name, password } = this.state;

    this.setState({ isCreatingInProgress: true });

    createSSID(name, password, false)
      .then(this.onSuccess)
      .catch(handleError)
      .finally(() => {
        this.setState({ isCreatingInProgress: false });
      });
  };

  onChangeName = (text: string) => {
    this.setState({ name: text });
  };

  onChangePassword = (text: string) => {
    this.setState({ password: text });
  };

  onSuccess = (ssidNumber: number) => {
    this.onPrimaryPress({
      ssidNumber,
    });
  };

  renderBody = () => {
    const { isCreatingInProgress, name, password } = this.state;

    if (isCreatingInProgress) {
      return (
        <FullScreenContainerView screenStyles={styles.bodyContainerInProgress}>
          <MkiText textStyle="subheading" screenStyles={styles.textInProgress}>
            {I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE.IN_PROGRESS")}
          </MkiText>
        </FullScreenContainerView>
      );
    }

    return (
      <FullScreenContainerView withScroll>
        <View style={styles.bodyContainer}>
          <MkiText textStyle="heading" screenStyles={styles.header}>
            {I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE.HEADER")}
          </MkiText>
          <MkiText textStyle="smallSecondary" screenStyles={styles.message}>
            {I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE.MESSAGE")}
          </MkiText>
          <SSIDCreateForm
            name={name}
            password={password}
            onChangeName={this.onChangeName}
            onChangePassword={this.onChangePassword}
          />
        </View>
      </FullScreenContainerView>
    );
  };

  showConnectAndUpgrade = () => {
    const { navigation } = this.props;
    navigation.navigate("ConnectAndUpgradeStatus");
  };

  getFooterData = () => {
    const { name, isCreatingInProgress } = this.state;
    const { status } = this.props;

    return {
      buttons: [
        <RoundedButton
          key="nextButton"
          onPress={this.onNextPress}
          disabled={isEmpty(name)}
          loading={isCreatingInProgress}
        >
          {this.nextStageConfig.nextButtonText}
        </RoundedButton>,
      ],
      status: nodesStatusToFooterStatus(status),
      onStatusPress: this.showConnectAndUpgrade,
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.wifiSetup);
}

const styles = StyleSheet.create({
  bodyContainer: {
    margin: SPACING.default,
  },
  bodyContainerInProgress: {
    justifyContent: "center",
  },
  header: {
    marginVertical: SPACING.small,
  },
  message: {
    marginBottom: SPACING.default,
  },
  textInProgress: {
    textAlign: "center",
    color: MkiColors.creatingSSIDInProgressText,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasConfiguredWiFi: hasConfiguredWiFiSelector(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for WiFiSetupCreateScreen",
      currentNetworkState(state),
    ),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
  withSSIDCreate,
  withPendingComponent,
)(WiFiSetupCreateScreenComponent);
