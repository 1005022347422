import { PureComponent, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiLegendElement, { MkiLegendElementProps } from "~/shared/components/MkiLegendElement";
import { SelectedElements } from "~/shared/types/MkiChartTypes";

export interface MkiGraphLegendProps {
  children?: ReactNode;
  legendValues: MkiLegendElementProps[];
  selectedElements: SelectedElements;
  onLabelSelected?: (label: string) => void;
  isElementSelected?: (label: string) => boolean;
}

class MkiGraphLegend extends PureComponent<MkiGraphLegendProps> {
  static defaultProps = {
    legendValues: [],
    selectedElements: new Set<string>(),
  };

  render() {
    const {
      children: childrenProp,
      legendValues,
      selectedElements,
      onLabelSelected,
      isElementSelected = (label) => selectedElements.has(label),
    } = this.props;

    const children =
      childrenProp ||
      legendValues.map((value) => (
        <MkiLegendElement
          key={value.label}
          selected={isElementSelected(value.label)}
          onSelectChange={() => onLabelSelected?.(value.label)}
          {...value}
        />
      ));
    return <View style={styles.container}>{children}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: SPACING.small,
  },
});

export default MkiGraphLegend;
