import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  PortDiagram,
  PortStatus,
  Text,
} from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useCableTest } from "@meraki/react-live-broker";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

interface CableTestBottomSheetProps {
  deviceId: string;
  portIds: string[];
}

const NUM_OF_REPS = 10;

const CableTest = ({ deviceId, portIds }: CableTestBottomSheetProps) => {
  const cableTestStatus = useCableTest(deviceId, portIds);

  const [state, setState] = useState<PortStatus["state"] | undefined>(undefined);
  const opacity = useSharedValue(1);

  useEffect(() => {
    if (cableTestStatus) {
      opacity.value = 1;

      const { linkType, pair1, pair2, pair3, pair4 } = cableTestStatus;
      const subGigabitLinkPassed = pair1 && pair2;
      const gigabitLinkPassed = linkType === "1Gfdx" && subGigabitLinkPassed && pair3 && pair4;

      setState(subGigabitLinkPassed || gigabitLinkPassed ? "fullSpeed" : "critical");
    } else if (state === undefined) {
      opacity.value = withRepeat(
        withTiming(0, { duration: 1000, easing: Easing.ease }),
        NUM_OF_REPS,
        true,
        (finished?: boolean) => {
          if (finished && state === undefined) {
            runOnJS(setState)("critical");
            opacity.value = 1;
          }
        },
      );
    }
  }, [cableTestStatus, opacity, state]);

  const animatedStyles = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);
  return (
    <Box gap="sm" alignItems="center">
      <Animated.View style={animatedStyles}>
        <PortDiagram.Port size="md" state={state} testID="CABLE_TEST.PORT_ICON" />
      </Animated.View>
      {state === "critical" ? (
        <Box gap="2xs">
          <Text>{I18n.t("CABLE_TEST.FAIL.DESCRIPTION")}</Text>
          <Text>{I18n.t("CABLE_TEST.FAIL.TROUBLESHOOTING.MESSAGE")}</Text>
        </Box>
      ) : (
        <Text>
          {state === "fullSpeed"
            ? I18n.t("CABLE_TEST.PASS.DESCRIPTION")
            : I18n.t("CABLE_TEST.DESCRIPTION")}
        </Text>
      )}
    </Box>
  );
};

export const CableTestBottomSheet = forwardRef(function CableTestBottomSheet(
  { deviceId, portIds }: CableTestBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref}>
      <BottomSheet.Header
        title={I18n.t("CABLE_TEST.TITLE", { portNumber: String(portIds) })}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => {
          if (typeof ref !== "function") {
            ref?.current?.dismiss();
          }
        }}
      />
      <BottomSheet.Content>
        <CableTest deviceId={deviceId} portIds={portIds} />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
});
