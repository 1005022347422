import { ErrorMessage } from "@hookform/error-message";
import { BottomSheetMethods, Notification } from "@meraki/magnetic/components";
import { Picker, PickerProps } from "@meraki/shared/components";
import { ForwardedRef, forwardRef, PropsWithoutRef, ReactElement, RefAttributes } from "react";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormPickerProps<T> = Omit<PickerProps<T>, "onSelectOption" | "selectedOption"> &
  Partial<Pick<PickerProps<T>, "onSelectOption">> &
  FormItemProps;

// Redecalare forwardRef
// used for generics with FC's
function forwardRefFC<T, P = Record<string, never>>(
  render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: PropsWithoutRef<P> & RefAttributes<T>) => ReactElement | null {
  return forwardRef(render);
}

export const FormPicker = forwardRefFC(function FormPicker<T>(
  { name, rules, options, onSelectOption, ...rest }: FormPickerProps<T>,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const { control, watch } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange } }) => (
          <Picker
            {...rest}
            ref={ref}
            options={options}
            onSelectOption={(option) => {
              onChange(option.value);
              onSelectOption?.(option);
            }}
            selectedOption={options?.find((option) => option.value === watch(name))}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
});
