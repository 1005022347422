import { createActionBatchSchema, PortVlanSchema } from "@meraki/shared/api";
import z from "zod";

export enum AccessPolicyTypes {
  open = "Open",
  custom = "Custom access policy",
  macAllowList = "MAC allow list",
  stikcyMacAllowList = "Sticky MAC allow list",
}

export const AccessPolicyTypeSchema = z
  .union([
    z.literal(AccessPolicyTypes.open),
    z.literal(AccessPolicyTypes.custom),
    z.literal(AccessPolicyTypes.macAllowList),
    z.literal(AccessPolicyTypes.stikcyMacAllowList),
  ] as const)
  .describe("AccessPolicyTypeSchema");

export const SwitchPortSchema = PortVlanSchema.extend({
  portId: z.string(),
  name: z.string().nullable(),
  tags: z.array(z.string()).optional(),
  enabled: z.boolean(),
  poeEnabled: z.boolean(),
  isolationEnabled: z.boolean(),
  rstpEnabled: z.boolean(),
  stpGuard: z.string(),
  linkNegotiation: z.string(),
  linkNegotiationCapabilities: z.array(z.string()),
  portScheduleId: z.string().nullable(),
  udld: z.string(),
  accessPolicyType: AccessPolicyTypeSchema,
  macAllowList: z.array(z.string()).optional(),
  stickyMacAllowList: z.array(z.string()).optional(),
  stickyMacAllowListLimit: z.number().optional(),
  stormControlEnabled: z.boolean().optional(),
}).describe("SwitchPortSchema");

export const SwitchPortStatusSchema = z.object({
  portId: z.string(),
  status: z.string(),
  enabled: z.boolean(),
  errors: z.array(z.string()),
  warnings: z.array(z.string()),
  powerUsageInWh: z.number().optional(),
  speed: z.string().optional(),
  isUplink: z.boolean(),
});

export const BatchUpdateSwitchPortsSchema = createActionBatchSchema(SwitchPortSchema.partial());

export const SwitchPortsSchema = z.array(SwitchPortSchema);

export const SwitchPortsStatusesSchema = z.array(SwitchPortStatusSchema);

export const IMMUTABLE_SWITCH_PORT_KEYS = ["portId", "linkNegotiationCapabilities"] as const;
export const MUTABLE_SWITCH_PORT_KEYS = [
  "name",
  "tags",
  "enabled",
  "poeEnabled",
  "isolationEnabled",
  "rstpEnabled",
  "stpGuard",
  "linkNegotiation",
  "portScheduleId",
  "udld",
  "accessPolicyType",
  "macAllowList",
  "stickyMacAllowList",
  "stickyMacAllowListLimit",
  "stormControlEnabled",
  "type",
  "vlan",
  "allowedVlans",
  "voiceVlan",
] as const;

export type SwitchPort = z.infer<typeof SwitchPortSchema>;
export type StagedSwitchPort = Omit<SwitchPort, (typeof IMMUTABLE_SWITCH_PORT_KEYS)[number]>;
export type ImmutableSwitchPort = Pick<SwitchPort, (typeof IMMUTABLE_SWITCH_PORT_KEYS)[number]>;
export type SwitchPortsStatuses = z.infer<typeof SwitchPortsStatusesSchema>;
export type SwitchPortStatus = z.infer<typeof SwitchPortStatusSchema>;
