import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, List } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  PoliciesByClients,
  useClients,
  useConfiguredSsids,
  usePoliciesByClient,
} from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import { filterData } from "@meraki/shared/filters";
import { addNewlinesWhereCommaDetected } from "@meraki/shared/formatters";
import { toList } from "@meraki/shared/formatters";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { groupBy, isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

type PolicyType = "Blocked" | "Limited";

type ExtendedAssignedPolicy = {
  ssidNames: string[];
  policyType: PolicyType;
};

type ExtendedClientPolicy = {
  name: string;
  id: string;
  mac: string;
  subtitle: string[];
  status?: string | null;
};

export const LimitedBlockedClientsListScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<ConfigureStackProps, "LimitedBlockedClients">>();
  const route = useRoute<RouteProp<ConfigureStackProps, "LimitedBlockedClients">>();
  const { params: props } = route;
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data: ssids } = useConfiguredSsids({ networkId });
  const { data: clients } = useClients({ networkId });
  const {
    data: limitedBlockedClients,
    isLoading,
    isFetching,
  } = usePoliciesByClient(
    { networkId, timespan },
    {
      select: (data) => {
        const policiesPerClient: ExtendedClientPolicy[] = [];
        let filteredData: PoliciesByClients = data;
        if (props.ssidNumber !== undefined) {
          filteredData = data.filter((policy) =>
            policy.assigned.some(
              (assignment) =>
                assignment.type === "ssid" && assignment.ssid.number === props.ssidNumber,
            ),
          );
        }
        filteredData.forEach((clientPolicy) => {
          const clientPolicies = groupBy(clientPolicy.assigned, "name");
          const assignedPolicies: ExtendedAssignedPolicy[] = [];

          for (const policy in clientPolicies) {
            const ssidNames: string[] = [];
            clientPolicies[policy]?.forEach((assigned) => {
              if (assigned.type === "ssid") {
                const thisSsid = ssids?.find((ssid) => ssid.number === assigned.ssid.number);
                ssidNames.push(thisSsid?.name || "");
              }
            });
            assignedPolicies.push({
              policyType: policy === "Blocked" ? "Blocked" : "Limited",
              ssidNames,
            });
          }
          const subtitle: string[] = [];
          assignedPolicies.forEach((policy) => {
            subtitle.push(
              policy.policyType === "Blocked"
                ? I18n.t("CLIENTS_LIST.BLOCKED_ON", { blocked_names: toList(policy.ssidNames) })
                : I18n.t("CLIENTS_LIST.LIMITED_ON", { limited_names: toList(policy.ssidNames) }),
            );
          });
          const thisClient = clients?.find((client) => client.id === clientPolicy.clientId);
          policiesPerClient.push({
            name: clientPolicy.name,
            id: clientPolicy.clientId,
            mac: clientPolicy.mac,
            subtitle,
            status: thisClient?.status,
          });
        });
        return policiesPerClient;
      },
    },
  );
  const [policyTypeFilters, setPolicyTypeFilters] = useState<PolicyType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filteredLimitedBlockedClients, setFilteredLimitedBlockedClients] =
    useState(limitedBlockedClients);
  const searchedLimitedBlockedClients = filterData(
    filteredLimitedBlockedClients || [],
    ["mac", "name", "subtitle"],
    searchText,
  ) as ExtendedClientPolicy[];

  const updateFilteredClientsWithTypeFilter = useCallback(
    ({ policyTypes }: { policyTypes: PolicyType[] }) => {
      if (isEmpty(policyTypes)) {
        setFilteredLimitedBlockedClients(limitedBlockedClients);
      } else {
        setFilteredLimitedBlockedClients(
          limitedBlockedClients?.filter((client) =>
            policyTypes.some((type) => client.subtitle.toString().includes(type)),
          ),
        );
      }
      setPolicyTypeFilters(policyTypes);
    },
    [limitedBlockedClients],
  );

  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);

  useEffect(() => {
    if (!isLoading) {
      updateFilteredClientsWithTypeFilter({ policyTypes: policyTypeFilters });
    }
  }, [policyTypeFilters, updateFilteredClientsWithTypeFilter, isLoading]);

  return (
    <Screen.View gap="none">
      <Box flexDirection="row" paddingTop="sm" alignItems="center" gap="sm">
        <SearchFilterSection
          placeholder={I18n.t("CLIENTS_LIST.LIMITED_AND_BLOCKED_LIST.SEARCH_PLACEHOLDER")}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFilterPress={() => filterBottomSheetRef.current?.present()}
        />
      </Box>
      <FilterTagList
        filters={{
          policyTypes:
            policyTypeFilters.map((policyType) => ({
              value: policyType,
              label:
                policyType === "Blocked"
                  ? I18n.t("CLIENTS_LIST.BLOCKED_LABEL")
                  : I18n.t("CLIENTS_LIST.LIMITED_LABEL"),
            })) ?? [],
        }}
        onUpdateFilters={updateFilteredClientsWithTypeFilter}
      />
      <List.FlashList
        data={searchedLimitedBlockedClients}
        emptyState={{
          title: limitedBlockedClients?.length
            ? I18n.t("CLIENTS_LIST.LIMITED_AND_BLOCKED_LIST.EMPTY_SEARCH")
            : I18n.t("CLIENTS_LIST.LIMITED_AND_BLOCKED_LIST.EMPTY"),
        }}
        getItemData={(client) => {
          return {
            title: client.name,
            description: addNewlinesWhereCommaDetected(client.subtitle.toString()),
            leftAccessory: (
              <Box paddingRight="2xs">
                <Status size={20} status={client.status === "Online" ? "positive" : "negative"} />
              </Box>
            ),
            onPress: () => navigation.navigate("ClientDetails", { id: client.id }),
          };
        }}
        loading={isLoading || isFetching}
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          policyTypes: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.POLICY_TYPE"),
            options: [
              { value: "Blocked", label: I18n.t("CLIENTS_LIST.BLOCKED_LABEL") },
              { value: "Limited", label: I18n.t("CLIENTS_LIST.LIMITED_LABEL") },
            ],
          },
        }}
        initialFilters={{
          policyTypes: policyTypeFilters ?? [],
        }}
        onUpdateFilters={({ policyTypes }) => {
          updateFilteredClientsWithTypeFilter({ policyTypes });
          setPolicyTypeFilters(policyTypes);
        }}
      />
    </Screen.View>
  );
};
