import { request } from "../../../api/request/request";
import { APIResponseError } from "../../../schemas";
import { createMutation } from "../../createMutation";
import { SwitchPort, SwitchPortSchema } from "./useSwitchPorts";

interface UpdateSwitchPortRequest {
  serial?: string;
  portId: string;
  switchPort?: Partial<SwitchPort>;
}

const buildUrl = ({ serial, portId }: UpdateSwitchPortRequest) => {
  return `/api/v1/devices/${serial}/switch/ports/${portId}`;
};

const fetchSwitchPorts = ({
  serial,
  portId,
  switchPort,
}: UpdateSwitchPortRequest): Promise<SwitchPort> => {
  return request(SwitchPortSchema, "PUT", buildUrl({ serial, portId }), {
    body: JSON.stringify(switchPort),
  });
};

export const useUpdateSwitchPort = createMutation<
  UpdateSwitchPortRequest,
  SwitchPort,
  APIResponseError
>({
  baseMutationKey: buildUrl({ serial: `{serial}`, portId: `{portId}` }),
  mutationFn: (variables) => fetchSwitchPorts(variables),
});
