import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface DeviceConnectionStatsRequest {
  networkId?: string;
  timespan?: number;
  ssid?: number;
  vlan?: number;
  band?: string;
  apTag?: string;
}

const DeviceConnectionStatSchema = z.object({
  assoc: z.number(),
  auth: z.number(),
  dhcp: z.number(),
  dns: z.number(),
  success: z.number(),
});

export const DeviceBySerialConnectionStatSchema = z.object({
  serial: z.string(),
  connectionStats: DeviceConnectionStatSchema,
});
export const DeviceConnectionStatsSchema = z.array(DeviceBySerialConnectionStatSchema);

export type DeviceConnectionStat = z.infer<typeof DeviceConnectionStatSchema>;
export type DeviceBySerialConnectionStat = z.infer<typeof DeviceBySerialConnectionStatSchema>;
export type DeviceConnectionStats = z.infer<typeof DeviceConnectionStatsSchema>;

const buildUrl = ({ networkId }: DeviceConnectionStatsRequest) => {
  return `/api/v1/networks/${networkId}/wireless/devices/connectionStats`;
};

const fetchDeviceConnectionStats = ({
  networkId,
  ...queryParams
}: DeviceConnectionStatsRequest) => {
  return request(DeviceConnectionStatsSchema, "GET", buildUrl({ networkId }), { queryParams });
};

export const useDeviceConnectionStats = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: DeviceConnectionStatsRequest) => fetchDeviceConnectionStats(parameters),
  requiredVariables: ["networkId"],
});
