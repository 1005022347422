import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import SummaryCard from "~/shared/components/SummaryCard";
import WirelessHealthAssocOverview from "~/shared/components/WirelessHealthAssocOverview";

type AssociationStatsProps = {
  isLoading: boolean;
};

export const AssociationStatsCard = ({ isLoading }: AssociationStatsProps) => {
  return (
    <View style={styles.containerStyle}>
      <SummaryCard
        heading={I18n.t("ASSOCIATION_STATS.SUMMARY_HEADER.HEADING")}
        subheading={I18n.t("ASSOCIATION_STATS.SUMMARY_HEADER.SUBHEADING")}
        contentContainerStyles={styles.contentStyle}
        loading={isLoading}
      >
        <WirelessHealthAssocOverview />
      </SummaryCard>
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyle: {
    paddingVertical: SPACING.default,
  },
  contentStyle: {
    borderBottomWidth: 0,
  },
});

export default AssociationStatsCard;
