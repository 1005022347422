import { I18n } from "@meraki/core/i18n";
import { Children } from "react";
import { Image, ImageProps, StyleSheet, Text, View } from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import MCIcons from "react-native-vector-icons/MaterialCommunityIcons";

import { SPACING } from "~/constants/MkiConstants";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import MerakiIcon, { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";

import PressableOpacity from "../components/PressableOpacity";
import { useThemeColors } from "../hooks/useTheme";

const BTN_HORIZONTAL_MARGIN = isWeb() ? SPACING.default : 0;
const BTN_FONT_SIZE = 18;

type StyledButtonProps = ButtonProps & {
  title?: string;
};

type ImageButtonProps = ButtonProps & {
  source: ImageProps["source"];
};

type IconButtonProps = ButtonProps & {
  name: string;
  size: MerakiIconProps["size"];
};

type ButtonProps = {
  testID?: string;
  onPress: () => void;
  disabled?: boolean;
};

const StyledButton = ({ onPress, title, disabled, testID }: StyledButtonProps) => {
  const colors = useThemeColors();
  return (
    <PressableOpacity onPress={onPress} disabled={disabled} testID={testID}>
      <Text
        style={{
          color: disabled ? colors.inactive?.color : colors.navigation.primary,
          fontSize: BTN_FONT_SIZE,
          marginHorizontal: BTN_HORIZONTAL_MARGIN,
        }}
      >
        {title}
      </Text>
    </PressableOpacity>
  );
};

const ImageButton = ({ onPress, source, testID }: ImageButtonProps) => {
  const colors = useThemeColors();
  return (
    <PressableOpacity onPress={onPress} testID={testID}>
      <Image
        style={{
          tintColor: colors.navigation.primary,
          width: SPACING.large,
          height: SPACING.large,
          marginHorizontal: BTN_HORIZONTAL_MARGIN,
        }}
        source={source}
      />
    </PressableOpacity>
  );
};

const IconButton = ({ onPress, name, size, testID }: IconButtonProps) => {
  const colors = useThemeColors();
  return (
    <PressableOpacity onPress={onPress} testID={testID}>
      <MerakiIcon name={name} color={colors.navigation.primary} size={size} />
    </PressableOpacity>
  );
};

export const RefreshButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={isWeb() ? undefined : MCIcons.getImageSourceSync("refresh", 24)}
      testID="REFRESH_BUTTON"
    />
  );
};

export const LicensesButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={
        isWeb()
          ? require("~/images/navBar/licenses_web.png")
          : require("~/images/navBar/licenses.png")
      }
      testID="LISCENSES_BUTTON"
    />
  );
};

export const LeftMenuButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={Ionicons.getImageSourceSync("ios-menu", 28)}
      testID="LEFT_MENU_BUTTON"
    />
  );
};

export const ListButton = (props: ButtonProps) => {
  return (
    <ImageButton {...props} source={Ionicons.getImageSourceSync("list", 24)} testID="LIST_BUTTON" />
  );
};

export const GridButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={Ionicons.getImageSourceSync("grid-outline", 24)}
      testID="GRID_BUTTON"
    />
  );
};

export const HeaderButtonGroup = ({ children }: { children: React.ReactNode }) => {
  return (
    <View style={styles.headerButtonGroupContainer}>
      {Children.map(children, (child: React.ReactNode) => {
        return <View style={styles.headerButtonGroupChild}>{child}</View>;
      })}
    </View>
  );
};

export const MountingButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={require("~/enterprise/screens/onboarding/icons8-user-manual-32.png")}
      testID="MOUNTING_BUTTON"
    />
  );
};

export const AddButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={platformSelect({
        mobile: require("~/images/navBar/add.png"),
        web: require("~/images/navBar/add_web.png"),
      })}
      testID="ADD_BUTTON"
    />
  );
};

export const ConfigButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={isWeb() ? undefined : MCIcons.getImageSourceSync("cog", 24)}
      testID="CONFIG_BUTTON"
    />
  );
};

export const TopologyButton = (props: ButtonProps) => {
  return <IconButton {...props} name="server-network" size={24} testID="TOPOLOGY_BUTTON" />;
};

export const ExportButton = (props: ButtonProps) => {
  return <IconButton {...props} name="export" size={24} testID="EXPORT_BUTTON" />;
};

export const PDFShareButton = (props: StyledButtonProps) => {
  return (
    <StyledButton {...props} title={I18n.t("SSID_SHARE.PDF_BUTTON")} testID="PDF_SHARE_BUTTON" />
  );
};

export const ExitButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={require("~/images/navBar/exit-circle.png")}
      testID="EXIT_BUTTON"
    />
  );
};

export const XButton = (props: ButtonProps) => {
  return <IconButton {...props} name="close" size={24} testID="CLOSE_BUTTON" />;
};

export const TextAddButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("ADD")} testID="TEXT_ADD_BUTTON" />;
};

export const SettingsButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("SETTINGS_WORD")} testID="SETTINGS_BUTTON" />;
};

export const FloorPlanButton = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={require("~/images/navBar/floor-plan.png")}
      testID="FLOOR_PLAN_BUTTON"
    />
  );
};

export const PreviewFileButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("PREVIEW_FILE")} testID="PREVIEW_FILE_BUTTON" />;
};

export const SaveButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("SAVE")} testID="SAVE_BUTTON" />;
};

export const SetUsageButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("SET_USAGE.BUTTON")} />;
};

export const CancelButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("CANCEL")} testID="CANCEL_BUTTON" />;
};

export const CloseButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("CLOSE")} testID="CLOSE_BUTTON" />;
};

export const BackButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title="<" testID="BACK_BUTTON" />;
};

export const CloseChevron = (props: ButtonProps) => {
  return (
    <ImageButton
      {...props}
      source={Ionicons.getImageSourceSync("chevron-down", 24)}
      testID="CLOSE_BUTTON"
    />
  );
};

export const DoneButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("DONE")} testID="DONE_BUTTON" />;
};

export const NextButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("NEXT")} testID="NEXT_BUTTON" />;
};

export const ResetButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("RESET")} testID="RESET_BUTTON" />;
};

export const EditButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} title={I18n.t("EDIT")} testID="EDIT_BUTTON" />;
};

export const TextButton = (props: StyledButtonProps) => {
  return <StyledButton {...props} testID="TEXT_BUTTON" />;
};

// select button

const styles = StyleSheet.create({
  headerButtonGroupContainer: {
    flexDirection: "row",
  },
  headerButtonGroupChild: {
    marginStart: 20,
  },
});
