import Dark from "~/enterprise/themes/Dark";
import SharedColors from "~/shared/constants/SharedColors";
import ThemeInterface from "~/shared/themes/ThemeInterface";

const Amoled: ThemeInterface = {
  ...Dark,
  navigation: {
    ...Dark.navigation,
    backgroundPrimary: SharedColors.trueBlack,
    backgroundSecondary: SharedColors.trueBlack,
  },
};

export default Amoled;
