import { config } from "@meraki/core/dev-config";
import { Clusters } from "@meraki/shared/redux";
import {
  GIT_HASH,
  LAUNCH_DARKLY_KEY as LAUNCH_DARKLY_KEY_ENV,
  SENTRY_DSN_DM,
  SENTRY_DSN_DM_NIGHTLY,
  SENTRY_DSN_GO,
  SENTRY_DSN_GO_NIGHTLY,
  SENTRY_DSN_GO_WEB,
} from "react-native-dotenv";

import { MASTER_ID } from "~/constants/MkiConstants";
import { appSelect, isNightly, isWeb, platformSelect } from "~/lib/PlatformUtils";

export const DETOX = process.env.E2E || config.useEphemeral || false;

const LAUNCH_DARKLY_CLIENT_KEY = isWeb()
  ? "64e396f65ed18b14dfe940f6"
  : "mob-b63b2bbc-5bb1-4f92-b442-153351061c38";
export const LAUNCH_DARKLY_KEY = LAUNCH_DARKLY_KEY_ENV ?? LAUNCH_DARKLY_CLIENT_KEY;

let isDev = false;
let user: any = null;
const EPHEMERAL_PREFIX = `n1.${
  appSelect({
    go: process.env.GO_EPHEMERAL_E2E_PREFIX,
    enterprise: process.env.DM_EPHEMERAL_E2E_PREFIX,
  }) ||
  config.ephemeralPrefix ||
  config.mkiUser
}`;

if (__DEV__) {
  const { useDev, mkiUser } = config;
  isDev = useDev;
  user = mkiUser;
}

export const isJestEnv = process.env.NODE_ENV === "test";

export const SENTRY_DSN = isNightly
  ? appSelect({
      enterprise: SENTRY_DSN_DM_NIGHTLY,
      go: platformSelect({
        mobile: SENTRY_DSN_GO_NIGHTLY,
        web: SENTRY_DSN_GO_WEB,
      }),
    })
  : appSelect({
      enterprise: SENTRY_DSN_DM,
      go: platformSelect({
        mobile: SENTRY_DSN_GO,
        web: SENTRY_DSN_GO_WEB,
      }),
    });

export const getRevision = () => GIT_HASH || "0";

const devMasterURL = () => `https://n1.${user}.dev.ikarem.io`;

const GO_MAIN_PROXY = "/main";
const GO_SHARD_PROXY = "/shard";
const GO_ACCOUNT_PROXY = "/account";

export const masterURL = (cluster?: Clusters): string => {
  if (isWeb()) {
    return GO_MAIN_PROXY;
  }

  switch (cluster) {
    case "sandbox":
      return "https://n2.sandbox.meraki.com";
    case "devel":
      return "https://n1.meraki.dev.ikarem.io";
    case "china":
      return "https://dashboard.meraki.cn";
    case "ephemeral":
      return `https://${EPHEMERAL_PREFIX}.ephemeral.exp.ikarem.io`;
    default:
      return isDev ? devMasterURL() : "https://dashboard.meraki.com";
  }
};

export const ssoAuthCookie = (cluster?: Clusters): string =>
  isDev || cluster === "devel" ? "devel_dash_auth" : "dash_auth";

type ShardId = number | string | undefined;

export const shardURL = (shardId: ShardId = MASTER_ID, cluster?: Clusters): string => {
  if (isWeb()) {
    return `${GO_SHARD_PROXY}/${shardId}`;
  }

  switch (cluster) {
    case "sandbox":
      return `https://n${shardId}.sandbox.meraki.com`;
    case "china":
      return `https://n${shardId}.meraki.cn`;
    case "devel":
      return `https://n${shardId}.meraki.dev.ikarem.io`;
    case "ephemeral":
      return `https://${EPHEMERAL_PREFIX}.ephemeral.exp.ikarem.io`;
    default:
      return isDev ? `https://n${shardId}.${user}.dev.ikarem.io` : `https://n${shardId}.meraki.com`;
  }
};

export const cookieDomain = (cluster?: Clusters): string => {
  if (isWeb()) {
    return `${GO_SHARD_PROXY}`;
  }

  switch (cluster) {
    case "sandbox":
      return `.sandbox.meraki.com`;
    case "devel":
      return `.ikarem.io`;
    case "china":
      return `.meraki.cn`;
    default:
      return isDev ? `.ikarem.io` : `.meraki.com`;
  }
};

export const goPortalURL = (cluster: Clusters): string => {
  if (isWeb()) {
    return GO_ACCOUNT_PROXY;
  }

  switch (cluster) {
    case "sandbox":
      return "https://account.sandbox.meraki-go.com";
    case "devel":
      return "https://account.meraki.meraki-go.ikarem.io";
    case "china":
      return "https://account.meraki-go.cn";
    default:
      return isDev ? devMasterURL() : "https://account.meraki-go.com";
  }
};

export const umbrellaURL = () => (isWeb() ? "/umbrella" : "https://api.umbrella.com");

export const marketoURL = () => (isWeb() ? "/marketo" : "https://010-KNZ-501.mktorest.com");

export const documentationURL = () =>
  isWeb() ? "/documentation" : "https://documentation.meraki.com";

export const communityURL = () => (isWeb() ? "/community" : "https://community.meraki.com");

export const apiURL = (cluster: Clusters) => {
  if (isWeb()) {
    return "/go-api";
  }

  if (isDev) {
    return `https://api.${user}.dev.ikarem.io`;
  }

  switch (cluster) {
    case "devel":
      return "https://n1.meraki.dev.ikarem.io";
    case "sandbox":
      return "https://api.sandbox.meraki.com";
    case "china":
      return "https://api.meraki.cn";
    case "ephemeral":
      return `https://${EPHEMERAL_PREFIX}.ephemeral.exp.ikarem.io`;
    default:
      return "https://api.meraki.com";
  }
};
