import { useEffect, useState } from "react";

import MkiColors from "~/constants/MkiColors";
import { BioAuthTypes, getBioAuthType } from "~/lib/BioAuth";
import MerakiIcon from "~/shared/components/icons";
import Touchable from "~/shared/components/Touchable";

interface BioAuthButtonProps {
  onPress: () => void;
}

const BIO_ICON = {
  [BioAuthTypes.touchId]: "fingerprint",
  [BioAuthTypes.faceId]: "face",
};

const BioAuthButton = (props: BioAuthButtonProps) => {
  const { onPress } = props;
  const [bioType, setBioType] = useState<BioAuthTypes | undefined>(undefined);

  useEffect(() => {
    getBioAuthType().then((bioType: BioAuthTypes | undefined) => {
      setBioType(bioType);
    });
  });

  return bioType ? (
    <Touchable testID={"BIO_AUTH"} onPress={onPress} transparentBackground>
      <MerakiIcon name={BIO_ICON[bioType]} size="s" color={MkiColors.primaryButton} />
    </Touchable>
  ) : null;
};

export default BioAuthButton;
