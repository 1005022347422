import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const UpdateSystemManagerSchema = z.object({
  id: z.string(),
  name: z.string(),
  notes: z.string(),
  serial: z.string(),
  wifiMac: z.nullable(z.string()),
});

export const SystemManagerDeviceSchema = z.object({
  batteryEstCharge: z.unknown(),
  bestLat: z.optional(z.union([z.string(), z.number()])),
  bestLon: z.optional(z.union([z.string(), z.number()])),
  hasChromeMdm: z.boolean(),
  id: z.string(),
  imei: z.nullable(z.string()),
  ip: z.nullable(z.string()),
  lastConnected: z.number(),
  location: z.optional(z.string()),
  name: z.string(),
  osName: z.string(),
  ownerUsername: z.nullable(z.string()),
  phoneNumber: z.nullable(z.string()),
  publicIp: z.string(),
  serialNumber: z.string(),
  ssid: z.nullable(z.string()),
  systemModel: z.string(),
  tags: z.array(z.string()),
  uuid: z.string(),
  wifiMac: z.nullable(z.string()),
});

export const SystemManagerDevicesSchema = z.array(SystemManagerDeviceSchema);

type SystemManagerDevice = z.infer<typeof SystemManagerDeviceSchema>;
type SystemManagerDevices = z.infer<typeof SystemManagerDevicesSchema>;
type UpdateSystemManager = z.infer<typeof UpdateSystemManagerSchema>;

const SMFields = [
  "ip",
  "batteryEstCharge",
  "ownerUsername",
  "location",
  "imei",
  "lastConnected",
  "phoneNumber",
  "publicIp",
  "bestLat",
  "bestLon",
];

interface SystemManagerRequest {
  networkId?: string;
}

interface SystemManagerPutRequest {
  networkId: string;
  device: SystemManagerDevice;
}

const buildQueryUrl = ({ networkId }: SystemManagerRequest) => {
  return `/api/v1/networks/${networkId}/sm/devices`;
};

const buildMutationUrl = ({ networkId }: SystemManagerRequest) => {
  return `/api/v1/networks/${networkId}/sm/devices/fields`;
};

const fetchSystemManagerData = (variables: SystemManagerRequest) => {
  return request(SystemManagerDevicesSchema, "GET", buildQueryUrl(variables), {
    queryParams: { fields: SMFields },
  });
};

const mutateSystemManagerData = ({ networkId, device }: SystemManagerPutRequest) => {
  if (!device.id) {
    throw new Error("SM Device missing id");
  }
  const body = JSON.stringify({
    id: device.id,
    deviceFields: {
      name: device.name,
    },
  });
  return request(UpdateSystemManagerSchema, "PUT", buildMutationUrl({ networkId }), { body });
};

export const useSystemManagerDevices = createQuery<SystemManagerRequest, SystemManagerDevices>({
  baseQueryKey: buildQueryUrl({ networkId: "{network_id}" }),
  queryFn: (variables) => fetchSystemManagerData(variables),
  requiredVariables: ["networkId"],
});

export const useUpdateSystemManagerDevice = createMutation<
  SystemManagerPutRequest,
  UpdateSystemManager
>({
  baseMutationKey: buildMutationUrl({ networkId: "{network_id}" }),
  mutationFn: (variables) => mutateSystemManagerData(variables),
});

export default useSystemManagerDevices;
