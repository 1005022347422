import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import I18n from "~/i18n/i18n";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { isBoolean, isString } from "~/lib/TypeHelper";
import ListRow, { ListRowProps } from "~/shared/rows/ListRow";

// TODO: Extend ListRowProps when we convert ListRow to TS
export type TwoColumnRowProps = Pick<ListRowProps, "onPress" | "onLongPress"> & {
  style?: StyleProp<ViewStyle>;
  title?: string | React.ReactNode;
  value?: string | number | boolean | React.ReactElement | null;
  shouldCapitalizeFirstLetter?: boolean;
  accessory?: React.ReactNode;
};

const TwoColumnRow = (props: TwoColumnRowProps) => {
  const { onPress, onLongPress, style, accessory, value, title, shouldCapitalizeFirstLetter } =
    props;
  const rowStyles: StyleProp<ViewStyle>[] = [styles.rowStyles];
  if (style) {
    rowStyles.push(style);
  }

  let derivedValue = value;
  if (isBoolean(value)) {
    derivedValue = value ? I18n.t("ENABLED") : I18n.t("DISABLED");
  }

  if (shouldCapitalizeFirstLetter && isString(derivedValue)) {
    derivedValue = capitalizeFirstLetter(derivedValue);
  }

  return (
    <ListRow
      onPress={onPress}
      onLongPress={onLongPress}
      accessory={accessory}
      labelStyle={styles.detailLabel}
      leftStyle={styles.leftContent}
      rightStyle={styles.rightContent}
      rightTextStyle={styles.leftAlign}
      rowStyles={rowStyles}
      value={derivedValue}
    >
      {title}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: appSelect({
      enterprise: SPACING.default,
      go: 0,
    }),
    alignItems: "flex-end",
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  leftAlign: {
    textAlign: "left",
  },
  detailLabel: {
    color: appSelect({
      enterprise: TEXT_COLORS.secondary,
      go: MkiColors.secondaryTextColor,
    }),
  },
});

export default TwoColumnRow;
