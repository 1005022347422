import { StyleSheet, View, ViewProps } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import i18n from "~/i18n/i18n";
import MkiText, { MkiTextProps } from "~/shared/components/MkiText";
import PressableOpacity, { PressableOpacityProps } from "~/shared/components/PressableOpacity";
import { useThemeColors } from "~/shared/hooks/useTheme";

export function EmptyState(props: ViewProps) {
  return <View {...props} style={styles.container} />;
}

export function EmptyStatePrimaryText(props: MkiTextProps) {
  return <MkiText {...props} screenStyles={styles.primaryText} />;
}

export function EmptyStateSecondaryText(props: MkiTextProps) {
  return <MkiText {...props} screenStyles={styles.secondaryText} />;
}

export const learnMoreText = i18n.t("LEARN_MORE");

export function LearnMoreLink(props: PressableOpacityProps) {
  const themeColors = useThemeColors();
  return (
    <PressableOpacity {...props} accessibilityRole="link" accessibilityLabel={learnMoreText}>
      <MkiText
        screenStyles={[
          {
            color: themeColors.navigation.primary,
          },
          styles.secondaryText,
        ]}
      >
        {learnMoreText}
      </MkiText>
    </PressableOpacity>
  );
}

const styles = StyleSheet.create({
  container: { margin: SPACING.large, flex: 1, justifyContent: "center", alignItems: "center" },
  primaryText: { fontSize: 18, fontWeight: "600", textAlign: "center" },
  secondaryText: { fontSize: 14, fontWeight: "400", textAlign: "center" },
});
