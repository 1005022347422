import { INDOOR, OUTDOOR } from "@meraki/shared/devices";
import {
  ADD_ONBOARDING_SCANNED,
  ASK_FOR_BIO_AUTH,
  GET_FIRMWARE_UPGRADES_SUCCESS,
  GET_WIRELESS_SETTINGS_SUCCESS,
  HIDE_ASK_FOR_BIO_AUTH,
  HIDE_MESSAGE_BANNER,
  INCREASE_APP_SURVEY_DISMISSALS,
  INCREASE_HOME_VISITS,
  LOGIN_SUCCESS,
  preferences as LibPreferences,
  preferencesInitialState as LibInitialState,
  PROMPT_SET_BIOAUTH,
  SET_BIOMETRIC_AUTH,
  SET_CAMERA_GRID_PREFERRED,
  SET_CAMERA_LIST_PREFERRED,
  SET_DEFAULT_NETWORK,
  SET_FIRMWARE_UPGRADES_SUCCESS,
  SET_HOME_SCREEN_CARDS_ORDER,
  SET_HOME_SSID,
  SET_IS_SAML_USER,
  SET_LOCATION_ANALYTICS_ENABLED_SUCCESS,
  SET_ONBOARDING_STEP,
  SET_ONBOARDING_UNCONFIGURED,
  SET_ONBOARDING_WIRE_VS_MESH,
  SET_SSO_SUBDOMAIN,
  UNPROMPT_SET_BIOAUTH,
  UPDATE_EXPANDABLE_CARDS,
  UPDATE_PDF_INFO,
  UPDATE_VISIBLE_INLINE_ALERTS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { isEmpty, merge } from "lodash";

import { getIndoorOrOutdoorFromSerial } from "~/lib/DeviceUtils";
import { reducerHelper } from "~/lib/ReducerUtils";
import { Preferences } from "~/shared/types/Preferences";

// FIXME: This file needs to be refactored to be properly typed to address this
// The problem here is the Preferences type asks for timespan to be a number but
// then allows any number of additional properties to be of type UserPreferences.
// The TS compiler has no way of knowing whether the property timespan should be
// a UserPreference or a number. This can be fixed by nesting the UserPreferences
// object under a "userPreferences" key on the Preferences type but that could entail
// breakages if untyped code relies on its current shape.
// @ts-expect-error TS(2322) FIXME: Type '{ timespan: number; isSamlUser: false; }' is... Remove this comment to see the full error message
const initialState: Preferences = {
  ...LibInitialState,
  isSamlUser: false,
  migratedTheme: false,
  migratedBioauth: false,
};

export default function preferences(state: Preferences = initialState, action: any) {
  const user = state.currentUser;

  const {
    type,
    biometricAuth,
    networkId,
    onboardingStep,
    wireCount,
    meshCount,
    unconfiguredCount,
    serial,
    homeSSID,
    expandableCards,
    response,
    isSamlUser,
    ssoSubdomain,
    homeScreenCardsOrder,
    dismissalCount,
    visibleInlineAlerts,
  } = action;

  const scannedHardwareInitial = {
    serials: [],
    indoorCount: 0,
    outdoorCount: 0,
  };

  // type casting due to the LibPreferences returns a subset of Preferences.
  const newState = LibPreferences(state, action) as Preferences;

  if (type === "SET_MIGRATED_THEME_STATE") {
    return {
      ...state,
      migratedTheme: true,
    };
  }
  if (type === "SET_MIGRATED_BIOAUTH") {
    return {
      ...state,
      migratedBioauth: true,
    };
  }

  if (user === undefined) {
    return newState;
  }

  const { promptSetBioAuth } = state[user] ?? {};

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          promptSetBioAuth: promptSetBioAuth ?? true,
        },
      };
    case SET_BIOMETRIC_AUTH:
      return reducerHelper(newState, user, { biometricAuth: biometricAuth });
    case SET_IS_SAML_USER:
      return {
        ...newState,
        isSamlUser: !!isSamlUser,
      };
    case SET_SSO_SUBDOMAIN:
      return {
        ...newState,
        ssoSubdomain: ssoSubdomain,
      };
    case SET_DEFAULT_NETWORK:
      return reducerHelper(newState, user, { defaultNetwork: networkId });
    case SET_ONBOARDING_STEP:
      return merge({}, newState, {
        [user]: { onboardingStep: onboardingStep },
      });
    case SET_ONBOARDING_WIRE_VS_MESH:
      return merge({}, newState, {
        [user]: { wireCount: wireCount, meshCount: meshCount },
      });
    case SET_ONBOARDING_UNCONFIGURED:
      if (unconfiguredCount === 0) {
        return {
          ...newState,
          [user]: {
            ...newState[user],
            unconfiguredCount: unconfiguredCount,
            wireCount: 0,
            meshCount: 0,
            scannedHardware: scannedHardwareInitial,
          },
        };
      }
      return {
        ...newState,
        [user]: {
          ...newState[user],
          unconfiguredCount: unconfiguredCount,
          scannedHardware: {
            ...(isEmpty(newState[user].scannedHardware)
              ? scannedHardwareInitial
              : newState[user].scannedHardware),
          },
        },
      };
    case ADD_ONBOARDING_SCANNED: {
      const scanned = newState[user].scannedHardware;
      const serials = [...scanned.serials, serial];
      const indoorIncrement = getIndoorOrOutdoorFromSerial(serial) === INDOOR ? 1 : 0;
      const outdoorIncrement = getIndoorOrOutdoorFromSerial(serial) === OUTDOOR ? 1 : 0;
      return merge({}, newState, {
        [user]: {
          scannedHardware: {
            serials,
            indoorCount: scanned.indoorCount + indoorIncrement,
            outdoorCount: scanned.outdoorCount + outdoorIncrement,
          },
        },
      });
    }
    case SET_HOME_SSID:
      return merge({}, newState, { [user]: { homeSSID: homeSSID } });
    case SET_LOCATION_ANALYTICS_ENABLED_SUCCESS:
    case GET_FIRMWARE_UPGRADES_SUCCESS:
    case SET_FIRMWARE_UPGRADES_SUCCESS:
    case GET_WIRELESS_SETTINGS_SUCCESS:
      return reducerHelper(newState, user, { ...response });
    case INCREASE_HOME_VISITS: {
      const previousHomeVisits = newState?.[user]?.numberOfHomeVisits || 0;
      const newHomeVisits = Math.min(previousHomeVisits + 1, 5);
      return reducerHelper(newState, user, { numberOfHomeVisits: newHomeVisits });
    }
    case INCREASE_APP_SURVEY_DISMISSALS: {
      const previousAppSurveyDismissals = newState?.[user]?.appSurveyDismissals || 0;
      return reducerHelper(newState, user, {
        appSurveyDismissals: previousAppSurveyDismissals + dismissalCount,
      });
    }
    case UPDATE_EXPANDABLE_CARDS: {
      const previousCards = newState?.[user]?.expandableCards || {};
      return reducerHelper(newState, user, {
        expandableCards: { ...previousCards, ...expandableCards },
      });
    }
    case UPDATE_PDF_INFO: {
      const oldPDFInfo = newState[user]?.pdfInfo
        ? newState[user]?.pdfInfo
        : {
            [action.networkId]: {
              [action.ssidNumber]: {},
            },
          };

      const pdfInfo = {
        ...oldPDFInfo,
        [action.networkId]: {
          ...oldPDFInfo[action.networkId],
          [action.ssidNumber]: {
            ...action.pdfInfo,
          },
        },
      };

      return {
        ...newState,
        [user]: {
          ...newState[user],
          pdfInfo,
        },
      };
    }
    case ASK_FOR_BIO_AUTH:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          askForBioAuthType: action.payload.biometryType,
        },
      };
    case HIDE_ASK_FOR_BIO_AUTH:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          hideAskForBioAuth: true,
        },
      };
    case UNPROMPT_SET_BIOAUTH:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          promptSetBioAuth: false,
        },
      };
    case PROMPT_SET_BIOAUTH:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          promptSetBioAuth: true,
        },
      };
    case HIDE_MESSAGE_BANNER:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          hideMessageBanner: true,
        },
      };
    case SET_CAMERA_GRID_PREFERRED:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          cameraGridPreferred: true,
        },
      };
    case SET_CAMERA_LIST_PREFERRED:
      return {
        ...newState,
        [user]: {
          ...newState[user],
          cameraGridPreferred: false,
        },
      };
    case SET_HOME_SCREEN_CARDS_ORDER:
      return reducerHelper(newState, user, { homeScreenCardsOrder });
    case UPDATE_VISIBLE_INLINE_ALERTS:
      const previousVisibleInlineAlerts = newState?.[user]?.visibleInlineAlerts || {};
      return reducerHelper(newState, user, {
        visibleInlineAlerts: {
          ...previousVisibleInlineAlerts,
          ...visibleInlineAlerts,
        },
      });
    case WIPE_REDUX:
      return reducerHelper(newState, user, { visibleInlineAlerts: {} });
    default:
      return newState;
  }
}
