import { I18n } from "@meraki/core/i18n";
import { Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { mapColorToken, useMagneticTheme } from "@meraki/magnetic/themes";
import SvgChart from "@wuba/react-native-echarts/svgChart";
import Color from "color";
import type {
  DimensionDefinitionLoose,
  EChartsOption,
  LabelFormatterParams,
  ScaleDataValue,
} from "echarts";
import * as echarts from "echarts/core";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { baseChartColors, getColorForIndex } from "../utils/colors";
import { ChartData, getDimensionName } from "../utils/data";

type ChartSize = "sm" | "md" | "lg";
interface BarChartProps {
  testID?: string;
  size?: ChartSize;
  data: ChartData;
  dimensions: DimensionDefinitionLoose[];
  showAnimation?: boolean;
  showLegend?: boolean;
  loading?: boolean;
  tooltipLabelFormatter?: (timespan: ScaleDataValue) => string;
  xAxisFormatter?: (timespan: ScaleDataValue | number) => string;
  yAxisFormatter?: (value: object | string | number) => string;
}

const getAspectRatio = (width: number, size: ChartSize) => {
  switch (size) {
    case "sm":
      return width >= 480 ? 0.15 : 0.3;
    case "md":
      return width >= 480 ? 0.3 : 0.6;
    case "lg":
      return width >= 480 ? 0.5 : 0.8;
  }
};

export function BarChart({
  testID,
  size = "md",
  data,
  showAnimation = true,
  showLegend = true,
  loading = false,
  xAxisFormatter,
  yAxisFormatter,
  tooltipLabelFormatter,
  dimensions,
}: BarChartProps) {
  const theme = useMagneticTheme();
  const chartRef = useRef(null);
  const [chartInstance, setChart] = useState<echarts.EChartsType | null>(null);

  const [width, setWidth] = useState<number | null>(null);
  const showEmptyState = isEmpty(data);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  // This effect is responsible for the basic setup of the chart instance.
  useEffect(() => {
    if (!width) return;

    const chart = echarts.init(chartRef.current, "light", {
      renderer: "svg",
      width: !!width && width > 0 ? width : undefined,
      height: !!width && width > 0 ? width * getAspectRatio(width, size) : undefined,
    });

    setChart(chart);

    return () => chart.dispose();
  }, [size, width]);

  // This effect is responsible for setting the basic chart options
  useEffect(() => {
    chartInstance?.setOption({
      animation: showAnimation,
      animationDuration: 500,
      animationEasingUpdate: "quinticInOut",
      grid: {
        containLabel: true,
        top: showLegend ? 25 : 10,
        left: 0,
        right: 0,
        bottom: 0,
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
          filterMode: "none",
        },
      ],
      tooltip: {
        show: !showEmptyState,
        trigger: "axis",
        confine: true,
        backgroundColor: mapColorToken(baseChartColors.toolTipBackground, theme),
        borderColor: mapColorToken(baseChartColors.toolTipBorder, theme),
        valueFormatter: yAxisFormatter,
        textStyle: {
          color: mapColorToken(baseChartColors.toolTipText, theme),
        },
        axisPointer: {
          lineStyle: {
            type: "solid",
            color: mapColorToken(baseChartColors.toolTipBorder, theme),
          },
          label: {
            formatter:
              tooltipLabelFormatter || xAxisFormatter
                ? (params: LabelFormatterParams): string => {
                    return (
                      tooltipLabelFormatter?.(params.value) || xAxisFormatter?.(params.value) || ""
                    );
                  }
                : undefined,
          },
        },
      },
      legend: {
        show: showLegend,
        top: 0,
        right: 5,
        icon: "roundRect",
        textStyle: { color: mapColorToken(baseChartColors.axisText, theme) },
      },
      xAxis: {
        type: "category",
        axisLabel: {
          color: mapColorToken(baseChartColors.axisText, theme),
          formatter: xAxisFormatter,
        },
        axisLine: {
          lineStyle: {
            color: mapColorToken(baseChartColors.axisLines, theme),
          },
        },
      },
      yAxis: {
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: mapColorToken(baseChartColors.gridLines, theme),
          },
        },
        axisLabel: {
          color: mapColorToken(baseChartColors.axisText, theme),
          formatter: yAxisFormatter,
        },
      },
    });
  }, [
    chartInstance,
    showAnimation,
    showEmptyState,
    showLegend,
    theme,
    tooltipLabelFormatter,
    width,
    xAxisFormatter,
    yAxisFormatter,
  ]);

  // This effect is responsible for setting the data related options
  useEffect(() => {
    const generateSeries = () => {
      const series: EChartsOption["series"] = [];
      for (let i = 0; i < dimensions?.length - 1; i++) {
        const dimension = dimensions[i + 1];
        const dimensionName = getDimensionName(dimension);

        series.push({
          type: "bar",
          color: getColorForIndex(i, theme),
          clip: false,
          encode: {
            x: 0,
            y: [dimensionName],
            seriesName: [dimensionName],
          },
        });
      }
      return series.slice(0, 10);
    };

    chartInstance?.setOption({
      dataset: {
        dimensions: dimensions,
        source: data,
      },
      series: generateSeries(),
      graphic: {
        elements: [
          {
            invisible: !showEmptyState || loading,
            type: "text",
            left: "center",
            top: "center",
            style: {
              text: I18n.t("CHART.NOT_ENOUGH_DATA"),
              font: "1.2rem Sharp Sans",
              fill: theme.color.default.text.base,
            },
            z: 1,
          },
        ],
      },
    });
  }, [chartInstance, theme, dimensions, data, showEmptyState, loading]);

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <SvgChart ref={chartRef} useRNGH />
      {loading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Color(theme.color.default.bg.weak.base).alpha(0.8).hexa(),
          }}
        >
          <Loader.Spinner />
        </View>
      )}
    </Box>
  );
}
