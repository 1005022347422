import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Voip({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path
        d="M7.92187 13.6686C8.69364 15.2623 9.98239 16.5478 11.5781 17.3154C11.6958 17.3712 11.826 17.3953 11.9559 17.3854C12.0858 17.3756 12.2108 17.3321 12.3187 17.2592L14.6625 15.6936C14.766 15.6233 14.8857 15.5805 15.0103 15.569C15.1349 15.5575 15.2604 15.5778 15.375 15.6279L19.7625 17.5123C19.9125 17.5747 20.0377 17.6847 20.119 17.8253C20.2004 17.9659 20.2332 18.1293 20.2125 18.2904C20.0734 19.3758 19.5437 20.3733 18.7223 21.0964C17.9009 21.8194 16.8443 22.2183 15.75 22.2186C12.3685 22.2186 9.12548 20.8753 6.73439 18.4842C4.3433 16.0931 3 12.8501 3 9.46857C3.00025 8.37431 3.3992 7.31764 4.12221 6.49628C4.84522 5.67491 5.84274 5.14513 6.92812 5.00607C7.08922 4.98537 7.25266 5.01821 7.39326 5.09952C7.53386 5.18084 7.64384 5.30611 7.70625 5.45607L9.59062 9.85294C9.63963 9.96577 9.66013 10.0889 9.65032 10.2115C9.64051 10.3341 9.60069 10.4525 9.53437 10.5561L7.96875 12.9373C7.89905 13.045 7.85814 13.1688 7.84993 13.2968C7.84172 13.4249 7.86649 13.5528 7.92187 13.6686V13.6686Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
