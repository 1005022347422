const IOS = Object.freeze({
  APP_TRACKING_TRANSPARENCY: "ios.permission.APP_TRACKING_TRANSPARENCY",
  BLUETOOTH_PERIPHERAL: "ios.permission.BLUETOOTH_PERIPHERAL",
  CALENDARS: "ios.permission.CALENDARS",
  CAMERA: "ios.permission.CAMERA",
  CONTACTS: "ios.permission.CONTACTS",
  FACE_ID: "ios.permission.FACE_ID",
  LOCATION_ALWAYS: "ios.permission.LOCATION_ALWAYS",
  LOCATION_WHEN_IN_USE: "ios.permission.LOCATION_WHEN_IN_USE",
  MEDIA_LIBRARY: "ios.permission.MEDIA_LIBRARY",
  MICROPHONE: "ios.permission.MICROPHONE",
  MOTION: "ios.permission.MOTION",
  PHOTO_LIBRARY: "ios.permission.PHOTO_LIBRARY",
  PHOTO_LIBRARY_ADD_ONLY: "ios.permission.PHOTO_LIBRARY_ADD_ONLY",
  REMINDERS: "ios.permission.REMINDERS",
  SIRI: "ios.permission.SIRI",
  SPEECH_RECOGNITION: "ios.permission.SPEECH_RECOGNITION",
  STOREKIT: "ios.permission.STOREKIT",
});
const ANDROID = Object.freeze({
  ACCEPT_HANDOVER: "android.permission.ACCEPT_HANDOVER",
  ACCESS_BACKGROUND_LOCATION: "android.permission.ACCESS_BACKGROUND_LOCATION",
  ACCESS_COARSE_LOCATION: "android.permission.ACCESS_COARSE_LOCATION",
  ACCESS_FINE_LOCATION: "android.permission.ACCESS_FINE_LOCATION",
  ACCESS_MEDIA_LOCATION: "android.permission.ACCESS_MEDIA_LOCATION",
  ACTIVITY_RECOGNITION: "android.permission.ACTIVITY_RECOGNITION",
  ADD_VOICEMAIL: "com.android.voicemail.permission.ADD_VOICEMAIL",
  ANSWER_PHONE_CALLS: "android.permission.ANSWER_PHONE_CALLS",
  BLUETOOTH_ADVERTISE: "android.permission.BLUETOOTH_ADVERTISE",
  BLUETOOTH_CONNECT: "android.permission.BLUETOOTH_CONNECT",
  BLUETOOTH_SCAN: "android.permission.BLUETOOTH_SCAN",
  BODY_SENSORS: "android.permission.BODY_SENSORS",
  BODY_SENSORS_BACKGROUND: "android.permission.BODY_SENSORS_BACKGROUND",
  CALL_PHONE: "android.permission.CALL_PHONE",
  CAMERA: "android.permission.CAMERA",
  GET_ACCOUNTS: "android.permission.GET_ACCOUNTS",
  NEARBY_WIFI_DEVICES: "android.permission.NEARBY_WIFI_DEVICES",
  POST_NOTIFICATIONS: "android.permission.POST_NOTIFICATIONS",
  PROCESS_OUTGOING_CALLS: "android.permission.PROCESS_OUTGOING_CALLS",
  READ_CALENDAR: "android.permission.READ_CALENDAR",
  READ_CALL_LOG: "android.permission.READ_CALL_LOG",
  READ_CONTACTS: "android.permission.READ_CONTACTS",
  READ_EXTERNAL_STORAGE: "android.permission.READ_EXTERNAL_STORAGE",
  READ_MEDIA_AUDIO: "android.permission.READ_MEDIA_AUDIO",
  READ_MEDIA_IMAGES: "android.permission.READ_MEDIA_IMAGES",
  READ_MEDIA_VIDEO: "android.permission.READ_MEDIA_VIDEO",
  READ_PHONE_NUMBERS: "android.permission.READ_PHONE_NUMBERS",
  READ_PHONE_STATE: "android.permission.READ_PHONE_STATE",
  READ_SMS: "android.permission.READ_SMS",
  RECEIVE_MMS: "android.permission.RECEIVE_MMS",
  RECEIVE_SMS: "android.permission.RECEIVE_SMS",
  RECEIVE_WAP_PUSH: "android.permission.RECEIVE_WAP_PUSH",
  RECORD_AUDIO: "android.permission.RECORD_AUDIO",
  SEND_SMS: "android.permission.SEND_SMS",
  USE_SIP: "android.permission.USE_SIP",
  UWB_RANGING: "android.permission.UWB_RANGING",
  WRITE_CALENDAR: "android.permission.WRITE_CALENDAR",
  WRITE_CALL_LOG: "android.permission.WRITE_CALL_LOG",
  WRITE_CONTACTS: "android.permission.WRITE_CONTACTS",
  WRITE_EXTERNAL_STORAGE: "android.permission.WRITE_EXTERNAL_STORAGE",
});

const RESULTS = Object.freeze({
  UNAVAILABLE: "unavailable",
  BLOCKED: "blocked",
  DENIED: "denied",
  GRANTED: "granted",
  LIMITED: "limited",
});

const PERMISSIONS = { ANDROID, IOS };
export { PERMISSIONS, RESULTS };

export const openLimitedPhotoLibraryPicker = async () => undefined;
export const openSettings = async () => undefined;
export const check = async (_) => RESULTS.GRANTED;
export const request = async (_) => RESULTS.GRANTED;
export const checkLocationAccuracy = async () => "full";
export const requestLocationAccuracy = async (_) => "full";

const notificationOptions = [
  "alert",
  "badge",
  "sound",
  "carPlay",
  "criticalAlert",
  "provisional",
  "providesAppSettings",
];

const notificationSettings = {
  alert: true,
  badge: true,
  sound: true,
  carPlay: true,
  criticalAlert: true,
  provisional: true,
  providesAppSettings: true,
  lockScreen: true,
  notificationCenter: true,
};

export const checkNotifications = async () => ({
  status: RESULTS.GRANTED,
  settings: notificationSettings,
});

export const requestNotifications = async (options) => ({
  status: RESULTS.GRANTED,
  settings: options
    .filter((option) => notificationOptions.includes(option))
    .reduce((acc, option) => ({ ...acc, [option]: true }), {
      lockScreen: true,
      notificationCenter: true,
    }),
});

export const checkMultiple = async (permissions) =>
  permissions.reduce((acc, permission) => ({ ...acc, [permission]: RESULTS.GRANTED }), {});

export const requestMultiple = async (permissions) =>
  permissions.reduce((acc, permission) => ({ ...acc, [permission]: RESULTS.GRANTED }), {});

export default {
  PERMISSIONS,
  RESULTS,

  check,
  checkLocationAccuracy,
  checkMultiple,
  checkNotifications,
  openLimitedPhotoLibraryPicker,
  openSettings,
  request,
  requestLocationAccuracy,
  requestMultiple,
  requestNotifications,
};
