import isLokiRunning from "@loki/is-loki-running";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";

export type SpinnerLoaderProps = Omit<ActivityIndicatorProps, "color" | "animating"> & {
  animate?: boolean;
};

export function SpinnerLoader({ animate, ...rest }: SpinnerLoaderProps) {
  const theme = useMagneticTheme();

  return (
    <ActivityIndicator
      {...rest}
      animating={!isLokiRunning() && animate}
      color={theme.color.interact.icon.weak.base}
    />
  );
}
