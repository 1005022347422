import { I18n } from "@meraki/core/i18n";
import { Ssid } from "@meraki/shared/api";
import { useState } from "react";
import { View } from "react-native";

import { MAX_SSIDS } from "~/constants/MkiConstants";
import IconBanner from "~/go/components/IconBanner";
import NetworkCard from "~/go/components/networkTemplate/NetworkCard";
import { PickerModalItems } from "~/go/components/PickerModal";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { ExportType } from "~/lib/ExportTemplateUtils";
import { importFormatSsidId } from "~/lib/ImportTemplateUtils";
import { isUnconfiguredSSID } from "~/lib/SSIDUtils";
import MkiText from "~/shared/components/MkiText";

interface SsidNetworkCardProps {
  ssid: Ssid;
  overMaxSsids: boolean;
  existingSsids: Ssid[];
  selectedExistingSsids: string[];
  isSelected: boolean;
  ssidOptions: PickerModalItems[];
  tapHandler: (type: ExportType, id: string) => void;
  onSsidOverwrite: (ssidId: string, ids: number[]) => void;
}

const SsidNetworkCard = ({
  ssid,
  overMaxSsids,
  existingSsids,
  selectedExistingSsids,
  isSelected,
  ssidOptions,
  tapHandler,
  onSsidOverwrite,
}: SsidNetworkCardProps) => {
  const [droppedDown, setDroppedDown] = useState<boolean>(false);
  const [selectedSsidOverwrite, setSelectedSsidOverwrite] = useState<number>(-1);
  const [showSsidModal, setShowSsidModal] = useState(false);

  const getNextAvailableSsid = () => {
    return existingSsids
      .slice(0, MAX_SSIDS)
      .findIndex((s, index) => isUnconfiguredSSID(s) && selectedExistingSsids[index] === "");
  };

  const onPress = () => {
    tapHandler(ExportType.ssid, importFormatSsidId(ssid));
    if (selectedSsidOverwrite !== -1) {
      onSsidOverwrite(importFormatSsidId(ssid), [selectedSsidOverwrite]);
      setSelectedSsidOverwrite(-1);
    } else {
      const defaultId = getNextAvailableSsid();
      setSelectedSsidOverwrite(defaultId);
      if (defaultId !== -1) {
        onSsidOverwrite(importFormatSsidId(ssid), [defaultId]);
      }
    }
    setDroppedDown(!droppedDown);
  };

  // TODO: UDG-3699 - Should be revised once component library is ready
  return (
    <NetworkCard
      name={ssid.name}
      type="ssid"
      isSelected={isSelected}
      onRowPress={onPress}
      onDropDownPress={() => setDroppedDown(!droppedDown)}
      dropDown={droppedDown}
      testID={`SSID_NETWORK_CARD.${ssid.number}`}
    >
      {
        <View testID={`SSID_NETWORK_CARD.DROP_DOWN.${ssid.number}`}>
          <MkiText textStyle="smallLight">
            {I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.SSID_REPLACE_TEXT")}
          </MkiText>
          <PickerModalRow
            label={
              selectedSsidOverwrite !== -1 ? (
                existingSsids[selectedSsidOverwrite].name
              ) : (
                <MkiText textStyle="secondary">
                  {I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.SSID_REPLACE_PLACEHOLDER")}
                </MkiText>
              )
            }
            visible={showSsidModal}
            items={ssidOptions}
            selectedValue={selectedSsidOverwrite}
            onValueSelect={(value) => {
              const id = value as number;
              const overwriteArr =
                selectedSsidOverwrite !== -1 ? [selectedSsidOverwrite, id] : [id];
              onSsidOverwrite(importFormatSsidId(ssid), overwriteArr);
              setShowSsidModal(false);
              setSelectedSsidOverwrite(id);
            }}
            onPress={() => setShowSsidModal(true)}
            onExit={() => setShowSsidModal(false)}
            testID=""
          />
          {overMaxSsids && (
            <IconBanner
              bannerType="warning"
              alertText={I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.SSID_REPLACE_WARNING")}
              textStyle="small"
              testID="SSID_OVERWRITE_WARNING_BANNER"
              screenStyles={undefined}
            />
          )}
        </View>
      }
    </NetworkCard>
  );
};

export default SsidNetworkCard;
