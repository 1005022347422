import { I18n } from "@meraki/core/i18n";
import {
  AddStaticIPScreen,
  AppliancePortDetailsScreen,
  AppliancePortsListScreen,
  DeviceDetailsScreen,
  DeviceMountLocationScreen,
  DevicesListScreen,
  FirmwareUpgradeScreen,
  IPConfigScreen,
  LinkSpeedSettingsScreen,
  MACAllowListConfigureScreen,
  MACAllowListScreen,
  SecurityProtectionScreen,
  SwitchPortDetailsScreen,
  SwitchPortsListScreen,
  VLANConfigurationScreen,
  WirelessRadioSettingsEditScreen,
  WirelessRadioSettingsScreen,
} from "@meraki/go/device";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button } from "@meraki/magnetic/components";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function DeviceGroup<T extends DeviceGroupProps>(Stack: StackType<T>) {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  return (
    <>
      <Stack.Screen
        name="MACAllowListConfigure"
        component={MACAllowListConfigureScreen}
        options={{
          presentation: "modal",
          animation: "slide_from_bottom",
          headerTitle: I18n.t("MAC_ALLOW_LIST.HEADER.TITLE"),
          headerLeft: () => (
            <Button text={I18n.t("CANCEL")} kind="tertiary" onPress={() => navigation.goBack()} />
          ),
        }}
      />
      <Stack.Screen
        name="FirmwareUpgrade"
        component={FirmwareUpgradeScreen}
        options={{
          headerLeft: () => (
            <Button text={I18n.t("CANCEL")} kind="tertiary" onPress={() => navigation.goBack()} />
          ),
        }}
      />
      <Stack.Screen
        name="VLANConfiguration"
        component={VLANConfigurationScreen}
        options={{
          headerLeft: () => (
            <Button
              text={I18n.t("ROOT_TITLES.SETTINGS")}
              leadingIcon={"CaretLeft"}
              kind="tertiary"
              onPress={() => navigation.goBack()}
            />
          ),
        }}
      />
      <Stack.Screen
        name="MACAllowList"
        component={MACAllowListScreen}
        options={{
          headerLeft: () => (
            <Button.Nav
              text={I18n.t("ROOT_TITLES.SETTINGS")}
              leadingIcon={"CaretLeft"}
              onPress={() => navigation.goBack()}
            />
          ),
        }}
      />
      <Stack.Screen
        name="DevicesList"
        component={DevicesListScreen}
        options={{ headerTitle: I18n.t("HARDWARE.TITLE") }}
      />
      <Stack.Screen
        name="DeviceDetails"
        component={DeviceDetailsScreen}
        options={{
          headerShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="WirelessRadioSettings"
        component={WirelessRadioSettingsScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="WirelessRadioSettingsEdit"
        component={WirelessRadioSettingsEditScreen}
        options={{
          presentation: "modal",
          animation: "slide_from_bottom",
          headerShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="DeviceMountLocation"
        component={DeviceMountLocationScreen}
        options={{
          presentation: "modal",
          animation: "slide_from_bottom",
          headerTitle: I18n.t("DEVICE_DETAILS.LOCATION"),
          headerLeft: () => (
            <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.goBack()} />
          ),
        }}
      />
      <Stack.Screen
        name="AppliancePortsList"
        component={AppliancePortsListScreen}
        options={{ headerShadowVisible: false, headerTitle: I18n.t("PORTS_WORD") }}
      />
      <Stack.Screen
        name="SwitchPortsList"
        component={SwitchPortsListScreen}
        options={{ headerShadowVisible: false, headerTitle: I18n.t("PORTS_WORD") }}
      />
      <Stack.Screen
        name="AppliancePortDetails"
        component={AppliancePortDetailsScreen}
        options={{
          headerShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="SwitchPortDetails"
        component={SwitchPortDetailsScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="IPConfig"
        component={IPConfigScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="AddStaticIP"
        component={AddStaticIPScreen}
        options={{
          headerTitle: I18n.t("IP_CONFIG.ADD_STATIC_IP.TITLE"),
          headerLeft: () => (
            <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.goBack()} />
          ),
        }}
      />
      <Stack.Screen
        name="SecurityProtection"
        component={SecurityProtectionScreen}
        options={{ headerShadowVisible: false }}
      />
      <Stack.Screen
        name="LinkSpeedSettings"
        component={LinkSpeedSettingsScreen}
        options={{
          headerShadowVisible: false,
          headerLeft: () => (
            <Button
              text={I18n.t("ROOT_TITLES.SETTINGS")}
              leadingIcon={"CaretLeft"}
              kind="tertiary"
              onPress={() => navigation.goBack()}
            />
          ),
        }}
      />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "HARDWARE.TITLE",
    tab: "MonitorTab",
    screen: "DevicesList",
  },
  ["device", "access point", "switch", "router", "wireless", "wired"],
);

registerTags(
  {
    title: "FIRMWARE_UPDATES.HEADING",
    subtitle: "FIRMWARE_UPDATES.DESCRIPTION",
    tab: "ConfigureTab",
    scope: "network",
    screen: "FirmwareUpgrade",
  },
  ["device", "firmware"],
);

registerTags(
  {
    title: "UMBRELLA.ENABLE.TITLE",
    subtitle: "UMBRELLA.SUBSCRIPTION.DESCRIPTION",
    tab: "ConfigureTab",
    scope: "network",
    screen: "SecurityProtection",
  },
  ["device", "network", "router", "security"],
);
