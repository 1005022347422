import * as errorMonitor from "@meraki/core/errors";
import { Ssid } from "@meraki/shared/api";
import { PureComponent } from "react";
import { SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { showAlert, showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import NetworkUtils from "~/lib/NetworkUtils";
import { getSsidNameFromNum } from "~/lib/SSIDUtils";
import {
  currentNetworkState,
  firewallLayerRulesOnSSIDsSelector,
  gxDeviceSelector,
  securityWebBlockingRules,
  slimSsidsSelector,
} from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import NoDataFooter from "~/shared/components/NoDataFooter";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import Device_DeprecatedType from "~/shared/types/Device";
import { FirewallLayerRule } from "~/shared/types/FirewallLayerRule";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const GX = "GX";
const isGXFirewall = (sectionId?: string) => sectionId === GX;

type ReduxProps = {
  firewallLayerRules: Record<string, FirewallLayerRule[]>;
  gxDevice?: Device_DeprecatedType;
  networkId: string;
  ssids: Ssid[];
  webBlockingRules?: FirewallLayerRule[] | null;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "BlockContent"> &
  ReduxProps &
  BasicActions;

type BlockContentState = {
  reqPending: boolean;
  isEditMode: boolean;
};

type BlockContentRow = {
  GX: FirewallLayerRule[];
} & Record<string, FirewallLayerRule>;

export class BlockContent extends PureComponent<Props, BlockContentState> {
  static defaultProps = {
    ssidNumber: undefined,
  };

  static renderSectionFooter({ section }: { section: SectionListData<BlockContentRow> }) {
    return (
      <NoDataFooter data={section.data} noDataString={I18n.t("BLOCK_CONTENT.NO_RESTRICTIONS")} />
    );
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      reqPending: false,
      isEditMode: false,
    };
    this.setNavOptions();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { isEditMode } = this.state;

    navigation.setOptions({
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => this.setState({ isEditMode: true })} />;
        } else {
          return <DoneButton onPress={() => this.setState({ isEditMode: false })} />;
        }
      },
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { actions, networkId, ssidNumber, ssids } = this.props;
    const { reqPending } = this.state;
    if (reqPending) {
      return;
    }
    this.setState({ reqPending: true });

    const promiseArray: Promise<unknown>[] = [];
    if (ssidNumber === undefined) {
      //@ts-expect-error UDG-3806: Verify rm getGxLayer7FirewallRules invocation
      promiseArray.push(actions.loadNodes(networkId).then(actions.getGXLayer7FirewallRules()));

      for (const ssid of ssids) {
        if (ssid.enabled) {
          promiseArray.push(actions.getSSIDLayer7FirewallRules(ssid.number));
        }
      }
    } else {
      promiseArray.push(actions.getSSIDLayer7FirewallRules(ssidNumber));
    }

    Promise.all(promiseArray)
      .then(() => this.setState({ reqPending: false }))
      .catch(() => showNetworkErrorWithRetry(() => this.getData()));
  }

  editContent(ruleNumber: number, ssidNumber?: number) {
    const { navigation } = this.props;

    navigation.navigate("BlockWebsite", {
      ruleNumber,
      ssidNumber,
    });
  }

  addContent = (section: number) => {
    const { navigation } = this.props;
    navigation.navigate("BlockWebsite", {
      ssidNumber: section,
    });
  };

  deleteGXBlockedHost(deletedRuleNumber: number) {
    this.setState({ reqPending: true });
    const { actions, webBlockingRules } = this.props;
    const updatedRules = webBlockingRules?.filter(
      (_: unknown, index) => index !== deletedRuleNumber,
    );
    actions
      .updateGXLayer7FirewallRules(updatedRules)
      .then(() => this.setState({ reqPending: false }))
      .catch((error: unknown) => this.handleError(error));
  }

  deleteSSIDBlockedHost(deletedRuleNumber: number, sectionId: string) {
    const { actions, firewallLayerRules } = this.props;
    const ssidNumber = parseInt(sectionId, 10);
    const updatedRules = firewallLayerRules[ssidNumber].filter(
      (_: unknown, index) => index !== deletedRuleNumber,
    );

    this.setState({ reqPending: true });
    actions.updateSSIDLayer7FirewallRules(ssidNumber, updatedRules).then(
      () => this.setState({ reqPending: false }),
      (error: unknown) => this.handleError(error),
    );
  }

  handleError(error: unknown) {
    this.setState({ reqPending: false });
    showAlert(I18n.t("ERROR"), error);
  }

  renderSectionHeader({ section }: { section: SectionListData<BlockContentRow> }, ssids: Ssid[]) {
    if (isGXFirewall(section.key)) {
      return this.renderGXSectionHeader();
    }
    return this.renderGRSectionHeader(section, ssids);
  }

  renderGXSectionHeader() {
    return (
      <SectionListHeader
        heading={I18n.t("BLOCK_CONTENT.EVERYWHERE")}
        // @ts-expect-error we want to navigate to "BlockWebsite" with no ssidNum for GX, but
        // "BlockWebsite" is not set up to handle GX vs GR very well and errors in a lot of
        // places if ssidNum is made optional
        onPress={() => this.addContent()}
        withHorizontalMargin
      />
    );
  }

  renderGRSectionHeader(section: SectionListData<BlockContentRow>, ssids: Ssid[]) {
    // section.key will always be defined because we're making a section list
    const ssidNum = parseInt(section.key!, 10);
    const sectionName = I18n.t("ON_SSID_SECTION_HEADER", {
      section_name: getSsidNameFromNum(ssidNum, ssids),
    });
    return (
      <SectionListHeader
        heading={sectionName}
        onPress={() => this.addContent(ssidNum)}
        withHorizontalMargin
      />
    );
  }

  renderRow(rowData: FirewallLayerRule, rowId: number, sectionId: string) {
    if (isGXFirewall(sectionId)) {
      return this.renderGXRow(rowData, rowId);
    }
    return this.renderGRRow(rowData, rowId, sectionId);
  }

  renderGXRow(rowData: FirewallLayerRule, rowId: number) {
    const { isEditMode } = this.state;
    const blockedHost = rowData.value;

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => this.deleteGXBlockedHost(rowId)}
        testID={`DELETE_BUTTON-${blockedHost}`}
      />
    );

    return (
      <ListRow
        subtitle1={blockedHost}
        icon={deleteIcon}
        onPress={() => this.editContent(rowId, undefined)}
        rowStyles={styles.rowStyles}
      >
        {I18n.t("BLOCK_CONTENT.BLOCK_FOO", {
          blocked_url: NetworkUtils.getHostFromUrl(blockedHost).toUpperCase(),
        })}
      </ListRow>
    );
  }

  renderGRRow(rowData: FirewallLayerRule, rowId: number, sectionId: string) {
    const { isEditMode } = this.state;
    const blockedHost = rowData.value;
    const ssidNumber = parseInt(sectionId, 10);

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => this.deleteSSIDBlockedHost(rowId, sectionId)}
        testID={`DELETE_BUTTON-${blockedHost}`}
      />
    );

    return (
      <ListRow
        subtitle1={blockedHost}
        icon={deleteIcon}
        onPress={() => this.editContent(rowId, ssidNumber)}
        rowStyles={styles.rowStyles}
      >
        {I18n.t("BLOCK_CONTENT.BLOCK_FOO", {
          blocked_url: NetworkUtils.getHostFromUrl(blockedHost).toUpperCase(),
        })}
      </ListRow>
    );
  }

  getRowData() {
    const { firewallLayerRules, ssidNumber, webBlockingRules } = this.props;

    let data = {};
    if (ssidNumber != null) {
      data = {
        [ssidNumber]: firewallLayerRules?.[ssidNumber] || [],
      };
    } else {
      data = { ...firewallLayerRules };

      if (webBlockingRules) {
        data = {
          ...data,
          GX: webBlockingRules,
        };
      }
    }

    return data;
  }

  render() {
    const { ssids } = this.props;
    const { reqPending } = this.state;

    const sortKeyComparator = (key1: string, key2: string) => {
      if (key1 === GX) {
        return -1;
      }
      if (key2 === GX) {
        return 1;
      }
      // @ts-expect-error typing: we cant do this operation with two strings
      return key1 - key2;
    };

    // the typing is difficult on this component because it either renders as a flat list or section
    // list depending if we're looking at GR or GX, and the typing on MkiTable does not handle that well.
    return (
      <View style={styles.container} testID="BLOCK_CONTENT_SCREEN">
        <View style={styles.tableContainer}>
          <MkiTable<BlockContentRow>
            data={this.getRowData()}
            keyExtractor={(_, idx) => `${idx}`}
            renderRow={(rowData, rowId, sectionId) =>
              // @ts-expect-error: MkiTable isn't typed well to handle a SectionList so it gets the wrong type here
              // rowData will be type FirewallLayerRule and sectionId should be defined in a sectionList, but it doesn't do that properly
              this.renderRow(rowData, rowId, sectionId)
            }
            renderSectionHeader={(section) => this.renderSectionHeader(section, ssids)}
            renderSectionFooter={BlockContent.renderSectionFooter}
            sortKeyComparator={sortKeyComparator}
          />
        </View>
        <LoadingSpinner visible={reqPending} screenStyles={styles.loadingContainer} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  loadingContainer: {
    position: "absolute",
  },
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
});

const mapStateToProps = () => {
  return (state: RootState): ReduxProps => ({
    firewallLayerRules: firewallLayerRulesOnSSIDsSelector(state),
    gxDevice: gxDeviceSelector(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for BlockContentScreen",
      currentNetworkState(state),
    ),
    ssids: slimSsidsSelector(state),
    webBlockingRules: securityWebBlockingRules(state),
  });
};

export default compose<any>(connect(mapStateToProps, basicMapDispatchToProps))(BlockContent);
