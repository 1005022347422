import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon, { IconSizeTypes } from "~/shared/components/icons";
import { MkiText } from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";
import { ConnectionType } from "~/shared/types/Device";

const LLDP_DEVICE_HEADER_MAX_LENGTH = 30;

const getConnectionLabel = (
  connectionType: ConnectionType,
  connectionName: string,
  skipDisplayPort?: boolean,
): string => {
  if (connectionType === ConnectionType.wired && !skipDisplayPort) {
    return I18n.t("DETAILS_HEADER.CONNECTED_TO_PORT", { port_name: connectionName });
  }
  if (connectionType === ConnectionType.vpn) {
    return I18n.t("DETAILS_HEADER.CLIENT_VPN", { connection_name: connectionName });
  }
  return `${connectionName}`;
};

const getIconType = (connectionType: ConnectionType) => {
  switch (connectionType) {
    case ConnectionType.wireless:
      return "wifi";
    case ConnectionType.vpn:
      return "vpn";
    default:
      return "sitemap";
  }
};

const PressableLink = ({ label, onPress }: { label: string; onPress?: () => void }) => {
  const { navigation } = themeColors(useTheme());
  const linkTextStyle = { color: navigation.primary };
  return (
    <MkiText
      onPress={onPress}
      textStyle="small"
      screenStyles={!!onPress ? linkTextStyle : styles.nonLinkText}
      disabled={!onPress}
      truncation={LLDP_DEVICE_HEADER_MAX_LENGTH}
    >
      {label}
    </MkiText>
  );
};

interface DetailsHeaderConnectionsProps {
  connectionName: string;
  connectionType: ConnectionType;
  connectedNode: string;
  connectedNodeOnPress?: () => void;
  connectionOnPress?: () => void;
  iconSize?: IconSizeTypes;
  skipDisplayPort?: boolean;
}

export const DetailsHeaderConnections = (props: DetailsHeaderConnectionsProps) => {
  const {
    connectionType,
    connectionName,
    connectedNode,
    iconSize,
    connectionOnPress,
    connectedNodeOnPress,
    skipDisplayPort,
  } = props;

  const isGo = appSelect({ enterprise: false, go: true });
  const iconType = getIconType(connectionType);
  const connectionLabel = getConnectionLabel(connectionType, connectionName, skipDisplayPort);

  return (
    <View style={styles.description} testID="connections-data">
      <MerakiIcon
        name={iconType}
        size={iconSize}
        testID={`${iconType}-icon`}
        hasGradient={isGo && connectionType !== ConnectionType.vpn}
        style={styles.connectionIcon}
      />
      <PressableLink label={connectionLabel} onPress={connectionOnPress} />
      <MkiText textStyle="small"> {I18n.t("DETAILS_HEADER.VIA")} </MkiText>
      <PressableLink label={connectedNode} onPress={connectedNodeOnPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  description: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: SPACING.default,
  },
  connectionIcon: {
    marginRight: SPACING.small,
    color: MkiColors.secondaryTextColor,
  },
  nonLinkText: {
    color: TEXT_COLORS.placeholder,
  },
});

export default DetailsHeaderConnections;
