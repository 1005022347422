import { StyleProp, View, ViewStyle } from "react-native";
import MaterialIcon from "react-native-vector-icons/MaterialIcons";

import { CHECKMARK_SIZE } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { Next } from "~/shared/screens/NestedTableModalTypes";

export type RowOptions<T> = {
  customRender?: (rowData: RowData<T>, rowIndex: number) => JSX.Element;
  singleDismiss?: boolean;
  toggle?: boolean;
};

export const defaultOptions = {
  customRender: undefined,
  singleDismiss: undefined,
  toggle: undefined,
};

export type RowData<T> = T &
  Partial<Next<T>> & {
    onPress?: () => void;
    label: string;
    selected: boolean;
    renderDetail?: (entries?: Next<T>["entries"]) => void;
  };

export interface NestedTableRenderRowProps<T> {
  rowData: RowData<T>;
  rowIndex: number;
  emptyViewStyle: StyleProp<ViewStyle>;
  options?: RowOptions<T>;
}

/*
 * If parent, render arrow
 * if parent and selected, render arrow and detail
 * if leaf and selected, render selected
 */
function NestedTableRenderRow<T>(props: NestedTableRenderRowProps<T>) {
  const { rowData, rowIndex, emptyViewStyle, options } = props;

  const navColors = useThemeColors().navigation;
  if (options?.customRender) {
    return options.customRender(rowData, rowIndex);
  }

  const { onPress, label } = rowData;

  let icon = <View style={emptyViewStyle} />;
  let detail;

  if (rowData.hasOwnProperty("next") && rowData.next) {
    if (rowData.selected) {
      detail = rowData.renderDetail ? rowData.renderDetail(rowData.next.entries) : null;
    }
  } else {
    if (rowData.selected) {
      icon = (
        <MaterialIcon
          name="done"
          size={CHECKMARK_SIZE}
          color={navColors.primary}
          testID={`${label}-checked`}
        />
      );
    }
  }

  return (
    <SimpleDisclosureRow
      onPress={onPress}
      icon={icon}
      // @ts-expect-error TS(2322): Type 'void | null' is not assignable to type 'Reac... Remove this comment to see the full error message
      subtitle={detail}
      hideDisclosureIcon={!("next" in rowData)}
      testID={`${rowIndex}`}
    >
      {label}
    </SimpleDisclosureRow>
  );
}

export default NestedTableRenderRow;
