import { PureComponent } from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { isValidString } from "~/lib/formatHelper";
import { isNumber } from "~/lib/TypeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

export interface HeaderValueTextProps {
  label: string;
  labelIcon?: React.ReactNode;
  value: string;
  screenStyles?: StyleProp<ViewStyle>;
  headerStyles?: StyleProp<TextStyle>;
  valueStyles?: StyleProp<TextStyle>;
  onEditPress?: () => void;
}

export default class HeaderValueText extends PureComponent<HeaderValueTextProps> {
  render() {
    if (!verifyValidProps(this.props)) {
      return null;
    }
    const { label, labelIcon, value, screenStyles, headerStyles, valueStyles, onEditPress } =
      this.props;

    const editIcon = onEditPress ? (
      <MerakiIcon
        name="edit"
        size="s"
        color={MkiColors.secondaryButton}
        containerStyle={styles.pencilIcon}
        onPress={onEditPress}
      />
    ) : null;

    return (
      <View style={[styles.highlightItem, screenStyles]}>
        <View style={styles.itemContainer}>
          <MkiText textStyle="label" screenStyles={headerStyles}>
            {label}
          </MkiText>
          {labelIcon}
        </View>
        <View style={styles.itemContainer}>
          <MkiText screenStyles={valueStyles}>{value}</MkiText>
          {editIcon}
        </View>
      </View>
    );
  }
}

const verifyValidProps = (props: HeaderValueTextProps) => {
  const { label, value } = props;
  const labelIsValid = isValidString(label) || isNumber(label);
  const valueIsValid = isValidString(value) || isNumber(value);

  return labelIsValid && valueIsValid;
};

const styles = StyleSheet.create({
  highlightItem: {
    paddingHorizontal: SPACING.small,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  pencilIcon: {
    marginLeft: SPACING.small,
  },
});
