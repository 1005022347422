import { GET_MSP_ORGANIZATION_DATA_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

const initialState = {};

export default function msgOrgData(state = initialState, action: any) {
  const { type, response } = action;

  switch (type) {
    case GET_MSP_ORGANIZATION_DATA_SUCCESS:
      return {
        ...state,
        ...response,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
