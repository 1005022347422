import { memo } from "react";
import { StyleSheet, View } from "react-native";

import I18n from "~/i18n/i18n";
import SummaryCard from "~/shared/components/SummaryCard";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

type Props = {
  children: React.ReactNode;
  onMoreToolsPress?: () => void;
};

const TroubleshootingToolsCard = memo(function TroubleshootingToolsCard(props: Props) {
  const { children } = props;

  const renderMoreTools = () => {
    const { onMoreToolsPress } = props;
    if (onMoreToolsPress === undefined) {
      return null;
    }
    return (
      <DisclosureRow
        onPress={onMoreToolsPress}
        isFullButton
        bottomSeparator
        testID="TROUBLESHOOT_MORE_TOOLS"
      >
        {I18n.t("TROUBLESHOOT_MORE_TOOLS")}
      </DisclosureRow>
    );
  };
  return (
    <View>
      <SummaryCard
        heading={I18n.t("LIVE_TOOLS.TROUBLESHOOTING_TOOLS")}
        contentContainerStyles={styles.liveToolsContainer}
      >
        {children}
      </SummaryCard>
      {renderMoreTools()}
    </View>
  );
});

const styles = StyleSheet.create({
  liveToolsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    borderBottomWidth: 0,
  },
});

export default TroubleshootingToolsCard;
