import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import HardwareRow, { HardwareRowData, ProductIconSize } from "~/go/components/HardwareRow";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { AddHardwareScreensPropMap } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { onbaordingErroredNodeRowData, onboardingScannedNodeRowData } from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingNodeStatus, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  nodeRows: HardwareRowData[];
  errorRows: HardwareRowData[];
};

type Props = ForwardedNativeStackScreenProps<AddHardwareScreensPropMap, "ScannedHardware"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

const NODE_ROWS = "nodeRows";

export class ScannedHardwareScreen extends BaseOnboardingScreen<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.scannedHardware);
  }

  isHardwareConnected = () => {
    const { status } = this.props;
    return status === OnboardingNodeStatus.finished;
  };

  onClosePress = () => {
    const { nodeRows, errorRows } = this.props;

    if (this.isHardwareConnected()) {
      this.close();
    } else if (isEmpty(nodeRows) && isEmpty(errorRows)) {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.MESSAGE"),
        this.onAddHardwarePress,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.CANCEL_BUTTON"),
        },
      );
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.SCANNED_HARDWARE_NOT_CONNECTED.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.SCANNED_HARDWARE_NOT_CONNECTED.MESSAGE"),
        () => this.onPrimaryPress(),
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t(
            "ONBOARDING_FULLSTACK.EXIT_ALERTS.SCANNED_HARDWARE_NOT_CONNECTED.CANCEL_BUTTON",
          ),
        },
      );
    }
  };

  onAddHardwarePress = () => {
    this.pushOnboardingScreen(OnboardingStage.addHardware);
  };

  static renderRow = (rowData: HardwareRowData) => {
    const { name, model, description, status } = rowData;

    return (
      <HardwareRow
        name={name}
        description={description}
        model={model}
        status={status}
        productIconSize={ProductIconSize.small}
        showStatusIcon
      />
    );
  };

  renderHeader = ({ section }: { section: SectionListData<HardwareRowData> }) => {
    if (isEmpty(section.data)) {
      return null;
    }

    return (
      <SectionListHeader
        heading={
          section.key === NODE_ROWS
            ? I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.READY_MESSAGE")
            : I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.ERROR_MESSAGE")
        }
        withHorizontalMargin
      />
    );
  };

  tableData = () => {
    const { nodeRows, errorRows } = this.props;
    return {
      nodeRows,
      errorRows,
    };
  };

  renderBody = () => {
    const { errorRows, nodeRows } = this.props;

    return (
      <View style={styles.bodyContainer}>
        <MkiText textStyle="heading" screenStyles={styles.headingText}>
          {isEmpty(errorRows) && isEmpty(nodeRows)
            ? I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.NO_HARDWARE_MESSAGE")
            : I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.MESSAGE")}
        </MkiText>
        <MkiTable<HardwareRowData>
          data={this.tableData()}
          renderSectionHeader={this.renderHeader}
          renderRow={ScannedHardwareScreen.renderRow}
          keyExtractor={(_: unknown, index: number) => `${index}`}
          hasSeparators={false}
        />
      </View>
    );
  };

  getFooterData = () => {
    const data: React.ReactNode[] = [];

    if (!isWeb()) {
      data.push(
        <RoundedButton
          buttonType={ButtonType.secondary}
          key="skipButton"
          onPress={() => this.onClosePress()}
        >
          {I18n.t("SKIP")}
        </RoundedButton>,
      );
    }

    data.push(
      <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    );

    return {
      buttons: data,
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.scannedHardware, {
    noScannedHardware: isEmpty(this.props.nodeRows),
  });
}

const styles = StyleSheet.create({
  headingText: {
    textAlign: "center",
    marginHorizontal: SPACING.extraLarge,
    marginTop: SPACING.large,
    marginBottom: SPACING.default,
  },
  bodyContainer: {
    flex: 1,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    nodeRows: onboardingScannedNodeRowData(state),
    errorRows: onbaordingErroredNodeRowData(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(ScannedHardwareScreen);
