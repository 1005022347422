import * as errorMonitor from "@meraki/core/errors";
import { get } from "lodash";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { NETWORK_TYPE_ABBREVIATIONS } from "~/constants/MkiConstants";
import { isClientConnectedWithConnections, isClientOnline } from "~/lib/ClientUtils";
import { withNodeGroupSubscription } from "~/lib/liveBroker";
import NetworkUtils from "~/lib/NetworkUtils";
import { customFilteredSearchedClients, networkTypesSelector } from "~/selectors";
import { Client, ClientConnections, ExtendedClient } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";

// todo: type this as part of DM-5765
const withConnectedClients = (WrappedComponent: any) =>
  compose<any>(
    connect(mapStateToProps),
    withNodeGroupSubscription({
      type: "DBClients",
      shouldSubscribe: (props: any) => props.networkTypes.hasWireless,
      handler: (message: any, props: any) =>
        processMessage(message, props, NETWORK_TYPE_ABBREVIATIONS.wireless),
      nodeGroupId: (props: any) => NetworkUtils.parseId(props.networkTypes.hasWireless).id,
    }),
    withNodeGroupSubscription({
      type: "DBClients",
      shouldSubscribe: (props: any) => props.networkTypes.hasSwitch,
      handler: (message: any, props: any) =>
        processMessage(message, props, NETWORK_TYPE_ABBREVIATIONS.switch),
      nodeGroupId: (props: any) => NetworkUtils.parseId(props.networkTypes.hasSwitch).id,
    }),
    withNodeGroupSubscription({
      type: "DBClients",
      shouldSubscribe: (props: any) => props.networkTypes.hasWired,
      handler: (message: any, props: any) =>
        processMessage(message, props, NETWORK_TYPE_ABBREVIATIONS.wired),
      nodeGroupId: (props: any) => NetworkUtils.parseId(props.networkTypes.hasWired).id,
    }),
    connectedClientsComponent,
  )(WrappedComponent);

interface ConnectedClientsComponentProps {
  switchConnections: {};
  wiredConnections: {};
  wirelessConnections: {};
}

type NetworkTypes = {
  hasWired: string;
  hasSwitch: string;
  hasWireless: string;
};

type ReduxProps = {
  clients: ExtendedClient[];
  networkTypes: NetworkTypes;
};

export interface WithConnectedClientsProps extends ReduxProps {
  isClientConnected: (client: Client) => boolean;
}

export const connectedClientsComponent = (WrappedComponent: any) =>
  class ConnectedClients extends PureComponent<ConnectedClientsComponentProps> {
    isClientConnected = (client: ExtendedClient) => {
      const { switchConnections, wiredConnections, wirelessConnections } = this.props;

      const connections: ClientConnections = [
        switchConnections,
        wiredConnections,
        wirelessConnections,
      ];
      return isClientOnline(client) || isClientConnectedWithConnections(client, connections);
    };

    render() {
      return <WrappedComponent {...this.props} isClientConnected={this.isClientConnected} />;
    }
  };

const processMessage = (message: any, props: any, networkType: any) => {
  const cMessage = get(message, ["data", "clients"], {});

  const { clients } = props;
  if (!clients) {
    return {};
  }

  const clientConnections = clients.reduce(
    (connected: any, client: any) => ({
      ...connected,
      [client.id]: !!cMessage[client.id],
    }),
    {},
  );

  switch (networkType) {
    case NETWORK_TYPE_ABBREVIATIONS.wireless:
      return { wirelessConnections: clientConnections };
    case NETWORK_TYPE_ABBREVIATIONS.switch:
      return { switchConnections: clientConnections };
    case NETWORK_TYPE_ABBREVIATIONS.wired:
      return { wiredConnections: clientConnections };
    default:
      return {};
  }
};

type ConnectedClientsInputProps = {
  ignoreFilter: boolean;
  clients?: ExtendedClient[];
  filter: (clients: ExtendedClient[]) => ExtendedClient[];
};

const mapStateToProps = () => {
  return (state: RootState, props: ConnectedClientsInputProps): ReduxProps => {
    let clients;

    if (props.ignoreFilter) {
      clients = props.clients || [];
    } else if (props.filter) {
      clients = customFilteredSearchedClients(state, { customFilter: props.filter });
    } else {
      clients = customFilteredSearchedClients(state, props);
    }

    return {
      clients,
      networkTypes: errorMonitor.notifyNonOptional(
        "param 'networkTypes' undefined for ConnectedClients",
        networkTypesSelector(state),
      ),
    };
  };
};

export default withConnectedClients;
