import { I18n } from "@meraki/core/i18n";
import { ProductType } from "@meraki/shared/api";
import { Platform } from "react-native";

import { launchUrl } from "./launchUrl";

const isWeb = () => Platform.OS === "web";

// Works only on iOS; android will require a Java-based implementation.
export const appSettingsUrl = () => launchUrl("app-settings://", I18n.t("OPEN_APP_SETTINGS.ERROR"));

export const otpAuthUrl = (authUsername: string, authSecret: string) =>
  launchUrl(`otpauth://totp/${authUsername}?secret=${authSecret}`, I18n.t("TOTP_ERROR"));

// We're using Google Authenticator for our suggested OTP app
export const getSuggestedOtpApp = () =>
  Platform.OS === "ios"
    ? "https://apps.apple.com/us/app/google-authenticator/id388497605"
    : "market://details?id=com.google.android.apps.authenticator2";

export const otpAppUrl = () =>
  launchUrl(getSuggestedOtpApp(), "We're unable to open this right now");

export const customDocumentationUrl = (docLinks: string, docError: string) =>
  launchUrl(docLinks, docError);

// This may be illegal and possibly rejected from App Store.
export const launchIOSSettings = () =>
  launchUrl("App-Prefs:root=WIFI", I18n.t("IOS_SETTINGS_LINK_ERROR"));

export const lspWiredURL = () => "http://wired.meraki.com";

export const lspSwitchURL = () => "http://switch.meraki.com";

export const lspWirelesshURL = () => "http://ap.meraki.com";

export const lspAnyURL = () => "http://my.meraki.com";

export const getLspURL = (productType: ProductType) => {
  switch (productType) {
    case "appliance":
      return lspWiredURL();
    case "switch":
      return lspSwitchURL();
    case "wireless":
      return lspWirelesshURL();
    default:
      return null;
  }
};

export const launchLSP = (productType?: ProductType) => {
  if (!productType) {
    return;
  }
  const lspURL = getLspURL(productType);

  if (lspURL != null) {
    launchUrl(lspURL);
  }
};

export const umbrellaURL = () => (isWeb() ? "/umbrella" : "https://api.umbrella.com");

export const marketoURL = () => (isWeb() ? "/marketo" : "https://010-KNZ-501.mktorest.com");

export const documentationURL = () =>
  isWeb() ? "/documentation" : "https://documentation.meraki.com";

export const communityURL = () => (isWeb() ? "/community" : "https://community.meraki.com");
