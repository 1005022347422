import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import SetupCompleteScreen from "../screens/onboardingFullstack/SetupCompleteScreen";
import { OnboardingStackProps } from "./Types";

const ForwardedSetupCompleteScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<OnboardingStackProps, "SetupComplete">>()(
    SetupCompleteScreen,
  );

export function onboardingSetupCompleteScreen<
  T extends Pick<OnboardingStackProps, "SetupComplete">,
>(Stack: StackType<T>) {
  return (
    <Stack.Screen
      name="SetupComplete"
      component={ForwardedSetupCompleteScreen}
      options={{ headerTitle: I18n.t("ONBOARDING_FULLSTACK.SETUP_COMPLETE.TITLE") }}
    />
  );
}
