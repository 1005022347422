import z from "zod";

const ExpandedPortSchema = z.object({
  agg_group_id: z.string(),
  agg_group: z.record(z.string(), z.array(z.number())),
});

export const EventDataSchema = z
  .object({
    // might need to add more fields here later
    ip: z.optional(z.string()),
    mac: z.optional(z.string()),
    vlan: z.optional(z.string()),
    port: z.optional(z.array(ExpandedPortSchema.or(z.string())).or(z.string())),
    vap: z.optional(z.string()),
    msg: z.optional(z.string()),
    carrier: z.optional(z.string()),
    device: z.optional(z.string()),
    connectivity: z.optional(z.string()),
    vpn_type: z.optional(z.string()),
    contending_mac: z.optional(z.string()),
    pvd_id: z.optional(z.string()),
    extra: z.optional(z.string()),
    connected: z.optional(z.boolean().or(z.string())),
  })
  .describe("EventDataSchema");

export type EventData = z.infer<typeof EventDataSchema>;

export const EventSchema = z
  .object({
    description: z.nullable(z.string()),
    clientId: z.nullable(z.string()),
    clientDescription: z.nullable(z.string()),
    clientMac: z.nullable(z.string()),
    deviceSerial: z.string(),
    occurredAt: z.string().datetime(),
    deviceName: z.string(),
    eventData: EventDataSchema,
    type: z.string(),
    networkId: z.string(),
  })
  .describe("EventSchema");

export const EventsSchema = z.array(EventSchema).describe("EventSchema");

export type Event = z.infer<typeof EventSchema>;

export const EventsResponseSchema = z
  .object({
    events: z.array(EventSchema),
    message: z.nullable(z.string()),
    pageEndAt: z.string(),
    pageStartAt: z.string(),
  })
  .describe("EventsResponseSchema");

export type EventsResponse = z.infer<typeof EventsResponseSchema>;
