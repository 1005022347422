import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ encryptedNetworkId }: CounterSetsRequest) => {
  return `/n/${encryptedNetworkId}/manage/usage/counter_sets`;
};

interface CounterSetsRequest {
  encryptedNetworkId?: string;
}

export const CounterSetRuleSchema = z.object({
  guid: z.string(),
  name: z.string(),
  color: z.string().optional(),
  group: z.string().optional(),
});

export const CounterSetRulesetSchema = z.object({
  name: z.string(),
  rules: z.record(z.string(), CounterSetRuleSchema),
});

export const CounterSetsSchema = z.object({
  "19": z.optional(CounterSetRulesetSchema),
  "20": z.optional(CounterSetRulesetSchema),
  "21": z.optional(CounterSetRulesetSchema),
  "31": z.optional(CounterSetRulesetSchema),
  "32": z.optional(CounterSetRulesetSchema),
});

export type CounterSetRule = z.infer<typeof CounterSetRuleSchema>;
export type CounterSets = z.infer<typeof CounterSetsSchema>;

const fetchCounterSets = ({ encryptedNetworkId }: CounterSetsRequest) => {
  return request(CounterSetsSchema, "GET", buildUrl({ encryptedNetworkId }));
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCounterSets = createQuery({
  baseQueryKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  queryFn: (parameters: CounterSetsRequest) => fetchCounterSets(parameters),
  requiredVariables: ["encryptedNetworkId"],
});
