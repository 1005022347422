import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Input,
  List,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useCreateGoNetwork, useOrgNetworks } from "@meraki/shared/api";
import { useDebuggingTools } from "@meraki/shared/debugging-tools";
import { showErrorAlert } from "@meraki/shared/native-alert";
import {
  useCurrentOrganizationId,
  USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import Toast from "react-native-simple-toast";
import { useDispatch } from "react-redux";

const UMB_LICENSE_TYPE = "GX-UMB";

export const ConfigureScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps, "Configure">>();

  const { enabled: debuggingToolsEnabled, toggle: toggleDebuggingTools } = useDebuggingTools();

  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const createNetworkBottomsheetRef = useRef<BottomSheetMethods>(null);

  const organizationId = useCurrentOrganizationId();
  const {
    data: timeZone,
    isLoading,
    refetch,
  } = useOrgNetworks(
    { organizationId },
    {
      select: (data) => data[0]?.timeZone,
    },
  );
  const createGoNetwork = useCreateGoNetwork();

  const [networkName, setNetworkName] = useState("");

  const createNetwork = () => {
    if (timeZone && networkName) {
      createGoNetwork.mutate(
        { body: { name: networkName, timeZone } },
        {
          onSuccess: () => {
            Toast.showWithGravity(
              I18n.t("CREATE_SITE.SUCCESS_ALERT.DESCRIPTION", { name: networkName }),
              Toast.SHORT,
              Toast.BOTTOM,
            );
            setNetworkName("");
            createNetworkBottomsheetRef.current?.dismiss();
            refetch();
          },
          onError: (error) => showErrorAlert(error.error),
        },
      );
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Icon
          color="default.textIn.base"
          icon="Plus"
          onPress={() => bottomSheetRef.current?.present()}
        />
      ),
    });
  }, [navigation]);

  return (
    <Screen addDefaultPadding>
      <List label="Internal Alpha Features">
        <List.Item
          title="Magnetic Theme"
          rightAccessory={
            <Toggle
              checked={true}
              onValueChange={(magneticTheme: boolean) => {
                dispatch({
                  type: USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
                  payload: { magneticTheme },
                });
              }}
              testID="MAGNETIC_THEME_TOGGLE"
            />
          }
        />
        <List.Item
          title="Debugging Tools"
          rightAccessory={
            <Toggle checked={debuggingToolsEnabled} onValueChange={toggleDebuggingTools} />
          }
        />
      </List>
      <List
        loading={isLoading}
        label={`${I18n.t("ACCOUNT.ORGANIZATION")} ${I18n.t("TAB_TITLES.SETTINGS")}`}
        testID="ORG_WIDE_SETTINGS"
      >
        <List.Item
          title={I18n.t("SETTINGS.ADMIN.TITLE")}
          testID="ADMINS_SETTINGS"
          onPress={() => navigation.navigate("Administrators")}
        />
        <List.Item
          title={I18n.t("UMBRELLA.SUBSCRIPTION.LICENSES.TITLE")}
          onPress={() => navigation.navigate("LicensesList", { filterList: [UMB_LICENSE_TYPE] })}
          testID="LICENSE_SETTINGS"
        />
        <List.Item
          title={I18n.t("FIRMWARE_UPDATES.HEADING")}
          testID="FIRMWARE_SETTINGS"
          onPress={() => {
            navigation.navigate("NetworkSelection");
          }}
        />
        <List.Item title={I18n.t("CLIENT_DETAILS_SCREEN.POLICIES")} testID="POLICIES_SETTINGS" />
        <List.Item
          title={I18n.t("DELETE_LOCATION.DELETE_ORG")}
          testID="DELETE"
          onPress={() => {
            navigation.navigate("OrgDelete");
          }}
        />
      </List>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("CONFIGURE.CREATE.ACTION_SHEET_TITLE")}
          onCancelPress={() => bottomSheetRef.current?.dismiss()}
        />
        <BottomSheet.Content>
          <Box gap="xs" paddingBottom="xs">
            <Button
              kind="tertiary"
              text={I18n.t("CONFIGURE.CREATE.NETWORK.TITLE")}
              onPress={() => {
                bottomSheetRef.current?.close();
                createNetworkBottomsheetRef.current?.present();
              }}
            />
            <Button
              kind="tertiary"
              text={I18n.t("CREATE_ORGANIZATION.TITLE")}
              onPress={() => {
                bottomSheetRef.current?.close();
                navigation.navigate("CreateOrganization");
              }}
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
      <BottomSheet.Modal
        ref={createNetworkBottomsheetRef}
        snapPoints={["CONTENT_HEIGHT"]}
        index={0}
      >
        <BottomSheet.Header
          title={I18n.t("CREATE_SITE.HEADER")}
          onCancelPress={() => createNetworkBottomsheetRef.current?.dismiss()}
        />
        <BottomSheet.Content>
          <Box gap="xs" paddingBottom="xs">
            <Text size="p2" color="light">
              {I18n.t("CREATE_SITE.DESCRIPTION")}
            </Text>
            <Input
              value={networkName}
              onChangeText={setNetworkName}
              testID="CONFIGURE.CREATE_NETWORK.NAME_INPUT"
            />
          </Box>
          <Button
            kind="primary"
            text={I18n.t("CREATE_SITE.PRIMARY_BUTTON")}
            onPress={createNetwork}
            disabled={!networkName}
            loading={createGoNetwork.isLoading}
            testID="CONFIGURE.CREATE_NETWORK.BUTTON"
          />
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
