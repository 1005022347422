import { createSelector } from "reselect";

import { getCurrentNetwork } from "~/selectors/orgNetworks";
import { ProductType } from "~/shared/types/Networks";
import { ReduxSelector } from "~/shared/types/Redux";

export const getNetworkProductTypes: ReduxSelector<ProductType[]> = createSelector(
  getCurrentNetwork,
  (network) => network?.productTypes,
);

// this function does not actually check whether a network has clients,
// it checks whether a network has the capacity to support network clients
export const getNetworkHasClients: ReduxSelector<boolean> = createSelector(
  getNetworkProductTypes,
  (productTypes) => {
    if (!productTypes) {
      return undefined;
    }

    return !!productTypes.find((type) => {
      return (
        type === ProductType.wireless ||
        type === ProductType.appliance ||
        type === ProductType.switch
      );
    });
  },
);

const makeNetworkHasSelector = (productType: ProductType) =>
  createSelector(getNetworkProductTypes, (productTypes) => productTypes?.includes(productType));

export const getNetworkHasWireless: ReduxSelector<boolean> = makeNetworkHasSelector(
  ProductType.wireless,
);

// TODO: remove "environmental" type once issue with this endpoint is resolved:
// /api/v1/organizations/${orgId}/networks
export const getNetworkHasSensor: ReduxSelector<boolean> = createSelector(
  makeNetworkHasSelector(ProductType.sensor),
  makeNetworkHasSelector(ProductType.environmental),
  (isSensor, isEnvironmental) => isSensor || isEnvironmental,
);

export const getNetworkHasSM: ReduxSelector<boolean> = makeNetworkHasSelector(
  ProductType.systemsManager,
);

export const getNetworkHasSwitch: ReduxSelector<boolean> = makeNetworkHasSelector(
  ProductType.switch,
);

export const getNetworkHasCamera: ReduxSelector<boolean> = makeNetworkHasSelector(
  ProductType.camera,
);

export const getNetworkHasAppliance: ReduxSelector<boolean> = makeNetworkHasSelector(
  ProductType.appliance,
);

export const getNetworkHasDevices: ReduxSelector<boolean> = createSelector(
  getNetworkHasSM,
  getNetworkProductTypes,
  (hasSM, productTypes) => {
    if (!productTypes) {
      return undefined;
    }

    if (productTypes.length === 0) {
      return false;
    }

    const onlySM = hasSM && productTypes.length === 1;
    return !onlySM;
  },
);
