import { I18n } from "@meraki/core/i18n";
import { Button, Card, Heading, List, Text } from "@meraki/magnetic/components";
import { Icon, Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSiteToSiteVPN } from "@meraki/react-live-broker";
import {
  Devices,
  useDevices,
  useOrgNetworks,
  usePeerOutageData,
  useVpnDetails,
  useVpnStatuses,
} from "@meraki/shared/api";
import { PickerCard, SingleBarGraph } from "@meraki/shared/components";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useState } from "react";
import { Linking } from "react-native";

import {
  formatOutageData,
  getPeerGraphDataGo,
  getRegistryDetails,
  getRegistryStatusColor,
  Outage,
  PeerById,
} from "../utils/SiteToSiteUtils";

export const SiteToSiteDetailsScreen = () => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const [displayMore, setDisplayMore] = useState<boolean>(false);

  const { data: networks } = useOrgNetworks({ organizationId });
  const { data: applianceId } = useDevices(
    { organizationId, networkId },
    {
      select: (data: Devices) => data.find((device) => device.productType === "appliance")?.id,
    },
  );
  const { data: vpnStatuses, isLoading: vpnStatusIsLoading } = useVpnStatuses({ organizationId });
  const registryResponse = useSiteToSiteVPN(applianceId ?? "");

  let networkEid = "";
  const allNetworkEids: string[] = [];
  const allNetworkEidAndIdPairs: Record<string, string> = {};
  networks?.forEach((network) => {
    if (network.id === networkId) {
      networkEid = network.eid ?? "";
      return;
    } else {
      allNetworkEids.push(network.eid ?? "");
      if (network.eid && network.id) {
        allNetworkEidAndIdPairs[network.id] = network.eid;
      }
    }
  });

  const { data: peerOutageData, isLoading: peerOutageDataIsLoading } = usePeerOutageData(
    {
      networkEid,
      timespan,
      peerEids: allNetworkEids,
    },
    {
      select(data) {
        return formatOutageData(data);
      },
    },
  );
  const { data: vpnDetails } = useVpnDetails(
    { networkEid, timespan },
    {
      select: (data) =>
        data.details.peers.reduce((prev, peer) => {
          if (!prev[peer.id]) {
            prev[peer.id] = {
              ...peer,
            };
          }
          return prev;
        }, {} as PeerById),
    },
  );

  const networkName = networks?.find((network) => network.id === networkId)?.name ?? "";
  const vpnStatus = vpnStatuses?.find((vpn) => vpn.networkId === networkId);
  const peers = vpnStatus?.merakiVpnPeers;
  const peersWithData = peers?.map((peer) => ({
    ...peer,
    averageLatency: vpnDetails?.[allNetworkEidAndIdPairs?.[peer.networkId] ?? ""]?.avg_lat,
    data: peerOutageData?.[allNetworkEidAndIdPairs?.[peer.networkId] ?? ""] as Outage[],
  }));
  const registryStatus = registryResponse?.status;
  const privateIp =
    (registryStatus && registryStatus.private_contact) ??
    I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");
  const publicIp =
    (registryStatus && registryStatus.public_contact) ??
    I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");
  const registryStatusIcon = getRegistryStatusColor(registryResponse);

  const statusName = (reachability: string) =>
    reachability === "reachable"
      ? "positive"
      : reachability === "unreachable"
        ? "negative"
        : "neutral";

  return (
    <Screen.View addDefaultPadding>
      <Card testID="HEADING_CARD">
        <Heading size="h2">
          {I18n.t("VPN_HEALTH_OVERVIEW.SITES_SCREEN.SUBHEADER", {
            numPeers: peers?.length,
            siteName: networkName,
          })}
        </Heading>
      </Card>
      <Box>
        <PickerCard.Time title={I18n.t("TIMEPICKER_TITLE")} />
      </Box>
      <Box flex={1}>
        {peersWithData && peersWithData?.length > 0 ? (
          <List.FlashList
            paddingTop="none"
            paddingLeft="none"
            paddingRight="none"
            testID="FLASH_LIST"
            loading={peerOutageDataIsLoading || vpnStatusIsLoading}
            data={peersWithData}
            emptyState={{
              title: I18n.t("VPN_HEALTH_OVERVIEW.NO_DATA_TIMEFRAME"),
              status: "informational",
            }}
            getItemData={(peer) => ({
              leftAccessory: <Status status={statusName(peer.reachability)} />,
              title: peer.networkName,
              rightAccessory: (
                <Box flexDirection="row">
                  <Text color="light">{I18n.t("VPN_HEALTH_OVERVIEW.AVERAGE_LATENCY")}</Text>
                  <Text>
                    {peer.averageLatency
                      ? I18n.t("VPN_HEALTH_OVERVIEW.LATENCY_VALUE", {
                          latency: peer.averageLatency,
                        })
                      : I18n.t("UNKNOWN")}
                  </Text>
                </Box>
              ),
              children: <SingleBarGraph data={getPeerGraphDataGo(peer.data, timespan)} />,
              key: peer.networkName,
              testID: `PEER_ROW.${peer.networkName}`,
            })}
          />
        ) : (
          <Box flex={1} testID={"NO_DATA_IN_TIMEFRAME"}>
            <Card marginHorizontal="sm">
              <Card.Well>
                <Text size="p2">{I18n.t("VPN_HEALTH_OVERVIEW.NO_DATA_TIMEFRAME")}</Text>
              </Card.Well>
            </Card>
          </Box>
        )}
        <Card loading={registryStatus === undefined} testID="REGISTRY_CARD">
          <Card.Header
            title={I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.REGISTRY_PORT")}
            leftAccessory={
              <Status
                status={registryStatusIcon}
                testID={`REGISTRY_STATUS_ICON_${registryStatusIcon}`}
              ></Status>
            }
            rightAccessory={
              <Text size="p1" color="light">
                {getRegistryDetails(registryResponse)}
              </Text>
            }
          />
          <List>
            <List.Item
              title={I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.PRIVATE_IP")}
              value={privateIp}
            />
            <List.Item title={I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.PUBLIC_IP")} value={publicIp} />
            <List.Item
              title={""}
              onPress={() => setDisplayMore(!displayMore)}
              hidePressable
              leftAccessory={
                <Button
                  text={I18n.t("LEARN_MORE")}
                  kind="tertiary"
                  onPress={() =>
                    Linking.openURL(I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.LEARN_MORE_LINK"))
                  }
                  testID="LEARN_MORE_BUTTON"
                />
              }
              value={
                displayMore
                  ? I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.LESS")
                  : I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.MORE")
              }
              rightAccessory={<Icon name={displayMore ? "CaretUp" : "CaretDown"} />}
              children={
                displayMore ? (
                  <Text size="p2">{I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.MORE_INFO_TEXT")}</Text>
                ) : null
              }
            />
          </List>
        </Card>
      </Box>
    </Screen.View>
  );
};
