import { Platform } from "react-native";
import z from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const PasswordUpdateResponseSchema = z
  .object({
    success: z.boolean(),
  })
  .describe("PasswordUpdateResponseSchema");

export type PasswordUpdateResponse = z.infer<typeof PasswordUpdateResponseSchema>;

interface PasswordUpdateRequest {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const URL = "/go/update_password";
const baseUrl =
  Platform.OS === "web" ? "/account" : __DEV__ ? undefined : "https://account.meraki-go.com";

function updatePassword({
  oldPassword,
  newPassword,
  confirmNewPassword,
}: PasswordUpdateRequest): Promise<PasswordUpdateResponse> {
  return request(PasswordUpdateResponseSchema, "POST", URL, {
    baseUrl,
    body: JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: confirmNewPassword,
    }),
  });
}

export const useUpdatePassword = createMutation<PasswordUpdateRequest, PasswordUpdateResponse>({
  baseMutationKey: URL,
  mutationFn: (variable: PasswordUpdateRequest) => updatePassword(variable),
});
