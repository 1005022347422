import { I18n } from "@meraki/core/i18n";
import { communityUrl, documentationQueryUrl, launchRouterFirewallPlusUrl } from "@meraki/go/links";
import { getProductType } from "@meraki/shared/devices";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { useEffect, useLayoutEffect, useState } from "react";
import { Clipboard, LayoutAnimation, StyleSheet, View } from "react-native";

import { useCurrentOrgDeviceBySerial } from "~/api/queries/organizations/useOrgDevices";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import BroacastingSSIDsCard from "~/go/components/BroadcastingSSIDsCard";
import ChannelUtilizationGraph from "~/go/components/ChannelUtilizationGraph";
import DeleteHardwareButton from "~/go/components/DeleteHardwareButton";
import HardwareDetailsHeaderWrapper from "~/go/components/hardware/HardwareDetailsHeaderWrapper";
import HardwareTroubleshootingTools from "~/go/components/hardware/HardwareTroubleshootingTools";
import productInfoRow from "~/go/components/hardware/productInfoRow";
import InlineAlert from "~/go/components/InlineAlert";
import SecurityLicenseCard from "~/go/components/SecurityLicenseCard";
import SsidClientUsageSummary from "~/go/components/SsidClientUsageSummary";
import TroubleshootingModal from "~/go/components/TroubleshootingModal";
import { showAlert } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import {
  isGX20,
  stripAlertTags,
  validateNodeAlertName,
  validNodeAlertMap,
} from "~/lib/DeviceUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_CATEGORIES } from "~/lib/FirebaseUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import { clientsSelector, getDeviceWithFirmwareFromSerial } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import NodeInfoCard from "~/shared/components/NodeInfoCard.go";
import PortSummaryCard from "~/shared/components/portDiagrams/PortSummaryCard";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import SlideUpContainer from "~/shared/components/SlideUpContainer";
import SummaryCard from "~/shared/components/SummaryCard";
import UplinkTrafficGraph from "~/shared/components/UplinkTrafficGraph.go";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { SettingsButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

const logScreenToFirebase = (device: any) => {
  const { model } = device;
  analytics.logEvent(FIREBASE_CATEGORIES.hardwareScreenVisit, { hardware_screen: model });
};

interface HardwareDetailsScreenProps {
  serialNumber: string;
  loadHardwareListData?: () => void;
}

const HardwareDetailsScreen = ({
  serialNumber,
  loadHardwareListData,
}: HardwareDetailsScreenProps) => {
  setupAndroidLayoutAnimation();

  const theme = useTheme();
  const clients = useAppSelector(clientsSelector);
  const networkId = useCurrentNetworkId();
  const device = useAppSelector(
    (state) => getDeviceWithFirmwareFromSerial(state, { serialNumber }) as DeviceWithNodeGroupInfo,
  );
  const ngData = useCurrentOrgDeviceBySerial(serialNumber).data;
  const timespan = useGlobalTimespan();

  const actions = useActions();
  const navigation = useNavigation();

  const [reqPending, setReqPending] = useState<boolean>(false);
  const [showTroubleshooting, setShowTroubleshooting] = useState<boolean>(false);
  const [didCloseAlert, setDidCloseAlert] = useState<boolean>(false);
  const [didCloseGX50Alert, setDidCloseGX50Alert] = useState<boolean>(false);
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);

  const productType = getProductType(device.model);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <SettingsButton
          onPress={() =>
            navigation.navigate("HardwareSettings", {
              serialNumber,
            })
          }
        />
      ),
    });
  }, [navigation, serialNumber]);

  const loadData = (force = false) => {
    if (isEmpty(device) || networkId == undefined) {
      return;
    }

    try {
      actions.fetchSingleDeviceDetails(serialNumber);
      actions.checkLSPConnection(serialNumber, productType);

      if (productType === ProductType.switch) {
        actions.getSwitchPortsJson(device.id, serialNumber);
      }
      if (productType === ProductType.appliance) {
        actions.getManagementInterface(device.serial);
      }

      if (isEmpty(clients) || force) {
        actions.getClients();
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    logScreenToFirebase(device);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timespan]);

  const getAlertName = () => {
    return device?.alerts?.[0]?.name;
  };

  const alertNameIsValid = () => {
    const name = getAlertName();
    return name != null && validateNodeAlertName(name);
  };

  const getAlertTitle = () => {
    return alertNameIsValid()
      ? stripAlertTags(device.alerts?.[0]?.short_desc)
      : I18n.t("DEFAULT_ALERT_TITLE");
  };

  const getAlertMessage = () => {
    if (!alertNameIsValid()) {
      return I18n.t("DEFAULT_ALERT_MESSAGE");
    }

    const name = getAlertName();
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const alertKey = name ? validNodeAlertMap[name] : null;
    if (alertKey == null) {
      return undefined;
    }

    return I18n.t(alertKey);
  };

  const showLearnMore = () => {
    alertNameIsValid() ? documentationQueryUrl(getAlertTitle()) : communityUrl();
  };

  const deleteHardware = () => {
    setReqPending(true);
    actions
      .removeDevice(device.serial)
      .then(() => actions.unclaimDevice(device.id))
      .then(() => {
        setReqPending(false);
        navigation.goBack();
        if (loadHardwareListData) {
          loadHardwareListData();
        } else if (networkId) {
          actions.loadNodesAndStatuses(networkId);
        }
      })
      .catch(() => {
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        setReqPending(false);
      });
  };

  const showtAPAvailabilityModal = (ssidNumber: number) => {
    navigation.navigate("AccessPointAvailability", { ssidNumber });
  };

  const launchTroubleshootingTools = () => {
    navigation.navigate("Troubleshooting", {
      device,
      presentedModally: true,
    });
  };

  const showContactSupport = () => {
    navigation.navigate("SearchSubject");
  };

  const closeAlertCard = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setDidCloseAlert(true);
  };

  const closeGX50AlertCard = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setDidCloseGX50Alert(true);
  };

  const getHasUnreadAlert = () => {
    return didCloseAlert === false && !isEmpty(device?.alerts);
  };

  const renderGX50Upsell = () => {
    return (
      <InlineAlert
        visible={isGX20(device) && !didCloseGX50Alert}
        onExit={closeGX50AlertCard}
        alertTitle={I18n.t("GX50_UPSELL_CARD.TITLE")}
        alertMessage={I18n.t("GX50_UPSELL_CARD.MESSAGE")}
        screenStyles={styles.inlineAlert}
        primaryButtonText={I18n.t("LEARN_MORE")}
        onPrimaryPress={launchRouterFirewallPlusUrl}
        preferenceKey={"GX50_UPSELL"}
        testID="GX50_UPSELL"
      />
    );
  };

  const renderSecurityUsageChart = () => {
    if (productType === ProductType.appliance) {
      return (
        <SummaryCard
          heading={I18n.t("HARDWARE_SCREEN.LIVE_USAGE")}
          contentContainerStyles={styles.deviceDetailCard}
          testID="LIVE_USAGE_CHART"
        >
          <UplinkTrafficGraph deviceId={device.id} />
        </SummaryCard>
      );
    }

    return;
  };

  const copyToClipboard = (deviceInfo: any) => {
    Clipboard.setString(deviceInfo);
    setShowCopySuccess(true);
  };

  const resetCopySuccess = () => setShowCopySuccess(false);

  const renderCopyMessage = () => {
    if (!showCopySuccess) {
      return null;
    }

    return (
      <SlideUpContainer
        shouldSlideDown
        afterComplete={resetCopySuccess}
        screenStyles={styles.slideContainerStyle}
      >
        <ListRow rowStyles={styles.slideRowStyle} underlayColor={MkiColors.primaryButtonLight}>
          <MkiText textStyle="activeTab">{I18n.t("COPIED_TO_CLIPBOARD")}</MkiText>
        </ListRow>
      </SlideUpContainer>
    );
  };

  const hasUnreadAlert = getHasUnreadAlert();

  if (isEmpty(device)) {
    return null;
  }

  const isWireless = getProductType(device?.model) === ProductType.wireless;

  const troubleshootingMoreTools = () => {
    launchTroubleshootingTools();
  };

  const troubleshootingHandleContactSupport = () => {
    setShowTroubleshooting(false);
    showContactSupport();
  };

  return (
    <FullScreenContainerView>
      <RefreshControlScrollView
        testID="HARDWARE_DETAILS_SCREEN_SCROLL_VIEW"
        keyboardShouldPersistTaps="handled"
        onRefresh={() => loadData(true)}
      >
        <HardwareDetailsHeaderWrapper
          device={device}
          setReqPending={setReqPending}
          ngData={ngData}
        />
        <InlineAlert
          visible={hasUnreadAlert}
          onExit={() => closeAlertCard}
          alertTitle={getAlertTitle()}
          alertMessage={getAlertMessage() || ""}
          primaryButtonText={I18n.t("TROUBLESHOOT")}
          secondaryButtonText={I18n.t("LEARN_MORE")}
          onPrimaryPress={() => setShowTroubleshooting(true)}
          onSecondaryPress={() => showLearnMore}
          testID={`ALERT_${getAlertName()?.toUpperCase()}`}
        />
        {renderGX50Upsell()}
        <SecurityLicenseCard serialNumber={serialNumber} productType={productType} />
        {isWireless && <BroacastingSSIDsCard onPress={showtAPAvailabilityModal} device={device} />}
        <SummaryCard
          heading={capitalizeFirstLetter(I18n.t("DEVICES_WORD"))}
          contentContainerStyles={styles.deviceDetailCard}
          testID="DEVICES_CARD"
        >
          <SsidClientUsageSummary
            navigate={navigation.navigate}
            filter={CUSTOM_FILTERS.DEVICE_CLIENTS(device.id)}
          />
        </SummaryCard>
        {isWireless && <ChannelUtilizationGraph serialNumber={serialNumber} />}
        {renderSecurityUsageChart()}
        {!isWireless && <PortSummaryCard device={device} navigate={navigation.navigate} />}
        <HardwareTroubleshootingTools device={device} />
        <NodeInfoCard
          heading={I18n.t("PRODUCT_INFO")}
          headingTextStyle={styles.headerTextStyle}
          device={device}
          renderRow={(rowData) => productInfoRow(rowData, theme, navigation, copyToClipboard)}
          ngData={ngData}
          disableBottomBorder
          testID="NODE_INFO_CARD"
        />
        <TroubleshootingModal
          visible={showTroubleshooting}
          onPrimaryPress={() => troubleshootingMoreTools}
          onSecondaryPress={() => troubleshootingHandleContactSupport}
          onExit={() => setShowTroubleshooting(false)}
        />
        <View style={styles.footerContainer}>
          <DeleteHardwareButton device={device} reqPending={reqPending} onDelete={deleteHardware} />
        </View>
      </RefreshControlScrollView>
      {renderCopyMessage()}
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  footerContainer: {
    marginVertical: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
  },
  deviceDetailCard: {
    borderBottomWidth: 0,
    marginHorizontal: 0,
  },
  slideContainerStyle: {
    borderTopColor: MkiColors.goPurple,
    borderTopWidth: SPACING.tiny,
  },
  slideRowStyle: {
    backgroundColor: MkiColors.primaryButtonLight,
    paddingHorizontal: SPACING.default,
  },
  headerTextStyle: {
    fontSize: normalizedFontSize(19),
  },
  inlineAlert: {
    marginVertical: SPACING.default,
  },
});

export default HardwareDetailsScreen;
