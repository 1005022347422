import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { usePortStatuses } from "@meraki/react-live-broker";
import { Device } from "@meraki/shared/api";
import { FiltersState, PortFilterCategories, PortFilterOptions } from "@meraki/shared/filters";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { isEmpty } from "lodash";

export const AppliancePortOverviewCard = ({ device }: { device: Device }) => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  const liveGXPortData = usePortStatuses(device.id);

  const getGXActivePortCount = () => {
    if (liveGXPortData === undefined) {
      return 0;
    }
    if (isEmpty(liveGXPortData)) {
      return 0;
    }

    const connectedPorts = liveGXPortData.filter((port) => port.carrier);
    return connectedPorts.length;
  };

  const navigateToListScreenWFilters = (
    statusFilters?: FiltersState<PortFilterCategories, PortFilterOptions>,
  ) => navigation.navigate("AppliancePortsList", { device, statusFilters });

  // GX does not report alerting port, so the alerting port number is 0
  return (
    <Card
      loadingContentHeight={30}
      loading={!liveGXPortData}
      testID="PORTS_CARD"
      onPress={() => navigateToListScreenWFilters()}
    >
      <Card.Header title={I18n.t("PORTS_WORD")} />
      <Card.Content flexDirection="row" gap="sm">
        <Card.Well
          flex={1}
          onPress={() => navigateToListScreenWFilters({ status: new Set(["online"]) })}
        >
          <Heading>{getGXActivePortCount()}</Heading>
          <Box flexDirection="row" gap="2xs" alignItems="center">
            <Text size="p2" weight="bold">
              {I18n.t("ACTIVE")}
            </Text>
            <Status status="positive" />
          </Box>
        </Card.Well>
        <Card.Well
          flex={1}
          onPress={() => navigateToListScreenWFilters({ status: new Set(["offline"]) })}
        >
          <Heading>{0}</Heading>
          <Box flexDirection="row" gap="2xs" alignItems="center">
            <Text size="p2" weight="bold">
              {I18n.t("ALERTING")}
            </Text>
            <Status status="negative" />
          </Box>
        </Card.Well>
      </Card.Content>
    </Card>
  );
};
