import { I18n } from "@meraki/core/i18n";
import { Admin, AuthUser, useAdmins, useAuthUsers, useIpSecVPN } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { RADIO_BUTTON_SIZE, SPACING } from "~/constants/MkiConstants";
import { useAuthUsersByEmail } from "~/go/hooks/useAuthUsersByEmail";
import { SettingsStackProps } from "~/go/navigation/Types";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import {
  currentNetworkState,
  currentOrganization,
  currentUserState,
  getNetworkName,
  isFetchingAdmins,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { AddButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import DropDownRow from "~/shared/rows/DropDownRow";
import { AdminAccountStatus, OrgAccess } from "~/shared/types/AdminTypes";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "AdminAndAuthUser"> &
  PendingComponent;

const bottomSheetData = [
  I18n.t("CLIENT_VPN.USER.INVITE_ADMIN"),
  I18n.t("CLIENT_VPN.USER.INVITE_GUEST"),
];

function AdminAndAuthUserScreen({ setReqPending, isFromClientVPN }: Props) {
  const navigation = useNavigation();
  const loading = useAppSelector(isFetchingAdmins);

  useEffect(() => {
    const showBottomSheet = () => {
      showActionSheet(
        bottomSheetData,
        (index: number) => {
          navigation.navigate(index === 1 ? "InviteGuest" : "InviteAdministrator");
        },
        {
          title: I18n.t("CLIENT_VPN.USER.TITLE"),
        },
      );
    };

    navigation.setOptions({
      headerRight: () =>
        isFromClientVPN ? (
          <AddButton onPress={() => showBottomSheet()} />
        ) : (
          <AddButton onPress={() => navigation.navigate("InviteAdministrator")} />
        ),
      headerTitle: isFromClientVPN
        ? I18n.t("CLIENT_VPN.USER.TITLE")
        : I18n.t("CLIENT_VPN.ADMIN.TITLE"),
    });
  }, [isFromClientVPN, navigation]);

  const networkId = useAppSelector(currentNetworkState);
  const currentUser = useAppSelector(currentUserState);
  const organizationId = useAppSelector(currentOrganization).id ?? "";
  const networkName = useAppSelector(getNetworkName);

  const { data: admins } = useAdmins({ organizationId });
  const { data: ipSecVPN } = useIpSecVPN({ networkId });
  const { data } = useAuthUsers({ networkId });
  const clientVPNEnabledOnNetwork = ipSecVPN?.enabled ?? false;
  const authsOnNetworkByEmail = useAuthUsersByEmail(networkId);

  const actions = useActions();

  useEffect(() => {
    setReqPending(true);
    try {
      actions.fetchAlertSettings();
    } catch {
      showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [actions, setReqPending]);

  const viewAdministrator = (adminId: string) => {
    navigation.navigate("ViewAdministrator", {
      adminId,
    });
  };

  const viewGuest = (id: string, email: string, name: string) => {
    navigation.navigate("ViewGuest", {
      id,
      name,
      email,
    });
  };

  const guests = data?.filter((guest) => !admins?.find((admin) => admin.email === guest.email));

  // Put the current user in the first of order
  const sortAdmins = () => {
    if (!admins) {
      return [];
    }
    return admins.slice().sort(({ email }) => (email === currentUser ? -1 : 1));
  };

  const getRowSuffixForVerified = (email: string, orgAccess?: Admin["orgAccess"]) => {
    if (email === currentUser) {
      return I18n.t("ADMIN.SUFFIX.CURRENT_USER");
    } else if (orgAccess === OrgAccess.read) {
      return I18n.t("ADMIN.SUFFIX.READ_ONLY");
    }

    return;
  };

  const isClientVPNEnabledOnAdmin = (email: string) => {
    return authsOnNetworkByEmail.data && authsOnNetworkByEmail.data[email]?.clientVPNEnabled;
  };

  const renderRow = ({ accountStatus, id, email, name, orgAccess }: Admin) => {
    const isUnverified = accountStatus === AdminAccountStatus.unverified;
    return (
      <DisclosureRow
        key={id}
        testID={`AdministratorListRow - ${id}`}
        icon={
          <View style={styles.iconContainer}>
            <MerakiIcon
              color={isUnverified ? MkiColors.secondaryButton : MkiColors.goPurple}
              name="profile"
              size="s"
            />
            {clientVPNEnabledOnNetwork && isClientVPNEnabledOnAdmin(email) && (
              <MerakiIcon
                color={isUnverified ? MkiColors.secondaryButton : MkiColors.goPurple}
                name="vpnAccess"
                size="s"
                containerStyle={styles.vpn}
                testID="AdministratorListRow_VPNIcon"
              />
            )}
          </View>
        }
        onPress={() => viewAdministrator(id)}
      >
        <MkiText>
          {name}
          <MkiText screenStyles={styles.suffix}>
            {isUnverified
              ? I18n.t("ADMIN.SUFFIX.WAITING")
              : getRowSuffixForVerified(email, orgAccess)}
          </MkiText>
        </MkiText>
      </DisclosureRow>
    );
  };

  const renderRowForGuest = ({ id, email, name }: AuthUser) => {
    return (
      <DisclosureRow
        testID={`GuestListRow - ${id}`}
        key={id}
        icon={
          <View style={styles.iconContainer}>
            <MerakiIcon color={MkiColors.secondaryButton} name="profile" size="s" />
            {clientVPNEnabledOnNetwork && (
              <MerakiIcon
                color={MkiColors.secondaryButton}
                name="vpnAccess"
                size="s"
                containerStyle={styles.vpn}
              />
            )}
          </View>
        }
        onPress={() => viewGuest(id, email, name)}
      >
        <MkiText>{name}</MkiText>
      </DisclosureRow>
    );
  };

  return (
    <FullScreenContainerView withScroll>
      {isFromClientVPN ? (
        <>
          <DropDownRow title={I18n.t("CLIENT_VPN.USER.ADMIN")} noMargin>
            {sortAdmins().map(renderRow)}
          </DropDownRow>
          <DropDownRow title={I18n.t("CLIENT_VPN.USER.GUEST")} subtitle={networkName} noMargin>
            {guests && guests.map(renderRowForGuest)}
          </DropDownRow>
        </>
      ) : (
        <MkiTable<Admin>
          data={sortAdmins()}
          keyExtractor={(admin) => admin.id}
          renderRow={renderRow}
          loading={loading}
          scrollViewTestID={loading ? "AdministratorListScreen_Loading" : "AdministratorListScreen"}
        />
      )}
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: "row",
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  suffix: {
    color: MkiColors.secondaryTextColor,
  },
  vpn: {
    position: "absolute",
    top: RADIO_BUTTON_SIZE,
    left: SPACING.small,
  },
});

export default withPendingComponent(AdminAndAuthUserScreen);
