import { Icon, IconName } from "@meraki/magnetic/icons";
import {
  ColorToken,
  getColorFromTokenName,
  Theme,
  useMagneticTheme,
} from "@meraki/magnetic/themes";
import Color from "color";
import { ActivityIndicator, View, ViewStyle } from "react-native";

import { Text, TextProps } from "../Text/Text";

const KIND_MAP = {
  primary: {
    backgroundColor: {
      default: "interact.bg.base",
      pressed: "interact.bg.hover",
    },
    borderColor: {
      default: "interact.bg.base",
      pressed: "interact.bg.hover",
    },
    textColor: {
      default: "interact.textIn.base",
      pressed: "interact.textIn.hover",
    },
  },
  primaryDestructive: {
    backgroundColor: {
      default: "negative.bg.base",
      pressed: "negative.bg.hover",
    },
    borderColor: {
      default: "negative.bg.base",
      pressed: "negative.bg.hover",
    },
    textColor: {
      default: "negative.textIn.base",
      pressed: "negative.textIn.hover",
    },
  },
  secondary: {
    backgroundColor: {
      default: "interact.bg.weak.base",
      pressed: "interact.bg.weak.hover",
    },
    borderColor: {
      default: "interact.border.base",
      pressed: "interact.border.hover",
    },
    textColor: {
      default: "interact.text.base",
      pressed: "interact.text.hover",
    },
  },
  secondaryDestructive: {
    backgroundColor: {
      default: "negative.bg.weak.base",
      pressed: "negative.bg.weak.hover",
    },
    borderColor: {
      default: "negative.border.base",
      pressed: "negative.border.hover",
    },
    textColor: {
      default: "negative.text.base",
      pressed: "negative.text.hover",
    },
  },
  tertiary: {
    backgroundColor: {
      default: "transparent",
      pressed: "interact.bg.weak.hover",
    },
    borderColor: {
      default: "transparent",
      pressed: "transparent",
    },
    textColor: {
      default: "interact.text.base",
      pressed: "interact.text.hover",
    },
  },
} as const;

export type ButtonKind = keyof typeof KIND_MAP;

interface InternalProps {
  kind: ButtonKind;
  text: string;
  disabled?: boolean;
  loading: boolean;
  pressed: boolean;

  leadingIcon?: IconName;
  trailingIcon?: IconName;
}
type InternalStyleProps = Omit<InternalProps, "text">;
export interface ButtonInternalProps extends Partial<InternalProps> {
  text: string; // Re-declared to make it required to everyone outside of this button...
}

function getBoxProps(
  { kind, disabled, loading, pressed }: InternalStyleProps,
  theme: Theme,
): ViewStyle {
  let backgroundColor = getColorFromTokenName(
    KIND_MAP[kind]["backgroundColor"][pressed ? "pressed" : "default"],
    theme,
  );

  if ((kind === "secondaryDestructive" || kind === "secondary") && pressed) {
    // Ugh, we should probably not have to do this...
    backgroundColor = Color(backgroundColor).alpha(0.5).hexa();
  }

  const applyPadding = kind !== "tertiary";

  return {
    gap: 5,
    backgroundColor,
    borderColor: getColorFromTokenName(
      KIND_MAP[kind]["borderColor"][pressed ? "pressed" : "default"],
      theme,
    ),
    borderWidth: theme.SizeInteractStroke,
    paddingHorizontal: applyPadding ? theme.SizeInteractPaddingHorizMd : undefined,
    paddingVertical: applyPadding ? theme.SizeInteractPaddingVertMd : undefined,
    borderRadius: theme.SizeInteractRadiusBorder,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: !disabled && !loading ? 1 : 0.4,
  };
}

function getLoadingProps({ kind }: InternalStyleProps, theme: Theme) {
  return {
    color: getColorFromTokenName(KIND_MAP[kind]["textColor"]["default"], theme),
    size: 20,
  };
}

function getTextStyles({ kind, pressed }: InternalStyleProps): Omit<TextProps, "children"> {
  return {
    size: "p1",
    weight: "regular",
    color: KIND_MAP[kind]["textColor"][pressed ? "pressed" : "default"],
    selectable: false,
  };
}

function getIconColor({ kind, pressed }: InternalStyleProps): ColorToken {
  return KIND_MAP[kind]["textColor"][pressed ? "pressed" : "default"];
}

export function ButtonInternal({
  kind = "primary",
  disabled = false,
  loading = false,
  pressed = false,
  leadingIcon,
  trailingIcon,
  text,
}: ButtonInternalProps) {
  const defaultedProps = { kind, disabled, pressed, loading };
  const theme = useMagneticTheme();

  // This is just used to keep the conditional rendering of the loading state a little more clear
  let leadingIconElement = leadingIcon && (
    <Icon name={leadingIcon} color={getIconColor(defaultedProps)} size={20} />
  );

  if (loading) {
    leadingIconElement = <ActivityIndicator {...getLoadingProps(defaultedProps, theme)} />;
  }

  return (
    <View style={getBoxProps(defaultedProps, theme)}>
      {leadingIconElement}
      <Text {...getTextStyles(defaultedProps)}>{text}</Text>
      {trailingIcon && <Icon name={trailingIcon} color={getIconColor(defaultedProps)} size={20} />}
    </View>
  );
}
