import { assign, cloneDeep, get, isEmpty, isEqual, merge, omit } from "lodash";

import { isString } from "~/lib/TypeHelper";

export default function resolvedState(nextState: any, state: any) {
  return isEqual(nextState, state) ? state : nextState;
}

export function reducerHelper(
  state: any,
  location: string,
  newData: any,
  shouldOverwrite?: boolean,
) {
  if (shouldOverwrite) {
    return {
      ...state,
      [location]: newData,
    };
  }

  return {
    ...state,
    [location]: {
      ...state[location],
      ...newData,
    },
  };
}

export const reducerHelper2 = (
  state: any,
  location: string | string[],
  newData: any,
  shouldOverwrite?: boolean,
) => {
  const locationArrayCopy = isString(location)
    ? ([location] as string[])
    : (location.slice(0) as string[]);

  return {
    ...state,
    ...modifyLocation(state, locationArrayCopy, newData, shouldOverwrite),
  };
};

// @ts-expect-error TS(7023): 'modifyLocation' implicitly has return type 'any' ... Remove this comment to see the full error message
const modifyLocation = (
  state: any,
  location: string[],
  newData: any,
  shouldOverwrite?: boolean,
) => {
  if (location.length === 1) {
    const parent = location[0];
    const shouldAggregate = !(Array.isArray(newData) || isEmpty(newData));
    const aggregationMode = shouldOverwrite ? assign : merge;

    const result = shouldAggregate
      ? cloneDeep(aggregationMode(get(state, parent), newData))
      : newData;

    return { [parent]: result };
  }

  const head = location.shift();
  if (!head) {
    throw Error("Location cannot be empty/null/undefined!");
  }

  const stateAtHead = get(state, head);

  return {
    [head]: {
      ...omit(stateAtHead, location[0]),
      ...modifyLocation(stateAtHead, location, newData, shouldOverwrite),
    },
  };
};
