import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const RuleTypeSchema = z.union([
  z.literal("application"),
  z.literal("applicationCategory"),
  z.literal("host"),
  z.literal("port"),
  z.literal("ipRange"),
]);

const SsidL7FirewallRuleSchema = z.object({
  policy: z.literal("deny"),
  type: RuleTypeSchema,
  value: z.string(),
});

export const SsidL7FirewallRulesSchema = z
  .object({
    rules: z.array(SsidL7FirewallRuleSchema),
  })
  .describe("SsidL7FirewallRulesSchema");

export type SsidL7FirewallRule = z.infer<typeof SsidL7FirewallRuleSchema>;
export type SsidL7FirewallRules = z.infer<typeof SsidL7FirewallRulesSchema>;

interface SsidL7FirewallRulesBuildUrlRequest {
  networkId?: string;
  ssidNumber: string;
}
interface SsidL7FirewallRulesRequest {
  networkId?: string;
  ssidNumber?: number;
}

interface SetSsidL7FirewallRulesRequest {
  networkId?: string;
  ssidNumber: string;
  rules: SsidL7FirewallRule[];
}

const buildUrl = ({ networkId, ssidNumber }: SsidL7FirewallRulesBuildUrlRequest) => {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/firewall/l7FirewallRules`;
};

const fetchSsidL7FirewallRules = ({ networkId, ssidNumber }: SsidL7FirewallRulesRequest) => {
  if (ssidNumber === undefined) {
    throw new Error("SSID Number is not defined");
  }
  return request(
    SsidL7FirewallRulesSchema,
    "GET",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
  );
};

const putSsidL7FirewallRules = ({
  networkId,
  ssidNumber,
  rules,
}: SetSsidL7FirewallRulesRequest) => {
  return request(SsidL7FirewallRulesSchema, "PUT", buildUrl({ networkId, ssidNumber }), {
    body: JSON.stringify({ rules }),
  });
};

export const useSsidL7FirewallRules = createQuery<
  SsidL7FirewallRulesRequest,
  SsidL7FirewallRules,
  APIResponseError
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}", ssidNumber: "{ssidNumber}" }),
  queryFn: (parameters: SsidL7FirewallRulesRequest) => fetchSsidL7FirewallRules(parameters),
  requiredVariables: ["networkId", "ssidNumber"],
});

export const useSetSsidL7FirewallRules = createMutation<
  SetSsidL7FirewallRulesRequest,
  SsidL7FirewallRules,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}", ssidNumber: "{ssidNumber}" }),
  mutationFn: (variables) => putSsidL7FirewallRules(variables),
});
