import { PureComponent } from "react";
import { InputAccessoryView, InputAccessoryViewProps } from "react-native";

import { platformSelect } from "~/lib/PlatformUtils";

// If touches are not registered for the buttons inside
// of the accessory view, make sure you send the following prop
// into the parent scrollview
// keyboardShouldPersistTaps="handled"
class MkiInputAccessoryView extends PureComponent<InputAccessoryViewProps> {
  render() {
    // InputAccessoryView is not supported for android.  Adding this in will crash android
    return platformSelect({
      android: null,
      ios: <InputAccessoryView {...this.props} />,
    });
  }
}

export default MkiInputAccessoryView;
