import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const SupportCaseSchema = z
  .object({
    caseNumber: z.string(),
    caseThreadId: z.string(),
    contactEmail: z.string(),
    contactName: z.string(),
    createdDate: z.string(),
    customerAssignedPriority: z.string().nullable(),
    description: z.string().nullable(),
    id: z.string(),
    lastModifiedDate: z.string(),
    organization: z.object({
      id: z.string(),
      name: z.string(),
      shard_id: z.string(),
      url: z.string(),
    }),
    organizationNumber: z.string(),
    ownerName: z.string(),
    status: z.string(),
    statusDetails: z.string().nullable(),
    subject: z.string(),
    suppliedEmail: z.string().nullable(),
    suppliedName: z.string().nullable(),
  })
  .describe("SupportCaseSchema");

export const SupportCasesSchema = z.array(SupportCaseSchema).describe("SupportCaseListSchema");

export type SupportCase = z.infer<typeof SupportCaseSchema>;
export type SupportCases = z.infer<typeof SupportCasesSchema>;

const buildUrl = ({ encryptedNetworkId }: SupportCasesRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/case_list`;
};

interface SupportCasesRequest {
  encryptedNetworkId?: string;
}
interface SupportCasesParams extends SupportCasesRequest {
  userEmail: string;
}

const fetchSupportCases = ({ encryptedNetworkId }: SupportCasesParams) => {
  return request(SupportCasesSchema, "GET", buildUrl({ encryptedNetworkId }));
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSupportCases = createQuery<SupportCasesParams, SupportCases>({
  baseQueryKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  queryFn: fetchSupportCases,
  requiredVariables: ["encryptedNetworkId", "userEmail"],
});
