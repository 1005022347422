import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { UmbrellaScreensPropMap } from "~/go/navigation/Types";
import MkiTable from "~/shared/components/MkiTable";
import NoDataFooter from "~/shared/components/NoDataFooter";
import { CloseButton, DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

type Props = ForwardedNativeStackScreenProps<UmbrellaScreensPropMap, "UmbrellaExcludedURLs">;

interface UmbrellaExcludedURLsScreenState {
  excludedURLs: string[];
  isEditMode: boolean;
}

type UrlRow = { url: string };

export class UmbrellaExcludedURLsScreen extends PureComponent<
  Props,
  UmbrellaExcludedURLsScreenState
> {
  static renderSectionFooter = ({ section }: { section: SectionListData<UrlRow> }) => (
    <NoDataFooter data={section.data} noDataString={I18n.t("UMBRELLA.EXCLUDE_URL.EMPTY_STATE")} />
  );

  constructor(props: Props) {
    super(props);
    const { passedExcludedURLs } = props;

    this.state = {
      excludedURLs: [...passedExcludedURLs],
      isEditMode: false,
    };

    this.setNavOptions();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { isEditMode } = this.state;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => this.setState({ isEditMode: true })} />;
        } else {
          return <DoneButton onPress={() => this.setState({ isEditMode: false })} />;
        }
      },
    });
  }

  componentWillUnmount() {
    const { updateURLs } = this.props;
    const { excludedURLs } = this.state;

    updateURLs(excludedURLs);
  }

  getData = () => {
    const { excludedURLs } = this.state;
    return excludedURLs.map((url) => ({ url }));
  };

  delete = (deletedURLIndex: number) => {
    this.setState((prevState) => {
      const newExcludedURLs = [...prevState.excludedURLs];
      newExcludedURLs.splice(deletedURLIndex, 1);

      return { excludedURLs: newExcludedURLs };
    });
  };

  addEditURL = (rowId: number, url: string) => {
    this.setState((prevState) => {
      const newExcludedURLs = [...prevState.excludedURLs];

      if (rowId == null) {
        newExcludedURLs.push(url);
      } else {
        newExcludedURLs[rowId] = url;
      }

      return { excludedURLs: newExcludedURLs };
    });
  };

  pushAddEditURL = (rowId?: number, url?: string) => {
    const { navigation } = this.props;
    navigation.navigate("UmbrellaExcludeURL", {
      rowId,
      passedURL: url,
      addEditURL: this.addEditURL,
    });
  };

  renderRow = (rowData: UrlRow, rowId: number) => {
    const { isEditMode } = this.state;

    const deleteIcon = (
      <DeleteButton
        show={isEditMode}
        onPress={() => this.delete(rowId)}
        testID={`DELETE_BUTTON-${rowId}`}
      />
    );

    return (
      <ListRow
        icon={deleteIcon}
        onPress={() => this.pushAddEditURL(rowId, rowData.url)}
        rowStyles={styles.rowStyles}
      >
        {rowData.url}
      </ListRow>
    );
  };

  renderSectionHeader = () => {
    return (
      <SectionListHeader
        heading={I18n.t("UMBRELLA.EXCLUDE_URL.LIST_HEADER")}
        onPress={() => this.pushAddEditURL()}
        withHorizontalMargin
      />
    );
  };

  render() {
    return (
      <View style={styles.container} testID="UMBRELLA_EXCLUDED_URLS_SCREEN">
        <View style={styles.tableContainer}>
          <MkiTable<UrlRow>
            data={this.getData()}
            renderRow={this.renderRow}
            renderSectionHeader={this.renderSectionHeader}
            renderSectionFooter={UmbrellaExcludedURLsScreen.renderSectionFooter}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
});

export default UmbrellaExcludedURLsScreen;
