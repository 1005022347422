import Svg, { G, Path } from "react-native-svg";

import { IconProps } from "./types";

export function PassThrough({ color, x, y }: IconProps) {
  return (
    <G x={x} y={y}>
      <Svg fill="none" height="20" viewBox="0 0 20 20" width="20">
        <Path
          clipRule="evenodd"
          d="M2.4163 12.4921C1.72727 11.8031 2.21527 10.625 3.18969 10.625H17.1875C17.7052 10.625 18.125 11.0447 18.125 11.5625C18.125 12.0803 17.7052 12.5 17.1875 12.5H5.0758L8.78788 16.2121C9.154 16.5782 9.154 17.1718 8.78788 17.5379C8.42177 17.904 7.82818 17.904 7.46206 17.5379L2.4163 12.4921Z"
          fill={color}
          fillRule="evenodd"
        />
        <Path
          clipRule="evenodd"
          d="M11.2121 2.46209C11.5782 2.09597 12.1718 2.09597 12.5379 2.46209L17.5837 7.50785C18.2727 8.19688 17.7847 9.375 16.8103 9.375H2.8125C2.29473 9.375 1.875 8.95527 1.875 8.4375C1.875 7.91973 2.29473 7.5 2.8125 7.5H14.9242L11.2121 3.78791C10.846 3.4218 10.846 2.8282 11.2121 2.46209Z"
          fill={color}
          fillRule="evenodd"
        />
      </Svg>
    </G>
  );
}
