import { useBioauthEnabled } from "@meraki/core/bioauth";
import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { launchSupportEmailUrl } from "@meraki/go/links";
import { LoginResponseType, verifyIsLoginResponse } from "@meraki/shared/api";
import { PureComponent, Ref } from "react";
import {
  Image,
  ImageStyle,
  Keyboard,
  StyleProp,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LinearGradient from "react-native-linear-gradient";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { getAutoShowBioAuth, setAutoShowBioAuth } from "~/bioAuthHack";
import MkiColors from "~/constants/MkiColors";
import {
  BOX_BORDER_RADIUS,
  KEYBOARD_TYPE,
  RETURN_KEY,
  SPACING,
  TIMEOUT_ERROR_KEY,
} from "~/constants/MkiConstants";
import BoxContainer from "~/go/components/BoxContainer";
import ForgotPasswordButton from "~/go/components/ForgotPasswordButton";
import { LoginStackProps } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import withCodePush from "~/hocs/CodePush";
import { showAlert } from "~/lib/AlertUtils";
import { handleBioAuthFailure } from "~/lib/BioAuth";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import { isDebug, isNightly, isWeb } from "~/lib/PlatformUtils";
import { sizeSelect, themeMode } from "~/lib/themeHelper";
import {
  biometricAuthState,
  currentUserState,
  errorMessageState,
  getIsSSOInProgress,
  getVerifiedEmail,
  isFetchingState,
  showCaptchaState,
} from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import CaptchaButton from "~/shared/components/CaptchaButton";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import ChooseClusterButton from "~/shared/components/login/ChooseClusterButton";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import { LoginWithSpSamlButton } from "~/shared/screens/SpSamlLogin";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const GO_LOGO = {
  light: require("~/images/logos/merakiGo/logoMerakiGoLargeColor.png"),
  dark: require("~/images/logos/merakiGo/logoMerakiGoMediumWhite.png"),
};
const GO_BETA_LOGO = {
  light: require("~/images/logos/merakiGo/logoMerakiGoBetaLargeColor.png"),
  dark: require("~/images/logos/merakiGo/logoMerakiGoBetaLargeWhite.png"),
};

const ALERT_TITLE = {
  success: I18n.t("SUCCESS"),
  error: I18n.t("ERROR"),
};

const ALERT_MESSAGE = {
  timeout: I18n.t("LOGIN.TIMEOUT_ERROR"),
  delay: I18n.t("LOGIN.DELAY_WARNING"),
};

const LoginButton = ({ children, onPress, isFetching }: any) => (
  <TouchableOpacity
    testID="login button"
    style={styles.loginButton}
    onPress={onPress}
    disabled={isFetching}
  >
    <MkiText screenStyles={styles.loginButtonText}>{children}</MkiText>
  </TouchableOpacity>
);

const CreateAccountButton = ({ children, onPress }: any) => (
  <TouchableOpacity
    testID="create account button"
    style={styles.createAccountButton}
    onPress={onPress}
  >
    <MkiText textStyle="small" screenStyles={styles.createAccountText}>
      {children}
    </MkiText>
  </TouchableOpacity>
);

const ErrorMessage = ({ errorMessage }: any) => {
  if (!errorMessage) {
    return <View style={styles.errorContainer} />;
  }
  return (
    <BannerAlert
      alertType={GoStatus.bad}
      alertText={errorMessage}
      screenStyles={styles.incorrectLoginAlert}
    />
  );
};

const createSupportEmail = () => launchSupportEmailUrl({ showErrorWithContact: true });

type ReduxProps = {
  currentUser: any;
  biometricAuth?: {
    enabled: boolean;
  };
  isFetching: boolean;
  isSSOInProgress: boolean;
  errorMessage: string;
  showCaptcha: boolean;
  verifiedEmail: string;
};

type Props = ForwardedNativeStackScreenProps<LoginStackProps, "Login"> & ReduxProps & BasicActions;

type LoginState = {
  email: string;
  password: string;
  showCreatedAccountModal: boolean;
  resetEmail?: string;
  alert?: {
    message: string;
    status: (typeof GoStatus)[keyof typeof GoStatus];
  };
  enableBioAuth: boolean;
  captchaResolved: boolean;
  captchaResponseToken: string;
  withBioAuth?: boolean;
};

export class Login extends PureComponent<Props, LoginState> {
  static defaultProps = {
    isFirstLaunch: false,
  };

  private blurListenerUnsubscribe;
  private passwordInput?: Ref<TextInput>;
  private emailInput?: Ref<TextInput>;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: props.currentUser || "",
      password: "",
      showCreatedAccountModal: false,
      alert: undefined,
      enableBioAuth: this.getBiometricAuthEnabled(),
      captchaResolved: false,
      captchaResponseToken: "",
    };

    const { navigation } = props;
    this.blurListenerUnsubscribe = navigation.addListener("blur", this.componentDidDisappear);
  }

  componentWillUnmount() {
    this.blurListenerUnsubscribe();
  }

  componentDidMount() {
    const { isFirstLaunch } = this.props;

    if (isFirstLaunch) {
      this.launchWelcomeIntro();
    } else {
      this.emailInputFocus();

      // HACK: Make bioauth pop up if its configured the first time we show the login page.
      if (!getAutoShowBioAuth()) {
        return;
      }

      setAutoShowBioAuth(false);
      this.bioAuthLogin();
    }
  }

  componentDidDisappear = () => {
    const { actions } = this.props;
    actions.resetLoginState();
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { errorMessage } = this.props;
    if (nextProps.errorMessage === TIMEOUT_ERROR_KEY && errorMessage !== TIMEOUT_ERROR_KEY) {
      showAlert(ALERT_TITLE.error, ALERT_MESSAGE.timeout);
    }
  }

  getEmail = () => {
    const { verifiedEmail } = this.props;
    const { email } = this.state;
    return verifiedEmail || email;
  };

  launchCreateAccount = () => {
    const { navigation } = this.props;
    navigation.navigate("CreateAccount", {
      onCreatedAccount: this.setCreatedAccountModal,
    });
  };

  launchWelcomeIntro = () => {
    const { navigation } = this.props;
    navigation.navigate("Welcome");
  };

  dismissModal = () => {
    this.setState({
      showCreatedAccountModal: false,
      alert: undefined,
    });
  };

  onChangeEmail = (newEmail: any) => {
    const { verifiedEmail, actions } = this.props;
    if (verifiedEmail) {
      actions.clearVerifiedEmail();
    }

    actions.setCurrentUser(newEmail);
    this.setState({ email: newEmail });
  };

  onChangePassword = (newPassword: any) => {
    this.setState({ password: newPassword });
  };

  setCreatedAccountModal = (newEmail: any) => {
    this.setState({
      email: newEmail,
    });
  };

  onCaptchaComplete = (token: any) => {
    if (token) {
      this.setState({ captchaResolved: true, captchaResponseToken: token });
      // This work around lets recaptcha work because we update state here and if we call onLoginPress on this tick
      // it tries to use our state which isn't guarenteed to be updated.
      // Follow up ticket to solve better: DM-2836
      setTimeout(() => this.regularLogin());
    }
  };

  bioAuthLogin = async () => {
    const biometricAuthEnabled = this.getBiometricAuthEnabled();
    if (!biometricAuthEnabled || isWeb()) {
      return;
    }
    const { actions } = this.props;

    Keyboard.dismiss();
    try {
      const response = await actions.tryBioAuthLogin(true);
      this.setState({ withBioAuth: true });
      //@ts-ignore
      await this.handleLoginMode(response);
    } catch (error) {
      handleBioAuthFailure(error);
    }
  };

  regularLogin = () => {
    this.setState({ withBioAuth: false });
    this.login(false);
    return Promise.resolve();
  };

  emailInputFocus() {
    // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type '((instanc... Remove this comment to see the full error message
    this.emailInput?.focus();
  }

  passwordInputFocus = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type '((instanc... Remove this comment to see the full error message
    this.passwordInput?.focus();
  };

  handleLogin = async (mode: any, withBioAuth: any) => {
    const { actions } = this.props;
    actions.resetUpdateAlert();

    if (isWeb()) {
      actions.detectIdleTimeInWeb();
    }

    let loginEventType;
    if (withBioAuth) {
      loginEventType = FIREBASE_EVENTS.bioAuthLogin;
      actions.hideAskForBioAuth();
    } else {
      loginEventType = FIREBASE_EVENTS.regularLogin;
      actions.askForBioAuth();
    }

    analytics.logEvent(loginEventType);
    await this.handleLoginMode(mode);
  };

  handleLoginMode = (response?: LoginResponseType) => {
    const { navigation } = this.props;

    if (!response || verifyIsLoginResponse(response)) {
      return;
    }

    const { mode } = response;

    if (mode === "one_time_password") {
      navigation.navigate("OTPAuth");
    } else if (mode === "two_factor" || mode === "sms") {
      navigation.navigate("TwoFactorAuth");
    } else if (mode === "org_choose") {
      navigation.navigate("OrgChoose", { initialLogin: true });
    } else if (mode === "two_factor_onboarding") {
      navigation.navigate("IntroTwoFactor", {
        isOnboarding: false,
        forcingTwoFactor: true,
      });
    }
    return mode;
  };

  handleError = (error: unknown) => {
    const { verifiedEmail } = this.props;
    if (verifiedEmail) {
      showAlert(ALERT_TITLE.error, ALERT_MESSAGE.delay, null, {
        negativeText: I18n.t("CONTACT_SUPPORT"),
        onNegativePress: createSupportEmail,
      });
    }
    return error;
  };

  login = (withBioAuth: any) => {
    const { password, captchaResolved, captchaResponseToken } = this.state;
    const { actions } = this.props;

    let data = {
      password,
      email: this.getEmail(),
    };

    if (captchaResolved) {
      data = {
        ...data,
        // @ts-expect-error TS(2322) FIXME: Type '{ captcha_shown: any; mki_captcha_provider_r... Remove this comment to see the full error message
        captcha_shown: captchaResolved,
        mki_captcha_provider_response: captchaResponseToken,
        mki_captcha_provider_id: "hcaptcha",
      };
    }

    return actions
      .loginUser(data)
      .then((response) => this.handleLogin(response, withBioAuth))
      .catch(() => {
        this.handleError;
        this.setState({ captchaResolved: false, captchaResponseToken: "" });
      });
  };

  renderCreateAccount() {
    return (
      <CreateAccountButton onPress={this.launchCreateAccount}>
        {I18n.t("LOGIN.CREATE_ACCOUNT_TEXT.DONT_HAVE_ACCOUNT")}
        <MkiText textStyle="small" screenStyles={styles.registerNowText} testID={"LOGIN.REGISTER"}>
          {I18n.t("LOGIN.CREATE_ACCOUNT_TEXT.REGISTER_NOW")}
        </MkiText>
      </CreateAccountButton>
    );
  }

  renderHeader = () => {
    const { theme } = useTheme.getState();
    const logoThemeMode = themeMode(theme);
    const logoStyle: StyleProp<ImageStyle> = [styles.logo];

    if (isWeb()) {
      logoStyle.push(styles.webLogo);
    }

    return (
      <View style={styles.header}>
        <Image
          style={logoStyle}
          resizeMode="contain"
          source={isWeb() ? GO_BETA_LOGO[logoThemeMode] : GO_LOGO[logoThemeMode]}
        />
        <TouchableOpacity
          testID="welcome intro button"
          onPress={this.launchWelcomeIntro}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          transparentBackground
        >
          <MkiText textStyle="small" screenStyles={styles.aboutGoButton}>
            {I18n.t("LOGIN.ABOUT_GO")}
          </MkiText>
        </TouchableOpacity>
      </View>
    );
  };

  renderEmail = () => {
    const emailValue = this.getEmail();
    return (
      <View style={styles.inputContainer}>
        <InputRow
          testID="email input"
          ref={(ref: any) => {
            this.emailInput = ref;
          }}
          value={emailValue}
          onChangeText={this.onChangeEmail}
          placeholder={I18n.t("EMAIL_PLACEHOLDER")}
          keyboardType={KEYBOARD_TYPE.emailAddress}
          returnKeyType={RETURN_KEY.next}
          autoCapitalize="none"
          autoCorrect={false}
          onSubmitEditing={this.passwordInputFocus}
        >
          {I18n.t("LOGIN.EMAIL_PLACEHOLDER")}
        </InputRow>
      </View>
    );
  };

  renderPassword = () => {
    const { password } = this.state;
    const biometricAuthEnabled = this.getBiometricAuthEnabled();
    return (
      <View style={styles.inputContainer}>
        <InputRow
          testID="password input"
          ref={(ref: any) => {
            this.passwordInput = ref;
          }}
          value={password}
          placeholder={I18n.t("PASSWORD_PLACEHOLDER")}
          secureTextEntry
          revealable={!biometricAuthEnabled}
          enableBioAuth={biometricAuthEnabled}
          onBioAuthPress={this.bioAuthLogin}
          returnKeyType={RETURN_KEY.go}
          onSubmitEditing={this.regularLogin}
          onChangeText={this.onChangePassword}
        >
          {I18n.t("LOGIN.PASSWORD_TITLE")}
        </InputRow>
      </View>
    );
  };

  renderLoginButton = () => {
    const { isFetching, isSSOInProgress, showCaptcha } = this.props;

    if (showCaptcha) {
      return (
        <CaptchaButton
          onCaptchaComplete={this.onCaptchaComplete}
          disabled={isFetching}
          testID="CAPTCHA_LOGIN_BUTTON"
          buttonText={I18n.t("LOGIN.LOGIN")}
        />
      );
    }

    return (
      <LoginButton onPress={this.regularLogin} isFetching={isFetching || isSSOInProgress}>
        {I18n.t("LOGIN.LOGIN")}
      </LoginButton>
    );
  };

  getBiometricAuthEnabled = () => {
    const { biometricAuth } = this.props;
    const { enabled } = useBioauthEnabled.getState();
    return (enabled ?? false) || (biometricAuth?.enabled ?? false);
  };

  render() {
    const { email } = this.state;
    const { isFetching, errorMessage, isSSOInProgress } = this.props;

    const content = (
      <>
        <KeyboardAwareScrollView
          scrollEnabled
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
        >
          {this.renderHeader()}
          {this.renderEmail()}
          {this.renderPassword()}
          <ErrorMessage errorMessage={errorMessage} />
          <View style={styles.otherButtons}>
            <ForgotPasswordButton email={email} />
            <ChooseClusterButton />
          </View>
          {this.renderLoginButton()}
          {(isDebug || isNightly) && <LoginWithSpSamlButton />}
          {this.renderCreateAccount()}
        </KeyboardAwareScrollView>
        <LoadingSpinner visible={isFetching || isSSOInProgress} screenStyles={styles.spinner} />
      </>
    );

    if (isWeb()) {
      return content;
    }

    return (
      <LinearGradient
        colors={[MkiColors.gradientStart, MkiColors.gradientEnd]}
        start={{ x: 0, y: 0.0 }}
        end={{ x: 0.5, y: 1 }}
        style={styles.flexOne}
      >
        <BoxContainer>{content}</BoxContainer>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  spinner: {
    borderRadius: BOX_BORDER_RADIUS,
  },
  scrollView: {
    flex: 1,
    paddingHorizontal: SPACING.extraLarge,
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: sizeSelect({
      default: SPACING.default,
      small: SPACING.small,
    }),
  },
  logo: {
    height: SPACING.extraLarge,
    marginBottom: 0,
  },
  webLogo: {
    width: 100,
  },
  aboutGoButton: {
    paddingTop: SPACING.small + SPACING.tiny,
    paddingBottom: SPACING.small,
    color: MkiColors.primaryButton,
  },
  inputContainer: {
    width: "100%",
    backgroundColor: MkiColors.fullTransparent,
  },
  loginButton: {
    backgroundColor: MkiColors.primaryButton,
    borderRadius: 10,
    marginTop: SPACING.large,
    marginBottom: SPACING.small,
    paddingVertical: 13,
    alignSelf: "stretch",
    justifyContent: "center",
  },
  loginButtonText: {
    textAlign: "center",
    color: MkiColors.whiteBackground,
  },
  otherButtons: {
    marginTop: SPACING.meager,
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  createAccountButton: {
    alignSelf: "stretch",
    textAlign: "center",
    paddingVertical: SPACING.default,
  },
  createAccountText: {
    backgroundColor: MkiColors.fullTransparent,
    color: MkiColors.primaryButton,
    alignSelf: "stretch",
    textAlign: "center",
  },
  registerNowText: {
    fontWeight: "bold",
    color: MkiColors.primaryButton,
  },
  errorContainer: {
    paddingTop: SPACING.small,
    alignSelf: "flex-start",
  },
  incorrectLoginAlert: {
    marginTop: SPACING.default,
    marginBottom: SPACING.small,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    currentUser: currentUserState(state),
    biometricAuth: biometricAuthState(state),
    isFetching: !!isFetchingState(state),
    isSSOInProgress: getIsSSOInProgress(state),
    errorMessage: errorMessageState(state),
    showCaptcha: showCaptchaState(state),
    verifiedEmail: getVerifiedEmail(state),
  };
}

export default compose<any>(connect(mapStateToProps, basicMapDispatchToProps), withCodePush)(Login);
