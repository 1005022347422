import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  BottomSheetProps,
  SearchBar,
} from "@meraki/magnetic/components";
import { Clients, oneMonth, useClients } from "@meraki/shared/api";
import { filterData } from "@meraki/shared/filters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { ForwardedRef, forwardRef, useMemo, useState } from "react";

export interface SelectedClient {
  name: string;
  mac: string;
  ip: string | null;
}

interface SelectClientBottomSheetProps {
  onSelect: (props: SelectedClient) => void;
  snapPoints: BottomSheetProps["snapPoints"];
}

export const SelectClientBottomSheet = forwardRef(
  (
    { onSelect, snapPoints }: SelectClientBottomSheetProps,
    ref: ForwardedRef<BottomSheetMethods>,
  ) => {
    const networkId = useCurrentNetworkId();

    const [searchText, setSearchText] = useState<string>("");

    const {
      data: clients,
      isLoading: isLoadingClients,
      refetch: refetchClients,
      isRefetching: isRefetchingClients,
    } = useClients({ networkId, timespan: oneMonth });

    const dismiss = () => {
      if (typeof ref !== "function") {
        ref?.current?.dismiss();
      }
    };

    const searchedClients = useMemo(() => {
      return filterData(clients || [], ["description", "mac", "ip"], searchText) as Clients;
    }, [clients, searchText]);

    return (
      <BottomSheet.Modal snapPoints={snapPoints} index={0} ref={ref}>
        <BottomSheet.Header title={I18n.t("ROOT_TITLES.CLIENTS")} onCancelPress={dismiss} />
        <BottomSheet.Content>
          <SearchBar
            value={searchText}
            onChangeText={setSearchText}
            testID="SELECT_CLIENT.SEARCH"
          />
        </BottomSheet.Content>
        <BottomSheet.FlashList
          data={searchedClients}
          loading={isLoadingClients}
          onRefresh={refetchClients}
          refreshing={isRefetchingClients}
          getItemData={(client) => {
            return {
              title: client.description || client.mac,
              description: client.ip,
              onPress: () => {
                onSelect({
                  name: client.description || client.mac,
                  mac: client.mac,
                  ip: client.ip,
                });
                dismiss();
              },
            };
          }}
          emptyState={{ title: I18n.t("SORT_FILTER.EMPTY") }}
          paddingTop="none"
          testID="SELECT_CLIENT.LIST"
        />
      </BottomSheet.Modal>
    );
  },
);
