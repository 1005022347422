import { getLanguageOptions, I18n, Locale } from "@meraki/core/i18n";
import { useAdmins, useTimezones } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { memo, useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useUpdateGoAccount } from "~/api/queries/go/useUpdateAccount";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { ALERT_BUTTONS, showAlert, unsubscribeAdminFromAllAlerts } from "~/lib/AlertUtils";
import { BioAuthTypes, getBioAuthType } from "~/lib/BioAuth";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { platformSelect } from "~/lib/PlatformUtils";
import { noneIfFalsey } from "~/lib/stringHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import {
  alertSettingsState,
  biometricAuthState,
  currentOrganization,
  currentUserState,
  getCurrentLocale,
  getCurrentNetwork,
  getNetworkTimezone,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import { Alert, AlertSetting } from "~/shared/types/AlertTypes";
import { BiometricAuth } from "~/shared/types/Preferences";

import { SettingsStackProps } from "../navigation/Types";

interface InfoRowProps {
  label: string;
  value: string;
  testID: string;
}

const InfoRow = memo(function InfoRow(props: InfoRowProps) {
  const { label, value, testID } = props;

  return (
    <View style={styles.infoView} testID={testID}>
      <MkiText screenStyles={styles.infoRowLabel}>{label}</MkiText>
      <MkiText>{value}</MkiText>
    </View>
  );
});

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "Settings">;

const AccountScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const actions = useActions();

  const biometricAuth: BiometricAuth = useAppSelector(biometricAuthState);
  const organization = useAppSelector(currentOrganization);
  const network = useAppSelector(getCurrentNetwork);
  const organizationId = useCurrentOrganizationId();
  const email = useAppSelector(currentUserState);
  const currentTimeZone = useAppSelector(getNetworkTimezone);
  const currentLocale = useAppSelector(getCurrentLocale);
  const { data: adminData, isLoading: isAdminLoading } = useAdmins({
    organizationId: organization.id || "",
  });
  const currentUserAdmin = adminData?.find(
    (admin) => admin.email.toLowerCase() === email.toLowerCase(),
  );
  const { data: timezones, isLoading: isTimezonesLoading } = useTimezones();
  const alertSettings = useAppSelector(alertSettingsState);
  const hasAlphaFeatures = useHasAlphaFeatures();
  const updateGoAccount = useUpdateGoAccount();

  const [reqPending, setReqPending] = useState(false);
  const [bioType, setBioType] = useState<BioAuthTypes | undefined>(undefined);
  const [showTimeZonePicker, setShowTimeZonePicker] = useState(false);
  const [showLanguagePicker, setShowLanguagePicker] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangeOrgNameModal, setShowChangeOrgNameModal] = useState(false);
  const [showChangeNetworkNameModal, setShowChangeNetworkNameModal] = useState(false);
  const [stagedOrgName, setStagedOrgName] = useState(organization.name ?? "");
  const [stagedNetworkName, setStagedNetworkName] = useState(network?.name ?? "");
  const [stagedEmail, setStagedEmail] = useState<string>(email ?? "");
  const [InputModalReqPending, setInputModalReqPending] = useState(false);
  const [InputModalAlert, setInputModalAlert] = useState<Alert | undefined>();

  const loadData = useCallback(async () => {
    if (network != null && organizationId != null) {
      setReqPending(true);

      try {
        await actions.fetchOrgs();
        await actions.getNetwork(network.id);
        await actions.fetchAlertSettings();

        setBioType(await getBioAuthType());
      } catch (error) {
        showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
      }
      setReqPending(false);
    }
  }, [actions, network, organizationId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const logout = async () => {
    setReqPending(true);
    try {
      await actions.logoutUser();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  };

  const setOrgName = useCallback(async () => {
    if (organizationId != null) {
      setInputModalReqPending(true);
      try {
        await actions.changeOrganizationName(organizationId, stagedOrgName);
        setShowChangeOrgNameModal(false);
      } catch (error) {
        if (typeof error === "string") {
          setInputModalAlert({
            status: GoStatus.bad,
            message: error,
          });
        }
      } finally {
        setInputModalReqPending(false);
      }
    }
  }, [actions, organizationId, stagedOrgName]);

  const setNetworkName = useCallback(async () => {
    setInputModalReqPending(true);
    try {
      await actions.updateNetwork({ name: stagedNetworkName });
      setShowChangeNetworkNameModal(false);
    } catch (error) {
      if (typeof error === "string") {
        setInputModalAlert({
          status: GoStatus.bad,
          message: error,
        });
      }
    } finally {
      setInputModalReqPending(false);
    }
  }, [actions, stagedNetworkName]);

  const setEmail = useCallback(async () => {
    try {
      setReqPending(true);
      setShowChangeEmailModal(false);
      const newAlertSettings: AlertSetting[] = unsubscribeAdminFromAllAlerts(
        currentUserAdmin,
        alertSettings,
      );
      await actions.updateAlertSettings(newAlertSettings);
      await updateGoAccount(stagedEmail);
      await actions.logoutUser();
    } catch (error) {
      if (typeof error === "string") {
        setInputModalAlert({
          status: GoStatus.bad,
          message: error,
        });
      }
    } finally {
      setReqPending(true);
      setInputModalReqPending(false);
    }
  }, [actions, alertSettings, currentUserAdmin, stagedEmail, updateGoAccount]);

  const showEmailAlert = () =>
    showAlert(I18n.t("WARNING"), I18n.t("ACCOUNT.CHANGE_EMAIL_MODAL.WARNING"), setEmail, {
      positiveText: ALERT_BUTTONS.ok,
      negativeText: ALERT_BUTTONS.cancel,
    });

  const onValueSelectTimeZoneRow = async (value: string | number | null) => {
    try {
      setReqPending(true);
      setShowTimeZonePicker(false);
      await actions.updateTimeZone(value as string);
      setReqPending(false);
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  };

  const onLanguageSelect = (value: string | number | null) => {
    setShowLanguagePicker(false);

    try {
      setReqPending(true);
      actions.setCurrentLocale(value as Locale);
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  };

  const renderBioRow = () => {
    const bioTitle = platformSelect({
      ios: bioType === BioAuthTypes.faceId ? I18n.t("ACCOUNT.FACE_ID") : I18n.t("ACCOUNT.TOUCH_ID"),
      android: I18n.t("ACCOUNT.ANDROID"),
    });

    return (
      <SwitchRow
        value={biometricAuth.enabled}
        onValueChange={(value) => {
          actions.setBiometricAuth({ enabled: value });
        }}
        testID="YOUR_ACCOUNT.FACE_ID"
      >
        {bioTitle}
      </SwitchRow>
    );
  };

  const languageOptions = getLanguageOptions();
  const tzSubtitle = timezones?.options?.find((option) => option.value === currentTimeZone)?.label;
  const lgSubtitle = languageOptions.find((option) => option.value === currentLocale)?.label;

  return (
    <FullScreenContainerView>
      <View style={styles.header}>
        <MkiText textStyle="label" screenStyles={styles.heading}>
          {I18n.t("ACCOUNT.SECTIONS.INFO")}
        </MkiText>
      </View>
      <View style={styles.rowStyle}>
        <InfoRow
          label={capitalizeFirstLetter(I18n.t("ACCOUNT.ORGANIZATION"))}
          value={noneIfFalsey(organization?.name)}
          testID="YOUR_ACCOUNT.ORGANIZATION"
        />
        <MerakiIcon
          name="edit"
          size="s"
          color={MkiColors.secondaryButton}
          containerStyle={styles.pencilIcon}
          onPress={() => setShowChangeOrgNameModal(true)}
          testID="ORG.EDIT"
        />
      </View>
      <View style={styles.rowStyle}>
        <InfoRow
          label={capitalizeFirstLetter(I18n.t("ACCOUNT.SITE"))}
          value={noneIfFalsey(network?.name)}
          testID="YOUR_ACCOUNT.NETWORK"
        />
        <MerakiIcon
          name="edit"
          size="s"
          color={MkiColors.secondaryButton}
          containerStyle={styles.pencilIcon}
          onPress={() => setShowChangeNetworkNameModal(true)}
          testID="NETWORK.EDIT"
        />
      </View>
      <View style={styles.rowStyle}>
        <InfoRow
          label={capitalizeFirstLetter(I18n.t("ACCOUNT.EMAIL"))}
          value={email}
          testID="YOUR_ACCOUNT.EMAIL"
        />
        {hasAlphaFeatures && (
          <MerakiIcon
            name="edit"
            size="s"
            color={MkiColors.secondaryButton}
            containerStyle={styles.pencilIcon}
            onPress={() => setShowChangeEmailModal(true)}
            testID="EMAIL.EDIT"
          />
        )}
      </View>
      <View style={styles.header}>
        <MkiText textStyle="label" screenStyles={styles.heading}>
          {I18n.t("ACCOUNT.SECTIONS.SETTINGS")}
        </MkiText>
      </View>
      {bioType && Object.values(BioAuthTypes).includes(bioType) && renderBioRow()}
      <PickerModalRow
        selectedValue={currentTimeZone}
        label={I18n.t("ACCOUNT.TIME_ZONE")}
        subtitle={tzSubtitle}
        visible={showTimeZonePicker}
        title={I18n.t("ACCOUNT.SELECT_TIME_ZONE")}
        items={timezones?.options || []}
        screenStyles={styles.pickerStyle}
        onValueSelect={onValueSelectTimeZoneRow}
        onExit={() => setShowTimeZonePicker(false)}
        onPress={() => setShowTimeZonePicker(true)}
        testID="YOUR_ACCOUNT.TIME_ZONE"
        disableBottomBorder
      />
      <PickerModalRow
        selectedValue={currentLocale}
        label={I18n.t("ACCOUNT.LANGUAGE")}
        subtitle={lgSubtitle}
        visible={showLanguagePicker}
        title={I18n.t("ACCOUNT.SELECT_LANGUAGE")}
        items={languageOptions}
        screenStyles={styles.pickerStyle}
        onValueSelect={onLanguageSelect}
        onExit={() => setShowLanguagePicker(false)}
        onPress={() => setShowLanguagePicker(true)}
        testID="YOUR_ACCOUNT.LANGUAGE"
        disableBottomBorder
      />
      <View style={styles.header}>
        <MkiText textStyle="label" screenStyles={styles.heading}>
          {I18n.t("ACCOUNT.SECTIONS.ACTIONS")}
        </MkiText>
      </View>
      <SimpleDisclosureRow
        onPress={() => navigation.navigate("PasswordReset", {})}
        testID="YOUR_ACCOUNT.CHANGE_PASSWORD"
        hideDisclosureIcon
      >
        {I18n.t("ACCOUNT.CHANGE_PASSWORD")}
      </SimpleDisclosureRow>
      <SimpleDisclosureRow onPress={logout} testID="YOUR_ACCOUNT.LOG_OUT" hideDisclosureIcon>
        {capitalizeFirstLetter(I18n.t("LOG_OUT"))}
      </SimpleDisclosureRow>
      <InputModal
        value={stagedOrgName}
        title={I18n.t("ACCOUNT.CHANGE_ORG_NAME_MODAL.TITLE")}
        visible={showChangeOrgNameModal}
        onPrimaryPress={setOrgName}
        onChangeText={setStagedOrgName}
        onExit={() => setShowChangeOrgNameModal(false)}
        alert={InputModalAlert}
        loading={InputModalReqPending}
      />
      <InputModal
        value={stagedNetworkName}
        title={I18n.t("ACCOUNT.CHANGE_NETWORK_NAME_MODAL.TITLE")}
        visible={showChangeNetworkNameModal}
        onPrimaryPress={setNetworkName}
        onChangeText={setStagedNetworkName}
        onExit={() => setShowChangeNetworkNameModal(false)}
        alert={InputModalAlert}
        loading={InputModalReqPending}
      />
      <InputModal
        value={stagedEmail}
        title={I18n.t("ACCOUNT.CHANGE_EMAIL_MODAL.TITLE")}
        visible={showChangeEmailModal}
        onPrimaryPress={showEmailAlert}
        onChangeText={setStagedEmail}
        onExit={() => setShowChangeEmailModal(false)}
        alert={InputModalAlert}
        loading={InputModalReqPending}
      />
      <LoadingSpinner visible={reqPending || isAdminLoading || isTimezonesLoading} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
    marginHorizontal: SPACING.default,
  },
  heading: {
    paddingVertical: SPACING.small,
  },
  infoView: {
    paddingHorizontal: SPACING.default,
    paddingVertical: SPACING.small,
  },
  infoRowLabel: {
    color: MkiColors.secondaryTextColor,
    fontSize: normalizedFontSize(13),
  },
  rowStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  pencilIcon: {
    margin: SPACING.small,
    marginHorizontal: SPACING.default,
  },
  pickerStyle: {
    paddingHorizontal: SPACING.default,
  },
});

export default AccountScreen;
