import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { List } from "@meraki/magnetic/components";
import {
  Device,
  DeviceStatus,
  FirmwareDetails,
  useApplianceSettings,
  useFirmwareUpgrades,
  useRadioStatus,
} from "@meraki/shared/api";
import { getMinimalFirmwareMessage } from "@meraki/shared/formatters";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

interface DeviceDetailsListProps {
  device: Device;
  deviceStatus: DeviceStatus;
}

export const DeviceDetailsList = ({ device, deviceStatus }: DeviceDetailsListProps) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<DeviceGroupProps, "DeviceDetailsList">>();
  const { data: applianceSettings } = useApplianceSettings(
    { networkId: device.networkId },
    { enabled: device.productType === "appliance" },
  );
  const { data: radioStatus } = useRadioStatus(
    { serial: device.serial ?? "" },
    { enabled: Boolean(device.serial) && device.productType === "wireless" },
  );
  const { data: firmwareInfo, isLoading: firmwareInfoIsLoading } = useFirmwareUpgrades({
    networkId: device.networkId,
  });

  const detailRows = [
    <List.Item title={I18n.t("NODE_INFO.MODEL")} value={device.model} key="MODEL" testID="MODEL" />,
    <List.Item
      title={I18n.t("NODE_INFO.SERIAL")}
      value={device.serial}
      key="SERIAL"
      testID="SERIAL"
    />,
    <List.Item
      title={I18n.t("NODE_INFO.MAC_ADDRESS")}
      value={device.mac}
      key="MAC_ADDRESS"
      testID="MAC_ADDRESS"
    />,
  ];

  const simpleProductType =
    device.productType === "appliance" ||
    device.productType === "switch" ||
    device.productType === "wireless"
      ? device.productType
      : undefined;

  let currentFirmware: FirmwareDetails = {};
  let latestFirmware: FirmwareDetails[] = [];

  if (simpleProductType) {
    const availableFirmware =
      firmwareInfo?.products && firmwareInfo?.products[simpleProductType]?.availableVersions;
    currentFirmware =
      (firmwareInfo?.products && firmwareInfo?.products[simpleProductType]?.currentVersion) ?? {};

    latestFirmware =
      availableFirmware?.filter((firmware) => {
        return (firmware.id ?? 0) > (currentFirmware?.id ?? 0);
      }) ?? [];
  }

  // WAN IP serves as LAN IP on appliances due to numerous potential interfaces
  if (deviceStatus?.lanIp) {
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.LAN_IP")}
        value={deviceStatus?.lanIp}
        key="LAN_IP"
        testID="LAN_IP"
      />,
    );
  } else if (deviceStatus?.wan1Ip) {
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.WAN_IP")}
        value={deviceStatus?.wan1Ip}
        key="WAN_IP"
        testID="WAN_IP"
      />,
    );
  }
  if (deviceStatus?.publicIp) {
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.PUBLIC_IP")}
        value={deviceStatus?.publicIp}
        key="PUBLIC_IP"
        testID="PUBLIC_IP"
      />,
    );
  }
  if (radioStatus && radioStatus.length > 0 && device.productType === "wireless") {
    // wireless only
    const channels = radioStatus.map((band) => band.channelSettings.baseChannel);
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.CHANNELS")}
        value={channels.join(", ")}
        key="CHANNELS"
        testID="CHANNELS"
      />,
    );
  }

  if (device.notes) {
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.NOTES")}
        value={device.notes}
        key="NOTES"
        testID="NOTES"
      />,
    );
  }

  if (device.firmware) {
    if (device.firmware === currentFirmware?.firmware) {
      detailRows.push(
        <List.Item
          title={I18n.t("FIRMWARE_UPDATES.CURRENT")}
          value={getMinimalFirmwareMessage(device.firmware, device.model)}
          key="FIRMWARE_CURRENT"
          testID="FIRMWARE_CURRENT"
        />,
      );
      if (
        latestFirmware.length > 0 &&
        latestFirmware[0] &&
        device.firmware !== latestFirmware[0].firmware
      ) {
        detailRows.push(
          <List.Item
            title={I18n.t("FIRMWARE_UPDATES.LATEST")}
            value={getMinimalFirmwareMessage(latestFirmware[0].firmware, device.model)}
            bottomDividerBorder={false}
            key="FIRMWARE_LATEST"
            testID="FIRMWARE_LATEST"
          />,
          <List.Item
            title={I18n.t("FIRMWARE_UPDATES.SCHEDULE")}
            onPress={() => navigation.navigate("FirmwareUpgrade", { networkId: device.networkId })}
            key="FIRMWARE_SCHEDULE_UPDATES"
            testID="FIRMWARE_SCHEDULE_UPDATES"
          />,
        );
      } else {
        detailRows.push(
          <List.Item
            title={I18n.t("FIRMWARE_UPDATES.LATEST")}
            value={getMinimalFirmwareMessage(currentFirmware.firmware, device.model)}
            key="FIRMWARE_LATEST"
            testID="FIRMWARE_LATEST"
          />,
        );
      }
    } else {
      detailRows.push(
        <List.Item
          title={I18n.t("FIRMWARE.TITLE")}
          value={getMinimalFirmwareMessage(currentFirmware.firmware, device.model)}
          bottomDividerBorder={false}
          key="FIRMWARE"
          testID="FIRMWARE"
        />,
        <List.Item
          title={I18n.t("RESOURCES_RESULTS.OPEN_CASE_BUTTON")}
          // TODO: Uncomment once SearchSubject screen is created
          // onPress={() => navigation.navigate("SearchSubject")}
          key="FIRMWARE_OPEN_CASE"
          testID="FIRMWARE_OPEN_CASE"
        />,
      );
    }
  }

  if (device.productType === "appliance" && applianceSettings?.dynamicDns) {
    const { dynamicDns } = applianceSettings;
    const { url } = dynamicDns;
    detailRows.push(
      <List.Item
        title={I18n.t("NODE_INFO.HOSTNAME")}
        value={url}
        key="HOSTNAME"
        testID="HOSTNAME"
      />,
    );
  }

  return (
    <List loading={firmwareInfoIsLoading} testID="DEVICE_DETAIL_LIST">
      {detailRows}
    </List>
  );
};
