import { createSelector } from "reselect";

import { currentNetworkState, getSensorRoles } from "~/selectors/getters";
import { RootState } from "~/shared/types/Redux";

// TODO: Add tests for this selector
export const getCameraSerialRelatedToSensor = createSelector(
  getSensorRoles,
  currentNetworkState,
  (_: RootState, props: { sensorSerial: string }) => props.sensorSerial,
  (roles, networkId = "", sensorSerial) => {
    const networkRoles = roles[networkId];
    if (!networkRoles) {
      return undefined;
    }
    return roles[networkId][sensorSerial]?.relationships?.livestream?.relatedDevices[0]?.serial;
  },
);
