import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const AttachFileToSupportCaseInputSchema = z
  .object({
    file: z.string(),
    filename: z.string(),
    case_number: z.string(),
  })
  .describe("AttachFileToSupportCaseInputSchema");

export const AttachFileToSupportCaseResponseSchema = z
  .object({
    success: z.boolean(),
    errors: z.array(z.string()).optional(),
  })
  .describe("AttachFileToSupportCaseResponseSchema");

export type SupportCaseFileAttachment = z.infer<typeof AttachFileToSupportCaseInputSchema>;
export type AttachFileToSupportCaseResponse = z.infer<typeof AttachFileToSupportCaseResponseSchema>;

const buildUrl = ({ encryptedNetworkId }: AttachFileToSupportCaseRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/upload_case_attachment`;
};

interface AttachFileToSupportCaseRequest {
  encryptedNetworkId?: string;
}

interface MutateAttachFileToSupportCaseRequest extends AttachFileToSupportCaseRequest {
  body: SupportCaseFileAttachment;
}

const attachFileToSupportCase = ({
  encryptedNetworkId,
  body,
}: MutateAttachFileToSupportCaseRequest) => {
  return request(AttachFileToSupportCaseResponseSchema, "POST", buildUrl({ encryptedNetworkId }), {
    body: JSON.stringify({ ...body, from_new_page: true }),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useAttachFileToSupportCase = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: MutateAttachFileToSupportCaseRequest) =>
    attachFileToSupportCase({ ...parameters }),
});
