import { Button, ButtonProps } from "@meraki/magnetic/components";

import { getIsReadOnlyUser } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

type Props = Pick<ButtonProps, "onPress" | "text" | "disabled">;

export default function PermissionsAwareNavButton({ onPress, text, disabled }: Props) {
  const isReadOnlyUser = useAppSelector(getIsReadOnlyUser);

  return (
    <Button.Nav disabled={(isReadOnlyUser ?? true) || !!disabled} text={text} onPress={onPress} />
  );
}
