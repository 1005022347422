import { getProductType } from "@meraki/shared/devices";
import { subSeconds } from "date-fns";

import { getDeviceStatus } from "~/lib/DeviceUtils";
import { getTimeAgo } from "~/lib/formatHelper";
import { switchportIsAlerting, switchportIsConnected } from "~/lib/SwitchPortStatusUtils";
import { DeviceStatus } from "~/shared/constants/Status";
import { Client, ClientList, ExtendedClient } from "~/shared/types/Client";
import { PredefiendDevicePolicyID } from "~/shared/types/ClientPolicy";

import Device from "../types/Device";
import { ProductType } from "../types/Networks";

/**
 * Fix the timespan filter here and also make sure the DEVICE_CLIENTS filter works as expected
 */
export const CUSTOM_FILTERS = {
  CLIENTS: {
    byIds: (ids: any) => (clients: any) => clients.filter((client: any) => ids.includes(client.id)),
  },
  DEFAULT: (x: any) => x,
  HIGH_USAGE_CLIENT: (clients: ExtendedClient[]) => clients.filter((client) => client.highUsage),
  HIGH_SECURITY_EVENTS: (clients: any) =>
    clients.filter((client: any) => client.highSecurityEvents),
  TIMESPAN_CLIENTS: (timespan: any) => (clients: ClientList) =>
    getFilterClientsLastSeenWithinTimespan(clients, timespan),
  TIMESPAN_CLIENTS_HOME: (timespan: any) => (clients: ExtendedClient[]) =>
    getLastSeenFilteredClientsForHomepage(clients, timespan),
  SSID_CLIENTS: (ssidNumber: any) => (clients: ExtendedClient[]) =>
    clients.filter((client) => client.connectedBy === ssidNumber),
  DEVICE_CLIENTS: (nodeId: any) => (clients: ExtendedClient[]) =>
    clients.filter((client) => client.nodeId === nodeId),
  PRODUCT_TYPE_DEVICES: (devices: Device[], productType: ProductType) =>
    devices.filter((device) => getProductType(device?.model) === productType),
  ONLINE_DEVICE: (devices: any) =>
    devices.filter((device: any) => getDeviceStatus(device) === DeviceStatus.online),
  ALERTING_DEVICE: (devices: any) =>
    devices.filter((device: any) => getDeviceStatus(device) === DeviceStatus.alerting),
  BLOCKED_CLIENT: (clients: (ExtendedClient | Client)[]) =>
    clients.filter(
      ({ wiredGroupNum, wirelessGroupNums }) =>
        wiredGroupNum === PredefiendDevicePolicyID.blocked ||
        wirelessGroupNums?.split(",")?.includes(PredefiendDevicePolicyID.blocked),
    ),
  ACTIVE_PORT: (ports: any) => ports.filter((port: any) => switchportIsConnected(port)),
  ACTIVE_GX_PORT: (ports: any) => ports.filter((port: any) => port.carrier),
  ALERTING_PORT: (ports: any) => ports.filter((port: any) => switchportIsAlerting(port)),
  ALL_PORTS: (ports: any) => ports,
  OFFLINE_DEVICE: (devices: any) =>
    devices.filter((device: any) => getDeviceStatus(device) === DeviceStatus.offline),
  UNCONFIGURED_DEVICE: (devices: any) =>
    devices.filter((device: any) => getDeviceStatus(device) !== DeviceStatus.online),
  SM_MISSING_APPS_CLIENT: (clients: any) =>
    clients.filter((client: any) => client.missing_apps && client.missing_apps > 0),
  SM_VIOLATING_POLICY_CLIENT: (clients: any) =>
    clients.filter(
      (client: any) =>
        client.is_compliant && Object.values(client.is_compliant).some((compliant) => !compliant),
    ),
  SM_RECENTLY_ADDED_CLIENT: (clients: any) =>
    clients.filter(
      (client: any) => client.created_at && client.created_at >= getTimeAgo(5, "days"),
    ),
};

export function getFilterClientsLastSeenWithinTimespan(clients: ClientList, timespan: number) {
  return Object.values(clients).filter((value: Client) => {
    return new Date(value.lastSeen) >= subSeconds(Date.now(), timespan);
  });
}

export function getLastSeenFilteredClientsForHomepage(clients: ExtendedClient[], timespan: number) {
  return clients.filter((value: Client) => {
    return new Date(value.lastSeen) >= subSeconds(Date.now(), timespan);
  });
}
