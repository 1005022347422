import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { createRef, PureComponent } from "react";
import { StyleSheet, TextInput, TouchableOpacity } from "react-native";
import * as RNLocalize from "react-native-localize";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  COMPANY_SIZE_RANGES,
  COUNTRIES,
  INDUSTRIES,
  KEYBOARD_TYPE,
  RETURN_KEY,
  SPACING,
} from "~/constants/MkiConstants";
import AgreementInfoModal from "~/go/components/AgreementInfoModal";
import CheckBox from "~/go/components/CheckBox";
import ConfirmAlert from "~/go/components/ConfirmAlert";
import InfoModal from "~/go/components/InfoModal";
import { PickerModalItems } from "~/go/components/PickerModal";
import RoundedButton from "~/go/components/RoundedButton";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import PickerModalRow from "~/go/rows/PickerModalRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { FIREBASE_EVENTS, sendMilestoneEvent } from "~/lib/FirebaseUtils";
import { ActivationError, parseActivationError } from "~/lib/RequestUtils";
import { stringIsEmail } from "~/lib/stringHelper";
import { themeColors } from "~/lib/themeHelper";
import { getRegionTimezonesSorted } from "~/lib/TimezoneUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { ExitButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { LoginStackProps } from "../navigation/Types";
import { RegistrationForm } from "../types/RegistrationTypes";

interface CreateAccountResponse {
  marketo_api_token: string;
  shard_id: number;
  success: boolean;
}

interface NewAccountForm {
  company: string;
  company_size: string;
  email: string;
  first_name: string;
  last_name: string;
  industry: string;
  password: string;
  password_confirmation: string;
  country: string;
  time_zone: string;
  tips: boolean;
  terms: boolean;
}

type Props = ForwardedNativeStackScreenProps<LoginStackProps, "CreateAccount"> &
  BasicActions &
  PendingComponent;

interface CreateAccountScreenState {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  company: string;
  companySize: string;
  selectCompanySize: boolean;
  industry: string;
  selectIndustry: boolean;
  country: string;
  sendTips: boolean;
  timezone: string;
  selectCountry: boolean;
  selectTimezone: boolean;
  showCreatedAccountModal: boolean;
  showTerms: boolean;
  acceptTerms: boolean;
  tzOptions: PickerModalItems[];
  formErrors: CreateAccountScreenFormErrors;
}

class CreateAccountScreenFormErrors {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  company: boolean;
  password: boolean;
  companySize: boolean;
  industry: boolean;
  confirmPassword: boolean;

  constructor() {
    this.firstName = false;
    this.lastName = false;
    this.email = false;
    this.company = false;
    this.password = false;
    this.confirmPassword = false;
    this.companySize = false;
    this.industry = false;
  }
}

const getCountryRows = () => {
  return Object.keys(COUNTRIES).map((countryCode) => {
    const { name } = COUNTRIES[countryCode];
    return {
      label: I18n.t(name),
      value: countryCode,
    };
  });
};

const initialCompanySize = COMPANY_SIZE_RANGES[0].value;
const companySizeValueToLabel = COMPANY_SIZE_RANGES.reduce((obj: Record<string, string>, range) => {
  const { label, value } = range;
  obj[value] = I18n.t(label);
  return obj;
}, {});
const companySizeRows = COMPANY_SIZE_RANGES.map((code) => {
  const { label, value } = code;
  return {
    label: I18n.t(label),
    value,
  };
});

const initialIndustry = INDUSTRIES[0].value;
const IndustryValueToLabel = INDUSTRIES.reduce((obj: Record<string, string>, industry) => {
  const { label, value } = industry;
  obj[value] = I18n.t(label);
  return obj;
}, {});
const industryRows = INDUSTRIES.map((code) => {
  const { label, value } = code;
  return {
    label: I18n.t(label),
    value,
  };
});

export class CreateAccountScreen extends PureComponent<Props, CreateAccountScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      company: "",
      companySize: COMPANY_SIZE_RANGES[0].value,
      industry: INDUSTRIES[0].value,
      country: "US",
      sendTips: false,
      timezone: RNLocalize.getTimeZone(),
      tzOptions: [],

      selectCompanySize: false,
      selectIndustry: false,
      selectCountry: false,
      selectTimezone: false,
      showCreatedAccountModal: false,
      showTerms: false,
      acceptTerms: false,

      formErrors: new CreateAccountScreenFormErrors(),
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <ExitButton onPress={navigation.goBack} />,
    });
  }

  componentDidMount() {
    this.getTimezoneData();
  }

  toggleCompanySizePicker = () => {
    this.setState((prevState) => {
      return { selectCompanySize: !prevState.selectCompanySize };
    });
  };

  toggleIndustryPicker = () => {
    this.setState((prevState) => {
      return { selectIndustry: !prevState.selectIndustry };
    });
  };

  toggleCountryPicker = () => {
    this.setState((prevState) => {
      return { selectCountry: !prevState.selectCountry };
    });
  };

  toggleTimezonePicker = () => {
    this.setState((prevState) => {
      return { selectTimezone: !prevState.selectTimezone };
    });
  };

  toggleSendTips = () => {
    this.setState((prevState) => {
      return { sendTips: !prevState.sendTips };
    });
  };

  showTermsOfConditions = () => {
    this.setState({ showTerms: true });
  };

  getIndustryString() {
    const { industry } = this.state;
    return IndustryValueToLabel[industry];
  }

  getCompanySizeString() {
    const { companySize } = this.state;
    return companySizeValueToLabel[companySize];
  }

  getTimezoneString() {
    const { tzOptions, timezone } = this.state;

    const pickedTimezone: undefined | PickerModalItems = tzOptions.find((tz: PickerModalItems) => {
      return tz.value === timezone;
    });

    return pickedTimezone === undefined ? "" : pickedTimezone.label;
  }

  getCountryString() {
    const { country } = this.state;
    return I18n.t(COUNTRIES[country].name);
  }

  getTimezoneData() {
    const { actions, setReqPending, handleError } = this.props;

    setReqPending(true);
    return actions
      .getTimezones()
      .then((tz) => {
        this.setState({ tzOptions: tz.response.options });
        setReqPending(false);
      })
      .catch(handleError);
  }

  renderButtonText() {
    const { acceptTerms } = this.state;
    return acceptTerms
      ? I18n.t("CREATE_ACCOUNT.CREATE_ACCOUNT_BUTTON")
      : I18n.t("CREATE_ACCOUNT.VIEW_TERMS_BUTTON");
  }

  returnToLogin = () => {
    const { email } = this.state;
    const { navigation, onCreatedAccount } = this.props;
    onCreatedAccount(email);
    this.setState({ showCreatedAccountModal: false }, navigation.goBack);
  };

  renderCreatedAccountModal = () => {
    const { showCreatedAccountModal } = this.state;

    return (
      <InfoModal visible={showCreatedAccountModal} onExit={this.returnToLogin}>
        <ConfirmAlert
          title={I18n.t("VERIFY_EMAIL.TITLE")}
          subtitle={I18n.t("VERIFY_EMAIL.MESSAGE")}
          primaryButtonText={I18n.t("OK")}
          onPrimaryPress={this.returnToLogin}
        />
      </InfoModal>
    );
  };

  checkFormErrors = () => {
    const { email, password, confirmPassword } = this.state;

    let errorMessage = "";
    const formValidationErrors = new CreateAccountScreenFormErrors();

    Object.keys(formValidationErrors).forEach((key) => {
      const state = this.state;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!state[key]) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        formValidationErrors[key] = true;
      }
    });

    const hasMissingData = Object.values(formValidationErrors).includes(true);
    if (hasMissingData) {
      errorMessage += `${I18n.t("CREATE_ACCOUNT.ERRORS.ENTER_ALL_INFO")}\n`;
    }

    if (email && !stringIsEmail(email)) {
      formValidationErrors.email = true;
      errorMessage += `${I18n.t("CREATE_ACCOUNT.ERRORS.INVALID_EMAIL")}\n`;
    }
    if (password !== confirmPassword) {
      formValidationErrors.password = true;
      formValidationErrors.confirmPassword = true;
      errorMessage += `${I18n.t("CREATE_ACCOUNT.ERRORS.PASSWORDS_DO_NOT_MATCH")}\n`;
    }

    this.setState({ formErrors: formValidationErrors });

    const hasErrors = Object.values(formValidationErrors).includes(true);
    if (hasErrors && errorMessage) {
      showAlert(I18n.t("ERROR"), errorMessage);
    }
    return hasErrors;
  };

  sendData = () => {
    if (this.checkFormErrors()) {
      return;
    }
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      company,
      companySize,
      industry,
      country,
      timezone,
      sendTips,
      acceptTerms,
    } = this.state;

    const form: NewAccountForm = {
      first_name: firstName,
      last_name: lastName,
      email: email.toLowerCase(),
      password: password,
      password_confirmation: confirmPassword,
      company: company,
      country: country,
      time_zone: timezone,
      industry: industry,
      company_size: companySize,
      tips: sendTips,
      terms: acceptTerms,
    };

    const { setReqPending, actions } = this.props;
    setReqPending(true);
    actions
      .register(form)
      .then(({ response }) => this.handleCreatedAccount(response, form))
      .catch(this.handleRegistrationFlowError);
  };

  handleMarketo = (apiToken: string, form: RegistrationForm) => {
    const { actions } = this.props;
    if (!!apiToken) {
      actions.sendToMarketo(form, apiToken);
    }
  };

  handleCreatedAccount = (response: CreateAccountResponse, form: NewAccountForm) => {
    const { marketo_api_token } = response;
    const { setReqPending } = this.props;
    this.handleMarketo(marketo_api_token, form);
    sendMilestoneEvent(FIREBASE_EVENTS.createdAccount);
    setReqPending(false);
    this.setState({ showCreatedAccountModal: true });
  };

  handleRegistrationFlowError = (error: ActivationError) => {
    const { handleError } = this.props;
    const errorMessage = parseActivationError(error);
    handleError(errorMessage);
  };

  focusField = (ref: React.RefObject<TextInput>) => () => {
    ref?.current?.focus();
  };

  onChangeTextInput =
    (
      stateField: keyof CreateAccountScreenState,
      formErrorKey: keyof CreateAccountScreenFormErrors,
    ) =>
    (text: string) => {
      this.setState((prevState: CreateAccountScreenState) => {
        const newState: Pick<CreateAccountScreenState, "formErrors"> = {
          [stateField]: text,
          formErrors: { ...prevState.formErrors, [formErrorKey]: false },
        };

        return newState;
      });
    };

  firstNameInput: React.RefObject<TextInput> = createRef();
  lastNameInput: React.RefObject<TextInput> = createRef();
  emailInput: React.RefObject<TextInput> = createRef();
  companyInput: React.RefObject<TextInput> = createRef();
  passwordInput: React.RefObject<TextInput> = createRef();
  confirmPasswordInput: React.RefObject<TextInput> = createRef();

  render() {
    const {
      country,
      selectCountry,
      timezone,
      selectTimezone,
      sendTips,
      firstName,
      lastName,
      email,
      company,
      companySize,
      selectCompanySize,
      industry,
      selectIndustry,
      tzOptions,
      showTerms,
      acceptTerms,
      formErrors,
    } = this.state;

    const { theme } = useTheme.getState();
    const textStyle = themeColors(theme).text.heading;

    return (
      <FullScreenContainerView withScroll defaultPadding testID={"REGISTER.SCROLL"}>
        <UnderlinedTextInput
          autoFocus
          title={I18n.t("CREATE_ACCOUNT.FIRST_NAME")}
          placeholder={I18n.t("CREATE_ACCOUNT.PLACEHOLDERS.FIRST_NAME")}
          value={firstName}
          onChangeText={this.onChangeTextInput("firstName", "firstName")}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.firstNameInput}
          style={textStyle}
          onSubmitEditing={this.focusField(this.lastNameInput)}
          isError={formErrors.firstName}
          returnKeyType={RETURN_KEY.next}
          testID={"REGISTER.FIRST_NAME"}
        />
        <UnderlinedTextInput
          title={I18n.t("CREATE_ACCOUNT.LAST_NAME")}
          placeholder={I18n.t("CREATE_ACCOUNT.PLACEHOLDERS.LAST_NAME")}
          value={lastName}
          onChangeText={this.onChangeTextInput("lastName", "lastName")}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.lastNameInput}
          style={textStyle}
          isError={formErrors.lastName}
          onSubmitEditing={this.focusField(this.emailInput)}
          returnKeyType={RETURN_KEY.next}
          testID={"REGISTER.LAST_NAME"}
        />
        <UnderlinedTextInput
          title={I18n.t("CREATE_ACCOUNT.EMAIL")}
          placeholder={I18n.t("CREATE_ACCOUNT.PLACEHOLDERS.EMAIL")}
          value={email}
          keyboardType={KEYBOARD_TYPE.emailAddress}
          onChangeText={this.onChangeTextInput("email", "email")}
          autoCapitalize={"none"}
          autoCorrect={false}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.emailInput}
          style={textStyle}
          isError={formErrors.email}
          onSubmitEditing={this.focusField(this.companyInput)}
          returnKeyType={RETURN_KEY.next}
          testID={"REGISTER.EMAIL"}
        />
        <UnderlinedTextInput
          title={I18n.t("CREATE_ACCOUNT.COMPANY")}
          placeholder={I18n.t("CREATE_ACCOUNT.PLACEHOLDERS.COMPANY")}
          value={company}
          onChangeText={this.onChangeTextInput("company", "company")}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.companyInput}
          style={textStyle}
          isError={formErrors.company}
          onSubmitEditing={this.focusField(this.passwordInput)}
          returnKeyType={RETURN_KEY.next}
          testID={"REGISTER.COMPANY"}
        />
        <UnderlinedTextInput
          title={I18n.t("CREATE_ACCOUNT.PASSWORD")}
          secureTextEntry={true}
          onChangeText={this.onChangeTextInput("password", "password")}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.passwordInput}
          style={textStyle}
          isError={formErrors.password}
          onSubmitEditing={this.focusField(this.confirmPasswordInput)}
          returnKeyType={RETURN_KEY.next}
          testID={"REGISTER.PASSWORD"}
        />
        <UnderlinedTextInput
          title={I18n.t("CREATE_ACCOUNT.CONFIRM_PASSWORD")}
          secureTextEntry={true}
          onChangeText={this.onChangeTextInput("confirmPassword", "confirmPassword")}
          showClearButton
          // @ts-expect-error Type 'RefObject<TextInput>' is not assignable to type 'Ref<UnderlinedTextInput> | undefined'.
          ref={this.confirmPasswordInput}
          style={textStyle}
          isError={formErrors.confirmPassword}
          returnKeyType={RETURN_KEY.done}
          testID={"REGISTER.CONFIRM_PASSWORD"}
        />

        <MkiText textStyle="smallSecondary">
          {I18n.t("CREATE_ACCOUNT.PASSWORD_REQUIREMENTS")}
        </MkiText>
        <PickerModalRow
          selectedValue={industry}
          label={I18n.t("CREATE_ACCOUNT.SELECT_INDUSTRY")}
          subtitle={this.getIndustryString()}
          visible={selectIndustry}
          title={I18n.t("CREATE_ACCOUNT.SELECT_INDUSTRY")}
          screenStyles={styles.pickerStyle}
          items={industryRows}
          onValueSelect={(value) => {
            this.setState({ industry: (value as string) || initialIndustry });
            this.toggleIndustryPicker();
          }}
          onExit={this.toggleIndustryPicker}
          onPress={this.toggleIndustryPicker}
          isError={formErrors.industry}
          testID={"REGISTER.INDUSTRY"}
        />
        <PickerModalRow
          selectedValue={companySize}
          label={I18n.t("CREATE_ACCOUNT.SELECT_COMPANY_SIZE")}
          subtitle={this.getCompanySizeString()}
          visible={selectCompanySize}
          title={I18n.t("CREATE_ACCOUNT.SELECT_COMPANY_SIZE")}
          screenStyles={styles.pickerStyle}
          items={companySizeRows}
          onValueSelect={(value) => {
            this.setState({ companySize: value === "" ? initialCompanySize : (value as string) });
            this.toggleCompanySizePicker();
          }}
          onExit={this.toggleCompanySizePicker}
          onPress={this.toggleCompanySizePicker}
          isError={formErrors.companySize}
          testID={"REGISTER.COMPANY_SIZE"}
        />
        <PickerModalRow
          selectedValue={country}
          label={I18n.t("CREATE_ACCOUNT.SELECT_COUNTRY")}
          subtitle={this.getCountryString()}
          visible={selectCountry}
          title={I18n.t("CREATE_ACCOUNT.SELECT_COUNTRY")}
          screenStyles={styles.pickerStyle}
          items={getCountryRows()}
          onValueSelect={(value) => {
            this.setState({ country: value as string });
            this.toggleCountryPicker();
          }}
          onExit={this.toggleCountryPicker}
          onPress={this.toggleCountryPicker}
          testID={"REGISTER.COUNTRY"}
        />
        <PickerModalRow
          selectedValue={timezone}
          label={I18n.t("CREATE_ACCOUNT.SELECT_TIMEZONE")}
          subtitle={this.getTimezoneString()}
          visible={selectTimezone}
          title={I18n.t("CREATE_ACCOUNT.SELECT_TIMEZONE")}
          items={getRegionTimezonesSorted(tzOptions, COUNTRIES[country].region)}
          screenStyles={styles.pickerStyle}
          onValueSelect={(value) => {
            this.setState({ timezone: value as string });
            this.toggleTimezonePicker();
          }}
          onExit={this.toggleTimezonePicker}
          onPress={this.toggleTimezonePicker}
          testID={"REGISTER.TIMEZONE"}
        />
        <TouchableOpacity onPress={this.toggleSendTips}>
          <ListRow
            icon={<CheckBox selected={sendTips} screenStyles={styles.checkBox} />}
            leftStyle={styles.checkBoxLeft}
            rowStyles={styles.rowStyle}
          >
            <MkiText textStyle="small" screenStyles={textStyle}>
              {I18n.t("CREATE_ACCOUNT.SEND_TIPS")}
            </MkiText>
          </ListRow>
        </TouchableOpacity>
        {this.renderCreatedAccountModal()}
        <AgreementInfoModal
          visible={showTerms}
          onExit={() => this.setState({ showTerms: false })}
          onPress={() => this.setState({ acceptTerms: true, showTerms: false })}
          title={I18n.t("TERMS_AND_CONDITIONS.TITLE")}
          buttonText={I18n.t("TERMS_AND_CONDITIONS.ACCEPT_TERMS")}
        />
        <RoundedButton
          onPress={acceptTerms ? this.sendData : this.showTermsOfConditions}
          testID="SIGNUP_BUTTON"
          screenStyles={styles.button}
        >
          {this.renderButtonText()}
        </RoundedButton>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  pickerStyle: {
    paddingLeft: 0,
    paddingRight: SPACING.tiny,
  },
  rowStyle: {},
  button: {
    flex: 1,
    marginVertical: SPACING.default,
    marginHorizontal: SPACING.extraLarge,
  },
  checkBoxLeft: {
    flex: 1,
    alignItems: "center",
  },
  checkBox: {
    marginRight: SPACING.small,
    padding: SPACING.small,
  },
});

export default compose<any>(
  connect(null, basicMapDispatchToProps),
  withPendingComponent,
)(CreateAccountScreen);
