import {
  BATCH_UPDATE_SWITCH_PORT_FAILURE,
  BATCH_UPDATE_SWITCH_PORT_REQUEST,
  BATCH_UPDATE_SWITCH_PORT_SUCCESS,
  SWITCH_PORTS_FAILURE,
  SWITCH_PORTS_REQUEST,
  SWITCH_PORTS_SUCCESS,
  UPDATE_SWITCH_PORT_FAILURE,
  UPDATE_SWITCH_PORT_REQUEST,
  UPDATE_SWITCH_PORT_SUCCESS,
} from "@meraki/shared/redux";

import { batch } from "~/actions/actionBatches";
import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { convertToPublicAPIPort } from "~/lib/SwitchPortUtils";
import { ApiAction, ApiResponseAction, CALL_API } from "~/middleware/api";
import { Schemas } from "~/shared/lib/Schemas";
import Device from "~/shared/types/Device";
import { PublicSwitchPort, SwitchPort } from "~/shared/types/Models";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function getSwitchPorts(device: Device): ApiAction {
  const callback = (json: any) => ({
    ports: json,
    id: device.serial,
  });
  return {
    [CALL_API]: {
      types: [SWITCH_PORTS_REQUEST, SWITCH_PORTS_SUCCESS, SWITCH_PORTS_FAILURE],
      endpoint: `/api/v1/devices/${device.serial}/switchPorts`,
      config: { method: Method.get },
      schema: Schemas.SWITCH_PORTS,
      callback,
    },
  };
}

export function updateSwitchPorts(
  serialNumber: string,
  portIds: string | string[],
  portData: Partial<SwitchPort>,
): AppThunk<Promise<any> | void> {
  //@ts-ignore UDG-3805: Add / Guard additional case for updateSwitchPorts in SwitchPortsDetailsScreen
  return (dispatch) => {
    const body = convertToPublicAPIPort(portData);

    if (Array.isArray(portIds) && portIds.length > 1) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<PublicSwitchPort>' is no... Remove this comment to see the full error message
      return dispatch(updateMultipleSwitchPorts(serialNumber, portIds, body));
    }

    const portId = Array.isArray(portIds) ? portIds[0] : portIds;

    if (portId) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<PublicSwitchPort>' is no... Remove this comment to see the full error message
      return dispatch(updateSingleSwitchPort(serialNumber, portId, body));
    }
  };
}

function updateSingleSwitchPort(
  serialNumber: string,
  portId: string,
  body: PublicSwitchPort,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_SWITCH_PORT_REQUEST, UPDATE_SWITCH_PORT_SUCCESS, UPDATE_SWITCH_PORT_FAILURE],
        endpoint: `/api/v1/devices/${serialNumber}/switch/ports/${portId}`,
        config: {
          method: Method.put,
          body: JSON.stringify(body),
        },
        meta: { serialNumber, portId },
      }),
    );
  };
}

const PRIVATE_PORT_ID_SPLIT_STRING = "_";

function updateMultipleSwitchPorts(
  serialNumber: string,
  portIds: string[],
  body: PublicSwitchPort,
): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return (dispatch) => {
    const actions = portIds
      .map((id) => id?.split?.(PRIVATE_PORT_ID_SPLIT_STRING)?.[1])
      .filter((id) => !!id)
      .map((id) => ({
        resource: `/devices/${serialNumber}/switch/ports/${id}`,
        operation: "update",
        body,
      }));

    if (actions.length === 0) {
      return Promise.resolve();
    }

    return dispatch(
      batch({
        types: [
          BATCH_UPDATE_SWITCH_PORT_REQUEST,
          BATCH_UPDATE_SWITCH_PORT_SUCCESS,
          BATCH_UPDATE_SWITCH_PORT_FAILURE,
        ],
        config: {
          method: Method.post,
          body: JSON.stringify({
            confirmed: true,
            synchronous: false,
            actions,
          }),
        },
        meta: { serialNumber, portIds },
      }),
    );
  };
}
