import { z } from "zod";

import { request } from "../../api/request/request";
import { ProductType } from "../../schemas";
import { createQuery } from "../createQuery";

export const DeviceStatusOverviewByStatusSchema = z.object({
  online: z.number(),
  alerting: z.number(),
  offline: z.number(),
  dormant: z.number(),
});

export const OrganizationDeviceStatusOverviewSchema = z.object({
  counts: z.object({
    byStatus: DeviceStatusOverviewByStatusSchema,
  }),
});

export type DeviceStatusOverviewByStatus = z.infer<typeof DeviceStatusOverviewByStatusSchema>;
export type OrganizationDeviceStatusOverview = z.infer<
  typeof OrganizationDeviceStatusOverviewSchema
>;

interface OrganizationDeviceStatsOverviewRequest {
  organizationId?: string;
  networkId?: string;
  body?: {
    productTypes?: ProductType[];
  };
}

const buildUrl = ({ organizationId, networkId }: OrganizationDeviceStatsOverviewRequest) => {
  return `/api/v1/organizations/${organizationId}/devices/statuses/overview${
    networkId ? `/${networkId}` : ""
  }`;
};

const fetchOrganizationDeviceStatsOverview = ({
  organizationId,
  networkId,
  body,
}: OrganizationDeviceStatsOverviewRequest) => {
  return request(OrganizationDeviceStatusOverviewSchema, "GET", buildUrl({ organizationId }), {
    queryParams: { ...(body ?? {}), networkIds: networkId ? [networkId] : undefined },
  });
};

export const useOrganizationDeviceStatusOverview = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}`, networkId: `{networkId}` }),
  queryFn: (parameters: OrganizationDeviceStatsOverviewRequest) =>
    fetchOrganizationDeviceStatsOverview(parameters),
  requiredVariables: ["organizationId"],
});
