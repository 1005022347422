import { SECONDS_IN_A_DAY } from "./ChartHelper";

export type Weekday =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";
export const DAYS: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] =
  ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export interface OfflineRange {
  start: number;
  end: number;
}

interface Schedule {
  start: number;
  end: number;
  isAvailable: boolean;
}

export const mergeOfflineTimes = (offlineTimes: OfflineRange[]) => {
  offlineTimes.sort((a, b) => a.start - b.start);
  const newOfflineTimes = [];

  if (offlineTimes.length === 0) {
    return [];
  }

  let newTime: OfflineRange = { ...(offlineTimes[0] as OfflineRange) };
  for (const time of offlineTimes) {
    if (newTime?.end !== undefined && newTime.end >= time.start) {
      newTime.end = Math.max(time.end, newTime.end) ?? 0;
    } else {
      newOfflineTimes.push({ ...newTime });
      newTime = { ...time };
    }
  }
  newOfflineTimes.push(newTime);
  return newOfflineTimes;
};

export const invertRange = (startTime: number, endTime: number) => {
  const fullStartDay = 0;
  const fullEndDay = SECONDS_IN_A_DAY;

  // Available all day
  if (startTime === fullStartDay && endTime === fullEndDay) {
    return [];
  } else if (
    (startTime === fullStartDay && endTime !== fullEndDay) ||
    (startTime !== fullStartDay && endTime === fullEndDay)
  ) {
    return [{ start: startTime, end: endTime }];
  } else {
    return [
      { start: fullStartDay, end: startTime },
      { start: endTime, end: fullEndDay },
    ];
  }
};

export const rangeForDay = (offlineRanges: OfflineRange[], startDay: number, endDay: number) => {
  const rangesInDay: OfflineRange[] = [];

  for (const range of offlineRanges) {
    const rangeToPush = {
      start: -1,
      end: -1,
    };

    const { start, end } = range;
    if (end <= start) {
      continue;
    }

    if (start >= startDay && start < endDay) {
      rangeToPush.start = start;
      if (end > endDay) {
        rangeToPush.end = endDay;
      } else {
        rangeToPush.end = end;
      }
      rangesInDay.push({ ...rangeToPush });
    } else if (end > startDay && end <= endDay) {
      rangeToPush.end = end;
      if (start < startDay) {
        rangeToPush.start = startDay;
      } else {
        rangeToPush.start = start;
      }
      rangesInDay.push({ ...rangeToPush });
    }
  }

  if (rangesInDay.length === 1) {
    const start = rangesInDay[0]?.start ?? 0;
    const end = rangesInDay[0]?.end ?? 0;
    return {
      isAvailable: false,
      start: start * 1000,
      end: end * 1000,
    };
  } else if (rangesInDay.length === 2) {
    const end = rangesInDay[0]?.end ?? 0;
    const start = rangesInDay[1]?.start ?? 0;

    return {
      isAvailable: true,
      start: end * 1000,
      end: start * 1000,
    };
  }
  return {
    isAvailable: true,
    start: startDay * 1000,
    end: endDay * 1000,
  };
};

export const dateFormatter = (offlineRanges: OfflineRange[]) => {
  if (!offlineRanges) return;

  const schedule: {
    sunday: Schedule | undefined;
    monday: Schedule | undefined;
    tuesday: Schedule | undefined;
    wednesday: Schedule | undefined;
    thursday: Schedule | undefined;
    friday: Schedule | undefined;
    saturday: Schedule | undefined;
  } = {
    sunday: undefined,
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  };

  for (let i = 0; i < DAYS.length; i++) {
    const day = DAYS[i];
    const startOfDay = SECONDS_IN_A_DAY * i;
    const endOfDay = SECONDS_IN_A_DAY * (i + 1);
    const range = rangeForDay(offlineRanges, startOfDay, endOfDay);
    if (!day) {
      continue;
    }

    range.start = Math.abs(range.start - startOfDay * 1000);
    range.end = Math.abs(range.end - startOfDay * 1000) % (SECONDS_IN_A_DAY * 1000);
    schedule[day] = range;
  }
  return schedule;
};
