import {
  CAMERA_CONFIG_GET_SUCCESS,
  CAMERA_CONFIG_UPDATE_SUCCESS,
  CAMERA_PLAYER_RELEASED,
  CAMERA_WIRELESS_PROFILES_GET_SUCCESS,
  CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE,
  CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST,
  CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS,
  DEWARPED_TOGGLED,
  HISTORICAL_VIDEO_FILE_SUCCESS,
  MOTION_EVENTS_SUCCESS,
  MOTION_SEARCH_FILTER_SET,
  PEOPLE_DETECTION_SET,
  PLAY_HISTORICAL_VIDEO,
  PLAY_LIVE_VIDEO,
  SET_CLOCK_START_TS,
  SET_CURRENT_VIDEO_SETTING_PAGE,
  SET_INITIAL_DELTA,
  SET_MOTION_EVENTS_LOADING_STATUS,
  SET_MOTION_SEARCH_INTERVAL,
  SET_MOTION_SEARCH_PAGE,
  SET_OFFSET,
  SET_PLAYBACK_RATE,
  SET_PLAYBACK_STATUS,
  SET_REGION_OF_INTEREST,
  SET_SELECTED_MOTION_EVENT_TIMESTAMP,
  SET_VIDEO_IS_LOADING,
  SET_VIDEO_MODE,
  SET_VIDEO_SETTING_MENU_VISIBILITY,
  UPDATE_OFFSET,
  VIDEO_CHANNEL_SUCCESS,
  VIDEO_MUTE_TOGGLED,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { unionBy } from "lodash";
import { set } from "lodash/fp";
import { Reducer } from "redux";

import { VideoChannel } from "~/api/schemas/VideoChannel";
import {
  DEFAULT_MIN_EVENT_LENGTH_SEC,
  DEFAULT_MOTION_SENSITIVITY,
} from "~/enterprise/constants/Camera";
import {
  CameraConfig,
  MotionEvent,
  MotionSearch,
  PlaybackRateId,
  SettingPageId,
  VideoSettings,
  VideoStatus,
} from "~/enterprise/types/Cameras";
import { WirelessProfile } from "~/shared/types/Models";

export type CameraState = {
  channels: { [nodeId: string]: VideoChannel };
  configs: { [nodeId: string]: CameraConfig };
  wirelessProfiles: WirelessProfile[];
  motionEvents: { [nodeId: string]: MotionEvent[] };
  motionSearch: { [nodeId: string]: MotionSearch };
  videoStatus: VideoStatus;
  videoSettings: VideoSettings;
};

export const defaultMotionSearch = {
  selectedEventTimestamp: null,
  isLoading: false,
  searchInterval: {
    startTime: null,
    endTime: null,
    currentPage: 1,
    totalPages: 1,
  },
  roi: null,
  polygonPoints: null,
  minEventLength: DEFAULT_MIN_EVENT_LENGTH_SEC,
  withPeople: false,
  sensitivity: DEFAULT_MOTION_SENSITIVITY,
};

export const initialState: CameraState = {
  channels: {},
  configs: {},
  wirelessProfiles: [],
  motionEvents: {},
  motionSearch: {},
  videoStatus: {
    initialDelta: 0,
    offset: 0,
    isPlaying: false,
    videoMode: "live",
    isLoading: true,
    clockStartTS: 0,
    isDewarped: true,
    // TODO: Persist this?
    isMuted: true,
  },
  videoSettings: {
    showMenu: false,
    rateId: PlaybackRateId.NORMAL,
    currentVideoSettingPageId: SettingPageId.VIDEO_SETTINGS,
  },
};

const cameras: Reducer<CameraState> = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_CHANNEL_SUCCESS:
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.response.node_id]: {
            ...action.response,
          },
        },
      };
    case HISTORICAL_VIDEO_FILE_SUCCESS:
    case CAMERA_CONFIG_GET_SUCCESS:
    case CAMERA_CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.response.id]: {
            ...action.response,
          },
        },
      };
    case CAMERA_WIRELESS_PROFILES_GET_SUCCESS:
      return {
        ...state,
        wirelessProfiles: action.response,
      };
    case CAMERA_WIRELESS_PROFILES_UPDATE_REQUEST:
    case CAMERA_WIRELESS_PROFILES_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case MOTION_EVENTS_SUCCESS:
      const existingMotionEvents: MotionEvent[] = state.motionEvents?.[action.meta.nodeId] ?? [];

      return {
        ...state,
        motionEvents: {
          ...state.motionEvents,
          [action.meta.nodeId]: action.meta.update
            ? unionBy<MotionEvent>(existingMotionEvents, action.response, (me) => me.ts)
            : action.response,
        },
      };
    case SET_REGION_OF_INTEREST:
      return {
        ...state,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            roi: action.payload.roiArr,
            polygonPoints: action.payload.polygonPoints,
          },
        },
      };
    case SET_MOTION_SEARCH_INTERVAL:
      return {
        ...state,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            searchInterval: {
              ...state.motionSearch[action.payload.nodeId]?.searchInterval,
              startTime: action.payload.startTime,
              endTime: action.payload.endTime,
            },
          },
        },
      };
    case SET_MOTION_SEARCH_PAGE:
      return {
        ...state,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            searchInterval: {
              ...state.motionSearch[action.payload.nodeId]?.searchInterval,
              currentPage: action.payload.currentPage,
              totalPages: action.payload.totalPages,
            },
          },
        },
      };
    case SET_MOTION_EVENTS_LOADING_STATUS:
      return set(
        ["motionSearch", action.payload.nodeId, "isLoading"],
        action.payload.isLoading,
      )(state);
    case MOTION_SEARCH_FILTER_SET:
      return {
        ...state,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            withPeople: action.payload.withPeople,
            minEventLength: action.payload.minEventLength,
            sensitivity: action.payload.sensitivity,
          },
        },
      };
    case PEOPLE_DETECTION_SET:
      return set(
        ["motionSearch", action.payload.deviceId, "withPeople"],
        action.payload.withPeople,
      )(state);
    case SET_SELECTED_MOTION_EVENT_TIMESTAMP:
      return set(
        ["motionSearch", action.payload.nodeId, "selectedEventTimestamp"],
        action.payload.timestamp,
      )(state);
    case SET_VIDEO_SETTING_MENU_VISIBILITY:
      return {
        ...state,
        videoSettings: {
          ...state.videoSettings,
          showMenu: action.payload.visible,
          currentVideoSettingPageId: SettingPageId.VIDEO_SETTINGS,
        },
      };
    case SET_CURRENT_VIDEO_SETTING_PAGE:
      return {
        ...state,
        videoSettings: {
          ...state.videoSettings,
          currentVideoSettingPageId: action.payload.pageId,
        },
      };
    case SET_PLAYBACK_RATE:
      return {
        ...state,
        videoSettings: {
          ...state.videoSettings,
          rateId: action.payload.rateId,
        },
      };
    case SET_VIDEO_MODE:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          videoMode: action.payload.videoMode,
        },
      };
    case SET_PLAYBACK_STATUS:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          isPlaying: action.payload.isPlaying,
        },
      };
    case SET_OFFSET:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          offset: action.payload.offset,
        },
      };
    case UPDATE_OFFSET:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          offset: state.videoStatus.offset + action.payload.offset,
        },
      };
    case SET_CLOCK_START_TS:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          clockStartTS: action.payload.clockStartTS,
        },
      };
    case SET_INITIAL_DELTA:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          initialDelta: action.payload.initialDelta,
        },
      };
    case SET_VIDEO_IS_LOADING:
      return {
        ...state,
        videoStatus: {
          ...state.videoStatus,
          isLoading: action.payload.isLoading,
        },
      };
    case PLAY_LIVE_VIDEO:
      return {
        ...state,
        videoStatus: { ...initialState.videoStatus, isLoading: true },
        videoSettings: initialState.videoSettings,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            selectedEventTimestamp: null,
          },
        },
      };
    case PLAY_HISTORICAL_VIDEO:
      return {
        ...state,
        videoStatus: { ...state.videoStatus, ...action.payload },
      };
    case CAMERA_PLAYER_RELEASED:
      return {
        ...state,
        videoStatus: initialState.videoStatus,
        videoSettings: initialState.videoSettings,
        motionSearch: {
          ...state.motionSearch,
          [action.payload.nodeId]: {
            ...state.motionSearch[action.payload.nodeId],
            isLoading: defaultMotionSearch.isLoading,
            searchInterval: defaultMotionSearch.searchInterval,
            selectedEventTimestamp: defaultMotionSearch.selectedEventTimestamp,
          },
        },
      };
    case DEWARPED_TOGGLED:
      return set(["videoStatus", "isDewarped"], action.payload.isDewarped)(state);
    case VIDEO_MUTE_TOGGLED:
      return set(["videoStatus", "isMuted"], action.payload.isMuted)(state);
    case CAMERA_WIRELESS_PROFILES_UPDATE_FAILURE:
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default cameras;
