import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import ComparisonQuickStatusBoxes from "~/go/components/ComparisonQuickStatusBoxes";
import { SummaryValue, TranslatedGraphData } from "~/go/types/LocationAnalyticsTypes";
import { updateSelectedElements } from "~/lib/LocationAnalyticsUtils";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import { MkiLegendElementProps } from "~/shared/components/MkiLegendElement";
import SummaryCard from "~/shared/components/SummaryCard";
import { DataKey, Domain, SelectedElements } from "~/shared/types/MkiChartTypes";

export interface LocationDataCardProps {
  summaryData?: SummaryValue[];
  graphData?: TranslatedGraphData;
  dataKeys: DataKey[];
  comparedSummaryData?: SummaryValue[];
  comparedDataKeys?: DataKey[];
  domain: Domain;
  timespan: number;
  testID?: string;
}

export interface LocationDataCardState {
  selectedElements: SelectedElements;
  renderedDataKeys: DataKey[];
  legendElements: MkiLegendElementProps[];
}

abstract class LocationDataCard extends PureComponent<
  LocationDataCardProps,
  LocationDataCardState
> {
  abstract heading: string;

  abstract subheading: string;

  abstract renderChart: (shownDataKeys: DataKey[]) => JSX.Element;

  static defaultProps = {
    summaryData: [],
    graphData: [],
    dataKeys: [],
    comparedSummaryData: undefined,
    comparedDataKeys: undefined,
    domain: undefined,
  };

  constructor(props: LocationDataCardProps) {
    super(props);
    const { dataKeys } = props;
    const selectedElements = new Set<string>(dataKeys.map((key) => key.y));
    const renderedDataKeys = [...dataKeys];
    const legendElements = this.createLegendElements(dataKeys);
    this.state = { selectedElements, renderedDataKeys, legendElements };
  }

  componentDidUpdate(prevProps: LocationDataCardProps) {
    const { dataKeys, comparedDataKeys } = this.props;
    if (dataKeys === prevProps.dataKeys || comparedDataKeys === prevProps.comparedDataKeys) {
      return;
    }
    const legendElements = this.createLegendElements(dataKeys);
    this.setState({ legendElements });
  }

  createLegendElements = (dataKeys: DataKey[]) =>
    dataKeys.map((key, idx) => this.convertToLegendElement(key, idx));

  convertToLegendElement = (key: DataKey, idx: number): MkiLegendElementProps => {
    const { comparedDataKeys } = this.props;
    const colors = [key.color];
    if (comparedDataKeys) {
      colors.push(comparedDataKeys[idx].color);
    }
    return {
      label: key.y,
      colors,
      onSelectChange: () => {
        this.updateStateOnSelect(key.y);
      },
    };
  };

  updateStateOnSelect = (label: string) => {
    const { dataKeys } = this.props;
    const { selectedElements, legendElements } = this.state;
    const newSelectedElements = updateSelectedElements(selectedElements, legendElements, label);
    const newRenderedDataKeys = dataKeys.filter((key) => newSelectedElements.has(key.y));
    this.setState({
      ...this.state,
      selectedElements: newSelectedElements,
      renderedDataKeys: newRenderedDataKeys,
    });
  };

  renderSummaryData = () => {
    const { summaryData, comparedSummaryData } = this.props;
    if (!summaryData) {
      return null;
    }
    const statusBoxes = summaryData.map((summaryValue, idx) => {
      const { value, units, label } = summaryValue;

      return (
        <ComparisonQuickStatusBoxes
          key={label}
          value={value}
          comparedValue={comparedSummaryData ? comparedSummaryData[idx].value : undefined}
          units={units}
          label={label}
        />
      );
    });

    return <View style={styles.summaryContainer}>{statusBoxes}</View>;
  };

  render() {
    const { testID } = this.props;
    const { selectedElements, legendElements, renderedDataKeys } = this.state;
    return (
      <SummaryCard heading={this.heading} subheading={this.subheading} testID={testID}>
        {this.renderSummaryData()}
        {this.renderChart(renderedDataKeys)}
        <MkiGraphLegend legendValues={legendElements} selectedElements={selectedElements} />
      </SummaryCard>
    );
  }
}

const styles = StyleSheet.create({
  summaryContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});

export default LocationDataCard;
