import MkiColors from "~/constants/MkiColors";
import { isDeepEmpty } from "~/lib/objectHelper";
import { getFetchingNodes, makeHardwareListDevices } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { DevicesByType } from "~/shared/types/Device";

export const useUpdateHardwareBadge = () => {
  const actions = useActions();

  const deviceFilter = makeHardwareListDevices();
  const devicesByType: DevicesByType = useAppSelector((state) => deviceFilter(state, {}));
  const fetchingNodes = useAppSelector(getFetchingNodes);

  // update Hardware badge
  if (!fetchingNodes) {
    if (isDeepEmpty(devicesByType)) {
      actions.setBottomBarTab("HardwareStack", "!", {
        backgroundColor: MkiColors.badStatus,
      });
    } else {
      actions.setBottomBarTab("HardwareStack", undefined);
    }
  }
};
