import { Dimensions, StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { platformSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";

type Props = {
  show: boolean;
  onPress: () => void;
  testID?: string;
  hasCustomStyle?: boolean;
};

const DeleteButton = (props: Props) => {
  const { show, onPress, hasCustomStyle, testID } = props;
  const positionStyle = show ? styles.deleteShow : styles.deleteHide;
  const rightStyle = show ? styles.deleteRightShow : styles.deleteRightHide;
  const containerStyle = hasCustomStyle ? rightStyle : positionStyle;
  return (
    <MerakiIcon
      name="remove-circle"
      size="xs"
      containerStyle={[styles.deleteIcon, containerStyle]}
      color="red"
      onPress={onPress}
      testID={testID}
    />
  );
};

DeleteButton.defaultProps = {
  testID: undefined,
  hasCustomStyle: false,
};

const deleteHeight = 20;
const deleteOffset = 16;

const { width } = Dimensions.get("window");
const widthOffset = 40;

const styles = StyleSheet.create({
  deleteIcon: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: SPACING.default - SPACING.tiny,
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
    borderRadius: platformSelect({
      // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'.
      mobile: deleteHeight / 2,
      web: "50%",
    }),
    width: deleteHeight,
    height: deleteHeight,
    ...platformSelect({
      ios: {
        shadowColor: MkiColors.tileShadowColor,
        shadowOpacity: 0.12,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
      },
      android: { elevation: 1 },
    }),
  },
  deleteShow: {
    marginLeft: 0,
  },
  deleteHide: {
    marginLeft: -deleteHeight - deleteOffset,
  },
  deleteRightHide: {
    marginStart: width,
  },
  deleteRightShow: {
    marginStart: width - widthOffset,
  },
});

export default DeleteButton;
