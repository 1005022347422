import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import { globalSearchKeys } from "~/api/queries/admin/keys";
import { GoOrgSearchResults, GoOrgSearchResultsSchema } from "~/api/schemas/GoOrgSearch";
import { validatedMerakiRequest } from "~/api/util/request";
import { getUserData } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const goOrgSearch = (searchTerm: string): Promise<GoOrgSearchResults> => {
  return validatedMerakiRequest(GoOrgSearchResultsSchema, "GET", "/go/org_search", {
    queryParams: { query: searchTerm },
  });
};

const useGoOrgSearch = (searchTerm: string) => {
  const { is_meraki_sso_admin: isMerakiSSOAdmin } = useAppSelector(getUserData);

  return useQuery<GoOrgSearchResults, Error>({
    queryKey: globalSearchKeys.goOrgSearch(searchTerm),
    queryFn: () => goOrgSearch(searchTerm),
    enabled: !!isMerakiSSOAdmin && !isEmpty(searchTerm),
  });
};

export default useGoOrgSearch;
