export * from "./models/SwitchPort";
import { FontWeightOptions } from "@meraki/go/ssid";

import { BAND_SELECTIONS } from "~/constants/MkiConstants";
import { SplashMethods } from "~/shared/types/SplashSettings";

import { Client } from "./Client";

export type BooleanStrings = "true" | "false";

export interface PerPortVlanSettingsItem {
  allowed_vlans?: string[];
  enabled: BooleanStrings;
  is_trunk?: BooleanStrings;
  native_vlan_guid?: string;
  port_idx: number;
  skip?: boolean;
}

export interface PerPortVlanSettings {
  builtin?: PerPortVlanSettingsItem[];
}

export interface PortForwardingRule {
  allowedIps: string[];
  lanIp: string;
  localPort: string;
  name: string;
  protocol: string;
  publicPort: string;
  uplink: string;
}

interface BaseSSID {
  enabled: boolean;
  name: string;
  number: number;
}

export enum SSIDAuthMode {
  open = "open",
  psk = "psk",
  ipsk = "ipsk-without-radius",
  radius802 = "8021x-radius",
  meraki802 = "8021x-meraki",
}

export enum SSIDEncryptionMode {
  wpa = "wpa",
  eap = "wpa-eap",
}

export interface pdfInfo {
  orgName?: string;
  logoURL?: string;
  base64Logo?: string;
  customMessage?: string;
  fontWeight?: FontWeightOptions;
}

export interface SSID extends BaseSSID {
  availableOnAllAps: boolean;
  authMode: SSIDAuthMode;
  bandSelection?: BAND_SELECTIONS;
  c2cEnabled?: boolean;
  defaultVlanId?: number;
  dot11w?: Dot11w;
  encryptionMode?: SSIDEncryptionMode;
  ipAssignmentMode?: string;
  isGuestNetwork?: boolean;
  minBitrate?: number;
  perClientBandwidthLimitDown?: number;
  perClientBandwidthLimitUp?: number;
  perSsidBandwidthLimitUp?: number;
  perSsidBandwidthLimitDown?: number;
  psk?: string;
  schedule?: SSIDSReduxSchedule;
  splashPage?: SplashMethods;
  splashTimeout?: string;
  ssidAdminAccessible?: boolean;
  useVlanTagging?: boolean;
  walledGardenEnabled?: boolean;
  walledGardenRanges: string[];
  wpaEncryptionMode?: string;
  blockedMacs?: string[];
  alloweddMacs?: string[];
  qrCode?: string;
  pdfInfo: pdfInfo;
  visible?: boolean;
}

export interface SSIDWithClients extends SSID {
  clients: Client[];
}

export interface WirelessProfile {
  id: number;
  name: string;
  appliedDeviceCount: number;
  ssid: SSID;
  identity?: Identity;
}

export interface Identity {
  username: string;
  password: string;
}

export interface Dot11w {
  enabled: boolean;
  required: boolean;
}

export type OnboardingCredentials = OnboardingCredentialsPSK | OnboardingCredentialsEAP;

export interface OnboardingCredentialsPSK {
  key_mgmt: string;
  ssid: string;
  psk: string;
  priority: number;
}

export interface OnboardingCredentialsEAP {
  key_mgmt: string;
  ssid: string;
  priority: number;
  identity: string;
  password: string;
}

export interface NotificationPermissions {
  alert: number | boolean;
  badge: number;
  sound: number;
}

export interface SSIDsByNumber {
  [id: number]: SSID;
}

export interface I18nParams {
  [id: string]: string;
}

export interface CommunityData {
  type: string;
  id: string;
  view_href: string;
  subject: string;
  search_snippet: string;
}

interface DocumentationPage {
  path: string;
  rating: string;
  title: string;
  "uri.ui": string;
}

export interface DocumentationData {
  content: string;
  "date.modified": string;
  id: number;
  preview: string;
  title: string;
  uri: string;
  page: DocumentationPage;
}
export interface SSIDSReduxSchedule {
  enabled: boolean;
  ranges: SSIDScheduleRange[];
  rangesInSeconds: SSIDScheduleRangeInSeconds[];
}

export interface SSIDScheduleRange {
  endDay: string;
  startDay: string;
  endTime: string;
  startTime: string;
}

export interface SSIDScheduleRangeInSeconds {
  start: number;
  end: number;
}
export interface ScheduleRow {
  day: string;
  isActive: boolean;
  from: string;
  to: string;
}

export interface ScheduleInfo {
  isActive: boolean;
  from: string;
  to: string;
}

export interface SsidSchedule {
  sunday?: ScheduleInfo;
  monday?: ScheduleInfo;
  tuesday?: ScheduleInfo;
  wednesday?: ScheduleInfo;
  thursday?: ScheduleInfo;
  friday?: ScheduleInfo;
  saturday?: ScheduleInfo;
}

interface WPAWirelessProfileEnterpriseAuth {
  username: string;
  password: string;
}

interface WPAWirelessProfilePSKAuth {
  psk: string;
}

export interface WPAWirelessProfileCreate {
  name: string;
  ssidName: string;
  auth: WPAWirelessProfileEnterpriseAuth | WPAWirelessProfilePSKAuth;
}

export interface WPAWirelessProfileUpdate extends WPAWirelessProfileCreate {
  id: number;
}
