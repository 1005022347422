import { createSelector } from "reselect";

import { getRemoteConfig } from "~/selectors/getters";
import { RemoteConfigParams } from "~/shared/types/Firebase";
import { RootState } from "~/shared/types/Redux";

export function getRemoteConfigByParam<P extends keyof RemoteConfigParams>(
  state: RootState,
  remoteConfigParam: P,
) {
  return createSelector(
    getRemoteConfig,
    (_: RootState, remoteConfigParam: P) => remoteConfigParam,
    (remoteConfigState, remoteConfigParam) => {
      return remoteConfigState?.[remoteConfigParam]?.value as RemoteConfigParams[P];
    },
  )(state, remoteConfigParam);
}
