import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";
import RadioSelectionList, {
  RadioSelectionListProps,
} from "~/shared/components/RadioSelectionList";

interface RadioSelectionRowProps<T extends string | number> extends RadioSelectionListProps<T> {
  title?: string;
  screenStyles?: StyleProp<ViewStyle>;
}

export default function RadioSelectionRow<T extends string | number>({
  title,
  screenStyles,
  ...rest
}: RadioSelectionRowProps<T>) {
  return (
    <View style={[styles.rowContainer, screenStyles]}>
      {title && <MkiText textStyle={"small"}>{title}</MkiText>}
      <RadioSelectionList {...rest} />
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.large,
  },
});
