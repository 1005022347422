import { I18n } from "@meraki/core/i18n";
import { Button } from "@meraki/magnetic/components";
import { LicenseScreen, LicensesListScreen } from "@meraki/shared/license";
import { LicenseGroupProps, StackType } from "@meraki/shared/navigation-type";

export function LicenseGroup<T extends LicenseGroupProps>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="LicensesList"
        component={LicensesListScreen}
        options={{
          headerTitle: I18n.t("UMBRELLA.SUBSCRIPTION.LICENSES.TITLE"),
          headerRight: () => <Button.Icon icon="Plus" onPress={() => null} />,
        }}
      />
      <Stack.Screen
        name="License"
        component={LicenseScreen}
        options={{
          headerTitle: I18n.t("UMBRELLA.SUBSCRIPTION.LICENSE"),
        }}
      />
    </>
  );
}
