import { LoginDetailsResponseType } from "@meraki/shared/api";
import { setCurrentShardId } from "@meraki/shared/redux";

const getShardFromPath = (response: LoginDetailsResponseType) => {
  const { user } = response;
  const { path } = user;
  return Number(path.split(".")[0]?.split("n")[1]);
};

// todo: we might want to find a more fitting place to store these
// functions as they come out of intermediate.ts
export const setShardFromPath = (response: LoginDetailsResponseType) => {
  const shardId = getShardFromPath(response);
  return setCurrentShardId(shardId);
};
