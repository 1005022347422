import { Alert, Icon, Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import {
  ColorToken,
  getColorFromTokenName,
  Theme,
  useMagneticTheme,
} from "@meraki/magnetic/themes";
import { ReactNode } from "react";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";

import { Text, TextProps } from "../Text/Text";

export type TagProps =
  | {
      testID?: string;
      type?: "default";
      status?: never;
      color?: never;
      dotColor?: never;
      selected?: never;
      onPress?: () => void;
      children: string;
    }
  | {
      testID?: string;
      type: "status";
      status: "positive" | "warning" | "negative" | "inactive" | "disabled";
      color?: never;
      dotColor?: never;
      selected?: never;
      onPress?: () => void;
      children: string;
    }
  | {
      testID?: string;
      type: "categorial";
      color: keyof typeof CATEGORIAL_COLOR_MAP;
      dotColor?: never;
      status?: never;
      selected?: never;
      onPress?: () => void;
      children: string;
    }
  | {
      testID?: string;
      type: "selectable";
      selected: boolean;
      onPress?: () => void;
      status?: never;
      color?: never;
      dotColor?: never;
      children: string;
    }
  | {
      testID?: string;
      type: "removable";
      selected?: never;
      onPress?: () => void;
      status?: never;
      color?: never;
      dotColor?: never;
      children: string;
    }
  | {
      testID?: string;
      type: "alert";
      status: "positive" | "warning" | "negative";
      color?: never;
      dotColor?: never;
      selected?: never;
      onPress?: () => void;
      children: string;
    }
  | {
      testID?: string;
      type: "selectableWithColor";
      selected: boolean;
      onPress?: () => void;
      dotColor: ColorToken;
      status?: never;
      color?: never;
      children: string;
    };

type PossibleStatuses = Exclude<TagProps["status"], undefined>;
type InternalProps = Omit<TagProps, "children">;

const ICON_MAP: Record<PossibleStatuses, ReactNode> = {
  positive: <Status status="positive" />,
  warning: <Status status="warning" />,
  negative: <Status status="negative" />,
  inactive: <Status status="neutral" />,
  disabled: <Status status="disabled" />,
};

const ALERTING_ICON_MAP: Record<PossibleStatuses, ReactNode> = {
  positive: <Alert size={13} status="positive" />,
  warning: <Alert size={13} status="warning" />,
  negative: <Alert size={13} status="negative" />,
  inactive: undefined,
  disabled: undefined,
};

const BACKGROUNDCOLOR_MAP: Record<PossibleStatuses, ColorToken> = {
  positive: "positive.bg.medium.base",
  warning: "warning.bg.medium.base",
  negative: "negative.bg.medium.base",
  inactive: "control.bg.medium.disabled",
  disabled: "control.bg.medium.disabled",
};

const CATEGORIAL_COLOR_MAP = {
  accentA: "accent.a.weak",
  accentB: "accent.b.weak",
  accentC: "accent.c.weak",
  accentD: "accent.d.weak",
  accentE: "accent.e.weak",
  accentF: "accent.f.weak",
  accentG: "accent.g.weak",
  accentH: "accent.h.weak",
  accentI: "accent.i.weak",
  accentJ: "accent.j.weak",
  accentK: "accent.k.weak",
} as const;

// NOTE: Some of these values eventually will move to tokens in the theme!
function getContainerStyle(
  { type, status, color, selected }: InternalProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let backgroundColor = theme.color.control.bg.medium.disabled;
  let borderColor = "transparent";
  let borderWidth = 0;
  let paddingVertical = 4;
  let paddingLeft = 12;
  let paddingRight = 12;

  if (type === "status" && status) {
    backgroundColor = getColorFromTokenName(BACKGROUNDCOLOR_MAP[status], theme);
  }

  if (type === "alert" && status) {
    backgroundColor = getColorFromTokenName(BACKGROUNDCOLOR_MAP[status], theme);
    paddingVertical = 1;
    paddingLeft = 4;
    paddingRight = 8;
  }

  if (type === "categorial" && color) {
    backgroundColor = getColorFromTokenName(CATEGORIAL_COLOR_MAP[color], theme);
  }

  if (type === "selectable" || type === "selectableWithColor") {
    backgroundColor = theme.color.default.bg.medium.base;
    borderColor = theme.color.control.border.base;
    borderWidth = 1;

    if (selected) {
      backgroundColor = theme.color.control.bg.hover;
      borderColor = theme.color.control.border.active;
      borderWidth = 2;
    }
  }

  return {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "center",
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius: 17,
    paddingLeft,
    paddingRight,
    paddingVertical,
  };
}

function getIcon({ type, status, dotColor }: InternalProps, theme: Theme) {
  let icon: ReactNode = null;

  switch (type) {
    case "status":
      icon = ICON_MAP[status ?? "disabled"];
      break;
    case "alert":
      icon = ALERTING_ICON_MAP[status ?? "disabled"];
      break;
    case "selectableWithColor":
      icon = (
        <View
          style={{
            width: 12,
            height: 12,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.color.control.border.base,
            backgroundColor: getColorFromTokenName(dotColor ?? "default.bg.base", theme),
          }}
        />
      );
      break;
  }

  if (!icon) {
    return null;
  }

  return (
    <View
      style={{
        marginRight: 5,
      }}
    >
      {icon}
    </View>
  );
}

function getTextProps({ type, selected }: InternalProps): TextProps {
  let color: ColorToken = "default.text.base";

  if ((type === "selectable" || type === "selectableWithColor") && selected) {
    color = "interact.text.base";
  }

  return {
    size: type === "alert" ? "p3" : "p2",
    color,
    weight: type === "alert" || selected ? "semiBold" : "regular",
  };
}

export function Tag({ children, type = "default", onPress, testID, ...rest }: TagProps) {
  const theme = useMagneticTheme();
  const internalProps = { type, ...rest };

  return (
    <Pressable onPress={onPress} hitSlop={{ top: 5, bottom: 5 }}>
      <View style={getContainerStyle(internalProps, theme)} testID={testID}>
        {getIcon(internalProps, theme)}
        <Text {...getTextProps(internalProps)}>{children}</Text>
        {type === "removable" && (
          <Box marginLeft="2xs">
            <Icon name="X" size={18} color="interact.icon.weak.base" />
          </Box>
        )}
      </View>
    </Pressable>
  );
}
