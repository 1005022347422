import { Badge } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { showErrorAlert } from "@meraki/shared/native-alert";
import React from "react";
import { Pressable } from "react-native";
import { useFilePicker } from "use-file-picker";

export interface FileAttachment {
  name: string;
  content: string;
}

interface AttachFileButtonProps {
  fileSelected: boolean;
  isDisabled: boolean;
  setFile: (file: FileAttachment) => void;
}

export const AttachFileButton = ({ fileSelected, isDisabled, setFile }: AttachFileButtonProps) => {
  const { openFilePicker: onPressAttach } = useFilePicker({
    multiple: false,
    readAs: "DataURL",
    accept: "image/*",
    onFilesRejected: ({ errors }) => {
      if (errors.length !== 0) {
        showErrorAlert(errors.join(", "));
      }
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      if (plainFiles.length === 1) {
        const selectedFile = plainFiles[0];
        const fileContent = filesContent[0];

        if (selectedFile !== undefined && fileContent !== undefined && selectedFile.name != null) {
          setFile({
            name: selectedFile.name,
            content: fileContent.content,
          });
        }
      }
    },
  });

  return (
    <Pressable disabled={isDisabled} onPress={onPressAttach}>
      <Badge.Dot badgeVisible={fileSelected}>
        <Icon
          name="Paperclip"
          color={`interact.icon.${isDisabled ? "disabled" : "base"}`}
          testID={`ATTACH_FILE_BUTTON${fileSelected ? ".ATTACHED_FILE" : ""}`}
        />
      </Badge.Dot>
    </Pressable>
  );
};
