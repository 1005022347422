import { useMutation } from "@tanstack/react-query";
import { Platform } from "react-native";

import { GoEmailOtpBackupSchema } from "~/api/schemas/GoEmailOtpBackupSchema";
import { validatedMerakiRequest } from "~/api/util/request";

const baseUrl = Platform.OS === "web" ? "/main" : "https://dashboard.meraki.com";

export const useEmailOtpBackup = () =>
  useMutation({
    mutationFn: () =>
      validatedMerakiRequest(GoEmailOtpBackupSchema, "POST", "/go/email_otp_code", {
        baseUrl,
      }),
  });
