import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

export const NewSupportCaseInputSchema = z
  .object({
    subject: z.string(),
    description: z.string(),
    related_devices: z.string(),
    // enterprise params
    captcha_shown: z.boolean().optional(),
    mki_captcha_provider_response: z.string().optional(),
    orgid: z.string().optional(),
    // go params
    email_override: z.string().optional(),
    Customer_Assigned_Priority__c: z.string().optional(),
    callback_number: z.string().optional(),
  })
  .describe("NewSupportCaseInputSchema");

export const NewSupportCaseResponseSchema = z
  .object({
    success: z.boolean(),
    need_captcha: z.boolean(),
    invalid_captcha: z.boolean(),
  })
  .describe("NewSupportCaseResponseSchema");

export type NewSupportCaseInput = z.infer<typeof NewSupportCaseInputSchema>;
export type NewSupportCaseResponse = z.infer<typeof NewSupportCaseResponseSchema>;

interface NewSupportCaseRequest {
  encryptedNetworkId?: string;
}

interface MutateNewSupportCaseRequest extends NewSupportCaseRequest {
  body: NewSupportCaseInput;
}

const buildUrl = ({ encryptedNetworkId }: NewSupportCaseRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/new_case`;
};

const newSupportCase = ({ encryptedNetworkId, body }: MutateNewSupportCaseRequest) => {
  return request(NewSupportCaseResponseSchema, "POST", buildUrl({ encryptedNetworkId }), {
    body: JSON.stringify(body),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useNewSupportCase = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: MutateNewSupportCaseRequest) => newSupportCase({ ...parameters }),
});
