import Svg, { Path } from "react-native-svg";

export const AppleLogoIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M8.63135 2.03125C8.77672 1.65376 9.03315 1.32922 9.36679 1.10047C9.70042 0.871722 10.0956 0.749523 10.5001 0.750001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.6562 10.1813C12.8687 12.1063 11.4438 13.5 10.25 13.5H5.75C4 13.5 1.75 10.5 1.75 7.25002C1.75026 6.52623 1.95997 5.81797 2.35384 5.21073C2.74771 4.60348 3.30889 4.12319 3.96968 3.82782C4.63046 3.53245 5.36261 3.43462 6.07776 3.54613C6.79291 3.65765 7.46051 3.97374 8 4.45627V4.45627C8.38187 4.11416 8.82984 3.85398 9.31623 3.69183C9.80262 3.52967 10.3171 3.46897 10.8279 3.51349C11.3386 3.55801 11.8349 3.70679 12.2859 3.95065C12.7369 4.1945 13.1331 4.52825 13.45 4.93127V4.93127C12.9906 5.20865 12.6141 5.60415 12.3596 6.07655C12.1051 6.54896 11.982 7.08098 12.003 7.61717C12.0241 8.15336 12.1886 8.67408 12.4794 9.12507C12.7701 9.57605 13.1766 9.94079 13.6562 10.1813V10.1813Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default AppleLogoIcon;
