import { useContext, useEffect, useRef } from "react";

import LiveToolContext from "../LiveToolContext";
import { CompletableDataMsg } from "../types/DataMsg";
import useCachedFormatter from "./useCachedFormatter";

export default function useCtxAwareAggregation<M extends CompletableDataMsg, FM, AR>(
  msg: M | undefined,
  formatter: (msg: M) => FM | undefined,
  aggregator: (accumulatedResult?: AR, formattedMsg?: FM) => AR | undefined,
): AR | undefined {
  const isComplete = msg?.data.completed;
  const formattedMsg = useCachedFormatter(msg, formatter);

  const accumulatedResult = useRef<AR>();
  const ltContext = useContext(LiveToolContext);

  const userStarted = ltContext.command === "start";
  const userRestarted = ltContext.command === "restart";
  const userStopped = ltContext.command === "stop";

  useEffect(() => {
    if (accumulatedResult.current) {
      return;
    }
    // First defined msg
    accumulatedResult.current = aggregator(undefined, formattedMsg);
  }, [msg]);

  const skipDerivation = userStopped || isComplete;
  if (skipDerivation) {
    return accumulatedResult.current;
  }

  const reset = (userRestarted || userStarted) && !formattedMsg;
  if (reset) {
    accumulatedResult.current = undefined;
    return undefined;
  }

  accumulatedResult.current = aggregator(accumulatedResult.current, formattedMsg);
  return accumulatedResult.current;
}
