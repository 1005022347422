import { Box } from "@meraki/magnetic/layout";
import Svg, { Defs, Image, Pattern, Rect, Use } from "react-native-svg";

import { BasePortKindProps, BasePortProps } from "../types";
import { Indicator } from "./Indicator";
import { PortColorMap } from "./portColorMap";
import { PortNumber } from "./PortNumber";

const viewBoxWidth = 80;
const viewBoxHeight = 44;

const smallSizeDelta = 20;

export function Stack({
  flipped,
  state,
  indicator,
  selected,
  number,
  size = "md",
  testID,
}: BasePortProps & BasePortKindProps) {
  const colors = PortColorMap[state ?? "disconnected"][selected ? "selected" : "idle"];
  const striped = state === "reducedSpeed";

  const PortNumberComponent = <PortNumber number={number} selected={selected} size={size} />;

  return (
    <Box alignItems="center" gap="2xs" testID={testID}>
      {!flipped && PortNumberComponent}
      <Svg
        width={viewBoxWidth - (size === "md" ? 0 : smallSizeDelta)}
        height={viewBoxHeight - (size === "md" ? 0 : smallSizeDelta)}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        fill="none"
        pointerEvents="none"
      >
        {striped && (
          <Defs>
            <Pattern
              height="0.227273"
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="0.25"
            >
              <Use
                transform="translate(0.25) scale(0.0125 0.0113636) rotate(90)"
                xlinkHref="#image0_6942_14099"
              />
            </Pattern>
            <Image
              height="20"
              id="image0_6942_14099"
              width="20"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABKSURBVHgB7dPBCgAgCAPQ/Xn9+cIwKOgQtCDQXR3vogJTSFauKdjkqBcE86IMsx6UmA2hxAYowzqoxBj4Hv/FvCjDmJ9yjfHF1hsZzPUnWlXRXQAAAABJRU5ErkJggg=="
            />
          </Defs>
        )}
        <Rect width="80" height="44" rx="2" fill={colors.boxBackground} />
        {striped && (
          <Rect
            fill="url(#pattern0)"
            height={selected ? 42 : 44}
            width={selected ? 78 : 80}
            x={selected ? 2 : 1}
          />
        )}
        <Rect
          x="6.5"
          y="6.5"
          width="67"
          height="31"
          fill={colors.portBackground}
          stroke={colors.portBorder}
        />
        {selected && (
          <Rect
            x="1"
            y="1"
            width="78"
            height="42"
            rx="1"
            stroke={colors.portBackground}
            stroke-width="2"
          />
        )}
        <Indicator indicator={indicator} x={30} y={12} color={colors.iconBackground} />
      </Svg>
      {flipped && PortNumberComponent}
    </Box>
  );
}
