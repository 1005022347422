import {
  dhcpProblemEventTypes,
  sensorAlertEventTypes,
  sensorGeneralEventTypes,
} from "~/constants/NetworkEvent";

const sensorEvents = new Set<string>([...sensorAlertEventTypes, ...sensorGeneralEventTypes]);
const dhcpProblemEvents = new Set<string>([...dhcpProblemEventTypes]);

export const isSensorEventType = (eventType: string): boolean => sensorEvents.has(eventType);
export const isDeviceDown = (eventType: string): boolean => eventType === "stopped_reporting";
export const isDHCPProblem = (eventType: string): boolean => dhcpProblemEvents.has(eventType);

export const testables = {
  sensorEvents,
};
