import { useMemo } from "react";

export default function useCachedFormatter<M, FM>(
  msg: M | undefined,
  formatter: (msg: M) => FM,
): FM | undefined {
  // cache the transformed msg to prevent rerenders
  return useMemo(() => {
    return msg ? formatter(msg) : undefined;
  }, [msg]);
}
