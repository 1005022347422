import { I18n } from "@meraki/core/i18n";
import { useDeleteVlan, useVlans } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import SectionListHeader from "~/go/components/SectionListHeader";
import VlansListRow from "~/go/rows/VlansListRow";
import { showAlert } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import MkiTable from "~/shared/components/MkiTable";
import useActions from "~/shared/hooks/redux/useActions";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import { Vlan } from "~/shared/types/Vlans";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "VlansList">;

export const VlansListScreen = ({ readOnly }: Props) => {
  setupAndroidLayoutAnimation();
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();

  const { data: vlans, isFetching, refetch } = useVlans({ networkId });
  const deleteVlan = useDeleteVlan();
  const queryClient = useQueryClient();

  const [isEditMode, setIsEditMode] = useState(false);
  const [reqPending, setReqPending] = useState(false);

  const actions = useActions();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: readOnly
        ? undefined
        : () => {
            if (!isEditMode) {
              return <EditButton onPress={() => setIsEditMode(true)} />;
            } else {
              return <DoneButton onPress={() => setIsEditMode(false)} />;
            }
          },
    });
  }, [isEditMode, navigation, readOnly]);

  const addNewVlan = () => navigation.navigate("ConfigureVlan", {});

  const onPressRow = useCallback(
    (vlan: Vlan) => {
      if (readOnly) {
        actions.setSelectedVlanId(parseInt(vlan.id));
        navigation.navigate("LocalAddressing");
      } else {
        navigation.navigate("ConfigureVlan", { vlan });
      }
    },
    [actions, navigation, readOnly],
  );

  const onDeleteRow = useCallback(
    async (vlanId: string) => {
      if (parseInt(vlanId) === 1) {
        showAlert(I18n.t("ERROR"), I18n.t("SUBNETS_VLANS.DELETE_ERROR"));
        return;
      }

      const parsedVlanId = parseInt(vlanId);
      const vlan = vlans?.find(({ id }) => id === parsedVlanId);
      if (!vlan) {
        return;
      }

      try {
        setReqPending(true);
        if (vlan.subnet) {
          await actions.removeVlanSecurity(vlan.subnet);
        }

        await deleteVlan.mutateAsync(
          { networkId, vlanId: parsedVlanId },
          {
            onSuccess: () => queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot }),
            onError: (error) => {
              showAlert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
            },
          },
        );
      } catch (error) {
        showAlert(I18n.t("ERROR"), error);
      }
      setReqPending(false);
    },
    [actions, deleteVlan, networkId, queryClient, vlans],
  );

  return (
    <MkiTable
      data={{ vlans: vlans ?? [] }}
      loading={reqPending || isFetching}
      onRefresh={refetch}
      renderRow={(rowData: Vlan) => (
        <VlansListRow
          vlan={rowData}
          isEditMode={isEditMode}
          onPressRow={onPressRow}
          onDelete={onDeleteRow}
        />
      )}
      renderSectionHeader={() =>
        readOnly ? null : (
          <SectionListHeader
            heading={I18n.t("SUBNETS_VLANS.TABLE_HEADER")}
            onPress={addNewVlan}
            withHorizontalMargin
          />
        )
      }
    />
  );
};

export default VlansListScreen;
