import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { clientsByMac, devicesByMacSelector, switchPortClientsSelector } from "~/selectors";
import { Client } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";

function withLLDPData<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return compose<P>(connect(mapStateToProps), lldpDataComponent)(WrappedComponent);
}

export type ReduxProps = {
  lldpClient: Client;
  lldpDevice: any;
  deviceClients: any;
};

type LLDPDataProps = {
  portNumber: number;
  serialNumber: string;
  lldpMac?: string;
};

type Props = ReduxProps & LLDPDataProps;

export function lldpDataComponent<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return class LLDPData extends PureComponent<Props> {
    render() {
      // @ts-expect-error TS(2322) FIXME: Type '{ lldpClient: { status?: string | null | und... Remove this comment to see the full error message
      return <WrappedComponent {...this.props} />;
    }
  };
}

function mapStateToProps(state: RootState, props: LLDPDataProps): ReduxProps {
  const { portNumber, serialNumber, lldpMac } = props;
  return {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    lldpClient: clientsByMac(state, props)[lldpMac],
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    lldpDevice: devicesByMacSelector(state, props)[lldpMac],
    deviceClients: switchPortClientsSelector(state, { portNumber, serialNumber }),
  };
}

export default withLLDPData;
