import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Card, Input } from "@meraki/magnetic/components";
import React, { useEffect, useState } from "react";

interface BottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
  initialURL: string;
  onSave: (url: string) => void;
  type: "add" | "update" | "unblock";
}

export const EditExcludeBottomSheet = ({
  bottomSheetRef,
  initialURL,
  onSave,
  type,
}: BottomSheetProps) => {
  const [stagedExcludedURL, setStagedExcludedURL] = useState<string>(initialURL);
  useEffect(() => {
    setStagedExcludedURL(initialURL);
  }, [initialURL]);

  const onPress = () => {
    onSave(stagedExcludedURL);
    bottomSheetRef.current?.dismiss();
  };

  const BUTTON_TEXT = {
    add: I18n.t("ADD"),
    update: I18n.t("UPDATE"),
    unblock: I18n.t("UNBLOCK"),
  };

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("UMBRELLA.EXCLUDE_URL.TITLE")}
        onCancelPress={() => {
          bottomSheetRef.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Card testID="CARD">
          <Input
            label={I18n.t("UMBRELLA.EXCLUDE_URL.NOTE")}
            onChangeText={setStagedExcludedURL}
            value={stagedExcludedURL}
            placeholder={I18n.t("BLOCK_CONTENT.BLOCK_WEBSITE_PLACEHOLDER")}
            testID="EXCLUDE_URL_INPUT"
          />
          <Button
            text={BUTTON_TEXT[type]}
            onPress={onPress}
            testID="EXCLUDE_BUTTON"
            disabled={!stagedExcludedURL}
          />
        </Card>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
};
