import { BorderRadius, Box } from "@meraki/magnetic/layout";
import React from "react";
import { Animated, Pressable } from "react-native";
import { Swipeable } from "react-native-gesture-handler";

import { Text } from "../../Text/Text";
import { ListActionType } from "../ListItem";

export type SwipeableActionsType = {
  leftActions?: ListActionType[];
  rightActions?: ListActionType[];
  section: "middle" | "top" | "bottom" | "both";
  children: JSX.Element;
};

type SwipeButton = {
  action: ListActionType;
  dragX: Animated.AnimatedInterpolation<string | number>;
  borderTopLeftRadius?: BorderRadius;
  borderBottomLeftRadius?: BorderRadius;
  borderTopRightRadius?: BorderRadius;
  borderBottomRightRadius?: BorderRadius;
};

const AnimatedSwipeButton = ({
  action,
  dragX,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
}: SwipeButton) => {
  const trans = dragX.interpolate({
    inputRange: [0, 50, 100],
    outputRange: [0, 0.5, 1],
  });

  return (
    <Animated.View
      style={{
        overflow: "hidden",
        transform: [{ translateX: trans }],
      }}
    >
      <Pressable
        style={{
          overflow: "hidden",
          flex: 1,
          height: "90%",
          width: "100%",
        }}
        onPress={action.onPress}
      >
        <Box
          flex={1}
          overflow="hidden"
          padding="sm"
          justifyContent="center"
          backgroundColor={action.color}
          borderTopLeftRadius={borderTopLeftRadius}
          borderBottomLeftRadius={borderBottomLeftRadius}
          borderTopRightRadius={borderTopRightRadius}
          borderBottomRightRadius={borderBottomRightRadius}
        >
          <Text color={action.textColor}>{action.text}</Text>
        </Box>
      </Pressable>
    </Animated.View>
  );
};

const getSwipeButtons = (
  actions: ListActionType[],
  side: "left" | "right",
  first: boolean,
  last: boolean,
  dragX: Animated.AnimatedInterpolation<string | number>,
): JSX.Element[] => {
  if (!actions || actions.length === 0) {
    return [];
  }

  const firstAction = actions[0];

  const ret: JSX.Element[] = [];

  if (firstAction) {
    const borderRadii: {
      borderTopLeftRadius?: BorderRadius;
      borderBottomLeftRadius?: BorderRadius;
      borderTopRightRadius?: BorderRadius;
      borderBottomRightRadius?: BorderRadius;
    } = {};
    switch (side) {
      case "left":
        if (first) {
          borderRadii.borderTopLeftRadius = "sm";
        }
        if (last) {
          borderRadii.borderBottomLeftRadius = "sm";
        }
        break;
      case "right":
        if (first) {
          borderRadii.borderTopRightRadius = "sm";
        }
        if (last) {
          borderRadii.borderBottomRightRadius = "sm";
        }
        break;
    }
    ret.push(<AnimatedSwipeButton action={firstAction} dragX={dragX} {...borderRadii} />);

    actions
      .slice(1)
      .map((action) => ret.push(<AnimatedSwipeButton action={action} dragX={dragX} />));
    return ret;
  }
  return ret;
};

export const SwipeableWithActions = ({
  leftActions,
  rightActions,
  section = "middle",
  children,
}: SwipeableActionsType) => {
  const getLeftButtons = (
    _: Animated.AnimatedInterpolation<string | number>,
    dragX: Animated.AnimatedInterpolation<string | number>,
  ) =>
    getSwipeButtons(
      leftActions ?? [],
      "left",
      section === "top" || section === "both",
      section === "bottom" || section === "both",
      dragX,
    );
  const getRightButtons = (
    _: Animated.AnimatedInterpolation<string | number>,
    dragX: Animated.AnimatedInterpolation<string | number>,
  ) =>
    getSwipeButtons(
      rightActions ?? [],
      "right",
      section === "top" || section === "both",
      section === "bottom" || section === "both",
      dragX,
    );

  return (
    <Swipeable renderLeftActions={getLeftButtons} renderRightActions={getRightButtons}>
      {children}
    </Swipeable>
  );
};
