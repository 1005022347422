import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { StyleSheet, Text, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { formatSummaryValueInThousands } from "~/lib/formatHelper";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";

export interface ComparisonQuickStatusBoxesProps {
  value: number;
  comparedValue?: number;
  units?: string;
  label: string;
  testID?: string;
}

export class ComparisonQuickStatusBoxes extends PureComponent<ComparisonQuickStatusBoxesProps> {
  renderValue = (value: number, units: string | undefined, color: string) => {
    const { formattedValue, formattedUnits } = formatSummaryValueInThousands(value, units);
    return (
      <Text style={styles.singleValueContainer}>
        <MkiText textStyle="bigNumber" screenStyles={{ color }}>
          {formattedValue}
        </MkiText>
        <MkiText textStyle="small" screenStyles={styles.unitsSize}>
          {formattedUnits}
        </MkiText>
      </Text>
    );
  };

  render() {
    const { value, comparedValue, units, label, testID } = this.props;
    const { theme } = useTheme.getState();

    const mainValueText = comparedValue
      ? this.renderValue(value, units, MkiColors.comparePurple)
      : this.renderValue(value, units, themeColors(theme).text?.default?.color);
    const comparedValueText = comparedValue
      ? this.renderValue(comparedValue, units, MkiColors.compareTeal)
      : null;

    return (
      <View style={styles.boxesContainer} testID={testID}>
        <View style={styles.valuesContainer}>
          {mainValueText}
          {comparedValueText}
        </View>
        <MkiText screenStyles={styles.subText}>{label}</MkiText>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subText: {
    fontSize: normalizedFontSize(11),
    color: MkiColors.secondaryTextColor,
  },
  valuesContainer: {
    flexDirection: "row",
  },
  singleValueContainer: {
    flexDirection: "row",
    alignItems: "baseline",
    marginHorizontal: SPACING.small,
  },
  unitsSize: {
    fontWeight: "300",
  },
  boxesContainer: {
    alignItems: "center",
    marginVertical: SPACING.default,
  },
});

export default ComparisonQuickStatusBoxes;
