import { I18n } from "@meraki/core/i18n";
import { DeviceIllustration } from "@meraki/go/device-illustration";
import { BottomSheet, BottomSheetMethods, Button, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useCreateBlinkLED } from "@meraki/shared/api";
import { getIndoorOrOutdoor, getProductType } from "@meraki/shared/devices";
import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import { Alert } from "react-native";
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

const getDescription = (model: string) => {
  switch (getProductType(model)) {
    case "appliance":
      return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.SECURITY_GATEWAY");
    case "switch":
      return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.SWITCH");
    case "wireless":
      return getIndoorOrOutdoor(model) === "indoor"
        ? I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.INDOOR")
        : I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.OUTDOOR");
    default:
      return I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.DESCRIPTION.DEVICE_BLINK_DEFAULT");
  }
};

const DEFAULT_DURATION_SECONDS = 20;

interface BlinkLedBottomSheetProps {
  serial: string;
  model: string;
}

export const BlinkLedBottomSheet = forwardRef(function BlinkLedBottomSheet(
  { serial, model }: BlinkLedBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const { mutate, isLoading, isIdle } = useCreateBlinkLED();

  const [isBlinking, setIsBlinking] = useState<boolean>(false);
  const [showTroubleshootMessage, setShowTroubleshootMessage] = useState<boolean>(false);
  const opacity = useSharedValue(1);

  const blinkDeviceLEDs = useCallback(() => {
    mutate(
      { serial },
      {
        onError() {
          Alert.alert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        },
        onSuccess() {
          setIsBlinking(true);
          opacity.value = withRepeat(
            withTiming(0, { duration: 1000, easing: Easing.ease }),
            DEFAULT_DURATION_SECONDS,
            true,
            (finished?: boolean) => {
              if (finished) {
                runOnJS(setIsBlinking)(false);
                runOnJS(setShowTroubleshootMessage)(true);
                opacity.value = 1;
              }
            },
          );
        },
      },
    );
  }, [mutate, opacity, serial]);

  const onDismiss = () => {
    setIsBlinking(false);
    setShowTroubleshootMessage(false);
  };

  const animatedStyles = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);

  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref} onDismiss={onDismiss}>
      <BottomSheet.Header
        title={I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.TITLE")}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => {
          if (typeof ref !== "function") {
            ref?.current?.dismiss();
            onDismiss();
          }
        }}
      />
      <BottomSheet.Content>
        <Box gap="sm" alignItems="center">
          <Animated.View style={animatedStyles}>
            <DeviceIllustration model={model} size={"medium"} />
          </Animated.View>
          <Text>{getDescription(model)}</Text>
          {showTroubleshootMessage && (
            <Text color="negative.text.base">
              {I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.TROUBLESHOOTING.BLINK_TEXT_GOv3")}
            </Text>
          )}
          <Button
            text={
              isIdle
                ? I18n.t("LIVE_TOOLS.BUTTON.START")
                : isBlinking
                  ? I18n.t("LIVE_TOOLS.BLINK_LED_SCREEN.BLINKING")
                  : I18n.t("LIVE_TOOLS.BUTTON.RUN_AGAIN")
            }
            loading={isLoading}
            disabled={isLoading || isBlinking}
            kind="secondary"
            onPress={() => {
              blinkDeviceLEDs();
            }}
            testID="BLINKING_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
});
