import { I18n } from "@meraki/core/i18n";
import {
  AddLicenseError,
  useAddGoLicense,
  useAssignGoLicense,
  useGetLicenses,
} from "@meraki/shared/api";
import { addSerialHyphens } from "@meraki/shared/devices";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BOX_BORDER_RADIUS, SPACING } from "~/constants/MkiConstants";
import { useNetworkUmbrellaLicenses } from "~/go/hooks/useFilteredLicenses";
import { replaceAll } from "~/lib/StringUtils";
import { currentOrganization, gxDeviceSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import Touchable from "~/shared/components/Touchable";
import GeneralStatus from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { Alert } from "~/shared/types/AlertTypes";

function AddLicenseRow() {
  const queryClient = useQueryClient();
  const organizationId = useAppSelector(currentOrganization).id;
  const networkId = useCurrentNetworkId();
  const { data: licenses } = useNetworkUmbrellaLicenses(organizationId, networkId);
  const gxDevice = useAppSelector(gxDeviceSelector);
  const addLicense = useAddGoLicense();
  const assignLicense = useAssignGoLicense();
  const hasAssignedLicense =
    gxDevice != null && !!licenses?.some((license) => license.deviceSerial === gxDevice?.serial);

  const [showInputModal, setShowInputModal] = useState(false);
  const [stagedLicenseKey, setStagedLicenseKey] = useState("");
  const [licenseAlert, setLicenseAlert] = useState<Alert>();

  const closeInputModal = () => setShowInputModal(false);

  const changeLicenseKey = useCallback(
    (newKey: string) => {
      if (newKey.length > stagedLicenseKey.length) {
        newKey = addSerialHyphens(newKey);
      }
      setStagedLicenseKey(newKey);
    },
    [stagedLicenseKey.length],
  );

  const handleSuccess = () => {
    setLicenseAlert({
      message: I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SUCCESS_MESSAGE"),
      status: GeneralStatus.good,
      primaryButtonText: I18n.t("CLOSE"),
      primaryButtonPress: closeInputModal,
    });
  };

  const handleError = (errorMessage: string) => {
    setLicenseAlert({
      message: errorMessage ? errorMessage : I18n.t("SERVER_ERROR_TEXT"),
      status: GeneralStatus.bad,
    });
  };

  const addSubscription = () => {
    let errorMessage: string | undefined;
    const mutateOptions = {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
        handleSuccess();
      },
      onError: (error: AddLicenseError) => {
        errorMessage = error.error;
      },
    };
    addLicense.mutate({ key: stagedLicenseKey, GXDeviceSerial: gxDevice?.serial }, mutateOptions);
    const key = replaceAll(stagedLicenseKey, "-", "");
    const targetLicense = licenses?.find(({ licenseKey }) => licenseKey === key);
    if (hasAssignedLicense && addLicense.isSuccess && targetLicense) {
      assignLicense.mutate(
        {
          licenseId: targetLicense.id,
          deviceSerial: gxDevice?.id,
        },
        mutateOptions,
      );
    }
    if (errorMessage) {
      handleError(errorMessage);
    }
  };

  return (
    <View style={styles.row}>
      <Touchable testID="ADD_LICENSE_ROW" onPress={() => setShowInputModal(true)}>
        <View style={styles.addButton}>
          <MerakiIcon
            testID="ADD_LICENSE_ROW.ADD_ICON"
            name="add"
            size="s"
            color={MkiColors.secondaryButton}
          />
        </View>
      </Touchable>
      <InputModal
        value={stagedLicenseKey}
        title={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
        subtitle={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.ENTER_KEY")}
        visible={showInputModal}
        primaryButtonText={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MODAL_TITLE")}
        placeholder={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.PLACEHOLDER")}
        onChangeText={changeLicenseKey}
        onPrimaryPress={addSubscription}
        onExit={closeInputModal}
        alert={licenseAlert}
        autoCapitalize="characters"
        loading={addLicense.isLoading || assignLicense.isLoading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flex: 1,
    justifyContent: "center",
  },
  addButton: {
    borderRadius: BOX_BORDER_RADIUS,
    borderWidth: 2,
    borderStyle: "dashed",
    justifyContent: "center",
    alignItems: "center",
    borderColor: MkiColors.borderColor,
    paddingVertical: SPACING.default,
  },
});

export default AddLicenseRow;
