import { PureComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ProductIcon from "~/go/components/ProductIcon";
import { getDeviceLiveStatus } from "~/lib/DeviceUtils";
import { determineHardwareDetails, determineHardwareDetailsByStatus } from "~/lib/HardwareUtils";
import { withNodeSubscription } from "~/lib/liveBroker";
import { sizeSelect } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import GoStatus, { StatusType } from "~/shared/constants/Status";
import ListRow from "~/shared/rows/ListRow";
import Device_DeprecatedType from "~/shared/types/Device";

type NodeStatusProps = {
  isLive: boolean;
};

type DeviceListRowProps = {
  deviceId: string;
  device: Device_DeprecatedType;
  model: string;
  isHardwired?: boolean;
  onPress?: () => void;
  status: StatusType;
  selected?: boolean;
  onClose?: () => void;
  showDisclosure?: boolean;
  testID?: string;
  accessoryTestID?: string;
  screenStyles?: StyleProp<ViewStyle>;
  children: React.ReactElement;
};

type Props = NodeStatusProps & DeviceListRowProps;

export class DeviceListRowComponent extends PureComponent<Props> {
  static defaultProps = {
    isHardwired: false,
    onPress: undefined,
    status: GoStatus.bad,
    selected: false,
    onClose: undefined,
    showDisclosure: false,
    testID: undefined,
  };

  onPressHandler = () => {
    const { isLive, onPress } = this.props;
    if (onPress) {
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      onPress({ isLive });
    }
  };

  accessory() {
    const { accessoryTestID, onClose, showDisclosure } = this.props;

    if (onClose) {
      return (
        <MerakiIcon
          name="close"
          size="s"
          onPress={onClose}
          containerStyle={styles.exitIcon}
          testID={accessoryTestID}
        />
      );
    }

    if (showDisclosure) {
      return <MerakiIcon name="chevron-right" size="s" color={MkiColors.secondaryButton} />;
    }

    return null;
  }

  render() {
    const { device, status, model, children, isLive, selected, testID, onPress, screenStyles } =
      this.props;

    // if live broker is not returning the real time status of the node, let's just
    // use the status returned from postgres even if it's a little outdated
    const deviceStatus = getDeviceLiveStatus(isLive, status);

    const description = device.status
      ? determineHardwareDetails(device).description
      : determineHardwareDetailsByStatus(status, device).description;

    const statusIcon = <StatusIcon status={deviceStatus} screenStyles={styles.statusIcon} />;

    const combinedDeviceStatusIcon = (
      <View style={styles.iconsContainer}>
        <ProductIcon status={deviceStatus} model={model} />
        {statusIcon}
      </View>
    );

    return (
      <ListRow
        icon={combinedDeviceStatusIcon}
        onPress={onPress ? this.onPressHandler : undefined}
        rowStyles={[selected ? styles.highlightedRow : styles.row, screenStyles]}
        subtitle1={description}
        // @ts-expect-error TS(2322) FIXME: Type 'GoColors | null' is not assignable to type '... Remove this comment to see the full error message
        underlayColor={selected ? MkiColors.primaryButtonLighter : null}
        accessory={this.accessory()}
        touchableStyles={styles.touchableStyle}
        testID={testID}
      >
        {children}
      </ListRow>
    );
  }
}

const styles = StyleSheet.create({
  exitIcon: {
    marginRight: 0,
  },
  highlightedRow: {
    backgroundColor: MkiColors.primaryButtonLight,
    paddingVertical: SPACING.default,
    paddingHorizontal: SPACING.default,
  },
  row: {
    paddingVertical: SPACING.default,
    paddingHorizontal: SPACING.default,
  },
  statusIcon: {
    marginRight: 10,
    marginLeft: sizeSelect({
      default: SPACING.default,
      small: 14,
    }),
    width: 8,
    height: 8,
  },
  touchableStyle: {
    flex: 1,
  },
  iconsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
  },
});

export default withNodeSubscription({
  type: "NodeStatus",
  handler: ({ data }: any) => ({ isLive: data.live }),
  deviceId: (ownProps: DeviceListRowProps) => ownProps.deviceId,
})(DeviceListRowComponent);
