import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import I18n from "~/i18n/i18n";
import { isPassingLatency, isPassingLossRate } from "~/lib/PingUtils";
import MkiText from "~/shared/components/MkiText";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import { GoStatus } from "~/shared/constants/Status";

type Props = {
  averageLatency: number;
  lossRate: number;
  hideTitle?: boolean;
};

const PingResultStats = memo(function PingResultStats(props: Props) {
  const { averageLatency, hideTitle = false, lossRate } = props;

  const title = hideTitle ? null : (
    <MkiText textStyle="subheading" screenStyles={styles.resultsHeader}>
      {I18n.t("LIVE_TOOLS.TEST_RESULTS")}
    </MkiText>
  );

  return (
    <View>
      {title}
      <View style={styles.stats}>
        <QuickStatusBox
          value={Math.round(averageLatency)}
          subText={I18n.t("AVERAGE_LATENCY")}
          status={isPassingLatency(averageLatency) ? null : GoStatus.bad}
          category="ms"
        />
        <QuickStatusBox
          value={Math.round(lossRate)}
          subText={I18n.t("LOSS_RATE")}
          status={isPassingLossRate(lossRate) ? null : GoStatus.bad}
          category="%"
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  resultsHeader: {
    color: MkiColors.primaryButton,
  },
  stats: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default PingResultStats;
