import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useLayoutEffect } from "react";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { OnboardingStackProps } from "~/go/navigation/Types";
import LEDColorLegend from "~/go/screens/LEDColorLegend";
import ClientVPNInstructionScreen from "~/go/screens/onboardingFullstack/clientVPN/ClientVPNInstructionScreen";
import ClientVPNSetupScreen from "~/go/screens/onboardingFullstack/clientVPN/ClientVPNSetupScreen";
import ConnectAndUpgradeScreen from "~/go/screens/onboardingFullstack/ConnectAndUpgradeScreen";
import ConnectAndUpgradeStatusScreen from "~/go/screens/onboardingFullstack/ConnectAndUpgradeStatusScreen";
import GetStartedScreen from "~/go/screens/onboardingFullstack/GetStartedScreen";
import OnboardingAlertsScreen from "~/go/screens/onboardingFullstack/OnboardingAlertsScreen";
import OnboardingNotificationsRequestScreen from "~/go/screens/onboardingFullstack/OnboardingNotificationsRequestScreen";
import PlugInScreen from "~/go/screens/onboardingFullstack/PlugInScreen";
import UmbrellaExplainerScreen from "~/go/screens/onboardingFullstack/umbrellaExplainer/UmbrellaExplainerScreen";
import WiFiSetupCreateScreen from "~/go/screens/onboardingFullstack/wiFiSetup/WiFiSetupCreateScreen";
import WiFiSetupCreateSuccessScreen from "~/go/screens/onboardingFullstack/wiFiSetup/WiFiSetupCreateSuccessScreen";
import { getOnboardingScreen } from "~/lib/OnboardingFullstackUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { onboardingStage } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

import SSIDShareScreen from "../screens/ssids/SSIDShareScreen";
import { addHardwareScreens } from "./AddHardwareScreens";
import { onboardingSetupCompleteScreen } from "./OnboardingScreens";
import { useDefaultScreenOptions } from "./Options";
import { twoFactorScreens } from "./TwoFactorScreens";
import { umbrellaScreens } from "./UmbrellaScreens";

const ForwardedLEDColorLegend =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "LEDColorLegend">
  >()(LEDColorLegend);
const ForwardedConnectAndUpgradeScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "ConnectAndUpgrade">
  >()(ConnectAndUpgradeScreen);
const ForwardedConnectAndUpgradeStatusScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<OnboardingStackProps, "ConnectAndUpgradeStatus">
>()(ConnectAndUpgradeStatusScreen);
const ForwardedGetStartedScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<OnboardingStackProps, "GetStarted">>()(
    GetStartedScreen,
  );
const ForwardedPlugInScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<OnboardingStackProps, "PlugIn">>()(
    PlugInScreen,
  );
const ForwardedOnboardingAlertsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "OnboardingAlerts">
  >()(OnboardingAlertsScreen);
const ForwardedUmbrellaExplainerScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "UmbrellaExplainer">
  >()(UmbrellaExplainerScreen);
const ForwardedWiFiSetupCreateScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "WiFiSetupCreate">
  >()(WiFiSetupCreateScreen);
const ForwardedWiFiSetupCreateSuccessScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<OnboardingStackProps, "WiFiSetupCreateSuccess">
>()(WiFiSetupCreateSuccessScreen);
const ForwardedClientVPNSetupScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<OnboardingStackProps, "ClientVPNSetup">
  >()(ClientVPNSetupScreen);
const ForwardedClientVPNInstructionScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<OnboardingStackProps, "ClientVPNInstruction">
>()(ClientVPNInstructionScreen);
const ForwardedOnboardingNotificationsRequestScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<OnboardingStackProps, "OnboardingNotificationsRequest">
>()(OnboardingNotificationsRequestScreen);
const ForwardedSSIDShareScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<OnboardingStackProps, "SSIDShare">>()(
    SSIDShareScreen,
  );

const Stack = createNativeStackNavigator<OnboardingStackProps>();

const OnboardingStack = () => {
  useLayoutEffect(() => {
    if (isWeb()) {
      history.replaceState(null, "", "#onboarding");
    }
  }, []);

  const screen = getOnboardingScreen(useAppSelector(onboardingStage));
  const defaultScreenOptions = useDefaultScreenOptions();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions} initialRouteName={screen}>
      <Stack.Screen
        name="GetStarted"
        component={ForwardedGetStartedScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.GET_STARTED.TITLE"),
        }}
      />
      {addHardwareScreens(Stack)}
      <Stack.Screen
        name="PlugIn"
        component={ForwardedPlugInScreen}
        options={{ headerTitle: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.TITLE") }}
      />
      <Stack.Screen
        name="OnboardingNotificationsRequest"
        component={ForwardedOnboardingNotificationsRequestScreen}
        options={{ headerTitle: I18n.t("ONBOARDING_FULLSTACK.NOTIFICATIONS_REQUEST.TITLE") }}
      />
      <Stack.Screen
        name="ConnectAndUpgrade"
        component={ForwardedConnectAndUpgradeScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE.TITLE"),
        }}
      />
      <Stack.Screen
        name="LEDColorLegend"
        component={ForwardedLEDColorLegend}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("LEDS.TITLE") }}
      />
      {/* {verify} */}
      <Stack.Screen
        name="ConnectAndUpgradeStatus"
        component={ForwardedConnectAndUpgradeStatusScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE_STATUS.TITLE"),
        }}
      />
      <Stack.Screen
        name="UmbrellaExplainer"
        component={ForwardedUmbrellaExplainerScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.UMBRELLA_EXPLAINER.TITLE"),
        }}
      />
      {umbrellaScreens(Stack)}
      <Stack.Screen
        name="OnboardingAlerts"
        component={ForwardedOnboardingAlertsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SETTINGS.NOTIFICATIONS") }}
      />
      {/* {verify} */}
      {twoFactorScreens(Stack)}
      {/* {verify} */}
      <Stack.Screen
        name="ClientVPNSetup"
        component={ForwardedClientVPNSetupScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_SETUP.TITLE"),
        }}
      />
      <Stack.Screen
        name="ClientVPNInstruction"
        component={ForwardedClientVPNInstructionScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_INSTRUCTION.TITLE"),
        }}
      />
      <Stack.Screen
        name="WiFiSetupCreate"
        component={ForwardedWiFiSetupCreateScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE.TITLE"),
        }}
      />
      <Stack.Screen
        name="SSIDShare"
        component={ForwardedSSIDShareScreen}
        options={{
          presentation: "fullScreenModal",
        }}
      />
      <Stack.Screen
        name="WiFiSetupCreateSuccess"
        component={ForwardedWiFiSetupCreateSuccessScreen}
        options={{
          headerBackVisible: false,
          presentation: "fullScreenModal",
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE_SUCCESS.TITLE"),
        }}
      />
      {/* {verify} */}
      {onboardingSetupCompleteScreen(Stack)}
    </Stack.Navigator>
  );
};

export default OnboardingStack;
