import * as errorMonitor from "@meraki/core/errors";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'he'.... Remove this comment to see the full error message
import he from "he";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { SPLASH_PAGE_ID, SPLASH_TIMEOUT_OPTIONS } from "~/constants/Splash";
import SplashConfigureRows from "~/go/components/SplashConfigureRows";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import LiveEditInputRow, { LiveEditInputRowProps } from "~/go/rows/LiveEditInputRow";
import { SPLASH_FEATURE_ID, SplashCustomStates, SplashStates } from "~/go/types/SplashTypes";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showSaveWarning } from "~/lib/AlertUtils";
import {
  exitModal,
  getSplashPage,
  getWalledGardenRanges,
  isChangedCustomURL,
  openModal,
  updateCustomStates,
  updateStagedStates,
  validateCustomURLOnSubmit,
  validateRedirectURLOnSubmit,
} from "~/lib/SplashUtils";
import { stringIsURL } from "~/lib/stringHelper";
import {
  currentNetworkState,
  currentShardIdState,
  slimSsidsByIdSelector,
  splashSettingsOnSSIDsSelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import InputModal from "~/shared/components/InputModal";
import SummaryList from "~/shared/components/SummaryList";
import { StatusType } from "~/shared/constants/Status";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { SplashMethods, SplashSettings } from "~/shared/types/SplashSettings";
import { BasicActions, basicMapDispatchToProps } from "~/store";

// TODO: update new copy for this page once we get it (mainly for the "website" text)

type ReduxProps = {
  networkId: string;
  shardId: number;
  splashSettings: SplashSettings;
  ssid: SSID;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SplashCustomURLSetup"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

export interface SplashCustomURLSetupScreenState extends SplashStates {
  customURLAlert?: { message: string; status: StatusType };
  isCustomURLValid: boolean;
}

export class SplashCustomURLSetupScreenComponent extends PureComponent<
  Props,
  SplashCustomURLSetupScreenState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModalStates: {
        [SPLASH_FEATURE_ID.redirectURL]: false,
        [SPLASH_FEATURE_ID.timeout]: false,
        [SPLASH_FEATURE_ID.customURL]: false,
      },
      customStates: {
        // fields applied by input, used for saving
        [SPLASH_FEATURE_ID.redirectURL]: undefined,
        [SPLASH_FEATURE_ID.redirectURLEnabled]: undefined,
        [SPLASH_FEATURE_ID.timeout]: SPLASH_TIMEOUT_OPTIONS[0],
        [SPLASH_FEATURE_ID.customURL]: undefined,
        [SPLASH_FEATURE_ID.walledGardenEnabled]: undefined,
        [SPLASH_FEATURE_ID.walledGardenRanges]: undefined,
      },
      stagedStates: {
        // fields returned by input, may or may not be applied
        // note that some things don't need staged states, as they can be applied to customState
        // immediately upon change
        [SPLASH_FEATURE_ID.redirectURL]: undefined,
        [SPLASH_FEATURE_ID.customURL]: undefined,
        [SPLASH_FEATURE_ID.walledGardenRanges]: undefined,
      },
      customURLAlert: undefined,
      isCustomURLValid: false,
      redirectURLAlert: undefined,
    };

    this.setNavOptions(false);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { splashSettings } = this.props;
    const { customStates, stagedStates } = this.state;

    this.setNavOptions(isChangedCustomURL(splashSettings, stagedStates, customStates));
  }

  setNavOptions(saveEnabled: boolean) {
    const { navigation, splashSettings } = this.props;
    const { stagedStates, customStates } = this.state;

    const onCancel = () => {
      if (isChangedCustomURL(splashSettings, stagedStates, customStates)) {
        this.cancelSplashPage();
      } else {
        navigation.goBack();
      }
    };
    navigation.setOptions({
      headerLeft: () =>
        saveEnabled ? <CancelButton onPress={onCancel} /> : <CloseButton onPress={onCancel} />,
      headerRight: () => <SaveButton onPress={this.saveSplashScreen} disabled={!saveEnabled} />,
    });
  }

  async getData() {
    const { actions, handleError, setReqPending, splashSettings, ssid, ssidNumber } = this.props;

    setReqPending(true);
    try {
      const promiseOrUndefined = actions.getSSIDSplashSettings(ssidNumber);
      if (promiseOrUndefined) {
        await promiseOrUndefined;
      }
    } catch (e) {
      if (typeof e === "string") {
        handleError(e);
      }
    }
    setReqPending(false);

    const timeoutInMinutes = splashSettings.splashTimeout || 0;
    const walledGardenRanges = getWalledGardenRanges(ssid.walledGardenRanges);
    this.setState({
      stagedStates: {
        [SPLASH_FEATURE_ID.redirectURL]: splashSettings.redirectUrl,
        [SPLASH_FEATURE_ID.customURL]: splashSettings.splashUrl,
        [SPLASH_FEATURE_ID.walledGardenRanges]: walledGardenRanges,
      },
      customStates: {
        [SPLASH_FEATURE_ID.redirectURL]: splashSettings.redirectUrl,
        [SPLASH_FEATURE_ID.redirectURLEnabled]: splashSettings.useRedirectUrl,
        [SPLASH_FEATURE_ID.customURL]: splashSettings.splashUrl,
        [SPLASH_FEATURE_ID.timeout]: timeoutInMinutes * 60,
        [SPLASH_FEATURE_ID.walledGardenEnabled]: true,
        [SPLASH_FEATURE_ID.walledGardenRanges]: walledGardenRanges,
      },
      isCustomURLValid: stringIsURL(splashSettings.splashUrl),
    });
  }

  updateFrequency = (value: number) => {
    this.setState(updateCustomStates(SPLASH_FEATURE_ID.timeout, value));
    this.setState(exitModal(SPLASH_FEATURE_ID.timeout));
  };

  cancelSplashPage = () => {
    const { navigation } = this.props;
    showSaveWarning(() => this.saveSplashScreen(), navigation.goBack);
  };

  saveSplashScreen = async () => {
    const {
      actions,
      handleError,
      networkId,
      setReqPending,
      ssidNumber,
      navigation,
      splashSettings,
    } = this.props;
    const { customStates } = this.state;

    setReqPending(true);

    try {
      const newSplashSettings: Partial<SplashSettings> = {
        splashUrl: customStates[SPLASH_FEATURE_ID.customURL],
        useSplashUrl: true,
        splashTimeout: customStates[SPLASH_FEATURE_ID.timeout] / 60,
        useRedirectUrl: customStates[SPLASH_FEATURE_ID.redirectURLEnabled],
      };

      if (newSplashSettings.useRedirectUrl) {
        newSplashSettings.redirectUrl = customStates[SPLASH_FEATURE_ID.redirectURL];
      }

      const newSsid: Partial<SSID> = { number: ssidNumber };
      if (getSplashPage(splashSettings) !== SPLASH_PAGE_ID.customURL) {
        newSsid.splashPage = SplashMethods.clickThrough;
      }

      const walledGardenRanges =
        customStates[SPLASH_FEATURE_ID.walledGardenRanges]?.map(({ range }) => range) || [];
      if (walledGardenRanges.length > 0) {
        newSsid.walledGardenRanges = walledGardenRanges;
        newSsid.walledGardenEnabled = customStates[SPLASH_FEATURE_ID.walledGardenEnabled];
      }

      // if there is more properties than just number, send setSsid request
      if (Object.values(newSsid).length > 1) {
        await actions.setSsid(networkId, newSsid);
      }

      await actions.updateSSIDSplashSettings(ssidNumber, newSplashSettings);

      navigation.goBack();
    } catch (e) {
      if (typeof e === "string") {
        handleError(e);
      }
    }
    setReqPending(false);
  };

  renderCustomURLRow() {
    const { customStates } = this.state;

    const onPressURL = () => {
      this.setState({ customURLAlert: undefined });
      this.setState(
        updateStagedStates(
          SPLASH_FEATURE_ID.customURL,
          he.decode(customStates[SPLASH_FEATURE_ID.customURL] || ""),
        ),
      );
      this.setState(openModal(SPLASH_FEATURE_ID.customURL));
    };

    const rows = [
      {
        label: I18n.t("SPLASH_CONFIG.CONFIGURE.CUSTOM_URL.ROW_TITLE"),
        value:
          customStates[SPLASH_FEATURE_ID.customURL] != null
            ? customStates[SPLASH_FEATURE_ID.customURL]
            : I18n.t("SPLASH_CONFIG.NONE_OPT"),
        onPress: onPressURL,
      },
    ];

    return (
      <SummaryList<LiveEditInputRowProps>
        contentRows={rows}
        customRenderRow={LiveEditInputRow}
        disableBottomBorder
        hasSeparators
      />
    );
  }

  onPressRedirectURL = (customStates: SplashCustomStates) => {
    const redirectURL = customStates[SPLASH_FEATURE_ID.redirectURL];
    this.setState({ redirectURLAlert: undefined });
    this.setState(
      updateStagedStates(
        SPLASH_FEATURE_ID.redirectURL,
        redirectURL ? he.decode(redirectURL) : redirectURL,
      ),
    );
    this.setState(openModal(SPLASH_FEATURE_ID.redirectURL));
  };

  onToggleRedirectURL = (customStates: SplashCustomStates) => {
    this.setState(
      updateCustomStates(
        SPLASH_FEATURE_ID.redirectURLEnabled,
        !customStates[SPLASH_FEATURE_ID.redirectURLEnabled],
      ),
    );
  };

  onPressWalledGarden = (ssidName: string, customStates: SplashCustomStates) => {
    const { navigation } = this.props;

    navigation.navigate("SplashWalledGarden", {
      ssidName,
      currentRanges: customStates[SPLASH_FEATURE_ID.walledGardenRanges],
      updateWalledGardenRanges: (ranges) =>
        this.setState(updateCustomStates(SPLASH_FEATURE_ID.walledGardenRanges, ranges)),
    });
  };

  render() {
    const { ssid } = this.props;
    const {
      customStates,
      stagedStates,
      showModalStates,
      redirectURLAlert,
      customURLAlert,
      isCustomURLValid,
    } = this.state;

    const frequencyProps = {
      visible: showModalStates[SPLASH_FEATURE_ID.timeout] || false,
      title: I18n.t("SPLASH_CONFIG.CONFIGURE.TIMEOUT_FREQUENCY.PICKER_TITLE"),
      subtitle: I18n.t("SPLASH_CONFIG.CONFIGURE.TIMEOUT_FREQUENCY.ROW_SUBTITLE"),
      selectedValue: customStates[SPLASH_FEATURE_ID.timeout] || 0,
      onValueSelect: (value: string) => this.updateFrequency(parseInt(value)),
    };

    return (
      <FullScreenContainerView withScroll>
        <InputModal
          value={stagedStates[SPLASH_FEATURE_ID.redirectURL] || ""}
          title={I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.EDIT_MESSAGE_TITLE")}
          subtitle={I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.EDIT_MESSAGE_SUBTITLE")}
          visible={showModalStates[SPLASH_FEATURE_ID.redirectURL] || false}
          onChangeText={(redirectURL: string) =>
            this.setState(updateStagedStates(SPLASH_FEATURE_ID.redirectURL, redirectURL))
          }
          alert={redirectURLAlert}
          primaryButtonText={I18n.t("SPLASH_CONFIG.PROMPT_BUTTON")}
          onSubmitEditing={() => this.setState(validateRedirectURLOnSubmit)}
          onPrimaryPress={() => this.setState(validateRedirectURLOnSubmit)}
          onExit={() => this.setState(exitModal(SPLASH_FEATURE_ID.redirectURL))}
          autoCapitalize="none"
        />
        <InputModal
          value={stagedStates[SPLASH_FEATURE_ID.customURL] || ""}
          title={I18n.t("SPLASH_CONFIG.CONFIGURE.CUSTOM_URL.EDIT_MESSAGE_TITLE")}
          subtitle={I18n.t("SPLASH_CONFIG.CONFIGURE.CUSTOM_URL.EDIT_MESSAGE_SUBTITLE")}
          visible={showModalStates[SPLASH_FEATURE_ID.customURL] || false}
          onChangeText={(customURL: string) =>
            this.setState(updateStagedStates(SPLASH_FEATURE_ID.customURL, customURL))
          }
          alert={customURLAlert}
          primaryButtonText={I18n.t("SPLASH_CONFIG.PROMPT_BUTTON")}
          onSubmitEditing={() => this.setState(validateCustomURLOnSubmit)}
          onPrimaryPress={() => this.setState(validateCustomURLOnSubmit)}
          onExit={() => this.setState(exitModal(SPLASH_FEATURE_ID.customURL))}
          autoCapitalize="none"
        />
        {this.renderCustomURLRow()}
        <View style={styles.borderView} />
        <SplashConfigureRows
          isCustomURLValid={isCustomURLValid}
          onPressRedirectURL={() => this.onPressRedirectURL(customStates)}
          onPressWalledGarden={() => this.onPressWalledGarden(ssid.name || "", customStates)}
          customStates={customStates}
          frequencyProps={frequencyProps}
          onPressFrequency={() => this.setState(openModal(SPLASH_FEATURE_ID.timeout))}
          onExitFrequency={() => this.setState(exitModal(SPLASH_FEATURE_ID.timeout))}
          splashPage={SPLASH_PAGE_ID.customURL}
        />
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SplashCustomURLSetup"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SplashCustomURLSetupScreen",
      currentNetworkState(state),
    ),
    shardId: errorMonitor.notifyNonOptional(
      "param 'shardId' undefined for SplashCustomURLSetupScreen",
      currentShardIdState(state),
    ),
    splashSettings: splashSettingsOnSSIDsSelector(state)[props.ssidNumber],
    ssid: slimSsidsByIdSelector(state)[props.ssidNumber],
  };
}

const styles = StyleSheet.create({
  borderView: {
    marginHorizontal: SPACING.default,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(SplashCustomURLSetupScreenComponent);
