import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { CaseInteraction } from "~/api/schemas/SupportCaseInfo";
import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING, SUPPORT_CASE_DEFAULT_NAME } from "~/constants/MkiConstants";
import { fromSupportCaseTsToDate, shorthandTimeFromNow } from "~/lib/timeHelper";
import { MkiText } from "~/shared/components/MkiText";
import { SupportCaseAbstractedIdentifiers } from "~/shared/types/SupportCaseTypes";

interface SupportCaseMessageProps {
  possibleUserNames: string[];
  comment: CaseInteraction;
  testID: string;
}

const SupportCaseMessage = (props: SupportCaseMessageProps) => {
  const { possibleUserNames, comment, testID } = props;
  const { commentBody, createdDate, createdByName, fromName } = comment;

  // @ts-expect-error TS(2345): Argument of type 'number | Date' is not assignable... Remove this comment to see the full error message
  const timeSinceLastInteraction = shorthandTimeFromNow(fromSupportCaseTsToDate(createdDate));
  const senderName = fromName ?? createdByName;

  const abstractedSender =
    senderName && [...possibleUserNames, SUPPORT_CASE_DEFAULT_NAME].includes(senderName)
      ? SupportCaseAbstractedIdentifiers.you
      : SupportCaseAbstractedIdentifiers.support;
  const messageStyles =
    abstractedSender === SupportCaseAbstractedIdentifiers.you
      ? styles.customerMessage
      : styles.supportMessage;
  const messageDirection =
    abstractedSender === SupportCaseAbstractedIdentifiers.you ? styles.messageRowDirection : {};
  const textColor =
    abstractedSender === SupportCaseAbstractedIdentifiers.you ? { color: MkiColors.whiteText } : {};

  return (
    <View style={styles.verticleContainer} testID={testID}>
      <View style={[styles.messageRowContainer, messageDirection]}>
        <View style={[styles.messageContainer, messageStyles]}>
          <MkiText textStyle="default" screenStyles={textColor}>
            {commentBody}
          </MkiText>
        </View>
      </View>
      <View style={[styles.subtextContainer, messageDirection]}>
        <MkiText textStyle="smallSecondary">
          {I18n.t(`SUPPORT_CENTER.MESSAGE.${abstractedSender}`)}
          {I18n.t("SUPPORT_CENTER.TIME_SINCE", { time: timeSinceLastInteraction })}
        </MkiText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  verticleContainer: {
    flexDirection: "column",
  },
  subtextContainer: {
    flexDirection: "row",
    paddingHorizontal: SPACING.medium,
  },
  messageRowContainer: {
    flex: 1,
    flexDirection: "row",
  },
  messageRowDirection: {
    justifyContent: "flex-end",
  },
  messageContainer: {
    flexDirection: "row",
    paddingVertical: SPACING.medium,
    paddingHorizontal: SPACING.medium,
    marginTop: SPACING.default,
  },
  supportMessage: {
    borderTopLeftRadius: BUTTON_SIZING.borderRadius.large,
    borderTopRightRadius: BUTTON_SIZING.borderRadius.large,
    borderBottomLeftRadius: BUTTON_SIZING.borderRadius.small,
    borderBottomRightRadius: BUTTON_SIZING.borderRadius.large,
    backgroundColor: MkiColors.comparePurpleLightest,
    width: "80%",
  },
  customerMessage: {
    borderTopLeftRadius: BUTTON_SIZING.borderRadius.large,
    borderTopRightRadius: BUTTON_SIZING.borderRadius.large,
    borderBottomLeftRadius: BUTTON_SIZING.borderRadius.large,
    borderBottomRightRadius: BUTTON_SIZING.borderRadius.small,
    backgroundColor: MkiColors.comparePurple,
    width: "80%",
  },
});

export default SupportCaseMessage;
