import {
  SENSOR_ROLES_FAILURE,
  SENSOR_ROLES_REQUEST,
  SENSOR_ROLES_SUCCESS,
} from "@meraki/shared/redux";

import { SensorRole } from "~/api/models/Sensor";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const getSensorRoles =
  (): AppThunk<Promise<ApiResponseAction<SensorRole[]>>> => (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [SENSOR_ROLES_REQUEST, SENSOR_ROLES_SUCCESS, SENSOR_ROLES_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sensor/relationships`,
        config: {
          method: Method.get,
        },
        meta: { networkId },
      },
    });
  };
