import { I18n } from "@meraki/core/i18n";
import { communityUrl } from "@meraki/go/links";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { KEYBOARD_SCROLL_OFFSET, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import ConfigHeader from "~/go/components/ConfigHeader";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import InputRow from "~/go/rows/InputRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { isStringEmpty } from "~/lib/formatHelper";
import { sizeSelect } from "~/lib/themeHelper";
import { currentUserState } from "~/selectors";
import { CancelButton } from "~/shared/navigation/Buttons";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { GoSharedScreensPropMap } from "../navigation/Types";

const KEYBOARD_SCROLL_OFFSET_SMALL = 86;

type ReduxProps = {
  email: string;
};

type Props = ForwardedNativeStackScreenProps<GoSharedScreensPropMap, "Wish"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

export interface WishScreenState {
  success: boolean;
  wish: string;
}

export class WishScreen extends PureComponent<Props, WishScreenState> {
  static validateInput(text: string) {
    if (isStringEmpty(text)) {
      return I18n.t("GO_WISH.WISH_EMPTY");
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      wish: "",
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { success } = this.state;

    navigation.setOptions({
      headerLeft: success ? undefined : () => <CancelButton onPress={navigation.goBack} />,
    });
  }

  componentDidUpdate(_: unknown, prevState: WishScreenState) {
    const { success } = this.state;
    if (prevState.success !== success) {
      this.setNavOptions();
    }
  }

  createWishCase = () => {
    const { wish } = this.state;
    const { actions, setReqPending } = this.props;
    const validationError = WishScreen.validateInput(wish);
    if (validationError) {
      showAlert(I18n.t("ERROR"), validationError);
      return Promise.reject(validationError);
    }
    setReqPending(true);
    const wishCase = { wish };
    return actions
      .createFeedbackCase(wishCase)
      .then(() => {
        this.setState({ success: true });
        setReqPending(false);
        return true;
      })
      .catch((error: unknown) => {
        this.setState({ success: false });
        setReqPending(false);
        showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
        return false;
      });
  };

  render() {
    const { navigation } = this.props;
    const { success, wish } = this.state;
    const onChangeInput = (text: string) => {
      this.setState({ wish: text });
    };
    const onSubmit = this.createWishCase;

    if (success) {
      return (
        <KeyboardAwareScrollView
          extraScrollHeight={KEYBOARD_SCROLL_OFFSET}
          keyboardShouldPersistTaps="handled"
        >
          <ConfigHeader
            title={I18n.t("GO_WISH.SUCCESS_ALERT.TITLE")}
            description={I18n.t("GO_WISH.SUCCESS_ALERT.MESSAGE")}
          />
          <RoundedButton
            onPress={navigation.goBack}
            screenStyles={styles.primaryButton}
            testID="CLOSE_BUTTON"
          >
            {I18n.t("GO_WISH.CLOSE_BUTTON")}
          </RoundedButton>
          <RoundedButton
            onPress={communityUrl}
            screenStyles={styles.secondaryButton}
            buttonType={ButtonType.secondary}
            testID="COMMUNITY_BUTTON"
          >
            {I18n.t("GO_WISH.COMMUNITY_BUTTON")}
          </RoundedButton>
        </KeyboardAwareScrollView>
      );
    }
    return (
      <KeyboardAwareScrollView
        extraScrollHeight={sizeSelect({
          small: KEYBOARD_SCROLL_OFFSET_SMALL,
          default: KEYBOARD_SCROLL_OFFSET,
        })}
        keyboardShouldPersistTaps="handled"
      >
        <ConfigHeader title={I18n.t("GO_WISH.TITLE")} description={I18n.t("GO_WISH.MESSAGE")} />
        <InputRow
          onChangeText={onChangeInput}
          placeholder={I18n.t("GO_WISH.INPUT_PROMPT")}
          value={wish}
          returnKeyType={RETURN_KEY.done}
          onSubmitEditing={onSubmit}
          enablesReturnKeyAutomatically
          autoCapitalize="sentences"
          autoCorrect
          multiline
          testID="INPUT"
          clearTestID="INPUT.CLEAR_BUTTON"
        >
          {I18n.t("GO_WISH.INPUT_QUESTION")}
        </InputRow>
        <RoundedButton
          onPress={onSubmit}
          screenStyles={styles.button}
          disabled={wish === ""}
          testID="SUBMIT_BUTTON"
        >
          {I18n.t("GO_WISH.SUBMIT_BUTTON")}
        </RoundedButton>
      </KeyboardAwareScrollView>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginVertical: SPACING.default,
    marginHorizontal: SPACING.extraLarge * 2,
  },
  primaryButton: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.extraLarge * 2,
  },
  secondaryButton: {
    marginBottom: SPACING.default,
    marginHorizontal: SPACING.extraLarge * 2,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    email: currentUserState(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(WishScreen);
