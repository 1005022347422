import { useCallback } from "react";
import { useSelector } from "react-redux";

import Organization from "~/api/models/Organization";
import { filteredOrgsBySearch } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import { Thunk } from "~/store";

export default function useOrgs(): [Organization[] | undefined, Thunk<Promise<any>>] {
  const orgs = useSelector(filteredOrgsBySearch);
  const actions = useActions();

  const actionThunk = useCallback(() => {
    return actions.fetchOrgs();
  }, [actions]);

  return [orgs, actionThunk];
}
