import { ErrorMessage } from "@hookform/error-message";
import { Input, InputProps, Notification, ValueType } from "@meraki/magnetic/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormInputProps<T extends ValueType> = Omit<InputProps<T>, "value" | "errored"> & FormItemProps;

export function FormInput<T extends ValueType>({
  name,
  rules,
  onChangeText,
  onBlur,
  ...rest
}: FormInputProps<T>) {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({
          field: { ref, onChange, value, onBlur: controllerOnBlur },
          fieldState: { invalid },
        }) => (
          <Input
            {...rest}
            ref={ref}
            errored={invalid}
            onChangeText={(text) => {
              onChange(text);
              onChangeText?.(text);
            }}
            value={value}
            onBlur={(event) => {
              controllerOnBlur();
              onBlur?.(event);
            }}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
}
