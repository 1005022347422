import { ContextOptions } from "../types";

export abstract class Provider {
  abstract init(): void;
  abstract setContext(context: ContextOptions): void;

  abstract getBool(flag: string): Promise<boolean>;
  abstract getDefaultBool(flag: string): boolean;
  abstract getNumber(flag: string): Promise<number>;
  abstract getDefaultNumber(flag: string): number;
  abstract getString(flag: string): Promise<string>;
  abstract getDefaultString(flag: string): string;
  abstract getJson(flag: string): Promise<unknown>;
  abstract getDefaultJson(flag: string): object;
  abstract track(metricKey: string): void;

  abstract registerFlagListener(flag: string, callback: (flag: string) => void): () => void;
}
