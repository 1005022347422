import { FirebaseRemoteConfigTypes } from "@react-native-firebase/remote-config";
import { FirebaseApp } from "firebase/app";
import { fetchAndActivate, getAll, getRemoteConfig, isSupported } from "firebase/remote-config";

export function setupRemoteConfig(app: FirebaseApp) {
  return {
    getAll,
    fetchAndActivate: async () => {
      if (!(await isSupported())) {
        return;
      }

      const remoteConfig = getRemoteConfig(app);
      fetchAndActivate(remoteConfig);
    },
    setConfigSettings: async (configSettings: FirebaseRemoteConfigTypes.ConfigSettings) => {
      if (!(await isSupported())) {
        return;
      }

      const remoteConfig = getRemoteConfig(app);
      // web types non optional
      remoteConfig.settings = {
        ...configSettings,
        /**
         * Defines the maximum age in milliseconds of an entry in the config cache before
         * it is considered stale. Defaults to 43200000 (Twelve hours).
         */
        minimumFetchIntervalMillis: configSettings.minimumFetchIntervalMillis ?? 43200000,
        /**
         * Defines the maximum amount of milliseconds to wait for a response when fetching
         * configuration from the Remote Config server. Defaults to 60000 (One minute).
         */
        fetchTimeoutMillis: configSettings.fetchTimeMillis ?? 60000,
      };
    },
    setDefaults: async (defaults: FirebaseRemoteConfigTypes.ConfigDefaults) => {
      if (!(await isSupported())) {
        return;
      }

      const remoteConfig = getRemoteConfig(app);
      remoteConfig.defaultConfig = defaults;
    },
  };
}
