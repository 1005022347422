import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import {
  ButtonGroup as ElementsButtonGroup,
  ButtonGroupProps as ElementsButtonGroupProps,
} from "react-native-elements";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING } from "~/constants/MkiConstants";

interface ButtonGroupProps extends ElementsButtonGroupProps {
  selectedButtonStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}

export function ButtonGroup(props: ButtonGroupProps) {
  const { onPress, buttons, selectedIndex, selectedButtonStyle, containerStyle } = props;

  const getContainerStyles = () => {
    return [styles.container, selectedButtonStyle];
  };

  const getSelectedButtonStyles = () => {
    return [styles.selectedButton, containerStyle];
  };

  return (
    <ElementsButtonGroup
      onPress={onPress}
      buttons={buttons}
      selectedIndex={selectedIndex}
      containerStyle={getContainerStyles()}
      selectedButtonStyle={getSelectedButtonStyles()}
      textStyle={styles.textStyle}
      innerBorderStyle={styles.innerBorder}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    backgroundColor: MkiColors.fullTransparent,
    borderColor: MkiColors.primaryButton,
    borderRadius: BUTTON_SIZING.borderRadius.medium,
  },
  innerBorder: {
    borderWidth: 1,
    color: MkiColors.primaryButton,
  },
  selectedButton: {
    borderWidth: 0,
    borderColor: MkiColors.primaryButton,
    backgroundColor: MkiColors.primaryButton,
  },
  textStyle: {
    color: MkiColors.primaryButton,
  },
});

export default ButtonGroup;
