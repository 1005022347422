import { ErrorMessage } from "@hookform/error-message";
import { Notification } from "@meraki/magnetic/components";
import { PickerCard, PickerCardProps } from "@meraki/shared/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormPickerCardProps<T> = Omit<PickerCardProps<T>, "onSelectOption" | "selectedOption"> &
  Partial<Pick<PickerCardProps<T>, "onSelectOption">> &
  FormItemProps;

export function FormPickerCard<T>({
  name,
  rules,
  options,
  onSelectOption,
  ...rest
}: FormPickerCardProps<T>) {
  const { control, watch } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange } }) => (
          <PickerCard
            {...rest}
            options={options}
            onSelectOption={(option) => {
              onChange(option.value);
              onSelectOption?.(option);
            }}
            selectedOption={options?.find((option) => option.value === watch(name))}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
}
