import { I18n } from "@meraki/core/i18n";

import * as DashboardActions from "~/actions";
import { showAlert } from "~/lib/AlertUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { isWeb } from "~/lib/PlatformUtils";
import { normalizeAuthData } from "~/lib/SpSamlUtils";
import { isAuthenticatedState } from "~/selectors";
import { navRef } from "~/shared/navigation/NavigationRef";
import { store } from "~/store/configureStore";

import { EmailVerificationErrors } from "../types/RegistrationTypes";

const VERIFY_EMAIL_PATH = "/verify_email";
const CONFIRM_EMAIL_PATH = "/confirm_email_change";
const RESET_PASSWORD_PATH = "/password_reset";
const APP_LINK_PATH = "/app/";
const SSO_SUCCESS = "/sso/success";

export const handleAppLink = (url: string) => {
  // remove non-customer-facing path from URL
  if (isWeb()) {
    history.replaceState(null, "", `${window.location.origin}/${window.location.hash}`);
  }

  if (url.includes(VERIFY_EMAIL_PATH)) {
    verifyEmail(url);
  } else if (url.includes(CONFIRM_EMAIL_PATH)) {
    confirmEmailChange(url);
  } else if (url.includes(RESET_PASSWORD_PATH)) {
    openPasswordResetScreen(url);
  } else if (url.includes(SSO_SUCCESS)) {
    // wait for login screen to finish loading
    setTimeout(() => handleSSO(url), 500);
  } else if (url.includes(APP_LINK_PATH)) {
    if (!isAuthenticatedState(store.getState())) {
      return;
    }

    const splitUrl = url.split(APP_LINK_PATH);
    const appLink = splitUrl[splitUrl.length - 1];

    const derivedScreenName = capitalizeFirstLetter(appLink.replace("Screen", ""));
    switch (derivedScreenName) {
      case "Alerts":
        navRef.navigate("SettingsStack", { screen: "Alerts" });
        return;
      case "Home":
        navRef.navigate("HomeStack", { screen: "Home" });
        return;
      case "LocationAnalytics":
        navRef.navigate("SettingsStack", { screen: "LocationAnalytics" });
        return;
      default:
        showAlert("Screen not found");
    }
  }
};

const verifyEmail = async (url: string) => {
  try {
    await store.dispatch(DashboardActions.verifyEmail(url));
    showAlert(I18n.t("EMAIL_VERIFICATION.THANKS"), I18n.t("EMAIL_VERIFICATION.CONFIRMED"));
  } catch (error) {
    let args: unknown[] = [I18n.t("EMAIL_VERIFICATION.FAILED")];

    if (error === EmailVerificationErrors.invalidRequest) {
      showAlert(I18n.t("EMAIL_VERIFICATION.FAILED"), I18n.t("EMAIL_VERIFICATION.INVALID"));
    } else if (error === EmailVerificationErrors.alreadyVerified) {
      showAlert(I18n.t("EMAIL_VERIFICATION.FAILED"), I18n.t("EMAIL_VERIFICATION.ALREADY_VERIFIED"));
    } else if (error === EmailVerificationErrors.invalidToken) {
      showAlert(I18n.t("EMAIL_VERIFICATION.FAILED"), I18n.t("EMAIL_VERIFICATION.INVALID_TOKEN"));
    } else if (error === EmailVerificationErrors.expiredToken) {
      showAlert(
        I18n.t("EMAIL_VERIFICATION.FAILED"),
        I18n.t("EMAIL_VERIFICATION.EXPIRED"),
        () => resendVerificationEmail(url),
        { positiveText: I18n.t("EMAIL_VERIFICATION.RESEND") },
      );
      args = [...args, I18n.t("EMAIL_VERIFICATION.EXPIRED"), () => resendVerificationEmail(url), ,];
    }

    showAlert(...args);
  }
};

const resendVerificationEmail = async (url: string) => {
  try {
    await store.dispatch(DashboardActions.resendVerificationEmail(url));
  } catch (error) {
    showAlert(I18n.t("EMAIL_VERIFICATION.FAILED"), error);
  }
};

const confirmEmailChange = async (url: string) => {
  try {
    await store.dispatch(DashboardActions.confirmEmail(url));
    showAlert(
      I18n.t("EMAIL_CHANGE_CONFIRMATION.CONFIRMED"),
      I18n.t("EMAIL_CHANGE_CONFIRMATION.DESCRIPTION"),
    );
  } catch (error) {
    let args: unknown[] = [I18n.t("EMAIL_CHANGE_CONFIRMATION.FAILED")];

    if (error === EmailVerificationErrors.invalidRequest) {
      args.push(I18n.t("EMAIL_VERIFICATION.INVALID"));
    } else if (error === EmailVerificationErrors.alreadyConfirmed) {
      args.push(I18n.t("EMAIL_CHANGE_CONFIRMATION.ALREADY_CONFIRMED"));
    } else if (error === EmailVerificationErrors.invalidToken) {
      args.push(I18n.t("EMAIL_VERIFICATION.INVALID_TOKEN"));
    } else if (error === EmailVerificationErrors.expiredToken) {
      args = [
        ...args,
        I18n.t("EMAIL_VERIFICATION.EXPIRED"),
        () => resendConfirmationEmail(url),
        { positiveText: I18n.t("EMAIL_CHANGE_CONFIRMATION.RESEND") },
      ];
    }

    showAlert(...args);
  }
};

const resendConfirmationEmail = async (url: string) => {
  try {
    await store.dispatch(DashboardActions.resendConfirmationEmail(url));
  } catch (error) {
    showAlert(I18n.t("EMAIL_CHANGE_CONFIRMATION.FAILED"), error);
  }
};

const openPasswordResetScreen = async (url: string) => {
  try {
    await store.dispatch(DashboardActions.fetchResetPasswordShard(url));
    navRef.navigate("PasswordReset", { url });
  } catch {
    showAlert(I18n.t("PASSWORD_RESET.FAILED"), I18n.t("PASSWORD_RESET.INVALID_TOKEN"));
  }
};

const handleSSO = (url: string) => {
  const authData = normalizeAuthData(url);
  if (!!authData) {
    const { auth, shardId, orgId } = authData;
    if (auth || !shardId || !orgId) {
      showAlert(I18n.t("ERROR"), I18n.t("SPSAML.REDIRECT_ERROR"));
      return;
    }

    try {
      store.dispatch(DashboardActions.setSSOInProgress(true));
      store.dispatch(DashboardActions.setInitialSamlLoginEntities(orgId));
      store.dispatch(DashboardActions.setSSOInProgress(false));
    } catch (error) {
      console.warn(error);
    }
  }
};
