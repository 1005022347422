import * as errorMonitor from "@meraki/core/errors";
import { useUmbrellaProtection } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";

import { useGXUMBLicense } from "~/go/hooks/useFilteredLicenses";
import { useGoUmbrellaPolicies } from "~/go/hooks/useGoUmbrellaPolicies";
import UmbrellaSettingsScreen from "~/go/screens/umbrella/UmbrellaSettingsScreen";
import {
  currentOrganization,
  getGXSerial,
  getNetworkTimezone,
  isUmbrellaAccountReadySelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const UmbrellaSettingsScreenWrapper = () => {
  const serialNumber = useAppSelector(getGXSerial) ?? "";
  const isUmbrellaAccountReady = useAppSelector(isUmbrellaAccountReadySelector);
  const organization = errorMonitor.notifyNonOptional(
    "param 'organization' undefined for UmbrellaSettingsScreen",
    useAppSelector(currentOrganization),
  );
  const networkId = errorMonitor.notifyNonOptional(
    "param 'netowrkId' undefined for UmbrellaSettingsScreen",
    useCurrentNetworkId(),
  );
  const timezone = errorMonitor.notifyNonOptional(
    "param 'timezone' undefined for UmbrellaSettingsScreen",
    useAppSelector(getNetworkTimezone),
  );

  const { data: license, isFetching: isLicenseFetching } = useGXUMBLicense(
    serialNumber,
    organization?.id,
    networkId,
  );
  const { data: protection, isFetching: isProtectionFetching } = useUmbrellaProtection({
    networkId,
  });
  const { data: policies, isFetching: isPoliciesFetching } = useGoUmbrellaPolicies(
    organization?.id,
  );

  if (!protection || !policies) {
    return (
      <LoadingSpinner visible={isLicenseFetching || isProtectionFetching || isPoliciesFetching} />
    );
  }

  return (
    <FullScreenContainerView withScroll>
      <UmbrellaSettingsScreen
        networkId={networkId}
        serialNumber={serialNumber}
        license={license}
        isUmbrellaAccountReady={isUmbrellaAccountReady}
        protection={protection}
        policies={policies}
        timezone={timezone}
      />
    </FullScreenContainerView>
  );
};

export default UmbrellaSettingsScreenWrapper;
