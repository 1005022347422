import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DefaultItemLabel from "~/go/rows/DefaultItemLabel";
import MkiTextInput from "~/shared/components/MkiTextInput";
import ShowHidePasswordButton from "~/shared/components/ShowHidePasswordButton";

type Props = {
  psk: string;
  onChangeText: (val: string) => void;
  onSubmitEditing: () => void;
  rowIsSelected?: boolean;
  styles: ViewStyle;
};

type State = {
  showPassword: boolean;
  focused: boolean;
};

export default class SSIDPasswordField extends PureComponent<Props, State> {
  static defaultProps = {
    rowIsSelected: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showPassword: false,
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  onClear = (clearedValue: string) => {
    const { onChangeText } = this.props;
    onChangeText(clearedValue);
  };

  toggleShowPassword = (showPassword: boolean) => {
    this.setState({ showPassword });
  };

  render() {
    const { focused, showPassword } = this.state;
    const { rowIsSelected, psk, onChangeText, onSubmitEditing } = this.props;

    let passwordInput;
    if (rowIsSelected) {
      let focusedStyle;
      const showButton = (
        <ShowHidePasswordButton key="showHide" onPress={this.toggleShowPassword} />
      );
      const buttons = [showButton];

      if (focused) {
        focusedStyle = styles.passwordInputFocused;
      }

      passwordInput = (
        <View
          ref={(ref) => {
            // @ts-expect-error TS(2339) FIXME: Property 'passwordInput' does not exist on type 'S... Remove this comment to see the full error message
            this.passwordInput = ref;
          }}
          style={[styles.passwordInput, focusedStyle]}
        >
          <MkiTextInput
            value={psk}
            onChangeText={onChangeText}
            onSubmitEditing={onSubmitEditing}
            placeholder={I18n.t(
              "SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_PLACEHOLDER",
            )}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            screenStyles={styles.passwordInputField}
            secureTextEntry={!showPassword}
            underlineColorAndroid="transparent"
            returnKeyType="done"
            testID="SSID_PASSWORD.INPUT"
            showClearButton
          />
          <View style={styles.inputButtonsContainer}>{buttons}</View>
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <DefaultItemLabel rowIsSelected={rowIsSelected}>
          {I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.PASSWORD_LABEL")}
        </DefaultItemLabel>
        {passwordInput}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  passwordInput: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: SPACING.small,
    borderBottomColor: MkiColors.inactiveInputUnderline,
    borderBottomWidth: 1,
  },
  passwordInputFocused: {
    borderBottomColor: MkiColors.activeInputUnderline,
  },
  passwordInputField: {
    flexGrow: 1,
    maxWidth: "60%",
  },
  inputButtonsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
