import {
  GET_SSID_L7_FIREWALL_RULES_SUCCESS,
  GET_SSID_SCHEDULES_SUCCESS,
  GET_SSID_SPLASH_SETTINGS_SUCCESS,
  GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
  SET_REDUX_SSIDS,
  SET_SSID_QR_CODE,
  SSIDS_SUCCESS,
  UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS,
  UPDATE_SSID_SCHEDULES_SUCCESS,
  UPDATE_SSID_SPLASH_SETTINGS_SUCCESS,
  UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
  UPDATE_WARN_INVALID_BRIDGE_MODE,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { ApplicationRulesByNumber } from "~/go/types/NetworksTypes";
import resolvedState from "~/lib/ReducerUtils";
import { setIdentifierOnRules } from "~/lib/TrafficShapingUtils";
import { FirewallLayerRule } from "~/shared/types/FirewallLayerRule";
import { SSID } from "~/shared/types/Models";
import { SplashSettings } from "~/shared/types/SplashSettings";

interface FirewallLayerRulesOnState {
  [id: number]: FirewallLayerRule[];
}

export interface SplashSettingsOnState {
  [id: string]: SplashSettings;
}

interface SSIDOnReducer {
  nodeGroupId: string;
  wireless: [SSID];
  firewallLayerRules: FirewallLayerRulesOnState;
  trafficShapingRules: ApplicationRulesByNumber;
  splashSettings: SplashSettingsOnState;
  warnInvalidBridgeMode?: boolean;
}

interface SSIDsReducer {
  [nodeGroupId: string]: SSIDOnReducer;
}

const initialState: SSIDsReducer = {};

export default function SSIDs(state = initialState, action: any) {
  const { response, meta, type } = action;

  switch (type) {
    case SET_REDUX_SSIDS: {
      const currentSSIDsOnNetwork = state[action.networkId];

      const nextState = {
        ...state,
        [action.networkId]: {
          ...currentSSIDsOnNetwork,
          networkId: action.networkId,
          wireless: action.ssids,
        },
      };
      return resolvedState(nextState, state);
    }
    case SSIDS_SUCCESS: {
      const newState = {
        ...state,
        ...response.entities.ssids,
      };

      Object.entries(state).forEach(([nodeGroupId, currentNetworkState]) => {
        newState[nodeGroupId].warnInvalidBridgeMode = currentNetworkState?.warnInvalidBridgeMode;
        for (let i = 0; i < currentNetworkState?.wireless?.length; i++) {
          newState[nodeGroupId].wireless[i].schedule = currentNetworkState?.wireless[i]?.schedule;
        }
      });

      return newState;
    }
    case GET_SSID_L7_FIREWALL_RULES_SUCCESS:
    case UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS: {
      const currentSSIDsOnNetwork = state[meta.networkId];

      return {
        ...state,
        [meta.networkId]: {
          ...currentSSIDsOnNetwork,
          firewallLayerRules: {
            ...currentSSIDsOnNetwork.firewallLayerRules,
            [meta.ssidNumber]: response.rules,
          },
        },
      };
    }
    case SET_SSID_QR_CODE: {
      const newChange = {
        ...state[action.networkId].wireless[action.ssidNumber],
        qrCode: action.qrCode,
      };
      const originalWireless = [
        ...state[action.networkId].wireless.slice(0, action.ssidNumber),
        newChange,
        ...state[action.networkId].wireless.slice(action.ssidNumber + 1),
      ];
      return {
        ...state,
        [action.networkId]: {
          ...state[action.networkId],
          wireless: originalWireless,
        },
      };
    }
    case GET_SSID_SCHEDULES_SUCCESS:
    case UPDATE_SSID_SCHEDULES_SUCCESS: {
      const newChange = {
        ...state[meta.networkId].wireless[meta.ssidNumber],
        schedule: response,
      };
      const originalWireless = [
        ...state[meta.networkId]?.wireless?.slice(0, meta.ssidNumber),
        newChange,
        ...state[meta.networkId]?.wireless?.slice(meta.ssidNumber + 1),
      ];
      return {
        ...state,
        [meta.networkId]: {
          ...state[meta.networkId],
          wireless: originalWireless,
        },
      };
    }
    case GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS:
    case UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS: {
      const currentSSIDsOnNetwork = state[meta.networkId];

      return {
        ...state,
        [meta.networkId]: {
          ...currentSSIDsOnNetwork,
          trafficShapingRules: {
            ...currentSSIDsOnNetwork.trafficShapingRules,
            [meta.ssidNumber]: setIdentifierOnRules(response.rules),
          },
        },
      };
    }
    case GET_SSID_SPLASH_SETTINGS_SUCCESS:
    case UPDATE_SSID_SPLASH_SETTINGS_SUCCESS: {
      const currentSSIDsOnNetwork = state[meta.networkId];

      return {
        ...state,
        [meta.networkId]: {
          ...currentSSIDsOnNetwork,
          splashSettings: {
            ...currentSSIDsOnNetwork.splashSettings,
            [meta.ssidNumber]: response,
          },
        },
      };
    }
    case UPDATE_WARN_INVALID_BRIDGE_MODE:
      const currentSSIDsOnNetwork = state[action.networkId];

      return {
        ...state,
        [action.networkId]: {
          ...currentSSIDsOnNetwork,
          warnInvalidBridgeMode: action.warnInvalidBridgeMode,
        },
      };
    case WIPE_REDUX: {
      const wipedState: SSIDsReducer = {};

      Object.entries(state).forEach(([nodeGroupId, ngState]) => {
        wipedState[nodeGroupId] = {
          nodeGroupId,
          warnInvalidBridgeMode: ngState.warnInvalidBridgeMode,
        } as SSIDOnReducer;
      });

      return wipedState;
    }
    default: {
      return state;
    }
  }
}
