import { I18n } from "@meraki/core/i18n";
import { useUpdateIpSecVPN } from "@meraki/shared/api";
import { calculateNumberOfIPAddresses, findValidSubnet } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { ALLOWED_SUBNET_MASK_MAX, ALLOWED_SUBNET_MASK_MIN } from "~/constants/IPAddress";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { SettingsStackProps } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import PickerModalRow from "~/go/rows/PickerModalRow";
import {
  DEFAULT_CLIENT_VPN_SUBNET,
  DNS_PROVIDER_OPTIONS,
  SUBNET_PLACEHOLDER,
} from "~/go/types/ClientVPN";
import { DNSServers } from "~/go/types/NetworksTypes";
import { showAlert } from "~/lib/AlertUtils";
import { extractSubnetMask, isSubnetValid } from "~/lib/IPAddressUtils";
import { getAllVlanSubnetRanges } from "~/selectors";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";

import { Features } from "../types/ContextHelpTypes";

const FAKE_SHARED_SECRET = "        ";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ClientVPNSettings">;

const ClientVPNSettingsScreen = ({ originalSettings, isFetching }: Props) => {
  const networkId = useCurrentNetworkId();
  const updateIpSecVPN = useUpdateIpSecVPN();
  const existingSubnetRanges: number[][] = useAppSelector(getAllVlanSubnetRanges);

  const [enabled, setEnabled] = useState(originalSettings?.enabled || false);
  const [subnet, setSubnet] = useState(
    originalSettings?.ipv4?.subnet ||
      findValidSubnet(existingSubnetRanges, DEFAULT_CLIENT_VPN_SUBNET),
  );
  const [subnetError, setSubnetError] = useState("");
  const [provider, setDNSProvider] = useState(
    originalSettings?.ipv4?.nameservers?.provider || DNSServers.google_dns,
  );
  const [showProviderPicker, setShowProviderPicker] = useState(false);
  const [addresses, setDNSAddresses] = useState(
    originalSettings?.ipv4?.nameservers?.addresses || [],
  );
  const [sharedSecret, setSharedSecret] = useState(
    originalSettings?.enabled ? originalSettings.sharedSecret : "",
  );

  const [revealable, setRevealable] = useState(false);
  const subnetMask = extractSubnetMask(subnet);
  const totalIps = subnet ? calculateNumberOfIPAddresses(subnetMask) : 0;

  const selectedOption = DNS_PROVIDER_OPTIONS.find(({ value }) => value === provider);

  const navigation = useNavigation<Props["navigation"]>();

  const hasDiff =
    originalSettings?.enabled !== enabled ||
    originalSettings?.sharedSecret !== sharedSecret ||
    originalSettings?.ipv4?.subnet !== subnet ||
    originalSettings?.ipv4?.nameservers?.provider !== provider ||
    originalSettings?.ipv4?.nameservers?.addresses !== addresses;

  useLayoutEffect(() => {
    const onSave = () => {
      if (enabled) {
        if (subnetMask < ALLOWED_SUBNET_MASK_MIN || subnetMask > ALLOWED_SUBNET_MASK_MAX) {
          setSubnetError(I18n.t("CLIENT_VPN.ERROR.INVALID_SUBNET_MASK"));
          return;
        } else if (!isSubnetValid(subnet, existingSubnetRanges)) {
          setSubnetError(I18n.t("CLIENT_VPN.ERROR.INVALID_SUBNET"));
          return;
        } else {
          setSubnetError("");
        }
      }

      const body = {
        enabled,
        ipv4: {
          subnet,
          nameservers: {
            provider,
            addresses: provider === DNSServers.custom ? addresses : [],
          },
        },
        sharedSecret: enabled ? sharedSecret : undefined,
      };

      updateIpSecVPN.mutate(
        {
          networkId,
          params: body,
        },
        {
          onError: (error) => {
            if (error !== null && typeof error === "object" && error.hasOwnProperty("errors")) {
              showAlert(I18n.t("ERROR"), error["errors"]?.[0]);
            }
          },
          onSuccess: () => navigation.goBack(),
        },
      );
    };

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} disabled={!hasDiff} />,
    });
  }, [
    addresses,
    enabled,
    existingSubnetRanges,
    hasDiff,
    navigation,
    networkId,
    provider,
    sharedSecret,
    subnet,
    subnetMask,
    updateIpSecVPN,
  ]);

  const setDNSProviderAndCloseModal = useCallback((newProvider: any) => {
    setDNSProvider(newProvider);
    setShowProviderPicker(false);
  }, []);

  const updateSharedSecret = useCallback((newSharedSecret: string) => {
    setSharedSecret(newSharedSecret);
    setRevealable(true);
  }, []);

  const generateValidSubnet = useCallback(() => {
    setSubnet(findValidSubnet(existingSubnetRanges, DEFAULT_CLIENT_VPN_SUBNET));
    setSubnetError("");
  }, [existingSubnetRanges]);

  const openAdminScreen = () => navigation.navigate("AdminAndAuthUser", { isFromClientVPN: true });

  const removeFakeSecret = useCallback(() => {
    if (sharedSecret === FAKE_SHARED_SECRET) {
      setSharedSecret("");
    }
  }, [sharedSecret]);

  return (
    <FullscreenContainerView withScroll>
      <SwitchRow
        value={enabled}
        onValueChange={setEnabled}
        testID={`CLIENT_VPN.${enabled ? "ENABLED" : "DISABLED"}`}
        context={Features.clientVPNTroubleshooting}
      >
        {I18n.t("ENABLED")}
      </SwitchRow>
      {!!originalSettings?.enabled && (
        <SimpleDisclosureRow
          subtitle={I18n.t("CLIENT_VPN.USER.SUBTITLE")}
          testID="CLIENT_VPN.ADMIN"
          onPress={openAdminScreen}
        >
          {I18n.t("CLIENT_VPN.USER.TITLE")}
        </SimpleDisclosureRow>
      )}
      {!!originalSettings?.enabled && (
        <SimpleDisclosureRow
          subtitle={I18n.t("CLIENT_VPN.EVENT_LOG.DESCRIPTION")}
          testID="CLIENT_VPN.EVENT_LOG"
          onPress={() => navigation.navigate("ClientVPNEventLog")}
        >
          {I18n.t("CLIENT_VPN.EVENT_LOG.TITLE")}
        </SimpleDisclosureRow>
      )}
      <View style={styles.row}>
        <View style={styles.ipAddressInput}>
          <MkiText textStyle="default">{I18n.t("CLIENT_VPN.SUBNET")}</MkiText>
        </View>
        <IPAddressTextInput
          cidrAddress={subnet}
          onAddressChange={setSubnet}
          leaveEditableBlank={false}
          placeholders={SUBNET_PLACEHOLDER}
          includeSubnetMask
        />
        {!!subnetError && (
          <>
            <MkiText testID="SUBNET_ERROR_MESSAGE" textStyle="error">
              {subnetError}
            </MkiText>
            <RoundedButton
              testID="SUBNET_AUTO_GENERATE"
              buttonType={ButtonType.secondary}
              containerStyles={styles.generateSubnetButtonContainerStyle}
              screenStyles={styles.generateSubnetButtonStyle}
              onPress={generateValidSubnet}
            >
              {I18n.t("CLIENT_VPN.ERROR.AUTO_CONFIG_SUBNET")}
            </RoundedButton>
          </>
        )}
        <MkiText textStyle="smallSecondary" screenStyles={styles.subnetCountStyles}>
          {I18n.t("CONFIGURE_VLAN.SUBNET_COUNT", { address_count: totalIps })}
        </MkiText>
      </View>
      <PickerModalRow
        visible={showProviderPicker}
        label={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.TITLE")}
        items={DNS_PROVIDER_OPTIONS}
        subtitle={selectedOption?.label}
        onPress={() => setShowProviderPicker(true)}
        selectedValue={provider}
        onExit={() => setShowProviderPicker(false)}
        onValueSelect={setDNSProviderAndCloseModal}
        containerStyles={styles.modalRowContainer}
        testID="CLIENT_VPN.DNS_PROVIDER"
        disableBottomBorder
      />
      <View style={styles.row}>
        <MkiText textStyle="smallSecondary">
          {I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.DESCRIPTION")}
        </MkiText>
      </View>
      {provider === DNSServers.custom && (
        <View style={styles.row}>
          <View style={styles.ipAddressInput}>
            <MkiText textStyle="default">{I18n.t("CLIENT_VPN.NAMESERVERS.ADDRESSES")}</MkiText>
          </View>
          <IPAddressTextInput
            cidrAddress={addresses[0]}
            onAddressChange={(newVal: any) => setDNSAddresses([newVal, addresses[1]])}
            leaveEditableBlank={false}
          />
          <IPAddressTextInput
            cidrAddress={addresses[1]}
            onAddressChange={(newVal: any) => setDNSAddresses([addresses[0], newVal])}
            leaveEditableBlank={false}
          />
        </View>
      )}
      <InputRow
        value={sharedSecret}
        onChangeText={updateSharedSecret}
        onFocus={removeFakeSecret}
        revealable={revealable}
        description={I18n.t("CLIENT_VPN.SHARED_SECRET.DESCRIPTION")}
        testID="CLIENT_VPN.SHARED_SECRET"
        secureTextEntry
      >
        <MkiText textStyle="default" screenStyles={styles.row}>
          {I18n.t("CLIENT_VPN.SHARED_SECRET.TITLE")}
        </MkiText>
      </InputRow>
      <LoadingSpinner visible={isFetching || updateIpSecVPN.isLoading} />
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  row: {
    marginHorizontal: SPACING.default,
  },
  ipAddressInput: {
    marginTop: SPACING.small,
  },
  subnetCountStyles: {
    marginBottom: SPACING.default,
  },
  modalRowContainer: {
    backgroundColor: undefined,
    marginTop: 0,
  },
  generateSubnetButtonContainerStyle: {
    paddingHorizontal: SPACING.large,
  },
  generateSubnetButtonStyle: {
    paddingVertical: SPACING.small,
  },
});

export default ClientVPNSettingsScreen;
