import { buildAppHeaders } from "~/api/migrationUtil";
import { ApiActionConfig, Method } from "~/shared/types/RequestTypes";

export const createHeaders = (config: ApiActionConfig) => {
  const { headers, method } = config;

  const newHeaders: Record<string, string> = {
    ...headers,
    ...buildAppHeaders(),
  };

  if (method === Method.post) {
    newHeaders["X-Requested-With"] = "XMLHttpRequest";
  }

  if (!config.noHeaderFill) {
    newHeaders["Accept"] = "application/json";
    newHeaders["Content-Type"] = "application/json";
  }

  return newHeaders;
};

export default createHeaders;

export interface CancelablePromise<T = any> {
  promise: Promise<T>;
  cancel: () => void;
}

export function makeCancelablePromise<T>(promise: Promise<T>): CancelablePromise<T> {
  let isCanceled = false;

  const cancelablePromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => {
        if (!isCanceled) {
          resolve(val);
        }
      },
      (err) => {
        if (!isCanceled) {
          reject(err);
        }
      },
    );
  });

  return {
    promise: cancelablePromise,
    cancel: () => (isCanceled = true),
  };
}

export function makeFormBody(data: object): string {
  if (!data) {
    return "";
  }
  const formEntries: string[] = [];
  for (const property in data) {
    const encodedKey: string = encodeURIComponent(property);
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const encodedValue: string = encodeURIComponent(data[property]);
    formEntries.push(`${encodedKey}=${encodedValue}`);
  }
  return formEntries.join("&");
}

export type ActivationError =
  | string
  | {
      title: string;
      items: ActivationError[];
    };

export function parseActivationError(error: ActivationError, extraNewline?: boolean) {
  if (typeof error === "string") {
    return `${error}\n`;
  }
  if (!error || !Array.isArray(error.items)) {
    return "";
  }

  let errorBody = "";
  for (const item of error.items) {
    errorBody += parseActivationError(item, true);
  }
  return `${extraNewline ? "\n" : ""}${error.title}\n${errorBody}`;
}

export function requiresTwoFactor(response: any) {
  return (
    response?.state?.destination == "sms_auth_config" &&
    response?.state?.options?.xhr_text === "need sms auth"
  );
}
