import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function PositiveRepeater({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        clipRule="evenodd"
        d="M11.138 6.195c.26.26.26.683 0 .943l-3.499 3.5a.667.667 0 0 1-.942 0l-1.502-1.5a.667.667 0 1 1 .943-.943l1.03 1.029 3.027-3.029c.26-.26.683-.26.943 0Z"
        fill={theme.color.positive.icon.base}
        fillRule="evenodd"
      />
      <Path
        d="M1.674 6.5A6.51 6.51 0 0 1 6.5 1.674v1.398A5.162 5.162 0 0 0 3.072 6.5H1.674ZM1.674 9.5A6.51 6.51 0 0 0 6.5 14.326v-1.398A5.162 5.162 0 0 1 3.072 9.5H1.674ZM12.928 9.5A5.161 5.161 0 0 1 9.5 12.928v1.398A6.51 6.51 0 0 0 14.326 9.5h-1.398ZM14.326 6.5A6.51 6.51 0 0 0 9.5 1.674v1.398A5.161 5.161 0 0 1 12.928 6.5h1.398Z"
        fill={theme.color.positive.icon.base}
      />
    </Svg>
  );
}
