import { I18n } from "@meraki/core/i18n";
import { TrafficShapingGroupProps } from "@meraki/go/navigation-type";
import {
  L3FirewallRuleScreen,
  L3FirewallRulesListScreen,
  PortForwardingRulesListScreen,
  SwitchQoSRuleScreen,
  SwitchQoSRulesListScreen,
  TrafficShapingRuleScreen,
  TrafficShapingRulesListScreen,
} from "@meraki/go/traffic-shaping";
import { Button } from "@meraki/magnetic/components";
import { ModalScreenOptions, useNetworkBackButton } from "@meraki/shared/navigation";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function TrafficShapingGroup<T extends TrafficShapingGroupProps>(Stack: StackType<T>) {
  const navigation = useNavigation<NativeStackNavigationProp<TrafficShapingGroupProps>>();

  const optionsWithNetworkButton = useNetworkBackButton();

  return (
    <>
      <Stack.Screen
        name="SwitchQoSRule"
        component={SwitchQoSRuleScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("CONFIGURE.SWITCH_QOS.RULE.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="SwitchQoSRulesList"
        component={SwitchQoSRulesListScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="TrafficShapingRulesList"
        component={TrafficShapingRulesListScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="TrafficShapingRule"
        component={TrafficShapingRuleScreen}
        options={{
          headerTitle: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.TITLE"),
        }}
      />
      <Stack.Screen
        name="PortForwardingRulesList"
        component={PortForwardingRulesListScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="L3FirewallRulesList"
        component={L3FirewallRulesListScreen}
        options={optionsWithNetworkButton}
      />
      <Stack.Screen
        name="L3FirewallRule"
        component={L3FirewallRuleScreen}
        options={{ ...optionsWithNetworkButton, headerTitle: I18n.t("L3_FIREWALL_RULE.TITLE") }}
      />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "L3_FIREWALL_RULES_LIST.HEADER.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "L3FirewallRulesList",
  },
  ["network", "wired", "firewall"],
);

registerTags(
  {
    title: "PORT_FORWARDING.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "PortForwardingRulesList",
  },
  ["network", "wired", "forwarding"],
);

registerTags(
  {
    title: "CONFIGURE.SWITCH_QOS.TITLE",
    tab: "ConfigureTab",
    scope: "network",
    screen: "SwitchQoSRulesList",
  },
  ["network", "switch", "qos", "traffic"],
);

registerTags(
  {
    title: "NETWORK_CONFIGURE.NETWORK.TRAFFIC_SHAPING",
    tab: "ConfigureTab",
    scope: "network",
    screen: "TrafficShapingRulesList",
  },
  ["network", "wired", "wireless", "traffic"],
);
