import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  Loader,
  TabView,
  TabViewMethods,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useDeviceBySerial, useDevices } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";

import { EditBottomSheet } from "../components/EditBottomSheet";
import { DeviceDetailsContext } from "./DeviceDetailsContext";
import { DetailsTab } from "./tabs/DetailsTab";
import { InsightsTab } from "./tabs/InsightsTab";
import { SettingsTab } from "./tabs/SettingsTab";

const DEVICE_TABS = {
  details: {
    title: I18n.t("TAB_TITLES.DETAILS"),
    view: <DetailsTab />,
    testID: "DETAILS_TABVIEW_TAB",
  },
  insight: {
    title: I18n.t("TAB_TITLES.INSIGHTS"),
    view: <InsightsTab />,
    testID: "INSIGHTS_TABVIEW_TAB",
  },
  settings: {
    title: I18n.t("TAB_TITLES.SETTINGS"),
    view: <SettingsTab />,
    testID: "SETTINGS_TABVIEW_TAB",
  },
};

export const DeviceDetailsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "DeviceDetails">>();
  const { params: props } = route;
  const serial = props?.serial;

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const tabViewRef = useRef<TabViewMethods>(null);
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  // load data for MountLocationScreen
  queryClient.prefetchQuery({
    queryKey: useDeviceBySerial.queryKey({ serial: serial }),
    queryFn: useDeviceBySerial.queryFn({ serial: serial }),
  });

  const { data: device, isLoading: deviceIsLoading } = useDevices(
    {
      organizationId,
      networkId,
      serial: serial,
    },
    {
      select(data) {
        return data.find((device) => device.serial === serial);
      },
    },
  );

  const handleGoToTab = useCallback((key: string) => {
    tabViewRef.current?.goToTab(key);
  }, []);

  useEffect(
    () =>
      navigation.setOptions({
        headerRight: () => (
          <>
            <Button.Icon icon="Pencil" onPress={() => bottomSheetRef.current?.present()} />
            <Button.Icon
              icon="MapPin"
              onPress={() => navigation.navigate("DeviceMountLocation", { serialNumber: serial })}
            />
          </>
        ),
      }),
    [navigation, serial],
  );

  if (!device || deviceIsLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  return (
    <DeviceDetailsContext.Provider value={{ device: device, goToTab: handleGoToTab }}>
      <Screen.View>
        <Box paddingVertical="none" padding="sm">
          <Heading size="h1">{device.name || device.serial || device.mac}</Heading>
        </Box>
        <TabView tabs={DEVICE_TABS} ref={tabViewRef} />
      </Screen.View>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <EditBottomSheet device={device} onClose={() => bottomSheetRef.current?.close()} />
      </BottomSheet.Modal>
    </DeviceDetailsContext.Provider>
  );
};
