import { TranslationKeys } from "@meraki/core/i18n";

import MkiColors from "~/constants/MkiColors";
import { FONT_SIZES } from "~/constants/MkiFonts";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, sizeSelect, Theme, themeColors } from "~/lib/themeHelper";

interface CountriesWithCode {
  [code: string]: {
    name: TranslationKeys;
    region: string;
    phoneCode?: string;
    emoji?: string;
  };
}

export const THIRTY_SECONDS_IN_MS = 30000;
export const LATENCY_LIMIT = 150;
export const LOSS_LIMIT = 10;
export const PING_TIMEOUT = THIRTY_SECONDS_IN_MS;
export const CABLE_TEST_TIMEOUT = THIRTY_SECONDS_IN_MS;
export const CAPTCHA_TIMEOUT_FIVE_MINUTES = 300000;
export const MS_IN_A_DAY = 86400000;
export const MS_IN_AN_HOUR = 3600000;
export const MS_IN_A_MIN = 60000;
export const MS_IN_A_SECOND = 1000;
export const SECONDS_IN_A_MINUTE = 60;
export const SECONDS_IN_AN_HOUR = 3600;
export const SECONDS_IN_A_DAY = 86400;
export const SECONDS_IN_A_WEEK = 604800;
export const BITS_IN_A_BYTE = 8;
export const MASTER_ID = 1;
export const MODAL_DISMISS_DURATION = 600;
export const UTC_TIMEZONE = "UTC";
export const TIMESTAMP_KEY = "timestamp";
export const BASE_POLICIES = ["-1", "0", "1"];
export const CLIENT: "CLIENT_WORD" | "DEVICE_WORD" = appSelect({
  enterprise: "CLIENT_WORD",
  go: "DEVICE_WORD",
});
export const CLIENTS: "CLIENTS_WORD" | "DEVICES_WORD" = appSelect({
  enterprise: "CLIENTS_WORD",
  go: "DEVICES_WORD",
});
export const DEVICES = appSelect({
  enterprise: "DEVICES_WORD",
  go: "HARDWARE_WORD",
});
export const SINGLE_DEVICE: "DEVICE_WORD" | "HARDWARE_WORD" = appSelect({
  enterprise: "DEVICE_WORD",
  go: "HARDWARE_WORD",
});
const ORIENTATION = {
  portrait: "portrait",
};

/* The navbar text is platform specific due to limitations
 * in the Wix library that don't allow us to set fontWeight
 * for the title and subtitle
 */

export const NAV_BAR_EVENT = {
  buttonPress: "NavBarButtonPress",
  screenChanged: "ScreenChangedEvent",
};
export const NAV_STYLES = {
  navBarTextColor: MkiColors.headingColor,
  navBarTextFontFamily: platformSelect({
    android: "ciscosans",
    ios: "System",
  }),
  navBarSubtitleColor: MkiColors.secondaryTextColor,
  navBarSubtitleFontFamily: platformSelect({
    android: "ciscosans",
    ios: "System",
  }),
  navBarBackgroundColor: MkiColors.whiteBackground,
};
export const GO_NAV_BAR_BUTTON_SIZE = FONT_SIZES.NAV_BAR.BUTTONS.default;
export const GO_NAV_TITLE_MEDIUM = {
  fontSize: FONT_SIZES.NAV_BAR.TITLE.medium,
};
export const GO_NAV_TITLE_LARGE = {
  largeTitle: true,
  navBarLargeTextFontSize: FONT_SIZES.NAV_BAR.TITLE.large,
  navBarLargeTextFontFamily: platformSelect({
    android: "proximasoft_medium",
    ios: "ProximaSoft-Medium",
  }),
};
export const GO_NAV_STYLES = (theme: Theme = "light") => ({
  title: {
    ...themeColors(theme).text?.heading,
    fontSize: platformSelect({
      android: normalizedFontSize(24),
      ios: undefined,
    }),
    fontFamily: platformSelect({
      android: "proximasoft_medium",
      ios: "ProximaSoft-Medium",
    }),
  },
  subtitle: {
    color: MkiColors.secondaryTextColor,
    fontSize: platformSelect({
      android: normalizedFontSize(24),
      ios: undefined,
    }),
    fontFamily: platformSelect({
      android: "proximasoft_medium",
      ios: "ProximaSoft-Medium",
    }),
  },
  backButton: {
    color: MkiColors.primaryButton,
    title: "",
    testID: "BACK_BUTTON",
    enableMenu: false,
  },
  background: {
    color: themeColors(theme).navigation.backgroundPrimary,
  },
});

export const GO_APP_STYLES = {
  backButtonImage: require("~/images/navBar/chevronLeft-S.png"),
  forceTitlesDisplay: true,
  keepStyleAcrossPush: false,
  orientation: ORIENTATION.portrait,
};
export const ONBOARDING_NAV_STYLES = {
  title: {
    fontSize: FONT_SIZES.NAV_BAR.TITLE.default,
    color: MkiColors.primaryButton,
  },
};

export const GRAY_SCREEN_BACKGROUND = {
  screenBackgroundColor: MkiColors.lightGrayBackground,
};
/* There's no "WHITE_SCREEN_BACKGROUND" variable because that's the default */

export const DOT = {
  size: 8,
  spacing: 3,
};

export const LINK_TOP = {
  default: 1.5,
  large: 3,
};

export const SPACING = {
  tiny: 2,
  meager: 4,
  small: 8,
  medium: 12,
  default: 16,
  large: 24,
  extraLarge: 32,
  extraExtraLarge: 48,
} as const;

export const BORDER_RADIUS = {
  default: 8,
  bannerAlert: 5,
  round: 20,
} as const;

export const BUTTON_SIZING = {
  borderRadius: {
    default: 6,
    medium: 12,
    large: 20,
    small: 4,
  },
  borderWidth: 1,
  paddingVertical: {
    primary: 14,
    default: 9,
  },
  width: {
    narrower: 16,
    narrow: 24,
    default: 32,
    wide: 88,
  },
  roundedButtonMarginBottom: sizeSelect({
    small: SPACING.large,
    default: 60,
  }),
  helplink: {
    default: 18,
  },
} as const;

export const BOX_BORDER_RADIUS = 4;

export const LINE_HEIGHT = {
  default: 22,
};

export const BETA_TAG_SPACING = {
  right: 3,
  top: -2,
};

export const LETTER_SPACING = {
  default: 0,
  smallText: 0.2,
  subheaders: 1.0,
  allCaps: 1.18,
};

export const DISABLED_OPACITY = 0.5;

export const PICKER_WHEEL_SIZE = {
  default: 134,
};

export const CHECKMARK_SIZE = 25;
export const ICON_HEADER_SIZE = 32;
export const ICON_BANNER_SIZE = 19;

export const PERMISSIONS_ERRORS = {
  locationDenied: "User denied location access",
  androidOnly: "This method is available on Android only",
};

export const TIMEOUT_ERROR_KEY = "Poor internet connection";

export enum BAND_SELECTIONS {
  dualBandOperation = "Dual band operation",
  fiveGHzOnly = "5 GHz band only",
  twoGHzOnly = "2.4 GHz band only",
  dualBandWithBandSteering = "Dual band operation with Band Steering",
}

export const RETURN_KEY = {
  go: "go",
  next: "next",
  done: "done",
} as const;

export const KEYBOARD_TYPE = {
  default: "default",
  decimalPad: "decimal-pad",
  emailAddress: "email-address",
  numeric: "numeric",
  phonePad: "phone-pad",
  // iOS only
  url: "url",
  numbersAndPunctuation: "numbers-and-punctuation",
} as const;

// extra offset for KeyboardAwareScrollView
export const KEYBOARD_SCROLL_OFFSET = 15;

export const MAX_SSIDS = appSelect({
  enterprise: 15,
  go: 4,
});

// nav bar height on iOS
export const NAV_BAR_HEIGHT = 128;

export const LINE_CHART_STROKEWIDTH = 2;

// These chart padding values are necessary to prevent long y-axis labels from being cropped
export const LINE_CHART_PADDING = {
  left: platformSelect({
    mobile: SPACING.default * 4,
    web: SPACING.extraLarge * 4,
  }),
  top: 24,
  bottom: platformSelect({
    mobile: 24,
    web: 34,
  }),
  right: 16,
};

export const FULL_WIDTH_LINE_CHART_PADDING = {
  left: platformSelect({
    mobile: SPACING.default * 4,
    web: SPACING.extraLarge * 4,
  }),
  top: SPACING.small,
  bottom: SPACING.default,
  right: SPACING.default,
};

// Heights for bar charts
export const DEFAULT_MOBILE_CHART_HEIGHT = 250;
export const DEFAULT_TABLET_CHART_HEIGHT = 450;

// Padding for Victory Chart components
export const DEFAULT_CARD_PADDING_HORIZONTAL = SPACING.default * 2;
export const DEFAULT_CHART_HEIGHT = platformSelect({
  mobile: 160,
  web: 300,
});

// default perPage for pagination
export const DEFAULT_PAGE_SIZE = 25;

export const pieColors = appSelect({
  // moved from mkiColors
  enterprise: [
    "lblue",
    "lpink",
    "lgreen",
    "mblue",
    "lpurple",
    "morange",
    "lorange",
    "mpink",
    "mgreen",
    "mpurple",
  ],
  go: ["pie1", "pie2", "pie3", "pie4", "pie5", "pie6", "pie7"],
});

// ordered from worst to best
export const UPTIME_COLOR_STATUSES = [
  "offlineBar",
  "offline",
  "alerting",
  "lightGreenWarningStatus",
  "goodBar",
] as const;

export const GO_UPTIME_COLOR_STATUSES = [
  //not needed if we consolidate to UPTIME_COLOR_STATUSES and handle colors in themes
  "offlineBar",
  "badBar",
  "alertingBar",
  "goodWithIssueBar",
  "goodBar",
];

export enum GO_HEALTH_COLOR_STATUSES {
  bad = "offline",
  good = "goodBar",
  offline = "offlineBar",
}

export const SM_CLIENT_FIELDS = [
  "name",
  "missing_apps",
  "is_compliant",
  "created_at",
  "tags",
  "outages_status",
] as const;

export const SWITCH_PANEL_WIDTH = 365;

export const SM_STATUS_ONLINE = 10;

export const NETWORK_TYPE_ABBREVIATIONS = {
  wireless: "r",
  switch: "s",
  wired: "x",
} as const;

export const TAB_ORDER = {
  home: 0,
  networks: 1,
  hardware: 2,
  settings: 3,
} as const;

export const RADIO_BUTTON_SIZE = appSelect({
  go: 14,
  enterprise: SPACING.small,
});

export const WINDOW = "window";

export const DISCONNECTED_STATUS = "disconnected";

export const INDUSTRIES = [
  {
    label: "INDUSTRIES.ARTS_RECREATION",
    value: "Arts/Recreation",
  },
  {
    label: "INDUSTRIES.EDUCATION_HIGHER_ED",
    value: "Edu - Higher Ed",
  },
  {
    label: "INDUSTRIES.EDUCATION_PUBLIC_PRIVATE",
    value: "Edu - K-12, Public & Private",
  },
  {
    label: "INDUSTRIES.EDUCATION_OTHER",
    value: "Edu - Other",
  },
  {
    label: "INDUSTRIES.ENERGY_UTILITIES",
    value: "Energy/Utilities",
  },
  {
    label: "INDUSTRIES.FINANCIAL_SERVICES",
    value: "Financial Services",
  },
  {
    label: "INDUSTRIES.GOVERNMENT",
    value: "Government",
  },
  {
    label: "INDUSTRIES.HEALTHCARE",
    value: "Health Care",
  },
  {
    label: "INDUSTRIES.HIGH_TECH",
    value: "High Tech - Soft/Hardware",
  },
  {
    label: "INDUSTRIES.HOSPITALITY_HOTELS_LEISURE",
    value: "Hospitality/Hotels & Leisure",
  },
  {
    label: "INDUSTRIES.LIBRARY",
    value: "Library",
  },
  {
    label: "INDUSTRIES.MANUFACTURING",
    value: "Manufacturing",
  },
  {
    label: "INDUSTRIES.MEDIA_ENTERTAINMENT",
    value: "Media/Entertainment",
  },
  {
    label: "INDUSTRIES.NONPROFIT_MEMBERSHIP",
    value: "Non-Profit & Membership Orgs",
  },
  {
    label: "INDUSTRIES.PROFESSIONAL_SERVICES",
    value: "Professional Services",
  },
  {
    label: "INDUSTRIES.RETAIL_RESTAURANTS",
    value: "Retail/Restaurants",
  },
  {
    label: "INDUSTRIES.SERVICE_PROVIDER",
    value: "Service Provider",
  },
  {
    label: "INDUSTRIES.TRANSPORTATION",
    value: "Transportation",
  },
  {
    label: "INDUSTRIES.VAR_SYSTEMS_INTEGRATOR",
    value: "VAR/Systems Integrator",
  },
  {
    label: "INDUSTRIES.WHOLESALE_DISTRIBUTION",
    value: "Wholesale/Distribution",
  },
  {
    label: "INDUSTRIES.OTHER",
    value: "Other",
  },
] as const;

export const COMPANY_SIZE_RANGES = [
  { value: "1 to 9", label: "COMPANY_SIZE_RANGES.RANGE_1_9" },
  { value: "10 to 24", label: "COMPANY_SIZE_RANGES.RANGE_10_24" },
  { value: "25 to 49", label: "COMPANY_SIZE_RANGES.RANGE_25_49" },
  { value: "50 to 74", label: "COMPANY_SIZE_RANGES.RANGE_50_74" },
  { value: "75 to 99", label: "COMPANY_SIZE_RANGES.RANGE_75_99" },
  { value: "100+", label: "COMPANY_SIZE_RANGES.RANGE_100_PLUS" },
] as const;

export const COUNTRIES: CountriesWithCode = {
  AF: { name: "CREATE_ACCOUNT.COUNTRIES.AFGHANISTAN", region: "APAC" },
  AX: { name: "CREATE_ACCOUNT.COUNTRIES.ALAND_ISLANDS", region: "EMEA" },
  AL: { name: "CREATE_ACCOUNT.COUNTRIES.ALBANIA", region: "EMEA" },
  DZ: { name: "CREATE_ACCOUNT.COUNTRIES.ALGERIA", region: "EMEA" },
  AS: { name: "CREATE_ACCOUNT.COUNTRIES.AMERICAN_SAMOA", region: "APAC" },
  AD: { name: "CREATE_ACCOUNT.COUNTRIES.ANDORRA", region: "EMEA" },
  AO: { name: "CREATE_ACCOUNT.COUNTRIES.ANGOLA", region: "EMEA" },
  AI: { name: "CREATE_ACCOUNT.COUNTRIES.ANGUILLA", region: "SA" },
  AQ: { name: "CREATE_ACCOUNT.COUNTRIES.ANTARCTICA", region: "APAC" },
  AG: { name: "CREATE_ACCOUNT.COUNTRIES.ANTIGUA_AND_BARBUDA", region: "SA" },
  AR: { name: "CREATE_ACCOUNT.COUNTRIES.ARGENTINA", region: "SA" },
  AM: { name: "CREATE_ACCOUNT.COUNTRIES.ARMENIA", region: "EMEA" },
  AW: { name: "CREATE_ACCOUNT.COUNTRIES.ARUBA", region: "SA" },
  AU: { name: "CREATE_ACCOUNT.COUNTRIES.AUSTRALIA", region: "APAC" },
  AT: { name: "CREATE_ACCOUNT.COUNTRIES.AUSTRIA", region: "EMEA" },
  AZ: { name: "CREATE_ACCOUNT.COUNTRIES.AZERBAIJAN", region: "APAC" },
  BS: { name: "CREATE_ACCOUNT.COUNTRIES.BAHAMAS", region: "SA" },
  BH: { name: "CREATE_ACCOUNT.COUNTRIES.BAHRAIN", region: "EMEA" },
  BD: { name: "CREATE_ACCOUNT.COUNTRIES.BANGLADESH", region: "APAC" },
  BB: { name: "CREATE_ACCOUNT.COUNTRIES.BARBADOS", region: "SA" },
  BY: { name: "CREATE_ACCOUNT.COUNTRIES.BELARUS", region: "EMEA" },
  BE: { name: "CREATE_ACCOUNT.COUNTRIES.BELGIUM", region: "EMEA" },
  BZ: { name: "CREATE_ACCOUNT.COUNTRIES.BELIZE", region: "SA" },
  BJ: { name: "CREATE_ACCOUNT.COUNTRIES.BENIN", region: "EMEA" },
  BM: { name: "CREATE_ACCOUNT.COUNTRIES.BERMUDA", region: "SA" },
  BT: { name: "CREATE_ACCOUNT.COUNTRIES.BHUTAN", region: "APAC" },
  BO: { name: "CREATE_ACCOUNT.COUNTRIES.BOLIVIA", region: "SA" },
  BA: { name: "CREATE_ACCOUNT.COUNTRIES.BOSNIA_AND_HERZEGOVINA", region: "EMEA" },
  BW: { name: "CREATE_ACCOUNT.COUNTRIES.BOTSWANA", region: "EMEA" },
  BV: { name: "CREATE_ACCOUNT.COUNTRIES.BOUVET_ISLAND", region: "SA" },
  BR: { name: "CREATE_ACCOUNT.COUNTRIES.BRAZIL", region: "SA" },
  IO: { name: "CREATE_ACCOUNT.COUNTRIES.BRITISH_INDIAN_OCEAN_TERRITORY", region: "APAC" },
  BN: { name: "CREATE_ACCOUNT.COUNTRIES.BRUNEI_DARUSSALAM", region: "APAC" },
  BG: { name: "CREATE_ACCOUNT.COUNTRIES.BULGARIA", region: "EMEA" },
  BF: { name: "CREATE_ACCOUNT.COUNTRIES.BURKINA_FASO", region: "EMEA" },
  BI: { name: "CREATE_ACCOUNT.COUNTRIES.BURUNDI", region: "EMEA" },
  KH: { name: "CREATE_ACCOUNT.COUNTRIES.CAMBODIA", region: "APAC" },
  CM: { name: "CREATE_ACCOUNT.COUNTRIES.CAMEROON", region: "EMEA" },
  CA: { name: "CREATE_ACCOUNT.COUNTRIES.CANADA", region: "NA", phoneCode: "+1", emoji: "🇨🇦" },
  CV: { name: "CREATE_ACCOUNT.COUNTRIES.CAPE_VERDE", region: "EMEA" },
  KY: { name: "CREATE_ACCOUNT.COUNTRIES.CAYMAN_ISLANDS", region: "SA" },
  CF: { name: "CREATE_ACCOUNT.COUNTRIES.CENTRAL_AFRICAN_REPUBLIC", region: "EMEA" },
  TD: { name: "CREATE_ACCOUNT.COUNTRIES.CHAD", region: "EMEA" },
  CL: { name: "CREATE_ACCOUNT.COUNTRIES.CHILE", region: "SA" },
  CN: { name: "CREATE_ACCOUNT.COUNTRIES.CHINA", region: "CN" },
  CX: { name: "CREATE_ACCOUNT.COUNTRIES.CHRISTMAS_ISLAND", region: "APAC" },
  CC: { name: "CREATE_ACCOUNT.COUNTRIES.COCOS_KEELING_ISLANDS", region: "APAC" },
  CO: { name: "CREATE_ACCOUNT.COUNTRIES.COLOMBIA", region: "SA" },
  KM: { name: "CREATE_ACCOUNT.COUNTRIES.COMOROS", region: "EMEA" },
  CG: { name: "CREATE_ACCOUNT.COUNTRIES.CONGO_BRAZZAVILLE_REPUBLIC_OF_THE", region: "EMEA" },
  CD: { name: "CREATE_ACCOUNT.COUNTRIES.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE", region: "EMEA" },
  CK: { name: "CREATE_ACCOUNT.COUNTRIES.COOK_ISLANDS", region: "APAC" },
  CR: { name: "CREATE_ACCOUNT.COUNTRIES.COSTA_RICA", region: "SA" },
  CI: { name: "CREATE_ACCOUNT.COUNTRIES.COTE_D’IVOIRE", region: "EMEA" },
  HR: { name: "CREATE_ACCOUNT.COUNTRIES.CROATIA", region: "EMEA" },
  CU: { name: "CREATE_ACCOUNT.COUNTRIES.CUBA", region: "SA" },
  CY: { name: "CREATE_ACCOUNT.COUNTRIES.CYPRUS", region: "EMEA" },
  CZ: { name: "CREATE_ACCOUNT.COUNTRIES.CZECH_REPUBLIC", region: "EMEA" },
  DK: { name: "CREATE_ACCOUNT.COUNTRIES.DENMARK", region: "EMEA" },
  DJ: { name: "CREATE_ACCOUNT.COUNTRIES.DJIBOUTI", region: "EMEA" },
  DM: { name: "CREATE_ACCOUNT.COUNTRIES.DOMINICA", region: "SA" },
  DO: { name: "CREATE_ACCOUNT.COUNTRIES.DOMINICAN_REPUBLIC", region: "SA" },
  EC: { name: "CREATE_ACCOUNT.COUNTRIES.ECUADOR", region: "SA" },
  EG: { name: "CREATE_ACCOUNT.COUNTRIES.EGYPT", region: "EMEA" },
  SV: { name: "CREATE_ACCOUNT.COUNTRIES.EL_SALVADOR", region: "SA" },
  GQ: { name: "CREATE_ACCOUNT.COUNTRIES.EQUATORIAL_GUINEA", region: "EMEA" },
  ER: { name: "CREATE_ACCOUNT.COUNTRIES.ERITREA", region: "EMEA" },
  EE: { name: "CREATE_ACCOUNT.COUNTRIES.ESTONIA", region: "EMEA" },
  ET: { name: "CREATE_ACCOUNT.COUNTRIES.ETHIOPIA", region: "EMEA" },
  FK: { name: "CREATE_ACCOUNT.COUNTRIES.FALKLAND_ISLANDS_MALVINAS", region: "SA" },
  FO: { name: "CREATE_ACCOUNT.COUNTRIES.FAROE_ISLANDS", region: "EMEA" },
  FJ: { name: "CREATE_ACCOUNT.COUNTRIES.FIJI", region: "APAC" },
  FI: { name: "CREATE_ACCOUNT.COUNTRIES.FINLAND", region: "EMEA" },
  FR: { name: "CREATE_ACCOUNT.COUNTRIES.FRANCE", region: "EMEA", phoneCode: "+33", emoji: "🇫🇷" },
  GF: { name: "CREATE_ACCOUNT.COUNTRIES.FRENCH_GUIANA", region: "SA" },
  PF: { name: "CREATE_ACCOUNT.COUNTRIES.FRENCH_POLYNESIA", region: "APAC" },
  TF: { name: "CREATE_ACCOUNT.COUNTRIES.FRENCH_SOUTHERN_TERRITORIES", region: "APAC" },
  GA: { name: "CREATE_ACCOUNT.COUNTRIES.GABON", region: "EMEA" },
  GM: { name: "CREATE_ACCOUNT.COUNTRIES.GAMBIA", region: "EMEA" },
  GE: { name: "CREATE_ACCOUNT.COUNTRIES.GEORGIA", region: "EMEA" },
  DE: { name: "CREATE_ACCOUNT.COUNTRIES.GERMANY", region: "EMEA", phoneCode: "+49", emoji: "🇩🇪" },
  GH: { name: "CREATE_ACCOUNT.COUNTRIES.GHANA", region: "EMEA" },
  GI: { name: "CREATE_ACCOUNT.COUNTRIES.GIBRALTAR", region: "EMEA" },
  GR: { name: "CREATE_ACCOUNT.COUNTRIES.GREECE", region: "EMEA" },
  GL: { name: "CREATE_ACCOUNT.COUNTRIES.GREENLAND", region: "EMEA" },
  GD: { name: "CREATE_ACCOUNT.COUNTRIES.GRENADA", region: "SA" },
  GP: { name: "CREATE_ACCOUNT.COUNTRIES.GUADELOUPE", region: "SA" },
  GU: { name: "CREATE_ACCOUNT.COUNTRIES.GUAM", region: "APAC" },
  GT: { name: "CREATE_ACCOUNT.COUNTRIES.GUATEMALA", region: "SA" },
  GG: { name: "CREATE_ACCOUNT.COUNTRIES.GUERNSEY", region: "EMEA" },
  GN: { name: "CREATE_ACCOUNT.COUNTRIES.GUINEA", region: "EMEA" },
  GW: { name: "CREATE_ACCOUNT.COUNTRIES.GUINEABISSAU", region: "EMEA" },
  GY: { name: "CREATE_ACCOUNT.COUNTRIES.GUYANA", region: "SA" },
  HT: { name: "CREATE_ACCOUNT.COUNTRIES.HAITI", region: "SA" },
  HM: { name: "CREATE_ACCOUNT.COUNTRIES.HEARD_ISLAND_AND_MCDONALD_ISLANDS", region: "APAC" },
  VA: { name: "CREATE_ACCOUNT.COUNTRIES.HOLY_SEE_VATICAN_CITY_STATE", region: "EMEA" },
  HN: { name: "CREATE_ACCOUNT.COUNTRIES.HONDURAS", region: "SA" },
  HK: { name: "CREATE_ACCOUNT.COUNTRIES.HONG_KONG", region: "APAC" },
  HU: { name: "CREATE_ACCOUNT.COUNTRIES.HUNGARY", region: "EMEA" },
  IS: { name: "CREATE_ACCOUNT.COUNTRIES.ICELAND", region: "EMEA" },
  IN: { name: "CREATE_ACCOUNT.COUNTRIES.INDIA", region: "APAC" },
  ID: { name: "CREATE_ACCOUNT.COUNTRIES.INDONESIA", region: "APAC" },
  IR: { name: "CREATE_ACCOUNT.COUNTRIES.IRAN_ISLAMIC_REPUBLIC_OF", region: "EMEA" },
  IQ: { name: "CREATE_ACCOUNT.COUNTRIES.IRAQ", region: "EMEA" },
  IE: { name: "CREATE_ACCOUNT.COUNTRIES.IRELAND", region: "EMEA" },
  IM: { name: "CREATE_ACCOUNT.COUNTRIES.ISLE_OF_MAN", region: "EMEA" },
  IL: { name: "CREATE_ACCOUNT.COUNTRIES.ISRAEL", region: "EMEA" },
  IT: { name: "CREATE_ACCOUNT.COUNTRIES.ITALY", region: "EMEA", phoneCode: "+39", emoji: "🇮🇹" },
  JM: { name: "CREATE_ACCOUNT.COUNTRIES.JAMAICA", region: "SA" },
  JP: { name: "CREATE_ACCOUNT.COUNTRIES.JAPAN", region: "APAC", phoneCode: "+81", emoji: "🇯🇵" },
  JE: { name: "CREATE_ACCOUNT.COUNTRIES.JERSEY", region: "EMEA" },
  JO: { name: "CREATE_ACCOUNT.COUNTRIES.JORDAN", region: "EMEA" },
  KZ: { name: "CREATE_ACCOUNT.COUNTRIES.KAZAKHSTAN", region: "APAC" },
  KE: { name: "CREATE_ACCOUNT.COUNTRIES.KENYA", region: "EMEA" },
  KI: { name: "CREATE_ACCOUNT.COUNTRIES.KIRIBATI", region: "APAC" },
  KP: { name: "CREATE_ACCOUNT.COUNTRIES.KOREA_DEMOCRATIC_PEOPLE'S_REPUBLIC_OF", region: "APAC" },
  KR: { name: "CREATE_ACCOUNT.COUNTRIES.KOREA_REPUBLIC_OF", region: "APAC" },
  KW: { name: "CREATE_ACCOUNT.COUNTRIES.KUWAIT", region: "EMEA" },
  KG: { name: "CREATE_ACCOUNT.COUNTRIES.KYRGYZSTAN", region: "APAC" },
  LA: { name: "CREATE_ACCOUNT.COUNTRIES.LAO_PEOPLE'S_DEMOCRATIC_REPUBLIC", region: "APAC" },
  LV: { name: "CREATE_ACCOUNT.COUNTRIES.LATVIA", region: "EMEA" },
  LB: { name: "CREATE_ACCOUNT.COUNTRIES.LEBANON", region: "EMEA" },
  LS: { name: "CREATE_ACCOUNT.COUNTRIES.LESOTHO", region: "EMEA" },
  LR: { name: "CREATE_ACCOUNT.COUNTRIES.LIBERIA", region: "EMEA" },
  LY: { name: "CREATE_ACCOUNT.COUNTRIES.LIBYAN_ARAB_JAMAHIRIYA", region: "EMEA" },
  LI: { name: "CREATE_ACCOUNT.COUNTRIES.LIECHTENSTEIN", region: "EMEA" },
  LT: { name: "CREATE_ACCOUNT.COUNTRIES.LITHUANIA", region: "EMEA" },
  LU: { name: "CREATE_ACCOUNT.COUNTRIES.LUXEMBOURG", region: "EMEA" },
  MO: { name: "CREATE_ACCOUNT.COUNTRIES.MACAU", region: "APAC" },
  MK: { name: "CREATE_ACCOUNT.COUNTRIES.MACEDONIA_REPUBLIC_OF", region: "EMEA" },
  MG: { name: "CREATE_ACCOUNT.COUNTRIES.MADAGASCAR", region: "EMEA" },
  MW: { name: "CREATE_ACCOUNT.COUNTRIES.MALAWI", region: "EMEA" },
  MY: { name: "CREATE_ACCOUNT.COUNTRIES.MALAYSIA", region: "APAC" },
  MV: { name: "CREATE_ACCOUNT.COUNTRIES.MALDIVES", region: "APAC" },
  ML: { name: "CREATE_ACCOUNT.COUNTRIES.MALI", region: "EMEA" },
  MT: { name: "CREATE_ACCOUNT.COUNTRIES.MALTA", region: "EMEA" },
  MH: { name: "CREATE_ACCOUNT.COUNTRIES.MARSHALL_ISLANDS", region: "APAC" },
  MQ: { name: "CREATE_ACCOUNT.COUNTRIES.MARTINIQUE", region: "SA" },
  MR: { name: "CREATE_ACCOUNT.COUNTRIES.MAURITANIA", region: "EMEA" },
  MU: { name: "CREATE_ACCOUNT.COUNTRIES.MAURITIUS", region: "EMEA" },
  YT: { name: "CREATE_ACCOUNT.COUNTRIES.MAYOTTE", region: "EMEA" },
  MX: { name: "CREATE_ACCOUNT.COUNTRIES.MEXICO", region: "SA", phoneCode: "+52", emoji: "🇲🇽" },
  FM: { name: "CREATE_ACCOUNT.COUNTRIES.MICRONESIA_FEDERATED_STATES_OF", region: "APAC" },
  MD: { name: "CREATE_ACCOUNT.COUNTRIES.MOLDOVA_REPUBLIC_OF", region: "EMEA" },
  MC: { name: "CREATE_ACCOUNT.COUNTRIES.MONACO", region: "EMEA" },
  MN: { name: "CREATE_ACCOUNT.COUNTRIES.MONGOLIA", region: "APAC" },
  ME: { name: "CREATE_ACCOUNT.COUNTRIES.MONTENEGRO", region: "EMEA" },
  MS: { name: "CREATE_ACCOUNT.COUNTRIES.MONTSERRAT", region: "SA" },
  MA: { name: "CREATE_ACCOUNT.COUNTRIES.MOROCCO", region: "EMEA" },
  MZ: { name: "CREATE_ACCOUNT.COUNTRIES.MOZAMBIQUE", region: "EMEA" },
  MM: { name: "CREATE_ACCOUNT.COUNTRIES.MYANMAR", region: "APAC" },
  NA: { name: "CREATE_ACCOUNT.COUNTRIES.NAMIBIA", region: "EMEA" },
  NR: { name: "CREATE_ACCOUNT.COUNTRIES.NAURU", region: "APAC" },
  NP: { name: "CREATE_ACCOUNT.COUNTRIES.NEPAL", region: "APAC" },
  NL: { name: "CREATE_ACCOUNT.COUNTRIES.NETHERLANDS", region: "EMEA" },
  AN: { name: "CREATE_ACCOUNT.COUNTRIES.NETHERLANDS_ANTILLES", region: "SA" },
  NC: { name: "CREATE_ACCOUNT.COUNTRIES.NEW_CALEDONIA", region: "APAC" },
  NZ: { name: "CREATE_ACCOUNT.COUNTRIES.NEW_ZEALAND", region: "APAC" },
  NI: { name: "CREATE_ACCOUNT.COUNTRIES.NICARAGUA", region: "SA" },
  NE: { name: "CREATE_ACCOUNT.COUNTRIES.NIGER", region: "EMEA" },
  NG: { name: "CREATE_ACCOUNT.COUNTRIES.NIGERIA", region: "EMEA" },
  NU: { name: "CREATE_ACCOUNT.COUNTRIES.NIUE", region: "APAC" },
  NF: { name: "CREATE_ACCOUNT.COUNTRIES.NORFOLK_ISLAND", region: "APAC" },
  MP: { name: "CREATE_ACCOUNT.COUNTRIES.NORTHERN_MARIANA_ISLANDS", region: "APAC" },
  NO: { name: "CREATE_ACCOUNT.COUNTRIES.NORWAY", region: "EMEA" },
  OM: { name: "CREATE_ACCOUNT.COUNTRIES.OMAN", region: "EMEA" },
  PK: { name: "CREATE_ACCOUNT.COUNTRIES.PAKISTAN", region: "APAC" },
  PW: { name: "CREATE_ACCOUNT.COUNTRIES.PALAU", region: "APAC" },
  PS: { name: "CREATE_ACCOUNT.COUNTRIES.PALESTINIAN_TERRITORIES", region: "EMEA" },
  PA: { name: "CREATE_ACCOUNT.COUNTRIES.PANAMA", region: "SA" },
  PG: { name: "CREATE_ACCOUNT.COUNTRIES.PAPUA_NEW_GUINEA", region: "APAC" },
  PY: { name: "CREATE_ACCOUNT.COUNTRIES.PARAGUAY", region: "SA" },
  PE: { name: "CREATE_ACCOUNT.COUNTRIES.PERU", region: "SA" },
  PH: { name: "CREATE_ACCOUNT.COUNTRIES.PHILIPPINES", region: "APAC" },
  PN: { name: "CREATE_ACCOUNT.COUNTRIES.PITCAIRN", region: "APAC" },
  PL: { name: "CREATE_ACCOUNT.COUNTRIES.POLAND", region: "EMEA" },
  PT: { name: "CREATE_ACCOUNT.COUNTRIES.PORTUGAL", region: "EMEA" },
  PR: { name: "CREATE_ACCOUNT.COUNTRIES.PUERTO_RICO", region: "SA" },
  QA: { name: "CREATE_ACCOUNT.COUNTRIES.QATAR", region: "EMEA" },
  RE: { name: "CREATE_ACCOUNT.COUNTRIES.REUNION", region: "APAC" },
  RO: { name: "CREATE_ACCOUNT.COUNTRIES.ROMANIA", region: "EMEA" },
  RU: { name: "CREATE_ACCOUNT.COUNTRIES.RUSSIAN_FEDERATION", region: "EMEA" },
  RW: { name: "CREATE_ACCOUNT.COUNTRIES.RWANDA", region: "EMEA" },
  BL: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_BARTHELEMY", region: "SA" },
  KN: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_CHRISTOPHER_ST_KITTS_AND_NEVIS", region: "SA" },
  SH: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_HELENA", region: "EMEA" },
  LC: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_LUCIA", region: "SA" },
  MF: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_MARTIN", region: "SA" },
  PM: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_PIERRE_AND_MIQUELON", region: "NA" },
  VC: { name: "CREATE_ACCOUNT.COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES", region: "SA" },
  WS: { name: "CREATE_ACCOUNT.COUNTRIES.SAMOA", region: "APAC" },
  SM: { name: "CREATE_ACCOUNT.COUNTRIES.SAN_MARINO", region: "EMEA" },
  ST: { name: "CREATE_ACCOUNT.COUNTRIES.SAO_TOME_AND_PRINCIPE", region: "EMEA" },
  SA: { name: "CREATE_ACCOUNT.COUNTRIES.SAUDI_ARABIA", region: "EMEA" },
  SN: { name: "CREATE_ACCOUNT.COUNTRIES.SENEGAL", region: "EMEA" },
  RS: { name: "CREATE_ACCOUNT.COUNTRIES.SERBIA_REPUBLIC_OF", region: "EMEA" },
  SC: { name: "CREATE_ACCOUNT.COUNTRIES.SEYCHELLES", region: "EMEA" },
  SL: { name: "CREATE_ACCOUNT.COUNTRIES.SIERRA_LEONE", region: "EMEA" },
  SG: { name: "CREATE_ACCOUNT.COUNTRIES.SINGAPORE", region: "APAC" },
  SK: { name: "CREATE_ACCOUNT.COUNTRIES.SLOVAK_REPUBLIC", region: "EMEA" },
  SI: { name: "CREATE_ACCOUNT.COUNTRIES.SLOVENIA", region: "EMEA" },
  SB: { name: "CREATE_ACCOUNT.COUNTRIES.SOLOMON_ISLANDS", region: "APAC" },
  SO: { name: "CREATE_ACCOUNT.COUNTRIES.SOMALIA", region: "EMEA" },
  ZA: { name: "CREATE_ACCOUNT.COUNTRIES.SOUTH_AFRICA", region: "EMEA" },
  GS: {
    name: "CREATE_ACCOUNT.COUNTRIES.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS",
    region: "SA",
  },
  SS: { name: "CREATE_ACCOUNT.COUNTRIES.SOUTH_SUDAN", region: "EMEA" },
  ES: { name: "CREATE_ACCOUNT.COUNTRIES.SPAIN", region: "EMEA", phoneCode: "+34", emoji: "🇪🇸" },
  LK: { name: "CREATE_ACCOUNT.COUNTRIES.SRI_LANKA", region: "APAC" },
  SD: { name: "CREATE_ACCOUNT.COUNTRIES.SUDAN", region: "EMEA" },
  SR: { name: "CREATE_ACCOUNT.COUNTRIES.SURINAME", region: "SA" },
  SJ: { name: "CREATE_ACCOUNT.COUNTRIES.SVALBARD_AND_JAN_MAYEN", region: "EMEA" },
  SZ: { name: "CREATE_ACCOUNT.COUNTRIES.SWAZILAND", region: "EMEA" },
  SE: { name: "CREATE_ACCOUNT.COUNTRIES.SWEDEN", region: "EMEA" },
  CH: { name: "CREATE_ACCOUNT.COUNTRIES.SWITZERLAND", region: "EMEA" },
  SY: { name: "CREATE_ACCOUNT.COUNTRIES.SYRIAN_ARAB_REPUBLIC", region: "APAC" },
  TW: { name: "CREATE_ACCOUNT.COUNTRIES.TAIWAN", region: "APAC" },
  TJ: { name: "CREATE_ACCOUNT.COUNTRIES.TAJIKISTAN", region: "APAC" },
  TZ: { name: "CREATE_ACCOUNT.COUNTRIES.TANZANIA_UNITED_REPUBLIC_OF", region: "EMEA" },
  TH: { name: "CREATE_ACCOUNT.COUNTRIES.THAILAND", region: "APAC" },
  TL: { name: "CREATE_ACCOUNT.COUNTRIES.TIMORLESTE_EAST_TIMOR", region: "APAC" },
  TG: { name: "CREATE_ACCOUNT.COUNTRIES.TOGO", region: "EMEA" },
  TK: { name: "CREATE_ACCOUNT.COUNTRIES.TOKELAU", region: "APAC" },
  TO: { name: "CREATE_ACCOUNT.COUNTRIES.TONGA", region: "APAC" },
  TT: { name: "CREATE_ACCOUNT.COUNTRIES.TRINIDAD_AND_TOBAGO", region: "SA" },
  TN: { name: "CREATE_ACCOUNT.COUNTRIES.TUNISIA", region: "EMEA" },
  TR: { name: "CREATE_ACCOUNT.COUNTRIES.TURKEY", region: "EMEA" },
  TM: { name: "CREATE_ACCOUNT.COUNTRIES.TURKMENISTAN", region: "APAC" },
  TC: { name: "CREATE_ACCOUNT.COUNTRIES.TURKS_AND_CAICOS_ISLANDS", region: "SA" },
  TV: { name: "CREATE_ACCOUNT.COUNTRIES.TUVALU", region: "APAC" },
  UG: { name: "CREATE_ACCOUNT.COUNTRIES.UGANDA", region: "EMEA" },
  UA: { name: "CREATE_ACCOUNT.COUNTRIES.UKRAINE", region: "EMEA" },
  AE: { name: "CREATE_ACCOUNT.COUNTRIES.UNITED_ARAB_EMIRATES", region: "EMEA" },
  GB: {
    name: "CREATE_ACCOUNT.COUNTRIES.UNITED_KINGDOM",
    region: "EMEA",
    phoneCode: "+44",
    emoji: "🇬🇧",
  },
  US: {
    name: "CREATE_ACCOUNT.COUNTRIES.UNITED_STATES_OF_AMERICA",
    region: "NA",
    phoneCode: "+1",
    emoji: "🇺🇸",
  },
  UY: { name: "CREATE_ACCOUNT.COUNTRIES.URUGUAY", region: "SA" },
  UZ: { name: "CREATE_ACCOUNT.COUNTRIES.UZBEKISTAN", region: "APAC" },
  VU: { name: "CREATE_ACCOUNT.COUNTRIES.VANUATU", region: "APAC" },
  VE: { name: "CREATE_ACCOUNT.COUNTRIES.VENEZUELA", region: "SA" },
  VN: { name: "CREATE_ACCOUNT.COUNTRIES.VIETNAM", region: "APAC" },
  VG: { name: "CREATE_ACCOUNT.COUNTRIES.VIRGIN_ISLANDS_BRITISH", region: "SA" },
  VI: { name: "CREATE_ACCOUNT.COUNTRIES.VIRGIN_ISLANDS_US", region: "SA" },
  WF: { name: "CREATE_ACCOUNT.COUNTRIES.WALLIS_AND_FUTUNA", region: "APAC" },
  EH: { name: "CREATE_ACCOUNT.COUNTRIES.WESTERN_SAHARA", region: "EMEA" },
  YE: { name: "CREATE_ACCOUNT.COUNTRIES.YEMEN", region: "EMEA" },
  ZM: { name: "CREATE_ACCOUNT.COUNTRIES.ZAMBIA", region: "EMEA" },
  ZW: { name: "CREATE_ACCOUNT.COUNTRIES.ZIMBABWE", region: "EMEA" },
};
export const DRAWER_LIST_WINDOW_SIZE = 11;

export const CHANGE_TEXT_TIME_SPACER = 333;

export const NAME_VALIDATION_REGEX = new RegExp(/^[^\000-\037\"\&;<>\177\u2028\u2029]*$/);

export const CONTEXT_HELP_BUTTON_HEIGHT = 40;

export const HEALTH_ALERTS_MAX = 3;

export const ALERT_TITLE_CHAR_MAX = 22;

export const WEB_TIMEOUT_LIMIT = MS_IN_A_MIN * 15;

export const MAX_FB_EVENT_LENGTH = 40;

export const SUPPORT_CASE_DEFAULT_NAME = "Dashboard";
