import { I18n } from "@meraki/core/i18n";
import { launchMLabPolicy } from "@meraki/go/links";
import { MLabStatus, useMLabSpeedTest } from "@meraki/go/monitor";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Animated, Dimensions, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { MBPS } from "~/constants/LinkSpeeds";
import MkiColors from "~/constants/MkiColors";
import { ICON_BANNER_SIZE, SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { useConnectedToNetworkByIp } from "~/go/hooks/useConnectedToNetworkByIp";
import { showAlert } from "~/lib/AlertUtils";
import { getRotationDegreeFromSpeed, isReadyToTest } from "~/lib/MLabSpeedTestUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { getSpeedTestAgreement, isAnyDeviceConnected } from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Status from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";
import { CloseButton } from "~/shared/navigation/Buttons";

import { SettingsStackProps } from "../navigation/Types";

const deviceWidthOrHeight = isWeb() ? window.innerHeight : Dimensions.get("window").width;
const halfOfDeviceWidthOrHeight = deviceWidthOrHeight / 2;
const outerCircleRadius = deviceWidthOrHeight * 0.8;
const outerCircleHeight = halfOfDeviceWidthOrHeight * 0.8;
const outerCircleHeightOrigin = outerCircleHeight / 2;
const innerCircleRadius = deviceWidthOrHeight * 0.7;
const innerCircleHeight = halfOfDeviceWidthOrHeight * 0.7;

function getRotationDegree(status: MLabStatus, downloadSpeed: number, uploadSpeed: number) {
  switch (status) {
    case "downloading":
      return `${getRotationDegreeFromSpeed(downloadSpeed)}deg`;
    case "uploading":
      return `${getRotationDegreeFromSpeed(uploadSpeed)}deg`;
    default:
      return "0deg";
  }
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SpeedTest">;

const SpeedTestScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const theme = useTheme();
  const networkId = useCurrentNetworkId();
  const agreement = useAppSelector(getSpeedTestAgreement);
  const deviceConnected = useAppSelector(isAnyDeviceConnected);
  const connectedViaIp = useConnectedToNetworkByIp(networkId).data ?? false;
  const connectedToNetwork = (deviceConnected || connectedViaIp) ?? false;
  const actions = useActions();
  const [status, { fetchTestLocations, downloadTester, uploadTester }] = useMLabSpeedTest();

  const [runAnyway, setRunAnyway] = useState(false);
  const [downloadResult, setDownloadResult] = useState(0.0);
  const [uploadResult, setUploadResult] = useState(0.0);

  useEffect(() => {
    if (!isWeb()) {
      actions.checkLSPConnection();
    }
    if (agreement) {
      fetchTestLocations();
    }
  }, [actions, agreement, fetchTestLocations]);

  const startTest = useCallback(async () => {
    await downloadTester(setDownloadResult);
    await uploadTester(setUploadResult);
  }, [downloadTester, uploadTester]);

  const colors = themeColors(theme);
  const backgroundCircleColor: ViewStyle = { backgroundColor: colors.border.borderColor };
  const speedometerInnerCircleColor: ViewStyle = {
    backgroundColor: colors.navigation.backgroundPrimary,
  };

  const meterTransform: ViewStyle = {
    transform: [
      { translateY: outerCircleHeightOrigin },
      { rotate: getRotationDegree(status, downloadResult, uploadResult) },
      { translateY: -outerCircleHeightOrigin },
    ],
  };

  const showDirectionOfUse = () =>
    showAlert(I18n.t("SPEED_TEST.TITLE"), I18n.t("SPEED_TEST.DIRECTION_OF_USE"), () =>
      setRunAnyway(true),
    );

  const BUTTON_TRANSLATION_BY_STATUS: Record<MLabStatus, string> = {
    notReady: I18n.t("SPEED_TEST.BUTTON.BEGIN"),
    ready: I18n.t("SPEED_TEST.BUTTON.BEGIN"),
    failed: I18n.t("SPEED_TEST.BUTTON.BEGIN"),
    downloading: I18n.t("SPEED_TEST.BUTTON.DOWNLOADING"),
    uploading: I18n.t("SPEED_TEST.BUTTON.UPLOADING"),
    completed: I18n.t("SPEED_TEST.BUTTON.COMPLETE"),
  };

  return (
    <FullScreenContainerView defaultPadding withScroll>
      <MkiText textStyle="smallSecondary">{I18n.t("SPEED_TEST.DESCRIPTION")}</MkiText>
      <BannerAlert
        customIcon={
          <MerakiIcon name="exclamation-circle" size={ICON_BANNER_SIZE} style={styles.noticeIcon} />
        }
        alertType={connectedToNetwork ? Status.good : Status.alerting}
        alertText={
          connectedToNetwork
            ? I18n.t("SPEED_TEST.CONNECTION.DETECTED")
            : I18n.t("SPEED_TEST.CONNECTION.NOT_DETECTED")
        }
        viewStyles={styles.directionOfUse}
        textAlign="left"
        testID="SPEED_TEST.ALERT"
      />
      <View style={styles.speedometer}>
        <View style={[styles.backgroundCircle, backgroundCircleColor]} />
        <Animated.View style={[styles.meter, meterTransform]} />
        <View style={[styles.speedometerInnerCircle, speedometerInnerCircleColor]} />
      </View>
      <View style={styles.speedLabels}>
        <View style={styles.speedLabel}>
          <View style={styles.speedNumber}>
            <MkiText textStyle="title">{downloadResult}</MkiText>
            <MkiText textStyle="small">{MBPS}</MkiText>
          </View>
          <View style={styles.labelBottom}>
            <MkiText textStyle="drawerSectionHeader">
              {I18n.t("SPEED_TEST.RESULT.DOWNLOAD")}
            </MkiText>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.speedLabel}>
          <View style={styles.speedNumber}>
            <MkiText textStyle="title">{uploadResult}</MkiText>
            <MkiText textStyle="small">{MBPS}</MkiText>
          </View>
          <View style={styles.labelBottom}>
            <MkiText textStyle="drawerSectionHeader">{I18n.t("SPEED_TEST.RESULT.UPLOAD")}</MkiText>
          </View>
        </View>
      </View>
      <View style={styles.agreement}>
        <TouchableOpacity
          testID="SPEED_TEST.AGREEMENT"
          onPress={() => actions.setSpeedTestAgreement(!agreement)}
          style={styles.checkBox}
        >
          <CheckBox selected={agreement} />
        </TouchableOpacity>
        <MkiText textStyle="smallSecondary" screenStyles={styles.policy}>
          {I18n.t("SPEED_TEST.POLICY.AGREEMENT")}
          <MkiText
            textStyle="smallSecondary"
            screenStyles={styles.link}
            onPress={launchMLabPolicy}
            testID="SPEED_TEST.POLICY"
          >
            {I18n.t("SPEED_TEST.POLICY.LINK")}
          </MkiText>
          {I18n.t("SPEED_TEST.POLICY.ADDITIONAL_INFO")}
        </MkiText>
      </View>
      {!connectedToNetwork && (
        <View style={styles.agreement}>
          <TouchableOpacity
            testID="SPEED_TEST.ACKNOWLEDGEMENT"
            onPress={() => setRunAnyway(!runAnyway)}
            style={styles.checkBox}
          >
            <CheckBox selected={runAnyway} />
          </TouchableOpacity>
          <MkiText
            textStyle="smallSecondary"
            screenStyles={[styles.policy, styles.link]}
            onPress={showDirectionOfUse}
            testID="SPEED_TEST.DIRECTION_OF_USE"
          >
            {I18n.t("SPEED_TEST.POLICY.ACKNOWLEDGEMENT")}
          </MkiText>
        </View>
      )}
      <RoundedButton
        disabled={!(agreement && isReadyToTest(status) && (connectedToNetwork || runAnyway))}
        buttonType={ButtonType.primary}
        onPress={startTest}
        containerStyles={styles.button}
        testID="SPEED_TEST.BUTTON"
      >
        {BUTTON_TRANSLATION_BY_STATUS[status]}
      </RoundedButton>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  agreement: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: SPACING.tiny,
  },
  checkBox: {
    flex: 1,
    paddingLeft: SPACING.small,
  },
  directionOfUse: {
    marginTop: SPACING.default,
    fontStyle: "italic",
  },
  policy: {
    flex: 8,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  link: {
    color: MkiColors.primaryButton,
  },
  button: {
    marginTop: SPACING.small,
  },
  speedometer: {
    overflow: "hidden",
    alignItems: "center",
    marginVertical: SPACING.large,
    height: outerCircleHeight,
  },
  backgroundCircle: {
    position: "absolute",
    overflow: "hidden",
    backgroundColor: MkiColors.grayBackground,
    width: outerCircleRadius,
    height: outerCircleHeight,
    borderRadius: halfOfDeviceWidthOrHeight,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  meter: {
    position: "absolute",
    overflow: "hidden",
    backgroundColor: MkiColors.goPurple,
    width: outerCircleRadius,
    height: outerCircleHeight,
    borderRadius: halfOfDeviceWidthOrHeight,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  speedometerInnerCircle: {
    position: "absolute",
    top: halfOfDeviceWidthOrHeight * 0.101, // prevents thin residue line
    overflow: "hidden",
    width: innerCircleRadius,
    height: innerCircleHeight,
    borderRadius: halfOfDeviceWidthOrHeight,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: SPACING.extraExtraLarge,
  },
  noticeIcon: {
    paddingRight: SPACING.medium,
  },
  speedLabels: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: SPACING.default,
    alignItems: "center",
  },
  speedLabel: {
    flex: 1,
    flexDirection: "column",
  },
  speedNumber: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  labelBottom: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  divider: {
    borderRightColor: MkiColors.bottomBorderColor,
    borderRightWidth: 1,
  },
});

export default SpeedTestScreen;
