/**
 * This file is solely for inspecting objects that have been normalized by 'normalizr'
 */

import { hasIn } from "lodash";

import { isString } from "~/lib/TypeHelper";

export const isNormalized = (data: any) => {
  return hasIn(data, "entities") && hasIn(data, "result");
};

export const containsEntity = (data: any, entityName: string) => {
  if (!isString(entityName)) {
    return false;
  }
  if (!isNormalized(data)) {
    return false;
  }

  const { entities } = data;
  return hasIn(entities, entityName);
};
