import I18n, { IS_ON_JAPANESE } from "~/i18n/i18n";
import { RadioSettingsBands, RadioSettingsPowerLimits } from "~/shared/types/RadioSettingsTypes";

// channel 14 is only allowed in Japan
const TWO_GHZ_CHANNELS = IS_ON_JAPANESE
  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14]
  : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const FIVE_GHZ_CHANNELS = [
  36, 40, 44, 48, 52, 56, 60, 64, 100, 104, 108, 112, 132, 136, 140, 144, 149, 153, 157, 161,
];
const FIVE_GHZ_CHANNEL_WIDTHS = [20, 40, 80];

// 5 to 30
export const TWO_GHZ_POWER_LIMITS: RadioSettingsPowerLimits = {
  min: 5,
  max: 30,
};
// 8 to 30
export const FIVE_GHZ_POWER_LIMITS: RadioSettingsPowerLimits = {
  min: 8,
  max: 30,
};

export const getChannelLabel = (channel?: number | null) =>
  channel ? channel.toString() : I18n.t("RADIO_SETTINGS.HARDWARE.AUTO");

export const getChannelWidthLabel = (channelWidth?: number | null) =>
  channelWidth ? `${channelWidth} MHz` : I18n.t("RADIO_SETTINGS.HARDWARE.AUTO");

export const getPowerLabel = (power?: number | null) =>
  power ? `${power} dBm` : I18n.t("RADIO_SETTINGS.HARDWARE.AUTO");

export const getChannelOptions = (band: RadioSettingsBands) => {
  const channels =
    band === RadioSettingsBands.twoFourGhzSettings ? TWO_GHZ_CHANNELS : FIVE_GHZ_CHANNELS;

  return [null, ...channels].map((channel) => ({
    label: getChannelLabel(channel),
    value: channel,
  }));
};

export const getFiveGhzChannelWidthOptions = () => {
  return [null, ...FIVE_GHZ_CHANNEL_WIDTHS].map((channelWidth) => ({
    label: getChannelWidthLabel(channelWidth),
    value: channelWidth,
  }));
};

export const getRadioPowerLimits = (band: RadioSettingsBands) => {
  return band === RadioSettingsBands.twoFourGhzSettings
    ? TWO_GHZ_POWER_LIMITS
    : FIVE_GHZ_POWER_LIMITS;
};
