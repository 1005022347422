import { pick } from "lodash";
import { StyleProp, StyleSheet, TextStyle, View, ViewProps, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { ROW_SPACINGS } from "~/constants/RowConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { isText } from "~/lib/TypeHelper";
import DisclosureIcon, {
  ArrowDirection,
  DisclosureIconProps,
} from "~/shared/components/DisclosureIcon";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import { useTheme } from "~/shared/hooks/useTheme";
import ThemeProps from "~/shared/themes/ThemeProps";
export interface SimpleDisclosureRowProps extends Partial<ThemeProps> {
  testID?: string;
  onPress?: () => void;
  subtitle?: TextChildProps["subtitle"];
  icon?: React.ReactElement;
  deleteIcon?: React.ReactElement;
  deleteStyle?: StyleProp<ViewStyle | TextStyle>;
  style?: StyleProp<ViewStyle | TextStyle>;
  iconStyles?: DisclosureIconProps["style"];
  iconContainerStyle?: ViewProps["style"];
  disclosureIcon?: DisclosureIconProps["icon"];
  hideDisclosureIcon?: DisclosureIconProps["hide"];
  context?: string;
  children?: React.ReactNode;
  // TempFix: Hopefully "Pressable" in rn 63.2 will fix the black bg issues (or handle it better)
  useTouchableOpacity?: boolean;
  iconDirection?: ArrowDirection;
  disabled?: boolean;
  iconColor?: string;
  // ignore padding on top of element, can be used for top row element (e.g.)
  skipTopPadding?: boolean;
}

interface TextChildProps extends Partial<ThemeProps> {
  subtitle?: React.ReactText | React.ReactText[] | null;
  children?: React.ReactText;
  style?: StyleProp<ViewStyle | TextStyle>;
  testID?: string;
}

const TextChild = ({ children, subtitle, style, theme, testID }: TextChildProps) => {
  const makeSubtitleChild = (text: React.ReactText) => {
    return (
      <MkiText key={text} textStyle="small" screenStyles={[styles.subtitle, style]}>
        {text}
      </MkiText>
    );
  };

  const textArray: React.ReactElement[] = [
    <MkiText
      key={"main-text"}
      screenStyles={[themeColors(theme).text?.default, style]}
      testID={testID}
    >
      {children}
    </MkiText>,
  ];
  if (Array.isArray(subtitle)) {
    subtitle.forEach((s) => textArray.push(makeSubtitleChild(s)));
  } else if (subtitle) {
    textArray.push(makeSubtitleChild(subtitle));
  }

  return <>{textArray}</>;
};

const SimpleDisclosureRow = ({
  children,
  onPress,
  icon,
  deleteIcon,
  deleteStyle,
  disabled,
  style,
  subtitle,
  iconStyles,
  iconContainerStyle,
  disclosureIcon,
  hideDisclosureIcon,
  useTouchableOpacity,
  context,
  theme,
  testID,
  iconDirection,
  iconColor,
  skipTopPadding,
}: SimpleDisclosureRowProps) => {
  const savedTheme = useTheme();
  const resolvedTheme = theme ?? savedTheme;
  const backgroundColor = themeColors(resolvedTheme).navigation.backgroundPrimary;
  const childrenStyle = skipTopPadding ? [styles.childrenSkipTopPadding] : [styles.children, style];
  if (!children) {
    return null;
  }

  const showDisclosureIcon = onPress && !hideDisclosureIcon;

  const container = (
    <View style={[{ backgroundColor }, styles.container]}>
      {icon && <View style={[styles.icon, iconContainerStyle]}>{icon}</View>}
      {isText(children) ? (
        <View style={childrenStyle}>
          <TextChild
            subtitle={subtitle}
            style={
              // check hasOwnProperty due to web's style being a non-object that causes typeErrors
              // then check if color in style to prevent typcheck issues with pick
              style && style.hasOwnProperty("color") && "color" in style
                ? pick<TextStyle, "color">(style, "color")
                : {}
            }
            theme={resolvedTheme}
            testID={!onPress ? testID : undefined}
          >
            {children}
          </TextChild>
        </View>
      ) : (
        <View style={onPress ? [...childrenStyle, styles.withIcon] : childrenStyle}>
          {children}
          {subtitle && (
            <MkiText textStyle="small" screenStyles={[styles.subtitle, style]}>
              {subtitle}
            </MkiText>
          )}
        </View>
      )}
      <View style={styles.horizontalContainer}>
        {context && (
          <View style={styles.contextMargins}>
            <ContextHelp context={context} />
          </View>
        )}
        <DisclosureIcon
          hide={!showDisclosureIcon}
          style={iconStyles}
          icon={disclosureIcon}
          iconDirection={iconDirection}
          iconColor={iconColor}
        />
      </View>
      {deleteIcon && <View style={deleteStyle}>{deleteIcon}</View>}
    </View>
  );
  return (
    <>
      {onPress && (
        <Touchable
          testID={testID}
          onPress={onPress}
          transparentBackground={useTouchableOpacity}
          disabled={disabled}
        >
          {container}
        </Touchable>
      )}
      {!onPress && container}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  children: {
    flex: 1,
    paddingVertical: ROW_SPACINGS.listRow.bottomPadding,
    paddingHorizontal: SPACING.default,
  },
  childrenSkipTopPadding: {
    flex: 1,
    paddingBottom: ROW_SPACINGS.listRow.bottomPadding,
    paddingHorizontal: SPACING.default,
  },
  withIcon: {
    paddingEnd: 0,
  },
  subtitle: {
    color: MkiColors.secondaryTextColor,
  },
  icon: {
    paddingStart: SPACING.default,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  contextMargins: {
    ...platformSelect({
      ios: {
        marginEnd: -SPACING.medium,
      },
      android: {},
      web: {},
    }),
  },
});

export default SimpleDisclosureRow;
