import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import { LegendElementStyle } from "~/shared/types/MkiChartTypes";

export interface MkiLegendElementProps {
  label: string;
  colorElement?: React.ReactNode;
  colors?: (string | undefined)[];
  selected?: boolean;
  onSelectChange?: () => void;
}

export class MkiLegendElement extends PureComponent<MkiLegendElementProps> {
  static defaultProps = {
    selected: true,
  };

  onPress = () => {
    const { onSelectChange } = this.props;
    onSelectChange?.();
  };

  createColors = (colors: (string | undefined)[], selected: boolean | undefined) => {
    const colorElems = colors.map((color, idx) => {
      color = color || MkiColors.primaryGraphLine;
      const elementStyle = [styles.colorHolder, { backgroundColor: color }];

      if (selected) {
        elementStyle.push(styles.selectedColorHolder);
      }
      if (colors.length > 1) {
        elementStyle.push(styles.comparisonPadding);
      }

      return <View style={elementStyle} key={`${color} - ${idx}`} />;
    });

    return <View style={styles.colorsContainer}>{colorElems}</View>;
  };

  render() {
    const { colorElement, colors, label, onSelectChange, selected } = this.props;
    const { theme } = useTheme.getState();
    if (!label) {
      return null;
    }
    const labelStyle = selected
      ? [styles.selectedLabel, themeColors(theme).locationAnalyticsLegend.selectedLabel]
      : themeColors(theme).locationAnalyticsLegend.unselectedLabel;
    const colorElems =
      colorElement || this.createColors(colors || [MkiColors.primaryGraphLine], selected);

    const legendElementBody = (
      <View style={styles.elementContainer}>
        {colorElems}
        <MkiText screenStyles={[styles.label, labelStyle]}>{label}</MkiText>
      </View>
    );

    return onSelectChange ? (
      <TouchableOpacity onPress={this.onPress}>{legendElementBody}</TouchableOpacity>
    ) : (
      legendElementBody
    );
  }
}

const styles = StyleSheet.create<LegendElementStyle>({
  elementContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  selectedLabel: {
    color: MkiColors.graphLegendSelectedColor,
  },
  selectedColorHolder: {
    opacity: 1,
  },
  colorsContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  comparisonPadding: {
    marginVertical: SPACING.tiny,
  },
  colorHolder: {
    borderRadius: 2,
    width: 16,
    height: 9,
    opacity: 0.5,
  },
  label: {
    marginLeft: SPACING.small,
    fontSize: appSelect({
      enterprise: 10,
      go: normalizedFontSize(13),
    }),
    color: MkiColors.graphLegendLabelColor,
  },
});

export default MkiLegendElement;
