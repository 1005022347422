import { I18n } from "@meraki/core/i18n";
import { TrafficShapingGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, List, RefreshControl, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useL3FirewallRules } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";

export const L3FirewallRulesListScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<TrafficShapingGroupProps>>();

  const networkId = useCurrentNetworkId();
  const {
    data: l3FirewallRules,
    isRefetching,
    refetch,
    isLoading,
  } = useL3FirewallRules({ networkId });

  return (
    <Screen
      addDefaultPadding
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
    >
      <Box padding="none" paddingBottom="xs" bottomDividerBorder>
        <Heading size="h1">{I18n.t("L3_FIREWALL_RULES_LIST.HEADER.TITLE")}</Heading>
      </Box>
      <Box padding="none">
        <Text size="p1">{I18n.t("L3_FIREWALL_RULES_LIST.HEADER.SUBTITLE")}</Text>
      </Box>
      <Box>
        <Box
          marginHorizontal="xs"
          paddingBottom="2xs"
          marginTop="md"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="h4">{I18n.t("L3_FIREWALL_RULES_LIST.RULES")}</Heading>
          <Button
            kind="tertiary"
            text={I18n.t("ADD")}
            trailingIcon="Plus"
            onPress={() => navigation.navigate("L3FirewallRule")}
          />
        </Box>
        <List.FlashList
          loading={isLoading}
          paddingTop="none"
          paddingLeft="none"
          paddingRight="none"
          data={l3FirewallRules?.rules}
          ListEmptyComponent={
            <Card>
              <Text color="light">{I18n.t("NOT_ADDED")}</Text>
            </Card>
          }
          getItemData={(rule) => {
            const { comment, protocol } = rule;
            return {
              title: comment,
              description: protocol.toUpperCase(),
              rightAccessory: (
                <Button
                  kind="tertiary"
                  text={I18n.t("EDIT")}
                  onPress={() => navigation.navigate("L3FirewallRule", { rule })}
                />
              ),
            };
          }}
        />
      </Box>
    </Screen>
  );
};
