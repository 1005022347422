import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  List,
  Loader,
  RefreshControl,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useUpdateVlan, useVlan } from "@meraki/shared/api";
import { launchUrl } from "@meraki/shared/links";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef } from "react";
import { Alert } from "react-native";

import { DHCP_HANDLING_OFF, DHCP_HANDLING_ON } from "../constants/DHCP";

type DHCPType = typeof DHCP_HANDLING_ON | typeof DHCP_HANDLING_OFF;

export function DHCPServerScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "DHCPServer">>();
  const { vlanId } = route.params;
  const networkId = useCurrentNetworkId();
  const { mutate: updateVlan } = useUpdateVlan();
  const {
    data: vlan,
    isLoading: vlanIsLoading,
    refetch,
    isRefetching,
  } = useVlan({
    networkId,
    vlanId,
  });

  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  if (!vlan || vlanIsLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  const onUpdateVlan = (dchpType: DHCPType) => {
    const newVlan = { ...vlan, dhcpHandling: dchpType };
    updateVlan(
      { networkId, vlanId, vlan: newVlan },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useVlan.queryKeyRoot });
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  return (
    <Screen
      addDefaultPadding
      testID="DHCP_SERVER_SCREEN"
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
    >
      <Box bottomDividerBorder paddingBottom="sm">
        <Heading size="h1">{I18n.t("DHCP_SERVER.HEADER")}</Heading>
      </Box>
      <Box alignItems="flex-start">
        <Text size="p1">{I18n.t("DHCP_SERVER.MESSAGE")}</Text>
        <Button
          text={I18n.t("CONTEXT_HELP.DHCP_SERVER.TITLE")}
          kind="tertiary"
          onPress={() => bottomSheetRef.current?.present()}
          testID="DHCP_CONTEXT_BUTTON"
        />
      </Box>
      <List>
        <List.Item
          title={I18n.t("DHCP_SERVER.TOGGLE.SERVER.TITLE")}
          description={I18n.t("DHCP_SERVER.TOGGLE.SERVER.DESCRIPTION")}
          rightAccessory={
            <Toggle
              checked={vlan.dhcpHandling === DHCP_HANDLING_ON}
              onValueChange={(toggleValue: boolean) =>
                onUpdateVlan(toggleValue ? DHCP_HANDLING_ON : DHCP_HANDLING_OFF)
              }
              testID={`DHCP_SERVER_TOGGLE.${
                vlan.dhcpHandling === DHCP_HANDLING_ON ? "CHECKED" : "UNCHECKED"
              }`}
            />
          }
          leftAccessory={<Icon name="Gear" />}
          bottomDividerBorder={false}
        />
        <List.Item
          title={I18n.t("DHCP_SERVER.OPTIONS")}
          hidePressable
          paddingBottom="none"
          rightAccessory={
            <Button
              testID={`ADD_DHCP_OPTION.${
                vlan.dhcpHandling === DHCP_HANDLING_ON ? "ENABLED" : "DISABLED"
              }`}
              onPress={() => navigation.navigate("DHCPOption", { vlan })}
              text={I18n.t("ADD")}
              kind="tertiary"
              leadingIcon="Plus"
              disabled={vlan.dhcpHandling !== DHCP_HANDLING_ON}
            />
          }
        >
          <List.FlashList
            data={vlan.dhcpOptions}
            paddingLeft="none"
            paddingRight="none"
            ListEmptyComponent={
              <List>
                <List.Item
                  title={I18n.t("DHCP_SERVER.NOT_ENABLED")}
                  testID="NO_DHCP_OPTIONS_ADDED"
                />
              </List>
            }
            getItemData={(option, _, optionIndex) => {
              return {
                title: option.code,
                description: `${option.type} | ${option.value}`,
                rightAccessory: (
                  <Button
                    testID={`EDIT_DHCP_OPTION.${
                      vlan.dhcpHandling === DHCP_HANDLING_ON ? "ENABLED" : "DISABLED"
                    }.${optionIndex}`}
                    onPress={() => navigation.navigate("DHCPOption", { vlan, optionIndex })}
                    text={I18n.t("EDIT")}
                    kind="tertiary"
                    leadingIcon="Pencil"
                    disabled={vlan.dhcpHandling !== DHCP_HANDLING_ON}
                  />
                ),
              };
            }}
          />
        </List.Item>
      </List>
      <List>
        <List.Item
          leftAccessory={<Icon name="Lock" />}
          title={I18n.t("DHCP_SERVER.TOGGLE.SERVICE.TITLE")}
          description={I18n.t("DHCP_SERVER.TOGGLE.SERVICE.DESCRIPTION")}
          rightAccessory={
            <Toggle
              checked={vlan.dhcpHandling === DHCP_HANDLING_OFF}
              onValueChange={(toggleValue: boolean) =>
                onUpdateVlan(toggleValue ? DHCP_HANDLING_OFF : DHCP_HANDLING_ON)
              }
              testID={`DHCP_SERVICE_TOGGLE.${
                vlan.dhcpHandling === DHCP_HANDLING_OFF ? "CHECKED" : "UNCHECKED"
              }`}
            />
          }
        />
      </List>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header title={I18n.t("DHCP_SERVER.HEADER")} />
        <BottomSheet.Content>
          <Box gap="sm" padding="none">
            <Text size="p1" testID="DHCP_CONTEXT_MESSAGE">
              {I18n.t("CONTEXT_HELP.DHCP_SERVER.MESSAGE")}
            </Text>
            <Box
              flexDirection="row"
              justifyContent="flex-end"
              paddingRight="sm"
              gap="sm"
              alignItems="baseline"
            >
              <Button
                text={I18n.t("CONTEXT_HELP.DHCP_SERVER.LEARN_MORE")}
                kind="tertiary"
                onPress={() => launchUrl(I18n.t("CONTEXT_HELP.DHCP_SERVER.DOC"))}
                testID="LEARN_MORE"
              />
              <Button
                text={I18n.t("CONTEXT_HELP.DHCP_SERVER.CLOSE")}
                onPress={() => bottomSheetRef.current?.dismiss()}
                testID="DISMISS_BOTTOM_SHEET"
              />
            </Box>
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
