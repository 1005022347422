import { ClientType } from "@meraki/shared/api";

export const HIGH_USAGE_MIN_CLIENTS = 4;

export const totalUsage = (c: ClientType) => c.usage?.total ?? 0;

export const getThreshold = (clients: ClientType[]) => {
  const mean = clients.reduce((sum, client) => totalUsage(client) + sum, 0) / clients.length;
  const variance =
    clients
      .map((client) => Math.pow(totalUsage(client) - mean, 2))
      .reduce((total, x) => total + x, 0) / clients.length;
  const stdDev = Math.sqrt(variance);
  const thresholdUsage = mean + 2 * stdDev;
  return thresholdUsage;
};

export const highUsageClientFilter = (clients: ClientType[]) => {
  if (clients.length < HIGH_USAGE_MIN_CLIENTS) {
    return [];
  }

  const thresholdUsage = getThreshold(clients);
  return clients.filter((client) => totalUsage(client) >= thresholdUsage);
};

export const CUSTOM_FILTERS = {
  DEFAULT: (x: ClientType[]) => x,
  HIGH_USAGE_CLIENT: (clients: ClientType[]) => highUsageClientFilter(clients),
};
