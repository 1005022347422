import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  SiteToSiteVPN,
  useCreateVlan,
  useOrgNetworks,
  useSiteToSiteVPNSettings,
  useVlans,
  Vlan,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import {
  calculateNumberOfIPAddresses,
  calculateSubnetRange,
  getNextValidIPv4Subnet,
} from "@meraki/shared/ip-address";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "react-native";

const DEFAULT_SUBNET_MASK = "24";

type AddVlanForm = {
  name: string;
  networkId: string;
  id: string;
};

export function AddVlanScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const organizationId = useCurrentOrganizationId();
  const currentNetworkId = useCurrentNetworkId();
  const [selectedNetwork, setSelectedNetwork] = useState(currentNetworkId ?? "");
  const { data: orgNetworks, isLoading: isLoadingOrgNetworks } = useOrgNetworks({ organizationId });
  const createVlan = useCreateVlan();

  const methods = useForm<AddVlanForm>({
    defaultValues: {
      name: "",
      networkId: selectedNetwork,
      id: "",
    },
  });

  const vlansRaw = useQueries({
    queries:
      orgNetworks?.map((network) =>
        useVlans.useQueries(
          { networkId: network.id },
          {
            select: (data) => ({
              networkId: network.id,
              vlans: data,
            }),
          },
        ),
      ) ?? [],
  });

  const vlansByNetworkId = vlansRaw.reduce<Record<string, Vlan[]>>((result, { data }) => {
    if (data) {
      result[data.networkId] = data.vlans;
    }

    return result;
  }, {});

  const orgSiteToSiteVPNSettingsRaw = useQueries({
    queries:
      orgNetworks?.map((network) =>
        useSiteToSiteVPNSettings.useQueries({ networkId: network.id }),
      ) ?? [],
  });
  const orgSiteToSiteVPNSettings: SiteToSiteVPN[] = [];
  orgSiteToSiteVPNSettingsRaw.forEach(({ data }) => {
    if (data) {
      orgSiteToSiteVPNSettings.push(data);
    }
  });

  const totalIps = calculateNumberOfIPAddresses(parseInt(DEFAULT_SUBNET_MASK));

  const allVlanSubnetRanges =
    vlansByNetworkId[selectedNetwork]?.reduce((result, { subnet }) => {
      if (subnet) {
        const ranges = calculateSubnetRange(subnet);
        if (ranges != null) {
          result.push(ranges);
        }
      }
      return result;
    }, [] as number[][]) ?? [];

  const validVlanInfo = getNextValidIPv4Subnet(orgSiteToSiteVPNSettings, allVlanSubnetRanges);
  const ipMaskSplit = validVlanInfo.subnet.split("/");

  const onSubmit = useCallback(
    ({ name, networkId, id }: AddVlanForm) => {
      if (!name || !networkId || !id) {
        return;
      }

      if (networkId && name) {
        createVlan.mutate(
          {
            networkId,
            vlan: {
              id: parseInt(id),
              name,
              applianceIp: validVlanInfo.applianceIp,
              subnet: validVlanInfo.subnet,
            },
          },
          {
            onError: (error) => Alert.alert(String(error["errors"])),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: useVlans.queryKey({ networkId: selectedNetwork }),
              });
              navigation.goBack();
            },
          },
        );
      }
    },
    [createVlan, navigation, selectedNetwork, validVlanInfo.applianceIp, validVlanInfo.subnet],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSubmit)} />
      ),
    });
  }, [methods, navigation, onSubmit]);

  return (
    <Screen addDefaultPadding testID="ADD_VLAN_SCREEN">
      {createVlan.isLoading && <Loader.Spinner animate={true} />}
      <Form {...methods}>
        <Form.Input
          name="name"
          rules={{ required: I18n.t("CONFIGURE_VLAN.NAME_REQUIRED") }}
          label={I18n.t("CONFIGURE_VLAN.NAME")}
          additionalContext={I18n.t("CONFIGURE_VLAN.NAME_DESCRIPTION")}
          placeholder={I18n.t("CONFIGURE_VLAN.NAME_PLACEHOLDER")}
          disabled={isLoadingOrgNetworks}
          testID="ADD_VLAN.NAME_INPUT"
        />
        <Form.Input
          name="id"
          rules={{ required: I18n.t("CONFIGURE_VLAN.ID_REQUIRED") }}
          label={I18n.t("CONFIGURE_VLAN.ID")}
          additionalContext={I18n.t("CONTEXT_HELP.VLAN_ID.MESSAGE")}
          placeholder={I18n.t("CONFIGURE_VLAN.ID_PLACEHOLDER")}
          disabled={isLoadingOrgNetworks}
          keyboardType="numeric"
          testID="ADD_VLAN.ID_INPUT"
        />
        <Box>
          <Form.FlashList
            name="networkId"
            rules={{ required: I18n.t("CONFIGURE_VLAN.NETWORK.REQUIRED") }}
            loading={isLoadingOrgNetworks}
            label={I18n.t("CONFIGURE_VLAN.NETWORK.LABEL")}
            data={orgNetworks}
            getItemData={(network) => ({
              kind: "radio",
              title: network.name,
              radioValue: network.id,
              testID: `ADD_VLAN.NETWORK_${network.id}.${
                network.id === selectedNetwork ? "SELECTED" : "UNSELECTED"
              }`,
              checkedRadioValue: selectedNetwork,
              onRadioValueChanged: () => setSelectedNetwork(network.id),
            })}
            paddingLeft="none"
            paddingRight="none"
          />
        </Box>
      </Form>
      <Card label={I18n.t("SUBNET_MASK_ROW.LABEL")} testID="ADD_VLAN.SUBNET_MASK">
        <Text size="p1" testID="ADD_VLAN.SUBNET_MASK_TITLE">
          {DEFAULT_SUBNET_MASK}
        </Text>
        <Text color="light" testID="ADD_VLAN.SUBNET_COUNT">
          {I18n.t("CONFIGURE_VLAN.SUBNET_COUNT", { address_count: totalIps })}
        </Text>
        <Text color="light" testID="ADD_VLAN.SUBNET">{`${I18n.t("CONFIGURE_VLAN.SUBNET")}: ${
          ipMaskSplit[0]
        }/${ipMaskSplit[1]}`}</Text>
        <Text color="light" testID="ADD_VLAN.GX_IP">{`${I18n.t("CONFIGURE_VLAN.IP.GX")}: ${
          validVlanInfo.applianceIp
        }`}</Text>
      </Card>
    </Screen>
  );
}
