import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Disabled({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 20 20" width={width} height={height} testID={testID}>
      <Path
        d="M2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10ZM5 10C5 12.7614 7.23858 15 10 15C10.9272 15 11.7955 14.7476 12.5398 14.3078L5.6922 7.46016C5.25239 8.20452 5 9.07279 5 10ZM14.3077 12.5401L7.45992 5.69234C8.20434 5.25244 9.07269 5 10 5C12.7614 5 15 7.23858 15 10C15 10.9273 14.7476 11.7957 14.3077 12.5401Z"
        fill={theme.color.control.icon.weak.base}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
