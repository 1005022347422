import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useWindowDimensions } from "react-native";
import { TabView as RNTabView } from "react-native-tab-view";

import { ExtendedRoute, TabBar } from "./TabBar";

type TabProps = {
  title: string;
  view: ReactNode;
  accessibilityLabel?: string;
  testID?: string;
  icon?: ReactNode;
};

type TabMap = Record<string, TabProps>;

export type TabViewProps = AnalyticsProp<"onChange"> & {
  tabs: TabMap;
  onTabChange?: (index: number) => void;
};

export type TabViewMethods = {
  goToTab: (key: string) => void;
};

export const TabView = forwardRef(function TabView(
  { tabs, analytics, onTabChange }: TabViewProps,
  ref: ForwardedRef<TabViewMethods>,
) {
  const layout = useWindowDimensions();
  const trackEvent = useAnalytics(analytics);

  const [index, setIndex] = useState(0);

  const routes: ExtendedRoute[] = Object.entries(tabs).map(([key, tab]) => {
    return {
      key,
      title: tab.title,
      iconNode: tab.icon,
      accessibilityLabel: tab.accessibilityLabel,
      testID: tab.testID,
    };
  });

  const handleIndexChange = useCallback(
    (index: number) => {
      trackEvent("onChange", {
        tab: routes[index].key,
      });

      setIndex(index);
      onTabChange?.(index);
    },
    [trackEvent, setIndex, routes, onTabChange],
  );

  useImperativeHandle(
    ref,
    () => ({
      goToTab: (key) => {
        const routeToNavTo = routes.findIndex((route) => route.key === key);

        if (routeToNavTo === -1) {
          if (__DEV__) {
            console.error(
              `Attempted to navigate to a tab with key '${key}' but that route was not found`,
            );
          }
          return;
        }

        handleIndexChange(routeToNavTo);
      },
    }),
    [routes, handleIndexChange],
  );

  if (routes.length === 0) {
    return null;
  }

  return (
    <RNTabView
      navigationState={{ index, routes }}
      renderScene={({ route }) => {
        const props = tabs[route.key];
        const view = props?.view;

        if (!view) return null;

        return view;
      }}
      onIndexChange={handleIndexChange}
      initialLayout={{ width: layout.width }}
      renderTabBar={(props) => <TabBar {...props} />}
    />
  );
});
