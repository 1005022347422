import { Children, PureComponent, ReactNode } from "react";
import { DimensionValue, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { formatPercent } from "~/lib/formatHelper";

export interface WrappingRowProps {
  children: ReactNode;
  elementsPerRow?: number;
  screenStyles?: StyleProp<ViewStyle>;
}

export interface WrappingRowState {
  flexBasisPercentage?: DimensionValue;
}

export default class WrappingRow extends PureComponent<WrappingRowProps, WrappingRowState> {
  constructor(props: WrappingRowProps) {
    super(props);

    const { elementsPerRow } = props;

    let flexBasisPercentage: DimensionValue | undefined = undefined;
    if (elementsPerRow !== undefined) {
      flexBasisPercentage = formatPercent(1 / elementsPerRow);
    }

    this.state = { flexBasisPercentage };
  }

  filterOutFalseyElements = (children: React.ReactNode) => {
    return Children.toArray(children).filter(Boolean);
  };

  mapChildren = (children: React.ReactNode) => {
    const { flexBasisPercentage } = this.state;
    const filteredChildren = this.filterOutFalseyElements(children);
    if (!flexBasisPercentage) {
      return filteredChildren;
    }

    return Children.map(filteredChildren, (child: React.ReactNode) => {
      return <View style={{ flexBasis: flexBasisPercentage }}>{child}</View>;
    });
  };

  render() {
    const { screenStyles, children } = this.props;
    return <View style={screenStyles || styles.row}>{this.mapChildren(children)}</View>;
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
