import * as errorMonitor from "@meraki/core/errors";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import { ONBOARDING_STEPS } from "~/constants/OnboardingSteps";
import CheckBox from "~/go/components/CheckBox";
import LoadingModal from "~/go/components/LoadingModal";
import RoundedButton from "~/go/components/RoundedButton";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import { Features } from "~/go/types/ContextHelpTypes";
import withSSIDCreate, { SSIDCreateProps } from "~/hocs/SSIDCreate";
import I18n from "~/i18n/i18n";
import { ALERT_BUTTONS, showAlert } from "~/lib/AlertUtils";
import { DEVICE_CONFIG } from "~/lib/DeviceUtils";
import { getNodesOnboardingStatus } from "~/lib/OnboardingFullstackUtils";
import { platformSelect } from "~/lib/PlatformUtils";
import { generateSSIDName } from "~/lib/SSIDUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import {
  canAutoConnectToWifi,
  getSsid,
  isUserDenialError,
  openSettings,
  waitForConnection,
} from "~/lib/WifiUtils";
import {
  configuredSSIDsSelector,
  currentNetworkState,
  currentOrganization,
  hasNATRouterSelector,
  onlineDevicesSelector,
} from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const NEW_SSID_CONFIG_DELAY = 5000; // 5 seconds
const CONFIG_FETCH_INTERVAL = 30000; // 30 seconds
const CONFIG_FETCH_TIMEOUT = 480000; // 8 minutes

type ReduxProps = {
  networkId: string;
  orgName?: string;
  onlineDevices: any[];
  configuredSSIDs: any[];
  hasNATRouter: boolean;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "CreateSSID"> &
  ReduxProps &
  BasicActions &
  SSIDCreateProps;

type CreateSSIDState = {
  reqPending: boolean;
  psk?: string;
  name: string;
  isGuestNetwork: boolean;
  connecting: boolean;
};

export class CreateSSID extends PureComponent<Props, CreateSSIDState> {
  static defaultProps = {
    stepObject: {
      step: ONBOARDING_STEPS.ssidCreate,
      title: I18n.t("CREATE_SSID.TITLE"),
      screen: "CreateSSID",
    },
    onboardingCallback: undefined,
  };

  private tryConnectTimer: any;
  private configFetchInterval: any;
  private passwordInput: any;
  private configFetchTimeout: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      reqPending: false,
      psk: undefined,
      name: generateSSIDName(props.orgName),
      isGuestNetwork: false,
      connecting: false,
    };

    this.setNavOptions(false);
  }

  setNavOptions(cancelDisabled: any) {
    const { navigation, onboardingCallback } = this.props;

    navigation.setOptions({
      // TODO: We should pass a message to the onboardingCallback
      // if the user already has 1 valid SSID created. But, for the
      // case where a user has 0 valid SSIDs, we cannot advance them.
      headerLeft: cancelDisabled ? undefined : () => <CancelButton onPress={navigation.goBack} />,
      headerRight: onboardingCallback
        ? undefined
        : () => <SaveButton onPress={this.saveAndDismiss} />,
    });
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { connecting } = this.state;
    if (connecting !== prevState.connecting) {
      this.setNavOptions(connecting);
    }
  }

  clearConnectTimer = () => {
    if (this.tryConnectTimer) {
      clearTimeout(this.tryConnectTimer);
      this.tryConnectTimer = null;
    }
  };

  finishAutoConnect = () => {
    this.clearAllTimers();
    this.setState({ connecting: false });
    this.dismiss();
  };

  clearAllTimers = () => {
    this.clearConfigTimers();
    this.clearConnectTimer();
  };

  clearConfigTimers = () => {
    if (this.configFetchInterval) {
      clearTimeout(this.configFetchInterval);
      this.configFetchInterval = null;
    }

    if (this.configFetchTimeout) {
      clearTimeout(this.configFetchTimeout);
      this.configFetchTimeout = null;
    }
  };

  // Kicks off specialized native WiFi connect functions in WifiUtils.js.
  // Shows a retry on failure. Pushes to a new screen if succeeds.
  connectToWifi = (name: any, psk: any) => {
    const tryConnect = () => {
      waitForConnection(name, psk)
        .then(() => this.dismiss())
        .catch((error) => {
          if (isUserDenialError(error)) {
            this.finishAutoConnect();
          } else if (this.tryConnectTimer) {
            this.showRetryAlert(name, tryConnect);
          }
        });
    };

    if (!canAutoConnectToWifi()) {
      this.showWifiSettingsInstructions(name);
    } else {
      this.tryConnectTimer = setTimeout(tryConnect, NEW_SSID_CONFIG_DELAY);
    }
  };

  // Polls dashboard to see if new SSID config is fetched.
  // Polling can timeout, which then presents a retry.
  // If polling shows successful config, we resolve the promise.
  checkNodeConfig = (name: any, psk: any) =>
    new Promise<void>((resolve, reject) => {
      const { actions } = this.props;
      this.configFetchInterval = setInterval(() => {
        actions
          .getUdgNodesStatus()
          .then(({ response }: any) => {
            const status = getNodesOnboardingStatus(response);
            if (status === DEVICE_CONFIG.finished.title) {
              this.clearConfigTimers();
              return resolve();
            }
            return null;
          })
          .catch(() => {
            this.clearConfigTimers();
            reject();
          });
      }, CONFIG_FETCH_INTERVAL);

      this.configFetchTimeout = setTimeout(() => {
        this.showRetryAlert(name, () => this.nodeConfigHandler(name, psk));
      }, CONFIG_FETCH_TIMEOUT);
    });

  nodeConfigHandler = (name: any, psk: any) => {
    this.checkNodeConfig(name, psk)
      .then(() => {
        this.connectToWifi(name, psk);
      })
      .catch(() => {
        this.showRetryAlert(name, () => this.nodeConfigHandler(name, psk));
      });
  };

  // Creates a new SSID, kicks off config check.
  // If config is downloaded, try to connect.
  createAndConnect = () => {
    Keyboard.dismiss();
    return this.save().then(() => {
      const { name, psk } = this.state;
      this.setState({ connecting: true });
      this.nodeConfigHandler(name, psk);
    });
  };

  showRetryAlert = (name: any, retryFunction: any) =>
    showAlert(I18n.t("ERROR"), I18n.t("WIFI_CONNECT_ERROR", { name }), retryFunction, {
      positiveText: ALERT_BUTTONS.retry,
      negativeText: ALERT_BUTTONS.cancel,
      onNegativePress: () => {
        this.finishAutoConnect();
      },
    });

  saveAndDismiss = () => {
    this.save().then(this.dismiss);
  };

  dismiss = () => {
    const { navigation, stepObject, onboardingCallback } = this.props;

    if (!stepObject || !onboardingCallback) {
      navigation.goBack();
    } else {
      const connectedNextStep = () => onboardingCallback(stepObject.primaryNextStep);
      //@ts-ignore
      const notConnectedNextStep = () => onboardingCallback(stepObject.notConnectedNextStep);

      getSsid()
        .catch(() => false)
        .then((ssidName: any) => {
          platformSelect({
            ios: () => this.dismissIos(ssidName, connectedNextStep, notConnectedNextStep),
            android: () => this.dismissAndroid(ssidName, connectedNextStep, notConnectedNextStep),
          })();
        });
    }
  };

  dismissIos(ssidName: any, connectedNextStep: any, notConnectedNextStep: any) {
    const { navigation } = this.props;
    const { name } = this.state;
    if (ssidName === name) {
      connectedNextStep();
    } else {
      notConnectedNextStep();
    }
    navigation.goBack();
  }

  dismissAndroid(ssidName: any, connectedNextStep: any, notConnectedNextStep: any) {
    const { navigation } = this.props;
    const { name } = this.state;
    // Android needs a slightly different dismiss action
    // so it doesn't conflict with a subsequent push.
    navigation.goBack();
    setTimeout(ssidName === name ? connectedNextStep : notConnectedNextStep, 0);
  }

  // Happens if user is on older phone or development device
  showWifiSettingsInstructions(name: any) {
    this.clearAllTimers();
    platformSelect({
      ios: () => this.showWifiSettingsInstructionsiOS(),
      android: () => this.showWifiSettingsInstructionsAndroid(name),
    })();
  }

  showWifiSettingsInstructionsiOS() {
    const { navigation, stepObject, onboardingCallback } = this.props;
    onboardingCallback?.(stepObject?.secondaryNextStep);
    navigation.goBack();
  }

  showWifiSettingsInstructionsAndroid(name: any) {
    showAlert(
      I18n.t("SUCCESS"),
      I18n.t("WIFI_SETTING_TEXT", { name }),
      () => {
        this.dismiss();
        setTimeout(openSettings, 5);
      },
      {
        positiveText: I18n.t("CREATE_SSID.SETTINGS"),
        negativeText: ALERT_BUTTONS.later,
        onNegativePress: () => this.dismiss(),
      },
    );
  }

  loadData() {
    const { actions, networkId, configuredSSIDs, onboardingCallback } = this.props;
    if (networkId === null) {
      return;
    }

    const reqs = [actions.loadNodesAndStatuses(networkId)];

    if (isEmpty(configuredSSIDs) && !onboardingCallback) {
      reqs.push(actions.getSsids(networkId));
    }

    this.setState({ reqPending: true });
    Promise.all(reqs)
      .catch(() => showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT")))
      .then(() => this.setState({ reqPending: false }));
  }

  save() {
    const { psk, name, isGuestNetwork } = this.state;
    const { createSSID } = this.props;

    this.setState({ reqPending: true });

    return createSSID(name, psk, isGuestNetwork)
      .then(() => this.setState({ reqPending: false }))
      .catch((error) => {
        this.setState({ reqPending: false });
        showAlert(I18n.t("ERROR"), error);
        throw error;
      });
  }

  render() {
    const { name, connecting, isGuestNetwork, reqPending, psk } = this.state;
    const { onboardingCallback, stepObject } = this.props;

    const nameInputRow = (
      <InputRow
        testID={"CREATE_SSID.NAME"}
        clearTestID={"CREATE_SSID.NAME.CLEAR"}
        value={name}
        placeholder={I18n.t("CREATE_SSID.NAME.PLACEHOLDER")}
        onChangeText={(newName: any) => this.setState({ name: newName })}
        returnKeyType={RETURN_KEY.next}
        onSubmitEditing={() => this.passwordInput.focus()}
      >
        {I18n.t("CREATE_SSID.NAME.TITLE")}
      </InputRow>
    );

    const passwordInputRow = (
      <View>
        <InputRow
          ref={(ref: any) => {
            this.passwordInput = ref;
          }}
          testID={"CREATE_SSID.PASSWORD"}
          placeholder={I18n.t("CREATE_SSID.PASSWORD.PLACEHOLDER")}
          value={psk}
          onChangeText={(newValue: any) => this.setState({ psk: newValue })}
          secureTextEntry
          returnKeyType={RETURN_KEY.go}
          onSubmitEditing={onboardingCallback ? this.createAndConnect : this.saveAndDismiss}
        >
          {I18n.t("CREATE_SSID.PASSWORD.TITLE")}
        </InputRow>
        <MkiText textStyle="small" screenStyles={styles.passwordDescription}>
          {I18n.t("CREATE_SSID.PASSWORD.SUBTITLE")}
        </MkiText>
      </View>
    );

    const guestNetworkRow = (
      <ListRow
        icon={<CheckBox selected={isGuestNetwork} screenStyles={styles.checkBox} />}
        subtitle1={I18n.t("SSID_CONFIGURATION.STATUS.GUEST_NETWORK.SUBTITLE")}
        onPress={() => this.setState({ isGuestNetwork: !isGuestNetwork })}
        leftStyle={styles.checkBoxLeft}
        context={Features.guestNetworks}
      >
        {I18n.t("SSID_CONFIGURATION.STATUS.GUEST_NETWORK.TITLE")}
      </ListRow>
    );
    const rows = [nameInputRow, passwordInputRow];
    if (!stepObject || !onboardingCallback) {
      rows.push(guestNetworkRow);
    }
    const renderRow = (rowData: any) => rowData;

    return (
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="handled"
      >
        <SummaryList
          heading={I18n.t("CREATE_SSID.TITLE")}
          headingTextStyle={styles.headingText}
          contentRows={rows}
          customRenderRow={renderRow}
          disableBottomBorder
        />
        {onboardingCallback ? (
          <RoundedButton onPress={this.createAndConnect} screenStyles={styles.roundedButton}>
            Create and connect
          </RoundedButton>
        ) : null}
        <LoadingSpinner visible={reqPending} />
        <LoadingModal
          title={I18n.t("WIFI_CONNECT_LOADING_TITLE")}
          subtitle={I18n.t("WIFI_CONNECT_LOADING_SUBTITLE")}
          visible={connecting}
          buttonTitle={I18n.t("ONBOARDING.CONNECT_TO_WIFI.SECONDARY_BUTTON")}
          buttonOnPress={this.finishAutoConnect}
        />
      </KeyboardAwareScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headingText: {
    color: MkiColors.configHeadingColor,
    fontSize: normalizedFontSize(24),
  },
  passwordDescription: {
    color: MkiColors.secondaryTextColor,
    marginHorizontal: SPACING.default,
    marginVertical: SPACING.small,
  },
  checkBoxLeft: {
    alignItems: "flex-start",
    width: "85%",
  },
  checkBox: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
    marginTop: SPACING.meager,
  },
  roundedButton: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for CreateSSIDScreen",
      currentNetworkState(state),
    ),
    orgName: currentOrganization(state).name,
    onlineDevices: onlineDevicesSelector(state),
    configuredSSIDs: configuredSSIDsSelector(state),
    hasNATRouter: hasNATRouterSelector(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withSSIDCreate,
)(CreateSSID);
