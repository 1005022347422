import { useDebuggingTools } from "@meraki/shared/debugging-tools";
import { useStringFlag } from "@meraki/shared/feature-flags";
import {
  USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
  USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE,
  USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE,
} from "@meraki/shared/redux";
import * as Sentry from "@sentry/react-native";
import { ReactNode } from "react";
import { View } from "react-native";

import NFOs from "~/constants/NFOs";
import {
  getAlphaMagneticThemeFeature,
  getAlphaTappableChartsFeature,
  getThumbnailScrubbingFeature,
} from "~/selectors";
import SummaryListHeading from "~/shared/components/SummaryListHeading";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useNFOs, { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";

function AlphaFeaturesGatedContainer({ children }: { children: ReactNode }) {
  const alphaFeaturesNfo = useHasAlphaFeatures();
  return alphaFeaturesNfo ? (
    <View testID="alpha-features">
      <SummaryListHeading>Internal Alpha Features</SummaryListHeading>
      {children}
    </View>
  ) : null;
}

function crashTheApp() {
  const temp = null;
  // @ts-ignore because we want to crash this thing...
  temp.mobileAmbassadorCanIgnoreThisCrash();
}

function TappableChartsFeature() {
  const dispatch = useAppDispatch();
  const alphaTappableChartsSetting = useAppSelector(getAlphaTappableChartsFeature);
  return (
    <SwitchRow
      onValueChange={(value) => {
        dispatch({
          type: USER_TOGGLED_TAPPABLE_CHARTS_ALPHA_FEATURE,
          payload: { tappableCharts: value },
        });
      }}
      value={alphaTappableChartsSetting}
    >
      MT Tappable Charts
    </SwitchRow>
  );
}

function MagneticThemeFeature() {
  const dispatch = useAppDispatch();
  const alphaMagneticThemeSetting = useAppSelector(getAlphaMagneticThemeFeature);
  return (
    <SwitchRow
      onValueChange={(value) => {
        dispatch({
          type: USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
          payload: { magneticTheme: value },
        });
      }}
      value={alphaMagneticThemeSetting}
    >
      Magnetic Theme
    </SwitchRow>
  );
}

function ThumbnailScrubbingFeature() {
  const dispatch = useAppDispatch();
  const hasCameraScrub = useNFOs().has(NFOs.hasCameraScrub);
  const ThumbnailScrubbingFeature = useAppSelector(getThumbnailScrubbingFeature);

  return hasCameraScrub ? (
    <SwitchRow
      onValueChange={(value) => {
        dispatch({
          type: USER_TOGGLED_THUMBNAIL_SCRUBBING_FEATURE,
          payload: { thumbnailScrubbing: value },
        });
      }}
      value={ThumbnailScrubbingFeature}
    >
      Thumbnail Scrubbing
    </SwitchRow>
  ) : null;
}

function DebuggingToolsFeature() {
  const { enabled: debuggingToolsEnabled, toggle: toggleDebuggingTools } = useDebuggingTools();

  return (
    <SwitchRow onValueChange={toggleDebuggingTools} value={debuggingToolsEnabled}>
      Debugging Tools
    </SwitchRow>
  );
}

export default function AlphaFeatures() {
  // This is a short term test to validate A/B testing is working. This will be better eventually.
  const { value: abTestValue } = useStringFlag("ld", "ab-testing");

  return (
    <AlphaFeaturesGatedContainer>
      <SimpleDisclosureRow
        subtitle="sends test alert to sentry"
        onPress={() => {
          Sentry.captureMessage("Test message 😀");
        }}
      >
        Send Sentry Test
      </SimpleDisclosureRow>
      <SimpleDisclosureRow
        subtitle="Not kidding. This will crash your app."
        onPress={() => {
          crashTheApp();
        }}
      >
        Crash the App.
      </SimpleDisclosureRow>
      <TappableChartsFeature />
      <SimpleDisclosureRow subtitle={`The A/B testing value is ${abTestValue}`}>
        A/B Testing
      </SimpleDisclosureRow>
      <ThumbnailScrubbingFeature />
      {/* This file is in the Enterprise folder but be warned, it is shared between Go and Enterprise! */}
      {__MERAKI_GO__ && <MagneticThemeFeature />}
      <DebuggingToolsFeature />
    </AlphaFeaturesGatedContainer>
  );
}
