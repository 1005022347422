import { getProductType } from "@meraki/shared/devices";

import { MS_IN_A_SECOND } from "~/constants/MkiConstants";
import { deviceState, getOnboardingNodes, onboardingStartTimeSelector } from "~/selectors";
import { RootState } from "~/shared/types/Redux";

export enum GAPropertyKey {
  dev = "UA-312871-27",
  prod = "UA-312871-28",
}

export const PROD_METRICS = {
  onboardingTime: 1,
  scannedHardwareCount: 2,
};

export const getAnalyticsKey = (isCI: boolean, isGo: boolean, isDev: boolean): string => {
  if (isCI) {
    return "";
  }
  if (!isGo) {
    return "";
  }
  return isDev ? GAPropertyKey.dev : GAPropertyKey.prod;
};

export function onboardingAddNodeActionEvent(state: RootState, serialNumber: string) {
  const device = deviceState(state, { serialNumber });
  const productType = getProductType(device.model);
  if (typeof productType !== "string") {
    return null;
  }

  const productTypeUpperCase = productType.toUpperCase();
  return `ADDED_${productTypeUpperCase}_DEVICE`;
}

export function onboardingTimeTakenPayload(state: RootState) {
  const onboardingStartTime = onboardingStartTimeSelector(state);
  if (typeof onboardingStartTime !== "number") {
    return null;
  }

  const timeTaken = Date.now() - onboardingStartTime;
  const customMetrics = { [PROD_METRICS.onboardingTime]: timeTaken / MS_IN_A_SECOND };
  const payload = { customMetrics };
  return payload;
}

export function scannedHardwareCountPayload(state: RootState) {
  const nodes = getOnboardingNodes(state);
  if (!nodes) {
    return null;
  }

  const customMetrics = { [PROD_METRICS.scannedHardwareCount]: Object.keys(nodes).length };
  const payload = { customMetrics };
  return payload;
}
