import { I18n } from "@meraki/core/i18n";
import { Box, ShiftBy } from "@meraki/magnetic/layout";
import { ReactNode } from "react";
import { Platform } from "react-native";

import { Button } from "../Button/Button";
import { Heading } from "../Heading/Heading";
import { Loader } from "../Loader/Loader";
import { Text } from "../Text/Text";

interface HeaderProps {
  title: string;
  subTitle?: string;
  description?: string;
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  onSeeAllPress?: () => void;
}

export function HeaderLoading({ title, description, leftAccessory, rightAccessory }: HeaderProps) {
  return (
    <Box alignItems="center" flexDirection="row" gap="sm">
      {!!leftAccessory && <Loader.Skeleton height="medium" width={25} />}
      <Box flex={1} gap="2xs">
        <Box alignItems="flex-end" flexDirection="row" gap="2xs">
          {!!title && <Loader.Skeleton color="strong" height="medium" />}
        </Box>
        {!!description && <Loader.Skeleton />}
      </Box>
      {!!rightAccessory && <Loader.Skeleton height="medium" width={25} />}
    </Box>
  );
}

export function Header({
  title,
  subTitle,
  description,
  leftAccessory,
  rightAccessory,
  onSeeAllPress,
}: HeaderProps) {
  return (
    <Box alignItems="center" flexDirection="row" gap="sm">
      {leftAccessory}
      <Box flex={1}>
        <Box alignItems="flex-end" flexDirection="row" gap="2xs">
          <Heading size="h3">{title}</Heading>
          {!!subTitle && (
            // The ShiftBy is to fix a vertical alignment issue on web when two sets of text
            // have different line hieghts. H3 has a line-height of 22px while P2 has 20px.
            // On web the text is centered in the line height which means the header is 1px higher then the subtitle.
            <ShiftBy y={Platform.OS === "web" ? -1 : 0}>
              <Text size="p2" color="light">
                {subTitle}
              </Text>
            </ShiftBy>
          )}
        </Box>
        {!!description && (
          <Text size="p2" color="light">
            {description}
          </Text>
        )}
      </Box>
      {rightAccessory}
      {onSeeAllPress && (
        <Button
          kind="tertiary"
          text={I18n.t("SEE_ALL")}
          trailingIcon="CaretRight"
          onPress={onSeeAllPress}
        />
      )}
    </Box>
  );
}
