import { I18n } from "@meraki/core/i18n";
import { Button, List, SearchBar, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  mapFromPrivateToPublicEndpoint,
  useDevices,
  useFailedConnections,
  useOrgNetwork,
  usePrivateClientListJson,
  useSsids,
} from "@meraki/shared/api";
import { FAILED_WIRELESS_CLIENTS_SEARCH_FIELDS, filterData } from "@meraki/shared/filters";
import {
  DisplayableFailedConnection,
  FailedConnectionGroupProps,
} from "@meraki/shared/navigation-type";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";

import {
  formatClientsByMac,
  formatDevicesBySerial,
  formatFailedConnections,
} from "../utils/FailedConnectionsUtils";

export const FailedWirelessConnectionsListScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<FailedConnectionGroupProps>>();
  const { params: props } =
    useRoute<RouteProp<FailedConnectionGroupProps, "FailedWirelessConnectionsList">>();

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();
  const { data: network } = useOrgNetwork({ organizationId, networkId });
  const {
    data: failedConnectionData,
    isLoading: failedConnectionIsLoading,
    refetch: failedConnectionRefetch,
    isRefetching: failedConnectionIsRefetching,
  } = useFailedConnections({ networkId, timespan });

  const {
    data: clientData,
    isLoading: clientIsLoading,
    refetch: clientRefetch,
    isRefetching: clientIsRefetching,
  } = usePrivateClientListJson(
    { networkEid: network?.eid ?? "" },
    {
      select(data) {
        const privatClientsAsPublic = mapFromPrivateToPublicEndpoint(data);
        return formatClientsByMac(privatClientsAsPublic);
      },
      enabled: Boolean(network?.eid),
    },
  );

  const {
    data: deviceData,
    isLoading: deviceIsLoading,
    refetch: deviceRefetch,
    isRefetching: deviceIsRefetching,
  } = useDevices(
    { organizationId, networkId },
    {
      select(data) {
        return formatDevicesBySerial(data);
      },
    },
  );
  const {
    data: ssidData,
    isLoading: ssidIsLoading,
    refetch: ssidRefetch,
    isRefetching: ssidIsRefetching,
  } = useSsids({
    networkId,
  });

  const [searchText, setSearchText] = useState<string>(props?.searchPrefill ?? "");

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => <Button.Icon icon={"ArrowLeft"} onPress={() => navigation.goBack()} />,
        headerShadowVisible: false,
      }),
    [navigation],
  );

  const refetchData = () => {
    failedConnectionRefetch();
    clientRefetch();
    deviceRefetch();
    ssidRefetch();
  };

  const formattedFailedConnections = formatFailedConnections(
    failedConnectionData,
    clientData,
    deviceData,
    ssidData,
  );

  const filteredFormatedFailedConnections = filterData(
    formattedFailedConnections,
    FAILED_WIRELESS_CLIENTS_SEARCH_FIELDS,
    searchText,
  ) as DisplayableFailedConnection[];

  return (
    <Screen.View gap="none">
      <Box
        flexDirection="row"
        paddingTop="none"
        padding="sm"
        alignItems="center"
        bottomDividerBorder
      >
        <Box flex={1}>
          <SearchBar
            testID="SEARCH_BAR"
            value={searchText}
            onChangeText={(value: string) => setSearchText(value)}
            placeholder={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.SEARCH")}
          />
        </Box>
      </Box>
      <List.FlashList
        data={filteredFormatedFailedConnections}
        emptyState={{ title: I18n.t("FAILED_CONNECTIONS_CARD.STATUS_TEXT.POSITIVE") }}
        loading={failedConnectionIsLoading || clientIsLoading || deviceIsLoading || ssidIsLoading}
        onRefresh={refetchData}
        testID="FAILED_CONNECTION_LIST"
        refreshing={
          failedConnectionIsRefetching ||
          clientIsRefetching ||
          deviceIsRefetching ||
          ssidIsRefetching
        }
        getItemData={(failedConnection) => {
          return {
            key: failedConnection.clientName,
            title: failedConnection.clientName,
            description: formatDistance(Date.parse(failedConnection.ts), Date.now(), {
              addSuffix: true,
            }),
            children: (
              <Box>
                <Text color="light">
                  {I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONSREASON", {
                    reason: failedConnection.failureStep,
                  })}
                </Text>
                <Text color="light">
                  {I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.AP", {
                    ap: failedConnection.apName,
                  })}
                </Text>
                <Text color="light">
                  {I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.SSID", {
                    ssid: failedConnection.ssidName,
                  })}
                </Text>
              </Box>
            ),
            onPress: () =>
              navigation.navigate("FailedWirelessConnection", {
                failedConnection,
              }),
          };
        }}
      />
    </Screen.View>
  );
};
