interface GroupPolicySchedulingDay {
  active: boolean;
  from: string;
  to: string;
}

interface GroupPolicyScheduling {
  enabled: boolean;
  monday: GroupPolicySchedulingDay;
  tuesday: GroupPolicySchedulingDay;
  wednesday: GroupPolicySchedulingDay;
  thursday: GroupPolicySchedulingDay;
  friday: GroupPolicySchedulingDay;
  saturday: GroupPolicySchedulingDay;
  sunday: GroupPolicySchedulingDay;
}

export enum BandwidthSettings {
  custom = "custom",
  default = "network default",
  ignore = "ignore",
}
export interface GroupPolicyBandwidthLimits {
  limitDown: number | null;
  limitUp: number | null;
}

export interface GroupPolicyBandwidth {
  settings: BandwidthSettings;
  bandwidthLimits: GroupPolicyBandwidthLimits;
}

export interface GroupPolicyState {
  [groupPolicyId: string]: GroupPolicy;
}

export interface GroupPolicy {
  groupPolicyId: string;
  name: string;
  scheduling?: GroupPolicyScheduling;
  bandwidth?: GroupPolicyBandwidth | null;
  firewallAndTrafficShaping?: unknown | null;
  contentFiltering?: unknown | null;
  splashAuthSettings?: unknown | null;
  bonjourForwarding?: unknown | null;
  vlanTagging?: unknown | null;
}
