import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import ExpandableSummaryCard from "~/shared/components/ExpandableSummaryCard";
import MkiText from "~/shared/components/MkiText";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import { GoStatus } from "~/shared/constants/Status";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

interface SecurityMeasuresSummaryProps {
  securityEventsCount: number;
  highEventsClientsCount: number;
  loading: boolean;
  dragging?: boolean;
  onPressSecurityEvents: () => void;
  onPressHighEvents: () => void;
}

export default class SecurityMeasuresSummary extends PureComponent<SecurityMeasuresSummaryProps> {
  renderSummary = () => {
    const {
      securityEventsCount,
      highEventsClientsCount,
      onPressSecurityEvents,
      onPressHighEvents,
    } = this.props;

    if (securityEventsCount === 0) {
      return (
        <MkiText screenStyles={styles.noClientsText}>
          {I18n.t("SECURITY_MEASURES_SUMMARY.NO_SECURITY_EVENTS")}
        </MkiText>
      );
    }

    return (
      <View style={styles.quickStatusBoxRow}>
        <QuickStatusBox
          value={securityEventsCount}
          subText={I18n.t("SECURITY_MEASURES_SUMMARY.SECURITY_EVENTS")}
          status={GoStatus.good}
          onPress={onPressSecurityEvents}
        />
        <QuickStatusBox
          value={highEventsClientsCount}
          subText={I18n.t("SECURITY_MEASURES_SUMMARY.HIGH_EVENT_DEVICE", {
            count: highEventsClientsCount,
          })}
          status={GoStatus.bad}
          onPress={highEventsClientsCount > 0 ? onPressHighEvents : undefined}
        />
      </View>
    );
  };

  render() {
    const { loading, dragging } = this.props;

    return (
      <ExpandableSummaryCard
        heading={I18n.t("SECURITY_MEASURES_SUMMARY.HEADING")}
        subheading={I18n.t("SECURITY_MEASURES_SUMMARY.SUBHEADING")}
        loading={loading}
        savePreferencesKey={ExpandableCardKeys.securityMeasures}
        dragging={dragging}
      >
        {this.renderSummary()}
      </ExpandableSummaryCard>
    );
  }
}

const styles = StyleSheet.create({
  noClientsText: {
    color: MkiColors.secondaryTextColor,
  },
  quickStatusBoxRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
