import Svg, { Path } from "react-native-svg";

type ElekidPowerControlProps = {
  color: string;
};

export const ElekidPowerControlIcon = (props: ElekidPowerControlProps) => {
  const { color } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M5.5 21.75L6.75 15.5L1.75 13.625L10.5 4.25L9.25 10.5L14.25 12.375L5.5 21.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18 8.24976C19.2426 8.24976 20.25 7.2424 20.25 5.99976C20.25 4.75712 19.2426 3.74976 18 3.74976C16.7574 3.74976 15.75 4.75712 15.75 5.99976C15.75 7.2424 16.7574 8.24976 18 8.24976Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.2531 3.78262C21.3659 3.94418 21.4647 4.11512 21.5484 4.29355L22.7625 4.96855C22.914 5.64753 22.9156 6.3514 22.7671 7.03105L21.5484 7.70605C21.4647 7.88449 21.3659 8.05543 21.2531 8.21699L21.2765 9.60918C20.762 10.078 20.153 10.4313 19.4906 10.6451L18.2953 9.92793C18.0986 9.94199 17.9013 9.94199 17.7046 9.92793L16.514 10.6404C15.8495 10.4305 15.2385 10.0786 14.7234 9.60918L14.7468 8.22168C14.6349 8.05788 14.5362 7.88546 14.4515 7.70605L13.2375 7.03105C13.0859 6.35208 13.0843 5.64821 13.2328 4.96855L14.4515 4.29355C14.5352 4.11512 14.634 3.94418 14.7468 3.78262L14.7234 2.39043C15.2379 1.92157 15.8469 1.56834 16.5093 1.35449L17.7046 2.07168C17.9013 2.05762 18.0986 2.05762 18.2953 2.07168L19.4859 1.35918C20.1504 1.56913 20.7614 1.921 21.2765 2.39043L21.2531 3.78262Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ElekidPowerControlIcon;
