import z from "zod";

export const ProductTypeSchema = z
  .union([
    z.literal("wireless"),
    z.literal("appliance"),
    z.literal("switch"),
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("sensor"),
    z.literal("systemsManager"),
  ] as const)
  .describe("ProductTypeSchema");

export const StatusSchema = z
  .union([
    z.literal("online"),
    z.literal("offline"),
    z.literal("alerting"),
    z.literal("dormant"),
  ] as const)
  .describe("StatusSchema");

export type ProductType = z.infer<typeof ProductTypeSchema>;

export const DeviceSchema = z
  .object({
    id: z.string(),
    name: z.optional(z.nullable(z.string())),
    lat: z.number(),
    lng: z.number(),
    address: z.string(),
    notes: z.string().optional(),
    tags: z.array(z.string()),
    networkId: z.string(),
    serial: z.string(),
    model: z.string(),
    mac: z.string(),
    lanIp: z.optional(z.nullable(z.string())),
    ip: z.optional(z.nullable(z.string())),
    wan1Ip: z.optional(z.nullable(z.string())),
    wan2Ip: z.optional(z.nullable(z.string())),
    firmware: z.string(),
    productType: z.optional(ProductTypeSchema),
    networkEid: z.string(),
    imei: z.optional(z.number()),
    is_gateway: z.optional(z.boolean()),
    status: z.optional(StatusSchema),
  })
  .describe("DeviceSchema");

export const DevicesSchema = z.array(DeviceSchema).describe("DevicesSchema");

export type Device = z.infer<typeof DeviceSchema>;
