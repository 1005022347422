import { List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { DebugProps } from "./types";

export function DebugScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<DebugProps>>();

  return (
    <Screen addDefaultPadding>
      <List>
        <List.Item title="Theme" onPress={() => navigation.navigate("DebuggingViewTheme")} />
        <List.Item title="i18n (WIP)" />
        <List.Item
          title="Analytics"
          onPress={() => navigation.navigate("DebuggingViewAnalytics")}
        />
        <List.Item title="Camera" onPress={() => navigation.navigate("DebuggingViewCamera")} />
        <List.Item
          title="Feature Flags"
          onPress={() => navigation.navigate("DebuggingViewFeatureFlags")}
        />
      </List>
    </Screen>
  );
}
