import { QueryCache, QueryClient } from "@tanstack/react-query";
import { Alert } from "react-native";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (
        typeof query.meta?.alertTitle === "string" &&
        typeof query.meta?.alertMessage === "string"
      ) {
        Alert.alert(query.meta.alertTitle, query.meta.alertMessage);
      }
    },
  }),
});
