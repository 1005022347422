import * as errorMonitor from "@meraki/core/errors";
import { featureFlagClient } from "@meraki/shared/feature-flags";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useEffect } from "react";

import { analytics } from "~/lib/FirebaseModules";
import { currentUserState, getUserData } from "~/selectors";

import useAppSelector from "../hooks/redux/useAppSelector";

export function useAnalyticsAndErrorContextSynchronizer() {
  const currentOrgId = useCurrentOrganizationId();
  const currentUser = useAppSelector(getUserData);
  const currentEmail = useAppSelector(currentUserState);

  useEffect(() => {
    // Organization Synchronizer!
    if (!!currentOrgId) {
      analytics.setUserProperty("organization_id", currentOrgId);
      analytics.setUserProperty("mki_org_id", currentOrgId);
    }

    errorMonitor.setOrgID(currentOrgId);

    // User Synchronizer!
    if (!!currentUser.id) {
      analytics.setUserId(currentUser.id);
      analytics.setUserProperty("mki_user_id", currentUser.id);
    }

    errorMonitor.setUser(currentUser.id);

    featureFlagClient.setContext({
      userId: currentUser.id,
      email: currentEmail,
      organizationId: currentOrgId,
    });
  }, [currentOrgId, currentEmail, currentUser]);
}
