import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";
export function WarningBordered({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} testID={testID}>
      <Path
        clipRule="evenodd"
        d="M19.4605 23.2499H4.53784C1.09043 23.2499 -1.07664 19.5324 0.622069 16.5325L8.08341 3.35612C9.80685 0.312602 14.1915 0.312615 15.915 3.35612L23.3763 16.5325C25.075 19.5324 22.9079 23.2499 19.4605 23.2499Z"
        fill={theme.color.warning.border.weak.base}
        fillRule="evenodd"
      />

      <Path
        d="M9.82473 4.34161C10.7822 2.65077 13.2181 2.65078 14.1756 4.34162L21.6369 17.518C22.5807 19.1846 21.3767 21.2499 19.4615 21.2499H4.53882C2.62359 21.2499 1.41966 19.1846 2.36339 17.518L9.82473 4.34161Z"
        fill={theme.color.warning.icon.base}
      />
      <Path
        d="M11 13.75L11 9.5C11 8.94772 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94772 13 9.5L13 13.75C13 14.3023 12.5523 14.75 12 14.75C11.4477 14.75 11 14.3023 11 13.75Z"
        fill={theme.color.warning.iconIn.base}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <Circle cx="12" cy="17.25" fill={theme.color.warning.iconIn.base} r="1.25" />
    </Svg>
  );
}
