import { I18n } from "@meraki/core/i18n";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { LoginResponseType, verifyIsLoginResponse } from "@meraki/shared/api";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { RoundedButtonType } from "~/enterprise/components/RoundedButton";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { APP_KEY } from "~/i18n/translationUtils";
import { showAlert } from "~/lib/AlertUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { OTPAuthScreen as MagneticOTPAuthScreen } from "~/migrationZone/enterprise/auth/screens/OTPAuthScreen/OTPAuthScreen";
import { currentUserState } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import InputRow from "~/shared/rows/InputRow";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { RootState } from "../types/Redux";

type ReduxProps = {
  currentUserEmail: string;
};

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "OTPAuth"> &
  BasicActions &
  ReduxProps &
  PendingComponent;

interface OTPAuthScreenState {
  pinCode: string;
  loading: boolean;
}

export class OTPAuthScreen extends PureComponent<Props, OTPAuthScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pinCode: "",
      loading: false,
    };
  }

  renderResendOTP() {
    const { actions, handleError } = this.props;
    const onPress = () => {
      this.setState({ loading: true });
      actions
        .resendOTPCode()
        .then(() => {
          showAlert(
            I18n.t("TWO_FACTOR_AUTH.EMAIL_RESENT_SUCCESS_TITLE"),
            I18n.t("TWO_FACTOR_AUTH.EMAIL"),
          );
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    };

    // TODO: show some indication of whether or not this post succeeded
    return (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType={RoundedButtonType.secondary}
          buttonStyle={styles.resendCodeContainer}
          onPress={onPress}
          testID="OTP.RESEND_BUTTON"
        >
          {I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
        </RoundedButton>
      </View>
    );
  }

  pushOrgChoose = (response?: LoginResponseType) => {
    const { navigation } = this.props;
    // if everything succeeded during login and there is no followup, then nothing is returned
    // from the loginUser call and we have nothing to handle post login
    if (!response) {
      return;
    }

    if (verifyIsLoginResponse(response)) {
      navigation.navigate("VerifyEmail", { response });
      return;
    }

    const { mode } = response;

    if (mode === "org_choose") {
      navigation.navigate("OrgChoose", { initialLogin: true });
    }
  };

  submitOTP = () => {
    const { actions, setReqPending, handleError } = this.props;
    const { pinCode } = this.state;
    setReqPending(true);
    actions
      .submitOTPAuth(pinCode)
      .then((response) => {
        this.pushOrgChoose(response);
        setReqPending(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  render() {
    const { currentUserEmail } = this.props;
    const { pinCode, loading } = this.state;

    return (
      <FullScreenContainerView>
        <MkiText screenStyles={styles.instructionsText} testID="OTP.DESCRIPTION">
          {I18n.t(`TWO_FACTOR_AUTH.OTP.${APP_KEY}`, { email: currentUserEmail })}
        </MkiText>
        <InputRow
          value={pinCode}
          placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
          keyboardType={KEYBOARD_TYPE.numeric}
          onChangeText={(pinCode) => this.setState({ pinCode })}
          testID="OTP.CODE_INPUT"
        >
          {I18n.t("TWO_FACTOR_AUTH.CODE")}
        </InputRow>
        <RoundedButton
          buttonType={RoundedButtonType.primary}
          buttonStyle={appSelect({
            enterprise: styles.verifyButtonEnterpriseContainer,
            go: styles.verifyButtonGoContainer,
          })}
          onPress={this.submitOTP}
          testID="OTP.VERIFY_BUTTON"
        >
          {I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        </RoundedButton>
        {this.renderResendOTP()}
        <LoadingSpinner visible={loading} />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  instructionsText: {
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.large,
  },
  buttonContainer: {
    marginTop: SPACING.small,
  },
  verifyButtonEnterpriseContainer: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.default,
  },
  verifyButtonGoContainer: {
    marginHorizontal: SPACING.default,
  },
  resendCodeContainer: {
    marginHorizontal: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    currentUserEmail: currentUserState(state),
  };
}

const ConnectedOTPAuthScreen = compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(OTPAuthScreen);

const MagnetizedOTPAuthScreen = withMagneticReplacementAdapter(
  ConnectedOTPAuthScreen,
  MagneticOTPAuthScreen,
);

export default MagnetizedOTPAuthScreen;
