import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button, Heading, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useFirmwareUpgrades, useUpdateFirmwareUpgrades } from "@meraki/shared/api";
import { Picker } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef } from "react";
import { Alert, Pressable } from "react-native";
import Toast from "react-native-simple-toast";

type FirmwareUpgradeForm = {
  day: string;
  time: string;
};

const getHoursOfDay = () => {
  const date = new Date();
  const hours: { label: string; value: string }[] = [];
  for (let i = 0; i < 24; i++) {
    hours.push({
      label: new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hourCycle: "h12",
      }).format(new Date(date.setHours(i, 0, 0, 0))),
      value: `${i}:00`,
    });
  }
  return hours;
};

const HOURS_OF_DAY = getHoursOfDay();

const DAYS_OF_WEEK = [
  { value: "Sun", label: I18n.t("DAYS_OF_THE_WEEK.SUNDAY") },
  { value: "Mon", label: I18n.t("DAYS_OF_THE_WEEK.MONDAY") },
  { value: "Tue", label: I18n.t("DAYS_OF_THE_WEEK.TUESDAY") },
  { value: "Wed", label: I18n.t("DAYS_OF_THE_WEEK.WEDNESDAY") },
  { value: "Thu", label: I18n.t("DAYS_OF_THE_WEEK.THURSDAY") },
  { value: "Fri", label: I18n.t("DAYS_OF_THE_WEEK.FRIDAY") },
  { value: "Sat", label: I18n.t("DAYS_OF_THE_WEEK.SATURDAY") },
];
const showToast = (message: string) => Toast.showWithGravity(message, Toast.SHORT, Toast.BOTTOM);

export function FirmwareUpgradeScreen() {
  const navigation =
    useNavigation<NativeStackNavigationProp<DeviceGroupProps, "FirmwareUpgrade">>();
  const route = useRoute<RouteProp<DeviceGroupProps, "FirmwareUpgrade">>();
  const { params: props } = route;

  const currentNetworkId = useCurrentNetworkId();
  const networkId = props.networkId ?? currentNetworkId;

  const { data: currentFirmwareUpgradeValues, isLoading } = useFirmwareUpgrades({
    networkId,
  });
  const updateFirmwareUpgrades = useUpdateFirmwareUpgrades();

  const methods = useForm<FirmwareUpgradeForm>({
    values: {
      day: currentFirmwareUpgradeValues?.upgradeWindow?.dayOfWeek ?? "Sun",
      time: currentFirmwareUpgradeValues?.upgradeWindow?.hourOfDay ?? "4:00",
    },
  });
  const dayBottomSheetRef = useRef<BottomSheetMethods>(null);
  const timeBottomSheetRef = useRef<BottomSheetMethods>(null);

  const selectedDay = methods.watch("day");
  const selectedTime = methods.watch("time");

  const onSave = methods.handleSubmit(({ day, time }) => {
    updateFirmwareUpgrades.mutate(
      {
        networkId,
        firmwareUpgrades: {
          upgradeWindow: {
            dayOfWeek: day,
            hourOfDay: time,
          },
        },
      },
      {
        onSuccess: () => {
          showToast(I18n.t("FIRMWARE_UPDATES.SUCCESS"));
          navigation.goBack();
        },
        onError: (error) => Alert.alert(String(error["errors"])),
      },
    );
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          text={I18n.t("SAVE")}
          kind="tertiary"
          onPress={onSave}
          disabled={!methods.formState.isDirty || isLoading}
        />
      ),
    });
  });

  return (
    <Screen addDefaultPadding>
      <Box bottomDividerBorder>
        <Heading size="h1">{I18n.t("FIRMWARE_UPDATES.HEADING")}</Heading>
      </Box>
      <Text size="p1">{I18n.t("FIRMWARE_UPDATES.DESCRIPTION")}</Text>
      <Box paddingLeft="xs">
        <Heading size="h4">{I18n.t("FIRMWARE_UPDATES.SELECT.DAY")}</Heading>
      </Box>
      <Pressable
        onPress={() => dayBottomSheetRef?.current?.present()}
        testID="FIRMWARE_UPGRADES.DAY"
      >
        <Box
          borderRadius="sm"
          borderColor="default.border.base"
          borderWidth="strong"
          padding="sm"
          backgroundColor="default.bg.weak.base"
        >
          <Text color="light">{DAYS_OF_WEEK.find((day) => day.value === selectedDay)?.label}</Text>
        </Box>
      </Pressable>
      <Box paddingLeft="xs">
        <Heading size="h4">{I18n.t("FIRMWARE_UPDATES.SELECT.TIME")}</Heading>
      </Box>
      <Pressable
        onPress={() => timeBottomSheetRef?.current?.present()}
        testID="FIRMWARE_UPGRADES.TIME"
      >
        <Box
          borderRadius="sm"
          borderColor="default.border.base"
          borderWidth="strong"
          padding="sm"
          backgroundColor="default.bg.weak.base"
        >
          <Text color="light">
            {HOURS_OF_DAY.find((hour) => hour.value === selectedTime)?.label}
          </Text>
        </Box>
      </Pressable>
      <Form {...methods}>
        <Picker<string>
          ref={dayBottomSheetRef}
          snapPoints={["CONTENT_HEIGHT"]}
          options={DAYS_OF_WEEK.map(({ value, label }) => ({
            value,
            label,
          }))}
          selectedOption={{
            value: selectedDay,
            label:
              DAYS_OF_WEEK.find((day) => day.value === selectedDay)?.label ??
              I18n.t("DAYS_OF_THE_WEEK.SUNDAY"),
          }}
          onSelectOption={({ value }) => methods.setValue("day", value, { shouldDirty: true })}
          testID="FIRMWARE_UPGRADES.DAY_PICKER"
        />
        <Picker<string>
          ref={timeBottomSheetRef}
          snapPoints={["95%"]}
          options={HOURS_OF_DAY.map(({ value, label }) => ({
            value,
            label,
          }))}
          selectedOption={{
            value: selectedTime,
            label: HOURS_OF_DAY.find((hour) => hour.value === selectedTime)?.label ?? "4 AM",
          }}
          onSelectOption={({ value }) => methods.setValue("time", value, { shouldDirty: true })}
          testID="FIRMWARE_UPGRADES.TIME_PICKER"
        />
      </Form>
    </Screen>
  );
}
