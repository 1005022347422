import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { BAND_SELECTIONS, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showSaveWarning } from "~/lib/AlertUtils";
import { currentNetworkState, ssidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const radioOptions = () => {
  const options: { label: string; sublabel: string; value: BAND_SELECTIONS }[] = [];
  options.push({
    label: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND.LABEL"),
    sublabel: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND.SUBLABEL"),
    value: BAND_SELECTIONS.dualBandOperation,
  });
  options.push({
    label: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.FIVE_GHZ_ONLY.LABEL"),
    sublabel: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.FIVE_GHZ_ONLY.SUBLABEL"),
    value: BAND_SELECTIONS.fiveGHzOnly,
  });
  options.push({
    label: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.TWO_GHZ_ONLY.LABEL"),
    sublabel: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.TWO_GHZ_ONLY.SUBLABEL"),
    value: BAND_SELECTIONS.twoGHzOnly,
  });
  options.push({
    label: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND_WITH_BAND_STEERING.LABEL"),
    sublabel: I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND_WITH_BAND_STEERING.SUBLABEL"),
    value: BAND_SELECTIONS.dualBandWithBandSteering,
  });
  return options;
};

type ReduxProps = {
  networkId: string;
  ssid: SSID;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDRadioSettings"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

interface RadioSettingsState {
  selectedBand: BAND_SELECTIONS;
}

export class SSIDRadioSettingsScreen extends PureComponent<Props, RadioSettingsState> {
  constructor(props: Props) {
    super(props);

    const { ssid } = props;
    const { bandSelection } = ssid;
    this.state = { selectedBand: bandSelection || BAND_SELECTIONS.dualBandOperation };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={this.close} />,
      headerRight: () => <SaveButton onPress={this.save} disabled={!this.hasChanges()} />,
    });
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  close = () => {
    const { navigation } = this.props;

    if (this.hasChanges()) {
      showSaveWarning(this.save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  };

  save = () => {
    const { selectedBand } = this.state;
    const { actions, networkId, ssid, setReqPending, handleError, cancelablePromise, navigation } =
      this.props;
    setReqPending(true);
    cancelablePromise(actions.setSsid(networkId, { ...ssid, bandSelection: selectedBand }))
      .then(() => {
        setReqPending(false);
        navigation.goBack();
      })
      .catch(handleError);
  };

  hasChanges = () => {
    const { selectedBand } = this.state;
    const { ssid } = this.props;
    const { bandSelection } = ssid;

    return selectedBand !== bandSelection;
  };

  render() {
    const { selectedBand } = this.state;

    return (
      <FullScreenContainerView withScroll>
        <DefaultHeader
          title={I18n.t("RADIO_SETTINGS.HEADER")}
          description={I18n.t("RADIO_SETTINGS.MESSAGE")}
        />
        <RadioSelectionList
          options={radioOptions()}
          onSelect={(bandSelection) => this.setState({ selectedBand: bandSelection })}
          selectedValue={selectedBand}
          screenStyles={styles.contentContainer}
          radioStyle={styles.radioSpacing}
        />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    margin: SPACING.default,
  },
  radioSpacing: {
    marginVertical: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SSIDRadioSettings"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDDetailsScreen",
      currentNetworkState(state),
    ),
    ssid: ssidsSelector(state)[props.ssidNumber] || {},
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(SSIDRadioSettingsScreen);
