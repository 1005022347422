import { useClients } from "@meraki/shared/api";
import { SECONDS_IN_A_DAY } from "@meraki/shared/redux";
import { getIpAddressSync } from "react-native-device-info";

export const useConnectedToNetworkByIp = (networkId?: string, targetIp?: string) => {
  return useClients(
    { networkId, timespan: SECONDS_IN_A_DAY },
    {
      select: (data) => {
        if (data.length === 0) {
          return false;
        }
        targetIp = targetIp || getIpAddressSync();
        return data.some((client) => client.ip === targetIp);
      },
    },
  );
};
