import { useEffect, useRef } from "react";
import { Animated, StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { isWeb } from "~/lib/PlatformUtils";

interface LiveBackgroundProps {
  children: React.ReactNode;
  timestamp?: number;
}

function LiveBackground({ children, timestamp }: LiveBackgroundProps) {
  const opacity = useRef(new Animated.Value(0.7)).current;
  const scale = useRef(new Animated.Value(1.0)).current;
  const prevTimestamp = useRef(0);

  useEffect(() => {
    if (timestamp == null || timestamp === prevTimestamp.current) {
      return;
    }

    prevTimestamp.current = timestamp;

    Animated.sequence([
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0.0,
          duration: 1400,
          useNativeDriver: true,
        }),
        Animated.timing(scale, {
          toValue: 32.0,
          duration: 1400,
          useNativeDriver: true,
        }),
      ]),
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0.0,
          duration: 600,
          useNativeDriver: true,
        }),
        Animated.timing(scale, {
          toValue: 1.0,
          duration: 600,
          useNativeDriver: true,
        }),
      ]),
    ]).start(() => {
      scale.setValue(1.0);
      opacity.setValue(0.7);
    });
  }, [timestamp, opacity, scale]);

  const animatedStyles = {
    opacity,
    transform: [
      {
        scale,
      },
    ],
  };

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.staticStyles, animatedStyles]} />
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  staticStyles: {
    position: "absolute",
    backgroundColor: MkiColors.primaryButton,
    // @ts-expect-error TS(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
    borderRadius: isWeb() ? "50%" : 64,
    height: 1,
    width: 1,
  },
});

export default LiveBackground;
