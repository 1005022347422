import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

const NodeJsonSchema = z.object({
  nodes: z.record(
    z.string(),
    z.object({
      serial: z.optional(z.string()),
      upseries: z.optional(z.array(z.array(z.number()).length(2))),
      /**
       * Add more properties here as needed, keep in mind that they all should probably be optional
       * since the 'f' field below has the final say in what properties are in the response.
       */
    }),
  ),
});

type NodeJson = z.infer<typeof NodeJsonSchema>;

type DeviceByIdRequest = {
  nodeGroupEncryptedId?: string;
  id: string;
  fields?: string[];
};

/**
 * @param f: seems to stand for 'fields' and is an array of the device's properties you'd like the endpoint to return.
 * @param ids: even though is plural it is the device id you'd like to fetch info for. Arrays don't seem to work for this one ¯\_(ツ)_/¯.
 */
const buildQueryParams = ({ id, fields = [] }: DeviceByIdRequest) => ({
  f: fields,
  ids: id,
});

function buildUrl(nodeGroupEncryptedId: DeviceByIdRequest["nodeGroupEncryptedId"]) {
  return `/n/${nodeGroupEncryptedId}/manage/nodes/json`;
}

export function fetchNodesJSON({
  id,
  fields,
  nodeGroupEncryptedId,
}: DeviceByIdRequest): Promise<NodeJson> {
  return request(NodeJsonSchema, "GET", buildUrl(nodeGroupEncryptedId), {
    queryParams: buildQueryParams({ id, fields }),
  });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useDeviceById = createQuery<DeviceByIdRequest, NodeJson, Error>({
  baseQueryKey: buildUrl("{nodeGroupEncryptedId}"),
  queryFn: (variables) => fetchNodesJSON(variables),
});
