import { AuthType, AuthUser, useAuthUsers } from "@meraki/shared/api";

export const useAuthIdByEmail = (email: string, authType: AuthType, networkId?: string) => {
  return useAuthUsers(
    { networkId },
    {
      select: (data) => {
        if (!data) {
          return "";
        }
        const authUser = data.find(
          (user: AuthUser) => user.email === email && user.accountType === authType,
        );
        return authUser?.id ?? "";
      },
    },
  );
};
