import { createSelector } from "reselect";

import { normalizeObjectByKey } from "~/lib/objectHelper";
import { getTotalUsage } from "~/lib/UsageUtils";
import { devicesByMacSelector } from "~/selectors/devices";
import { clientsState, deviceClientsState, devicesState } from "~/selectors/getters";
import { DisplayableClient } from "~/shared/types/Client";
import Device from "~/shared/types/Device";
import { RootState } from "~/shared/types/Redux";

export const deviceClientsForSerial = (state: RootState, serial: string) =>
  deviceClientsState(state)[serial];

export const deviceClientsForSerialByMac = createSelector(deviceClientsForSerial, (clients) =>
  normalizeObjectByKey(clients, "mac"),
);

const getFilteredDeviceClientsForSerial = createSelector(
  clientsState,
  devicesState,
  //@ts-ignore
  deviceClientsState,
  (_: RootState, serial: string) => serial,
  devicesByMacSelector,
  (
    clients,
    devices,
    clientsBySerial: DisplayableClient[],
    serial: string,
    devicesByMac: Record<string, Device> | undefined,
  ) => {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    return clientsBySerial[serial]
      ?.filter(({ mac }: DisplayableClient) => !devicesByMac?.[mac])
      .filter(({ id }: DisplayableClient) => {
        // Remove once backend is fixed
        const clientDeviceId = clients?.[id]?.nodeId;
        if (!clientDeviceId) {
          return true;
        }

        return clientDeviceId === devices?.[serial]?.id;
      });
  },
);

export const deviceClientsForSerialSorted = (state: RootState, serial: string) => {
  return (
    //@ts-ignore
    getFilteredDeviceClientsForSerial(state, serial)
      //@ts-ignore
      ?.slice()
      .sort((a: DisplayableClient, b: DisplayableClient) => {
        return getTotalUsage(b.usage) - getTotalUsage(a.usage);
      })
  );
};
