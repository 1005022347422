import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import { LoginResponse, LoginResponseType } from "./types";

type SubmitData = {
  code: string;
};

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const submit2FA = (formData: SubmitData) =>
  // todo: there is some kind of "remember me" logic attached to 2FA we need to implement
  request(LoginResponse, "POST", "/login/do_sms_auth", {
    headers,
    queryParams: {
      ...formData,
      remember_user: 1,
    },
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSubmit2FA = createMutation<SubmitData, LoginResponseType>({
  baseMutationKey: "/login/do_sms_auth",
  mutationFn: (data) => submit2FA(data),
});

type Resend2FAData = {
  retry: "primary" | "secondary";
};

const Resend2FASchemaResponse = z
  .object({
    mode: z.literal("sms"),
    primary: z.string(),
    secondary: z.string(),
  })
  .describe("Resend2FASchemaResponse");

type Resend2FAResponse = z.infer<typeof Resend2FASchemaResponse>;

const resend2FA = (formData: Resend2FAData) =>
  request(Resend2FASchemaResponse, "POST", "/login/sms_auth_retry", {
    headers,
    queryParams: formData,
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useResend2FA = createMutation<Resend2FAData, Resend2FAResponse>({
  baseMutationKey: "/login/sms_auth_retry",
  mutationFn: (data) => resend2FA(data),
});
