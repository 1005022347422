import { formatDuration } from "date-fns";
export const ActiveAndQueuedStatuses = ["active", "expiring", "recentlyQueued"];
export const ActiveStatuses = ["active", "expiring", "unusedActive"];

export const getFormattedStringFromDays = (numberOfDays: number) => {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  return formatDuration({ years, months, days });
};
