import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { Card, Heading, Numeral, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useClientsOverview, useNetwork } from "@meraki/shared/api";
import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import {
  ClientGroupProps,
  ClientTypeFilterOption,
  ConnectionTypeFilterOption,
  IsHighUsageFilterOption,
  IsOnlineFilterOption,
} from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

type ClientsOverviewCardProps = {
  t0?: string;
  t1?: string;
  isWirelessProductHub?: boolean;
  initialFilters?: {
    clientType?: Set<ClientTypeFilterOption>;
    connectionType?: Set<ConnectionTypeFilterOption>;
    isOnline?: Set<IsOnlineFilterOption>;
    isHighUsage?: Set<IsHighUsageFilterOption>;
  };
} & AnalyticsProp<"onPress">;

export function ClientsOverviewCard({
  t0,
  t1,
  analytics,
  initialFilters,
}: ClientsOverviewCardProps) {
  const navigation = useNavigation<NativeStackNavigationProp<ClientGroupProps>>();
  const networkId = useCurrentNetworkId();
  const globalTimespan = useGlobalTimespan();
  const currentNetwork = useNetwork({ networkId }, { enabled: Boolean(networkId) }).data;
  const trackEvent = useAnalytics(analytics);

  const networkSupportsClients = () => {
    const networkProductTypes = currentNetwork?.productTypes;

    if (!networkProductTypes) {
      return false;
    }
    return networkProductTypes.some(
      (type) => type === "wireless" || type === "appliance" || type === "switch",
    );
  };

  const { data: clientsOverview, isLoading } = useClientsOverview(
    { networkId, timespan: globalTimespan, t0, t1 },
    { enabled: networkSupportsClients() },
  );
  const clientsOverviewExists = clientsOverview && clientsOverview?.counts;

  if (!networkSupportsClients() || !clientsOverviewExists) {
    return null;
  }

  const { counts, usages } = clientsOverview;
  const highUsageNum = counts?.withHeavyUsage;
  const uniqueNum = counts?.total;

  const { value: scaledUsage, unit: usageUnit } = formatAndParseKibibytes(usages?.average ?? 0);

  const noData = (uniqueNum ?? 0) === 0;

  return (
    <Card
      loading={isLoading}
      loadingContentHeight={60}
      testID="CLIENTS_OVERVIEW_CARD"
      onPress={() => {
        trackEvent("onPress", { interaction: "whitespace", high_usage: false });
        navigation.navigate("ClientList");
      }}
    >
      <Card.Header title={I18n.t("CLIENTS_OVERVIEW_CARD.TITLE")} />
      {noData && <Text size="p2">{I18n.t("CLIENTS_OVERVIEW_CARD.NO_CLIENTS")}</Text>}
      {!noData && (
        <Card.Content flexDirection="row" gap="xs">
          <Card.Well
            flex={1}
            onPress={() => {
              trackEvent("onPress", { interaction: "high_usage", high_usage: true });
              navigation.navigate("ClientList", {
                title: I18n.t("CLIENTS_OVERVIEW_CARD.TITLE"),
                initialFilters: {
                  isHighUsage: new Set(["isHighUsage"]),
                  ...initialFilters,
                },
              });
            }}
          >
            <Heading size="h2">{highUsageNum}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text size="p3" weight="semiBold">
                {I18n.t("CLIENTS_OVERVIEW_CARD.HIGH")}
              </Text>
              <Status status="warning" />
            </Box>
          </Card.Well>
          <Card.Well
            flex={1}
            onPress={() => trackEvent("onPress", { interaction: "unique", high_usage: false })}
          >
            <Heading size="h2">{uniqueNum}</Heading>
            <Text size="p3" weight="semiBold">
              {I18n.t("CLIENTS_OVERVIEW_CARD.UNIQUE")}
            </Text>
          </Card.Well>
          <Card.Well
            flex={1}
            onPress={() =>
              trackEvent("onPress", { interaction: "average_usage", high_usage: false })
            }
          >
            <Numeral value={scaledUsage} units={usageUnit} />
            <Text size="p3" weight="semiBold">
              {I18n.t("CLIENTS_OVERVIEW_CARD.AVERAGE")}
            </Text>
          </Card.Well>
        </Card.Content>
      )}
    </Card>
  );
}
