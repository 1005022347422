import { I18n } from "@meraki/core/i18n";
import { launchGoSiteCompanyManagementDoc } from "@meraki/go/links";
import { useAdmins } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { useGXUMBLicense } from "~/go/hooks/useFilteredLicenses";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { sizeSelect } from "~/lib/themeHelper";
import { ApiResponseAction } from "~/middleware/api";
import { currentOrganizationName, devicesState, getGXSerial, getOrgNetworks } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LinkText from "~/shared/components/LinkText";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { ActiveStatuses } from "~/shared/types/License";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "OrgDelete"> & PendingComponent;

function OrgDeleteScreen({ handleError, reqPending, setReqPending }: Props) {
  const { deleteNetwork, deleteOrg, loadNodes } = useActions();
  const { cancelablePromise } = useCancelablePromise();

  const organizationId = useCurrentOrganizationId() ?? "";
  const networkId = useCurrentNetworkId();
  const serialNumber = useAppSelector(getGXSerial) ?? "";
  const { data: admins } = useAdmins({ organizationId });
  const devices = useAppSelector(devicesState);
  const { data: license } = useGXUMBLicense(serialNumber, organizationId, networkId);
  const orgNetworks = useAppSelector(getOrgNetworks);
  const organization = useAppSelector(currentOrganizationName);
  useEffect(() => {
    async function getNodesForOrg() {
      setReqPending(true);
      const requests: Promise<ApiResponseAction<void>>[] = [];
      try {
        if (orgNetworks != null) {
          for (const { id } of orgNetworks) {
            requests.push(loadNodes(id));
          }
        }

        await cancelablePromise(Promise.all(requests));
      } catch (e: any) {
        handleError(e);
      }
      setReqPending(false);
    }
    getNodesForOrg();
  }, [cancelablePromise, handleError, loadNodes, orgNetworks, setReqPending]);

  const detectInvalidCases = () => {
    const errors: string[] = [];

    if (admins && Object.keys(admins).length > 1) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.MORE_THAN_ONE_ADMINS"));
    }

    if (Object.keys(devices).length > 0) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.DEVICES_PRESENT"));
    }

    if (license != null && ActiveStatuses.includes(license.state)) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.ACTIVE_LICENSE"));
    }

    return errors;
  };

  const deleteOrganization = async () => {
    if (organizationId != null) {
      try {
        setReqPending(true);

        if (orgNetworks != null) {
          for (const { id } of orgNetworks) {
            await deleteNetwork(id);
          }
        }

        await deleteOrg(organizationId);
      } catch (error) {
        handleError(error || I18n.t("ERRORS.SERVER_ERROR_TEXT"));
      } finally {
        setReqPending(false);
      }
    }
  };

  const showDeleteConfirmation = () => {
    const errors = detectInvalidCases();

    if (errors.length > 0) {
      showAlert(I18n.t("ERROR"), errors.join("\n\n"));
      return;
    }

    showAlert(
      I18n.t("WARNING"),
      I18n.t("DELETE_LOCATION.CONFIRMATION", { organization }),
      deleteOrganization,
      {
        negativeText: I18n.t("CANCEL"),
      },
    );
  };

  return (
    <FullScreenContainerView screenStyles={styles.screen}>
      <View style={styles.container}>
        <MkiText textStyle="secondary">{I18n.t("DELETE_LOCATION.MESSAGE")}</MkiText>
        <LinkText
          accessibilityRole="link"
          textStyles={styles.linkText}
          onPress={launchGoSiteCompanyManagementDoc}
        >
          {I18n.t("DELETE_LOCATION.HELP_LINK")}
        </LinkText>
      </View>
      <View style={styles.footerContainer}>
        <RoundedButton
          onPress={showDeleteConfirmation}
          buttonType={ButtonType.destructive}
          screenStyles={styles.button}
          disabled={reqPending}
        >
          {I18n.t("DELETE_LOCATION.BUTTON", { organization })}
        </RoundedButton>
      </View>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  screen: {
    justifyContent: "space-between",
  },
  container: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  linkText: {
    color: MkiColors.primaryButton,
    marginTop: SPACING.default,
    textAlign: "center",
  },
  footerContainer: {
    marginVertical: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
  },
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
});

export default withPendingComponent(OrgDeleteScreen);
