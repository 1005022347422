import { z } from "zod";

export const StatusSchema = z
  .union([
    z.literal("online"),
    z.literal("offline"),
    z.literal("alerting"),
    z.literal("dormant"),
  ] as const)
  .describe("StatusSchema");

export type Status = z.infer<typeof StatusSchema>;

export const ProductTypeSchema = z
  .union([
    z.literal("wireless"),
    z.literal("appliance"),
    z.literal("switch"),
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("phone"),
    z.literal("sensor"),
    z.literal("systemsManager"),
  ] as const)
  .describe("ProductTypeSchema");

export type ProductType = z.infer<typeof ProductTypeSchema>;
