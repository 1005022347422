import {
  GET_APPLIANCE_PORTS_SUCCESS,
  GX_PORTS_UPDATE,
  UPDATE_APPLIANCE_PORT_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { gxPortVlanSettingsIndexForNum } from "~/lib/GXPortUtils";
import { reducerHelper } from "~/lib/ReducerUtils";
import { GXPort, GXPortAPIData, GXPortLiveData } from "~/shared/types/ApplianceTypes";

interface GXPortsReduxState {
  [serial: string]: GXPort[];
}

const initialState: GXPortsReduxState = {};

export default function gxPorts(state: GXPortsReduxState = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case GX_PORTS_UPDATE: {
      const { serial, liveData }: { serial: string; liveData: GXPortLiveData[] } = action;

      const result = Object.values(liveData).map((data: GXPortLiveData, index: number) => {
        return {
          ...state?.[serial]?.[index],
          ...data,
          number: index + 1,
        };
      });

      return reducerHelper(state, serial, { ...result });
    }
    case GET_APPLIANCE_PORTS_SUCCESS: {
      const { serial } = action.meta;

      const uplink = {
        ...state?.[serial]?.[0],
        number: 1,
      };

      const nonUplinkPorts = action.response.map((portData: GXPortAPIData, index: number) => {
        return {
          ...state?.[serial]?.[index + 1],
          ...portData,
        };
      });

      const allPorts = [uplink, ...nonUplinkPorts];

      return reducerHelper(state, serial, { ...allPorts });
    }
    case UPDATE_APPLIANCE_PORT_SUCCESS: {
      const { serial } = action.meta;
      const portData = action?.response;

      const newState = {
        ...state?.[serial],
      };

      const portIndex = gxPortVlanSettingsIndexForNum(portData?.number);
      newState[portIndex] = {
        ...newState?.[portIndex],
        ...portData,
      };

      return reducerHelper(state, serial, newState);
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
