import {
  DOOR_RELATED_EVENTS_REQUEST,
  DOOR_RELATED_EVENTS_SUCCESS,
  SENSOR_EVENTS_REQUEST,
  SENSOR_EVENTS_SUCCESS,
  WATER_RELATED_EVENTS_REQUEST,
  WATER_RELATED_EVENTS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { find } from "lodash";

import { SensorEventTypes, WATER_DETECTED_VAL } from "~/shared/constants/SensorEventTypes";
import { SensorEvent } from "~/shared/types/SensorEvents";

interface SensorEventsState {
  [sensorSerial: string]: {
    historical: SensorEvent[];
    lastWaterDetectedAt?: string;
  };
}

const initialState: SensorEventsState = {};

export default function sensorEvents(state: any = initialState, action: any) {
  const { type, response } = action;
  switch (type) {
    case DOOR_RELATED_EVENTS_REQUEST: {
      const { sensorSerial } = action.meta;
      const newState = { ...state };
      newState[sensorSerial] = { ...newState[sensorSerial], isFetchingDoorRelatedEvents: true };
      return newState;
    }
    case DOOR_RELATED_EVENTS_SUCCESS: {
      const { sensorSerial } = action.meta;
      const newState = { ...state };
      newState[sensorSerial] = {
        ...newState[sensorSerial],
        isFetchingDoorRelatedEvents: false,
        historical: response,
      };
      return newState;
    }
    case SENSOR_EVENTS_REQUEST: {
      return { ...state, isFetchingSensorEvents: true };
    }
    case SENSOR_EVENTS_SUCCESS: {
      const { sensorSerial } = action.meta;
      const newState = { ...state, isFetchingSensorEvents: false };
      newState[sensorSerial] = { ...newState[sensorSerial] };
      newState[sensorSerial].historical = response;
      return newState;
    }
    case WATER_RELATED_EVENTS_SUCCESS: {
      const { sensorSerial } = action.meta;
      const newState = { ...state, isFetchingWaterRelatedEvents: false };
      newState[sensorSerial] = { ...newState[sensorSerial] };
      newState[sensorSerial].historical = response;

      const lastWaterDetected = find(
        response,
        ({ type, eventData }: any) =>
          type === SensorEventTypes.water_detection && eventData.value === WATER_DETECTED_VAL,
      );
      newState[sensorSerial].lastWaterDetectedAt = lastWaterDetected?.occurredAt;

      return newState;
    }
    case WATER_RELATED_EVENTS_REQUEST: {
      return { ...state, isFetchingWaterRelatedEvents: true };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
