import { I18n } from "@meraki/core/i18n";
import { BottomSheetMethods, List, Numeral, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useCounterSets, useNetworkUsageblocks, useOrgNetwork } from "@meraki/shared/api";
import {
  FilterBottomSheet,
  FilterTagList,
  getIndexToGraphColorToken,
  SearchFilterSection,
  SingleBarGraph,
} from "@meraki/shared/components";
import { FilterConfiguration, FiltersState, useFilteredState } from "@meraki/shared/filters";
import {
  formatAndParseKibibytes,
  formatUseNetblocksRusageWithCounterSet,
} from "@meraki/shared/formatters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useMemo, useRef, useState } from "react";

// Counter sets 19 has Layer 7 categories as Application group
export const LAYER_7_CATEGORY_BY_FILTER = {
  advertising: "Advertising",
  blogging: "Blogging",
  businessManagement: "Business management",
  cloudServices: "Cloud services",
  databases: "Databases",
  email: "Email",
  fileSharing: "File sharing",
  gaming: "Gaming",
  healthCare: "Health care",
  music: "Music",
  news: "News",
  onlineBackup: "Online backup",
  p2pSharing: "Peer-to-peer (P2P)",
  photoSharing: "Photo sharing",
  productivity: "Productivity",
  remoteMonitoring: "Remote monitoring & management",
  security: "Security",
  socialWeb: "Social web",
  softwareVirus: "Software & anti-virus updates",
  sports: "Sports",
  video: "Video",
  voip: "VoIP & video conferencing",
  webFileSharing: "Web file sharing",
  webPayments: "Web payments",
} as const;

const LAYER_7_CATEGORIES = Object.values(LAYER_7_CATEGORY_BY_FILTER);

type ApplicationFilterType = keyof typeof LAYER_7_CATEGORY_BY_FILTER;
type Layer7Category = (typeof LAYER_7_CATEGORIES)[number];

type ApplicationFilterCategory = "group";

type ApplicationData = {
  total: number;
  name: string;
  group?: Layer7Category;
};

type ApplicationFilterOptions = {
  group: ApplicationFilterType;
};

export const applicationFilterConfiguration: FilterConfiguration<
  ApplicationFilterCategory,
  ApplicationFilterOptions,
  ApplicationData
> = (item) => {
  return {
    group: {
      advertising: item.group === LAYER_7_CATEGORY_BY_FILTER.advertising,
      blogging: item.group === LAYER_7_CATEGORY_BY_FILTER.blogging,
      businessManagement: item.group === LAYER_7_CATEGORY_BY_FILTER.businessManagement,
      cloudServices: item.group === LAYER_7_CATEGORY_BY_FILTER.cloudServices,
      databases: item.group === LAYER_7_CATEGORY_BY_FILTER.databases,
      email: item.group === LAYER_7_CATEGORY_BY_FILTER.email,
      fileSharing: item.group === LAYER_7_CATEGORY_BY_FILTER.fileSharing,
      gaming: item.group === LAYER_7_CATEGORY_BY_FILTER.gaming,
      healthCare: item.group === LAYER_7_CATEGORY_BY_FILTER.healthCare,
      music: item.group === LAYER_7_CATEGORY_BY_FILTER.music,
      news: item.group === LAYER_7_CATEGORY_BY_FILTER.news,
      onlineBackup: item.group === LAYER_7_CATEGORY_BY_FILTER.onlineBackup,
      p2pSharing: item.group === LAYER_7_CATEGORY_BY_FILTER.p2pSharing,
      photoSharing: item.group === LAYER_7_CATEGORY_BY_FILTER.photoSharing,
      productivity: item.group === LAYER_7_CATEGORY_BY_FILTER.productivity,
      remoteMonitoring: item.group === LAYER_7_CATEGORY_BY_FILTER.remoteMonitoring,
      security: item.group === LAYER_7_CATEGORY_BY_FILTER.security,
      socialWeb: item.group === LAYER_7_CATEGORY_BY_FILTER.socialWeb,
      softwareVirus: item.group === LAYER_7_CATEGORY_BY_FILTER.softwareVirus,
      sports: item.group === LAYER_7_CATEGORY_BY_FILTER.sports,
      video: item.group === LAYER_7_CATEGORY_BY_FILTER.video,
      voip: item.group === LAYER_7_CATEGORY_BY_FILTER.voip,
      webFileSharing: item.group === LAYER_7_CATEGORY_BY_FILTER.webFileSharing,
      webPayments: item.group === LAYER_7_CATEGORY_BY_FILTER.webPayments,
    },
  };
};

export function ApplicationUsageListScreen() {
  const FILTER_TRANSLATIONS = {
    advertising: I18n.t("LAYER_7.ADVERTISING"),
    blogging: I18n.t("LAYER_7.BLOGGING"),
    businessManagement: I18n.t("LAYER_7.BUSINESS_MANAGEMENT"),
    cloudServices: I18n.t("LAYER_7.CLOUD_SERVICES"),
    databases: I18n.t("LAYER_7.DATABASES"),
    email: I18n.t("LAYER_7.EMAIL"),
    fileSharing: I18n.t("LAYER_7.FILE_SHARING"),
    gaming: I18n.t("LAYER_7.GAMING"),
    healthCare: I18n.t("LAYER_7.HEALTH_CARE.DISPLAY_NAME"),
    music: I18n.t("LAYER_7.MUSIC"),
    news: I18n.t("LAYER_7.NEWS"),
    onlineBackup: I18n.t("LAYER_7.ONLINE_BACKUP"),
    p2pSharing: I18n.t("LAYER_7.P2P_SHARING.DISPLAY_NAME"),
    photoSharing: I18n.t("LAYER_7.PHOTO_SHARING"),
    productivity: I18n.t("LAYER_7.PRODUCTIVITY"),
    remoteMonitoring: I18n.t("LAYER_7.REMOTE_MONITORING"),
    security: I18n.t("LAYER_7.SECURITY"),
    socialWeb: I18n.t("LAYER_7.SOCIAL_WEB"),
    softwareVirus: I18n.t("LAYER_7.SOFTWARE_VIRUS"),
    sports: I18n.t("LAYER_7.SPORTS"),
    video: I18n.t("LAYER_7.VIDEO"),
    voip: I18n.t("LAYER_7.VOIP"),
    webFileSharing: I18n.t("LAYER_7.WEB_FILE_SHARING"),
    webPayments: I18n.t("LAYER_7.WEB_PAYMENTS"),
  };

  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const organizationId = useCurrentOrganizationId();
  const { data: network } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const encryptedNetworkId = network?.eid;

  const { data: networkUseblocks, isLoading: isUseblocksLoading } = useNetworkUsageblocks({
    timespan,
    encryptedNetworkId,
  });
  const { data: counterSets, isLoading: isCounterSetsLoading } = useCounterSets({
    encryptedNetworkId,
  });

  const applicationData: ApplicationData[] = useMemo(
    () =>
      Object.values(formatUseNetblocksRusageWithCounterSet(networkUseblocks, counterSets))
        .map(({ download, upload, name, group }) => ({
          total: download + upload,
          name: name,
          group: group ? (group as Layer7Category) : undefined,
        }))
        .sort((a, b) => b.total - a.total),
    [networkUseblocks, counterSets],
  );
  const totalUsage = useMemo(
    () => applicationData.reduce((sum, application) => sum + application.total, 0),
    [applicationData],
  );

  const { setFilters, filters, filteredData } = useFilteredState<
    ApplicationFilterCategory,
    ApplicationFilterOptions,
    ApplicationData
  >({}, applicationData, { filterConfiguration: applicationFilterConfiguration });
  const [searchText, setSearchText] = useState("");

  const searchedData = useMemo(
    () => filteredData.filter(({ name }) => name.includes(searchText)),
    [filteredData, searchText],
  );
  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);
  return (
    <Screen>
      <SearchFilterSection
        placeholder={I18n.t("SEARCH")}
        searchText={searchText}
        onSearchTextChange={setSearchText}
        onFilterPress={() => filterBottomSheetRef.current?.present()}
      />
      <FilterTagList
        filters={{
          group:
            filters.group != null
              ? Array.from(filters.group).map((filter) => ({
                  value: filter,
                  label: FILTER_TRANSLATIONS[filter],
                }))
              : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<
            ApplicationFilterCategory,
            ApplicationFilterOptions
          > = {};

          if (filters.group.length > 0) {
            formattedFilters.group = new Set(filters.group);
          }

          setFilters(formattedFilters);
        }}
      />
      <List.FlashList
        data={searchedData.map((application, idx) => ({ application, idx }))}
        getItemData={(applicationInfo) => {
          const { application, idx } = applicationInfo;
          const { value, unit } = formatAndParseKibibytes(application.total);
          const percentage = application.total / totalUsage;
          return {
            title: application.name,
            children: (
              <Box>
                <SingleBarGraph
                  testID={`BAR_GRAPH.${application.name.toUpperCase()}`}
                  data={[
                    {
                      percentage,
                      color: getIndexToGraphColorToken(idx),
                    },
                  ]}
                />
                <Box flex={1} flexDirection={"row"} alignItems="center" paddingTop="sm">
                  <Numeral units={unit} value={value} size="n2" />
                  <Text color="light">
                    {I18n.t("MERAKI_GO_3.APPLICATION_USAGE_LIST_SCREEN.PERCENTAGE_USAGE", {
                      percentage: (percentage * 100).toFixed(2),
                    })}
                  </Text>
                </Box>
              </Box>
            ),
          };
        }}
        loading={isUseblocksLoading || isCounterSetsLoading}
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          group: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
            options: [
              { value: "advertising", label: FILTER_TRANSLATIONS.advertising },
              { value: "blogging", label: FILTER_TRANSLATIONS.blogging },
              { value: "businessManagement", label: FILTER_TRANSLATIONS.businessManagement },
              { value: "cloudServices", label: FILTER_TRANSLATIONS.cloudServices },
              { value: "databases", label: FILTER_TRANSLATIONS.databases },
              { value: "email", label: FILTER_TRANSLATIONS.email },
              { value: "fileSharing", label: FILTER_TRANSLATIONS.fileSharing },
              { value: "gaming", label: FILTER_TRANSLATIONS.gaming },
              { value: "healthCare", label: FILTER_TRANSLATIONS.healthCare },
              { value: "music", label: FILTER_TRANSLATIONS.music },
              { value: "news", label: FILTER_TRANSLATIONS.news },
              { value: "onlineBackup", label: FILTER_TRANSLATIONS.onlineBackup },
              { value: "p2pSharing", label: FILTER_TRANSLATIONS.p2pSharing },
              { value: "photoSharing", label: FILTER_TRANSLATIONS.photoSharing },
              { value: "productivity", label: FILTER_TRANSLATIONS.productivity },
              { value: "remoteMonitoring", label: FILTER_TRANSLATIONS.remoteMonitoring },
              { value: "security", label: FILTER_TRANSLATIONS.security },
              { value: "socialWeb", label: FILTER_TRANSLATIONS.socialWeb },
              { value: "softwareVirus", label: FILTER_TRANSLATIONS.softwareVirus },
              { value: "sports", label: FILTER_TRANSLATIONS.sports },
              { value: "video", label: FILTER_TRANSLATIONS.video },
              { value: "voip", label: FILTER_TRANSLATIONS.voip },
              { value: "webFileSharing", label: FILTER_TRANSLATIONS.webFileSharing },
              { value: "webPayments", label: FILTER_TRANSLATIONS.webPayments },
            ],
          },
        }}
        initialFilters={{
          group: filters.group != null ? Array.from(filters.group) : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<
            ApplicationFilterCategory,
            ApplicationFilterOptions
          > = {};

          if (filters.group.length > 0) {
            formattedFilters.group = new Set(filters.group);
          }

          setFilters(formattedFilters);
        }}
      />
    </Screen>
  );
}
