import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Notification } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useAlertSettings, useOrgNetwork } from "@meraki/shared/api";
import { useAppForeground } from "@meraki/shared/navigation";
import { checkHasAlertNotificationPermissions } from "@meraki/shared/permissions";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { AlertSettingsCard } from "../components/AlertSettingsCard";
import { useSetupNotifications } from "../hooks/useSetupNotifications";
import { goEnabledAlerts, sortGoAlertsByType } from "../utils/AlertsUtils";

type AlertSettingsData = {
  pushNotificationEnabled: boolean;
  setPushNotificationsEnabled: (pushNotificationEnabled: boolean) => void;
};

export const useAlertSettingsData = create<AlertSettingsData>()(
  persist(
    (set) => ({
      pushNotificationEnabled: false,
      setPushNotificationsEnabled: (pushNotificationEnabled: boolean) =>
        set((_) => ({
          pushNotificationEnabled,
        })),
    }),
    {
      name: "AlertSettingsData",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export const AlertsSettingsScreen = () => {
  const route = useRoute<RouteProp<AlertsStackProps, "AlertsSettings">>();
  const { params: props } = route;
  const { networkId } = props;
  const organizationId = useCurrentOrganizationId();
  const { data: network } = useOrgNetwork({ organizationId, networkId });

  const { pushNotificationEnabled, setPushNotificationsEnabled } = useAlertSettingsData();

  const { setup: setupNotifications } = useSetupNotifications();
  const enablePushNotifications = async () => {
    await setupNotifications(true);
  };

  useAppForeground(async () => {
    const isDeviceNotificationSettingsEnabled =
      (await checkHasAlertNotificationPermissions()) === "granted";
    if (pushNotificationEnabled !== isDeviceNotificationSettingsEnabled) {
      setPushNotificationsEnabled(isDeviceNotificationSettingsEnabled);
    }
  });

  const { data: alertSettingsData, isLoading: isLoadingAlertSettings } = useAlertSettings(
    { networkId },
    {
      select: (data) => {
        const gatewayDownExists = data.alerts.filter(({ type }) => type === "gatewayDown");
        return data.alerts.filter(({ type }) => {
          return gatewayDownExists
            ? goEnabledAlerts.includes(type) && type !== "applianceDown"
            : goEnabledAlerts.includes(type);
        });
      },
    },
  );

  alertSettingsData?.sort((alertA, alertB) => sortGoAlertsByType(alertA.type, alertB.type));

  return (
    <Screen addDefaultPadding paddingTop="none">
      <Box paddingVertical="sm" bottomDividerBorder>
        <Heading size="h1">{network?.name}</Heading>
      </Box>
      <Text size="p1">{I18n.t("ALERT_SETTINGS.TEXT")}</Text>
      {!pushNotificationEnabled && (
        <Notification.Inline
          status="informational"
          message={I18n.t("ALERT_SETTINGS.BANNER.SEND_NOTIF")}
          onDismiss={() => setPushNotificationsEnabled(true)}
          onLinkPress={() => enablePushNotifications()}
          linkText={I18n.t("ALERT_SETTINGS.BANNER.ENABLE")}
        />
      )}
      {isLoadingAlertSettings && (
        <Card loading={isLoadingAlertSettings} loadingContentHeight={60} />
      )}
      {alertSettingsData?.map((alert, index) => {
        return (
          <AlertSettingsCard
            alert={alert}
            networkId={networkId}
            pushNotificationsEnabled={pushNotificationEnabled}
            key={`ALERT_${index}`}
          />
        );
      })}
    </Screen>
  );
};
