import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import { createQuery } from "../../createQuery";

export const WithNodeImagesSchema = z.object({
  id: z.string(),
  image_urls: z.object({
    large: z.string(),
    medium: z.string(),
    thumbnail: z.string(),
  }),
  node_id: z.string(),
  image_content_type: z.string(),
  image_file_name: z.string(),
  image_file_size: z.number(),
  image_md5: z.string(),
  image_metadata: z.null(),
});

export const NoNodeImageSchema = z.object({
  errors: z.array(z.string()),
  success: z.boolean(),
});

export const NodeImagesSchema = z
  .union([WithNodeImagesSchema, NoNodeImageSchema])
  .describe("NodeImagesSchema");

export const NodeImagesDeleteResponseSchema = z.union([z.undefined(), z.null(), z.object({})]);

export type NodeImages = z.infer<typeof NodeImagesSchema>;

interface NodeImagesRequest {
  nodeId?: string;
  imageId?: string;
}

interface UpdateNodeImagesRequest {
  nodeId: string;
  image: {
    content: string;
    extension: string;
  };
}

const buildUrl = ({ nodeId, imageId }: NodeImagesRequest) => {
  return `/nodes/${nodeId}/node_images${imageId ? `/${imageId}` : ""}`;
};

const fetchNodeImages = ({ nodeId }: NodeImagesRequest) => {
  return request(NodeImagesSchema, "GET", buildUrl({ nodeId }));
};

const postNodeImages = ({ nodeId, image }: UpdateNodeImagesRequest) => {
  const imagePayload = {
    uri: image.content,
    type: `image/${image.extension}`,
    name: "image",
    filename: `${nodeId}-mounting-photo.jpg`,
  };

  const body = new FormData();
  // @ts-ignore There is a typing issue here as of react-native 0.71.4 upgrade. DM-
  body.append("image", imagePayload);

  return request(NodeImagesSchema, "POST", buildUrl({ nodeId }), { body });
};

const deleteNodeImages = ({ nodeId, imageId }: NodeImagesRequest) => {
  return request(NodeImagesDeleteResponseSchema, "DELETE", buildUrl({ nodeId, imageId }));
};

export const useNodeImages = createQuery({
  baseQueryKey: buildUrl({ nodeId: `{nodeId}` }),
  queryFn: (parameters: NodeImagesRequest) => fetchNodeImages(parameters),
  requiredVariables: ["nodeId"],
});

export const useUpdateNodeImages = createMutation({
  baseMutationKey: buildUrl({ nodeId: `{nodeId}` }),
  mutationFn: (parameters: UpdateNodeImagesRequest) => postNodeImages(parameters),
});

export const useDeleteNodeImages = createMutation({
  baseMutationKey: buildUrl({ nodeId: `{nodeId}` }),
  mutationFn: (parameters: NodeImagesRequest) => deleteNodeImages(parameters),
});
