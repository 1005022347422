import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  Loader,
  Text,
} from "@meraki/magnetic/components";
import { Icon, IconName } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef } from "react";

import { useApPlacementRooms } from "../store/useApPlacementRooms";

const GOOD_SCORE_THRESHOLD = 25;
const OKAY_SCORE_THRESHOLD = 20;

type ScoreDescription = {
  description: string;
  iconName: IconName;
  iconColor: ColorToken;
  testID: string;
};

export const PlacementScoreScreen = () => {
  const route = useRoute<RouteProp<AccessPointPlacementGroupProps, "PlacementScore">>();
  const networkId = useCurrentNetworkId();
  const { room } = route.params;
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const { roomsByNetwork } = useApPlacementRooms();

  if (!networkId) {
    return (
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <Loader.Spinner animate={true} />
      </BottomSheet.Modal>
    );
  }

  const score = roomsByNetwork[networkId]?.[room] ?? OKAY_SCORE_THRESHOLD;

  const getScore = (): ScoreDescription => {
    if (score >= GOOD_SCORE_THRESHOLD) {
      return {
        description: I18n.t(`ACCESS_POINT.PLACEMENT_SCORE.GOOD`, { roomName: room }),
        iconName: "CheckCircle",
        iconColor: "positive.icon.base",
        testID: "PLACEMENT_SCORE_ICON.GOOD",
      };
    } else if (score >= OKAY_SCORE_THRESHOLD) {
      return {
        description: I18n.t(`ACCESS_POINT.PLACEMENT_SCORE.OKAY`, { roomName: room }),
        iconName: "WarningCircle",
        iconColor: "warning.icon.base",
        testID: "PLACEMENT_SCORE_ICON.OKAY",
      };
    } else {
      return {
        description: I18n.t(`ACCESS_POINT.PLACEMENT_SCORE.BAD`, { roomName: room }),
        iconName: "WarningCircle",
        iconColor: "negative.icon.base",
        testID: "PLACEMENT_SCORE_ICON.BAD",
      };
    }
  };

  const { description, iconName, iconColor, testID } = getScore();

  return (
    <Screen.View addDefaultPadding>
      <Box flex={1}>
        <Box padding="none" bottomDividerBorder>
          <Heading size="h1">
            {I18n.t("ACCESS_POINT.PLACEMENT_SCORE.TITLE", { roomName: room })}
          </Heading>
        </Box>
        <Box flex={1} alignItems="center" gap="sm" justifyContent="center">
          <Icon name={iconName} size={150} color={iconColor} testID={testID} />
          <Text size="p1" textAlign="center">
            {description}
          </Text>
        </Box>
        <Box>
          <Button
            text={I18n.t("ACCESS_POINT.NEXT_STEPS.TITLE")}
            kind="secondary"
            onPress={() => bottomSheetRef.current?.present()}
            testID="NEXT_STEPS_BUTTON"
          />
        </Box>
      </Box>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Content>
          <Box gap="sm">
            <Button
              text={I18n.t("ACCESS_POINT.NEXT_STEPS.ANOTHER_TEST")}
              analytics={{
                event: "onPress",
                eventName: "placement_score_test_another_room",
              }}
              onPress={() => {
                navigation.navigate("RoomSelect");
              }}
              kind="secondary"
              testID="NEXT_STEPS.ANOTHER_TEST_BUTTON"
            />
            <Button
              text={I18n.t("ACCESS_POINT.NEXT_STEPS.RETEST")}
              onPress={() => navigation.navigate("APTest", { room })}
              analytics={{
                event: "onPress",
                eventName: "placement_score_retest",
              }}
              kind="secondary"
              testID="NEXT_STEPS.RETEST_BUTTON"
            />
            <Button
              text={I18n.t("ACCESS_POINT.NEXT_STEPS.FINISH")}
              onPress={() => navigation.navigate("APSummary")}
              analytics={{
                event: "onPress",
                eventName: "placement_score_finish",
              }}
              kind="secondary"
              testID="NEXT_STEPS.FINISH_BUTTON"
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen.View>
  );
};
