import {
  CLEAR_SENSOR_STAT_METRIC,
  LATEST_WATER_DETECTION_STAT_FAILURE,
  LATEST_WATER_DETECTION_STAT_REQUEST,
  LATEST_WATER_DETECTION_STAT_SUCCESS,
  SENSOR_STATS_LATEST_FAILURE,
  SENSOR_STATS_LATEST_REQUEST,
  SENSOR_STATS_LATEST_SUCCESS,
} from "@meraki/shared/redux";

import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { SensorMetrics, SensorMetricsType } from "~/shared/constants/SensorMetrics";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const clearSensorStatForMetricForSerial = (metric: SensorMetricsType) => {
  return (serial: string) => ({ type: CLEAR_SENSOR_STAT_METRIC, serial, metric });
};

export const clearTemperatureStatForSensor = clearSensorStatForMetricForSerial(
  SensorMetrics.temperature,
);
export const clearHumidityStatForSensor = clearSensorStatForMetricForSerial(SensorMetrics.humidity);
export const clearWaterDetectionStatForSensor = clearSensorStatForMetricForSerial(
  SensorMetrics.waterDetection,
);

const getLatestStats =
  (
    networkId: string,
    serial: string,
    metric: string,
    actionTypes: [string, string, string],
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    const queryParams = {
      metric,
      serials: [serial],
    };

    return dispatch({
      [CALL_API]: {
        types: actionTypes,
        endpoint: `/api/v1/networks/${networkId}/sensors/stats/latestBySensor`,
        config: {
          method: Method.get,
          queryParams,
        },
        meta: { metric, serial },
      },
    });
  };

// TODO: Remove this action and use "getLatestStatForSensor" instead.
export const getLatestWaterDetectionStatForSensor =
  (networkId: string, serial: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    return dispatch(
      getLatestStats(networkId, serial, SensorMetrics.waterDetection, [
        LATEST_WATER_DETECTION_STAT_REQUEST,
        LATEST_WATER_DETECTION_STAT_SUCCESS,
        LATEST_WATER_DETECTION_STAT_FAILURE,
      ]),
    );
  };

// TODO: Remove this and use getLatestStats "instead"
export const getLatestStatForSensor =
  (
    networkId: string,
    serial: string,
    metric: SensorMetricsType,
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    return dispatch(
      getLatestStats(networkId, serial, metric, [
        SENSOR_STATS_LATEST_REQUEST,
        SENSOR_STATS_LATEST_SUCCESS,
        SENSOR_STATS_LATEST_FAILURE,
      ]),
    );
  };

export interface SensorStatCountParams {
  timespan: number;
  metric: string;
  serial: string;
}
