import { FieldValues, useFormContext as useReactHookFormContext } from "react-hook-form";

export type FormAdditionalContext = {
  disableErrorNotification: boolean;
};

export function useFormContext<
  TFieldValues extends FieldValues,
  TContext = FormAdditionalContext,
  TTransformedValues extends FieldValues | undefined = undefined,
>() {
  return useReactHookFormContext<TFieldValues, TContext, TTransformedValues>();
}
