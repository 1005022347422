/**
 * This file is deprecated in favor of live broker hooks. Please see https://gitlab.ikarem.io/dashboard-mobile/react-live-broker
 */

import { SubscriptionHandle } from "cometd";
import hoistStatics from "hoist-non-react-statics";
import { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as DashboardActions from "~/actions";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  isConnected: boolean;
};

function mapStateToProps(state: RootState): ReduxProps {
  return {
    isConnected: state.liveBroker.connected,
  };
}

type Props = ReduxProps & {
  privateActions: typeof DashboardActions;
};

type State = {
  liveData: any;
  subscription: any;
  listener: any;
};

export const withNodeSubscription = (config: any) => (WrappedComponent: any) => {
  class WithNodeSubscription extends Component<Props, State> {
    state = {
      liveData: {},
      subscription: null,
      listener: null,
    };

    UNSAFE_componentWillMount() {
      const { privateActions } = this.props;
      this.setState({
        listener: privateActions.registerHandshakeListener(this.handshakeListener),
      });
      this.subscribe();
    }

    componentWillUnmount() {
      const { listener, subscription } = this.state;
      const { privateActions } = this.props;

      if (listener) {
        privateActions.removeHandshakeListener(listener);
      }
      if (subscription) {
        privateActions.unsubscribe(subscription);
      }
    }

    handshakeListener = (message: any) => {
      const { subscription } = this.state;
      const { isConnected, privateActions } = this.props;

      if (message.successful) {
        if (!isConnected) {
          privateActions.cometConnected();
        }
        if (subscription) {
          privateActions.resubscribe(subscription);
        } else {
          this.subscribe();
        }
      }
    };

    messageHandler = (message: any) =>
      this.setState({ liveData: config.handler(message, this.props) });

    subscribe() {
      const { isConnected, privateActions } = this.props;
      if (!isConnected) {
        return;
      }
      const type = config.typeWithProps ? config.typeWithProps(this.props) : config.type;
      const subscription = privateActions.nodeSubscribe(
        type,
        this.messageHandler,
        config.deviceId(this.props),
      );
      this.setState({ subscription });
    }

    render() {
      const { liveData } = this.state;
      const { ...params } = this.props;
      return <WrappedComponent {...liveData} {...params} />;
    }
  }

  function privateMapDispatchToProps(dispatch: any) {
    return {
      privateActions: bindActionCreators(DashboardActions, dispatch),
    };
  }

  //@ts-ignore
  WithNodeSubscription.displayName = `WithNodeSubscription(${getDisplayName(WrappedComponent)})`;

  return connect(mapStateToProps, privateMapDispatchToProps)(WithNodeSubscription);
};

export function withCommandSubscription(config: any) {
  return (WrappedComponent: any) => {
    class WithCommandSubscription extends PureComponent<Props, State> {
      state = {
        liveData: {},
        subscription: null,
        listener: null,
      };

      tool = {
        commandSubscribe: () => this.subscribe(),
        commandUnsubscribe: () => this.unsubscribe(),
      };

      UNSAFE_componentWillMount() {
        const { privateActions } = this.props;
        this.setState({
          listener: privateActions.registerHandshakeListener(this.handshakeListener),
        });
      }

      componentWillUnmount() {
        const { listener } = this.state;
        const { privateActions } = this.props;

        if (listener) {
          privateActions.removeHandshakeListener(listener);
        }
        this.unsubscribe();
      }

      handshakeListener = (message: any) => {
        const { subscription } = this.state;
        const { privateActions } = this.props;

        if (message.successful) {
          if (subscription) {
            privateActions.resubscribe(subscription);
          }
        }
      };

      messageHandler = (message: any) => {
        const { subscription } = this.state;
        //Message id is set to the subscription id so that it is unique on each request made
        if (message != null) {
          message.id = (subscription as SubscriptionHandle | null)?.id;
        }
        this.setState({
          liveData: config.handler(message, this.props, this.tool),
        });
      };

      subscribe() {
        const { isConnected, privateActions } = this.props;
        const { subscription } = this.state;

        if (isConnected && !subscription) {
          const subscription = privateActions.commandSubscribe(
            config.getArgs(this.props),
            this.messageHandler,
            config.deviceId(this.props),
          );
          this.setState({ subscription });
        }
      }

      unsubscribe() {
        const { subscription } = this.state;
        const { privateActions } = this.props;

        if (subscription) {
          privateActions.unsubscribe(subscription);
        }
        this.setState({ liveData: {}, subscription: null });
      }

      render() {
        const { liveData } = this.state;

        const { ...params } = this.props;
        return <WrappedComponent tool={this.tool} {...liveData} {...params} />;
      }
    }

    function privateMapDispatchToProps(dispatch: any) {
      return {
        privateActions: bindActionCreators(DashboardActions, dispatch),
      };
    }

    //@ts-ignore
    WithCommandSubscription.displayName = `WithCommandSubscription(${getDisplayName(
      WrappedComponent,
    )})`;

    return connect(
      mapStateToProps,
      privateMapDispatchToProps,
    )(hoistStatics(WithCommandSubscription, WrappedComponent));
  };
}
export function withNodeGroupSubscription(config: any) {
  return (WrappedComponent: any) => {
    class WithNodeGroupSubscription extends Component<Props, State> {
      state = {
        liveData: {},
        subscription: null,
        listener: null,
      };

      UNSAFE_componentWillMount() {
        const { privateActions } = this.props;
        const shouldSubscribe = config.shouldSubscribe ? config.shouldSubscribe(this.props) : true;
        this.setState({
          listener: privateActions.registerHandshakeListener(this.handshakeListener),
        });
        if (shouldSubscribe) {
          this.subscribe();
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (
          config.shouldSubscribe &&
          config.shouldSubscribe(this.props) !== config.shouldSubscribe(nextProps)
        ) {
          if (config.shouldSubscribe(nextProps)) {
            this.subscribe();
          } else {
            this.unsubscribe();
          }
        }
      }

      componentWillUnmount() {
        const { listener } = this.state;
        const { privateActions } = this.props;

        if (listener) {
          privateActions.removeHandshakeListener(listener);
        }
        this.unsubscribe();
      }

      handshakeListener = (message: any) => {
        const { subscription } = this.state;
        const { privateActions } = this.props;
        const shouldSubscribe = config.shouldSubscribe ? config.shouldSubscribe(this.props) : true;

        if (message.successful && shouldSubscribe) {
          if (subscription) {
            privateActions.resubscribe(subscription);
          } else {
            this.subscribe();
          }
        }
      };

      messageHandler = (message: any) =>
        this.setState({ liveData: config.handler(message, this.props) });

      subscribe() {
        const { isConnected, privateActions } = this.props;

        if (isConnected) {
          const subscription = privateActions.nodeGroupSubscribe(
            config.type,
            this.messageHandler,
            config.nodeGroupId(this.props),
          );
          this.setState({ subscription });
        }
      }

      unsubscribe() {
        const { subscription } = this.state;
        const { privateActions } = this.props;

        if (subscription) {
          privateActions.unsubscribe(subscription);
          this.setState({ liveData: {}, subscription: null });
        }
      }

      render() {
        const { liveData } = this.state;
        const { ...params } = this.props;
        return <WrappedComponent {...liveData} {...params} />;
      }
    }

    function privateMapDispatchToProps(dispatch: any) {
      return {
        privateActions: bindActionCreators(DashboardActions, dispatch),
      };
    }

    //@ts-ignore
    WithNodeGroupSubscription.displayName = `WithNodeGroupSubscription(${getDisplayName(
      WrappedComponent,
    )})`;

    return connect(
      mapStateToProps,
      privateMapDispatchToProps,
    )(hoistStatics(WithNodeGroupSubscription, WrappedComponent));
  };
}

export function withClientSubscription(config: any) {
  return (WrappedComponent: any) => {
    class WithClientSubscription extends Component<Props, State> {
      state = {
        liveData: {},
        subscription: null,
        listener: null,
      };

      UNSAFE_componentWillMount() {
        const { privateActions } = this.props;
        const shouldSubscribe = config.shouldSubscribe ? config.shouldSubscribe(this.props) : true;
        this.setState({
          listener: privateActions.registerHandshakeListener(this.handshakeListener),
        });
        if (shouldSubscribe) {
          this.subscribe();
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (
          config.shouldSubscribe &&
          config.shouldSubscribe(this.props) !== config.shouldSubscribe(nextProps)
        ) {
          if (config.shouldSubscribe(nextProps)) {
            this.subscribe();
          } else {
            this.unsubscribe();
          }
        }
      }

      componentWillUnmount() {
        const { listener } = this.state;
        const { privateActions } = this.props;

        if (listener) {
          privateActions.removeHandshakeListener(listener);
          this.setState({ listener: null });
        }
        this.unsubscribe();
      }

      handshakeListener = (message: any) => {
        const { subscription } = this.state;
        const { privateActions } = this.props;
        const shouldSubscribe = config.shouldSubscribe ? config.shouldSubscribe(this.props) : true;

        if (message.successful && shouldSubscribe) {
          if (shouldSubscribe) {
            if (subscription) {
              privateActions.resubscribe(subscription);
            } else {
              this.subscribe();
            }
          }
        }
      };

      messageHandler = (message: any) =>
        this.setState({ liveData: config.handler(message, this.props) });

      subscribe() {
        const { isConnected, privateActions } = this.props;

        if (isConnected) {
          const nodeGroupId = config.nodeGroupId(this.props);
          const mac = config.mac(this.props);

          if (nodeGroupId && mac) {
            const subscription = privateActions.clientSubscribe(
              config.type,
              this.messageHandler,
              nodeGroupId,
              mac,
            );
            this.setState({ subscription });
          }
        }
      }

      unsubscribe() {
        const { subscription } = this.state;
        const { privateActions } = this.props;

        if (subscription) {
          privateActions.unsubscribe(subscription);
          this.setState({ liveData: {}, subscription: null });
        }
      }

      render() {
        const { liveData } = this.state;
        const { ...params } = this.props;
        return <WrappedComponent {...liveData} {...params} />;
      }
    }

    function privateMapDispatchToProps(dispatch: any) {
      return {
        privateActions: bindActionCreators(DashboardActions, dispatch),
      };
    }

    //@ts-ignore
    WithClientSubscription.displayName = `WithClientSubscription(${getDisplayName(
      WrappedComponent,
    )})`;

    return connect(mapStateToProps, privateMapDispatchToProps)(WithClientSubscription);
  };
}

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
