import {
  AuthenticationType,
  hasHardwareAsync,
  isEnrolledAsync,
  supportedAuthenticationTypesAsync,
} from "expo-local-authentication";

import { showAlert } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { convertStringToFBEvent, FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import { platformSelect } from "~/lib/PlatformUtils";

export enum BioAuthTypes {
  faceId = "face-id",
  touchId = "touch-id",
}

export enum BioAuthFailures {
  noCredentials = "No saved credentials",
  loginNotEnrolled = "Not enrolled on login",
  userCancel = "user_cancel",
  systemCancel = "system_cancel",
}

export const getBioAuthType = async () => {
  const enrolled: boolean = await isEnrolledAsync();
  const hasHardware = await hasHardwareAsync();

  if (!enrolled || !hasHardware) {
    return undefined;
  }

  const supportedTypes: AuthenticationType[] = await supportedAuthenticationTypesAsync();
  if (supportedTypes.includes(AuthenticationType.FINGERPRINT)) {
    return BioAuthTypes.touchId;
  } else if (
    supportedTypes.includes(AuthenticationType.FACIAL_RECOGNITION) ||
    supportedTypes.includes(AuthenticationType.IRIS)
  ) {
    return BioAuthTypes.faceId;
  }

  return undefined;
};

export const handleBioAuthFailure = (err: any) => {
  switch (err) {
    case BioAuthFailures.noCredentials:
      analytics.logEvent(FIREBASE_EVENTS.bioAuthCredentialsFailure);
      showAlert(
        "No Credentials Saved",
        "Please log in with your password to enable biometric authentication.",
      );
      break;
    case BioAuthFailures.loginNotEnrolled:
      analytics.logEvent(FIREBASE_EVENTS.bioAuthLoginNotEnrolled);
      showAlert(
        "No Saved Biometric Data",
        platformSelect({
          android: "Please add a fingerprint: Settings > Security > Fingerprints",
          ios: "Please add an appearance: Settings > Face ID & Passcode > Set Up Face ID",
        }),
      );
      break;
    case BioAuthFailures.systemCancel:
    case BioAuthFailures.userCancel:
      analytics.logEvent(FIREBASE_EVENTS.bioAuthCancelFailure);
      break;
    default:
      analytics.logEvent(convertStringToFBEvent(err, "bio_auth"));
      showAlert(
        "Something Went Wrong",
        "Please try logging in with your password and submit a wish if the problem persists.",
      );
      break;
  }
};
