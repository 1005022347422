import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const InventoryDeviceSchema = z.object({
  name: z.string(),
  serial: z.string(),
  mac: z.string(),
  networkId: z.string().nullable(),
  orderNumber: z.string().nullable(),
  claimedAt: z.string(),
  licenseExpirationDate: z.string().nullable(),
  tags: z.array(z.string().optional()),
  productType: z.union([z.literal("wireless"), z.literal("appliance"), z.literal("switch")]),
  details: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    }),
  ),
});

export const InventoryDevicesSchema = z
  .array(InventoryDeviceSchema)
  .describe("InventoryDeviceSchema");

export type InventoryDevice = z.infer<typeof InventoryDeviceSchema>;
export type InventoryDevices = z.infer<typeof InventoryDevicesSchema>;

interface InventoryDevicesRequest {
  organizationId?: string;
  networkIds?: string[];
  serials?: string[];
}

function buildUrl({
  organizationId,
}: {
  organizationId: InventoryDevicesRequest["organizationId"];
}) {
  return `/api/v1/organizations/${organizationId}/inventory/devices`;
}

const fetchInventoryDevices = ({ organizationId, ...queryParams }: InventoryDevicesRequest) => {
  if (!organizationId) {
    throw new Error("Undefined organization id when fetching devices");
  }

  return request(InventoryDevicesSchema, "GET", buildUrl({ organizationId }), { queryParams });
};

export const useInventoryDevices = createQuery<InventoryDevicesRequest, InventoryDevices>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: fetchInventoryDevices,
  requiredVariables: ["organizationId"],
});
