import Svg, { G, Path, SvgProps } from "react-native-svg";

import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

function CameraIcon(props: SvgProps) {
  const colors = themeColors(useTheme());
  return (
    <Svg width={56} height={40} viewBox="0 0 56 40" {...props}>
      <G
        stroke={colors.navigation.secondary}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <Path d="M4.852 7.01l.068-2.336c0-1.218 1.082-2.337 2.343-2.337h2.276c1.34 0 2.546 1.12 2.546 2.337V7.01" />
        <Path d="M43.425 2.337C42.58 1.11 41.333 0 39.81 0H27.755c-1.524 0-2.77 1.11-3.616 2.337L21.86 7.284 4.852 7.01C1.236 7.01.031 8.6.031 11.037v24.275c0 2.437 1.205 4.414 4.956 4.414h45.536c3.75 0 4.956-1.977 4.956-4.414V11.037c0-2.437-1.205-4.026-4.956-4.026h-4.687l-2.41-4.674z" />
        <Path d="M47.041 22.2c0 7.097-5.937 12.853-13.26 12.853-7.323 0-13.259-5.756-13.259-12.853 0-7.097 5.936-12.853 13.26-12.853 7.322 0 13.26 5.756 13.26 12.853h0z" />
        <Path d="M41.014 22.2c0 3.87-3.24 7.01-7.232 7.01-3.995 0-7.232-3.14-7.232-7.01 0-3.87 3.237-7.01 7.232-7.01 3.992 0 7.232 3.14 7.232 7.01h0zM12.085 15.19c0 1.934-1.62 3.505-3.617 3.505-1.998 0-3.616-1.57-3.616-3.505 0-1.935 1.618-3.506 3.616-3.506 1.997 0 3.617 1.57 3.617 3.506h0z" />
      </G>
    </Svg>
  );
}

export default CameraIcon;
