import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface AutomationsRequest {
  networkId?: string;
}

const Condition = z
  .object({
    metric: z.string(),
    button: z.object({ pressType: z.union([z.literal("short"), z.literal("long")]) }),
    serials: z.array(z.string()),
  })
  .describe("Condition");

const Webhook = z
  .object({
    id: z.string(),
    message: z.string().nullable().optional(),
  })
  .describe("Webhook");

const Target = z
  .object({
    target: z
      .union([
        z.literal("notification"),
        z.literal("camera"),
        z.literal("ssid"),
        z.literal("switchport"),
        z.literal("webhook"),
        z.literal("snapshot"),
      ])
      .nullable()
      .optional(),
    webhook: Webhook.nullable().optional(),
  })
  .describe("Target");

export const AutomationSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    icon: z.string().nullable(),
    enabled: z.boolean().nullable(),
    conditions: z.array(Condition),
    targets: z.array(Target.nullable().optional()),
  })
  .describe("AutomationSchema");

export const AutomationsSchema = z.array(AutomationSchema).describe("AutomationsSchema");

export type Automation = z.infer<typeof AutomationSchema>;
export type Automations = z.infer<typeof AutomationsSchema>;

function buildUrl({ networkId }: AutomationsRequest) {
  return `/api/v1/networks/${networkId}/sensor/automations`;
}

export const fetchAutomations = (variables: AutomationsRequest) => {
  return request(AutomationsSchema, "GET", buildUrl(variables));
};

export const useAutomations = createQuery({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (options: AutomationsRequest) => fetchAutomations(options),
  requiredVariables: ["networkId"],
});
