import {
  LOCALE_USAGE_FAILURE,
  LOCALE_USAGE_REQUEST,
  LOCALE_USAGE_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

const initialState = {
  isFetching: false,
  items: {},
};

export default function localeUsage(state = initialState, action: any) {
  switch (action.type) {
    case LOCALE_USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOCALE_USAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case LOCALE_USAGE_SUCCESS: {
      if (!action.response.usage_points) {
        return { ...state, isFetching: false };
      }
      const usage = action.response.usage_points.map((i: any) => ({
        // we get milliseconds back, convert to seconds
        timestamp: i[0] / 1000,

        download: i[1],
        total: i[2],
      }));
      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [action.meta.networkId]: usage,
        },
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
