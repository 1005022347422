import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import useFixSuggestionForNetworkEvent from "~/go/hooks/useFixSuggestionForNetworkEvent";
import { showAlert } from "~/lib/AlertUtils";
import MerakiIcon from "~/shared/components/icons";
import { useThemeColors } from "~/shared/hooks/useTheme";
import { NetworkEvent } from "~/shared/types/NetworkEvent";

interface FixEventProblemButtonProps {
  event: NetworkEvent;
  testID?: string;
}

const FixEventProblemButton = (props: FixEventProblemButtonProps) => {
  const { event, testID } = props;

  const themeColors = useThemeColors();
  const fixSuggestion = useFixSuggestionForNetworkEvent(event);

  const onPress = fixSuggestion
    ? () =>
        showAlert(
          I18n.t(`NETWORK_EVENT_SUGGESTION.${fixSuggestion.messageKey}.TITLE`),
          I18n.t(
            `NETWORK_EVENT_SUGGESTION.${fixSuggestion.messageKey}.MESSAGE`,
            fixSuggestion.messageOptions,
          ),
          fixSuggestion.action,
          { negativeText: I18n.t("CANCEL") },
        )
    : fixSuggestion;

  return (
    <View testID={testID}>
      <MerakiIcon
        name="hammer"
        size="xs"
        color={
          fixSuggestion == null ? themeColors.border.borderColor : themeColors.icon.tabBar.selected
        }
        containerStyle={styles.container}
        testID={`${testID}.ICON`}
        onPress={onPress}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: SPACING.tiny,
  },
});

export default FixEventProblemButton;
