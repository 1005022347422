import { I18n } from "@meraki/core/i18n";
import { useAppForeground } from "@meraki/shared/navigation";
import { checkHasAlertNotificationPermissions } from "@meraki/shared/permissions";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import MkiText from "~/shared/components/MkiText";
import { setupNotifications } from "~/shared/lib/NotificationUtils";

export interface RequestNotificationCardProps {
  onResult: (hasAlertPermissions: boolean) => void;
  onboarding?: boolean;
}

const RequestNotificationCard = ({ onResult, onboarding }: RequestNotificationCardProps) => {
  useAppForeground(async () => {
    // TODO: check why this stops working
    onResult((await checkHasAlertNotificationPermissions()) === "granted");
  });

  const onPress = async () => {
    try {
      await setupNotifications(true);
      onResult(true);
    } catch {
      onResult(false);
    }
  };

  return (
    <View style={styles.bodyContainer} testID="REQUEST_NOTIFICATION_CARD">
      <MkiText textStyle="heading" screenStyles={styles.header}>
        {onboarding
          ? I18n.t("ONBOARDING_FULLSTACK.NOTIFICATIONS_REQUEST.HEADER")
          : I18n.t("ONBOARDING_FULLSTACK.NOTIFICATIONS_REQUEST.CURRENTLY_DISABLED")}
      </MkiText>
      <RoundedButton
        onPress={onPress}
        screenStyles={styles.enableButton}
        testID="REQUEST_NOTIFICATION_CARD.BUTTON"
      >
        {I18n.t("ONBOARDING_FULLSTACK.NOTIFICATIONS_REQUEST.ENABLE_BUTTON")}
      </RoundedButton>
    </View>
  );
};

const styles = StyleSheet.create({
  bodyContainer: {
    margin: SPACING.default,
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    lineHeight: 35,
  },
  enableButton: {
    marginTop: SPACING.default,
  },
});

export default RequestNotificationCard;
