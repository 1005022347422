import { isEmpty } from "lodash";
import { PureComponent, ReactNode } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { platformSelect } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";

export const CAPTION_BOX_WIDTH = 114;

export type CaptionLocation = "column" | "column-reverse";

export interface CaptionBoxProps {
  children: ReactNode;
  caption: string;
  captionLocation?: CaptionLocation;
  onPress?: () => void;
  screenStyles?: StyleProp<ViewStyle>;
}

export default class CaptionBox extends PureComponent<CaptionBoxProps> {
  static defaultProps = {
    captionLocation: "top",
  };

  getFlexDirection = () => {
    const { captionLocation } = this.props;
    return {
      flexDirection: captionLocation,
    };
  };

  renderCaption = () => {
    const { caption } = this.props;
    return (
      <MkiText textStyle="label" screenStyles={styles.caption}>
        {caption.toUpperCase()}
      </MkiText>
    );
  };

  renderChildren = () => {
    const { children } = this.props;
    return <View>{children}</View>;
  };

  render() {
    const { caption, children, onPress, screenStyles } = this.props;
    if (isEmpty(caption) || isEmpty(children)) {
      return null;
    }

    return (
      <TouchableOpacity disabled={!onPress} onPress={() => onPress?.()}>
        <View
          accessibilityLabel={caption}
          style={[this.getFlexDirection(), styles.container, screenStyles]}
        >
          {this.renderCaption()}
          {this.renderChildren()}
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  // TODO: We need to establish a pattern for smaller phones
  // Many of these captionBoxes are 3-wide. On small phones,
  // 114 will be too wide and cause issues. However, less than
  // 114 and we'll have issues rendering header text in a read-
  // able manner. Work with UX to figure out a gameplan.
  container: {
    alignItems: "flex-start",
    width: CAPTION_BOX_WIDTH,
  },
  caption: {
    marginBottom: SPACING.tiny,
    marginTop: platformSelect({
      web: SPACING.large,
      mobile: SPACING.tiny,
    }),
  },
});
