import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { Features } from "~/go/types/ContextHelpTypes";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { IP_ASSIGNMENT } from "~/lib/SSIDUtils";
import { currentNetworkState, onlineDevicesSelector, ssidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import { CloseButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import Device from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const getIpAssignmentMode = (obj: unknown) =>
  nestedValueExists(obj, ["ssid", "ipAssignmentMode"], null);

type ReduxProps = {
  ssid: SSID;
  onlineDevices: Device[];
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "NATBridge"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

interface NatBridgeScreenState {
  ipAssignmentMode?: string;
  c2cEnabled?: boolean;
}

export class NATBridge extends PureComponent<Props, NatBridgeScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ipAssignmentMode: getIpAssignmentMode(props),
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, ssidName } = this.props;

    navigation.setOptions({
      headerTitle: ssidName,
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  willAppear() {
    this.getData();
  }

  radioOptions = () => {
    const { ssid } = this.props;
    const { isGuestNetwork } = ssid;

    const options: {
      label: string;
      sublabel: string;
      value: string;
      testID: string;
      disabled?: boolean;
      context?: string;
    }[] = [];

    if (isGuestNetwork) {
      options.push({
        label: I18n.t("NAT_BRIDGE.BRIDGE.TITLE"),
        sublabel: I18n.t("NAT_BRIDGE.BRIDGE.GUEST.SUBTITLE"),
        testID: "NAT_BRIDGE.C2C_DISABLED",
        value: IP_ASSIGNMENT.BRIDGE,
        disabled: true,
        context: Features.bridgeVsNATMode,
      });
      options.push({
        label: I18n.t("NAT_BRIDGE.NAT.TITLE"),
        sublabel: I18n.t("NAT_BRIDGE.NAT.GUEST.SUBTITLE"),
        value: IP_ASSIGNMENT.NAT,
        testID: "NAT_BRIDGE.NAT",
      });
    } else {
      options.push({
        label: I18n.t("NAT_BRIDGE.BRIDGE.TITLE"),
        sublabel: I18n.t("NAT_BRIDGE.BRIDGE.SUBTITLE"),
        testID: "NAT_BRIDGE.C2C",
        value: IP_ASSIGNMENT.BRIDGE,
        context: Features.bridgeVsNATMode,
      });
      options.push({
        label: I18n.t("NAT_BRIDGE.NAT.TITLE"),
        sublabel: I18n.t("NAT_BRIDGE.NAT.SUBTITLE"),
        value: IP_ASSIGNMENT.NAT,
        testID: "NAT_BRIDGE.NAT",
      });
    }
    return options;
  };

  getData() {
    const { networkId, ssid, onlineDevices, actions } = this.props;
    const reqs: Promise<unknown>[] = [];
    if (!ssid || ssid.ipAssignmentMode === undefined) {
      reqs.push(actions.getSsids(networkId));
    }
    if (!onlineDevices || onlineDevices.length === 0) {
      reqs.push(actions.loadNodesAndStatuses(networkId));
    }
    if (reqs.length > 0) {
      this.handleRequests(reqs);
    }
  }

  handleRequests(reqs: Promise<unknown>[]) {
    const { setReqPending } = this.props;
    const reqDone = () => setReqPending(false);
    const handleError = (err: unknown) => {
      setReqPending(false);
      showAlert(I18n.t("ERROR"), err || I18n.t("SERVER_ERROR_TEXT"));
    };
    setReqPending(true);
    return Promise.all(reqs).then(reqDone, handleError);
  }

  onSave = (savedData: { c2cEnabled?: boolean; ipAssignmentMode?: string }) => {
    const { actions, networkId, ssid, cancelablePromise, setReqPending } = this.props;
    setReqPending(true);

    return cancelablePromise(
      actions.setSsid(networkId, {
        number: ssid.number,
        ...savedData,
      }),
    )
      .then(() => {
        this.setState(savedData);
        setReqPending(false);
        actions.setWarnInvalidBridgeMode(true);
      })
      .catch(({ isCanceled }) => {
        if (isCanceled) {
          return;
        }

        setReqPending(false);
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
      });
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("NAT_BRIDGE.TITLE")}
        description={I18n.t("NAT_BRIDGE.SUBTITLE")}
      />
    );
  };

  renderD2DCommunication = () => {
    const { ipAssignmentMode } = this.state;
    const { ssid } = this.props;
    const { isGuestNetwork } = ssid;
    const isNat = ipAssignmentMode === IP_ASSIGNMENT.NAT;
    const c2cEnabled = ssid?.c2cEnabled;
    const switchDisabled = isGuestNetwork || !isNat;

    return (
      <SwitchRow
        value={c2cEnabled}
        onValueChange={(c2cEnabled) => this.onSave({ c2cEnabled })}
        subtitle={I18n.t("CLIENT_ISOLATION.DESCRIPTION")}
        disabled={switchDisabled}
        context={Features.natIsolation}
        testID={`C2C.${switchDisabled ? "DISABLED" : "SWITCH"}`}
      >
        {I18n.t("CLIENT_ISOLATION.SWITCH")}
      </SwitchRow>
    );
  };

  render() {
    const { ipAssignmentMode } = this.state;

    return (
      <FullScreenContainerView withScroll screenStyles={styles.container}>
        {this.renderHeader()}
        <RadioSelectionList
          options={this.radioOptions()}
          onSelect={(ipAssignmentMode) => this.onSave({ ipAssignmentMode })}
          selectedValue={ipAssignmentMode}
          radioStyle={styles.radioSpacing}
          testID={"NAT_MODE"}
        />
        {this.renderD2DCommunication()}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    margin: SPACING.default,
  },
  radioSpacing: {
    marginVertical: SPACING.default,
  },
});

function mapStateToProps(state: RootState, props: NetworkScreensPropMap["NATBridge"]): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for NATBridgeScreen",
      currentNetworkState(state),
    ),
    ssid: ssidsSelector(state)[props.ssidNumber],
    onlineDevices: onlineDevicesSelector(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withPendingComponent,
)(NATBridge);
