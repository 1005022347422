import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";
import {
  AddLicenseError,
  AddLicenseParams,
  AssignLicenseRequest,
  AssignLicenseResponse,
  Licenses,
  LicensesSchema,
} from "../../licenses/useLicenses";

function mutateAssignGoLicense({
  licenseId,
  deviceSerial,
}: AssignLicenseRequest): Promise<AssignLicenseResponse> {
  if (deviceSerial === null) {
    throw new Error("No security gateway device found");
  }
  return request(AssignLicenseResponse, "POST", "/go/products/assign", {
    body: JSON.stringify({
      node_id: deviceSerial,
      license_id: licenseId,
    }),
  });
}
function mutateAddGoLicense({ key, GXDeviceSerial }: AddLicenseParams): Promise<Licenses> {
  return request(LicensesSchema, "POST", "/go/products/add_subscription", {
    body: JSON.stringify({
      key,
      node_id: GXDeviceSerial,
    }),
  });
}

export const useAddGoLicense = createMutation<AddLicenseParams, Licenses, AddLicenseError>({
  baseMutationKey: "/go/products/add_subscription",
  mutationFn: (params: AddLicenseParams) => mutateAddGoLicense(params),
});

export const useAssignGoLicense = createMutation<
  AssignLicenseRequest,
  AssignLicenseResponse,
  AddLicenseError
>({
  baseMutationKey: "/go/products/assign",
  mutationFn: (params: AssignLicenseRequest) => mutateAssignGoLicense(params),
});
