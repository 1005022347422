import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";

type Props = {
  children: string;
  altDefaultLabel?: string;
  rowIsSelected?: boolean;
  rowIsDisabled?: boolean;
};

const DefaultItemLabel = (props: Props) => {
  const { rowIsSelected, rowIsDisabled, altDefaultLabel, children } = props;
  let itemStyle = null;
  if (rowIsSelected) {
    itemStyle = styles.selectedTextColor;
  } else if (rowIsDisabled) {
    itemStyle = styles.disabledTextColor;
  }

  return (
    <MkiText>
      <MkiText screenStyles={itemStyle}>{children}</MkiText>
      <MkiText screenStyles={styles.defaultLabel}>
        {I18n.t("DEFAULT_ITEM_LABEL", { label: altDefaultLabel })}
      </MkiText>
    </MkiText>
  );
};
DefaultItemLabel.defaultProps = {
  rowIsSelected: false,
  rowIsDisabled: false,
  altDefaultLabel: I18n.t("DEFAULT"),
};

const styles = StyleSheet.create({
  selectedTextColor: {
    color: MkiColors.goPurple,
  },
  disabledTextColor: {
    color: MkiColors.secondaryTextColor,
  },
  defaultLabel: {
    color: MkiColors.secondaryTextColor,
  },
});

export default DefaultItemLabel;
