import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { NetworkScreensPropMap } from "~/go/navigation/Types";

import { NetworkScreens } from "./NetworkScreens";
import { useDefaultScreenOptions } from "./Options";

const Stack = createNativeStackNavigator<NetworkScreensPropMap>();

const NetworksStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>{NetworkScreens(Stack)}</Stack.Navigator>
  );
};

export default NetworksStack;
