import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Insight({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path d="M3 14.5a1.5 1.5 0 0 1 3 0v5a1.5 1.5 0 0 1-3 0v-5Z" fill={color1} />
      <Path d="M13 12.5a1.5 1.5 0 0 1 3 0v7a1.5 1.5 0 0 1-3 0v-7Z" fill={color2} />
      <Path
        d="M8 9.5a1.5 1.5 0 1 1 3 0v10a1.5 1.5 0 0 1-3 0v-10ZM18 7.5a1.5 1.5 0 0 1 3 0v12a1.5 1.5 0 0 1-3 0v-12Z"
        fill={color3}
      />
    </Svg>
  );
}
