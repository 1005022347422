import { Network } from "./icons/Network";
import { Organization } from "./icons/Organization";

const ICON_MAP = {
  network: Network,
  organization: Organization,
};

export interface BorderedProductIconProps {
  name: keyof typeof ICON_MAP;
  color?: boolean;
  size?: number;
}

export function BorderedProductIcon({ name, size, color }: BorderedProductIconProps) {
  const Icon = ICON_MAP[name];

  if (!Icon) return null;

  return <Icon width={size ?? 32} height={size ?? 32} color={color} />;
}
