import { AlertSettingsType, AlertSettingsTypes } from "@meraki/shared/api";

export const THRESHOLD_INDEX_STEPS = 1;
export const USAGE_ALERT_THRESHOLD_VALUES = [
  {
    kb: 1048576,
    gb: 1,
  },
  {
    kb: 10485760,
    gb: 10,
  },
  {
    kb: 20971520,
    gb: 20,
  },
  {
    kb: 31457280,
    gb: 30,
  },
  {
    kb: 41943040,
    gb: 40,
  },
  {
    kb: 52428800,
    gb: 50,
  },
  {
    kb: 62914560,
    gb: 60,
  },
  {
    kb: 73400320,
    gb: 70,
  },
  {
    kb: 83886080,
    gb: 80,
  },
  {
    kb: 94371840,
    gb: 90,
  },
  {
    kb: 104857600,
    gb: 100,
  },
];

export const MAX_THRESHOLD_INDEX = USAGE_ALERT_THRESHOLD_VALUES.length - 1;

export const HardwareDownTypes = [
  AlertSettingsTypes.gatewayDown,
  AlertSettingsTypes.repeaterDown,
  AlertSettingsTypes.applianceDown,
  AlertSettingsTypes.switchDown,
];

export function findSliderIndexForKB(usageThresholdKb: number | undefined) {
  if (usageThresholdKb === undefined) return 0;
  const val = USAGE_ALERT_THRESHOLD_VALUES.findIndex(
    (thresholdValue) => thresholdValue.kb === usageThresholdKb,
  );
  return val === -1 ? 0 : val;
}

export function findSliderIndexForGB(usageThresholdGb: number | undefined) {
  if (usageThresholdGb === undefined) return 0;
  const val = USAGE_ALERT_THRESHOLD_VALUES.findIndex(
    (thresholdValue) => thresholdValue.gb === usageThresholdGb,
  );
  return val === -1 ? 0 : val;
}

export function getKBForSliderIndex(sliderIndex: number | undefined) {
  return sliderIndex !== undefined ? USAGE_ALERT_THRESHOLD_VALUES[sliderIndex]?.kb : 0;
}

export function getGBForSliderIndex(sliderIndex: number) {
  return USAGE_ALERT_THRESHOLD_VALUES[sliderIndex]?.gb;
}

export const goEnabledAlerts: AlertSettingsType[] = [
  "gatewayDown",
  "applianceDown",
  "clientConnectivity",
  "usageAlert",
  "weeklyPresence",
  "weeklyUmbrella",
];

export const sortGoAlertsByType = (typeA: AlertSettingsType, typeB: AlertSettingsType) => {
  const idxA = goEnabledAlerts.indexOf(typeA);
  const idxB = goEnabledAlerts.indexOf(typeB);
  return idxA - idxB;
};
