import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, List, RefreshControl, Text, Toggle } from "@meraki/magnetic/components";
import { Icon, Status } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  Ssid,
  useApplianceL7FirewallRules,
  useApplianceTrafficShapingRules,
  usePoliciesByClient,
  useSsidL7FirewallRules,
  useSsids,
  useSSIDSchedules,
  useSsidTrafficShapingRules,
  useUpdateSsid,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useState } from "react";

import { useSSIDContext } from "../SSIDContext";

export const PreferencesTab = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { ssid, isLoading } = useSSIDContext();
  const [displayMore, setDisplayMore] = useState<boolean>(false);

  const {
    data: applianceTrafficShapingRules,
    refetch: refetchApplianceTrafficShapingRules,
    isRefetching: applianceTrafficShapingRulesIsRefetching,
  } = useApplianceTrafficShapingRules({ networkId });
  const {
    data: blockedWebsites,
    refetch: refetchBlockedWebsites,
    isRefetching: blockedWebsitesIsRefetching,
  } = useApplianceL7FirewallRules({ networkId });
  const { data: blockedWebsitesForSSID } = useSsidL7FirewallRules({
    networkId,
    ssidNumber: ssid?.number,
  });

  const numberOfBlockWebsites =
    (blockedWebsites?.rules.length ?? 0) + (blockedWebsitesForSSID?.rules.length ?? 0);
  const { data: clientPolicies } = usePoliciesByClient(
    { networkId, timespan },
    {
      select: (policies) =>
        policies.filter((policy) =>
          policy.assigned.some(
            (assignment) => assignment.type === "ssid" && assignment.ssid.number === ssid?.number,
          ),
        ),
    },
  );
  const {
    data: schedules,
    refetch: refetchSchedules,
    isRefetching: SSIDSchedulesIsRefetching,
  } = useSSIDSchedules({ networkId, ssidNumber: ssid?.number });
  const {
    data: trafficShaping,
    refetch: refetchTrafficShapingRules,
    isRefetching: trafficShapingRulesIsRefetching,
  } = useSsidTrafficShapingRules({
    networkId,
    ssidNumber: ssid?.number,
  });

  const updateSsid = useUpdateSsid();

  const updatePreferences = useCallback(
    (body: Partial<Ssid>) => {
      updateSsid.mutate(
        { networkId, ssidNumber: ssid?.number, body },
        {
          onSuccess: () => queryClient.refetchQueries({ queryKey: useSsids.queryKeyRoot }),
        },
      );
    },
    [networkId, ssid?.number, updateSsid],
  );

  const numberOfRules = (trafficShaping?.rules ?? []).length;
  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={
            SSIDSchedulesIsRefetching ||
            blockedWebsitesIsRefetching ||
            trafficShapingRulesIsRefetching ||
            applianceTrafficShapingRulesIsRefetching
          }
          onRefresh={() => {
            refetchApplianceTrafficShapingRules();
            refetchBlockedWebsites();
            refetchSchedules();
            refetchTrafficShapingRules();
          }}
        />
      }
      addDefaultPadding
    >
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.ENABLE.TITLE")}
          rightAccessory={
            <Toggle
              disabled={isLoading || updateSsid.isLoading}
              checked={ssid?.enabled ?? false}
              onChange={() => updatePreferences({ enabled: !ssid?.enabled })}
            />
          }
          leftAccessory={<Status size={20} status={ssid?.enabled ? "positive" : "disabled"} />}
        />
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.GUEST_MODE.TITLE")}
          leftAccessory={<Icon name="Gear" />}
          rightAccessory={
            <Toggle
              disabled={isLoading || updateSsid.isLoading}
              checked={ssid?.isGuestNetwork ?? false}
              onChange={() => updatePreferences({ isGuestNetwork: !ssid?.isGuestNetwork })}
              testID={"GUEST_MODE_TOGGLE"}
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.PASSWORD.TITLE")}
          leftAccessory={<Icon name="Key" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.authMode}
            </Text>
          }
          onPress={() => navigation.navigate("PasswordSettings", { ssidNumber: ssid?.number })}
          testID={"SSID_PASSWORD"}
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.TRAFFIC_SHAPING.TITLE")}
          leftAccessory={<Icon name="ArrowsLeftRight" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {applianceTrafficShapingRules?.defaultRulesEnabled
                ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.TRAFFIC_SHAPING.DEFAULT")
                : numberOfRules > 1
                  ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.TRAFFIC_SHAPING.RULES", { numberOfRules })
                  : I18n.t("SSID_SCREEN.TABS.PREFERENCES.TRAFFIC_SHAPING.RULE", { numberOfRules })}
            </Text>
          }
          onPress={() =>
            navigation.navigate("TrafficShapingRulesList", {
              applianceOrSSID: "ssid",
              ssidNumber: ssid?.number,
            })
          }
          testID={"SSID_TRAFFIC_SHAPING"}
        />
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.TITLE")}
          leftAccessory={<Icon name="Prohibit" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {numberOfBlockWebsites > 1
                ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.RULES", {
                    numberOfRules: numberOfBlockWebsites,
                  })
                : I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.RULE", {
                    numberOfRules: numberOfBlockWebsites,
                  })}
            </Text>
          }
          onPress={() => navigation.navigate("WebBlocking", { ssid: ssid })}
          testID={"SSID_WEB_BLOCKING"}
        />
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.CLIENT_POLICY.TITLE")}
          leftAccessory={<Icon name="EyeSlash" />}
          rightAccessory={
            !clientPolicies || clientPolicies.length === 0 ? (
              <Text size="p1" color="interact.text.base">
                {I18n.t("NONE")}
              </Text>
            ) : (
              <Text size="p1" color="interact.text.base">
                {clientPolicies.length > 1
                  ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.CLIENT_POLICY.POLICIES", {
                      numberOfPolicies: clientPolicies.length,
                    })
                  : I18n.t("SSID_SCREEN.TABS.PREFERENCES.CLIENT_POLICY.POLICY", {
                      numberOfPolicies: clientPolicies.length,
                    })}
              </Text>
            )
          }
          onPress={() => navigation.navigate("LimitedBlockedClients", { ssidNumber: ssid?.number })}
          testID="SSID_CLIENT_POLICY"
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.LANDING_PAGE.TITLE")}
          leftAccessory={<Icon name="Cards" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {ssid?.splashPage}
            </Text>
          }
          onPress={() => navigation.navigate("LandingPage", { ssidNumber: ssid?.number })}
        />
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.SCHEDULES.TITLE")}
          leftAccessory={<Icon name="Clock" />}
          rightAccessory={
            <Text size="p1" color="interact.text.base">
              {schedules?.enabled
                ? I18n.t("SSID_SCREEN.TABS.PREFERENCES.SCHEDULES.ENABLED")
                : I18n.t("SSID_SCREEN.TABS.PREFERENCES.SCHEDULES.DISABLED")}
            </Text>
          }
          onPress={() => navigation.navigate("Schedules", { ssidNumber: ssid?.number })}
          testID="SSID_SCHEDULES"
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("SSID_SCREEN.TABS.PREFERENCES.DISCOVERABLE.TITLE")}
          description={I18n.t("SSID_SCREEN.TABS.PREFERENCES.DISCOVERABLE.DESCRIPTION")}
          leftAccessory={<Icon name="Bell" />}
          rightAccessory={
            <Toggle
              disabled={isLoading || updateSsid.isLoading}
              checked={ssid?.visible ?? false}
              onChange={() => updatePreferences({ visible: !ssid?.visible })}
            />
          }
        />
        <List.Item
          title={""}
          onPress={() => setDisplayMore(!displayMore)}
          hidePressable
          leftAccessory={
            <Button text={I18n.t("LEARN_MORE")} kind="tertiary" testID="LEARN_MORE_BUTTON" />
          }
          rightAccessory={<Icon name={displayMore ? "CaretUp" : "CaretDown"} />}
          children={displayMore ? <Text size="p2">{""}</Text> : null}
        />
      </List>
    </Screen>
  );
};
