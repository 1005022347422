import { useIpSecVPN, useSiteToSiteVPNSettings } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import LoadingSpinner from "~/shared/components/LoadingSpinner";

import { SettingsStackProps } from "../navigation/Types";
import SiteToSiteVPNSettingsScreen from "./SiteToSiteVPNSettingsScreen";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SiteToSiteVPNSettings">;

const SiteToSiteVPNSettingsScreenWrapper = ({
  handleError,
  setReqPending,
  reqPending,
}: PendingComponent) => {
  const navigation = useNavigation<Props["navigation"]>();
  const route = useRoute<Props["route"]>();

  const networkId = useCurrentNetworkId();
  const { data: ipSecSettings, isFetching: ipSecFetching } = useIpSecVPN({ networkId });
  const { data: siteVPNSettings, isFetching: originalFetching } = useSiteToSiteVPNSettings({
    networkId,
  });

  const props = {
    originalSettings: siteVPNSettings,
    ipSecSettings,
    navigation,
    route,
  } as Props;

  if (ipSecFetching || originalFetching) {
    return <LoadingSpinner visible={true} />;
  }
  return (
    <SiteToSiteVPNSettingsScreen
      {...props}
      handleError={handleError}
      setReqPending={setReqPending}
      reqPending={reqPending}
    />
  );
};

export default withPendingComponent(SiteToSiteVPNSettingsScreenWrapper);
