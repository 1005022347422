import { useRuntimeSchemaValidation } from "@meraki/shared/api";
import { useWatchedBoolFlag } from "@meraki/shared/feature-flags";
import { useEffect } from "react";

export function useRuntimeSchemaValidationWatcher() {
  const { value: zodSchemaValidationEnabled } = useWatchedBoolFlag("ld", "zod-schema-parsing");
  const { setFlag } = useRuntimeSchemaValidation();

  // Yes, this is just syncing data from this feature flag into a zustand store.
  // The reason for that is we can't access the feature flags from the shared api library.
  useEffect(() => {
    setFlag(zodSchemaValidationEnabled);
  }, [zodSchemaValidationEnabled, setFlag]);
}
