import { useMagneticTheme } from "@meraki/magnetic/themes";
import { SafeAreaView as RNSafeAreaView, SafeAreaViewProps } from "react-native-safe-area-context";

import { SpacingStyle } from "../Box/Box";
import { getScreenStyles } from "./styles";

export type ScreenProps = Omit<SafeAreaViewProps, "style"> &
  SpacingStyle & {
    addDefaultPadding?: boolean;
  };

export function SafeAreaView({ children, addDefaultPadding, ...rest }: ScreenProps) {
  const theme = useMagneticTheme();

  const { style: spacingStyle, ...spacingRest } = getScreenStyles(rest, addDefaultPadding, theme);

  return (
    <RNSafeAreaView
      {...spacingRest}
      style={[{ flex: 1, backgroundColor: theme.color.default.bg.base }, spacingStyle]}
    >
      {children}
    </RNSafeAreaView>
  );
}
