import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ organizationId }: OrganizationAlertProfilesBuildUrlRequest) => {
  return `/api/v1/organizations/${organizationId}/alerts/profiles`;
};

interface OrganizationAlertProfilesRequest {
  organizationId?: string;
}
interface OrganizationAlertProfilesBuildUrlRequest {
  organizationId: string;
}

export const OrganizationAlertProfileSchema = z.object({
  id: z.string(),
  type: z.string(),
  enabled: z.boolean(),
  alertCondition: z.object({
    duration: z.number(),
    window: z.number(),
    bit_rate_bps: z.number(),
    interface: z.string(),
  }),
  recipients: z.object({
    emails: z.array(z.string()),
    httpServerIds: z.array(z.string()),
  }),
  networkTags: z.array(z.string()),
  description: z.string(),
});

export const OrganizationAlertProfilesSchema = z.array(OrganizationAlertProfileSchema);

export type OrganizationAlertProfile = z.infer<typeof OrganizationAlertProfileSchema>;
export type OrganizationAlertProfiles = z.infer<typeof OrganizationAlertProfilesSchema>;

const fetchOrganizationAlertProfiles = ({ organizationId }: OrganizationAlertProfilesRequest) => {
  if (!organizationId) {
    throw Error("OrganizationId is not defined");
  }
  return request(OrganizationAlertProfilesSchema, "GET", buildUrl({ organizationId }));
};

export const useOrganizationAlertProfiles = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  requiredVariables: ["organizationId"],
  queryFn: (parameters: OrganizationAlertProfilesRequest) =>
    fetchOrganizationAlertProfiles(parameters),
});
