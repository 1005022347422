import {
  SENSOR_ALERT_PROFILES_FAILURE,
  SENSOR_ALERT_PROFILES_REQUEST,
  SENSOR_ALERT_PROFILES_SUCCESS,
  TEST_SENSOR_ALERT_FAILURE,
  TEST_SENSOR_ALERT_REQUEST,
  TEST_SENSOR_ALERT_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, encryptedNetworkIdSelector } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const getSensorAlertProfiles =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [
          SENSOR_ALERT_PROFILES_REQUEST,
          SENSOR_ALERT_PROFILES_SUCCESS,
          SENSOR_ALERT_PROFILES_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/sensors/alerts/profiles`,
        config: { method: Method.get },
        meta: { networkId },
      },
    });
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const testSensorAlert =
  (alertConfigId: string): AppThunk =>
  (dispatch, getState) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [TEST_SENSOR_ALERT_REQUEST, TEST_SENSOR_ALERT_SUCCESS, TEST_SENSOR_ALERT_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/sensor_alert_test/deliver`,
        config: {
          method: Method.post,
          queryParams: {
            alert_config_id: alertConfigId,
          },
        },
      }),
    );
  };
