import { I18n } from "@meraki/core/i18n";
import { TRAFFIC_SHAPING_MEBIBITS_INTEGERED } from "@meraki/go/traffic-shaping";
import { List } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useGroupPolicies } from "@meraki/shared/api";
import { formatTransferBitsPerSecond } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";

import { useClientDetails } from "../ClientDetailsContext";

export const SettingsTab = () => {
  const { client, openBlockBottomSheet, openLimitBottomSheet, clientPolicies } = useClientDetails();
  const networkId = useCurrentNetworkId();

  const blockedWirelessPolicies = clientPolicies?.assigned.filter(
    (policyObj) => policyObj.type === "ssid" && policyObj.name === "Blocked",
  );

  const limitedSsids = clientPolicies?.assigned.filter((assignedPolicy) => {
    return assignedPolicy.type === "ssid" && assignedPolicy.groupPolicyId;
  });

  const { data: groupPolicy } = useGroupPolicies(
    { networkId },
    {
      select(data) {
        return data.find((policy) => {
          return limitedSsids?.find((ssid) => {
            return (
              ssid.type === "ssid" &&
              ssid.groupPolicyId &&
              ssid.groupPolicyId === policy.groupPolicyId
            );
          });
        });
      },
    },
  );

  const currentLimit =
    TRAFFIC_SHAPING_MEBIBITS_INTEGERED[groupPolicy?.bandwidth?.bandwidthLimits.limitDown ?? 0];

  const getCurrentLimitLabel = () => {
    if (currentLimit) {
      const parsedLimit = currentLimit;
      if (parsedLimit) {
        return formatTransferBitsPerSecond(currentLimit);
      }
    }

    return I18n.t("THROTTLE_CLIENT.NO_LIMIT");
  };

  if (!client) {
    return null;
  }

  return (
    <Screen addDefaultPadding testID="SETTINGS_TAB">
      <Box paddingBottom="sm" gap="sm">
        <List>
          <List.Item
            title={I18n.t("THROTTLE_CLIENT.TITLE")}
            leftAccessory={<Icon name={"Lock"} />}
            value={getCurrentLimitLabel()}
            onPress={openLimitBottomSheet}
            testID="LIMIT_CLIENT_ROW"
          />
          <List.Item
            title={I18n.t("BLOCK_CLIENT.GOv3.HEADER")}
            leftAccessory={<Icon name={"Lock"} />}
            value={I18n.t("BLOCK_CLIENT.GOv3.BLOCK_COUNT", {
              blockedSsids: blockedWirelessPolicies?.length ?? 0,
            })}
            onPress={openBlockBottomSheet}
            testID="BLOCK_CLIENT_ROW"
          />
        </List>
      </Box>
    </Screen>
  );
};
