import { StyleSheet, Switch, SwitchProps, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { appSelect, isWeb, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

export type MkiSwitchProps = {
  testID?: string;
  context?: string;
} & Pick<SwitchProps, "onValueChange" | "disabled" | "value">;

const MkiSwitch = ({ value, disabled = false, onValueChange, testID, context }: MkiSwitchProps) => {
  const navColors = themeColors(useTheme()).navigation;
  /* Check which words we want to use to set up the Android colors */
  const valueWord = value ? "Active" : "Inactive";
  const disabledWord = disabled ? "Disabled" : "";

  /* This sets the background of the switch when it's active */
  const trackColor = appSelect({
    enterprise: {
      true: navColors.primary,
      false: navColors.backgroundSecondary,
    },
    go: {
      true: platformSelect({
        ios: MkiColors.iosSwitchBackgroundActive,
        android: MkiColors[`androidSwitchBackgroundActive${disabledWord}`],
        web: MkiColors[`androidSwitchBackgroundActive${disabledWord}`],
      }),
      false: platformSelect({
        ios: "",
        android: MkiColors[`androidSwitchBackgroundInactive${disabledWord}`],
        web: MkiColors[`androidSwitchBackgroundInactive${disabledWord}`],
      }),
    },
  });

  /* This sets the color of the toggle on Android */
  const thumbColor = platformSelect({
    ios: undefined,
    android: MkiColors[`androidSwitchToggle${valueWord}${disabledWord}`],
    web: MkiColors[`androidSwitchToggle${valueWord}${disabledWord}`],
  });

  return (
    <View style={styles.horizontalContainer}>
      {context && <ContextHelp context={context} />}
      <Switch
        disabled={disabled}
        trackColor={trackColor}
        onValueChange={onValueChange}
        thumbColor={thumbColor}
        value={value}
        testID={testID}
        style={context && isWeb() ? styles.contextMargin : undefined}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contextMargin: {
    marginLeft: SPACING.small,
  },
});

export default MkiSwitch;
