export enum ClientIconType {
  android = "android-logo",
  chrome = "chrome",
  port = "wired",
  vpn = "vpn",
  windows = "windows-logo",
  wireless = "wireless",
  phone = "phone",
  linux = "linux",
  apple = "apple-logo",
  google = "google",
  sm = "system-manager",
}

export enum ClientOS {
  android = "android",
  chrome = "chrome",
  ios = "ios",
  ipad = "ipados",
  mac = "mac",
  osx = "os x",
  port = "port",
  vpn = "vpn",
  windows = "windows",
  wireless = "wireless",
  voip = "voip",
  linux = "linux",
  apple = "apple",
  google = "google",
}
