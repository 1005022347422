import { MkiconfState } from "./mkiconf";
import { NavigationState } from "./navigation";
import { PreferencesState } from "./preferences";

export interface RootState {
  mkiconf: MkiconfState;
  navigation: NavigationState;
  preferences: PreferencesState;
}

export * from "./mkiconf";
export * from "./navigation";
export * from "./preferences";
