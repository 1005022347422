import { GET_NODE_GROUPS_SUCCESS, NODE_GROUP_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

import { normalizeObjectByKey } from "~/lib/objectHelper";
import { NodeGroupsState } from "~/shared/types/NodeGroups";

const initialState: NodeGroupsState = {};

export default function nodeGroups(state = initialState, action: any) {
  const { meta, type, response } = action;
  switch (type) {
    case NODE_GROUP_SUCCESS: {
      const { nodeGroupId } = meta;

      return {
        ...state,
        [nodeGroupId]: response,
      };
    }
    case GET_NODE_GROUPS_SUCCESS: {
      return {
        ...state,
        ...normalizeObjectByKey(response, "id"),
      };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
