import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import { clientName } from "~/lib/ClientUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { filteredClients, timespanNameSelector } from "~/selectors";
import TopItemsList from "~/shared/components/TopItemsList";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import ClientListRow, { CLIENT_ROW_SUBTITLE_FORMATS } from "~/shared/rows/ClientListRow";
import { ExtendedClient } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  clients?: ExtendedClient[];
  timespanName?: string;
};

type TopClientsListProps = {
  navigate: (screen: string, params: Record<string, any>) => void;
  filter?: () => void;
  hideUsage?: boolean;
  ignoreFilter?: boolean;
  maxClientsToDisplay?: number;
  prioritizeOnlineClients?: boolean;
  renderAllClientsButton?: boolean;
  renderTimespan?: boolean;
  showOnline?: boolean;
  ssidNumber?: number;
  testID?: string;
};

type Props = ReduxProps & WithConnectedClientsProps & TopClientsListProps;

class TopClientsList extends PureComponent<Props> {
  static defaultProps = {
    clients: {},
    filter: CUSTOM_FILTERS.DEFAULT,
    hideUsage: false,
    ignoreFilter: false,
    maxClientsToDisplay: undefined,
    prioritizeOnlineClients: false,
    navigate: undefined,
    renderAllClientsButton: false,
    renderTimespan: false,
  };

  constructor(props: Props) {
    super(props);
    this.pushFilteredClientsList = this.pushFilteredClientsList.bind(this);
  }

  pushFilteredClientsList() {
    const { filter, navigate, showOnline, ssidNumber } = this.props;

    navigate("ClientList", {
      title: capitalizeFirstLetter(
        I18n.t("CLIENTS_LIST.TITLE", { client_word: I18n.t("DEVICES_WORD") }),
      ),
      customFilter: filter,
      showOnline,
      ssidNumber,
    });
  }

  subtitleFormat() {
    const { hideUsage, renderTimespan } = this.props;

    if (hideUsage) {
      return CLIENT_ROW_SUBTITLE_FORMATS.hideUsage;
    }
    if (renderTimespan) {
      return CLIENT_ROW_SUBTITLE_FORMATS.usageWithTimespan;
    }

    return CLIENT_ROW_SUBTITLE_FORMATS.usage;
  }

  render() {
    const {
      clients,
      isClientConnected,
      maxClientsToDisplay,
      prioritizeOnlineClients,
      navigate,
      renderAllClientsButton,
      showOnline,
      testID,
    } = this.props;
    if (clients.length === 0) {
      return null;
    }

    const buildClientCell = (client: ExtendedClient) => {
      const name = clientName(client);
      return {
        children: name,
        isOnline: isClientConnected(client),
        onPress: !navigate
          ? undefined
          : () =>
              navigate("ClientDetails", {
                title: appSelect({
                  enterprise: capitalizeFirstLetter(
                    I18n.t("CLIENT_DETAILS.TITLE", { client_word: I18n.t("DEVICE_WORD") }),
                  ),
                  go: null,
                }),
                id: client.id,
              }),
        renderAccessory: !!navigate,
        subtitleFormat: this.subtitleFormat(),
        usage: client.totalUsage,
      };
    };

    const sortClientsByConnection = (connected = true) =>
      clients
        .filter((c) => connected === isClientConnected(c))
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        .sort((a, b) => b.totalUsage - a.totalUsage)
        .map(buildClientCell);

    const sortClientsByUsage = () =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      clients.sort((a, b) => b.totalUsage - a.totalUsage).map(buildClientCell);

    const sortedClients = (
      showOnline
        ? () => sortClientsByConnection()
        : () => {
            if (prioritizeOnlineClients) {
              return [...sortClientsByConnection(), ...sortClientsByConnection(false)];
            }
            return sortClientsByUsage();
          }
    )();

    const renderRow = (rowData: any, rowID: any) => (
      <ClientListRow {...rowData} testID={`${testID} - ${rowID}`} />
    );

    return (
      <TopItemsList
        customRenderRow={renderRow}
        items={sortedClients}
        maxItemsToDisplay={maxClientsToDisplay}
        onPressSeeAll={renderAllClientsButton ? this.pushFilteredClientsList : null}
      />
    );
  }
}
function mapStateToProps(state: RootState, props: TopClientsListProps): ReduxProps {
  return {
    clients: filteredClients(state, { customFilter: props.filter }),
    timespanName: timespanNameSelector(state),
  };
}

export default compose<any>(withConnectedClients, connect(mapStateToProps))(TopClientsList);
