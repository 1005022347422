import { I18n } from "@meraki/core/i18n";
import { BottomSheetMethods, Button } from "@meraki/magnetic/components";
import { Picker } from "@meraki/shared/components";
import { ForwardedRef, forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { DSCP_TAG_GROUP_FILTERS, DSCP_TAG_OPTIONS } from "../constants/TrafficShaping";

export interface DSCPTagPickerForm {
  dscpTag: number | null;
}

export const getDscpTagDescriptionTranslations = (): Record<string, string> => ({
  "0": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.0"),
  "8": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.8"),
  "10": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.10"),
  "12": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.12"),
  "14": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.14"),
  "16": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.16"),
  "18": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.18"),
  "20": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.20"),
  "22": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.22"),
  "24": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.24"),
  "26": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.26"),
  "28": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.28"),
  "30": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.30"),
  "32": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.32"),
  "34": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.34"),
  "36": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.36"),
  "38": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.38"),
  "40": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.40"),
  "44": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.44"),
  "46": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.46"),
  "48": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.48"),
  "56": I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.56"),
  null: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_DESCRIPTION.null"),
});

export const DSCPTagPicker = forwardRef((_: unknown, ref: ForwardedRef<BottomSheetMethods>) => {
  const { control } = useFormContext<DSCPTagPickerForm>();

  const dismiss = () => {
    if (typeof ref !== "function") {
      ref?.current?.dismiss();
    }
  };

  const tagDescriptionTranslations = getDscpTagDescriptionTranslations();

  const filterTranslations = {
    ASSURED_FORWARDING: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_FILTER.ASSURED_FORWARDING"),
    CLASS_SELECTORS: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_FILTER.CLASS_SELECTORS"),
    OTHER: I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TAG_FILTER.OTHER"),
  };

  return (
    <Controller
      name="dscpTag"
      control={control}
      render={({ field: { value: dscpTag, onChange }, formState: { isDirty, defaultValues } }) => (
        <Picker.WithFilter<number | null, string>
          ref={ref}
          snapPoints={["95%"]}
          hasSearchBar
          supportMultipleFilterTags
          enableContentPanningGesture={false}
          title={I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.DSCP.TITLE")}
          onCancelPress={() => {
            onChange(defaultValues?.dscpTag);
            dismiss();
          }}
          onResetPress={() => onChange(defaultValues?.dscpTag)}
          options={DSCP_TAG_OPTIONS.map(({ value, tags }) => ({
            label: value == null ? I18n.t("NONE") : value.toString(),
            value: value,
            description: tagDescriptionTranslations[String(value)],
            tags: tags,
          }))}
          filterTags={DSCP_TAG_GROUP_FILTERS.map((filter) => ({
            label: filterTranslations[filter],
            value: filter,
          }))}
          selectedOptions={[dscpTag]}
          onSelectOption={(value) => onChange(value)}
          footer={
            <Button
              text={I18n.t("SAVE")}
              onPress={dismiss}
              disabled={!isDirty}
              testID="DSCP_TAG.SAVE_BUTTON"
            />
          }
          testID="DSCP_TAG_LIST"
        />
      )}
    />
  );
});
