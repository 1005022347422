import { License } from "@meraki/shared/api";
import { memo, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import LicenseIcon from "~/go/components/LicenseIcon";
import ProductIcon from "~/go/components/ProductIcon";
import { DeviceModelsGo } from "~/go/types/DevicesTypes";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import Touchable from "~/shared/components/Touchable";
import GeneralStatus, { StatusType } from "~/shared/constants/Status";

export enum ProductIconSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export interface HardwareRowData {
  name: string;
  model: DeviceModelsGo;
  serial?: string;
  license?: License | null;
  status?: StatusType;
  description: string;
  testID?: string;
  showStatusIcon?: boolean;
  showLicenseIcon?: boolean;
  productIconSize?: ProductIconSize;
  rightAccessory?: React.ReactNode;
  onPress?: () => void;
  timestamp?: number;
  isMeshAccessPoint?: boolean;
}

const ICON_SIZE = 8;
const ICON_GUTTER = SPACING.extraLarge;

const HardwareRow = (props: HardwareRowData) => {
  const {
    name,
    model,
    serial,
    license,
    status,
    description,
    testID,
    showLicenseIcon,
    showStatusIcon,
    productIconSize,
    rightAccessory,
    onPress,
    timestamp,
    isMeshAccessPoint,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (timestamp) {
      setIsUpdating(true);
      setTimeout(() => {
        setIsUpdating(false);
      }, 1400);
    }
  }, [timestamp]);

  const statusIcon = showStatusIcon ? (
    <StatusIcon
      status={status}
      isHollow={isMeshAccessPoint}
      screenStyles={styles.statusIcon}
      timestamp={timestamp}
    />
  ) : null;

  const licenseIcon = showLicenseIcon ? (
    <LicenseIcon license={license} serial={serial} screenStyles={styles.licenseIcon} />
  ) : null;

  const combinedDeviceStatusIcon = (
    <View style={styles.iconsContainer}>
      <ProductIcon status={status} model={model} size={productIconSize} />
      {licenseIcon}
      {statusIcon}
    </View>
  );

  const descriptionLabel =
    description && description !== "" ? (
      <MkiText
        textStyle="smallSecondary"
        screenStyles={status === GeneralStatus.bad ? styles.errorMessage : {}}
      >
        {isUpdating ? I18n.t("UPDATING") : description}
      </MkiText>
    ) : null;

  const textContainerStyle = showStatusIcon ? {} : styles.textContainer;

  const renderedRightAccessory = rightAccessory && (
    <View style={styles.rightAccessory}>{rightAccessory}</View>
  );

  const row = (
    <View style={styles.rowContainer}>
      {combinedDeviceStatusIcon}
      <View style={textContainerStyle}>
        <MkiText textStyle="default">{name}</MkiText>
        {descriptionLabel}
      </View>
      {renderedRightAccessory}
    </View>
  );

  if (onPress) {
    return (
      <Touchable testID={testID} onPress={onPress} underlayColor={MkiColors.primaryButtonLighter}>
        {row}
      </Touchable>
    );
  }

  return row;
};

HardwareRow.defaultProps = {
  showStatusIcon: false,
  showLicenseIcon: false,
};

const styles = StyleSheet.create({
  rowContainer: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    margin: SPACING.default,
  },
  textContainer: {
    marginLeft: ICON_GUTTER,
  },
  errorMessage: {
    color: MkiColors.errorTextColor,
  },
  statusIcon: {
    marginHorizontal: Math.round((ICON_GUTTER - ICON_SIZE) / 2),
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  iconsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rightAccessory: {
    flex: 1,
    alignItems: "flex-end",
    alignSelf: "center",
  },
  licenseIcon: {
    position: "absolute",
    right: SPACING.meager,
    top: SPACING.meager,
  },
});

export default memo(HardwareRow);
