import { useCallback, useEffect, useState } from "react";

import { featureFlagClient } from "./client";
import { ObjectKeys } from "./providers/typeHelpers";
import { FlagMap, FlagProviders } from "./types";

export function useFlag<TValueType, TFlagProvider extends FlagProviders, TFlag>(
  provider: TFlagProvider,
  flag: string,
  defaultValue: TValueType,
  flagFetcher: (provider: TFlagProvider, flag: TFlag, skipOverride: boolean) => Promise<TValueType>,
  listenToChanges: boolean,
  skipOverride = false,
) {
  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFlag = useCallback(async () => {
    const flagValue = await flagFetcher(provider, flag as TFlag, skipOverride);
    setValue(flagValue);
  }, [provider, flag, flagFetcher, skipOverride]);

  useEffect(() => {
    const initialFetch = async () => {
      await fetchFlag();
      setIsLoading(false);
    };

    initialFetch();
  }, [setValue, setIsLoading, fetchFlag]);

  useEffect(() => {
    if (!listenToChanges) {
      return;
    }

    const listener = featureFlagClient.registerFlagListener(
      provider,
      flag as ObjectKeys<FlagMap[TFlagProvider]>,
      () => {
        fetchFlag();
      },
    );

    return () => listener();
  }, [listenToChanges, provider, flag, fetchFlag]);

  return { value, isLoading };
}
