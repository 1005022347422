import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Negative({ width, height }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 340 300" width={width} height={height}>
      <Rect
        fill={theme.color.negative.icon.weak.base}
        height="160"
        opacity=".4"
        rx="18"
        transform="rotate(25 308.619 47)"
        width="36"
        x="308.619"
        y="47"
      />
      <Rect
        fill={theme.color.negative.icon.weak.base}
        height="120"
        opacity=".2"
        rx="18"
        transform="rotate(25 83.715 124)"
        width="36"
        x="83.715"
        y="124"
      />
      <Rect
        fill={theme.color.negative.icon.weak.base}
        height="64.254"
        opacity=".3"
        rx="18"
        transform="rotate(25 125.154 0)"
        width="36"
        x="125.154"
      />
      <Path
        d="M159.083 77.535a21.666 21.666 0 0 1 21.834 0l56.334 32.862A21.666 21.666 0 0 1 248 129.112v61.777a21.667 21.667 0 0 1-10.749 18.715l-56.334 32.861a21.662 21.662 0 0 1-21.834 0l-56.334-32.861A21.667 21.667 0 0 1 92 190.889v-61.777a21.666 21.666 0 0 1 10.749-18.715l56.334-32.862Z"
        fill={theme.color.negative.icon.base}
      />
      <Path
        d="M161.334 164.334v-36.833a8.666 8.666 0 1 1 17.333 0v36.833a8.667 8.667 0 0 1-17.333 0Z"
        fill={theme.color.negative.iconIn.base}
      />
      <Circle cx="170" cy="194.667" fill={theme.color.negative.iconIn.base} r="10.833" />
    </Svg>
  );
}
