import { I18n } from "@meraki/core/i18n";
import { useCurrentAdminUser, useTimezones } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import * as RNLocalize from "react-native-localize";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import {
  COMPANY_SIZE_RANGES,
  COUNTRIES,
  INDUSTRIES,
  RETURN_KEY,
  SPACING,
} from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { RegistrationForm } from "~/go/types/RegistrationTypes";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { translateOptionLabels } from "~/lib/formatHelper";
import { PerfScreenNames } from "~/lib/PerformanceUtils";
import { getRegionTimezonesSorted } from "~/lib/TimezoneUtils";
import { currentOrganization, currentUserState } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { usePerfTrace } from "~/shared/hooks/usePerfTrace";
import { CloseButton } from "~/shared/navigation/Buttons";

import { SettingsStackProps } from "../navigation/Types";

interface Option {
  label: string;
  value: string;
}

const industryOptions: Option[] = translateOptionLabels(INDUSTRIES);
const companySizeOptions: Option[] = translateOptionLabels(COMPANY_SIZE_RANGES);
const countryOptions: Option[] = translateOptionLabels(
  Object.entries(COUNTRIES).map(([countryCode, { name }]) => ({
    label: name,
    value: countryCode,
  })),
);

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "CreateOrganization"> &
  PendingComponent;

function CreateOrganizationScreen({ handleError, setReqPending }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const actions = useActions();
  const organizationId = useAppSelector(currentOrganization).id ?? "";
  const email = useAppSelector(currentUserState);
  const { data: currentUserAdmin } = useCurrentAdminUser({ organizationId, email });
  const [_, stopPerfTrace] = usePerfTrace(PerfScreenNames.orgChooseScreen);

  const [company, setCompany] = useState("");

  const [showIndustryPicker, setShowIndustryPicker] = useState(false);
  const [industry, setIndustry] = useState(industryOptions[0].value);
  const [industryLabel, setIndustryLabel] = useState(industryOptions[0].label);
  const toggleIndustryPicker = useCallback(() => {
    setShowIndustryPicker(!showIndustryPicker);
  }, [showIndustryPicker]);
  function selectIndustry(val: string | number | null) {
    const selected = val as string;
    setIndustry(selected);
    setIndustryLabel(industryOptions.find(({ value }) => value === selected)?.label ?? "");
    toggleIndustryPicker();
  }

  const [showCompanySizePicker, setShowCompanySizePicker] = useState(false);
  const [companySize, setCompanySize] = useState(companySizeOptions[0].value);
  const [companySizeLabel, setCompanySizeLabel] = useState(companySizeOptions[0].label);
  const toggleCompanySizePicker = useCallback(() => {
    setShowCompanySizePicker(!showCompanySizePicker);
  }, [showCompanySizePicker]);
  function selectCompanySize(val: string | number | null) {
    const selected = val as string;
    setCompanySize(selected);
    setCompanySizeLabel(companySizeOptions.find(({ value }) => value === selected)?.label ?? "");
    toggleCompanySizePicker();
  }

  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const [country, setCountry] = useState("US");
  const [countryLabel, setCountryLabel] = useState(
    countryOptions.find(({ value }) => value === "US")?.label ?? "",
  );
  const toggleCountryPicker = useCallback(() => {
    setShowCountryPicker(!showCountryPicker);
  }, [showCountryPicker]);
  function selectCountry(val: string | number | null) {
    const selected = val as string;
    setCountry(selected);
    setCountryLabel(countryOptions.find(({ value }) => value === selected)?.label ?? "");
    toggleCountryPicker();
  }

  const { data: timezones } = useTimezones();
  const [showTimezonePicker, setShowTimezonePicker] = useState(false);
  const [timezone, setTimezone] = useState(RNLocalize.getTimeZone());
  const toggleTimezonePicker = useCallback(() => {
    setShowTimezonePicker(!showTimezonePicker);
  }, [showTimezonePicker]);
  function selectTimezone(val: string | number | null) {
    const selected = val as string;
    setTimezone(selected);
    toggleTimezonePicker();
  }

  const createOrg = useCallback(async () => {
    setReqPending(true);

    try {
      const { response } = await actions.createGoOrg(company, timezone, country);
      const { marketo_api_token, eid } = response;

      if (marketo_api_token != null && currentUserAdmin != null) {
        const names = currentUserAdmin.name.split(" ");
        actions.sendToMarketo(
          {
            company,
            industry,
            country,
            company_size: companySize,
            email: currentUserAdmin.email,
            first_name: names.shift(),
            last_name: names.join(" "),
            tips: false,
          } as RegistrationForm,
          marketo_api_token,
          true,
        );
      }

      if (eid != null) {
        await actions.fetchOrgs();
        await actions.orgOverview(eid);
        await stopPerfTrace();
      }
    } catch (error) {
      if (typeof error === "string") {
        handleError(error);
      }
    }
  }, [
    actions,
    company,
    companySize,
    country,
    currentUserAdmin,
    handleError,
    industry,
    setReqPending,
    stopPerfTrace,
    timezone,
  ]);

  const timezoneLabel = timezones?.options?.find(({ value }) => value === timezone)?.label || "";
  return (
    <FullScreenContainerView defaultPadding withScroll>
      <UnderlinedTextInput
        title={I18n.t("CREATE_ACCOUNT.COMPANY")}
        placeholder={I18n.t("CREATE_ACCOUNT.PLACEHOLDERS.COMPANY")}
        value={company}
        onChangeText={setCompany}
        returnKeyType={RETURN_KEY.next}
        testID="CREATE_ORG.NAME"
        editable
        showClearButton
      />
      <PickerModalRow
        loginStyle
        selectedValue={industry}
        label={I18n.t("CREATE_ACCOUNT.SELECT_INDUSTRY")}
        subtitle={industryLabel}
        visible={showIndustryPicker}
        title={I18n.t("CREATE_ACCOUNT.SELECT_INDUSTRY")}
        items={industryOptions}
        onValueSelect={selectIndustry}
        onExit={toggleIndustryPicker}
        onPress={toggleIndustryPicker}
        testID="CREATE_ORG.INDUSTRY"
      />
      <PickerModalRow
        loginStyle
        selectedValue={companySize}
        label={I18n.t("CREATE_ACCOUNT.SELECT_COMPANY_SIZE")}
        subtitle={companySizeLabel}
        visible={showCompanySizePicker}
        title={I18n.t("CREATE_ACCOUNT.SELECT_COMPANY_SIZE")}
        items={companySizeOptions}
        onValueSelect={selectCompanySize}
        onExit={toggleCompanySizePicker}
        onPress={toggleCompanySizePicker}
        testID="CREATE_ORG.SIZE"
      />
      <PickerModalRow
        loginStyle
        selectedValue={country}
        label={I18n.t("CREATE_ACCOUNT.SELECT_COUNTRY")}
        subtitle={countryLabel}
        visible={showCountryPicker}
        title={I18n.t("CREATE_ACCOUNT.SELECT_COUNTRY")}
        items={countryOptions}
        onValueSelect={selectCountry}
        onExit={toggleCountryPicker}
        onPress={toggleCountryPicker}
        testID="CREATE_ORG.COUNTRY"
      />
      <PickerModalRow
        loginStyle
        selectedValue={timezone}
        label={I18n.t("CREATE_ACCOUNT.SELECT_TIMEZONE")}
        subtitle={timezoneLabel}
        visible={showTimezonePicker}
        title={I18n.t("CREATE_ACCOUNT.SELECT_TIMEZONE")}
        items={getRegionTimezonesSorted(timezones?.options || [], COUNTRIES[country].region)}
        onValueSelect={selectTimezone}
        onExit={toggleTimezonePicker}
        onPress={toggleTimezonePicker}
        testID="CREATE_ORG.TIMEZONE"
      />
      <RoundedButton
        buttonType={ButtonType.tertiary}
        onPress={createOrg}
        screenStyles={styles.button}
        testID="CREATE_ORG.BUTTON"
      >
        {I18n.t("SETTINGS.ADVANCED.CREATE_ORG.TITLE")}
      </RoundedButton>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  button: {
    marginVertical: SPACING.default,
    marginHorizontal: SPACING.extraLarge,
  },
});

export default withPendingComponent(CreateOrganizationScreen);
