import { I18n } from "@meraki/core/i18n";
import { LAYER_7_GROUPS } from "@meraki/go/traffic-shaping";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { UsageScreensPropMap } from "~/go/navigation/Types";
import MkiTable from "~/shared/components/MkiTable";
import { CancelButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

type Props = ForwardedNativeStackScreenProps<UsageScreensPropMap, "LimitApplicationUsage">;

type Row = {
  children: string;
  key: string;
  onPress?: undefined;
};
export default class LimitApplicationUsageListScreen extends PureComponent<Props> {
  static defaultProps = {
    ssidNumber: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
    });
  }

  pushFromSelection = (rowData: Row) => {
    // @ts-expect-error TS(2339) FIXME: Property 'onTitle' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { navigation, onTitle, ssidNumber } = this.props;
    const { key } = rowData;

    navigation.navigate("LimitApplicationUsage", {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ onTitle: any; group: any; ssid... Remove this comment to see the full error message
      onTitle,
      group: key,
      ssidNumber,
    });
  };

  render() {
    const rows = Object.entries(LAYER_7_GROUPS).map(([key, value]) => ({
      children: I18n.t(value.displayName || value.name),
      key,
    }));

    return (
      <MkiTable<Row>
        data={rows}
        keyExtractor={(item) => item.key}
        onPress={this.pushFromSelection}
        renderRow={(rowData) => <DisclosureRow {...rowData} />}
        scrollViewTestID="APPLICATION_USAGE_LIST_VIEW"
      />
    );
  }
}
