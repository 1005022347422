import { I18n } from "@meraki/core/i18n";
import { memo, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { LETTER_SPACING, SPACING } from "~/constants/MkiConstants";
import { PROXIMA_SOFT_FONT } from "~/constants/MkiFonts";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import { useTheme } from "~/shared/hooks/useTheme";

interface ShowHidePasswordButtonProps {
  onPress: (showPassword: boolean) => void;
  buttonStyle?: StyleProp<ViewStyle>;
}

const ShowHidePasswordButton = ({ onPress, buttonStyle }: ShowHidePasswordButtonProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const showButtonText = showPassword
    ? I18n.t("PASSWORD_BUTTON.HIDE_PASSWORD")
    : I18n.t("PASSWORD_BUTTON.SHOW_PASSWORD");
  const color = themeColors(useTheme()).navigation.primary;

  const handleOnPress = () => {
    const newPasswordState = !showPassword;
    setShowPassword(newPasswordState);
    onPress?.(newPasswordState);
  };

  return (
    <Touchable onPress={handleOnPress} transparentBackground testID={"SHOW_HIDE_PASSWORD"}>
      <View style={[styles.showButton, buttonStyle]}>
        <MkiText textStyle="small" screenStyles={[{ color }, styles.showButtonText]}>
          {showButtonText.toUpperCase()}
        </MkiText>
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  showButton: {
    paddingVertical: SPACING.default,
    paddingHorizontal: SPACING.small,
    justifyContent: "center",
  },
  showButtonText: appSelect({
    go: {
      ...PROXIMA_SOFT_FONT,
      letterSpacing: LETTER_SPACING.allCaps,
    },
    enterprise: {
      letterSpacing: LETTER_SPACING.allCaps,
    },
  }),
});

export default memo(ShowHidePasswordButton);
