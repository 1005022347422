import { Box } from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { Platform, Switch, SwitchProps } from "react-native";

import { Text } from "../Text/Text";

type InternalToggleProps = {
  checked: boolean;
  disabled?: boolean;
  children?: string;
};

export type ToggleProps = Omit<SwitchProps, "style" | "value"> & InternalToggleProps;

function getTrackColor({ disabled }: InternalToggleProps, theme: Theme) {
  if (disabled) {
    return {
      true: theme.color.control.bg.strong.disabled,
      false: theme.color.default.bg.strong.disabled,
    };
  }

  return {
    true: theme.color.control.bg.strong.base,
    false: theme.color.default.bg.strong.base,
  };
}

function getIosBackgroundColor({ checked, disabled }: InternalToggleProps, theme: Theme) {
  if (disabled) {
    if (checked) {
      return "transparent";
    }

    return theme.color.default.bg.strong.disabled;
  }

  return theme.color.default.bg.strong.base;
}

export function Toggle({ checked, disabled, children, ...rest }: ToggleProps) {
  const theme = useMagneticTheme();

  const internalProps = { checked, disabled };

  return (
    <Box flexDirection="row" alignItems="center" gap="2xs">
      <Switch
        {...rest}
        value={checked}
        disabled={disabled}
        trackColor={getTrackColor(internalProps, theme)}
        thumbColor={theme.color.control.iconIn.base}
        {...Platform.select({
          web: {
            activeThumbColor: theme.color.control.iconIn.base,
          },
        })}
        ios_backgroundColor={getIosBackgroundColor(internalProps, theme)}
        accessibilityState={{ checked }}
      />
      <Text size="p3">{children}</Text>
    </Box>
  );
}
