import * as Ping from "../handlers/Ping";
import LiveTool from "../types/LiveToolMsg";
import useCtxAwareAggregation from "./useCtxAwareAggregation";
import useLiveBroker from "./useLiveBroker";

const aggregatePingStats = (
  accumulatedResult?: Ping.Result,
  formattedMsg?: Ping.FormattedResult,
): Ping.Result | undefined => {
  if (!formattedMsg) {
    return undefined;
  }

  const stagedResult: Ping.Result = {
    ...(accumulatedResult ?? {
      pings: [],
      numLost: 0,
      numSuccess: 0,
      lossRate: 0,
      averageLatency: 0,
    }),
  };

  const { latencyV4 } = formattedMsg;
  stagedResult.pings = stagedResult.pings.concat(latencyV4.pings);
  stagedResult.numSuccess += latencyV4.numSuccess;
  stagedResult.numLost += latencyV4.numLost;
  const numPings = stagedResult.pings.length;
  stagedResult.lossRate = stagedResult.numLost / numPings;

  let sum = 0;
  for (let i = 0; i < numPings; i++) {
    sum += stagedResult.pings[i];
  }
  stagedResult.averageLatency = sum / stagedResult.numSuccess;
  return stagedResult;
};

export default function usePing(deviceId: string, host?: string): LiveTool<Ping.Result> {
  const msg = useLiveBroker<Ping.Props, Ping.Message>({
    type: host ? "PingHost" : "PingAP",
    deviceId,
    host,
  });

  return useCtxAwareAggregation(msg, Ping.formatMsg, aggregatePingStats);
}
