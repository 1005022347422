import { I18n } from "@meraki/core/i18n";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import { CHECKMARK_SIZE } from "~/constants/MkiConstants";
import { showAlert } from "~/lib/AlertUtils";
import { filterSelectedData, getNestedData, setSelected } from "~/lib/NestedTableUtils";
import { NestedTableModal as MagneticNestedTableModal } from "~/migrationZone/enterprise/clients/screens/EditClientScreen/NestedTableModal";
import { nestedModalDataState } from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import NestedTableRenderRow, { RowData } from "~/shared/components/NestedTableRenderRow";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import { SharedScreensPropMap } from "~/shared/navigation/Types";
import { Next } from "~/shared/screens/NestedTableModalTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

/*
 * NestedTableModal builds out a tree of tableviews based on the data provided. The data must
 * be stored in redux using setNestedModalData().
 *
 * The basic requirement of the NestedTableModal component is that it needs to be able to
 * manipulate any node on the tree regardless of where we currently are in the tree, the
 * explanation of this can be found in the comments for the setSelected method. The reason
 * we need to use Redux becomes clear when we examine the alternative:

 * Keep state at the root of the tree or even at the component that called showModal, and pass
 * the setSelected function down to all children. When a child is selected, it will call the
 * setSelected function, which has been passed down from its parent, which in turn was passed
 * down from its parent and so on, eventually triggering the function at the root which changes
 * its state. The problem here is that since the views don't operate on a render hierarchy and
 * are instead separate views pushed down onto the previous one, this change of state doesn't
 * propagate down the tree. This means that the data will be changed, and the child won't even
 * know about it!

 * Maybe we could store the state of the local node so the local node's change is reflected and
 * that would solve our issue? Not so fast, the selection of the current node selects all other
 * nodes in its hierarchy, so when you go up a level, you encounter the same problem, that node
 * doesn't know about the state change that just occurred, and still displays the initial state!

 * Because of this, we need to have a source of truth that all nodes can manipulate, as well as
 * a way for all nodes to be informed of any changes, hence why we need redux.
 *
 * nestedModalData should have a shape like this (* required):
 *   *next: (Object) top level "next" key to keep the structure identical to children
 *     title: (String) modal title
 *     singleChoice: (boolean) should only one of the children be selected at any point?
 *     *entries: (array of Objects) the children to show in a list, must have at least 1
 *       *entry: (Object) an entry should have a shape like this:
 *         *label: (String) text to show on the row
 *         *value: (Object implementing '==' operator) value to be used for comparisons,
 *           it should be unique amongst its siblings
 *         renderDetail: (function accepting entries) Used for parent nodes, the entries
 *           passed in are its children, and the return value should be a String that will
 *           be displayed on right side of the row
 *         selected: (boolean) is this row or any of its descendents selected? This value is
 *           used to render details or check marks. It is also conditionally recalculated when
 *           a row is tapped. See setSelected() for more info.
 *         next: (Object) if this entry has children, their info is nested in here
 *
 *  Example:
 *  {
 *    next: {
 *      title: "Example title",
 *      entries: [{
 *        label: "Entry 1",
 *        value: "entry1",
 *        renderDetail: entries => `${entries.length} value(s) inside!`,
 *        selected: true,
 *        next: {
 *          title: "Nested title",
 *          singleChoice: true,
 *          entries: [
 *            { label: "Entry 3", value: 0 },
 *            { label: "Entry 4", value: 1 },
 *            { label: "Entry 5", value: 2 },
 *          ],
 *        },
 *      }, {
 *        label: "Entry 2",
 *        value: "entry2",
 *        selected: true,
 *      }],
 *    }
 *  }
 *
 * Extra fields can be included as well, these will be passed in along with the object when
 * the save button is pressed and the onSave callback is triggered.
 *
 * Since all levels of the NestedTableModal have access to the full data structure, the way we
 * keep track of where in the "tree" we are is by this.props.position. Position is an array of
 * indexes where the first index is the current ancestor at the top level, and the next index is
 * the next level down, and so on.
 *
 * For example if we have a nested modal structure like this:
 * NestedModal  this.props.position
 * -----------  -------------------
 * entry1           []
 *   entry4         [0]
 *   entry5         [0]
 *     entry7       [0, 1]
 * entry2           []
 * entry3           []
 *
 */

type ReduxProps<T> = {
  nestedModalData: {
    next: Next<T>;
  };
};

type Props<T> = ForwardedNativeStackScreenProps<SharedScreensPropMap, "NestedTable"> &
  ReduxProps<T> &
  BasicActions;

type NestedTableModalState = {
  loading: boolean;
};

class NestedTableModal<T> extends PureComponent<Props<T>, NestedTableModalState> {
  static defaultProps = {
    position: [],
    showDismiss: false,
    options: {},
  };

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      loading: false,
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, nestedModalData, onSave, showDismiss, options, title } = this.props;

    if (title) {
      navigation.setOptions({
        headerTitle: title,
      });
    }

    navigation.setOptions({
      headerLeft: () => (showDismiss ? <CancelButton onPress={navigation.goBack} /> : null),
      headerRight: () => (
        <SaveButton
          onPress={async () => {
            const filteredData = filterSelectedData(nestedModalData.next.entries);
            this.setState({ loading: true });

            try {
              await onSave(filteredData);
              if (options?.singleDismiss) {
                navigation.goBack();
              } else {
                navigation.popToTop();
              }
            } catch (error) {
              showAlert("", error || I18n.t("ERROR.MESSAGE"));
            } finally {
              this.setState({ loading: false });
            }
          }}
        />
      ),
    });
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  /*
   * If a parent node is pressed, update the position and push on a new
   * modal. Otherwise, set the leaf node to selected and recalculate the
   * tree of selected nodes.
   */
  onPress = (rowData: Next<T>, rowIndex: number) => {
    const { navigation, actions, nestedModalData, onSave, options, position = [] } = this.props;
    const newPosition = [...position, rowIndex];

    if ("next" in rowData) {
      navigation.navigate("NestedTable", {
        title: rowData.next.title,
        position: newPosition,
        onSave,
        options,
      });
    } else {
      const newData = setSelected(nestedModalData, newPosition, 0, { toggle: options?.toggle });
      actions.setNestedModalData(newData);
    }
  };

  renderRow = (rowData: RowData<T>, rowIndex: number) => {
    const { options } = this.props;
    return (
      <NestedTableRenderRow
        rowData={rowData}
        rowIndex={rowIndex}
        emptyViewStyle={styles.emptyView}
        options={options}
      />
    );
  };

  render() {
    const { nestedModalData, position } = this.props;
    const { loading } = this.state;
    if (!nestedModalData) {
      return null;
    }
    return (
      <MkiTable<any>
        data={getNestedData(nestedModalData, position, true)}
        keyExtractor={(item) => item.label}
        renderRow={this.renderRow}
        onPress={this.onPress}
        loading={loading}
      />
    );
  }
}

function mapStateToProps<T extends string>(state: RootState): ReduxProps<T> {
  return {
    nestedModalData: nestedModalDataState(state),
  };
}

const styles = StyleSheet.create({
  emptyView: {
    width: CHECKMARK_SIZE,
    height: CHECKMARK_SIZE,
  },
});

const ConnectedNestedTableModal = connect(
  mapStateToProps,
  basicMapDispatchToProps,
)(NestedTableModal);

export default withMagneticReplacementAdapter(ConnectedNestedTableModal, MagneticNestedTableModal);
