import { useEffect } from "react";

import NFOs from "~/constants/NFOs";
import { getNFOs } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

export function useHasAlphaFeatures() {
  return useNFOs().has(NFOs.hasMobileAlphaFeatures);
}

export default function useNFOs(): Set<NFOs> {
  const nfos = useAppSelector(getNFOs);
  const { fetchOrgNFOs } = useActions();

  useEffect(() => {
    fetchOrgNFOs();
  }, [fetchOrgNFOs]);

  return nfos;
}
