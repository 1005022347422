import * as errorMonitor from "@meraki/core/errors";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { getSSIDtoAdd, IP_ASSIGNMENT, validateName, validatePSK } from "~/lib/SSIDUtils";
import {
  configuredSSIDsSelector,
  currentNetworkState,
  hasNATRouterSelector,
  slimSsidsSelector,
} from "~/selectors";
import { WrappedScreenComponent } from "~/shared/types/Hocs";
import { SSID, SSIDAuthMode, SSIDEncryptionMode } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  availableSSIDNumber: number;
  configuredSSIDs: SSID[];
  hasNATRouter: boolean;
  networkId: string;
};

export interface SSIDCreateProps extends PendingComponent, BasicActions, ReduxProps {
  createSSID: (name: string, psk?: string | null, isGuestNetwork?: boolean) => Promise<number>;
}

export interface SSIDCreateState {
  mustHaveNAT: boolean;
}

const withSSIDCreate = <P extends SSIDCreateProps>(WrappedComponent: WrappedScreenComponent<P>) =>
  compose<any>(
    withPendingComponent,
    connect(mapStateToProps, basicMapDispatchToProps),
    SSIDCreateComponent,
  )(WrappedComponent);

export const SSIDCreateComponent = <P extends object>(
  WrappedComponent: WrappedScreenComponent<P>,
) =>
  class SSIDCreate extends PureComponent<P & SSIDCreateProps, SSIDCreateState> {
    constructor(props: P & SSIDCreateProps) {
      super(props);

      const { hasNATRouter } = props;
      this.state = { mustHaveNAT: !hasNATRouter };
      this.loadData();
    }

    loadData = () => {
      const { actions, handleError, hasNATRouter, networkId } = this.props;

      if (!networkId) {
        return;
      }

      actions
        .loadNodesAndStatuses(networkId)
        .then(() =>
          this.setState({
            // Copying this over from original implementation done by Rochelle Lee
            // If there are no online devices to use for checking if there's an
            // upstream NAT router then we should choose NAT just to be safe.
            // The problem with this is if it turns how they do have a NAT modem then
            // we have chosen a less ideal default mode, but at least their network will
            // still work so it's still better than the alternative
            mustHaveNAT: !hasNATRouter,
          }),
        )
        .catch(() => handleError(I18n.t("SERVER_ERROR_TEXT")));
    };

    createSSID = (name: string, psk?: string, isGuestNetwork?: boolean) => {
      const { actions, availableSSIDNumber, configuredSSIDs, networkId } = this.props;

      const { mustHaveNAT } = this.state;

      if (availableSSIDNumber === undefined || availableSSIDNumber < 0) {
        return Promise.reject(I18n.t("MAX_SSIDS_ERROR.MESSAGE"));
      }

      const authMode = isEmpty(psk) ? SSIDAuthMode.open : SSIDAuthMode.psk;

      if (psk) {
        const error = validatePSK(psk);
        if (error) {
          return Promise.reject(error);
        }
      }

      const nameError = validateName(name, configuredSSIDs);
      if (nameError) {
        return Promise.reject(nameError);
      }

      // Copying this over from original implementation done by Rochelle Lee
      // this is an extra precaution, the loadData call should be able warn the
      // user in case we fail to fetch the devices to determine the value of mustHaveNAT
      // but just in case something else goes wrong, we don't want the user to be
      // able to create a new SSID without checking if something is NATing for the
      // AP first.
      if (mustHaveNAT === undefined) {
        return Promise.reject(I18n.t("SERVER_ERROR_TEXT"));
      }

      const ipAssignmentMode =
        isGuestNetwork || mustHaveNAT ? IP_ASSIGNMENT.NAT : IP_ASSIGNMENT.BRIDGE;

      const newSsid = {
        number: availableSSIDNumber,
        name,
        authMode,
        enabled: true,
        isGuestNetwork,
        ipAssignmentMode,
        ...(mustHaveNAT && !isGuestNetwork ? { c2cEnabled: true } : {}),
        ...(authMode === SSIDAuthMode.psk ? { psk, encryptionMode: SSIDEncryptionMode.wpa } : {}),
      };

      return actions.setSsid(networkId, newSsid).then(() => availableSSIDNumber);
    };

    render() {
      return <WrappedComponent {...this.props} createSSID={this.createSSID} />;
    }
  };

function mapStateToProps(state: RootState): ReduxProps {
  return {
    availableSSIDNumber: getSSIDtoAdd(slimSsidsSelector(state)),
    configuredSSIDs: configuredSSIDsSelector(state),
    hasNATRouter: hasNATRouterSelector(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDCreate",
      currentNetworkState(state),
    ),
  };
}

export default withSSIDCreate;
