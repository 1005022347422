import { I18n } from "@meraki/core/i18n";
import { getProductType } from "@meraki/shared/devices";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import ImagePicker from "react-native-image-crop-picker";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import {
  useApplianceSettings,
  useUpdateApplianceSettings,
} from "~/api/queries/appliances/useApplianceSettings";
import MkiColors from "~/constants/MkiColors";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showActionSheet, showAlert, showRequestPermissions } from "~/lib/AlertUtils";
import { PERMISSION_ERRORS, USER_LIBRARY } from "~/lib/CameraUtils";
import {
  deviceName,
  getDeviceLiveStatus,
  getDeviceStatus,
  getIsMeshAccessPoint,
} from "~/lib/DeviceUtils";
import { deviceState } from "~/selectors";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import ImageThumbnail from "~/shared/components/ImageThumbnail";
import InputModal from "~/shared/components/InputModal";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { Alert } from "~/shared/types/AlertTypes";
import { ProductType } from "~/shared/types/Networks";

const THUMBNAIL_SIZE = 100;

interface PrefixError {
  errors?: string[];
}

const getIpAddressText = (device: any) => {
  const { model, wan1Ip, lanIp, is_gateway: isGateway, ip, isLive } = device;

  const deviceStatus = getDeviceLiveStatus(isLive, getDeviceStatus(device) ?? GoStatus.offline);
  const isMeshedAP = getIsMeshAccessPoint(device);
  const isOffline = deviceStatus === GoStatus.offline;
  const isWireless = getProductType(model) === ProductType.wireless;
  const noLanIpText = isGateway || !isWireless ? I18n.t("NONE") : I18n.t("LAN_IP_VALUE_REPEATER");

  let ipAddressText;
  if (isOffline) {
    ipAddressText = I18n.t("NONE");
  } else if (isMeshedAP) {
    ipAddressText = I18n.t("MESHED_GR_STATUS.AP_DESCRIPTION");
  } else {
    ipAddressText = wan1Ip || lanIp || ip || noLanIpText;
  }
  return ipAddressText;
};

const renderRow = ({ subtitle, title, onPressRow, testID, inlineView }: any) => {
  return (
    <DisclosureRow subtitle={subtitle} onPress={onPressRow} testID={testID} inlineView={inlineView}>
      {title}
    </DisclosureRow>
  );
};

const renderSectionHeader = ({ section }: any) => {
  return (
    <MkiText textStyle="subheading" screenStyles={styles.header}>
      {section.key}
    </MkiText>
  );
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "HardwareSettings">;

export const HardwareSettingsScreen = ({ serialNumber }: Props) => {
  const navigation = useNavigation<Props["navigation"]>();
  const actions = useActions();
  const { updateDevicePhoto, updateDevice, deleteDevicePhoto } = actions;

  const ddnsPrefixMutation = useUpdateApplianceSettings();
  const device = useAppSelector((state) => deviceState(state, { serialNumber }));
  const { model, serial, id, name, images, address } = device;
  const isWireless = getProductType(model) === ProductType.wireless;
  const isAppliance = getProductType(model) === ProductType.appliance;
  const applianceSettings = useApplianceSettings(isAppliance).data;
  const dynamicDns = applianceSettings?.dynamicDns;

  const [ddnsAlert, setDDNSAlert] = useState<Alert | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditDDNS, setIsEditDDNS] = useState(false);
  const [newPrefix, setNewPrefix] = useState(dynamicDns?.prefix || "");
  const [newAddress, setNewAddress] = useState(address);

  const imagePath = images?.thumbnail;

  useEffect(() => {
    const dynamicDns = applianceSettings?.dynamicDns;
    if (dynamicDns) {
      setNewPrefix(dynamicDns?.prefix ?? "");
    }
  }, [applianceSettings]);

  const updateAddress = async () => {
    setIsLoading(true);
    await updateDevice(serial, {
      address: newAddress,
    });
    setIsLoading(false);
    setIsEditAddress(false);
  };

  const updatePrefix = () => {
    setIsLoading(true);
    ddnsPrefixMutation.mutate(
      { dynamicDns: { prefix: newPrefix } },
      {
        onError: (e) => {
          const error = e as PrefixError;

          if (error.errors) {
            setDDNSAlert({
              status: GoStatus.bad,
              message: error.errors[0],
            });
          }
        },
        onSuccess: () => {
          setIsEditDDNS(false);
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const removeMountingPhoto = async () => {
    setIsLoading(true);
    await deleteDevicePhoto(serial, images.id);
    setIsLoading(false);
  };

  const launchPhotoLibrary = async () => {
    try {
      const imageFile = await ImagePicker.openPicker({
        smartAlbums: [USER_LIBRARY],
        avoidEmptySpaceAroundImage: false,
        cropping: true,
      });

      if (imageFile && !Array.isArray(imageFile)) {
        setIsLoading(true);
        await updateDevicePhoto(serial, id, imageFile?.path);
        setIsLoading(false);
      }
    } catch (error: unknown) {
      const { code } = error as { code: string };
      if (code === PERMISSION_ERRORS.CAMERA_ROLL_PERMISSIONS_MISSING) {
        showRequestPermissions(
          I18n.t("CAMERA_ROLL_PERMISSIONS.TITLE"),
          I18n.t("CAMERA_ROLL_PERMISSIONS.REQUEST_IOS"),
          I18n.t("CAMERA_ROLL_PERMISSIONS.REQUEST_ANDROID"),
          I18n.t("OPEN_APP_SETTINGS.TITLE"),
        );
      } else {
        showAlert(I18n.t("ERROR"), error);
      }
    }
    setIsLoading(false);
  };

  const launchCamera = async () => {
    try {
      const imageFile = await ImagePicker.openCamera({
        cropping: true,
      });
      if (imageFile && !Array.isArray(imageFile)) {
        setIsLoading(true);
        await updateDevicePhoto(serial, id, imageFile?.path);
        setIsLoading(false);
      }
    } catch (error: unknown) {
      const { code } = error as { code: string };
      if (code === PERMISSION_ERRORS.CAMERA_PERMISSIONS_MISSING) {
        showRequestPermissions(
          I18n.t("CAMERA_PERMISSIONS.TITLE"),
          I18n.t("CAMERA_PERMISSIONS.REQUEST_IOS"),
          I18n.t("CAMERA_PERMISSIONS.REQUEST_ANDROID"),
          I18n.t("OPEN_APP_SETTINGS.TITLE"),
        );
      }
    }
    setIsLoading(false);
  };

  const getData = () => {
    const settingsRows: any[] = [];
    const detailsRows: any[] = [];

    if (isWireless) {
      settingsRows.push({
        key: "RADIO_SETTINGS",
        testID: "RADIO_SETTINGS_ROW",
        title: I18n.t("HARDWARE_SETTINGS.RADIO_SETTINGS.TITLE"),
        subtitle: I18n.t("HARDWARE_SETTINGS.RADIO_SETTINGS.DESCRIPTION"),
        onPressRow: () => navigation.navigate("RadioSettingsList", { serialNumber }),
      });
    }
    if (isAppliance) {
      settingsRows.push({
        key: "SECURITY",
        testID: "SECURITY_ROW",
        title: I18n.t("HARDWARE_SETTINGS.UMBRELLA.TITLE"),
        subtitle: I18n.t("HARDWARE_SETTINGS.UMBRELLA.DESCRIPTION"),
        onPressRow: () => navigation.navigate("UmbrellaSettings"),
      });
      settingsRows.push({
        key: "DYNAMIC_DNS",
        testID: "DYNAMIC_DNS_ROW",
        title: I18n.t("HARDWARE_SETTINGS.DYNAMIC_DNS.TITLE"),
        subtitle: applianceSettings?.dynamicDns?.url ?? "",
        onPressRow: () => setIsEditDDNS(true),
      });
      settingsRows.push({
        key: "L3_SETTINGS",
        testID: "L3_SETTINGS",
        title: I18n.t("HARDWARE_SETTINGS.L3_FIREWALL_RULES.TITLE"),
        subtitle: I18n.t("HARDWARE_SETTINGS.L3_FIREWALL_RULES.DESCRIPTION"),
        onPressRow: () => navigation.navigate("L3FirewallRulesList"),
      });
    }

    settingsRows.push({
      key: "IP_CONFIGURATION",
      testID: "IP_CONFIGURATION_ROW",
      title: I18n.t("HARDWARE_SETTINGS.IP_CONFIGURATION.TITLE"),
      subtitle: getIpAddressText(device),
      onPressRow: () => navigation.navigate("StaticIPConfiguration", { serialNumber }),
    });

    detailsRows.push(
      {
        key: "MOUNTING_PHOTO",
        testID: "MOUNTING_PHOTO_ROW",
        title: I18n.t("HARDWARE_SETTINGS.MOUNTING_PHOTO.TITLE"),
        subtitle: !imagePath ? I18n.t("NONE") : undefined,
        inlineView: (
          <ImageThumbnail
            show={!!imagePath}
            source={{ uri: imagePath, width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE }}
            onClose={removeMountingPhoto}
          />
        ),
        onPressRow: () =>
          showActionSheet(
            [
              I18n.t("HARDWARE_SETTINGS.MOUNTING_PHOTO.OPTIONS.SELECT_FROM_GALLERY"),
              I18n.t("HARDWARE_SETTINGS.MOUNTING_PHOTO.OPTIONS.TAKE_PHOTO"),
            ],
            (id: any) => {
              switch (id) {
                case 0:
                  launchPhotoLibrary();
                  break;
                case 1:
                  launchCamera();
                  break;
              }
            },
          ),
      },
      {
        key: "ADDRESS",
        testID: "ADDRESS_ROW",
        title: I18n.t("HARDWARE_SETTINGS.ADDRESS.TITLE"),
        subtitle: address || I18n.t("NONE"),
        onPressRow: () => setIsEditAddress(true),
      },
    );

    return {
      [I18n.t("HARDWARE_SETTINGS.HEADINGS.DETAILS")]: detailsRows,
      [I18n.t("HARDWARE_SETTINGS.HEADINGS.SETTINGS")]: settingsRows,
    };
  };

  const saveName = (name: any) => {
    return actions.updateDevice(serialNumber, { name });
  };

  return (
    <FullScreenContainerView defaultPadding>
      <EditableNameHeader
        entity={I18n.t("HARDWARE_WORD")}
        save={saveName}
        title={deviceName({ name, serial: serialNumber })}
        testID="HARDWARE_SETTINGS_NAME"
      />
      <MkiTable
        data={getData()}
        renderSectionHeader={renderSectionHeader}
        renderRow={renderRow}
        scrollViewTestID={"HARDWARE_SETTINGS_SCROLL_VIEW"}
      />
      <InputModal
        title={I18n.t("HARDWARE_SETTINGS.ADDRESS.INPUT_MODAL.TITLE")}
        visible={isEditAddress}
        value={newAddress}
        onExit={() => {
          setIsEditAddress(false);
          setNewAddress(address);
        }}
        onChangeText={(newText: any) => setNewAddress(newText)}
        onPrimaryPress={updateAddress}
        loading={isLoading}
      />
      <InputModal
        title={I18n.t("HARDWARE_SETTINGS.DYNAMIC_DNS.INPUT_MODAL.TITLE")}
        alert={ddnsAlert}
        visible={isEditDDNS}
        value={newPrefix}
        autoCapitalize={"none"}
        onExit={() => {
          setIsEditDDNS(false);
          setDDNSAlert(undefined);
          setNewPrefix(dynamicDns?.prefix ?? "");
        }}
        onChangeText={(prefix: any) => setNewPrefix(prefix)}
        onPrimaryPress={updatePrefix}
        loading={isLoading}
      />
      <LoadingSpinner visible={isLoading} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  header: {
    color: MkiColors.primaryButton,
  },
});

export default HardwareSettingsScreen;
