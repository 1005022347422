import { I18n } from "@meraki/core/i18n";
import { useVlan } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { showAlert } from "~/lib/AlertUtils";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";

import { NetworkScreensPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "DeviceReservation">;

export const DeviceReservationScreen = ({ onPressSave }: Props) => {
  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);

  const [macAddress, setMacAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [reqPending, setReqPending] = useState(false);

  const { data: gxVlan } = useVlan({ networkId, vlanId });

  const navigation = useNavigation();

  const onSave = useCallback(async () => {
    setReqPending(true);

    try {
      await onPressSave(macAddress, deviceName, ipAddress);
      navigation.goBack();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [deviceName, ipAddress, macAddress, navigation, onPressSave]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} />,
    });
  }, [navigation, onSave]);

  // TODO: Consider making component for MkiText+UnderlinedTextInput
  // since they're commonly used together.
  return (
    <FullScreenContainerView withScroll defaultPadding>
      <MkiText textStyle="smallSecondary" screenStyles={styles.inputTitle}>
        {I18n.t("DEVICE_RESERVATIONS.DEVICE_NAME")}
      </MkiText>
      <UnderlinedTextInput
        name="deviceName"
        placeholder={I18n.t("DEVICE_RESERVATIONS.NAME_PLACEHOLDER")}
        value={deviceName}
        onChangeText={setDeviceName}
        returnKeyType={RETURN_KEY.done}
        editable
        showClearButton
      />
      <MkiText textStyle="smallSecondary" screenStyles={styles.inputTitle}>
        {I18n.t("DEVICE_RESERVATIONS.DEVICE_MAC_ADDRESS")}
      </MkiText>
      <UnderlinedTextInput
        name="macAddress"
        placeholder={I18n.t("DEVICE_RESERVATIONS.MAC_PLACEHOLDER")}
        value={macAddress}
        onChangeText={setMacAddress}
        returnKeyType={RETURN_KEY.done}
        editable
        showClearButton
      />
      <MkiText textStyle="smallSecondary" screenStyles={styles.inputTitle}>
        {I18n.t("LOCAL_ADDRESS_SPACE.IP.IP")}
      </MkiText>
      <IPAddressTextInput
        name="ipAddress"
        cidrAddress={gxVlan?.subnet}
        onAddressChange={setIpAddress}
      />
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  inputTitle: {
    marginTop: SPACING.large,
  },
});

export default DeviceReservationScreen;
