import { I18n } from "@meraki/core/i18n";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useOrgNetworks, usePeerOutageData, useVpnStatuses } from "@meraki/shared/api";
import { SingleBarGraph } from "@meraki/shared/components";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { formatOutageDataWithId, getPeerGraphDataGo } from "../utils/SiteToSiteUtils";

export function SiteToSiteVpnCard() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data: networks } = useOrgNetworks({ organizationId }, { enabled: !!organizationId });
  const { data: vpnStatuses, isLoading: vpnStatusIsLoading } = useVpnStatuses({ organizationId });

  let networkEid = "";
  const allNetworkEids: string[] = [];
  networks?.forEach((network) =>
    network.id === networkId
      ? (networkEid = network.eid ?? "")
      : allNetworkEids.push(network.eid ?? ""),
  );

  const { data: peerOutageData, isLoading: peerOutageDataIsLoading } = usePeerOutageData({
    networkEid,
    timespan,
    peerEids: allNetworkEids,
  });

  const vpnStatus = vpnStatuses?.find((vpn) => vpn.networkId === networkId);
  const peers = vpnStatus?.merakiVpnPeers;
  const onlinePeers = peers?.filter((vpn) => vpn.reachability === "reachable").length;
  const peersWithData = formatOutageDataWithId(peerOutageData);
  const combinedPeerGraphData = getPeerGraphDataGo(peersWithData, timespan);

  const statusName =
    onlinePeers === 0 ? "negative" : onlinePeers === peers?.length ? "positive" : "warning";

  if (vpnStatus === undefined) {
    return null;
  }

  return (
    <Card
      loading={vpnStatusIsLoading || peerOutageDataIsLoading}
      loadingContentHeight={60}
      onPress={() => navigation.navigate("SiteToSiteDetails")}
      testID="SITE_TO_SITE_VPN_CARD"
    >
      <Card.Header title={I18n.t("VPN_HEALTH_OVERVIEW.TITLE")} />
      {peers && peers?.length > 0 ? (
        <Card.Content flexDirection="row">
          <Card.Well flexDirection="row" gap="xs" flex={1}>
            <Box flexDirection="column">
              <Heading size="h4">{I18n.t("VPN_HEALTH_OVERVIEW.OVERALL")}</Heading>
              <Box flexDirection="row" gap="2xs" alignItems="center" justifyContent="center">
                <Heading size="h2">{`${onlinePeers}/${peers.length}`}</Heading>
                <Status status={statusName} />
              </Box>
            </Box>

            <Box flex={1} justifyContent="center">
              {peersWithData.length > 0 ? (
                <SingleBarGraph data={combinedPeerGraphData} />
              ) : (
                <Text color="light">{I18n.t("VPN_HEALTH_OVERVIEW.NO_DATA")}</Text>
              )}
            </Box>
          </Card.Well>
        </Card.Content>
      ) : (
        <Card.Content gap="xs">
          <Card.Well>
            <Text>{I18n.t("VPN_HEALTH_OVERVIEW.NO_DATA")}</Text>
          </Card.Well>
        </Card.Content>
      )}
    </Card>
  );
}
