import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Card, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { SwitchPortStatus, useClients, useDeviceStatuses } from "@meraki/shared/api";
import { DEVICE_STATUS_MAP } from "@meraki/shared/devices";
import { ClientGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { getStatusTranslations } from "../constants/Status";

interface SwitchPortDeviceClientCardProps {
  switchPortStatus: SwitchPortStatus;
}

export const SwitchPortDeviceClientCard = ({
  switchPortStatus,
}: SwitchPortDeviceClientCardProps) => {
  const deviceNavigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const clientNavigation = useNavigation<NativeStackNavigationProp<ClientGroupProps>>();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { data: device } = useDeviceStatuses(
    { organizationId },
    {
      select(data) {
        return data.find(
          (device) =>
            device.mac === switchPortStatus.lldp?.chassisId ||
            (device.lanIp && device.lanIp === switchPortStatus.cdp?.address),
        );
      },
      enabled: Boolean(switchPortStatus.lldp) || Boolean(switchPortStatus.cdp),
    },
  );
  const { data: client } = useClients(
    {
      networkId,
    },
    {
      select(data) {
        return data.find(
          (client) =>
            client.mac === switchPortStatus.lldp?.chassisId ||
            client.ip === switchPortStatus.cdp?.address,
        );
      },
      enabled: Boolean(switchPortStatus.lldp) || Boolean(switchPortStatus.cdp),
    },
  );

  if (!device && !client) {
    return null;
  }

  const deviceStatusIcon = DEVICE_STATUS_MAP[device?.status || "dormant"];
  const clientStatusIcon =
    client?.status === "Online"
      ? "positive"
      : client?.status === "Offline"
        ? "negative"
        : "neutral";

  const statusTranslations = getStatusTranslations();
  return (
    <Card
      loadingContentHeight={30}
      onPress={() =>
        device
          ? deviceNavigation.navigate("DeviceDetails", { serial: device.serial })
          : clientNavigation.navigate("ClientDetails", { id: client?.id ?? "" })
      }
      testID="SWITCH_PORT_DEVICE_CLIENT_CARD"
    >
      <Card.Header title={device ? "Device" : "Client"} />

      <Box flexDirection="row" gap="sm">
        <Card.Well flex={1}>
          {device ? (
            <>
              <Text size="p1" weight="bold">
                {device?.name || device?.serial}
              </Text>
              <Box flexDirection="row" gap="2xs" alignItems="center">
                <Text size="p2" weight="bold">
                  {statusTranslations[device?.status || "dormant"]}
                </Text>
                <Status status={deviceStatusIcon} testID={`STATUS_ICON.${deviceStatusIcon}`} />
              </Box>
            </>
          ) : (
            <>
              <Text size="p1" weight="bold">
                {client?.description || client?.mac}
              </Text>
              <Box flexDirection="row" gap="2xs" alignItems="center">
                <Text size="p2" weight="bold">
                  {client?.status || I18n.t("UNKNOWN")}
                </Text>
                <Status status={clientStatusIcon} testID={`STATUS_ICON.${clientStatusIcon}`} />
              </Box>
            </>
          )}
        </Card.Well>
      </Box>
    </Card>
  );
};
