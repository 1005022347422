import {
  AlertsStackProps,
  InsightsStackProps,
  MonitorStackProps,
} from "@meraki/go/navigation-type";
import { useOrgNetworks } from "@meraki/shared/api";
import { PushNotificationData, setupNotifications } from "@meraki/shared/notification";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const GO_SENDER_ID = "751936598021";

export const useSetupNotifications = () => {
  const navigation =
    useNavigation<
      NativeStackNavigationProp<MonitorStackProps & InsightsStackProps & AlertsStackProps>
    >();

  const organizationId = useCurrentOrganizationId();
  const { data: networks, isLoading } = useOrgNetworks({ organizationId });

  const notificationHandler = ({ event_type, ng_eid }: PushNotificationData) => {
    const network = networks?.find(({ ngIds }) =>
      Object.values(ngIds ?? {}).some(({ ngEid }) => ngEid === ng_eid),
    );

    switch (event_type) {
      case "weekly_presence":
        navigation.navigate("PresenceReport", { networkId: network?.id });
        break;
      case "client_connectivity":
        navigation.navigate("TrackedClients", { networkId: network?.id });
        break;
    }
  };

  return {
    isNetworkLoading: isLoading,
    setup: async (isManual = false) => {
      await setupNotifications(notificationHandler, GO_SENDER_ID, isManual);
    },
  };
};
