import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export enum UmbrellaPolicyNames {
  securityOnly = "Security Filtering Only",
  basicContentOnly = "Basic Appropriate Use Filtering",
  moderateContentOnly = "Moderate Appropriate Use Filtering",
  fullContentOnly = "Full Appropriate Use Filtering",
  securityAndBasicContent = "Security & Basic Appropriate Use Filtering",
  securityAndModerateContent = "Security & Moderate Appropriate Use Filtering",
  securityAndFullContent = "Security & Full Appropriate Use Filtering",
}

export const UmbrellaPolicyNameSchema = z.union([
  z.literal(UmbrellaPolicyNames.securityOnly),
  z.literal(UmbrellaPolicyNames.basicContentOnly),
  z.literal(UmbrellaPolicyNames.moderateContentOnly),
  z.literal(UmbrellaPolicyNames.fullContentOnly),
  z.literal(UmbrellaPolicyNames.securityAndBasicContent),
  z.literal(UmbrellaPolicyNames.securityAndModerateContent),
  z.literal(UmbrellaPolicyNames.securityAndFullContent),
]);

export const UmbrellaPolicySchema = z.object({
  name: UmbrellaPolicyNameSchema,
  priority: z.number(),
  id: z.number(),
  default: z.boolean(),
  createdAt: z.string(),
  organizationId: z.number(),
});

export const UmbrellaPoliciesSchema = z.array(UmbrellaPolicySchema);

export type UmbrellaPolicies = z.infer<typeof UmbrellaPoliciesSchema>;
export type UmbrellaPolicy = z.infer<typeof UmbrellaPolicySchema>;

interface UmbrellaPoliciesRequest {
  organizationId?: string;
}

const buildUrl = ({ organizationId }: UmbrellaPoliciesRequest) => {
  return `/api/v1/organizations/${organizationId}/umbrella/policies`;
};

const fetchUmbrellaPolicies = ({ organizationId }: UmbrellaPoliciesRequest) => {
  return request(UmbrellaPoliciesSchema, "GET", buildUrl({ organizationId }));
};

export const useUmbrellaPolicies = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: UmbrellaPoliciesRequest) => fetchUmbrellaPolicies(parameters),
  requiredVariables: ["organizationId"],
});
