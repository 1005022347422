import { I18n } from "@meraki/core/i18n";
import { Button, List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { FailedConnectionGroupProps } from "@meraki/shared/navigation-type";
import { ParamListBase, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { format } from "date-fns-tz";
import { useEffect } from "react";

export function FailedWirelessConnectionDetailsScreen() {
  // update ParamListBase to appropriate prop type when it's ready
  const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>();
  const { params: props } =
    useRoute<RouteProp<FailedConnectionGroupProps, "FailedWirelessConnection">>();
  const { failedConnection } = props;

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => <Button.Icon icon={"ArrowLeft"} onPress={() => navigation.goBack()} />,
        headerShadowVisible: false,
      }),
    [navigation],
  );

  return (
    <Screen addDefaultPadding>
      <List>
        <List.Item
          title={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.CLIENT")}
          value={failedConnection.clientName}
          onPress={
            failedConnection.id
              ? () =>
                  navigation.navigate("ClientDetails", {
                    id: failedConnection.id,
                    initialPage: 1,
                  })
              : null
          }
        />
        <List.Item
          title={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.AP")}
          value={[failedConnection.apName, failedConnection.apMac]}
          onPress={
            failedConnection.serial
              ? () =>
                  navigation.navigate("DeviceDetails", {
                    serial: failedConnection.serial,
                  })
              : null
          }
        />
        <List.Item
          title={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.SSID")}
          value={failedConnection.ssidName}
        />
        <List.Item
          title={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.REASON")}
          value={failedConnection.type}
          description={failedConnection.failureStep}
        />
        <List.Item
          title={I18n.t("NETWORK_OVERVIEW.WIRELESS_HEALTH.FAILED_CONNECTIONS.LABELS.TIMESTAMP")}
          value={format(Date.parse(failedConnection.ts), "MMM d, yyyy h:mm a")}
        />
      </List>
    </Screen>
  );
}
