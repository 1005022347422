import { DEVICE_CLIENTS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

import { DisplayableClient } from "~/shared/types/Client";

const initialState: Record<string, DisplayableClient> = {};

export default function deviceClients(state = initialState, action: any) {
  switch (action.type) {
    case DEVICE_CLIENTS_SUCCESS:
      return {
        ...state,
        [action.meta.id]: action.response,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
