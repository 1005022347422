import { Box } from "@meraki/magnetic/layout";
import { ColorToken, getColorFromTokenName, useMagneticTheme } from "@meraki/magnetic/themes";
import type { IconProps as PhosphorIconProps } from "phosphor-react-native";
import * as PhosphorIcons from "phosphor-react-native";

export type IconName = Exclude<keyof typeof PhosphorIcons, "IconContext">;
export type IconProps = Omit<PhosphorIconProps, "color" | "weight"> & {
  name: IconName;
  color?: ColorToken;
};
export type IconCleanColorProps = Omit<IconProps, "color"> & {
  color?: ColorToken;
  testID?: string;
};

export function Icon({
  name,
  color = "interact.icon.weak.base",
  testID,
  ...rest
}: IconCleanColorProps) {
  const theme = useMagneticTheme();
  const IconComponent = PhosphorIcons[name];

  return (
    <Box testID={testID}>
      <IconComponent {...rest} weight="bold" color={getColorFromTokenName(color, theme)} />
    </Box>
  );
}
