import { useCallback } from "react";

import { isWeb } from "~/lib/PlatformUtils";
import { isString } from "~/lib/TypeHelper";
import Clipboard from "~/shared/lib/clipboard";
import TwoColumnRow, { TwoColumnRowProps } from "~/shared/rows/TwoColumnRow";

const CopyableTwoColumnRow: React.FC<TwoColumnRowProps> = (props) => {
  const { value, onLongPress } = props;
  const handleLongPress = useCallback(
    (event: any) => {
      if (isString(value)) {
        Clipboard.mkiSetString(value);
      }
      if (typeof onLongPress === "function") {
        onLongPress(event);
      }
    },
    [onLongPress, value],
  );
  return <TwoColumnRow {...props} onLongPress={handleLongPress} />;
};

export default isWeb() ? TwoColumnRow : CopyableTwoColumnRow;
