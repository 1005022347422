// These are all environmental events which are not the same as sensor related network events
// See manage codebase EnvironmentalEventLog class for more context: lib/environmental_event_log.rb

import { TemperatureUnits } from "~/shared/constants/SensorMetrics";
interface SensorEventData {
  cutoff?: string;
  reason?: string;
  rssi?: string;
  state?: string;
  value?: string;
  version?: string;
}

interface SensorExtraData {
  alert_config_name?: string;
  direction?: string;
  gateway_id?: string;
  gateway_name?: string;
  gateway_ng_eid?: string;
  gateway_ng_name?: string;
  is_alerting?: string;
  sensor_id?: string;
  sensor_name?: string;
  threshold?: string;
  unit?: keyof typeof TemperatureUnits;
}

export enum SensorEventType {
  Door = "mt_door",
  DoorTamper = "mt_door_tamper",
  Humidity = "mt_humidity",
  Temperature = "mt_temperature",
  // Note: There aren't mt_temperature_cable_connection events yet
  WaterCableConnection = "mt_water_cable_connection",
  WaterDetection = "mt_water_detection",
  AmbientNoise = "mt_ambient_noise",
  Co2 = "mt_real_co2",
  IaqIndex = "mt_iaq_index",
  Pm25 = "mt_pm25",
  Tvoc = "mt_tvoc",
  ButtonRelease = "mt_button_release",
  RealPower = "mt_mains_real_power",
  Current = "mt_mains_current_rms",
  Frequency = "mt_mains_frequency",
  PowerFactor = "mt_mains_inst_power_factor",
  ApparentPower = "mt_mains_apparent_power",
  Voltage = "mt_mains_voltage_rms",
}

export interface SensorEvent {
  eventData: SensorEventData;
  extraData: SensorExtraData;
  gatewaySerial: string;
  networkId: string;
  occurredAt: string;
  sensorSerial: string;
  type: SensorEventType;
}

export enum ButtonPressEventValue {
  Short = "0.0",
  Long = "1.0",
}
