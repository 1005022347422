import Svg, { Path } from "react-native-svg";

function MagneticWirelessIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M9.41688 16.5116C10.9831 15.4948 13.017 15.4948 14.5831 16.5116C15.0464 16.8124 15.0422 17.4568 14.6517 17.8474L12.7071 19.7919C12.3166 20.1825 11.6834 20.1825 11.2929 19.7919L9.34836 17.8474C8.95784 17.4568 8.95366 16.8124 9.41688 16.5116Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4801 15.0189C17.0896 15.4095 16.4601 15.4021 16.0166 15.073C13.6381 13.3077 10.362 13.3077 7.98347 15.073C7.53998 15.4021 6.91048 15.4095 6.51995 15.0189L5.45929 13.9583C5.06877 13.5678 5.06611 12.93 5.49241 12.5789C9.26404 9.4724 14.736 9.4724 18.5077 12.5789C18.934 12.93 18.9313 13.5678 18.5408 13.9583L17.4801 15.0189Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.369 11.1299C20.9785 11.5204 20.3472 11.5163 19.9265 11.1584C15.3655 7.27922 8.63413 7.27922 4.07315 11.1584C3.65245 11.5163 3.02118 11.5204 2.63066 11.1299L1.57 10.0692C1.17947 9.67867 1.17804 9.04228 1.59223 8.67694C7.52974 3.43972 16.4699 3.43972 22.4074 8.67694C22.8216 9.04228 22.8202 9.67867 22.4296 10.0692L21.369 11.1299Z"
        fill="#8F8F8F"
      />
    </Svg>
  );
}

export default MagneticWirelessIcon;
