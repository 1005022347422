import LiveBroker, { Callback, Subscription } from "./LiveBroker";
import Broker from "./types/Broker";

enum SubscriptionChannel {
  device = "node",
  network = "node_group",
}

function buildChannel(...params: (string | number)[]): string {
  return `/${params.filter(Boolean).join("/")}`;
}

interface BaseSubscriptionProps {
  type: Broker;
}

export interface DeviceSubscriptionProps extends BaseSubscriptionProps {
  deviceId: string;
}

export const deviceSubscribe = (
  props: DeviceSubscriptionProps,
  callback: Callback,
): Promise<Subscription> => {
  const { deviceId, type } = props;

  const channel = buildChannel(SubscriptionChannel.device, deviceId, type);

  const request = { ...props, node_id: deviceId, type };
  return LiveBroker.subscribe(request, channel, callback);
};

export interface NetworkSubscriptionProps extends BaseSubscriptionProps {
  networkId: string;
  clientMac?: string;
}

export const networkSubscribe = (
  props: NetworkSubscriptionProps,
  callback: Callback,
): Promise<Subscription> => {
  const { networkId, clientMac, type } = props;

  const channel = buildChannel(
    SubscriptionChannel.network,
    networkId,
    clientMac ? `client/${clientMac}` : "",
    type,
  );

  const request = { ng_id: networkId, client: clientMac, type };
  return LiveBroker.subscribe(request, channel, callback);
};
