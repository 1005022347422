import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import {
  addSerialHyphens,
  SERIAL_LENGTH,
  validateDeviceSerial,
  validateDeviceSerialCharacters,
} from "@meraki/shared/devices";
import { isEmpty } from "lodash";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { AddHardwareScreensPropMap } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import { SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";
import withLicense, { LicenseProps } from "~/hocs/LicenseData";
import withOnboardingStatus, { OnboardingDataProps } from "~/hocs/OnboardingData";
import withTopologyNodes, { TopologyNodesProps } from "~/hocs/TopologyNodeData";
import { showAlert } from "~/lib/AlertUtils";
import { deviceName, isApplianceBySerial, isDeviceNameCharactersValid } from "~/lib/DeviceUtils";
import { analytics } from "~/lib/FirebaseModules";
import { sendGXFirebaseSwapEvent } from "~/lib/FirebaseUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { getAutoclaimableSerials } from "~/lib/TopologyUtils";
import { getUpdatedDefaultVLANPayload } from "~/lib/VlanUtils";
import {
  currentNetworkState,
  devicesState,
  getAllSiteToSiteSettings,
  getDeviceNames,
  getOnboardingNodes,
  gxDeviceSelector,
  hasGSDevices,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { ExitButton } from "~/shared/navigation/Buttons";
import Device, { DevicesBySerial } from "~/shared/types/Device";
import { ActiveStatuses } from "~/shared/types/License";
import {
  BatchOfDevices,
  OnboardingNodes,
  OnboardingNodeStatus,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  networkId: string;
  nodes: DevicesBySerial;
  gxNode?: Device;
  hasAnyGS: boolean;
  onboardingNodes: OnboardingNodes;
  deviceNames: string[];
  orgSiteToSiteSettings: SiteToSiteSettings[];
};

type Props = ForwardedNativeStackScreenProps<AddHardwareScreensPropMap, "AddHardware"> &
  ReduxProps &
  BasicActions &
  TopologyNodesProps &
  LicenseProps &
  OnboardingDataProps &
  BaseOnboardingScreenProps;

interface DeviceToClaim {
  serial: string;
  name: string;
}
interface InputFocused {
  serial: boolean;
  name: boolean;
}

interface InputAlert {
  serial: string | undefined | null;
  name: string | undefined | null;
}
interface AddHardwareScreenState {
  reqPending: boolean;
  inAutoClaimProcess: boolean;
  batchOfDevices: DeviceToClaim[];
  focused: InputFocused[];
  alerts: InputAlert[];
}

export class AddHardwareScreen extends BaseOnboardingScreen<Props, AddHardwareScreenState> {
  defaultState = {
    reqPending: false,
    inAutoClaimProcess: false,
    focused: [],
    batchOfDevices: [],
    alerts: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = { ...this.defaultState };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.fetchAllSiteToSiteVPNSettings();
    actions.setOnboardingStage(OnboardingStage.addHardware);
  }

  componentDidUpdate() {
    const { topologyNodes } = this.props;
    const { alerts, focused, batchOfDevices, inAutoClaimProcess } = this.state;

    if (inAutoClaimProcess && topologyNodes && !isEmpty(topologyNodes)) {
      const unclaimedSerials = getAutoclaimableSerials(topologyNodes);
      const newDevices: DeviceToClaim[] = [];
      const newFocused: InputFocused[] = [];
      const newAlerts: InputAlert[] = [];

      for (const serial of unclaimedSerials) {
        newDevices.push({ serial, name: "" });
        newFocused.push({ serial: false, name: false });
        newAlerts.push({ serial: undefined, name: undefined });
      }

      if (unclaimedSerials.length > 0) {
        this.setState({
          batchOfDevices: [...batchOfDevices, ...newDevices],
          focused: [...focused, ...newFocused],
          alerts: [...alerts, ...newAlerts],
          inAutoClaimProcess: false,
        });
      } else {
        this.setState({ inAutoClaimProcess: false });

        showAlert(
          I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.AUTO_CLAIM.NO_NEW_HARDWARES.TITLE"),
          I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.AUTO_CLAIM.NO_NEW_HARDWARES.MESSAGE"),
        );
      }
    }
  }

  alreadyScanned(newSerial: string) {
    const { batchOfDevices } = this.state;
    return Object.values(batchOfDevices).some(({ serial }) => serial === newSerial);
  }

  sendFirebaseResult = () => {
    analytics.logEvent("hardware_claimed_typed");
  };

  hasAddedHardware = () => {
    const { onboardingNodes } = this.props;
    return !isEmpty(onboardingNodes);
  };

  isHardwareConnected = () => {
    const { status } = this.props;
    return status === OnboardingNodeStatus.finished;
  };

  onClosePress = () => {
    const { isOnboarding } = this.props;

    if (this.hasAddedHardware()) {
      if (this.isHardwareConnected()) {
        this.close();
      } else {
        showAlert(
          I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.HARDWARE_NOT_CONNECTED.TITLE"),
          I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.HARDWARE_NOT_CONNECTED.MESSAGE"),
          () => this.onPrimaryPress(),
          {
            negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
            onNegativePress: this.close,
            positiveText: I18n.t(
              "ONBOARDING_FULLSTACK.EXIT_ALERTS.HARDWARE_NOT_CONNECTED.CANCEL_BUTTON",
            ),
          },
        );
      }
    } else if (isOnboarding) {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.MESSAGE"),
        undefined,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.CANCEL_BUTTON"),
        },
      );
    } else {
      this.close();
    }
  };

  validateDeviceName = (name: string) => {
    const { deviceNames } = this.props;
    return deviceNames.every((deviceName) => deviceName?.toLowerCase() !== name?.toLowerCase());
  };

  onSerialChange = (newSerial: string, index: number) => {
    const { alerts, batchOfDevices } = this.state;
    const oldSerial = batchOfDevices[index]?.serial ?? "";

    let alert: string | undefined | null;
    if (newSerial.length > 0) {
      if (newSerial.length > oldSerial.length) {
        newSerial = addSerialHyphens(newSerial).toUpperCase();
      }

      alert = validateDeviceSerialCharacters(newSerial);
      if (alert != null && !validateDeviceSerial(newSerial)) {
        alert = I18n.t("DEVICES.REGEX_ERROR_TEXT");
      }
    }

    const newBatchOfDevice = [...batchOfDevices];
    newBatchOfDevice[index] = {
      ...batchOfDevices[index],
      serial: newSerial,
    };

    const newAlerts = [...alerts];
    newAlerts[index] = {
      ...alerts[index],
      serial: alert,
    };

    this.setState({
      batchOfDevices: newBatchOfDevice,
      alerts: newAlerts,
    });
  };

  onNameChange = (name: string, index: number) => {
    const { alerts, batchOfDevices } = this.state;

    let alert: string | undefined;
    if (name.length > 0) {
      if (!isDeviceNameCharactersValid(name)) {
        alert = I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NAME_HARDWARE.INVALID_FORMAT");
      }

      if (!this.validateDeviceName(name)) {
        alert = I18n.t("DEVICES.DUPLICATE_NAME_TEXT");
      }
    }

    const newBatchOfDevice = [...batchOfDevices];
    newBatchOfDevice[index] = {
      ...batchOfDevices[index],
      name,
    };

    const newAlerts = [...alerts];
    newAlerts[index] = {
      ...alerts[index],
      name: alert,
    };

    this.setState({
      batchOfDevices: newBatchOfDevice,
      alerts: newAlerts,
    });
  };

  onFocus = (isFocused: boolean, index: number, targetInput: string) => {
    const { focused } = this.state;
    const newFocused = [...focused];
    newFocused[index] = {
      ...focused[index],
      [targetInput]: isFocused,
    };

    this.setState({ focused: newFocused });
  };

  startAutoClaimProccess = () => {
    this.setState({ reqPending: true });
    try {
      this.setState({ inAutoClaimProcess: true });
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    } finally {
      this.setState({ reqPending: false });
    }
  };

  onAddButtonPress = () => {
    const { batchOfDevices, focused, alerts } = this.state;
    this.setState({
      batchOfDevices: [...batchOfDevices, { serial: "", name: "" }],
      focused: [...focused, { serial: false, name: false }],
      alerts: [...alerts, { serial: undefined, name: undefined }],
    });
  };

  onDeleteButtonPress = (index: number) => {
    const { batchOfDevices, focused, alerts } = this.state;
    batchOfDevices.splice(index, 1);
    focused.splice(index, 1);
    alerts.splice(index, 1);

    this.setState({
      batchOfDevices: [...batchOfDevices],
      focused: [...focused],
      alerts: [...alerts],
    });
  };

  renderBody = () => {
    const { reqPending, batchOfDevices, focused, alerts } = this.state;
    const single_device_word = I18n.t("HARDWARE_WORD");

    const rows = batchOfDevices.map((batchDevice, rowId) => (
      <View key={`HARDWARE_ROW_${rowId}`} style={styles.inputRow}>
        <View style={styles.inputContainer}>
          <View
            style={[
              styles.input,
              focused[rowId]?.serial ? styles.inputFocused : styles.inputNotFucused,
            ]}
          >
            <MkiTextInput
              testID={`HARDWARE_ROW_${rowId}.SERIAL`}
              value={batchDevice.serial}
              onFocus={() => this.onFocus(true, rowId, "serial")}
              onBlur={() => this.onFocus(false, rowId, "serial")}
              onEndEditing={() => this.onFocus(false, rowId, "serial")}
              onChangeText={(newSerial: any) => this.onSerialChange(newSerial, rowId)}
              autoCorrect={false}
              multiline={false}
              maxLength={SERIAL_LENGTH}
              placeholder={I18n.t("DASHES_INSTRUCTION")}
            />
          </View>
          <View
            style={[
              styles.input,
              focused[rowId]?.name ? styles.inputFocused : styles.inputNotFucused,
            ]}
          >
            <MkiTextInput
              testID={`HARDWARE_ROW_${rowId}.NAME`}
              value={batchDevice.name}
              onFocus={() => this.onFocus(true, rowId, "name")}
              onBlur={() => this.onFocus(false, rowId, "name")}
              onEndEditing={() => this.onFocus(false, rowId, "name")}
              onChangeText={(newName: any) => this.onNameChange(newName, rowId)}
              autoCorrect={false}
              multiline={false}
              placeholder={I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NAME_HARDWARE.MESSAGE", {
                device_type: single_device_word,
              })}
            />
          </View>
          <View style={styles.deleteButton}>
            <DeleteButton
              show={true}
              onPress={() => this.onDeleteButtonPress(rowId)}
              testID={`HARDWARE_ROW_${rowId}.DELETE_BUTTON`}
            />
          </View>
        </View>
        <MkiText textStyle="error" screenStyles={styles.error}>
          {Object.values(alerts[rowId]).join("\n")}
        </MkiText>
      </View>
    ));

    return (
      <FullScreenContainerView withScroll testID="ADD_HARDWARE_SCREEN">
        <View style={styles.textContainer}>
          <MkiText textStyle="heading">
            {I18n.t("ENTER_SERIAL_TEXT", { single_device_word })}
          </MkiText>
          <MkiText textStyle="secondary">{I18n.t("SERIAL_LOCATION_INSTRUCTION")}</MkiText>
        </View>
        {rows}
        <View style={styles.addMore}>
          <RoundedButton
            onPress={this.onAddButtonPress}
            buttonType={ButtonType.tertiary}
            textStyles={styles.error}
          >
            {`+ ${I18n.t("ADD_DEVICES.BUTTON", { single_device_word })}`}
          </RoundedButton>
        </View>
        <LoadingSpinner visible={reqPending} />
      </FullScreenContainerView>
    );
  };

  updateVlanInfo = () => {
    const { actions, orgSiteToSiteSettings } = this.props;

    //updates default wired vlan
    const updatedDefaultVlanPayload = getUpdatedDefaultVLANPayload(orgSiteToSiteSettings);
    actions.updateVlan(1, updatedDefaultVlanPayload);

    return;
  };

  handleNextButton = async () => {
    const { actions, gxNode, license, networkId } = this.props;
    const { batchOfDevices } = this.state;

    if (!isEmpty(batchOfDevices)) {
      this.setState({ reqPending: true });

      let gxSerial: any;
      const processableBatch: BatchOfDevices = batchOfDevices.reduce((result, { serial, name }) => {
        if (gxSerial == null && isApplianceBySerial(serial)) {
          gxSerial = serial;
        }

        return {
          ...result,
          [serial]: { name },
        };
      }, {});

      try {
        // Ask user to remove old GX
        if (gxSerial != null && gxNode != null) {
          await new Promise((resolve, reject) => {
            showAlert(
              I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NEW_GX.TITLE"),
              I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NEW_GX.MESSAGE"),
              () => actions.removeDevice(gxNode.serial).then(resolve).catch(reject),
              {
                positiveText: I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NEW_GX.CONFIRM", {
                  device_name: deviceName(gxNode),
                }),
                negativeText: I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.NEW_GX.CANCEL"),
                onNegativePress: () => reject(),
              },
            );
          });
        }

        await actions.batchClaimDevices(processableBatch, networkId);

        if (gxSerial != null) {
          if (gxNode == null) {
            this.updateVlanInfo();
          } else {
            sendGXFirebaseSwapEvent(gxNode.serial, gxSerial);
          }

          // Move the burning license to the new GX
          if (license != null && ActiveStatuses.includes(license.state)) {
            await actions.reassignLicense(license.id, gxSerial);
          }
        }

        this.setState({ ...this.defaultState });
        this.onPrimaryPress();
      } catch (error) {
        if (error != null) {
          showAlert(I18n.t("ERROR"), error);
        }
      } finally {
        this.setState({ reqPending: false });
      }
    } else {
      this.setState({ inAutoClaimProcess: false });
      this.onPrimaryPress();
    }
  };

  getFooterData = () => {
    const { hasAnyGS } = this.props;
    const { alerts } = this.state;
    const allAlerts = alerts.flatMap(Object.values).filter(Boolean);

    const data: React.ReactNode[] = [
      <RoundedButton
        testID="ADD_HARDWARE.NEXT_BUTTON"
        key="nextButton"
        disabled={!isEmpty(allAlerts)}
        onPress={this.handleNextButton}
      >
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    ];

    if (hasAnyGS) {
      data.unshift(
        <RoundedButton
          testID="ADD_HARDWARE.AUTO_CLAIM_BUTTON"
          key="autoClaimButton"
          onPress={this.startAutoClaimProccess}
        >
          {I18n.t("ONBOARDING_FULLSTACK.ADD_HARDWARE.AUTO_CLAIM.BUTTON_TITLE")}
        </RoundedButton>,
      );
    }

    return { buttons: data };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.addHardware);
}

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: SPACING.default,
    paddingTop: SPACING.large,
    paddingBottom: SPACING.extraExtraLarge,
  },
  inputRow: {
    paddingHorizontal: SPACING.large,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteButton: {
    margin: SPACING.small,
  },
  input: {
    flex: 1,
    borderBottomWidth: 1,
    marginHorizontal: SPACING.small,
  },
  inputNotFucused: {
    borderBottomColor: MkiColors.inactiveInputUnderline,
  },
  inputFocused: {
    borderBottomColor: MkiColors.activeInputUnderline,
  },
  error: {
    paddingTop: SPACING.small,
    paddingHorizontal: SPACING.small,
  },
  addMore: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  loadingContainer: {
    position: "absolute",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for AddHardwareScreen",
      currentNetworkState(state),
    ),
    onboardingNodes: getOnboardingNodes(state),
    nodes: devicesState(state),
    gxNode: gxDeviceSelector(state),
    hasAnyGS: hasGSDevices(state),
    deviceNames: getDeviceNames(state),
    orgSiteToSiteSettings: getAllSiteToSiteSettings(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
  withTopologyNodes,
  withLicense,
)(AddHardwareScreen);
