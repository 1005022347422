import { omit } from "lodash";
import { createSelector } from "reselect";

import Organization from "~/api/models/Organization";
import { INVENTORY_SEARCH_FIELDS, INVENTORY_SEARCH_KEY } from "~/constants/SearchKeys";
import I18n from "~/i18n/i18n";
import NetworkUtils from "~/lib/NetworkUtils";
import { filterData } from "~/lib/SearchUtils";
import {
  currentNetworkState,
  getCurrentOrganization,
  getLatestSupportCaseSubmitTime,
  getSSOAccessOrg,
  groupPoliciesState,
  ssidsState,
} from "~/selectors/getters";
import { getOrgsById } from "~/selectors/orgs";
import { searchTextState } from "~/selectors/search";
import { RootState } from "~/shared/types/Redux";

const emptyOrg: Partial<Organization> = {};
export const currentOrganization = createSelector(
  getCurrentOrganization,
  getOrgsById,
  getSSOAccessOrg,
  (organizationId, organizations, ssoAccessOrg) => {
    if (ssoAccessOrg != null) {
      return ssoAccessOrg;
    }

    if (organizationId == null) {
      return emptyOrg;
    }

    return organizations?.[organizationId] ?? emptyOrg;
  },
);

export const currentOrganizationName = createSelector(
  currentOrganization,
  (currentOrganization) => currentOrganization?.name || I18n.t("CURRENT_LOCATION"),
);

export const parsedNetworkState = createSelector(currentNetworkState, (network) =>
  NetworkUtils.parseId(network),
);

export const networkGroupPolicies = createSelector(
  currentNetworkState,
  groupPoliciesState,
  //@ts-ignore
  (networkId, groupPolicies) => {
    if (networkId) {
      return omit(groupPolicies[networkId], ["network_id"]);
    }
  },
);

const unusedInventoryState = (state: RootState) => {
  const orgId = getCurrentOrganization(state);
  if (orgId) {
    return state?.entities?.inventory?.[orgId]?.unused || [];
  }
};

export const filteredUnusedInventorySelector = createSelector(
  unusedInventoryState,
  searchTextState,
  (inventory, searchText) =>
    filterData(inventory, INVENTORY_SEARCH_FIELDS, searchText(INVENTORY_SEARCH_KEY)),
);

const emptySSIDs: any = [];
export const ssidsForCurrentNetwork = createSelector(
  ssidsState,
  currentNetworkState,
  (ssids, networkId) => {
    return (networkId ? ssids?.[networkId]?.wireless : undefined) || emptySSIDs;
  },
);

export const latestSupportSubmitDate = createSelector(
  getLatestSupportCaseSubmitTime,
  (time?: number): Date | undefined => (!!time ? new Date(time) : undefined),
);
