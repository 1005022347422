import {
  SENSOR_ALERT_PROFILES_FAILURE,
  SENSOR_ALERT_PROFILES_REQUEST,
  SENSOR_ALERT_PROFILES_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { AnyAction } from "redux";

import { WipeReduxAction } from "~/shared/types/Redux";
import { AlertProfile } from "~/shared/types/SensorAlertProfile";

interface SensorAlertProfileRequestAction extends AnyAction {
  type: typeof SENSOR_ALERT_PROFILES_REQUEST;
}

interface SensorAlertProfileSuccessAction extends AnyAction {
  type: typeof SENSOR_ALERT_PROFILES_SUCCESS;
  response: AlertProfile[];
}

interface SensorAlertProfileFailureAction extends AnyAction {
  type: typeof SENSOR_ALERT_PROFILES_FAILURE;
}

type SensorAlertProfileActions =
  | SensorAlertProfileRequestAction
  | SensorAlertProfileSuccessAction
  | SensorAlertProfileFailureAction
  | WipeReduxAction;

const IDLE_STATUS = "idle";
const LOADING_STATUS = "loading";
const SUCCESS_STATUS = "success";
const FAILURE_STATUS = "failure";

interface SensorAlertProfilesState {
  alertProfiles: { [alertProfileId: string]: AlertProfile } | null;
  status:
    | typeof IDLE_STATUS
    | typeof LOADING_STATUS
    | typeof SUCCESS_STATUS
    | typeof FAILURE_STATUS;
}

const initialState: SensorAlertProfilesState = { alertProfiles: null, status: IDLE_STATUS };

const sensorAlertProfilesReducer = (
  state: SensorAlertProfilesState = initialState,
  action: SensorAlertProfileActions,
): SensorAlertProfilesState => {
  switch (action.type) {
    case SENSOR_ALERT_PROFILES_REQUEST: {
      return { ...state, status: LOADING_STATUS };
    }
    case SENSOR_ALERT_PROFILES_SUCCESS: {
      const nextProfiles = action.response.reduce((nextProfiles: any, profile) => {
        nextProfiles[profile.id] = profile;
        return nextProfiles;
      }, {});
      return { ...state, alertProfiles: nextProfiles, status: SUCCESS_STATUS };
    }
    case SENSOR_ALERT_PROFILES_FAILURE: {
      return { ...state, status: FAILURE_STATUS };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default:
      return state;
  }
};

export default sensorAlertProfilesReducer;
