import { ProductType } from "@meraki/shared/api";

export const PRODUCT_TYPES: ProductType[] = [
  "appliance",
  "switch",
  "wireless",
  "camera",
  "sensor",
  "cellularGateway",
];

export type NetworkStatus = "online" | "offline" | "alerting" | "dormant";

export const NETWORK_STATUSES: NetworkStatus[] = ["online", "offline", "alerting", "dormant"];
