export enum Agg {
  Avg = "avg",
  Max = "max",
  Min = "min",
}

// Let's deprecate this in favor of union type SensorMetric in libs/monolith/src/api/schemas/SensorReading.ts
export const SensorMetrics = {
  battery: "battery",
  temperature: "temperature",
  humidity: "humidity",
  waterDetection: "water_detection",
  door: "door",
  eco2: "eco2",
  co2: "co2",
  tvoc: "tvoc",
  pm25: "pm25",
  ambientNoise: "ambient_noise",
  iaqIndex: "iaq_index",
  buttonRelease: "button_release",
  frequency: "frequency",
  voltage: "voltage",
  current: "current",
  realPower: "realPower",
  apparentPower: "apparentPower",
  powerFactor: "powerFactor",
  energyUsage: "energy",
  downstreamPower: "downstreamPower",
  remoteLockoutSwitch: "remoteLockoutSwitch",
} as const;

type SensorMetricsKeys = keyof typeof SensorMetrics;
// Union of object values: https://stackoverflow.com/a/72463958
export type SensorMetricsType = (typeof SensorMetrics)[SensorMetricsKeys];

export const TemperatureUnits = {
  fahrenheit: "fahrenheit",
  celsius: "celsius",
} as const;

export type TemperatureUnits = (typeof TemperatureUnits)[keyof typeof TemperatureUnits];

export type SensorReadingUnits = TemperatureUnits;

export const TEMPERATURE_ABBREV_LABEL = "°";
// If these characters look funky in your text editor font, know they look
// good in Ciscosans in the app.
export const FAHRENHEIT_LABEL = "℉";
export const CELSIUS_LABEL = "℃";
export const HUMIDITY_ABBREV_LABEL = "%";
export const NO_UNIT_LABEL = "";
export const NO_VALUE_LABEL = "--";
export const NO_READING_LABEL = "---";
export const TVOC_UNIT_LABEL = "μg/m³";
export const AMBIENT_NOISE_UNIT_LABEL = "dBA";
export const PM25_UNIT_LABEL = "μg/m³";
export const FREQUENCY_LABEL = "Hz";
export const VOLTAGE_LABEL = "V";
export const CURRENT_LABEL = "A";
export const REAL_POWER_LABEL = "W";
export const APPARENT_POWER_LABEL = "VA";
export const ENERGY_USAGE = "kWh";
export const POWER_FACTOR_LABEL = "%";
export const CO2_UNIT_LABEL = "ppm";

export enum WaterDetectionValues {
  wet = 1,
  dry = 0,
}
export const WATER_DETECTION_WET_LABEL = "Water detected";
export const WATER_DETECTION_DRY_LABEL = "All clear";

export enum DoorValues {
  open = 1,
  closed = 0,
}

export const DOOR_OPEN_LABEL = "Open";
export const DOOR_CLOSED_LABEL = "Closed";
