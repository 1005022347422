import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper/lib/typescript/types";

import PortConnectedDevices from "~/go/components/port/PortConnectedDevices";
import { PokeDeviceStackProps } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { CloseButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<PokeDeviceStackProps, "PokeDeviceSelection">;

class PokeDeviceSelectionScreen extends PureComponent<Props> {
  static defaultProps = {
    lldpMac: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  navigate = (_screen: unknown, params: { name: string; ip: string }) => {
    const { navigation, device, portNumber } = this.props;
    const { ip, name } = params;
    if (!ip) {
      showAlert(I18n.t("WARNING"), I18n.t("POKE_DEVICE.MISSING_IP"));
    }
    navigation.navigate("PokeDevice", {
      host: { name },
      device,
      portNumber,
    });
  };

  render() {
    const { portNumber } = this.props;
    return (
      <PortConnectedDevices
        isConnected
        heading={I18n.t("POKE_DEVICE.CHOOSE.MESSAGE", { port_number: portNumber })}
        navigate={this.navigate}
        {...this.props}
      />
    );
  }
}

export default PokeDeviceSelectionScreen;
