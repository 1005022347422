import { AppliancePort, createEditableAppliancePort } from "@meraki/shared/api";
import { useMemo } from "react";

import useObjectUpdater from "~/shared/hooks/useObjectUpdater";

export default function useEditAppliancePort(appliancePort: AppliancePort) {
  const editableAppliancePort = useMemo(
    () => createEditableAppliancePort(appliancePort),
    [appliancePort],
  );
  return useObjectUpdater(editableAppliancePort);
}
