import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { Alert, AlertProps, Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import React, { ReactNode } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";
import { Text as RNText } from "react-native";

import { Text } from "../Text/Text";

type TextWrapperProps = {
  onLinkPress: () => void;
  linkText: string;
};

function TextWrapper({ linkText, onLinkPress }: TextWrapperProps) {
  const theme = useMagneticTheme();
  return (
    <RNText style={{ color: theme.color.interact.text.base }} onPress={onLinkPress}>
      {linkText}
    </RNText>
  );
}

// Shh.... nothing to see here!
// For real though... Text complains if you pass anything other then a string or another Text component to it.
// This is for a really good reason... to drive consistency and not allow us to deviate from Magnetic as much.
TextWrapper.magneticTextWorkaroundMagic = true;

type BaseInlineNotificationProps = {
  status?: AlertProps["status"];
  onDismiss?: PressableProps["onPress"];
} & AnalyticsProp<"onDismiss" | "onLinkPress">;

export type InlineNotificationProps =
  | ({
      message: string;
      onLinkPress?: () => void;
      linkText?: string;
      children?: never;
    } & BaseInlineNotificationProps)
  | ({
      message?: never;
      onLinkPress?: () => void;
      linkText?: string;
      children: ReactNode;
    } & BaseInlineNotificationProps);

export function Inline({
  status = "informational",
  message,
  children,
  onLinkPress,
  linkText,
  analytics,
  onDismiss,
}: InlineNotificationProps) {
  const trackEvent = useAnalytics(analytics);

  const handleDismiss = (event: GestureResponderEvent) => {
    onDismiss?.(event);
    trackEvent("onDismiss");
  };

  const handleLinkPress = () => {
    onLinkPress?.();
    trackEvent("onLinkPress");
  };

  return (
    <Pressable onPress={handleDismiss}>
      <Box
        flexDirection="row"
        gap="2xs"
        alignItems="center"
        padding="xs"
        borderRadius="md"
        backgroundColor={`${status === "informational" ? "info" : status}.bg.strong.base`}
      >
        <Alert status={status} />
        <Box flex={1}>
          {message && onLinkPress && linkText ? (
            <Text>
              {`${message} - `}
              <TextWrapper onLinkPress={handleLinkPress} linkText={linkText} />
            </Text>
          ) : (
            message && <Text>{message}</Text>
          )}
          {children}
        </Box>
        {onDismiss && <Icon name="X" size={20} />}
      </Box>
    </Pressable>
  );
}
