import { I18n } from "@meraki/core/i18n";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import DeviceInfo from "react-native-device-info";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useAttachFileToSupportCase } from "~/api/queries/cases/useSupportCaseInfo";
import { useNewSupportCaseMutation, useSupportCase } from "~/api/queries/cases/useSupportCases";
import { Device, DeviceSchema } from "~/api/schemas/Device";
import { KEYBOARD_TYPE, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import NFOs from "~/constants/NFOs";
import ConfigHeader from "~/go/components/ConfigHeader";
import RoundedButton from "~/go/components/RoundedButton";
import DevicesSection from "~/go/components/supportCase/contactSupport/DeviceSection";
import PhoneSection from "~/go/components/supportCase/contactSupport/PhoneSection";
import { SupportScreensPropMap } from "~/go/navigation/Types";
import { DeviceListRowComponent } from "~/go/rows/DeviceListRow";
import InputRow from "~/go/rows/InputRow";
import { ALERT_BUTTONS, showActionSheet, showAlert } from "~/lib/AlertUtils";
import {
  deviceName,
  findDeviceBySerial,
  getDeviceStatus,
  isApplianceBySerial,
} from "~/lib/DeviceUtils";
import { isStringEmpty } from "~/lib/formatHelper";
import { isWeb } from "~/lib/PlatformUtils";
import { createEncryptedSupportData, findSupportCase, saveSupportData } from "~/lib/SupportUtils";
import { currentUserState, devicesSelector, getNFOs } from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiKeyboardAwareScrollView from "~/shared/components/MkiKeyboardAwareScrollView";
import { RowData } from "~/shared/components/NestedTableRenderRow";
import GeneralStatus, { StatusType } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton } from "~/shared/navigation/Buttons";
import { SupportCaseStatusOptions } from "~/shared/types/SupportCaseTypes";

const SUPPORT_DATA_FILE_NAME = "support_data.dat";

export interface ContactSupportDeviceRowDataFormat extends ContactSupportDeviceFormat {
  onPress: () => void;
  onClose: () => void;
  model: string;
}
export interface ContactSupportDeviceFormat {
  device: Device;
  isGateway: boolean;
  label: string;
  selected: boolean;
  status: StatusType;
}

type Props = ForwardedNativeStackScreenProps<SupportScreensPropMap, "ContactSupport">;

export const ContactSupportScreen = (props: Props) => {
  const { subject, supportDeviceSerial } = props;
  const actions = useActions();
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const createSupportCaseMutation = useNewSupportCaseMutation();
  const supportCaseListQuery = useSupportCase();
  const attachFileToCaseMutation = useAttachFileToSupportCase();

  const email = useAppSelector(currentUserState);
  const devicesSelection = useAppSelector(devicesSelector);
  const devices = devicesSelection.flatMap((device) => DeviceSchema.parse(device));
  const currentOrganization = useCurrentOrganizationId();
  const nfos = useAppSelector(getNFOs);

  const [subjectVal, setSubjectVal] = useState<string>(subject ? subject : "");
  const [message, setMessage] = useState<string>("");
  const [showNumber, setShowNumber] = useState<boolean>(false);
  const [number, setNumber] = useState<string>("");
  const [emailVal, setEmailVal] = useState<string>(email);
  const [selectedDevices, setSelectedDevices] = useState<ContactSupportDeviceFormat[]>([]);
  const [reqPending, setReqPending] = useState<boolean>(false);

  // @ts-expect-error TS(2749) FIXME: 'InputRow' refers to a value, but is being used as... Remove this comment to see the full error message
  const emailRef = useRef<InputRow>(null);
  // @ts-expect-error TS(2749) FIXME: 'InputRow' refers to a value, but is being used as... Remove this comment to see the full error message
  const descriptionRef = useRef<InputRow>(null);

  const onSerial = (serial: string) => {
    const device = findDeviceBySerial(devices, serial);

    // TODO: custom flow when device is not in this network
    if (!device) {
      return {
        status: GeneralStatus.bad,
        message: I18n.t("SUPPORT.DEVICE_NOT_FOUND"),
      };
    }
    if (
      findDeviceBySerial(
        selectedDevices.flatMap(({ device }) => device),
        serial,
      )
    ) {
      return {
        status: GeneralStatus.bad,
        message: I18n.t("SUPPORT.DEVICE_ALREADY_ADDED"),
      };
    }

    setSelectedDevices([...selectedDevices, formatDevice(device)]);
    return {
      status: GeneralStatus.good,
      message: I18n.t("SUPPORT.DEVICE_ADDED"),
    };
  };

  useEffect(() => {
    if (supportDeviceSerial) {
      onSerial(supportDeviceSerial);
    }
  });

  const sendSupportDataToCase = async () => {
    if (!currentOrganization) {
      showAlert(
        I18n.t("SUPPORT.CASE_NOT_CREATED.TITLE"),
        I18n.t("SUPPORT.CASE_NOT_CREATED.MESSAGE_LIMITED"),
      );
      return;
    }

    setReqPending(true);
    const supportData = await createEncryptedSupportData(currentOrganization);

    // need to refetch to ensure we get new case
    await supportCaseListQuery.refetch();
    const caseList = supportCaseListQuery.data;
    const openCases = caseList?.filter(
      (supportCase) => supportCase.status !== SupportCaseStatusOptions.closed,
    );

    // create support case endpoint does not return case number or ID
    const newCase = findSupportCase(openCases ?? [], subjectVal, message);

    if (newCase) {
      attachFileToCaseMutation.mutate(
        //@ts-ignore
        { file: supportData, filename: SUPPORT_DATA_FILE_NAME, case_number: newCase.caseNumber },
        {
          onSuccess: () => {
            showAlert(
              I18n.t("SUPPORT.SUPPORT_DATA_SENT.TITLE"),
              I18n.t("SUPPORT.SUPPORT_DATA_SENT.MESSAGE"),
              () => navigation.popToTop(),
            );
          },
          onError: () => {
            showAlert(
              I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_UPLOAD.TITLE"),
              I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_UPLOAD.MESSAGE"),
              () => saveData(supportData),
              {
                positiveText: ALERT_BUTTONS.save,
                negativeText: ALERT_BUTTONS.discard,
                onNegativePress: () => navigation.popToTop(),
              },
            );
          },
        },
      );
    } else {
      showAlert(
        I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_UPLOAD.TITLE"),
        I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_UPLOAD.MESSAGE"),
        () => saveData(supportData),
        {
          positiveText: ALERT_BUTTONS.save,
          negativeText: ALERT_BUTTONS.discard,
          onNegativePress: () => navigation.popToTop(),
        },
      );
    }
    setReqPending(false);
  };

  const renderDeviceRow = (rowData: RowData<ContactSupportDeviceRowDataFormat>, rowId: number) => (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <DeviceListRowComponent
      key={rowData.label}
      onPress={rowData.onPress}
      status={rowData.status}
      model={rowData.device.model}
      selected={rowData.selected}
      onClose={rowData.onClose}
      testID={`DeviceListRow - ${rowId}`}
      accessoryTestID={`DeviceListRow Remove - ${rowId}`}
      device={rowData.device}
    >
      {rowData.label}
    </DeviceListRowComponent>
  );

  const createSupportCase = () => {
    setReqPending(true);

    if (isStringEmpty(subjectVal)) {
      showAlert(
        I18n.t("SUPPORT.EMPTY_CASE_ERROR.TITLE"),
        I18n.t("SUPPORT.EMPTY_CASE_ERROR.SUBJECT"),
      );
      return;
    }

    if (isStringEmpty(message)) {
      showAlert(
        I18n.t("SUPPORT.EMPTY_CASE_ERROR.TITLE"),
        I18n.t("SUPPORT.EMPTY_CASE_ERROR.MESSAGE"),
      );
      return;
    }

    const deviceNames = selectedDevices
      .map(({ device }) => deviceName({ name: device.name || "", serial: device.serial }))
      .join("\n");
    const supportCase = {
      description: `${message}\n\n\n----------\nRelated devices:\n${deviceNames}\nVersion: ${DeviceInfo.getVersion()}\nBuild: ${DeviceInfo.getBuildNumber()}`,
      email_override: emailVal,
      related_devices: selectedDevices.map(({ device }) => device.id).join(";"),
      subject: subjectVal,
      // TODO: have support tell us what to mark these. Do users get to choose?
      Customer_Assigned_Priority__c: "Medium",
      ...(showNumber ? { callback_number: number } : {}),
    };

    setReqPending(false);
    createSupportCaseMutation.mutate(supportCase, {
      onSettled: (response, error) => {
        if (response?.success) {
          if (!isWeb() && nfos.has(NFOs.hasMobileBetaFeatures)) {
            showAlert(
              I18n.t("SUPPORT.CASE_CREATED.TITLE"),
              I18n.t("SUPPORT.SUPPORT_DATA.MESSAGE"),
              () => setTimeout(sendSupportDataToCase, 60000),
              {
                positiveText: I18n.t("SUPPORT.SUPPORT_DATA.BUTTON"),
                negativeText: ALERT_BUTTONS.cancel,
                onNegativePress: () => navigation.popToTop(),
              },
            );
          } else {
            showAlert(
              I18n.t("SUPPORT.CASE_CREATED.TITLE"),
              I18n.t("SUPPORT.CASE_CREATED.MESSAGE"),
              () => navigation.popToTop(),
            );
          }
        } else {
          if (response?.need_captcha) {
            showAlert(
              I18n.t("SUPPORT.CASE_NOT_CREATED.TITLE"),
              I18n.t("SUPPORT.CASE_NOT_CREATED.MESSAGE_LIMITED"),
            );
          } else {
            const errorMessage = error || I18n.t("SUPPORT.CASE_NOT_CREATED.MESSAGE");
            showAlert(
              I18n.t("SUPPORT.CASE_NOT_CREATED.TITLE"),
              errorMessage,
              () => createSupportCase,
              {
                positiveText: ALERT_BUTTONS.retry,
                negativeText: ALERT_BUTTONS.cancel,
                onNegativePress: () => navigation.popToTop(),
              },
            );
          }
        }
      },
    });
  };

  const showHardwareAdd = () => {
    navigation.navigate("AddHardware", {
      onSupportSerial: onSerial,
    });
  };

  const setSelectedDevicesWrapper = (selectedDevices: ContactSupportDeviceFormat[]) => {
    setSelectedDevices(selectedDevices);
    return Promise.resolve();
  };

  const formatDevice = (unformattedDevice: Device): ContactSupportDeviceFormat => {
    const matchingDeprecatedDevice = devicesSelection.find(({ id }) => id === unformattedDevice.id);

    return {
      device: unformattedDevice,
      isGateway: isApplianceBySerial(unformattedDevice.serial),
      label: deviceName({ name: unformattedDevice.name || "", serial: unformattedDevice.serial }),
      selected: !!selectedDevices.find(({ device }) => device.serial === unformattedDevice.serial),
      status: matchingDeprecatedDevice
        ? getDeviceStatus(matchingDeprecatedDevice) || GeneralStatus.bad
        : GeneralStatus.bad,
    };
  };

  const showDevicesList = async () => {
    await actions.setNestedModalData({ next: { entries: devices.map(formatDevice) } });
    navigation.navigate("NestedTable", {
      onSave: setSelectedDevicesWrapper,
      showDismiss: true,
      options: {
        customRender: renderDeviceRow,
        singleDismiss: true,
        toggle: true,
      },
    });
  };

  const removeDevice = (serial: string) => () => {
    setSelectedDevices(selectedDevices.filter(({ device }) => device.serial !== serial));
  };

  const saveData = async (supportData: string) => {
    try {
      await saveSupportData(supportData);
      showAlert(
        I18n.t("SUPPORT.SUPPORT_DATA_SAVED.TITLE"),
        I18n.t("SUPPORT.SUPPORT_DATA_SAVED.MESSAGE"),
        () => navigation.popToTop(),
      );
    } catch (e) {
      showAlert(
        I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_SAVE.TITLE"),
        I18n.t("SUPPORT.SUPPORT_DATA_FAILED_TO_SAVE.MESSAGE"),
        () => navigation.popToTop(),
      );
    }
  };

  const onTabPress = (index: number) => setShowNumber(index === 0);
  const onChangeNumber = (text: string) => setNumber(text);
  const onSubmitEditing = () => emailRef.current.focus();
  const showHardwareList = () =>
    showActionSheet(
      [I18n.t("SUPPORT.ACTION_SHEET.OPTIONS.LIST"), I18n.t("SUPPORT.ACTION_SHEET.OPTIONS.QR")],
      (index: number) => (index === 0 ? showDevicesList() : showHardwareAdd()),
      { title: I18n.t("SUPPORT.ACTION_SHEET.TITLE") },
    );

  // TODO: remove PhoneSection gating once support wants to make calls
  return (
    <MkiKeyboardAwareScrollView testID="scroll view">
      <ConfigHeader
        title={I18n.t("SUPPORT.HEADER.TITLE")}
        description={I18n.t("SUPPORT.HEADER.DESCRIPTION")}
        testID="CONFIG_HEADER"
      />
      <InputRow
        testID="SUBJECT_INPUT"
        clearTestID="subject clear button"
        onChangeText={(text: string) => setSubjectVal(text)}
        placeholder={I18n.t("SUPPORT.PLACEHOLDER.SUBJECT")}
        value={subjectVal}
        returnKeyType={RETURN_KEY.next}
        onSubmitEditing={() => descriptionRef.current?.focus()}
        autoCapitalize="sentences"
        autoCorrect
        multiline
      >
        {I18n.t("SUPPORT.SECTION.SUBJECT")}
      </InputRow>
      <InputRow
        testID="DESCRIPTION_INPUT"
        clearTestID="description clear button"
        ref={descriptionRef}
        onChangeText={(text: string) => setMessage(text)}
        placeholder={I18n.t("SUPPORT.PLACEHOLDER.MESSAGE")}
        value={message}
        autoCapitalize="sentences"
        autoCorrect
        multiline
      >
        {I18n.t("SUPPORT.SECTION.MESSAGE")}
      </InputRow>
      <DevicesSection
        showHardwareList={() => (isWeb() ? showDevicesList() : showHardwareList())}
        devices={selectedDevices}
        renderDeviceRow={renderDeviceRow}
        removeDevice={removeDevice}
      />
      {false && (
        <PhoneSection
          showNumber={showNumber}
          number={number}
          onTabPress={onTabPress}
          onChangeNumber={onChangeNumber}
          onSubmitEditing={onSubmitEditing}
        />
      )}
      <InputRow
        testID="EMAIL_INPUT"
        clearTestID="email clear button"
        ref={emailRef}
        onChangeText={(text: string) => setEmailVal(text)}
        placeholder={I18n.t("SUPPORT.PLACEHOLDER.EMAIL")}
        value={emailVal}
        returnKeyType={RETURN_KEY.done}
        onSubmitEditing={createSupportCase}
        keyboardType={KEYBOARD_TYPE.emailAddress}
      >
        {I18n.t("SUPPORT.SECTION.EMAIL")}
      </InputRow>
      <RoundedButton
        onPress={createSupportCase}
        screenStyles={styles.button}
        testID="SUBMIT_BUTTON"
        loading={reqPending}
      >
        {I18n.t("SUBMIT")}
      </RoundedButton>
      <LoadingSpinner
        visible={
          reqPending ||
          createSupportCaseMutation.isLoading ||
          supportCaseListQuery.isLoading ||
          attachFileToCaseMutation.isLoading
        }
      />
    </MkiKeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  button: {
    marginVertical: SPACING.default,
    marginHorizontal: SPACING.extraLarge * 2,
  },
});

export default ContactSupportScreen;
