import { isEmpty } from "lodash";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

export interface NoDataFooterData {
  data: [] | {} | undefined;
  noDataString: string;
}

const NoDataFooter: React.FC<NoDataFooterData> = (props) => {
  const { data, noDataString } = props;
  if (isEmpty(data)) {
    return (
      <View style={styles.rowStyles}>
        <MkiText screenStyles={styles.noSettingsStyle}>{noDataString}</MkiText>
      </View>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  noSettingsStyle: {
    color: MkiColors.secondaryTextColor,
  },
});

export default NoDataFooter;
