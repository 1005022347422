import { defaultTimespans } from "@meraki/shared/redux";
import { StyleSheet, View } from "react-native";

import { CLIENT } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import MkiText from "~/shared/components/MkiText";

type Props = {
  timespan: number;
};

const NoClientData = (props: Props) => {
  const { timespan } = props;
  const data = Object.values(defaultTimespans);
  const textTimespan = data.find((d) => timespan === d.value)?.label;

  return (
    <View style={styles.container}>
      <MkiText textStyle="heading" screenStyles={styles.heading}>
        {capitalizeFirstLetter(
          I18n.t("CLIENT_DETAILS.CLIENT_NOT_FOUND", { client_word: I18n.t(CLIENT) }),
        )}
      </MkiText>
      <MkiText screenStyles={styles.message}>
        {I18n.t("CLIENT_DETAILS.CLIENT_NOT_ONLINE_IN_TIMESPAN", {
          client_word: I18n.t(CLIENT),
          // Check if "-" okay
          timespan: textTimespan ? I18n.t(textTimespan) : "-",
        })}
      </MkiText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  heading: {
    marginBottom: 12,
  },
  message: {
    width: "80%",
    textAlign: "center",
  },
});

export default NoClientData;
