import { I18n } from "@meraki/core/i18n";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { Heading, List, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useCommunityData, useCommunityTopicsData, useDocumentationData } from "@meraki/shared/api";
import { isValidUrl } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useSearchTagsMap } from "@meraki/shared/search";
import { ParamListBase, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Linking } from "react-native";

export function SearchResultsScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>();
  const route = useRoute<RouteProp<HelpStackProps, "SearchResults">>();
  const { query } = route.params;

  const netowrkId = useCurrentNetworkId();

  const types = {
    feature: I18n.t("SEARCH_RESULTS_SCREEN.FEATURE"),
    community: I18n.t("SEARCH_RESULTS_SCREEN.COMMUNITY"),
    documentation: I18n.t("SEARCH_RESULTS_SCREEN.DOCUMENTATION"),
  };
  const typesOrder = [types.feature, types.community, types.documentation];

  const features = useSearchTagsMap(query, netowrkId ? "network" : "organization", {
    type: types.feature,
  });
  const { data: topicsData, isLoading: communityTopicsLoading } = useCommunityTopicsData(query, {
    enabled: Boolean(query),
  });
  const { data: communityResponseData, isLoading: communityLoading } = useCommunityData(
    topicsData?.data.items ?? [],
    {
      select: (response) =>
        response.data?.items?.slice(0, 5)?.map((data) => ({
          title: data.subject,
          description: data.search_snippet,
          link: data.view_href,
          type: types.community,
        })),
    },
  );
  const { data: documentationResponseData, isLoading: documentationLoading } = useDocumentationData(
    query,
    {
      select: (response) =>
        response.result.slice(0, 5).map((data) => ({
          title: data.title,
          description: data.content,
          link: data.uri,
          type: types.documentation,
        })),
      enabled: Boolean(query),
    },
  );

  const results = [
    ...features,
    ...(communityResponseData ?? []),
    ...(documentationResponseData ?? []),
  ];

  return (
    <Screen addDefaultPadding>
      <Heading>{I18n.t("SEARCH_RESULTS_SCREEN.RESULTS_FOR")}</Heading>
      <Text size="p1">{I18n.t("SEARCH_RESULTS_SCREEN.QUOTED_QUERY", { query })}</Text>
      <List.FlashList
        paddingLeft="none"
        paddingRight="none"
        loading={communityTopicsLoading || communityLoading || documentationLoading}
        data={results}
        groupBy={(data) => data.type ?? ""}
        sortGroupBy={(a, b) => typesOrder.indexOf(a.label) - typesOrder.indexOf(b.label)}
        getItemData={(result) => {
          if ("screen" in result) {
            return {
              title: I18n.t(result.title),
              description: result.subtitle ? I18n.t(result.subtitle) : undefined,
              onPress: () => {
                if (result.tab) {
                  navigation.navigate(result.tab, {
                    screen: result.screen,
                    initial: false,
                    params: {}, // TODO: deisgn a way to select a device, SSID, VLAN etc.
                  });
                } else {
                  navigation.navigate(result.screen);
                }
              },
            };
          } else {
            return {
              title: result.title,
              description: result.description,
              onPress: isValidUrl(result.link) ? () => Linking.openURL(result.link) : undefined,
            };
          }
        }}
      />
    </Screen>
  );
}
