import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { HIDDEN_PASSWORD_TEXT } from "~/constants/SSID";
import RevealPasswordButton from "~/go/components/RevealPasswordButton";
import ListRow from "~/shared/rows/ListRow";

export const SSIDIdentityPskListRow = (props: any) => {
  const { rowData, rowId, ssidNumber } = props;
  const { name, passphrase } = rowData;
  const navigation = useNavigation();
  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  return (
    <ListRow
      subtitle1={isRevealed ? passphrase : HIDDEN_PASSWORD_TEXT}
      leftStyle={styles.leftStyle}
      rightStyle={styles.rightAccessory}
      numberOfLines={2}
      ellipsizeMode="tail"
      accessory={
        <RevealPasswordButton
          buttonStyles={styles.revealPasswordButton}
          onPress={() => setIsRevealed(!isRevealed)}
          passwordVisible={isRevealed}
        />
      }
      onPress={() => {
        navigation.navigate("ConfigureIpsk", { ipsk: rowData, ssidNumber });
      }}
      key={`IPSK_LIST.${rowId}`}
      testID={`IPSK_LIST.${rowId}`}
    >
      {name}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  revealPasswordButton: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  leftStyle: {
    flex: 5,
  },
  rightAccessory: {
    flex: 1,
    paddingRight: SPACING.default,
  },
});
