import Svg, { Path } from "react-native-svg";

export const WindowsLogoIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M13.5 13.5L8.5 12.5938V9H13.5V13.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.5 12.225L2.5 11.5V9H6.5V12.225Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.5 2.5L8.5 3.40625V7H13.5V2.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.5 3.77499L2.5 4.49999V6.99999H6.5V3.77499Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default WindowsLogoIcon;
