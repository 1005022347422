import { combineReducers } from "redux";

import fetchAllExports from "~/reducers/allExports";
import initiateExport from "~/reducers/exportFeed";

const videoExport = combineReducers({
  initiateExport,
  fetchAllExports,
});

export default videoExport;
