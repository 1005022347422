import { get } from "lodash";

import { ColorToken, Theme } from "./ThemeContext";

export function getColorFromTokenName(token: ColorToken, theme: Theme): string {
  return get(theme, `color.${token}`, "transparent");
}

export function mapColorToken(token: ColorToken | undefined, theme: Theme): string | undefined {
  if (!token) return undefined;

  return getColorFromTokenName(token, theme);
}
