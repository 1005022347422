import { Icon, IconName } from "@meraki/magnetic/icons";
import {
  ColorToken,
  getColorFromTokenName,
  Theme,
  useMagneticTheme,
} from "@meraki/magnetic/themes";
import { ActivityIndicator, View, ViewStyle } from "react-native";

const KIND_MAP = {
  backgroundColor: {
    default: "transparent",
    pressed: "interact.bg.weak.hover",
  },
  borderColor: {
    default: "transparent",
    pressed: "interact.bg.weak.hover",
  },
  textColor: {
    default: "interact.icon.weak.base",
    pressed: "interact.icon.weak.hover",
  },
} as const;

export interface IconButtonInternalProps {
  disabled?: boolean;
  loading?: boolean;
  pressed?: boolean;
  color?: ColorToken;
  icon: IconName;
}
type InternalStyleProps = Omit<IconButtonInternalProps, "icon">;

function getBoxProps({ disabled, loading, pressed }: InternalStyleProps, theme: Theme): ViewStyle {
  return {
    gap: 5,
    backgroundColor: getColorFromTokenName(
      KIND_MAP["backgroundColor"][pressed ? "pressed" : "default"],
      theme,
    ),
    borderColor: getColorFromTokenName(
      KIND_MAP["borderColor"][pressed ? "pressed" : "default"],
      theme,
    ),
    borderWidth: theme.SizeInteractStroke,
    paddingHorizontal: theme.SizeInteractPaddingHorizSm,
    paddingVertical: theme.SizeInteractPaddingVertSm,
    borderRadius: theme.SizeInteractRadiusBorder,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: !disabled && !loading ? 1 : 0.4,
  };
}

function getLoadingProps(theme: Theme) {
  return {
    color: getColorFromTokenName(KIND_MAP["textColor"]["default"], theme),
    size: 20,
  };
}

function getIconColor({ pressed }: InternalStyleProps): ColorToken {
  return KIND_MAP["textColor"][pressed ? "pressed" : "default"];
}

export function IconButtonInternal({
  disabled = false,
  loading = false,
  pressed = false,
  color,
  icon,
}: IconButtonInternalProps) {
  const defaultedProps = { disabled, pressed, loading };
  const theme = useMagneticTheme();

  return (
    <View style={getBoxProps(defaultedProps, theme)}>
      {loading ? (
        <ActivityIndicator {...getLoadingProps(theme)} />
      ) : (
        <Icon name={icon} color={color ?? getIconColor(defaultedProps)} size={20} />
      )}
    </View>
  );
}
