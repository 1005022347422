import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";

import { Heading } from "../Heading/Heading";
import { Text } from "../Text/Text";
import { StepStatuses } from "./useStepper";

export type StepPanelProps = {
  stepStatuses: StepStatuses[];
  stepTitle: string;
  stepSubTitle?: string;
};

export type StepProps = {
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
};

function HeaderBar({ active, completed, disabled }: StepProps) {
  let color: ColorToken = "default.bg.strong.base";
  if (disabled) {
    color = "dormant.icon.disabled";
  } else if (active) {
    color = "interact.icon.base";
  } else if (completed) {
    color = "interact.icon.disabled";
  }

  return <Box flex={1} borderRadius="sm" backgroundColor={color} />;
}

export function HeaderPanel({ stepStatuses, stepTitle, stepSubTitle }: StepPanelProps) {
  return (
    <Box paddingBottom="sm" gap="sm">
      <Box flexDirection="row" gap="xs" height={5}>
        {stepStatuses.map((status, index) => {
          return <HeaderBar {...status} key={index} />;
        })}
      </Box>
      <Box flexDirection="column" gap="2xs">
        <Heading>{stepTitle}</Heading>
        <Text size="p3" color="light">
          {stepSubTitle}
        </Text>
      </Box>
    </Box>
  );
}
