import { useTheme } from "@meraki/core/theme";
import { memo } from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LETTER_SPACING, SPACING } from "~/constants/MkiConstants";
import { PROXIMA_SOFT_FONT } from "~/constants/MkiFonts";
import { ROW_SPACINGS } from "~/constants/RowConstants";
import { platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import ListRow, { EllipsizeMode } from "~/shared/rows/ListRow";

type Props = {
  renderAccessory?: boolean;
  accessory?: React.ReactElement;
  children: React.ReactElement | string;
  onPress?: (a?: unknown) => void;
  onLongPress?: () => void;
  value?: string | React.ReactElement;
  rowStyle?: StyleProp<TextStyle>;
  screenStyles?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  leftStyle?: StyleProp<ViewStyle>;
  rightStyle?: StyleProp<ViewStyle>;
  subtitle?: string;
  subtitle2?: string;
  icon?: React.ReactElement;
  isFullButton?: boolean;
  bottomSeparator?: boolean;
  testID?: string;
  numberOfLines?: number;
  ellipsizeMode?: EllipsizeMode;
  inlineView?: React.ReactElement;
};

export const DisclosureRow = memo(
  ({
    renderAccessory = true,
    accessory = <MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />,
    rowStyle = {},
    screenStyles = {},
    isFullButton = false,
    bottomSeparator = false,
    value,
    icon,
    subtitle,
    subtitle2,
    leftStyle = styles.leftStyle,
    rightStyle = styles.rightStyle,
    labelStyle,
    testID,
    numberOfLines,
    ellipsizeMode,
    onLongPress,
    onPress,
    children,
    inlineView,
  }: Props) => {
    const { theme } = useTheme.getState();
    const textStyle = isFullButton ? styles.fullButtonRow : {};
    const backgroundColor = themeColors(theme).navigation.backgroundPrimary;

    const bottomSeparatorStyle = [
      styles.bottomSeparator,
      { borderColor: themeColors(theme).border.borderColor },
    ];

    const row = (
      <View style={styles.wrapper}>
        <View style={[{ backgroundColor }, styles.container, screenStyles]}>
          <ListRow
            value={value}
            icon={icon}
            accessory={renderAccessory ? accessory : null}
            subtitle1={subtitle}
            subtitle2={subtitle2}
            rowStyles={[rowStyle, bottomSeparator ? bottomSeparatorStyle : {}]}
            leftStyle={leftStyle}
            rightStyle={rightStyle}
            labelStyle={labelStyle}
            testID={testID}
            numberOfLines={numberOfLines}
            ellipsizeMode={ellipsizeMode}
            onLongPress={onLongPress}
          >
            <MkiText screenStyles={[textStyle, rowStyle]} uppercase={isFullButton}>
              {children}
            </MkiText>
          </ListRow>
        </View>
        {inlineView && (
          <View style={[styles.inlineViewContainer, { backgroundColor }]}>{inlineView}</View>
        )}
      </View>
    );

    if (onPress) {
      return <Touchable onPress={onPress}>{row}</Touchable>;
    }

    return row;
  },
);

DisclosureRow.displayName = "DisclosureRow";

const styles = StyleSheet.create({
  wrapper: {
    flex: platformSelect({
      mobile: undefined,
      web: 1,
    }),
  },
  container: {
    paddingHorizontal: SPACING.default,
  },
  leftStyle: {
    width: "90%",
  },
  rightStyle: {
    width: "10%",
  },
  fullButtonRow: {
    ...PROXIMA_SOFT_FONT,
    letterSpacing: LETTER_SPACING.allCaps,
    fontSize: normalizedFontSize(13),
    color: MkiColors.primaryButton,
  },
  bottomSeparator: {
    borderColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  subtitle2: {
    width: "90%",
    paddingLeft: SPACING.default,
    color: MkiColors.secondaryTextColor,
  },
  subtitle2Container: {
    width: "100%",
    paddingBottom: ROW_SPACINGS.listRow.bottomPadding,
  },
  inlineViewContainer: {
    width: "100%",
    paddingBottom: ROW_SPACINGS.listRow.bottomPadding,
    paddingLeft: SPACING.default,
  },
});
export default DisclosureRow;
