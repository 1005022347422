import { isEmpty } from "lodash";

export const mean = (data: number[], dataSize: number): number | undefined => {
  if (!sharedDataValidation(data, dataSize)) {
    return undefined;
  }
  return (
    data.reduce((accumulator: number, dataPoint: number) => accumulator + dataPoint, 0) / dataSize
  );
};

const sharedDataValidation = (data?: number[], dataSize?: number): boolean => {
  if (
    !dataSize ||
    data === undefined ||
    isEmpty(data) ||
    dataSize < data.length ||
    data.some(isNaN)
  ) {
    return false;
  }
  return true;
};

export const outlierThreshold = (
  mean?: number,
  stdDev?: number,
  multiple?: number,
): number | undefined => {
  if (!mean || !stdDev || !multiple) {
    return undefined;
  }
  return mean + stdDev * multiple;
};

export const standardDeviation = (data?: number[], dataSize?: number): number | undefined => {
  if (data === undefined || !dataSize || !sharedDataValidation(data, dataSize)) {
    return undefined;
  }
  const calculatedMean = mean(data, dataSize);
  if (!calculatedMean) {
    return undefined;
  }

  return Math.sqrt(
    data
      .map((dataPoint: number) => Math.pow(dataPoint - calculatedMean, 2))
      .reduce((total: number, x: number) => total + x, 0) / dataSize,
  );
};

export function calculateHighEventThreshold(countArray?: number[]): number | undefined {
  if (countArray === undefined || isEmpty(countArray)) {
    return undefined;
  }

  const theMean = mean(countArray, countArray.length);
  if (theMean === undefined) {
    return undefined;
  }

  const stdDev = standardDeviation(countArray, countArray.length);
  if (stdDev === undefined) {
    return undefined;
  }

  return outlierThreshold(theMean, stdDev, 2);
}
