import { MLabStatus } from "@meraki/go/monitor";

export function isReadyToTest(status: MLabStatus) {
  return status === "ready" || status === "completed";
}

export function getRotationDegreeFromSpeed(speed: number) {
  // get next closest 10 increment
  const max = Math.ceil(speed / 10) * 10;
  // convert to negative degree for rotation
  //   0% -> -180 degree
  //   100% -> 0 degree
  return (speed / max) * 180 - 180;
}
