import Svg, { G, Path, Polygon, Rect } from "react-native-svg";

import mkiColors from "~/constants/MkiColors";

const {
  topFillColor,
  outlineFillColor,
  sideFillColor,
  otherSideFillColor,
  logoFillColor,
  activePortFillColor,
  inactivePortFillColor,
} = mkiColors.hardwareIllustrations;

const svgLayoutStyle = { flex: 1 };

const gr10 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 75 48.19066" focusable={false}>
      <G focusable={false} id="gr10-perspective">
        <Path
          id="gr10-perspective-side"
          d="M5.44063,47.19955,5.8335.73151.88455,4.16568A2.31278,2.31278,0,0,0,0,5.85614V41.38563a2.70108,2.70108,0,0,0,.812,1.78366Z"
          fill={sideFillColor}
        />
        <Path
          id="gr10-perspective-top"
          d="M7.25432,47.97532a2.45761,2.45761,0,0,1-2.47967-2.48839V2.8334A2.62087,2.62087,0,0,1,7.39242.21532L72.14658.69083a2.66472,2.66472,0,0,1,2.63811,2.65756v38.1257a2.83282,2.83282,0,0,1-2.63653,2.79173L7.40493,47.971C7.35446,47.97385,7.3041,47.97532,7.25432,47.97532Z"
          fill={topFillColor}
        />
        <Path
          id="gr10-perspective-outline"
          d="M7.25429,48.19066a2.67063,2.67063,0,0,1-2.695-2.70372V2.83336A2.83644,2.83644,0,0,1,7.3924,0L72.14814.47545A2.88068,2.88068,0,0,1,75,3.34834V41.4741a3.05106,3.05106,0,0,1-2.83956,3.00674L7.41721,48.186C7.36248,48.18908,7.30807,48.19066,7.25429,48.19066Zm.13653-47.76A2.40491,2.40491,0,0,0,4.99,2.83336V45.48694A2.24819,2.24819,0,0,0,7.39282,47.756l64.743-3.70519a2.61469,2.61469,0,0,0,2.43351-2.57671V3.34834A2.4489,2.4489,0,0,0,72.145.90611Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gr10-perspective-logo">
          <Path d="M39.5154,37.51343l-.51432.02332v-1.884l.51432-.0218Z" fill={logoFillColor} />
          <Path
            d="M43.65565,35.99094a.93942.93942,0,0,0-.4322-.08121.54124.54124,0,0,0-.56247.52375.4875.4875,0,0,0,.56247.47521.99858.99858,0,0,0,.4322-.1185v.50217a1.7983,1.7983,0,0,1-.46818.08818.95047.95047,0,0,1-1.085-.92256,1.05318,1.05318,0,0,1,1.085-1.016,1.66458,1.66458,0,0,1,.46818.04732Z"
            fill={logoFillColor}
          />
          <Path
            d="M38.29584,36.22262a.9205.9205,0,0,0-.43132-.08208.543.543,0,0,0-.56286.52795.489.489,0,0,0,.56286.47939.98246.98246,0,0,0,.43132-.11931V37.535a1.77441,1.77441,0,0,1-.46906.08878.95229.95229,0,0,1-1.08268-.9308,1.05562,1.05562,0,0,1,1.08268-1.024,1.63544,1.63544,0,0,1,.46906.04785Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.26989,35.82907a.53111.53111,0,0,0-.5302.51327.47861.47861,0,0,0,.5302.46814.53281.53281,0,0,0,.53129-.5147.47947.47947,0,0,0-.53129-.46671m1.07494.44287a1.06135,1.06135,0,0,1-1.07494,1.01412.95314.95314,0,0,1-1.07366-.9199,1.05758,1.05758,0,0,1,1.07366-1.01231.95575.95575,0,0,1,1.07494.91809"
            fill={logoFillColor}
          />
          <Path
            d="M41.46191,35.97481a1.8623,1.8623,0,0,0-.40108-.0396c-.20573.00884-.31743.07685-.31743.16776,0,.11375.15031.14714.23465.16855l.14238.03516c.33288.08331.485.28748.485.51547,0,.47142-.44988.65058-.84235.66839a3.33957,3.33957,0,0,1-.55429-.02626v-.43306a2.03457,2.03457,0,0,0,.48475.04871c.25473-.01139.373-.086.373-.19271,0-.09494-.10235-.14447-.22956-.17649-.03076-.00775-.07735-.01967-.10994-.02755-.28412-.06867-.52361-.21619-.52361-.52712,0-.35209.28455-.6.75849-.62a2.36206,2.36206,0,0,1,.5.03786Z"
            fill={logoFillColor}
          />
          <Path
            d="M36.19915,33.73786a.23488.23488,0,0,0-.25515-.22615.25847.25847,0,0,0-.254.24612v.49678a.23486.23486,0,0,0,.254.22685.25836.25836,0,0,0,.25515-.24722Z"
            fill={logoFillColor}
          />
          <Path
            d="M37.5994,33.03205a.23443.23443,0,0,0-.254-.22572.25785.25785,0,0,0-.25581.24521v1.14709a.23485.23485,0,0,0,.25581.22625.25782.25782,0,0,0,.254-.24665Z"
            fill={logoFillColor}
          />
          <Path
            d="M38.99931,32.091a.23455.23455,0,0,0-.25428-.22626.25749.25749,0,0,0-.25517.245v2.50348a.235.235,0,0,0,.25517.22609.25813.25813,0,0,0,.25428-.24685Z"
            fill={logoFillColor}
          />
          <Path
            d="M40.39968,32.925a.23408.23408,0,0,0-.25384-.2247.25693.25693,0,0,0-.25518.24417v1.1421a.234.234,0,0,0,.25518.22525.25743.25743,0,0,0,.25384-.24561Z"
            fill={logoFillColor}
          />
          <Path
            d="M41.79854,33.51814a.23317.23317,0,0,0-.2534-.22418.25751.25751,0,0,0-.255.24413v.49248a.234.234,0,0,0,.255.22474.25647.25647,0,0,0,.2534-.24508Z"
            fill={logoFillColor}
          />
          <Path
            d="M43.19982,32.818a.23353.23353,0,0,0-.2543-.22367.25577.25577,0,0,0-.25384.24309v1.13712a.233.233,0,0,0,.25384.22428.25678.25678,0,0,0,.2543-.2446Z"
            fill={logoFillColor}
          />
          <Path
            d="M44.60043,31.88462a.23354.23354,0,0,0-.25454-.22421.256.256,0,0,0-.25384.24293V34.385a.23369.23369,0,0,0,.25384.22409.257.257,0,0,0,.25454-.2448Z"
            fill={logoFillColor}
          />
          <Path
            d="M46.00182,32.71082a.23472.23472,0,0,0-.25825-.22249.2561.2561,0,0,0-.254.24207v1.13215a.23322.23322,0,0,0,.254.22325.258.258,0,0,0,.25825-.24373Z"
            fill={logoFillColor}
          />
          <Path
            d="M47.39964,33.29835a.23344.23344,0,0,0-.2547-.22207.25553.25553,0,0,0-.254.242v.48819a.23223.23223,0,0,0,.254.22272.25678.25678,0,0,0,.2547-.24308Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 87.27893 14.25" focusable={false}>
      <G focusable={false} id="gr10-profile">
        <Path
          id="gr10-profile-side"
          d="M86.63946,7.25h-86l2.86609,3.34375h0l2.4831,2.897A2.48059,2.48059,0,0,0,7.63946,14.25h72a2.4806,2.4806,0,0,0,1.65082-.75928l2.4831-2.897h0Z"
          fill={sideFillColor}
        />
        <Path
          id="gr10-profile-top"
          d="M86.10846,5.63934l-5.938-3.77874-.00037-.00025L78.48315.78687A4.05433,4.05433,0,0,0,76.63946.25h-66A4.05432,4.05432,0,0,0,8.79578.78687l-1.687,1.07348-.00037.00025-5.938,3.77874C-.22162,6.52521-.01056,7.25,1.63946,7.25h84C87.28949,7.25,87.50055,6.52521,86.10846,5.63934Z"
          fill={topFillColor}
        />
        <Path
          id="gr10-profile-outline"
          d="M85.63946,7.5h-84C.46173,7.5.11114,7.145.02423,6.84668c-.08691-.29785.01855-.78564,1.0127-1.41846l7.625-4.852A4.26165,4.26165,0,0,1,10.63946,0h66A4.26886,4.26886,0,0,1,78.618.57617l7.624,4.852c.99414.63282,1.09961,1.12061,1.0127,1.41846C87.16778,7.145,86.8172,7.5,85.63946,7.5Zm-75-7a3.80665,3.80665,0,0,0-1.709.49805L1.3045,5.8501C.60431,6.29639.47443,6.606.50372,6.707.534,6.80859.80841,7,1.63946,7h84c.83106,0,1.10547-.19141,1.13575-.293.02929-.10107-.10059-.41064-.80078-.85693l-7.625-4.852a3.81427,3.81427,0,0,0-1.71-.498Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gr10-profile-logo">
          <Path d="M41.36577,5.1119h-.57624l.07026-.5769h.562Z" fill={logoFillColor} />
          <Path
            d="M45.96279,4.70016a3.48954,3.48954,0,0,0-.47817-.03078c-.3652,0-.61477.06552-.61.15383.00459.086.2534.15408.63478.15408a3.4237,3.4237,0,0,0,.47834-.03061l.01565.15489c-.0719.00526-.26734.02065-.52267.02065-.6485,0-1.21912-.11289-1.22408-.299-.00461-.17211.49305-.29847,1.17708-.29847.26256,0,.45924.01658.51347.02071Z"
            fill={logoFillColor}
          />
          <Path
            d="M40.06344,4.70016a3.24921,3.24921,0,0,0-.4693-.03078c-.36588,0-.632.06552-.64994.15383-.01746.086.21332.15408.59569.15408a3.57907,3.57907,0,0,0,.48521-.03061l-.02409.15489c-.07252.00526-.27166.02065-.529.02065-.647,0-1.18759-.11289-1.14482-.299.03955-.17211.56908-.29847,1.25117-.29847.26568,0,.45643.01658.5091.02071Z"
            fill={logoFillColor}
          />
          <Path
            d="M47.73446,4.67169c-.33463,0-.57248.06778-.55968.15152.01286.08411.27475.15195.61379.15195.33954,0,.578-.06784.5608-.15195-.01706-.08374-.2798-.15152-.61491-.15152m1.2165.15152c.03793.16509-.43475.299-1.13617.299-.70089,0-1.22042-.13392-1.24139-.299-.02088-.1646.45183-.29847,1.13485-.29847.68351,0,1.20489.13387,1.24271.29847"
            fill={logoFillColor}
          />
          <Path
            d="M43.54837,4.66606c-.00777-.00069-.24527-.01747-.4404-.01747-.22593,0-.34934.01941-.35042.04732-.00134.03493.16391.04719.25673.05489l.1568.0127c.36679.03.53554.09488.53574.165.00043.14494-.50369.19375-.94361.19375-.30656,0-.59093-.01439-.62027-.01577l.00849-.13285c.05043.00318.289.02159.539.02159.284,0,.41637-.02128.41713-.05407.00067-.02917-.1115-.04595-.25338-.05733l-.12158-.00995c-.31518-.025-.576-.07341-.56984-.1688.00693-.108.32238-.18037.84-.18037.27269,0,.52964.017.546.01815Z"
            fill={logoFillColor}
          />
          <Path
            d="M37.95664,3.915c.01016-.0397-.10227-.072-.252-.072-.14732,0-.27821.03227-.28934.072l-.04243.15135c-.01121.04.10325.07222.25253.07222.15169,0,.28221-.03217.29247-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M39.48277,3.71611c.00751-.03974-.106-.07191-.25319-.07191-.14835,0-.2755.03217-.284.07191l-.07469.35021c-.00855.04.10758.07222.25927.07222.1505,0,.27887-.03217.28644-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M40.99077,3.44434c.00487-.03992-.10887-.07212-.25492-.07212-.14536,0-.26935.0322-.2752.07212l-.112.766c-.00585.04.11371.07239.26518.07239.15218,0,.27874-.03239.28362-.07239Z"
            fill={logoFillColor}
          />
          <Path
            d="M42.433,3.71611c.00217-.03974-.11591-.07191-.26263-.07191-.14812,0-.27051.03217-.27366.07191l-.02773.35021c-.00317.04.11682.07222.26829.07222.15,0,.27434-.03217.27653-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M43.9092,3.915c-.00048-.0397-.12085-.072-.26939-.072-.14875,0-.27055.03227-.271.072l-.00184.15135c-.0005.04.12213.07222.27289.07222.15051,0,.2717-.03217.27122-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.38311,3.71611c-.00315-.03974-.12508-.07191-.27273-.07191-.14719,0-.2648.03217-.26262.07191l.01924.35021c.00219.04.126.07222.27653.07222.151,0,.2705-.03217.26732-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M46.819,3.44434c-.00585-.03992-.12778-.07212-.27454-.07212-.14419,0-.25932.0322-.25445.07212l.09341.766c.00487.04.13289.07239.28313.07239.15293,0,.27034-.03239.26449-.07239Z"
            fill={logoFillColor}
          />
          <Path
            d="M48.33517,3.71611c-.00848-.03974-.13561-.07191-.28653-.07191-.14626,0-.2607.03217-.25319.07191l.06613.35021c.00757.04.13688.07222.28643.07222.15431,0,.27043-.03217.26188-.07222Z"
            fill={logoFillColor}
          />
          <Path
            d="M49.86359,3.915c-.01113-.0397-.14156-.072-.29005-.072-.149,0-.26094.03227-.25078.072l.03875.15135c.01024.04.14029.07222.29126.07222.15048,0,.26446-.03217.25325-.07222Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const gr60 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 37.57808 90" focusable={false}>
      <G focusable={false} id="gr60-perspective">
        <Path
          id="gr60-perspective-side"
          d="M2.99038,1.21056A4.11268,4.11268,0,0,0,0,5.01352V84.596a4.4327,4.4327,0,0,0,2.94421,3.985L5.99792,89.585,6.14147.38022Z"
          fill={sideFillColor}
        />
        <Path
          id="gr60-perspective-top"
          d="M7.07379,89.74988a3.43769,3.43769,0,0,1-3.46811-3.48056V3.762A3.47,3.47,0,0,1,7.10835.2502c.05923,0,.11907.0014.179.00433l26.353,1.2772A3.91882,3.91882,0,0,1,37.328,5.40242V84.32906a3.96154,3.96154,0,0,1-3.68646,3.90384L7.28494,89.74378C7.214,89.74781,7.14383,89.74988,7.07379,89.74988Z"
          fill={topFillColor}
        />
        <Path
          id="gr60-perspective-outline"
          d="M7.07379,90h0a3.68516,3.68516,0,0,1-3.71821-3.73067V3.762a3.72068,3.72068,0,0,1,3.944-3.7573L33.65244,1.28183a4.17185,4.17185,0,0,1,3.92564,4.1206V84.32907a4.2149,4.2149,0,0,1-3.92228,4.15358L7.29923,89.99353C7.22339,89.9978,7.14853,90,7.07379,90ZM7.10835.50025A3.22251,3.22251,0,0,0,3.85578,3.762V86.26933a3.19024,3.19024,0,0,0,3.21807,3.23046c.06528,0,.13073-.002.19692-.00574l26.35645-1.51088a3.708,3.708,0,0,0,3.45065-3.6541V5.40243a3.66588,3.66588,0,0,0-3.44961-3.62089L7.27523.5044C7.2193.50159,7.16355.50025,7.10835.50025Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gr60-perspective-logo">
          <Path d="M18.82053,77.093l-.49665.02119V75.29381l.49665-.02028Z" fill={logoFillColor} />
          <Path
            d="M22.81845,75.6291a.90546.90546,0,0,0-.41733-.0795.52206.52206,0,0,0-.54314.50611.47258.47258,0,0,0,.54314.46155.95973.95973,0,0,0,.41733-.11377v.48656a1.72748,1.72748,0,0,1-.45208.08416.9215.9215,0,0,1-1.04767-.896,1.0161,1.0161,0,0,1,1.04767-.98175,1.603,1.603,0,0,1,.45208.04662Z"
            fill={logoFillColor}
          />
          <Path
            d="M17.64287,75.84313a.88957.88957,0,0,0-.41648-.08.52311.52311,0,0,0-.54351.50862.47342.47342,0,0,0,.54351.464.9463.9463,0,0,0,.41648-.11423v.48908a1.71016,1.71016,0,0,1-.45291.08453.92217.92217,0,0,1-1.04548-.901,1.01718,1.01718,0,0,1,1.04548-.9865,1.58007,1.58007,0,0,1,.45291.04692Z"
            fill={logoFillColor}
          />
          <Path
            d="M24.37722,75.47533a.51259.51259,0,0,0-.512.4966.46426.46426,0,0,0,.512.45521.514.514,0,0,0,.513-.498.46488.46488,0,0,0-.513-.45381m1.038.4319a1.02359,1.02359,0,0,1-1.038.98117.92467.92467,0,0,1-1.03676-.89457,1.02087,1.02087,0,0,1,1.03676-.9794.92639.92639,0,0,1,1.038.8928"
            fill={logoFillColor}
          />
          <Path
            d="M20.70012,75.60933a1.79553,1.79553,0,0,0-.38729-.039c-.19866.00818-.30652.07376-.30652.16169,0,.11.14514.14262.22658.16349l.13749.0343c.32144.08125.46831.27917.46831.4998,0,.4562-.43443.62837-.81341.64454a3.22045,3.22045,0,0,1-.53524-.02686v-.41872a1.964,1.964,0,0,0,.46809.04826c.246-.0104.36023-.08226.36023-.18552,0-.09184-.09883-.14-.22167-.17124-.0297-.00757-.07469-.0192-.10615-.02689-.27439-.06708-.50563-.21019-.50563-.51082,0-.34045.27478-.5797.73243-.59838a2.27667,2.27667,0,0,1,.48278.03744Z"
            fill={logoFillColor}
          />
          <Path
            d="M15.61826,73.44257a.22676.22676,0,0,0-.24638-.21819.24939.24939,0,0,0-.24529.23731v.47907a.22685.22685,0,0,0,.24529.21891.24909.24909,0,0,0,.24638-.23826Z"
            fill={logoFillColor}
          />
          <Path
            d="M16.97038,72.76164a.22629.22629,0,0,0-.24531-.21786.249.249,0,0,0-.247.2367v1.10708a.22693.22693,0,0,0,.247.21854.24866.24866,0,0,0,.24531-.23792Z"
            fill={logoFillColor}
          />
          <Path
            d="M18.32219,71.85238a.22637.22637,0,0,0-.24556-.21844.24887.24887,0,0,0-.24639.23685V74.289a.22725.22725,0,0,0,.24639.21865.249.249,0,0,0,.24556-.23824Z"
            fill={logoFillColor}
          />
          <Path
            d="M19.67442,72.65817a.22614.22614,0,0,0-.24513-.21726.24826.24826,0,0,0-.24641.23607v1.10411a.22628.22628,0,0,0,.24641.218.24851.24851,0,0,0,.24513-.23731Z"
            fill={logoFillColor}
          />
          <Path
            d="M21.02518,73.2323a.22542.22542,0,0,0-.24468-.217.24881.24881,0,0,0-.24622.23612v.4765a.22635.22635,0,0,0,.24622.21766.24765.24765,0,0,0,.24468-.237Z"
            fill={logoFillColor}
          />
          <Path
            d="M22.3783,72.55471a.22573.22573,0,0,0-.24556-.21663.24732.24732,0,0,0-.24513.2354v1.10114a.22542.22542,0,0,0,.24513.21739.248.248,0,0,0,.24556-.2367Z"
            fill={logoFillColor}
          />
          <Path
            d="M23.73075,71.65a.22568.22568,0,0,0-.24578-.21721.24783.24783,0,0,0-.24511.23558v2.40517a.22628.22628,0,0,0,.24511.21747.2482.2482,0,0,0,.24578-.237Z"
            fill={logoFillColor}
          />
          <Path
            d="M25.084,72.45118a.227.227,0,0,0-.24937-.21587.2478.2478,0,0,0-.24531.2348v1.09817a.22581.22581,0,0,0,.24531.21676.24941.24941,0,0,0,.24937-.23624Z"
            fill={logoFillColor}
          />
          <Path
            d="M26.43375,73.022a.226.226,0,0,0-.246-.21576.24724.24724,0,0,0-.2453.23487V73.515a.225.225,0,0,0,.2453.21646.24829.24829,0,0,0,.246-.2358Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 46.49851 20.24962" focusable={false}>
      <G focusable={false} id="gr60-profile">
        <Path
          id="gr60-profile-bottom"
          d="M13.44007,19.2672l.07758.73838a.26856.26856,0,0,0,.25091.244H32.73a.26856.26856,0,0,0,.25091-.244l.07758-.73838Z"
          fill={sideFillColor}
        />
        <Path
          id="gr60-profile-side"
          d="M46.24923,14.35508H.24928l.20446.96065v.00018l.63648,2.99045a1.26969,1.26969,0,0,0,1.18544.96084h41.9472a1.26969,1.26969,0,0,0,1.18543-.96084l.63649-2.99045v-.00018Z"
          fill={sideFillColor}
        />
        <Path
          id="gr60-profile-top"
          d="M45.77117,13.06616l-4.53172-5.106h0L39.41,5.89892,37.13209,3.33238h0L34.89629.8133A2.24472,2.24472,0,0,0,33.41515.24962H13.08336A2.24472,2.24472,0,0,0,11.60222.8133L9.36642,3.33238h0L7.08853,5.89892,5.25906,7.96018h0l-4.53172,5.106c-1.1006,1.24-.23541,2.25455,1.92263,2.25455H43.84854C46.00658,15.32071,46.87177,14.30618,45.77117,13.06616Z"
          fill={topFillColor}
        />
        <Path
          id="gr60-profile-outline"
          d="M43.84886,15.57031H2.64965c-1.32813,0-2.25879-.376-2.55371-1.03222a1.52745,1.52745,0,0,1,.44531-1.6377L11.41527.64746A2.47717,2.47717,0,0,1,13.08324,0h20.332a2.48171,2.48171,0,0,1,1.669.64746l10.873,12.25293a1.52746,1.52746,0,0,1,.44532,1.6377C46.10765,15.19434,45.177,15.57031,43.84886,15.57031ZM13.08324.5a2.01964,2.01964,0,0,0-1.294.47949L.91429,13.23242c-.25878.292-.5332.7207-.3623,1.10059.20508.45508,1.00879.7373,2.09766.7373H43.84886c1.08887,0,1.89258-.28222,2.09766-.7373.1709-.37989-.10351-.8086-.3623-1.10059L34.71019.97949A2.02454,2.02454,0,0,0,33.41527.5Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gr60-profile-logo">
          <Path d="M21.21028,10.72229h-.51675l.07419-.648H21.27Z" fill={logoFillColor} />
          <Path
            d="M25.32959,10.25978a2.50275,2.50275,0,0,0-.42793-.03458c-.32664,0-.54973.0736-.54532.17279.0043.09661.22739.17309.56914.17309a2.45568,2.45568,0,0,0,.42839-.03438l.01526.174c-.06444.00591-.23961.0232-.4686.0232-.5816,0-1.09321-.12682-1.09744-.3359-.00391-.19332.441-.33526,1.05224-.33526a4.55237,4.55237,0,0,1,.459.02327Z"
            fill={logoFillColor}
          />
          <Path
            d="M20.05205,10.25978a2.313,2.313,0,0,0-.419-.03458c-.32726,0-.56723.0736-.58591.17279-.01819.09661.18662.17309.52924.17309a2.586,2.586,0,0,0,.43551-.03438l-.02525.174c-.06515.00591-.24411.0232-.47487.0232-.58024,0-1.0614-.12682-1.017-.3359.04108-.19332.51851-.33526,1.128-.33526a4.33518,4.33518,0,0,1,.45446.02327Z"
            fill={logoFillColor}
          />
          <Path
            d="M26.914,10.2278c-.2993,0-.51106.07614-.4984.17019.01274.0945.24837.17071.55217.17071.30426,0,.5165-.07621.49941-.17071-.017-.094-.25344-.17019-.55318-.17019m1.09175.17019c.0379.18546-.38286.3359-1.01192.3359-.62858,0-1.096-.15044-1.1166-.3359-.02056-.18489.40036-.33526,1.01072-.33526.61083,0,1.08.15037,1.1178.33526"
            fill={logoFillColor}
          />
          <Path
            d="M23.16983,10.22147c-.00694-.00077-.21925-.01961-.39376-.01961-.20206,0-.31261.02179-.31383.05314-.00153.03924.14622.053.22923.06167l.14023.01426c.328.03376.47888.10659.47877.18532-.00022.16281-.45254.21764-.84707.21764-.275,0-.5298-.01616-.55609-.01772l.00926-.14923c.04513.00357.2587.02425.48277.02425.2545,0,.37329-.02391.37421-.06073.00081-.03276-.09954-.05162-.2265-.06441l-.1088-.01117c-.28187-.02811-.51488-.08247-.50821-.18961.00755-.12135.29036-.20262.753-.20262.24368,0,.47322.01912.48787.02039Z"
            fill={logoFillColor}
          />
          <Path
            d="M18.19585,9.37778c.01053-.04462-.08829-.08086-.22148-.08086-.13107,0-.24887.03624-.26039.08086l-.0439.17c-.01163.045.08912.08113.22221.08113.13522,0,.25278-.03615.2634-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M19.56014,9.15439c.00783-.04465-.09212-.08078-.2229-.08078-.13182,0-.24584.03613-.25466.08078l-.07768.39339c-.00888.045.09361.08113.22885.08113.13416,0,.24953-.03615.25742-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M20.90633,8.84911c.00514-.04485-.09508-.081-.22463-.081-.12892,0-.23966.03616-.2458.081l-.1176.86044c-.00613.04492.09979.0813.235.0813.1358,0,.24936-.03638.25451-.0813Z"
            fill={logoFillColor}
          />
          <Path
            d="M22.183,9.15439c.00239-.04465-.10216-.08078-.23253-.08078-.13162,0-.24086.03613-.24425.08078l-.02985.39339c-.0034.045.10311.08113.23813.08113.13375,0,.24494-.03615.24735-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M23.49482,9.37778c-.00031-.04462-.10734-.08086-.23949-.08086-.13236,0-.24092.03624-.24159.08086l-.00257.17c-.00068.045.10845.08113.24283.08113.13419,0,.24233-.03615.242-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M24.80576,9.15439c-.003-.04465-.11156-.08078-.24278-.08078-.13079,0-.23522.03613-.23317.08078l.018.39339c.00206.045.11253.08113.2467.08113.1346,0,.241-.03615.23792-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M26.07834,8.84911c-.00577-.04485-.11439-.081-.24457-.081-.1279,0-.22964.03616-.22485.081l.09172.86044c.00479.04492.11937.0813.25345.0813.13644,0,.24075-.03638.235-.0813Z"
            fill={logoFillColor}
          />
          <Path
            d="M27.43024,9.15439c-.00847-.04465-.12219-.08078-.25629-.08078-.13,0-.231.03613-.22354.08078l.06581.39339c.00753.045.12343.08113.25676.08113.13756,0,.24034-.03615.23181-.08113Z"
            fill={logoFillColor}
          />
          <Path
            d="M28.79541,9.37778c-.01117-.04462-.12825-.08086-.26037-.08086-.13256,0-.23125.03624-.22107.08086l.03879.17c.01025.045.1271.08113.26168.08113.13416,0,.23476-.03615.22351-.08113Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const gs1108 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 90 16.06976" focusable={false}>
      <G focusable={false} id="gs110-8p-perspective">
        <Path
          id="gs110-8p-perspective-side"
          d="M29.00263,15.79687,29.00283.26553,2.21045.82725A2.01223,2.01223,0,0,0,.26011,2.82334v9.62549a2.08681,2.08681,0,0,0,1.9481,2.05475Z"
          fill={sideFillColor}
        />
        <Path
          id="gs110-8p-perspective-side-outline"
          d="M29.26275,16.06976l-.27269-.01308L2.19561,14.76347A2.34656,2.34656,0,0,1,0,12.44884V2.8233A2.27015,2.27015,0,0,1,2.20438.56722L29.263,0Zm-.52-15.53861L2.21594,1.08733A1.74664,1.74664,0,0,0,.52023,2.8233v9.62554a1.81963,1.81963,0,0,0,1.7018,1.795l26.52049,1.28Z"
          fill={outlineFillColor}
        />
        <Path
          id="gs110-8p-perspective-front"
          d="M89.73988,12.86681a1.99512,1.99512,0,0,1-1.95062,1.98231l-58.78663.94775A1.91882,1.91882,0,0,1,27.052,13.87745V2.20207A1.9393,1.9393,0,0,1,29.00283.26553L87.78906.69724a1.97406,1.97406,0,0,1,1.95082,1.9652Z"
          fill={topFillColor}
        />
        <Path
          id="gs110-8p-perspective-front-outline"
          d="M28.97038,16.05719a2.17884,2.17884,0,0,1-2.17847-2.17974V2.20209A2.19939,2.19939,0,0,1,28.98828.00546h.01652L87.79092.43717A2.2312,2.2312,0,0,1,90,2.66237V12.86683a2.25272,2.25272,0,0,1-2.20654,2.24236l-58.78663.94774Zm.018-15.5315a1.67926,1.67926,0,0,0-1.67627,1.6764V13.87745a1.6585,1.6585,0,0,0,1.6863,1.65925L87.78508,14.589a1.7301,1.7301,0,0,0,1.69469-1.72212V2.66237A1.70961,1.70961,0,0,0,87.78711.9574L29.001.52569Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-8p-perspective-logo">
          <Path d="M81.01159,10.15984l-.39033.00323V8.58994l.39033-.00216Z" fill={logoFillColor} />
          <Path
            d="M84.15358,9.018a.678.678,0,0,0-.328-.08141.40994.40994,0,0,0-.42688.42008.403.403,0,0,0,.42688.41487.68375.68375,0,0,0,.328-.08544v.41976a1.22533,1.22533,0,0,1-.35531.05892.78435.78435,0,0,1-.82337-.80518.79777.79777,0,0,1,.82337-.81507,1.17358,1.17358,0,0,1,.35531.05418Z"
            fill={logoFillColor}
          />
          <Path
            d="M80.086,9.04371a.66536.66536,0,0,0-.32731-.082.41132.41132,0,0,0-.42716.42307.4043.4043,0,0,0,.42716.41784.67366.67366,0,0,0,.32731-.086v.42276a1.21055,1.21055,0,0,1-.35595.05933.78618.78618,0,0,1-.82165-.811.7999.7999,0,0,1,.82165-.82085,1.15471,1.15471,0,0,1,.35595.05459Z"
            fill={logoFillColor}
          />
          <Path
            d="M85.37862,8.93326a.40361.40361,0,0,0-.40236.41252.39737.39737,0,0,0,.40236.40814.40486.40486,0,0,0,.40319-.41369.39786.39786,0,0,0-.40319-.407m.81579.40412a.80458.80458,0,0,1-.81579.81425.78927.78927,0,0,1-.8148-.803.802.802,0,0,1,.8148-.81277.791.791,0,0,1,.81579.80152"
            fill={logoFillColor}
          />
          <Path
            d="M82.48877,8.93582a1.31143,1.31143,0,0,0-.30437-.04563c-.15613.00095-.24091.05427-.24091.13021,0,.095.11409.12763.1781.14815l.108.03384a.46676.46676,0,0,1,.368.44588c0,.39387-.3414.52935-.63924.53183a2.32886,2.32886,0,0,1-.42065-.03943V9.779a1.43413,1.43413,0,0,0,.36788.05594c.19331-.00149.28311-.06006.28311-.14923,0-.07931-.0778-.12391-.17423-.15466-.02334-.00744-.05871-.01887-.08341-.02647a.47611.47611,0,0,1-.39738-.45671c0-.29409.21594-.49225.5756-.49422a1.65617,1.65617,0,0,1,.37944.04724Z"
            fill={logoFillColor}
          />
          <Path
            d="M78.49489,6.90467a.19325.19325,0,1,0-.38642.001v.41451a.19326.19326,0,1,0,.38642-.00128Z"
            fill={logoFillColor}
          />
          <Path
            d="M79.55752,6.35863a.19347.19347,0,1,0-.3869.00063v.95741a.19349.19349,0,1,0,.3869-.00128Z"
            fill={logoFillColor}
          />
          <Path
            d="M80.61992,5.61581a.19333.19333,0,1,0-.38661.00014V7.70612a.19335.19335,0,1,0,.38661-.00154Z"
            fill={logoFillColor}
          />
          <Path
            d="M81.68264,6.35515a.19317.19317,0,1,0-.3863.00064v.95386a.19318.19318,0,1,0,.3863-.00128Z"
            fill={logoFillColor}
          />
          <Path
            d="M82.74422,6.8937a.19338.19338,0,0,0-.19229-.19511.19539.19539,0,0,0-.19347.1961v.41145a.19291.19291,0,1,0,.38576-.00127Z"
            fill={logoFillColor}
          />
          <Path
            d="M83.80767,6.35168a.19283.19283,0,1,0-.38563.00063v.95032a.19284.19284,0,1,0,.38563-.00128Z"
            fill={logoFillColor}
          />
          <Path
            d="M84.87058,5.61436a.19292.19292,0,1,0-.38582.00013V7.68917a.19294.19294,0,1,0,.38582-.00154Z"
            fill={logoFillColor}
          />
          <Path
            d="M85.9341,6.3482a.19439.19439,0,0,0-.38878.00063v.94678a.19341.19341,0,0,0,.1928.19456.19562.19562,0,0,0,.196-.19584Z"
            fill={logoFillColor}
          />
          <Path
            d="M86.99488,6.88272a.193.193,0,1,0-.38607.001V7.2921a.19258.19258,0,0,0,.1928.19419.19486.19486,0,0,0,.19327-.19547Z"
            fill={logoFillColor}
          />
        </G>
        <Polygon
          id="gs110-8p-perspective-ports"
          points="75.2 10.236 35.597 10.565 35.597 5.439 75.2 5.439 75.2 10.236"
          fill={activePortFillColor}
        />
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 90.5 21.74902" focusable={false}>
      <G focusable={false} id="gs110-8p-profile">
        <Path
          id="gs110-8p-profile-front"
          d="M90.25,19.49917a2.00588,2.00588,0,0,1-2,2h-86a2.00588,2.00588,0,0,1-2-2v-8a2.00588,2.00588,0,0,1,2-2h86a2.00588,2.00588,0,0,1,2,2Z"
          fill={otherSideFillColor}
        />
        <Polygon
          id="gs110-8p-profile-top"
          points="74.378 0 16.122 0 2.25 9.499 88.25 9.499 74.378 0"
          fill={topFillColor}
        />
        <Path
          id="gs110-8p-profile-outline"
          d="M88.25,21.749h-86A2.25309,2.25309,0,0,1,0,19.499v-8a2.25309,2.25309,0,0,1,2.25-2.25h86a2.25309,2.25309,0,0,1,2.25,2.25v8A2.25309,2.25309,0,0,1,88.25,21.749Zm-86-12A1.75213,1.75213,0,0,0,.5,11.499v8a1.75212,1.75212,0,0,0,1.75,1.75h86A1.75212,1.75212,0,0,0,90,19.499v-8a1.75213,1.75213,0,0,0-1.75-1.75Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-8p-profile-ports">
          <Rect x="8.25" y="12.99917" width="56" height="5" fill={inactivePortFillColor} />
          <Rect x="68.25" y="12.99917" width="14" height="5" fill={inactivePortFillColor} />
        </G>
        <G focusable={false} id="gs110-8p-profile-logo">
          <Path d="M42.99843,6.48969h-.57062l.10268-.74172h.54986Z" fill={logoFillColor} />
          <Path
            d="M47.5311,5.96033a2.65568,2.65568,0,0,0-.47066-.0396c-.35832,0-.60152.08425-.59462.1978.00671.11055.25415.1981.63078.1981a2.58744,2.58744,0,0,0,.47092-.03935l.02287.19915a5.17189,5.17189,0,0,1-.51672.02653c-.64232,0-1.20892-.14513-1.21619-.38443-.00671-.22128.47844-.38377,1.14752-.38377a4.79278,4.79278,0,0,1,.50326.02665Z"
            fill={logoFillColor}
          />
          <Path
            d="M41.739,5.96033a2.40671,2.40671,0,0,0-.45818-.0396c-.359,0-.62651.08425-.65272.1978-.02553.11055.19583.1981.57342.1981a2.7764,2.7764,0,0,0,.48143-.03935l-.03521.19915c-.07219.00675-.27059.02653-.52543.02653-.64083,0-1.16407-.14513-1.10154-.38443.05781-.22128.58981-.38377,1.257-.38377a4.50886,4.50886,0,0,1,.49643.02665Z"
            fill={logoFillColor}
          />
          <Path
            d="M49.26814,5.92371c-.32834,0-.55676.08715-.538.19482.0188.10815.28234.19535.61713.19535.33531,0,.56418-.0872.53912-.19535-.02495-.10767-.28938-.19482-.61821-.19482m1.21039.19482c.05545.21227-.39824.38443-1.093.38443-.69422,0-1.21682-.17216-1.24745-.38443-.03053-.21162.42363-.38377,1.09171-.38377.66858,0,1.19342.17215,1.2487.38377"
            fill={logoFillColor}
          />
          <Path
            d="M45.16061,5.91647c-.00761-.00089-.24049-.02245-.43187-.02245-.22158,0-.343.02495-.34455.06084-.002.0449.1602.06067.25134.07056l.154.01634c.36032.03862.52665.122.52694.21211.00061.18635-.49864.24909-.93435.24909-.30365,0-.5849-.01849-.6139-.02026l.01242-.17081c.04969.0041.28482.02776.53185.02776.28053,0,.41154-.02737.41264-.06953.001-.03749-.10945-.05908-.24911-.0737l-.11965-.0128c-.30972-.03213-.56532-.09439-.55636-.217.01013-.13889.32094-.2319.82728-.2319.26673,0,.518.02188.53406.02332Z"
            fill={logoFillColor}
          />
          <Path
            d="M39.76685,4.9508c.01486-.05107-.08933-.09254-.23351-.09254-.14191,0-.27251.04147-.28877.09254l-.062.19457c-.0164.0515.09017.09286.235.09286.14709,0,.27772-.04136.29271-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M41.258,4.69511c.011-.05108-.09482-.09244-.23592-.09244-.14223,0-.26758.04136-.28.09244l-.10916.45026c-.0125.0515.09676.09286.24387.09286.14595,0,.27343-.04136.2845-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M42.72285,4.3457c.00712-.05134-.09921-.09274-.23831-.09274-.13844,0-.25892.0414-.26747.09274l-.16373.98484c-.00856.0514.10546.09306.25283.09306.14808,0,.27315-.04166.28026-.09306Z"
            fill={logoFillColor}
          />
          <Path
            d="M44.09135,4.69511c.00319-.05108-.10913-.09244-.2498-.09244-.142,0-.26063.04136-.26523.09244l-.04054.45026c-.00462.0515.11051.09286.25739.09286.14549,0,.26692-.04136.27012-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.51025,4.9508c-.0007-.05107-.11685-.09254-.25991-.09254-.1433,0-.26081.04147-.26151.09254l-.0027.19457c-.00072.0515.118.09286.26422.09286.146,0,.26331-.04136.2626-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M46.92455,4.69511c-.0046-.05108-.12278-.09244-.26435-.09244-.14111,0-.253.04136-.24978.09244l.0281.45026c.00322.0515.12418.09286.27014.09286.14643,0,.26108-.04136.25644-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M48.28358,4.3457c-.00853-.05134-.12706-.09274-.26683-.09274-.13732,0-.245.0414-.23787.09274l.13653.98484c.00712.0514.1336.09306.2798.09306.14878,0,.26071-.04166.25215-.09306Z"
            fill={logoFillColor}
          />
          <Path
            d="M49.75963,4.69511c-.01239-.05108-.13773-.09244-.28241-.09244-.14023,0-.24689.04136-.23592.09244l.09665.45026c.01106.0515.13945.09286.2845.09286.14964,0,.25888-.04136.2464-.09286Z"
            fill={logoFillColor}
          />
          <Path
            d="M51.25543,4.9508c-.01626-.05107-.14641-.09254-.28946-.09254-.14349,0-.24722.04147-.23237.09254l.05662.19457c.015.0515.14516.09286.29157.09286.14593,0,.252-.04136.23564-.09286Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const gs11024 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 98.5 16.75195" focusable={false}>
      <G focusable={false} id="gs110-24p-perspective">
        <Path
          id="gs110-24p-perspective-side"
          d="M.25,13.71551a1.73558,1.73558,0,0,0,1.62021,1.70891L20.91235,16.4398a1.52574,1.52574,0,0,0,1.62021-1.53612V1.92522A1.54373,1.54373,0,0,0,20.91159.3734L1.871,1.20389A1.71536,1.71536,0,0,0,.25,2.89711Z"
          fill={sideFillColor}
        />
        <Path
          id="gs110-24p-perspective-side-outline"
          d="M21.00049,16.69141c-.03369,0-.06787,0-.10156-.002L1.85693,15.6748A1.98653,1.98653,0,0,1,0,13.71582V2.89746A1.96546,1.96546,0,0,1,1.86035.9541l19.04053-.831A1.7948,1.7948,0,0,1,22.78271,1.9248V14.90332a1.77482,1.77482,0,0,1-1.78222,1.78809ZM20.98145.62109c-.01954,0-.03956.001-.05909.002L1.88184,1.4541A1.45966,1.45966,0,0,0,.5,2.89746V13.71582a1.47939,1.47939,0,0,0,1.3833,1.459l19.042,1.01465a1.26292,1.26292,0,0,0,.95948-.34668,1.28111,1.28111,0,0,0,.39794-.93945V1.9248A1.29474,1.29474,0,0,0,20.98145.62109Z"
          fill={outlineFillColor}
        />
        <Path
          id="gs110-24p-perspective-front"
          d="M19.3957,14.90535a1.59594,1.59594,0,0,0,1.6223,1.59643l75.60969-1.21569A1.65924,1.65924,0,0,0,98.25,13.63749V2.89873a1.65364,1.65364,0,0,0-1.62237-1.64407L21.01807.25039a1.60135,1.60135,0,0,0-1.62237,1.601Z"
          fill={topFillColor}
        />
        <Path
          id="gs110-24p-perspective-front-outline"
          d="M20.99072,16.752a1.8453,1.8453,0,0,1-1.84521-1.84668V1.85156A1.837,1.837,0,0,1,19.69678.53418,1.91906,1.91906,0,0,1,21.02148,0L96.63086,1.00488A1.90108,1.90108,0,0,1,98.5,2.89844V13.6377a1.90709,1.90709,0,0,1-1.86816,1.89843L21.022,16.752ZM20.99658.5a1.35194,1.35194,0,0,0-1.35107,1.35156V14.90527a1.34521,1.34521,0,0,0,1.3457,1.34668h.023L96.624,15.03613A1.40516,1.40516,0,0,0,98,13.6377V2.89844a1.39878,1.39878,0,0,0-1.375-1.39356L21.01465.5Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-24p-perspective-ports">
          <Polygon
            points="85.23 7.368 56.843 7.37 56.843 3.251 85.23 3.467 85.23 7.368"
            fill={activePortFillColor}
          />
          <Polygon
            points="85.23 13.069 56.843 13.39 56.843 9.271 85.23 9.168 85.23 13.069"
            fill={activePortFillColor}
          />
        </G>
        <G focusable={false} id="gs110-24p-perspective-logo">
          <Path d="M90.6693,10.01416l-.2926.00226v-1.174l.2926-.00153Z" fill={logoFillColor} />
          <Path
            d="M93.02474,9.16272a.51044.51044,0,0,0-.24588-.06085.30654.30654,0,0,0-.32.31347.30164.30164,0,0,0,.32.30976.5136.5136,0,0,0,.24588-.06372v.31335a.92171.92171,0,0,1-.26635.04389.5871.5871,0,0,1-.61725-.6012.59658.59658,0,0,1,.61725-.60822.88507.88507,0,0,1,.26635.04053Z"
            fill={logoFillColor}
          />
          <Path
            d="M89.97548,9.181a.5004.5004,0,0,0-.24539-.06128.30752.30752,0,0,0-.32022.31555.30254.30254,0,0,0,.32022.31195.50563.50563,0,0,0,.24539-.06415V9.9986a.91189.91189,0,0,1-.26685.04412.58833.58833,0,0,1-.616-.6054.598.598,0,0,1,.616-.61231.8701.8701,0,0,1,.26685.04077Z"
            fill={logoFillColor}
          />
          <Path
            d="M93.94312,9.09973a.3018.3018,0,0,0-.30165.30786.29734.29734,0,0,0,.30165.30475.30269.30269,0,0,0,.30225-.30871.29774.29774,0,0,0-.30225-.3039m.61154.30188a.60158.60158,0,0,1-.61154.60761.5907.5907,0,0,1-.61083-.59961.59969.59969,0,0,1,.61083-.60651.592.592,0,0,1,.61154.59851"
            fill={logoFillColor}
          />
          <Path
            d="M91.7767,9.101a.98668.98668,0,0,0-.22818-.03418c-.117.00067-.18059.04047-.18059.09717,0,.07092.08551.09528.1335.1106l.081.02527a.3489.3489,0,0,1,.27592.33288c0,.294-.25595.395-.47924.39679A1.75414,1.75414,0,0,1,91.06377,10V9.73a1.07774,1.07774,0,0,0,.27577.04181c.14493-.001.21224-.04474.21224-.11127,0-.0592-.05765-.09283-.1306-.11548l-.06255-.01977a.356.356,0,0,1-.29789-.34094c0-.21949.16189-.36737.43152-.36878a1.24979,1.24979,0,0,1,.28444.03534Z"
            fill={logoFillColor}
          />
          <Path
            d="M88.78265,7.58441a.14486.14486,0,1,0-.28968.00067v.30933a.14487.14487,0,1,0,.28968-.00092Z"
            fill={logoFillColor}
          />
          <Path
            d="M89.57927,7.17712a.145.145,0,1,0-.29006.00037V7.892a.145.145,0,1,0,.29006-.00092Z"
            fill={logoFillColor}
          />
          <Path
            d="M90.37569,6.62286a.14493.14493,0,1,0-.28984.00006V8.1828a.14494.14494,0,1,0,.28984-.0011Z"
            fill={logoFillColor}
          />
          <Path
            d="M91.17238,7.17474a.1448.1448,0,1,0-.28959.00043v.71191a.14481.14481,0,1,0,.28959-.00091Z"
            fill={logoFillColor}
          />
          <Path
            d="M91.96821,7.57684a.14466.14466,0,0,0-.14417-.14563.14613.14613,0,0,0-.145.14637v.30706a.14462.14462,0,1,0,.28922-.00091Z"
            fill={logoFillColor}
          />
          <Path
            d="M92.76541,7.17242a.14454.14454,0,1,0-.28908.00043V7.8822a.14455.14455,0,1,0,.28908-.00091Z"
            fill={logoFillColor}
          />
          <Path
            d="M93.56224,6.62219a.14461.14461,0,1,0-.28922.00006V8.1709a.14462.14462,0,1,0,.28922-.0011Z"
            fill={logoFillColor}
          />
          <Path
            d="M94.3595,7.1701a.14572.14572,0,0,0-.29144.00043v.70679a.14473.14473,0,0,0,.14453.14526.14633.14633,0,0,0,.14691-.14618Z"
            fill={logoFillColor}
          />
          <Path
            d="M95.15475,7.56934a.14472.14472,0,1,0-.28943.00067v.30487a.144.144,0,0,0,.14453.145.1457.1457,0,0,0,.1449-.14588Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 98.5 28.24967" focusable={false}>
      <G focusable={false} id="gs110-24p-profile">
        <Polygon
          id="gs110-24p-profile-top"
          points="73.904 0 24.596 0 2.25 14 96.25 14 73.904 0"
          fill={topFillColor}
        />
        <Path
          id="gs110-24p-profile-front"
          d="M96.25,28h-94a2,2,0,0,1-2-2V16a2,2,0,0,1,2-2h94a2,2,0,0,1,2,2V26A2,2,0,0,1,96.25,28Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gs110-24p-profile-outline"
          d="M96.25,28.24967h-94a2.25309,2.25309,0,0,1-2.25-2.25v-10a2.25309,2.25309,0,0,1,2.25-2.25h94a2.25309,2.25309,0,0,1,2.25,2.25v10A2.25309,2.25309,0,0,1,96.25,28.24967Zm-94-14a1.75213,1.75213,0,0,0-1.75,1.75v10a1.75212,1.75212,0,0,0,1.75,1.75h94a1.75212,1.75212,0,0,0,1.75-1.75v-10a1.75213,1.75213,0,0,0-1.75-1.75Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-24p-profile-ports">
          <Rect x="43.25" y="16.5" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="43.25" y="21.5" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="81.25" y="21.5" width="12" height="4" fill={inactivePortFillColor} />
          <Rect x="81.25" y="16.5" width="12" height="4" fill={inactivePortFillColor} />
        </G>
        <G focusable={false} id="gs110-24p-profile-logo">
          <Path d="M46.96095,9.48673h-.58013l.18607-.74174h.5425Z" fill={logoFillColor} />
          <Path
            d="M51.52071,8.95736a2.69758,2.69758,0,0,0-.47148-.03959c-.3561,0-.59359.08425-.5811.19779.01217.11054.26631.19809.64664.19809a2.5732,2.5732,0,0,0,.47193-.03933l.04145.19914c-.07147.00676-.26591.02654-.52316.02654-.65335,0-1.23319-.14515-1.24634-.38444-.01218-.22129.46213-.38376,1.12189-.38376a4.75262,4.75262,0,0,1,.49877.02662Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.755,8.95736a2.27971,2.27971,0,0,0-.44962-.03959c-.35676,0-.63853.08425-.68605.19779-.04625.11054.16106.19809.54237.19809a2.94416,2.94416,0,0,0,.49175-.03933l-.06381.19914c-.0743.00676-.279.02654-.53819.02654-.65182,0-1.1539-.14515-1.04061-.38444.10476-.22129.66442-.38376,1.32229-.38376a4.28177,4.28177,0,0,1,.4856.02662Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.24378,8.92072c-.32636,0-.541.08718-.50708.19484.03408.10813.31234.19535.6504.19535.33857,0,.55346-.08722.50806-.19535-.0452-.10766-.32455-.19484-.65138-.19484m1.24469.19484c.10047.21225-.32524.38444-1.0319.38444-.70615,0-1.25746-.17219-1.313-.38444-.05533-.21161.372-.38376,1.03076-.38376.65924,0,1.21392.17215,1.3141.38376"
            fill={logoFillColor}
          />
          <Path
            d="M49.16119,8.9135c-.00756-.00089-.23866-.02244-.42864-.02244-.22,0-.34138.02493-.34421.06081-.00355.04491.15763.06068.24835.07056l.15338.01636c.35915.03862.52634.122.52687.21212.00112.18634-.50655.24909-.94974.24909-.30887,0-.59389-.01851-.62327-.02028l.02252-.1708c.04993.0041.28626.02776.53593.02776.28356,0,.41656-.02738.41855-.06955.00176-.03748-.10891-.059-.24882-.07369l-.11979-.0128c-.31017-.0322-.56232-.09436-.5461-.217.01837-.13885.33045-.23189.82974-.23189.263,0,.5107.02189.52652.02332Z"
            fill={logoFillColor}
          />
          <Path
            d="M44.03169,7.94784c.0269-.05108-.06135-.09254-.198-.09254a.40853.40853,0,0,0-.2991.09254l-.11235.19455c-.02973.0515.0619.09287.20156.09287.1419,0,.27812-.04137.30527-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.49807,7.69216c.01988-.05113-.0713-.09246-.2033-.09246-.133,0-.2591.04133-.28155.09246l-.19783.45023c-.02264.0515.07425.09287.21615.09287.14079,0,.2713-.04137.29133-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M46.917,7.34271c.01289-.0513-.07966-.09271-.20744-.09271-.12717,0-.244.04141-.25947.09271l-.29671.98484c-.01549.05142.08961.09309.233.09309.144,0,.27054-.04167.28344-.09309Z"
            fill={logoFillColor}
          />
          <Path
            d="M48.161,7.69216c.00577-.05113-.09705-.09246-.22864-.09246-.13286,0-.2471.04133-.25543.09246l-.07346.45023c-.00839.0515.0995.09287.2412.09287.14035,0,.25966-.04137.26547-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M49.49769,7.94784c-.00128-.05108-.11181-.09254-.24737-.09254-.13576,0-.24759.04146-.24887.09254l-.00489.19455c-.0013.0515.11275.09287.25377.09287.14082,0,.25352-.04137.25223-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M50.82385,7.69216c-.00833-.05113-.12215-.09246-.25458-.09246-.132,0-.23441.04133-.22863.09246l.05093.45023c.00583.0515.12469.09287.26549.09287.14125,0,.24869-.04137.24029-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M52.0505,7.34271C52.035,7.29141,51.92,7.25,51.79159,7.25c-.12615,0-.21992.04141-.207.09271l.24741.98484c.01291.05142.14079.09309.283.09309.14473,0,.2478-.04167.23229-.09309Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.48845,7.69216C53.466,7.641,53.34,7.5997,53.2046,7.5997c-.13119,0-.22318.04133-.2033.09246l.17516.45023c.02.0515.15141.09287.29132.09287.14436,0,.24123-.04137.2186-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.96537,7.94784a.40845.40845,0,0,0-.29973-.09254c-.136,0-.2238.04146-.19688.09254l.10258.19455c.02715.0515.16292.09287.30415.09287.14077,0,.232-.04137.20223-.09287Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const gs11048 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 98.5 16.75238" focusable={false}>
      <G focusable={false} id="gs110-48p-perspective">
        <Path
          id="gs110-48p-perspective-side"
          d="M.25,13.71579A1.73558,1.73558,0,0,0,1.87021,15.4247l19.04214,1.01538A1.52574,1.52574,0,0,0,22.53256,14.904V1.9255a1.54372,1.54372,0,0,0-1.621-1.55181L1.871,1.20417A1.71537,1.71537,0,0,0,.25,2.89739Z"
          fill={sideFillColor}
        />
        <Path
          id="gs110-48p-perspective-side-outline"
          d="M21.00049,16.69183c-.03369,0-.06787,0-.10156-.002l-19.042-1.01563A1.98493,1.98493,0,0,1,0,13.71625V2.89691A1.96321,1.96321,0,0,1,1.86035.95453L20.90088.12347a1.794,1.794,0,0,1,1.88183,1.80176V14.90375a1.77482,1.77482,0,0,1-1.78222,1.78808ZM20.98291.62152c-.02,0-.04.001-.06055.002L1.88184,1.45453A1.45773,1.45773,0,0,0,.5,2.89691V13.71625a1.47779,1.47779,0,0,0,1.3833,1.458l19.042,1.01563a1.252,1.252,0,0,0,.95948-.34668,1.28111,1.28111,0,0,0,.39794-.93945V1.92523A1.29386,1.29386,0,0,0,20.98291.62152Z"
          fill={outlineFillColor}
        />
        <Path
          id="gs110-48p-perspective-front"
          d="M19.3957,14.90564a1.596,1.596,0,0,0,1.6223,1.59643l75.60969-1.2157A1.65924,1.65924,0,0,0,98.25,13.63777V2.899A1.65363,1.65363,0,0,0,96.62763,1.255L21.01807.25067a1.60136,1.60136,0,0,0-1.62237,1.601Z"
          fill={topFillColor}
        />
        <Path
          id="gs110-48p-perspective-front-outline"
          d="M20.99268,16.75238a1.847,1.847,0,0,1-1.84717-1.84668V1.852A1.83693,1.83693,0,0,1,19.69678.53461a1.85958,1.85958,0,0,1,1.3247-.53418L96.63086,1.00531A1.90107,1.90107,0,0,1,98.5,2.89886V13.63812a1.90709,1.90709,0,0,1-1.86816,1.89844L21.022,16.75238ZM20.99658.50043A1.35194,1.35194,0,0,0,19.64551,1.852V14.9057a1.33656,1.33656,0,0,0,.40137.959,1.4361,1.4361,0,0,0,.96728.3877L96.624,15.03656A1.40517,1.40517,0,0,0,98,13.63812V2.89886a1.39878,1.39878,0,0,0-1.375-1.39355L21.01465.50043Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-48p-perspective-ports">
          <Polygon
            points="56.302 9.273 25.073 9.386 25.073 13.751 56.302 13.397 56.302 9.273"
            fill={activePortFillColor}
          />
          <Polygon
            points="57.384 9.269 57.384 13.385 85.23 13.069 85.23 9.169 57.384 9.269"
            fill={activePortFillColor}
          />
          <Polygon
            points="56.302 3.246 25.073 3.006 25.073 7.371 56.302 7.37 56.302 3.246"
            fill={activePortFillColor}
          />
          <Polygon
            points="57.384 3.254 57.384 7.37 85.23 7.368 85.23 3.468 57.384 3.254"
            fill={activePortFillColor}
          />
        </G>
        <G focusable={false} id="gs110-48p-perspective-logo">
          <Path d="M90.6693,10.0144l-.2926.00232v-1.174l.2926-.00152Z" fill={logoFillColor} />
          <Path
            d="M93.02474,9.163a.50905.50905,0,0,0-.24588-.06085.30652.30652,0,0,0-.32.31342.30164.30164,0,0,0,.32.30981.51423.51423,0,0,0,.24588-.06372V9.975a.92282.92282,0,0,1-.26635.04382.58706.58706,0,0,1-.61725-.6012.59656.59656,0,0,1,.61725-.60815.881.881,0,0,1,.26635.04053Z"
            fill={logoFillColor}
          />
          <Path
            d="M89.97548,9.18121a.50113.50113,0,0,0-.24539-.06128.30754.30754,0,0,0-.32022.31562.30258.30258,0,0,0,.32022.312.50661.50661,0,0,0,.24539-.06415v.31549a.91115.91115,0,0,1-.26685.04419.58835.58835,0,0,1-.616-.60541.59808.59808,0,0,1,.616-.61236.871.871,0,0,1,.26685.04083Z"
            fill={logoFillColor}
          />
          <Path
            d="M93.94312,9.1a.30175.30175,0,0,0-.30165.30786.29741.29741,0,0,0,.30165.30475.30273.30273,0,0,0,.30225-.30872A.2977.2977,0,0,0,93.94312,9.1m.61154.30188a.60162.60162,0,0,1-.61154.6076.59069.59069,0,0,1-.61083-.59961.59966.59966,0,0,1,.61083-.6065.59192.59192,0,0,1,.61154.59851"
            fill={logoFillColor}
          />
          <Path
            d="M91.7767,9.10126a.991.991,0,0,0-.22818-.03412c-.117.00067-.18059.04046-.18059.09711,0,.07092.08551.09527.1335.11065l.081.02527a.3488.3488,0,0,1,.27592.33289c0,.29394-.25595.39495-.47924.39672a1.75312,1.75312,0,0,1-.31533-.02954V9.73029a1.08057,1.08057,0,0,0,.27577.04187c.14493-.001.21224-.04474.21224-.11133,0-.05915-.05765-.09284-.1306-.11542l-.06255-.01978a.356.356,0,0,1-.29789-.341c0-.21948.16189-.36731.43152-.36871a1.246,1.246,0,0,1,.28444.03534Z"
            fill={logoFillColor}
          />
          <Path
            d="M88.78265,7.58472a.14486.14486,0,1,0-.28968.00067v.30932a.14487.14487,0,1,0,.28968-.00091Z"
            fill={logoFillColor}
          />
          <Path
            d="M89.57927,7.17737a.145.145,0,1,0-.29006.00043v.71447a.145.145,0,1,0,.29006-.00091Z"
            fill={logoFillColor}
          />
          <Path
            d="M90.37569,6.62317a.14493.14493,0,1,0-.28984.00006V8.183a.14494.14494,0,1,0,.28984-.001Z"
            fill={logoFillColor}
          />
          <Path
            d="M91.17238,7.17505a.1448.1448,0,1,0-.28959.00043v.71185a.14481.14481,0,1,0,.28959-.00086Z"
            fill={logoFillColor}
          />
          <Path
            d="M91.96821,7.57715a.14462.14462,0,1,0-.28922.00067v.30707a.14462.14462,0,1,0,.28922-.00086Z"
            fill={logoFillColor}
          />
          <Path
            d="M92.76541,7.17273a.14454.14454,0,1,0-.28908.00043v.70929a.14455.14455,0,1,0,.28908-.00086Z"
            fill={logoFillColor}
          />
          <Path
            d="M93.56224,6.6225a.14461.14461,0,1,0-.28922.00006V8.1712a.14462.14462,0,1,0,.28922-.0011Z"
            fill={logoFillColor}
          />
          <Path
            d="M94.3595,7.17041a.14572.14572,0,0,0-.29144.00043v.70672a.14476.14476,0,0,0,.14453.14533.14634.14634,0,0,0,.14691-.14618Z"
            fill={logoFillColor}
          />
          <Path
            d="M95.15475,7.56958a.14472.14472,0,1,0-.28943.00067v.30487a.14409.14409,0,0,0,.14453.145.14569.14569,0,0,0,.1449-.14587Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 98.5 28.25" focusable={false}>
      <G focusable={false} id="gs110-48p-profile">
        <Polygon
          id="gs110-48p-profile-top"
          points="73.904 0 24.596 0 2.25 14 96.25 14 73.904 0"
          fill={topFillColor}
        />
        <Path
          id="gs110-48p-profile-front"
          d="M96.25,28h-94a2,2,0,0,1-2-2V16a2,2,0,0,1,2-2h94a2,2,0,0,1,2,2V26A2,2,0,0,1,96.25,28Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gs110-48p-profile-outline"
          d="M96.25,28.25h-94A2.25309,2.25309,0,0,1,0,26V16a2.25309,2.25309,0,0,1,2.25-2.25h94A2.25309,2.25309,0,0,1,98.5,16V26A2.25309,2.25309,0,0,1,96.25,28.25Zm-94-14A1.75213,1.75213,0,0,0,.5,16V26a1.75213,1.75213,0,0,0,1.75,1.75h94A1.75213,1.75213,0,0,0,98,26V16a1.75213,1.75213,0,0,0-1.75-1.75Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gs110-48p-profile-ports">
          <Rect x="43.25" y="16.4588" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="43.25" y="21.4588" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="5.25" y="16.4588" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="5.25" y="21.4588" width="37" height="4" fill={inactivePortFillColor} />
          <Rect x="81.25" y="21.4588" width="12" height="4" fill={inactivePortFillColor} />
          <Rect x="81.25" y="16.4588" width="12" height="4" fill={inactivePortFillColor} />
        </G>
        <G focusable={false} id="gs110-48p-profile-logo">
          <Path d="M46.96095,9.48673h-.58013l.18607-.74174h.5425Z" fill={logoFillColor} />
          <Path
            d="M51.52071,8.95736a2.69758,2.69758,0,0,0-.47148-.03959c-.3561,0-.59359.08425-.5811.19779.01217.11054.26631.19809.64664.19809a2.5732,2.5732,0,0,0,.47193-.03933l.04145.19914c-.07147.00676-.26591.02654-.52316.02654-.65335,0-1.23319-.14515-1.24634-.38444-.01218-.22129.46213-.38376,1.12189-.38376a4.75262,4.75262,0,0,1,.49877.02662Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.755,8.95736a2.27971,2.27971,0,0,0-.44962-.03959c-.35676,0-.63853.08425-.68605.19779-.04625.11054.16106.19809.54237.19809a2.94416,2.94416,0,0,0,.49175-.03933l-.06381.19914c-.0743.00676-.279.02654-.53819.02654-.65182,0-1.1539-.14515-1.04061-.38444.10476-.22129.66442-.38376,1.32229-.38376a4.28177,4.28177,0,0,1,.4856.02662Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.24378,8.92072c-.32636,0-.541.08718-.50708.19484.03408.10813.31234.19535.6504.19535.33857,0,.55346-.08722.50806-.19535-.0452-.10766-.32455-.19484-.65138-.19484m1.24469.19484c.10047.21225-.32524.38444-1.0319.38444-.70615,0-1.25746-.17219-1.313-.38444-.05533-.21161.372-.38376,1.03076-.38376.65924,0,1.21392.17215,1.3141.38376"
            fill={logoFillColor}
          />
          <Path
            d="M49.16119,8.9135c-.00756-.00089-.23866-.02244-.42864-.02244-.22,0-.34138.02493-.34421.06081-.00355.04491.15763.06068.24835.07056l.15338.01636c.35915.03862.52634.122.52687.21212.00112.18634-.50655.24909-.94974.24909-.30887,0-.59389-.01851-.62327-.02028l.02252-.1708c.04993.0041.28626.02776.53593.02776.28356,0,.41656-.02738.41855-.06955.00176-.03748-.10891-.059-.24882-.07369l-.11979-.0128c-.31017-.0322-.56232-.09436-.5461-.217.01837-.13885.33045-.23189.82974-.23189.263,0,.5107.02189.52652.02332Z"
            fill={logoFillColor}
          />
          <Path
            d="M44.03169,7.94784c.0269-.05108-.06135-.09254-.198-.09254a.40853.40853,0,0,0-.2991.09254l-.11235.19455c-.02973.0515.0619.09287.20156.09287.1419,0,.27812-.04137.30527-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M45.49807,7.69216c.01988-.05113-.0713-.09246-.2033-.09246-.133,0-.2591.04133-.28155.09246l-.19783.45023c-.02264.0515.07425.09287.21615.09287.14079,0,.2713-.04137.29133-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M46.917,7.34271c.01289-.0513-.07966-.09271-.20744-.09271-.12717,0-.244.04141-.25947.09271l-.29671.98484c-.01549.05142.08961.09309.233.09309.144,0,.27054-.04167.28344-.09309Z"
            fill={logoFillColor}
          />
          <Path
            d="M48.161,7.69216c.00577-.05113-.09705-.09246-.22864-.09246-.13286,0-.2471.04133-.25543.09246l-.07346.45023c-.00839.0515.0995.09287.2412.09287.14035,0,.25966-.04137.26547-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M49.49769,7.94784c-.00128-.05108-.11181-.09254-.24737-.09254-.13576,0-.24759.04146-.24887.09254l-.00489.19455c-.0013.0515.11275.09287.25377.09287.14082,0,.25352-.04137.25223-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M50.82385,7.69216c-.00833-.05113-.12215-.09246-.25458-.09246-.132,0-.23441.04133-.22863.09246l.05093.45023c.00583.0515.12469.09287.26549.09287.14125,0,.24869-.04137.24029-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M52.0505,7.34271C52.035,7.29141,51.92,7.25,51.79159,7.25c-.12615,0-.21992.04141-.207.09271l.24741.98484c.01291.05142.14079.09309.283.09309.14473,0,.2478-.04167.23229-.09309Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.48845,7.69216C53.466,7.641,53.34,7.5997,53.2046,7.5997c-.13119,0-.22318.04133-.2033.09246l.17516.45023c.02.0515.15141.09287.29132.09287.14436,0,.24123-.04137.2186-.09287Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.96537,7.94784a.40845.40845,0,0,0-.29973-.09254c-.136,0-.2238.04146-.19688.09254l.10258.19455c.02715.0515.16292.09287.30415.09287.14077,0,.232-.04137.20223-.09287Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const gx20 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 68 12" focusable={false}>
      <G focusable={false} id="gx20-perspective">
        <Path
          id="gx20-perspective-side"
          d="M20.15417.10917a1.02288,1.02288,0,0,1,1.09858.96205V10.9618a.98469.98469,0,0,1-1.097.92759L5.097,11.08794A1.11948,1.11948,0,0,1,4,10.04359V.94573A.539.539,0,0,1,4.54929.44078Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gx20-perspective-front"
          d="M63.45058.35087,22.35162.08006C21.568.0749,20.52282.08891,20.15416.09675h0L19.15367.118V11.82364l1.0021.05333c.54811.02917,1.64675.05037,2.19574.04238l40.54975-.59042A1.06871,1.06871,0,0,0,64,10.327V.84748A.52926.52926,0,0,0,63.45058.35087Z"
          fill={topFillColor}
        />
        <Path
          id="gx20-perspective-led"
          d="M30.80907,7.3508c0,.40216-.46144.734-1.02543.73733l-1.76487.01055c-.564.00337-1.02544-.32466-1.02544-.729s.46145-.73691,1.02545-.73913l1.76484-.00695C30.34762,6.62141,30.80907,6.94864,30.80907,7.3508Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gx20-perspective-logo">
          <Path d="M55.13453,7.91374l-.34381.00286V6.55116l.34381-.00192Z" fill={logoFillColor} />
          <Path
            d="M57.902,6.92234a.604.604,0,0,0-.28889-.07062.35855.35855,0,0,0-.376.36464.35246.35246,0,0,0,.376.36.609.609,0,0,0,.28889-.07421v.36432a1.09413,1.09413,0,0,1-.313.05118.68613.68613,0,0,1-.72523-.69871.69776.69776,0,0,1,.72523-.7075,1.04787,1.04787,0,0,1,.313.047Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.31931,6.94507a.59232.59232,0,0,0-.2883-.07117.35976.35976,0,0,0-.37624.36727.35357.35357,0,0,0,.37624.36265.6.6,0,0,0,.2883-.07472v.367a1.08067,1.08067,0,0,1-.31352.05155.6877.6877,0,0,1-.72373-.70385.69958.69958,0,0,1,.72373-.71258,1.03064,1.03064,0,0,1,.31352.04735Z"
            fill={logoFillColor}
          />
          <Path
            d="M58.98109,6.84868a.35282.35282,0,0,0-.35444.35805.34733.34733,0,0,0,.35444.35416.35393.35393,0,0,0,.35513-.35907.34778.34778,0,0,0-.35513-.35314m.71852.35061a.70356.70356,0,0,1-.71852.70674.69013.69013,0,0,1-.7177-.69678.70128.70128,0,0,1,.7177-.70545.69169.69169,0,0,1,.71852.69549"
            fill={logoFillColor}
          />
          <Path
            d="M56.43566,6.85118a1.17039,1.17039,0,0,0-.26809-.03957c-.13753.00084-.21219.04712-.21219.113,0,.08246.10047.11076.15684.12856l.09516.02937a.40636.40636,0,0,1,.3242.38695c0,.34185-.30073.45948-.56308.46166A2.08062,2.08062,0,0,1,55.598,7.897V7.58309a1.27977,1.27977,0,0,0,.324.04851c.17025-.00132.24935-.05217.24935-.12956,0-.06884-.0685-.10753-.15344-.13421l-.07348-.023a.41519.41519,0,0,1-.35-.39637c0-.25525.19019-.42726.507-.429a1.47858,1.47858,0,0,1,.3342.041Z"
            fill={logoFillColor}
          />
          <Path
            d="M52.91781,5.08846a.17019.17019,0,1,0-.34037.00088v.35982a.17019.17019,0,1,0,.34037-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.85378,4.61445a.1704.1704,0,1,0-.3408.00056v.83105a.1704.1704,0,1,0,.3408-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.78957,3.96968a.17029.17029,0,1,0-.34057.00012V5.784a.17029.17029,0,1,0,.34057-.00136Z"
            fill={logoFillColor}
          />
          <Path
            d="M55.72562,4.61138a.17014.17014,0,0,0-.34027.00056v.82791a.17014.17014,0,1,0,.34027-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M56.66068,5.07876a.16907.16907,0,0,0-.1694-.16933.17083.17083,0,0,0-.17042.17021v.35711a.16991.16991,0,1,0,.33982-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M57.59734,4.60831a.16983.16983,0,0,0-.33965.00056v.82478a.16983.16983,0,1,0,.33965-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M58.53358,3.96839a.16992.16992,0,0,0-.33983.00012V5.76906a.16992.16992,0,1,0,.33983-.00136Z"
            fill={logoFillColor}
          />
          <Path
            d="M59.47033,4.60523a.17124.17124,0,0,0-.34244.00057v.82165a.16907.16907,0,0,0,.16983.16883.17106.17106,0,0,0,.17261-.17Z"
            fill={logoFillColor}
          />
          <Path
            d="M60.40469,5.06906a.17.17,0,0,0-.34005.00089v.3544a.16836.16836,0,0,0,.1698.16851.17037.17037,0,0,0,.17025-.16963Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 68 12" focusable={false}>
      <G focusable={false} id="gx20-profile">
        <Path
          id="gx20-profile-side"
          d="M60.83,5.21053H6.36843v5.921A.798.798,0,0,0,7.17,11.921H60.83a.798.798,0,0,0,.8016-.78947v-5.921Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gx20-profile-top"
          d="M61.00253,4.29548l-3.092-2.51206-.00064-.00048-.927-.753A3.93926,3.93926,0,0,0,54.85305.27632H13.147a3.93926,3.93926,0,0,0-2.12993.75357l-.92695.75305-.00064.00048-3.092,2.51206c-1.0203.82894-.77293,1.50715.54973,1.50715H60.4528C61.77546,5.80263,62.02283,5.12442,61.00253,4.29548Z"
          fill={topFillColor}
        />
        <Path
          id="gx20-profile-outline"
          d="M60.45277,6H7.54723c-.73242,0-1.20811-.20508-1.34071-.57746-.09252-.26136-.05243-.69618.66689-1.28058L10.89325.87613A4.15944,4.15944,0,0,1,13.14679.079H54.85321a4.15944,4.15944,0,0,1,2.25354.79718L61.12659,4.142c.71932.5844.75941,1.01922.66689,1.28058C61.66088,5.79492,61.18519,6,60.45277,6ZM10.15928,1.98093,7.12166,4.44881c-.48186.39165-.59673.69156-.54276.8419.054.15188.33691.31455.96833.31455H60.45277c.63142,0,.91437-.16267.96833-.31455.054-.15034-.0609-.45025-.54276-.8419L57.84072,1.98093h-.12721l-.00077-.10408L56.8585,1.183a3.75963,3.75963,0,0,0-2.00529-.7093H13.14679a3.75963,3.75963,0,0,0-2.00529.7093l-.85424.69387v.10331Z"
          fill={outlineFillColor}
        />
        <Path
          id="gx20-profile-led"
          d="M12.665,10.34211H10.44542A.49343.49343,0,0,1,9.952,9.84868h0a.49342.49342,0,0,1,.49342-.49342H12.665a.49342.49342,0,0,1,.49342.49342h0A.49343.49343,0,0,1,12.665,10.34211Z"
          fill={sideFillColor}
        />
        <G focusable={false} id="gx20-profile-logo">
          <Path d="M32.47265,3.81962h-.38709l.05751-.5205h.37546Z" fill={logoFillColor} />
          <Path
            d="M35.55458,3.44814a1.75243,1.75243,0,0,0-.32032-.02778c-.24428,0-.4107.05912-.40684.1388.00376.07757.17123.139.4271.139a1.712,1.712,0,0,0,.32046-.02761l.01281.13976A3.40406,3.40406,0,0,1,35.237,3.829c-.43567,0-.81945-.10187-.82353-.26979-.00376-.15528.32815-.26929.78507-.26929a3.18275,3.18275,0,0,1,.3433.01867Z"
            fill={logoFillColor}
          />
          <Path
            d="M31.60721,3.44814a1.61219,1.61219,0,0,0-.31321-.02778c-.24474,0-.42475.05912-.43944.1388-.0143.07757.1385.139.395.139a1.81054,1.81054,0,0,0,.32622-.02761l-.01971.13976a3.52306,3.52306,0,0,1-.35584.01863c-.43466,0-.794-.10187-.759-.26979.03238-.15528.39048-.26929.84607-.26929a3.02208,3.02208,0,0,1,.3396.01867Z"
            fill={logoFillColor}
          />
          <Path
            d="M36.73928,3.42245c-.22383,0-.3814.06115-.37092.13671.01053.07589.18777.13711.41522.13711.2278,0,.3857-.06122.37167-.13711-.014-.07556-.19179-.13671-.416-.13671m.819.13671c.03106.149-.282.26979-.7532.26979-.47087,0-.8224-.12083-.83956-.26979-.0171-.14851.29609-.26929.75233-.26929.45656,0,.80946.12078.84043.26929"
            fill={logoFillColor}
          />
          <Path
            d="M33.93906,3.41737c-.0052-.00063-.164-.01576-.29452-.01576-.15109,0-.23374.01752-.23462.0427-.0011.03151.10945.04257.17156.04951l.10492.01146c.24548.02711.3586.08561.35877.14885.00034.13078-.33831.17482-.63384.17482-.206,0-.39689-.013-.41657-.01424l.007-.11986c.03379.00286.1937.01949.36147.01949.19056,0,.27947-.0192.28008-.04879.00055-.02631-.0746-.04146-.16965-.05173l-.08144-.009c-.21092-.02253-.38532-.06624-.38031-.15229.00568-.09746.21708-.16275.56287-.16275.18215,0,.35377.01537.36473.01638Z"
            fill={logoFillColor}
          />
          <Path
            d="M30.22757,2.73971c.00831-.03585-.065-.065-.16427-.065-.09772,0-.186.0291-.19507.065l-.03472.13653c-.0092.03614.06558.06518.16491.06518.10094,0,.189-.029.19743-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M31.24648,2.56027c.00614-.03585-.068-.06488-.16547-.06488-.0982,0-.18343.029-.19037.06488l-.06115.316c-.007.03614.06923.06518.17015.06518.10014,0,.18649-.029.19268-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M32.2506,2.31507c.004-.036-.07046-.06507-.16685-.06507-.09592,0-.17851.02906-.18328.06507l-.09172.69111c-.00478.03609.07416.06531.17509.06531.10141,0,.18635-.02922.19034-.06531Z"
            fill={logoFillColor}
          />
          <Path
            d="M33.20081,2.56027c.00179-.03585-.07609-.06488-.17321-.06488-.09805,0-.17946.029-.182.06488l-.0227.316c-.00261.03614.07685.06518.17764.06518.09982,0,.18279-.029.18459-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M34.17906,2.73971c-.0004-.03585-.0803-.065-.17883-.065s-.17952.0291-.17992.065l-.00151.13653c-.0004.03614.08113.06518.18143.06518.10015,0,.18073-.029.18033-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M35.155,2.56027c-.00257-.03585-.08368-.06488-.18142-.06488-.09742,0-.175.029-.17321.06488l.01575.316c.00179.03614.08447.06518.1846.06518.10047,0,.1796-.029.177-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M36.1,2.31507c-.00478-.036-.086-.06507-.18285-.06507-.09516,0-.17051.02906-.16653.06507l.07647.69111c.004.03609.08989.06531.19.06531.1019,0,.17942-.02922.17463-.06531Z"
            fill={logoFillColor}
          />
          <Path
            d="M37.11058,2.56027c-.00694-.03585-.09217-.06488-.19206-.06488-.09681,0-.17161.029-.16547.06488l.05415.316c.00619.03614.09316.06518.19267.06518.10267,0,.17889-.029.17189-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M38.13177,2.73971c-.00911-.03585-.097-.065-.19554-.065-.09882,0-.17179.0291-.16348.065l.03171.13653c.0084.03614.09618.06518.19664.06518.10013,0,.17459-.029.16539-.06518Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

// TODO: Update this when the actual GX50 illustration is given
const gx50 = {
  perspective: (
    <Svg style={svgLayoutStyle} viewBox="0 0 68 12" focusable={false}>
      <G focusable={false} id="gx50-perspective">
        <Path
          id="gx50-perspective-side"
          d="M20.15417.10917a1.02288,1.02288,0,0,1,1.09858.96205V10.9618a.98469.98469,0,0,1-1.097.92759L5.097,11.08794A1.11948,1.11948,0,0,1,4,10.04359V.94573A.539.539,0,0,1,4.54929.44078Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gx50-perspective-front"
          d="M63.45058.35087,22.35162.08006C21.568.0749,20.52282.08891,20.15416.09675h0L19.15367.118V11.82364l1.0021.05333c.54811.02917,1.64675.05037,2.19574.04238l40.54975-.59042A1.06871,1.06871,0,0,0,64,10.327V.84748A.52926.52926,0,0,0,63.45058.35087Z"
          fill={topFillColor}
        />
        <Path
          id="gx50-perspective-led"
          d="M30.80907,7.3508c0,.40216-.46144.734-1.02543.73733l-1.76487.01055c-.564.00337-1.02544-.32466-1.02544-.729s.46145-.73691,1.02545-.73913l1.76484-.00695C30.34762,6.62141,30.80907,6.94864,30.80907,7.3508Z"
          fill={outlineFillColor}
        />
        <G focusable={false} id="gx50-perspective-logo">
          <Path d="M55.13453,7.91374l-.34381.00286V6.55116l.34381-.00192Z" fill={logoFillColor} />
          <Path
            d="M57.902,6.92234a.604.604,0,0,0-.28889-.07062.35855.35855,0,0,0-.376.36464.35246.35246,0,0,0,.376.36.609.609,0,0,0,.28889-.07421v.36432a1.09413,1.09413,0,0,1-.313.05118.68613.68613,0,0,1-.72523-.69871.69776.69776,0,0,1,.72523-.7075,1.04787,1.04787,0,0,1,.313.047Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.31931,6.94507a.59232.59232,0,0,0-.2883-.07117.35976.35976,0,0,0-.37624.36727.35357.35357,0,0,0,.37624.36265.6.6,0,0,0,.2883-.07472v.367a1.08067,1.08067,0,0,1-.31352.05155.6877.6877,0,0,1-.72373-.70385.69958.69958,0,0,1,.72373-.71258,1.03064,1.03064,0,0,1,.31352.04735Z"
            fill={logoFillColor}
          />
          <Path
            d="M58.98109,6.84868a.35282.35282,0,0,0-.35444.35805.34733.34733,0,0,0,.35444.35416.35393.35393,0,0,0,.35513-.35907.34778.34778,0,0,0-.35513-.35314m.71852.35061a.70356.70356,0,0,1-.71852.70674.69013.69013,0,0,1-.7177-.69678.70128.70128,0,0,1,.7177-.70545.69169.69169,0,0,1,.71852.69549"
            fill={logoFillColor}
          />
          <Path
            d="M56.43566,6.85118a1.17039,1.17039,0,0,0-.26809-.03957c-.13753.00084-.21219.04712-.21219.113,0,.08246.10047.11076.15684.12856l.09516.02937a.40636.40636,0,0,1,.3242.38695c0,.34185-.30073.45948-.56308.46166A2.08062,2.08062,0,0,1,55.598,7.897V7.58309a1.27977,1.27977,0,0,0,.324.04851c.17025-.00132.24935-.05217.24935-.12956,0-.06884-.0685-.10753-.15344-.13421l-.07348-.023a.41519.41519,0,0,1-.35-.39637c0-.25525.19019-.42726.507-.429a1.47858,1.47858,0,0,1,.3342.041Z"
            fill={logoFillColor}
          />
          <Path
            d="M52.91781,5.08846a.17019.17019,0,1,0-.34037.00088v.35982a.17019.17019,0,1,0,.34037-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M53.85378,4.61445a.1704.1704,0,1,0-.3408.00056v.83105a.1704.1704,0,1,0,.3408-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M54.78957,3.96968a.17029.17029,0,1,0-.34057.00012V5.784a.17029.17029,0,1,0,.34057-.00136Z"
            fill={logoFillColor}
          />
          <Path
            d="M55.72562,4.61138a.17014.17014,0,0,0-.34027.00056v.82791a.17014.17014,0,1,0,.34027-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M56.66068,5.07876a.16907.16907,0,0,0-.1694-.16933.17083.17083,0,0,0-.17042.17021v.35711a.16991.16991,0,1,0,.33982-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M57.59734,4.60831a.16983.16983,0,0,0-.33965.00056v.82478a.16983.16983,0,1,0,.33965-.00113Z"
            fill={logoFillColor}
          />
          <Path
            d="M58.53358,3.96839a.16992.16992,0,0,0-.33983.00012V5.76906a.16992.16992,0,1,0,.33983-.00136Z"
            fill={logoFillColor}
          />
          <Path
            d="M59.47033,4.60523a.17124.17124,0,0,0-.34244.00057v.82165a.16907.16907,0,0,0,.16983.16883.17106.17106,0,0,0,.17261-.17Z"
            fill={logoFillColor}
          />
          <Path
            d="M60.40469,5.06906a.17.17,0,0,0-.34005.00089v.3544a.16836.16836,0,0,0,.1698.16851.17037.17037,0,0,0,.17025-.16963Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
  profile: (
    <Svg style={svgLayoutStyle} viewBox="0 0 68 12" focusable={false}>
      <G focusable={false} id="gx50-profile">
        <Path
          id="gx50-profile-side"
          d="M60.83,5.21053H6.36843v5.921A.798.798,0,0,0,7.17,11.921H60.83a.798.798,0,0,0,.8016-.78947v-5.921Z"
          fill={otherSideFillColor}
        />
        <Path
          id="gx50-profile-top"
          d="M61.00253,4.29548l-3.092-2.51206-.00064-.00048-.927-.753A3.93926,3.93926,0,0,0,54.85305.27632H13.147a3.93926,3.93926,0,0,0-2.12993.75357l-.92695.75305-.00064.00048-3.092,2.51206c-1.0203.82894-.77293,1.50715.54973,1.50715H60.4528C61.77546,5.80263,62.02283,5.12442,61.00253,4.29548Z"
          fill={topFillColor}
        />
        <Path
          id="gx50-profile-outline"
          d="M60.45277,6H7.54723c-.73242,0-1.20811-.20508-1.34071-.57746-.09252-.26136-.05243-.69618.66689-1.28058L10.89325.87613A4.15944,4.15944,0,0,1,13.14679.079H54.85321a4.15944,4.15944,0,0,1,2.25354.79718L61.12659,4.142c.71932.5844.75941,1.01922.66689,1.28058C61.66088,5.79492,61.18519,6,60.45277,6ZM10.15928,1.98093,7.12166,4.44881c-.48186.39165-.59673.69156-.54276.8419.054.15188.33691.31455.96833.31455H60.45277c.63142,0,.91437-.16267.96833-.31455.054-.15034-.0609-.45025-.54276-.8419L57.84072,1.98093h-.12721l-.00077-.10408L56.8585,1.183a3.75963,3.75963,0,0,0-2.00529-.7093H13.14679a3.75963,3.75963,0,0,0-2.00529.7093l-.85424.69387v.10331Z"
          fill={outlineFillColor}
        />
        <Path
          id="gx50-profile-led"
          d="M12.665,10.34211H10.44542A.49343.49343,0,0,1,9.952,9.84868h0a.49342.49342,0,0,1,.49342-.49342H12.665a.49342.49342,0,0,1,.49342.49342h0A.49343.49343,0,0,1,12.665,10.34211Z"
          fill={sideFillColor}
        />
        <G focusable={false} id="gx50-profile-logo">
          <Path d="M32.47265,3.81962h-.38709l.05751-.5205h.37546Z" fill={logoFillColor} />
          <Path
            d="M35.55458,3.44814a1.75243,1.75243,0,0,0-.32032-.02778c-.24428,0-.4107.05912-.40684.1388.00376.07757.17123.139.4271.139a1.712,1.712,0,0,0,.32046-.02761l.01281.13976A3.40406,3.40406,0,0,1,35.237,3.829c-.43567,0-.81945-.10187-.82353-.26979-.00376-.15528.32815-.26929.78507-.26929a3.18275,3.18275,0,0,1,.3433.01867Z"
            fill={logoFillColor}
          />
          <Path
            d="M31.60721,3.44814a1.61219,1.61219,0,0,0-.31321-.02778c-.24474,0-.42475.05912-.43944.1388-.0143.07757.1385.139.395.139a1.81054,1.81054,0,0,0,.32622-.02761l-.01971.13976a3.52306,3.52306,0,0,1-.35584.01863c-.43466,0-.794-.10187-.759-.26979.03238-.15528.39048-.26929.84607-.26929a3.02208,3.02208,0,0,1,.3396.01867Z"
            fill={logoFillColor}
          />
          <Path
            d="M36.73928,3.42245c-.22383,0-.3814.06115-.37092.13671.01053.07589.18777.13711.41522.13711.2278,0,.3857-.06122.37167-.13711-.014-.07556-.19179-.13671-.416-.13671m.819.13671c.03106.149-.282.26979-.7532.26979-.47087,0-.8224-.12083-.83956-.26979-.0171-.14851.29609-.26929.75233-.26929.45656,0,.80946.12078.84043.26929"
            fill={logoFillColor}
          />
          <Path
            d="M33.93906,3.41737c-.0052-.00063-.164-.01576-.29452-.01576-.15109,0-.23374.01752-.23462.0427-.0011.03151.10945.04257.17156.04951l.10492.01146c.24548.02711.3586.08561.35877.14885.00034.13078-.33831.17482-.63384.17482-.206,0-.39689-.013-.41657-.01424l.007-.11986c.03379.00286.1937.01949.36147.01949.19056,0,.27947-.0192.28008-.04879.00055-.02631-.0746-.04146-.16965-.05173l-.08144-.009c-.21092-.02253-.38532-.06624-.38031-.15229.00568-.09746.21708-.16275.56287-.16275.18215,0,.35377.01537.36473.01638Z"
            fill={logoFillColor}
          />
          <Path
            d="M30.22757,2.73971c.00831-.03585-.065-.065-.16427-.065-.09772,0-.186.0291-.19507.065l-.03472.13653c-.0092.03614.06558.06518.16491.06518.10094,0,.189-.029.19743-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M31.24648,2.56027c.00614-.03585-.068-.06488-.16547-.06488-.0982,0-.18343.029-.19037.06488l-.06115.316c-.007.03614.06923.06518.17015.06518.10014,0,.18649-.029.19268-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M32.2506,2.31507c.004-.036-.07046-.06507-.16685-.06507-.09592,0-.17851.02906-.18328.06507l-.09172.69111c-.00478.03609.07416.06531.17509.06531.10141,0,.18635-.02922.19034-.06531Z"
            fill={logoFillColor}
          />
          <Path
            d="M33.20081,2.56027c.00179-.03585-.07609-.06488-.17321-.06488-.09805,0-.17946.029-.182.06488l-.0227.316c-.00261.03614.07685.06518.17764.06518.09982,0,.18279-.029.18459-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M34.17906,2.73971c-.0004-.03585-.0803-.065-.17883-.065s-.17952.0291-.17992.065l-.00151.13653c-.0004.03614.08113.06518.18143.06518.10015,0,.18073-.029.18033-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M35.155,2.56027c-.00257-.03585-.08368-.06488-.18142-.06488-.09742,0-.175.029-.17321.06488l.01575.316c.00179.03614.08447.06518.1846.06518.10047,0,.1796-.029.177-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M36.1,2.31507c-.00478-.036-.086-.06507-.18285-.06507-.09516,0-.17051.02906-.16653.06507l.07647.69111c.004.03609.08989.06531.19.06531.1019,0,.17942-.02922.17463-.06531Z"
            fill={logoFillColor}
          />
          <Path
            d="M37.11058,2.56027c-.00694-.03585-.09217-.06488-.19206-.06488-.09681,0-.17161.029-.16547.06488l.05415.316c.00619.03614.09316.06518.19267.06518.10267,0,.17889-.029.17189-.06518Z"
            fill={logoFillColor}
          />
          <Path
            d="M38.13177,2.73971c-.00911-.03585-.097-.065-.19554-.065-.09882,0-.17179.0291-.16348.065l.03171.13653c.0084.03614.09618.06518.19664.06518.10013,0,.17459-.029.16539-.06518Z"
            fill={logoFillColor}
          />
        </G>
      </G>
    </Svg>
  ),
};

const hardwareIllustrations = {
  GR10Online: gr10.perspective,
  GR10Offline: gr10.profile,
  GR12Online: gr10.perspective,
  GR12Offline: gr10.profile,
  GR60Online: gr60.perspective,
  GR60Offline: gr60.profile,
  GR62Online: gr60.perspective,
  GR62Offline: gr60.profile,
  "GS110-8Online": gs1108.perspective,
  "GS110-8Offline": gs1108.profile,
  "GS110-24Online": gs11024.perspective,
  "GS110-24Offline": gs11024.profile,
  "GS110-48Online": gs11048.perspective,
  "GS110-48Offline": gs11048.profile,
  GX20Online: gx20.perspective,
  GX20Offline: gx20.profile,
  GX50Online: gx50.perspective,
  GX50Offline: gx50.profile,
};

export default hardwareIllustrations;
