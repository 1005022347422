import { DeviceSubscriptionProps, NetworkSubscriptionProps } from "./Subscriptions";
import { CompletableDataMsg } from "./types/DataMsg";

export function isCompletableDataMsg(msg: unknown): msg is CompletableDataMsg {
  return (msg as CompletableDataMsg)?.data?.completed !== undefined;
}

export function isDeviceSubscriptionProps(props: unknown): props is DeviceSubscriptionProps {
  return (props as DeviceSubscriptionProps)?.deviceId !== undefined;
}

export function isNetworkSubscriptionProps(props: unknown): props is NetworkSubscriptionProps {
  return (props as NetworkSubscriptionProps)?.networkId !== undefined;
}

export function capitalizeFirstLetter(str?: string): string {
  if (str === undefined) {
    throw Error("Paramater str is undefined!");
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function parseDeviceId(channel: string): string {
  const matcher = channel.match(`\\/node\\/(\\d+)\\/`);
  const deviceId = matcher?.[1];

  if (!deviceId) {
    throw Error("Unable to parse deviceId");
  }

  return deviceId;
}
