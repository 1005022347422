import {
  CLEAR_SENSOR_STAT_METRIC,
  LATEST_WATER_DETECTION_STAT_REQUEST,
  LATEST_WATER_DETECTION_STAT_SUCCESS,
  SENSOR_STATS_COUNT_BY_VALUE_REQUEST,
  SENSOR_STATS_COUNT_BY_VALUE_SUCCESS,
  SENSOR_STATS_LATEST_REQUEST,
  SENSOR_STATS_LATEST_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { find } from "lodash";

import { fromIsoToUnixTimestamp } from "~/lib/timeHelper";
import { ApiSensorDataLatest, SensorStat } from "~/shared/types/SensorStats";

interface SensorStatsReducer {
  [sensorSerial: string]: {
    metric: SensorStat;
  };
}

const initialState: SensorStatsReducer = {};

export default function sensorStats(state: any = initialState, action: any) {
  const { type, response } = action;
  switch (type) {
    case CLEAR_SENSOR_STAT_METRIC: {
      const { agg, serial, metric, timespan } = action;
      const key = `${metric}:${agg}:${timespan}`;
      const newState = { ...state };
      if (newState[serial] && newState[serial][key]) {
        newState[serial] = { ...newState[serial] };
        newState[serial][key] = {};
      }
      return newState;
    }
    case LATEST_WATER_DETECTION_STAT_REQUEST: {
      return { ...state, isFetchingLatestWaterDetectionStat: true };
    }
    case LATEST_WATER_DETECTION_STAT_SUCCESS: {
      const { metric } = action.meta;
      const newState = { ...state, isFetchingLatestWaterDetectionStat: false };
      const response: ApiSensorDataLatest[] = action.response;
      response.forEach(({ serial, ts, ...latest }) => {
        newState[serial] = { ...newState[serial] };
        newState[serial][metric] = {
          ...newState[serial][metric],
          latest: {
            ...latest,
            timestamp: fromIsoToUnixTimestamp(ts),
          },
        };
      });

      return newState;
    }
    case SENSOR_STATS_COUNT_BY_VALUE_REQUEST: {
      const { serial, metric } = action.meta;
      const newState = { ...state };
      newState[serial] = { ...newState[serial] };
      newState[serial][metric] = { ...newState[serial][metric], isFetchingCountByValue: true };
      return newState;
    }
    case SENSOR_STATS_COUNT_BY_VALUE_SUCCESS: {
      const { serial, metric } = action.meta;
      const newState = { ...state };
      newState[serial] = { ...newState[serial] };
      const countByValue = response.reduce((memo: any, { sensor_value, count }: any) => {
        memo[sensor_value] = count;
        return memo;
      }, {});
      newState[serial][metric] = {
        ...newState[serial][metric],
        isFetchingCountByValue: false,
        countByValue,
      };
      return newState;
    }
    case SENSOR_STATS_LATEST_REQUEST: {
      const { serial, metric } = action.meta;
      const newState = { ...state };
      newState[serial] = { ...newState[serial] };
      newState[serial][metric] = { ...newState[serial][metric], isFetchingLatest: true };
      return newState;
    }
    case SENSOR_STATS_LATEST_SUCCESS: {
      const { serial, metric } = action.meta;
      const newState = { ...state };
      newState[serial] = { ...newState[serial] };
      const latest = find(response, (datum: any) => datum.serial === serial);
      newState[serial][metric] = { ...newState[serial][metric], isFetchingLatest: false, latest };
      return newState;
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
