import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Organization({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color3 = color
    ? theme.color.brandAccent.border.strong.active
    : theme.color.brandAccent.border.strong.base;
  const color4 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 32 32" width={width} height={height} fill="none">
      <Path
        d="M18.5 10.475 16.475 12.5l2.025 2.025 2.025-2.025-2.025-2.025Zm.707-1.768a1 1 0 0 0-1.414 0l-3.086 3.086a1 1 0 0 0 0 1.414l3.086 3.086a1 1 0 0 0 1.414 0l3.086-3.086a1 1 0 0 0 0-1.414l-3.086-3.086Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M12.793 8.707a1 1 0 0 1 1.414 0l3.086 3.086a1 1 0 0 1 0 1.414l-3.086 3.086a1 1 0 0 1-1.414 0l-3.086-3.086a1 1 0 0 1 0-1.414l3.086-3.086Z"
        fill={color2}
      />
      <Path
        d="M12.086 8a2 2 0 0 1 2.828 0L18 11.086a2 2 0 0 1 0 2.828L14.914 17a2 2 0 0 1-2.828 0L9 13.914a2 2 0 0 1 0-2.828L12.086 8Zm2.121.707a1 1 0 0 0-1.414 0l-3.086 3.086a1 1 0 0 0 0 1.414l3.086 3.086a1 1 0 0 0 1.414 0l3.086-3.086a1 1 0 0 0 0-1.414l-3.086-3.086Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color3}
      />
      <Path
        d="M16 23.5a1 1 0 0 1-1-1V18a1 1 0 1 1 2 0v4.5a1 1 0 0 1-1 1Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color4}
      />
      <Path
        d="M10 23a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-1H11v1a1 1 0 0 1-1 1Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color4}
      />
      <Path
        clipRule="evenodd"
        d="M24 2H8a6 6 0 0 0-6 6v16a6 6 0 0 0 6 6h16a6 6 0 0 0 6-6V8a6 6 0 0 0-6-6ZM8 0a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H8Z"
        fill={color4}
        fillRule="evenodd"
      />
    </Svg>
  );
}
