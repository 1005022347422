import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { BottomSheetMethods, List, RefreshControl } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { ClientType, useClients } from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import {
  CLIENTS_LIST_SEARCH_FIELDS,
  FilterConfiguration,
  filterData,
  FiltersState,
  getThreshold,
  totalUsage,
  useFilteredState,
} from "@meraki/shared/filters";
import { getTimespanName } from "@meraki/shared/formatters";
import { ClientGroupProps } from "@meraki/shared/navigation-type";
import { IsHighUsageFilterOption, IsOnlineFilterOption } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef, useState } from "react";

type ClientListFilterCategories = "isOnline" | "isHighUsage";
type ClientListFilterOptions = {
  isOnline: IsOnlineFilterOption;
  isHighUsage: IsHighUsageFilterOption;
};

interface ExtendedClientFileds {
  isOnline: boolean;
  highUsage: boolean;
}

type ExtendedClientType = ClientType & ExtendedClientFileds;

export const clientListFilterConfiguration: FilterConfiguration<
  ClientListFilterCategories,
  ClientListFilterOptions,
  ExtendedClientType
> = (item) => {
  return {
    isOnline: {
      online: !!item.isOnline,
      offline: !item.isOnline,
    },
    isHighUsage: {
      isHighUsage: item.highUsage,
    },
  };
};

export function ClientListScreen() {
  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);
  const route = useRoute<RouteProp<ClientGroupProps, "ClientList">>();
  const navigation = useNavigation<NativeStackNavigationProp<ClientGroupProps>>();
  const { params: props } = route;
  const initialFilters = props?.initialFilters ?? {};

  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const {
    data: clients,
    isLoading: isLoadingClients,
    refetch: refetchClients,
    isRefetching: isRefetchingClients,
  } = useClients(
    { networkId, timespan },
    {
      enabled: Boolean(networkId),
      select: (data) => {
        const threshold = getThreshold(data);
        const filteredData = props?.customFilter?.(data) ?? data;
        return filteredData.map((datum) => {
          return {
            ...datum,
            isOnline: datum.status === "Online",
            highUsage: totalUsage(datum) >= threshold,
          };
        });
      },
    },
  );

  const [searchText, setSearchText] = useState("");

  const {
    setFilters,
    filters,
    filteredData: filteredClients,
  } = useFilteredState<ClientListFilterCategories, ClientListFilterOptions, ExtendedClientType>(
    initialFilters,
    clients ?? [],
    { filterConfiguration: clientListFilterConfiguration },
  );

  const hasFilters = Object.values(filters).some((filter) => filter.values.length > 0);
  const searchedClients = filterData(
    filteredClients,
    CLIENTS_LIST_SEARCH_FIELDS,
    searchText,
  ) as ExtendedClientType[];

  return (
    <Screen
      gap="none"
      refreshControl={
        <RefreshControl
          refreshing={isRefetchingClients}
          onRefresh={() => {
            refetchClients();
          }}
        />
      }
    >
      <Box flexDirection="row" paddingTop="sm" alignItems="center" gap="sm">
        <SearchFilterSection
          placeholder={I18n.t("CLIENTS_LIST.SEARCH_PLACEHOLDER")}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFilterPress={() => filterBottomSheetRef.current?.present()}
        />
      </Box>
      <FilterTagList
        filters={{
          isOnline:
            filters.isOnline != null
              ? Array.from(filters.isOnline).map((pt) => ({
                  value: pt,
                  label: pt,
                }))
              : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<
            ClientListFilterCategories,
            ClientListFilterOptions
          > = {};

          if (filters.isOnline.length > 0) {
            formattedFilters.isOnline = new Set(filters.isOnline);
          }

          setFilters(formattedFilters);
        }}
      />
      <List.FlashList
        paddingTop={hasFilters ? "none" : undefined}
        data={searchedClients}
        onRefresh={refetchClients}
        refreshing={isRefetchingClients}
        loading={isLoadingClients}
        label={
          searchedClients.length > 1
            ? I18n.t("RESULTS.MANY", {
                num: searchedClients.length,
              })
            : I18n.t("RESULTS.ONE", {
                num: searchedClients.length,
              })
        }
        emptyState={{
          title: clients?.length
            ? I18n.t("SORT_FILTER.EMPTY")
            : I18n.t("CLIENTS_LIST.NO_CLIENTS_FOUND", { timespan: getTimespanName(timespan) }),
        }}
        getItemData={(client) => {
          return {
            title: client.description || client.mac,
            description: I18n.t("CLIENTS_LIST.LAST_SEEN_AT", {
              date: formatDate(client.lastSeen, {
                dateFormat: "shortDate",
                timeFormat: "shortTime",
              }),
            }),
            leftAccessory: (
              <Box paddingRight="2xs">
                <Status size={20} status={client.status === "Online" ? "positive" : "negative"} />
              </Box>
            ),
            onPress: () => navigation.navigate("ClientDetails", { id: client.id }),
          };
        }}
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          isOnline: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
            options: [
              { value: "online", label: I18n.t("CLIENTS_LIST.FILTER_VALUES.ONLINE") },
              { value: "offline", label: I18n.t("CLIENTS_LIST.FILTER_VALUES.OFFLINE") },
            ],
          },
        }}
        initialFilters={{
          isOnline: filters.isOnline != null ? Array.from(filters.isOnline) : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<
            ClientListFilterCategories,
            ClientListFilterOptions
          > = {};

          if (filters.isOnline.length > 0) {
            formattedFilters.isOnline = new Set(filters.isOnline);
          }

          setFilters(formattedFilters);
        }}
      />
    </Screen>
  );
}
