import { I18n } from "@meraki/core/i18n";
import {
  LiveToolCommand,
  LiveToolContext,
  LiveToolStatus,
  usePing,
} from "@meraki/react-live-broker";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { PING_TIMEOUT, SPACING } from "~/constants/MkiConstants";
import PingInProgressStats from "~/go/components/liveTools/ping/PingInProgressStats";
import PokeDeviceHeader from "~/go/components/liveTools/ping/pokeDevice/PokeDeviceHeader";
import PokeDeviceResults from "~/go/components/liveTools/ping/pokeDevice/PokeDeviceResults";
import { PokeDeviceStackProps } from "~/go/navigation/Types";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import { CloseButton } from "~/shared/navigation/Buttons";
import Device_DeprecatedType from "~/shared/types/Device";

type Props = ForwardedNativeStackScreenProps<PokeDeviceStackProps, "PokeDevice">;

type PingProgressProps = {
  device: Device_DeprecatedType;
  portNumber: number;
  status: LiveToolStatus;
  setCommand: (command: LiveToolCommand) => void;
};

function PingProgress({ device, portNumber, status, setCommand }: PingProgressProps) {
  const pingStats = usePing(device.id);
  const [stopped, setStopped] = useState(false);
  const navigation = useNavigation();

  const onStop = useCallback(() => {
    setCommand("stop");
    setStopped(true);
  }, [setCommand]);

  const onRestart = useCallback(() => {
    setCommand("restart");
    setStopped(false);
  }, [setCommand]);

  const timeout = useMemo(() => {
    // Reset/set timeout on status change to running
    if (status === LiveToolStatus.running) {
      return setTimeout(onStop, PING_TIMEOUT);
    }
    return undefined;
  }, [status, onStop]);

  const navigate = (name: string, params: Record<string, unknown>) => {
    onStop();
    navigation.navigate(name, params);
  };

  if (stopped || status === LiveToolStatus.completed) {
    if (timeout) {
      clearTimeout(timeout);
    }

    return (
      <PokeDeviceResults
        onRestartPress={onRestart}
        navigate={navigate}
        averageLatency={pingStats?.averageLatency}
        lossRate={pingStats?.lossRate}
        portNumber={portNumber}
        device={device}
      />
    );
  }

  return (
    <PingInProgressStats
      onStopPress={onStop}
      title={I18n.t("POKE_DEVICE.POKING_DEVICE")}
      averageLatency={pingStats?.averageLatency}
      lossRate={pingStats?.lossRate}
      pings={pingStats?.pings}
    />
  );
}

export function PokeDeviceScreen({ host, portNumber, device }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const [status, setStatus] = useState(LiveToolStatus.idle);
  const [command, setCommand] = useState<LiveToolCommand>("start");
  const liveToolContext = {
    command,
    onStatusChange: setStatus,
  };

  const isPinging = status === LiveToolStatus.connecting || status === LiveToolStatus.running;

  return (
    <FullScreenContainerView withScroll contentContainerStyle={styles.contentContainer}>
      <PokeDeviceHeader
        deviceName={host?.name}
        hideText={!isPinging}
        isOnline
        portNumber={portNumber}
      />
      <LiveToolContext.Provider value={liveToolContext}>
        <PingProgress
          portNumber={portNumber}
          device={device}
          status={status}
          setCommand={setCommand}
        />
      </LiveToolContext.Provider>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
  },
});

export default PokeDeviceScreen;
