import z from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

type AFCRefreshRequest = {
  serial: string;
};

function buildUrl({ serial }: AFCRefreshRequest) {
  return `/api/v1/devices/${serial}/wireless/radio/afc/refresh`;
}

export const useAFCRefresh = createMutation({
  baseMutationKey: buildUrl({ serial: "{serial}" }),
  mutationFn: (variables: AFCRefreshRequest) =>
    request(z.null(), "POST", buildUrl({ serial: variables.serial })),
});
