import { useBioauthEnabled, useHasBioauth } from "@meraki/core/bioauth";
import { clearCookies, clearSavedCookies } from "@meraki/core/cookies";
import * as errorMonitor from "@meraki/core/errors";
import { currentLanguage, I18n, ORIGINAL_LOCALE, updateLocale } from "@meraki/core/i18n";
import { getLanguageOptions } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Toggle } from "@meraki/magnetic/components";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  List,
  Text,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  Organization,
  useEditNetwork,
  useEditOrganization,
  useLogOut,
  useNetwork,
  useOrganization,
  useTimezones,
} from "@meraki/shared/api";
import { Picker } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import {
  setCurrentLocale,
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack/lib/typescript/src/types";
import { AuthenticationType, supportedAuthenticationTypesAsync } from "expo-local-authentication";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { Alert } from "react-native";
import { useDispatch } from "react-redux";

import { generateDeleteCookieString } from "../utils/webUtils";

type EditAccountForm = {
  orgName: string;
};

export const AccountScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();

  const languageBottomSheetRef = useRef<BottomSheetMethods>(null);
  const timezoneBottomSheetRef = useRef<BottomSheetMethods>(null);
  const orgNameBottomSheetRef = useRef<BottomSheetMethods>(null);

  const organizationId = useCurrentOrganizationId();
  const {
    data: organization,
    refetch: refetchOrg,
    isFetching: orgIsLoading,
  } = useOrganization({ organizationId });
  const { mutate: editOrg, isLoading: orgIsUpdating } = useEditOrganization();

  const networkId = useCurrentNetworkId();
  const { data: network, refetch, isFetching: networkIsLoading } = useNetwork({ networkId });
  const { mutate: updateNetwork, isLoading: networkIsUpdating } = useEditNetwork();

  const { data: timezones } = useTimezones();
  const timezone = timezones?.options.find((t) => t.value === network?.timeZone);
  const timeZoneIdx = timezones?.options.findIndex((t) => t.value === network?.timeZone) ?? 1;

  const languages = getLanguageOptions();
  const currentLocale = currentLanguage();
  const language = languages.find((l) => l.value === currentLocale);
  const localIdx = languages?.findIndex((l) => l.value === currentLocale) ?? 1;

  const email = useCurrentUserEmail();
  const hasBioauth = useHasBioauth();
  const { enabled: bioauthEnabled, setEnabled: setBioauthEnabled } = useBioauthEnabled();
  const [bioAuthDescription, setBioAuthDescription] = useState("");

  const logOff = useLogOut();

  const isLoading = orgIsLoading || orgIsUpdating || networkIsLoading || networkIsUpdating;

  const dispatch = useDispatch();

  const methods = useForm<EditAccountForm>({
    values: { orgName: organization?.name ?? "" },
  });

  const updateOrgSettings = (body: Partial<Organization>) => {
    editOrg(
      { organizationId, body },
      {
        onSettled: () => {
          refetchOrg();
          orgNameBottomSheetRef.current?.dismiss();
        },
        onError: (error) => Alert.alert(String(error["errors"])),
      },
    );
  };
  useEffect(() => {
    const createDescription = async () => {
      if (!hasBioauth) {
        setBioAuthDescription(I18n.t("ACCOUNT.BIOAUTH_METHODS.NOT_ENABLED"));
        return;
      }

      const supportedTypes = await supportedAuthenticationTypesAsync();
      if (
        supportedTypes.includes(AuthenticationType.FACIAL_RECOGNITION) ||
        supportedTypes.includes(AuthenticationType.IRIS)
      ) {
        setBioAuthDescription(I18n.t("ACCOUNT.BIOAUTH_METHODS.FACE_ID"));
      } else if (supportedTypes.includes(AuthenticationType.FINGERPRINT)) {
        setBioAuthDescription(I18n.t("ACCOUNT.BIOAUTH_METHODS.FINGERPRINT"));
      }
    };
    createDescription();
  }, [hasBioauth]);

  const logOut = async () => {
    try {
      logOff.mutate({});
      //Todo: analytics log logging out
      errorMonitor.setUser();
      if (Platform.OS === "web") {
        document.cookie = generateDeleteCookieString("theme");
      }
      dispatch({ type: "WIPE_REDUX" });
      await clearCookies();
      clearSavedCookies();
    } catch {
      Alert.alert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
    }
  };

  return (
    <Screen addDefaultPadding>
      <Box justifyContent="space-between">
        <Box gap="sm">
          <Box gap="xs">
            <Box paddingLeft="sm">
              <Heading size="h4">{I18n.t("ACCOUNT.SECTIONS.PREFERENCES")}</Heading>
            </Box>
            <List>
              <List.Item
                title={I18n.t("ACCOUNT.LANGUAGE")}
                value={language?.label}
                onPress={() => languageBottomSheetRef.current?.present()}
                testID="LOCALE_ROW"
              />
              <List.Item
                title={I18n.t("ACCOUNT.TIME_ZONE")}
                value={timezone?.label}
                onPress={() => timezoneBottomSheetRef.current?.present()}
                testID="TIMEZONE_ROW"
              />
              <List.Item
                title={I18n.t("SETTINGS.GENERAL.THEME_SELECT.TITLE")}
                value={I18n.t(`THEME_SELECT.LABEL.${useTheme().theme.toUpperCase()}`)}
                onPress={() => navigation.navigate("ThemeSelect")}
              />
            </List>
          </Box>

          <List label={I18n.t("ACCOUNT.SECTIONS.INFO")}>
            <List.Item
              title={I18n.t("ACCOUNT.ORGANIZATION")}
              rightAccessory={
                <Text size="p1" color="light">
                  {organization?.name}
                </Text>
              }
              onPress={() => {
                orgNameBottomSheetRef.current?.present();
              }}
              testID="ORG_NAME_ROW"
            />
            <List.Item
              title={I18n.t("ACCOUNT.EMAIL")}
              rightAccessory={
                <Text size="p1" color="light">
                  {email}
                </Text>
              }
            />
            <List.Item
              title={I18n.t("ACCOUNT.CHANGE_PASSWORD")}
              onPress={() => navigation.navigate("PasswordReset")}
            />
            <List.Item
              title={I18n.t("ACCOUNT.BIOAUTH")}
              description={bioAuthDescription}
              rightAccessory={
                <Toggle
                  checked={bioauthEnabled}
                  onValueChange={setBioauthEnabled}
                  disabled={!hasBioauth}
                />
              }
            />
            <List.Item
              title={I18n.t("SETTINGS_SCREEN.GENERAL.ABOUT.TITLE")}
              onPress={() => navigation.navigate("About")}
              description={I18n.t("SETTINGS_SCREEN.GENERAL.ABOUT.SUBTITLE")}
            />
          </List>
        </Box>
      </Box>
      <Button
        text={I18n.t("ACCOUNT_SCREEN.LOG_OUT")}
        kind="secondaryDestructive"
        onPress={logOut}
        testID="LOG_OUT_BUTTON"
      />
      <Picker
        ref={languageBottomSheetRef}
        snapPoints={["75%"]}
        initialScrollIndex={localIdx}
        options={languages}
        onSelectOption={(option) => {
          const derivedLocale = option.value ?? currentLocale ?? ORIGINAL_LOCALE;
          updateLocale(derivedLocale);
          dispatch(setCurrentLocale(derivedLocale));
        }}
        selectedOption={language}
      />
      <Picker
        ref={timezoneBottomSheetRef}
        options={timezones?.options ?? []}
        snapPoints={["75%"]}
        initialScrollIndex={timeZoneIdx < 4 ? timeZoneIdx : timeZoneIdx - 4}
        onSelectOption={(option) => {
          updateNetwork(
            { networkId, body: { timeZone: option.value } },
            {
              onSuccess: () => {
                refetch();
              },
              onError: (error) => Alert.alert(String(error["errors"])),
            },
          );
        }}
        selectedOption={timezone}
      />
      <BottomSheet.Modal ref={orgNameBottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("ACCOUNT.ORG_NAME")}
          onResetPress={() => {
            methods.reset({ orgName: organization?.name ?? "" });
            orgNameBottomSheetRef.current?.close();
          }}
        />
        <BottomSheet.Content>
          <Box gap="sm">
            <Form {...methods}>
              <Form.Input
                name={"orgName"}
                label={I18n.t("NAME")}
                showClear={false}
                testID="ORG_NAME_INPUT"
              />
            </Form>
            <Button
              text={I18n.t("SAVE")}
              onPress={() => updateOrgSettings({ name: methods.watch("orgName") })}
              disabled={!methods.formState.isDirty || isLoading}
              loading={orgIsLoading}
              testID="SAVE_BUTTON"
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
