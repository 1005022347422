import { RouteName } from "@meraki/go/navigation-type";
import { Icon, IconName } from "@meraki/magnetic/icons";
import { NavigationRouteContext } from "@react-navigation/native";
import { useContext } from "react";

export type TabBarIconProps = {
  routeName?: RouteName;
  focused: boolean;
};

const RouteNameToIcon: Record<string, IconName> = {
  MonitorTab: "HouseSimple",
  InsightsTab: "ChartBar",
  HelpTab: "MagnifyingGlass",
  ConfigureTab: "TreeStructure",
  AlertsTab: "BellSimple",
};

const TabBarIcon = ({ routeName, focused }: TabBarIconProps) => {
  // Sharing with web, useRoute will throw if undefined
  const derivedRouteName = useContext(NavigationRouteContext)?.name ?? routeName;
  const color = focused ? "brandAccent.icon.active" : undefined;

  if (derivedRouteName) {
    const iconName = RouteNameToIcon[derivedRouteName];
    if (iconName) {
      return <Icon name={iconName} color={color} />;
    }
  }

  return null;
};

export default TabBarIcon;
