import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { launchGoWebApp } from "@meraki/go/links";
import DraggableList from "@meraki/shared/draggable-list";
import { useCurrentNetworkId, useGlobalTimespan, useHomeSSID } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { get } from "lodash";
import { useCallback, useState } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";

import { MAX_SSIDS, SPACING } from "~/constants/MkiConstants";
import ClientsOverview from "~/go/components/ClientsOverview";
import InlineAlert from "~/go/components/InlineAlert";
import LocationAnalyticsSummaryCard from "~/go/components/locationAnalytics/LocationAnalyticsSummaryCard";
import NextStepsMessage from "~/go/components/NextStepsMessage";
import QuickActionsCard from "~/go/components/QuickActionsCard";
import RefreshButton from "~/go/components/RefreshButton";
import SecurityMeasuresSummary from "~/go/components/SecurityMeasuresSummary";
import UsageChart from "~/go/components/UsageChart";
import { Features } from "~/go/types/ContextHelpTypes";
import { showAlert } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS, sendSetHomeNetworkEvent } from "~/lib/FirebaseUtils";
import { getLocationAnalyticsProps } from "~/lib/LocationAnalyticsUtils";
import { isDeepEmpty, nestedValueExists } from "~/lib/objectHelper";
import { isWeb } from "~/lib/PlatformUtils";
import {
  canWarnInvalidBridegeModeOnSSID,
  clientsSelector,
  counterSetsState,
  getHighEventIpsCount,
  getHomeScreenCardsOrder,
  getNetworkTimezone,
  getOrgNetworksCount,
  getTotalBlockedRequests,
  hasInvalidBridgeSSIDSelector,
  isLocationAnalyticsEnabled as locationAnalyticsEnabled,
  locationAnalyticsSummaryCardDataSelector,
  makeHardwareListDevices,
  networkUseblocksLoadingState,
  networkUseblocksSelector,
  onlineGRsWithPublicIPSelector,
  shouldShowUmbrellaUISelector,
  slimSsidsSelector,
  ssidUseblocksLoadingState,
  ssidUseblocksState,
} from "~/selectors";
import ExpandableSummaryCard from "~/shared/components/ExpandableSummaryCard";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import UsagePieChart from "~/shared/components/UsagePieChart";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { ExtendedClient } from "~/shared/types/Client";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

export const DraggableHomeScreenCards = ({ setReqPending, reqPending }: any) => {
  const [hasUnreadAlert, setHasUnreadAlert] = useState(true);
  const [hasUnreadWebappAlert, setHasUnreadWebappAlert] = useState(true);
  const [hasUnreadInvalidBridgeSSIDAlert, setHasUnreadInvalidBridgeSSIDAlert] = useState(true);
  const [pendingDeleteNetwork, setPendingDeleteNetwork] = useState(false);

  const homeSSID = useHomeSSID();
  const filter = makeHardwareListDevices();

  const ssids = useAppSelector(slimSsidsSelector);
  const clients = useAppSelector(clientsSelector) as ExtendedClient[]; // GO does not support SM;
  const counterSets = useAppSelector(counterSetsState);
  const timespan = useGlobalTimespan();
  const timezone = useAppSelector(getNetworkTimezone) || "";
  const networkId = useCurrentNetworkId() || "";
  const networkUsage = useAppSelector(networkUseblocksSelector);
  const networkUsageIsFetching = useAppSelector(networkUseblocksLoadingState);
  const ssidUsage = useAppSelector((state) => ssidUseblocksState(state, { ssidNumber: homeSSID }));
  const ssidUsageIsFetching = useAppSelector(ssidUseblocksLoadingState);
  const totalBlockedRequests = useAppSelector(getTotalBlockedRequests);
  const devicesByType = useAppSelector((state) =>
    filter(state, { customFilter: CUSTOM_FILTERS.DEFAULT }),
  );
  const highEventIpsCount = useAppSelector(getHighEventIpsCount);
  const shouldShowUmbrellaUI = useAppSelector(shouldShowUmbrellaUISelector);
  const presenceSummaryData = useAppSelector((state) =>
    locationAnalyticsSummaryCardDataSelector(state, timespan),
  );
  const isLocationAnalyticsEnabled = useAppSelector(locationAnalyticsEnabled);
  const hasInvalidBridgeSSIDs = useAppSelector(hasInvalidBridgeSSIDSelector);
  const onlineGRsWithPublicIP = useAppSelector(onlineGRsWithPublicIPSelector);
  const warningForInvalidBridgeModeEnabled = useAppSelector(canWarnInvalidBridegeModeOnSSID);
  const numberOfNetworks = useAppSelector(getOrgNetworksCount);
  const homeScreenCardsOrder = useAppSelector(getHomeScreenCardsOrder) || [];
  const { theme } = useTheme();

  const closeAlertCard = (key: string) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    switch (key) {
      case "hasUnreadInvalidBridgeSSIDAlert":
        setHasUnreadInvalidBridgeSSIDAlert(false);
        break;
      case "hasUnreadAlert":
        setHasUnreadAlert(false);
        break;
      case "hasUnreadWebappAlert":
        setHasUnreadWebappAlert(false);
        break;
    }
  };

  const navigation = useNavigation();
  const actions = useActions();

  const showingAllNetworks = useCallback(() => homeSSID === MAX_SSIDS, [homeSSID]);

  const closeInlineAlert = () => closeAlertCard("hasUnreadAlert");
  const closeWebappAlert = () => closeAlertCard("hasUnreadWebappAlert");
  const openNewWebapp = () => launchGoWebApp();
  const showContactSupport = () => {
    sendSetHomeNetworkEvent(homeSSID);
    navigation.navigate("SupportCenter");
  };
  const showFeedback = () => {
    sendSetHomeNetworkEvent(homeSSID);
    navigation.navigate("Wish");
  };

  const closeInlineAlertForSSID = () => {
    actions.setWarnInvalidBridgeMode(false);
    closeAlertCard("hasUnreadInvalidBridgeSSIDAlert");
  };
  const hasDevices = () => reqPending || !isDeepEmpty(devicesByType);

  const showOrgChooseModal = (visibleBackButton = true) => {
    sendSetHomeNetworkEvent(homeSSID);
    navigation.navigate("OrgChoose", {
      showLocationTitle: true,
      allowClose: visibleBackButton,
    });
  };

  const deleteNetwork = async () => {
    if (networkId != null) {
      try {
        setPendingDeleteNetwork(true);
        await actions.deleteNetwork(networkId);
        analytics.logEvent(FIREBASE_EVENTS.deleteNetwork);
        showOrgChooseModal(false);
      } catch (error) {
        showAlert(I18n.t("ERROR"), error || I18n.t("ERRORS.SERVER_ERROR_TEXT"));
      } finally {
        setPendingDeleteNetwork(false);
      }
    }
  };

  const renderListHeader = () => {
    let headerContent: JSX.Element | null = null;
    if (!hasDevices()) {
      headerContent = (
        <NextStepsMessage
          message={I18n.t("HARDWARE_EMPTY_STATE.MESSAGE")}
          onPress={() => {
            sendSetHomeNetworkEvent(homeSSID);
            navigation.navigate("AddHardware", { isOnboarding: false });
          }}
          buttonText={I18n.t("HARDWARE_EMPTY_STATE.BUTTON")}
          deleteText={numberOfNetworks > 1 ? I18n.t("HOME.DELETE.BUTTON") : undefined}
          disabled={reqPending}
          deleteAction={() =>
            showAlert(I18n.t("WARNING"), I18n.t("HOME.DELETE.CONFIRM"), deleteNetwork, {
              negativeText: I18n.t("CANCEL"),
            })
          }
          testID={"NO_HARDWARE_MESSAGE"}
        />
      );
    } else if (hasInvalidBridgeSSIDs && warningForInvalidBridgeModeEnabled) {
      headerContent = (
        <InlineAlert
          visible={hasUnreadInvalidBridgeSSIDAlert}
          onExit={closeInlineAlertForSSID}
          alertTitle={I18n.t("WARNING")}
          alertMessage={I18n.t("IN_INVALID_BRIDGE_WARNING", {
            grs: onlineGRsWithPublicIP.join(I18n.t("CONJUNCTIONS.AND")),
          })}
          testID={"INLINE_ALERT.INVALID_BRIDGE_SSID_ALERT"}
          preferenceKey={"INVALID_BRIDGE_SSID_ALERT"}
          screenStyles={styles.inlineAlertForSSID}
        />
      );
    }

    return (
      <View>
        {isWeb() ? <RefreshButton onRefresh={() => setReqPending(true)} /> : null}
        {headerContent}
      </View>
    );
  };

  const renderInlineAlerts = () => {
    return (
      <>
        <InlineAlert
          visible={hasUnreadWebappAlert && !isWeb()}
          onExit={closeWebappAlert}
          alertTitle={I18n.t("WEBAPP.ALERT.TITLE")}
          alertMessage={I18n.t("WEBAPP.ALERT.MESSAGE")}
          primaryButtonText={I18n.t("WEBAPP.ALERT.PRIMARY_BUTTON_TITLE")}
          onPrimaryPress={openNewWebapp}
          screenStyles={styles.inlineAlertForWebApp}
          testID={"INLINE_ALERT.TRY_WEB_APP"}
          preferenceKey={"TRY_WEB_APP"}
        />
        <InlineAlert
          visible={hasUnreadAlert}
          onExit={closeInlineAlert}
          alertTitle={I18n.t("FEEDBACK_REQUEST.TITLE")}
          alertMessage={I18n.t("FEEDBACK_REQUEST.MESSAGE")}
          primaryButtonText={I18n.t("FEEDBACK_REQUEST.PRIMARY_BUTTON_TITLE")}
          secondaryButtonText={I18n.t("FEEDBACK_REQUEST.SECONDARY_BUTTON_TITLE")}
          onPrimaryPress={showContactSupport}
          onSecondaryPress={showFeedback}
          screenStyles={styles.inlineAlert}
          testID={"INLINE_ALERT.FEEDBACK_REQUEST"}
          preferenceKey={"FEEDBACK_REQUEST"}
        />
      </>
    );
  };

  const clientsFilter = showingAllNetworks()
    ? CUSTOM_FILTERS.TIMESPAN_CLIENTS(timespan)
    : (clients: any) =>
        CUSTOM_FILTERS.SSID_CLIENTS(homeSSID)(
          CUSTOM_FILTERS.TIMESPAN_CLIENTS_HOME(timespan)(clients),
        );

  const reorderHomeCards = (fromIndex: any, toIndex: any) => {
    const newOrder = homeScreenCardsOrder.slice();
    newOrder.splice(toIndex, 0, newOrder.splice(fromIndex, 1)[0]);
    actions.setHomeScreenCardsOrder(newOrder);
    return null;
  };

  const ssidNumber = showingAllNetworks() ? -1 : homeSSID;

  const renderLocationAnalytics = (dragging: any) => {
    if (!presenceSummaryData || !isLocationAnalyticsEnabled || !hasDevices()) {
      return null;
    }

    const ssid = ssids[homeSSID];
    const locationAnalyticsProps = getLocationAnalyticsProps(ssid, presenceSummaryData);
    const ssidName = get(ssid, "name", "");

    return (
      <ExpandableSummaryCard
        heading={I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.HEADING")}
        subheading={I18n.t("LOCATION_ANALYTICS.SUMMARY_CARD.SUBHEADING")}
        loading={reqPending}
        context={Features.visitorInfo}
        savePreferencesKey={ExpandableCardKeys.locationAnalytics}
        dragging={dragging}
        testID={"LOCATION_ANALYTICS_CARD"}
      >
        <LocationAnalyticsSummaryCard
          {...locationAnalyticsProps}
          timespan={timespan}
          onPress={() => {
            sendSetHomeNetworkEvent(homeSSID);
            navigation.navigate("LocationAnalytics", {
              ssidName,
              initialTimespan: timespan,
            });
          }}
          onHomeScreen
        />
      </ExpandableSummaryCard>
    );
  };

  const renderCard = (item: any, dragging: any) => {
    const networkRusageSource = showingAllNetworks() ? networkUsage : ssidUsage;

    switch (item) {
      case ExpandableCardKeys.securityMeasures:
        return (
          showingAllNetworks() &&
          shouldShowUmbrellaUI && (
            <SecurityMeasuresSummary
              securityEventsCount={totalBlockedRequests}
              highEventsClientsCount={highEventIpsCount}
              loading={reqPending}
              onPressSecurityEvents={() => {
                sendSetHomeNetworkEvent(homeSSID);
                navigation.navigate("SecurityEventsList");
              }}
              onPressHighEvents={() => {
                sendSetHomeNetworkEvent(homeSSID);
                navigation.navigate("HighEventClientsList");
              }}
              dragging={dragging}
            />
          )
        );
      case ExpandableCardKeys.quickActions:
        return hasDevices() && <QuickActionsCard dragging={dragging} />;
      case ExpandableCardKeys.clientsOverview:
        return (
          hasDevices() && (
            <ClientsOverview
              clients={clients}
              navigate={(screen, params) => {
                sendSetHomeNetworkEvent(homeSSID);

                switch (screen) {
                  case "ClientList":
                    navigation.navigate("ClientList", params);
                    return;
                  case "ApplicationUsageList":
                    const ssidNumber = showingAllNetworks() ? -1 : homeSSID;
                    const ssidName = showingAllNetworks()
                      ? ""
                      : nestedValueExists(ssids, [homeSSID, "name"], "");

                    navigation.navigate("ApplicationUsageList", { ssidNumber, ssidName });
                    return;
                }
              }}
              loading={reqPending}
              counterSets={counterSets}
              customFilter={clientsFilter}
              ssidNumber={ssidNumber}
              context={Features.deviceInfo}
              dragging={dragging}
            />
          )
        );
      case ExpandableCardKeys.usageChart:
        return (
          hasDevices() &&
          networkUsage && (
            <UsageChart
              networkUsageIsFetching={networkUsageIsFetching}
              ssidUsageIsFetching={ssidUsageIsFetching}
              networkUsage={networkUsage}
              ssidUsage={ssidUsage}
              timespan={timespan}
              timezone={timezone}
              showingAllNetworks={showingAllNetworks()}
              dragging={dragging}
            />
          )
        );
      case ExpandableCardKeys.applicationUsage:
        return (
          hasDevices() && (
            <ExpandableSummaryCard
              heading={I18n.t("HOME.APPLICATIONS.TITLE")}
              subheading={I18n.t("HOME.APPLICATIONS.SUBTITLE")}
              loading={reqPending}
              savePreferencesKey={ExpandableCardKeys.applicationUsage}
              dragging={dragging}
              testID={"APPLICATION_USAGE_CARD"}
            >
              <UsagePieChart
                testID="HOME.APPLICATION_USAGE"
                rusage={nestedValueExists(networkRusageSource, ["rusage"], {})}
                counterSets={counterSets}
                chartStyle={styles.pieChart}
                numSections={4}
                onPress={() => {
                  sendSetHomeNetworkEvent(homeSSID);
                  navigation.navigate(
                    "ApplicationUsageList",
                    showingAllNetworks()
                      ? {}
                      : {
                          ssidName: nestedValueExists(ssids, [homeSSID, "name"], ""),
                          ssidNumber: homeSSID,
                        },
                  );
                }}
                theme={theme}
              />
            </ExpandableSummaryCard>
          )
        );
      case ExpandableCardKeys.locationAnalytics:
        return renderLocationAnalytics(dragging);
      default:
        return null;
    }
  };

  return (
    <>
      <DraggableList
        data={homeScreenCardsOrder}
        renderItem={renderCard}
        onReorder={reorderHomeCards}
        onRefresh={() => setReqPending(true)}
        refreshing={reqPending}
        listHeader={renderListHeader()}
        listFooter={renderInlineAlerts()}
        testID="DRAGGABLE_HOME_SCREEN_CARDS"
      />
      <LoadingSpinner visible={pendingDeleteNetwork} />
    </>
  );
};

const styles = StyleSheet.create({
  pieChart: {
    paddingLeft: SPACING.small,
    paddingVertical: SPACING.default,
  },
  inlineAlertForSSID: {
    marginTop: SPACING.small,
  },
  inlineAlertForWebApp: {
    marginTop: SPACING.large,
  },
  inlineAlert: {
    marginVertical: SPACING.large,
  },
});
