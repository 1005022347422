import {
  BATCH_UPDATE_DEVICE_SUCCESS,
  DEVICE_LLDP_SUCCESS,
  FETCH_DEVICE_BY_SERIAL_SUCCESS,
  FETCH_ONBOARDING_STATUS_SUCCESS,
  FETCH_SINGLE_DEVICE_DETAILS_SUCCESS,
  GET_HARDWARE_RADIO_SETTINGS_SUCCESS,
  LSP_CONNECTION_FAILURE,
  LSP_CONNECTION_SUCCESS,
  MANAGEMENT_INTERFACE_SUCCESS,
  NODE_IMAGE_DELETE_SUCCESS,
  NODE_IMAGE_UPLOAD_SUCCESS,
  NODES_JSON_SUCCESS,
  NODES_SUCCESS,
  ONBAORDING_BATCH_CLAIM_SUCCESS,
  ORG_DEVICE_STATUSES_SUCCESS,
  ORG_WIDE_DEVICES_SUCCESS,
  REMOVE_NODE_SUCCESS,
  SENSOR_ALERT_PROFILES_SUCCESS,
  SET_DEVICE,
  SET_HARDWARE_RADIO_SETTINGS_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { isEmpty, omit, pickBy, uniq } from "lodash";
import { AnyAction } from "redux";

import DeviceStatus from "~/api/models/DeviceStatus";
import {
  WirelessOnboardingStatus,
  WirelessOnboardingStatusResponse,
} from "~/api/models/WirelessOnboardingStatusResponse";
import { convertTagsToArray } from "~/lib/DeviceUtils";
import resolvedState from "~/lib/ReducerUtils";
import { hasNATRouter } from "~/lib/SSIDUtils";
import { isString } from "~/lib/TypeHelper";
import Device from "~/shared/types/Device";
import { RadioSettings } from "~/shared/types/RadioSettingsTypes";
import { AlertProfile } from "~/shared/types/SensorAlertProfile";

export const initialState = {};

interface ReduxDevice extends Device {
  alertProfileIds?: string[];
  id: string;
  hasNATRouter: boolean;
  name?: string;
  notes?: string;
  radioSettings: RadioSettings;
  serial: string;
  wirelessOnboardingStatus?: WirelessOnboardingStatus;
  tags?: string | string[];
}

interface DeviceNodes {
  [serialNumber: string]: Device;
}

interface DeviceState {
  [serialNumber: string]: ReduxDevice;
}

export const devices = (state: DeviceState = initialState, action: AnyAction): DeviceState => {
  const { type, meta, response } = action;

  switch (type) {
    case FETCH_ONBOARDING_STATUS_SUCCESS: {
      if (isEmpty(response)) {
        return state;
      }
      const nextState = { ...state };
      const nodes: [WirelessOnboardingStatusResponse] = response;
      nodes.forEach((node: WirelessOnboardingStatusResponse) => {
        nextState[node.serial] = {
          ...nextState[node.serial],
          wirelessOnboardingStatus: node.status,
        };
      });
      return nextState;
    }
    case NODES_JSON_SUCCESS: {
      if (isEmpty(response)) {
        return state;
      }
      const { serial } = meta;
      const nextState = { ...state };
      const nodes: DeviceNodes = response.nodes;
      Object.entries(nodes).forEach(([key, value]) => {
        const nodeSerial = serial || value.serial;
        nextState[nodeSerial] = {
          ...nextState[nodeSerial],
          ...value,
          id: key,
          hasNATRouter: hasNATRouter({ ...nextState[value.serial], ...value }),
        };
      });
      return resolvedState(nextState, state);
    }
    case FETCH_DEVICE_BY_SERIAL_SUCCESS:
    case UPDATE_DEVICE_SUCCESS:
    case FETCH_SINGLE_DEVICE_DETAILS_SUCCESS: {
      const device: Device = response;

      return {
        ...state,
        [device.serial]: {
          ...(state[device.serial] ?? {}),
          ...device,
        },
      };
    }
    case ORG_WIDE_DEVICES_SUCCESS:
    case NODES_SUCCESS: {
      const nodes: DeviceNodes = response.entities.nodes || {};

      // NOTE: This pickBy is done to remove devices that are no longer in the response
      const nextState = pickBy(state, (_: any, key: string) => key in nodes);

      Object.entries(nodes).forEach(([key, value]) => {
        const newName = value.name;
        const newTags = isString(value.tags) ? convertTagsToArray(value.tags) : value.tags;
        const newNotes = value.notes;
        const newImages = value.images;

        nextState[key] = {
          ...nextState[key],
          ...value,
          name: newName,
          tags: newTags,
          notes: newNotes,
          images: newImages,
          hasNATRouter: hasNATRouter({ ...nextState[key], ...value }),
        };
      });
      return resolvedState(nextState, state);
    }
    case SET_DEVICE:
      return {
        ...state,
        [action.device.serial]: {
          ...(state[action.device.serial] || {}),
          ...action.device,
        },
      };
    case NODE_IMAGE_UPLOAD_SUCCESS: {
      const { nodeSerial } = meta;
      const { image_urls, id } = response;
      return {
        ...state,
        [nodeSerial]: {
          ...state[nodeSerial],
          images: {
            ...image_urls,
            id,
          },
        },
      };
    }
    case NODE_IMAGE_DELETE_SUCCESS: {
      const { nodeSerial } = meta;
      return {
        ...state,
        [nodeSerial]: {
          ...state[nodeSerial],
          images: undefined,
        },
      };
    }
    case MANAGEMENT_INTERFACE_SUCCESS: {
      const { nodeSerial } = meta;

      return {
        ...state,
        [nodeSerial]: {
          ...state[nodeSerial],
          ...response,
        },
      };
    }
    case ONBAORDING_BATCH_CLAIM_SUCCESS: {
      return {
        ...state,
        ...response.new_nodes,
      };
    }
    case ORG_DEVICE_STATUSES_SUCCESS: {
      if (isEmpty(response)) {
        return state;
      }
      const nextState = { ...state };
      const nodes: [DeviceStatus] = response;
      nodes.forEach((node: DeviceStatus) => {
        nextState[node.serial] = {
          ...nextState[node.serial],
          status: node.status,
        };
      });

      return nextState;
    }
    case GET_HARDWARE_RADIO_SETTINGS_SUCCESS:
    case SET_HARDWARE_RADIO_SETTINGS_SUCCESS: {
      const { serialNumber } = meta;

      return {
        ...state,
        [serialNumber]: {
          ...(state[serialNumber] || {}),
          radioSettings: response,
        },
      };
    }
    case DEVICE_LLDP_SUCCESS: {
      const { serialNumber } = meta;
      return {
        ...state,
        [serialNumber]: {
          ...(state[serialNumber] || {}),
          lldp: response,
        },
      };
    }
    case SENSOR_ALERT_PROFILES_SUCCESS: {
      const alertProfiles: AlertProfile[] = response;
      const nextDevices = alertProfiles?.reduce((nextDevices, alertProfile) => {
        alertProfile.serials.forEach((serial) => {
          const device = state[serial];
          const deviceExistsInState = Boolean(device);
          if (deviceExistsInState) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const nextDevice = nextDevices[serial];
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            nextDevices[serial] = {
              ...device,
              alertProfileIds: uniq([
                ...(device?.alertProfileIds || []),
                ...(nextDevice?.alertProfileIds || []),
                alertProfile.id,
              ]),
            };
          }
        });
        return nextDevices;
      }, {});
      return { ...state, ...nextDevices };
    }
    case LSP_CONNECTION_FAILURE: {
      const { serial } = meta;

      if (serial != null) {
        return {
          ...state,
          [serial]: {
            ...(state[serial] || {}),
            lspConnected: false,
          },
        };
      }

      return Object.entries(state).reduce((newState, [serial, device]) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[serial] = {
          ...device,
          lspConnected: false,
        };
        return newState;
      }, {});
    }
    case LSP_CONNECTION_SUCCESS: {
      const { serial } = meta;
      const connectedMac = response?.config?.mac;

      if (serial != null) {
        return {
          ...state,
          [serial]: {
            ...(state[serial] || {}),
            lspConnected: state[serial]?.mac === connectedMac,
          },
        };
      }

      return Object.entries(state).reduce((newState, [serial, device]) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[serial] = {
          ...device,
          lspConnected: device.mac === connectedMac,
        };
        return newState;
      }, {});
    }
    case REMOVE_NODE_SUCCESS: {
      const { serial } = meta;
      return omit(state, serial);
    }
    case BATCH_UPDATE_DEVICE_SUCCESS: {
      const { deviceChanges } = meta;
      let newState = {
        ...state,
      };

      for (const [serial, change] of Object.entries(
        deviceChanges as { [serial: string]: Object },
      )) {
        newState = {
          ...newState,
          [serial]: {
            ...(state[serial] || {}),
            ...change,
          },
        };
      }
      return newState;
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default devices;
