import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import {
  Modal,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import AddLicenseRow from "~/go/components/AddLicenseRow";
import LicenseRow from "~/go/components/LicenseRow";
import { useNetworkUmbrellaLicenses } from "~/go/hooks/useFilteredLicenses";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

interface LicensesModalProps {
  visible: boolean;
  onDismiss: () => void;
}

const LicensesModal: React.FunctionComponent<LicensesModalProps> = ({ visible, onDismiss }) => {
  const modalColors = themeColors(useTheme());
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { data: licenses } = useNetworkUmbrellaLicenses(organizationId, networkId);
  if (!visible) {
    return null;
  }

  return (
    <Modal
      transparent
      animationType="fade"
      visible={visible}
      onRequestClose={onDismiss}
      testID="LICENSE_MODAL"
    >
      <TouchableOpacity style={styles.container} activeOpacity={1} onPress={onDismiss}>
        <TouchableWithoutFeedback>
          <View style={[styles.card, { backgroundColor: modalColors.frontPanel.backgroundColor }]}>
            <View style={styles.header}>
              <MkiText textStyle="subheading">
                {I18n.t("UMBRELLA.SUBSCRIPTION.LICENSES.TITLE")}
              </MkiText>
              <MerakiIcon
                testID="LICENSES_MODAL.CANCEL"
                name="close"
                size="s"
                color={MkiColors.secondaryButton}
                onPress={onDismiss}
              />
            </View>
            <View style={styles.body}>
              <ScrollView>
                {licenses?.map((license) => (
                  <LicenseRow license={license} key={`LICENSE_ROW_${license.licenseKey}`} />
                ))}
                <AddLicenseRow />
              </ScrollView>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MkiColors.modalBackgroundColor,
    justifyContent: "center",
  },
  card: {
    width: "60%",
    maxWidth: 600,
    alignSelf: "center",
    paddingTop: SPACING.small,
    paddingBottom: SPACING.default,
    paddingHorizontal: SPACING.default,
    borderRadius: 6,
  },
  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: SPACING.default,
  },
  body: {
    flex: 9,
    flexDirection: "column",
    justifyContent: "center",
    padding: SPACING.default,
  },
});

export default LicensesModal;
