import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Indeterminate({ disabled }: IconProps) {
  const theme = useMagneticTheme();

  if (disabled) {
    return (
      <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={3}
          fill={theme.color.control.bg.weak.disabled}
        />
        <Path
          d="M15 11H5V9H15V11Z"
          fill={theme.color.control.iconIn.disabled}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={3}
          stroke={theme.color.control.border.disabled}
          strokeWidth={2}
        />
      </Svg>
    );
  }

  return (
    <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
      <Rect width={20} height={20} rx={4} fill={theme.color.control.bg.strong.base} />
      <Path
        d="M15 11H5V9H15V11Z"
        fill={theme.color.control.iconIn.active}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
