import * as errorMonitor from "@meraki/core/errors";
import {
  REGISTER_SESSION_FAILURE,
  REGISTER_SESSION_REQUEST,
  REGISTER_SESSION_SUCCESS,
} from "@meraki/shared/redux";
import DeviceInfo from "react-native-device-info";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { setupNotifications } from "~/shared/lib/NotificationUtils";
import { initAppLinkListener } from "~/shared/navigation/Linking";
import { AppThunk } from "~/shared/types/Redux";

export function registerSession(deviceToken: string): AppThunk<Promise<ApiResponseAction<any>>> {
  const udid = DeviceInfo.getUniqueId();
  const deviceType = DeviceInfo.getSystemName().toLowerCase();
  return wrapApiActionWithCSRF({
    types: [REGISTER_SESSION_REQUEST, REGISTER_SESSION_SUCCESS, REGISTER_SESSION_FAILURE],
    endpoint: "/mobile/register_session",
    config: {
      method: "POST",
      body: JSON.stringify({
        device_token: deviceToken,
        device_type: deviceType,
        udid,
      }),
    },
  });
}

export function setupAndRegisterPushNotifications(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const pushToken = await setupNotifications();
      await dispatch(registerSession(pushToken));
    } catch (e) {
      errorMonitor.notify("Failed to setup push notifications, insufficient permissions");
    }
  };
}

export function initAppLinking(
  handler: (url: string) => void,
  enablePush = true,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    if (enablePush) {
      await dispatch(setupAndRegisterPushNotifications());
    }
    await initAppLinkListener(handler);
  };
}
