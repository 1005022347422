import { I18n } from "@meraki/core/i18n";
import { DeviceIllustration } from "@meraki/go/device-illustration";
import { BottomSheet, BottomSheetMethods, Card, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { usePing } from "@meraki/react-live-broker";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import Animated, {
  Easing,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

interface PokeDeviceBottomSheetProps {
  deviceId: string;
  model: string;
}

const NUM_OF_REPS = 10;
const LATENCY_LIMIT = 150;
const LOSS_LIMIT = 10;

type PokeDeviceStatus = "Failed" | "Passed" | "TimedOut";

const PokeDevice = ({ deviceId, model }: PokeDeviceBottomSheetProps) => {
  const pokeDeviceStatus = usePing(deviceId);

  const [state, setState] = useState<PokeDeviceStatus>();
  const opacity = useSharedValue(1);

  useEffect(() => {
    if (pokeDeviceStatus) {
      opacity.value = 1;
      const { averageLatency, lossRate } = pokeDeviceStatus;

      const passed = averageLatency <= LATENCY_LIMIT && lossRate <= LOSS_LIMIT;
      setState(passed ? "Passed" : "Failed");
    } else if (pokeDeviceStatus === undefined) {
      opacity.value = withRepeat(
        withTiming(0, { duration: 1000, easing: Easing.ease }),
        NUM_OF_REPS,
        true,
        (finished?: boolean) => {
          if (finished && pokeDeviceStatus === undefined) {
            runOnJS(setState)("TimedOut");
            opacity.value = 1;
          }
        },
      );
    }
  }, [pokeDeviceStatus, opacity]);

  const animatedStyles = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);

  return (
    <Box gap="sm" alignItems="center">
      <Text>{I18n.t("POKE_DEVICE.DESCRIPTION")}</Text>

      <Animated.View style={animatedStyles}>
        <Card>
          <Box gap="sm" alignItems="center">
            <DeviceIllustration model={model} size={"medium"} />
            {pokeDeviceStatus && (
              <>
                <Text>{`${I18n.t("AVERAGE_LATENCY")} ${Math.round(
                  pokeDeviceStatus.averageLatency,
                )}ms`}</Text>
                <Text>{`${I18n.t("LOSS_RATE")} ${Math.round(pokeDeviceStatus.lossRate)}%`}</Text>
              </>
            )}
            {!pokeDeviceStatus && <Text>{I18n.t("POKE_DEVICE.POKING_DEVICE")}</Text>}
          </Box>
        </Card>
      </Animated.View>
      {state === "Passed" ? (
        <Text>{I18n.t("POKE_DEVICE.PASS.DESCRIPTION")}</Text>
      ) : (
        <Box gap="2xs">
          {state === "TimedOut" && (
            <Text>{I18n.t("POKE_DEVICE.FAIL.POOR_PERFORMANCE.DESCRIPTION")}</Text>
          )}
          {state === "Failed" && (
            <Text>{I18n.t("POKE_DEVICE.FAIL.COMPLETE_FAIL.DESCRIPTION")}</Text>
          )}
          <Text>{I18n.t("POKE_DEVICE.FAIL.TROUBLESHOOTING.MESSAGE")}</Text>
        </Box>
      )}
    </Box>
  );
};

export const PokeDeviceBottomSheet = forwardRef(function PokeDeviceBottomSheet(
  { deviceId, model }: PokeDeviceBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref}>
      <BottomSheet.Header
        title={I18n.t("POKE_DEVICE.TITLE")}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => {
          if (typeof ref !== "function") {
            ref?.current?.dismiss();
          }
        }}
      />
      <BottomSheet.Content>
        <PokeDevice deviceId={deviceId} model={model} />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
});
