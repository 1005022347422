import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import PortTypes, { PortStatus } from "~/constants/PortLayouts";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon, { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";
import SharedColors from "~/shared/constants/SharedColors";
import { useTheme } from "~/shared/hooks/useTheme";

// The widths are based on the percentage of the panelWidth
const RJ45_CONSTANT = 0.026;
const SFP_CONSTANT = 0.032;
const QSFP_CONSTANT = 0.045;

interface PortProps extends PortStatusProps {
  portId: string;
  portType: PortTypes;
  panelWidth: number;
  style?: StyleProp<ViewStyle>;
}

interface PortStatusProps {
  status: PortStatus;
  hideStatusIcon?: boolean;
  fadedOut?: boolean;
}

const usePortColor = (status: PortStatus) => {
  const { disabled, active, alerting, disconnected } = themeColors(useTheme()).port;

  switch (status) {
    case PortStatus.disabled:
      return disabled;
    case PortStatus.uplink:
    case PortStatus.poe:
    case PortStatus.active:
      return active;
    case PortStatus.alerting:
      return alerting;
    default:
      return disconnected;
  }
};

const PortStatusIcon = ({
  status,
  size,
  color = SharedColors.white,
}: PortStatusProps & Pick<MerakiIconProps, "size" | "color">) => {
  let iconName: string | undefined;

  switch (status) {
    case PortStatus.uplink:
      iconName = "arrow-up";
      break;
    case PortStatus.poe:
      iconName = "bolt";
      break;
  }

  if (!iconName) {
    return null;
  }

  return <MerakiIcon name={iconName} size={size} color={color} />;
};

export function usePortHeight(portType: PortTypes, panelWidth: number) {
  let scaleFactor = RJ45_CONSTANT;

  // includes b/c could be suffixed for irregular port layouts
  if (portType.includes(PortTypes.qsfp)) {
    scaleFactor = QSFP_CONSTANT;
  } else if (portType.includes(PortTypes.sfp)) {
    scaleFactor = SFP_CONSTANT;
  }

  return Math.ceil(panelWidth * scaleFactor);
}

const Port = ({
  portId,
  portType,
  panelWidth,
  status,
  fadedOut,
  hideStatusIcon = false,
  style,
}: PortProps) => {
  const portColor = usePortColor(status);
  const width = usePortHeight(portType, panelWidth);

  const portStyle = {
    height: usePortHeight(PortTypes.rj45, panelWidth),
    width,
    backgroundColor: portColor,
    opacity: fadedOut ? 0.15 : 1,
  };

  const iconSize = Math.floor(panelWidth * RJ45_CONSTANT - 1);

  return (
    <View key={portId} style={[styles.base, portStyle, style]}>
      {!hideStatusIcon && <PortStatusIcon status={status} size={iconSize} />}
    </View>
  );
};

const styles = StyleSheet.create({
  base: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Port;
