import { Vlan } from "@meraki/shared/api";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import GoColors from "~/go/constants/GoColors";
import { ExportType } from "~/lib/ExportTemplateUtils";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import ListRow from "~/shared/rows/ListRow";

interface VlanExportRowProps {
  vlan: Vlan;
  isSelected: boolean;
  tapHandler: (type: ExportType, id: number) => void;
}

const VlanExportRow = ({ vlan, isSelected, tapHandler }: VlanExportRowProps) => {
  return (
    <ListRow
      rowStyles={styles.rowStyle}
      testID={`VLAN_EXPORT_ROW.${vlan.id}`}
      icon={
        <View style={styles.iconView}>
          <StatusIcon
            testID="VLAN_EXPORT_ROW.LAN_ICON"
            customIcon={{
              icon: MerakiIcon,
              name: "sitemap",
              size: "s",
              color: GoColors.navyLighter,
              hasGradient: false,
            }}
            screenStyles={styles.icon}
          />
        </View>
      }
      rightStyle={styles.rightAccessory}
      accessory={<CheckBox selected={isSelected} />}
      onPress={() => tapHandler(ExportType.vlan, vlan.id)}
    >
      {vlan.name}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyle: {
    paddingVertical: SPACING.small,
  },
  icon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  iconView: {
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  rightAccessory: {
    paddingRight: SPACING.large,
  },
});

export default VlanExportRow;
