import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const BASE_ORGANIZATION_URL = "/api/v1/organizations";

const buildUrl = ({ organizationId }: OrganizationRequest) => {
  return `${BASE_ORGANIZATION_URL}/${organizationId}`;
};

interface OrganizationRequest {
  organizationId?: string;
}

export const OrganizationSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  api: z.object({ enabled: z.boolean() }),
  licensing: z.object({ model: z.string() }),
  cloud: z.object({ region: z.object({ name: z.string().nullable() }) }),
  management: z.object({
    details: z.array(z.object({ name: z.string(), value: z.string() })),
  }),
  shardId: z.number().nullable(),
  eid: z.string(),
});

export type Organization = z.infer<typeof OrganizationSchema>;

export type MutateOrganizationRequest = OrganizationRequest & {
  body: Partial<Organization>;
};

const fetchOrganization = ({ organizationId }: OrganizationRequest) => {
  return request(OrganizationSchema, "GET", buildUrl({ organizationId }));
};

const fetchOrganizations = () => {
  return request(OrganizationSchema.array(), "GET", BASE_ORGANIZATION_URL);
};

function mutateOrganization({ organizationId, body }: MutateOrganizationRequest) {
  return request(OrganizationSchema, "PUT", buildUrl({ organizationId }), {
    body: JSON.stringify(body),
  });
}

export const useOrganization = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: OrganizationRequest) => fetchOrganization(parameters),
  requiredVariables: ["organizationId"],
});

export const useEditOrganization = createMutation<
  MutateOrganizationRequest,
  Organization,
  APIResponseError
>({
  baseMutationKey: buildUrl({ organizationId: `{organizationId}` }),
  mutationFn: (parameters: MutateOrganizationRequest) => mutateOrganization(parameters),
});

export const useOrganizations = createQuery({
  baseQueryKey: BASE_ORGANIZATION_URL,
  queryFn: () => fetchOrganizations(),
});

function deleteOrganization({ organizationId }: OrganizationRequest): Promise<void> {
  return request(z.undefined(), "DELETE", buildUrl({ organizationId }));
}

export const useDeleteOrganization = createMutation<OrganizationRequest, void, APIResponseError>({
  baseMutationKey: buildUrl({ organizationId: "{organizationId}" }),
  mutationFn: (variables) => deleteOrganization(variables),
});
