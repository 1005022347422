import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet } from "react-native";

import LiveToolsButton from "~/go/components/liveTools/LiveToolsButton";
import { showActionSheet } from "~/lib/AlertUtils";
import { sortGoDeviesByProduct } from "~/lib/DeviceUtils";
import { devicesSelector, slimSsidsSelector } from "~/selectors";
import ExpandableSummaryCard from "~/shared/components/ExpandableSummaryCard";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

interface QuickActionsCardProps {
  dragging?: boolean;
}

const QuickActionsCard = (props: QuickActionsCardProps) => {
  const { dragging } = props;

  const navigation = useNavigation();
  const ssids = useAppSelector(slimSsidsSelector)?.filter((ssid: any) => ssid.configured);
  const devices = useAppSelector(devicesSelector).sort(sortGoDeviesByProduct);

  return (
    <ExpandableSummaryCard
      heading={I18n.t("HOME.QUICK_ACTIONS.TITLE")}
      contentContainerStyles={styles.liveToolsContainer}
      savePreferencesKey={ExpandableCardKeys.quickActions}
      dragging={dragging}
      testID={"QUICK_ACTIONS_CARD"}
    >
      <LiveToolsButton
        testID={"SPEED_TEST"}
        name={"gauge"}
        label={I18n.t("HOME.QUICK_ACTIONS.SPEED_TEST")}
        onPress={() => navigation.navigate("SpeedTest")}
      />
      <LiveToolsButton
        testID="REBOOT_BUTTON"
        label={I18n.t("HOME.QUICK_ACTIONS.REBOOT.BUTTON_TITLE")}
        name={"reboot"}
        onPress={() => {
          showActionSheet(
            devices.map((device) => device.name || device.serial),
            (id: any) => {
              const device = devices[id];

              if (device == null) {
                return;
              }

              navigation.navigate("Restart", {
                device,
              });
            },
            {
              title: I18n.t("HOME.QUICK_ACTIONS.REBOOT.ACTION_SHEET_TITLE"),
            },
          );
        }}
      />
      <LiveToolsButton
        testID={"SHARE_WIFI"}
        enabled={ssids.length > 0}
        name={"share"}
        label={I18n.t("HOME.QUICK_ACTIONS.SHARE_WIFI")}
        onPress={() => {
          showActionSheet(
            ssids.map((ssid: any) => ssid.name),
            (id: any) => {
              const ssid = ssids[id];
              navigation.navigate("SSIDShare", {
                name: ssid.name,
                encryptionMode: ssid.encryptionMode,
                passphrase: ssid.psk,
                ssid,
              });
            },
          );
        }}
      />
    </ExpandableSummaryCard>
  );
};

const styles = StyleSheet.create({
  liveToolsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
});

export default QuickActionsCard;
