import { useQueries } from "@tanstack/react-query";
import { z } from "zod";

import { request } from "../../../api/request/request";
import { queryClient } from "../../../queryClient";
import { createQuery } from "../../createQuery";
import { OrgNetwork } from "../../network/useOrgNetworks";

export const NetworkAccessRolesSchema = z
  .object({
    can_write: z.boolean(),
    is_camera_only_admin: z.boolean(),
    is_scoped_admin: z.boolean(),
    is_sensor_only_admin: z.boolean(),
    monitor_only: z.boolean(),
  })
  .describe("NetworkAccessRolesSchema");

export type NetworkAccessRoles = z.infer<typeof NetworkAccessRolesSchema>;

const DEFAULT_ROLES = {
  can_write: false,
  is_camera_only_admin: false,
  is_scoped_admin: false,
  is_sensor_only_admin: false,
  monitor_only: false,
};

type NetworkAccessRoleRequest = {
  networkEid: string | undefined;
};

function buildUrl({ networkEid }: NetworkAccessRoleRequest) {
  return `/n/${networkEid}/manage/users/access_role`;
}

const fetchNetworkAccessRoles = ({
  networkEid,
}: NetworkAccessRoleRequest): Promise<NetworkAccessRoles> =>
  request(NetworkAccessRolesSchema, "GET", buildUrl({ networkEid }));

/**
 * @privateapi Public endpoints should be used whenever possible
 */
const useFetchNetworkAccessRolesQuery = createQuery<NetworkAccessRoleRequest, NetworkAccessRoles>({
  baseQueryKey: buildUrl({ networkEid: "{networkEid}" }),
  queryFn: (variables) => fetchNetworkAccessRoles(variables),
});

const fetchEids = (network?: OrgNetwork) => {
  const networkEid = network?.eid;
  const cameraEid = network?.ngIds?.camera?.ngEid;
  const sensorEid = network?.ngIds?.sensor?.ngEid;
  return [networkEid, cameraEid, sensorEid].filter((eid) => !!eid);
};

const aggregateRoles = (responses: (NetworkAccessRoles | undefined)[]) => {
  const roles = { ...DEFAULT_ROLES };
  for (const data of responses) {
    if (!data) {
      continue;
    }
    for (const key of Object.keys(data) as (keyof NetworkAccessRoles)[]) {
      //If the current key of the role is true then we keep that value. If it is false then use the value
      //returned from the api.
      roles[key] = roles[key] || data[key];
    }
  }
  return roles;
};

export const fetchAllNetworkAccessRoles = async (network?: OrgNetwork) => {
  const eids = fetchEids(network);

  const responses = await Promise.all(
    eids.map(async (eid) => {
      try {
        return await queryClient.fetchQuery(
          useFetchNetworkAccessRolesQuery.useQueries({ networkEid: eid }),
        );
      } catch (error) {
        return DEFAULT_ROLES;
      }
    }),
  );

  return aggregateRoles(responses);
};

export const useFetchNetworkAccessRoles = (
  { network }: { network?: OrgNetwork },
  { enabled }: { enabled: boolean },
) => {
  const eids = fetchEids(network);

  const rawData = useQueries({
    queries: eids.map((eid) =>
      useFetchNetworkAccessRolesQuery.useQueries({ networkEid: eid }, { enabled }),
    ),
  });

  const responses = rawData.map(({ data }) => data);

  return aggregateRoles(responses);
};
