import { getIpAddressSync } from "react-native-device-info";
import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const ClientLLdpSchema = z.object({
  lldp: z
    .object({
      opts: z.array(z.array(z.number().or(z.string())).length(2)),
      device: z.string().optional(),
      port: z.string().optional(),
    })
    .nullable(),
});

const ClientVpnRecord = z.object({
  connected_at: z.optional(z.number()),
  disconnected_at: z.optional(z.number()),
  remote_ip: z.optional(z.string()),
});

export const OldClientSchema = z.object({
  id: z.string(),
  description: z.string(),
  node_id: z.string(),
  connected_by: z.string(),
  ssid_name: z.string(),
  user: z.string(),
  user_src: z.string(),
  notes: z.string(),
  ip: z.string(),
  ip6: z.string(),
  ip6_local: z.string(),
  os: z.string(),
  capabilities: z.string(),
  client_features: z.string(),
  vlan: z.string(),
  named_vlan: z.string(),
  mac: z.string(),
  last_seen: z.number(),
  first_seen: z.number(),
  products: z.string(),
  recv: z.number(),
  sent: z.number(),
  has_pcc: z.string(),
  wired_group_num: z.string(),
  wireless_group_nums: z.string(),
  ssid_masks: z.string(),
  client_vpn_details: z
    .object({
      user: z.string(),
      connections: z.record(z.string(), ClientVpnRecord),
    })
    .nullable(),
  lldp: ClientLLdpSchema,
  blocked_message: z.string(),
  group_policy_8021x: z.string(),
  physical_ports: z.array(z.string()),
  port_type: z.string(),
  device_type_prediction: z.string(),
  network_id: z.string(),
  hashed_ip4: z.string(),
  onboarding_score: z.number().nullable(),
  performance_score: z.number().nullable(),
  adaptive_policy_group: z.string(),
  adaptive_policy_sgt_src: z.string(),
  sm_serial_number: z.string(),
  sm_os_version: z.string(),
  sm_last_mdm_checkin: z.string(),
  sm_anyconnect_app_version: z.string(),
  sm_owner_username: z.string(),
  sm_owner_email: z.string(),
  sm_owner_full_name: z.string(),
  is_trusted_access_device: z.string(),
});

export const OldClientsSchema = z.array(OldClientSchema).describe("OldClientsSchema");

export type OldClientType = z.infer<typeof OldClientSchema> & { manufacturer?: string };
export type OldClientsType = z.infer<typeof OldClientsSchema>;

export const ClientStatusSchema = z
  .union([z.literal("Online"), z.literal("Offline")] as const)
  .describe("ClientStatusSchema");

export type ClientStatus = z.infer<typeof ClientStatusSchema>;

export const ClientSchema = z
  .object({
    id: z.string(),
    mac: z.string(),
    description: z.string().nullable(),
    ip: z.string().nullable(),
    ip6: z.string().nullable(),
    ip6Local: z.string().nullable(),
    user: z.string().nullable(),
    firstSeen: z.string(),
    lastSeen: z.string(),
    manufacturer: z.string().nullable().optional(),
    os: z.string().nullable(),
    deviceTypePrediction: z.string().nullable(),
    recentDeviceSerial: z.string().optional(),
    recentDeviceName: z.string().nullable().optional(),
    recentDeviceMac: z.string().nullable().optional(),
    recentDeviceConnection: z.string().nullable().optional(),
    // this field is called ssid, but conflicts with an existing ssid field we have
    // and the conflicts should be handled separately before we move to the public endpoint
    ssidName: z.string().nullable().optional(),
    ssid: z.string().or(z.number()).optional().nullable(),
    vlan: z.string(),
    switchport: z.string().nullable().optional(),
    usage: z
      .object({
        sent: z.number(),
        recv: z.number(),
        total: z.number(),
      })
      .optional(), // probably isn't optional but legacy tests don't exepct it to exist
    status: ClientStatusSchema.nullable().optional(),
    notes: z.string().nullable(),
    groupPolicy8021x: z.string().nullable(),
    adaptivePolicyGroup: z.string().nullable(),
    smInstalled: z.boolean().optional(),
    nodeId: z.string().nullable(),
    connectedBy: z.union([z.number(), z.string()]).nullable().optional(), // probably isn't optional but legacy tests don't exepct it to exist
    userSrc: z.string().nullable(),
    capabilities: z.string().nullable(),
    clientFeatures: z.string().nullable(),
    products: z.string(),
    wiredGroupNum: z.string().nullable(),
    wirelessGroupNums: z.string().nullable(),
    ssidMasks: z.string().nullable(),
    clientVpnDetails: z
      .object({
        user: z.string(),
        connections: z.record(z.string(), ClientVpnRecord),
      })
      .nullable(),
    lldp: ClientLLdpSchema.nullable().optional(),
    blockedMessage: z.string().nullable().optional(),
    physicalPorts: z.array(z.string()),
    portType: z.string().nullable(),
    networkId: z.string(),
    hashedIp4: z.string(),
    onboardingScore: z.number().nullable(),
    performanceScore: z.number().nullable(),
  })
  .describe("ClientSchema");

export const ClientsSchema = z.array(ClientSchema).describe("ClientSchema");
export type Clients = z.infer<typeof ClientsSchema>;
export type ClientType = z.infer<typeof ClientSchema>;

interface ClientsRequest {
  networkId?: string;
  timespan?: number;
  perPage?: number;
  recentDeviceConnections?: ("wireless" | "wired")[];
}

const buildQueryUrl = ({ networkId }: ClientsRequest) => {
  return `/api/v1/networks/${networkId}/clients`;
};

const fetchClientsData = ({ networkId, timespan, perPage, ...rest }: ClientsRequest) => {
  return request(ClientsSchema, "GET", buildQueryUrl({ networkId }), {
    queryParams: { getInternalData: true, timespan, perPage: perPage ?? 1000, ...rest },
  });
};

export const useClients = createQuery<ClientsRequest, Clients>({
  baseQueryKey: buildQueryUrl({ networkId: "{network_id}" }),
  queryFn: (variables) => fetchClientsData(variables),
  requiredVariables: ["networkId"],
});

export const useClientByIp = (networkId?: string, targetIp?: string) => {
  return useClients(
    { networkId },
    {
      select: (data) => {
        if (data.length === 0) {
          return false;
        }
        targetIp = targetIp || getIpAddressSync();
        return data.find((client) => client?.ip === targetIp);
      },
    },
  );
};
