import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";

type Props = {
  onStartPress: () => void;
};

const CableTestStart = memo(function CableTestStart(props: Props) {
  const { onStartPress } = props;

  return (
    <View>
      <MkiText screenStyles={styles.infoText}>{I18n.t("CABLE_TEST.DESCRIPTION")}</MkiText>
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="secondary"
          onPress={onStartPress}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("START")}
        </RoundedButton>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.extraLarge,
  },
  infoText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    textAlign: "left",
  },
});

export default CableTestStart;
