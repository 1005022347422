import { I18n } from "@meraki/core/i18n";
import { useVlans, Vlan } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { MAX_SSIDS } from "~/constants/MkiConstants";
import NextStepsMessage from "~/go/components/NextStepsMessage";
import WiredNetworkRow from "~/go/components/WiredNetworkRow";
import WirelessNetworkRow from "~/go/components/WirelessNetworkRow";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { pickExportedFile, processFile } from "~/lib/ImportTemplateUtils";
import { getSSIDtoAdd, isUnconfiguredSSID } from "~/lib/SSIDUtils";
import {
  clientCountsBySSIDNumber,
  hasGRDevices,
  hasGXDevices,
  networkTypesSelector,
  slimSsidsSelector,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";
import { AddButton, ExportButton, HeaderButtonGroup } from "~/shared/navigation/Buttons";
import { SSID } from "~/shared/types/Models";
import { NetworkTypesWithId } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  networkTypes: NetworkTypesWithId;
  clientCountsBySSIDNumber: {
    [key: number]: number;
  };
};

export const NetworksListScreen = ({ clientCountsBySSIDNumber }: ReduxProps) => {
  const ssids: SSID[] = useAppSelector(slimSsidsSelector);
  const networkId = useCurrentNetworkId();
  const hasAnyGRDevice = useAppSelector(hasGRDevices);
  const hasAnyGXDevice = useAppSelector(hasGXDevices);
  const hasAlphaFeatures = useHasAlphaFeatures();

  const {
    data: wiredNetworks,
    isFetching,
    refetch: refetchVlans,
  } = useVlans({
    networkId,
  });

  const [reqPending, setReqPending] = useState(false);

  const actions = useActions();
  const navigation = useNavigation();

  const showCreateSSID = useCallback(() => {
    const ssidToAdd = getSSIDtoAdd(ssids);
    if (ssidToAdd < 0) {
      showAlert(I18n.t("MAX_SSIDS_ERROR.TITLE"), I18n.t("MAX_SSIDS_ERROR.MESSAGE"));
      return;
    }
    navigation.navigate("CreateSSID");
  }, [navigation, ssids]);

  const showConfigureVLAN = useCallback(() => navigation.navigate("ConfigureVlan"), [navigation]);
  const showExportNetwork = useCallback(() => navigation.navigate("ExportTemplate"), [navigation]);
  const showImportNetwork = useCallback(async () => {
    const pickedFile = await pickExportedFile();
    if (!pickedFile) {
      showAlert(I18n.t("ERROR"), I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.FILE_READ_ERROR"));
      return;
    }
    const importedNetworks = processFile(pickedFile);
    if (!importedNetworks) {
      showAlert(I18n.t("ERROR"), I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.PARSE_ERROR"));
      return;
    } else {
      navigation.navigate("ImportTemplate", { importedNetworks });
    }
  }, [navigation]);

  const showCreateScreen = useCallback(() => {
    if (hasAnyGRDevice && hasAnyGXDevice) {
      if (hasAlphaFeatures) {
        showActionSheet(
          [
            I18n.t("NETWORK_CREATION.WIRELESS"),
            I18n.t("NETWORK_CREATION.WIRED"),
            I18n.t("NETWORK_CREATION.IMPORT"),
          ],
          (id: number) => {
            switch (id) {
              case 0: {
                showCreateSSID();
                break;
              }
              case 1: {
                showConfigureVLAN();
                break;
              }
              case 2: {
                showImportNetwork();
                break;
              }
            }
          },
          { title: I18n.t("NETWORK_CREATION.CHOOSE") },
        );
      } else {
        showActionSheet(
          [I18n.t("NETWORK_CREATION.WIRELESS"), I18n.t("NETWORK_CREATION.WIRED")],
          (id: number) => {
            switch (id) {
              case 0: {
                showCreateSSID();
                break;
              }
              case 1: {
                showConfigureVLAN();
                break;
              }
            }
          },
          { title: I18n.t("NETWORK_CREATION.CHOOSE") },
        );
      }
    } else if (hasAnyGXDevice) {
      showConfigureVLAN();
    } else {
      showCreateSSID();
    }
  }, [
    hasAnyGRDevice,
    hasAnyGXDevice,
    hasAlphaFeatures,
    showConfigureVLAN,
    showCreateSSID,
    showImportNetwork,
  ]);

  useLayoutEffect(() => {
    if (hasAnyGRDevice || hasAnyGXDevice) {
      navigation.setOptions({
        headerRight: () => (
          <HeaderButtonGroup>
            {hasAlphaFeatures && <ExportButton onPress={showExportNetwork} />}
            <AddButton onPress={showCreateScreen} />
          </HeaderButtonGroup>
        ),
      });
    }
  }, [
    hasAlphaFeatures,
    hasAnyGRDevice,
    hasAnyGXDevice,
    navigation,
    showCreateScreen,
    showExportNetwork,
  ]);

  const getData = useCallback(
    async (isRefresh = false) => {
      try {
        setReqPending(true);
        await actions.getSsids(networkId);
        await actions.getSSIDSchedulesForCurrentNetwork();
        await actions.fetchIpsecVPNSettings();
        await actions.fetchAllSiteToSiteVPNSettings();

        if (isRefresh) {
          refetchVlans();
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setReqPending(false);
      }
    },
    [actions, networkId, refetchVlans],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const wirelessNetworks = ssids
    .slice(0, MAX_SSIDS)
    .filter((ssid) => !isUnconfiguredSSID(ssid))
    .sort((ssidA, ssidB) => {
      if (ssidA.enabled && !ssidB.enabled) {
        return -1;
      }
      if (!ssidA.enabled && ssidB.enabled) {
        return 1;
      }
      return 0;
    });

  let combinedNetworks: (SSID | Vlan)[] = [...wirelessNetworks];
  if (hasAnyGXDevice && wiredNetworks) {
    combinedNetworks = combinedNetworks.concat(wiredNetworks);
  }

  if (combinedNetworks.length === 0 && !reqPending) {
    if (hasAnyGRDevice || hasAnyGXDevice) {
      // set network badge
      actions.setBottomBarTab("NetworksStack", "!", { backgroundColor: MkiColors.badStatus });

      return (
        <NextStepsMessage
          message={I18n.t("SSID_EMPTY_STATE.MESSAGE")}
          onPress={showCreateScreen}
          buttonText={I18n.t("SSID_EMPTY_STATE.BUTTON")}
        />
      );
    } else {
      return <NextStepsMessage message={I18n.t("SSID_EMPTY_STATE_NO_GR.MESSAGE")} />;
    }
  }

  if (combinedNetworks.length > 0 && !reqPending) {
    // remove network badge
    actions.setBottomBarTab("NetworksStack", undefined);
  }

  return (
    <MkiTable
      data={combinedNetworks}
      keyExtractor={(item: Vlan | SSID) =>
        "enabled" in item ? `wireless${item.number}` : `wired${item.id}`
      }
      renderRow={(rowData: Vlan | SSID, idx: number) =>
        "enabled" in rowData ? (
          <WirelessNetworkRow
            ssid={rowData}
            idx={idx}
            clientCount={clientCountsBySSIDNumber[rowData.number]}
          />
        ) : (
          <WiredNetworkRow vlan={rowData} />
        )
      }
      onRefresh={getData}
      refreshing={reqPending || isFetching}
      testID="NETWORKS_LIST.TABLE"
    />
  );
};

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkTypes: networkTypesSelector(state),
    clientCountsBySSIDNumber: clientCountsBySSIDNumber(state),
  };
}

export default compose<any>(connect(mapStateToProps))(NetworksListScreen);
