import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Positive({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} testID={testID}>
      <Path
        d="M21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12Z"
        fill={theme.color.positive.icon.base}
      />
      <Path
        d="M16.457 8.79279C16.8476 9.18326 16.8477 9.81643 16.4572 10.207L11.2088 15.4569C10.8184 15.8474 10.1854 15.8476 9.79475 15.4573L7.54316 13.2074C7.15249 12.817 7.15225 12.1838 7.54262 11.7932C7.933 11.4025 8.56617 11.4022 8.95684 11.7926L10.5012 13.3358L15.0428 8.79299C15.4333 8.40241 16.0664 8.40232 16.457 8.79279Z"
        fill={theme.color.positive.iconIn.base}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
