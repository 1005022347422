import { MS_IN_A_SECOND } from "@meraki/shared/redux";
import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

const UMBRELLA_BASE_URL = "https://api.umbrella.com/reports/v2";
const TOP_IPS_LIMIT = 5000;

interface UmbrellaTopIpsRequest {
  timespan: number;
  accessToken?: string;
}

export const UmbrellaTopIpsSchema = z
  .object({
    data: z.array(
      z.object({
        count: z.number(),
        ip: z.string(),
      }),
    ),
    meta: z.any(),
  })
  .describe("UmbrellaTopIpsSchema");

export type UmbrellaTopIpsResponse = z.infer<typeof UmbrellaTopIpsSchema>;

const buildUrl = () => {
  return `/top-ips/internal`;
};

const fetchUmbrellaStatus = ({ accessToken, timespan }: UmbrellaTopIpsRequest) => {
  const to = Date.now();
  const from = to - timespan * MS_IN_A_SECOND;
  return request(UmbrellaTopIpsSchema, "GET", buildUrl(), {
    baseUrl: UMBRELLA_BASE_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
    queryParams: { from, to, limit: TOP_IPS_LIMIT, verdict: "blocked" },
    skipCSRF: true,
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useUmbrellaTopIps = createQuery({
  baseQueryKey: buildUrl(),
  queryFn: fetchUmbrellaStatus,
  requiredVariables: ["accessToken", "timespan"],
});
