import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface DeviceChangeHistoryRequest {
  organizationId?: string;
  timespan?: number;
  serials?: (string | undefined)[];
  networkIds?: (string | undefined)[];
}

const DeviceChange = z.object({
  name: z.string(),
  value: z.string(),
});

const DeviceChangeHistorySchema = z.object({
  category: z.string(),
  ts: z.string(),
  details: z.object({
    new: z.array(DeviceChange),
    old: z.array(DeviceChange),
  }),
  device: z.object({
    serial: z.string(),
  }),
  network: z.object({
    id: z.string(),
  }),
});

export const DeviceChangeHistorySchemas = z.array(DeviceChangeHistorySchema);
export type DeviceChangeHistory = z.infer<typeof DeviceChangeHistorySchema>;
export type DeviceChangeHistories = z.infer<typeof DeviceChangeHistorySchemas>;

const buildUrl = ({ organizationId }: DeviceChangeHistoryRequest) => {
  return `/api/v1/organizations/${organizationId}/devices/availabilities/changeHistory`;
};

const fetchDeviceChangeHistory = ({
  organizationId,
  ...queryParams
}: DeviceChangeHistoryRequest) => {
  return request(DeviceChangeHistorySchemas, "GET", buildUrl({ organizationId }), { queryParams });
};

export const useDeviceChangeHistory = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: DeviceChangeHistoryRequest) => fetchDeviceChangeHistory(parameters),
  requiredVariables: ["organizationId"],
});
