import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  color: string;
  size?: number;
};

export const ToolsIcon = (props: Props) => {
  const { color, size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.5218 6.65627C21.0138 7.80497 21.1342 9.07877 20.8663 10.2993C20.5983 11.5199 19.9554 12.6261 19.0274 13.4631C18.0995 14.3001 16.9331 14.8259 15.6915 14.967C14.4498 15.1081 13.1952 14.8574 12.1031 14.25V14.25L6.84372 20.3438C6.42103 20.7665 5.84774 21.0039 5.24997 21.0039C4.65219 21.0039 4.0789 20.7665 3.65622 20.3438C3.23353 19.9211 2.99606 19.3478 2.99606 18.75C2.99606 18.1522 3.23353 17.579 3.65622 17.1563L9.74997 11.8969C9.14255 10.8048 8.89185 9.55014 9.03296 8.30851C9.17407 7.06688 9.69992 5.90046 10.5369 4.97254C11.3739 4.04463 12.4801 3.40168 13.7006 3.13371C14.9212 2.86575 16.195 2.98618 17.3437 3.47814L13.4062 7.40627L13.9406 10.0594L16.5937 10.5938L20.5218 6.65627Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ToolsIcon;
