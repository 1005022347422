import { DeepPartial } from "redux";
import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const buildUrl = ({ serial }: ManagementInterfaceRequest) => {
  return `/api/v1/devices/${serial}/managementInterface`;
};

interface ManagementInterfaceRequest {
  serial: string;
}

const DDNSHostnamesSchema = z.object({
  activeDdnsHostname: z.string(),
  ddnsHostnameWan1: z.string(),
  ddnsHostnameWan2: z.string(),
});

export const WANSchema = z.object({
  wanEnabled: z.string().optional(),
  usingStaticIp: z.boolean(),
  vlan: z.number().nullable(),
  staticIp: z.string().optional(),
  staticSubnetMask: z.string().optional(),
  staticGatewayIp: z.string().optional(),
  staticDns: z.array(z.string()).optional(),
});

export const ManagementInterfaceSchema = z.object({
  ddnsHostnames: DDNSHostnamesSchema.optional(),
  wan1: WANSchema.optional(),
  wan2: WANSchema.optional(),
});

export type ManagementInterface = z.infer<typeof ManagementInterfaceSchema>;
interface SetVlanIDRequest {
  serial: string;
  body: DeepPartial<ManagementInterface>;
}

const fetchManagementInterface = ({ serial }: ManagementInterfaceRequest) => {
  return request(ManagementInterfaceSchema, "GET", buildUrl({ serial }));
};

export const putVlanID = ({ body, serial }: SetVlanIDRequest) => {
  return request(ManagementInterfaceSchema, "PUT", buildUrl({ serial }), {
    body: JSON.stringify(body),
  });
};

export const useManagementInterface = createQuery({
  baseQueryKey: buildUrl({ serial: `{serial}` }),
  queryFn: (parameters: ManagementInterfaceRequest) => fetchManagementInterface(parameters),
});

export const useMutateManagementInterface = createMutation<SetVlanIDRequest, ManagementInterface>({
  baseMutationKey: buildUrl({ serial: `{serial}` }),
  mutationFn: (variables) => putVlanID(variables),
});
