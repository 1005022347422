import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { isEqual } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import EditableSsidNameHeader from "~/go/components/EditableSsidNameHeader";
import SectionListHeader from "~/go/components/SectionListHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import OutageScheduleRow from "~/go/rows/OutageScheduleRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showActionSheet, showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { alwaysAvailable, available9To5, available9To5Weekdays } from "~/lib/SSIDScheduleTemplates";
import { formatScheduleToOutagesInSeconds, formatSSIDSchedule } from "~/lib/SSIDUtils";
import { currentNetworkState, slimSsidsSelector, ssidSchedulesForSsidSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { ScheduleRow, SSID, SsidSchedule } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const SCHEDULE_TEMPLATES = {
  [I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.ALWAYS_AVAILABLE")]:
    alwaysAvailable,
  [I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.NINE_TO_FIVE_DAILY")]:
    available9To5,
  [I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.NINE_TO_FIVE_WEEKDAYS")]:
    available9To5Weekdays,
  [I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.CUSTOM")]: alwaysAvailable,
};

const TIME_PICKER_HEIGHT = 48;
const caretIconColor = MkiColors.secondaryTextColor;

type SSIDSchedule = {
  enabled: boolean;
  ranges: {
    startDay: string;
    endDay: string;
    startTime: string;
    endTime: string;
  };
  rangesInSeconds: {
    start: number;
    end: number;
  }[];
};

type ReduxProps = {
  networkId: string;
  ssid: SSID;
  ssidSchedule: SSIDSchedule;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDSchedules"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

interface SSIDSchedulesScreenState {
  scheduleEnabled: boolean;
  template: string;
  schedule: SsidSchedule | Record<string, never>;
  originalSchedule: {
    scheduleEnabled: boolean;
    schedule: SsidSchedule | Record<string, never>;
  };
}

export class SSIDSchedulesScreen extends PureComponent<Props, SSIDSchedulesScreenState> {
  constructor(props: Props) {
    super(props);
    const { ssidSchedule } = props;
    let currScheduleInfo = {
      scheduleEnabled: false,
      schedule: available9To5Weekdays,
    };
    let template = I18n.t(
      "SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.NINE_TO_FIVE_WEEKDAYS",
    );
    if (ssidSchedule) {
      currScheduleInfo = {
        scheduleEnabled: ssidSchedule.enabled || false,
        schedule: formatSSIDSchedule(ssidSchedule),
      };
      template = I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.CUSTOM");
    }
    this.state = {
      template,
      ...currScheduleInfo,
      originalSchedule: currScheduleInfo,
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const saveEnabled = this.isScheduleChanged();

    navigation.setOptions({
      headerLeft: () => {
        if (saveEnabled) {
          return <CancelButton onPress={this.handleClose} />;
        } else {
          return <CloseButton onPress={this.handleClose} />;
        }
      },
      headerRight: () => <SaveButton onPress={this.save} disabled={!saveEnabled} />,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    const oldScheduleInfo = prevProps.ssidSchedule;
    const { ssidSchedule } = this.props;

    if (!isEqual(oldScheduleInfo, ssidSchedule)) {
      this.setState({
        schedule: formatSSIDSchedule(ssidSchedule),
        scheduleEnabled: ssidSchedule.enabled || false,
      });
    }
    this.setNavOptions();
  }

  loadData = async () => {
    const { actions, ssidNumber, setReqPending } = this.props;
    setReqPending(true);
    try {
      await actions.getScheduleForSSID(ssidNumber);
    } catch (e) {
      showAlert(I18n.t("ERROR"), e);
    }
    setReqPending(false);
    return Promise.resolve();
  };

  onRefresh = () => this.loadData();

  isScheduleChanged = () => {
    const { originalSchedule, schedule, scheduleEnabled } = this.state;
    const { schedule: oldSchedule, scheduleEnabled: oldScheduleEnabled } = originalSchedule;

    const isEnabledSwitched = !isEqual(oldScheduleEnabled, scheduleEnabled);
    const isScheduleChanged = !isEqual(oldSchedule, schedule);

    return isEnabledSwitched || isScheduleChanged;
  };

  save = () => {
    const { setReqPending } = this.props;
    setReqPending(true);
    this.saveSchedule();
  };

  saveSchedule = () => {
    const { actions, ssidNumber, setReqPending, navigation } = this.props;
    const { schedule, scheduleEnabled } = this.state;
    const rangesInSeconds = formatScheduleToOutagesInSeconds(schedule);

    const updatedSchedule = {
      enabled: scheduleEnabled,
      rangesInSeconds,
    };
    actions
      .updateScheduleForSSID(ssidNumber, updatedSchedule)
      .then(navigation.goBack)
      .catch((error: unknown) => showAlert(I18n.t("ERROR"), error))
      .finally(() => setReqPending(false));
  };

  handleClose = () => {
    const { navigation } = this.props;
    this.isScheduleChanged() ? showSaveWarning(this.save, navigation.goBack) : navigation.goBack();
  };

  renderEnabledSwitch = () => {
    const { scheduleEnabled } = this.state;
    return (
      <SwitchRow
        value={scheduleEnabled}
        onValueChange={this.saveToggle}
        testID={"ENABLE_SCHEDULE"}
        subtitle={I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_TOGGLE_SUBTITLE")}
      >
        {I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULE_TOGGLE")}
      </SwitchRow>
    );
  };

  saveToggle = (scheduleEnabled: boolean) => {
    this.setState({ scheduleEnabled });
  };

  showOptions = () => {
    const actionSheetNames = Object.keys(SCHEDULE_TEMPLATES);
    showActionSheet(
      actionSheetNames,
      (id: number) => {
        this.setState({ template: actionSheetNames[id] });
        this.setState({ schedule: SCHEDULE_TEMPLATES[actionSheetNames[id]] });
      },
      { title: I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.CHOOSE_TEMPLATE") },
    );
  };

  onDayChange = (day: string, newDayInfo: Omit<ScheduleRow, "day">) => {
    const { schedule, template } = this.state;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const currentDay = schedule[day];
    const newSchedule = {
      ...schedule,
      [day]: {
        ...currentDay,
        ...newDayInfo,
      },
    };
    const newTemplate = isEqual(SCHEDULE_TEMPLATES[template], newSchedule)
      ? template
      : I18n.t("SSID_CONFIGURATION.SCHEDULED_SSIDS.SCHEDULES_TEMPLATES.CUSTOM");

    this.setState({ schedule: newSchedule, template: newTemplate });
  };

  renderScheduleRow = (rowData: ScheduleRow, _rowId: number) => {
    const { day, isActive, from, to } = rowData;
    return (
      <View style={styles.outageRowStyle}>
        <OutageScheduleRow
          onChange={(changes) => this.onDayChange(day, changes)}
          active={isActive}
          from={from}
          to={to}
        />
      </View>
    );
  };

  getData = () => {
    const { schedule } = this.state;
    const allData: Partial<Record<keyof SsidSchedule, [ScheduleRow]>> = {};
    const days = Object.keys(schedule) as (keyof SsidSchedule)[];
    for (const day of days) {
      const daySchedule = schedule[day];
      if (daySchedule) {
        const { isActive, from, to } = daySchedule;
        allData[day] = [{ day, isActive, from, to }];
      }
    }
    return allData;
  };

  renderSchedule = () => {
    const { template, scheduleEnabled } = this.state;
    if (scheduleEnabled) {
      return (
        <View>
          <TouchableOpacity style={styles.container} onPress={() => this.showOptions()}>
            <View style={styles.dataContainer}>
              <MkiText>{template}</MkiText>
            </View>
            <MerakiIcon
              name="caret-down"
              size="xxs"
              color={caretIconColor}
              containerStyle={styles.rightIcon}
            />
          </TouchableOpacity>
          <MkiTable<ScheduleRow>
            data={this.getData()}
            keyExtractor={(item) => item.day}
            renderRow={this.renderScheduleRow}
            renderSectionHeader={({ section }) => {
              return <SectionListHeader heading={section.key} withHorizontalMargin />;
            }}
          />
        </View>
      );
    }
    return null;
  };

  render() {
    const { ssid } = this.props;
    return (
      <RefreshControlScrollView onRefresh={this.onRefresh}>
        <KeyboardAwareScrollView>
          <EditableSsidNameHeader title={ssid.name} entity="network" enabled={ssid.enabled} />
          {this.renderEnabledSwitch()}
          {this.renderSchedule()}
        </KeyboardAwareScrollView>
      </RefreshControlScrollView>
    );
  }
}

const styles = StyleSheet.create({
  dataContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightIcon: {
    marginRight: SPACING.default,
  },
  outageRowStyle: {
    marginBottom: SPACING.large,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: TIME_PICKER_HEIGHT,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopColor: MkiColors.borderColor,
    borderTopWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.default,
    marginTop: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SSIDSchedules"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDSchedulesScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsSelector(state)[props.ssidNumber],
    ssidSchedule: ssidSchedulesForSsidSelector(state, props.ssidNumber),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(SSIDSchedulesScreen);
