import { setTimezone } from "@meraki/core/date";
import { useEffect } from "react";

import { getCurrentNetwork } from "~/selectors";

import useAppSelector from "./redux/useAppSelector";

// This hook is used to sync the timezone from the currently selected network to our date library. The date library has no idea about redux so this utility is used to make sure it is kept up to date.
export function useTimeZoneSync() {
  const currentNetwork = useAppSelector(getCurrentNetwork);

  useEffect(() => {
    setTimezone(currentNetwork?.timeZone);
  }, [currentNetwork]);
}
