export type Entry<T> = [string, T];

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const [key, value] = [0, 1] as const;

type ComparableEntryValue = string | number;

export const getMaxValueEntry = (entries: Entry<ComparableEntryValue>[]) => {
  return entries.reduce((prev: Entry<ComparableEntryValue>, cur: Entry<ComparableEntryValue>) => {
    if ((prev || cur) === undefined) {
      throw new Error("Unable to determine max value from entries");
    }
    if (prev === undefined) {
      return cur;
    }
    if (cur === undefined) {
      return prev;
    }

    return prev[value] > cur[value] ? prev : cur;
  });
};

/**
 * Function to get the key of the max value entry, a max of 0 will return undefined
 * @param entries a [key, ComparableEntryValue] pair
 */
export const getMaxValueEntryKey = (entries: Entry<ComparableEntryValue>[]) => {
  const maxValueEntry = getMaxValueEntry(entries);
  if (maxValueEntry[value] === 0) {
    return undefined;
  }

  return maxValueEntry[key];
};
