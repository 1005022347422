import { Device } from "@meraki/shared/api";
import { isEmpty } from "lodash";

const partialStringMatch = (data: string | null | undefined, searchText: string) =>
  data?.toString().toLowerCase().includes(searchText.toLowerCase());

export function getDeviceSearchResults<T extends Pick<Device, "mac" | "name" | "serial">>(
  devices?: T[],
  searchText?: string,
) {
  if (isEmpty(searchText) || !searchText || !devices || isEmpty(devices)) {
    return devices;
  }

  return devices.filter((device) => {
    const { name, serial, mac } = device;
    return (
      partialStringMatch(name, searchText) ||
      partialStringMatch(serial, searchText) ||
      partialStringMatch(mac, searchText)
    );
  });
}
