import { I18n } from "@meraki/core/i18n";
import { Dimensions } from "react-native";
import { useFilePicker } from "use-file-picker";

import { WINDOW } from "~/constants/MkiConstants";
import { showAlert } from "~/lib/AlertUtils";
import ImageThumbnail from "~/shared/components/ImageThumbnail";
import useActions from "~/shared/hooks/redux/useActions";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

interface Props {
  ssidNumber: number;
  logoURL: string;
  setReqPending: (value: boolean) => void;
  setStagedLogoURL: (imagePath: string) => void;
}

const SplashLogoRow = ({ ssidNumber, logoURL, setReqPending, setStagedLogoURL }: Props) => {
  const actions = useActions();
  const { cancelablePromise } = useCancelablePromise();

  const { openFilePicker: onPress } = useFilePicker({
    multiple: false,
    readAs: "DataURL",
    accept: "image/*",
    onFilesRejected: ({ errors }) => {
      if (errors.length !== 0) {
        showAlert(I18n.t("ERROR"), I18n.t("SPLASH_CONFIG.LOGO_UPLOAD_ERROR_MESSAGE"));
      }
    },
    onFilesSuccessfullySelected: async ({ plainFiles, filesContent }) => {
      try {
        setReqPending(true);

        const selectedFile = plainFiles[0];
        const fileContent = filesContent[0]?.content;

        const extension = /[^.]+$/.exec(selectedFile.name)?.[0] ?? "png";
        setStagedLogoURL(fileContent);

        if (fileContent != null) {
          await cancelablePromise(
            actions.updateSSIDSplashSettings(ssidNumber, {
              splashLogo: {
                image: {
                  contents: fileContent.replace(/.*base64,/, ""),
                  format: extension,
                },
              },
            }),
          );
        }
      } catch (error) {
        showAlert(I18n.t("ERROR"), I18n.t("SPLASH_CONFIG.LOGO_UPLOAD_ERROR_MESSAGE"));
      } finally {
        setReqPending(false);
      }
    },
  });

  const windowWidth = Dimensions.get(WINDOW).width;
  return (
    <DisclosureRow
      onPress={onPress}
      inlineView={
        <ImageThumbnail
          source={{
            uri: logoURL,
            height: windowWidth / 8,
            width: windowWidth / 4,
          }}
          show={!!logoURL}
          onClose={() => setStagedLogoURL("")}
        />
      }
      testID="SPLASH_CONFIG.LOGO"
    >
      {I18n.t("SPLASH_CONFIG.CUSTOMIZE.CUSTOM_LOGO.ROW_TITLE")}
    </DisclosureRow>
  );
};

export default SplashLogoRow;
