import { FLOORPLANS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

import { indexObjectsByKey } from "~/lib/objectHelper";

export const initialState: Record<string, any> | any = {};

export default function floorPlans(state = initialState, action: any) {
  switch (action.type) {
    case FLOORPLANS_SUCCESS: {
      const networkId = action.meta.networkId;
      const indexedFloorPlans = indexObjectsByKey(action.response, "floorPlanId");

      return networkId
        ? {
            ...state,
            [networkId]: indexedFloorPlans,
          }
        : state;
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
