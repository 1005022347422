import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

const secureConnectOverridesSchema = z.object({
  allowed_vlans: z.string(),
  is_configurable_stack_port: z.boolean(),
  is_trunk: z.boolean(),
  native_vid: z.number().nullable(),
  vid: z.number().nullable(),
  voice_vid: z.number().nullable(),
});

const lldpCdp = z.object({
  device: z.string(),
  opts: z.array(z.array(z.string().or(z.number())).length(2)),
  port: z.string(),
});

const LldpInfo = z.object({
  cdp: lldpCdp.nullable(),
  device_description: z.string(),
  device_name: z.string(),
  lldp: lldpCdp.nullable(),
  mac: z.string(),
  meraki_port_identifier: z.string(),
  updated_at: z.string(),
});

const LinkNegotiationOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const SwitchPortJsonSchema = z
  .object({
    access_policy: z.string().nullable(),
    access_policy_number: z.number().nullable(),
    adaptive_policy_group_id: z.nullable(z.string()),
    all_child_overrides: z.record(z.unknown()).optional(),
    allowed_vlans: z.string().optional(),
    can_use_polaris_access_mac_policies: z.boolean().optional(),
    can_use_trunk_mac_policies: z.boolean(),
    created_at: z.string(),
    curr_status: z.number(),
    dai_trusted: z.boolean(),
    dedicated_stack_port_num: z.nullable(z.number()).optional(),
    dual_media_preference: z.string().optional(),
    enabled: z.boolean(),
    expose_stp_topology_count: z.boolean(),
    expose_trusted: z.boolean(),
    first_status: z.array(z.number()).length(5),
    has_isolation_display: z.boolean(),
    has_loopguard: z.boolean(),
    has_poe: z.boolean(),
    has_storm_control: z.boolean(),
    has_stp: z.boolean(),
    has_udld: z.boolean(),
    id: z.string(),
    is_configurable_stack_port: z.boolean(),
    is_dedicated_stack_port: z.boolean(),
    is_dual_media: z.boolean(),
    is_isolated: z.boolean(),
    is_mirror_dest_port: z.boolean(),
    is_mirror_src_port: z.boolean(),
    is_polaris_switch: z.boolean(),
    is_read_only: z.boolean(),
    is_stackport: z.boolean(),
    is_trunk: z.boolean(),
    is_uplink: z.boolean(),
    link_negotiation: z.string(),
    link_negotiation_options: z.array(LinkNegotiationOptionSchema),
    lldp_infos: z.record(LldpInfo).optional(),
    local_overrides: z.nullable(z.unknown()),
    mac_whitelist: z.nullable(z.unknown()),
    module_type: z.nullable(z.string()),
    name: z.nullable(z.string()),
    native_vid: z.nullable(z.number()),
    node_profile_id: z.string(),
    node_profile_id_of_parent_node: z.string(),
    num: z.array(z.number()),
    peer_sgt_capable: z.nullable(z.unknown()),
    port_schedule_id: z.string(),
    profile: z.string(),
    rx_bcast_pkts: z.number(),
    rx_bytes: z.number(),
    rx_crc_align_err: z.number(),
    rx_drops: z.number(),
    rx_frags: z.number(),
    rx_jabbers: z.number(),
    rx_mcast_pkts: z.number(),
    rx_oversize_pkts: z.number(),
    rx_pkts: z.number(),
    schedule: z.nullable(z.unknown()),
    scheduled_outage: z.nullable(z.unknown()),
    secureConnectStatus: z.number(),
    secure_connect: z
      .object({
        auth_status: z.string(),
        enabled: z.boolean(),
        is_connected_to_compatible_device: z.boolean(),
        overrides: z.nullable(secureConnectOverridesSchema),
      })
      .optional(),
    stack: z.string(),
    stack_id: z.nullable(z.string()),
    static_switchport_profile_enabled: z.boolean(),
    static_switchport_profile_id: z.string(),
    static_switchport_profile_name: z.nullable(z.string()),
    status: z.array(z.array(z.number()).length(5)),
    sticky_whitelist_limit: z.nullable(z.string()),
    storm_control: z.boolean(),
    stp_flags: z.number(),
    stp_guard: z.string(),
    supports_switchport_profiles: z.boolean(),
    switch: z.string(),
    switch_id: z.string(),
    switch_model: z.string(),
    tags: z.nullable(z.array(z.string()).or(z.string())),
    tx_bcast_pkts: z.number(),
    tx_bytes: z.number(),
    tx_collisions: z.number(),
    tx_drops: z.number(),
    tx_mcast_pkts: z.number(),
    tx_pkts: z.number(),
    type: z.string(),
    udld_mode: z.string(),
    updated_at: z.string(),
    usageStatus: z.number(),
    use_poe: z.boolean(),
    use_sticky_whitelist: z.boolean(),
    use_stp: z.boolean(),
    use_whitelist: z.boolean(),
    vid: z.number(),
    voice_vid: z.nullable(z.number()),
  })
  .describe("SwitchPortJsonSchema");

export const SwitchPortsJsonSchema = z
  .record(SwitchPortJsonSchema)
  .describe("SwitchPortJsonSchema");

export type SwitchPortJson = z.infer<typeof SwitchPortJsonSchema>;
export type SwitchPortJsonMap = z.infer<typeof SwitchPortsJsonSchema>;

interface PortJsonRequest {
  eid: string;
}

interface PortJsonParams extends PortJsonRequest {
  nodeId: string;
  timespan: number;
}

const buildUrl = ({ eid }: PortJsonRequest) => {
  return `/n/${eid}/manage/nodes/ports_json`;
};

const fetchSwitchPortJson = ({
  eid,
  nodeId,
  timespan,
}: PortJsonParams): Promise<SwitchPortJsonMap> => {
  const t1 = Date.now();
  const t0 = t1 - timespan;
  const queryParams = {
    node_id: nodeId,
    t0,
    t1,
    aggregates: false,
  };
  return request(SwitchPortsJsonSchema, "GET", buildUrl({ eid }), { queryParams });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/get-device-switch-ports/
 */
export const useSwitchPortJson = createQuery<PortJsonParams, SwitchPortJsonMap>({
  baseQueryKey: buildUrl({ eid: `{eid}` }),
  queryFn: (variables) => fetchSwitchPortJson(variables),
});
