import LottieView from "lottie-react-native";
import { PureComponent } from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { withStateChange, WithStateChangeComponent } from "~/hocs/PendingUtils";
import getScreenFormattedHeight from "~/lib/LottieUtils";

const originalAnimation = require("~/images/onboarding/connect.json");

export type ConnectingAnimationProps = ViewProps;

class ConnectingAnimation extends PureComponent<
  ConnectingAnimationProps & WithStateChangeComponent
> {
  animation = originalAnimation;

  refreshAnimation(animator: any) {
    if (animator) {
      this.animation = animator;
      this.animation.play();
    }
  }

  render() {
    return (
      <View {...this.props}>
        <LottieView
          style={styles.lottie}
          source={originalAnimation}
          ref={(animator) => {
            this.refreshAnimation(animator);
          }}
          loop
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  lottie: {
    height: getScreenFormattedHeight(originalAnimation),
    width: "100%",
    alignSelf: "center",
  },
});

export default withStateChange<ConnectingAnimationProps>(ConnectingAnimation);
