import { Component, ReactNode } from "react";
import { Animated, Easing, StyleProp, StyleSheet, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { MS_IN_A_SECOND } from "~/constants/MkiConstants";

export interface SlideUpContainerProps {
  children: ReactNode;
  screenStyles: StyleProp<ViewStyle>;
  shouldSlideDown: boolean;
  afterComplete?: () => void;
  testID?: string;
}

export interface SlideUpContainerState {
  animation: Animated.Value;
}

const animationValue = 100;
const animationDuration = 0.4 * MS_IN_A_SECOND;
const persistDuration = MS_IN_A_SECOND;

abstract class SlideUpContainer extends Component<SlideUpContainerProps, SlideUpContainerState> {
  static defaultProps = {
    shouldSlideDown: false,
  };

  constructor(props: SlideUpContainerProps) {
    super(props);

    this.state = { animation: new Animated.Value(animationValue) };
  }

  slideUpTiming = () => {
    const { animation } = this.state;

    return Animated.timing(animation, {
      toValue: 0,
      duration: animationDuration,
      useNativeDriver: true,
      easing: Easing.out(Easing.exp),
    });
  };

  slideDownTiming = () => {
    const { animation } = this.state;

    return Animated.timing(animation, {
      toValue: animationValue,
      duration: animationDuration,
      useNativeDriver: true,
      easing: Easing.exp,
    });
  };

  componentDidMount() {
    const { shouldSlideDown, afterComplete } = this.props;
    this.slideUpTiming().start(() =>
      shouldSlideDown
        ? setTimeout(() => this.slideDownTiming().start(afterComplete), persistDuration)
        : null,
    );
  }

  render() {
    const { testID, children, screenStyles } = this.props;
    const { animation } = this.state;

    const containerStyle = [
      styles.container,
      screenStyles,
      {
        transform: [{ translateY: animation }],
      },
    ];

    return (
      <Animated.View style={containerStyle} testID={testID}>
        {children}
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: MkiColors.whiteBackground,
  },
});

export default SlideUpContainer;
