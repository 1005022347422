import { DeviceAvailability } from "@meraki/shared/api";

import { WirelessOnboardingStatus } from "~/api/models/WirelessOnboardingStatusResponse";
import { ConfigStatus } from "~/constants/ConfigStatus";
import { ProductType } from "~/shared/types/Networks";
import { Firmware } from "~/shared/types/NodeGroups";
import { RadioSettings } from "~/shared/types/RadioSettingsTypes";
import Tags from "~/shared/types/Tags";

import { DeviceStatus, StatusType } from "../constants/Status";

export enum DeviceDetailsTab {
  usage = "Usage",
  health = "Health",
  details = "Details",
  logs = "Logs",
  ports = "Ports",
  tools = "Tools",
  stream = "Stream",
  clips = "Clips",
  sensorSummary = "Summary",
  sensorLogs = "Sensor logs",
  powerEventLogs = "Power Event Logs",
  uplink = " Uplink",
}

interface Images {
  id: string;
  thumbnail: string;
  medium: string;
  large: string;
}

// Prefer Device type from the /api/schemas directory

export default interface Device_DeprecatedType {
  address?: string;
  alertProfileIds?: string[];
  alerts?: DeviceAlert[];
  channels?: number[];
  config_status?: ConfigStatus;
  "config_new_at#": number;
  "config_fetch_at#": number;
  has_firmware_eco: boolean;
  has_tagged_firmware_eco: boolean;
  hashedIp4: string;
  hasNATRouter?: boolean;
  id: string;
  images: Images;
  ip?: string;
  is_gateway?: boolean;
  label?: string;
  lanIp?: string;
  lastReportedAt?: string;
  lat?: number;
  lng?: number;
  mac: string;
  model: string;
  name?: string;
  networkEid: string;
  networkId: string;
  notes?: string;
  serial: string;
  status?: StatusType;
  tags?: string | string[];
  wan1Ip?: string;
  wan2Ip?: string;
  supports_humidity?: boolean;
  supports_temperature?: boolean;
  supports_water_detection?: boolean;
  supports_door?: boolean;
  supports_power?: boolean;
  has_probe?: boolean;
  supports_co2?: boolean;
  supports_tvoc?: boolean;
  supports_pm25?: boolean;
  supports_ambient_noise?: boolean;
  supports_iaq_index?: boolean;
  supports_button_release?: boolean;
  radioSettings?: RadioSettings;
  lldp?: LLDPDevice;
  wirelessOnboardingStatus?: WirelessOnboardingStatus;
  lspConnected?: boolean;
  productType?: ProductType;
  floorPlanId?: string;
  firmware: string;
  statusColor?: string;
}

export enum DeviceAlertName {
  water_cable_disconnect = "water_cable_disconnect",
}

export enum ConnectionType {
  wired = "wired",
  wireless = "wireless",
  vpn = "vpn",
  none = "none",
}

export type AlertType = "danger" | "success" | "warning";

export interface DeviceAlert {
  admin_alert?: boolean;
  alert_type: AlertType;
  name?: DeviceAlertName;
  short_desc: string;
}

export interface DeviceWithNodeGroupInfo extends Device_DeprecatedType {
  firmwareDetails: Firmware;
  hasEco: boolean;
  imei?: number;
}

export type GenericDevice = Device_DeprecatedType | DeviceWithNodeGroupInfo;

export interface DevicesBySerial {
  [serial: string]: Device_DeprecatedType;
}
export interface DevicesByMac {
  [mac: string]: Device_DeprecatedType;
}

export interface DeviceHashedIpMap {
  [hashedIp4: string]: string;
}

export type DevicesByType = {
  [productType in ProductType]: Device_DeprecatedType[];
};

export type DeviceState = DevicesBySerial;

export enum IPConfiguration {
  dhcp = "DHCP",
  staticIp = "STATIC_IP",
}

export const StatusKeys = DeviceStatus;

export const DeviceStatusSummary = {
  ...DeviceStatus,
  total: "total",
} as const;

export type DeviceStatusSummaryType =
  (typeof DeviceStatusSummary)[keyof typeof DeviceStatusSummary];

export interface DeviceSummaryStatusesMap {
  [networkId: string]: Partial<DeviceStatusSummaryType>;
}

export interface LLDPDevice {
  deviceMac?: string;
  deviceId?: string;
  portId?: string;
  sourcePort?: string;
  systemName?: string;
}

export interface DeviceDetails {
  name?: string;
  address?: string;
  moveMapMarker?: boolean;
  notes?: string;
  tags?: Tags;
  lat?: number;
  lng?: number;
  photo?: any;
  images?: Images;
}

export type FilterCategory = "productType" | "status";

export type FilterPills = Pick<DeviceAvailability, FilterCategory>;

export const Filters = {
  online: "online",
  offline: "offline",
  dormant: "dormant",
  alerting: "alerting",
  appliance: "appliance",
  wireless: "wireless",
  switch: "switch",
  phone: "phone",
  camera: "camera",
  environmental: "environmental",
  sensor: "sensor",
  cellularGateway: "cellularGateway",
} as const;
export type Filter = (typeof Filters)[keyof typeof Filters];

export interface UsageData {
  data?: [number, number][];
  label: string | null;
}

export interface DeviceUsage {
  usage?: {
    data?: UsageData[];
  };
}
