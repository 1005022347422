import { createSelector } from "reselect";

import { VPN_KEY } from "~/constants/SearchKeys";
import { MerakiVpnPeer, Outage, Reachability, Usage, Vpn } from "~/enterprise/types/VpnTypes";
import { IpsecSettings } from "~/go/types/ClientVPN";
import { Modes, SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";
import { filterData } from "~/lib/SearchUtils";
import {
  currentNetworkState,
  getIpsecSettingsByNetworkId,
  getOrgNetworksState,
  getPeerStatuses,
  getSiteToSiteSettingsByNetworkId,
  getVpnOutageData,
  getVpns,
  getVpnUsage,
} from "~/selectors/getters";
import { getOrgNetworks } from "~/selectors/orgNetworks";
import { timespanNameSelector } from "~/selectors/preferences";
import { searchTextState } from "~/selectors/search";
import { ProductType } from "~/shared/types/Networks";
import { MkiSelector, ReduxSelector } from "~/shared/types/Redux";

export const getIsFetchingVpn: ReduxSelector<boolean> = (state) => state?.vpns.isFetching;

export const getNetworkHasVpns: ReduxSelector<boolean> = createSelector(
  getVpns,
  currentNetworkState,
  (vpns, currentNetworkId) => {
    if (!vpns || !currentNetworkId) {
      return undefined;
    }

    return !!vpns[currentNetworkId];
  },
);

export const getCurrentNetworkVpn: ReduxSelector<Vpn> = createSelector(
  getVpns,
  currentNetworkState,
  (vpns, currentNetworkId) => (currentNetworkId ? vpns[currentNetworkId] : undefined),
);

export const getNetworkPeersStatus: ReduxSelector<MerakiVpnPeer[]> = createSelector(
  getVpns,
  currentNetworkState,
  searchTextState,
  (vpns, networkId, searchText) =>
    networkId && vpns[networkId]
      ? filterData(vpns[networkId].merakiVpnPeersReachability, ["networkName"], searchText(VPN_KEY))
      : [],
);

export const getNetworkOnlinePeers: ReduxSelector<number> = createSelector(
  getVpns,
  currentNetworkState,
  (vpns, networkId) =>
    networkId && vpns[networkId]
      ? vpns[networkId].merakiVpnPeersReachability?.filter(
          (peer) => peer.reachability === Reachability.reachable,
        ).length
      : 0,
);

export const getNetworkOfflinePeers: ReduxSelector<number> = createSelector(
  getVpns,
  currentNetworkState,
  (vpns, networkId) =>
    networkId && vpns[networkId]
      ? vpns[networkId].merakiVpnPeersReachability?.filter(
          (peer) => peer.reachability === Reachability.unreachable,
        ).length
      : 0,
);

export const getVpnPeerIds: ReduxSelector<string[]> = createSelector(
  getVpns,
  currentNetworkState,
  (vpns, networkId) =>
    networkId && vpns[networkId]
      ? vpns[networkId].merakiVpnPeersReachability.map((p) => p.networkId)
      : [],
);

export const getVpnPeerEids: ReduxSelector<string[]> = createSelector(getPeerStatuses, (peers) =>
  Object.keys(peers),
);

export const getOutageData: ReduxSelector<{ [id: string]: Outage[] }> = createSelector(
  getVpnOutageData,
  getOrgNetworksState,
  (outageData, orgNetworks) => {
    const mappedOutageData: any = {};
    Object.keys(outageData).forEach((eid) => {
      const result = orgNetworks.filter(
        (network) =>
          // eids may match to the network eid or to the ng eid for the MX
          network?.ngIds?.[ProductType.appliance]?.ngEid === eid || network.eid === eid,
      );
      if (result.length > 0) {
        mappedOutageData[result[0].id] = outageData[eid];
      }
    });
    return mappedOutageData;
  },
);

export const getPeerLatency: ReduxSelector<{ [id: string]: number }> = createSelector(
  getPeerStatuses,
  getOrgNetworksState,
  (peerStatus, orgNetworks) => {
    const mappedLatency: any = {};
    Object.keys(peerStatus).forEach((eid) => {
      const result = orgNetworks.find(
        (network) =>
          // eids may match to the network eid or to the ng eid for the MX
          network?.ngIds?.[ProductType.appliance]?.ngEid === eid || network.eid === eid,
      );
      if (result) {
        mappedLatency[result.id] = peerStatus[eid].avg_lat;
      }
    });
    return mappedLatency;
  },
);

export const getVpnUsageData: ReduxSelector<Usage[]> = createSelector(
  getVpnUsage,
  timespanNameSelector,
  (usage, timespan) => (timespan && usage[timespan]) || [],
);

export const getIpsecSettings: ReduxSelector<IpsecSettings> = createSelector(
  currentNetworkState,
  getIpsecSettingsByNetworkId,
  (networkId, ipsecSettingsByNetworkId) =>
    networkId ? ipsecSettingsByNetworkId?.[networkId] : undefined,
);

export const getSiteToSiteSettings: ReduxSelector<SiteToSiteSettings> = createSelector(
  currentNetworkState,
  getSiteToSiteSettingsByNetworkId,
  (networkId, siteToSiteSettings) => (networkId ? siteToSiteSettings?.[networkId] : undefined),
);

const EMPTY_SITE_TO_SITE_SETTINGS = {} as SiteToSiteSettings;
export const getAllSiteToSiteSettings: MkiSelector<SiteToSiteSettings[]> = createSelector(
  getOrgNetworks,
  getSiteToSiteSettingsByNetworkId,
  (orgNetworks, siteToSiteSettings) =>
    orgNetworks?.map((network) => {
      return network.id
        ? siteToSiteSettings?.[network.id] ?? EMPTY_SITE_TO_SITE_SETTINGS
        : EMPTY_SITE_TO_SITE_SETTINGS;
    }) ?? [],
);

export const HasNetworkSiteToSiteVPNEnabled: ReduxSelector<boolean> = createSelector(
  getSiteToSiteSettings,
  (siteToSiteSettings) => siteToSiteSettings != null && siteToSiteSettings.mode === Modes.hub,
);
