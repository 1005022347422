import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { List, Text } from "@meraki/magnetic/components";
import { Alert, Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useOrgNetworks, useSupportCases } from "@meraki/shared/api";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useSupportCasesListContext } from "../screens/SupportCasesListContext";
import { useFavoriteSupportCasesStore } from "../screens/SupportCasesListScreen";
import { fromSupportCaseTsToDate } from "../utils/SupportCaseUtils";

export const SupportCasesListTab = ({ status }: { status: "open" | "closed" }) => {
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackProps>>();

  const theme = useMagneticTheme();

  const { favoriteCaseIds } = useFavoriteSupportCasesStore();
  const organizationId = useCurrentOrganizationId();
  const { openCases, closedCases } = useSupportCasesListContext();

  const networkId = useCurrentNetworkId();
  const userEmail = useCurrentUserEmail();

  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId);
      },
    },
  );
  const { refetch: refetchCases } = useSupportCases({
    encryptedNetworkId: network?.eid,
    userEmail,
  });

  const supportCases = status === "open" ? openCases : closedCases;

  const orderedSupportCases = supportCases?.sort((a, b) => {
    if (!favoriteCaseIds.includes(a.caseNumber)) {
      return 1;
    } else if (!favoriteCaseIds.includes(b.caseNumber)) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <List.FlashList
        data={orderedSupportCases}
        onRefresh={() => {
          refetchCases();
        }}
        loading={supportCases === undefined}
        emptyState={{ title: I18n.t("SUPPORT_CENTER.NO_DATA") }}
        getItemData={(supportCase) => {
          const { description, subject, caseNumber } = supportCase;
          const title = description;
          const rowDescription = [
            <Text size="p3" color="light" numberOfLines={1} ellipsizeMode="tail">
              {subject}
            </Text>,
          ];

          if (status === "closed") {
            const creationDate = fromSupportCaseTsToDate(supportCase.createdDate);
            const lastModifiedDate = fromSupportCaseTsToDate(supportCase.lastModifiedDate);
            rowDescription.push(
              <Text size="p3" color="light">{`${I18n.t(
                "SUPPORT_CENTER.GOv3.ROW_DESCRIPTION.OPEN",
              )}: ${formatDate(creationDate)} | ${I18n.t(
                "SUPPORT_CENTER.GOv3.ROW_DESCRIPTION.CLOSED",
              )}: ${formatDate(lastModifiedDate)}`}</Text>,
            );
          }
          const isFavorite = favoriteCaseIds.includes(caseNumber);

          return {
            title: <Text weight="bold">{title}</Text>,
            leftAccessory: isFavorite ? (
              <Icon name="Star" testID="STAR_ICON" color="interact.icon.base" />
            ) : status === "open" ? (
              <Icon name="ChatCircleText" testID="CHAT_CIRLE_TEXT_ICON" />
            ) : (
              <Icon name="Archive" testID="ARCHIVED_ICON" />
            ),
            children: (
              <Box marginLeft="lg" paddingLeft="xs" marginRight="xl">
                {rowDescription}
              </Box>
            ),
            onPress: () =>
              navigation.navigate("SupportCaseDetails", {
                supportCase,
              }),
            testID: `${status.toUpperCase()}_CASE.NUMBER-${caseNumber}${
              isFavorite ? ".FAVORITE" : ""
            }`,
          };
        }}
        groupBy={(supportCase) => supportCase.organization.name}
        sortGroupBy={(org1, org2) => {
          if (org1.data[0]?.organization.id === organizationId) {
            return -1;
          } else if (org2.data[0]?.organization.id === organizationId) {
            return 1;
          } else if (org1.label > org2.label) {
            return 1;
          } else {
            return -1;
          }
        }}
        ListFooterComponent={
          // ensures full list shows w/ support hours banner
          <Box paddingVertical="xl" />
        }
      />
      {status !== "closed" && (
        <Box
          flex={1}
          flexDirection="row"
          position="absolute"
          bottom={theme.SizeInputPaddingHorizMd}
        >
          <Box
            flex={1}
            flexDirection="row"
            marginHorizontal="md"
            gap="2xs"
            alignItems="center"
            padding="xs"
            borderRadius="md"
            backgroundColor={`info.bg.strong.base`}
            testID="SUPPORT_HOURS_BANNER"
          >
            <Box flexDirection="column">
              <Box flexDirection="row" gap="sm">
                <Alert status="informational" />
                <Text color="default.text.base" weight="bold">
                  {I18n.t("SUPPORT_CENTER.SUPPORT_INFO.LABEL")}
                </Text>
              </Box>
              <Box paddingLeft="xl">
                <Text color="default.text.base">
                  {I18n.t("SUPPORT_CENTER.GOv3.SUPPORT_INFO.DESCRIPTION")}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
