import { ArrowUp } from "phosphor-react-native";
import { G } from "react-native-svg";

import { IconProps } from "./types";

export function Uplink({ color, x, y }: IconProps) {
  return (
    <G x={x} y={y}>
      <ArrowUp weight="bold" color={color} size={20} />
    </G>
  );
}
