import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function SphereIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M2.9998 12C2.9998 16.9706 7.02924 21 11.9998 21C16.9704 21 20.9998 16.9706 20.9998 12C20.9998 7.02944 16.9704 3 11.9998 3C7.02924 3 2.9998 7.02944 2.9998 12Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <Path
        d="M3.24356 12C3.24356 14.0711 7.16387 15.75 11.9998 15.75C16.8358 15.75 20.7561 14.0711 20.7561 12C20.7561 9.92893 16.8358 8.25 11.9998 8.25C7.16387 8.25 3.24356 9.92893 3.24356 12Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Svg>
  );
}

export default SphereIcon;
