import { showContextHelpDoc } from "@meraki/go/links";

import { ContextHelpImport, HelpIcons } from "~/go/types/ContextHelpTypes";

const contextHelp: ContextHelpImport = {
  noHelp: {
    title: "CONTEXT_HELP.NO_HELP.TITLE",
    message: "CONTEXT_HELP.NO_HELP.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("NO_HELP"),
  },
  clientTracking: {
    title: "CONTEXT_HELP.CLIENT_TRACKING.TITLE",
    message: "CONTEXT_HELP.CLIENT_TRACKING.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("CLIENT_TRACKING"),
  },
  deviceInfo: {
    title: "CONTEXT_HELP.DEVICE_INFO.TITLE",
    message: "CONTEXT_HELP.DEVICE_INFO.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("DEVICE_INFO"),
  },
  defaultQoS: {
    title: "CONTEXT_HELP.DEFAULT_USAGE_LIMITS.TITLE",
    message: "CONTEXT_HELP.DEFAULT_USAGE_LIMITS.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("DEFAULT_USAGE_LIMITS"),
  },
  guestNetworks: {
    title: "CONTEXT_HELP.GUEST_NETWORKS.TITLE",
    message: "CONTEXT_HELP.GUEST_NETWORKS.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("GUEST_NETWORKS"),
  },
  visitorInfo: {
    title: "CONTEXT_HELP.VISITOR_INFO.TITLE",
    message: "CONTEXT_HELP.VISITOR_INFO.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("VISITOR_INFO"),
  },
  visitorInfoDetailed: {
    title: "CONTEXT_HELP.VISITOR_INFO_DETAILED.TITLE",
    message: "CONTEXT_HELP.VISITOR_INFO_DETAILED.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("VISITOR_INFO_DETAILED"),
  },
  clientVPNTroubleshooting: {
    title: "CONTEXT_HELP.CLIENT_VPN_TROUBLESHOOTING.TITLE",
    message: "CONTEXT_HELP.CLIENT_VPN_TROUBLESHOOTING.DESCRIPTION",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("CLIENT_VPN_TROUBLESHOOTING"),
  },
  hiddenNetworks: {
    title: "CONTEXT_HELP.HIDDEN_NETWORKS.TITLE",
    message: "CONTEXT_HELP.HIDDEN_NETWORKS.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("HIDDEN_NETWORKS"),
  },
  bridgeVsNATMode: {
    title: "CONTEXT_HELP.BRIDGE_NAT_MODE.TITLE",
    message: "CONTEXT_HELP.BRIDGE_NAT_MODE.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("BRIDGE_NAT_MODE"),
  },
  natIsolation: {
    title: "CONTEXT_HELP.NAT_ISOLATION.TITLE",
    message: "CONTEXT_HELP.NAT_ISOLATION.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("NAT_ISOLATION"),
  },
  gxIPAddress: {
    title: "CONTEXT_HELP.GX_IP_ADDRESS.TITLE",
    message: "CONTEXT_HELP.GX_IP_ADDRESS.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("GX_IP_ADDRESS"),
  },
  subnetting: {
    title: "CONTEXT_HELP.SUBNETTING.TITLE",
    message: "CONTEXT_HELP.SUBNETTING.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("SUBNETTING"),
  },
  vlanID: {
    title: "CONTEXT_HELP.VLAN_ID.TITLE",
    message: "CONTEXT_HELP.VLAN_ID.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("VLAN_ID"),
  },
  automaticFirewall: {
    title: "CONTEXT_HELP.AUTOMATIC_FIREWALL.TITLE",
    message: "CONTEXT_HELP.AUTOMATIC_FIREWALL.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("AUTOMATIC_FIREWALL"),
  },
  statusDetails: {
    title: "CONTEXT_HELP.STATUS_DETAILS.TITLE",
    message: "CONTEXT_HELP.STATUS_DETAILS.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("STATUS_DETAILS"),
  },
  dhcpServer: {
    title: "CONTEXT_HELP.DHCP_SERVER.TITLE",
    message: "CONTEXT_HELP.DHCP_SERVER.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("DHCP_SERVER"),
  },
  usageLimits: {
    title: "CONTEXT_HELP.USAGE_LIMITS.TITLE",
    message: "CONTEXT_HELP.USAGE_LIMITS.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("USAGE_LIMITS"),
  },
  securityLicenseExpiration: {
    title: "CONTEXT_HELP.SECURITY_LICENSE_EXPIRATION.TITLE",
    message: "CONTEXT_HELP.SECURITY_LICENSE_EXPIRATION.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("SECURITY_LICENSE_EXPIRATION"),
  },
  deviceIPAddress: {
    title: "CONTEXT_HELP.DEVICE_IP_ADDRESS.TITLE",
    message: "CONTEXT_HELP.DEVICE_IP_ADDRESS.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("DEVICE_IP_ADDRESS"),
  },
  runDHCPServer: {
    title: "CONTEXT_HELP.RUN_DHCP_SERVER.TITLE",
    message: "CONTEXT_HELP.RUN_DHCP_SERVER.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("RUN_DHCP_SERVER"),
  },
  useOtherDHCP: {
    title: "CONTEXT_HELP.USE_OTHER_DHCP.TITLE",
    message: "CONTEXT_HELP.USE_OTHER_DHCP.MESSAGE",
    icon: HelpIcons.info,
    showDoc: showContextHelpDoc("USE_OTHER_DHCP"),
  },
  siteToSiteVPNHealthOverview: {
    title: "CONTEXT_HELP.SITE_TO_SITE_VPN_HEALTH.TITLE",
    message: "CONTEXT_HELP.SITE_TO_SITE_VPN_HEALTH.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("SITE_TO_SITE_VPN_HEALTH"),
  },
  networkUsage: {
    title: "CONTEXT_HELP.NETWORK_USAGE.TITLE",
    message: "CONTEXT_HELP.NETWORK_USAGE.MESSAGE",
    icon: HelpIcons.help,
    showDoc: showContextHelpDoc("NETWORK_USAGE"),
  },
};

export function readContextHelp(feature: string) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (contextHelp[feature]) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return contextHelp[feature];
  }
  return null;
}
