import { DEVICE_UPLINK_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

const initialState: Record<string, any> = {};

const uplink = (state = initialState, action: any) => {
  const { type, meta, response } = action;

  switch (type) {
    case DEVICE_UPLINK_SUCCESS: {
      return {
        ...state,
        [meta.serialNumber]: response?.[0]?.uplinks,
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default uplink;
