/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum GoColors {
  navy = "#213A44",
  navyLight = "#666F77",
  navyLighter = "#8E959B",
  navyLighterButNotLightest = "#8f9ca1",
  navyMed = "#D3D6D8",
  navyMedLight = "#E4E7E8",
  navyLightest = "#EEEFF0",
  navyTransparent = "rgba(33, 58, 68, 0.05)",
  seaFoam = "#13f1c9",
  blue = "#20BCFF",
  pieBlue1 = "#20BCFF",
  pieBlue2 = "#16E4D8",
  teal = "#16E4D8",
  tealLight = "#B6F2EF",
  tealLightest = "#D9FBF9",
  pieTeal1 = "#71FFCB",
  purple = "#6665F6",
  purpleLight = "rgba(101, 101, 246, 0.25)",
  purpleLighter = "rgba(101, 101, 246, 0.08)",
  red = "#FC5635",
  redBar = "#E05843",
  yellow = "#FFBF40",
  yellowBar = "#D9B216",
  greenBar = "#45991F",
}

export default GoColors;
