import { Camera } from "@meraki/shared/camera";
import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";
import BarcodeMask from "react-native-barcode-mask";

import { useScanSerial } from "../hooks/useScanSerial";

interface SerialScannerProps {
  onSerialScan: (serial: string) => void;
  isProcessingSerial?: boolean;
}

export const SerialScanner = ({ onSerialScan, isProcessingSerial }: SerialScannerProps) => {
  const [codeScanner, serial] = useScanSerial();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (serial && !isProcessingSerial) {
      onSerialScan(serial);
    }
  }, [isProcessingSerial, onSerialScan, serial]);

  return (
    <Camera
      isActive={isFocused && !isProcessingSerial}
      mask={<BarcodeMask />}
      codeScanner={codeScanner}
    />
  );
};
