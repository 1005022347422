import DataMsg from "../types/DataMsg";

export interface Message extends DataMsg {
  data: {
    "dl_speed#": number;
    completed: boolean;
  };
}

export function formatMsg(message?: Message): string | undefined {
  const throughputBytes = message?.data?.["dl_speed#"];

  if (throughputBytes === undefined) {
    return undefined;
  }

  const bitsPerSecond = throughputBytes * 8;

  if (bitsPerSecond === 0) {
    return "0 bps";
  }

  const e = Math.floor(Math.log(bitsPerSecond) / Math.log(1024));
  const derivedThroughput = (bitsPerSecond / Math.pow(1024, e)).toFixed(2);
  const unit = "KMGTP".charAt(e);

  return `${derivedThroughput} ${unit}bps`;
}
