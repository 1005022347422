import { Box } from "@meraki/magnetic/layout";
import { Children, Fragment, isValidElement, ReactNode } from "react";

import { Loader } from "../Loader/Loader";
import { SkeletonLoaderProps } from "../Loader/Skeleton/SkeletonLoader";
import { Header, HeaderLoading } from "./Header";
import { Well } from "./Well";

export type UseLoadingHandlerProps = { loading?: boolean } & (
  | { loadingContentHeight?: never; loadingContentComponent?: never }
  | {
      loadingContentHeight: SkeletonLoaderProps["height"];
      loadingContentComponent?: never;
    }
  | {
      loadingContentHeight?: never;
      loadingContentComponent: ReactNode;
    }
);

export function useLoadingHandler(
  children: ReactNode,
  { loading = false, loadingContentHeight, loadingContentComponent }: UseLoadingHandlerProps,
) {
  if (!loading) {
    return children;
  }

  let setContentSkeleton = false;
  return Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return null;
    }

    if (child.type === Header) {
      return <HeaderLoading {...child.props} />;
    }

    if (child.type === Box || child.type === Well || child.type === Fragment) {
      if (setContentSkeleton) {
        return null;
      }
      setContentSkeleton = true;

      if (loadingContentComponent) {
        return loadingContentComponent;
      }

      return <Loader.Skeleton height={loadingContentHeight} />;
    }

    return null;
  });
}
