import { I18n } from "@meraki/core/i18n";
import {
  PortForwardingRule,
  usePortForwardingRules,
  useUpdatePortForwardingRules,
  useVlan,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { Address4 } from "ip-address";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import FormItem from "~/go/components/FormItem";
import FormSection from "~/go/components/FormSection";
import IPRadioSelection, { IPOptions } from "~/go/components/IPRadioSelection";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import PortRangeTextInput from "~/go/components/textInputs/PortRangeTextInput";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { DeviceReservation } from "~/go/types/NetworksTypes";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { clientName } from "~/lib/ClientUtils";
import {
  ALLOWED_IPS_ANY,
  createAllowedIPsTableData,
  newPortForwardingRule,
  parsePortRangeString,
} from "~/lib/PortForwardingUtils";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import MkiText from "~/shared/components/MkiText";
import Table from "~/shared/components/Table";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { SaveButton } from "~/shared/navigation/Buttons";
import ClientListRow, { CLIENT_ROW_SUBTITLE_FORMATS } from "~/shared/rows/ClientListRow";
import ListRow from "~/shared/rows/ListRow";
import { Client } from "~/shared/types/Client";

import { SettingsStackProps } from "../navigation/Types";

class IPInputModalState {
  singleRemoteIp = "";
  singleIpModalVisible = false;
  remoteIpRange = "";
  remoteIpRangeModalVisible = false;

  constructor() {
    this.singleRemoteIp = "";
    this.singleIpModalVisible = false;
    this.remoteIpRange = "";
    this.remoteIpRangeModalVisible = false;
  }
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "PortForwardingRule"> &
  PendingComponent &
  WithConnectedClientsProps;

function PortForwardingRuleScreen({ ruleIndex, clients, isClientConnected, handleError }: Props) {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const actions = useActions();

  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);

  const { data: gxVlan } = useVlan({ networkId, vlanId });
  const { data: portForwardingRules } = usePortForwardingRules({ networkId });
  const updatePortForwardingRules = useUpdatePortForwardingRules();
  const rule = portForwardingRules && portForwardingRules.rules[ruleIndex];

  const [stagedRule, setStagedRule] = useState(rule ? rule : newPortForwardingRule());
  const [reservedClientDetails, setReservedClientDetails] = useState<null | Client>(null);
  const [manualIpReservation, setManualIpReservation] = useState<null | string>(null);
  const [ipInputModalState, setIpInputModalState] = useState<IPInputModalState>(
    new IPInputModalState(),
  );

  useEffect(() => {
    const addPortForwardingRule = () => {
      const newRules = portForwardingRules?.rules || [];
      if (ruleIndex === undefined) {
        newRules.push(stagedRule);
      } else {
        newRules[ruleIndex] = stagedRule;
      }
      updatePortForwardingRules.mutate(
        { networkId: networkId, rules: newRules },
        {
          onError: (error) => {
            showAlert(I18n.t("ERROR"), error.errors?.join(","));
          },
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: usePortForwardingRules.queryKeyRoot });
            updateVlanReservations()
              .then(() => {
                navigation.goBack();
              })
              .catch(handleError);
          },
        },
      );
    };

    const updateVlanReservations = async () => {
      let updatedVlanData;
      const fixedIpAssignments = gxVlan?.fixedIpAssignments ?? {};
      const reservedIpRanges = (gxVlan && gxVlan.reservedIpRanges) || [];
      if (reservedClientDetails) {
        updatedVlanData = {
          fixedIpAssignments: {
            ...fixedIpAssignments,
            [reservedClientDetails.mac]: {
              name: clientName(reservedClientDetails),
              ip: stagedRule.lanIp,
            },
          },
        };
      } else if (manualIpReservation) {
        updatedVlanData = {
          reservedIpRanges: [
            ...reservedIpRanges,
            {
              comment: stagedRule.name,
              start: manualIpReservation,
              end: manualIpReservation,
            },
          ],
        };
      }

      if (updatedVlanData) {
        await actions.updateVlan(gxVlan?.id, updatedVlanData);
      }
    };
    const { name, protocol, lanIp, localPort, publicPort } = stagedRule;
    const isSaveDisabled =
      isEmpty(name) ||
      isEmpty(protocol) ||
      isEmpty(lanIp) ||
      isEmpty(localPort) ||
      isEmpty(publicPort);
    navigation.setOptions({
      headerRight: () => <SaveButton onPress={addPortForwardingRule} disabled={isSaveDisabled} />,
    });
  }, [
    actions,
    gxVlan,
    handleError,
    manualIpReservation,
    navigation,
    networkId,
    portForwardingRules?.rules,
    queryClient,
    reservedClientDetails,
    ruleIndex,
    stagedRule,
    updatePortForwardingRules,
  ]);

  const updateRule = (
    key: keyof PortForwardingRule,
    value: any,
    reservedClientDetails?: Client | null,
    manualIpReservation?: string | null,
  ) => {
    const updatedRule = { ...stagedRule };
    updatedRule[key] = value;
    setStagedRule(updatedRule);
    reservedClientDetails && setReservedClientDetails(reservedClientDetails);
    manualIpReservation && setManualIpReservation(manualIpReservation);
  };

  const onNameChange = (text: string) => updateRule("name", text);

  const onIPSelect = (value: IPOptions) => updateRule("protocol", value);

  const onLocalPortsChange = (startingPort?: number, endingPort?: number) => {
    checkPorts("localPort", startingPort, endingPort);
  };

  const onPublicPortsChange = (startingPort?: number, endingPort?: number) => {
    checkPorts("publicPort", startingPort, endingPort);
  };

  const checkPorts = (rule: keyof PortForwardingRule, startingPort?: number, endingPort?: number) =>
    updateRule(rule, `${startingPort || ""}${endingPort ? `-${endingPort}` : ""}`);
  const deleteRemoteIpAddress = (removedIndex: number) => {
    if (stagedRule.allowedIps) {
      const allowedIps = stagedRule.allowedIps.filter((_, index: number) => index !== removedIndex);
      if (allowedIps.length === 0) {
        allowedIps.push(ALLOWED_IPS_ANY);
      }

      setStagedRule({ ...stagedRule, allowedIps });
    }
  };

  const renderRuleDefinitionSection = () => {
    return (
      <FormSection
        title={I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.TITLE")}
        description={I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.DESCRIPTION")}
      >
        <UnderlinedTextInput
          title={I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.NAME.TITLE")}
          value={get(stagedRule, "name")}
          placeholder={I18n.t("PORT_FORWARDING_RULE.RULE_DEFINITION.NAME.PLACEHOLDER")}
          onChangeText={onNameChange}
          testID="RULE_NAME"
          showClearButton
        />
        <IPRadioSelection
          selectedValue={get(stagedRule, "protocol") as IPOptions}
          onSelect={onIPSelect}
        />
      </FormSection>
    );
  };

  const onAddClientDetails = (lanIp: string, reservedClientDetails: DeviceReservation) => {
    updateRule(
      "lanIp",
      lanIp,
      reservedClientDetails.reservedClientDetails,
      reservedClientDetails.manualIpReservation,
    );
  };

  const showClientsList = () => {
    navigation.navigate("ClientList", {
      title: I18n.t("RESERVED_ADDRESSES.DEVICES_TITLE"),
      showCancelButton: true,
      detailsScreenOverride: {
        name: "DeviceIpAssignment",
        params: {
          onAddClient: onAddClientDetails,
          filterOnSubnets: true,
          gxVlan: gxVlan,
        },
      },
      subtitleFormat: CLIENT_ROW_SUBTITLE_FORMATS.ip,
    });
  };

  const showManualDeviceEntry = () => {
    navigation.navigate("ManualDeviceInput", {
      onAddClient: onAddClientDetails,
      gxVlan: gxVlan,
    });
  };

  const showAddDeviceSheet = () => {
    showActionSheet(
      [
        I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.SELECT_DEVICE_BUTTON.SELECT_FROM_LIST"),
        I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.SELECT_DEVICE_BUTTON.ENTER_MANUALLY"),
      ],
      (id: number) => {
        if (id === 0) {
          showClientsList();
        } else if (id === 1) {
          showManualDeviceEntry();
        }
      },
      { title: I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.SELECT_DEVICE_BUTTON.TITLE") },
    );
  };

  const clearSelectedDevice = () => {
    updateRule("lanIp", "", null, null);
  };

  const renderClientRow = () => {
    if (stagedRule.lanIp) {
      const closeButton = <MerakiIcon name="close" size="s" onPress={clearSelectedDevice} />;

      let client;
      if (reservedClientDetails) {
        client = clients.find((clientInfo) => clientInfo.mac === reservedClientDetails.mac);
      } else if (clients && stagedRule.lanIp) {
        client = clients.find((clientInfo) => clientInfo.ip === stagedRule.lanIp);
      }

      if (client) {
        return (
          <ClientListRow
            renderAccessory
            deleteIcon={closeButton}
            isOnline={isClientConnected(client)}
            subtitleFormat={CLIENT_ROW_SUBTITLE_FORMATS.ip}
            ip={reservedClientDetails ? stagedRule.lanIp : client.ip}
            screenStyles={styles.clientRow}
          >
            {clientName(client)}
          </ClientListRow>
        );
      }

      return (
        <ClientListRow
          renderAccessory
          hideOnlineStatus
          deleteIcon={closeButton}
          subtitleFormat={CLIENT_ROW_SUBTITLE_FORMATS.custom}
          customSubtitle={I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.MANUAL_ENTRY")}
          screenStyles={styles.clientRow}
        >
          {stagedRule.lanIp}
        </ClientListRow>
      );
    }

    return (
      <RoundedButton onPress={showAddDeviceSheet} buttonType={ButtonType.tertiary}>
        {I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.SELECT_DEVICE")}
      </RoundedButton>
    );
  };

  const renderLocalDeviceAndPortSection = () => {
    return (
      <FormSection
        title={I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.TITLE")}
        description={I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.DESCRIPTION")}
      >
        <FormItem title={I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.DEVICE")}>
          {renderClientRow()}
        </FormItem>
        <PortRangeTextInput
          {...parsePortRangeString(get(stagedRule, "localPort"))}
          title={I18n.t("PORT_FORWARDING_RULE.LOCAL_DEVICE_AND_PORT.LOCAL_PORT")}
          onPortsChange={onLocalPortsChange}
          testID="LOCAL_PORT"
        />
      </FormSection>
    );
  };

  const addRemoteIP = () => {
    showActionSheet(
      [
        I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.ADD_RULE.SINGLE"),
        I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.ADD_RULE.RANGE"),
      ],
      (id: number) => {
        if (id === 0) {
          updateIPInputModalState("singleIpModalVisible", true);
        } else if (id === 1) {
          updateIPInputModalState("remoteIpRangeModalVisible", true);
        }
      },
      { title: I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.ADD_RULE.TITLE") },
    );
  };

  const renderAllowedConnectionsHeader = () => (
    <SectionListHeader
      heading={I18n.t(
        "PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.ALLOWED_PUBLIC_IPS.TITLE",
      )}
      onPress={addRemoteIP}
    />
  );

  // TODO: Look into how we can allow editing of the remote IP settings
  // instead of requiring users to delete the setting and add a new one.
  const renderAllowedIPsRow = (rowData: { address: string }, index: number) => {
    const { address } = rowData;

    const deleteButton = (
      <DeleteButton
        show
        onPress={() => deleteRemoteIpAddress(index)}
        testID={`DELETE_BUTTON - ${index}`}
      />
    );

    return <ListRow icon={deleteButton}>{address}</ListRow>;
  };

  const renderAllowedIPsFooter = () => {
    const allowedIps = stagedRule.allowedIps || [];

    if (isEmpty(allowedIps) || allowedIps[0] === ALLOWED_IPS_ANY) {
      return (
        <MkiText textStyle="smallSecondary">
          {I18n.t(
            "PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.ALLOWED_PUBLIC_IPS.ANY",
          )}
        </MkiText>
      );
    }
    return <></>;
  };

  const renderPublicPortAndConnectionsSection = () => {
    return (
      <FormSection
        title={I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.TITLE")}
        description={I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.DESCRIPTION")}
      >
        <PortRangeTextInput
          {...parsePortRangeString(get(stagedRule, "publicPort"))}
          onPortsChange={onPublicPortsChange}
          testID="PUBLIC_PORT"
        />
        {renderAllowedConnectionsHeader()}
        <Table
          entries={createAllowedIPsTableData(stagedRule)}
          renderRow={renderAllowedIPsRow}
          separators={false}
        />
        {renderAllowedIPsFooter()}
      </FormSection>
    );
  };

  const onIpAddressTextInputChange = (remoteIp: string) => {
    const { singleIpModalVisible, remoteIpRangeModalVisible } = ipInputModalState;

    let addressKey: keyof IPInputModalState | undefined;
    if (singleIpModalVisible) {
      addressKey = "singleRemoteIp";
    } else if (remoteIpRangeModalVisible) {
      addressKey = "remoteIpRange";
    }

    updateIPInputModalState(addressKey, remoteIp);
  };

  const updateIPInputModalState = (key?: keyof IPInputModalState, value?: string | boolean) => {
    if (!key || (typeof value !== "string" && typeof value !== "boolean")) {
      return;
    }
    setIpInputModalState({
      ...ipInputModalState,
      [key]: value,
    });
  };

  const customTextInput = () => {
    const { remoteIpRangeModalVisible } = ipInputModalState;

    return (
      <IPAddressTextInput
        onAddressChange={onIpAddressTextInputChange}
        includeSubnetMask={remoteIpRangeModalVisible}
      />
    );
  };

  const addAllowedIp = (newAllowedIp: string) => {
    const filteredAllowedIps = stagedRule.allowedIps.filter((ip) => ip !== ALLOWED_IPS_ANY);

    setStagedRule({
      ...stagedRule,
      allowedIps: [...filteredAllowedIps, newAllowedIp],
    });
    setIpInputModalState(new IPInputModalState());
  };

  const inputModalOnExit = () => {
    setIpInputModalState(new IPInputModalState());
  };

  const onSaveIp = () => {
    const { singleIpModalVisible, singleRemoteIp, remoteIpRange } = ipInputModalState;
    const remoteIp = singleIpModalVisible ? singleRemoteIp : remoteIpRange;

    if (Address4.isValid(remoteIp)) {
      addAllowedIp(remoteIp);
    } else {
      showAlert(I18n.t("ERROR"), I18n.t("PORT_FORWARDING_RULE.INVALID_IP"));
    }
  };

  const { singleIpModalVisible, remoteIpRangeModalVisible } = ipInputModalState;
  return (
    <FullScreenContainerView
      withScroll
      screenStyles={styles.scrollView}
      testID="FULLSCREEN_SCROLL_CONTAINER"
    >
      {renderRuleDefinitionSection()}
      {renderLocalDeviceAndPortSection()}
      {renderPublicPortAndConnectionsSection()}
      <InputModal
        visible={singleIpModalVisible || remoteIpRangeModalVisible}
        title={
          singleIpModalVisible
            ? I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.SINGLE_MODAL_TITLE")
            : I18n.t("PORT_FORWARDING_RULE.PUBLIC_PORT_AND_ALLOWED_CONNECTIONS.RANGE_MODAL_TITLE")
        }
        onPrimaryPress={onSaveIp}
        onExit={inputModalOnExit}
        customInputComponent={customTextInput()}
      />
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: SPACING.default,
  },
  clientRow: {
    paddingHorizontal: 0,
  },
});

export default withPendingComponent(withConnectedClients(PortForwardingRuleScreen));
