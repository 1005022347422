import { I18n } from "@meraki/core/i18n";
import { SiteToSiteVPN, Vlan } from "@meraki/shared/api";
import { Address4 } from "ip-address";

import {
  DEFAULT_SUBNET_MASK_INT,
  DEFAULT_VLAN_SUBNET,
  IPV4_BIT_SIZE,
  SUBNET_REGEX,
} from "./constants/IPAddress";

export function calculateNumberOfIPAddresses(subnetMask: number): number {
  return Math.pow(2, IPV4_BIT_SIZE - subnetMask) - 3;
}
export function ip4ToInt(ip: string): number {
  return ip.split(".").reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0;
}

export function intToIp4(int: number): string {
  return [(int >>> 24) & 0xff, (int >>> 16) & 0xff, (int >>> 8) & 0xff, int & 0xff].join(".");
}

export function calculateSubnetRange(subnet: string): number[] {
  if (SUBNET_REGEX.test(subnet)) {
    const [ip, mask] = subnet.split("/");
    const maskInt = -1 << (32 - parseInt(mask ?? "0"));
    // [start IP, end IP] - unassigned
    return [(ip4ToInt(ip ?? "") & maskInt) >>> 0, (ip4ToInt(ip ?? "") | ~maskInt) >>> 0];
  }

  return [];
}

export function doesSubnetRangeOverlaps(
  targetSubnetRange: number[],
  existingSubnetRange: number[],
) {
  if (targetSubnetRange.length !== 2 || existingSubnetRange.length !== 2) {
    return false;
  }

  const [minIpInt, maxIpInt] = existingSubnetRange;
  return targetSubnetRange.some((ipInt) => ipInt >= (minIpInt ?? 1) && ipInt <= (maxIpInt ?? 4094));
}

export function findValidSubnet(existingSubnetRanges: number[][], defaultSubnet: string): string {
  if (!existingSubnetRanges || existingSubnetRanges.length === 0) {
    return defaultSubnet;
  }

  let foundValidNewSubnet = false;
  let newSubnet = 0;
  const sortedRanges = [...existingSubnetRanges.sort(([_a, a], [_b, b]) => Number(a) - Number(b))];

  while (sortedRanges.length > 0 && !foundValidNewSubnet) {
    const possibleNewSubnetStart =
      Math.ceil((sortedRanges.shift()?.[1] ?? 0) / DEFAULT_SUBNET_MASK_INT) *
      DEFAULT_SUBNET_MASK_INT;
    const possibleNewSubnetRange = [
      possibleNewSubnetStart,
      (possibleNewSubnetStart | DEFAULT_SUBNET_MASK_INT) >>> 0,
    ];

    if (!sortedRanges.some((range) => doesSubnetRangeOverlaps(possibleNewSubnetRange, range))) {
      foundValidNewSubnet = true;
      newSubnet = possibleNewSubnetStart;
    }
  }

  return foundValidNewSubnet ? `${intToIp4(newSubnet)}/24` : "";
}

export function countIpsBetweenTwoAddresses(startAddress: string, endAddress: string): number {
  return new Address4(endAddress).bigInteger() - new Address4(startAddress).bigInteger();
}

export function getTotalReservedIps(vlan: Vlan) {
  let totalReservedIps = 0;
  if (vlan.fixedIpAssignments) {
    totalReservedIps += Object.keys(vlan.fixedIpAssignments).length;
  }
  if (vlan.reservedIpRanges) {
    totalReservedIps += vlan.reservedIpRanges.reduce((sum, ipRanges) => {
      // count IPs between two address + start address count (1)
      return sum + countIpsBetweenTwoAddresses(ipRanges.start, ipRanges.end) + 1;
    }, 0);
  }
  return totalReservedIps;
}

export function isIpReservationConflicting(ip?: string, cidr?: string, applianceIp?: string) {
  if (!cidr) {
    return null;
  }
  try {
    const subnetInfo = new Address4(cidr);
    const networkAddress = subnetInfo.startAddress().address;
    const broadcastAddress = subnetInfo.endAddress().address;

    if (ip === networkAddress) {
      return I18n.t("RESERVE_IP_RANGE.NETWORK_ADDRESS_ERROR", { ip_address: ip });
    }
    if (ip === broadcastAddress) {
      return I18n.t("RESERVE_IP_RANGE.BROADCAST_ADDRESS_ERROR", { ip_address: ip });
    }
    if (ip === applianceIp) {
      return I18n.t("RESERVE_IP_RANGE.GX_ADDRESS_ERROR", { ip_address: ip });
    }
    return null;
  } catch (_e) {
    return null;
  }
}

export function isIpInSubnetRange(ip: string, subnet: string) {
  try {
    return new Address4(ip).isInSubnet(new Address4(subnet));
  } catch (_) {
    return false;
  }
}

export function extractSubnetMask(cidrAddress: string) {
  return parseInt(cidrAddress.split("/")[1] ?? "", 10);
}

export function getImmutableAddressPart(subnet: string) {
  try {
    const subnetObj = new Address4(subnet);
    const startAddress = subnetObj.startAddress().correctForm().split(".");
    const endAddress = subnetObj.endAddress().correctForm().split(".");

    return startAddress.filter((part, index) => part === endAddress[index]).join(".");
  } catch (_) {
    return "";
  }
}
export function getApplianceIpFromSubnet(subnet: string) {
  return `${intToIp4(ip4ToInt(subnet) + 1)}`;
}

export function getNextValidIPv4Subnet(
  orgSiteToSiteSettings: SiteToSiteVPN[],
  existingSubnetRanges?: number[][],
) {
  const allSubnetRanges: number[][] = existingSubnetRanges ? existingSubnetRanges : [];

  if (orgSiteToSiteSettings) {
    orgSiteToSiteSettings.forEach((setting) => {
      setting.subnets?.forEach((subnet) => {
        const subnetRange = calculateSubnetRange(subnet.localSubnet);

        if (subnetRange != null) {
          allSubnetRanges.push(subnetRange);
        }
      });
    });
  }

  const availableSubnet = findValidSubnet(allSubnetRanges, DEFAULT_VLAN_SUBNET);
  const applianceIp = getApplianceIpFromSubnet(availableSubnet);

  const validSubnet = {
    applianceIp,
    subnet: availableSubnet,
  };

  return validSubnet;
}

export function getTotalIps(subnet: string) {
  const subnetMask = extractSubnetMask(subnet ?? "");
  return subnet ? calculateNumberOfIPAddresses(subnetMask) : 0;
}
