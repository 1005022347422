import DataMsg from "../types/DataMsg";

export interface Message extends DataMsg {
  data: {
    live: boolean;
  };
}

export function formatMsg(message?: Message): boolean | undefined {
  const status = message?.data?.live;
  if (status === undefined) {
    return undefined;
  }

  return status;
}
