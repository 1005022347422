import { I18n } from "@meraki/core/i18n";
import { Loader, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useEffect, useRef, useState } from "react";
import { Linking, Pressable, StyleSheet } from "react-native";
import { TapGestureHandler } from "react-native-gesture-handler";
import {
  Camera as VisionCamera,
  CameraPermissionRequestResult,
  CameraProps as VisionCameraProps,
  useCameraDevice,
} from "react-native-vision-camera";

type InternalCameraProps = Omit<VisionCameraProps, "style" | "device">;

interface CameraProps extends Omit<InternalCameraProps, "torch"> {
  mask?: React.ReactNode;
}

const InternalCamera = (props: InternalCameraProps) => {
  const device = useCameraDevice("back");
  const cameraRef = useRef<VisionCamera>(null);

  if (!device) {
    return (
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        alignItems="center"
        justifyContent="center"
        testID="CAMERA.LOADING"
      >
        <Loader.Spinner animate={true} />
      </Box>
    );
  }

  return (
    <TapGestureHandler
      waitFor={cameraRef}
      onHandlerStateChange={({ nativeEvent: { x, y } }) => {
        if (device.supportsFocus) {
          cameraRef.current?.focus({ x, y });
        }
      }}
    >
      <VisionCamera
        ref={cameraRef}
        style={StyleSheet.absoluteFill}
        device={device}
        zoom={3}
        testID="CAMERA.INTERNAL"
        enableZoomGesture
        {...props}
      />
    </TapGestureHandler>
  );
};

export const Camera = ({ mask, ...rest }: CameraProps) => {
  const [permissionState, setPermissionState] = useState<CameraPermissionRequestResult>();
  const [torchOn, setTorchOn] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const currentStatus = await VisionCamera.getCameraPermissionStatus();

      if (currentStatus === "granted") {
        setPermissionState(currentStatus);
        return;
      }

      const status = await VisionCamera.requestCameraPermission();
      setPermissionState(status);
    })();
  }, []);

  if (!permissionState) {
    return (
      <Box flex={1} testID="CAMERA.PERMISSION_LOADING" alignItems="center" justifyContent="center">
        <Loader.Spinner animate={true} />
      </Box>
    );
  } else if (permissionState === "denied") {
    return (
      <Box flex={1} testID="CAMERA.PERMISSION_DENIED" alignItems="center" justifyContent="center">
        <Pressable onPress={Linking.openSettings} testID="CAMERA.OPEN_SETTINGS">
          <Text size="p1" textAlign="center">
            {I18n.t("SCAN_NEW_DEVICE.CAMERA_PERMISSIONS.REQUEST")}
          </Text>
        </Pressable>
      </Box>
    );
  }

  return (
    <Box flex={1} testID="CAMERA">
      <InternalCamera torch={torchOn ? "on" : "off"} {...rest} />
      {mask && (
        <Box flex={1} testID="CAMERA.MASK_CONTAINER" pointerEvents="none">
          {mask}
        </Box>
      )}
      <Box position="absolute" bottom={17} right={17} borderRadius="sm">
        <Pressable onPress={() => setTorchOn(!torchOn)} testID="CAMERA.TORCH_BUTTON">
          <Icon
            name="Lightbulb"
            color={torchOn ? "warning.icon.focus" : "dormant.icon.base"}
            size={35}
          />
        </Pressable>
      </Box>
    </Box>
  );
};
