import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, Loader } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useWirelessRadioSettings } from "@meraki/shared/api";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useEffect } from "react";

import { WirelessRadioBandCard } from "../components/wirelessRadio/WirelessRadioBandCard";
import {
  DEFAULT_FIVE_SETTINGS,
  DEFAULT_TWO_FOUR_SETTINGS,
  WirelessBandTypes,
} from "../utils/RadioUtils";

export const WirelessRadioSettingsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "WirelessRadioSettings">>();
  const { params } = route;
  const { device } = params;

  const {
    data: radioSettings,
    isLoading,
    isRefetching,
  } = useWirelessRadioSettings({
    serialNumber: device.serial,
  });

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button.Nav
            leadingIcon="CaretLeft"
            text={device.name || device.serial}
            onPress={() => navigation.goBack()}
          />
        ),
      }),
    [navigation, device],
  );

  if (isLoading || isRefetching || !radioSettings) {
    return <Loader.Spinner animate={true} />;
  }

  const onEdit = (radioBand: WirelessBandTypes) => {
    navigation.navigate("WirelessRadioSettingsEdit", {
      radioBand,
      radioSettings,
      serial: device.serial,
    });
  };

  return (
    <Screen.View>
      <Box bottomDividerBorder>
        <Box paddingHorizontal="md" paddingBottom="sm">
          <Heading size="h1">{I18n.t("RADIO_SETTINGS.TITLE")}</Heading>
        </Box>
      </Box>
      <Box paddingTop="2xs" paddingHorizontal="md" gap="md">
        <WirelessRadioBandCard
          type="twoFourGhzSettings"
          band={radioSettings.twoFourGhzSettings ?? DEFAULT_TWO_FOUR_SETTINGS}
          loading={isLoading || isRefetching}
          onEditPress={onEdit}
        />
        <WirelessRadioBandCard
          type="fiveGhzSettings"
          band={radioSettings.fiveGhzSettings ?? DEFAULT_FIVE_SETTINGS}
          loading={isLoading || isRefetching}
          onEditPress={onEdit}
        />
      </Box>
    </Screen.View>
  );
};
