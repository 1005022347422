import { I18n } from "@meraki/core/i18n";
import {
  MonitorScreen,
  NetworkMonitorScreen,
  SecurityEventScreen,
  SecurityEventsListScreen,
  SiteToSiteDetailsScreen,
  SpeedTestScreen,
} from "@meraki/go/monitor";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { Button } from "@meraki/magnetic/components";
import { FailedConnectionGroup } from "@meraki/shared/navigation";
import {
  ModalScreenOptions,
  useDefaultScreenOptions,
  useTabScreenOptions,
} from "@meraki/shared/navigation";
import { AccessPointPlacementGroup } from "@meraki/shared/navigation";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import { ClientGroup } from "../screenGroups/ClientGroup";
import { DeviceGroup } from "../screenGroups/DeviceGroup";
import { SsidGroup } from "../screenGroups/SsidGroup";

const Stack = createNativeStackNavigator<MonitorStackProps>();

const MonitorStack = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();

  const defaultScreenOptions = useDefaultScreenOptions();
  const tabScreenOptions = useTabScreenOptions();
  const currentNetworkId = useCurrentNetworkId();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="Monitor"
        component={currentNetworkId ? NetworkMonitorScreen : MonitorScreen}
        options={{
          ...tabScreenOptions,
          headerRight: () => (
            <Button.Icon
              icon="Gauge"
              color="default.textIn.base"
              onPress={() => navigation.navigate("SpeedTest")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="SpeedTest"
        component={SpeedTestScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("SPEED_TEST.TITLE"),
          headerLeft: () => <Button.Nav text={I18n.t("CLOSE")} onPress={() => navigation.pop()} />,
        }}
      />
      <Stack.Screen
        name="SiteToSiteDetails"
        component={SiteToSiteDetailsScreen}
        options={{ headerTitle: I18n.t("SITES_OVERVIEW.TITLE") }}
      />
      <Stack.Screen
        name="SecurityEventsList"
        component={SecurityEventsListScreen}
        options={{ headerTitle: I18n.t("SECURITY_EVENTS_LIST.TITLE") }}
      />
      <Stack.Screen
        name="SecurityEvent"
        component={SecurityEventScreen}
        options={{ headerTitle: I18n.t("SECURITY_EVENTS_LIST.BLOCKED_REQUEST") }}
      />
      {DeviceGroup(Stack)}
      {ClientGroup(Stack)}
      {SsidGroup(Stack)}
      {FailedConnectionGroup(Stack)}
      {AccessPointPlacementGroup(Stack)}
    </Stack.Navigator>
  );
};

// search tag registrations
registerTags(
  {
    title: "SECURITY_EVENTS_LIST.TITLE",
    tab: "MonitorTab",
    scope: "network",
    screen: "SecurityEventsList",
  },
  ["network", "security", "events"],
);

export default MonitorStack;
