import { Platform } from "react-native";
import z from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

const URL = "/go/create_organization";
const baseUrl =
  Platform.OS === "web" ? "/account" : __DEV__ ? undefined : "https://account.meraki-go.com";

interface MutateCreateGoOrganizationRequest {
  body: CreateGoOrganizationRequest;
}

interface CreateGoOrganizationError {
  error: string;
}

export const CreateGoOrganizationRequest = z.object({
  name: z.string(),
  time_zone: z.string(),
  region: z.string().optional(),
});

export const CreateGoOrganizationSchema = z.object({
  success: z.boolean(),
  eid: z.string(),
  marketo_api_token: z.string(),
  shard_id: z.number(),
});

export type CreateGoOrganization = z.infer<typeof CreateGoOrganizationSchema>;

export type CreateGoOrganizationRequest = z.infer<typeof CreateGoOrganizationRequest>;

const createGoOrganization = ({ body }: MutateCreateGoOrganizationRequest) => {
  if (!body.region) {
    throw new Error("Region needs to be defined");
  }
  return request(CreateGoOrganizationSchema, "POST", URL, {
    baseUrl,
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Meraki-Go-Proxy-Region": body.region,
    },
  });
};

export const useCreateGoOrganization = createMutation<
  MutateCreateGoOrganizationRequest,
  CreateGoOrganization,
  CreateGoOrganizationError
>({
  baseMutationKey: URL,
  mutationFn: (parameters: MutateCreateGoOrganizationRequest) =>
    createGoOrganization({ ...parameters }),
});
