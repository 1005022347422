import { ClientTrackingMethod } from "@meraki/shared/redux";
import { useInfiniteQuery } from "@tanstack/react-query";

import { EventRequestParams } from "~/actions";
import deviceKeys from "~/api/queries/devices/keys";
import { EventsResponse, EventsResponseSchema } from "~/api/schemas/Event";
import { validatedMerakiRequest } from "~/api/util/request";
import { Method } from "~/shared/types/RequestTypes";

export function getResolvedEventsParams(
  params: EventRequestParams,
  trackingMethod?: ClientTrackingMethod,
) {
  const resolvedParams = { ...params };

  switch (trackingMethod) {
    case ClientTrackingMethod.ip:
      delete resolvedParams.clientMac;
      break;
    case ClientTrackingMethod.mac:
    case ClientTrackingMethod.tbuci:
      delete resolvedParams.clientIp;
      break;
  }

  return resolvedParams;
}

const fetchDeviceEvents = async (params: EventRequestParams): Promise<EventsResponse> => {
  const { networkId, trackingMethod, productType } = params;
  if (!networkId) {
    return Promise.reject(new Error("Missing network id"));
  }
  if (!productType) {
    return Promise.reject(new Error("Missing product type"));
  }

  const queryParams = getResolvedEventsParams(params, trackingMethod);
  const response = await validatedMerakiRequest(
    EventsResponseSchema,
    Method.get,
    `/api/v1/networks/${networkId}/events`,
    { queryParams: queryParams },
  );
  return response;
};

export const useDeviceEvents = (params: EventRequestParams) =>
  useInfiniteQuery({
    queryKey: [...deviceKeys.events, params],
    queryFn: (query) => {
      if (query.pageParam) {
        params.endingBefore = query.pageParam;
      }
      return fetchDeviceEvents(params);
    },
    getNextPageParam: (prevPage) => prevPage?.pageStartAt,
  });

export default useDeviceEvents;
