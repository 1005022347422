import { Platform, PlatformIOSStatic } from "react-native";
import DeviceInfo from "react-native-device-info";

export enum AppStates {
  active = "active",
  background = "background",
  inactive = "inactive",
}

export const isIOS = (): boolean => Platform.OS === "ios";
export const isAndroid = (): boolean => Platform.OS === "android";
export const isWeb = (): boolean => Platform.OS === "web";
export const isIpad = (): boolean => (isIOS() ? (Platform as PlatformIOSStatic).isPad : false);
export const isNightly = isWeb()
  ? document?.location?.origin?.includes("sandbox.meraki.com")
  : DeviceInfo.getBundleId().includes("nightly");
export const isDebug = isWeb()
  ? document?.location?.origin?.includes("dev.ikarem.io")
  : DeviceInfo.getBundleId().includes("debug");

export const flavorSelect = <E>(flavorOptions: { production: E; nightly: E; debug?: E }): E => {
  const defaultValue = flavorOptions.production;
  if (isNightly) {
    return flavorOptions.nightly;
  }
  if (isDebug) {
    return flavorOptions.debug || defaultValue;
  }
  return defaultValue;
};

// I'm pretty sure this should just be { web: T, mobile: T } | { ios: T, android: T } but I can't be sure
// while there are still js files in the codebase
type PlatformSelect<T> =
  | {
      web: T;
      mobile: T;
      ios?: T;
      android?: T;
    }
  | { android: T; ios: T; mobile?: T };

export const platformSelect = <T>(ps: PlatformSelect<T>): T => {
  if ("web" in ps) {
    return Platform.select({
      ios: ps.ios ?? ps.mobile,
      android: ps.android ?? ps.mobile,
      web: ps.web,
      default: ps.mobile,
    });
  }

  return Platform.select({
    ios: ps.ios !== undefined ? ps.ios : ps.mobile,
    android: ps.android !== undefined ? ps.android : ps.mobile,
    default: ps.mobile ?? ps.ios ?? ps.android,
  });
};

export const appSelect = <E, T = E>(appOptions: { enterprise: E; go: T }): E | T => {
  if (__MERAKI_GO__ && "go" in appOptions) {
    return appOptions.go;
  }
  return appOptions.enterprise;
};
