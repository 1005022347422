import { useEffect } from "react";

import { onboardingStatus } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { WrappedScreenComponent } from "~/shared/types/Hocs";
import { OnboardingNodeStatus } from "~/shared/types/OnboardingTypes";

export interface OnboardingDataProps {
  status: OnboardingNodeStatus;
  skipOnboarding: () => void;
  cleanupOnboarding: () => void;
  completeOnboarding: () => Promise<void>;
}

const withOnboardingData = <P extends object>(
  WrappedComponent: WrappedScreenComponent<P> & { options?: any },
) => {
  const OnboardingData = (props: P & OnboardingDataProps) => {
    const actions = useActions();
    const status = useAppSelector(onboardingStatus);
    useEffect(() => {
      // It's important to check & kick of onboarding polling
      // at each onboarding screen, as a user can start on any of them.
      // Also, a user can enter a state where onboarding polling makes
      // sense in a single onboarding session. Ex. traveling from Get Started
      // to the Connect & Upgrade screen.
      actions.checkForOnboardingPolling();
    }, [actions]);

    return (
      <WrappedComponent
        {...props}
        status={status}
        skipOnboarding={actions.skipOnboarding}
        cleanupOnboarding={actions.cleanupOnboarding}
        completeOnboarding={actions.completeOnboarding}
      />
    );
  };
  return OnboardingData;
};

export default withOnboardingData;
