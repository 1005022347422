import { createContext, useContext } from "react";

import { enterpriseLightTheme } from "./themes/design-tokens/enterprise/lightTheme";
import { Theme } from "./themes/design-tokens/types";

export { Theme } from "./themes/design-tokens/types";

type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];
type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
    ? F
    : T extends [infer F, ...infer R]
      ? F extends string
        ? `${F}${D}${Join<Extract<R, string[]>, D>}`
        : never
      : string;
export type ColorToken = Join<PathsToStringProps<Theme["color"]>, ".">;

export const ThemeContext = createContext<Theme>(enterpriseLightTheme);

export function ThemeProvider({ theme, children }: { theme: Theme; children: React.ReactNode }) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export function useMagneticTheme() {
  const theme = useContext(ThemeContext);

  return theme;
}

export function useThemeMode() {
  const theme = useContext(ThemeContext);

  return theme.mode;
}
