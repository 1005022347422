import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Checked({ disabled }: IconProps) {
  const theme = useMagneticTheme();

  if (disabled) {
    return (
      <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={9}
          fill={theme.color.control.bg.weak.disabled}
        />
        <Circle cx={10} cy={10} r={5} fill={theme.color.control.icon.strong.disabled} />
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={9}
          stroke={theme.color.control.border.disabled}
          strokeWidth={2}
        />
      </Svg>
    );
  }

  return (
    <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
      <Rect x={1} y={1} width={18} height={18} rx={9} fill={theme.color.control.bg.weak.base} />
      <Circle cx={10} cy={10} r={5} fill={theme.color.control.iconIn.strong.active} />
      <Rect
        x={1}
        y={1}
        width={18}
        height={18}
        rx={9}
        stroke={theme.color.control.border.strong.active}
        strokeWidth={2}
      />
    </Svg>
  );
}
