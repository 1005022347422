import { FirebasePerformanceTypes } from "@react-native-firebase/perf";
import { Action } from "redux";

import { performance } from "~/lib/FirebaseModules";

const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const REQUEST = "REQUEST";

class TimerTracker {
  traces: Record<string, FirebasePerformanceTypes.Trace[]> = {};

  getBaseType = (type: any) => {
    return type.replace(`_${SUCCESS}`, "").replace(`_${FAILURE}`, "").replace(`_${REQUEST}`, "");
  };

  isRequestAction = (action: Action) => {
    const { type } = action;
    return type.includes(REQUEST);
  };

  isCompleteAction = (action: Action) => {
    const { type } = action;
    return type.includes(SUCCESS) || type.includes(FAILURE);
  };

  trackTimer = (action: Action) => {
    if (this.isRequestAction(action)) {
      this.addTimer(action);
    } else if (this.isCompleteAction(action)) {
      this.stopTimer(action);
    }
  };

  addTimer = async (action: Action) => {
    const { type } = action;
    const timer: FirebasePerformanceTypes.Trace = await performance.startTrace(
      this.getBaseType(type),
    );
    const baseType = this.getBaseType(type);

    this.traces[baseType] === undefined
      ? (this.traces[baseType] = [timer])
      : this.traces[baseType].push(timer);
  };

  stopTimer = async (action: Action) => {
    const { type } = action;
    const baseType = this.getBaseType(type);
    const timer = Array.isArray(this.traces[baseType]) ? this.traces[baseType].pop() : undefined;

    if (!timer) {
      return;
    }

    await timer.stop();
  };
}

const timerTracker = new TimerTracker();

export default timerTracker;
