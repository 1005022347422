import { appStoreUrl as appStoreUrlEnterprise } from "@meraki/enterprise/links";
import { appStoreUrl as appStoreUrlGo } from "@meraki/go/links";
import { appSettingsUrl } from "@meraki/shared/links";
import { groupBy, isEmpty } from "lodash";
import { ActionSheetIOS, Alert, Linking } from "react-native";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DialogAndroid from "react-native-dialogs";

import { AlertSettings } from "~/api/schemas/alerts/AlertSettings";
import { AlertSeverities } from "~/api/schemas/HealthAlerts";
import MkiColors from "~/constants/MkiColors";
import I18n from "~/i18n/i18n";
import { appSelect, isAndroid, isIOS, platformSelect } from "~/lib/PlatformUtils";
import { isString } from "~/lib/TypeHelper";
import { showWebActionSheet, showWebAlert } from "~/lib/WebAlertUtils";
import GeneralStatus from "~/shared/constants/Status";
import { navRef } from "~/shared/navigation/NavigationRef";
import {
  AlertSetting,
  DestinationTypes,
  DownAlertID,
  GoAlertTypes,
} from "~/shared/types/AlertTypes";

export const DOWN_ALERT_TIMEOUT = 5;
export const USAGE_ALERT_PERIOD = 1200;

const NETWORK_WIDE_ALERTS = ["vpnConnectivityChange", "settingsChanged", "rogueAp", "usageAlert"];
const SECURITY_APPLIANCE_ALERTS = [
  "applianceDown",
  "failoverEvent",
  "dhcpNoLeases",
  "ipConflict",
  "ip6Conflict",
  "dhcp6naRenumber",
  "dhcp6pdRenumber",
  "cellularUpDown",
  "rogueDhcp",
  "vrrp",
  "ampMalwareBlocked",
  "ampMalwareDetected",
  "clientConnectivity",
  "prefixStarvation",
];
const SWITCH_ALERTS = [
  "switchDown",
  "newDhcpServer",
  "portDown",
  "portError",
  "portSpeed",
  "powerSupplyDown",
  "rpsBackup",
  "udldError",
];
const SENSOR_ALERTS = [
  "sensorBatteryPercentage",
  "sensorProbeCable",
  "sensorWaterCable",
  "sensorMagneticTampering",
  "sensorBatteryCover",
  "sensorUsbPowerCable",
];
const CAMERA_ALERTS = ["cameraDown"];
const WIRELESS_ALERTS = [
  "gatewayDown",
  "repeaterDown",
  "gatewayToRepeater",
  "highWirelessUsage",
  "onboarding",
  "uplinkIp6Conflict",
];

export const ALERT_BUTTONS = {
  ok: I18n.t("OK"),
  save: I18n.t("SAVE"),
  cancel: I18n.t("CANCEL"),
  refresh: I18n.t("REFRESH"),
  retry: I18n.t("RETRY"),
  later: I18n.t("LATER"),
  done: I18n.t("DONE"),
  discard: I18n.t("DISCARD"),
};

export const EMAIL_ALERTS = [
  GoAlertTypes.downEmail,
  GoAlertTypes.usage,
  GoAlertTypes.guestInsightsEmail,
  GoAlertTypes.weeklyUmbrellaEmail,
  GoAlertTypes.clientConnectivityEmail,
];

const ALERT_TITLES = {
  networkError: I18n.t("NETWORK_ERROR"),
  warning: I18n.t("WARNING"),
};

const ALERT_MESSAGES = {
  networkError: I18n.t("SERVER_ERROR_TEXT"),
  saveWarning: I18n.t("UNSAVED_CHANGES.MESSAGE"),
};

const commonDialogAndroidOpts = {
  positiveColor: MkiColors.primaryButton,
  negativeColor: MkiColors.primaryButton,
  neutralColor: MkiColors.primaryButton,
  cancelable: false,
};

export const showUpdateAlert = () => {
  const alertMessage = appSelect({
    go: I18n.t("FORCED_UPDATE_ALERT.MESSAGE"),
    enterprise: I18n.t("FORCED_UPDATE_ALERT.MESSAGE_ENTERPRISE"),
  });
  const appStoreUrl = appSelect({
    go: appStoreUrlGo,
    enterprise: appStoreUrlEnterprise,
  });
  showAlert(
    I18n.t("FORCED_UPDATE_ALERT.TITLE"),
    alertMessage,
    appStoreUrl,
    {
      positiveText: I18n.t("FORCED_UPDATE_ALERT.APP_STORE_BUTTON"),
    },
    { cancelable: false },
  );
};

export const showRequestPermissions = (
  title: string,
  iosMessage: string,
  androidMessage: string,
  positiveText: string | null | undefined = null,
  negativeText = ALERT_BUTTONS.cancel,
) => {
  if (isIOS()) {
    return showAlert(title, iosMessage, appSettingsUrl, { positiveText, negativeText });
  }
  if (isAndroid()) {
    return showAlert(title, androidMessage, () => Linking.openSettings(), {
      positiveText,
      negativeText,
    });
  }
  return null;
};

export const mapAlertSeverityToStatus = (status: any) => {
  switch (status) {
    case AlertSeverities.error:
    case AlertSeverities.critical:
      return GeneralStatus.bad;
    case AlertSeverities.warning:
    case AlertSeverities.info:
      return GeneralStatus.alerting;
    default:
      return GeneralStatus.alerting;
  }
};

type Buttons = {
  onPress: () => void;
  text: string;
  style?: string;
};

const getButtons = (onPress: Buttons["onPress"], options: any) => {
  const buttons: Buttons[] = [];
  buttons.push({
    text: options.positiveText || ALERT_BUTTONS.ok,
    onPress,
  });
  if (options.negativeText) {
    buttons.push({
      text: options.negativeText,
      onPress: options.onNegativePress,
      style: options.isRatePrompt ? "default" : "cancel",
    });
  }
  if (options.neutralText) {
    buttons.push({
      text: options.neutralText,
      onPress: () => {},
      style: options.isRatePrompt ? "default" : "cancel",
    });
  }
  return buttons;
};

const showIosAlert = (title: string, message: string, onPress: any, options = {}) =>
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Buttons[]' is not assignable to ... Remove this comment to see the full error message
  Alert.alert(title, message, getButtons(onPress, options));

let showingAndroidAlert = false;

type AlertOptions = {
  positiveText: string;
  negativeText: string | null;
  neutralText: string;
  isRatePrompt: boolean;
  onNegativePress: () => void;
};

const showAndroidAlert = async (
  title: any,
  message: any,
  onPress: any,
  options: Partial<AlertOptions> = {},
) => {
  // Trying to show more than one alert at a time will cause the app to crash with
  // the error: java.lang.RuntimeException
  // Illegal callback invocation from native module. This callback type only permits a single invocation from native code.
  if (showingAndroidAlert) {
    return;
  }
  showingAndroidAlert = true;

  const { positiveText, negativeText, neutralText } = options;
  const derivedOpts: Partial<AlertOptions> = {
    positiveText: positiveText || ALERT_BUTTONS.ok,
    negativeText: negativeText || null,
    // @ts-expect-error TS(2783) FIXME: 'cancelable' is specified more than once, so this ... Remove this comment to see the full error message
    cancelable: options.isRatePrompt,
    ...commonDialogAndroidOpts,
  };

  if (neutralText) {
    derivedOpts.neutralText = neutralText;
  }

  try {
    const { action } = await DialogAndroid.alert(title, message, derivedOpts);

    if (options.isRatePrompt) {
      showingAndroidAlert = false;
    }

    switch (action) {
      case DialogAndroid.actionPositive:
        if (onPress) {
          onPress();
        }
        break;
      case DialogAndroid.actionNegative:
        if (options.onNegativePress) {
          options.onNegativePress();
        }
        break;
    }
  } catch (_) {
  } finally {
    showingAndroidAlert = false;
  }
};

type AndroidActionSheetOptions = {
  title: string;
  message: string;
  contentColor: string;
};

const showAndroidActionSheet = async (
  items: any,
  onPress: any,
  options: Partial<AndroidActionSheetOptions> = {},
) => {
  const { title, message, contentColor } = options;
  const derivedOpts = {
    ...commonDialogAndroidOpts,
    items: items.map((label: any, id: any) => {
      return { label, id };
    }),
    contentColor: contentColor || MkiColors.primaryButton,
    neutralText: ALERT_BUTTONS.cancel,
    positiveText: null,
  };

  const { action, selectedItem } = await DialogAndroid.showPicker(title, message, derivedOpts);
  switch (action) {
    case DialogAndroid.actionSelect:
      onPress(selectedItem.id);
      return;
  }
};

type IOSActionSheetOptions = {
  destructiveButtonIndex: number;
  contentColor: string;
  title: string;
  message: string;
};

const showIosActionSheet = (
  items: any,
  onPress: any,
  options: Partial<IOSActionSheetOptions> = {},
) =>
  ActionSheetIOS.showActionSheetWithOptions(
    {
      options: [...items, ALERT_BUTTONS.cancel],
      cancelButtonIndex: items.length,
      destructiveButtonIndex: options.destructiveButtonIndex,
      tintColor: options.contentColor || MkiColors.primaryButton,
      title: options.title,
      message: options.message,
    },
    // Discard the cancel button press
    (index) => {
      if (index === items.length) {
        return;
      }
      onPress(index);
    },
  );

/*
 * Parameters
 *   title: heading of the alert
 *   message: message body
 *   onPress: callback for positive button press
 *   options: {
 *     positiveText: positive button text
 *     negativeText: negative button text
 *     onNegative: callback for negative button press
 *     neutralText: neutral button text (dismisses modal)
 *   }
 */
export const showAlert = (...args: any[]) => {
  const [title, error] = args;

  const shouldNotShowError =
    (error && !isString(error)) ||
    (title.includes(I18n.t("ERROR")) && error?.includes(I18n.t("SERVER_ERROR_TEXT")));

  if (shouldNotShowError) {
    return;
  }

  return platformSelect({
    android: showAndroidAlert,
    ios: showIosAlert,
    web: showWebAlert,
    // @ts-expect-error TS(2556) FIXME: A spread argument must either have a tuple type or... Remove this comment to see the full error message
  })(...args);
};

export const showActionSheet = (...args: any[]) =>
  platformSelect({
    android: showAndroidActionSheet,
    ios: showIosActionSheet,
    web: showWebActionSheet,
    // @ts-expect-error TS(2556) FIXME: A spread argument must either have a tuple type or... Remove this comment to see the full error message
  })(...args);

type NetworkErrorOptions = {
  errorMessage: string;
  noCancel: boolean;
  onNegativePress: () => void;
};

export const showNetworkErrorWithRetry = (
  onPress: () => void,
  options: Partial<NetworkErrorOptions> = {},
) =>
  showAlert(
    ALERT_TITLES.networkError,
    options.errorMessage || ALERT_MESSAGES.networkError,
    onPress,
    {
      positiveText: ALERT_BUTTONS.retry,
      ...(options.noCancel
        ? {}
        : options.onNegativePress
          ? { negativeText: ALERT_BUTTONS.cancel, onNegativePress: options.onNegativePress }
          : { negativeText: ALERT_BUTTONS.cancel }),
    },
  );

export const showSaveWarning = (savePress: any, discardPress?: () => void) =>
  showAlert(ALERT_TITLES.warning, ALERT_MESSAGES.saveWarning, savePress, {
    positiveText: ALERT_BUTTONS.save,
    negativeText: ALERT_BUTTONS.discard,
    onNegativePress: discardPress,
  });

export const confirmSaveWarning = (savePress: any, discardPress = undefined, message: any) =>
  showAlert(ALERT_TITLES.warning, message, savePress, {
    positiveText: ALERT_BUTTONS.save,
    negativeText: ALERT_BUTTONS.cancel,
    onNegativePress: discardPress,
  });

export const confirmDeleteWarning = (
  deletePress: () => Promise<void>,
  message: string,
  discardPress?: () => void,
) =>
  showAlert(ALERT_TITLES.warning, message, deletePress, {
    positiveText: ALERT_BUTTONS.ok,
    negativeText: ALERT_BUTTONS.cancel,
    onNegativePress: discardPress,
  });

export const showAlertWithContactSupportButton = ({
  title = I18n.t("ERROR"),
  message = I18n.t("SERVER_ERROR_TEXT"),
  onExit,
  params,
  ...options
}: any) => {
  showAlert(title, message, onExit, {
    ...options,
    negativeText: I18n.t("CONTACT_SUPPORT"),
    onNegativePress: () => navRef.navigate("ContactSupport", params),
  });
};

export const isEnabledForUser = (enabled: any, recipients: any, currentUser: any) =>
  enabled && recipients.includes(currentUser);

export const getNewRecipients = (enabled: any, recipients: any, currentUser: any) => {
  if (!Array.isArray(recipients)) {
    return [];
  }

  if (enabled) {
    return recipients.includes(currentUser) ? recipients : [...recipients, currentUser];
  } else {
    return recipients.filter((recipient) => recipient !== currentUser);
  }
};

const baseAlertConfigs = {
  [GoAlertTypes.downEmail]: {
    filters: {
      timeout: DOWN_ALERT_TIMEOUT,
    },
  },
  [GoAlertTypes.downPush]: {
    filters: {
      timeout: DOWN_ALERT_TIMEOUT,
    },
  },
  [GoAlertTypes.usage]: {
    filters: {
      period: USAGE_ALERT_PERIOD,
    },
  },
};

export const getChangedAlertSettings = (
  alertType: GoAlertTypes,
  enabled: boolean,
  emailRecipients: string[],
  pushRecipients: string[] | null,
  currentUser: string,
  clients?: any,
) => {
  let newRecipients;
  let otherRecipients;
  let destination;

  if (EMAIL_ALERTS.includes(alertType)) {
    newRecipients = getNewRecipients(enabled, emailRecipients, currentUser);
    otherRecipients = pushRecipients;
    destination = DestinationTypes.email;
  } else {
    newRecipients = getNewRecipients(enabled, pushRecipients, currentUser);
    otherRecipients = emailRecipients;
    destination = DestinationTypes.push;
  }

  const alertConfig = {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...baseAlertConfigs[alertType],
    enabled: enabled || !isEmpty(newRecipients) || !isEmpty(otherRecipients),
    alertDestinations: {
      [destination]: newRecipients,
    },
  };

  switch (alertType) {
    case GoAlertTypes.downEmail:
    case GoAlertTypes.downPush:
      return Object.values(DownAlertID).map((alertId) => ({ ...alertConfig, type: alertId }));
    case GoAlertTypes.usage:
      return [
        {
          ...alertConfig,
          type: "usageAlert",
        },
      ];
    case GoAlertTypes.guestInsightsEmail:
    case GoAlertTypes.guestInsightsPush:
      return [
        {
          ...alertConfig,
          type: "weeklyPresence",
        },
      ];
    case GoAlertTypes.weeklyUmbrellaEmail:
    case GoAlertTypes.weeklyUmbrellaPush:
      return [
        {
          ...alertConfig,
          type: "weeklyUmbrella",
        },
      ];
    case GoAlertTypes.clientConnectivityEmail:
      return [
        {
          filters: {
            clients: [...clients],
          },
          alertDestinations: {
            [destination]: newRecipients,
          },
          type: "clientConnectivity",
        },
      ];
    case GoAlertTypes.clientConnectivityPush:
      return [
        {
          filters: {
            clients: [...clients],
          },
          alertDestinations: {
            [destination]: newRecipients,
          },
          type: "clientConnectivity",
        },
      ];
  }
};

export const getChangedTrackedClients = (enabled: any, clients: any) => {
  return [
    {
      filters: {
        clients: [...clients],
      },
      enabled: enabled,
      type: "clientConnectivity",
    },
  ];
};

export const addPushRecipientsToSettings = (
  alertSettings: any,
  alertType: any,
  enabled: any,
  emailRecipients: any,
  pushRecipients: any,
  currentUserId: any,
) => {
  if (alertSettings == null || isEmpty(alertSettings)) {
    return getChangedAlertSettings(
      alertType,
      enabled,
      emailRecipients,
      pushRecipients,
      currentUserId,
    );
  }

  const newPushRecipients = getNewRecipients(enabled, pushRecipients, currentUserId);

  return alertSettings.map((setting: any) => ({
    ...setting,
    enabled: setting.enabled || enabled,

    alertDestinations: {
      ...setting.alertDestinations,
      mobileAdminIds: newPushRecipients,
    },
  }));
};

export function unsubscribeAdminFromAllAlerts(admin: any, alertSettings: AlertSetting[]) {
  const newSettings: AlertSetting[] = [];
  for (const settings of Object.values(alertSettings)) {
    //@ts-ignore
    const newDestinations = { ...settings.alertDestinations };
    newDestinations.emails = newDestinations.emails?.filter((email) => email !== admin.email);

    if (newDestinations.mobileAdminIds != null) {
      newDestinations.mobileAdminIds = newDestinations.mobileAdminIds.filter(
        (adminId) => adminId !== admin.id,
      );
    }

    //@ts-ignore
    newSettings.push({
      //@ts-ignore
      ...settings,
      enabled: !isEmpty(newDestinations.emails) || !isEmpty(newDestinations.mobileAdminIds),
      //@ts-ignore FIXME: can alertDestinations be undefined
      alertDestinations: newDestinations,
    });
  }

  return newSettings;
}

export interface EnabledAlertsByCategory {
  numNetworkWideEnabled: number;
  numSecurityApplianceEnabled: number;
  numSwitchEnabled: number;
  numSensorEnabled: number;
  numCameraEnabled: number;
  numWirelessEnabled: number;
}

export function getNumberOfAlertsEnabledByCategory(
  alertSettings: AlertSettings,
): EnabledAlertsByCategory {
  const alerts = alertSettings.alerts;

  const filteredAlerts = alerts.filter((alert) => alert.enabled);

  const groupedAlerts = groupBy(filteredAlerts, (alert) => {
    if (NETWORK_WIDE_ALERTS.includes(alert.type)) {
      return "networkWide";
    } else if (SECURITY_APPLIANCE_ALERTS.includes(alert.type)) {
      return "securityAppliance";
    } else if (SWITCH_ALERTS.includes(alert.type)) {
      return "switch";
    } else if (SENSOR_ALERTS.includes(alert.type)) {
      return "sensor";
    } else if (CAMERA_ALERTS.includes(alert.type)) {
      return "camera";
    } else if (WIRELESS_ALERTS.includes(alert.type)) {
      return "wireless";
    } else {
      return "unknown";
    }
  });

  return {
    numNetworkWideEnabled: groupedAlerts["networkWide"].length,
    numSecurityApplianceEnabled: groupedAlerts["securityAppliance"].length,
    numSwitchEnabled: groupedAlerts["switch"].length,
    numSensorEnabled: groupedAlerts["sensor"].length,
    numCameraEnabled: groupedAlerts["camera"].length,
    numWirelessEnabled: groupedAlerts["wireless"].length,
  };
}
