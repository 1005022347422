import z, { ZodTypeAny } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";

const MAX_BATCH_ACTION_NUM = 20;

export const baseActionBatchSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  confirmed: z.boolean(),
  synchronous: z.boolean(),
  status: z.object({
    completed: z.boolean(),
    failed: z.boolean(),
    errors: z.array(z.string()),
    createdResources: z.optional(
      z.array(
        z.object({
          id: z.number(),
          uri: z.string(),
        }),
      ),
    ),
  }),
});

export const createActionBatchSchema = <T extends ZodTypeAny>(genericBodySchema: T) =>
  baseActionBatchSchema
    .extend({
      actions: z.array(
        z.object({
          resource: z.string(),
          operation: z.string(),
          body: genericBodySchema,
        }),
      ),
    })
    .describe(`ActionBatchSchema<${genericBodySchema.description}>`);

export type BaseActionBatch = z.infer<typeof baseActionBatchSchema>;

export interface BatchRequest {
  organizationId?: string;
}

export interface BatchRequestAction {
  resource: string;
  operation: string;
  body: Record<string, unknown>;
}

interface BatchRequestActions extends BatchRequest {
  actions: BatchRequestAction[];
  actionTypeSchema: z.ZodTypeAny;
}

export function buildBatchMutationUrl({ organizationId }: BatchRequest) {
  return `/api/v1/organizations/${organizationId}/actionBatches`;
}

const createActionBatches = (actions: BatchRequestAction[]): BatchRequestAction[][] => {
  if (actions.length < 20) {
    return [actions];
  }

  const numOfIteration = Math.floor(actions.length / MAX_BATCH_ACTION_NUM);
  const actions2d: BatchRequestAction[][] = [];
  for (let i = 0; i <= numOfIteration; i++) {
    actions2d.push(actions.slice(i * MAX_BATCH_ACTION_NUM, (i + 1) * MAX_BATCH_ACTION_NUM));
  }

  return actions2d;
};

const sendBatchRequests = async ({
  organizationId,
  actions,
  actionTypeSchema,
}: BatchRequestActions) => {
  const typedActionBatchSchema = createActionBatchSchema(actionTypeSchema);

  const batchedActions2d = createActionBatches(actions);

  const errors = new Set<string>();
  for (const batchedActions of batchedActions2d) {
    const response = await request(
      typedActionBatchSchema,
      "POST",
      buildBatchMutationUrl({ organizationId }),
      {
        body: JSON.stringify({
          confirmed: true,
          synchronous: true,
          actions: batchedActions,
        }),
      },
    );

    if (!response.status.completed) {
      response.status.errors.forEach((error) => errors.add(error));
    }
  }

  if (errors.size > 0) {
    return Promise.reject({ errors: Array.from(errors) });
  }

  return batchedActions2d.map((actions) => actions.map((action) => action.body));
};

export const useActionBatchMutationWrapper = createMutation<
  BatchRequestActions,
  Record<string, unknown>[][],
  APIResponseError
>({
  baseMutationKey: buildBatchMutationUrl({
    organizationId: "{organizationId}",
  }),
  mutationFn: (variables: BatchRequestActions) => {
    return sendBatchRequests(variables);
  },
});
