import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  List,
  Notification,
  RefreshControl,
  Tag,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  Admin,
  APIResponseError,
  queryClient,
  useAdmins,
  useDeleteAdmin,
  useUpdateAdmin,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import { useCurrentOrganizationId, useCurrentUserEmail } from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { useCallback, useEffect, useRef, useState } from "react";

type EditAdminForm = {
  name: string;
};

export function AdministratorListScreen() {
  const route = useRoute<RouteProp<ConfigureStackProps, "Administrators">>();
  const { params } = route;

  const organizationId = useCurrentOrganizationId();
  const email = useCurrentUserEmail();

  const adminDetailsRef = useRef<BottomSheetMethods>(null);

  const {
    data: admins,
    isLoading: isAdminsLoading,
    refetch,
    isRefetching,
  } = useAdmins(
    { organizationId },
    {
      select: (admins) =>
        admins?.sort((a, b) =>
          a.email === email || b.email === email ? -1 : a.name.localeCompare(b.name),
        ),
    },
  );

  const updateAdmin = useUpdateAdmin();
  const deleteAdmin = useDeleteAdmin();

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | undefined>(undefined);

  useEffect(() => {
    setSuccessMessage(params?.passedSuccessMessage);
    refetch();
  }, [params?.passedSuccessMessage, refetch]);

  const closeBottomSheets = useCallback(() => {
    setSelectedAdmin(undefined);
    setErrorMessage(undefined);
    adminDetailsRef.current?.dismiss();
  }, []);

  const onSuccessCall = useCallback(
    (message: string) => {
      setSuccessMessage(message);
      queryClient.refetchQueries({ queryKey: useAdmins.queryKeyRoot });
      closeBottomSheets();
    },
    [closeBottomSheets],
  );

  const updateSelectedAdmin = useCallback(
    ({ name }: EditAdminForm) => {
      const resolutions = {
        onSuccess: () => onSuccessCall(I18n.t("ADMIN.EDIT.UPDATED", { name })),
        onError: (error: APIResponseError) =>
          setErrorMessage(error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT")),
      };
      if (name !== "") {
        updateAdmin.mutate(
          {
            organizationId,
            adminId: selectedAdmin?.id ?? "",
            params: {
              name: name,
            },
          },
          resolutions,
        );
      }
    },
    [onSuccessCall, organizationId, selectedAdmin, updateAdmin],
  );

  const deleteAdministrator = useCallback(() => {
    if (selectedAdmin) {
      deleteAdmin.mutate(
        { organizationId, adminId: selectedAdmin.id },
        {
          onSuccess: () => onSuccessCall(I18n.t("ADMIN.DELETE.SUCCESS")),
        },
      );
    }
  }, [deleteAdmin, onSuccessCall, organizationId, selectedAdmin]);

  const editMethods = useForm<EditAdminForm>({
    defaultValues: { name: selectedAdmin?.name },
  });

  const onUpdateSubmit = editMethods.handleSubmit(updateSelectedAdmin);

  return (
    <Screen
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
      scrollEnabled
    >
      {successMessage && (
        <Box paddingHorizontal="sm">
          <Notification.Inline
            status="positive"
            message={successMessage}
            onDismiss={() => setSuccessMessage(undefined)}
          />
        </Box>
      )}
      <List.FlashList
        label={I18n.t("ADMIN.LIST_TITLE")}
        loading={isAdminsLoading}
        data={admins}
        getItemData={(admin, _, index) => {
          const tags = [];
          if (admin.email === email) {
            tags.push(
              <Tag
                type="categorial"
                color="accentA"
                key={`${index}-tag-you`}
                testID={`${admin.id}_YOU`}
              >
                {I18n.t("ADMIN.TAGS.YOU")}
              </Tag>,
            );
          }
          if (admin.orgAccess === "read-only") {
            tags.push(
              <Tag
                type="categorial"
                color="accentB"
                key={`${index}-tag-read-only`}
                testID={`${admin.id}_READ_ONLY`}
              >
                {I18n.t("ADMIN.TAGS.READ_ONLY")}
              </Tag>,
            );
          }
          return {
            title: admin.name,
            description: admin.email,
            onPress: () => {
              setSelectedAdmin(admin);
              editMethods.setValue("name", admin.name);
              adminDetailsRef.current?.present();
            },
            children:
              tags.length > 0 ? (
                <Box flex={1} flexDirection="row">
                  {tags}
                </Box>
              ) : undefined,
          };
        }}
      />
      <BottomSheet.Modal ref={adminDetailsRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("ADMIN.EDIT.TITLE")}
          onCancelPress={closeBottomSheets}
          onResetPress={onUpdateSubmit}
        />
        <BottomSheet.Content>
          <Card>
            {errorMessage && (
              <Notification.Inline
                status="negative"
                message={errorMessage}
                onDismiss={() => setErrorMessage(undefined)}
              />
            )}
            <Form {...editMethods}>
              <Form.Input
                name="name"
                label={I18n.t("ADMIN.EDIT.NAME")}
                placeholder={selectedAdmin?.name}
                disabled={updateAdmin.isLoading || deleteAdmin.isLoading}
              />
            </Form>
          </Card>
          <Box paddingTop="lg">
            <Button
              kind="secondaryDestructive"
              disabled={selectedAdmin?.email === email}
              text={I18n.t("ADMIN.DELETE.BUTTON")}
              onPress={() =>
                showDeleteConfirmAlert(
                  I18n.t("ADMIN.DELETE.CONFIRMATION.TITLE"),
                  I18n.t("ADMIN.DELETE.CONFIRMATION.DESCRIPTION"),
                  deleteAdministrator,
                )
              }
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
