import { ThemeName, useTheme } from "@meraki/core/theme";
import { ReactNode } from "react";
import { useColorScheme } from "react-native";

import { Theme, ThemeProvider } from "./ThemeContext";

export type ThemeConfiguration = Record<Exclude<ThemeName, "system">, Theme>;

export interface MagneticProviderProps {
  themeConfiguration: ThemeConfiguration;
  children: ReactNode;
}

export function useMagneticThemeFromThemeName(themeConfiguration: ThemeConfiguration) {
  const { theme: themeName } = useTheme();
  const currentColorScheme = useColorScheme();

  switch (themeName) {
    case "light":
      return themeConfiguration.light;
    case "lightDeuteranomaly":
      return themeConfiguration.lightDeuteranomaly;
    case "dark":
      return themeConfiguration.dark;
    case "amoled":
      return themeConfiguration.amoled;
    case "darkDeuteranomaly":
      return themeConfiguration.darkDeuteranomaly;
    case "system":
    default:
      return currentColorScheme === "light" ? themeConfiguration.light : themeConfiguration.dark;
  }
}

export function MagneticThemeProvider({ themeConfiguration, children }: MagneticProviderProps) {
  const theme = useMagneticThemeFromThemeName(themeConfiguration);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
