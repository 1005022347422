import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface FirmwareUpgradesRequest {
  networkId?: string;
}

interface FirmwareUpgradesUpdateRequest {
  firmwareUpgrades: FirmwareUpgrades;
  networkId?: string;
}

export const FirmwareDetailsSchema = z.object({
  firmware: z.string().optional(),
  id: z.string().optional(),
  releaseDate: z.string().optional(),
  releaseType: z.string().optional(),
  shortName: z.string().optional(),
});

export const PerProductFirmwareSchema = z.object({
  availableVersions: z.array(FirmwareDetailsSchema).optional(),
  currentVersion: FirmwareDetailsSchema.optional(),
  lastUpgrade: z
    .object({
      fromVersion: FirmwareDetailsSchema.optional(),
      time: z.string().optional(),
      toVersion: FirmwareDetailsSchema.optional(),
    })
    .optional(),
  nextUpgrade: z
    .object({
      time: z.string().optional(),
      toVersion: FirmwareDetailsSchema.optional(),
    })
    .optional(),
});

export const FirmwareUpgradesSchema = z.object({
  products: z
    .object({
      appliance: PerProductFirmwareSchema.optional(),
      camera: PerProductFirmwareSchema.optional(),
      cellularGateway: PerProductFirmwareSchema.optional(),
      cloudGateway: PerProductFirmwareSchema.optional(),
      sensor: PerProductFirmwareSchema.optional(),
      switch: PerProductFirmwareSchema.optional(),
      wireless: PerProductFirmwareSchema.optional(),
      systemsManager: PerProductFirmwareSchema.optional(),
    })
    .optional(),
  timezone: z.string().optional(),
  upgradeWindow: z
    .object({
      dayOfWeek: z.string().optional(),
      hourOfDay: z.string().optional(),
    })
    .optional(),
});

export type FirmwareDetails = z.infer<typeof FirmwareDetailsSchema>;
export type FirmwareUpgrades = z.infer<typeof FirmwareUpgradesSchema>;

const buildUrl = ({ networkId }: FirmwareUpgradesRequest) => {
  return `/api/v1/networks/${networkId}/firmwareUpgrades`;
};

export function fetchFirmwareUpgrades(networkId?: string): Promise<FirmwareUpgrades> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching firmware upgrades");
  }
  return request(FirmwareUpgradesSchema, "GET", buildUrl({ networkId }));
}

function mutateFirmwareUpgrades({ firmwareUpgrades, networkId }: FirmwareUpgradesUpdateRequest) {
  return request(FirmwareUpgradesSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify(firmwareUpgrades),
  });
}

export const useUpdateFirmwareUpgrades = createMutation<
  FirmwareUpgradesUpdateRequest,
  FirmwareUpgrades,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => mutateFirmwareUpgrades(variables),
});

export const useFirmwareUpgrades = createQuery<FirmwareUpgradesRequest, FirmwareUpgrades>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: ({ networkId }: FirmwareUpgradesRequest) => fetchFirmwareUpgrades(networkId),
  requiredVariables: ["networkId"],
});
