import Clipboard from "@react-native-clipboard/clipboard";
import ReactNativeHapticFeedback from "react-native-haptic-feedback";
import Toast from "react-native-simple-toast";

import I18n from "~/i18n/i18n";

/**
 * Adds our desired UX to the `@react-native-clipboard/clipboard` library's Clipboard.setString method
 * - Copies string value to device clipboard
 * - Shows success toast
 * - Vibrates device
 */

export function mkiSetString(value: string) {
  Clipboard.setString(value);
  Toast.showWithGravity(I18n.t("COPIED_TO_CLIPBOARD"), Toast.SHORT, Toast.TOP);
  ReactNativeHapticFeedback.trigger("impactLight");
}

export default {
  ...Clipboard,
  mkiSetString,
};
