import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { memo, PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { chartDateFormatter, formatUseBlocks, useblocksGraphseries } from "~/lib/formatHelper";
import { calculateMaxAndDomain, formatBitsAxis } from "~/lib/MkiChartUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import {
  applicationUseblocksLoadingState,
  applicationUseblocksSelector,
  ClientApplicationType,
  counterSetsState,
  currentNetworkState,
  encryptedNetworkIdSelector,
  getNetworkName,
  getNetworkTimezone,
  makeApplicationTotalUsageSelector,
  makeClientsByApplicationForSSIDSelector,
  makeClientsByApplicationSelector,
  networkUseblocksLoadingState,
  networkUseblocksSelector,
  ssidUseblocksLoadingState,
  ssidUseblocksState,
  timespanState,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import DetailsHeader from "~/shared/components/header/DetailsHeader";
import MerakiIcon from "~/shared/components/icons";
import MkiLineChart from "~/shared/components/MkiLineChart";
import MkiText from "~/shared/components/MkiText";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import SummaryCard from "~/shared/components/SummaryCard";
import SummaryList from "~/shared/components/SummaryList";
import Touchable from "~/shared/components/Touchable";
import { SetUsageButton } from "~/shared/navigation/Buttons";
import UsageRow from "~/shared/rows/UsageRow.go";
import { ApplicationTypes } from "~/shared/types/ApplicationTypes";
import { ConnectionType } from "~/shared/types/Device";
import { RootState } from "~/shared/types/Redux";
import { Useblock } from "~/shared/types/Useblocks";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { ApplicationUsageScreensPropMap } from "../navigation/Types";

interface ApplicationHeaderProps {
  connectionOnPress: () => void;
  ssidName: string;
  testID: string;
  description: string;
}

const CustomData = ({
  connectionOnPress,
  ssidName,
}: {
  connectionOnPress: () => void;
  ssidName: string;
}) => {
  const wifiIcon = <MerakiIcon name="wifi" size="xs" />;
  return (
    <View style={styles.headerDetailsContainer}>
      {wifiIcon}
      <Touchable onPress={connectionOnPress} transparentBackground>
        <View>
          <MkiText textStyle="small" screenStyles={styles.linkText}>
            {ssidName}
          </MkiText>
        </View>
      </Touchable>
    </View>
  );
};

const ApplicationHeader = memo(function ApplicationHeader(props: ApplicationHeaderProps) {
  const { connectionOnPress, ssidName, testID } = props;

  const detailsProps = props.ssidName
    ? {
        connectionType: ConnectionType.none,
        connectionName: props.ssidName,
        connectionOnPress: props.connectionOnPress,
        CustomHeader: <CustomData ssidName={ssidName} connectionOnPress={connectionOnPress} />,
      }
    : {};

  return <DetailsHeader description={props.description} testID={testID} {...detailsProps} />;
});

type ReduxProps = {
  ssidUseblocks: Useblock;
  networkUseblocks?: Useblock;
  applicationUseblocks: Useblock;
  clientsByApplication: ClientApplicationType[];
  networkId: string;
  encryptedNetworkId: string;
  timespan: number;
  application: { name: string };
  networkName: string;
  applicationTotalUsage: number;
  applicationUsageIsFetching: boolean;
  networkUsageIsFetching: boolean;
  ssidUsageIsFetching: boolean;
  timezone: string;
  clientsAndUseBlocksFetching: boolean;
};

type Props = ForwardedNativeStackScreenProps<
  ApplicationUsageScreensPropMap,
  "ApplicationUsageDetails"
> &
  ReduxProps &
  BasicActions;

class ApplicationUsageDetailsScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => (
        <SetUsageButton
          onPress={() => {
            navigation.navigate("SetUsage", {});
          }}
        />
      ),
    });
  }

  componentDidMount() {
    this.getData();
  }

  onRefresh = () => this.getData(true);

  getData(force = false): Promise<unknown> | void {
    const {
      networkUseblocks,
      ssidUseblocks,
      applicationUseblocks,
      actions,
      clientsByApplication,
      networkId,
      encryptedNetworkId,
      timespan,
      applicationId,
      ssidNumber,
    } = this.props;
    const { getClientsAndUseblocks, getNetworkUseblocks, getRuleUseblocks, getSsidUseblocks } =
      actions;
    const reqs: unknown[] = [];
    const ssidFilter = this.showingAllNetworks() ? -1 : ssidNumber;

    if (clientsByApplication.length === 0 || force) {
      reqs.push(getClientsAndUseblocks(timespan, ssidNumber));
    }

    if (this.showingAllNetworks()) {
      if (!networkUseblocks || force) {
        reqs.push(getNetworkUseblocks(networkId, encryptedNetworkId, timespan));
      }
    } else {
      if (!ssidUseblocks || force) {
        reqs.push(getSsidUseblocks(networkId, encryptedNetworkId, ssidFilter, timespan));
      }
    }

    if (!applicationUseblocks || force) {
      const fullGraphData = this.showingAllNetworks() ? networkUseblocks : ssidUseblocks;
      const src = nestedValueExists(fullGraphData, ["rusage_src", "19", applicationId], "");
      reqs.push(
        getRuleUseblocks(
          encryptedNetworkId,
          networkId,
          [applicationId],
          [src],
          timespan,
          ssidFilter,
        ),
      );
    }

    if (reqs.length > 0) {
      return Promise.all(reqs).catch(() => showNetworkErrorWithRetry(() => this.getData(true)));
    }
  }

  screenName = () => {
    const { application } = this.props;
    const { name } = application;

    let displayName;
    if (name === ApplicationTypes.miscSecWeb) {
      displayName = I18n.t("APPLICATION_NAME_SWAPS.MISC_SEC_WEB");
    } else if (name === ApplicationTypes.miscWeb) {
      displayName = I18n.t("APPLICATION_NAME_SWAPS.MISC_WEB");
    } else {
      displayName = name;
    }
    return displayName || I18n.t("APPLICATION_USAGE.UNKNOWN");
  };

  showingAllNetworks = () => {
    const { ssidNumber } = this.props;
    return !Number.isInteger(ssidNumber);
  };

  pushClientDetailsScreen(id: string) {
    const { navigation } = this.props;
    navigation.navigate("ClientDetails", {
      id,
    });
  }

  renderUsageCard(applicationName: string) {
    const {
      applicationUseblocks,
      applicationUsageIsFetching,
      networkUseblocks,
      networkUsageIsFetching,
      ssidName,
      ssidUseblocks,
      ssidUsageIsFetching,
      timespan,
      timezone,
    } = this.props;
    const fullGraphData = this.showingAllNetworks() ? networkUseblocks : ssidUseblocks;
    const netuseblocks = nestedValueExists(fullGraphData, ["netuseblocks"], null);
    if (
      !fullGraphData ||
      !netuseblocks ||
      !nestedValueExists(fullGraphData, ["t0"]) ||
      !nestedValueExists(fullGraphData, ["t1"])
    ) {
      return null;
    }

    const primaryData = formatUseBlocks(
      useblocksGraphseries(netuseblocks, fullGraphData.t0, fullGraphData.t1),
    );
    const secondaryData = formatUseBlocks(
      useblocksGraphseries(applicationUseblocks, fullGraphData.t0, fullGraphData.t1),
    );

    const usageSubheading = ssidName
      ? I18n.t("APPLICATION_DETAILS.USAGE_ONE_NETWORK_SUBHEADING", { applicationName })
      : I18n.t("APPLICATION_DETAILS.USAGE_ALL_NETWORKS_SUBHEADING", { applicationName });

    const t0Millis = fullGraphData.t0 * 1000;
    const t1Millis = fullGraphData.t1 * 1000;

    const { max, domain } = calculateMaxAndDomain(primaryData, t0Millis, t1Millis);

    const usageLoading =
      applicationUsageIsFetching || networkUsageIsFetching || ssidUsageIsFetching;

    return (
      <SummaryCard
        heading={I18n.t("APPLICATION_DETAILS.USAGE_HEADING")}
        subheading={usageSubheading}
        loading={usageLoading}
      >
        <MkiLineChart
          primaryData={primaryData}
          secondaryData={secondaryData}
          domain={domain}
          secondaryDataColor={MkiColors.secondaryGraphLine}
          xAxisFormatter={(t) => chartDateFormatter(t, timespan, timezone, true)}
          yAxisFormatter={formatBitsAxis(max)}
        />
      </SummaryCard>
    );
  }

  renderRow(rowData: ClientApplicationType) {
    return (
      <UsageRow
        {...rowData}
        color="applicationDetailsClientBar"
        onPress={() => this.pushClientDetailsScreen(rowData.id)}
      />
    );
  }

  renderTopClients() {
    const { clientsByApplication, clientsAndUseBlocksFetching } = this.props;
    const heading = I18n.t("TOP_CLIENTS.TITLE", { client_word: I18n.t("DEVICES_WORD") });

    if (clientsByApplication.length === 0) {
      return (
        <SummaryCard
          heading={heading}
          loading={clientsAndUseBlocksFetching}
          testID={"TOP_CLIENTS.NONE"}
        >
          <MkiText screenStyles={styles.noClientsText}>
            {I18n.t("TOP_CLIENTS.NO_CLIENTS", { client_word: I18n.t("DEVICES_WORD") })}
          </MkiText>
        </SummaryCard>
      );
    }

    const content = clientsByApplication.slice(0, 10);

    return (
      <SummaryList<ClientApplicationType>
        heading={heading}
        contentRows={content}
        loading={clientsAndUseBlocksFetching}
        customRenderRow={(rowData) => this.renderRow(rowData)}
        disableBottomBorder
      />
    );
  }

  render() {
    // TODO: add Timepicker to this screen
    const { navigation, ssidName, ssidNumber } = this.props;

    const description = this.screenName();

    return (
      <FullScreenContainerView>
        <ApplicationHeader
          description={description}
          ssidName={ssidName}
          connectionOnPress={() =>
            navigation.navigate("SSIDDetails", {
              ssidNumber,
            })
          }
          testID="APPLICATION_DETAILS.HEADER"
        />
        <RefreshControlScrollView
          testID="APPLICATION_DETAIL_SCROLL_VIEW"
          onRefresh={this.onRefresh}
        >
          {this.renderUsageCard(description)}
          {this.renderTopClients()}
        </RefreshControlScrollView>
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(
  state: RootState,
  props: ApplicationUsageScreensPropMap["ApplicationUsageDetails"],
): ReduxProps {
  const ssidNumber = props.ssidNumber ?? -1;
  const clientsByApplicationSelector =
    ssidNumber == null || ssidNumber < 0
      ? makeClientsByApplicationSelector()
      : makeClientsByApplicationForSSIDSelector(ssidNumber);
  const applicationTotalUsageSelector = makeApplicationTotalUsageSelector();

  return {
    networkName: getNetworkName(state),
    networkUseblocks: networkUseblocksSelector(state),
    networkUsageIsFetching: networkUseblocksLoadingState(state),
    ssidUseblocks: ssidUseblocksState(state, { ssidNumber: props.ssidNumber }),
    ssidUsageIsFetching: ssidUseblocksLoadingState(state),
    applicationUseblocks: applicationUseblocksSelector(state, props),
    applicationUsageIsFetching: applicationUseblocksLoadingState(state),
    clientsByApplication: clientsByApplicationSelector(state, props),
    clientsAndUseBlocksFetching: state.loading.clientsAndUseBlocksFetching,
    applicationTotalUsage: applicationTotalUsageSelector(state, props),
    encryptedNetworkId: errorMonitor.notifyNonOptional(
      "param 'encryptedNetworkId' undefined for ApplicationUsageDetailsScreen",
      encryptedNetworkIdSelector(state),
    ),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for ApplicationUsageDetailsScreen",
      currentNetworkState(state),
    ),

    timespan: timespanState(state),
    timezone: errorMonitor.notifyNonOptional(
      "param 'timezone' undefined for ApplicationUsageDetailsScreen",
      getNetworkTimezone(state),
    ),
    application: nestedValueExists(
      counterSetsState(state),
      ["19", "rules", props.applicationId],
      "",
    ),
  };
}

const styles = StyleSheet.create({
  noClientsText: {
    color: MkiColors.secondaryTextColor,
  },
  headerDetailsContainer: {
    paddingTop: SPACING.small,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  linkText: {
    color: MkiColors.primaryButton,
    alignItems: "center",
    paddingLeft: SPACING.small,
  },
});

export default connect(mapStateToProps, basicMapDispatchToProps)(ApplicationUsageDetailsScreen);
