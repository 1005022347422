import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import { Badge, BottomSheetMethods, Button, List } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  NetworkEventProductType,
  useClients,
  useNetworkEvent,
  useOrgNetworks,
} from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import { ALERTS_LIST_SEARCH_FIELDS, filterData } from "@meraki/shared/filters";
import { LineProductIcon, LineProductIconProps } from "@meraki/shared/product-icons";
import { useCurrentOrganizationId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { compareDesc } from "date-fns";
import { useMemo, useRef, useState } from "react";

import { findSliderIndexForKB, getGBForSliderIndex } from "../utils/AlertsUtils";

type FormattedEvent = {
  description: string;
  rawTime: string;
  labelTime: string;
  itemLabelTime: string;
  networkName: string;
  icon: JSX.Element;
  onPress?: () => void;
  testID?: string;
};

export const AlertsHistoryScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<AlertsStackProps>>();
  const organizationId = useCurrentOrganizationId();
  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);
  const timespan = useGlobalTimespan();

  const { data: networks } = useOrgNetworks({ organizationId });
  const [searchText, setSearchText] = useState("");

  const networkEventsDataForOrgRaw = useQueries({
    queries: networks?.map(({ id: networkId }) => useNetworkEvent.useQueries({ networkId })) ?? [],
  });

  const networkEventsDataForOrg = networkEventsDataForOrgRaw.map(({ data }) => data);
  const isLoading = networkEventsDataForOrgRaw.some(({ isLoading }) => isLoading);

  const clientsRaw = useQueries({
    queries:
      networks?.map(({ id: networkId }) => useClients.useQueries({ networkId, timespan })) ?? [],
  });

  const isLoadingEvents = networkEventsDataForOrgRaw.some(({ isLoading }) => isLoading);

  const clients = clientsRaw.flatMap(({ data }) => data);

  const currentNetworks = networks?.map((network) => network.name) ?? [];

  const formattedOrgEvents: FormattedEvent[] = useMemo(() => {
    return networkEventsDataForOrg?.flatMap((eventAlertsOnNetwork, index) => {
      if (eventAlertsOnNetwork === undefined) {
        return [];
      }

      return eventAlertsOnNetwork?.reduce<FormattedEvent[]>((result, eventAlertPerNetwork) => {
        const { alertTypeId } = eventAlertPerNetwork;
        const testID = alertTypeId.toUpperCase();
        const eventOccuredAt = eventAlertPerNetwork.occurredAt;

        const networkName = networks?.[index]?.name ?? "";
        const itemLabelTime = formatDate(eventOccuredAt, {
          dateFormat: "shortDate",
          timeFormat: "shortTime",
        });
        const rawTime = formatDate(eventOccuredAt, {
          dateFormat: "date",
          timeFormat: "longTime24HourForce",
        });
        const labelTime = formatDate(eventOccuredAt, { dateFormat: "longDate" });

        if (alertTypeId === "client_connectivity") {
          const currentClient = clients?.find((client) => {
            return client?.mac.toUpperCase() === eventAlertPerNetwork.alertData?.mac;
          });
          const status =
            eventAlertPerNetwork.alertData && eventAlertPerNetwork.alertData.connected === "true"
              ? "positive"
              : "negative";

          const clientName =
            currentClient?.description ||
            eventAlertPerNetwork.alertData?.mac?.toString() ||
            I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.NOT_FOUND");

          result.push({
            description:
              eventAlertPerNetwork.alertData && eventAlertPerNetwork.alertData.connected === "true"
                ? I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.CONNECTED", { clientName })
                : I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.DISCONNECTED", { clientName }),
            rawTime,
            itemLabelTime,
            labelTime,
            networkName,
            testID,
            icon: (
              <Box padding="xs">
                <Badge.Status badgeVisible status={status} size={20}>
                  <LineProductIcon name={"client"} />
                </Badge.Status>
              </Box>
            ),
            onPress: () =>
              navigation.navigate("ClientDetails", {
                id: currentClient?.id ?? "",
                networkId: networks?.[index]?.id,
              }),
          });
        }
        if (alertTypeId === "started_reporting" || alertTypeId === "stopped_reporting") {
          const hasStartedReporting = alertTypeId === "started_reporting";
          const deviceName = eventAlertPerNetwork?.device.name ?? "";
          const description = hasStartedReporting
            ? I18n.t("ALERTS_CENTER.STARTED_REPORTING", { deviceName })
            : I18n.t("ALERTS_CENTER.STOPPED_REPORTING", { deviceName });
          const status = hasStartedReporting ? "positive" : "negative";

          const productType = (product?: NetworkEventProductType): LineProductIconProps["name"] => {
            switch (product) {
              case "wireless":
                return "accessPoint";
              case "appliance":
              case "switch":
                return product;
              default:
                return "system";
            }
          };
          result.push({
            description,
            rawTime,
            itemLabelTime,
            labelTime,
            networkName,
            testID,
            icon: (
              <Box padding="xs">
                <Badge.Status badgeVisible status={status} size={20}>
                  <LineProductIcon name={productType(eventAlertPerNetwork.device.productType)} />
                </Badge.Status>
              </Box>
            ),
            onPress: () =>
              navigation.navigate("DeviceDetails", {
                serial: eventAlertPerNetwork?.device?.serial ?? "",
              }),
          });
        }
        if (eventAlertPerNetwork.alertTypeId === "weekly_presence") {
          const changeInNumber = Number(eventAlertPerNetwork?.alertData?.change);
          const description =
            changeInNumber >= 0
              ? I18n.t("ALERTS_CENTER.WEEKLY_PRESENCE.MORE", {
                  numOfVisitors: Math.abs(changeInNumber) ?? 0,
                })
              : I18n.t("ALERTS_CENTER.WEEKLY_PRESENCE.LESS", {
                  numOfVisitors: Math.abs(changeInNumber) ?? 0,
                });

          result.push({
            description,
            rawTime,
            itemLabelTime,
            labelTime,
            networkName,
            testID,
            icon: <Button.Icon icon="UsersThree" />,
            onPress: () =>
              navigation.navigate("PresenceReport", { networkId: networks?.[index]?.id }),
          });
        }
        if (eventAlertPerNetwork.alertTypeId === "weekly_umbrella") {
          result.push({
            description: I18n.t("ALERTS_CENTER.WEEKLY_UMBRELLA", {
              numOfCategories: Number(eventAlertPerNetwork.alertData?.length) ?? 0,
            }),
            rawTime,
            itemLabelTime,
            labelTime,
            networkName,
            testID,
            icon: <Button.Icon icon="Umbrella" />,
          });
        }
        if (eventAlertPerNetwork.alertTypeId === "usage_alert") {
          const threshold = eventAlertPerNetwork.alertData?.usageThreshold as number;
          const sliderIndex = findSliderIndexForKB(Number(threshold));
          result.push({
            description: I18n.t("ALERTS_CENTER.USAGE_ALERT", {
              usage: getGBForSliderIndex(sliderIndex),
            }),
            rawTime,
            itemLabelTime,
            labelTime,
            networkName,
            testID,
            icon: <Button.Icon icon="Gauge" />,
            onPress: () => {
              const { kbTotal: total, period } = eventAlertPerNetwork.alertData as {
                kbTotal: number;
                period: number;
              };
              navigation.navigate("NetworkUsageExceeded", {
                networkId: networks?.[index]?.id,
                occurredAt: eventAlertPerNetwork.occurredAt,
                period,
                threshold,
                total,
              });
            },
          });
        }

        return result;
      }, []);
    });
  }, [clients, navigation, networkEventsDataForOrg, networks]);

  const [networkFilters, setNetworkFilters] = useState<string[]>([]);

  const filteredEvents =
    networkFilters.length === 0
      ? formattedOrgEvents
      : formattedOrgEvents.filter(({ networkName }) => networkFilters.includes(networkName));

  const searchedEvents = filterData(
    filteredEvents,
    ALERTS_LIST_SEARCH_FIELDS,
    searchText,
  ) as FormattedEvent[];

  return (
    <Screen.View testID="ALERTS_SCREEN">
      <Box flexDirection="row" alignItems="center" gap="sm">
        <SearchFilterSection
          placeholder={I18n.t("ALERTS_CENTER.SEARCH_PLACEHOLDER")}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFilterPress={() => filterBottomSheetRef.current?.present()}
        />
      </Box>
      <FilterTagList
        filters={{
          networks:
            networkFilters?.map((networkName) => ({
              value: networkName,
              label: networkName,
            })) ?? [],
        }}
        onUpdateFilters={({ networks }) => setNetworkFilters(networks)}
      />
      <List.FlashList
        data={searchedEvents}
        emptyState={{
          title:
            formattedOrgEvents.length > 0
              ? I18n.t("ALERTS_CENTER.EMPTY.SEARCH")
              : I18n.t("ALERTS_CENTER.EMPTY.ORGANIZATION"),
        }}
        getItemData={(event) => {
          return {
            title: event.description,
            description: `${event.networkName} | ${event.itemLabelTime}`,
            leftAccessory: event.icon,
            onPress: event.onPress,
            testID: event.testID,
          };
        }}
        groupBy={(event) => event.labelTime}
        sortGroupBy={(event1, event2) => {
          return compareDesc(
            new Date(event1.data[0]?.rawTime ?? ""),
            new Date(event2.data[0]?.rawTime ?? ""),
          );
        }}
        loading={isLoading || isLoadingEvents}
        testID="ALERTS_LIST"
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          networks: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.NETWORK"),
            options: currentNetworks?.map((networkName) => ({
              value: networkName,
              label: networkName,
            })),
          },
        }}
        initialFilters={{
          networks: networkFilters,
        }}
        onUpdateFilters={({ networks }) => setNetworkFilters(networks)}
      />
    </Screen.View>
  );
};
