import { formatDate } from "@meraki/core/date";
import { secondsToMilliseconds } from "date-fns";
import { groupBy } from "lodash";
import { createSelector } from "reselect";

import { VideoChannel } from "~/api/schemas/VideoChannel";
import { FULL_REGION_OF_INTEREST } from "~/enterprise/constants/Camera";
import {
  CameraConfig,
  MotionEvent,
  MotionSearch,
  PointData,
  RoiArr,
  SearchInterval,
} from "~/enterprise/types/Cameras";
import { isEggplantWizardCamera } from "~/lib/HotspotUtilities";
import { defaultMotionSearch } from "~/reducers/cameras";
import { nodesByTypeSelector } from "~/selectors/devices";
import { isCameraOnlyAdmin } from "~/selectors/getters";
import { getDeviceWithFirmwareFromSerial } from "~/selectors/nodeGroups";
import Device, { GenericDevice } from "~/shared/types/Device";
import { MkiSelector } from "~/shared/types/Redux";

type NodeIdParam = { nodeId: string };

export const getCameraChannelByNodeId: MkiSelector<VideoChannel | undefined, NodeIdParam> = (
  state,
  { nodeId },
) => state.cameras.channels?.[nodeId];

export const getCameraConfigByNodeId: MkiSelector<CameraConfig | undefined, NodeIdParam> = (
  state,
  { nodeId },
) => state.cameras.configs?.[nodeId];

export type SearchParam = {
  nodeId: string;
  onSearch: (
    startTime: number,
    endTime: number,
    roi: RoiArr | null,
    showLoadingIndicator: boolean,
  ) => void;
};

// Saves a reference for reselect memoization
const EMPTY_ARRAY: any = [];

export const getMotionEventsByCameraId: MkiSelector<MotionEvent[], NodeIdParam> = (
  state,
  { nodeId },
) => state.cameras.motionEvents?.[nodeId] ?? EMPTY_ARRAY;

export const getPolygonPoints: MkiSelector<PointData[], NodeIdParam> = (state, { nodeId }) =>
  state.cameras.motionSearch[nodeId]?.polygonPoints ?? EMPTY_ARRAY;

export const getRegionOfInterest: MkiSelector<RoiArr, NodeIdParam> = (state, { nodeId }) =>
  state.cameras.motionSearch[nodeId]?.roi ?? FULL_REGION_OF_INTEREST;

const getMotionEventsInRange = (motionEvents: MotionEvent[], startTs: number, endTs: number) =>
  motionEvents?.filter((me) => {
    const eventEndTs = secondsToMilliseconds(me.ts) + me.duration_ms;
    const eventStartTs = secondsToMilliseconds(me.ts);
    return (
      eventStartTs < endTs && eventEndTs > startTs && me.duration_ms > 0 && eventStartTs >= startTs
    );
  }) ?? [];

export const getMostRecentMotionEventsFirst = createSelector(
  getMotionEventsByCameraId,
  (motionEvents) =>
    motionEvents
      ?.slice()
      .sort(
        (motionEvent1: MotionEvent, motionEvent2: MotionEvent) => motionEvent2.ts - motionEvent1.ts,
      ),
);

export const getMotionSearchFilters: MkiSelector<MotionSearch, NodeIdParam> = (
  state,
  { nodeId },
) => ({ ...defaultMotionSearch, ...state.cameras.motionSearch[nodeId] });

export const getMotionSearchInterval: MkiSelector<SearchInterval, NodeIdParam> = (
  state,
  { nodeId },
) => ({
  ...defaultMotionSearch.searchInterval,
  ...state.cameras.motionSearch[nodeId]?.searchInterval,
});

/**
 * Applies /motionEventFilters to all /motionEvents
 */
export const getFilteredMotionEvents = createSelector(
  getMostRecentMotionEventsFirst,
  getMotionSearchFilters,
  (motionEvents, filters) => {
    const { minEventLength, withPeople, sensitivity } = filters;
    const minEventLengthMs = secondsToMilliseconds(minEventLength);
    return motionEvents.filter((me) => {
      // Add more filters here
      const hasEnoughMotion = me.intensity_roi > (100 - sensitivity) / 100;
      const hasPeople = withPeople ? me.has_people : true;
      const hasMinDuration = me.duration_ms >= minEventLengthMs;

      return hasMinDuration && hasPeople && hasEnoughMotion;
    });
  },
);

/**
 * Groups filtered motion events by hour
 */
export const getSectionedMotionEvents = createSelector(
  getFilteredMotionEvents,
  (motionEvents: MotionEvent[]) => {
    const sections: { [date: string]: MotionEvent[] } = groupBy(motionEvents, (me: MotionEvent) => {
      return formatDate(me.ts, { dateFormat: "shortDate", timeFormat: "hourTime" });
    });
    return {
      eventCount: motionEvents.length,
      motionEvents: Object.keys(sections).map((key) => ({
        title: key,
        data: sections[key],
      })),
    };
  },
);

/**
 * Gets motion events where any part of the motion event is in the given millisecond range.
 * networkStartTs and networkEndTs params should be given in the MERAKI org local time (network), NOT in the
 * user local time (where the account is being accessed from).
 */
export const getMotionEventsInRangeForCameraId = createSelector(
  getFilteredMotionEvents,
  (_, { networkStartTs }: { networkStartTs: number }) => networkStartTs,
  (_, { networkEndTs }: { networkEndTs: number }) => networkEndTs,
  (motionEvents = [], networkStartTs, networkEndTs) => {
    return getMotionEventsInRange(motionEvents, networkStartTs, networkEndTs);
  },
);

export const isWirelesslyOnboardableCamera = createSelector(
  getDeviceWithFirmwareFromSerial,
  isCameraOnlyAdmin,
  (device: GenericDevice | undefined, isCameraOnlyAdmin: boolean) =>
    !isCameraOnlyAdmin && isEggplantWizardCamera(device),
);

export const areMotionEventsEmpty = createSelector(
  getMotionEventsByCameraId,
  (motionEvents) => motionEvents.length == 0,
);

export const selectAllCameras: MkiSelector<Device[]> = createSelector(
  nodesByTypeSelector,
  (devicesByType) => devicesByType.camera,
);
