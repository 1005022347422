import { Icon } from "@meraki/magnetic/icons";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import Color from "color";
import { isEmpty } from "lodash";
import { forwardRef, Ref } from "react";
import { StyleProp, TextInput, TextInputProps, View, ViewStyle } from "react-native";

import { Button } from "../Button/Button";

type SearchBarInternalSpecificProps = {
  placeholder?: string;
  focused: boolean;
  disabled?: boolean;
  showClear?: boolean;
};
export type SearchBarInternalProps = Omit<TextInputProps, "style" | "multiline"> &
  SearchBarInternalSpecificProps;

function getOuterBorderStyle(
  { focused, disabled }: SearchBarInternalSpecificProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let borderColor = "transparent";

  if (!disabled) {
    if (focused) {
      borderColor = Color(theme.color.control.bg.strong.base).alpha(0.25).hexa();
    }
  }

  return {
    borderRadius: 8,
    borderWidth: theme.SizeInputBorderStroke,
    borderColor,
    backgroundColor: borderColor,
  };
}

function getWrapperContainerStyle(
  { focused, disabled }: SearchBarInternalSpecificProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let borderColor = theme.color.control.bg.focus;
  let backgroundColor = theme.color.control.bg.focus;

  if (focused) {
    borderColor = theme.color.control.border.active;
  }

  if (disabled) {
    borderColor = theme.color.control.bg.disabled;
    backgroundColor = theme.color.control.bg.disabled;
  }

  return {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: theme.SizeInputBorderStroke,
    borderRadius: theme.SizeInputBorderRadius,
    borderColor,
    backgroundColor,
    gap: 5,
    paddingHorizontal: 5,
  };
}

function getTextInputStyle(theme: Theme) {
  return {
    flex: 1,
    fontFamily: "Inter",
    minHeight: 42,
    color: theme.color.default.text.base,
  };
}

function SearchBarInternalForward(
  {
    placeholder,
    focused,
    disabled,
    value,
    onChangeText,
    showClear = true,
    ...rest
  }: SearchBarInternalProps,
  ref: Ref<TextInput>,
) {
  const theme = useMagneticTheme();
  const internalProps = { focused, disabled };

  return (
    <View style={getOuterBorderStyle(internalProps, theme)}>
      <View style={getWrapperContainerStyle(internalProps, theme)}>
        <Icon name="MagnifyingGlass" color="control.icon.weak.base" />
        <TextInput
          {...rest}
          value={value}
          onChangeText={onChangeText}
          editable={!disabled}
          placeholder={placeholder ?? "Search"}
          style={getTextInputStyle(theme)}
          placeholderTextColor={theme.color.default.text.weak.base}
          ref={ref}
        />
        {showClear && !isEmpty(value) && onChangeText && focused && !disabled && (
          <Button.Icon icon="XCircle" onPress={() => onChangeText("")} />
        )}
      </View>
    </View>
  );
}

export const SearchBarInternal = forwardRef(SearchBarInternalForward);
