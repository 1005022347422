import { clearCookies, clearSavedCookies } from "@meraki/core/cookies";
import * as errorMonitor from "@meraki/core/errors";
import { LoginContext } from "@meraki/shared/api";
import {
  DISABLE_TWO_FACTOR_BACKUP_FAILURE,
  DISABLE_TWO_FACTOR_BACKUP_REQUEST,
  DISABLE_TWO_FACTOR_BACKUP_SUCCESS,
  DISABLE_TWO_FACTOR_FAILURE,
  DISABLE_TWO_FACTOR_REQUEST,
  DISABLE_TWO_FACTOR_SUCCESS,
  ENABLE_TWO_FACTOR_BACKUP_FAILURE,
  ENABLE_TWO_FACTOR_BACKUP_REQUEST,
  ENABLE_TWO_FACTOR_BACKUP_SUCCESS,
  ENABLE_TWO_FACTOR_FAILURE,
  ENABLE_TWO_FACTOR_REQUEST,
  ENABLE_TWO_FACTOR_SUCCESS,
  GET_LOGIN_SECURITY_FAILURE,
  GET_LOGIN_SECURITY_REQUEST,
  GET_LOGIN_SECURITY_SUCCESS,
  GET_TWO_FACTOR_SECRET_FAILURE,
  GET_TWO_FACTOR_SECRET_REQUEST,
  GET_TWO_FACTOR_SECRET_SUCCESS,
  LB_DISCONNECT,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REAUTHENTICATE_FOR_TWO_FACTOR_FAILURE,
  REAUTHENTICATE_FOR_TWO_FACTOR_REQUEST,
  REAUTHENTICATE_FOR_TWO_FACTOR_SUCCESS,
  RESET_UPDATE_REQUIRED,
  SEND_TWO_FACTOR_CODE_TO_BACKUP_FAILURE,
  SEND_TWO_FACTOR_CODE_TO_BACKUP_REQUEST,
  SEND_TWO_FACTOR_CODE_TO_BACKUP_SUCCESS,
  SET_LOGIN_SECURITY_FAILURE,
  SET_LOGIN_SECURITY_REQUEST,
  SET_LOGIN_SECURITY_SUCCESS,
  SET_RESET_TFA,
  SET_SSO_IN_PROGRESS,
  SET_UPDATE_REQUIRED,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  VERIFY_TWO_FACTOR_FAILURE,
  VERIFY_TWO_FACTOR_REQUEST,
  VERIFY_TWO_FACTOR_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { analytics } from "~/lib/FirebaseModules";
import { isWeb } from "~/lib/PlatformUtils";
import { generateDeleteCookieString } from "~/lib/WebUtils";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { getCurrentOrganization } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const showUpdateAlert = () => ({ type: SET_UPDATE_REQUIRED });
export const resetUpdateAlert = () => ({ type: RESET_UPDATE_REQUIRED });

export const updateGoPassword =
  (
    oldPassword: any,
    newPassword: any,
    newPasswordConfirmation: any,
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    };
    return dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE],
        endpoint: "/go/update_password",
        config: {
          method: Method.post,
          body: JSON.stringify(data),
        },
      }),
    );
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const logoutUser = (): AppThunk => (dispatch, _getState, cometd) => {
  return dispatch(
    wrapApiActionWithCSRF({
      types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
      endpoint: "/login/logout",
      config: { method: "POST" },
    }),
  ).then(async () => {
    analytics.logEvent("logout_manual");
    errorMonitor.setUser(); // clear user
    if (isWeb()) {
      document.cookie = generateDeleteCookieString("theme");
    }
    cometd.disconnect(() => {});
    dispatch({ type: LB_DISCONNECT });
    dispatch({ type: WIPE_REDUX });
    await clearCookies();
    clearSavedCookies();
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const getTwoFactorInfo = (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_TWO_FACTOR_SECRET_REQUEST,
        GET_TWO_FACTOR_SECRET_SUCCESS,
        GET_TWO_FACTOR_SECRET_FAILURE,
      ],
      endpoint: `/manage/users/sms_auth_app`,
      config: {
        method: Method.get,
      },
    },
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const enableTwoFactor = (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch) => {
  const data = { verify_continue: true, disable_sms: "true" };
  return dispatch(
    wrapApiActionWithCSRF({
      types: [ENABLE_TWO_FACTOR_REQUEST, ENABLE_TWO_FACTOR_SUCCESS, ENABLE_TWO_FACTOR_FAILURE],
      endpoint: "/manage/users/sms_auth_app",
      config: {
        method: Method.post,
        body: JSON.stringify(data),
      },
    }),
  );
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const verifyTwoFactorWorks =
  (code: any, phone = "app", forBackup = false): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [VERIFY_TWO_FACTOR_REQUEST, VERIFY_TWO_FACTOR_SUCCESS, VERIFY_TWO_FACTOR_FAILURE],
        endpoint: "/manage/users/sms_auth_verify",
        config: {
          method: Method.post,
          body: JSON.stringify({
            code,
            phone,
            which: forBackup ? "primary" : "app",
          }),
        },
      }),
    );
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const reauthforTwoFactor =
  (password: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          REAUTHENTICATE_FOR_TWO_FACTOR_REQUEST,
          REAUTHENTICATE_FOR_TWO_FACTOR_SUCCESS,
          REAUTHENTICATE_FOR_TWO_FACTOR_FAILURE,
        ],
        endpoint: `/manage/dashboard/reauthenticate`,
        config: {
          method: "POST",
          body: JSON.stringify({ password, format: "json" }),
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const disableTwoFactor = (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch) =>
  dispatch(
    wrapApiActionWithCSRF({
      types: [DISABLE_TWO_FACTOR_REQUEST, DISABLE_TWO_FACTOR_SUCCESS, DISABLE_TWO_FACTOR_FAILURE],
      endpoint: `/manage/users/sms_auth_disable`,
      config: {
        method: Method.post,
      },
    }),
  );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const sendTwoFactorBackupCode =
  (phone: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          SEND_TWO_FACTOR_CODE_TO_BACKUP_REQUEST,
          SEND_TWO_FACTOR_CODE_TO_BACKUP_SUCCESS,
          SEND_TWO_FACTOR_CODE_TO_BACKUP_FAILURE,
        ],
        endpoint: "/manage/users/send_code_xhr",
        config: {
          method: Method.post,
          body: JSON.stringify({ which: "primary", phone }),
        },
      }),
    );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const enableBackup = (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch) =>
  dispatch(
    wrapApiActionWithCSRF({
      types: [
        ENABLE_TWO_FACTOR_BACKUP_REQUEST,
        ENABLE_TWO_FACTOR_BACKUP_SUCCESS,
        ENABLE_TWO_FACTOR_BACKUP_FAILURE,
      ],
      endpoint: "/manage/users/sms_auth_enable",
      config: {
        method: Method.post,
      },
    }),
  );

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const removeBackup = (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch) =>
  dispatch(
    wrapApiActionWithCSRF({
      types: [
        DISABLE_TWO_FACTOR_BACKUP_REQUEST,
        DISABLE_TWO_FACTOR_BACKUP_SUCCESS,
        DISABLE_TWO_FACTOR_BACKUP_FAILURE,
      ],
      endpoint: "/manage/users/sms_auth_config",
      config: {
        method: Method.post,
        body: JSON.stringify({ sms_auth_state: "remove_primary" }),
      },
    }),
  );

export const fetchLoginSecurity =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());
    return dispatch({
      [CALL_API]: {
        types: [GET_LOGIN_SECURITY_REQUEST, GET_LOGIN_SECURITY_SUCCESS, GET_LOGIN_SECURITY_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/loginSecurity`,
        config: { method: Method.get },
        meta: { organizationId },
      },
    });
  };

export const updateLoginSecurity =
  (loginSecurityParams: any): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [SET_LOGIN_SECURITY_REQUEST, SET_LOGIN_SECURITY_SUCCESS, SET_LOGIN_SECURITY_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/loginSecurity`,
        config: { method: Method.put, body: JSON.stringify(loginSecurityParams) },
        meta: { organizationId },
      }),
    );
  };

export const setResetTFAFlow = (): AppThunk => (dispatch) =>
  dispatch({
    type: SET_RESET_TFA,
  });

export const setAuthFail = (error: string) => ({
  type: LOGIN_FAILURE,
  error,
});

export const setSSOInProgress = (isInProgress: boolean) => ({
  type: SET_SSO_IN_PROGRESS,
  isInProgress,
});

/**
 * this action stores the context needed to complete user login (orgEid, userId, and email).
 * It is used during email verification to maintain the state of the login-in-progress while
 * the user verifies their email and then is deeplinked back into the app, so that their
 * login can be completed without them needed to authenticate a second time. See commit
 * msg for more details.
 */
export const storeLoginContext = (context: LoginContext) => ({
  type: "STORE_VERIFY_EMAIL_CONTEXT",
  context,
});

/**
 * this action is the counterpart to the one above, and clears the login context upon
 * user login so it's not perpetuated indefinitely
 */
export const clearLoginContext = () => ({
  type: "CLEAR_VERIFY_EMAIL_CONTEXT",
});
