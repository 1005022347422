import { setupAlertPermissions } from "@meraki/shared/permissions";
import PushNotificationIOS from "@react-native-community/push-notification-ios";
// @ts-ignore next-frame does not have typescript
import nextFrame from "next-frame";
import { AppState, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import PushNotification from "react-native-push-notification";

export type PushNotificationData = {
  [key: string]: unknown;
  camera_id?: string;
  camera_network_eid?: string;
  event_type: string;
  ng_eid?: string;
  node_id?: number;
  timestamp?: number;
};

type NotificationCallback = (data: PushNotificationData) => void;

async function configureNotificationService(callback: NotificationCallback, senderID: string) {
  if (Platform.OS === "ios" && (await DeviceInfo.isEmulator())) {
    throw Error("Token is not generated when using the iOS simulator");
  }

  return await new Promise<string>((resolve, reject) => {
    PushNotification.configure({
      // onRegister does not get called on the iOS simulator
      onRegister: ({ token }: { token: string }) => {
        resolve(token);
      },
      onRegistrationError: (err: Error) => {
        reject(err);
      },
      onNotification: async (notification) => {
        if (Platform.OS === "android") {
          // Note: this computed value is a work around for `react-native-push-notification`
          // not sending a reliable `userInteraction` value every time
          // https://github.com/zo0r/react-native-push-notification/issues/1205#issuecomment-569374091
          await nextFrame();

          if (
            !notification.userInteraction &&
            (notification.foreground || AppState.currentState !== "active")
          ) {
            return;
          }
        }

        if (Platform.OS === "ios") {
          notification.finish(PushNotificationIOS.FetchResult.NoData);
        }

        // Android data may be in the root of the notification
        callback((notification.data ?? notification) as PushNotificationData);
      },
      // @ts-ignore this prop doesn't exist in type
      senderID,
    });
  });
}

export async function setupNotifications(
  callback: NotificationCallback,
  senderID: string,
  isManualRequest = false,
) {
  try {
    await setupAlertPermissions(isManualRequest);
  } catch (e) {
    // DM-3387: PushNotification.requestPermissions() could return undefined for android
    if (Platform.OS === "android") {
      return await configureNotificationService(callback, senderID);
    }
  }
  return await configureNotificationService(callback, senderID);
}
