import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useUpdatePassword } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import { Alert } from "react-native";

interface PasswordResetProps {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const PasswordMatchText = ({ status }: { status: "positive" | "negative" }) => (
  <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
    <Status status={status} />
    <Box paddingLeft="md">
      <Text color="light">
        {status === "positive"
          ? I18n.t("PASSWORD_RESET.PASSWORDS_MATCH")
          : I18n.t("PASSWORD_RESET.PASSWORDS_DONT_MATCH")}
      </Text>
    </Box>
  </Box>
);

export function PasswordResetScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const passwordUpdateRequest = useUpdatePassword();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const methods = useForm<PasswordResetProps>({
    values: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const onSave = methods.handleSubmit(({ oldPassword, newPassword, confirmNewPassword }) => {
    passwordUpdateRequest.mutate(
      { oldPassword, newPassword, confirmNewPassword },
      {
        onSuccess: () => {
          Alert.alert(
            I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.TITLE"),
            I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.SUBTITLE"),
            [
              {
                text: I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.CONFIRM"),
                onPress: () => navigation.goBack(),
              },
            ],
          );
        },
        onError: () => {
          Alert.alert(I18n.t("PASSWORD_RESET.MEET_REQUIREMENTS"));
        },
      },
    );
  });

  return (
    <Screen addDefaultPadding>
      <Heading size="h4">{I18n.t("PASSWORD_RESET.DESCRIPTION")}</Heading>
      <Box paddingBottom="none">
        <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.MINIUM_CHARACTERS")}`}</Text>
        <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.NO_COMMON")}`}</Text>
        <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.DIFFERENT_FROM_CURRENT")}`}</Text>
        <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.INCLUDES")}`}</Text>
        <Box paddingLeft="xs">
          <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.UPPER_LOWER_CASE")}`}</Text>
          <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.NUMBER")}`}</Text>
          <Text>{`\u2022 ${I18n.t("PASSWORD_RESET.REQUIREMENTS.SYMBOL")}`}</Text>
        </Box>
      </Box>
      <Form {...methods}>
        <Form.Input
          label={I18n.t("PASSWORD_RESET.OLD_PASSWORD")}
          name={"oldPassword"}
          rules={{ required: I18n.t("PASSWORD_RESET.REQUIRED") }}
          autoCapitalize="none"
          textContentType="newPassword"
          returnKeyType="next"
          autoComplete="new-password"
          secureTextEntry={passwordHidden}
          rightAccessory={
            <Button.Icon
              icon={passwordHidden ? "EyeSlash" : "Eye"}
              onPress={() => setPasswordHidden(!passwordHidden)}
            />
          }
          testID="OLD_PASSWORD_INPUT"
        />
        <Form.Input
          label={I18n.t("PASSWORD_RESET.NEW_PASSWORD")}
          name={"newPassword"}
          autoCapitalize="none"
          textContentType="newPassword"
          returnKeyType="next"
          autoComplete="new-password"
          secureTextEntry={passwordHidden}
          onSubmitEditing={() => methods.setFocus("confirmNewPassword")}
          rules={{
            required: I18n.t("PASSWORD_RESET.REQUIRED"),
            minLength: {
              value: 8,
              message: I18n.t("PASSWORD_RESET.REQUIREMENTS.MINIUM_CHARACTERS"),
            },
          }}
          rightAccessory={
            <Button.Icon
              icon={passwordHidden ? "EyeSlash" : "Eye"}
              onPress={() => setPasswordHidden(!passwordHidden)}
            />
          }
          testID="NEW_PASSWORD_INPUT"
        />
        <Form.Input
          label={I18n.t("PASSWORD_RESET.CONFIRM_NEW_PASSWORD")}
          name={"confirmNewPassword"}
          rules={{
            required: I18n.t("PASSWORD_RESET.REQUIRED"),
            min: 8,
            validate: (val: string) => {
              if (methods.watch("newPassword") !== val) {
                return I18n.t("PASSWORD_RESET.NO_MATCH");
              }
              return undefined;
            },
          }}
          autoCapitalize="none"
          textContentType="password"
          autoComplete="current-password"
          returnKeyType="next"
          secureTextEntry={passwordHidden}
          rightAccessory={
            <Button.Icon
              icon={passwordHidden ? "EyeSlash" : "Eye"}
              onPress={() => setPasswordHidden(!passwordHidden)}
            />
          }
          additionalContext={(() => {
            if (!methods.watch("confirmNewPassword")?.length) {
              return null;
            }
            return methods.watch("newPassword") === methods.watch("confirmNewPassword") ? (
              <PasswordMatchText status="positive" />
            ) : (
              <PasswordMatchText status="negative" />
            );
          })()}
          testID="CONFIRM_NEW_PASSWORD_INPUT"
        />
      </Form>
      <Button
        text={I18n.t("PASSWORD_RESET.TITLE")}
        onPress={onSave}
        testID="SUBMIT_PASSWORD_BUTTON"
      />
    </Screen>
  );
}
