import { I18n } from "@meraki/core/i18n";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TagInput from "react-native-tags-input";

import MkiColors from "~/constants/MkiColors";
import { BOX_BORDER_RADIUS, SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLES } from "~/constants/MkiTextStyles";
import { BORDER_COLORS } from "~/enterprise/constants/Colors";
import { appSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import { useThemeColors } from "~/shared/hooks/useTheme";
interface TagsInputComponentProps {
  onUpdate?: (tags: string[]) => void;
  tags?: string[];
  placeholder?: string;
  isReadOnly?: boolean;
}

interface TagsInputCardProps extends TagsInputComponentProps {
  title: string;
}

const DeleteButton = () => {
  const themeColors = useThemeColors();
  return (
    <MerakiIcon
      name="close"
      size="s"
      color={appSelect({
        enterprise: MkiColors.secondaryButton,
        go: themeColors.closeButton,
      })}
    />
  );
};

export const TagsInputComponent = ({
  tags,
  onUpdate,
  placeholder,
  isReadOnly,
}: TagsInputComponentProps) => {
  const [tagsInput, setTagsInput] = useState({ tagsArray: tags ?? [] });
  const [isFocused, setSetIsFocused] = useState(false);
  const colors = useThemeColors();

  const onFocus = () => {
    setSetIsFocused(true);
  };
  const onBlur = () => {
    setSetIsFocused(false);
  };

  const onUpdateState = (updatedTags: { tag: string; tagsArray: string[] }) => {
    setTagsInput(updatedTags);
    onUpdate?.(updatedTags.tagsArray);
  };

  const isReadOnlyProps = isReadOnly
    ? {
        disabled: true,
        hideInput: true,
        deleteElement: <></>,
      }
    : {};

  const hasNoTags = tags?.length === 0 && isReadOnly;
  const tagTextStyle = colors.text.default;
  const darkStyle = colors.roundedButtons.secondary.button;
  const placeholderTextColor = colors.text.placeholder.color;

  return hasNoTags ? (
    <MkiText textStyle="secondary">{I18n.t("TAG_GROUP.NO_TAGS")}</MkiText>
  ) : (
    <TagInput
      updateState={onUpdateState}
      tags={tagsInput}
      placeholder={placeholder}
      tagStyle={[styles.tagsStyle, darkStyle]}
      deleteElement={<DeleteButton />}
      autoCorrect={false}
      autoCapitalize={"none"}
      onFocus={onFocus}
      onBlur={onBlur}
      inputStyle={[
        tagTextStyle,
        styles.textInput,
        isFocused ? styles.textInputContainerFocused : styles.textInputContainer,
      ]}
      clearButtonMode="while-editing"
      tagTextStyle={tagTextStyle}
      placeholderTextColor={placeholderTextColor}
      {...isReadOnlyProps}
    />
  );
};

export const TagsInputCard = ({
  onUpdate,
  tags,
  isReadOnly,
  title,
  placeholder,
}: TagsInputCardProps) => {
  return (
    <View>
      <SummaryCard heading={title}>
        <TagsInputComponent
          onUpdate={onUpdate}
          tags={tags}
          placeholder={placeholder}
          isReadOnly={isReadOnly}
        />
      </SummaryCard>
    </View>
  );
};

const styles = StyleSheet.create({
  tagsStyle: appSelect({
    enterprise: {
      padding: 3,
      borderRadius: 4,
      backgroundColor: MkiColors.tagBackgroundColor,
      borderColor: MkiColors.tagBorderColor,
      borderWidth: 1,
      alignSelf: "flex-start",
    },
    go: {
      backgroundColor: MkiColors.primaryButtonLight,
      borderColor: MkiColors.goPurple,
      padding: 3,
      borderRadius: 4,
      alignSelf: "flex-start",
    },
  }),
  textInput: appSelect({
    enterprise: { ...TEXT_STYLES.textInput, padding: SPACING.default },
    go: {},
  }),
  textInputContainer: appSelect({
    enterprise: {
      borderWidth: 1,
      borderRadius: BOX_BORDER_RADIUS,
      borderColor: BORDER_COLORS.regular,
    },
    go: {},
  }),
  textInputContainerFocused: appSelect({
    enterprise: {
      borderWidth: 1,
      borderRadius: BOX_BORDER_RADIUS,
      borderColor: BORDER_COLORS.highlighted,
    },
    go: {},
  }),
});

export default TagsInputCard;
