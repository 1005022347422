import { ReactNode } from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { BUTTON_COLORS } from "~/enterprise/constants/Colors";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

export enum RoundedButtonType {
  primary,
  secondary,
  tertiary,
  warning,
  success,
}

export interface RoundedButtonProps {
  buttonType?: RoundedButtonType;
  onPress?: () => void;
  buttonStyle?: ViewStyle;
  disabled?: boolean;
  children?: ReactNode;
  testID?: string;
}

function RoundedButton({
  buttonType = RoundedButtonType.primary,
  buttonStyle: buttonStyleProp,
  children,
  disabled,
  onPress,
  testID,
}: RoundedButtonProps) {
  const colors = useThemeColors();
  const buttonStyleForType = (): ViewStyle => {
    switch (buttonType) {
      case RoundedButtonType.success:
        return styles.buttonSuccess;
      case RoundedButtonType.secondary:
        return {
          borderColor: colors.navigation.primary,
        };
      case RoundedButtonType.warning:
        return {
          backgroundColor: BUTTON_COLORS.warning.background,
          borderColor: colors.status.offline.color,
        };
      case RoundedButtonType.tertiary:
        return styles.buttonTertiary;
      case RoundedButtonType.primary:
      default:
        return {
          backgroundColor: colors.navigation.primary,
          borderColor: colors.navigation.primary,
        };
    }
  };

  const buttonStyle = (): ViewStyle | ViewStyle[] => {
    const buttonStyles = [styles.buttonBase, buttonStyleForType()];

    if (disabled) {
      buttonStyles.push(styles.disabled);
    }
    if (buttonStyleProp) {
      buttonStyles.push(buttonStyleProp);
    }

    return buttonStyles;
  };

  const textStyleForType = () => {
    switch (buttonType) {
      case RoundedButtonType.secondary:
        return { color: colors.navigation.primary };
      case RoundedButtonType.warning:
        return { color: colors.status.offline.color };
      case RoundedButtonType.tertiary:
        return styles.textTertiary;
      case RoundedButtonType.success:
        return styles.textSuccess;
      case RoundedButtonType.primary:
      default:
        return styles.textPrimary;
    }
  };

  const textStyle = () => [styles.textBase, textStyleForType()];

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
    >
      <View style={buttonStyle()}>
        <MkiText screenStyles={textStyle()}>{children}</MkiText>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  buttonBase: {
    alignItems: "center",
    borderRadius: BUTTON_SIZING.borderRadius.default,
    borderWidth: BUTTON_SIZING.borderWidth,
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
  buttonTertiary: {
    backgroundColor: BUTTON_COLORS.tertiary.background,
    borderColor: BUTTON_COLORS.tertiary.border,
  },
  buttonSuccess: {
    backgroundColor: BUTTON_COLORS.success.background,
    borderColor: BUTTON_COLORS.success.border,
  },
  container: {
    alignSelf: "stretch",
    paddingVertical: SPACING.small,
  },
  disabled: {
    opacity: 0.5,
  },
  textBase: {
    textAlign: "center",
  },
  textPrimary: {
    color: BUTTON_COLORS.primary.text,
  },
  textTertiary: {
    color: BUTTON_COLORS.tertiary.text,
  },
  textSuccess: {
    color: BUTTON_COLORS.success.text,
  },
});

export default RoundedButton;
