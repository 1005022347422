import { ContextOptions } from "../types";

export const LDFlags = {
  "ab-testing": "default value",
  "magnetic-halt-full-rollout": false,
  "create-account-flow": false,
  "enable-signup-marketing-emails": false,
  "mounting-photo-markup-feature": false,
  "motion-search-v2": false,
  "get-started-checklist": false,
  "view-ports-tab-first-on-switch-devices-screen": false,
  "local-device-settings": false,
  "uplink-loss-latency-charts": false,
  "alert-details-historical-status": false,
  "alert-details-settings-changed": false,
  "wireless-product-hub": false,
  "motion-event-alerts": false,
  "create-network-screen": false,
  "edit-network-screen": false,
  "scheduled-power-events": false,
  "organization-wide-context": false,
  "firmware-management-screen": false,
  "use-shared-auth": false,
  "shared-login-screen": false,
  "access-point-placement": false,
  "zod-schema-parsing": false,
  "show-cluster-dropdown": false,
  "has-camera-investigation": false,
};

export type LDFlag = typeof LDFlags;

export function getIdentityContext({ userId, email, organizationId }: ContextOptions) {
  if (!userId || !organizationId) {
    return { kind: "user", anonymous: true };
  }

  return {
    kind: "multi",
    user: { key: userId, email },
    organization: { key: organizationId },
  };
}
