export const toList = (stringArray: string[]) => {
  if (stringArray.length > 1) {
    return `${stringArray.slice(0, -1).join(", ")}${
      stringArray.length > 2 ? ", " : " "
    }and ${stringArray.slice(-1)}`;
  } else {
    return `${stringArray[0]}`;
  }
};

export const removeSpaces = (text: string) => text.replace(/\s/g, "");

export const addNewlinesWhereCommaDetected = (input: string) => input.replace(/ *, */g, "\n");

export function addHyphensForLicenseKey(key: string) {
  return key.match(/.{4}/g)?.join("-");
}

export const decimalToPercentString = (deci: number) => `${Number((deci * 100).toFixed(2))}%`;
