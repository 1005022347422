export interface GXPort extends GXPortLiveData, GXPortAPIData {
  number: number;
}

export interface GXPortAPIData {
  number: number;
  enabled?: boolean;
  type?: string;
  dropUntaggedTraffic?: boolean;
  vlan?: number;
  allowedVlans?: string;
  accessPolicy?: string;
}

export interface GXPortLiveData {
  carrier: boolean;
  duplex: boolean;
  id: string;
  node_id: string;
  speed: string;
  using_poe: boolean;
}

export enum Protocol {
  "tcp" = "tcp",
  "udp" = "udp",
  "icmp" = "icmp",
  "any" = "any",
}

export enum Policy {
  "allow" = "allow",
  "deny" = "deny",
}

export interface Rule {
  comment: string;
  policy: Policy;
  protocol: Protocol;
  destPort: number | "any";
  destCidr: string | "any";
  srcPort: number | "any";
  srcCidr: string | "any";
  syslogEnabled?: boolean;
}
