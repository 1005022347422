import { getProductType } from "@meraki/shared/devices";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { ONBOARDING_STEPS } from "~/constants/OnboardingSteps";
import {
  mapBatchClaimErrorsToRowData,
  mapOnboardingNodesToRowDataWithModel,
} from "~/lib/OnboardingMappings";
import { isWeb } from "~/lib/PlatformUtils";
import { devicesSelector } from "~/selectors/devices";
import { currentUserState, devicesState, getOnboardingState } from "~/selectors/getters";
import { currentUserPrefsState } from "~/selectors/preferences";
import { hasUmbrellaLicenseSelector } from "~/selectors/umbrella";
import Device, { DevicesBySerial } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";
import {
  OnboardingBatchClaimResult,
  OnboardingFlows,
  OnboardingNodes,
  OnboardingNodeStatus,
  OnboardingReducer,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";

export const hasUnfinishedNodes = (
  nodes: OnboardingNodes,
  nodesStatus: OnboardingNodeStatus,
): boolean => {
  if (isEmpty(nodes)) {
    return false;
  }
  return Object.keys(nodes).length > 0 && nodesStatus !== OnboardingNodeStatus.finished;
};

export const onboardingStep = (state: RootState) => currentUserPrefsState(state).onboardingStep;

export const onboardingIsComplete = createSelector(
  getOnboardingState,
  currentUserState,
  (onboarding: OnboardingReducer, user: string): boolean =>
    onboarding[user] ? onboarding[user].isComplete : false,
);

export const onboardingIsForced = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): boolean =>
    (user && state[user] && state[user].isForced) || false,
);

export const getOnboardingNodes = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): OnboardingNodes =>
    (user && state[user] && state[user].nodes) || {},
);

export const onboardingSkipped = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): boolean =>
    (user && state[user] && state[user].skipped) || false,
);

export const onboardingStage = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): OnboardingStage =>
    (user && state[user] && state[user].stage) || OnboardingStage.getStarted,
);

export const onboardingFlow = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): OnboardingFlows =>
    state?.[user]?.flow || OnboardingFlows.mainOnboarding,
);

export const onboardingStatus = createSelector(
  getOnboardingState,
  currentUserState,
  (state: OnboardingReducer, user: string): OnboardingNodeStatus =>
    (user && state[user] && state[user].status) || OnboardingNodeStatus.unstarted,
);

export const onboardingDevices = createSelector(
  getOnboardingNodes,
  devicesState,
  (nodes: OnboardingNodes, devices: DevicesBySerial) =>
    Object.keys(nodes)
      .map((key) => devices[key])
      .filter((result) => result),
);

export const isGXScanned = createSelector(onboardingDevices, (devices: Device[]): boolean =>
  devices.some((device) => getProductType(device.model) === ProductType.appliance),
);

export const showUmbrellaOnboarding = createSelector(
  isGXScanned,
  hasUmbrellaLicenseSelector,
  (gxScanned, hasUmbrellaLicense) => gxScanned && hasUmbrellaLicense,
);

export const onboardingStartTimeSelector = createSelector(
  getOnboardingState,
  currentUserState,
  (onboarding, user: string): number | null => onboarding?.[user]?.onboardingStartTime || null,
);

export const hasOnboardingNodesNeedingPolling = createSelector(
  getOnboardingNodes,
  onboardingStatus,
  (nodes, nodesStatus): boolean => {
    return hasUnfinishedNodes(nodes, nodesStatus);
  },
);

export const shouldShowOnboardingSelector = createSelector(
  devicesSelector,
  onboardingStep,
  onboardingSkipped,
  onboardingIsComplete,
  onboardingIsForced,
  onboardingStage,
  (devices, legacyStep, skipped, isComplete, isForced, stage) => {
    if (isForced) {
      return true;
    }

    if (!isEmpty(devices) || isWeb()) {
      return false;
    }

    if (legacyStep !== undefined) {
      return legacyStep !== ONBOARDING_STEPS.finished;
    } else {
      return stage !== OnboardingStage.exit && !skipped && !isComplete;
    }
  },
);

export const onboardingBatchClaimResult = createSelector(
  getOnboardingState,
  currentUserState,
  (onboarding: OnboardingReducer, user: string): OnboardingBatchClaimResult =>
    onboarding?.[user]?.batchClaimResult || {},
);

export const onboardingScannedNodeRowData = createSelector(
  devicesState,
  getOnboardingNodes,
  (devices: DevicesBySerial, onboardingNodes: OnboardingNodes) =>
    mapOnboardingNodesToRowDataWithModel(devices, onboardingNodes),
);

export const onbaordingErroredNodeRowData = createSelector(
  devicesState,
  onboardingBatchClaimResult,
  (devices: DevicesBySerial, batchClaimResult: OnboardingBatchClaimResult) =>
    mapBatchClaimErrorsToRowData(devices, batchClaimResult),
);
