import Tags from "~/shared/types/Tags";

export enum STPGuard {
  bpduGuard = "bpdu guard",
  disabled = "disabled",
  loopGuard = "loop guard",
  rootGuard = "root guard",
}

export interface SwitchPortState {
  [key: string]: SwitchPort;
}

export interface PublicSwitchPort {
  portId: string;
  name: string;
  tags: Tags;
  enabled: boolean;
  poeEnabled: boolean;
  type: "access" | "trunk";
  vlan: number;
  voiceVlan: number;
  isolationEnabled: boolean;
  rstpEnabled: boolean;
  stpGuard: string;
  linkNegotiation: string;
  portScheduleId: string;
  udld: string;
  accessPolicyType: string;
  stickyMacAllowList: string[];
  stickyMacAllowListLimit: number;
  stormControlEnabled: boolean;
}

export interface SwitchPort {
  connectedClients?: object;
  access_policy?: string;
  access_policy_number?: number;
  all_child_overrides?: object;
  allowed_vlans?: string;
  created_at: string;
  curr_status: number;
  dai_trusted?: boolean;
  dedicated_stack_port_num?: number;
  dual_media_preference?: string;
  enabled: boolean;
  expose_stp_topology_count?: boolean;
  expose_trusted?: boolean;
  first_status?: number[];
  has_isolation_display?: boolean;
  has_loopguard?: boolean;
  has_poe: boolean;
  has_storm_control?: boolean;
  has_stp?: boolean;
  has_udld?: boolean;
  id: string;
  is_configurable_stack_port?: boolean;
  is_dedicated_stack_port?: boolean;
  is_dual_media?: boolean;
  is_isolated?: boolean;
  is_mirror_dest_port?: boolean;
  is_mirror_src_port?: boolean;
  is_stackport?: boolean;
  is_trunk?: boolean;
  is_uplink?: boolean;
  link_negotiation?: string;
  link_negotiation_options?: any[];
  local_overrides?: any;
  mac_whitelist?: string;
  name?: string;
  native_vid?: number | string;
  node_profile_id?: string;
  node_profile_id_of_parent_node?: string;
  num: number[];
  port_schedule_id?: string;
  profile?: string;
  rstpEnabled?: boolean;
  rx_bcast_pkts?: number;
  rx_bytes?: number;
  rx_crc_align_err?: number;
  rx_drops?: number;
  rx_frags?: number;
  rx_jabbers?: number;
  rx_mcast_pkts?: number;
  rx_oversize_pkts?: number;
  rx_pkts?: number;
  schedule?: any;
  scheduled_outage?: any;
  stack?: string;
  stack_id?: any;
  status?: any[];
  sticky_whitelist_limit?: any;
  storm_control?: boolean;
  stp_flags?: number;
  stp_guard?: STPGuard;
  switch?: string;
  switch_id: string;
  switch_model?: string;
  switch_port_module_id?: any;
  tags?: any;
  tx_bcast_pkts?: number;
  tx_bytes?: number;
  tx_collisions?: number;
  tx_drops?: number;
  tx_mcast_pkts?: number;
  tx_pkts?: number;
  type: string;
  udld_mode?: string;
  updated_at?: string;
  usageStatus?: number;
  use_poe?: boolean;
  use_sticky_whitelist?: boolean;
  use_stp?: boolean;
  use_whitelist?: boolean;
  vid?: number | string;
  voice_vid?: number | string;
}

export enum SwitchPortType {
  trunk = "trunk",
  access = "access",
}
