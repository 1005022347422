import * as errorMonitor from "@meraki/core/errors";
import { registerRootComponent } from "expo";
import { ComponentType } from "react";
import { gestureHandlerRootHOC } from "react-native-gesture-handler";

import { isWeb } from "~/lib/PlatformUtils";

function AppRegistryWrapper<P>(Component: ComponentType<P>) {
  return registerRootComponent(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ComponentType<P>' is not assigna... Remove this comment to see the full error message
    errorMonitor.wrap(isWeb() ? Component : gestureHandlerRootHOC(Component)),
  );
}

export default AppRegistryWrapper;
