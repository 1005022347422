import { useCallback, useState } from "react";

export interface StepStatuses {
  active: boolean;
  completed: boolean;
  disabled: boolean;
}

interface useStepperReturnType {
  currentStep: number;
  onGoBack: () => void;
  onCompleteAndGoNext: () => void;
  onToggleStepDisabled: (stepNumber: number) => void;
  resetStepper: () => void;
  stepStatuses: StepStatuses[];
}

export const useStepper = (numOfSteps = 3): useStepperReturnType => {
  if (__DEV__) {
    if (numOfSteps > 9) {
      throw new Error("Mobile stepper can only have a maximum of 9 steps");
    } else if (numOfSteps < 1) {
      throw new Error("Invalid stepper configuration");
    }
  }

  const initialState = Array.from({ length: numOfSteps }).map((_, index): StepStatuses => {
    if (index === 0) {
      return {
        active: true,
        completed: false,
        disabled: false,
      };
    }
    return {
      active: false,
      completed: false,
      disabled: false,
    };
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [stepStatuses, setStepStatuses] = useState<StepStatuses[]>(initialState);

  const resetStepper = () => {
    setCurrentStep(1);
    setStepStatuses(initialState);
  };

  const onGoBack = useCallback(() => {
    if (currentStep > 1) {
      let prevStep = currentStep - 1;
      let prevStepIndexReversed = numOfSteps - prevStep;
      setStepStatuses((prevStepStatuses) => {
        const newStatusesReversed = prevStepStatuses.reverse().map((step, index) => {
          if (index === prevStepIndexReversed) {
            const newCurrentStepStatus = { ...step, active: true };

            if (step.disabled) {
              prevStep--;
              prevStepIndexReversed++;
            } else {
              return newCurrentStepStatus;
            }
          }

          return { ...step, active: false };
        });

        setCurrentStep(prevStep);
        return newStatusesReversed.reverse();
      });
    }
  }, [currentStep, numOfSteps]);

  const onCompleteAndGoNext = useCallback(() => {
    if (currentStep < numOfSteps) {
      let nextStep = currentStep + 1;
      setStepStatuses((prevStepStatuses) => {
        const newStatuses = prevStepStatuses.map((step, index) => {
          if (index === currentStep - 1) {
            return { ...step, active: false, completed: true };
          }

          if (index === nextStep - 1) {
            const newCurrentStepStatus = { ...step, active: true };
            if (step.disabled) {
              nextStep++;
            } else {
              return newCurrentStepStatus;
            }
          }

          return { ...step, active: false };
        });

        setCurrentStep(nextStep);
        return newStatuses;
      });
    }
  }, [currentStep, numOfSteps]);

  const onToggleStepDisabled = useCallback(
    (stepNumber: number) => {
      // the first and last step cannot be disabled
      if (stepNumber > 1 && stepNumber < numOfSteps) {
        setStepStatuses((prevStepStatuses) =>
          prevStepStatuses.map((step, index: number) => {
            if (index === stepNumber - 1) {
              return { ...step, disabled: !step.disabled };
            }
            return step;
          }),
        );
      }
    },
    [numOfSteps],
  );

  return {
    currentStep,
    onGoBack,
    onCompleteAndGoNext,
    onToggleStepDisabled,
    resetStepper,
    stepStatuses,
  };
};
