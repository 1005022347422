import { Button } from "@meraki/magnetic/components";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { TextStyle } from "react-native";

import { DebugAnalytics } from "./DebugAnalytics";
import { DebugCamera } from "./DebugCamera";
import { DebugFeatureFlags } from "./DebugFeatureFlags";
import { DebugScreen } from "./DebugScreen";
import { DebugTheme } from "./DebugTheme";

const Stack = createNativeStackNavigator();

export function DebugNavigator() {
  const navigation = useNavigation();
  const theme = useMagneticTheme();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.color.default.bg.base },
        headerShadowVisible: true,
        headerBackTitleVisible: false,
        headerTransparent: false,
        headerTitleAlign: "center",
        headerTitle: "",
        headerBackTitle: "",
        headerTintColor: theme.color.default.text.medium.base,
        headerTitleStyle: {
          fontFamily: "Sharp Sans",
          color: theme.color.default.text.medium.base,
          fontWeight: `${theme.TypographyWeightBold}` as TextStyle["fontWeight"],
          fontSize: theme.SizeFontMd,
        },
        headerRight: () => <Button.Nav text="Close" onPress={() => navigation.goBack()} />,
      }}
    >
      <Stack.Screen
        name="DebuggingViewMain"
        component={DebugScreen}
        options={{ headerTitle: "Debug" }}
      />
      <Stack.Screen
        name="DebuggingViewTheme"
        component={DebugTheme}
        options={{ headerTitle: "Debug Theme" }}
      />
      <Stack.Screen
        name="DebuggingViewAnalytics"
        component={DebugAnalytics}
        options={{ headerTitle: "Debug Analytics" }}
      />
      <Stack.Screen
        name="DebuggingViewCamera"
        component={DebugCamera}
        options={{ headerTitle: "Debug Camera" }}
      />
      <Stack.Screen
        name="DebuggingViewFeatureFlags"
        component={DebugFeatureFlags}
        options={{ headerTitle: "Debug Feature Flags" }}
      />
    </Stack.Navigator>
  );
}
