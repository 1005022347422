import { I18n } from "@meraki/core/i18n";
import { LineChart } from "@meraki/magnetic/charts";
import { Card, List, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  ClientUsageHistory,
  useNetworkClientsApplicationUsage,
  useNetworkClientsUsageHistories,
} from "@meraki/shared/api";
import { PickerCard } from "@meraki/shared/components";
import {
  formatAndParseKibibytes,
  formatKibibytes,
  processClientRusage,
  useCachedTimestampFormatter,
} from "@meraki/shared/formatters";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { isEmpty } from "lodash";
import { useState } from "react";

import { useClientDetails } from "../ClientDetailsContext";

const getDataPointsForClient = (clientUsageHistories?: ClientUsageHistory[]) => {
  return (
    clientUsageHistories?.map((usageHistory) => ({
      timestamp: Date.parse(usageHistory.ts),
      upload: usageHistory.sent,
      download: usageHistory.received,
    })) ?? []
  );
};

export const InsightsTab = () => {
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();
  const [cachedTimespan, setCachedTimespan] = useState(timespan);
  const { client } = useClientDetails();
  const timespanFormatter = useCachedTimestampFormatter(timespan);

  const { data: clientApplicationUsage, isLoading } = useNetworkClientsApplicationUsage({
    networkId,
    clients: client?.id,
    timespan: cachedTimespan,
  });
  const { data: clientUsageHistories, isLoading: clientUsageHistoriesLoading } =
    useNetworkClientsUsageHistories(
      {
        networkId,
        clients: client?.id,
        timespan: cachedTimespan,
      },
      { select: (clientHistory) => getDataPointsForClient(clientHistory[0]?.usageHistory) },
    );
  const processedUsage =
    clientApplicationUsage && processClientRusage(clientApplicationUsage[0]?.applicationUsage);
  return (
    <Screen addDefaultPadding testID="INSIGHTS_TAB">
      <PickerCard.Time
        currentCustomTimespan={cachedTimespan}
        onCustomTimespanChange={setCachedTimespan}
      />
      {clientUsageHistories && !isEmpty(clientUsageHistories) && (
        <Card label={I18n.t("CLIENT_USAGE.CHART.TITLE")}>
          <Card.Content>
            <Box flexDirection="row" alignItems="baseline">
              <Text size="p1" weight="bold">
                {`${formatAndParseKibibytes(client?.usage?.total ?? 0).value} `}
              </Text>
              <Text size="p2" weight="bold" color="light">
                {formatAndParseKibibytes(client?.usage?.total ?? 0).unit}
              </Text>
            </Box>
            <Text size="p1" weight="bold" color="light">
              {I18n.t("CLIENT_USAGE.CHART.HEADING")}
            </Text>
            <LineChart
              size="lg"
              data={clientUsageHistories}
              dimensions={[
                { name: "timestamp" },
                { name: "download", displayName: I18n.t("CLIENT_USAGE.CHART.DOWNLOAD") },
                { name: "upload", displayName: I18n.t("CLIENT_USAGE.CHART.UPLOAD") },
              ]}
              yAxisFormatter={(value) => formatKibibytes(Number(value))}
              yAxisMin={0}
              xAxisFormatter={timespanFormatter}
              showAnimation={true}
              loading={clientUsageHistoriesLoading}
              testID="CLIENT_USAGE_CHART"
            />
          </Card.Content>
        </Card>
      )}
      <List.FlashList
        paddingBottom="none"
        paddingTop="none"
        paddingLeft="none"
        paddingRight="none"
        label={I18n.t("CLIENT_USAGE.HEADING")}
        data={processedUsage}
        getItemData={(usage) => {
          return {
            title: usage.applicationName,
            description: I18n.t("USAGE_ROW.enterprise.PERCENT_OF_USAGE", {
              usage: `${usage.formattedUsage} ${usage.unit}`,
              percentage: `${usage.percentUsage}%`,
            }),
          };
        }}
        emptyState={{ title: I18n.t("CLIENT_USAGE.EMPTY") }}
        loading={isLoading}
      />
    </Screen>
  );
};
