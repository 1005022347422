export enum AdminAccountStatus {
  unverified = "unverified",
  ok = "ok",
}

export enum OrgAccess {
  full = "full",
  read = "read-only",
  none = "none",
}

export type NetworkAccess = "Full" | "Read Only" | "Monitor Only" | "Guest Ambassador";

export interface AdminInfo {
  accountStatus: AdminAccountStatus;
  authenticationMethod: string;
  email: string;
  hasApiKey: boolean;
  id: string;
  lastActive: string;
  name: string;
  networks: string[];
  orgAccess: OrgAccess;
  tags: string[];
  twoFactorAuthEnabled: boolean;
}

export interface AdminsItems {
  [userId: string]: AdminInfo;
}

export enum AuthUserType {
  GUEST = "Guest",
  RADIUS = "802.1X",
  CLIENT_VPN = "Client VPN",
}

export interface Authorization {
  id: string;
  type: AuthUserType;
  ssidNumber?: number;
  expiresAt: string;
}

export interface AuthorizationsByEmail {
  [userEmail: string]: Authorization[];
}

export interface AuthsOnNetwork {
  clientVPNEnabled: boolean;
}

export interface AuthsOnNetworkByEmail {
  [userEmail: string]: AuthsOnNetwork;
}

export interface NetworkPrivilege {
  access: NetworkAccess;
  id: string;
  name?: string;
}

export interface NetworkPrivilegeRequest {
  access: string;
  id: string;
  name?: string;
}
