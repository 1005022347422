import { I18n } from "@meraki/core/i18n";
import { Clipboard, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withOnboardingStatus from "~/hocs/OnboardingData";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { ALERT_BUTTONS, showAlert } from "~/lib/AlertUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { userNeedsTFA } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { TwoFactorScreensPropMap } from "../navigation/Types";

type ReduxProps = {
  forcingTwoFactor: boolean;
};

type Props = ForwardedNativeStackScreenProps<TwoFactorScreensPropMap, "SuccessTwoFactor"> &
  BaseOnboardingScreenProps &
  BasicActions &
  ReduxProps &
  WithCancelablePromiseProps &
  PendingComponent;

export interface SuccessTwoFactorScreenState {
  showCopySuccess: boolean;
}

export class SuccessTwoFactorScreen extends BaseOnboardingScreen<
  Props,
  SuccessTwoFactorScreenState
> {
  state: SuccessTwoFactorScreenState = { showCopySuccess: false };

  static defaultProps = {
    oneTimeCodes: [],
    isOnboarding: false,
  };

  copyCodesToClipboard = () => {
    const { oneTimeCodes } = this.props;
    Clipboard.setString(oneTimeCodes.join(" "));
    this.setState({ showCopySuccess: true });
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("SUCCESS_TWO_FACTOR.HEADER.TITLE")}
        description={I18n.t("SUCCESS_TWO_FACTOR.HEADER.DESCRIPTION")}
      />
    );
  };

  renderTwoFactorSuccess = () => {
    return (
      <View style={styles.checkCircleStyle}>
        <MerakiIcon name="check-circle" color={MkiColors.confirmAlertSuccess} size="xl" />
      </View>
    );
  };

  renderOneTimeCodes = () => {
    const { oneTimeCodes } = this.props;
    const leftColumn: JSX.Element[] = [];
    const rightColumn: JSX.Element[] = [];

    for (const [idx, code] of oneTimeCodes.entries()) {
      (idx % 2 ? leftColumn : rightColumn).push(
        <MkiText key={`${idx}`} screenStyles={styles.codeStyle}>
          {code}
        </MkiText>,
      );
    }

    return (
      <View style={styles.oneTimeCodesContainer}>
        <View style={styles.oneTimeCodesColumn}>{leftColumn}</View>
        <View style={styles.oneTimeCodesColumn}>{rightColumn}</View>
      </View>
    );
  };

  renderCopyToClipboardButton = () => {
    const { showCopySuccess } = this.state;
    return (
      <RoundedButton
        buttonType={ButtonType.secondary}
        onPress={this.copyCodesToClipboard}
        screenStyles={styles.buttonStyle}
        containerStyles={styles.buttonContainer}
      >
        {showCopySuccess ? I18n.t("COPIED") : I18n.t("COPY_TO_CLIPBOARD")}
      </RoundedButton>
    );
  };

  openTwoFactorModal = () => {
    const { navigation } = this.props;
    navigation.navigate("IntroTwoFactor", { isOnboarding: false });
  };

  onNextStep = async () => {
    const { navigation, completeOnboarding, forcingTwoFactor, setReqPending, actions } = this.props;
    if (forcingTwoFactor) {
      setReqPending(true);
      try {
        await actions.logoutUser();
      } finally {
        setReqPending(false);
        navigation.popToTop();
      }
    } else {
      await completeOnboarding();
      showAlert(
        I18n.t("SUCCESS_TWO_FACTOR.RECOMMEND_BACKUP.TITLE"),
        I18n.t("SUCCESS_TWO_FACTOR.RECOMMEND_BACKUP.DESCRIPTION"),
        () => this.openTwoFactorModal(),
        {
          positiveText: ALERT_BUTTONS.ok,
          negativeText: ALERT_BUTTONS.cancel,
        },
      );
    }
  };

  getFooterData = () => {
    const { forcingTwoFactor } = this.props;

    return {
      buttons: [
        <RoundedButton onPress={() => this.onNextStep()} key="two_factor">
          {forcingTwoFactor
            ? capitalizeFirstLetter(I18n.t("LOG_OUT"))
            : I18n.t("ONBOARDING_FULLSTACK.SETUP_COMPLETE.NEXT_BUTTON")}
        </RoundedButton>,
      ],
    };
  };

  renderBody = () => {
    return (
      <FullScreenContainerView withScroll>
        {this.renderHeader()}
        {this.renderTwoFactorSuccess()}
        {this.renderOneTimeCodes()}
        {this.renderCopyToClipboardButton()}
      </FullScreenContainerView>
    );
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.successTwoFactor);
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  checkCircleStyle: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
  oneTimeCodesContainer: {
    flex: 1,
    flexDirection: "row",
    padding: SPACING.default,
    alignContent: "space-between",
  },
  oneTimeCodesColumn: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignContent: "space-around",
  },
  codeStyle: {
    flex: 1,
    padding: SPACING.small,
    height: "50%",
    width: "50%",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    forcingTwoFactor: userNeedsTFA(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
  withCancelablePromise,
  withPendingComponent,
)(SuccessTwoFactorScreen);
