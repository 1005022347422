import { I18n } from "@meraki/core/i18n";

type LinkSpeedTitleDescriptionType = {
  [key: string]: {
    title: string;
    description?: string;
    forced: boolean;
  };
};

export const getLinkSpeedTitleDescription = (): LinkSpeedTitleDescriptionType => ({
  "1 Gigabit full duplex (forced)": {
    title: "1 Gbps",
    description: I18n.t("LINK_SPEED.FULL_DUPLEX"),
    forced: true,
  },
  "100 Megabit (auto)": {
    title: "100 Mbps",
    description: I18n.t("LINK_SPEED.AUTO_DUPLEX"),
    forced: false,
  },
  "100 Megabit half duplex (forced)": {
    title: "100 Mbps",
    description: I18n.t("LINK_SPEED.HALF_DUPLEX"),
    forced: true,
  },
  "100 Megabit full duplex (forced)": {
    title: "100 Mbps",
    description: I18n.t("LINK_SPEED.FULL_DUPLEX"),
    forced: true,
  },
  "10 Megabit (auto)": {
    title: "10 Mbps",
    description: I18n.t("LINK_SPEED.AUTO_DUPLEX"),
    forced: false,
  },
  "10 Megabit half duplex (forced)": {
    title: "10 Mbps",
    description: I18n.t("LINK_SPEED.HALF_DUPLEX"),
    forced: true,
  },
  "10 Megabit full duplex (forced)": {
    title: "10 Mbps",
    description: I18n.t("LINK_SPEED.FULL_DUPLEX"),
    forced: true,
  },
  "Auto negotiate": {
    title: I18n.t("LINK_SPEED.AUTO_NEGOTIATE"),
    description: undefined,
    forced: false,
  },
});
