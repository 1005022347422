import Svg, { Circle, Path, SvgProps } from "react-native-svg";

function MagneticCameraIcon(props: SvgProps) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        opacity="0.7"
        d="M2.5 5C2.5 4.44772 2.94772 4 3.5 4H20.5C21.0523 4 21.5 4.44772 21.5 5C21.5 5.55228 21.0523 6 20.5 6H3.5C2.94771 6 2.5 5.55228 2.5 5Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 13C21 18.0625 16.9706 22 12 22C7.02944 22 3 18.0625 3 13V9C3 8.44772 3.44772 8 4 8H20C20.5523 8 21 8.44772 21 9V13ZM12 19.5C14.7614 19.5 17 17.2614 17 14.5C17 11.7386 14.7614 9.5 12 9.5C9.23858 9.5 7 11.7386 7 14.5C7 17.2614 9.23858 19.5 12 19.5Z"
        fill="#8F8F8F"
      />
      <Circle cx="12" cy="14.5" r="3" fill="#8F8F8F" />
    </Svg>
  );
}

export default MagneticCameraIcon;
