import Svg, { Circle, Path } from "react-native-svg";

function MagneticEnvironmentalIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0828 5.42472C12.1729 4.87984 12.6877 4.51119 13.2326 4.60133C16.3886 5.12343 18.8777 7.61244 19.3997 10.7685C19.4899 11.3134 19.1212 11.8282 18.5764 11.9183C18.0315 12.0084 17.5167 11.6398 17.4266 11.0949C17.0442 8.78372 15.2174 6.95685 12.9062 6.57451C12.3613 6.48438 11.9926 5.9696 12.0828 5.42472ZM5.42484 12.0826C5.96972 11.9925 6.4845 12.3612 6.57464 12.906C6.95697 15.2172 8.78384 17.0441 11.095 17.4264C11.6399 17.5166 12.0086 18.0314 11.9184 18.5762C11.8283 19.1211 11.3135 19.4898 10.7686 19.3996C7.61256 18.8775 5.12355 16.3885 4.60145 13.2324C4.51132 12.6876 4.87996 12.1728 5.42484 12.0826Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8341 1.95205C11.8794 1.40162 12.3622 0.9921 12.9127 1.03735C18.2607 1.47704 22.5232 5.73951 22.9629 11.0876C23.0081 11.638 22.5986 12.1209 22.0482 12.1661C21.4978 12.2114 21.0149 11.8019 20.9696 11.2514C20.6101 6.8786 17.1216 3.39013 12.7488 3.03063C12.1984 2.98537 11.7888 2.50248 11.8341 1.95205ZM1.9523 11.8339C2.50272 11.7886 2.98562 12.1981 3.03087 12.7486C3.39038 17.1214 6.87885 20.6099 11.2517 20.9694C11.8021 21.0146 12.2116 21.4975 12.1664 22.0479C12.1211 22.5984 11.6382 23.0079 11.0878 22.9626C5.73976 22.523 1.47728 18.2605 1.0376 12.9124C0.992344 12.362 1.40187 11.8791 1.9523 11.8339Z"
        fill="#8F8F8F"
      />
      <Circle cx="12" cy="12" r="3" fill="#8F8F8F" />
    </Svg>
  );
}

export default MagneticEnvironmentalIcon;
