import { z } from "zod";

import { request } from "../../../api/request/request";
import { PortVlanSchema } from "../../../schemas";
import { createQuery } from "../../createQuery";

export const AccessPolicyTypeSchema = z
  .union([
    z.literal("Open"),
    z.literal("Custom access policy"),
    z.literal("MAC allow list"),
    z.literal("Sticky MAC allow list"),
  ] as const)
  .describe("AccessPolicyTypeSchema");

export const SwitchPortSchema = PortVlanSchema.extend({
  portId: z.string(),
  name: z.string().nullable(),
  tags: z.array(z.string()),
  enabled: z.boolean(),
  poeEnabled: z.boolean(),
  isolationEnabled: z.boolean(),
  rstpEnabled: z.boolean(),
  stpGuard: z.string(),
  linkNegotiation: z.string(),
  linkNegotiationCapabilities: z.array(z.string()),
  portScheduleId: z.string().nullable(),
  udld: z.string(),
  accessPolicyType: AccessPolicyTypeSchema,
  macAllowList: z.array(z.string()).optional(),
  stickyMacAllowList: z.array(z.string()).optional(),
  stickyMacAllowListLimit: z.number().optional(),
  stormControlEnabled: z.boolean().optional(),
}).describe("SwitchPortSchema");
export const SwitchPortsSchema = z.array(SwitchPortSchema);

export type SwitchPort = z.infer<typeof SwitchPortSchema>;

interface SwitchPortRequest {
  serial?: string;
}

const buildUrl = ({ serial }: SwitchPortRequest) => {
  return `/api/v1/devices/${serial}/switch/ports`;
};

const fetchSwitchPorts = ({ serial }: SwitchPortRequest): Promise<SwitchPort[]> => {
  return request(SwitchPortsSchema, "GET", buildUrl({ serial }));
};

export const useSwitchPorts = createQuery<SwitchPortRequest, SwitchPort[]>({
  baseQueryKey: buildUrl({ serial: `{serial}` }),
  queryFn: (variables) => fetchSwitchPorts(variables),
  requiredVariables: ["serial"],
});
