import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  size?: number;
};

function TemperatureIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  const { stroke } = useThemeColors().icon;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M8.99976 13.7814V4.50012C8.99976 3.70447 9.31583 2.94141 9.87843 2.3788C10.441 1.81619 11.2041 1.50012 11.9998 1.50012C12.7954 1.50012 13.5585 1.81619 14.1211 2.3788C14.6837 2.94141 14.9998 3.70447 14.9998 4.50012V13.7814C15.7999 14.4061 16.3851 15.2649 16.6736 16.2382C16.9622 17.2114 16.9398 18.2504 16.6094 19.2103C16.2791 20.1702 15.6574 21.003 14.831 21.5925C14.0047 22.1821 13.0149 22.499 11.9998 22.499C10.9846 22.499 9.99485 22.1821 9.16848 21.5925C8.34211 21.003 7.72039 20.1702 7.39007 19.2103C7.05975 18.2504 7.03731 17.2114 7.32587 16.2382C7.61444 15.2649 8.19962 14.4061 8.99976 13.7814V13.7814Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.999 19.5C13.0346 19.5 13.874 18.6605 13.874 17.625C13.874 16.5895 13.0346 15.75 11.999 15.75C10.9635 15.75 10.124 16.5895 10.124 17.625C10.124 18.6605 10.9635 19.5 11.999 19.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.999 15.75V8.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default TemperatureIcon;
