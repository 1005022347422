import { Dimensions } from "react-native";

import { WINDOW } from "~/constants/MkiConstants";

const WINDOW_WIDTH = Dimensions.get(WINDOW).width;

export default function getScreenFormattedHeight(animation: any) {
  if (!animation) {
    return 0;
  }
  const aspectRatio = animation.h / animation.w;
  return WINDOW_WIDTH * aspectRatio;
}
