import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Checked({ disabled }: IconProps) {
  const theme = useMagneticTheme();

  if (disabled) {
    return (
      <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={3}
          fill={theme.color.control.bg.weak.disabled}
        />
        <Path
          d="M15.2072 7.20718L8.50008 13.9143L4.79297 10.2072L6.20718 8.79297L8.50008 11.0859L13.793 5.79297L15.2072 7.20718Z"
          fill={theme.color.control.iconIn.disabled}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <Rect
          x={1}
          y={1}
          width={18}
          height={18}
          rx={3}
          stroke={theme.color.control.border.disabled}
          strokeWidth={2}
        />
      </Svg>
    );
  }

  return (
    <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
      <Rect width={20} height={20} rx={4} fill={theme.color.control.bg.strong.base} />
      <Path
        d="M15.2072 7.20718L8.50008 13.9143L4.79297 10.2072L6.20718 8.79297L8.50008 11.0859L13.793 5.79297L15.2072 7.20718Z"
        fill={theme.color.control.iconIn.active}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
}
