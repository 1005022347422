import Gradient, { GradientProps } from "~/shared/components/icons/Gradient";

type LEDProps = Omit<GradientProps, "path">;

export const LEDIcon = (props: LEDProps) => {
  const { name } = props;
  const blinkingLED =
    "M18.5,6H5.5a5.5,5.5,0,0,0,0,11h13a5.5,5.5,0,0,0,0-11ZM12,14a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,12,14Z";
  const solidLED = "M18.5,6H5.5a5.5,5.5,0,0,0,0,11h13a5.5,5.5,0,0,0,0-11Z";

  const path = name === "blinkingLED" ? blinkingLED : solidLED;

  return <Gradient path={path} {...props} />;
};

export default LEDIcon;
