import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import {
  PortForwardingRule,
  useCreateVlan,
  usePortForwardingRules,
  useSiteToSiteVPNSettings,
  useUpdatePortForwardingRules,
  useUpdateSiteToSiteVPNSettings,
  useUpdateVlan,
  useVlans,
} from "@meraki/shared/api";
import {
  calculateNumberOfIPAddresses,
  calculateSubnetRange,
  getNextValidIPv4Subnet,
} from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import InputRow from "~/go/rows/InputRow";
import SubnetMaskSelectionRow from "~/go/rows/SubnetMaskSelectionRow";
import { Features } from "~/go/types/ContextHelpTypes";
import { IPv6 } from "~/go/types/NetworksTypes";
import { Modes } from "~/go/types/SiteToSiteVPN";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { isIPv6Capable } from "~/lib/DeviceUtils";
import { addNewlinesWherePeriodCommaDetected } from "~/lib/formatHelper";
import {
  calculateNetworkAddress,
  extractNetworkPrefix,
  extractSubnetMask,
  findInvalidAddressesForGivenRange,
  getNetworkIdFromSubnetIp,
} from "~/lib/IPAddressUtils";
import { platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import { getAllSiteToSiteSettings, getIsNetworkSecured, gxDeviceSelector } from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";

import { NetworkScreensPropMap } from "../navigation/Types";

const TOGGLE_SWITCH_KEYS = ["isSecureNetworkEnabled", "useSiteToSiteVPN", "useIpv6"];

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "ConfigureVlan">;

interface VlanConfig {
  name: string;
  applianceIp: string;
  subnetMask: string;
  ipAddress: string | null;
  id: string;
  isSecureNetworkEnabled: boolean;
  useSiteToSiteVPN: boolean;
  useIpv6?: boolean;
}

export const ConfigureVlanScreen = ({ vlan, ipv6Subnet }: Props) => {
  const isDefaultVlan = vlan?.id === 1;
  const isNewVlan = vlan == null;

  const networkId = useCurrentNetworkId();
  const isNetworkSecured = useAppSelector((state) => getIsNetworkSecured(state, vlan?.subnet));

  const { data: siteToSiteVPNSettings } = useSiteToSiteVPNSettings({ networkId });
  const updateSiteToSiteVPNSettings = useUpdateSiteToSiteVPNSettings();
  const { theme } = useTheme();
  const { data: portForwardRules } = usePortForwardingRules(
    { networkId },
    {
      enabled: Boolean(networkId),
    },
  );
  const updatePortForwardingRules = useUpdatePortForwardingRules();
  const orgSiteToSiteVPNSettings = errorMonitor.notifyNonOptional(
    "param 'orgSiteToSiteVPNSettings' undefined for ConfigureVlanScreen",
    useAppSelector(getAllSiteToSiteSettings),
  );
  const securityAppliance = errorMonitor.notifyNonOptional(
    "param 'securityAppliance' undefined for ConfigureVlanScreen",
    useAppSelector(gxDeviceSelector),
  );

  const navigation = useNavigation();
  const actions = useActions();
  const queryClient = useQueryClient();

  const { data: vlans } = useVlans({ networkId });
  const createVlan = useCreateVlan();
  const updateVlan = useUpdateVlan();

  const allVlanSubnetRanges =
    vlans?.reduce((result, { subnet }) => {
      if (subnet) {
        const ranges = calculateSubnetRange(subnet);
        if (ranges != null) {
          result.push(ranges);
        }
      }
      return result;
    }, [] as number[][]) ?? [];

  const isIpv6Compatible = isIPv6Capable(securityAppliance);
  const validVlanInfo = getNextValidIPv4Subnet(orgSiteToSiteVPNSettings, allVlanSubnetRanges);
  const ipMaskSplit = validVlanInfo.subnet.split("/");
  const useSiteToSiteVPN =
    !!vlan &&
    !!siteToSiteVPNSettings?.subnets?.some(
      (subnetSettings) => subnetSettings.localSubnet === vlan.subnet && subnetSettings.useVpn,
    );

  const [vlanOnState, setVlanOnState] = useState(vlan);
  const [reqPending, setReqPending] = useState(false);
  const [stagedValues, setStagedValues] = useState<VlanConfig>({
    name: vlan?.name ?? "",
    applianceIp: vlan?.applianceIp ?? validVlanInfo.applianceIp,
    subnetMask: vlan ? String(extractSubnetMask(vlan.subnet)) : "24",
    ipAddress: vlan ? extractNetworkPrefix(vlan.subnet) : ipMaskSplit[0],
    useSiteToSiteVPN: vlan ? useSiteToSiteVPN : false,
    id: vlan ? String(vlan.id) : "",
    isSecureNetworkEnabled: vlan ? isNetworkSecured : false,
    useIpv6: vlan ? vlan.ipv6?.enabled : isIpv6Compatible ? false : undefined,
  });

  const getSubnet = useCallback(() => {
    const { subnetMask, ipAddress } = stagedValues;
    return ipAddress ? `${ipAddress}/${subnetMask}` : "";
  }, [stagedValues]);

  const getStagedIpv6Data = (stagedUseIpv6: any): IPv6 => {
    return {
      enabled: stagedUseIpv6,
    };
  };

  const createNewVlan = useCallback(async () => {
    try {
      setReqPending(true);

      const subnet = getSubnet();
      if (!subnet) {
        showAlert(I18n.t("ERROR"), I18n.t("CONFIGURE_VLAN.IP.ERROR"));
        return;
      }

      const { id, name, applianceIp, useSiteToSiteVPN, useIpv6 } = stagedValues;
      const newVlan: any = {
        id: parseInt(id),
        name,
        applianceIp,
        subnet,
      };

      if (useIpv6 != null) {
        newVlan["ipv6"] = getStagedIpv6Data(useIpv6);
      }

      await createVlan.mutateAsync(
        { networkId, vlan: newVlan },
        {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot }),
          onError: (error) => {
            showAlert(
              I18n.t("ERROR"),
              addNewlinesWherePeriodCommaDetected(error.errors?.join(",")),
            );
          },
        },
      );

      if (stagedValues.isSecureNetworkEnabled) {
        await actions.addVlanSecurity(subnet);
      }

      if (siteToSiteVPNSettings?.mode === Modes.hub) {
        updateSiteToSiteVPNSettings.mutate({
          networkId,
          params: {
            mode: Modes.hub,
            subnets: [
              {
                localSubnet: subnet,
                useVpn: useSiteToSiteVPN,
              },
            ],
          },
        });
      }

      navigation.goBack();
    } catch (error) {
      showAlert(I18n.t("ERROR"), addNewlinesWherePeriodCommaDetected(error));
    } finally {
      setReqPending(false);
    }
  }, [
    actions,
    createVlan,
    getSubnet,
    navigation,
    networkId,
    queryClient,
    siteToSiteVPNSettings?.mode,
    stagedValues,
    updateSiteToSiteVPNSettings,
  ]);

  const confirmRemoveConflictingPortForwardingRules = useCallback(
    (invalidPortForwardRuleIps: string[]) => {
      const rulesToBeDeleted = [] as PortForwardingRule[];
      const rulesToKeep = [] as PortForwardingRule[];

      portForwardRules?.rules.forEach((rule) => {
        if (invalidPortForwardRuleIps.includes(rule.lanIp)) {
          rulesToBeDeleted.push(rule);
        } else {
          rulesToKeep.push(rule);
        }
      });

      const portForwardRuleNames = rulesToBeDeleted
        .flatMap((portForwardRule) => portForwardRule.name)
        .join(", ");

      showAlert(
        I18n.t("CONFIGURE_VLAN.PORT_FORWARD_ERROR.TITLE"),
        I18n.t("CONFIGURE_VLAN.PORT_FORWARD_ERROR.MESSAGE", { portForwardRuleNames }),
        () => {
          setReqPending(true);
          updatePortForwardingRules.mutate(
            { networkId: networkId, rules: rulesToKeep },
            {
              onError: (error) => {
                console.warn(error);
              },
              onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: usePortForwardingRules.queryKeyRoot });
              },
              onSettled: () => {
                setReqPending(false);
              },
            },
          );
        },
        {
          positiveText: I18n.t("CONFIGURE_VLAN.CONFIRM"),
          negativeText: I18n.t("CONFIGURE_VLAN.CANCEL"),
        },
      );
    },
    [networkId, portForwardRules?.rules, queryClient, updatePortForwardingRules],
  );

  const updateExistingVlan = useCallback(async () => {
    if (!vlanOnState) {
      return;
    }

    const subnet = getSubnet();
    if (!subnet) {
      showAlert(I18n.t("ERROR"), I18n.t("CONFIGURE_VLAN.IP.ERROR"));
      return;
    }

    try {
      setReqPending(true);

      const { id, name, applianceIp, useSiteToSiteVPN, isSecureNetworkEnabled, useIpv6 } =
        stagedValues;

      const updatedVlanData: any = {
        name,
        applianceIp,
        subnet,
      };

      // check to see if subnet has been modified
      if (portForwardRules && vlanOnState.subnet != updatedVlanData.subnet) {
        // get the networkId from old Ip  and compare with
        // port forward ips to get list of affected rules
        // and then get their Ip addresses
        const unmodifiedIpNetworkId = getNetworkIdFromSubnetIp(vlanOnState.subnet);

        const affectedPortForwardIpAddresses = portForwardRules.rules
          .filter(
            (portForwardRule) =>
              getNetworkIdFromSubnetIp(portForwardRule.lanIp) === unmodifiedIpNetworkId,
          )
          .map((portForwardRule) => portForwardRule.lanIp);

        if (affectedPortForwardIpAddresses.length) {
          const invalidPortForwardRuleIps = findInvalidAddressesForGivenRange(
            affectedPortForwardIpAddresses,
            updatedVlanData.subnet,
          );

          // if ports not valid, show alert and stop execution
          if (invalidPortForwardRuleIps.length) {
            confirmRemoveConflictingPortForwardingRules(invalidPortForwardRuleIps);
            showAlert(I18n.t("ERROR"), I18n.t("CONFIGURE_VLAN.PORT_FORWARD_ERROR.MESSAGE"));
            return;
          }
        }
      }

      if (useIpv6 != null) {
        updatedVlanData["ipv6"] = getStagedIpv6Data(useIpv6);
      }

      // TODO: This logic should really be done with actions batches; however, this resource
      // is currently not supported by action batches.
      //
      // To make configuration changes work, we need to delete and recreate the vlan security
      // settings so there are no backend validation errors when making changes to the VLAN.
      if (isSecureNetworkEnabled === true) {
        await actions.removeVlanSecurity(vlanOnState.subnet);
      } else if (isSecureNetworkEnabled === false) {
        await actions.removeVlanSecurity(vlanOnState.subnet);
      }

      // if new Vlan ID
      if (vlanOnState.id !== parseInt(id)) {
        const result = await actions.changeVlanId(vlanOnState.id, { ...updatedVlanData, id });
        setVlanOnState(result.response.actions[1].body); // second action batch response
        actions.setSelectedVlanId(parseInt(id));
        if (!result.response?.status?.failed) {
          showAlert(I18n.t("SUCCESS"), I18n.t("CONFIGURE_VLAN.SUCCESS_MESSAGE"));
        }
      } else {
        await updateVlan.mutateAsync(
          {
            networkId,
            vlanId: vlanOnState.id,
            vlan: updatedVlanData,
          },
          {
            onSuccess: (data) => {
              setVlanOnState(data);
              showAlert(I18n.t("SUCCESS"), I18n.t("CONFIGURE_VLAN.SUCCESS_MESSAGE"));
              queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot });
            },
            onError: (error) => {
              showAlert(
                I18n.t("ERROR"),
                addNewlinesWherePeriodCommaDetected(error.errors?.join(",")),
              );
            },
          },
        );
      }

      if (siteToSiteVPNSettings?.mode === Modes.hub) {
        updateSiteToSiteVPNSettings.mutate({
          networkId,
          params: {
            mode: Modes.hub,
            subnets: [
              {
                localSubnet: subnet,
                useVpn: useSiteToSiteVPN,
              },
            ],
          },
        });
      }

      // TODO: This logic should really be done with actions batches; however, this resource
      // is currently not supported by action batches.
      //
      // To make configuration changes work, we need to delete and recreate the vlan security
      // settings so there are no backend validation errors when making changes to the VLAN.
      if (isSecureNetworkEnabled === true) {
        await actions.addVlanSecurity(subnet);
      } else if (isSecureNetworkEnabled === false) {
        await actions.removeVlanSecurity(subnet);
      }
    } catch (error) {
      // TODO: This check to recreate security rules is not needed if we use
      // action batches.
      //
      // If the network previously had network security, and a request fails
      // after removing it, we need to recreatethe security rules.
      if (isNetworkSecured) {
        await actions.addVlanSecurity(vlanOnState.subnet);
      }
      showAlert(I18n.t("ERROR"), addNewlinesWherePeriodCommaDetected(error));
    } finally {
      setReqPending(false);
    }
  }, [
    actions,
    confirmRemoveConflictingPortForwardingRules,
    getSubnet,
    isNetworkSecured,
    networkId,
    portForwardRules,
    queryClient,
    siteToSiteVPNSettings?.mode,
    stagedValues,
    updateSiteToSiteVPNSettings,
    updateVlan,
    vlanOnState,
  ]);

  const hasChanges = useCallback(() => {
    if (!vlanOnState) {
      return true;
    }

    const { applianceIp, subnet, name, id, ipv6 } = vlanOnState;
    const subnetMask = String(extractSubnetMask(subnet));

    return (
      name !== stagedValues.name ||
      id !== parseInt(stagedValues.id) ||
      applianceIp !== stagedValues.applianceIp ||
      extractNetworkPrefix(subnet) !== stagedValues.ipAddress ||
      subnetMask !== stagedValues.subnetMask ||
      isNetworkSecured !== stagedValues.isSecureNetworkEnabled ||
      useSiteToSiteVPN !== stagedValues.useSiteToSiteVPN ||
      ipv6?.enabled !== stagedValues.useIpv6
    );
  }, [isNetworkSecured, stagedValues, useSiteToSiteVPN, vlanOnState]);

  const save = useCallback(async () => {
    if (reqPending) {
      return;
    }

    if (isNewVlan) {
      await createNewVlan();
    } else {
      await updateExistingVlan();
    }
  }, [createNewVlan, isNewVlan, reqPending, updateExistingVlan]);

  const close = useCallback(() => {
    if (isNewVlan && hasChanges()) {
      showSaveWarning(save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  }, [hasChanges, isNewVlan, navigation, save]);

  useLayoutEffect(() => {
    const hasEmptyValues = Object.entries(stagedValues).some(([key, value]) =>
      TOGGLE_SWITCH_KEYS.includes(key) ? false : !value,
    );
    const isSaveEnabled = !hasEmptyValues && hasChanges();

    navigation.setOptions({
      headerTitle: vlan ? I18n.t("CONFIGURE_VLAN.TITLE_CONFIGURE") : undefined,
      headerLeft: () => <CancelButton onPress={close} />,
      headerRight: () => <SaveButton onPress={save} disabled={!isSaveEnabled} />,
    });
  }, [close, hasChanges, navigation, save, stagedValues, vlan]);

  const onChangeForm = useCallback(
    (change: Partial<VlanConfig>) => {
      setStagedValues({
        ...stagedValues,
        ...change,
      });
    },
    [stagedValues],
  );

  const onChangeSecureNetwork = useCallback(
    (change: boolean) => onChangeForm({ isSecureNetworkEnabled: change }),
    [onChangeForm],
  );

  const loadFirewallRules = useCallback(async () => {
    try {
      setReqPending(true);
      await actions.fetchL3FirewallRules();
    } catch (error) {
      console.warn(error);
    } finally {
      setReqPending(false);
    }
  }, [actions]);

  useEffect(() => {
    loadFirewallRules();
  }, [loadFirewallRules]);

  const renderIpSectionHeader = (title: string, reducedBottom: boolean) => {
    const headerStyling = reducedBottom
      ? [styles.ipAddressContainer, styles.sectionHeaderReduced]
      : [styles.sectionHeader, styles.ipAddressContainer];

    return (
      <View style={headerStyling}>
        <MkiText textStyle="secondary">{title}</MkiText>
      </View>
    );
  };

  const renderSectionSeperator = useCallback(() => {
    if (!isIpv6Compatible) {
      return;
    }

    const borderColor = themeColors(theme).border.borderColor;
    return (
      <View
        style={[styles.sectionSeperator, styles.ipAddressContainer, { borderColor: borderColor }]}
      />
    );
  }, [isIpv6Compatible, theme]);

  const onChangeName = (change: string) => onChangeForm({ name: change });
  const onChangeId = (change: string) => onChangeForm({ id: change });
  const onChangeIpv6 = (change: boolean) => onChangeForm({ useIpv6: change });
  const onChangeUseVPN = (change: boolean) => onChangeForm({ useSiteToSiteVPN: change });

  const onChangeApplianceIp = useCallback(
    (change: string) => {
      onChangeForm({
        applianceIp: change,
        ipAddress: calculateNetworkAddress(change, stagedValues.subnetMask),
      });
    },
    [onChangeForm, stagedValues.subnetMask],
  );

  const onChangeSubnetMask = useCallback(
    (change: string) => {
      onChangeForm({
        subnetMask: change,
        ipAddress: calculateNetworkAddress(stagedValues.applianceIp, change),
      });
    },
    [onChangeForm, stagedValues.applianceIp],
  );

  const totalIps = calculateNumberOfIPAddresses(parseInt(stagedValues.subnetMask));
  const otherSectionVisible = !isDefaultVlan || siteToSiteVPNSettings?.mode === Modes.hub;

  return (
    <FullscreenContainerView withScroll>
      <View style={styles.verticalContainer}>
        {isNewVlan && (
          <MkiText textStyle={"subheading"} screenStyles={styles.subheading}>
            {I18n.t("CONFIGURE_VLAN.SUBHEADING")}
          </MkiText>
        )}
        {isDefaultVlan && (
          <BannerAlert
            alertType={GoStatus.warning}
            alertText={I18n.t("CONFIGURE_VLAN.BANNER")}
            screenStyles={styles.warningBanner}
          />
        )}
        <InputRow
          value={stagedValues.name}
          description={I18n.t("CONFIGURE_VLAN.NAME_DESCRIPTION")}
          placeholder={I18n.t("CONFIGURE_VLAN.NAME_PLACEHOLDER")}
          onChangeText={onChangeName}
          editable={!isDefaultVlan}
          testID={"VLAN_NAME"}
          clearTestID={"CLEAR_NAME"}
        >
          {I18n.t("CONFIGURE_VLAN.NAME")}
        </InputRow>
        <InputRow
          keyboardType={"numeric"}
          value={stagedValues.id}
          placeholder={I18n.t("CONFIGURE_VLAN.ID_PLACEHOLDER")}
          onChangeText={onChangeId}
          context={Features.vlanID}
          editable={!isDefaultVlan}
          testID={"VLAN_ID"}
          clearTestID={"CLEAR_ID"}
        >
          {I18n.t("CONFIGURE_VLAN.ID")}
        </InputRow>
        {renderIpSectionHeader(I18n.t("CONFIGURE_VLAN.IPV4_HEADING"), true)}
        <View style={styles.ipAddressContainer}>
          <View style={[styles.horizontalContainer, styles.ipAddressInput]}>
            <MkiText textStyle="smallSecondary">{I18n.t("CONFIGURE_VLAN.IP.GX")}</MkiText>
            <ContextHelp context={Features.gxIPAddress} />
          </View>
          <IPAddressTextInput
            cidrAddress={stagedValues.applianceIp}
            onAddressChange={onChangeApplianceIp}
            leaveEditableBlank={false}
          />
        </View>
        <SubnetMaskSelectionRow
          value={stagedValues.subnetMask}
          onApplySubnetMask={onChangeSubnetMask}
        />
        <MkiText textStyle="smallSecondary" screenStyles={styles.subnetCountStyles}>
          {I18n.t("CONFIGURE_VLAN.SUBNET_COUNT", { address_count: totalIps })}
        </MkiText>
        <MkiText textStyle="smallSecondary" screenStyles={styles.subnetCountStyles}>
          {`${I18n.t("CONFIGURE_VLAN.SUBNET")}: ${getSubnet()}`}
        </MkiText>
        {isIpv6Compatible && renderSectionSeperator()}
        {isIpv6Compatible && renderIpSectionHeader(I18n.t("CONFIGURE_VLAN.IPV6_HEADING"), false)}
        {isIpv6Compatible && (
          <>
            <SwitchRow
              value={stagedValues.useIpv6}
              onValueChange={onChangeIpv6}
              screenStyles={styles.switchRow}
            >
              {I18n.t("CONFIGURE_VLAN.IPV6")}
            </SwitchRow>
            <MkiText textStyle="smallSecondary" screenStyles={styles.subnetCountStyles}>
              {`${I18n.t("CONFIGURE_VLAN.SUBNET")}: ${ipv6Subnet}`}
            </MkiText>
          </>
        )}
        {otherSectionVisible && renderSectionSeperator()}
        {otherSectionVisible && renderIpSectionHeader(I18n.t("CONFIGURE_VLAN.OTHER"), false)}
        {siteToSiteVPNSettings?.mode === Modes.hub && (
          <SwitchRow
            value={stagedValues.useSiteToSiteVPN}
            onValueChange={onChangeUseVPN}
            screenStyles={styles.switchRow}
          >
            {I18n.t("CONFIGURE_VLAN.SITE_TO_SITE_VPN")}
          </SwitchRow>
        )}
        {!isDefaultVlan && (
          <SwitchRow
            value={stagedValues.isSecureNetworkEnabled}
            onValueChange={onChangeSecureNetwork}
            screenStyles={styles.switchRow}
            subtitle={I18n.t("CONFIGURE_VLAN.SECURE.SUBTITLE")}
            context={Features.automaticFirewall}
          >
            {I18n.t("CONFIGURE_VLAN.SECURE.TITLE")}
          </SwitchRow>
        )}
      </View>
      <LoadingSpinner visible={reqPending} />
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  subheading: {
    color: MkiColors.configHeadingColor,
    fontSize: normalizedFontSize(24),
    marginHorizontal: SPACING.default,
    marginTop: SPACING.default,
  },
  warningBanner: {
    margin: SPACING.default,
    width: undefined,
  },
  subnetCountStyles: {
    marginHorizontal: SPACING.default,
  },
  ipAddressInput: {
    marginTop: SPACING.large,
  },
  ipAddressContainer: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
  switchRow: {
    paddingVertical: 0,
    marginTop: SPACING.large,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...platformSelect({
      ios: {
        marginEnd: -SPACING.small,
        marginVertical: -SPACING.default,
      },
      android: {},
    }),
  },
  verticalContainer: {
    paddingBottom: SPACING.default,
  },
  sectionHeader: {
    marginTop: SPACING.large,
  },
  sectionHeaderReduced: {
    marginTop: SPACING.large,
    marginBottom: -SPACING.small,
  },
  sectionSeperator: {
    marginHorizontal: SPACING.default,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default ConfigureVlanScreen;
