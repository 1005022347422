import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const ByProductStatuses = z.object({
  alerting: z.number(),
  dormant: z.number(),
  offline: z.number(),
  online: z.number(),
  productType: z.union([
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("sensor"),
    z.literal("switch"),
    z.literal("wired"),
    z.literal("wireless"),
  ]),
});

const Counts = z.object({
  networks: z.number(),
  total: z.number(),
  statuses: z.object({
    alerting: z.number(),
    dormant: z.number(),
    offline: z.number(),
    byProductType: z.array(ByProductStatuses),
  }),
});

const Organization = z.object({
  id: z.string(),
  name: z.string(),
  management: z.object({
    details: z.array(
      z.object({
        name: z.string(),
        value: z.string(),
      }),
    ),
  }),
});

const OrgDeviceOverview = z.object({
  counts: Counts,
  organization: Organization,
});

export const GlobalOrgDeviceOverviewSchema = z
  .object({
    errors: z.array(z.string()),
    items: z.array(OrgDeviceOverview),
  })
  .describe("GlobalOrgDeviceOverviewSchema");

export type GlobalOrgDeviceOverview = z.infer<typeof GlobalOrgDeviceOverviewSchema>;

const URL = "/api/v1/administered/global/overview/devices/overview/byOrganization";

function fetchGlobalOrgDeviceOverview(): Promise<GlobalOrgDeviceOverview> {
  return request(GlobalOrgDeviceOverviewSchema, "GET", URL);
}

export const useGlobalOrgDeviceOverview = createQuery({
  baseQueryKey: URL,
  queryFn: () => fetchGlobalOrgDeviceOverview(),
});
