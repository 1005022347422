import { MS_IN_A_SECOND } from "@meraki/shared/redux";
import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

const UMBRELLA_BASE_URL = "https://api.umbrella.com/reports/v2";
interface UmbrellaTotalRequest {
  timespan: number;
  accessToken?: string;
}

export const UmbrellaTotalRequestsSchema = z
  .object({
    data: z.object({
      count: z.number(),
    }),
    meta: z.any(),
  })
  .describe("UmbrellaTotalRequestsSchema");

export type UmbrellaTotalRequestsResponse = z.infer<typeof UmbrellaTotalRequestsSchema>;

const buildUrl = () => {
  return `/total-requests`;
};

const fetchGoUmbrellaActivities = ({ accessToken, timespan }: UmbrellaTotalRequest) => {
  const to = Date.now();
  const from = to - timespan * MS_IN_A_SECOND;
  return request(UmbrellaTotalRequestsSchema, "GET", buildUrl(), {
    baseUrl: UMBRELLA_BASE_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
    queryParams: { from, to, verdict: "blocked" },
    skipCSRF: true,
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useUmbrellaTotalRequests = createQuery({
  baseQueryKey: buildUrl(),
  queryFn: fetchGoUmbrellaActivities,
  requiredVariables: ["accessToken", "timespan"],
});
