import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import SlideUpContainer from "~/shared/components/SlideUpContainer";
import ListRow from "~/shared/rows/ListRow";

interface WhatsNewBannerProps {
  shouldHideWhatsNew: boolean;
  numOfUnreadFeatures: number;
  showWhatsNewListScreen: () => void;
}

function WhatsNewBanner({
  shouldHideWhatsNew,
  numOfUnreadFeatures,
  showWhatsNewListScreen,
}: WhatsNewBannerProps) {
  if (shouldHideWhatsNew) {
    return null;
  }

  const numOfNewFeatures = (
    <MkiText textStyle="activeTab">{`${numOfUnreadFeatures} ${I18n.t("WHATS_NEW.NEW")}`}</MkiText>
  );

  const chevronRight = <MerakiIcon name="chevron-right" size="s" color={MkiColors.primaryButton} />;

  return (
    <SlideUpContainer screenStyles={styles.containerStyle}>
      <ListRow
        onPress={showWhatsNewListScreen}
        value={numOfNewFeatures}
        rowStyles={styles.rowStyle}
        accessory={chevronRight}
        underlayColor={MkiColors.primaryButtonLight}
      >
        <MkiText textStyle="activeTab">{I18n.t("WHATS_NEW.TITLE")}</MkiText>
      </ListRow>
    </SlideUpContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderTopColor: MkiColors.goPurple,
    borderTopWidth: SPACING.tiny,
  },
  rowStyle: {
    backgroundColor: MkiColors.primaryButtonLight,
    paddingHorizontal: SPACING.default,
  },
});

export default WhatsNewBanner;
