import { PureComponent } from "react";
import { StyleProp, StyleSheet, Text, TextStyle } from "react-native";

import { DISPLAY_STRING_NO_DATA } from "~/enterprise/constants/Strings";
import { isValidString } from "~/lib/formatHelper";
import { isNumber } from "~/lib/TypeHelper";
import MkiText from "~/shared/components/MkiText";

export interface ValueUnitTextProps {
  value?: number | string;
  unit?: string;
  unitTextScreenStyles?: StyleProp<TextStyle>;
  isSuperscript?: boolean;
  compact?: boolean;
  isElekid?: boolean;
}

export default class ValueUnitText extends PureComponent<ValueUnitTextProps> {
  render() {
    const { compact, value, unit, unitTextScreenStyles, isSuperscript, isElekid } = this.props;
    const isValidValueText = isNumber(value) || isValidString(value);
    const resolvedValue = isValidValueText ? value : DISPLAY_STRING_NO_DATA;

    return (
      <Text style={isSuperscript ? [styles.container, styles.superscript] : styles.container}>
        <MkiText textStyle={compact ? "title" : isElekid ? "heading" : "bigNumber"}>
          {resolvedValue}
        </MkiText>
        <MkiText textStyle="unit" screenStyles={unitTextScreenStyles}>
          {unit}
        </MkiText>
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "baseline",
    flexWrap: "wrap",
  },
  superscript: {
    alignItems: "flex-start",
  },
});
