export interface UmbrellaIdentityType {
  id: number;
  label?: string;
}

export interface UmbrellaIdentity {
  id: number;
  label?: string;
  type?: UmbrellaIdentityType;
}

export interface UmbrellaCategory {
  id: number;
  label: CategoryLabel;
  type: CategoryType;
  integration?: boolean;
}

export enum CategoryType {
  content = "content",
  security = "security",
}

export enum CategoryLabel {
  malware = "Malware",
  phishing = "Phishing",
  command = "Command and Control",
}

export interface UmbrellaCategoryRanked {
  rank?: number;
  category?: UmbrellaCategory;
  count?: number;
}

export interface UmbrellaActivity {
  externalip?: string;
  internalip?: string;
  policycategories?: UmbrellaCategory[];
  categories?: UmbrellaCategory[];
  verdict?: UmbrellaVerdict;
  domain?: string;
  timestamp: number;
  identities?: UmbrellaIdentity[];
  clientId?: string;
}

export interface UmbrellaActivityFirewall {
  destinationip?: string;
  sourceip?: string;
  sourceport?: number;
  destinationport?: number;
  verdict?: UmbrellaVerdict;
  timestamp?: number;
  identities?: UmbrellaIdentity[];
}

export enum UmbrellaVerdict {
  blocked = "blocked",
}

export interface UmbrellaActivityRanked {
  rank?: number;
  identity?: UmbrellaIdentity;
  count?: number;
}

export interface UmbrellaRequestsByHour {
  count?: number;
  timestamp?: number;
  date?: string;
  time?: string;
}

export interface UmbrellaDomain {
  rank?: number;
  domain?: string;
  count?: number;
  categories?: UmbrellaCategory[];
}

export interface UmbrellaThreat {
  name?: string;
  description?: string;
  domains?: UmbrellaDomain[];
  count?: number;
  globalDomains?: string[];
}

export interface SecurityEventListSection {
  [date: string]: UmbrellaActivity[];
}

export interface UmbrellaActivityByIp {
  [id: string]: UmbrellaActivity[];
}

export interface EventsForClient {
  [clientId: string]: number[];
}

export interface EventCount {
  ip: string;
  count: number;
}

export interface EventCountByIp {
  [ip: string]: EventCountRich;
}

export interface EventCountRich extends EventCount {
  high: boolean;
}

export enum UmbrellaFetchingState {
  loading = "loading",
  finished = "finished",
  failed = "failed",
}

export interface UmbrellaStatus {
  hasClaimedUmbrellaLicense: boolean;
  hasActiveUmbrellaLicense: boolean;
  isUmbrellaProvisioned: boolean;
  isUmbrellaEnabled: boolean;
  umbrellaOrganizationId: string | null;
}

export interface UmbrellaProtection {
  excludedDomains: string[];
  umbrellaPolicyId: number | null;
  umbrellaProtectionEnabled: boolean;
}
