import { config } from "@meraki/core/dev-config";
import { Clusters } from "@meraki/shared/redux";

let isDev = false;

// This sitekey bypasses captcha for e2e tests
// see https://docs.hcaptcha.com/#integration-testing-test-keys
const HCAPTCHA_EPHEMERAL = "10000000-ffff-ffff-ffff-000000000001";

if (__DEV__) {
  const { useDev } = config;
  isDev = useDev;
}

export const captchaSiteKey = (cluster?: Clusters): string | undefined => {
  switch (cluster) {
    case "default":
      return isDev ? process.env.HCAPTCHA_TESTING : process.env.HCAPTCHA_DEFAULT;
    case "china":
      return process.env.HCAPTCHA_CHINA;
    case "ephemeral":
      return HCAPTCHA_EPHEMERAL;
    default:
      return process.env.HCAPTCHA_TESTING;
  }
};
