import { I18n } from "@meraki/core/i18n";
import { Box } from "@meraki/magnetic/layout";
import { Form } from "@meraki/shared/form";
import { useFormContext } from "react-hook-form";

import { FIVE_GHZ_CHANNEL_WIDTHS, WirelessBandTypes } from "../../utils/RadioUtils";

interface TargetPowerCardProps {
  band: WirelessBandTypes;
}

export const ChannelWidthCard = ({ band }: TargetPowerCardProps) => {
  const methods = useFormContext();
  const auto = I18n.t("RADIO_SETTINGS.GOv3.AUTO");

  if (band === "twoFourGhzSettings") {
    return null;
  }

  const channelOptions = [{ label: auto, value: null }, ...FIVE_GHZ_CHANNEL_WIDTHS];

  return (
    <Box flex={1} testID="CHANNEL_WIDTH_CARD">
      <Form.FlashList
        name={`${band}.channelWidth`}
        paddingLeft="none"
        paddingRight="none"
        paddingBottom="none"
        label={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL_WIDTH.TITLE")}
        data={channelOptions}
        getItemData={(channelWidth) => ({
          kind: "radio",
          title: channelWidth.label,
          radioValue: channelWidth.value,
          testID: `CHANNEL_WIDTH_ROW.${channelWidth.value}${
            methods.getValues(`${band}.channelWidth`) === channelWidth.value ? ".SELECTED" : ""
          }`,
        })}
        testID="CHANNEL_WIDTH_LIST"
      />
    </Box>
  );
};
