import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";

type Props = {
  onStopPress: () => void;
};

const CableTestInProgress = memo(function CableTestInProgress(props: Props) {
  const { onStopPress } = props;

  return (
    <View>
      <MkiText screenStyles={styles.infoText}>{I18n.t("CABLE_TEST.DESCRIPTION")}</MkiText>
      <MkiText screenStyles={styles.statusText} textStyle="heading">
        {I18n.t("CABLE_TEST.TESTING_CABLE")}
      </MkiText>
      <View style={styles.loadingContainer}>
        <MkiSpinner />
      </View>
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="destructive"
          onPress={onStopPress}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("STOP")}
        </RoundedButton>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.extraLarge,
  },
  infoText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    textAlign: "left",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
  statusText: {
    marginVertical: SPACING.default,
    textAlign: "center",
  },
});

export default CableTestInProgress;
