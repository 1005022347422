import { I18n } from "@meraki/core/i18n";

import IconBanner from "~/go/components/IconBanner";

interface ErrorMessageBannerProps {
  errorArr: string[];
}

const ErrorMessageBanner = ({ errorArr }: ErrorMessageBannerProps) => {
  if (errorArr.includes("default_id")) {
    return (
      <IconBanner
        bannerType="negative"
        alertText={I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.VLAN_DEFAULT_ID")}
        testID="VLAN_DEFAULT_ID_BANNER"
        screenStyles={undefined}
      />
    );
  } else if (errorArr.includes("ip_overlap")) {
    return (
      <IconBanner
        bannerType="negative"
        alertText={I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.VLAN_IP_OVERLAP")}
        testID="VLAN_IP_OVERLAP_BANNER"
        screenStyles={undefined}
      />
    );
  } else if (errorArr.includes("duplicate_id")) {
    return (
      <IconBanner
        bannerType="warning"
        alertText={I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.VLAN_DUPLICATE_ID")}
        testID="VLAN_DUPLICATE_ID_BANNER"
        screenStyles={undefined}
      />
    );
  } else {
    return null;
  }
};

export default ErrorMessageBanner;
