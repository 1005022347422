import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { StyleProp, View, ViewStyle } from "react-native";

export type DotProps = {
  testID?: string;
};

function getContainerStyle(theme: Theme): StyleProp<ViewStyle> {
  return {
    backgroundColor: theme.color.negative.icon.base,
    borderColor: theme.color.default.bg.weak.base,
    borderWidth: theme.SizeStrokeStrong,
    borderRadius: theme.SizeRadiusBorderMd,
    height: 10,
    width: 10,
  };
}

export function Dot({ testID }: DotProps) {
  const theme = useMagneticTheme();
  return <View style={getContainerStyle(theme)} testID={testID}></View>;
}
