import { useTheme } from "@meraki/core/theme";
import { Component } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import mkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";

type TableProps<T> = {
  title?: string;
  entries: any[];
  renderRow: (item: T, index: number) => void;
  separators?: boolean;
  listStyles?: StyleProp<ViewStyle>;
  topPadding?: boolean;
};

export class Table<T> extends Component<TableProps<T>> {
  static defaultProps = {
    title: undefined,
    separators: true,
    listStyles: {},
    topPadding: true,
  };

  keyExtractor = (_: T, index: number) => `${index}`;

  renderItem = (item: T, index: number) => {
    const { renderRow, separators } = this.props;
    const { theme } = useTheme.getState();

    const themeSeperatorStyle = {
      backgroundColor: themeColors(theme).border?.borderColor || mkiColors.borderColor,
    };
    const separatorStyles = [styles.separator, themeSeperatorStyle];

    return (
      <View key={index}>
        <>
          {renderRow(item, index)}
          {separators ? <View style={separatorStyles} /> : null}
        </>
      </View>
    );
  };

  render() {
    const { entries, listStyles, separators, title, topPadding } = this.props;

    const titleText = title ? (
      <MkiText textStyle="subheading" screenStyles={styles.title}>
        {title}
      </MkiText>
    ) : null;
    const containerStyle = [styles.container, topPadding ? { paddingTop: SPACING.default } : null];

    const listWrappingStyle = [separators ? styles.listView : null, listStyles];

    return (
      <View style={containerStyle}>
        {titleText}
        <Entries<T>
          entries={entries}
          listWrappingStyle={listWrappingStyle}
          renderItem={this.renderItem}
        />
      </View>
    );
  }
}

type EntriesProps<T> = {
  entries: TableProps<T>["entries"];
  listWrappingStyle: StyleProp<ViewStyle>;
  renderItem: (item: T, index: number) => React.ReactElement;
};

const Entries = <T,>({ entries, listWrappingStyle, renderItem }: EntriesProps<T>) => {
  if (entries == null || entries.length === 0) {
    return null;
  }

  return <View style={listWrappingStyle}>{entries.map(renderItem)}</View>;
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  title: {
    marginLeft: 16,
    marginBottom: 12,
  },
  listView: {
    borderColor: mkiColors.borderColor,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: mkiColors.borderColor,
    marginHorizontal: appSelect({
      enterprise: 0,
      go: SPACING.default,
    }),
  },
});

export default Table;
