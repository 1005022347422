import { AppRegistry } from "react-native";

export function getAlertRoot() {
  return document.getElementById("web-alert");
}

function renderWebAlertComponent(key: string, props: object) {
  const alertRoot = getAlertRoot();
  if (alertRoot == null) {
    return;
  }

  AppRegistry.runApplication(key, {
    rootTag: alertRoot,
    initialProps: props,
  });
}

export function showWebAlert(title: string, message: string, onPress: () => void, options = {}) {
  const props = { title, message, onPress, ...options };
  renderWebAlertComponent("WebAlert", props);
}

export function showWebActionSheet(items: string[], onPress: () => void, options = {}) {
  const props = { items, onPress, ...options };
  renderWebAlertComponent("WebActionSheet", props);
}

export function removeWebAlertComponent() {
  const alertRoot = getAlertRoot();
  if (alertRoot == null) {
    return;
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type 'HTMLElement' is not assignable t... Remove this comment to see the full error message
  AppRegistry.unmountApplicationComponentAtRootTag(alertRoot);
}
