import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { getCurrentOrganization } from "~/selectors";
import { ApiAction, AppThunk } from "~/shared/types/Redux";

export function batch(
  action: Omit<ApiAction[typeof CALL_API], "endpoint">,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    return dispatch(
      wrapApiActionWithCSRF<{ status?: { failed: boolean; errors?: string[] } }>({
        ...action,
        endpoint: `/api/v1/organizations/${getCurrentOrganization(getState())}/actionBatches`,
      }),
    ).then((result) => {
      const status = result?.response?.status;

      if (status?.failed) {
        const error = status?.errors?.join?.(", ");
        return Promise.reject(error);
      }

      return result;
    });
  };
}
