import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { SplashMethodsSchema } from "../../schemas/SplashMethods";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";
import { Device } from "../devices/useDevices";

const SSIDAuthMode = {
  open: "open",
  psk: "psk",
  ipsk: "ipsk-without-radius",
  radius802: "8021x-radius",
  localRadius802: "8021x-localradius",
  meraki802: "8021x-meraki",
} as const;

export const SSIDAuthModeSchema = z.union([
  z.literal(SSIDAuthMode.open),
  z.literal(SSIDAuthMode.psk),
  z.literal(SSIDAuthMode.ipsk),
  z.literal(SSIDAuthMode.radius802),
  z.literal(SSIDAuthMode.localRadius802),
  z.literal(SSIDAuthMode.meraki802),
]);

const IpAssignmentModes = {
  natMode: "NAT mode",
  bridgeMode: "Bridge mode",
  layer3Roaming: "Layer 3 roaming",
  ethernetOverGRE: "Ethernet over GRE",
  layer3RoamingWithAConcentrator: "Layer 3 roaming with a concentrator",
  vpn: "VPN",
};

export const ipAssignmentModeSchema = z.union([
  z.literal(IpAssignmentModes.natMode),
  z.literal(IpAssignmentModes.bridgeMode),
  z.literal(IpAssignmentModes.layer3Roaming),
  z.literal(IpAssignmentModes.ethernetOverGRE),
  z.literal(IpAssignmentModes.layer3RoamingWithAConcentrator),
  z.literal(IpAssignmentModes.vpn),
]);

const SSIDEncryptionMode = {
  wpa: "wpa",
  eap: "wpa-eap",
} as const;

export const SSIDEncryptionModeSchema = z.union([
  z.literal(SSIDEncryptionMode.wpa),
  z.literal(SSIDEncryptionMode.eap),
]);

export const pdfInfoSchema = z.object({
  orgName: z.string(),
  logoURL: z.string(),
  base64Logo: z.string(),
  customMessage: z.string(),
  fontWeight: z.union([z.literal("lighter"), z.literal("normal"), z.literal("bold")]),
});

const SSIDScheduleRange = z.object({
  endDay: z.string(),
  startDay: z.string(),
  endTime: z.string(),
  startTime: z.string(),
});

export const SSIDScheduleRangeInSecondsSchema = z.object({
  start: z.number(),
  end: z.number(),
});

export const SsidSchema = z
  .object({
    enabled: z.boolean(),
    availabilityTags: z.array(z.string()).optional(),
    name: z.string(),
    number: z.number(),
    availableOnAllAps: z.boolean(),
    authMode: SSIDAuthModeSchema,
    bandSelection: z.string().optional(),
    c2cEnabled: z.boolean().optional(),
    defaultVlanId: z.number().optional(),
    dot11w: z
      .object({
        enabled: z.boolean().optional(),
        required: z.boolean().optional(),
      })
      .optional(),
    encryptionMode: SSIDEncryptionModeSchema.optional(),
    ipAssignmentMode: ipAssignmentModeSchema.optional(),
    isGuestNetwork: z.boolean().optional(),
    minBitrate: z.number().optional(),
    perClientBandwidthLimitDown: z.number().optional(),
    perClientBandwidthLimitUp: z.number().optional(),
    perSsidBandwidthLimitUp: z.number().optional(),
    perSsidBandwidthLimitDown: z.number().optional(),
    psk: z.string().optional(),
    schedule: z
      .object({
        enabled: z.boolean().optional(),
        ranges: z.array(SSIDScheduleRange),
        rangesInSeconds: z.array(SSIDScheduleRangeInSecondsSchema),
      })
      .optional(),
    splashPage: SplashMethodsSchema.optional(),
    splashTimeout: z.string().optional(),
    ssidAdminAccessible: z.boolean().optional(),
    useVlanTagging: z.boolean().optional(),
    walledGardenEnabled: z.boolean().optional(),
    walledGardenRanges: z.array(z.string()).optional(),
    wpaEncryptionMode: z.string().optional(),
    blockedMacs: z.array(z.string()).optional(),
    alloweddMacs: z.array(z.string()).optional(),
    qrCode: z.string().optional(),
    pdfInfo: pdfInfoSchema.optional(),
    visible: z.boolean(),
  })
  .describe("SsidSchema");

export const UNCONFIGURED_REGEX = /Unconfigured SSID \d/; // name on unconfigured SSID
export const SsidsSchema = z.array(SsidSchema);

export type Ssid = z.infer<typeof SsidSchema>;
export type SsidsResponse = z.infer<typeof SsidsSchema>;

export interface SsidsRequest {
  networkId?: string;
}

export interface SsidsForDeviceRequest {
  networkId?: string;
  device: Device;
}
export interface SsidRequest {
  networkId?: string;
  ssidNumber?: number;
}

export interface SsidsRequestForBuildUrl {
  networkId: string;
  ssidNumber?: string;
}
export interface UpdateSsidRequest {
  networkId?: string;
  ssidNumber?: number;
}

export type MutateSsidRequest = UpdateSsidRequest & {
  body: Partial<Ssid>;
};

function buildUrl({ networkId, ssidNumber }: SsidsRequestForBuildUrl) {
  return `/api/v1/networks/${networkId}/wireless/ssids/${
    ssidNumber === undefined ? "" : ssidNumber
  }`;
}

function buildUpdateUrl({ networkId, ssidNumber }: SsidsRequestForBuildUrl) {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}`;
}

export function fetchSsids({ networkId }: SsidsRequest): Promise<SsidsResponse> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching Ssids");
  }
  return request(SsidsSchema, "GET", buildUrl({ networkId }));
}

export const useSsids = createQuery<SsidsRequest, SsidsResponse>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => {
    return fetchSsids(variables);
  },
  requiredVariables: ["networkId"],
});

export function fetchSsid({ networkId, ssidNumber }: SsidRequest): Promise<Ssid> {
  if (!networkId || ssidNumber === undefined) {
    throw new Error("Undefined network id when fetching Ssids");
  }
  return request(SsidSchema, "GET", buildUrl({ networkId, ssidNumber: ssidNumber.toString() }));
}

export const useSsid = createQuery<SsidRequest, Ssid>({
  baseQueryKey: buildUrl({ networkId: "{networkId}", ssidNumber: "{ssidNumber}" }),
  queryFn: (variables) => fetchSsid(variables),
  requiredVariables: ["networkId", "ssidNumber"],
});

const updateSSID = ({ networkId, ssidNumber, body }: MutateSsidRequest) => {
  if (networkId === undefined) {
    throw new Error("Undefined network ID when updating SSID");
  }

  if (ssidNumber === undefined) {
    throw new Error("Undefined ssidNumber when updating SSID");
  }

  return request(
    SsidSchema,
    "PUT",
    buildUpdateUrl({ networkId, ssidNumber: ssidNumber.toString() }),
    {
      body: JSON.stringify(body),
    },
  );
};

export const useUpdateSsid = createMutation<MutateSsidRequest, Ssid, APIResponseError>({
  baseMutationKey: buildUpdateUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  mutationFn: (parameters: MutateSsidRequest) => updateSSID({ ...parameters }),
});

export const useSsidsForDevice = ({ networkId, device }: SsidsForDeviceRequest) =>
  useSsids(
    { networkId },
    {
      enabled: Boolean(device.productType === "wireless"),
      select(data) {
        return data.filter(
          (ssid) =>
            ssid.enabled &&
            (ssid.availableOnAllAps ||
              device.tags.some((tag) => ssid.availabilityTags?.includes(tag))),
        );
      },
    },
  );

export const useConfiguredSsids = ({ networkId }: { networkId?: string }) =>
  useSsids(
    { networkId },
    { select: (data) => data.filter(({ name }) => !name.match(UNCONFIGURED_REGEX)) },
  );
