import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheet, Button, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useAPBanner } from "@meraki/shared/access-point-placement";
import {
  Device,
  queryClient,
  useDevices,
  useRemoveDevice,
  useSsidsForDevice,
  useUnclaimDevice,
  useUpdateDeviceBySerial,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Alert } from "react-native";

type EditNameForm = {
  name: string;
};
interface EditBottomSheetProps {
  device: Device;
  onClose: () => void;
}

export const EditBottomSheet = ({ device, onClose }: EditBottomSheetProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { data: ssidsOnDevice } = useSsidsForDevice({ networkId, device });

  const { mutate: updateDevice, isLoading: isUpdatingBySerial } = useUpdateDeviceBySerial();
  const { mutate: removeDevice, isLoading: removingIsLoading } = useRemoveDevice();
  const { mutate: unclaimDevice, isLoading: unclaimingIsLoading } = useUnclaimDevice();

  const { removeUntestedAp } = useAPBanner();

  const deleteIsLoading = removingIsLoading || unclaimingIsLoading;

  const methods = useForm<EditNameForm>({
    defaultValues: {
      name: device.name ?? "",
    },
  });

  if (!device) {
    return <Loader.Spinner animate={true} />;
  }

  const donePress = () => {
    methods.reset();
    onClose();
  };

  const onSave = () => {
    updateDevice(
      { serial: device.serial, body: { name: methods.getValues("name") } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useDevices.queryKey({ organizationId }) });
          onClose();
        },
        onError: (error) => Alert.alert(I18n.t("ERROR"), String(error["errors"])),
      },
    );
  };
  const confirmDelete = () => {
    let warningMessage = I18n.t("HARDWARE_SCREEN.DELETE.DELETE_CONFIRMATION_MESSAGE");
    if (device.productType === "wireless" && ssidsOnDevice && ssidsOnDevice.length > 0) {
      const ssidNameList = ssidsOnDevice.map((ssid) => ssid.name).join(", ");
      warningMessage = I18n.t("HARDWARE_SCREEN.DELETE.DELETE_CONFIRMATION_MESSAGE_WIRELESS", {
        deviceName: device.name || device.serial,
        ssidNameList: ssidNameList,
      });
    }

    showDeleteConfirmAlert(I18n.t("WARNING"), warningMessage, onDelete);
  };

  const onDelete = () => {
    // first remove from network
    removeDevice(
      {
        networkId: device.networkId,
        serial: device.serial,
      },
      {
        onSuccess: () => {
          // then remove from organization
          unclaimDevice(
            {
              organizationId,
              serials: [device.serial],
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: useDevices.queryKey({ organizationId }),
                });
                onClose();
                removeUntestedAp(device.serial);
                navigation.goBack();
              },
              onError: (error) => Alert.alert(I18n.t("ERROR"), String(error["errors"])),
            },
          );
        },
        onError: (error) => Alert.alert(I18n.t("ERROR"), String(error["errors"])),
      },
    );
  };

  return (
    <>
      <BottomSheet.Header
        title={I18n.t("EDIT_DEVICE.DEVICE")}
        onResetPress={donePress}
        resetLabel={I18n.t("DONE")}
      />
      <BottomSheet.Content>
        <Box gap="sm">
          <Form {...methods}>
            <Form.Input
              name={"name"}
              label={I18n.t("NAME")}
              showClear={false}
              testID="DEVICE_NAME_INPUT"
            />
          </Form>
          <Button
            text={I18n.t("SAVE")}
            onPress={onSave}
            disabled={!methods.formState.isDirty}
            loading={queryClient.isFetching() > 0 || isUpdatingBySerial}
            testID="SAVE_BUTTON"
          />
          <Button
            text={I18n.t("DELETE")}
            onPress={confirmDelete}
            kind="secondaryDestructive"
            loading={deleteIsLoading}
            testID="DELETE_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </>
  );
};
