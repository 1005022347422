import { Locale } from "@meraki/core/i18n";
import { SET_CURRENT_LOCALE, SET_LATEST_SUPPORT_SUBMIT_TIME } from "@meraki/shared/redux";

import { ORIGINAL_LOCALE, updateLocale } from "~/i18n/i18n";
import { getCurrentLocale } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";

export { setCurrentCluster } from "@meraki/shared/redux";

export function setCurrentLocale(locale?: Locale): AppThunk {
  return (dispatch, getState) => {
    const derivedLocale = locale ?? getCurrentLocale(getState()) ?? ORIGINAL_LOCALE;

    updateLocale(derivedLocale);

    dispatch({
      type: SET_CURRENT_LOCALE,
      locale: derivedLocale,
    });
  };
}

export function setCurrentSupportSubmitTime(time: Date) {
  return {
    type: SET_LATEST_SUPPORT_SUBMIT_TIME,
    time,
  } as const;
}
export type MkiConfActions =
  | ReturnType<typeof setCurrentLocale>
  | ReturnType<typeof setCurrentSupportSubmitTime>;
