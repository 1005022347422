import { StatusType } from "~/shared/constants/Status";
import Uplink from "~/shared/types/models/Uplink";

export enum VpnMode {
  spoke = "spoke",
  hub = "hub",
  off = "off",
}

export enum Reachability {
  reachable = "reachable",
  unreachable = "unreachable",
}

interface ExportedSubnet {
  name: string;
  subnet: string;
}

export interface UsageSummary {
  receivedInKilobytes: number;
  sentInKilobytes: number;
}

interface UplinkType {
  senderUplink: string;
  receiverUplink: string;
}

export interface LatencySummary extends UplinkType {
  avgLatencyMs: number;
  minLatencyMs: number;
  maxLatencyMs: number;
}

export interface LossPercentageSummary {
  avgLossPercentage: number;
  minLossPercentage: number;
  maxLossPercentage: number;
}

export interface JitterSummary {
  avgJitter: number;
  minJitter: number;
  maxJitter: number;
}

export interface MosSummary {
  avgMos: number;
  minMos: number;
  maxMos: number;
}

export interface MerakiVpnPeer {
  networkId: string;
  networkName: string;
  reachability?: Reachability;
  usageSummary?: UsageSummary;
  latencySummaries?: LatencySummary[];
  lossPercentageSummaries?: LossPercentageSummary[];
  jitterSummaries?: JitterSummary[];
  mosSummaries?: MosSummary[];
}

export interface ThirdPartyVpnPeer {
  name: string;
  publicIp: string;
  reachability: Reachability;
}

export interface Vpn {
  networkId: string;
  networkName: string;
  deviceSerial: string;
  deviceStatus: StatusType;
  uplinks: Uplink[];
  vpnMode: VpnMode;
  exportedSubnets: ExportedSubnet[];
  merakiVpnPeers: MerakiVpnPeer[];
  merakiVpnPeersReachability: MerakiVpnPeer[];
  thirdPartyVpnPeers: ThirdPartyVpnPeer[];
}

export type Usage = {
  latency50: number;
  latency90: number;
  ts: number;
  usage: number;
};

export type OutageData = {
  [eid: string]: Outage[];
};

export type Outage = {
  status: number;
  ts: number;
  peerId?: string;
};

export type Peer = {
  id: string;
  peer_status: number;
  avg_lat: number;
};

export interface EidKeyedData<T> {
  [eid: string]: T;
}

export type RegistryDataType = {
  anyRegConnected: boolean;
  allRegConnected: boolean;
  privateContact: string;
  natType: string;
  contactRegistry2: string;
  publicContact: string;
  contactRegistry1: string;
  connectionFailed: boolean;
};

export type IncomingRegistryDataType = {
  any_reg_connected: boolean;
  all_reg_connected: boolean;
  private_contact: string;
  nat_type: string;
  contact_registry2: string;
  public_contact: string;
  contact_registry1: string;
  connection_failed: boolean;
};
