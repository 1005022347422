import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useSsid, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect } from "react";
import Toast from "react-native-simple-toast";

const WPA_ENCRYPTION_MODE = {
  WPA2: "WPA2 only",
  WPA2_3: "WPA3 Transition Mode",
  WPA3: "WPA3 only",
};

interface Dot11w {
  enabled: boolean;
  required: boolean;
}

export const WPASettingsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "WPASettings">>();
  const networkId = useCurrentNetworkId();
  const updateSsid = useUpdateSsid();

  const { params: props } = route;
  const { data: ssid } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  const wpaEncryptionMode = String(ssid?.wpaEncryptionMode);

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button.Nav
            leadingIcon="CaretLeft"
            text={ssid?.name ?? ""}
            onPress={() => navigation.goBack()}
          />
        ),
      }),
    [navigation, ssid?.name],
  );

  const update = (wpaMode: string) => {
    const updatedDot11w: Dot11w = {
      enabled: false,
      required: false,
    };
    switch (wpaMode) {
      case WPA_ENCRYPTION_MODE.WPA2_3:
        updatedDot11w.enabled = true;
        break;
      case WPA_ENCRYPTION_MODE.WPA3:
        updatedDot11w.enabled = true;
        updatedDot11w.required = true;
        break;
    }

    updateSsid.mutate(
      {
        networkId,
        ssidNumber: ssid?.number,
        body: { wpaEncryptionMode: wpaMode, dot11w: updatedDot11w },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSsid.queryKeyRoot });
          queryClient.invalidateQueries({ queryKey: useSsids.queryKeyRoot });
          Toast.showWithGravity(
            I18n.t("WPA_SETTINGS.SUCCESS_TOAST", { mode: wpaMode }),
            Toast.SHORT,
            Toast.TOP,
          );
        },
        onError: (error) =>
          Toast.showWithGravity(
            error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT"),
            Toast.SHORT,
            Toast.TOP,
          ),
      },
    );
  };
  return (
    <Screen addDefaultPadding>
      <Box bottomDividerBorder padding="xs">
        <Heading size="h1">{I18n.t("WPA_SETTINGS.HEADER")}</Heading>
      </Box>
      <Box padding="xs">
        <Text size="p2">{I18n.t("WPA_SETTINGS.SUBTITLE", { name: ssid?.name })}</Text>
      </Box>
      <List>
        <List.RadioItem
          title={I18n.t("WPA_SETTINGS.WPA2.TITLE")}
          description={I18n.t("WPA_SETTINGS.WPA2.SUBTITLE")}
          onRadioValueChange={(wpa) => update(wpa)}
          radioValue={WPA_ENCRYPTION_MODE.WPA2}
          checkedRadioValue={wpaEncryptionMode}
          rightAccessory={<Icon name="Gear" />}
          testID={`WPA2${wpaEncryptionMode === WPA_ENCRYPTION_MODE.WPA2 ? ".SELECTED" : ""}`}
        />
        <List.RadioItem
          title={I18n.t("WPA_SETTINGS.WPA2_3.TITLE")}
          description={I18n.t("WPA_SETTINGS.WPA2_3.SUBTITLE")}
          onRadioValueChange={(wpa) => update(wpa)}
          radioValue={WPA_ENCRYPTION_MODE.WPA2_3}
          checkedRadioValue={wpaEncryptionMode}
          rightAccessory={<Icon name="Gear" />}
          testID={`WPA2_3${wpaEncryptionMode === WPA_ENCRYPTION_MODE.WPA2_3 ? ".SELECTED" : ""}`}
        />
        <List.RadioItem
          title={I18n.t("WPA_SETTINGS.WPA3.TITLE")}
          description={I18n.t("WPA_SETTINGS.WPA3.SUBTITLE")}
          onRadioValueChange={(wpa) => update(wpa)}
          radioValue={WPA_ENCRYPTION_MODE.WPA3}
          checkedRadioValue={wpaEncryptionMode}
          rightAccessory={<Icon name="Gear" />}
        />
      </List>
    </Screen>
  );
};
