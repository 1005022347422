import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, List, PortDiagram } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { usePortStatuses } from "@meraki/react-live-broker";
import { useAllAppliancePorts } from "@meraki/shared/api";
import { FilterBottomSheet, FilterTagList, SearchFilterSection } from "@meraki/shared/components";
import {
  AppliancePortStatusAndFilter,
  FilterConfiguration,
  FiltersState,
  getAppliancePortSearchResults,
  PortFilterCategories,
  PortFilterOptions,
  useFilteredState,
} from "@meraki/shared/filters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useMemo, useRef, useState } from "react";

import {
  combineAppliancePorts,
  deriveAppliancePortStatus,
  getPortFilterTranslation,
  portListDescription,
} from "../../utils/PortUtils";

export const AppliancePortFilterConfiguration: FilterConfiguration<
  PortFilterCategories,
  PortFilterOptions,
  AppliancePortStatusAndFilter
> = (item) => {
  return {
    status: {
      online: item.state === "fullSpeed",
      offline: false,
      disabled: item.state === "disabled",
      disconnected: item.state === "disconnected",
    },
    usage: {
      uplink: item.indicator === "uplink",
      poe: item.indicator === "active",
    },
  };
};

export const AppliancePortsListScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "AppliancePortsList">>();
  const { device, statusFilters } = route.params;

  const networkId = useCurrentNetworkId();
  const filterBottomSheetRef = useRef<BottomSheetMethods>(null);

  const {
    data: apiPortData,
    refetch: refetchData,
    isLoading,
  } = useAllAppliancePorts({
    networkId,
  });
  const livePortData = usePortStatuses(device.id);

  const portStatuses = useMemo(
    () => deriveAppliancePortStatus(combineAppliancePorts(apiPortData, livePortData)),
    [apiPortData, livePortData],
  );
  const [searchText, setSearchText] = useState("");

  const {
    setFilters,
    filters,
    filteredData: filteredPorts,
  } = useFilteredState<PortFilterCategories, PortFilterOptions, AppliancePortStatusAndFilter>(
    statusFilters ?? {},
    portStatuses,
    { filterConfiguration: AppliancePortFilterConfiguration },
  );

  const searchedPorts = getAppliancePortSearchResults(filteredPorts, searchText);

  return (
    <Screen.View gap="none">
      <Box flexDirection="row" paddingTop="sm" alignItems="center" gap="sm">
        <SearchFilterSection
          placeholder={I18n.t("SEARCH")}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onFilterPress={() => filterBottomSheetRef.current?.present()}
        />
      </Box>
      <FilterTagList
        filters={{
          status:
            filters.status != null
              ? Array.from(filters.status).map((filter) => ({
                  value: filter,
                  label: getPortFilterTranslation(filter),
                }))
              : [],
          usage:
            filters.usage != null
              ? Array.from(filters.usage).map((filter) => ({
                  value: filter,
                  label: getPortFilterTranslation(filter),
                }))
              : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<PortFilterCategories, PortFilterOptions> = {};

          if (filters.status.length > 0) {
            formattedFilters.status = new Set(filters.status);
          }
          if (filters.usage.length > 0) {
            formattedFilters.usage = new Set(filters.usage);
          }

          setFilters(formattedFilters);
        }}
      />
      <List.FlashList
        data={searchedPorts}
        loading={isLoading || livePortData === undefined}
        emptyState={{
          title: I18n.t("PORT_EMPTY_STATE.MESSAGE_SEARCH"),
        }}
        onScrollEndDrag={() => refetchData()}
        getItemData={(port) => {
          return {
            title:
              port.number === 1
                ? `${I18n.t("PORTS.NUMBER", { port_number: port.number })} - ${I18n.t(
                    "PORTS.INTERNET_CONNECTION",
                  )}`
                : I18n.t("PORTS.NUMBER", { port_number: port.number }),
            leftAccessory: (
              <PortDiagram.Port
                size="sm"
                {...({ state: port.state, indicator: port.indicator } ?? { state: "disconnected" })}
              />
            ),
            description: portListDescription(port),
            onPress: () => {
              navigation.navigate("AppliancePortDetails", {
                serial: device.serial,
                portNumber: port.number,
              });
            },
            testID: `APPLIANCE_PORT.${port.number}`,
          };
        }}
      />
      <FilterBottomSheet
        ref={filterBottomSheetRef}
        availableFilters={{
          status: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.STATUS"),
            options: [
              { value: "online", label: I18n.t("PORT_FILTERS.ONLINE") },
              { value: "offline", label: I18n.t("PORT_FILTERS.OFFLINE") },
              { value: "disabled", label: I18n.t("PORT_FILTERS.DISABLED") },
              { value: "disconnected", label: I18n.t("PORT_FILTERS.DISCONNECTED") },
            ],
          },
          usage: {
            label: I18n.t("SORT_FILTER.FILTER_OPTIONS.USAGE"),
            options: [
              { value: "uplink", label: I18n.t("PORT_FILTERS.UPLINK") },
              { value: "poe", label: I18n.t("PORT_FILTERS.POE") },
            ],
          },
        }}
        initialFilters={{
          status: filters.status != null ? Array.from(filters.status) : [],
          usage: filters.usage != null ? Array.from(filters.usage) : [],
        }}
        onUpdateFilters={(filters) => {
          const formattedFilters: FiltersState<PortFilterCategories, PortFilterOptions> = {};

          if (filters.status.length > 0) {
            formattedFilters.status = new Set(filters.status);
          }
          if (filters.usage.length > 0) {
            formattedFilters.usage = new Set(filters.usage);
          }

          setFilters(formattedFilters);
        }}
      />
    </Screen.View>
  );
};
