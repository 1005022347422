import { NodeType, TopologyRequest, useTopology } from "@meraki/shared/api";

import { extractRootIdsAndNormalizeNodes, mapPorts, normalizeLinkData } from "~/lib/TopologyUtils";

export interface NodeById {
  [key: string]: NodeType;
}

export interface LinkById {
  [key: string]: string[];
}

export interface PortMapById {
  [key: string]: number[];
}

export interface FormattedTopologyResponse {
  rootIds: string[];
  nodes: NodeById;
  links: LinkById[];
  portMappings: PortMapById;
}

const formatTopologyData = ({ nodes, links }: any): FormattedTopologyResponse => {
  const { rootIds, nodesById } = extractRootIdsAndNormalizeNodes(nodes);

  return {
    rootIds,
    nodes: nodesById,
    links: normalizeLinkData(links, rootIds) ?? [],
    portMappings: mapPorts(nodes, links),
  };
};

export const useFormattedTopology = (variables: TopologyRequest) => {
  return useTopology(variables, {
    select: formatTopologyData,
  });
};

export default useFormattedTopology;
