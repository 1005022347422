import { ErrorMessage } from "@hookform/error-message";
import { Notification, Slider, SliderProps } from "@meraki/magnetic/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormSliderProps = Omit<SliderProps, "value"> & FormItemProps;

export const FormSlider = ({ name, rules, onValueChange, testID, ...rest }: FormSliderProps) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Slider
            {...rest}
            onValueChange={(value) => {
              onChange(value);
              onValueChange?.(value);
            }}
            value={value}
            testID={testID}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
};
