import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Input,
  Numeral,
} from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  LiveToolCommand,
  LiveToolContext,
  LiveToolStatus,
  usePing,
} from "@meraki/react-live-broker";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";

interface TestConnectionBottomSheetProps {
  deviceId: string;
}

interface TestConnectionProps {
  deviceId: string;
  liveState: LiveToolStatus;
  website?: string;
}

const TestConnection = ({ deviceId, website, liveState }: TestConnectionProps) => {
  const pingStats = usePing(deviceId, website);
  const opacity = useSharedValue(1);

  useEffect(() => {
    if (pingStats?.averageLatency === undefined && liveState === LiveToolStatus.idle) {
      opacity.value = 0.5;
    } else if (liveState === LiveToolStatus.completed || liveState === LiveToolStatus.idle) {
      opacity.value = 1;
    } else if (liveState === LiveToolStatus.connecting || liveState === LiveToolStatus.running) {
      const NUM_OF_REPS = 100;
      const toValue = liveState === LiveToolStatus.connecting ? 0 : 0.25;
      const duration = liveState === LiveToolStatus.connecting ? 1000 : 1500;
      opacity.value = withRepeat(
        withTiming(toValue, { duration, easing: Easing.ease }),
        NUM_OF_REPS,
        true,
        (finished?: boolean) => {
          if (finished) {
            opacity.value = 1;
          }
        },
      );
    }
  }, [liveState, opacity, pingStats?.averageLatency]);

  const animatedStyles = useAnimatedStyle(() => ({ opacity: opacity.value }), [opacity]);
  return (
    <Box gap="sm" alignItems="center" padding="md" testID="PING_NUMERAL">
      <Animated.View style={animatedStyles}>
        <Numeral value={pingStats?.averageLatency?.toFixed(2)} units="ms" />
      </Animated.View>
    </Box>
  );
};

const DEFAULT_WEB_URL = "https://www.meraki-go.com";

export const TestConnectionBottomSheet = forwardRef(function TestConnectionBottomSheet(
  { deviceId }: TestConnectionBottomSheetProps,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const [website, setWebsite] = useState<string>(DEFAULT_WEB_URL);
  const [liveState, setLiveState] = useState<LiveToolStatus>(LiveToolStatus.idle);
  const [command, setCommand] = useState<LiveToolCommand>("stop");
  const liveToolContext = {
    command,
    onStatusChange: setLiveState,
  };
  return (
    <BottomSheet.Modal snapPoints={["CONTENT_HEIGHT"]} index={0} ref={ref}>
      <BottomSheet.Header
        title={I18n.t("TEST_CONNECTION.TITLE")}
        cancelLabel={I18n.t("CLOSE")}
        onCancelPress={() => {
          if (typeof ref !== "function") {
            setCommand("stop");
            ref?.current?.dismiss();
          }
        }}
      />
      <BottomSheet.Content>
        <LiveToolContext.Provider value={liveToolContext}>
          <TestConnection
            liveState={liveState}
            deviceId={deviceId}
            website={website === DEFAULT_WEB_URL ? undefined : website}
          />
        </LiveToolContext.Provider>
        <Box flex={1} justifyContent="center" alignContent="center">
          <Box paddingBottom="md">
            <Input
              autoCapitalize="none"
              value={website}
              onChangeText={(newWebsite) => {
                setCommand("stop");
                setWebsite(newWebsite);
              }}
              label={I18n.t("TEST_CONNECTION.CUSTOM_WEBSITE_INPUT.TITLE")}
              additionalContext={I18n.t("TEST_CONNECTION.CUSTOM_WEBSITE_INPUT.EXAMPLE")}
            />
          </Box>
          <Button
            kind="secondary"
            text={I18n.t("TEST_CONNECTION.TEST_CONNECTION")}
            testID={"TEST_CONNECTION_BUTTON"}
            onPress={() => {
              setCommand("start");
            }}
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
});
