import { I18n } from "@meraki/core/i18n";
import { ProductType } from "@meraki/shared/api";

export const translatedProductTypesMap: Record<ProductType, string> = {
  appliance: I18n.t("PRODUCT_TYPE.APPLIANCE"),
  wireless: I18n.t("PRODUCT_TYPE.WIRELESS"),
  switch: I18n.t("PRODUCT_TYPE.SWITCH"),
  camera: I18n.t("PRODUCT_TYPE.CAMERA"),
  sensor: I18n.t("PRODUCT_TYPE.SENSOR"),
  cellularGateway: I18n.t("PRODUCT_TYPE.CELLULAR_GATEWAY"),
  systemsManager: I18n.t("PRODUCT_TYPE.SYSTEMS_MANAGER"),
  phone: "",
};

export const translatedStatusMap: Record<string, string> = {
  online: I18n.t("DEVICE_STATUS.ONLINE"),
  offline: I18n.t("DEVICE_STATUS.OFFLINE"),
  alerting: I18n.t("DEVICE_STATUS.ALERTING"),
  dormant: I18n.t("DEVICE_STATUS.DORMANT"),
};

export const translatedSeveritiesMap: Record<"informational" | "warning" | "critical", string> = {
  informational: I18n.t("SORT_FILTER.SEVERITY.INFORMATIONAL"),
  warning: I18n.t("SORT_FILTER.SEVERITY.WARNING"),
  critical: I18n.t("SORT_FILTER.SEVERITY.CRITICAL"),
};
