import * as DNSLookup from "../handlers/commandfetch/DNSLookup";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useDNSLookup(
  deviceId: string,
  hostName: string,
  dnsServer: string,
): LiveTool<string> {
  const msg = useLiveBroker<DNSLookup.Props, DNSLookup.Message>({
    type: "CommandFetch",
    deviceId,
    command: "dns",
    host: hostName,
    server: dnsServer,
  });

  return useCachedFormatter(msg, DNSLookup.formatMsg);
}
