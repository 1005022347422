import { Heading, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useContext, useEffect, useState } from "react";

import { DebuggingToolsContext } from "../DebuggingToolsContext";

export function DebuggingToolsComplexView() {
  const { navigationRef } = useContext(DebuggingToolsContext);
  const [currentRoute, setCurrentRoute] = useState(navigationRef?.getCurrentRoute());

  useEffect(() => {
    // We have to keep track of the current route and update the state when it changes to ensure that the view is re-rendered with the new data.
    const changeListener = navigationRef?.addListener("state", () => {
      setCurrentRoute(navigationRef.getCurrentRoute());
    });

    return () => {
      changeListener?.();
    };
  }, [navigationRef]);

  return (
    <Box>
      <Box>
        <Heading size="h4">Current Route:</Heading>
      </Box>
      <Box>
        <Text>
          <Text>Name: </Text>
          <Text>{currentRoute?.name ?? "Route not found"}</Text>
        </Text>
      </Box>
      <Box>
        <Text>
          <Text>Params: </Text>
          <Text>{JSON.stringify(currentRoute?.params) ?? "None"}</Text>
        </Text>
      </Box>
    </Box>
  );
}
