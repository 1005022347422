import z from "zod";

export const NetworkAccessRolesSchema = z
  .object({
    can_write: z.boolean(),
    is_camera_only_admin: z.boolean(),
    is_scoped_admin: z.boolean(),
    is_sensor_only_admin: z.boolean(),
    monitor_only: z.boolean(),
  })
  .describe("NetworkAccessRolesSchema");

export type NetworkAccessRoles = z.infer<typeof NetworkAccessRolesSchema>;
