import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId }: ApplianceTrafficShapingRequest) => {
  return `/api/v1/networks/${networkId}/appliance/trafficShaping`;
};

interface ApplianceTrafficShapingRequest {
  networkId: string;
}

export const ApplianceTrafficShapingSchema = z.object({
  globalBandwidthLimits: z.object({
    limitUp: z.number(),
    limitDown: z.number(),
  }),
});

export type ApplianceTrafficShaping = z.infer<typeof ApplianceTrafficShapingSchema>;

const fetchApplianceTrafficShaping = ({ networkId }: ApplianceTrafficShapingRequest) => {
  return request(ApplianceTrafficShapingSchema, "GET", buildUrl({ networkId }));
};

export const useApplianceTrafficShaping = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: ApplianceTrafficShapingRequest) => fetchApplianceTrafficShaping(parameters),
});
