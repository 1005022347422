import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const BuiltInRuleTypes = {
  application: "application",
  applicationCategory: "applicationCategory",
} as const;

const BuiltInRuleTypeSchema = z.union([
  z.literal(BuiltInRuleTypes.application),
  z.literal(BuiltInRuleTypes.applicationCategory),
]);

const BuiltInRuleSchema = z.object({
  type: BuiltInRuleTypeSchema,
  value: z.object({
    id: z.string(),
    name: z.string().optional(),
  }),
});

export const CustomRuleTypes = {
  host: "host",
  port: "port",
  ipRange: "ipRange",
  localNet: "localNet",
} as const;

const CustomRuleTypeSchema = z.union([
  z.literal(CustomRuleTypes.host),
  z.literal(CustomRuleTypes.port),
  z.literal(CustomRuleTypes.ipRange),
  z.literal(CustomRuleTypes.localNet),
]);

const CustomRuleSchema = z.object({
  type: CustomRuleTypeSchema,
  value: z.string(),
});

export const RulePriorityTypes = {
  high: "high",
  normal: "normal",
  low: "low",
} as const;

const RulePriorityTypeSchema = z.union([
  z.literal(RulePriorityTypes.high),
  z.literal(RulePriorityTypes.normal),
  z.literal(RulePriorityTypes.low),
]);

const PerClientBandwidthLimitsSettingsSchema = z.union([
  z.literal("network default"),
  z.literal("ignore"),
  z.literal("custom"),
]);

export const ApplianceTrafficShapingRuleSchema = z.object({
  definitions: z.array(z.union([BuiltInRuleSchema, CustomRuleSchema])),
  perClientBandwidthLimits: z.object({
    settings: PerClientBandwidthLimitsSettingsSchema,
    bandwidthLimits: z
      .object({
        limitUp: z.number(),
        limitDown: z.number(),
      })
      .optional(),
  }),
  dscpTagValue: z.number().nullable(),
  priority: RulePriorityTypeSchema.optional(),
});

export const ApplianceTrafficShapingRulesSchema = z
  .object({
    defaultRulesEnabled: z.boolean(),
    rules: z.array(ApplianceTrafficShapingRuleSchema),
  })
  .describe("ApplianceTrafficShapingRulesSchema");

export type ApplianceTrafficShapingRule = z.infer<typeof ApplianceTrafficShapingRuleSchema>;
export type ApplianceTrafficShapingRules = z.infer<typeof ApplianceTrafficShapingRulesSchema>;

interface ApplianceTrafficShapingRulesRequest {
  networkId?: string;
}

interface UpdateApplianceTrafficShapingRulesRequest extends ApplianceTrafficShapingRulesRequest {
  rules: ApplianceTrafficShapingRules;
}

const buildUrl = ({ networkId }: ApplianceTrafficShapingRulesRequest) => {
  return `/api/v1/networks/${networkId}/appliance/trafficShaping/rules`;
};

const fetchApplianceTrafficShapingRules = ({ networkId }: ApplianceTrafficShapingRulesRequest) => {
  return request(ApplianceTrafficShapingRulesSchema, "GET", buildUrl({ networkId }));
};

const mutateApplianceTrafficShapingRules = ({
  networkId,
  rules,
}: UpdateApplianceTrafficShapingRulesRequest) => {
  return request(ApplianceTrafficShapingRulesSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify(rules),
  });
};

export const useApplianceTrafficShapingRules = createQuery<
  ApplianceTrafficShapingRulesRequest,
  ApplianceTrafficShapingRules
>({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: ApplianceTrafficShapingRulesRequest) =>
    fetchApplianceTrafficShapingRules(parameters),
  requiredVariables: ["networkId"],
});

export const useUpdateApplianceTrafficShapingRules = createMutation<
  UpdateApplianceTrafficShapingRulesRequest,
  ApplianceTrafficShapingRules,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters) => mutateApplianceTrafficShapingRules(parameters),
});
