import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, List, Loader } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  Ssid,
  UNCONFIGURED_REGEX,
  useOrgNetworks,
  useSsids,
  useUpdateSsid,
} from "@meraki/shared/api";
import { Form } from "@meraki/shared/form";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert } from "react-native";

const MAX_NUMBER_OF_SSIDS = 4;

type AddSSIDForm = {
  name: string;
  networkId?: string;
  enabled: boolean;
};

type SsidPartition = {
  configured: Ssid[];
  unconfigured: Ssid[];
};

export function AddSSIDScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();

  const organizationId = useCurrentOrganizationId();

  const [showEnabled, setShowEnabled] = useState(false);

  const { data: orgNetworks, isLoading: isLoadingOrgNetworks } = useOrgNetworks({ organizationId });
  const ssidsResults = useQueries({
    queries:
      orgNetworks?.map((network) =>
        useSsids.useQueries(
          { networkId: network.id },
          {
            select: (data) => ({
              networkId: network.id,
              ssids: data.reduce<SsidPartition>(
                (result, ssid) => {
                  if (ssid.name.match(UNCONFIGURED_REGEX)) {
                    result.unconfigured.push(ssid);
                  } else {
                    result.configured.push(ssid);
                  }
                  return result;
                },
                {
                  configured: [],
                  unconfigured: [],
                },
              ),
            }),
          },
        ),
      ) ?? [],
  });

  const isLoadingSSIDs = ssidsResults.some(({ isLoading }) => isLoading);
  const ssidsByNetworkId = ssidsResults.reduce<Record<string, SsidPartition>>(
    (result, { data }) => {
      if (data) {
        result[data.networkId] = data.ssids;
      }

      return result;
    },
    {},
  );

  const updateSsid = useUpdateSsid();
  const methods = useForm<AddSSIDForm>({
    defaultValues: {
      name: "",
      networkId: undefined,
      enabled: false,
    },
  });

  const validateNetwork = (networkId: string) => {
    const ssids = ssidsByNetworkId[networkId];

    if (
      !ssids ||
      ssids.configured.length >= MAX_NUMBER_OF_SSIDS ||
      ssids.unconfigured.length === 0
    ) {
      return I18n.t("MAX_SSIDS_ERROR.MESSAGE");
    }

    return;
  };

  const onSubmit = useCallback(
    ({ name, networkId, enabled }: AddSSIDForm) => {
      if (!name || !networkId) {
        return;
      }

      const ssids = ssidsByNetworkId[networkId];
      if (!ssids || ssids.unconfigured.length === 0) {
        return;
      }

      if (networkId && name) {
        updateSsid.mutate(
          {
            networkId,
            ssidNumber: ssids.unconfigured[0]?.number,
            body: {
              name,
              enabled,
            },
          },
          {
            onError: (error) => Alert.alert(String(error)),
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useSsids.queryKey({ networkId }) });
              navigation.goBack();
            },
          },
        );
      }
    },
    [navigation, ssidsByNetworkId, updateSsid],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSubmit)} />
      ),
    });
  }, [methods, navigation, onSubmit]);

  return (
    <Screen addDefaultPadding>
      {updateSsid.isLoading && <Loader.Spinner animate={true} />}
      <Form {...methods}>
        <Form.Input
          name="name"
          rules={{ required: I18n.t("CONFIGURE.CREATE.SSID.NAME.REQUIRED") }}
          label={I18n.t("CONFIGURE.CREATE.SSID.NAME.LABEL")}
          placeholder={I18n.t("CONFIGURE.CREATE.SSID.NAME.PLACEHOLDER")}
          disabled={isLoadingOrgNetworks || isLoadingSSIDs}
          testID="ADD_SSID.NAME_INPUT"
        />
        <Box>
          <Form.FlashList
            name="networkId"
            rules={{
              required: I18n.t("CONFIGURE.CREATE.SSID.NETWORK.REQUIRED"),
              validate: validateNetwork,
            }}
            loading={isLoadingOrgNetworks || isLoadingSSIDs}
            label={I18n.t("CONFIGURE.CREATE.SSID.NETWORK.LABEL")}
            data={orgNetworks}
            getItemData={(network) => ({
              kind: "radio",
              title: network.name,
              radioValue: network.id,
              testID: `ADD_SSID.NETWORK_${network.id}`,
            })}
            paddingLeft="none"
            paddingRight="none"
          />
        </Box>
        <List>
          <List.Item
            title={
              showEnabled
                ? I18n.t(`CONFIGURE.CREATE.SSID.ENABLED.LABEL`)
                : I18n.t(`CONFIGURE.CREATE.SSID.DISABLED.LABEL`)
            }
            description={
              showEnabled
                ? I18n.t(`CONFIGURE.CREATE.SSID.ENABLED.DESCRIPTION`)
                : I18n.t(`CONFIGURE.CREATE.SSID.DISABLED.DESCRIPTION`)
            }
            leftAccessory={<Status status={showEnabled ? "positive" : "disabled"} />}
            rightAccessory={
              <Form.Toggle
                name="enabled"
                onValueChange={(newValue) => setShowEnabled(newValue)}
                testID="ADD_SSID.ENABLED_TOGGLE"
              />
            }
          />
        </List>
      </Form>
    </Screen>
  );
}
