import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";
import {
  LoginDetailsResponseType,
  LoginResponse,
  LoginResponseType,
  ModesResponseType,
} from "../private/login/types";

const baseUrl = "https://account.meraki.com";
const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const verifyIsModeResponse = (
  response: LoginResponseType,
): response is ModesResponseType => {
  return "mode" in response;
};

export const verifyIsLoginResponse = (
  response: LoginResponseType,
): response is LoginDetailsResponseType => {
  return "success" in response;
};

export type CreateAccountForm = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  company_name: string;
  address1: string;
  region: string;
  receive_marketing: boolean;
};

type CreateAccountSubmitForm = CreateAccountForm & { token?: string };

const getCreateAccountBody = (formData: CreateAccountSubmitForm) => {
  const { token, region, ...primaryData } = formData;
  const data: Record<string, string | boolean> = { region };

  (Object.keys(primaryData) as Array<keyof Omit<CreateAccountForm, "region">>).forEach((key) => {
    // endpoint expects all user input fields except for region to have the key structure user[...]
    data[`user[${key}]`] = primaryData[key];
  });

  if (token) {
    data["captcha_shown"] = true;
    data["mki_captcha_provider_response"] = token;
    data["mki_captcha_provider_id"] = "hcaptcha";
  }
  return data;
};

const createAccount = (formData: CreateAccountSubmitForm) =>
  request(LoginResponse, "POST", "/login/signup", {
    baseUrl,
    headers,
    queryParams: getCreateAccountBody(formData),
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCreateAccount = createMutation<CreateAccountSubmitForm, LoginResponseType>({
  baseMutationKey: "/login/signup",
  mutationFn: (formData) => createAccount(formData),
});

type EmailTakenQuery = {
  email: string;
};

const checkEmailTaken = (data: EmailTakenQuery) =>
  request(z.boolean(), "GET", "/login/check_user_exists", {
    queryParams: data,
  });

export const useIsEmailTaken = createQuery<EmailTakenQuery, boolean>({
  baseQueryKey: "/login/check_user_exists",
  queryFn: (data) => checkEmailTaken(data),
});
