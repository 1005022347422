import { pick } from "lodash";
import { useMemo } from "react";

import {
  IMMUTABLE_SWITCH_PORT_KEYS,
  ImmutableSwitchPort,
  MUTABLE_SWITCH_PORT_KEYS,
  StagedSwitchPort,
  SwitchPort,
} from "~/api/schemas/SwitchPort";
import useObjectUpdater from "~/shared/hooks/useObjectUpdater";

export const createImmutableSwitchPort = (switchPort: SwitchPort): ImmutableSwitchPort => {
  return pick(switchPort, IMMUTABLE_SWITCH_PORT_KEYS);
};
export const createEditableSwitchPort = (switchPort: SwitchPort): StagedSwitchPort => {
  return pick(switchPort, MUTABLE_SWITCH_PORT_KEYS);
};

export default function useEditSwitchPort(switchPort: SwitchPort) {
  const editableSwitchPort = useMemo(() => createEditableSwitchPort(switchPort), [switchPort]);
  return useObjectUpdater(editableSwitchPort);
}
