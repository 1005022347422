import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useSwitchPortStatuses } from "@meraki/react-live-broker";
import { Device, useSwitchPortJson } from "@meraki/shared/api";
import { FiltersState, PortFilterCategories, PortFilterOptions } from "@meraki/shared/filters";
import { switchportAlertingCount, switchportIsConnected } from "@meraki/shared/formatters";
import { useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { isEmpty } from "lodash";

export const SwitchPortOverviewCard = ({ device }: { device: Device }) => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const timespan = useGlobalTimespan();

  const { data: privatePorts } = useSwitchPortJson(
    {
      nodeId: device.id,
      eid: device.networkEid,
      timespan,
    },
    {
      select(data) {
        return Object.values(data).map((port) => port);
      },
      enabled: device.productType === "switch",
    },
  );
  const liveSwitchPortData = useSwitchPortStatuses(device.id);

  const getGSActivePortCount = () => {
    if (liveSwitchPortData === undefined) {
      return 0;
    }
    if (isEmpty(liveSwitchPortData)) {
      return 0;
    }

    const connectedPorts = Object.values(liveSwitchPortData).filter((port) =>
      switchportIsConnected(port),
    );

    return connectedPorts.length;
  };

  const navigateToListScreenWFilters = (
    statusFilters?: FiltersState<PortFilterCategories, PortFilterOptions>,
  ) => navigation.navigate("SwitchPortsList", { device, statusFilters });

  return (
    <Card
      loadingContentHeight={30}
      loading={!liveSwitchPortData || !privatePorts}
      testID="PORTS_CARD"
      onPress={() => navigateToListScreenWFilters()}
    >
      <Card.Header title={I18n.t("PORTS_WORD")} />
      <Card.Content flexDirection="row" gap="sm">
        <Card.Well
          flex={1}
          onPress={() => navigateToListScreenWFilters({ status: new Set(["online"]) })}
        >
          <Heading>{getGSActivePortCount()}</Heading>
          <Box flexDirection="row" gap="2xs" alignItems="center">
            <Text size="p2" weight="bold">
              {I18n.t("ACTIVE")}
            </Text>
            <Status status="positive" />
          </Box>
        </Card.Well>
        <Card.Well
          flex={1}
          onPress={() => navigateToListScreenWFilters({ status: new Set(["offline"]) })}
        >
          <Heading>{switchportAlertingCount(privatePorts ?? [])}</Heading>
          <Box flexDirection="row" gap="2xs" alignItems="center">
            <Text size="p2" weight="bold">
              {I18n.t("ALERTING")}
            </Text>
            <Status status="negative" />
          </Box>
        </Card.Well>
      </Card.Content>
    </Card>
  );
};
