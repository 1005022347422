import { I18n } from "@meraki/core/i18n";
import { Alert, AlertButton, Platform } from "react-native";

const alertPolyfill = (title: string, description: string, options?: AlertButton[]) => {
  const result = window.confirm([title, description].filter(Boolean).join("\n"));

  if (options) {
    if (result) {
      const confirmOption = options.find(({ style }) => style !== "cancel");
      confirmOption && confirmOption.onPress?.();
    } else {
      const cancelOption = options.find(({ style }) => style === "cancel");
      cancelOption && cancelOption.onPress?.();
    }
  }
};

export const showDeleteConfirmAlert = (title: string, body: string, onConfirm: () => void) =>
  showAlert(title, body, [
    {
      text: I18n.t("CANCEL"),
      isPreferred: true,
      style: "cancel",
    },
    {
      text: I18n.t("CONFIRM"),
      onPress: onConfirm,
      style: "destructive",
    },
  ]);

export const showOkayAlert = (title: string, body: string, onPress?: () => void) =>
  showAlert(title, body, [
    {
      text: I18n.t("CONFIRM"),
      isPreferred: true,
      style: "default",
      onPress,
    },
  ]);

export const showErrorAlert = (body?: string, title?: string) =>
  showAlert(title ?? I18n.t("ERROR"), body ?? "", [
    {
      text: I18n.t("CONFIRM"),
      isPreferred: true,
    },
  ]);

export const showAlert = (title: string, body: string, buttons: AlertButton[]) =>
  Platform.OS === "web"
    ? alertPolyfill(title, body, buttons)
    : Alert.alert(title, body, buttons, { cancelable: true });
