import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";

export const BuiltInPolicyNames = ["Allowed", "Blocked", "Normal"] as const;
export const CustomGroupPolicyName = "Group policy" as const;

const ApplianceDevicePolicies = z.union([
  z.literal(BuiltInPolicyNames[0]),
  z.literal(BuiltInPolicyNames[1]),
  z.literal(BuiltInPolicyNames[2]),
]);

const SsidDevicePolicies = ApplianceDevicePolicies.or(z.literal(CustomGroupPolicyName));

const DevicePolicyOptions = SsidDevicePolicies.or(z.literal("Per connection"));

const PoliciesBySecurityAppliance = z.object({
  devicePolicy: ApplianceDevicePolicies,
});

const SsidPolicySchema = z.object({
  devicePolicy: SsidDevicePolicies,
  groupPolicyId: z.string().optional(),
});

const PoliciesBySsidSchema = z.record(z.string(), SsidPolicySchema);

export const ClientProvisionInfoSchema = z.object({
  mac: z.string(),
  clientId: z.string().optional(),
  name: z.string().optional(),
  message: z.string().optional().nullable(),
});

export const ProvisionClientsSchema = z
  .object({
    clients: z.array(ClientProvisionInfoSchema),
    devicePolicy: DevicePolicyOptions,
    groupPolicyId: z.string().optional(),
    policiesBySecurityAppliance: PoliciesBySecurityAppliance.optional(),
    policiesBySsid: PoliciesBySsidSchema.optional(),
  })
  .describe("ProvisionClientsSchema");

export type PoliciesBySsid = z.infer<typeof PoliciesBySsidSchema>;
export type ClientProvisionInfo = z.infer<typeof ClientProvisionInfoSchema>;
export type ProvisionClients = z.infer<typeof ProvisionClientsSchema>;

interface ProvisionClientsRequest {
  networkId?: string;
}

interface MutateProvisionClientsRequest extends ProvisionClientsRequest {
  body: ProvisionClients;
}

const buildUrl = ({ networkId }: ProvisionClientsRequest) => {
  return `/api/v1/networks/${networkId}/clients/provision`;
};

const createProvisionClients = ({ networkId, body }: MutateProvisionClientsRequest) => {
  return request(ProvisionClientsSchema, "POST", buildUrl({ networkId }), {
    body: JSON.stringify(body),
  });
};

export const useCreateProvisionClients = createMutation<
  MutateProvisionClientsRequest,
  ProvisionClients,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters: MutateProvisionClientsRequest) =>
    createProvisionClients({ ...parameters }),
});
