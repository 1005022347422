import { LaunchDarkly } from "./providers/LaunchDarkly";
import { LDFlags } from "./providers/LaunchDarkly.base";
import { NFO } from "./providers/NFO";
import { BooleanKeys, NumberKeys, ObjectKeys, StringKeys } from "./providers/typeHelpers";
import { ContextOptions, FlagMap } from "./types";
import { useOverrideStore } from "./useOverrideStore";

export const AllFlags = {
  ...LDFlags,
};

type InitOptions = {
  launchDarklyKey?: string;
};

function getOverride<TFlag, TReturnType>(
  flag: TFlag,
  skipOverride: boolean,
): Promise<TReturnType> | undefined {
  if (skipOverride) {
    return undefined;
  }

  const override = useOverrideStore.getState().overrides[flag as string];

  if (override?.enabled) {
    return Promise.resolve(override.value as TReturnType);
  }

  return undefined;
}

class Client {
  flagProviders = {
    ld: new LaunchDarkly(),
    nfo: new NFO(),
  } as const;

  init({ launchDarklyKey }: InitOptions) {
    this.flagProviders.ld.init(launchDarklyKey);
    this.flagProviders.nfo.init();
  }

  setContext(context: ContextOptions) {
    this.flagProviders.ld.setContext(context);
    this.flagProviders.nfo.setContext(context);
  }

  getProvider(provider: keyof typeof this.flagProviders) {
    return this.flagProviders[provider];
  }

  // The below have a few `any`, 'never' and an `as` because each provider can have different flag keys and the type of object returned for getJson is unknown
  // As such TS has a really hard here figuring out what type `flag` and `TReturnType` should be. This problem ONLY exists here. The typings outside of here
  // are solid so consumers of this have all the correect types and know nothing of the dragons in here.

  getBool = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
    skipOverride = false,
  ): Promise<boolean> => {
    return (
      getOverride(flag as string, skipOverride) ?? this.getProvider(provider).getBool(flag as never)
    );
  };

  getDefaultBool = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
  ): boolean => {
    return this.getProvider(provider).getDefaultBool(flag as never);
  };

  getNumber = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends NumberKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
    skipOverride = false,
  ): Promise<number> => {
    return (
      getOverride(flag as string, skipOverride) ??
      this.getProvider(provider).getNumber(flag as never)
    );
  };

  getDefaultNumber = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends NumberKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
  ): number => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.getProvider(provider).getDefaultNumber(flag as never);
  };

  getString = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends StringKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
    skipOverride = false,
  ): Promise<string> => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOverride(flag as string, skipOverride) ?? this.getProvider(provider).getString(flag as any)
    );
  };

  getDefaultString = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends StringKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
  ): string => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.getProvider(provider).getDefaultString(flag as any);
  };

  getJson = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends ObjectKeys<FlagMap[TFlagProvider]>,
    TReturnType extends FlagMap[TFlagProvider][TFlag],
  >(
    provider: TFlagProvider,
    flag: TFlag,
    skipOverride = false,
  ): Promise<TReturnType> => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (
      getOverride(flag as string, skipOverride) ??
      (this.getProvider(provider).getJson(flag as never) as Promise<TReturnType>)
    );
  };

  getDefaultJson = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends ObjectKeys<FlagMap[TFlagProvider]>,
    TReturnType extends FlagMap[TFlagProvider][TFlag],
  >(
    provider: TFlagProvider,
    flag: TFlag,
  ): TReturnType => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.getProvider(provider).getDefaultJson(flag as never) as TReturnType;
  };

  registerFlagListener = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends ObjectKeys<FlagMap[TFlagProvider]>,
  >(
    provider: TFlagProvider,
    flag: TFlag,
    callback: (flag: string) => void,
  ): (() => void) => {
    // Letting any happen here because of the hard time TS is having with the type of flag and the different providers.
    // All typing external to the library is solid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.getProvider(provider).registerFlagListener(flag as any, callback);
  };

  track = <TFlagProvider extends keyof typeof this.flagProviders>(
    provider: TFlagProvider,
    metricKey: string,
  ) => {
    this.getProvider(provider).track(metricKey);
  };

  setOverride = <
    TFlagProvider extends keyof typeof this.flagProviders,
    TFlag extends keyof FlagMap[TFlagProvider],
    TFlagValue extends FlagMap[TFlagProvider][TFlag],
  >(
    _provider: TFlagProvider,
    flag: TFlag,
    value: TFlagValue,
  ) => {
    useOverrideStore.getState().setOverride(flag as string, value);
  };

  clearOverrides = () => {
    useOverrideStore.getState().clearOverrides();
  };
}

export const featureFlagClient = new Client();
