// This list comes from Wikipedia deprecated timezones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const deprecatedTimeZones: Record<string, string> = {
  "Australia/ACT": "Australia/Sydney",
  "Australia/LHI": "Australia/Lord_Howe",
  "Australia/North": "Australia/Darwin",
  "Australia/NSW": "Australia/Sydney",
  "Australia/Queensland": "Australia/Brisbane",
  "Australia/South": "Australia/Adelaide",
  "Australia/Tasmania": "Australia/Hobart",
  "Australia/Victoria": "Australia/Melbourne",
  "Australia/West": "Australia/Perth",
  "Australia/Canberra": "Australia/Sydney",
  "Australia/Yancowinna": "Australia/Broken_Hill",
  "Pacific/Yap": "Pacific/Port_Moresby",
  "Asia/Chungking": "Asia/Shanghai",
  "Brazil/Acre": "America/Rio_Branco",
  "Brazil/DeNoronha": "America/Noronha",
  "Brazil/East": "America/Sao_Paulo",
  "Brazil/West": "America/Manaus",
  "Canada/Atlantic": "America/Halifax",
  "Canada/Central": "America/Winnipeg",
  "Canada/Eastern": "America/Toronto",
  "Canada/Mountain": "America/Edmonton",
  "Canada/Newfoundland": "America/St_Johns",
  "Canada/Pacific": "America/Vancouver",
  "Canada/Saskatchewan": "America/Regina",
  "Asia/Macao": "Asia/Macau",
  "Canada/Yukon": "America/Whitehorse",
  "Asia/Ujung_Pandang": "Asia/Makassar",
  "Asia/Ulan_Bator": "Asia/Ulaanbaatar",
  "Asia/Saigon": "Asia/Ho_Chi_Minh",
  "Asia/Dacca": "Asia/Dhaka",
  "Asia/Thimbu": "Asia/Thimphu",
  "Asia/Ashkhabad": "Asia/Ashgabat",
  "Africa/Asmera": "Africa/Nairobi",
  "Asia/Istanbul": "Europe/Istanbul",
  "Asia/Tel_Aviv": "Asia/Jerusalem",
  "Europe/Nicosia": "Asia/Nicosia",
  "Europe/Tiraspol": "Europe/Chisinau",
  "Atlantic/Jan_Mayen": "Europe/Berlin",
  "Atlantic/Faeroe": "Atlantic/Faroe",
  "Europe/Belfast": "Europe/London",
  "Africa/Timbuktu": "Africa/Abidjan",
  "America/Argentina/ComodRivadavia": "America/Argentina/Catamarca",
  "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
  "America/Catamarca": "America/Argentina/Catamarca",
  "America/Cordoba": "America/Argentina/Cordoba",
  "America/Jujuy": "America/Argentina/Jujuy",
  "America/Mendoza": "America/Argentina/Mendoza",
  "America/Rosario": "America/Argentina/Cordoba",
  "America/Fort_Wayne": "America/Indiana/Indianapolis",
  "America/Indianapolis": "America/Indiana/Indianapolis",
  "America/Louisville": "America/Kentucky/Louisville",
  "America/Virgin": "America/Puerto_Rico",
  "America/Coral_Harbour": "America/Panama",
  "America/Knox_IN": "America/Indiana/Knox",
  "America/Porto_Acre": "America/Rio_Branco",
  "America/Ensenada": "America/Tijuana",
  "America/Atka": "America/Adak",
  "Pacific/Samoa": "Pacific/Pago_Pago",
  CET: "Europe/Paris",
  "Chile/Continental": "America/Santiago",
  "Chile/EasterIsland": "Pacific/Easter",
  CST6CDT: "America/Chicago",
  Cuba: "America/Havana",
  EET: "Europe/Sofia",
  Egypt: "Africa/Cairo",
  Eire: "Europe/Dublin",
  EST: "America/Cancun",
  EST5EDT: "America/New_York",
  "Etc/GMT": "GMT",
  "Etc/Greenwich": "GMT",
  "Etc/UTC": "UTC",
  "Etc/Universal": "UTC",
  "Etc/Zulu": "UTC",
  GB: "Europe/London",
  "GB-Eire": "Europe/London",
  "Etc/GMT0": "GMT",
  "GMT+0": "GMT",
  "Etc/GMT+0": "GMT",
  "Etc/GMT-0": "GMT",
  "Etc/UCT": "UTC",
  GMT0: "GMT",
  "GMT-0": "GMT",
  Greenwich: "GMT",
  Hongkong: "Asia/Hong_Kong",
  HST: "Pacific/Honolulu",
  Iceland: "Atlantic/Reykjavik",
  Iran: "Asia/Tehran",
  Israel: "Asia/Jerusalem",
  Jamaica: "America/Jamaica",
  Japan: "Asia/Tokyo",
  Kwajalein: "Pacific/Kwajalein",
  Libya: "Africa/Tripoli",
  MET: "Europe/Paris",
  "Mexico/BajaNorte": "America/Tijuana",
  "Mexico/BajaSur": "America/Mazatlan",
  "Mexico/General": "America/Mexico_City",
  MST: "America/Phoenix",
  MST7MDT: "America/Denver",
  Navajo: "America/Denver",
  NZ: "Pacific/Auckland",
  "NZ-CHAT": "Pacific/Chatham",
  Poland: "Europe/Warsaw",
  Portugal: "Europe/Lisbon",
  PRC: "Asia/Shanghai",
  PST8PDT: "America/Los_Angeles",
  ROC: "Asia/Taipei",
  ROK: "Asia/Seoul",
  Singapore: "Asia/Singapore",
  Turkey: "Europe/Istanbul",
  UCT: "UTC",
  Universal: "UTC",
  "US/Alaska": "America/Anchorage",
  "US/Aleutian": "America/Adak",
  "US/Arizona": "America/Phoenix",
  "US/Central": "America/Chicago",
  "US/Eastern": "America/New_York",
  "US/East-Indiana": "America/Indiana/Indianapolis",
  "US/Hawaii": "Pacific/Honolulu",
  "US/Indiana-Starke": "America/Indiana/Knox",
  "US/Michigan": "America/Detroit",
  "US/Mountain": "America/Denver",
  "US/Pacific": "America/Los_Angeles",
  "US/Pacific-New": "America/Los_Angeles",
  "US/Samoa": "Pacific/Pago_Pago",
  WET: "Europe/Lisbon",
  "W-SU": "Europe/Moscow",
  Zulu: "UTC",

  // Mapping GMT Based ones to Timezones that Hermes knows
  "Etc/GMT-14": "Pacific/Kiritimati",
  "Etc/GMT-13": "Pacific/Apia",
  "Etc/GMT-12": "Pacific/Auckland",
  "Etc/GMT-11": "Pacific/Bougainville",
  "Etc/GMT-10": "Pacific/Guam",
  "Etc/GMT-9": "Pacific/Palau",
  "Etc/GMT-8": "Antarctica/Casey",
  "Etc/GMT-7": "Antarctica/Davis",
  "Etc/GMT-6": "Asia/Bishkek",
  "Etc/GMT-5": "Asia/Dushanbe",
  "Etc/GMT-4": "Asia/Tbilisi",
  "Etc/GMT-3": "Europe/Istanbul",
  "Etc/GMT-2": "Europe/Kaliningrad",
  "Etc/GMT-1": "Africa/Algiers",
  "Etc/GMT+1": "Atlantic/Cape_Verde",
  "Etc/GMT+2": "Atlantic/South_Georgia",
  "Etc/GMT+3": "Atlantic/Stanley",
  "Etc/GMT+4": "America/Boa_Vista",
  "Etc/GMT+5": "America/Bogota",
  "Etc/GMT+6": "Pacific/Galapagos",
  "Etc/GMT+7": "America/Whitehorse",
  "Etc/GMT+8": "Pacific/Pitcairn",
  "Etc/GMT+9": "Pacific/Gambier",
  "Etc/GMT+10": "Pacific/Honolulu",
  "Etc/GMT+11": "Pacific/Niue",
  "Etc/GMT+12": "Pacific/Niue", // There is no replacement so we are getting as close as we can...

  // This timezone is not deprecated, but it is not working with react-native hermes right now
  "Asia/Kolkata": "Asia/Calcutta",
};

export const getTimezone = (tz: string) => {
  return tz in deprecatedTimeZones ? (deprecatedTimeZones[tz] as string) : tz;
};
