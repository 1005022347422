import { CLEAR_SEARCH_TEXT, SET_SEARCH_TEXT, WIPE_REDUX } from "@meraki/shared/redux";
import { AnyAction } from "redux";

import { WipeReduxAction } from "~/actions";
import resolvedState from "~/lib/ReducerUtils";

type SearchState = Record<string, string>;

interface SetSearchTextAction extends AnyAction {
  type: typeof SET_SEARCH_TEXT;
  searchKey: string;
  searchText: string;
}

interface ClearSearchTextAction extends AnyAction {
  type: typeof CLEAR_SEARCH_TEXT;
  searchKey: string;
}

type SearchAction = SetSearchTextAction | ClearSearchTextAction | WipeReduxAction;

const initialState: SearchState = {};

export default function search(
  state: SearchState = initialState,
  action: SearchAction,
): SearchState {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        [action.searchKey]: action.searchText,
      };
    case CLEAR_SEARCH_TEXT: {
      const nextState = {
        ...state,
        [action.searchKey]: "",
      };
      return resolvedState(nextState, state);
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
