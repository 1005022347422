import { I18n } from "@meraki/core/i18n";
import {
  LiveToolCommand,
  LiveToolContext,
  LiveToolStatus,
  usePing,
} from "@meraki/react-live-broker";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import {
  BUTTON_SIZING,
  KEYBOARD_TYPE,
  PING_TIMEOUT,
  RETURN_KEY,
  SPACING,
} from "~/constants/MkiConstants";
import PingInProgressStats from "~/go/components/liveTools/ping/PingInProgressStats";
import PingWebsiteResults from "~/go/components/liveTools/ping/pingWebsite/PingWebsiteResults";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { platformSelect } from "~/lib/PlatformUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { CloseButton } from "~/shared/navigation/Buttons";
import Device from "~/shared/types/Device";

interface PingProgressProps {
  device: Device;
  isFinished: boolean;
  isPinging: boolean;
  isStopped: boolean;
  status: LiveToolStatus;
  setCommand: (command: LiveToolCommand) => void;
  website: string;
}

export interface PingWebsiteProps {
  isFinished: boolean;
  isFocused: boolean;
  isPinging: boolean;
  isStopped: boolean;
  onStart: (e?: NativeSyntheticEvent<unknown>) => void;
  setWebsite: (website: string) => void;
  toggleFocus: (e?: NativeSyntheticEvent<unknown>) => void;
  website: string;
}

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "PingWebsite">;

export function PingProgress(props: PingProgressProps) {
  const { device, isFinished, isPinging, isStopped, status, setCommand, website } = props;

  const navigation = useNavigation<Props["navigation"]>();
  const pingStats = usePing(device.id, website);

  const onStop = useCallback(() => {
    setCommand("stop");
  }, [setCommand]);

  const onRestart = useCallback(() => {
    setCommand("restart");
  }, [setCommand]);

  const timeout = useMemo(() => {
    // Reset/set timeout on status change to running
    if (status === LiveToolStatus.running) {
      return setTimeout(onStop, PING_TIMEOUT);
    }
    return undefined;
  }, [status, onStop]);

  if (isPinging) {
    return (
      <PingInProgressStats
        onStopPress={onStop}
        title={I18n.t("LIVE_TOOLS.TESTING_CONNECTION", { name: website })}
        averageLatency={pingStats?.averageLatency}
        lossRate={pingStats?.lossRate}
        pings={pingStats?.pings}
      />
    );
  } else if (isStopped || isFinished) {
    if (timeout) {
      clearTimeout(timeout);
    }

    return (
      <PingWebsiteResults
        onShowSearchSubjectPress={() => navigation.navigate("SearchSubject")}
        onShowPingHostPress={() =>
          navigation.navigate("PingHost", {
            device,
          })
        }
        onRestartPress={onRestart}
        averageLatency={pingStats?.averageLatency}
        lossRate={pingStats?.lossRate}
        device={device}
        website={website}
      />
    );
  }

  return null;
}

export function PingWebsiteInput(props: PingWebsiteProps) {
  const { isFinished, isFocused, isPinging, isStopped, onStart, setWebsite, toggleFocus, website } =
    props;

  const text = !(isStopped || isFinished) && (
    <MkiText screenStyles={styles.infoText}>{I18n.t("LIVE_TOOLS.PING_WEB_TEXT")}</MkiText>
  );

  const keyboardType = platformSelect({
    android: KEYBOARD_TYPE.emailAddress,
    ios: KEYBOARD_TYPE.url,
  });

  /* TODO jims: replace this with an InputRow */
  const inputStyle: StyleProp<ViewStyle>[] = [styles.inputText];
  if (isFocused) {
    inputStyle.push(styles.inputFocused);
  }

  const input = !(isPinging || isFinished || isStopped) && (
    <View>
      <MkiText textStyle="smallSecondary">{I18n.t("LIVE_TOOLS.PING_WEBSITE")}</MkiText>
      <View style={inputStyle}>
        <MkiTextInput
          testID={"PING_WEBSITE_INPUT"}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          onEndEditing={toggleFocus}
          placeholder={I18n.t("BLOCK_CONTENT.BLOCK_WEBSITE_PLACEHOLDER")}
          onChangeText={(newValue: string) => setWebsite(newValue)}
          keyboardType={keyboardType}
          returnKeyType={RETURN_KEY.done}
          underlineColorAndroid="transparent"
          autoCapitalize="none"
          onSubmitEditing={onStart}
          enablesReturnKeyAutomatically
        />
      </View>
    </View>
  );

  const button = !(isPinging || isFinished || isStopped) && (
    <View style={styles.buttonContainer}>
      <RoundedButton
        buttonType={ButtonType.secondary}
        onPress={() => onStart()}
        disabled={website === ""}
        screenStyles={styles.buttonStyle}
      >
        {I18n.t("TEST")}
      </RoundedButton>
    </View>
  );

  return (
    <>
      {text}
      {input}
      {button}
    </>
  );
}

function PingWebsiteScreen({ device }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const [isFocused, setFocus] = useState(false);
  const [website, setWebsite] = useState("");
  const [isStarted, setStarted] = useState(false);
  const [status, setStatus] = useState(LiveToolStatus.idle);
  const [command, setCommand] = useState<LiveToolCommand>("start" as const);

  const isPinging = status === LiveToolStatus.connecting || status === LiveToolStatus.running;
  const isFinished = status === LiveToolStatus.completed;
  const isStopped = command === "stop" && !isPinging;

  const toggleFocus = useCallback(() => {
    setFocus(!isFocused);
  }, [setFocus, isFocused]);

  const onStart = useCallback(() => {
    if (isFinished) {
      setCommand("restart");
    } else {
      setCommand("start");
    }

    setStarted(true);
  }, [isFinished, setCommand, setStarted]);

  const liveToolContext = {
    command,
    onStatusChange: setStatus,
  };

  return (
    <FullScreenContainerView>
      <ScrollView
        contentContainerStyle={styles.contentContainer}
        keyboardShouldPersistTaps="handled"
      >
        <PingWebsiteInput
          isFinished={isFinished}
          isFocused={isFocused}
          isPinging={isPinging}
          isStopped={isStopped}
          onStart={onStart}
          setWebsite={setWebsite}
          toggleFocus={toggleFocus}
          website={website}
        />
        <LiveToolContext.Provider value={liveToolContext}>
          {isStarted && (
            <PingProgress
              device={device}
              isFinished={isFinished}
              isPinging={isPinging}
              isStopped={isStopped}
              status={status}
              setCommand={setCommand}
              website={website}
            />
          )}
        </LiveToolContext.Provider>
      </ScrollView>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.large,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  contentContainer: {
    paddingHorizontal: SPACING.default,
  },
  infoText: {
    color: MkiColors.secondaryTextColor,
    textAlign: "left",
    width: "92%",
    paddingBottom: SPACING.extraLarge,
  },
  inputFocused: {
    borderBottomColor: MkiColors.activeInputUnderline,
  },
  inputText: {
    paddingVertical: SPACING.small,
    borderBottomWidth: 1,
    borderBottomColor: MkiColors.inactiveInputUnderline,
  },
});

export default PingWebsiteScreen;
