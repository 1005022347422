import { memo } from "react";
import { StyleProp, ViewStyle } from "react-native";

import { CombinedAppliancePort } from "~/api/queries/appliances/useCombinedAppliancePorts";
import { isPortEnabled } from "~/lib/GXPortUtils";
import PortIcon, {
  PortIconSizes,
  PortIconTypes,
} from "~/shared/components/portDiagrams/portIcons/PortIcon";

export interface GXPortIconProps {
  livePortData: CombinedAppliancePort;
  screenStyles?: StyleProp<ViewStyle>;
  size: PortIconSizes;
}

const GXPortIcon: React.FC<GXPortIconProps> = (props: GXPortIconProps) => {
  const { livePortData, screenStyles } = props;

  const connected = livePortData?.carrier;
  return (
    <PortIcon
      size={PortIconSizes.large}
      portType={PortIconTypes.rj45}
      connected={connected}
      uplink={connected && livePortData?.number === 1}
      enabled={isPortEnabled(livePortData)}
      screenStyles={screenStyles}
    />
  );
};

GXPortIcon.defaultProps = {
  screenStyles: {},
};

export default memo(GXPortIcon);
