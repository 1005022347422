import { memo } from "react";
import { StyleSheet } from "react-native";
import SegmentedControlTab, {
  SegmentedControlTabProperties,
} from "react-native-segmented-control-tab";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLES } from "~/constants/MkiTextStyles";
import { themeColors } from "~/lib/themeHelper";
import SharedColors from "~/shared/constants/SharedColors";
import { useTheme } from "~/shared/hooks/useTheme";

const MkiSegmentedControl = (props: SegmentedControlTabProperties) => {
  const navColors = themeColors(useTheme()).navigation;
  const navPrimaryTextStyle = {
    color: navColors.primary,
  };

  return (
    <SegmentedControlTab
      tabStyle={[{ borderColor: navColors.primary }, styles.tabStyle]}
      activeTabStyle={styles.activeTabStyle}
      activeTabTextStyle={[TEXT_STYLES.small, navPrimaryTextStyle, styles.activeTabTextStyle]}
      tabTextStyle={[TEXT_STYLES.small, navPrimaryTextStyle]}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  tabStyle: {
    marginVertical: SPACING.default,
    backgroundColor: SharedColors.transparent,
  },
  activeTabStyle: {
    backgroundColor: MkiColors.primaryButtonLight,
  },
  activeTabTextStyle: {
    fontWeight: "600",
  },
});

export default memo(MkiSegmentedControl);
