import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const NetworkSchema = z
  .object({
    enrollmentString: z.string().nullable(),
    id: z.string(),
    isBoundToConfigTemplate: z.boolean().or(z.undefined()),
    name: z.string(),
    notes: z.string().nullable(),
    organizationId: z.string(),
    productTypes: z.array(
      z.union([
        z.literal("wireless"),
        z.literal("appliance"),
        z.literal("switch"),
        z.literal("camera"),
        z.literal("cellularGateway"),
        z.literal("sensor"),
        z.literal("systemsManager"),
        z.literal("frontizo"),
        z.literal("phone"),
      ] as const),
    ),
    tags: z.array(z.string()),
    timeZone: z.string(),
    url: z.string(),
  })
  .describe("NetworkSchema");

export type Network = z.infer<typeof NetworkSchema>;

type NetworkRequest = {
  networkId?: string;
};

export type MutateNetworkRequest = NetworkRequest & {
  body: Partial<Network>;
};

function buildUrl({ networkId }: NetworkRequest) {
  return `/api/v1/networks/${networkId}`;
}

function fetchNetwork({ networkId }: NetworkRequest): Promise<Network> {
  return request(NetworkSchema, "GET", buildUrl({ networkId }));
}

function mutateNetwork({ networkId, body }: MutateNetworkRequest) {
  return request(NetworkSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify(body),
  });
}

function deleteNetwork({ networkId }: NetworkRequest): Promise<void> {
  return request(z.undefined(), "DELETE", buildUrl({ networkId }));
}

export const useNetwork = createQuery<NetworkRequest, Network>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchNetwork(variables),
  requiredVariables: ["networkId"],
});

export const useEditNetwork = createMutation<MutateNetworkRequest, Network, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters: MutateNetworkRequest) => mutateNetwork(parameters),
});

export const useDeleteNetwork = createMutation<NetworkRequest, void, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => deleteNetwork(variables),
});
