import { useMagneticTheme } from "@meraki/magnetic/themes";
import { View as RNView, ViewProps } from "react-native";

import { SpacingStyle } from "../Box/Box";
import { getScreenStyles } from "./styles";

export type ScreenProps = Omit<ViewProps, "style"> &
  SpacingStyle & {
    addDefaultPadding?: boolean;
  };

export function View({ children, addDefaultPadding, ...rest }: ScreenProps) {
  const theme = useMagneticTheme();

  const { style: spacingStyle, ...spacingRest } = getScreenStyles(rest, addDefaultPadding, theme);

  return (
    <RNView
      {...spacingRest}
      style={[{ flex: 1, backgroundColor: theme.color.default.bg.base }, spacingStyle]}
    >
      {children}
    </RNView>
  );
}
