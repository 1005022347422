import { DeviceStatus } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useQuery } from "@tanstack/react-query";

import PublicApiNetwork from "~/api/models/Network";
import organizationsKeys from "~/api/queries/organizations/keys";
import useOrgDeviceStatuses from "~/api/queries/organizations/useOrgDeviceStatuses";
import { Device, DevicesSchema, ProductType } from "~/api/schemas/Device";
import { UseQueryOptionsWithoutFnOrKey } from "~/api/util/query";
import { validatedMerakiRequest } from "~/api/util/request";
import { getCurrentNetwork } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

type DeviceQueryParams = {
  productTypes?: ProductType[];
  serial?: string;
};

const orgDevicesKeys = {
  devices: (orgId?: string, networkId?: string, queryParams?: DeviceQueryParams) =>
    [...organizationsKeys.organizations(orgId), "devices", networkId, queryParams] as const,
};

type OrgDevicesKey = ReturnType<(typeof orgDevicesKeys)["devices"]>;

const fetchOrgDevices = (
  orgId: string,
  network: PublicApiNetwork,
  deviceQueryParams: DeviceQueryParams,
): Promise<Device[]> => {
  return validatedMerakiRequest(DevicesSchema, "GET", `/api/v1/organizations/${orgId}/devices`, {
    queryParams: {
      networkIds: [network.id],
      ...deviceQueryParams,
    },
  });
};

/**
 * Get the devices for the organization
 * @param orgId defaults to current org if not passed
 * @param productTypeFilter filters devices by matching productType
 */
const useOrgDevices = <TData = Device[]>(
  deviceQueryParams: DeviceQueryParams,
  orgId?: string,
  queryOpts?: UseQueryOptionsWithoutFnOrKey<Device[], Error, TData, OrgDevicesKey>,
) => {
  const currentNetwork = useAppSelector(getCurrentNetwork);
  return useQuery({
    queryKey: orgDevicesKeys.devices(orgId, currentNetwork?.id, deviceQueryParams),
    queryFn: async () => {
      if (!currentNetwork) {
        throw new Error("Cannot get org devices, current network is not set in Redux.");
      }
      if (!orgId) {
        throw new Error("Cannot get current org devices. No current org set in Redux.");
      }
      return await fetchOrgDevices(orgId, currentNetwork, deviceQueryParams);
    },
    ...queryOpts,
  });
};

export function useCurrentOrgDevices<TData = Device[]>(
  productTypes?: ProductType[],
  queryOpts?: UseQueryOptionsWithoutFnOrKey<Device[], Error, TData, OrgDevicesKey>,
) {
  const currentOrgId = useCurrentOrganizationId();
  return useOrgDevices({ productTypes }, currentOrgId, queryOpts);
}

export const useCurrentOrgDeviceBySerial = (
  serial?: string,
  queryOpts?: UseQueryOptionsWithoutFnOrKey<Device[], Error, Device[], OrgDevicesKey>,
) => {
  const currentOrgId = useCurrentOrganizationId();
  return useOrgDevices({ serial }, currentOrgId, queryOpts);
};

export function useCurrentOrgCameras<TData = Device[]>(
  queryOpts?: UseQueryOptionsWithoutFnOrKey<Device[], Error, TData, OrgDevicesKey>,
) {
  return useCurrentOrgDevices(["camera"], queryOpts);
}

export type DeviceWithStatus = Device & Partial<Pick<DeviceStatus, "status">>;

export const useCurrentOrgDevicesWithStatus = (
  productTypes?: ProductType[],
  selectCallback?: (device: DeviceWithStatus[]) => DeviceWithStatus[],
) => {
  const deviceStatusesQuery = useOrgDeviceStatuses();

  const currentOrgId = useCurrentOrganizationId();

  const devicesQuery = useOrgDevices<DeviceWithStatus[]>({ productTypes }, currentOrgId, {
    select: (devices) => {
      const devicesWithStatus = devices.map((device) => ({
        ...device,
        status: deviceStatusesQuery.data?.find((s) => s.serial === device.serial)?.status,
      }));
      return selectCallback ? selectCallback(devicesWithStatus) : devicesWithStatus;
    },
  });
  return [devicesQuery, deviceStatusesQuery] as const;
};

export default useOrgDevices;
