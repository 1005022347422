import {
  LB_ADD_PUBLISH_DATA,
  LB_CONNECTED,
  LB_DISCONNECT,
  LB_DISCONNECTED,
  LB_INIT,
  LB_REMOVE_PUBLISH_DATA,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { omit } from "lodash";

import resolvedState from "~/lib/ReducerUtils";

const initialState = {
  connected: false,
  initialized: false,
  publishData: {},
};

export default function liveBroker(state = initialState, action: any) {
  switch (action.type) {
    case LB_INIT:
      return {
        ...state,
        initialized: true,
      };
    case LB_CONNECTED:
      return {
        ...state,
        connected: true,
      };
    case LB_DISCONNECTED:
      return {
        ...state,
        connected: false,
      };
    case LB_ADD_PUBLISH_DATA: {
      const nextState = {
        ...state,
        publishData: {
          ...state.publishData,
          [action.id]: action.request,
        },
      };
      return resolvedState(nextState, state);
    }
    case LB_REMOVE_PUBLISH_DATA:
      return {
        ...state,
        publishData: omit(state.publishData, [action.id]),
      };
    case LB_DISCONNECT:
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
