import z from "zod";

export const DynamicDnsSchema = z
  .object({
    prefix: z.string(),
    enabled: z.boolean(),
    url: z.string(),
  })
  .describe("DynamicDnsSchema");

export const ApplianceSettingsSchema = z
  .object({
    clientTrackingMethod: z.string(),
    deploymentMode: z.string(),
    dynamicDns: DynamicDnsSchema,
  })
  .describe("ApplianceSettingsSchema");

export type DynamicDns = z.infer<typeof DynamicDnsSchema>;
export type ApplianceSettings = z.infer<typeof ApplianceSettingsSchema>;
