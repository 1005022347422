import { analytics } from "@meraki/core/firebase";
import _ from "lodash";
import { useState } from "react";

export type FiltersState<
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
> = {
  [K in FilterCategory]?: Set<FiltersObject[K]>;
};

export type FilterConfiguration<
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
  FilterDataType extends { [K in FilterCategory]?: unknown },
> = (item: FilterDataType) => { [Key in FilterCategory]: Record<FiltersObject[Key], boolean> };

function matchFilters<
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
  FilterDataType extends Record<FilterCategory, unknown>,
>(
  datapoint: FilterDataType,
  filters: FiltersState<FilterCategory, FiltersObject>,
  filterConfiguration?: FilterConfiguration<FilterCategory, FiltersObject, FilterDataType>,
): boolean {
  const config = filterConfiguration?.(datapoint);
  const categories = Object.keys(filters) as FilterCategory[];
  const datapointMatchesFilters = categories.map((category) => {
    const selectedPills: Set<string> | undefined = filters[category];
    if (selectedPills === undefined) {
      return false;
    }

    if (config && category in config) {
      const pills = Array.from(selectedPills);
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const categoriesMatched = pills.filter((pill) => config[category][pill]);
      return categoriesMatched.length > 0;
    }

    // if no config passed in, check if value matches one of the filter pills
    const valueToCompare = datapoint[category];
    if (typeof valueToCompare !== "string") {
      return false;
    }
    return selectedPills?.has(valueToCompare) ?? false;
  });

  return datapointMatchesFilters.every(Boolean);
}

type FilterPairs<
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
> = (readonly [FilterCategory, FiltersObject[FilterCategory]])[];

export const toFilterPairs = <
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
>(
  filterState: FiltersState<FilterCategory, FiltersObject>,
): FilterPairs<FilterCategory, FiltersObject> => {
  const categories = Object.keys(filterState) as FilterCategory[];
  return categories.flatMap((category) =>
    Array.from(filterState[category] ?? []).map((fv) => [category, fv] as const),
  );
};

const diffFilters = <
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
>(
  oldFilters: FiltersState<FilterCategory, FiltersObject>,
  newFilters: FiltersState<FilterCategory, FiltersObject>,
): FilterPairs<FilterCategory, FiltersObject> => {
  const newFilterPairs = toFilterPairs<FilterCategory, FiltersObject>(newFilters);
  return newFilterPairs.filter(
    ([filterCategory, filterValue]) => !oldFilters[filterCategory]?.has(filterValue),
  );
};

/**
 * A custom useState hook that updates a list of data of object type T with various filters
 * matching the keys of T.
 *
 * FilterCategory is a key on type T that can be filtered.
 *
 * FiltersObject is an object type where each key is a FilterCategory and the value for each
 * key is the valid strings for that category.
 *
 * Ex: For the datatype T = { productType: "switch" | "camera", status: "offline" | "online", id: number }
 * The FilterCategory generic type could be "productType" | "status", indicating these are the filterable properties
 * The FiltersObject is the subset object { productType: "switch" | "camera", status: "offline" | "online"}
 */
export function useFilteredState<
  FilterCategory extends string,
  FiltersObject extends Record<FilterCategory, string>,
  FilterDataType extends { [K in FilterCategory]?: unknown },
>(
  initialFiltersState: FiltersState<FilterCategory, FiltersObject>,
  data: FilterDataType[],
  options: {
    /**
     * when onFilterChange is passed in, the FilterState assumes user is implementing filtering themself,
     * in this case the hook returns all data passed in as filteredData
     */
    onFilterChange?: (filters: FiltersState<FilterCategory, FiltersObject>) => void;
    /**
     * when filterConfiguration is passed in, filtering logic is handled by custom
     * boolean-returning functions written by user
     */
    filterConfiguration?: FilterConfiguration<FilterCategory, FiltersObject, FilterDataType>;
  } = {},
) {
  const { onFilterChange, filterConfiguration } = options;
  const [filtersState, setFiltersState] =
    useState<FiltersState<FilterCategory, FiltersObject>>(initialFiltersState);

  const updateFilters = (filters: FiltersState<FilterCategory, FiltersObject>) => {
    setFiltersState(filters);
    if (onFilterChange) {
      onFilterChange(filters);
    }
  };

  const setFilters = (filters: FiltersState<FilterCategory, FiltersObject>) => {
    const filterDiff = diffFilters(filtersState, filters);
    filterDiff.forEach(([filterCategory, filterValue]) => {
      analytics.logEvent("list_filter", { filterCategory, filterValue });
    });
    updateFilters(filters);
  };

  const removeFilter = (
    filterCategory: FilterCategory,
    filterValue: FiltersObject[FilterCategory],
  ) => {
    const newFilters = new Set(filtersState[filterCategory]);
    newFilters.delete(filterValue);

    const newFilterState: FiltersState<FilterCategory, FiltersObject> =
      newFilters.size === 0
        ? _.omit<FiltersState<FilterCategory, FiltersObject>>(filtersState, filterCategory)
        : { ...filtersState, [filterCategory]: newFilters };
    updateFilters(newFilterState);
  };

  const clearFilters = () => updateFilters({});

  const filteredData: FilterDataType[] = onFilterChange
    ? data
    : data.filter((t) => matchFilters(t, filtersState, filterConfiguration));

  return {
    setFilters,
    clearFilters,
    removeFilter,
    filters: filtersState,
    filteredData,
  };
}
