import { ErrorMessage } from "@hookform/error-message";
import { Notification, Toggle, ToggleProps } from "@meraki/magnetic/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormToggleProps = Omit<ToggleProps, "checked"> &
  Partial<Pick<ToggleProps, "checked">> &
  FormItemProps;

export function FormToggle({ name, rules, onValueChange, ...rest }: FormToggleProps) {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Toggle
            {...rest}
            checked={value}
            onValueChange={(value) => {
              onChange(value);
              onValueChange?.(value);
            }}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
}
