import * as errorMonitor from "@meraki/core/errors";
import { ComponentType, PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import ConfigHeader from "~/go/components/ConfigHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { IP_ASSIGNMENT } from "~/lib/SSIDUtils";
import { currentNetworkState, ssidsSelector } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import { CloseButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  networkId: string;
  ssid: SSID;
  c2cEnabled: boolean;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "ClientIsolation"> &
  ReduxProps &
  BasicActions &
  WithCancelablePromiseProps &
  PendingComponent;

type ClientIsolationScreenState = {
  allowCommunication: boolean;
  reqPending?: boolean;
};

export class ClientIsolationScreen extends PureComponent<Props, ClientIsolationScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      allowCommunication: props.c2cEnabled,
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  UNSAFE_componentWillMount() {
    const { ssid, networkId, actions, cancelablePromise, setReqPending, c2cEnabled } = this.props;
    if (!ssid) {
      setReqPending(true);
      cancelablePromise(actions.getSsids(networkId))
        .then(() => {
          setReqPending(false);
          this.setState({
            allowCommunication: c2cEnabled,
          });
        })
        .catch(({ isCanceled }) => {
          if (isCanceled) {
            return;
          }

          setReqPending(false);
          showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { c2cEnabled } = this.props;
    if (nextProps.c2cEnabled !== c2cEnabled) {
      this.setState({ allowCommunication: nextProps.c2cEnabled });
    }
  }

  onToggle = (enabled: boolean) => {
    this.setState({ allowCommunication: enabled });
    const { actions, networkId, ssid, cancelablePromise, setReqPending } = this.props;

    setReqPending(true);
    return cancelablePromise(
      actions.setSsid(networkId, {
        number: ssid.number,
        // TODO: remove toString() call once backend bug is fixed
        c2cEnabled: enabled.toString(),
      }),
    )
      .then(() => {
        setReqPending(false);
      })
      .catch(({ isCanceled }) => {
        if (isCanceled) {
          return;
        }

        setReqPending(false);
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
      });
  };

  render() {
    const { allowCommunication, reqPending } = this.state;
    const { ssid } = this.props;
    const configContent =
      ssid.ipAssignmentMode === IP_ASSIGNMENT.BRIDGE ? (
        // c2c can not be enabled if the ssid is in Bridge mode
        // hopefully we successfully prevent the user from navigating to this screen
        // so this wouldn't be an issue
        <MkiText screenStyles={styles.errorText}>{I18n.t("MUST_BE_IN_NAT_ERROR")}</MkiText>
      ) : (
        <SwitchRow
          value={allowCommunication}
          onValueChange={this.onToggle}
          subtitle={I18n.t("CLIENT_ISOLATION.DESCRIPTION")}
          disabled={reqPending}
          testID="C2C.SWITCH"
        >
          {I18n.t("CLIENT_ISOLATION.SWITCH")}
        </SwitchRow>
      );

    return (
      <View style={styles.container}>
        <ConfigHeader
          title={I18n.t("CLIENT_ISOLATION.TITLE")}
          description={I18n.t("CLIENT_ISOLATION.SUBTITLE")}
        />
        {configContent}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    marginRight: SPACING.default,
  },
  errorText: {
    marginLeft: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["ClientIsolation"],
): ReduxProps {
  const ssid = ssidsSelector(state)[props.ssidNumber];
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for ClientIsolationScreen",
      currentNetworkState(state),
    ),
    ssid,
    c2cEnabled: ssid ? ssid.c2cEnabled : null,
  };
}

export default compose<ComponentType<Props>>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(ClientIsolationScreen);
