/* Copied from private/javascripts/SwitchportStatusUtils.js */
export const SWITCHPORT_STATUS_FLAGS = {
  SPEED_10: 1,
  SPEED_100: 2,
  SPEED_1000: 3,
  SPEED_2500: 4,
  SPEED_5000: 5,
  SPEED_10000: 6,
  SPEED_20000: 7,
  SPEED_40000: 8,
  CONNECTED: 0xf,
  FULL_DUPLEX: 16,
  SPEED_DOWNSHIFT: 256,
  USING_SFP: 1024,
  POE_OVERLOAD: 262144,
  POE_UNDERLOAD: 524288,
  POE_DENIED: 1048576,
  LACP_DISABLED: 2097152,
  AP_NOAUTH: 4194304,
  GUEST_ENABLED: 8388608,
  UDLD_PORT_BLOCKED: 33554432,
  UDLD_ERR_MASK: 201326592,
  UDLD_ERR_TX_RX_LOOP: 67108864,
  UDLD_ERR_N_MISMATCH: 134217728,
  UDLD_ERR_UNIDIR: 201326592,
};

/* Copied from private/javascripts/SwitchportStatusUtils.js */
export const SWITCHPORT_STP_FLAGS = {
  STP_STATE_LEARNING: 0x1,
  STP_STATE_FORWARDING: 0x2,
  STP_STATE_DISCARDING: 0x4,
  STP_VERSION_LEGACY_STP: 0x8,
  STP_IS_EDGE: 0x10,
  STP_IS_PTP: 0x20,
  STP_ROOTGUARD_ACTIVE: 0x40,
  STP_BPDUGUARD_ACTIVE: 0x80,
  STP_LOOPGUARD_ACTIVE: 0x100,
  STP_BPDU_CONFLICT: 0x200,
};

/* Copied from private/javascripts/SwitchportStatusUtils.js */
export const SWITCHPORT_STATUS_TYPE = {
  STATUS_DISCONNECTED: 0,
  STATUS_OPTIMAL: 1,
  STATUS_SUBOPTIMAL: 2,
  STATUS_WARNING: 3,
  STATUS_ERROR: 4,
};

/* Copied from private/javascripts/SwitchportStatusUtils.js */
export const SWITCHPORT_USAGE_FLAGS = {
  CRC_WARNING: 0x1,
  CRC_ERROR: 0x2,
  JABBERS_WARNING: 0x4,
  JABBERS_ERROR: 0x8,
  FRAGS_WARNING: 0x10,
  FRAGS_ERROR: 0x20,
  MISSIZED_WARNING: 0x40,
  MISSIZED_ERROR: 0x80,
  COLLISIONS_WARNING: 0x100,
  COLLISIONS_ERROR: 0x200,
  STP_TCS_WARNING: 0x400,
  STP_TCS_ERROR: 0x800,
};
