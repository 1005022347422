import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { getColorTokenForIndex, PieChart } from "@meraki/magnetic/charts";
import { Card, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";
import { formatKibibytes } from "@meraki/shared/formatters";

type Props = {
  data: { name: string; value: number }[];
  graphOnLeft?: boolean;
  testID?: string;
} & AnalyticsProp<"onPress">;

type LabelProps = {
  color: ColorToken;
  name: string;
  usage: string;
  ratio: number;
};

function Label({ name, usage, ratio, color }: LabelProps) {
  return (
    <Box flexDirection="row" alignItems="center">
      <Box backgroundColor={color} height={10} width={10} borderRadius="sm" marginRight="xs" />
      <Box flex={1}>
        <Text weight="semiBold" size="p3" numberOfLines={1}>
          {name}
        </Text>
        <Text color="default.text.medium.base">
          {String(usage)} ({(ratio * 100).toFixed(2)}%)
        </Text>
      </Box>
    </Box>
  );
}

export function ApplicationUsageChart({ data, graphOnLeft, testID, analytics }: Props) {
  const trackEvent = useAnalytics(analytics);
  const total = data.reduce((sum, cur) => sum + cur.value, 0);
  const noUsage = !data || data.length < 1;

  if (noUsage) {
    return (
      <Text size="p2" testID="EMPTY_STATE">
        {I18n.t("APPLICATION_USAGE.NO_DATA")}
      </Text>
    );
  }

  return (
    <Box
      testID={testID}
      flex={1}
      flexDirection={graphOnLeft ? "row-reverse" : "row"}
      alignContent="center"
      alignItems="center"
      justifyContent="space-between"
    >
      <Card.Well
        flex={1}
        testID="APPLICATION_USAGE_PIE_CHART_LEGEND"
        onPress={() => {
          trackEvent("onPress", { interaction: "legend" });
        }}
      >
        {data.map(({ name, value }, idx) => (
          <Label
            key={idx}
            name={name}
            usage={formatKibibytes(value)}
            ratio={value / total}
            color={getColorTokenForIndex(idx)}
          />
        ))}
      </Card.Well>
      <Box
        width={150}
        testID="APPLICATION_USAGE_PIE_CHART"
        onTouchEnd={(e) => {
          e.stopPropagation();
        }}
        onStartShouldSetResponder={() => true}
      >
        <PieChart
          kind="donut"
          size="md"
          showLabel={false}
          data={data}
          dimensions={[{ name: "value" }, { name: "name" }]}
        />
      </Box>
    </Box>
  );
}
