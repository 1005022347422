import { WIPE_REDUX } from "@meraki/shared/redux";
import { CometD } from "cometd";
import {
  Action,
  ActionCreatorsMapObject,
  AnyAction,
  bindActionCreators,
  Dispatch,
  MiddlewareAPI,
} from "redux";
import { ParametricSelector, Selector } from "reselect";

import type { ApiAction, ApiDispatch, ApiResponseAction } from "~/middleware/api";
import type { RootReducer } from "~/reducers";
import type { AppDispatch } from "~/store/configureStore";

export type { ApiAction, ApiDispatch, AppDispatch };

export type RootState = ReturnType<RootReducer>;

export type GetState = () => RootState;

export type AppStore = MiddlewareAPI<ApiDispatch, RootState>;

export type ReduxSelector<T> = Selector<RootState, T | undefined>;

// TODO: replace the other one with this for more accurate typings
export type MkiSelector<T, P = never> = [P] extends [never]
  ? Selector<RootState, T>
  : ParametricSelector<RootState, P, T>;

export interface AppThunkDispatch<ReturnType, ExtraThunkArg> {
  /** Accepts a thunk function, runs it, and returns whatever the thunk itself returns */
  (thunkAction: AppThunkAction<ReturnType, ExtraThunkArg>): ReturnType;
  /** Accepts a standard action object, and returns that action object */
  <A extends Action>(action: A): A;
  /** Accepts a api action object, and returns a promise */
  <ReturnType>(action: ApiAction<ReturnType>): Promise<ApiResponseAction<ReturnType>>;
  /** A union of the other two overloads for TS inference purposes */
  <ReturnType, A extends Action | ApiAction<ReturnType>>(
    action: A | AppThunkAction<ReturnType, ExtraThunkArg>,
  ): ActionCreatorReturnType<ReturnType> | ReturnType;
}

export type AppThunk<ReturnType = void> = AppThunkAction<ReturnType, CometD>;

export type AppThunkAction<R, E> = (
  dispatch: AppThunkDispatch<R, E>,
  getState: GetState,
  extraArgument: E,
) => R;

// the type of bindActionCreators doesn't properly account for how middleware
// effects the return value of dispatched actions so we have to enumerate our three
// cases here:
// 1. A thunk action returns its parameterized return value
// 2. An api action returns the return value of ApiDispatch (always Promise<any> at the moment)
// 3. A normal action just returns the action itself
type ActionCreatorReturnType<AC> = AC extends (...args: infer A) => AppThunk<infer R>
  ? (...args: A) => R
  : AC extends (...args: infer A) => ApiAction<infer R>
    ? (...args: A) => Promise<ApiResponseAction<R>>
    : AC extends (...args: infer A) => infer R
      ? (...args: A) => R
      : never;

export type DispatchedActionCreatorsMapObject<A, M extends ActionCreatorsMapObject<A>> = {
  [K in keyof M]: ActionCreatorReturnType<M[K]>;
};

export function patchedBindActionCreators<
  A,
  M extends ActionCreatorsMapObject<A>,
  D extends Dispatch,
>(actionCreators: M, dispatch: D): DispatchedActionCreatorsMapObject<A, M> {
  return bindActionCreators(actionCreators, dispatch) as any; // Basically just re-typing this function with a hard override
}

export interface WipeReduxAction extends AnyAction {
  type: typeof WIPE_REDUX;
}
