import { z } from "zod";

import { request } from "../../api/request/request";
import { ClientType, OldClientType } from "../clients/useClients";
import { createQuery } from "../createQuery";

type PrivateClientRequest = {
  networkEid?: string;
};

const PrivateClientsEndpointResponseSchema = z.object({
  clients: z.array(z.array(z.any())),
  ouis: z.record(z.string().nullable()).optional(),
  fields: z.array(z.string()),
});

type PrivateClientResp = z.infer<typeof PrivateClientsEndpointResponseSchema>;

const buildQueryUrl = ({ networkEid }: PrivateClientRequest) => {
  return `/n/${networkEid}/manage/usage/client_list_json`;
};

const fetchClientListJson = (variables: PrivateClientRequest) => {
  return request(PrivateClientsEndpointResponseSchema, "GET", buildQueryUrl(variables));
};

/**
 * @privateapi Public endpoints should be used whenever possible
 * Query call for the PRIVATE client endpoint - Public endpoint does not provide all Client info.
 *
 * It is recommended you use mapFromPrivateToPublicEndpoint() on the response data to make the transition between
 * private and public endpoints as painless as possible in the future.
 */
export const usePrivateClientListJson = createQuery<PrivateClientRequest, PrivateClientResp>({
  baseQueryKey: buildQueryUrl({ networkEid: "{networkEid}" }),
  queryFn: (variables) => fetchClientListJson(variables),
  requiredVariables: ["networkEid"],
});

export type ClientList = Record<string, ClientType>;

interface PrivateClientsEndpointResponse {
  clients: string[][];
  ouis?: Record<string, string | null>;
  fields: string[];
}

/**
 * Takes the private client api endpoint and transforms it into the shape of the public endpoint.
 *
 * Implemented by Hannah Bulmer.
 */
export const mapFromPrivateToPublicEndpoint = (
  response: PrivateClientsEndpointResponse,
): ClientList => {
  const clients = response.clients.map((c: string[]): OldClientType => {
    const obj = {} as OldClientType;
    response.fields.forEach((key, idx) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      obj[key] = c[idx];
    });
    return obj;
  });
  const clientList = clients.map((c) => ({
    id: c.id,
    mac: c.mac,
    description: c.description,
    ip: c.ip,
    ip6: c.ip6,
    ip6Local: c.ip6_local,
    user: c.user,
    firstSeen: new Date(c.first_seen * 1000), //transform this to a date
    lastSeen: new Date(c.last_seen * 1000),
    manufacturer: c.mac ? response.ouis?.[c.mac?.substring(0, 8)] : null,
    os: c.os,
    deviceTypePrediction: c.device_type_prediction,
    ssidName: c.ssid_name,
    vlan: c.vlan,
    usage: {
      sent: c.sent,
      recv: c.recv,
      total: c.sent + c.recv,
    },
    notes: c.notes,
    groupPolicy8021x: c.group_policy_8021x,
    adaptivePolicyGroup: c.adaptive_policy_group,
    nodeId: c.node_id,
    connectedBy: c.connected_by,
    userSrc: c.user_src,
    capabilities: c.capabilities,
    clientFeatures: c.client_features,
    products: c.products,
    wiredGroupNum: c.wired_group_num,
    wirelessGroupNums: c.wireless_group_nums,
    ssidMasks: c.ssid_masks,
    clientVpnDetails: c.client_vpn_details,
    lldp: c.lldp,
    blockedMessage: c.blocked_message,
    physicalPorts: c.physical_ports,
    portType: c.port_type,
    networkId: c.network_id,
    hashedIp4: c.hashed_ip4,
    onboardingScore: c.onboarding_score,
    performanceScore: c.performance_score,
  }));
  return clientList.reduce((obj, client) => ({ ...obj, [client.id]: client }), {});
};
