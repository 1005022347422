import { memo } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

// NOTE: large text blobs will often result in awkward placement of the helplink button.
// styling and content should be taken into account while making the screen. (Also, as a
// stylistic choice, this should always be used for 1-2 line items rather than paragraphs)

// 3 types of materialicon that are relevant:
// ? : help, ! : error, i : info
type IconTypes = "help" | "info" | "error";

// note: all options for MkiText are allowed, but from a UX perspective it may
// be worth it to limit the options in the future, since a helplink next to a "small"
// label might not make sense for example.
type MkiHelplinkedTextStyles =
  | "title"
  | "heading"
  | "subheading"
  | "error"
  | "default"
  | "activeTab"
  | "inactiveTab"
  | "small"
  | "smallSecondary"
  | "label"
  | "bigNumber"
  | "monospace"
  | "graphLabel";

const DEFAULT_ICON_NAME = "info";

export interface MkiHelplinkedTextProps {
  onPress: () => void;
  icon?: IconTypes;
  textStyle?: MkiHelplinkedTextStyles;
  screenStyles?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  uppercase?: boolean;
  testID?: string;
}

const MkiHelplinkedText: React.FC<MkiHelplinkedTextProps> = (props) => {
  const { icon, onPress, textStyle, screenStyles, children, uppercase, testID } = props;

  // NOTE: in order to maintain optimal UX, it is highly suggested that this component be
  // used on single-line and short text (preferably things like Titles). Use on paragraphs
  // at your own discretion.

  const buttonSize = BUTTON_SIZING.helplink.default;

  const optimalPressSize = buttonSize / 2;
  const hitSlop = {
    top: optimalPressSize,
    bottom: optimalPressSize,
    left: optimalPressSize,
    right: optimalPressSize,
  };

  return (
    <View style={styles.container}>
      <MkiText
        textStyle={textStyle}
        screenStyles={[styles.textContainer, screenStyles]}
        uppercase={uppercase}
        testID={testID}
      >
        {children}
      </MkiText>
      <TouchableOpacity onPress={onPress} hitSlop={hitSlop} style={styles.icon}>
        <MerakiIcon
          name={icon || DEFAULT_ICON_NAME}
          size={buttonSize}
          color={MkiColors.secondaryButton}
        />
      </TouchableOpacity>
    </View>
  );
};

MkiHelplinkedText.defaultProps = {
  icon: DEFAULT_ICON_NAME,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  textContainer: {
    paddingRight: SPACING.small,
  },
  icon: {
    alignSelf: "center",
    height: BUTTON_SIZING.helplink.default,
    width: BUTTON_SIZING.helplink.default,
  },
});

export default memo(MkiHelplinkedText);
