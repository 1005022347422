import {
  CLIENTS_OVERVIEW_FETCH_FAILURE,
  CLIENTS_OVERVIEW_FETCH_REQUEST,
  CLIENTS_OVERVIEW_FETCH_SUCCESS,
} from "@meraki/shared/redux";

import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, timespanState } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function fetchClientsOverview(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const networkId = currentNetworkState(state);
    const timespan = timespanState(state);

    return dispatch({
      [CALL_API]: {
        types: [
          CLIENTS_OVERVIEW_FETCH_REQUEST,
          CLIENTS_OVERVIEW_FETCH_SUCCESS,
          CLIENTS_OVERVIEW_FETCH_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/clients/overview?timespan=${timespan}`,
        config: { method: Method.get },
      },
    });
  };
}
