import { Loader } from "@meraki/magnetic/components";
import { Tree, TreeDatum } from "@meraki/shared/components";
import { useCurrentNetworkId } from "@meraki/shared/redux";

import { ExtendedNode, TopologyMap, useTopologyMap } from "../hooks/useTopologyMap";

export function mapData(topologyMap: TopologyMap, node: ExtendedNode): TreeDatum {
  return {
    name: node.name,
    value: node.device
      ? `model: ${node.device.model}\nserial: ${node.device.serial}\nstatus: ${node.device.status}`
      : undefined,
    children: node.downstream.reduce<TreeDatum[]>((result, childNode) => {
      const childNodeInMap = topologyMap[childNode];
      if (childNodeInMap) {
        result.push(mapData(topologyMap, childNodeInMap));
      }

      return result;
    }, []),
  };
}

export const DeviceTopologyTree = () => {
  const networkId = useCurrentNetworkId();
  const { data: topologyMap = {} } = useTopologyMap({ networkId });

  const rootNode = Object.values(topologyMap).find(({ root }) => root);
  if (!rootNode) {
    return <Loader.Spinner />;
  }

  return <Tree data={mapData(topologyMap, rootNode)} testID="TOPOLOGY_TREE" />;
};
