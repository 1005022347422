import * as WanUsageTrafficSplit from "../handlers/WanUsageTrafficSplit";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useUplinkUsages(
  deviceId: string,
): LiveTool<WanUsageTrafficSplit.UplinkUsages> {
  const msg = useLiveBroker<BaseDeviceSubProps, WanUsageTrafficSplit.Message>({
    type: "WanUsageTrafficSplit",
    deviceId,
  });

  return useCachedFormatter(msg, WanUsageTrafficSplit.formatMsg);
}
