import {
  LT_CYCLE_PORT_FAILURE,
  LT_CYCLE_PORT_REQUEST,
  LT_CYCLE_PORT_SUCCESS,
  LT_FAILURE,
  LT_REQUEST,
  LT_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function restart(serial: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [LT_REQUEST, LT_SUCCESS, LT_FAILURE],
        endpoint: `/api/v1/devices/${serial}/reboot`,
        config: {
          method: Method.post,
        },
      }),
    );
}
/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/blink-device-leds/
 */
export function blinkLED(
  deviceId: string,
  networkEid?: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [LT_REQUEST, LT_SUCCESS, LT_FAILURE],
        endpoint: `${
          networkEid ? `/n/${networkEid}` : ``
        }/manage/nodes/blink_leds_data/${deviceId}`, // TODO JIRA DM-3764
        config: {
          method: Method.post,
          queryParams: { duration: 5 },
        },
      }),
    );
}

export function cycleSwitchPort(
  serial: string,
  ports: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [LT_CYCLE_PORT_REQUEST, LT_CYCLE_PORT_SUCCESS, LT_CYCLE_PORT_FAILURE],
        endpoint: `/api/v1/devices/${serial}/switch/ports/cycle`,
        config: {
          method: Method.post,
          body: JSON.stringify({ ports: [ports] }),
        },
      }),
    );
}
