import { AuthUsers, useAuthUsers } from "@meraki/shared/api";

import { AuthsOnNetworkByEmail } from "~/shared/types/AdminTypes";

export function filterAuthUsersByEmail(data?: AuthUsers) {
  if (!data) {
    return undefined;
  }
  const authUsersByEmail: AuthsOnNetworkByEmail = {};
  data.forEach(
    (user) =>
      (authUsersByEmail[user.email] = { clientVPNEnabled: user.accountType === "Client VPN" }),
  );
  return authUsersByEmail;
}

export const useAuthUsersByEmail = (networkId?: string) => {
  return useAuthUsers(
    { networkId },
    {
      select: (data) => filterAuthUsersByEmail(data),
    },
  );
};
