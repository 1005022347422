import { I18n } from "@meraki/core/i18n";
import { Button, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useAdmins, useAuthUsers, useEnableClientVPN } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";
import z from "zod";

type InviteGuestForm = {
  name: string;
  email: string;
  password: string;
  sendPassword: boolean;
};

export const ClientVPNInviteGuestScreen = () => {
  const organizationId = useCurrentOrganizationId() ?? "";
  const networkId = useCurrentNetworkId();

  const { data: authUsers, refetch } = useAuthUsers({ networkId });
  const { data: admins } = useAdmins({ organizationId });

  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <Button.Nav text={I18n.t("GUEST.CANCEL")} onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const methods = useForm<InviteGuestForm>({
    values: {
      name: "",
      email: "",
      password: "",
      sendPassword: false,
    },
  });

  const enableClientVPNGuest = useEnableClientVPN();

  const save = () => {
    enableClientVPNGuest.mutate(
      {
        email: methods.watch("email").toLowerCase(),
        networkId: networkId,
        name: methods.watch("name"),
        password: methods.watch("password"),
        isAdmin: false,
        emailPasswordToUser: methods.watch("sendPassword"),
      },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          navigation.goBack();
        },
        onSettled: () => {
          refetch();
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding>
      <Form {...methods}>
        <Form.Input
          label={I18n.t("GUEST.NAME")}
          name={"name"}
          testID="INVITE_INPUT_NAME"
          rules={{
            required: I18n.t("CLIENT_VPN.USER.INVITE.NAME_REQUIRED"),
            validate: (name) => {
              return admins?.find((admin) => admin.name === name) ||
                authUsers?.find((guest) => guest.name === name)
                ? I18n.t("NAME_ERROR")
                : undefined;
            },
          }}
        />
        <Form.Input
          label={I18n.t("GUEST.EMAIL")}
          name={"email"}
          testID="INVITE_INPUT_EMAIL"
          rules={{
            required: I18n.t("CLIENT_VPN.USER.INVITE.EMAIL_REQURED"),
            validate: (email) => {
              if (email) {
                if (z.string().email().safeParse(email).success) {
                  if (
                    !!admins?.find((admin) => admin.email === email.toLowerCase()) ||
                    !!authUsers?.find((guest) => guest.email === email.toLowerCase())
                  ) {
                    return I18n.t("GUEST.ERROR.EMAIL");
                  }
                } else {
                  return I18n.t("GUEST.ERROR.INVALID_EMAIL");
                }
              }
              return undefined;
            },
          }}
        />
        <Form.Input
          label={I18n.t("GUEST.PASSWORD")}
          secureTextEntry
          name={"password"}
          rules={{
            required: I18n.t("CLIENT_VPN.USER.INVITE.PASSWORD_REQURED"),
            validate: (password) => {
              return password.length < 8 || password.length > 64
                ? I18n.t("CLIENT_VPN.USER.INVITE.PASSWORD_ERROR")
                : undefined;
            },
          }}
          testID="PASSWORD"
        />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingHorizontal="2xs"
        >
          <Text size="p2">{I18n.t("GUEST.SEND_PASSWORD")}</Text>
          <Form.Toggle name={"sendPassword"} testID="INVITE_GUEST_TOGGLE" />
        </Box>
        <Box paddingVertical="lg">
          <Button
            text={I18n.t("GUEST.INVITE.INVITE")}
            onPress={methods.handleSubmit(save)}
            testID="InviteButton"
          />
        </Box>
      </Form>
    </Screen>
  );
};
