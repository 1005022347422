import { DateFormatOptions, formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Pressable } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";

interface DateTimeProps {
  onConfirm: (_: Date) => void;
  mode: "date" | "time" | "datetime";
  initialDate?: Date;
}

export const DateTimeInput = ({ onConfirm, mode, initialDate }: DateTimeProps) => {
  const [showModal, setShowModal] = useState(false);

  const methods = useForm({
    values: {
      datetime: initialDate ? new Date(initialDate) : new Date(0),
    },
  });

  const datetime = methods.watch("datetime");

  let formatOptions: DateFormatOptions = {
    dateFormat: "date",
    timeFormat: "shortTime",
    timeZone: "UTC",
  };
  let placeholderString = I18n.t("DATE_TIMEPICKER");
  switch (mode) {
    case "time":
      formatOptions = { dateFormat: "hide", timeFormat: "shortTime", timeZone: "UTC" };
      placeholderString = I18n.t("TIME_PLACEHOLDER");
      break;
    case "date":
      formatOptions = { dateFormat: "date", timeZone: "UTC" };
      break;
  }

  return (
    <>
      <Pressable onPress={() => setShowModal(true)}>
        <Box
          padding="sm"
          marginHorizontal="2xs"
          borderRadius="sm"
          borderWidth="medium"
          flexDirection="row"
          backgroundColor="control.bg.weak.base"
          borderColor={showModal ? "control.border.active" : "control.border.base"}
        >
          <Box paddingHorizontal="xs">
            <Text>
              {datetime !== undefined ? formatDate(datetime, formatOptions) : placeholderString}
            </Text>
          </Box>
          <Icon name="Clock" size={18} />
        </Box>
      </Pressable>
      <DateTimePickerModal
        mode={mode}
        date={datetime === undefined ? new Date(0) : datetime}
        timeZoneOffsetInMinutes={0}
        onConfirm={(data: Date) => {
          const formattedDate = Number.isInteger(data) ? new Date(data) : (data as Date);
          onConfirm?.(formattedDate);
          methods.setValue("datetime", formattedDate);
          setShowModal(false);
        }}
        minuteInterval={15}
        isVisible={showModal}
        onHide={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        modalPropsIOS={{ supportedOrientations: ["portrait", "landscape"] }}
      />
    </>
  );
};
