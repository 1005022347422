import { useEffect } from "react";
import { BackHandler } from "react-native";

export const useDisableAndroidBackButton = () => useAndroidBackButtonOverride();

const useAndroidBackButtonOverride = (backAction?: () => void) => {
  const wrappedBackAction = () => {
    if (backAction) {
      backAction();
    }
    return true;
  };
  useEffect(() => {
    const backHandler = BackHandler.addEventListener("hardwareBackPress", wrappedBackAction);
    return () => backHandler.remove();
  });
};

export default useAndroidBackButtonOverride;
