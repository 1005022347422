import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiSpinner from "~/shared/components/MkiSpinner";

type LoadingViewProps = {
  isLoading?: boolean;
  children?: React.ReactElement;
};

export default class LoadingView extends PureComponent<LoadingViewProps> {
  render() {
    const { isLoading = false, children } = this.props;

    return isLoading ? renderLoading() : children;
  }
}
const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

const styles = StyleSheet.create({
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
});
