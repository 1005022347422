import { I18n } from "@meraki/core/i18n";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import Organization from "~/api/models/Organization";
import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { PerfScreenNames } from "~/lib/PerformanceUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { OrgChooseScreen as MagneticOrgChooseScreen } from "~/migrationZone/enterprise/auth/screens/OrgChooseScreen/OrgChooseScreen";
import { currentOrganization, getLoginOrgs } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import OrgList from "~/shared/components/lists/orgs/OrgList";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useAndroidBackButtonOverride from "~/shared/hooks/useAndroidBackButtonOverride";
import { usePerfTrace } from "~/shared/hooks/usePerfTrace";

import { CloseButton } from "../navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "OrgChoose"> & PendingComponent;

function OrgChooseScreen({
  initialLogin,
  handleError,
  setReqPending,
  reqPending,
  allowClose,
}: Props) {
  const { initialOrgChoose, orgChoose, orgOverview, setSSOAccessOrg, setEntitiesAdmin } =
    useActions();

  const organization = useAppSelector(currentOrganization);
  const loginOrgs = useAppSelector(getLoginOrgs);
  const isInitialLogin = isEmpty(organization) || initialLogin;
  const eid = isInitialLogin ? loginOrgs[0]?.eid : organization?.eid;

  const [_, stopPerfTrace] = usePerfTrace(PerfScreenNames.orgChooseScreen);
  const [hasDashAuthCookie, setHasDashAuthCookie] = useState(false);
  const [disableCloseButton, setDisableCloseButton] = useState(false);

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: allowClose
        ? () => <CloseButton onPress={navigation.goBack} disabled={disableCloseButton} />
        : undefined,
      headerBackVisible: isInitialLogin,
    });
  }, [allowClose, disableCloseButton, isInitialLogin, navigation]);

  function androidBackButtonCallback() {
    // block Android hardware back button if initial login
    if (!isInitialLogin) {
      navigation.goBack();
    }
  }
  useAndroidBackButtonOverride(androidBackButtonCallback);

  const preRenderSteps = useCallback(async () => {
    try {
      if (isInitialLogin && eid != null) {
        await initialOrgChoose(eid);
      }

      setHasDashAuthCookie(true);
    } catch (error) {
      console.warn(error);
    }
  }, [initialOrgChoose, isInitialLogin, eid, setHasDashAuthCookie]);

  useEffect(() => {
    if (!hasDashAuthCookie) {
      preRenderSteps();
    }
  }, [hasDashAuthCookie, preRenderSteps]);

  if (!hasDashAuthCookie) {
    return null;
  }

  async function chooseOrg(org: Organization | GoOrgSearchResult) {
    setReqPending(true);
    try {
      if (isInitialLogin) {
        await orgChoose(org.eid);
      } else {
        setDisableCloseButton(true);
        if ("url" in org) {
          await orgOverview(org.eid);
        } else {
          await setSSOAccessOrg(org);
          await setEntitiesAdmin(org.id, org.eid, org.shardId, org.networkId);
        }
        navigation.goBack();
      }
      await stopPerfTrace();
    } catch (error) {
      navigation.popToTop();
      handleError(error || I18n.t("ORGANIZATION.ERROR"));
    }
  }

  return (
    <FullScreenContainerView defaultPadding={isWeb() && initialLogin}>
      {!reqPending && <OrgList onPress={chooseOrg} setReqPending={setReqPending} />}
    </FullScreenContainerView>
  );
}

const ConnectedOrgChooseScreen = withPendingComponent(OrgChooseScreen);

const MagnetizedOrgChooseScreen = withMagneticReplacementAdapter(
  ConnectedOrgChooseScreen,
  MagneticOrgChooseScreen,
);

export default MagnetizedOrgChooseScreen;
