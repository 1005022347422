import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheetMethods,
  Button,
  Loader,
  TabView,
  TabViewMethods,
} from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useDevices, useSwitchPorts } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";

import { AddTagSwitchPortBottomSheet } from "../../components/AddTagSwitchPortBottomSheet";
import { DeleteTagSwitchPortBottomSheet } from "../../components/DeleteTagSwitchPortBottomSheet";
import { EditSwitchPortBottomSheet } from "../../components/EditSwitchPortBottomSheet";
import { SwitchPortDetailsContext } from "./SwitchPortDetailsContext";
import { SwitchPortDetailsTab } from "./tabs/SwitchPortDetailsTab";
import { SwitchPortSettingsTab } from "./tabs/SwitchPortSettingsTab";

const DEVICE_TABS = {
  details: {
    title: I18n.t("TAB_TITLES.DETAILS"),
    view: <SwitchPortDetailsTab />,
    testID: "DETAILS_TABVIEW_TAB",
  },
  settings: {
    title: I18n.t("TAB_TITLES.SETTINGS"),
    view: <SwitchPortSettingsTab />,
    testID: "SETTINGS_TABVIEW_TAB",
  },
};

export const SwitchPortDetailsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "SwitchPortDetails">>();
  const { params: props } = route;
  const { serial, portNumber } = props;

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const tabViewRef = useRef<TabViewMethods>(null);
  const editPortNameBottomSheetRef = useRef<BottomSheetMethods>(null);
  const addTagsBottomSheetRef = useRef<BottomSheetMethods>(null);
  const deleteTagsBottomSheetRef = useRef<BottomSheetMethods>(null);

  const handleOpenAddTagBottomSheetRef = useCallback(() => {
    addTagsBottomSheetRef.current?.present();
  }, []);
  const handleOpenDeleteTagBottomSheetRef = useCallback(() => {
    deleteTagsBottomSheetRef.current?.present();
  }, []);
  const { data: device, isLoading: deviceIsLoading } = useDevices(
    {
      organizationId,
      networkId,
      serial: serial,
    },
    {
      select(data) {
        return data.find((device) => device.serial === serial);
      },
    },
  );

  const { data: switchPort, isLoading: isPortLoading } = useSwitchPorts(
    {
      serial,
    },
    {
      select(data) {
        return data.find((port) => port.portId === portNumber);
      },
    },
  );

  const handleGoToTab = useCallback((key: string) => {
    tabViewRef.current?.goToTab(key);
  }, []);

  useEffect(
    () =>
      navigation.setOptions({
        headerTitle: switchPort?.name ?? I18n.t("PORTS.NUMBER", { port_number: portNumber }),
        headerRight: () => (
          <Button.Icon
            icon="Pencil"
            onPress={() => editPortNameBottomSheetRef.current?.present()}
          />
        ),
      }),
    [navigation, portNumber, serial, switchPort?.name],
  );

  if (!device || deviceIsLoading || !switchPort || isPortLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  return (
    <SwitchPortDetailsContext.Provider
      value={{
        device: device,
        switchPort: switchPort,
        goToTab: handleGoToTab,
        openAddTagBottomSheet: handleOpenAddTagBottomSheetRef,
        openDeletTagBottomSheet: handleOpenDeleteTagBottomSheetRef,
      }}
    >
      <Screen.View>
        <TabView tabs={DEVICE_TABS} ref={tabViewRef} />
      </Screen.View>
      <EditSwitchPortBottomSheet bottomSheetRef={editPortNameBottomSheetRef} />
      <AddTagSwitchPortBottomSheet bottomSheetRef={addTagsBottomSheetRef} />
      <DeleteTagSwitchPortBottomSheet bottomSheetRef={deleteTagsBottomSheetRef} />
    </SwitchPortDetailsContext.Provider>
  );
};
