import { isEmpty, pick, pickBy, some } from "lodash";
import { createSelector } from "reselect";

import { devicesState, getSensors } from "~/selectors/getters";
import { SensorMetrics } from "~/shared/constants/SensorMetrics";
import { RootState } from "~/shared/types/Redux";
import { Sensor, SensorsBySerial } from "~/shared/types/Sensors";

const emptySensorsList: any[] = [];

export const getAlertingSensors = createSelector(getSensors, (sensorsBySerial: SensorsBySerial) => {
  return pickBy(sensorsBySerial, (sensor: any) => !isEmpty(sensor.alertingOn));
});

export const getAlertingSensorsAsList = createSelector(
  getAlertingSensors,
  (alertingSensorsBySerial: SensorsBySerial) => {
    const alertingSensors = Object.values(alertingSensorsBySerial);
    return alertingSensors.length > 0 ? alertingSensors : emptySensorsList;
  },
);

const getAlertingSensorsForMetric = (metric: any) =>
  createSelector(getAlertingSensorsAsList, (alertingSensors: Sensor[]) => {
    const alertingSensorsForMetric = alertingSensors.reduce(
      (memo, sensor) => {
        if (metric in sensor.alertingOn) {
          memo.push(sensor);
        }
        return memo;
      },
      <Sensor[]>[],
    );

    return alertingSensorsForMetric.length > 0 ? alertingSensorsForMetric : emptySensorsList;
  });

export const getAlertingTemperatureSensors = getAlertingSensorsForMetric(SensorMetrics.temperature);

export const getAlertingHumiditySensors = getAlertingSensorsForMetric(SensorMetrics.humidity);

export const getAlertingWaterDetectionSensors = getAlertingSensorsForMetric(
  SensorMetrics.waterDetection,
);

export const getSensorDevices = createSelector(devicesState, getSensors, (devices, sensors) => {
  const sensorSerials = Object.keys(sensors);
  const picked = pick(devices, sensorSerials);
  return picked;
});

export const getSensorDevicesAsList = createSelector(getSensorDevices, (sensors) => {
  return Object.values(sensors);
});

const EMPTY_SENSOR_DEVICE = {};
export const getSensorDeviceForSerial = createSelector(
  getSensorDevices,
  (_: RootState, serial: string) => serial,
  (sensorDevicesBySerial, serial) => {
    return sensorDevicesBySerial[serial] || EMPTY_SENSOR_DEVICE;
  },
);

const hasSensorsForMetric = (metric: any) =>
  createSelector(getSensorDevicesAsList, (sensors) => {
    const attribute = `supports_${metric}`;
    return some(sensors, (sensor: any) => sensor[attribute]);
  });

export const hasTemperatureSensors = hasSensorsForMetric(SensorMetrics.temperature);

export const hasHumiditySensors = hasSensorsForMetric(SensorMetrics.humidity);

export const hasWaterDetectionSensors = hasSensorsForMetric(SensorMetrics.waterDetection);

const emptyAlertingSensorDevicesList: any[] = [];
export const getAlertingSensorDevicesAsList = createSelector(
  getAlertingSensorsAsList,
  getSensorDevices,
  (alertingSensors, sensorDevicesBySerial) => {
    const alertingSensorDevicesList = alertingSensors.reduce((memo: Sensor[], sensor: Sensor) => {
      const sensorDevice = sensorDevicesBySerial[sensor.serial];
      if (sensorDevice) {
        memo.push({ ...sensor, ...sensorDevice });
      }
      return memo;
    }, []);
    return alertingSensorDevicesList.length > 0
      ? alertingSensorDevicesList
      : emptyAlertingSensorDevicesList;
  },
);
