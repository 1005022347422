import { uniqueId } from "lodash";
import { cloneElement, PureComponent } from "react";
import {
  Keyboard,
  KeyboardTypeOptions,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
} from "react-native";

import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { isWeb } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";
import NavigationInputAccessoryView from "~/shared/components/NavigationInputAccessoryView";

export interface MultiPartUnderlinedTextInputProps {
  title?: string;
  footer?: string;
  testID?: string;
}

export interface MultiPartUnderlinedTextInputState {}

abstract class MultiPartUnderlinedTextInput<
  P extends MultiPartUnderlinedTextInputProps,
  S extends MultiPartUnderlinedTextInputState,
> extends PureComponent<P, S> {
  private textInputs: TextInput[] = [];

  abstract numParts: number;

  abstract maxLength: ((index: number) => number) | number;

  abstract keyboardType: KeyboardTypeOptions;

  abstract separator: JSX.Element;

  abstract value(index: number): string;

  abstract placeholder(index: number): string | undefined;

  abstract placeholderTextColor(): string | undefined;

  abstract onChangeText(text: string, index: number): void;

  abstract editable(index: number): boolean;

  abstract styleForPart(index: number): StyleProp<TextStyle>;

  isLastTextInputIndex = (index: number) => index >= this.textInputs.length - 1;

  isFirstEditableTextInputIndex = (index: number) => index <= 0;

  prevFocusAtIndex = (index: number) => {
    if (this.isFirstEditableTextInputIndex(index)) {
      Keyboard.dismiss();
    } else {
      this.textInputs[index - 1].focus();
    }
  };

  endFocusAtIndex = (index: number) => {
    if (this.isLastTextInputIndex(index)) {
      Keyboard.dismiss();
    } else {
      this.textInputs[index + 1].focus();
    }
  };

  focusOnIndex = (index: number) => {
    this.textInputs[index].focus();
  };

  addRef = (ref: any) => {
    this.textInputs.push(ref);
  };

  renderSecondaryText = (text?: string) =>
    text ? (
      <MkiText textStyle="smallSecondary" screenStyles={styles.secondaryText}>
        {text}
      </MkiText>
    ) : null;

  renderTitle = () => {
    const { title } = this.props;
    return this.renderSecondaryText(title);
  };

  renderFooter = () => {
    const { footer } = this.props;
    return this.renderSecondaryText(footer);
  };

  underlinedTextInput = (index: number) => {
    const inputAccessoryViewID = uniqueId("inputAccessoryView-");
    const { testID } = this.props;
    const style = isWeb() ? { style: styles.webInputContainer } : {};

    return (
      <View key={`underlinedTextInput-${index}`} {...style}>
        <UnderlinedTextInput
          key={index}
          ref={this.addRef}
          value={this.value(index)}
          style={this.styleForPart(index)}
          maxLength={typeof this.maxLength === "function" ? this.maxLength(index) : this.maxLength}
          editable={this.editable(index)}
          placeholder={this.placeholder(index)}
          placeholderTextColor={this.placeholderTextColor()}
          returnKeyType={RETURN_KEY.next}
          keyboardType={this.keyboardType}
          inputAccessoryViewID={inputAccessoryViewID}
          onChangeText={(text: string) => this.onChangeText(text, index)}
          onSubmitEditing={() => this.endFocusAtIndex(index)}
          testID={
            testID ? `${testID}.UNDERLINE_TEXT_INPUT - ${index}` : `UNDERLINE_TEXT_INPUT - ${index}`
          }
        />
        {!isWeb() && (
          <NavigationInputAccessoryView
            nativeID={inputAccessoryViewID}
            onPrevPress={() => this.prevFocusAtIndex(index)}
            onNextPress={() => this.endFocusAtIndex(index)}
            onExitPress={Keyboard.dismiss}
          />
        )}
      </View>
    );
  };

  separatorForIndex = (index: number) =>
    index !== 0 ? cloneElement(this.separator, { key: `separator-${index}` }) : null;

  // combining underlined text inputs with a separator as a delimiter
  joinedTextInputsWithSeparators = () =>
    Array.from(Array(this.numParts)).reduce(
      (accumlator: JSX.Element[], _obj: undefined, index: number) => [
        ...accumlator,
        this.separatorForIndex(index),
        this.underlinedTextInput(index),
      ],
      [],
    );

  render() {
    const { testID } = this.props;
    const containerStyle = isWeb() ? [styles.webContainer, styles.container] : styles.container;
    return (
      <View testID={testID}>
        {this.renderTitle()}
        <View style={containerStyle}>{this.joinedTextInputsWithSeparators()}</View>
        {this.renderFooter()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  webContainer: {
    flex: 1,
    maxWidth: "50%",
  },
  webInputContainer: {
    flex: 1,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  secondaryText: {
    marginTop: SPACING.small,
  },
});

export default MultiPartUnderlinedTextInput;
