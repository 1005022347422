import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { MAX_SSIDS, SPACING } from "~/constants/MkiConstants";
import NextStepsMessage from "~/go/components/NextStepsMessage";
import GoColors from "~/go/constants/GoColors";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import { getSSIDtoAdd, isUnconfiguredSSID } from "~/lib/SSIDUtils";
import {
  clientCountsBySSIDNumber,
  clientsSelector,
  currentNetworkState,
  hasGRDevices,
  networkTypesSelector,
  slimSsidsSelector,
} from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiTable from "~/shared/components/MkiTable";
import StatusIcon from "~/shared/components/StatusIcon";
import { AddButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import { NetworkClientOrSMDevice } from "~/shared/types/Client";
import { SSID } from "~/shared/types/Models";
import { NetworkTypesWithId } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  clients: NetworkClientOrSMDevice[];
  clientCountsBySSIDNumber: {
    [key: number]: number;
  };
  ssids: SSID[];
  networkId: string;
  networkTypes: NetworkTypesWithId;
  hasGRDevices: boolean;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDsList"> &
  BasicActions &
  ReduxProps;

type SSIDsListState = {
  reqPending: boolean;
};

type SSIDRow = SSID & { onPress?: () => void; clientCount: number };

export class SSIDsList extends PureComponent<Props, SSIDsListState> {
  static defaultProps = {
    detailsScreenOverride: null,
  };

  static MAX_SSIDS_ERROR = [I18n.t("MAX_SSIDS_ERROR.TITLE"), I18n.t("MAX_SSIDS_ERROR.MESSAGE")];

  static renderRow(
    { enabled, name, onPress, schedule, clientCount }: SSIDRow,
    detailsScreenOverride: Props["detailsScreenOverride"],
    idx: number,
  ) {
    const iconColor = enabled ? GoColors.purple : GoColors.navyLighter;
    const hasSchedule = schedule?.enabled ?? false;

    let clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.zero");
    if (clientCount === 1) {
      clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.one");
    } else if (clientCount > 1) {
      clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.other", {
        formatted_number: clientCount,
      });
    }

    const standardSubtitle = enabled ? clientCountText : I18n.t("DISABLED");
    const subtitle = detailsScreenOverride ? "" : standardSubtitle;

    const scheduleIcon = hasSchedule ? (
      <StatusIcon
        screenStyles={styles.scheduleIcon}
        customIcon={{
          icon: MerakiIcon,
          name: "calendar-clock",
          // @ts-expect-error TS(2322) FIXME: Type '16' is not assignable to type '"m" | "s" | "... Remove this comment to see the full error message
          size: SPACING.default,
          color: iconColor,
        }}
      />
    ) : null;
    return (
      <ListRow
        testID={`SSIDsListRow - ${idx}`}
        icon={
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          <View stlye={styles.iconView}>
            <StatusIcon
              // @ts-expect-error TS(2741) FIXME: Property 'hasGradient' is missing in type '{ icon:... Remove this comment to see the full error message
              customIcon={{
                icon: MerakiIcon,
                name: "wifi",
                size: "m",
                color: iconColor,
              }}
              screenStyles={styles.icon}
            />
            {scheduleIcon}
          </View>
        }
        subtitle1={subtitle}
        rightStyle={styles.rightAccessory}
        accessory={<MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />}
        onPress={onPress}
      >
        {name}
      </ListRow>
    );
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      reqPending: false,
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, hasGRDevices } = this.props;

    if (hasGRDevices) {
      navigation.setOptions({
        headerRight: () => <AddButton onPress={this.showCreateSSID} />,
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  showCreateSSID = () => {
    const { ssids } = this.props;
    const ssidToAdd = getSSIDtoAdd(ssids);
    if (ssidToAdd < 0) {
      showAlert(...SSIDsList.MAX_SSIDS_ERROR);
      return;
    }
    const { navigation } = this.props;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    navigation.navigate("CreateSSID");
  };

  onRefresh = () => this.getData();

  getData() {
    const { actions, networkId } = this.props;

    this.setState({ reqPending: true });
    const reqs = [actions.getSsids(networkId), actions.getSSIDSchedulesForCurrentNetwork()];

    const onComplete = () => this.setState({ reqPending: false });
    return Promise.all(reqs).finally(onComplete);
  }

  navigate({ name, number }: { name: string; number: number }) {
    const { navigation, detailsScreenOverride } = this.props;

    if (detailsScreenOverride) {
      if (detailsScreenOverride.presentedModally) {
        // @ts-expect-error TS(2345) FIXME: Argument of type '[string, { ssidNumber: any; ssid... Remove this comment to see the full error message
        navigation.navigate(detailsScreenOverride.name, {
          ssidNumber: number,
          ssidName: name,
        });
      } else {
        // @ts-expect-error TS(2345) FIXME: Argument of type '[string, { ssidNumber: any; ssid... Remove this comment to see the full error message
        navigation.navigate(detailsScreenOverride.name, {
          ssidNumber: number,
          ssidName: name,
        });
      }
    } else {
      navigation.navigate("SSIDDetails", {
        ssidNumber: number,
      });
    }
  }

  renderTableRow = (rowData: SSIDRow, idx: number) => {
    const { detailsScreenOverride } = this.props;
    return SSIDsList.renderRow(rowData, detailsScreenOverride, idx);
  };

  onPress = (rowData: SSIDRow) => this.navigate(rowData);

  render() {
    const { ssids, hasGRDevices, clientCountsBySSIDNumber } = this.props;
    const { reqPending } = this.state;
    const wirelessNetworks = ssids
      .slice(0, MAX_SSIDS)
      .map((ssid) => ({
        ...ssid,
        clientCount: clientCountsBySSIDNumber[ssid.number],
      }))
      .filter((ssid) => !isUnconfiguredSSID(ssid))
      .sort((ssidA, ssidB) => {
        if (ssidA.enabled && !ssidB.enabled) {
          return -1;
        }
        if (!ssidA.enabled && ssidB.enabled) {
          return 1;
        }
        return 0;
      });

    if (wirelessNetworks.length === 0 && !reqPending) {
      if (hasGRDevices) {
        return (
          <NextStepsMessage
            message={I18n.t("SSID_EMPTY_STATE.MESSAGE")}
            onPress={this.showCreateSSID}
            buttonText={I18n.t("SSID_EMPTY_STATE.BUTTON")}
          />
        );
      } else {
        return <NextStepsMessage message={I18n.t("SSID_EMPTY_STATE_NO_GR.MESSAGE")} />;
      }
    }

    return (
      <MkiTable<SSIDRow>
        data={wirelessNetworks}
        keyExtractor={(item) => `wireless${item.number}`}
        renderRow={this.renderTableRow}
        onPress={this.onPress}
        testID="NETWORK.SSIDS_LIST.TABLE"
        onRefresh={this.onRefresh}
        refreshing={reqPending}
      />
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  iconView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column-reverse",
  },
  scheduleIcon: {
    alignSelf: "flex-end",
    paddingRight: SPACING.meager,
    bottom: SPACING.small,
  },
  rightAccessory: {
    paddingRight: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    clients: clientsSelector(state),
    ssids: slimSsidsSelector(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for DeviceDetailsScreen",
      currentNetworkState(state),
    ),
    networkTypes: networkTypesSelector(state),
    hasGRDevices: hasGRDevices(state),
    clientCountsBySSIDNumber: clientCountsBySSIDNumber(state),
  };
}

export default compose<any>(connect(mapStateToProps, basicMapDispatchToProps))(SSIDsList);
