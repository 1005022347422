import { TRAFFIC_SHAPING_MEBIBITS_INTEGERED } from "@meraki/go/traffic-shaping";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { GLOBAL_BANDWIDTH, PER_SSID_BANDWIDTH } from "~/constants/Layer7";
import { SSIDS_SEARCH_FIELDS, SSIDS_SEARCH_KEY } from "~/constants/SearchKeys";
import { BandwidthRule, BandwidthRuleByNumber } from "~/go/types/NetworksTypes";
import { filterData } from "~/lib/SearchUtils";
import { convertSSIDSchedule, IP_ASSIGNMENT, isUnconfiguredSSID } from "~/lib/SSIDUtils";
import { verifyIsSMDevice } from "~/lib/TypeHelper";
import { SplashSettingsOnState } from "~/reducers/ssids";
import { clientsSelector } from "~/selectors/clients";
import { onlineGRsWithPublicIPSelector } from "~/selectors/devices";
import { currentNetworkState, getClientPolicies, ssidsState } from "~/selectors/getters";
import { ssidsForCurrentNetwork } from "~/selectors/mkiconf";
import { searchTextState } from "~/selectors/search";
import { getDisplayableFailedConnections } from "~/selectors/wirelessHealth";
import { ClientPolicy, DevicePolicies, PolicyType } from "~/shared/types/ClientPolicy";
import { SSID, SSIDsByNumber } from "~/shared/types/Models";
import { MkiSelector, RootState } from "~/shared/types/Redux";

export const getBlockedClientsBySSIDs = createSelector(
  getClientPolicies,
  (clientPolicies: ClientPolicy[]) => {
    const blockedClientsBySSIDs: any = {};

    if (isEmpty(clientPolicies)) {
      return blockedClientsBySSIDs;
    }

    for (const clientPolicy of clientPolicies) {
      const policies = clientPolicy?.assigned;
      if (policies == null || policies.length === 0) {
        continue;
      }

      for (const policy of policies) {
        if (policy?.type === PolicyType.ssid && policy?.ssid?.number != null) {
          const ssidNumber = policy.ssid.number;
          if (blockedClientsBySSIDs[ssidNumber] == null) {
            blockedClientsBySSIDs[ssidNumber] = [];
          }

          if (policy.name === DevicePolicies.blocked) {
            blockedClientsBySSIDs[ssidNumber].push(clientPolicy.mac);
          }
        }
      }
    }

    return blockedClientsBySSIDs;
  },
);

const emptySSIDs: any = [];
const emptyBlockedMacs: any = [];
export const slimSsidsSelector = createSelector(
  ssidsForCurrentNetwork,
  getBlockedClientsBySSIDs,
  (ssids, blockedClientsBySSIDs) => {
    const mappedSSIDs = ssids.map((ssid: any) => ({
      configured: !isUnconfiguredSSID(ssid),
      ...ssid,
      blockedMacs: blockedClientsBySSIDs[ssid.number] || emptyBlockedMacs,
    }));

    if (mappedSSIDs.length === 0) {
      return emptySSIDs;
    }
    return mappedSSIDs;
  },
);

export const slimSsidsByIdSelector = createSelector(slimSsidsSelector, (ssids): SSIDsByNumber => {
  const ssidsById: any = {};
  for (const ssid of ssids) {
    if (ssid.configured) {
      ssidsById[ssid.number] = ssid;
    }
  }
  return ssidsById;
});

export const firewallLayerRulesOnSSIDsSelector = createSelector(
  ssidsState,
  currentNetworkState,
  (ssids, networkId) => {
    return networkId ? ssids?.[networkId]?.firewallLayerRules || {} : undefined;
  },
);

export const hasInvalidBridgeSSIDSelector = createSelector(
  ssidsForCurrentNetwork,
  onlineGRsWithPublicIPSelector,
  (ssids, onlineGRsWithPublicIP) =>
    !isEmpty(onlineGRsWithPublicIP) &&
    !!ssids?.some((ssid: any) => ssid.ipAssignmentMode === IP_ASSIGNMENT.BRIDGE),
);

export const trafficShapingRulesOnSSIDsSelector = createSelector(
  ssidsState,
  currentNetworkState,
  (ssids, networkId) => {
    return (networkId ? ssids?.[networkId]?.trafficShapingRules : undefined) || {};
  },
);

export const splashSettingsOnSSIDsSelector: MkiSelector<SplashSettingsOnState> = createSelector(
  ssidsState,
  currentNetworkState,
  (ssids, networkId) => {
    return (networkId ? ssids?.[networkId]?.splashSettings : undefined) || {};
  },
);

export const perClientBandwidthLimitsOnSSIDsSelector = createSelector(
  slimSsidsSelector,
  (ssids): BandwidthRuleByNumber => {
    const bandwidthLimitsById: BandwidthRuleByNumber = {};
    for (const ssid of ssids) {
      if (ssid.configured && ssid.perClientBandwidthLimitUp > 0) {
        bandwidthLimitsById[ssid.number] = {
          identifier: GLOBAL_BANDWIDTH,
          limit: TRAFFIC_SHAPING_MEBIBITS_INTEGERED[ssid.perClientBandwidthLimitUp],
        } as BandwidthRule;
      }
    }
    return bandwidthLimitsById;
  },
);

export const perSSIDBandwidthLimitsSelector = createSelector(
  slimSsidsSelector,
  (ssids): BandwidthRuleByNumber => {
    const bandwidthLimitsById: BandwidthRuleByNumber = {};
    for (const ssid of ssids) {
      if (ssid.configured && ssid.perSsidBandwidthLimitUp > 0) {
        bandwidthLimitsById[ssid.number] = {
          identifier: PER_SSID_BANDWIDTH,
          limit: TRAFFIC_SHAPING_MEBIBITS_INTEGERED[ssid.perSsidBandwidthLimitUp],
        } as BandwidthRule;
      }
    }
    return bandwidthLimitsById;
  },
);

// ssids pt.2 (the selector needs ssids state, clients, and settings hash)
export const ssidsSelector = createSelector(
  ssidsForCurrentNetwork,
  clientsSelector,
  (ssids, clients) =>
    ssids.map((ssid: any) => ({
      clients: clients.filter((c) => !verifyIsSMDevice(c) && c.connectedBy === ssid.number),
      configured: !isUnconfiguredSSID(ssid),
      ...ssid,
    })),
);

export const configuredSSIDsSelector = createSelector(ssidsSelector, (ssids) =>
  ssids.filter((s: any) => s.configured),
);

export const hasConfiguredWiFiSelector = createSelector(
  configuredSSIDsSelector,
  (ssids) => ssids.length > 0,
);

export const ssidsByIdSelector = createSelector(configuredSSIDsSelector, (ssids) => {
  const ssidsById: any = {};
  if (!ssids) {
    return ssidsById;
  }
  for (const ssid of ssids) {
    ssidsById[ssid.number] = ssid;
  }
  return ssidsById;
});

export const ssidsOnAccessPoint = createSelector(
  slimSsidsSelector,
  (_: RootState, device: any) => device,
  (ssids, device): SSID[] =>
    ssids.filter(
      (ssid: any) =>
        !isUnconfiguredSSID(ssid) &&
        (ssid.availableOnAllAps || device.tags.includes(...ssid.availabilityTags)),
    ),
);

export const ssidSchedulesSelector = createSelector(ssidsForCurrentNetwork, (ssids) => {
  const schedules: any = {};
  for (const ssid of ssids) {
    schedules[ssid.number] = convertSSIDSchedule(ssid.schedule);
  }
  return schedules;
});

export const ssidSchedulesForSsidSelector = createSelector(
  ssidSchedulesSelector,
  (_: RootState, ssidNumber: any) => ssidNumber,
  (ssidSchedules, ssidNumber) => {
    return ssidSchedules[ssidNumber] || {};
  },
);

export const filteredSsidsSelector = createSelector(
  ssidsSelector,
  searchTextState,
  (ssids, searchText) => filterData(ssids, SSIDS_SEARCH_FIELDS, searchText(SSIDS_SEARCH_KEY)),
);

export const sortedSsidsSelector = createSelector(
  ssidsSelector,
  (ssids) => ssids?.slice().sort((a: any, b: any) => b.clients?.length - a.clients?.length),
);

const emptyUnfliteredSsidsById = {};
export const unfilteredSsidsByIdSelector = createSelector(ssidsSelector, (ssids) => {
  if (isEmpty(ssids)) {
    return emptyUnfliteredSsidsById;
  }
  const ssidsById: any = {};
  for (const ssid of ssids) {
    ssidsById[ssid.number] = ssid;
  }
  return ssidsById;
});

export const ssidsEnabledSelector = createSelector(
  ssidsSelector,
  (ssids) => ssids?.filter((s: any) => s?.enabled),
);

export const canWarnInvalidBridegeModeOnSSID = createSelector(
  ssidsState,
  currentNetworkState,
  (ssids, networkId) => (networkId ? ssids?.[networkId]?.warnInvalidBridgeMode : undefined) ?? true,
);

export const failedConnectionsForSSIDs = createSelector(
  configuredSSIDsSelector,
  getDisplayableFailedConnections,
  (ssids, failedConnections) => {
    const failedConnectionsBySSID: any = {};
    for (const ssid of ssids) {
      const { number, name } = ssid;
      failedConnectionsBySSID[name] = failedConnections?.filter(
        (connection) => connection.ssidNumber === number,
      );
    }
    return failedConnectionsBySSID;
  },
);
