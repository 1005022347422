import {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  ReactElement,
  Ref,
  RefAttributes,
  useCallback,
  useState,
} from "react";
import { NativeSyntheticEvent, TextInputFocusEventData } from "react-native";

import { useSafeBottomSheetInternal } from "../util/useSafeBottomSheetInternal";
import { InputInternal, InputInternalProps, InputRefFunctions, ValueType } from "./InputInternal";

export type InputProps<T extends ValueType> = Omit<InputInternalProps<T>, "focused">;
export type { ValueType };

function forwardRefFC<T, P = Record<string, never>>(
  render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: PropsWithoutRef<P> & RefAttributes<T>) => ReactElement | null {
  return forwardRef(render);
}

function InputForward<T extends ValueType>(
  { onFocus, onBlur, ...rest }: InputProps<T>,
  ref: Ref<InputRefFunctions>,
) {
  const [focused, setFocused] = useState(false);
  const bottomSheet = useSafeBottomSheetInternal(true);

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(true);
      if (bottomSheet) {
        bottomSheet.shouldHandleKeyboardEvents.value = true;
      }
      onFocus && onFocus(e);
    },
    [onFocus, setFocused, bottomSheet],
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(false);
      if (bottomSheet) {
        bottomSheet.shouldHandleKeyboardEvents.value = false;
      }

      onBlur && onBlur(e);
    },
    [onBlur, setFocused, bottomSheet],
  );

  return (
    <InputInternal
      {...rest}
      focused={focused}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
    />
  );
}

export const Input = forwardRefFC(InputForward);
