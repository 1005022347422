import { I18n } from "@meraki/core/i18n";
import { lowerCase, startCase } from "lodash";

import { showAlert } from "~/lib/AlertUtils";
import { NetworkAccess, NetworkPrivilege, OrgAccess } from "~/shared/types/AdminTypes";

export const onFailInvite = (errorMsg: { error: unknown; title?: string }) => {
  const { error, title } = errorMsg;
  if (
    error !== null &&
    typeof error === "object" &&
    (error.hasOwnProperty("error") || error.hasOwnProperty("errors"))
  ) {
    if (error.hasOwnProperty("errors")) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const errorMessage = error["errors"];
      title ? showAlert(title, errorMessage[0]) : showAlert(errorMessage[0], errorMessage[1]);
    } else {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      showAlert(I18n.t("ERROR"), error["error"]);
    }
  }
};

export const normalizeToDisplayAccess = (access: string) => {
  return startCase(access.split("-").join(" "));
};

export const normalizeToRequestAccess = (access: string) => {
  return lowerCase(access).split(" ").join("-");
};

export const filterNetworks = (networks: NetworkPrivilege[], removed: NetworkPrivilege[]) => {
  return networks.filter(
    (network) =>
      !removed.some((remove) => remove.id === network.id && remove.access === network.access),
  );
};

export const filterNetworksPerAccess = (
  networks: NetworkPrivilege[],
  id: string,
  access: NetworkAccess,
) => {
  return networks.filter((n) => !(n.id === id && n.access === access));
};

export const getOrgAccess = (privilege: string) => {
  switch (privilege) {
    case I18n.t("ADMINISTRATOR_SCREEN.ADD.NONE"):
      return OrgAccess.none;
    case I18n.t("ADMINISTRATOR_SCREEN.ADD.FULL"):
      return OrgAccess.full;
    case I18n.t("ADMINISTRATOR_SCREEN.ADD.READ_ONLY"):
    default:
      return OrgAccess.read;
  }
};

export const getDisplayOrgAccess = (orgAccess: string, extended?: boolean) => {
  switch (orgAccess) {
    case OrgAccess.full:
      return I18n.t("ADMINISTRATOR_SCREEN.ORG_ACCESS.ORGANIZATION");
    case OrgAccess.read:
      return I18n.t("ADMINISTRATOR_SCREEN.ORG_ACCESS.READ_ONLY");
    case OrgAccess.none:
    default:
      return extended
        ? I18n.t("ADMINISTRATOR_SCREEN.ADD.NONE")
        : I18n.t("ADMINISTRATOR_SCREEN.ORG_ACCESS.NETWORK_ONLY");
  }
};
