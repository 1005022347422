import { I18n } from "@meraki/core/i18n";
import { Card, List, Loader, RefreshControl, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useDevices, useDHCPSubnets } from "@meraki/shared/api";
import { getTotalReservedIps } from "@meraki/shared/ip-address";
import { useCurrentOrganizationId } from "@meraki/shared/redux";

import { useVlanDetails } from "../VlanDetailsContext";

export const DetailsTab = () => {
  const { vlan } = useVlanDetails();
  const organizationId = useCurrentOrganizationId();
  const {
    data: appliance,
    refetch: refetchAppliance,
    isRefetching: applianceIsRefetching,
  } = useDevices(
    { organizationId },
    {
      select(data) {
        return data.find((device) => device.productType === "appliance");
      },
    },
  );

  const {
    data: dhcpSubnet,
    refetch: refetchSubnet,
    isRefetching: subnetIsRefetching,
  } = useDHCPSubnets(
    {
      serial: appliance?.serial,
    },
    {
      select(data) {
        return data.find((dhcpSubnet) => dhcpSubnet.subnet === vlan?.subnet);
      },
    },
  );

  if (!vlan || !dhcpSubnet) {
    return (
      <Screen testID="DETAILS_TAB">
        <Loader.Spinner testID="DETAILS_LOADING_SPINNER" />
      </Screen>
    );
  }

  let totalIps = 0;
  if (dhcpSubnet.usedCount != null && dhcpSubnet.freeCount != null) {
    totalIps = dhcpSubnet.usedCount + dhcpSubnet.freeCount;
  }
  const totalReservedIps = getTotalReservedIps(vlan);

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={subnetIsRefetching || applianceIsRefetching}
          onRefresh={() => {
            refetchSubnet();
            refetchAppliance();
          }}
        />
      }
      addDefaultPadding
      testID="DETAILS_TAB"
    >
      <List testID="ID_CARD">
        <List.Item title={I18n.t("VLAN.ID")} value={vlan.id} />
      </List>
      <Card testID="IP_CARD">
        <Card.Header
          title={I18n.t("VLAN.IP_CARD.TITLE")}
          description={I18n.t("VLAN.IP_CARD.DESCRIPTION", { totalIps })}
          rightAccessory={<Text size="p2">{vlan.subnet}</Text>}
        />

        <List>
          <List.Item title={I18n.t("VLAN.IP_CARD.AVAILABLE")} value={dhcpSubnet.freeCount} />
          <List.Item title={I18n.t("VLAN.IP_CARD.RESERVED")} value={totalReservedIps.toString()} />
          <List.Item
            title={I18n.t("VLAN.IP_CARD.USED")}
            value={(dhcpSubnet.usedCount - totalReservedIps).toString()}
          />
        </List>
      </Card>
    </Screen>
  );
};
