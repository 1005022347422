import { I18n } from "@meraki/core/i18n";
import { SettingsGroupProps, StackType } from "@meraki/shared/navigation-type";
import { ThemeSelectScreen } from "@meraki/shared/theme-select";

export function SettingsGroup<T extends SettingsGroupProps>(Stack: StackType<T>) {
  return (
    <Stack.Screen
      name="ThemeSelect"
      component={ThemeSelectScreen}
      options={{
        headerTitle: I18n.t("SETTINGS.GENERAL.THEME_SELECT.TITLE"),
      }}
    />
  );
}
