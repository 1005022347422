// file for temp moving of index fns, so we can import and avoid circ deps

import { useBioauthEnabled } from "@meraki/core/bioauth";
import * as errorMonitor from "@meraki/core/errors";
import { LiveBroker } from "@meraki/react-live-broker";
import {
  ClientList,
  LoginDetailsResponseType,
  LoginResponseType,
  mapFromPrivateToPublicEndpoint,
  queryClient,
  standardizeLoginResponse,
  useCheckIfEmailVerified,
  verifyIsLoginResponse,
} from "@meraki/shared/api";
import { setShardFromPath } from "@meraki/shared/auth";
import { featureFlagClient } from "@meraki/shared/feature-flags";
import {
  ADD_NODES_FAILURE,
  ADD_NODES_REQUEST,
  ADD_NODES_SUCCESS,
  CLEAR_NESTED_MODAL_DATA,
  CLEAR_ONBOARDING_DEVICE_EDITS,
  CLEAR_SEARCH_TEXT,
  CLIENT_LIST_JSON_FAILURE,
  CLIENT_LIST_JSON_REQUEST,
  CLIENT_LIST_JSON_SUCCESS,
  CLIENTS_AND_USEBLOCKS_FINISH,
  CLIENTS_AND_USEBLOCKS_START,
  COUNTER_SETS_FAILURE,
  COUNTER_SETS_REQUEST,
  COUNTER_SETS_SUCCESS,
  CREATE_NETWORK_FAILURE,
  CREATE_NETWORK_REQUEST,
  CREATE_NETWORK_SUCCESS,
  defaultTimespans,
  DELETE_SSID_FAILURE,
  DELETE_SSID_REQUEST,
  DELETE_SSID_SUCCESS,
  DEVICE_USAGE_FAILURE,
  DEVICE_USAGE_REQUEST,
  DEVICE_USAGE_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FINISHED_ADDITIONAL_LOGIN_REQUESTS,
  GET_MSP_ORGANIZATION_DATA_FAILURE,
  GET_MSP_ORGANIZATION_DATA_REQUEST,
  GET_MSP_ORGANIZATION_DATA_SUCCESS,
  GET_NETWORK_ACCESS_ROLES_REQUEST,
  GET_NETWORK_ACCESS_ROLES_SUCCESS,
  GET_ORG_SP_IDP_FAILURE,
  GET_ORG_SP_IDP_REQUEST,
  GET_ORG_SP_IDP_SUCCESS,
  INITIAL_ORG_CHOOSE_FAILURE,
  INITIAL_ORG_CHOOSE_REQUEST,
  INITIAL_ORG_CHOOSE_SUCCESS,
  INVENTORY_REMOVE_FAILURE,
  INVENTORY_REMOVE_REQUEST,
  INVENTORY_REMOVE_SUCCESS,
  LB_ADD_PUBLISH_DATA,
  LB_CONNECTED,
  LB_INIT,
  LB_REMOVE_PUBLISH_DATA,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  NETWORK_HEALTH_FAILURE,
  NETWORK_HEALTH_REQUEST,
  NETWORK_HEALTH_SUCCESS,
  NETWORK_USEBLOCKS_FAILURE,
  NETWORK_USEBLOCKS_REQUEST,
  NETWORK_USEBLOCKS_SUCCESS,
  NODES_FAILURE,
  NODES_REQUEST,
  NODES_SUCCESS,
  ONBOARDING_TIMING_START,
  ORG_CHOOSE_FAILURE,
  ORG_CHOOSE_REQUEST,
  ORG_CHOOSE_SUCCESS,
  ORG_CHOSEN,
  ORG_OVERVIEW_FAILURE,
  ORG_OVERVIEW_REQUEST,
  ORG_OVERVIEW_SUCCESS,
  OTP_AUTH_FAILURE,
  OTP_AUTH_REQUEST,
  OTP_AUTH_RETRY_FAILURE,
  OTP_AUTH_RETRY_REQUEST,
  OTP_AUTH_RETRY_SUCCESS,
  OTP_AUTH_SUCCESS,
  PRESENCE_DATA_FAILURE,
  PRESENCE_DATA_REQUEST,
  PRESENCE_DATA_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
  REQUEST_PASSWORD_RESET_REQUEST,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_IDLE_TIMEOUT_SESSION_EXPIRED,
  RESET_LOGIN_STATE,
  RULE_USEBLOCKS_FAILURE,
  RULE_USEBLOCKS_REQUEST,
  RULE_USEBLOCKS_SUCCESS,
  SEND_LAST_LOGIN_TO_MARKETO_FAILURE,
  SEND_LAST_LOGIN_TO_MARKETO_REQUEST,
  SEND_LAST_LOGIN_TO_MARKETO_SUCCESS,
  SET_BIOMETRIC_AUTH,
  SET_CLIENT_INFO,
  SET_CURRENT_USER,
  SET_DEFAULT_NETWORK,
  SET_DEVICE,
  SET_DEVICE_ONBOARDING_COMPLETE,
  SET_DEVICE_ONBOARDING_CONTINUED,
  SET_HOME_SSID,
  SET_MANY_POLICIES_FAILURE,
  SET_MANY_POLICIES_REQUEST,
  SET_MANY_POLICIES_SUCCESS,
  SET_NESTED_MODAL_DATA,
  SET_ONBOARDING_DEVICE_EDITS,
  SET_REDUX_SSIDS,
  SET_SEARCH_TEXT,
  SET_SSID_FAILURE,
  SET_SSID_REQUEST,
  SET_SSID_SUCCESS,
  SET_TIMESPAN,
  SET_USER_DATA,
  setCurrentNetwork as libSetCurrentNetwork,
  setCurrentOrganization,
  setCurrentShardId,
  SMS_AUTH_RETRY_FAILURE,
  SMS_AUTH_RETRY_REQUEST,
  SMS_AUTH_RETRY_SUCCESS,
  SSID_GROUP_POLICY_FAILURE,
  SSID_GROUP_POLICY_REQUEST,
  SSID_GROUP_POLICY_SUCCESS,
  SSID_USEBLOCKS_FAILURE,
  SSID_USEBLOCKS_REQUEST,
  SSID_USEBLOCKS_SUCCESS,
  SSIDS_FAILURE,
  SSIDS_REQUEST,
  SSIDS_SUCCESS,
  STORE_TFA_REMEMBER_ME,
  SWITCH_PORTS_JSON_FAILURE,
  SWITCH_PORTS_JSON_REQUEST,
  SWITCH_PORTS_JSON_SUCCESS,
  TIMEZONE_GET_FAILURE,
  TIMEZONE_GET_REQUEST,
  TIMEZONE_GET_SUCCESS,
  TWO_FACTOR_FAILURE,
  TWO_FACTOR_REQUEST,
  TWO_FACTOR_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_SPLASH_LOGO_FAILURE,
  UPDATE_SPLASH_LOGO_REQUEST,
  UPDATE_SPLASH_LOGO_SUCCESS,
  USER_DATA_PROFILE_SETTINGS_GET_FAILURE,
  USER_DATA_PROFILE_SETTINGS_GET_REQUEST,
  USER_DATA_PROFILE_SETTINGS_GET_SUCCESS,
  VLAN_UPDATE_FAILURE,
  VLAN_UPDATE_REQUEST,
  VLAN_UPDATE_SUCCESS,
  VLANS_CREATE_FAILURE,
  VLANS_CREATE_REQUEST,
  VLANS_CREATE_SUCCESS,
  VLANS_DELETE_FAILURE,
  VLANS_DELETE_REQUEST,
  VLANS_DELETE_SUCCESS,
  VLANS_GET_FAIL,
  VLANS_GET_REQUEST,
  VLANS_GET_SUCCESS,
  WIPE_REDUX,
  WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW,
  WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS,
} from "@meraki/shared/redux";
import * as LocalAuthentication from "expo-local-authentication";
import { get, isEmpty } from "lodash";
import DeviceInfo from "react-native-device-info";
import * as Keychain from "react-native-keychain";
import { AnyAction } from "redux";

import { clearLoginContext } from "~/actions/auth";
import { getClientApplicationUsages, getClientUsageHistories } from "~/actions/clients";
import { fetchCSRFToken, wrapApiActionWithCSRF } from "~/actions/csrf";
import { getDeviceStatuses } from "~/actions/devices";
import { fetchOrgNFOs } from "~/actions/nfos";
import { setIsSamlUser } from "~/actions/preferences";
import { getSensors } from "~/actions/sensors";
import { getUmbrellaStatus } from "~/actions/umbrella";
import { fetchOrgNetworks } from "~/api/actions/orgNetworks";
import { fetchOrg, fetchOrgs } from "~/api/actions/orgs";
import Network from "~/api/models/Network";
import Organization from "~/api/models/Organization";
import { NetworkAccessRoles, NetworkAccessRolesSchema } from "~/api/schemas/NetworkAccessRoles";
import { validatedMerakiRequest } from "~/api/util/request";
import {
  DISCONNECTED_STATUS,
  MASTER_ID,
  MAX_SSIDS,
  SECONDS_IN_A_DAY,
  SECONDS_IN_A_WEEK,
  SECONDS_IN_AN_HOUR,
} from "~/constants/MkiConstants";
import { DETOX, shardURL } from "~/env";
import I18n from "~/i18n/i18n";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { appSelect, isNightly, isWeb } from "~/lib/PlatformUtils";
import { calculateTimespan } from "~/lib/timeHelper";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkSelector,
  currentNetworkState,
  currentShardIdState,
  currentUserState,
  encryptedNetworkIdSelector,
  getCameraNodeGroupEid,
  getCurrentNetwork,
  getCurrentOrganization,
  getCurrentOrgEid,
  getLoginOrgs,
  getNetworkNodeGroupIds,
  getOrgsByEid,
  getSensorNodeGroupEid,
  getSingleOrgById,
  getSwitchNodeGroupEid,
  getUserEmail,
  getUserId,
  getWirelessNodeGroupEid,
  slimSsidsSelector,
  ssidsForCurrentNetwork,
  timespanState,
  userNeedsTFA,
} from "~/selectors";
import { Schemas } from "~/shared/lib/Schemas";
import { SMSTypes } from "~/shared/types/AuthTypes";
import Device, { DeviceDetails } from "~/shared/types/Device";
import { URLPrepends } from "~/shared/types/Networks";
import { ApiAction, AppDispatch, AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

const RememberUserOptions = {
  yes: "1",
  no: "0",
} as const;
type RememberUser = (typeof RememberUserOptions)[keyof typeof RememberUserOptions];

const getShardFromPath = (response: LoginDetailsResponseType) => {
  const { user } = response;
  const { path } = user;
  return Number(path.split(".")[0].split("n")[1]);
};

export const setShardFromDeeplinkPath = (path: string) => {
  const shardId = Number(path.split("/")[1]);
  return (dispatch: AppDispatch) => dispatch(setCurrentShardId(shardId));
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function submitTwoFactorAuth(
  code: string,
  remember = false,
): AppThunk<Promise<LoginResponseType | undefined>> {
  /* remember_user is a param that is required to set auth cookie expiry.
   this is required to save the cookie.

   remember is a param that is required to set two_factor_auth cookie, used
   to bypass TFA in the "remember" toggle flow.
  */
  const params: { code: string; remember_user: RememberUser; remember?: string } = {
    code,
    remember_user: RememberUserOptions.yes,
  };
  if (remember) {
    params.remember = RememberUserOptions.yes;
  }
  // @ts-expect-error return types not matching perfectly here but we're moving away from this code
  return async (dispatch) => {
    const { response } = await dispatch(
      wrapApiActionWithCSRF<LoginResponseType>({
        types: [TWO_FACTOR_REQUEST, TWO_FACTOR_SUCCESS, TWO_FACTOR_FAILURE],
        endpoint: "/login/do_sms_auth",
        config: {
          method: "POST",
          body: JSON.stringify(params),
        },
      }),
    );
    if (verifyIsLoginResponse(response)) {
      if (!response.success) {
        return Promise.reject();
      }

      const { org_eid, user } = response;

      const emailVerified = await checkIfEmailVerified(org_eid);

      if (!emailVerified) {
        return response;
      }
      dispatch(setInitialEntities(org_eid, user));
      return;
    }

    const { mode } = response;

    if (mode === "org_choose") {
      return { mode };
    } else if (mode === "two_factor" && response?.error) {
      return Promise.reject(response.error);
    }
    return Promise.reject();
  };
}

export function storeTFARememberMe(value: boolean): AppThunk {
  return (dispatch: AppDispatch) =>
    dispatch({
      type: STORE_TFA_REMEMBER_ME,
      response: value,
    });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function submitOTPAuth(code: string): AppThunk<Promise<LoginResponseType | undefined>> {
  // @ts-expect-error return types not matching perfectly here but we're moving away from this code
  return async (dispatch) => {
    const { response } = await dispatch(
      wrapApiActionWithCSRF<LoginResponseType>({
        types: [OTP_AUTH_REQUEST, OTP_AUTH_SUCCESS, OTP_AUTH_FAILURE],
        endpoint: "/login/do_otp_auth",
        config: {
          method: "POST",
          body: JSON.stringify({ code: code }),
        },
      }),
    );
    if (verifyIsLoginResponse(response)) {
      if (!response.success) {
        return Promise.reject();
      }
      dispatch(setShardFromPath(response));

      const { org_eid, user } = response;

      const emailVerified = await checkIfEmailVerified(org_eid);

      if (!emailVerified) {
        return response;
      }
      dispatch(setInitialEntities(org_eid, user));
      return;
    }
    const { mode } = response;
    if (mode === "org_choose") {
      return { mode };
    } else if (mode === "two_factor" && response?.error) {
      return Promise.reject(response.error);
    }
    return Promise.reject();
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function resendOTPCode(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [OTP_AUTH_RETRY_REQUEST, OTP_AUTH_RETRY_SUCCESS, OTP_AUTH_RETRY_FAILURE],
        endpoint: "/login/otp_auth_retry",
        config: {
          method: "POST",
          body: JSON.stringify({ retry: "email" }),
        },
      }),
    );
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function resendSMSCode(which: SMSTypes): AppThunk {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [SMS_AUTH_RETRY_REQUEST, SMS_AUTH_RETRY_SUCCESS, SMS_AUTH_RETRY_FAILURE],
        endpoint: "/login/sms_auth_retry",
        config: {
          method: "POST",
          body: JSON.stringify({ retry: which }),
        },
      }),
    );
}

export function getTimezones(): ApiAction {
  return {
    [CALL_API]: {
      types: [TIMEZONE_GET_REQUEST, TIMEZONE_GET_SUCCESS, TIMEZONE_GET_FAILURE],
      endpoint: "/go/tz_data",
      prepend: URLPrepends.portal,
      config: {
        method: "GET",
      },
    },
  };
}

function setUserData(user: any) {
  return {
    type: SET_USER_DATA,
    user,
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getUserProfileSettings(): ApiAction {
  return {
    [CALL_API]: {
      types: [
        USER_DATA_PROFILE_SETTINGS_GET_REQUEST,
        USER_DATA_PROFILE_SETTINGS_GET_SUCCESS,
        USER_DATA_PROFILE_SETTINGS_GET_FAILURE,
      ],
      endpoint: "/manage/users/user_profile_settings",
      config: {
        method: Method.get,
      },
    },
  };
}

function setDefaultNetwork(networkId: string | null) {
  return {
    type: SET_DEFAULT_NETWORK,
    networkId,
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getOrgSpIdp(subdomain: string): ApiAction {
  return {
    [CALL_API]: {
      types: [GET_ORG_SP_IDP_REQUEST, GET_ORG_SP_IDP_SUCCESS, GET_ORG_SP_IDP_FAILURE],
      endpoint: `/login/sso/${subdomain}`,
      config: {
        method: "GET",
      },
    },
  };
}

export function setCurrentNetwork(networkId: Network["id"] | null, setDefault = true): AppThunk {
  return (dispatch) => {
    if (setDefault) {
      dispatch(setDefaultNetwork(networkId));
    }
    return dispatch(libSetCurrentNetwork(networkId));
  };
}

export function setInitialEntities(
  encryptedOrgId: string,
  userData?: any,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(clearLoginContext());

      if (userData) {
        dispatch(setUserData(userData));

        const firebaseUserId = getUserId(getState());

        if (!!firebaseUserId) {
          await Promise.all([
            analytics.setUserId(firebaseUserId),
            analytics.setUserProperty("mki_user_id", firebaseUserId),
          ]);
        }
        errorMonitor.setUser(userData.id);
      }

      await dispatch(fetchOrgs());
      const organizations = getOrgsByEid(getState());
      // Redundant check is necessary since lodash isEmpty doesn't type narrow
      if (isEmpty(organizations) || !organizations) {
        return Promise.reject();
      }

      const organization = encryptedOrgId
        ? organizations[encryptedOrgId]
        : Object.values(organizations)[0];

      const { id: orgId, shardId: orgShardId } = organization;
      dispatch(setCurrentOrganization(orgId));
      errorMonitor.setOrgID(orgId);

      await Promise.all([
        analytics.setUserProperty("organization_id", orgId),
        analytics.setUserProperty("mki_org_id", orgId),
      ]);

      const shardId = orgShardId || MASTER_ID;
      dispatch(setCurrentShardId(shardId));

      await Promise.all([dispatch(fetchOrgNFOs()), dispatch(fetchOrgNetworks(orgId))]);

      const networkId = currentNetworkSelector(getState());

      if (__MERAKI_GO__) {
        dispatch(setCurrentNetwork(networkId));
        dispatch(cometInit());

        await Promise.all([
          dispatch(loadNodesAndStatuses(networkId)),
          dispatch(getUmbrellaStatus()),
          dispatch(getSsids(networkId)),
        ]);

        dispatch(setInitialHomeSSID());
      } else {
        if (!networkId) {
          throw new Error("networkId missing");
        }

        const orgWideView = await featureFlagClient.getBool("ld", "organization-wide-context");
        const currentNetworkId = orgWideView ? null : networkId;

        dispatch(setCurrentNetwork(currentNetworkId));

        dispatch(cometInit());
        await dispatch(fetchNetworkAccessRoles());
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        errorMonitor.notify(error);
      }
    } finally {
      dispatch(setIsSamlUser(false));
      dispatch(finishedAdditionalLoginRequests());
      dispatch({ type: ORG_CHOSEN });
    }
  };
}

export function setEntitiesAdmin(
  orgId: string,
  eid: string,
  shardId: number | null,
  networkId?: string,
  hideDrawer?: () => void,
): AppThunk {
  return async (dispatch, getState) => {
    try {
      dispatch(setCurrentOrganization(orgId));
      dispatch(setCurrentShardId(shardId ?? MASTER_ID));
      errorMonitor.setOrgID(orgId);
      await dispatch(getOrgCookie(eid));
      await dispatch(fetchOrgNetworks(orgId));
      dispatch(setCurrentNetwork(networkId || currentNetworkSelector(getState())));
      hideDrawer?.();
    } catch (error: unknown) {
      if (error instanceof Error) {
        errorMonitor.notify(error);
      }
    }
  };
}

function setInitialSamlOrganizations(orgId: string): AppThunk<Promise<unknown>> {
  return async (dispatch) => {
    await dispatch(fetchOrg(orgId)).catch(async () => {
      /*
        network and camera only admins don't have perms to fetch
        single org. This is a workaround for inconsistant endpoint perms
      */
      await dispatch(fetchOrgs());
    });
  };
}

export function setInitialSamlLoginEntities(orgId: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      await dispatch(setInitialSamlOrganizations(orgId));
      const organization = getSingleOrgById(getState(), orgId);

      if (isEmpty(organization) || !organization) {
        return Promise.reject();
      }

      const { id: currentOrgId, shardId: orgShardId } = organization;

      const shardId = orgShardId;

      dispatch(setCurrentShardId(shardId || MASTER_ID));

      dispatch(setCurrentNetwork(null, false));
      dispatch(setCurrentOrganization(currentOrgId));
      errorMonitor.setOrgID(currentOrgId);

      await Promise.all([
        analytics.setUserProperty("organization_id", currentOrgId),
        analytics.setUserProperty("mki_org_id", currentOrgId),
      ]);

      await Promise.all([dispatch(fetchOrgNFOs()), dispatch(fetchOrgNetworks(currentOrgId))]);

      const networkId = currentNetworkSelector(getState());

      if (!networkId) {
        throw new Error("networkId missing");
      }

      dispatch(setCurrentNetwork(networkId));

      await dispatch(fetchNetworkAccessRoles());

      await dispatch(fetchUserData());
      const userID = getUserId(getState());
      errorMonitor.setUser(userID);
      const userEmail = getUserEmail(getState());
      dispatch(setCurrentUser(userEmail));

      dispatch(fetchCSRFToken(shardId));
      dispatch(cometInit());

      if (__MERAKI_GO__) {
        dispatch(setCurrentNetwork(networkId));
        dispatch(cometInit());

        await Promise.all([
          dispatch(loadNodesAndStatuses(networkId)),
          dispatch(getUmbrellaStatus()),
          dispatch(getSsids(networkId)),
        ]);

        dispatch(setInitialHomeSSID());
      }
    } catch (error: unknown) {
      console.log(error);
      if (error instanceof Error) {
        errorMonitor.notify(error);
      }
    } finally {
      dispatch(setIsSamlUser(true));
      dispatch(finishedAdditionalLoginRequests());
      dispatch({ type: ORG_CHOSEN });
    }
  };
}

function setInitialHomeSSID(): AppThunk {
  return (dispatch) => {
    return dispatch(setHomeSSID(MAX_SSIDS));
  };
}

function finishedAdditionalLoginRequests() {
  return {
    type: FINISHED_ADDITIONAL_LOGIN_REQUESTS,
  };
}

export function registerCometListeners(): AppThunk {
  return (dispatch, getState) =>
    dispatch(
      registerHandshakeListener((message: any) => {
        if (message.successful && !getState().liveBroker.connected) {
          dispatch(cometConnected());
        }
      }),
    );
}

export type LoginData = {
  email: string;
  password: string;
  captcha_shown?: boolean;
  mki_captcha_provider_response?: string;
  mki_captcha_provider_id?: string;
};

export function tryBioAuthLogin(
  makeLoginRequest = false,
): AppThunk<Promise<ApiResponseAction<LoginResponseType | undefined> | undefined>> {
  return async (dispatch) => {
    // user has removed their biometrics from the device
    if (!(await LocalAuthentication.isEnrolledAsync())) {
      // TODO: set biometricAuthState to false here;
      analytics.logEvent(FIREBASE_EVENTS.bioAuthNotEnrolled);
      throw Error("Bioauth not enrolled");
    }

    if ((await LocalAuthentication.authenticateAsync()).success) {
      // TODO: DM-3290 only relogin if session has expired
      if (makeLoginRequest) {
        return await dispatch(tryLoginWithSavedCredentials());
      }

      return;
    } else {
      dispatch(resetLoginState());
    }

    throw Error("Failed to login via bioauth");
  };
}

function tryLoginWithSavedCredentials(): AppThunk<
  Promise<ApiResponseAction<LoginResponseType | undefined>>
> {
  return async (dispatch, getState) => {
    const lastLoggedInUser = currentUserState(getState());
    if (!lastLoggedInUser) {
      throw Error("No last logged in user");
    }
    const credentials = await Keychain.getInternetCredentials(lastLoggedInUser);
    if (!credentials) {
      throw Error("No saved credentials");
    }

    const { username, password } = credentials;
    return await dispatch(loginUser({ email: username, password }));
  };
}

export function sendLastLoginToMarketo() {
  return wrapApiActionWithCSRF({
    types: [
      SEND_LAST_LOGIN_TO_MARKETO_SUCCESS,
      SEND_LAST_LOGIN_TO_MARKETO_REQUEST,
      SEND_LAST_LOGIN_TO_MARKETO_FAILURE,
    ],
    endpoint: "/mobile/send_last_login_to_marketo",
    config: {
      method: Method.post,
    },
  });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function loginUser(
  loginData: LoginData,
): AppThunk<Promise<ApiResponseAction<LoginResponseType | undefined>>> {
  const { email, password } = loginData;
  const udid = DeviceInfo.getUniqueId();
  const action: ApiAction<LoginResponseType>[typeof CALL_API] = {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    endpoint: "/login/login",
    config: {
      method: "POST",
      body: JSON.stringify({
        ...loginData,
        remember_user: 0,
      }),
      headers: {
        "X-Mobile-Device-Id": udid,
      },
    },
    meta: {
      user: email,
    },
  };

  //@ts-ignore
  return async (dispatch, getState) => {
    // Dashboard requires login request to be protected by authenticity token when referer is present
    const loginRequest = isWeb()
      ? dispatch(wrapApiActionWithCSRF<LoginResponseType>(action))
      : dispatch({ [CALL_API]: action } as ApiAction<LoginResponseType>);

    const { response } = await loginRequest;

    const shouldUseNewLogin = await featureFlagClient.getBool("ld", "use-shared-auth");

    if (shouldUseNewLogin && !__MERAKI_GO__) {
      return standardizeLoginResponse(response);
    }

    dispatch(setCurrentUser(email));

    if (__MERAKI_GO__) {
      dispatch(sendLastLoginToMarketo());
    }

    if (!isWeb()) {
      Keychain.setInternetCredentials(email, email, password).catch(errorMonitor.notify);
    }

    if (verifyIsLoginResponse(response)) {
      if (!response.success) {
        return Promise.reject();
      }
      const { org_eid, user } = response;

      const emailVerified = await checkIfEmailVerified(org_eid);

      if (!emailVerified) {
        return response;
      }
      const shard = getShardFromPath(response);
      dispatch(fetchCSRFToken(shard));

      if (__MERAKI_GO__ && userNeedsTFA(getState())) {
        dispatch(setCurrentShardId(shard));
        return { mode: "two_factor_onboarding" as const };
      }

      dispatch(setInitialEntities(org_eid, user));
      return;
    }

    const { mode } = response;

    if (mode === "two_factor" && response?.error) {
      return Promise.reject(response.error);
    }

    if (
      mode === "two_factor" ||
      mode === "sms" ||
      mode === "one_time_password" ||
      (__MERAKI_GO__ && isNightly && mode === "org_choose") ||
      (!__MERAKI_GO__ && mode === "org_choose")
    ) {
      return { mode };
    } else if (!isNightly && mode === "org_choose") {
      if (__MERAKI_GO__ && userNeedsTFA(getState())) {
        const eid = getLoginOrgs(getState())?.find((org) => org.two_factor_auth_enabled)?.eid;
        if (!eid) {
          return Promise.reject();
        }
        const { response } = await dispatch(initialOrgChoose(eid));
        const tfaShard = getShardFromPath(response);
        dispatch(setCurrentShardId(tfaShard));
        return { mode: "two_factor_onboarding" as const };
      }

      return { mode };
    } else if (mode === "two_factor_onboarding") {
      const eid = getLoginOrgs(getState())?.find((org) => org.two_factor_auth_enabled)?.eid;
      if (!eid) {
        return Promise.reject();
      }
      const { response } = await dispatch(initialOrgChoose(eid));
      const tfaShard = getShardFromPath(response);
      dispatch(setCurrentShardId(tfaShard));
      return { mode: "two_factor_onboarding" as const };
    }
    return Promise.reject();
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function requestResetPasswordEnterprise(email: string): ApiAction {
  const udid = DeviceInfo.getUniqueId();
  return {
    [CALL_API]: {
      types: [
        REQUEST_PASSWORD_RESET_REQUEST,
        REQUEST_PASSWORD_RESET_SUCCESS,
        REQUEST_PASSWORD_RESET_FAILURE,
      ],
      endpoint: "/login/reset_password_submit",
      config: {
        method: "POST",
        headers: {
          "X-Mobile-Device-Id": udid,
        },
        body: JSON.stringify({
          email,
        }),
      },
    },
  };
}

export function setCurrentUser(user: any): AppThunk {
  return (dispatch) =>
    dispatch({
      type: SET_CURRENT_USER,
      user,
    });
}

export interface WipeReduxAction extends AnyAction {
  type: typeof WIPE_REDUX;
}

export function wipeRedux(): WipeReduxAction {
  return { type: WIPE_REDUX };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 *
 * This action is meant to get the initial auth cookie for a mulit-org account.
 * The data returned from the request is handled differently than orgChoose and
 * auth state should remain unchanged since a user has not selected an org yet.
 */
export function initialOrgChoose(
  encryptedOrgId: string,
): AppThunk<Promise<ApiResponseAction<LoginDetailsResponseType>>> {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        types: [INITIAL_ORG_CHOOSE_REQUEST, INITIAL_ORG_CHOOSE_SUCCESS, INITIAL_ORG_CHOOSE_FAILURE],
        endpoint: "/login/org_choose",
        config: {
          method: Method.get,
          queryParams: {
            eid: encryptedOrgId,
          },
        },
      },
    } as ApiAction<LoginDetailsResponseType>);
}

async function checkIfEmailVerified(org_eid: string) {
  if (__MERAKI_GO__) {
    return true;
  }
  const { verified } = await queryClient.fetchQuery(
    useCheckIfEmailVerified.queryKey({ orgEid: org_eid }),
    useCheckIfEmailVerified.queryFn({ orgEid: org_eid }),
  );
  return verified;
}

export function dispatchLoginActions(response: LoginDetailsResponseType, email?: string): AppThunk {
  const { org_eid, user } = response;

  return async (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS,
      response,
      meta: { user: email },
    });

    if (email) {
      dispatch(setCurrentUser(email));
    }

    const shard = getShardFromPath(response);

    await dispatch(fetchCSRFToken(shard));
    dispatch(setInitialEntities(org_eid, user));
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function orgChoose(
  encryptedOrgId: string,
): AppThunk<Promise<LoginDetailsResponseType | undefined>> {
  return async (dispatch) => {
    const { response }: { response: LoginDetailsResponseType } = await dispatch({
      [CALL_API]: {
        types: [ORG_CHOOSE_REQUEST, ORG_CHOOSE_SUCCESS, ORG_CHOOSE_FAILURE],
        endpoint: "/login/org_choose",
        config: {
          method: Method.get,
          queryParams: {
            eid: encryptedOrgId,
            remember_user: 1,
          },
        },
      },
    });

    const { success, org_eid, user } = response;

    if (success === true) {
      const emailVerified = await checkIfEmailVerified(org_eid);

      if (!emailVerified) {
        return response;
      }

      dispatch(setInitialEntities(encryptedOrgId, user));
      return;
    }

    return Promise.reject();
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
function getOrgCookie(eid: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        types: [ORG_OVERVIEW_REQUEST, ORG_OVERVIEW_SUCCESS, ORG_OVERVIEW_FAILURE],
        endpoint: `/o/${eid}/manage/organization/overview`,
        config: {
          method: "GET",
        },
      },
    });
}

export function setOrg(org: Organization): AppThunk<Promise<any> | void> {
  return (dispatch) => {
    const { id, eid, shardId } = org;

    dispatch(setCurrentNetwork(null, false));

    errorMonitor.setOrgID(id);

    analytics.setUserProperty("mki_org_id", org.id);
    analytics.setUserProperty("organization_id", org.id);
    dispatch(setCurrentOrganization(id));
    dispatch(setCurrentShardId(shardId ?? MASTER_ID));

    return dispatch(getOrgCookie(eid))
      .then(() => {
        dispatch(cometInit());
        return dispatch(fetchOrgNetworks(id));
      })
      .catch(() => {
        return Promise.reject(I18n.t("ORG_OVERVIEW.ERROR"));
      });
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function orgOverview(newOrgEid: string): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return async (dispatch, getState) => {
    const orgEid = getCurrentOrgEid(getState());
    if (orgEid === newOrgEid) {
      return Promise.resolve();
    }

    const organization = getOrgsByEid(getState())?.[newOrgEid];
    if (!organization) {
      return Promise.resolve();
    }

    const shardId = organization.shardId || MASTER_ID;
    dispatch(setCurrentShardId(shardId));
    dispatch(setCurrentOrganization(organization.id));

    await dispatch({
      [CALL_API]: {
        types: [ORG_OVERVIEW_REQUEST, ORG_OVERVIEW_SUCCESS, ORG_OVERVIEW_FAILURE],
        endpoint: `/o/${newOrgEid}/manage/organization/overview`,
        config: {
          method: "GET",
        },
      },
    }).catch((error: unknown) => Promise.reject(error || I18n.t("ORG_OVERVIEW.ERROR")));
    dispatch(setInitialEntities(newOrgEid));
  };
}

/**
 * Fetches devices within a network. This endpoint requires the user to have network-wide
 * read access. Camera only Admins do not have network-wide read access.
 */
export function loadNodes(networkId?: string): ApiAction {
  return {
    [CALL_API]: {
      types: [NODES_REQUEST, NODES_SUCCESS, NODES_FAILURE],
      endpoint: `/api/v1/networks/${networkId}/devices`,
      config: {
        method: "GET",
      },
      schema: Schemas.NODE_ARRAY,
    },
  };
}

export function loadNodesAndStatuses(
  networkId?: string,
): AppThunk<
  Promise<ApiResponseAction<any> | [ApiResponseAction<any>, void | ApiResponseAction<any>[]]>
> {
  return (dispatch) =>
    Promise.all([dispatch(loadNodes(networkId)), dispatch(getDeviceStatuses())]).catch(() =>
      Promise.reject(I18n.t("NETWORK_ERROR")),
    );
}

export function getSensorsAndDeviceStatuses(
  networkId: string,
): AppThunk<
  Promise<ApiResponseAction<any> | [void | ApiResponseAction<any>[], ApiResponseAction<any>]>
> {
  return (dispatch) =>
    Promise.all([dispatch(getDeviceStatuses()), dispatch(getSensors(networkId))]).catch(() =>
      Promise.reject(I18n.t("NETWORK_ERROR")),
    );
}

export function setOnboardingDeviceEdits(deviceDetails: DeviceDetails) {
  return {
    type: SET_ONBOARDING_DEVICE_EDITS,
    deviceDetails: deviceDetails,
  };
}

export function clearOnboardingDeviceEdits() {
  return {
    type: CLEAR_ONBOARDING_DEVICE_EDITS,
  };
}

export function setDeviceOnboardingContinued() {
  return {
    type: SET_DEVICE_ONBOARDING_CONTINUED,
  };
}

export function setWirelessConfigLaunchedFromAddFlow() {
  return {
    type: WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW,
  };
}

export function setWirelessConfigLaunchedFromDetails() {
  return {
    type: WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS,
  };
}

export function setDeviceOnboardingComplete() {
  return {
    type: SET_DEVICE_ONBOARDING_COMPLETE,
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/get-network-clients/
 */
export const getClientsPrivateEndpoint =
  (
    isBlocked: boolean,
    ssidNumber = -1,
    timespan?: number,
  ): AppThunk<Promise<ApiResponseAction<ClientList>>> =>
  (dispatch, getState) =>
    dispatch({
      [CALL_API]: {
        types: [CLIENT_LIST_JSON_REQUEST, CLIENT_LIST_JSON_SUCCESS, CLIENT_LIST_JSON_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/usage/client_list_json`,
        config: {
          method: "GET",
          queryParams: {
            filter: isBlocked ? "listed" : ssidNumber,
            ...(!isBlocked ? { timespan: timespan ?? timespanState(getState()) } : {}),
          },
        },
        meta: {
          ssids: slimSsidsSelector(getState()),
          timespan: getState().preferences.timespan,
        },
        callback: (json) => mapFromPrivateToPublicEndpoint(json),
      },
    });

export const getClients = (ssidNumber?: number, timespan?: number) =>
  getClientsPrivateEndpoint(false, ssidNumber, timespan);

export const getBlockedClients = (ssidNumber?: number, timespan?: number) =>
  getClientsPrivateEndpoint(true, ssidNumber, timespan);

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getSsidUseblocks(
  networkId: any,
  encryptedNetworkId: string,
  ssidNumber: any,
  timespan = defaultTimespans.WEEK.value,
): ApiAction {
  return {
    [CALL_API]: {
      types: [SSID_USEBLOCKS_REQUEST, SSID_USEBLOCKS_SUCCESS, SSID_USEBLOCKS_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/usage/network_useblocks`,
      meta: { networkId, ssidNumber, timespan },
      config: {
        method: "GET",
        queryParams: { filter: ssidNumber, timespan },
      },
    },
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getPresenceData(
  t0: number,
  t1: number,
  timespan?: number,
): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    if (DETOX) {
      return Promise.resolve();
    }

    const orgId = getCurrentOrganization(getState());
    const networkId = getWirelessNodeGroupEid(getState());
    t0 = Math.round(t0);
    t1 = Math.round(t1);
    const dt = t1 - t0 < SECONDS_IN_A_WEEK ? SECONDS_IN_AN_HOUR : SECONDS_IN_A_DAY;
    return dispatch(
      wrapApiActionWithCSRF({
        types: [PRESENCE_DATA_REQUEST, PRESENCE_DATA_SUCCESS, PRESENCE_DATA_FAILURE],
        endpoint: `/nmr/o/${orgId}/manage/reports/presence`,
        meta: { t0, t1, networkId, timespan },
        config: {
          method: "GET",
          queryParams: { t0, t1, dt, networkId },
          noHeaderFill: true,
        },
      }),
    );
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getNetworkUseblocks(
  networkId: string,
  encryptedNetworkId: string,
  timespan = defaultTimespans.WEEK.value,
): ApiAction {
  const callback = (json: unknown) => ({ id: networkId, [timespan]: json });
  return {
    [CALL_API]: {
      types: [NETWORK_USEBLOCKS_REQUEST, NETWORK_USEBLOCKS_SUCCESS, NETWORK_USEBLOCKS_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/usage/network_useblocks`,
      config: {
        method: "GET",
        queryParams: { timespan, denormalize: "t" },
      },
      schema: Schemas.NETWORK_USEBLOCKS,
      callback,
    },
  };
}

export function getClientsAndUseblocks(
  timespan: number = defaultTimespans.WEEK.value,
  ssidNumber = -1,
): AppThunk {
  return (dispatch) => {
    dispatch({ type: CLIENTS_AND_USEBLOCKS_START });
    return (
      dispatch(getClients(ssidNumber))
        //@ts-ignore
        .then(({ response }) => {
          if (isEmpty(response)) {
            // Means zero clients for the timespan.
            // Don't try to fetch usage data.
            return Promise.resolve();
          }
          const ids = Object.keys(response);
          return dispatch(getClientUsage(ids, timespan, ssidNumber));
        })
        .then(() => dispatch(getBlockedClients()))
        .finally(() => dispatch({ type: CLIENTS_AND_USEBLOCKS_FINISH }))
    );
  };
}

const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  const len = array.length;
  let i = 0;
  while (i < len) {
    chunks.push(array.slice(i, (i += chunkSize)));
  }
  return chunks;
};

export function getClientUsage(
  clientIDs: string[],
  timespan: number = defaultTimespans.WEEK.value,
  ssidNumber = -1,
): AppThunk<Promise<any[]>> {
  const chunkedIds = chunkArray(clientIDs, 200);
  return (dispatch) =>
    Promise.all(
      chunkedIds
        .map((clientSubset) => [
          dispatch(getClientUsageHistories(clientSubset, timespan, ssidNumber)),
          dispatch(getClientApplicationUsages(clientSubset, timespan, ssidNumber)),
        ])
        .flat(),
    );
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getDeviceUsage(
  device: Device,
  timespan: number = defaultTimespans.WEEK.value,
): ApiAction {
  const { serial, id, networkEid } = device;
  const callback = (json: any) => ({
    id: serial,
    [timespan]: json,
  });
  return {
    [CALL_API]: {
      types: [DEVICE_USAGE_REQUEST, DEVICE_USAGE_SUCCESS, DEVICE_USAGE_FAILURE],
      endpoint: `/n/${networkEid}/manage/nodes/show3_graph_data`,
      config: {
        method: "GET",
        queryParams: {
          for_new_node_details: true,
          id,
          timespan,
        },
      },
      schema: Schemas.DEVICE_USAGE,
      callback,
    },
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getCounterSets(networkId: any, encryptedNetworkId: any): ApiAction {
  return {
    [CALL_API]: {
      types: [COUNTER_SETS_REQUEST, COUNTER_SETS_SUCCESS, COUNTER_SETS_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/usage/counter_sets`,
      config: { method: "GET" },
      meta: { networkId },
    },
  };
}

function setClientInfo(client: any) {
  return {
    type: SET_CLIENT_INFO,
    client,
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/create-network-group-policy/
 */
export function getNetworkSsidsAndPolicies(encryptedNetworkId: any): ApiAction {
  return {
    [CALL_API]: {
      types: [SSID_GROUP_POLICY_REQUEST, SSID_GROUP_POLICY_SUCCESS, SSID_GROUP_POLICY_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/usage/network_ssids_and_policies`,
      config: { method: "GET" },
      schema: {
        group_policies: Schemas.GROUP_POLICIES,
      },
    },
  };
}

// This endpoint is used by our configure/switchports page
/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/get-device-switch-ports/
 */
export function getSwitchPortsJson(
  nodeId: string,
  serial: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  // This endpoint conditionally returns jsonP if enterprise
  // and regular JSON for Go. We need to continue this trend
  // to ensure backwards compatability with old Cordova users.
  const jsonPResponse = appSelect({
    enterprise: true,
    go: false,
  });
  return (dispatch, getState) => {
    const eid = getSwitchNodeGroupEid(getState());
    const timespan = timespanState(getState());
    return dispatch({
      [CALL_API]: {
        types: [SWITCH_PORTS_JSON_REQUEST, SWITCH_PORTS_JSON_SUCCESS, SWITCH_PORTS_JSON_FAILURE],
        endpoint: `/n/${eid}/manage/nodes/ports_json`,
        config: {
          method: "GET",
          queryParams: {
            node_id: nodeId,
            ...calculateTimespan(timespan),
            aggregates: false,
          },
          jsonPResponse,
        },
        meta: { id: serial },
      },
    });
  };
}

export function setBiometricAuth(value: any) {
  useBioauthEnabled.getState().setEnabled(value?.enabled ?? false);
  return {
    type: SET_BIOMETRIC_AUTH,
    biometricAuth: value,
  };
}

export function setNestedModalData(data: any): AppThunk {
  return (dispatch) => dispatch({ type: SET_NESTED_MODAL_DATA, nestedModalData: data });
}

export function clearNestedModalData(): AppThunk {
  return (dispatch) => dispatch({ type: CLEAR_NESTED_MODAL_DATA });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/update-network-group-policy/
 */
export function setManyPolicies(encryptedNetworkId: any, data: any): AppThunk<Promise<void>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [SET_MANY_POLICIES_REQUEST, SET_MANY_POLICIES_SUCCESS, SET_MANY_POLICIES_FAILURE],
        endpoint: `/n/${encryptedNetworkId}/manage/usage/set_many_policies`,
        config: {
          method: "POST",
          body: JSON.stringify(data),
        },
      }),
    ).then(({ response }) => {
      // response: { wired_group_num: <String>, wireless_group_nums: <String>, ssid_masks: <String> }
      data.ids.forEach((clientId: any) => dispatch(setClientInfo({ ...response, id: clientId })));
    });
}

export function setTimespan(timespan: any, skipReload = false): AppThunk {
  return (dispatch, getState) => {
    if (timespan !== timespanState(getState())) {
      return dispatch({
        type: SET_TIMESPAN,
        timespan,
        meta: {
          skipReload,
        },
      });
    }
    return null;
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getNetworkHealth(): AppThunk {
  return (dispatch, getState) => {
    const orgEid = getCurrentOrgEid(getState());
    const timespan = timespanState(getState()) || defaultTimespans.WEEK.value;
    const ids = getNetworkNodeGroupIds(getState());
    const { t0, t1 } = calculateTimespan(timespan);
    // This is a POST because the list of ids could be very long, but GETs
    // don't support that many characters.
    return dispatch(
      wrapApiActionWithCSRF({
        types: [NETWORK_HEALTH_REQUEST, NETWORK_HEALTH_SUCCESS, NETWORK_HEALTH_FAILURE],
        endpoint: `/o/${orgEid}/manage/organization/network_health_time_series_bar`,
        config: {
          method: "POST",
          body: JSON.stringify({ ids, t0, t1 }),
        },
        meta: {
          t0,
          t1,
        },
        schema: Schemas.NETWORK_HEALTH_ARRAY,
      }),
    );
  };
}

export function getSsids(networkId: any): ApiAction {
  const callback = (json: any) => ({
    networkId,
    wireless: json,
  });
  return {
    [CALL_API]: {
      types: [SSIDS_REQUEST, SSIDS_SUCCESS, SSIDS_FAILURE],
      endpoint: `/api/v1/networks/${networkId}/wireless/ssids`,
      config: { method: "GET" },
      schema: Schemas.SSIDS,
      callback,
    },
  };
}

export function setSsid(
  networkId: any,
  newSsid: any,
): AppThunk<Promise<{ type: string; networkId: string; ssids: any[] }>> {
  const num = newSsid.number;
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [SET_SSID_REQUEST, SET_SSID_SUCCESS, SET_SSID_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${num}`,
        config: {
          method: "PUT",
          body: JSON.stringify(newSsid),
        },
      }),
    ).then(({ response }: any) => {
      const ssids = ssidsForCurrentNetwork(getState());
      return dispatch({
        type: SET_REDUX_SSIDS,
        networkId,
        ssids: [...ssids.slice(0, num), { ...ssids[num], ...response }, ...ssids.slice(num + 1)],
      });
    });
}

export function deleteSsid(
  networkId: string,
  ssidNumber: any,
): AppThunk<Promise<{ type: string; networkId: string; ssids: any[] }>> {
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [DELETE_SSID_REQUEST, DELETE_SSID_SUCCESS, DELETE_SSID_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/reset`,
        config: { method: "POST" },
      }),
    ).then(({ response }: any) => {
      const ssids = ssidsForCurrentNetwork(getState());
      return dispatch({
        type: SET_REDUX_SSIDS,
        networkId,
        ssids: [...ssids.slice(0, ssidNumber), response, ...ssids.slice(ssidNumber + 1)],
      });
    });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/get-network-wireless-ssid-splash-settings/
 */
export function updateSplashLogo(
  organization: Organization,
  encryptedNetworkId: string,
  image: string,
): AppThunk<Promise<{ md5: string; extension: string }>> {
  // the ImagePicker will by default generate temp images in jpeg format. Since all
  // images that the user uploads must go through this process, we can safely get by
  // for now by assuming in the formdata type: image/jpeg. We also don't use the name
  // in any meaningful way, so adding name here is to just ensure that the request is
  // properly formed.
  // If in the future we add additional methods of image upload, we will need to dynamically
  // check for the type of the image and the name before uploading.

  const formdata = new FormData();
  formdata.append("ssid_splash_detail[logo]", {
    uri: image,
    type: "image/jpeg",
    name: "logo.jpg",
  });

  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_SPLASH_LOGO_REQUEST, UPDATE_SPLASH_LOGO_SUCCESS, UPDATE_SPLASH_LOGO_FAILURE],
        endpoint: `/${organization.name}/n/${encryptedNetworkId}/manage/configure/update_splash_image`,
        config: {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          method: "POST",
          body: formdata,
          noHeaderFill: true,
          textResponse: true,
        },
      }),
    )
      .then(({ response }) => {
        // this path only reached when we get a 200 response. 200 response always comes with a string
        // shaped as follows: "Success: #{md5}.#{extension}"
        const result = get(response, "result", "error");
        if (result === "error") {
          return Promise.reject(new Error(I18n.t("SPLASH_CONFIG.LOGO_UPLOAD_ERROR_MESSAGE")));
        }
        const message = result.split(":")[1].trim();
        const [md5, extension] = message.split(".");
        return { md5, extension };
      })
      .catch(
        // otherwise, we end up with an error which ends up being parsed generically and api.js returns
        // "Invalid Response" (api.js:checkStatus). We replace that error message with something a
        // little more helpful (but still generic)
        () => Promise.reject(new Error(I18n.t("SPLASH_CONFIG.LOGO_UPLOAD_ERROR_MESSAGE"))),
      );
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getRuleUseblocks(
  encryptedNetworkId: any,
  networkId: any,
  csrid: any,
  src: any,
  timespan = defaultTimespans.WEEK.value,
  ssidNumber = -1,
): ApiAction {
  const callback = (json: any) => ({
    id: networkId,
    [timespan]: nestedValueExists(json, ["allnode", "nruseblocks"], {}),
  });
  return {
    [CALL_API]: {
      types: [RULE_USEBLOCKS_REQUEST, RULE_USEBLOCKS_SUCCESS, RULE_USEBLOCKS_FAILURE],
      endpoint: `/n/${encryptedNetworkId}/manage/usage/rule_useblocks`,
      config: {
        method: "GET",
        queryParams: {
          csid: "19",
          csrid: csrid.join(" "),
          f: "netruseblocks",
          src: src.join(" "),
          ssid: ssidNumber,
          ...calculateTimespan(timespan),
        },
      },
      schema: Schemas.RULE_USEBLOCKS,
      callback,
    },
  };
}

export function setSearchText(key: any, text: any) {
  return {
    type: SET_SEARCH_TEXT,
    searchKey: key,
    searchText: text,
  };
}

export function clearSearchText(key: any) {
  return {
    type: CLEAR_SEARCH_TEXT,
    searchKey: key,
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function unclaimDevice(nodeId: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const orgEid = getCurrentOrgEid(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [INVENTORY_REMOVE_REQUEST, INVENTORY_REMOVE_SUCCESS, INVENTORY_REMOVE_FAILURE],
        endpoint: `/o/${orgEid}/manage/organization/unclaim_devices`,
        config: {
          method: "POST",
          body: JSON.stringify({ items: [nodeId] }),
        },
      }),
    );
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function createNetwork(
  encryptedOrgId: any,
  options: any,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [CREATE_NETWORK_REQUEST, CREATE_NETWORK_SUCCESS, CREATE_NETWORK_FAILURE],
        endpoint: `/o/${encryptedOrgId}/manage/organization/create_network`,
        config: {
          method: "POST",
          body: JSON.stringify(options),
        },
      }),
    );
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/claim-into-organization-inventory/
 */
export function addNodes(serials: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [ADD_NODES_REQUEST, ADD_NODES_SUCCESS, ADD_NODES_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/node_group/add_nodes`,
        config: {
          method: "POST",
          body: JSON.stringify({ nodes: serials }),
        },
      }),
    );
}

export function updateDevice(
  serial: string,
  attributes: any,
): AppThunk<Promise<ReturnType<typeof setDevice>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_DEVICE_REQUEST, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE],
        endpoint: `/api/v1/devices/${serial}`,
        config: {
          method: "PUT",
          body: JSON.stringify(attributes),
        },
      }),
    ).then(({ response }: any) => dispatch(setDevice({ ...attributes, ...response })));
}

function setDevice(device: any) {
  return { type: SET_DEVICE, device };
}

export function cometInit(): AppThunk {
  return (dispatch, getState, cometd) => {
    // livebroker can cause some problems with detox as well as not needed for our tests (so far).
    // For now, we will disable cometd for detox tests.
    if (DETOX) {
      return;
    }
    if (getState().liveBroker.initialized) {
      return;
    }
    dispatch({ type: LB_INIT });
    if (cometd.getStatus() === DISCONNECTED_STATUS) {
      const shardId = currentShardIdState(getState());
      const url = `${shardURL(shardId)}/cometd`;
      cometd.init({ url, useWorkerScheduler: false });
      LiveBroker.setUrl(url);
    }
  };
}

export function cometConnected(): AppThunk {
  return (dispatch) => dispatch({ type: LB_CONNECTED });
}

export function registerHandshakeListener(callback: any): AppThunk {
  return (_dispatch, _getState, cometd) => cometd.addListener("/meta/handshake", callback);
}

export function removeHandshakeListener(listener: any): AppThunk {
  return (_dispatch, _getState, cometd) => {
    cometd.removeListener(listener);
  };
}

export function resubscribe(subscription: any): AppThunk {
  return (_dispatch, getState, cometd) => {
    if (!getState().liveBroker.connected) {
      return;
    }
    if (subscription && subscription.id) {
      const request = getState().liveBroker.publishData[subscription.id];
      cometd.batch(() => {
        cometd.resubscribe(subscription);
        if (request) {
          cometd.publish("/requests", request);
        }
      });
    }
  };
}

function subscribe(channel: any, handler: any, request: any): AppThunk {
  return (dispatch, getState, cometd) => {
    let subscription;
    if (!getState().liveBroker.connected) {
      return null;
    }
    cometd.batch(() => {
      subscription = cometd.subscribe(channel, handler);
      if (request) {
        cometd.publish("/requests", request);
      }
    });
    dispatch({
      type: LB_ADD_PUBLISH_DATA,
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      id: subscription?.id,
      request,
    });
    return subscription;
  };
}

export function nodeSubscribe(type: any, handler: any, deviceId: any): AppThunk {
  return (dispatch) => {
    const channel = `/node/${deviceId}/${type}`;
    const request = { node_id: deviceId, type };
    return dispatch(subscribe(channel, handler, request));
  };
}

export function commandSubscribe(args: any, handler: any, deviceId: any): AppThunk {
  return (dispatch) => {
    const pid = Math.floor(Math.random() * 1000);
    const channel = `/node/${deviceId}/${args.broker}/${pid}`;
    const request = {
      node_id: deviceId,
      type: args.broker,
      pid,
      ...args,
    };
    return dispatch(subscribe(channel, handler, request));
  };
}

export function nodeGroupSubscribe(type: any, handler: any, nodeGroupId: any): AppThunk {
  return (dispatch) => {
    const channel = `/node_group/${nodeGroupId}/${type}`;
    const request = { ng_id: nodeGroupId, type };
    return dispatch(subscribe(channel, handler, request));
  };
}

export function clientSubscribe(type: any, handler: any, nodeGroupId: any, mac: any): AppThunk {
  return (dispatch) => {
    const channel = `/node_group/${nodeGroupId}/client/${mac}/${type}`;
    const request = { ng_id: nodeGroupId, client: mac, type };
    return dispatch(subscribe(channel, handler, request));
  };
}

export function unsubscribe(subscription: any): AppThunk {
  return (dispatch, _getState, cometd) => {
    if (subscription && !cometd.isDisconnected()) {
      dispatch({
        type: LB_REMOVE_PUBLISH_DATA,
        id: subscription.id,
      });
      cometd.unsubscribe(subscription);
    }
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 * @see https://developer.cisco.com/meraki/api-v1/provision-network-clients/
 */
export const updateClient =
  (client: any): AppThunk<Promise<ReturnType<typeof setClientInfo>>> =>
  (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(getState())}/manage/usage/update_lcd`,
        config: {
          method: "POST",
          body: JSON.stringify({ ...client, desc: client.description }),
        },
      }),
    ).then(() => dispatch(setClientInfo(client)));

export const setHomeSSID = (homeSSID: any) => ({
  type: SET_HOME_SSID,
  homeSSID,
});

export function getVlans(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [VLANS_GET_REQUEST, VLANS_GET_SUCCESS, VLANS_GET_FAIL],
        endpoint: `/api/v1/networks/${networkId}/appliance/vlans`,
        meta: {
          networkId,
        },
        config: {
          method: Method.get,
        },
      }),
    );
  };
}

export function updateVlan(vlanId: any, vlan: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [VLAN_UPDATE_REQUEST, VLAN_UPDATE_SUCCESS, VLAN_UPDATE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/appliance/vlans/${vlanId}`,
        config: {
          method: "PUT",
          body: JSON.stringify(vlan),
        },
        meta: {
          networkId,
          vlanId,
        },
      }),
    );
  };
}

export function deleteVlan(vlanId: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [VLANS_DELETE_REQUEST, VLANS_DELETE_SUCCESS, VLANS_DELETE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/appliance/vlans/${vlanId}`,
        config: {
          method: Method.delete,
        },
        meta: {
          networkId,
          vlanId,
        },
      }),
    );
  };
}

export function createVlan(vlan: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    return dispatch(
      wrapApiActionWithCSRF({
        types: [VLANS_CREATE_REQUEST, VLANS_CREATE_SUCCESS, VLANS_CREATE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/appliance/vlans`,
        config: {
          method: Method.post,
          body: JSON.stringify(vlan),
        },
        meta: {
          networkId,
        },
      }),
    );
  };
}

export function onboardingTimingStart(): AppThunk {
  return (dispatch, getState) => {
    return dispatch({
      type: ONBOARDING_TIMING_START,
      user: currentUserState(getState()),
      onboardingStartTime: Date.now(),
    });
  };
}

export function fetchAllMSPOrgDataByShard(eid: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const organizations = getLoginOrgs(getState());
    const orgIds = organizations?.map(({ id }) => id) ?? [];

    return dispatch(getMSPOrganziationData(eid, orgIds));
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function getMSPOrganziationData(
  eid: string,
  orgIds: string[],
): AppThunk<Promise<ApiResponseAction<any>>> {
  const form = isWeb() ? new URLSearchParams() : new FormData();

  for (const id of orgIds) {
    form.append("org_ids[]", id);
  }

  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          GET_MSP_ORGANIZATION_DATA_REQUEST,
          GET_MSP_ORGANIZATION_DATA_SUCCESS,
          GET_MSP_ORGANIZATION_DATA_FAILURE,
        ],
        endpoint: `/o/${eid}/manage/msp_portal/get_msp_organization_data`,
        config: {
          noHeaderFill: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          method: "POST",
          body: form,
        },
        meta: {
          skipTwoFactorCheck: true,
        },
      }),
    );
}

export function resetLoginState() {
  return {
    type: RESET_LOGIN_STATE,
  };
}

export function resetIdleTimeoutState() {
  return {
    type: RESET_IDLE_TIMEOUT_SESSION_EXPIRED,
  };
}

// Used for sp saml flow
export function fetchUserData(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [FETCH_USER_DATA_REQUEST, FETCH_USER_DATA_SUCCESS, FETCH_USER_DATA_FAILURE],
        endpoint: `/users`,
        config: {
          headers: { "X-Requested-With": "XMLHttpRequest" },
          method: Method.get,
        },
      },
    });
  };
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function fetchNetworkAccessRoles(): AppThunk<Promise<NetworkAccessRoles>> {
  return async (dispatch, getState) => {
    // A Camera Only Admin (CoA) only has access to the camera node group. A Sensor Only Admin only has access to the sensor node group.
    // For other users, any eid should work to get the data we need. Therefore, we try the eid for all three where they exist.
    // After getting the response from all available eids we merge the permissions together.
    const eids = [
      getSensorNodeGroupEid(getState()),
      getCameraNodeGroupEid(getState()),
      getCurrentNetwork(getState())?.eid,
    ].filter((eid) => eid != null);
    if (eids.length === 0) {
      return Promise.reject("Could not find an eid");
    }

    const roles: NetworkAccessRoles = {
      can_write: false,
      is_camera_only_admin: false,
      is_scoped_admin: false,
      is_sensor_only_admin: false,
      monitor_only: false,
    };

    dispatch({ type: GET_NETWORK_ACCESS_ROLES_REQUEST });

    const responses: NetworkAccessRoles[] = await Promise.all<NetworkAccessRoles>(
      eids.map(async (eid) => {
        try {
          return await validatedMerakiRequest(
            NetworkAccessRolesSchema,
            "GET",
            `/n/${eid}/manage/users/access_role`,
            {
              headers: { "X-Requested-With": "XMLHttpRequest" },
            },
          );
        } catch (error) {
          //If there is a issue with trying to get the access_roles we return the default values of all
          //properties being false. Typically this will be a 302 redirection when a user tries to request
          //from a node group they do not have access to.
          return roles;
        }
      }),
    );

    for (const data of responses) {
      for (const key of Object.keys(data) as Array<Extract<keyof NetworkAccessRoles, string>>) {
        //If the current key of the role is true then we keep that value. If it is false then use the value
        //returned from the api.
        roles[key] = roles[key] || data[key];
      }
    }

    dispatch({ type: GET_NETWORK_ACCESS_ROLES_SUCCESS, response: roles });

    return roles;
  };
}
