import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const SupportCaseInfoInteractionSchema = z
  .object({
    type: z.string(),
    preview: z.string(),
    createdDate: z.string(),
    //comments
    createdByName: z.string().optional(),
    commentBody: z.string().optional(),
    //emails
    parentContactEmail: z.string().nullable().optional(),
    textBody: z.string().optional(),
    status: z.string().optional(),
    incoming: z.string().optional(),
    ccAddress: z.string().nullable().optional(),
    toAddress: z.string().optional(),
    fromAddress: z.string().optional(),
    fromName: z.string().optional(),
    subject: z.string().optional(),
  })
  .describe("SupportCaseInfoInteractionSchema");

export const SupportCaseInfoAttachmentSchema = z
  .object({
    type: z.string(),
    Id: z.array(z.string()),
    CreatedDate: z.string(),
    Name: z.string(),
  })
  .describe("SupportCaseInfoAttachmentSchema");

export const SupportCaseInfoSchema = z
  .object({
    interactions: z.array(SupportCaseInfoInteractionSchema),
    attachments: z.array(SupportCaseInfoAttachmentSchema),
  })
  .describe("SupportCaseInfoResponseSchema");

export type SupportCaseInfo = z.infer<typeof SupportCaseInfoSchema>;
export type SupportCaseInteraction = z.infer<typeof SupportCaseInfoInteractionSchema>;

interface SupportCaseInfoRequest {
  encryptedNetworkId?: string;
}

interface SupportCaseInfoRequestParams extends SupportCaseInfoRequest {
  caseNumber?: string;
}

const buildUrl = ({ encryptedNetworkId }: SupportCaseInfoRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/case_info`;
};

const fetchSupportCaseInfo = ({ encryptedNetworkId, caseNumber }: SupportCaseInfoRequestParams) => {
  return request(SupportCaseInfoSchema, "GET", buildUrl({ encryptedNetworkId }), {
    queryParams: { case_number: caseNumber },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSupportCaseInfo = createQuery({
  baseQueryKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  queryFn: (parameters: SupportCaseInfoRequestParams) => fetchSupportCaseInfo(parameters),
  requiredVariables: ["encryptedNetworkId", "caseNumber"],
});
