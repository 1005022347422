import { useMagneticTheme } from "@meraki/magnetic/themes";
import { ScrollView, ScrollViewProps } from "react-native";

import { SpacingStyle } from "../Box/Box";
import { SafeAreaView } from "./SafeAreaView";
import { getScreenStyles } from "./styles";
import { View } from "./View";

export type ScreenProps = Omit<
  ScrollViewProps,
  "style" | "contentContainerStyle" | "indicatorStyle"
> &
  SpacingStyle & {
    addDefaultPadding?: boolean;
  };

export function Screen({ children, addDefaultPadding, ...rest }: ScreenProps) {
  const theme = useMagneticTheme();

  const { style: spacingStyle, ...spacingRest } = getScreenStyles(rest, addDefaultPadding, theme);

  return (
    <ScrollView
      {...spacingRest}
      style={{ flex: 1, backgroundColor: theme.color.default.bg.base }}
      contentContainerStyle={spacingStyle}
      keyboardShouldPersistTaps="handled"
    >
      {children}
    </ScrollView>
  );
}

Screen.SafeAreaView = SafeAreaView;
Screen.View = View;
