export enum SMSTypes {
  primary = "primary",
  secondary = "secondary",
}

export interface LoginOrg {
  eid: string;
  id: string;
  name: string;
  two_factor_auth_enabled: boolean;
}

export interface LoginSecurity {
  enforcePasswordExpiration: boolean;
  passwordExpirationDays: number | null;
  enforceDifferentPasswords: boolean;
  numDifferentPasswords: number | null;
  enforceStrongPasswords: boolean;
  enforceAccountLockout: boolean;
  accountLockoutAttempts: number | null;
  idleTimeoutMinutes: number | null;
  enforceTwoFactorAuth: boolean;
  enforceLoginIpRanges: boolean;
  enforceIdleTimeout: boolean;
  loginIpRanges: string[];
  apiAuthentication: {
    ipRestrictionsForKeys: {
      enabled: boolean;
      ranges: string[];
    };
  };
}

export interface LoginSecurityByOrg {
  [key: string]: LoginSecurity;
}

export interface SpSamlAuthData {
  shardId: string | null;
  auth: string | null;
  orgId: string | null;
}
