import { useSiteToSiteVPNSettings } from "@meraki/shared/api";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { WirelessHealthRequestParams } from "~/actions";
import ConnectionStatsCard from "~/go/components/AssociationStatsCard";
import FailedConnectionsCard from "~/go/components/FailedConnectionsCard";
import LatencyStatsCard from "~/go/components/LatencyStatsCard";
import NetworkEventsCard from "~/go/components/NetworkEventsCard";
import SiteToSiteHealthOverview from "~/go/components/SiteToSiteHealthOverview";
import { currentNetworkState, hasGXDevices, timespanState } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import NetworkUptime from "~/shared/components/NetworkUptime";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import TimePicker from "~/shared/components/TimePicker";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";

const HealthScreen = () => {
  const actions = useActions();

  const timespan = useSelector(timespanState);
  const networkId = useSelector(currentNetworkState);
  const { data: siteToSiteVPN } = useSiteToSiteVPNSettings({ networkId });
  const siteToSiteEnabled = siteToSiteVPN?.mode == "hub" ?? false;

  const hasGX = useAppSelector(hasGXDevices);
  const hasAlphaFeatures = useHasAlphaFeatures();
  const [loadingFailedConnections, setLoadingFailedConnections] = useState(false);
  const [loadingConnectionStats, setLoadingConnectionStats] = useState(false);
  const [loadingLatencyStats, setLoadingLatencyStats] = useState(false);
  const [loadingSiteVpnHealth, setLoadingSiteVpnHealth] = useState(false);

  const loadData = useCallback(async () => {
    if (networkId) {
      const wirelessHealthParams: WirelessHealthRequestParams = { networkId, timespan };
      setLoadingFailedConnections(true);
      setLoadingConnectionStats(true);
      setLoadingLatencyStats(true);
      setLoadingSiteVpnHealth(true);

      try {
        await actions.getNetworkHealth();

        await actions.getFailedConnections(wirelessHealthParams);
        setLoadingFailedConnections(false);
      } catch (error) {
        console.warn(error);
      }

      try {
        await actions.getConnectionStats(wirelessHealthParams);
        setLoadingConnectionStats(false);
      } catch (error) {
        console.warn(error);
      }

      try {
        await actions.getLatencyStats(wirelessHealthParams);
        setLoadingLatencyStats(false);
      } catch (error) {
        console.warn(error);
      }

      try {
        if (siteToSiteEnabled) {
          await actions.fetchVpnStatuses();
          await actions.fetchPeerStatuses();
          await actions.fetchPeerOutageDataWithTimespan(timespan);
        }
        setLoadingSiteVpnHealth(false);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [networkId, timespan, actions, siteToSiteEnabled]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <FullScreenContainerView>
      <RefreshControlScrollView onRefresh={loadData}>
        <TimePicker timespan={timespan} setTimespan={actions.setTimespan} testID="TIME_PICKER" />
        <NetworkUptime key="uptime" />
        <SiteToSiteHealthOverview isLoading={loadingSiteVpnHealth} />
        <LatencyStatsCard isLoading={loadingLatencyStats} />
        <FailedConnectionsCard isLoading={loadingFailedConnections} />
        {hasAlphaFeatures && hasGX && <NetworkEventsCard />}
        <ConnectionStatsCard isLoading={loadingConnectionStats} />
      </RefreshControlScrollView>
    </FullScreenContainerView>
  );
};

export default HealthScreen;
