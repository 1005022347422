import { useCallback } from "react";
import { Linking } from "react-native";

import MkiText, { MkiTextProps } from "~/shared/components/MkiText";

interface WebLinkProps extends MkiTextProps {
  children: React.ReactNode;
  linkUrl: string;
}
const WebLink = (props: WebLinkProps) => {
  const { linkUrl, textStyle, ...rest } = props;
  const defaultTextStyle = textStyle ?? "pressable";

  const handlePress = useCallback(async () => {
    const supported = await Linking.canOpenURL(linkUrl);
    if (supported) {
      await Linking.openURL(linkUrl);
    }
  }, [linkUrl]);

  return <MkiText textStyle={defaultTextStyle} onPress={handlePress} {...rest} />;
};

export default WebLink;
