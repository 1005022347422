import { createSelector } from "reselect";

import { getNetworkNodeGroupData } from "~/selectors/nodeGroups";
import { getCurrentNetwork } from "~/selectors/orgNetworks";
import { NetworkTypesWithId, ProductType } from "~/shared/types/Networks";
import { ReduxSelector } from "~/shared/types/Redux";

export const networkTypesSelector = createSelector(getNetworkNodeGroupData, (nodeGroupData) => {
  const networkTypes: NetworkTypesWithId = {};
  if (!nodeGroupData) {
    return networkTypes;
  }

  for (const [type, ids] of Object.entries(nodeGroupData)) {
    const ngIdWithPrefix = `N_${ids.ngId}`;

    switch (type) {
      case ProductType.systemsManager:
        networkTypes.hasPcc = ngIdWithPrefix;
        break;
      case ProductType.phone:
        networkTypes.hasPhone = ngIdWithPrefix;
        break;
      case ProductType.camera:
        networkTypes.hasCamera = ngIdWithPrefix;
        break;
      case ProductType.switch:
        networkTypes.hasSwitch = ngIdWithPrefix;
        break;
      case ProductType.appliance:
        networkTypes.hasWired = ngIdWithPrefix;
        break;
      case ProductType.wireless:
        networkTypes.hasWireless = ngIdWithPrefix;
        break;
      case ProductType.sensor:
        networkTypes.hasSensor = ngIdWithPrefix;
        break;
    }
  }

  return networkTypes;
});

export const encryptedNetworkIdSelector: ReduxSelector<string> = createSelector(
  getCurrentNetwork,
  (network) => network?.eid,
);
