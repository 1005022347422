import * as errorMonitor from "@meraki/core/errors";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import useSwitchPorts from "~/api/queries/switches/useSwitchPorts";
import SwitchPortEditScreen from "~/enterprise/screens/ports/switchPorts/SwitchPortEditScreen";
import SwitchPortSettingsScreen from "~/go/screens/switchports/SwitchPortSettingsScreen";
import { appSelect } from "~/lib/PlatformUtils";
import { SwitchPortEditScreen as MagneticSwitchPortScreen } from "~/migrationZone/enterprise/devices/screens/SwitchPortEditScreen/SwitchPortEditScreen";
import MkiSpinner from "~/shared/components/MkiSpinner";
import { SharedScreensPropMap } from "~/shared/navigation/Types";

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "SwitchPortEditWrapper">;

const SwitchPortEditWrapper = ({ serialNumber, portIds, onDismiss }: Props) => {
  // temp until public api
  /**
   * The public api returns the id as a string, whereas the expected private one follows
   * the format of has "####_#" with the second part being the port number.
   * The Number.parseInt(id).toString() === id comparison fails for the private string,
   * but would pass for the public api one as the id should be the same as the number equivalent in this case
   */
  const derivedPortIds = portIds.map((id) => {
    const stringId = id.toString(); // will investigate more, but looks like the private api comes back with numbers sometimes
    if (Number.parseInt(id).toString() === stringId) {
      return stringId;
    }

    const parsedId = stringId.match(/\d+_(\d+)/);
    if (!parsedId) {
      errorMonitor.notify(`${stringId} failed to parse in SwitchPortEditWrapper`);
      return "";
    }

    return parsedId[1];
  });

  const switchPortQueries = useSwitchPorts(serialNumber, derivedPortIds);
  const switchPort = switchPortQueries?.[0].data;

  if (switchPortQueries.some((r) => r.isError) || !switchPort) {
    return null;
  }

  if (switchPortQueries.some((r) => r.isLoading)) {
    return <MkiSpinner fillHeight />;
  }

  return appSelect({
    go: (
      <SwitchPortSettingsScreen
        serialNumber={serialNumber}
        portIds={derivedPortIds}
        privatePortIds={portIds}
        switchPort={switchPort}
        onDismiss={onDismiss}
      />
    ),
    enterprise: <SwitchPortEditScreen serialNumber={serialNumber} switchPort={switchPort} />,
  });
};

const MagnetizedSwitchPortEditScreen = withMagneticReplacementAdapter(
  SwitchPortEditWrapper,
  MagneticSwitchPortScreen,
);

export default MagnetizedSwitchPortEditScreen;
