import { isIpReservationConflicting } from "@meraki/shared/ip-address";
import { Address4 } from "ip-address";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import FormItem from "~/go/components/FormItem";
import FormSection from "~/go/components/FormSection";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { IPAddressAssignment } from "~/go/types/NetworksTypes";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { clientName } from "~/lib/ClientUtils";
import { checkForBadReservedIpAddress, isReservedIP } from "~/lib/PortForwardingUtils";
import { clientsState } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import { CancelButton, TextAddButton } from "~/shared/navigation/Buttons";
import ClientListRow, { CLIENT_ROW_SUBTITLE_FORMATS } from "~/shared/rows/ClientListRow";
import { Client } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { SettingsStackProps } from "../navigation/Types";

type ReduxProps = {
  client: Client;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "DeviceIpAssignment"> &
  ReduxProps &
  BasicActions &
  WithConnectedClientsProps;

interface DeviceIpAssignmentScreenState {
  selectedIpAddress: IPAddressAssignment;
  reservedIpAddress: string;
}

export class DeviceIpAssignmentScreen extends PureComponent<Props, DeviceIpAssignmentScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIpAddress: IPAddressAssignment.reserved,
      reservedIpAddress: "",
    };

    this.setNavOptions(true);
  }

  componentDidUpdate() {
    const { selectedIpAddress, reservedIpAddress } = this.state;
    const isSaveDisabled =
      isReservedIP(selectedIpAddress) && Address4.isValid(reservedIpAddress) !== true;

    this.setNavOptions(isSaveDisabled);
  }

  setNavOptions(isSaveDisabled: boolean) {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => (
        <TextAddButton
          onPress={() => {
            const { client, gxVlan, onAddClient } = this.props;
            const { selectedIpAddress, reservedIpAddress } = this.state;

            if (checkForBadReservedIpAddress(selectedIpAddress, reservedIpAddress)) {
              showAlert(I18n.t("ERROR"), I18n.t("DEVICE_IP_ASSIGNMENT.ENTER_RESERVED_IP_ERROR"));
              return;
            }

            if (isReservedIP(selectedIpAddress)) {
              const ipError = isIpReservationConflicting(
                reservedIpAddress,
                gxVlan?.subnet,
                gxVlan?.applianceIp,
              );
              if (ipError) {
                showAlert(I18n.t("ERROR"), ipError);
                return;
              }
            }

            const ip = (reservedIpAddress || client.ip) ?? "";
            if (ip) {
              onAddClient(ip, {
                manualIpReservation: null,
                reservedClientDetails: reservedIpAddress ? client : null,
              });
            }
            navigation.pop(2);
          }}
          disabled={isSaveDisabled}
        />
      ),
    });
  }

  updateRadioSelection = (newSelectedIpAddress: IPAddressAssignment) => {
    this.setState({ selectedIpAddress: newSelectedIpAddress });
  };

  renderReserveIpAddressInput = () => {
    const { gxVlan } = this.props;
    const { selectedIpAddress } = this.state;

    return (
      <IPAddressTextInput
        cidrAddress={gxVlan?.subnet}
        onAddressChange={(address: string) => this.setState({ reservedIpAddress: address })}
        editable={isReservedIP(selectedIpAddress)}
      />
    );
  };

  render() {
    const { client, isClientConnected } = this.props;
    const { selectedIpAddress } = this.state;
    const radioOptions = [
      {
        value: IPAddressAssignment.reserved,
        label: I18n.t("DEVICE_IP_ASSIGNMENT.RESERVED_IP_RADIO"),
        textInputComponent: this.renderReserveIpAddressInput,
      },
      {
        value: IPAddressAssignment.dynamic,
        label: I18n.t("DEVICE_IP_ASSIGNMENT.DYNAMIC_IP_RADIO"),
      },
    ];

    return (
      <FullScreenContainerView withScroll screenStyles={[styles.scrollView]}>
        <ClientListRow
          isOnline={isClientConnected(client)}
          subtitleFormat={CLIENT_ROW_SUBTITLE_FORMATS.ip}
          screenStyles={styles.clientListRow}
          ip={client.ip}
        >
          {clientName(client)}
        </ClientListRow>

        <FormSection
          title={I18n.t("DEVICE_IP_ASSIGNMENT.IP_ADDRESS")}
          description={I18n.t("DEVICE_IP_ASSIGNMENT.DESCRIPTION")}
        >
          <FormItem>
            <RadioSelectionList
              onSelect={this.updateRadioSelection}
              options={radioOptions}
              selectedValue={selectedIpAddress}
            />
          </FormItem>
        </FormSection>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  scrollView: {
    paddingHorizontal: SPACING.default,
  },
  clientListRow: {
    paddingHorizontal: 0,
  },
});

function mapStateToProps(
  state: RootState,
  props: SettingsStackProps["DeviceIpAssignment"],
): ReduxProps {
  return {
    client: clientsState(state)[props.id],
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withConnectedClients,
)(DeviceIpAssignmentScreen);
