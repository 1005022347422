import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";
import { SsidSchema } from "../../ssid/useSsid";
import { VlanPayloadSchema } from "../../vlan/useVlan";

export const VlanExportSchema = VlanPayloadSchema.extend({
  id: z.number(),
  name: z.string(),
});

export const ExportTemplateResponseSchema = z.object({
  ssids: z.array(SsidSchema).optional(),
  vlans: z.array(VlanExportSchema).optional(),
});

export type VlanExport = z.infer<typeof VlanExportSchema>;
export type ExportTemplateResponse = z.infer<typeof ExportTemplateResponseSchema>;

export enum ExportTemplateType {
  ssid = "ssid",
  vlan = "vlan",
  all = "all",
}
export interface ExportTemplateRequest {
  networkId?: string;
  type?: ExportTemplateType;
  cleanNames?: boolean;
  ssidId?: string;
  vlanId?: string;
  keepDhcp?: boolean;
}

function buildUrl() {
  return `/go/export_template`;
}

export function fetchExportTemplate(
  variables: ExportTemplateRequest,
): Promise<ExportTemplateResponse> {
  return request(ExportTemplateResponseSchema, "GET", buildUrl(), {
    queryParams: { ...variables },
  });
}

export const useExportTemplate = createQuery<ExportTemplateRequest, ExportTemplateResponse>({
  baseQueryKey: buildUrl(),
  queryFn: (variables) => {
    return fetchExportTemplate(variables);
  },
  staleTime: Infinity,
});
