import { config } from "@meraki/core/dev-config";
import { GET_NFOS_FAILURE, GET_NFOS_REQUEST, GET_NFOS_SUCCESS } from "@meraki/shared/redux";
import * as Sentry from "@sentry/react-native";

import NFOs from "~/constants/NFOs";
import { DETOX } from "~/env";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { getCurrentOrganization, getNFOs } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function fetchOrgNFOs(): AppThunk<Promise<void | ApiResponseAction<any>>> {
  return async (dispatch, getState) => {
    if (DETOX || config.useEphemeral) {
      return Promise.resolve();
    }

    const organizationId = getCurrentOrganization(getState());
    const action = await dispatch({
      [CALL_API]: {
        types: [GET_NFOS_REQUEST, GET_NFOS_SUCCESS, GET_NFOS_FAILURE],
        endpoint: `/testbed/api/v0/organizations/${organizationId}/nfos/org_nfos`,
        config: {
          method: Method.get,
        },
        meta: { organizationId },
      },
    });

    setAlphaNfoSentryTag(getNFOs(getState()));

    return action;
  };
}

function setAlphaNfoSentryTag(nfos: Set<NFOs>): void {
  const hasMobileAlphaFeatures = nfos.has(NFOs.hasMobileAlphaFeatures);
  Sentry.setTag("alpha_nfo", hasMobileAlphaFeatures ? "true" : "false");
}
