import { nestedValueExists } from "~/lib/objectHelper";
import { defaultOptions, RowOptions } from "~/shared/components/NestedTableRenderRow";

// Returns the entry at the given position, or optionally retrieves
// sub entries of the given position.
// The return value is a pointer to the requested data
export function getNestedData(data: any, position: any, entriesOf = false) {
  // allow nestedValueExists to traverse the data structure
  // transforms: [1, 2, 3]
  // into: ["next", "entries", 1, "next", "entries", 2, "next", "entries", 3]
  let adjustedPosition = position
    .map((pos: any) => ["next", "entries", pos])
    .reduce((acc: any, cur: any) => acc.concat(cur), []);
  // get all the entries at the specified position
  if (entriesOf) {
    adjustedPosition = adjustedPosition.concat(["next", "entries"]);
  }
  return nestedValueExists(data, adjustedPosition, null);
}

// deselect all nodes in data
function deselectAllNodes(data: any) {
  if ("next" in data) {
    data.next.entries = data.next.entries.map((d: any) => deselectAllNodes(d));
  }
  if (!data.selected) {
    return data;
  }

  return { ...data, selected: false };
}

// filter out all unselected data in the tree
export function filterSelectedData(data: any) {
  const filteredData = data
    .filter((d: any) => d.selected)
    .map((d: any) => {
      if ("next" in d) {
        const filteredEntries = filterSelectedData(d.next.entries);
        return {
          ...d,
          next: {
            ...d.next,
            entries: filteredEntries,
          },
        };
      }
      return d;
    });
  return filteredData;
}

/*
 * When a leaf node (!("next" in entry)) is selected, all its upstream nodes are selected
 * as well. If any of the upstream nodes are part of a "singleChoice" set of entries, then we
 * want to recursively deselect all the other nodes in each of those trees.
 * Example:
 * ( ` means selected )
 * Initial State:
 *          _
 *       /     \
 *      a      `b    <- Single choice entries
 *     / \     / \
 *    c   d  `e   f
 *   /       / \
 *  g      `h   i
 *
 * Next state: d is selected
 *          _
 *       /     \
 *     `a       b    <- Single choice entries
 *     / \     / \
 *    c  `d   e   f
 *   /       / \
 *  g       h   i
 */
export function setSelected(
  tree: any,
  address: any,
  depth: any,
  options: RowOptions<any> = defaultOptions,
) {
  const d = depth || 0;
  const thisAddress = address.slice(0, d);
  const thisEntry = getNestedData(tree, thisAddress);

  // don't set root node as "selected"
  const update = d === 0 ? {} : { selected: options.toggle ? !thisEntry.selected : true };
  const newEntry = { ...thisEntry, ...update };

  if (d < address.length && newEntry.next) {
    const children = newEntry.next.entries;
    const selectedChildEntry = setSelected(tree, address, d + 1, options);
    let newChildren;

    if (newEntry.next.singleChoice) {
      newChildren = children.map((child: any) => {
        if (child.value === selectedChildEntry.value) {
          return selectedChildEntry;
        }
        return deselectAllNodes(child);
      });
    } else {
      newChildren = children
        .slice(0, address[d])
        .concat([selectedChildEntry], children.slice(address[d] + 1));
    }

    newEntry.next = { ...newEntry.next, entries: newChildren };
  }

  return newEntry;
}
