import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { SECONDS_IN_A_DAY } from "../constants";
import { RootState } from "../reducers";

export const ALL_SSIDS = -1;

const getPreferences = (state: RootState) => state?.preferences;

const getCurrentUser = (state: RootState) => state?.preferences?.currentUser ?? "";
export const useCurrentUserEmail = () => useSelector(getCurrentUser);

export const getTimespan = createSelector(getPreferences, (substate) => substate?.timespan);
export const useGlobalTimespan = () => useSelector(getTimespan) ?? SECONDS_IN_A_DAY;

export const getHomeSSID = createSelector(
  getPreferences,
  getCurrentUser,
  (preferences, currentUser) => preferences.users?.[currentUser]?.homeSSID,
);

export const useHomeSSID = () => useSelector(getHomeSSID) ?? ALL_SSIDS;
