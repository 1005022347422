import { StatusType } from "~/shared/constants/Status";

// Note: while these types and constants are used by both SplashPageSetup and
// SplashCustomURLSetup, only SplashCustomURLSetup uses the interface since it's
// a .js file. Should we ever port it over to being a .tsx file, it would be optimal
// for it to use these interfaces and types.

export enum SPLASH_FEATURE_ID {
  welcomeMessage = "welcomeMessage",
  redirectURLEnabled = "redirectURLEnabled",
  redirectURL = "redirectURL",
  logoURL = "logoURL",
  logoMD5 = "logoMD5",
  logoExtension = "logoExtension",
  timeout = "timeout",
  customURL = "adminSplashURL",
  walledGardenEnabled = "walledGardenEnabled",
  walledGardenRanges = "walledGardenRanges",
}

export interface WalledGardenRange {
  range: string;
  isValid: boolean;
}

export interface SplashCustomStates {
  [SPLASH_FEATURE_ID.welcomeMessage]?: string;
  [SPLASH_FEATURE_ID.redirectURL]?: string;
  [SPLASH_FEATURE_ID.redirectURLEnabled]?: boolean;
  [SPLASH_FEATURE_ID.customURL]?: string;
  [SPLASH_FEATURE_ID.timeout]: number;
  [SPLASH_FEATURE_ID.walledGardenEnabled]?: boolean;
  [SPLASH_FEATURE_ID.walledGardenRanges]?: WalledGardenRange[];
  [SPLASH_FEATURE_ID.logoURL]?: string;
  [SPLASH_FEATURE_ID.logoMD5]?: string | boolean;
  [SPLASH_FEATURE_ID.logoExtension]?: string | boolean;
}

export interface SplashStagedStates {
  [SPLASH_FEATURE_ID.welcomeMessage]?: string;
  [SPLASH_FEATURE_ID.redirectURL]?: string;
  [SPLASH_FEATURE_ID.customURL]?: string;
  [SPLASH_FEATURE_ID.logoURL]?: string;
  [SPLASH_FEATURE_ID.walledGardenRanges]?: WalledGardenRange[];
}

export interface SplashShowModalStates {
  [SPLASH_FEATURE_ID.welcomeMessage]?: boolean;
  [SPLASH_FEATURE_ID.redirectURL]?: boolean;
  [SPLASH_FEATURE_ID.customURL]?: boolean;
  [SPLASH_FEATURE_ID.timeout]?: boolean;
}

export interface SplashStates {
  showModalStates: SplashShowModalStates;
  stagedStates: SplashStagedStates;
  customStates: SplashCustomStates;
  redirectURLAlert?: {
    message: string;
    status: StatusType;
  };
}
