import { I18n } from "@meraki/core/i18n";
import { launchUrl } from "@meraki/shared/links";
import { Platform } from "react-native";

export const documentationErrorMessage = I18n.t("DOCS.ERROR.GO");

export const appStoreUrl = () => launchUrl("https://web.meraki-go.com/go_app");

export const communityUrl = () =>
  launchUrl("https://community.meraki-go.com", I18n.t("COMMUNITY_ERROR"));

export const communityQueryUrl = (query: string) =>
  launchUrl(
    `${I18n.t("COMMUNITY_QUERY_URL")}?${
      // @ts-expect-error TS(2554) FIXME: Expected 2-3 arguments, but got 1.
      formatURLQueryParams({
        advanced: false,
        allow_punctuation: false,
        filter: "location",
        location: "category:go",
        q: query,
      })
    }`,
    I18n.t("COMMUNITY_ERROR"),
  );

export const documentationQueryUrl = (query: string) =>
  launchUrl(
    `${I18n.t("DOCS.URL.ENTERPRISE")}/Special:Search?${
      // @ts-expect-error TS(2554) FIXME: Expected 2-3 arguments, but got 1.
      formatURLQueryParams({
        path: "Go",
        q: query,
      })
    }`,
    documentationErrorMessage,
  );

export const communityResultUrl = (communityUrl: string) =>
  launchUrl(communityUrl, I18n.t("COMMUNITY_ERROR"));

export const topologyUrl = () => launchUrl(I18n.t("TOPOLOGY_URL"), I18n.t("TOPOLOGY_ERROR"));

export const pppoeUrl = () => launchUrl(I18n.t("PPPOE_URL"), I18n.t("PPPOE_ERROR"));

export const walledGardenDocumentationURL = () =>
  launchUrl(
    I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.DOCUMENTATION_LINK"),
    I18n.t("DOCS_ERROR"),
  );

export const showWhatsNewDoc = (featureKey: string) => () =>
  launchUrl(I18n.t(`WHATS_NEW.${featureKey}.DOC`), documentationErrorMessage);

export const launchSupportEmailUrl = ({
  showErrorWithContact,
}: {
  showErrorWithContact: boolean;
}) => {
  launchUrl(
    "mailto:help@meraki-go.com?subject=Meraki Go MFA Access",
    showErrorWithContact
      ? I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.BODY")
      : I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.MAIL_ERROR"),
    I18n.t("TWO_FACTOR_AUTH.LOST_TFA_MODAL.MAIL_ERROR_TITLE"),
  );
};

export const launchAmazonUrl = () => {
  const appName = Platform.OS === "android" ? "app2" : "app1";
  const amazonUrl = `https://www.amazon.com/stores/page/15010822-1AA7-406A-BABE-D37F4B72FA2D?channel=${appName}`;
  launchUrl(amazonUrl);
};

export const launchRouterFirewallPlusUrl = () => {
  launchUrl("https://www.meraki-go.com/product/router-firewall-plus/");
};

export const launchUmbrellaSecurityUrl = () => {
  launchUrl("https://www.meraki-go.com/product/umbrella-security-license/");
};

export const launchOpenSourceUrl = () =>
  launchUrl("https://web.meraki-go.com/licenses", documentationErrorMessage);

export const showContextHelpDoc = (contextKey: string) => () =>
  launchUrl(I18n.t(`CONTEXT_HELP.${contextKey}.DOC`), documentationErrorMessage);

export const webApp = "https://web.meraki-go.com";
export const launchGoWebApp = () => {
  launchUrl(webApp, I18n.t("WEBAPP.ALERT.ERROR", { webApp }));
};

export const launchMLabPolicy = () => {
  launchUrl("https://www.measurementlab.net/privacy/");
};

export const launchGoSiteCompanyManagementDoc = () =>
  launchUrl(I18n.t("DELETE_LOCATION.HELP_LINK_URL"), documentationErrorMessage);

export const launchIOSBeta = () => {
  launchUrl("https://testflight.apple.com/join/LdobmsN9");
};

export const launchAndroidBeta = () => {
  launchUrl("https://play.google.com/apps/testing/com.meraki.go");
};

export const documentationUrl = () => launchUrl(I18n.t("DOCS.URL.GO"), documentationErrorMessage);
