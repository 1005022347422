import HCaptcha from "@hcaptcha/react-hcaptcha";
import { captchaSiteKey } from "@meraki/shared/auth";
import { useCallback, useState } from "react";

import RoundedButton, { RoundedButtonType } from "~/enterprise/components/RoundedButton";
import { getCurrentCluster } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const HCAPTCHA_TOKEN_EXPIRATION_TIME = 120000;

interface CaptchaButtonProps {
  onCaptchaComplete: (token?: string) => void;
  buttonType?: RoundedButtonType;
  buttonText: string;
  disabled?: boolean;
  testID?: string;
}

const CaptchaButton = ({
  onCaptchaComplete,
  buttonType,
  buttonText,
  disabled,
  testID,
}: CaptchaButtonProps) => {
  const currentCluster = useAppSelector(getCurrentCluster);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [token, setToken] = useState("");

  const resetCaptcha = useCallback(() => {
    setShowCaptcha(false);
    setToken("");
    setTimeout(() => setShowCaptcha(true), 1000);
  }, [setShowCaptcha]);

  const onVerify = useCallback(
    (token: any) => {
      if (token != null) {
        if (["cancel", "error", "expired"].includes(token)) {
          resetCaptcha();
        } else {
          setToken(token);
          setTimeout(() => {
            // TODO: expired token
          }, HCAPTCHA_TOKEN_EXPIRATION_TIME);
        }
      }
    },
    [resetCaptcha],
  );

  if (!showCaptcha) {
    return null;
  }
  return (
    <>
      <HCaptcha sitekey={captchaSiteKey(currentCluster) ?? ""} onVerify={onVerify} />
      <RoundedButton
        buttonType={buttonType ?? RoundedButtonType.tertiary}
        disabled={disabled || !token}
        onPress={() => onCaptchaComplete(token)}
        testID={testID}
      >
        {buttonText}
      </RoundedButton>
    </>
  );
};

export default CaptchaButton;
