import { LinkType, NodeType, TopologyNodeKeys } from "@meraki/shared/api";
import { isEmpty } from "lodash";

import { LinkById, NodeById, PortMapById } from "~/api/queries/topology/useFormattedTopology";
import I18n from "~/i18n/i18n";
import { ProductType } from "~/shared/types/Networks";

export function extractRootIdsAndNormalizeNodes(nodes: NodeType[]) {
  const rootIds = [] as string[];

  const nodesById = nodes.reduce((nodesByIdTemp, node) => {
    if (node.root) {
      rootIds.push(node.derivedId);
    }

    return {
      ...nodesByIdTemp,
      [node.derivedId]: { ...node },
    };
  }, {} as NodeById);

  return { rootIds, nodesById };
}

export function normalizeLinkData(links: LinkType[], rootIds: string[]) {
  if (rootIds == null || rootIds.length === 0) {
    return null;
  }

  let linksToBeNormalized = [...links];
  const normalizedLinks: LinkById[] = [];
  let nextLayer = [...rootIds];

  while (linksToBeNormalized.length > 0) {
    const currentLayer = [...nextLayer];
    nextLayer = [];
    const layerLinks: LinkById = {};

    currentLayer.forEach((nodeId) => {
      const [connectedNodeIds, others] = linksToBeNormalized.reduce(
        (result: [string[], LinkType[]], link) => {
          let isConnection = false;
          let otherEnd = "";

          link.ends.forEach(({ node }) => {
            if (node.derivedId === nodeId) {
              isConnection = true;
            } else {
              otherEnd = node.derivedId;
            }
          });

          if (isConnection) {
            if (otherEnd) {
              result[0].push(otherEnd);
            }
          } else {
            result[1].push(link);
          }

          return result;
        },
        [[], []],
      );

      if (connectedNodeIds.length > 0) {
        layerLinks[nodeId] = connectedNodeIds;
        nextLayer = [...nextLayer, ...connectedNodeIds];
      }

      linksToBeNormalized = [...others];
    });

    normalizedLinks.push(layerLinks);
  }

  if (normalizedLinks.length == 0) {
    return null;
  }

  return normalizedLinks;
}

export function getNodeHierarchyByLinks(normalizedLinks?: LinkById[], rootIds?: string[]) {
  const nodeHierarchy: string[][] = [];

  if (
    rootIds == null ||
    rootIds.length === 0 ||
    normalizedLinks == null ||
    normalizedLinks.length === 0
  ) {
    return nodeHierarchy;
  }

  let currentLayer = [...rootIds];
  nodeHierarchy.push(currentLayer);

  normalizedLinks.forEach((layer) => {
    const nextLayer = currentLayer.reduce((result: string[], nodeId) => {
      if (layer[nodeId] == null) {
        return result;
      } else {
        return [...result, ...layer[nodeId]];
      }
    }, []);

    nodeHierarchy.push(nextLayer);
    currentLayer = nextLayer;
  });

  return nodeHierarchy;
}

export function mapPorts(nodes: NodeType[], links: LinkType[]) {
  const uplinkList: PortMapById = {};
  const macToDerivedIdmap: any = nodes.reduce(
    (result, { mac, derivedId }) => ({ ...result, [mac]: derivedId }),
    {},
  );

  // get all uplink derivedIds from device type nodes
  nodes.forEach(({ device, derivedId, type }) => {
    if (
      type === TopologyNodeKeys.device &&
      device != null &&
      device.productType === ProductType.switch &&
      !isEmpty(device?.uplinks)
    ) {
      // create a map of derived ID => port ID for the node
      const parentPortMapById = links.reduce((portMap: any, { ends }) => {
        const [isConnection, otherEnd] = ends.reduce(
          (result, end) => {
            if (end.node.derivedId === derivedId) {
              result[0] = true;
            } else if (result[1] !== end) {
              result[1] = end;
            }
            return result;
          },
          [false, ends[0]],
        );

        if (isConnection) {
          const { node, discovered } = otherEnd;
          const portId = discovered?.lldp?.portId || discovered?.cdp?.portId;
          const portNumber = portId?.match(/\d+/)?.[0]; // get number from "Port ##"

          if (portNumber != null) {
            portMap[node.derivedId] = parseInt(portNumber);
          }
        }

        return portMap;
      }, {});

      const uplinkPorts: number[] = [];

      device.uplinks.forEach((uplink) => {
        const parentMac = uplink.ipv4.gateway.mac;
        const parentId = parentMac ? macToDerivedIdmap[parentMac] : null;
        const portId = parentPortMapById[parentId];

        if (portId != null) {
          uplinkPorts.push(portId);
        }
      });

      uplinkList[derivedId] = uplinkPorts;
    }
  });

  return uplinkList;
}

export interface nodeInfoType {
  name: string;
  mac: string;
  serial?: string;
  connectedDevices?: number;
  productType?: string;
  uplinkIpAddress?: string;
}

export function getNodeRenderInfo(node: NodeType) {
  const { derivedId, device, discovered, mac, stack, type } = node;
  const nodeInfo: nodeInfoType = {
    name: I18n.t("UNKNOWN"),
    mac,
  };

  switch (type) {
    case TopologyNodeKeys.unknown:
      nodeInfo.name = derivedId;
      break;
    case TopologyNodeKeys.discovered:
      if (discovered?.cdp != null) {
        nodeInfo.name = discovered.cdp.platform;
      }

      if (discovered?.lldp != null) {
        nodeInfo.name = discovered.lldp.systemName;
      }
      break;
    case TopologyNodeKeys.stack:
      if (stack != null) {
        nodeInfo.name = stack.name || stack.id;
      }
      break;
    case TopologyNodeKeys.device:
      if (device != null) {
        nodeInfo.name = device.name || device.model;
        nodeInfo.serial = device.serial;
        nodeInfo.connectedDevices = device.clients.counts.total;
        nodeInfo.productType = device.productType;
        nodeInfo.uplinkIpAddress = device.uplinks?.[0]?.ipv4?.gateway?.address ?? undefined;
      }
      break;
  }

  return nodeInfo;
}

export function getAutoclaimableSerials(nodes: NodeById) {
  const serials: string[] = [];

  for (const nodeMac in nodes) {
    const { discovered } = nodes[nodeMac];

    if (discovered?.lldp?.serial) {
      serials.push(discovered.lldp.serial);
    } else if (discovered?.cdp?.serial) {
      serials.push(discovered.cdp.serial);
    }
  }

  return serials;
}
