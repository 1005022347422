import { I18n } from "@meraki/core/i18n";
import { cloneDeep, isEqual, uniq } from "lodash";

import Organization from "~/api/models/Organization";
import { errorAlerts, SPLASH_F, SPLASH_PAGE_ID, SPLASH_TIMEOUT_OPTIONS } from "~/constants/Splash";
import { shardURL } from "~/env";
import { PickerModalItems } from "~/go/components/PickerModal";
import type { SplashCustomURLSetupScreenState } from "~/go/screens/SplashCustomURLSetupScreen";
import type { SplashWalledGardenState } from "~/go/screens/SplashWalledGardenScreen";
import {
  SPLASH_FEATURE_ID,
  SplashCustomStates,
  SplashStagedStates,
  SplashStates,
  WalledGardenRange,
} from "~/go/types/SplashTypes";
import { isIPValid } from "~/lib/IPAddressUtils";
import { stringIsURL, stringToList } from "~/lib/stringHelper";
import { SplashMethods, SplashSettings } from "~/shared/types/SplashSettings";

// TODO: remove "f" handling
// TODO: split this file into 2 different files (getting too long)

// SPLASH CONFIG GENERATORS

// TODO: remove function. This is used just to reduce overly verbose "f" handling. Checks if the
// logo is an "empty" value. Can take in extension or md5.
export const isLogoEmpty = (logoSection: boolean | string | undefined) =>
  logoSection === undefined ||
  logoSection === false ||
  logoSection === SPLASH_F ||
  logoSection === "";

// SAVE DETECTION TOOLS

export const isChangedConfigure = (
  splashSettings: SplashSettings,
  stagedStates: SplashStagedStates,
  customStates: SplashCustomStates,
  splashPage: string,
) => {
  const isChangedRedirectURL = !isEqual(
    splashSettings.redirectUrl,
    customStates[SPLASH_FEATURE_ID.redirectURL],
  );
  const isChangedRedirectURLEnabled =
    splashSettings.useRedirectUrl !== customStates[SPLASH_FEATURE_ID.redirectURLEnabled];
  const isChangedFrequency = !isEqual(
    splashSettings.splashTimeout * 60,
    customStates[SPLASH_FEATURE_ID.timeout],
  );
  const isChangedDefault =
    isChangedRedirectURL || isChangedRedirectURLEnabled || isChangedFrequency;

  // only if custom URL
  if (splashPage === SPLASH_PAGE_ID.customURL) {
    const isChangedWalledGardenRanges = !isEqual(
      stagedStates[SPLASH_FEATURE_ID.walledGardenRanges],
      customStates[SPLASH_FEATURE_ID.walledGardenRanges],
    );

    return isChangedDefault || isChangedWalledGardenRanges;
  }
  return isChangedDefault;
};

export const isChangedCustomize = (
  splashSettings: SplashSettings,
  stagedStates: SplashStagedStates,
  customStates: SplashCustomStates,
) => {
  const isChangedSubheader = !isEqual(
    splashSettings.welcomeMessage,
    customStates[SPLASH_FEATURE_ID.welcomeMessage],
  );
  const isChangedLogoURL = !isEqual(
    stagedStates[SPLASH_FEATURE_ID.logoURL],
    customStates[SPLASH_FEATURE_ID.logoURL],
  );

  return isChangedSubheader || isChangedLogoURL;
};

export const isChangedClickthrough = (
  splashSettings: SplashSettings,
  stagedStates: SplashStagedStates,
  customStates: SplashCustomStates,
) =>
  isChangedConfigure(splashSettings, stagedStates, customStates, SPLASH_PAGE_ID.clickThrough) ||
  isChangedCustomize(splashSettings, stagedStates, customStates) ||
  getSplashPage(splashSettings) !== SPLASH_PAGE_ID.clickThrough;

export const isChangedCustomURL = (
  splashSettings: SplashSettings,
  stagedStates: SplashStagedStates,
  customStates: SplashCustomStates,
) =>
  isChangedConfigure(splashSettings, stagedStates, customStates, SPLASH_PAGE_ID.customURL) ||
  splashSettings.splashUrl !== customStates[SPLASH_FEATURE_ID.customURL] ||
  getSplashPage(splashSettings) !== SPLASH_PAGE_ID.customURL;

// SPLASH HELPERS

export const getSplashPage = (splashSettings: SplashSettings) => {
  if (splashSettings?.splashMethod === SplashMethods.none) {
    return "none";
  }
  if (splashSettings?.splashMethod === SplashMethods.clickThrough) {
    return splashSettings.useSplashUrl ? "customURL" : "clickThrough";
  }
  return undefined;
};

export const logoS3URL = (
  splashSettings: SplashSettings,
  shardId: number,
  organization: Partial<Organization>,
  encryptedNetworkId: string,
) => {
  const md5 = splashSettings?.splashLogo?.md5;
  const extension = splashSettings?.splashLogo?.extension;

  if (isLogoEmpty(md5) || isLogoEmpty(extension)) {
    return "";
  } else {
    const baseUrl = shardURL(shardId);
    const { name } = organization;
    return `${baseUrl}/${name}/n/${encryptedNetworkId}/public/image/${md5}?extension=${extension}`;
  }
};

// isShorthand(boolean): If true, will use shorthand table. If false, uses longhand table.
// A bad request will return undefined. String otherwise.
export const convertFrequency = (timeout: number, isShorthand = true) => {
  if (SPLASH_TIMEOUT_OPTIONS.includes(timeout)) {
    if (isShorthand) {
      const TRANSLATIONS: Record<number, string> = {
        1800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.1800"),
        3600: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.3600"),
        7200: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.7200"),
        14400: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.14400"),
        28800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.28800"),
        86400: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.86400"),
        259200: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.259200"),
        604800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.604800"),
        2592000: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.2592000"),
        7776000: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_SHORTHAND.7776000"),
      };

      return TRANSLATIONS[timeout];
    } else {
      const TRANSLATIONS: Record<number, string> = {
        1800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.1800"),
        3600: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.3600"),
        7200: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.7200"),
        14400: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.14400"),
        28800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.28800"),
        86400: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.86400"),
        259200: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.259200"),
        604800: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.604800"),
        2592000: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.2592000"),
        7776000: I18n.t("SPLASH_TIMEOUT_OPTIONS.SECONDS_LONGHAND.7776000"),
      };

      return TRANSLATIONS[timeout];
    }
  }

  return;
};

export const getTimeoutOptions = (): PickerModalItems[] => {
  return SPLASH_TIMEOUT_OPTIONS.map((timeout) => ({
    label: convertFrequency(timeout, false) ?? "",
    value: timeout,
  }));
};

// REACT STATE MANAGEMENT FUNCTIONS

// note: updateCustomStates, updateShowModalStates, and updateStagedStates could all be
// combined into a single function, but they're currently split up to make it easier to read
// at glance at the component level.
export const updateCustomStates = (
  field: SPLASH_FEATURE_ID,
  content: string | number | boolean | object,
) =>
  function update(state: any) {
    const { customStates } = state;

    const newStates = { ...customStates };
    newStates[field] = content;
    return { customStates: newStates };
  };

export const updateStagedStates = (field: SPLASH_FEATURE_ID, content: string | number | boolean) =>
  function update(state: any) {
    const { stagedStates } = state;

    const newStates = { ...stagedStates };
    newStates[field] = content;
    return { stagedStates: newStates };
  };

export const updateShowModalStates = (field: SPLASH_FEATURE_ID, bool: boolean) =>
  function update(state: any) {
    const { showModalStates } = state;

    const newStates = { ...showModalStates };
    newStates[field] = bool;
    return { showModalStates: newStates };
  };

export const exitModal = (field: SPLASH_FEATURE_ID) => updateShowModalStates(field, false);

export const openModal = (field: SPLASH_FEATURE_ID) => updateShowModalStates(field, true);

export const applyStagedStates = (field: SPLASH_FEATURE_ID, closeModal = true) =>
  function update(state: any) {
    const { customStates, stagedStates, showModalStates } = state;

    const newCustomStates = { ...customStates };
    const newShowModalStates = { ...showModalStates };
    newCustomStates[field] = stagedStates[field];
    newShowModalStates[field] = !closeModal;
    return {
      customStates: newCustomStates,
      showModalStates: newShowModalStates,
    };
  };

export const validateRedirectURLOnSubmit = (state: SplashStates): SplashStates => {
  const result = cloneDeep(state);

  if (result.stagedStates[SPLASH_FEATURE_ID.redirectURL] === "") {
    // this now means user does not want to redirect, so clear out the URL and turn it off
    result.customStates[SPLASH_FEATURE_ID.redirectURL] = "";
    result.customStates[SPLASH_FEATURE_ID.redirectURLEnabled] = false;
  } else if (!stringIsURL(result.stagedStates[SPLASH_FEATURE_ID.redirectURL]!)) {
    // it's a bad URL. Keep the modal up and show an error
    result.redirectURLAlert = errorAlerts().incorrectURLError;
    return result;
  } else {
    // it's not empty and a valid URL, set it and enable the redirect
    result.customStates[SPLASH_FEATURE_ID.redirectURL] =
      result.stagedStates[SPLASH_FEATURE_ID.redirectURL];
    result.customStates[SPLASH_FEATURE_ID.redirectURLEnabled] = true;
  }

  // hide the modal, clear out any alert
  result.showModalStates[SPLASH_FEATURE_ID.redirectURL] = false;
  result.redirectURLAlert = undefined;
  return result;
};

export const validateCustomURLOnSubmit = (
  state: SplashCustomURLSetupScreenState,
): SplashCustomURLSetupScreenState => {
  const result = cloneDeep(state);

  if (result.stagedStates[SPLASH_FEATURE_ID.customURL] === "") {
    result.customURLAlert = errorAlerts().emptyCustomURLError;
    return result;
  }
  //@ts-ignore
  if (!stringIsURL(result.stagedStates[SPLASH_FEATURE_ID.customURL])) {
    result.customURLAlert = errorAlerts().incorrectURLError;
    return result;
  }

  result.customStates[SPLASH_FEATURE_ID.customURL] =
    result.stagedStates[SPLASH_FEATURE_ID.customURL];
  result.showModalStates[SPLASH_FEATURE_ID.customURL] = false;

  result.customURLAlert = undefined;
  result.isCustomURLValid = true;
  return result;
};

export const validateWalledGardenRange = (range: string) => {
  // This process will replicate what's done on Dashboard right now:
  // 1. categorize the range: range.contains("/") ? ip : domain/wildcard
  // 2. if an ip, try to generate an object using a 3rd party IP-checking library. If it
  //    fails, then it's not a valid IP. otherwise, it's valid.
  // 3. if a domain, no error
  // code can be found scattered around in models/ssid.rb by looking for key-word
  // walled_garden_ranges.
  const trimmedRange = range.trim();

  // not empty
  if (trimmedRange === "") {
    return errorAlerts().walledGardenNoValueError;
  }
  // no whitespace
  if (trimmedRange.includes(" ")) {
    return errorAlerts().walledGardenWhitespaceError;
  }
  // if IP range
  if (trimmedRange.includes("/") && !isIPValid(trimmedRange)) {
    return errorAlerts().walledGardenIPError;
  }
  return undefined;
};

export const getWalledGardenRanges = (rowRanges: string[]): WalledGardenRange[] => {
  return rowRanges
    ? rowRanges.map(
        (range) =>
          ({
            range,
            isValid: !validateWalledGardenRange(range),
          }) as WalledGardenRange,
      )
    : [];
};

// state function for editing a single range
export const editWalledGardenRange = (state: SplashWalledGardenState) => {
  const result = cloneDeep(state);

  result.editModalAlert = validateWalledGardenRange(result.editModalCurrentRange);

  // if we encountered an error
  if (result.editModalAlert !== undefined) {
    return result;
  }

  // everything is correct
  //@ts-ignore
  result.stagedRanges[result.editModalCurrentIndex] = {
    range: result.editModalCurrentRange.trim(),
    isValid: true,
  };
  result.showEditModal = false;

  return result;
};

// state function for adding all ranges after a bulk add. This will not pop errors,
// but it will toggle the isValid field on incorrect values.
export const addWalledGardenRange = (state: SplashWalledGardenState) => {
  const result = cloneDeep(state);
  const ranges = stringToList(result.addModalCurrentRanges);

  ranges.forEach((range) => {
    const error = validateWalledGardenRange(range);

    if (error !== undefined) {
      // if invalid, push to front of the array
      result.stagedRanges.unshift({ range, isValid: false });
    } else {
      // if valid, push to back of the array
      result.stagedRanges.push({ range, isValid: true });
    }
  });
  result.showAddModal = false;

  return result;
};

// Consolidates errors returned by updateSplashPage and updateAccessControl
// into a single error message to be passed into showAlert (or handleError).
export const formatErrorMessage = (errors: string[] | string): string => {
  if (Array.isArray(errors)) {
    return errors.length > 1 ? "• ".concat(errors.join("\n• ")) : errors[0];
  }
  return errors;
};

export const parseUpdateResponse = (errors: string[] | string): string => {
  if (Array.isArray(errors)) {
    // TODO: this anti-duplication logic is only necessary because a bug exists in the
    // backend that causes the duplication (UDG-1531). Once that bug is resolved, we can
    // remove the uniq to filter out duplicates.
    // We use the slice to remove a canned "One or more errors" message at index 0.
    //@ts-ignore
    return errors.length > 1 ? uniq(errors.slice(1)) : errors[0];
  }
  return errors;
};
