import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import { DeviceScreensPropMap } from "~/enterprise/navigation/Types";
import { showSaveWarning } from "~/lib/AlertUtils";
import { SelectionScreen as MagneticSelectionScreen } from "~/migrationZone/enterprise/devices/screens/SelectionScreen/SelectionScreen";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import RadioSelectionList from "~/shared/components/RadioSelectionList";

import { CloseButton, SaveButton } from "../navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<DeviceScreensPropMap, "Selection">;

interface SelectionScreenState {
  selectedValue: string;
}

export class SelectionScreen extends PureComponent<Props, SelectionScreenState> {
  constructor(props: Props) {
    super(props);

    const { initialValue } = props;

    this.state = {
      selectedValue: initialValue,
    };

    this.setNavOptions();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  setNavOptions = () => {
    const { navigation, title } = this.props;
    navigation.setOptions({
      headerTitle: title,
      headerLeft: () => <CloseButton onPress={this.close} />,
      headerRight: () => <SaveButton onPress={this.save} disabled={!this.hasChanges()} />,
    });
  };

  hasChanges = () => {
    const { selectedValue } = this.state;
    const { initialValue } = this.props;
    return selectedValue !== initialValue;
  };

  close = () => {
    const { navigation } = this.props;
    if (this.hasChanges()) {
      showSaveWarning(this.save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  };

  save = () => {
    const { navigation, onSelect } = this.props;
    const { selectedValue } = this.state;
    onSelect?.(selectedValue);
    navigation.goBack();
  };

  onPressSelectedValue = (selectedValue: string) => {
    this.setState({ selectedValue });
  };

  render() {
    const { description, options } = this.props;
    const { selectedValue } = this.state;

    return (
      <FullScreenContainerView withScroll defaultPadding>
        <MkiText textStyle="smallSecondary" screenStyles={styles.infoText}>
          {description}
        </MkiText>
        <RadioSelectionList
          onSelect={this.onPressSelectedValue}
          options={options}
          selectedValue={selectedValue}
          testID={"SELECTION"}
        />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  infoText: {
    marginVertical: SPACING.default,
  },
});

export const MagnetizedSelectionScreen = withMagneticReplacementAdapter(
  SelectionScreen,
  MagneticSelectionScreen,
);

export default MagnetizedSelectionScreen;
