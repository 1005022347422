import { NodeType } from "@meraki/shared/api";
import { isEmpty } from "lodash";
import { MutableRefObject, RefObject } from "react";
import { StyleSheet, View } from "react-native";

import { LinkById, NodeById, PortMapById } from "~/api/queries/topology/useFormattedTopology";
import { SPACING } from "~/constants/MkiConstants";
import TopologyNodeCard from "~/go/components/topology/TopologyNodeCard";
import ViewPager from "~/shared/components/viewPager/ViewPager";
import { useThemeColors } from "~/shared/hooks/useTheme";

interface TopologyLayerProps {
  layer: string[];
  layerIndex: number;
  maxLayerIndex: number;
  onPageSelected: (layerIndex: number, nodeIndex: number) => void;
  pagerRefs?: MutableRefObject<RefObject<ViewPager>[]>;
  nodeIndexes: number[];
  links?: LinkById[];
  nodes?: NodeById;
  portMappings?: PortMapById;
  testID: string;
}

const TopologyLayer = (props: TopologyLayerProps) => {
  const {
    layer,
    layerIndex,
    maxLayerIndex,
    onPageSelected,
    pagerRefs,
    nodeIndexes,
    links,
    nodes,
    portMappings,
    testID,
  } = props;

  const getBorderLineWidth = (shouldRenderLine: boolean) =>
    shouldRenderLine ? styles.borderLine : styles.noBorder;

  const themeColors = useThemeColors();
  const lineStyle = { flex: 1, borderColor: themeColors.status.online.color };

  const isNotFirstLayer = layerIndex !== 0;
  const isNotLastLayer = layerIndex !== maxLayerIndex;
  const maxNodeIndex = layer.length - 1;

  const children = layer.map((currNodeId: string, nodeIndex: number) => {
    const isNotFirstInLayer = nodeIndex !== 0;
    const isNotLastInLayer = nodeIndex < maxNodeIndex;

    let hasChildren = false;
    if (isNotLastLayer) {
      hasChildren = !isEmpty(links?.[layerIndex]?.[currNodeId]);
    }

    return (
      <View style={styles.nodeWrapper} testID={`SCREEN_CARD_${currNodeId}`} key={currNodeId}>
        <View style={[lineStyle, getBorderLineWidth(isNotFirstLayer)] /* top line */} />
        <View style={styles.cardContainer}>
          <View style={[lineStyle, getBorderLineWidth(isNotFirstInLayer)] /* left line */} />
          <TopologyNodeCard
            node={nodes?.[currNodeId] ?? ({} as NodeType)}
            portMappings={portMappings ?? {}}
          />
          <View style={[lineStyle, getBorderLineWidth(isNotLastInLayer)] /* right line */} />
        </View>
        <View style={[lineStyle, getBorderLineWidth(hasChildren)] /* bottom line */} />
      </View>
    );
  });

  return (
    <View style={styles.flex} testID={testID} key={testID}>
      <ViewPager
        ref={pagerRefs?.current?.[layerIndex] ?? undefined}
        style={styles.flex}
        onPageSelected={(event) => onPageSelected(layerIndex, event.nativeEvent.position)}
        initialPage={nodeIndexes[layerIndex]}
      >
        {children}
      </ViewPager>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flexGrow: 1,
  },
  nodeWrapper: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  borderLine: {
    borderWidth: SPACING.tiny,
  },
  noBorder: {
    borderWidth: 0,
    margin: SPACING.tiny,
  },
});

export default TopologyLayer;
