import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const UplinkSchema = z.object({
  interface: z.string(),
  addresses: z.array(
    z.object({
      address: z.string(),
      assignmentMode: z.string(),
      gateway: z.string().nullable(),
      protocol: z.string(),
      public: z.object({
        address: z.nullable(z.string()),
      }),
    }),
  ),
});

export const DeviceUplinkSchema = z
  .object({
    mac: z.string(),
    name: z.string(),
    network: z.object({
      id: z.string(),
    }),
    productType: z.string(),
    serial: z.string(),
    tags: z.array(z.string()),
    uplinks: z.array(UplinkSchema),
  })
  .describe("DeviceUplinkSchema");

export const DeviceUplinksSchema = z.array(DeviceUplinkSchema).describe("DeviceUplinksSchema");

export type DeviceUplink = z.infer<typeof DeviceUplinkSchema>;

interface DeviceUplinkRequest {
  organizationId?: string;
  networkIds?: string[];
  serials?: string[];
}

function buildUrl(organizationId?: string) {
  return `/api/v1/organizations/${organizationId}/devices/uplinks/addresses/byDevice`;
}

function fetchDeviceUplinks({
  organizationId,
  ...queryParams
}: DeviceUplinkRequest): Promise<DeviceUplink[]> {
  return request(DeviceUplinksSchema, "GET", buildUrl(organizationId), { queryParams });
}

export const useDeviceUplinks = createQuery<DeviceUplinkRequest, DeviceUplink[]>({
  baseQueryKey: buildUrl("{organizationId}"),
  queryFn: (variables) => fetchDeviceUplinks(variables),
  requiredVariables: ["organizationId"],
});
