import { I18n } from "@meraki/core/i18n";
import { useOrgNetworks } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import RoundedButton from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import useActions from "~/shared/hooks/redux/useActions";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { CloseButton } from "~/shared/navigation/Buttons";

import { SettingsStackProps } from "../navigation/Types";

const renderNetworkName = (row: any) => {
  return (
    <MkiText textStyle={"secondary"} screenStyles={styles.networkName}>
      {row.name}
    </MkiText>
  );
};

const onError = (error: unknown) => {
  showAlert(I18n.t("ERROR"), error);
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "CreateNetwork">;

function CreateNetworkScreen() {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const { cancelablePromise } = useCancelablePromise();
  const { createGoNetwork } = useActions();
  const organizationId = useCurrentOrganizationId();
  const networksQuery = useOrgNetworks({ organizationId }, { enabled: !!organizationId });

  const [networkName, setNetworkName] = useState("");
  const [loading, setLoading] = useState(false);

  const createNewNetwork = useCallback(() => {
    setLoading(true);
    cancelablePromise(createGoNetwork(networkName))
      .then(() => {
        setLoading(false);
        analytics.logEvent(FIREBASE_EVENTS.createNetwork);
        showAlert(
          I18n.t("CREATE_SITE.SUCCESS_ALERT.TITLE"),
          I18n.t("CREATE_SITE.SUCCESS_ALERT.DESCRIPTION", { name: networkName }),
          navigation.goBack,
        );
      })
      .catch(onError)
      .finally(() => setLoading(false));
  }, [cancelablePromise, createGoNetwork, navigation, networkName, setLoading]);

  return (
    <FullScreenContainerView screenStyles={styles.screen}>
      <DefaultHeader
        title={I18n.t("CREATE_SITE.HEADER")}
        description={I18n.t("CREATE_SITE.DESCRIPTION")}
      />
      <View>
        <MkiTextInput
          screenStyles={styles.inputField}
          onChangeText={setNetworkName}
          placeholder={I18n.t("CREATE_SITE.PLACEHOLDER")}
        />
        <RoundedButton
          onPress={createNewNetwork}
          screenStyles={styles.button}
          testID="CREATE_NETWORK.BUTTON"
        >
          {I18n.t("CREATE_SITE.PRIMARY_BUTTON")}
        </RoundedButton>
      </View>
      {networksQuery.data && (
        <View style={styles.chooseNetworkName}>
          <MkiText>{I18n.t("CREATE_SITE.CURRENT_SITES")}</MkiText>
          <MkiTable
            data={networksQuery.data}
            keyExtractor={(item: any) => item.name}
            renderRow={renderNetworkName}
            testID="CREATE_NETWORK.CURRENT_SITES"
          />
        </View>
      )}
      <LoadingSpinner visible={loading || networksQuery.isLoading} />
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  button: {
    marginHorizontal: SPACING.default,
  },
  chooseNetworkName: {
    padding: SPACING.small,
    height: "50%",
  },
  networkName: {
    padding: SPACING.small,
  },
  inputField: {
    padding: SPACING.small,
    margin: SPACING.default,
  },
});

export default CreateNetworkScreen;
