import { useTheme } from "@meraki/core/theme";
import { isEmpty, omit } from "lodash";
import { createRef, forwardRef, PureComponent, ReactElement } from "react";
import { StyleProp, StyleSheet, TextInput, TextInputProps, TextStyle, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DropDownButton from "~/enterprise/components/DropDownButton";
import ClearInputButton from "~/go/components/ClearInputButton";
import RevealPasswordButton from "~/go/components/RevealPasswordButton";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import BioAuthButton from "~/shared/components/BioAuthButton";

export interface MkiTextInputProps extends TextInputProps {
  testID?: string;
  screenStyles?: StyleProp<TextStyle>;
  showClearButton?: boolean;
  revealable?: boolean;
  clearTestID?: string;
  enableBioAuth?: boolean;
  onBioAuthPress?: () => void;
  showDropDown?: boolean;
  icon?: ReactElement;
  textStyle?: StyleProp<TextStyle>;
  clearButtonColor?: string;
  onDropDownPress?: () => void;
}

interface MkiTextInputState {
  value: string;
  isRevealed: boolean;
}

export class MkiTextInput extends PureComponent<MkiTextInputProps, MkiTextInputState> {
  static defaultProps = {
    testID: undefined,
    screenStyles: {},
    multiline: false,
    numberOfLines: 1,
    showClearButton: false,
    isRevealed: false,
  };

  // Note: This is only need for rare cases where `value` changes
  // in the parent component from something other than `onChangeText`
  static getDerivedStateFromProps(props: MkiTextInputProps, state: MkiTextInputState) {
    if (props.value != null && props.value !== state.value) {
      return { value: props.value };
    }
    return null;
  }

  textInput: React.RefObject<TextInput> = createRef();

  state = { value: "", isRevealed: false };

  focus() {
    if (this.textInput.current) {
      this.textInput.current.focus();
    }
  }

  isFocused = () => {
    let isFocusedResult = false;
    if (this.textInput.current) {
      isFocusedResult = this.textInput.current.isFocused();
    }
    return isFocusedResult;
  };

  onClear = () => {
    this.focus();
    this.onChangeText("");
  };

  onChangeText = (newText: string) => {
    const { onChangeText } = this.props;
    if (onChangeText) {
      onChangeText(newText);
    }
    if (isEmpty(newText)) {
      this.setState({ isRevealed: false });
    }
    this.setState({ value: newText });
  };

  renderButtons = () => {
    return (
      <View style={mkiTextInputStyles.buttonsContainer}>
        {this.renderRevealButton()}
        {this.renderClearButton()}
        {this.renderBioAuth()}
        {this.renderDropDown()}
      </View>
    );
  };

  renderBioAuth = () => {
    const { value } = this.state;
    const { enableBioAuth, onBioAuthPress } = this.props;
    if (!enableBioAuth || value || !onBioAuthPress) {
      return null;
    }

    return <BioAuthButton onPress={onBioAuthPress} />;
  };

  renderRevealButton = () => {
    const { revealable, secureTextEntry } = this.props;
    const { value, isRevealed } = this.state;

    return !isEmpty(value) && secureTextEntry && revealable ? (
      <RevealPasswordButton
        buttonStyles={mkiTextInputStyles.revealPasswordButton}
        onPress={() => this.setState({ isRevealed: !isRevealed })}
        passwordVisible={isRevealed}
      />
    ) : null;
  };

  renderClearButton = () => {
    const { showClearButton, clearTestID, clearButtonColor } = this.props;
    const { value } = this.state;

    if (!showClearButton || isEmpty(value)) {
      return null;
    }

    return (
      <ClearInputButton
        onClear={this.onClear}
        buttonStyles={mkiTextInputStyles.clearButton}
        clearTestID={clearTestID}
        clearButtonColor={clearButtonColor}
      />
    );
  };

  renderDropDown = () => {
    const { showDropDown, onDropDownPress } = this.props;

    return showDropDown ? <DropDownButton onPress={onDropDownPress} testID={"drop-down"} /> : null;
  };

  renderText = () => {
    const { onDropDownPress, editable, screenStyles, secureTextEntry, icon, textStyle } =
      this.props;
    const { theme } = useTheme.getState();
    const { value, isRevealed } = this.state;
    const additionalProps = omit(this.props, [
      "screenStyles",
      "style",
      "clearTestID",
      "secureTextEntry",
    ]);

    return (
      <View style={[mkiTextInputStyles.containerStyle, screenStyles]}>
        {icon}
        <TextInput
          ref={this.textInput}
          style={[
            mkiTextInputStyles.textInput,
            themeColors(theme).text?.default,
            ...(textStyle ? [textStyle] : []),
          ]}
          editable={editable}
          secureTextEntry={secureTextEntry && !isRevealed}
          placeholderTextColor={
            themeColors(theme).text?.placeholder?.color || MkiColors.placeholderTextColor
          }
          {...additionalProps}
          value={value}
          onChangeText={this.onChangeText}
          onPressIn={onDropDownPress}
        />
        {this.renderButtons()}
      </View>
    );
  };

  render() {
    const { onDropDownPress, showDropDown } = this.props;

    return showDropDown ? (
      <TouchableWithoutFeedback onPress={onDropDownPress}>
        {this.renderText()}
      </TouchableWithoutFeedback>
    ) : (
      <>{this.renderText()}</>
    );
  }
}

const mkiTextInputStyles = StyleSheet.create({
  containerStyle: {
    flexDirection: "row",
    flexGrow: platformSelect({
      web: 1,
      mobile: 0,
    }),
    alignItems: "center",
  },
  textInput: {
    flex: 1,
    fontFamily: "ciscosans",
    fontSize: appSelect({
      enterprise: 16,
      go: normalizedFontSize(16),
    }),
    fontStyle: "normal",
    fontWeight: "400",
    color: MkiColors.textColor,
    paddingTop: SPACING.meager,
  },
  buttonsContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  revealPasswordButton: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  clearButton: {
    paddingVertical: 0,
  },
});

const MkiTextInputWithRef = forwardRef<TextInput, MkiTextInputProps>((props, ref) => (
  // @ts-expect-error ref has been set up a bit strangely here, it needs the properties of a TextInput type but
  // MkiTextInput expects it to be MkiTextInput type, which is a component and does not have the right attributes
  <MkiTextInput {...props} ref={ref} />
));

MkiTextInputWithRef.displayName = "MkiTextInputWithRef";

export default MkiTextInputWithRef;
