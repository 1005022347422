import { NumeralInternal } from "./NumeralInternal";
import { NumeralProps } from "./types";

export type PercentNumeralProps = Omit<NumeralProps, "value" | "units"> & {
  value: number | null | undefined;
};

export function PercentNumeral({ value, ...rest }: PercentNumeralProps) {
  const percentage = (value !== 0 && !value) || isNaN(value) ? undefined : Math.round(value * 100);

  return <NumeralInternal {...rest} value={percentage} units="%" />;
}
