import { I18n } from "@meraki/core/i18n";
import { useAppForeground } from "@meraki/shared/navigation";
import JailMonkey from "jail-monkey";
import { ReactElement, useEffect, useState } from "react";
import { Platform, SafeAreaView, StyleSheet, View } from "react-native";

import { tryBioAuthLogin } from "~/actions";
import { SPACING } from "~/constants/MkiConstants";
import LoadingScreen from "~/enterprise/screens/LoadingScreen";
import { showUpdateAlert } from "~/lib/AlertUtils";
import { FIREBASE_EVENTS, sendMilestoneEvent } from "~/lib/FirebaseUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import {
  getIsSamlUser,
  isAuthenticatedState,
  isUpdateRequired,
  isUserBioAuthEnabled,
} from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

import useAppSelector from "../hooks/redux/useAppSelector";

const LOCK_ICON_SIZE = 72;

const SecurityComponent = ({ children }: { children: ReactElement }) => {
  const isSamlUser = useAppSelector(getIsSamlUser);
  const isPrevAuthenticated = useAppSelector(isAuthenticatedState);
  const userEnabledBioAuth = useAppSelector(isUserBioAuthEnabled);
  const updateRequired = useAppSelector(isUpdateRequired);
  const [passedAuthCheck, setPassedAuthCheck] = useState(isSamlUser || !isPrevAuthenticated);

  useEffect(() => {
    if (updateRequired) {
      showUpdateAlert();
    }
  }, [updateRequired]);

  useAppForeground(() => {
    if (!isWeb() && __MERAKI_GO__) {
      sendMilestoneEvent(FIREBASE_EVENTS.appForeground);
    }
  });

  const color = themeColors(useTheme()).navigation.primary;
  const isJailbroken = Platform.OS === "ios" && JailMonkey.isJailBroken();

  if (isJailbroken) {
    const text = I18n.t("SECURITY.JAILBROKEN");

    return (
      <SafeAreaView>
        <View style={styles.containerStyle}>
          <MerakiIcon name="lock" size={LOCK_ICON_SIZE} style={{ color }} />
          <MkiText screenStyles={styles.textContainerStyle}>{text}</MkiText>
        </View>
      </SafeAreaView>
    );
  }

  if (userEnabledBioAuth && !passedAuthCheck) {
    return (
      <LoadingScreen
        devMessage="Waiting on bio auth"
        actions={[tryBioAuthLogin]}
        onFinishLoad={() => {
          setPassedAuthCheck(true);
        }}
      />
    );
  }

  return children;
};

const styles = StyleSheet.create({
  containerStyle: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainerStyle: {
    paddingHorizontal: SPACING.default,
  },
});

export default SecurityComponent;
