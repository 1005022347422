import {
  CONNECTION_STATS_FAILURE,
  CONNECTION_STATS_REQUEST,
  CONNECTION_STATS_SUCCESS,
  FAILED_CONNECTIONS_FAILURE,
  FAILED_CONNECTIONS_REQUEST,
  FAILED_CONNECTIONS_SUCCESS,
  LATENCY_STATS_FAILURE,
  LATENCY_STATS_REQUEST,
  LATENCY_STATS_SUCCESS,
  WEEK,
} from "@meraki/shared/redux";

import { WirelessHealthRequestPath, WirelessHealthRequestType } from "~/constants/WirelessHealth";
import { ApiAction, CALL_API } from "~/middleware/api";
import { Schemas } from "~/shared/lib/Schemas";
import { WirelessHealthPath } from "~/shared/types/WirelessHealth";

export interface WirelessHealthRequestParams {
  networkId: string;
  t0?: number;
  t1?: number;
  timespan?: number;
  type?: WirelessHealthRequestType;
}

function getWirelessHealthStats(
  path: string,
  types: readonly [string, string, string],
  params: WirelessHealthRequestParams,
) {
  const { networkId, t0, t1, timespan, type } = params;
  let schema;

  // Public API only supports fetching up to 1 week back
  // TODO: Remove and chain this into 4 requests or smth if we decide to fetch the full month
  const sanitizeTimespan = (timespan: number) => Math.min(timespan, WEEK.value);

  const buildQueryParams = () => {
    if (timespan === undefined) {
      return { t0, t1 };
    }
    return { timespan: sanitizeTimespan(timespan) };
  };

  let baseEndpoint = `/api/v1/networks/${networkId}/wireless/`;
  if (path !== WirelessHealthPath.failedConnections) {
    switch (type) {
      case WirelessHealthRequestType.clients:
        baseEndpoint += `${WirelessHealthRequestPath.clients}/`;
        schema = Schemas.CLIENTS_MAC_ARRAY;
        break;
      case WirelessHealthRequestType.nodes:
        baseEndpoint += `${WirelessHealthRequestPath.devices}/`;
        schema = Schemas.NODE_ARRAY;
        break;
      case WirelessHealthRequestType.summary:
        break;
    }
  }

  return {
    [CALL_API]: {
      types,
      endpoint: `${baseEndpoint}${path}`,
      config: {
        method: "GET",
        queryParams: buildQueryParams(),
      },
      schema,
      meta: { networkId },
    },
  } as const;
}

export function getConnectionStats(params: WirelessHealthRequestParams): ApiAction {
  const actionTypes = [
    CONNECTION_STATS_REQUEST,
    CONNECTION_STATS_SUCCESS,
    CONNECTION_STATS_FAILURE,
  ] as const;

  return getWirelessHealthStats(WirelessHealthPath.connectionStats, actionTypes, params);
}

export function getLatencyStats(params: WirelessHealthRequestParams) {
  const actionTypes = [
    LATENCY_STATS_REQUEST,
    LATENCY_STATS_SUCCESS,
    LATENCY_STATS_FAILURE,
  ] as const;

  return getWirelessHealthStats(WirelessHealthPath.latencyStats, actionTypes, params);
}

export function getFailedConnections(params: WirelessHealthRequestParams) {
  const actionTypes = [
    FAILED_CONNECTIONS_REQUEST,
    FAILED_CONNECTIONS_SUCCESS,
    FAILED_CONNECTIONS_FAILURE,
  ] as const;

  return getWirelessHealthStats(WirelessHealthPath.failedConnections, actionTypes, params);
}
