import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const AFCStatusSchema = z.union([
  z.literal("LOCATION_NOT_FOUND"),
  z.literal("AFC_GENERAL_ERROR"),
  z.literal("DEVICE_DISALLOWED"),
  z.literal("INVALID_VALUE"),
  z.literal("HEIGHT_UNDEFINED"),
  z.literal("NEVER_REQUESTED"),
  z.literal("SUCCESS"),
]);

export const AFCSettingsSchema = z
  .object({
    name: z.string(),
    network: z.object({
      id: z.string(),
    }),
    serial: z.string(),
    expiresAt: z.string(),
    lastUpdatedAt: z.string(),
    limits: z.array(
      z.object({
        channel: z.number(),
        limit: z.object({
          inPsd: z.number(),
          inDbm: z.number(),
        }),
      }),
    ),
    status: AFCStatusSchema,
    location: z.object({
      lat: z.number(),
      lng: z.number(),
      uncertainty: z.number(),
      type: z.union([z.literal("gps"), z.literal("propagation")]),
    }),
  })
  .describe("AFCSettingsSchema");

export type AFCSettings = z.infer<typeof AFCSettingsSchema>;
export type AFCStatus = z.infer<typeof AFCStatusSchema>;

interface AFCSettingsRequest {
  serial: string;
}

function buildUrl({ serial }: AFCSettingsRequest) {
  return `/api/v1/devices/${serial}/wireless/radio/afc/powerLimits`;
}

const fetchAFCSettings = ({ serial }: AFCSettingsRequest): Promise<AFCSettings> =>
  request(AFCSettingsSchema, "GET", buildUrl({ serial }));

export const useAFCSettings = createQuery<AFCSettingsRequest, AFCSettings>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchAFCSettings(variables),
});
