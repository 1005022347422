import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { ApplicationUsageScreensPropMap } from "~/go/navigation/Types";
import { usageScreens } from "~/go/navigation/UsageStack";
import ApplicationUsageDetailsScreen from "~/go/screens/ApplicationUsageDetailsScreen";
import ApplicationUsageListScreen from "~/shared/screens/ApplicationUsageListScreen";

const ForwardedApplicationUsageListScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<ApplicationUsageScreensPropMap, "ApplicationUsageList">
>()(ApplicationUsageListScreen);
const ForwardedApplicationUsageDetailsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<ApplicationUsageScreensPropMap, "ApplicationUsageDetails">
>()(ApplicationUsageDetailsScreen);

export function applicationUsageScreens<T extends ApplicationUsageScreensPropMap>(
  Stack: StackType<T>,
) {
  return (
    <>
      <Stack.Screen
        name="ApplicationUsageList"
        component={ForwardedApplicationUsageListScreen}
        options={{ headerTitle: I18n.t("APPLICATION_USAGE.TITLE") }}
      />
      <Stack.Screen
        name="ApplicationUsageDetails"
        component={ForwardedApplicationUsageDetailsScreen}
      />
      {usageScreens(Stack)}
    </>
  );
}
