import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { FontWeightOptions, generatePdfHTML, pdfOptions } from "@meraki/go/ssid";
import { getPermission } from "@meraki/shared/permissions";
import { get } from "lodash";
import { PureComponent } from "react";
import { Dimensions, StyleSheet } from "react-native";
import RNHTMLtoPDF, { Pdf } from "react-native-html-to-pdf";
import ImagePicker from "react-native-image-crop-picker";
import { PERMISSIONS } from "react-native-permissions";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";
import RNFetchBlob from "rn-fetch-blob";

import Organization from "~/api/models/Organization";
import { SPACING, WINDOW } from "~/constants/MkiConstants";
import { SPLASH_IMAGE_DIMENSIONS } from "~/constants/Splash";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import PickerModalRow from "~/go/rows/PickerModalRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert, showRequestPermissions } from "~/lib/AlertUtils";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import {
  currentNetworkState,
  currentOrganization,
  getPdfInfo,
  slimSsidsSelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import ImageThumbnail from "~/shared/components/ImageThumbnail";
import InputModal from "~/shared/components/InputModal";
import SummaryList from "~/shared/components/SummaryList";
import { PreviewFileButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { pdfInfo, SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const CAMERA_ROLL_PERMISSIONS_MISSING = "E_PERMISSION_MISSING";
const USER_LIBRARY = "UserLibrary";
const ORG_NAME = "orgName";
const CUSTOM_MESSAGE = "customMessage";
const FONT_WEIGHT = "fontWeight";

type ReduxProps = {
  networkId: string;
  ssid: SSID;
  organization: Organization;
  allPdfInfo: {
    [networkId: string]: {
      [num: number]: pdfInfo;
    };
  };
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDSharePDF"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

interface showModalStates {
  orgName: boolean;
  customMessage: boolean;
  fontWeight: boolean;
}

interface maxLength {
  orgName: number;
  customMessage: number;
}

interface SSIDDetailsPDFState {
  showModalStates: showModalStates;
  originalStates: pdfInfo;
  customStates: pdfInfo;
  maxLength: maxLength;
}

type PDFOptions = {
  html: string;
  fileName: string;
  padding: number;
};

export class SSIDSharePDFScreen extends PureComponent<Props, SSIDDetailsPDFState> {
  constructor(props: Props) {
    super(props);

    const { organization, allPdfInfo, networkId, ssidNumber } = this.props;
    let orgName: string | undefined = organization.name;
    let logoURL: string | undefined = "";
    let customMessage: string | undefined = "";
    let base64Logo: string | undefined = "";

    let fontWeight: FontWeightOptions = "normal";

    // Grab any previously stored info about the PDF
    if (allPdfInfo?.[networkId]?.[ssidNumber]) {
      const pdfInfo = allPdfInfo[networkId][ssidNumber];
      orgName = pdfInfo.orgName;
      logoURL = pdfInfo.logoURL;
      base64Logo = pdfInfo.base64Logo;
      customMessage = pdfInfo.customMessage;
      fontWeight = (pdfInfo.fontWeight || fontWeight) as FontWeightOptions;
    }

    this.state = {
      showModalStates: {
        orgName: false,
        customMessage: false,
        fontWeight: false,
      },
      originalStates: {
        orgName,
        logoURL,
        customMessage,
        fontWeight,
        base64Logo,
      },
      customStates: {
        orgName,
        logoURL,
        customMessage,
        fontWeight,
        base64Logo,
      },
      maxLength: {
        orgName: 40,
        customMessage: 200,
      },
    };

    if (!base64Logo && logoURL) {
      this.updateBase64ForLogo(logoURL);
    }
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerRight: () => <PreviewFileButton onPress={this.showPDF} />,
    });
  }

  updateBase64ForLogo = (url: string) => {
    let data = "";
    RNFetchBlob.fs.readStream(url, "base64", 4095).then((ifstream) => {
      ifstream.open();
      ifstream.onData((chunk) => {
        data += chunk;
      });
      ifstream.onError(console.log);
      ifstream.onEnd(() => {
        this.updateCustomStates({ base64Logo: data });
      });
    });
  };

  /* Create PDF */

  createPDF = (): PDFOptions => {
    const { ssid, setReqPending } = this.props;
    const { name, psk, qrCode } = ssid;
    const { customStates } = this.state;
    const { orgName, customMessage, base64Logo, fontWeight } = customStates;
    setReqPending(true);
    const pdfOptions: Partial<pdfOptions> = {
      organizationName: orgName,
      ssidName: name,
      ssidPassword: psk || "",
      qrCode: qrCode || "",
      customMessage,
      base64Logo,
      fontWeight,
    };
    const html = generatePdfHTML(pdfOptions);
    const options = {
      html: html,
      fileName: I18n.t("SSID_SHARE_PDF.PDF_NAME"),
      padding: 0,
    };
    return options;
  };

  showPDF = () => {
    const options = this.createPDF();
    this.htmlToPDF(options);
  };

  htmlToPDF = (options: PDFOptions) => {
    const { setReqPending } = this.props;

    if (isWeb()) {
      const newTab = window.open("about:blank", options.html, "_blank");
      newTab?.document?.write(options.html);
      setReqPending(false);
    } else {
      RNHTMLtoPDF.convert(options)
        .then((file: Pdf) => {
          platformSelect({
            ios: this.previewPDFiOS,
            android: this.previewPDFAndroid,
          })(file?.filePath ?? "");
          setReqPending(false);
        })
        .catch((error: unknown) => {
          const err = error as { message?: string };
          setReqPending(false);
          // Emojis don't work for Android, so we catch the error
          // and alert users that they are unsupported
          if (err?.message?.includes("Value is not an integer")) {
            showAlert(I18n.t("SSID_SHARE_PDF.ALERT.EMOJI"));
          }

          getPermission(PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE).catch(() => {
            setReqPending(false);
            showRequestPermissions(
              I18n.t("STORAGE_PERMISSIONS.TITLE"),
              I18n.t("STORAGE_PERMISSIONS.REQUEST_IOS"),
              I18n.t("STORAGE_PERMISSIONS.REQUEST_ANDROID"),
              I18n.t("OPEN_APP_SETTINGS.TITLE"),
            );
          });
        });
    }
  };

  previewPDFiOS = (filePath: string) => {
    RNFetchBlob.ios.openDocument(filePath);
  };

  previewPDFAndroid = (filePath: string) => {
    const android = RNFetchBlob.android;
    android
      .actionViewIntent(filePath, "application/pdf")
      .then()
      .catch((err) => {
        console.log(err);
      });
  };

  downloadPDFWeb = () => {
    const { setReqPending } = this.props;

    const options = this.createPDF();

    const newTab = window.open("about:blank", options.html, "_blank");
    newTab?.document?.write(options.html);
    newTab?.focus();
    newTab?.print();
    setReqPending(false);
  };

  /* Input Functions */

  toggleModal = (field: string, bool: boolean) => {
    const { showModalStates } = this.state;
    const newModalStates = { ...showModalStates };
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newModalStates[field] = bool;
    this.setState({
      showModalStates: newModalStates,
    });
  };

  submitModal = (field: string) => {
    this.toggleModal(field, false);

    // Can't have a blank orgName, so defaults to name in organization.name
    const { organization } = this.props;
    const { customStates } = this.state;

    const changes: { orgName?: string } = {};
    if (field === ORG_NAME && customStates.orgName === "") {
      this.updateCustomStates({ orgName: organization.name });
      changes.orgName = organization.name;
    }

    this.setState({
      originalStates: { ...customStates, ...changes },
    });

    this.updateReduxState(changes);
  };

  updateCustomStates = (options: pdfInfo) => {
    const { customStates } = this.state;
    options = this.checkLength(options);
    this.setState({
      customStates: { ...customStates, ...options },
    });
  };

  // Limit the characters when pasting into the InputModal
  checkLength = (options: pdfInfo) => {
    const { showModalStates, maxLength } = this.state;
    const fields = Object.keys(showModalStates) as (keyof maxLength)[];
    let returnOptions = { ...options };
    let newOptions: pdfInfo = {};
    for (const field of fields) {
      const option = options[field];
      if (option && option.length > maxLength[field]) {
        newOptions = {
          [field]: option.slice(0, maxLength[field]),
        };
        returnOptions = { ...returnOptions, ...newOptions };
      }
    }
    return returnOptions;
  };

  updateReduxState = (options: pdfInfo) => {
    const { customStates } = this.state;
    const { actions, networkId, ssidNumber } = this.props;
    actions.updatePDFInfo(networkId, ssidNumber, { ...customStates, ...options });
  };

  onUploadLogo = async () => {
    const { originalStates } = this.state;
    const { setReqPending } = this.props;

    // image picker will pop an error upon closing if the user
    // does not choose an image. We don't actually want to consider
    // that as an error, so we just catch it and kill the loading spinner.
    try {
      setReqPending(true);
      const imageFile = await ImagePicker.openPicker({
        smartAlbums: [USER_LIBRARY],
        avoidEmptySpaceAroundImage: false,
        width: SPLASH_IMAGE_DIMENSIONS.width,
        height: SPLASH_IMAGE_DIMENSIONS.height,
        cropping: true,
      });
      this.updateCustomStates({ logoURL: get(imageFile, "path") });
      this.updateBase64ForLogo(get(imageFile, "path"));
      this.setState({ originalStates: { ...originalStates, logoURL: get(imageFile, "path") } });
      this.updateReduxState({ logoURL: get(imageFile, "path") });
      setReqPending(false);
    } catch (error: unknown) {
      setReqPending(false);
      const { code } = error as { code: string };
      if (code === CAMERA_ROLL_PERMISSIONS_MISSING) {
        showRequestPermissions(
          I18n.t("PHOTO_PERMISSIONS.TITLE"),
          I18n.t("PHOTO_PERMISSIONS.REQUEST_IOS"),
          I18n.t("PHOTO_PERMISSIONS.REQUEST_ANDROID"),
          I18n.t("OPEN_APP_SETTINGS.TITLE"),
        );
      }
    }
  };

  clearImage = () => {
    const { originalStates } = this.state;
    this.updateCustomStates({ logoURL: "", base64Logo: "" });
    this.setState({ originalStates: { ...originalStates, logoURL: "", base64Logo: "" } });
    this.updateReduxState({ logoURL: "", base64Logo: "" });
  };

  resetOrgName = () => {
    const { originalStates } = this.state;
    const { orgName } = originalStates;
    this.updateCustomStates({ orgName });
    this.toggleModal(ORG_NAME, false);
  };

  resetCustomMessage = () => {
    const { originalStates } = this.state;
    const { customMessage } = originalStates;
    this.updateCustomStates({ customMessage });
    this.toggleModal(CUSTOM_MESSAGE, false);
  };

  getRenderableFontweightOptions = () => {
    return [
      {
        label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.LIGHTER"),
        value: "lighter" as const,
      },
      {
        label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.NORMAL"),
        value: "normal" as const,
      },
      {
        label: I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.OPTIONS.BOLD"),
        value: "bold" as const,
      },
    ];
  };

  /* Render Functions */

  renderCustomizeSettings = () => {
    const windowWidth = Dimensions.get(WINDOW).width;
    const { originalStates } = this.state;
    const { orgName, logoURL, customMessage } = originalStates;
    const rows = [
      {
        children: I18n.t("SSID_SHARE_PDF.CUSTOMIZE.ORG_NAME.LABEL"),
        subtitle: orgName,
        onPress: () => {
          this.toggleModal(ORG_NAME, true);
        },
        testID: "CUSTOM_PROMPT",
      },
      {
        children: I18n.t("SSID_SHARE_PDF.CUSTOMIZE.LOGO"),
        onPress: this.onUploadLogo,
        inlineView: (
          <ImageThumbnail
            source={{
              uri: logoURL,
              height: windowWidth / 4,
              width: windowWidth / 2,
            }}
            show={!!logoURL}
            onClose={this.clearImage}
          />
        ),
      },
      {
        children: I18n.t("SSID_SHARE_PDF.CUSTOMIZE.CUSTOM_MESSAGE.LABEL"),
        subtitle: customMessage,
        onPress: () => {
          this.toggleModal(CUSTOM_MESSAGE, true);
        },
        testID: "CUSTOM_PROMPT",
      },
    ];
    return (
      <SummaryList
        contentRows={rows}
        customRenderRow={(props) => <DisclosureRow {...props} />}
        disableBottomBorder
        hasSeparators
      />
    );
  };

  render() {
    const { showModalStates, customStates, maxLength } = this.state;

    const renderableFontWeightOptions = this.getRenderableFontweightOptions();
    const selectedFontweightOption = renderableFontWeightOptions.find(
      ({ value }) => value === customStates.fontWeight,
    );

    return (
      <FullScreenContainerView>
        <InputModal
          title={I18n.t("SSID_SHARE_PDF.CUSTOMIZE.ORG_NAME.LABEL")}
          visible={showModalStates.orgName}
          value={customStates.orgName}
          onChangeText={(input) => {
            this.updateCustomStates({ orgName: input });
          }}
          onExit={this.resetOrgName}
          onPrimaryPress={() => {
            this.submitModal(ORG_NAME);
          }}
          maxLength={maxLength.orgName}
          keyboardType={platformSelect({ android: "email-address", ios: "default" })}
        />
        <InputModal
          title={I18n.t("SSID_SHARE_PDF.CUSTOMIZE.CUSTOM_MESSAGE.LABEL")}
          visible={showModalStates.customMessage}
          value={customStates.customMessage}
          onChangeText={(input) => {
            this.updateCustomStates({ customMessage: input });
          }}
          onExit={this.resetCustomMessage}
          onPrimaryPress={() => {
            this.submitModal(CUSTOM_MESSAGE);
          }}
          maxLength={maxLength.customMessage}
          subtitle={I18n.t("SSID_SHARE_PDF.CUSTOMIZE.CUSTOM_MESSAGE.SUBTITLE")}
          keyboardType={platformSelect({ android: "email-address", ios: "default" })}
        />
        {this.renderCustomizeSettings()}
        <PickerModalRow
          selectedValue={selectedFontweightOption?.value}
          label={I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.LABEL")}
          subtitle={selectedFontweightOption?.label}
          visible={showModalStates.fontWeight}
          title={I18n.t("SSID_SHARE_PDF.FONT_WEIGHT.TITLE")}
          items={renderableFontWeightOptions}
          screenStyles={styles.pickerStyle}
          onValueSelect={(fontWeight) => {
            this.updateCustomStates({ fontWeight: fontWeight as FontWeightOptions });
            this.updateReduxState({ fontWeight: fontWeight as FontWeightOptions });

            this.toggleModal(FONT_WEIGHT, false);
          }}
          onExit={() => this.toggleModal(FONT_WEIGHT, false)}
          onPress={() => this.toggleModal(FONT_WEIGHT, true)}
          disableBottomBorder
        />

        {isWeb() && (
          <RoundedButton
            buttonType={ButtonType.primary}
            testID="DOWNLOAD_BUTTON"
            onPress={this.downloadPDFWeb}
            containerStyles={styles.shareButton}
          >
            {I18n.t("SSID_SHARE_PDF.WEB_DOWNLOAD_BUTTON")}
          </RoundedButton>
        )}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  pickerStyle: {
    paddingHorizontal: SPACING.default,
  },
  shareButton: {
    marginHorizontal: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SSIDSharePDF"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDSharePDFScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsSelector(state)[props.ssidNumber] || {},
    // @ts-expect-error TS(2322) FIXME: Type 'Required<{ name: string; networkId: string; ... Remove this comment to see the full error message
    organization: errorMonitor.notifyNonOptional(
      "param 'organization' undefined for SSIDSharePDFScreen",
      currentOrganization(state),
    ),
    allPdfInfo: getPdfInfo(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(SSIDSharePDFScreen);
