import { Box } from "@meraki/magnetic/layout";
import { useThemeMode } from "@meraki/magnetic/themes";
import { useCallback, useState } from "react";
import { LayoutChangeEvent } from "react-native";
import MapView, { MapViewProps, Marker, PROVIDER_GOOGLE } from "react-native-maps";

const nightMapStyle = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  { featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#d59563" }] },
  { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#263c3f" }] },
  { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#6b9a76" }] },
  { featureType: "road", elementType: "geometry", stylers: [{ color: "#38414e" }] },
  { featureType: "road", elementType: "geometry.stroke", stylers: [{ color: "#212a37" }] },
  { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#9ca5b3" }] },
  { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#746855" }] },
  { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#1f2835" }] },
  { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: "#f3d19c" }] },
  { featureType: "transit", elementType: "geometry", stylers: [{ color: "#2f3948" }] },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  { featureType: "water", elementType: "geometry", stylers: [{ color: "#17263c" }] },
  { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#515c6d" }] },
  { featureType: "water", elementType: "labels.text.stroke", stylers: [{ color: "#17263c" }] },
];

interface MapProps extends MapViewProps {
  testID?: string;
}

export const Map = ({ testID, ...rest }: MapProps) => {
  const theme = useThemeMode();
  const [width, setWidth] = useState<number>(1);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <MapView
        provider={PROVIDER_GOOGLE}
        customMapStyle={theme === "dark" ? nightMapStyle : undefined}
        style={{ height: width * 0.6 }}
        pitchEnabled={false}
        rotateEnabled={false}
        moveOnMarkerPress={false}
        {...rest}
      />
    </Box>
  );
};

Map.Marker = Marker;
