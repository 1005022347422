import { I18n } from "@meraki/core/i18n";
import { Button, List } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { setNestedModalData } from "~/actions";
import { showAlert } from "~/lib/AlertUtils";
import { filterSelectedData, getNestedData, setSelected } from "~/lib/NestedTableUtils";
import { nestedModalDataState } from "~/selectors";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { SharedScreensPropMap } from "~/shared/navigation/Types";
import { Next } from "~/shared/screens/NestedTableModalTypes";

/*
 * NestedTableModal builds out a tree of tableviews based on the data provided. The data must
 * be stored in redux using setNestedModalData().
 *
 * The basic requirement of the NestedTableModal component is that it needs to be able to
 * manipulate any node on the tree regardless of where we currently are in the tree, the
 * explanation of this can be found in the comments for the setSelected method. The reason
 * we need to use Redux becomes clear when we examine the alternative:

 * Keep state at the root of the tree or even at the component that called showModal, and pass
 * the setSelected function down to all children. When a child is selected, it will call the
 * setSelected function, which has been passed down from its parent, which in turn was passed
 * down from its parent and so on, eventually triggering the function at the root which changes
 * its state. The problem here is that since the views don't operate on a render hierarchy and
 * are instead separate views pushed down onto the previous one, this change of state doesn't
 * propagate down the tree. This means that the data will be changed, and the child won't even
 * know about it!

 * Maybe we could store the state of the local node so the local node's change is reflected and
 * that would solve our issue? Not so fast, the selection of the current node selects all other
 * nodes in its hierarchy, so when you go up a level, you encounter the same problem, that node
 * doesn't know about the state change that just occurred, and still displays the initial state!

 * Because of this, we need to have a source of truth that all nodes can manipulate, as well as
 * a way for all nodes to be informed of any changes, hence why we need redux.
 *
 * nestedModalData should have a shape like this (* required):
 *   *next: (Object) top level "next" key to keep the structure identical to children
 *     title: (String) modal title
 *     singleChoice: (boolean) should only one of the children be selected at any point?
 *     *entries: (array of Objects) the children to show in a list, must have at least 1
 *       *entry: (Object) an entry should have a shape like this:
 *         *label: (String) text to show on the row
 *         *value: (Object implementing '==' operator) value to be used for comparisons,
 *           it should be unique amongst its siblings
 *         renderDetail: (function accepting entries) Used for parent nodes, the entries
 *           passed in are its children, and the return value should be a String that will
 *           be displayed on right side of the row
 *         selected: (boolean) is this row or any of its descendents selected? This value is
 *           used to render details or check marks. It is also conditionally recalculated when
 *           a row is tapped. See setSelected() for more info.
 *         next: (Object) if this entry has children, their info is nested in here
 *
 *  Example:
 *  {
 *    next: {
 *      title: "Example title",
 *      entries: [{
 *        label: "Entry 1",
 *        value: "entry1",
 *        renderDetail: entries => `${entries.length} value(s) inside!`,
 *        selected: true,
 *        next: {
 *          title: "Nested title",
 *          singleChoice: true,
 *          entries: [
 *            { label: "Entry 3", value: 0 },
 *            { label: "Entry 4", value: 1 },
 *            { label: "Entry 5", value: 2 },
 *          ],
 *        },
 *      }, {
 *        label: "Entry 2",
 *        value: "entry2",
 *        selected: true,
 *      }],
 *    }
 *  }
 *
 * Extra fields can be included as well, these will be passed in along with the object when
 * the save button is pressed and the onSave callback is triggered.
 *
 * Since all levels of the NestedTableModal have access to the full data structure, the way we
 * keep track of where in the "tree" we are is by this.props.position. Position is an array of
 * indexes where the first index is the current ancestor at the top level, and the next index is
 * the next level down, and so on.
 *
 * For example if we have a nested modal structure like this:
 * NestedModal  this.props.position
 * -----------  -------------------
 * entry1           []
 *   entry4         [0]
 *   entry5         [0]
 *     entry7       [0, 1]
 * entry2           []
 * entry3           []
 *
 */

export type RowData<T> = Partial<Next<T>> & {
  label: string;
  selected: boolean;
  renderDetail?: (entries?: Next<T>["entries"]) => string;
};

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "NestedTable">;

export function NestedTableModal<T>({
  position = [],
  // TODO: investigate first render takes in prop, then after falls back to this value
  showDismiss = true,
  options = {},
  onSave,
  title,
}: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  const nestedModalData: {
    next: Next<T>;
  } = useAppSelector(nestedModalDataState);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (title) {
      navigation.setOptions({
        headerTitle: title,
      });
    }
    navigation.setOptions({
      headerLeft: () =>
        showDismiss ? <Button.Nav text={I18n.t("CANCEL")} onPress={navigation.goBack} /> : null,
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={async () => {
            const filteredData = filterSelectedData(nestedModalData.next.entries);
            setLoading(true);

            try {
              await onSave(filteredData);
              if (options?.singleDismiss) {
                navigation.goBack();
              } else {
                navigation.popToTop();
              }
            } catch (error) {
              showAlert("", error || I18n.t("ERROR.MESSAGE"));
            } finally {
              setLoading(false);
            }
          }}
        />
      ),
    });
  }, [
    navigation,
    nestedModalData?.next.entries,
    onSave,
    options?.singleDismiss,
    showDismiss,
    title,
  ]);

  /*
   * If a parent node is pressed, update the position and push on a new
   * modal. Otherwise, set the leaf node to selected and recalculate the
   * tree of selected nodes.
   */
  const onPress = (item: RowData<T>, rowIndex: number) => {
    const newPosition = [...position, rowIndex];

    if (item.next) {
      navigation.navigate("NestedTable", {
        title: item.next.title,
        position: newPosition,
        onSave,
        options,
      });
    } else {
      const newData = setSelected(nestedModalData, newPosition, 0, { toggle: options?.toggle });
      dispatch(setNestedModalData(newData));
    }
  };

  if (!nestedModalData) {
    return null;
  }

  return (
    <Screen.View>
      <List.FlashList
        paddingLeft="none"
        paddingRight="none"
        paddingTop="none"
        loading={loading}
        data={getNestedData(nestedModalData, position, true)}
        getItemData={(item: RowData<T>, _, index: number) => {
          const { label } = item;

          let rightAccessory;
          let description;
          if (item.hasOwnProperty("next") && item.next) {
            if (item.selected) {
              description = item.renderDetail?.(item.next.entries);
            }
          } else {
            if (item.selected) {
              rightAccessory = <Icon name="Check" color="brandAccent.icon.active" size={22} />;
            }
          }

          return {
            title: label,
            key: label,
            rightAccessory,
            description,
            hidePressable: !item.next,
            onPress: () => onPress(item, index),
          };
        }}
      />
    </Screen.View>
  );
}
