import { LiveSwitchPort } from "@meraki/react-live-broker";
import { SwitchPortJson } from "@meraki/shared/api";

import {
  SWITCHPORT_STATUS_FLAGS,
  SWITCHPORT_STATUS_TYPE,
  SWITCHPORT_STP_FLAGS,
  SWITCHPORT_USAGE_FLAGS,
} from "./constants/SwitchportStatus";

/* Copied from private/javascripts/SwitchportStatusUtils.js
 * All the logic for error coding is done in checkPortStatus
 */

export function checkPortStatus(port: LiveSwitchPort | SwitchPortJson) {
  const privateErrorConditions =
    `usageStatus` in port
      ? port.usageStatus & SWITCHPORT_USAGE_FLAGS.CRC_ERROR ||
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.JABBERS_ERROR || //&& port.isAdmin)
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.FRAGS_ERROR ||
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.STP_TCS_ERROR ||
        (port.usageStatus & SWITCHPORT_USAGE_FLAGS.MISSIZED_ERROR && !port.is_stackport) //  port.isAdmin &&
      : false;

  const privateWarningConditions =
    `usageStatus` in port
      ? port.usageStatus & SWITCHPORT_USAGE_FLAGS.CRC_WARNING ||
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.JABBERS_WARNING || //&& port.isAdmin
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.FRAGS_WARNING ||
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.COLLISIONS_ERROR ||
        port.usageStatus & SWITCHPORT_USAGE_FLAGS.STP_TCS_WARNING ||
        (port.usageStatus & SWITCHPORT_USAGE_FLAGS.MISSIZED_WARNING && !port.is_stackport) // port.isAdmin &&
      : false;
  if (
    port.curr_status & SWITCHPORT_STATUS_FLAGS.POE_OVERLOAD ||
    port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_PORT_BLOCKED ||
    port.stp_flags & SWITCHPORT_STP_FLAGS.STP_LOOPGUARD_ACTIVE ||
    privateErrorConditions
  ) {
    return SWITCHPORT_STATUS_TYPE.STATUS_ERROR;
  }
  if (
    port.curr_status & SWITCHPORT_STATUS_FLAGS.POE_DENIED ||
    port.curr_status & SWITCHPORT_STATUS_FLAGS.AP_NOAUTH ||
    port.curr_status & SWITCHPORT_STATUS_FLAGS.SPEED_DOWNSHIFT ||
    port.stp_flags & SWITCHPORT_STP_FLAGS.STP_BPDUGUARD_ACTIVE ||
    port.stp_flags & SWITCHPORT_STP_FLAGS.STP_ROOTGUARD_ACTIVE ||
    port.stp_flags & SWITCHPORT_STP_FLAGS.STP_BPDU_CONFLICT ||
    privateWarningConditions
  ) {
    return SWITCHPORT_STATUS_TYPE.STATUS_WARNING;
  }
  if (!switchportIsConnected(port)) {
    return SWITCHPORT_STATUS_TYPE.STATUS_DISCONNECTED;
  }
  if ((port.curr_status & 0xf) <= SWITCHPORT_STATUS_FLAGS.SPEED_100) {
    // Note: In this case, the Switch port is receiving less than 1GB speed,
    // but we will ignore this for Meraki Go.
    return SWITCHPORT_STATUS_TYPE.STATUS_SUBOPTIMAL;
  }
  return SWITCHPORT_STATUS_TYPE.STATUS_OPTIMAL;
}

export function switchportAlertingCount(portData: SwitchPortJson[]) {
  if (!portData) {
    return 0;
  }
  return portData.reduce((alertingCount: number, currentPort: LiveSwitchPort | SwitchPortJson) => {
    if (switchportIsAlerting(currentPort)) {
      return alertingCount + 1;
    }
    return alertingCount;
  }, 0);
}

export function switchportIsAlerting(portData: LiveSwitchPort | SwitchPortJson) {
  const status = checkPortStatus(portData);
  return (
    status === SWITCHPORT_STATUS_TYPE.STATUS_ERROR ||
    status === SWITCHPORT_STATUS_TYPE.STATUS_WARNING
  );
}

export function switchportActiveCount(portData: SwitchPortJson[]) {
  if (!portData) {
    return 0;
  }
  return portData.reduce((activeCount: number, currentPort: LiveSwitchPort | SwitchPortJson) => {
    if (switchportIsConnected(currentPort)) {
      return activeCount + 1;
    }
    return activeCount;
  }, 0);
}

export function switchportIsConnected(livePortData: LiveSwitchPort | SwitchPortJson) {
  return (livePortData.curr_status & SWITCHPORT_STATUS_FLAGS.CONNECTED) !== 0;
}

export function switchportIsStatusOptimal(livePortData: LiveSwitchPort | SwitchPortJson) {
  return checkPortStatus(livePortData) === SWITCHPORT_STATUS_TYPE.STATUS_OPTIMAL;
}
export function switchportIsStatusSubOptimal(livePortData: LiveSwitchPort | SwitchPortJson) {
  return checkPortStatus(livePortData) === SWITCHPORT_STATUS_TYPE.STATUS_SUBOPTIMAL;
}
export function switchportIsStatusWarning(livePortData: LiveSwitchPort | SwitchPortJson) {
  return checkPortStatus(livePortData) === SWITCHPORT_STATUS_TYPE.STATUS_WARNING;
}
export function switchportIsStatusError(livePortData: LiveSwitchPort | SwitchPortJson) {
  return checkPortStatus(livePortData) === SWITCHPORT_STATUS_TYPE.STATUS_ERROR;
}

export function switchPortIsUplink(livePortData: LiveSwitchPort | SwitchPortJson) {
  return switchportIsConnected(livePortData) && livePortData.is_uplink;
}

export function switchPortUsingPoe(livePortData: LiveSwitchPort | SwitchPortJson) {
  return (
    switchportIsConnected(livePortData) &&
    (`using_poe` in livePortData ? livePortData.using_poe : livePortData.use_poe)
  );
}

export function switchportIsBlockingTraffic(livePortData: LiveSwitchPort | SwitchPortJson) {
  return (
    switchportIsConnected(livePortData) &&
    (livePortData.curr_status & SWITCHPORT_STATUS_FLAGS.LACP_DISABLED ||
      livePortData.stp_flags & SWITCHPORT_STP_FLAGS.STP_STATE_DISCARDING) !== 0
  );
}
