import { I18n } from "@meraki/core/i18n";
import { Input, List } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { PortVlanTypes } from "@meraki/shared/api";

import { StagedSwitchPort } from "~/api/schemas/SwitchPort";
import { ObjectUpdater } from "~/shared/hooks/useObjectUpdater";

interface PortVlanConfigProps {
  updateByKey: ObjectUpdater<Partial<StagedSwitchPort>>;
  port: StagedSwitchPort;
}

export const PortVlanConfig = ({ port, updateByKey }: PortVlanConfigProps) => {
  const { type, vlan, voiceVlan, allowedVlans } = port;

  const updateVLAN = updateByKey("vlan");
  const updateVoicVLAN = updateByKey("voiceVlan");
  const updateVLANWithNumber = (newVlan: string) =>
    updateVLAN(newVlan ? parseInt(newVlan) || undefined : undefined);
  const updateVoicVLANWithNumber = (newVoicVlan: string) =>
    updateVoicVLAN(newVoicVlan ? parseInt(newVoicVlan) || null : null);

  return (
    <List label={I18n.t("SWITCH_PORT_EDIT.VLAN.TITLE")}>
      <List.RadioItem
        title={I18n.t("SWITCH_PORT_EDIT.ACCESS")}
        radioValue={PortVlanTypes.access}
        checkedRadioValue={type}
        onRadioValueChange={updateByKey("type")}
      >
        {type === PortVlanTypes.access && (
          <Box gap="sm">
            <Input
              onChangeText={updateVLANWithNumber}
              label={I18n.t("SWITCH_PORT_EDIT.VLAN.TITLE")}
              placeholder={I18n.t("SWITCH_PORT_EDIT.VLAN.PLACEHOLDER")}
              testID={"VLAN_CONFIG.VLAN"}
              value={vlan?.toString() ?? ""}
            />
            {"voiceVlan" in port && (
              <Input
                onChangeText={updateVoicVLANWithNumber}
                label={I18n.t("SWITCH_PORT_EDIT.VOICE_VLAN.TITLE")}
                placeholder={I18n.t("SWITCH_PORT_EDIT.VOICE_VLAN.PLACEHOLDER")}
                testID={"VLAN_CONFIG.VOICE_VLAN"}
                value={voiceVlan?.toString()}
              />
            )}
          </Box>
        )}
      </List.RadioItem>
      <List.RadioItem
        title={I18n.t("SWITCH_PORT_EDIT.TRUNK")}
        radioValue={PortVlanTypes.trunk}
        checkedRadioValue={type}
        onRadioValueChange={updateByKey("type")}
      >
        {type === PortVlanTypes.trunk && (
          <Box gap="sm">
            <Input
              onChangeText={updateVLANWithNumber}
              label={I18n.t("SWITCH_PORT_EDIT.NATIVE_VLAN.TITLE")}
              placeholder={I18n.t("SWITCH_PORT_EDIT.NATIVE_VLAN.PLACEHOLDER")}
              testID={"VLAN_CONFIG.NATIVE_VLAN"}
              value={vlan?.toString() ?? ""}
            />
            <Input
              onChangeText={updateByKey("allowedVlans")}
              label={I18n.t("SWITCH_PORT_EDIT.ALLOWED_VLANS.TITLE")}
              placeholder={I18n.t("SWITCH_PORT_EDIT.ALLOWED_VLANS.PLACEHOLDER")}
              value={allowedVlans}
              testID={"VLAN_CONFIG.ALLOWED_VLANS"}
              autoCapitalize={"none"}
            />
          </Box>
        )}
      </List.RadioItem>
    </List>
  );
};
