import { LAYER_7_GROUPS } from "@meraki/go/traffic-shaping";
import { kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import { cloneDeep, isEmpty } from "lodash";

import {
  ApplicationRule,
  ApplicationRulesByNumber,
  BandwidthRule,
  BandwidthRuleByNumber,
  PriorityLevel,
  SecurityRule,
  SecurityRulesByNumber,
  UsageLimitConflictOptional,
} from "~/go/types/NetworksTypes";
import I18n from "~/i18n/i18n";
import { formatTransferBits } from "~/lib/formatHelper";

export const MEGABITS_TO_MEBIBITS = 1.024;
export const KILOBITS_IN_MEGABITS = 1000;
export const UNLIMITED = 0;

const CUSTOM = "custom";
const NORMAL = "normal";
const APPLICATION_CATEGORY = "applicationCategory";

export const makeTrafficShapingRule = (
  limit: number | undefined,
  group: string,
  keyName: string,
  dscpTagValue: number | null,
  priority: PriorityLevel,
): ApplicationRule => ({
  identifier: group,
  definitions: [
    {
      type: APPLICATION_CATEGORY,
      value: {
        id: group,
        name: keyName,
      },
    },
  ],
  perClientBandwidthLimits: {
    bandwidthLimits: limit
      ? {
          limitDown: limit,
          limitUp: limit,
        }
      : {},
    settings: limit ? CUSTOM : "network default",
  },
  dscpTagValue,
  priority,
});

export const makeTrafficShapingRuleSet = (
  limit: number | undefined,
  group: string,
  keyName: string,
  existingRules: ApplicationRule[],
  dscpTagValue: number | null,
  priority: PriorityLevel | null,
): ApplicationRule[] => {
  const newRules = cloneDeep(existingRules);
  const ruleToUpdate = newRules.find((rule: ApplicationRule) => rule.identifier === group);
  const adjustedLimit = kilobitsToMebibitsInt(limit);

  if (ruleToUpdate) {
    // Update case
    if (adjustedLimit) {
      ruleToUpdate.perClientBandwidthLimits.settings = "custom";
      ruleToUpdate.perClientBandwidthLimits.bandwidthLimits = {
        limitDown: adjustedLimit,
        limitUp: adjustedLimit,
      };
    } else {
      ruleToUpdate.perClientBandwidthLimits.settings = "network default";
      ruleToUpdate.perClientBandwidthLimits.bandwidthLimits = {};
    }
    ruleToUpdate.dscpTagValue = dscpTagValue;
    ruleToUpdate.priority = priority;
  } else {
    // Create case
    const newRule = makeTrafficShapingRule(
      adjustedLimit,
      group,
      keyName,
      dscpTagValue,
      priority ?? NORMAL,
    );
    newRules.push(newRule);
  }

  return newRules;
};

export const applicationLimitConflicts = (
  proposedApplicationLimit: number | undefined,
  clientLimit: number,
): string | undefined => {
  // Validate proposed application bandwidth limit.
  // It must be less than the device/client limit.
  if (proposedApplicationLimit === undefined) {
    return;
  }
  if (Number.isInteger(clientLimit) && proposedApplicationLimit > clientLimit) {
    return formatTransferBits(clientLimit);
  }
  return;
};

export const clientLimitConflicts = (
  proposedClientLimit: number,
  applicationLimits?: ApplicationRule[] | null,
): UsageLimitConflictOptional => {
  if (isEmpty(applicationLimits) || !applicationLimits) {
    return;
  }
  const maxAppLimitGroup = applicationLimits.reduce(
    (prev: ApplicationRule, current: ApplicationRule) => {
      const bandwidthLimits = prev.perClientBandwidthLimits.bandwidthLimits;
      if (bandwidthLimits?.limitUp && bandwidthLimits?.limitDown) {
        return bandwidthLimits?.limitUp > bandwidthLimits?.limitUp ? prev : current;
      } else {
        return prev;
      }
    },
  );
  const maxAppLimit =
    (maxAppLimitGroup.perClientBandwidthLimits.bandwidthLimits?.limitUp || 0) *
    KILOBITS_IN_MEGABITS *
    MEGABITS_TO_MEBIBITS; // GX app limits are stored in Mb/s, not Kb/s like client limits
  const groupPayload = LAYER_7_GROUPS[maxAppLimitGroup.identifier];

  if (Number.isInteger(maxAppLimit) && proposedClientLimit < maxAppLimit) {
    return {
      group: I18n.t(groupPayload.name),
      limit: formatTransferBits(maxAppLimit),
    };
  }
  return;
};

export const mergeGXRules = (
  gxApplicationLimits: ApplicationRule[] | null | undefined,
  gxBandwidthLimits: BandwidthRule | null | undefined,
): SecurityRule[] => {
  let combinedGXShaping: SecurityRule[] = [];
  if (!isEmpty(gxApplicationLimits)) {
    combinedGXShaping = [...(gxApplicationLimits ? gxApplicationLimits : [])];
  }
  if (!isEmpty(gxBandwidthLimits) && gxBandwidthLimits) {
    combinedGXShaping.push(gxBandwidthLimits);
  }
  return combinedGXShaping;
};

export const mergeSSIDRules = (
  ssidApplicationLimits: ApplicationRulesByNumber,
  perClientLimits: BandwidthRuleByNumber,
  perSSIDLimits: BandwidthRuleByNumber,
  targetSsidNumber?: number,
): SecurityRulesByNumber => {
  const combinedSSIDRules: SecurityRulesByNumber = {};
  const entries = Object.entries(ssidApplicationLimits).filter(
    ([ssidNum, _]) => targetSsidNumber == null || parseInt(ssidNum) === targetSsidNumber,
  );

  for (const [ssidNumber, applicationLimits] of entries) {
    combinedSSIDRules[ssidNumber] = [...applicationLimits];

    const perClientLimit = perClientLimits[ssidNumber];
    const perSSIDLimit = perSSIDLimits[ssidNumber];
    if (perClientLimit != null) {
      combinedSSIDRules[ssidNumber].push(perClientLimit);
    }
    if (perSSIDLimit != null) {
      combinedSSIDRules[ssidNumber].push(perSSIDLimit);
    }
  }

  return combinedSSIDRules;
};

export const setIdentifierOnRules = (rules: ApplicationRule[]) => {
  Object.entries(rules).forEach(([ruleNum, rule]) => {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    rules[ruleNum].identifier = rule.definitions[0].value.id;
  });
  return rules;
};

export const removeRules = (rules: ApplicationRule[] | null | undefined, ruleNum: number) => {
  if (ruleNum == null) {
    return rules ?? [];
  }
  const updatedRules = cloneDeep(rules) ?? [];
  updatedRules.splice(ruleNum, 1);
  return updatedRules;
};
