import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Heading,
  List,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  IpSecVPN,
  useAdmins,
  useAuthUsers,
  useIpSecVPN,
  useUpdateIpSecVPN,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { getTotalIps } from "@meraki/shared/ip-address";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import { useMemo, useRef, useState } from "react";
import { Alert, RefreshControl } from "react-native";
import Toast from "react-native-simple-toast";

type FormData = {
  DNSProvider: string;
  primaryDNS: string;
  secondaryDNS: string;
  subnet: string;
  sharedSecret: string;
};

export const getDNSProviderLabel = (dns: string) => {
  switch (dns) {
    case "google_dns":
      return I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.GOOGLE");
    case "opendns":
      return I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.OPEN_DNS");
    case "custom":
      return I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.CUSTOM");
    default:
      return "";
  }
};

export const ClientVPNScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();
  const { data: ipSecVPN, refetch, isRefetching } = useIpSecVPN({ networkId });
  const {
    data: admins,
    refetch: refetchAdmins,
    isRefetching: adminsIsRefetching,
  } = useAdmins({ organizationId });
  const {
    data: authUsers,
    refetch: refetchAuthUsers,
    isRefetching: authUsersIsRefetching,
  } = useAuthUsers({ networkId });

  const updateIpSecVPN = useUpdateIpSecVPN();

  const bottomSheetDetailsRef = useRef<BottomSheetMethods>(null);
  const DNSbottomSheetDetailsRef = useRef<BottomSheetMethods>(null);

  const adminEmails = useMemo(() => admins?.map(({ email }) => email), [admins]);
  const adminAuthUsers = useMemo(
    () =>
      authUsers?.filter(
        ({ email, accountType }) => adminEmails?.includes(email) && accountType === "Client VPN",
      ),
    [adminEmails, authUsers],
  );
  const guests = useMemo(
    () =>
      authUsers?.filter(
        ({ email, accountType }) => !adminEmails?.includes(email) && accountType === "Client VPN",
      ),
    [adminEmails, authUsers],
  );

  const [bottomSheetType, setBottomSheetType] = useState("");
  const methods = useForm<FormData>({
    values: {
      DNSProvider: ipSecVPN?.ipv4?.nameservers?.provider || "google_dns",
      primaryDNS: ipSecVPN?.ipv4?.nameservers?.addresses?.[0] ?? "",
      secondaryDNS: ipSecVPN?.ipv4?.nameservers?.addresses?.[1] ?? "",
      subnet: ipSecVPN?.ipv4.subnet ?? "",
      sharedSecret: "",
    },
  });

  const resetDNS = () => {
    methods.setValue("primaryDNS", "");
    methods.setValue("secondaryDNS", "");
    methods.setValue("DNSProvider", ipSecVPN?.ipv4?.nameservers?.provider ?? "google_dns");
  };

  const resetForm = () => {
    switch (bottomSheetType) {
      case "subnet":
        methods.setValue("subnet", "");
        break;
      case "secret":
        methods.setValue("sharedSecret", "");
        break;
      default:
        resetDNS();
    }
  };

  const updateSettings = (body: Partial<IpSecVPN>) => {
    updateIpSecVPN.mutate(
      {
        networkId,
        params: {
          enabled,
          ...body,
        },
      },
      {
        onSuccess: () => {
          bottomSheetDetailsRef?.current?.dismiss();
          DNSbottomSheetDetailsRef?.current?.dismiss();
          refetch();
          Toast.showWithGravity(I18n.t("CLIENT_VPN.SAVED"), Toast.SHORT, Toast.TOP);
          resetForm();
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  const onDNSProviderSave = ({ DNSProvider, primaryDNS, secondaryDNS }: FormData) => {
    updateSettings({
      ipv4: {
        nameservers: {
          provider: DNSProvider,
          addresses: DNSProvider === "custom" ? [primaryDNS, secondaryDNS] : [],
        },
      },
    });
  };

  const onSave = ({ subnet, sharedSecret }: FormData) => {
    if (bottomSheetType === "subnet") {
      updateSettings({ ipv4: { subnet } });
    } else {
      updateSettings({ sharedSecret });
    }
  };

  const enabled = ipSecVPN?.enabled ?? false;
  const provider = methods.watch("DNSProvider");
  const subnet = methods.watch("subnet");
  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={isRefetching || adminsIsRefetching || authUsersIsRefetching}
          onRefresh={() => {
            refetch();
            refetchAdmins();
            refetchAuthUsers();
          }}
        />
      }
      addDefaultPadding
    >
      <Box bottomDividerBorder paddingBottom="xs">
        <Heading size="h1">{I18n.t("CLIENT_VPN.TITLE")}</Heading>
      </Box>
      <List>
        <List.Item
          title={I18n.t("CLIENT_VPN.TITLE")}
          rightAccessory={
            <Toggle
              checked={enabled}
              onChange={() => updateSettings({ enabled: !enabled })}
              testID={`CLIENT_VPN.${enabled ? "ENABLED" : "DISABLED"}`}
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("CLIENT_VPN.USER.ADMIN")}
          description={I18n.t("CLIENT_VPN.HAS_VPN_ACCESS", {
            numVPN: adminAuthUsers?.length ?? 0,
            num: admins?.length ?? 0,
          })}
          leftAccessory={<Icon name="User" />}
          rightAccessory={
            <Button
              text="Edit"
              kind="tertiary"
              disabled={!enabled}
              onPress={() => {
                navigation.navigate("ClientVPNAdminAccessList");
              }}
            />
          }
        />
        <List.Item
          title={I18n.t("CLIENT_VPN.USER.GUEST")}
          description={
            guests?.length === 1
              ? I18n.t("CLIENT_VPN.USER.HAS_VPN_ACCESS_1", { num: guests?.length })
              : I18n.t("CLIENT_VPN.USER.HAS_VPN_ACCESS", { num: guests?.length })
          }
          leftAccessory={<Icon name="User" />}
          rightAccessory={
            <Button
              text={I18n.t("EDIT")}
              kind="tertiary"
              disabled={!enabled}
              onPress={() => {
                navigation.navigate("ClientVPNGuestList");
              }}
            />
          }
        />
      </List>
      <Card>
        <Text size="p1">{I18n.t("CLIENT_VPN.SUBNET")}</Text>
        <Text size="p3" color="light">
          {I18n.t("CONFIGURE.TRAFFIC_SHAPING.RULE.APP_CATEGORY.DESCRIPTION")}
        </Text>
        <List>
          <List.Item
            title={subnet}
            rightAccessory={
              <Button
                text={I18n.t("EDIT")}
                kind="tertiary"
                onPress={() => {
                  setBottomSheetType("subnet");
                  bottomSheetDetailsRef?.current?.present();
                }}
                testID="SUBNET_EDIT"
              />
            }
          />
        </List>
        <Text size="p3" color="light">
          {I18n.t("CONFIGURE_VLAN.SUBNET_COUNT", { address_count: getTotalIps(subnet ?? "") })}
        </Text>
      </Card>
      <List>
        <List.Item
          title={I18n.t("CLIENT_VPN.SHARED_SECRET.TITLE")}
          description={I18n.t("CLIENT_VPN.SHARED_SECRET.DESCRIPTION")}
          rightAccessory={
            <Button
              text={I18n.t("ADD")}
              kind="tertiary"
              onPress={() => {
                setBottomSheetType("secret");
                bottomSheetDetailsRef.current?.present();
              }}
              testID="SHARED_SECRET_ADD"
            />
          }
        />
      </List>
      <Card>
        <Box flexDirection="row" alignItems="center" gap="sm" justifyContent="space-between">
          <Text size="p1">{I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.TITLE")}</Text>
        </Box>
        <Text size="p3" color="light">
          {I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.DESCRIPTION")}
        </Text>
        <List>
          <List.Item
            title={getDNSProviderLabel(provider)}
            rightAccessory={
              <Button
                text={I18n.t("EDIT")}
                kind="tertiary"
                onPress={() => DNSbottomSheetDetailsRef.current?.present()}
                testID="DNS_EDIT"
              />
            }
          />
        </List>
      </Card>
      <List>
        <List.Item
          title={I18n.t("CLIENT_VPN.EVENT_LOG.TITLE")}
          description={I18n.t("CLIENT_VPN.EVENT_LOG.DESCRIPTION")}
          onPress={() => navigation.navigate("ClientVPNEventLog")}
        />
      </List>
      <BottomSheet.Modal ref={bottomSheetDetailsRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={
            bottomSheetType === "subnet"
              ? I18n.t("CLIENT_VPN.SUBNET_EDIT")
              : I18n.t("CLIENT_VPN.SHARED_SECRET.ADD")
          }
          onCancelPress={() => {
            bottomSheetDetailsRef.current?.dismiss();
            resetForm();
          }}
          onResetPress={() => {
            resetForm();
          }}
        />
        <BottomSheet.Content>
          <Box padding="xs">
            <Form {...methods}>
              {bottomSheetType === "subnet" ? (
                <Form.Input
                  label={I18n.t("CLIENT_VPN.SUBNET")}
                  name="subnet"
                  rules={{
                    required: I18n.t("CLIENT_VPN.SUBNET_EMPTY"),
                    validate: (subnet: string) => {
                      const addressesCount = getTotalIps(subnet);
                      return isNaN(addressesCount) || !subnet || addressesCount < 0
                        ? I18n.t("CLIENT_VPN.INVALID_SUBNET")
                        : undefined;
                    },
                  }}
                  placeholder="172.16.10.0/24"
                  testID="SUBNET_INPUT"
                />
              ) : (
                <Form.Input
                  label={I18n.t("CLIENT_VPN.SHARED_SECRET.TITLE")}
                  name="sharedSecret"
                  rules={{
                    required: I18n.t("CLIENT_VPN.SHARED_SECRET.EMPTY"),
                    validate: (secret: string) => {
                      return secret.length < 8
                        ? I18n.t("CLIENT_VPN.SHARED_SECRET.INVALID")
                        : undefined;
                    },
                  }}
                  testID="SHARED_SECRET_INPUT"
                />
              )}
            </Form>
          </Box>
          <Box padding="xs">
            <Button text={I18n.t("SAVE")} onPress={methods.handleSubmit(onSave)} />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
      <BottomSheet.Modal ref={DNSbottomSheetDetailsRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.TITLE")}
          onCancelPress={() => {
            DNSbottomSheetDetailsRef?.current?.dismiss();
            resetDNS();
          }}
          onResetPress={() => {
            resetDNS();
          }}
        />
        <BottomSheet.Content>
          <Form {...methods}>
            <List>
              <List.RadioItem
                title={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.GOOGLE")}
                onRadioValueChange={() => methods.setValue("DNSProvider", "google_dns")}
                radioValue={"google_dns"}
                checkedRadioValue={methods.watch("DNSProvider")}
              />
              <List.RadioItem
                title={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.OPEN_DNS")}
                onRadioValueChange={() => methods.setValue("DNSProvider", "opendns")}
                radioValue={"opendns"}
                checkedRadioValue={methods.watch("DNSProvider")}
              />
              <List.RadioItem
                title={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.CUSTOM")}
                onRadioValueChange={() => methods.setValue("DNSProvider", "custom")}
                radioValue={"custom"}
                checkedRadioValue={methods.watch("DNSProvider")}
                description={I18n.t("CLIENT_VPN.NAMESERVERS.DESCRIPTION")}
                testID="CUSTOM_DNS"
              >
                <Form.Input
                  label={I18n.t("CLIENT_VPN.NAMESERVERS.PRIMARY_DNS")}
                  name={"primaryDNS"}
                  placeholder="172.16.10.0"
                  testID="PRIMARY_DNS_INPUT"
                  rules={{
                    required: I18n.t("CLIENT_VPN.NAMESERVERS.EMPTY"),
                    validate: (dns) => {
                      return methods.watch("DNSProvider") !== "custom" || Address4.isValid(dns)
                        ? undefined
                        : I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.INVALID");
                    },
                  }}
                />
                <Box paddingVertical="sm">
                  <Form.Input
                    name={"secondaryDNS"}
                    label={I18n.t("CLIENT_VPN.NAMESERVERS.SECONDARY_DNS")}
                    placeholder="172.16.10.0"
                    rules={{
                      required: I18n.t("CLIENT_VPN.NAMESERVERS.EMPTY"),
                      validate: (dns) => {
                        return methods.watch("DNSProvider") !== "custom" || Address4.isValid(dns)
                          ? undefined
                          : I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.INVALID");
                      },
                    }}
                    testID="SECONDARY_DNS_INPUT"
                  />
                </Box>
              </List.RadioItem>
            </List>
          </Form>
          <Box padding="xs">
            <Button text={I18n.t("SAVE")} onPress={methods.handleSubmit(onDNSProviderSave)} />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
