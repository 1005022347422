import * as magneticBaseTheme from "@magnetic/design-tokens";
import Color from "color";

import { baseTheme } from "../baseTheme";
import { colorPalette } from "../colorPalette";
import { Theme } from "../types";

export const enterpriseDarkTheme: Theme = {
  ...magneticBaseTheme,
  ...baseTheme,

  color: {
    transparent: colorPalette.neutral.transparent,

    default: {
      bg: {
        base: colorPalette.neutral[5],
        disabled: colorPalette.neutral[35],

        weak: {
          base: colorPalette.neutral[15],
        },

        medium: {
          base: colorPalette.neutral[25],
        },

        strong: {
          base: colorPalette.neutral[70],
          hover: colorPalette.neutral[80],
          focus: colorPalette.neutral[80],
          active: colorPalette.neutral[80],
          disabled: colorPalette.neutral[40],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[50],
      },

      text: {
        base: colorPalette.neutral[98],
        disabled: colorPalette.neutral[50],

        weak: {
          base: colorPalette.neutral[60],
          disabled: colorPalette.neutral[50],
        },

        medium: {
          base: colorPalette.neutral[85],
          disabled: colorPalette.neutral[50],
        },

        strong: {
          base: colorPalette.neutral[95],
          disabled: colorPalette.neutral[50],
        },
      },

      textIn: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],

        weak: {
          base: colorPalette.neutral[98],
        },
      },
      border: {
        base: colorPalette.neutral[40],
        weak: {
          base: colorPalette.neutral[15],
        },
        medium: {
          base: colorPalette.neutral[35],
        },
        strong: {
          base: colorPalette.neutral[80],
        },
      },
    },

    interact: {
      bg: {
        base: colorPalette.interact[65],
        hover: colorPalette.interact[70],
        focus: colorPalette.interact[70],
        active: colorPalette.interact[70],
        disabled: Color(colorPalette.interact[65]).mix(Color(colorPalette.neutral[15]), 0.5).hex(), // SPECIAL??

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[25],
          focus: colorPalette.neutral[25],
          active: colorPalette.neutral[25],
          disabled: colorPalette.neutral[25],
        },

        medium: {
          base: colorPalette.interact[50],
        },
      },

      text: {
        base: colorPalette.interact[65],
        hover: colorPalette.interact[70],
        focus: colorPalette.interact[70],
        active: colorPalette.interact[70],
        disabled: Color(colorPalette.interact[65]).mix(Color(colorPalette.neutral[15]), 0.5).hex(), // SPECIAL??

        weak: {
          base: colorPalette.neutral[80],
          hover: colorPalette.interact[65],
          focus: colorPalette.interact[65],
          active: colorPalette.interact[65],
          disabled: colorPalette.neutral[45],
        },
      },

      textIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      icon: {
        base: colorPalette.interact[65],
        hover: colorPalette.interact[70],
        focus: colorPalette.interact[70],
        active: colorPalette.interact[70],
        disabled: Color(colorPalette.interact[65]).mix(Color(colorPalette.neutral[15]), 0.5).hex(), // SPECIAL??

        weak: {
          base: colorPalette.neutral[80],
          hover: colorPalette.neutral[85],
          focus: colorPalette.neutral[85],
          active: colorPalette.neutral[85],
          disabled: colorPalette.neutral[50],
        },

        medium: {
          base: colorPalette.neutral[55],
          hover: colorPalette.interact[50],
          focus: colorPalette.interact[50],
          active: colorPalette.interact[50],
          disabled: colorPalette.neutral[80],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.interact[65],
        hover: colorPalette.interact[70],
        focus: colorPalette.interact[70],
        active: colorPalette.interact[70],
        disabled: Color(colorPalette.interact[65]).mix(Color(colorPalette.neutral[15]), 0.5).hex(), // SPECIAL??

        weak: {
          base: colorPalette.neutral[35],
          hover: colorPalette.interact[90],
          focus: colorPalette.neutral[90],
          active: colorPalette.neutral[90],
          disabled: colorPalette.neutral[90],
        },

        medium: {
          base: colorPalette.interact[50],
          hover: colorPalette.interact[85],
          focus: colorPalette.interact[50],
          active: colorPalette.interact[50],
          disabled: colorPalette.neutral[90],
        },
      },
    },

    control: {
      bg: {
        base: colorPalette.neutral[15],
        hover: Color(colorPalette.neutral[15]).mix(Color(colorPalette.interact[65]), 0.1).hex(), // SPECIAL?
        focus: Color(colorPalette.neutral[15]).mix(Color(colorPalette.interact[65]), 0.1).hex(), // SPECIAL?
        active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.interact[65]), 0.1).hex(), // SPECIAL?
        disabled: colorPalette.neutral[25],

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[25],
          focus: colorPalette.neutral[15],
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.interact[65]), 0.1).hex(), // SPECIAL?
          disabled: colorPalette.neutral[25],
        },

        medium: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[25],
          focus: colorPalette.neutral[65],
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.interact[65]), 0.1).hex(), // SPECIAL?
          disabled: colorPalette.neutral[35],
        },

        strong: {
          base: colorPalette.interact[60],
          hover: colorPalette.interact[65],
          focus: colorPalette.interact[65],
          active: colorPalette.interact[65],
          disabled: colorPalette.neutral[40],
        },
      },

      icon: {
        base: colorPalette.interact[65],
        hover: colorPalette.interact[60],
        focus: colorPalette.interact[60],
        active: colorPalette.interact[60],
        disabled: colorPalette.interact[90],

        weak: {
          base: colorPalette.neutral[80],
          hover: colorPalette.neutral[85],
          focus: colorPalette.neutral[85],
          active: colorPalette.neutral[85],
          disabled: colorPalette.neutral[50],
        },

        medium: {
          base: colorPalette.interact[70],
          hover: colorPalette.neutral[65],
          focus: colorPalette.neutral[65],
          active: colorPalette.neutral[60],
          disabled: colorPalette.neutral[90],
        },

        strong: {
          base: colorPalette.neutral[80],
          hover: colorPalette.neutral[70],
          focus: colorPalette.neutral[70],
          active: colorPalette.neutral[65],
          disabled: colorPalette.neutral[50],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[50],

        strong: {
          base: colorPalette.interact[50],
          hover: colorPalette.interact[50],
          focus: colorPalette.interact[50],
          active: colorPalette.interact[50],
          disabled: colorPalette.neutral[80],
        },
      },

      border: {
        base: colorPalette.neutral[50],
        hover: colorPalette.interact[70],
        focus: colorPalette.interact[70],
        active: colorPalette.interact[70],
        disabled: colorPalette.neutral[40],

        weak: {
          base: colorPalette.neutral[50],
          hover: colorPalette.interact[70],
          focus: colorPalette.interact[70],
          active: colorPalette.interact[70],
          disabled: colorPalette.neutral[40],
        },

        medium: {
          base: colorPalette.interact[50],
          hover: colorPalette.interact[85],
          focus: colorPalette.interact[50],
          active: colorPalette.interact[50],
          disabled: colorPalette.neutral[85],
        },

        strong: {
          base: colorPalette.neutral[45],
          hover: colorPalette.neutral[45],
          focus: colorPalette.neutral[45],
          active: colorPalette.neutral[45],
          disabled: colorPalette.neutral[40],
        },
      },
    },

    positive: {
      bg: {
        base: colorPalette.positive[70], // Positive5?
        hover: colorPalette.positive[70], // Positive5?
        focus: colorPalette.positive[70], // Positive5?
        active: colorPalette.positive[70], // Positive5?
        disabled: colorPalette.positive[70], // Positive5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[15],
        },

        medium: {
          base: Color(colorPalette.neutral[15]).mix(Color(colorPalette.positive[70]), 0.3).hex(), // Positive5? Special?
          hover: Color(colorPalette.neutral[15]).mix(Color(colorPalette.positive[70]), 0.3).hex(), // Positive5? Special?
          focus: Color(colorPalette.neutral[15]).mix(Color(colorPalette.positive[70]), 0.3).hex(), // Positive5? Special?
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.positive[70]), 0.3).hex(), // Positive5? Special?
          disabled: colorPalette.neutral[25],
        },

        strong: {
          base: Color(colorPalette.neutral[5]).mix(Color(colorPalette.positive[70]), 0.22).hex(), // Positive5? Special?
        },
      },

      icon: {
        base: colorPalette.positive[70], // Positive5?
        hover: colorPalette.positive[70], // Positive5?
        focus: colorPalette.positive[70], // Positive5?
        active: colorPalette.positive[70], // Positive5?
        disabled: colorPalette.neutral[40],

        weak: {
          base: colorPalette.positive[70], // Positive5?
          hover: colorPalette.positive[70], // Positive5?
          focus: colorPalette.positive[70], // Positive5?
          active: colorPalette.positive[70], // Positive5?
          disabled: colorPalette.neutral[40],
        },

        strong: {
          base: colorPalette.positive[70], // Positive5?
          hover: colorPalette.positive[70], // Positive5?
          focus: colorPalette.positive[70], // Positive5?
          active: colorPalette.positive[70], // Positive5?
          disabled: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.positive[70]), 0.5)
            .hex(), // Positive5? Special?
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.positive[70], // Positive5?
        hover: colorPalette.positive[70], // Positive5?
        focus: colorPalette.positive[70], // Positive5?
        active: colorPalette.positive[70], // Positive5?
        disabled: colorPalette.positive[70], // Positive5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.positive[70], // Positive5?
          focus: colorPalette.positive[70], // Positive5?
          active: colorPalette.positive[70], // Positive5?
          disabled: colorPalette.neutral[40],
        },

        strong: {
          base: colorPalette.neutral[98],
          hover: colorPalette.neutral[98],
          focus: colorPalette.neutral[98],
          active: colorPalette.neutral[98],
          disabled: colorPalette.neutral[98],
        },
      },

      textIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },
    },

    negative: {
      bg: {
        base: colorPalette.negative[65], // Negative6?
        hover: colorPalette.negative[70], // Negative5?
        focus: colorPalette.negative[70], // Negative5?
        active: colorPalette.negative[70], // Negative5?
        disabled: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?

        weak: {
          base: colorPalette.neutral[15],
          hover: Color(colorPalette.neutral[25]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?
          focus: Color(colorPalette.neutral[25]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?
          active: Color(colorPalette.neutral[25]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?
          disabled: colorPalette.neutral[25],
        },

        medium: {
          base: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.3).hex(), // Negative6? Special?
          hover: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.3).hex(), // Negative6? Special?
          focus: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.3).hex(), // Negative6? Special?
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.3).hex(), // Negative6? Special?
          disabled: colorPalette.neutral[25],
        },

        strong: {
          base: Color(colorPalette.neutral[5]).mix(Color(colorPalette.negative[65]), 0.23).hex(), // Negative6? Special?
        },
      },

      text: {
        base: colorPalette.negative[65], // Negative6?
        hover: colorPalette.negative[80], // Negative4?
        focus: colorPalette.negative[80], // Negative4?
        active: colorPalette.negative[80], // Negative4?
        disabled: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?
      },

      textIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[5],
        focus: colorPalette.neutral[5],
        active: colorPalette.neutral[5],
        disabled: colorPalette.neutral[15],
      },

      icon: {
        base: colorPalette.negative[65], // Negative6?
        hover: colorPalette.negative[80], // Negative4?
        focus: colorPalette.negative[80], // Negative4?
        active: colorPalette.negative[80], // Negative4?
        disabled: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?

        weak: {
          base: colorPalette.negative[70], // Negative5?
          hover: colorPalette.negative[70], // Negative5?
          focus: colorPalette.negative[70], // Negative5?
          active: colorPalette.negative[70], // Negative5?
          disabled: colorPalette.neutral[40],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.negative[65], // Negative6?
        hover: colorPalette.negative[80], // Negative4?
        focus: colorPalette.negative[80], // Negative4?
        active: colorPalette.negative[80], // Negative4?
        disabled: Color(colorPalette.neutral[15]).mix(Color(colorPalette.negative[65]), 0.5).hex(), // Negative6? Special?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[40],
        },

        medium: {
          base: colorPalette.negative[65], // Negative6?
          hover: colorPalette.negative[80], // Negative4?
          focus: colorPalette.negative[80], // Negative4?
          active: colorPalette.negative[80], // Negative4?
          disabled: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.negative[65]), 0.6)
            .hex(), // Negative6? Special?
        },

        strong: {
          base: colorPalette.neutral[98],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[98],
          active: colorPalette.neutral[98],
          disabled: colorPalette.neutral[98],
        },
      },
    },

    severeWarning: {
      bg: {
        base: colorPalette.severeWarning[65],
        hover: colorPalette.severeWarning[65],
        focus: colorPalette.severeWarning[65],
        active: colorPalette.severeWarning[65],
        disabled: colorPalette.severeWarning[65],

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[15],
        },

        medium: {
          base: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.severeWarning[70]), 0.3)
            .hex(), // Special?
          hover: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.severeWarning[70]), 0.3)
            .hex(), // Special?
          focus: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.severeWarning[70]), 0.3)
            .hex(), // Special?
          active: Color(colorPalette.neutral[15])
            .mix(Color(colorPalette.severeWarning[70]), 0.3)
            .hex(), // Special?
          disabled: colorPalette.neutral[25],
        },

        strong: {
          base: Color(colorPalette.neutral[5])
            .mix(Color(colorPalette.severeWarning[65]), 0.22)
            .hex(), // Special?
        },
      },

      icon: {
        base: colorPalette.severeWarning[65],
        hover: colorPalette.severeWarning[65],
        focus: colorPalette.severeWarning[65],
        active: colorPalette.severeWarning[65],
        disabled: colorPalette.neutral[40],

        strong: {
          base: colorPalette.severeWarning[70],
          hover: colorPalette.severeWarning[70],
          focus: colorPalette.severeWarning[70],
          active: colorPalette.severeWarning[70],
          disabled: colorPalette.severeWarning[70],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.severeWarning[65],
        hover: colorPalette.severeWarning[65],
        focus: colorPalette.severeWarning[65],
        active: colorPalette.severeWarning[65],
        disabled: colorPalette.severeWarning[65],

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[40],
        },
      },
    },

    warning: {
      bg: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.warning[70], // Warning5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[15],
        },

        medium: {
          base: Color(colorPalette.neutral[15]).mix(Color(colorPalette.warning[70]), 0.3).hex(), // Warning5? Special?
          hover: Color(colorPalette.neutral[15]).mix(Color(colorPalette.warning[70]), 0.3).hex(), // Warning5? Special?
          focus: Color(colorPalette.neutral[15]).mix(Color(colorPalette.warning[70]), 0.3).hex(), // Warning5? Special?
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.warning[70]), 0.3).hex(), // Warning5? Special?
          disabled: colorPalette.neutral[25],
        },

        strong: {
          base: Color(colorPalette.neutral[5]).mix(Color(colorPalette.warning[70]), 0.17).hex(), // Warning5? Special?
        },
      },

      icon: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.neutral[40],

        strong: {
          base: colorPalette.warning[70], // Warning5?
          hover: colorPalette.warning[70], // Warning5?
          focus: colorPalette.warning[70], // Warning5?
          active: colorPalette.warning[70], // Warning5?
          disabled: colorPalette.warning[70], // Warning5?
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.warning[70], // Warning5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[40],
        },

        strong: {
          base: colorPalette.neutral[98],
          hover: colorPalette.neutral[98],
          focus: colorPalette.neutral[98],
          active: colorPalette.neutral[98],
          disabled: colorPalette.neutral[98],
        },
      },

      textIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },
    },

    info: {
      bg: {
        base: colorPalette.info[70], // Info5?
        hover: colorPalette.info[70], // Info5?
        focus: colorPalette.info[70], // Info5?
        active: colorPalette.info[70], // Info5?
        disabled: colorPalette.info[70], // Info5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[15],
        },

        medium: {
          base: Color(colorPalette.neutral[15]).mix(Color(colorPalette.info[70]), 0.3).hex(), // Info5? Special?
          hover: Color(colorPalette.neutral[15]).mix(Color(colorPalette.info[70]), 0.3).hex(), // Info5? Special?
          focus: Color(colorPalette.neutral[15]).mix(Color(colorPalette.info[70]), 0.3).hex(), // Info5? Special?
          active: Color(colorPalette.neutral[15]).mix(Color(colorPalette.info[70]), 0.3).hex(), // Info5? Special?
          disabled: colorPalette.neutral[25],
        },

        strong: {
          base: Color(colorPalette.neutral[5]).mix(Color(colorPalette.info[70]), 0.23).hex(), // Info5? Special?
        },
      },

      icon: {
        base: colorPalette.info[70], // Info5?
        hover: colorPalette.info[70], // Info5?
        focus: colorPalette.info[70], // Info5?
        active: colorPalette.info[70], // Info5?
        disabled: colorPalette.neutral[40],

        weak: {
          base: colorPalette.info[70], // Info5?
          hover: colorPalette.info[70], // Info5?
          focus: colorPalette.info[70], // Info5?
          active: colorPalette.info[70], // Info5?
          disabled: colorPalette.neutral[40],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[15],
        focus: colorPalette.neutral[15],
        active: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],
      },

      border: {
        base: colorPalette.info[70], // Info5?
        hover: colorPalette.info[70], // Info5?
        focus: colorPalette.info[70], // Info5?
        active: colorPalette.info[70], // Info5?
        disabled: colorPalette.info[70], // Info5?

        weak: {
          base: colorPalette.neutral[15],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[40],
        },
      },
    },

    accent: {
      a: {
        base: colorPalette.accentA[70],
        hover: colorPalette.accentA[65],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentA[70]), 0.35).hex(), // Special?
      },

      b: {
        base: colorPalette.accentBC[55],
        hover: colorPalette.accentBC[50],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentBC[70]), 0.35).hex(), // Special?
      },

      c: {
        base: colorPalette.accentBC[80],
        hover: colorPalette.accentBC[70],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentBC[80]), 0.35).hex(), // Special?
      },

      d: {
        base: colorPalette.accentD[55],
        hover: colorPalette.accentD[50],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentD[70]), 0.35).hex(), // Special?
      },

      e: {
        base: colorPalette.accentE[70],
        hover: colorPalette.accentE[65],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentE[70]), 0.35).hex(), // Special?
      },

      f: {
        base: colorPalette.accentF[55],
        hover: colorPalette.accentF[50],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentF[70]), 0.35).hex(), // Special?
      },

      g: {
        base: colorPalette.accentGH[80],
        hover: colorPalette.accentGH[70],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentGH[70]), 0.35).hex(), // Special?
      },

      h: {
        base: colorPalette.accentGH[55],
        hover: colorPalette.accentGH[50],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentGH[85]), 0.35).hex(), // Special?
      },

      i: {
        base: colorPalette.accentI[70],
        hover: colorPalette.accentI[65],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentI[70]), 0.35).hex(), // Special?
      },

      j: {
        base: colorPalette.accentJ[55],
        hover: colorPalette.accentJ[50],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentJ[70]), 0.35).hex(), // Special?
      },

      k: {
        base: colorPalette.accentK[70],
        hover: colorPalette.accentK[65],
        weak: Color(colorPalette.neutral[15]).mix(Color(colorPalette.accentK[70]), 0.35).hex(), // Special?
      },
    },

    dormant: {
      bg: {
        base: colorPalette.neutral[50],
        disabled: colorPalette.neutral[50],

        medium: {
          base: colorPalette.neutral[35],
          disabled: colorPalette.neutral[25],
        },
      },

      icon: {
        base: colorPalette.neutral[80],
        disabled: colorPalette.neutral[40],

        medium: {
          base: colorPalette.neutral[100],
        },
      },

      iconIn: {
        base: colorPalette.neutral[15],
        disabled: colorPalette.neutral[15],

        medium: {
          base: colorPalette.neutral[15],
        },
      },

      border: {
        base: colorPalette.neutral[100],
        disabled: colorPalette.neutral[85],
      },
    },

    inverse: {
      bg: {
        base: colorPalette.neutral[25],
        hover: colorPalette.neutral[25],
        focus: colorPalette.neutral[35],
        active: colorPalette.neutral[35],
        disabled: colorPalette.neutral[90],

        gradient: {
          start: colorPalette.neutral[25],
          end: colorPalette.neutral[5],
        },

        weak: {
          base: colorPalette.neutral[35],
        },
      },

      text: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[98],
        focus: colorPalette.neutral[98],
        active: colorPalette.neutral[98],
        disabled: colorPalette.neutral[45],

        medium: {
          base: colorPalette.neutral[80],
          disabled: colorPalette.neutral[45],
        },
      },

      icon: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[50],

        strong: {
          base: colorPalette.neutral[98],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[50],
        },
      },

      border: {
        base: colorPalette.neutral[50],
        hover: colorPalette.interact[65],
        focus: colorPalette.interact[90],
        active: colorPalette.interact[90],
        disabled: colorPalette.neutral[90],
      },
    },

    brandAccent: {
      icon: {
        base: colorPalette.neutral[80],
        focus: colorPalette.neutral[100],
        active: colorPalette.green[55], // Brand Color!
        disabled: colorPalette.neutral[50],

        weak: {
          base: colorPalette.neutral[50],
          focus: colorPalette.neutral[100],
          active: colorPalette.green[70], // Brand Color!
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.neutral[65],
          focus: colorPalette.neutral[100],
          active: colorPalette.green[60], // Brand Color!
          disabled: colorPalette.neutral[100],
        },
      },

      border: {
        base: colorPalette.green[60], // Brand Color!
        hover: colorPalette.neutral[55],
        focus: colorPalette.green[60], // Brand Color!
        active: colorPalette.green[60], // Brand Color!

        weak: {
          base: colorPalette.neutral[85],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.green[70], // Brand Color!
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.neutral[40],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.green[95], // Brand Color!
          disabled: colorPalette.neutral[100],
        },

        strong: {
          base: colorPalette.neutral[5],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[5],
          disabled: colorPalette.neutral[100],
        },
      },
    },

    brand: {
      bg: {
        base: colorPalette.neutral[15],
        hover: colorPalette.neutral[25],
        focus: colorPalette.neutral[25],
        active: colorPalette.neutral[25],
        disabled: colorPalette.neutral[90],

        gradient: {
          start: colorPalette.neutral[98],
          end: colorPalette.neutral[90],
        },
      },

      text: {
        base: colorPalette.neutral[100],

        weak: {
          base: colorPalette.neutral[100],
        },

        strong: {
          base: colorPalette.neutral[100],
        },
      },
    },

    indoorAirQuality: {
      ...colorPalette.indoorAirQuality,
      excellent: colorPalette.indoorAirQuality.excellentDtmaly,
      good: colorPalette.indoorAirQuality.goodDtmaly,
    },

    attributeSearch: colorPalette.attributeSearch,
  },

  mode: "dark",
};
