import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { BioAuthTypes } from "~/lib/BioAuth";
import { platformSelect } from "~/lib/PlatformUtils";
import { getAskForBioAuthType, getHideAskForBioAuth } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import SlideUpContainer from "~/shared/components/SlideUpContainer";
import useActions from "~/shared/hooks/redux/useActions";
import ListRow from "~/shared/rows/ListRow";

interface AskForBioAuthBannerProps {
  shouldHideWhatsNew: boolean;
}

function AskForBioAuthBanner({ shouldHideWhatsNew }: AskForBioAuthBannerProps) {
  const actions = useActions();
  const navigation = useNavigation();
  const askForBioAuthType: BioAuthTypes | undefined = useSelector(getAskForBioAuthType);
  const hideAskForBioAuth = useSelector(getHideAskForBioAuth);

  const onPress = useCallback(() => {
    navigation.navigate("Account");
    actions.hideAskForBioAuth();
  }, [navigation, actions]);

  const showAskForBioAuth = askForBioAuthType && shouldHideWhatsNew && !hideAskForBioAuth;
  if (!showAskForBioAuth) {
    return null;
  }

  const chevronRight = <MerakiIcon name="chevron-right" size="s" color={MkiColors.primaryButton} />;

  const askText = platformSelect({
    ios:
      askForBioAuthType === BioAuthTypes.faceId
        ? I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.FACE_ID")
        : I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.TOUCH_ID"),
    android: I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.ANDROID_AUTH"),
  });

  return (
    <SlideUpContainer screenStyles={styles.containerStyle}>
      <ListRow
        onPress={onPress}
        rowStyles={styles.rowStyle}
        accessory={chevronRight}
        underlayColor={MkiColors.primaryButtonLight}
      >
        <MkiText textStyle="activeTab">{askText}</MkiText>
      </ListRow>
    </SlideUpContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderTopColor: MkiColors.goPurple,
    borderTopWidth: SPACING.tiny,
  },
  rowStyle: {
    backgroundColor: MkiColors.primaryButtonLight,
    paddingHorizontal: SPACING.default,
  },
});

export default AskForBioAuthBanner;
