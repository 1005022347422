import { Empty, EmptyProps } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";

import { Button, ButtonProps } from "../Button/Button";
import { Heading } from "../Heading/Heading";
import { Text } from "../Text/Text";

export type EmptyStateProps = {
  status?: EmptyProps["status"];
  title?: string;
  description?: string;
  action?: ButtonProps;
};

export function EmptyState({
  status = "informational",
  title,
  description,
  action,
}: EmptyStateProps) {
  return (
    <Box
      flex={1}
      backgroundColor="default.bg.base"
      justifyContent="center"
      alignItems="center"
      testID="EMPTY_STATE"
    >
      <Empty status={status} />
      {!!title && (
        <Heading size="h2" textAlign="center">
          {title}
        </Heading>
      )}
      {!!description && (
        <Text size="p2" color="light" textAlign="center">
          {description}
        </Text>
      )}
      {!!action && (
        <Box marginVertical="md">
          <Button {...action} />
        </Box>
      )}
    </Box>
  );
}
