import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { BottomSheet, Button, Input, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { queryClient, useDeleteSsid, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import { Alert } from "react-native";

import { useSSIDContext } from "../screens/SSIDContext";

interface EditBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export function EditBottomSheet({ bottomSheetRef }: EditBottomSheetProps) {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const { ssid } = useSSIDContext();
  const { mutate: updateSsid } = useUpdateSsid();
  const { mutate: deleteSsid } = useDeleteSsid();
  const [ssidName, setSsidName] = useState<string | undefined>(ssid?.name);
  const networkId = useCurrentNetworkId();

  if (!ssid || !ssidName) {
    return (
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <Loader.Spinner animate={true} />
      </BottomSheet.Modal>
    );
  }

  const onDelete = () => {
    deleteSsid(
      {
        networkId,
        ssidNumber: ssid.number.toString(),
      },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },

        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useSsids.queryKeyRoot });
          bottomSheetRef?.current?.dismiss();
        },
        onSuccess: () => {
          Alert.alert(I18n.t("SSID_DETAILS.DELETE_SUCCESS"));
          navigation.goBack();
        },
      },
    );
  };

  const onSave = () => {
    updateSsid(
      {
        networkId,
        ssidNumber: ssid.number,
        body: { ...ssid, name: ssidName },
      },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
        onSuccess: () => {
          Alert.alert(I18n.t("SSID_DETAILS.EDIT_SCREEN.RENAME_SUCCESS"));
        },
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: useSsids.queryKeyRoot });
          bottomSheetRef?.current?.dismiss();
        },
      },
    );
  };

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("SSID_DETAILS.EDIT_SCREEN.TITLE")}
        cancelLabel={I18n.t("CANCEL")}
        onCancelPress={() => {
          setSsidName(ssid.name);
          bottomSheetRef?.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Box paddingBottom="sm">
          <Input
            label={I18n.t("SSID_DETAILS.EDIT_SCREEN.LABEL")}
            value={ssidName}
            onChangeText={setSsidName}
            testID="EDIT_SSID_NAME_INPUT"
          />
        </Box>
        <Box paddingBottom="sm">
          <Button
            text={I18n.t("SAVE")}
            onPress={onSave}
            disabled={ssidName === ssid.name}
            testID="SAVE_BUTTON"
          />
        </Box>
        <Button
          text={I18n.t("SSID_DETAILS.DELETE_SSID")}
          kind="secondaryDestructive"
          onPress={async () => {
            showDeleteConfirmAlert(
              I18n.t("SSID_DETAILS.CONFIRMATION"),
              I18n.t("SSID_DETAILS.DELETE_CONFIRMATION"),
              onDelete,
            );
          }}
          testID="DELETE_BUTTON"
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
