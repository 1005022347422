import MkiColors from "~/constants/MkiColors";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize } from "~/lib/themeHelper";

export const chartHeight = 300;
// Slightly magic number to position the label in the y direction
export const noDataLabelYOffset = chartHeight / 2 - 32;

export const lineGraphTheme = {
  chart: {
    padding: {
      top: appSelect({
        enterprise: 0,
        go: 20,
      }),
      right: 15,
      bottom: 30,
      left: appSelect({
        enterprise: 65,
        go: 55,
      }),
    },
    height: chartHeight,
  },
  area: {
    padding: 0,
    style: {
      data: {
        fill: MkiColors.primaryGraphLine,
        fillOpacity: 0.15,
        stroke: MkiColors.primaryGraphLine,
        strokeWidth: 2,
      },
    },
  },
  axis: {
    style: {
      grid: {
        fill: "transparent",
        stroke: "transparent",
      },
      tickLabels: {
        fontFamily: "ciscosans",
        fontSize: appSelect({
          enterprise: 10,
          go: platformSelect({
            mobile: normalizedFontSize(10),
            web: normalizedFontSize(20),
          }),
        }),
        fill: MkiColors.secondaryTextColor,
        padding: platformSelect({
          mobile: 5,
          web: 0,
        }),
      },
    },
  },
  label: {
    textAnchor: "middle",
    verticalAnchor: "middle",
  },
};

export const mkiChartTheme = {
  chart: {
    padding: {
      top: 20,
      right: 15,
      bottom: 30,
      left: 40,
    },
    height: chartHeight,
  },
  axis: {
    style: {
      grid: {
        fill: "transparent",
        stroke: "transparent",
      },
      tickLabels: {
        fontFamily: "ciscosans",
        fontSize: appSelect({
          enterprise: 10,
          go: platformSelect({
            mobile: normalizedFontSize(10),
            web: normalizedFontSize(20),
          }),
        }),
        fill: MkiColors.secondaryTextColor,
        padding: 5,
      },
    },
  },
  label: {
    textAnchor: "middle",
    verticalAnchor: "middle",
  },
};
