import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Card, Loader, Tag, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { queryClient, SwitchPort, useSwitchPorts, useUpdateSwitchPort } from "@meraki/shared/api";
import { useForm } from "@meraki/shared/form";
import { Alert } from "react-native";

import { useSwitchPortDetails } from "../screens/ports/SwitchPortDetailsContext";

type EditTagsForm = {
  tags: string[];
};
interface DeleteTagSwitchPortBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export const DeleteTagSwitchPortBottomSheet = ({
  bottomSheetRef,
}: DeleteTagSwitchPortBottomSheetProps) => {
  const { switchPort, device } = useSwitchPortDetails();

  const { mutate: updateSwitchPort, isLoading } = useUpdateSwitchPort();

  const methods = useForm<EditTagsForm>({
    values: { tags: switchPort?.tags ?? [] },
  });

  const watchedTags = methods.watch("tags");

  if (!switchPort) {
    return <Loader.Spinner animate={true} />;
  }

  const donePress = () => {
    methods.reset();
    bottomSheetRef?.current?.close();
  };

  const onSave = (formData: EditTagsForm) => {
    updateSwitchPort(
      {
        serial: device?.serial,
        portId: switchPort.portId,
        switchPort: { tags: formData.tags } as SwitchPort,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: useSwitchPorts.queryKey({ serial: device?.serial }),
          });
          bottomSheetRef?.current?.close();
        },
        onError(error) {
          Alert.alert(I18n.t("ERROR"), String(error.errors));
        },
      },
    );
  };
  const onSubmit = methods.handleSubmit((data) => onSave(data));

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("PORTS.GOv3.TAGS.DELETE_LABEL")}
        onCancelPress={donePress}
        cancelLabel={I18n.t("CANCEL")}
      />
      <BottomSheet.Content>
        <Box gap="sm">
          {switchPort.tags.length > 0 && (
            <Card label={I18n.t("PORTS.GOv3.TAGS.DELETE_LABEL")}>
              <Box flexDirection="row" gap="sm" flexWrap="wrap">
                {watchedTags.length > 0 ? (
                  watchedTags.map((tag: string) => (
                    <Tag
                      type="removable"
                      onPress={() => {
                        const newTagList = methods
                          .getValues("tags")
                          .filter((curTag) => curTag !== tag);
                        methods.setValue("tags", newTagList, { shouldDirty: true });
                      }}
                      key={tag}
                      testID={`REMOVABLE_TAG.${tag}`}
                    >
                      {tag}
                    </Tag>
                  ))
                ) : (
                  <Text>{I18n.t("PORTS.GOv3.TAGS.DELETE_EMPTY_TEXT")}</Text>
                )}
              </Box>
            </Card>
          )}
          <Button
            text={I18n.t("SAVE")}
            onPress={onSubmit}
            disabled={!methods.formState.isDirty}
            loading={queryClient.isFetching() > 0 || isLoading}
            testID="SAVE_DELETED_TAGS_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
};
