import { AdapterProvider } from "@meraki/magnetic/adapter";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { ReactNode } from "react";

import { getAlphaMagneticThemeFeature } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

export interface MagneticProviderProps {
  children: ReactNode;
  fullRollout?: boolean;
}

export function MagneticProvider({ children, fullRollout }: MagneticProviderProps) {
  const magneticAlphaEnabled = useAppSelector(getAlphaMagneticThemeFeature);

  const { value: haltFullRollout } = useBoolFlag("ld", "magnetic-halt-full-rollout");

  let magneticEnabled = false;

  if (!__MERAKI_GO__) {
    // If we are Enterprise then Magnetic is just on.... FOREVER!
    magneticEnabled = true;
  } else if (fullRollout) {
    // If the app is in full rollout we ignore the alpha setting and rely only on the remote config.
    magneticEnabled = !haltFullRollout;
  } else {
    magneticEnabled = magneticAlphaEnabled;
  }

  return <AdapterProvider magneticEnabled={magneticEnabled}>{children}</AdapterProvider>;
}
