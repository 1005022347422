import { I18n } from "@meraki/core/i18n";
import { DeviceAvailability } from "@meraki/shared/api";
import {
  getIndoorOrOutdoor,
  getProductType,
  INDOOR,
  INDOOR_APS,
  OUTDOOR,
  OUTDOOR_APS,
} from "@meraki/shared/devices";
import { isEmpty } from "lodash";

import { Device as DeviceSchema } from "~/api/schemas/Device";
import { NAME_VALIDATION_REGEX } from "~/constants/MkiConstants";
import { getDuplicatesForArray } from "~/lib/arrayHelper";
import { nestedValueExists } from "~/lib/objectHelper";
import { appSelect } from "~/lib/PlatformUtils";
import { getSwitchLayout } from "~/lib/SwitchUtils";
import { isString } from "~/lib/TypeHelper";
import { DeviceStatus, StatusType } from "~/shared/constants/Status";
import Device, { DeviceAlert, DeviceDetails, DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";
import Tags from "~/shared/types/Tags";

const LLDP_WIRED_FIRST_PORT = "0";
const NON_DIGIT_REGEX = new RegExp("\\D", "gmi");
const GX20_REGEX = new RegExp("^Q2UN", "i");
const GX50_REGEX = new RegExp("^Q3JA", "i");

export const hardwareModels = [
  "MS120-8",
  "GS110-8",
  "MS120-8LP",
  "GS110-8P",
  "MS120-24",
  "GS110-24",
  "MS120-24P",
  "GS110-24P",
  "MS120-48",
  "GS110-48",
  "MS120-48LP",
  "GS110-48P",
  "Z1",
  "GX20",
  "GX50",
  "GR10",
  "GR60",
];

const go8PortSwitch = ["MS120-8", "GS110-8"];
const go8PortPoESwitch = ["MS120-8LP", "GS110-8P"];

const go24PortSwitch = ["MS120-24", "GS110-24"];
const go24PortPoESwitch = ["MS120-24P", "GS110-24P"];

const go48PortSwitch = ["MS120-48", "GS110-48"];
const go48PortPoESwitch = ["MS120-48LP", "GS110-48P"];

const goOldSecurityGateway = ["Z1", "GX20"];
const goNewSecurityGateway = ["GX50"];

const macAddressDelimiters = /(:|-)/g;
export const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

export function getTrueModel(model: string, ignorePoE = false) {
  if (INDOOR_APS.includes(model)) {
    return model;
  }
  if (OUTDOOR_APS.includes(model)) {
    return model;
  }
  if (go8PortSwitch.includes(model)) {
    return "GS110-8";
  }
  if (go8PortPoESwitch.includes(model)) {
    return ignorePoE ? "GS110-8" : "GS110-8P";
  }
  if (go24PortSwitch.includes(model)) {
    return "GS110-24";
  }
  if (go24PortPoESwitch.includes(model)) {
    return ignorePoE ? "GS110-24" : "GS110-24P";
  }
  if (go48PortSwitch.includes(model)) {
    return "GS110-48";
  }
  if (go48PortPoESwitch.includes(model)) {
    return ignorePoE ? "GS110-48" : "GS110-48P";
  }
  if (goOldSecurityGateway.includes(model)) {
    return "GX20";
  }
  if (goNewSecurityGateway.includes(model)) {
    return "GX50";
  }
  return null;
}

// LED placement varies by device type. The distinct types present in GO HW are
// - indoor AP (front)
// - outdoor AP (bottom)
// - switch (front)
// - security gateway (front)
export function getHardwareTypeForLED(model: string) {
  const wirelessType = getIndoorOrOutdoor(model);
  if (wirelessType) {
    return wirelessType;
  }
  return getProductType(model);
}

export function getIndoorOrOutdoorFromSerial(serial: string) {
  // part_code is a value in our db that is used in lib/serial_support.rb
  // this value helps us know if a wireless device is indoor or outdoor type
  const partCode = serial.substring(1, 4);
  if (partCode === "2VD") {
    return INDOOR;
  }
  if (partCode === "2WD") {
    return OUTDOOR;
  }
  return null;
}

// part_code is a value in our db that is used in lib/serial_support.rb
export const isGX20BySerial = (serial: string) => !!serial && GX20_REGEX.test(serial);
export const isGX50BySerial = (serial: string) => !!serial && GX50_REGEX.test(serial);
export const isApplianceBySerial = (serial: string) =>
  isGX20BySerial(serial) || isGX50BySerial(serial);

export function isGXModel({ model }: Device) {
  return getProductType(model) === ProductType.appliance;
}

export function isGX20(device?: Device) {
  if (!device) {
    return false;
  }
  const { model } = device;
  return getTrueModel(model) === "GX20";
}

export function isGX50(device?: Device) {
  if (!device) {
    return false;
  }
  const { model } = device;
  return getTrueModel(model) === "GX50";
}

export function isIPv6Capable(device?: Device) {
  if (!device) {
    return false;
  }
  if (isGX50(device)) {
    const { firmware } = device;
    if (firmware) {
      const cleanedVersion = firmware.split("-")[1] ?? "0";
      const FirmwareVersionNumber = parseInt(cleanedVersion);
      if (FirmwareVersionNumber >= 17) {
        return true;
      }
    }
  }

  return false;
}

export const sortGoDeviesByProduct = (deviceA: Device, deviceB: Device) => {
  const orderOfDevices = ["GX", "GS", "GR"];
  const idxA = orderOfDevices.indexOf(getDeviceModelPrefix(deviceA.model));
  const idxB = orderOfDevices.indexOf(getDeviceModelPrefix(deviceB.model));

  if (idxA == idxB) {
    const nameA = deviceA.name || deviceA.serial;
    const nameB = deviceB.name || deviceB.serial;
    if (!deviceA.name && deviceB.name) {
      return 1;
    }
    if (!deviceB.name && deviceA.name) {
      return -1;
    }

    return nameA < nameB ? -1 : 1;
  }
  return idxA - idxB;
};

export const getDeviceModelPrefix = (modelName: string): string => {
  const modelRootPattern = /^[a-zA-Z]{2}/gm;
  const modelRootMatches = modelName?.match(modelRootPattern);
  return modelRootMatches ? modelRootMatches[0] : "";
};

export const DEVICE_CONFIG = {
  unstarted: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.UNSTARTED.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.UNSTARTED.MESSAGE"),
    timeout: 300000, // 5 minutes
  },
  initializing: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.INITIALIZING.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.INITIALIZING.MESSAGE"),
  },
  initializingFailed: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.INITIALIZING_FAILED.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.INITIALIZING_FAILED.MESSAGE"),
  },
  upgrading: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.UPGRADING.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.UPGRADING.MESSAGE"),
    timeout: 420000, // 7 minutes
  },
  upgradingFailed: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.UPGRADE_FAILED.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.UPGRADE_FAILED.MESSAGE"),
  },
  connecting: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.CONNECTING.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.CONNECTING.MESSAGE"),
    timeout: 300000, // 5 minutes
  },
  connectingFailed: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.CONNECTING_FAILED.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.CONNECTING_FAILED.MESSAGE"),
  },
  finished: {
    title: I18n.t("ONBOARDING.DEVICE_STATUSES.FINISHED.TITLE"),
    message: I18n.t("ONBOARDING.DEVICE_STATUSES.FINISHED.MESSAGE"),
  },
};

export const getDeviceStatus = appSelect({
  enterprise: getDeviceStatusEnterprise,
  go: getDeviceStatusGo,
});

export function getDeviceStatusEnterprise(
  device: Device | DeviceAvailability,
): StatusType | undefined {
  if (!device || !device.status) {
    return undefined;
  }

  return DeviceStatus[device.status];
}

export function getDeviceStatusGo(
  device: Device | (DeviceAvailability & { alerts?: DeviceAlert[] }),
): StatusType | undefined {
  if (!device) {
    return undefined;
  }

  const deviceStatus = device.status;
  if (deviceStatus) {
    return DeviceStatus[deviceStatus];
  }

  if (device.alerts && nestedValueExists(device, ["alerts", 0, "name"], "") !== "unreachable") {
    return DeviceStatus.alerting;
  }

  return undefined;
}

export function getDeviceLiveStatus(isLive: boolean | undefined, status?: StatusType) {
  if (isLive === undefined) {
    return status;
  }

  if (isLive && status === DeviceStatus.offline) {
    return status;
  }

  return isLive ? DeviceStatus.online : DeviceStatus.dormant;
}

export function isDeviceNameCharactersValid(name: string) {
  if (!NAME_VALIDATION_REGEX.test(name)) {
    return false;
  }
  return true;
}

export function getIsMeshAccessPoint(device: Device) {
  if (!device) {
    return false;
  }

  const productTypeIsWireless = getProductType(device.model) === ProductType.wireless;

  // TODO DM-2465: check with Go if they can also rely on hasLanIP to determine if
  // a node is a gateway or repeater, then remove is_gateway field
  if (device.is_gateway === undefined) {
    const hasLanIP = !!device.lanIp;
    return !hasLanIP && productTypeIsWireless;
  }
  const isGateway = device.is_gateway;
  return !isGateway && productTypeIsWireless;
}

export function stripAlertTags(alert: any) {
  const htmlRegex = /<\/?[\w\s="/.':;#-/]+>/gi;
  return alert.replace(htmlRegex, "");
}

const TRUNCATE_LENGTH = 15;
export const deviceName = <T extends { name?: string; serial?: string }>(
  { name, serial }: T,
  shouldTruncate = false,
) => {
  let result = name || serial || "";

  if (shouldTruncate && result.length > TRUNCATE_LENGTH) {
    result = `${result.substring(0, TRUNCATE_LENGTH - 1)}...`;
  }

  return result;
};

export const enterpriseDeviceName = <T extends { name?: string | null; mac?: string }>({
  name,
  mac,
}: T) => name || mac || "";

export function getDescriptionByDeviceModel(model: string) {
  const deviceProductType = getProductType(model);

  let description = "";
  if (deviceProductType === ProductType.wireless) {
    if (getIndoorOrOutdoor(model) === OUTDOOR) {
      description = I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.OUTDOOR_AP");
    } else {
      description = I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.INDOOR_AP");
    }
  } else if (deviceProductType === ProductType.switch) {
    description = I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.SWITCH", {
      ports: getSwitchLayout(model).blocks.reduce((sum, block) => sum + block.num, 0),
    });
  } else if (deviceProductType === ProductType.appliance) {
    description = I18n.t("ONBOARDING_FULLSTACK.SCANNED_HARDWARE.SECURITY_GATEWAY");
  }

  return description;
}

export function validateNodeAlertName(name: string) {
  return Object.prototype.hasOwnProperty.call(validNodeAlertMap, name);
}

export const isSwitchOrAppliance = (deviceModel: string) => {
  const productType = getProductType(deviceModel);
  return productType === ProductType.appliance || productType === ProductType.switch;
};

export const isValidMacAddress = (hexString?: string) => {
  const MAX_HEX_GROUPS = 3;
  hexString = hexString?.replace(macAddressDelimiters, "");
  const re = new RegExp("[0-9A-Fa-f]{6}", "gmi");
  const numMatches = hexString?.match(re)?.length;
  if (!numMatches || numMatches >= MAX_HEX_GROUPS) {
    return false;
  }
  return true;
};

export const lldpDeviceIdToMac = (deviceId: string) => {
  if (!deviceId) {
    return null;
  }
  //deviceId from api can also be device system name
  if (!isValidMacAddress(deviceId)) {
    return deviceId;
  }
  return deviceId.replace(/(..?)/g, "$1:").slice(0, -1);
};

const stripNonNumberChars = (inputString: string) => {
  if (!isString(inputString)) {
    return null;
  }
  return inputString.replace(NON_DIGIT_REGEX, "");
};

const mergeCDPLLDP = (cdpLLDPData: any) => {
  if (!cdpLLDPData || isEmpty(cdpLLDPData)) {
    return {};
  }
  const { cdp, lldp, deviceMac } = cdpLLDPData;
  const connectedDeviceId = cdp?.deviceId;
  const portId = stripNonNumberChars(lldp?.portId);
  const sourcePort = stripNonNumberChars(lldp?.sourcePort);
  const systemName = lldp?.systemName;
  const deviceId = lldpDeviceIdToMac(connectedDeviceId);
  return {
    deviceMac,
    deviceId,
    portId,
    sourcePort,
    systemName,
  };
};

const removeEmptyStringNesting = (lldpData: any) => {
  if (lldpData.hasOwnProperty("")) {
    return lldpData[""];
  }
  return lldpData;
};

const normalizePortNames = (lldpPorts: any) => {
  if (!lldpPorts || isEmpty(lldpPorts)) {
    return {};
  }
  const normalizedPorts: any = {};
  for (const [portName, lldpData] of Object.entries(lldpPorts)) {
    const normalizedPortName = stripNonNumberChars(portName);
    if (!normalizedPortName) {
      continue;
    }

    normalizedPorts[normalizedPortName] = lldpData;
  }
  return normalizedPorts;
};

const removePortNesting = (lldpData: any) => {
  let normalizedData = normalizePortNames(lldpData?.ports);
  normalizedData = normalizedData.hasOwnProperty(LLDP_WIRED_FIRST_PORT)
    ? normalizedData[LLDP_WIRED_FIRST_PORT]
    : normalizedData;
  return removeEmptyStringNesting(normalizedData);
};

export const lldpNormalizer = (lldpData: any) => {
  if (!lldpData || isEmpty(lldpData)) {
    return {};
  }
  const normalizedData = removePortNesting(lldpData);
  return mergeCDPLLDP(normalizedData);
};

const getLLDPDataByPort = (lldpData: any, portNumber: any) => {
  const normalizedData = removeEmptyStringNesting(lldpData);
  return normalizedData[portNumber];
};

export const lldpWithPortNormalizer = (lldpData: any, portNumber: any) => {
  if (!lldpData || isEmpty(lldpData)) {
    return {};
  }
  const normalizedData = normalizePortNames(lldpData?.ports);
  const portData = getLLDPDataByPort(normalizedData, portNumber);
  return mergeCDPLLDP(portData);
};

export const convertTagsToArray = (tags: any) =>
  tags
    ?.trim()
    .split(" ")
    .filter((tag: any) => tag !== "") || [];

export const validNodeAlertMap = {
  unreachable: "NODE_ALERTS.UNREACHABLE" as const,
  unseen: null,
  bad_gateway: null,
  gateway: "NODE_ALERTS.GATEWAY" as const,
  dns_down: null,
  bad_connectivity: null,
  node_check: null,
  ip_conflict: null,
  asymmetry: null,
  bad_ipconf: null,
  bad_vlanconf: null,
  using_mtun_http: null,
  firewall: null,
  config: null,
  eapol_test_failed: null,
  reboot_count: null,
  reboot_count_panic: null,
  reboot_count_no_xmit_mon: null,
  high_interference: null,
  odd_upstream_gateway: null,
  vpn_outage: null,
  firmware_version: null,
  disassociation: null,
  reg_dom_mismatch: null,
  country_mismatch: null,
  mps_down: null,
  rps_down: null,
  rps_backup: null,
  fan_down: null,
  vrrp_failover: null,
  vlan_disconnect: null,
  l3_dynamic_routes_overflow: null,
  l3_hosts_overflow: null,
  stack_misconfigured: null,
  stack_not_configured: null,
  switch_not_setup_as_stack: null,
  radar_detection: null,
  cellular_failover: null,
};

export const findDeviceBySerial_Deprecated = (devices: Device[], serial: string) =>
  devices.find((d) => d.serial === serial);

export const findDeviceBySerial = (devices: DeviceSchema[], serial: string) =>
  devices.find((d) => d.serial === serial);

export function isDeviceDetailsValid(deviceDetails: DeviceDetails) {
  const { tags } = deviceDetails;
  const tagsArray = tagsAsArray(tags);
  const duplicateTags = getDuplicatesForArray(tagsArray);
  if (duplicateTags.length > 0) {
    return false;
  }
  return true;
}

export function tagsAsArray(tags: Tags) {
  if (Array.isArray(tags)) {
    return tags;
  }
  return convertTagsToArray(tags);
}

export function tagsAsString(tags: Tags) {
  if (Array.isArray(tags)) {
    return tags.join(" ");
  }
  return tags;
}

export const isATT = (provider: string) => provider.toLowerCase() === "at&t";

export function isDeviceWithNodeGroupInfo(
  value: DeviceWithNodeGroupInfo | DeviceSchema | Device,
): value is DeviceWithNodeGroupInfo {
  return value.hasOwnProperty("images");
}
