import { isEmpty } from "lodash";

import { LATENCY_LIMIT, LOSS_LIMIT } from "~/constants/MkiConstants";

export const PING_RESULT_GROUP = {
  ok: "ok",
  loss: "loss",
  unacceptable: "unacceptable",
} as const;

export const getLossRate = (pings: any) => {
  if (isEmpty(pings)) {
    return undefined;
  }
  return (pings.filter((ping: any) => ping === -1).length / pings.length) * 100;
};

export const getAverageLatency = (pings: any) => {
  if (isEmpty(pings)) {
    return undefined;
  }

  return (
    pings.reduce((total: any, ping: any) => {
      if (ping === -1) {
        return total;
      }
      return total + ping;
    }, 0) / pings.length
  );
};

export const isPassingLatency = (latency: any) => latency <= LATENCY_LIMIT;

export const isPassingLossRate = (lossRate: any) => lossRate <= LOSS_LIMIT;

export const getResultGroup = (averageLatency: any, lossRate: any) => {
  if (lossRate === 100) {
    return PING_RESULT_GROUP.unacceptable;
  }
  if (!isPassingLatency(averageLatency) || !isPassingLossRate(lossRate)) {
    return PING_RESULT_GROUP.loss;
  }

  return PING_RESULT_GROUP.ok;
};
