import { registerNavigationContainer } from "@meraki/core/errors";
import { BottomSheetProvider } from "@meraki/magnetic/components";
import { DebuggingTools } from "@meraki/shared/debugging-tools";
import {
  DefaultTheme,
  DocumentTitleOptions,
  LinkingOptions,
  NavigationContainer,
  NavigationContainerProps,
  ParamListBase,
} from "@react-navigation/native";
import { useRef } from "react";
import { StyleSheet, View } from "react-native";

import { removePendingAppLink } from "~/actions";
import { trackScreen } from "~/lib/FirebaseUtils";
import { getPendingAppLink } from "~/selectors";

import useAppDispatch from "../hooks/redux/useAppDispatch";
import useAppSelector from "../hooks/redux/useAppSelector";
import { useThemeColors, useThemeMode } from "../hooks/useTheme";
import { navRef } from "./NavigationRef";
import { useMigrateStateData, useMigrateThemeState } from "./useMigrateState";

const AppNavigationContainer = (
  props: NavigationContainerProps & {
    linking?: LinkingOptions<ParamListBase>;
    documentTitle?: DocumentTitleOptions;
    handlePendingAppLink?: boolean;
  },
) => {
  const colors = useThemeColors();
  const mode = useThemeMode();
  const routeNameRef = useRef<string>();

  const pendingAppLink = useAppSelector(getPendingAppLink);
  const dispatch = useAppDispatch();

  useMigrateThemeState();
  useMigrateStateData();

  const { children, handlePendingAppLink = false } = props;

  // This wrapping view is important.
  // during navigation transitions where we are mounting entire navigators the native background shows through.
  // This means Green on Android and White on iOS. This is no fun in dark mode.
  return (
    <View style={[styles.wrapper, { backgroundColor: colors.navigation.backgroundPrimary }]}>
      <NavigationContainer
        {...props}
        theme={{
          ...DefaultTheme,
          dark: mode === "dark",
          colors: {
            ...DefaultTheme.colors,
            primary: colors.navigation.primary,
            background: colors.navigation.backgroundPrimary,
            card: colors.navigation.backgroundPrimary,
            text: colors.text.heading.color,
            border: colors.navigation.tabBorder,
          },
        }}
        ref={navRef}
        onReady={() => {
          registerNavigationContainer(navRef);
          routeNameRef.current = navRef.getCurrentRoute()?.name;
        }}
        onStateChange={async () => {
          const curRouteName = navRef.getCurrentRoute()?.name;
          const prevRouteName = routeNameRef.current;

          if (
            curRouteName &&
            prevRouteName !== curRouteName &&
            !curRouteName.includes("DebuggingView") &&
            !prevRouteName?.includes("DebuggingView")
          ) {
            await trackScreen(curRouteName);
          }

          if (handlePendingAppLink) {
            const hasNavigatorRemounted = curRouteName !== undefined && prevRouteName === undefined;
            if (pendingAppLink && hasNavigatorRemounted) {
              dispatch(removePendingAppLink());
              const { screen, params } = pendingAppLink;

              navRef.navigate(screen, params);
            }
          }

          routeNameRef.current = curRouteName;
        }}
      >
        <DebuggingTools navigationRef={navRef}>
          <BottomSheetProvider>{children}</BottomSheetProvider>
        </DebuggingTools>
      </NavigationContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
});

export default AppNavigationContainer;
