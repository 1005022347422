import { useEffect, useState } from "react";

import useAppSelector from "../hooks/redux/useAppSelector";
import { RootState } from "../types/Redux";

type ChangingStateOptions = {
  ignoreUndefined?: boolean;
  ignoreNull?: boolean;
};

function useChangingState<T>(
  selector: (state: RootState) => T,
  duration = 800,
  { ignoreUndefined, ignoreNull }: ChangingStateOptions = {
    ignoreUndefined: true,
    ignoreNull: true,
  },
) {
  const currentState = useAppSelector(selector);
  const [isStateChanging, setIsStateChanging] = useState(false);

  // make selector changes cause a rerender
  useEffect(() => {
    if (ignoreUndefined && currentState === undefined) {
      return;
    }

    if (ignoreNull && currentState === null) {
      return;
    }

    setIsStateChanging(true);
    setTimeout(() => setIsStateChanging(false), duration);
  }, [currentState, duration, ignoreNull, ignoreUndefined]);

  return isStateChanging;
}

export default useChangingState;
