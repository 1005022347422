import { find, first, get, isEmpty, last, startsWith, uniq } from "lodash";

import { MS_IN_A_SECOND, TIMESTAMP_KEY } from "~/constants/MkiConstants";
import { PortTypes } from "~/constants/PortLayouts";
import I18n from "~/i18n/i18n";
import { clientName } from "~/lib/ClientUtils";
import { convertTagsToArray, deviceName } from "~/lib/DeviceUtils";
import { mergeObjectsByUpdatedTimestamp } from "~/lib/objectHelper";
import { stringEqualsNumber } from "~/lib/stringHelper";
import { isString } from "~/lib/TypeHelper";
import { PublicSwitchPort, STPGuard, SwitchPort } from "~/shared/types/models/SwitchPort";

export const DASH = "-";
const LESS_THAN_ONE = "< 1";
const TRAFFIC_TUPLE_LENGTH = 3;

// Dashboard's currentTraffic live tool keeps track of the last four
// byte measurements when applying any new ones. This means that currentTraffic
// is often computed with five traffic data points.
// See: /manage/private/react/pages/nodes/SwitchDetails.jsx
const MAX_TRAFFIC_MEMORY_LENGTH = 4;
const BYTES_TO_BITS = 8;
const CHASSIS_ID_KEY = "Chassis ID";

export const SWITCH_PORTS_EDITABLE_ATTRIBUTES = [
  "enabled",
  "link_negotiation",
  "use_poe",
  "is_trunk",
  "allowed_vlans",
  "vid",
  "native_vid",
  "voice_vid",
  "is_isolated",
  "use_stp",
  "stp_guard",
  // For now, this will not be included. To allow the
  // "Guest Access" Feature, we will likely need to use this along with
  // additional firewall rules to implement.  When that logic is determined,
  // We can revisit how to implement.
];

export const STP_GUARD_OPTIONS = [
  { label: "Disabled", value: "disabled" },
  { label: "BPDU Guard", value: "bpdu guard" },
  { label: "Loop Guard", value: "loop guard" },
  { label: "Root Guard", value: "root guard" },
];

export function isSFPPortType(type: any) {
  return type.toLowerCase() === PortTypes.sfp.toLowerCase();
}

const BPS_SIZES = [
  [1024 ** 4, "Tbps"],
  [1024 ** 3, "Gbps"],
  [1024 ** 2, "Mbps"],
  [1024 ** 1, "Kbps"],
  [1, "bps"],
] as const;

export function renderBps(bps: number, precision: number) {
  if (bps <= 0.0) {
    return {};
  }
  if (bps < 1) {
    const unit = last(BPS_SIZES)![1];
    return {
      unit,
    };
  }
  const unwrappedPrecision = precision || 1;
  const size = find(BPS_SIZES, (s) => bps >= s[0])!;
  // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  let value = parseFloat(bps / size[0]).toFixed(unwrappedPrecision);

  if (size[0] === 1) {
    // Avoid rendered decimal points for values in bps
    value = parseInt(value, 10).toFixed();
  }

  return {
    value,
    unit: size[1],
  };
}

export function currentTrafficLabels(liveTraffic: any) {
  const traffic = calculateSwitchPortTraffic(liveTraffic);
  let curTraffic;
  let units;
  if (!traffic.total) {
    curTraffic = DASH;
  } else {
    const { value, unit } = renderBps(traffic.total, 1);
    // @ts-expect-error TS(2365) FIXME: Operator '<' cannot be applied to types 'string' a... Remove this comment to see the full error message
    if (value && value < 1) {
      curTraffic = LESS_THAN_ONE;
    }
    if (value) {
      curTraffic = value;
    }
    if (unit) {
      units = unit;
    }
  }

  return {
    curTraffic,
    units,
  };
}

/*
@parameters
traffic | array | required

Specifies a traffic array consisting of five or less 3-tuples
a) timestamp (number), time of bytes measurement
b) txBytes (number), sent bytes
c) rxBytes (number), received bytes

@return
sent | number
number of bits sent over port during timespan

received | number
number of bits received over port during timespan

total | number
total number of bits transmitted over port during timespan
*/
export function calculateSwitchPortTraffic(traffic: number[][]) {
  const bailout = { send: 0, received: 0, total: 0 };
  if (!traffic || !(traffic.length > 1)) {
    return bailout;
  }

  const firstVal = first(traffic)!;
  const lastVal = last(traffic)!;

  if (firstVal.length !== TRAFFIC_TUPLE_LENGTH || lastVal.length !== TRAFFIC_TUPLE_LENGTH) {
    return bailout;
  }

  const timespan = lastVal[0] - firstVal[0];
  if (!timespan) {
    return bailout;
  }

  const newTx = (lastVal[1] - firstVal[1]) * BYTES_TO_BITS;
  const newRx = (lastVal[2] - firstVal[2]) * BYTES_TO_BITS;

  return {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    sent: parseInt(newTx / timespan, 10),
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    received: parseInt(newRx / timespan, 10),
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    total: parseInt((newRx + newTx) / timespan, 10),
  };
}

/*
@parameters
liveTraffic | required
Specifies a traffic array consisting of n 3-tuples
a) timestamp (number), time of bytes measurement
b) txBytes (number), sent bytes
c) rxBytes (number), received bytes

timestamp | required
time of bytes measurement

txBytes | number
sent bytes

rxBytes | number
received bytes

@return
liveTraffic
Specifies a traffic array consisting of five or less 3-tuples
a) timestamp (number), time of bytes measurement
b) txBytes (number), sent bytes
c) rxBytes (number), received bytes
*/
export function rollingLiveTraffic(liveTraffic: any, timestamp: any, txBytes: any, rxBytes: any) {
  const limitedMemoryLiveTraffic = liveTraffic.slice(-1 * MAX_TRAFFIC_MEMORY_LENGTH);
  limitedMemoryLiveTraffic.push([timestamp, txBytes, rxBytes]);
  return limitedMemoryLiveTraffic;
}

export function getChassisId(switchPort: any) {
  const lldpInfos = get(switchPort, "lldp_infos", {});

  let chassisId;

  Object.values(lldpInfos).forEach((info) => {
    const opts = get(info, "lldp.opts", []);
    opts.forEach((tuple: any) => {
      if (tuple.length === 2 && tuple[0] === CHASSIS_ID_KEY) {
        const id = tuple[1];
        chassisId = id;
      }
    });
  });

  return chassisId;
}

// Merges switchPorts and connected clients
// into a single data payload usable by MkiTable.
export const mergeSwitchData = (switchPorts: any, liveSwitchPorts: any, deviceClientsByMac: any) =>
  switchPorts.map((switchPort: any, index: any) =>
    // index + 1 is used here because the livebroker data is wrapped in the port number
    // as the key (starting at 1).  Index here is the index of the port in an array
    // (starting at 0).  So we need to do the +1 to map accordingly.
    mergePortData(switchPort, get(liveSwitchPorts, index + 1), deviceClientsByMac),
  );

// Merges a switch port data with connected clients.
export const mergePortData = (switchPort: any, liveSwitchPort = {}, deviceClientsByMac: any) => {
  if (!switchPort) {
    return undefined;
  }

  const switchPortTimestamp = Date.parse(switchPort.updated_at);
  const liveSwitchPortTimestamp = get(liveSwitchPort, TIMESTAMP_KEY, 0) * MS_IN_A_SECOND;

  const portData = mergeObjectsByUpdatedTimestamp(
    switchPort,
    switchPortTimestamp,
    liveSwitchPort,
    liveSwitchPortTimestamp,
  );

  // Attach clients to port objects if connected.
  const portNumber = portNumberOfSwitchPort(portData);
  portData.connectedClients = portConnectedClientsArray(deviceClientsByMac, portNumber);

  return portData;
};

// Returns an array of clients connected to a port.
export const portConnectedClientsArray = (deviceClientsByMac: any, portNumber: any) => {
  if (!deviceClientsByMac) {
    return [];
  }
  return Object.values(deviceClientsByMac).filter((client) =>
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    stringEqualsNumber(client.switchport, portNumber),
  );
};

// Returns a mac-keyed object of clients connected to a port.
export const portConnectedClientsByMac = (deviceClientsByMac: any, portNumber: any) => {
  if (!deviceClientsByMac) {
    return {};
  }
  return portConnectedClientsArray(deviceClientsByMac, portNumber).reduce(
    (portClientsByMac, portClient) => ({
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...portClientsByMac,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      [portClient.mac]: portClient,
    }),
    {},
  );
};

// Given a mac, return a node (device) or client object.
export function connectedClientOrNodeForMac(mac: any, devicesByMac: any, clientsByMac: any) {
  const deviceByMac = get(devicesByMac, [mac]);
  if (deviceByMac) {
    return deviceByMac;
  }
  const clientByMac = get(clientsByMac, [mac]);
  if (clientByMac) {
    return clientByMac;
  }
  return undefined;
}

// Primarily used with Go
export const portTitle = (isUplink: any, number: any) => {
  if (isUplink) {
    return `${I18n.t("PORTS.NUMBER", { port_number: number })} - ${I18n.t(
      "PORTS.INTERNET_CONNECTION",
    )}`;
  }
  return I18n.t("PORTS.NUMBER", { port_number: number });
};

// Primarily used with Enterprise
export const portDisplayName = (portNumber: any, portName: any) => {
  const emptyPortName = "";
  if (!portNumber) {
    return emptyPortName;
  }
  const portNumberName = I18n.t("PORTS.NUMBER", {
    port_number: portNumber,
  });
  if (portName) {
    return `${portNumberName}: ${portName}`;
  }
  return portNumberName;
};

// Given port data and connected devices/clients, return a poke host object
export const getPokeHosts = (
  lldpMac: any,
  connectedClients: any,
  clientsByMac: any,
  devicesByMac: any,
) => {
  const lldpDevice = get(devicesByMac, [lldpMac]);
  if (get(lldpDevice, "ip")) {
    return [
      {
        ip: lldpDevice.ip,
        name: deviceName(lldpDevice) || lldpMac,
      },
    ];
  }

  const lldpClient = get(clientsByMac, [lldpMac]);
  if (get(lldpClient, "ip")) {
    return [
      {
        ip: lldpClient.ip,
        name: clientName(lldpClient) || lldpMac,
      },
    ];
  }

  if (!isEmpty(connectedClients)) {
    return connectedClients.map((client: any) => ({
      ip: client.ip,
      name: clientName(client) || lldpMac,
    }));
  }
  return null;
};

// NOTE: This assumes that all swtichports given will have the same attributes.
// If this assumption changes, we will need to create a list of all unique
// attributes to use.
// NOTE: This only looks at values we are currently allowing the app to edit.
// This is for performance reasons.
export function getUniqueEditableAttributeValues(switchPorts: any) {
  if (isEmpty(switchPorts)) {
    return {};
  }

  return SWITCH_PORTS_EDITABLE_ATTRIBUTES.reduce((obj, key) => {
    const allValues = switchPorts.reduce((values: any, port: any) => [...values, port[key]], []);
    return { ...obj, [key]: uniq(allValues) };
  }, {});
}

export const processLiveSwitchPortDataForPortNumber = (data: any, portNumber: any) => {
  const timestamp = get(data, "ts");
  const livePortsData = get(data, "ports");

  const liveSwitchPortData = get(livePortsData, portNumber);

  if (!liveSwitchPortData) {
    return undefined;
  }

  const liveSwitchPort = {
    ...liveSwitchPortData,
    liveStatus: liveSwitchPortData.status,
    timestamp,
  };

  // This is the only attribute that differs in what the value is in switchport
  // We don't want this status to override the switchport.status
  // We save the liveSwitchPortData.status as liveStatus (see above).
  delete liveSwitchPort.status;
  return liveSwitchPort;
};

export const portNumberOfSwitchPort = (switchPort: any) => get(switchPort, "num[0]");

export const switchPortsToArray = (switchPorts: any) => {
  const newSwitchPorts: any = {};
  for (const serial in switchPorts) {
    newSwitchPorts[serial] = Object.values(switchPorts[serial]);
  }
  return newSwitchPorts;
};

export const switchPortHasTags = (switchport: any) => {
  if (!switchport) {
    return false;
  }
  return convertTagsToArray(switchport.tags).length > 0;
};

const VALUE_BY_LABEL = {
  "Auto negotiate": "auto",
  "1 Gigabit full duplex (forced)": "1Gfdx",
  "100 Megabit (auto)": "100M-auto",
  "100 Megabit half duplex (forced)": "100Mhdx",
  "100 Megabit full duplex (forced)": "100Mfdx",
  "10 Megabit (auto)": "10M-auto",
  "10 Megabit half duplex (forced)": "10Mhdx",
  "10 Megabit full duplex (forced)": "10Mfdx",
};

export function getLinkNegotiationValue(label: any) {
  if (!label) {
    return undefined;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return VALUE_BY_LABEL[label];
}

const LABEL_BY_VALUE = {
  auto: "Auto negotiate",
  "1Gfdx": "1 Gigabit full duplex (forced)",
  "100M-auto": "100 Megabit (auto)",
  "100Mhdx": "100 Megabit half duplex (forced)",
  "100Mfdx": "100 Megabit full duplex (forced)",
  "10M-auto": "10 Megabit (auto)",
  "10Mhdx": "10 Megabit half duplex (forced)",
  "10Mfdx": "10 Megabit full duplex (forced)",
};

export function getLinkNegotiationLabel(value: any) {
  if (!value) {
    return undefined;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return LABEL_BY_VALUE[value];
}

const TRUNK = "trunk";
const ACCESS = "access";

export function normalizeSwitchPort(switchPort: any) {
  for (const key in switchPort) {
    const { stp_guard } = switchPort[key];
    switchPort[key].stp_guard = normalizeSTPGuard(stp_guard);
  }
  return switchPort;
}

export function normalizeSTPGuard(stpGuard: any) {
  if (startsWith(stpGuard, "loop")) {
    return STPGuard.loopGuard;
  } else if (startsWith(stpGuard, "root")) {
    return STPGuard.rootGuard;
  } else if (startsWith(stpGuard, "bpdu")) {
    return STPGuard.bpduGuard;
  }

  return STPGuard.disabled;
}

export function convertToPrivateAPIPort(port: any) {
  const {
    allowedVlans,
    enabled,
    isolationEnabled,
    type,
    linkNegotiation,
    name,
    vlan,
    poeEnabled,
    voiceVlan,
    rstpEnabled,
    stpGuard,
    tags,
  } = port;

  const privateDataStructure = {
    allowed_vlans: allowedVlans,
    enabled: enabled,
    is_isolated: isolationEnabled,
    link_negotiation: getLinkNegotiationValue(linkNegotiation),
    name: name,
    native_vid: vlan,
    use_poe: poeEnabled,
    vid: vlan,
    voice_vid: voiceVlan,
    use_stp: rstpEnabled,
    stp_guard: normalizeSTPGuard(stpGuard),
    tags: tags?.join(" "),
  };

  if (type != null) {
    // @ts-expect-error TS(2339) FIXME: Property 'is_trunk' does not exist on type '{ allo... Remove this comment to see the full error message
    privateDataStructure.is_trunk = type === TRUNK;
  }

  if (stpGuard != null) {
    privateDataStructure.stp_guard = normalizeSTPGuard(stpGuard);
  }

  return privateDataStructure;
}

export function convertToPublicAPIPort(port: Partial<SwitchPort>) {
  const {
    allowed_vlans,
    enabled,
    is_isolated,
    is_trunk,
    link_negotiation,
    name,
    native_vid,
    use_stp,
    stp_guard,
    tags,
    use_poe,
    vid,
    voice_vid,
  } = port;

  const publicDataStructure: Partial<PublicSwitchPort> = {
    allowedVlans: allowed_vlans,
    enabled: enabled,
    isolationEnabled: is_isolated,
    linkNegotiation: getLinkNegotiationLabel(link_negotiation),
    name: name,
    poeEnabled: use_poe,
    rstpEnabled: use_stp,
    stpGuard: stp_guard,
    // @ts-expect-error TS(2322) FIXME: Type 'string | number | undefined' is not assignab... Remove this comment to see the full error message
    voiceVlan: voice_vid,
  };

  if (is_trunk != null) {
    publicDataStructure.type = is_trunk ? TRUNK : ACCESS;
  }

  if (native_vid != null) {
    // @ts-expect-error TS(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
    publicDataStructure.vlan = native_vid;
  }

  if (vid != null) {
    // @ts-expect-error TS(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
    publicDataStructure.vlan = vid;
  }

  if (tags != null) {
    if (isString(tags)) {
      publicDataStructure.tags = convertTagsToArray(tags);
    } else {
      publicDataStructure.tags = tags;
    }
  }

  return publicDataStructure;
}
