import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface Override {
  enabled: boolean;
  value: unknown;
}

interface ThemeStore {
  overrides: Record<string, Override>;
  setOverride: (flag: string, value: unknown) => void;
  toggleEnabled: (flag: string) => void;
  clearOverrides: () => void;
}

export const useOverrideStore = create<ThemeStore>()(
  persist(
    (set) => ({
      overrides: {},
      setOverride: (flag: string, value: unknown) => {
        set((state) => ({ overrides: { ...state.overrides, [flag]: { enabled: true, value } } }));
      },
      toggleEnabled: (flag: string) => {
        set((state) => {
          const enabled = state.overrides[flag]?.enabled ?? false;
          const currentValue = state.overrides[flag]?.value ?? false;

          return {
            overrides: {
              ...state.overrides,
              [flag]: { enabled: !enabled, value: currentValue },
            },
          };
        });
      },
      clearOverrides: () => {
        set({ overrides: {} });
      },
    }),
    {
      name: "FeatureFlagOverrideStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
      version: 1,
    },
  ),
);
