import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { ColorToken } from "@meraki/magnetic/themes";
import { useCallback } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";

import { copyValue } from "../util/clipboard";
import { ListItemInternal, ListItemInternalProps } from "./ListItemInternal";

export type ListActionType = {
  text: string;
  color: ColorToken;
  textColor: ColorToken;
  onPress: () => void;
};

export type ListItemProps = Omit<ListItemInternalProps, "pressable"> & {
  onPress?: PressableProps["onPress"];
  onLongPress?: PressableProps["onLongPress"];
  copyable?: boolean;
  leftActions?: ListActionType[];
  rightActions?: ListActionType[];
  /**
   * The value to copy if long pressed instead of just copying `value`.
   * Note: Value will only copy on long press if it is a simple string or number.
   * More complex types will require passing in a `copyableValue`
   */
  copyableValue?: string;
} & AnalyticsProp<"onPress" | "onLongPress">;

export function ListItem({
  onPress,
  onLongPress,
  analytics,
  copyable = true,
  copyableValue,
  value,
  ...rest
}: ListItemProps) {
  const trackEvent = useAnalytics(analytics);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      trackEvent("onPress");
      onPress?.(event);
    },
    [trackEvent, onPress],
  );

  const handleLongPress = useCallback(
    (event: GestureResponderEvent) => {
      if (copyable) {
        // Use copyable if its provided.
        if (copyableValue) {
          copyValue(copyableValue);
        }

        // Fallback to the value... if its something we can easily copy.
        if (typeof value === "string" || typeof value === "number") {
          copyValue(value);
        }
      }

      if (!onLongPress) {
        return;
      }

      trackEvent("onLongPress");
      onLongPress(event);
    },
    [copyable, value, onLongPress, trackEvent, copyableValue],
  );

  return (
    <Pressable onPress={handlePress} onLongPress={handleLongPress}>
      <ListItemInternal {...rest} value={value} pressable={!!onPress} />
    </Pressable>
  );
}
