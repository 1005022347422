import { featureFlagClient } from "./client";
import { BooleanKeys, NumberKeys, ObjectKeys, StringKeys } from "./providers/typeHelpers";
import { FlagMap, FlagProviders } from "./types";
import { useFlag } from "./useFlag";

export function useBoolFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<boolean, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultBool(provider, flag),
    featureFlagClient.getBool,
    false,
    skipOverride,
  );
}

export function useWatchedBoolFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<boolean, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultBool(provider, flag),
    featureFlagClient.getBool,
    true,
    skipOverride,
  );
}

export function useNumberFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends NumberKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<number, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultNumber(provider, flag),
    featureFlagClient.getNumber,
    false,
    skipOverride,
  );
}

export function useWatchedNumberFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends NumberKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<number, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultNumber(provider, flag),
    featureFlagClient.getNumber,
    true,
    skipOverride,
  );
}

export function useStringFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends StringKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<string, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultString(provider, flag),
    featureFlagClient.getString,
    false,
    skipOverride,
  );
}

export function useWatchedStringFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends StringKeys<FlagMap[TFlagProvider]>,
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<string, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultString(provider, flag),
    featureFlagClient.getString,
    true,
    skipOverride,
  );
}

export function useJsonFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends ObjectKeys<FlagMap[TFlagProvider]>,
  TReturnType extends FlagMap[TFlagProvider][TFlag],
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<TReturnType, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultJson(provider, flag),
    featureFlagClient.getJson,
    false,
    skipOverride,
  );
}

export function useWatchedJsonFlag<
  TFlagProvider extends FlagProviders,
  TFlag extends ObjectKeys<FlagMap[TFlagProvider]>,
  TReturnType extends FlagMap[TFlagProvider][TFlag],
>(provider: TFlagProvider, flag: TFlag, skipOverride = false) {
  return useFlag<TReturnType, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultJson(provider, flag),
    featureFlagClient.getJson,
    true,
    skipOverride,
  );
}
