import { Tag } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ScrollView } from "react-native";

export type SelectableTag<T> = {
  label: string;
  value: T | null;
};

type SelectableTagsListProps<T> = {
  tags: SelectableTag<T>[];
  onSelect: (value: T | null) => void;
  selected: T[];
  testID?: string;
  key?: string;
};

export function SelectableTagsList<T>({
  tags,
  onSelect,
  selected,
  testID,
  key,
}: SelectableTagsListProps<T>) {
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <Box flexDirection="row" gap="xs">
        {tags.map(({ label, value }) => {
          const isSelected = value === null ? selected.length === 0 : selected.includes(value);

          return (
            <Tag
              type="selectable"
              onPress={() => onSelect(value)}
              selected={isSelected}
              key={`${key ? `${key}.` : ""}${value}`}
              testID={`${testID ? `${testID}.` : ""}${value}${isSelected ? ".SELECTED" : ""}`}
            >
              {label}
            </Tag>
          );
        })}
      </Box>
    </ScrollView>
  );
}
