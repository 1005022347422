import * as errorMonitor from "@meraki/core/errors";
import {
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_USAGE_INCREMENTS,
} from "@meraki/go/traffic-shaping";
import { kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import Slider from "@react-native-community/slider";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import SectionListHeader from "~/go/components/SectionListHeader";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { formatTransferBits } from "~/lib/formatHelper";
import { currentNetworkState, slimSsidsSelector } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { SettingsStackProps } from "../navigation/Types";

type ReduxProps = {
  ssid: SSID;
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "LimitSSIDUsage"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

interface LimitSSIDUsageState {
  sliderValue: number;
}

export class LimitSSIDUsage extends PureComponent<Props, LimitSSIDUsageState> {
  constructor(props: Props) {
    super(props);
    const { editLimitDown } = this.props;
    this.state = {
      sliderValue: editLimitDown ? TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimitDown) : 0,
    };
    this.setNavOptions(false);
  }

  setNavOptions(saveEnabled: boolean) {
    const { navigation, editLimitDown } = this.props;

    let headerLeft;
    let headerRight;
    if (editLimitDown) {
      headerLeft = () =>
        saveEnabled ? (
          <CancelButton onPress={this.handleClose} />
        ) : (
          <CloseButton onPress={this.handleClose} />
        );
      headerRight = () => <SaveButton onPress={this.save} disabled={!saveEnabled} />;
    } else {
      headerLeft = () => <CancelButton onPress={this.handleClose} />;
      headerRight = () => <SaveButton onPress={this.save} />;
    }

    navigation.setOptions({
      headerLeft,
      headerRight,
    });
  }

  componentDidUpdate() {
    this.setNavOptions(this.isDirty());
  }

  isDirty = () => {
    const { editLimitDown } = this.props;
    if (editLimitDown) {
      const { sliderValue } = this.state;
      return TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimitDown) !== sliderValue;
    }
    return false;
  };

  handleClose = () => {
    const { navigation } = this.props;

    if (this.isDirty()) {
      this.cancelChanges();
    } else {
      navigation.goBack();
    }
  };

  cancelChanges = () => {
    const { navigation } = this.props;
    showSaveWarning(this.save, navigation.goBack);
  };

  save = () => {
    const { navigation, networkId, setReqPending, actions, ssidNumber, cancelablePromise } =
      this.props;
    const { sliderValue } = this.state;
    const limit = kilobitsToMebibitsInt(TRAFFIC_SHAPING_LIMIT_VALUES[sliderValue]);
    const newSsid = {
      number: ssidNumber,
      perSsidBandwidthLimitUp: limit,
      perSsidBandwidthLimitDown: limit,
    };
    setReqPending(true);
    cancelablePromise(actions.setSsid(networkId, newSsid))
      .then(() => {
        setReqPending(false);
        navigation.goBack();
      })
      .catch((err) => {
        showAlert(I18n.t("ERROR"), err);
        setReqPending(false);
      });
  };

  render() {
    const { sliderValue } = this.state;
    const { onTitle, ssid } = this.props;
    const limitSpeed = formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[sliderValue]);

    return (
      <View style={styles.container}>
        <ScrollView>
          <View style={styles.headerContainer}>
            <MkiText screenStyles={styles.headerStyle}>
              {I18n.t("PER_SSID_USAGE_LIMIT.HEADER", { ssid_name: ssid.name })}
            </MkiText>
            <SectionListHeader heading={onTitle} />
          </View>
          <View style={styles.sliderContainer}>
            <Slider
              maximumValue={TRAFFIC_SHAPING_USAGE_INCREMENTS}
              onValueChange={(sliderValue) => this.setState({ sliderValue })}
              step={1}
              value={sliderValue}
              minimumTrackTintColor={MkiColors.goPurple}
              testID="SSID_USAGE.SLIDER"
            />
            <MkiText>{I18n.t("PER_SSID_USAGE_LIMIT.LIMIT_SPEED", { speed: limitSpeed })}</MkiText>
          </View>
          <View style={styles.subtitle}>
            <MkiText screenStyles={styles.headerStyle}>
              {I18n.t("PER_SSID_USAGE_LIMIT.SUBTITLE")}
            </MkiText>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    marginHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
  sliderContainer: {
    marginHorizontal: SPACING.large,
  },
  subtitle: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: SettingsStackProps["LimitSSIDUsage"],
): ReduxProps {
  const { ssidNumber } = props;
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for LimitSSIDUsageScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsSelector(state)[ssidNumber],
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(LimitSSIDUsage);
