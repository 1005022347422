import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";

import { ConfigStatus } from "~/constants/ConfigStatus";

// See: /manage/private/react/views/nodeDetails/leftSections/NodeConfig.jsx lines 76-90
export const getConfigMessage = (
  status: ConfigStatus,
  changedAt: number,
  fetchedAt: number,
): string => {
  if (status === undefined) {
    return I18n.t("CONFIG_MESSAGE.UNKNOWN");
  } else if (status === ConfigStatus.outOfDateSafe) {
    return I18n.t("CONFIG_MESSAGE.OUT_OF_DATE");
  } else if (status === ConfigStatus.outOfDateOther) {
    return I18n.t("CONFIG_MESSAGE.OUT_OF_DATE_OTHER");
  } else if (isNaN(changedAt) || isNaN(fetchedAt) || fetchedAt === 0) {
    return I18n.t("CONFIG_MESSAGE.NEVER_FETCHED");
  } else if (fetchedAt > changedAt) {
    return I18n.t("CONFIG_MESSAGE.UP_TO_DATE");
  } else {
    return I18n.t("CONFIG_MESSAGE.OUT_OF_DATE_CHANGED", {
      time: formatDate(fetchedAt, {
        dateFormat: "hide",
        timeFormat: "shortTime",
        unixTimestamp: false,
      }),
    });
  }
};
