import { I18n } from "@meraki/core/i18n";
import { formatKibibytes } from "@meraki/shared/formatters";
import { isEmpty } from "lodash";

import ClientUsageGraph from "~/shared/components/ClientUsageGraph";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import { ExtendedClient } from "~/shared/types/Client";
import { Useblock } from "~/shared/types/Useblocks";

interface ClientUsageGraphProps {
  client: ExtendedClient;
  usageHistory: Useblock;
  timespan: number;
  loading: boolean;
  t0: number;
  t1: number;
  timezone: string;
}

export const ClientUsageGraphCard = (props: ClientUsageGraphProps) => {
  const { client, usageHistory, timespan, loading, t0, t1, timezone } = props;
  const usageText =
    !isEmpty(client) && client.usage
      ? I18n.t("CLIENT_USAGE.TOTAL_USAGE", {
          usage_number: `${formatKibibytes(client.usage?.total)}`,
        })
      : I18n.t("CLIENT_USAGE.EMPTY");

  return (
    <SummaryCard
      testID="CLIENT_APPLICATION_USAGE"
      heading={I18n.t("CLIENT_USAGE.PICKER_TITLE")}
      subheading={I18n.t("CLIENT_DETAILS.USAGE.SUBTITLE", { client_word: I18n.t("DEVICE_WORD") })}
      loading={loading}
    >
      <ClientUsageGraph
        // @ts-expect-error TS(2740) FIXME: Type 'Useblock' is missing the following propertie... Remove this comment to see the full error message
        usageHistory={usageHistory}
        timespan={timespan}
        t0={t0}
        t1={t1}
        timezone={timezone}
      />
      <MkiText textStyle="small">{usageText}</MkiText>
    </SummaryCard>
  );
};

export default ClientUsageGraphCard;
