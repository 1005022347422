import { ColorToken, mapColorToken, Theme } from "@meraki/magnetic/themes";
import { ViewStyle } from "react-native";

const BORDER_WIDTH_MAP = {
  light: "Light",
  medium: "Medium",
  strong: "Strong",
} as const;

const BORDER_RADIUS_MAP = {
  none: "None",
  xs: "Xs",
  sm: "Sm",
  md: "Md",
  lg: "Lg",
} as const;

export type BorderWidth = keyof typeof BORDER_WIDTH_MAP;
export type BorderRadius = keyof typeof BORDER_RADIUS_MAP;

export interface BorderStyle {
  borderColor?: ColorToken;
  borderBottomColor?: ColorToken;
  borderLeftColor?: ColorToken;
  borderRightColor?: ColorToken;
  borderTopColor?: ColorToken;
  borderRadius?: BorderRadius;
  borderTopLeftRadius?: BorderRadius;
  borderTopRightRadius?: BorderRadius;
  borderBottomLeftRadius?: BorderRadius;
  borderBottomRightRadius?: BorderRadius;
  borderWidth?: BorderWidth;
  borderBottomWidth?: BorderWidth;
  borderLeftWidth?: BorderWidth;
  borderRightWidth?: BorderWidth;
  borderTopWidth?: BorderWidth;
  borderStyle?: ViewStyle["borderStyle"];

  bottomDividerBorder?: boolean;
}

function mapBorderWidth(borderWidth: BorderWidth | undefined, theme: Theme) {
  if (typeof borderWidth === "undefined") return undefined;

  return theme[`SizeStroke${BORDER_WIDTH_MAP[borderWidth]}`];
}

function mapBorderRadius(borderRadius: BorderRadius | undefined, theme: Theme) {
  if (typeof borderRadius === "undefined") return undefined;

  return theme[`SizeRadiusBorder${BORDER_RADIUS_MAP[borderRadius]}`];
}

export function mapBorderPropsToStyles(
  {
    borderColor,
    borderBottomColor,
    borderLeftColor,
    borderRightColor,
    borderTopColor,
    borderWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderRightWidth,
    borderTopWidth,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    bottomDividerBorder,
    borderStyle,
    ...rest
  }: BorderStyle,
  theme: Theme,
) {
  const style = {
    borderColor: mapColorToken(borderColor, theme),
    borderBottomColor: mapColorToken(borderBottomColor, theme),
    borderLeftColor: mapColorToken(borderLeftColor, theme),
    borderRightColor: mapColorToken(borderRightColor, theme),
    borderTopColor: mapColorToken(borderTopColor, theme),
    borderWidth: mapBorderWidth(borderWidth, theme),
    borderBottomWidth: mapBorderWidth(borderBottomWidth, theme),
    borderLeftWidth: mapBorderWidth(borderLeftWidth, theme),
    borderRightWidth: mapBorderWidth(borderRightWidth, theme),
    borderTopWidth: mapBorderWidth(borderTopWidth, theme),
    borderRadius: mapBorderRadius(borderRadius, theme),
    borderTopLeftRadius: mapBorderRadius(borderTopLeftRadius, theme),
    borderTopRightRadius: mapBorderRadius(borderTopRightRadius, theme),
    borderBottomLeftRadius: mapBorderRadius(borderBottomLeftRadius, theme),
    borderBottomRightRadius: mapBorderRadius(borderBottomRightRadius, theme),
    borderStyle: borderStyle,
  };

  if (bottomDividerBorder) {
    style.borderBottomColor = theme.color.control.border.weak.base;
    style.borderBottomWidth = mapBorderWidth("medium", theme);
  }

  (Object.keys(style) as Array<keyof typeof style>).forEach(
    (key) => style[key] === undefined && delete style[key],
  );

  return { style, ...rest };
}
