import { StyleProp, ViewStyle } from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import IonIcon from "react-native-vector-icons/Ionicons";

type Props = {
  checked?: boolean;
  style?: StyleProp<ViewStyle>;
};

const CheckmarkIcon = (props: Props) => {
  const { checked, style } = props;
  if (checked) {
    return <IonIcon name="ios-checkmark-circle" style={style} />;
  }
  return <FontAwesomeIcon name="circle-thin" style={style} />;
};

CheckmarkIcon.defaultProps = {
  checked: false,
};

export default CheckmarkIcon;
