import { I18n } from "@meraki/core/i18n";
import { ThemeName, useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { Image, ImageStyle, ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import ProductIcon from "~/go/components/ProductIcon";
import { AddHardwareScreensPropMap } from "~/go/navigation/Types";
import { themeColors } from "~/lib/themeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import IndicatorViewPager from "~/shared/components/viewPager/IndicatorViewPager";
import ViewPager, { PageSelectedEvent } from "~/shared/components/viewPager/ViewPager";
import { CloseButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

type Props = ForwardedNativeStackScreenProps<AddHardwareScreensPropMap, "QRLocation">;

interface QRLocationScreenState {
  selectedRowIndex: number;
}

type QRRowData = {
  isSelected: boolean;
  onPress: () => void;
  theme: ThemeName;
  name: string;
  diagram: NodeRequire;
  icon: string;
  locationDescription: string;
};

const QR_CODE_DEVICES = [
  {
    name: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GX.NAME"),
    diagram: require("~/images/qrLocations/gx-qr-location.png"),
    icon: "GX20",
    locationDescription: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GX.LOCATION"),
  },
  {
    name: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GS.NAME"),
    diagram: require("~/images/qrLocations/gs-qr-location.png"),
    icon: "GS110-8",
    locationDescription: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GS.LOCATION"),
  },
  {
    name: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GR_INDOOR.NAME"),
    diagram: require("~/images/qrLocations/indoor-qr-location.png"),
    icon: "GR10",
    locationDescription: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GR_INDOOR.LOCATION"),
  },
  {
    name: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GR_OUTDOOR.NAME"),
    diagram: require("~/images/qrLocations/outdoor-qr-location.png"),
    icon: "GR60",
    locationDescription: I18n.t("ONBOARDING_FULLSTACK.QR_CODE.DEVICES.GR_OUTDOOR.LOCATION"),
  },
];

export class QRLocationScreen extends PureComponent<Props, QRLocationScreenState> {
  private viewPager?: ViewPager | null;

  constructor(props: Props) {
    super(props);

    this.state = { selectedRowIndex: 0 };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  pagerViews = () =>
    QR_CODE_DEVICES.map((qrDevice, index) => (
      <View style={styles.pagerViewContainer} key={index}>
        <Image
          style={styles.qrLocationDiagram as ImageStyle}
          source={qrDevice.diagram}
          resizeMode="contain"
        />
        <MkiText textStyle="smallSecondary" screenStyles={styles.descriptionText}>
          {qrDevice.locationDescription}
        </MkiText>
      </View>
    ));

  onPageSelected = (e: PageSelectedEvent) =>
    this.setState({ selectedRowIndex: e.nativeEvent.position });

  renderRow = (rowData: QRRowData) => {
    const icon = (
      <View style={styles.icon}>
        <ProductIcon status="online" model={rowData.icon} />
      </View>
    );
    const selectedStyle = themeColors(rowData.theme).selectedColor;
    return (
      <ListRow
        icon={icon}
        onPress={rowData.onPress}
        rowStyles={rowData.isSelected ? [styles.listRow, selectedStyle] : styles.listRow}
      >
        {rowData.name}
      </ListRow>
    );
  };

  rowData = () => {
    const { selectedRowIndex } = this.state;
    const { theme } = useTheme.getState();

    return QR_CODE_DEVICES.map((qrDevice, index) => ({
      ...qrDevice,
      isSelected: index === selectedRowIndex,
      onPress: () => {
        this.viewPager?.setPage(index);
        this.setState({ selectedRowIndex: index });
      },
      theme,
    }));
  };

  renderPageView = () => (
    <IndicatorViewPager
      style={styles.pagerStyle}
      onPageSelected={this.onPageSelected}
      ref={(item) => (this.viewPager = item)}
    >
      {this.pagerViews()}
    </IndicatorViewPager>
  );

  renderList = () => (
    <ScrollView>
      <SummaryList<QRRowData>
        contentRows={this.rowData()}
        customRenderRow={this.renderRow}
        disableBottomBorder
      />
    </ScrollView>
  );

  render() {
    return (
      <FullScreenContainerView>
        {this.renderPageView()}
        {this.renderList()}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  descriptionText: {
    marginTop: SPACING.default,
    textAlign: "center",
  },
  icon: {
    marginRight: SPACING.large,
  },
  listRow: {
    paddingHorizontal: SPACING.default,
  },
  pagerStyle: {
    flex: 1,
  },
  pagerViewContainer: {
    justifyContent: "center",
    paddingHorizontal: SPACING.default,
    marginVertical: SPACING.default,
  },
  qrLocationDiagram: {
    alignSelf: "center",
    borderRadius: BUTTON_SIZING.borderRadius.default,
  },
});

export default QRLocationScreen;
