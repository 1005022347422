import { Box, mapSpacingPropsToStyles } from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import {
  ContentStyle as ShopifyContentStyle,
  FlashList as ShopifyFlashList,
  FlashListProps as ShopifyFlashListProps,
} from "@shopify/flash-list";
import { Keyboard, Platform, StyleProp, View, ViewStyle } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { EdgeInsets, SafeAreaViewProps, useSafeAreaInsets } from "react-native-safe-area-context";

import { Card } from "../../Card/Card";
import { EmptyState } from "../../EmptyState/EmptyState";
import { Heading } from "../../Heading/Heading";
import { Loader } from "../../Loader/Loader";
import { RefreshControl } from "../../RefreshControl/RefreshControl";
import { ListItem } from "../ListItem";
import { RadioListItem } from "../RadioListItem";
import { SwipeableWithActions } from "./SwipeableActions";
import {
  InternalFlashListItemProps,
  InternalFlashListProps,
  InternalFlashListRegularItemProps,
  OurSpacingProps,
} from "./types";

function getSafeAreaPadding(props: OurSpacingProps, insets: EdgeInsets) {
  const insetPaddings: Record<string, number> = {};

  if (insets) {
    if (props.safeAreaEdges?.includes("top")) {
      insetPaddings.paddingTop = insets.top;
    }
    if (props.safeAreaEdges?.includes("bottom")) {
      insetPaddings.paddingBottom = insets.bottom;
    }
    if (props.safeAreaEdges?.includes("left")) {
      insetPaddings.paddingLeft = insets.left;
    }
    if (props.safeAreaEdges?.includes("right")) {
      insetPaddings.paddingRight = insets.right;
    }
  }

  return insetPaddings;
}

function getContainerStyle(
  props: OurSpacingProps,
  theme: Theme,
  insets: EdgeInsets,
): ShopifyContentStyle {
  const { style } = mapSpacingPropsToStyles(
    {
      paddingTop: props.paddingTop ?? "sm",
      paddingLeft: props.paddingLeft ?? "sm",
      paddingRight: props.paddingRight ?? "sm",
      paddingBottom: props.paddingBottom ?? "sm",
    },
    theme,
  );

  return { ...style, ...getSafeAreaPadding(props, insets) };
}

// Android has this odd issue where if you try and set the border
// width to absolute 0 ONLY FOR THE BOTTOM BORDER the left and right borders stop
// rendering too.
// This is absolutely a work around and should be dropped the moment it can be.
// https://github.com/facebook/react-native/issues/34722 seems related although that is talking about color not width
function getBorderBottomWidthWithAndroidWorkaround(
  item: InternalFlashListRegularItemProps,
  theme: Theme,
) {
  const noBorder = Platform.OS === "android" ? 0.001 : 0;

  return item.lastOfSection ? theme.SizeContainBorderStroke : noBorder;
}

function getLeftBorderRadiusStyle(
  item: Pick<InternalFlashListRegularItemProps, "firstOfSection" | "lastOfSection">,
  theme: Theme,
) {
  return {
    borderTopLeftRadius: item.firstOfSection ? theme.SizeContainBorderRadius : 0,
    borderBottomLeftRadius: item.lastOfSection ? theme.SizeContainBorderRadius : 0,
  };
}

function getRightBorderRadiusStyle(
  item: Pick<InternalFlashListRegularItemProps, "firstOfSection" | "lastOfSection">,
  theme: Theme,
) {
  return {
    borderTopRightRadius: item.firstOfSection ? theme.SizeContainBorderRadius : 0,
    borderBottomRightRadius: item.lastOfSection ? theme.SizeContainBorderRadius : 0,
  };
}

function getItemContainerStyle(
  item: InternalFlashListRegularItemProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  return {
    backgroundColor: theme.color.control.bg.weak.base,
    borderWidth: theme.SizeContainBorderStroke,
    borderRadius: theme.SizeContainBorderRadius,
    borderColor: theme.color.control.border.weak.base,

    borderTopWidth: item.firstOfSection ? theme.SizeContainBorderStroke : 0,
    borderBottomWidth: getBorderBottomWidthWithAndroidWorkaround(item, theme),
    ...getLeftBorderRadiusStyle(item, theme),
    ...getRightBorderRadiusStyle(item, theme),
  };
}

export function getFlashListProps(
  props: OurSpacingProps & { safeAreaEdges?: SafeAreaViewProps["edges"] },
  theme: Theme,
  insets: EdgeInsets,
): Omit<ShopifyFlashListProps<InternalFlashListItemProps>, "data"> {
  return {
    contentContainerStyle: getContainerStyle(props, theme, insets),
    renderItem: ({ item }) => {
      if (item.header) {
        return (
          <Box paddingLeft="sm" paddingTop={item.firstSection ? "none" : "md"} paddingBottom="2xs">
            <Heading size="h4">{item.label}</Heading>
          </Box>
        );
      }

      const itemData = item.getItemData();

      if (itemData.kind === "radio") {
        return (
          <View style={getItemContainerStyle(item, theme)}>
            <RadioListItem
              {...itemData}
              radioValue={itemData.radioValue}
              checkedRadioValue={itemData.checkedRadioValue}
              onRadioValueChange={itemData.onRadioValueChanged}
              first={item.firstOfSection}
              last={item.lastOfSection}
            />
          </View>
        );
      }

      const listItemChild = (
        <ListItem {...itemData} first={item.firstOfSection} last={item.lastOfSection} />
      );

      let section: "top" | "bottom" | "middle" | "both" = "middle";

      if (item.firstOfSection && item.lastOfSection) {
        section = "both";
      } else if (item.firstOfSection) {
        section = "top";
      } else if (item.lastOfSection) {
        section = "bottom";
      } else {
        section = "middle";
      }

      const listItem =
        itemData.leftActions || itemData.rightActions ? (
          <SwipeableWithActions
            leftActions={itemData.leftActions}
            rightActions={itemData.rightActions}
            section={section}
          >
            {listItemChild}
          </SwipeableWithActions>
        ) : (
          listItemChild
        );

      return <View style={getItemContainerStyle(item, theme)}>{listItem}</View>;
    },
  };
}

export function InternalFlashList({
  loading,
  emptyState,
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
  safeAreaEdges,
  refreshing,
  onRefresh,
  label,
  ...rest
}: InternalFlashListProps<InternalFlashListItemProps>) {
  const theme = useMagneticTheme();
  const insets = useSafeAreaInsets();

  const spacingProps = { paddingTop, paddingLeft, paddingRight, paddingBottom, safeAreaEdges };

  if (loading) {
    const renderLabels =
      typeof loading === "object" &&
      (typeof loading.numberOfSections !== "undefined" || loading.renderLabel);
    const numLoadingSections = typeof loading === "object" ? loading.numberOfSections ?? 1 : 1;
    const numLoadingRows = typeof loading === "object" ? loading.numberOfRows : 15;

    const containerStyles = getContainerStyle(spacingProps, theme, insets);

    return (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View style={[containerStyles, { gap: theme.SizeMd }]}>
          {new Array(numLoadingSections).fill(1).map((_, sectionIdx) => (
            <Box key={sectionIdx} gap="xs">
              {renderLabels && !label && <Loader.Skeleton color="strong" />}
              {!renderLabels && !!label && (
                <Box paddingLeft="sm">
                  <Heading size="h4">{label}</Heading>
                </Box>
              )}
              <Card gap="xs">
                {new Array(numLoadingRows).fill(1).map((_, rowIdx) => (
                  <Loader.Skeleton key={`${sectionIdx}_${rowIdx}`} height="large" />
                ))}
              </Card>
            </Box>
          ))}
        </View>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <ShopifyFlashList
      keyboardDismissMode="on-drag"
      estimatedItemSize={47}
      extraData={{
        theme,
        insets,
      }}
      refreshControl={
        onRefresh ? (
          <RefreshControl refreshing={refreshing ?? false} onRefresh={onRefresh} />
        ) : undefined
      }
      ListEmptyComponent={
        !emptyState || emptyState.enabled !== false ? <EmptyState {...emptyState} /> : undefined
      }
      {...rest}
      {...getFlashListProps(spacingProps, theme, insets)}
    />
  );
}
