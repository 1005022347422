import { I18n } from "@meraki/core/i18n";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useInventoryDevices } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { capitalize, groupBy } from "lodash";

interface DeviceOverviewCardProps {
  networkId?: string;
}

export function DeviceOverviewCard({ networkId }: DeviceOverviewCardProps) {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();
  const organizationId = useCurrentOrganizationId();
  const { data: devices = [], isLoading } = useInventoryDevices(
    {
      organizationId,
    },
    {
      select(data) {
        return networkId ? data.filter((device) => device.networkId === networkId) : data;
      },
    },
  );

  const devicesByType = groupBy(devices, "productType");

  return (
    <Card
      loadingContentHeight={60}
      loading={isLoading}
      testID="DEVICE_OVERVIEW_CARD"
      onPress={() => navigation.navigate("DevicesList")}
    >
      <Card.Header title={I18n.t("HARDWARE.TITLE")} />
      {devices.length > 0 ? (
        <Card.Content flexDirection="row" gap="xs">
          <Card.Well
            flex={1}
            onPress={() => navigation.navigate("DevicesList", { productTypeFilters: ["switch"] })}
          >
            <Heading size="h2">{devicesByType["switch"]?.length ?? 0}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text size="p3" weight="semiBold">
                {capitalize(I18n.t("PRODUCT_LINES.GS.FAMILY"))}
              </Text>
            </Box>
          </Card.Well>
          <Card.Well
            flex={1}
            onPress={() =>
              navigation.navigate("DevicesList", { productTypeFilters: ["appliance"] })
            }
          >
            <Heading size="h2">{devicesByType["appliance"]?.length ?? 0}</Heading>
            <Text size="p3" weight="semiBold">
              {capitalize(I18n.t("PRODUCT_LINES.GX.FAMILY"))}
            </Text>
          </Card.Well>
          <Card.Well
            flex={1}
            onPress={() => navigation.navigate("DevicesList", { productTypeFilters: ["wireless"] })}
          >
            <Heading size="h2">{devicesByType["wireless"]?.length ?? 0}</Heading>
            <Text size="p3" weight="semiBold">
              {capitalize(I18n.t("PRODUCT_LINES.GR.FAMILY"))}
            </Text>
          </Card.Well>
        </Card.Content>
      ) : (
        <Text size="p2">{I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_HARDWARE_ADDED.TITLE")}</Text>
      )}
    </Card>
  );
}
