export function startsWith(strToSearch: string, searchStrings: string[]) {
  return searchStrings.some((str) => strToSearch.startsWith(str));
}

export function replaceAll(str: string, pattern: string, replacement: string) {
  return str.replace(new RegExp(pattern, "gi"), replacement);
}

export const cleanSupportMessageText = (
  messageBody: string | undefined | null,
  type: "preview" | "bubble",
) => {
  // cascading email replies will only show new content
  const pattern = new RegExp("On .* wrote:\n\n&gt;");
  if (messageBody) {
    let dirtyText = messageBody;

    dirtyText = dirtyText.split(pattern)[0];
    dirtyText = dirtyText.split("\n--")[0];
    dirtyText = dirtyText.split("| .ılı.ılı.")[0];

    return type == "preview" ? dirtyText.replace(/[\r\n]+/g, " ").trim() : dirtyText.trim();
  }
  return;
};
