import { AnalyticsObject } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { Box } from "@meraki/magnetic/layout";
import { PressableProps } from "react-native";

import { Button, ButtonProps } from "../Button/Button";
import { Text } from "../Text/Text";

type FooterPanelProps = {
  explanatoryText?: string;

  isPrimaryActionDisabled?: boolean;
  onPrimaryPress: PressableProps["onPress"];
  primaryAnalytics?: AnalyticsObject<"onPress">;
  primaryText?: string;

  onSecondaryPress: PressableProps["onPress"];
  secondaryAnalytics?: AnalyticsObject<"onPress">;
  secondaryText?: string;
  secondaryKind?: ButtonProps["kind"];
};

export function FooterPanel({
  explanatoryText,

  isPrimaryActionDisabled,
  onPrimaryPress,
  primaryAnalytics,
  primaryText = I18n.t("NEXT"),

  onSecondaryPress,
  secondaryAnalytics,
  secondaryText = I18n.t("BACK"),
  secondaryKind = "secondary",
}: FooterPanelProps) {
  return (
    <Box flexDirection="row" paddingVertical="md" justifyContent="space-between">
      {explanatoryText && (
        <Box flex={1} justifyContent="center">
          <Text size="p3" color="light">
            {explanatoryText}
          </Text>
        </Box>
      )}
      <Box flex={1} flexDirection="row" gap="xs">
        <Box flex={1} justifyContent="center">
          <Button
            kind={secondaryKind}
            text={secondaryText}
            onPress={onSecondaryPress}
            analytics={secondaryAnalytics}
          />
        </Box>
        <Box flex={1}>
          <Button
            kind="primary"
            text={primaryText}
            onPress={onPrimaryPress}
            analytics={primaryAnalytics}
            disabled={isPrimaryActionDisabled}
          />
        </Box>
      </Box>
    </Box>
  );
}
