import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { SafeAreaView, StyleSheet } from "react-native";
import { WebView } from "react-native-webview";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SettingsStackProps } from "~/go/navigation/Types";
import { isWeb } from "~/lib/PlatformUtils";
import { OpenSourceLicensesScreen as MagnetizedOpenSourceLicensesScreen } from "~/migrationZone/enterprise/settings/screens/OpenSourceLicensesScreen/OpenSourceLicensesScreen";
import LoadingSpinner from "~/shared/components/LoadingSpinner";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "OpenSourceLicenses">;

const OpenSourceLicensesScreen = ({}: Props) => {
  return (
    <SafeAreaView style={styles.container}>
      <WebView
        startInLoadingState
        source={{ uri: "https://web.meraki-go.com/licenses" }}
        newWindow={isWeb()}
        renderLoading={() => <LoadingSpinner visible />}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const MagnetizedAboutScreen = withMagneticReplacementAdapter(
  OpenSourceLicensesScreen,
  MagnetizedOpenSourceLicensesScreen,
);

export default MagnetizedAboutScreen;
