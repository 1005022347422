import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function SystemsManager({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M3 4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11.5V9A1.5 1.5 0 0 1 16 7.5h3V5a1 1 0 0 0-1-1H3ZM13 17H9v2H7.5a.5.5 0 0 0 0 1H14a.5.5 0 0 0 0-1h-1v-2Z"
        fill={color1}
      />
      <Path
        d="M17 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1h-4Zm2 9.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
    </Svg>
  );
}
