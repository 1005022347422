import * as Throughput from "../handlers/Throughput";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useThroughput(deviceId: string): LiveTool<string> {
  const msg = useLiveBroker<BaseDeviceSubProps, Throughput.Message>({
    type: "Throughput",
    deviceId,
  });

  return useCachedFormatter(msg, Throughput.formatMsg);
}
