import { Box, ShiftBy } from "@meraki/magnetic/layout";

import { Heading } from "../Heading/Heading";
import { NumeralProps } from "./types";

export function NumeralInternal({ size, value, units, showNoData = true }: NumeralProps) {
  const valueSize = size === "n1" ? "h1" : "h2";
  const unitSize = size === "n1" ? "h3" : "h4";
  const offset = size === "n1" ? -4 : -1;

  if (!value && !showNoData) {
    return null;
  }

  return (
    <Box flexDirection="row" alignItems="flex-end">
      <Heading size={valueSize}>{value ?? "-"}</Heading>
      <ShiftBy y={offset}>
        <Heading size={unitSize}>{units}</Heading>
      </ShiftBy>
    </Box>
  );
}
