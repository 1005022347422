import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { EditExcludeBottomSheet, SUPPORTED_UMBRELLA_POLICIES } from "@meraki/go/device";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button, Card, List, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  UmbrellaProtection,
  useUmbrellaPolicies,
  useUmbrellaProtection,
  useUpdateUmbrellaProtection,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Pressable } from "react-native";
import Toast from "react-native-simple-toast";

export function SecurityEventScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();
  const route = useRoute<RouteProp<MonitorStackProps, "SecurityEvent">>();

  const { securityEvent, device, client } = route.params;
  const networkId = useCurrentNetworkId();

  const { data: umbrellaProtection } = useUmbrellaProtection({ networkId });
  const isUnblocked = umbrellaProtection?.excludedDomains?.some(
    (excludedDomain) => securityEvent?.domain?.includes(excludedDomain),
  );

  const { mutate: updateUmbrella } = useUpdateUmbrellaProtection();

  const { data: protection } = useUmbrellaProtection({
    networkId,
  });

  const organizationId = useCurrentOrganizationId();
  const { data: goPolicies } = useUmbrellaPolicies(
    { organizationId },
    {
      select(data) {
        return data.filter(({ name }) => SUPPORTED_UMBRELLA_POLICIES.includes(name));
      },
    },
  );

  const defaultPolicy = goPolicies?.find((policy) => policy.default);

  const methods = useForm<UmbrellaProtection>({
    values: protection && {
      ...protection,
      umbrellaPolicyId: protection?.umbrellaPolicyId ?? defaultPolicy?.id ?? null,
    },
  });

  const onAddBlockedUrl = () => {
    const newExcluded = [...methods.watch("excludedDomains"), stagedExcludedURL];
    setStagedExcludedURL("");
    methods.setValue("excludedDomains", newExcluded, { shouldDirty: true });
    const umbrellaProtection = { ...methods.getValues(), excludedDomains: newExcluded };

    updateUmbrella(
      {
        networkId,
        umbrellaProtection,
      },
      {
        onError(error) {
          Alert.alert(error.errors ? error.errors.join(",") : I18n.t("SERVER_ERROR_TEXT"));
        },
        onSuccess() {
          Toast.showWithGravity(I18n.t("SECURITY_EVENT_DETAILS.UNBLOCKED"), Toast.SHORT, Toast.TOP);
        },
        onSettled() {
          bottomSheetRef.current?.dismiss();
        },
      },
    );
  };

  const [showAlert, setShowAlert] = useState(true);
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  let alertTitle: string | undefined;
  let alertMessage: string | undefined;
  if (device) {
    alertTitle = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING_DEVICE.TITLE");
    alertMessage = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING_DEVICE.MESSAGE", {
      client_word: I18n.t("DEVICE_WORD"),
    });
  } else if (client) {
    alertTitle = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING.TITLE");
    alertMessage = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING.MESSAGE");
  }

  const [stagedExcludedURL, setStagedExcludedURL] = useState<string>(securityEvent.domain);

  return (
    <Screen addDefaultPadding>
      <Text size="p1">{I18n.t("SECURITY_EVENT_DETAILS.WHY_BLOCKED")}</Text>
      <Text size="p2" color="light">
        {I18n.t("SECURITY_EVENT_DETAILS.CONTENT_EVENT", {
          categoryLabel: securityEvent.policycategories[0]?.label,
        })}
      </Text>
      <Box gap="md">
        <List>
          <List.Item
            title={I18n.t("SECURITY_EVENT_DETAILS.WEBSITE_URL")}
            rightAccessory={
              <Text size="p1" color="light">
                {securityEvent.domain}
              </Text>
            }
          />
          <List.Item
            title={I18n.t("SECURITY_EVENT_DETAILS.EVENT_TIME")}
            rightAccessory={
              <Text size="p1" color="light">
                {formatDate(securityEvent.timestamp, {
                  dateFormat: "longDate",
                  timeFormat: "shortTime",
                  unixTimestamp: false,
                })}
              </Text>
            }
          />
        </List>
        {client && (
          <Box gap="xs">
            <List label={I18n.t("SECURITY_EVENT_DETAILS.WHAT_DEVICES")}>
              <List.Item
                title={I18n.t("SECURITY_EVENT_DETAILS.NAME")}
                rightAccessory={
                  <Text size="p1" color="light">
                    {client.description || client.mac}
                  </Text>
                }
              />
              {client.status === "Online" ? (
                <List.Item
                  title={I18n.t("CLIENT_DETAILS.CONNECTED_SINCE")}
                  rightAccessory={
                    <Text size="p1" color="light">
                      {formatDate(client.firstSeen, {
                        dateFormat: "longDate",
                        timeFormat: "shortTime",
                        unixTimestamp: false,
                      })}
                    </Text>
                  }
                />
              ) : (
                <List.Item
                  title={I18n.t("CLIENT_DETAILS.LAST_SEEN")}
                  rightAccessory={
                    <Text size="p1" color="light">
                      {formatDate(client.lastSeen, {
                        dateFormat: "longDate",
                        timeFormat: "shortTime",
                        unixTimestamp: false,
                      })}
                    </Text>
                  }
                />
              )}
            </List>
            <Button
              kind="tertiary"
              text={I18n.t("SECURITY_EVENT_DETAILS.SEE_ALL")}
              onPress={() => navigation.navigate("ClientDetails", { id: client.id })}
              testID="SECURITY_EVENT.CLIENT_DETAILS_BUTTON"
            />
          </Box>
        )}
        {alertTitle && alertMessage && showAlert && (
          <Card paddingBottom="lg">
            <Box
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              paddingBottom="xs"
            >
              <Text size="p1">{alertTitle}</Text>
              <Pressable onPress={() => setShowAlert(false)}>
                <Icon name="X" />
              </Pressable>
            </Box>
            <Text size="p2" color="light">
              {alertMessage}
            </Text>
          </Card>
        )}
      </Box>
      <Button
        text={
          isUnblocked
            ? I18n.t("SECURITY_EVENT_DETAILS.UNBLOCKED")
            : I18n.t("SECURITY_EVENT_DETAILS.UNBLOCK")
        }
        onPress={() => bottomSheetRef.current?.present()}
        disabled={isUnblocked}
      />
      <EditExcludeBottomSheet
        bottomSheetRef={bottomSheetRef}
        onSave={onAddBlockedUrl}
        initialURL={securityEvent.domain}
        type="unblock"
      />
    </Screen>
  );
}
