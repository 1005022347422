import { currentLanguage, I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { Picker } from "@react-native-picker/picker";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { PICKER_WHEEL_SIZE, SPACING } from "~/constants/MkiConstants";
import { isAndroid } from "~/lib/PlatformUtils";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import { currentMaintenanceWindow } from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import WrappingRow from "~/shared/rows/WrappingRow";
import { UpgradeWindow } from "~/shared/types/Preferences";
import { RootState } from "~/shared/types/Redux";

const getHoursOfDay = () => {
  const date = new Date();
  const locale = currentLanguage();
  const options = { hour: "2-digit" } as const;
  const hours: { label: string; value: string }[] = [];
  for (let i = 0; i < 24; i++) {
    date.setHours(i, 0, 0, 0);
    let label = date.toLocaleTimeString(locale, options);
    if (label.toLocaleLowerCase().includes("am") || label.toLocaleLowerCase().includes("pm")) {
      label = label.replace(/^0(?:0:0?)?/, "");
    }
    hours.push({
      label,
      value: `${i}:00`,
    });
  }
  return hours;
};

const DAYS_OF_WEEK = [
  { value: "Sun", label: I18n.t("DAYS_OF_THE_WEEK.SUNDAY") },
  { value: "Mon", label: I18n.t("DAYS_OF_THE_WEEK.MONDAY") },
  { value: "Tue", label: I18n.t("DAYS_OF_THE_WEEK.TUESDAY") },
  { value: "Wed", label: I18n.t("DAYS_OF_THE_WEEK.WEDNESDAY") },
  { value: "Thu", label: I18n.t("DAYS_OF_THE_WEEK.THURSDAY") },
  { value: "Fri", label: I18n.t("DAYS_OF_THE_WEEK.FRIDAY") },
  { value: "Sat", label: I18n.t("DAYS_OF_THE_WEEK.SATURDAY") },
];

type Item = {
  value: string;
  label?: string;
  testID?: string;
};

const renderItems = (items: Item[]) => {
  return items.map((item) => (
    <Picker.Item
      key={item.value}
      label={item.label || ""}
      value={item.value}
      testID={item.testID}
    />
  ));
};

const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

type ReduxProps = {
  maintenanceWindow?: UpgradeWindow;
};

export interface MaintenanceWindowPickerProps extends ReduxProps {
  onChange: (selectedDay: string, selectedHour: string) => void;
  isLoading: boolean;
}

export interface MaintenanceWindowPickerState {
  selectedDay: string;
  selectedHour: string;
  waitingForValue: boolean;
}

export class MaintenanceWindowPicker extends PureComponent<
  MaintenanceWindowPickerProps,
  MaintenanceWindowPickerState
> {
  HOURS_OF_DAY = getHoursOfDay();

  constructor(props: MaintenanceWindowPickerProps) {
    super(props);
    const { maintenanceWindow } = props;
    this.state = {
      selectedDay: maintenanceWindow?.hourOfDay || "",
      selectedHour: maintenanceWindow?.dayOfWeek || "",
      waitingForValue: false,
    };
  }

  componentDidUpdate() {
    const { maintenanceWindow, isLoading } = this.props;
    const { waitingForValue } = this.state;
    if (isLoading) {
      this.setState({ waitingForValue: true });
    }
    if (maintenanceWindow && waitingForValue) {
      this.setState({
        selectedDay: maintenanceWindow.dayOfWeek,
        selectedHour: maintenanceWindow.hourOfDay,
        waitingForValue: isLoading,
      });
    }
  }

  handleOnChange = (day: string, hour: string) => {
    const { onChange } = this.props;
    onChange(day, hour);
  };

  onDayChange = (selectedDay: string) => {
    const { selectedHour } = this.state;

    this.setState({ selectedDay });
    this.handleOnChange(selectedDay, selectedHour);
  };

  onHourChange = (selectedHour: string) => {
    const { selectedDay } = this.state;

    this.setState({ selectedHour });
    this.handleOnChange(selectedDay, selectedHour);
  };

  renderWindowRow = () => {
    const { selectedDay, selectedHour } = this.state;
    const { theme } = useTheme.getState();
    const textColor = themeColors(theme).text.default;
    const iconColor = themeColors(theme).text.default.color;
    const pickerStyle = [styles.picker, isAndroid() ? textColor : {}];

    return (
      <WrappingRow>
        <Picker
          style={pickerStyle}
          itemStyle={[styles.itemPicker, textColor]}
          selectedValue={selectedDay}
          onValueChange={this.onDayChange}
          mode="dropdown"
          dropdownIconColor={iconColor}
        >
          {renderItems(DAYS_OF_WEEK)}
        </Picker>
        <Picker
          style={pickerStyle}
          itemStyle={[styles.itemPicker, textColor]}
          selectedValue={selectedHour}
          onValueChange={this.onHourChange}
          dropdownIconColor={iconColor}
        >
          {renderItems(this.HOURS_OF_DAY)}
        </Picker>
      </WrappingRow>
    );
  };

  render() {
    const { isLoading } = this.props;
    return <View>{isLoading ? renderLoading() : this.renderWindowRow()}</View>;
  }
}

const styles = StyleSheet.create({
  picker: {
    flex: 1,
    backgroundColor: MkiColors.fullTransparent,
  },
  itemPicker: {
    height: sizeSelect(PICKER_WHEEL_SIZE),
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    maintenanceWindow: currentMaintenanceWindow(state),
  };
}

export default compose(connect(mapStateToProps, null))(MaintenanceWindowPicker);
