import { getProductType } from "@meraki/shared/devices";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withNodeSubscription } from "~/lib/liveBroker";
import Device from "~/shared/types/Device";
import { WrappedScreenComponent } from "~/shared/types/Hocs";
import { PortStatusJson } from "~/shared/types/LiveBroker";
import { ProductType } from "~/shared/types/Networks";
import { basicMapDispatchToProps } from "~/store";

export interface HardwarePortStatusProps {
  device: Device;

  liveGXPortData?: PortStatusJson;
  liveSwitchPortData?: PortStatusJson;
}

const handler = (data: any, props: any) => {
  const { actions, device } = props;
  const serial = device.serial;
  const model = device.model;

  if (getProductType(model) === ProductType.appliance) {
    actions.setGXportData(data, serial);
    return { liveGXPortData: data };
  } else {
    return { liveSwitchPortData: data.ports };
  }
};

// todo: type this as part of DM-5765
const withHardwarePortStatusData = <P extends HardwarePortStatusProps>(
  WrappedComponent: WrappedScreenComponent<P>,
) => {
  return compose<any>(
    connect(null, basicMapDispatchToProps),
    withNodeSubscription({
      typeWithProps: (props: any) => {
        if (props.showAllPorts) {
          return undefined;
        }
        return getProductType(props.device.model) === ProductType.appliance
          ? "PortStatusJson"
          : "SwitchPortStats";
      },
      handler: ({ data }: any, props: HardwarePortStatusProps) => handler(data, props),
      deviceId: (props: HardwarePortStatusProps) => props.device?.id,
    }),
    HardwarePortStatusComponent,
  )(WrappedComponent);
};

export const HardwarePortStatusComponent = <P extends object>(
  WrappedComponent: WrappedScreenComponent<P>,
) =>
  class HardwarePortStatus extends PureComponent<P> {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withHardwarePortStatusData;
