import { I18n } from "@meraki/core/i18n";
import { DeviceOverviewCard } from "@meraki/go/device";
import { Notification, RefreshControl } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  useGetLicenses,
  useInventoryDevices,
  useOrgNetworks,
  useSupportCases,
} from "@meraki/shared/api";
import { useCurrentOrganizationId, useCurrentUserEmail } from "@meraki/shared/redux";
import { useState } from "react";

import { LicenseSummaryCard } from "../components/LicenseSummaryCard";
import { SupportCaseSummaryCard } from "../components/SupportCaseSummaryCard";

export function MonitorScreen() {
  const [showBanner, setShowBanner] = useState(true);

  const organizationId = useCurrentOrganizationId();
  const userEmail = useCurrentUserEmail();

  const { data: networks } = useOrgNetworks({ organizationId });

  const {
    data: openSupportCases,
    refetch: refetchSupportCases,
    isRefetching: supportCasesIsRefetching,
  } = useSupportCases(
    { encryptedNetworkId: networks?.[0]?.eid, userEmail },
    {
      select(data) {
        return Object.values(data).filter((supportCase) => {
          return (
            supportCase.suppliedEmail === userEmail &&
            supportCase.status !== "Closed" &&
            supportCase.organization.id === organizationId
          );
        });
      },
    },
  );

  const { refetch: refetchLicense, isRefetching: licenseIsRefetching } = useGetLicenses({
    organizationId,
  });
  const { refetch: refetchDevices, isRefetching: devicesIsRefetching } = useInventoryDevices({
    organizationId,
  });
  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={licenseIsRefetching || supportCasesIsRefetching || devicesIsRefetching}
          onRefresh={() => {
            refetchLicense();
            refetchSupportCases();
            refetchDevices();
          }}
        />
      }
      addDefaultPadding
    >
      {showBanner && (
        <Notification.Inline
          status="informational"
          message={I18n.t("MONITOR.ORG_MESSAGE")}
          onDismiss={() => setShowBanner(false)}
        />
      )}
      <DeviceOverviewCard />
      <LicenseSummaryCard />

      {openSupportCases && openSupportCases.length > 0 && <SupportCaseSummaryCard />}
    </Screen>
  );
}
