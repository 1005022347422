import { I18n } from "@meraki/core/i18n";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import StatusIcon from "~/shared/components/StatusIcon";
import { GoStatus } from "~/shared/constants/Status";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

interface HighEventClientRowProps {
  name: string | null;
  count: number;
  timespanName?: string;
  isOnline?: boolean;
  onPress?: () => void;
}

const HighEventClientRow = (props: HighEventClientRowProps) => {
  const { name, count, isOnline, onPress, timespanName } = props;
  if (!name || name === "" || typeof count !== "number" || !timespanName || timespanName === "") {
    return null;
  }

  let icon;
  if (onPress) {
    const status = isOnline ? GoStatus.good : GoStatus.offline;
    icon = <StatusIcon status={status} screenStyles={styles.statusIcon} />;
  }

  return (
    <DisclosureRow
      icon={icon}
      subtitle={I18n.t("HIGH_EVENT_CLIENTS_ROW.SUBTITLE", { numberOfEvents: count, timespanName })}
      renderAccessory={!!onPress}
      onPress={onPress}
    >
      {name}
    </DisclosureRow>
  );
};

const styles = StyleSheet.create({
  statusIcon: {
    marginRight: SPACING.default,
  },
});

export default HighEventClientRow;
