import { get, pickBy } from "lodash";
import { createSelector } from "reselect";

import { makeRowsFromActivities, mapClientId } from "~/lib/UmbrellaUtils";
import {
  getActivitiesByIp,
  getEventCountsByIp,
  getSecurityEvents,
  getUmbrellaProtection,
  getUmbrellaState,
} from "~/selectors/getters";
import { getNetworkHasAppliance } from "~/selectors/networkProductTypes";
import { getNetworkTimezone } from "~/selectors/orgNetworks";
import { RootState } from "~/shared/types/Redux";
import { TbuciHistory } from "~/shared/types/Tbuci";
import {
  EventCountByIp,
  EventCountRich,
  UmbrellaActivity,
  UmbrellaProtection,
} from "~/shared/types/Umbrella";

export const getHighEventIps = createSelector(
  getEventCountsByIp,
  (eventCountsByIp: EventCountByIp): EventCountByIp =>
    pickBy(eventCountsByIp, (value: EventCountRich) => value.high),
);

export const getHighEventIpsCount = createSelector(
  getHighEventIps,
  (highEventIps: EventCountByIp) => Object.keys(highEventIps).length,
);

export const getEventCountForIp = createSelector(
  getEventCountsByIp,
  (_: RootState, ip: string) => ip,
  (highEventIps: EventCountByIp, ip: string) => get(highEventIps, [ip, "count"], 0),
);

// Need a constant here to preserve reference equality for caching
const emptySecurityEvents = {};

export const getSecurityEventsSections = createSelector(
  getSecurityEvents,
  getNetworkTimezone,
  (securityEvents?: UmbrellaActivity[], timezone?: string) => {
    if (!securityEvents || !timezone) {
      return emptySecurityEvents;
    }

    return makeRowsFromActivities(securityEvents, timezone);
  },
);

export const getSecurityEventsWithClientIds = createSelector(
  getSecurityEvents,
  (_: RootState, currentTbuciHistory: TbuciHistory) => currentTbuciHistory,
  (securityEvents: UmbrellaActivity[], currentTbuciHistory: TbuciHistory) => {
    return securityEvents.map((activity: UmbrellaActivity) => ({
      ...activity,
      clientId: mapClientId(currentTbuciHistory, activity.internalip, activity.timestamp),
    }));
  },
);

export const hasUmbrellaLicenseSelector = createSelector(getUmbrellaState, (umbrella) =>
  get(umbrella, "status.hasClaimedUmbrellaLicense", false),
);

export const isUmbrellaProvisionedSelector = createSelector(getUmbrellaState, (umbrella) =>
  get(umbrella, "status.isUmbrellaProvisioned", false),
);

export const umbrellaOrganizationIdSelector = createSelector(getUmbrellaState, (umbrella) =>
  get(umbrella, "status.umbrellaOrganizationId"),
);

export const isUmbrellaEnabledSelector = createSelector(
  getUmbrellaProtection,
  (protection?: UmbrellaProtection) => get(protection, "umbrellaProtectionEnabled", false),
);

export const getSecurityEventsSectionsForIp = createSelector(
  getActivitiesByIp,
  (_: RootState, payload: any) => payload.ipAddress,
  getNetworkTimezone,
  (activitiesByIp, ipAddress, timezone?: string) => {
    if (!timezone) {
      return emptySecurityEvents;
    }

    return makeRowsFromActivities(activitiesByIp[ipAddress], timezone);
  },
);

export const isUmbrellaAccountReadySelector = createSelector(
  getNetworkHasAppliance,
  hasUmbrellaLicenseSelector,
  isUmbrellaProvisionedSelector,
  (hasGXDevice, hasUmbrellaLicense, isUmbrellaProvisioned) =>
    hasGXDevice && hasUmbrellaLicense && isUmbrellaProvisioned,
);

export const shouldShowUmbrellaUISelector = createSelector(
  isUmbrellaAccountReadySelector,
  isUmbrellaEnabledSelector,
  (isUmbrellaAccountReady, isUmbrellaEnabled) => isUmbrellaAccountReady && isUmbrellaEnabled,
);
