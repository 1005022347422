import {
  CLEAR_VERIFIED_EMAIL,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  VERIFY_EMAIL_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

interface RegistrationReduxState {
  isRegisteringAccount: boolean;
  verifiedEmail: string;
}

export const initialState = {
  isRegisteringAccount: false,
  verifiedEmail: "",
};

export default function registration(state: RegistrationReduxState = initialState, action: any) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return { ...state, isRegisteringAccount: true };
    case REGISTER_SUCCESS:
    case REGISTER_FAILURE:
      return { ...state, isRegisteringAccount: false };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, verifiedEmail: action.response.email };
    case CLEAR_VERIFIED_EMAIL:
      return { ...state, verifiedEmail: "" };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
