import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { HomeStackProps } from "~/go/navigation/Types";
import AccountScreen from "~/go/screens/AccountScreen";
import HomeScreen from "~/go/screens/HomeScreen";
import SpeedTestScreen from "~/go/screens/SpeedTestScreen";
import HighEventClientsListScreen from "~/go/screens/umbrella/HighEventClientsListScreen";
import SecurityEventDetailsScreen from "~/go/screens/umbrella/SecurityEventDetailsScreen";
import SecurityEventsListScreen from "~/go/screens/umbrella/SecurityEventsListScreen";
import WhatsNewDetailScreen from "~/go/screens/whatsNew/WhatsNewDetailScreen";
import WhatsNewListScreen from "~/go/screens/whatsNew/WhatsNewListScreen";
import WishScreen from "~/go/screens/WishScreen";
import OrgChooseScreen from "~/shared/screens/OrgChooseScreen";

import { addHardwareScreens } from "./AddHardwareScreens";
import { hardwareScreens } from "./HardwareScreens";
import { useDefaultScreenOptions } from "./Options";
import { ContactSupportStack } from "./SupportStack";
import { umbrellaScreens } from "./UmbrellaScreens";

const ForwardedHomeScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "Home">>()(HomeScreen);
const ForwardedOrgChooseScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "OrgChoose">>()(
    OrgChooseScreen,
  );
const ForwardedHighEventClientsListScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HomeStackProps, "HighEventClientsList">
>()(HighEventClientsListScreen);
const ForwardedSecurityEventDetailsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HomeStackProps, "SecurityEventDetails">
>()(SecurityEventDetailsScreen);
const ForwardedSecurityEventsListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "SecurityEventsList">>()(
    SecurityEventsListScreen,
  );
const ForwardedWishScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "Wish">>()(WishScreen);
const ForwardedWhatsNewListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "WhatsNewList">>()(
    WhatsNewListScreen,
  );
const ForwardedWhatsNewDetailScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "WhatsNewDetail">>()(
    WhatsNewDetailScreen,
  );
const ForwardedAccountScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "Account">>()(
    AccountScreen,
  );
const ForwardedSpeedTestScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HomeStackProps, "SpeedTest">>()(
    SpeedTestScreen,
  );

const Stack = createNativeStackNavigator<HomeStackProps>();

const HomeStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen name="Home" component={ForwardedHomeScreen} options={{ headerShown: false }} />
      <Stack.Screen
        name="OrgChoose"
        component={ForwardedOrgChooseScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SETTINGS.ADVANCED.CHANGE_GO_ORG.TITLE"),
        }}
      />
      <Stack.Screen
        name="SecurityEventsList"
        component={ForwardedSecurityEventsListScreen}
        options={{
          headerTitle: I18n.t("SECURITY_EVENTS_LIST.TITLE"),
        }}
      />
      <Stack.Screen
        name="SecurityEventDetails"
        component={ForwardedSecurityEventDetailsScreen}
        options={{
          headerTitle: I18n.t("SECURITY_EVENTS_LIST.BLOCKED_REQUESTS"),
        }}
      />
      <Stack.Screen
        name="HighEventClientsList"
        component={ForwardedHighEventClientsListScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("HIGH_EVENT_CLIENTS_LIST.TITLE"),
        }}
      />
      <Stack.Screen
        name="SupportCenter"
        component={ContactSupportStack}
        options={{ headerShown: false, presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="Wish"
        component={ForwardedWishScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="WhatsNewList"
        component={ForwardedWhatsNewListScreen}
        options={{ headerTitle: I18n.t("WHATS_NEW.TITLE") }}
      />
      <Stack.Screen name="WhatsNewDetail" component={ForwardedWhatsNewDetailScreen} />
      <Stack.Screen
        name="Account"
        component={ForwardedAccountScreen}
        options={{ headerTitle: I18n.t("ROOT_TITLES.ACCOUNT") }}
      />
      <Stack.Screen
        name="SpeedTest"
        component={ForwardedSpeedTestScreen}
        options={{ headerTitle: I18n.t("SETTINGS.USAGE.SPEED_TEST.TITLE") }}
      />
      {hardwareScreens(Stack)}
      {addHardwareScreens(Stack)}
      {umbrellaScreens(Stack)}
    </Stack.Navigator>
  );
};

export default HomeStack;
