import { documentationUrl } from "@meraki/go/links";
import { memo } from "react";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import I18n from "~/i18n/i18n";

type Props = {
  visible: boolean;
  onExit: () => void;
  showContactSupport: () => void;
  alertTitle: string;
  alertMessage: string;
};

const PortAlertingCard = memo(function PortAlertingCard(props: Props) {
  const { visible, alertTitle, alertMessage, onExit, showContactSupport } = props;

  const contactSupportText = I18n.t("CONTACT_SUPPORT");
  const contactSupportPress = showContactSupport;

  const primaryButtonText = I18n.t("LIVE_TOOLS.LEARN_MORE");
  const onPrimaryPress = documentationUrl;

  return (
    <InlineAlert
      visible={visible}
      onExit={onExit}
      alertTitle={alertTitle}
      alertMessage={alertMessage}
      primaryButtonText={primaryButtonText}
      onPrimaryPress={onPrimaryPress}
      secondaryButtonText={contactSupportText}
      onSecondaryPress={contactSupportPress}
      screenStyles={styles.inlineAlertStyle}
      testID="PORT_CARD_ALERTING"
    />
  );
});

const styles = StyleSheet.create({
  inlineAlertStyle: {
    marginVertical: SPACING.default,
  },
});

export default PortAlertingCard;
