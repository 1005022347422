import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { RefreshControl, ScrollView, ScrollViewProps } from "react-native";

import RefreshButton from "~/go/components/RefreshButton";
import { isWeb } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";

interface RefreshControlScrollViewProps extends ScrollViewProps {
  refreshing?: boolean;
  onRefresh: (() => Promise<void>) | (() => void);
}

interface RefreshControlScrollViewState {
  reqPending: boolean;
}

export class RefreshControlScrollView extends PureComponent<
  RefreshControlScrollViewProps,
  RefreshControlScrollViewState
> {
  constructor(props: RefreshControlScrollViewProps) {
    super(props);
    this.state = { reqPending: false };
  }

  onRefresh = async () => {
    const { onRefresh } = this.props;

    this.setState({ reqPending: true });
    await onRefresh();
    this.setState({ reqPending: false });
  };

  render() {
    const { onRefresh, refreshing, children, ...rest } = this.props;
    const { theme } = useTheme.getState();
    const { reqPending } = this.state;

    const refreshControl = isWeb() ? undefined : (
      <RefreshControl
        refreshing={refreshing || reqPending}
        onRefresh={this.onRefresh}
        tintColor={themeColors(theme).spinner?.color}
      />
    );

    return (
      <ScrollView refreshControl={refreshControl} {...rest}>
        {isWeb() ? <RefreshButton onRefresh={onRefresh} /> : null}
        {children}
      </ScrollView>
    );
  }
}

export default RefreshControlScrollView;
