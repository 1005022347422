import { StyleProp, TextStyle } from "react-native";

import { ReduxAction } from "../actions";
import {
  REMOVE_PENDING_APP_LINK,
  SET_BOTTOM_TAB_BAR,
  SET_PENDING_APP_LINK,
  SET_SELECTED_VLAN_ID,
  TOGGLE_BOTTOM_TAB_VISIBILITY,
  WIPE_REDUX,
} from "../constants";

export interface NavigationState {
  selectedVlanId: number;
  bottomTabBarConfig: Partial<{
    visibility: "hidden" | "visible";
    tabs: Record<
      string,
      Partial<{
        badgeText: string;
        badgeStyle: StyleProp<TextStyle>;
      }>
    >;
  }>;
  pendingAppLink?: {
    screen: string;
    params?: Record<string, string | number | undefined>;
  };
}

const initialState: NavigationState = {
  selectedVlanId: 1,
  bottomTabBarConfig: {},
};

export function navigation(
  state: NavigationState = initialState,
  action: ReduxAction,
): NavigationState {
  switch (action.type) {
    case TOGGLE_BOTTOM_TAB_VISIBILITY: {
      return {
        ...state,
        bottomTabBarConfig: {
          ...state.bottomTabBarConfig,
          visibility: action.payload.value,
        },
      };
    }
    case SET_BOTTOM_TAB_BAR: {
      const tabName = action.payload.tabName;
      return {
        ...state,
        bottomTabBarConfig: {
          ...state.bottomTabBarConfig,
          tabs: {
            ...(state.bottomTabBarConfig?.tabs ?? {}),
            [tabName]: {
              ...(state.bottomTabBarConfig?.tabs?.[tabName] ?? {}),
              badgeText: action.payload.badgeText,
              badgeStyle: action.payload.badgeStyle,
            },
          },
        },
      };
    }
    case SET_PENDING_APP_LINK: {
      const { screen, params } = action.payload;
      return {
        ...state,
        pendingAppLink: {
          screen,
          params,
        },
      };
    }
    case REMOVE_PENDING_APP_LINK: {
      return {
        ...state,
        pendingAppLink: undefined,
      };
    }
    case SET_SELECTED_VLAN_ID: {
      return {
        ...state,
        selectedVlanId: action.payload.vlanId,
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
