import { useTheme } from "@meraki/core/theme";
import { createRef, PureComponent } from "react";
import { StyleSheet, TextInput, TextInputProps, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import FormTextInput from "~/enterprise/components/textInputs/FormTextInput";
import ConfirmAlert from "~/go/components/ConfirmAlert";
import InfoModal from "~/go/components/InfoModal";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { nestedValueExists } from "~/lib/objectHelper";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import BannerAlert from "~/shared/components/BannerAlert";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import GeneralStatus from "~/shared/constants/Status";
import { Alert } from "~/shared/types/AlertTypes";

export interface InputModalProps extends TextInputProps {
  visible: boolean;
  title: string;
  onPrimaryPress: () => void;

  primaryButtonText?: string;
  onExit?: () => void;
  subtitle?: string;
  promptIcon?: React.ReactNode;
  alert?: Alert;
  multilineMaxHeight?: number;
  maxLength?: number;
  loading?: boolean;
  customInputComponent?: React.ReactNode;
  customInputRef?: React.ReactNode;
  customInputFocus?: () => void;
  loginStyle: boolean;
  value: string;
  inputTitle?: string;
  icon?: React.ReactElement;
}

interface InputModalState {
  focused: boolean;
  hasUnreadAlert: boolean;
}

export class InputModal extends PureComponent<InputModalProps, InputModalState> {
  private textInput: React.RefObject<TextInput> = createRef();

  static defaultProps = {
    primaryButtonText: I18n.t("SAVE"),
    onExit: undefined,
    subtitle: undefined,
    promptIcon: undefined,
    alert: undefined,
    autoCapitalize: "sentences",
    keyboardType: "default",
    multiline: false,
    multilineMaxHeight: 200,
    returnKeyType: RETURN_KEY.done,
    maxLength: null,
    loading: false,
    loginStyle: false,
    value: "",
  };

  constructor(props: InputModalProps) {
    super(props);
    this.state = {
      focused: false,
      hasUnreadAlert: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: InputModalProps) {
    const { visible } = this.props;

    if (nextProps.alert && nextProps.alert.message !== "") {
      this.setState({ hasUnreadAlert: true });
    }
    // Due to component lifecycles, we need to wait one thread cycle
    // for the input to render before we focus it. On Android, we need
    // to wait a bit longer, otherwise the keyboard-avoid animation jumps.
    if (nextProps.visible && !visible) {
      const delay = platformSelect({
        ios: 0,
        android: 300,
      });
      setTimeout(() => {
        this.inputFocus();
      }, delay);
    }
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onEndEditing = () => {
    this.setState({ focused: false });
  };

  changeText = (text: string) => {
    const { onChangeText } = this.props;
    if (onChangeText) {
      onChangeText(text);
    }
  };

  inputFocus = () => {
    const { customInputFocus } = this.props;
    if (this.textInput.current) {
      this.textInput.current.focus();
    } else if (customInputFocus) {
      customInputFocus();
    }
  };

  textChange = (text: string) => {
    this.changeText(text);
    this.setState({ hasUnreadAlert: false });
  };

  unreadAlert() {
    const { alert } = this.props;
    const { hasUnreadAlert } = this.state;
    return alert && alert.status !== GeneralStatus.good && hasUnreadAlert;
  }

  goodAlert() {
    const { alert } = this.props;
    return alert && alert.message && alert.status === GeneralStatus.good;
  }

  renderDefaultInputComponent = () => {
    const defaultInput = appSelect({
      enterprise: this.defaultEnterpriseInput,
      go: this.defaultGoInput,
    });

    return defaultInput();
  };

  defaultGoInput = () => {
    const {
      value,
      onSubmitEditing,
      autoCapitalize,
      keyboardType,
      multiline,
      multilineMaxHeight,
      maxLength,
      placeholder,
      loginStyle,
      autoFocus,
      returnKeyType,
      secureTextEntry,
      icon,
    } = this.props;
    const { focused } = this.state;
    const { theme } = useTheme.getState();

    let focusedStyle;
    if (focused) {
      focusedStyle = styles.inputFocused;
    }

    const maxHeight = multiline ? multilineMaxHeight : null;
    const formStyle = [
      styles.inputField,
      styles.formFieldColor,
      loginStyle ? styles.loginText : themeColors(theme).text?.default,
    ];
    return (
      <View style={[styles.inputContainer, focusedStyle, { maxHeight }]}>
        <MkiTextInput
          testID="INPUT_MODAL.INPUT"
          autoFocus={autoFocus}
          screenStyles={formStyle}
          value={value}
          onFocus={this.onFocus}
          onBlur={this.onEndEditing}
          onEndEditing={this.onEndEditing}
          onChangeText={this.textChange}
          onSubmitEditing={onSubmitEditing}
          underlineColorAndroid="transparent"
          returnKeyType={returnKeyType}
          autoCorrect={false}
          autoCapitalize={autoCapitalize}
          blurOnSubmit={!multiline}
          keyboardType={keyboardType}
          multiline={multiline}
          maxLength={maxLength}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          revealable={loginStyle}
          showClearButton
          icon={icon}
        />
      </View>
    );
  };

  defaultEnterpriseInput = () => {
    const { value, onSubmitEditing, autoCapitalize, placeholder, inputTitle, keyboardType } =
      this.props;

    return (
      <FormTextInput
        testID="INPUT_MODAL.FORM"
        title={inputTitle}
        value={value}
        placeholder={placeholder}
        onChangeText={this.textChange}
        onSubmitEditing={onSubmitEditing}
        autoCapitalize={autoCapitalize}
        returnKeyType={RETURN_KEY.done}
        keyboardType={keyboardType}
      />
    );
  };

  // TODO: Consolidate MkiTextInput logic with a variation of InputRow
  render() {
    const {
      visible,
      title,
      subtitle,
      promptIcon,
      primaryButtonText,
      onPrimaryPress,
      onExit,
      alert,
      loading,
      loginStyle,
      customInputComponent,
    } = this.props;

    if (!visible) {
      return null;
    }
    const subtitleText = subtitle ? (
      <MkiText textStyle="default" screenStyles={styles.subtitle}>
        {subtitle}
      </MkiText>
    ) : null;
    const unwrappedPromptIcon = promptIcon ? (
      <View style={styles.promptIcon}>{promptIcon}</View>
    ) : null;
    const bannerAlert =
      alert && this.unreadAlert() ? (
        <BannerAlert
          testID="INPUT_MODAL.ALERT"
          alertType={alert.status}
          alertText={alert.message}
          screenStyles={styles.alert}
        />
      ) : null;

    const confirmAlert = this.goodAlert() ? (
      <ConfirmAlert
        subtitle={nestedValueExists(alert, ["message"], "")}
        onPrimaryPress={nestedValueExists(alert, ["primaryButtonPress"], onExit)}
        primaryButtonText={nestedValueExists(alert, ["primaryButtonText"], I18n.t("OK"))}
      />
    ) : null;
    const defaultRender = !this.goodAlert() ? (
      <View>
        <View style={promptIcon || this.unreadAlert() ? {} : styles.spacer}>
          <MkiText textStyle="subheading" screenStyles={loginStyle ? styles.loginTitleColor : {}}>
            {title}
          </MkiText>
          {subtitleText}
        </View>
        {bannerAlert}
        {unwrappedPromptIcon}
        {customInputComponent || this.renderDefaultInputComponent()}
        <View style={styles.buttonContainer}>
          <RoundedButton
            testID="INPUT_MODAL.BUTTON"
            onPress={onPrimaryPress}
            screenStyles={styles.button}
            loading={loading}
          >
            {primaryButtonText}
          </RoundedButton>
        </View>
      </View>
    ) : null;

    return (
      <InfoModal visible={visible} onExit={onExit}>
        {confirmAlert}
        {defaultRender}
      </InfoModal>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  spacer: {
    marginBottom: SPACING.default,
  },
  subtitle: {
    color: MkiColors.formFieldLabel,
    marginTop: SPACING.small,
  },
  button: {
    marginTop: SPACING.default,
    paddingHorizontal: SPACING.large,
  },
  promptIcon: {
    alignItems: "center",
    marginTop: SPACING.default,
  },
  loginText: {
    color: MkiColors.textColor,
  },
  loginTitleColor: {
    color: MkiColors.headingColor,
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: SPACING.small,
    borderBottomColor: MkiColors.inactiveInputUnderline,
    borderBottomWidth: 1,
  },
  inputFocused: {
    borderBottomColor: MkiColors.activeInputUnderline,
  },
  inputField: {
    flexGrow: 1,
  },
  formFieldColor: {
    color: MkiColors.formFieldLabel,
  },
  alert: {
    marginTop: SPACING.default,
    marginBottom: SPACING.small,
  },
});

export default InputModal;
