import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import InputRow from "~/go/rows/InputRow";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { sizeSelect } from "~/lib/themeHelper";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import { DHCPOptions, DHCPOptionTypes } from "~/shared/types/Vlans";

import { NetworkScreensPropMap } from "../navigation/Types";

const OPTIONS = [
  {
    label: I18n.t("DHCP_OPTION.OPTIONS.TIME_OFFSET"),
    value: DHCPOptions.timeOffset,
  },
  {
    label: I18n.t("DHCP_OPTION.OPTIONS.INTERFACE"),
    value: DHCPOptions.interface,
  },
  {
    label: I18n.t("DHCP_OPTION.OPTIONS.NTP"),
    value: DHCPOptions.ntp,
  },
  {
    label: I18n.t("DHCP_OPTION.OPTIONS.TFTP"),
    value: DHCPOptions.tftp,
  },
  {
    label: I18n.t("DHCP_OPTION.OPTIONS.CUSTOM"),
    value: DHCPOptions.custom,
  },
];
const TYPES = [
  {
    label: I18n.t("DHCP_OPTION.TYPES.TEXT"),
    value: "text" as const,
  },
  {
    label: I18n.t("DHCP_OPTION.TYPES.IP"),
    value: "ip" as const,
  },
  {
    label: I18n.t("DHCP_OPTION.TYPES.HEX"),
    value: "hex" as const,
  },
];

const OPTION_VALUES = Object.values<string>(DHCPOptions);
const getIsCustom = (code: string) => {
  return code === DHCPOptions.custom || !OPTION_VALUES.includes(code);
};

const getOptionLabel = (code: string) => {
  return OPTIONS.find(({ value }) => value === code)?.label ?? DHCPOptions.custom;
};

const getTypeByOption = (code: string) => {
  switch (code) {
    case DHCPOptions.timeOffset:
    case DHCPOptions.interface:
      return "integer" as const;
    case DHCPOptions.ntp:
      return "ip" as const;
    case DHCPOptions.tftp:
    default:
      return "text" as const;
  }
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "DHCPOption">;

const DHCPOptionsScreen = ({ option, onSave, onRemove }: Props) => {
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [code, setCode] = useState(option?.code ?? DHCPOptions.timeOffset);
  const [isCustom, setIsCustom] = useState(getIsCustom(code));
  const [optionLabel, setOptionLabel] = useState(getOptionLabel(code));
  const [type, setType] = useState(option?.type ?? "text");
  const [value, setValue] = useState(option?.value ?? "");

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => (
        <SaveButton
          onPress={() => {
            onSave({
              code,
              type,
              value,
            });
            navigation.goBack();
          }}
        />
      ),
    });
  }, [code, navigation, onSave, type, value]);

  const selectOption = (val: string | number | null) => {
    const newValue = val as DHCPOptions;
    const isNewOptionCustom = getIsCustom(newValue);

    setIsCustom(isNewOptionCustom);
    setCode(isNewOptionCustom ? "" : newValue);
    setOptionLabel(getOptionLabel(newValue));
    setType(getTypeByOption(newValue));
    setShowOptionModal(false);
  };

  const selectType = (val: string | number | null) => {
    const newValue = val as DHCPOptionTypes;
    setType(newValue);
    setShowTypeModal(false);
  };

  const onDelete = () => {
    onRemove?.();
    navigation.goBack();
  };

  const TYPE_LABELS = {
    ip: I18n.t("DHCP_OPTION.TYPES.IP"),
    hex: I18n.t("DHCP_OPTION.TYPES.HEX"),
    text: I18n.t("DHCP_OPTION.TYPES.TEXT"),
    integer: I18n.t("DHCP_OPTION.TYPES.INTEGER"),
  };
  return (
    <FullscreenContainerView withScroll>
      <MkiText textStyle="smallSecondary" screenStyles={styles.text}>
        {I18n.t("DHCP_OPTION.DESCRIPTION")}
      </MkiText>
      <PickerModalRow
        visible={showOptionModal}
        label={I18n.t("DHCP_OPTION.INPUTS.OPTION")}
        subtitle={optionLabel}
        items={OPTIONS}
        selectedValue={code}
        onValueSelect={selectOption}
        onPress={() => setShowOptionModal(true)}
        onExit={() => setShowOptionModal(false)}
        testID="INPUTS.OPTION"
        disableBottomBorder
      />
      <View style={styles.text}>
        <MkiText textStyle="smallSecondary">
          {I18n.t("DHCP_OPTION.INPUTS.OPTION_DESCRIPTION")}
        </MkiText>
      </View>
      <InputRow value={code} onChangeText={setCode} editable={isCustom} testID="INPUTS.CODE">
        <MkiText textStyle="default" screenStyles={styles.text}>
          {I18n.t("DHCP_OPTION.INPUTS.CODE")}
        </MkiText>
      </InputRow>
      <PickerModalRow
        visible={showTypeModal}
        label={I18n.t("DHCP_OPTION.INPUTS.TYPE")}
        subtitle={TYPE_LABELS[type]}
        items={TYPES}
        selectedValue={type}
        onValueSelect={selectType}
        containerStyles={styles.typeRow}
        onPress={isCustom ? () => setShowTypeModal(true) : undefined}
        onExit={() => setShowTypeModal(false)}
        testID="INPUTS.TYPE"
        disableBottomBorder
      />
      <View style={styles.divider} />
      {type === "ip" ? (
        <View style={styles.ipInputRow}>
          <MkiText textStyle="default">{I18n.t("DHCP_OPTION.INPUTS.VALUE")}</MkiText>
          <IPAddressTextInput
            cidrAddress={value}
            onAddressChange={setValue}
            leaveEditableBlank={false}
          />
        </View>
      ) : (
        <InputRow value={value} onChangeText={setValue} testID="INPUTS.VALUE">
          <MkiText textStyle="default" screenStyles={styles.text}>
            {I18n.t("DHCP_OPTION.INPUTS.VALUE")}
          </MkiText>
        </InputRow>
      )}
      <View style={styles.footer}>
        {onRemove != null && (
          <RoundedButton
            buttonType={ButtonType.destructive}
            containerStyles={styles.button}
            onPress={onDelete}
          >
            {I18n.t("DELETE")}
          </RoundedButton>
        )}
      </View>
    </FullscreenContainerView>
  );
};
const styles = StyleSheet.create({
  text: {
    marginHorizontal: SPACING.default,
  },
  ipInputRow: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  typeRow: {
    marginTop: SPACING.large,
  },
  divider: {
    marginHorizontal: SPACING.default,
    borderBottomColor: MkiColors.bottomBorderColor,
    borderBottomWidth: 1,
  },
  footer: {
    marginTop: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
  },
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
});

export default DHCPOptionsScreen;
