import { useIpSecVPN } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SettingsStackProps } from "~/go/navigation/Types";
import ClientVPNSettingsScreen from "~/go/screens/ClientVPNSettingsScreen";
import LoadingSpinner from "~/shared/components/LoadingSpinner";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ClientVPNSettings">;

const ClientVPNSettingsScreenWrapper = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const route = useRoute<Props["route"]>();

  const networkId = useCurrentNetworkId();
  const { data: originalSettings, isFetching } = useIpSecVPN({ networkId });

  const props = { originalSettings, isFetching, navigation, route } as Props;

  if (isFetching) {
    return <LoadingSpinner visible={true} />;
  }
  return <ClientVPNSettingsScreen {...props} />;
};

export default ClientVPNSettingsScreenWrapper;
