import { I18n } from "@meraki/core/i18n";
import {
  HelpScreen,
  NewSupportCaseScreen,
  SearchResultsScreen,
  SupportCaseDetailsScreen,
  SupportCasesListScreen,
  WishScreen,
} from "@meraki/go/help";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { Button } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useDefaultScreenOptions, useTabScreenOptions } from "@meraki/shared/navigation";
import { useNavigation } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import { DeviceGroup } from "../screenGroups/DeviceGroup";

const Stack = createNativeStackNavigator<HelpStackProps>();

const HelpStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  const tabScreenOptions = useTabScreenOptions();
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackProps>>();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="Help"
        component={HelpScreen}
        options={{
          ...tabScreenOptions,
          headerRight: () => (
            <Box justifyContent="center" flexDirection="row" alignItems="center">
              <Button.Nav
                text={I18n.t("HELP.SUPPORT")}
                trailingIcon="ChatCircleText"
                color="default.textIn.base"
                onPress={() => navigation.navigate("SupportCasesList")}
              />
            </Box>
          ),
        }}
      />
      {DeviceGroup(Stack)}
      <Stack.Screen
        name="NewSupportCase"
        component={NewSupportCaseScreen}
        options={{ headerTitle: I18n.t("SUPPORT.GOv3.TITLE") }}
      />
      <Stack.Screen name="SupportCaseDetails" component={SupportCaseDetailsScreen} />
      <Stack.Screen
        name="SupportCasesList"
        component={SupportCasesListScreen}
        options={{
          headerRight: () => (
            <Button.Nav
              text={I18n.t("HELP.NEW")}
              trailingIcon="Plus"
              onPress={() => navigation.navigate("NewSupportCase")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="SearchResults"
        component={SearchResultsScreen}
        options={{ headerTitle: I18n.t("SEARCH_RESULTS_SCREEN.TITLE") }}
      />
      <Stack.Screen
        name="Wish"
        component={WishScreen}
        options={{
          headerTitle: I18n.t("WISH.MAKE_A_WISH"),
        }}
      />
    </Stack.Navigator>
  );
};

export default HelpStack;
