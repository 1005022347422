import { I18n } from "@meraki/core/i18n";
import { ColorToken } from "@meraki/magnetic/themes";
import { RegistryResult } from "@meraki/react-live-broker";
import { PeerDetail } from "@meraki/shared/api";
import { SingleBarGraphData } from "@meraki/shared/components";
import { millisecondsToSeconds } from "date-fns";

const UNFRIENDLY_NAT = "unfriendly";
const TRY_AGAIN = "try_again";
const INSUF_REGS = "insuf_regs";
const WARNING_NAT_TYPE = [UNFRIENDLY_NAT, TRY_AGAIN, INSUF_REGS];
const DEFAULT_PORT = "9350";
const GOOD_STATUS = 4;
export interface Outage {
  status: number;
  ts: number;
  peerId?: string;
}

export interface OutageData {
  [id: string]: Outage[];
}

export interface PeerById {
  [id: string]: PeerDetail;
}

export interface ColorMap {
  positive: ColorToken;
  negative: ColorToken;
  dormant: ColorToken;
}

export const colorMap: ColorMap = {
  positive: "positive.icon.base",
  negative: "negative.icon.base",
  dormant: "dormant.icon.base",
};

const mapStatus = (status: number) =>
  status === GOOD_STATUS
    ? colorMap.positive
    : status > GOOD_STATUS
      ? colorMap.dormant
      : colorMap.negative;

export const formatOutageDataWithId = (peerOutageData?: Record<string, [number, number][]>) => {
  if (peerOutageData === undefined) {
    return [];
  }

  const peersWithData = [] as Outage[];
  Object.entries(peerOutageData ?? {}).forEach(([key, values]) =>
    values.forEach((dataPoint) => {
      peersWithData.push({ ts: dataPoint[0], status: dataPoint[1], peerId: key } as Outage);
    }),
  );

  return peersWithData;
};

export const formatOutageData = (peerOutageData?: Record<string, [number, number][]>) => {
  if (peerOutageData === undefined) {
    return undefined;
  }

  const peersWithData: OutageData = {};
  Object.entries(peerOutageData ?? {}).forEach(
    ([key, values]) =>
      (peersWithData[key] = values.map(
        (dataPoint) => ({ ts: dataPoint[0], status: dataPoint[1] }) as Outage,
      )),
  );

  return peersWithData;
};

export const getRegistryStatusColor = (data?: RegistryResult) => {
  if (!data) {
    return "neutral";
  }

  const dataStatus = data.status;

  if (data.connectionFailed || !dataStatus) {
    return "negative";
  } else if (
    dataStatus &&
    (!dataStatus.all_reg_connected || WARNING_NAT_TYPE.includes(dataStatus.nat_type))
  ) {
    return "warning";
  }
  return "positive";
};

export const getRegistryDetails = (data?: RegistryResult) => {
  if (!data || data.connectionFailed || !data.status) {
    return I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");
  }

  const dataStatus = data.status;

  let port = DEFAULT_PORT;
  if (dataStatus.contact_registry1) {
    const port1 = dataStatus.contact_registry1.split(":")[1];
    if (port1) {
      port = port1;
    }
    if (dataStatus.contact_registry2) {
      const port2 = dataStatus.contact_registry2.split(":")[1];
      if (port1 && port2 && port1 !== port2) {
        port = `${port1} & ${port2}`;
      }
    }
  }
  return port;
};

export const getPeerGraphDataGo = (outageData: Outage[] | null, timespan: number) => {
  // if no outage data available for peer, display grey (empty) bar
  if (outageData == null || outageData.length === 0) {
    return [];
  }

  const now = millisecondsToSeconds(Date.now());
  const t0 = now - timespan;

  const data: SingleBarGraphData[] = [];
  let status: ColorToken = colorMap.dormant; // defaults to offlineStatus which displays as empty
  const statusTracker = {};
  let prevTimespan = t0;

  outageData.forEach((point) => {
    const pointStatus = mapStatus(point.status);

    // if single site, acts same as how enterprise renders their data
    // otherwise, only renders good, when all values are good
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    statusTracker[point.peerId ? point.peerId : "singelePeer"] = pointStatus;

    const allValsSame = Object.values(statusTracker).every((val, i, arr) => val === arr[0]);

    // Data is only ever pushed after a new status is found
    if (allValsSame) {
      if (status !== pointStatus) {
        data.push({
          percentage: (point.ts - prevTimespan) / timespan,
          color: status,
        });
        prevTimespan = point.ts;
        status = pointStatus;
      }
    } else {
      // we know that at least 1 value must be bad so we only want to create
      //  a new data segment if we were previously good
      if (status !== colorMap.negative) {
        data.push({
          percentage: (point.ts - prevTimespan) / timespan,
          color: status,
        });
        prevTimespan = point.ts;
        status = colorMap.negative;
      }
    }
  });

  data.push({
    percentage: (now - prevTimespan) / timespan,
    color: status,
  });

  return data;
};
