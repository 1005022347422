import { Platform } from "react-native";
import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const MAX_COMMUNITY_RESPONSES = 10;

const sanitizeString = (str: string) => str.replace(/"|'|`/gi, "");

const getTopicsForQuery = (queryString: string) => {
  const columns = "topic";
  const sanitizedString = sanitizeString(queryString);
  const query = `
  SELECT ${columns}
  FROM messages
  WHERE category.id="go"
  AND subject MATCHES "${sanitizedString}"
  OR body MATCHES "${sanitizedString}"
  ORDER BY popularity DESC`;
  return { q: query };
};

const getParentPosts = (topicIDs: string[]) => {
  const stringTopicIDs = topicIDs.map((topic) => `"${topic}"`);
  const columns = "subject,search_snippet,language,view_href";
  const query = `
  SELECT ${columns}
  FROM messages
  WHERE category.id="go"
  AND id IN (${stringTopicIDs.join(",")})
  AND depth=0
  ORDER BY popularity DESC
  LIMIT ${MAX_COMMUNITY_RESPONSES}`;
  return { q: query };
};

const getTopAnnouncements = () => {
  const columns = "subject,search_snippet,language,view_href";
  const query = `
  SELECT ${columns}
  FROM messages
  WHERE category.id="go"
  AND depth=0
  AND board.id="go-announcements"
  ORDER BY post_time DESC
  LIMIT 3`;
  return { q: query };
};

export const CommunityTopicSchema = z.object({
  topic: z.object({
    href: z.string(),
    id: z.string(),
    type: z.string(),
    view_href: z.string(),
  }),
  type: z.string(),
});

export const CommunityTopicsSchema = z
  .object({
    data: z.object({
      items: z.array(CommunityTopicSchema),
      list_item_type: z.string(),
      next_cursor: z.string().optional(),
      size: z.number(),
      type: z.string(),
    }),
  })
  .describe("CommunityTopicsSchema");

export const CommunityResultSchema = z
  .object({
    language: z.string(),
    search_snippet: z.string(),
    subject: z.string(),
    type: z.string(),
    view_href: z.string(),
  })
  .describe("CommunityResultSchema");

export const CommunitySchema = z
  .object({
    data: z.object({
      items: z.array(CommunityResultSchema),
      list_item_type: z.string(),
      next_cursor: z.string().optional(),
      size: z.number(),
      type: z.string(),
    }),
  })
  .describe("CommunitySchema");

export type Community = z.infer<typeof CommunitySchema>;
export type CommunityTopics = z.infer<typeof CommunityTopicsSchema>;
export type CommunityTopic = z.infer<typeof CommunityTopicSchema>;
export type CommunityResult = z.infer<typeof CommunityResultSchema>;

const URL = "/api/2.0/search";
const baseUrl = Platform.OS === "web" ? "/community" : "https://community.meraki.com";

export function fetchCommunityTopicsData(query: string): Promise<CommunityTopics> {
  return request(CommunityTopicsSchema, "GET", URL, {
    baseUrl,
    queryParams: getTopicsForQuery(query),
  });
}

export function fetchCommunityData(topics: CommunityTopic[]): Promise<Community> {
  const topicIds = topics.map((item) => item.topic.id);
  return request(CommunitySchema, "GET", URL, {
    baseUrl,
    queryParams: getParentPosts(topicIds),
  });
}

function fetchTopAnnouncements(): Promise<Community> {
  return request(CommunitySchema, "GET", URL, {
    baseUrl,
    queryParams: getTopAnnouncements(),
  });
}

export const useCommunityTopicsData = createQuery({
  baseQueryKey: URL,
  queryFn: (query: string) => {
    return fetchCommunityTopicsData(query);
  },
});

export const useTopAnnouncements = createQuery({
  baseQueryKey: URL,
  queryFn: () => {
    return fetchTopAnnouncements();
  },
});

export const useCommunityData = createQuery({
  baseQueryKey: URL,
  queryFn: (topics: CommunityTopic[]) => {
    return fetchCommunityData(topics);
  },
});
