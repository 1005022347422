import { I18n } from "@meraki/core/i18n";
import { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { platformSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";

export default function RefreshButton({ onRefresh }: any) {
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const onPress = useCallback(async () => {
    setLoading(true);
    await onRefresh();
    setLoading(false);
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 1250);
  }, [onRefresh]);

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.row}>
          <MkiSpinner size="small" />
          <MkiText textStyle="smallLight" screenStyles={styles.leftMargin}>
            {I18n.t("REFRESH_BUTTON.UPDATING")}
          </MkiText>
        </View>
      ) : (
        <Pressable onPress={onPress}>
          <View style={styles.row}>
            <MerakiIcon name="refresh" size={23} color={MkiColors.spinner} />
            {updated && (
              <MkiText textStyle="smallLight" screenStyles={styles.leftMargin}>
                {I18n.t("REFRESH_BUTTON.UPDATED")}
              </MkiText>
            )}
          </View>
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // @ts-expect-error TS(2322) FIXME: Type '"flex" | "block"' is not assignable to type ... Remove this comment to see the full error message
    display: platformSelect({
      mobile: "flex",
      web: "block",
    }),
    marginLeft: SPACING.default,
    marginTop: SPACING.default,
    alignSelf: "flex-start",
  },
  row: {
    flexDirection: "row",
  },
  leftMargin: {
    marginLeft: SPACING.meager,
  },
});
