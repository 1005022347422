import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { get } from "lodash";
import { memo } from "react";

import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { UmbrellaActivity } from "~/shared/types/Umbrella";

interface SecurityEventRowProps {
  activity: UmbrellaActivity;
  clientName: string | null;
  timezone?: string;
  onPress: () => void;
}

const SecurityEventRow = memo(
  ({ activity, clientName, timezone, onPress }: SecurityEventRowProps) => {
    if (!activity || !clientName || !timezone || !activity.timestamp || !onPress) {
      return null;
    }
    const time = formatDate(activity.timestamp, {
      dateFormat: "hide",
      timeFormat: "shortTime",
      unixTimestamp: false,
    });
    const subtitle = I18n.t("SECURITY_EVENTS_ROW.SUBTITLE", { clientName, time });

    const { domain } = activity;
    const activityType = get(activity, "policycategories[0].label");
    const label = activityType
      ? I18n.t("SECURITY_EVENTS_ROW.SITE_LABEL", { activityType, domain })
      : domain;

    if (!label) {
      return null;
    }

    return (
      <DisclosureRow onPress={onPress} subtitle={subtitle}>
        {label}
      </DisclosureRow>
    );
  },
);

SecurityEventRow.displayName = "SecurityEventRow";

export default SecurityEventRow;
