import { memoize } from "lodash";

import I18n from "~/i18n/i18n";
import { nestedValueExists } from "~/lib/objectHelper";
import { GeneralStatus } from "~/shared/constants/Status";
import { DeviceStatusSummary } from "~/shared/types/Device";

const psl = require("psl");

const NETWORK_REGEX = /^([L|N])_(\d+)$/;
const ID_REGEX = /^(\d+)$/;
const PROTOCOL_REGEX = /(^\w+:|^)\/\//;

export default class NetworkUtils {
  static idsEqual(...idsArray: any[]) {
    if (!idsArray || idsArray.length === 0) {
      return false;
    }
    const getId = (num: any) => (num ? NetworkUtils.parseId(num.toString()).id : null);
    const value = getId(idsArray[0]);
    return idsArray.every((num, index) => index === 0 || getId(num) === value);
  }

  static parseId = memoize((idString: any) => {
    if (NETWORK_REGEX.test(idString)) {
      const [, type, id] = idString.match(NETWORK_REGEX);
      return { type, id };
    }
    if (ID_REGEX.test(idString)) {
      const [, id] = idString.match(ID_REGEX);
      return { id };
    }
    return {};
  });

  static getHostFromUrl(urlString: any) {
    const newUrl = urlString.replace(/^https?:\/\//, "");
    return nestedValueExists(psl.parse(newUrl), ["sld"], I18n.t("BLOCK_CONTENT.DEFAULT_DOMAIN"));
  }

  static getHostFromTwoLengthSplit(splitResult: any) {
    // Server does allow "www.facebook" format
    if (splitResult[0] === "www") {
      return splitResult[1].toUpperCase();
    }

    // ex: "facebook.com"
    return splitResult[0].toUpperCase();
  }

  static getDomainFromUrl(url: any) {
    const urlWithoutProtocol = url.replace(PROTOCOL_REGEX, "");
    return psl.parse(urlWithoutProtocol).domain;
  }

  static networkVlanForId(network: any, vlanId: any) {
    return network?.vlans?.find?.((vlan: any) => vlan.id === vlanId);
  }
}

export const normalizeNetworkStatus = (status: any) => {
  if (!status) {
    return GeneralStatus.bad;
  }

  if (status[DeviceStatusSummary.dormant] === status[DeviceStatusSummary.total]) {
    return GeneralStatus.dormant;
  } else if (status[DeviceStatusSummary.offline]) {
    return GeneralStatus.bad;
  } else if (status[DeviceStatusSummary.alerting]) {
    return GeneralStatus.alerting;
  }
  return GeneralStatus.good;
};
