import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { platformSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";
import { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";
import MkiText from "~/shared/components/MkiText";

type Props = {
  icon?: string;
  customIcon?: React.ReactElement;
  size?: MerakiIconProps["size"];
  header: string;
  subtitle?: string;
  context?: string;
};

const IconSectionHeader = ({ icon, customIcon, header, subtitle, size, context }: Props) => {
  const subtitleText = subtitle ? (
    <MkiText textStyle="small" screenStyles={styles.subtitle}>
      {subtitle}
    </MkiText>
  ) : null;

  return (
    <View style={styles.container}>
      {customIcon ||
        (icon && (
          <MerakiIcon
            name={icon}
            size={size}
            containerStyle={styles.icon}
            color={MkiColors.defaultIconColor}
          />
        ))}
      <View style={styles.horizontalContainer}>
        <MkiText textStyle="subheading" screenStyles={styles.contextMargin}>
          {header}
        </MkiText>
        {context && <ContextHelp context={context} />}
      </View>
      {subtitleText}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  subtitle: {
    color: MkiColors.secondaryTextColor,
  },
  icon: {
    marginBottom: SPACING.small,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  contextMargin: platformSelect({
    mobile: {},
    web: {
      marginRight: SPACING.small,
    },
  }),
});

IconSectionHeader.defaultProps = {
  size: "l",
  subtitle: null,
};

export default IconSectionHeader;
