import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import { default as FontAwesomeIcon } from "react-native-vector-icons/FontAwesome";
import { default as IonIcon } from "react-native-vector-icons/Ionicons";
import { default as MaterialCommunityIcon } from "react-native-vector-icons/MaterialCommunityIcons";
import { default as MaterialIcon } from "react-native-vector-icons/MaterialIcons";

import icoMoonConfig from "~/micons/micons.json";

const IcoMoon = createIconSetFromIcoMoon(icoMoonConfig);

enum IconType {
  IonIcon = "IonIcon",
  FontAwesomeIcon = "FontAwesomeIcon",
  MaterialIcon = "MaterialIcon",
  MaterialCommunityIcon = "MaterialCommunityIcon",
  IcoMoon = "IcoMoon",
}

export const iconLibraryMapping = {
  [IconType.IonIcon]: IonIcon,
  [IconType.FontAwesomeIcon]: FontAwesomeIcon,
  [IconType.MaterialIcon]: MaterialIcon,
  [IconType.MaterialCommunityIcon]: MaterialCommunityIcon,
  [IconType.IcoMoon]: createIconSetFromIcoMoon(icoMoonConfig),
};

export const iconLibraryForIconName = (name: string) => {
  if (IcoMoon.hasIcon(name)) {
    return IconType.IcoMoon;
  }
  if (MaterialIcon.hasIcon(name)) {
    return IconType.MaterialIcon;
  }
  if (FontAwesomeIcon.hasIcon(name)) {
    return IconType.FontAwesomeIcon;
  }
  if (IonIcon.hasIcon(name)) {
    return IconType.IonIcon;
  }

  if (MaterialCommunityIcon.hasIcon(name)) {
    return IconType.MaterialCommunityIcon;
  }
  return null;
};
