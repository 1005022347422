import Svg, { Path } from "react-native-svg";

import { useThemeColors } from "~/shared/hooks/useTheme";

import { DEFAULT_ICON_SIZES } from "../types";

type NotAlertingProps = {
  color: string;
  size?: number;
};

export const NotAlertingIcon = ({ color, size = DEFAULT_ICON_SIZES.l }: NotAlertingProps) => {
  const colors = useThemeColors();

  return (
    <Svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <Path
        d="M13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7Z"
        fill={color}
      />
      <Svg width={size} height={size} viewBox="0 0 12 14" fill="none">
        <Path
          d="M8.13801 4.195196C8.39839 4.45551 8.39845 4.87762 8.13814 5.13801L4.63919 8.63792C4.37893 8.89827 3.9569 8.89838 3.6965 8.63817L2.195441 7.13825C1.9349928 6.878 1.9348326 6.45589 2.195083 6.19544C2.455334 5.93499 2.877444 5.93483 3.13789 6.19508L4.16748 7.22389L7.1952 4.195328C7.45551 3.9349421 7.87762 3.9348832 8.13801 4.195196Z"
          fill={colors.modal.background}
        />
      </Svg>
    </Svg>
  );
};

export default NotAlertingIcon;
