import { ScrollView, ScrollViewProps } from "react-native";

import PaddedKeyboardAvoidingView from "~/shared/components/PaddedKeyboardAvoidingView";

interface MkiKeyboardAwareScrollViewProps extends ScrollViewProps {
  disableVerticalOffset?: boolean;
}

const MkiKeyboardAwareScrollView: React.FunctionComponent<MkiKeyboardAwareScrollViewProps> = (
  props: MkiKeyboardAwareScrollViewProps,
) => {
  const { disableVerticalOffset, ...rest } = props;
  const scrollableComponent = <ScrollView {...rest} />;

  return (
    <PaddedKeyboardAvoidingView disableVerticalOffset={disableVerticalOffset}>
      {scrollableComponent}
    </PaddedKeyboardAvoidingView>
  );
};

export default MkiKeyboardAwareScrollView;
