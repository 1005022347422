import { PureComponent, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

export interface FormItemProps {
  children?: ReactNode;
  title?: string;
  footer?: string;
  testID?: string;
}

class FormItem extends PureComponent<FormItemProps> {
  renderSecondaryText = (text?: string) =>
    text ? (
      <MkiText textStyle="smallSecondary" screenStyles={styles.secondaryText}>
        {text}
      </MkiText>
    ) : null;

  renderTitle = () => {
    const { title } = this.props;
    return this.renderSecondaryText(title);
  };

  renderFooter = () => {
    const { footer } = this.props;
    return this.renderSecondaryText(footer);
  };

  render() {
    const { children, testID } = this.props;

    return (
      <View style={styles.container} testID={testID}>
        {this.renderTitle()}
        {children}
        {this.renderFooter()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginVertical: SPACING.small,
  },
  secondaryText: {
    marginTop: SPACING.small,
  },
});

export default FormItem;
