import {
  ColorToken,
  getColorFromTokenName,
  Theme,
  useMagneticTheme,
} from "@meraki/magnetic/themes";
import { StyleProp, View, ViewStyle } from "react-native";

import { Text, TextProps } from "../Text/Text";

export type CounterProps = {
  testID?: string;
  value: number;
  status?: "general" | "positive" | "warning" | "negative";
};

type PossibleStatuses = Exclude<CounterProps["status"], undefined>;
type InternalProps = Omit<CounterProps, "value">;

const BACKGROUNDCOLOR_MAP: Record<PossibleStatuses, ColorToken> = {
  general: "control.bg.medium.disabled",
  positive: "positive.bg.base",
  warning: "warning.bg.base",
  negative: "negative.bg.base",
};

const TEXTCOLOR_MAP: Record<PossibleStatuses, ColorToken> = {
  general: "default.text.base",
  positive: "positive.textIn.base",
  warning: "warning.textIn.base",
  negative: "negative.textIn.base",
};

function getContainerStyle({ status }: InternalProps, theme: Theme): StyleProp<ViewStyle> {
  let backgroundColor = theme.color.control.bg.medium.disabled;

  if (status) {
    backgroundColor = getColorFromTokenName(BACKGROUNDCOLOR_MAP[status], theme);
  }

  return {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor,
    borderColor: theme.color.default.bg.weak.base,
    borderWidth: theme.SizeStrokeStrong,
    borderRadius: theme.SizeRadiusBorderLg,
    paddingHorizontal: theme.SizeInputPaddingHorizSmRare,
    minWidth: 23,
    gap: 4,
  };
}

function getTextProps({ status }: InternalProps): TextProps {
  let color: ColorToken = "default.text.base";

  if (status) {
    color = TEXTCOLOR_MAP[status];
  }

  return {
    size: "p4",
    color,
    weight: "semiBold",
  };
}

export function Counter({ value, testID, status }: CounterProps) {
  const theme = useMagneticTheme();
  const internalProps = { status };

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={getContainerStyle(internalProps, theme)} testID={testID}>
        <Text {...getTextProps(internalProps)}>{value > 99 ? "99+" : value}</Text>
      </View>
    </View>
  );
}
