import DataMsg from "../types/DataMsg";

export interface Props {
  deviceId: string;
}

// Index 0 of this response is unlike the rest
// has extra u_ifindex: 0, wan_remapped: NOT_REMAPPED;
export interface Message extends DataMsg {
  data: PortStatus[];
}

export interface PortStatus {
  // Index 0 metadata
  u_ifindex?: number;
  wan_remapped?: string;
  // only when using_poe === true
  power_consumption?: number;
  // common across
  carrier: boolean;
  using_poe: boolean;
  duplex: boolean;
  id: string;
  speed: string;
  node_id: string;
}

export function formatMsg(message?: Message): PortStatus[] | undefined {
  const statuses = message?.data;
  return statuses;
}
