import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";

const buildUrl = ({ organizationId }: UnclaimDeviceRequest) => {
  return `/api/v1/organizations/${organizationId}/inventory/release`;
};

interface UnclaimDeviceRequest {
  organizationId?: string;
}

type MutateUnclaimDeviceRequest = UnclaimDeviceRequest & UnclaimDevice;

export const UnclaimDeviceSchema = z
  .object({ serials: z.array(z.string()) })
  .describe("UnclaimDeviceSchema");

export type UnclaimDevice = z.infer<typeof UnclaimDeviceSchema>;

const createUnclaimDevice = ({ organizationId, serials }: MutateUnclaimDeviceRequest) => {
  return request(UnclaimDeviceSchema, "POST", buildUrl({ organizationId }), {
    body: JSON.stringify({ serials }),
  });
};

export const useUnclaimDevice = createMutation<
  MutateUnclaimDeviceRequest,
  UnclaimDevice,
  APIResponseError
>({
  baseMutationKey: buildUrl({ organizationId: `{organizationId}` }),
  mutationFn: (parameters: MutateUnclaimDeviceRequest) => createUnclaimDevice(parameters),
});
