import { Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { mapColorToken, useMagneticTheme } from "@meraki/magnetic/themes";
import SvgChart from "@wuba/react-native-echarts/svgChart";
import Color from "color";
import type { DimensionDefinitionLoose, LabelFormatterParams, ScaleDataValue } from "echarts";
import * as echarts from "echarts/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { baseChartColors, getColorList } from "../utils/colors";
import { ChartData } from "../utils/data";

type ChartSize = "sm" | "md" | "lg";
export interface PieChartProps {
  testID?: string;
  kind?: "pie" | "donut";
  size?: ChartSize;
  data: ChartData;
  dimensions: DimensionDefinitionLoose[];
  showAnimation?: boolean;
  showLabel?: boolean;
  loading?: boolean;
  showTooltip?: boolean;
  tooltipLabelFormatter?: (timespan: ScaleDataValue) => string;
  handleGesture?: boolean;
}

const getHeight = (size: ChartSize) => {
  switch (size) {
    case "sm":
      return 80;
    case "md":
      return 200;
    case "lg":
      return 300;
  }
};

export function PieChart({
  testID,
  kind = "pie",
  size = "md",
  data,
  showAnimation = true,
  showLabel = true,
  loading = false,
  tooltipLabelFormatter,
  dimensions,
  showTooltip,
  handleGesture,
}: PieChartProps) {
  const theme = useMagneticTheme();
  const chartRef = useRef(null);
  const [chartInstance, setChart] = useState<echarts.EChartsType | null>(null);

  const [width, setWidth] = useState<number | null>(null);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  // This effect is responsible for the basic setup of the chart instance.
  useEffect(() => {
    if (!width) return;

    const chart = echarts.init(chartRef.current, "light", {
      renderer: "svg",
      width: !!width && width > 0 ? width : undefined,
      height: getHeight(size),
    });

    setChart(chart);

    return () => chart.dispose();
  }, [size, width]);

  // This effect is responsible for setting the basic chart options
  useEffect(() => {
    chartInstance?.setOption({
      animation: showAnimation,
      animationDuration: 500,
      animationEasingUpdate: "quinticInOut",
      color: getColorList(theme),
      ...(showTooltip && {
        tooltip: {
          backgroundColor: mapColorToken(baseChartColors.toolTipBackground, theme),
          borderColor: mapColorToken(baseChartColors.toolTipBorder, theme),
          textStyle: {
            color: mapColorToken(baseChartColors.toolTipText, theme),
          },
          confine: true,
          axisPointer: {
            label: {
              formatter: tooltipLabelFormatter
                ? (params: LabelFormatterParams): string => {
                    return tooltipLabelFormatter?.(params.value) || "";
                  }
                : undefined,
            },
          },
        },
      }),
    });
  }, [chartInstance, showAnimation, theme, showTooltip, tooltipLabelFormatter]);

  // This effect is responsible for setting the data related options
  useEffect(() => {
    chartInstance?.setOption({
      dataset: {
        dimensions: dimensions,
        source: data,
      },
      series: [
        {
          type: "pie",
          radius: kind === "pie" ? ["0%", "100%"] : ["40%", "70%"],
          top: 6,
          bottom: 6,
          label: {
            show: showLabel,
          },
          labelLine: {
            show: showLabel,
          },
          itemStyle: {
            borderRadius: 0,
            borderColor: "#fff",
            borderWidth: 2,
          },
          emphasis: {
            focus: true,
          },
          blur: {
            itemStyle: {
              opacity: 0.5,
            },
          },
        },
      ],
    });
  }, [chartInstance, theme, kind, dimensions, showLabel, data]);

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <SvgChart ref={chartRef} handleGesture={!!handleGesture} useRNGH />
      {loading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Color(theme.color.default.bg.weak.base).alpha(0.8).hexa(),
          }}
        >
          <Loader.Spinner />
        </View>
      )}
    </Box>
  );
}
