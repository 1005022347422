import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import IntroTwoFactorScreen from "~/go/screens/IntroTwoFactorScreen";
import SuccessTwoFactorScreen from "~/go/screens/SuccessTwoFactorScreen";

import SetupTwoFactorScreen from "../screens/SetupTwoFactorScreen";
import VerifyTwoFactorScreen from "../screens/VerifyTwoFactorScreen";
import { TwoFactorScreensPropMap } from "./Types";

const ForwardedIntroTwoFactorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<TwoFactorScreensPropMap, "IntroTwoFactor">
  >()(IntroTwoFactorScreen);
const ForwardedSetupTwoFactorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<TwoFactorScreensPropMap, "SetupTwoFactor">
  >()(SetupTwoFactorScreen);
const ForwardedVerifyTwoFactorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<TwoFactorScreensPropMap, "VerifyTwoFactor">
  >()(VerifyTwoFactorScreen);
const ForwardedSuccessTwoFactorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<TwoFactorScreensPropMap, "SuccessTwoFactor">
  >()(SuccessTwoFactorScreen);

export function twoFactorScreens<T extends TwoFactorScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="IntroTwoFactor"
        component={ForwardedIntroTwoFactorScreen}
        options={({ route }) => ({
          presentation: route.params?.presentedModally ? "fullScreenModal" : undefined,
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.INTRO_TWO_FACTOR.TITLE"),
        })}
      />
      <Stack.Screen
        name="SetupTwoFactor"
        component={ForwardedSetupTwoFactorScreen}
        options={{
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.SETUP_TWO_FACTOR.TITLE"),
        }}
      />
      <Stack.Screen
        name="VerifyTwoFactor"
        component={ForwardedVerifyTwoFactorScreen}
        options={{
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.VERIFY_TWO_FACTOR.TITLE"),
        }}
      />
      <Stack.Screen
        name="SuccessTwoFactor"
        component={ForwardedSuccessTwoFactorScreen}
        options={{
          headerTitle: I18n.t("ONBOARDING_FULLSTACK.INTRO_TWO_FACTOR.TITLE"),
        }}
      />
    </>
  );
}
