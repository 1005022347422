import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface ClientVPNRequest {
  email?: string;
  networkId?: string;
  id?: string;
  password?: string | null;
  name?: string | null;
  isAdmin?: boolean | null;
  emailPasswordToUser?: boolean | null;
}

interface AuthsOnNetwork {
  clientVPNEnabled: boolean;
}

export const AccountType = z.union([
  z.literal("Guest"),
  z.literal("802.1X"),
  z.literal("Client VPN"),
]);

export const AuthUserSchema = z
  .object({
    id: z.string(),
    accountType: AccountType,
    ssidNumber: z.optional(z.number()),
    authorizations: z.array(
      z.object({
        authorizedByEmail: z.string(),
        authorizedByName: z.string(),
        authorizedZone: z.string(),
        expiresAt: z.string(),
      }),
    ),
    createdAt: z.string(),
    email: z.string(),
    isAdmin: z.boolean(),
    name: z.string(),
  })
  .describe("AuthUserSchema");

export const AuthUsersSchema = z.array(AuthUserSchema).describe("AuthUsersSchema");

export type AuthUsers = z.infer<typeof AuthUsersSchema>;

export type AuthUser = z.infer<typeof AuthUserSchema>;

export type AuthType = z.infer<typeof AccountType>;

interface AuthUsersRequest {
  networkId?: string;
}

interface AuthUserRequest {
  networkId?: string;
  id: string;
}
const buildMutationUrl = ({ networkId, id }: ClientVPNRequest) => {
  if (id) {
    return `/api/v1/networks/${networkId}/merakiAuthUsers/${id}`;
  }
  return `/api/v1/networks/${networkId}/merakiAuthUsers`;
};

export function fetchAuthUsers({ networkId }: AuthUsersRequest): Promise<AuthUsers> {
  return request(AuthUsersSchema, "GET", buildMutationUrl({ networkId }));
}

export function fetchAuthUser({ networkId, id }: AuthUserRequest): Promise<AuthUser> {
  if (!id) {
    throw new Error("Undefined id when fetching auth users");
  }
  return request(AuthUserSchema, "GET", buildMutationUrl({ networkId, id }));
}

export const mutateEnableClientVPN = ({
  email,
  networkId,
  name,
  password,
  isAdmin,
  emailPasswordToUser,
}: ClientVPNRequest) => {
  return request(AuthUserSchema, "POST", buildMutationUrl({ networkId }), {
    body: JSON.stringify({
      email,
      name,
      accountType: "Client VPN",
      isAdmin,
      password,
      authorizations: [{ ssidNumber: 0 }],
      emailPasswordToUser,
    }),
  });
};

export const mutateDisableClientVPN = ({ id, networkId }: ClientVPNRequest) => {
  return request(AuthUserSchema, "DELETE", buildMutationUrl({ id, networkId }));
};

export const useEnableClientVPN = createMutation<ClientVPNRequest, AuthUser, APIResponseError>({
  baseMutationKey: buildMutationUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => mutateEnableClientVPN(variables),
});

export const useDisableClientVPN = createMutation<ClientVPNRequest, AuthUser, APIResponseError>({
  baseMutationKey: buildMutationUrl({ networkId: "{networkId}", id: "{id}" }),
  mutationFn: (variables) => mutateDisableClientVPN(variables),
});

export const useAuthUsers = createQuery<AuthUsersRequest, AuthUsers>({
  baseQueryKey: buildMutationUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchAuthUsers(variables),
});

export const useAuthUser = createQuery<AuthUserRequest, AuthUser>({
  baseQueryKey: buildMutationUrl({ networkId: "{networkId}", id: "{id}" }),
  queryFn: (variables) => fetchAuthUser(variables),
  requiredVariables: ["networkId", "id"],
});

export function filterAuthUsersByEmail(data?: AuthUsers) {
  return data?.reduce<Record<string, AuthsOnNetwork>>((result, user) => {
    result[user.email] = { clientVPNEnabled: user.accountType === "Client VPN" };
    return result;
  }, {});
}

export const useAuthUsersByEmail = (networkId?: string) => {
  return useAuthUsers(
    { networkId },
    {
      select: (data) => filterAuthUsersByEmail(data),
    },
  );
};
