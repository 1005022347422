import { I18n } from "@meraki/core/i18n";
import { isEqual } from "lodash";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showNetworkErrorWithRetry, showSaveWarning } from "~/lib/AlertUtils";
import { getChannelLabel, getChannelWidthLabel, getPowerLabel } from "~/lib/RadioSettingsUtils";
import { deviceState } from "~/selectors";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import Device from "~/shared/types/Device";
import { RadioSettingsBands, RadioSettingsOnBand } from "~/shared/types/RadioSettingsTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { HardwareStackPropMap } from "../navigation/Types";

type ReduxProps = {
  device: Device;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "RadioSettingsList"> &
  BasicActions &
  ReduxProps &
  PendingComponent &
  WithCancelablePromiseProps;

type RadioSettingsListState = {
  [key in RadioSettingsBands]?: RadioSettingsOnBand | null;
};

export class RadioSettingsListScreen extends PureComponent<Props, RadioSettingsListState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      twoFourGhzSettings: {
        channel: null,
        targetPower: null,
      },
      fiveGhzSettings: {
        channel: null,
        channelWidth: null,
        targetPower: null,
      },
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerLeft: () => (
        <CloseButton
          onPress={() => {
            if (this.isChanged()) {
              showSaveWarning(this.saveRadioSettings, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => (
        <SaveButton onPress={this.saveRadioSettings} disabled={!this.isChanged()} />
      ),
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.setNavOptions();
  }

  isChanged() {
    const { twoFourGhzSettings, fiveGhzSettings } = this.state;

    const { device } = this.props;
    const { radioSettings } = device;

    return !(
      (twoFourGhzSettings == null ||
        isEqual(twoFourGhzSettings, radioSettings?.twoFourGhzSettings)) &&
      (fiveGhzSettings == null || isEqual(fiveGhzSettings, radioSettings?.fiveGhzSettings))
    );
  }

  resetRadioStates() {
    const { device } = this.props;
    const { radioSettings } = device;

    this.setState({
      twoFourGhzSettings: {
        ...radioSettings?.twoFourGhzSettings,
      },
      fiveGhzSettings: {
        ...radioSettings?.fiveGhzSettings,
      },
    });
  }

  async loadData() {
    const { actions, cancelablePromise, setReqPending, serialNumber } = this.props;

    if (serialNumber == null) {
      return;
    }

    setReqPending(true);
    try {
      await cancelablePromise(actions.getRadioSettings(serialNumber));
    } catch (e) {
      showNetworkErrorWithRetry(this.loadData);
    }
    this.resetRadioStates();
    setReqPending(false);
  }

  getRadioSettingsOnBands() {
    const { twoFourGhzSettings, fiveGhzSettings } = this.state;

    return {
      twoFourGhzSettings: {
        ...(twoFourGhzSettings || {}),
      },
      fiveGhzSettings: {
        ...(fiveGhzSettings || {}),
      },
    };
  }

  saveRadioSettings = async () => {
    const { actions, cancelablePromise, handleError, serialNumber, setReqPending, navigation } =
      this.props;
    const newSettings = this.getRadioSettingsOnBands();

    setReqPending(true);
    try {
      await cancelablePromise(actions.setRadioSettings(serialNumber, newSettings));
    } catch (e) {
      if (typeof e === "string") {
        handleError(e);
      }
    }
    setReqPending(false);
    navigation.goBack();
  };

  updateSettings(band: RadioSettingsBands, settings: RadioSettingsOnBand) {
    this.setState({
      [band]: settings,
    });
  }

  showRadioSettings = (band: RadioSettingsBands) => {
    const { navigation } = this.props;
    const settingsOnBands = this.getRadioSettingsOnBands();

    navigation.navigate("RadioSettings", {
      band,
      radioSettings: { ...settingsOnBands[band] },
      updateSettings: (band, settings) => this.updateSettings(band, settings),
    });
  };

  showTwoFourRadioSettings = () => this.showRadioSettings(RadioSettingsBands.twoFourGhzSettings);
  showFiveRadioSettings = () => this.showRadioSettings(RadioSettingsBands.fiveGhzSettings);

  render() {
    const settings = this.getRadioSettingsOnBands();
    const twoFourGhzSettings = settings?.twoFourGhzSettings;
    const fiveGhzSettings = settings?.fiveGhzSettings;

    return (
      <FullscreenContainerView>
        <DisclosureRow
          subtitle={I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.SUBTITLE", {
            channel: getChannelLabel(twoFourGhzSettings?.channel),
          })}
          subtitle2={I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.SUBTITLE2", {
            target_power: getPowerLabel(twoFourGhzSettings?.targetPower),
          })}
          onPress={this.showTwoFourRadioSettings}
          testID="TWO_GHZ_SETTINGS_ROW"
        >
          {I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.LABEL")}
        </DisclosureRow>
        <DisclosureRow
          subtitle={I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.SUBTITLE", {
            channel: getChannelLabel(fiveGhzSettings?.channel),
            channel_width: getChannelWidthLabel(fiveGhzSettings?.channelWidth),
          })}
          subtitle2={I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.SUBTITLE2", {
            target_power: getPowerLabel(fiveGhzSettings?.targetPower),
          })}
          onPress={this.showFiveRadioSettings}
          testID="FIVE_GHZ_SETTINGS_ROW"
        >
          {I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.LABEL")}
        </DisclosureRow>
      </FullscreenContainerView>
    );
  }
}

function mapStateToProps(
  state: RootState,
  props: HardwareStackPropMap["RadioSettingsList"],
): ReduxProps {
  return {
    device: deviceState(state, props),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(RadioSettingsListScreen);
