import { z } from "zod";

import { request } from "../../api/request/request";
import { ProductTypeSchema, StatusSchema } from "../../schemas";
import { createQuery } from "../createQuery";

const buildUrl = ({ organizationId }: TopNetworkSummariesByStatusBuildUrlRequest) => {
  return `/api/v1/organizations/${organizationId}/summary/top/networks/byStatus`;
};

interface TopNetworkSummariesByStatusRequest {
  organizationId?: string;
}
interface TopNetworkSummariesByStatusBuildUrlRequest {
  organizationId: string;
}

export const TopNetworkSummaryNetworkSchema = z.object({
  networkId: z.string(),
  name: z.string(),
  url: z.string(),
  tags: z.array(z.string()),
  productTypes: z.array(ProductTypeSchema),
  clients: z.object({
    counts: z.object({ total: z.number() }),
    usage: z.object({ upstream: z.number(), downstream: z.number() }),
  }),
  statuses: z.object({
    overall: StatusSchema,
    byProductType: z.array(
      z.object({
        productType: ProductTypeSchema,
        counts: z.object({
          online: z.number(),
          offline: z.number(),
          dormant: z.number(),
          alerting: z.number(),
        }),
      }),
    ),
  }),
  devices: z.object({
    byProductType: z.array(z.object({ productType: ProductTypeSchema, url: z.string() })),
  }),
});

export const TopNetworkSummariesByStatusSchema = z.array(TopNetworkSummaryNetworkSchema);

export type TopNetworkSummariesByStatus = z.infer<typeof TopNetworkSummariesByStatusSchema>;

const fetchTopNetworkSummariesByStatus = ({
  organizationId,
}: TopNetworkSummariesByStatusRequest) => {
  if (organizationId === undefined) {
    throw new Error("organizationId is not defined");
  }
  return request(TopNetworkSummariesByStatusSchema, "GET", buildUrl({ organizationId }));
};

export const useTopNetworkSummariesByStatus = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: TopNetworkSummariesByStatusRequest) =>
    fetchTopNetworkSummariesByStatus(parameters),
});
