import { Image, ImageProps, StyleSheet } from "react-native";

export type TabIconProps = {
  icon: ImageProps["source"];
  focusedIcon?: ImageProps["source"];
  focused?: boolean;
  tintColor?: string;
};

const TabIcon = ({ icon, focusedIcon, focused, tintColor }: TabIconProps) => {
  return (
    <Image
      source={focusedIcon ? (focused ? focusedIcon : icon) : icon}
      style={[styles.tabButtonIcon, { tintColor }]}
    />
  );
};

const styles = StyleSheet.create({
  tabButtonIcon: {
    width: 30,
    height: 30,
  },
});

export default TabIcon;
