import {
  addZeroes,
  chartDateFormatter,
  formatUseBlocks,
  useblocksGraphseries,
} from "~/lib/formatHelper";
import { calculateMaxAndDomain, formatBitsAxis } from "~/lib/MkiChartUtils";
import MkiLineChart from "~/shared/components/MkiLineChart";

type Props = {
  usageHistory?: number[][];
  timezone?: string;
  timespan: number;
  onTimeChosen?: () => void;
  loading?: boolean;
  t0?: number;
  t1?: number;
  chartWidth?: number;
};

export const ClientUsageGraph = (props: Props) => {
  const { usageHistory, timespan, t0, t1, timezone, chartWidth } = props;
  if (!t0 || !t1 || !usageHistory) {
    return null;
  }
  const formattedUseblocks = formatUseBlocks(useblocksGraphseries(usageHistory, t0, t1));
  const usageData = addZeroes(formattedUseblocks, t0, t1, "x", "y");

  const t0Millis = t0 * 1000;
  const t1Milllis = t1 * 1000;
  const { max, domain } = calculateMaxAndDomain(usageData, t0Millis, t1Milllis);

  return (
    <MkiLineChart
      primaryData={usageData}
      domain={domain}
      xAxisFormatter={(t: any) => chartDateFormatter(t, timespan, timezone, true)}
      yAxisFormatter={formatBitsAxis(max)}
      chartWidth={chartWidth}
    />
  );
};
ClientUsageGraph.defaultProps = {
  onTimeChosen: undefined,
  loading: false,
  t0: undefined,
  t1: undefined,
};

export default ClientUsageGraph;
