import { formatChartBits, formatPerSecond, ParsedValueUnit } from "@meraki/shared/formatters";
import { isEmpty } from "lodash";

import { TIMESTAMP_KEY } from "~/constants/MkiConstants";

export function isEmptyMkiChartData(data: any) {
  if (isEmpty(data)) {
    return true;
  }
  for (const dataPoint of data) {
    const keys = Object.keys(dataPoint);
    for (const key of keys) {
      if (key !== TIMESTAMP_KEY && key !== "x") {
        if (dataPoint[key] && dataPoint[key] !== 0) {
          return false;
        }
      }
    }
  }
  return true;
}

export interface ChartDomain {
  x: number[];
  y?: number[];
}

export interface ChartPoint {
  x: number;
  y: number;
}

export function calculateMaxAndDomain(data: ChartPoint[], t0: number, t1: number, key?: string) {
  const domain: ChartDomain = {
    x: [t0, t1],
  };

  const dataKey = key || "y";
  const max = data
    ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data.reduce((max: number, obj: ChartPoint) => Math.max(max, obj?.[dataKey]), 0)
    : 0;

  if (max > 0) {
    domain.y = [0, max];
  }

  return { max, domain };
}

export function yAxisFormatter(result: ParsedValueUnit) {
  const { value, unit } = result;
  return formatPerSecond(`${value} ${unit}`);
}

export function formatBitsAxis(max: number) {
  return (bits: number) => {
    const result = formatChartBits(bits, max);
    return yAxisFormatter(result);
  };
}
