import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type DewarpIconProps = {
  color: string;
  size?: number;
};

export const DewarpIcon = (props: DewarpIconProps) => {
  const { color, size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <Path
        d="M28 7C28 6.44772 27.5 6 27 6C26.5 6 20.2958 8 16 8C11.7042 8 5.5 6 5 6C4.5 6 4 6.44772 4 7V25C4 25.5523 4.5 26 5 26C5.5 26 11.7042 24 16 24C20.2958 24 26.5 26 27 26C27.5 26 28 25.5523 28 25V7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M11 24V7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <Path d="M21 24V7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </Svg>
  );
};

export default DewarpIcon;
