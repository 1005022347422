import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function CellularGateway({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color2 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M4 10.5A1.5 1.5 0 0 1 5.5 9h13a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 20.5v-10Z"
        fill={color1}
      />
      <Path
        d="M8 1a1 1 0 0 0-1 1v6h2V2a1 1 0 0 0-1-1ZM16 1a1 1 0 0 0-1 1v6h2V2a1 1 0 0 0-1-1Z"
        fill={color2}
      />
    </Svg>
  );
}
