import { ReactNode } from "react";
import { SafeAreaView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BOX_BORDER_RADIUS, SPACING } from "~/constants/MkiConstants";
import { platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

export interface BoxContainerProps {
  children: ReactNode;
  screenStyles?: StyleProp<ViewStyle>;
}

const BoxContainer: React.FC<BoxContainerProps> = (props) => {
  const { children, screenStyles } = props;

  const backgroundColor = themeColors(useTheme()).navigation.backgroundPrimary;

  const viewStyle = [styles.container, screenStyles];
  return (
    <SafeAreaView style={styles.flexOne}>
      <View style={[{ backgroundColor }, viewStyle]}>{children}</View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  container: {
    flex: 1,
    marginTop: SPACING.default,
    marginBottom: SPACING.default,
    marginHorizontal: SPACING.default,
    borderRadius: BOX_BORDER_RADIUS,
    ...platformSelect({
      ios: {
        shadowColor: MkiColors.tileShadowColor,
        shadowOpacity: 0.08,
        shadowOffset: { width: 0, height: 6 },
        shadowRadius: 4,
      },
      android: { elevation: 1 },
    }),
  },
});

export default BoxContainer;
