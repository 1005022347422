import { I18n } from "@meraki/core/i18n";
import { Button, List } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import { useDeviceStatuses } from "@meraki/shared/api";
import { DEVICE_STATUS_MAP } from "@meraki/shared/devices";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import React from "react";

export const APInstallationScreen = () => {
  // TODO: get props from stepper component after it is built?
  // const route = useRoute<RouteProp<DeviceGroupProps, "APInstallation">>();
  // const { params: props } = route;

  const organizationId = useCurrentOrganizationId();
  const { data: device } = useDeviceStatuses(
    { organizationId },
    {
      select(data) {
        return data.find((device) => device.serial === "serial" /*props.serial*/);
      },
    },
  );

  return (
    <Screen addDefaultPadding>
      {/* Todo: UDG-4037 UX input probably better to make this a swipeable screen with graphics? */}
      <List label={I18n.t("ACCESS_POINT.TIPS.HEADER")}>
        <List.Item title={I18n.t("ACCESS_POINT.TIPS.STRENGTH")} />
        <List.Item title={I18n.t("ACCESS_POINT.TIPS.SEE")} />
        <List.Item title={I18n.t("ACCESS_POINT.TIPS.METAL")} />
      </List>
      <List>
        <List.Item
          title={I18n.t("ACCESS_POINT.STATUS", {
            name: device?.name ?? device?.serial,
            status: device?.status ?? "offline",
          })}
          leftAccessory={<Status status={DEVICE_STATUS_MAP[device?.status || "offline"]} />}
        />
      </List>
      {/* Todo: UDG-4017 ap test screen */}
      <Button
        text={I18n.t("ACCESS_POINT.BUTTON")}
        onPress={() => undefined}
        disabled={device?.status !== "online"}
      ></Button>
    </Screen>
  );
};
