import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function FailedToLoadIcon(props: Props) {
  const { size = BUTTON_SIZING.width.default } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 77 77" fill="none">
      <Path
        d="M72.08 50.1926C73.6148 50.727 75.3038 49.918 75.7195 48.3469C77.6311 41.1217 77.4034 33.4728 75.0297 26.3413C72.3453 18.2763 67.0674 11.3264 60.0191 6.57545C52.9709 1.82448 44.5485 -0.460435 36.0655 0.0770472C27.5825 0.614529 19.5159 3.94419 13.1236 9.54676C6.73138 15.1493 2.37287 22.7098 0.727869 31.0491C-0.917135 39.3884 0.243843 48.0376 4.02974 55.6479C7.81564 63.2582 14.0136 69.4017 21.657 73.1203C28.4158 76.4085 35.9688 77.6371 43.3823 76.6892C44.9942 76.4831 46.0177 74.9147 45.6891 73.3232V73.3232C45.3606 71.7316 43.8041 70.7218 42.1894 70.9057C36.0529 71.6044 29.8216 70.5479 24.2316 67.8283C17.7565 64.6782 12.506 59.4738 9.29877 53.0267C6.09157 46.5797 5.10806 39.2526 6.50161 32.188C7.89516 25.1234 11.5874 18.7186 17.0026 13.9725C22.4178 9.2263 29.2514 6.4056 36.4376 5.95027C43.6239 5.49495 50.7589 7.4306 56.7298 11.4554C62.7007 15.4801 67.1718 21.3677 69.4458 28.1999C71.4091 34.0982 71.6395 40.4142 70.1423 46.4061C69.7483 47.9828 70.5453 49.6582 72.08 50.1926V50.1926Z"
        fill="#E0E0E0"
      />
      <Path d="M33 51V27L51 39L33 51Z" stroke="#E0E0E0" strokeWidth="6" strokeLinejoin="round" />
      <Path
        d="M52 55L69.941 73.0588M52 73.0588L69.941 55"
        stroke="#E0E0E0"
        strokeWidth="6"
        strokeLinecap="round"
      />
    </Svg>
  );
}

export default FailedToLoadIcon;
