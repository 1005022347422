import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Loader, TabView, TabViewMethods } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { usePortStatuses } from "@meraki/react-live-broker";
import { useAllAppliancePorts, useDevices } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";

import { combineAppliancePorts } from "../../utils/PortUtils";
import { AppliancePortDetailsContext } from "./AppliancePortDetailsContext";
import { AppliancePortDetailsTab } from "./tabs/AppliancePortDetailsTab";
import { AppliancePortSettingsTab } from "./tabs/AppliancePortSettingsTab";

const DEVICE_TABS = {
  details: {
    title: I18n.t("TAB_TITLES.DETAILS"),
    view: <AppliancePortDetailsTab />,
    testID: "DETAILS_TABVIEW_TAB",
  },
  settings: {
    title: I18n.t("TAB_TITLES.SETTINGS"),
    view: <AppliancePortSettingsTab />,
    testID: "SETTINGS_TABVIEW_TAB",
  },
};

export const AppliancePortDetailsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "AppliancePortDetails">>();
  const { params: props } = route;
  const { serial, portNumber } = props;

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const tabViewRef = useRef<TabViewMethods>(null);

  const { data: device, isLoading: deviceIsLoading } = useDevices(
    {
      organizationId,
      networkId,
      serial: serial,
    },
    {
      select(data) {
        return data.find((device) => device.serial === serial);
      },
    },
  );

  const { data: appliancePort, isLoading: isPortLoading } = useAllAppliancePorts({
    networkId,
  });
  const livePortData = usePortStatuses(device?.id ?? "");
  const combinedPortData = combineAppliancePorts(appliancePort, livePortData);

  const combinedPort = combinedPortData[portNumber - 1];

  const handleGoToTab = useCallback((key: string) => {
    tabViewRef.current?.goToTab(key);
  }, []);

  useEffect(
    () =>
      navigation.setOptions({
        headerTitle:
          combinedPort?.number === 1
            ? I18n.t("PORTS.INTERNET_CONNECTION")
            : I18n.t("PORTS.NUMBER", { port_number: portNumber }),
      }),
    [navigation, portNumber, serial, combinedPort?.number],
  );

  if (!device || deviceIsLoading || !combinedPort || isPortLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  return (
    <AppliancePortDetailsContext.Provider
      value={{ device: device, appliancePort: combinedPort, goToTab: handleGoToTab }}
    >
      <Screen.View>
        <TabView tabs={DEVICE_TABS} ref={tabViewRef} />
      </Screen.View>
    </AppliancePortDetailsContext.Provider>
  );
};
