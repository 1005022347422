import { I18n } from "@meraki/core/i18n";
import { hoursToMilliseconds } from "date-fns";

import { MS_IN_A_DAY } from "~/constants/MkiConstants";
import { PlaybackRateId, VideoSettingId } from "~/enterprise/types/Cameras";

/* Historical Video */
export const NO_VIDEO_STATUS = "no_video";
export const DEFAULT_CONTROL_ANIMATION_DELAY_MS = 5000;
export const MAX_CONTROL_ANIMATION_DELAY_MS = 300000;
export const SKIP_OFFSET_SEC = 10;
export const HISTORICAL_VIDEO_DELAY = 1000;

/* Motion Search */
export const FULL_REGION_OF_INTEREST = { "0": { top: 0, left: 0, width: 60, height: 34 } };
export const PAGE_LENGTH_MS = MS_IN_A_DAY;
export const MAX_EVENT_LENGTH_SEC = 35;
export const DEFAULT_MIN_EVENT_LENGTH_SEC = 0;
export const DEFAULT_MOTION_SENSITIVITY = 100;
// Used to start playing motion events 2 secs before the start time of the event
export const MOTION_EVENT_OFFSET_MS = 2000;
export const ROI_POINT_SIZE = 50;
export const ROI_POINT_SIZE_HALF = ROI_POINT_SIZE / 2;
export const ROI_DOT_SIZE = 16;
export const MOTION_SEARCH_DEFAULT_INTERVAL = hoursToMilliseconds(3);

export const PLAYBACK_RATES = [
  { id: PlaybackRateId.RATE_0_5x, text: I18n.t("VIDEO_RATE.RATE_0_5x"), rate: 0.5 },
  { id: PlaybackRateId.NORMAL, text: I18n.t("VIDEO_RATE.NORMAL"), rate: 1.0 },
  { id: PlaybackRateId.RATE_2x, text: I18n.t("VIDEO_RATE.RATE_2x"), rate: 2 },
] as const;

export const VIDEO_SETTINGS = [
  {
    id: VideoSettingId.PLAYBACK_SPEED,
    text: I18n.t("VIDEO_SETTINGS.PLAYBACK_SPEED"),
    iconName: "slow-motion-video",
  },
] as const;

// Approximate time it takes for an image to be ready after url is generated
export const CAMERA_SNAPSHOT_TIMEOUT = 1000;
