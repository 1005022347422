import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";
interface NetworkHealthRequest {
  encryptedOrgId?: string;
  ids: string[];
  t0: number;
  t1: number;
}

export const NetworkHealthSchema = z.object({
  locale: z.record(z.string(), z.array(z.tuple([z.number(), z.number()]))),
  network: z.record(z.string(), z.array(z.tuple([z.number(), z.number()]))),
});

export type NetworkHealth = z.infer<typeof NetworkHealthSchema>;

const buildUrl = ({ encryptedOrgId }: { encryptedOrgId?: string }) => {
  return `/o/${encryptedOrgId}/manage/organization/network_health_time_series_bar`;
};

const fetchNetworkHealth = ({ encryptedOrgId, ...queryParams }: NetworkHealthRequest) => {
  return request(NetworkHealthSchema, "POST", buildUrl({ encryptedOrgId }), { queryParams });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useNetworkHealth = createQuery({
  baseQueryKey: buildUrl({ encryptedOrgId: `{encryptedOrgId}` }),
  queryFn: (parameters: NetworkHealthRequest) => fetchNetworkHealth(parameters),
  requiredVariables: ["encryptedOrgId"],
});
