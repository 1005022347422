import { config } from "@meraki/core/dev-config";

import * as DashboardActions from "~/actions";
import * as overrideActions from "~/actions/ephemeralOverrides";
import {
  AppDispatch,
  DispatchedActionCreatorsMapObject,
  patchedBindActionCreators,
} from "~/shared/types/Redux";

let actions = DashboardActions;
if (config.useEphemeral) {
  //@ts-ignore
  // TODO: consolidate types
  actions = {
    ...actions,
    ...overrideActions,
  };
}

export type Actions = DispatchedActionCreatorsMapObject<unknown, typeof DashboardActions>;

export type Thunk<A> = () => A;

export interface BasicActions {
  actions: Actions;
}

let cachedActions: null | Actions = null;

export const basicMapDispatchToProps = (dispatch: AppDispatch): BasicActions => {
  if (!cachedActions) {
    cachedActions = patchedBindActionCreators(actions, dispatch);
  }
  return { actions: cachedActions };
};
