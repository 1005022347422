import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

export function CloudIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 12 12" fill="none">
      <Path
        d="M7.50469 1.875C6.73775 1.87499 5.98596 2.08855 5.33356 2.49174C4.68116 2.89494 4.15393 3.47184 3.81094 4.15781V4.15781C3.52462 4.7247 3.37531 5.35085 3.375 5.98594C3.37664 6.08251 3.34186 6.17615 3.27758 6.24825C3.21331 6.32034 3.12425 6.36559 3.02812 6.375C2.97657 6.37888 2.92476 6.37205 2.87597 6.35495C2.82717 6.33785 2.78244 6.31084 2.74458 6.27563C2.70673 6.24041 2.67656 6.19775 2.65598 6.15031C2.6354 6.10288 2.62485 6.0517 2.625 6C2.62519 5.47599 2.70906 4.95537 2.87344 4.45781C2.88441 4.42609 2.88666 4.392 2.87996 4.3591C2.87325 4.32621 2.85784 4.29572 2.83533 4.27081C2.81282 4.24591 2.78404 4.2275 2.75199 4.21752C2.71994 4.20754 2.6858 4.20634 2.65312 4.21406C2.00291 4.37526 1.42535 4.74944 1.01248 5.27699C0.59962 5.80454 0.375205 6.4551 0.375 7.125C0.375 8.775 1.77188 10.125 3.41719 10.125H7.5C8.59402 10.1256 9.64347 9.69162 10.4175 8.91847C11.1915 8.14533 11.6267 7.09636 11.6273 6.00234C11.628 4.90833 11.194 3.85887 10.4208 3.08484C9.64767 2.31081 8.59871 1.87562 7.50469 1.875V1.875Z"
        fill="white"
        opacity={0.7}
      />
    </Svg>
  );
}

export default CloudIcon;
