import Svg, { Path } from "react-native-svg";

export const ChartIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width={30} height={30} viewBox="0 0 23 24" fill="none">
      <Path
        d="M20.125 18.8428H2.875V4.46777"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20.1252 8.78027L14.3752 13.8115L8.62518 9.49902L2.87518 14.5303"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChartIcon;
