import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

interface UpdateClientNameRequest {
  encryptedNetworkId?: string;
  clientId: string;
  name: string;
}

const buildUrl = ({ encryptedNetworkId }: { encryptedNetworkId: string }) => {
  return `/n/${encryptedNetworkId}/manage/usage/update_lcd`;
};

const mutateClientName = ({ encryptedNetworkId, ...queryParams }: UpdateClientNameRequest) => {
  if (!encryptedNetworkId) {
    throw new Error("Invalid network id");
  }
  return request(z.any(), "POST", buildUrl({ encryptedNetworkId }), {
    body: JSON.stringify({ id: queryParams.clientId, desc: queryParams.name }),
  });
};

export const useUpdateClientName = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: UpdateClientNameRequest) => mutateClientName(parameters),
});
