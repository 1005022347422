import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import { memo } from "react";
import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import I18n from "~/i18n/i18n";
import SingleBarGraph from "~/shared/components/SingleBarGraph";
import ListRow from "~/shared/rows/ListRow";

import { PeerGraphData } from "../components/PeerGraphUtils";

export type UsageRowProps = {
  id?: string;
  children?: string;
  usage: number;
  usageSuffix?: string;
  percent: number;
  color?: PeerGraphData["color"];
  onPress?: () => void;
  renderSeparators?: boolean;
  numberOfLines?: number;
  testID?: string;
};

const UsageRow = memo(function UsageRow(props: UsageRowProps) {
  const { onPress, testID, renderSeparators = false, numberOfLines = 1 } = props;
  const rowStyles = renderSeparators ? [styles.row, styles.separator] : styles.row;
  const data = props.color
    ? [{ percentage: props.percent, color: props.color }]
    : [{ percentage: props.percent }];
  const { usageSuffix } = props;

  const { value: usage, unit } = formatAndParseKibibytes(props.usage);

  return (
    <ListRow
      onPress={onPress}
      subtitle1={`${usage} ${usageSuffix ?? unit}`}
      accessory={<SingleBarGraph data={data} graphStyles={styles.usageGraph} roundCorners />}
      rowStyles={rowStyles}
      rightStyle={styles.rightStyles}
      ellipsizeMode="middle"
      numberOfLines={numberOfLines}
      testID={testID}
    >
      {props.children || I18n.t("APPLICATION_USAGE.UNKNOWN")}
    </ListRow>
  );
});

const styles = StyleSheet.create({
  row: {
    paddingHorizontal: 16,
  },
  separator: {
    borderTopWidth: 1,
    borderTopColor: MkiColors.borderColor,
  },
  usageGraph: {
    width: 120,
  },
  rightStyles: {
    width: "35%",
  },
});

export default UsageRow;
