import * as errorMonitor from "@meraki/core/errors";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { CHECKMARK_SIZE } from "~/constants/MkiConstants";
import { SPLASH_PAGE_ID } from "~/constants/Splash";
import ConfigHeader from "~/go/components/ConfigHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import DefaultItemLabel from "~/go/rows/DefaultItemLabel";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { getSplashPage } from "~/lib/SplashUtils";
import {
  currentNetworkState,
  slimSsidsByIdSelector,
  splashSettingsOnSSIDsSelector,
} from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import MkiTable, { RowData } from "~/shared/components/MkiTable";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { SplashSettings } from "~/shared/types/SplashSettings";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  networkId: string;
  splashSettings: SplashSettings;
  ssid: SSID;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SplashSelect"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

interface SplashSelectScreenState {
  splashPage?: string;
}

type SplashRow = {
  key: keyof typeof SPLASH_PAGE_ID;
  label: string;
  altDefaultLabel?: string;
  testID?: string;
};

export class SplashSelectScreenComponent extends PureComponent<Props, SplashSelectScreenState> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { actions, cancelablePromise, handleError, setReqPending, ssidNumber } = this.props;

    setReqPending(true);
    try {
      const promiseOrUndefined = actions.getSSIDSplashSettings(ssidNumber);
      if (promiseOrUndefined) {
        await cancelablePromise(promiseOrUndefined);
      }
    } catch (e) {
      if (typeof e === "string") {
        handleError(e);
      }
    }
    setReqPending(false);
  };

  showSplashPageSetup() {
    const { navigation, ssidNumber } = this.props;
    navigation.navigate("SplashPageSetup", { ssidNumber });
  }

  showCustomURLSetup() {
    // for now identical. As more features are added, these will start to differ more and more.
    const { navigation, ssidNumber } = this.props;
    navigation.navigate("SplashCustomURLSetup", { ssidNumber });
  }

  setSplashPageToNone = () => this.saveNewSplashSettings(SPLASH_PAGE_ID.none);

  // eslint-disable-next-line react/destructuring-assignment
  async saveNewSplashSettings(newSplashPage: string) {
    const {
      actions,
      cancelablePromise,
      handleError,
      networkId,
      setReqPending,
      ssidNumber,
      splashSettings,
    } = this.props;

    if (newSplashPage === getSplashPage(splashSettings)) {
      return;
    }

    setReqPending(true);
    try {
      await cancelablePromise(
        actions.setSsid(networkId, {
          number: ssidNumber,
          splashPage: SPLASH_PAGE_ID.none,
        }),
      );
      await this.getData();
    } catch (e) {
      if (typeof e === "string") {
        handleError(e);
      }
    }
    setReqPending(false);
  }

  handlePress = (rowData: SplashRow) => {
    const { splashSettings } = this.props;
    const ssidSplashPage = getSplashPage(splashSettings);

    switch (rowData.key) {
      case SPLASH_PAGE_ID.none:
        if (ssidSplashPage === SPLASH_PAGE_ID.none) {
          this.setState({ splashPage: SPLASH_PAGE_ID.none });
        }
        this.setSplashPageToNone();
        break;
      case SPLASH_PAGE_ID.clickThrough:
        if (ssidSplashPage === SPLASH_PAGE_ID.clickThrough) {
          this.setState({ splashPage: SPLASH_PAGE_ID.clickThrough });
        }
        this.showSplashPageSetup();
        break;
      case SPLASH_PAGE_ID.customURL:
        if (ssidSplashPage === SPLASH_PAGE_ID.customURL) {
          this.setState({ splashPage: SPLASH_PAGE_ID.customURL });
        }
        this.showCustomURLSetup();
        break;
      // TODO: including Facebook splash is a stretch feature for Launch
      /*
      case "facebook":
        this.setState({ splashPage: SplashMethods.facebook, error: null });
        break;
      */
      default:
        break;
    }
  };

  renderRow = (rowData: RowData<SplashRow>) => {
    const { splashSettings } = this.props;
    const splashPage = getSplashPage(splashSettings);

    const emptyView = <View style={styles.emptyView} />;
    const selectedRow: Record<SplashRow["key"], React.ReactElement> = {
      none: emptyView,
      clickThrough: emptyView,
      customURL: emptyView,
    };

    if (splashPage) {
      selectedRow[splashPage] = (
        <MerakiIcon name="done" size={CHECKMARK_SIZE} color={MkiColors.primaryButton} />
      );
    }

    const label =
      rowData.key === SPLASH_PAGE_ID.none ? (
        <DefaultItemLabel altDefaultLabel={rowData.altDefaultLabel}>
          {rowData.label}
        </DefaultItemLabel>
      ) : (
        rowData.label
      );

    return (
      <SimpleDisclosureRow
        icon={selectedRow[rowData.key]}
        onPress={() => rowData?.onPress?.()}
        hideDisclosureIcon={rowData.key === SPLASH_PAGE_ID.none}
        testID={rowData.testID}
      >
        {label}
      </SimpleDisclosureRow>
    );
  };

  render() {
    const data = [
      {
        key: "none" as const,
        label: I18n.t("SPLASH_CONFIG.NONE_OPT"),
        altDefaultLabel: I18n.t("SPLASH_CONFIG.NONE_ALT_DEFAULT_LABEL"),
        testID: "SPLASH.NONE",
      },
      {
        key: "clickThrough" as const,
        label: I18n.t("SPLASH_CONFIG.CLICK_THROUGH_OPT"),
        testID: "SPLASH.CLICKTHROUGH",
      },
      {
        key: "customURL" as const,
        label: I18n.t("SPLASH_CONFIG.CUSTOM_WEBSITE_OPT"),
        testID: "SPLASH.CUSTOM_URL",
      },
      // { key: "facebook", label: "Facebook WiFi" }, TODO: add post beta
    ];

    return (
      <>
        <ConfigHeader
          title={I18n.t("SPLASH_CONFIG.TITLE")}
          description={I18n.t("SPLASH_CONFIG.SUBTITLE")}
        />
        <MkiTable<SplashRow>
          data={data}
          renderRow={this.renderRow}
          onPress={this.handlePress}
          onRefresh={this.getData}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  emptyView: {
    width: CHECKMARK_SIZE,
    height: CHECKMARK_SIZE,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SplashSelect"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDDetailsScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsByIdSelector(state)[props.ssidNumber],
    splashSettings: splashSettingsOnSSIDsSelector(state)[props.ssidNumber],
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withPendingComponent,
)(SplashSelectScreenComponent);
