export const DEFAULT_VLAN_SUBNET = "192.168.128.0/24";
export interface Vlan {
  name: string;
  subnet: string;
  defaultGateway: string;
  id: string;
}

export interface VlanResponse {
  applianceIp: string;
  dhcpBootOptionsEnabled: boolean;
  dhcpHandling: string;
  dhcpLeaseTime: string;
  dhcpOptions: DHCPOption[];
  dnsNameservers: string;
  fixedIpAssignments: {
    [mac: string]: {
      ip: string;
    };
  };
  id: 1;
  name: string;
  networkId: string;
  reservedIpRanges: string[];
  subnet: string;
}

export enum DHCPOptions {
  timeOffset = "2",
  interface = "26",
  ntp = "42",
  tftp = "66",
  custom = "custom",
}

export type DHCPOptionTypes = "text" | "ip" | "hex" | "integer";

export interface DHCPOption {
  code: string;
  type: DHCPOptionTypes;
  value: string;
}
