import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, Loader, TabView, TabViewMethods } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useVlan } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useRef } from "react";

import { DetailsTab } from "./tabs/DetailsTab";
import { SettingsTab } from "./tabs/SettingsTab";
import { VlanDetailsContext } from "./VlanDetailsContext";

const VLAN_TABS = {
  details: {
    title: I18n.t("TAB_TITLES.DETAILS"),
    view: <DetailsTab />,
    testID: "DETAILS_TABVIEW_TAB",
  },
  settings: {
    title: I18n.t("TAB_TITLES.SETTINGS"),
    view: <SettingsTab />,
    testID: "SETTINGS_TABVIEW_TAB",
  },
};

export const VlanDetailsScreen = () => {
  const route = useRoute<RouteProp<VlanGroupProps, "Vlan">>();
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const { vlanId } = route.params;

  const networkId = useCurrentNetworkId();
  const tabViewRef = useRef<TabViewMethods>(null);

  const handleGoToTab = useCallback((key: string) => {
    tabViewRef.current?.goToTab(key);
  }, []);

  const { data: vlan, isLoading: vlanIsLoading } = useVlan({
    networkId,
    vlanId,
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Icon icon="Pencil" onPress={() => navigation.navigate("EditVlan", { vlanId })} />
      ),
    });
  }, [navigation, vlanId]);

  if (!vlan || vlanIsLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  return (
    <VlanDetailsContext.Provider value={{ vlan: vlan, goToTab: handleGoToTab }}>
      <Screen.View>
        <Box paddingVertical="none" padding="sm">
          <Heading size="h1">{vlan.name}</Heading>
        </Box>
        <TabView tabs={VLAN_TABS} ref={tabViewRef} />
      </Screen.View>
    </VlanDetailsContext.Provider>
  );
};
