import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const AssignedApplianceSchema = z.object({
  type: z.literal("appliance"),
  name: z.string(),
  groupPolicyId: z.string().optional(),
});

const AssignedSsidSchema = AssignedApplianceSchema.extend({
  type: z.literal("ssid"),
  ssid: z.object({
    number: z.number(),
  }),
});

export const PolicyByClientSchema = z
  .object({
    clientId: z.string(),
    mac: z.string(),
    name: z.string(),
    assigned: z.array(z.union([AssignedApplianceSchema, AssignedSsidSchema])),
  })
  .describe("PolicyByClientSchema");

export const PoliciesByClientSchema = z
  .array(PolicyByClientSchema)
  .describe("PoliciesByClientSchema");

export type AssignedAppliancePolicy = z.infer<typeof AssignedApplianceSchema>;
export type AssignedSsidPolicy = z.infer<typeof AssignedSsidSchema>;
export type PoliciesByClient = z.infer<typeof PolicyByClientSchema>;
export type PoliciesByClients = z.infer<typeof PoliciesByClientSchema>;

interface PoliciesByClientRequest {
  networkId?: string;
}

interface PoliciesByClientRequestParams extends PoliciesByClientRequest {
  perPage?: number;
  startingAfter?: string;
  endingBefore?: string;
  t0?: string;
  timespan?: number;
}

const buildQueryUrl = ({ networkId }: PoliciesByClientRequest) => {
  return `/api/v1/networks/${networkId}/policies/byClient`;
};

const fetchClientsData = ({
  networkId,
  perPage,
  startingAfter,
  endingBefore,
  t0,
  timespan,
}: PoliciesByClientRequestParams) => {
  const queryParams = { perPage, startingAfter, endingBefore, t0, timespan };

  if (!queryParams.perPage) {
    delete queryParams.perPage;
  }
  if (!queryParams.startingAfter) {
    delete queryParams.startingAfter;
  }
  if (!queryParams.endingBefore) {
    delete queryParams.endingBefore;
  }
  if (!queryParams.t0) {
    delete queryParams.t0;
  }
  if (!queryParams.timespan) {
    delete queryParams.timespan;
  }

  return request(PoliciesByClientSchema, "GET", buildQueryUrl({ networkId }), {
    queryParams,
  });
};

export const usePoliciesByClient = createQuery<PoliciesByClientRequestParams, PoliciesByClients>({
  baseQueryKey: buildQueryUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchClientsData(variables),
  requiredVariables: ["networkId"],
});
