import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { SSIDRowList, SSIDRowListForm } from "@meraki/go/ssid";
import { BottomSheet, Button, List } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  AssignedSsidPolicy,
  ClientType,
  PoliciesByClient,
  PoliciesBySsid,
  queryClient,
  useConfiguredSsids,
  useCreateProvisionClients,
  usePoliciesByClient,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { toList } from "@meraki/shared/formatters";
import { showDeleteConfirmAlert, showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";

interface BlockBottomSheetProps {
  client: ClientType;
  clientPolicies?: PoliciesByClient;
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

interface BlockBottomSheetForm extends SSIDRowListForm {
  isBlocked: boolean;
}

export const BlockBottomSheet = ({
  client,
  clientPolicies,
  bottomSheetRef,
}: BlockBottomSheetProps) => {
  const networkId = useCurrentNetworkId();

  const { data: availableSSIDs, isLoading: isSsidsLoading } = useConfiguredSsids({ networkId });

  const { mutate: updateClientPolicy } = useCreateProvisionClients();

  const existingSsidPolicies: AssignedSsidPolicy[] = [];
  const blockedSsids: number[] = [];
  const limitedSsids: number[] = [];
  let groupPolicyId: string | undefined = undefined;
  clientPolicies?.assigned?.forEach((policy) => {
    if (policy.type === "ssid") {
      // Go allows only one custom group policy per client
      if (policy.groupPolicyId) {
        if (groupPolicyId === undefined) {
          groupPolicyId = policy.groupPolicyId;
        }

        limitedSsids.push(policy.ssid.number);
      } else {
        blockedSsids.push(policy.ssid.number);
      }

      existingSsidPolicies.push(policy);
    }
  });

  const methods = useForm<BlockBottomSheetForm>({
    values: {
      isBlocked: blockedSsids.length > 0,
      ssidNumbers: blockedSsids,
    },
  });

  const submit = methods.handleSubmit(({ isBlocked, ssidNumbers }) => {
    const onSave = () => {
      const policiesBySsid = existingSsidPolicies.reduce<PoliciesBySsid>((result, policy) => {
        // only set custom policies first
        if (policy.groupPolicyId != null) {
          result[policy.ssid.number] = {
            devicePolicy: "Group policy",
            groupPolicyId: policy.groupPolicyId,
          };
        }

        return result;
      }, {});

      if (ssidNumbers.length > 0 && isBlocked) {
        ssidNumbers.forEach((ssidNumber) => {
          policiesBySsid[ssidNumber] = { devicePolicy: "Blocked" };
        });
      }

      updateClientPolicy(
        {
          networkId,
          body: {
            devicePolicy: "Per connection",
            clients: [{ mac: client.mac, clientId: client.id }],
            policiesBySsid,
          },
        },
        {
          onError: (error) => showErrorAlert(String(error)),
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: usePoliciesByClient.queryKey({ networkId }),
            });
            bottomSheetRef?.current?.dismiss();
          },
        },
      );
    };

    const isCurrentlyLimited = limitedSsids.filter((ssid) => ssidNumbers.includes(ssid));
    const unblockedSsids = blockedSsids.filter((ssid) => !ssidNumbers.includes(ssid));
    const newBlockedSsids = ssidNumbers.filter((ssid) => !blockedSsids.includes(ssid));
    const isCurrentlyLimitedSsidNames: string[] = [];
    const selectedSsidNames: string[] = [];
    const unblockedSsidNames: string[] = [];
    const newBlockedSsidNames: string[] = [];

    availableSSIDs?.forEach((ssid) => {
      if (isCurrentlyLimited.includes(ssid.number)) {
        isCurrentlyLimitedSsidNames.push(ssid.name);
      }
      if (ssidNumbers.includes(ssid.number)) {
        selectedSsidNames.push(ssid.name);
      }
      if (unblockedSsids.includes(ssid.number)) {
        unblockedSsidNames.push(ssid.name);
      }
      if (newBlockedSsids.includes(ssid.number)) {
        newBlockedSsidNames.push(ssid.name);
      }
    });
    const description = () => {
      const clientName = client.description ?? "";

      if (ssidNumbers.length === 0 || !isBlocked) {
        return I18n.t("BLOCK_CLIENT.WARNING.UNBLOCK", {
          client_name: clientName,
        });
      }
      if (isCurrentlyLimited.length > 0) {
        return I18n.t("BLOCK_CLIENT.WARNING.REMOVE_LIMIT_AND_BLOCK", {
          client_name: clientName,
          limited_ssids: toList(isCurrentlyLimitedSsidNames),
          ssid_names: toList(newBlockedSsidNames),
        });
      } else if (newBlockedSsids.length > 0 && unblockedSsids.length > 0) {
        return I18n.t("BLOCK_CLIENT.WARNING.BLOCK_AND_UNBLOCK", {
          client_name: clientName,
          unblock_ssids: toList(unblockedSsidNames),
          ssid_names: toList(newBlockedSsidNames),
        });
      } else if (unblockedSsids.length > 0) {
        return I18n.t("BLOCK_CLIENT.WARNING.UNBLOCK_SOME", {
          client_name: clientName,
          ssid_names: toList(unblockedSsidNames),
        });
      } else {
        return I18n.t("BLOCK_CLIENT.WARNING.BLOCK", {
          client_name: clientName,
          ssid_names: toList(selectedSsidNames),
        });
      }
    };

    showDeleteConfirmAlert(I18n.t("SSID_DETAILS.CONFIRMATION"), description(), onSave);
  });

  const watchedIsBlocked = methods.watch("isBlocked");
  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("BLOCK_CLIENT.HEADING")}
        onResetPress={() => methods.reset()}
        onCancelPress={() => {
          methods.reset();
          bottomSheetRef.current?.close();
        }}
        cancelLabel={I18n.t("CANCEL")}
        resetLabel={I18n.t("RESET")}
      />
      <BottomSheet.Content>
        <Form {...methods}>
          <List label={I18n.t("BLOCK_CLIENT.GOv3.DESCRIPTION")}>
            <List.Item
              title={I18n.t("BLOCK_CLIENT.GOv3.HEADER")}
              rightAccessory={
                <Form.Toggle
                  name="isBlocked"
                  testID={`BLOCK_CLIENT_TOGGLE.${watchedIsBlocked ? "SELECTED" : "UNSELECTED"}`}
                />
              }
            />
          </List>
          <Box paddingVertical="sm">{watchedIsBlocked && <SSIDRowList />}</Box>
        </Form>
        <Button
          text={I18n.t("SAVE")}
          onPress={submit}
          testID="SAVE_BUTTON"
          disabled={!methods.formState.isDirty || isSsidsLoading}
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
};
