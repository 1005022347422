import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import {
  findSliderIndexForKB,
  getGBForSliderIndex,
  getKBForSliderIndex,
  MAX_THRESHOLD_INDEX,
  THRESHOLD_INDEX_STEPS,
} from "@meraki/go/alert";
import {
  AlertSettingsItem,
  AlertSettingsPayload,
  AlertSettingsType,
  AlertSettingsTypes,
  useAlertSettings,
  useUpdateAlertSettings,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useReducer } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import RequestNotifcationCard from "~/go/components/RequestNotificationCard";
import SectionListHeader from "~/go/components/SectionListHeader";
import { getNewRecipients, isEnabledForUser, showAlert } from "~/lib/AlertUtils";
import {
  currentUserState,
  devicesSelector,
  getUserId,
  hasGRDevices,
  isClientConnectivitySupported,
  isLocationAnalyticsEnabled,
  isUmbrellaEnabledSelector,
} from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import NoData from "~/shared/components/NoData";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import SwitchRow, { SwitchRowProps } from "~/shared/rows/SwitchRow";
import { AlertRowData, DestinationTypes } from "~/shared/types/AlertTypes";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "AlertsSettings">;

interface State {
  pushNotificationsEnabled: boolean;
  hardwareDown: AlertSettingsItem;
  trackClient: AlertSettingsItem;
  usageAlert: AlertSettingsItem;
  guestInsights: AlertSettingsItem;
  securityInsights: AlertSettingsItem;
  sliderIndex: number;
}

const HardwareDownTypes = [
  AlertSettingsTypes.gatewayDown,
  AlertSettingsTypes.repeaterDown,
  AlertSettingsTypes.applianceDown,
  AlertSettingsTypes.switchDown,
];

const AlertSectionHeader = ({ section }: { section: { key?: string } }) => (
  <SectionListHeader heading={section.key} withHorizontalMargin />
);

const EmptyAlert = {
  alertDestinations: {
    allAdmins: false,
    emails: [],
    mobileAdminIds: [],
    snmp: false,
  },
  enabled: false,
  filters: {},
};

const getEmptyAlert = (type: AlertSettingsType): AlertSettingsItem => {
  return Object.assign({ type }, EmptyAlert);
};

const getSwitchValue = (
  settings: AlertSettingsItem,
  destType: DestinationTypes,
  currentUser: string,
) => {
  const recipients = settings.alertDestinations[destType];
  return isEnabledForUser(settings.enabled, recipients, currentUser);
};

const toggleSwitchValue = (
  settings: AlertSettingsItem,
  destType: DestinationTypes,
  currentUser: string,
  enabled: boolean,
) => {
  const newSettings = Object.assign({}, settings);
  const recipients = settings.alertDestinations[destType];

  const newRecipients = getNewRecipients(enabled, recipients, currentUser);
  newSettings.alertDestinations[destType] = newRecipients;

  newSettings.enabled =
    newSettings.alertDestinations.emails.length > 0 ||
    (!!newSettings.alertDestinations.mobileAdminIds &&
      newSettings.alertDestinations.mobileAdminIds.length > 0);

  return newSettings;
};

const updateThreshold = (settings: AlertSettingsItem, sliderIndex: number) => {
  const newSettings = Object.assign({}, settings);
  newSettings.filters = {
    threshold: getKBForSliderIndex(sliderIndex),
  };

  return newSettings;
};

export const AlertsSettingsScreen = ({ notificationsEnabled }: Props) => {
  const networkId = errorMonitor.notifyNonOptional(
    "param 'currentNetowrkState' undefined for AlertsScreen",
    useCurrentNetworkId(),
  );
  const currentUserEmail = errorMonitor.notifyNonOptional(
    "param 'currentUserEmail' undefined for AlertsScreen",
    useAppSelector(currentUserState),
  );
  const currentUserId = errorMonitor.notifyNonOptional(
    "param 'currentUserId' undefined for AlertsScreen",
    useAppSelector(getUserId),
  );
  const hasHardware = useAppSelector(devicesSelector).length > 0;
  const clientConnectivitySupported = useAppSelector(isClientConnectivitySupported);
  const hasGR = useAppSelector(hasGRDevices);
  const locationAnalyticsEnabled = useAppSelector(isLocationAnalyticsEnabled) && hasGR;
  const umbrellaEnabled = useAppSelector(isUmbrellaEnabledSelector);

  const actions = useActions();
  const loadData = useCallback(async () => {
    await actions.getWirelessSettings(networkId);
  }, [actions, networkId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [
    {
      pushNotificationsEnabled,
      hardwareDown,
      trackClient,
      usageAlert,
      guestInsights,
      securityInsights,
      sliderIndex,
    },
    updateStates,
  ] = useReducer(
    (states: State, partialStates: Partial<State>) => ({ ...states, ...partialStates }),
    {
      pushNotificationsEnabled: notificationsEnabled === undefined ? true : notificationsEnabled,
      hardwareDown: getEmptyAlert(AlertSettingsTypes.applianceDown),
      trackClient: getEmptyAlert(AlertSettingsTypes.clientConnectivity),
      usageAlert: getEmptyAlert(AlertSettingsTypes.usageAlert),
      guestInsights: getEmptyAlert(AlertSettingsTypes.weeklyPresence),
      securityInsights: getEmptyAlert(AlertSettingsTypes.weeklyUmbrella),
      sliderIndex: MAX_THRESHOLD_INDEX,
    },
  );
  const { data, isFetching, refetch } = useAlertSettings({ networkId });

  useEffect(() => {
    if (data != null && !isFetching) {
      const newState = {} as Partial<State>;

      for (const item of data.alerts) {
        switch (item.type) {
          // some case gatewayDown alert does not exist
          case AlertSettingsTypes.gatewayDown:
          case AlertSettingsTypes.applianceDown:
            newState.hardwareDown = item;
            break;
          case AlertSettingsTypes.clientConnectivity:
            newState.trackClient = item;
            break;
          case AlertSettingsTypes.usageAlert:
            newState.usageAlert = item;
            newState.sliderIndex = findSliderIndexForKB(item.filters?.threshold);
            break;
          case AlertSettingsTypes.weeklyPresence:
            newState.guestInsights = item;
            break;
          case AlertSettingsTypes.weeklyUmbrella:
            newState.securityInsights = item;
            break;
        }
      }

      updateStates(newState);
    }
  }, [data, isFetching]);

  const navigation = useNavigation();
  const updateAlertSettings = useUpdateAlertSettings();
  const saveAlertSettings = (newState: { [key: string]: AlertSettingsItem }) => {
    updateStates({ ...newState });

    const alertSettings = { alerts: [] } as AlertSettingsPayload;
    Object.entries(newState).forEach(([key, value]) => {
      if (key === "hardwareDown") {
        alertSettings.alerts.push(...HardwareDownTypes.map((type) => ({ ...value, type })));
      } else {
        alertSettings.alerts.push(value);
      }
    });

    updateAlertSettings.mutate(
      { networkId, alertSettings },
      {
        onSuccess: () => refetch(),
        onError: (error) => {
          showAlert(I18n.t("ERROR"), error.errors?.join(","));
        },
      },
    );
  };

  if (data == null && !isFetching) {
    return <NoData onPress={() => refetch()} />;
  }

  const tableData = [
    {
      label: I18n.t("NOTIFICATIONS.ALERTS.OFFLINE"),
      switches: [
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
          value:
            hasHardware && getSwitchValue(hardwareDown, DestinationTypes.email, currentUserEmail),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              hardwareDown: toggleSwitchValue(
                hardwareDown,
                DestinationTypes.email,
                currentUserEmail,
                newValue,
              ),
            }),
          disabled: !hasHardware,
          testID: "ALERTS.OFFLINE_EMAIL",
        },
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
          value: hasHardware && getSwitchValue(hardwareDown, DestinationTypes.push, currentUserId),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              hardwareDown: toggleSwitchValue(
                hardwareDown,
                DestinationTypes.push,
                currentUserId,
                newValue,
              ),
            }),
          disabled: !hasHardware,
          testID: "ALERTS.OFFLINE_PUSH",
        },
      ],
    },
    {
      label: I18n.t("NOTIFICATIONS.ALERTS.USAGE_EXCEEDED"),
      switches: [
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
          value: getSwitchValue(usageAlert, DestinationTypes.email, currentUserEmail),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              usageAlert: toggleSwitchValue(
                usageAlert,
                DestinationTypes.email,
                currentUserEmail,
                newValue,
              ),
            }),
          disabled: false,
          testID: "ALERTS.USAGE_EXCEEDED",
        },
      ],
    },
    {
      label: I18n.t("NOTIFICATIONS.ALERTS.GUEST_INSIGHTS"),
      switches: [
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
          value:
            locationAnalyticsEnabled &&
            getSwitchValue(guestInsights, DestinationTypes.email, currentUserEmail),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              guestInsights: toggleSwitchValue(
                guestInsights,
                DestinationTypes.email,
                currentUserEmail,
                newValue,
              ),
            }),
          disabled: !locationAnalyticsEnabled,
          testID: "ALERTS.GUEST_INSIGHTS_EMAIL",
        },
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
          value:
            locationAnalyticsEnabled &&
            getSwitchValue(guestInsights, DestinationTypes.push, currentUserId),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              guestInsights: toggleSwitchValue(
                guestInsights,
                DestinationTypes.push,
                currentUserId,
                newValue,
              ),
            }),
          disabled: !locationAnalyticsEnabled,
          testID: "ALERTS.GUEST_INSIGHTS_PUSH",
        },
      ],
    },
    {
      label: I18n.t("NOTIFICATIONS.ALERTS.WEEKLY_UMBRELLA"),
      switches: [
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
          value:
            umbrellaEnabled &&
            getSwitchValue(securityInsights, DestinationTypes.email, currentUserEmail),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              securityInsights: toggleSwitchValue(
                securityInsights,
                DestinationTypes.email,
                currentUserEmail,
                newValue,
              ),
            }),
          disabled: !umbrellaEnabled,
          testID: "ALERTS.WEEKLY_UMBRELLA_EMAIL",
        },
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
          value:
            umbrellaEnabled &&
            getSwitchValue(securityInsights, DestinationTypes.push, currentUserId),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              securityInsights: toggleSwitchValue(
                securityInsights,
                DestinationTypes.push,
                currentUserId,
                newValue,
              ),
            }),
          disabled: !umbrellaEnabled,
          testID: "ALERTS.WEEKLY_UMBRELLA_PUSH",
        },
      ],
    },
  ];

  if (clientConnectivitySupported) {
    tableData.splice(1, 0, {
      label: I18n.t("NOTIFICATIONS.ALERTS.TRACKED"),
      switches: [
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
          value: getSwitchValue(trackClient, DestinationTypes.email, currentUserEmail),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              trackClient: toggleSwitchValue(
                trackClient,
                DestinationTypes.email,
                currentUserEmail,
                newValue,
              ),
            }),
          disabled: false,
          testID: "ALERTS.TRACKED_EMAIL",
        },
        {
          subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
          value: getSwitchValue(trackClient, DestinationTypes.push, currentUserId),
          onValueChange: (newValue: boolean) =>
            saveAlertSettings({
              trackClient: toggleSwitchValue(
                trackClient,
                DestinationTypes.push,
                currentUserId,
                newValue,
              ),
            }),
          disabled: false,
          testID: "ALERTS.TRACKED_PUSH",
        },
      ],
    });
  }

  const renderRow = (rowData: AlertRowData) => {
    let header = (
      <MkiText textStyle="subheader" screenStyles={styles.rowHeader}>
        {rowData.label}
      </MkiText>
    );
    let nonSwitchRow: React.ReactElement | null = null;

    if (rowData.label === I18n.t("NOTIFICATIONS.ALERTS.TRACKED")) {
      header = (
        <View style={styles.trackClientsTitle}>
          <MkiText textStyle="subheader" screenStyles={styles.rowHeader}>
            {rowData.label}
          </MkiText>
          <ContextHelp context="clientTracking" />
        </View>
      );

      nonSwitchRow = (
        <DisclosureRow
          onPress={() => navigation.navigate("TrackedClients")}
          rowStyle={styles.editRow}
          testID="ALERTS.TRACK_CLIENT.CLIENTS_LIST_ROW"
        >
          {I18n.t("TRACK_CLIENT.EDIT.SUBJECT")}
        </DisclosureRow>
      );
    } else if (rowData.label === I18n.t("NOTIFICATIONS.ALERTS.USAGE_EXCEEDED")) {
      const { value } = rowData.switches[0];
      const isSliderDisabled = !value;
      const limitTextStyle = isSliderDisabled ? "secondary" : "default";

      nonSwitchRow = (
        <View style={styles.slider}>
          <Slider
            key={`SLIDER_${isSliderDisabled}`}
            disabled={isSliderDisabled}
            maximumValue={MAX_THRESHOLD_INDEX}
            step={THRESHOLD_INDEX_STEPS}
            onValueChange={(newIndex) => updateStates({ sliderIndex: newIndex })}
            onSlidingComplete={(newIndex) =>
              saveAlertSettings({
                usageAlert: updateThreshold(usageAlert, newIndex),
              })
            }
            value={sliderIndex}
            minimumTrackTintColor={MkiColors.goPurple}
            testID="ALERTS.USAGE_EXCEEDED.SLIDER"
          />
          <MkiText textStyle={limitTextStyle}>
            {I18n.t("NOTIFICATIONS.ALERTS.LIMIT", {
              limit: getGBForSliderIndex(sliderIndex),
            })}
          </MkiText>
        </View>
      );
    }

    return (
      <View style={styles.row}>
        {header}
        {rowData.switches.map((switchRowProps: SwitchRowProps, index: number) => (
          <SwitchRow key={index.toString()} {...switchRowProps} screenStyles={styles.switchRow} />
        ))}
        {nonSwitchRow}
      </View>
    );
  };

  return (
    <>
      {!pushNotificationsEnabled && (
        <RequestNotifcationCard
          onResult={(hasAlertPermissions) =>
            updateStates({ pushNotificationsEnabled: hasAlertPermissions })
          }
        />
      )}
      <MkiTable<AlertRowData>
        data={{ [I18n.t("NOTIFICATIONS.ALERTS.HEADING")]: tableData }}
        renderRow={renderRow}
        renderSectionHeader={AlertSectionHeader}
        onRefresh={refetch}
        testID="ALERTS.TABLE"
      />
      <LoadingSpinner visible={isFetching || updateAlertSettings.isLoading} />
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    paddingVertical: SPACING.small,
  },
  rowHeader: {
    paddingHorizontal: SPACING.default,
  },
  trackClientsTitle: { flex: 1, flexDirection: "row" },
  switchRow: {
    paddingVertical: SPACING.small,
  },
  editRow: {
    color: MkiColors.secondaryTextColor,
    paddingVertical: SPACING.small,
  },
  slider: {
    marginVertical: SPACING.small,
    marginHorizontal: SPACING.default,
  },
});

export default AlertsSettingsScreen;
