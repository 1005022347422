import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Line, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Switch({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path
        d="M4.75 10.5C4.75 10.3619 4.86193 10.25 5 10.25H19C19.1381 10.25 19.25 10.3619 19.25 10.5V13.5C19.25 13.6381 19.1381 13.75 19 13.75H5C4.86193 13.75 4.75 13.6381 4.75 13.5V10.5Z"
        stroke={color1}
        strokeWidth="1.5"
      />
      <Path
        d="M4.75 4C4.75 3.86193 4.86193 3.75 5 3.75H19C19.1381 3.75 19.25 3.86193 19.25 4V7C19.25 7.13807 19.1381 7.25 19 7.25H5C4.86193 7.25 4.75 7.13807 4.75 7V4Z"
        stroke={color1}
        strokeWidth="1.5"
      />
      <Path
        d="M4.75 17C4.75 16.8619 4.86193 16.75 5 16.75H19C19.1381 16.75 19.25 16.8619 19.25 17V20C19.25 20.1381 19.1381 20.25 19 20.25H5C4.86193 20.25 4.75 20.1381 4.75 20V17Z"
        stroke={color1}
        strokeWidth="1.5"
      />
      <Line x1="7.6" y1="17" x2="7.6" y2="20" stroke={color1} strokeWidth="1.2" />
      <Line x1="7.6" y1="10" x2="7.6" y2="13" stroke={color1} strokeWidth="1.2" />
      <Line x1="7.6" y1="4" x2="7.6" y2="7" stroke={color1} strokeWidth="1.2" />
    </Svg>
  );
}
