import _ from "lodash";
import { StyleProp, Text, TextProps, TextStyle } from "react-native";

import { TEXT_STYLE_NAME, TEXT_STYLES } from "~/constants/MkiTextStyles";
import { appSelect } from "~/lib/PlatformUtils";
import { isString } from "~/lib/TypeHelper";

import { useThemeColors } from "../hooks/useTheme";

export interface MkiTextProps extends TextProps {
  children: React.ReactNode;
  textStyle?: TEXT_STYLE_NAME;
  // This should probably be refactored to only use style from TextProps
  // However, that would require a lot of changes throughout the app to update
  // Leaving as is for now, can tackle separately, as it may result in a big PR
  screenStyles?: StyleProp<TextStyle>;
  uppercase?: boolean;
  testID?: string;
  truncation?: number;
}

/* Font variables */
const UPPERCASE_TEXT_STYLES = {
  enterprise: {},
  go: {
    label: true,
  },
};

const formatTextString = (props: MkiTextProps) => {
  const { children, uppercase, textStyle, truncation } = props;
  let formattedText = children;
  if (
    isString(formattedText) &&
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (uppercase || (textStyle === "label" && appSelect(UPPERCASE_TEXT_STYLES)[textStyle]))
  ) {
    formattedText = formattedText.toUpperCase();
  }
  if (isString(formattedText) && truncation && formattedText.length > truncation) {
    formattedText = `${formattedText.slice(0, truncation)}...`;
  }
  return formattedText;
};

export const MkiText = (props: MkiTextProps) => {
  const additionalProps = _.omit(props, [
    "textStyle",
    "screenStyles",
    "children",
    "style",
    "theme",
  ]);

  const { textStyle, screenStyles } = props;

  const stylesKey = textStyle || "default";
  const themeColors = useThemeColors();
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const textStyles = [TEXT_STYLES[stylesKey], themeColors.text?.[stylesKey]];
  const renderedText = (
    <Text style={[textStyles, screenStyles]} {...additionalProps}>
      {formatTextString(props)}
    </Text>
  );
  return renderedText;
};

MkiText.defaultProps = {
  textStyle: "default",
  screenStyles: {},
  uppercase: false,
  testID: undefined,
};

export default MkiText;
