import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export interface LicenseRequest {
  organizationId?: string;
}

export interface LicensesURLParams extends LicenseRequest {
  licenseId?: string;
  destOrganizationId?: string;
}

export interface MoveLicenseRequest extends LicenseRequest {
  destOrganizationId: string;
  licenseIds: string[];
}

export interface AssignLicenseRequest extends LicenseRequest {
  licenseId: string;
  deviceSerial: string | null;
}

export interface AddLicenseParams {
  key: string;
  GXDeviceSerial?: string;
}

export interface AddLicenseError {
  error: string;
}

export const StateType = z.union([
  z.literal("active"),
  z.literal("expired"),
  z.literal("expiring"),
  z.literal("unused"),
  z.literal("unusedActive"),
  z.literal("recentlyQueued"),
]);

export const LicenseSchema = z.object({
  activationDate: z.string().nullable(),
  claimDate: z.string(),
  deviceSerial: z.string().nullable(),
  durationInDays: z.number().nullable(),
  expirationDate: z.string().nullable(),
  headLicenseId: z.nullable(z.number()),
  headLicenseKey: z.string().optional().nullable(),
  id: z.string(),
  licenseKey: z.string().nullable(),
  licenseType: z.string(),
  networkId: z.string().nullable(),
  orderNumber: z.string().nullable(),
  permanentlyQueuedLicenses: z.array(
    z.object({
      id: z.string(),
      licenseType: z.string(),
      licenseKey: z.string(),
      orderNumber: z.string().nullable(),
      durationInDays: z.number().nullable(),
    }),
  ),
  seatCount: z.nullable(z.number()),
  state: StateType,
  totalDurationInDays: z.number().nullable(),
});

export const LicensesSchema = z.array(LicenseSchema).describe("LicensesSchema");

export const AssignLicenseResponse = z.object({
  success: z.boolean(),
});

export const MoveLicenseResponse = z.object({
  destOrganizationId: z.string(),
  licenseIds: z.array(z.string()),
});

export type MoveLicenseResponse = z.infer<typeof MoveLicenseResponse>;

export type AssignLicenseResponse = z.infer<typeof AssignLicenseResponse>;

export type LicenseStatus = z.infer<typeof StateType>;

export type License = z.infer<typeof LicenseSchema>;

export type Licenses = z.infer<typeof LicensesSchema>;

const buildPublicUrl = ({ organizationId, destOrganizationId, licenseId }: LicensesURLParams) => {
  if (destOrganizationId) {
    return `/api/v1/organizations/${organizationId}/licenses/move`;
  }
  if (licenseId) {
    return `/api/v1/organizations/${organizationId}/licenses/${licenseId}`;
  }
  return `/api/v1/organizations/${organizationId}/licenses`;
};

function fetchLicenses({ organizationId }: LicenseRequest): Promise<Licenses> {
  if (!organizationId) {
    throw new Error("Undefined organization id when fetching licenses");
  }
  return request(LicensesSchema, "GET", buildPublicUrl({ organizationId }));
}

function mutateAssignLicense({
  organizationId,
  licenseId,
  deviceSerial,
}: AssignLicenseRequest): Promise<License> {
  return request(LicenseSchema, "PUT", buildPublicUrl({ organizationId, licenseId }), {
    body: JSON.stringify({
      deviceSerial,
    }),
  });
}

function mutateMoveLicense({
  organizationId,
  destOrganizationId,
  licenseIds,
}: MoveLicenseRequest): Promise<MoveLicenseResponse> {
  return request(
    MoveLicenseResponse,
    "POST",
    buildPublicUrl({ organizationId, destOrganizationId }),
    {
      body: JSON.stringify({
        destOrganizationId,
        licenseIds,
      }),
    },
  );
}

export const useAssignLicense = createMutation<AssignLicenseRequest, License, APIResponseError>({
  baseMutationKey: buildPublicUrl({ organizationId: "{organizationId}" }),
  mutationFn: (params: AssignLicenseRequest) => mutateAssignLicense(params),
});

export const useMoveLicense = createMutation<
  MoveLicenseRequest,
  MoveLicenseResponse,
  APIResponseError
>({
  baseMutationKey: buildPublicUrl({ organizationId: "{organizationId}" }),
  mutationFn: (params: MoveLicenseRequest) => mutateMoveLicense(params),
});

export const useGetLicenses = createQuery<LicenseRequest, Licenses>({
  baseQueryKey: buildPublicUrl({ organizationId: "{organizationId}" }),
  queryFn: (params: LicenseRequest) => fetchLicenses(params),
  requiredVariables: ["organizationId"],
});
