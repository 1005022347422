import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  BottomSheetProps,
  Button,
  Card,
  Heading,
  Tag,
} from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { ScrollView } from "react-native";

type FilterOption<T> = {
  value: T;
  label?: string;
};
type Filter<T> = {
  label?: string;
  options: FilterOption<T>[];
};

type ExtractOptions<
  T extends Record<TKey, Filter<TValue>>,
  TKey extends string,
  TValue extends string,
> = {
  [K in keyof T]: T[K]["options"] extends FilterOption<infer Z>[] ? Z[] : never;
};

export type FilterBottomSheetMethods = Pick<BottomSheetMethods, "present" | "dismiss">;
type FilterBottomSheetProps<
  TFilters extends Record<TKey, Filter<TValue>>,
  TKey extends string,
  TValue extends string,
> = {
  title?: string;
  cancelLabel?: string;
  resetLabel?: string;
  snapPoints?: BottomSheetProps["snapPoints"];
  index?: number;
  availableFilters: TFilters;
  initialFilters: ExtractOptions<TFilters, TKey, TValue>;
  onUpdateFilters: (filters: ExtractOptions<TFilters, TKey, TValue>) => void;
};

function FilterBottomSheetInner<
  TFilters extends Record<TKey, Filter<TValue>>,
  TKey extends string,
  TValue extends string,
>(
  {
    title,
    cancelLabel,
    resetLabel,
    initialFilters,
    availableFilters,
    onUpdateFilters,
    snapPoints,
    index,
  }: FilterBottomSheetProps<TFilters, TKey, TValue>,
  ref: ForwardedRef<FilterBottomSheetMethods>,
) {
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const [filters, setFilters] = useState<ExtractOptions<TFilters, TKey, TValue>>(initialFilters);

  useImperativeHandle(
    ref,
    () => ({
      present: () => {
        setFilters(initialFilters);
        bottomSheetRef.current?.present();
      },
      dismiss: () => bottomSheetRef.current?.dismiss(),
    }),
    [setFilters, initialFilters],
  );

  return (
    <BottomSheet.Modal
      ref={bottomSheetRef}
      snapPoints={snapPoints ?? ["CONTENT_HEIGHT"]}
      index={index ?? 0}
    >
      <BottomSheet.Header
        title={title ?? I18n.t("SORT_FILTER.TITLE")}
        cancelLabel={cancelLabel ?? I18n.t("SORT_FILTER.CANCEL")}
        onCancelPress={() => bottomSheetRef.current?.dismiss()}
        resetLabel={resetLabel ?? I18n.t("SORT_FILTER.RESET")}
        onResetPress={() => {
          setFilters(initialFilters);
        }}
      />
      <BottomSheet.Content>
        <ScrollView contentContainerStyle={{ paddingBottom: 15 }}>
          <Box gap="md">
            {(Object.keys(availableFilters) as (keyof TFilters)[]).map((key) => {
              // This is because TS thinks `key` could be `symbol`. Its being odd...
              const strKey = `${key.toString()}`;

              const filter = availableFilters[key];
              if (filter.options.length === 0) return null;

              return (
                <Box gap="xs" key={strKey}>
                  <Box paddingLeft="sm">
                    <Heading size="h4">{filter.label ?? strKey}</Heading>
                  </Box>
                  <Card flexDirection="row" flexWrap="wrap" gap="xs">
                    {filter.options.map((opt) => {
                      const isSelected = filters[key].some((so) => so === opt.value);

                      return (
                        <Tag
                          key={`${strKey}_${opt.value}`}
                          type="selectable"
                          selected={isSelected}
                          onPress={() => {
                            const updatedValues = isSelected
                              ? filters[key].filter((f) => f !== opt.value) // Deslecting
                              : [...filters[key], opt.value]; // Selecting

                            setFilters({
                              ...filters,
                              [key]: updatedValues,
                            });
                          }}
                        >
                          {opt.label ?? opt.value}
                        </Tag>
                      );
                    })}
                  </Card>
                </Box>
              );
            })}
          </Box>
        </ScrollView>
        <Button
          text="Show Results"
          onPress={() => {
            onUpdateFilters(filters);
            bottomSheetRef.current?.dismiss();
          }}
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}

export const FilterBottomSheet = forwardRef(FilterBottomSheetInner) as <
  TFilters extends Record<TKey, Filter<TValue>>,
  TKey extends string,
  TValue extends string,
>(
  props: FilterBottomSheetProps<TFilters, TKey, TValue> & {
    ref?: React.ForwardedRef<FilterBottomSheetMethods>;
  },
) => ReturnType<typeof FilterBottomSheetInner>;
