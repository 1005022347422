import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { useCallback } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";

import { ButtonInternal, ButtonInternalProps } from "./ButtonInternal";
import { IconButton } from "./IconButton";
import { NavButton } from "./NavButton";

export { IconButtonProps } from "./IconButton";

export type ButtonProps = Omit<ButtonInternalProps, "pressed"> &
  Omit<PressableProps, "style" | "accessibilityRole"> &
  AnalyticsProp<"onPress">;

export function Button({
  kind,
  disabled,
  loading,
  text,
  leadingIcon,
  trailingIcon,
  onPress,
  analytics,
  ...rest
}: ButtonProps) {
  const trackEvent = useAnalytics(analytics);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      trackEvent("onPress");
      onPress?.(event);
    },
    [trackEvent, onPress],
  );

  return (
    <Pressable
      {...rest}
      onPress={handlePress}
      disabled={disabled || loading}
      accessibilityRole="button"
    >
      {({ pressed }) => (
        <ButtonInternal
          kind={kind}
          disabled={disabled}
          loading={loading}
          text={text}
          pressed={pressed}
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
        />
      )}
    </Pressable>
  );
}

Button.Nav = NavButton;
Button.Icon = IconButton;
