import z from "zod";

export const ApplianceDelegatedPrefixSchema = z
  .object({
    origin: z.object({
      interface: z.string(),
    }),
    counts: z.object({
      assigned: z.number(),
      available: z.number(),
    }),
    prefix: z.string(),
    method: z.string(),
    description: z.string(),
    isPreferred: z.boolean(),
    expiresAt: z.string(),
  })
  .describe("ApplianceDelegatedPrefixSchema");

export const ApplianceDelegatedPrefixVlanAssignmentSchema = z.object({
  vlan: z.object({
    id: z.number(),
    name: z.string(),
  }),
  origin: z.object({
    interface: z.string(),
    prefix: z.string(),
  }),
  ipv6: z.object({
    prefix: z.string(),
    address: z.string(),
    linkLocal: z.object({
      address: z.string(),
    }),
    solicitedNodeMulticast: z.object({
      address: z.string(),
    }),
  }),
  status: z.string(),
});

export const ApplianceDelegatedPrefixesSchema = z.array(ApplianceDelegatedPrefixSchema);
export const ApplianceDelegatedPrefixVlanAssignmentsSchema = z.array(
  ApplianceDelegatedPrefixVlanAssignmentSchema,
);

export type ApplianceDelegatedPrefix = z.infer<typeof ApplianceDelegatedPrefixSchema>;
export type ApplianceDelegatedPrefixVlanAssignment = z.infer<
  typeof ApplianceDelegatedPrefixVlanAssignmentSchema
>;
