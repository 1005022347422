export type StepProps = {
  title: string;
  subtitle: string;
  explanatoryText?: string;
  isPrimaryActionDisabled?: boolean;
  isDisabled?: boolean;

  // helper functions
  onComplete?: () => void;
  onCancel?: () => void;

  children: JSX.Element;
};

export function Step({ children }: StepProps) {
  return children;
}
