import { Disabled } from "./icons/Disabled";
import { Informational } from "./icons/Informational";
import { Negative } from "./icons/Negative";
import { NegativeRepeater } from "./icons/NegativeRepeater";
import { Neutral } from "./icons/Neutral";
import { NeutralRepeater } from "./icons/NeutralRepeater";
import { Positive } from "./icons/Positive";
import { PositiveRepeater } from "./icons/PositiveRepeater";
import { Warning } from "./icons/Warning";
import { WarningRepeater } from "./icons/WarningRepeater";

const ICON_MAP = {
  negative: Negative,
  positive: Positive,
  warning: Warning,
  neutral: Neutral,
  informational: Informational,
  disabled: Disabled,
};

const REPEATER_ICON_MAP = {
  negative: NegativeRepeater,
  positive: PositiveRepeater,
  warning: WarningRepeater,
  neutral: NeutralRepeater,
  informational: Informational,
  disabled: Disabled,
};

export type StatusType = keyof typeof ICON_MAP;
export interface StatusProps {
  status: StatusType;
  size?: number;
  repeater?: boolean;
  testID?: string;
}

export function Status({ status, size, repeater, testID }: StatusProps) {
  const Icon = repeater ? REPEATER_ICON_MAP[status] : ICON_MAP[status];

  if (!Icon) return null;

  return <Icon width={size ?? 16} height={size ?? 16} testID={testID} />;
}
