import { I18n } from "@meraki/core/i18n";
import {
  License,
  useAssignGoLicense,
  useAssignLicense,
  useGetLicenses,
  useMoveLicense,
} from "@meraki/shared/api";
import { DAYS_IN_A_YEAR } from "@meraki/shared/redux";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef, useState } from "react";
import { Animated, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import Organization from "~/api/models/Organization";
import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import MkiColors from "~/constants/MkiColors";
import { BOX_BORDER_RADIUS, SPACING } from "~/constants/MkiConstants";
import { ROW_SPACINGS } from "~/constants/RowConstants";
import InfoModal from "~/go/components/InfoModal";
import LicenseIcon from "~/go/components/LicenseIcon";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import { currentOrganization, getOrgCount, gxDeviceSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import OrgList from "~/shared/components/lists/orgs/OrgList";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { ActiveAndQueuedStatuses, ActiveStatuses, LicenseStatuses } from "~/shared/types/License";

interface LicenseRowProps {
  license: License;
}

function addHyphens(key: string) {
  return key.match(/.{4}/g)?.join("-");
}

const LicenseRow: React.FunctionComponent<LicenseRowProps> = ({ license }) => {
  const queryClient = useQueryClient();
  const animatedFlex = useRef(new Animated.Value(0)).current;
  const organizationId = useAppSelector(currentOrganization).id;
  const gxDevice = useAppSelector(gxDeviceSelector);
  const reassignLicense = useAssignLicense();
  const moveLicense = useMoveLicense();
  const assignLicense = useAssignGoLicense();
  const numOfOrgs = useSelector(getOrgCount);

  const [showButtonContainer, setShowButtonContainer] = useState(false);
  const [showOrgs, setShowOrgs] = useState(false);

  const toggleButtonContainer = useCallback(() => {
    setShowButtonContainer(!showButtonContainer);
    Animated.timing(animatedFlex, {
      toValue: showButtonContainer ? 0 : 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [showButtonContainer, animatedFlex]);

  const assign = () => {
    assignLicense.mutate(
      { licenseId: license.id, deviceSerial: gxDevice?.id ?? null },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
          showAlert(
            I18n.t("SUCCESS"),
            I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SUCCESSFUL_ASSIGNING_MESSAGE"),
          );
        },
        onError: (error) => {
          let errorMessage = I18n.t("SERVER_ERROR_TEXT");
          if (typeof error === "string") {
            errorMessage = error;
          } else if (Array.isArray(error)) {
            errorMessage = error.join("\n");
          }
          showAlert(I18n.t("ERROR"), errorMessage);
        },
      },
    );
  };

  const move = (org: Organization | GoOrgSearchResult) => {
    moveLicense.mutate(
      { organizationId, licenseIds: [license.id], destOrganizationId: org.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
          showAlert(
            I18n.t("SUCCESS"),
            I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SUCCESSFUL_MOVING_MESSAGE"),
          );
        },
        onError: (error) => showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT")),
      },
    );
  };

  const unassign = () => {
    reassignLicense.mutate(
      { organizationId, licenseId: license.id, deviceSerial: null },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
          showAlert(
            I18n.t("SUCCESS"),
            I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SUCCESSFUL_UNASSIGNING_MESSAGE"),
          );
          setShowOrgs(false);
        },
        onError: (error) => {
          showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
        },
      },
    );
  };

  const isMultiOrg = numOfOrgs != null && numOfOrgs > 1;
  const isUsed = license.deviceSerial != null && ActiveAndQueuedStatuses.includes(license.state);
  const isActive = ActiveStatuses.includes(license.state);
  const canAct =
    (isUsed &&
      (license.headLicenseId == null || license.state === LicenseStatuses.recentlyQueued)) ||
    (!isUsed && license.headLicenseId == null);
  const durationInYear =
    license.totalDurationInDays == null ? 0 : license.totalDurationInDays / DAYS_IN_A_YEAR;

  const rowStyle: StyleProp<ViewStyle> = [styles.row];
  if (isUsed) {
    rowStyle.push(styles.used);
  }

  const unAssigningAlert = () =>
    showAlert(
      I18n.t("WARNING"),
      license.headLicenseId == null
        ? I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.UNASSIGN_CONFIRMATION.ACTIVE")
        : I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.UNASSIGN_CONFIRMATION.QUEUED"),
      unassign,
      { negativeText: I18n.t("CANCEL") },
    );

  const buttonContainerStyle = [styles.buttonContainer, { flex: animatedFlex }];
  return (
    <View style={rowStyle} testID={`LICENSE_ROW_${license.licenseKey}`}>
      <View style={styles.leftContainer}>
        <LicenseIcon license={license} screenStyles={styles.icon} />
        <View style={styles.leftTextConttainer}>
          <MkiText textStyle="small" screenStyles={styles.text}>
            {addHyphens(license.licenseKey || "")}
          </MkiText>
          <MkiText textStyle="smallSecondary" screenStyles={styles.text}>
            {`${durationInYear} ${I18n.t("YEAR")}`}
          </MkiText>
          {license.headLicenseKey != null && (
            <MkiText textStyle="smallSecondary" screenStyles={styles.text}>
              {I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.QUEUED", {
                licenseKey: addHyphens(license.headLicenseKey),
              })}
            </MkiText>
          )}
        </View>
      </View>
      <View style={styles.rightContainer}>
        <View style={styles.door}>
          {canAct && (
            <Touchable onPress={toggleButtonContainer} testID="LICENSE_ROW.HAMBURGER">
              <MerakiIcon name="hamburger" size="s" />
            </Touchable>
          )}
        </View>
        <Animated.View style={buttonContainerStyle}>
          {showButtonContainer && !isUsed && (
            <RoundedButton
              onPress={assign}
              loading={assignLicense.isLoading}
              disabled={moveLicense.isLoading}
              buttonType={ButtonType.primary}
              containerStyles={styles.button}
              screenStyles={styles.button}
              textStyles={styles.text}
              testID="LICENSE_ROW.ASSIGN"
            >
              {I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.ASSIGN")}
            </RoundedButton>
          )}
          {showButtonContainer && !isUsed && !isActive && isMultiOrg && (
            <RoundedButton
              onPress={() => setShowOrgs(true)}
              loading={moveLicense.isLoading}
              disabled={assignLicense.isLoading}
              buttonType={ButtonType.tertiary}
              containerStyles={styles.button}
              screenStyles={styles.button}
              textStyles={styles.text}
              testID="LICENSE_ROW.MOVE"
            >
              {I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MOVE")}
            </RoundedButton>
          )}
          {showButtonContainer && isUsed && (
            <RoundedButton
              onPress={unAssigningAlert}
              loading={reassignLicense.isLoading}
              buttonType={ButtonType.primary}
              containerStyles={styles.button}
              screenStyles={styles.button}
              textStyles={styles.text}
              testID="LICENSE_ROW.UNASSIGN"
            >
              {I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.UNASSIGN")}
            </RoundedButton>
          )}
        </Animated.View>
      </View>
      <InfoModal
        visible={showOrgs}
        onExit={() => setShowOrgs(false)}
        testID="LICENSE_ROW.ORG_MODAL"
      >
        <View style={styles.modalHeader}>
          <MkiText textStyle="small">{I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.SELECT_ORG")}</MkiText>
        </View>
        <View style={styles.modalBody}>
          <ScrollView>
            <OrgList onPress={move} />
          </ScrollView>
        </View>
      </InfoModal>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    borderRadius: BOX_BORDER_RADIUS,
    borderWidth: 2,
    borderColor: MkiColors.borderColor,
    paddingHorizontal: SPACING.small,
    paddingVertical: SPACING.meager,
    marginBottom: ROW_SPACINGS.listRow.bottomPadding,
  },
  used: {
    borderColor: MkiColors.goodBar,
  },
  leftContainer: {
    flex: 3,
    flexDirection: "row",
    paddingVertical: SPACING.default,
  },
  icon: {
    paddingHorizontal: SPACING.small,
  },
  leftTextConttainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    fontSize: normalizedFontSize(11),
  },
  rightContainer: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  door: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    paddingVertical: SPACING.meager,
    paddingHorizontal: SPACING.small,
  },
  modalHeader: {
    flex: 1,
    padding: SPACING.small,
  },
  modalBody: {
    flex: 5,
  },
});

export default LicenseRow;
