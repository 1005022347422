import { ReactNode } from "react";

interface BottomSheetProviderProps {
  children: ReactNode;
}

// NOTE: This component is not supported on web. DM-2728
export function BottomSheetProvider({ children }: BottomSheetProviderProps) {
  return children;
}
