import { useCallback, useEffect, useRef } from "react";

import { CancelablePromise, makeCancelablePromise } from "~/lib/RequestUtils";

function useCancelablePromise() {
  const promises = useRef<CancelablePromise<unknown>[]>([]);
  useEffect(() => {
    return () => {
      promises.current.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, []);

  function cancelablePromise<T>(p: Promise<T>): Promise<T> {
    const cPromise = makeCancelablePromise(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { cancelablePromise: useCallback(cancelablePromise, []) };
}

export default useCancelablePromise;
