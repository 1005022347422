import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, List, RefreshControl, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  useApplianceL7FirewallRules,
  useConfiguredSsids,
  useSsidL7FirewallRules,
} from "@meraki/shared/api";
import { getHostFromUrl, toList } from "@meraki/shared/formatters";
import { ALL_SSIDS, useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { capitalize, groupBy } from "lodash";

type ConsolidatedRules = {
  ssidNumber?: number;
  value: string;
};
type ConsolidatedGroupedRules = {
  ssidNumbers: number[];
  ssidNames: string[];
  value: string;
};

export const WebBlockingScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps, "WebBlocking">>();
  const route = useRoute<RouteProp<ConfigureStackProps, "WebBlocking">>();
  const { params: props } = route;
  const networkId = useCurrentNetworkId();
  const { data: ssids } = useConfiguredSsids({ networkId });
  const {
    refetch,
    isLoading,
    isRefetching,
    data: blockedWebsites,
  } = useApplianceL7FirewallRules(
    { networkId },
    {
      select: (data) => data.rules.filter((rule) => rule.type === "host"),
    },
  );

  const ssidRulesResults = useQueries({
    queries:
      ssids?.map(({ number: ssidNumber }) =>
        useSsidL7FirewallRules.useQueries({ networkId, ssidNumber: ssidNumber }),
      ) ?? [],
  });
  const ssidFirewallRules = ssidRulesResults.flatMap(({ data }) => data);
  const consolidatedBlockedWebsites: ConsolidatedRules[] = [];
  blockedWebsites?.forEach((rule) => {
    consolidatedBlockedWebsites.push({ value: rule.value, ssidNumber: ALL_SSIDS });
  });
  ssidFirewallRules.forEach((data) => {
    data?.rules.forEach((rule) => {
      if (rule.type === "host")
        consolidatedBlockedWebsites.push({
          value: rule.value,
          ssidNumber: ssidFirewallRules.indexOf(data),
        });
    });
  });
  const groupedRules = groupBy(consolidatedBlockedWebsites, "value");

  const groupedBlockedWebsites: ConsolidatedGroupedRules[] = [];

  for (const website in groupedRules) {
    const ssidNames: string[] = [];
    const ssidNumbers: number[] = [];
    groupedRules[website]?.forEach((rule) => {
      ssidNumbers.push(rule.ssidNumber ?? ALL_SSIDS);
      const thisSsid = ssids?.find((ssid) => ssid.number === rule.ssidNumber);
      ssidNames.push(thisSsid?.name ?? "All SSIDs and VLANs");
    });
    groupedBlockedWebsites.push({ value: website, ssidNames, ssidNumbers });
  }

  const blockedWebsiteForCurrentSsid = groupedBlockedWebsites.filter((rule) =>
    rule.ssidNames.some((name) => {
      return props.ssid === undefined
        ? name === "All SSIDs and VLANs"
        : name === "All SSIDs and VLANs" || name === props.ssid.name;
    }),
  );

  const getSubtitle = (ssidNames: string[]) => {
    if (ssidNames.length === 0) {
      return "";
    }
    if (ssidNames[0] === "All SSIDs and VLANs") {
      return I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.ALL_NETWORKS");
    } else if (ssidNames.length === ssids?.length) {
      return I18n.t("SSIDS_ROW_LIST.ALL_SSIDS", { ssidTotal: ssidNames.length });
    } else {
      return toList(ssidNames);
    }
  };

  return (
    <Screen refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}>
      <Box paddingTop="md" paddingHorizontal="md" bottomDividerBorder>
        <Heading size="h1">{I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.TITLE")}</Heading>
      </Box>
      <Box paddingHorizontal="md">
        <Text size="p1">{I18n.t("SSID_SCREEN.TABS.PREFERENCES.WEB_BLOCKING.DESCRIPTION")}</Text>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" paddingTop="lg">
          <Heading>{I18n.t("BLOCK_CONTENT.SUBTITLE")}</Heading>
          <Box flexDirection="row" alignItems="center">
            <Button
              kind="tertiary"
              text={I18n.t("ADD")}
              trailingIcon="Plus"
              onPress={() =>
                navigation.navigate("BlockWebsite", {
                  isConfiguredEverywhere: props.ssid === undefined,
                  isAdd: true,
                })
              }
            />
          </Box>
        </Box>
      </Box>
      <List.FlashList
        data={props.ssid ? blockedWebsiteForCurrentSsid : groupedBlockedWebsites}
        getItemData={(rule) => {
          return {
            title: capitalize(getHostFromUrl(rule.value)),
            description: rule.value,
            children: <Text color="light">{getSubtitle(rule.ssidNames)}</Text>,
            rightAccessory: (
              <Button
                kind="tertiary"
                text={I18n.t("EDIT")}
                onPress={() =>
                  navigation.navigate("BlockWebsite", {
                    isConfiguredEverywhere: rule.ssidNumbers[0] === ALL_SSIDS,
                    ssidNumbers: rule.ssidNumbers,
                    url: rule.value,
                    isAdd: false,
                  })
                }
              />
            ),
            hidePressable: true,
          };
        }}
        loading={isLoading}
        ListEmptyComponent={
          <Card>
            <Text size="p1">{I18n.t("NOT_ADDED")}</Text>
          </Card>
        }
        paddingTop="none"
      />
    </Screen>
  );
};
