import * as magneticBaseTheme from "@magnetic/design-tokens";
import Color from "color";

import { baseTheme } from "../baseTheme";
import { colorPalette } from "../colorPalette";
import { Theme } from "../types";

export const goLightTheme: Theme = {
  ...magneticBaseTheme,
  ...baseTheme,

  color: {
    transparent: colorPalette.neutral.transparent,

    default: {
      bg: {
        base: colorPalette.neutral[98],
        disabled: colorPalette.neutral[95],

        weak: {
          base: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.neutral[45],
          hover: colorPalette.neutral[15],
          focus: colorPalette.neutral[15],
          active: colorPalette.neutral[15],
          disabled: colorPalette.neutral[85],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      text: {
        base: colorPalette.neutral[15],
        disabled: colorPalette.neutral[80],

        weak: {
          base: colorPalette.neutral[60],
          disabled: colorPalette.neutral[80],
        },
        medium: {
          base: colorPalette.neutral[45],
          disabled: colorPalette.neutral[80],
        },
        strong: {
          base: colorPalette.neutral[25],
          disabled: colorPalette.neutral[80],
        },
      },
      textIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],

        weak: {
          base: colorPalette.neutral[98],
        },
      },
      border: {
        base: colorPalette.neutral[90],
        weak: {
          base: colorPalette.neutral[100],
        },
        medium: {
          base: colorPalette.neutral[90],
        },
        strong: {
          base: colorPalette.neutral[45],
        },
      },
    },

    interact: {
      bg: {
        base: colorPalette.purple[55],
        hover: colorPalette.purple[50],
        focus: colorPalette.purple[50],
        active: colorPalette.purple[50],
        disabled: colorPalette.purple[80],

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.purple[90],
          focus: colorPalette.purple[90],
          active: colorPalette.purple[90],
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.purple[60],
        },
      },
      text: {
        base: colorPalette.purple[55],
        hover: colorPalette.purple[50],
        focus: colorPalette.purple[50],
        active: colorPalette.purple[50],
        disabled: colorPalette.purple[80],

        weak: {
          base: colorPalette.neutral[45],
          hover: colorPalette.purple[50],
          focus: colorPalette.purple[50],
          active: colorPalette.purple[50],
          disabled: colorPalette.neutral[80],
        },
      },
      textIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },
      icon: {
        base: colorPalette.purple[55],
        hover: colorPalette.purple[50],
        focus: colorPalette.purple[50],
        active: colorPalette.purple[50],
        disabled: colorPalette.purple[80],

        weak: {
          base: colorPalette.neutral[55],
          hover: colorPalette.neutral[50],
          focus: colorPalette.neutral[50],
          active: colorPalette.neutral[50],
          disabled: colorPalette.neutral[85],
        },

        medium: {
          base: colorPalette.neutral[55],
          hover: colorPalette.purple[60],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[60],
          disabled: colorPalette.neutral[80],
        },
      },
      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },
      border: {
        base: colorPalette.purple[55],
        hover: colorPalette.purple[50],
        focus: colorPalette.purple[50],
        active: colorPalette.purple[50],
        disabled: colorPalette.purple[80],

        weak: {
          base: colorPalette.neutral[90],
          hover: colorPalette.purple[90],
          focus: colorPalette.neutral[90],
          active: colorPalette.neutral[90],
          disabled: colorPalette.neutral[90],
        },

        medium: {
          base: colorPalette.purple[60],
          hover: colorPalette.purple[85],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[60],
          disabled: colorPalette.neutral[90],
        },
      },
    },

    control: {
      bg: {
        base: colorPalette.neutral[100],
        hover: colorPalette.purple[95],
        focus: colorPalette.neutral[95],
        active: colorPalette.neutral[95],
        disabled: colorPalette.neutral[98],

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.purple[90],
          focus: colorPalette.purple[95],
          active: colorPalette.purple[95],
          disabled: colorPalette.neutral[98],
        },

        medium: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[98],
          focus: colorPalette.purple[95],
          active: colorPalette.purple[95],
          disabled: colorPalette.neutral[90],
        },

        strong: {
          base: colorPalette.purple[60],
          hover: colorPalette.purple[50],
          focus: colorPalette.purple[50],
          active: colorPalette.purple[50],
          disabled: colorPalette.purple[85],
        },
      },

      icon: {
        base: colorPalette.purple[55],
        hover: colorPalette.purple[50],
        focus: colorPalette.purple[50],
        active: colorPalette.purple[50],
        disabled: colorPalette.purple[80],

        weak: {
          base: colorPalette.neutral[55],
          hover: colorPalette.purple[60],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[60],
          disabled: colorPalette.neutral[85],
        },

        medium: {
          base: colorPalette.purple[55],
          hover: colorPalette.purple[50],
          focus: colorPalette.purple[50],
          active: colorPalette.purple[50],
          disabled: colorPalette.purple[80],
        },

        strong: {
          base: colorPalette.neutral[45],
          hover: colorPalette.neutral[35],
          focus: colorPalette.purple[55],
          active: colorPalette.purple[55],
          disabled: colorPalette.neutral[80],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[80],

        strong: {
          base: colorPalette.purple[60],
          hover: colorPalette.purple[60],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[60],
          disabled: colorPalette.neutral[80],
        },
      },

      border: {
        base: colorPalette.neutral[60],
        hover: colorPalette.purple[60],
        focus: Color(colorPalette.purple[60]).alpha(0.2).hexa(),
        active: colorPalette.purple[60],
        disabled: colorPalette.neutral[85],

        weak: {
          base: colorPalette.neutral[90],
          hover: colorPalette.purple[70],
          focus: colorPalette.purple[70],
          active: colorPalette.purple[70],
          disabled: colorPalette.neutral[85],
        },

        medium: {
          base: colorPalette.purple[60],
          hover: colorPalette.purple[85],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[65],
          disabled: colorPalette.neutral[85],
        },

        strong: {
          base: colorPalette.neutral[45],
          hover: colorPalette.neutral[45],
          focus: colorPalette.purple[60],
          active: colorPalette.purple[60],
          disabled: colorPalette.neutral[85],
        },
      },
    },

    positive: {
      bg: {
        base: colorPalette.positive[60], // Positive7?
        hover: colorPalette.positive[60], // Positive7?
        focus: colorPalette.positive[60], // Positive7?
        active: colorPalette.positive[60], // Positive7?
        disabled: colorPalette.positive[85], // Positive4?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.positive[95], // Positive1?
          hover: colorPalette.positive[95], // Positive1?
          focus: colorPalette.positive[95], // Positive1?
          active: colorPalette.positive[95], // Positive1?
          disabled: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.positive[95], // Positive1?
        },
      },

      icon: {
        base: colorPalette.positive[60], // Positive7?
        hover: colorPalette.positive[60], // Positive7?
        focus: colorPalette.positive[60], // Positive7?
        active: colorPalette.positive[60], // Positive7?
        disabled: colorPalette.neutral[85],

        weak: {
          base: colorPalette.positive[85], // Positive4?
          hover: colorPalette.positive[85], // Positive4?
          focus: colorPalette.positive[85], // Positive4?
          active: colorPalette.positive[85], // Positive4?
          disabled: colorPalette.positive[85], // Positive4?
        },

        strong: {
          base: colorPalette.positive[60], // Positive7?
          hover: colorPalette.positive[60], // Positive7?
          focus: colorPalette.positive[60], // Positive7?
          active: colorPalette.positive[60], // Positive7?
          disabled: colorPalette.neutral[85],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      border: {
        base: colorPalette.positive[60], // Positive7?
        hover: colorPalette.positive[60], // Positive7?
        focus: colorPalette.positive[60], // Positive7?
        active: colorPalette.positive[60], // Positive7?
        disabled: colorPalette.positive[60], // Positive7?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[85],
        },

        strong: {
          base: colorPalette.positive[55], // Positive8?
          hover: colorPalette.positive[55], // Positive8?
          focus: colorPalette.positive[55], // Positive8?
          active: colorPalette.positive[55], // Positive8?
          disabled: colorPalette.positive[55], // Positive8?
        },
      },

      textIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },
    },

    negative: {
      bg: {
        base: colorPalette.negative[55], // Negative8?
        hover: colorPalette.negative[50], // Negative9?
        focus: colorPalette.negative[50], // Negative9?
        active: colorPalette.negative[50], // Negative9?
        disabled: colorPalette.negative[80], // Negative4?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.negative[90], // Negative2?
          focus: colorPalette.negative[90], // Negative2?
          active: colorPalette.negative[90], // Negative2?
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.negative[90], // Negative2?
          hover: colorPalette.negative[90], // Negative2?
          focus: colorPalette.negative[90], // Negative2?
          active: colorPalette.negative[90], // Negative2?
          disabled: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.negative[90], // Negative2?
        },
      },

      text: {
        base: colorPalette.negative[55], // Negative8?
        hover: colorPalette.negative[50], // Negative9?
        focus: colorPalette.negative[50], // Negative9?
        active: colorPalette.negative[50], // Negative9?
        disabled: colorPalette.negative[80], // Negative4?
      },

      textIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      icon: {
        base: colorPalette.negative[55], // Negative8?
        hover: colorPalette.negative[50], // Negative9?
        focus: colorPalette.negative[50], // Negative9?
        active: colorPalette.negative[50], // Negative9?
        disabled: colorPalette.negative[80], // Negative4?

        weak: {
          base: colorPalette.negative[80], // Negative4?
          hover: colorPalette.negative[80], // Negative4?
          focus: colorPalette.negative[80], // Negative4?
          active: colorPalette.negative[80], // Negative4?
          disabled: colorPalette.neutral[85],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      border: {
        base: colorPalette.negative[55], // Negative8?
        hover: colorPalette.negative[50], // Negative9?
        focus: colorPalette.negative[50], // Negative9?
        active: colorPalette.negative[50], // Negative9?
        disabled: colorPalette.negative[80], // Negative4?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[85],
        },

        medium: {
          base: colorPalette.negative[60], // Negative7?
          hover: colorPalette.negative[60], // Negative7?
          focus: colorPalette.negative[60], // Negative7?
          active: colorPalette.negative[60], // Negative7?
          disabled: colorPalette.negative[80], // Negative4?
        },

        strong: {
          base: colorPalette.negative[55], // Negative8?
          hover: colorPalette.negative[55], // Negative8?
          focus: colorPalette.negative[55], // Negative8?
          active: colorPalette.negative[55], // Negative8?
          disabled: colorPalette.negative[80], // Negative4?
        },
      },
    },

    severeWarning: {
      bg: {
        base: colorPalette.severeWarning[60],
        hover: colorPalette.severeWarning[60],
        focus: colorPalette.severeWarning[60],
        active: colorPalette.severeWarning[60],
        disabled: colorPalette.severeWarning[85],

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.severeWarning[95],
          hover: colorPalette.severeWarning[95],
          focus: colorPalette.severeWarning[95],
          active: colorPalette.severeWarning[95],
          disabled: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.severeWarning[95],
        },
      },

      icon: {
        base: colorPalette.severeWarning[60],
        hover: colorPalette.severeWarning[55],
        focus: colorPalette.severeWarning[50],
        active: colorPalette.severeWarning[50],
        disabled: colorPalette.neutral[85],

        strong: {
          base: colorPalette.severeWarning[55],
          hover: colorPalette.severeWarning[55],
          focus: colorPalette.severeWarning[55],
          active: colorPalette.severeWarning[55],
          disabled: colorPalette.severeWarning[55],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      border: {
        base: colorPalette.severeWarning[60],
        hover: colorPalette.severeWarning[60],
        focus: colorPalette.severeWarning[60],
        active: colorPalette.severeWarning[60],
        disabled: colorPalette.severeWarning[60],

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[85],
        },
      },
    },

    warning: {
      bg: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.warning[70], // Warning5?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.warning[95], // Warning1?
          hover: colorPalette.warning[95], // Warning1?
          focus: colorPalette.warning[95], // Warning1?
          active: colorPalette.warning[95], // Warning1?
          disabled: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.warning[95], // Warning1?
        },
      },

      icon: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.neutral[85],

        strong: {
          base: colorPalette.warning[70], // Warning5?
          hover: colorPalette.warning[70], // Warning5?
          focus: colorPalette.warning[70], // Warning5?
          active: colorPalette.warning[70], // Warning5?
          disabled: colorPalette.neutral[85],
        },
      },

      iconIn: {
        base: colorPalette.neutral[35],
        hover: colorPalette.neutral[35],
        focus: colorPalette.neutral[35],
        active: colorPalette.neutral[35],
        disabled: colorPalette.neutral[100],
      },

      border: {
        base: colorPalette.warning[70], // Warning5?
        hover: colorPalette.warning[70], // Warning5?
        focus: colorPalette.warning[70], // Warning5?
        active: colorPalette.warning[70], // Warning5?
        disabled: colorPalette.warning[70], // Warning5?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[85],
        },

        strong: {
          base: colorPalette.warning[50], // Warning9?
          hover: colorPalette.warning[50], // Warning9?
          focus: colorPalette.warning[50], // Warning9?
          active: colorPalette.warning[50], // Warning9?
          disabled: colorPalette.warning[50], // Warning9?
        },
      },

      textIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },
    },

    info: {
      bg: {
        base: colorPalette.info[65], // Info6?
        hover: colorPalette.info[65], // Info6?
        focus: colorPalette.info[65], // Info6?
        active: colorPalette.info[65], // Info6?
        disabled: colorPalette.info[65], // Info6?

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.info[90], // Info2?
          hover: colorPalette.info[90], // Info2?
          focus: colorPalette.info[90], // Info2?
          active: colorPalette.info[90], // Info2?
          disabled: colorPalette.neutral[98],
        },

        strong: {
          base: colorPalette.info[90], // Info2?
        },
      },

      icon: {
        base: colorPalette.info[65], // Info6?
        hover: colorPalette.info[65], // Info6?
        focus: colorPalette.info[65], // Info6?
        active: colorPalette.info[65], // Info6?
        disabled: colorPalette.neutral[85],

        weak: {
          base: colorPalette.info[80], // Info4?
          hover: colorPalette.info[80], // Info4?
          focus: colorPalette.info[80], // Info4?
          active: colorPalette.info[80], // Info4?
          disabled: colorPalette.info[80], // Info4?
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],
      },

      border: {
        base: colorPalette.info[65], // Info6
        hover: colorPalette.info[65], // Info6
        focus: colorPalette.info[65], // Info6
        active: colorPalette.info[65], // Info6
        disabled: colorPalette.info[65], // Info6

        weak: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[85],
        },
      },
    },

    accent: {
      a: {
        base: colorPalette.accentA[60],
        hover: colorPalette.accentA[55],
        weak: colorPalette.accentA[95],
      },

      b: {
        base: colorPalette.accentBC[40],
        hover: colorPalette.accentBC[35],
        weak: colorPalette.accentBC[95],
      },

      c: {
        base: colorPalette.accentBC[60],
        hover: colorPalette.accentBC[55],
        weak: colorPalette.accentBC[90],
      },

      d: {
        base: colorPalette.accentD[40],
        hover: colorPalette.accentD[35],
        weak: colorPalette.accentD[95],
      },

      e: {
        base: colorPalette.accentE[60],
        hover: colorPalette.accentE[55],
        weak: colorPalette.accentE[95],
      },

      f: {
        base: colorPalette.accentF[40],
        hover: colorPalette.accentF[35],
        weak: colorPalette.accentF[95],
      },

      g: {
        base: colorPalette.accentGH[60],
        hover: colorPalette.accentGH[55],
        weak: colorPalette.accentGH[95],
      },

      h: {
        base: colorPalette.accentGH[40],
        hover: colorPalette.accentGH[35],
        weak: colorPalette.accentGH[90],
      },

      i: {
        base: colorPalette.accentI[60],
        hover: colorPalette.accentI[55],
        weak: colorPalette.accentI[95],
      },

      j: {
        base: colorPalette.accentJ[40],
        hover: colorPalette.accentJ[35],
        weak: colorPalette.accentJ[95],
      },

      k: {
        base: colorPalette.accentK[60],
        hover: colorPalette.accentK[55],
        weak: colorPalette.accentK[95],
      },
    },

    dormant: {
      bg: {
        base: colorPalette.neutral[50],
        disabled: colorPalette.neutral[50],

        medium: {
          base: colorPalette.neutral[90],
          disabled: colorPalette.neutral[98],
        },
      },

      icon: {
        base: colorPalette.neutral[50],
        disabled: colorPalette.neutral[85],

        medium: {
          base: colorPalette.neutral[100],
        },
      },

      iconIn: {
        base: colorPalette.neutral[100],
        disabled: colorPalette.neutral[100],

        medium: {
          base: colorPalette.neutral[100],
        },
      },

      border: {
        base: colorPalette.neutral[60],
        disabled: colorPalette.neutral[85],
      },
    },

    inverse: {
      bg: {
        base: colorPalette.neutral[25],
        hover: colorPalette.neutral[25],
        focus: colorPalette.neutral[35],
        active: colorPalette.neutral[35],
        disabled: colorPalette.neutral[90],

        gradient: {
          start: colorPalette.neutral[25],
          end: colorPalette.neutral[5],
        },

        weak: {
          base: colorPalette.neutral[25],
        },
      },

      text: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[98],
        focus: colorPalette.neutral[98],
        active: colorPalette.neutral[98],
        disabled: colorPalette.neutral[45],

        medium: {
          base: colorPalette.neutral[80],
          disabled: colorPalette.neutral[45],
        },
      },

      icon: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[100],
        focus: colorPalette.neutral[100],
        active: colorPalette.neutral[100],
        disabled: colorPalette.neutral[50],

        strong: {
          base: colorPalette.neutral[100],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.neutral[100],
          disabled: colorPalette.neutral[100],
        },
      },

      border: {
        base: colorPalette.neutral[50],
        hover: colorPalette.purple[65],
        focus: colorPalette.purple[90],
        active: colorPalette.purple[90],
        disabled: colorPalette.neutral[90],
      },
    },

    brandAccent: {
      icon: {
        base: colorPalette.neutral[60],
        focus: colorPalette.neutral[100],
        active: colorPalette.purple[55], // Brand Color!
        disabled: colorPalette.neutral[85],

        weak: {
          base: colorPalette.neutral[85],
          focus: colorPalette.neutral[100],
          active: colorPalette.purple[50], // Brand Color!
          disabled: colorPalette.neutral[85],
        },

        medium: {
          base: colorPalette.neutral[70],
          focus: colorPalette.neutral[100],
          active: colorPalette.purple[55], // Brand Color!
          disabled: colorPalette.neutral[85],
        },
      },

      border: {
        base: colorPalette.purple[65], // Brand Color!
        hover: colorPalette.neutral[90],
        focus: colorPalette.purple[65], // Brand Color!
        active: colorPalette.purple[65], // Brand Color!

        weak: {
          base: colorPalette.neutral[85],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.purple[70], // Brand Color!
          disabled: colorPalette.neutral[100],
        },

        medium: {
          base: colorPalette.neutral[95],
          hover: colorPalette.neutral[100],
          focus: colorPalette.neutral[100],
          active: colorPalette.purple[95], // Brand Color!
          disabled: colorPalette.neutral[100],
        },

        strong: {
          base: colorPalette.neutral[98],
          hover: colorPalette.neutral[98],
          focus: colorPalette.neutral[98],
          active: colorPalette.neutral[98],
          disabled: colorPalette.neutral[100],
        },
      },
    },

    brand: {
      bg: {
        base: colorPalette.neutral[98],
        hover: colorPalette.neutral[95],
        focus: colorPalette.neutral[90],
        active: colorPalette.neutral[90],
        disabled: colorPalette.neutral[100],

        gradient: {
          start: colorPalette.neutral[98],
          end: colorPalette.neutral[90],
        },
      },

      text: {
        base: colorPalette.neutral[5],

        weak: {
          base: colorPalette.neutral[35],
        },

        strong: {
          base: colorPalette.neutral[0],
        },
      },
    },

    indoorAirQuality: colorPalette.indoorAirQuality,
    attributeSearch: colorPalette.attributeSearch,
  },

  mode: "light",
};
