import CodePush from "react-native-code-push";

import { isWeb } from "~/lib/PlatformUtils";

const withCodePush = (component: (props: any) => JSX.Element) => {
  if (isWeb() || process.env.E2E) {
    return component;
  }

  return CodePush({
    updateDialog: false,
    checkFrequency: CodePush.CheckFrequency.ON_APP_RESUME,

    // since our app is not a single component, immediate in this context just means when the component is loaded
    installMode: CodePush.InstallMode.IMMEDIATE,
    mandatoryInstallMode: CodePush.InstallMode.IMMEDIATE,
  })(component);
};

export default withCodePush;
