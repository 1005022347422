import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function NegativeRepeater({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        d="M1.674 6.5A6.51 6.51 0 0 1 6.5 1.674v1.398A5.162 5.162 0 0 0 3.072 6.5H1.674ZM1.674 9.5A6.51 6.51 0 0 0 6.5 14.326v-1.398A5.161 5.161 0 0 1 3.072 9.5H1.674ZM12.928 9.5A5.161 5.161 0 0 1 9.5 12.928v1.398A6.51 6.51 0 0 0 14.326 9.5h-1.398ZM14.326 6.5A6.51 6.51 0 0 0 9.5 1.674v1.398A5.161 5.161 0 0 1 12.928 6.5h1.398ZM10.505 6.495a.7.7 0 1 0-.99-.99L7.981 7.039 6.495 5.553a.7.7 0 0 0-.99.99l1.486 1.486-1.436 1.436a.7.7 0 0 0 .99.99l1.436-1.436 1.484 1.483a.7.7 0 1 0 .99-.99L8.971 8.03l1.534-1.534Z"
        fill={theme.color.negative.icon.base}
      />
    </Svg>
  );
}
