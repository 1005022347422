import { I18n } from "@meraki/core/i18n";
import { HelpStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Checkbox,
  Text,
} from "@meraki/magnetic/components";
import { Alert, Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  Device,
  NewSupportCaseInput,
  useDevices,
  useNewSupportCase,
  useOrgNetworks,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showAlert, showErrorAlert } from "@meraki/shared/native-alert";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Keyboard, Linking, Pressable } from "react-native";
import DeviceInfo from "react-native-device-info";

import {
  SUPPORT_CASE_SUBJECTS_CISCO_CX,
  SUPPORT_CASE_SUBJECTS_MERAKI,
} from "../utils/SupportCaseUtils";

const MIN_ACCESSORY_HEIGHT = 22;

export function NewSupportCaseScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackProps, "NewSupportCase">>();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const email = useCurrentUserEmail();

  const subjectSelectRef = useRef<BottomSheetMethods>(null);
  const devicesSelectRef = useRef<BottomSheetMethods>(null);

  const { mutate: createNewSupportCase, isLoading } = useNewSupportCase();
  const { data: devices } = useDevices({ organizationId, networkId });

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);

  const methods = useForm<NewSupportCaseInput>({
    defaultValues: {
      subject: "",
      description: "",
      related_devices: "",
      // enterprise params
      orgid: organizationId,
      // go params
      email_override: email,
      Customer_Assigned_Priority__c: "Medium",
      callback_number: undefined,
    },
  });

  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId) ?? data[0];
      },
    },
  );

  const isCXSubject = Object.values(I18n.t("SUPPORT.GOv3.SUBJECT.OPTIONS.CX")).includes(
    methods.watch("subject"),
  );

  const addSuppportCase = useCallback(
    (newSupportCaseInput: NewSupportCaseInput) => {
      const deviceNames = selectedDevices.map((device) => device.name ?? device.serial).join("\n");
      const newSupportCase: NewSupportCaseInput = {
        ...newSupportCaseInput,
        related_devices: selectedDevices.map((device) => device.id).join(";"),
        description: `${
          newSupportCaseInput.description
        }\n\n\n----------\nRelated devices:\n${deviceNames}\nVersion: ${DeviceInfo.getVersion()}\nBuild: ${DeviceInfo.getBuildNumber()}`,
      };

      if (isCXSubject) {
        Linking.openURL(
          `mailto:ask-merakigo@cisco.com?subject=${newSupportCase.subject}&body=${newSupportCase.description}`,
        );
      } else {
        createNewSupportCase(
          { encryptedNetworkId: network?.eid, body: newSupportCase },
          {
            onSuccess: (response) => {
              if (response.success) {
                showAlert(
                  I18n.t("SUPPORT.CASE_CREATED.TITLE"),
                  I18n.t("SUPPORT.CASE_CREATED.MESSAGE"),
                  [
                    {
                      text: I18n.t("OK"),
                      onPress: () => navigation.goBack(),
                    },
                  ],
                );
              } else if (response.need_captcha) {
                showAlert(
                  I18n.t("SUPPORT.CASE_NOT_CREATED.TITLE"),
                  I18n.t("SUPPORT.CASE_NOT_CREATED.MESSAGE_LIMITED"),
                  [{ text: I18n.t("OK") }],
                );
              } else {
                showAlert(
                  I18n.t("SUPPORT.CASE_NOT_CREATED.TITLE"),
                  I18n.t("SUPPORT.CASE_NOT_CREATED.MESSAGE"),
                  [{ text: I18n.t("OK") }],
                );
              }
            },

            onError(error) {
              showErrorAlert(JSON.stringify(error));
            },
          },
        );
      }
    },
    [createNewSupportCase, isCXSubject, navigation, network?.eid, selectedDevices],
  );

  const handleDeviceSelection = useCallback(
    (device: Device) => {
      if (selectedDevices.includes(device)) {
        const newList = selectedDevices.filter((filterDevice) => filterDevice !== device);
        setSelectedDevices(newList);
      } else {
        setSelectedDevices([...selectedDevices, device]);
      }
    },
    [selectedDevices],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SUPPORT.GOv3.CREATE")}
          onPress={methods.handleSubmit(addSuppportCase)}
          disabled={isLoading}
        />
      ),
    });
  }, [addSuppportCase, isLoading, methods, navigation]);

  const subjectVal = methods.watch("subject");

  return (
    <Screen addDefaultPadding>
      {isCXSubject && (
        <Box flex={1} flexDirection="row">
          <Box
            flex={1}
            flexDirection="row"
            gap="2xs"
            alignItems="center"
            padding="xs"
            borderRadius="md"
            backgroundColor={`info.bg.strong.base`}
            testID="SUPPORT_HOURS_BANNER"
          >
            <Box flexDirection="column">
              <Box flexDirection="row" gap="sm">
                <Alert status="informational" />
                <Text color="default.text.base" weight="bold">
                  {I18n.t("SUPPORT.GOv3.CISCO_CX_BANNER.TITLE")}
                </Text>
              </Box>
              <Box paddingLeft="xl">
                <Text color="default.text.base">
                  {I18n.t("SUPPORT.GOv3.CISCO_CX_BANNER.MESSAGE")}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Form {...methods}>
        <Box>
          <Box paddingLeft="2xs" paddingBottom="2xs">
            <Text size="p3" weight="semiBold">
              {I18n.t("SUPPORT.SECTION.SUBJECT")}
            </Text>
          </Box>
          <Pressable
            testID="SUBJECT_ROW"
            onPress={() => {
              Keyboard.dismiss();
              subjectSelectRef?.current?.present();
            }}
          >
            <Box
              borderRadius="sm"
              backgroundColor={`control.bg.weak.${isLoading ? "disabled" : "base"}`}
              borderColor={`control.border.${isLoading ? "disabled" : "base"}`}
              borderWidth="strong"
              padding="sm"
            >
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <Text color={subjectVal === "" ? "light" : "regular"}>
                  {subjectVal === "" ? I18n.t("SUPPORT.GOv3.SUBJECT.PLACEHOLDER") : subjectVal}
                </Text>
                <Icon name="CaretRight" size={MIN_ACCESSORY_HEIGHT} />
              </Box>
            </Box>
          </Pressable>
        </Box>
        <Form.Picker
          ref={subjectSelectRef}
          name="subject"
          rules={{ required: I18n.t("SUPPORT.EMPTY_CASE_ERROR.SUBJECT") }}
          options={SUPPORT_CASE_SUBJECTS_MERAKI.concat(SUPPORT_CASE_SUBJECTS_CISCO_CX)}
          snapPoints={["CONTENT_HEIGHT"]}
        />
        <Form.Input
          name="description"
          disabled={isLoading}
          rules={{ required: I18n.t("SUPPORT.EMPTY_CASE_ERROR.MESSAGE") }}
          label={I18n.t("SUPPORT.GOv3.DESCRIPTION_LABEL")}
          multiline
          numberOfLines={5}
          textAlignVertical="top"
          placeholder={I18n.t("SUPPORT.GOv3.DESCRIPTION_PLACEHOLDER")}
          testID="DESCRIPTION_INPUT"
        />
        <Box>
          <Box paddingLeft="2xs" paddingBottom="2xs">
            <Text size="p3" weight="semiBold">
              {I18n.t("SUPPORT.GOv3.RELATED_DEVICES")}
            </Text>
          </Box>
          <Pressable
            testID="RELATED_DEVICES_ROW"
            onPress={() => {
              Keyboard.dismiss();
              devicesSelectRef?.current?.present();
            }}
          >
            <Box
              borderRadius="sm"
              backgroundColor={`control.bg.weak.${isLoading ? "disabled" : "base"}`}
              borderColor={`control.border.${isLoading ? "disabled" : "base"}`}
              borderWidth="strong"
              padding="sm"
            >
              <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                <Text color={selectedDevices.length > 0 ? "regular" : "light"}>
                  {I18n.t("SUPPORT.GOv3.SELECTED_DEVICES", {
                    count: selectedDevices.length,
                    devices: selectedDevices
                      .map((selectedDevice) => selectedDevice.name ?? selectedDevice.serial)
                      .join(", "),
                  })}
                </Text>
                <Icon name="CaretRight" size={MIN_ACCESSORY_HEIGHT} />
              </Box>
            </Box>
            <Box paddingHorizontal="2xs" paddingVertical="2xs">
              <Text color="light">{I18n.t("OPTIONAL")}</Text>
            </Box>
          </Pressable>
        </Box>
      </Form>
      <BottomSheet.Modal ref={devicesSelectRef} snapPoints={["CONTENT_HEIGHT", "95%"]} index={0}>
        <BottomSheet.Header
          title={I18n.t("SUPPORT.GOv3.RELATED_DEVICES")}
          onResetPress={() => setSelectedDevices([])}
          cancelLabel={I18n.t("DONE")}
          onCancelPress={() => devicesSelectRef.current?.dismiss()}
        />
        <BottomSheet.FlashList
          data={devices}
          estimatedItemSize={50}
          getItemData={(device) => {
            return {
              title: device.name ?? device.serial,
              leftAccessory: (
                <Checkbox
                  checked={selectedDevices.includes(device)}
                  onValueChange={() => handleDeviceSelection(device)}
                />
              ),
              onPress: () => handleDeviceSelection(device),
              hidePressable: true,
            };
          }}
        />
        <Box paddingBottom="md" />
      </BottomSheet.Modal>
    </Screen>
  );
}
