import { useTheme } from "@meraki/core/theme";
import { getProductType } from "@meraki/shared/devices";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Defs, LinearGradient, Stop } from "react-native-svg";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { OnboardingStackProps } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { getPluginHardwareMap } from "~/lib/OnboardingFullstackUtils";
import { themeColors } from "~/lib/themeHelper";
import { hasGRDevices, hasGSDevices, hasGXDevices, onboardingDevices } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import IndicatorViewPager from "~/shared/components/viewPager/IndicatorViewPager";
import ViewPager from "~/shared/components/viewPager/ViewPager";
import { CloseButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import { ProductType } from "~/shared/types/Networks";
import { OnboardingScannedDevices } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions } from "~/store";

const renderLedGradient = ({ id }: { id: string }) => (
  <Defs>
    <LinearGradient x1="100%" y1="100%" x2="0%" y2="0%" id={id}>
      <Stop stopColor="#FF7171" stopOpacity="1" offset="0%" />
      <Stop stopColor="#FFDA71" stopOpacity="1" offset="33.6714764%" />
      <Stop stopColor="#71FFCB" stopOpacity="1" offset="50.5082111%" />
      <Stop stopColor="#16E4D8" stopOpacity="1" offset="63.2633131%" />
      <Stop stopColor="#6665F6" stopOpacity="1" offset="100%" />
    </LinearGradient>
  </Defs>
);

const ICONS = {
  SOLID_ORANGE: {
    name: "solidLED",
    color: "#FFAA7D",
  },
  BLINKING_ORANGE: {
    name: "blinkingLED",
    color: "#FFAA7D",
  },
  SOLID_WHITE: {
    name: "solidLED",
    color: "#E2EDFF",
  },
  BLINKING_WHITE: {
    name: "blinkingLED",
    color: "#E2EDFF",
  },
  SOLID_GREEN: {
    name: "solidLED",
    color: "#50E3C2",
  },
  BLINKING_BLUE: {
    name: "blinkingLED",
    color: "#6A5EFF",
  },
  MULTI_COLOR: {
    name: "solidLED",
    hasGradient: true,
    renderGradient: renderLedGradient,
  },
} as const;

type Page = {
  model: (typeof ProductType)[keyof typeof ProductType];
  name: string;
  rows: {
    status: string;
    icon: (typeof ICONS)[keyof typeof ICONS];
    children: string;
    pagerText: string;
  }[];
};

export const WIRELESS_PAGE = {
  model: ProductType.wireless,
  name: I18n.t("LEDS.GR.NAME"),
  rows: [
    {
      status: I18n.t("LEDS.GR.ORANGE.STATUS"),
      icon: ICONS.SOLID_ORANGE,
      children: I18n.t("LEDS.GR.ORANGE.COLOR"),
      pagerText: I18n.t("LEDS.GR.ORANGE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GR.BLINKING_ORANGE.STATUS"),
      icon: ICONS.BLINKING_ORANGE,
      children: I18n.t("LEDS.GR.BLINKING_ORANGE.COLOR"),
      pagerText: I18n.t("LEDS.GR.BLINKING_ORANGE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GR.MULTI_COLOR.STATUS"),
      icon: ICONS.MULTI_COLOR,
      children: I18n.t("LEDS.GR.MULTI_COLOR.COLOR"),
      pagerText: I18n.t("LEDS.GR.MULTI_COLOR.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GR.BLINKING_BLUE.STATUS"),
      icon: ICONS.BLINKING_BLUE,
      children: I18n.t("LEDS.GR.BLINKING_BLUE.COLOR"),
      pagerText: I18n.t("LEDS.GR.BLINKING_BLUE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GR.GREEN.STATUS"),
      icon: ICONS.SOLID_GREEN,
      children: I18n.t("LEDS.GR.GREEN.COLOR"),
      pagerText: I18n.t("LEDS.GR.GREEN.DESCRIPTION"),
    },
  ],
};

export const SECURITY_PAGE = {
  model: ProductType.appliance,
  name: I18n.t("LEDS.GX.NAME"),
  rows: [
    {
      status: I18n.t("LEDS.GX.ORANGE.STATUS"),
      icon: ICONS.SOLID_ORANGE,
      children: I18n.t("LEDS.GX.ORANGE.COLOR"),
      pagerText: I18n.t("LEDS.GX.ORANGE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GX.MULTI_COLOR.STATUS"),
      icon: ICONS.MULTI_COLOR,
      children: I18n.t("LEDS.GX.MULTI_COLOR.COLOR"),
      pagerText: I18n.t("LEDS.GX.MULTI_COLOR.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GX.BLINKING_WHITE.STATUS"),
      icon: ICONS.BLINKING_WHITE,
      children: I18n.t("LEDS.GX.BLINKING_WHITE.COLOR"),
      pagerText: I18n.t("LEDS.GX.BLINKING_WHITE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GX.WHITE.STATUS"),
      icon: ICONS.SOLID_WHITE,
      children: I18n.t("LEDS.GX.WHITE.COLOR"),
      pagerText: I18n.t("LEDS.GX.WHITE.DESCRIPTION"),
    },
  ],
};

export const SWITCH_PAGE = {
  model: ProductType.switch,
  name: I18n.t("LEDS.GS.NAME"),
  rows: [
    {
      status: I18n.t("LEDS.GS.MULTI_COLOR.STATUS"),
      icon: ICONS.MULTI_COLOR,
      children: I18n.t("LEDS.GS.MULTI_COLOR.COLOR"),
      pagerText: I18n.t("LEDS.GS.MULTI_COLOR.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GS.BLINKING_WHITE.STATUS"),
      icon: ICONS.BLINKING_WHITE,
      children: I18n.t("LEDS.GS.BLINKING_WHITE.COLOR"),
      pagerText: I18n.t("LEDS.GS.BLINKING_WHITE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GS.WHITE.STATUS"),
      icon: ICONS.SOLID_WHITE,
      children: I18n.t("LEDS.GS.WHITE.COLOR"),
      pagerText: I18n.t("LEDS.GS.WHITE.DESCRIPTION"),
    },
    {
      status: I18n.t("LEDS.GS.ORANGE.STATUS"),
      icon: ICONS.SOLID_ORANGE,
      children: I18n.t("LEDS.GS.ORANGE.COLOR"),
      pagerText: I18n.t("LEDS.GS.ORANGE.DESCRIPTION"),
    },
  ],
};

type LEDRowProps = {
  status: string;
  icon: {
    name: "solidLED" | "blinkingLED";
    color?: string;
    hasGradient?: boolean;
    renderGradient?: (prop: { id: string }) => void;
  };
  children: string;
  onPress: () => void;
  isSelected: boolean;
};

const LEDRow = ({ children, icon, status, onPress, isSelected }: LEDRowProps) => {
  const { theme } = useTheme();
  return (
    <ListRow
      value={status}
      icon={
        <MerakiIcon
          name={icon.name}
          color={icon.color}
          hasGradient={icon.hasGradient}
          // @ts-expect-error TS(2322) FIXME: Type '(() => void) | undefined' is not assignable ... Remove this comment to see the full error message
          renderGradient={icon.renderGradient}
          size="s"
          containerStyle={styles.icon}
        />
      }
      leftStyle={styles.ledRowLeft}
      rightStyle={styles.ledRowRight}
      rowStyles={isSelected ? [styles.ledRow, themeColors(theme).selectedColor] : styles.ledRow}
      onPress={onPress}
    >
      {children}
    </ListRow>
  );
};

type ReduxProps = {
  pages: Page[];
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "LEDColorLegend"> &
  ReduxProps &
  BasicActions;

type LEDColorLegendComponentState = {
  selectedRowIndex: number;
  selectedPageIndex: number;
};

export class LEDColorLegendComponent extends PureComponent<Props, LEDColorLegendComponentState> {
  private viewPager: ViewPager | null = null;

  constructor(props: Props) {
    super(props);

    const { model, pages } = props;

    this.state = {
      selectedRowIndex: 0,
      selectedPageIndex: this.getInitialSelectedIndex(model, pages),
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  getInitialSelectedIndex(model?: string, pages?: Page[]) {
    let pageIndex = 0;

    if (model && pages) {
      const productType = getProductType(model);
      pageIndex = pages.findIndex((page) => page.model === productType);
    }

    if (pageIndex === -1) {
      pageIndex = 0;
    }
    return pageIndex;
  }

  onPressPage = (offset = 1) => {
    const { selectedPageIndex } = this.state;
    const { pages } = this.props;
    this.viewPager?.setPage(0);
    this.setState({
      selectedPageIndex:
        (((selectedPageIndex + offset) % pages.length) + pages.length) % pages.length,
      selectedRowIndex: 0,
    });
  };

  onPressNextPage = () => {
    this.onPressPage(1);
  };

  onPressPrevPage = () => {
    this.onPressPage(-1);
  };

  // TODO: extract this into a separate component to be shared with HomeScreen
  renderHeading = () => {
    const { selectedPageIndex } = this.state;
    const { pages } = this.props;

    if (pages.length > 1) {
      // cycle through all pages
      const hitSlop = { top: 10, bottom: 10, left: 20, right: 20 };
      return (
        <View style={styles.devicePicker}>
          <MerakiIcon
            name="chevron-left"
            color={MkiColors.chevronColor}
            size="s"
            onPress={this.onPressPrevPage}
            hitSlop={hitSlop}
          />
          <View style={styles.deviceName}>
            <MkiText textStyle="heading" numberOfLines={1} ellipsizeMode="tail">
              {pages[selectedPageIndex].name}
            </MkiText>
          </View>
          <MerakiIcon
            name="chevron-right"
            color={MkiColors.chevronColor}
            size="s"
            onPress={this.onPressNextPage}
            hitSlop={hitSlop}
          />
        </View>
      );
    } else {
      return null;
    }
  };

  render() {
    const { selectedPageIndex, selectedRowIndex } = this.state;
    const { pages } = this.props;
    const { theme } = useTheme.getState();
    if (pages?.[selectedPageIndex] == null) {
      return (
        <FullScreenContainerView defaultMargins>
          <MkiText>{I18n.t("LEDS.NO_HARDWARE")}</MkiText>
        </FullScreenContainerView>
      );
    }

    const led_rows = pages[selectedPageIndex].rows;

    // TODO: get copy text for all possible states
    // TODO: display highlighted state and currently selected state
    const pagerViews = led_rows.map(({ pagerText }: Page["rows"][number], index: number) => (
      // this index doesn't change, so it's ok to use
      <View style={styles.pageStyle} key={index}>
        <MkiText screenStyles={styles.descriptionText}>{pagerText}</MkiText>
      </View>
    ));

    const rows = led_rows.map((row: Page["rows"][number], index: number) => ({
      ...row,

      onPress: () => {
        this.viewPager?.setPage(index);
        this.setState({ selectedRowIndex: index });
      },

      isSelected: index === selectedRowIndex,
      theme,
    }));
    return (
      <FullScreenContainerView testID="LED_COLOR_LEGEND_SCREEN">
        {this.renderHeading()}
        <IndicatorViewPager
          // @ts-expect-error TS(2339) FIXME: Property 'position' does not exist on type 'PagerV... Remove this comment to see the full error message
          onPageSelected={(e) => this.setState({ selectedRowIndex: e.position })}
          style={styles.pagerStyle}
          ref={(item) => (this.viewPager = item)}
        >
          {pagerViews}
        </IndicatorViewPager>
        <ScrollView>
          <SummaryList<LEDRowProps>
            contentRows={rows}
            customRenderRow={LEDRow}
            disableBottomBorder
          />
        </ScrollView>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  descriptionText: {
    textAlign: "center",
  },
  icon: {
    marginRight: 20,
  },
  pagerStyle: {
    height: "100%",
  },
  pageStyle: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: SPACING.extraLarge,
  },
  ledRow: {
    paddingHorizontal: SPACING.default,
  },
  ledRowLeft: {
    flex: 1,
  },
  ledRowRight: {
    flex: 1,
  },
  devicePicker: {
    marginVertical: SPACING.default,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  deviceName: {
    width: "65%",
    alignItems: "center",
  },
});

const resolvePages = (
  scannedHardwareMap: OnboardingScannedDevices,
  hasGR: boolean,
  hasGS: boolean,
  hasGX: boolean,
  isOnboarding?: boolean,
) => {
  // figure out the device legend pages to show based on whether we are showing scanned or existing devices, and
  // what devices were detected under each case.
  const pages: Page[] = [];
  if (isOnboarding) {
    if (scannedHardwareMap.wireless) {
      pages.push(WIRELESS_PAGE);
    }
    if (scannedHardwareMap.switch) {
      pages.push(SWITCH_PAGE);
    }
    if (scannedHardwareMap.appliance) {
      pages.push(SECURITY_PAGE);
    }
  } else {
    if (hasGR) {
      pages.push(WIRELESS_PAGE);
    }
    if (hasGX) {
      pages.push(SECURITY_PAGE);
    }
    if (hasGS) {
      pages.push(SWITCH_PAGE);
    }
  }
  return pages;
};

function mapStateToProps(
  state: RootState,
  props: OnboardingStackProps["LEDColorLegend"],
): ReduxProps {
  const { onboarding } = props;
  const scanned = getPluginHardwareMap(onboardingDevices(state));
  const hasGR = hasGRDevices(state);
  const hasGS = hasGSDevices(state);
  const hasGX = hasGXDevices(state);

  return {
    pages: resolvePages(scanned, hasGR, hasGS, hasGX, onboarding),
  };
}

export default connect(mapStateToProps)(LEDColorLegendComponent);
