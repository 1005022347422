import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Positive({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
        fill={theme.color.positive.icon.base}
      />
      <Path
        d="M11.138 6.195c.26.26.26.683 0 .943l-3.499 3.5a.667.667 0 0 1-.942 0l-1.502-1.5a.667.667 0 1 1 .943-.943l1.03 1.029 3.027-3.029c.26-.26.683-.26.943 0Z"
        fill={theme.color.positive.iconIn.base}
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  );
}
