import { I18n } from "@meraki/core/i18n";
import { useAssociation } from "@meraki/react-live-broker";
import { StyleSheet, useWindowDimensions, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import SingleBarGraph from "~/shared/components/SingleBarGraph";

interface ClientSignalStrengthProps {
  networkId: string;
  clientMac: string;
}

const CONN_GRAPH_WINDOW_PERCENTAGE = 0.6;
const ClientSignalStrength = ({ networkId, clientMac }: ClientSignalStrengthProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const assocResults = useAssociation(networkId, clientMac);
  if (!assocResults) {
    return <MkiSpinner size="small" />;
  }

  const { associated, channel, rssi, associationBand } = assocResults;

  // Following dashboard
  const percentage = associated ? Math.min(rssi / 40, 1) : 0;

  // SPACING.default for the outside padding, SPACING.small for the paddingLeft
  // of the right side of ListRow, and 60% represents the width of the right side
  const width =
    (windowWidth - SPACING.default * 2) * CONN_GRAPH_WINDOW_PERCENTAGE - SPACING.default;

  return (
    <View testID="SIGNAL_STRENGTH">
      <SingleBarGraph
        data={[{ percentage }]}
        graphStyles={[styles.graphStyle, { width }]}
        roundCorners
      />
      {associated ? (
        <MkiText textStyle="label" screenStyles={styles.signalStyle}>
          {I18n.t("CLIENT_DETAILS.SIGNAL_STRENGTH_DETAILS", {
            signal: rssi,
            channel: channel,
            band: associationBand,
          })}
        </MkiText>
      ) : (
        <MkiText textStyle="label" screenStyles={styles.signalStyle} uppercase>
          {I18n.t("CLIENT_DETAILS.NOT_CONNECTED")}
        </MkiText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  graphStyle: {
    height: SPACING.medium,
  },
  signalStyle: {
    paddingLeft: SPACING.tiny,
    paddingTop: SPACING.small,
  },
});

export default ClientSignalStrength;
