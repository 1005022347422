import { I18n } from "@meraki/core/i18n";
import { topologyUrl } from "@meraki/go/links";
import { getProductType } from "@meraki/shared/devices";
import { PureComponent } from "react";
import { LayoutAnimation, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import SummaryList from "~/shared/components/SummaryList";
import { CloseButton } from "~/shared/navigation/Buttons";

import { HardwareStackPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "Troubleshooting">;

interface TroubleshootingScreenState {
  isAlertVisible: boolean;
}

export class Troubleshooting extends PureComponent<Props, TroubleshootingScreenState> {
  constructor(props: Props) {
    super(props);

    setupAndroidLayoutAnimation();
    this.state = { isAlertVisible: true };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, presentedModally } = this.props;

    if (presentedModally) {
      navigation.setOptions({
        headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      });
    }
  }

  showLedLegend = () => {
    const { navigation, device } = this.props;
    navigation.navigate("LEDColorLegend", {
      model: device.model,
    });
  };

  showPlugin = () => {
    const { navigation, device } = this.props;

    const productType = getProductType(device.model);
    if (productType) {
      navigation.navigate("PlugIn", {
        isOnboarding: false,
        productType,
      });
    }
  };

  showContactSupport = () => {
    const { navigation } = this.props;

    navigation.navigate("SearchSubject");
  };

  closeAlertCard = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ isAlertVisible: false });
  };

  rows = [
    {
      label: I18n.t("LEDS.DISCLOSURE_ROW_LABEL"),
      onPress: this.showLedLegend,
      testID: "LED_LEGEND",
    },
    {
      label: I18n.t("TROUBLESHOOTING_SCREEN.PLUG_IN_INSTRUCTIONS"),
      onPress: this.showPlugin,
      testID: "PLUG_IN_INSTRUCTIONS",
    },
    {
      label: I18n.t("TROUBLESHOOTING_SCREEN.TOPOLOGY"),
      onPress: topologyUrl,
      testID: "TOPOLOGY",
    },
  ];

  render() {
    const { isAlertVisible } = this.state;
    return (
      <FullScreenContainerView withScroll testID="TROUBLESHOOTING_SCREEN">
        <SummaryList contentRows={this.rows} hasSeparators disableBottomBorder />
        <InlineAlert
          screenStyles={styles.alert}
          visible={isAlertVisible}
          onExit={this.closeAlertCard}
          alertTitle={I18n.t("TROUBLESHOOTING_SCREEN.ALERT_TITLE")}
          alertMessage={I18n.t("TROUBLESHOOTING_SCREEN.ALERT_MESSAGE")}
          primaryButtonText={I18n.t("CONTACT_SUPPORT")}
          onPrimaryPress={this.showContactSupport}
        />
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  alert: {
    marginVertical: SPACING.default,
  },
});

export default Troubleshooting;
