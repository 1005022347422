import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useClients, useDevicesByNetwork, useUmbrellaActivity } from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { compareDesc } from "date-fns";

import { useUmbrellaAuthTokenStore } from "./NetworkMonitorScreen";

export function SecurityEventsListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();

  const timespan = useGlobalTimespan();
  const { accessToken } = useUmbrellaAuthTokenStore();

  const { data: activity = { data: [] } } = useUmbrellaActivity({
    timespan,
    accessToken,
  });

  const networkId = useCurrentNetworkId();
  const { data: clients = [] } = useClients({ networkId, timespan });
  const { data: devices = [] } = useDevicesByNetwork({ networkId });

  return (
    <Screen>
      <List.FlashList
        data={activity.data}
        getItemData={(activity) => {
          const time = formatDate(activity.timestamp, {
            dateFormat: "shortDate",
            timeFormat: "shortTime",
            unixTimestamp: false,
          });
          const client = clients.find((client) => client.hashedIp4 === activity.internalip);
          const device = devices.find((device) => device.hashedIp4 === activity.internalip);
          let name = activity.internalip;
          if (device) {
            name = device.name || device.serial || "";
          } else if (client) {
            name = client.description || client.mac;
          }
          return {
            title: I18n.t("SECURITY_EVENTS_ROW.SITE_LABEL", {
              activityType: activity.policycategories[0]?.label,
              domain: activity.domain,
            }),
            description: I18n.t("SECURITY_EVENTS_ROW.SUBTITLE", {
              clientName: name,
              time: time,
            }),
            onPress: () =>
              navigation.navigate("SecurityEvent", { securityEvent: activity, device, client }),
          };
        }}
        groupBy={(event) =>
          formatDate(event.timestamp, { dateFormat: "longDate", unixTimestamp: false })
        }
        sortGroupBy={(event1, event2) => {
          return compareDesc(
            new Date(event1.data[0]?.timestamp ?? ""),
            new Date(event2.data[0]?.timestamp ?? ""),
          );
        }}
      />
    </Screen>
  );
}
