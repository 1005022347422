import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import PingResultStats from "~/go/components/liveTools/ping/PingResultStats";
import ConnectingAnimation from "~/go/components/onboarding/ConnectingAnimation";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { normalizedFontSize } from "~/lib/themeHelper";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";

type Props = {
  averageLatency?: number;
  lossRate?: number;
  onStopPress: () => void;
  pings?: number[];
  title?: string;
};

class PingInProgressStats extends PureComponent<Props> {
  shouldRenderStats() {
    const { pings = [], lossRate, averageLatency } = this.props;
    if (pings.length === 0 || averageLatency === undefined || lossRate === undefined) {
      return false;
    }
    return true;
  }

  renderStats() {
    if (!this.shouldRenderStats()) {
      return this.renderLoading();
    }

    const { lossRate, averageLatency, onStopPress } = this.props;

    return (
      <View>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ averageLatency: number | undefined; lossRa... Remove this comment to see the full error message */}
        <PingResultStats hideTitle {...{ averageLatency, lossRate }} />
        <ConnectingAnimation />
        <View style={styles.buttonContainer}>
          <RoundedButton
            buttonType="destructive"
            onPress={onStopPress}
            screenStyles={styles.buttonStyle}
          >
            {I18n.t("STOP")}
          </RoundedButton>
        </View>
      </View>
    );
  }

  renderLoading = () => (
    <View style={styles.loadingContainer}>
      <MkiSpinner />
    </View>
  );

  renderText() {
    const { title } = this.props;
    return (
      <MkiText screenStyles={[styles.testing, styles.centerText]} textStyle="default">
        {title}
      </MkiText>
    );
  }

  render() {
    return (
      <ScrollView>
        {this.renderText()}
        {this.renderStats()}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.large,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  centerText: {
    alignSelf: "center",
    textAlign: "center",
    paddingHorizontal: SPACING.extraLarge,
    paddingBottom: SPACING.small,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
  testing: {
    fontSize: normalizedFontSize(24),
  },
});

export default PingInProgressStats;
