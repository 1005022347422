import { createSelector } from "reselect";

import { celsiusToFahrenheit, fahrenheitToCelsius } from "~/lib/sensor/SensorReadingUtils";
import { getSensorAlertProfilesState, getTemperatureUnit } from "~/selectors/getters";
import { getSensorDeviceForSerial } from "~/selectors/sensors";
import { SensorMetrics, TemperatureUnits } from "~/shared/constants/SensorMetrics";
import { RootState } from "~/shared/types/Redux";
import { AlertProfile, AlertProfileCondition } from "~/shared/types/SensorAlertProfile";

const empty: any = [];

export const selectAlertProfilesForSensor = createSelector(
  getSensorDeviceForSerial,
  getSensorAlertProfilesState,
  (sensor, sensorAlertProfilesState): AlertProfile[] => {
    return (
      sensor.alertProfileIds
        ?.map((alertProfileId: string) => {
          return sensorAlertProfilesState.alertProfiles?.[alertProfileId];
        })
        .filter(
          (maybeAlertProfile: AlertProfile | undefined): maybeAlertProfile is AlertProfile => {
            return maybeAlertProfile !== undefined;
          },
        ) ?? empty
    );
  },
);

export const selectAlertProfileConditions = createSelector(
  selectAlertProfilesForSensor,
  (_p1, _p2, conditionType) => conditionType,
  (alertProfilesForSensor, conditionType): AlertProfileCondition[] => {
    return alertProfilesForSensor.reduce((conditions: AlertProfileCondition[], alertProfile) => {
      const conditionsForType = alertProfile?.conditions?.filter(
        (condition) => condition.type === conditionType || condition.type?.includes(conditionType),
      );
      if (Array.isArray(conditionsForType) && conditionsForType.length > 0) {
        conditions.push(...conditionsForType);
      }
      return conditions;
    }, []);
  },
);

export const selectTemperatureAlertProfileConditions = createSelector(
  (state: RootState, serial: string) =>
    selectAlertProfileConditions(state, serial, SensorMetrics.temperature),
  getTemperatureUnit,
  (conditions, temperatureUnit) => {
    return conditions.map((condition) => {
      if (
        condition.unit === TemperatureUnits.celsius &&
        temperatureUnit === TemperatureUnits.fahrenheit
      ) {
        return { ...condition, threshold: celsiusToFahrenheit(condition.threshold) };
      }
      if (
        condition.unit === TemperatureUnits.fahrenheit &&
        temperatureUnit === TemperatureUnits.celsius
      ) {
        return { ...condition, threshold: fahrenheitToCelsius(condition.threshold) };
      }
      return condition;
    });
  },
);
