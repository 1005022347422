import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

const ERROR_MESSAGES = {
  network:
    "Uh oh! Looks like something went wrong when trying to talk to our servers. Try refreshing.",
};

const BUTTONS = {
  refresh: "Refresh",
};

type Props = {
  children?: string;
  onPress: () => void;
  button?: string;
};

const NoData = ({ onPress, children, button }: Props) => (
  <View style={styles.noData}>
    <MkiText textStyle="subheading" screenStyles={{ margin: SPACING.large }}>
      {children}
    </MkiText>
    <Touchable onPress={onPress} transparentBackground>
      <View>
        <MkiText textStyle="heading" screenStyles={{ color: MkiColors.primaryButton }}>
          {button}
        </MkiText>
      </View>
    </Touchable>
  </View>
);

NoData.defaultProps = {
  children: ERROR_MESSAGES.network,
  button: BUTTONS.refresh,
};

const styles = StyleSheet.create({
  noData: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NoData;
