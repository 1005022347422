import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Appliance({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path
        d="M12.0623 3L19.1418 5.06123C19.6625 5.21281 20 5.55874 20 5.94353V13.9683C20 17.425 14.8802 19.8879 12 21"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path
        d="M11.9377 3L4.85815 5.06123C4.33755 5.21281 4 5.55874 4 5.94353V13.9683C4 17.425 9.11981 19.8879 12 21"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <Path d="M12.25 7L12.25 16" stroke={color1} strokeWidth="1.5" strokeLinecap="round" />
    </Svg>
  );
}
