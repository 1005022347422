import { Fragment, PureComponent } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

type HorizontalDividerProps = {
  text?: string;
  withHorizontalPadding?: boolean;
};

export default class HorizontalDivider extends PureComponent<HorizontalDividerProps> {
  render() {
    const { text, withHorizontalPadding } = this.props;

    const style: StyleProp<ViewStyle> = [styles.dividerContainer];
    if (withHorizontalPadding) {
      style.push(styles.horizontalPadding);
    }

    return <View style={style}>{separatorView(text)}</View>;
  }
}

const separatorView = (text?: string) => {
  if (text) {
    return (
      <Fragment>
        <View style={styles.separatorView} />
        <View style={styles.textView}>
          <MkiText textStyle="small">{text}</MkiText>
        </View>
        <View style={styles.separatorView} />
      </Fragment>
    );
  }

  return <View style={styles.separatorView} />;
};

const styles = StyleSheet.create({
  dividerContainer: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: SPACING.small,
  },
  horizontalPadding: {
    paddingHorizontal: SPACING.default,
  },
  separatorView: {
    height: 1,
    flexGrow: 1,
    backgroundColor: MkiColors.borderColor,
  },
  textView: {
    marginHorizontal: SPACING.small,
  },
});
