/**
 * is_parent: This will add a right caret to the right side of the row.
 * is_selected: This will add a checkmark on the left side of the row if the item is selected.
 * is_selected_parent: This will allow a row to have both the right caret and be selected at
 * the same time.
 */

export const NESTED_TABLE_MODAL_ROWTYPE = {
  isParent: "IS_PARENT",
  isSelected: "SELECTED",
  isSelectedParent: "IS_SELECTED_PARENT",
};

export const ROW_SPACINGS = {
  listRow: {
    bottomPadding: 12,
  },
  accordian: {
    height: 56,
  },
};
