import z from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

interface TestAutomationRequest {
  networkId: string;
  automationId: string;
}

export const responseSchema = z.object({ success: z.boolean() });

function buildUrl({ networkId, automationId }: TestAutomationRequest) {
  return `/api/v1/networks/${networkId}/sensor/automations/${automationId}/test`;
}

export const postTestAutomation = (variables: TestAutomationRequest) => {
  return request(responseSchema, "POST", buildUrl(variables));
};

export const useTestAutomation = createMutation({
  baseMutationKey: buildUrl({ networkId: "{networkId}", automationId: "{automationId}" }),
  mutationFn: (options: TestAutomationRequest) => postTestAutomation(options),
});
