import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const IpSecVPNSchema = z
  .object({
    enabled: z.boolean(),
    hostname: z.string().optional(),
    sharedSecret: z.string().optional(),
    ipv4: z.object({
      subnet: z.string().nullable().optional(),
      nameservers: z
        .object({
          provider: z.string().nullable(),
          addresses: z.array(z.string()).optional(),
        })
        .optional(),
    }),
  })
  .describe("IpSecVPNSchema");

export type IpSecVPN = z.infer<typeof IpSecVPNSchema>;

type IpSecRequest = {
  networkId?: string;
};

type IpSecPostParams = IpSecRequest & {
  params: Partial<IpSecVPN>;
};

function buildUrl({ networkId }: { networkId?: string }) {
  return `/api/v1/networks/${networkId}/appliance/clientVpn/ipsec`;
}

function fetchIpSecVPN(variables: IpSecRequest): Promise<IpSecVPN> {
  if (!variables.networkId) {
    throw new Error("Undefined network id when fetching client VPN");
  }
  return request(IpSecVPNSchema, "GET", buildUrl(variables));
}

function updateIpSecVPN(variables: IpSecPostParams): Promise<IpSecVPN> {
  return request(IpSecVPNSchema, "PUT", buildUrl(variables), {
    body: JSON.stringify(variables.params),
  });
}

export const useIpSecVPN = createQuery<IpSecRequest, IpSecVPN>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchIpSecVPN(variables),
  requiredVariables: ["networkId"],
});

export const useUpdateIpSecVPN = createMutation<IpSecPostParams, IpSecVPN, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables: IpSecPostParams) => updateIpSecVPN(variables),
});
