import { ThemeName, useTheme } from "@meraki/core/theme";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import SummaryListHeading from "~/shared/components/SummaryListHeading";
import Table from "~/shared/components/Table";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

const SubHeading = ({ children }: { children: string }) => (
  <View style={styles.subheading}>
    <MkiText textStyle="small" screenStyles={styles.subheadingText}>
      {children}
    </MkiText>
  </View>
);

const renderRow = <T,>(rowData: T) => {
  // @ts-ignore these properties are not guaranteed to exist because every
  // single time we call renderRow we throw different things at it :(
  const { icon, subtitle, testID, onPress, label } = rowData;
  return (
    <SimpleDisclosureRow icon={icon} subtitle={subtitle} testID={testID} onPress={onPress}>
      {label}
    </SimpleDisclosureRow>
  );
};

const renderTable = <T,>(props: SummaryListProps<T>) => {
  const { contentRows, customRenderRow, disclosureRows, hasSeparators, heading } = props;

  // @ts-ignore disclosure rows and content rows are different and can't be concatenated
  // idk why we're doing this
  const allRows = contentRows.concat(disclosureRows);
  const renderFunc = (item: T, index: number) => {
    if (customRenderRow) {
      return customRenderRow(item, index);
    }
    return renderRow(item);
  };

  return (
    <Table<T>
      entries={allRows}
      renderRow={renderFunc}
      separators={hasSeparators}
      listStyles={styles.listStyles}
      topPadding={!!heading}
    />
  );
};

type SummaryListProps<T> = {
  heading?: string;
  subheading?: string;
  headingContainerStyles?: StyleProp<ViewStyle>;
  headingTextStyle?: StyleProp<TextStyle>;
  containerStyles?: StyleProp<ViewStyle>;
  contentRows: T[];
  customRenderRow?: (rowData: T, rowID: number) => void;
  disclosureRows?: {
    label?: string;
    subtitle?: string;
    isDisclosureRow?: boolean;
    hasSeparator?: boolean;
    theme?: ThemeName;
    onPress?: () => void;
  }[];
  loading?: boolean;
  disableBottomBorder?: boolean;
  disableHeadingTop?: boolean;
  disableContainerTop?: boolean;
  hasSeparators?: boolean;
  testID?: string;
};

export const SummaryList = <T,>(props: SummaryListProps<T>) => {
  const {
    containerStyles,
    disableBottomBorder,
    disableHeadingTop,
    disableContainerTop,
    heading,
    headingContainerStyles,
    headingTextStyle,
    loading,
    subheading,
    testID,
  } = props;

  const { theme } = useTheme();

  const headingView = heading ? (
    <SummaryListHeading
      headingContainerStyles={headingContainerStyles}
      headingTextStyle={headingTextStyle}
      subheading={subheading ? <SubHeading>{subheading}</SubHeading> : null}
      disableHeadingTop={disableHeadingTop}
    >
      {heading}
    </SummaryListHeading>
  ) : undefined;

  const renderBottomBorder = () =>
    disableBottomBorder ? null : (
      <View style={styles.bottomLineContainer}>
        <MkiRowSeparator />
      </View>
    );

  const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
  const containerStylesArray = [{ backgroundColor }, styles.container, containerStyles];

  if (!disableContainerTop) {
    containerStylesArray.push(styles.containerTopPadding);
  }

  return (
    <View style={containerStylesArray} testID={testID}>
      {headingView}
      {loading ? renderLoading() : renderTable(props)}
      {renderBottomBorder()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  containerTopPadding: {
    paddingTop: SPACING.small,
  },
  bottomLineContainer: {
    marginHorizontal: appSelect({
      enterprise: SPACING.default,
      go: 0,
    }),
    marginTop: appSelect({
      enterprise: 12,
      go: 0,
    }),
    height: 1,
  },
  subheading: {
    marginTop: SPACING.tiny,
  },
  subheadingText: {
    color: MkiColors.secondaryTextColor,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 24,
  },
  listStyles: {
    borderTopWidth: 0,
  },
});

SummaryList.defaultProps = {
  heading: undefined,
  subheading: undefined,
  headingContainerStyles: {},
  headingTextStyle: {},
  containerStyles: {},
  customRenderRow: undefined,
  disclosureRows: [],
  loading: false,
  disableBottomBorder: false,
  disableHeadingTop: false,
  disableContainerTop: false,
  hasSeparators: false,
};

export default SummaryList;
