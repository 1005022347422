import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const RadioSettingsSchema = z
  .object({
    serial: z.string(),
    rfProfileId: z.string().nullable(),
    twoFourGhzSettings: z.object({
      channel: z.number().nullable(),
      targetPower: z.number().nullable(),
    }),
    fiveGhzSettings: z.object({
      channel: z.number().nullable(),
      channelWidth: z.coerce.number().nullable(),
      targetPower: z.number().nullable(),
    }),
  })
  .describe("RadioSettingsSchema");

export type RadioSettings = z.infer<typeof RadioSettingsSchema>;

interface RadioSettingsRequest {
  serial?: string;
}

function buildUrl({ serial }: RadioSettingsRequest) {
  return `/api/v1/devices/${serial}/wireless/radio/settings`;
}

const fetchRadioSettings = ({ serial }: RadioSettingsRequest): Promise<RadioSettings> =>
  request(RadioSettingsSchema, "GET", buildUrl({ serial }));

export const useRadioSettings = createQuery<RadioSettingsRequest, RadioSettings>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchRadioSettings(variables),
  requiredVariables: ["serial"],
});
