import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Heading,
  List,
  Text,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useAllAppliancePorts,
  useSwitchPorts,
  useUpdateAppliancePorts,
  useUpdateSwitchPorts,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { removeSpaces, toList } from "@meraki/shared/formatters";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { Alert } from "react-native";

const ALLOWED_VLANS_REGEX = new RegExp(/^(\d+(?:-\d+)?)(?:,(\d+(?:-\d+)?))*$/);

type VLANConfigurationForm = {
  VLAN: string;
  voiceVLAN: string;
  allowedVLANs: string;
  typeAccess: boolean;
  typeTrunk: boolean;
};

export function VLANConfigurationScreen() {
  const route = useRoute<RouteProp<DeviceGroupProps, "VLANConfiguration">>();
  const { params: props } = route;
  const { appliancePortIds, switchPortIds, serial } = props;
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const updateSwitchPorts = useUpdateSwitchPorts();
  const updateAppliancePorts = useUpdateAppliancePorts();
  const portType = appliancePortIds ? "appliance" : switchPortIds ? "switch" : undefined;
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const { data: switchPorts } = useSwitchPorts(
    {
      serial,
    },
    {
      select(data) {
        return data.filter((port) => switchPortIds?.includes(port.portId));
      },
    },
  );

  const { data: appliancePorts } = useAllAppliancePorts(
    {
      networkId,
    },
    {
      select(data) {
        return data.filter((port) => appliancePortIds?.includes(port.number));
      },
    },
  );
  const initialType = appliancePorts?.[0]?.type ?? switchPorts?.[0]?.type;
  const initialVlan = (appliancePorts?.[0]?.vlan ?? switchPorts?.[0]?.vlan)?.toString();
  const initialVoiceVlan = (
    appliancePorts?.[0]?.voiceVlan ?? switchPorts?.[0]?.voiceVlan
  )?.toString();
  const initialAllowedVlans = (
    appliancePorts?.[0]?.allowedVlans ?? switchPorts?.[0]?.allowedVlans
  )?.toString();

  const methods = useForm<VLANConfigurationForm>({
    defaultValues: {
      VLAN: "",
      voiceVLAN: "",
      allowedVLANs: "",
      typeAccess: false,
      typeTrunk: false,
    },
    values: {
      VLAN: initialVlan ?? "",
      voiceVLAN: initialVoiceVlan ?? "",
      allowedVLANs: initialAllowedVlans ?? "",
      typeAccess: initialType === "access",
      typeTrunk: initialType === "trunk",
    },
  });

  const getPortNumbers = () => {
    const portNumbers: string[] = [];
    if (portType === "appliance") {
      appliancePorts?.forEach((port) => portNumbers.push(port.number.toString()));
    } else {
      switchPorts?.forEach((port) => portNumbers.push(port.portId));
    }
    return portNumbers;
  };

  if (
    !portType ||
    (portType === "appliance" && !appliancePorts?.[0]) ||
    (portType === "switch" && !switchPorts?.[0])
  ) {
    return null;
  }

  const onClose = () => {
    resetPress();
    bottomSheetRef.current?.dismiss();
  };
  const resetPress = () => {
    methods.reset();
  };
  const onSave = () => {
    if (portType === "appliance" && appliancePorts) {
      const updatedPorts = appliancePorts.map((port) => {
        return {
          ...port,
          type: methods.watch("typeAccess") ? ("access" as const) : ("trunk" as const),
          vlan: !isEmpty(methods.watch("VLAN")) ? Number(methods.watch("VLAN")) : null,
          allowedVlans: methods.watch("typeTrunk")
            ? removeSpaces(methods.watch("allowedVLANs"))
            : undefined,
          accessPolicy: methods.watch("typeAccess") ? "open" : undefined,
        };
      });
      updateAppliancePorts.mutate(
        {
          organizationId,
          networkId,
          appliancePorts: updatedPorts,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: useAllAppliancePorts.queryKeyRoot });
            bottomSheetRef.current?.dismiss();
          },
          onError: (error) => {
            Alert.alert(String(error));
          },
        },
      );
    } else if (portType === "switch" && switchPorts && serial) {
      const updatedPorts = switchPorts.map((port) => {
        return {
          ...port,
          type: methods.watch("typeAccess") ? ("access" as const) : ("trunk" as const),
          vlan: !isEmpty(methods.watch("VLAN")) ? Number(methods.watch("VLAN")) : null,
          voiceVlan: !isEmpty(methods.watch("voiceVLAN"))
            ? Number(methods.watch("voiceVLAN"))
            : null,
          allowedVlans: removeSpaces(methods.watch("allowedVLANs")),
        };
      });
      updateSwitchPorts.mutate(
        {
          organizationId,
          serial,
          switchPorts: updatedPorts,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: useSwitchPorts.queryKey({ serial }),
            });
            bottomSheetRef.current?.dismiss();
          },
          onError: (error) => {
            Alert.alert(String(error));
          },
        },
      );
    }
  };

  return (
    <Screen testID="VLAN_CONFIGURATION_SETTINGS" addDefaultPadding>
      <Box padding="none" paddingBottom="xs" bottomDividerBorder>
        <Heading size="h1">{I18n.t("VLAN_CONFIGURATION.TITLE")}</Heading>
      </Box>
      <Box padding="none">
        <Text size="p1">
          {I18n.t("VLAN_CONFIGURATION.DESCRIPTION", {
            portNumber: toList(getPortNumbers()),
          })}
        </Text>
      </Box>
      <Form {...methods}>
        <List>
          <List.Item
            title={I18n.t("VLAN_CONFIGURATION.ACCESS")}
            leftAccessory={<Icon name="Gear" />}
            rightAccessory={
              <Form.Toggle
                name="typeAccess"
                onValueChange={(value) => {
                  methods.setValue("typeAccess", value);
                  methods.setValue("typeTrunk", !value);
                  bottomSheetRef.current?.present();
                }}
                testID={`ACCESS_TOGGLE.${methods.watch("typeAccess") ? "SELECTED" : "UNSELECTED"}`}
              />
            }
          >
            {methods.watch("typeAccess") && (
              <List>
                <List.Item
                  title={I18n.t("VLAN_CONFIGURATION.LABEL_ACCESS", {
                    vlanNumber: initialVlan ?? I18n.t("VLAN_CONFIGURATION.NONE"),
                  })}
                  description={
                    switchPortIds &&
                    I18n.t("VLAN_CONFIGURATION.VOICE_VLAN", {
                      voiceVlanNumber: initialVoiceVlan ?? I18n.t("VLAN_CONFIGURATION.NONE"),
                    })
                  }
                  rightAccessory={
                    <Button
                      text={I18n.t("EDIT")}
                      kind="tertiary"
                      onPress={() => bottomSheetRef.current?.present()}
                      testID="ACCESS_LIST_EDIT_BUTTON"
                    />
                  }
                  testID="ACCESS_LIST"
                />
              </List>
            )}
          </List.Item>
        </List>
        <List>
          <List.Item
            title={I18n.t("VLAN_CONFIGURATION.TRUNK")}
            leftAccessory={<Icon name="Gear" />}
            rightAccessory={
              <Form.Toggle
                name="typeTrunk"
                onValueChange={(value) => {
                  methods.setValue("typeTrunk", value);
                  methods.setValue("typeAccess", !value);
                  bottomSheetRef.current?.present();
                }}
                testID={`TRUNK_TOGGLE.${methods.watch("typeTrunk") ? "SELECTED" : "UNSELECTED"}`}
              />
            }
          >
            {methods.watch("typeTrunk") && (
              <List>
                <List.Item
                  title={I18n.t("VLAN_CONFIGURATION.LABEL_TRUNK", {
                    vlanNumber: initialVlan ?? I18n.t("VLAN_CONFIGURATION.NONE"),
                  })}
                  description={I18n.t("VLAN_CONFIGURATION.ALLOWED_VLANS", {
                    allowedVlans: initialAllowedVlans ?? I18n.t("VLAN_CONFIGURATION.NONE"),
                  })}
                  rightAccessory={
                    <Button
                      text={I18n.t("EDIT")}
                      kind="tertiary"
                      onPress={() => bottomSheetRef.current?.present()}
                      testID="TRUNK_LIST_EDIT_BUTTON"
                    />
                  }
                  testID="TRUNK_LIST"
                />
              </List>
            )}
          </List.Item>
        </List>
        <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
          <BottomSheet.Header
            title={
              methods.watch("typeAccess")
                ? I18n.t("VLAN_CONFIGURATION.ACCESS")
                : I18n.t("VLAN_CONFIGURATION.TRUNK")
            }
            onResetPress={resetPress}
            resetLabel={I18n.t("RESET")}
            cancelLabel={I18n.t("CANCEL")}
            onCancelPress={onClose}
          />
          <BottomSheet.Content>
            <Box
              gap="sm"
              testID={`${methods.watch("typeAccess") ? "ACCESS" : "TRUNK"}_BOTTOM_SHEET_FORM`}
            >
              <Form.Input
                rules={{
                  required:
                    methods.watch("typeAccess") && I18n.t("VLAN_CONFIGURATION.VLAN_ERROR_MESSAGE"),
                  validate: (input: string) => {
                    if (methods.watch("typeAccess")) {
                      if (
                        !Number(input) ||
                        (Number(input) && (Number(input) < 1 || Number(input) > 4094))
                      ) {
                        return I18n.t("VLAN_CONFIGURATION.VLAN_ERROR_MESSAGE");
                      }
                    } else if (!Number(input) || Number(input) < 1 || Number(input) > 4094) {
                      return I18n.t("VLAN_CONFIGURATION.VLAN_ERROR_MESSAGE");
                    }
                    return undefined;
                  },
                }}
                name={"VLAN"}
                label={
                  methods.watch("typeAccess")
                    ? I18n.t("VLAN_CONFIGURATION.VLAN_LABEL_ACCESS")
                    : I18n.t("VLAN_CONFIGURATION.VLAN_LABEL_TRUNK")
                }
                additionalContext={
                  methods.watch("typeAccess")
                    ? I18n.t("VLAN_CONFIGURATION.VLAN_HELPER_ACCESS")
                    : I18n.t("VLAN_CONFIGURATION.VLAN_HELPER_TRUNK")
                }
                showClear={false}
                autoCapitalize="none"
                testID="VLAN_INPUT"
              />
              {methods.watch("typeAccess") && portType === "switch" && (
                <Form.Input
                  rules={{
                    validate: (input: string) => {
                      if (input.length > 0 && !Number(input)) {
                        return I18n.t("VLAN_CONFIGURATION.VOICE_VLAN_ERROR_MESSAGE");
                      }
                      return undefined;
                    },
                  }}
                  name={"voiceVLAN"}
                  additionalContext={I18n.t("VLAN_CONFIGURATION.VOICE_VLAN_HELPER")}
                  label={I18n.t("VLAN_CONFIGURATION.VOICE_VLAN_LABEL")}
                  showClear={false}
                  autoCapitalize="none"
                  testID="VOICE_VLAN_INPUT"
                />
              )}
              {methods.watch("typeTrunk") && (
                <Form.Input
                  rules={{
                    validate: (input: string) => {
                      if (
                        !(
                          Number(input) ||
                          input.toLowerCase() === "all" ||
                          ALLOWED_VLANS_REGEX.test(removeSpaces(input))
                        )
                      ) {
                        return I18n.t("VLAN_CONFIGURATION.ALLOWED_VLAN_ERROR_MESSAGE");
                      }
                      return undefined;
                    },
                  }}
                  name={"allowedVLANs"}
                  additionalContext={I18n.t("VLAN_CONFIGURATION.ALLOWED_VLANS_HELPER")}
                  label={I18n.t("VLAN_CONFIGURATION.ALLOWED_VLANS_LABEL")}
                  showClear={false}
                  autoCapitalize="none"
                  testID="ALLOWED_VLANS_INPUT"
                />
              )}

              <Button
                text={I18n.t("SAVE")}
                onPress={methods.handleSubmit(onSave)}
                disabled={!methods.formState.isDirty}
                testID="SAVE_BUTTON"
              />
            </Box>
          </BottomSheet.Content>
        </BottomSheet.Modal>
      </Form>
    </Screen>
  );
}
