import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";

import { useDeviceEvents } from "~/api/queries/devices/useDeviceEvents";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import SummaryCard from "~/shared/components/SummaryCard";
import GeneralStatus from "~/shared/constants/Status";
import { ProductType } from "~/shared/types/Networks";

interface NetworkEventsCountProps {
  numberOfEvents: number;
}

const NetworkEventsCount = ({ numberOfEvents }: NetworkEventsCountProps) => {
  const navigation = useNavigation();

  const pushNetworkEventListScreen = () => navigation.navigate("NetworkEventsList");
  return (
    <View>
      <View style={styles.statusBoxes}>
        <QuickStatusBox
          value={numberOfEvents}
          subText={I18n.t("NETWORK_EVENTS_CARD.NUMBER")}
          status={GeneralStatus.good}
          onPress={pushNetworkEventListScreen}
        />
      </View>
    </View>
  );
};

export function NetworkEventsCard() {
  const networkId = useCurrentNetworkId();
  const networkEvents = useDeviceEvents({
    networkId,
    productType: ProductType.appliance,
    perPage: 1000,
  });
  const events = networkEvents.data?.pages.flatMap((page) => page.events) ?? [];

  return (
    <SummaryCard
      heading={I18n.t("NETWORK_EVENTS_CARD.HEADING")}
      subheading={I18n.t("NETWORK_EVENTS_CARD.SUBHEADING")}
      loading={networkEvents.isLoading}
    >
      <NetworkEventsCount numberOfEvents={events.length} />
    </SummaryCard>
  );
}

const styles = StyleSheet.create({
  statusBoxes: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default NetworkEventsCard;
