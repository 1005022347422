import { I18n } from "@meraki/core/i18n";
import { useUpdateVlan, useVlan, useVlans } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { DNSServers } from "~/go/types/NetworksTypes";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { getDNSSettings } from "~/lib/ApplianceUtils";
import { addNewlinesWherePeriodCommaDetected } from "~/lib/formatHelper";
import { selectedVlanId } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";

export const DNSServersScreen = () => {
  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);

  const { data: gxVlan } = useVlan({ networkId, vlanId });
  const dnsSettings = getDNSSettings(gxVlan);

  const [dnsServer, setDnsServer] = useState(dnsSettings.dnsServer);
  const [primaryDNSServer, setPrimaryDNSServer] = useState(dnsSettings.primaryDNSServer);
  const [secondaryDNSServer, setSecondaryDNSServer] = useState(dnsSettings.secondaryDNSServer);
  const [reqPending, setReqPending] = useState(false);

  const updateVlan = useUpdateVlan();
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const isChanged = useCallback(() => {
    return isEqual(dnsSettings, {
      dnsServer,
      primaryDNSServer,
      secondaryDNSServer,
    });
  }, [dnsServer, dnsSettings, primaryDNSServer, secondaryDNSServer]);

  const save = useCallback(async () => {
    try {
      setReqPending(true);

      if (gxVlan) {
        const dnsNameservers =
          dnsServer === DNSServers.custom
            ? [primaryDNSServer, secondaryDNSServer].join("\n")
            : dnsServer;

        const updatedVlanData = {
          ...gxVlan,
          dnsNameservers,
        };

        await updateVlan.mutateAsync(
          { networkId, vlanId, vlan: updatedVlanData },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot });
              navigation.goBack();
            },
            onError: (error) => {
              showAlert(
                I18n.t("ERROR"),
                addNewlinesWherePeriodCommaDetected(error.errors?.join(",")),
              );
            },
          },
        );
      }
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [
    dnsServer,
    gxVlan,
    navigation,
    networkId,
    primaryDNSServer,
    queryClient,
    secondaryDNSServer,
    updateVlan,
    vlanId,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() =>
            isChanged() ? navigation.goBack() : showSaveWarning(save, navigation.goBack)
          }
        />
      ),
      headerRight: () => <SaveButton onPress={save} />,
    });
  }, [isChanged, navigation, save]);

  const options = [
    {
      label: I18n.t("DNS_SERVER.SERVICES.UPSTREAM.LABEL"),
      sublabel: I18n.t("DNS_SERVER.SERVICES.UPSTREAM.SUBLABEL"),
      value: DNSServers.upstream_dns,
    },
    {
      label: I18n.t("DNS_SERVER.SERVICES.GOOGLE_DNS.LABEL"),
      sublabel: I18n.t("DNS_SERVER.SERVICES.GOOGLE_DNS.SUBLABEL"),
      value: DNSServers.google_dns,
    },
    {
      label: I18n.t("DNS_SERVER.SERVICES.OPEN_DNS.LABEL"),
      sublabel: I18n.t("DNS_SERVER.SERVICES.OPEN_DNS.SUBLABEL"),
      value: DNSServers.opendns,
    },
    {
      label: I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.LABEL"),
      value: DNSServers.custom,
    },
  ];

  return (
    <FullScreenContainerView withScroll>
      <DefaultHeader
        title={I18n.t("DNS_SERVER.TITLE")}
        description={I18n.t("DNS_SERVER.MESSAGE")}
      />
      <RadioSelectionList
        onSelect={setDnsServer}
        options={options}
        selectedValue={dnsServer}
        screenStyles={styles.contentContainer}
        radioStyle={styles.radioStyle}
      />
      {dnsServer === DNSServers.custom && (
        <View style={styles.ipAddressContainer}>
          <MkiText textStyle="smallSecondary" screenStyles={styles.ipAddressInput}>
            {I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.PRIMARY")}
          </MkiText>
          <IPAddressTextInput
            cidrAddress={primaryDNSServer}
            onAddressChange={setPrimaryDNSServer}
            leaveEditableBlank={false}
          />
          <MkiText textStyle="smallSecondary" screenStyles={styles.ipAddressInput}>
            {I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.SECONDARY")}
          </MkiText>
          <IPAddressTextInput
            cidrAddress={secondaryDNSServer}
            onAddressChange={setSecondaryDNSServer}
            leaveEditableBlank={false}
          />
        </View>
      )}
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  radioStyle: {
    marginVertical: SPACING.default,
  },
  ipAddressInput: {
    marginTop: SPACING.small,
  },
  ipAddressContainer: {
    marginHorizontal: SPACING.extraExtraLarge,
  },
});

export default DNSServersScreen;
