import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import PublicApiNetwork from "~/api/models/Network";
import DefaultHeader from "~/go/components/DefaultHeader";
import { getOrgNetworks } from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CloseButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "NetworkChoose">;

function NetworkChooseScreen() {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const actions = useActions();

  const orgNetworks = useAppSelector(getOrgNetworks) ?? [];

  const renderRow = (network: any) => {
    const { name, id: networkId } = network;

    return (
      <SimpleDisclosureRow
        onPress={() => {
          actions.setCurrentNetwork(networkId);
        }}
        testID={`NETWORK.${name}`}
      >
        <MkiText>{name}</MkiText>
      </SimpleDisclosureRow>
    );
  };

  return (
    <>
      <DefaultHeader
        title={I18n.t("NETWORK_CHOOSE.HEADER.TITLE")}
        description={I18n.t("NETWORK_CHOOSE.HEADER.DESCRIPTION")}
      />
      <MkiTable<PublicApiNetwork> data={orgNetworks} renderRow={renderRow} />
    </>
  );
}

export default NetworkChooseScreen;
