import {
  ORG_NETWORKS_GET_SUCCESS,
  UPDATE_NETWORK_SUCCESS,
  UPDATE_TIME_ZONE_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import Network from "~/api/models/Network";

const initialState: Network[] = [];

const orgNetworks = (state = initialState, action: any) => {
  const { type, response, meta } = action;

  switch (type) {
    case ORG_NETWORKS_GET_SUCCESS: {
      return response;
    }
    case UPDATE_TIME_ZONE_SUCCESS: {
      const { networkId } = meta;
      const { timeZone } = response;

      return state.slice().map((n) => {
        if (n.id === networkId) {
          return {
            ...n,
            timeZone,
          };
        }

        return n;
      });
    }
    case UPDATE_NETWORK_SUCCESS: {
      const { response, meta } = action;
      const { networkId } = meta;
      return state.slice().map((n) => {
        if (n.id === networkId) {
          return {
            ...n,
            ...response,
          };
        }

        return n;
      });
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default orgNetworks;
