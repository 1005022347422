import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId }: SwitchQoSRulesRequest) => {
  return `/api/v1/networks/${networkId}/switch/qosRules`;
};

const buildRuleUrl = ({ networkId, ruleId }: SwitchQoSRuleRequest) => {
  return `/api/v1/networks/${networkId}/switch/qosRules/${ruleId}`;
};

interface SwitchQoSRulesRequest {
  networkId?: string;
}

interface SwitchQoSRuleRequest extends SwitchQoSRulesRequest {
  ruleId: string;
}

interface CreateSwitchQoSRuleRequest extends SwitchQoSRulesRequest {
  body: CreateSwitchQoSRule;
}

interface UpdateSwitchQoSRuleRequest extends SwitchQoSRuleRequest {
  body: UpdateSwitchQoSRule;
}

export const SwitchQoSRuleBodySchema = z.object({
  vlan: z.number().nullable().optional(),
  protocol: z.string(),
  srcPort: z.number().nullable().optional(),
  srcPortRange: z.string().nullable().optional(),
  dstPort: z.number().nullable().optional(),
  dstPortRange: z.string().nullable().optional(),
  dscp: z.number().nullable().optional(),
});

export const SwitchQoSRuleSchema = z.object({
  id: z.string(),
  vlan: z.number().nullable().optional(),
  protocol: z.string(),
  srcPort: z.number().nullable().optional(),
  srcPortRange: z.string().nullable().optional(),
  dstPort: z.number().nullable().optional(),
  dstPortRange: z.string().nullable().optional(),
  dscp: z.number().nullable().optional(),
});

export const CreateSwitchQoSRuleSchema = z.object({
  vlan: z.number().nullable().optional(),
  protocol: z.string(),
  srcPort: z.number().nullable().optional(),
  srcPortRange: z.string().optional().nullable(),
  dstPort: z.number().nullable().optional(),
  dstPortRange: z.string().optional().nullable(),
  dscp: z.number().nullable().optional(),
});

export const SwitchQoSRulesSchema = z.array(SwitchQoSRuleSchema);

export type SwitchQoSRules = z.infer<typeof SwitchQoSRulesSchema>;
export type SwitchQoSRule = z.infer<typeof SwitchQoSRuleSchema>;
export type SwitchQoSRuleBody = z.infer<typeof SwitchQoSRuleBodySchema>;
export type CreateSwitchQoSRule = z.infer<typeof CreateSwitchQoSRuleSchema>;
export type UpdateSwitchQoSRule = Partial<Omit<SwitchQoSRule, "id">>;

const fetchSwitchQoSRules = ({ networkId }: SwitchQoSRulesRequest) => {
  return request(SwitchQoSRulesSchema, "GET", buildUrl({ networkId }));
};

export const useSwitchQoSRules = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: SwitchQoSRulesRequest) => fetchSwitchQoSRules(parameters),
  requiredVariables: ["networkId"],
});

const fetchSwitchQoSRule = ({ networkId, ruleId }: SwitchQoSRuleRequest) => {
  return request(SwitchQoSRuleSchema, "GET", buildRuleUrl({ networkId, ruleId }));
};

export const useSwitchQoSRule = createQuery({
  baseQueryKey: buildRuleUrl({ networkId: `{networkId}`, ruleId: `{ruleId}` }),
  queryFn: (parameters: SwitchQoSRuleRequest) => fetchSwitchQoSRule(parameters),
});

const updateSwitchQoSRule = ({ networkId, ruleId, body }: UpdateSwitchQoSRuleRequest) => {
  return request(SwitchQoSRuleBodySchema, "PUT", buildRuleUrl({ networkId, ruleId }), {
    body: JSON.stringify(body),
  });
};

export const useUpdateSwitchQoSRule = createMutation<
  UpdateSwitchQoSRuleRequest,
  SwitchQoSRuleBody,
  APIResponseError
>({
  baseMutationKey: buildRuleUrl({ networkId: `{networkId}`, ruleId: `{ruleId}` }),
  mutationFn: (parameters: UpdateSwitchQoSRuleRequest) => updateSwitchQoSRule({ ...parameters }),
});

const createSwitchQoSRule = ({ networkId, body }: CreateSwitchQoSRuleRequest) => {
  return request(SwitchQoSRuleSchema, "POST", buildUrl({ networkId }), {
    body: JSON.stringify(body),
  });
};

export const useCreateSwitchQoSRule = createMutation<
  CreateSwitchQoSRuleRequest,
  SwitchQoSRuleBody,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters: CreateSwitchQoSRuleRequest) => createSwitchQoSRule({ ...parameters }),
});

const deleteSwitchQoSRule = ({ networkId, ruleId }: SwitchQoSRuleRequest) => {
  return request(z.null(), "DELETE", buildRuleUrl({ networkId, ruleId }));
};

export const useDeleteSwitchQoSRule = createMutation<SwitchQoSRuleRequest, null, APIResponseError>({
  baseMutationKey: buildRuleUrl({ networkId: `{networkId}`, ruleId: `{ruleId}` }),
  mutationFn: (parameters: SwitchQoSRuleRequest) => deleteSwitchQoSRule({ ...parameters }),
});
