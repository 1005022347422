import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import MkiText from "~/shared/components/MkiText";

export default function IPAddressInputRow({ title, ...rest }: any) {
  return (
    <View style={styles.rowContainer}>
      <MkiText textStyle="small" screenStyles={styles.defaultHeaderColor}>
        {title}
      </MkiText>
      <IPAddressTextInput {...rest} />
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.large,
  },
  defaultHeaderColor: {
    color: MkiColors.formFieldLabel,
  },
});
