import * as errorMonitor from "@meraki/core/errors";
import { createRef, PureComponent } from "react";
import { View } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { connect } from "react-redux";

import { SPACING } from "~/constants/MkiConstants";
import { isWeb } from "~/lib/PlatformUtils";
import { currentNetworkState } from "~/selectors";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { RootState } from "../types/Redux";

type ReduxProps = {
  networkId: string;
};

type WiFiQRCodeProps = {
  name: string;
  encryptionMode?: string;
  passphrase?: string;
  ssidNumber?: number;
};

type Props = ReduxProps & BasicActions & WiFiQRCodeProps;

export class WiFiQRCode extends PureComponent<Props> {
  svg = createRef<SVGAElement>();

  ssidQRString() {
    const { name, encryptionMode, passphrase } = this.props;
    const components = [`WIFI:S:${name}`];

    if (encryptionMode) {
      components.push(`;T:${encryptionMode.toUpperCase()}`);
    }

    if (passphrase) {
      components.push(`;P:${passphrase}`);
    }

    components.push(";;");
    return components.join("");
  }

  componentDidMount = () => {
    if (this.svg) {
      this.getDataURL();
    }
  };

  getDataURL = () => {
    if (this.svg) {
      if (isWeb()) {
        let ReactDOMServer = require("react-dom/server");
        if (ReactDOMServer.default) {
          ReactDOMServer = ReactDOMServer.default;
        }

        //@ts-ignore
        const svgHTML = ReactDOMServer?.renderToStaticMarkup(this.svg.render());
        this.callback(svgHTML);
        //@ts-ignore
      } else if (this.svg.toDataURL) {
        //@ts-ignore
        this.svg.toDataURL(this.callback);
      }
    }
  };

  callback = (dataURL: string) => {
    const { networkId, ssidNumber, actions } = this.props;
    actions.setQRCode(networkId, ssidNumber, dataURL);
  };

  render() {
    return (
      <View pointerEvents="none">
        <QRCode
          value={this.ssidQRString()}
          size={200}
          color="black"
          backgroundColor="white"
          getRef={(c) => (this.svg = c)}
          quietZone={SPACING.tiny}
        />
      </View>
    );
  }
}

const mapStateToProps = () => {
  return (state: RootState): ReduxProps => ({
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for WiFiQRCode",
      currentNetworkState(state),
    ),
  });
};

export default connect(mapStateToProps, basicMapDispatchToProps)(WiFiQRCode);
