import { initializeApp } from "firebase/app";

require("@firebase/analytics");
require("@firebase/remote-config");
require("@firebase/performance");

const webApp = "https://web.meraki-go.com";

let firebaseConfig = {
  apiKey: "AIzaSyDz5HPqgdwIGY8ywMBWsztteeTIk2yrH78",
  authDomain: "go-web-app-8a768.firebaseapp.com",
  projectId: "go-web-app-8a768",
  storageBucket: "go-web-app-8a768.appspot.com",
  messagingSenderId: "788527691217",
  appId: "1:788527691217:web:21ee8b8f68e204a580dcf0",
  measurementId: "G-8Z8XSWWCPQ",
};

// send prod analytics to prod channel
if (window.location.origin === webApp) {
  firebaseConfig = {
    ...firebaseConfig,
    appId: "1:788527691217:web:ce0f9de09ba9133a80dcf0",
    measurementId: "G-YD5RDLTTYZ",
  };
}

const firebase = initializeApp(firebaseConfig);

export { firebase };
