import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import ClientDetailsScreen from "~/go/screens/ClientDetailsScreen";
import ClientsListScreen from "~/go/screens/ClientListScreen";

import BlockClientScreen from "../screens/BlockClientScreen";
import { ClientScreensPropMap } from "./Types";

const WrappedClientsListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<ClientScreensPropMap, "ClientList">>()(
    ClientsListScreen,
  );
const ForwardedClientsListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<ClientScreensPropMap, "ClientListModal">
  >()(ClientsListScreen);
const WrappedClientDetailsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<ClientScreensPropMap, "ClientDetails">>()(
    ClientDetailsScreen,
  );
const ForwardedBlockClientScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<ClientScreensPropMap, "BlockClient">>()(
    BlockClientScreen,
  );

export function clientScreens<T extends ClientScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen name="ClientList" component={WrappedClientsListScreen} />
      <Stack.Screen
        name="ClientListModal"
        component={ForwardedClientsListScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="ClientDetails"
        component={WrappedClientDetailsScreen}
        options={{ headerTitle: "" }}
      />
      <Stack.Screen
        name="BlockClient"
        component={ForwardedBlockClientScreen}
        options={{ presentation: "fullScreenModal" }}
      />
    </>
  );
}
