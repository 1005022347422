import { EmptyState, List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useCameraDevices } from "react-native-vision-camera";

export function DebugCamera() {
  const cameras = useCameraDevices();

  return (
    <Screen addDefaultPadding>
      {cameras.length === 0 && <EmptyState title="No cameras found" />}
      {cameras.map((camera, index) => (
        <List label={camera.name ?? `Unnamed Camera ${index}`}>
          <List.Item title="ID" value={camera.id} />
          <List.Item title="Phsical Devices" value={camera.physicalDevices.join(", ")} />
          <List.Item title="Position" value={camera.position} />
          <List.Item title="Has Flash" value={camera.hasFlash ? "Yes" : "No"} />
          <List.Item title="Has Torch" value={camera.hasTorch ? "Yes" : "No"} />
          <List.Item title="Is Multi Cam" value={camera.isMultiCam ? "Yes" : "No"} />
          <List.Item title="Minimum Zoom" value={camera.minZoom} />
          <List.Item title="Maximum Zoom" value={camera.maxZoom} />
          <List.Item title="Neutral Zoom" value={camera.neutralZoom} />
          <List.Item title="Formats (Count)" value={camera.formats.length} />
          <List.Item
            title="Supports Low Light Boost"
            value={camera.supportsLowLightBoost ? "Yes" : "No"}
          />
          <List.Item
            title="Supports Raw Capture"
            value={camera.supportsRawCapture ? "Yes" : "No"}
          />
          <List.Item title="Supports Focus" value={camera.supportsFocus ? "Yes" : "No"} />
          <List.Item title="Hardware Level" value={camera.hardwareLevel} />
          <List.Item title="Sensor Orientation" value={camera.sensorOrientation} />
        </List>
      ))}
    </Screen>
  );
}
