import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const UserProfileSettingsSchema = z
  .object({
    temperature_unit: z.literal("fahrenheit").or(z.literal("celsius")),
  })
  .describe("UserProfileSettingsSchema");
type UserProfileSettings = z.infer<typeof UserProfileSettingsSchema>;

interface UserProfileSettingsRequest {
  networkEid: string;
}

function buildUrl({ networkEid }: UserProfileSettingsRequest) {
  return `/n/${networkEid}/manage/users/user_profile_settings`;
}

export function fetchUserProfileSettings(
  variables: UserProfileSettingsRequest,
): Promise<UserProfileSettings> {
  return request(UserProfileSettingsSchema, "GET", buildUrl(variables));
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useUserProfileSettings = createQuery<UserProfileSettingsRequest, UserProfileSettings>({
  baseQueryKey: buildUrl({ networkEid: "{networkEid}" }),
  queryFn: (variables) => {
    return fetchUserProfileSettings(variables);
  },
});
