import z from "zod";

export const PortVlanTypes = {
  access: "access",
  trunk: "trunk",
} as const;

export const PortVlanTypesSchema = z.union([
  z.literal(PortVlanTypes.access),
  z.literal(PortVlanTypes.trunk),
]);

export const PortVlanSchema = z
  .object({
    type: PortVlanTypesSchema,
    vlan: z.number().default(1).nullable(),
    allowedVlans: z.string(),
    voiceVlan: z.number().nullable(),
  })
  .describe("PortVlanSchema");

export type PortVlan = z.infer<typeof PortVlanSchema>;
