import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { RootState } from "../reducers";

const getNavigation = (state: RootState) => state?.navigation;

export const getBottomTabConfig = createSelector(
  getNavigation,
  (navigation) => navigation.bottomTabBarConfig,
);

export const useBottomTabConfig = () => useSelector(getBottomTabConfig);
