import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";
import Dark from "~/enterprise/themes/Dark";
import ThemeInterface from "~/shared/themes/ThemeInterface";

// empty {} to take component defined styles
// This file can contain raw values for now
const DarkDeuteranomaly: ThemeInterface = {
  ...Dark,
  status: {
    ...Dark.status,
    online: {
      color: EnterpriseColors.greenDtmaly,
      background: EnterpriseColors.lightGreenContastDtmaly,
    },
  },
  graphColors: {
    ...Dark.graphColors,
    goodBar: EnterpriseColors.greenDtmaly,
    online: EnterpriseColors.greenDtmaly,
    lightGreenWarningStatus: EnterpriseColors.lightGreenDtmaly,
    applicationDetailsClientBar: EnterpriseColors.greenDtmaly,
    lgreen: EnterpriseColors.lightGreenDtmaly,
    mgreen: EnterpriseColors.greenDtmaly,
  },
  port: {
    ...Dark.port,
    active: EnterpriseColors.greenDtmaly,
  },
  icon: {
    ...Dark.icon,
    tabBar: {
      selected: EnterpriseColors.greenDtmaly,
    },
  },
  mapStatusColors: {
    ...Dark.mapStatusColors,
    online: EnterpriseColors.greenDtmaly,
  },
  IAQ: {
    ...Dark.IAQ,
    excellent: EnterpriseColors.excellentDtmaly,
    good: EnterpriseColors.goodDtmaly,
  },
  accordian: {
    ...Dark.accordian,
  },
};

export default DarkDeuteranomaly;
