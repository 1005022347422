import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { userNeedsTFA } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { TwoFactorScreensPropMap } from "../navigation/Types";

type ReduxProps = {
  forcingTwoFactor: boolean;
};

type Props = ForwardedNativeStackScreenProps<TwoFactorScreensPropMap, "VerifyTwoFactor"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps &
  PendingComponent;

export interface VerifyTwoFactorScreenState {
  pinCode: string;
  verified: boolean;
}

export class VerifyTwoFactorScreen extends BaseOnboardingScreen<Props, VerifyTwoFactorScreenState> {
  state: VerifyTwoFactorScreenState = { verified: false, pinCode: "" };

  handleError = (error: unknown) => {
    showAlert(I18n.t("ERROR"), error);
  };

  confirmTwoFactorCode = async () => {
    const { actions, setReqPending } = this.props;
    const { pinCode } = this.state;

    setReqPending(true);
    try {
      await actions.verifyTwoFactorWorks(pinCode);
      this.setState({ verified: true });
    } catch (error) {
      this.handleError(I18n.t("VERIFY_TWO_FACTOR.PIN.INVALID_PIN"));
      this.setState({ verified: false });
    }
    setReqPending(false);
  };

  enableTwoFactor = async () => {
    const { actions, setReqPending, forcingTwoFactor } = this.props;

    setReqPending(true);
    try {
      const response = await actions.enableTwoFactor();
      const { one_time_codes } = response.response;
      if (!forcingTwoFactor) {
        await actions.updateLoginSecurity({ enforceTwoFactorAuth: true });
      }
      this.onPrimaryPress({ oneTimeCodes: Object.keys(one_time_codes) });
    } catch (error) {
      this.handleError(error);
    }
    setReqPending(false);
  };

  renderCodeBox = () => {
    const { pinCode } = this.state;
    return (
      <View style={styles.codeBoxStyle}>
        <MkiTextInput
          value={pinCode}
          textAlign={"center"}
          screenStyles={styles.codeBoxStyle}
          placeholder={I18n.t("VERIFY_TWO_FACTOR.PIN.PLACEHOLDER")}
          keyboardType={KEYBOARD_TYPE.numeric}
          onChangeText={(pinCode: string) =>
            pinCode.length <= 6 ? this.setState({ pinCode }) : undefined
          }
        />
      </View>
    );
  };

  renderCheckCircle = () => {
    const { verified } = this.state;
    return verified ? (
      <View style={styles.checkCircleStyle}>
        <MerakiIcon name="check-circle" size="xl" color={MkiColors.confirmAlertSuccess} />
        <MkiText textStyle="secondary">{I18n.t("VERIFY_TWO_FACTOR.PIN_VERIFIED")}</MkiText>
      </View>
    ) : undefined;
  };

  renderVerifyButton = () => (
    <View style={styles.buttonContainer}>
      <RoundedButton
        buttonType={ButtonType.secondary}
        onPress={this.confirmTwoFactorCode}
        screenStyles={styles.buttonStyle}
      >
        {I18n.t("VERIFY_TWO_FACTOR.VERIFY_BUTTON")}
      </RoundedButton>
    </View>
  );

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("VERIFY_TWO_FACTOR.HEADER.TITLE")}
        description={I18n.t("VERIFY_TWO_FACTOR.HEADER.DESCRIPTION")}
      />
    );
  };

  getFooterData = () => {
    const { verified } = this.state;
    return {
      buttons: [
        <RoundedButton
          disabled={!verified}
          onPress={() => this.enableTwoFactor()}
          key="verify_two_factor"
        >
          {I18n.t("VERIFY_TWO_FACTOR.ENABLE_BUTTON")}
        </RoundedButton>,
      ],
    };
  };

  renderBody = () => {
    return (
      <FullScreenContainerView withScroll>
        {this.renderHeader()}
        {this.renderCodeBox()}
        {this.renderVerifyButton()}
        {this.renderCheckCircle()}
      </FullScreenContainerView>
    );
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.verifyTwoFactor);
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  codeBoxStyle: {
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
  checkCircleStyle: {
    flex: 1,
    alignItems: "center",
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    forcingTwoFactor: userNeedsTFA(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withOnboardingStatus,
)(VerifyTwoFactorScreen);
