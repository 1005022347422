import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { useDeleteVlan, useVlan, useVlans } from "@meraki/shared/api";
import { countIpsBetweenTwoAddresses } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useApplianceDelegatedPrefixVlanAssignments } from "~/api/queries/appliances/useApplianceDelegatedPrefixes";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import CircleView from "~/go/components/CircleView";
import { DHCPHandling } from "~/go/types/NetworksTypes";
import { showActionSheet, showAlert } from "~/lib/AlertUtils";
import { addNewlinesWherePeriodCommaDetected } from "~/lib/formatHelper";
import { themeColors } from "~/lib/themeHelper";
import {
  getGXSerial,
  getIsNetworkSecured,
  getNumberOfFreeIps,
  getNumberOfUsedIPs,
  selectedVlanId,
} from "~/selectors";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import RefreshControlScrollView from "~/shared/components/RefreshControlScrollView";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

import { NetworkScreensPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "LocalAddressing">;

const LocalAddressingScreen = () => {
  const networkId = useCurrentNetworkId();
  const vlanId = useAppSelector(selectedVlanId);
  const numberOfUsedIps = useAppSelector(getNumberOfUsedIPs);
  const numberOfFreeIps = useAppSelector(getNumberOfFreeIps);
  const { theme } = useTheme();
  const gxSerial = useAppSelector(getGXSerial);

  const { data: gxVlan, refetch: vlanRefetch } = useVlan({ networkId, vlanId });
  const isNetworkSecured = useAppSelector((state) => getIsNetworkSecured(state, gxVlan?.subnet));

  const { data: ipv6Assignments, refetch: prefixRefetch } =
    useApplianceDelegatedPrefixVlanAssignments(gxSerial);
  const dhcpServerEnabled = gxVlan?.dhcpHandling === DHCPHandling.on;
  const ipv6Subnet = ipv6Assignments?.find(({ vlan }) => vlan.id === gxVlan?.id)?.ipv6?.prefix;

  const deleteVlan = useDeleteVlan();
  const queryClient = useQueryClient();
  const navigation = useNavigation<Props["navigation"]>();
  const actions = useActions();

  const isDefaultVlan = gxVlan?.id === 1;
  const color = themeColors(theme);

  const [reqPending, setReqPending] = useState(false);

  const getData = useCallback(
    async (isRefresh?: boolean) => {
      try {
        if (gxSerial) {
          setReqPending(true);
          if (isRefresh) {
            vlanRefetch();
            prefixRefetch();
          }
          await actions.getDHCPSubnets(gxSerial);
          setReqPending(false);
        } else {
          throw new Error("gxSerial undefined");
        }
      } catch (error) {
        console.warn("Failed to load DHCP data", error);
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
      }
    },
    [actions, gxSerial, prefixRefetch, vlanRefetch],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const onDeletePress = useCallback(() => {
    const onConfirm = async () => {
      try {
        setReqPending(true);

        if (gxVlan != null) {
          const { id: vlanId, subnet } = gxVlan;

          if (isNetworkSecured && subnet) {
            await actions.removeVlanSecurity(subnet);
          }

          if (networkId) {
            await deleteVlan.mutateAsync(
              { networkId, vlanId },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({ queryKey: useVlans.queryKeyRoot });
                  navigation.goBack();
                },
                onError: (error) => {
                  showAlert(
                    I18n.t("ERROR"),
                    error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"),
                  );
                },
              },
            );
          }
        }
      } catch (error) {
        console.warn(error);
        showAlert(I18n.t("ERROR"), addNewlinesWherePeriodCommaDetected(error));
      } finally {
        setReqPending(false);
      }
    };

    const options = {
      title: I18n.t("LOCAL_ADDRESSING.DELETE_VLAN.DELETE_CONFIRMATION"),
      destructiveButtonIndex: 0,
    };

    showActionSheet([I18n.t("LOCAL_ADDRESSING.DELETE_VLAN.DELETE_NETWORK")], onConfirm, options);
  }, [gxVlan, navigation, isNetworkSecured, networkId, actions, deleteVlan, queryClient]);

  useLayoutEffect(() => {
    const showEditSheet = () => {
      const options = [
        I18n.t("LOCAL_ADDRESSING.EDIT_CHOOSE.CONFIGURE_VLAN"),
        I18n.t("LOCAL_ADDRESSING.EDIT_CHOOSE.CHANGE_DHCP"),
        I18n.t("LOCAL_ADDRESSING.EDIT_CHOOSE.CHANGE_DNS"),
      ];

      const actionSheetProperties: any = { title: I18n.t("LOCAL_ADDRESSING.EDIT_CHOOSE.TITLE") };

      if (!isDefaultVlan) {
        options.push(I18n.t("LOCAL_ADDRESSING.EDIT_CHOOSE.DELETE_NETWORK"));
        actionSheetProperties["destructiveButtonIndex"] = 3;
      }

      showActionSheet(
        options,
        (id: number) => {
          switch (id) {
            case 0: {
              // UDG-3544: Validate/consolidate Vlan types
              navigation.navigate("ConfigureVlan", { vlan: gxVlan, ipv6Subnet });
              break;
            }
            case 1: {
              navigation.navigate("DHCPServer");
              break;
            }
            case 2: {
              navigation.navigate("DNSServers");
              break;
            }
            case 3: {
              onDeletePress();
              break;
            }
          }
        },
        actionSheetProperties,
      );
    };

    navigation.setOptions({
      headerRight: () => <EditButton onPress={showEditSheet} />,
    });
  }, [gxVlan, ipv6Subnet, isDefaultVlan, navigation, onDeletePress]);

  if (!gxVlan || (dhcpServerEnabled && numberOfUsedIps == null)) {
    return <LoadingSpinner visible />;
  }

  let totalIps: string | number = "";
  if (numberOfUsedIps != null && numberOfFreeIps != null) {
    totalIps = numberOfUsedIps + numberOfFreeIps;
  }

  let totalRservedIps = 0;
  if (gxVlan.fixedIpAssignments) {
    totalRservedIps += Object.keys(gxVlan.fixedIpAssignments).length;
  }
  if (gxVlan.reservedIpRanges) {
    totalRservedIps += gxVlan.reservedIpRanges.reduce((sum, ipRanges) => {
      // count IPs between two address + start address count (1)
      return sum + countIpsBetweenTwoAddresses(ipRanges.start, ipRanges.end) + 1;
    }, 0);
  }

  const { name, id, subnet } = gxVlan;
  const textColor = dhcpServerEnabled ? undefined : { color: MkiColors.disabledHeadingColor };

  const renderDHCPDiagram = () => {
    if (numberOfFreeIps == null || numberOfFreeIps == null || !dhcpServerEnabled) {
      return null;
    }

    return (
      <>
        <View key="lines" style={[styles.spacedBetweenRow, styles.lineContainer]}>
          <View style={[styles.lineStyle, styles.lineLeft]} />
          <View style={[styles.lineStyle, styles.lineRight]} />
        </View>
        <View key="circles" style={[styles.spacedBetweenRow, styles.circleContainer]}>
          <CircleView
            topText={`${totalRservedIps}`}
            bottomText={I18n.t("LOCAL_ADDRESSING.RESERVED_IP_ADDRESSES")}
            onPress={() => navigation.navigate("ReservedAddresses")}
          />
          <CircleView
            topText={`${numberOfFreeIps}`}
            bottomText={I18n.t("LOCAL_ADDRESSING.DYNAMIC_IP_ADDRESSES")}
            style={color.localAddressing}
          />
        </View>
      </>
    );
  };

  const renderIPv6Summary = () => {
    if (ipv6Subnet == null) {
      return null;
    }

    const [prefix, subnet] = ipv6Subnet.split("/");
    const rows = [
      { label: I18n.t("LOCAL_ADDRESSING.IPV6.PREFIX"), value: prefix, key: "IPv6Prefix" },
      { label: I18n.t("LOCAL_ADDRESSING.IPV6.SUBNET"), value: subnet, key: "IPv6Subnet" },
    ];

    return (
      <View style={styles.ipv6Container}>
        {rows.map((rowData) => (
          <ListRow
            value={rowData.value}
            rowStyles={styles.listRow}
            leftStyle={styles.leftContent}
            rightStyle={styles.rightContent}
            key={rowData.key}
          >
            {rowData.label}
          </ListRow>
        ))}
      </View>
    );
  };

  return (
    <RefreshControlScrollView
      contentContainerStyle={styles.mainScrollView}
      onRefresh={() => getData(true)}
    >
      <View style={styles.nameHeader}>
        <EditableNameHeader
          containerStyles={styles.editableNameContainer}
          title={name}
          entity={I18n.t("LOCAL_ADDRESSING.VLAN")}
        />
        <MkiText textStyle="smallSecondary">
          {I18n.t("LOCAL_ADDRESSING.VLAN_ID", { vlan_id: id })}
        </MkiText>
      </View>
      <MkiText textStyle="smallSecondary" screenStyles={styles.addressesAmountLabel}>
        {`${I18n.t("LOCAL_ADDRESSING.YOUR_NETWORK_PREFIX")} ${totalIps} ${I18n.t(
          "LOCAL_ADDRESSING.YOUR_NETWORK_SUFFIX",
        )}`}
      </MkiText>
      <MkiText textStyle="heading" screenStyles={styles.address}>
        {subnet}
      </MkiText>
      <MkiText textStyle="smallSecondary" screenStyles={styles.heresHowLabel}>
        {I18n.t("LOCAL_ADDRESSING.HERES_HOW")}
      </MkiText>
      <View style={[styles.box, color.localAddressing]}>
        <MkiText textStyle="heading" screenStyles={textColor}>
          {I18n.t("LOCAL_ADDRESSING.DHCP_SERVER")}
        </MkiText>
        <MkiText textStyle="smallSecondary">
          {dhcpServerEnabled ? I18n.t("ENABLED") : I18n.t("DISABLED")}
        </MkiText>
        <MkiText textStyle="smallSecondary">
          {I18n.t("LOCAL_ADDRESSING.DHCP_OPTIONS", {
            numOfOptions: gxVlan?.dhcpOptions?.length ?? 0,
          })}
        </MkiText>
      </View>
      {renderDHCPDiagram()}
      {renderIPv6Summary()}
      <LoadingSpinner visible={reqPending} />
    </RefreshControlScrollView>
  );
};

const styles = StyleSheet.create({
  mainScrollView: {
    alignItems: "center",
  },
  editableNameContainer: {
    paddingBottom: SPACING.tiny,
  },
  nameHeader: {
    flex: 1,
    alignItems: "center",
  },
  lineStyle: {
    width: 3,
    height: 60,
    borderRadius: 1.5,
    backgroundColor: MkiColors.localAddressingLine,
  },
  addressesAmountLabel: {
    paddingTop: SPACING.large,
    width: "40%",
    textAlign: "center",
  },
  address: {
    paddingTop: SPACING.default,
  },
  heresHowLabel: {
    paddingVertical: SPACING.large,
    width: "50%",
    textAlign: "center",
  },
  spacedBetweenRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 204,
    padding: SPACING.default,
    paddingBottom: SPACING.default,
    backgroundColor: MkiColors.localAddressingBox,
    alignItems: "center",
    borderRadius: 6,
  },
  lineContainer: {
    width: 164,
  },
  lineLeft: { transform: [{ rotate: "-170deg" }] },
  lineRight: { transform: [{ rotate: "170deg" }] },
  circleContainer: {
    width: 278,
  },
  ipv6Container: {
    marginTop: SPACING.large,
    paddingTop: SPACING.default,
    marginHorizontal: SPACING.default,
    borderTopColor: MkiColors.borderColor,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  listRow: {
    paddingVertical: SPACING.tiny,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
});

export default LocalAddressingScreen;
