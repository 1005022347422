import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface SSIDSchedulesRequest {
  networkId?: string;
  ssidNumber?: number;
}

interface SSIDSchedulesBuildUrlRequest {
  networkId?: string;
  ssidNumber: string;
}

interface MutateSSIDSchedulesRequest extends SSIDSchedulesRequest {
  body: SSIDSchedules;
}

export const SSIDSchedulesSchema = z.object({
  enabled: z.boolean(),
  ranges: z
    .array(
      z.object({
        startDay: z.string(),
        startTime: z.string(),
        endDay: z.string(),
        endTime: z.string(),
      }),
    )
    .optional(),
  rangesInSeconds: z
    .array(
      z.object({
        start: z.number(),
        end: z.number(),
      }),
    )
    .optional(),
});

export type SSIDSchedules = z.infer<typeof SSIDSchedulesSchema>;

const buildUrl = ({ networkId, ssidNumber }: SSIDSchedulesBuildUrlRequest) => {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/schedules`;
};

const fetchSSIDSchedules = ({ networkId, ssidNumber }: SSIDSchedulesRequest) => {
  if (ssidNumber === undefined) {
    throw new Error("SSIDNumber is not defined");
  }

  return request(
    SSIDSchedulesSchema,
    "GET",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
  );
};

export const useSSIDSchedules = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  queryFn: (parameters: SSIDSchedulesRequest) => fetchSSIDSchedules(parameters),
  requiredVariables: ["networkId", "ssidNumber"],
});

const updateSSIDSchedules = ({ networkId, ssidNumber, body }: MutateSSIDSchedulesRequest) => {
  if (ssidNumber === undefined) {
    throw new Error("SSIDNumber is not defined");
  }

  return request(
    SSIDSchedulesSchema,
    "PUT",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
    {
      body: JSON.stringify(body),
    },
  );
};

export const useUpdateSSIDSchedules = createMutation<
  MutateSSIDSchedulesRequest,
  SSIDSchedules,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  mutationFn: (parameters: MutateSSIDSchedulesRequest) => updateSSIDSchedules({ ...parameters }),
});
