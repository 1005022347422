import { I18n } from "@meraki/core/i18n";
import { MAX_IPSK_LIMIT } from "@meraki/go/ssid";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { IdentityPskResponse, IdentityPsksResponse } from "~/api/schemas/IdentityPsk";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import SectionListHeader from "~/go/components/SectionListHeader";
import { SSIDIdentityPskListRow } from "~/go/rows/SSIDIdentityPskListRow";
import { showAlert } from "~/lib/AlertUtils";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";

interface SSIDIdentityPskRowProps {
  selected: boolean;
  disabled: boolean;
  ssidNumber: number;
  ipskValues?: IdentityPsksResponse;
  loading: boolean;
}

export const SSIDIdentityPskRow = (props: SSIDIdentityPskRowProps) => {
  const { selected, disabled, ipskValues, ssidNumber, loading } = props;
  const navigation = useNavigation();

  const ipskLimit = ipskValues && ipskValues.length >= MAX_IPSK_LIMIT;

  const itemStyle = disabled ? styles.disabledTextColor : selected ? styles.selectedTextColor : {};

  const renderRow = (rowData: IdentityPskResponse, rowId: number) => {
    return <SSIDIdentityPskListRow rowData={rowData} rowId={rowId} ssidNumber={ssidNumber} />;
  };

  const ipskOverLimitError = () => {
    showAlert(
      I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.ERROR.LABEL"),
      I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.ERROR.SUBLABEL"),
    );
  };

  return (
    <View style={styles.container}>
      <MkiText screenStyles={itemStyle}>
        {I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.LABEL")}
      </MkiText>
      {selected && !disabled && (
        <View style={styles.passwordList}>
          <SectionListHeader
            heading={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.CONFIG.IPSK.SUBLABEL")}
            onPress={
              ipskLimit
                ? ipskOverLimitError
                : () => navigation.navigate("ConfigureIpsk", { ssidNumber })
            }
          />
          <FlatList
            scrollEnabled={false}
            data={ipskValues}
            renderItem={({ item, index }) => renderRow(item, index)}
            keyExtractor={(_, index) => index.toString()}
          />
          <LoadingSpinner visible={loading} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  selectedTextColor: {
    color: MkiColors.goPurple,
  },
  disabledTextColor: {
    color: MkiColors.secondaryTextColor,
  },
  passwordList: {
    paddingRight: SPACING.extraLarge,
  },
});
