import { StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { getExpandableCards } from "~/selectors";
import ExpandableContainer, {
  ExpandableContainerProps,
} from "~/shared/components/ExpandableContainer";
import MerakiIcon from "~/shared/components/icons";
import { ExpandableCardKeys, ExpandableCards } from "~/shared/types/Preferences";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { RootState } from "../types/Redux";

type ReduxProps = {
  expandedCards?: ExpandableCards;
};

interface ExpandableCardProps extends ExpandableContainerProps {
  alwaysUnexpended?: boolean;
  expandedCards?: ExpandableCards;
  renderHeader: () => JSX.Element;
  savePreferencesKey?: ExpandableCardKeys;
}

type Props = ExpandableCardProps & ReduxProps & BasicActions;

export class ExpandableCard extends ExpandableContainer<Props> {
  constructor(props: Props) {
    super(props);
    const { alwaysUnexpended, expandedCards, savePreferencesKey } = props;
    this.state = {
      isExpanded:
        !alwaysUnexpended &&
        (savePreferencesKey == null ||
          expandedCards?.[savePreferencesKey] == null ||
          !!expandedCards[savePreferencesKey]),
    };
  }

  onPress = () => {
    const { actions, savePreferencesKey } = this.props;
    const { isExpanded } = this.state;

    if (savePreferencesKey) {
      actions.updateExpandableCards({ [savePreferencesKey]: !isExpanded });
    }
    this.onHeaderPress();
  };

  renderMain() {
    const { renderHeader } = this.props;
    const { isExpanded } = this.state;

    return (
      <TouchableOpacity onPress={this.onPress}>
        <View style={styles.container}>
          <View style={styles.header}>{renderHeader()}</View>
          <MerakiIcon
            color={MkiColors.primaryButton}
            name={isExpanded ? "expand-less" : "expand-more"}
            size="s"
            containerStyle={styles.chevron}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    flex: 10,
  },
  chevron: {
    flex: 1,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    expandedCards: getExpandableCards(state),
  };
}

export default connect(mapStateToProps, basicMapDispatchToProps)(ExpandableCard);
