import { getProductType } from "@meraki/shared/devices";
import { groupBy, isEmpty, sortBy } from "lodash";
import { createSelector } from "reselect";

import { NODES_SEARCH_FIELDS, NODES_SEARCH_KEY } from "~/constants/SearchKeys";
import {
  getIsMeshAccessPoint,
  isGX50,
  isSwitchOrAppliance,
  lldpNormalizer,
  lldpWithPortNormalizer,
} from "~/lib/DeviceUtils";
import NetworkUtils from "~/lib/NetworkUtils";
import { normalizeObjectByKey, normalizeObjectToKeyValue } from "~/lib/objectHelper";
import { filterData } from "~/lib/SearchUtils";
import {
  currentNetworkState,
  devicesState,
  deviceUsageState,
  getUplinkState,
} from "~/selectors/getters";
import { timespanState } from "~/selectors/preferences";
import { searchTextState } from "~/selectors/search";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import Device, { DevicesBySerial, DevicesByType, LLDPDevice } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";

const COMPLETE_SUITE = [ProductType.appliance, ProductType.switch, ProductType.wireless];

export const devicesSelector = createSelector(
  currentNetworkState,
  devicesState,
  (networkId: string | undefined, devices: DevicesBySerial) => {
    const filteredDevices = Object.values(devices).filter((device) =>
      NetworkUtils.idsEqual(device.networkId, networkId),
    );
    return sortBy(filteredDevices, ["name", "mac"]);
  },
);

export const devicesByMacSelector = createSelector(devicesState, (devices) =>
  normalizeObjectByKey(devices, "mac"),
);

export const deviceLLDPData = createSelector(
  devicesState,
  (_: RootState, payload: any) => payload.serialNumber,
  (_: RootState, payload: any) => payload.portNumber,
  (devices: DevicesBySerial, deviceSerial: string, portNumber: number): LLDPDevice | object => {
    const device = devices[deviceSerial];
    if (!portNumber && isSwitchOrAppliance(device?.model)) {
      return {} as LLDPDevice;
    }
    const lldpData = device?.lldp;
    const normalizedLLDP = isSwitchOrAppliance(device?.model)
      ? lldpWithPortNormalizer(lldpData, portNumber)
      : lldpNormalizer(lldpData);

    return normalizedLLDP;
  },
);

export const onlineDevicesSelector = createSelector(devicesSelector, CUSTOM_FILTERS.ONLINE_DEVICE);

export const alertingDevicesSelector = createSelector(
  devicesSelector,
  CUSTOM_FILTERS.ALERTING_DEVICE,
);

export const offlineDevicesSelector = createSelector(
  devicesSelector,
  CUSTOM_FILTERS.OFFLINE_DEVICE,
);

export const unconfiguredDevicesSelector = createSelector(
  devicesSelector,
  CUSTOM_FILTERS.UNCONFIGURED_DEVICE,
);

export const productTypeDevicesSelector = createSelector(
  devicesSelector,
  (_: RootState, productType: ProductType) => productType,
  (devices, productType) => CUSTOM_FILTERS.PRODUCT_TYPE_DEVICES(devices, productType),
);

export const nodesByTypeSelector = createSelector(devicesSelector, (nodes) => {
  return groupBy(nodes, (node) => getProductType(node.model));
});

const emptyDevice = {} as Device;

export const deviceByIdSelector = createSelector(
  devicesState,
  (_: RootState, props: any) => props.id,
  (devices: DevicesBySerial, id: string) =>
    Object.values(devices).find((device) => {
      // For camera only admins the device id is a Number sometimes and sometimes it is a String
      return String(device.id) === id;
    }) || emptyDevice,
);

export const hasNATRouterSelector = createSelector(onlineDevicesSelector, (devices) =>
  devices.some((device: any) => device.hasNATRouter),
);

export const filteredNodesByTypeSelector = createSelector(
  nodesByTypeSelector,
  searchTextState,
  (nodes, searchText) => filterData(nodes, NODES_SEARCH_FIELDS, searchText(NODES_SEARCH_KEY)),
);

export const makeCustomFilteredDevices = () =>
  createSelector(
    filteredNodesByTypeSelector,
    (_: RootState, props: any) => props.customFilter || CUSTOM_FILTERS.DEFAULT,
    (devices, customFilter) => {
      const customFilteredDevices: any = {};
      Object.keys(devices).forEach((type) => {
        const temp = customFilter(devices[type]);
        if (temp.length > 0) {
          customFilteredDevices[type] = temp;
        }
      });
      return customFilteredDevices;
    },
  );

export const makeHardwareListDevices = () =>
  createSelector(makeCustomFilteredDevices(), (devices) => {
    if (Object.keys(devices).length === 1) {
      const [type] = Object.keys(devices);
      return devices[type];
    }
    return devices;
  });

export const hasCompleteSuite = createSelector(
  filteredNodesByTypeSelector,
  (devicesByType: DevicesByType) => {
    const claimedProductTypes = Object.keys(devicesByType);
    return COMPLETE_SUITE.every((productType) => claimedProductTypes.includes(productType));
  },
);

export const hasGXDevices = createSelector(
  nodesByTypeSelector,
  (devicesByType) => !isEmpty(devicesByType.appliance),
);

export const hasGRDevices = createSelector(
  nodesByTypeSelector,
  (devicesByType) => !isEmpty(devicesByType.wireless),
);

export const hasGSDevices = createSelector(
  nodesByTypeSelector,
  (devicesByType) => !isEmpty(devicesByType.switch),
);

export const deviceState = (state: RootState, props: any) =>
  devicesState(state)[props.serialNumber || props.serial] || {};

export const makeNodesInNetworkSelector = () =>
  createSelector(
    devicesState,
    (_: RootState, props: any) => props.networkId,
    (_: RootState, props: any) => props.serials,
    (devices: DevicesBySerial, networkId, serials) => {
      if (!networkId) {
        return [];
      }
      const devicesInNetwork = Object.values(devices).filter((device) =>
        NetworkUtils.idsEqual(device.networkId, networkId),
      );

      if (serials && serials.length > 0) {
        return devicesInNetwork.filter((device) => serials.includes(device.serial));
      }

      return devicesInNetwork;
    },
  );

export const nodeUsageState = (state: RootState, props: any) =>
  deviceUsageState(state)?.[props.serialNumber]?.[timespanState(state)] || null;

// node uptime
export const nodeUptimeState = (state: RootState, props: any) => {
  const nodeUsage = nodeUsageState(state, props);
  return !nodeUsage
    ? null
    : {
        entries: nodeUsage.upseries || [],
        t0: nodeUsage.t0 || 0,
        t1: nodeUsage.t1 || 1,
      };
};

export const getDeviceHashedIpMap = createSelector(
  devicesState,
  (devices) => normalizeObjectToKeyValue(devices, "hashedIp4", "serial") || {},
);

// use up to two GR names for warning message
export const onlineGRsWithPublicIPSelector = createSelector(
  onlineDevicesSelector,
  (onlineDevices: Device[]) =>
    onlineDevices
      .filter((device) => {
        const productType = getProductType(device.model);
        return (
          productType === ProductType.wireless &&
          !device.hasNATRouter &&
          !getIsMeshAccessPoint(device)
        );
      })
      .slice(0, 2)
      .map((device) => device.name),
);

export const gxDeviceSelector = createSelector(devicesSelector, (nodes: Device[]) => {
  const gxDevice = nodes.find((node) => {
    const productType = getProductType(node.model);
    return productType === ProductType.appliance;
  });

  return gxDevice;
});

export const switchDevicesSelector = createSelector(devicesSelector, (nodes: Device[]) => {
  const switches: any = {};
  nodes.forEach((node) => {
    const productType = getProductType(node.model);
    if (productType === ProductType.switch) {
      switches[node.serial] = node;
    }
  });
  return switches;
});

export const getGXSerial = createSelector(
  gxDeviceSelector,
  (gxDevice: Device | undefined) => gxDevice?.serial,
);

export const getDeviceNames = createSelector(devicesState, (devices: DevicesBySerial) =>
  Object.values(devices).map((device) => device.name || device.serial),
);

export const getDeviceUplink = (state: RootState, serialNumber: string) => {
  return getUplinkState(state)?.[serialNumber];
};

export const hasGXWithVPNSelector = createSelector(
  gxDeviceSelector,
  (gxDevice: Device | undefined) => isGX50(gxDevice),
);

export const isAnyDeviceConnected = createSelector(devicesState, (devices: DevicesBySerial) =>
  Object.values(devices).some((device) => device.lspConnected),
);
