import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import {
  FlatList,
  FlatListProps,
  RefreshControl,
  ScrollViewProps,
  SectionList,
  SectionListData,
  SectionListProps,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import SectionHeader from "~/enterprise/components/SectionHeader";
import RefreshButton from "~/go/components/RefreshButton";
import { isWeb } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import SearchBar, { SearchBarProps } from "~/shared/components/SearchBar";

import { MkiText } from "./MkiText";
import SkeletonList from "./SkeletonList";

type onFlatListPress<T> = (item: T, index: number, innerData?: T) => void;
type onSectionListPress<T> = (item: T, index: number, section: T, innerData?: T) => void;

interface RowDataProps<T> {
  onPress?: (innerData?: T) => void;
}

export type RowData<T> = T & RowDataProps<T>;

type CommonPropsToFilter =
  | "data"
  | "getItemLayout"
  | "viewabilityConfig"
  | "onContentSizeChange"
  | "onScroll"
  | "style";
type FilteredFLProps<T> = Partial<Omit<FlatListProps<T>, CommonPropsToFilter | "renderItem">>;
type FilteredSLProps<T> = Partial<Omit<SectionListProps<T>, CommonPropsToFilter>>;
type FilteredSVProps = Partial<Omit<ScrollViewProps, CommonPropsToFilter>>;

type onSearchChangeCallback = SearchBarProps["onChangeText"];

export interface MkiTableProps<T> extends FilteredFLProps<T>, FilteredSLProps<T>, FilteredSVProps {
  scrollViewTestID?: string;
  loading?: boolean;
  hasSeparators?: boolean;
  hasSectionSeparators?: boolean;

  onSearchChange?: onSearchChangeCallback;
  onSearchClearPressed?: SearchBarProps["onClearPressed"]; // this prop is unused...
  searchPlaceholder?: string;
  searchPrefill?: string;
  searchText?: string;

  style?: StyleProp<ViewStyle>;
  sectionHeaderStyle?: StyleProp<ViewStyle>;
  renderRow?: (row: RowData<T>, index: number, id?: string) => React.ReactElement | null;
  onPress?: ((rowData?: T) => void) | ((rowData: T, idx: number) => void);
  sortKeyComparator?: (obj1: string, obj2: string) => number;
  sectionTitleMap?: object;

  emptyComponent?: React.ReactElement;

  // Common props in both FlatListProps and SectionListProps whose types are not identical.
  data: ReadonlyArray<T> | { [key: string]: unknown };
  loadingInitialData?: boolean;
  numLoadingElements?: number;

  isFailure?: boolean;
  failureComponent?: React.ReactElement;
  showResultsCount?: boolean;
}
export class MkiTable<T> extends PureComponent<MkiTableProps<T>> {
  renderSectionHeader = ({ section }: { section: SectionListData<T> }) => {
    if (!section.key) {
      return null;
    }

    const { sectionHeaderStyle, sectionTitleMap } = this.props;
    let title = section.key;
    if (sectionTitleMap && title in sectionTitleMap) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      title = sectionTitleMap[title];
    }
    return <SectionHeader style={sectionHeaderStyle}>{title.toUpperCase()}</SectionHeader>;
  };

  renderSeparatorComponent = () => <MkiRowSeparator withHorizontalMargins={__MERAKI_GO__} />;

  renderSectionListItem = ({ item, index }: { item: T; index: number }) => {
    // @ts-ignore this property is set in "formatting data" before being passed to this function
    const { mkiTableSectionId } = item;
    const { onPress, renderRow } = this.props;
    const { theme } = useTheme.getState();
    const onPressRow = (innerData?: T) =>
      (onPress as onSectionListPress<T>)?.(item, index, mkiTableSectionId, innerData);

    const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
    return (
      <View style={{ backgroundColor }}>
        {renderRow?.({ ...item, onPress: onPressRow }, index, mkiTableSectionId)}
      </View>
    );
  };

  getRefreshControl = () => {
    const { refreshing, loading, onRefresh, refreshControl } = this.props;
    const { theme } = useTheme.getState();
    if (refreshControl) {
      return refreshControl;
    }
    if (!onRefresh) {
      return undefined;
    }
    const tintColor = themeColors(theme).spinner?.color;
    return (
      <RefreshControl
        refreshing={refreshing || loading || false}
        onRefresh={onRefresh}
        tintColor={tintColor}
      />
    );
  };

  numberOfSections = () => {
    const { data } = this.props;
    return Object.keys(data).length;
  };

  renderSectionSeparator = ({ section }: { section: SectionListData<T> }) => {
    if (section.index === this.numberOfSections() - 1) {
      return null;
    }
    return this.renderSeparatorComponent();
  };

  renderSectionList(data: { [key: string]: unknown }) {
    const {
      hasSeparators,
      hasSectionSeparators,
      scrollViewTestID,
      sortKeyComparator,
      renderItem,
      renderSectionHeader,
      ...remainingProps
    } = this.props;

    const {
      // props we don't want to pass in to native SectionList
      sections: _sections,
      keyExtractor: _keyExtractor,
      refreshing: _refreshing,
      onRefresh: _onRefresh,
      ...rest
    } = remainingProps;

    let keys = Object.keys(data);
    if (sortKeyComparator) {
      keys = keys.sort(sortKeyComparator);
    }

    const formattedData = keys.map((section, index) => ({
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      data: data[section].map((d: T) => ({
        ...d,
        mkiTableSectionId: section,
      })),
      key: section,
      index,
    }));

    return (
      <SectionList<T>
        keyboardDismissMode="on-drag"
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        keyExtractor={(item, index) => `${item["mkiTableSectionId"]}:${index}`}
        ItemSeparatorComponent={hasSeparators ? this.renderSeparatorComponent : null}
        stickySectionHeadersEnabled={false}
        refreshControl={this.getRefreshControl()}
        sections={formattedData}
        renderItem={renderItem || this.renderSectionListItem}
        renderSectionFooter={hasSectionSeparators ? this.renderSectionSeparator : undefined}
        renderSectionHeader={renderSectionHeader ?? this.renderSectionHeader}
        {...(rest as Partial<SectionListProps<T>>)}
        testID={scrollViewTestID}
      />
    );
  }

  renderFlatListItem = ({ item, index }: { item: T; index: number }) => {
    const { onPress, renderRow } = this.props;
    const onPressRow = (innerData?: T) => (onPress as onFlatListPress<T>)?.(item, index, innerData);

    const { theme } = useTheme.getState();
    const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
    return (
      <View style={{ backgroundColor }}>
        {renderRow?.({ ...item, onPress: onPressRow }, index)}
      </View>
    );
  };

  renderFlatList = (data: T[]) => {
    const { scrollViewTestID, hasSeparators, scrollEnabled, ...remainingProps } = this.props;

    const {
      // props we don't want to pass in to native FlatList
      renderItem: _renderItem,
      onRefresh: _onRefresh,
      refreshing: _refreshing,
      ...rest
    } = remainingProps;

    return (
      <FlatList<T>
        ListHeaderComponent={this.renderResultsCount(data)}
        keyboardDismissMode="on-drag"
        data={data}
        scrollEnabled={scrollEnabled ?? true}
        ItemSeparatorComponent={hasSeparators ? this.renderSeparatorComponent : null}
        renderItem={this.renderFlatListItem}
        refreshControl={this.getRefreshControl()}
        {...(rest as Partial<FlatListProps<T>>)}
        testID={scrollViewTestID}
      />
    );
  };

  renderSearchBar = () => {
    const { searchPrefill, searchText, searchPlaceholder, testID, onSearchChange } = this.props;

    return (
      <SearchBar
        transparentBackground
        defaultValue={searchPrefill}
        value={searchText}
        placeholder={searchPlaceholder}
        onChangeText={onSearchChange}
        testID={testID ? `${testID}.SEARCHBAR` : "SEARCHBAR"}
      />
    );
  };

  renderInitalLoading = () => {
    const { numLoadingElements } = this.props;
    return <SkeletonList numElements={numLoadingElements} placeholderHeight={44} />;
  };

  renderList = () => {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    return Array.isArray(data)
      ? this.renderFlatList(data)
      : this.renderSectionList(data as { [key: string]: unknown });
  };

  renderBody = () => {
    const { loadingInitialData, data, emptyComponent } = this.props;
    if (loadingInitialData) {
      return this.renderInitalLoading();
    } else if ((!data || (data && data.length === 0)) && emptyComponent) {
      return emptyComponent;
    }
    return this.renderList();
  };

  renderResultsCount = (data: T[]) => {
    const { showResultsCount, loadingInitialData } = this.props;
    if (!showResultsCount || loadingInitialData) {
      return undefined;
    }
    return (
      <MkiText textStyle="smallSecondary" screenStyles={styles.results}>
        {data.length > 1
          ? I18n.t("RESULTS.MANY", { num: data.length })
          : I18n.t("RESULTS.ONE", { num: data.length })}
      </MkiText>
    );
  };

  render() {
    const { onSearchChange, testID, onRefresh, style, failureComponent, isFailure } = this.props;
    const searchBar = onSearchChange ? this.renderSearchBar() : null;

    if (isFailure && failureComponent) {
      return failureComponent;
    }

    return (
      <View style={[styles.container, style]} testID={testID}>
        {searchBar}
        {onRefresh && isWeb() ? <RefreshButton onRefresh={onRefresh} /> : null}
        {this.renderBody()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  results: {
    textAlign: "left",
    paddingVertical: SPACING.small,
    paddingStart: SPACING.default,
  },
});

export default MkiTable;
