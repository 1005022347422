import { isEmpty } from "lodash";
import { memo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";

import { SPACING } from "~/constants/MkiConstants";
import { getOnboardingFooterStatusAttributes } from "~/lib/OnboardingFullstackUtils";
import MkiText from "~/shared/components/MkiText";
import { OnboardingFooterStatus } from "~/shared/types/OnboardingTypes";

const STATUS_BAR_HEIGHT = "15";
const STATUS_BAR_WIDTH = "100%";

export interface OnboardingFooterProps {
  buttons?: React.ReactNode[];
  status?: OnboardingFooterStatus;
  onStatusPress?: () => void;
}

const renderStatus = (status?: OnboardingFooterStatus, onStatusPress?: () => void) => {
  const statusAttributes = getOnboardingFooterStatusAttributes(status);

  if (!statusAttributes) {
    return null;
  }

  const statusTextColorStyle = { color: statusAttributes.textColor };

  const statusText = (
    <MkiText screenStyles={[styles.statusText, statusTextColorStyle]}>
      {statusAttributes.text}
    </MkiText>
  );

  const statusContainer = onStatusPress ? (
    <TouchableOpacity testID={"ONBOARDING_FOOTER"} onPress={onStatusPress}>
      {statusText}
    </TouchableOpacity>
  ) : (
    statusText
  );

  return (
    <View>
      {statusContainer}
      <Svg height={STATUS_BAR_HEIGHT} width={STATUS_BAR_WIDTH}>
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="0">
            <Stop offset="0" stopColor={statusAttributes.gradientStartColor} stopOpacity="1" />
            <Stop offset="1" stopColor={statusAttributes.gradientEndColor} stopOpacity="1" />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" width={STATUS_BAR_WIDTH} height={STATUS_BAR_HEIGHT} fill="url(#grad)" />
      </Svg>
    </View>
  );
};

const OnboardingFooter = (props: OnboardingFooterProps) => {
  const { buttons, status, onStatusPress } = props;
  const renderedStatus = renderStatus(status, onStatusPress);
  if (!isEmpty(buttons) || renderedStatus) {
    return (
      <View>
        <View style={styles.buttonContainer}>{buttons}</View>
        {renderStatus(status, onStatusPress)}
      </View>
    );
  } else {
    return null;
  }
};

OnboardingFooter.defaultProps = {
  buttons: [],
};

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: SPACING.extraLarge,
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  statusText: {
    textAlign: "center",
    marginBottom: SPACING.small,
  },
});

export default memo(OnboardingFooter);
