import { I18n } from "@meraki/core/i18n";
import {
  endOfDay,
  formatDistanceToNowStrict,
  getUnixTime,
  parseISO,
  startOfDay,
  sub,
} from "date-fns";

import { MS_IN_A_SECOND } from "~/constants/MkiConstants";
import { DateString } from "~/shared/types/TimeTypes";

export function calculateTimespan(secondsAgo: any, options: { ms?: boolean } = {}) {
  const t1 = Date.now();
  const t0 = t1 - secondsAgo * 1000;

  if (options.ms) {
    return { t0, t1 };
  }
  return { t0: t0 / 1000, t1: t1 / 1000 };
}

// This follows from the logic in Dashboard for selecting the correct t0 and t1
// values to send to the /presence endpoint for Location Analytics.  This function
// finds the most recent Midnight UTC that has passed, and we use that as the
// reference point t1 for sending the request.  If we use a request time that is
// later than the most recent Midnight UTC, the data returned is all 0's since we
// are asking for data that is not yet available.
export function calculateTimespanForPresence(secondsAgo: any) {
  const t1 = convertToUTCStartOfDay(Date.now());
  return { t0: t1 - secondsAgo, t1 };
}

// Helpers for custom time intervals for Location Analytics
export const convertToUTCStartOfDay = (date: any) => {
  const dateObj = new Date(date);
  // Subtract the timezone offset and 1 extra minute to account for a edge case that moment used to do.
  // If the time was midnight the `startOf` would return the start of the previous day. This is a workaround for code that relied on that behavior.
  // This is currently only used in Go 2.0 and therefore should not be around for much longer.
  dateObj.setMinutes(dateObj.getMinutes() - new Date().getTimezoneOffset() - 1);
  const startOfDayDate = startOfDay(dateObj);
  return Math.floor(startOfDayDate.getTime() / 1000);
};
export const convertToUTCEndOfDay = (date: any) => {
  const dateObj = new Date(date);
  // Subtract the timezone offset and 1 extra minute to account for a edge case that moment used to do.
  // If the time was midnight the `endOf` would return the be one minute before the end of the day. This is a workaround for code that relied on that behavior.
  // This is currently only used in Go 2.0 and therefore should not be around for much longer.
  dateObj.setMinutes(dateObj.getMinutes() - new Date().getTimezoneOffset() - 1);
  const endOfDayDate = endOfDay(dateObj);
  return Math.floor(endOfDayDate.getTime() / 1000);
};

export const getStartOfYesterdayAsDate = () => startOfDay(sub(Date.now(), { days: 1 }));
export const getEndOfYesterdayAsDate = () => endOfDay(sub(Date.now(), { days: 1 }));
export const getOneYearAgoAsDate = () => sub(Date.now(), { years: 1 });

// The functions below are functions for setting the start dates and end dates
// in a comparison between two time intervals of length timespanLength seconds.
// In these functions, we subtract 1 second from the timespanLength in order
// to make the endpoints for the date range the actual start or end of the
// desired dates.  For example, if we are at 11:59:59 pm and subtract 1 day, we are
// at 11:59:59 of the previous day, when the actual start of the day that we want
// is one second later at 12:00:00 on the same day as the original date.
export const addTimespanToDate = (date: Date, timespanSeconds: number) => {
  return new Date(date.valueOf() + (timespanSeconds - 1) * MS_IN_A_SECOND);
};

export const subtractTimespanFromDate = (date: Date, timespanSeconds: number) => {
  return new Date(date.valueOf() - (timespanSeconds - 1) * MS_IN_A_SECOND);
};

export const fromIsoToUnixTimestamp = (iso: DateString): number => getUnixTime(parseISO(iso));

export const fromSupportCaseTsToDate = (utc: string) => {
  // Case date are from a private endpoint, which are not standardized.

  let finalTime;
  if (utc) {
    // Formatted to ISO because react native's Date() cannot process non-RFC2822/ISO dates.
    const temp = utc.replace(/\//g, "-").split(" +")[0].split(" ");
    const date = `${temp[0]}T${temp[1]}Z`;

    finalTime = new Date(date);
  } else {
    // for local support case comment additions
    finalTime = Date.now();
  }
  return finalTime;
};

export const shorthandTimeFromNow = (dateTime: Date) => {
  if (!dateTime) {
    return;
  }

  const UNIT_TRANSLATIONS: Record<string, string> = {
    DAY: I18n.t("TIMEUNITS_SHORTHAND.DAY"),
    HOUR: I18n.t("TIMEUNITS_SHORTHAND.HOUR"),
    MILLISECOND: I18n.t("TIMEUNITS_SHORTHAND.MILLISECOND"),
    MINUTE: I18n.t("TIMEUNITS_SHORTHAND.MINUTE"),
    MONTH: I18n.t("TIMEUNITS_SHORTHAND.MONTH"),
    SECOND: I18n.t("TIMEUNITS_SHORTHAND.SECOND"),
    YEAR: I18n.t("TIMEUNITS_SHORTHAND.YEAR"),
  };

  const fromNowString = formatDistanceToNowStrict(dateTime);
  const fromNowArray = fromNowString.split(" ");

  const unit = fromNowArray[1].endsWith("s") ? fromNowArray[1].slice(0, -1) : fromNowArray[1];

  return fromNowArray[0] + UNIT_TRANSLATIONS[unit.toUpperCase()];
};
