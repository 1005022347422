import { PureComponent } from "react";
import { AppState, NativeEventSubscription, StyleSheet, View } from "react-native";

import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { AppStates } from "~/lib/PlatformUtils";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import { WrappedScreenComponent } from "~/shared/types/Hocs";

export interface PendingComponent {
  setReqPending: (reqPending: boolean) => void;
  handleError: (error: unknown, onPress?: () => void) => void;
  reqPending: boolean;
}

export interface PendingComponentState {
  reqPending: boolean;
}

function withPendingComponent<P extends object>(WrappedComponent: WrappedScreenComponent<P>) {
  return class WithPendingComponent extends PureComponent<
    P & PendingComponent,
    PendingComponentState
  > {
    state = {
      reqPending: false,
    };

    setReqPending = (reqPending: boolean) => {
      this.setState((state) => (state.reqPending === reqPending ? null : { reqPending }));
    };

    handleError = (error: unknown, onPress?: () => void) => {
      this.setState({ reqPending: false });
      showAlert(I18n.t("ERROR"), error, onPress);
    };

    render() {
      const { reqPending } = this.state;
      return (
        <View style={styles.container}>
          <WrappedComponent
            {...this.props}
            setReqPending={this.setReqPending}
            handleError={this.handleError}
            reqPending={reqPending}
          />
          <LoadingSpinner visible={reqPending} />
        </View>
      );
    }
  };
}

export interface WithStateChangeComponent {
  appState?: AppStates;
}

export function withStateChange<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return class WithStateChange extends PureComponent<P & WithStateChangeComponent> {
    state = {
      appState: undefined,
    };

    appBecameActive = (nextAppState: unknown) => {
      this.setState({
        appState: nextAppState,
      });
    };
    appStateSubscription: NativeEventSubscription | undefined = undefined;

    componentDidMount() {
      this.appStateSubscription = AppState.addEventListener("change", this.appBecameActive);
    }

    componentWillUnmount() {
      this.appStateSubscription?.remove();
    }

    render() {
      const { appState } = this.state;
      return <WrappedComponent {...this.props} appState={appState} />;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default withPendingComponent;
