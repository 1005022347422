import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { ColorToken } from "@meraki/magnetic/themes";
import { useCallback } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";

import { NavButtonInternal, NavButtonInternalProps } from "./NavButtonInternal";

export type NavButtonProps = Omit<NavButtonInternalProps, "pressed"> &
  Omit<PressableProps, "style" | "accessibilityRole"> &
  AnalyticsProp<"onPress"> & {
    text: string;
    color?: ColorToken;
    loading?: boolean;
  };

export function NavButton({
  text,
  color,
  analytics,
  onPress,
  disabled,
  loading,
  leadingIcon,
  trailingIcon,
  ...rest
}: NavButtonProps) {
  const trackEvent = useAnalytics(analytics);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      trackEvent("onPress");
      onPress?.(event);
    },
    [trackEvent, onPress],
  );

  return (
    <Pressable
      {...rest}
      onPress={handlePress}
      disabled={disabled || loading}
      accessibilityRole="button"
    >
      {({ pressed }) => (
        <NavButtonInternal
          text={text}
          color={color}
          pressed={pressed}
          disabled={disabled}
          loading={loading}
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
        />
      )}
    </Pressable>
  );
}
