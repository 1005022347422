import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const LossAndLatencySchema = z.array(
  z.object({
    startTs: z.string(),
    endTs: z.string(),
    lossPercent: z.number().nullable(),
    latencyMs: z.number().nullable(),
    goodput: z.number().nullable(),
    jitter: z.number().nullable(),
  }),
);

export type LossAndLatency = z.infer<typeof LossAndLatencySchema>;

interface LossAndLatencyRequest {
  serial?: string;
  ip?: string;
  uplink?: "wan1" | "wan2" | "cellular";
  timespan?: number;
}

function buildUrl({ serial }: LossAndLatencyRequest) {
  return `/api/v1/devices/${serial}/lossAndLatencyHistory`;
}

const fetchLossAndLatency = ({
  serial,
  ip,
  uplink,
  timespan,
}: LossAndLatencyRequest): Promise<LossAndLatency> =>
  request(LossAndLatencySchema, "GET", buildUrl({ serial }), {
    queryParams: { ip, uplink, timespan },
  });

export const useLossAndLatency = createQuery<LossAndLatencyRequest, LossAndLatency>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => fetchLossAndLatency(variables),
  requiredVariables: ["serial", "uplink"],
});
