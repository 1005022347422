import { PreferencesState as LibPreferences } from "@meraki/shared/redux";

import { Theme } from "~/lib/themeHelper";

export interface BiometricAuth {
  enabled: boolean;
}

export interface UpgradeWindow {
  dayOfWeek: string;
  hourOfDay: string;
}

export enum ExpandableCardKeys {
  securityMeasures = "SECURITY_MEASURES",
  quickActions = "QUICK_ACTIONS",
  clientsOverview = "CLIENTS_OVERVIEW",
  usageChart = "USAGE_CHART",
  applicationUsage = "APPLICATION_USAGE",
  locationAnalytics = "LOCATION_ANALYTICS",
}

export type HomeScreenCardsOrder = ExpandableCardKeys[];
export const DEFAULT_HOME_SCREEN_CARDS_ORDER = Object.values(ExpandableCardKeys);

export type ExpandableCards = {
  [key in ExpandableCardKeys]?: boolean;
};

export interface UserPreferences {
  visibleInlineAlerts: {
    [key: string]: boolean;
  };
  biometricAuth?: BiometricAuth;
  defaultNetwork?: string;
  expandableCards?: ExpandableCards;
  locationAnalyticsEnabled?: boolean;
  scannedHardware?: any;
  upgradeWindow?: UpgradeWindow;
  numberOfHomeVisits?: number;
  pdfInfo?: any;
  homeScreenCardsOrder?: HomeScreenCardsOrder;
  appSurveyDismissals?: number;
  promptSetBioAuth: boolean;
  stagedTheme: Theme;
}

interface UsersAndPreferences {
  [key: string]: UserPreferences;
}

interface PreferencesBase extends LibPreferences {
  isSamlUser: boolean;
  migratedTheme: boolean;
  migratedBioauth: boolean;
}

export type Preferences = PreferencesBase & UsersAndPreferences;
