export const ONBOARDING_STEPS = {
  unbox: "unbox",
  scanHardware: "scanHardware",
  scannedReview: "scannedReview",
  primaryPlugin: "primaryPlugin",
  ledLegend: "ledLegend",
  primaryConnect: "primaryConnect",
  primaryUpgrade: "primaryUpgrade",
  primarySuccess: "primarySuccess",
  ssidCreate: "ssidCreate",
  ssidSuccess: "ssidSuccess",
  connectToWifi: "connectToWifi",
  connected: "connected",
  planAhead: "planAhead",
  remainingMenu: "remainingMenu",
  hardwireSetup: "hardwireSetup",
  hardwireConnect: "hardwireConnect",
  hardwireUpgrade: "hardwireUpgrade",
  hardwireSuccess: "hardwireSuccess",
  meshSetup: "meshSetup",
  meshConnect: "meshConnect",
  meshUpgrade: "meshUpgrade",
  meshOptimizing: "meshOptimizing",
  meshComplete: "meshComplete",
  confirmation: "confirmation",
  finished: "finished",
} as const;

export type OnboardingSteps = (typeof ONBOARDING_STEPS)[keyof typeof ONBOARDING_STEPS];

export default ONBOARDING_STEPS;
