import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import { GoStatus } from "~/shared/constants/Status";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

type Props = {
  deviceName: string;
  hideText?: boolean;
  isOnline: boolean;
  onPortPress?: () => void;
  portNumber: number;
};

const PokeDeviceHeader = memo(function PokeDeviceHeader(props: Props) {
  const { deviceName, hideText, isOnline, onPortPress, portNumber } = props;

  const text = hideText ? null : headerText;

  return (
    <View>
      {text}
      {portRow(deviceName, isOnline, onPortPress, portNumber)}
    </View>
  );
});

const portRow = (
  deviceName: string,
  isOnline: boolean,
  onPress?: (event?: unknown) => void,
  portNumber?: number,
) => {
  const icon = iconForStatus(isOnline);
  const subtitle = I18n.t("PORTS.NUMBER", { port_number: portNumber });

  return (
    <DisclosureRow icon={icon} onPress={onPress} subtitle={subtitle} renderAccessory={false}>
      {deviceName}
    </DisclosureRow>
  );
};

const iconForStatus = (isOnline: boolean) => {
  const status = isOnline ? GoStatus.good : GoStatus.offline;
  return <StatusIcon status={status} screenStyles={styles.statusIcon} />;
};

const styles = StyleSheet.create({
  infoText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    textAlign: "left",
  },
  statusIcon: {
    marginRight: SPACING.default,
  },
});

const headerText = (
  <MkiText screenStyles={styles.infoText}>{I18n.t("POKE_DEVICE.DESCRIPTION")}</MkiText>
);

export default PokeDeviceHeader;
