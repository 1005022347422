import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";

export type ObjectUpdater<T> = <K extends keyof T>(key: K) => (value: T[K]) => void;

export default function useObjectUpdater<T>(object: T) {
  const [stagedObject, updateStagedObject] = useState(object);
  const updateStagedObjectByKey = useCallback<ObjectUpdater<Partial<T>>>(
    (key) => (value) => {
      updateStagedObject({
        ...stagedObject,
        [key]: value,
      });
    },
    [stagedObject, updateStagedObject],
  );

  const hasDiff = useMemo(
    () => !isEqual(object, { ...object, ...stagedObject }),
    [object, stagedObject],
  );

  return [stagedObject, updateStagedObject, updateStagedObjectByKey, hasDiff] as const;
}
