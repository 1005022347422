import { Box } from "@meraki/magnetic/layout";
import { Children, cloneElement, isValidElement, JSXElementConstructor, ReactElement } from "react";

import { Collapse, CollapseProps } from "./Collapse";

type RootChildType = ReactElement<CollapseProps, string | JSXElementConstructor<CollapseProps>>;
type CollapseType = RootChildType | RootChildType[] | boolean;

export type InternalGroupProps = {
  children: CollapseType | CollapseType[];
  type?: CollapseProps["type"];
  backgroundColor?: CollapseProps["backgroundColor"];
  calculateChildProps: (index: number) => {
    open: CollapseProps["open"];
    onToggle: CollapseProps["onToggle"];
  };
};

export function InternalGroup({
  children,
  type = "stacked",
  backgroundColor,
  calculateChildProps,
}: InternalGroupProps) {
  const childrenArray = Children.toArray(children).filter(Boolean);
  const numberOfChildren = childrenArray.length;
  const updatedChildren = Children.map(childrenArray, (child, idx) => {
    if (!isValidElement(child)) {
      return child;
    }

    if (__DEV__ && child.type !== Collapse) {
      console.error(
        "You should only pass in Collapse components into a Collapse.Group and Collapse.AccordionGroup",
      );

      return null;
    }

    // We are forcing out everything except actual children that we need to render so we need to let TS know that
    // fact as it is unable to implicitly figure that out in this case
    return cloneElement(child as RootChildType, {
      ...calculateChildProps(idx),
      type,
      backgroundColor,
      first: idx === 0,
      last: idx === numberOfChildren - 1,
    });
  });

  return <Box gap={type === "stacked" ? "sm" : "none"}>{updatedChildren}</Box>;
}
