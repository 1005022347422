import { I18n } from "@meraki/core/i18n";
import { Alert } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import Color from "color";
import { isEmpty } from "lodash";
import {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { StyleProp, TextInput, TextInputProps, TextStyle, View, ViewStyle } from "react-native";

import { Button } from "../Button/Button";
import { Text } from "../Text/Text";

type InputInternalSpecificProps = {
  label?: string;
  additionalContext?: string | ReactElement | null;
  focused: boolean;
  errored?: boolean;
  disabled?: boolean;
  height?: number;
  maxHeight?: number;
  multiline?: boolean;
  showClear?: boolean;
  optionality?: "required" | "optional";
  rightAccessory?: ReactNode;
};

export type ValueType = TextInputProps["value"] | number;

export type InputRefFunctions = {
  focus: () => void;
  blur: () => void;
  clear: () => void;
  isFocused: () => boolean;
};

function forwardRefFC<T, P = Record<string, never>>(
  render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: PropsWithoutRef<P> & RefAttributes<T>) => ReactElement | null {
  return forwardRef(render);
}

type CustomTextInputProps<T extends ValueType> = Omit<
  TextInputProps,
  "value" | "defaultValue" | "onChangeText"
> & { value?: T; defaultValue?: T; onChangeText?: (text: T) => void };
export type InputInternalProps<T extends ValueType> = Omit<CustomTextInputProps<T>, "style"> &
  InputInternalSpecificProps;

function getOuterBorderStyle(
  { focused, errored, disabled }: InputInternalSpecificProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let borderColor = "transparent";

  if (!disabled) {
    if (focused) {
      borderColor = Color(theme.color.control.bg.strong.base).alpha(0.25).hexa();
    }

    if (errored) {
      borderColor = Color(theme.color.negative.border.base).alpha(0.25).hexa();
    }
  }

  return {
    borderRadius: 8,
    borderWidth: theme.SizeInputBorderStroke,
    borderColor,
    backgroundColor: borderColor,
  };
}

function getWrapperContainerStyle(
  { focused, errored, disabled }: InputInternalSpecificProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let borderColor = theme.color.control.border.base;
  let backgroundColor = theme.color.control.bg.weak.base;

  if (focused) {
    borderColor = theme.color.control.border.active;
  }

  if (errored) {
    borderColor = theme.color.negative.border.base;
  }

  if (disabled) {
    borderColor = theme.color.control.border.disabled;
    backgroundColor = theme.color.control.bg.weak.disabled;
  }

  return {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: theme.SizeInputBorderStroke,
    borderRadius: theme.SizeInputBorderRadius,
    borderColor,
    backgroundColor,
  };
}

function getTextInputStyle(
  { height, maxHeight, multiline }: InputInternalSpecificProps,
  theme: Theme,
): StyleProp<TextStyle> {
  return {
    fontFamily: "Inter",
    flex: 1,
    minHeight: 42,
    height,
    maxHeight: maxHeight ?? 250,
    paddingHorizontal: theme.SizeInputPaddingHorizMd,
    color: theme.color.default.text.base,
    paddingTop: multiline ? 12 : undefined,
  };
}

function InputInternalForward<T extends ValueType>(
  {
    label,
    additionalContext,
    focused,
    errored,
    disabled,
    height,
    maxHeight,
    rightAccessory,
    multiline,
    showClear = true,
    value,
    defaultValue,
    optionality,
    onChangeText,
    ...rest
  }: InputInternalProps<T>,
  ref: Ref<InputRefFunctions>,
) {
  const theme = useMagneticTheme();

  const inputRef = useRef<TextInput>(null);

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
    clear: () => inputRef.current?.clear(),
    isFocused: () => inputRef.current?.isFocused() ?? false,
  }));

  const [internalValue, setInternalValue] = useState<ValueType>(value);
  const internalProps = {
    label,
    additionalContext,
    focused,
    errored,
    disabled,
    height,
    maxHeight,
    multiline,
  };

  return (
    <View style={{ width: "100%" }}>
      {label && (
        <Box paddingTop="2xs" paddingLeft="xs" flexDirection="row">
          <Text size="p3" weight="semiBold">
            {label}
          </Text>
          {optionality === "required" && <Text color="negative.text.active">*</Text>}
          {optionality === "optional" && <Text color="light"> {I18n.t("OPTIONAL_HINT")}</Text>}
        </Box>
      )}
      <View style={getOuterBorderStyle(internalProps, theme)}>
        <View style={getWrapperContainerStyle(internalProps, theme)}>
          <TextInput
            {...rest}
            value={value?.toString()}
            defaultValue={defaultValue?.toString()}
            onChangeText={(text) => {
              if (typeof value === "number") {
                onChangeText?.(Number(text) as T);
                setInternalValue(text);
                return;
              }
              onChangeText?.(text as T);
              setInternalValue(text);
            }}
            editable={!disabled}
            style={getTextInputStyle(internalProps, theme)}
            placeholderTextColor={theme.color.default.text.weak.base}
            multiline={multiline}
            ref={inputRef}
          />
          {showClear && !isEmpty(internalValue) && !multiline && focused && !disabled && (
            <View style={{ marginRight: 5 }}>
              <Button.Icon
                icon="XCircle"
                onPress={() => {
                  inputRef.current?.clear();
                  setInternalValue(undefined);
                }}
              />
            </View>
          )}
          {rightAccessory && <View style={{ marginRight: 5 }}>{rightAccessory}</View>}
          {errored && (
            <View style={{ marginRight: 5 }}>
              <Alert status="negative" size={15} />
            </View>
          )}
        </View>
      </View>
      {additionalContext && <ExposedContext context={additionalContext} />}
    </View>
  );
}

const ExposedContext = ({ context }: { context: string | ReactElement }) => (
  <Box paddingTop="2xs" paddingLeft="xs">
    {typeof context === "string" ? <Text color="light">{context}</Text> : context}
  </Box>
);

export const InputInternal = forwardRefFC(InputInternalForward);
