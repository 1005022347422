import GoColors from "~/go/constants/GoColors";
import SharedColors from "~/shared/constants/SharedColors";
import ThemeInterface from "~/shared/themes/ThemeInterface";

// empty {} to take component defined styles
const Light: ThemeInterface = {
  colors: {
    merakiGreen: SharedColors.green,
  },
  navigation: {
    primary: GoColors.purple,
    secondary: GoColors.navyLighter,
    backgroundPrimary: SharedColors.white,
    backgroundSecondary: SharedColors.white,
    tabBorder: SharedColors.gray70Lighter,
  },
  modal: {
    background: SharedColors.white,
  },
  status: {
    online: {
      color: GoColors.teal,
      background: SharedColors.greenLightest,
    },
    offline: {
      color: GoColors.red,
      background: SharedColors.redLightest,
    },
    alerting: {
      color: GoColors.yellow,
      background: SharedColors.yellowLightest,
    },
    dormant: {
      color: GoColors.navyLighter,
      background: SharedColors.gray50,
    },
    enabledStatus: GoColors.purple,
  },
  spinner: {
    color: SharedColors.purpleSpinnerTransparent,
  },
  spinnerColor: {
    backgroundColor: SharedColors.whiteFaded80,
  },
  graph: {
    gridColor: SharedColors.gray90,
    barChartBackground: GoColors.navyLightest,
  },
  locationAnalyticsLegend: {
    selectedLabel: {
      color: SharedColors.gray30,
    },
    unselectedLabel: {
      color: GoColors.navyLighter,
    },
  },
  border: {
    borderColor: SharedColors.gray80,
    strongBorderColor: SharedColors.gray80,
  },
  tileBorderColor: SharedColors.gray90Darker,
  text: {
    accordianHeader: {
      color: GoColors.navy,
    },
    title: {
      color: undefined,
    },
    heading: {
      color: GoColors.navy,
    },
    subheader: {
      color: GoColors.navy,
    },
    subheading: {
      color: GoColors.navy,
    },
    small: {
      color: GoColors.navy,
    },
    placeholder: {
      color: SharedColors.gray40,
    },
    disabled: {
      color: GoColors.navyLighter,
    },
    default: {
      color: GoColors.navy,
    },
    defaultBold: {
      color: GoColors.navy,
    },
    smallSecondary: {
      color: SharedColors.gray40,
    },
    active: {
      color: SharedColors.gray40,
    },
    inactive: {
      color: SharedColors.gray88,
    },
  },
  closeButton: SharedColors.trueBlack,
  selectedColor: {
    backgroundColor: SharedColors.gray90,
  },
  infoCard: {
    backgroundColor: GoColors.navyTransparent,
  },
  iconBanner: {
    positive: {
      color: GoColors.greenBar,
      backgroundColor: SharedColors.greenLightest,
    },
    warning: {
      color: GoColors.yellow,
      backgroundColor: SharedColors.yellowLightest,
    },
    negative: {
      color: GoColors.red,
      backgroundColor: SharedColors.redLightest,
    },
    informational: {
      color: SharedColors.tagBlue3,
      backgroundColor: SharedColors.tagBlue1,
    },
  },
  localAddressing: {
    backgroundColor: SharedColors.gray90Lighter,
  },
  frontPanel: {
    backgroundColor: SharedColors.white,
  },
  roundedButtons: {
    destructive: {
      button: {},
      buttonText: {},
    },
    secondary: {
      button: {
        backgroundColor: null,
        borderColor: GoColors.purple,
      },
      buttonText: {},
    },
  },
  selectedRowColor: SharedColors.purpleLighter,
  searchBar: {},
  gridItem: {
    borderColor: SharedColors.gray80Lighter,
    backgroundColor: SharedColors.gray90Lighter,
  },
  graphColors: {
    barChartDefaultFill: SharedColors.teal,
    goodBar: GoColors.purple,
    online: GoColors.greenBar,
    offline: GoColors.redBar,
    lightGreenWarningStatus: GoColors.greenBar,
    alerting: GoColors.yellowBar,
    offlineBar: GoColors.navyLightest,
    applicationDetailsClientBar: SharedColors.green,
    pie1: GoColors.pieTeal1,
    pie2: GoColors.pieBlue2,
    pie3: GoColors.pieBlue1,
    pie4: GoColors.purple,
    pie5: SharedColors.chartPurple,
    pie6: SharedColors.tealLighter,
    pie7: SharedColors.gray50,
    other: SharedColors.teal,
    othergrey: SharedColors.gray70Lighter,
    // Included to pass UsagePieColorUtils.spec
    lpink: SharedColors.lpink,
    mgreen: SharedColors.mgreen,
    lblue: SharedColors.lblue,
  },
  port: {
    active: GoColors.teal,
    alerting: SharedColors.errorRed,
    disconnected: GoColors.navyLighter,
    disabled: GoColors.navyLightest,
    poeActive: SharedColors.white,
    uplinkActive: SharedColors.white,
  },
  icon: {
    tabBar: {
      selected: GoColors.purple,
    },
    backgroundColor: SharedColors.white,
    stroke: GoColors.navyLighter,
  },
  mapStatusColors: {
    online: GoColors.teal,
    alerting: GoColors.red,
    offline: SharedColors.errorRed,
    dormant: SharedColors.gray50,
  },
  IAQ: {
    excellent: "",
    good: "",
    fair: "",
    poor: "",
    inadequate: "",
  },
  accordian: {
    alert: {
      background: "",
      pressed: "",
      chevron: "",
      text: "",
    },
  },
};

export default Light;
