import { I18n } from "@meraki/core/i18n";
import { getPeerGraphDataGo } from "@meraki/go/monitor";
import { useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { Dimensions, StyleSheet, View } from "react-native";

import { SPACING, WINDOW } from "~/constants/MkiConstants";
import StatusIconWrapper from "~/enterprise/components/StatusIconWrapper";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import { MerakiVpnPeer, Outage } from "~/enterprise/types/VpnTypes";
import { Features } from "~/go/types/ContextHelpTypes";
import {
  getNetworkOfflinePeers,
  getNetworkOnlinePeers,
  getNetworkPeersStatus,
  getOutageData,
} from "~/selectors";
import CaptionBox, { CAPTION_BOX_WIDTH } from "~/shared/components/CaptionBox";
import MkiText from "~/shared/components/MkiText";
import { goMapPeerGraphDataToNonMagneticColors } from "~/shared/components/PeerGraphUtils";
import SingleBarGraph from "~/shared/components/SingleBarGraph";
import SummaryCard from "~/shared/components/SummaryCard";
import { GoStatus } from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

const CARD_PADDING_HORIZONTAL = SPACING.extraLarge;
const GRAPH_WIDTH_OFFSET = CARD_PADDING_HORIZONTAL + CAPTION_BOX_WIDTH;
const GRAPH_HEIGHT = 12;
const graphWidth = Dimensions.get(WINDOW).width - GRAPH_WIDTH_OFFSET;

export interface outageData {
  [id: string]: Outage[];
}

export interface SiteToSiteHealthOverviewProps {
  isLoading: boolean;
}

const sortByTime = (a: any, b: any) => a.ts - b.ts;

const GraphAndVPNs = () => {
  const timespan = useGlobalTimespan();
  const networkOnlinePeers = useAppSelector(getNetworkOnlinePeers) ?? 0;
  const networkOfflinePeers = useAppSelector(getNetworkOfflinePeers) ?? 0;
  const peers = useAppSelector(getNetworkPeersStatus);
  const outageData = useAppSelector(getOutageData);

  let noDataMessage;

  const displayStatus = networkOfflinePeers == 0 ? GoStatus.good : GoStatus.bad;

  const peersWithData = [] as Outage[];
  if (outageData != null && peers != null) {
    peers.forEach((peer: MerakiVpnPeer) => {
      if (outageData[peer.networkId] != null) {
        outageData[peer.networkId].forEach((dataPoint: Outage) =>
          peersWithData.push({
            ...dataPoint, // this avoids overwriting response data
            peerId: peer.networkId,
          }),
        );
      }
    });
  }

  peersWithData.sort(sortByTime);
  const magneticCombinedPeerGraphData = getPeerGraphDataGo(peersWithData, timespan);
  const combinedPeerGraphData = goMapPeerGraphDataToNonMagneticColors(
    magneticCombinedPeerGraphData,
  );

  if (combinedPeerGraphData.length === 0) {
    noDataMessage = I18n.t("VPN_HEALTH_OVERVIEW.NO_DATA");
  }

  return (
    <View style={styles.graphAndVpns}>
      <CaptionBox caption={I18n.t("VPN_HEALTH_OVERVIEW.OVERALL")}>
        <StatusIconWrapper status={displayStatus}>
          <MkiText>{`${networkOnlinePeers} / ${networkOnlinePeers + networkOfflinePeers}`}</MkiText>
        </StatusIconWrapper>
      </CaptionBox>
      {noDataMessage ? (
        <MkiText screenStyles={styles.noHealthText}>{noDataMessage}</MkiText>
      ) : (
        <SingleBarGraph data={combinedPeerGraphData} roundCorners graphStyles={styles.barGraph} />
      )}
    </View>
  );
};

const SiteToSiteHealthOverview = (props: SiteToSiteHealthOverviewProps) => {
  const { isLoading } = props;
  const peers = useAppSelector(getNetworkPeersStatus);

  const navigation = useNavigation();

  if (peers && peers.length > 0) {
    return (
      <SummaryCard
        heading={I18n.t("VPN_HEALTH_OVERVIEW.TITLE")}
        subheading={I18n.t("VPN_HEALTH_OVERVIEW.SUBHEADING")}
        context={Features.siteToSiteVPNHealthOverview}
        loading={isLoading}
        testID={"SITE_TO_SITE_HEALTH_OVERVIEW_CARD"}
      >
        <GraphAndVPNs />
        <DisclosureRow isFullButton onPress={() => navigation.navigate("PeersOverview", props)}>
          {I18n.t("VPN_HEALTH_OVERVIEW.SITE_DETAILS")}
        </DisclosureRow>
      </SummaryCard>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  noHealthText: {
    flex: 1,
    color: TEXT_COLORS.secondary,
  },
  graphAndVpns: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  barGraph: {
    width: graphWidth,
    height: GRAPH_HEIGHT,
  },
});

export default SiteToSiteHealthOverview;
