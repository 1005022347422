import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withOnboardingStatus from "~/hocs/OnboardingData";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import {
  addPushRecipientsToSettings,
  getChangedAlertSettings,
  isEnabledForUser,
  showAlert,
} from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig, getPluginHardwareMap } from "~/lib/OnboardingFullstackUtils";
import {
  alertSettingsFetching,
  alertSettingsState,
  currentUserState,
  getDownEmailRecipients,
  getDownPushRecipients,
  getGuestInsightsEmailRecipients,
  getGuestInsightsPushRecipients,
  getUsageEmailRecipients,
  getUserId,
  hasConfiguredWiFiSelector,
  isDownEnabled,
  isGuestInsightsEnabled,
  isUsageEnabled,
  onboardingDevices,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import NoData from "~/shared/components/NoData";
import { ExitButton } from "~/shared/navigation/Buttons";
import SwitchRow, { SwitchRowProps } from "~/shared/rows/SwitchRow";
import {
  AlertRowData,
  AlertSetting,
  AlertsSettings,
  GoAlertTypes,
} from "~/shared/types/AlertTypes";
import { OnboardingScannedDevices, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  alertSettingsFetching: boolean;
  alertSettings: AlertsSettings;
  downEnabled: boolean;
  downAlertEmailRecipients: string[];
  downAlertPushRecipients: string[];
  usageEnabled: boolean;
  usageAlertEmailRecipients: string[];
  guestInsightsEnabled: boolean;
  guestInsightsEmailRecipients: string[];
  guestInsightsPushRecipients: string[];
  currentUserEmail: string;
  currentUserId: string;
  hasConfiguredWiFi: boolean;
  scannedHardwareMap: OnboardingScannedDevices;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "OnboardingAlerts"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps &
  WithCancelablePromiseProps &
  PendingComponent;

export interface OnboardingAlertsScreenState {
  downEmail: null | boolean;
  downPush: null | boolean;
  usage: null | boolean;
  guestInsightsEmail: null | boolean;
  guestInsightsPush: null | boolean;
}

export class OnboardingAlertsScreen extends BaseOnboardingScreen<
  Props,
  OnboardingAlertsScreenState
> {
  static renderSectionHeader = ({ section }: { section: SectionListData<AlertRowData> }) => (
    <View style={styles.heading}>
      <MkiText textStyle="heading">{section.key}</MkiText>
    </View>
  );

  state = {
    downEmail: null,
    downPush: null,
    usage: null,
    guestInsightsEmail: null,
    guestInsightsPush: null,
  };

  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.alertsSettings);
  }

  onClosePress = () => {
    const { hasConfiguredWiFi } = this.props;
    if (hasConfiguredWiFi || !this.hasScannedWifi()) {
      this.close();
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.MESSAGE"),
        () => this.pushOnboardingScreen(OnboardingStage.wifiSetup),
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.CANCEL_BUTTON"),
        },
      );
    }
  };

  componentDidMount() {
    this.getData();
  }

  onRefresh = () => this.getData(true);

  getData(force = false) {
    const { actions, alertSettings } = this.props;
    if (isEmpty(alertSettings) || force) {
      actions.fetchAlertSettings();
    }
  }

  setSwitchValue = (alertType: GoAlertTypes, newValue: boolean) => {
    // @ts-expect-error setting state like this cannot tell that alertType is GoAlertTypes and not just a string
    this.setState({ [alertType]: newValue });
  };

  async saveAlerts() {
    const {
      actions,
      cancelablePromise,
      setReqPending,
      currentUserEmail,
      currentUserId,
      downAlertEmailRecipients,
      downAlertPushRecipients,
      usageAlertEmailRecipients,
      guestInsightsEmailRecipients,
      guestInsightsPushRecipients,
    } = this.props;
    const { downEmail, downPush, usage, guestInsightsEmail, guestInsightsPush } = this.state;

    let downSettings: AlertSetting[] = [];
    let usageSetting: AlertSetting[] = [];
    let guestInsightSetting: AlertSetting[] = [];

    if (downEmail != null) {
      downSettings = getChangedAlertSettings(
        GoAlertTypes.downEmail,
        downEmail,
        downAlertEmailRecipients,
        downAlertPushRecipients,
        currentUserEmail,
      );
    }

    if (downPush != null) {
      downSettings = addPushRecipientsToSettings(
        downSettings,
        GoAlertTypes.downPush,
        downPush,
        downAlertEmailRecipients,
        downAlertPushRecipients,
        currentUserId,
      );
    }

    if (usage != null) {
      usageSetting = getChangedAlertSettings(
        GoAlertTypes.usage,
        usage,
        usageAlertEmailRecipients,
        null,
        currentUserEmail,
      );
    }

    if (guestInsightsEmail != null) {
      guestInsightSetting = getChangedAlertSettings(
        GoAlertTypes.guestInsightsEmail,
        guestInsightsEmail,
        guestInsightsEmailRecipients,
        guestInsightsPushRecipients,
        currentUserEmail,
      );
    }

    if (guestInsightsPush != null) {
      guestInsightSetting = addPushRecipientsToSettings(
        guestInsightSetting,
        GoAlertTypes.guestInsightsPush,
        guestInsightsPush,
        guestInsightsEmailRecipients,
        guestInsightsPushRecipients,
        currentUserId,
      );
    }

    const changedAlertSettings = [...downSettings, ...usageSetting, ...guestInsightSetting];

    setReqPending(true);
    try {
      await cancelablePromise(actions.updateAlertSettings(changedAlertSettings));
      this.onPrimaryPress();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }

    this.setState({
      downEmail: null,
      downPush: null,
      usage: null,
      guestInsightsEmail: null,
      guestInsightsPush: null,
    });

    setReqPending(false);
  }

  getSwitchValueFor = (alertType: GoAlertTypes) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (this.state[alertType] != null) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return this.state[alertType];
    }

    const {
      currentUserEmail,
      currentUserId,
      downEnabled,
      downAlertEmailRecipients,
      downAlertPushRecipients,
      usageEnabled,
      usageAlertEmailRecipients,
      guestInsightsEnabled,
      guestInsightsEmailRecipients,
      guestInsightsPushRecipients,
    } = this.props;

    let enabled: boolean;
    let recipients: string[];
    let currentUser: string;

    switch (alertType) {
      case GoAlertTypes.downEmail:
        enabled = downEnabled;
        recipients = downAlertEmailRecipients;
        currentUser = currentUserEmail;
        break;
      case GoAlertTypes.downPush:
        enabled = downEnabled;
        recipients = downAlertPushRecipients;
        currentUser = currentUserId;
        break;
      case GoAlertTypes.usage:
        enabled = usageEnabled;
        recipients = usageAlertEmailRecipients;
        currentUser = currentUserEmail;
        break;
      case GoAlertTypes.guestInsightsEmail:
        enabled = guestInsightsEnabled;
        recipients = guestInsightsEmailRecipients;
        currentUser = currentUserEmail;
        break;
      case GoAlertTypes.guestInsightsPush:
        enabled = guestInsightsEnabled;
        recipients = guestInsightsPushRecipients;
        currentUser = currentUserId;
        break;
      default:
        enabled = false;
        recipients = [];
        currentUser = "";
        break;
    }

    return isEnabledForUser(enabled, recipients, currentUser);
  };

  renderRow = (rowData: AlertRowData) => {
    return (
      <View style={styles.row}>
        <MkiText textStyle="subheader" screenStyles={styles.rowHeader}>
          {rowData.label}
        </MkiText>
        {rowData.switches.map((switchRowProps: SwitchRowProps, index) => (
          <SwitchRow {...switchRowProps} screenStyles={styles.switchRow} key={index} />
        ))}
      </View>
    );
  };

  renderBody = () => {
    const { alertSettings, alertSettingsFetching } = this.props;
    if (isEmpty(alertSettings) && !alertSettingsFetching) {
      return <NoData onPress={() => this.getData(true)} />;
    }

    const data = {
      [I18n.t("NOTIFICATIONS.ALERTS.HEADING")]: [
        {
          label: I18n.t("NOTIFICATIONS.ALERTS.OFFLINE"),
          switches: [
            {
              subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
              value: this.getSwitchValueFor(GoAlertTypes.downEmail),
              onValueChange: (newValue: boolean) =>
                this.setSwitchValue(GoAlertTypes.downEmail, newValue),
              disabled: false,
              testID: "ALERTS.OFFLINE_EMAIL",
            },
            {
              subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
              value: this.getSwitchValueFor(GoAlertTypes.downPush),
              onValueChange: (newValue: boolean) =>
                this.setSwitchValue(GoAlertTypes.downPush, newValue),
              disabled: false,
              testID: "ALERTS.OFFLINE_PUSH",
            },
          ],
        },
        {
          label: I18n.t("NOTIFICATIONS.ALERTS.USAGE_EXCEEDED"),
          switches: [
            {
              subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
              value: this.getSwitchValueFor(GoAlertTypes.usage),
              onValueChange: (newValue: boolean) =>
                this.setSwitchValue(GoAlertTypes.usage, newValue),
              disabled: false,
              testID: "ALERTS.USAGE_EXCEEDED",
            },
          ],
        },
        {
          label: I18n.t("NOTIFICATIONS.ALERTS.GUEST_INSIGHTS"),
          switches: [
            {
              subtitle: I18n.t("NOTIFICATIONS.ALERTS.EMAIL_NOTIFICATION"),
              value: this.getSwitchValueFor(GoAlertTypes.guestInsightsEmail),
              onValueChange: (newValue: boolean) =>
                this.setSwitchValue(GoAlertTypes.guestInsightsEmail, newValue),
              disabled: false,
              testID: "ALERTS.GUEST_INSIGHTS_EMAIL",
            },
            {
              subtitle: I18n.t("NOTIFICATIONS.ALERTS.PUSH_NOTIFICATION"),
              value: this.getSwitchValueFor(GoAlertTypes.guestInsightsPush),
              onValueChange: (newValue: boolean) =>
                this.setSwitchValue(GoAlertTypes.guestInsightsPush, newValue),
              disabled: false,
              testID: "ALERTS.GUEST_INSIGHTS_PUSH",
            },
          ],
        },
      ],
    };

    return (
      <View style={styles.bodyContainer}>
        <MkiTable<AlertRowData>
          data={data}
          renderRow={this.renderRow}
          renderSectionHeader={OnboardingAlertsScreen.renderSectionHeader}
          keyExtractor={(_: unknown, index: number) => `${index}`}
          onRefresh={this.onRefresh}
          loading={alertSettingsFetching}
          testID="ALERTS.TABLE"
        />
      </View>
    );
  };

  getFooterData = () => ({
    buttons: [
      <RoundedButton key="nextButton" onPress={() => this.saveAlerts()}>
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    ],
  });

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.alertsSettings, {
    hasScannedWifi: this.hasScannedWifi(),
  });
}

const styles = StyleSheet.create({
  heading: {
    marginHorizontal: SPACING.small,
    marginTop: SPACING.large,
    paddingBottom: SPACING.large,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  bodyContainer: {
    flex: 1,
    margin: SPACING.tiny,
    alignItems: "center",
  },
  row: {
    paddingVertical: SPACING.small,
  },
  rowHeader: {
    paddingHorizontal: SPACING.default,
  },
  switchRow: {
    paddingVertical: SPACING.small,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    alertSettingsFetching: errorMonitor.notifyNonOptional(
      "param 'alertSettingsFetching' undefined for OnboardingAlertsScreen",
      alertSettingsFetching(state),
    ),
    alertSettings: alertSettingsState(state),
    downEnabled: isDownEnabled(state),
    downAlertEmailRecipients: getDownEmailRecipients(state),
    downAlertPushRecipients: getDownPushRecipients(state),
    usageEnabled: isUsageEnabled(state),
    usageAlertEmailRecipients: getUsageEmailRecipients(state),
    guestInsightsEnabled: isGuestInsightsEnabled(state),
    guestInsightsEmailRecipients: getGuestInsightsEmailRecipients(state),
    guestInsightsPushRecipients: getGuestInsightsPushRecipients(state),
    currentUserEmail: currentUserState(state),
    currentUserId: errorMonitor.notifyNonOptional(
      "param 'currentUserId' undefined for OnboardingAlertsScreen",
      getUserId(state),
    ),
    hasConfiguredWiFi: hasConfiguredWiFiSelector(state),
    scannedHardwareMap: getPluginHardwareMap(onboardingDevices(state)),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withOnboardingStatus,
  withPendingComponent,
)(OnboardingAlertsScreen);
