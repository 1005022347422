import { useBioauthEnabled } from "@meraki/core/bioauth";
import { useTheme } from "@meraki/core/theme";

import { biometricAuthState, currentUserState } from "~/selectors";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";

import useAppSelector from "../hooks/redux/useAppSelector";
import { RootState } from "../types/Redux";

/**
 * todo: delete me after this has been out on a release and theme
 * has been migrated to the new zustand store
 */
export const useMigrateThemeState = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(currentUserState);
  const theme =
    useAppSelector((state: RootState) =>
      currentUser ? state.preferences[currentUser]?.stagedTheme : undefined,
    ) ?? "system";
  const hasMigratedTheme = useAppSelector((state) => state.preferences.migratedTheme);

  if (!hasMigratedTheme) {
    let updatedTheme = theme;

    // Handle the rename of the theme
    if (theme === "lightDtmaly") {
      updatedTheme = "lightDeuteranomaly";
    }

    if (theme === "darkDtmaly") {
      updatedTheme = "darkDeuteranomaly";
    }

    useTheme.getState().setTheme(updatedTheme);
    dispatch({ type: "SET_MIGRATED_THEME_STATE" });
  }
};

/* 
  Function to migrate any data we need from redux to a new world without redux (outside of monolith)
*/
export const useMigrateStateData = () => {
  const dispatch = useAppDispatch();

  // TODO migrate data from BioAuth redux to zustand core/bioauth library
  const bioAuthState = useAppSelector(biometricAuthState);
  const hasMigratedBioauth = useAppSelector((state) => state.preferences.migratedBioauth);

  if (!hasMigratedBioauth) {
    useBioauthEnabled.getState().setEnabled(bioAuthState?.enabled ?? false);
    dispatch({ type: "SET_MIGRATED_BIOAUTH" });
  }
};
