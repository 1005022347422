import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  size?: number;
};

function WaterIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  const { stroke } = useThemeColors().icon;
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" fill="none">
      <Path
        d="M19.5966 13.9332C19.5966 7.18323 12.0966 1.93323 12.0966 1.93323C12.0966 1.93323 4.59656 7.18323 4.59656 13.9332C4.59656 15.9224 5.38673 17.83 6.79326 19.2365C8.19978 20.6431 10.1074 21.4332 12.0966 21.4332C14.0857 21.4332 15.9933 20.6431 17.3999 19.2365C18.8064 17.83 19.5966 15.9224 19.5966 13.9332Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.856 18.3583C13.7726 18.2029 14.6185 17.7668 15.2767 17.1102C15.935 16.4536 16.3732 15.6089 16.531 14.6926"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default WaterIcon;
