import { I18n } from "@meraki/core/i18n";
import {
  Protocol,
  SwitchQoSRuleBody,
  useCreateSwitchQoSRule,
  useSwitchQoSRules,
  useUpdateSwitchQoSRule,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useLayoutEffect, useReducer } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { errorMessageParser } from "~/api/util/error";
import { SPACING } from "~/constants/MkiConstants";
import PortRangeTextInput from "~/go/components/textInputs/PortRangeTextInput";
import { SettingsStackProps } from "~/go/navigation/Types";
import RadioSelectionRow from "~/go/rows/RadioSelectionRow";
import { showAlert } from "~/lib/AlertUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import MkiTextInput from "~/shared/components/MkiTextInput";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SwitchQoSRule">;

const getPortsAttributes = (
  port: number | undefined | null,
  portRange: string | undefined | null,
) => {
  if (port) {
    return { startingPort: port, endingPort: undefined };
  } else if (portRange) {
    const [port1, port2] = portRange.split("-");
    return {
      startingPort: parseInt(port1),
      endingPort: parseInt(port2),
    };
  } else {
    return { startingPort: undefined, endingPort: undefined };
  }
};

const updateRule = (state: SwitchQoSRuleBody, partial: Partial<SwitchQoSRuleBody>) => {
  const newBody = { ...partial };
  if (partial.protocol === "any") {
    newBody.srcPort = undefined;
    newBody.srcPortRange = undefined;
    newBody.dstPort = undefined;
    newBody.dstPortRange = undefined;
  }
  return { ...state, ...newBody };
};

export function SwitchQoSRuleScreen({ rule }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  const networkId = useCurrentNetworkId();
  const queryClient = useQueryClient();

  const isEditRule = !!rule;

  const [qosRule, updateQosRule] = useReducer(
    updateRule,
    !rule
      ? {
          vlan: undefined,
          protocol: "tcp",
          srcPort: undefined,
          srcPortRange: undefined,
          dstPort: undefined,
          dstPortRange: undefined,
          dscp: undefined,
        }
      : { ...rule },
  );
  const { vlan, protocol, srcPort, srcPortRange, dstPort, dstPortRange, dscp } = qosRule;

  const createSwitchQoSRule = useCreateSwitchQoSRule();
  const editSwitchQoSRule = useUpdateSwitchQoSRule();

  useEffect(() => {
    updateQosRule(rule ?? {});
  }, [rule]);

  const onSave = useCallback(() => {
    const body = qosRule;
    const responseFunctions = {
      onSuccess: () => {
        navigation.goBack();
        queryClient.refetchQueries({ queryKey: useSwitchQoSRules.queryKeyRoot });
      },
      onError: (error: unknown) => {
        const errorMessage = errorMessageParser(error);
        showAlert(I18n.t("ERROR"), errorMessage);
      },
    };
    if (isEditRule) {
      editSwitchQoSRule.mutate({ networkId, ruleId: rule.id, body }, responseFunctions);
    } else {
      createSwitchQoSRule.mutate({ networkId, body }, responseFunctions);
    }
  }, [
    createSwitchQoSRule,
    editSwitchQoSRule,
    isEditRule,
    navigation,
    networkId,
    qosRule,
    queryClient,
    rule?.id,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} />,
    });
  }, [navigation, onSave]);

  return (
    <FullScreenContainerView withScroll defaultPadding>
      <LoadingSpinner visible={editSwitchQoSRule.isLoading || createSwitchQoSRule.isLoading} />
      <MkiText textStyle={"secondary"}>{I18n.t("SWITCH_QOS_RULE.VLAN.TITLE")}</MkiText>
      <MkiTextInput
        value={`${vlan ?? ""}`}
        screenStyles={styles.vlanSpacing}
        onChangeText={(newVlan: string) =>
          updateQosRule({ vlan: newVlan === "" ? null : Number(newVlan) })
        }
        placeholder={"1"}
      />
      <MkiText screenStyles={styles.dscpSpacing} textStyle={"secondary"}>
        {I18n.t("SWITCH_QOS_RULE.DSCP.TITLE")}
      </MkiText>
      <MkiTextInput
        value={`${dscp ?? ""}`}
        onChangeText={(newDcsp: string) =>
          updateQosRule({ dscp: newDcsp === "" ? null : Number(newDcsp) })
        }
        placeholder={"0"}
      />

      <MkiText screenStyles={styles.protocolSpacing} textStyle={"secondary"}>
        {I18n.t("SWITCH_QOS_RULE.PROTOCOL.TITLE")}
      </MkiText>
      <RadioSelectionRow
        radioStyle={styles.radioContainer}
        options={[
          { label: I18n.t("SWITCH_QOS_RULE.PROTOCOL.VALUES.TCP"), value: Protocol.tcp },
          { label: I18n.t("SWITCH_QOS_RULE.PROTOCOL.VALUES.UDP"), value: Protocol.udp },
          {
            label: I18n.t("SWITCH_QOS_RULE.PROTOCOL.VALUES.ANY"),
            value: Protocol.any,
            sublabel: I18n.t("SWITCH_QOS_RULE.PROTOCOL.WARNING"),
          },
        ]}
        onSelect={(protocol) => updateQosRule({ protocol })}
        selectedValue={protocol?.toLocaleLowerCase()}
      />
      <PortRangeTextInput
        title={I18n.t("SWITCH_QOS_RULE.SRC_PORT")}
        onPortsChange={(start, end) => {
          if (start && end) {
            updateQosRule({
              srcPort: undefined,
              srcPortRange: `${start}-${end}`,
            });
          } else {
            updateQosRule({
              srcPort: start ?? undefined,
              srcPortRange: undefined,
            });
          }
        }}
        testID="SRC_PORT"
        {...getPortsAttributes(srcPort, srcPortRange)}
      />
      <PortRangeTextInput
        title={I18n.t("SWITCH_QOS_RULE.DST_PORT")}
        onPortsChange={(start, end) => {
          if (start && end) {
            updateQosRule({
              dstPort: undefined,
              dstPortRange: `${start}-${end}`,
            });
          } else {
            updateQosRule({
              dstPort: start ?? undefined,
              dstPortRange: undefined,
            });
          }
        }}
        testID="DST_PORT"
        {...getPortsAttributes(dstPort, dstPortRange)}
      />
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  vlanSpacing: {
    marginVertical: SPACING.small,
  },
  protocolSpacing: {
    marginTop: SPACING.small,
  },
  dscpSpacing: {
    marginVertical: SPACING.small,
  },
  radioContainer: {
    marginVertical: SPACING.default,
  },
});
