import { ColorToken, mapColorToken, Theme } from "@meraki/magnetic/themes";

export const chartColorTokens: ColorToken[] = [
  "accent.a.base",
  "accent.b.base",
  "accent.c.base",
  "accent.d.base",
  "accent.e.base",
  "accent.f.base",
  "accent.g.base",
  "accent.h.base",
  "accent.i.base",
  "accent.j.base",
];

export const baseChartColors: Record<string, ColorToken> = {
  toolTipBorder: "default.border.strong.base",
  toolTipBackground: "default.bg.weak.base",
  toolTipText: "default.text.base",
  axisLines: "default.text.medium.base",
  axisText: "default.text.medium.base",
  gridLines: "control.border.weak.base",
  thresholdLines: "default.border.medium.base",
} as const;

export function getColorTokenForIndex(index: number): ColorToken {
  return chartColorTokens[index % chartColorTokens.length] ?? "accent.a.base";
}

export function getColorForIndex(index: number, theme: Theme): string | undefined {
  const colorToken = getColorTokenForIndex(index);
  return mapColorToken(colorToken, theme);
}

export function getColorList(theme: Theme) {
  return chartColorTokens.reduce<string[]>((acc, colorToken) => {
    const colorHex = mapColorToken(colorToken, theme);
    if (colorHex) {
      acc.push(colorHex);
    }
    return acc;
  }, []);
}
