import { I18n, IS_ON_JAPANESE } from "@meraki/core/i18n";
import { pppoeUrl } from "@meraki/go/links";
import { isEmpty } from "lodash";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { GoSharedScreensPropMap } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig, getPluginHardwareMap } from "~/lib/OnboardingFullstackUtils";
import {
  hasConfiguredWiFiSelector,
  onboardingDevices,
  onboardingIsComplete,
  showUmbrellaOnboarding,
} from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import IndicatorViewPager from "~/shared/components/viewPager/IndicatorViewPager";
import { ExitButton } from "~/shared/navigation/Buttons";
import { ProductType } from "~/shared/types/Networks";
import { OnboardingScannedDevices, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const PLUG_IN_PAGE_DATA = [
  {
    title: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.GATEWAY_TO_MODEM.TITLE"),
    description: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.GATEWAY_TO_MODEM.DESCRIPTION"),
    pppoe: IS_ON_JAPANESE ? I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.GATEWAY_TO_MODEM.PPPOE") : null,
    diagram: require("~/images/plugInDiagrams/gx-into-modem.png"),
    productType: ProductType.appliance,
  },
  {
    title: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.SWITCH_TO_UPSTREAM.TITLE"),
    description: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.SWITCH_TO_UPSTREAM.DESCRIPTION"),
    diagram: require("~/images/plugInDiagrams/gs-into-modem.png"),
    productType: ProductType.switch,
  },
  {
    title: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.AP_PLACEMENT.TITLE"),
    description: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.AP_PLACEMENT.DESCRIPTION"),
    diagram: require("~/images/plugInDiagrams/hardwire-vs-mesh.png"),
    productType: ProductType.wireless,
  },
  {
    title: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.AP_TO_UPSTREAM.TITLE"),
    description: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.AP_TO_UPSTREAM.DESCRIPTION"),
    diagram: require("~/images/plugInDiagrams/gr-into-gs.png"),
    productType: ProductType.wireless,
  },
  {
    title: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.MESH_AP_PLACEMENT.TITLE"),
    description: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.MESH_AP_PLACEMENT.DESCRIPTION"),
    diagram: require("~/images/plugInDiagrams/gr-mesh.png"),
    productType: ProductType.wireless,
  },
];

// For smaller devices, the ScrollView will try to squeeze the content into
// a view with height less than 485. This will cause the image and text to
// overlap. This number is relative to the static heights of the images.
const MIN_SCROLL_VIEW_HEIGHT = 485;

type ReduxProps = {
  hasConfiguredWiFi: boolean;
  onboardingComplete: boolean;
  showUmbrella: boolean;
  scannedHardwareMap: OnboardingScannedDevices;
};

type Props = ForwardedNativeStackScreenProps<GoSharedScreensPropMap, "PlugIn"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

interface PlugInScreenState {
  scrollable: boolean;
}

export class PlugInScreen extends BaseOnboardingScreen<Props, PlugInScreenState> {
  static defaultProps = {
    isOnboarding: true,
    productType: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = { scrollable: false };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, isOnboarding } = this.props;

    if (isOnboarding) {
      navigation.setOptions({
        headerRight: () => <ExitButton onPress={this.onClosePress} />,
      });
    }
  }

  onboardingComplete = () => {
    const { onboardingComplete } = this.props;
    return onboardingComplete;
  };

  showUmbrella = () => {
    const { showUmbrella } = this.props;
    return showUmbrella;
  };

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.plugIn);
  }

  onClosePress = () => {
    const { hasConfiguredWiFi } = this.props;
    if (hasConfiguredWiFi || !this.hasScannedWifi()) {
      this.close();
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.MESSAGE"),
        this.showWiFiCreate,
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.CANCEL_BUTTON"),
        },
      );
    }
  };

  showWiFiCreate = () => {
    this.pushOnboardingScreen(OnboardingStage.wifiSetup);
  };

  renderPPPOELinkText = (pppoeText: React.ReactNode) => {
    if (!pppoeText) {
      return null;
    }
    return (
      <MkiText
        onPress={pppoeUrl}
        textStyle="smallSecondary"
        screenStyles={[styles.pppoeText, styles.descriptionText]}
      >
        {pppoeText}
      </MkiText>
    );
  };

  pages = () => {
    const { scannedHardwareMap, productType } = this.props;
    return PLUG_IN_PAGE_DATA.filter((data) =>
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      productType ? productType === data.productType : scannedHardwareMap[data.productType],
    ).map((data, index) => (
      <View style={styles.pageContainer} key={index}>
        <MkiText textStyle="heading" screenStyles={styles.title}>
          {data.title}
        </MkiText>
        <Image style={styles.diagram} source={data.diagram} resizeMode="contain" />
        <View>
          <MkiText textStyle="smallSecondary" screenStyles={styles.descriptionText}>
            {data.description}
          </MkiText>
          {this.renderPPPOELinkText(data.pppoe)}
        </View>
      </View>
    ));
  };

  onScrollViewContentSizeChange = (_: unknown, height: number) => {
    if (height === MIN_SCROLL_VIEW_HEIGHT) {
      this.setState({ scrollable: true });
    }
  };

  renderBody = () => {
    const { scrollable } = this.state;
    const pages = this.pages();

    if (isEmpty(pages)) {
      return (
        <MkiText textStyle="heading" screenStyles={styles.headingText}>
          {I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.NO_HARDWARE_MESSAGE")}
        </MkiText>
      );
    }

    return (
      <ScrollView
        scrollEnabled={scrollable}
        contentContainerStyle={styles.scrollViewContainer}
        onContentSizeChange={this.onScrollViewContentSizeChange}
        testID="PLUG_IN_SCREEN"
      >
        <IndicatorViewPager style={styles.viewPager}>{pages}</IndicatorViewPager>
      </ScrollView>
    );
  };

  getFooterData = () => ({
    buttons: this.props.isOnboarding
      ? [
          <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
            {this.nextStageConfig.nextButtonText}
          </RoundedButton>,
        ]
      : [],
  });

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.plugIn, {
    onboardingComplete: this.onboardingComplete(),
    showUmbrella: this.showUmbrella(),
  });
}

const styles = StyleSheet.create({
  diagram: {
    alignSelf: "center",
    borderRadius: BUTTON_SIZING.borderRadius.default,
  },
  descriptionText: {
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  scrollViewContainer: {
    flex: 1,
    minHeight: MIN_SCROLL_VIEW_HEIGHT,
  },
  viewPager: {
    flex: 1,
  },
  pppoeText: {
    paddingVertical: SPACING.extraLarge,
    color: MkiColors.goPurple,
  },
  headingText: {
    textAlign: "center",
    marginHorizontal: SPACING.extraLarge,
    marginTop: SPACING.large,
    marginBottom: SPACING.default,
  },
  pageContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    paddingHorizontal: SPACING.default,
    marginTop: SPACING.default,
    paddingBottom: SPACING.large,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasConfiguredWiFi: hasConfiguredWiFiSelector(state),
    scannedHardwareMap: getPluginHardwareMap(onboardingDevices(state)),
    showUmbrella: showUmbrellaOnboarding(state),
    onboardingComplete: onboardingIsComplete(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(PlugInScreen);
