import { I18n } from "@meraki/core/i18n";
import { useSsids } from "@meraki/shared/api";
import { PickerCard, PickerOption } from "@meraki/shared/components";
import { ALL_SSIDS, setHomeSSID, useCurrentNetworkId, useHomeSSID } from "@meraki/shared/redux";
import { useDispatch } from "react-redux";

export function SSIDPickerCard() {
  const networkId = useCurrentNetworkId();
  const currentSSIDNumber = useHomeSSID();
  const dispatch = useDispatch();
  const { data: availableSSIDs } = useSsids(
    { networkId },
    {
      select: (data) => {
        const formattedSsids: PickerOption<number>[] = [];
        const enabledSsids = data.filter((ssid) => ssid.enabled);
        enabledSsids.forEach((ssid) => {
          formattedSsids.push({ label: ssid.name, value: ssid.number });
        });
        return formattedSsids;
      },
    },
  );

  if (availableSSIDs && availableSSIDs.length > 1) {
    availableSSIDs.unshift({
      label: "All SSIDs",
      value: ALL_SSIDS,
    });
  }

  const currentSSID = availableSSIDs?.find((ssid) => ssid.value === currentSSIDNumber);

  return (
    <PickerCard
      options={availableSSIDs}
      selectedOption={currentSSID}
      onSelectOption={({ value: homeSSID }) => dispatch(setHomeSSID(homeSSID))}
      title={I18n.t("SSID_PICKER_TITLE")}
      emptyTitle={I18n.t("SSID_PICKER_EMPTY")}
      testID="SSID_PICKER_CARD"
    />
  );
}
