import { memo, useContext } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { TEXT_STYLE_NAME } from "~/constants/MkiTextStyles";
import { ThemeContext } from "~/lib/themeHelper";
import { isString } from "~/lib/TypeHelper";
import MkiText from "~/shared/components/MkiText";

interface SectionHeaderProps {
  children?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: TEXT_STYLE_NAME;
}

export const SectionHeader = memo<SectionHeaderProps>(
  ({ textStyle = "tableHeader", children, style }) => {
    const headerText = isString(children) ? children.toUpperCase() : "";
    const { backgroundColor } = useContext(ThemeContext);

    return (
      <View style={[{ backgroundColor }, styles.container, style]}>
        <MkiText textStyle={textStyle}>{headerText}</MkiText>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    paddingTop: SPACING.default,
    paddingBottom: SPACING.tiny,
    paddingLeft: SPACING.default,
  },
});

export default SectionHeader;
