import { I18n } from "@meraki/core/i18n";
import { memo } from "react";
import { View } from "react-native";

import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";

export interface SSIDCreateFormProps {
  name: string;
  password: string;
  onChangeName: (name: string) => void;
  onChangePassword: (password: string) => void;
}

const SSIDCreateForm = memo<SSIDCreateFormProps>(function SSIDCreateForm(props) {
  const { name, password, onChangeName, onChangePassword } = props;

  return (
    <View>
      <UnderlinedTextInput
        title={I18n.t("CREATE_SSID.NAME.TITLE")}
        value={name}
        placeholder={I18n.t("CREATE_SSID.NAME.PLACEHOLDER")}
        onChangeText={onChangeName}
        testID={"NEW_SSID.NAME"}
        showClearButton
      />
      <UnderlinedTextInput
        secureTextEntry
        revealable={true}
        showClearButton
        title={I18n.t("CREATE_SSID.PASSWORD.TITLE")}
        footer={I18n.t("CREATE_SSID.PASSWORD.FOOTER")}
        placeholder={I18n.t("CREATE_SSID.PASSWORD.PLACEHOLDER")}
        value={password}
        onChangeText={onChangePassword}
      />
    </View>
  );
});

export default SSIDCreateForm;
