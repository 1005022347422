import { setPendingAppLink } from "~/actions";
import { fetchAndSwitchToDeviceNetwork } from "~/actions/devices";
import { currentNetworkState } from "~/selectors";
import logEvent from "~/shared/lib/analytics";
import { AppLinkSource } from "~/shared/navigation/Linking";
import { navRef } from "~/shared/navigation/NavigationRef";
import { store } from "~/store/configureStore";

export async function navigateToDeviceDetails(
  ngEid: string,
  deviceId: number,
  timestamp?: number,
  source: AppLinkSource = "push_notification",
) {
  const curNetworkId = currentNetworkState(store.getState());
  //@ts-ignore
  const { serial } = await store.dispatch(fetchAndSwitchToDeviceNetwork(deviceId, ngEid));

  const postDeviceFetchNetworkId = currentNetworkState(store.getState());

  logEvent("app_deeplink_redirect", { source });

  if (curNetworkId === postDeviceFetchNetworkId) {
    navRef.navigate("DeviceDetails", { serial, timestamp });
  } else {
    store.dispatch(setPendingAppLink("DeviceDetails", { serial, timestamp }));
  }
}
