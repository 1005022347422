import { I18n } from "@meraki/core/i18n";
import { BottomSheet, BottomSheetMethods, Button, List, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useAssignGoLicense,
  useAssignLicense,
  useDeviceBySerial,
  useDeviceStatuses,
  useGetLicenses,
  useMoveLicense,
  useOrganizations,
  useOrgNetworks,
} from "@meraki/shared/api";
import { addHyphensForLicenseKey } from "@meraki/shared/formatters";
import { LicenseGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { useRef, useState } from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";

import {
  ActiveAndQueuedStatuses,
  ActiveStatuses,
  getFormattedStringFromDays,
} from "../utils/LicenseUtils";

export function LicenseScreen() {
  const route = useRoute<RouteProp<LicenseGroupProps, "License">>();
  const { params: props } = route;
  const organizationId = useCurrentOrganizationId();
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const reassignLicense = useAssignLicense();
  const moveLicense = useMoveLicense();
  const assignLicense = useAssignGoLicense();

  const { data: networks } = useOrgNetworks({ organizationId });

  const [bottomSheetType, setBottomSheetType] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");

  const { data: devices } = useDeviceStatuses({
    organizationId,
    networkIds: selectedNetwork ? [selectedNetwork] : undefined,
  });

  const { data: organizations } = useOrganizations({});

  const gxDevice = devices?.find((device) => device.productType === "appliance");
  const { data: gxDeviceBySerial } = useDeviceBySerial({ serial: gxDevice?.serial });

  const isUsed =
    props.license.deviceSerial != null && ActiveAndQueuedStatuses.includes(props.license.state);
  const isActive = ActiveStatuses.includes(props.license.state);

  const canAct =
    (isUsed &&
      (props.license.headLicenseId === null || props.license.state === "recentlyQueued")) ||
    (!isUsed && props.license.headLicenseId === null);

  const assign = () => {
    assignLicense.mutate(
      { licenseId: props.license.id, deviceSerial: gxDeviceBySerial?.id ?? null },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
        },
        onError: (error) => {
          Alert.alert(String(error["error"]));
        },
      },
    );
  };

  const unassign = () => {
    reassignLicense.mutate(
      { organizationId, licenseId: props.license.id, deviceSerial: null },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  const move = (orgId: string) => {
    moveLicense.mutate(
      { organizationId, licenseIds: [props.license.id], destOrganizationId: orgId },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          queryClient.invalidateQueries({ queryKey: useGetLicenses.queryKeyRoot });
        },
        onError: (error) => {
          Alert.alert(String(error["errors"]));
        },
      },
    );
  };

  return (
    <Screen addDefaultPadding>
      <List>
        <List.Item
          title={I18n.t("UMBRELLA.SUBSCRIPTION.LICENSE_KEY")}
          rightAccessory={
            <Text size="p1" color="light">
              {props.license.licenseKey && addHyphensForLicenseKey(props.license.licenseKey)}
            </Text>
          }
        />
        <List.Item
          title={I18n.t("UMBRELLA.SUBSCRIPTION.DURATION")}
          rightAccessory={
            <Text size="p1" color="light">
              {getFormattedStringFromDays(props.license.totalDurationInDays ?? 0)}
            </Text>
          }
        />
      </List>
      {canAct && (
        <Box paddingTop="sm" gap="sm">
          {!isUsed && (
            <Button
              text={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.ASSIGN")}
              onPress={() => {
                setBottomSheetType("assign");
                bottomSheetRef.current?.present();
              }}
            />
          )}
          {!isUsed && !isActive && organizations && organizations.length > 1 && (
            <Button
              text={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.MOVE")}
              onPress={() => {
                setBottomSheetType("move");
                bottomSheetRef.current?.present();
              }}
            />
          )}
          {isUsed && (
            <Button
              text={I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.UNASSIGN")}
              onPress={() => unassign()}
            />
          )}
        </Box>
      )}
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header
          title={
            bottomSheetType === "assign"
              ? I18n.t("UMBRELLA.SUBSCRIPTION.SELECT_NETWORK")
              : I18n.t("UMBRELLA.SUBSCRIPTION.SELECT_ORG")
          }
          onCancelPress={() => {
            bottomSheetType === "assign" ? setSelectedOrg("") : setSelectedNetwork("");
            bottomSheetRef.current?.dismiss();
          }}
        />
        <BottomSheet.Content>
          {bottomSheetType === "assign" ? (
            <List.FlashList
              paddingLeft="xs"
              paddingRight="xs"
              data={networks}
              getItemData={(network) => {
                return {
                  kind: "radio",
                  checkedRadioValue: selectedNetwork,
                  radioValue: network.id,
                  onRadioValueChanged: () => {
                    setSelectedNetwork(network.id);
                  },
                  title: network.name,
                };
              }}
            />
          ) : (
            <List.FlashList
              paddingLeft="xs"
              paddingRight="xs"
              data={organizations}
              getItemData={(org) => {
                return {
                  kind: "radio",
                  radioValue: org.id,
                  checkedRadioValue: selectedOrg,
                  onRadioValueChanged: () => setSelectedOrg(org.id),
                  title: org.name,
                };
              }}
            />
          )}
          <Box paddingTop="sm">
            <Button
              text={I18n.t("UMBRELLA.SUBSCRIPTION.SELECT")}
              onPress={() => {
                bottomSheetType === "assign" ? assign() : move(selectedOrg);
              }}
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
}
