import { I18n } from "@meraki/core/i18n";
import { getProductType } from "@meraki/shared/devices";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { sizeSelect } from "~/lib/themeHelper";
import { ssidsOnAccessPoint } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { ProductType } from "~/shared/types/Networks";

export const DeleteHardwareButton = ({ device, reqPending, onDelete }: any) => {
  const isWireless = getProductType(device?.model) === ProductType.wireless;
  const ssids = useAppSelector((state) => ssidsOnAccessPoint(state, device));
  const noSSIDsBroadcasted = ssids.length === 0;

  let warningMessage = I18n.t("HARDWARE_SCREEN.DELETE.DELETE_CONFIRMATION_MESSAGE");
  if (isWireless && !noSSIDsBroadcasted) {
    const ssidNameList = ssids.map((ssid) => ssid.name).join(", ");
    warningMessage = I18n.t("HARDWARE_SCREEN.DELETE.DELETE_CONFIRMATION_MESSAGE_WIRELESS", {
      deviceName: device.name,
      ssidNameList: ssidNameList,
    });
  }

  const onButtonPress = () => {
    showAlert(I18n.t("WARNING"), warningMessage, onDelete, {
      positiveText: I18n.t("DELETE"),
      negativeText: I18n.t("CANCEL"),
    });
  };

  return (
    <RoundedButton
      onPress={onButtonPress}
      buttonType={ButtonType.destructive}
      screenStyles={styles.button}
      disabled={reqPending}
      testID="DELETE_HARDWARE_BUTTON"
    >
      {I18n.t("DELETE")}
    </RoundedButton>
  );
};

const styles = StyleSheet.create({
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
});

export default DeleteHardwareButton;
