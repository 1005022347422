import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ organizationId }: UplinkStatusesRequestBuildUrl) => {
  return `/api/v1/organizations/${organizationId}/uplinks/statuses`;
};

export const UplinkStatusStateSchema = z.union([
  z.literal("active"),
  z.literal("ready"),
  z.literal("connecting"),
  z.literal("not connected"),
  z.literal("failed"),
]);

interface UplinkStatusesRequest {
  organizationId?: string;
}

interface UplinkStatusesRequestBuildUrl {
  organizationId: string;
}

export const UplinkStatusesSchema = z.array(
  z.object({
    networkId: z.string(),
    serial: z.string(),
    model: z.string(),
    lastReportedAt: z.string(),
    highAvailability: z.object({ enabled: z.boolean(), role: z.string() }),
    uplinks: z.array(
      z.object({
        interface: z.string(),
        status: UplinkStatusStateSchema,
        ip: z.string(),
        gateway: z.string(),
        publicIp: z.string(),
        primaryDns: z.string(),
        secondaryDns: z.string(),
        ipAssignedBy: z.string(),
        provider: z.string(),
        signalStat: z.object({ rsrp: z.string(), rsrq: z.string() }),
        connectionType: z.string(),
        apn: z.string(),
        dns1: z.string(),
        dns2: z.string(),
        signalType: z.string(),
        iccid: z.string(),
      }),
    ),
  }),
);

export type UplinkStatues = z.infer<typeof UplinkStatusesSchema>;
export type UplinkStatusState = z.infer<typeof UplinkStatusStateSchema>;

const fetchUplinkStatuses = ({ organizationId }: UplinkStatusesRequest) => {
  if (!organizationId) {
    throw Error("OrganizationId is not defined");
  }

  return request(UplinkStatusesSchema, "GET", buildUrl({ organizationId }));
};

export const useUplinkStatuses = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: UplinkStatusesRequest) => fetchUplinkStatuses(parameters),
  requiredVariables: ["organizationId"],
});
