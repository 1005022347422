import { I18n } from "@meraki/core/i18n";
import { Text } from "@meraki/magnetic/components";
import { LoginResponseType, verifyIsLoginResponse } from "@meraki/shared/api";
import { OTPScreen } from "@meraki/shared/auth";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { APP_KEY } from "~/i18n/translationUtils";
import { showAlert } from "~/lib/AlertUtils";
import { currentUserState } from "~/selectors";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "OTPAuth">;

export function OTPAuthScreen() {
  const navigation = useNavigation<Props["navigation"]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);

  const { submitOTPAuth, resendOTPCode } = useActions();

  const handleResendCodePress = () => {
    setIsLoadingResend(true);
    resendOTPCode()
      .then(() => {
        showAlert(
          I18n.t("TWO_FACTOR_AUTH.EMAIL_RESENT_SUCCESS_TITLE"),
          I18n.t("TWO_FACTOR_AUTH.EMAIL"),
        );
      })
      .catch((error) => {
        showAlert(I18n.t("ERROR"), error);
      })
      .finally(() => {
        setIsLoadingResend(false);
      });
  };

  const handleVerifyPress = (pinCode: string) => {
    setIsLoading(true);
    submitOTPAuth(pinCode)
      .then((response?: LoginResponseType) => {
        // if everything succeeded during login and there is no followup, then nothing is returned
        // from the loginUser call and we have nothing to handle post login
        if (!response) {
          return;
        }

        if (verifyIsLoginResponse(response)) {
          navigation.navigate("VerifyEmail", { response });
          return;
        }

        const { mode } = response;
        if (mode === "org_choose") {
          navigation.navigate("OrgChoose", { initialLogin: true });
        }
      })
      .catch((error) => {
        showAlert(I18n.t("ERROR"), error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const currentUserEmail = useAppSelector(currentUserState);

  return (
    <OTPScreen
      submitOTPAuth={handleVerifyPress}
      resendOTPCode={handleResendCodePress}
      isLoading={isLoading}
      isResendingCode={isLoadingResend}
      analytics={[
        {
          event: "onPressResend" as const,
          eventName: "resend_otp_code",
          params: { src: "login" },
        },
        {
          event: "onPressVerify" as const,
          eventName: "verify_otp_code",
          params: { src: "login" },
        },
      ]}
    >
      <Text size="p1">{I18n.t(`TWO_FACTOR_AUTH.OTP.${APP_KEY}`, { email: currentUserEmail })}</Text>
    </OTPScreen>
  );
}
