import { useMemo } from "react";

import * as DashboardActions from "~/actions";
import useAppDispatch from "~/shared/hooks/redux/useAppDispatch";
import { patchedBindActionCreators } from "~/shared/types/Redux";

const useActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => patchedBindActionCreators(DashboardActions, dispatch), [dispatch]);
};

export default useActions;
