export interface RegistrationForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  company: string;
  company_size: string;
  industry: string;
  country: string;
  time_zone: string;
  tips: boolean;
  terms: boolean;
}

export interface RegistrationResult {
  response: {
    marketo_api_token: string;
    shard_id: string;
    success: boolean;
  };
}

export enum EmailVerificationErrors {
  invalidRequest = "invalid_request",
  alreadyVerified = "already_verified",
  alreadyConfirmed = "already_confirmed",
  expiredToken = "expired_token",
  invalidToken = "invalid_token",
}

export const TimezoneRegexByRegion = {
  NA: /^(America|Canada|US)/,
  SA: /^(America|Atlantic|Brazil|Chile|Mexico|US)/,
  EMEA: /^(Africa|Antarctica|Arctic|Europe|Indian)/,
  APAC: /^(Asia|Australia|Indian|Pacific)/,
  CN: /^(Asia)/,
};
