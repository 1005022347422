import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import LiveBroker, { Subscription } from "../LiveBroker";
import LiveToolContext, { LiveToolStatus } from "../LiveToolContext";
import {
  deviceSubscribe,
  DeviceSubscriptionProps,
  networkSubscribe,
  NetworkSubscriptionProps,
} from "../Subscriptions";
import Commands from "../types/Commands";
import DataMsg from "../types/DataMsg";
import LiveTool from "../types/LiveToolMsg";
import {
  isCompletableDataMsg,
  isDeviceSubscriptionProps,
  isNetworkSubscriptionProps,
} from "../utils";

type SubscriptionProps<P> = (DeviceSubscriptionProps | NetworkSubscriptionProps) | P;
type MsgCallback<M> = (msg: M) => void;

function useLiveBrokerSubscription<P, M extends DataMsg>(
  props: SubscriptionProps<P>,
): (msgCallback: MsgCallback<M>) => Promise<Subscription> {
  return useCallback((msgCallback) => {
    if (isDeviceSubscriptionProps(props)) {
      return deviceSubscribe(props, msgCallback);
    }

    if (isNetworkSubscriptionProps(props)) {
      return networkSubscribe(props, msgCallback);
    }

    throw Error("Unable to discern subscription type from props");
  }, []);
}

function useLiveBrokerSubscriptionHandler<M extends DataMsg>(
  subscription: (msgCallback: MsgCallback<M>) => Promise<Subscription>,
  onStatusChange: (status: LiveToolStatus) => void,
  completeImmediately: boolean,
): [M | undefined, Commands] {
  const [message, setMessage] = useState<M | undefined>();
  const subscriptionRef = useRef<Subscription | undefined>();

  const unsubscribe = useCallback((completed = false) => {
    if (subscriptionRef.current) {
      LiveBroker.unsubscribe(subscriptionRef.current)
        .then(() => {
          subscriptionRef.current = undefined;
          onStatusChange(completed ? LiveToolStatus.completed : LiveToolStatus.idle);
        })
        .catch((_e) => onStatusChange(LiveToolStatus.error));
    }
  }, []);

  const commands: Commands = useMemo(() => {
    return {
      subscribe: async () => {
        setMessage(undefined);
        if (subscriptionRef.current) {
          await LiveBroker.resubscribe(subscriptionRef.current);
        } else {
          onStatusChange(LiveToolStatus.connecting);
          let isRunning = false;

          try {
            subscriptionRef.current = await subscription((msg) => {
              if (!isRunning) {
                isRunning = true;
                onStatusChange(LiveToolStatus.running);
              }

              setMessage(msg);

              if (completeImmediately || (isCompletableDataMsg(msg) && msg.data.completed)) {
                unsubscribe(true);
              }
            });
          } catch (e) {
            onStatusChange(LiveToolStatus.error);
          }
        }
      },
      unsubscribe,
    };
  }, []);

  useEffect(() => {
    return unsubscribe;
  }, []);

  return [message, commands];
}

function useLiveBroker<P, M extends DataMsg>(
  props: SubscriptionProps<P>,
  completeImmediately = false,
): LiveTool<M> {
  const { command, onStatusChange } = useContext(LiveToolContext);

  const subscription = useLiveBrokerSubscription<P, M>(props);
  const [msg, commands] = useLiveBrokerSubscriptionHandler<M>(
    subscription,
    onStatusChange,
    completeImmediately,
  );

  useEffect(() => {
    switch (command) {
      case "start":
      case "restart":
        commands.subscribe();
        break;
      case "stop":
        commands.unsubscribe();
        break;
    }
  }, [command]);

  return msg;
}

export default useLiveBroker;

export const testables = {
  useLiveBrokerSubscription,
  useLiveBrokerSubscriptionHandler,
};
