import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const LocalStatus = z.object({
  client: z.object({
    ip: z.string(),
    mac: z.string(),
    radio: z.string(),
    band: z.string(),
    channel: z.string(),
    rssi: z.string(),
    channel_width: z.string(),
    wireless_mode: z.string(),
    max_device_bitrate: z.string(),
  }),
  config: z.object({
    mac: z.string(),
    iswireless: z.boolean(),
    iswired: z.boolean(),
    iswd: z.boolean(),
    isswitch: z.boolean(),
    ismrunderdog: z.boolean(),
    iscellulargateway: z.boolean(),
    node_name: z.string(),
    network_name: z.string(),
    product_model: z.string(),
    claimed: z.boolean(),
  }),
  connection_state: z.object({
    config_fetched: z.boolean(),
    is_wired_gateway: z.boolean(),
    state: z.string(),
    wired_ip: z.string(),
    wired_ip6: z.string(),
    upgrading: z.boolean(),
    dns: z.boolean(),
    inet: z.boolean(),
    wan_inet: z.boolean(),
    using_nextunnel: z.boolean(),
    mtun: z.boolean(),
    nextunnel: z.boolean(),
    lan: z.boolean(),
    ipconflict: z.boolean(),
  }),
  radio_stats: z.array(
    z.object({
      radio_number: z.number(),
      band: z.string(),
      channel: z.number(),
      prof_tf: z.number(),
      prof_rf: z.number(),
      prof_rc: z.number(),
      prof_cc: z.number(),
      prof_ec: z.number(),
    }),
  ),
  local_ssids: z.object({ local_ssids: z.string() }),
});

export type LocalStatusType = z.infer<typeof LocalStatus>;

const fetchLocalStatusData = async () => {
  return await request(LocalStatus, "GET", "/index.json", {
    baseUrl: "http://10.128.128.126",
  });
};

export const useLocalStatus = createQuery({
  baseQueryKey: "http://10.128.128.126/index.json",
  queryFn: () => fetchLocalStatusData(),
  cacheTime: 0,
  retry: 0,
});
