import { createSelector } from "reselect";

import { isClientOnline } from "~/lib/ClientUtils";
import { verifyIsNetworkClient } from "~/lib/TypeHelper";
import { clientsSelector } from "~/selectors/clients";

export const getSystemsManagerOnlineCount = createSelector(clientsSelector, (clients) => {
  let onlineCount = 0;

  Object.values(clients).forEach((client) => {
    if (!verifyIsNetworkClient(client) && isClientOnline(client)) {
      onlineCount++;
    }
  });

  return onlineCount;
});

export const getSystemsManagerOfflineCount = createSelector(clientsSelector, (clients) => {
  let offlineCount = 0;

  Object.values(clients).forEach((client) => {
    if (!verifyIsNetworkClient(client) && !isClientOnline(client)) {
      offlineCount++;
    }
  });

  return offlineCount;
});
