import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { Button, Input, Loader, Tag, Text, Toggle } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  SupportCaseInteraction,
  useAddSupportCaseComment,
  useAttachFileToSupportCase,
  useCloseSupportCase,
  useOrgNetworks,
  useSupportCaseInfo,
  useSupportCases,
} from "@meraki/shared/api";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { FlashList } from "@shopify/flash-list";
import React, { useEffect, useState } from "react";
import { Alert } from "react-native";

import { AttachFileButton, FileAttachment } from "../components/AttachFileButton";
import { SupportCaseMessage } from "../components/SupportCaseMessage";
import {
  cleanSupportMessageText,
  fromSupportCaseTsToDate,
  SUPPORT_CASE_DEFAULT_NAME,
} from "../utils/SupportCaseUtils";
import { useFavoriteSupportCasesStore } from "./SupportCasesListScreen";

const MAX_SUBJECT_LENGTH = 27;

export function SupportCaseDetailsScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackProps>>();
  const route = useRoute<RouteProp<HelpStackProps, "SupportCaseDetails">>();
  const { supportCase } = route.params;
  const { caseNumber } = supportCase;
  const { subject, description, createdDate, status } = supportCase;
  const isClosed = status === "Closed";

  const { favoriteCaseIds, setFavoriteCaseIds } = useFavoriteSupportCasesStore();

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const [replyMessage, setReplyMessage] = useState<string>("");
  const [file, setFile] = useState<FileAttachment>();

  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId);
      },
    },
  );
  const {
    data: caseInfo,
    isLoading,
    refetch: refetchCaseInfo,
  } = useSupportCaseInfo({
    caseNumber,
    encryptedNetworkId: network?.eid,
  });
  const { mutate: addCaseCommentMutation, isLoading: addCaseCommentIsLoading } =
    useAddSupportCaseComment();
  const { mutate: closeCaseMutation, isLoading: closeCaseIsLoading } = useCloseSupportCase();
  const { mutate: attachFileToCaseMutation, isLoading: attachFileIsLoading } =
    useAttachFileToSupportCase();

  const disabledInputs = closeCaseIsLoading || addCaseCommentIsLoading || attachFileIsLoading;

  useEffect(() => {
    navigation.setOptions({
      headerTitle:
        subject.length > MAX_SUBJECT_LENGTH
          ? `${subject.substring(0, MAX_SUBJECT_LENGTH)}...`
          : subject,
      headerRight: () => {
        return isClosed ? null : (
          <Button.Icon
            icon="ArchiveBox"
            disabled={disabledInputs}
            onPress={() =>
              Alert.alert(
                isClosed
                  ? I18n.t("SUPPORT_CASE_DETAILS.REOPEN_CASE.BUTTON")
                  : I18n.t("SUPPORT_CASE_DETAILS.CLOSE_CASE.BUTTON"),
                isClosed
                  ? I18n.t("SUPPORT_CASE_DETAILS.REOPEN_CASE.MESSAGE")
                  : I18n.t("SUPPORT_CASE_DETAILS.CLOSE_CASE.MESSAGE"),
                [
                  {
                    text: I18n.t("OK"),
                    onPress: () => {
                      closeCaseMutation(
                        {
                          encryptedNetworkId: network?.eid,
                          caseNumber,
                          userClosed: status !== "Closed",
                        },
                        {
                          onSettled: () => {
                            queryClient.invalidateQueries({
                              queryKey: useSupportCases.queryKeyRoot,
                            });
                            navigation.pop();
                          },
                        },
                      );
                    },
                  },
                  {
                    text: I18n.t("CANCEL"),
                  },
                ],
              )
            }
          />
        );
      },
    });
  }, [
    caseNumber,
    closeCaseMutation,
    disabledInputs,
    isClosed,
    navigation,
    network?.eid,
    status,
    subject,
  ]);

  const sendComment = () => {
    if (replyMessage) {
      try {
        if (file !== undefined) {
          attachFileToCaseMutation(
            {
              encryptedNetworkId: network?.eid,
              body: { file: file.content, filename: file.name, case_number: caseNumber },
            },
            {
              onError: () => showErrorAlert(I18n.t("SERVER_ERROR_TEXT")),
              onSuccess: () => {
                addCaseCommentMutation(
                  {
                    encryptedNetworkId: network?.eid,
                    caseNumber,
                    commentText: `${replyMessage ? `${replyMessage}\n\n` : ""}Attached: ${
                      file.name
                    }`,
                  },
                  {
                    onSuccess: () => refetchCaseInfo(),
                    onError: () => showErrorAlert(I18n.t("SERVER_ERROR_TEXT")),
                  },
                );
              },
            },
          );
        } else {
          addCaseCommentMutation(
            {
              encryptedNetworkId: network?.eid,
              caseNumber,
              commentText: replyMessage,
            },
            {
              onSuccess: () => refetchCaseInfo(),
              onError: () => showErrorAlert(I18n.t("SERVER_ERROR_TEXT")),
            },
          );
        }
        setReplyMessage("");
        setFile(undefined);
      } catch (error) {
        showErrorAlert(I18n.t("SERVER_ERROR_TEXT"));
      }
    }
  };

  const setFavoriteCase = () => {
    let newFavoriteCaseIds = [];
    if (favoriteCaseIds.includes(caseNumber)) {
      newFavoriteCaseIds = favoriteCaseIds.filter((value) => value !== caseNumber);
    } else {
      newFavoriteCaseIds = [...favoriteCaseIds, caseNumber];
    }
    setFavoriteCaseIds(newFavoriteCaseIds);
  };

  const possibleUserNames = supportCase.suppliedName
    ? [supportCase.contactName, supportCase.suppliedName]
    : [supportCase.contactName];
  const timeSinceCreation = formatDate(fromSupportCaseTsToDate(supportCase.createdDate), {
    dateFormat: "date",
  });

  let formattedCommentsList = caseInfo?.interactions ? caseInfo.interactions : [];
  if (description !== undefined && description !== null) {
    const cleanedDescription = description.split("----------")[0]?.trim();

    const descriptionComment: SupportCaseInteraction = {
      commentBody: cleanedDescription,
      createdDate,
      createdByName: SUPPORT_CASE_DEFAULT_NAME,
      type: "comment",
      preview: "",
    };

    formattedCommentsList = formattedCommentsList.concat(descriptionComment);
  }

  return (
    <Screen.View addDefaultPadding paddingBottom="sm">
      {disabledInputs && <Loader.Spinner animate={true} testID="ACTION_LOADING_SPINNER" />}
      <Box flexDirection="row" justifyContent="space-between" testID="DETAILS_HEADER">
        <Box>
          <Text size="p2">{I18n.t("SUPPORT_CASE_DETAILS.GOv3.STATUS")}</Text>
          <Tag type="status" status={isClosed ? "inactive" : "positive"}>
            {isClosed
              ? I18n.t("SUPPORT_CENTER.STATUS.CLOSED")
              : I18n.t("SUPPORT_CENTER.STATUS.OPEN")}
          </Tag>
        </Box>
        <Box>
          <Text size="p2">{I18n.t(`SUPPORT_CENTER.GOv3.ROW_DESCRIPTION.OPEN`)}</Text>
          <Text size="p1" weight="bold">
            {timeSinceCreation}
          </Text>
        </Box>
        <Box>
          <Text size="p2">{I18n.t("SUPPORT_CASE_DETAILS.GOv3.FAVORITE_BUTTON")}</Text>
          <Toggle
            checked={favoriteCaseIds.includes(caseNumber)}
            onValueChange={setFavoriteCase}
            disabled={disabledInputs}
            testID="FAVORITE_TOGGLE"
          />
        </Box>
      </Box>
      {isLoading ? (
        <Box flex={1} justifyContent="center">
          <Loader.Spinner animate={true} testID="ACTION_LOADING_SPINNER" />
        </Box>
      ) : (
        <FlashList
          inverted={true}
          estimatedItemSize={100}
          showsVerticalScrollIndicator={false}
          data={formattedCommentsList}
          renderItem={({ item: interaction }) => {
            const messageText = interaction.commentBody ?? interaction.textBody;

            const cleanedMessageBody = cleanSupportMessageText(messageText, "bubble");
            if (cleanedMessageBody) {
              return SupportCaseMessage({
                possibleUserNames: possibleUserNames,
                comment: { ...interaction, commentBody: cleanedMessageBody },
              });
            }
            return null;
          }}
          keyExtractor={(_, index) => index.toString()}
          testID="SUPPORT_CASE_MESSSAGES"
        />
      )}
      {isClosed ? null : (
        <Box flexDirection="row" alignItems="flex-end" gap="xs" testID="ADD_COMMENT_FOOTER">
          <Box flex={1}>
            <Input
              value={replyMessage}
              onChangeText={(text: string) => setReplyMessage(text)}
              placeholder={I18n.t("SUPPORT_CASE_DETAILS.GOv3.COMMENT_PLACEHOLDER")}
              multiline
              maxHeight={75}
              disabled={disabledInputs}
              testID="MESSAGE_INPUT"
              rightAccessory={
                <Box flex={1} justifyContent="flex-end" paddingRight="xs" paddingBottom="sm">
                  <AttachFileButton
                    fileSelected={file !== undefined}
                    isDisabled={disabledInputs}
                    setFile={setFile}
                  />
                </Box>
              }
            />
          </Box>
          <Box paddingBottom="xs">
            <Button.Icon
              icon="ArrowFatRight"
              color={`interact.icon.${disabledInputs ? "disabled" : "base"}`}
              disabled={disabledInputs}
              onPress={sendComment}
              testID={"SEND_BUTTON"}
            />
          </Box>
        </Box>
      )}
    </Screen.View>
  );
}
