import { DateFormatOptions, formatDate } from "@meraki/core/date";
import { PureComponent } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import * as RNLocalize from "react-native-localize";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { formatTimeStringToDate, roundTimeToNearestQuarter } from "~/lib/formatHelper";
import { getCurrentLocale } from "~/selectors";
import MkiText from "~/shared/components/MkiText";

import { RootState } from "../types/Redux";

type ReduxProps = {
  currentLocale: string;
};

export interface MkiDatePickerProps extends ReduxProps {
  dateLabel?: Date;
  disabled?: boolean;
  testID?: string;
  format: DateFormatOptions;
  date: Date;
  mode?: DateTimePickerModes;
  containerStyles?: StyleProp<ViewStyle>;
  disabledContainerStyles?: StyleProp<ViewStyle>;
  textColor?: string;
  textStyle?: StyleProp<ViewStyle>;
  icon?: React.ReactNode;
  onConfirm: (date: any) => void;
  onHide?: ({ minutes, hours }: { minutes: number; hours: number }) => void;
  onPress?: () => {};
}

export interface MkiDatePickerState {
  isVisible: boolean;
}

export enum DateTimePickerModes {
  time = "time",
  date = "date",
  datetime = "datetime",
}

export class MkiDatePicker extends PureComponent<MkiDatePickerProps, MkiDatePickerState> {
  static defaultProps = {
    format: { dateFormat: "longDate" },
  };

  constructor(props: MkiDatePickerProps) {
    super(props);
    this.state = { isVisible: false };
  }

  showDatePicker = () => {
    const { onPress } = this.props;
    this.setState({ isVisible: true });
    onPress?.();
  };

  onHide = (date: any) => {
    const { onHide } = this.props;
    this.setState({ isVisible: false });

    if (onHide) {
      onHide(date);
    }
  };

  onCancel = () => {
    this.setState({ isVisible: false });
  };

  onDateConfirm = (date: any) => {
    const { onConfirm } = this.props;

    const dateObject = date.date;
    const newDate = new Date(dateObject.toDateString());
    this.onHide(newDate);
    onConfirm(newDate);
  };

  onTimeConfirm = (date: any) => {
    const { hours, minutes } = roundTimeToNearestQuarter(date);
    this.onHide(date);
    const { onConfirm } = this.props;
    const time = formatTimeStringToDate(`${hours}:${minutes < 10 ? `0${minutes}` : minutes}`);
    onConfirm(time);
  };

  formatDateTime = () => {
    const { format, dateLabel } = this.props;

    if (dateLabel) {
      return formatDate(dateLabel, { ...format, timeZone: RNLocalize.getTimeZone() });
    } else {
      return I18n.t("SELECT_DATE");
    }
  };

  render() {
    const {
      icon,
      currentLocale,
      date,
      mode,
      disabled,
      disabledContainerStyles,
      containerStyles,
      testID,
      textStyle,
    } = this.props;
    const { isVisible } = this.state;
    const datetimeString = this.formatDateTime();
    const touchableStyle = disabled
      ? [styles.disabledContainer, styles.inputContainer, disabledContainerStyles]
      : [styles.inputContainer, containerStyles];
    const dateStringTextStyle = textStyle ? textStyle : styles.textAlign;

    return (
      <TouchableOpacity
        style={touchableStyle}
        onPress={this.showDatePicker}
        disabled={disabled}
        testID={testID}
      >
        <MkiText screenStyles={dateStringTextStyle}>{datetimeString}</MkiText>
        {mode === DateTimePickerModes.time ? (
          <TimePickerModal
            {...this.props}
            hours={date.getHours()}
            minutes={date.getMinutes()}
            visible={isVisible}
            onConfirm={this.onTimeConfirm}
            onDismiss={this.onCancel}
          />
        ) : (
          <DatePickerModal
            {...this.props}
            mode="single"
            locale={currentLocale}
            visible={isVisible}
            onConfirm={this.onDateConfirm}
            onDismiss={this.onCancel}
          />
        )}
        {icon}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: SPACING.small,
    borderBottomWidth: 1,
    borderBottomColor: MkiColors.borderColor,
  },
  disabledContainer: {
    opacity: 0.5,
  },
  textAlign: {
    marginBottom: SPACING.small,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    currentLocale: getCurrentLocale(state),
  };
}

export default connect(mapStateToProps)(MkiDatePicker);
