/**
 * @deprecated use "~/api/schemas/CameraConfig" instead
 */
export type CameraConfig = {
  zoom: number;
  focus: number;
  aperture: number;
  rotation: number;
  crop_x: number;
  crop_y: number;
  crop_w: number;
  crop_h: number;
  brightness: number;
  contrast: number;
  saturation: number;
  sharpness: number;
  hdr: boolean;
  record_audio: boolean;
  bitrate: number;
  frame_rate: number;
  video_height: number;
  night_mode: string; // TODO: Could be a enum of mode settings (e.g.: "auto" | ...)
  ir_led: boolean;
  mlux_day: number;
  mlux_night: number;
  motion_based_retention: boolean;
  motion_recap_enabled: boolean;
  schedule_id: any; // TODO: type it better, response came back as null so :shrug: for now
  max_retention: any; // TODO: type it better, response came back as null so :shrug: for now
  camera_client_ssid: any; // TODO: type it better, response came back as null so :shrug: for now
  camera_client_psk: any; // TODO: type it better, response came back as null so :shrug: for now
  overlay_rectangles: string; // FIXME: Looks like string of object :monocle_face:
  mqtt_broker_config_id: string;
  person_model_provider: number;
  profile_id: any; // TODO: type it better, response came back as null so :shrug: for now
  motion_detector_version: number;
  external_rtsp_enabled: boolean;
  fisheye: boolean;
  varifocal: boolean;
  spyglass: boolean;
  local_video_storage_capacity: number;
  max_motor_positions: {}; // TODO: Maybe make a subtype here? this is one response: { zoom: 1400; focus: 5900; aperture: 86 };
  has_alexandria: boolean;
  has_sharpless: boolean;
  pretty_has_alexandria: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  pretty_has_sharpless: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  id: string;
  supported_detected_object_types: string[]; // This might not be truthful + We may also want an enum for the object types
  supported_detected_object_type_plurals: {}; // This could be better typed, maybe with help from the enum for object types (response: { person: "people" })
  has_model_switcher: boolean;
  supported_person_models: {}[]; // This could be better. Here's a response example: { value: 0; label: "Body (default)"; name: "Body"; provider: 0 },{ value: 1; label: "Body (experimental)"; name: "Body"; provider: 1;}
  has_extended_storage: boolean;
  pretty_zoom: string; // percent
  pretty_focus: string; // percent
  pretty_aperture: string; // percent
  pretty_rotation: string;
  pretty_crop_x: number;
  pretty_crop_y: number;
  pretty_crop_w: number;
  pretty_crop_h: number;
  pretty_brightness: string; // percent
  pretty_contrast: string; // percent
  pretty_saturation: string; // percent
  pretty_sharpness: string; // percent
  pretty_hdr: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  pretty_record_audio: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  pretty_bitrate: string;
  pretty_frame_rate: string;
  pretty_video_height: number;
  pretty_night_mode: string; // TODO: Could be a enum of mode settings (e.g.: "auto" | ...)
  pretty_ir_led: string; // TODO: Could be an enum (e.g.: "Off" | ....)
  pretty_mlux_day: string;
  pretty_mlux_night: string;
  pretty_motion_based_retention: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  pretty_motion_recap_enabled: string; // TODO: maybe make an enum? (e.g.: "Disabled" | "Enabled" | ...);
  pretty_schedule_id: string;
  pretty_max_retention: any; // TODO: type it better, response came back as null so :shrug: for now
  pretty_camera_client_ssid: any; // TODO: type it better, response came back as null so :shrug: for now
  pretty_camera_client_psk: string;
  pretty_tunnel_host_region: string;
  pretty_overlay_rectangles: {};
  pretty_mqtt_broker_config_id: string;
  pretty_person_model_provider: number;
  pretty_profile_id: any; // TODO: type it better, response came back as null so :shrug: for now
  pretty_motion_detector_version: number;
  pretty_external_rtsp_enabled: boolean;
  tunnel_host: string;
};

export interface HistoricalVideoFile {
  channel_id: string;
  end_time: number;
  id: string;
  local: boolean;
  metadata: {
    channel: string;
    end: number;
    expiry: number;
    height: number;
    id: string;
    length: number;
    on_camera: boolean;
    size: number;
    src: string;
    start: number;
    storage_provider: string;
    width: number;
  };
  metadata_backup?: string;
  requested_ts: number;
  s3_transfer_requested_at: number;
  start_time: number;
  video_status: string;
  video_status_reason?: string;
}

export type VideoMode = "live" | "stored" | "liveBuffer";

export type WirelessProfilesSet = {
  ids: {
    primary: number;
    secondary: number | null;
    backup?: number;
  };
};

export type RegionOfInterest = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export type PointData = {
  id: number;
  x: number;
  y: number;
};

export type RoiArr = { [index: string]: RegionOfInterest };

export type MotionEvent = {
  duration_ms: number;
  has_people: boolean;
  img_url: string[];
  intensity_frame: number;
  intensity_roi: number;
  motion: number[];
  // This is in seconds, be VERY careful about that
  // FIXME: convert this to ms as soon as we get it from the server
  // so we don't have to deal with it everywhere.
  ts: number;
  unconsolidated_motion: number[][];
};

export type QueuedVideoFile = {
  startTime: number;
  lengthMS: number;
  // video file position in MS, a number from 0 to ~30000
  positionMS: number;
};

export type FlatListItem = {
  id: number;
  text: string;
};

export interface TimeIntervalItem extends FlatListItem {
  timeRangeMS: number;
}

export interface PlaybackRateItem extends FlatListItem {
  rate: number;
}

export interface VideoSettingItem extends FlatListItem {
  iconName: string;
}

export type VideoStatus = {
  videoMode: VideoMode;
  isPlaying: boolean;
  offset: number;
  clockStartTS: number;
  initialDelta: number;
  isLoading: boolean;
  isDewarped: boolean;
  isMuted: boolean;
};

export enum VideoSettingId {
  PLAYBACK_SPEED = 0,
}

export enum SettingPageId {
  VIDEO_SETTINGS = 0,
  PLAYBACK_RATES = 1,
}

export type VideoSettings = {
  showMenu: boolean;
  rateId: PlaybackRateId;
  currentVideoSettingPageId: SettingPageId;
};

export enum TimeIntervalId {
  CUSTOM = 0,
  LAST_30_MINS = 1,
  LAST_HOUR = 2,
  LAST_6_HOURS = 3,
  LAST_12_HOURS = 4,
  LAST_DAY = 5,
  LAST_3_DAYS = 6,
  LAST_WEEK = 7,
}

export type SearchInterval = {
  startTime: number | null;
  endTime: number | null;
  currentPage: number;
  totalPages: number;
};

export type MotionSearch = {
  selectedEventTimestamp: number | null;
  isLoading: boolean;
  searchInterval: SearchInterval;
  roi: RoiArr | null;
  polygonPoints: PointData[] | null;
  minEventLength: number;
  withPeople: boolean;
  sensitivity: number;
};

export enum PlaybackRateId {
  RATE_0_5x = 0,
  NORMAL = 1,
  RATE_2x = 2,
}

export type AvailabilityStatus = "available" | "unavailable" | "pending";

export type Availability = {
  status: AvailabilityStatus;
  error: string | null;
};

export type StreamType = "localLan" | "cloudProxy" | "unknown";
