import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useLayoutEffect, useState } from "react";
import { LayoutAnimation, SectionListData, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { AccessPolicyTypes } from "~/api/schemas/SwitchPort";
import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showActionSheet } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import useEditSwitchPort from "~/shared/hooks/switch/useEditSwitchPort";
import { CloseButton, DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "SwitchPortsVlan">;

interface MACAddressData {
  mac: string;
}

export const MACAllowListScreen = ({ switchPort, portIds, onSave }: Props) => {
  setupAndroidLayoutAnimation();

  const [stagedSwitchPort, _, updateStagedSwitchPortByKey, hasDiff] = useEditSwitchPort(switchPort);
  const isSingleSwitchPort = portIds.length === 1;
  const description = isSingleSwitchPort
    ? I18n.t("MAC_ALLOW_LIST.HEADER.DESCRIPTION.SINGLE", { port: portIds[0] })
    : I18n.t("MAC_ALLOW_LIST.HEADER.DESCRIPTION.MANY", {
        list_of_ports: portIds.join(", "),
      });

  const updateAccessPolicyType = updateStagedSwitchPortByKey("accessPolicyType");
  const updateMacAllowList = updateStagedSwitchPortByKey("macAllowList");

  const [isEditMode, setIsEditMode] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    if (
      !stagedSwitchPort.macAllowList &&
      stagedSwitchPort.accessPolicyType !== AccessPolicyTypes.open
    ) {
      updateAccessPolicyType(AccessPolicyTypes.open);
    } else if (
      stagedSwitchPort.macAllowList &&
      stagedSwitchPort.accessPolicyType !== AccessPolicyTypes.macAllowList
    ) {
      updateAccessPolicyType(AccessPolicyTypes.macAllowList);
    }
  }, [stagedSwitchPort.accessPolicyType, stagedSwitchPort.macAllowList, updateAccessPolicyType]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CloseButton
          onPress={() => {
            if (hasDiff) {
              onSave(stagedSwitchPort);
            }

            navigation.goBack();
          }}
        />
      ),
      headerRight: () =>
        isEditMode ? (
          <DoneButton
            onPress={() => {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              setIsEditMode(false);
            }}
          />
        ) : (
          <EditButton
            onPress={() => {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              setIsEditMode(true);
            }}
          />
        ),
    });
  }, [hasDiff, isEditMode, navigation, onSave, stagedSwitchPort]);

  const AddToMACAllowList = (macAddress: string) => {
    const newMacList = [...(stagedSwitchPort.macAllowList ?? [])];

    if (!newMacList.includes(macAddress)) {
      newMacList.push(macAddress);
      updateMacAllowList(newMacList);
    }
  };

  const onSectionHeaderPress = () => {
    showActionSheet(
      [I18n.t("MAC_ALLOW_LIST.SELECT_FROM_DEVICE_LIST"), I18n.t("MAC_ALLOW_LIST.ENTER_MANUALLY")],
      (id: number) => {
        switch (id) {
          case 0:
            navigation.navigate("ClientListModal", {
              title: I18n.t("MAC_ALLOW_LIST.DEVICES_TITLE"),
              showCancelButton: true,
              skipModal: true,
              onPressSave: AddToMACAllowList,
            });
            break;
          case 1:
            navigation.navigate("DeviceMACAddress", {
              onPressSave: AddToMACAllowList,
            });
            break;
        }
      },
      {
        title: I18n.t("MAC_ALLOW_LIST.ACTION_SHEET_TITLE"),
      },
    );
  };

  const deleteMACAddress = (deletedIndex: number) => {
    const newMacList = [...(stagedSwitchPort.macAllowList ?? [])];
    newMacList.splice(deletedIndex, 1);

    updateMacAllowList(newMacList.length === 0 ? undefined : newMacList);
  };

  return (
    <FullScreenContainerView>
      <DefaultHeader
        title={I18n.t("MAC_ALLOW_LIST.HEADER.TITLE")}
        description={description}
        style={styles.header}
      />
      <MkiTable<MACAddressData>
        data={{ MACAddresses: stagedSwitchPort.macAllowList?.map((mac) => ({ mac })) || [] }}
        renderSectionHeader={() => (
          <SectionListHeader
            heading={I18n.t("MAC_ALLOW_LIST.TABLE_HEADER")}
            onPress={onSectionHeaderPress}
            withHorizontalMargin
          />
        )}
        renderSectionFooter={({ section }: { section: SectionListData<MACAddressData> }) =>
          section.data.length > 0 ? null : (
            <View style={styles.rowStyles}>
              <MkiText textStyle="smallSecondary">{I18n.t("MAC_ALLOW_LIST.NO_DEVICES")}</MkiText>
            </View>
          )
        }
        keyExtractor={(_: unknown, index: number) => `${index}`}
        renderRow={(item: MACAddressData, index: number) => (
          <ListRow
            icon={<DeleteButton show={isEditMode} onPress={() => deleteMACAddress(index)} />}
            rowStyles={styles.rowStyles}
          >
            {item.mac}
          </ListRow>
        )}
      />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingBottom: SPACING.default,
  },
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
});

export default MACAllowListScreen;
