import z from "zod";

import { ProductTypeSchema } from "./Device";

export const AlertSeverities = {
  info: "info",
  warning: "warning",
  error: "error",
  critical: "critical",
} as const;
export type AlertSeverity = (typeof AlertSeverities)[keyof typeof AlertSeverities];

const HealthAlertTypes = z.union([
  z.literal("afc_height_empty"),
  z.literal("afc_status_bad"),
  z.literal("bad_ipconf"),
  z.literal("bad_ip6conf"),
  z.literal("bad_vlanconf"),
  z.literal("ccd"),
  z.literal("config_bad_fetch"),
  z.literal("config_stale"),
  z.literal("detected_country_mismatch"),
  z.literal("device_access_issue"),
  z.literal("dns_down"),
  z.literal("firmware_version"),
  z.literal("invalid_config"),
  z.literal("ip_conflict"),
  z.literal("l3_dynamic_routes_overflow"),
  z.literal("l3_hosts_overflow"),
  z.literal("line_vty_error"),
  z.literal("manual_config_fix_required"),
  z.literal("manual_country_mismatch"),
  z.literal("mg_safe_mode"),
  z.literal("missing_config_options"),
  z.literal("monitoring_unsupported_version"),
  z.literal("odd_upstream_gateway"),
  z.literal("radar_detection"),
  z.literal("unknown_config_options"),
  z.literal("vlan_mismatch"),
  z.literal("vlan_prefix_starvation"),
  z.literal("config"),
  z.literal("country_mismatch"),
  z.literal("over_recommended_site_to_site_vpn_tunnels"),
  z.literal("pcc_apns_check_cert"),
  z.literal("pcc_apns_expired"),
  z.literal("pcc_apns_expires_soon"),
  z.literal("pcc_empty_imei"),
  z.literal("pcc_empty_imei"),
  z.literal("pcc_empty_device_identifier"),
  z.literal("pcc_duplicate_device_identifier"),
  z.literal("reg_dom_mismatch"),
  z.literal("stack_misconfigured"),
  z.literal("stack_not_configured"),
  z.literal("switch_not_setup_as_stack"),
  z.literal("insecure_sm_networks_disabled_enrollment_auth_alert"),
  z.literal("asymmetry"),
  z.literal("bad_connectivity"),
  z.literal("bad_gateway"),
  z.literal("cellular_failover"),
  z.literal("disassociation"),
  z.literal("eapol_test_failed"),
  z.literal("firewall"),
  z.literal("high_interference"),
  z.literal("no_telemetry"),
  z.literal("node_check"),
  z.literal("unreachable"),
  z.literal("unseen"),
  z.literal("using_mtun_http"),
  z.literal("vlan_disconnect"),
  z.literal("vpn_outage"),
  z.literal("vrrp_failover"),
  z.literal("all_auto_vpn_peers_down"),
  z.literal("auto_vpn_peer_down"),
  z.literal("dormant"),
  z.literal("ap_noauth"),
  z.literal("collisions_errors"),
  z.literal("crc_errors"),
  z.literal("door_tamper"),
  z.literal("fan_down"),
  z.literal("frags_errors"),
  z.literal("jabbers_errors"),
  z.literal("low_power"),
  z.literal("missized_errors"),
  z.literal("mmc_issue"),
  z.literal("monitoring_stack_error"),
  z.literal("mps_down"),
  z.literal("netconf_abnormal"),
  z.literal("ntp_issue"),
  z.literal("poe_denied"),
  z.literal("poe_overload"),
  z.literal("probe_cable_unknown"),
  z.literal("reboot_count"),
  z.literal("reboot_count_no_xmit_mon"),
  z.literal("reboot_count_panic"),
  z.literal("rps_backup"),
  z.literal("rps_down"),
  z.literal("secure_connect_auth_in_progress"),
  z.literal("secure_connect_auth_failure"),
  z.literal("secure_connect_auth_timeout"),
  z.literal("speed_downshift"),
  z.literal("stp_bpdu_conflict"),
  z.literal("stp_bpduguard_active"),
  z.literal("stp_loopguard_active"),
  z.literal("stp_rootguard_active"),
  z.literal("stp_tcn_errors"),
  z.literal("temperature_cable_disconnect"),
  z.literal("udld_err_tx_rx_loop"),
  z.literal("udld_err_n_mismatch"),
  z.literal("udld_err_unidir"),
  z.literal("water_cable_disconnect"),
  z.literal("wlc_ha_active_recovery"),
  z.literal("wlc_ha_standby_recovery"),
  z.literal("wlc_ha_standby_offline"),
  z.literal("wlc_ha_fail_over_past_day"),
  z.literal("cloud_archive_alert"),
  z.literal("crc_errors_error"),
  z.literal("crc_errors_warning"),
  z.literal("stp_tcn_error"),
  z.literal("stp_tcn_warning"),
  z.literal("arp_failure"),
  z.literal("dfs_event_pattern"),
  z.literal("insight_web_app"),
  z.literal("isp_issue_local_branch"),
  z.literal("mac_flap_alert"),
  z.literal("sticky_client"),
  z.literal("traffic_shaping_rule_saturation"),
  z.literal("uplink_saturation"),
  z.literal("vpn_backhaul"),
  z.literal("voip_jitter"),
  z.literal("voip_mos"),
  z.literal("voip_packet_loss"),
  z.literal("wan_latency"),
  z.literal("wan_packet_loss"),
  z.literal("wan_usage"),
  z.literal("wan_status"),
  z.literal("insight_web_app_alert"),
]);
export type HealthAlertType = z.infer<typeof HealthAlertTypes>;

const Scope = z.object({
  devices: z.array(
    z.object({
      url: z.string(),
      name: z.string(),
      productType: ProductTypeSchema,
      serial: z.string(),
      mac: z.string(),
      lldp: z
        .object({
          portId: z.string(),
        })
        .optional(),
      clients: z
        .array(
          z.object({
            mac: z.string(),
          }),
        )
        .optional(),
      order: z.number().optional(),
    }),
  ),
  applications: z.array(
    z.object({
      url: z.string(),
      name: z.string(),
    }),
  ),
  peers: z.array(
    z.object({
      url: z.string().nullable(),
      network: z.object({
        name: z.string(),
        id: z.string().nullable(),
      }),
    }),
  ),
});

const HealthAlertSchema = z.object({
  id: z.string(),
  category: z.string(),
  type: HealthAlertTypes,
  severity: z.string().nullable(),
  scope: Scope,
});

export const HealthAlertsSchema = z.array(HealthAlertSchema).describe("HealthAlertsSchema");

const OrgHealthAlertSchema = HealthAlertSchema.extend({
  network: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .nullable(),
  startedAt: z.string(),
  dismissedAt: z.nullable(z.string()),
  scopeCategory: z.nullable(z.string()),
  troubleshooting: z.object({
    hasGuidance: z.boolean(),
  }),
  isVisibleOnlyToMerakiAdmins: z.boolean(),
  title: z.string(),
});

export const OrgHealthAlertsSchema = z
  .array(OrgHealthAlertSchema)
  .describe("OrgHealthAlertsSchema");

export type HealthAlert = z.infer<typeof HealthAlertSchema>;
export type HealthAlerts = z.infer<typeof HealthAlertsSchema>;
export type OrgHealthAlert = z.infer<typeof OrgHealthAlertSchema>;
export type OrgHealthAlerts = z.infer<typeof OrgHealthAlertsSchema>;
