import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { HealthStackProps } from "~/go/navigation/Types";
import NetworkEventsListScreen from "~/go/screens/NetworkEventsListScreen";
import FailedWirelessConnectionScreen from "~/shared/screens/FailedWirelessConnectionScreen";
import FailedWirelessConnectionsListScreen from "~/shared/screens/FailedWirelessConnectionsListScreen";
import PeersOverview from "~/shared/screens/PeersOverview";

import HealthScreen from "../screens/HealthScreen";
import { hardwareScreens } from "./HardwareScreens";
import { useDefaultScreenOptions } from "./Options";

const ForwardedHealthScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HealthStackProps, "Health">>()(
    HealthScreen,
  );
const ForwardedFailedWirelessConnectionsListScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HealthStackProps, "FailedWirelessConnectionsList">
>()(FailedWirelessConnectionsListScreen);
const ForwardedFailedWirelessConnectionScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HealthStackProps, "FailedWirelessConnection">
>()(FailedWirelessConnectionScreen);
const ForwardedNetworkEventsListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HealthStackProps, "NetworkEventsList">>()(
    NetworkEventsListScreen,
  );
const ForwardedPeersoverview =
  withForwardedNavigationParams<ForwardedStackScreenProps<HealthStackProps, "PeersOverview">>()(
    PeersOverview,
  );

const Stack = createNativeStackNavigator<HealthStackProps>();

const HealthStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="Health"
        component={ForwardedHealthScreen}
        options={{ headerTitle: I18n.t("ALERTS_SCREEN.TITLE.GO") }}
      />
      <Stack.Screen
        name="FailedWirelessConnectionsList"
        component={ForwardedFailedWirelessConnectionsListScreen}
        options={{ headerTitle: I18n.t("FAILED_CONNECTIONS_LIST.TITLE") }}
      />
      <Stack.Screen
        name="FailedWirelessConnection"
        component={ForwardedFailedWirelessConnectionScreen}
      />
      <Stack.Screen
        name="NetworkEventsList"
        component={ForwardedNetworkEventsListScreen}
        options={{ headerTitle: I18n.t("NETWORK_EVENTS_LIST.TITLE") }}
      />
      <Stack.Screen
        name="PeersOverview"
        component={ForwardedPeersoverview}
        options={{ headerTitle: I18n.t("SITES_OVERVIEW.TITLE") }}
      />
      {hardwareScreens(Stack)}
    </Stack.Navigator>
  );
};

export default HealthStack;
