import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const KeepIdleTimeoutAliveSchema = z.literal("alive").describe("KeepIdleTimeoutAliveSchema");

export type IdleTimeoutAlive = z.infer<typeof KeepIdleTimeoutAliveSchema>;

function buildUrl(encryptedNetworkId: string) {
  return `/n/${encryptedNetworkId}/manage/dashboard/keep_idle_timeout_alive`;
}

export const postKeepIdleTimeoutAlive = ({
  encryptedNetworkId,
}: {
  encryptedNetworkId: string | undefined;
}) => {
  if (encryptedNetworkId === undefined) {
    return Promise.reject(new Error("encryptedNetworkId is undefined"));
  }
  // we are using a query w/ this post to take advantage of refetchInterval
  return request(KeepIdleTimeoutAliveSchema, "POST", buildUrl(encryptedNetworkId));
};

export const useKeepIdleTimeoutAliveQuery = createQuery({
  baseQueryKey: buildUrl("{encryptedNetworkId}"),
  queryFn: (options: { encryptedNetworkId: string | undefined }) =>
    postKeepIdleTimeoutAlive(options),
  requiredVariables: ["encryptedNetworkId"],
});
