import { I18n } from "@meraki/core/i18n";
import { Box } from "@meraki/magnetic/layout";
import { Children, ReactElement, useEffect } from "react";

import { FooterPanel } from "./FooterPanel";
import { HeaderPanel } from "./HeaderPanel";
import { Step, StepProps } from "./Step";
import { useStepper } from "./useStepper";

type RootChildType = ReactElement<StepProps>;

type StepperProps = {
  onComplete: () => void;
  onCancel: () => void;

  children: RootChildType | RootChildType[];
};

export function Stepper({ onComplete, onCancel, children }: StepperProps) {
  const childrenArray = Children.toArray(children).filter(Boolean) as RootChildType[];
  if (childrenArray.some((child) => __DEV__ && child.type !== Step)) {
    throw new Error("You should only pass in Stepper.Step components into a Stepper");
  }

  const numberOfSteps = childrenArray.length;
  const {
    currentStep,
    stepStatuses,
    onGoBack,
    onCompleteAndGoNext,
    onToggleStepDisabled,
    resetStepper,
  } = useStepper(numberOfSteps);

  // check if each steps should be disabled
  // note: the first and last steps cannot be disabled
  useEffect(() => {
    stepStatuses.forEach((step, index) => {
      const shouldDisableStep = childrenArray[index]?.props?.isDisabled;
      if (shouldDisableStep != null && step.disabled !== shouldDisableStep) {
        onToggleStepDisabled(index + 1);
      }
    });
  }, [childrenArray, stepStatuses, onToggleStepDisabled]);

  const activeStep = childrenArray[currentStep - 1];
  const activeStepProps = activeStep?.props;
  return (
    <Box flex={1}>
      <HeaderPanel
        stepStatuses={stepStatuses}
        stepTitle={activeStepProps?.title ?? ""}
        stepSubTitle={activeStepProps?.subtitle ?? ""}
      />
      <Box flex={1}>{activeStep}</Box>
      <FooterPanel
        explanatoryText={activeStepProps?.explanatoryText}
        isPrimaryActionDisabled={activeStepProps?.isPrimaryActionDisabled}
        primaryText={currentStep < numberOfSteps ? I18n.t("NEXT") : I18n.t("DONE")}
        onPrimaryPress={() => {
          // on step complete
          activeStepProps?.onComplete?.();

          if (currentStep < numberOfSteps) {
            onCompleteAndGoNext();
          } else {
            // wizard flow complete
            onComplete();
          }
        }}
        secondaryKind={currentStep === 1 ? "tertiary" : "secondary"}
        secondaryText={currentStep === 1 ? I18n.t("CANCEL") : I18n.t("BACK")}
        onSecondaryPress={() => {
          // on step cancel
          activeStepProps?.onCancel?.();

          if (currentStep === 1) {
            // wizard flow cancel
            onCancel();
            resetStepper();
          } else {
            onGoBack();
          }
        }}
      />
    </Box>
  );
}

Stepper.Step = Step;
