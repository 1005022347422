import { I18n } from "@meraki/core/i18n";
import {
  oneHour,
  oneMonth,
  tenMinutes,
  useCurrentNetworkId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { secondsToMilliseconds } from "date-fns";
import { isEmpty, min } from "lodash";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LINE_CHART_PADDING, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import { ProductIconSize } from "~/go/components/HardwareRow";
import ProductIcon from "~/go/components/ProductIcon";
import { useChannelUtilizationByDevice } from "~/go/hooks/useChannelUtilizationByDevice";
import { chartDateFormatter } from "~/lib/formatHelper";
import { isWeb } from "~/lib/PlatformUtils";
import { currentOrganization, deviceState, getNetworkTimezone } from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import MkiMultilineChart from "~/shared/components/MkiMultilineChart";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import GeneralStatus from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const TIMESTAMP_KEY = "timestamp";
const TWO_FOUR_GHZ_KEY = "wifi0";
const FIVE_GHZ_KEY = "wifi1";

const NO_WIFI_DATA = {
  wifi0: [],
  wifi1: [],
};

const DATA_KEYS = [
  {
    x: TIMESTAMP_KEY,
    y: FIVE_GHZ_KEY,
    color: MkiColors.primaryGraphLine,
  },
  {
    x: TIMESTAMP_KEY,
    y: TWO_FOUR_GHZ_KEY,
    color: MkiColors.secondaryGraphLine,
  },
];

const calculateDomain = (data: { timestamp: number }[], timespan: any) => {
  if (isEmpty(data)) {
    return undefined;
  }

  const leftXDomain = min(data.map((d) => d.timestamp))!;
  return {
    y: [0, 100],
    x: [leftXDomain, leftXDomain + secondsToMilliseconds(timespan)],
  };
};

const getDataPointsForAP = (apUtilization: any) => {
  const data24GHz = apUtilization.wifi0.map((packet: any) => ({
    timestamp: Date.parse(packet.start_ts),
    wifi0: packet.utilization,
  }));
  const data5GHz = apUtilization.wifi1.map((packet: any) => ({
    timestamp: Date.parse(packet.start_ts),
    wifi1: packet.utilization,
  }));

  return [...data24GHz, ...data5GHz];
};

const GraphHeader = ({ model, name }: any) => {
  return (
    <View style={styles.graphHeaderStyle}>
      {/*
// @ts-ignore */}
      <ProductIcon model={model} status={GeneralStatus.good} productSize={ProductIconSize.small} />
      <MkiText textStyle="subheading" screenStyles={styles.nameText}>
        {name}
      </MkiText>
    </View>
  );
};

const APGraph = ({ serialNumber }: any) => {
  let interval = tenMinutes;
  const organizationId = useAppSelector(currentOrganization).id;
  const networkId = useCurrentNetworkId();
  const accessPoint = useAppSelector((state) => deviceState(state, { serialNumber }));
  const timespan = useGlobalTimespan();
  if (timespan && timespan >= oneMonth) {
    interval = oneHour;
  }
  const apUtilizations = useChannelUtilizationByDevice({
    organizationId,
    networkIds: networkId ? [networkId] : [],
    serials: serialNumber ? [serialNumber] : [],
    timespan,
    interval,
  });
  const timezone = useAppSelector(getNetworkTimezone);
  let apUtilization = apUtilizations.data ?? NO_WIFI_DATA;
  if (!(TWO_FOUR_GHZ_KEY in apUtilization && FIVE_GHZ_KEY in apUtilization)) {
    apUtilization = NO_WIFI_DATA;
  }

  const { model } = accessPoint;
  const name = accessPoint.name || accessPoint.serial;
  const data = getDataPointsForAP(apUtilization);

  return (
    <SummaryCard heading={<GraphHeader model={model} name={name} />}>
      <MkiMultilineChart
        domain={calculateDomain(data, timespan)}
        data={data}
        dataKeys={DATA_KEYS}
        padding={isWeb() ? LINE_CHART_PADDING : undefined}
        yAxisFormatter={(value: any) => `${value}%`}
        xAxisFormatter={(t: any) => chartDateFormatter(t, timespan, timezone, true)}
      />
      <MkiGraphLegend
        legendValues={[
          {
            label: I18n.t("CHANNEL_UTILIZATION.GRAPH.WIFI_1"),
            colors: [MkiColors.primaryGraphLine],
          },
          {
            label: I18n.t("CHANNEL_UTILIZATION.GRAPH.WIFI_0"),
            colors: [MkiColors.secondaryGraphLine],
          },
        ]}
        isElementSelected={() => true}
      />
      <LoadingSpinner visible={apUtilizations.isLoading || apUtilizations.isFetching} />
    </SummaryCard>
  );
};

export const ChannelUtilizationGraph = ({ serialNumber }: any) => {
  const accessPoint = useAppSelector((state) => deviceState(state, { serialNumber }));

  if (!accessPoint) {
    return null;
  }

  return (
    <>
      <DefaultHeader
        title={I18n.t("CHANNEL_UTILIZATION.TITLE")}
        description={I18n.t("CHANNEL_UTILIZATION.DESCRIPTION")}
      />
      <APGraph serialNumber={serialNumber} />
    </>
  );
};

const styles = StyleSheet.create({
  graphHeaderStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  nameText: { paddingHorizontal: SPACING.default },
});

export default ChannelUtilizationGraph;
