import { createSelector } from "reselect";

import {
  getCurrentOrganization,
  getLoginSecurity,
  getTwoFactorSecret,
  getTwoFactorUsername,
  requiresTwoFactorState,
} from "~/selectors/getters";

const emptyTwoFactorAuthInfo = { twoFactorSecret: "", twoFactorUsername: "" };
export const getTwoFactorAuthInfo = createSelector(
  getTwoFactorSecret,
  getTwoFactorUsername,
  (twoFactorSecret, twoFactorUsername) => {
    return twoFactorUsername && twoFactorUsername
      ? {
          twoFactorSecret,
          twoFactorUsername,
        }
      : emptyTwoFactorAuthInfo;
  },
);

export const userNeedsTFA = createSelector(
  requiresTwoFactorState,
  (requiresTwoFactor) => requiresTwoFactor,
);

export const isTwoFactorAuthEnforced = createSelector(
  getLoginSecurity,
  getCurrentOrganization,
  (loginSecurity, orgId) => (orgId ? loginSecurity?.[orgId]?.enforceTwoFactorAuth : undefined),
);
