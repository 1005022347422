import Gradient, { GradientProps } from "~/shared/components/icons/Gradient";

type WifiProps = Omit<GradientProps, "path">;

export const WifiIcon = (props: WifiProps) => {
  const path =
    "M14.5,24a2,2,0,1,1,2-2A2,2,0,0,1,14.5,24ZM2.75,12.66a1,1,0,0,1-1.5-1.32C4.94,7.13,9.38,5,14.5,5s9.56,2.13,13.25,6.34a1,1,0,0,1-1.5,1.32C22.92,8.87,19,7,14.5,7S6.08,8.87,2.75,12.66Zm8,6a1,1,0,0,1-1.41-.05,1,1,0,0,1-.05-1.31,7.06,7.06,0,0,1,10-.49c.17.16.34.32.49.49a1,1,0,0,1-1.46,1.36,5.07,5.07,0,0,0-7.16-.38C11,18.4,10.87,18.53,10.75,18.66Zm-4-3a1,1,0,1,1-1.52-1.3h0A11.7,11.7,0,0,1,14.5,10a11.71,11.71,0,0,1,9.26,4.35,1,1,0,1,1-1.52,1.3A9.73,9.73,0,0,0,14.5,12a9.73,9.73,0,0,0-7.74,3.65Z";

  return <Gradient path={path} {...props} viewBox="0 0 30 30" />;
};

export default WifiIcon;
