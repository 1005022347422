import { NODE_GROUP_FAILURE, NODE_GROUP_REQUEST, NODE_GROUP_SUCCESS } from "@meraki/shared/redux";

import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const getNodeGroup =
  (nodeGroupId: string | undefined): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [NODE_GROUP_REQUEST, NODE_GROUP_SUCCESS, NODE_GROUP_FAILURE],
        endpoint: `/node_groups/${nodeGroupId}`,
        config: {
          method: Method.get,
          queryParams: {
            id: nodeGroupId,
            "with[with_firmware]": true,
          },
        },
        meta: {
          nodeGroupId,
        },
      },
    });
  };
