import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

import { SPACING, WINDOW } from "~/constants/MkiConstants";
import StatusIconWrapper from "~/enterprise/components/StatusIconWrapper";
import EnterpriseSummaryCard from "~/enterprise/components/SummaryCard";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import { computeGoodPercent } from "~/lib/graphUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { networkHealthSelector, timespanState } from "~/selectors";
import CaptionBox, { CAPTION_BOX_WIDTH } from "~/shared/components/CaptionBox";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import MkiText from "~/shared/components/MkiText";
import PercentageText from "~/shared/components/PercentageText";
import GoSummaryCard from "~/shared/components/SummaryCard";
import UptimeGraph from "~/shared/components/UptimeGraph";
import { statusForUptimePercent } from "~/shared/lib/UptimeRange";
import { NetworkHealthData } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";

const CARD_PADDING_HORIZONTAL = SPACING.extraLarge;
const GRAPH_WIDTH_OFFSET = CARD_PADDING_HORIZONTAL + CAPTION_BOX_WIDTH;
const GRAPH_HEIGHT = 12;

type GraphAndPercentProps = {
  timespan: number;
  networkHealth?: NetworkHealthData;
};

export const GraphAndPercent = (props: GraphAndPercentProps) => {
  const { timespan, networkHealth } = props;
  if (!networkHealth || isEmpty(networkHealth.entries)) {
    return <MkiText screenStyles={styles.noHealthText}>{I18n.t("NETWORK_UPTIME.NO_DATA")}</MkiText>;
  }

  const { entries, t0, t1 } = networkHealth;

  const graphWidth = Dimensions.get(WINDOW).width - GRAPH_WIDTH_OFFSET;
  const goodPercent = computeGoodPercent({
    t0,
    t1,
    data: entries,
    width: graphWidth,
  });

  return (
    <View style={styles.graphAndPercent}>
      <CaptionBox caption={I18n.t("NETWORK_UPTIME.UPTIME_CAPTION")}>
        <StatusIconWrapper status={statusForUptimePercent(goodPercent)}>
          <PercentageText ratio={goodPercent} />
        </StatusIconWrapper>
      </CaptionBox>
      <UptimeGraph
        data={networkHealth}
        timespan={timespan}
        graphHeight={GRAPH_HEIGHT}
        graphWidthOffset={GRAPH_WIDTH_OFFSET}
      />
    </View>
  );
};

type ReduxProps = {
  timespan: number;
  networkHealth?: NetworkHealthData;
};

interface NetworkUptimeProps extends ReduxProps {
  loading: boolean;
}

export const NetworkUptime = (props: NetworkUptimeProps) => {
  const { loading } = props;
  const { theme } = useTheme.getState();

  const graphColors = themeColors(theme).graphColors;

  return appSelect({
    enterprise: (
      <EnterpriseSummaryCard title={I18n.t("NETWORK_UPTIME.TITLE")} loading={loading}>
        <GraphAndPercent {...props} />
      </EnterpriseSummaryCard>
    ),
    go: (
      <GoSummaryCard
        heading={I18n.t("NETWORK_UPTIME.TITLE")}
        subheading={I18n.t("NETWORK_UPTIME.SUBHEADING")}
      >
        <GraphAndPercent {...props} />
        <MkiGraphLegend
          legendValues={[
            { label: I18n.t("NETWORK_UPTIME.LEGEND.ONLINE"), colors: [graphColors.goodBar] },
            {
              label: I18n.t("NETWORK_UPTIME.LEGEND.PARTIALLY_ONLINE"),
              colors: [graphColors.lightGreenWarningStatus],
            },
            { label: I18n.t("NETWORK_UPTIME.LEGEND.ALERTING"), colors: [graphColors.alerting] },
            { label: I18n.t("NETWORK_UPTIME.LEGEND.OFFLINE"), colors: [graphColors.offline] },
            {
              label: I18n.t("NETWORK_UPTIME.LEGEND.UNREACHABLE"),
              colors: [graphColors.offlineBar],
            },
          ]}
          isElementSelected={() => true}
        />
      </GoSummaryCard>
    ),
  });
};

const styles = StyleSheet.create({
  noHealthText: {
    flex: 1,
    color: TEXT_COLORS.secondary,
  },
  graphAndPercent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkHealth: networkHealthSelector(state),
    timespan: timespanState(state),
  };
}

export default connect(mapStateToProps)(memo(NetworkUptime));
