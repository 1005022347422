import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Firmware({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M12 2a1 1 0 0 0-1 1v2h2V3a1 1 0 0 0-1-1ZM16 2a1 1 0 0 0-1 1v2h2V3a1 1 0 0 0-1-1ZM13 19v2a1 1 0 1 1-2 0v-2h2ZM9 19H7v2a1 1 0 1 0 2 0v-2ZM2 12a1 1 0 0 1 1-1h2v2H3a1 1 0 0 1-1-1ZM3 15a1 1 0 1 0 0 2h2v-2H3ZM2 8a1 1 0 0 1 1-1h2v2H3a1 1 0 0 1-1-1ZM21 11h-2v2h2a1 1 0 1 0 0-2ZM19 15h2a1 1 0 1 1 0 2h-2v-2ZM21 7h-2v2h2a1 1 0 1 0 0-2ZM17 19v2a1 1 0 1 1-2 0v-2h2ZM8 2a1 1 0 0 0-1 1v2h2V3a1 1 0 0 0-1-1Z"
        fill={color1}
      />
      <Path
        d="M17 17V7H7v10h10ZM7 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
      <Rect fill={color3} height="6" rx="1" width="6" x="9" y="9" />
    </Svg>
  );
}
