import { SET_CLIENTS_LIST_TIMESPAN, SET_WIRELESS_TIMESPAN } from "@meraki/shared/redux";

import { AppThunk } from "~/shared/types/Redux";

export function setClientListTimespan(timespan: number): AppThunk {
  return (dispatch) =>
    dispatch({
      type: SET_CLIENTS_LIST_TIMESPAN,
      timespan,
    });
}

export function setWirelessProductHubTimespan(timespan: number): AppThunk {
  return (dispatch) =>
    dispatch({
      type: SET_WIRELESS_TIMESPAN,
      timespan,
    });
}
