import { Licenses, useGetLicenses } from "@meraki/shared/api";
import { groupBy } from "lodash";

import {
  ActiveStatuses,
  InactiveStatuses,
  LicenseStatuses,
  LicenseTypes,
} from "~/shared/types/License";

function getSortedGXUMBs(licenses: Licenses, targetDate: string, descendingOrder = false) {
  return licenses
    .filter(({ licenseType }) => licenseType === LicenseTypes.gxUMB)
    .sort((a, b) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const aDate = (descendingOrder ? a : b)[targetDate];
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const bDate = (descendingOrder ? b : a)[targetDate];
      return new Date(aDate).valueOf() - new Date(bDate).valueOf();
    });
}

const filterNetworkUmbrellaLicenses = (licenses: Licenses) => {
  const nonExpiredLicenses = Object.values(licenses)
    .map((license) => {
      if (license.headLicenseId) {
        return {
          ...license,
          headLicenseKey: license.licenseKey,
        };
      } else {
        return license;
      }
    })
    .filter(({ state }) => state !== LicenseStatuses.expired);

  const sortedLicenses = getSortedGXUMBs(nonExpiredLicenses, "claimDate", true);
  const partitionedLicenses = groupBy(sortedLicenses, (license) => {
    return ActiveStatuses.includes(license.state) ? "isActive" : "notActive";
  });
  const consolidatedLicenses: Licenses = [
    ...(partitionedLicenses["isActive"] || []),
    ...(partitionedLicenses["notActive"] || []),
  ];
  return consolidatedLicenses;
};
const filterGXUMBLicense = (licenses: Licenses, serialNumber: string, networkId?: string) => {
  if (networkId) {
    const networkLicenses = licenses.filter((license) => {
      return license.networkId === networkId;
    });
    const gxLicenses = getSortedGXUMBs(Object.values(networkLicenses), "expirationDate");
    return (
      (gxLicenses.find(({ deviceSerial }) => deviceSerial === serialNumber) &&
        gxLicenses.find(({ state }) => state === LicenseStatuses.active)) ||
      gxLicenses.find(({ state }) => ActiveStatuses.includes(state)) ||
      gxLicenses.find(({ state }) => InactiveStatuses.includes(state))
    );
  }
  return;
};

export const useGXUMBLicense = (
  serialNumber: string,
  organizationId?: string,
  networkId?: string,
) => {
  return useGetLicenses(
    { organizationId },
    {
      enabled: Boolean(organizationId),
      select: (data) => filterGXUMBLicense(data, serialNumber, networkId),
    },
  );
};

export const useNetworkUmbrellaLicenses = (organizationId?: string, networkId?: string) => {
  return useGetLicenses(
    { organizationId },
    {
      enabled: Boolean(organizationId && networkId),
      select: (data) => filterNetworkUmbrellaLicenses(data),
    },
  );
};
