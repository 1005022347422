import { AnalyticsProp } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { Icon } from "@meraki/magnetic/icons";
import { defaultTimespans, setGlobalTimespan, useGlobalTimespan } from "@meraki/shared/redux";
import { useDispatch } from "react-redux";

import { PickerOption } from "./Picker";
import { PickerCard } from "./PickerCard";

export type TimePickerCardProps = {
  title?: string;
  currentCustomTimespan?: number;
  onCustomTimespanChange?: (timespan: number) => void;
} & AnalyticsProp<"onOpen" | "onChange">;

function getLabelForTimespan(availableTimespans: PickerOption<number>[], timespan: number) {
  const matchingTimespan = availableTimespans.find((d) => timespan === d.value);

  if (!matchingTimespan) {
    return "";
  }

  const { label } = matchingTimespan;
  return I18n.t("TIMEPICKER_PAST_TIMESPAN", { timespan: I18n.t(label) });
}

export function TimePickerCard({
  title,
  currentCustomTimespan,
  onCustomTimespanChange,
  analytics,
}: TimePickerCardProps) {
  const timespan = useGlobalTimespan();
  const dispatch = useDispatch();
  const formattedTimespans: PickerOption<number>[] = [];
  const availableTimespans = Object.values(defaultTimespans);
  const handleOnSelectTimepsan = ({ value: timespan }: { value: number }) => {
    if (currentCustomTimespan) {
      onCustomTimespanChange && onCustomTimespanChange(timespan);
    } else {
      dispatch(setGlobalTimespan(timespan));
    }
  };

  availableTimespans.forEach(({ label, value }) =>
    formattedTimespans.push({
      label: I18n.t(label),
      value: value,
    }),
  );
  const currentTimespan = currentCustomTimespan || timespan;
  return (
    <PickerCard
      analytics={analytics}
      options={formattedTimespans}
      selectedOption={{
        label: getLabelForTimespan(availableTimespans, currentTimespan),
        value: currentTimespan,
      }}
      onSelectOption={handleOnSelectTimepsan}
      rightAccessory={!title && <Icon name="Clock" color="interact.text.base" size={18} />}
      title={title}
      testID="TIMEPICKER_CARD"
    />
  );
}
