import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Navigation({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path d="m16 10-2-2M10 16l-2-2M8 10l2-2M14 16l2-2" stroke={color1} strokeWidth="1.5" />
      <Path
        d="M11.912 3.326a.125.125 0 0 1 .176 0l2.586 2.586a.125.125 0 0 1 0 .176l-2.586 2.586a.125.125 0 0 1-.176 0L9.326 6.088a.125.125 0 0 1 0-.176l2.586-2.586ZM11.912 15.326a.125.125 0 0 1 .176 0l2.586 2.586a.125.125 0 0 1 0 .176l-2.586 2.586a.125.125 0 0 1-.176 0l-2.586-2.586a.125.125 0 0 1 0-.176l2.586-2.586Z"
        stroke={color2}
        strokeWidth="1.75"
      />
      <Path
        d="M5.293 8.707a1 1 0 0 1 1.414 0l2.586 2.586a1 1 0 0 1 0 1.414l-2.586 2.586a1 1 0 0 1-1.414 0l-2.586-2.586a1 1 0 0 1 0-1.414l2.586-2.586ZM17.293 8.707a1 1 0 0 1 1.414 0l2.586 2.586a1 1 0 0 1 0 1.414l-2.586 2.586a1 1 0 0 1-1.414 0l-2.586-2.586a1 1 0 0 1 0-1.414l2.586-2.586Z"
        fill={color2}
      />
    </Svg>
  );
}
