import { StyleProp, TextStyle } from "react-native";
import { AnyAction } from "redux";

import {
  REMOVE_PENDING_APP_LINK,
  SET_BOTTOM_TAB_BAR,
  SET_PENDING_APP_LINK,
  SET_SELECTED_VLAN_ID,
  TOGGLE_BOTTOM_TAB_VISIBILITY,
  WIPE_REDUX,
} from "../constants/ActionTypes";

interface setPendingAppLinkAction extends AnyAction {
  type: typeof SET_PENDING_APP_LINK;
  payload: {
    screen: string;
    params?: Record<string, string | number | undefined>;
  };
}

interface RemovePendingAppLinkAction extends AnyAction {
  type: typeof REMOVE_PENDING_APP_LINK;
}

interface SetSelectedVlanIdAction extends AnyAction {
  type: typeof SET_SELECTED_VLAN_ID;
  payload: { vlanId: number };
}

interface WipeReduxAction extends AnyAction {
  type: typeof WIPE_REDUX;
}

export function setBottomBarTab(
  tabName: string,
  badgeText?: string,
  badgeStyle?: StyleProp<TextStyle>,
) {
  return {
    type: SET_BOTTOM_TAB_BAR,
    payload: { tabName, badgeText, badgeStyle },
  } as const;
}

export function setBottomBarVisibility(value: "hidden" | "visible") {
  return {
    type: TOGGLE_BOTTOM_TAB_VISIBILITY,
    payload: { value },
  } as const;
}

type SetBottomBarTabAction = ReturnType<typeof setBottomBarTab> & AnyAction;
type SetBottomBarVisibilityAction = ReturnType<typeof setBottomBarVisibility> & AnyAction;

export type NavigationAction =
  | SetBottomBarTabAction
  | SetBottomBarVisibilityAction
  | setPendingAppLinkAction
  | RemovePendingAppLinkAction
  | SetSelectedVlanIdAction
  | WipeReduxAction;
