import { I18n } from "@meraki/core/i18n";
import { UmbrellaPolicy } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { NESTED_TABLE_MODAL_ROWTYPE } from "~/constants/RowConstants";
import { getUmbrellaPolicyTranslations } from "~/constants/Umbrella";
import { UmbrellaScreensPropMap } from "~/go/navigation/Types";
import NestedTableModalRow from "~/go/rows/NestedTableModalRow";
import MkiTable from "~/shared/components/MkiTable";
import NoDataFooter from "~/shared/components/NoDataFooter";
import { CloseButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<UmbrellaScreensPropMap, "UmbrellaPolicies">;

const UmbrellaPoliciesScreen = ({
  selectedPolicy,
  umbrellaPolicies,
  updateSelectedPolicy,
}: Props) => {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const selectPolicy = (newSelectedPolicy: UmbrellaPolicy) => {
    updateSelectedPolicy(newSelectedPolicy);
    navigation.goBack();
  };

  const renderRow = (rowData: UmbrellaPolicy, rowId: number) => {
    const rowType =
      rowData.id === selectedPolicy?.id ? NESTED_TABLE_MODAL_ROWTYPE.isSelected : undefined;

    const { title, message } = getUmbrellaPolicyTranslations(rowData.name);
    return (
      <NestedTableModalRow
        rowType={rowType}
        subtitle={message}
        onPress={() => selectPolicy(rowData)}
        testID={`POLICY_${rowId}`}
      >
        {title}
      </NestedTableModalRow>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.tableContainer}>
        <MkiTable<UmbrellaPolicy>
          data={umbrellaPolicies}
          renderRow={renderRow}
          ListEmptyComponent={
            <NoDataFooter
              data={umbrellaPolicies}
              noDataString={I18n.t("UMBRELLA.POLICY.NO_POLICY")}
            />
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
});

export default UmbrellaPoliciesScreen;
