import { BaseDeviceSubProps } from "../../types/BaseSubProps";
import DataMsg from "../../types/DataMsg";

type TracerouteInterfaces = "wired0" | "wired1" | "cellular_linux";

export interface Props extends BaseDeviceSubProps {
  interface: TracerouteInterfaces;
  host: string;
  v: 4 | 6; // ipv4, ipv6
  c?: number; // number of cycles used for mtr (unsure if should keep in same handler)
  command: "traceroute" | "mtr";
}

export interface Message extends DataMsg {
  data: {
    chunk: string;
    completed: boolean;
  };
}

export function formatMsg(message?: Message): string | undefined {
  const result = message?.data?.chunk;
  return result;
}
