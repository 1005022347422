import { Rule } from "~/shared/types/ApplianceTypes";
import { Client } from "~/shared/types/Client";

export type PriorityLevel = "high" | "normal" | "low";
export interface Network {
  vlans?: Vlan[];
  l3FirewallRules: Rule[];
}

export enum DNSServers {
  upstream_dns = "upstream_dns",
  google_dns = "google_dns",
  opendns = "opendns",
  custom = "custom",
}

// UDG-3544: Validate/consolidate Vlan types
export interface Vlan {
  id: string;
  networkId: string;
  name: string;
  applianceIp: string;
  subnet: string;
  fixedIpAssignments: FixedIPAssignments;
  reservedIpRanges: IPRange[];
  dnsNameservers: DNSServers | string;
  dchpHandling: DHCPHandling;
  ipv6?: IPv6;
}

export interface DNSSettings {
  dnsServer: DNSServers;
  primaryDNSServer: string;
  secondaryDNSServer: string;
}

interface FixedIPAssignments {
  [macAddress: string]: FixedIPAssignment;
}

export interface FixedIPAssignment {
  kind: "DEVICE_RESERVATIONS";
  ip: string;
  name: string;
}

export interface IPRange {
  kind: "RESERVED_IP_RANGES";
  comment: string;
  start: string;
  end: string;
}

export enum DHCPHandling {
  on = "Run a DHCP server",
  off = "Do not respond to DHCP requests",
}

export interface IPv6 {
  enabled: boolean;
}

export interface AddressSpace {
  gatewayIp: string;
  subnetBase: string;
  subnetMask: string;
}

export enum IPAddressAssignment {
  reserved,
  dynamic,
}

export interface DeviceReservation {
  manualIpReservation: string | null;
  reservedClientDetails: Client | null;
}

export interface WebBlockingRule {
  policy: string;
  type: string;
  value: string;
}

export type SecurityRule = ApplicationRule | BandwidthRule;

export interface ApplicationRule {
  identifier: string;
  definitions: {
    type: string;
    value: {
      id: string;
      name: string;
    };
  }[];
  perClientBandwidthLimits: {
    bandwidthLimits: {
      limitDown?: number;
      limitUp?: number;
    };
    settings: string;
  };
  dscpTagValue: number | null;
  priority: PriorityLevel | null;
}

export interface BandwidthRule {
  identifier: string;
  limit: number;
}

export interface UsageLimitConflict {
  group: string;
  limit: string;
}

export type UsageLimitConflictOptional = UsageLimitConflict | undefined;

// UDG-3515: Verify and update ssidNumber to be a number
export interface ApplicationRulesByNumber {
  [id: string]: ApplicationRule[];
}

export interface BandwidthRuleByNumber {
  [id: string]: BandwidthRule;
}

export interface SecurityRulesByNumber {
  [id: string]: SecurityRule[];
}

export interface SecurityTrafficShapingSettings {
  globalBandwidthLimits: {
    limitUp: number;
    limitDown: number;
  };
}
