import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  size?: number;
};

function PowerIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  const { stroke } = useThemeColors().icon;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M9 22.5L10.5 15L4.5 12.75L15 1.5L13.5 9L19.5 11.25L9 22.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default PowerIcon;
