import { I18n } from "@meraki/core/i18n";
import { TrafficShapingGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  L3FirewallRule,
  queryClient,
  useL3FirewallRules,
  useUpdateL3FirewallRules,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import { useEffect } from "react";

const PROTOCOL_OPTIONS = ["tcp", "udp", "Any"] as const;

const validateIPAddressRule = {
  validate: (newValue: string) => {
    if (!(newValue === "" || newValue === "Any" || Address4.isValid(newValue))) {
      return I18n.t("L3_FIREWALL_RULE.VALIDATE.IP.INVALID");
    }

    return undefined;
  },
};

type FormL3Rule = Omit<L3FirewallRule, "policy"> & { policy: boolean };

export function L3FirewallRuleScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<TrafficShapingGroupProps>>();
  const route = useRoute<RouteProp<TrafficShapingGroupProps, "L3FirewallRule">>();

  const { params: props } = route;
  const rule = props?.rule;

  const networkId = useCurrentNetworkId();
  const updateL3FirewallRule = useUpdateL3FirewallRules();

  const methods = useForm<FormL3Rule>({
    values: rule
      ? {
          ...rule,
          policy: rule?.policy === "allow",
        }
      : undefined,
  });

  useEffect(() => {
    const onSubmit = (rule: FormL3Rule) => {
      updateL3FirewallRule.mutate(
        {
          networkId,
          params: {
            rules: [
              {
                ...rule,
                policy: rule.policy ? "allow" : "deny",
                srcCidr: rule.srcCidr || "Any",
                srcPort: rule.srcPort || "Any",
                destCidr: rule.destCidr || "Any",
                destPort: rule.destPort || "Any",
              },
            ],
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: useL3FirewallRules.queryKey({ networkId }) });
            navigation.goBack();
          },
          onError: (error) =>
            showErrorAlert(String(error["errors"] ?? I18n.t("SERVER_ERROR_TEXT"))),
        },
      );
    };

    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          disabled={!methods.formState.isDirty}
          onPress={methods.handleSubmit(onSubmit)}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, networkId, updateL3FirewallRule]);

  const protocol = methods.watch("protocol");
  return (
    <Screen>
      <Form {...methods}>
        {updateL3FirewallRule.isLoading && <Loader.Spinner animate />}
        <Box paddingHorizontal="sm" gap="xs">
          <Form.Input
            name="comment"
            label={I18n.t("L3_FIREWALL_RULE.COMMENT.PLACEHOLDER")}
            placeholder={I18n.t("L3_FIREWALL_RULE.COMMENT.PLACEHOLDER")}
            testID="L3_FIREWALL_RULE.COMMENT"
          />
          <List>
            <List.Item
              title={I18n.t("L3_FIREWALL_RULE.POLICY.SWITCH_TITLE")}
              rightAccessory={<Form.Toggle name="policy" testID="L3_FIREWALL_RULE.POLICY_SWITCH" />}
            />
          </List>
        </Box>
        <Form.FlashList
          name="protocol"
          label={I18n.t(`L3_FIREWALL_RULE.PROTOCOL.TITLE`)}
          rules={{ required: I18n.t("PROTOCOL.REQUIRED") }}
          data={PROTOCOL_OPTIONS}
          getItemData={(item) => {
            const itemUpperCase = item.toUpperCase() as Uppercase<typeof item>;
            return {
              kind: "radio",
              title: I18n.t(`L3_FIREWALL_RULE.PROTOCOL.${itemUpperCase}`),
              radioValue: item,
              testID: `L3_FIREWALL_RULE.PROTOCOL.${protocol}`,
            };
          }}
          paddingTop="none"
          paddingBottom="none"
        />
        <Box paddingHorizontal="sm" gap="xs">
          <Heading>{I18n.t("L3_FIREWALL_RULE.HEADING.SOURCE")}</Heading>
          <Form.Input
            name="srcCidr"
            label={I18n.t("L3_FIREWALL_RULE.CIDR.TITLE")}
            placeholder={"192.168.128.20/30"}
            rules={validateIPAddressRule}
            keyboardType="numeric"
            disabled={protocol === "Any"}
            additionalContext={<Text>{I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_CIDR")}</Text>}
            testID="L3_FIREWALL_RULE.SOURCE_CIDR"
          />
          <Form.Input
            name="srcPort"
            label={I18n.t("L3_FIREWALL_RULE.CIDR.PORT.TITLE")}
            placeholder={I18n.t("L3_FIREWALL_RULE.CIDR.PORT.PLACEHOLDER")}
            keyboardType="numbers-and-punctuation"
            disabled={protocol === "Any"}
            additionalContext={<Text>{I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_PORT")}</Text>}
            testID="L3_FIREWALL_RULE.SOURCE_PORT"
          />
          <Heading>{I18n.t("L3_FIREWALL_RULE.HEADING.DESTINATION")}</Heading>
          <Form.Input
            name="destCidr"
            label={I18n.t("L3_FIREWALL_RULE.CIDR.TITLE")}
            placeholder={"192.168.128.20/30"}
            rules={validateIPAddressRule}
            keyboardType="numbers-and-punctuation"
            disabled={protocol === "Any"}
            additionalContext={<Text>{I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_CIDR")}</Text>}
            testID="L3_FIREWALL_RULE.DEST_CIDR"
          />
          <Form.Input
            name="destPort"
            label={I18n.t("L3_FIREWALL_RULE.CIDR.PORT.TITLE")}
            placeholder={I18n.t("L3_FIREWALL_RULE.CIDR.PORT.PLACEHOLDER")}
            keyboardType="numeric"
            disabled={protocol === "Any"}
            additionalContext={<Text>{I18n.t("L3_FIREWALL_RULE.CIDR.LEAVE_BLANK_PORT")}</Text>}
            testID="L3_FIREWALL_RULE.DEST_PORT"
          />
        </Box>
      </Form>
    </Screen>
  );
}
