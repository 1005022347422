import { request } from "../../api/request/request";
import { LoginSecuritySchema, NewLoginSecurity } from "../../schemas/LoginSecurity";
import { createMutation } from "../createMutation";

interface LoginSecurityRequest {
  organizationId: string | undefined;
  newLoginSecurity: NewLoginSecurity;
}

function buildMutationUrl(organizationId: string | undefined) {
  return `/api/v1/organizations/${organizationId}/loginSecurity`;
}

export const mutateLoginSecurity = ({ organizationId, newLoginSecurity }: LoginSecurityRequest) => {
  const body = JSON.stringify(newLoginSecurity);
  return request(LoginSecuritySchema, "PUT", buildMutationUrl(organizationId), { body });
};

export const useLoginSecurityMutation = createMutation({
  baseMutationKey: buildMutationUrl("{organizationId}"),
  mutationFn: (options: LoginSecurityRequest) => mutateLoginSecurity(options),
});
