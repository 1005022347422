import { AppliancePort, PortVlanTypes, StagedAppliancePort } from "@meraki/shared/api";
import { isEmpty, uniq } from "lodash";

import { CombinedAppliancePort } from "~/api/queries/appliances/useCombinedAppliancePorts";
import { isString } from "~/lib/TypeHelper";
import { GXPort } from "~/shared/types/ApplianceTypes";
import { SwitchPort } from "~/shared/types/Models";

const GX_UPLINK_PORT_NUMBER = 1;

const GX_PORT_EDITABLE_ATTRIBUTES = ["enabled"];

type GXPortResolvedEditableValues = {
  enabled: boolean | undefined;
};

export const getBatchAppliancePortUpdateDiff = (
  appliancePorts: AppliancePort[],
  modifiedAppliancePort: StagedAppliancePort,
): AppliancePort[] => {
  return appliancePorts.map((port) => {
    const mergedPort: AppliancePort = {
      ...port,
      ...modifiedAppliancePort,
    };

    /*
     * Setting `vlan` and `dropUntaggedTraffic` is a workaround for MX-10919
     * https://jira.ikarem.io/browse/MX-10919
     */
    if (mergedPort.enabled) {
      mergedPort.dropUntaggedTraffic = false;
      mergedPort.vlan = mergedPort.vlan ?? 1;

      // "Access Policy is not a Trunk port field." response handling
      if (mergedPort.type === PortVlanTypes.access) {
        mergedPort.accessPolicy = "open";
        delete mergedPort.allowedVlans;
      } else if (mergedPort.type === PortVlanTypes.trunk) {
        mergedPort.accessPolicy = undefined;
        mergedPort.allowedVlans =
          modifiedAppliancePort.allowedVlans !== undefined &&
          modifiedAppliancePort.allowedVlans !== ""
            ? modifiedAppliancePort.allowedVlans
            : "all";
      }
    }
    return mergedPort;
  });
};

export const getPortsToDisplay = (ports?: CombinedAppliancePort[]) => {
  if (ports == null) {
    return [];
  }
  const realPorts = ports.filter((port) => port.number === 1 || typeof port.enabled === "boolean");

  return realPorts;
};

export const gxPortLiveBrokerIndexForNum = (num: number): number => {
  if (num < 1) {
    console.warn("GX livebroker - port number must be greater than 0");
  }

  return num - 1;
};

export const gxPortVlanSettingsIndexForNum = (num: number): number => {
  if (num < 1) {
    console.warn("GX vlan settings - port number must be greater than 0");
  }

  return num - 1;
};

export const isUplinkPort = (port?: AppliancePort): boolean =>
  port?.number === GX_UPLINK_PORT_NUMBER;

export function resolvedEditableAttributeValues(
  perPortVlanSettings: GXPort[],
): GXPortResolvedEditableValues {
  if (isEmpty(perPortVlanSettings)) {
    return { enabled: undefined };
  }

  return GX_PORT_EDITABLE_ATTRIBUTES.reduce((obj, key) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const allValues = perPortVlanSettings.map((port) => port[key]);
    const uniqueValues = uniq(allValues);
    const resolvedValue = uniqueValues.length === 1 ? uniqueValues[0] : undefined;
    return { ...obj, [key]: resolvedValue };
  }, {}) as GXPortResolvedEditableValues;
}

export function isPortEnabled(port: CombinedAppliancePort) {
  return port?.number === 1 || port?.enabled === true;
}

export function convertToPrivateApi(port: null | Partial<GXPort>): null | Partial<SwitchPort> {
  if (!port) {
    return null;
  }

  const { type, vlan, allowedVlans } = port;
  const isTrunk = type === "trunk";

  if (isTrunk) {
    return {
      is_trunk: true,
      native_vid: vlan,
      allowed_vlans: allowedVlans,
    };
  }

  return {
    is_trunk: false,
    vid: vlan,
  };
}

export function convertToPublicApi(port: null | Partial<SwitchPort>): null | Partial<GXPort> {
  if (!port) {
    return null;
  }

  const { is_trunk, vid, native_vid, allowed_vlans } = port;

  const vlanId = is_trunk ? native_vid : vid;
  const vlan = isString(vlanId) ? parseInt(vlanId, 10) : vlanId;

  if (is_trunk) {
    return {
      type: "trunk",
      vlan: vlan,
      allowedVlans: allowed_vlans,
    };
  }

  return {
    type: "access",
    vlan: vlan,
    accessPolicy: "open",
  };
}
