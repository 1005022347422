import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { queryClient, SwitchPort, useSwitchPorts, useUpdateSwitchPort } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { Alert } from "react-native";

import { useSwitchPortDetails } from "../screens/ports/SwitchPortDetailsContext";

type EditTagsForm = {
  newTags: string;
};
interface AddTagSwitchPortBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export const AddTagSwitchPortBottomSheet = ({
  bottomSheetRef,
}: AddTagSwitchPortBottomSheetProps) => {
  const { switchPort, device } = useSwitchPortDetails();

  const { mutate: updateSwitchPort, isLoading } = useUpdateSwitchPort();

  const methods = useForm<EditTagsForm>({
    defaultValues: {
      newTags: "",
    },
  });

  if (!switchPort) {
    return <Loader.Spinner animate={true} />;
  }

  const donePress = () => {
    methods.reset();
    bottomSheetRef?.current?.close();
  };

  const onSave = (formData: EditTagsForm) => {
    const parsedNewTags = formData.newTags.split(/[\s]+/).filter(Boolean);

    const newTagsArray = [...switchPort.tags, ...parsedNewTags];
    updateSwitchPort(
      {
        serial: device?.serial,
        portId: switchPort.portId,
        switchPort: { tags: newTagsArray } as SwitchPort,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: useSwitchPorts.queryKey({ serial: device?.serial }),
          });
          donePress();
        },
        onError(error) {
          Alert.alert(I18n.t("ERROR"), String(error.errors));
        },
      },
    );
  };

  const onSubmit = methods.handleSubmit((data) => onSave(data));

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("PORTS.GOv3.TAGS.ADD_LABEL")}
        onCancelPress={donePress}
        cancelLabel={I18n.t("CANCEL")}
      />
      <BottomSheet.Content>
        <Box gap="sm">
          <Form {...methods}>
            <Form.Input
              name={"newTags"}
              label={I18n.t("PORTS.GOv3.TAGS.ADD_INPUT_LABEL")}
              showClear={false}
              placeholder={I18n.t("PORTS.GOv3.TAGS.PLACEHOLDER")}
              testID="NEW_TAG_INPUT"
            />
          </Form>
          <Button
            text={I18n.t("SAVE")}
            onPress={onSubmit}
            disabled={!methods.formState.isDirty}
            loading={queryClient.isFetching() > 0 || isLoading}
            testID="SAVE_ADDED_TAGS_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
};
