import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { queryClient, SwitchPort, useSwitchPorts, useUpdateSwitchPort } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { Alert } from "react-native";

import { useSwitchPortDetails } from "../screens/ports/SwitchPortDetailsContext";

type EditNameForm = {
  name: string;
};
interface EditSwitchPortBottomSheetProps {
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export const EditSwitchPortBottomSheet = ({ bottomSheetRef }: EditSwitchPortBottomSheetProps) => {
  const { switchPort, device } = useSwitchPortDetails();

  const { mutate: updateSwitchPort, isLoading } = useUpdateSwitchPort();

  const methods = useForm<EditNameForm>({
    defaultValues: {
      name: switchPort?.name ?? "",
    },
  });

  if (!switchPort) {
    return <Loader.Spinner animate={true} />;
  }

  const donePress = () => {
    methods.reset();
    bottomSheetRef?.current?.close();
  };

  const onSave = (formData: EditNameForm) => {
    const name = formData.name === "" ? null : formData.name;
    updateSwitchPort(
      {
        serial: device?.serial,
        portId: switchPort.portId,
        switchPort: { name } as SwitchPort,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: useSwitchPorts.queryKey({ serial: device?.serial }),
          });
          bottomSheetRef?.current?.close();
        },
        onError(error) {
          Alert.alert(I18n.t("ERROR"), String(error.errors));
        },
      },
    );
  };
  const onSubmit = methods.handleSubmit((data) => onSave(data));

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("PORTS.NUMBER", { port_number: switchPort.portId })}
        onResetPress={donePress}
        resetLabel={I18n.t("DONE")}
      />
      <BottomSheet.Content>
        <Box gap="sm">
          <Form {...methods}>
            <Form.Input
              name={"name"}
              label={I18n.t("PORTS.GOv3.EDIT_NAME.LABEL")}
              showClear={false}
              placeholder={I18n.t("PORTS.NUMBER", { port_number: switchPort.portId })}
              testID="PORT_NAME_INPUT"
            />
          </Form>
          <Button
            text={I18n.t("SAVE")}
            onPress={onSubmit}
            disabled={!methods.formState.isDirty}
            loading={queryClient.isFetching() > 0 || isLoading}
            testID="SAVE_BUTTON"
          />
        </Box>
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
};
