import { defaultTimespans, defaultTimespansWithCustom } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import I18n from "~/i18n/i18n";
import { showActionSheet } from "~/lib/AlertUtils";
import { appSelect, isWeb } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

const TIME_PICKER_HEIGHT = 48;
export interface DefaultHeaderProps {
  timespan: number;
  setTimespan: (timespan: number) => void;
  showCustom?: boolean;
  disabled?: boolean;
  screenStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

type TimespanType = {
  label: string;
  value: number;
};

const TimePicker = ({
  timespan,
  setTimespan,
  disabled,
  testID,
  screenStyle,
  showCustom,
}: DefaultHeaderProps) => {
  const navigation = useNavigation();

  const timespanDataSource: TimespanType[] = Object.values(
    showCustom ? defaultTimespansWithCustom : defaultTimespans,
  );

  const getLabelForTimespan = () => {
    const matchingTimespan = timespanDataSource.find((d: TimespanType) => timespan === d.value);
    if (!matchingTimespan) {
      return null;
    }
    const { label } = matchingTimespan;
    return I18n.t(label);
  };

  const currentTimespanLabel = getLabelForTimespan();

  const onPress = (index: number) => {
    if (index < timespanDataSource.length) {
      setTimespan(timespanDataSource[index].value);
    }
  };

  const onPressShowActionSheet = () => {
    const timespans = timespanDataSource.map((d: TimespanType) => {
      const timeLabel = I18n.t(d.label);
      return d.value ? I18n.t("TIMEPICKER_PAST_TIMESPAN", { timespan: timeLabel }) : timeLabel;
    });

    if (isWeb()) {
      showActionSheet(timespans, onPress);
    } else {
      navigation.navigate("ActionSheet", {
        data: timespans,
        onPress,
      });
    }
  };

  const colors = themeColors(useTheme());
  const disabledStyle = disabled ? styles.disabled : {};
  const navColors = colors.navigation;

  const historyIconColor = appSelect({
    go: MkiColors.primaryButton,
    enterprise: navColors.primary,
  });
  const caretIconColor = appSelect({
    go: MkiColors.secondaryTextColor,
    enterprise: TEXT_COLORS.secondary,
  });

  const leftIconStyle = appSelect({
    go: styles.goLeftIcon,
    enterprise: styles.enterpriseLeftIcon,
  });

  return (
    <View style={{ backgroundColor: colors.navigation.backgroundPrimary }}>
      <TouchableOpacity
        style={[
          styles.container,
          {
            borderBottomColor: colors.border?.borderColor,
            borderTopColor: colors.border?.borderColor,
          },
          screenStyle,
          disabledStyle,
        ]}
        onPress={onPressShowActionSheet}
        disabled={disabled}
        testID={testID || "TIME_PICKER"}
      >
        <View style={styles.dataContainer}>
          <MerakiIcon
            name="schedule"
            size="xxs"
            color={historyIconColor}
            containerStyle={leftIconStyle}
          />
          <MkiText screenStyles={{ color: navColors.primary }} textStyle="timePicker" uppercase>
            {timespan
              ? I18n.t("TIMEPICKER_PAST_TIMESPAN", { timespan: currentTimespanLabel })
              : currentTimespanLabel}
          </MkiText>
        </View>
        <MerakiIcon
          name="caret-down"
          size="xxs"
          color={caretIconColor}
          containerStyle={styles.rightIcon}
        />
      </TouchableOpacity>
    </View>
  );
};

TimePicker.defaultProps = {
  showCustom: false,
  screenStyle: {},
  disabled: false,
};

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.5,
  },
  dataContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: TIME_PICKER_HEIGHT,
    ...appSelect({
      go: {
        borderBottomColor: MkiColors.borderColor,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderTopColor: MkiColors.borderColor,
        borderTopWidth: StyleSheet.hairlineWidth,
        marginHorizontal: SPACING.default,
        marginTop: SPACING.default,
      },
      enterprise: {
        borderBottomWidth: 1,
      },
    }),
  },
  enterpriseLeftIcon: {
    marginLeft: 14,
    marginRight: 22,
  },
  goLeftIcon: {
    marginHorizontal: SPACING.default,
  },
  rightIcon: {
    marginRight: SPACING.default,
  },
});

export default TimePicker;
