import MkiColors from "~/constants/MkiColors";
import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";
import SharedColors from "~/shared/constants/SharedColors";
import ThemeInterface from "~/shared/themes/ThemeInterface";

import MagneticColors from "../constants/MagneticColors";

// empty {} to take component defined styles
const Light: ThemeInterface = {
  colors: {
    merakiGreen: SharedColors.green,
  },
  navigation: {
    primary: EnterpriseColors.purple,
    secondary: EnterpriseColors.gray40Darker,
    backgroundPrimary: SharedColors.white,
    backgroundSecondary: EnterpriseColors.gray90Lighter,
    textSecondary: EnterpriseColors.textSecondary,
    textTertiary: EnterpriseColors.textTertiary,
    tabBorder: SharedColors.gray70Lighter,
  },
  modal: {
    background: SharedColors.white,
  },
  status: {
    online: {
      color: SharedColors.green,
      background: SharedColors.greenLightest,
    },
    offline: {
      color: SharedColors.errorRed,
      background: SharedColors.redLightest,
    },
    alerting: {
      color: SharedColors.yellow,
      background: SharedColors.yellowLightest,
    },
    dormant: {
      color: SharedColors.gray50,
      background: SharedColors.gray50,
    },
  },
  spinner: {
    color: SharedColors.gray70,
  },
  spinnerColor: {
    backgroundColor: SharedColors.whiteFaded80,
  },
  graph: {
    gridColor: SharedColors.gray90,
    barChartBackground: SharedColors.gray90,
  },
  locationAnalyticsLegend: {
    selectedLabel: {
      color: SharedColors.gray30,
    },
    unselectedLabel: {
      color: MkiColors.graphLegendLabelColor,
    },
  },
  border: {
    borderColor: SharedColors.gray90Darker,
    strongBorderColor: SharedColors.gray80,
  },
  tileBorderColor: SharedColors.gray90Darker,
  text: {
    accordianHeader: {
      color: EnterpriseColors.gray10,
    },
    title: {
      color: EnterpriseColors.gray20,
    },
    announcement: {
      color: SharedColors.gray10,
    },
    heading: {
      color: EnterpriseColors.gray30,
    },
    subheader: {
      color: SharedColors.gray10,
    },
    subheading: {
      color: SharedColors.gray10,
    },
    subtitle: {
      color: EnterpriseColors.neutral12,
    },
    small: {
      color: SharedColors.gray10,
    },
    smallBold: {
      color: SharedColors.gray10,
    },
    placeholder: {
      color: SharedColors.gray40,
    },
    disabled: {
      color: SharedColors.gray40,
    },
    default: {
      color: SharedColors.gray10,
    },
    defaultBold: {
      color: SharedColors.gray10,
    },
    smallSecondary: {
      color: SharedColors.gray40,
    },
    active: {
      color: SharedColors.gray40,
    },
    inactive: {
      color: SharedColors.gray88,
    },
    pressable: {
      color: EnterpriseColors.purple,
    },
    redAlerting: {
      color: EnterpriseColors.red10,
    },
  },
  closeButton: SharedColors.trueBlack,
  selectedColor: {
    backgroundColor: SharedColors.gray90,
  },
  infoCard: {
    backgroundColor: EnterpriseColors.gray97,
  },
  localAddressing: {
    backgroundColor: SharedColors.gray90Lighter,
  },
  frontPanel: {
    backgroundColor: SharedColors.gray80Lighter,
  },
  roundedButtons: {
    destructive: {
      button: {},
      buttonText: {},
    },
    secondary: {
      button: {},
      buttonText: {},
    },
  },
  selectedRowColor: SharedColors.purpleLighter,
  searchBar: {},
  gridItem: {
    borderColor: SharedColors.gray80Lighter,
    backgroundColor: SharedColors.gray90Lighter,
  },
  utilityBar: {
    color: SharedColors.gray20,
  },
  inactive: {
    color: SharedColors.gray88,
  },
  skeletonBlock: {
    backgroundColor: SharedColors.gray90Darker,
  },
  graphColors: {
    barChartDefaultFill: SharedColors.teal,
    goodBar: EnterpriseColors.green,
    online: EnterpriseColors.green,
    offline: EnterpriseColors.red,
    lightGreenWarningStatus: SharedColors.greenLighter,
    alerting: SharedColors.yellow,
    offlineBar: SharedColors.gray50,
    applicationDetailsClientBar: SharedColors.green,
    unknown: EnterpriseColors.gray70Lighter,
    other: SharedColors.teal,
    pie1: SharedColors.teal,
    pie2: SharedColors.chartBlue1,
    pie3: SharedColors.chartBlue2,
    pie4: SharedColors.chartMagenta,
    pie5: SharedColors.chartPurple,
    pie6: SharedColors.tealLighter,
    pie7: SharedColors.gray50,
    lblue: EnterpriseColors.lblue,
    lpink: EnterpriseColors.lpink,
    lgreen: EnterpriseColors.lgreen,
    mblue: EnterpriseColors.mblue,
    lpurple: EnterpriseColors.lpurple,
    morange: EnterpriseColors.morange,
    lorange: EnterpriseColors.lorange,
    mpink: EnterpriseColors.mpink,
    mgreen: EnterpriseColors.mgreen,
    mpurple: EnterpriseColors.mpurple,
    othergrey: SharedColors.gray70Lighter,
  },
  port: {
    active: SharedColors.green,
    alerting: SharedColors.errorRed,
    disconnected: SharedColors.gray10,
    disabled: SharedColors.gray50,
    poeActive: SharedColors.white,
    uplinkActive: SharedColors.white,
  },
  icon: {
    tabBar: {
      selected: EnterpriseColors.green,
    },
    backgroundColor: EnterpriseColors.neutral3,
    stroke: EnterpriseColors.neutral12,
  },
  mapStatusColors: {
    online: EnterpriseColors.green,
    alerting: SharedColors.orange,
    offline: SharedColors.errorRed,
    dormant: SharedColors.gray50,
  },
  IAQ: {
    excellent: EnterpriseColors.excellent,
    good: EnterpriseColors.good,
    fair: EnterpriseColors.fair,
    poor: EnterpriseColors.poor,
    inadequate: EnterpriseColors.inadequate,
  },
  accordian: {
    alert: {
      background: MagneticColors.neutral1,
      pressed: MagneticColors.neutral2,
      chevron: EnterpriseColors.gray30,
      text: EnterpriseColors.gray30,
    },
  },
};

export default Light;
