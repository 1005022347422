import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { LayoutAnimation } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useCombinedAppliancePorts } from "~/api/queries/appliances/useCombinedAppliancePorts";
import GXPortListRow from "~/go/rows/ports/GXPortListRow";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { getPortsToDisplay, isUplinkPort } from "~/lib/GXPortUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import ApplianceFront from "~/shared/components/portDiagrams/ApplianceFront";
import { TextButton } from "~/shared/navigation/Buttons";

import { HardwareStackPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "GXPortsList">;

export const GXPortsListScreen = (props: Props) => {
  const { title, serialNumber, filter, device } = props;
  setupAndroidLayoutAnimation();

  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const { combinedPortData, refetchApiData } = useCombinedAppliancePorts(networkId, device.id);

  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [selectedPorts, setSelectedPorts] = useState<number[]>([]);

  useLayoutEffect(() => {
    let buttonTitle = I18n.t("SETTINGS_WORD");
    if (selectMode && selectedPorts.length === 0) {
      buttonTitle = I18n.t("CANCEL");
    }
    if (selectMode && selectedPorts.length > 0) {
      buttonTitle = `${I18n.t("SETTINGS_WORD")} (${selectedPorts.length})`;
    }

    navigation.setOptions({
      headerTitle: title,
      headerRight: () => (
        <TextButton
          title={buttonTitle}
          onPress={() => {
            if (selectMode && selectedPorts.length > 0) {
              pushPortSettings();
            } else {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              setSelectMode(!selectMode), setSelectedPorts([]);
            }
          }}
        />
      ),
    });
  });

  const pushPortSettings = () => {
    navigation.navigate("AppliancePortSettings", {
      serialNumber: serialNumber,
      selectedPorts: selectedPorts.map((id) => combinedPortData[id - 1]),
      onDismiss: () => refetchApiData(),
    });
  };

  const pushGXPortDetails = (rowData: any) => {
    const { port } = rowData;

    navigation.navigate("GXPortDetails", {
      deviceSerial: serialNumber,
      portNumber: port.number,
      device,
    });
  };

  const selectPort = (portNumber: number) => {
    const mutatedPorts = Object.assign([], selectedPorts);
    const index = mutatedPorts.indexOf(portNumber);
    if (index > -1) {
      mutatedPorts.splice(index, 1);
    } else {
      mutatedPorts.push(portNumber);
    }
    setSelectedPorts(mutatedPorts);
  };

  const selectGXPort = (rowData: any) => {
    const { port } = rowData;
    selectPort(port.number);
  };

  const renderGXRow = (rowData: any) => {
    const { onPress, selected, selectMode, port } = rowData;
    const selectable = !selectMode || !isUplinkPort(port);

    return (
      <GXPortListRow
        livePortData={port}
        selectMode={selectMode}
        selected={selected}
        selectable={selectable}
        onPress={onPress}
        key={`${port.id}.${port.number}`}
        testID={`PORT_${String(port.number)}.${port.enabled ? "ENABLED" : "DISABLED"}`}
      />
    );
  };

  const getGXRowData = () => {
    const realPorts = getPortsToDisplay(combinedPortData);
    return filter(realPorts).map((port) => ({
      port,
      selected: selectedPorts.includes(port.number),
      selectMode,
    }));
  };

  const renderTable = () => {
    const onPress = selectMode ? selectGXPort : pushGXPortDetails;
    return (
      <MkiTable
        data={getGXRowData()}
        keyExtractor={(data: any) => String(data.port.number)}
        renderRow={renderGXRow}
        onPress={onPress}
        onRefresh={() => refetchApiData()}
        testID="PORTS_LIST_SCROLL_VIEW"
      />
    );
  };

  return (
    <FullScreenContainerView>
      <ApplianceFront serialNumber={serialNumber} />
      {renderTable()}
    </FullScreenContainerView>
  );
};

export default GXPortsListScreen;
