import Svg, { G, Path } from "react-native-svg";

function MagneticSystemsManagerIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <G opacity="0.4">
        <Path
          d="M3 4C2.44772 4 2 4.44772 2 5V15C2 15.5523 2.44772 16 3 16H14.5V9C14.5 8.17157 15.1716 7.5 16 7.5H19V5C19 4.44772 18.5523 4 18 4H3Z"
          fill="#8F8F8F"
        />
        <Path
          d="M13 17H9V19H7.5C7.22386 19 7 19.2239 7 19.5C7 19.7761 7.22386 20 7.5 20H14C14.2761 20 14.5 19.7761 14.5 19.5C14.5 19.2239 14.2761 19 14 19H13V17Z"
          fill="#8F8F8F"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 9C16.4477 9 16 9.44771 16 10V19C16 19.5523 16.4477 20 17 20H21C21.5523 20 22 19.5523 22 19V10C22 9.44772 21.5523 9 21 9H17ZM19 18.5C19.4142 18.5 19.75 18.1642 19.75 17.75C19.75 17.3358 19.4142 17 19 17C18.5858 17 18.25 17.3358 18.25 17.75C18.25 18.1642 18.5858 18.5 19 18.5Z"
        fill="#8F8F8F"
      />
    </Svg>
  );
}

export default MagneticSystemsManagerIcon;
