import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { debounce, isEmpty, merge } from "lodash";
import { createRef, PureComponent } from "react";
import { Appearance, StyleSheet, TextStyle } from "react-native";
import {
  SearchBar as ElementsSearchBar,
  SearchBarProps as ElementsSearchBarProps,
} from "react-native-elements";

import { CHANGE_TEXT_TIME_SPACER, SPACING } from "~/constants/MkiConstants";
import { BORDER_COLORS, SEARCH_BARS } from "~/enterprise/constants/Colors";
import { FONT_SIZES, FontFamily } from "~/enterprise/constants/Fonts";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import SharedColors from "~/shared/constants/SharedColors";

export const SEARCH_BAR_ICON_SIZE = 20;
const SEARCH_BAR_ICON_CLEAR_PADDING = SPACING.tiny * 2;
const SEARCH_BAR_ICON_CLEAR_SIZE = SEARCH_BAR_ICON_SIZE - SEARCH_BAR_ICON_CLEAR_PADDING * 2;

export interface SearchBarProps extends ElementsSearchBarProps {
  testID?: string;
  onClearPressed?: ElementsSearchBarProps["onClear"];
  withSearchBarBorder?: boolean;
  transparentBackground?: boolean;
}

interface SearchBarState {
  value: string;
  onChangeTextCached?: SearchBarProps["onChangeText"];
  onChangeTextDebounced?: SearchBarProps["onChangeText"];
}

const mergeStyle = (style: StyleSheet.NamedStyles<any>) =>
  StyleSheet.create(merge(stylesBase(), style));

export class SearchBar extends PureComponent<SearchBarProps, SearchBarState> {
  static getDerivedStateFromProps(
    props: SearchBarProps,
    state: SearchBarState,
  ): Partial<SearchBarState> | null {
    const { onChangeText } = props;
    const { onChangeTextCached } = state;

    if (onChangeText && onChangeText !== onChangeTextCached) {
      return {
        onChangeTextCached: onChangeText,
        onChangeTextDebounced: debounce((value: string) => {
          onChangeText(value);
        }, CHANGE_TEXT_TIME_SPACER),
      };
    }

    return null;
  }

  constructor(props: SearchBarProps) {
    super(props);
    const { onChangeText } = props;
    const value = props.defaultValue || "";

    this.state = { value };
    onChangeText?.(value);
  }

  searchBar: React.RefObject<ElementsSearchBar> = createRef();

  onChangeText = (value: string) => {
    const { onChangeTextDebounced } = this.state;
    this.setState({ value });

    onChangeTextDebounced?.(value);
  };

  onClearPressed = () => {
    const { onClear } = this.props;

    this.onChangeText("");
    onClear?.();
    this?.searchBar?.current?.focus();
  };

  renderClearIcon = () => {
    const { value } = this.state;
    const styles = stylesForTheme();

    return !!value ? (
      <MerakiIcon
        name="close"
        size={SEARCH_BAR_ICON_CLEAR_SIZE}
        style={styles.searchBarClearIcon}
        onPress={this.onClearPressed}
        testID="search-bar-clear"
      />
    ) : (
      false
    );
  };

  render() {
    const { withSearchBarBorder, placeholder, transparentBackground, ...rest } = this.props;
    const { value } = this.state;

    const styles = stylesForTheme();

    const inputContainerStyle = styles.searchBarInputContainer;
    const containerStyle = withSearchBarBorder
      ? styles.withBottomBorder
      : styles.searchBarContainer;
    if (transparentBackground) {
      //@ts-ignore
      containerStyle.backgroundColor = SharedColors.transparent;
      //@ts-ignore
      inputContainerStyle.backgroundColor = SharedColors.transparent;
    }

    return (
      <ElementsSearchBar
        ref={this.searchBar}
        clearIcon={this.renderClearIcon()}
        containerStyle={containerStyle}
        inputContainerStyle={inputContainerStyle}
        inputStyle={styles.searchBarInput}
        placeholder={placeholder ?? I18n.t("SEARCH_BAR.DEFAULTS.SEARCH_PLACEHOLDER")}
        placeholderTextColor={(styles.placeholderText as TextStyle).color}
        searchIcon={
          //@ts-ignore
          <MerakiIcon name="search" size={SEARCH_BAR_ICON_SIZE} color={styles.searchIcon.color} />
        }
        {...rest}
        value={value}
        onChangeText={this.onChangeText}
        onClear={this.onClearPressed}
      />
    );
  }
}

const stylesForTheme = () => {
  const { theme } = useTheme.getState();
  const schemeStyle = themeColors(theme).searchBar;
  // Temp override for now until DM darkmode ticket
  if (!isEmpty(schemeStyle)) {
    return mergeStyle(schemeStyle);
  }

  const normalizedTheme = theme === "system" ? Appearance.getColorScheme() : theme;

  switch (normalizedTheme) {
    case "dark":
    case "amoled":
    case "darkDeuteranomaly":
      return mergeStyle(stylesForSearchBarKey("dark"));
    case "light":
    default:
      return mergeStyle(stylesForSearchBarKey("light"));
  }
};

const stylesBase = () => ({
  searchBarContainer: {
    // Top and Bottom are needed here.  'border' gets overridden.
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  withBottomBorder: {
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: BORDER_COLORS.light,
  },
  searchBarInput: {
    fontFamily: FontFamily.default,
    fontSize: FONT_SIZES.default,
  },
  searchBarInputContainer: {
    borderRadius: SPACING.small,
  },
  searchBarClearIcon: {
    borderRadius: SEARCH_BAR_ICON_SIZE / 2.0,
    borderTopLeftRadius: SEARCH_BAR_ICON_SIZE / 2.0,
    overflow: "hidden" as const,
    padding: SEARCH_BAR_ICON_CLEAR_PADDING,
  },
});

const stylesForSearchBarKey = (searchBarColorKey: keyof typeof SEARCH_BARS) => {
  const colorScheme = SEARCH_BARS[searchBarColorKey];

  return {
    placeholderText: {
      color: colorScheme.placeholder,
    },
    searchBarContainer: {
      backgroundColor: colorScheme.background,
    },
    withBottomBorder: {
      backgroundColor: colorScheme.background,
    },
    searchBarInput: {
      color: colorScheme.text,
    },
    searchBarInputContainer: {
      backgroundColor: colorScheme.inputBackground,
    },
    searchBarClearIcon: {
      backgroundColor: colorScheme.clearIconBackground,
      color: colorScheme.clearIcon,
    },
    searchIcon: {
      color: colorScheme.iconColor,
    },
  };
};

export default SearchBar;
