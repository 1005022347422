import { Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken, mapColorToken, useMagneticTheme } from "@meraki/magnetic/themes";
import SvgChart from "@wuba/react-native-echarts/svgChart";
import Color from "color";
import type {
  DimensionDefinitionLoose,
  EChartsOption,
  LabelFormatterParams,
  MarkArea1DDataItemOption,
  MarkArea2DDataItemOption,
  ScaleDataValue,
  VisualPiece,
} from "echarts";
import * as echarts from "echarts/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

import { baseChartColors, getColorForIndex } from "../utils/colors";
import { ChartData, getDimensionName } from "../utils/data";

type ChartSize = "sm" | "md" | "lg";
interface LineChartProps {
  testID?: string;
  size?: ChartSize;
  data: ChartData;
  dimensions: (DimensionDefinitionLoose & { lineType?: "solid" | "dotted" })[];
  showAnimation?: boolean;
  showLegend?: boolean;
  loading?: boolean;
  tooltipLabelFormatter?: (timespan: ScaleDataValue) => string;
  xAxisFormatter?: (timespan: ScaleDataValue | number) => string;
  yAxisFormatter?: (value: object | string | number) => string;
  yAxisMin?: ((value: { min: number; max: number }) => number) | number;
  yAxisMax?: ((value: { min: number; max: number }) => number) | number;
  thresholds?: { value: number; direction: string; color: ColorToken }[];
  conditionalRanges?: (VisualPiece & { color: ColorToken })[];
  markAreaColor?: ColorToken;
  markArea?: (MarkArea1DDataItemOption | MarkArea2DDataItemOption)[];
}

const getAspectRatio = (width: number, size: ChartSize) => {
  switch (size) {
    case "sm":
      return width >= 480 ? 0.15 : 0.3;
    case "md":
      return width >= 480 ? 0.3 : 0.6;
    case "lg":
      return width >= 480 ? 0.5 : 0.8;
  }
};

const getNumberOfXAxisTicks = (width: number | null) => {
  if (!!width && width > 0) {
    if (width <= 480) {
      return 5;
    } else if (width <= 768) {
      return 6;
    } else {
      return 10;
    }
  }
  return 5;
};

const getNumberOfYAxisTicks = (width: number | null) => {
  if (!!width && width > 0) {
    if (width <= 480) {
      return 3;
    } else if (width <= 768) {
      return 6;
    } else {
      return 7;
    }
  }
  return 5;
};

const generateThresholdLines = (thresholds: { value: number; direction: string }[]) => {
  const thresholdLines = [];
  for (let i = 0; i < thresholds.length; i++) {
    thresholdLines.push({
      yAxis: thresholds[i]?.value,
    });
  }
  return thresholdLines;
};

const defaultYAxisMin = (value: { min: number; max: number }) => {
  return value.min - Math.min(value.max - value.min, 10);
};

const defaultYAxisMax = (value: { min: number; max: number }) => {
  return value.max + Math.min(value.max - value.min, 10);
};

export function LineChart({
  testID,
  size = "md",
  data,
  showAnimation = true,
  showLegend = true,
  loading = false,
  xAxisFormatter,
  yAxisFormatter,
  yAxisMin,
  yAxisMax,
  tooltipLabelFormatter,
  dimensions,
  thresholds,
  conditionalRanges,
  markAreaColor = "negative.bg.medium.active",
  markArea,
}: LineChartProps) {
  const theme = useMagneticTheme();
  const chartRef = useRef(null);
  const [chartInstance, setChart] = useState<echarts.EChartsType | null>(null);

  const [width, setWidth] = useState<number | null>(null);

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  // This effect is responsible for the basic setup of the chart instance.
  useEffect(() => {
    if (!width) return;

    const chart = echarts.init(chartRef.current, "light", {
      renderer: "svg",
      width: !!width && width > 0 ? width : undefined,
      height: !!width && width > 0 ? width * getAspectRatio(width, size) : undefined,
    });

    setChart(chart);

    return () => chart.dispose();
  }, [size, width]);

  // This effect is responsible for setting the basic chart options
  useEffect(() => {
    chartInstance?.setOption({
      animation: showAnimation,
      animationDuration: 500,
      animationEasingUpdate: "quinticInOut",
      grid: {
        containLabel: true,
        top: showLegend ? 25 : 10,
        left: 0,
        right: 0,
        bottom: 0,
      },
      dataZoom: [
        {
          type: "inside",
          throttle: 50,
          filterMode: "none",
        },
      ],
      tooltip: {
        trigger: "axis",
        confine: true,
        backgroundColor: mapColorToken(baseChartColors.toolTipBackground, theme),
        borderColor: mapColorToken(baseChartColors.toolTipBorder, theme),
        valueFormatter: yAxisFormatter,
        textStyle: {
          color: mapColorToken(baseChartColors.toolTipText, theme),
        },
        axisPointer: {
          lineStyle: {
            type: "solid",
            color: mapColorToken(baseChartColors.toolTipBorder, theme),
          },
          label: {
            formatter:
              tooltipLabelFormatter || xAxisFormatter
                ? (params: LabelFormatterParams): string => {
                    return (
                      tooltipLabelFormatter?.(params.value) || xAxisFormatter?.(params.value) || ""
                    );
                  }
                : undefined,
          },
        },
      },
      legend: {
        show: showLegend,
        top: 0,
        right: 5,
        icon: "roundRect",
        textStyle: { color: mapColorToken(baseChartColors.axisText, theme) },
      },
      xAxis: {
        type: "time",
        axisLabel: {
          hideOverlap: true,
          padding: [0, 10],
          borderWidth: 0.5,
          borderColor: "transparent",
          color: mapColorToken(baseChartColors.axisText, theme),
          formatter: xAxisFormatter,
        },
        axisLine: {
          lineStyle: {
            color: mapColorToken(baseChartColors.axisLines, theme),
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: mapColorToken(baseChartColors.gridLines, theme),
          },
        },
        splitNumber: getNumberOfXAxisTicks(width),
      },
      yAxis: {
        min: yAxisMin ?? defaultYAxisMin,
        max: yAxisMax ?? defaultYAxisMax,
        scale: true,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: mapColorToken(baseChartColors.gridLines, theme),
          },
        },
        axisLabel: {
          hideOverlap: true,
          padding: [3, 0],
          borderWidth: 0.5,
          borderColor: "transparent",
          color: mapColorToken(baseChartColors.axisText, theme),
          formatter: yAxisFormatter,
        },
        splitNumber: getNumberOfYAxisTicks(width),
      },
    });
  }, [
    chartInstance,
    showAnimation,
    showLegend,
    theme,
    tooltipLabelFormatter,
    width,
    xAxisFormatter,
    yAxisFormatter,
    yAxisMax,
    yAxisMin,
  ]);

  // This effect is responsible for setting the data related options
  useEffect(() => {
    const generateSeries = () => {
      const series: EChartsOption["series"] = [];
      for (let i = 0; i < dimensions?.length - 1; i++) {
        const dimension = dimensions[i + 1];
        const dimensionName = getDimensionName(dimension);
        const lineType = dimension?.lineType ?? "solid";

        series.push({
          type: "line",
          color: getColorForIndex(i, theme),
          symbol: "none",
          showSymbol: false,
          connectNulls: true,
          clip: false,
          lineStyle: {
            type: lineType,
          },
          encode: {
            x: 0,
            y: [dimensionName],
            seriesName: [dimensionName],
          },
          markArea: {
            itemStyle: {
              color: Color(mapColorToken(markAreaColor, theme)).alpha(0.5).hexa(),
            },
            emphasis: {
              disabled: true,
            },
            data: markArea,
          },
          markLine: thresholds
            ? {
                data: generateThresholdLines(thresholds),
                symbol: "none",
                lineStyle: {
                  color: mapColorToken(baseChartColors.thresholdLines, theme),
                  type: "dashed",
                  dashOffset: 20,
                  width: 2,
                },
              }
            : undefined,
        });
      }
      return series.slice(0, 10);
    };

    chartInstance?.setOption({
      dataset: {
        dimensions: dimensions,
        source: data,
      },
      series: generateSeries(),
    });
  }, [chartInstance, theme, dimensions, data, thresholds, markArea, markAreaColor]);

  // This effect is responsible for setting the visual map options
  // moved to the end after data is set so we can potentially use chartInstance.getOption().series or something of the sort
  useEffect(() => {
    if (!conditionalRanges || (conditionalRanges?.length ?? 0) === 0) {
      chartInstance?.setOption({
        visualMap: null,
      });

      return;
    }

    chartInstance?.setOption({
      visualMap: {
        top: 50,
        right: 10,
        show: false,
        pieces: conditionalRanges.map((cr) => ({ ...cr, color: mapColorToken(cr.color, theme) })),
        outOfRange: {
          color: getColorForIndex(0, theme),
        },
        textStyle: {
          color: mapColorToken(baseChartColors.axisText, theme),
        },
      },
    });
  }, [chartInstance, conditionalRanges, theme, thresholds]);

  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <SvgChart ref={chartRef} useRNGH />
      {loading && (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Color(theme.color.default.bg.weak.base).alpha(0.8).hexa(),
          }}
        >
          <Loader.Spinner />
        </View>
      )}
    </Box>
  );
}
