import { sortBy } from "lodash";

import { PickerModalItems } from "~/go/components/PickerModal";
import { TimezoneRegexByRegion } from "~/go/types/RegistrationTypes";

export const getRegionTimezonesSorted = (tzOptions: PickerModalItems[], region: string) => {
  if (region == null) {
    return tzOptions;
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const timezoneRegex: RegExp = TimezoneRegexByRegion[region];
  return sortBy(tzOptions, (tz: PickerModalItems) => !timezoneRegex.test(tz?.label || ""));
};
