import { useSwitchPortStatuses } from "@meraki/react-live-broker";
import { StyleSheet, useWindowDimensions, View } from "react-native";

import { useAllSwitchPorts } from "~/api/queries/switches/useSwitchPorts";
import MkiColors from "~/constants/MkiColors";
import { SPACING, SWITCH_PANEL_WIDTH } from "~/constants/MkiConstants";
import { DeviceChassis } from "~/constants/PortLayouts";
import { appSelect, isWeb, platformSelect } from "~/lib/PlatformUtils";
import { deriveSwitchPortStatuses, getSwitchLayout } from "~/lib/SwitchUtils";
import { sizeSelectWidth, themeColors } from "~/lib/themeHelper";
import { devicesState } from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import PortBlocks from "~/shared/components/portDiagrams/PortBlocks";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";

const useSwitchPanelWidth = () => {
  const { width: windowWidth } = useWindowDimensions();
  const compactPanelWidth = windowWidth - SPACING.default;
  return sizeSelectWidth({
    small: compactPanelWidth,
    medium: compactPanelWidth,
    default: SWITCH_PANEL_WIDTH,
  });
};

interface SwitchFrontProps {
  serialNumber: string;
  selectedPorts?: string[];
  hidePortStatusIcons?: boolean;
}

const SwitchFront = ({ serialNumber, selectedPorts, hidePortStatusIcons }: SwitchFrontProps) => {
  const { id, model } = useAppSelector(devicesState)[serialNumber];
  const switchPorts = useAllSwitchPorts(serialNumber).data;
  const livePortStatuses = useSwitchPortStatuses(id);
  const panelWidth = useSwitchPanelWidth();
  const theme = useTheme();

  if (!switchPorts) {
    return null;
  }

  if (model == null) {
    return null;
  }

  const switchLayout = getSwitchLayout(model);
  if (!switchLayout) {
    return null;
  }

  const panelStyle =
    switchLayout.chassis === DeviceChassis.rack ? styles.frontPanel : styles.desktopPanel;
  const derivedPanelStyle = [themeColors(theme).frontPanel, styles.frontPanel, panelStyle];

  return (
    <View style={styles.container} testID="SWITCH_FRONT">
      <View style={derivedPanelStyle}>
        <PortBlocks
          portBlockData={switchLayout.blocks}
          portsStatuses={deriveSwitchPortStatuses(switchPorts, livePortStatuses)}
          selectedPorts={selectedPorts}
          panelWidth={panelWidth}
          hidePortStatusIcons={hidePortStatusIcons}
        />
      </View>
      {!livePortStatuses && <MkiSpinner style={styles.spinner} size="small" />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginVertical: 8,
    marginHorizontal: SPACING.default,
  },
  spinner: {
    marginStart: 6,
  },
  frontPanel: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    borderColor: MkiColors.panelShadowColor,
    borderWidth: appSelect({
      enterprise: 0,
      go: 1,
    }),
    borderBottomWidth: 1,
    borderRadius: appSelect({
      enterprise: 2,
      go: 4,
    }),
    paddingVertical: 6,
    paddingHorizontal: 8,
    ...appSelect({
      go: {
        ...platformSelect({
          ios: {
            shadowColor: MkiColors.tileShadowColor,
            shadowOpacity: 0.5,
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 2,
          },
          android: { elevation: 1 },
        }),
      },
      enterprise: {},
    }),
  },
  desktopPanel: {
    flex: isWeb() ? 1 : 0,
    paddingHorizontal: 16,
  },
});

export default SwitchFront;
