import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { Card, Numeral, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useNetworkUsageblocks, useOrgNetwork } from "@meraki/shared/api";
import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
  useHomeSSID,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function NetworkUsageCard() {
  const navigation = useNavigation<NativeStackNavigationProp<InsightsStackProps, "Insights">>();
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();
  const ssid = useHomeSSID();

  const { data: network, isLoading: isLoadingNetworks } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const encryptedNetworkId = network?.eid;

  const { data: networkUsage, isLoading: isLoadingNetworkUseBlocks } = useNetworkUsageblocks(
    {
      encryptedNetworkId,
      timespan,
      ssid,
    },
    {
      select: ({ netuseblocks }) => {
        let totalReceived = 0;
        let totalSent = 0;
        netuseblocks.forEach(([_, received, sent]) => {
          totalReceived += received;
          totalSent += sent;
        });

        return {
          sent: totalSent,
          received: totalReceived,
          average: (totalSent + totalReceived) / netuseblocks.length,
        };
      },
    },
  );

  const downloadSize = formatAndParseKibibytes(networkUsage?.received ?? 0);
  const uploadSize = formatAndParseKibibytes(networkUsage?.sent ?? 0);
  const averageSize = formatAndParseKibibytes(networkUsage?.average ?? 0);

  return (
    <Card
      loadingContentHeight="medium"
      loading={isLoadingNetworks || isLoadingNetworkUseBlocks}
      onPress={() => navigation.navigate("NetworkUsage", {})}
      testID="NETWORK_USAGE_CARD"
    >
      <Card.Header title={I18n.t("HOME.USAGE.TITLE")} />
      <Box flex={1} flexDirection="row">
        <Card.Well flex={1} paddingVertical="sm" alignItems="flex-start">
          <Numeral units={downloadSize.unit} value={downloadSize.value} />
          <Text weight="bold">{I18n.t("USAGE_CHART.DOWNLOAD")}</Text>
        </Card.Well>
        <Card.Well flex={1} paddingVertical="sm" marginHorizontal="xs" alignItems="flex-start">
          <Numeral units={uploadSize.unit} value={uploadSize.value} />
          <Text weight="bold">{I18n.t("USAGE_CHART.UPLOAD")}</Text>
        </Card.Well>
        <Card.Well flex={1} paddingVertical="sm">
          <Numeral units={averageSize.unit} value={averageSize.value} />
          <Box flex={1} flexDirection="row">
            <Text weight="bold">{I18n.t("USAGE_CHART.AVERAGE")}</Text>
            <Status status="warning" />
          </Box>
        </Card.Well>
      </Box>
    </Card>
  );
}
