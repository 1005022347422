import { isEmpty } from "lodash";

import { Device } from "~/api/schemas/Device";
import { OrgHealthAlert } from "~/api/schemas/HealthAlerts";
import { NetworkEvent } from "~/api/schemas/NetworkEvents";
import { AnyEvent } from "~/enterprise/screens/alertsScreenFilterUtils";

export function filterData(data: object | object[], keys: string[], searchText?: string) {
  if (!searchText || isEmpty(data)) {
    return data;
  }
  if (isEmpty(keys)) {
    return undefined;
  }

  if (Array.isArray(data)) {
    return filterArray(data, keys, searchText);
  }

  const newObject: any = {};

  for (const section in data) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const sectionContents = data[section];
    if (Array.isArray(sectionContents)) {
      newObject[section] = filterArray(sectionContents, keys, searchText);
    } else {
      if (filterElement(sectionContents, keys, searchText)) {
        newObject[section] = sectionContents;
      }
    }
  }

  return newObject;
}

const filterArray = (data: any, keys: any, searchText: any) => {
  if (isEmpty(data)) {
    return undefined;
  }
  return data.filter((datum: any) => {
    return filterElement(datum, keys, searchText);
  });
};

const filterElement = (element: any, keys: any, searchText: any) => {
  if (isEmpty(element)) {
    return false;
  }
  return keys.some((key: string) => {
    switch (typeof element[key]) {
      case "number":
      case "string":
        return partialStringMatch(element[key], searchText);
      case "boolean":
        return element[key] && partialStringMatch(key, searchText);
      case "object":
        if (Array.isArray(element[key])) {
          return element[key].some(
            (entry: any) =>
              (typeof entry === "string" || typeof entry === "number") &&
              partialStringMatch(entry, searchText),
          );
        }
        return false;
      default:
        return false;
    }
  });
};

const partialStringMatch = (data: any, searchText: any) =>
  data?.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0;

const matchDeviceDetails = (
  {
    productType,
    name,
    mac,
    serial,
  }: Partial<Pick<Device, "productType" | "name" | "mac" | "serial">>,
  searchText: string,
) =>
  partialStringMatch(productType, searchText) ||
  partialStringMatch(name, searchText) ||
  partialStringMatch(mac, searchText) ||
  partialStringMatch(serial, searchText);

const matchHealthAlertDevices = (alert: OrgHealthAlert, searchText: string) =>
  alert.scope.devices.some((device) => matchDeviceDetails(device, searchText));

const matchNetworkEventDevice = (networkEvent: NetworkEvent, searchText: string) => {
  const device = networkEvent.device;
  return isEmpty(device) ? false : matchDeviceDetails(device, searchText);
};

export function eventDataSearchResults(data: Array<AnyEvent>, searchText: string) {
  if (!searchText || isEmpty(data)) {
    return data;
  }

  return data.filter((event) => {
    if ("occurredAt" in event) {
      return (
        partialStringMatch(event.alertType, searchText) ||
        matchNetworkEventDevice(event, searchText)
      );
    } else {
      const { type, severity, scopeCategory } = event;
      return (
        partialStringMatch(type, searchText) ||
        matchHealthAlertDevices(event, searchText) ||
        partialStringMatch(severity, searchText) ||
        partialStringMatch(scopeCategory, searchText)
      );
    }
  });
}
