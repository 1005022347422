import * as errorMonitor from "@meraki/core/errors";
import { REMOTE_CONFIG_UPDATE } from "@meraki/shared/redux";

import { MS_IN_A_SECOND } from "~/constants/MkiConstants";
import { remoteConfig } from "~/lib/FirebaseModules";
import { remoteConfigDefaultValues, remoteConfigSettings } from "~/lib/FirebaseUtils";
import { isString } from "~/lib/TypeHelper";
import { AppThunk } from "~/shared/types/Redux";

async function configureRemoteConfig() {
  await remoteConfig.setConfigSettings(remoteConfigSettings);
  await remoteConfig.setDefaults(remoteConfigDefaultValues);
}

export function updateRemoteConfig() {
  const events = remoteConfig.getAll();
  return {
    type: REMOTE_CONFIG_UPDATE,
    response: {
      values: events,
    },
  };
}

export function fetchRemoteConfig(): AppThunk {
  return async (dispatch) => {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(reject, 3 * MS_IN_A_SECOND);
    });

    try {
      await configureRemoteConfig();
      await Promise.race([timeoutPromise, remoteConfig.fetchAndActivate()]);
    } catch (error) {
      if (isString(error)) {
        errorMonitor.notify(error);
      }
      throw error;
    }

    dispatch(updateRemoteConfig());
  };
}
