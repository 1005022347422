import { I18n } from "@meraki/core/i18n";
import {
  LAYER_7_GROUPS,
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_USAGE_INCREMENTS,
} from "@meraki/go/traffic-shaping";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import SectionListHeader from "~/go/components/SectionListHeader";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { UsageScreensPropMap } from "~/go/navigation/Types";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { ApplicationRule, BandwidthRule, PriorityLevel } from "~/go/types/NetworksTypes";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { formatTransferBits } from "~/lib/formatHelper";
import { applicationLimitConflicts, makeTrafficShapingRuleSet } from "~/lib/TrafficShapingUtils";
import {
  applicationUsageSelector,
  perClientBandwidthLimitsOnSSIDsSelector,
  securityBandwidthLimits,
  securityTrafficShapingRules,
  timespanNameSelector,
  trafficShapingRulesOnSSIDsSelector,
} from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import UsageRow from "~/shared/rows/UsageRow.go";
import { ApplicationUsages } from "~/shared/types/ApplicationUsages";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { LIMIT_STRING_KEYS } from "../components/identityPsk/GroupPolicySlider";

const priorityTranslations = {
  high: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.HIGH"),
  normal: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.NORMAL"),
  low: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.LOW"),
};

type ReduxProps = {
  group: string;
  onTitle: string;
  ssidNumber?: number;
  editLimit?: number;
  timespanName: string;
  applicationUsages: ApplicationUsages;
  ssidApplicationLimits: ApplicationRule[];
  ssidPerClientBandwidthLimit: BandwidthRule;
  gxApplicationLimits?: ApplicationRule[] | null;
  gxPerClientBandwidth: BandwidthRule | null;
};

type Props = ForwardedNativeStackScreenProps<UsageScreensPropMap, "LimitApplicationUsage"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

const handleError = (error: unknown) => {
  showAlert(I18n.t("ERROR"), error);
};

export const LimitApplicationUsage = ({
  applicationUsages,
  editLimit,
  ssidNumber,
  group,
  setReqPending,
  onTitle,
  timespanName,
  gxApplicationLimits,
  gxPerClientBandwidth,
  ssidApplicationLimits,
  ssidPerClientBandwidthLimit,
}: Props) => {
  const limitIndex = editLimit === undefined ? -1 : TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimit);
  const isGX = useCallback(() => ssidNumber === undefined, [ssidNumber]);

  const [value, setValue] = useState(limitIndex !== -1 ? limitIndex : 0);
  const [currentApplianceRule, setCurrentApplianceRule] = useState(
    gxApplicationLimits?.find((limit) => limit.identifier === group),
  );

  const [currentSsidRule, setCurrentSsidRule] = useState(
    ssidApplicationLimits?.find((limit) => limit.identifier === group),
  );
  const [priority, setPriority] = useState(currentApplianceRule?.priority ?? "normal");
  const [enforceLimit, setEnforceLimit] = useState(
    isGX()
      ? currentApplianceRule?.perClientBandwidthLimits.settings === "custom"
      : currentSsidRule?.perClientBandwidthLimits.settings === "custom",
  );
  const [showPriority, setShowPriority] = useState(false);
  const [dscpTagValue, setDscpTagValue] = useState(
    currentSsidRule?.dscpTagValue ?? currentApplianceRule?.dscpTagValue ?? null,
  );

  const navigation = useNavigation();
  const actions = useActions();

  useEffect(() => {
    const updatedApplianceRule = gxApplicationLimits?.find((limit) => limit.identifier === group);
    const updatedSsidRule = ssidApplicationLimits?.find((limit) => limit.identifier === group);
    setCurrentSsidRule(updatedSsidRule);
    setCurrentApplianceRule(updatedApplianceRule);

    setPriority(updatedApplianceRule?.priority ?? "normal");
    setDscpTagValue(updatedSsidRule?.dscpTagValue ?? updatedApplianceRule?.dscpTagValue ?? null);
  }, [group, gxApplicationLimits, ssidApplicationLimits]);

  const getNewRules = useCallback(() => {
    const proposedApplicationLimit = enforceLimit ? TRAFFIC_SHAPING_LIMIT_VALUES[value] : undefined;
    const groupPayload = LAYER_7_GROUPS[group];

    const applicationLimits = (isGX() ? gxApplicationLimits : ssidApplicationLimits) ?? [];
    const perClientBandwidth = isGX() ? gxPerClientBandwidth : ssidPerClientBandwidthLimit;

    if (perClientBandwidth) {
      const conflictingLimit = applicationLimitConflicts(
        proposedApplicationLimit,
        perClientBandwidth.limit,
      );

      if (conflictingLimit) {
        const translationOptions = {
          client_word: I18n.t("DEVICE_WORD"),
          max_limit: conflictingLimit,
        };

        showAlert(
          I18n.t("ERROR"),
          isGX()
            ? I18n.t(
                "LIMIT_APPLICATION_USAGE.HIGHER_CLIENT_RESTRICTION_GX_ERROR",
                translationOptions,
              )
            : I18n.t("LIMIT_APPLICATION_USAGE.HIGHER_CLIENT_RESTRICTION_ERROR", translationOptions),
        );
        return;
      }
    }
    return makeTrafficShapingRuleSet(
      proposedApplicationLimit,
      group,
      groupPayload.keyName,
      applicationLimits,
      dscpTagValue,
      priority,
    );
  }, [
    dscpTagValue,
    enforceLimit,
    group,
    gxApplicationLimits,
    gxPerClientBandwidth,
    isGX,
    priority,
    ssidApplicationLimits,
    ssidPerClientBandwidthLimit,
    value,
  ]);

  const save = useCallback(async () => {
    const newRules = getNewRules();
    if (newRules == null) {
      return;
    }

    setReqPending(true);
    try {
      if (isGX()) {
        await actions.updateGXTrafficShapingRules({ rules: newRules });
      } else {
        if (ssidNumber != null) {
          await actions.updateSSIDTrafficShapingRules(ssidNumber, newRules);
        }
      }
      navigation.goBack();
    } catch (error) {
      handleError(error || I18n.t("SERVER_ERROR_TEXT"));
    }
    setReqPending(false);
  }, [actions, getNewRules, isGX, navigation, setReqPending, ssidNumber]);

  const isDirty = useCallback(() => {
    if (editLimit === undefined) {
      return true;
    }

    const hasTrafficShapingLimitChanged = TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimit) !== value;
    const hasDscpTagValueChanged = isGX()
      ? currentApplianceRule?.dscpTagValue !== dscpTagValue
      : currentSsidRule?.dscpTagValue !== dscpTagValue;
    const hasPriorityChanged = isGX() && currentApplianceRule?.priority !== priority;
    const hasLimitEnforceChanged = isGX()
      ? (currentApplianceRule?.perClientBandwidthLimits.settings === "custom") != enforceLimit
      : (currentSsidRule?.perClientBandwidthLimits.settings === "custom") != enforceLimit;

    return (
      hasTrafficShapingLimitChanged ||
      hasDscpTagValueChanged ||
      hasPriorityChanged ||
      hasLimitEnforceChanged
    );
  }, [
    currentApplianceRule?.dscpTagValue,
    currentApplianceRule?.perClientBandwidthLimits.settings,
    currentApplianceRule?.priority,
    currentSsidRule?.dscpTagValue,
    currentSsidRule?.perClientBandwidthLimits.settings,
    dscpTagValue,
    editLimit,
    enforceLimit,
    isGX,
    priority,
    value,
  ]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() =>
            isDirty() ? showSaveWarning(save, navigation.goBack) : navigation.goBack()
          }
        />
      ),
      headerRight: () => <SaveButton onPress={save} disabled={!isDirty()} />,
    });
  }, [isDirty, save, navigation]);

  const description = I18n.t("LIMIT_APPLICATION_USAGE.DESCRIPTION");
  const limitSpeed = formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[value]);

  const { displayName, name, keyName } = LAYER_7_GROUPS[group];
  const applicationUsage = applicationUsages.find((app) => app.group === keyName);

  const percent = applicationUsage ? applicationUsage.percent : 0;
  const usage = applicationUsage ? applicationUsage.usage : 0;

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.headerContainer}>
          <MkiText screenStyles={styles.headerStyle}>{description}</MkiText>
          <SectionListHeader heading={onTitle} />
        </View>
        <View style={styles.usageRow}>
          <UsageRow
            usage={usage}
            usageSuffix={I18n.t("LIMIT_APPLICATION_USAGE.USAGE_TIMESPAN", {
              timespan: timespanName,
            })}
            percent={percent}
            numberOfLines={0}
          >
            {I18n.t(displayName || name)}
          </UsageRow>
        </View>
        <View style={styles.sliderContainer}>
          <TouchableOpacity
            style={styles.checkboxContainer}
            onPress={() => setEnforceLimit(!enforceLimit)}
          >
            <CheckBox selected={enforceLimit} testID={"ENABLE_USAGE_LIMIT"} />
            <MkiText screenStyles={{ paddingLeft: SPACING.small }} textStyle="default">
              {I18n.t("LIMIT_APPLICATION_USAGE.ENFORCE_USAGE_LIMIT")}
            </MkiText>
          </TouchableOpacity>
          <Slider
            maximumValue={TRAFFIC_SHAPING_USAGE_INCREMENTS}
            onValueChange={setValue}
            step={1}
            disabled={!enforceLimit}
            value={!enforceLimit ? TRAFFIC_SHAPING_USAGE_INCREMENTS : value}
            minimumTrackTintColor={MkiColors.goPurple}
            testID="SET_USAGE.SLIDER"
          />
          <MkiText>
            {enforceLimit
              ? I18n.t(LIMIT_STRING_KEYS[Math.min(value, 6)], { limit: limitSpeed })
              : I18n.t("LIMIT_APPLICATION_USAGE.RULE_LIMIT_NOT_ENFORCED")}
          </MkiText>
          <View style={styles.qosSettingsContainer}>
            <MkiText textStyle="header">
              {I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.TITLE")}
            </MkiText>

            <UnderlinedTextInput
              title={I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.DSCP_TAG_VALUE")}
              value={dscpTagValue === null || dscpTagValue === undefined ? "" : `${dscpTagValue}`}
              // @ts-ignore setDscpTagValue takes in a number but it should only allow a string
              onChangeText={setDscpTagValue}
              keyboardType={KEYBOARD_TYPE.decimalPad}
              testID={"DSCP_TAG_VALUE"}
            />
            {isGX() && (
              <PickerModalRow
                selectedValue={priority}
                subtitle={I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_SET_TO", {
                  priority: priorityTranslations[priority],
                })}
                visible={showPriority}
                label={I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY")}
                title={I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_MODAL_TITLE")}
                items={[
                  {
                    value: "high",
                    label: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.HIGH"),
                  },
                  {
                    value: "normal",
                    label: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.NORMAL"),
                  },
                  {
                    value: "low",
                    label: I18n.t("LIMIT_APPLICATION_USAGE.QOS_SETTINGS.PRIORITY_VALUES.LOW"),
                  },
                ]}
                onExit={() => setShowPriority(false)}
                onPress={() => setShowPriority(true)}
                onValueSelect={(value) => {
                  setPriority(value as PriorityLevel);
                  setShowPriority(false);
                }}
                testID="USAGE_PRIORITY"
                disableBottomBorder
              />
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  checkboxContainer: { flex: 1, flexDirection: "row" },
  headerContainer: {
    marginHorizontal: SPACING.default,
  },
  qosSettingsContainer: {
    flex: 1,
    marginVertical: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
  usageRow: {
    marginHorizontal: 10,
  },
  sliderContainer: {
    marginHorizontal: SPACING.large,
  },
});

function mapStateToProps(
  state: RootState,
  props: UsageScreensPropMap["LimitApplicationUsage"],
): ReduxProps {
  const { ssidNumber } = props;

  return {
    applicationUsages: applicationUsageSelector(state, {
      groupApplications: true,
      ssidNumber,
    }),
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    timespanName: timespanNameSelector(state),
    ssidApplicationLimits: ssidNumber ? trafficShapingRulesOnSSIDsSelector(state)[ssidNumber] : [],
    // @ts-expect-error TS(2322) FIXME: Type 'BandwidthRule | never[]' is not assignable t... Remove this comment to see the full error message
    ssidPerClientBandwidthLimit: ssidNumber
      ? perClientBandwidthLimitsOnSSIDsSelector(state)[ssidNumber]
      : [],
    gxApplicationLimits: securityTrafficShapingRules(state),
    gxPerClientBandwidth: securityBandwidthLimits(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(LimitApplicationUsage);
