import { PureComponent } from "react";
import { Image, ImageSourcePropType, ImageStyle, StyleSheet, View } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import BoxContainer from "~/go/components/BoxContainer";
import RoundedButton from "~/go/components/RoundedButton";
import { LoginStackProps } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, sizeSelect, themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";
import PageIndicator from "~/shared/components/viewPager/PageIndicator";
import ViewPager from "~/shared/components/viewPager/ViewPager";
import { useTheme, useThemeMode } from "~/shared/hooks/useTheme";

const FIRST_ID = "welcome-pane-1";
const LAST_ID = "welcome-pane-4";
const WELCOME_PANES = [
  {
    id: FIRST_ID,
    title: I18n.t("WELCOME_INTRO.CHECK_IN.TITLE"),
    message: I18n.t("WELCOME_INTRO.CHECK_IN.MESSAGE"),
    image: {
      light: require(`~/images/welcomeIntro/welcome-check-in.png`),
      dark: require(`~/images/welcomeIntro/welcome-check-in-light-gray.png`),
    },
  },
  {
    id: "welcome-pane-2",
    title: I18n.t("WELCOME_INTRO.MULTIPLE_NETWORKS.TITLE"),
    message: I18n.t("WELCOME_INTRO.MULTIPLE_NETWORKS.MESSAGE"),
    image: {
      light: require("~/images/welcomeIntro/welcome-multiple-networks.png"),
      dark: require("~/images/welcomeIntro/welcome-multiple-networks-light-gray.png"),
    },
  },
  {
    id: "welcome-pane-3",
    title: I18n.t("WELCOME_INTRO.WEB_BLOCKING.TITLE"),
    message: I18n.t("WELCOME_INTRO.WEB_BLOCKING.MESSAGE"),
    image: {
      light: require("~/images/welcomeIntro/welcome-web-blocking.png"),
      dark: require("~/images/welcomeIntro/welcome-web-blocking-light-gray.png"),
    },
  },
  {
    id: LAST_ID,
    title: I18n.t("WELCOME_INTRO.USAGE_LIMITS.TITLE"),
    message: I18n.t("WELCOME_INTRO.USAGE_LIMITS.MESSAGE"),
    image: {
      light: require("~/images/welcomeIntro/welcome-usage-limits.png"),
      dark: require("~/images/welcomeIntro/welcome-usage-limits-light-gray.png"),
    },
  },
];

type WelcomePaneProps = {
  id: string;
  title: string;
  message: string;
  image: { light: ImageSourcePropType; dark: ImageSourcePropType };
  position: number;
  totalPanes: number;
  onDismiss: () => void;
  onRegister: () => void;
  onPressPrevPage: () => void;
  onPressNextPage: () => void;
  primaryActionPosition?: "top" | "bottom";
};

export const WelcomePane = ({
  id,
  title,
  message,
  image,
  position,
  totalPanes,
  onDismiss,
  onRegister,
  onPressPrevPage,
  onPressNextPage,
  primaryActionPosition,
}: WelcomePaneProps) => {
  const isFirst = id === FIRST_ID;
  const isLast = id === LAST_ID;
  const imageMode = useThemeMode(); //solution to handle 'system' theme
  const colors = themeColors(useTheme());

  const headerButton =
    primaryActionPosition === "top" ? (
      <Touchable testID={`${id} dismiss`} onPress={onDismiss} transparentBackground>
        <MkiText screenStyles={welcomePaneStyles.loginLink}>
          {I18n.t("WELCOME_INTRO.LOG_IN")}
        </MkiText>
      </Touchable>
    ) : (
      <View style={welcomePaneStyles.dummyLoginLink} />
    );
  const footerButton =
    primaryActionPosition === "bottom" ? (
      <View style={welcomePaneStyles.footerButtonContainer}>
        <RoundedButton
          testID={`${id} dismiss`}
          onPress={onDismiss}
          containerStyles={welcomePaneStyles.footerButton}
          screenStyles={welcomePaneStyles.loginButton}
        >
          {I18n.t("WELCOME_INTRO.LOG_IN")}
        </RoundedButton>
      </View>
    ) : null;

  let body = (
    <View
      style={platformSelect({ web: welcomePaneStyles.webBody, mobile: welcomePaneStyles.body })}
    >
      <Image
        style={welcomePaneStyles.image as ImageStyle}
        source={image[imageMode]}
        resizeMode="contain"
      />
      <View style={welcomePaneStyles.textContainer}>
        <MkiText textStyle="heading" screenStyles={welcomePaneStyles.titleText}>
          {title}
        </MkiText>
        <MkiText
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"navigati... Remove this comment to see the full error message
          textStyle={sizeSelect({ small: "small", default: "default" })}
          screenStyles={welcomePaneStyles.messageText}
        >
          {message}
        </MkiText>
      </View>
    </View>
  );

  if (isWeb()) {
    body = (
      <View style={welcomePaneStyles.webContentWrapper}>
        {!isFirst && (
          <MerakiIcon
            name="chevron-back"
            size="l"
            color={colors.navigation.primary}
            onPress={() => onPressPrevPage()}
          />
        )}
        {body}
        {!isLast && (
          <MerakiIcon
            name="chevron-forward"
            size="l"
            color={colors.navigation.primary}
            onPress={() => onPressNextPage()}
          />
        )}
      </View>
    );
  }

  // @ts-expect-error TS(2741) FIXME: Property 'forceTouchAvailable' is missing in type ... Remove this comment to see the full error message
  const ContainerComponent = platformSelect({ web: View, mobile: BoxContainer });
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <ContainerComponent screenStyles={welcomePaneStyles.container}>
      <View style={welcomePaneStyles.content}>
        <View style={welcomePaneStyles.header}>
          {isWeb() && (
            <Touchable testID={`${id} register`} onPress={onRegister} transparentBackground>
              <MkiText screenStyles={welcomePaneStyles.loginLink}>
                {I18n.t("LOGIN.CREATE_ACCOUNT_TEXT.REGISTER_NOW")}
              </MkiText>
            </Touchable>
          )}
          {headerButton}
        </View>
        {body}
      </View>
      {footerButton}
      {/* @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{ numPages... Remove this comment to see the full error message */}
      {!isWeb() && <PageIndicator numPages={totalPanes} activePage={position + 1} />}
    </ContainerComponent>
  );
};
WelcomePane.defaultProps = {
  primaryActionPosition: "top",
};

const welcomePaneStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  webContentWrapper: {
    // @ts-expect-error react-native no longer supports vh because it is more specific with its type. This will still work on web.
    height: "60vh",
    flexDirection: "row",
    alignItems: "center",
  },
  body: {
    alignItems: "center",
  },
  webBody: {
    flex: 1,
    alignItems: "center",
  },
  loginLink: {
    paddingHorizontal: SPACING.large,
    paddingTop: SPACING.large,
    paddingBottom: SPACING.small,
    color: MkiColors.primaryButton,
  },
  dummyLoginLink: {
    // Ensures that images and text render at the same y-position regardless of
    // the position of the primary action button.
    // Using normalizedFontSize plus the additional line-height so I can account
    // for the varied text height for plus size phones and tablets.
    height: normalizedFontSize(16) + 6.5 + SPACING.large + SPACING.small,
  },
  loginButton: {
    margin: platformSelect({
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '24'.
      mobile: "auto",
      web: SPACING.large,
    }),
  },
  image: {
    height: sizeSelect({
      default: 230,
      small: 160,
    }),
    marginTop: sizeSelect({
      default: 0,
      extraLarge: SPACING.extraLarge * 4,
    }),
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ web: { width: number; }; }' is... Remove this comment to see the full error message
    ...platformSelect({
      web: { width: 230 },
    }),
  },
  textContainer: {
    marginHorizontal: SPACING.extraLarge,
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    maxWidth: sizeSelect({
      default: null,
      extraLarge: "60%",
    }),
  },
  titleText: {
    textAlign: "center",
  },
  messageText: {
    marginTop: SPACING.large,
    textAlign: "center",
    color: MkiColors.secondaryTextColor,
  },
  footerButtonContainer: {
    alignSelf: "stretch",
    marginHorizontal: SPACING.extraLarge,
  },
  // @ts-expect-error TS(2322) FIXME: Type '{ width?: string | undefined; alignSelf?: st... Remove this comment to see the full error message
  footerButton: {
    ...platformSelect({
      mobile: sizeSelect({
        default: null,
        extraLarge: {
          width: "60%",
          alignSelf: "center",
        },
      }),
      web: {
        width: "30%",
        alignSelf: "center",
      },
    }),
  },
});

type Props = ForwardedNativeStackScreenProps<LoginStackProps, "Welcome">;

export default class WelcomeScreen extends PureComponent<Props> {
  private viewPager: ViewPager | null = null;

  // eslint-disable-next-line no-unused-vars
  setCreatedAccountModal = () => {
    showAlert(I18n.t("VERIFY_EMAIL.TITLE"), I18n.t("VERIFY_EMAIL.MESSAGE"));
  };

  launchCreateAccount = () => {
    const { navigation } = this.props;
    navigation.navigate("CreateAccount", {
      onCreatedAccount: this.setCreatedAccountModal,
    });
  };

  render() {
    const { navigation } = this.props;
    const pager = (
      <ViewPager
        style={welcomeScreenStyles.flexOne}
        ref={(item) => (this.viewPager = item)}
        showPageIndicator={isWeb()}
      >
        {WELCOME_PANES.map((pane, idx) => (
          <View key={pane.id} style={welcomeScreenStyles.flexOne}>
            <WelcomePane
              onDismiss={navigation.goBack}
              onRegister={this.launchCreateAccount}
              position={idx}
              totalPanes={WELCOME_PANES.length}
              primaryActionPosition={idx === WELCOME_PANES.length - 1 ? "bottom" : "top"}
              // @ts-expect-error TS(2339) FIXME: Property 'onPressPrevPage' does not exist on type ... Remove this comment to see the full error message
              onPressPrevPage={() => this.viewPager?.onPressPrevPage()}
              // @ts-expect-error TS(2339) FIXME: Property 'onPressNextPage' does not exist on type ... Remove this comment to see the full error message
              onPressNextPage={() => this.viewPager?.onPressNextPage()}
              {...pane}
            />
          </View>
        ))}
      </ViewPager>
    );

    if (isWeb()) {
      return pager;
    }

    return (
      <LinearGradient
        colors={[MkiColors.gradientStart, MkiColors.gradientEnd]}
        start={{ x: 0, y: 0.0 }}
        end={{ x: 0.5, y: 1 }}
        style={welcomeScreenStyles.flexOne}
      >
        {pager}
      </LinearGradient>
    );
  }
}

const welcomeScreenStyles = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
});
