import { useEffect, useRef } from "react";
import { AppState, Platform } from "react-native";

export const useAppForeground = (onAppForeground: () => void) => {
  const appState = useRef(AppState.currentState);
  const callbackRef = useRef(onAppForeground);
  callbackRef.current = onAppForeground;

  useEffect(() => {
    if (Platform.OS === "web") {
      return;
    }

    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (appState.current === "background" && nextAppState === "active") {
        callbackRef.current();
      }

      appState.current = nextAppState;
    });

    return () => {
      if (Platform.OS === "web") {
        return;
      }
      subscription.remove();
    };
  }, []);
};
