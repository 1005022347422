import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { launchUrl } from "@meraki/shared/links";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { LETTER_SPACING, SPACING } from "~/constants/MkiConstants";
import { PROXIMA_SOFT_FONT } from "~/constants/MkiFonts";
import { errorAlerts, SPLASH_PAGE_ID, SPLASH_TIMEOUT_OPTIONS } from "~/constants/Splash";
import SplashLogoRow from "~/go/components/SplashLogoRow";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { SPLASH_FEATURE_ID } from "~/go/types/SplashTypes";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { isWeb } from "~/lib/PlatformUtils";
import {
  convertFrequency,
  getSplashPage,
  getTimeoutOptions,
  isChangedClickthrough,
  logoS3URL,
} from "~/lib/SplashUtils";
import { stringIsURL } from "~/lib/stringHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import {
  currentOrganization,
  currentShardIdState,
  getWirelessNodeGroupEid,
  splashSettingsOnSSIDsSelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { Alert } from "~/shared/types/AlertTypes";
import { SplashMethods, SplashSettings } from "~/shared/types/SplashSettings";

import { useSplashPagePreview } from "../hooks/useSplashPagePreview";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SplashPageSetup">;

interface State {
  showWelcomeMessageModal: boolean;
  showRedirectURLModal: boolean;
  showTimeoutModal: boolean;
  welcomeMessage: string | undefined;
  redirectURL: string | undefined;
  redirectURLEnabled: boolean | undefined;
  timeout: number;
  stagedWelcomeMessage: string | undefined;
  stagedRedirectURL: string | undefined;
  stagedLogoURL: string;
}

export const SplashPageSetupScreen = ({ ssidNumber }: Props) => {
  const networkId = errorMonitor.notifyNonOptional(
    "param 'networkId' undefined for SplashCustomURLSetupScreen",
    useCurrentNetworkId(),
  );
  const encryptedNetworkId = errorMonitor.notifyNonOptional(
    "param 'encryptedNetworkId' undefined for SplashPageSetupScreen",
    useAppSelector(getWirelessNodeGroupEid),
  );
  const organization = errorMonitor.notifyNonOptional(
    "param 'organization' undefined for SplashPageSetupScreen",
    useAppSelector(currentOrganization),
  );
  const shardId = errorMonitor.notifyNonOptional(
    "param 'shardId' undefined for SplashPageSetupScreen",
    useAppSelector(currentShardIdState),
  );
  const splashSettings = useAppSelector(splashSettingsOnSSIDsSelector)[ssidNumber];
  const timeoutInMinutes = splashSettings?.splashTimeout ?? 0;
  const logoURL = logoS3URL(splashSettings, shardId, organization, encryptedNetworkId);
  const logoMD5 = splashSettings?.splashLogo?.md5;
  const logoExtension = splashSettings?.splashLogo?.extension;

  const previewURL = useSplashPagePreview(ssidNumber);

  const [
    {
      showWelcomeMessageModal,
      showRedirectURLModal,
      showTimeoutModal,
      welcomeMessage,
      redirectURL,
      redirectURLEnabled,
      timeout,
      stagedWelcomeMessage,
      stagedRedirectURL,
      stagedLogoURL,
    },
    updateStates,
  ] = useReducer(
    (states: State, partialStates: Partial<State>) => ({ ...states, ...partialStates }),
    {
      showWelcomeMessageModal: false,
      showRedirectURLModal: false,
      showTimeoutModal: false,
      welcomeMessage: splashSettings?.welcomeMessage,
      redirectURL: splashSettings?.redirectUrl,
      redirectURLEnabled: splashSettings?.useRedirectUrl,
      timeout: timeoutInMinutes * 60 || SPLASH_TIMEOUT_OPTIONS[0],
      stagedWelcomeMessage: splashSettings?.welcomeMessage,
      stagedRedirectURL: splashSettings?.redirectUrl ? splashSettings?.redirectUrl ?? "" : "",
      stagedLogoURL: logoURL,
    },
  );

  // fields applied by input, used for saving
  const customStates = useMemo(
    () => ({
      [SPLASH_FEATURE_ID.welcomeMessage]: welcomeMessage,
      [SPLASH_FEATURE_ID.redirectURL]: redirectURL,
      [SPLASH_FEATURE_ID.redirectURLEnabled]: redirectURLEnabled,
      [SPLASH_FEATURE_ID.timeout]: timeout,
      [SPLASH_FEATURE_ID.logoURL]: logoURL,
      [SPLASH_FEATURE_ID.logoMD5]: logoMD5,
      [SPLASH_FEATURE_ID.logoExtension]: logoExtension,
    }),
    [logoExtension, logoMD5, logoURL, redirectURL, redirectURLEnabled, timeout, welcomeMessage],
  );

  // fields returned by input, may or may not be applied
  // note that some things don't need staged states, as they can be applied to customState
  // immediately upon change
  const stagedStates = useMemo(
    () => ({
      [SPLASH_FEATURE_ID.welcomeMessage]: stagedWelcomeMessage,
      [SPLASH_FEATURE_ID.redirectURL]: stagedRedirectURL,
      [SPLASH_FEATURE_ID.logoURL]: stagedLogoURL,
    }),
    [stagedLogoURL, stagedRedirectURL, stagedWelcomeMessage],
  );

  const [redirectURLAlert, setRedirectURLAlert] = useState<Alert | undefined>(undefined);
  const [reqPending, setReqPending] = useState(false);

  const navigation = useNavigation();
  const actions = useActions();

  const getData = useCallback(async () => {
    setReqPending(true);
    try {
      const promiseOrUndefined = actions.getSSIDSplashSettings(ssidNumber);
      if (promiseOrUndefined) {
        await promiseOrUndefined;
      }
    } catch (e) {
      showAlert(I18n.t("ERROR"), typeof e === "string" ? e : I18n.t("SERVER_ERROR_TEXT"));
    }

    setReqPending(false);
  }, [actions, ssidNumber]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isDirty = useCallback(() => {
    return splashSettings && isChangedClickthrough(splashSettings, stagedStates, customStates);
  }, [customStates, stagedStates, splashSettings]);

  const saveSplashScreen = useCallback(
    async (onComplete?: () => void) => {
      setReqPending(true);
      try {
        const newSplashSettings: Partial<SplashSettings> = {
          splashTimeout: timeout / 60,
          welcomeMessage: welcomeMessage,
          useRedirectUrl: redirectURLEnabled,
          redirectUrl: redirectURLEnabled ? redirectURL : undefined,
          useSplashUrl: false,
        };

        if (logoURL === "") {
          newSplashSettings.splashLogo = { md5: undefined };
        }

        if (getSplashPage(splashSettings) !== SPLASH_PAGE_ID.clickThrough) {
          await actions.setSsid(networkId, {
            number: ssidNumber,
            splashPage: SplashMethods.clickThrough,
          });
        }

        await actions.updateSSIDSplashSettings(ssidNumber, newSplashSettings);
        await getData();
        onComplete?.();
        navigation.goBack();
      } catch (e) {
        showAlert(I18n.t("ERROR"), typeof e === "string" ? e : I18n.t("SERVER_ERROR_TEXT"));
      }
      setReqPending(false);
    },
    [
      actions,
      getData,
      logoURL,
      navigation,
      networkId,
      redirectURL,
      redirectURLEnabled,
      splashSettings,
      ssidNumber,
      timeout,
      welcomeMessage,
    ],
  );

  const cancelSplashPage = useCallback(() => {
    showSaveWarning(() => saveSplashScreen(), navigation.goBack);
  }, [navigation.goBack, saveSplashScreen]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (isDirty()) {
              cancelSplashPage();
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={() => saveSplashScreen()} disabled={!isDirty()} />,
    });
  }, [cancelSplashPage, isDirty, navigation, saveSplashScreen]);

  const onPreviewPress = useCallback(() => {
    const openPreview = () => {
      if (isWeb()) {
        launchUrl(previewURL);
      } else {
        navigation.navigate("SplashPagePreview", { previewURL });
      }
    };

    if (isDirty()) {
      showSaveWarning(() => saveSplashScreen(openPreview));
    } else {
      openPreview();
    }
  }, [isDirty, previewURL, navigation, saveSplashScreen]);

  const submitWelcomeMessage = useCallback(() => {
    updateStates({ welcomeMessage: stagedWelcomeMessage });
    updateStates({ showWelcomeMessageModal: false });
  }, [stagedWelcomeMessage]);

  const validateRedirectURLOnSubmit = useCallback(() => {
    if (stagedRedirectURL && !stringIsURL(stagedRedirectURL)) {
      // it's a bad URL. Keep the modal up and show an error
      setRedirectURLAlert(errorAlerts().incorrectURLError);
      return;
    } else {
      updateStates({ redirectURL: stagedRedirectURL });
      updateStates({ redirectURLEnabled: !!stagedRedirectURL });

      // hide the modal, clear out any alert
      updateStates({ showRedirectURLModal: false });
      setRedirectURLAlert(undefined);
    }
  }, [stagedRedirectURL]);

  return (
    <FullScreenContainerView withScroll>
      <TouchableOpacity
        style={styles.previewContainer}
        onPress={onPreviewPress}
        testID="SPLASH_CONFIG.SPLASH_PREVIEW"
      >
        <View style={styles.previewButton}>
          <MkiText screenStyles={styles.previewTextStyle} uppercase>
            {I18n.t("SPLASH_CONFIG.SPLASH_PREVIEW_BUTTON")}
          </MkiText>
        </View>
        <MerakiIcon
          name="chevron-right"
          size="s"
          color={MkiColors.secondaryTextColor}
          containerStyle={styles.rightIconStyle}
        />
      </TouchableOpacity>
      <SplashLogoRow
        ssidNumber={ssidNumber}
        logoURL={logoURL}
        setReqPending={setReqPending}
        setStagedLogoURL={(imagePath) => updateStates({ stagedLogoURL: imagePath })}
      />
      <PickerModalRow
        visible={showTimeoutModal}
        label={I18n.t("SPLASH_CONFIG.CONFIGURE.TIMEOUT_FREQUENCY.PICKER_TITLE")}
        items={getTimeoutOptions()}
        subtitle={convertFrequency(timeout, false)}
        onPress={() => updateStates({ showTimeoutModal: true })}
        selectedValue={timeout}
        onExit={() => updateStates({ showTimeoutModal: false })}
        onValueSelect={(value) => {
          updateStates({ timeout: value as number });
          updateStates({ showTimeoutModal: false });
        }}
        containerStyles={styles.modalRowContainer}
        testID="SPLASH_CONFIG.TIMEOUT"
        disableBottomBorder
      />
      <DisclosureRow
        subtitle={welcomeMessage}
        onPress={() => updateStates({ showWelcomeMessageModal: true })}
        testID="SPLASH_CONFIG.CUSTOM_PROMPT"
      >
        {I18n.t("SPLASH_CONFIG.CUSTOMIZE.SUBHEADER.ROW_TITLE")}
      </DisclosureRow>
      <DisclosureRow
        subtitle={stagedRedirectURL ? stagedRedirectURL : I18n.t("SPLASH_CONFIG.NONE_OPT")}
        onPress={() => updateStates({ showRedirectURLModal: true })}
        testID="SPLASH_CONFIG.REDIRECT_URL"
      >
        {I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.TOGGLE_ROW_TITLE")}
      </DisclosureRow>
      <InputModal
        value={stagedWelcomeMessage}
        title={I18n.t("SPLASH_CONFIG.CUSTOMIZE.SUBHEADER.EDIT_MESSAGE_TITLE")}
        visible={showWelcomeMessageModal}
        onChangeText={(text: string) => updateStates({ stagedWelcomeMessage: text })}
        primaryButtonText={I18n.t("SPLASH_CONFIG.PROMPT_BUTTON")}
        onSubmitEditing={submitWelcomeMessage}
        onPrimaryPress={submitWelcomeMessage}
        onExit={() => updateStates({ showWelcomeMessageModal: false })}
      />
      <InputModal
        value={stagedRedirectURL}
        title={I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.EDIT_MESSAGE_TITLE")}
        subtitle={I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.EDIT_MESSAGE_SUBTITLE")}
        visible={showRedirectURLModal}
        onChangeText={(text: string) => updateStates({ stagedRedirectURL: text })}
        alert={redirectURLAlert}
        primaryButtonText={I18n.t("SPLASH_CONFIG.PROMPT_BUTTON")}
        onSubmitEditing={validateRedirectURLOnSubmit}
        onPrimaryPress={validateRedirectURLOnSubmit}
        onExit={() => updateStates({ showRedirectURLModal: false })}
        autoCapitalize="none"
      />
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  previewContainer: {
    paddingVertical: SPACING.small,
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopColor: MkiColors.borderColor,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  modalRowContainer: {
    backgroundColor: undefined,
    marginTop: 0,
  },
  previewButton: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightIconStyle: {
    marginHorizontal: -1 * SPACING.small,
  },
  previewTextStyle: {
    ...PROXIMA_SOFT_FONT,
    color: MkiColors.primaryButton,
    fontSize: normalizedFontSize(13),
    letterSpacing: LETTER_SPACING.allCaps,
  },
});

export default SplashPageSetupScreen;
