import { useState } from "react";
import { Code, useCodeScanner } from "react-native-vision-camera";

import { validateDeviceSerial } from "../utils/DeviceUtils";

const filterBarcodes = (barcodes: Code[]) => {
  return barcodes.map((b) => b.value ?? "").filter(validateDeviceSerial);
};

export const useScanSerial = () => {
  const [barcodes, setBarcodes] = useState<Code[]>([]);

  const codeScanner = useCodeScanner({
    codeTypes: ["qr", "code-128", "code-39"],
    onCodeScanned: setBarcodes,
  });

  const serial = filterBarcodes(barcodes)[0];

  return [codeScanner, serial] as const;
};
