import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useCallback, useRef } from "react";
import { LayoutChangeEvent, Pressable, ScrollView, useWindowDimensions, View } from "react-native";

import { Port, PortProps } from "./Port/Port";
import { SelectedIndicator } from "./Port/SelectedIndicator";

export { PortProps } from "./Port/Port";

export type PortStatus = { state?: PortProps["state"]; indicator?: PortProps["indicator"] };

function SelectablePort(
  props: PortProps & {
    onPress?: (port: string) => void;
    selectedPort?: string;
    showSelectable: boolean;
    status?: Record<string, PortStatus>;
  },
) {
  const selected = props.selectedPort === (props.number ?? -1);
  const SelectedIndicatorComponent = <SelectedIndicator {...props} selected={selected} />;
  const portStatus = (props.status ?? {})[props.number ?? ""] ?? {};

  return (
    <Pressable onPress={() => props.number && props.onPress?.(props.number)}>
      {!props.flipped && props.showSelectable && SelectedIndicatorComponent}
      {/* @ts-ignore We are getting a type error here because `state` can be any available options but not all options are available for all `kind`. TS can't figure it out here... thats fine though. */}
      <Port {...props} {...portStatus} selected={selected} />
      {props.flipped && props.showSelectable && SelectedIndicatorComponent}
    </Pressable>
  );
}

export type PortDiagramProps = {
  layout: { kind: PortProps["kind"]; ports: number; rows?: 1 | 2 }[];
  status?: Record<string, PortStatus>;
  selectedPort?: string;
  onSelectPort?: (port: string) => void;
  showSelectable?: boolean;
  scrollToInitialSelection?: boolean;
};

export function PortDiagram({
  layout,
  status,
  selectedPort,
  onSelectPort,
  showSelectable,
  scrollToInitialSelection,
}: PortDiagramProps) {
  const scrollViewRef = useRef<ScrollView>(null);
  const selectedSectionRef = useRef<View>(null);
  const scrolledToInitialSelection = useRef(false);
  const { width: windowWidth } = useWindowDimensions();

  const theme = useMagneticTheme();

  const handleSelectedPortLayout = useCallback(
    (_: LayoutChangeEvent) => {
      if (
        !scrollToInitialSelection ||
        scrolledToInitialSelection.current ||
        !scrollViewRef.current ||
        !selectedSectionRef.current
      ) {
        return;
      }

      scrolledToInitialSelection.current = true;

      selectedSectionRef.current.measure((_x, _y, _width, _height, pageX) => {
        const scrollToX = Math.max(pageX - windowWidth / 4, 0);

        if (scrollToX > 0) {
          scrollViewRef.current?.scrollTo({ x: scrollToX });
        }
      });
    },
    [scrollToInitialSelection, windowWidth],
  );

  const anyBlockHasTwoRows = layout.some((l) => l.rows === 2);
  const calculatedShowSelectable = showSelectable ?? !!onSelectPort;
  let portCounter = 1;

  return (
    <ScrollView
      horizontal
      style={{
        backgroundColor: theme.color.control.bg.weak.base,
        paddingTop: anyBlockHasTwoRows ? 0 : 12,
        borderColor: theme.color.control.border.weak.base,
        borderTopWidth: theme.SizeStrokeMedium,
        borderBottomWidth: theme.SizeStrokeMedium,
      }}
      contentContainerStyle={{ paddingHorizontal: theme.SizeSm }}
      showsHorizontalScrollIndicator={false}
      ref={scrollViewRef}
    >
      <View style={{ flexDirection: "row", gap: 15 }}>
        {layout.map((section, idx) => {
          const numRows = section.rows ?? 1;
          const numColumns = Math.ceil(section.ports / numRows);

          return (
            <View style={{ flexDirection: "row", gap: 3 }} key={idx}>
              {new Array(numColumns).fill(1).map((_, colIdx) => {
                const containsSelectedPort =
                  selectedPort === (portCounter + 1).toString() ||
                  (numRows > 1 && selectedPort === (portCounter + 2).toString());

                return (
                  <View
                    style={{ flexDirection: "row", gap: 3 }}
                    key={`${idx}-${colIdx}`}
                    ref={
                      containsSelectedPort && scrollToInitialSelection
                        ? selectedSectionRef
                        : undefined
                    }
                    onLayout={
                      containsSelectedPort && scrollToInitialSelection
                        ? handleSelectedPortLayout
                        : undefined
                    }
                  >
                    <View style={{ gap: 3, justifyContent: "flex-end" }}>
                      <SelectablePort
                        kind={section.kind}
                        number={(portCounter++).toString()}
                        selectedPort={selectedPort}
                        onPress={onSelectPort}
                        flipped={numRows === 1}
                        showSelectable={calculatedShowSelectable}
                        status={status}
                      />
                      {numRows > 1 && (
                        <SelectablePort
                          kind={section.kind}
                          number={(portCounter++).toString()}
                          selectedPort={selectedPort}
                          flipped
                          onPress={onSelectPort}
                          showSelectable={calculatedShowSelectable}
                          status={status}
                        />
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
}

PortDiagram.Port = Port;
