import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { NetworkScreens } from "~/go/navigation/NetworkScreens";
import OnboardingStack from "~/go/navigation/OnboardingStack";
import PokeDeviceStack from "~/go/navigation/PokeDeviceStack";
import { ContactSupportStack } from "~/go/navigation/SupportStack";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import AppliancePortsVlanScreen from "~/go/screens/AppliancePortsVlanScreen";
import FirmwareUpgradesScreen from "~/go/screens/FirmwareUpgradesScreen";
import GXPortsListScreen from "~/go/screens/GXPortsListScreen";
import HardwareDetailsScreen from "~/go/screens/HardwareDetailsScreen";
import L3FirewallRuleScreen from "~/go/screens/L3FirewallRuleScreen";
import L3FirewallRulesListScreen from "~/go/screens/L3FirewallRulesListScreen";
import LEDColorLegend from "~/go/screens/LEDColorLegend";
import BlinkLEDModal from "~/go/screens/liveTools/BlinkLEDModal";
import CableTest from "~/go/screens/liveTools/CableTest";
import PingHost from "~/go/screens/liveTools/PingHost";
import PingWebsite from "~/go/screens/liveTools/PingWebsite";
import RestartModal from "~/go/screens/liveTools/RestartModal";
import AppliancePortSettingsScreen from "~/go/screens/ports/AppliancePortSettingsScreen";
import GXPortDetailsScreen from "~/go/screens/ports/GXPortDetailsScreen";
import RadioSettingsListScreen from "~/go/screens/RadioSettingsListScreen";
import StaticIPConfigurationScreen from "~/go/screens/StaticIPConfigurationScreen";
import DeviceMACScreen from "~/go/screens/switchports/DeviceMACScreen";
import MACAllowListScreen from "~/go/screens/switchports/MACAllowListScreen";
import SwitchPortsDetailsScreen from "~/go/screens/SwitchPortsDetailsScreen";
import SwitchPortsListScreen from "~/go/screens/SwitchPortsListScreen";
import SwitchPortsVlanScreen from "~/go/screens/SwitchPortsVlanScreen";
import TopologyScreen from "~/go/screens/TopologyScreen";
import TroubleshootingScreen from "~/go/screens/TroubleshootingScreen";
import UmbrellaSettingsScreenWrapper from "~/go/screens/umbrella/UmbrellaSettingsScreenWrapper";
import SwitchPortEditWrapper from "~/shared/components/ports/switchPorts/SwitchPortEditWrapper";
import SelectionScreen from "~/shared/screens/SelectionScreen";

import HardwareSettingsScreen from "../screens/HardwareSettingsScreen";
import PlugInScreen from "../screens/onboardingFullstack/PlugInScreen";

const ForwardedHardwareDetailsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "HardwareDetails">
  >()(HardwareDetailsScreen);
const ForwardedHardwareSettingsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "HardwareSettings">
  >()(HardwareSettingsScreen);
const ForwardedRadioSettingsListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "RadioSettingsList">
  >()(RadioSettingsListScreen);
const ForwardedUmbrellaSettingsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HardwareStackPropMap, "UmbrellaSettings">
>()(UmbrellaSettingsScreenWrapper);
const ForwardedStaticIPConfigurationScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<HardwareStackPropMap, "StaticIPConfiguration">
>()(StaticIPConfigurationScreen);
const ForwardedPingHostScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "PingHost">>()(
    PingHost,
  );
const ForwardedL3FirewallRulesListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "L3FirewallRulesList">
  >()(L3FirewallRulesListScreen);

const ForwardedL3FirewallRuleScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "L3FirewallRule">
  >()(L3FirewallRuleScreen);
const ForwardedBlinkLEDModal =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "BlinkLED">>()(
    BlinkLEDModal,
  );
const ForwardedRestartModal =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "Restart">>()(
    RestartModal,
  );
const ForwardedSwitchPortsListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "SwitchPortsList">
  >()(SwitchPortsListScreen);
const ForwardedSwitchPortSettingsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "SwitchPortSettings">
  >()(SwitchPortEditWrapper);
const ForwardedSwitchPortDetailsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "SwitchPortsDetails">
  >()(SwitchPortsDetailsScreen);
const ForwardedSwitchPortsVlanScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "SwitchPortsVlan">
  >()(SwitchPortsVlanScreen);
const ForwardedSelectionScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "Selection">>()(
    SelectionScreen,
  );
const ForwardedLEDColorLegend =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "LEDColorLegend">
  >()(LEDColorLegend);
const ForwardedTroubleshootingScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "Troubleshooting">
  >()(TroubleshootingScreen);
const ForwardedPlugInScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "PlugIn">>()(
    PlugInScreen,
  );
const ForwardedPingWebsite =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "PingWebsite">>()(
    PingWebsite,
  );
const ForwardedGXPortsListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "GXPortsList">>()(
    GXPortsListScreen,
  );
const ForwardedGXPortDetailsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "GXPortDetails">>()(
    GXPortDetailsScreen,
  );
const ForwardedAppliancePortEditWrapper = withForwardedNavigationParams<
  ForwardedStackScreenProps<HardwareStackPropMap, "AppliancePortSettings">
>()(AppliancePortSettingsScreen);
const ForwardedAppliancePortsVlanScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "AppliancePortsVlan">
  >()(AppliancePortsVlanScreen);
const ForwardedMACAllowListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "MACAllowList">>()(
    MACAllowListScreen,
  );
const ForwardedDeviceMACScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "DeviceMACAddress">
  >()(DeviceMACScreen);
const ForwardedTopologyScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "Topology">>()(
    TopologyScreen,
  );
const ForwardedCableTest =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "CableTest">>()(
    CableTest,
  );
const ForwardedFirmwareUpgradesScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<HardwareStackPropMap, "FirmwareUpgrades">
  >()(FirmwareUpgradesScreen);

export function hardwareScreens<T extends HardwareStackPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="Topology"
        component={ForwardedTopologyScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("TOPOLOGY.TITLE") }}
      />
      <Stack.Screen
        name="OnboardingStack"
        component={OnboardingStack}
        options={({ route }) => {
          const screenParams = route.params?.params;
          const presentedModally = screenParams?.hasOwnProperty("presentedModally");

          return {
            headerShown: false,
            presentation: presentedModally ? "fullScreenModal" : undefined,
          };
        }}
      />
      <Stack.Screen
        name="PlugIn"
        component={ForwardedPlugInScreen}
        options={{ headerTitle: I18n.t("ONBOARDING_FULLSTACK.PLUG_IN.TITLE") }}
      />

      <Stack.Screen name="HardwareDetails" component={ForwardedHardwareDetailsScreen} />
      <Stack.Screen
        name="HardwareSettings"
        component={ForwardedHardwareSettingsScreen}
        options={{ headerTitle: I18n.t("HARDWARE_SETTINGS.TITLE") }}
      />
      <Stack.Screen
        name="RadioSettingsList"
        component={ForwardedRadioSettingsListScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("RADIO_SETTINGS.TITLE") }}
      />
      <Stack.Screen
        name="L3FirewallRulesList"
        component={ForwardedL3FirewallRulesListScreen}
        options={{
          headerTitle: I18n.t("L3_FIREWALL_RULES_LIST.TITLE"),
        }}
      />
      <Stack.Screen
        name="L3FirewallRule"
        component={ForwardedL3FirewallRuleScreen}
        options={{
          headerTitle: I18n.t("L3_FIREWALL_RULE.TITLE"),
        }}
      />
      <Stack.Screen
        name="UmbrellaSettings"
        component={ForwardedUmbrellaSettingsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("UMBRELLA.TITLE") }}
      />
      <Stack.Screen
        name="StaticIPConfiguration"
        component={ForwardedStaticIPConfigurationScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("STATIC_IP_CONFIGURATION.TITLE"),
        }}
      />
      <Stack.Screen
        name="LEDColorLegend"
        component={ForwardedLEDColorLegend}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("LEDS.TITLE") }}
      />
      <Stack.Screen
        name="SearchSubject"
        component={ContactSupportStack}
        options={{ headerShown: false, presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="PingHost"
        component={ForwardedPingHostScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("LIVE_TOOLS.TEST_CONNECTION"),
        }}
      />
      <Stack.Screen
        name="PingWebsite"
        component={ForwardedPingWebsite}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("LIVE_TOOLS.TEST_WEBSITE"),
        }}
      />
      <Stack.Screen
        name="BlinkLED"
        component={ForwardedBlinkLEDModal}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("LIVE_TOOLS.BLINK_LED") }}
      />
      <Stack.Screen
        name="Restart"
        component={ForwardedRestartModal}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("LIVE_TOOLS.REBOOT") }}
      />
      <Stack.Screen name="GXPortsList" component={ForwardedGXPortsListScreen} />
      <Stack.Screen name="GXPortDetails" component={ForwardedGXPortDetailsScreen} />
      <Stack.Screen
        name="AppliancePortSettings"
        component={ForwardedAppliancePortEditWrapper}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="AppliancePortsVlan"
        component={ForwardedAppliancePortsVlanScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("VLAN_SWITCH_PORTS.TITLE"),
        }}
      />
      <Stack.Screen
        name="MACAllowList"
        component={ForwardedMACAllowListScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("MAC_ALLOW_LIST.TITLE"),
        }}
      />
      <Stack.Screen
        name="DeviceMACAddress"
        component={ForwardedDeviceMACScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("MAC_ALLOW_LIST.TITLE"),
        }}
      />
      <Stack.Screen
        name="SwitchPortsList"
        component={ForwardedSwitchPortsListScreen}
        options={{ headerTitle: "" }}
      />
      <Stack.Screen
        name="SwitchPortSettings"
        component={ForwardedSwitchPortSettingsScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen name="SwitchPortsDetails" component={ForwardedSwitchPortDetailsScreen} />
      <Stack.Screen
        name="CableTest"
        component={ForwardedCableTest}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("LIVE_TOOLS.CABLE_TEST.TITLE"),
        }}
      />
      <Stack.Screen
        name="Selection"
        component={ForwardedSelectionScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="SwitchPortsVlan"
        component={ForwardedSwitchPortsVlanScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("VLAN_SWITCH_PORTS.TITLE"),
        }}
      />
      <Stack.Screen
        name="Troubleshooting"
        component={ForwardedTroubleshootingScreen}
        options={({ route }) => {
          return {
            headerTitle: I18n.t("LIVE_TOOLS.TROUBLESHOOTING_TOOLS"),
            presentation: route.params?.presentedModally ? "fullScreenModal" : undefined,
          };
        }}
      />
      <Stack.Screen
        name="PokeDeviceStack"
        component={PokeDeviceStack}
        options={{ headerShown: false, presentation: "fullScreenModal" }}
      />
      {NetworkScreens(Stack)}
      <Stack.Screen
        name="FirmwareUpgrades"
        component={ForwardedFirmwareUpgradesScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("FIRMWARE_UPDATES.TITLE") }}
      />
    </>
  );
}
