import { I18n } from "@meraki/core/i18n";
import { PortVlan, PortVlanTypes } from "@meraki/shared/api";
import { Fragment, memo } from "react";
import { StyleSheet, View } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import SummaryHeader from "~/enterprise/components/SummaryHeader";
import FormTextInput from "~/enterprise/components/textInputs/FormTextInput";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import { ObjectUpdater } from "~/shared/hooks/useObjectUpdater";
import { useTheme } from "~/shared/hooks/useTheme";

interface PortVlanConfigProps<T extends Partial<PortVlan>> {
  port: T;
  updatePortByKey: ObjectUpdater<T>;
}

function PortVlanConfig<T extends Partial<PortVlan>>({
  port,
  updatePortByKey,
}: PortVlanConfigProps<T>) {
  const { type, vlan, voiceVlan, allowedVlans } = port;

  const colors = themeColors(useTheme());

  const updateVLAN = updatePortByKey("vlan");
  const updateVoicVLAN = updatePortByKey("voiceVlan");
  const updateVLANWithNumber = (newVlan: any) =>
    updateVLAN(newVlan ? parseInt(newVlan) || undefined : undefined);
  const updateVoicVLANWithNumber = (newVoicVlan: any) =>
    updateVoicVLAN(newVoicVlan ? parseInt(newVoicVlan) || null : null);

  const renderAccessVlanInput = () => {
    if (type === PortVlanTypes.trunk) {
      return null;
    }
    return (
      <View style={[styles.infoCard, colors.infoCard]}>
        <FormTextInput
          onChangeText={updateVLANWithNumber}
          title={I18n.t("SWITCH_PORT_EDIT.VLAN.TITLE")}
          placeholder={I18n.t("SWITCH_PORT_EDIT.VLAN.PLACEHOLDER")}
          testID={"VLAN_CONFIG.VLAN"}
          value={vlan?.toString() ?? ""}
        />

        {"voiceVlan" in port && (
          <FormTextInput
            onChangeText={updateVoicVLANWithNumber}
            title={I18n.t("SWITCH_PORT_EDIT.VOICE_VLAN.TITLE")}
            placeholder={I18n.t("SWITCH_PORT_EDIT.VOICE_VLAN.PLACEHOLDER")}
            testID={"VLAN_CONFIG.VOICE_VLAN"}
            value={voiceVlan?.toString()}
          />
        )}
      </View>
    );
  };

  const renderTrunkVlanInput = () => {
    if (type === PortVlanTypes.access) {
      return null;
    }
    return (
      <View style={[styles.infoCard, colors.infoCard]}>
        <FormTextInput
          onChangeText={updateVLANWithNumber}
          title={I18n.t("SWITCH_PORT_EDIT.NATIVE_VLAN.TITLE")}
          placeholder={I18n.t("SWITCH_PORT_EDIT.NATIVE_VLAN.PLACEHOLDER")}
          testID={"VLAN_CONFIG.NATIVE_VLAN"}
          value={vlan?.toString() ?? ""}
        />
        <FormTextInput
          onChangeText={updatePortByKey("allowedVlans")}
          title={I18n.t("SWITCH_PORT_EDIT.ALLOWED_VLANS.TITLE")}
          placeholder={I18n.t("SWITCH_PORT_EDIT.ALLOWED_VLANS.PLACEHOLDER")}
          value={allowedVlans}
          testID={"VLAN_CONFIG.ALLOWED_VLANS"}
          autoCapitalize={"none"}
        />
      </View>
    );
  };
  const getVlanOptions = () => [
    {
      value: "access" as const,
      label: I18n.t("SWITCH_PORT_EDIT.ACCESS"),
      textInputComponent: renderAccessVlanInput,
    },
    {
      value: "trunk" as const,
      label: I18n.t("SWITCH_PORT_EDIT.TRUNK"),
      textInputComponent: renderTrunkVlanInput,
    },
  ];

  const renderHeader = () => {
    return appSelect({
      enterprise: <SummaryHeader title={I18n.t("SWITCH_PORT_EDIT.VLAN.TITLE")} />,
      go: null,
    });
  };

  return (
    <Fragment>
      {renderHeader()}
      <RadioSelectionList<"access" | "trunk">
        radioStyle={"radioStyle" in styles ? styles.radioStyle : undefined}
        options={getVlanOptions()}
        selectedValue={type}
        onSelect={updatePortByKey("type")}
        testID={"VLAN_CONFIG.OPTIONS"}
      />
    </Fragment>
  );
}

const styles = StyleSheet.create(
  appSelect({
    enterprise: {
      infoCard: {
        marginTop: SPACING.small,
        paddingVertical: SPACING.small,
        paddingHorizontal: SPACING.default,
        borderRadius: BUTTON_SIZING.borderRadius.default,
      },
    },
    go: {
      infoCard: {
        marginTop: SPACING.small,
        paddingVertical: SPACING.tiny,
        paddingHorizontal: SPACING.small,
        borderRadius: BUTTON_SIZING.borderRadius.small,
      },
      radioStyle: {
        marginVertical: SPACING.small,
      },
    },
  }),
);

export default memo(PortVlanConfig);
