import { createDateFormatter } from "./format";

export type FormatDateRangeOptions = {
  timeZone?: string;
  unixTimestamp?: boolean;
  hideDate?: boolean;
};

export function formatDateRange(
  from: string | Date | number | undefined,
  to: string | Date | number | undefined,
  options?: FormatDateRangeOptions,
) {
  const dateFormatter = createDateFormatter(options);

  const areSameDay = dateFormatter(from) === dateFormatter(to);

  if (areSameDay) {
    return `${dateFormatter(from, {
      dateFormat: options?.hideDate ? "hide" : "shortDate",
      timeFormat: "shortTime",
    })} - ${dateFormatter(to, { dateFormat: "hide", timeFormat: "shortTime" })}`;
  }

  return `${dateFormatter(from, {
    dateFormat: options?.hideDate ? "hide" : "shortDate",
    timeFormat: "shortTime",
  })} - ${dateFormatter(to, {
    dateFormat: options?.hideDate ? "hide" : "shortDate",
    timeFormat: "shortTime",
  })}`;
}
