import * as errorMonitor from "@meraki/core/errors";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { ApplicationUsageListScreen as MagneticApplicationUsageListScreen } from "@meraki/shared/applications";
import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import { HomeStackProps } from "~/enterprise/navigation/Types";
import SectionListHeader from "~/go/components/SectionListHeader";
import { HomeStackProps as GoHomeStackProps } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { PerfScreenNames, ScreenTrace, TraceTypes } from "~/lib/PerformanceUtils";
import { assignColors } from "~/lib/UsageUtils";
import {
  applicationUsageSelector,
  currentNetworkState,
  encryptedNetworkIdSelector,
  getNetworkHasClients,
  getNetworkName,
  timespanState,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import { SetUsageButton } from "~/shared/navigation/Buttons";
import UsageRow from "~/shared/rows/UsageRow";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { ApplicationUsage } from "../types/ApplicationUsages";
import { RootState } from "../types/Redux";

type ReduxProps = {
  applicationUsages: ApplicationUsage[];
  networkHasClients: boolean;
  networkId: string;
  encryptedNetworkId: string;
  timespan: number;
  networkName: string;
};

type Props = ForwardedNativeStackScreenProps<HomeStackProps, "ApplicationUsageList"> &
  ReduxProps &
  BasicActions;

type ApplicationUsageListScreenState = {
  reqPending: boolean;
};

class ApplicationUsageListScreen extends PureComponent<Props, ApplicationUsageListScreenState> {
  perfTrace;

  static renderSectionHeader = ({ section }: { section: { key?: string } }) => (
    <SectionListHeader
      heading={I18n.t("ON_FOO_SECTION_HEADER", { section_name: section.key })}
      withHorizontalMargin
    />
  );

  constructor(props: Props) {
    super(props);
    this.perfTrace = new ScreenTrace(
      PerfScreenNames.applicationUsageListScreen,
      TraceTypes.totalLoad,
    );

    this.state = {
      reqPending: false,
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    if (__MERAKI_GO__) {
      type GoNavigation = ForwardedNativeStackScreenProps<
        GoHomeStackProps,
        "ApplicationUsageList"
      >["navigation"];

      navigation.setOptions({
        headerRight: () => (
          <SetUsageButton
            onPress={() => {
              (navigation as unknown as GoNavigation).navigate("SetUsage", {});
            }}
          />
        ),
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  onPress = (rowData: ApplicationUsage) => {
    const { navigation, ssidName, ssidNumber } = this.props;

    if (__MERAKI_GO__) {
      type GoNavigation = ForwardedNativeStackScreenProps<
        GoHomeStackProps,
        "ApplicationUsageList"
      >["navigation"];

      (navigation as unknown as GoNavigation).navigate("ApplicationUsageDetails", {
        applicationId: Number(rowData.id),
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        ssidName,
        // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        ssidNumber,
      });
      return;
    }

    navigation.navigate("ApplicationUsageDetails", {
      applicationId: Number(rowData.id),
      applicationName: rowData.children,
      usage: rowData.usage,
    });
  };

  onRefresh = () => this.getData();

  getData() {
    const { timespan, actions, networkId, encryptedNetworkId, networkHasClients, ssidNumber } =
      this.props;
    const { getNetworkUseblocks, getSsidUseblocks } = actions;
    const reqs: Promise<unknown>[] = [];

    if (networkHasClients) {
      const action = Number.isInteger(ssidNumber)
        ? getSsidUseblocks(networkId, encryptedNetworkId, ssidNumber, timespan)
        : getNetworkUseblocks(networkId, encryptedNetworkId, timespan);
      reqs.push(action);
    }

    if (reqs.length > 0) {
      const reqDone = () => {
        this.setState({ reqPending: false });
        this.perfTrace.stopTime();
      };
      this.setState({ reqPending: true });
      Promise.all(reqs).then(reqDone, reqDone);
    } else {
      this.perfTrace.stopTime();
    }
  }

  keyExtractor = (item: ApplicationUsage) => item.children;

  renderRow = (rowData: ApplicationUsage) => <UsageRow {...rowData} />;

  render() {
    const { ssidName, applicationUsages } = this.props;
    const { reqPending } = this.state;
    const assignedColorApplicationUsages = assignColors(applicationUsages);
    const applicationData = (
      ssidName ? { [ssidName]: assignedColorApplicationUsages } : assignedColorApplicationUsages
    ) as ApplicationUsage[];
    const renderSectionHeader = __MERAKI_GO__
      ? ApplicationUsageListScreen.renderSectionHeader
      : undefined;
    return (
      <MkiTable<ApplicationUsage>
        data={applicationData}
        keyExtractor={this.keyExtractor}
        renderRow={(rowData) => this.renderRow(rowData)}
        renderSectionHeader={renderSectionHeader}
        onPress={this.onPress}
        loading={reqPending}
        onRefresh={this.onRefresh}
        scrollViewTestID={"APPLICATION_USAGE_LIST.SCROLL_VIEW"}
      />
    );
  }
}

function mapStateToProps(
  state: RootState,
  props: HomeStackProps["ApplicationUsageList"],
): ReduxProps {
  return {
    applicationUsages: applicationUsageSelector(state, props),
    networkHasClients: !!getNetworkHasClients(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for ApplicationUsageListScreen",
      currentNetworkState(state),
    ),
    encryptedNetworkId: errorMonitor.notifyNonOptional(
      "param 'encryptedNetworkId' undefined for ApplicationUsageListScreen",
      encryptedNetworkIdSelector(state),
    ),
    timespan: timespanState(state),
    networkName: getNetworkName(state),
  };
}

const ConnectedApplicationUsageListScreen = connect(
  mapStateToProps,
  basicMapDispatchToProps,
)(ApplicationUsageListScreen);

const MagnetizedApplicatoinUsageListScreen = withMagneticReplacementAdapter(
  ConnectedApplicationUsageListScreen,
  MagneticApplicationUsageListScreen,
);

export default MagnetizedApplicatoinUsageListScreen;
