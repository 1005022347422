import { queryClient } from "@meraki/shared/api";
import { useMutation, useQuery } from "@tanstack/react-query";

import casesKeys from "~/api/queries/cases/keys";
import {
  CloseCaseResponseSchema,
  SupportCaseInput,
  SupportCaseListSchema,
  SupportCaseResponseSchema,
} from "~/api/schemas/SupportCases";
import { validatedMerakiRequest } from "~/api/util/request";
import { currentUserState, encryptedNetworkIdSelector } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const supportCaseKeys = {
  supportCase: () => [...casesKeys.cases] as const,
};

const fetchSupportCases = (encryptedNetworkId: string) => {
  return validatedMerakiRequest(
    SupportCaseListSchema,
    "GET",
    `/n/${encryptedNetworkId}/manage/support/case_list`,
  );
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSupportCase = () => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector) || "";
  const userEmail = useAppSelector(currentUserState);

  return useQuery({
    queryKey: supportCaseKeys.supportCase(),
    queryFn: () => fetchSupportCases(encryptedNetworkId),
    select: (supportCases) => {
      const filteredCases = Object.values(supportCases).filter((supportCase) => {
        return supportCase.suppliedEmail === userEmail;
      });
      return filteredCases;
    },
  });
};

// TODO: UDG-3473 - Make use of this for Go (requires screen refactor)

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useNewSupportCaseMutation = () => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector);

  return useMutation({
    mutationFn: (supportCaseSubmit: SupportCaseInput) => {
      return validatedMerakiRequest(
        SupportCaseResponseSchema,
        "POST",
        `/n/${encryptedNetworkId}/manage/support/new_case`,
        {
          body: JSON.stringify(supportCaseSubmit),
        },
      );
    },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCloseSupportCaseMutation = (caseNumber: string) => {
  const encryptedNetworkId = useAppSelector(encryptedNetworkIdSelector);

  return useMutation({
    mutationFn: (userClosed: boolean) =>
      validatedMerakiRequest(
        CloseCaseResponseSchema,
        "POST",
        `/n/${encryptedNetworkId}/manage/support/update_case?case_number=${caseNumber}&user_closed=${userClosed}`,
        { queryParams: { case_number: caseNumber, user_closed: userClosed } },
      ),
    onSuccess: () => queryClient.refetchQueries(supportCaseKeys.supportCase()),
  });
};
