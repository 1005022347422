import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { DeviceOverviewCard } from "@meraki/go/device";
import { Card, RefreshControl, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  useFailedConnections,
  useInventoryDevices,
  useNetworkConnectionsStats,
  useNetworkHealth,
  useOrganization,
  useOrgNetwork,
  useOrgNetworks,
  useSiteToSiteVPNSettings,
  useUmbrellaAuthToken,
  useUmbrellaProtection,
  useUmbrellaStatus,
  useWirelessLatency,
} from "@meraki/shared/api";
import { ClientsOverviewCard } from "@meraki/shared/clients";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useEffect, useState } from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { ConnectionStatsCard } from "../components/ConnectionStatsCard";
import { FailedConnectionsCard } from "../components/FailedConnectionsCard";
import { getTimespans, NetworkUptime } from "../components/NetworkUptime";
import { SecurityMeasuresCard } from "../components/SecurityMeasuresCard";
import { SiteToSiteVpnCard } from "../components/SiteToSiteVpnCard";
import { WirelessLatencyCard } from "../components/WirelessLatencyCard";

interface UmbrellaAccessTokenStoreState {
  accessToken: string;
  setAccessToken: (accessToken: string) => void;
}

export const useUmbrellaAuthTokenStore = create<UmbrellaAccessTokenStoreState>()(
  persist(
    (set) => ({
      accessToken: "",
      setAccessToken: (accessToken) =>
        set((_) => ({
          accessToken,
        })),
    }),
    {
      name: "umbrellaAuthTokenStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export function NetworkMonitorScreen() {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const {
    data: networks,
    isRefetching: networksIsRefetching,
    refetch: refetchNetworks,
  } = useOrgNetworks({ organizationId }, { enabled: !!organizationId });
  const { data: siteToSiteMode, refetch: refetchSiteToSiteMode } = useSiteToSiteVPNSettings(
    { networkId },
    { select: (data) => data.mode },
  );
  const { data: orgEid } = useOrganization(
    { organizationId },
    {
      select: (org) => org.eid,
    },
  );

  const { refetch: refetchInventory, isLoading: inventoryDevicesIsLoading } = useInventoryDevices({
    organizationId,
  });

  const { data: umbrellaStatus, refetch: refetchUmbrellaStatus } = useUmbrellaStatus({ orgEid });
  const { data: umbrellaProtection, refetch: refetchUmbrellaProtection } = useUmbrellaProtection({
    networkId,
  });
  const { data: authToken } = useUmbrellaAuthToken({});

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const { setAccessToken } = useUmbrellaAuthTokenStore();

  useEffect(() => {
    if (initialLoad && authToken !== undefined) {
      setInitialLoad(false);
      setAccessToken(authToken.token);
    }
  }, [initialLoad, authToken, setAccessToken]);

  const timespan = useGlobalTimespan();
  const { data: network } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const nodeGroupIds = Object.values(network?.ngIds ?? {}).map((node) => node.ngId) ?? [];
  const [{ t0, t1 }] = useState(getTimespans(timespan));
  const { refetch: refetchNetworkHealth } = useNetworkHealth(
    { encryptedOrgId: orgEid, ids: nodeGroupIds ?? [], t0, t1 },
    {
      enabled: !!orgEid,
    },
  );

  const { refetch: refetchLatency, isRefetching: latencyIsRefetching } = useWirelessLatency({
    networkId,
    timespan,
  });

  const { refetch: refetchConnections, isRefetching: failedConnectionsIsRefetching } =
    useFailedConnections({ networkId, timespan });

  const { refetch: refetchNetworkConnectionStats, isRefetching: networkConnectionStatsIsLoading } =
    useNetworkConnectionsStats({ networkId, timespan });

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={
            networksIsRefetching ||
            inventoryDevicesIsLoading ||
            latencyIsRefetching ||
            failedConnectionsIsRefetching ||
            networkConnectionStatsIsLoading
          }
          onRefresh={() => {
            refetchNetworks();
            refetchSiteToSiteMode();
            refetchUmbrellaStatus();
            refetchUmbrellaProtection();
            refetchInventory();
            refetchNetworkHealth();
            refetchLatency();
            refetchConnections();
            refetchNetworkConnectionStats();
          }}
        />
      }
      addDefaultPadding
    >
      {!networks?.length ? (
        <Card>
          <Text size="p1" testID="EMPTY_SCREEN">
            {I18n.t("NETWORK_SUMMARY.EMPTY")}
          </Text>
        </Card>
      ) : (
        <>
          <DeviceOverviewCard networkId={networkId} />
          <NetworkUptime />
          {umbrellaStatus &&
            umbrellaProtection &&
            umbrellaStatus?.isUmbrellaProvisioned &&
            umbrellaStatus?.hasClaimedUmbrellaLicense &&
            umbrellaStatus?.isUmbrellaEnabled &&
            umbrellaProtection.umbrellaProtectionEnabled && <SecurityMeasuresCard />}
          <ClientsOverviewCard />
          <WirelessLatencyCard />
          <FailedConnectionsCard />
          <ConnectionStatsCard />
          {siteToSiteMode !== "none" && <SiteToSiteVpnCard />}
        </>
      )}
    </Screen>
  );
}
