export enum WirelessHealthRequestType {
  clients = "clients",
  nodes = "nodes",
  summary = "summary",
}

export enum WirelessHealthRequestPath {
  clients = "clients",
  devices = "devices",
}
