import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize } from "~/lib/themeHelper";

export const FONT_SIZES = {
  BODY: {
    default: normalizedFontSize(13),
  },
  SUBHEADER: {
    default: normalizedFontSize(14),
  },
  CARD_BUTTON: {
    default: normalizedFontSize(14),
  },
  NAV_BAR: {
    BUTTONS: {
      default: normalizedFontSize(16),
    },
    TITLE: {
      default: normalizedFontSize(16),
      medium: normalizedFontSize(20),
      large: normalizedFontSize(68),
    },
  },
};

export const CHART_FONT_SIZES = {
  CHART_AXIS_LABEL: {
    default: appSelect({
      enterprise: 16,
      go: normalizedFontSize(16),
    }),
  },
};

export const PROXIMA_SOFT_FONT = {
  fontFamily: platformSelect({
    ios: "proxima soft",
    android: "proximasoft",
  }),
};

export const CISCO_SANS_FONT = {
  fontFamily: platformSelect({
    android: "ciscosans",
    ios: "System",
  }),
};

export const ENTERPRISE_FONT_SIZES = {
  NAV_BAR: {
    TITLE: {
      default: 17,
    },
    SUBTITLE: {
      default: 14,
    },
  },
};

export default PROXIMA_SOFT_FONT;
