import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";
import GoColors from "~/go/constants/GoColors";
import { appSelect } from "~/lib/PlatformUtils";
import SharedColors from "~/shared/constants/SharedColors";

/* Add "named" colors here in the appropriate section */
const mkiColors = {
  /* Brand colors */
  merakiGreen: SharedColors.green,
  goPurple: SharedColors.purple, // unsure why this uses enterprise's purple
  /* Button colors */
  primaryButton: appSelect({
    enterprise: TEXT_COLORS.link,
    go: GoColors.purple,
  }),
  primaryButtonLight: GoColors.purpleLight,
  primaryButtonLighter: GoColors.purpleLighter,
  primaryButtonLightBorder: GoColors.purpleLight,
  spinner: appSelect({
    enterprise: SharedColors.blue,
    go: GoColors.purple,
  }),
  spinnerWhite: SharedColors.white,
  spinnerBackgroundGray: SharedColors.grayFaded80,
  spinnerBackgroundWhite: SharedColors.whiteFaded80,
  secondaryButton: SharedColors.gray60,
  unCheckedRadioButton: SharedColors.gray50,
  chevronColor: SharedColors.gray40,
  iosSwitchBackgroundActive: appSelect({
    enterprise: SharedColors.blue,
    go: GoColors.teal,
  }),
  androidSwitchToggleActive: appSelect({
    enterprise: SharedColors.white,
    go: GoColors.teal,
  }),
  androidSwitchBackgroundActive: appSelect({
    enterprise: SharedColors.blueLighter,
    go: GoColors.tealLight,
  }),
  androidSwitchToggleInactive: appSelect({
    enterprise: SharedColors.white,
    go: SharedColors.white,
  }),
  androidSwitchBackgroundInactive: appSelect({
    enterprise: "", // TODO: Add enterprise color
    go: GoColors.navyLighter,
  }),
  androidSwitchToggleActiveDisabled: appSelect({
    enterprise: "", // TODO: Add enterprise color
    go: GoColors.tealLight,
  }),
  androidSwitchBackgroundActiveDisabled: appSelect({
    enterprise: "", // TODO: Add enterprise color
    go: GoColors.tealLightest,
  }),
  androidSwitchToggleInactiveDisabled: appSelect({
    enterprise: "", // TODO: Add enterprise color
    go: GoColors.navyMed,
  }),
  androidSwitchBackgroundInactiveDisabled: appSelect({
    enterprise: "", // TODO: Add enterprise color
    go: GoColors.navyLightest,
  }),
  fabIconColor: SharedColors.white,
  fabBackgroundColor: appSelect({
    enterprise: SharedColors.blue,
    go: GoColors.purple,
  }),
  /* Tab Bar Icon Colors */
  unselectedTabBarIcon: appSelect({
    enterprise: SharedColors.gray60,
    go: GoColors.navyLighter,
  }),
  selectedTabBarIcon: appSelect({
    enterprise: SharedColors.green,
    go: GoColors.purple,
  }),

  defaultBackground: SharedColors.white,
  /* Component colors */
  borderColor: SharedColors.gray80,
  bottomBorderColor: SharedColors.gray80,
  darkGrayBackground: SharedColors.gray10Darker,
  lightGrayBackground: SharedColors.gray90,
  grayBackground: SharedColors.gray70,
  blackBackground: SharedColors.trueBlack,
  darkBackground: SharedColors.gray10Darker,
  whiteBackground: SharedColors.white,
  whiteBackgroundFaded: SharedColors.whiteFaded90,
  whiteBackgroundTransparent: SharedColors.whiteFaded75,
  tileBorderColor: SharedColors.gray90Darker,
  tileShadowColor: SharedColors.trueBlack,
  disclosureArrowColor: SharedColors.gray70,
  inactiveInputUnderline: GoColors.navyLighter,
  activeInputUnderline: GoColors.teal,
  pagerDotColor: GoColors.navyMed,
  infoCardColor: GoColors.navyTransparent,
  modalBackgroundColor: SharedColors.grayFaded35,
  /* Map colors */
  mapPinBlue: SharedColors.mapPinBlue,
  mapRadiusBlue: SharedColors.mapRadiusBlue,
  /* Graph colors */
  barChartBackground: appSelect({
    enterprise: SharedColors.gray90,
    go: GoColors.navyLightest,
  }),
  barChartBorderColor: SharedColors.gray80,
  barChartDefaultFill: appSelect({
    enterprise: SharedColors.teal,
    go: GoColors.purple,
  }),
  lineChartBorderColor: SharedColors.gray70,
  basicLineChartColor: SharedColors.chartLineBlue,
  thresholdLineColor: SharedColors.thresholdRed,
  referenceLineColor: SharedColors.gray90,
  primaryGraphLine: SharedColors.purple,
  primaryGraphLineFill: SharedColors.purpleLightestTransparent,
  secondaryGraphLine: appSelect({
    enterprise: SharedColors.teal,
    go: GoColors.teal,
  }),
  secondaryGraphLineFill: SharedColors.tealLightestTransparent,
  tertiaryGraphLine: SharedColors.yellowDarker,
  graphLegendLabelColor: GoColors.navyLighter,
  graphLegendSelectedColor: SharedColors.gray30,
  confirmAlertSuccess: appSelect({
    enterprise: EnterpriseColors.purple,
    go: GoColors.seaFoam,
  }),
  /* Application colors from Dashboard
   * private/javascripts/pies.js L5-19
   */
  mpurple: "#8D537C",
  lpurple: "#9659AF",
  mblue: "#1077B3",
  lblue: "#40B7D6",
  morange: "#F97821",
  lorange: "#FCBF62",
  mpink: "#D67193",
  lpink: "#F2AAC2",
  mgreen: "#008C8D",
  lgreen: "#94B378",
  /* Gradient colors */
  gradientEnd: GoColors.seaFoam,
  gradientStart: GoColors.purple,
  /* Status colors */
  enabledStatus: GoColors.purple,
  disabledStatus: GoColors.navyLighter,
  badBar: appSelect({
    enterprise: SharedColors.errorRed,
    go: GoColors.redBar,
  }),
  badStatus: appSelect({
    enterprise: SharedColors.errorRed,
    go: GoColors.red,
  }),
  badStatusBackground: SharedColors.redLightest,
  goodStatus: appSelect({
    enterprise: SharedColors.green,
    go: GoColors.teal,
  }),
  goodStatusBackground: SharedColors.greenLightest,
  offlineStatus: appSelect({
    enterprise: SharedColors.gray50,
    go: GoColors.navyLighter,
  }),
  warningStatus: appSelect({
    enterprise: SharedColors.yellow,
    go: GoColors.yellow,
  }),
  alertingBar: appSelect({
    enterprise: SharedColors.orange,
    go: GoColors.yellowBar,
  }),
  lightGreenWarningStatus: SharedColors.greenLighter,
  goodWithIssueBar: appSelect({
    enterprise: SharedColors.greenLighter,
    go: GoColors.greenBar,
  }),
  warningStatusBackground: SharedColors.yellowLightest,
  alertStatus: SharedColors.orange,
  /* Typography colors */
  alertTextColor: SharedColors.redDarker,
  captionTextColor: appSelect({
    enterprise: SharedColors.gray40,
    go: GoColors.navyLighter,
  }),
  displayNumberColor: appSelect({
    enterprise: SharedColors.gray10Darker,
    go: GoColors.purple,
  }),
  titleColor: appSelect({
    enterprise: SharedColors.gray10,
    go: GoColors.navy,
  }),
  headingColor: appSelect({
    enterprise: SharedColors.gray10,
    go: GoColors.navy,
  }),
  configHeadingColor: appSelect({
    enterprise: SharedColors.gray10, // currently this isn't being used in enterprise
    go: GoColors.purple,
  }),
  placeholderTextColor: appSelect({
    enterprise: SharedColors.gray40,
    go: GoColors.navyLighter,
  }),
  lightPlaceholderTextColor: GoColors.navyMed,
  subheadingColor: appSelect({
    enterprise: SharedColors.gray10,
    go: GoColors.navy,
  }),
  textColor: appSelect({
    enterprise: SharedColors.gray10Darker,
    go: GoColors.navy,
  }),
  secondaryTextColor: appSelect({
    enterprise: SharedColors.gray40,
    go: GoColors.navyLighter,
  }),
  whiteText: SharedColors.white,
  whiteTextFaded: SharedColors.whiteFaded75,
  formFieldLabel: appSelect({
    enterprise: SharedColors.gray40,
    go: GoColors.navyLight,
  }),
  defaultIconColor: appSelect({
    enterprise: SharedColors.gray40,
    go: GoColors.navyLighter,
  }),
  errorTextColor: SharedColors.errorRed,
  androidLoginSelectionColor: SharedColors.whiteFaded50,
  /* Pie chart colors */
  pie1: appSelect({
    enterprise: SharedColors.teal,
    go: GoColors.pieTeal1,
  }),
  pie2: appSelect({
    enterprise: SharedColors.chartBlue1,
    go: GoColors.pieBlue2,
  }),
  pie3: appSelect({
    enterprise: SharedColors.chartBlue2,
    go: GoColors.pieBlue1,
  }),
  pie4: appSelect({
    enterprise: SharedColors.chartMagenta,
    go: GoColors.purple,
  }),
  pie5: SharedColors.chartPurple,
  pie6: SharedColors.tealLighter,
  pie7: SharedColors.gray50,
  /* Colors for comparison in Location Analytics Graphs */
  comparePurple: GoColors.purple,
  comparePurpleLight: "rgba(101, 101, 246, 0.75)",
  comparePurpleLighter: "rgba(101, 101, 246, 0.5)",
  comparePurpleLightest: GoColors.purpleLight,

  compareTeal: GoColors.teal,
  compareTealLight: "rgba(19, 241, 201, 0.75)",
  compareTealLighter: "rgba(19, 241, 201, 0.5)",
  compareTealLightest: "rgba(19, 241, 201, 0.25)",
  /* Tag colors */
  tagBackgroundColor: SharedColors.tagBlue1,
  tagBorderColor: SharedColors.tagBlue2,
  tagTextColor: SharedColors.tagBlue3,
  /* Port diagram colors */
  switchFrontPanelBackgroundColor: appSelect({
    enterprise: SharedColors.gray80Lighter,
    go: SharedColors.white,
  }),
  panelShadowColor: appSelect({
    enterprise: SharedColors.gray70,
    go: GoColors.navyMed,
  }),
  portActiveColor: appSelect({
    enterprise: SharedColors.green,
    go: GoColors.teal,
  }),
  portAlertingColor: SharedColors.errorRed,
  portDisconnectedColor: appSelect({
    enterprise: SharedColors.gray10,
    go: GoColors.navyLighter,
  }),
  portDisabledColor: appSelect({
    enterprise: SharedColors.gray50,
    go: GoColors.navyLightest,
  }),
  poeActiveColor: SharedColors.white,
  darkModePorts: {
    portActiveColor: GoColors.teal,
    portAlertingColor: SharedColors.errorRed,
    portDisconnectedColor: SharedColors.gray50,
    portDisabledColor: SharedColors.gray20,
  },
  uplinkActiveColor: SharedColors.white,
  goodBar: appSelect({
    enterprise: SharedColors.green, // should be same as goodStatus
    go: GoColors.purple,
  }),
  offlineBar: appSelect({
    enterprise: SharedColors.gray50, // should be same as offlineStatus
    go: GoColors.navyLightest,
  }),
  applicationDetailsClientBar: appSelect({
    enterprise: SharedColors.green,
    go: GoColors.teal,
  }),
  hardwareIllustrations: {
    topFillColor: GoColors.navyLightest,
    outlineFillColor: SharedColors.white,
    sideFillColor: GoColors.navyMed,
    otherSideFillColor: GoColors.navyMedLight,
    logoFillColor: GoColors.navyLighterButNotLightest,
    activePortFillColor: GoColors.navyLighterButNotLightest,
    inactivePortFillColor: GoColors.navyMed,
  },
  localAddressingBox: SharedColors.gray90Lighter,
  localAddressingLine: GoColors.navyLighter,
  disabledHeadingColor: GoColors.navyMed,
  pickerBackground: SharedColors.gray90Lighter,
  onboardingQRCodeDiagramOutline: "#D8D8D8",
  onboardingQRCode: SharedColors.trueBlack,
  onboardingFooterStatusText: GoColors.purple,
  onboardingFooterStatusTextError: GoColors.red,
  onboardingStatusBarGradientStart: GoColors.seaFoam,
  onboardingStatusBarGradientEnd: GoColors.purple,
  onboardingStatusBarFailed: GoColors.red,
  creatingSSIDInProgressText: GoColors.purple,
  onboardingWiFiPasswordBackground: "rgba(222, 222, 250, 0.4)",
  invalidWalledGardenEntry: GoColors.navyLighter,
  closeButton: SharedColors.trueBlack,
  /* Map status colors */
  mapStatusColors: {
    online: SharedColors.green,
    alerting: SharedColors.orange,
    offline: SharedColors.errorRed,
    dormant: SharedColors.gray50,
  },
  othergrey: SharedColors.gray70Lighter,
  /* Camera stream */
  videoOverlayBackground: "#00000088",
  videoOverlayButtonBackground: "#FFFFFF33",
  videoGrayInactive: SharedColors.gray50Darker,
  videoLiveRed: SharedColors.redDarker,
  motionEvent: "#EEA751",
  fullTransparent: "#00000000",
  /* Skeleton block */
  lightSeletonBlock: SharedColors.gray90Darker,
  darkSeletonBlock: SharedColors.gray10,
};
export default mkiColors;
