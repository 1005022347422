import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import {
  getNextOnboardingStageConfig,
  nodesStatusToFooterStatus,
} from "~/lib/OnboardingFullstackUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { OnboardingNodeStatus, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "SetupComplete"> &
  BasicActions &
  BaseOnboardingScreenProps;

export class SetupCompleteScreen extends BaseOnboardingScreen<Props> {
  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.setupComplete);
  }

  onNextPress = () => this.props.completeOnboarding();

  renderMessage = () => {
    const { status } = this.props;
    if (status === OnboardingNodeStatus.finished) {
      return null;
    }
    return (
      <MkiText textStyle="secondary" screenStyles={styles.message}>
        {I18n.t("ONBOARDING_FULLSTACK.SETUP_COMPLETE.MESSAGE")}
      </MkiText>
    );
  };

  renderBody = () => (
    <View style={styles.bodyContainer}>
      <MerakiIcon name="check-circle" color={MkiColors.goPurple} size="xl" />

      <MkiText textStyle="heading" screenStyles={styles.header}>
        {I18n.t("ONBOARDING_FULLSTACK.SETUP_COMPLETE.HEADER")}
      </MkiText>
      {this.renderMessage()}
    </View>
  );

  showConnectAndUpgrade = () => {
    const { navigation } = this.props;
    navigation.navigate("ConnectAndUpgradeStatus");
  };

  getFooterData = () => {
    const { status } = this.props;
    return {
      buttons: [
        <RoundedButton key="nextButton" onPress={this.onNextPress}>
          {I18n.t("ONBOARDING_FULLSTACK.SETUP_COMPLETE.NEXT_BUTTON")}
        </RoundedButton>,
      ],
      status: nodesStatusToFooterStatus(status),
      onStatusPress: this.showConnectAndUpgrade,
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.setupComplete);
}

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    margin: SPACING.default,
  },
  header: {
    textAlign: "center",
    marginVertical: SPACING.large,
  },
  message: {
    marginHorizontal: SPACING.default,
    textAlign: "center",
    flexWrap: "wrap",
  },
});

export default compose<any>(
  connect(null, basicMapDispatchToProps),
  withOnboardingStatus,
)(SetupCompleteScreen);
