import * as errorMonitor from "@meraki/core/errors";
import { useTheme } from "@meraki/core/theme";
import { isEqual } from "lodash";
import { memo } from "react";
import { StyleProp, StyleSheet, View } from "react-native";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

import MkiColors from "~/constants/MkiColors";
import { Theme, themeColors } from "~/lib/themeHelper";
import { isNumber } from "~/lib/TypeHelper";

import { PeerGraphData } from "./PeerGraphUtils";

const renderSegments = (data: Props["data"], width: number, height: number, theme: Theme) => {
  let startPoint = 0;

  const barSegments = data.map((datum) => {
    /* Steps to build the chart
     * 1. multiply the percentage parameter by the overall width and adjust it accordingly
     * 2. build the shape "d" using the start point
     * 3. set the fill color
     */

    if (!isNumber(datum.percentage)) {
      // Temporary until we find all cases where d values are dynamic
      errorMonitor.notify("Invalid datum percentage");
    }

    const currentThemeColors = themeColors(theme).graphColors;
    const datumPercentage = datum.percentage || 0;
    const datumWidth = Math.max(Math.round(width * datumPercentage), 1);
    const dParam = `M ${startPoint} 0 H ${datumWidth + startPoint} V ${height} H ${startPoint} Z`;
    const fillColor = datum.color ? currentThemeColors[datum.color] : MkiColors.barChartDefaultFill;
    // reason for the random is beacuse react doesn't allow two children with the same key, but
    // it is possible for two bar segments to have the same percentage and color.
    const shapeElement = (
      <Path
        key={`percent:${datum.percentage}${Math.random() / 1000000}color:${fillColor}`}
        d={dParam}
        fill={fillColor}
      />
    );

    /* Finishing steps
     * 1. set the startPoint for the next segment
     * 2. return the next shape in the barSegements array
     */
    startPoint += datumWidth;
    return shapeElement;
  });
  return <G>{barSegments}</G>;
};

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

type GraphStyle = {
  width?: number;
  height?: number;
};

type Props = {
  data: PeerGraphData[];
  roundCorners?: boolean;
  graphStyles?: StyleProp<GraphStyle>;
  testID?: string;
};

/**
 * @deprecated - please use the SingleBarGraph found in
 * libs/shared/components/src/lib/SingleBarGraph/SingleBarGraph.tsx
 * instead
 */
export const SingleBarGraph = memo(({ data, graphStyles = {}, roundCorners = false }: Props) => {
  const { theme } = useTheme();
  const style = StyleSheet.flatten(graphStyles);
  const width = style.width || 100;
  const height = style.height || 12;
  const derivedRoundCorners = roundCorners ? "url(#chartMask)" : undefined;

  return (
    <View style={[graphStyles, { width, height }]} testID="SINGLE_BAR_GRAPH">
      <Svg width={width} height={height}>
        <Defs>
          <ClipPath id="chartMask">
            <Rect width={width} height={height} rx={height / 2} ry={height / 2} />
          </ClipPath>
        </Defs>
        <G clipPath={derivedRoundCorners}>
          <Rect
            width={width}
            height={height}
            fill={themeColors(theme).graph.barChartBackground || MkiColors.barChartBackground}
          />
          {renderSegments(data, width, height, theme)}
        </G>
      </Svg>
    </View>
  );
}, areEqual);

SingleBarGraph.displayName = "SingleBarGraph";

export default SingleBarGraph;
