import { StyleProp, StyleSheet, TextStyle, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import Touchable from "~/shared/components/Touchable";

interface RevealPasswordButtonProps {
  passwordVisible: boolean;
  buttonStyles: StyleProp<TextStyle>;
  onPress: () => void;
}

const RevealPasswordButton = (props: RevealPasswordButtonProps) => {
  const { passwordVisible, buttonStyles, onPress } = props;
  return (
    <Touchable onPress={onPress} transparentBackground>
      <View style={[styles.buttonStyle, buttonStyles]} testID={"REVEAL_PASSWORD_BUTTON"}>
        <MerakiIcon
          name={passwordVisible ? "eye-slash" : "eye"}
          size="s"
          color={MkiColors.secondaryButton}
        />
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    paddingVertical: SPACING.small,
    paddingHorizontal: SPACING.default,
  },
});

export default RevealPasswordButton;
