import { ColorBlue8 } from "@magnetic/design-tokens";
import Svg, { Defs, G, LinearGradient, Path, Rect, Stop } from "react-native-svg";

import { PortProps } from "./types";

type SelectedIndicatorProps = Pick<PortProps, "kind" | "selected" | "flipped">;

export function SelectedIndicator({ kind, selected, flipped }: SelectedIndicatorProps) {
  let width = "40";

  if (kind === "stack") {
    width = "80";
  } else if (kind === "usb") {
    width = "60";
  }

  return (
    <Svg width={width} height="12" viewBox={`0 0 ${width} 12`}>
      {selected && (
        <G
          translateX={flipped ? width : 0}
          translateY={flipped ? 12 : 0}
          rotation={flipped ? 180 : 0}
        >
          <Path
            d={`M0 2C0 0.89543 0.895431 0 2 0H38C39.1046 0 ${width} 0.895431 ${width} 2V24H0V2Z`}
            fill="url(#paint0_linear_2391_28237)"
          />
          <Rect width={width} height="3" rx="1.5" fill={ColorBlue8} />
        </G>
      )}
      <Defs>
        <LinearGradient
          id="paint0_linear_2391_28237"
          x1="20"
          y1="0"
          x2="20"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={ColorBlue8} stopOpacity="0.3" />
          <Stop offset="1" stopColor={ColorBlue8} stopOpacity="0" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
