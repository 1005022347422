import { I18n } from "@meraki/core/i18n";
import { ActivityTimeline, ActivityType, Button } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect } from "react";

import { useApPlacementRooms } from "../store/useApPlacementRooms";

export const IntroScreen = () => {
  const networkId = useCurrentNetworkId();
  const { resetRoomsByNetwork } = useApPlacementRooms();
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();

  useEffect(() => {
    resetRoomsByNetwork(networkId);
  }, [networkId, resetRoomsByNetwork]);

  const steps: ActivityType[] = [
    {
      title: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.ONE.TITLE"),
      paragraphText: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.ONE.STEP"),
    },
    {
      title: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.TWO.TITLE"),
      paragraphText: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.TWO.STEP"),
    },
    {
      title: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.THREE.TITLE"),
      paragraphText: I18n.t("ACCESS_POINT.INTRO_SCREEN.STEPS.THREE.STEP"),
    },
  ];
  return (
    <Screen addDefaultPadding>
      <ActivityTimeline
        title={I18n.t("ACCESS_POINT.INTRO_SCREEN.TITLE")}
        activity={steps}
        numbered
        testID="STEPS"
      />
      <Box paddingTop="md" flex={1} justifyContent="flex-end">
        <Button
          text={I18n.t("CONTINUE")}
          onPress={() => navigation.navigate("RoomSelect")}
          testID="CONTINUE_BUTTON"
        />
      </Box>
    </Screen>
  );
};
