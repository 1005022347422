import { I18n } from "@meraki/core/i18n";
import { Loader, Notification } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { OrgChooseResponseType, SamlOrPasswordLoginResponse } from "@meraki/shared/api";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { OneTimePassword } from "../components/PostLogin/OneTimePassword";
import { OrgChoose } from "../components/PostLogin/OrgChoose";
import { SmsAuth } from "../components/PostLogin/SmsAuth";
import { TwoFactorAuth } from "../components/PostLogin/TwoFactorAuth";
import { PostLoginProps } from "../components/PostLogin/Types";
import { fetchOrgAndUser, handleSuccessfulLogin } from "../libs/handleLogin";

type NextStepProps = {
  latestResponse: SamlOrPasswordLoginResponse;
  setLatestResponse: PostLoginProps["setLatestResponse"];
};

const NextStep = ({ latestResponse, setLatestResponse }: NextStepProps) => {
  switch (latestResponse?.mode) {
    case "one_time_password":
      return <OneTimePassword setLatestResponse={setLatestResponse} />;
    case "two_factor":
      return <TwoFactorAuth setLatestResponse={setLatestResponse} />;
    case "sms":
      return <SmsAuth setLatestResponse={setLatestResponse} response={latestResponse} />;
    case "org_choose":
      return (
        <OrgChoose
          setLatestResponse={setLatestResponse}
          response={latestResponse as OrgChooseResponseType}
        />
      );
    case "success":
    default:
      return <Loader.Spinner />;
  }
};

export const LoginNextStepsScreen = () => {
  const navigation = useNavigation();
  const {
    params: { response: initialResponse, email },
  } = useRoute<RouteProp<LoginGroupProps, "LoginNextSteps">>();
  const [latestResponse, setLatestResponse] = useState(initialResponse);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    async (response: SamlOrPasswordLoginResponse) => {
      navigation.setOptions({ headerTitle: I18n.t("VERIFY_EMAIL.SIGNING_IN") });

      const orgUserData = await fetchOrgAndUser(response, email);
      await handleSuccessfulLogin(orgUserData, dispatch);
    },
    [dispatch, email, navigation],
  );

  const updateLatestResponse = useCallback(
    async (response: SamlOrPasswordLoginResponse) => {
      setLatestResponse(response);

      if (response?.mode === "success") {
        navigation.setOptions({ headerTitle: I18n.t("VERIFY_EMAIL.SIGNING_IN") });
        await handleSuccess(response);
      }
    },
    [handleSuccess, navigation],
  );

  useEffect(() => {
    if (initialResponse?.mode === "success") {
      handleSuccess(initialResponse);
    }
  }, [handleSuccess, initialResponse, updateLatestResponse]);

  return (
    <Screen addDefaultPadding gap="md">
      {latestResponse?.error && (
        <Notification.Inline status="negative" message={latestResponse?.error} />
      )}
      <NextStep latestResponse={latestResponse} setLatestResponse={updateLatestResponse} />
    </Screen>
  );
};
