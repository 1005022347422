if (__DEV__) {
  require("~/wdyr");
}
import "web/setup";

import { AppRegistry } from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import LoadingScreen from "~/enterprise/screens/LoadingScreen";
import WebActionSheet from "~/shared/components/web/WebActionSheet";
import WebAlert from "~/shared/components/web/WebAlert";
import { setupStorePersistor, store } from "~/store/configureStore";

const persistor = setupStorePersistor();

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

const registerWebComponent = (name, Component) => {
  AppRegistry.registerComponent(name, () => (props) => {
    return (
      <Provider store={store}>
        <PersistGate
          loading={<LoadingScreen devMessage={"Waiting on redux-persist"} />}
          persistor={persistor}
        >
          <Component {...props} />
        </PersistGate>
      </Provider>
    );
  });
};

registerWebComponent("WebAlert", WebAlert);
registerWebComponent("WebActionSheet", WebActionSheet);

// This will start the app with either index.go.js or index.enterprise.js
import "~/entry";
