import { I18n } from "@meraki/core/i18n";
import { ScrollView, StyleSheet, Text } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ConnectingAnimation from "~/go/components/onboarding/ConnectingAnimation";
import MkiText from "~/shared/components/MkiText";

export interface ConnectAndUpgradeBodyProps {
  headerTitle?: string;
  onLEDLegendPress: () => void;
}

const ConnectAndUpgradeBody = (props: ConnectAndUpgradeBodyProps) => {
  const { headerTitle, onLEDLegendPress } = props;
  return (
    <ScrollView contentContainerStyle={styles.bodyContainer}>
      <MkiText textStyle="heading" screenStyles={styles.header}>
        {headerTitle}
      </MkiText>
      <ConnectingAnimation style={styles.connectingAnimation} />
      <Text style={styles.message}>
        <MkiText textStyle="smallSecondary" screenStyles={styles.message}>
          {I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE.MESSAGE.PART_1")}
        </MkiText>
        <MkiText
          testID="LED_LINK"
          textStyle="small"
          screenStyles={styles.linkText}
          onPress={onLEDLegendPress}
        >
          {I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE.MESSAGE.LED_LINK")}
        </MkiText>
        <MkiText textStyle="smallSecondary" screenStyles={styles.message}>
          {I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE.MESSAGE.PART_2")}
        </MkiText>
      </Text>
    </ScrollView>
  );
};

ConnectAndUpgradeBody.defaultProps = {
  headerTitle: I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE.HEADER"),
};

const styles = StyleSheet.create({
  bodyContainer: {
    margin: SPACING.default,
  },
  connectingAnimation: {
    marginVertical: SPACING.large,
  },
  linkText: {
    flex: 1,
    flexWrap: "wrap",
    color: MkiColors.primaryButton,
  },
  header: {
    textAlign: "center",
  },
  message: {
    textAlign: "center",
    flexWrap: "wrap",
  },
});

export default ConnectAndUpgradeBody;
