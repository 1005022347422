import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { MkiText } from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";
import {
  SupportCaseAbstractedIdentifiers,
  SupportCaseStatusOptions,
} from "~/shared/types/SupportCaseTypes";

const SupportCaseStatus = (props: any) => {
  const { status } = props;
  const themeColors = useThemeColors();

  const abstractedStatus =
    status !== SupportCaseStatusOptions.closed
      ? SupportCaseAbstractedIdentifiers.open
      : SupportCaseAbstractedIdentifiers.closed;
  const colorStyles =
    abstractedStatus === SupportCaseAbstractedIdentifiers.open
      ? { backgroundColor: themeColors.status.online.color }
      : { backgroundColor: themeColors.border.borderColor };

  return (
    <View
      style={[styles.container, colorStyles]}
      testID={`SUPPORT_CENTER_STATUS.${abstractedStatus}`}
    >
      <MkiText textStyle="small">{I18n.t(`SUPPORT_CENTER.STATUS.${abstractedStatus}`)}</MkiText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingVertical: SPACING.meager,
    paddingHorizontal: SPACING.medium,
  },
});

export default SupportCaseStatus;
