import { getTimezone } from "@meraki/core/date";
import { getTimezoneOffset } from "date-fns-tz";
import { groupBy, keyBy } from "lodash";
import * as RNLocalize from "react-native-localize";
import { createSelector } from "reselect";

import Network from "~/api/models/Network";
import {
  currentNetworkState,
  getCurrentOrganization,
  getOrgNetworksState,
} from "~/selectors/getters";
import { MkiSelector, RootState } from "~/shared/types/Redux";

export const getNetworksById: MkiSelector<Record<string, Network>> = createSelector(
  getOrgNetworksState,
  (networks) => networks && keyBy(networks, "id"),
);

export const getOrgNetworkById: MkiSelector<Network | undefined, Network["id"]> = createSelector(
  getNetworksById,
  (_: RootState, id: Network["id"]) => id,
  (orgNetworks, id) => orgNetworks?.[id],
);

export const getNetworksByOrgId: MkiSelector<Record<string, Network[]>> = createSelector(
  getOrgNetworksState,
  (networks) => networks && groupBy(networks, "organizationId"),
);

export const getCurrentNetwork: MkiSelector<Network | undefined> = createSelector(
  currentNetworkState,
  getNetworksById,
  (networkId, networks) => (networkId ? networks?.[networkId] : undefined),
);

const blankNetworkName = "";
export const getNetworkName: MkiSelector<string> = createSelector(
  getCurrentNetwork,
  (network) => network?.name || blankNetworkName,
);

export const getOrgNetworks: MkiSelector<Network[] | undefined> = createSelector(
  getCurrentOrganization,
  getNetworksByOrgId,
  (orgId, networks) => (orgId ? networks?.[orgId] : undefined),
);

export const getOrgNetworksCount: MkiSelector<number> = createSelector(
  getOrgNetworks,
  (networks) => networks?.length ?? 0,
);

export const getOrgHasNetworks: MkiSelector<boolean> = createSelector(
  getOrgNetworks,
  (networks) => !!networks?.length,
);

export const getNetworkTimezone: MkiSelector<string> = createSelector(
  getCurrentNetwork,
  (network) => network?.timeZone || RNLocalize.getTimeZone(),
);

const AMERICAN_TIMEZONE = "America";
export const getIsAmericanTimezone: MkiSelector<boolean> = createSelector(
  getNetworkTimezone,
  (timeZone) => timeZone?.startsWith(AMERICAN_TIMEZONE) ?? false,
);

export const getTimezoneOffsetMS: MkiSelector<number> = createSelector(
  getNetworkTimezone,
  (timeZone) => {
    if (!timeZone) {
      return 0;
    }

    const now = new Date(Date.now());
    const localUtcOffset = getTimezoneOffset(getTimezone(RNLocalize.getTimeZone()), now);
    const otherTimeZoneUtcOffset = getTimezoneOffset(getTimezone(timeZone), now);

    return localUtcOffset - otherTimeZoneUtcOffset;
  },
);
