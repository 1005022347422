import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Unchecked({ disabled }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 20 20" width={20} height={20} fill="none">
      <Rect
        x={1}
        y={1}
        width={18}
        height={18}
        rx={3}
        fill={disabled ? theme.color.control.bg.weak.disabled : theme.color.control.bg.weak.base}
      />
      <Rect
        x={1}
        y={1}
        width={18}
        height={18}
        rx={3}
        stroke={disabled ? theme.color.control.border.disabled : theme.color.control.border.base}
        strokeWidth={2}
      />
    </Svg>
  );
}
