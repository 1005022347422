import { useFirmwareUpgrades } from "@meraki/shared/api";
import { getProductType } from "@meraki/shared/devices";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { StyleProp, TextStyle } from "react-native";

import { useApplianceSettings } from "~/api/queries/appliances/useApplianceSettings";
import { Device } from "~/api/schemas/Device";
import { nodeInfoCardContentForDevice } from "~/lib/NodeInfoCardUtils";
import SummaryList from "~/shared/components/SummaryList";
import { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";
import { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

export interface NodeInfoCardProps {
  device: DeviceWithNodeGroupInfo | Device;
  heading: string;
  headingTextStyle?: StyleProp<TextStyle>;
  renderRow?: (rowData: any) => React.ReactNode;
  timezone?: string;
  disableBottomBorder?: boolean;
  testID?: string;
  ngData?: Device[];
}

const NodeInfoCard = ({
  device,
  heading,
  headingTextStyle,
  renderRow,
  timezone,
  ngData,
  disableBottomBorder,
  testID,
}: NodeInfoCardProps) => {
  const isAppliance = getProductType(device.model) === ProductType.appliance;
  const applianceSettings = useApplianceSettings(isAppliance).data;
  const networkId = useCurrentNetworkId();
  const firmwareInfo = useFirmwareUpgrades({ networkId });
  const hasAlphaFeatures = useHasAlphaFeatures();

  const opts = {
    timezone,
    imei: ngData?.[0]?.imei,
    applianceSettings,
    firmwareInfo: firmwareInfo.data,
    hasAlphaFeatures,
  };

  return (
    <SummaryList
      heading={heading}
      headingTextStyle={headingTextStyle}
      contentRows={nodeInfoCardContentForDevice(device, opts)}
      customRenderRow={renderRow}
      disableBottomBorder={disableBottomBorder}
      testID={testID}
    />
  );
};

export default NodeInfoCard;
