import { PureComponent } from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { ApiResponseAction } from "~/middleware/api";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import MkiText from "~/shared/components/MkiText";
import GeneralStatus from "~/shared/constants/Status";

type Props<T = ApiResponseAction<void>> = {
  title: string;
  entity: string;
  save?: (name: string) => Promise<T>;
  promptIcon?: React.ReactElement;
  icon?: React.ReactElement;
  titleStyle?: StyleProp<TextStyle>;
  shouldFlex?: boolean;
  containerStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

type State = {
  showEditModal: boolean;
  name: string;
  alert?: {
    message: string;
    status: (typeof GeneralStatus)[keyof typeof GeneralStatus];
  };
  loading: boolean;
};

export default class EditableNameHeader<T> extends PureComponent<Props<T>, State> {
  static defaultProps = {
    save: undefined,
    icon: null,
    promptIcon: null,
    titleStyle: {},
    shouldFlex: false,
    containerStyles: {},
    testID: undefined,
  };

  private EDIT_TITLE_TEXT: string;

  constructor(props: Props<T>) {
    super(props);

    this.EDIT_TITLE_TEXT = I18n.t("EDITABLE_NAME_HEADER_MODAL_TITLE_TEXT", {
      entity: props.entity,
    });

    this.state = {
      showEditModal: false,
      name: props.title,
      alert: undefined,
      loading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
    const { title } = this.props;
    if (nextProps.title !== title && nextProps.title !== "") {
      this.setState({
        name: nextProps.title,
      });
    }
  }

  save = () => {
    const { name } = this.state;
    const { save } = this.props;

    this.setState({ loading: true });
    save?.(name).then(this.dismissModal, (error) =>
      this.setState({
        alert: {
          message: error,
          status: GeneralStatus.bad,
        },
        loading: false,
      }),
    );
  };

  dismissModal = () => {
    const { title } = this.props;
    this.setState({
      showEditModal: false,
      name: title,
      loading: false,
      alert: undefined,
    });
  };

  changeText = (name: string) => {
    this.setState({
      name,
      alert: undefined,
    });
  };

  render() {
    const { testID, containerStyles, shouldFlex, save, title, titleStyle, icon, promptIcon } =
      this.props;
    const { alert, loading, name, showEditModal } = this.state;
    const onPress = () => this.setState({ showEditModal: true });
    const editIcon = (
      <MerakiIcon
        name="edit"
        size="s"
        color={MkiColors.secondaryButton}
        containerStyle={styles.pencilIcon}
        onPress={onPress}
        testID={testID && `${testID}.EDIT`}
      />
    );
    return (
      <View style={[styles.container, containerStyles]}>
        {icon}
        <View style={shouldFlex ? styles.nameContainer : {}} testID={testID}>
          <MkiText
            textStyle="heading"
            screenStyles={titleStyle}
            onPress={save ? onPress : undefined}
          >
            {title}
          </MkiText>
        </View>
        {save ? editIcon : null}
        <InputModal
          autoFocus
          value={name}
          title={this.EDIT_TITLE_TEXT}
          visible={showEditModal}
          onPrimaryPress={this.save}
          onChangeText={(name) => this.changeText(name)}
          onSubmitEditing={this.save}
          onExit={this.dismissModal}
          alert={alert}
          promptIcon={promptIcon}
          loading={loading}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: SPACING.default,
  },
  pencilIcon: {
    marginLeft: SPACING.small,
    alignSelf: "center",
  },
  nameContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
