export const DOT_REGEX = /\./g;
const IP_ADDRESS_PART_COUNT = 4;
const IP_ADDRESS_PART_SIZE = 8;
const IP_ADDRESS_PART_MAX = 255;
const IP_ADDRESSES_USABLE = 65280;

export function dottedDecimalFromSubnetMask(subnetMask: number): string {
  const dottedDecimal: number[] = [];
  const divisionWhole = Math.floor((subnetMask - 1) / 8);
  const cidrFraction = subnetMask - 8 * divisionWhole;

  for (let i = 0; i < IP_ADDRESS_PART_COUNT; i += 1) {
    let workingOctet;
    if (divisionWhole === i) {
      workingOctet = 2 ** 8 - 2 ** (8 - cidrFraction);
    } else if (divisionWhole > i) {
      workingOctet = 2 ** 8 - 1;
    } else {
      workingOctet = 0;
    }
    dottedDecimal.push(workingOctet);
  }
  return dottedDecimal.join(".");
}

// Source: https://ba.net/util/netcalc/netcalc.html
function countBits(num: number) {
  if (num === IP_ADDRESS_PART_MAX) {
    return IP_ADDRESS_PART_SIZE;
  }
  let i = 0;
  let bitpat = IP_ADDRESSES_USABLE;
  while (i < IP_ADDRESS_PART_SIZE) {
    if (num === (bitpat & IP_ADDRESS_PART_MAX)) {
      return i;
    }

    bitpat >>= 1;
    i += 1;
  }

  return Number.NaN;
}

// Source: https://ba.net/util/netcalc/netcalc.html
export function subnetMaskFromDottedDecimal(dottedDecimal: string): number | string {
  const dottedArray = dottedDecimal.split(".");
  let sumofbits = 0;
  for (let i = 0; i < IP_ADDRESS_PART_COUNT; i += 1) {
    const tmpvar = parseInt(dottedArray[i] as string, 10);
    if (isNaN(tmpvar)) {
      return sumofbits;
    }

    const bits = countBits(tmpvar);

    if (isNaN(bits)) {
      return sumofbits;
    }

    sumofbits += bits;
  }

  return sumofbits;
}
