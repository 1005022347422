import { I18n } from "@meraki/core/i18n";
import { get, isEmpty } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { CISCO_SANS_FONT, FONT_SIZES } from "~/constants/MkiFonts";
import withLLDPData, { ReduxProps as LLDPDataReduxProps } from "~/hocs/LLDPData";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { getChassisId, portNumberOfSwitchPort } from "~/lib/SwitchPortUtils";
import { switchPortsForSerialWithIds } from "~/selectors";
import LinkText from "~/shared/components/LinkText";
import MkiText from "~/shared/components/MkiText";
import SwitchPortIcon from "~/shared/components/portDiagrams/portIcons/SwitchPortIcon";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  lldpMac: string;
  portNumber: number;
  switchPorts: {
    id: string;
    is_uplink: boolean;
    num: number[];
  }[];
};

type SwitchPortSettingsAffectedSummaryProps = {
  navigate: (screen: string, params: Record<string, unknown>) => void;
  serialNumber: string;
  switchPortIds: string[];
};

type Props = LLDPDataReduxProps & ReduxProps & SwitchPortSettingsAffectedSummaryProps;

export class SwitchPortSettingsAffectedSummaryComponent extends PureComponent<Props> {
  showHardwareDetails = () => {
    const { navigate, lldpDevice } = this.props;

    navigate("HardwareDetails", {
      serialNumber: lldpDevice.serial,
    });
  };

  showClientDetails = () => {
    const { navigate, lldpClient, deviceClients } = this.props;

    const clients = Object.values(deviceClients);
    const clientId = get(lldpClient, "id") || get(clients, "0.id");

    navigate("ClientDetails", {
      id: clientId,
    });
  };

  showConnectedClientsList = () => {
    const { navigate, deviceClients } = this.props;

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const clientIds = Object.values(deviceClients).map((client) => client.id);
    const customFilter = CUSTOM_FILTERS.CLIENTS.byIds(clientIds);

    navigate("ClientList", {
      title: I18n.t("CLIENTS_LIST.TITLE", { client_word: I18n.t("DEVICES_WORD") }),
      customFilter,
    });
  };

  renderConnectedClientsText() {
    const { lldpClient, lldpDevice, lldpMac, deviceClients, switchPorts } = this.props;

    if (switchPorts.length !== 1) {
      return null;
    }

    const switchPort = switchPorts[0];

    if (switchPort.is_uplink) {
      return <MkiText textStyle="smallSecondary">{I18n.t("PORTS.INTERNET_CONNECTION")}</MkiText>;
    }

    if (lldpDevice) {
      const text = get(lldpDevice, "name") || get(lldpDevice, "serial", lldpMac);
      return linkText(this.showHardwareDetails, text);
    }

    if (lldpClient) {
      const text = get(lldpClient, "description") || get(lldpClient, "mac", lldpMac);
      return linkText(this.showClientDetails, text);
    }

    if (isEmpty(deviceClients)) {
      return (
        <MkiText textStyle="smallSecondary">
          {I18n.t("PORTS.NO_CONNECTED_CLIENTS", { client_word: I18n.t("DEVICES_WORD") })}
        </MkiText>
      );
    }

    const clients = Object.values(deviceClients);

    if (clients.length === 1) {
      return linkText(this.showClientDetails, get(clients, "0.description"));
    }

    const text = `${clients.length} ${capitalizeFirstLetter(I18n.t("PORTS.DEVICES"))}`;
    return linkText(this.showConnectedClientsList, text);
  }

  renderConnectedDevices() {
    const { switchPorts } = this.props;

    if (switchPorts.length !== 1) {
      return null;
    }

    const switchPort = switchPorts[0];

    return (
      <View style={styles.connectedDevicesContainer}>
        <View style={styles.iconContainer}>
          <SwitchPortIcon switchPortId={switchPort.id} size="xs" />
        </View>
        {this.renderConnectedClientsText()}
      </View>
    );
  }

  renderAffectedPortNumbers() {
    const { switchPorts } = this.props;
    const portNumbers = switchPorts.map((port) => portNumberOfSwitchPort(port));
    const portNumbersString = portNumbers.join(", ");

    return (
      <MkiText textStyle="smallSecondary" screenStyles={styles.infoText}>
        {`${I18n.t("PORTS.SETTINGS.DESCRIPTION")} ${portNumbersString}`}
      </MkiText>
    );
  }

  render() {
    const { switchPorts } = this.props;

    if (switchPorts.length === 0) {
      return null;
    }

    if (switchPorts.length === 1) {
      return this.renderConnectedDevices();
    }

    return this.renderAffectedPortNumbers();
  }
}

const linkText = (onPress: () => void, text?: string) => (
  <LinkText onPress={onPress} textStyles={styles.linkText}>
    {text}
  </LinkText>
);

const styles = StyleSheet.create({
  connectedDevicesContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: SPACING.default,
  },
  iconContainer: {
    marginRight: SPACING.tiny,
  },
  infoText: {
    marginHorizontal: SPACING.default,
    marginVertical: SPACING.default,
  },
  linkText: {
    ...CISCO_SANS_FONT,
    color: MkiColors.primaryButton,
    fontSize: FONT_SIZES.BODY.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: SwitchPortSettingsAffectedSummaryProps,
): ReduxProps {
  const { serialNumber, switchPortIds } = props;
  const switchPorts = switchPortsForSerialWithIds(state, serialNumber, switchPortIds);

  const lldpMac = switchPorts.length === 1 ? getChassisId(switchPorts[0]) : undefined;
  const portNumber = get(switchPorts, "0.num.0");

  return {
    // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    lldpMac,
    portNumber,
    switchPorts,
  };
}

export default compose<any>(
  connect(mapStateToProps),
  withLLDPData,
)(SwitchPortSettingsAffectedSummaryComponent);
