import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import WiFiShowHidePWText from "~/go/components/onboarding/WiFiShowHidePWText";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import {
  getNextOnboardingStageConfig,
  nodesStatusToFooterStatus,
} from "~/lib/OnboardingFullstackUtils";
import { showUmbrellaOnboarding, ssidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import { SSID } from "~/shared/types/Models";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  ssid: SSID;
  showUmbrella: boolean;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "WiFiSetupCreateSuccess"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

export class WiFiSetupCreateSuccessScreen extends BaseOnboardingScreen<Props> {
  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.wifiSuccess);
  }

  onPressShareWiFi = () => {
    const { navigation, ssid } = this.props;

    navigation.navigate("SSIDShare", {
      name: ssid.name,
      encryptionMode: ssid.encryptionMode,
      passphrase: ssid.psk,
      ssid,
    });
  };

  renderMessageText = (text: string) => <MkiText screenStyles={styles.message}>{text}</MkiText>;

  renderPasswordText = () => {
    const {
      ssid: { psk },
    } = this.props;

    return psk === undefined || psk === "" ? (
      this.renderMessageText(I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE_SUCCESS.NO_PASSWORD"))
    ) : (
      <WiFiShowHidePWText psk={psk} />
    );
  };

  renderHeader = () => {
    const { ssid } = this.props;
    return (
      <View style={styles.headerContainer}>
        <StatusIcon
          customIcon={{
            icon: MerakiIcon,
            name: "wifi",
            size: "l",
            hasGradient: true,
          }}
        />
        <MkiText textStyle="heading" screenStyles={styles.headerText}>
          {ssid.name}
        </MkiText>
      </View>
    );
  };

  renderMessages = () => (
    <View style={styles.messagesContainer}>
      {this.renderMessageText(I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE_SUCCESS.MESSAGE"))}
      {this.renderPasswordText()}
    </View>
  );

  renderBody = () => {
    return (
      <FullScreenContainerView withScroll defaultMargins>
        {this.renderHeader()}
        {this.renderMessages()}
        <RoundedButton
          testID={"SHARE_WIFI"}
          buttonType={ButtonType.tertiary}
          onPress={this.onPressShareWiFi}
        >
          {I18n.t("ONBOARDING_FULLSTACK.WIFI_SETUP.CREATE_SUCCESS.SHARE_BUTTON")}
        </RoundedButton>
      </FullScreenContainerView>
    );
  };

  showConnectAndUpgrade = () => {
    const { navigation } = this.props;
    navigation.navigate("ConnectAndUpgradeStatus");
  };

  getFooterData = () => {
    const { status } = this.props;
    return {
      buttons: [
        <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
          {this.nextStageConfig.nextButtonText}
        </RoundedButton>,
      ],
      status: nodesStatusToFooterStatus(status),
      onStatusPress: this.showConnectAndUpgrade,
    };
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.wifiSuccess, {
    showUmbrella: this.props.showUmbrella,
  });
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: "center",
    marginTop: SPACING.default,
    marginBottom: SPACING.extraLarge,
  },
  headerText: {
    marginVertical: SPACING.small,
    textAlign: "center",
  },
  message: {
    textAlign: "center",
    marginBottom: SPACING.default,
  },
  messagesContainer: {
    marginVertical: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: OnboardingStackProps["WiFiSetupCreateSuccess"],
): ReduxProps {
  return {
    ssid: ssidsSelector(state)[props.ssidNumber] || {},
    showUmbrella: showUmbrellaOnboarding(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(WiFiSetupCreateSuccessScreen);
