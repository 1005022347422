import * as Sentry from "@sentry/react-native";
import { Platform } from "react-native";
import DeviceInfo from "react-native-device-info";

import { dist, release } from "../sentryVersions.json";
import getIntegrations from "./getIntegrations";

export { registerNavigationContainer } from "./getIntegrations";

interface InitArguments {
  dsn: string;
  tracesSampleRate?: number;
}

export function init({ dsn, tracesSampleRate }: InitArguments = { dsn: "" }) {
  Sentry.init({
    enabled: !__DEV__,
    dsn,
    release: release ?? DeviceInfo.getVersion(),
    dist: dist ?? DeviceInfo.getBuildNumber(),
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: tracesSampleRate ?? 0.5,
    integrations: getIntegrations(),
    maxValueLength: 5000,
    ignoreErrors:
      Platform.OS === "web"
        ? [
            "Non-Error promise rejection captured",
            // google injected JS error
            // reference: https://groups.google.com/a/chromium.org/g/chromium-discuss/c/7VU0_VvC7mE/m/R4nBXLkwBgAJ
            /__gCrWeb/,
          ]
        : [],
  });
}

export function notify(err: Error | string, context?: string) {
  const sentryTags = context ? { tags: { context: context } } : undefined;
  if (typeof err === "string") {
    Sentry.captureMessage(err, sentryTags);
  } else {
    Sentry.captureException(err, sentryTags);
  }
}

export function notifyNonOptional<T>(errMsg: string, value: T | undefined | null) {
  if (!value) {
    Sentry.captureMessage(errMsg);
  }

  return value as Required<T>;
}

export function setUser(id?: string) {
  Sentry.setUser({ id });
}

export function setOrgID(id?: string) {
  Sentry.setTag("orgID", id);
}
