import { StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import PickerModal, { PickerModalItems, PickerModalProps } from "~/go/components/PickerModal";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import SimpleDisclosureRow, { SimpleDisclosureRowProps } from "~/shared/rows/SimpleDisclosureRow";

type PickerModalRowProps = {
  containerStyles?: ViewStyle;
  disableBottomBorder?: boolean;
  heading?: string;
  headingContainerStyles?: ViewStyle;
  headingTextStyle?: TextStyle;
  loading?: boolean;
  subheading?: string;
  testID?: string;
  visible: boolean;
  title?: string;
  items: PickerModalItems[];
  onExit: () => void;
  loginStyle?: boolean;
  isError?: boolean;
  label: React.ReactNode;
  subtitle?: string;
  screenStyles?: ViewStyle;
} & SimpleDisclosureRowProps &
  Pick<PickerModalProps, "onValueSelect" | "selectedValue">;

const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

const renderSubheading = (subheading: string) => (
  <View style={styles.subheading}>
    <MkiText textStyle="small" screenStyles={styles.subheadingText}>
      {subheading}
    </MkiText>
  </View>
);

const renderRow = (rowData: PickerModalRowProps) => (
  <SimpleDisclosureRow
    icon={rowData.icon}
    subtitle={rowData.subtitle}
    testID={`${rowData.testID ?? "PICKER_MODAL"}_ROW`}
    onPress={rowData.onPress}
    context={rowData.context}
  >
    {rowData.label}
  </SimpleDisclosureRow>
);

const PickerModalRow = (props: PickerModalRowProps) => {
  const {
    containerStyles,
    disableBottomBorder,
    heading,
    headingContainerStyles,
    headingTextStyle,
    loading,
    subheading,
    testID,
    visible,
    selectedValue,
    title,
    items,
    onValueSelect,
    onExit,
    loginStyle,
    isError,
  } = props;

  const headingView = heading ? (
    <View style={[styles.heading, headingContainerStyles]}>
      <MkiText textStyle="subheading" screenStyles={headingTextStyle}>
        {heading}
      </MkiText>
      {subheading ? renderSubheading(subheading) : null}
    </View>
  ) : undefined;

  const borderContainerStyle = disableBottomBorder ? styles.container : styles.borderContainer;
  const pickerModalStyles = [borderContainerStyle, containerStyles];
  if (isError) {
    pickerModalStyles.push(styles.isErrorContainer);
  }
  return (
    <View style={pickerModalStyles} testID={testID}>
      <PickerModal
        loginStyle={loginStyle}
        visible={visible}
        selectedValue={selectedValue}
        title={title}
        items={items}
        onValueSelect={onValueSelect}
        onExit={onExit}
      />
      {headingView}
      {loading ? renderLoading() : renderRow(props)}
    </View>
  );
};

const styles = StyleSheet.create({
  borderContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: MkiColors.whiteBackground,
    marginTop: SPACING.small,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  isErrorContainer: {
    borderBottomColor: MkiColors.errorTextColor,
  },
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: MkiColors.whiteBackground,
    marginTop: SPACING.small,
  },
  heading: {
    marginTop: SPACING.default,
    marginLeft: SPACING.default,
  },
  subheading: {
    marginTop: SPACING.tiny,
  },
  subheadingText: {
    color: MkiColors.secondaryTextColor,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
});

export default PickerModalRow;
