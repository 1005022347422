import { License } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";

import { useGXUMBLicense } from "~/go/hooks/useFilteredLicenses";
import { getGXSerial } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { WrappedScreenComponent } from "~/shared/types/Hocs";

export interface LicenseProps {
  license?: License;
  isLicenseLoading: boolean;
}

const withLicense = <P extends object>(
  WrappedComponent: WrappedScreenComponent<P> & { options?: unknown },
) => {
  const License = (props: P) => {
    const organizationId = useCurrentOrganizationId();
    const serialNumber = useAppSelector(getGXSerial) ?? "";
    const networkId = useCurrentNetworkId();
    const license = useGXUMBLicense(serialNumber, organizationId, networkId);

    return (
      <WrappedComponent {...props} license={license.data} isLicenseLoading={license.isLoading} />
    );
  };
  return License;
};

export default withLicense;
