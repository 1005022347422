import { Features, WhatsNewFeatures } from "~/shared/types/WhatsNewTypes";

export function whatsNewFilter(
  whatsNew: WhatsNewFeatures,
  isUmbrellaAccountReady: boolean,
  hasAlphaFeatures: boolean,
  gxSerial?: string,
) {
  if (!isUmbrellaAccountReady) {
    delete whatsNew[Features.gxSecurityPolicy];
  }

  if (gxSerial == null) {
    delete whatsNew[Features.gxRealtimeUsage];
    delete whatsNew[Features.dhcpOptions];
  } else {
    const gxRealtimeUsage = whatsNew[Features.gxRealtimeUsage];

    if (gxRealtimeUsage) {
      gxRealtimeUsage.screenProps = { serialNumber: gxSerial };
    }
  }

  if (!hasAlphaFeatures) {
    delete whatsNew[Features.networkHealth];
  }

  return whatsNew;
}
