import { StyleSheet, TextStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LETTER_SPACING } from "~/constants/MkiConstants";
import { SECTION_TAB_COLORS, TEXT_COLORS } from "~/enterprise/constants/Colors";
import { FONT_SIZES, FontFamily } from "~/enterprise/constants/Fonts";
import { LetterSpacing } from "~/enterprise/constants/Typography";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize } from "~/lib/themeHelper";

export enum FontWeight {
  BOLD = "bold",
  W_200 = "200",
  W_300 = "300",
  W_400 = "400",
  W_600 = "600",
}

const SMALL_STYLE: TextStyle = {
  fontFamily: "ciscosans",
  fontSize: appSelect({
    enterprise: 13,
    go: normalizedFontSize(13),
  }),
  fontStyle: "normal",
  fontWeight: FontWeight.W_400,
  color: MkiColors.textColor,
};

const DEFAULT_STYLE: TextStyle = {
  ...platformSelect({
    ios: { fontFamily: "ciscosans" },
    android: { fontFamily: "ciscosans" },
  }),
  fontSize: appSelect({
    enterprise: 16,
    go: normalizedFontSize(16),
  }),
  fontStyle: "normal",
  fontWeight: FontWeight.W_400,
  color: MkiColors.textColor,
};

export const TAB_STYLE: TextStyle = {
  ...platformSelect({
    ios: { fontFamily: "ciscosans" },
    android: { fontFamily: "ciscosans_light" },
  }),
  fontSize: appSelect({
    enterprise: 16,
    go: normalizedFontSize(16),
  }),
  fontStyle: "normal",
  fontWeight: FontWeight.W_300,
};

export const TEXT_STYLES = StyleSheet.create({
  default: {
    ...DEFAULT_STYLE,
  },
  secondary: {
    ...DEFAULT_STYLE,
    color: MkiColors.secondaryTextColor,
  },
  defaultBold: {
    ...DEFAULT_STYLE,
    fontWeight: FontWeight.W_600,
  },
  defaultSpacedLines: {
    ...DEFAULT_STYLE,
    lineHeight: 22,
  },
  title: {
    ...platformSelect({
      ios: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "proxima soft" },
      }),
      android: appSelect({
        enterprise: { fontFamily: "ciscosans_light" },
        go: { fontFamily: "proximasoft_medium" },
      }),
    }),
    fontSize: appSelect({
      enterprise: 28,
      go: normalizedFontSize(32),
    }),
    fontStyle: "normal",
    fontWeight: appSelect({
      enterprise: FontWeight.W_300,
      go: FontWeight.W_400,
    }),
    color: MkiColors.titleColor,
  },
  heading: {
    ...platformSelect({
      ios: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "proxima soft" },
      }),
      android: appSelect({
        enterprise: { fontFamily: "ciscosans_light" },
        go: { fontFamily: "proximasoft" },
      }),
    }),
    fontSize: appSelect({
      enterprise: 20,
      go: normalizedFontSize(24),
    }),
    fontStyle: "normal",
    fontWeight: appSelect({
      enterprise: FontWeight.W_300,
      go: FontWeight.W_400,
    }),
    color: MkiColors.headingColor,
    lineHeight: 30,
  },
  subheading: {
    ...platformSelect({
      ios: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "proxima soft" },
      }),
      android: appSelect({
        enterprise: { fontFamily: "ciscosans_light" },
        go: { fontFamily: "proximasoft_medium" },
      }),
    }),
    fontSize: appSelect({
      enterprise: 18,
      go: normalizedFontSize(19),
    }),
    fontStyle: "normal",
    fontWeight: appSelect({
      enterprise: FontWeight.W_300,
      go: FontWeight.W_400,
    }),
    color: MkiColors.subheadingColor,
  },
  accordianHeader: {
    fontFamily: "ciscosans",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "normal",
    color: MkiColors.subheadingColor,
  },
  error: {
    color: MkiColors.errorTextColor,
  },
  activeTab: {
    ...TAB_STYLE,
    color: SECTION_TAB_COLORS.selected,
  },
  inactiveTab: {
    ...TAB_STYLE,
    color: SECTION_TAB_COLORS.unselected,
  },
  activeUnderlineStyle: {
    borderBottomWidth: 3,
    borderBottomColor: SECTION_TAB_COLORS.selected,
  },
  inactiveUnderlineStyle: {
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: SECTION_TAB_COLORS.underline,
  },
  announcement: {
    ...DEFAULT_STYLE,
    fontSize: FONT_SIZES.announcement,
  },
  small: {
    ...SMALL_STYLE,
  },
  smallLight: {
    // does not support theming
    ...SMALL_STYLE,
    fontWeight: FontWeight.W_300,
  },
  smallBold: {
    ...SMALL_STYLE,
    fontWeight: FontWeight.W_600,
  },
  smallSecondary: {
    ...SMALL_STYLE,
    color: MkiColors.secondaryTextColor,
  },
  label: {
    fontFamily: "ciscosans",
    fontSize: appSelect({
      enterprise: 13,
      go: normalizedFontSize(11),
    }),
    fontStyle: "normal",
    fontWeight: FontWeight.W_400,
    letterSpacing: LETTER_SPACING.allCaps,
    color: appSelect({
      enterprise: TEXT_COLORS.secondary,
      go: MkiColors.secondaryTextColor,
    }),
  },
  bigNumber: {
    ...platformSelect({
      ios: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "proxima soft" },
      }),
      android: appSelect({
        enterprise: { fontFamily: "ciscosans_extralight" },
        go: { fontFamily: "proximasoft" },
      }),
    }),
    fontSize: appSelect({
      enterprise: 32,
      go: normalizedFontSize(32),
    }),
    fontStyle: "normal",
    fontWeight: appSelect({
      enterprise: FontWeight.W_300,
      go: FontWeight.W_600,
    }),
    color: MkiColors.displayNumberColor,
  },
  monospace: {
    ...platformSelect({
      ios: { fontFamily: "Courier New" },
      android: { fontFamily: "monospace" },
    }),
    fontSize: appSelect({
      enterprise: 13,
      go: normalizedFontSize(13),
    }),
    color: appSelect({
      enterprise: MkiColors.textColor,
      go: MkiColors.secondaryTextColor,
    }),
  },
  graphLabel: {
    ...platformSelect({
      ios: { fontFamily: "ciscosans" },
      android: { fontFamily: "ciscosans" },
    }),
    fontSize: appSelect({
      enterprise: 9,
      go: normalizedFontSize(9),
    }),
    fontStyle: "normal",
    fontWeight: FontWeight.W_400,
    color: MkiColors.textColor,
  },
  timePicker: {
    color: appSelect({
      go: MkiColors.primaryButton,
      enterprise: TEXT_COLORS.link,
    }),
    fontSize: normalizedFontSize(13),
    letterSpacing: LETTER_SPACING.allCaps,
    ...platformSelect({
      ios: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "ProximaSoft-Medium" },
      }),
      android: appSelect({
        enterprise: { fontFamily: "ciscosans" },
        go: { fontFamily: "proximasoft_medium" },
      }),
    }),
  },
  // Enterprise only
  // Mobile / Header
  header: {
    ...DEFAULT_STYLE,
    fontSize: FONT_SIZES.heading,
  },
  // Mobile / Header / Subheader
  subheader: {
    ...DEFAULT_STYLE,
    fontSize: 19,
    letterSpacing: LetterSpacing.subheaders,
  },
  tableHeader: {
    fontFamily: FontFamily.default,
    fontSize: 14,
    color: TEXT_COLORS.secondary,
  },
  subheaderSuperScript: {
    ...DEFAULT_STYLE,
    fontSize: 15,
    lineHeight: 40,
    color: TEXT_COLORS.red,
  },
  drawerSectionHeader: {
    color: TEXT_COLORS.secondary,
    fontFamily: FontFamily.default,
    fontSize: 13,
    fontStyle: "normal",
    letterSpacing: 1,
  },
  drawerRowTitle: {
    color: TEXT_COLORS.white,
    fontFamily: FontFamily.default,
    fontSize: 16,
    fontStyle: "normal",
  },
  drawerScreenTitle: {
    color: TEXT_COLORS.white,
    fontFamily: FontFamily.default,
    fontSize: 16,
    fontStyle: "normal",
    textAlign: "center",
  },
  instructionsHeader: {
    fontFamily: FontFamily.default,
    fontSize: FONT_SIZES.small,
    color: TEXT_COLORS.secondary,
    textTransform: "uppercase",
  },
  navigation: {
    ...DEFAULT_STYLE,
    fontSize: FONT_SIZES.heading,
    color: TEXT_COLORS.heading,
  },
  formTextInputTitle: {
    color: TEXT_COLORS.secondary,
    fontFamily: FontFamily.default,
    fontSize: FONT_SIZES.small,
    fontStyle: "normal",
    letterSpacing: 0.5,
  },
  stepTitleActive: {
    fontFamily: FontFamily.default,
    fontSize: 14,
    color: TEXT_COLORS.primary,
    fontWeight: FontWeight.W_600,
    textAlign: "center",
  },
  stepTitleInactive: {
    fontFamily: FontFamily.default,
    fontSize: 14,
    color: TEXT_COLORS.primary,
    textAlign: "center",
  },
  textInput: {
    color: TEXT_COLORS.textInput,
    fontFamily: FontFamily.default,
    fontSize: FONT_SIZES.default,
    fontStyle: "normal",
  },
  unit: {
    ...SMALL_STYLE,
    fontWeight: FontWeight.W_300,
    fontSize: 16,
  },
  pressable: {
    color: TEXT_COLORS.link,
    fontFamily: FontFamily.default,
    fontSize: normalizedFontSize(13),
    fontStyle: "normal",
  },
  pressableAdjacent: {
    color: MkiColors.textColor,
    fontFamily: FontFamily.default,
    fontSize: normalizedFontSize(13),
    fontStyle: "normal",
  },
});

export type TEXT_STYLE_NAME = keyof typeof TEXT_STYLES;
