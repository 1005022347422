import { I18n } from "@meraki/core/i18n";
import { RegistryResult, useSiteToSiteVPN } from "@meraki/react-live-broker";
import { customDocumentationUrl } from "@meraki/shared/links";
import { useState } from "react";
import { StyleSheet, View } from "react-native";

import mkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import MerakiIcon from "~/shared/components/icons";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import Touchable from "~/shared/components/Touchable";
import GeneralStatus from "~/shared/constants/Status";

const UNFRIENDLY_NAT = "unfriendly";
const TRY_AGAIN = "try_again";
const INSUF_REGS = "insuf_regs";
const WARNING_NAT_TYPE = [UNFRIENDLY_NAT, TRY_AGAIN, INSUF_REGS];
const DEFAULT_PORT = "9350";

type RegistryInfoProps = {
  applianceId: string;
};

const getRegistryStatusColor = (data: RegistryResult) => {
  const dataStatus = data.status;

  if (!data) {
    return GeneralStatus.dormant;
  } else if (data.connectionFailed || !dataStatus) {
    return GeneralStatus.bad;
  } else if (
    dataStatus &&
    (!dataStatus.all_reg_connected || WARNING_NAT_TYPE.includes(dataStatus.nat_type))
  ) {
    return GeneralStatus.alerting;
  }
  return GeneralStatus.good;
};

const getRegistryDetails = (data: RegistryResult) => {
  const dataStatus = data.status;
  if (!data || data.connectionFailed || !dataStatus) {
    return I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");
  }

  let port = DEFAULT_PORT;
  if (dataStatus.contact_registry1) {
    const port1 = dataStatus.contact_registry1.split(":")[1];
    port = port1;
    if (dataStatus.contact_registry2) {
      const port2 = dataStatus.contact_registry2.split(":")[1];
      if (port1 !== port2) {
        port = `${port1} & ${port2}`;
      }
    }
  }
  return port;
};

export const RegistryInfoCard = (props: RegistryInfoProps) => {
  const { applianceId } = props;
  const [displayMore, setDisplayMore] = useState<boolean>(false);
  const registryResponse = useSiteToSiteVPN(applianceId);

  const registryStatus = registryResponse?.status;
  const privateIp =
    (registryStatus && registryStatus.private_contact) ??
    I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");
  const publicIp =
    (registryStatus && registryStatus.public_contact) ??
    I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.NO_DATA");

  if (!registryResponse) {
    return (
      <View style={styles.loadingContainer} testID={"LOADING_SPINNER"}>
        <MkiSpinner />
      </View>
    );
  }

  const registryStatusColor = getRegistryStatusColor(registryResponse);

  const renderMoreLess = () => {
    const iconName = displayMore ? "chevron-down" : "chevron-up";
    const moreLessText = displayMore
      ? I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.LESS")
      : I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.MORE");

    const leftSide = displayMore ? (
      <RoundedButton
        onPress={() =>
          customDocumentationUrl(
            I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.LEARN_MORE_LINK"),
            I18n.t("DOCS.URL.ENTERPRISE"),
          )
        }
        textStyles={styles.learnMoreButton}
        containerStyles={styles.learnMoreButtonContainer}
        buttonType={ButtonType.secondary}
      >
        {I18n.t("LEARN_MORE")}
      </RoundedButton>
    ) : (
      <View></View>
    );
    return (
      <View style={styles.moreInfoRow}>
        {leftSide}
        <View style={styles.rightSide}>
          <MkiText
            textStyle="default"
            screenStyles={[styles.buttonHeight, { color: mkiColors.primaryButton }]}
            testID={`MORE_LESS_VALUE.${moreLessText}`}
          >
            {moreLessText}
          </MkiText>
          <MerakiIcon name={iconName} size={SPACING.medium} color={mkiColors.primaryButton} />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.siteInfoContainer}>
      <View style={styles.peerInfo}>
        <View style={styles.leftSide}>
          <StatusIcon
            status={registryStatusColor}
            testID={`REGISTRY_STATUS_ICON_${registryStatusColor}`}
          />
          <MkiText screenStyles={styles.siteName}>
            {I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.REGISTRY_PORT")}
          </MkiText>
        </View>
        <View style={styles.rightSide}>
          <MkiText>{registryResponse && getRegistryDetails(registryResponse)}</MkiText>
        </View>
      </View>
      <View style={styles.ipRow}>
        <MkiText textStyle="secondary">
          {I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.PRIVATE_IP")}
        </MkiText>
        <MkiText textStyle="default">{privateIp}</MkiText>
      </View>
      <View style={styles.ipRow}>
        <MkiText textStyle="secondary">{I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.PUBLIC_IP")}</MkiText>
        <MkiText textStyle="default">{publicIp}</MkiText>
      </View>
      {displayMore ? (
        <MkiText textStyle="default">
          {I18n.t("VPN_HEALTH_OVERVIEW.SITE_INFO.MORE_INFO_TEXT")}
        </MkiText>
      ) : null}
      <Touchable onPress={() => setDisplayMore(!displayMore)} transparentBackground={true}>
        {renderMoreLess()}
      </Touchable>
    </View>
  );
};

const styles = StyleSheet.create({
  siteInfoContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: SPACING.default,
    margin: SPACING.default,
    borderWidth: 1,
    borderRadius: SPACING.default,
    borderColor: mkiColors.borderColor,
  },
  peerInfo: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: SPACING.default,
  },
  siteName: {
    paddingLeft: SPACING.small,
  },
  leftSide: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightSide: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  moreInfoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ipRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: SPACING.default,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: SPACING.extraExtraLarge,
  },
  // Keeps More/Less text in the same spot
  learnMoreButtonContainer: {
    paddingVertical: 0,
  },
  learnMoreButton: {
    paddingHorizontal: SPACING.medium,
  },
  buttonHeight: {
    paddingRight: SPACING.medium,
    paddingVertical: SPACING.small + SPACING.tiny,
  },
});

export default RegistryInfoCard;
