import { useMemo } from "react";
import { VictoryBar, VictoryStack } from "victory-native";

import MkiColors from "~/constants/MkiColors";
import MkiChart, { MkiChartProps } from "~/shared/components/MkiChart";

export const MkiStackedBarChart = (props: MkiChartProps) => {
  const { data, dataKeys } = props;

  const dataBar = useMemo(
    () =>
      dataKeys?.map((barKey, idx) => {
        const barStyle = {
          data: {
            fill: barKey.color || MkiColors.primaryGraphLine,
          },
        };
        return (
          <VictoryBar
            key={`VictoryBar${idx}`}
            data={data}
            style={barStyle}
            alignment="start"
            {...barKey}
          />
        );
      }),
    [data, dataKeys],
  );

  return (
    <MkiChart {...props}>
      <VictoryStack>{dataBar}</VictoryStack>
    </MkiChart>
  );
};

export default MkiStackedBarChart;
