import { I18n } from "@meraki/core/i18n";
import { withMagneticReplacementAdapter } from "@meraki/magnetic/adapter";
import { useSSO } from "@meraki/shared/api";
import { Clusters } from "@meraki/shared/redux";
import CookieManager from "@react-native-cookies/cookies";
import { useNavigation } from "@react-navigation/native";
import { addDays, formatISO } from "date-fns";
import * as WebBrowser from "expo-web-browser";
import { isEmpty } from "lodash";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import mkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { RoundedButtonType } from "~/enterprise/components/RoundedButton";
import FormTextInput from "~/enterprise/components/textInputs/FormTextInput";
import { cookieDomain, shardURL, ssoAuthCookie } from "~/env";
import { isWeb } from "~/lib/PlatformUtils";
import { normalizeAuthData } from "~/lib/SpSamlUtils";
import { SpSamlLoginScreen as MagneticSpSamlScreen } from "~/migrationZone/enterprise/auth/screens/SpSamlLoginScreen/SpSamlLoginScreen";
import { getCurrentCluster, getSsoSubdomain } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import WebLink from "~/shared/components/WebLink";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useAnalytics from "~/shared/hooks/useAnalytics";

const SSO_SUCCESS_URL = `meraki://sso/success`;
const DOCUMENTATION_URL = "https://cs.co/meraki-sp-saml-guide";

// This is non authoratative. The backend will enforce cookie expiry.
const AUTH_COOKIE_DAYS_TO_LIVE = 31;

const TOP_SPACER = "28%";
const LOGO_BOTTOM_SPACER = 50;
const LOGO_SIZE = 36;
const ERROR_SPACER = -10;

type ErrorMessageProps = {
  errorMessage?: string;
};

const setNewAuthCookie = async (
  auth: string,
  shardId: string,
  cluster: Clusters,
): Promise<void> => {
  const formattedExpiry = formatISO(addDays(Date.now(), AUTH_COOKIE_DAYS_TO_LIVE));
  const newCookie = {
    name: ssoAuthCookie(cluster),
    value: auth,
    domain: cookieDomain(cluster),
    path: "/",
    expires: formattedExpiry,
  };
  await CookieManager.set(shardURL(shardId, cluster), newCookie, false);
};

const ErrorMessage = (props: ErrorMessageProps): JSX.Element | null => {
  const { errorMessage } = props;
  if (!errorMessage) {
    return null;
  }
  return (
    <MkiText textStyle="small" screenStyles={styles.errorMessage}>
      {errorMessage}
    </MkiText>
  );
};

const SpSamlLogin = (): JSX.Element => {
  const cluster = useAppSelector(getCurrentCluster);
  const lastValidSsoSubdomain = useAppSelector(getSsoSubdomain);
  const actions = useActions();
  const logEvent = useAnalytics();
  const [subdomain, setSubdomain] = useState(lastValidSsoSubdomain || "");
  const [isProcessing, setIsProcessing] = useState(false);
  const [webBrowserResult, setWebBrowserResult] = useState<
    null | WebBrowser.WebBrowserAuthSessionResult | WebBrowser.WebBrowserResult
  >(null);

  const { data, refetch: fetchSSO, isFetching, error } = useSSO({ subdomain });
  const authNRequest = data?.sp_saml_idp_auth_request?.saml_request_url;

  const _handleAuthBrowserSessionPrompt = async (url: any) => {
    if (isWeb()) {
      // directly open IdP
      window.open(url, "_self");
    } else {
      const webBrowserResult: SetStateAction<
        null | WebBrowser.WebBrowserResult | WebBrowser.WebBrowserAuthSessionResult
      > = await WebBrowser.openAuthSessionAsync(url, SSO_SUCCESS_URL, {
        showInRecents: true,
        createTask: true,
      });
      setWebBrowserResult(webBrowserResult);
    }
  };

  useEffect(() => {
    if (!isEmpty(authNRequest)) {
      _handleAuthBrowserSessionPrompt(authNRequest);
    }
  }, [authNRequest]);

  useEffect(() => {
    if (webBrowserResult && webBrowserResult.type === "success") {
      const authData = normalizeAuthData(webBrowserResult.url);
      if (!!authData) {
        setIsProcessing(true);
        const { auth, shardId, orgId } = authData;
        if (!auth || !shardId || !orgId) {
          throw new Error(I18n.t("SPSAML.REDIRECT_ERROR"));
        }
        setNewAuthCookie(auth, shardId, cluster)
          .then(async () => {
            await actions.setInitialSamlLoginEntities(orgId);
            actions.setValidSsoSubdomain(subdomain);
            logEvent("saml_sso_succeess");
            setIsProcessing(false);
          })
          .catch((err) => {
            console.log(err);
            logEvent("saml_sso_failure");
            setIsProcessing(false);
          });
      }
    }
  }, [webBrowserResult, actions, logEvent, cluster, subdomain]);

  return (
    <>
      <FullScreenContainerView
        withScroll
        contentContainerStyle={styles.container}
        contentInsetAdjustmentBehavior="never"
        scrollEnabled={false}
      >
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={require("~/images/logos/ciscoMeraki/cisco-meraki.png")}
        />
        <View>
          <FormTextInput
            title={I18n.t("SPSAML.SUBDOMAIN_ENTRY_TITLE")}
            value={subdomain}
            multiline={false}
            placeholder={I18n.t("SPSAML.SSO_SUBDOMAIN_PLACEHOLDER")}
            returnKeyType={RETURN_KEY.next}
            onChangeText={(subdomain) => {
              setSubdomain(subdomain);
            }}
            scrollEnabled={false}
            // @ts-ignore fetchSSO does not have the right type for onSubmitEditing, which comes from RN TextInput
            onSubmitEditing={fetchSSO}
            autoCorrect={false}
            autoCapitalize="none"
            underlineColorAndroid="transparent"
            keyboardType={KEYBOARD_TYPE.default}
            containerStyle={styles.ssoInputField}
          />
        </View>
        {!!error?.error && <ErrorMessage errorMessage={error.error} />}
        <RoundedButton
          buttonType={RoundedButtonType.primary}
          disabled={isEmpty(subdomain)}
          onPress={fetchSSO}
        >
          {I18n.t("SPSAML.LOGIN_CONTINUE")}
        </RoundedButton>
        <WebLink linkUrl={DOCUMENTATION_URL}>{I18n.t("SPSAML.SETUP_LINK")}</WebLink>
      </FullScreenContainerView>
      <LoadingSpinner visible={isProcessing || isFetching} screenStyles={styles.spinner} />
    </>
  );
};

export const LoginWithSpSamlButton = (): JSX.Element => {
  const navigation = useNavigation();

  const handleLoginWithSSOPress = useCallback(() => {
    navigation.navigate("SpSamlLogin");
  }, [navigation]);

  return (
    <View style={styles.ssoButtonContainer}>
      <Text style={{ color: mkiColors.secondaryTextColor }}>or</Text>
      <RoundedButton buttonType={RoundedButtonType.secondary} onPress={handleLoginWithSSOPress}>
        {I18n.t("SPSAML.LOGIN_SSO")}
      </RoundedButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    top: TOP_SPACER,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingHorizontal: SPACING.large,
  },
  ssoButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    height: LOGO_SIZE,
    alignSelf: "center",
    marginBottom: LOGO_BOTTOM_SPACER,
  },
  spinner: {
    position: "absolute",
  },
  ssoInputField: {
    minWidth: "100%",
    marginBottom: 0,
  },
  errorMessage: {
    marginTop: ERROR_SPACER,
    marginLeft: SPACING.small,
    color: mkiColors.badStatus,
  },
});

const MagnetizedSpSamlLoginScreen = withMagneticReplacementAdapter(
  SpSamlLogin,
  MagneticSpSamlScreen,
);

export default MagnetizedSpSamlLoginScreen;
