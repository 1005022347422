import { I18n } from "@meraki/core/i18n";
import { SelectClientBottomSheet } from "@meraki/go/client";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { BottomSheetMethods, Button, Loader } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { APIResponseError, queryClient, useUpdateVlan, useVlans } from "@meraki/shared/api";
import { MAC_REGEX } from "@meraki/shared/filters";
import { Form, useForm } from "@meraki/shared/form";
import {
  getImmutableAddressPart,
  isIpInSubnetRange,
  isIpReservationConflicting,
} from "@meraki/shared/ip-address";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import { omit } from "lodash";
import { useCallback, useEffect, useRef } from "react";

interface ReserveDeviceForm {
  name: string;
  mac: string;
  ip: string;
}

const onError = (error: APIResponseError) => showErrorAlert(String(error?.errors));

export function ReserveDeviceScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "ReserveDevice">>();
  const { params: props } = route;
  const { vlan, mac } = props;

  const networkId = useCurrentNetworkId();
  const updateVlan = useUpdateVlan();

  const methods = useForm<ReserveDeviceForm>({
    values:
      mac && vlan.fixedIpAssignments[mac]
        ? {
            mac,
            name: vlan.fixedIpAssignments[mac]?.name ?? "",
            ip: vlan.fixedIpAssignments[mac]?.ip ?? "",
          }
        : {
            name: "",
            mac: "",
            ip: getImmutableAddressPart(vlan.subnet),
          },
  });

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: useVlans.queryKey({ networkId }) });
    navigation.goBack();
  }, [navigation, networkId]);

  const onSubmit = useCallback(
    ({ name, mac, ip }: ReserveDeviceForm) => {
      updateVlan.mutate(
        {
          networkId,
          vlanId: vlan.id,
          vlan: {
            fixedIpAssignments: {
              ...vlan.fixedIpAssignments,
              [mac]: {
                name,
                ip,
              },
            },
          },
        },
        {
          onSuccess,
          onError,
        },
      );
    },
    [networkId, onSuccess, updateVlan, vlan.fixedIpAssignments, vlan.id],
  );

  const deleteDeviceReservation = () => {
    if (mac) {
      updateVlan.mutate(
        {
          networkId,
          vlanId: vlan.id,
          vlan: {
            fixedIpAssignments: omit(vlan.fixedIpAssignments, mac),
          },
        },
        {
          onSuccess,
          onError,
        },
      );
    }
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSubmit)}
          disabled={!methods.formState.isDirty}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSubmit]);

  const selectClientBottomSheetRef = useRef<BottomSheetMethods>(null);
  return (
    <Screen.View addDefaultPadding>
      {updateVlan.isLoading && <Loader.Spinner animate />}
      <Box flex={1}>
        <Box alignItems="flex-end">
          <Button
            kind="tertiary"
            text={I18n.t("DEVICE_RESERVATIONS.GOv3.SELECT_DEVICE")}
            trailingIcon="Plus"
            onPress={() => selectClientBottomSheetRef?.current?.present()}
            testID="RESERVE_DEVICE.ADD_DEVICE"
          />
        </Box>
        <Form {...methods}>
          <Form.Input
            name="name"
            rules={{ required: I18n.t("DEVICE_RESERVATIONS.GOv3.NAME.REQUIRED") }}
            label={I18n.t("DEVICE_RESERVATIONS.DEVICE_NAME")}
            placeholder={I18n.t("DEVICE_RESERVATIONS.NAME_PLACEHOLDER")}
            testID="RESERVE_DEVICE.NAME"
          />
          <Form.Input
            name="mac"
            rules={{
              required: I18n.t("DEVICE_RESERVATIONS.GOv3.MAC.REQUIRED"),
              validate: (newValue: string) => {
                if (!MAC_REGEX.test(newValue)) {
                  return I18n.t("DEVICE_RESERVATIONS.GOv3.MAC.INVALID");
                }

                return undefined;
              },
            }}
            label={I18n.t("DEVICE_RESERVATIONS.DEVICE_MAC_ADDRESS")}
            placeholder={I18n.t("DEVICE_RESERVATIONS.MAC_PLACEHOLDER")}
            testID="RESERVE_DEVICE.MAC"
          />
          <Form.Input
            name="ip"
            label={I18n.t("DEVICE_RESERVATIONS.GOv3.IP.LABEL")}
            placeholder="1"
            rules={{
              required: I18n.t("DEVICE_RESERVATIONS.GOv3.IP.REQUIRED"),
              validate: (newValue: string) => {
                if (!Address4.isValid(newValue)) {
                  return I18n.t("DEVICE_RESERVATIONS.GOv3.IP.INVALID");
                }

                if (!isIpInSubnetRange(newValue, vlan.subnet)) {
                  return I18n.t("DEVICE_RESERVATIONS.GOv3.IP.NOT_IN_RANGE");
                }

                return (
                  isIpReservationConflicting(newValue, vlan.subnet, vlan.applianceIp) ?? undefined
                );
              },
            }}
            additionalContext={I18n.t("DEVICE_RESERVATIONS.GOv3.IP.HELPER_TEXT", {
              subnet: vlan.subnet,
            })}
            keyboardType="numeric"
            testID="RESERVE_DEVICE.IP"
          />
        </Form>
        <SelectClientBottomSheet
          snapPoints={["50%", "70%"]}
          onSelect={({ name, mac }) => {
            methods.setValue("name", name, { shouldDirty: true, shouldValidate: true });
            methods.setValue("mac", mac, { shouldDirty: true, shouldValidate: true });
          }}
          ref={selectClientBottomSheetRef}
        />
      </Box>
      {mac && (
        <Box paddingBottom="xl">
          <Button
            kind="primaryDestructive"
            text={I18n.t("DELETE")}
            onPress={deleteDeviceReservation}
            testID="RESERVE_DEVICE.DELETE"
          />
        </Box>
      )}
    </Screen.View>
  );
}
