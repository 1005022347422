import { ErrorMessage } from "@hookform/error-message";
import { Checkbox, CheckboxProps, Notification } from "@meraki/magnetic/components";
import { Controller } from "react-hook-form";

import { FormItemProps } from "./FormItemProps";
import { useFormContext } from "./useFormContext";

type FormCheckboxProps = Omit<CheckboxProps, "checked"> & FormItemProps;

export const FormCheckbox = ({
  name,
  rules,
  onValueChange,
  onBlur,
  ...rest
}: FormCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value, onBlur: controllerOnBlur } }) => (
          <Checkbox
            {...rest}
            onValueChange={(value) => {
              onChange(value);
              onValueChange?.(value);
            }}
            checked={value}
            onBlur={(event) => {
              controllerOnBlur();
              onBlur?.(event);
            }}
          />
        )}
      />
      {!control._options.context?.disableErrorNotification && (
        <ErrorMessage
          name={name}
          render={({ message }) => <Notification.Inline status="negative" message={message} />}
        />
      )}
    </>
  );
};
