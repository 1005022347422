import { useCurrentOrganizationId } from "@meraki/shared/redux";
import {
  QueryClient,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import switchKeys from "~/api/queries/switches/keys";
import {
  BatchUpdateSwitchPortsSchema,
  StagedSwitchPort,
  SwitchPort,
  SwitchPortSchema,
  SwitchPortsSchema,
} from "~/api/schemas/SwitchPort";
import { validatedMerakiRequest } from "~/api/util/request";
import { shallowDiffChanges } from "~/lib/objectHelper";
import {
  createEditableSwitchPort,
  createImmutableSwitchPort,
} from "~/shared/hooks/switch/useEditSwitchPort";

const switchPortKeys = {
  switchPort: (serialNumber: string, portId: string) =>
    [...switchKeys.switchPort, serialNumber, portId] as const,
  switchPorts: (serialNumber: string) => [...switchKeys.switchPort, serialNumber] as const,
};

const fetchSwitchPort = (serialNumber: string, portId: string): Promise<SwitchPort> => {
  return validatedMerakiRequest(
    SwitchPortSchema,
    "GET",
    `/api/v1/devices/${serialNumber}/switch/ports/${portId}`,
  );
};

const fetchSwitchPorts = (serialNumber: string): Promise<SwitchPort[]> => {
  return validatedMerakiRequest(
    SwitchPortsSchema,
    "GET",
    `/api/v1/devices/${serialNumber}/switch/ports/`,
  );
};

const updateSwitchPortsFromQueryData = (
  serialNumber: string,
  switchPorts: SwitchPort[],
  queryClient: QueryClient,
) => {
  switchPorts.forEach((port) => {
    queryClient.setQueryData(switchPortKeys.switchPort(serialNumber, port.portId), port);
  });
};

export const useAllSwitchPorts = (serialNumber: string) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: switchPortKeys.switchPorts(serialNumber),
    queryFn: () => fetchSwitchPorts(serialNumber),
    // MS390/C9300 has a swappable module with different ports, can identify by seeing if the portId is a string vs number
    select: (ports: SwitchPort[]) =>
      ports.filter(({ portId }) => Number.parseInt(portId).toString() === portId),
    onSuccess: (ports: SwitchPort[]) => {
      updateSwitchPortsFromQueryData(serialNumber, ports, queryClient);
    },
  });
};

export const useSwitchPorts = (serialNumber: string, portIds: string[]) => {
  return useQueries({
    queries: portIds.map((id) => ({
      queryKey: switchPortKeys.switchPort(serialNumber, id),
      queryFn: () => fetchSwitchPort(serialNumber, id),
    })),
  });
};

export const useSwitchPort = (serialNumber: string, portId: string) => {
  return useQuery({
    queryKey: switchPortKeys.switchPort(serialNumber, portId),
    queryFn: () => fetchSwitchPort(serialNumber, portId),
  });
};

const getBatchSwitchPortUpdateDiff = (
  switchPorts: SwitchPort[],
  modifiedSwitchPort: StagedSwitchPort,
): SwitchPort[] => {
  const switchPort = switchPorts[0];
  const diff = shallowDiffChanges(createEditableSwitchPort(switchPort), modifiedSwitchPort);

  return switchPorts.map((port) => ({
    ...port,
    ...diff,
  }));
};

const updateSwitchPorts = async (
  organizationId: string,
  serialNumber: string,
  switchPorts: SwitchPort[],
) => {
  if (__MERAKI_GO__) {
    // action batches are org admin scoped, does not currently work for enterprise network admins
    // go doesn't have network admins
    return await batchUpdateSwitchPorts(organizationId, serialNumber, switchPorts);
  }

  return Promise.all(
    switchPorts.map((port) =>
      validatedMerakiRequest(
        SwitchPortSchema,
        "PUT",
        `/api/v1/devices/${serialNumber}/switch/ports/${port.portId}`,
        {
          body: JSON.stringify(createEditableSwitchPort(port)),
        },
      ),
    ),
  );
};

const batchUpdateSwitchPorts = async (
  organizationId: string,
  serialNumber: string,
  switchPorts: SwitchPort[],
) => {
  // action batches are org admin scoped, does not currently work for enterprise network admins
  // go doesn't have network admins

  const actions = switchPorts.map((port) => ({
    resource: `/devices/${serialNumber}/switch/ports/${port.portId}`,
    operation: "update",
    body: createEditableSwitchPort(port),
  }));

  const response = await validatedMerakiRequest(
    BatchUpdateSwitchPortsSchema,
    "POST",
    `/api/v1/organizations/${organizationId}/actionBatches`,
    {
      body: JSON.stringify({
        confirmed: true,
        synchronous: true,
        actions,
      }),
    },
  );

  if (!response.status.completed) {
    throw Error(`Unable to update switch ports, ${response.status.errors}`);
  }
  return response.actions.map((action) => action.body);
};

export const useUpdateSwitchPorts = (serialNumber: string, portIds: string[]) => {
  const organizationId = useCurrentOrganizationId();
  const queryClient = useQueryClient();

  const previousSwitchPorts = portIds.map(
    (id) =>
      queryClient.getQueryData<SwitchPort>(switchPortKeys.switchPort(serialNumber, id)) ??
      <SwitchPort>{
        portId: id,
      },
  );

  return useMutation({
    mutationFn: async (switchPort: StagedSwitchPort) => {
      if (!organizationId) {
        throw Error("Cannot batch update switch ports. No current org set in Redux.");
      }

      const diffs = getBatchSwitchPortUpdateDiff(previousSwitchPorts, switchPort);
      return await updateSwitchPorts(organizationId, serialNumber, diffs);
    },
    onMutate: (switchPort: StagedSwitchPort) => {
      const augmentedSwitchPorts = previousSwitchPorts.map(
        (previousSwitchPort) =>
          <SwitchPort>{
            ...switchPort,
            ...createImmutableSwitchPort(previousSwitchPort),
          },
      );

      updateSwitchPortsFromQueryData(serialNumber, augmentedSwitchPorts, queryClient);

      return { previousSwitchPorts };
    },
    onError: (_err, _variables, context) => {
      const previousSwitchPorts = context?.previousSwitchPorts;
      if (previousSwitchPorts) {
        updateSwitchPortsFromQueryData(serialNumber, previousSwitchPorts, queryClient);
      }
    },
  });
};

export default useSwitchPorts;
