import { I18n } from "@meraki/core/i18n";
import { formatBits, formatPerSecond } from "@meraki/shared/formatters";
import { map } from "lodash";

import { BASE_POLICIES } from "~/constants/MkiConstants";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import {
  CustomPolicy,
  GroupPoliciesByNumber,
  GroupPolicy,
  PolicyFormData,
  PolicyOnClient,
} from "~/shared/types/ClientPolicy";
import { SSID } from "~/shared/types/Models";
import { NestedModalData } from "~/shared/types/NestedModalData";
import { NetworkTypesWithId } from "~/shared/types/Networks";

const TOGGLE_FIELDS = {
  bandwidth_limits_enabled: ["download_bandwidth_limit", "upload_bandwidth_limit"] as const,
  bonjour_forwarding_enabled: ["bonjour_forwarding_settings_json"] as const,
  enable_scheduling: ["schedule_json"] as const,
  firewall_enabled: ["firewall_layer7_rules", "firewall_rules"] as const,
  force_proxy_enabled: ["force_proxy_expr", "force_proxy_ip", "force_proxy_port"] as const,
  foreign_ap_alert_enabled: ["foreign_ap_alert_specified"] as const,
  splash_captive_portal_enabled: ["splash_url"] as const,
  splash_enabled: ["splash_url"] as const,
  vlan_tagging_enabled: ["vlan_tag"] as const,
};

const APPLIANCE_TEXT = I18n.t("PRODUCTS.appliance.full.one");

export function getActiveRules(policy?: GroupPolicy) {
  const rules: any = {};
  (Object.keys(TOGGLE_FIELDS) as (keyof typeof TOGGLE_FIELDS)[]).forEach((f) => {
    if (policy?.[f]) {
      TOGGLE_FIELDS[f].forEach((info) => {
        rules[info] = policy[info];
      });
    }
  });

  return map(rules, (val, key) => {
    switch (key) {
      case "download_bandwidth_limit":
        const downloadSpeed = formatPerSecond(formatBits(val));
        return `${I18n.t("GROUP_POLICY.DOWNLOAD_LIMIT")}: ${downloadSpeed}`;
      case "upload_bandwidth_limit":
        const uploadSpeed = formatPerSecond(formatBits(val));
        return `${I18n.t("GROUP_POLICY.UPLOAD_LIMIT")}: ${uploadSpeed}`;
      case "schedule_json":
        return `Schedule:\n${formatSchedule(val)}`;
      case "firewall_layer7_rules":
        return `Layer 7 firewall:\n${formatLayer7(val)}`;
      case "firewall_rules":
        return `Layer 3 firewall:\n${formatLayer3(val)}`;
      case "splash_url":
        return `Splash: ${val}`;
      case "vlan_tag":
        return `VLAN: ${val}`;
      default:
        return `${key}: ${val}`;
    }
  });
}

function formatLayer7(layer7Text: any) {
  if (!layer7Text) {
    return null;
  }
  // TODO convert rule # to words
  return layer7Text
    .split("\n")
    .map((l: any) => `- ${l}`)
    .join("\n");
}

function formatLayer3(layer3Text: any) {
  if (!layer3Text) {
    return null;
  }
  return layer3Text
    .split("\n")
    .map((text: any) => {
      const [rule, comment] = text.split(" // ");
      const sections = rule.split(" ");

      const allowOrDeny = sections[0].replace(/\b\w/, (l: any) => l.toUpperCase());

      let protocol;
      let destination;
      let port;
      switch (sections.length) {
        case 0: {
          return null;
        }
        case 2: {
          /* Example sections
           * |0   |1
           *  deny all // mwhahahaha↵
           */
          protocol = "Any";
          destination = "Any";
          port = "Any";
          break;
        }
        case 3: {
          /* Example sections
           * |0   |1  |2
           *  deny dst 198.185.159.145/32 // SSK Design↵
           */
          protocol = "Any";
          [, , destination] = sections;
          port = "Any";
          break;
        }
        case 5: {
          /* Example sections for rule with 1 protocol on all ports
           * |0   |1  |2 |3  |4
           *  deny tcp && dst 143.95.39.220/32 // schusie.net↵
           */
          protocol = sections[1].replace(/\w/g, (l: any) => l.toUpperCase());
          [, , , , destination] = sections;
          port = "Any";
          break;
        }
        case 9: {
          /* Example sections for rule with 1 protocol and 1 port
           * |0   |1  |2 |3  |4               |5 |6  |7   |8
           *  deny udp && dst 143.95.39.220/32 && dst port 80 // "
           */
          protocol = sections[1].replace(/\w/g, (l: any) => l.toUpperCase());
          [, , , , destination, , , , port] = sections;
          break;
        }
        default: {
          return "Unable to show Layer 3 rule details";
        }
      }

      return `- ${allowOrDeny} ${protocol} destination ${destination} \
port ${port} ${comment.length > 0 ? `(${comment})` : ""}`;
    })
    .join("\n");
}

function formatSchedule(scheduleText: any) {
  const schedule = JSON.parse(scheduleText);

  return schedule
    .map((day: any) => {
      if (day.state === "disabled") {
        return `- ${day.name} disabled`;
      }
      if (day.start === "0" && day.end === "48") {
        return `- ${day.name} enabled`;
      }
      const formatTime = (numHalves: any) =>
        `${Math.floor(numHalves / 2)}:${numHalves % 2 === 0 ? "00" : "30"}`;

      const start = formatTime(parseInt(day.start, 10));
      const end = formatTime(parseInt(day.end, 10));

      return `- ${day.name} enabled ${start} -> ${end}`;
    })
    .join("\n");
}

// if there's one policy and it has a type, it's custom
// if there's one policy and it's -1, 0, or 1, it's the basic policy
// if there's one policy and it's neither of those ^, it's group
// if there is more than one policy, it's custom
// otherwise it's normal
export function getPolicyStatus(clientPolicies: any) {
  if (!Array.isArray(clientPolicies)) {
    return "-1";
  }

  if (clientPolicies.length === 1) {
    const firstPolicy = clientPolicies[0];
    if (firstPolicy?.type) {
      return "custom";
    }

    if (BASE_POLICIES.includes(firstPolicy?.policy)) {
      return firstPolicy?.policy;
    }

    return "group";
  }
  if (clientPolicies.length > 1) {
    return "custom";
  }

  return "-1";
}

// Given a client's policy, set the corresponding entry as "selected"
// Example:
// entries: [
//   { label: "Normal", value: "-1" },
//   { label: "Whitelisted", value: "0" },
//   { label: "Blacklisted", value: "1" },
// ]
// clientPolicy: { type: "wired", policy: "-1" }
//
// return value:
// entries: [
//   { label: "Normal", value: "-1", selected: true },
//   { label: "Whitelisted", value: "0" },
//   { label: "Blacklisted", value: "1" },
// ]
export function setSelected(entries: any, clientPolicy: any) {
  // still need to return copy of the array because "entries" is the same object
  // passed int multiple times in different locations. slice() works since entries
  // is always an array of shallow objects
  if (!entries) {
    return [];
  }
  if (!clientPolicy) {
    return entries.slice();
  }
  const indexToSelect = entries.findIndex((p: any) => p.value === clientPolicy.policy);

  if (indexToSelect >= 0) {
    const newEntry = { ...entries[indexToSelect], selected: true };
    return entries.slice(0, indexToSelect).concat([newEntry], entries.slice(indexToSelect + 1));
  }

  return entries.slice();
}

export function assembleCustomPolicies(
  formattedPolicies: any,
  currentPolicy: any,
  clientPolicies: any,
  ssids: any,
  networkTypes: any,
) {
  // populate the enabled SSID's with the policies

  let ssidEntries = ssids
    ?.filter((s: any) => s.enabled)
    .map((s: any) => ({
      label: s.name,
      value: s.name,
      ssidNum: s.number,

      next: {
        title: I18n.t("CLIENT_POLICIES.SSID.FOR", { ssid_name: s.name }),
        singleChoice: true,
        entries: setSelected(
          formattedPolicies,
          currentPolicy === "custom"
            ? clientPolicies.find((p: any) => p.ssidName === s.name)
            : null,
        ),
      },
    }));
  // show wired but only include the basic policies
  if (networkTypes?.hasWired) {
    ssidEntries?.unshift({
      label: APPLIANCE_TEXT,
      value: "wired",
      next: {
        title: I18n.t("CLIENT_POLICIES.WIRED.TITLE"),
        singleChoice: true,
        entries: setSelected(
          formattedPolicies?.filter((p: any) => BASE_POLICIES.includes(p.value)),
          currentPolicy === "custom" ? clientPolicies?.find((p: any) => p.type === "wired") : null,
        ),
      },
    });
  }
  // go through and set the ssids (or wired) that have policies assigned as "selected"
  // so the detail will be rendered (i.e. "2 policies assigned")
  ssidEntries = ssidEntries?.map((s: any) => ({
    ...s,
    selected: s.next.entries.some((e: any) => e.selected),
    renderDetail: (entries: any) => (entries.find((e: any) => e.selected) || {}).label,
  }));

  return {
    label: I18n.t("CLIENT_POLICIES.SSID.PER"),
    value: "custom",
    renderDetail: (entries: any) => {
      const numEntries = entries?.filter((e: any) => e.selected).length;
      return I18n.t("CLIENT_POLICIES.SSID.ASSIGNED", { entries_count: numEntries });
    },
    next: { title: I18n.t("CLIENT_POLICIES.SSID.CHOOSE"), entries: ssidEntries },
  };
}

export function assembleGroupPolicy(
  formattedPolicies: any,
  currentPolicy: any,
  clientPolicies: any,
) {
  return {
    label: I18n.t("CLIENT_POLICIES.GROUP.TITLE"),
    value: "group",
    renderDetail: (entries: any) => (entries.find((e: any) => e.selected) || {}).label,
    next: {
      title: I18n.t("CLIENT_POLICIES.GROUP.CHOOSE"),
      singleChoice: true,
      entries: setSelected(
        formattedPolicies?.filter((p: any) => !BASE_POLICIES.includes(p.value)),
        currentPolicy === "group" ? clientPolicies[0] : null,
      ),
    },
  };
}

export const getUsageLimitPolicyName = (limitSpeed: any) => `Usage limit - ${limitSpeed}`;

export const getPolicyFormData = (data: NestedModalData[]) => {
  const getSelectedPolicy = (entry: NestedModalData) =>
    entry.next.entries.find((e) => e.selected)?.value;
  const formData: PolicyFormData = {};

  if (BASE_POLICIES.includes(data[0].value)) {
    formData.access = data[0].label.toLowerCase();
  } else if (data[0].value === "group") {
    formData.access = "group";
    formData.group_policy = getSelectedPolicy(data[0]);
  } else {
    formData.access = "custom";
    const customPolicy: CustomPolicy = {};
    data[0].next.entries.forEach((e) => {
      if (e.ssidNum !== undefined) {
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        customPolicy[e.ssidNum] = getSelectedPolicy(e);
      } else if (e.value === "wired") {
        customPolicy.wired = getSelectedPolicy(e);
      }
    });
    formData.custom_policy = customPolicy;
  }
  return formData;
};

export const getAllPolicies = (
  groupPolicies: GroupPoliciesByNumber,
  clientPolicies: PolicyOnClient[],
  networkTypes: NetworkTypesWithId,
  ssids: SSID[],
) => {
  // array of all policies, with their name (capitalized if base) and number
  // sorted by policy number
  const formattedPolicies = map(groupPolicies, (policy, policyNum) => ({
    // TODO: This causes a crash on read-only account
    label: BASE_POLICIES.includes(policyNum) ? capitalizeFirstLetter(policy.name) : policy.name,
    value: policyNum,
  })).sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  const currentPolicy = getPolicyStatus(clientPolicies);

  // show the basic policies
  const entries = formattedPolicies.filter((p) => BASE_POLICIES.includes(p.value));

  // show group policies
  if (formattedPolicies.some((p) => !BASE_POLICIES.includes(p.value))) {
    entries.push(assembleGroupPolicy(formattedPolicies, currentPolicy, clientPolicies));
  }

  // show per SSID policies
  if (networkTypes.hasWireless) {
    entries.push(
      assembleCustomPolicies(formattedPolicies, currentPolicy, clientPolicies, ssids, networkTypes),
    );
  }

  return [entries, currentPolicy];
};
