import { useTheme } from "@meraki/core/theme";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import { CLIENTS_SEARCH_KEY } from "~/constants/SearchKeys";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { SettingsStackProps } from "~/go/navigation/Types";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { clientName, getClientIconTypeByOS, networkFunctions } from "~/lib/ClientUtils";
import { isInSubnet } from "~/lib/IPAddressUtils";
import { PerfScreenNames, ScreenTrace, TraceTypes } from "~/lib/PerformanceUtils";
import { getPolicyAffectedNetworkNames } from "~/lib/SSIDUtils";
import { themeColors } from "~/lib/themeHelper";
import {
  getAllVlans,
  getBlockedNetworksForClient,
  getLimitedNetworksForClient,
  getNetworkName,
  getTrackedClientsList,
  gxDeviceSelector,
  searchTextState,
  slimSsidsSelector,
} from "~/selectors";
import { EmptyState, EmptyStatePrimaryText } from "~/shared/components/EmptyState";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import InputModal from "~/shared/components/InputModal";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { CancelButton } from "~/shared/navigation/Buttons";
import ClientListRow from "~/shared/rows/ClientListRow";
import { TrackedClientList } from "~/shared/types/AlertTypes";
import { BlockedNetworks, ExtendedClient, LimitedNetworks } from "~/shared/types/Client";
import Device from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { VlanResponse } from "~/shared/types/Vlans";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const MODAL_DISMISS_DURATION = 600;
const emptyRow: any = [];

type ReduxProps = {
  ssids: SSID[];
  securityAppliance: Device | undefined;
  getBlockedNetworksForClient: (client: ExtendedClient) => BlockedNetworks;
  getLimitedNetworksForClient: (client: ExtendedClient) => LimitedNetworks;
  networkName: string;
  searchText: string;
  trackedClientsList: TrackedClientList[];
  vlans: VlanResponse[];
};

interface ClientListScreenState {
  reqPending: boolean;
  selectedDeviceIp: string;
  selectedDevice: ExtendedClient | null;
  loadingInitialData: boolean;
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ClientList"> &
  ReduxProps &
  BasicActions &
  WithConnectedClientsProps &
  PendingComponent;

export class ClientsListScreen extends PureComponent<Props, ClientListScreenState> {
  private perfTrace: any;

  constructor(props: Props) {
    super(props);
    this.perfTrace = new ScreenTrace(PerfScreenNames.clientsListScreen, TraceTypes.totalLoad);

    const { navigation, title, showCancelButton } = props;
    if (title) {
      navigation.setOptions({
        headerTitle: title,
      });
    }

    navigation.setOptions({
      headerLeft: showCancelButton ? () => <CancelButton onPress={navigation.goBack} /> : undefined,
    });
  }

  clientIsTracked = (clientMac: string) => {
    const { trackedClientsList } = this.props;
    return trackedClientsList.find((thisClient) => thisClient.mac === clientMac);
  };

  trackedClientWithIcon(rowData: any) {
    const { theme } = useTheme.getState();
    return (
      <View style={styles.row}>
        <MkiText>{rowData.description || rowData.mac}</MkiText>
        <MerakiIcon
          name="tracked"
          size="s"
          color={themeColors(theme).text?.heading.color}
          style={styles.rowHeaderIcon}
        />
      </View>
    );
  }

  renderClientName = (rowData: any) => {
    return this.clientIsTracked(rowData.mac)
      ? this.trackedClientWithIcon(rowData)
      : rowData.description || rowData.mac;
  };

  renderClientRow = (rowData: any) => {
    const {
      subtitleFormat,
      ssids,
      securityAppliance,
      getBlockedNetworksForClient,
      getLimitedNetworksForClient,
    } = this.props;
    const blockedNetworks = getBlockedNetworksForClient(rowData);
    const blockedNetworkNames = getPolicyAffectedNetworkNames(
      ssids,
      securityAppliance,
      blockedNetworks,
    );

    const blockedMessage =
      blockedNetworkNames.length > 0
        ? I18n.t("CLIENTS_LIST.BLOCKED_ON", {
            blocked_names: blockedNetworkNames.join(", "),
          })
        : undefined;

    const limitedNetworks = getLimitedNetworksForClient(rowData);
    const limitedNetworkNames = getPolicyAffectedNetworkNames(
      ssids,
      securityAppliance,
      limitedNetworks,
    );

    const limitedMessage =
      limitedNetworkNames.length > 0
        ? I18n.t("CLIENTS_LIST.LIMITED_ON", {
            limited_names: limitedNetworkNames.join(", "),
          })
        : undefined;

    return (
      <ClientListRow
        renderAccessory
        blockedMessage={blockedMessage}
        limitedMessage={limitedMessage}
        isOnline={rowData.isOnline}
        onPress={rowData.onPress}
        subtitleFormat={subtitleFormat}
        usage={rowData.totalUsage}
        ip={rowData.ip}
        type={getClientIconTypeByOS(rowData.os)}
        testID={`CLIENT_LIST_ROW.${rowData.mac}`}
      >
        {this.renderClientName(rowData)}
      </ClientListRow>
    );
  };

  state: ClientListScreenState = {
    reqPending: false,
    selectedDeviceIp: "",
    selectedDevice: null,
    loadingInitialData: false,
  };

  componentDidMount() {
    this.getData(true);
  }

  onRefresh = () => this.getData(true);

  async getData(force = false) {
    const { actions, clients, ssidNumber, blockedClients } = this.props;
    const { getClients, getBlockedClients } = actions;

    const reqs: Promise<unknown>[] = [];

    if (clients.length === 0 || force) {
      // This API is flakey and not critical to app function. We will let this
      // request silently fail and it won't influence loading indicators.
      // getClientPoliciesNewEndpoint();
      reqs.push(blockedClients ? getBlockedClients() : getClients(ssidNumber));
    }

    if (reqs.length > 0) {
      await this.handleRequests(reqs, !force);
    } else {
      this.perfTrace.stopTime();
    }
  }

  handleRequests = async (requests: any, loadingInitialData: any) => {
    const reqDone = () => {
      this.perfTrace.stopTime();
      this.setState({ reqPending: false, loadingInitialData: false });
    };
    const handleError = () => {
      this.setState({ reqPending: false, loadingInitialData: false });
      showNetworkErrorWithRetry(() => this.getData(true));
    };
    this.setState({ reqPending: true, loadingInitialData });
    await Promise.all(requests).then(reqDone, handleError);
  };

  pushClientDetailsPage(rowData: any) {
    const { id } = rowData;
    const { navigation, detailsScreenOverride } = this.props;
    const { reqPending } = this.state;
    if (reqPending) {
      return;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type '[string, { id: any; }]' is not a... Remove this comment to see the full error message
    navigation.navigate(detailsScreenOverride?.name ?? "ClientDetails", {
      id,
      ...detailsScreenOverride?.params,
    });
  }

  customTextInput = () => {
    const { subnet } = this.props;

    return (
      <IPAddressTextInput
        cidrAddress={subnet}
        onAddressChange={(address: string) => {
          this.setState({
            selectedDeviceIp: address,
          });
        }}
      />
    );
  };

  onSubmitTextInputModal = async (selectedDevice: ExtendedClient) => {
    const { selectedDeviceIp } = this.state;
    const { navigation, setReqPending, handleError, onPressSave } = this.props;
    const { mac } = selectedDevice;

    try {
      setReqPending(true);

      if (onPressSave != null) {
        await onPressSave(mac, clientName(selectedDevice), selectedDeviceIp);
      }

      this.setState(
        {
          selectedDevice: null,
        },
        () =>
          setTimeout(() => {
            setReqPending(false);
            navigation.goBack();
          }, MODAL_DISMISS_DURATION),
      );
    } catch (error) {
      if (typeof error === "string") {
        handleError(error);
      }
    }
  };

  onSearchChange = (value: string) => {
    const { actions } = this.props;
    actions.setSearchText(CLIENTS_SEARCH_KEY, value);
  };

  clearSearchText = () => {
    const { actions } = this.props;
    actions.clearSearchText(CLIENTS_SEARCH_KEY);
  };

  keyExtractor = (item: any) => item.id;

  onPressRow = async (rowData: any) => {
    const { onPressSave, skipModal } = this.props;

    if (onPressSave) {
      if (skipModal) {
        await this.onSubmitTextInputModal(rowData);
      } else {
        this.setState({
          selectedDeviceIp: "",
          selectedDevice: rowData,
        });
      }
    } else {
      this.pushClientDetailsPage(rowData);
    }
  };

  getTableData = () => {
    const { clients, showOnline, filterOnSubnets, isClientConnected, vlans } = this.props;
    let filteredClients = clients;
    if (showOnline) {
      filteredClients = filteredClients.filter((c) => isClientConnected(c));
    }
    if (filterOnSubnets) {
      const subnets = vlans.map((vlan) => vlan.subnet);
      filteredClients = filteredClients.filter((c) =>
        subnets.some((subnet) => isInSubnet(subnet, c.ip)),
      );
    }
    const data = filteredClients
      .map((client) => {
        return {
          ...client,
          isOnline: isClientConnected(client),
        };
      })
      .sort(networkFunctions.sortBy);

    if (data.length === 0) {
      return emptyRow;
    }

    return data;
  };

  renderInputModal = () => {
    const { selectedDevice } = this.state;
    if (!selectedDevice) {
      return null;
    }

    const partialTitle = I18n.t("CLIENTS_LIST.RESERVE_IP_MODAL_TITLE");
    const title = `${partialTitle} ${selectedDevice && selectedDevice.description}`;
    const onExit = () => {
      this.setState({ selectedDevice: null });
    };

    if (!selectedDevice) {
      return null;
    }

    return (
      <InputModal
        title={title}
        visible={selectedDevice !== null}
        onPrimaryPress={() => this.onSubmitTextInputModal(selectedDevice)}
        onExit={onExit}
        customInputComponent={this.customTextInput()}
      />
    );
  };

  render() {
    const { searchText } = this.props;
    const { reqPending, loadingInitialData } = this.state;

    return (
      <FullScreenContainerView>
        <MkiTable
          data={this.getTableData()}
          keyExtractor={this.keyExtractor}
          renderRow={this.renderClientRow}
          onPress={this.onPressRow}
          searchPlaceholder={I18n.t("CLIENTS_LIST.SEARCH_PLACEHOLDER")}
          searchText={searchText}
          testID={"CLIENT_LIST"}
          onSearchChange={this.onSearchChange}
          onSearchClearPressed={this.clearSearchText}
          hasSeparators={true}
          refreshing={reqPending}
          onRefresh={this.onRefresh}
          loadingInitialData={loadingInitialData}
          emptyComponent={
            <EmptyState>
              <EmptyStatePrimaryText>{I18n.t("CLIENTS_LIST.EMPTY")}</EmptyStatePrimaryText>
            </EmptyState>
          }
        />
        {this.renderInputModal()}
      </FullScreenContainerView>
    );
  }
}
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  rowHeaderIcon: {
    marginLeft: SPACING.small,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    ssids: slimSsidsSelector(state),
    securityAppliance: gxDeviceSelector(state),
    getBlockedNetworksForClient: (client) => getBlockedNetworksForClient(state, { client }),
    getLimitedNetworksForClient: (client) => getLimitedNetworksForClient(state, { client }),
    networkName: getNetworkName(state),
    searchText: searchTextState(state)(CLIENTS_SEARCH_KEY),
    trackedClientsList: getTrackedClientsList(state),
    vlans: getAllVlans(state),
  };
}

export default compose<any>(
  withConnectedClients,
  withPendingComponent,
  connect(mapStateToProps, basicMapDispatchToProps),
)(ClientsListScreen);
