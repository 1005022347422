import { ReactNode } from "react";

import { Counter, CounterProps } from "./Counter";
import { Dot, DotProps } from "./Dot";
import { Floating } from "./Floating";
import { GeneralStatus, GeneralStatusProps } from "./GeneralStatus";
import { StackedCounter } from "./StackedCounter";
import { Status, StatusProps } from "./Status";

type BadgeProps = {
  badgeVisible: boolean;
  children: ReactNode;
};

type NotifierProps = BadgeProps & Omit<CounterProps, "status">;

type StatusBadgeProps = BadgeProps & StatusProps;

type GeneralStatusBadgeProps = BadgeProps & GeneralStatusProps;

type DotBadgeProps = BadgeProps & DotProps;

export const Badge = {
  Counter: Counter,
  StackedCounter: StackedCounter,
  Dot: ({ badgeVisible, children, testID }: DotBadgeProps) => {
    return <Floating badge={badgeVisible ? <Dot testID={testID} /> : null}>{children}</Floating>;
  },
  Notifier: ({ badgeVisible, value, children, testID }: NotifierProps) => {
    return (
      <Floating
        badge={badgeVisible ? <Counter value={value} status="negative" testID={testID} /> : null}
      >
        {children}
      </Floating>
    );
  },
  Status: ({ badgeVisible, status, size, children, testID }: StatusBadgeProps) => {
    return (
      <Floating
        badge={badgeVisible ? <Status status={status} size={size} testID={testID} /> : null}
      >
        {children}
      </Floating>
    );
  },
  GeneralStatus: ({ badgeVisible, status, size, children, testID }: GeneralStatusBadgeProps) => {
    return (
      <Floating
        badge={badgeVisible ? <GeneralStatus status={status} size={size} testID={testID} /> : null}
      >
        {children}
      </Floating>
    );
  },
};
