import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import ProductIcon, { PRODUCT_SIZE } from "~/go/components/ProductIcon";
import RoundedButton from "~/go/components/RoundedButton";
import TopClientsList from "~/go/components/TopClientsList";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import { showAlertWithContactSupportButton } from "~/lib/AlertUtils";
import { getDeviceLiveStatus, getDeviceStatus } from "~/lib/DeviceUtils";
import { withNodeSubscription } from "~/lib/liveBroker";
import { filteredClients, networkTypesSelector } from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { CloseButton } from "~/shared/navigation/Buttons";
import { Client } from "~/shared/types/Client";
import { NetworkTypesWithId } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  clients: Client[];
  networkTypes: NetworkTypesWithId;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "Restart"> &
  ReduxProps &
  BasicActions &
  WithConnectedClientsProps;

type RestartModalState = {
  state: "idle" | "pending" | "completed" | "error";
};

export class RestartModal extends PureComponent<Props, RestartModalState> {
  static defaultProps = {
    isLive: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      state: "idle",
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  showText = () => {
    const { clients, isClientConnected } = this.props;

    const online = clients.filter((c) => isClientConnected(c));

    return I18n.t("LIVE_TOOLS.CONFIRM_REBOOT", { count: online.length });
  };

  confirm() {
    const { device, actions } = this.props;
    const { serial } = device;
    this.setState({ state: "pending" });
    return actions
      .restart(serial)
      .then(() => this.setState({ state: "completed" }))
      .catch(() => {
        this.setState({ state: "error" });
        showAlertWithContactSupportButton({
          params: {
            supportDeviceSerial: serial,
          },
        });
        return false;
      });
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'isLive' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { navigation, device, isLive } = this.props;
    const { state } = this.state;
    const liveDeviceStatus = getDeviceLiveStatus(isLive, getDeviceStatus(device));

    return (
      <View style={styles.container}>
        <View style={[styles.productContainer, styles.center]}>
          <View style={styles.productImageContainer}>
            <ProductIcon status={liveDeviceStatus} size={PRODUCT_SIZE.large} model={device.model} />
          </View>
          <MkiText textStyle="heading">{device.name}</MkiText>
        </View>
        {(state === "idle" || state === "error") && (
          <>
            {state === "error" && (
              <View style={styles.contentContainer}>
                <MkiText textStyle="error" screenStyles={styles.errorText}>
                  {I18n.t("LIVE_TOOLS.REBOOT_FAILED")}
                </MkiText>
              </View>
            )}
            <MkiText screenStyles={styles.infoText}>{this.showText()}</MkiText>
            <TopClientsList
              filter={CUSTOM_FILTERS.DEVICE_CLIENTS(device.id)}
              maxClientsToDisplay={3}
              prioritizeOnlineClients
              showOnline
              navigate={navigation.navigate}
            />
            <View style={styles.buttonContainer}>
              <RoundedButton
                buttonType="secondary"
                onPress={() => this.confirm()}
                screenStyles={styles.buttonStyle}
              >
                {state === "idle" ? I18n.t("LIVE_TOOLS.CONFIRM") : I18n.t("LIVE_TOOLS.TRY_AGAIN")}
              </RoundedButton>
            </View>
          </>
        )}
        {state === "pending" && (
          <View style={styles.container}>
            <LoadingSpinner visible />
          </View>
        )}
        {state === "completed" && (
          <>
            <View style={styles.contentContainer}>
              <MkiText screenStyles={styles.successText}>
                {I18n.t("LIVE_TOOLS.REBOOT_INITIATED")}
              </MkiText>
            </View>
            <View style={styles.buttonContainer}>
              <RoundedButton
                buttonType="secondary"
                onPress={navigation.goBack}
                screenStyles={styles.buttonStyle}
              >
                {I18n.t("CLOSE")}
              </RoundedButton>
            </View>
          </>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  center: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  productImageContainer: {
    marginBottom: SPACING.small,
  },
  productContainer: {
    marginVertical: SPACING.default,
  },
  container: {
    flexDirection: "column",
    padding: SPACING.default,
  },
  contentContainer: {
    paddingBottom: SPACING.default,
  },
  successText: {
    paddingHorizontal: SPACING.default,
    textAlign: "center",
  },
  errorText: {
    paddingHorizontal: SPACING.default,
    textAlign: "center",
  },
  infoText: {
    color: MkiColors.secondaryTextColor,
    paddingHorizontal: SPACING.default,
    textAlign: "left",
    width: "80%",
  },
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
});

const mapStateToProps = () => {
  return (state: RootState, props: HardwareStackPropMap["Restart"]): ReduxProps => ({
    clients: filteredClients(state, props),
    networkTypes: networkTypesSelector(state),
  });
};

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withConnectedClients,
  withNodeSubscription({
    type: "NodeStatus",
    handler: ({ data }: any) => ({ isLive: data.live }),
    deviceId: (ownProps: any) => ownProps.device?.id ?? "",
  }),
)(RestartModal);
