export function isNotifiableError(value: unknown): value is string | Error {
  if (typeof value === "string") {
    return true;
  }
  if (value instanceof Error) {
    return true;
  }
  if (typeof value === "object" && value !== null) {
    if ("name" in value && "message" in value) {
      return true;
    }
  }
  return false;
}
