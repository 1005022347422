import { I18n } from "@meraki/core/i18n";
import { calculateNumberOfIPAddresses, findValidSubnet } from "@meraki/shared/ip-address";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { ALLOWED_SUBNET_MASK_MAX, ALLOWED_SUBNET_MASK_MIN } from "~/constants/IPAddress";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import IPAddressTextInput from "~/go/components/textInputs/IPAddressTextInput";
import { OnboardingStackProps } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import PickerModalRow from "~/go/rows/PickerModalRow";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import {
  DEFAULT_CLIENT_VPN_SUBNET,
  DNS_PROVIDER_OPTIONS,
  DNSProvider,
  SUBNET_PLACEHOLDER,
} from "~/go/types/ClientVPN";
import { DNSServers } from "~/go/types/NetworksTypes";
import withOnboardingStatus from "~/hocs/OnboardingData";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { extractSubnetMask, isSubnetValid } from "~/lib/IPAddressUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { currentUserState, getAllVlanSubnetRanges } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  userEmail: string;
  existingSubnetRanges: number[][];
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "ClientVPNSetup"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps &
  PendingComponent;

interface ClientVPNSetupScreenState {
  enabled: boolean;
  subnet: string;
  provider: DNSProvider;
  showProviderPicker: boolean;
  addresses: string[];
  sharedSecret: string;
  subnetError: string;
}

export class ClientVPNSetupScreen extends BaseOnboardingScreen<Props, ClientVPNSetupScreenState> {
  constructor(props: Props) {
    super(props);

    const { existingSubnetRanges } = props;

    this.state = {
      enabled: false,
      subnet: findValidSubnet(existingSubnetRanges, DEFAULT_CLIENT_VPN_SUBNET),
      provider: DNSServers.google_dns,
      showProviderPicker: false,
      addresses: [],
      sharedSecret: "",
      subnetError: "",
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.close} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.clientVPNSetup);
  }

  eanbleClientVPN = () => this.setState({ enabled: true });
  setSubnet = (newSubnet: string) => this.setState({ subnet: newSubnet });
  toggleShowProviderPicker = () =>
    this.setState({ showProviderPicker: !this.state.showProviderPicker });
  setDNSAddresses = (newAddresses: string[]) => this.setState({ addresses: newAddresses });
  updateSharedSecret = (newSharedSecret: string) =>
    this.setState({ sharedSecret: newSharedSecret });
  generateValidSubnet = () => {
    const { existingSubnetRanges } = this.props;
    const subnet = findValidSubnet(existingSubnetRanges, DEFAULT_CLIENT_VPN_SUBNET);
    this.setState({ subnet, subnetError: "" });
  };

  renderBody = () => {
    const { enabled, subnet, provider, showProviderPicker, addresses, sharedSecret, subnetError } =
      this.state;

    if (!enabled) {
      return (
        <View style={styles.firstBodyContainer}>
          <MkiText>{I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_SETUP.MESSAGE")}</MkiText>
          <RoundedButton
            buttonType={ButtonType.primary}
            key="enableButton"
            onPress={() => this.eanbleClientVPN()}
          >
            {I18n.t("ONBOARDING_FULLSTACK.CLIENT_VPN_SETUP.BUTTON")}
          </RoundedButton>
        </View>
      );
    }

    const selectedOption = DNS_PROVIDER_OPTIONS.find(({ value }) => value === provider);
    const totalIps = subnet ? calculateNumberOfIPAddresses(extractSubnetMask(subnet)) : 0;

    return (
      <View style={styles.secondBodyContainer}>
        <View style={styles.row}>
          <View style={styles.ipAddressInput}>
            <MkiText textStyle="default">{I18n.t("CLIENT_VPN.SUBNET")}</MkiText>
          </View>
          <IPAddressTextInput
            cidrAddress={subnet}
            onAddressChange={this.setSubnet}
            leaveEditableBlank={false}
            placeholders={SUBNET_PLACEHOLDER}
            includeSubnetMask
          />
          {!!subnetError && (
            <>
              <MkiText testID="SUBNET_ERROR_MESSAGE" textStyle="error">
                {subnetError}
              </MkiText>
              <RoundedButton
                testID="SUBNET_AUTO_GENERATE"
                buttonType={ButtonType.secondary}
                containerStyles={styles.generateSubnetButtonContainerStyle}
                screenStyles={styles.generateSubnetButtonStyle}
                onPress={this.generateValidSubnet}
              >
                {I18n.t("CLIENT_VPN.ERROR.AUTO_CONFIG_SUBNET")}
              </RoundedButton>
            </>
          )}
          <MkiText textStyle="smallSecondary" screenStyles={styles.subnetCountStyles}>
            {I18n.t("CONFIGURE_VLAN.SUBNET_COUNT", { address_count: totalIps })}
          </MkiText>
        </View>
        <PickerModalRow
          visible={showProviderPicker}
          label={I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.TITLE")}
          items={DNS_PROVIDER_OPTIONS}
          subtitle={selectedOption?.label}
          onPress={this.toggleShowProviderPicker}
          selectedValue={provider}
          onExit={this.toggleShowProviderPicker}
          onValueSelect={(newProvider) => {
            this.setState({ provider: newProvider as DNSProvider });
            this.toggleShowProviderPicker();
          }}
          containerStyles={styles.modalRowContainer}
          testID="CLIENT_VPN.DNS_PROVIDER"
          disableBottomBorder
        />
        <View style={styles.row}>
          <MkiText textStyle="smallSecondary">
            {I18n.t("CLIENT_VPN.NAMESERVERS.PROVIDER.DESCRIPTION")}
          </MkiText>
        </View>
        {provider === DNSServers.custom && (
          <View style={styles.row}>
            <View style={styles.ipAddressInput}>
              <MkiText textStyle="default">{I18n.t("CLIENT_VPN.NAMESERVERS.ADDRESSES")}</MkiText>
            </View>
            <IPAddressTextInput
              cidrAddress={addresses[0]}
              onAddressChange={(newVal: string) => this.setDNSAddresses([newVal, addresses[1]])}
              leaveEditableBlank={false}
            />
            <IPAddressTextInput
              cidrAddress={addresses[1]}
              onAddressChange={(newVal: string) => this.setDNSAddresses([addresses[0], newVal])}
              leaveEditableBlank={false}
            />
          </View>
        )}
        <InputRow
          value={sharedSecret}
          onChangeText={this.updateSharedSecret}
          description={I18n.t("CLIENT_VPN.SHARED_SECRET.DESCRIPTION")}
          testID="CLIENT_VPN.SHARED_SECRET"
          revealable
          secureTextEntry
        >
          <MkiText textStyle="default" screenStyles={styles.row}>
            {I18n.t("CLIENT_VPN.SHARED_SECRET.TITLE")}
          </MkiText>
        </InputRow>
      </View>
    );
  };

  onNextButtonPress = async () => {
    const { actions, existingSubnetRanges, handleError, setReqPending, userEmail } = this.props;
    const { enabled, subnet, provider, addresses, sharedSecret } = this.state;

    if (enabled) {
      const subnetMask = extractSubnetMask(subnet);
      if (subnetMask < ALLOWED_SUBNET_MASK_MIN || subnetMask > ALLOWED_SUBNET_MASK_MAX) {
        this.setState({ subnetError: I18n.t("CLIENT_VPN.ERROR.INVALID_SUBNET_MASK") });
        return;
      } else if (!isSubnetValid(subnet, existingSubnetRanges)) {
        this.setState({ subnetError: I18n.t("CLIENT_VPN.ERROR.INVALID_SUBNET") });
        return;
      } else {
        this.setState({ subnetError: "" });
      }

      try {
        setReqPending(true);
        await actions.updateIpsecVPNSettings({
          enabled,
          sharedSecret,
          ipv4: {
            subnet,
            nameservers: {
              provider,
              addresses: provider === DNSServers.custom ? addresses : [],
            },
          },
        });
        await actions.enableClientVPNAuth(userEmail);

        this.pushOnboardingScreen(this.instructionConfig.nextStage, { sharedSecret });
      } catch (e) {
        if (typeof e === "string") {
          handleError(e);
        }
      } finally {
        setReqPending(false);
      }
    } else {
      this.onPrimaryPress();
    }
  };

  getFooterData = () => {
    const { completeOnboarding } = this.props;
    const { enabled } = this.state;
    const buttons: React.ReactNode[] = [];

    if (!isWeb()) {
      buttons.push(
        <RoundedButton
          buttonType={ButtonType.secondary}
          key="skipButton"
          onPress={() => completeOnboarding()}
        >
          {I18n.t("SKIP")}
        </RoundedButton>,
      );
    }

    buttons.push(
      <RoundedButton key="nextButton" onPress={this.onNextButtonPress}>
        {enabled ? this.instructionConfig.nextButtonText : this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    );

    return { buttons };
  };

  skipConfig = getNextOnboardingStageConfig(OnboardingStage.setupComplete);
  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.clientVPNSetup);
  instructionConfig = getNextOnboardingStageConfig(OnboardingStage.clientVPNSetup, {
    hasEnabledClientVPN: true,
  });
}

const styles = StyleSheet.create({
  firstBodyContainer: {
    margin: SPACING.default,
  },
  secondBodyContainer: {
    marginVertical: SPACING.default,
  },
  row: {
    marginHorizontal: SPACING.default,
  },
  ipAddressInput: {
    marginTop: SPACING.small,
  },
  subnetCountStyles: {
    marginBottom: SPACING.default,
  },
  modalRowContainer: {
    backgroundColor: undefined,
    marginTop: 0,
  },
  generateSubnetButtonContainerStyle: {
    paddingHorizontal: SPACING.large,
  },
  generateSubnetButtonStyle: {
    paddingVertical: SPACING.small,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    userEmail: currentUserState(state),
    existingSubnetRanges: getAllVlanSubnetRanges(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
  withPendingComponent,
)(ClientVPNSetupScreen);
