import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Lan({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path
        d="M6.375 20.25C7.82475 20.25 9 19.0747 9 17.625C9 16.1753 7.82475 15 6.375 15C4.92525 15 3.75 16.1753 3.75 17.625C3.75 19.0747 4.92525 20.25 6.375 20.25Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.375 9C7.82475 9 9 7.82475 9 6.375C9 4.92525 7.82475 3.75 6.375 3.75C4.92525 3.75 3.75 4.92525 3.75 6.375C3.75 7.82475 4.92525 9 6.375 9Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.625 15.75C19.0747 15.75 20.25 14.5747 20.25 13.125C20.25 11.6753 19.0747 10.5 17.625 10.5C16.1753 10.5 15 11.6753 15 13.125C15 14.5747 16.1753 15.75 17.625 15.75Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15 13.5H12.2344C11.5739 13.5013 10.9212 13.3566 10.3232 13.0762C9.72513 12.7958 9.19646 12.3867 8.775 11.8781L6.375 9V15"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
