import { ThemeName, useTheme } from "@meraki/core/theme";
import { List } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";

export function DebugTheme() {
  const { theme, setTheme } = useTheme();

  const checked = (listItem: ThemeName) =>
    listItem === theme ? <Icon name="Check" size={20} /> : null;

  return (
    <Screen addDefaultPadding>
      <List>
        <List.Item
          title="Light"
          onPress={() => setTheme("light")}
          hidePressable
          rightAccessory={checked("light")}
        />
        <List.Item
          title="Dark"
          onPress={() => setTheme("dark")}
          hidePressable
          rightAccessory={checked("dark")}
        />
        <List.Item
          title="Deuteranomaly Light"
          onPress={() => setTheme("lightDeuteranomaly")}
          hidePressable
          rightAccessory={checked("lightDeuteranomaly")}
        />
        <List.Item
          title="Deuteranomaly Dark"
          onPress={() => setTheme("darkDeuteranomaly")}
          hidePressable
          rightAccessory={checked("darkDeuteranomaly")}
        />
        <List.Item
          title="Amoled"
          onPress={() => setTheme("amoled")}
          hidePressable
          rightAccessory={checked("amoled")}
        />
        <List.Item
          title="System"
          onPress={() => setTheme("system")}
          hidePressable
          rightAccessory={checked("system")}
        />
      </List>
    </Screen>
  );
}
