import { I18n } from "@meraki/core/i18n";
import { PortStatus } from "@meraki/magnetic/components";
import { LivePorts } from "@meraki/react-live-broker";
import { AppliancePort, SwitchPort } from "@meraki/shared/api";
import { isEmpty } from "lodash";

export const MAC_REGEX = /^([0-9A-Fa-f]{2}:){5}([0-9A-Fa-f]{2})$/;

export type PortFilterCategories = "status" | "usage";
type PortStatusFilters = "online" | "offline" | "disabled" | "disconnected";
type PortUsageFilters = "poe" | "uplink";

export type CombinedAppliancePort = AppliancePort & LivePorts;

export type AppliancePortStatusAndFilter = CombinedAppliancePort &
  PortStatus & {
    status?: PortStatusFilters;
    usage?: PortUsageFilters;
  };

const partialStringMatch = (data: string | number, searchText: string) =>
  data?.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0;

export function getAppliancePortSearchResults(
  ports?: AppliancePortStatusAndFilter[],
  searchText?: string,
) {
  if (isEmpty(searchText) || !searchText || !ports || isEmpty(ports)) {
    return ports;
  }

  return ports.filter((port) => {
    const { number, type, vlan } = port;

    if (number === 1) {
      return (
        partialStringMatch(I18n.t("PORTS.INTERNET_CONNECTION"), searchText) ||
        partialStringMatch(I18n.t("PORTS.NUMBER", { port_number: number }), searchText) ||
        partialStringMatch(type ?? "", searchText) ||
        partialStringMatch(vlan ?? "", searchText)
      );
    }

    return (
      partialStringMatch(I18n.t("PORTS.NUMBER", { port_number: number }), searchText) ||
      partialStringMatch(type ?? "", searchText) ||
      partialStringMatch(vlan ?? "", searchText)
    );
  });
}

export type PortFilterOptions = {
  status: PortStatusFilters;
  usage: PortUsageFilters;
};

export type SwitchPortStatusAndFilter = SwitchPort &
  PortStatus & {
    status?: PortStatusFilters;
    usage?: PortUsageFilters;
  };

export function getSwitchPortSearchResults(
  ports?: SwitchPortStatusAndFilter[],
  searchText?: string,
) {
  if (isEmpty(searchText) || !searchText || !ports || isEmpty(ports)) {
    return ports;
  }

  return ports.filter((port) => {
    const { name, type, vlan, portId } = port;
    return (
      partialStringMatch(I18n.t("PORTS.NUMBER", { port_number: portId }), searchText) ||
      partialStringMatch(name ?? "", searchText) ||
      partialStringMatch(type ?? "", searchText) ||
      partialStringMatch(vlan ?? "", searchText)
    );
  });
}
