import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const ModeTypeSchema = z.union([
  z.literal("none"),
  z.literal("spoke"),
  z.literal("hub"),
] as const);

export const SiteToSiteVPNSchema = z
  .object({
    mode: ModeTypeSchema,
    hubs: z
      .array(
        z.object({
          hubId: z.string(),
          useDefaultRoute: z.boolean(),
        }),
      )
      .optional(),
    subnets: z
      .array(
        z.object({
          localSubnet: z.string(),
          useVpn: z.boolean(),
        }),
      )
      .optional(),
  })
  .describe("SiteToSiteVPNSchema");

export type SiteToSiteVPN = z.infer<typeof SiteToSiteVPNSchema>;
export type ModeType = z.infer<typeof ModeTypeSchema>;

type SiteToSiteVPNRequest = {
  networkId?: string;
};

type SiteToSiteVPNPostParams = SiteToSiteVPNRequest & {
  params: SiteToSiteVPN;
};

function buildUrl({ networkId }: { networkId?: string }) {
  return `/api/v1/networks/${networkId}/appliance/vpn/siteToSiteVpn`;
}

function fetchSiteToSiteVPNSettings(variables: SiteToSiteVPNRequest): Promise<SiteToSiteVPN> {
  if (!variables.networkId) {
    throw new Error("Undefined network id when fetching site-to-site VPN settings");
  }
  return request(SiteToSiteVPNSchema, "GET", buildUrl(variables));
}
function updateSiteToSiteVPNSettings(variables: SiteToSiteVPNPostParams): Promise<SiteToSiteVPN> {
  return request(SiteToSiteVPNSchema, "PUT", buildUrl(variables), {
    body: JSON.stringify(variables.params),
  });
}

export const useSiteToSiteVPNSettings = createQuery<SiteToSiteVPNRequest, SiteToSiteVPN>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchSiteToSiteVPNSettings(variables),
  requiredVariables: ["networkId"],
});

export const useUpdateSiteToSiteVPNSettings = createMutation<
  SiteToSiteVPNPostParams,
  SiteToSiteVPN,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (variables: SiteToSiteVPNPostParams) => updateSiteToSiteVPNSettings(variables),
});
