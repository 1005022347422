import { SVGRenderer } from "@wuba/react-native-echarts/svgChart";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import {
  DatasetComponent,
  DataZoomComponent,
  GraphicComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import * as echarts from "echarts/core";

// register extensions
echarts.use([
  DatasetComponent,
  DataZoomComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart,
  GraphicComponent,
  GridComponent,
  GraphicComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  VisualMapComponent,
  SVGRenderer,
]);
