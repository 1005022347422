import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import Touchable from "~/shared/components/Touchable";
import { GoStatus, StatusType } from "~/shared/constants/Status";

const ICON_DIMENSION = 8;

interface StatusExample {
  status: StatusType;
  text: string;
  isHollow: boolean;
}

const StatusLegend = (props: { onPress: () => void }) => {
  const { onPress } = props;
  const statusSettings: StatusExample[] = [
    {
      status: GoStatus.good,
      text: I18n.t("ACTIVE"),
      isHollow: false,
    },
    {
      status: GoStatus.good,
      text: I18n.t("MESHED_GR_STATUS.LEGEND"),
      isHollow: true,
    },
    {
      status: GoStatus.warning,
      text: I18n.t("UPGRADING"),
      isHollow: false,
    },
    {
      status: GoStatus.bad,
      text: I18n.t("ALERTING"),
      isHollow: false,
    },
    {
      status: GoStatus.offline,
      text: I18n.t("OFFLINE"),
      isHollow: false,
    },
  ];

  const statusItems = statusSettings.map((statusObject: StatusExample, index: number) => {
    const { status, text, isHollow } = statusObject;
    return (
      <View key={`${status}${index}`} style={styles.legendItem}>
        <StatusIcon status={status} isHollow={isHollow} screenStyles={styles.legendIcon} />
        <MkiText textStyle="small" screenStyles={styles.legendText}>
          {text}
        </MkiText>
      </View>
    );
  });

  return (
    <Touchable onPress={onPress} testID="STATUS_LEGEND" transparentBackground>
      <View style={styles.legendContainer}>
        <View style={styles.statusItemsContainer}>{statusItems}</View>
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  legendContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: SPACING.large,
  },
  statusItemsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginLeft: SPACING.small,
    marginRight: SPACING.small,
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: SPACING.small,
  },
  legendText: {
    color: MkiColors.secondaryTextColor,
  },
  legendIcon: {
    marginRight: 6,
    width: ICON_DIMENSION,
    height: ICON_DIMENSION,
  },
});

export default StatusLegend;
