import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import ListRow from "~/shared/rows/ListRow";

const productInfoRow = (rowData: any, theme: any, navigation: any, copyToClipboard: any) => {
  const pushFirmwareUpgradesScreen = () => navigation.navigate("FirmwareUpgrades");

  const pushOpenSupportCaseScreen = () => navigation.navigate("SearchSubject");

  if (rowData.isDisclosureRow) {
    return (
      <DisclosureRow onPress={rowData.onPress} isFullButton>
        {rowData.label}
      </DisclosureRow>
    );
  }
  const rowStyles = [
    styles.rowStyles,
    {
      borderBottomColor: themeColors(theme).border.borderColor || MkiColors.borderColor,
    },
    rowData.disableBottomBorder && {
      borderBottomWidth: 0,
    },
  ];

  if (rowData.isButton) {
    return (
      <>
        <RoundedButton
          onPress={rowData.requiresSupport ? pushOpenSupportCaseScreen : pushFirmwareUpgradesScreen}
          screenStyles={styles.button}
          buttonType={ButtonType.secondary}
        >
          {rowData.label}
        </RoundedButton>
        <MkiRowSeparator withTableStyle />
      </>
    );
  }

  return (
    <ListRow
      value={rowData.value}
      rowStyles={rowStyles}
      rightStyle={styles.rightContent}
      leftStyle={styles.leftContent}
      labelStyle={styles.productInfoLabel}
      rightTextStyle={rowData.rightTextStyle}
      onLongPress={() => copyToClipboard(rowData.value)}
      underlayColor={"transparent"}
    >
      {rowData.label}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
  rowStyles: {
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.default,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  productInfoLabel: {
    color: MkiColors.secondaryTextColor,
  },
});

export default productInfoRow;
