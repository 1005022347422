import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Heading, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useSwitchPorts, useUpdateSwitchPort } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { RouteProp, useRoute } from "@react-navigation/native";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";

import { getLinkSpeedTitleDescription } from "../constants/LinkSpeed";

export interface LinkSpeedSettingsForm {
  linkSpeed: string;
}

export const LinkSpeedSettingsScreen = () => {
  const route = useRoute<RouteProp<DeviceGroupProps, "LinkSpeedSettings">>();
  const { params: props } = route;
  const { port, serial } = props;
  const LINK_SPEED_TITLE_DESCRIPTION = getLinkSpeedTitleDescription();

  const methods = useForm<LinkSpeedSettingsForm>({
    values: { linkSpeed: port?.linkNegotiation ?? "Auto negotiate" },
  });

  const { mutate: updatePortLinkSpeed } = useUpdateSwitchPort();

  if (!port || !serial) {
    return null;
  }

  const updateLinkSpeed = (linkSpeed: string) => {
    updatePortLinkSpeed(
      {
        serial,
        portId: port.portId,
        switchPort: { ...port, linkNegotiation: linkSpeed },
      },
      {
        onError: (error) => {
          Alert.alert(String(error["errors"]));
          methods.resetField("linkSpeed");
        },
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("LINK_SPEED.SUCCESS"), Toast.SHORT, Toast.BOTTOM);
          queryClient.invalidateQueries({
            queryKey: useSwitchPorts.queryKey({ serial }),
          });
        },
      },
    );
  };

  return (
    <Screen.View testID="LINK_SPEED_SETTINGS">
      <Box paddingLeft="md" paddingBottom="xs" bottomDividerBorder>
        <Heading size="h1">{I18n.t("LINK_SPEED.TITLE")}</Heading>
      </Box>
      <Box paddingHorizontal="md">
        <Text size="p1">{I18n.t("LINK_SPEED.DESCRIPTION")}</Text>
      </Box>
      <Form {...methods}>
        <Form.FlashList
          name="linkSpeed"
          data={port.linkNegotiationCapabilities.map((capability) => ({
            linkSpeed: capability,
          }))}
          getItemData={(option) => ({
            kind: "radio",
            title: LINK_SPEED_TITLE_DESCRIPTION[option.linkSpeed]?.title,
            description:
              LINK_SPEED_TITLE_DESCRIPTION[option.linkSpeed]?.description &&
              `${LINK_SPEED_TITLE_DESCRIPTION[option.linkSpeed]?.description} ${
                LINK_SPEED_TITLE_DESCRIPTION[option.linkSpeed]?.forced
                  ? I18n.t("LINK_SPEED.FORCED")
                  : ""
              }`,
            radioValue: option.linkSpeed,
            onRadioValueChanged: () => {
              updateLinkSpeed(option.linkSpeed);
            },
            testID: `LINK_SPEED_ROW.${option.linkSpeed}${
              methods.getValues("linkSpeed") === option.linkSpeed ? ".SELECTED" : ""
            }`,
          })}
          testID="LINK_SPEED_LIST"
        />
      </Form>
    </Screen.View>
  );
};
