import { AccessPoint } from "./icons/AccessPoint";
import { Appliance } from "./icons/Appliance";
import { Camera } from "./icons/Camera";
import { Client } from "./icons/Client";
import { Lan } from "./icons/Lan";
import { Sensor } from "./icons/Sensor";
import { Switch } from "./icons/Switch";
import { System } from "./icons/System";
import { SystemsManager } from "./icons/SystemsManager";
import { Voip } from "./icons/Voip";
import { Wan } from "./icons/Wan";

const ICON_MAP = {
  accessPoint: AccessPoint,
  appliance: Appliance,
  camera: Camera,
  client: Client,
  lan: Lan,
  sensor: Sensor,
  system: System,
  systemsManager: SystemsManager,
  switch: Switch,
  voip: Voip,
  wan: Wan,
};

export interface LineProductIconProps {
  name: keyof typeof ICON_MAP;
  color?: boolean;
  size?: number;
}

export function LineProductIcon({ name, size, color }: LineProductIconProps) {
  const Icon = ICON_MAP[name];

  if (!Icon) return null;

  return <Icon width={size ?? 24} height={size ?? 24} color={color} />;
}
