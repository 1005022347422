import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import GoColors from "~/go/constants/GoColors";
import MerakiIcon from "~/shared/components/icons";
import StatusIcon from "~/shared/components/StatusIcon";
import ListRow from "~/shared/rows/ListRow";
import { SSID } from "~/shared/types/Models";

interface WirelessNetworkRowProps {
  ssid: SSID;
  clientCount: number;
  idx: number;
}

const WirelessNetworkRow = ({ ssid, clientCount, idx }: WirelessNetworkRowProps) => {
  const navigation = useNavigation();

  const iconColor = ssid.enabled ? GoColors.purple : GoColors.navyLighter;
  const hasSchedule = ssid.schedule?.enabled ?? false;

  let clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.zero");
  if (clientCount === 1) {
    clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.one");
  } else if (clientCount > 1) {
    clientCountText = I18n.t("CLIENTS_CONNECTED_COUNT.other", {
      formatted_number: clientCount,
    });
  }

  return (
    <ListRow
      testID={`WIRELESS_NETWORK_ROW.${idx}`}
      icon={
        <View style={styles.iconView}>
          <StatusIcon
            testID="WIRELESS_NETWORK_ROW.WIFI_ICON"
            customIcon={{
              icon: MerakiIcon,
              name: "wifi",
              size: "m",
              color: iconColor,
              hasGradient: false,
            }}
            screenStyles={styles.icon}
          />
          {hasSchedule && (
            <StatusIcon
              testID="WIRELESS_NETWORK_ROW.SCHEDULE_ICON"
              screenStyles={styles.scheduleIcon}
              customIcon={{
                icon: MerakiIcon,
                name: "calendar-clock",
                size: "xxs",
                color: iconColor,
                hasGradient: false,
              }}
            />
          )}
        </View>
      }
      subtitle1={ssid.enabled ? clientCountText : I18n.t("DISABLED")}
      rightStyle={styles.rightAccessory}
      accessory={<MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />}
      onPress={() => navigation.navigate("SSIDDetails", { ssidNumber: ssid.number })}
    >
      {ssid.name}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  icon: {
    marginLeft: SPACING.default,
    marginRight: SPACING.small,
  },
  iconView: {
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  scheduleIcon: {
    alignSelf: "flex-end",
    paddingRight: SPACING.meager,
    bottom: SPACING.small,
  },
  rightAccessory: {
    paddingRight: SPACING.default,
  },
});

export default WirelessNetworkRow;
