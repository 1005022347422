import { queryClient } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useMutation } from "@tanstack/react-query";

import { applianceSettingsKeys } from "~/api/queries/appliances/keys";
import {
  ApplianceSettings,
  ApplianceSettingsSchema,
  DynamicDns,
} from "~/api/schemas/ApplianceSettings";
import { useDependentQuery } from "~/api/util/query";
import { validatedMerakiRequest } from "~/api/util/request";
import { isCameraOnlyAdmin } from "~/selectors";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

type ApplianceSettingsPartial =
  | Partial<ApplianceSettings>
  | {
      dynamicDns: Partial<DynamicDns>;
    };

export const fetchApplianceSettings = (networkId?: string) => {
  return validatedMerakiRequest(
    ApplianceSettingsSchema,
    "GET",
    `/api/v1/networks/${networkId}/appliance/settings`,
  );
};

export const updateApplianceSettings = (settings: ApplianceSettingsPartial, networkId?: string) => {
  return validatedMerakiRequest(
    ApplianceSettingsSchema,
    "PUT",
    `/api/v1/networks/${networkId}/appliance/settings`,
    { body: JSON.stringify(settings) },
  );
};

export const useApplianceSettings = (isAppliance: boolean) => {
  const networkId = useCurrentNetworkId();
  const isCOA = useAppSelector(isCameraOnlyAdmin);

  return useDependentQuery({
    dependencies: [networkId] as const,
    queryKey: applianceSettingsKeys.settings(networkId),
    queryFn: () => (networkId_: string) => fetchApplianceSettings(networkId_),
    enabled: !!networkId && !isCOA && isAppliance,
  });
};

export const useUpdateApplianceSettings = () => {
  const networkId = useCurrentNetworkId();
  return useMutation({
    mutationFn: (settings: ApplianceSettingsPartial) => {
      return updateApplianceSettings(settings, networkId);
    },
    onSuccess: async (newSettings: ApplianceSettings) => {
      const keyToSettings = applianceSettingsKeys.settings(networkId);
      await queryClient.cancelQueries(keyToSettings);
      queryClient.setQueryData(keyToSettings, {
        ...newSettings,
      });
      return { newSettings };
    },
  });
};
