import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId }: NetworkClientsUsageHistoriesRequest) => {
  return `/api/v1/networks/${networkId}/clients/usageHistories`;
};

interface NetworkClientsUsageHistoriesRequest {
  networkId?: string;
  timespan?: number;
  clients?: string;
  ssidNumber?: number;
}

export const ClientUsageHistorySchema = z.object({
  ts: z.string(),
  received: z.number(),
  sent: z.number(),
});

export const NetworkClientsUsageHistorySchema = z.object({
  clientId: z.string(),
  clientIp: z.string(),
  clientMac: z.string(),
  usageHistory: z.array(ClientUsageHistorySchema),
});

export const NetworkClientsUsageHistoriesSchema = z.array(NetworkClientsUsageHistorySchema);

export type NetworkClientsUsageHistories = z.infer<typeof NetworkClientsUsageHistoriesSchema>;
export type ClientUsageHistory = z.infer<typeof ClientUsageHistorySchema>;

const fetchNetworkClientsUsageHistories = ({
  networkId,
  ...queryParams
}: NetworkClientsUsageHistoriesRequest) => {
  return request(NetworkClientsUsageHistoriesSchema, "GET", buildUrl({ networkId }), {
    queryParams,
  });
};

export const useNetworkClientsUsageHistories = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: NetworkClientsUsageHistoriesRequest) =>
    fetchNetworkClientsUsageHistories(parameters),
  requiredVariables: ["networkId", "clients"],
});
