import { I18n } from "@meraki/core/i18n";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCallback, useState } from "react";
import { Platform } from "react-native";

export const LocateAPIEndpoint = "https://locate.measurementlab.net/v2/nearest/ndt/ndt7";

export const TestURLKeys = {
  download: "wss:///ndt/v7/download",
  upload: "wss:///ndt/v7/upload",
};

const TestProtocol = "net.measurementlab.ndt.v7";

const MaxMessageSize = 8388608; // = (1<<23) = 8MB
const InitialMessageSize = 8192; // (1<<13) = 8KB

// in milliseconds
const UploadDuration = 10000;
const UploadMeasurementInterval = 250;

export type MLabStatus =
  | "notReady"
  | "ready"
  | "failed"
  | "downloading"
  | "uploading"
  | "completed";

interface SpeedTestTools {
  fetchTestLocations: () => Promise<void>;
  downloadTester: (setDownloadResult: (speed: number) => void) => Promise<void>;
  uploadTester: (setUploadResult: (speed: number) => void) => Promise<void>;
}

type SpeedTestHookResult = [MLabStatus, SpeedTestTools];

// bytes/millisec * 0.008 = Megabits/sec
export function calculateMeanDownloadMbps(startTimeInMillisec: number, downloadTotal: number) {
  const elapsedTimeInMillisec = Date.now() - startTimeInMillisec;
  return elapsedTimeInMillisec > 0
    ? parseFloat(((downloadTotal / elapsedTimeInMillisec) * 0.008).toFixed(2))
    : 0;
}

// bytes/microsec * 8 = Megabits/sec
export function calculateUploadMbps(elapsedTimeInMicrosec: number, uploadTotal: number) {
  return elapsedTimeInMicrosec > 0
    ? parseFloat(((uploadTotal / elapsedTimeInMicrosec) * 8).toFixed(2))
    : 0;
}

export function useMLabSpeedTest(): SpeedTestHookResult {
  const [downloadURL, setDownloadURL] = useState("");
  const [uploadURL, setUploadURL] = useState("");
  const [status, setStatus] = useState<MLabStatus>("notReady");

  const fetchTestLocations = useCallback(async () => {
    try {
      const response = await fetch(LocateAPIEndpoint);
      const responseJson = await response.json();
      const urls = responseJson?.results?.[0]?.urls ?? {}; // choose first location

      if (TestURLKeys.download in urls || TestURLKeys.upload in urls) {
        setDownloadURL(urls[TestURLKeys.download]);
        setUploadURL(urls[TestURLKeys.upload]);
        setStatus("ready");
      } else {
        setStatus("failed");
      }
    } catch (e) {
      showErrorAlert(String(e));
      setStatus("failed");
    }
  }, []);

  const downloadTester = useCallback(
    async (setDownloadResult: (speed: number) => void) => {
      if (downloadURL) {
        await new Promise<void>((resolve) => {
          let start = Date.now();
          let downTotal = 0;

          const downSock = new WebSocket(downloadURL, TestProtocol);
          if (Platform.OS !== "web") {
            downSock.binaryType = "blob";
          }

          downSock.onopen = () => {
            start = Date.now();
            downTotal = 0;
            setStatus("downloading");
          };

          downSock.onmessage = (ev) => {
            // data size or length of blob gives download size
            downTotal += ev?.data?.size ?? ev?.data?.length ?? 0;
            if (ev?.data?.close != null) {
              ev.data.close(); // close blob to prevent memory leak
            }

            setDownloadResult(calculateMeanDownloadMbps(start, downTotal));
          };

          downSock.onerror = () => {
            showErrorAlert(I18n.t("SPEED_TEST.ERROR"));
            setStatus("failed");
            resolve();
          };

          downSock.onclose = () => {
            setDownloadResult(calculateMeanDownloadMbps(start, downTotal));
            resolve();
          };
        });
      }
    },
    [downloadURL],
  );

  const uploadTester = useCallback(
    async (setUploadResult: (speed: number) => void) => {
      if (uploadURL) {
        await new Promise<void>((resolve) => {
          let bytesReceived = 0;
          let elapsedTime = 0;

          const upSock = new WebSocket(uploadURL, TestProtocol);

          function uploader(
            data: Uint8Array,
            start: number,
            end: number,
            previous: number,
            upTotal: number,
          ) {
            const t = Date.now();
            const bufferedAmount = upSock.bufferedAmount ?? 0;
            if (t >= end) {
              upSock.close();
              return;
            }

            // Message size is doubled after the first 16 messages, and subsequently
            // every 8, up to MaxMessageSize.
            const nextSizeIncrement = data.length >= MaxMessageSize ? Infinity : 16 * data.length;
            if (upTotal - bufferedAmount >= nextSizeIncrement) {
              data = new Uint8Array(data.length * 2);
            }

            // Keeping 7 messages in the send buffer, so there is always some data to send.
            const desiredBuffer = 7 * data.length;
            if (bufferedAmount < desiredBuffer) {
              upSock.send(data);
              upTotal += data.length;
            }

            if (t >= previous + UploadMeasurementInterval) {
              setUploadResult(calculateUploadMbps(elapsedTime, bytesReceived));
              previous = t;
            }

            // Loop the uploader function in a way that respects the JS event handler.
            setTimeout(() => uploader(data, start, end, previous, upTotal), 0);
          }

          upSock.onopen = () => {
            const data = new Uint8Array(InitialMessageSize);
            const start = Date.now(); // ms since epoch
            const end = start + UploadDuration; // ms since epoch

            // Start the upload loop.
            uploader(data, start, end, start, 0);
            setStatus("uploading");
          };

          upSock.onmessage = (ev) => {
            if (ev.data != null) {
              const uploadData = JSON.parse(ev.data)?.TCPInfo;
              bytesReceived = uploadData?.BytesReceived ?? 0;
              elapsedTime = uploadData?.ElapsedTime ?? 0;
            }
          };

          upSock.onerror = () => {
            showErrorAlert(I18n.t("SPEED_TEST.ERROR"));
            setStatus("failed");
            resolve();
          };

          upSock.onclose = () => {
            setUploadResult(calculateUploadMbps(elapsedTime, bytesReceived));
            setStatus("completed");
            resolve();
          };
        });
      }
    },
    [uploadURL],
  );

  return [status, { fetchTestLocations, downloadTester, uploadTester }];
}
