import Svg, { Circle, G, Path } from "react-native-svg";

const GlobeIcon = (props: any) => (
  <Svg width={75} height={75} viewBox="0 0 75 75" {...props}>
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G id="Group-3">
        <Circle fill="#6665F6" cx="37.5" cy="37.5" r="37.5" />
        <Circle fill="#FFFFFF" cx="37.7" cy="37.7" r="27.7" />
        <G transform="translate(9.800000, 9.700000)" fill="#BFCAFF">
          <Path d="M31,5.3 C33.9,6.6 36.6,8.2 39.1,10.1 C39.6,9.6 40.4,9.3 41.2,9.3 C42.2,9.3 43.2,9.8 43.7,10.6 C44.7,10.5 45.7,10.5 46.7,10.5 C47.6,10.5 48.5,10.5 49.4,10.6 C45.2,5.5 39.3,1.9 32.5,0.7 C31.7,1.6 31,2.5 30.3,3.4 C30.7,3.9 31,4.6 31,5.3 Z" />
          <Path d="M41.3,15.4 C40,15.4 38.9,14.6 38.4,13.4 C33.6,14.4 29.1,16.3 25.1,18.9 C25.1,19.1 25.2,19.3 25.2,19.6 C25.2,21.2 24,22.5 22.5,22.7 C22.3,24.4 22.2,26 22.2,27.8 C22.2,32.4 23,36.9 24.4,41 C24.5,41 24.6,41 24.7,41 C26.4,41 27.8,42.4 27.8,44.1 C27.8,44.1 27.8,44.1 27.8,44.1 C31.7,45.4 35.8,46 40.1,46 C43.6,46 47,45.5 50.3,44.7 C51.7,42.8 52.9,40.7 53.8,38.4 C52.5,29.5 48.3,21.5 42.1,15.5 C41.8,15.4 41.5,15.4 41.3,15.4 Z" />
          <Path d="M27.1,45.8 C27,45.9 26.9,46.1 26.7,46.2 C28.4,49.5 30.6,52.5 33.1,55.2 C38.9,54.1 44.1,51.2 48,47.1 C45.4,47.6 42.8,47.9 40,47.9 C35.5,47.9 31.2,47.2 27.1,45.8 Z" />
          <Path d="M44.3,12.6 C44.3,13.1 44.1,13.7 43.8,14.1 C49.2,19.5 53.2,26.4 55,34.1 C55.4,32.2 55.7,30.1 55.7,28.1 C55.7,22.5 54,17.2 51.1,12.8 C49.7,12.6 48.2,12.6 46.8,12.6 C46,12.5 45.1,12.5 44.3,12.6 Z" />
          <Path d="M12.9,35.3 C15.7,37.9 19,40.1 22.5,41.8 C22.5,41.8 22.5,41.8 22.6,41.7 C21,37.3 20.2,32.6 20.2,27.6 C20.2,25.8 20.3,24 20.5,22.3 C17.3,25.1 14.6,28.3 12.4,31.9 C12.9,32.4 13.2,33.2 13.2,34 C13.2,34.5 13.1,34.9 12.9,35.3 Z" />
          <Path d="M37.6,11.5 C35.3,9.9 32.9,8.4 30.3,7.3 C29.7,8 28.9,8.4 27.9,8.4 C27.6,8.4 27.4,8.4 27.1,8.3 C25.6,11 24.4,13.9 23.6,16.9 C23.7,17 23.9,17.1 24,17.2 C28.1,14.5 32.7,12.6 37.6,11.5 Z" />
          <Path d="M27.9,2.2 C28.1,2.2 28.3,2.2 28.5,2.3 C29,1.6 29.5,1 30,0.4 C29.3,0.3 28.6,0.3 27.9,0.3 C24.6,0.3 21.4,0.9 18.5,1.9 C20.9,2.2 23.2,2.6 25.5,3.3 C26.2,2.7 27,2.2 27.9,2.2 Z" />
          <Path d="M28,55.7 C28.9,55.7 29.8,55.7 30.7,55.6 C28.4,53 26.4,50.1 24.8,47 C24.8,47 24.7,47 24.7,47 C23,47 21.6,45.6 21.6,43.9 C21.6,43.8 21.6,43.7 21.6,43.6 C17.9,41.8 14.5,39.5 11.5,36.7 C11.1,36.9 10.6,37.1 10.1,37.1 C10,37.1 9.9,37.1 9.7,37.1 C8.5,40 7.6,43 7.1,46.2 C12.2,52 19.6,55.7 28,55.7 Z" />
          <Path d="M10.1,31 C10.3,31 10.4,31 10.6,31 C12.9,27.1 15.9,23.6 19.3,20.7 C19.2,20.3 19.1,20 19.1,19.6 C19.1,18.1 20.2,16.8 21.7,16.6 C22.6,13.3 23.9,10.1 25.5,7.2 C25.1,6.7 24.9,6.1 24.9,5.4 C24.9,5.4 24.9,5.4 24.9,5.3 C21.6,4.4 18.2,3.9 14.7,3.8 C8.8,7 4.3,12.2 2,18.6 C3.6,23.2 6,27.5 9,31.2 C9.3,31 9.7,31 10.1,31 Z" />
          <Path d="M7,34 C7,33.4 7.2,32.9 7.4,32.4 C4.8,29.2 2.6,25.6 1,21.7 C0.5,23.7 0.3,25.8 0.3,27.9 C0.3,33.9 2.2,39.4 5.4,43.9 C6,41.2 6.8,38.6 7.9,36.1 C7.3,35.6 7,34.9 7,34 Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export default GlobeIcon;
