import z from "zod";

export const SupportCaseCommentResponseSchema = z
  .object({
    success: z.boolean(),
    status: z.string(),
  })
  .describe("SupportCaseCommentInputSchema");

export const SupportCaseInfoInteractionSchema = z
  .object({
    type: z.string(),
    preview: z.string(),
    createdDate: z.string(),
    //comments
    createdByName: z.string().optional(),
    commentBody: z.string().optional(),
    //emails
    parentContactEmail: z.string().optional(),
    textBody: z.string().optional(),
    status: z.string().optional(),
    incoming: z.string().optional(),
    ccAddress: z.string().nullable().optional(),
    toAddress: z.string().optional(),
    fromAddress: z.string().optional(),
    fromName: z.string().optional(),
    subject: z.string().optional(),
  })
  .describe("SupportCaseInfoInteractionSchema");

export const SupportCaseInfoAttachmentSchema = z
  .object({
    type: z.string(),
    Id: z.array(z.string()),
    CreatedDate: z.string(),
    Name: z.string(),
  })
  .describe("SupportCaseInfoAttachmentSchema");

export const SupportCaseInfoResponseSchema = z
  .object({
    interactions: z.array(SupportCaseInfoInteractionSchema),
    attachments: z.array(SupportCaseInfoAttachmentSchema),
  })
  .describe("SupportCaseInfoResponseSchema");

export const SupportCaseInfoAttachmentInputSchema = z
  .object({
    file: z.string(),
    filename: z.string(),
    case_number: z.string(),
  })
  .describe("SupportCaseInfoAttachmentInputSchema");

export const SupportCaseInfoAttachmentResponseSchema = z
  .object({
    success: z.boolean(),
    errors: z.array(z.string()).optional(),
  })
  .optional()
  .describe("SupportCaseInfoAttachmentResponseSchema");

export type CaseInfo = z.infer<typeof SupportCaseInfoResponseSchema>;
export type CaseInteraction = z.infer<typeof SupportCaseInfoInteractionSchema>;
export type CaseInfoResponse = z.infer<typeof SupportCaseInfoResponseSchema>;
export type CaseAttachmentInput = z.infer<typeof SupportCaseInfoAttachmentInputSchema>;
