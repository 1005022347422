import {
  FormatListDataArgs,
  FormatListFlatDataArgs,
  InternalFlashListItemProps,
  InternalFlashListProps,
} from "./types";

function createFormatter<T>({
  data,
  getItemData,
  sectionLabel,
}: FormatListFlatDataArgs<T> & { sectionLabel?: string }): (
  item: T,
  index: number,
) => InternalFlashListItemProps {
  return (item: T, index: number) => {
    return {
      firstOfSection: index === 0,
      lastOfSection: index === (data?.length ?? 0) - 1,
      getItemData: () => getItemData(item, sectionLabel, index),
    };
  };
}

export function formatListData<T>({
  label,
  groupBy,
  data,
  sortGroupBy = (a, b) => a.label.localeCompare(b.label),
  getItemData,
}: FormatListDataArgs<T>): InternalFlashListProps<InternalFlashListItemProps>["data"] {
  if (!data || data.length === 0) return [];

  const calculatedData: InternalFlashListItemProps[] = [];

  if (label) {
    calculatedData.push({ header: true, label, firstSection: true });
  }

  if (groupBy) {
    const groupedData = (data as readonly T[]).reduce<{ label: string; data: T[] }[]>(
      (acc, item) => {
        const itemGroup = groupBy(item);

        const existingGroup = acc.find((g) => g.label === itemGroup);

        if (!existingGroup) {
          acc.push({ label: itemGroup, data: [item] });
        } else {
          existingGroup.data.push(item);
        }

        return acc;
      },
      [],
    );

    groupedData.sort(sortGroupBy);

    calculatedData.push(
      ...groupedData.reduce<InternalFlashListItemProps[]>((acc, section) => {
        const itemFormatter = createFormatter({
          data: section.data,
          getItemData,
          sectionLabel: section.label,
        });

        return [
          ...acc,
          { header: true, label: section.label, firstSection: acc.length === 0 },
          ...section.data.map(itemFormatter),
        ];
      }, []),
    );
  } else {
    const itemFormatter = createFormatter({ data, getItemData });

    calculatedData.push(...data.map(itemFormatter));
  }

  return calculatedData;
}
