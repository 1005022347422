// Reference: manage codebase app/models/network_event.rb

export const sensorAlertEventTypes = [
  "sensor_alert",
  "sensor_alert_test",
  "sensor_general_alert_test",
] as const;

export const sensorGeneralEventTypes = [
  "sensor_battery_cover_removed",
  "sensor_battery_cover_replaced",
  "sensor_battery_low",
  "sensor_battery_improved",
  "sensor_magnetic_tampering_detected",
  "sensor_magnetic_tampering_reset",
  "sensor_probe_cable_disconnected",
  "sensor_probe_cable_reconnected",
  "sensor_water_cable_disconnected",
  "sensor_water_cable_reconnected",
] as const;

export const dhcpProblemEventTypes = [
  "dhcp_problem",
  "dhcp_nak",
  "dhcp_no_leases",
  "multiple_dhcp_servers_detected",
  "rogue_dhcp",
] as const;
