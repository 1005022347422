import { ReactNode } from "react";
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

import { StatusType } from "../constants/Status";

export interface BannerAlertType {
  alertType: StatusType;
  alertText: string;
}

interface BannerAlertProps extends BannerAlertType {
  screenStyles?: StyleProp<ViewStyle>;
  viewStyles?: StyleProp<ViewStyle>;
  textAlign?: TextStyle["textAlign"];
  testID?: string;
  customIcon?: ReactNode;
}

const BannerAlert = (props: BannerAlertProps) => {
  const {
    customIcon,
    alertType,
    alertText,
    textAlign = "center",
    screenStyles,
    testID,
    viewStyles,
  } = props;
  const theme = useThemeColors();
  const statusColors = theme.status[alertType] ?? theme.status.alerting;
  const themeStyle = appSelect({ enterprise: styles.enterpriseMargin, go: screenStyles });
  const alertStyle = {
    backgroundColor: statusColors.background,
    borderColor: statusColors.color,
  };
  const icon = customIcon ? (
    customIcon
  ) : (
    <MerakiIcon
      name="exclamation-triangle"
      size={16}
      style={[styles.alertIcon, { color: statusColors.color }]}
    />
  );
  return (
    <View testID={testID} style={[styles.alertContainer, alertStyle, themeStyle, viewStyles]}>
      {icon}
      <MkiText textStyle="small" screenStyles={[styles.alertText, { textAlign }]}>
        {alertText}
      </MkiText>
    </View>
  );
};

const styles = StyleSheet.create({
  alertContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderRadius: 1,
  },
  alertIcon: {
    marginRight: SPACING.default,
  },
  alertText: {
    flexGrow: 1,
    marginRight: SPACING.extraLarge,
    color: MkiColors.textColor,
  },
  enterpriseMargin: {
    marginVertical: SPACING.default,
  },
});

export default BannerAlert;
