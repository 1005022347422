import { I18n } from "@meraki/core/i18n";
import { Heading, Loader } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useCallback, useState } from "react";
import { Image, LayoutChangeEvent, Pressable } from "react-native";
import { useFilePicker } from "use-file-picker";

interface PhotoFile {
  content: string;
  extension: string;
  name: string;
}

interface PhotoSelectProps {
  loading?: boolean;
  disabled?: boolean;
  sourceUri?: string;
  onPhotoSelect?: (args: PhotoFile) => void;
  testID?: string;
}

export const PhotoSelect = ({
  loading,
  disabled,
  sourceUri,
  onPhotoSelect,
  testID,
}: PhotoSelectProps) => {
  const [width, setWidth] = useState<number>(0);

  const { openFilePicker: onPress } = useFilePicker({
    multiple: false,
    readAs: "DataURL",
    accept: "image/*",
    onFilesRejected: ({ errors }) => {
      if (errors.length !== 0) {
        window.alert(errors.join(", "));
      }
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      try {
        const name = plainFiles[0]?.name ?? "SelectedImage";
        const content = filesContent[0]?.content;

        const extension = /[^.]+$/.exec(name)?.[0] ?? "png";

        if (content != null) {
          onPhotoSelect?.({
            content,
            extension,
            name,
          });
        }
      } catch (error) {
        window.alert(error);
      }
    },
  });

  const handleContainerLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width },
      },
    }: LayoutChangeEvent) => {
      setWidth(width);
    },
    [setWidth],
  );

  const height = width * 0.6;
  return (
    <Box testID={testID} onLayout={handleContainerLayout}>
      <Pressable testID={`${testID}.PRESSABLE`} onPress={() => !disabled && onPress()}>
        {loading ? (
          <Loader.Skeleton height={height} />
        ) : sourceUri ? (
          <Box>
            <Image style={{ height, width }} source={{ uri: sourceUri }} resizeMode="contain" />
          </Box>
        ) : (
          <Box
            height={height}
            borderRadius="sm"
            borderWidth="strong"
            borderStyle="dashed"
            borderColor="control.border.base"
            justifyContent="center"
            alignItems="center"
            gap="xs"
          >
            <Icon name="UploadSimple" size={height * 0.1} />
            <Heading size="h3">{I18n.t("PHOTO_UPLOAD.TITLE")}</Heading>
          </Box>
        )}
      </Pressable>
    </Box>
  );
};
