import { useTheme } from "@meraki/core/theme";
import { getClusterOptions } from "@meraki/shared/auth";
import { Clusters } from "@meraki/shared/redux";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import { SPACING } from "~/constants/MkiConstants";
import { NESTED_TABLE_MODAL_ROWTYPE } from "~/constants/RowConstants";
import { masterURL } from "~/env";
import { LoginStackProps } from "~/go/navigation/Types";
import NestedTableModalRow from "~/go/rows/NestedTableModalRow";
import I18n from "~/i18n/i18n";
import { themeColors } from "~/lib/themeHelper";
import { getCurrentCluster } from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { CancelButton } from "../navigation/Buttons";
import { RootState } from "../types/Redux";

type ReduxProps = {
  currentCluster: Clusters;
};

type Props = ForwardedNativeStackScreenProps<LoginStackProps, "ChooseCluster"> &
  ReduxProps &
  BasicActions;

export class ChooseClusterScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
    });
  }

  selectCluster = (selectedCluster: Clusters) => {
    const { navigation, actions } = this.props;

    actions.setCurrentCluster(selectedCluster);
    navigation.goBack();
  };

  renderRow = (rowData: { key: Clusters; cluster: string }) => {
    const { currentCluster } = this.props;
    const { cluster, key: clusterKey } = rowData;
    const rowType =
      clusterKey === currentCluster ? NESTED_TABLE_MODAL_ROWTYPE.isSelected : undefined;
    const onPress = () => this.selectCluster(clusterKey);

    return (
      <NestedTableModalRow
        rowType={rowType}
        onPress={onPress}
        testID={`CHOOSE_${clusterKey}`}
        subtitle={masterURL(clusterKey)}
      >
        {cluster}
      </NestedTableModalRow>
    );
  };

  render() {
    const { theme } = useTheme.getState();
    const themeBackground = themeColors(theme).navigation.backgroundPrimary;
    return (
      <View style={[styles.container, { backgroundColor: themeBackground }]}>
        <MkiText textStyle="heading" screenStyles={styles.header}>
          {I18n.t("LOGIN.CHOOSE_CLUSTER")}
        </MkiText>
        <View style={styles.tableContainer}>
          <MkiTable data={getClusterOptions()} renderRow={this.renderRow} />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  header: {
    paddingLeft: SPACING.default,
    paddingBottom: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    currentCluster: getCurrentCluster(state),
  };
}

export default connect(mapStateToProps, basicMapDispatchToProps)(ChooseClusterScreen);
