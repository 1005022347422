import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const UpdateDynamicDnsSchema = z
  .object({
    prefix: z.string(),
    enabled: z.boolean(),
  })
  .describe("UpdateDynamicDnsSchema");

export const DynamicDnsSchema = UpdateDynamicDnsSchema.extend({
  url: z.string(),
}).describe("DynamicDnsSchema");

export const ApplianceSettingsSchema = z
  .object({
    clientTrackingMethod: z.string(),
    deploymentMode: z.string(),
    dynamicDns: DynamicDnsSchema,
  })
  .describe("ApplianceSettingsSchema");

export const UpdateApplianceSettingsSchema = z
  .object({
    clientTrackingMethod: z.string(),
    deploymentMode: z.string(),
    dynamicDns: UpdateDynamicDnsSchema,
  })
  .describe("UpdateApplianceSettingsSchema");

export type DynamicDns = z.infer<typeof DynamicDnsSchema>;
export type ApplianceSettings = z.infer<typeof ApplianceSettingsSchema>;
export type UpdateApplianceSettings = z.infer<typeof UpdateApplianceSettingsSchema>;

export interface ApplianceSettingsRequest {
  networkId?: string;
}

export interface UpdateApplianceSettingsRequest extends ApplianceSettingsRequest {
  body: UpdateApplianceSettings;
}

function buildAllUrl({ networkId }: ApplianceSettingsRequest) {
  return `/api/v1/networks/${networkId}/appliance/settings`;
}

export const fetchApplianceSettings = ({ networkId }: ApplianceSettingsRequest) => {
  return request(ApplianceSettingsSchema, "GET", buildAllUrl({ networkId }));
};

export const updateApplianceSettings = ({ networkId, body }: UpdateApplianceSettingsRequest) => {
  return request(ApplianceSettingsSchema, "PUT", buildAllUrl({ networkId }), {
    body: JSON.stringify(body),
  });
};

export const useApplianceSettings = createQuery<ApplianceSettingsRequest, ApplianceSettings>({
  baseQueryKey: buildAllUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchApplianceSettings(variables),
  requiredVariables: ["networkId"],
});

export const useUpdateApplianceSettings = createMutation<
  UpdateApplianceSettingsRequest,
  ApplianceSettings,
  APIResponseError
>({
  baseMutationKey: buildAllUrl({ networkId: "{networkId}" }),
  mutationFn: (variables) => updateApplianceSettings(variables),
});
