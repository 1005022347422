import { I18n } from "@meraki/core/i18n";
import { documentationUrl } from "@meraki/go/links";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import InlineAlert from "~/go/components/InlineAlert";
import PingResultDescription, {
  PingResultGroup,
} from "~/go/components/liveTools/ping/PingResultDescription";
import PingResultStats from "~/go/components/liveTools/ping/PingResultStats";
import { getHardwareTypeForLED } from "~/lib/DeviceUtils";
import { getResultGroup, PING_RESULT_GROUP } from "~/lib/PingUtils";
import Device from "~/shared/types/Device";

interface PokeDeviceResultsProps {
  averageLatency?: number;
  device: Device;
  lossRate?: number;
  onRestartPress: () => void;
  onShowSearchSubjectPress: () => void;
  onShowPingHostPress: () => void;
  website: string;
}

interface PokeDeviceResultsStates {
  resultGroup?: PingResultGroup;
  showTroubleshooting: boolean;
}

class PokeDeviceResults extends PureComponent<PokeDeviceResultsProps, PokeDeviceResultsStates> {
  static getDerivedStateFromProps(props: PokeDeviceResultsProps) {
    const { averageLatency, lossRate } = props;
    const resultGroup = getResultGroup(averageLatency, lossRate);

    return { resultGroup };
  }

  constructor(props: PokeDeviceResultsProps) {
    super(props);
    this.state = { showTroubleshooting: true };
  }

  renderTroubleshooting() {
    const { device, onShowSearchSubjectPress, onShowPingHostPress } = this.props;
    const { resultGroup, showTroubleshooting } = this.state;

    let message = I18n.t("LIVE_TOOLS.PINGWEB_DEFAULT");
    let primaryText = I18n.t("LIVE_TOOLS.CONTACT_SUPPORT");
    let primaryButton = onShowSearchSubjectPress;

    if (resultGroup === PING_RESULT_GROUP.ok) {
      switch (getHardwareTypeForLED(device.model)) {
        case "appliance":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.APPLIANCE");
          break;
        case "indoor":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.INDOOR");
          break;
        case "outdoor":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.OUTDOOR");
          break;
        case "switch":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.SWITCH");
          break;
      }

      primaryText = I18n.t("LIVE_TOOLS.CONTACT_SUPPORT");
    } else {
      message = I18n.t("LIVE_TOOLS.PINGWEB_UNACCEPTABLE");
      primaryText = I18n.t("LIVE_TOOLS.TEST_CONNECTION_TO", { name: "Meraki" });
      primaryButton = onShowPingHostPress;
    }

    // TODO: Link to Ping AP specific documentation
    return (
      <InlineAlert
        visible={showTroubleshooting}
        onExit={() => this.setState({ showTroubleshooting: false })}
        alertTitle={I18n.t("LIVE_TOOLS.TROUBLESHOOT_TITLE")}
        alertMessage={message}
        screenStyles={styles.alert}
        primaryButtonText={primaryText}
        secondaryButtonText={I18n.t("LIVE_TOOLS.LEARN_MORE")}
        onPrimaryPress={primaryButton}
        onSecondaryPress={documentationUrl}
      />
    );
  }

  render() {
    const { averageLatency, lossRate, onRestartPress: onButtonPress, website } = this.props;
    const { resultGroup } = this.state;

    const messages = {
      [PING_RESULT_GROUP.ok]: I18n.t("LIVE_TOOLS.RESULT_OK", { name: website }),
      [PING_RESULT_GROUP.loss]: I18n.t("LIVE_TOOLS.RESULT_LOSS", { name: website }),
      [PING_RESULT_GROUP.unacceptable]: I18n.t("LIVE_TOOLS.RESULT_UNACCEPTABLE", { name: website }),
    };

    return (
      <View>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ messages: { ok: string; loss: string; unac... Remove this comment to see the full error message */}
        <PingResultDescription
          {...{
            messages,
            onButtonPress,
            resultGroup,
          }}
        />
        {averageLatency != null && lossRate != null && (
          <PingResultStats averageLatency={averageLatency} lossRate={lossRate} />
        )}
        {this.renderTroubleshooting()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  alert: {
    marginHorizontal: 0,
  },
});

export default PokeDeviceResults;
