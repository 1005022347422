import { I18n } from "@meraki/core/i18n";
import {
  queryClient,
  SwitchQoSRule,
  useDeleteSwitchQoSRule,
  useSwitchQoSRules,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import DefaultHeader from "~/go/components/DefaultHeader";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { SettingsStackProps } from "~/go/navigation/Types";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SwitchQoSRulesList">;

export function SwitchQoSRulesListScreen() {
  const navigation = useNavigation<Props["navigation"]>();
  const networkId = useCurrentNetworkId();
  const [isEditMode, setIsEditMode] = useState(false);
  const { isLoading: isLoadingData, data } = useSwitchQoSRules({ networkId });
  const deleteSwitchQoSRule = useDeleteSwitchQoSRule();
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => setIsEditMode(true)} />;
        } else {
          return <DoneButton onPress={() => setIsEditMode(false)} />;
        }
      },
    });
  }, [isEditMode, navigation]);

  return (
    <FullScreenContainerView withScroll>
      <LoadingSpinner visible={isLoadingData || isLoading} />
      <DefaultHeader
        title={I18n.t("SWITCH_QOS_LIST.HEADER.TITLE")}
        description={I18n.t("SWITCH_QOS_LIST.HEADER.SUBTITLE")}
      />
      <MkiTable<SwitchQoSRule>
        data={{ data: data ?? [] }}
        renderSectionHeader={() => (
          <SectionListHeader
            heading={I18n.t("SWITCH_QOS_LIST.TABLE_TITLE")}
            onPress={() => navigation.navigate("SwitchQoSRule", {})}
            withHorizontalMargin
          />
        )}
        renderRow={(rowData: SwitchQoSRule) => {
          const deleteIcon = (
            <DeleteButton
              show={true}
              onPress={() => {
                setIsLoading(true);
                deleteSwitchQoSRule.mutate(
                  { networkId, ruleId: rowData.id },
                  {
                    onSettled: () => {
                      queryClient.removeQueries(useSwitchQoSRules.queryKeyRoot);
                      setIsLoading(false);
                    },
                  },
                );
              }}
              testID={"deleteTestID"}
            />
          );

          return (
            <SimpleDisclosureRow
              testID={`SWITCH_QOS_RULE_${rowData.id}`}
              icon={isEditMode ? deleteIcon : undefined}
              onPress={() => navigation.navigate("SwitchQoSRule", { rule: rowData })}
              subtitle={I18n.t("SWITCH_QOS_LIST.RULE.SRC_DEST_DSCP", {
                src: rowData.srcPort || rowData.srcPortRange || I18n.t("SWITCH_QOS_LIST.RULE.ANY"),
                dst: rowData.dstPort || rowData.dstPortRange || I18n.t("SWITCH_QOS_LIST.RULE.ANY"),
                dscp: rowData.dscp ?? "",
              })}
            >
              {rowData.vlan
                ? I18n.t("SWITCH_QOS_LIST.RULE.TITLE", {
                    protocol: rowData.protocol,
                    vlan: rowData.vlan,
                  })
                : I18n.t("SWITCH_QOS_LIST.RULE.TITLE_NO_VLAN", { protocol: rowData.protocol })}
            </SimpleDisclosureRow>
          );
        }}
      />
    </FullScreenContainerView>
  );
}
