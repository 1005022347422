import {
  CLEAR_SM_DEVICES,
  SET_SM_CLIENT_INFO,
  SM_DEVICE_LIST_JSON_FAILURE,
  SM_DEVICE_LIST_JSON_REQUEST,
  SM_DEVICE_LIST_JSON_SUCCESS,
  UPDATE_SM_CLIENT_FAILURE,
  UPDATE_SM_CLIENT_REQUEST,
  UPDATE_SM_CLIENT_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, getNetworkHasSM } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function getSMDevices(): AppThunk<Promise<ApiResponseAction<any> | void>> {
  return (dispatch, getState) => {
    const hasSM = getNetworkHasSM(getState());

    if (hasSM) {
      const networkId = currentNetworkState(getState());
      return dispatch({
        [CALL_API]: {
          types: [
            SM_DEVICE_LIST_JSON_REQUEST,
            SM_DEVICE_LIST_JSON_SUCCESS,
            SM_DEVICE_LIST_JSON_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/sm/devices`,
          config: {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            queryParams: {
              fields: [
                "ip",
                "batteryEstCharge",
                "ownerUsername",
                "ownerEmail",
                "lastUser",
                "location",
                "imei",
                "lastConnected",
                "phoneNumber",
                "publicIp",
                "bestLat",
                "bestLon",
              ],
            },
          },
        },
      });
    } else {
      dispatch({
        type: CLEAR_SM_DEVICES,
      });
      return Promise.resolve();
    }
  };
}

export const updateSMClient =
  (client: any): AppThunk<Promise<ReturnType<typeof setSMClientInfo>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [UPDATE_SM_CLIENT_REQUEST, UPDATE_SM_CLIENT_SUCCESS, UPDATE_SM_CLIENT_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sm/devices/fields`,
        config: {
          method: Method.put,
          body: JSON.stringify({
            id: client.id,
            deviceFields: {
              name: client.name,
            },
          }),
        },
      }),
    ).then(() => dispatch(setSMClientInfo(client)));
  };

export function setSMClientInfo(client: any) {
  return {
    type: SET_SM_CLIENT_INFO,
    client,
  };
}
