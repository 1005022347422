import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";
import Light from "~/enterprise/themes/Light";
import ThemeInterface from "~/shared/themes/ThemeInterface";

// empty {} to take component defined styles
const LightDeuteranomaly: ThemeInterface = {
  ...Light,
  status: {
    ...Light.status,
    online: {
      color: EnterpriseColors.greenDtmaly,
      background: EnterpriseColors.lightGreenDtmaly,
    },
  },
  graphColors: {
    ...Light.graphColors,
    goodBar: EnterpriseColors.greenDtmaly,
    online: EnterpriseColors.greenDtmaly,
    lightGreenWarningStatus: EnterpriseColors.lightGreenDtmaly,
    applicationDetailsClientBar: EnterpriseColors.greenDtmaly,
    lgreen: EnterpriseColors.lightGreenDtmaly,
    mgreen: EnterpriseColors.greenDtmaly,
  },
  port: {
    ...Light.port,
    active: EnterpriseColors.greenDtmaly,
  },
  icon: {
    ...Light.icon,
    tabBar: {
      selected: EnterpriseColors.greenDtmaly,
    },
  },
  mapStatusColors: {
    ...Light.mapStatusColors,
    online: EnterpriseColors.greenDtmaly,
  },
  IAQ: {
    ...Light.IAQ,
    excellent: EnterpriseColors.excellentDtmaly,
    good: EnterpriseColors.goodDtmaly,
  },
  accordian: {
    ...Light.accordian,
  },
};

export default LightDeuteranomaly;
