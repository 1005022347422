import Svg, { Circle, G, Path } from "react-native-svg";

const MalwareIcon = (props: any) => (
  <Svg width={76} height={76} viewBox="0 0 76 76" {...props}>
    <G fill="none" fillRule="evenodd">
      <Circle fill="#6665F6" cx={38} cy={37.9} r={37.5} />
      <G transform="translate(18.000000, 14.000000)" id="Path">
        <Path
          d="M36.2,47.8 L3.7,47.8 C2.1,47.8 0.8,46.5 0.8,44.9 L0.8,3 C0.8,1.4 2.1,0.1 3.7,0.1 L27.7,0.1 L33.3,5.9 L39.1,11.9 L39.1,45 C39.1,46.5 37.8,47.8 36.2,47.8 Z"
          fill="#BFCAFF"
        />
        <Path
          d="M36.2,47.8 L3.7,47.8 C2.1,47.8 0.8,46.5 0.8,44.9 L0.8,3 C0.8,1.4 2.1,0.1 3.7,0.1 L27.6,0.1 L27.6,12 L39.1,12 L39.1,44.9 C39.1,46.5 37.8,47.8 36.2,47.8 Z"
          fill="#FFFFFF"
        />
        <Path
          d="M33.9,29.2 L30,28.2 C30,26.5 29.5,24.7 28.7,22.9 L32.5,20.5 C33,20.2 33.2,19.5 32.9,18.9 C32.6,18.4 31.9,18.2 31.3,18.5 L27.4,20.9 C26.3,19.4 24.9,18.2 23.4,17.4 L24.4,13.5 C24.6,12.9 24.2,12.3 23.6,12.1 C23,11.9 22.4,12.3 22.2,12.9 L21.3,16.5 C20.3,16.2 19.3,16.1 18.3,16.1 L17.8,15.3 C16.6,13.4 14.1,12.8 12.2,14 C10.3,15.2 9.7,17.7 10.9,19.6 L11.3,20.2 C10.7,21.1 10.3,22.2 10.1,23.3 L6.6,22.4 C6,22.2 5.4,22.6 5.2,23.2 C5,23.8 5.4,24.4 6,24.6 L9.9,25.6 C9.9,27.3 10.4,29.1 11.2,30.8 L7.3,33.2 C6.8,33.5 6.6,34.2 6.9,34.8 C7.2,35.3 7.9,35.5 8.5,35.2 L12.4,32.8 C13.5,34.3 14.9,35.5 16.4,36.3 L15.4,40.2 C15.2,40.8 15.6,41.4 16.2,41.6 C16.8,41.8 17.4,41.4 17.6,40.8 L18.5,37.2 C21,37.9 23.6,37.7 25.8,36.3 C27.9,35 29.2,32.9 29.7,30.4 L33.2,31.3 C33.8,31.5 34.4,31.1 34.6,30.5 C34.9,30 34.5,29.4 33.9,29.2 Z"
          fillOpacity="0.94"
          fill="#6665F6"
        />
      </G>
    </G>
  </Svg>
);

export default MalwareIcon;
