import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const SEVEN_DAYS = 604800;

interface FailedConnectionsRequest {
  networkId?: string;
}

export interface FailedConnectionsRequestParams extends FailedConnectionsRequest {
  timespan?: number;
}

export const FailedConnectionSchema = z.object({
  clientMac: z.string(),
  failureStep: z.string(),
  radio: z.number(),
  serial: z.string(),
  ssidNumber: z.number(),
  ts: z.string(),
  type: z.string(),
  vlan: z.number(),
});

export const FailedConnectionsSchema = z.array(FailedConnectionSchema);

export type FailedConnection = z.infer<typeof FailedConnectionSchema>;
export type FailedConnectionsResponse = z.infer<typeof FailedConnectionsSchema>;

const buildUrl = ({ networkId }: FailedConnectionsRequest) => {
  return `/api/v1/networks/${networkId}/wireless/failedConnections`;
};

const fetchFailedConnections = ({
  networkId,
  timespan,
}: FailedConnectionsRequestParams): Promise<FailedConnectionsResponse> => {
  const validTimespan = timespan ? (timespan > SEVEN_DAYS ? SEVEN_DAYS : timespan) : undefined;
  return request(FailedConnectionsSchema, "GET", buildUrl({ networkId }), {
    queryParams: { timespan: validTimespan },
  });
};

export const useFailedConnections = createQuery<
  FailedConnectionsRequestParams,
  FailedConnectionsResponse
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: fetchFailedConnections,
  requiredVariables: ["networkId"],
});
