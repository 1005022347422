import { Active } from "./icons/Active";
import { Authenticated } from "./icons/Authenticated";
import { Blocking } from "./icons/Blocking";
import { PassThrough } from "./icons/PassThrough";
import { PlugPort } from "./icons/PlugPort";
import { Stacked } from "./icons/Stacked";
import { Uplink } from "./icons/Uplink";
import { WifiLightning } from "./icons/WifiLightning";

const ICON_MAP = {
  uplink: Uplink,
  passThrough: PassThrough,
  plug: PlugPort,
  active: Active,
  blocking: Blocking,
  authenticated: Authenticated,
  authenticatedDrawing: WifiLightning,
  stacked: Stacked,
};

export type IndicatorProps = {
  indicator: keyof typeof ICON_MAP | undefined | null;
  x: number;
  y: number;
  color: string;
};

export function Indicator({ indicator, x, y, color }: IndicatorProps) {
  if (!indicator) {
    return null;
  }

  const IconComponent = ICON_MAP[indicator];

  return <IconComponent x={x} y={y} color={color} />;
}
