import { TranslationKeys } from "@meraki/core/i18n";

export const MS_IN_A_SECOND = 1000;

export const TWO_HOURS = {
  label: "TIMESPANS.TWO_HOURS" as TranslationKeys,
  value: 7200 as number, // 2 hours in seconds
} as const;
export const DAY = {
  label: "TIMESPANS.DAY" as TranslationKeys,
  value: 86400 as number, // 1 day in seconds
};
export const WEEK = {
  label: "TIMESPANS.WEEK" as TranslationKeys,
  value: 604800 as number, // 1 week in seconds
} as const;
export const MONTH = {
  label: "TIMESPANS.MONTH" as TranslationKeys,
  value: 2592000 as number, // 1 month in seconds
} as const;
export const CUSTOM = {
  label: "TIMESPANS.CUSTOM" as TranslationKeys,
  value: null,
} as const;

export const DAYS_IN_A_YEAR = 365;

export const SECONDS_IN_A_DAY = 86400;

export const twoHours = 7200;
export const twentyFourHours = 86400;
export const sevenDays = 604800;
export const oneMonth = 2592000;
export type TimeSpan =
  | typeof twoHours
  | typeof twentyFourHours
  | typeof sevenDays
  | typeof oneMonth;

export const oneHour = 3600;
export const tenMinutes = 600;

export const defaultTimespans = { TWO_HOURS, DAY, WEEK, MONTH };
export type DefaultTimespansKeys = keyof typeof defaultTimespans;
export const defaultTimespansWithCustom = { ...defaultTimespans, CUSTOM };

export const calculateOneMonthAgoInSeconds = () => Date.now() / MS_IN_A_SECOND - MONTH.value;
