import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

const buildUrl = ({ serial }: RebootRequest) => {
  return `/api/v1/devices/${serial}/reboot`;
};

interface RebootRequest {
  serial: string;
}

export const RebootSchema = z.object({ success: z.boolean() });

export type Reboot = z.infer<typeof RebootSchema>;

const callReboot = ({ serial }: RebootRequest) => {
  return request(RebootSchema, "POST", buildUrl({ serial }));
};

export const useReboot = createMutation({
  baseMutationKey: buildUrl({ serial: `{serial}` }),
  mutationFn: (parameters: RebootRequest) => callReboot({ ...parameters }),
});
