import { queryClient } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useMutation, useQuery } from "@tanstack/react-query";

import ssidKeys from "~/api/queries/ssids/keys";
import {
  DeleteIdentityPskSchema,
  IdentityPsk,
  IdentityPskInput,
  IdentityPskInputSchema,
  IdentityPskResponse,
  IdentityPskResponseSchema,
  IdentityPskSchema,
  IdentityPsksResponse,
  IdentityPsksResponseSchema,
} from "~/api/schemas/IdentityPsk";
import { validatedMerakiRequest } from "~/api/util/request";

const ssidIdentityPskKeys = {
  identityPsk: (ssidNumber: string, identityPskId: string) =>
    [...ssidKeys.IdentityPsk, ssidNumber, identityPskId] as const,
  identityPsks: (ssidNumber: string) => [...ssidKeys.IdentityPsk, ssidNumber] as const,
};

const refreshAllIpsks = (ssidNumber: string) => {
  const keyToAllIpsks = ssidIdentityPskKeys.identityPsks(ssidNumber);
  queryClient.invalidateQueries(keyToAllIpsks);
};

const fetchIpskForSsid = (
  ssidNumber: string,
  identityPskId: string,
  networkId?: string,
): Promise<IdentityPskResponse> => {
  return validatedMerakiRequest(
    IdentityPskResponseSchema,
    "GET",
    `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks/${identityPskId}`,
  );
};

const fetchAllIpsksForSsid = (
  ssidNumber: string,
  networkId?: string,
): Promise<IdentityPsksResponse> => {
  return validatedMerakiRequest(
    IdentityPsksResponseSchema,
    "GET",
    `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks`,
  );
};

export const useGetIpskForSsid = (ssidNumber: string, identityPskId: string) => {
  const networkId = useCurrentNetworkId();

  return useQuery({
    queryKey: ssidIdentityPskKeys.identityPsk(ssidNumber, identityPskId),
    queryFn: () => fetchIpskForSsid(ssidNumber, identityPskId, networkId),
  });
};

export const useGetAllIpsksForSsid = (ssidNumber: string) => {
  const networkId = useCurrentNetworkId();

  return useQuery({
    queryKey: ssidIdentityPskKeys.identityPsks(ssidNumber),
    queryFn: () => fetchAllIpsksForSsid(ssidNumber, networkId),
  });
};

export const useDeleteIpskForSsid = (ssidNumber: string, identityPskId: string) => {
  const networkId = useCurrentNetworkId();

  return useMutation({
    mutationFn: () =>
      validatedMerakiRequest(
        DeleteIdentityPskSchema,
        "DELETE",
        `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks/${identityPskId}`,
      ),
    onSuccess: () => refreshAllIpsks(ssidNumber),
  });
};

export const useCreateIpsksForSsid = (ssidNumber: string) => {
  const networkId = useCurrentNetworkId();

  return useMutation({
    mutationFn: (ipskSetting: IdentityPskInput) =>
      validatedMerakiRequest(
        IdentityPskInputSchema,
        "POST",
        `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks`,
        { body: JSON.stringify(ipskSetting) },
      ),
    onSuccess: () => refreshAllIpsks(ssidNumber),
  });
};

export const useUpdateIpsksForSsid = (ssidNumber: string, identityPskId: string) => {
  const networkId = useCurrentNetworkId();
  const keyToIpsk = ssidIdentityPskKeys.identityPsk(ssidNumber, identityPskId);

  return useMutation({
    mutationFn: (ipskSetting: IdentityPsk) => {
      return validatedMerakiRequest(
        IdentityPskSchema,
        "PUT",
        `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/identityPsks/${identityPskId}`,
        { body: JSON.stringify(ipskSetting) },
      );
    },
    onMutate: async (newIdentityPsk: IdentityPsk) => {
      await queryClient.cancelQueries(keyToIpsk);
      const previousIpsk = queryClient.getQueryData<IdentityPsk>(keyToIpsk);
      queryClient.setQueryData(keyToIpsk, { [newIdentityPsk.id]: newIdentityPsk });

      return { previousIpsk };
    },
    onSuccess: () => refreshAllIpsks(ssidNumber),
    onError: (_error, _variables, context) => {
      if (context?.previousIpsk != null) {
        queryClient.setQueryData(keyToIpsk, context.previousIpsk);
      }
    },
  });
};
