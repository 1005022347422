import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, List, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useVlan } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export function ReservedAddressesListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "ReservedAddressesList">>();
  const { vlanId } = route.params;
  const networkId = useCurrentNetworkId();
  const { data: vlan, isLoading: vlanIsLoading } = useVlan({
    networkId,
    vlanId,
  });

  if (!vlan || vlanIsLoading) {
    return (
      <Screen.View>
        <Loader.Spinner />
      </Screen.View>
    );
  }

  const fixedIpAssignmentsArray = Object.entries(vlan.fixedIpAssignments).map(([key, value]) => ({
    ...value,
    mac: key,
  }));

  return (
    <Screen>
      <Box bottomDividerBorder>
        <Box paddingHorizontal="md" paddingBottom="sm">
          <Heading size="h1">{I18n.t("RESERVED_ADDRESSES.TITLE")}</Heading>
        </Box>
      </Box>

      <Box paddingHorizontal="md">
        <Text size="p1">{I18n.t("RESERVED_ADDRESSES.SUBTITLE")}</Text>

        <Box
          flexDirection="row"
          padding="2xs"
          paddingTop="sm"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="h4">{I18n.t("RESERVED_ADDRESSES.GOv3.IP_RANGES_LABEL")}</Heading>
          <Button
            text={I18n.t("ADD")}
            trailingIcon="Plus"
            kind="tertiary"
            onPress={() => navigation.navigate("ReserveIpAddress", { vlanId: vlan.id })}
            testID="RESERVED_IP.ADD"
          />
        </Box>
        <List.FlashList
          paddingTop="none"
          paddingLeft="none"
          paddingRight="none"
          loading={vlanIsLoading}
          data={vlan.reservedIpRanges}
          getItemData={(reservedIpRange, _, index) => ({
            hidePressable: true,
            title: reservedIpRange.comment,
            description: `${reservedIpRange.start} - ${reservedIpRange.end}`,
            rightAccessory: (
              <Button
                kind="tertiary"
                onPress={() =>
                  navigation.navigate("ReserveIpAddress", {
                    vlanId: vlan.id,
                    reservedIpIndex: index,
                  })
                }
                text={I18n.t("EDIT")}
              />
            ),
            onPress: () =>
              navigation.navigate("ReserveIpAddress", {
                vlanId: vlan.id,
                reservedIpIndex: index,
              }),
            testID: `RESERVED_IP.${index}`,
          })}
          testID="RESERVED_IP_LIST"
          ListEmptyComponent={
            <Card testID="RESERVED_IP_LIST.EMPTY">
              <Text size="p1" color="light">
                {I18n.t("NOT_ADDED")}
              </Text>
            </Card>
          }
        />
        <Box flexDirection="row" padding="2xs" justifyContent="space-between" alignItems="center">
          <Heading size="h4">{I18n.t("RESERVED_ADDRESSES.GOv3.DEVICES_LABEL")}</Heading>
          <Button
            text={I18n.t("ADD")}
            trailingIcon="Plus"
            kind="tertiary"
            onPress={() => navigation.navigate("ReserveDevice", { vlan })}
            testID="DEVICE_RESERVATIONS.ADD"
          />
        </Box>
        <List.FlashList
          paddingTop="none"
          paddingLeft="none"
          paddingRight="none"
          paddingBottom="none"
          loading={vlanIsLoading}
          data={fixedIpAssignmentsArray}
          getItemData={(fixedIpAssignment) => ({
            hidePressable: true,
            title: fixedIpAssignment.name,
            description: fixedIpAssignment.ip,
            rightAccessory: (
              <Button
                kind="tertiary"
                onPress={() =>
                  navigation.navigate("ReserveDevice", {
                    vlan,
                    mac: fixedIpAssignment.mac,
                  })
                }
                text={I18n.t("EDIT")}
              />
            ),
            testID: `DEVICE_RESERVATION.${fixedIpAssignment.mac}`,
          })}
          testID="DEVICE_RESERVATIONS_LIST"
          ListEmptyComponent={
            <Card testID="DEVICE_RESERVATIONS_LIST.EMPTY">
              <Text size="p1" color="light">
                {I18n.t("NOT_ADDED")}
              </Text>
            </Card>
          }
        />
      </Box>
    </Screen>
  );
}
