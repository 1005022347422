import { I18n } from "@meraki/core/i18n";
import Rate, { AndroidMarket, IConfig } from "react-native-rate";

import { showAlert } from "~/lib/AlertUtils";
import { FIREBASE_EVENTS, sendRatingEvent } from "~/lib/FirebaseUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { navRef } from "~/shared/navigation/NavigationRef";

const ratingOptionsGo = {
  AppleAppID: "1305210299",
  GooglePackageName: "com.meraki.go",
  preferredAndroidMarket: AndroidMarket.Google,
  preferInApp: true,
  openAppStoreIfInAppFails: true,
};

const ratingOptionsEnterprise: IConfig = {
  AppleAppID: "693056161",
  GooglePackageName: "com.meraki.Dashboard",
  preferredAndroidMarket: AndroidMarket.Google,
  // preferInApp and openAppStoreIfInAppFails are
  // broken; see https://github.com/KjellConnelly/react-native-rate/issues/101

  // preferInApp: true,
  // openAppStoreIfInAppFails: true,
};

function makeRequest() {
  const ratingOptions = appSelect({
    enterprise: ratingOptionsEnterprise,
    go: ratingOptionsGo,
  });
  sendRatingEvent(FIREBASE_EVENTS.sentForRating);
  Rate.rate(ratingOptions, () => {
    // There is no way to check that a rating is actually made for tha app,
    // so this callback is not important.
  });
}

export function askForRating() {
  sendRatingEvent(FIREBASE_EVENTS.requestedRating);
  showAlert(
    appSelect({
      enterprise: I18n.t("RATING.FIRST_PROMPT.TITLE.ENTERPRISE"),
      go: I18n.t("RATING.FIRST_PROMPT.TITLE.GO"),
    }),
    I18n.t("RATING.FIRST_PROMPT.MESSAGE"),
    showGoodExperience,
    {
      positiveText: I18n.t("RATING.FIRST_PROMPT.POSITIVE"),
      negativeText: I18n.t("RATING.FIRST_PROMPT.NEGATIVE"),
      onNegativePress: showBadExperience,
      neutralText: I18n.t("RATING.FIRST_PROMPT.NEUTRAL"),
      isRatePrompt: true,
    },
  );
}

function showGoodExperience() {
  sendRatingEvent(FIREBASE_EVENTS.ratedPositiveExperience);
  showAlert(
    I18n.t("RATING.GOOD_EXPERIENCE.TITLE"),
    I18n.t("RATING.GOOD_EXPERIENCE.MESSAGE"),
    makeRequest,
    {
      positiveText: I18n.t("RATING.GOOD_EXPERIENCE.POSITIVE"),
      negativeText: I18n.t("RATING.GOOD_EXPERIENCE.NEGATIVE"),
      isRatePrompt: true,
    },
  );
}

function handleGoBadExperienceNavigation() {
  navRef.navigate("SearchSubject");
}

function handleEnterpriseBadExperienceNavigation() {
  navRef.navigate("Wish", { isRatePrompt: true });
}

function showBadExperience() {
  sendRatingEvent(FIREBASE_EVENTS.ratedNegativeExperience);
  showAlert(
    I18n.t("RATING.BAD_EXPERIENCE.TITLE"),
    appSelect({
      enterprise: I18n.t("RATING.BAD_EXPERIENCE.MESSAGE.ENTERPRISE"),
      go: I18n.t("RATING.BAD_EXPERIENCE.MESSAGE.GO"),
    }),
    () => {
      sendRatingEvent(FIREBASE_EVENTS.sentToSupport);
      appSelect({
        enterprise: handleEnterpriseBadExperienceNavigation,
        go: handleGoBadExperienceNavigation,
      })();
    },
    {
      positiveText: I18n.t("RATING.BAD_EXPERIENCE.POSITIVE"),
      negativeText: I18n.t("RATING.BAD_EXPERIENCE.NEGATIVE"),
      isRatePrompt: true,
    },
  );
}
