import { ReduxAction } from "../actions";
import {
  LOGIN_SUCCESS,
  SET_CURRENT_USER,
  SET_HOME_SSID,
  SET_TIMESPAN,
} from "../constants/ActionTypes";
import { WEEK } from "../constants/Timespan";

export interface UserPreferences {
  homeSSID: number;
}

export interface PreferencesState {
  currentUser?: string;
  timespan: number;
  users: Record<string, UserPreferences>;
}

const ALL_USER_PREFS = [LOGIN_SUCCESS, SET_CURRENT_USER, SET_TIMESPAN];

export const preferencesInitialState: PreferencesState = {
  timespan: WEEK.value,
  users: {},
};

export function preferences(
  state: PreferencesState = preferencesInitialState,
  action: ReduxAction,
) {
  const { currentUser } = state;
  const { type, user: newUser, timespan, meta, homeSSID } = action;

  if (!currentUser && !ALL_USER_PREFS.includes(type)) {
    return state;
  }

  switch (type) {
    case LOGIN_SUCCESS: {
      if (meta.user) {
        return {
          ...state,
          currentUser: meta.user,
        };
      }
      return state;
    }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: newUser,
      };
    case SET_TIMESPAN:
      return { ...state, timespan: timespan };
  }

  if (currentUser === undefined) {
    return state;
  }

  switch (type) {
    case SET_HOME_SSID:
      return {
        ...state,
        users: {
          ...state.users,
          [currentUser]: {
            ...state.users?.[currentUser],
            homeSSID,
          },
        },
      };
    default:
      return state;
  }
}
