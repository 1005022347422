import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

interface ClientsApplicationUsageRequest {
  networkId?: string;
  timespan?: number;
  perPage?: number;
  clients?: string;
}
export const ApplicationUsageSchema = z.object({
  application: z.string().nullable(),
  received: z.number().optional(),
  sent: z.number(),
});
export const ClientsApplicationUsageSchema = z
  .object({
    clientId: z.string(),
    clientIp: z.string().nullable(),
    clientMac: z.string(),
    applicationUsage: z.array(ApplicationUsageSchema),
  })
  .describe("ClientsApplicationUsageSchema");

export type ApplicationUsage = z.infer<typeof ApplicationUsageSchema>;
export const ClientsApplicationUsageArraySchema = z.array(ClientsApplicationUsageSchema);
export type ClientsApplicationUsageType = z.infer<typeof ClientsApplicationUsageSchema>;
export type ClientsApplicationUsageArray = z.infer<typeof ClientsApplicationUsageArraySchema>;

const buildQueryUrl = ({ networkId }: ClientsApplicationUsageRequest) => {
  return `/api/v1/networks/${networkId}/clients/applicationUsage`;
};

const fetchClientsApplicationUsageData = ({
  networkId,
  timespan,
  perPage,
  clients,
}: ClientsApplicationUsageRequest) => {
  const queryParams = { clients, timespan, perPage: perPage ?? 1000 };
  if (!queryParams.timespan) {
    delete queryParams.timespan;
  }
  return request(ClientsApplicationUsageArraySchema, "GET", buildQueryUrl({ networkId }), {
    queryParams,
  });
};

export const useNetworkClientsApplicationUsage = createQuery<
  ClientsApplicationUsageRequest,
  ClientsApplicationUsageArray
>({
  baseQueryKey: buildQueryUrl({ networkId: "{network_id}" }),
  queryFn: (variables) => fetchClientsApplicationUsageData(variables),
  requiredVariables: ["networkId"],
});
