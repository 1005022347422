import * as Traceroute from "../handlers/commandstream/Traceroute";
import LiveTool from "../types/LiveToolMsg";
import useCtxAwareAggregation from "./useCtxAwareAggregation";
import useLiveBroker from "./useLiveBroker";

const aggregateTracerouteChunks = (
  accumulatedResult?: string,
  formattedMsg?: string,
): string | undefined => {
  if (!formattedMsg) {
    return undefined;
  }

  if (!accumulatedResult) {
    return formattedMsg;
  }

  return accumulatedResult?.concat(formattedMsg);
};

export default function useTraceroute(
  deviceId: string,
  uplinkInterface: Traceroute.Props["interface"],
  host: Traceroute.Props["host"],
  ipVersion: Traceroute.Props["v"] = 4,
  numCycles?: Traceroute.Props["c"],
): LiveTool<string> {
  const msg = useLiveBroker<Traceroute.Props, Traceroute.Message>({
    type: "CommandStream",
    command: numCycles ? "mtr" : "traceroute",
    deviceId,
    interface: uplinkInterface,
    host,
    v: ipVersion,
    c: numCycles,
  });

  return useCtxAwareAggregation(msg, Traceroute.formatMsg, aggregateTracerouteChunks);
}
