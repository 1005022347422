import { Ssid } from "@meraki/shared/api";
import { createContext, useContext } from "react";

export type blockSheetActions = "open" | "close";

type SSIDContextValue = {
  ssid?: Ssid;
  goToTab?: (key: string) => void;
  isLoading: boolean;
};

export const SSIDContext = createContext<SSIDContextValue>({
  goToTab: () => undefined,
  isLoading: false,
});

export function useSSIDContext() {
  return useContext(SSIDContext);
}
