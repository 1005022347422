import { I18n } from "@meraki/core/i18n";
import { useUpdateSiteToSiteVPNSettings } from "@meraki/shared/api";
import { calculateSubnetRange, doesSubnetRangeOverlaps } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import { SettingsStackProps } from "~/go/navigation/Types";
import { Modes } from "~/go/types/SiteToSiteVPN";
import { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { getAllVlans, getSiteToSiteSettingsByNetworkId } from "~/selectors";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import SwitchRow from "~/shared/rows/SwitchRow";

enum SubnetType {
  vlan = "vlan",
  clientVPN = "clientVPN",
}

interface SubnetSettings {
  [key: string]: {
    type: SubnetType;
    useVpn: boolean;
  };
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "SiteToSiteVPNSettings"> &
  PendingComponent;

const SiteToSiteVPNSettingsScreen = ({
  originalSettings,
  ipSecSettings,
  handleError,
  setReqPending,
  reqPending,
}: Props) => {
  const { getVlans, fetchAllSiteToSiteVPNSettings } = useActions();
  const { cancelablePromise } = useCancelablePromise();
  const networkId = useCurrentNetworkId();
  const vlans = useAppSelector(getAllVlans);

  const siteToSiteSettings = useAppSelector(getSiteToSiteSettingsByNetworkId);
  const currentNetwork = useCurrentNetworkId();

  const updateSiteToSiteVPNSettings = useUpdateSiteToSiteVPNSettings();
  const navigation = useNavigation<Props["navigation"]>();

  const [originalSubnetSettings, setOriginalSubnetSettings] = useState({});

  const [enabled, setEnabled] = useState(originalSettings?.mode === Modes.hub);
  const [isEnabledChanged, setIsEnabledChanged] = useState(false);
  const [subnetSettings, setSubnetSettings] = useState<SubnetSettings>(originalSubnetSettings);
  const subnetRangesForOtherNetworks: number[][] = useMemo(() => [], []);
  Object.entries(siteToSiteSettings).flatMap(([networkId, settings]) => {
    if (networkId !== currentNetwork && settings.subnets) {
      settings.subnets.forEach((subnet) => {
        if (subnet.useVpn) {
          const subnetRange = calculateSubnetRange(subnet.localSubnet);
          if (subnetRange != null) {
            subnetRangesForOtherNetworks.push(subnetRange);
          }
        }
      });
    }
  });

  useEffect(() => {
    const newOriginalSubnetSettings: SubnetSettings = {};
    for (const vlan of vlans) {
      newOriginalSubnetSettings[vlan.subnet] = {
        type: SubnetType.vlan,
        useVpn: false,
      };
    }
    if (ipSecSettings?.enabled && ipSecSettings?.ipv4?.subnet != null) {
      newOriginalSubnetSettings[ipSecSettings.ipv4.subnet] = {
        type: SubnetType.clientVPN,
        useVpn: false,
      };
    }
    if (originalSettings?.subnets != null) {
      for (const { localSubnet, useVpn } of originalSettings.subnets) {
        newOriginalSubnetSettings[localSubnet] = {
          ...newOriginalSubnetSettings[localSubnet],
          useVpn: useVpn,
        };
      }
    }
    setOriginalSubnetSettings(newOriginalSubnetSettings);
  }, [ipSecSettings?.enabled, ipSecSettings?.ipv4?.subnet, originalSettings?.subnets, vlans]);

  const getActiveSubnetsAddresses = (subnetSettings: SubnetSettings) => {
    const active: string[] = [];
    Object.entries(subnetSettings).forEach(([subnet, { useVpn }]) => {
      if (useVpn) {
        active.push(subnet);
      }
    });
    return active;
  };

  const updateEnabled = () => {
    setEnabled(!enabled);
    setIsEnabledChanged(!isEnabledChanged);
  };

  const checkSubnetAddresses = useCallback(() => {
    // Check if subnets in this request are valid for change first
    // filter out the subnets that are being set to true now
    const activeSubnetAddresses = getActiveSubnetsAddresses(subnetSettings);

    // Get ranges for other subnets

    for (const addr of activeSubnetAddresses) {
      const currentRange = calculateSubnetRange(addr);
      for (const range of subnetRangesForOtherNetworks) {
        if (currentRange && range && doesSubnetRangeOverlaps(currentRange, range)) {
          return false;
        }
      }
    }
    return true;
  }, [subnetRangesForOtherNetworks, subnetSettings]);

  const save = useCallback(() => {
    if (!checkSubnetAddresses()) {
      const error = I18n.t("SITE_TO_SITE_VPN.SUBNET_OVERLAP_ERROR");

      showAlert("Error", error);
      return;
    }
    setReqPending(true);
    updateSiteToSiteVPNSettings.mutate(
      {
        networkId,
        params: {
          mode: enabled ? Modes.hub : Modes.none,
          subnets: Object.entries(subnetSettings).map(([localSubnet, { useVpn }]) => ({
            localSubnet,
            useVpn,
          })),
        },
      },
      {
        onSuccess: () => {
          if (!enabled) {
            const newSubnetSettings = { ...subnetSettings };
            for (const subnet of Object.keys(newSubnetSettings)) {
              newSubnetSettings[subnet].useVpn = false;
            }

            setSubnetSettings(newSubnetSettings);
          }
          navigation.goBack();
        },
        onError: (error) => handleError(error),
        onSettled: () => setReqPending(false),
      },
    );
  }, [
    checkSubnetAddresses,
    enabled,
    handleError,
    navigation,
    networkId,
    setReqPending,
    subnetSettings,
    updateSiteToSiteVPNSettings,
  ]);

  const hasNoChanges =
    !isEnabledChanged &&
    JSON.stringify(getActiveSubnetsAddresses(subnetSettings).sort()) ===
      JSON.stringify(getActiveSubnetsAddresses(originalSubnetSettings).sort());

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (!hasNoChanges) {
              showSaveWarning(save, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={save} disabled={hasNoChanges} />,
    });
  }, [hasNoChanges, isEnabledChanged, navigation, originalSubnetSettings, save, subnetSettings]);

  useEffect(() => {
    setReqPending(true);
    cancelablePromise(Promise.all([getVlans(), fetchAllSiteToSiteVPNSettings()]))
      .catch(handleError)
      .finally(() => setReqPending(false));
  }, [cancelablePromise, fetchAllSiteToSiteVPNSettings, getVlans, handleError, setReqPending]);

  useEffect(() => {
    setSubnetSettings(originalSubnetSettings);
  }, [originalSubnetSettings]);

  const updateSubnetSettings = useCallback(
    (subnet: any) => {
      setSubnetSettings({
        ...subnetSettings,
        [subnet]: {
          ...subnetSettings[subnet],
          useVpn: !subnetSettings[subnet].useVpn,
        },
      });
    },
    [subnetSettings],
  );

  const renderRow = (subnet: string, rowId: number) => {
    const { type, useVpn } = subnetSettings[subnet];

    return (
      <ListRow
        icon={
          <View style={styles.subnetIcon}>
            <MerakiIcon
              name={type === SubnetType.clientVPN ? "vpn-lock" : "sitemap"}
              size="m"
              color={MkiColors.goPurple}
            />
          </View>
        }
        subtitle1={
          type === SubnetType.clientVPN
            ? I18n.t("SITE_TO_SITE_VPN.SUBNET.CLIENT_VPN")
            : I18n.t("SITE_TO_SITE_VPN.SUBNET.VLAN")
        }
        rightStyle={styles.checkBoxContainer}
        accessory={<CheckBox selected={useVpn} testID={`SITE_TO_SITE_VPN.SUBNET_ROW_${rowId}`} />}
        onPress={() => updateSubnetSettings(subnet)}
        key={`SITE_TO_SITE_VPN.SUBNET_ROW_${rowId}`}
      >
        {subnet}
      </ListRow>
    );
  };

  return (
    <FullscreenContainerView>
      <SwitchRow
        value={enabled}
        onValueChange={updateEnabled}
        testID={`SITE_TO_SITE_VPN.${enabled ? "ENABLED" : "DISABLED"}`}
      >
        {I18n.t("SITE_TO_SITE_VPN.ENABLED")}
      </SwitchRow>
      <SafeAreaView style={styles.subnetContainer}>
        <MkiText textStyle="tableHeader" screenStyles={styles.subnetHeader}>
          {I18n.t("SITE_TO_SITE_VPN.SUBNET.HEADER")}
        </MkiText>
        <FlatList
          style={styles.subnetList}
          data={Object.keys(subnetSettings)}
          renderItem={({ item, index }) => renderRow(item, index)}
          keyExtractor={(_, index) => index.toString()}
        />
      </SafeAreaView>
      <LoadingSpinner visible={reqPending} />
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  subnetContainer: {
    paddingHorizontal: SPACING.small,
    paddingBottom: SPACING.default,
    maxHeight: "80%",
  },
  subnetHeader: {
    paddingHorizontal: SPACING.small,
  },
  subnetList: {
    flexGrow: 0,
    flexShrink: 0,
  },
  subnetIcon: {
    paddingVertical: SPACING.meager,
    paddingHorizontal: SPACING.small,
  },
  checkBoxContainer: {
    paddingRight: SPACING.large,
  },
});

export default SiteToSiteVPNSettingsScreen;
