import numeral from "@elastic/numeral";
import { formatDate } from "@meraki/core/date";
import { currentLanguage, I18n } from "@meraki/core/i18n";
import { DAY } from "@meraki/shared/redux";
import { parse, sub } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { isEmpty } from "lodash";
import * as RNLocalize from "react-native-localize";

import { isString } from "~/lib/TypeHelper";

const FORMATTED_REGEX = /^(\d+\.*\d)(\w+)$/;
const TEN_THOUSAND = 10000;
const CLIENT_VPN = "client_vpn";

const PORT_REGEX = /^(port)?(\d+)$/;
export const formatUseBlocks = (usage?: number[][], milliseconds = false) =>
  usage
    ? usage.map((u) => ({
        x: milliseconds ? u[0] / 1000 || 0 : u[0] || 0,
        y: (u[1] || 0) + (u[2] || 0),
      }))
    : [];

// yKey is your primaryData key
// zKey is your secondaryData key
export const addZeroes = (
  usage: any,
  t0: any,
  t1: any,
  xKey: any,
  yKey: any,
  zKey = undefined,
  milliseconds = false,
) => {
  if (isEmpty(usage)) {
    return usage;
  }
  const t0Adjusted = milliseconds ? t0 : t0 * 1000;
  const t1Adjusted = milliseconds ? t1 : t1 * 1000;
  const firstItem = usage[0];
  if (firstItem[xKey] > t0Adjusted) {
    // Our t0 occurs before recorded data starts
    // Let's add a zero at t0
    if (zKey) {
      usage.unshift({ [xKey]: t0Adjusted, [yKey]: 0, [zKey]: 0 });
    } else {
      usage.unshift({ [xKey]: t0Adjusted, [yKey]: 0 });
    }
  }
  const lastItem = usage[usage.length - 1];
  if (lastItem[xKey] < t1Adjusted) {
    // Our t1 occurs after recorded data ends
    // Let's add a zero at t1
    if (zKey) {
      usage.push({ [xKey]: t1Adjusted, [yKey]: 0, [zKey]: 0 });
    } else {
      usage.push({ [xKey]: t1Adjusted, [yKey]: 0 });
    }
  }
  return usage;
};

export const chartDateFormatter = (
  timestamp: any,
  timespan: any,
  timezone: any,
  milliseconds = false,
) => {
  if (timespan > DAY.value) {
    return formatDate(timestamp, { dateFormat: "shortDate", unixTimestamp: !milliseconds });
  }
  return formatDate(timestamp, {
    dateFormat: "hide",
    timeFormat: "shortTime",
    unixTimestamp: !milliseconds,
    timeZone: timezone,
  });
};

export const formatDateString = (dateString: any, timezone: any) => {
  // for date strings YYYY/DD/MM HH:mm:ss +0000
  const dateSearch = new RegExp("/", "g");
  const formattedForDateParser = dateString.replace(dateSearch, "-").split(" +")[0];
  const cleanedDate = new Date(Date.parse(formattedForDateParser)).getTime() / 1000;

  return formatDate(cleanedDate, {
    dateFormat: "date",
    timeFormat: "shortTime24HourForce",
    timeZone: timezone,
  });
};

// copied from l3_datatypes.js
/** @brief Returns the greatest index in @a x where @a time could be inserted.
 * @returns The lowest index @a i where @a x[@a i][0] > @a time, or
 *   @a x.length if all elements of @a x are <= @a time.
 * @invariant 0 <= @a i <= @a x.length
 * Takes O(log x.length) time. */
function timeseries_upper_bound(x: any, time: any) {
  let l = 0,
    r = x.length,
    m;
  while (l < r) {
    m = l + ((r - l) >> 1);
    if (x[m][0] <= time) {
      l = m + 1;
    } else {
      r = m;
    }
  }
  return l;
}

// copied from l3.js
// @ts-expect-error TS(7030) FIXME: Not all code paths return a value.
export function useblocksGraphseries(blocks, t0, t1, totals?: any) {
  let i, // index into blocks
    b, // current block
    bt0,
    bt1,
    bt, // limits and width of current block
    prevbt0,
    prevbt, // left limit and width of previous block
    height,
    prevheight, // height of current/previous block
    r,
    s,
    frac, // received/sent amounts
    tr,
    ts, // total received/sent amounts
    x,
    y; // temporary

  if (!blocks || blocks.length < 2) {
    return [
      [t0 * 1000, 0],
      [t1 * 1000, 0],
    ];
  }

  i = Math.max(timeseries_upper_bound(blocks, t0) - 1, 1);

  bt0 = blocks[i - 1][0];
  b = blocks[i];
  bt1 = b[0];
  bt = bt1 - bt0;

  tr = ts = 0;
  prevbt = bt + 1; // we treat "prevbt == bt" specially
  prevbt0 = bt0 - prevbt;
  prevheight = 0;
  const data = [[(prevbt0 + prevbt / 2) * 1000, 0]]; // result

  const max_area_error_factor = (t1 - t0) / 64;

  while (1) {
    if (bt == 0) {
      throw new Error("bad blockseries");
    }

    r = b[1] || 0;
    s = b[2] || 0;

    // update totals, calculate height [8192 translates KB to b/s]
    if (r || s) {
      if (bt0 < t0 || bt1 > t1) {
        frac = (Math.min(bt1, t1) - Math.max(bt0, t0)) / bt;
        if (frac >= 0) {
          tr += r * frac;
          ts += s * frac;
        }
      } else {
        tr += r;
        ts += s;
      }
      height = ((r + s) * 8192) / bt;
    } else {
      height = 0;
    }

    const is_last_point = bt0 >= t1 || (i >= blocks.length && height == 0);

    // The basic graphing algorithm draws lines between the midpoints of
    // blocks.  But say you have a short tall block followed by a wide
    // short block.  Simply drawing a line between their midpoints would
    // greatly overestimate the amount of traffic.  In this fix, we limit
    // the area of "error" relative to a block-only display to a maximum
    // value.
    //
    //       __*__  The square wave shows the useblocks
    //      | /     Stars are useblock midpoints
    //      |/      The sum of the triangles is the "error"
    //     /|       Note that total error over both useblocks is 0.
    //    / |       If not aggregating, just limit the distance between
    // __*__|       stars to the min of the two useblock widths and
    //              1/64 the graph width.
    if (prevheight != height) {
      x = Math.min(prevbt, bt, max_area_error_factor);

      y = (bt0 - x / 2) * 1000;
      if (y > data[data.length - 1][0]) {
        data.push([y, prevheight]);
      }

      data.push([(bt0 + x / 2) * 1000, height]);
    } else if (is_last_point) {
      // for the last point don't use times in the future
      data.push([bt0 * 1000, height]);
    }
    // add midpoint of current block
    else {
      data.push([(bt0 + bt / 2) * 1000, height]);
    }

    if (is_last_point) {
      if (totals) {
        totals[0] = tr;
        totals[1] = ts;
      }
      return data;
    }

    // move to next block
    ++i;
    prevheight = height;
    prevbt = bt;
    prevbt0 = bt0;
    bt0 = bt1;
    b = blocks[i] || [bt0 + prevbt];
    bt1 = b[0];
    bt = bt1 - bt0;
  }
}

export const formatTransferBits = (bits: any, format = "0.[0] bitd") =>
  numeral(bits).format(format).replace("bit", "b").replace("k", "K");

export const formatPercent = (fraction: any) =>
  numeral(fraction || 0).format("0.0%") as `${number}%`;

export const roundTimeToNearestQuarter = (time: any) => {
  const { minutes, hours } = time;
  const newMinutes = ((((minutes + 7.5) / 15) | 0) * 15) % 60;
  const newHours = (((minutes / 105 + 0.5) | 0) + hours) % 24;
  return { hours: newHours, minutes: newMinutes };
};

export const formatTimeStringToDate = (time: any) => {
  const [currentHourStr, rest] = time.split(":");
  const [currentMinute, meridian] = rest.split(" ");
  const currentHour =
    parseInt(currentHourStr === "12" ? "0" : currentHourStr) +
    (meridian.toLowerCase() === "pm" ? 12 : 0);
  const hourTime = `${currentHour < 10 ? "0" : ""}${currentHour}:${currentMinute}:00`;
  return zonedTimeToUtc(new Date(`1970-01-01 ${hourTime}`), RNLocalize.getTimeZone());
};

export const convert24HourTo12Hour = (t: any) =>
  formatDate(parse(t, "HH:mm:ss", new Date()), {
    dateFormat: "hide",
    timeFormat: "shortTime",
    timeZone: RNLocalize.getTimeZone(),
  });
export const formatMonthDay = (t: string | Date | number) => {
  return formatDate(t, { dateFormat: "shortDate" });
};
export const formatThousands = (num: any, format = "0.0a") => numeral(num).format(format);

export const formatSummaryValueInThousands = (value: any, units: any) => {
  if (units || !value || value < TEN_THOUSAND) {
    return { formattedValue: value, formattedUnits: units };
  }
  const matcher = formatThousands(value).match(FORMATTED_REGEX);

  if (matcher) {
    const [, number, category] = matcher;
    return { formattedValue: number, formattedUnits: category };
  }

  // TODO: verify if empty string ok here
  return { formattedValue: "", formattedUnits: "" };
};

export const getConnectedNum = (connectedBy: any) => {
  const unknownConnection = I18n.t("CLIENT_DETAILS.CONNECTION.UNKNOWN");
  if (!connectedBy) {
    return unknownConnection;
  }
  if (Number.isInteger(connectedBy)) {
    return connectedBy;
  }
  if (connectedBy === CLIENT_VPN) {
    return I18n.t("CLIENT_DETAILS.CONNECTION.CLIENT_VPN");
  }
  const matches = connectedBy.match(PORT_REGEX);
  if (!matches) {
    return unknownConnection;
  }
  const [, , portNum] = matches;
  return Number(portNum);
};
export const clientWordForCount = (count: any) => {
  if (__MERAKI_GO__ && currentLanguage().includes("ja")) {
    return "";
  }
  return I18n.t(count === 1 ? "DEVICE_WORD" : "DEVICES_WORD");
};
export const portWordForCount = (count: any) =>
  count === 1 ? I18n.t("PORT_WORD") : I18n.t("PORTS_WORD");
export const capitalizeFirstLetter = (string: any) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const andCommaSeparatedString = (stringArray: any) => {
  const { length } = stringArray;
  if (length === 0) {
    return "";
  }
  return stringArray.reduce((builtString: any, currentValue: any, currentIndex: any) => {
    if (length === 2) {
      return `${builtString} ${I18n.t("AND")} ${currentValue}`;
    }
    return (
      builtString + (currentIndex + 1 !== length ? ", " : `, ${I18n.t("AND")} `) + currentValue
    );
  });
};
export const isStringEmpty = (string: any) => isEmpty(string.replace("\n", "").trim());
export const isValidString = (value: any) => isString(value) && !isStringEmpty(value);

export const getTimeAgo = (num: any, resolution = "minutes") => {
  return Math.floor(sub(Date.now(), { [resolution]: num }).valueOf() / 1000);
};

export const addNewlinesWherePeriodCommaDetected = (input: any) =>
  isString(input) ? input.replace(/\.,/g, ".\n\n") : null;

export const translateOptionLabels = (options: any) =>
  options.map(({ label, value }: any) => ({ label: I18n.t(label), value }));
