import { config } from "@meraki/core/dev-config";
import { Clusters, useCurrentCluster, useCurrentShardId } from "@meraki/shared/redux";

let isDev = false;
let user = "meraki";
if (__DEV__) {
  const { useDev, mkiUser } = config;
  isDev = !!useDev;
  user = mkiUser ?? "meraki";
}

export const shardNetworkAuthURL = (
  shardId: number | string | undefined,
  cluster: Clusters,
): string => {
  switch (cluster) {
    case "sandbox":
      return `https://n${shardId}.sandbox.network-auth.com`;
    case "china":
      return `https://n${shardId}.network-auth.cn`;
    case "devel":
      return `https://n${shardId}.meraki.dev.network-auth.ikarem.io`;
    default:
      return isDev
        ? `https://n${shardId}.${user}.dev.network-auth.ikarem.io`
        : `https://n${shardId}.network-auth.com`;
  }
};

export const useNetworkAuth = (endpoint: string) => {
  const shardId = useCurrentShardId();
  const cluster = useCurrentCluster();

  return shardNetworkAuthURL(shardId, cluster) + endpoint;
};

// All Meraki Go customers use this theme. This doesn't need any changes unless
// we add theme select/creation in the future to Go (no current plans to do so).
export const SPLASH_THEME_ID = "6961b3f08ec7dcb6eaa3fb0814e4722e4a83b6b8";
export const useSplashPagePreview = (
  organizationName?: string,
  encryptedNetworkId?: string,
  ssidNumber?: number,
  splashThemeId: string = SPLASH_THEME_ID,
) => {
  return useNetworkAuth(
    `/${organizationName}/n/${encryptedNetworkId}/splash/preview/${ssidNumber}/${splashThemeId}`,
  );
};
