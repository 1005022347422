import EnterpriseColors from "~/enterprise/constants/EnterpriseColors";

// https://stackoverflow.com/a/28056903
function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
}

const PRIMARY = EnterpriseColors.purple;

export const TEXT_COLORS = {
  primary: EnterpriseColors.gray20,
  secondary: EnterpriseColors.gray40Darker,
  row: EnterpriseColors.gray30,
  heading: EnterpriseColors.gray30,
  link: EnterpriseColors.purple,
  placeholder: EnterpriseColors.gray40Darker,
  textInput: EnterpriseColors.gray30,
  white: EnterpriseColors.white,
  red: EnterpriseColors.red,
};

export const BORDER_COLORS = {
  light: EnterpriseColors.gray90Darker,
  regular: EnterpriseColors.gray90Darker,
  highlighted: PRIMARY,
};

export const BANNER_COLORS = {
  green: hexToRGBA(EnterpriseColors.green, 0.2),
  yellow: hexToRGBA(EnterpriseColors.yellow, 0.3),
  red: hexToRGBA(EnterpriseColors.red, 0.2),
};

export const CHART_COLORS = {
  lineDefault: EnterpriseColors.blue,
  lineSecondary: EnterpriseColors.lpink,
  lineTertiary: EnterpriseColors.lorange,
  threshold: EnterpriseColors.redDarker,
};

export const DRAWER_ORG_INITIAL_CIRCLE_BG = hexToRGBA(EnterpriseColors.white, 0.26);
export const SPINNER = {
  background: hexToRGBA(EnterpriseColors.white, 0.3),
};

export const SECTION_TAB_COLORS = {
  selected: PRIMARY,
  unselected: EnterpriseColors.gray40Darker,
  underline: EnterpriseColors.gray90Darker,
};

export const BUTTON_COLORS = {
  primary: {
    background: PRIMARY,
    border: PRIMARY,
    text: EnterpriseColors.white,
  },
  secondary: {
    background: EnterpriseColors.white,
    border: EnterpriseColors.purple,
    text: EnterpriseColors.purple,
  },
  tertiary: {
    background: hexToRGBA(EnterpriseColors.blue, 0.05),
    border: EnterpriseColors.purple,
    text: EnterpriseColors.purple,
  },
  warning: {
    background: hexToRGBA(EnterpriseColors.red, 0.05),
    border: EnterpriseColors.red,
    text: EnterpriseColors.red,
  },
  success: {
    background: EnterpriseColors.green,
    border: EnterpriseColors.green,
    text: EnterpriseColors.white,
  },
};

export const RADIO_COLORS = {
  selected: PRIMARY,
  unselected: EnterpriseColors.gray40Darker,
};

export const SLIDER_COLORS = {
  default: PRIMARY,
};

export const PIE_COLORS = {
  lblue: EnterpriseColors.lblue,
  lpink: EnterpriseColors.lpink,
  lgreen: EnterpriseColors.lgreen,
  mblue: EnterpriseColors.mblue,
  lpurple: EnterpriseColors.lpurple,
  morange: EnterpriseColors.morange,
  lorange: EnterpriseColors.lorange,
  mpink: EnterpriseColors.mpink,
  mgreen: EnterpriseColors.mgreen,
  mpurple: EnterpriseColors.mpurple,
  unknown: EnterpriseColors.gray70Lighter,
};

export type ButtonColorScheme = keyof typeof BUTTON_COLORS;

export const NAV_BAR_BUTTON_TINT = PRIMARY;

export const SCREEN_BACKGROUND_COLORS = {
  dark: EnterpriseColors.gray30,
  light: EnterpriseColors.white,
};

export const SEARCH_BARS = {
  dark: {
    background: EnterpriseColors.gray30,
    clearIcon: EnterpriseColors.gray30,
    clearIconBackground: EnterpriseColors.gray40Darker,
    iconColor: EnterpriseColors.white,
    inputBackground: EnterpriseColors.gray30,
    placeholder: EnterpriseColors.gray70Lighter,
    text: EnterpriseColors.white,
  },
  light: {
    background: EnterpriseColors.white,
    clearIcon: EnterpriseColors.gray40Darker,
    clearIconBackground: EnterpriseColors.white,
    iconColor: EnterpriseColors.gray40Darker,
    inputBackground: EnterpriseColors.white,
    placeholder: TEXT_COLORS.secondary,
    text: TEXT_COLORS.primary,
  },
};

export const SECTION_HEADERS = {
  dark: {
    backgroundColor: SCREEN_BACKGROUND_COLORS.dark,
  },
  light: {
    backgroundColor: SCREEN_BACKGROUND_COLORS.light,
  },
};

export const EXPORT_ITEM = {
  yellow: EnterpriseColors.yellowLighter,
  darkGray: EnterpriseColors.gray40Darker,
  darkerGray: EnterpriseColors.gray20,
  lightGray: EnterpriseColors.gray70Lighter,
  faint: EnterpriseColors.gray30,
  fill: EnterpriseColors.neonGreen,
  background: EnterpriseColors.white,
  blue: EnterpriseColors.blue,
  faintGreen: EnterpriseColors.lgreen,
  red: EnterpriseColors.red,
  darkWhite: EnterpriseColors.gray90Darker,
};

export const SNACKBAR_COLORS = {
  message: EnterpriseColors.white,
  errorMessage: EnterpriseColors.red,
  button: EnterpriseColors.green,
  backGround: EnterpriseColors.gray90Darker,
};

export const STATUS_BAR_BACKGROUND = EnterpriseColors.white;

export const LOGIN_SCREEN_BACKGROUND = EnterpriseColors.gray90;

export const PLACEHOLDER = EnterpriseColors.gray70Lighter;

export const DISCLOSURE_ICON_ALERT = "#706E6B";

export const CLITextBackground = hexToRGBA(EnterpriseColors.gray90Darker, 0.3);

export const DRAWER_ACCORDION = "#353538";

export const DRAWER_PRESSED = "#4f4f52";

export const ROW_PRESSED = EnterpriseColors.gray90Darker;

export const DRAWER_INDICATOR = "#67b346";

export const CHECKBOX_COLOR = PRIMARY;

export const NO_INTERNET_ICON_COLOR = EnterpriseColors.gray40Darker;

export const IAQ_COLORS = {
  excellent: "#41B840",
  good: "#8EBC42",
  fair: "#FFBF3F",
  poor: "#FF9000",
  inadequate: "#DA0000",
};

export const ROI_COLORS = {
  point: "#FFFFFF",
  shape: "rgba(77, 190, 255, 0.5)",
  line: "#FFFFFF",
};
