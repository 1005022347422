import { I18n } from "@meraki/core/i18n";
import { Notification } from "@meraki/magnetic/components";
import { useDeviceStatuses } from "@meraki/shared/api";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useAPBanner } from "../store/useAPBanner";

export function APBanner() {
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();

  const { untestedAPs, removeAllUntestedAps } = useAPBanner();

  const { data: devices } = useDeviceStatuses({
    organizationId,
    networkIds: networkId ? [networkId] : undefined,
    serials: untestedAPs,
  });

  return (
    <Notification.Inline
      status="informational"
      message={
        untestedAPs.length > 1
          ? I18n.t("ACCESS_POINT.BANNER.ONLINE_MANY")
          : I18n.t("ACCESS_POINT.BANNER.ONLINE_SINGULAR", { name: devices?.[0]?.name })
      }
      onDismiss={() => removeAllUntestedAps()}
      onLinkPress={() => navigation.navigate("ApPlacementIntro")}
      analytics={[
        {
          event: "onDismiss",
          eventName: "ap_banner_dismiss",
        },
        {
          event: "onLinkPress",
          eventName: "ap_banner_link_press",
        },
      ]}
      linkText={
        untestedAPs.length > 1
          ? I18n.t("ACCESS_POINT.BANNER.TEST_MANY")
          : I18n.t("ACCESS_POINT.BANNER.TEST_SINGULAR")
      }
    />
  );
}
