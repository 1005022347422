import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const PerSsidSettingsSchema = z.object({
  name: z.string(),
  minBitrate: z.number(),
  bandOperationMode: z.string(),
  bandSteeringEnabled: z.boolean(),
  bands: z.object({
    enabled: z.array(z.string()),
  }),
});

export const RfProfilesSchema = z
  .object({
    id: z.string(),
    networkId: z.string(),
    name: z.string(),
    clientBalancingEnabled: z.boolean(),
    minBitrateType: z.string(),
    bandSelectionType: z.string(),
    apBandSettings: z.object({
      bandOperationMode: z.string(),
      bands: z.object({
        enabled: z.array(z.string()),
      }),
      bandSteeringEnabled: z.boolean(),
    }),
    twoFourGhzSettings: z.object({
      maxPower: z.number(),
      minPower: z.number(),
      minBitrate: z.number(),
      rxsop: z.number().nullable(),
      validAutoChannels: z.array(z.number()),
      axEnabled: z.boolean(),
    }),
    fiveGhzSettings: z.object({
      maxPower: z.number(),
      minPower: z.number(),
      minBitrate: z.number(),
      rxsop: z.number().nullable(),
      validAutoChannels: z.array(z.number()),
      channelWidth: z.string(),
    }),
    sixGhzSettings: z
      .object({
        maxPower: z.number(),
        minPower: z.number(),
        minBitrate: z.number(),
        rxsop: z.number().nullable(),
        validAutoChannels: z.array(z.number()),
        channelWidth: z.string(),
        afcEnabled: z.boolean(),
      })
      .optional(),
    perSsidSettings: z.record(z.string(), PerSsidSettingsSchema),
    transmission: z.object({
      enabled: z.boolean(),
    }),
    flexRadios: z
      .object({
        byModel: z.array(
          z.object({
            model: z.string(),
            bands: z.array(z.string()),
          }),
        ),
      })
      .optional(),
  })
  .describe("RfProfilesSchema");

type RfProfiles = z.infer<typeof RfProfilesSchema>;

interface RfProfilesRequest {
  networkId?: string;
  rfProfileId?: string | null;
}

function buildUrl({ networkId, rfProfileId }: RfProfilesRequest) {
  return `/api/v1/networks/${networkId}/wireless/rfProfiles/${rfProfileId}`;
}

const fetchRfProfiles = ({ networkId, rfProfileId }: RfProfilesRequest): Promise<RfProfiles> =>
  request(RfProfilesSchema, "GET", buildUrl({ networkId, rfProfileId }));

export const useRfProfile = createQuery<RfProfilesRequest, RfProfiles>({
  baseQueryKey: buildUrl({ networkId: "{networkId}", rfProfileId: "{rfProfileId}" }),
  queryFn: (variables) => fetchRfProfiles(variables),
  requiredVariables: ["networkId", "rfProfileId"],
});
