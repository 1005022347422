import { StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import ExpandableCard from "~/shared/components/ExpandableCard";
import MerakiIcon from "~/shared/components/icons";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import Table from "~/shared/components/Table";
import { useTheme } from "~/shared/hooks/useTheme";
import DisclosureRowEnterprise from "~/shared/rows/DisclosureRow";
import DisclosureRowGo from "~/shared/rows/DisclosureRow.go";
import { ExpandableCardKeys } from "~/shared/types/Preferences";

const DisclosureRow = __MERAKI_GO__ ? DisclosureRowGo : DisclosureRowEnterprise;

export const SummaryCardHeadingText = ({ children }: any) => {
  const theme = useTheme();
  return (
    <MkiText
      textStyle="subheading"
      screenStyles={themeColors(theme).text?.subheading}
      testID={"EXPANDABLE_SUMMARY_CARD_HEADER"}
    >
      {children}
    </MkiText>
  );
};

export const renderLoading = () => (
  <View style={styles.loadingContainer} testID={"EXPANDABLE_SUMMARY_CARD_LOADING"}>
    <MkiSpinner />
  </View>
);

const renderSubheading = (subheading: string) => (
  <View style={styles.subheading}>
    <MkiText textStyle="small" screenStyles={styles.subheadingText}>
      {subheading}
    </MkiText>
  </View>
);

interface DisclosureRowProps {
  label: string;
  onPress?: () => void;
  testID?: string;
}

const renderDisclosureRows = (disclosureRows?: DisclosureRowProps[]) => {
  if (!disclosureRows || disclosureRows.length === 0) {
    return null;
  }

  const renderRow = (rowData: DisclosureRowProps) => (
    <DisclosureRow onPress={rowData.onPress} isFullButton testID={`${rowData.testID}`}>
      {rowData.label}
    </DisclosureRow>
  );

  return (
    <Table
      entries={disclosureRows}
      renderRow={renderRow}
      separators={false}
      listStyles={styles.disclosureStyles}
    />
  );
};

interface ExpandableSummaryCardProps {
  heading?: React.ReactNode | string;
  subheading?: string;
  context?: string;
  savePreferencesKey?: ExpandableCardKeys;
  loading?: boolean;
  children: React.ReactNode;
  disclosureRows?: DisclosureRowProps[];
  contentContainerStyles?: ViewStyle;
  dragging?: boolean;
  testID?: string;
}

const ExpandableSummaryCard = (props: ExpandableSummaryCardProps) => {
  const { heading, subheading, context, dragging } = props;

  const theme = useTheme();
  const chosenThemeColors = themeColors(theme);

  const { savePreferencesKey, loading, children, disclosureRows, contentContainerStyles, testID } =
    props;

  const renderHeader = () => {
    const headingContent =
      typeof heading === "string" ? (
        <View style={styles.headerContainer} testID="EXPANDABLE_SUMMARY_CARD_HEADER_CONSTAINER">
          {dragging == null ? null : (
            <MerakiIcon
              name="drag-indicator"
              size="s"
              color={chosenThemeColors.text.default.color}
              style={styles.dragIconContainer}
              containerStyle={styles.dragIconContainer}
              testID="DRAG_INDICATOR"
            />
          )}
          <SummaryCardHeadingText>{heading}</SummaryCardHeadingText>
          {typeof context === "string" && <ContextHelp context={context} />}
        </View>
      ) : (
        heading
      );
    const headingStyle = subheading ? styles.headingWithSubheading : styles.heading;

    return (
      <View style={styles.headingContainer}>
        <View style={headingStyle}>{headingContent}</View>
        {subheading ? renderSubheading(subheading) : null}
      </View>
    );
  };

  const backgroundColor = chosenThemeColors.navigation.backgroundPrimary;
  const containerStyle = [{ backgroundColor }, styles.container];

  const contentContainerStyle = appSelect({
    enterprise: styles.enterpriseContentContainer,
    go: [styles.goContentContainer, { borderColor: chosenThemeColors.border?.borderColor }],
  });

  return (
    <View style={containerStyle} testID={testID}>
      <ExpandableCard
        noMargin={true}
        renderHeader={renderHeader}
        savePreferencesKey={savePreferencesKey}
        alwaysUnexpended={dragging}
      >
        <View style={[contentContainerStyle, contentContainerStyles]}>
          {loading ? renderLoading() : children}
        </View>
        {!loading ? renderDisclosureRows(disclosureRows) : null}
      </ExpandableCard>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: SPACING.small,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 24,
  },
  enterpriseContentContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
  },
  goContentContainer: {
    marginHorizontal: SPACING.default,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingBottom: SPACING.default,
  },
  headingContainer: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  heading: {
    marginBottom: appSelect({
      enterprise: 10,
      go: SPACING.small,
    }),
  },
  headingWithSubheading: {
    marginBottom: SPACING.tiny,
  },
  subheading: {
    marginBottom: SPACING.small,
  },
  subheadingText: {
    color: MkiColors.secondaryTextColor,
  },
  disclosureStyles: {
    borderTopWidth: 0,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    ...appSelect({
      go: {},
      enterprise: {
        justifyContent: "space-between",
      },
    }),
    ...platformSelect({
      ios: {
        marginVertical: -SPACING.tiny,
      },
      android: {},
    }),
    minHeight: SPACING.large,
  },
  dragIconContainer: {
    marginHorizontal: 0,
  },
});

export default ExpandableSummaryCard;
