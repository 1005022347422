import { I18n } from "@meraki/core/i18n";
import { PortVlanTypes } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import { useUpdateSwitchPorts } from "~/api/queries/switches/useSwitchPorts";
import { SwitchPort } from "~/api/schemas/SwitchPort";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import SwitchPortSettingsAffectedSummary from "~/go/components/switchPorts/SwitchPortSettingsAffectedSummary";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiTextInput from "~/shared/components/MkiTextInput";
import SwitchFront from "~/shared/components/portDiagrams/SwitchFront";
import SummaryCard from "~/shared/components/SummaryCard";
import SummaryListHeading from "~/shared/components/SummaryListHeading";
import TagsInputCard from "~/shared/components/TagsInputCard";
import useEditSwitchPort from "~/shared/hooks/switch/useEditSwitchPort";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import DropDownRow from "~/shared/rows/DropDownRow";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";

export interface SwitchPortSettingsProps {
  serialNumber: string;
  switchPort: SwitchPort;
  portIds: string[];
  privatePortIds: string[];
  onDismiss?: () => void;
}

const DISMISS_MODAL_TIMEOUT = 500;
const SwitchPortSettingsScreen = ({
  serialNumber,
  switchPort,
  portIds,
  onDismiss,
  privatePortIds,
}: SwitchPortSettingsProps) => {
  const isSingleSwitchPort = portIds.length === 1;
  const [stagedSwitchPort, updateStagedSwitchPort, updateStagedSwitchPortByKey, hasDiff] =
    useEditSwitchPort(switchPort);

  const { mutate, isLoading } = useUpdateSwitchPorts(serialNumber, portIds);

  const navigation = useNavigation();
  useLayoutEffect(() => {
    const saveCallback = () => {
      mutate(stagedSwitchPort, {
        onSuccess: () => {
          onDismiss?.();
          setTimeout(navigation.goBack, DISMISS_MODAL_TIMEOUT);
        },
        onError: (error) => {
          if (error instanceof Error) {
            showAlert(I18n.t("ERROR"), error.message, navigation.goBack);
          }
        },
      });
    };

    navigation.setOptions({
      headerTitle:
        portIds.length === 1
          ? switchPort.name ?? I18n.t("PORTS.NUMBER", { port_number: portIds[0] })
          : `${portIds.length} ${I18n.t("PORTS_WORD")}`,
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (hasDiff) {
              showSaveWarning(saveCallback, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={saveCallback} disabled={!hasDiff} />,
    });
  }, [hasDiff, mutate, navigation, onDismiss, portIds, stagedSwitchPort, switchPort.name]);

  const { linkNegotiation, enabled, poeEnabled, tags, type, name } = stagedSwitchPort;
  const { linkNegotiationCapabilities } = switchPort;

  const selectLinkSpeed = () => {
    navigation.navigate("Selection", {
      title: I18n.t("PORTS.LINK_SPEED"),
      description: I18n.t("LINK_SPEED.DESCRIPTION"),
      initialValue: linkNegotiation,
      onSelect: updateStagedSwitchPortByKey("linkNegotiation"),
      options: linkNegotiationCapabilities.map((option) => ({ label: option, value: option })),
    });
  };

  const vlanSubtitle = isSingleSwitchPort
    ? type === PortVlanTypes.trunk
      ? I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.TRUNK")
      : I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.ACCESS")
    : I18n.t("PORTS.SETTINGS.MULTIPLE_VALUES");

  const selectSwitchPortsVlan = () => {
    navigation.navigate("SwitchPortsVlan", {
      switchPort: stagedSwitchPort,
      portIds,
      onSave: updateStagedSwitchPort,
    });
  };

  const selectMACAllowList = () => {
    navigation.navigate("MACAllowList", {
      switchPort: stagedSwitchPort,
      portIds,
      onSave: updateStagedSwitchPort,
    });
  };

  if (isLoading) {
    return <MkiSpinner fillHeight />;
  }

  return (
    <FullScreenContainerView>
      <ScrollView>
        <View style={styles.portDiagramContainer}>
          <SwitchFront serialNumber={serialNumber} selectedPorts={portIds} hidePortStatusIcons />
        </View>
        <SwitchPortSettingsAffectedSummary
          serialNumber={serialNumber}
          switchPortIds={privatePortIds}
        />
        <SummaryListHeading headingTextStyle={styles.heading}>
          {I18n.t("PORTS.SETTINGS.SETTINGS")}
        </SummaryListHeading>
        {isSingleSwitchPort && (
          <SummaryCard heading={I18n.t("PORTS.SETTINGS.NAME")} removeBottomBorder>
            <MkiTextInput
              value={name ?? undefined}
              onChangeText={(name: any) =>
                updateStagedSwitchPortByKey("name")(name === "" ? null : name)
              }
              placeholder={I18n.t("PORTS.NUMBER", { port_number: portIds[0] })}
            ></MkiTextInput>
          </SummaryCard>
        )}
        <SwitchRow
          subtitle={I18n.t("PORTS.SETTINGS.ENABLED.DESCRIPTION")}
          value={enabled}
          onValueChange={updateStagedSwitchPortByKey("enabled")}
          testID={"PORT_SETTINGS_ENABLED_ROW"}
        >
          {I18n.t("PORTS.SETTINGS.ENABLED.TITLE")}
        </SwitchRow>
        <TagsInputCard
          title={I18n.t("PORTS.PORT_TAGS.TITLE")}
          tags={tags}
          onUpdate={updateStagedSwitchPortByKey("tags")}
          placeholder={I18n.t("PORTS.PORT_TAGS.PLACEHOLDER")}
        />
        <DropDownRow
          title={I18n.t("PORTS.SETTINGS.ADVANCED_SETTINGS")}
          testID="ADVANCED_SETTINGS_ROW"
        >
          <SwitchRow
            subtitle={I18n.t("PORTS.SETTINGS.POE.DESCRIPTION")}
            value={poeEnabled}
            onValueChange={updateStagedSwitchPortByKey("poeEnabled")}
            testID={"PORT_SETTINGS_POE_ROW"}
          >
            {I18n.t("PORTS.SETTINGS.POE.TITLE")}
          </SwitchRow>
          <MkiRowSeparator withTableStyle />
          <SimpleDisclosureRow
            subtitle={
              isSingleSwitchPort ? linkNegotiation : I18n.t("PORTS.SETTINGS.MULTIPLE_VALUES")
            }
            onPress={selectLinkSpeed}
            testID={"PORT_SETTINGS_LINK_SPEED_ROW"}
          >
            {I18n.t("PORTS.SETTINGS.LINK_SPEED")}
          </SimpleDisclosureRow>
          <MkiRowSeparator withTableStyle />
          <SimpleDisclosureRow
            subtitle={vlanSubtitle}
            onPress={selectSwitchPortsVlan}
            testID={"SWITCH_PORT_VLAN"}
          >
            {I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.TITLE")}
          </SimpleDisclosureRow>
          {type === PortVlanTypes.access && (
            <SimpleDisclosureRow onPress={selectMACAllowList} testID={"SWITCH_PORT_ALLOW_LIST"}>
              {I18n.t("PORTS.SETTINGS.SWITCH_PORT_ALLOW_LIST.TITLE")}
            </SimpleDisclosureRow>
          )}
        </DropDownRow>
      </ScrollView>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: normalizedFontSize(19),
    color: MkiColors.primaryButton,
  },
  portDiagramContainer: {
    marginBottom: SPACING.small,
  },
});

export default SwitchPortSettingsScreen;
