import {
  formatReadImageSettingsMessage,
  ReadImageSettingsMessage,
  ReadImageSettingsProps,
  ReadImageSettingsResult,
} from "../handlers/ReadImageSettings";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useReadImageSettings(deviceId: string): LiveTool<ReadImageSettingsResult> {
  const msg = useLiveBroker<ReadImageSettingsProps, ReadImageSettingsMessage>({
    type: "ReadImageSettings",
    deviceId,
  });
  return useCachedFormatter(msg, formatReadImageSettingsMessage);
}
