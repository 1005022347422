import { NetworkUsageblocks } from "@meraki/shared/api";
import { NETWORK_USEBLOCKS_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";
import { AnyAction } from "redux";

export type NetworkUsageSlice = {
  items: {
    [networkId: string]: {
      [timespans: number]: NetworkUsageblocks;
    };
  };
};

const initialState: NetworkUsageSlice = {
  items: {},
};

export default function networkUseblocks(state = initialState, action: AnyAction) {
  switch (action.type) {
    case NETWORK_USEBLOCKS_SUCCESS: {
      return {
        ...state,
        items: action.response.entities.networkUseblocks,
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
