import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Notification, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useCreateVlan,
  useDeleteVlan,
  useUpdateVlan,
  useVlan,
  useVlans,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import {
  calculateNumberOfIPAddresses,
  extractSubnetMask,
  SUBNET_VALUES,
} from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import { useCallback, useEffect, useState } from "react";
import { View } from "react-native";

type EditVlanForm = {
  name: string;
  id: string;
  applianceIp: string;
  subnet: number;
  ip6Enabled: boolean;
};

export function EditVlanScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const networkId = useCurrentNetworkId();
  const route = useRoute<RouteProp<VlanGroupProps, "EditVlan">>();
  const { params: props } = route;
  const vlanId = props.vlanId;
  const { data: vlan, isLoading: vlanIsLoading } = useVlan({
    networkId,
    vlanId: vlanId,
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const updateVlan = useUpdateVlan();
  const deleteVlan = useDeleteVlan();
  const createVlan = useCreateVlan();

  const methods = useForm<EditVlanForm>({
    values: {
      id: (vlan?.id ?? "").toString(),
      name: vlan?.name ?? "",
      applianceIp: vlan?.applianceIp ?? "192.168.128.1",
      subnet: extractSubnetMask(vlan?.subnet ?? "192.168.128.0/16"),
      ip6Enabled: !!vlan?.ipv6?.enabled,
    },
  });

  const onSubmit = useCallback(
    ({ name, id, applianceIp, subnet, ip6Enabled }: EditVlanForm) => {
      const onResolve = () => {
        queryClient.refetchQueries({ queryKey: useVlans.queryKeyRoot });
        navigation.goBack();
      };

      if (id === vlan?.id?.toString()) {
        updateVlan.mutate(
          {
            networkId,
            vlanId,
            vlan: {
              name,
              applianceIp,
              subnet: `${applianceIp}/${subnet}`,
              ipv6: { enabled: ip6Enabled },
            },
          },
          {
            onSuccess: onResolve,
            onError: (error) => setErrorMessage(error.errors[0]),
          },
        );
      } else {
        const newVlan = {
          ...vlan,
          name,
          id,
          applianceIp,
          subnet: `${applianceIp}/${subnet}`,
          ipv6: {
            ...vlan?.ipv6,
            enabled: ip6Enabled,
          },
        };

        deleteVlan.mutate(
          {
            networkId,
            vlanId,
          },
          {
            onSuccess: () => {
              createVlan.mutate(
                { networkId, vlan: newVlan },
                {
                  onSuccess: onResolve,
                  onError: (error) => setErrorMessage(error.errors[0]),
                },
              );
            },
            onError: (error) => setErrorMessage(error.errors[0]),
          },
        );
      }
    },
    [createVlan, deleteVlan, navigation, networkId, updateVlan, vlan, vlanId],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          onPress={methods.handleSubmit(onSubmit)}
          text={I18n.t("SAVE")}
          testID={"SAVE"}
          disabled={
            vlanIsLoading || createVlan.isLoading || deleteVlan.isLoading || updateVlan.isLoading
          }
        />
      ),
      headerTitle: vlan?.name,
    });
  });

  const watchedAddress = methods.watch("applianceIp");
  const watchedSubnet = methods.watch("subnet");
  const subnetObj = new Address4(`${watchedAddress}/${watchedSubnet}`);
  const correctSubnetFormat = `${subnetObj.startAddress().correctForm()}/${watchedSubnet}`;
  const numberOfAddresses = calculateNumberOfIPAddresses(watchedSubnet);

  return (
    <Screen addDefaultPadding>
      <Form {...methods}>
        {errorMessage && (
          <Notification.Inline
            status="negative"
            message={errorMessage}
            onDismiss={() => setErrorMessage(undefined)}
          />
        )}
        <Form.Input name="name" label="Name" />
        <Form.Input
          name="id"
          label="id"
          additionalContext={I18n.t("EDIT_VLAN_SCREEN.ID_DESCRIPTION")}
          testID={"VLAN_ID"}
        />
        <Heading> {I18n.t("EDIT_VLAN_SCREEN.IP4_CONFIGURATION")} </Heading>
        <Form.Input
          name="applianceIp"
          label={I18n.t("EDIT_VLAN_SCREEN.GX_IP")}
          additionalContext={I18n.t("EDIT_VLAN_SCREEN.GX_DESCRIPTION")}
        />
        <Form.PickerCard
          name="subnet"
          title={I18n.t("CLIENT_VPN.SUBNET")}
          options={SUBNET_VALUES.map((value: number) => ({
            value,
            label: value.toString(),
          }))}
          snapPoints={["95%"]}
        />
        <View style={{ paddingTop: 2, paddingLeft: 6 }}>
          <Text color="light">{I18n.t("EDIT_VLAN_SCREEN.SUBNET_DESCRIPTION")}</Text>
        </View>
        <Box flex={1} justifyContent="center" alignContent="center" alignItems="center">
          <Text size="p1">
            {I18n.t("EDIT_VLAN_SCREEN.ADDRESS_SPACE", { address_count: numberOfAddresses })}
          </Text>
          <Text size="p1">
            {I18n.t("EDIT_VLAN_SCREEN.SUBNET_TEXT", { subnet: correctSubnetFormat })}
          </Text>
        </Box>
        <Heading> {I18n.t("EDIT_VLAN_SCREEN.IP6_CONFIGURATION")} </Heading>
        <List>
          <List.Item
            title={I18n.t("EDIT_VLAN_SCREEN.ENABLE_IPV6")}
            rightAccessory={<Form.Toggle name="ip6Enabled" />}
          />
        </List>
      </Form>
    </Screen>
  );
}
