import { lspAnyURL, lspSwitchURL, lspWiredURL, lspWirelesshURL } from "@meraki/shared/links";
import { Clusters } from "@meraki/shared/redux";

import {
  apiURL,
  communityURL,
  documentationURL,
  goPortalURL,
  marketoURL,
  masterURL,
  shardURL,
  umbrellaURL,
} from "~/env";
import { URLPrepends } from "~/shared/types/Networks";

const API_CHECK = "/api/";
const TESTBED_API_CHECK = "/testbed/api/";

export function makeFullURL({
  prepend,
  endpoint,
  shardId,
  cluster,
}: {
  prepend?: URLPrepends;
  endpoint: string;
  shardId?: string | number;
  cluster: Clusters;
}) {
  let prependURL: string;

  switch (prepend) {
    case URLPrepends.portal:
      prependURL = goPortalURL(cluster);
      break;
    case URLPrepends.api:
      prependURL = apiURL(cluster);
      break;
    case URLPrepends.documentation:
      prependURL = documentationURL();
      break;
    case URLPrepends.community:
      prependURL = communityURL();
      break;
    case URLPrepends.lspWired:
      prependURL = lspWiredURL();
      break;
    case URLPrepends.lspSwitch:
      prependURL = lspSwitchURL();
      break;
    case URLPrepends.lspWireless:
      prependURL = lspWirelesshURL();
      break;
    case URLPrepends.lspAny:
      prependURL = lspAnyURL();
      break;
    case URLPrepends.marketo:
      prependURL = marketoURL();
      break;
    case URLPrepends.umbrella:
      prependURL = umbrellaURL();
      break;
    default:
      const shouldPointToShard = shardId != null;

      if (shouldPointToShard) {
        prependURL = shardURL(shardId, cluster);
      } else {
        // We shouldn't make /api/ requests to dashboard.meraki.com since they will be redirected to api.meraki.com
        if (endpoint.startsWith(API_CHECK) || endpoint.startsWith(TESTBED_API_CHECK)) {
          prependURL = apiURL(cluster);
        } else {
          prependURL = masterURL(cluster);
        }
      }
      break;
  }

  return prependURL + endpoint;
}
