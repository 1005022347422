import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const MetadataSchema = z.object({
  channel: z.string(),
  end: z.number(),
  expiry: z.number(),
  height: z.number(),
  id: z.string(),
  length: z.number(),
  on_camera: z.boolean(),
  size: z.number(),
  src: z.string().url(),
  start: z.number(),
  storage_provider: z.string(),
  width: z.number(),
});

export const StoredVideoFileSchema = z.object({
  channel_id: z.string(),
  end_time: z.number(),
  id: z.string(),
  local: z.boolean(),
  metadata: MetadataSchema,
  metadata_backup: MetadataSchema.nullable(),
  requested_ts: z.number(),
  start_time: z.number(),
  video_status: z.string(), // TODO: Enum("exists", ?, ?)
  video_status_reason: z.string().nullable(),
});

// If you query for a file and it doesn't find one, the endpoint constructs a fake video file to return
export const FakeStoredVideoFileSchema = StoredVideoFileSchema.pick({
  id: true,
  channel_id: true,
  video_status: true,
  video_status_reason: true,
  start_time: true,
  end_time: true,
  requested_ts: true,
}).merge(z.object({ metadata: z.object({}) }));

export const VideoJsonSchema = z
  .object({
    file: z.union([StoredVideoFileSchema, FakeStoredVideoFileSchema]),
  })
  .describe("VideoFileSchema");

export type VideoFile = z.infer<typeof StoredVideoFileSchema>;
export type VideoJson = z.infer<typeof VideoJsonSchema>;

type VideoFileError = {
  status?: string;
};

interface StoredVideoFileRequest {
  ts?: number;
  channelId?: string;
  networkEid?: string;
  lanAvailable: boolean;
}

const buildQueryParams = ({ ts, channelId, lanAvailable }: StoredVideoFileRequest) => ({
  ts,
  channel_id: channelId,
  local_lan_available: lanAvailable,
});

function buildUrl(networkEid: StoredVideoFileRequest["networkEid"]) {
  return `/n/${networkEid}/manage/video/video_json`;
}

const fetchVideoFile = (variables: StoredVideoFileRequest): Promise<VideoJson> =>
  request(VideoJsonSchema, "GET", buildUrl(variables.networkEid), {
    queryParams: buildQueryParams(variables),
  });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useStoredVideoFile = createQuery<StoredVideoFileRequest, VideoJson, VideoFileError>({
  baseQueryKey: buildUrl("{networkEid}"),
  queryFn: (variables) => {
    return fetchVideoFile(variables);
  },
  requiredVariables: ["ts", "channelId", "networkEid"],
});

export default useStoredVideoFile;
