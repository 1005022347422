import { I18n } from "@meraki/core/i18n";
import {
  NetworkUsageExceededScreen,
  NetworkUsageScreen,
  PresenceReportScreen,
} from "@meraki/go/insight";
import { AlertInsightGroupProps } from "@meraki/go/navigation-type";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";

export function AlertInsightGroup<T extends AlertInsightGroupProps>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="NetworkUsage"
        component={NetworkUsageScreen}
        options={{
          headerTitle: I18n.t("HOME.USAGE.TITLE"),
        }}
      />
      <Stack.Screen
        name="NetworkUsageExceeded"
        component={NetworkUsageExceededScreen}
        options={{
          headerShadowVisible: false,
          headerTitle: I18n.t("HOME.USAGE_EXCEEDED.TITLE"),
        }}
      />
      <Stack.Screen
        name="PresenceReport"
        component={PresenceReportScreen}
        options={{
          headerTitle: I18n.t("LOCATION_ANALYTICS.TITLE"),
        }}
      />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "HOME.USAGE.TITLE",
    tab: "InsightsTab",
    scope: "network",
    screen: "NetworkUsage",
  },
  ["network"],
);

registerTags(
  {
    title: "LOCATION_ANALYTICS.TITLE",
    tab: "InsightsTab",
    scope: "network",
    screen: "PresenceReport",
  },
  ["network", "wireless"],
);
