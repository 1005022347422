import { createSelector } from "reselect";

import { adminsState, authorizationsState, currentUserState } from "~/selectors/getters";
import { AuthsOnNetworkByEmail, AuthUserType } from "~/shared/types/AdminTypes";
import { RootState } from "~/shared/types/Redux";

export const getCurrentUserAdmin = createSelector(
  adminsState,
  currentUserState,
  (admins, currentUserEmail) =>
    Object.values(admins).find(
      ({ email }) => email?.toLowerCase() === currentUserEmail?.toLowerCase(),
    ),
);

export const isTwoFactorEnabled = createSelector(getCurrentUserAdmin, (currentUserAdmin) => {
  return currentUserAdmin?.twoFactorAuthEnabled ?? false;
});

// only support client VPN for now
export const getAuthsOnNetworkByEmail = createSelector(
  authorizationsState,
  (authroizationsByEmail) => {
    const result: AuthsOnNetworkByEmail = {};
    for (const [email, authorizations] of Object.entries(authroizationsByEmail)) {
      result[email] = {
        clientVPNEnabled: authorizations.some(({ type }) => type === AuthUserType.CLIENT_VPN),
      };
    }

    return result;
  },
);

export const getAuthIdByEmail = createSelector(
  authorizationsState,
  (_: RootState, { email }: { email: string }) => email,
  (_: RootState, { authType }: { authType: AuthUserType }) => authType,
  (authroizationsByEmail, email, authType) =>
    authroizationsByEmail[email]?.find((auth) => auth.type === authType)?.id ?? "",
);
