import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { useCallback } from "react";
import { GestureResponderEvent, Pressable, PressableProps } from "react-native";

import { IconButtonInternal, IconButtonInternalProps } from "./IconButtonInternal";

export type IconButtonProps = Omit<IconButtonInternalProps, "pressed"> &
  Omit<PressableProps, "style" | "accessibilityRole"> &
  AnalyticsProp<"onPress">;

export function IconButton({
  icon,
  color,
  disabled,
  loading,
  onPress,
  analytics,
  ...rest
}: IconButtonProps) {
  const trackEvent = useAnalytics(analytics);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      trackEvent("onPress");
      onPress?.(event);
    },
    [trackEvent, onPress],
  );

  return (
    <Pressable
      {...rest}
      onPress={handlePress}
      disabled={disabled || loading}
      accessibilityRole="button"
    >
      {({ pressed }) => (
        <IconButtonInternal
          icon={icon}
          disabled={disabled}
          loading={loading}
          pressed={pressed}
          color={color}
        />
      )}
    </Pressable>
  );
}
