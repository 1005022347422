import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Svg from "react-native-svg";
import { VictoryLabel, VictoryPie } from "victory-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import I18n from "~/i18n/i18n";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

interface MkiPieChartProps<X extends string = "x", Y extends string = "y"> {
  data: { [key in X | Y]: string | number }[];
  x: X;
  y: Y;
  colorScale: string[];
  showLegend: boolean;
  chartWidthHeight: number;
  innerRadiusPercent: number;
  chartStyle: StyleProp<ViewStyle>;
  onPress?: () => void;
  testID?: string;
  innerText?: string;
}

const defaultColors = [
  MkiColors.pie1,
  MkiColors.pie2,
  MkiColors.pie3,
  MkiColors.pie4,
  MkiColors.pie5,
  MkiColors.pie6,
  MkiColors.pie7,
];

const renderLegend = <X extends string = "x", Y extends string = "y">(
  data: MkiPieChartProps<X, Y>["data"],
  label: X,
  colors: string[],
) => {
  if (!data) {
    return null;
  }
  const legendInfo = data.map((d, i) => ({
    label: d[label] || I18n.t("UNKNOWN"),
    color: colors?.[i % colors?.length],
  }));

  // TODO: handle smaller screen sizes better
  return (
    <View style={styles.legend}>
      {legendInfo.map((item) => (
        <View key={item.label} style={styles.legendItem}>
          <View style={[styles.legendColor, { backgroundColor: item?.color }]} />
          <MkiText screenStyles={styles.legendTextColor} textStyle="small" numberOfLines={2}>
            {item.label}
          </MkiText>
        </View>
      ))}
    </View>
  );
};

const MkiPieChart = <X extends string = "x", Y extends string = "y">(
  props: MkiPieChartProps<X, Y>,
) => {
  const {
    chartWidthHeight,
    innerRadiusPercent,
    chartStyle,
    x,
    y,
    data,
    innerText,
    colorScale,
    showLegend,
    onPress,
    testID,
  } = props;
  const innerTextColor = themeColors(useTheme()).text.subheader.color;

  if (!data) {
    return null;
  }
  const innerRadius = (chartWidthHeight / 2) * innerRadiusPercent;

  const chart = (
    <View style={[styles.container, chartStyle]} pointerEvents="none" testID={testID}>
      <Svg width={chartWidthHeight} height={chartWidthHeight}>
        <VictoryPie
          x={x}
          y={y}
          standalone={false}
          data={data}
          colorScale={colorScale}
          labels={[]}
          width={chartWidthHeight}
          height={chartWidthHeight}
          innerRadius={innerRadius}
          padding={0}
        />
        {innerText && (
          <VictoryLabel
            textAnchor="middle"
            x={chartWidthHeight / 2}
            y={chartWidthHeight / 2}
            style={{ ...styles.innerText, ...{ fill: innerTextColor } }}
            text={innerText}
          />
        )}
      </Svg>
      {showLegend ? renderLegend(data, x, colorScale) : null}
    </View>
  );
  return onPress ? <TouchableOpacity onPress={onPress}>{chart}</TouchableOpacity> : chart;
};

MkiPieChart.defaultProps = {
  colorScale: defaultColors,
  showLegend: true,
  chartWidthHeight: 100,
  innerRadiusPercent: 0.75,
  chartStyle: {},
  onPress: undefined,
  testID: undefined,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  legend: {
    flexDirection: "column",
    marginLeft: SPACING.large,
    width: "50%",
  },
  innerText: {
    fontFamily: "ciscosans",
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  legendColor: {
    width: SPACING.default,
    height: SPACING.small,
    borderRadius: SPACING.tiny,
    marginRight: SPACING.small,
  },
  legendTextColor: appSelect({
    enterprise: { color: TEXT_COLORS.secondary },
    go: {},
  }),
});

export default MkiPieChart;
