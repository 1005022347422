import { I18n } from "@meraki/core/i18n";

export const convertFrequency = (timeout?: number, isShorthand = true) => {
  const newTimeout = timeout ?? 0;
  if (SPLASH_TIMEOUT_OPTIONS.includes(newTimeout)) {
    return I18n.t(
      `SPLASH_TIMEOUT_OPTIONS.SECONDS_${
        isShorthand ? "SHORTHAND" : "LONGHAND"
      }${newTimeout.toString()}`,
    );
  }

  return "";
};

export const SPLASH_TIMEOUT_OPTIONS = [
  1800, 3600, 7200, 14400, 28800, 86400, 259200, 604800, 2592000, 7776000,
];
