import {
  SET_CLIENTS_LIST_TIMESPAN,
  SET_WIRELESS_TIMESPAN,
  TimeSpan,
  twoHours,
} from "@meraki/shared/redux";
import { AnyAction } from "redux";

interface TimespanAction extends AnyAction {
  type: typeof SET_CLIENTS_LIST_TIMESPAN | typeof SET_WIRELESS_TIMESPAN;
  timespan: TimeSpan;
}

interface TimespanState {
  clientsList: TimeSpan;
  wirelessHub: TimeSpan;
}

const initialState: TimespanState = {
  clientsList: twoHours,
  wirelessHub: twoHours,
};

export default function timespans(
  state: TimespanState = initialState,
  action: TimespanAction,
): TimespanState {
  const { type, timespan } = action;
  switch (type) {
    case SET_CLIENTS_LIST_TIMESPAN:
      return {
        ...state,
        clientsList: timespan,
      };
    case SET_WIRELESS_TIMESPAN:
      return {
        ...state,
        wirelessHub: timespan,
      };
    default:
      return state;
  }
}
