import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { View, ViewProps } from "react-native";

import { BasicStyle, mapBasicPropsToStyles } from "./styles/basic";
import { BorderRadius, BorderStyle, mapBorderPropsToStyles } from "./styles/border";
import { FlexStyle, mapFlexPropsToStyle } from "./styles/flex";
import { mapSpacingPropsToStyles, SpacingStyle } from "./styles/spacing";

export type BoxProps = Omit<ViewProps, "style"> &
  BasicStyle &
  FlexStyle &
  BorderStyle &
  SpacingStyle;

function mapProps(props: BoxProps, theme: Theme) {
  const { style: basicStyle, ...basicRest } = mapBasicPropsToStyles(props, theme);
  const { style: flexStyle, ...flexRest } = mapFlexPropsToStyle(basicRest);
  const { style: borderStyle, ...borderRest } = mapBorderPropsToStyles(flexRest, theme);
  const { style: spacingStyle, ...spacingRest } = mapSpacingPropsToStyles(borderRest, theme);

  return {
    style: { ...basicStyle, ...flexStyle, ...borderStyle, ...spacingStyle },
    ...spacingRest,
  };
}

// Export spacing and flex helpers as they can be useful in other components in Magnetic too
export {
  BorderRadius,
  BorderStyle,
  FlexStyle,
  mapFlexPropsToStyle,
  mapSpacingPropsToStyles,
  SpacingStyle,
};

export function Box(props: BoxProps) {
  const theme = useMagneticTheme();

  const { style, ...rest } = mapProps(props, theme);

  return <View {...rest} style={style} />;
}
