import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useSsid, useSsids, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect } from "react";
import Toast from "react-native-simple-toast";

export function WirelessModeScreen() {
  const networkId = useCurrentNetworkId();
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "WirelessMode">>();
  const { params: props } = route;
  const { data: ssid, isLoading: isSsidLoading } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });
  const updateSsid = useUpdateSsid();
  const ipAssignmentMode = ssid?.ipAssignmentMode ?? "Bridge mode";
  useEffect(
    () =>
      navigation.setOptions({
        headerTitle: ssid?.name,
      }),
    [navigation, ssid],
  );
  const updateWirelessMode = useCallback(
    (mode: string) => {
      if (mode === ipAssignmentMode) {
        return;
      }
      updateSsid.mutate(
        { networkId, ssidNumber: props?.ssidNumber, body: { ipAssignmentMode: mode } },
        {
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: useSsid.queryKeyRoot });
            queryClient.refetchQueries({ queryKey: useSsids.queryKeyRoot });
            Toast.showWithGravity(
              I18n.t("WIRELESS_MODE.SUCCESS_TOAST", { mode }),
              Toast.SHORT,
              Toast.TOP,
            );
          },
          onError: (error) =>
            Toast.showWithGravity(
              error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT"),
              Toast.SHORT,
              Toast.TOP,
            ),
        },
      );
    },
    [networkId, props?.ssidNumber, updateSsid, ipAssignmentMode],
  );

  return (
    <Screen addDefaultPadding>
      <Box alignItems="flex-start">
        <Heading size="h1">{I18n.t("WIRELESS_MODE.TITLE")}</Heading>
        <Text size="p1">{I18n.t("WIRELESS_MODE.DESCRIPTION")}</Text>
        <Button
          kind="tertiary"
          onPress={() => undefined}
          text={I18n.t("WIRELESS_MODE.DESCRIPTION_LINK")}
        />
      </Box>

      <List loading={isSsidLoading || updateSsid.isLoading}>
        <List.RadioItem
          title={I18n.t("WIRELESS_MODE.BRIDGE_MODE.TITLE")}
          description={I18n.t("WIRELESS_MODE.BRIDGE_MODE.DESCRIPTION")}
          onRadioValueChange={updateWirelessMode}
          radioValue={"Bridge mode"}
          checkedRadioValue={ipAssignmentMode}
          rightAccessory={<Icon name="Gear" />}
          testID={"RADIO.BRIDGE_MODE"}
        />
        <List.RadioItem
          title={I18n.t("WIRELESS_MODE.NAT_MODE.TITLE")}
          description={I18n.t("WIRELESS_MODE.NAT_MODE.DESCRIPTION")}
          onRadioValueChange={updateWirelessMode}
          radioValue={"NAT mode"}
          checkedRadioValue={ipAssignmentMode}
          rightAccessory={<Icon name="Gear" />}
          testID={"RADIO.NAT_MODE"}
        />
      </List>
    </Screen>
  );
}
