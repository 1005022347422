import { Platform } from "react-native";
import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const TimezoneEntrySchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const TimezonesSchema = z
  .object({
    options: z.array(TimezoneEntrySchema),
  })
  .describe("TimezonesSchema");

export type Timezones = z.infer<typeof TimezonesSchema>;

const URL = "/go/tz_data";
const baseUrl =
  Platform.OS === "web" ? "/account" : __DEV__ ? undefined : "https://account.meraki-go.com";

function fetchTimezones(): Promise<Timezones> {
  return request(TimezonesSchema, "GET", URL, { baseUrl });
}

export const useTimezones = createQuery<void, Timezones>({
  baseQueryKey: URL,
  queryFn: () => fetchTimezones(),
});
