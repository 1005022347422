import { PureComponent } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";

interface ImageThumbnailProps {
  source: { uri?: string; height?: number; width?: number };
  show: boolean;
  onClose: () => void;
}

interface ImageThumbnailState {
  loading: boolean;
}

export default class ImageThumbnail extends PureComponent<
  ImageThumbnailProps,
  ImageThumbnailState
> {
  state = { loading: true };

  onLoadEnd = () => this.setState({ loading: false });

  render() {
    const { source, show, onClose } = this.props;
    if (!show) {
      return null;
    }
    const { loading } = this.state;

    return (
      <View style={styles.container}>
        <Image source={source} resizeMode="contain" onLoadEnd={this.onLoadEnd} />
        <LoadingSpinner visible={loading} />
        {!loading && (
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <MerakiIcon name="exit-circle" size="s" color={MkiColors.closeButton} />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "flex-start",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    left: SPACING.tiny,
    top: SPACING.tiny,
    borderRadius: 12,
    opacity: 0.8,
    backgroundColor: MkiColors.whiteBackground, // A valid use for the white background, since it's used to contrast the close button
  },
});
