import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const DHCPOptionTypes = {
  text: "text",
  ip: "ip",
  hex: "hex",
  integer: "integer",
} as const;

export const DHCPOptionTypesSchema = z.union([
  z.literal(DHCPOptionTypes.text),
  z.literal(DHCPOptionTypes.ip),
  z.literal(DHCPOptionTypes.hex),
  z.literal(DHCPOptionTypes.integer),
]);

export const DHCPOptionSchema = z.object({
  code: z.string(),
  type: DHCPOptionTypesSchema,
  value: z.string(),
});

export const fixedIpAssignmentsMacSchema = z.record(
  z.string(),
  z.object({
    ip: z.string(),
    name: z.string(),
  }),
);

export const reservedIpRangeSchema = z.object({
  start: z.string(),
  end: z.string(),
  comment: z.string(),
});

export const prefixAssignmentSchema = z.object({
  autonomous: z.boolean(),
  staticPrefix: z.string(),
  staticApplianceIp6: z.string(),
  origin: z.object({
    type: z.string(),
    interfaces: z.array(z.string()),
  }),
});

export const ipv6Schema = z.object({
  enabled: z.boolean(),
  prefixAssignemtns: z.array(prefixAssignmentSchema).optional(),
});

export const VlanSchema = z
  .object({
    id: z.number(),
    interfaceId: z.string(),
    name: z.string(),
    networkId: z.string(),
    subnet: z.string(),
    applianceIp: z.string(),
    groupPolicyId: z.string().optional(),
    templateVlanType: z.string().optional(),
    cidr: z.string().optional(),
    mask: z.number().optional(),
    dhcpRelayServerIps: z.array(z.string()).optional(),
    dhcpHandling: z.string(),
    dhcpLeaseTime: z.string().optional(),
    dhcpBootOptionsEnabled: z.boolean().optional(),
    dhcpBootNextServer: z.string().optional(),
    dhcpBootFilename: z.string().optional(),
    reservedIpRanges: z.array(reservedIpRangeSchema),
    dnsNameservers: z.string(),
    dhcpOptions: z.array(DHCPOptionSchema).optional(),
    vpnNatSubnet: z.string().optional(),
    mandatoryDhcp: z.object({ enabled: z.boolean() }).optional(),
    fixedIpAssignments: fixedIpAssignmentsMacSchema,
    ipv6: ipv6Schema.optional(),
  })
  .describe("VlanSchema");
export type Vlan = z.infer<typeof VlanSchema>;

export const VlanPayloadSchema = z
  .object({
    interfaceId: z.string().optional(),
    name: z.string().optional(),
    subnet: z.string().optional(),
    applianceIp: z.string().optional(),
    groupPolicyId: z.string().optional(),
    templateVlanType: z.string().optional(),
    cidr: z.string().optional(),
    mask: z.number().optional(),
    dhcpRelayServerIps: z.array(z.string()).optional(),
    dhcpHandling: z.string().optional(),
    dhcpLeaseTime: z.string().optional(),
    dhcpBootOptionsEnabled: z.boolean().optional(),
    dhcpBootNextServer: z.string().optional(),
    dhcpBootFilename: z.string().optional(),
    reservedIpRanges: z.array(reservedIpRangeSchema).optional(),
    dnsNameservers: z.string().optional(),
    dhcpOptions: z.array(DHCPOptionSchema).optional(),
    vpnNatSubnet: z.string().optional(),
    mandatoryDhcp: z.object({ enabled: z.boolean() }).optional(),
    fixedIpAssignments: fixedIpAssignmentsMacSchema.optional(),
    ipv6: ipv6Schema.optional(),
  })
  .describe("VlanPayloadSchema");
export type VlanPayload = z.infer<typeof VlanPayloadSchema>;

export const VlansSchema = z.array(VlanSchema).describe("VlansSchema");
export type Vlans = z.infer<typeof VlansSchema>;

interface VlansRequest {
  networkId?: string;
  vlanId?: number;
}

interface VlanRequest {
  networkId?: string;
  vlanId: number;
}

interface CreateVlanRequest {
  networkId?: string;
  vlan: VlanPayload | Vlan;
}

interface UpdateVlanRequest {
  networkId?: string;
  vlanId: number;
  vlan: VlanPayload;
}

interface DeleteVlanRequest {
  networkId?: string;
  vlanId: number;
}

function buildUrl({ networkId, vlanId }: VlansRequest) {
  return `/api/v1/networks/${networkId}/appliance/vlans${vlanId ? `/${vlanId}` : ""}`;
}

function fetchVlans({ networkId }: VlansRequest): Promise<Vlans> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching Vlans");
  }

  return request(VlansSchema, "GET", buildUrl({ networkId }));
}

function fetchVlan({ networkId, vlanId }: VlanRequest): Promise<Vlan> {
  return request(VlanSchema, "GET", buildUrl({ networkId, vlanId }));
}

function createVlan({ networkId, vlan }: CreateVlanRequest): Promise<Vlan> {
  if (!networkId) {
    throw new Error("Undefined network id when creating a vlan");
  }

  return request(VlanSchema, "POST", buildUrl({ networkId }), { body: JSON.stringify(vlan) });
}

function updateVlan({ networkId, vlanId, vlan }: UpdateVlanRequest): Promise<Vlan> {
  if (!networkId) {
    throw new Error("Undefined network id when updating a vlan");
  }
  return request(VlanSchema, "PUT", buildUrl({ networkId, vlanId }), {
    body: JSON.stringify(vlan),
  });
}

function deleteVlan({ networkId, vlanId }: DeleteVlanRequest): Promise<Vlan> {
  if (!networkId) {
    throw new Error("Undefined network id when deleting a vlan");
  }

  return request(VlanSchema, "DELETE", buildUrl({ networkId, vlanId }));
}

export const useVlans = createQuery<VlansRequest, Vlans, APIResponseError>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchVlans(variables),
  requiredVariables: ["networkId"],
});

export const useVlan = createQuery<VlanRequest, Vlan, APIResponseError>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchVlan(variables),
  requiredVariables: ["networkId", "vlanId"],
});

export const useCreateVlan = createMutation<CreateVlanRequest, Vlan, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: CreateVlanRequest) => createVlan(options),
});

export const useUpdateVlan = createMutation<UpdateVlanRequest, Vlan, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: UpdateVlanRequest) => updateVlan(options),
});

export const useDeleteVlan = createMutation<DeleteVlanRequest, Vlan, APIResponseError>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (options: DeleteVlanRequest) => deleteVlan(options),
});
