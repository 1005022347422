import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { SplashMethodsSchema } from "../../schemas/SplashMethods";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId, ssidNumber }: SsidSplashSettingsRequestForBuildUrl) => {
  return `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/splash/settings`;
};

interface SsidSplashSettingsRequest {
  networkId?: string;
  ssidNumber?: number;
}

interface SsidSplashSettingsRequestForBuildUrl {
  networkId: string;
  ssidNumber: string;
}

interface MutateSsidSplashSettingsRequest extends SsidSplashSettingsRequest {
  body: Partial<UpdateSsidSplashSettings>;
}

export const UpdateSsidSplashSettingsSchema = z.object({
  ssidNumber: z.number(),
  splashPage: z.string().optional(),
  useSplashUrl: z.boolean(),
  splashMethod: SplashMethodsSchema,
  splashUrl: z.string().nullable(),
  splashTimeout: z.number(),
  redirectUrl: z.string().nullable(),
  useRedirectUrl: z.boolean(),
  welcomeMessage: z.string().nullable(),
  splashLogo: z.object({
    md5: z.string().optional().nullable(),
    image: z
      .object({
        contents: z.string().nullable(),
        format: z.string().optional(),
      })
      .optional(),
  }),
  splashImage: z.object({ md5: z.string().nullable(), extension: z.string().optional() }),
  splashPrepaidFront: z.object({ md5: z.string().nullable(), extension: z.string().optional() }),
  guestSponsorship: z
    .object({
      durationInMinutes: z.number().nullable(),
      guestCanRequestTimeframe: z.boolean(),
    })
    .nullable(),
  blockAllTrafficBeforeSignOn: z.boolean(),
  controllerDisconnectionBehavior: z.string(),
  allowSimultaneousLogins: z.boolean(),
  billing: z.object({
    freeAccess: z.object({
      enabled: z.boolean(),
      durationInMinutes: z.number(),
    }),
    prepaidAccessFastLoginEnabled: z.boolean().nullable(),
    replyToEmailAddress: z.string(),
  }),
  sentryEnrollment: z.object({
    systemsManagerNetwork: z.object({ id: z.string() }).optional(),
    strength: z.string().optional(),
    enforcedSystems: z.array(z.string()).optional(),
  }),
  selfRegistration: z.object({
    enabled: z.boolean(),
    authorizationType: z.string(),
  }),
});

export type UpdateSsidSplashSettings = z.infer<typeof UpdateSsidSplashSettingsSchema>;

export const SsidSplashSettingsSchema = z.object({
  ssidNumber: z.number(),
  splashPage: z.string().optional(),
  useSplashUrl: z.boolean(),
  splashMethod: SplashMethodsSchema,
  splashUrl: z.string().nullable(),
  splashTimeout: z.number(),
  redirectUrl: z.string().nullable(),
  useRedirectUrl: z.boolean(),
  welcomeMessage: z.string().nullable(),
  splashLogo: z.object({ md5: z.string().nullable(), extension: z.string().optional() }),
  splashImage: z.object({ md5: z.string().nullable(), extension: z.string().optional() }),
  splashPrepaidFront: z.object({ md5: z.string().nullable(), extension: z.string().optional() }),
  guestSponsorship: z
    .object({
      durationInMinutes: z.number().nullable(),
      guestCanRequestTimeframe: z.boolean(),
    })
    .nullable(),
  blockAllTrafficBeforeSignOn: z.boolean(),
  controllerDisconnectionBehavior: z.string(),
  allowSimultaneousLogins: z.boolean(),
  billing: z.object({
    freeAccess: z.object({
      enabled: z.boolean(),
      durationInMinutes: z.number(),
    }),
    prepaidAccessFastLoginEnabled: z.boolean().nullable(),
    replyToEmailAddress: z.string(),
  }),
  sentryEnrollment: z.object({
    systemsManagerNetwork: z.object({ id: z.string() }).optional(),
    strength: z.string().optional(),
    enforcedSystems: z.array(z.string()).optional(),
  }),
  selfRegistration: z.object({
    enabled: z.boolean(),
    authorizationType: z.string(),
  }),
});

export type SsidSplashSettings = z.infer<typeof SsidSplashSettingsSchema>;

const fetchSsidSplashSettings = ({ networkId, ssidNumber }: SsidSplashSettingsRequest) => {
  if (ssidNumber === undefined) {
    throw Error("ssidNumber is not defined");
  }
  if (networkId === undefined) {
    throw Error("networkId is not defined");
  }
  return request(
    SsidSplashSettingsSchema,
    "GET",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
  );
};

export const useSsidSplashSettings = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{number}` }),
  queryFn: (parameters: SsidSplashSettingsRequest) => fetchSsidSplashSettings(parameters),
  requiredVariables: ["networkId"],
});

const updateSsidSplashSettings = ({
  networkId,
  ssidNumber,
  body,
}: MutateSsidSplashSettingsRequest) => {
  if (ssidNumber === undefined) {
    throw Error("ssidNumber is not defined");
  }
  if (networkId === undefined) {
    throw Error("networkId is not defined");
  }

  return request(
    SsidSplashSettingsSchema,
    "PUT",
    buildUrl({ networkId, ssidNumber: ssidNumber.toString() }),
    {
      body: JSON.stringify(body),
    },
  );
};

export const useUpdateSsidSplashSettings = createMutation<
  MutateSsidSplashSettingsRequest,
  SsidSplashSettings,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}`, ssidNumber: `{ssidNumber}` }),
  mutationFn: (parameters: MutateSsidSplashSettingsRequest) => {
    if (parameters.ssidNumber === undefined) {
      throw Error("ssidNumber is not defined");
    }
    return updateSsidSplashSettings({ ...parameters });
  },
});
