import {
  CLEAR_ONBOARDING_DEVICE_EDITS,
  SET_DEVICE_ONBOARDING_COMPLETE,
  SET_DEVICE_ONBOARDING_CONTINUED,
  SET_ONBOARDING_DEVICE_EDITS,
  WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW,
  WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS,
} from "@meraki/shared/redux";

import { DeviceDetails } from "~/shared/types/Device";

interface DeviceOnboardingState {
  deviceDetails?: DeviceDetails;
  isDeviceOnboardingContinued: boolean;
  isWirelessConfigLaunchedFromAddFlow: boolean;
}

export const initialState: DeviceOnboardingState = {
  isDeviceOnboardingContinued: false,
  isWirelessConfigLaunchedFromAddFlow: false,
};

export default function deviceOnboarding(state = initialState, action: any): DeviceOnboardingState {
  const { type } = action;
  switch (type) {
    case SET_ONBOARDING_DEVICE_EDITS: {
      const { deviceDetails } = action;
      return {
        ...state,
        deviceDetails: {
          ...state.deviceDetails,
          ...deviceDetails,
        },
      };
    }
    case CLEAR_ONBOARDING_DEVICE_EDITS: {
      return {
        ...state,
        deviceDetails: {},
      };
    }
    case SET_DEVICE_ONBOARDING_CONTINUED: {
      return {
        ...state,
        isDeviceOnboardingContinued: true,
      };
    }
    case SET_DEVICE_ONBOARDING_COMPLETE: {
      return {
        ...state,
        isDeviceOnboardingContinued: false,
      };
    }
    case WIRELESS_CONFIG_LAUNCH_FROM_ADD_FLOW: {
      return {
        ...state,
        isWirelessConfigLaunchedFromAddFlow: true,
      };
    }
    case WIRELESS_CONFIG_LAUNCHED_FROM_DETAILS: {
      return {
        ...state,
        isWirelessConfigLaunchedFromAddFlow: false,
      };
    }
    default: {
      return state;
    }
  }
}
