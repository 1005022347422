import { performance } from "~/lib/FirebaseModules";
import { isWeb } from "~/lib/PlatformUtils";
import { currentNetworkState, getCurrentOrganization, getRemoteConfigByParam } from "~/selectors";
import { store } from "~/store/configureStore";

const STOPPED_BEFORE_STOPPED_ERROR = "Cannot stop trace before starting";

export enum PerfScreenNames {
  applicationUsageDetailsScreen = "ApplicationUsageDetails",
  applicationUsageListScreen = "ApplicationUsageList",
  clientDetailsScreen = "ClientDetails",
  clientsListScreen = "ClientsList",
  deviceDetailsScreen = "DeviceDetails",
  devicesListScreen = "DevicesList",
  networkSummaryScreen = "NetworkSummary",
  settingsScreen = "Settings",
  orgChooseScreen = "OrgChoose",
}

export enum TraceTypes {
  totalLoad = "screen_total_load",
  firstRender = "screen_first_render",
}
export class ScreenTrace {
  trace: Promise<any> | object | null;
  screenName: string;
  constructor(screenName: string, traceType: TraceTypes) {
    this.trace = this.startTime(traceType);
    this.screenName = screenName;
  }

  static isPerfDisabled = () => !getRemoteConfigByParam(store.getState(), "performance_tracking");

  startTime = (traceType: any) => {
    if (isWeb()) {
      return Promise.resolve();
    }

    if (ScreenTrace.isPerfDisabled()) {
      return null;
    }
    return performance.startTrace(traceType);
  };

  _addTraceAttributes = (trace: any) => {
    if (isWeb()) {
      return;
    }

    const state = store.getState();
    const attrs = {
      screen_name: this.screenName,
      org_id: getCurrentOrganization(state),
      network_id: currentNetworkState(state),
    };
    for (let [attr, val] of Object.entries(attrs)) {
      if (attr == null) {
        return;
      } else if (attr.length > 40) {
        attr = attr.substr(0, 40);
      }

      if (val == null) {
        return;
      } else if (val.length > 100) {
        val = val.substr(0, 100);
      }

      trace.putAttribute(attr, val);
    }
  };

  stopTime = async () => {
    if (ScreenTrace.isPerfDisabled() || isWeb()) {
      return;
    }
    const trace = await this.trace;
    if (!trace) {
      console.error(STOPPED_BEFORE_STOPPED_ERROR);
    }
    this._addTraceAttributes(trace);
    await trace.stop();
  };
}
