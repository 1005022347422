import { useTheme } from "@meraki/core/theme";
import { Picker } from "@react-native-picker/picker";
import { PureComponent } from "react";
import { ScrollView, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import InfoModal from "~/go/components/InfoModal";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { platformSelect } from "~/lib/PlatformUtils";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import RadioSelectionList from "~/shared/components/RadioSelectionList";

export interface PickerModalItems {
  label: string;
  testID?: string;
  value: string | number | null;
}

export interface PickerModalProps {
  title?: string;
  subtitle?: string;
  selectedValue?: PickerModalItems["value"];
  modalStyles?: StyleProp<ViewStyle>;
  pickerStyles?: StyleProp<ViewStyle>;
  visible?: boolean;
  onValueSelect: (value: PickerModalItems["value"]) => void;
  onExit?: () => void;
  items: PickerModalItems[];
  loginStyle?: boolean;
}

export interface PickerModalState {
  selectedValue?: PickerModalItems["value"];
  visible: boolean;
}

export class PickerModal extends PureComponent<PickerModalProps, PickerModalState> {
  static defaultProps = {
    onExit: () => {
      /* do nothing */
    },
    visible: true,
  };

  constructor(props: PickerModalProps) {
    super(props);

    this.state = {
      selectedValue: props?.selectedValue,
      visible: !!props.visible,
    };
  }

  static getDerivedStateFromProps(props: PickerModalProps, state: PickerModalState) {
    // update selectedValue by checking when the visible state changes.

    if (props.visible !== state.visible) {
      return {
        selectedValue: props.selectedValue,
        visible: props.visible,
      };
    }
    return null;
  }

  onSave = () => {
    const { onValueSelect } = this.props;
    const { selectedValue } = this.state;

    onValueSelect(selectedValue ?? null);
  };

  renderItems = () => {
    const { items } = this.props;

    return items.map((item) => (
      <Picker.Item
        key={item.value}
        label={item.label || ""}
        value={item.value}
        testID={item.testID}
      />
    ));
  };

  renderRadioOptions = () => {
    const { items } = this.props;
    return items.map((item) => ({
      value: item.value,
      label: item.label,
    }));
  };

  onValueChange = (value: string | number | null) => {
    this.setState({ selectedValue: value });
  };

  renderPicker() {
    const { selectedValue } = this.state;
    const { pickerStyles, loginStyle } = this.props;
    const { theme } = useTheme.getState();
    const textColor: TextStyle = themeColors(theme).text.heading;
    return platformSelect({
      android: (
        <ScrollView style={styles.scrollContainer}>
          <RadioSelectionList
            radioTextStyle={loginStyle ? textColor : undefined}
            options={this.renderRadioOptions()}
            selectedValue={selectedValue}
            onSelect={this.onValueChange}
          />
        </ScrollView>
      ),
      ios: (
        <Picker
          selectedValue={selectedValue}
          style={[styles.picker, pickerStyles]}
          itemStyle={textColor}
          onValueChange={this.onValueChange}
          testID={"PICKER_MODAL"}
        >
          {this.renderItems()}
        </Picker>
      ),
    });
  }

  render() {
    const { modalStyles, title, subtitle, onExit, loginStyle } = this.props;
    const { visible } = this.state;

    return (
      <InfoModal visible={visible} onExit={onExit}>
        <View style={styles.spacer}>
          <MkiText textStyle="subheading" screenStyles={loginStyle ? styles.loginTitleColor : {}}>
            {title}
          </MkiText>
          {subtitle}
        </View>
        <View style={modalStyles}>{this.renderPicker()}</View>
        <View style={styles.buttonContainer}>
          <RoundedButton
            testID="PICKER_MODAL.BUTTON"
            onPress={this.onSave}
            screenStyles={styles.button}
          >
            {I18n.t("SPLASH_CONFIG.PROMPT_BUTTON")}
          </RoundedButton>
        </View>
      </InfoModal>
    );
  }
}

const styles = StyleSheet.create({
  scrollContainer: {
    height: sizeSelect({
      default: 300,
      extraLarge: 500,
    }),
  },
  picker: platformSelect({
    android: {
      paddingHorizontal: SPACING.default,
      backgroundColor: MkiColors.pickerBackground,
    },
    ios: { paddingHorizontal: SPACING.default },
  }),
  loginTitleColor: {
    color: MkiColors.headingColor,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: SPACING.default,
    paddingHorizontal: SPACING.large,
  },
  spacer: {
    marginBottom: SPACING.default,
  },
});

export default PickerModal;
