import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { View } from "react-native";

import {
  DeviceIllustrationType,
  gr10,
  gr60,
  gs1108,
  gs11024,
  gs11048,
  gx20,
  gx50,
} from "./DeviceIllustrationSvgs";

export const outdoorAps = ["GR60", "GR62"];

export const DeviceIllustrationSVGsByModel: Record<string, DeviceIllustrationType> = {
  GR10: gr10,
  GR12: gr10,
  GR60: gr60,
  GR62: gr60,
  "GS110-8P": gs1108,
  "GS110-24P": gs11024,
  "GS110-48P": gs11048,
  GX20: gx20,
  GX50: gx50,
};

export const PRODUCT_SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
} as const;

const DIMS = {
  small: {
    horizontal: { width: 46, height: 46 },
    vertical: { width: 46, height: 46 },
  },
  medium: {
    horizontal: { width: 68, height: 68 },
    vertical: { width: 68, height: 68 },
  },
  large: {
    horizontal: { width: 120, height: 80 },
    vertical: { width: 120, height: 120 },
  },
};

interface DeviceIllustrationProps {
  model: string;
  size: keyof typeof DIMS;
  view?: "perspective" | "profile";
}

export const DeviceIllustration = ({
  model,
  size,
  view = "perspective",
}: DeviceIllustrationProps) => {
  const theme = useMagneticTheme();

  const deviceIllustration = DeviceIllustrationSVGsByModel[model]?.[view];
  const orientation = outdoorAps.includes(model) ? "vertical" : "horizontal";

  const { height, width } = DIMS[size][orientation];

  if (!deviceIllustration) {
    return (
      <View
        style={{
          width,
          height,
          borderWidth: theme.SizeContainBorderStrokeStrong,
          borderRadius: theme.SizeContainBorderRadiusSm,
          borderStyle: "dashed",
          borderColor: theme.color.dormant.border.base,
        }}
        testID="UNKNOWN_MODEL_ICON"
      />
    );
  }

  return (
    <Box width={width} height={height}>
      {deviceIllustration}
    </Box>
  );
};
