import { useMemo } from "react";

import { formatListData } from "./formatListData";
import { InternalFlashList } from "./InternalFlashList";
import { FlashListProps } from "./types";

export { FlashListProps } from "./types";

export function FlashList<TItem>({
  data,
  label,
  groupBy,
  sortGroupBy,
  getItemData,
  ...rest
}: FlashListProps<TItem>) {
  const updatedData = useMemo(
    () =>
      formatListData<TItem>({
        data,
        label,
        groupBy,
        sortGroupBy,
        getItemData,
      }),
    [data, label, groupBy, sortGroupBy, getItemData],
  );

  return <InternalFlashList {...rest} label={label} data={updatedData} />;
}
