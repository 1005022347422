import { config } from "@meraki/core/dev-config";
import * as errorMonitor from "@meraki/core/errors";
import { FirebaseRemoteConfigTypes } from "@react-native-firebase/remote-config";
import _ from "lodash";

import { MAX_FB_EVENT_LENGTH, MAX_SSIDS, SECONDS_IN_AN_HOUR } from "~/constants/MkiConstants";
import { DETOX } from "~/env";
import { analytics, performance } from "~/lib/FirebaseModules";
import { parseString } from "~/lib/TypeHelper";
import { RemoteConfigParams } from "~/shared/types/Firebase";

import { isApplianceBySerial, isGX20BySerial, isGX50BySerial } from "./DeviceUtils";

export const convertStringToFBEvent = (str: string, prefix?: string, suffix?: string) => {
  let event = prefix ? `${prefix}_` : "";
  const suffixWithUnderscore = suffix ? `_${suffix}` : "";
  const limit = MAX_FB_EVENT_LENGTH - event.length - suffixWithUnderscore.length;
  const snakedAlert = _.snakeCase(str);

  event += snakedAlert.length > limit ? snakedAlert.substr(0, limit - 1) : snakedAlert;

  return event + suffixWithUnderscore;
};

export const remoteConfigSettings: FirebaseRemoteConfigTypes.ConfigSettings = {
  minimumFetchIntervalMillis: __DEV__ ? 0 : SECONDS_IN_AN_HOUR * 12 * 1000, // since we add the key, we need to ensure it's still keeps its default value (12 hours)
};

export const remoteConfigDefaultValues: RemoteConfigParams = {
  performance_tracking: true,
  has_device_shortcuts_ab: false,
  magnetic_rollout_halt: false,
  testing_remote_config: "help i'm not connected to firebase",
};

export interface FormattedConfig {
  source: string;
  value: string | boolean | number;
}

export const parseDefaultConfig = (config: Partial<RemoteConfigParams>) => {
  const formattedConfig: Record<string, FormattedConfig> = {};

  Object.entries(config).forEach((entry) => {
    const [key, value] = entry;
    formattedConfig[key] = { value: value, source: "default" };
  });

  return formattedConfig;
};

export const parseRemoteConfig = (values: FirebaseRemoteConfigTypes.ConfigValues) => {
  const parsedValues: Record<string, FormattedConfig> = {};
  if (values == null) {
    return {};
  }
  for (const configKey in values) {
    const valueObj = values[configKey];
    const source = valueObj.getSource();
    const value = parseString(valueObj.asString());

    if (value === "") {
      errorMonitor.notify(`Remote config value ${configKey} returned an empty string`);
      continue;
    }

    parsedValues[configKey] = {
      source: source,
      value: value,
    };
  }
  return parsedValues;
};

export const FIREBASE_CATEGORIES = {
  milestone: "milestone",
  onboarding: "onboarding",
  rating: "rating",
  hardwareScreenVisit: "hardware_screen_visit",
};

// DEPRECATED: Use src/shared/hooks/useAnalytics instead
export const FIREBASE_EVENTS = {
  adminstratorList: "administrators_list_from_settings",
  adminListView: "admininistrator_list_view_pressed",
  appErrorBannerRedirect: "app_errorbanner_redirect",
  appOnboardScanned: "app_onboard_scanned",
  appOnboardSerial: "app_onboard_serial",
  appOnboardFlashlight: "app_onboard_flashlight",
  appOnboardExistingInitiate: "app_onboard_existing_initiate",
  appOnboardExistingNext: "app_onboard_existing_next",
  loginSuccess: "login_success",
  loginFailure: "login_failure",
  logout: "logout",
  ssidUpdated: "ssid_updated",
  hardwareAdded: "hardware_added",
  hardwareFetched: "hardware_fetched",
  appLaunch: "app_launch",
  appForeground: "app_foreground",
  shareSSIDButtonTap: "ssid_share_button_tapped",
  shareSSIDShared: "ssid_share_shared",
  shareSSIDDismissed: "ssid_share_dismissed",
  skipped: (stage: any) => `skipped_${stage.toLowerCase()}`,
  successful: "successful",
  finishedAddHardware: "finished_add_hardware",
  gxSwap20: "gx20_swap",
  gxSwap20to50: "gx20_to_gx50_swap",
  gxSwap50: "gx50_swap",
  gxSwap50to20: "gx50_to_gx20_swap",
  resovledIssueWithClick: "resolved_issue_with_click",
  resourceResultClicked: "resource_result_clicked",
  openedCaseWithoutClicking: "opened_case_without_clicking",
  openedCaseWithClicking: "opened_case_with_clicking",
  setHomeToAllNetworks: "set_home_to_all_networks",
  setHomeToSSID: "set_home_to_ssid",
  requestedRating: "requested_rating",
  sentForRating: "sent_for_rating",
  ratedPositiveExperience: "rated_positive_experience",
  ratedNegativeExperience: "rated_negative_experience",
  sentToSupport: "sent_to_support",
  createdAccount: "created_new_account",
  openedDocumentationFromSettings: "opened_documentation_from_settings",
  openedCommunityFromSettings: "opened_community_from_settings",
  wirelessOnboardingPreviousErrorFound: "mv_wirelessonboard_previous_error",
  wirelessOnboardingSucceeded: "mv_wirelessonboard_succeeded",
  wirelessOnboardingFailed: "mv_wirelessonboard_failed",
  wirelessOnboardingStart: "mv_wirelessonboard_start",
  wirelessOnboardingStartContinue: "mv_wirelessonboard_start_continue",
  wirelessOnboardingStartReset: "mv_wirelessonboard_start_reset",
  wirelessOnboardingStartCommit: "mv_wirelessonboard_start_commit",
  bioAuthLogin: "bio_auth_login",
  bioAuthLoginFailure: "bio_auth_login_failure",
  bioAuthFailure: "bio_auth_failure",
  bioAuthCancelFailure: "bio_auth_cancel_failure",
  bioAuthCredentialsFailure: "bio_auth_credentials_failure",
  bioAuthLoginNotEnrolled: "bio_auth_login_not_enrolled",
  bioAuthNotEnrolled: "bio_auth_not_enrolled",
  regularLogin: "regular_login",
  mtDeviceProfileGraphToggleAvg: "mt_deviceprofile_graph_toggle_avg",
  mtDeviceProfileGraphToggleHigh: "mt_deviceprofile_graph_toggle_high",
  mtDeviceProfileGraphToggleLow: "mt_deviceprofile_graph_toggle_low",
  mtDeviceProfileGraphToggleThreshold: "mt_deviceprofile_graph_toggle_threshold",
  mtDeviceProfileGraphTapChart: "mt_deviceprofile_graph_tap_chart",
  mvMotionFilterTimeQuick: "mv_motion_filter_time_quick",
  mvMotionFilterTimeStart: "mv_motion_filter_time_start",
  mvMotionFilterTimeApply: "mv_motion_filter_time_apply",
  createNetwork: "create_new_network",
  deleteNetwork: "delete_new_network",
  createIPSKGroup: "create_ipsk_group",
  updateIPSKGroup: "update_ipsk_group",
  deleteIPSKGroup: "delete_ipsk_group",
};

export const isFirebaseEnabled = () => {
  if (DETOX) {
    return false;
  }

  if (__DEV__) {
    return config.isDevFirebaseEnabled;
  }

  return true;
};

const screenTrackSpecialCases = {
  DeviceDetails: true,
};

const normalizeScreenName = (screenName: string): string => {
  const enterpriseScreenPattern = /(dm\.)/gm;
  return screenName?.replace(enterpriseScreenPattern, "");
};

const isScreenSpecialCase = (screenName: string): boolean =>
  screenTrackSpecialCases.hasOwnProperty(screenName);

const trackScreenView = async (screenClass: string, screenName?: string) => {
  await analytics.logScreenView({
    screen_class: screenClass,
    screen_name: screenName || screenClass,
  });
};

export const trackScreen = async (screenName: string) => {
  const normalizedScreenName = normalizeScreenName(screenName);
  if (isScreenSpecialCase(normalizedScreenName)) {
    return;
  }

  await trackScreenView(normalizedScreenName);
};

export const sendMilestoneEvent = (eventName: string, params?: { [key: string]: any }) => {
  const milestoneEventName = "milestone_event";
  const milestoneEvent = {
    ...params,
    [milestoneEventName]: eventName,
  };
  analytics.logEvent(FIREBASE_CATEGORIES.milestone, milestoneEvent);
};

export const sendOnboardingEvent = (eventName: string, params?: { [key: string]: any }) => {
  const onboardingEventName = "onboarding_event";
  const onboardingEvent = {
    ...params,
    [onboardingEventName]: eventName,
  };
  analytics.logEvent(FIREBASE_CATEGORIES.onboarding, onboardingEvent);
};

export const sendRatingEvent = (eventName: string) => {
  const ratingEventName = "rating_event";
  const ratingEvent = { [ratingEventName]: eventName };
  analytics.logEvent(FIREBASE_CATEGORIES.rating, ratingEvent);
};

export const sendSetHomeNetworkEvent = (ssidNumber: number) => {
  if (ssidNumber === undefined) {
    return;
  }
  const networkEvent =
    ssidNumber === MAX_SSIDS ? FIREBASE_EVENTS.setHomeToAllNetworks : FIREBASE_EVENTS.setHomeToSSID;
  analytics.logEvent(networkEvent);
};

export const setAnalyticsUser = async (userId: string) => {
  await analytics.setUserId(userId);
  await analytics.setUserProperty("mki_user_id", userId);
};

export const setAnalyticsOrg = async (orgId: string) => {
  await analytics.setUserProperty("mki_org_id", orgId);
  await analytics.setUserProperty("organization_id", orgId);
};

export const configureFirebase = async (enabled = true) => {
  // Make sure to set firebase debug target if you enable config.isDevFirebaseDisabled.
  // Otherwise we will pollute prod FB data w/ dev data
  const isEnabled = isFirebaseEnabled() || enabled;

  await analytics.setAnalyticsCollectionEnabled(isEnabled);
  await performance.setPerformanceCollectionEnabled(isEnabled);

  // setupFirebaseScreenTracking();
};

export const sendGXFirebaseSwapEvent = (oldGXSerial: string, newGXSerial: string) => {
  if (!(isApplianceBySerial(oldGXSerial) && isApplianceBySerial(newGXSerial))) {
    return;
  }

  const isOldGX20 = isGX20BySerial(oldGXSerial);
  const isNewGX50 = isGX50BySerial(newGXSerial);

  let eventToSend = FIREBASE_EVENTS.gxSwap20to50;
  if (isOldGX20 && !isNewGX50) {
    eventToSend = FIREBASE_EVENTS.gxSwap20;
  } else if (!isOldGX20) {
    eventToSend = isNewGX50 ? FIREBASE_EVENTS.gxSwap50 : FIREBASE_EVENTS.gxSwap50to20;
  }

  analytics.logEvent(eventToSend);
};
