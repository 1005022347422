import z from "zod";

import { ProductTypeSchema } from "~/api/schemas/Device";

const NodeGroupIds = z.record(ProductTypeSchema, z.object({ ngId: z.string(), ngEid: z.string() }));

export const NetworkSchema = z
  .object({
    eid: z.string().or(z.undefined()),
    enrollmentString: z.string().nullable(),
    id: z.string(),
    isBoundToConfigTemplate: z.boolean().or(z.undefined()),
    name: z.string(),
    ngIds: NodeGroupIds.or(z.undefined()),
    notes: z.string().nullable(),
    organizationId: z.string(),
    productTypes: z.array(ProductTypeSchema),
    tags: z.array(z.string()),
    timeZone: z.string(),
    url: z.string(),
  })
  .describe("NetworkSchema");

export const NetworksSchema = z.array(NetworkSchema).describe("NetworksSchema");

export type Network = z.infer<typeof NetworkSchema>;
