import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";

interface ClaimDevicesURLParams {
  networkId: string;
}

interface ClaimDevicesRequest extends ClaimDevicesURLParams {
  serials: string[];
}

const buildUrl = ({ networkId }: ClaimDevicesURLParams) => {
  return `/api/v1/networks/${networkId}/devices/claim`;
};

export const ClaimDevicesSchema = z.undefined().nullable();

export type ClaimDevices = z.infer<typeof ClaimDevicesSchema>;

const fetchClaimDevices = ({ networkId, serials }: ClaimDevicesRequest) => {
  return request(ClaimDevicesSchema, "POST", buildUrl({ networkId }), {
    body: JSON.stringify({ serials }),
  });
};

export const useClaimDevices = createMutation<
  ClaimDevicesRequest,
  null | undefined,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters: ClaimDevicesRequest) => fetchClaimDevices(parameters),
});
