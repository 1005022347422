import { useCurrentNetworkId } from "@meraki/shared/redux";
import { formatDistanceStrict } from "date-fns";
import { StyleSheet, View } from "react-native";

import { useDeviceEvents } from "~/api/queries/devices/useDeviceEvents";
import FixEventProblemButton from "~/go/components/FixEventProblemButton";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { NetworkEvent } from "~/shared/types/NetworkEvent";
import { ProductType } from "~/shared/types/Networks";

const SubText = (props: any) => {
  const { children } = props;
  return <MkiText textStyle="smallSecondary">{children}</MkiText>;
};

export const NetworkEventsListScreen = () => {
  const networkId = useCurrentNetworkId();
  const { data, isLoading, isFetching, isError, refetch } = useDeviceEvents({
    networkId,
    productType: ProductType.appliance,
    perPage: 100,
  });
  if (isError) {
    showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
  }

  const refetchTop = () => {
    refetch({ refetchPage: (_, index) => index < (data?.pages.length || 1) });
  };

  const events: NetworkEvent[] = data?.pages.flatMap((page) => page.events) ?? [];

  const renderFailedConnectionRow = (event: NetworkEvent) => {
    const { clientDescription, description, deviceName, occurredAt } = event;
    const relativeTime = formatDistanceStrict(Date.parse(occurredAt), Date.now(), {
      addSuffix: true,
    });
    return (
      <SimpleDisclosureRow hideDisclosureIcon>
        <View style={styles.eventRow}>
          <MkiText>{clientDescription || deviceName}</MkiText>
          <SubText>{relativeTime}</SubText>
        </View>
        <SubText>{description}</SubText>
        <View style={styles.eventRowFooter}>
          <FixEventProblemButton
            event={event}
            testID={`FIX_EVENT_${event.type}_${event.clientId || event.deviceName}`}
          />
        </View>
      </SimpleDisclosureRow>
    );
  };

  const getKeyFromFailedConnection = (event: any) => {
    const { clientDescription, description, occurredAt } = event;

    return `${clientDescription}-${description}-${occurredAt}`;
  };

  return (
    <MkiTable<NetworkEvent>
      data={events}
      keyExtractor={getKeyFromFailedConnection}
      renderRow={renderFailedConnectionRow}
      onRefresh={refetchTop}
      loading={isLoading || isFetching}
    />
  );
};

const styles = StyleSheet.create({
  eventRow: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  eventRowFooter: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

export default NetworkEventsListScreen;
