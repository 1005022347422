import { Device, NetworkEvent, OrgHealthAlert } from "@meraki/shared/api";
import { isEmpty } from "lodash";

type NetworkEventExtended = NetworkEvent & {
  productType: never;
  severity: never;
};

export type AnyEvent = OrgHealthAlert | NetworkEventExtended;

export function filterData(data: object | object[], keys: string[], searchText?: string) {
  if (!searchText || isEmpty(data)) {
    return data;
  }
  if (isEmpty(keys)) {
    return undefined;
  }

  if (Array.isArray(data)) {
    return filterArray(data, keys, searchText);
  }

  const newObject: Record<string, object | object[]> = {};

  Object.entries(data).forEach(([section, sectionContents]) => {
    if (Array.isArray(sectionContents)) {
      const filteredArr = filterArray(sectionContents, keys, searchText);
      if (filteredArr !== undefined) {
        newObject[section] = filteredArr;
      }
    } else {
      if (filterElement(sectionContents, keys, searchText)) {
        newObject[section] = sectionContents;
      }
    }
  });

  return newObject;
}

function filterArray(data: object[], keys: string[], searchText: string) {
  if (isEmpty(data)) {
    return undefined;
  }
  return data.filter((datum) => {
    return filterElement(datum, keys, searchText);
  });
}

function filterElement(element: object, keys: string[], searchText: string) {
  if (isEmpty(element)) {
    return false;
  }

  return Object.entries(element).some(([confirmedKey, keyValue]) => {
    if (keys.includes(confirmedKey)) {
      switch (typeof keyValue) {
        case "number":
        case "string":
          return partialStringMatch(keyValue, searchText);
        case "boolean":
          return keyValue && partialStringMatch(confirmedKey, searchText);
        case "object":
          if (Array.isArray(keyValue)) {
            return keyValue.some(
              (entry) =>
                (typeof entry === "string" || typeof entry === "number") &&
                partialStringMatch(entry, searchText),
            );
          }
          return false;
        default:
          return false;
      }
    } else {
      return false;
    }
  });
}

function partialStringMatch<T>(data: T, searchText: string) {
  return typeof data?.toString === "function"
    ? data?.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0
    : false;
}
const matchDeviceDetails = (
  {
    productType,
    name,
    mac,
    serial,
  }: Partial<Pick<Device, "productType" | "name" | "mac" | "serial">>,
  searchText: string,
) =>
  partialStringMatch(productType, searchText) ||
  partialStringMatch(name, searchText) ||
  partialStringMatch(mac, searchText) ||
  partialStringMatch(serial, searchText);

const matchHealthAlertDevices = (alert: OrgHealthAlert, searchText: string) =>
  alert.scope.devices.some((device) => matchDeviceDetails(device, searchText));

const matchNetworkEventDevice = (networkEvent: NetworkEvent, searchText: string) => {
  const device = networkEvent.device;
  return isEmpty(device) ? false : matchDeviceDetails(device, searchText);
};

export function eventDataSearchResults(data: Array<AnyEvent>, searchText: string) {
  if (!searchText || isEmpty(data)) {
    return data;
  }

  return data.filter((event) => {
    if ("occurredAt" in event) {
      return (
        partialStringMatch(event.alertType, searchText) ||
        matchNetworkEventDevice(event, searchText)
      );
    } else {
      return (
        partialStringMatch(event.type, searchText) ||
        matchHealthAlertDevices(event, searchText) ||
        partialStringMatch(event.severity, searchText) ||
        partialStringMatch(event.scopeCategory, searchText)
      );
    }
  });
}
