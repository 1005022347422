import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, RadioButton } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useUpdateVlan, useVlan, useVlans, Vlan } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect } from "react";
import { Alert } from "react-native";

const IP_PLACEHOLDER = "192.163.132.20";

type DnsServerForm = {
  dnsNameservers: string;
  primaryDNSServer: string;
  secondaryDNSServer: string;
};

export const getDNSSettings = (vlan?: Vlan): DnsServerForm => {
  const dnsNameservers = vlan?.dnsNameservers;

  if (
    dnsNameservers === "upstream_dns" ||
    dnsNameservers === "google_dns" ||
    dnsNameservers === "opendns"
  ) {
    return {
      dnsNameservers,
      primaryDNSServer: "",
      secondaryDNSServer: "",
    };
  } else {
    const customServers = dnsNameservers?.split(/\n/);

    return {
      dnsNameservers: "custom",
      primaryDNSServer: customServers?.[0] ?? "",
      secondaryDNSServer: customServers?.[1] ?? "",
    };
  }
};

export function DnsServerScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "DnsServer">>();
  const { vlanId } = route.params;

  const networkId = useCurrentNetworkId();
  const { data: vlan, isLoading: vlanIsLoading } = useVlan({
    networkId,
    vlanId,
  });
  const { mutate, isLoading: vlanIsUpdating } = useUpdateVlan();

  const methods = useForm<DnsServerForm>({
    values: getDNSSettings(vlan),
  });

  const currentNameserver = methods.watch("dnsNameservers");

  const onSave = useCallback(
    ({ dnsNameservers, primaryDNSServer, secondaryDNSServer }: DnsServerForm) => {
      if (vlan) {
        const customNameserver = [primaryDNSServer, secondaryDNSServer].join("\n").trim();
        const updatedVlanData = {
          ...vlan,
          dnsNameservers: dnsNameservers === "custom" ? customNameserver : dnsNameservers,
        };
        mutate(
          { networkId, vlanId: vlan.id, vlan: updatedVlanData },
          {
            onError: (error) =>
              Alert.alert(I18n.t("ERROR"), String(error.errors[0]) ?? I18n.t("SERVER_ERROR_TEXT")),
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: useVlans.queryKey({ networkId }),
              });
            },
          },
        );
      }
    },
    [mutate, networkId, vlan],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSave)}
          disabled={!methods.formState.isDirty}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSave]);

  return (
    <Screen>
      <Box bottomDividerBorder>
        <Box paddingHorizontal="md" paddingBottom="sm">
          <Heading size="h1">{I18n.t("DNS_SERVER.TITLE")}</Heading>
        </Box>
      </Box>
      <Box paddingHorizontal="md">
        <List loading={!vlan || vlanIsUpdating || vlanIsLoading}>
          {/* TODO: Implement Form.ListItem or Form.RadioButton once created */}
          <List.Item
            hidePressable
            leftAccessory={<Icon name="Gear" />}
            title={I18n.t("DNS_SERVER.SERVICES.UPSTREAM.LABEL")}
            description={I18n.t("DNS_SERVER.SERVICES.UPSTREAM.SUBLABEL")}
            rightAccessory={<RadioButton checkedValue={currentNameserver} value={"upstream_dns"} />}
            onPress={() =>
              methods.setValue("dnsNameservers", "upstream_dns", { shouldDirty: true })
            }
            testID={`UPSTREAM_ROW${currentNameserver === "upstream_dns" ? ".SELECTED" : ""}`}
          />
          <List.Item
            hidePressable
            leftAccessory={<Icon name="Gear" />}
            title={I18n.t("DNS_SERVER.SERVICES.GOOGLE_DNS.LABEL")}
            description={I18n.t("DNS_SERVER.SERVICES.GOOGLE_DNS.SUBLABEL")}
            rightAccessory={<RadioButton checkedValue={currentNameserver} value={"google_dns"} />}
            onPress={() => methods.setValue("dnsNameservers", "google_dns", { shouldDirty: true })}
            testID={`GOOGLE_DNS_ROW${currentNameserver === "google_dns" ? ".SELECTED" : ""}`}
          />
          <List.Item
            hidePressable
            leftAccessory={<Icon name="Gear" />}
            title={I18n.t("DNS_SERVER.SERVICES.OPEN_DNS.LABEL")}
            description={I18n.t("DNS_SERVER.SERVICES.OPEN_DNS.SUBLABEL")}
            rightAccessory={<RadioButton checkedValue={currentNameserver} value={"opendns"} />}
            onPress={() => methods.setValue("dnsNameservers", "opendns", { shouldDirty: true })}
            testID={`OPEN_DNS_ROW${currentNameserver === "opendns" ? ".SELECTED" : ""}`}
          />
          <List.Item
            hidePressable
            leftAccessory={<Icon name="Gear" />}
            title={I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.LABEL")}
            rightAccessory={<RadioButton checkedValue={currentNameserver} value={"custom"} />}
            onPress={() => {
              methods.setValue("dnsNameservers", "custom");
            }}
            testID={`CUSTOM_ROW${currentNameserver === "custom" ? ".SELECTED" : ""}`}
          >
            {currentNameserver === "custom" && (
              <Box gap="sm">
                <Form {...methods}>
                  <Form.Input
                    label={I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.PRIMARY")}
                    name="primaryDNSServer"
                    placeholder={IP_PLACEHOLDER}
                    keyboardType="number-pad"
                    testID="CUSTOM_NAMESERVER_INPUT.PRIMARY"
                  />
                  <Form.Input
                    label={I18n.t("DNS_SERVER.SERVICES.CUSTOM_DNS.SECONDARY")}
                    name="secondaryDNSServer"
                    placeholder={IP_PLACEHOLDER}
                    keyboardType="number-pad"
                    testID="CUSTOM_NAMESERVER_INPUT.SECONDARY"
                  />
                </Form>
              </Box>
            )}
          </List.Item>
        </List>
      </Box>
    </Screen>
  );
}
