import { I18n } from "@meraki/core/i18n";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { LineChart } from "@meraki/magnetic/charts";
import { Card, Numeral, RefreshControl, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useNetworkUsageblocks, useOrgNetwork } from "@meraki/shared/api";
import {
  formatAndParseKibibytes,
  formatKibibytes,
  useCachedTimestampFormatter,
} from "@meraki/shared/formatters";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
  useHomeSSID,
} from "@meraki/shared/redux";
import { RouteProp, useRoute } from "@react-navigation/native";
import { secondsToMilliseconds } from "date-fns";
import React from "react";

export function NetworkUsageScreen() {
  const route = useRoute<RouteProp<InsightsStackProps, "NetworkUsage">>();
  const { params: props } = route;
  const organizationId = useCurrentOrganizationId();
  const currentNetworkId = useCurrentNetworkId();
  const networkId = props.networkId ?? currentNetworkId;
  const timespan = useGlobalTimespan();
  const ssid = useHomeSSID();

  const timespanFormatter = useCachedTimestampFormatter(timespan);

  const { data: network, isLoading: isLoadingNetworks } = useOrgNetwork({
    organizationId,
    networkId,
  });
  const encryptedNetworkId = network?.eid;

  let totalUsage = 0;
  const {
    data: networkUsage,
    isLoading: isLoadingNetworkUseBlocks,
    refetch,
    isRefetching,
  } = useNetworkUsageblocks(
    {
      encryptedNetworkId,
      timespan,
      ssid,
    },
    {
      select: ({ netuseblocks }) => {
        return netuseblocks.map(([timestamp, received, sent]) => {
          totalUsage += received + sent;

          return {
            timestamp: secondsToMilliseconds(timestamp),
            received,
            sent,
          };
        });
      },
    },
  );

  const average = formatAndParseKibibytes(totalUsage && totalUsage / (networkUsage?.length ?? 1));
  return (
    <Screen
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
      addDefaultPadding
    >
      <Card
        loadingContentHeight={"medium"}
        loading={isLoadingNetworks || isLoadingNetworkUseBlocks}
      >
        <Card.Header
          title={I18n.t("USAGE_CHART.TITLE")}
          description={I18n.t("HOME.USAGE.SUBTITLE")}
        />
        <Card.Content justifyContent="center" alignItems="center" gap="none">
          <Numeral units={average.unit} value={average.value} />
          <Text size="p4" weight="semiBold">
            {I18n.t("USAGE_CHART.AVERAGE")}
          </Text>
        </Card.Content>
        <Card.Content>
          <LineChart
            size="lg"
            data={networkUsage ?? []}
            xAxisFormatter={timespanFormatter}
            yAxisFormatter={(value) => formatKibibytes(Number(value))}
            dimensions={[
              { name: "timestamp" },
              { name: "received", displayName: I18n.t("USAGE_CHART.DOWNLOAD") },
              { name: "sent", displayName: I18n.t("USAGE_CHART.UPLOAD") },
            ]}
            showAnimation={true}
            testID="NETWORK_USAGE_CHART"
          />
        </Card.Content>
      </Card>
    </Screen>
  );
}
