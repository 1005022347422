import {
  CLIENT_APPLICATION_USAGES_FAILURE,
  CLIENT_APPLICATION_USAGES_REQUEST,
  CLIENT_APPLICATION_USAGES_SUCCESS,
  CLIENT_LIST_JSON_FAILURE,
  CLIENT_LIST_JSON_REQUEST,
  CLIENT_LIST_JSON_SUCCESS,
  CLIENT_USAGE_HISTORIES_FAILURE,
  CLIENT_USAGE_HISTORIES_REQUEST,
  CLIENT_USAGE_HISTORIES_SUCCESS,
  GET_CLIENT_POLICIES_FAILURE,
  GET_CLIENT_POLICIES_REQUEST,
  GET_CLIENT_POLICIES_SUCCESS,
  WIRELESS_CLIENT_CONNECTIVITY_EVENTS_FAILURE,
  WIRELESS_CLIENT_CONNECTIVITY_EVENTS_REQUEST,
  WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS,
} from "@meraki/shared/redux";
import { isEmpty } from "lodash";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import I18n from "~/i18n/i18n";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, slimSsidsSelector, timespanState } from "~/selectors";
import { Client, ClientList } from "~/shared/types/Client";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

// we will migrate to this soon
const _getClientsPublicEndpoint =
  (
    isBlocked = false,
    ssidNumber?: number,
    timespan?: number,
  ): AppThunk<Promise<ApiResponseAction<ClientList>>> =>
  (dispatch, getState) =>
    dispatch({
      [CALL_API]: {
        types: [CLIENT_LIST_JSON_REQUEST, CLIENT_LIST_JSON_SUCCESS, CLIENT_LIST_JSON_FAILURE],
        endpoint: `/api/v1/networks/${currentNetworkState(getState())}/clients`,
        config: {
          method: "GET",
          queryParams: {
            getInternalData: true,
            isBlocked,
            perPage: 1000,
            timespan: timespan ?? timespanState(getState()),
            ...(ssidNumber && ssidNumber !== -1 ? { ssidNumber } : {}),
          },
        },
        meta: {
          ssids: slimSsidsSelector(getState()),
          timespan: getState().preferences.timespan,
        },
      },
    });

const buildUsageQueryParams = (
  clientIDs: string[],
  timespan: number,
  ssidNumber: number | null,
) => {
  const queryParams: any = {
    clients: clientIDs.join(","),
    timespan,
  };

  if (ssidNumber != null && ssidNumber !== -1) {
    queryParams["ssidNumber"] = ssidNumber;
  }

  return queryParams;
};

export const getClientPolicies =
  (networkId: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) =>
    dispatch({
      [CALL_API]: {
        types: [
          GET_CLIENT_POLICIES_REQUEST,
          GET_CLIENT_POLICIES_SUCCESS,
          GET_CLIENT_POLICIES_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/clients/policies`,
        config: {
          method: Method.get,
          queryParams: {
            timespan: timespanState(getState()),
          },
        },
      },
    });

// TODO: Replace above with new endpoint once out of alpha
export const getClientPoliciesNewEndpoint = (): AppThunk<Promise<object>> => (dispatch, getState) =>
  dispatch({
    [CALL_API]: {
      types: [
        GET_CLIENT_POLICIES_REQUEST,
        GET_CLIENT_POLICIES_SUCCESS,
        GET_CLIENT_POLICIES_FAILURE,
      ],
      endpoint: `/api/v1/networks/${currentNetworkState(getState())}/policies/byClient`,
      config: {
        method: Method.get,
        queryParams: {
          timespan: timespanState(getState()),
        },
      },
    },
  });

export const getClientUsageHistories =
  (
    clientIDs: string[],
    timespan: number,
    ssidNumber: number | null,
  ): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && !isEmpty(clientIDs) && timespan != null) {
      return dispatch({
        [CALL_API]: {
          types: [
            CLIENT_USAGE_HISTORIES_REQUEST,
            CLIENT_USAGE_HISTORIES_SUCCESS,
            CLIENT_USAGE_HISTORIES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/clients/usageHistories`,
          config: {
            method: Method.get,
            queryParams: buildUsageQueryParams(clientIDs, timespan, ssidNumber),
          },
        },
      });
    }
    return Promise.reject();
  };

export const getClientApplicationUsages =
  (clientIDs: string[], timespan: number, ssidNumber: number | null): AppThunk<Promise<any>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && !isEmpty(clientIDs) && timespan != null) {
      return dispatch({
        [CALL_API]: {
          types: [
            CLIENT_APPLICATION_USAGES_REQUEST,
            CLIENT_APPLICATION_USAGES_SUCCESS,
            CLIENT_APPLICATION_USAGES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/clients/applicationUsage`,
          config: {
            method: Method.get,
            queryParams: buildUsageQueryParams(clientIDs, timespan, ssidNumber),
          },
        },
      });
    }
    return Promise.reject();
  };

export const getWirelessClientConnectivityEvents =
  (client: Client): AppThunk<Promise<any>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId == null || client?.id == null) {
      return Promise.reject(I18n.t("NETWORK_ERROR"));
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          WIRELESS_CLIENT_CONNECTIVITY_EVENTS_REQUEST,
          WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS,
          WIRELESS_CLIENT_CONNECTIVITY_EVENTS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/wireless/clients/${client.id}/connectivityEvents`,
        config: {
          method: Method.get,
          queryParams: {
            perPage: 3,
            types: ["connection"],
          },
        },
        meta: { clientId: client.id },
      }),
    );
  };
