export enum OnboardingStage {
  getStarted = "getStarted",
  addHardware = "addHardware",
  scannedHardware = "scannedHardware",
  plugIn = "plugIn",
  connectUpgrade = "connectUpgrade",
  umbrellaExplainer = "umbrellaExplainer",
  clientVPNSetup = "clientVPNSetup",
  clientVPNInstruction = "clientVPNInstruction",
  notificationsRequest = "notificationsRequest",
  wifiSetup = "wifiSetup",
  wifiSuccess = "wifiSuccess",
  introTwoFactor = "introTwoFactor",
  setupTwoFactor = "setupTwoFactor",
  verifyTwoFactor = "verifyTwoFactor",
  successTwoFactor = "successTwoFactor",

  setupComplete = "setupComplete",
  alertsSettings = "alertsSettings",
  exit = "exit",
}

export enum OnboardingFlows {
  mainOnboarding = "mainOnboarding",
  addHardware = "addHarddware",
}

export enum OnboardingNodeStatus {
  unstarted = "unstarted",
  upgrading = "upgrading",
  connecting = "connecting",
  finished = "finished",
}

export interface OnboardingNodeBreakdown {
  config_up_to_date: boolean;
  firmware_up_to_date: boolean;
  active: boolean;
  serial: string;
}

export interface OnboardingNodes {
  [key: string]: OnboardingNodeStatus; // TODO: Update to a more concrete node status
}

// This is just to get us started with onboarding status
// I blieve this will need to change or we may need to scope
// this to just OnboardingFooterStatus.  We will find out more
// as we dive into the onbaording data and states the user can be
// in during the entire onboarding process.
export enum OnboardingFooterStatus {
  hardwareConnectFailed,
  hardwareConnecting,
  hardwareUpgrading,
  hardwareUnstarted,
  hardwareFinished,
}

export interface OnboardingScannedDevices {
  wireless?: boolean;
  switch?: boolean;
  appliance?: boolean;
  phone?: boolean;
  camera?: boolean;
}

export interface NextOnboardingStageConfig {
  nextStage: OnboardingStage;
  nextButtonText: string;
}

export interface OnboardingCounts {
  [OnboardingNodeStatus.finished]: number;
  [OnboardingNodeStatus.connecting]: number;
  [OnboardingNodeStatus.upgrading]: number;
  [OnboardingNodeStatus.unstarted]: number;
}

interface OnboardingDevice {
  name?: string;
}
export interface BatchOfDevices {
  [key: string]: OnboardingDevice;
}

export interface OnboardingBatchClaimResult {
  [key: string]: string;
}

export interface OnboardingReducer {
  [key: string]: {
    flow: OnboardingFlows;
    nodes: OnboardingNodes;
    skipped: boolean;
    stage: OnboardingStage;
    status: OnboardingNodeStatus;
    isComplete: boolean;
    isForced: boolean;
    onboardingStartTime: number | null;
    batchClaimResult: OnboardingBatchClaimResult;
  };
}
