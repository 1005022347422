import { READ_IN_WHATS_NEW, READ_NEW_FEATURE, READ_WHATS_NEW } from "@meraki/shared/redux";

import {
  Features,
  WhatsNewBreakdown,
  WhatsNewImport,
  WhatsNewReduxState,
} from "~/shared/types/WhatsNewTypes";

const initialState = { updated: false };

export default function whatsNew(state: WhatsNewReduxState = initialState, action: any) {
  switch (action.type) {
    case READ_IN_WHATS_NEW: {
      const { whatsNew } = action;
      const newState: WhatsNewReduxState = { updated: state.updated };

      for (const feature in Features) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const existingBreakDown: WhatsNewBreakdown = state[feature];
        const newImport: WhatsNewImport = whatsNew[feature];

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[feature] = {
          ...existingBreakDown,
          ...newImport,
        };

        if (!newState.updated && !existingBreakDown) {
          newState.updated = true;
        }
      }

      return newState;
    }
    case READ_NEW_FEATURE: {
      const { feature } = action;
      const newState = { ...state };

      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newState[feature].read = true;
      return newState;
    }
    case READ_WHATS_NEW: {
      const newState = { ...state };

      newState.updated = false;
      return newState;
    }
    default:
      return state;
  }
}
