import { calculateHighEventThreshold } from "@meraki/go/monitor";
import {
  GET_UMBRELLA_STATUS_SUCCESS,
  UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS,
  UMBRELLA_ACTIVITY_SUCCESS,
  UMBRELLA_HIGH_USAGE_IPS_SUCCESS,
  UMBRELLA_PROTECTION_GET_SUCCESS,
  UMBRELLA_PROTECTION_SET_SUCCESS,
  UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS,
  UMBRELLA_TOTAL_REQUESTS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import {
  EventCount,
  EventCountByIp,
  UmbrellaActivity,
  UmbrellaActivityByIp,
  UmbrellaProtection,
  UmbrellaStatus,
} from "~/shared/types/Umbrella";

interface UmbrellaReduxState {
  activity: UmbrellaActivity[];
  totalBlockedRequests: number;
  topDestinationsByIp: UmbrellaActivityByIp;
  activitiesByIp: UmbrellaActivityByIp;
  eventCountsByIp: { [id: string]: number };
  protection: undefined | UmbrellaProtection;
  status: undefined | UmbrellaStatus;
  protectionStatus: unknown;
}

const initialState: UmbrellaReduxState = {
  activity: [],
  totalBlockedRequests: 0,
  topDestinationsByIp: {},
  activitiesByIp: {},
  eventCountsByIp: {},
  protection: undefined,
  status: undefined,
  protectionStatus: undefined,
};

export default function umbrella(state = initialState, action: any) {
  switch (action.type) {
    case UMBRELLA_ACTIVITY_SUCCESS:
      const { resetActivity } = action.meta;
      return {
        ...state,
        activity: resetActivity
          ? action.response.data
          : state.activity.concat(action.response.data),
      };
    case UMBRELLA_TOTAL_REQUESTS_SUCCESS:
      return {
        ...state,
        totalBlockedRequests: action.response.data.count,
      };
    case UMBRELLA_HIGH_USAGE_IPS_SUCCESS:
      const countArray = action.response.data.map((element: EventCount) => element.count);
      const threshold = calculateHighEventThreshold(countArray);
      const eventCountsByIp = action.response.data.reduce(
        (accumulator: EventCountByIp, element: EventCount) => {
          return {
            ...accumulator,
            [element.ip]: {
              count: element.count,
              ip: element.ip,
              high: threshold !== undefined ? element.count > threshold : false,
            },
          };
        },
        {},
      );

      return {
        ...state,
        eventCountsByIp,
      };

    case UMBRELLA_TOP_DESTINATIONS_BY_IP_SUCCESS:
      const { ip } = action.meta;
      return {
        ...state,
        topDestinationsByIp: {
          ...state.topDestinationsByIp,
          [ip]: action.response.data,
        },
      };

    case UMBRELLA_ACTIVITIES_FOR_IP_SUCCESS: {
      const { ip, resetActivity } = action.meta;
      return {
        ...state,
        activitiesByIp: {
          ...state.activitiesByIp,
          [ip]: resetActivity
            ? action.response.data
            : state.activitiesByIp[ip].concat(action.response.data),
        },
      };
    }

    case GET_UMBRELLA_STATUS_SUCCESS:
      return {
        ...state,
        status: action.response,
      };
    case UMBRELLA_PROTECTION_GET_SUCCESS:
      return {
        ...state,
        protection: action.response,
      };
    case UMBRELLA_PROTECTION_SET_SUCCESS:
      return {
        ...state,
        protectionStatus: action.response,
        protection: action.meta,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
