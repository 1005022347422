import {
  ADMINS_FAILURE,
  ADMINS_REQUEST,
  ADMINS_SUCCESS,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DISABLE_CLIENT_VPN_AUTH_FAILURE,
  DISABLE_CLIENT_VPN_AUTH_REQUEST,
  DISABLE_CLIENT_VPN_AUTH_SUCCESS,
  ENABLE_CLIENT_VPN_AUTH_FAILURE,
  ENABLE_CLIENT_VPN_AUTH_REQUEST,
  ENABLE_CLIENT_VPN_AUTH_SUCCESS,
  GET_AUTH_USERS_FAILURE,
  GET_AUTH_USERS_REQUEST,
  GET_AUTH_USERS_SUCCESS,
  SET_ADMIN_FAILURE,
  SET_ADMIN_REQUEST,
  SET_ADMIN_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import {
  currentNetworkState,
  currentUserState,
  getAuthIdByEmail,
  getCurrentOrganization,
} from "~/selectors";
import { Schemas } from "~/shared/lib/Schemas";
import { AuthUserType } from "~/shared/types/AdminTypes";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

const baseEndPoint = (state: any) =>
  `/api/v1/organizations/${getCurrentOrganization(state)}/admins`;
const getEndPointWithId = (state: any, id: any) => `${baseEndPoint(state)}/${id}`;

export function fetchAdmins(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [ADMINS_REQUEST, ADMINS_SUCCESS, ADMINS_FAILURE],
        endpoint: baseEndPoint(getState()),
        config: {
          method: Method.get,
        },
        schema: Schemas.ADMIN_ARRAY,
        meta: {
          currentUserEmail: currentUserState(getState()),
        },
      }),
    );
}

export function setAdmin(options: any): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [SET_ADMIN_REQUEST, SET_ADMIN_SUCCESS, SET_ADMIN_FAILURE],
        endpoint: baseEndPoint(getState()),
        config: {
          method: Method.post,
          body: JSON.stringify(options),
        },
      }),
    );
  };
}

export function deleteAdmin(id: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    return dispatch(
      wrapApiActionWithCSRF({
        types: [DELETE_ADMIN_REQUEST, DELETE_ADMIN_SUCCESS, DELETE_ADMIN_FAILURE],
        endpoint: getEndPointWithId(getState(), id),
        config: {
          method: Method.delete,
        },
        meta: { id },
      }),
    );
  };
}

export function getAuthUsers(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [GET_AUTH_USERS_REQUEST, GET_AUTH_USERS_SUCCESS, GET_AUTH_USERS_FAILURE],
        endpoint: `/api/v1/networks/${currentNetworkState(getState())}/merakiAuthUsers`,
        config: {
          method: Method.get,
        },
      }),
    );
}

export function enableClientVPNAuth(email: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          ENABLE_CLIENT_VPN_AUTH_REQUEST,
          ENABLE_CLIENT_VPN_AUTH_SUCCESS,
          ENABLE_CLIENT_VPN_AUTH_FAILURE,
        ],
        endpoint: `/api/v1/networks/${currentNetworkState(getState())}/merakiAuthUsers`,
        config: {
          method: Method.post,
          // Client VPN auth is set by reserved SSID which is assigned the number 0
          body: JSON.stringify({
            email,
            accountType: AuthUserType.CLIENT_VPN,
            isAdmin: true,
            authorizations: [{ ssidNumber: 0 }],
          }),
        },
        meta: {
          email,
        },
      }),
    );
}

export function disableClientVPNAuth(email: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const networkId = currentNetworkState(state);
    const id = getAuthIdByEmail(state, { email, authType: AuthUserType.CLIENT_VPN });

    if (id == null || id.length < 1) {
      return Promise.reject();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          DISABLE_CLIENT_VPN_AUTH_REQUEST,
          DISABLE_CLIENT_VPN_AUTH_SUCCESS,
          DISABLE_CLIENT_VPN_AUTH_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/merakiAuthUsers/${id}`,
        config: {
          method: Method.delete,
        },
        meta: {
          email,
        },
      }),
    );
  };
}
