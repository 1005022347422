import { LivePorts, usePortStatuses } from "@meraki/react-live-broker";
import { AppliancePort, useAllAppliancePorts } from "@meraki/shared/api";

export type CombinedAppliancePort = LivePorts & AppliancePort;
export type CombinedAppliancePorts = CombinedAppliancePort[];

export const useCombinedAppliancePorts = (networkId: any, deviceId: any) => {
  const {
    data: apiPortData,
    refetch: refetchApiData,
    isLoading: isApiLoading,
  } = useAllAppliancePorts({
    networkId,
  });
  const livePortData = usePortStatuses(deviceId ?? "");

  const uplink = { number: 1 } as CombinedAppliancePort;
  const nonUplinkData = apiPortData as CombinedAppliancePort[];

  if (livePortData && livePortData.length > 0) {
    Object.assign(uplink, livePortData[0]);

    nonUplinkData.map((apiPort, index) => {
      if (livePortData[index + 1]) {
        Object.assign(apiPort, livePortData[index + 1]);
      }
    });
  }

  const combinedPortData = [uplink, ...(nonUplinkData ?? [])];
  return { combinedPortData, refetchApiData, isApiLoading };
};
