import { ApplicationUsage } from "@meraki/shared/api";

import { formatAndParseKibibytes } from "./BinaryUnitHelper";

export type ProcessedApplicationUsage = {
  applicationName: string;
  usagePerApplication: number;
  formattedUsage: number;
  unit: string;
  percentUsage?: number;
};

export const processClientRusage = (applicationUsages?: ApplicationUsage[]) => {
  let totalUsage = 0;

  const result: ProcessedApplicationUsage[] = [];
  if (!applicationUsages) {
    return result;
  }
  applicationUsages.forEach((usage) => {
    const usagePerApplication = usage.sent + (usage.received ?? 0);
    totalUsage += usagePerApplication;
    const { value: formattedUsage, unit } = formatAndParseKibibytes(usagePerApplication);
    result.push({
      applicationName: usage.application || "Other",
      usagePerApplication,
      formattedUsage,
      unit,
    });
  });
  result.forEach(
    (e) =>
      (e.percentUsage = parseFloat(((e.usagePerApplication / totalUsage) * 100).toPrecision(2))),
  );
  const sortedResults = result.sort((a, b) => b.usagePerApplication - a.usagePerApplication);
  return sortedResults;
};
