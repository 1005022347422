import { Box } from "@meraki/magnetic/layout";
import Svg, { Defs, Image, Mask, Path, Pattern, Rect, Use } from "react-native-svg";

import { BasePortKindProps, BasePortProps } from "../types";
import { Indicator } from "./Indicator";
import { PortColorMap } from "./portColorMap";
import { PortNumber } from "./PortNumber";

const viewBoxWidth = 44;
const viewBoxHeight = 44;

const smallSizeDelta = 20;

export function Sfp({
  flipped,
  state,
  indicator,
  selected,
  number,
  size = "md",
  testID,
}: BasePortProps & BasePortKindProps) {
  const colors = PortColorMap[state ?? "disconnected"][selected ? "selected" : "idle"];
  const striped = state === "reducedSpeed";

  const PortNumberComponent = <PortNumber number={number} selected={selected} size={size} />;

  return (
    <Box alignItems="center" gap="2xs" testID={testID}>
      {!flipped && PortNumberComponent}
      <Svg
        width={viewBoxWidth - (size === "md" ? 0 : smallSizeDelta)}
        height={viewBoxHeight - (size === "md" ? 0 : smallSizeDelta)}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        fill="none"
        pointerEvents="none"
      >
        {striped && (
          <Defs>
            <Pattern
              height="0.227273"
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="0.25"
            >
              <Use
                transform="translate(0.25) scale(0.0125 0.0113636) rotate(90)"
                xlinkHref="#image0_6942_14099"
              />
            </Pattern>
            <Image
              height="20"
              id="image0_6942_14099"
              width="20"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABKSURBVHgB7dPBCgAgCAPQ/Xn9+cIwKOgQtCDQXR3vogJTSFauKdjkqBcE86IMsx6UmA2hxAYowzqoxBj4Hv/FvCjDmJ9yjfHF1hsZzPUnWlXRXQAAAABJRU5ErkJggg=="
            />
          </Defs>
        )}
        <Rect width={viewBoxWidth} height={viewBoxHeight} rx="2" fill={colors.boxBackground} />
        {striped && (
          <Rect
            fill="url(#pattern0)"
            height={selected ? viewBoxHeight - 2 : viewBoxHeight}
            width={selected ? viewBoxWidth - 2 : viewBoxWidth}
            x={selected ? 2 : 1}
          />
        )}
        <Mask id="path-2-inside-1_5147_37015" fill="white">
          <Path fill-rule="evenodd" clip-rule="evenodd" d="M6 6H38V34V38H28V34H16V38H6V34V6Z" />
        </Mask>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 6H38V34V38H28V34H16V38H6V34V6Z"
          fill={colors.portBackground}
          translateX={flipped ? viewBoxWidth : 0}
          translateY={flipped ? viewBoxHeight : 0}
          rotation={flipped ? 180 : 0}
        />
        <Path
          d="M38 6H39V5H38V6ZM6 6V5H5V6H6ZM38 38V39H39V38H38ZM28 38H27V39H28V38ZM28 34H29V33H28V34ZM16 34V33H15V34H16ZM16 38V39H17V38H16ZM6 38H5V39H6V38ZM38 5H6V7H38V5ZM39 34V6H37V34H39ZM37 34V38H39V34H37ZM38 37H28V39H38V37ZM29 38V34H27V38H29ZM16 35H28V33H16V35ZM17 38V34H15V38H17ZM6 39H16V37H6V39ZM5 34V38H7V34H5ZM5 6V34H7V6H5Z"
          fill={colors.portBorder}
          mask="url(#path-2-inside-1_5147_37015)"
          translateX={flipped ? viewBoxWidth : 0}
          translateY={flipped ? viewBoxHeight : 0}
          rotation={flipped ? 180 : 0}
        />
        {selected && (
          <Rect
            x="1"
            y="1"
            width="42"
            height="42"
            rx="1"
            stroke={colors.portBackground}
            stroke-width="2"
          />
        )}
        <Indicator indicator={indicator} x={12} y={12} color={colors.iconBackground} />
      </Svg>
      {flipped && PortNumberComponent}
    </Box>
  );
}
