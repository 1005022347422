import { ThemeName } from "@meraki/core/theme";

import { SWITCHPORT_STATUS_TYPE } from "~/constants/SwitchportStatus";
import { checkPortStatus } from "~/lib/SwitchPortStatusUtils";
import { themeColors } from "~/lib/themeHelper";
import GeneralStatus from "~/shared/constants/Status";
import { STPGuard, SwitchPort } from "~/shared/types/models/SwitchPort";
import { STPGuardDisplayString } from "~/shared/types/viewModels/SwitchPort";

export function portStatusColor(disabled: any, isConnected: any, alerting: any, theme: ThemeName) {
  const currentThemeColors = themeColors(theme).port;

  const portDisabled = currentThemeColors.disabled;
  const portAlerting = currentThemeColors.alerting;
  const portActive = currentThemeColors.active;
  const portDisconnected = currentThemeColors.disconnected;

  if (disabled) {
    return portDisabled;
  }
  if (alerting) {
    return portAlerting;
  }
  if (isConnected) {
    return portActive;
  }
  return portDisconnected;
}

// These may need to be tweaked and updated.  Need to follow up with the new redesign.
export function detailsHeaderStatusForSwitchPort(switchPort: SwitchPort) {
  const status = switchPort ? checkPortStatus(switchPort) : undefined;

  switch (status) {
    case SWITCHPORT_STATUS_TYPE.STATUS_ERROR:
    case SWITCHPORT_STATUS_TYPE.STATUS_WARNING:
      return GeneralStatus.alerting;
    case SWITCHPORT_STATUS_TYPE.STATUS_DISCONNECTED:
      return GeneralStatus.bad;
    case SWITCHPORT_STATUS_TYPE.STATUS_SUBOPTIMAL:
    case SWITCHPORT_STATUS_TYPE.STATUS_OPTIMAL:
      return GeneralStatus.good;
    default:
      return undefined;
  }
}

export function displayStringForStpGuard(stpGuard: STPGuard): STPGuardDisplayString | undefined {
  switch (stpGuard) {
    case STPGuard.bpduGuard:
      return STPGuardDisplayString.bpduGuard;
    case STPGuard.disabled:
      return STPGuardDisplayString.disabled;
    case STPGuard.loopGuard:
      return STPGuardDisplayString.loopGuard;
    case STPGuard.rootGuard:
      return STPGuardDisplayString.rootGuard;
    default:
      return undefined;
  }
}
