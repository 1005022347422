import { I18n } from "@meraki/core/i18n";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  List,
  Notification,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useClients, useLocalStatus } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { SECONDS_IN_A_DAY, useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useRef } from "react";
import { getIpAddressSync } from "react-native-device-info";

import { useApPlacementRooms } from "../store/useApPlacementRooms";

export const useConnectedToNetworkByIp = (networkId?: string, targetIp?: string) => {
  return useClients(
    { networkId, timespan: SECONDS_IN_A_DAY },
    {
      select: (data) => {
        if (data.length === 0) {
          return false;
        }
        targetIp = targetIp || getIpAddressSync();
        return data.some((client) => client.ip === targetIp);
      },
    },
  );
};

type RoomSelectForm = {
  roomName: string;
};

export const RoomSelectScreen = () => {
  const networkId = useCurrentNetworkId();
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();

  const { data: connectedViaIp } = useConnectedToNetworkByIp(networkId);
  const { status: lspStatus, failureReason } = useLocalStatus({});

  const { roomsByNetwork, setRoomsByNetwork } = useApPlacementRooms();

  const addBottomSheet = useRef<BottomSheetMethods>(null);
  const methods = useForm<RoomSelectForm>({ values: { roomName: "" } });

  const connectedToNetwork = connectedViaIp || (failureReason === null && lspStatus === "success");

  const onSave = useCallback(
    ({ roomName }: RoomSelectForm) => {
      if (!networkId) {
        showErrorAlert(I18n.t("ACCESS_POINT.ROOM_SELECT.NETWORK_NOT_SELECTED"));
        return;
      }

      const cleanedRoomName = roomName.trim();
      if (cleanedRoomName === "") {
        methods.setError("roomName", {
          message: I18n.t("ACCESS_POINT.ROOM_SELECT.INPUT_ERROR.EMPTY"),
        });
        return;
      }
      const rooms = { ...(roomsByNetwork[networkId] || {}) };
      if (cleanedRoomName in rooms) {
        methods.setError("roomName", {
          message: I18n.t("ACCESS_POINT.ROOM_SELECT.INPUT_ERROR.EXISTS"),
        });
        return;
      }
      rooms[cleanedRoomName] = null;
      methods.clearErrors();
      methods.reset();
      setRoomsByNetwork(rooms, networkId);
      addBottomSheet.current?.close();
      navigation.navigate("APTest", { room: cleanedRoomName });
    },
    [methods, navigation, networkId, roomsByNetwork, setRoomsByNetwork],
  );

  return (
    <Screen addDefaultPadding>
      <Notification.Inline
        status={connectedToNetwork ? "positive" : "warning"}
        message={
          connectedToNetwork
            ? I18n.t("ACCESS_POINT.ROOM_SELECT.BANNER.DETECTED")
            : I18n.t("ACCESS_POINT.ROOM_SELECT.BANNER.NOT_DETECTED")
        }
      />

      <Box
        marginHorizontal="xs"
        marginTop="md"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          kind="tertiary"
          text={I18n.t("ADD")}
          trailingIcon="Plus"
          onPress={() => addBottomSheet.current?.present()}
          // disabled={!connectedToNetwork}
          testID="ADD_ROOM_BUTTON"
        />
      </Box>
      <List.FlashList
        paddingTop="none"
        paddingLeft="none"
        paddingRight="none"
        emptyState={{ title: I18n.t("ACCESS_POINT.ROOM_SELECT.EMPTY_LIST") }}
        data={networkId ? Object.keys(roomsByNetwork[networkId] ?? {}) : []}
        getItemData={(room) => {
          return {
            title: room,
            onPress: () => {
              if (!connectedToNetwork) {
                return;
              }
              navigation.push("APTest", { room });
            },
            hidePressable: !connectedToNetwork,
            testID: `ROOM_LIST.${room}`,
          };
        }}
      />
      <BottomSheet.Modal ref={addBottomSheet} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header title={I18n.t("ACCESS_POINT.ROOM_SELECT.INPUT")} />
        <BottomSheet.Content>
          <Form {...methods}>
            <Form.Input
              name="roomName"
              label={I18n.t("ACCESS_POINT.ROOM_SELECT.DESCRIPTION")}
              disabled={!connectedToNetwork}
              testID="ROOM_NAME_INPUT"
            />
          </Form>
          <Box paddingVertical="md">
            <Button
              text={I18n.t("ACCESS_POINT.ROOM_SELECT.SAVE_BUTTON")}
              onPress={methods.handleSubmit(onSave)}
              testID="SAVE_ROOM_BUTTON"
            />
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
