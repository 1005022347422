import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Wan({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <Path
        d="M12.7747 21.1299C17.7452 21.1299 21.7747 17.1004 21.7747 12.1299C21.7747 7.15932 17.7452 3.12988 12.7747 3.12988C7.8041 3.12988 3.77466 7.15932 3.77466 12.1299C3.77466 17.1004 7.8041 21.1299 12.7747 21.1299Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <Path
        d="M4.29028 9.12988H21.259"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.29028 15.1299H21.259"
        stroke={color1}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.7747 20.8861C14.8457 20.8861 16.5247 16.9658 16.5247 12.1299C16.5247 7.29393 14.8457 3.37363 12.7747 3.37363C10.7036 3.37363 9.02466 7.29393 9.02466 12.1299C9.02466 16.9658 10.7036 20.8861 12.7747 20.8861Z"
        stroke={color1}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </Svg>
  );
}
