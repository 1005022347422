import { AnyAction } from "redux";

import {
  NETWORK_TRACK_SETTINGS_SUCCESS,
  SET_CURRENT_CLUSTER,
  SET_CURRENT_LOCALE,
  SET_CURRENT_NETWORK,
  SET_CURRENT_ORGANIZATION,
  SET_CURRENT_SHARD_ID,
  SET_LATEST_SUPPORT_SUBMIT_TIME,
  WIPE_REDUX,
} from "../constants/ActionTypes";
import { Clusters } from "../types/Networks";

interface SetCurrentLocaleAction extends AnyAction {
  type: typeof SET_CURRENT_LOCALE;
}
interface NetworkTrackSettingsSuccessAction extends AnyAction {
  type: typeof NETWORK_TRACK_SETTINGS_SUCCESS;
}
interface WipeReduxAction extends AnyAction {
  type: typeof WIPE_REDUX;
}
interface SetLatestSupportSubmitTime extends AnyAction {
  type: typeof SET_LATEST_SUPPORT_SUBMIT_TIME;
}

export const setCurrentLocale = (locale: string) => ({
  type: SET_CURRENT_LOCALE,
  locale,
});

export const setCurrentCluster = (cluster: Clusters) => ({
  type: SET_CURRENT_CLUSTER,
  cluster,
});

export const setCurrentShardId = (shardId: number) => ({
  type: SET_CURRENT_SHARD_ID,
  shardId,
});

export const setCurrentOrganization = (organization: string) => ({
  type: SET_CURRENT_ORGANIZATION,
  organization,
});

export const setCurrentNetwork = (networkId?: string | null) => ({
  type: SET_CURRENT_NETWORK,
  networkId,
});

type SetCurrentNetworkAction = ReturnType<typeof setCurrentNetwork> & AnyAction;
type SetCurrentShardIdAction = ReturnType<typeof setCurrentShardId> & AnyAction;
type SetCurrentClusterAction = ReturnType<typeof setCurrentCluster> & AnyAction;
type SetCurrentOrganizationAction = ReturnType<typeof setCurrentOrganization> & AnyAction;

// These actions don't have their data payloads typed
type PoorlyTypedMkiConfActions =
  | NetworkTrackSettingsSuccessAction
  | SetCurrentOrganizationAction
  | SetCurrentClusterAction
  | SetCurrentLocaleAction
  | SetLatestSupportSubmitTime
  | WipeReduxAction;

export type MkiConfAction =
  | SetCurrentShardIdAction
  | SetCurrentNetworkAction
  | PoorlyTypedMkiConfActions;
