import { ColorToken, mapColorToken, Theme } from "@meraki/magnetic/themes";
import { ViewStyle } from "react-native";

export interface BasicStyle
  extends Pick<
    ViewStyle,
    | "height"
    | "width"
    | "maxHeight"
    | "maxWidth"
    | "minHeight"
    | "minWidth"
    | "aspectRatio"
    | "opacity"
    | "overflow"
    | "transform"
    | "zIndex"
    | "position"
    | "top"
    | "right"
    | "bottom"
    | "left"
  > {
  backgroundColor?: ColorToken;
}

export function mapBasicPropsToStyles(
  {
    backgroundColor,
    height,
    width,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    aspectRatio,
    opacity,
    overflow,
    transform,
    zIndex,
    position,
    top,
    right,
    bottom,
    left,
    ...rest
  }: BasicStyle,
  theme: Theme,
) {
  const style = {
    backgroundColor: mapColorToken(backgroundColor, theme),
    height,
    width,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    aspectRatio,
    opacity,
    overflow,
    transform,
    zIndex,
    position,
    top,
    right,
    bottom,
    left,
  };

  (Object.keys(style) as Array<keyof typeof style>).forEach(
    (key) => style[key] === undefined && delete style[key],
  );

  return { style, ...rest };
}
