import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  color: string;
  size?: number;
};

function MotionSearchIcon(props: Props) {
  const { color, size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M16.75 3.5H20.5V7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.25 20.5H3.5V16.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M20.5 16.75V20.5H16.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3.5 7.25V3.5H7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.4375 15.375C13.6121 15.375 15.375 13.6121 15.375 11.4375C15.375 9.26288 13.6121 7.5 11.4375 7.5C9.26288 7.5 7.5 9.26288 7.5 11.4375C7.5 13.6121 9.26288 15.375 11.4375 15.375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.2219 14.2219L16.5 16.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default MotionSearchIcon;
