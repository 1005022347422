import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function NetworkWide({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        clipRule="evenodd"
        d="M8.505 8.505c.158-1.03.384-1.966.666-2.765.288-.815.648-1.529 1.085-2.053.434-.52 1.02-.937 1.744-.937s1.31.418 1.744.937c.437.524.797 1.238 1.085 2.053.282.8.508 1.736.666 2.765 1.03.158 1.966.384 2.765.666.816.288 1.529.648 2.052 1.085.52.434.938 1.02.938 1.744s-.418 1.31-.938 1.744c-.523.437-1.236.797-2.052 1.085-.8.282-1.736.508-2.765.666a15.418 15.418 0 0 1-.666 2.765c-.288.816-.648 1.529-1.085 2.052-.434.52-1.02.938-1.744.938s-1.31-.418-1.744-.938c-.437-.523-.797-1.236-1.085-2.052-.282-.8-.508-1.736-.666-2.765a15.419 15.419 0 0 1-2.765-.666c-.815-.288-1.529-.648-2.053-1.085-.52-.434-.937-1.02-.937-1.744s.418-1.31.937-1.744c.524-.437 1.238-.797 2.053-1.085.8-.282 1.736-.508 2.765-.666Zm1.55-.179a13.3 13.3 0 0 1 .53-2.087c.256-.723.542-1.254.823-1.59.284-.341.488-.399.592-.399.104 0 .308.058.592.398.28.337.567.868.822 1.591.212.6.393 1.305.531 2.087a24.46 24.46 0 0 0-3.89 0Zm-1.729 5.619a13.3 13.3 0 0 1-2.087-.53c-.723-.256-1.254-.542-1.59-.823-.341-.284-.399-.488-.399-.592 0-.104.058-.308.398-.592.337-.28.868-.567 1.591-.822a13.3 13.3 0 0 1 2.087-.531 24.46 24.46 0 0 0 0 3.89Zm1.524.205c-.065-.682-.1-1.403-.1-2.15 0-.747.035-1.468.1-2.15.682-.065 1.403-.1 2.15-.1.747 0 1.468.035 2.15.1.065.682.1 1.403.1 2.15 0 .747-.035 1.468-.1 2.15-.682.065-1.403.1-2.15.1-.747 0-1.468-.035-2.15-.1Zm.205 1.524c.139.782.32 1.487.53 2.087.256.723.542 1.254.823 1.59.284.341.488.399.592.399.104 0 .308-.058.592-.398.28-.337.567-.868.822-1.591.212-.6.393-1.305.531-2.087a24.456 24.456 0 0 1-3.89 0Zm5.619-1.729c.782-.139 1.487-.32 2.087-.53.723-.256 1.254-.542 1.59-.823.341-.284.399-.488.399-.592 0-.104-.058-.308-.398-.592-.337-.28-.868-.567-1.591-.822-.6-.212-1.305-.393-2.087-.531a24.456 24.456 0 0 1 0 3.89Z"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M15.642 5.157c.21-.46.335-.967.355-1.501a9.288 9.288 0 0 1 4.347 4.347 3.98 3.98 0 0 0-1.501.355 7.785 7.785 0 0 0-3.201-3.2ZM18.843 15.642a7.785 7.785 0 0 1-3.201 3.2c.21.461.335.968.355 1.502a9.289 9.289 0 0 0 4.347-4.347 3.98 3.98 0 0 1-1.501-.355ZM5.157 15.642a7.785 7.785 0 0 0 3.201 3.2 3.98 3.98 0 0 0-.355 1.502 9.288 9.288 0 0 1-4.347-4.347 3.98 3.98 0 0 0 1.501-.355ZM5.157 8.358a7.785 7.785 0 0 1 3.201-3.2 3.98 3.98 0 0 1-.355-1.502 9.288 9.288 0 0 0-4.347 4.347 3.98 3.98 0 0 1 1.501.355Z"
        fill={color2}
      />
      <Path
        d="M12 5.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM20.5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14 20.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM3.5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill={color3}
      />
    </Svg>
  );
}
