import {
  BottomSheet,
  BottomSheetMethods,
  BottomSheetProps,
  Checkbox,
  ListItemProps,
  SearchBar,
} from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ForwardedRef, ReactNode, useState } from "react";
import { PressableProps } from "react-native";

import { SelectableTag, SelectableTagsList } from "../SelectableTagsList/SelectableTagsList";

type OptionValue = string | number | null;

export type PickerWithFilterOption<T extends OptionValue, K> = {
  label: string;
  description?: string;
  value: T;
  tags?: K[];
};

export type PickerWithFilterProps<
  T extends OptionValue,
  K,
> = React.RefAttributes<BottomSheetMethods> &
  Pick<BottomSheetProps, "snapPoints" | "enableContentPanningGesture"> & {
    title: string;
    onCancelPress?: PressableProps["onPress"];
    onResetPress?: PressableProps["onPress"];
    options: PickerWithFilterOption<T, K>[];
    filterTags?: SelectableTag<K>[];
    onSelectOption: (value: T) => void;
    selectedOptions?: T[];
    testID?: string;
    multiSelect?: boolean;
    hasSearchBar?: boolean;
    supportMultipleFilterTags?: boolean;
    footer?: ReactNode;
  };

export function InternalPickerWithFilter<T extends OptionValue, K>(
  {
    title,
    onCancelPress,
    onResetPress,
    options,
    filterTags,
    onSelectOption,
    selectedOptions,
    snapPoints,
    testID,
    multiSelect = false,
    hasSearchBar = false,
    supportMultipleFilterTags = false,
    enableContentPanningGesture = true,
    footer,
  }: PickerWithFilterProps<T, K>,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedFilterTags, setSelectedFilterTags] = useState<Set<K>>(new Set());

  const selectFilterTags = (value: K | null) => {
    const newSelected = new Set(selectedFilterTags);

    if (value === null) {
      if (newSelected.size > 0) {
        newSelected.clear();
      }
    } else {
      if (newSelected.has(value)) {
        newSelected.delete(value);
      } else {
        if (!supportMultipleFilterTags) {
          newSelected.clear();
        }
        newSelected.add(value);
      }
    }

    setSelectedFilterTags(newSelected);
  };

  const filteredOptions =
    selectedFilterTags.size > 0
      ? options.filter(({ tags }) => tags?.some((tag) => selectedFilterTags.has(tag)))
      : options;
  const searchedOptions =
    hasSearchBar && searchText
      ? filteredOptions.filter(
          ({ label, description }) =>
            label.toLowerCase()?.includes(searchText.toLowerCase()) ||
            description?.toLowerCase()?.includes(searchText.toLowerCase()),
        )
      : filteredOptions;
  return (
    <BottomSheet.Modal
      ref={ref}
      snapPoints={snapPoints}
      index={0}
      enableContentPanningGesture={enableContentPanningGesture}
    >
      <BottomSheet.Header title={title} onCancelPress={onCancelPress} onResetPress={onResetPress} />
      <BottomSheet.Content>
        {hasSearchBar ? (
          <SearchBar value={searchText} onChangeText={setSearchText} testID={`${testID}_SEARCH`} />
        ) : null}
        {filterTags?.length ? (
          <Box marginTop={hasSearchBar ? "sm" : "none"}>
            <SelectableTagsList<K>
              tags={filterTags}
              onSelect={selectFilterTags}
              selected={Array.from(selectedFilterTags)}
            />
          </Box>
        ) : null}
      </BottomSheet.Content>
      <BottomSheet.FlashList
        paddingTop="none"
        testID={testID}
        data={searchedOptions}
        getItemData={({ label, description, value }) => {
          const base: ListItemProps = {
            title: label,
            description: description,
            testID: `${testID ? `${testID}.` : ""}${label}`,
          };

          if (multiSelect) {
            return {
              ...base,
              leftAccessory: (
                <Checkbox
                  checked={!!selectedOptions?.includes(value)}
                  onValueChange={() => onSelectOption(value)}
                />
              ),
              onPress: () => {
                onSelectOption(value);

                if (typeof ref !== "function" && !multiSelect) {
                  setTimeout(() => ref?.current?.dismiss());
                }
              },
              hidePressable: true,
            };
          } else {
            return {
              ...base,
              kind: "radio",
              radioValue: value,
              checkedRadioValue: selectedOptions?.[0] ?? null,
              onRadioValueChanged: (value) => onSelectOption(value as T),
            };
          }
        }}
      />
      <BottomSheet.Content>{footer}</BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
