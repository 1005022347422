import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, List, Loader, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  SwitchPort,
  useClients,
  useSwitchPorts,
  useUpdateSwitchPort,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback } from "react";
import { Alert } from "react-native";

export const MACAllowListScreen = () => {
  const {
    params: { switchPortId, serial },
  } = useRoute<RouteProp<DeviceGroupProps, "MACAllowList">>();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();
  const { data: clients } = useClients({ networkId, timespan });
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const updateSwitchPort = useUpdateSwitchPort();

  const { data: switchPort, isLoading: isPortLoading } = useSwitchPorts(
    {
      serial,
    },
    {
      select(data) {
        return data.find((port) => port.portId === switchPortId);
      },
    },
  );

  const isExistingClient = (mac: string) => {
    return clients?.find((client) => client.mac.toLowerCase() === mac.toLowerCase())?.description;
  };

  const removeMac = useCallback(
    (mac: string) => {
      const macAllowList = switchPort?.macAllowList?.filter(
        (thisMac) => thisMac.toLowerCase() !== mac.toLowerCase(),
      );
      updateSwitchPort.mutate(
        {
          serial,
          portId: switchPortId,
          switchPort:
            macAllowList?.length === 0
              ? ({ accessPolicyType: "Open" } as SwitchPort)
              : ({ macAllowList } as SwitchPort),
        },
        {
          onSuccess() {
            queryClient.invalidateQueries({ queryKey: useSwitchPorts.queryKey({ serial }) });
          },
          onError(error) {
            Alert.alert(String(error.errors));
          },
        },
      );
    },
    [serial, switchPort?.macAllowList, switchPortId, updateSwitchPort],
  );

  if (!switchPort) {
    return (
      <Screen testID="DETAILS_TAB">
        <Loader.Spinner testID="DETAILS_LOADING_SPINNER" />
      </Screen>
    );
  }

  return (
    <Screen addDefaultPadding>
      <Box padding="none" bottomDividerBorder>
        <Heading size="h1">{I18n.t("MAC_ALLOW_LIST.TITLE")}</Heading>
      </Box>
      <Box padding="none">
        <Text size="p1">
          {I18n.t("MAC_ALLOW_LIST.HEADER.DESCRIPTION.SINGLE", { port: switchPortId })}
        </Text>
      </Box>
      <Box>
        <Box
          marginHorizontal="xs"
          paddingBottom="2xs"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="h4">{I18n.t("MAC_ALLOW_LIST.TABLE_HEADER")}</Heading>
          <Button
            kind="tertiary"
            text={I18n.t("ADD")}
            trailingIcon="Plus"
            onPress={() => navigation.navigate("MACAllowListConfigure", { switchPortId, serial })}
            testID="ADD_DEVICE_BUTTON"
          />
        </Box>
        <List.FlashList
          paddingLeft="none"
          paddingRight="none"
          paddingTop="none"
          data={switchPort?.macAllowList}
          loading={isPortLoading}
          ListEmptyComponent={
            <Card>
              <Text color="light">{I18n.t("NOT_ADDED")}</Text>
            </Card>
          }
          getItemData={(mac) => {
            return {
              title: isExistingClient(mac) ?? mac,
              description: mac,
              rightAccessory: (
                <Button
                  kind="tertiary"
                  text={I18n.t("DELETE")}
                  onPress={() => removeMac(mac)}
                  testID={`DELETE_${mac}`}
                />
              ),
            };
          }}
        />
      </Box>
    </Screen>
  );
};
