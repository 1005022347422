import { Linking } from "react-native";
import PushNotification, { PushNotificationPermissions } from "react-native-push-notification";

type NotificationPermissionResult = "none" | "denied" | "granted";

const isAlertPermissionDenied = (permissions?: PushNotificationPermissions) =>
  permissions && !permissions.alert;

export function checkHasAlertNotificationPermissions() {
  return new Promise<NotificationPermissionResult>((resolve) => {
    PushNotification.checkPermissions((permissions) => {
      if (permissions == null) {
        resolve("none");
        return;
      }

      if (isAlertPermissionDenied(permissions)) {
        resolve("denied");
        return;
      }

      resolve("granted");
    });
  });
}

let requestingPermissions = false;
export async function setupAlertPermissions(isManualRequest = false) {
  const alertPermissions = await checkHasAlertNotificationPermissions();
  if (requestingPermissions) {
    return;
  }

  switch (alertPermissions) {
    case "denied":
      if (isManualRequest) {
        Linking.openSettings();
      }
      break;
    case "none": {
      requestingPermissions = true;
      const requestedPermissions = await PushNotification.requestPermissions();
      requestingPermissions = false;

      if (isAlertPermissionDenied(requestedPermissions)) {
        throw Error("Alert push permissions denied");
      }
      return;
    }
  }
}
