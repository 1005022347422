import { I18n } from "@meraki/core/i18n";
import { Card, Loader, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  OrganizationDeviceStatusOverview,
  ProductType,
  useOrganizationDeviceStatusOverview,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useQueries } from "@tanstack/react-query";

import { DeviceTypeCard } from "./DeviceTypeCard";

const totalDevices = (
  statusOverview: OrganizationDeviceStatusOverview["counts"]["byStatus"],
): number =>
  statusOverview.alerting + statusOverview.dormant + statusOverview.offline + statusOverview.online;

const PRODUCTS: ProductType[] = [
  "appliance",
  "switch",
  "wireless",
  "camera",
  "sensor",
  "cellularGateway",
];

interface DeviceSummaryCardsProps {
  isForCurrentNetwork?: boolean;
  onPress?: (
    productType: ProductType | "uplinks",
    status: OrganizationDeviceStatusOverview["counts"]["byStatus"],
  ) => void;
  onAlertPress?: (
    productType: ProductType | "uplinks",
    status: OrganizationDeviceStatusOverview["counts"]["byStatus"],
  ) => void;
}

export const DeviceSummaryCards = ({ onPress, onAlertPress }: DeviceSummaryCardsProps) => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  /*
  TODO: Unhide showUplinksCard when screen is built
  https://meraki.atlassian.net/browse/DM-5920

  const { data: uplinks, isLoading: isLoadingUplinks } = useUplinkStatuses(
    { organizationId },
    {
      select: (data) => {
        const uplinkStatuses: { [_ in UplinkStatusState]: number } = {
          active: 0,
          ready: 0,
          connecting: 0,
          "not connected": 0,
          failed: 0,
        };
        for (const network of data) {
          for (const uplink of network.uplinks) {
            const { status } = uplink;
            uplinkStatuses[status] += 1;
          }
        }
        return {
          online: uplinkStatuses.active,
          alerting: uplinkStatuses["not connected"],
          dormant: 0,
          offline: uplinkStatuses["not connected"] + uplinkStatuses.failed,
        };
      },
    },
  );
  const uplinkCounts = uplinks ?? {
    online: 0,
    alerting: 0,
    dormant: 0,
    offline: 0,
  };
  const totalUplinks = Object.values(uplinkCounts ?? {}).reduce((inc, count) => inc + count, 0);
  const showUplinks = !networkId && totalUplinks > 0;
  */

  const statusQueries = useQueries({
    queries: PRODUCTS.map(
      (productType) =>
        useOrganizationDeviceStatusOverview.useQueries(
          {
            organizationId,
            networkId: networkId,
            body: { productTypes: [productType] },
          },
          { select: (response) => response?.counts?.byStatus },
        ) ?? [],
    ),
  });

  if (statusQueries.some((query) => query.isLoading)) {
    return (
      <Box gap="xs">
        <Box flexDirection="row" gap="xs">
          <Loader.Skeleton color="strong" width="48%" height={70} />
          <Loader.Skeleton color="strong" width="48%" height={70} />
        </Box>
        <Box flexDirection="row" gap="xs">
          <Loader.Skeleton color="strong" width="48%" height={70} />
          <Loader.Skeleton color="strong" width="48%" height={70} />
        </Box>
      </Box>
    );
  }

  if (statusQueries.every((query) => query.isError)) {
    return (
      <Card>
        <Text>{I18n.t("ERRORS.DEVICE_HEALTH_SERVER_ERROR")}</Text>
      </Card>
    );
  }

  const [
    showApplianceStatus,
    showSwitchStatus,
    showWirelessStatus,
    showCameraStatus,
    showSensorStatus,
    showCellularGatewayStatus,
  ] = statusQueries.map((query) => query.isSuccess && totalDevices(query.data) > 0);

  return (
    <Box flexDirection="row" flexWrap="wrap" gap="xs" justifyContent="space-between">
      {/* 
      TODO: Unhide showUplinksCard when screen is built
      https://meraki.atlassian.net/browse/DM-5920
      {showUplinks && (
        <Card
          flexDirection="row"
          alignItems="center"
          flexBasis="48%"
          paddingHorizontal="xs"
          onPress={() => onPress?.("uplinks", uplinkCounts)}
        >
          <ProductIcon name={"uplinks"} />
          <Box flex={1}>
            <Heading size="h3">{I18n.t(`PRODUCT_TYPE.UPLINK`)}</Heading>
            <Text size="p2" color="light">
              {totalUplinks}
            </Text>
          </Box>
          {uplinkCounts !== undefined &&
            (uplinkCounts.offline > 0 || uplinkCounts.alerting > 0) && (
              <Box flexDirection="row">
                <Pressable onPress={() => onAlertPress?.("uplinks", uplinkCounts)}>
                  <Badge.StackedCounter
                    negative={uplinkCounts.offline}
                    warning={uplinkCounts.alerting}
                  />
                </Pressable>
              </Box>
            )}
        </Card>
      )} */}
      {showApplianceStatus && (
        <DeviceTypeCard
          productType="appliance"
          onPress={(statuses) => onPress?.("appliance", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("appliance", statuses)}
        />
      )}
      {showCellularGatewayStatus && (
        <DeviceTypeCard
          key="cellularGateway"
          productType="cellularGateway"
          onPress={(statuses) => onPress?.("cellularGateway", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("cellularGateway", statuses)}
        />
      )}
      {showSwitchStatus && (
        <DeviceTypeCard
          key="switch"
          productType="switch"
          onPress={(statuses) => onPress?.("switch", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("switch", statuses)}
        />
      )}
      {showWirelessStatus && (
        <DeviceTypeCard
          key="wireless"
          productType="wireless"
          onPress={(statuses) => onPress?.("wireless", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("wireless", statuses)}
        />
      )}
      {showCameraStatus && (
        <DeviceTypeCard
          key="camera"
          productType="camera"
          onPress={(statuses) => onPress?.("camera", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("camera", statuses)}
        />
      )}
      {showSensorStatus && (
        <DeviceTypeCard
          key="sensor"
          productType="sensor"
          onPress={(statuses) => onPress?.("sensor", statuses)}
          onAlertPress={(statuses) => onAlertPress?.("sensor", statuses)}
        />
      )}
    </Box>
  );
};
