import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useQuery } from "@tanstack/react-query";

import switchKeys from "~/api/queries/switches/keys";
import { SwitchSettings, SwitchSettingsSchema } from "~/api/schemas/SwitchSettings";
import { validatedMerakiRequest } from "~/api/util/request";

const switchSettingKeys = {
  switchSettings: (networkId?: string) => [...switchKeys.switchSettings, networkId] as const,
};

const fetchSwitchSettings = (networkId: string): Promise<SwitchSettings> => {
  return validatedMerakiRequest(
    SwitchSettingsSchema,
    "GET",
    `/api/v1/networks/${networkId}/switch/settings`,
  );
};
export const useSwitchSettings = () => {
  const networkId = useCurrentNetworkId();

  return useQuery({
    queryKey: switchSettingKeys.switchSettings(networkId),
    queryFn: () => {
      if (networkId === undefined) {
        throw new Error("No current network set in Redux");
      }
      return fetchSwitchSettings(networkId);
    },
  });
};
