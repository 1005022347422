import { Device } from "@meraki/shared/api";
import { createContext, useContext } from "react";

type DeviceDetailsContextValue = {
  device?: Device;
  goToTab?: (key: string) => void;
};

export const DeviceDetailsContext = createContext<DeviceDetailsContextValue>({
  goToTab: () => undefined,
});

export function useDeviceDetails() {
  return useContext(DeviceDetailsContext);
}
