import * as NodeStatus from "../handlers/NodeStatus";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useDeviceStatus(deviceId: string): LiveTool<boolean> {
  const msg = useLiveBroker<BaseDeviceSubProps, NodeStatus.Message>({
    type: "NodeStatus",
    deviceId,
  });

  return useCachedFormatter(msg, NodeStatus.formatMsg);
}
