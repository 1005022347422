export const BAR_GRAPH_COLOR_PROPS = [
  "barChartDefaultFill",
  "goodBar",
  "online",
  "alerting",
  "offline",
  "offlineBar",
  "lblue",
  "lpink",
  "lgreen",
  "lpurple",
  "mblue",
  "lpurple",
  "morange",
  "lorange",
  "mpink",
  "mgreen",
  "mpurple",
  "othergrey",
  "pie1",
  "pie2",
  "pie3",
  "pie4",
  "pie5",
  "pie6",
  "pie7",
  "applicationDetailsClientBar",
  "lightGreenWarningStatus",
] as const;
