import lib from "cometd";

export type Callback = lib.Callback;
export type Subscription = lib.SubscriptionHandle;

type Request = Record<string, unknown>;

export default class LiveBroker {
  private static url?: string;
  private static connections = new Map<number, lib.CometD>();
  private static requests = new Map<number, Request>();
  static onException?: (exception: string) => void;

  static setUrl(url: string): void {
    this.url = url;
  }

  private static async getCometdInstance(subscriptionId?: number): Promise<lib.CometD> {
    const url = this.url;
    if (!url) {
      throw Error("Unable to initialize cometd without a url/saved url");
    }

    return new Promise((resolve, reject) => {
      if (subscriptionId && this.connections.has(subscriptionId)) {
        return resolve(this.connections.get(subscriptionId) as lib.CometD);
      }
      const cometd = new lib.CometD();

      // Un-registering these will prevent cometd from attempting to connect via this method.
      cometd.unregisterTransport("websocket");
      // callback-polling uses JSONP and assumes there's a document object, which isn't true in our case.
      cometd.unregisterTransport("callback-polling");

      // do handshake
      cometd.configure({ url, useWorkerScheduler: false });
      this.setOnListenerExceptionHandler(cometd);

      cometd.handshake((handshake: { successful: boolean }) => {
        if (handshake?.successful === true) {
          return resolve(cometd);
        }

        return reject("Cometd handshake failed");
      });
    });
  }

  private static setOnListenerExceptionHandler(cometd: lib.CometD): void {
    cometd.onListenerException = (
      exception: string,
      subscriptionHandle: lib.SubscriptionHandle,
      isListener: boolean,
    ) => {
      if (isListener) {
        cometd.removeListener(subscriptionHandle);
      } else {
        this.unsubscribe(subscriptionHandle);
      }

      this.onException?.(exception);
    };
  }

  static async subscribe(
    request: Request,
    channel: string,
    callback: Callback,
  ): Promise<Subscription> {
    try {
      const cometd = await this.getCometdInstance();
      cometd.startBatch();
      const subscription = cometd.subscribe(channel, callback);
      cometd.publish("/requests", request);
      cometd.endBatch();

      this.connections.set(subscription.id, cometd);
      this.requests.set(subscription.id, request);

      return Promise.resolve(subscription);
    } catch (e) {
      console.warn(e);
      return Promise.reject("Failed to subscribe to: " + channel);
    }
  }

  static async resubscribe(subscription: Subscription): Promise<boolean> {
    try {
      const cometd = await this.getCometdInstance(subscription.id);
      const request = this.requests.get(subscription.id);

      if (request) {
        cometd.batch(() => {
          cometd.resubscribe(subscription);
          cometd.publish("/requests", request);
        });

        return Promise.resolve(true);
      }

      throw Error("Error, calling resubscribe without first subscribing!");
    } catch (e) {
      console.warn(e);
      return Promise.reject("Failed to resubscribe to: " + subscription.channel);
    }
  }

  static async unsubscribe(subscription: Subscription): Promise<boolean> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const cometd = await this.getCometdInstance(subscription.id);

        cometd.batch(() => {
          cometd.unsubscribe(subscription);
          cometd.disconnect((message: lib.Message) => {
            if (message.successful) {
              this.connections.delete(subscription.id);
              this.requests.delete(subscription.id);
              resolve(true);
            }

            reject("Failed to disconnect after unsubscribing");
          });
        });
      } catch (e) {
        console.warn(e);
        reject("Failed to unsubscribe from: " + subscription.channel);
      }
    });
  }
}
