import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button, Card, Heading, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  useDeleteNodeImages,
  useDeviceBySerial,
  useNodeImages,
  useUpdateDeviceBySerial,
  useUpdateNodeImages,
} from "@meraki/shared/api";
import { Map, PhotoSelect } from "@meraki/shared/components";
import { Form, useForm } from "@meraki/shared/form";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "react-native";

// default coordination is of San Francisco
const DEFAULT_LAT = 37.7749;
const DEFAULT_LONG = -122.4194;
const LATTITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = 0.0421;

interface AddressForm {
  address?: string;
  moveMapMarker: boolean;
}

interface ImageState {
  content: string;
  extension: string;
}

export const DeviceMountLocationScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const { params } = useRoute<RouteProp<DeviceGroupProps, "DeviceMountLocation">>();
  const { serialNumber } = params;

  const { data: device } = useDeviceBySerial({ serial: serialNumber });
  const { data: deviceImage, isLoading: isDeviceImageLoading } = useNodeImages(
    { nodeId: device?.id },
    {
      select: (data) => ({
        id: "id" in data ? data?.id : undefined,
        image: "image_urls" in data ? data?.image_urls?.medium : undefined,
      }),
    },
  );

  const updateDevice = useUpdateDeviceBySerial();
  const updateNodeImages = useUpdateNodeImages();
  const deleteNodeImages = useDeleteNodeImages();

  const methods = useForm<AddressForm>({
    defaultValues: {
      address: device?.address,
      moveMapMarker: false,
    },
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [clearImage, setClearImage] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<ImageState | undefined>(undefined);

  const onSubmit = methods.handleSubmit(({ address, moveMapMarker }) => {
    if (address && address !== device?.address) {
      updateDevice.mutate(
        {
          serial: serialNumber,
          body: {
            address,
            moveMapMarker,
          },
        },
        {
          onError: (error) => Alert.alert(String(error)),
        },
      );
    }
  });

  const onSave = useCallback(async () => {
    await onSubmit();

    if (device?.id) {
      if (deviceImage?.id && clearImage) {
        deleteNodeImages.mutate(
          {
            nodeId: device.id,
            imageId: deviceImage.id,
          },
          {
            onError: (_) => Alert.alert(I18n.t("ERRORS.SERVER_ERROR_TEXT")),
            onSuccess: () => setEditMode(false),
          },
        );
      } else if (newImage) {
        updateNodeImages.mutate(
          {
            nodeId: device.id,
            image: newImage,
          },
          {
            onError: (_) => Alert.alert(I18n.t("ERRORS.SERVER_ERROR_TEXT")),
            onSuccess: () => setEditMode(false),
          },
        );
      } else {
        setEditMode(false);
      }
    } else {
      setEditMode(false);
    }
  }, [clearImage, deleteNodeImages, device, deviceImage, newImage, onSubmit, updateNodeImages]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          onPress={editMode ? onSave : () => setEditMode(true)}
          text={I18n.t(editMode ? "SAVE" : "EDIT")}
        />
      ),
    });
  }, [editMode, navigation, onSave]);

  const latitude = device?.lat ?? DEFAULT_LAT;
  const longitude = device?.lng ?? DEFAULT_LONG;
  return (
    <Screen gap="2xl" addDefaultPadding>
      <Box gap="2xl">
        <Box gap="md">
          <Heading size="h3">{I18n.t("DEVICE_DETAILS.LOCATION")}</Heading>
          {editMode ? (
            <Box gap="md">
              <Heading size="h4">{I18n.t("EDIT_DEVICE.ADDRESS.TITLE")}</Heading>
              <Form {...methods}>
                <Form.Input
                  height={100}
                  name="address"
                  placeholder={I18n.t("EDIT_DEVICE.ADDRESS.PLACEHOLDER")}
                  returnKeyType="next"
                  autoCapitalize="none"
                  autoCorrect={false}
                  multiline={true}
                  testID="DEVICE_MOUNT_LOCATION.FORM.INPUT"
                />
                <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Heading size="h4">{I18n.t("EDIT_DEVICE.MOVE_MARKER")}</Heading>
                  <Form.Toggle name="moveMapMarker" testID="DEVICE_MOUNT_LOCATION.FORM.TOGGLE" />
                </Box>
              </Form>
            </Box>
          ) : (
            <Box gap="sm">
              <Map
                region={{
                  latitude,
                  longitude,
                  latitudeDelta: LATTITUDE_DELTA,
                  longitudeDelta: LONGITUDE_DELTA,
                }}
                pitchEnabled={false}
                rotateEnabled={false}
                scrollEnabled={false}
                zoomEnabled={false}
                testID="DEVICE_MOUNT_LOCATION.MAP"
              >
                <Map.Marker coordinate={{ latitude, longitude }} />
              </Map>
              <Text>{device?.address}</Text>
            </Box>
          )}
        </Box>
        <Box gap="md">
          <Heading size="h3">{I18n.t("DEVICE_DETAILS.MOUNTING_PHOTO")}</Heading>
          <Card>
            <PhotoSelect
              loading={isDeviceImageLoading}
              disabled={!editMode}
              sourceUri={clearImage ? undefined : newImage ? newImage.content : deviceImage?.image}
              onPhotoSelect={({ content, extension }) => {
                setClearImage(false);
                if (content && extension) {
                  setNewImage({
                    extension,
                    content: `data:image/${extension};base64,${content}`,
                  });
                }
              }}
              testID="DEVICE_MOUNT_LOCATION.PHOTO"
            />
            {editMode && (newImage ?? deviceImage?.image) && !clearImage && (
              <Box position="absolute" right={0}>
                <Button.Icon
                  icon="XCircle"
                  onPress={() => {
                    setNewImage(undefined);

                    if (deviceImage?.image) {
                      setClearImage(true);
                    }
                  }}
                  testID="DEVICE_MOUNT_LOCATION.CLEAR_IMAGE"
                />
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </Screen>
  );
};
