import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import UmbrellaExcludedURLsScreen from "~/go/screens/umbrella/UmbrellaExcludedURLsScreen";
import UmbrellaExcludeURLScreen from "~/go/screens/umbrella/UmbrellaExcludeURLScreen";
import UmbrellaPoliciesScreen from "~/go/screens/umbrella/UmbrellaPoliciesScreen";

import { UmbrellaScreensPropMap } from "./Types";

const ForwardedUmbrellaExcludedURLsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<UmbrellaScreensPropMap, "UmbrellaExcludedURLs">
>()(UmbrellaExcludedURLsScreen);
const ForwardedUmbrellaExcludeURLScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<UmbrellaScreensPropMap, "UmbrellaExcludeURL">
  >()(UmbrellaExcludeURLScreen);
const ForwardedUmbrellaPoliciesScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<UmbrellaScreensPropMap, "UmbrellaPolicies">
  >()(UmbrellaPoliciesScreen);

export function umbrellaScreens<T extends UmbrellaScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="UmbrellaExcludedURLs"
        component={ForwardedUmbrellaExcludedURLsScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("UMBRELLA.EXCLUDE_URL.TITLE"),
        }}
      />
      <Stack.Screen
        name="UmbrellaExcludeURL"
        component={ForwardedUmbrellaExcludeURLScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("UMBRELLA.EXCLUDED_URLS.TITLE"),
        }}
      />
      <Stack.Screen
        name="UmbrellaPolicies"
        component={ForwardedUmbrellaPoliciesScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("UMBRELLA.POLICY.SCREEN_TITLE"),
        }}
      />
    </>
  );
}
