import { I18n } from "@meraki/core/i18n";

import {
  SWITCHPORT_STATUS_FLAGS,
  SWITCHPORT_STP_FLAGS,
  SWITCHPORT_USAGE_FLAGS,
} from "~/constants/SwitchportStatus";

export const UDLD_ERROR_STRINGS = {
  [SWITCHPORT_STATUS_FLAGS.UDLD_ERR_TX_RX_LOOP]: I18n.t("PORT_STATUS.UDLD_ERRORS.TX_RX_LOOP"),
  [SWITCHPORT_STATUS_FLAGS.UDLD_ERR_N_MISMATCH]: I18n.t("PORT_STATUS.UDLD_ERRORS.N_MISMATCH"),
  [SWITCHPORT_STATUS_FLAGS.UDLD_ERR_UNIDIR]: I18n.t("PORT_STATUS.UDLD_ERRORS.UNIDIR"),
};

/* Copied from private/javascripts/SwitchportStatusUtils.js
 * All the logic for error coding is done in checkPortStatus
 */

export const switchportUDLDMessageText = (port: any) => {
  const udldErrorString =
    UDLD_ERROR_STRINGS[port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_ERR_MASK];

  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_PORT_BLOCKED) {
    return I18n.t("PORT_STATUS.PORT_ERRORS.UDLD_PORT_ERROR_WITH_BLOCKED_PORT", {
      udld_error: udldErrorString,
    });
  }
  return I18n.t("PORT_STATUS.PORT_ERRORS.UDLD_PORT_ERROR", { udld_error: udldErrorString });
};

export const switchPortErrorMessages = (port: any) => {
  const messages: string[] = [];

  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.POE_OVERLOAD) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.POE_OVERLOAD"));
  }
  if (
    port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_PORT_BLOCKED &&
    port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_ERR_MASK
  ) {
    messages.push(switchportUDLDMessageText(port));
  }
  if (port.stp_flags & SWITCHPORT_STP_FLAGS.STP_LOOPGUARD_ACTIVE) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.STP_LOOPGUARD_ACTIVE"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.CRC_ERROR) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.CRC_ERROR"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.JABBERS_ERROR && port.isAdmin) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.JABBERS_ERROR"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.FRAGS_ERROR) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.FRAGS_ERROR"));
  }
  if (
    port.usageStatus & SWITCHPORT_USAGE_FLAGS.MISSIZED_ERROR &&
    port.isAdmin &&
    !port.isStackport
  ) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.MISSIZED_ERROR"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.STP_TCS_ERROR) {
    messages.push(I18n.t("PORT_STATUS.PORT_ERRORS.STP_TCS_ERROR"));
  }
  return messages;
};

export const switchPortWarningMessages = (port: any) => {
  const messages: string[] = [];

  if (port.stp_flags & SWITCHPORT_STP_FLAGS.STP_BPDU_CONFLICT) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.STP_BPDU_CONFLICT"));
  }
  if (port.stp_flags & SWITCHPORT_STP_FLAGS.STP_BPDUGUARD_ACTIVE) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.STP_BPDU_CONFLICT"));
  }
  if (port.stp_flags & SWITCHPORT_STP_FLAGS.STP_ROOTGUARD_ACTIVE) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.STP_ROOTGUARD_ACTIVE"));
  }
  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.SPEED_DOWNSHIFT) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.SPEED_DOWNSHIFT"));
  }
  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.POE_DENIED) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.POE_DENIED"));
  }
  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.AP_NOAUTH) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.AP_NOAUTH"));
  }
  if (port.curr_status & SWITCHPORT_STATUS_FLAGS.UDLD_ERR_MASK) {
    messages.push(switchportUDLDMessageText(port));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.CRC_WARNING) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.CRC_WARNING"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.JABBERS_WARNING && port.isAdmin) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.JABBERS_WARNING"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.FRAGS_WARNING) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.FRAGS_WARNING"));
  }
  if (
    port.usageStatus & SWITCHPORT_USAGE_FLAGS.MISSIZED_WARNING &&
    port.isAdmin &&
    !port.isStackport
  ) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.MISSIZED_WARNING"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.COLLISIONS_ERROR) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.COLLISIONS_ERROR"));
  }
  if (port.usageStatus & SWITCHPORT_USAGE_FLAGS.STP_TCS_WARNING) {
    messages.push(I18n.t("PORT_STATUS.PORT_WARNINGS.STP_TCS_WARNING"));
  }
  return messages;
};

export function getAlertPortMessage(portData: any) {
  return [...switchPortErrorMessages(portData), ...switchPortWarningMessages(portData)].join(
    "\n\n",
  );
}
