// See https://magnetic.cisco.com/0a43ab5cd/p/773eef-color/b/09a42d
enum MagneticColors {
  // Neutral (grayscale)
  neutral1 = "#FFFFFF",
  neutral2 = "#F7F7F7",
  neutral3 = "#E0E0E0",
  neutral4 = "#CCCCCC",
  neutral5 = "#B8B8B8",
  neutral6 = "#8F8F8F",
  neutral7 = "#707070",
  neutral8 = "#5C5C5C",
  neutral9 = "#494A4A",
  neutral10 = "#373838",
  neutral11 = "#222423",
  neutral12 = "#161716",
  neutral13 = "#000000",

  // Primary Blue
  blue1 = "#EDF4FF",
  blue2 = "#D9E7FF",
  blue3 = "#CCDEFC",
  blue4 = "#A6C4F5",
  blue5 = "#7AA7F5",
  blue6 = "#5B92F0",
  blue7 = "#4580E5",
  blue8Primary = "#326CD1",
  blue9PrimaryDark = "#215CC2",
  blue10 = "#1650B5",

  // Status
  greenPositive = "#45991F",
  yellowWarning = "#D9B216",
  redNegative = "#E05843",
  redActive = "#FFCCCB",

  // Accent Palette
  accentALightBlue = "#049FD9",
  accentBDarkBlue = "#0D56E2",
  accentCPurple = "#B180D6",
  accentDMagenta = "#C0006F",
  accentESalmon = "#F4647A",
  accentFBrown = "#B73000",
  accentGYellowGreen = "#9C9711",
  accentHGreen = "#316E27",
  accentILightTeal = "#00A889",
  accentJDarkTeal = "#006C7D",
}
export default MagneticColors;
