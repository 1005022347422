import {
  PushNotificationData,
  setupNotifications as libSetupNotification,
} from "@meraki/shared/notification";
import { defaultTimespans } from "@meraki/shared/redux";

import { navigateToDeviceDetails } from "~/enterprise/navigation/notifications/DeviceLinking";
import { isDeviceDown, isSensorEventType } from "~/lib/NetworkEventUtils";
import { appSelect } from "~/lib/PlatformUtils";
import { navRef } from "~/shared/navigation/NavigationRef";

export const FIREBASE_SENDER_ID = appSelect({
  go: "751936598021",
  enterprise: "245787700090",
});

export async function setupNotifications(isManualRequest = false) {
  return await libSetupNotification(
    handleOpenedPushNotification,
    FIREBASE_SENDER_ID,
    isManualRequest,
  );
}

export function handleOpenedPushNotification({
  event_type,
  ng_eid,
  node_id,
  timestamp,
}: Pick<PushNotificationData, "event_type" | "ng_eid" | "node_id" | "timestamp">) {
  if (event_type === "weekly_presence") {
    navRef.navigate("LocationAnalytics", { initialTimespan: defaultTimespans.WEEK.value });
  } else if (event_type === "client_connectivity") {
    //TODO
    navRef.navigate("TrackedClients");
  } else if (node_id && ng_eid) {
    if (
      isSensorEventType(event_type) ||
      isDeviceDown(event_type) ||
      event_type === "motion_alert"
    ) {
      navigateToDeviceDetails(ng_eid, node_id, timestamp, "push_notification");
    }
  }
}
