import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { List, SearchBar } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { AuthUsers, useAdmins, useAuthUsers } from "@meraki/shared/api";
import { CLIENT_VPN_SEARCH_FIELDS, filterData } from "@meraki/shared/filters";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";

export const ClientVPNGuestListScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();
  const { data: admins } = useAdmins({ organizationId });
  const { data: authUsers } = useAuthUsers({ networkId });

  const [searchText, setSearchText] = useState("");
  const guests = authUsers?.filter(
    (guest) => !admins?.find((admin) => admin.email === guest.email),
  );

  const searchedGuests = filterData(
    guests ?? [],
    CLIENT_VPN_SEARCH_FIELDS,
    searchText,
  ) as AuthUsers;

  return (
    <Screen addDefaultPadding>
      <Box flexDirection="row" alignItems="center" gap="sm" bottomDividerBorder>
        <Box flex={1} paddingBottom="sm">
          <SearchBar
            placeholder={I18n.t("SEARCH")}
            value={searchText}
            onChangeText={setSearchText}
            testID="GUEST_SEARCH"
          />
        </Box>
      </Box>
      <List.FlashList
        paddingTop="none"
        paddingRight="none"
        paddingLeft="none"
        data={searchedGuests}
        getItemData={(guest) => {
          return {
            title: guest.name,
            onPress: () => {
              navigation.navigate("ClientVPNViewGuest", {
                id: guest.id,
                name: guest.name,
                email: guest.email,
              });
            },
          };
        }}
        emptyState={{
          title: guests?.length
            ? I18n.t("CLIENT_VPN.USER.EMPTY_SEARCH")
            : I18n.t("CLIENT_VPN.USER.EMPTY_SEARCH"),
        }}
      />
    </Screen>
  );
};
