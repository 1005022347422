import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import {
  FlexStyle,
  mapFlexPropsToStyle,
  mapSpacingPropsToStyles,
  SpacingStyle,
} from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { ReactNode, useCallback } from "react";
import {
  GestureResponderEvent,
  Pressable,
  PressableProps,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";

export type WellProps = SpacingStyle &
  FlexStyle & {
    children: ReactNode;
    onPress?: PressableProps["onPress"];
    testID?: string;
  } & AnalyticsProp<"onPress">;

function getWellStyle(theme: Theme): StyleProp<ViewStyle> {
  return {
    backgroundColor: theme.color.default.bg.medium.base,
    paddingVertical: theme.SizeContainPadding2xs,
    paddingHorizontal: theme.SizeContainPaddingXs,
    borderRadius: theme.SizeRadiusBorderSm,
  };
}

export function Well({ children, analytics, onPress, testID, ...rest }: WellProps) {
  const theme = useMagneticTheme();

  const trackEvent = useAnalytics(analytics);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (!onPress) {
        return;
      }

      trackEvent("onPress");
      onPress(event);
    },
    [trackEvent, onPress],
  );

  const { style: flexStyle, ...flexRest } = mapFlexPropsToStyle(rest);
  const { style: spacingStyle } = mapSpacingPropsToStyles(flexRest, theme);

  const style = [getWellStyle(theme), flexStyle, spacingStyle];

  if (onPress) {
    return (
      <Pressable style={style} onPress={handlePress} testID={testID}>
        {children}
      </Pressable>
    );
  }

  return (
    <View style={style} testID={testID}>
      {children}
    </View>
  );
}
