import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import DefaultHeader from "~/go/components/DefaultHeader";
import MaintenanceWindowPicker from "~/go/components/MaintenanceWindowPicker";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { currentMaintenanceWindow, currentNetworkState } from "~/selectors";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import { UpgradeWindow } from "~/shared/types/Preferences";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { SettingsStackProps } from "../navigation/Types";

type ReduxProps = {
  maintenanceWindow: UpgradeWindow;
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "FirmwareUpgrades"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

interface FirmwareUpgradesState {
  isLoading: boolean;
  stagedMaintenanceWindow?: UpgradeWindow;
  saveEnabled: boolean;
}

export class FirmwareUpgradesScreen extends PureComponent<Props, FirmwareUpgradesState> {
  state: FirmwareUpgradesState = {
    stagedMaintenanceWindow: undefined,
    isLoading: false,
    saveEnabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    const { saveEnabled } = this.state;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={this.close} />,
      headerRight: () => <SaveButton onPress={this.save} disabled={!saveEnabled} />,
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    this.updateSaveButton();
  }

  updateSaveButton = () => {
    this.setState({ saveEnabled: this.hasChanges() });
    this.setNavOptions();
  };

  hasChanges = () => {
    const { maintenanceWindow } = this.props;
    const { stagedMaintenanceWindow } = this.state;

    if (!stagedMaintenanceWindow) {
      return false;
    }
    return (
      maintenanceWindow?.dayOfWeek !== stagedMaintenanceWindow.dayOfWeek ||
      maintenanceWindow?.hourOfDay !== stagedMaintenanceWindow.hourOfDay
    );
  };

  close = () => {
    const { navigation } = this.props;
    if (this.hasChanges()) {
      showSaveWarning(this.save, navigation.goBack);
    } else {
      navigation.goBack();
    }
  };

  save = async () => {
    const { navigation, actions, networkId, cancelablePromise, setReqPending } = this.props;
    const { stagedMaintenanceWindow } = this.state;
    const settingsToSend = {
      upgradeWindow: {
        ...stagedMaintenanceWindow,
      },
    };
    try {
      setReqPending(true);
      await cancelablePromise(actions.setFirmwareUpgrades(networkId, settingsToSend));
      navigation.goBack();
    } catch (error) {
      this.handleError(error);
    }
    setReqPending(false);
  };

  handleError = (error: unknown) => {
    showAlert(I18n.t("ERROR"), error);
  };

  getData = async () => {
    const { actions, networkId } = this.props;
    const { getFirmwareUpgrades } = actions;
    this.setState({
      isLoading: true,
    });
    try {
      await getFirmwareUpgrades(networkId);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleChange = (newDay: string, newHour: string) => {
    this.setState({
      stagedMaintenanceWindow: {
        dayOfWeek: newDay,
        hourOfDay: newHour,
      },
    });
  };

  renderWindowPicker = () => {
    const { isLoading } = this.state;
    return <MaintenanceWindowPicker onChange={this.handleChange} isLoading={isLoading} />;
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("FIRMWARE_UPDATES.HEADER.TITLE")}
        description={I18n.t("FIRMWARE_UPDATES.HEADER.DESCRIPTION")}
      />
    );
  };

  render() {
    return (
      <View>
        {this.renderHeader()}
        {this.renderWindowPicker()}
      </View>
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    maintenanceWindow: currentMaintenanceWindow(state),
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for FirmwareUpgradesScreen",
      currentNetworkState(state),
    ),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withPendingComponent,
)(FirmwareUpgradesScreen);
