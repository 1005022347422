import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function PlayIcon(props: Props) {
  const { size = BUTTON_SIZING.width.default } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 28 28" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM11.382 8.13586L19.5692 13.2207C19.7007 13.3022 19.8095 13.4177 19.8849 13.5541C19.9604 13.6905 20 13.8443 20 14.0007C20 14.1571 19.9604 14.3109 19.8849 14.4473C19.8095 14.5837 19.7007 14.6981 19.5692 14.7795L11.3842 19.8644C11.2411 19.953 11.0768 19.9999 10.9092 20C10.6691 20.0009 10.4384 19.905 10.2679 19.7333C10.0974 19.5616 10.0011 19.3282 10 19.0844V8.91584C10.0002 8.75332 10.0431 8.59379 10.1242 8.45366C10.2053 8.31354 10.3217 8.19787 10.4614 8.11855C10.6025 8.03799 10.7622 7.99712 10.924 8.00016C11.0858 8.0032 11.2439 8.05005 11.382 8.13586Z"
        fill="white"
      />
    </Svg>
  );
}

export default PlayIcon;
