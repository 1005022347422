import { Button, List, Notification, SearchBar, Text, Toggle } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { AllFlags, useOverrideStore, useWatchedBoolFlag } from "@meraki/shared/feature-flags";
import { useCallback, useState } from "react";
import { Alert } from "react-native";

function BooleanOverride({
  flag,
  overriddenValue,
  onToggle,
  onToggleEnabled,
}: {
  flag: keyof typeof AllFlags;
  overriddenValue: { enabled: boolean; value: boolean } | undefined;
  onToggle: (enabled: boolean) => void;
  onToggleEnabled: () => void;
}) {
  const { value: currentValue } = useWatchedBoolFlag("ld", flag as never, true);

  return (
    <Box gap="xs">
      <Box flexDirection="row">
        <Box flex={1}>
          <Text size="p1">Current Value:</Text>
        </Box>
        <Text size="p1" color="light">
          {currentValue ? "True" : "False"}
        </Text>
      </Box>
      <Box flexDirection="row" gap="2xs" alignItems="center">
        <Box flex={1}>
          <Text size="p1">Enable Override:</Text>
        </Box>
        <Toggle
          checked={overriddenValue?.enabled ?? false}
          onValueChange={() => {
            onToggleEnabled();
          }}
        />
      </Box>
      <Box flexDirection="row" alignItems="center">
        <Box flex={1}>
          <Text size="p1">Override Value:</Text>
        </Box>
        <Toggle
          checked={overriddenValue?.value ?? false}
          disabled={!(overriddenValue?.enabled ?? false)}
          onValueChange={onToggle}
        />
      </Box>
    </Box>
  );
}

export function DebugFeatureFlags() {
  const [search, setSearch] = useState("");
  const { overrides, clearOverrides } = useOverrideStore();

  const flagList = Object.entries(AllFlags).filter(
    ([flag, defaultValue]) =>
      typeof defaultValue === "boolean" &&
      (!search || flag.toLowerCase().includes(search.toLowerCase())),
  );

  const handleClearOverrides = useCallback(() => {
    Alert.alert("Clear Overrides", "Are you sure you want to clear all overrides?", [
      {
        text: "Cancel",
        style: "cancel",
      },
      {
        text: "Clear",
        onPress: () => {
          clearOverrides();
        },
      },
    ]);
  }, [clearOverrides]);

  return (
    <Screen>
      <Box padding="sm" paddingBottom="none">
        <Notification.Inline
          status="informational"
          message="Note that depending on the location of the feature flag, it may not be immediately reflected in the UI. If that is the case try restarting the app after toggling the flag."
        />
      </Box>
      <Box flexDirection="row" paddingHorizontal="sm" alignItems="center" gap="sm">
        <Box flex={1}>
          <SearchBar placeholder="Search" value={search} onChangeText={(text) => setSearch(text)} />
        </Box>
        <Button.Icon icon="Trash" onPress={handleClearOverrides} />
      </Box>
      <List.FlashList
        paddingTop="none"
        data={flagList}
        scrollEnabled={false}
        groupBy={(flag) => flag[0]}
        getItemData={([flag, defaultValue]) => {
          return {
            title: "Default Value:",
            value: (defaultValue as boolean) ? "True" : "False",
            children: (
              <BooleanOverride
                flag={flag as keyof typeof AllFlags}
                overriddenValue={
                  overrides[flag] as { enabled: boolean; value: boolean } | undefined
                }
                onToggle={(value) => {
                  useOverrideStore.getState().setOverride(flag, value);
                }}
                onToggleEnabled={() => {
                  useOverrideStore.getState().toggleEnabled(flag);
                }}
              />
            ),
          };
        }}
      />
    </Screen>
  );
}
