import * as errorMonitor from "@meraki/core/errors";
import { useEffect, useRef, useState } from "react";

import { fetchNetworkAccessRoles, fetchOrgNFOs } from "~/actions";
import { currentNetworkState } from "~/selectors";

import useAppDispatch from "../hooks/redux/useAppDispatch";
import useChangingState from "./useChangingState";

export default (isAuthenticated: boolean) => {
  const dispatch = useAppDispatch();
  const isNetworkChanged = useChangingState(currentNetworkState);
  const [isFetchingAccessRoles, setFetchingAccessRoles] = useState(false);
  const isFetchingAccessRolesRef = useRef(false);

  useEffect(() => {
    (async () => {
      if (!isAuthenticated || isFetchingAccessRolesRef.current) {
        return;
      }

      isFetchingAccessRolesRef.current = true;
      setFetchingAccessRoles(true);

      try {
        await Promise.all([dispatch(fetchOrgNFOs()), dispatch(fetchNetworkAccessRoles())]);
      } catch (e) {
        let message = "Failed to fetch network access roles and/or NFOs";
        if (e instanceof Error) {
          message += `. Error: ${e.message}`;
        }
        errorMonitor.notify(message);
      } finally {
        isFetchingAccessRolesRef.current = false;
        setFetchingAccessRoles(false);
      }
    })();
  }, [dispatch, isAuthenticated, isNetworkChanged]);

  return isFetchingAccessRoles;
};
