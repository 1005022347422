import { StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/constants/MkiFonts";
import InfoModal from "~/go/components/InfoModal";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { sizeSelect } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import WrappingButtonRow from "~/shared/components/WrappingButtonRow";

type Props = {
  onPrimaryPress: () => void;
  onSecondaryPress: () => void;
  onExit?: () => void;
  visible: boolean;
  primaryText?: string;
  secondaryText?: string;
  customMessage?: string;
};

const TroubleshootingModal = (props: Props) => {
  const {
    visible,
    primaryText,
    onPrimaryPress,
    secondaryText,
    onSecondaryPress,
    customMessage,
    onExit,
  } = props;
  if (!visible) {
    return null;
  }
  const cMessage = customMessage ? (
    <MkiText textStyle="subheading" screenStyles={styles.subheading}>
      {customMessage}
    </MkiText>
  ) : null;
  return (
    <InfoModal visible={visible} onExit={onExit}>
      <MkiText textStyle="subheading" screenStyles={styles.subheading}>
        Troubleshooting
      </MkiText>
      {cMessage}
      <MkiText
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        textStyle={sizeSelect({
          small: "small",
          medium: "small",
          large: "default",
        })}
        screenStyles={styles.blockText}
      >
        {I18n.t("TROUBLESHOOTING_STEPS")}
      </MkiText>

      <WrappingButtonRow>
        <RoundedButton
          onPress={onPrimaryPress}
          buttonType="secondary"
          screenStyles={styles.button}
          textStyles={styles.buttonText}
        >
          {primaryText}
        </RoundedButton>

        <RoundedButton
          onPress={onSecondaryPress}
          buttonType="tertiary"
          screenStyles={styles.button}
          textStyles={styles.buttonText}
        >
          {secondaryText}
        </RoundedButton>
      </WrappingButtonRow>
    </InfoModal>
  );
};

TroubleshootingModal.defaultProps = {
  primaryText: I18n.t("TROUBLESHOOTING_MODAL.PRIMARY"),
  secondaryText: I18n.t("TROUBLESHOOTING_MODAL.SECONDARY"),
  onExit: null,
  customMessage: "",
};

const styles = StyleSheet.create({
  subheading: {
    marginBottom: SPACING.large,
  },
  blockText: {
    color: MkiColors.formFieldLabel,
    marginBottom: SPACING.default,
    marginHorizontal: SPACING.small,
  },
  button: {
    alignSelf: "stretch",
    paddingHorizontal: SPACING.default,
    borderRadius: 25,
    marginRight: 12,
  },
  buttonText: {
    fontSize: FONT_SIZES.CARD_BUTTON.default,
    textAlign: "left",
  },
});

export default TroubleshootingModal;
