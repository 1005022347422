import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import {
  Box,
  FlexStyle,
  mapFlexPropsToStyle,
  mapSpacingPropsToStyles,
  SpacingStyle,
} from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { useCallback } from "react";
import {
  GestureResponderEvent,
  Pressable,
  PressableProps,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";

import { Heading } from "../Heading/Heading";
import { Header } from "./Header";
import { useLoadingHandler, UseLoadingHandlerProps } from "./useLoadingHandler";
import { Well } from "./Well";

export { WellProps as CardWellProps } from "./Well";

type InternalCardProps = {
  label?: string;
  accent?: "informational" | "positive" | "warning" | "negative";
  selectable?: boolean;
} & UseLoadingHandlerProps;

export type CardProps = InternalCardProps &
  Pick<
    ViewProps,
    "children" | "testID" | "accessible" | "accessibilityLabel" | "accessibilityRole"
  > &
  SpacingStyle &
  FlexStyle & {
    onPress?: PressableProps["onPress"];
  } & AnalyticsProp<"onPress">;

function getContainerStyle({ selectable }: InternalCardProps, theme: Theme): StyleProp<ViewStyle> {
  let style: StyleProp<ViewStyle> = {
    gap: 5,
    backgroundColor: theme.color.control.bg.weak.base,
    padding: theme.SizeContainPaddingMd,
    borderWidth: theme.SizeContainBorderStroke,
    borderRadius: theme.SizeContainBorderRadius,
    borderColor: theme.color.control.border.weak.base,
  };

  // If we have an onPress then we are selectable...
  if (selectable) {
    style = {
      ...style,
      borderColor: theme.color.transparent,

      // Shadow Stuffs...
      shadowColor: "#000",
      shadowOffset: { width: 2, height: 2 },
      shadowRadius: 8,
      shadowOpacity: 0.07,
      elevation: 3,
    };
  }

  return style;
}

function getAccentStyle({ accent }: InternalCardProps, theme: Theme): StyleProp<ViewStyle> {
  let backgroundColor: string | undefined = undefined;

  switch (accent) {
    case "informational":
      backgroundColor = theme.color.info.border.base;
      break;
    case "positive":
      backgroundColor = theme.color.positive.border.base;
      break;
    case "warning":
      backgroundColor = theme.color.warning.border.base;
      break;
    case "negative":
      backgroundColor = theme.color.negative.border.base;
      break;
  }

  return {
    backgroundColor,
    height: 6,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopStartRadius: theme.SizeContainBorderRadius,
    borderTopEndRadius: theme.SizeContainBorderRadius,
  };
}

function getBoxProps({ ...rest }: CardProps, theme: Theme) {
  const { style: flexStyle, ...flexRest } = mapFlexPropsToStyle(rest);
  const { style: spacingStyle, ...spacingRest } = mapSpacingPropsToStyles(flexRest, theme);

  return {
    style: [flexStyle, spacingStyle],
    ...spacingRest,
  };
}

export function Card(props: CardProps) {
  const theme = useMagneticTheme();

  const { children, accent, label, onPress, analytics } = props;

  const trackEvent = useAnalytics(analytics);

  const updatedChildren = useLoadingHandler(children, props);

  const handlePress = useCallback(
    (event: GestureResponderEvent) => {
      if (!onPress) {
        return;
      }

      trackEvent("onPress");
      onPress(event);
    },
    [trackEvent, onPress],
  );

  const selectable = !!onPress;
  const internalProps = { accent, selectable };

  const { style, ...boxPropsRest } = getBoxProps(props, theme);

  // This probably should be combined with the return statement below but doing so breaks the Device Summary Cards
  // Those cards probably need a rework to be less of a pain. DM-4447
  if (!label) {
    return (
      <Pressable
        style={[getContainerStyle(internalProps, theme), ...style]}
        {...boxPropsRest}
        onPress={handlePress}
      >
        <View style={getAccentStyle(internalProps, theme)} />
        {updatedChildren}
      </Pressable>
    );
  }

  return (
    <Box gap="xs">
      <Box paddingLeft="sm">
        <Heading size="h4">{label}</Heading>
      </Box>
      <Pressable
        style={[getContainerStyle(internalProps, theme), ...style]}
        {...boxPropsRest}
        onPress={handlePress}
      >
        <View style={getAccentStyle(internalProps, theme)} />
        {updatedChildren}
      </Pressable>
    </Box>
  );
}

Card.Content = Box;
Card.Header = Header;
Card.Well = Well;
