import z from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const AdminSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    orgAccess: z.enum(["full", "read-only", "none"]),
    accountStatus: z.string(),
    twoFactorAuthEnabled: z.boolean(),
    hasApiKey: z.boolean(),
    lastActive: z.string().nullable(),
    tags: z.array(
      z.object({
        tag: z.string(),
        access: z.string(),
      }),
    ),
    networks: z.array(
      z.object({
        id: z.string().optional(),
        access: z.string().optional(),
      }),
    ),
    authenticationMethod: z.string(),
  })
  .describe("AdminSchema");

export const AdminsSchema = z.array(AdminSchema).describe("AdminsSchema");

export type Admin = z.infer<typeof AdminSchema>;

export interface AdminsRequest {
  organizationId?: string;
}

interface AdminRequest {
  organizationId?: string;
  adminId: string;
}

interface AdminsMutationRequest extends AdminsRequest {
  adminId: string;
}

interface AdminsUpdateRequest extends AdminsMutationRequest {
  params: {
    name?: string;
    orgAccess?: string;
    networks?: { id: string; access: string }[];
    tags?: string[];
  };
}

export const AdminPostParamsSchema = z.object({
  email: z.string(),
  name: z.string(),
  orgAccess: z.union([z.literal("full"), z.literal("read-only"), z.literal("none")]),
  networks: z.optional(
    z.array(
      z.object({
        access: z.string(),
        id: z.string(),
      }),
    ),
  ),
});

type AdminsPostParams = AdminsRequest & {
  params: z.infer<typeof AdminPostParamsSchema>;
};

function buildUrl({ organizationId, adminId }: AdminsRequest & { adminId?: string }) {
  return `/api/v1/organizations/${organizationId}/admins${adminId ? `/${adminId}` : ""}`;
}

function fetchAdmins(variables: AdminsRequest): Promise<Admin[]> {
  return request(AdminsSchema, "GET", buildUrl(variables));
}

function deleteAdmin(variables: AdminsMutationRequest): Promise<null> {
  return request(z.null(), "DELETE", buildUrl(variables));
}

function createAdmin(variables: AdminsPostParams): Promise<Admin> {
  return request(AdminSchema, "POST", buildUrl(variables), {
    body: JSON.stringify(variables.params),
  });
}

function updateAdmin(variables: AdminsUpdateRequest): Promise<Admin> {
  return request(AdminSchema, "PUT", buildUrl(variables), {
    body: JSON.stringify(variables.params),
  });
}

export const useAdmins = createQuery<AdminsRequest, Admin[]>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (variables) => fetchAdmins(variables),
  requiredVariables: ["organizationId"],
});

export const useAdmin = ({ organizationId, adminId }: AdminRequest) => {
  return useAdmins(
    { organizationId },
    {
      select: (admins) => admins.find((admin) => admin.id === adminId),
    },
  );
};

export const useCurrentAdminUser = ({
  organizationId,
  email,
}: AdminsRequest & { email: string }) => {
  return useAdmins(
    { organizationId },
    {
      select: (admins) => admins.find((admin) => admin.email === email),
    },
  );
};

export const useDeleteAdmin = createMutation({
  baseMutationKey: buildUrl({ organizationId: "{organizationId}" }),
  mutationFn: (options: AdminsMutationRequest) => deleteAdmin(options),
});

export const useCreateAdmin = createMutation<AdminsPostParams, Admin, APIResponseError>({
  baseMutationKey: buildUrl({ organizationId: "{organizationId}" }),
  mutationFn: (options: AdminsPostParams) => createAdmin(options),
});

export const useUpdateAdmin = createMutation<AdminsUpdateRequest, Admin, APIResponseError>({
  baseMutationKey: buildUrl({ organizationId: "{organizationId}" }),
  mutationFn: (options: AdminsUpdateRequest) => updateAdmin(options),
});
