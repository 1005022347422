import { getProductType } from "@meraki/shared/devices";
import { StyleSheet, View } from "react-native";

import { Device as DeviceSchema } from "~/api/schemas/Device";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/constants/MkiFonts";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import ProductIcon, { PRODUCT_SIZE } from "~/go/components/ProductIcon";
import { Features } from "~/go/types/ContextHelpTypes";
import I18n from "~/i18n/i18n";
import {
  deviceName,
  getDeviceLiveStatus,
  getDeviceStatus,
  getIsMeshAccessPoint,
} from "~/lib/DeviceUtils";
import { noneIfFalsey } from "~/lib/stringHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import HeaderValueText from "~/shared/components/HeaderValueText";
import { GoStatus } from "~/shared/constants/Status";
import Device from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

interface HardwareDetailsHeaderProps {
  device: Device;
  saveName?: (name: string) => void;
  productType?: ProductType;
  isLive?: boolean;
  ngData?: DeviceSchema[];
  showStaticIPConfiguration?: () => void;
}

const HardwareDetailsHeader = ({
  device,
  saveName,
  isLive,
  ngData,
  showStaticIPConfiguration,
}: HardwareDetailsHeaderProps) => {
  const { is_gateway: isGateway, name, model, serial, mac, lanIp } = device;
  const deviceStatus = getDeviceLiveStatus(isLive, getDeviceStatus(device));
  const isMeshedAP = getIsMeshAccessPoint(device);
  const isOffline = deviceStatus === GoStatus.offline;
  const isWireless = getProductType(model) === ProductType.wireless;
  const noLanIpText = isGateway || !isWireless ? I18n.t("NONE") : I18n.t("LAN_IP_VALUE_REPEATER");
  const icon = <ProductIcon status={deviceStatus} model={model} size={PRODUCT_SIZE.large} />;

  let ipAddressText;
  if (isOffline) {
    ipAddressText = I18n.t("NONE");
  } else if (isMeshedAP) {
    ipAddressText = I18n.t("MESHED_GR_STATUS.AP_DESCRIPTION");
  } else {
    ipAddressText = ngData?.[0]?.lanIp || lanIp || noLanIpText;
  }

  return (
    <View style={[styles.headerContainer, styles.center]}>
      {icon}
      <EditableNameHeader
        entity="device"
        // @ts-expect-error TS(2322) FIXME: Type '((name: string) => void) | undefined' is not... Remove this comment to see the full error message
        save={saveName}
        title={deviceName({ name, serial })}
        titleStyle={styles.titleTextStyle}
        promptIcon={icon}
        testID="HARDWARE_DETAILS_HEADER"
      />
      <View style={styles.headerHighlightsContainer}>
        <HeaderValueText
          screenStyles={styles.center}
          valueStyles={styles.highlightItemValue}
          label={I18n.t("MAC_ADDRESS")}
          value={noneIfFalsey(mac)}
        />
        <HeaderValueText
          screenStyles={styles.center}
          valueStyles={styles.highlightItemValue}
          label={I18n.t("IP_ADDRESS")}
          labelIcon={<ContextHelp context={Features.deviceIPAddress} withHorizontalPadding />}
          value={ipAddressText}
          onEditPress={showStaticIPConfiguration}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  center: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  headerContainer: {
    marginBottom: SPACING.extraLarge,
  },
  headerHighlightsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    paddingHorizontal: SPACING.small,
  },
  highlightItemValue: {
    fontSize: FONT_SIZES.SUBHEADER.default,
  },
  titleTextStyle: {
    fontSize: normalizedFontSize(24),
    color: MkiColors.primaryButton,
  },
});

export default HardwareDetailsHeader;
