import { useState } from "react";

import { InternalGroup, InternalGroupProps } from "./InternalGroup";

export type AccordionGroupProps = Omit<InternalGroupProps, "calculateChildProps"> & {
  initialOpenIndex?: number;
};

export function AccordionGroup({ initialOpenIndex, ...rest }: AccordionGroupProps) {
  const [openIndex, setOpenIndex] = useState(initialOpenIndex ?? -1);

  return (
    <InternalGroup
      {...rest}
      calculateChildProps={(idx) => ({
        open: openIndex === idx,
        onToggle: () => {
          const updateIdx = idx === openIndex ? -1 : idx;
          setOpenIndex(updateIdx);
        },
      })}
    />
  );
}
