import { BottomSheet, BottomSheetMethods, Button } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { setCurrentCluster, useCurrentCluster } from "@meraki/shared/redux";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import { ClusterNames, getClusterOptions } from "./ClusterUtils";

export function ClusterPicker() {
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const currentClusterOption = useCurrentCluster();
  const dispatch = useDispatch();

  const { value: showClusters, isLoading } = useBoolFlag("ld", "show-cluster-dropdown");

  if (!showClusters || isLoading) {
    return null;
  }

  return (
    <>
      <Button
        kind="tertiary"
        text={ClusterNames[currentClusterOption]}
        onPress={() => bottomSheetRef.current?.present()}
      />
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.FlashList
          data={getClusterOptions()}
          getItemData={(cluster) => ({
            title: cluster.cluster,
            rightAccessory: currentClusterOption === cluster.key && (
              <Icon name="Check" color="brandAccent.icon.active" />
            ),
            onPress: () => {
              dispatch(setCurrentCluster(cluster.key));
              bottomSheetRef.current?.dismiss();
            },
          })}
        />
      </BottomSheet.Modal>
    </>
  );
}
