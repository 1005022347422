import Svg, { Path } from "react-native-svg";

export const PortOutlineIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M8.6709 6.75H3.75C3.33579 6.75 3 7.08579 3 7.5V19.5C3 19.9142 3.33579 20.25 3.75 20.25H20.25C20.6642 20.25 21 19.9142 21 19.5V7.5C21 7.08579 20.6642 6.75 20.25 6.75H15.6709"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.6709 6.86609V4.13882C15.6709 4.06648 15.5234 3.99712 15.2608 3.94597C14.9983 3.89482 14.6422 3.86609 14.2709 3.86609H10.0709C9.6996 3.86609 9.3435 3.89482 9.08095 3.94597C8.8184 3.99712 8.6709 4.06648 8.6709 4.13882V6.86609"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default PortOutlineIcon;
