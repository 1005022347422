import Svg, { Path } from "react-native-svg";

function MagneticSwitchIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C4.44772 3 4 3.44772 4 4V7C4 7.55228 4.44772 8 5 8H19C19.5523 8 20 7.55228 20 7V4C20 3.44772 19.5523 3 19 3H5ZM7 6.5C7.55228 6.5 8 6.05228 8 5.5C8 4.94772 7.55228 4.5 7 4.5C6.44772 4.5 6 4.94772 6 5.5C6 6.05228 6.44772 6.5 7 6.5Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.5C4.44772 9.5 4 9.94772 4 10.5V13.5C4 14.0523 4.44772 14.5 5 14.5H19C19.5523 14.5 20 14.0523 20 13.5V10.5C20 9.94772 19.5523 9.5 19 9.5H5ZM7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z"
        fill="#8F8F8F"
      />
      <Path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 16C4.44772 16 4 16.4477 4 17V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V17C20 16.4477 19.5523 16 19 16H5ZM7 19.5C7.55228 19.5 8 19.0523 8 18.5C8 17.9477 7.55228 17.5 7 17.5C6.44772 17.5 6 17.9477 6 18.5C6 19.0523 6.44772 19.5 7 19.5Z"
        fill="#8F8F8F"
      />
    </Svg>
  );
}

export default MagneticSwitchIcon;
