import { I18n } from "@meraki/core/i18n";
import { useCallback } from "react";

import DeleteButton from "~/go/components/DeleteButton";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { Vlan } from "~/shared/types/Vlans";

interface VlansListRowProps {
  vlan: Vlan;
  onPressRow: (vlan: Vlan) => void;
  isEditMode: boolean;
  onDelete: (id: string) => void;
}

const VlansListRow = ({ vlan, onPressRow, isEditMode, onDelete }: VlansListRowProps) => {
  const { name, subnet, id } = vlan;
  const onPress = useCallback(() => {
    onPressRow(vlan);
  }, [onPressRow, vlan]);

  const onPressDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <DisclosureRow
      icon={<DeleteButton show={isEditMode} onPress={onPressDelete} />}
      onPress={onPress}
      subtitle={subnet}
      subtitle2={I18n.t("SUBNETS_VLANS.SUBTITLE2", { vlanId: id })}
      testID={I18n.t("SUBNETS_VLANS.TITLE")}
    >
      {name}
    </DisclosureRow>
  );
};

export default VlansListRow;
