import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetProps,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from "@gorhom/bottom-sheet";
import { BottomSheetDefaultBackdropProps } from "@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types";
import { FC, ReactNode, RefObject, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

import MerakiIcon from "./icons";
import MkiText from "./MkiText";

export interface MkiBottomSheetProps {
  sheetRef?: RefObject<BottomSheet>;
  initialSnapIndex?: BottomSheetProps["index"];
  snapPoints?: BottomSheetProps["snapPoints"];
  onClose: BottomSheetProps["onClose"];
  children: ReactNode;
  renderHeader?: () => React.ReactNode;
  showHandleIndicator?: boolean;
  showBackdrop?: boolean;
}

type MkiBottomSheetHeaderProps = {
  onClosePress: () => void;
  title: string;
  headerRight?: React.ReactNode;
};

const BackDrop: FC<BottomSheetDefaultBackdropProps> = (props) => (
  <BottomSheetBackdrop
    {...props}
    enableTouchThrough={true}
    appearsOnIndex={0}
    disappearsOnIndex={-1}
  />
);

export const MkiBottomSheetHeader = ({
  onClosePress,
  title,
  headerRight,
}: MkiBottomSheetHeaderProps) => {
  const colors = useThemeColors();

  return (
    <View style={styles.header}>
      <MerakiIcon
        name={"close-thin"}
        size={BUTTON_SIZING.width.narrow}
        color={colors.utilityBar?.color}
        onPress={onClosePress}
        containerStyle={styles.closeButton}
      />
      <MkiText>{title}</MkiText>
      <View style={styles.headerRight}>{!!headerRight && headerRight}</View>
    </View>
  );
};

const MkiBottomSheet = ({
  sheetRef,
  initialSnapIndex,
  snapPoints,
  renderHeader,
  children,
  onClose,
  showHandleIndicator = true,
  showBackdrop,
}: MkiBottomSheetProps) => {
  const colors = useThemeColors();
  const [isFullyOpen, setIsFullyOpen] = useState(false);

  const backgroundStyle = { backgroundColor: colors.navigation.backgroundPrimary };
  const initialSnapPoints = useMemo(() => ["CONTENT_HEIGHT"], []);
  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(initialSnapPoints);

  const useDynamicSnapPoints = !Boolean(snapPoints);
  const resolvedSnapPoints = snapPoints ? snapPoints : animatedSnapPoints;

  const renderContent = () => {
    const content = (
      <>
        {renderHeader && renderHeader()}
        {children}
      </>
    );

    return useDynamicSnapPoints ? (
      <BottomSheetView onLayout={handleContentLayout}>{content}</BottomSheetView>
    ) : (
      content
    );
  };

  return (
    <BottomSheet
      style={styles.shadow}
      backgroundStyle={backgroundStyle}
      backdropComponent={showBackdrop ? BackDrop : null}
      onClose={onClose}
      handleStyle={
        isFullyOpen
          ? [backgroundStyle, styles.headerHandleFullyOpenContainer]
          : [backgroundStyle, styles.handleStyle]
      }
      handleIndicatorStyle={{
        backgroundColor:
          isFullyOpen || !showHandleIndicator ? MkiColors.fullTransparent : MkiColors.othergrey,
      }}
      ref={sheetRef}
      index={initialSnapIndex}
      snapPoints={resolvedSnapPoints}
      handleHeight={useDynamicSnapPoints ? animatedHandleHeight : undefined}
      contentHeight={useDynamicSnapPoints ? animatedContentHeight : undefined}
      enablePanDownToClose
      enableContentPanningGesture
      enableHandlePanningGesture
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      onChange={(index) => setIsFullyOpen(resolvedSnapPoints[index] === "100%")}
    >
      {renderContent()}
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: SPACING.medium,
  },
  closeButton: {
    flex: 1,
    alignItems: "flex-start",
    paddingStart: SPACING.medium,
    minWidth: SPACING.medium,
  },
  // react-navigation expects a function which returns a React Element for headerLeft/Right
  // eslint-disable-next-line react/no-unstable-nested-components
  headerRight: {
    flex: 1,
    alignItems: "flex-end",
    minWidth: SPACING.medium,
  },
  handleStyle: {
    borderTopLeftRadius: SPACING.small,
    borderTopRightRadius: SPACING.small,
  },
  headerHandleFullyOpenContainer: {
    borderRadius: 0,
  },
  shadow: {
    shadowColor: MkiColors.blackBackground,
    shadowOpacity: 0.5,
    shadowOffset: {
      width: 0,
      height: SPACING.meager - 1,
    },
    shadowRadius: SPACING.meager,
    elevation: SPACING.extraLarge,
  },
});

export default MkiBottomSheet;
