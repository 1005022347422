import {
  CLIENT_APPLICATION_USAGES_SUCCESS,
  CLIENT_LIST_JSON_FAILURE,
  CLIENT_LIST_JSON_REQUEST,
  CLIENT_LIST_JSON_SUCCESS,
  CLIENT_USAGE_HISTORIES_SUCCESS,
  DELETE_CLIENT_PII_SUCCESS,
  GET_CLIENT_POLICIES_SUCCESS,
  SET_CLIENT_INFO,
  SET_CURRENT_NETWORK,
  SET_CURRENT_ORGANIZATION,
  SET_TIMESPAN,
  WIPE_REDUX,
  WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS,
} from "@meraki/shared/redux";
import { AnyAction, Reducer } from "redux";

import { RequestStatus } from "~/constants/Requests";
import { filterBlockedClients, filterClientsLastSeenWithinTimespan } from "~/lib/ClientUtils";
import { appSelect } from "~/lib/PlatformUtils";
import resolvedState, { reducerHelper } from "~/lib/ReducerUtils";
import { ClientList, ClientState } from "~/shared/types/Client";
import { ClientPolicy } from "~/shared/types/ClientPolicy";

const emptyObject = {};

export const initialState: ClientState = {
  items: emptyObject,
  clientPolicies: [],
  usageHistories: emptyObject,
  applicationUsages: emptyObject,
  status: RequestStatus.neverRun,
};

const clients: Reducer<ClientState> = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CLIENT_LIST_JSON_SUCCESS: {
      const items: ClientList = appSelect({
        go: () => {
          const { timespan } = action.meta;
          const prevClients = filterClientsLastSeenWithinTimespan(state.items, timespan);
          const blockedClients = filterBlockedClients(state.items);
          const clients = action.response;
          const mergedClients = { ...prevClients, ...blockedClients, ...clients };
          return mergedClients || emptyObject;
        },
        enterprise: () => action.response,
      })();

      const nextState = {
        ...state,
        items,
        status: RequestStatus.success,
      };
      return resolvedState(nextState, state);
    }
    case CLIENT_LIST_JSON_FAILURE: {
      return {
        ...state,
        status: RequestStatus.failure,
      };
    }
    case CLIENT_USAGE_HISTORIES_SUCCESS: {
      const usageHistories = action.response.reduce((result: any, entry: any) => {
        const { clientId, usageHistory } = entry;
        const historyInFormat = usageHistory.map(({ ts, received, sent }: any) => {
          return [Math.floor(new Date(ts).getTime() / 1000), received, sent];
        });

        return {
          ...result,
          [clientId]: historyInFormat,
        };
      }, {});

      return reducerHelper(state, "usageHistories", usageHistories);
    }
    case CLIENT_APPLICATION_USAGES_SUCCESS: {
      const applicationUsages = action.response.reduce((result: any, entry: any) => {
        const { clientId, applicationUsage } = entry;
        const appUsagesInFormat = applicationUsage.reduce(
          (result: any, { application, ...usage }: any) => ({
            ...result,
            [application]: usage,
          }),
          {},
        );

        return {
          ...result,
          [clientId]: appUsagesInFormat,
        };
      }, {});

      return reducerHelper(state, "applicationUsages", applicationUsages);
    }
    case SET_CLIENT_INFO: {
      const clientId = action.client.id;
      return {
        ...state,
        items: {
          ...state.items,
          [clientId]: {
            ...state.items[clientId],
            ...action.client,
          },
        },
      };
    }
    case GET_CLIENT_POLICIES_SUCCESS: {
      const clientPolicies: ClientPolicy[] = action.response;

      return {
        ...state,
        clientPolicies,
      };
    }
    case DELETE_CLIENT_PII_SUCCESS: {
      const { clientId } = action.meta;
      const newItems = { ...state.items };
      delete newItems[clientId];

      return {
        ...state,
        items: newItems,
      };
    }
    case WIRELESS_CLIENT_CONNECTIVITY_EVENTS_SUCCESS: {
      const { clientId } = action.meta;
      const band = action?.response?.[0]?.band;

      return {
        ...state,
        items: {
          ...state.items,
          [clientId]: {
            ...state.items[clientId],
            band,
          },
        },
      };
    }
    case SET_TIMESPAN:
      if (action.meta?.skipReload) {
        return state;
      }
      return initialState;
    case CLIENT_LIST_JSON_REQUEST:
      return {
        ...state,
        status: RequestStatus.pending,
      };
    case SET_CURRENT_NETWORK:
    case SET_CURRENT_ORGANIZATION:
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default clients;
