import { Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { Pressable } from "react-native";

export interface ItemType {
  id: string;
  name: string;
}

interface DrawerSelectorItemProps<T> {
  item: T;
  renderItemIcon?: (item: T) => React.ReactElement;
  selected?: boolean;
  onPress: (networkId: string) => void;
  testID?: string;
}

export function DrawerSelectorItem<T extends ItemType>({
  item,
  renderItemIcon,
  selected,
  onPress,
  testID,
}: DrawerSelectorItemProps<T>) {
  return (
    <Pressable onPress={() => onPress(item.id)} testID={testID}>
      <Box
        flexDirection="row"
        alignItems="center"
        gap="sm"
        padding="xs"
        borderRadius="md"
        backgroundColor={selected ? "control.bg.active" : undefined}
      >
        {renderItemIcon?.(item)}
        <Box flex={1}>
          <Text size="p1" weight="semiBold" color={selected ? "regular" : "light"}>
            {item.name}
          </Text>
        </Box>
        {selected && <Icon name="Check" color="brandAccent.icon.active" />}
      </Box>
    </Pressable>
  );
}
