import z from "zod";

export const GoOrgSearchResultSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    shardId: z.number().nullable(),
    eid: z.string(),
    networkId: z.string(),
  })
  .describe("GoOrgSearchResultSchema");

export const GoOrgSearchResultsSchema = z
  .array(GoOrgSearchResultSchema)
  .describe("GoOrgSearchResultsSchema");

export type GoOrgSearchResult = z.infer<typeof GoOrgSearchResultSchema>;
export type GoOrgSearchResults = z.infer<typeof GoOrgSearchResultsSchema>;
