import { UIManager } from "react-native";

import { isAndroid } from "~/lib/PlatformUtils";

export function setupAndroidLayoutAnimation() {
  if (isAndroid() && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const clamp = (value: number, min: number, max: number) => {
  "worklet";
  return Math.max(min, Math.min(value, max));
};
