import { ReactNode, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";

export type FloatingProps = {
  testID?: string;
  badge: ReactNode;
  children: ReactNode;
};

export function Floating({ badge, children, testID }: FloatingProps) {
  const [badgeOffset, setBadgeOffset] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const onBadgeLayout = (event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setBadgeOffset({ height, width });
  };

  return (
    <View testID={testID}>
      {children}
      <View
        onLayout={onBadgeLayout}
        style={{
          position: "absolute",
          right: -(badgeOffset.width / 2),
          top: -(badgeOffset.height / 2),
          opacity: badgeOffset.width === 0 || badgeOffset.height === 0 ? 0 : 1,
        }}
      >
        {badge}
      </View>
    </View>
  );
}
