export const colorPalette = {
  neutral: {
    transparent: "transparent",
    "100": "#FFFFFF",
    "98": "#F7F7F7",
    "95": "#F0F1F2",
    "90": "#E1E4E8",
    "85": "#D0D4D9",
    "80": "#C1C6CC",
    "70": "#A7ADB5",
    "65": "#979FA8",
    "60": "#889099",
    "55": "#7E868F",
    "50": "#6F7680",
    "45": "#656C75",
    "40": "#596069",
    "35": "#464C54",
    "25": "#373C42",
    "15": "#23282E",
    "5": "#0F1214",
    "0": "#000000",
  },
  interact: {
    "95": "#E3EEFF",
    "90": "#CCE1FF",
    "85": "#BAD6FF",
    "80": "#A3C8FF",
    "70": "#7CADF7",
    "65": "#649EF5",
    "60": "#5191F0",
    "55": "#3E84E5",
    "50": "#2774D9",
    "45": "#1D69CC",
    "40": "#0D5CBD",
    "35": "#0051AF",
  },
  positive: {
    "95": "#D9F4FF",
    "90": "#B5E9FF",
    "85": "#9ADFFC",
    "80": "#6FD2FC",
    "70": "#33BBF5",
    "65": "#23A8EB",
    "60": "#139BEB",
    "55": "#0D8BD4",
    "50": "#087ABD",
    "45": "#0570AD",
    "40": "#03639C",
    "35": "#015788",
  },
  negative: {
    "95": "#FFE8E9",
    "90": "#FFD4D5",
    "85": "#FFC7C9",
    "80": "#FFB2B5",
    "70": "#FF878B",
    "65": "#FF6E72",
    "60": "#FA5762",
    "55": "#EB4651",
    "50": "#D93843",
    "45": "#CC2D37",
    "40": "#B2242D",
    "35": "#A01D26",
  },
  warning: {
    "95": "#FAEFB9",
    "90": "#F5E08E",
    "85": "#F2D268",
    "80": "#F0C243",
    "70": "#E0A419",
    "65": "#D6900D",
    "60": "#CC8604",
    "55": "#BD7202",
    "50": "#B05F04",
    "45": "#A65503",
    "40": "#944B03",
    "35": "#804103",
  },
  severeWarning: {
    "95": "#FFEADB",
    "90": "#FFD9BF",
    "85": "#FCC9A7",
    "80": "#FCB88D",
    "70": "#FC8D4C",
    "65": "#F7782F",
    "60": "#F26722",
    "55": "#D95A1A",
    "50": "#C44F14",
    "45": "#BD410B",
    "40": "#AD3907",
    "35": "#942E03",
  },
  info: {
    "95": "#F3EBFF",
    "90": "#E8D9FF",
    "85": "#E0CCFF",
    "80": "#D6BAFF",
    "70": "#C299FF",
    "65": "#B587FA",
    "60": "#A974F7",
    "55": "#9B5FF5",
    "50": "#8D4EED",
    "45": "#864AE0",
    "40": "#753BCC",
    "35": "#6732B8",
  },
  accentA: {
    "95": "#EBEDFF",
    "90": "#D9DDFF",
    "85": "#CCD1FF",
    "80": "#BAC1FF",
    "70": "#9CA6FF",
    "65": "#8A95FF",
    "60": "#7D8AFF",
    "55": "#6977F0",
    "50": "#5A68E5",
    "45": "#505ED9",
    "40": "#4653C7",
    "35": "#3B47B2",
  },
  accentBC: {
    "95": "#FFE8EF",
    "90": "#FFD4E0",
    "85": "#FFC4D5",
    "80": "#FCB3C8",
    "70": "#FF87A9",
    "65": "#F57398",
    "60": "#F2638C",
    "55": "#E3447C",
    "50": "#CF3A7A",
    "45": "#C2306F",
    "40": "#B02863",
    "35": "#991D53",
  },
  accentD: {
    "95": "#F3EBFF",
    "90": "#E8D9FF",
    "85": "#E0CCFF",
    "80": "#D6BAFF",
    "70": "#C299FF",
    "65": "#B587FA",
    "60": "#A974F7",
    "55": "#9B5FF5",
    "50": "#8D4EED",
    "45": "#864AE0",
    "40": "#753BCC",
    "35": "#6732B8",
  },
  accentE: {
    "95": "#EBF5E0",
    "90": "#D1E8B7",
    "85": "#BCDE95",
    "80": "#A6D472",
    "70": "#85BD46",
    "65": "#74AD36",
    "60": "#6BA32A",
    "55": "#598F1B",
    "50": "#4D8212",
    "45": "#45780B",
    "40": "#3B6906",
    "35": "#335C04",
  },
  accentF: {
    "95": "#FFEADB",
    "90": "#FFD9BF",
    "85": "#FCC9A7",
    "80": "#FCB88D",
    "70": "#FC8D4C",
    "65": "#F7782F",
    "60": "#F26722",
    "55": "#D95A1A",
    "50": "#C44F14",
    "45": "#BD410B",
    "40": "#AD3907",
    "35": "#942E03",
  },
  accentGH: {
    "95": "#D5F5F5",
    "90": "#A9EBEB",
    "85": "#84E3E3",
    "80": "#4AD9D9",
    "70": "#17C2C2",
    "65": "#0BB2B8",
    "60": "#04A4B0",
    "55": "#028E99",
    "50": "#01818C",
    "45": "#017580",
    "40": "#006773",
    "35": "#005C66",
  },
  accentI: {
    "95": "#FFE8F9",
    "90": "#FFD1F3",
    "85": "#FAC5ED",
    "80": "#F7B0E5",
    "70": "#F582D8",
    "65": "#F26DD1",
    "60": "#E85FC6",
    "55": "#D649B3",
    "50": "#C23EA1",
    "45": "#B53394",
    "40": "#A62686",
    "35": "#941B76",
  },
  accentJ: {
    "95": "#EBEDFF",
    "90": "#D9DEFA",
    "85": "#CED3F2",
    "80": "#C1C6E8",
    "70": "#A3AAD6",
    "65": "#959CCC",
    "60": "#868EC2",
    "55": "#767EB2",
    "50": "#6871A3",
    "45": "#5D6596",
    "40": "#545C8A",
    "35": "#484F7A",
  },
  accentK: {
    "95": "#D4F5E1",
    "90": "#B6EDCC",
    "85": "#97E5B8",
    "80": "#75D9A0",
    "70": "#4CBF7F",
    "65": "#36B26E",
    "60": "#21A65F",
    "55": "#169855",
    "50": "#0F874C",
    "45": "#0B7B46",
    "40": "#087041",
    "35": "#075E39",
  },

  // Branding colors?
  green: {
    "95": "#E0F5D5",
    "90": "#C5EBB2",
    "85": "#B0E396",
    "80": "#98D977",
    "70": "#6BBF41",
    "65": "#5EB035",
    "60": "#52A62B",
    "55": "#45991F",
    "50": "#398519",
    "45": "#2B7A0C",
    "40": "#266B0B",
    "35": "#1F5E06",
  },

  // Branding colors Go
  purple: {
    "95": "#F3F2FF", // 1
    "90": "#E3E2FF", // 2
    "85": "#D3D1FF", // 3
    "80": "#C2C0FF", // 4
    "70": "#B2AFFF", // 5
    "65": "#918EFF", // 6
    "60": "#7571FE", // 7
    "55": "#5E59F8", // 8
    "50": "#4E49DB", // 9
    "45": "#413DBD", // 10
    "40": "#413DBD",
    "35": "#413DBD",
  },

  // Indoor Air Quality
  indoorAirQuality: {
    excellent: "#41B840",
    good: "#8EBC42",
    fair: "#FFBF3F",
    poor: "#FF9000",
    inadequate: "#DA0000",
    excellentDtmaly: "#41B8fC",
    goodDtmaly: "#8EBCFF",
  },

  // Attribute Search Colors
  attributeSearch: {
    red: "#D9544E",
    orange: "#EFAA74",
    yellow: "#F9DA78",
    green: "#67C971",
    blue: "#588AE4",
    purple: "#8F4CE2",
    pink: "#DA82C1",
    brown: "#AD6B3A",
    blackGray: "#000000",
    whiteGray: "#FFFFFF",
  },
} as const;

export const colorPaletteLegacy = {
  ...colorPalette,

  positive: {
    "95": "#EAF7E4",
    "90": "#B5E9FF",
    "85": "#A1DB84",
    "80": "#6FD2FC",
    "70": "#6DC242",
    "65": "#53A828",
    "60": "#45991F",
    "55": "#378716",
    "50": "#087ABD",
    "45": "#0570AD",
    "40": "#03639C",
    "35": "#015788",
  },

  negative: {
    "95": "#FFE8E9",
    "90": "#FCE2DE",
    "85": "#FFC7C9",
    "80": "#F0B3AA",
    "70": "#F08878",
    "65": "#EB6B57",
    "60": "#E05843",
    "55": "#C24632",
    "50": "#B03927",
    "45": "#CC2D37",
    "40": "#B2242D",
    "35": "#A01D26",
  },

  warning: {
    "95": "#FCF5D4",
    "90": "#F5E08E",
    "85": "#F2D268",
    "80": "#F0C243",
    "70": "#D9B216",
    "65": "#D6900D",
    "60": "#CC8604",
    "55": "#BD7202",
    "50": "#A16C03",
    "45": "#A65503",
    "40": "#944B03",
    "35": "#804103",
  },

  info: {
    "95": "#F3EBFF",
    "90": "#D9E7FF",
    "85": "#E0CCFF",
    "80": "#A6C4F5",
    "70": "#7AA7F5",
    "65": "#5B92F0",
    "60": "#A974F7",
    "55": "#9B5FF5",
    "50": "#8D4EED",
    "45": "#864AE0",
    "40": "#753BCC",
    "35": "#6732B8",
  },
} as const;
