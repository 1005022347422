import { keyBy } from "lodash";
import { createSelector } from "reselect";

import Organization from "~/api/models/Organization";
import { ORGANIZATION_FIELDS, ORGANIZATION_KEY } from "~/constants/SearchKeys";
import { filterData } from "~/lib/SearchUtils";
import { getCurrentOrganization, getOrgsState, getSSOAccessOrg } from "~/selectors/getters";
import { searchTextState } from "~/selectors/search";
import { MkiSelector, ReduxSelector, RootState } from "~/shared/types/Redux";

export const getOrgs: ReduxSelector<Organization[]> = createSelector(
  getOrgsState,
  (orgs) =>
    orgs?.slice()?.sort((a: Organization, b: Organization) => {
      return a.name.localeCompare(b.name);
    }),
);

export const filteredOrgsBySearch: ReduxSelector<Organization[]> = createSelector(
  getOrgs,
  searchTextState,
  (orgs = [], searchText) => filterData(orgs, ORGANIZATION_FIELDS, searchText(ORGANIZATION_KEY)),
);

export const getOrgsById: ReduxSelector<Record<string, Organization>> = createSelector(
  getOrgsState,
  (orgs) => orgs && keyBy(orgs, "id"),
);

export const getSingleOrgById: MkiSelector<Organization | undefined, Organization["id"]> =
  createSelector(
    getOrgsById,
    (_: RootState, id: Organization["id"]) => id,
    (orgs, id) => orgs?.[id],
  );

export const getOrgsByEid: ReduxSelector<Record<string, Organization>> = createSelector(
  getOrgsState,
  (orgs) => orgs && keyBy(orgs, "eid"),
);

export const getOrgCount: ReduxSelector<number> = createSelector(
  getOrgs,
  getSSOAccessOrg,
  (orgs, ssoAccessOrg) => (orgs?.length ?? 0) + Number(!!ssoAccessOrg),
);

export const getCurrentOrg: ReduxSelector<Organization> = createSelector(
  getCurrentOrganization,
  getOrgsById,
  (curOrgId, orgs) => (curOrgId ? orgs?.[curOrgId] : undefined),
);

export const getCurrentOrgEid: ReduxSelector<string> = (state) => getCurrentOrg(state)?.eid;
