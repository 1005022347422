import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type ThemeName =
  | "light"
  | "dark"
  | "lightDeuteranomaly"
  | "darkDeuteranomaly"
  | "amoled"
  | "system";
interface ThemeStore {
  theme: ThemeName;
  setTheme: (theme: ThemeName) => void;
}

export const useTheme = create<ThemeStore>()(
  persist(
    (set) => ({
      theme: "system",
      setTheme: (theme) => {
        return set((state) => ({ ...state, theme }));
      },
    }),
    {
      name: "ThemeStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
      version: 2,
      migrate: async (state) => {
        const themeState = state as ThemeStore;

        if (!state || typeof state !== "object") {
          return state as ThemeStore;
        }

        // @ts-ignore - This is an old type that was valid but is no longer valid, we are migrating away from it
        if (themeState.theme === "lightDtmaly") {
          return { ...themeState, theme: "lightDeuteranomaly" };
        }

        // @ts-ignore - This is an old type that was valid but is no longer valid, we are migrating away from it
        if (themeState.theme === "darkDtmaly") {
          return { ...themeState, theme: "darkDeuteranomaly" };
        }

        return themeState as ThemeStore;
      },
    },
  ),
);
