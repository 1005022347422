import * as errorMonitor from "@meraki/core/errors";
import { keyBy } from "lodash";
import { createSelector } from "reselect";

import {
  CLIENTS_LIST_SEARCH_FIELDS,
  CLIENTS_SEARCH_FIELDS,
  CLIENTS_SEARCH_KEY,
} from "~/constants/SearchKeys";
import { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import {
  calculateOutlierUsage,
  clientInfo,
  mergeClients,
  networkFunctions,
  smFunctions,
} from "~/lib/ClientUtils";
import { filterData } from "~/lib/SearchUtils";
import { isUnconfiguredSSID } from "~/lib/SSIDUtils";
import { verifyIsSMDevice } from "~/lib/TypeHelper";
import { gxDeviceSelector } from "~/selectors/devices";
import { clientsState, devicesState, smState } from "~/selectors/getters";
import { ssidsForCurrentNetwork } from "~/selectors/mkiconf";
import { networkTypesSelector } from "~/selectors/organizations";
import { clientSearchText, searchTextState } from "~/selectors/search";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import {
  BlockedNetworks,
  Client,
  ClientList,
  ExtendedClient,
  ExtraNetworkClientFields,
  LimitedNetworks,
  NetworkClientOrSMDevice,
} from "~/shared/types/Client";
import {
  PolicyTypeOnClient,
  PredefiendDevicePolicyID,
  PredefiendDevicePolicyIDs,
} from "~/shared/types/ClientPolicy";
import Device_DeprecatedType from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { SystemsManagerDevice } from "~/shared/types/SystemsManager";

export const clientsByMac = createSelector(
  clientsState,
  (clients): Record<string, Client> => keyBy(clients, "mac"),
);

export const clientsCount = createSelector(clientsState, (clients: ClientList) =>
  clients ? Object.keys(clients).length : 0,
);

export const smDevicesCount = createSelector(smState, (devices) =>
  devices ? Object.keys(devices).length : 0,
);

const getHighUsageClients = createSelector(clientsState, (clients: ClientList) =>
  calculateOutlierUsage(clients),
);

export const clientsSelector = createSelector(
  clientsState,
  getHighUsageClients,
  ssidsForCurrentNetwork,
  networkTypesSelector,
  smState,
  clientsByMac,
  (clients, highUsages, ssids, networkTypes, sm, macs): NetworkClientOrSMDevice[] => {
    const mapClientData = (c: Client[]) =>
      c.map((client, i) => ({ ...clientInfo(client, ssids, networkTypes, highUsages[i]) }));

    const networkClients: (Client & ExtraNetworkClientFields)[] | undefined = clients
      ? mapClientData(Object.values(clients))
      : undefined;
    const smDevices: SystemsManagerDevice[] | undefined = sm ? Object.values(sm) : undefined;
    const networkMacs: Set<string> = macs ? new Set(Object.keys(macs)) : new Set();

    return mergeClients(smDevices, networkMacs, networkClients);
  },
);

export const networkContainsClients = createSelector(
  clientsSelector,
  (clients: NetworkClientOrSMDevice[]) => clients.length > 0,
);

export const filteredBySearchClientsSelector = createSelector(
  clientsSelector,
  searchTextState,
  (clients, searchText) =>
    filterData(clients, CLIENTS_SEARCH_FIELDS, searchText(CLIENTS_SEARCH_KEY)),
);

export const customFilteredSearchedClients = createSelector(
  filteredBySearchClientsSelector,
  (_: RootState, props: any) => props?.customFilter || CUSTOM_FILTERS.DEFAULT,
  (clients: NetworkClientOrSMDevice[], customFilter) => customFilter(clients),
);

export const clientCountsBySSIDNumber = createSelector(clientsSelector, (clients) => {
  const clientCountsBySSIDNumber: Record<number, number> = {};

  clients.forEach((extendedClient) => {
    const client = extendedClient as ExtendedClient;
    if (typeof client.connectedBy === "number") {
      const currentCount = clientCountsBySSIDNumber[client.connectedBy];
      clientCountsBySSIDNumber[client.connectedBy] = currentCount ? currentCount + 1 : 1;
    }
  });
  return clientCountsBySSIDNumber;
});

interface SelectClientProps {
  isClientConnected: WithConnectedClientsProps["isClientConnected"];
  customFilter?: (clients: NetworkClientOrSMDevice[]) => NetworkClientOrSMDevice[];
  isSm: boolean;
}

export const selectClients = createSelector(
  clientsSelector,
  searchTextState,
  (_: RootState, props: SelectClientProps) => props,
  (
    clients: NetworkClientOrSMDevice[],
    searchText,
    { isClientConnected, customFilter = CUSTOM_FILTERS.DEFAULT, isSm },
  ) => {
    const group = isSm ? smFunctions : networkFunctions;
    const { sortBy, filterBy, searchFields, searchKey } = group;
    const c = filterBy(clients)
      .map((client) => ({
        ...client,
        // @ts-expect-error TS(2345): Argument of type 'ExtendedClient | ExtendedSM | Ex... Remove this comment to see the full error message
        isOnline: isClientConnected(client),
      }))
      // @ts-expect-error TS(2345): Argument of type '((clientA: SMDeviceType, clientB... Remove this comment to see the full error message
      .sort(sortBy);
    return filterData(customFilter(c), searchFields, searchText(searchKey));
  },
);

const getDate = (c: NetworkClientOrSMDevice) => {
  try {
    return verifyIsSMDevice(c) ? c.lastConnected : new Date(c.lastSeen).getTime() / 1000;
  } catch (error) {
    const lastConnected = verifyIsSMDevice(c) ? c?.lastConnected : c?.lastSeen;
    let message = `error in selectors/clients getDate: client=${c} | isSM=${verifyIsSMDevice(
      c,
    )} | lastConnected=${lastConnected}`;
    if (error instanceof Error) {
      message += ` | ${error}`;
    }
    errorMonitor.notify(message);
    // https://jira.ikarem.io/browse/DM-4782
    throw Error(message);
  }
};

const sortAllClients = (clientA: NetworkClientOrSMDevice, clientB: NetworkClientOrSMDevice) =>
  getDate(clientB) - getDate(clientA);

export const selectAllClients = createSelector(
  clientsSelector,
  clientSearchText,
  (_: RootState, { isClientConnected, customFilter }: any) => ({
    isClientConnected,
    customFilter,
  }),
  (
    clients: NetworkClientOrSMDevice[],
    searchText,
    { isClientConnected, customFilter = CUSTOM_FILTERS.DEFAULT },
  ) => {
    const c = clients.map((client: NetworkClientOrSMDevice) => ({
      ...client,
      isOnline: isClientConnected(client),
    }));
    return filterData(customFilter(c), CLIENTS_LIST_SEARCH_FIELDS, searchText).sort(sortAllClients);
  },
);

// TODO: Update this when policies/byClient is avaliable
export const getBlockedNetworksForClient = createSelector(
  ssidsForCurrentNetwork,
  gxDeviceSelector,
  (_: RootState, props: any) => props.client,
  (ssids, securityAppliance, client) => {
    const blockedNetworks: BlockedNetworks = {
      wired: false,
      ssids: {},
    };

    for (const ssid of ssids) {
      if (!isUnconfiguredSSID(ssid)) {
        blockedNetworks.ssids[ssid.number] = false;
      }
    }

    // client can be both undefiend and null, "== null" check both null and undefined
    if (client?.policies == null || client?.policies?.length === 0) {
      return blockedNetworks;
    }

    client.policies.forEach(({ type, policy, ssidNumber }: any) => {
      if (policy === PredefiendDevicePolicyID.blocked) {
        if (type === PolicyTypeOnClient.wired && securityAppliance != null) {
          blockedNetworks.wired = true;
        } else if (type === PolicyTypeOnClient.wireless) {
          blockedNetworks.ssids[ssidNumber] = true;
        }
      }
    });

    return blockedNetworks;
  },
);

export const getLimitedNetworksForClient = createSelector(
  ssidsForCurrentNetwork,
  (_: RootState, props: any) => props.client,
  (ssids: SSID[], client?: ExtendedClient) => {
    const policies = client?.policies;

    const customPolicyOnNetworks: LimitedNetworks = {
      ssids: {},
    };

    for (const ssid of ssids) {
      if (!isUnconfiguredSSID(ssid) && typeof ssid.number === "number") {
        customPolicyOnNetworks.ssids[ssid.number] = undefined;
      }
    }

    if (policies == null || policies.length === 0) {
      return customPolicyOnNetworks;
    }

    policies.forEach(({ type, policy, ssidNumber }) => {
      if (
        !PredefiendDevicePolicyIDs.includes(policy) &&
        type === PolicyTypeOnClient.wireless &&
        typeof ssidNumber === "number"
      ) {
        customPolicyOnNetworks.ssids[ssidNumber] = policy;
      }
    });

    return customPolicyOnNetworks;
  },
);

export const filteredClients = createSelector(
  clientsSelector,
  (_: RootState, props: any) => props?.customFilter || CUSTOM_FILTERS.DEFAULT,
  (clients, customFilter) => customFilter(clients),
);

// TODO: Look into more performant way of doing this (do client augmentation when reducing, etc..)
export const makeClientSelector = () =>
  createSelector(
    clientsSelector,
    (_: RootState, props: any) => props.id,
    (clients: NetworkClientOrSMDevice[], id) => clients.find((c) => c.id === id) || undefined,
  );

export const getClientDataByMac = createSelector(
  clientsSelector,
  devicesState,
  (_: RootState, props: { id: string }) => props.id,
  (clients: NetworkClientOrSMDevice[], devices, id) => {
    const client = clients.find((c) => c.id === id) || undefined;
    const nodeId = verifyIsSMDevice(client) ? null : client?.nodeId;
    const device =
      Object.values(devices).find((d) => String(d.id) === nodeId) || ({} as Device_DeprecatedType);

    return { clientId: id, client, device };
  },
);
