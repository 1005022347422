import { I18n } from "@meraki/core/i18n";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { WPA_ENCRYPTION_MODE } from "~/lib/SSIDUtils";
import { ssidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import RadioSelectionList from "~/shared/components/RadioSelectionList";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useCancelablePromise from "~/shared/hooks/useCancelablePromise";
import { CloseButton } from "~/shared/navigation/Buttons";
import { Dot11w } from "~/shared/types/Models";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "WPASettings"> &
  PendingComponent;

export function WPASettings({ ssid, networkId, ssidNumber, setReqPending }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const actions = useActions();
  const { cancelablePromise } = useCancelablePromise();
  networkId = useCurrentNetworkId();
  ssid = useAppSelector(ssidsSelector)[ssidNumber];

  useEffect(() => {
    const reqs: Promise<unknown>[] = [];
    const reqDone = () => setReqPending(false);
    const handleError = (err: unknown) => {
      setReqPending(false);
      showAlert(I18n.t("ERROR"), err || I18n.t("SERVER_ERROR_TEXT"));
    };
    if (!ssid || ssid.encryptionMode === undefined) {
      reqs.push(actions.getSsids(networkId));
    }
    if (reqs.length > 0) {
      setReqPending(true);
      Promise.all(reqs).then(reqDone, handleError);
    }
  }, [ssid, networkId, ssidNumber, actions, setReqPending]);

  const name = ssid.name;
  const [wpaEncryptionMode, setWpaEncryptionMode] = useState(ssid.wpaEncryptionMode);
  const radioOptions = () => {
    const options: {
      label: string;
      sublabel: string;
      value: string;
    }[] = [];
    options.push({
      label: I18n.t("WPA_SETTINGS.WPA2.TITLE"),
      sublabel: I18n.t("WPA_SETTINGS.WPA2.SUBTITLE"),
      value: WPA_ENCRYPTION_MODE.WPA2,
    });
    options.push({
      label: I18n.t("WPA_SETTINGS.WPA2_3.TITLE"),
      sublabel: I18n.t("WPA_SETTINGS.WPA2_3.SUBTITLE"),
      value: WPA_ENCRYPTION_MODE.WPA2_3,
    });
    options.push({
      label: I18n.t("WPA_SETTINGS.WPA3.TITLE"),
      sublabel: I18n.t("WPA_SETTINGS.WPA3.SUBTITLE"),
      value: WPA_ENCRYPTION_MODE.WPA3,
    });
    return options;
  };
  const updateState = (wpaMode: string) => {
    setWpaEncryptionMode(wpaMode);
    const updatedDot11w: Dot11w = {
      enabled: false,
      required: false,
    };
    switch (wpaMode) {
      case WPA_ENCRYPTION_MODE.WPA2_3:
        updatedDot11w.enabled = true;
        updatedDot11w.required = false;
        break;
      case WPA_ENCRYPTION_MODE.WPA3:
        updatedDot11w.enabled = true;
        updatedDot11w.required = true;
        break;
    }
    setReqPending(true);
    cancelablePromise(
      actions.setSsid(networkId, {
        ...ssid,
        wpaEncryptionMode: wpaMode,
        dot11w: updatedDot11w,
      }),
    )
      .then(() => {
        setReqPending(false);
      })
      .catch((errorMessage) => showAlert(I18n.t("ERROR"), errorMessage));
  };
  return (
    <FullScreenContainerView>
      <DefaultHeader
        title={I18n.t("WPA_SETTINGS.TITLE")}
        description={I18n.t("WPA_SETTINGS.SUBTITLE", { name: name })}
      />
      <RadioSelectionList
        options={radioOptions()}
        onSelect={updateState}
        selectedValue={wpaEncryptionMode}
        screenStyles={styles.contentContainer}
        radioStyle={styles.radioSpacing}
        testID={"WPA_SETTINGS"}
      />
    </FullScreenContainerView>
  );
}
const styles = StyleSheet.create({
  contentContainer: {
    margin: SPACING.default,
  },
  radioSpacing: {
    marginVertical: SPACING.default,
  },
});
export default compose<any>(withPendingComponent)(WPASettings);
