import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Circle, Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Environmental({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color2 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color3 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M12.083 5.425a1 1 0 0 1 1.15-.824 7.505 7.505 0 0 1 6.167 6.167 1 1 0 1 1-1.973.327 5.506 5.506 0 0 0-4.52-4.52 1 1 0 0 1-.824-1.15Zm-6.658 6.658a1 1 0 0 1 1.15.823 5.505 5.505 0 0 0 4.52 4.52 1 1 0 0 1-.326 1.974A7.505 7.505 0 0 1 4.6 13.232a1 1 0 0 1 .824-1.15Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M11.834 1.952a1 1 0 0 1 1.079-.915c5.348.44 9.61 4.703 10.05 10.05a1 1 0 0 1-1.993.164 9.002 9.002 0 0 0-8.221-8.22 1 1 0 0 1-.915-1.079Zm-9.882 9.882a1 1 0 0 1 1.079.915 9.002 9.002 0 0 0 8.22 8.22 1 1 0 1 1-.163 1.994 11.002 11.002 0 0 1-10.05-10.05 1 1 0 0 1 .914-1.08Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
      <Circle cx="12" cy="12" fill={color3} r="3" />
    </Svg>
  );
}
