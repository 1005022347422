import { Card, Input, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { Form } from "@meraki/shared/form";
import { formatPerSecond, formatTransferBits, parseValueUnit } from "@meraki/shared/formatters";
import { useWatch } from "react-hook-form";

import {
  MINIMUM_LIMIT_VALUE,
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_MEBIBITS_INTEGERED,
} from "../constants/TrafficShaping";

export const getLimitIndex = (limitInKiB?: number | null) => {
  if (limitInKiB == null) {
    return;
  }

  const currentLimit = TRAFFIC_SHAPING_MEBIBITS_INTEGERED[limitInKiB];
  if (currentLimit == null) {
    return;
  }

  return TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(currentLimit);
};

interface LimitUsageCardProps {
  title: string;
  disabled?: boolean;
}

export interface LimitUsageCardForm {
  limitIndex?: number;
}

export const getUsageLimitPolicyName = (limitSpeed: string) => `Usage limit - ${limitSpeed}`;

const getLimitByIndex = (limitIndex?: number) => {
  const limitInKB = limitIndex == null ? undefined : TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex];
  return formatTransferBits(limitInKB ?? MINIMUM_LIMIT_VALUE);
};

export const LimitUsageCard = ({ title, disabled }: LimitUsageCardProps) => {
  const limitIndex = useWatch<LimitUsageCardForm, "limitIndex">({ name: "limitIndex" });

  const parsedLimit = parseValueUnit(getLimitByIndex(limitIndex));
  return (
    <Card>
      <Card.Header
        title={title}
        rightAccessory={
          <Box flexDirection="row" alignItems="center">
            <Box width={150} paddingRight="xs">
              <Input
                maxLength={3}
                value={parsedLimit.value.toString()}
                disabled
                testID="LIMIT_USAGE_INPUT"
              />
            </Box>
            <Text size="p1">{formatPerSecond(parsedLimit.unit)}</Text>
          </Box>
        }
      />
      <Card.Content>
        <Box paddingHorizontal="md">
          <Form.Slider
            name="limitIndex"
            minimumValue={0}
            maximumValue={9}
            step={1}
            disabled={disabled}
            valueFormatter={(limitIndex) => formatPerSecond(getLimitByIndex(limitIndex))}
            testID="LIMIT_USAGE_SLIDER"
          />
        </Box>
      </Card.Content>
    </Card>
  );
};
