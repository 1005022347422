import { ErrorMessage } from "@hookform/error-message";
import { I18n } from "@meraki/core/i18n";
import { Button, Notification, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  CreateAccountForm,
  queryClient,
  useCreateAccount,
  useIsEmailTaken,
  verifyIsLoginResponse,
  verifyIsModeResponse,
} from "@meraki/shared/api";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { Form } from "@meraki/shared/form";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { KeyboardAvoidingView, Platform, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { useDispatch } from "react-redux";

import { CaptchaModal, CaptchaModalMethods } from "../components/CaptchaModal";
import { PasswordStrength } from "../components/PasswordStrength";
import { setShardFromPath } from "../libs/LoginUtils";

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "TwoFactorAuth">;

const regions = [
  { label: I18n.t("CREATE_ACCOUNT.REGION.NA"), value: "NA" },
  { label: I18n.t("CREATE_ACCOUNT.REGION.SA"), value: "SA" },
  { label: I18n.t("CREATE_ACCOUNT.REGION.EMEA"), value: "EMEA" },
  { label: I18n.t("CREATE_ACCOUNT.REGION.APAC"), value: "APAC" },
];

export const CreateAccountScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const createAccount = useCreateAccount();
  const captchaModalRef = useRef<CaptchaModalMethods>(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const dispatch = useDispatch();

  const handleCreateAccount = (data: CreateAccountForm, token?: string) => {
    const body = token ? { ...data, token } : data;
    createAccount.mutate(body, {
      onSuccess: (response) => {
        if (verifyIsLoginResponse(response) && response.success) {
          dispatch(setShardFromPath(response));
          navigation.reset({ index: 0, routes: [{ name: "Login" }] });
          navigation.navigate("VerifyEmail", { response, email: body.email });
        }
        if (verifyIsModeResponse(response)) {
          if (response.mode === "captcha") {
            setShowCaptcha(true);
          }
          if (response?.mode === "one_time_password") {
            navigation.reset({ index: 0, routes: [{ name: "Login" }] });
            navigation.navigate("OTPSignup", { email: body.email });
          }
          if (response?.mode === "signup") {
            methods.setError("root", { type: "server", message: response.error });
          }
        }
      },
    });
  };

  const methods = useForm<CreateAccountForm>();
  const onSubmit = methods.handleSubmit((data) => {
    handleCreateAccount(data);
  });

  useEffect(() => {
    if (showCaptcha) {
      captchaModalRef.current?.show();
    }
  }, [showCaptcha]);

  // TODO: turn this on once the work for marketing emails has been completed
  // see https://jira.ikarem.io/browse/DM-5185
  const { value: marketingEnabled } = useBoolFlag("ld", "enable-signup-marketing-emails");

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingView}
      behavior={Platform.select({
        ios: "padding",
        android: "height",
      })}
      keyboardVerticalOffset={Platform.select({
        ios: 100,
        android: 0,
      })}
    >
      <Screen addDefaultPadding gap="md">
        <Form {...methods}>
          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.FULL_NAME")}
            name="name"
            returnKeyType="next"
            onSubmitEditing={() => methods.setFocus("email")}
            autoComplete="name"
            rules={{ required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED") }}
          />
          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.EMAIL")}
            name="email"
            returnKeyType="next"
            onSubmitEditing={() => methods.setFocus("password")}
            autoComplete="email"
            autoCapitalize="none"
            onBlur={async () => {
              // we test if email is taken onBlur instead of on validate (which triggers
              // onChange after the form has been submitted once), or onSubmit (user may
              // click submit button multiple times in one submission, triggering brute force)
              // to minimize the calls being made to the endpoint, since it will hit
              // "brute force attempt detected" very quickly. This approach mirrors dashboard's
              // behaviour
              const email = methods.getValues("email");
              try {
                methods.clearErrors("email");
                const emailTaken = await queryClient.fetchQuery(
                  useIsEmailTaken.queryKey({ email }),
                  useIsEmailTaken.queryFn({ email }),
                );
                if (emailTaken) {
                  methods.setError("email", {
                    message: I18n.t("CREATE_ACCOUNT.VALIDATION.EMAIL_USED"),
                  });
                }
              } catch (err) {
                if (
                  typeof err === "object" &&
                  err !== null &&
                  "error" in err &&
                  // @ts-ignore this error will be resolved when we upgrade to ts 5.x.x
                  err.error.startsWith("Brute force attempt detected")
                ) {
                  // catches "brute force attempt detected" error and displays it at the
                  // bottom of the form

                  methods.setError("email", {
                    message: I18n.t("CREATE_ACCOUNT.VALIDATION.BRUTE_FORCE_DETECTED"),
                  });
                }
              }
            }}
            rules={{
              required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED"),
            }}
          />
          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.PASSWORD")}
            name="password"
            autoCapitalize="none"
            textContentType="newPassword"
            returnKeyType="next"
            autoComplete="new-password"
            secureTextEntry={passwordHidden}
            onSubmitEditing={() => methods.setFocus("password_confirmation")}
            rightAccessory={
              <Button.Icon
                icon={passwordHidden ? "EyeSlash" : "Eye"}
                onPress={() => setPasswordHidden(!passwordHidden)}
              />
            }
            rules={{
              required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED"),
              minLength: { value: 8, message: I18n.t("CREATE_ACCOUNT.VALIDATION.PASSWORD") },
            }}
            additionalContext={
              <PasswordStrength
                password={methods.watch("password")}
                passwordRules={I18n.t("CREATE_ACCOUNT.PASSWORD_REQUIREMENTS_ENT")}
              />
            }
          />

          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.CONFIRM_PASSWORD")}
            name="password_confirmation"
            autoCapitalize="none"
            textContentType="password"
            autoComplete="current-password"
            returnKeyType="next"
            onSubmitEditing={() => methods.setFocus("company_name")}
            secureTextEntry={passwordHidden}
            rightAccessory={
              <Button.Icon
                icon={passwordHidden ? "EyeSlash" : "Eye"}
                onPress={() => setPasswordHidden(!passwordHidden)}
              />
            }
            rules={{
              required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED"),
              min: 8,
              validate: (val: string) => {
                if (methods.watch("password") !== val) {
                  return I18n.t("CREATE_ACCOUNT.VALIDATION.NO_MATCH");
                }
                return undefined;
              },
            }}
            additionalContext={(() => {
              if (!methods.watch("password_confirmation")?.length) {
                return null;
              }
              return methods.watch("password") === methods.watch("password_confirmation") ? (
                <PasswordMatchText status="positive" />
              ) : (
                <PasswordMatchText status="negative" />
              );
            })()}
          />
          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.ORG_NAME")}
            name="company_name"
            onSubmitEditing={() => methods.setFocus("address1")}
            returnKeyType="next"
            rules={{ required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED") }}
          />
          <Form.Input
            label={I18n.t("CREATE_ACCOUNT.ADDRESS")}
            name="address1"
            optionality="optional"
            onSubmitEditing={() => methods.setFocus("region")}
            returnKeyType="next"
            autoComplete="address-line1"
          />
          <Form.PickerCard
            options={regions}
            name="region"
            title={I18n.t("CREATE_ACCOUNT.REGION.TITLE")}
            description={I18n.t("CREATE_ACCOUNT.REGION.INFO")}
            rules={{ required: I18n.t("CREATE_ACCOUNT.VALIDATION.REQUIRED") }}
          />
          {marketingEnabled && (
            <Form.Checkbox name="receive_marketing">
              {I18n.t("CREATE_ACCOUNT.RECEIVE_MARKETING")}
            </Form.Checkbox>
          )}
          {methods.formState.errors.root && (
            <ErrorMessage
              name="root"
              render={({ message }) => <Notification.Inline status="negative" message={message} />}
            />
          )}
        </Form>
        <Button
          kind="primary"
          text={I18n.t("CREATE_ACCOUNT.CREATE_ACCOUNT_BUTTON")}
          onPress={onSubmit}
          analytics={{
            event: "onPress",
            eventName: "signup",
          }}
          loading={createAccount.isLoading}
        />
        {showCaptcha && (
          <CaptchaModal
            ref={captchaModalRef}
            onCaptchaComplete={(token?: string) => {
              setShowCaptcha(false);
              handleCreateAccount(methods.getValues(), token);
            }}
          />
        )}
      </Screen>
    </KeyboardAvoidingView>
  );
};

const PasswordMatchText = ({ status }: { status: "positive" | "negative" }) => (
  <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
    <Status status={status} />
    <Box paddingLeft="md">
      <Text color="light">
        {status === "positive"
          ? I18n.t("CREATE_ACCOUNT.PASSWORDS_MATCH")
          : I18n.t("CREATE_ACCOUNT.PASSWORDS_DONT_MATCH")}
      </Text>
    </Box>
  </Box>
);

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
});
