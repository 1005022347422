import { chain, first, groupBy, includes, indexOf, isEmpty, last as lodashLast, min } from "lodash";

const GOOD_STATUS_INDEX = 4;
const WARNING_STATUS_INDEX = 3;
const ALERT_STATUS_INDEX = 2;
const BAD_STATUS_INDEX = 1;

type Carea = {
  xaxis: {
    from: number;
    to: number;
  };
  status: number;
};

type Options = {
  t0: number;
  t1: number;
  data: number[][];
  default_state: number;
  width: number;
  priority: number[];
};

// from private/javascripts/fill_graph#compute_careas
export function computeBarGraph(options: Options) {
  const t0 = options.t0;
  const t1 = options.t1;
  const data = options.data;
  let current = [t0, options.default_state];
  const careas: Carea[] = [];
  const len = data.length;
  let i: number, next: number[];

  for (i = 0; i < len && data[i][0] <= t0; i++) {
    current = data[i];
  }

  for (; i < len && data[i][0] <= t1; i++) {
    next = data[i];
    careas.push({
      xaxis: { from: current[0], to: next[0] },
      status: current[1],
    });
    current = next;
  }
  careas.push({
    xaxis: { from: current[0], to: t1 },
    status: current[1],
  });

  return resizeBarGraph(careas, options);
}

function resizeBarGraph(careas: Carea[], options: Options) {
  const t0 = options.t0;
  const t1 = options.t1;
  const width = options.width;
  const one_pixel_time = Math.ceil((t1 - t0) / width);
  const priority = options.priority;
  const len = careas.length;
  let shifted_careas: Carea[] = [];
  let queue: Carea[] = [];

  if (isEmpty(priority)) {
    return careas;
  } else {
    // queue holds last pixel
    function addToQueue(carea: Carea) {
      let last = lodashLast(shifted_careas)!;
      const worst = worstInQueue();

      if (
        worst &&
        hasPriority(indexOf(priority, worst.status), indexOf(priority, carea.status)) &&
        carea.xaxis.to - worst.xaxis.from >= one_pixel_time
      ) {
        if (last.status === worst.status) {
          last.xaxis.to = Math.max(last.xaxis.from + one_pixel_time, worst.xaxis.to);
        } else {
          last.xaxis.to = Math.max(last.xaxis.to, worst.xaxis.from);
          last = {
            xaxis: {
              from: last.xaxis.to,
              to: Math.max(worst.xaxis.to, last.xaxis.to + one_pixel_time),
            },
            status: worst.status,
          };
          shifted_careas.push(last);
        }
        queue = [];
      }

      // queue length is <= 2*one_pixel_time
      // We buffer up 2 pixels because the current pixel can be affected by a carea up to one pixel to the right
      // The first pixel is the one we want to write and the second pixel allows us to look forward one pixel
      if (carea.xaxis.to - last.xaxis.to >= 2 * one_pixel_time) {
        if (last.status === carea.status) {
          last.xaxis.to = Math.max(last.xaxis.from + one_pixel_time, carea.xaxis.to);
        } else {
          last.xaxis.to = Math.max(last.xaxis.to, carea.xaxis.from);
          last = {
            xaxis: { from: last.xaxis.to, to: carea.xaxis.to },
            status: carea.status,
          };
          shifted_careas.push(last);
        }
        queue = [];
      } else if (includes(priority, carea.status)) {
        queue.push(carea);
      }
    }

    // Finds the carea(s) with the lowest priority.
    // If there are several it returns a new carea that spans all of the careas in the queue with that priority
    function worstInQueue() {
      const groupedPriorities = groupBy(queue, function (carea: Carea) {
        return indexOf(priority, carea.status);
      });
      const keys = chain(groupedPriorities).keys().without("-1").value();
      if (keys.length > 0) {
        const minPriority = min(keys)!;
        const firstMin = first(groupedPriorities[minPriority])!;
        const lastMin = lodashLast(groupedPriorities[minPriority])!;
        return {
          xaxis: { from: firstMin.xaxis.from, to: lastMin.xaxis.to },
          status: lastMin.status,
        };
      } else {
        return null;
      }
    }

    shifted_careas = [careas[0]];
    for (let i = 1; i < len; i++) {
      addToQueue(careas[i]);
    }
    return shifted_careas;
  }
}

function hasPriority(current: any, next: any) {
  return current !== -1 && (next === -1 || current < next);
}

export function computeGoodPercent(options: any) {
  const { t1, t0 } = options;
  const data = computeBarGraph(options).map((d) => ({
    percentage: (d.xaxis.to - d.xaxis.from) / (t1 - t0),
    status: d.status,
  }));

  const totalGoodPercent = data.reduce((totalPercent, { percentage, status }) => {
    let multiplier = 0;
    if (status === GOOD_STATUS_INDEX) {
      multiplier = 1;
    } else if (status === WARNING_STATUS_INDEX) {
      multiplier = 0.95;
    } else if (status === ALERT_STATUS_INDEX) {
      multiplier = 0.75;
    } else if (status === BAD_STATUS_INDEX) {
      multiplier = 0.5;
    }

    return totalPercent + percentage * multiplier;
  }, 0);

  return totalGoodPercent;
}

// isEmptyChart data and helper functions

export function isEmptyChartData(data: any) {
  const targetKey = getTargetKey(data);
  if (targetKey) {
    return isEmptyDataForKey(data, targetKey);
  }
  return true;
}

function getTargetKey(data: any) {
  if (isEmpty(data)) {
    return undefined;
  }
  if (data[0].timestamp !== undefined) {
    return "total";
  }
  if (data[0].x !== undefined) {
    return "y";
  }
  return undefined;
}

function isEmptyDataForKey(data: any, targetKey: any) {
  for (let i = 0; i < data.length; i += 1) {
    if (pointContainsData(data[i], targetKey)) {
      return false;
    }
  }
  return true;
}

function pointContainsData(point: any, targetKey: any) {
  if (point[targetKey] && point[targetKey] !== 0) {
    return true;
  }
  return false;
}
