import { SsidSchedule } from "~/shared/types/Models";

export const alwaysAvailable: SsidSchedule = {
  sunday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  monday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  tuesday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  wednesday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  thursday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  friday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
  saturday: {
    isActive: true,
    from: "12:00 am",
    to: "12:00 am",
  },
};

export const available9To5: SsidSchedule = {
  sunday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  monday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  tuesday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  wednesday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  thursday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  friday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  saturday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
};

export const available9To5Weekdays: SsidSchedule = {
  sunday: {
    isActive: false,
    from: "12:00 am",
    to: "12:00 am",
  },
  monday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  tuesday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  wednesday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  thursday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  friday: {
    isActive: true,
    from: "9:00 am",
    to: "5:00 pm",
  },
  saturday: {
    isActive: false,
    from: "12:00 am",
    to: "12:00 am",
  },
};
