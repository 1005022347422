import { PureComponent } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { HomeStackProps } from "~/go/navigation/Types";
import HighEventClientRow from "~/go/rows/HighEventClientRow";
import withConnectedClients, { WithConnectedClientsProps } from "~/hocs/ConnectedClients";
import { clientName } from "~/lib/ClientUtils";
import { clientsState, getHighEventIps, timespanNameSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import { CloseButton, SettingsButton } from "~/shared/navigation/Buttons";
import { ClientList } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";
import { EventCountByIp, EventCountRich } from "~/shared/types/Umbrella";

type ReduxProps = {
  rawClients: ClientList;
  highEventIps: EventCountByIp;
  timespanName?: string;
};

type Props = ForwardedNativeStackScreenProps<HomeStackProps, "HighEventClientsList"> &
  ReduxProps &
  WithConnectedClientsProps;

interface RowData {
  name: string | null;
  count: number;
  isOnline?: boolean;
  clientId?: string;
}

export class HighEventClientsListScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SettingsButton onPress={() => undefined} />,
      // TODO: this button did not do anything
    });
  }

  renderHighRow = (data: RowData) => {
    const { timespanName } = this.props;
    const { name, count, clientId } = data;
    return (
      <HighEventClientRow
        name={name}
        count={count}
        onPress={clientId ? () => this.pushClientDetails(clientId) : undefined}
        timespanName={timespanName}
      />
    );
  };

  pushClientDetails = (clientId: string) => {
    const { navigation } = this.props;
    navigation.navigate("ClientDetails", {
      id: clientId,
    });
  };

  getData(): RowData[] {
    const { rawClients, highEventIps, isClientConnected } = this.props;
    if (!highEventIps) {
      return [];
    }

    return Object.values(highEventIps).map((element: EventCountRich) => {
      const client = Object.values(rawClients).find((client) => client.ip === element.ip);
      if (rawClients && client) {
        return {
          name: clientName(client),
          isOnline: isClientConnected ? isClientConnected(client) : undefined,
          count: element.count,
          clientId: client.id,
        };
      } else {
        return {
          name: element.ip,
          count: element.count,
        };
      }
    });
  }

  render() {
    return (
      <FullScreenContainerView withScroll>
        <MkiTable<RowData> data={this.getData()} renderRow={this.renderHighRow} />
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    rawClients: clientsState(state),
    timespanName: timespanNameSelector(state),
    highEventIps: getHighEventIps(state),
  };
}

export default compose<any>(
  connect(mapStateToProps),
  withConnectedClients,
)(HighEventClientsListScreen);
