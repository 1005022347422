import { I18n } from "@meraki/core/i18n";
import { ClientDetailsScreen, ClientListScreen } from "@meraki/go/client";
import { ClientGroupProps } from "@meraki/shared/navigation-type";
import { StackType } from "@meraki/shared/navigation-type";
import { registerTags } from "@meraki/shared/search";

export function ClientGroup<T extends ClientGroupProps>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="ClientList"
        component={ClientListScreen}
        options={{
          headerTitle: I18n.t("ROOT_TITLES.CLIENTS"),
        }}
      />
      <Stack.Screen name="ClientDetails" component={ClientDetailsScreen} />
    </>
  );
}

// search tag registrations
registerTags(
  {
    title: "ROOT_TITLES.CLIENTS",
    tab: "MonitorTab",
    scope: "network",
    screen: "ClientList",
  },
  ["clients"],
);
