import { NETWORK_WARM_SPARE_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";

const initialState = {};

const warmSpareSettings = (state = initialState, action: any) => {
  const { type, meta, response } = action;

  switch (type) {
    case NETWORK_WARM_SPARE_SUCCESS: {
      return {
        ...state,
        [meta.networkId]: response,
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default warmSpareSettings;
