import { I18n } from "@meraki/core/i18n";
import { Button, Input, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { standardizeLoginResponse, useResendOTP, useSubmitOTP } from "@meraki/shared/api";
import { showErrorAlert, showOkayAlert } from "@meraki/shared/native-alert";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import { PostLoginProps } from "./Types";

export const OneTimePassword = ({ setLatestResponse }: PostLoginProps) => {
  const submitOTPCode = useSubmitOTP();
  const resendOTPCode = useResendOTP();
  const navigation = useNavigation();
  const [pinCode, setPinCode] = useState("");

  const handleSubmitCode = () => {
    submitOTPCode.mutate(
      { code: pinCode },
      {
        onSuccess: (response) => {
          setLatestResponse(standardizeLoginResponse(response));
        },
        onError: (error) => {
          showErrorAlert(I18n.t("ERROR"), error as string);
        },
      },
    );
  };

  useEffect(() => {
    navigation.setOptions({
      headerTitle: I18n.t("TWO_FACTOR_AUTH.OTP.TITLE"),
    });
  });

  return (
    <Box gap="md">
      {/* todo: OTP on GO has a different text associated with it on purpose. Do we want to continue
      showing different OTP text to users on the Go app? */}
      <Text size="p1">{I18n.t("TWO_FACTOR_AUTH.OTP.ENTERPRISE")}</Text>
      <Input
        label={I18n.t("TWO_FACTOR_AUTH.CODE")}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType="numeric"
        value={pinCode}
        onChangeText={setPinCode}
        disabled={submitOTPCode.isLoading || resendOTPCode.isLoading}
      />
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        onPress={() => {
          handleSubmitCode();
        }}
        analytics={{
          event: "onPress",
          eventName: "verify_otp_code",
          params: { src: "login" },
        }}
        loading={submitOTPCode.isLoading}
        disabled={resendOTPCode.isLoading}
      />
      <Button
        kind="secondary"
        text={I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
        onPress={() => {
          resendOTPCode.mutate(undefined, {
            onSuccess: () => {
              showOkayAlert(
                I18n.t("TWO_FACTOR_AUTH.RESEND.SUCCESS"),
                I18n.t("TWO_FACTOR_AUTH.EMAIL"),
              );
            },
            onError: () => {
              showErrorAlert(
                I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE"),
                I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE_ACTION"),
              );
            },
          });
        }}
        analytics={{
          event: "onPress",
          eventName: "resend_otp_code",
          params: { src: "login" },
        }}
        loading={resendOTPCode.isLoading}
        disabled={submitOTPCode.isLoading}
      />
    </Box>
  );
};
