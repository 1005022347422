import { I18n } from "@meraki/core/i18n";
import { LivePorts, LiveSwitchPorts, LiveToolMsg } from "@meraki/react-live-broker";
import { AppliancePort, SwitchPort } from "@meraki/shared/api";
import { AppliancePortStatusAndFilter, SwitchPortStatusAndFilter } from "@meraki/shared/filters";
import { switchportIsAlerting, switchportIsConnected } from "@meraki/shared/formatters";

export const StatusFilterOptions = [
  "online",
  "offline",
  "disabled",
  "disconnected",
  "poe",
  "uplink",
] as const;

export type StatusFilter = (typeof StatusFilterOptions)[number];

export const getPortFilterTranslation = (filter: StatusFilter) => {
  return I18n.t(`PORT_FILTERS.${filter.toUpperCase()}`);
};

export type CombinedAppliancePort = AppliancePort & LivePorts;

export const combineAppliancePorts = (
  apiPortData?: AppliancePort[],
  livePortData?: LivePorts[],
): CombinedAppliancePort[] => {
  const uplink = { number: 1 } as CombinedAppliancePort;
  const nonUplinkData = apiPortData as CombinedAppliancePort[];

  if (livePortData && livePortData.length > 0) {
    Object.assign(uplink, livePortData[0]);

    nonUplinkData?.forEach((apiPort, index) => {
      if (livePortData[index + 1]) {
        Object.assign(apiPort, livePortData[index + 1]);
      }
    });
  }

  const combinedPortData = [uplink, ...(nonUplinkData ?? [])];
  return combinedPortData;
};

export const deriveAppliancePortStatus = (
  combineAppliancePorts: CombinedAppliancePort[],
): AppliancePortStatusAndFilter[] => {
  const portStatuses: AppliancePortStatusAndFilter[] = [];

  combineAppliancePorts.forEach((cAppliancePort) => {
    const { number, using_poe, carrier, enabled } = cAppliancePort;
    const portProps: AppliancePortStatusAndFilter = { ...cAppliancePort };
    if (carrier) {
      portProps.state = "fullSpeed";
    } else {
      portProps.state = "disconnected";
    }

    if (number === 1) {
      portProps.indicator = "uplink";
      portStatuses.push(portProps);
      return;
    }

    if (!enabled) {
      portProps.state = "disabled";
      portStatuses.push(portProps);
      return;
    } else {
      if (using_poe) {
        portProps.indicator = "active";
      }

      portStatuses.push(portProps);
    }
  });

  return portStatuses;
};

export const deriveSwitchPortStatus = (
  switchPorts: SwitchPort[],
  livePortStatuses: LiveToolMsg<LiveSwitchPorts>,
): SwitchPortStatusAndFilter[] => {
  const portStatuses: SwitchPortStatusAndFilter[] = [];
  switchPorts.forEach((switchPort) => {
    const { portId, enabled } = switchPort;
    const portProps: SwitchPortStatusAndFilter = { ...switchPort };
    if (!enabled) {
      portProps.state = "disabled";
      portStatuses.push(portProps);
      return;
    } else {
      const portStatus = livePortStatuses?.[portId];
      if (!portStatus) {
        //disconnected
        portProps.state = "disconnected";
        portStatuses.push(portProps);
        return;
      } else {
        if (switchportIsAlerting(portStatus)) {
          portProps.state = "warning";
        } else if (switchportIsConnected(portStatus)) {
          portProps.state = "fullSpeed";
        } else {
          portProps.state = "disconnected";
        }
        if (portStatus.is_uplink) {
          portProps.indicator = "uplink";
        } else if (portStatus.using_poe) {
          portProps.indicator = "active";
        }

        portStatuses.push(portProps);
      }
    }
  });

  return portStatuses;
};

export const portListDescription = (port: AppliancePort | SwitchPort) => {
  let description = "";
  const mode =
    port.type === "trunk"
      ? I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NAME_PORT")
      : I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.NAME_PORT");
  description = `${I18n.t("PORTS.TYPE")}: ${mode}`;
  if (port.vlan) {
    description =
      description +
      ` - ${
        port.type === "trunk"
          ? I18n.t("PORTS.VLAN_INFO.TRUNK_MODE.NATIVE_VLAN")
          : I18n.t("PORTS.VLAN_INFO.ACCESS_MODE.VLAN_ID")
      }: ${port.vlan}`;
  }
  return description;
};
