import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Neutral({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 16 16" width={width} height={height} testID={testID}>
      <Path
        d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
        fill={theme.color.dormant.icon.base}
      />
      <Path
        d="M4.5 8a.8.8 0 0 1 .8-.8h5.4a.8.8 0 0 1 0 1.6H5.3a.8.8 0 0 1-.8-.8Z"
        fill={theme.color.dormant.iconIn.base}
      />
    </Svg>
  );
}
