import { StyleSheet, ViewProps } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import { appSelect } from "~/lib/PlatformUtils";
import ListRow, { ListRowProps } from "~/shared/rows/ListRow";

export type CheckBoxRowProps = {
  selected: boolean;
  subtitle?: ListRowProps["subtitle1"];
  screenStyles?: ViewProps["style"];
} & Pick<ListRowProps, "children" | "testID" | "onPress">;

export const CheckBoxRow = (props: CheckBoxRowProps) => {
  const { children, subtitle, testID, selected, screenStyles, onPress } = props;

  return (
    <ListRow
      rowStyles={[styles.rowStyles, screenStyles]}
      subtitle1={subtitle}
      rightStyle={styles.rightStyle}
      leftStyle={styles.leftStyle}
      testID={testID}
      onPress={onPress}
      accessory={<CheckBox selected={selected} testID={testID ? `${testID}.CHECKBOX` : testID} />}
    >
      {children}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyles: appSelect({
    enterprise: {
      height: 44,
      paddingHorizontal: SPACING.default,
    },
    go: {
      paddingVertical: SPACING.default,
      marginHorizontal: SPACING.default,
    },
  }),
  leftStyle: appSelect({
    enterprise: {},
    go: {
      width: "77%",
    },
  }),
  rightStyle: appSelect({
    enterprise: {},
    go: {
      width: "15%",
    },
  }),
});

export default CheckBoxRow;
