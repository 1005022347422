import { createSelector } from "reselect";

import { getAllSensorEvents } from "~/selectors/getters";
import {
  DOOR_RELATED_EVENT_TYPES,
  WATER_RELATED_EVENT_TYPES,
} from "~/shared/constants/SensorEventTypes";
import { RootState } from "~/shared/types/Redux";

const noEvents = {};
export const getSensorEventsForSerial = createSelector(
  getAllSensorEvents,
  (_: RootState, serial: string) => serial,
  (sensorEvents, serial) => {
    return sensorEvents?.[serial] ?? noEvents;
  },
);

const noHistoricalEvents: any = [];
export const getHistoricalEventsForSerial = createSelector(
  getSensorEventsForSerial,
  (sensorEvents) => {
    return sensorEvents?.historical ?? noHistoricalEvents;
  },
);

export const getWaterRelatedEvents = createSelector(
  getHistoricalEventsForSerial,
  (sensorEvents) => {
    return sensorEvents.filter(({ type }: any) => WATER_RELATED_EVENT_TYPES.includes(type));
  },
);

export const getLastWaterDetectedAt = createSelector(getSensorEventsForSerial, (sensorEvents) => {
  return sensorEvents.lastWaterDetectedAt;
});

export const getDoorRelatedEvents = createSelector(getHistoricalEventsForSerial, (sensorEvents) => {
  return sensorEvents.filter(({ type }: any) => DOOR_RELATED_EVENT_TYPES.includes(type));
});

export const getIsFetchingDoorRelatedEvents = createSelector(
  getSensorEventsForSerial,
  (sensorEvents) => {
    return sensorEvents?.isFetchingDoorRelatedEvents;
  },
);
