import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { StyleSheet } from "react-native";

import { useUpdateSwitchPorts } from "~/api/queries/switches/useSwitchPorts";
import { SwitchPort } from "~/api/schemas/SwitchPort";
import { SPACING } from "~/constants/MkiConstants";
import SummaryHeader from "~/enterprise/components/SummaryHeader";
import FormTextInput from "~/enterprise/components/textInputs/FormTextInput";
import { showSaveWarning } from "~/lib/AlertUtils";
import { STP_GUARD_OPTIONS } from "~/lib/SwitchPortUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiSpinner from "~/shared/components/MkiSpinner";
import PortVlanConfig from "~/shared/components/ports/PortVlanConfig";
import TagsInputCard from "~/shared/components/TagsInputCard";
import useEditSwitchPort from "~/shared/hooks/switch/useEditSwitchPort";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";

interface SwitchPortEditScreenProps {
  serialNumber: string;
  switchPort: SwitchPort;
}

const SwitchPortEditScreen = ({ serialNumber, switchPort }: SwitchPortEditScreenProps) => {
  const { portId, linkNegotiationCapabilities } = switchPort;

  const [stagedSwitchPort, _, updateStagedSwitchPortByKey, hasDiff] = useEditSwitchPort(switchPort);

  const { mutate, isLoading } = useUpdateSwitchPorts(serialNumber, [portId]);

  const {
    linkNegotiation,
    name,
    enabled,
    poeEnabled,
    tags,
    rstpEnabled,
    isolationEnabled,
    stpGuard,
  } = stagedSwitchPort;

  const navigation = useNavigation();
  useLayoutEffect(() => {
    const saveCallback = () => {
      mutate(stagedSwitchPort, {
        onSuccess: () => {
          navigation.goBack();
        },
      });
    };

    navigation.setOptions({
      headerTitle: I18n.t("SWITCH_PORT_EDIT.EDIT_PORT_NUMBER", { portNumber: portId }),
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (hasDiff) {
              showSaveWarning(saveCallback, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={saveCallback} disabled={!hasDiff} />,
    });
  }, [navigation, portId, hasDiff, mutate, stagedSwitchPort]);

  const selectLinkSpeed = () => {
    navigation.navigate("Selection", {
      title: I18n.t("LINK_NEGOTIATION_SELECTION.TITLE"),
      description: I18n.t("LINK_NEGOTIATION_SELECTION.DESCRIPTION"),
      initialValue: linkNegotiation,
      onSelect: updateStagedSwitchPortByKey("linkNegotiation"),
      options: linkNegotiationCapabilities.map((option) => ({ label: option, value: option })),
    });
  };

  const selectSTPGuard = () => {
    navigation.navigate("Selection", {
      title: I18n.t("STP_GUARD_SELECTION.TITLE"),
      description: I18n.t("STP_GUARD_SELECTION.DESCRIPTION"),
      initialValue: stpGuard,
      onSelect: updateStagedSwitchPortByKey("stpGuard"),
      options: STP_GUARD_OPTIONS,
    });
  };

  if (isLoading) {
    return <MkiSpinner fillHeight />;
  }

  return (
    <FullScreenContainerView withScroll defaultPadding screenStyles={styles.container}>
      <SummaryHeader title={I18n.t("SWITCH_PORT_EDIT.BASIC")} />
      <FormTextInput
        onChangeText={updateStagedSwitchPortByKey("name")}
        placeholder={I18n.t("SWITCH_PORT_EDIT.NAME.PLACEHOLDER")}
        title={I18n.t("SWITCH_PORT_EDIT.NAME.TITLE")}
        value={name ?? undefined}
      />
      <SwitchRow
        onValueChange={updateStagedSwitchPortByKey("enabled")}
        screenStyles={styles.switchRow}
        testID={"SWITCH_PORT_EDIT.ENABLED"}
        value={enabled}
      >
        {I18n.t("SWITCH_PORT_EDIT.ENABLED")}
      </SwitchRow>
      <SwitchRow
        onValueChange={updateStagedSwitchPortByKey("poeEnabled")}
        screenStyles={styles.switchRow}
        testID={"SWITCH_PORT_EDIT.POE"}
        value={poeEnabled}
      >
        {I18n.t("SWITCH_PORT_EDIT.POE")}
      </SwitchRow>
      <SimpleDisclosureRow
        subtitle={linkNegotiation}
        onPress={selectLinkSpeed}
        testID={"PORT_SETTINGS_LINK_SPEED_ROW"}
      >
        {I18n.t("SWITCH_PORT_EDIT.LINK_NEGOTIATION.TITLE")}
      </SimpleDisclosureRow>
      <MkiRowSeparator />

      <PortVlanConfig port={stagedSwitchPort} updatePortByKey={updateStagedSwitchPortByKey} />

      <SummaryHeader title={I18n.t("SWITCH_PORT_EDIT.ADDITIONAL_CONFIGURATIONS")} />
      <TagsInputCard
        onUpdate={updateStagedSwitchPortByKey("tags")}
        title={I18n.t("SWITCH_PORT_EDIT.TAGS.TITLE")}
        tags={tags}
        placeholder={I18n.t("SWITCH_PORT_EDIT.TAGS.PLACEHOLDER")}
      />
      <SwitchRow
        onValueChange={updateStagedSwitchPortByKey("isolationEnabled")}
        screenStyles={styles.switchRow}
        testID={"SWITCH_PORT_EDIT.ISOLATION"}
        value={isolationEnabled}
      >
        {I18n.t("SWITCH_PORT_EDIT.ISOLATION")}
      </SwitchRow>
      <SwitchRow
        onValueChange={updateStagedSwitchPortByKey("rstpEnabled")}
        screenStyles={styles.switchRow}
        testID={"SWITCH_PORT_EDIT.RSTP_GUARD"}
        value={rstpEnabled}
      >
        {I18n.t("SWITCH_PORT_EDIT.RSTP_GUARD")}
      </SwitchRow>
      <SimpleDisclosureRow
        onPress={selectSTPGuard}
        style={styles.disclosureRow}
        subtitle={
          STP_GUARD_OPTIONS.find((option) => option.value === stagedSwitchPort.stpGuard)?.label
        }
      >
        {I18n.t("SWITCH_PORT_EDIT.STP_GUARD")}
      </SimpleDisclosureRow>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: SPACING.default,
  },
  disclosureRow: {
    paddingLeft: 0,
  },
  switchRow: {
    paddingLeft: 0,
  },
});

export default SwitchPortEditScreen;
