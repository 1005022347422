import { get, sum } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import { UmbrellaScreensPropMap } from "~/go/navigation/Types";
import SecurityEventRow from "~/go/rows/SecurityEventRow";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import { clientName } from "~/lib/ClientUtils";
import { deviceName } from "~/lib/DeviceUtils";
import {
  clientsState,
  devicesState,
  getDeviceHashedIpMap,
  getEventCountsByIp,
  getNetworkTimezone,
  getSecurityEventsSections,
  getSecurityEventsSectionsForIp,
  getTotalBlockedRequests,
} from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiTable from "~/shared/components/MkiTable";
import { SettingsButton } from "~/shared/navigation/Buttons";
import { Client, ClientList } from "~/shared/types/Client";
import Device, { DeviceHashedIpMap, DeviceState } from "~/shared/types/Device";
import { RootState } from "~/shared/types/Redux";
import { SecurityEventListSection, UmbrellaActivity } from "~/shared/types/Umbrella";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  securityEventSections: SecurityEventListSection;
  securityEventSectionsForIp: SecurityEventListSection;
  timezone?: string;
  totalBlockedRequests: number;
  totalBlockedRequestsForIp: number;
  clients: ClientList;
  devices: DeviceState;
  deviceHashedIpMap: DeviceHashedIpMap;
};

type Props = ForwardedNativeStackScreenProps<UmbrellaScreensPropMap, "SecurityEventsList"> &
  ReduxProps &
  BasicActions &
  WithCancelablePromiseProps;

interface SecurityEventsListScreenState {
  isRequesting: boolean;
  isFirstRequest: boolean;
}

export class SecurityEventsListScreen extends PureComponent<Props, SecurityEventsListScreenState> {
  constructor(props: Props) {
    super(props);

    this.state = { isRequesting: false, isFirstRequest: true };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerRight: () => <SettingsButton onPress={this.showUmbrella} />,
    });
  }

  componentDidMount() {
    this.getData(true);
  }

  showUmbrella = () => {
    const { navigation } = this.props;

    navigation.navigate("UmbrellaSettings");
  };

  getData = async (resetActivity = false) => {
    const { actions, ipAddress, cancelablePromise } = this.props;
    const { isRequesting } = this.state;
    const { getUmbrellaActivity, getTotalRequestCount, getHighUsageIps, getActivitiesForIp } =
      actions;

    if (!isRequesting) {
      this.setState({ isRequesting: true });

      const now = Date.now();
      const reqs: any[] = [];
      if (ipAddress) {
        reqs.push(getActivitiesForIp(now, resetActivity, ipAddress));
      } else {
        reqs.push(getUmbrellaActivity(now, resetActivity));
      }

      if (resetActivity) {
        if (ipAddress) {
          reqs.push(getHighUsageIps(now));
        } else {
          reqs.push(getTotalRequestCount(now));
        }
      }

      try {
        await cancelablePromise(Promise.all(reqs));
        this.setState({ isRequesting: false, isFirstRequest: false });
      } catch (error) {
        showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        this.setState({ isRequesting: false });
      }
    }
  };

  onPressClientOrDevice = (
    activity: UmbrellaActivity,
    client: undefined | Client,
    device: undefined | Device,
  ) => {
    const { navigation } = this.props;

    navigation.navigate("SecurityEventDetails", {
      securityEvent: activity,
      client,
      device,
    });
  };

  renderSecurityEventRow = (activity: UmbrellaActivity) => {
    if (!activity.internalip) {
      return null;
    }
    const { internalip } = activity;
    const { timezone, clients, devices, deviceHashedIpMap } = this.props;
    const isDeviceEvent = deviceHashedIpMap[internalip] != null;
    const client = Object.values(clients).find((client) => client.hashedIp4 === internalip);

    let name: string | null;
    let device: undefined | Device = undefined;

    if (isDeviceEvent) {
      const deviceSerial = deviceHashedIpMap[internalip];
      device = devices[deviceSerial];
      name = deviceName(device);
    } else if (client) {
      name = clientName(client);
    } else {
      name = activity.internalip;
    }

    const onPress =
      device || client ? () => this.onPressClientOrDevice(activity, client, device) : () => {};

    return (
      <SecurityEventRow
        activity={activity}
        clientName={name}
        timezone={timezone}
        onPress={onPress}
      />
    );
  };

  renderFooter = () => {
    const { isRequesting } = this.state;
    return isRequesting ? (
      <View style={styles.footerLoadingSpinner}>
        <MkiSpinner />
      </View>
    ) : null;
  };

  onRefresh = () => this.getData(true);

  render() {
    const {
      ipAddress,
      securityEventSections,
      securityEventSectionsForIp,
      totalBlockedRequests,
      totalBlockedRequestsForIp,
    } = this.props;
    const { isFirstRequest } = this.state;
    const dataSections = ipAddress ? securityEventSectionsForIp : securityEventSections;
    const totalRequestsCount = ipAddress ? totalBlockedRequestsForIp : totalBlockedRequests;
    const data = isFirstRequest ? {} : dataSections;

    const dataSize = sum(Object.values(data).map((arr) => arr.length));
    const onEndReached = () => {
      if (dataSize < totalRequestsCount) {
        this.getData();
      }
    };
    const onEndReachedThreshold = dataSize / 2;

    return (
      <MkiTable<UmbrellaActivity>
        data={data}
        keyExtractor={(_: any, index: number) => `${index}`}
        renderRow={this.renderSecurityEventRow}
        onRefresh={this.onRefresh}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        ListFooterComponent={this.renderFooter}
      />
    );
  }
}

const styles = StyleSheet.create({
  footerLoadingSpinner: {
    flex: 1,
    paddingVertical: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: UmbrellaScreensPropMap["SecurityEventsList"],
): ReduxProps {
  return {
    securityEventSections: getSecurityEventsSections(state),
    securityEventSectionsForIp: getSecurityEventsSectionsForIp(state, {
      ipAddress: props.ipAddress,
    }),
    timezone: getNetworkTimezone(state),
    totalBlockedRequests: getTotalBlockedRequests(state),
    totalBlockedRequestsForIp: get(getEventCountsByIp(state)[props.ipAddress], "count", 0),
    clients: clientsState(state),
    devices: devicesState(state),
    deviceHashedIpMap: getDeviceHashedIpMap(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
)(SecurityEventsListScreen);
