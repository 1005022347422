import { CounterSets, RUsage } from "@meraki/shared/api";
import { processRusage, RUsageApplicationData } from "@meraki/shared/formatters";
import { get } from "lodash";
import { memo } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { TEXT_COLORS } from "~/enterprise/constants/Colors";
import I18n from "~/i18n/i18n";
import { appSelect } from "~/lib/PlatformUtils";
import { Theme } from "~/lib/themeHelper";
import { assignColors } from "~/lib/UsageUtils";
import MkiPieChart from "~/shared/components/MkiPieChart";
import MkiText from "~/shared/components/MkiText";
import getUsageColor from "~/shared/lib/UsagePieColorUtils";

type Props = {
  rusage?: RUsage;
  counterSets: CounterSets;
  csid?: 19 | 20 | 21;
  numSections?: number;
  chartStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
  testID?: string;
  theme?: Theme; // TODO: useThemeColors (optional for call sites)
};

const UsagePieChart = memo(function UsagePieChart(props: Props) {
  const {
    rusage,
    counterSets,
    numSections = 5,
    csid = 19,
    testID,
    onPress,
    chartStyle = {},
    theme,
  } = props;

  if (!rusage) {
    return null;
  }
  if (!rusage[csid] || !get(counterSets, [csid, "rules"])) {
    return (
      <MkiText
        textStyle="secondary"
        screenStyles={appSelect({
          go: {},
          enterprise: styles.enterpriseNoDataText,
        })}
      >
        {I18n.t("USAGE_PIE.UNAVAILABLE")}
      </MkiText>
    );
  }
  const usageSeries = assignColors(
    processRusage(rusage?.[19] ?? {}, counterSets?.[19]?.rules ?? {}, { numSections }),
  ) as RUsageApplicationData[];

  const usageColors = usageSeries.map((series: RUsageApplicationData) =>
    getUsageColor(series, theme),
  );

  return (
    <MkiPieChart<"label", "totalUsage">
      data={usageSeries}
      x="label"
      y="totalUsage"
      colorScale={usageColors}
      onPress={onPress}
      chartStyle={chartStyle}
      testID={testID}
    />
  );
});

const styles = StyleSheet.create({
  enterpriseNoDataText: {
    color: TEXT_COLORS.secondary,
  },
});

export default UsagePieChart;
