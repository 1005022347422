import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  size?: number;
};

function AirIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  const { stroke } = useThemeColors().icon;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M3.00049 7.99988H10.0005C10.5938 7.99988 11.1739 7.82393 11.6672 7.49429C12.1605 7.16464 12.5451 6.69611 12.7721 6.14793C12.9992 5.59975 13.0586 4.99655 12.9428 4.41461C12.8271 3.83267 12.5414 3.29812 12.1218 2.87856C11.7023 2.459 11.1677 2.17328 10.5858 2.05752C10.0038 1.94177 9.40062 2.00118 8.85244 2.22824C8.30426 2.4553 7.83572 2.83982 7.50608 3.33317C7.17644 3.82652 7.00049 4.40654 7.00049 4.99988M4.00049 15.9999H15.0005C15.5938 15.9999 16.1739 16.1758 16.6672 16.5055C17.1605 16.8351 17.5451 17.3037 17.7721 17.8518C17.9992 18.4 18.0586 19.0032 17.9428 19.5852C17.8271 20.1671 17.5414 20.7016 17.1218 21.1212C16.7023 21.5408 16.1677 21.8265 15.5858 21.9422C15.0038 22.058 14.4006 21.9986 13.8524 21.7715C13.3043 21.5445 12.8357 21.1599 12.5061 20.6666C12.1764 20.1732 12.0005 19.5932 12.0005 18.9999M2.00049 11.9999H19.0005C19.5938 11.9999 20.1739 11.8239 20.6672 11.4943C21.1605 11.1646 21.5451 10.6961 21.7721 10.1479C21.9992 9.59975 22.0586 8.99655 21.9428 8.41461C21.8271 7.83267 21.5414 7.29812 21.1218 6.87856C20.7023 6.459 20.1677 6.17328 19.5858 6.05752C19.0038 5.94177 18.4006 6.00118 17.8524 6.22824C17.3043 6.4553 16.8357 6.83982 16.5061 7.33317C16.1764 7.82652 16.0005 8.40654 16.0005 8.99988"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default AirIcon;
