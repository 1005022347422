import { Status } from "../../schemas";
import {
  GlobalOrgDeviceOverview,
  useGlobalOrgDeviceOverview,
} from "../administered/useGlobalOrgDeviceOverview";

// copied from libs/monolith/src/lib/OrgUtils.ts
const getStatus = (total: number, dormant: number, offline: number, alerting: number): Status => {
  if (total === 0 || total === dormant) {
    return "dormant";
  }

  if (offline + dormant === total) {
    return "offline";
  }

  if (offline - dormant > 0) {
    return "offline";
  }

  if (alerting > 0) {
    return "alerting";
  }

  return "online";
};

export function formatOrgDeviceOverview(
  data?: GlobalOrgDeviceOverview,
): Record<string, Status> | undefined {
  if (!data) {
    return undefined;
  }
  return data.items.reduce(
    (result, { counts, organization }) => {
      result[organization.id] = getStatus(
        counts.total,
        counts.statuses.dormant,
        counts.statuses.offline,
        counts.statuses.alerting,
      );
      return result;
    },
    {} as Record<string, Status>,
  );
}

export const useOrgStatuses = ({ enabled }: { enabled: boolean } = { enabled: true }) => {
  return useGlobalOrgDeviceOverview(
    {},
    {
      select: (data) => formatOrgDeviceOverview(data),
      enabled,
    },
  );
};
