export interface RadioSettingsOnBand {
  channel?: number | null;
  channelWidth?: number | null;
  targetPower?: number | null;
}

export enum RadioSettingsBands {
  twoFourGhzSettings = "twoFourGhzSettings",
  fiveGhzSettings = "fiveGhzSettings",
}

type baseRadioSettings = {
  rfProfileId?: number;
};

type RadioSettingsOnBands = {
  [key in RadioSettingsBands]: RadioSettingsOnBand;
};

export type RadioSettings = baseRadioSettings & RadioSettingsOnBands;

export interface RadioSettingsPowerLimits {
  max: number;
  min: number;
}
