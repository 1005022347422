import { z } from "zod";

import { request } from "../../../api/request/request";
import {
  BatchRequest,
  buildBatchMutationUrl,
  createActionBatchSchema,
} from "../../actionBatch/useActionBatch";
import { createMutation } from "../../createMutation";
import { AppliancePort, AppliancePortSchema } from "./useAppliancePorts";
import { createDisabledAppliancePort, createEditableAppliancePort } from "./useUpdateAppliancePort";

export const BatchUpdateAppliancePortsSchema = createActionBatchSchema(
  AppliancePortSchema.partial(),
);

type BatchUpdateAppliancePorts = z.infer<typeof BatchUpdateAppliancePortsSchema>;

interface AppliancePortsMutationRequest extends BatchRequest {
  networkId?: string;
  appliancePorts: AppliancePort[];
}

const mutateAppliancePorts = async ({
  organizationId,
  networkId,
  appliancePorts,
}: AppliancePortsMutationRequest) => {
  const actions = appliancePorts.map((port) => ({
    resource: `/networks/${networkId}/appliance/ports/${port.number}`,
    operation: "update",
    body: port.enabled ? createEditableAppliancePort(port) : createDisabledAppliancePort(port),
  }));

  const response = await request(
    BatchUpdateAppliancePortsSchema,
    "POST",
    buildBatchMutationUrl({ organizationId }),
    {
      body: JSON.stringify({
        confirmed: true,
        synchronous: true,
        actions,
      }),
    },
  );

  if (!response.status.completed) {
    throw Error(`${response.status.errors} when updating appliance ports`);
  }
  return response;
};

export const useUpdateAppliancePorts = createMutation<
  AppliancePortsMutationRequest,
  BatchUpdateAppliancePorts,
  Error
>({
  baseMutationKey: buildBatchMutationUrl({
    organizationId: "{organizationId}",
  }),
  mutationFn: (variables: AppliancePortsMutationRequest) => {
    return mutateAppliancePorts(variables);
  },
});
