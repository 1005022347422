import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId }: ClientCountHistoryRequest) => {
  return `/api/v1/networks/${networkId}/wireless/clientCountHistory`;
};

interface ClientCountHistoryRequest {
  networkId?: string;
  timespan?: number;
  ssid?: number;
  resolution?: number;
}

export const ClientCountHistorySchema = z.array(
  z.object({ startTs: z.string(), endTs: z.string(), clientCount: z.number() }),
);

export type ClientCountHistory = z.infer<typeof ClientCountHistorySchema>;

const fetchClientCountHistory = ({
  networkId,
  timespan,
  ssid,
  resolution,
}: ClientCountHistoryRequest) => {
  if (!networkId) {
    throw new Error("Undefined network id");
  }
  const queryParams = { timespan, ssid, resolution };
  return request(ClientCountHistorySchema, "GET", buildUrl({ networkId }), { queryParams });
};

export const useClientCountHistory = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: ClientCountHistoryRequest) => fetchClientCountHistory(parameters),
  requiredVariables: ["networkId"],
});
