import { I18n } from "@meraki/core/i18n";

import { PortTypes } from "~/constants/PortLayouts";

const GBPS = "Gbps";
export const MBPS = "Mbps";
const getAutoDuplex = () => I18n.t("LINK_SPEED.AUTO_DUPLEX");
const getFullDuplex = () => I18n.t("LINK_SPEED.FULL_DUPLEX");
const getHalfDuplex = () => I18n.t("LINK_SPEED.HALF_DUPLEX");
const getForced = () => I18n.t("LINK_SPEED.FORCED");
const rj45 = PortTypes.rj45.toLowerCase();
const sfp = PortTypes.sfp.toLowerCase();

export const LINK_SPEED_PORT_TYPES = [rj45, sfp];

const wrapInValueKeys = (speeds: any) =>
  speeds.reduce(
    (obj: any, speed: any) => ({
      ...obj,
      [speed.value]: speed,
    }),
    {},
  );

export const getGsSFPLinkSpeeds = () => [
  {
    label: I18n.t("LINK_SPEED.AUTO_NEGOTIATE"),
    value: "auto",
  },
  {
    label: `1 ${GBPS}, ${getForced()}`,
    value: "1Gfdx",
  },
];

const getGsEthernetLinkSpeeds = () => [
  {
    label: I18n.t("LINK_SPEED.AUTO_NEGOTIATE"),
    value: "auto",
  },
  {
    label: `1 ${GBPS}, ${getAutoDuplex()}`,
    value: "1Gfdx",
  },
  {
    label: `100 ${MBPS}, ${getAutoDuplex()}`,
    value: "100M-auto",
  },
  {
    label: `100 ${MBPS}, ${getFullDuplex()}`,
    value: "100Mfdx",
  },
  {
    label: `100 ${MBPS}, ${getHalfDuplex()}`,
    value: "100Mhdx",
  },
  {
    label: `10 ${MBPS}, ${getAutoDuplex()}`,
    value: "10M-auto",
  },
  {
    label: `10 ${MBPS}, ${getFullDuplex()}`,
    value: "10Mfdx",
  },
  {
    label: `10 ${MBPS}, ${getHalfDuplex()}`,
    value: "10Mhdx",
  },
];

const getGsLinkSpeeds = () => ({
  [rj45]: wrapInValueKeys(getGsEthernetLinkSpeeds()),
  [sfp]: wrapInValueKeys(getGsSFPLinkSpeeds()),
});

export default getGsLinkSpeeds;
