import { StyleSheet, View } from "react-native";

import PortTypes, { PortBlockData, PortStatus } from "~/constants/PortLayouts";
import { isWeb } from "~/lib/PlatformUtils";
import PortBlock from "~/shared/components/portDiagrams/PortBlock";
interface PortBlocksProps {
  portsStatuses: Record<string, PortStatus>;
  panelWidth: number;
  portBlockData: PortBlockData["blocks"];
  selectedPorts?: string[];
  hidePortStatusIcons?: boolean;
}

const BLOCK_SPACING = 5;

const PortBlocks = ({
  portBlockData,
  portsStatuses,
  panelWidth,
  selectedPorts,
  hidePortStatusIcons,
}: PortBlocksProps): JSX.Element => {
  let numProcessedPorts = 0;
  return (
    <View style={styles.container}>
      {portBlockData.map(({ type, num, rows }, idx) => {
        if (type === PortTypes.stacking) {
          // skip stacking ports for now
          return null;
        }

        // rj45, sfp, stacking ports (rear)
        // only add margin if more than one block, or last block excluding stacking ports
        const addMarginStart =
          portBlockData.length > 1 &&
          (portBlockData.length - 1 === idx || portBlockData[idx + 1]?.type === PortTypes.stacking);

        let marginStart = BLOCK_SPACING;
        let marginEnd = 0;

        if (isWeb() && portBlockData.length - 1 === idx) {
          marginEnd = (portBlockData[idx].num / portBlockData[idx].rows) * 10;
        }
        if (isWeb() && idx > 0) {
          marginStart = (portBlockData[idx - 1].num / portBlockData[idx - 1].rows) * 10;
        }

        if (addMarginStart) {
          marginStart += 5;
        }

        const block = (
          <PortBlock
            key={`port_block_${idx}`}
            offset={numProcessedPorts}
            numPorts={num}
            numRows={rows}
            portType={type}
            panelWidth={panelWidth}
            portsStatuses={portsStatuses}
            selectedPorts={selectedPorts}
            style={isWeb() ? { marginStart, marginEnd } : { marginStart }}
            hidePortStatusIcons={hidePortStatusIcons}
          />
        );
        numProcessedPorts += num;
        return block;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
});

export default PortBlocks;
