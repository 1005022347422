import { Box } from "@meraki/magnetic/layout";
import { Children, cloneElement, isValidElement, JSXElementConstructor, ReactElement } from "react";
import { ViewProps } from "react-native";

import { Card } from "../Card/Card";
import { Heading } from "../Heading/Heading";
import { Loader } from "../Loader/Loader";
import { FlashList } from "./FlashList/FlashList";
import { ListItem, ListItemProps } from "./ListItem";
import { RadioListItem } from "./RadioListItem";

export { FlashListProps } from "./FlashList/types";
export { ListItemProps } from "./ListItem";

type RootChildType = ReactElement<ListItemProps, string | JSXElementConstructor<ListItemProps>>;
type ListItemType = RootChildType | RootChildType[] | boolean;
export type ListProps = Omit<ViewProps, "style"> & {
  label?: string;
  loading?: boolean | { numberOfRows: number };
  children: ListItemType | ListItemType[];
};

export function List({ label, loading, children, ...rest }: ListProps) {
  if (loading) {
    const numLoadingRows = typeof loading === "object" ? loading.numberOfRows : 6;

    return (
      <Box gap="xs">
        {label && (
          <Box paddingLeft="sm">
            <Heading size="h4">{label}</Heading>
          </Box>
        )}
        <Card gap="xs">
          {new Array(numLoadingRows).fill(1).map((_, idx) => (
            <Loader.Skeleton key={idx} height="large" />
          ))}
        </Card>
      </Box>
    );
  }

  // We are forcing out everything except actual children that we need to render so we need to let TS know that
  // fact as it is unable to implicitly figure that out in this case
  const childrenArray = Children.toArray(children).filter(Boolean) as RootChildType[];
  const stylizedChildren = childrenArray.map((child, idx) => {
    if (__DEV__ && child.type !== ListItem && child.type !== RadioListItem) {
      throw new Error("You should only pass in ListItem components into a List");
    }

    const isLast = idx === childrenArray.length - 1;
    const isFirst = idx === 0;

    if (isFirst || isLast) {
      if (isValidElement(child)) {
        return cloneElement(child, { last: isLast, first: isFirst });
      }
    }

    return child;
  });

  return (
    <Box gap="xs" {...rest}>
      {!!label && (
        <Box paddingLeft="sm">
          <Heading size="h4">{label}</Heading>
        </Box>
      )}
      <Card padding="none">
        <Box>{stylizedChildren}</Box>
      </Card>
    </Box>
  );
}

List.FlashList = FlashList;
List.Item = ListItem;
List.RadioItem = RadioListItem;
