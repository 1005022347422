import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, DISABLED_OPACITY, SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

interface LiveToolsButtonProps {
  enabled?: boolean;
  label: string;
  name: string;
  testID: string;
  onPress: () => void;
}

const LiveToolsButton = (props: LiveToolsButtonProps) => {
  const { enabled = true, name, label, testID, onPress } = props;

  const enabledOnPress = enabled ? onPress : undefined;
  const enabledStyle = enabled ? {} : styles.disabled;

  return (
    <View style={[styles.liveTools, styles.center, enabledStyle]}>
      <View style={[styles.liveToolsIcon, styles.center]}>
        <MerakiIcon
          testID={testID}
          name={name}
          size="m"
          color={MkiColors.whiteText}
          onPress={enabledOnPress}
        />
      </View>
      <MkiText textStyle="smallSecondary" screenStyles={styles.liveToolsButtonText}>
        {label}
      </MkiText>
    </View>
  );
};

const styles = StyleSheet.create({
  center: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  liveTools: {
    flex: 1,
    marginTop: SPACING.default,
  },
  liveToolsButtonText: {
    textAlign: "center",
  },
  liveToolsIcon: {
    width: 40,
    height: 40,
    borderRadius: BUTTON_SIZING.borderRadius.large,
    backgroundColor: MkiColors.primaryButton,
    marginBottom: SPACING.small,
  },
  disabled: {
    opacity: DISABLED_OPACITY,
  },
});

export default LiveToolsButton;
