import { StatusType } from "~/shared/constants/Status";

import { SwitchRowProps } from "../rows/SwitchRow";

export interface Alert {
  message: string;
  status: StatusType;
  primaryButtonText?: string;
  primaryButtonPress?: (...args: any) => any;
}

export interface TrackedClientList {
  mac: string;
  name: string | undefined;
}

export enum UsageAlertID {
  usageAlert = "usageAlert",
}

export enum DownAlertID {
  applianceDown = "applianceDown",
  switchDown = "switchDown",
  gatewayDown = "gatewayDown",
  cameraDown = "cameraDown",
  repeaterDown = "repeaterDown",
}

export enum GuestInsightsAlertID {
  weeklyPresence = "weeklyPresence",
}

export type AlertID = UsageAlertID | DownAlertID | GuestInsightsAlertID;

export interface AlertSetting {
  alertDestinations?: {
    allAdmins: boolean;
    emails: string[];
    snmp: boolean;
    mobileAdminIds?: string[];
  };
  enabled?: boolean;
  filters?: {
    timeout?: number;
    period?: number;
    threshold?: number;
    clients?: TrackedClientList[];
  };
  type: string;
}

export type AlertsSettings = {
  [ID in AlertID]: AlertSetting;
};

export enum GoAlertTypes {
  downEmail = "downEmail",
  downPush = "downPush",
  usage = "usage",
  guestInsightsEmail = "guestInsightsEmail",
  guestInsightsPush = "guestInsightsPush",
  weeklyUmbrellaEmail = "weeklyUmbrellaEmail",
  weeklyUmbrellaPush = "weeklyUmbrellaPush",
  clientConnectivityEmail = "clientConnectivityEmail",
  clientConnectivityPush = "clientConnectivityPush",
}

export enum DestinationTypes {
  email = "emails",
  push = "mobileAdminIds",
}

export interface AlertRowData {
  alertId?: AlertID;
  label: string;
  switches: SwitchRowProps[];
}

export type AlertSettingCategories =
  | "Network Wide"
  | "Switch"
  | "Sensor"
  | "Camera"
  | "Wireless"
  | "Security Appliance";
