import { StyleProp, StyleSheet, TextStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ExpandableContainer, {
  ExpandableContainerProps,
} from "~/shared/components/ExpandableContainer";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import ListRow from "~/shared/rows/ListRow";

export interface DropDownRowProps extends ExpandableContainerProps {
  title?: string;
  subtitle?: string;
  titleTextStyle?: StyleProp<TextStyle>;
  icon?: React.ReactElement;
  useDefaultTextStyle?: boolean;
  openInitially?: boolean;
}

class DropDownRow extends ExpandableContainer<DropDownRowProps> {
  static defaultProps = {
    title: undefined,
    testID: undefined,
    useDefaultTextStyle: false,
    openInitially: false,
  };

  constructor(props: DropDownRowProps) {
    super(props);

    this.state = { isExpanded: !!props.openInitially };
  }

  renderMain() {
    const { title, subtitle, titleTextStyle, testID, useDefaultTextStyle, icon } = this.props;
    const { isExpanded } = this.state;

    const chevron = (
      <MerakiIcon
        color={MkiColors.primaryButton}
        name={isExpanded ? "expand-less" : "expand-more"}
        size="s"
        containerStyle={styles.chevron}
      />
    );

    const subheadingDropDown = (
      <ListRow accessory={chevron} onPress={this.onHeaderPress} testID={`${testID}.LIST_ROW`}>
        {icon}
        <MkiText
          textStyle={useDefaultTextStyle ? undefined : "subheading"}
          screenStyles={useDefaultTextStyle ? [titleTextStyle] : [styles.header, titleTextStyle]}
        >
          {title}
        </MkiText>
        {subtitle && <MkiText screenStyles={[styles.subtitle, titleTextStyle]}>{subtitle}</MkiText>}
      </ListRow>
    );

    return subheadingDropDown;
  }
}

const styles = StyleSheet.create({
  chevron: {
    marginRight: SPACING.default + SPACING.tiny,
  },
  header: {
    marginLeft: SPACING.default,
    color: MkiColors.primaryButton,
  },
  subtitle: {
    marginLeft: SPACING.medium,
    color: MkiColors.secondaryButton,
  },
  container: {
    marginBottom: 0,
  },
});

export default DropDownRow;
