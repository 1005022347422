import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { findSliderIndexForKB, getGBForSliderIndex } from "@meraki/go/alert";
import { Badge, Button, List } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  NetworkEventProductType,
  useClients,
  useNetworkEvent,
  useOrgNetwork,
} from "@meraki/shared/api";
import { checkHasAlertNotificationPermissions } from "@meraki/shared/permissions";
import { LineProductIcon, LineProductIconProps } from "@meraki/shared/product-icons";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { compareDesc } from "date-fns";
import { useEffect } from "react";

type FormattedEvent = {
  description: string;
  rawTime: string;
  labelTime: string;
  itemLabelTime: string;
  networkName: string;
  icon: JSX.Element;
  onPress?: () => void;
  testID?: string;
};

export const AlertsScreen = () => {
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();

  const timespan = useGlobalTimespan();

  const { data: network } = useOrgNetwork({ organizationId, networkId });

  const { data: networkEventsData, isLoading: isLoadingEvents } = useNetworkEvent({ networkId });

  const { data: clients } = useClients({ networkId, timespan });

  const notificationsEnabled = async () =>
    (await checkHasAlertNotificationPermissions()) === "granted";

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Icon
          icon="Gear"
          onPress={() =>
            navigation.navigate("AlertsSettings", { notificationsEnabled: notificationsEnabled() })
          }
        />
      ),
    });
  });

  const formattedEvents: FormattedEvent[] = [];

  networkEventsData?.forEach((eventAlertPerNetwork) => {
    const { alertTypeId } = eventAlertPerNetwork;
    const testID = alertTypeId.toUpperCase();
    const eventOccuredAt = eventAlertPerNetwork.occurredAt;
    const networkName = network?.name ?? "";

    const itemLabelTime = formatDate(eventOccuredAt, {
      dateFormat: "shortDate",
      timeFormat: "shortTime",
    });
    const rawTime = formatDate(eventOccuredAt, {
      dateFormat: "date",
      timeFormat: "longTime24HourForce",
    });
    const labelTime = formatDate(eventOccuredAt, { dateFormat: "longDate" });

    if (alertTypeId === "client_connectivity") {
      const currentClient = clients?.find((client) => {
        return client?.mac.toUpperCase() === eventAlertPerNetwork.alertData?.mac;
      });
      const status =
        eventAlertPerNetwork.alertData && eventAlertPerNetwork.alertData.connected === "true"
          ? "positive"
          : "negative";

      const clientName =
        currentClient?.description ||
        eventAlertPerNetwork.alertData?.mac?.toString() ||
        I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.NOT_FOUND");

      formattedEvents.push({
        description:
          eventAlertPerNetwork.alertData && eventAlertPerNetwork.alertData.connected === "true"
            ? I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.CONNECTED", { clientName })
            : I18n.t("ALERTS_CENTER.CLIENT_CONNECTIVITY.DISCONNECTED", { clientName }),
        rawTime,
        itemLabelTime,
        labelTime,
        networkName,
        testID,
        icon: (
          <Box padding="xs">
            <Badge.Status badgeVisible status={status} size={20}>
              <LineProductIcon name={"client"} />
            </Badge.Status>
          </Box>
        ),
        onPress: () =>
          navigation.navigate("ClientDetails", {
            id: currentClient?.id,
          }),
      });
    }

    if (alertTypeId === "started_reporting" || alertTypeId === "stopped_reporting") {
      const hasStartedReporting = alertTypeId === "started_reporting";
      const deviceName = eventAlertPerNetwork?.device.name ?? "";
      const description = hasStartedReporting
        ? I18n.t("ALERTS_CENTER.STARTED_REPORTING", { deviceName })
        : I18n.t("ALERTS_CENTER.STOPPED_REPORTING", { deviceName });
      const status = hasStartedReporting ? "positive" : "negative";

      const productType = (product?: NetworkEventProductType): LineProductIconProps["name"] => {
        switch (product) {
          case "wireless":
            return "accessPoint";
          case "appliance":
          case "switch":
            return product;
          default:
            return "system";
        }
      };
      formattedEvents.push({
        description,
        rawTime,
        itemLabelTime,
        labelTime,
        networkName,
        testID,
        icon: (
          <Box padding="xs">
            <Badge.Status badgeVisible status={status} size={20}>
              <LineProductIcon name={productType(eventAlertPerNetwork.device.productType)} />
            </Badge.Status>
          </Box>
        ),
        onPress: () =>
          navigation.navigate("HardwareDetails", {
            serialNumber: eventAlertPerNetwork?.device?.serial ?? "",
          }),
      });
    }

    if (alertTypeId === "weekly_presence") {
      const changeInNumber = Number(eventAlertPerNetwork?.alertData?.change);
      const description =
        changeInNumber >= 0
          ? I18n.t("ALERTS_CENTER.WEEKLY_PRESENCE.MORE", {
              numOfVisitors: Math.abs(changeInNumber) ?? 0,
            })
          : I18n.t("ALERTS_CENTER.WEEKLY_PRESENCE.LESS", {
              numOfVisitors: Math.abs(changeInNumber) ?? 0,
            });

      formattedEvents.push({
        description,
        rawTime,
        itemLabelTime,
        labelTime,
        networkName,
        testID,
        icon: <Button.Icon icon="UsersThree" />,
        onPress: () => navigation.navigate("LocationAnalytics"),
      });
    }

    if (alertTypeId === "weekly_umbrella") {
      formattedEvents.push({
        description: I18n.t("ALERTS_CENTER.WEEKLY_UMBRELLA", {
          numOfCategories: Number(eventAlertPerNetwork.alertData?.length) ?? 0,
        }),
        rawTime,
        itemLabelTime,
        labelTime,
        networkName,
        testID,
        icon: <Button.Icon icon="Umbrella" />,
      });
    }

    if (alertTypeId === "usage_alert") {
      const sliderIndex = findSliderIndexForKB(
        Number(eventAlertPerNetwork.alertData?.usageThreshold),
      );
      formattedEvents.push({
        description: I18n.t("ALERTS_CENTER.USAGE_ALERT", {
          usage: getGBForSliderIndex(sliderIndex),
        }),
        rawTime,
        itemLabelTime,
        labelTime,
        networkName,
        testID,
        icon: <Button.Icon icon="Gauge" />,
        onPress: () =>
          navigation.navigate("ApplicationUsageList", { ssidNumber: -1, ssidName: "" }),
      });
    }
  });

  return (
    <Screen.View testID="ALERTS_SCREEN">
      <List.FlashList
        data={formattedEvents}
        emptyState={{
          title: I18n.t("ALERTS_CENTER.EMPTY.NETWORK"),
        }}
        getItemData={(event) => {
          return {
            title: event.description,
            description: `${event.networkName} | ${event.itemLabelTime}`,
            leftAccessory: event.icon,
            onPress: event.onPress,
            testID: event.testID,
          };
        }}
        groupBy={(event) => event.labelTime}
        sortGroupBy={(event1, event2) => {
          return compareDesc(
            new Date(event1.data[0]?.rawTime ?? ""),
            new Date(event2.data[0]?.rawTime ?? ""),
          );
        }}
        loading={isLoadingEvents}
        testID="ALERTS_LIST"
      />
    </Screen.View>
  );
};

export default AlertsScreen;
