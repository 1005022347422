export const MAX_ADDRESS_PART_LENGTH = 3;
export const MAX_SUBNET_MASK_PART_LENGTH = 2;
export const IPV4_BIT_SIZE = 32;
export const IP_ADDRESS_PART_COUNT = 4;
export const IP_ADDRESS_WITH_SUBNET_MAX_PART_COUNT = 5;
export const IP_ADDRESS_PART_SIZE = 8;
export const IP_ADDRESS_PART_MAX = 255;
export const IP_ADDRESSES_USABLE = 65280;
export const SUBNET_MASK_INDEX = 4;
export const SUBNET_REGEX =
  /^([01]?\d\d?|2[0-4]\d|25[0-5])(?:\.(?:[01]?\d\d?|2[0-4]\d|25[0-5])){3}(?:\/[0-2]\d|\/3[0-2])?$/;
export const DEFAULT_SUBNET_MASK_INT = 256;
export const ALLOWED_SUBNET_MASK_MIN = 16;
export const ALLOWED_SUBNET_MASK_MAX = 30;
