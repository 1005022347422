import {
  TouchableHighlight,
  TouchableHighlightProps,
  TouchableNativeFeedback,
  TouchableNativeFeedbackProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
} from "react-native";

import { platformSelect } from "~/lib/PlatformUtils";

type Props = TouchableWithoutFeedbackProps &
  TouchableNativeFeedbackProps & {
    children?: React.ReactElement;
    withoutFeedback?: boolean;
    transparentBackground?: boolean;
    underlayColor?: TouchableHighlightProps["underlayColor"];
    testID?: string;
  };

const Touchable = (props: Props) => {
  const { transparentBackground = false, withoutFeedback = false } = props;
  const platformTouchable = platformSelect({
    ios: transparentBackground ? (
      <TouchableOpacity {...props} />
    ) : (
      <TouchableHighlight {...props} />
    ),
    android: withoutFeedback ? (
      <TouchableWithoutFeedback useForeground {...props} />
    ) : (
      <TouchableNativeFeedback useForeground {...props} />
    ),
    web: <TouchableOpacity {...props} />,
  });

  return platformTouchable;
};

export default Touchable;
