import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "DeviceMACAddress">;

export const DeviceMACScreen = ({ onPressSave }: Props) => {
  const [macAddress, setMacAddress] = useState("");
  const [reqPending, setReqPending] = useState(false);

  const navigation = useNavigation();

  const onSave = useCallback(() => {
    setReqPending(true);

    try {
      onPressSave(macAddress);
      navigation.goBack();
    } catch (error) {
      showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
    } finally {
      setReqPending(false);
    }
  }, [macAddress, navigation, onPressSave]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} />,
    });
  }, [navigation, onSave]);

  return (
    <FullScreenContainerView withScroll defaultPadding>
      <MkiText textStyle="smallSecondary" screenStyles={styles.inputTitle}>
        {I18n.t("DEVICE_RESERVATIONS.DEVICE_MAC_ADDRESS")}
      </MkiText>
      <UnderlinedTextInput
        name="macAddress"
        placeholder={I18n.t("DEVICE_RESERVATIONS.MAC_PLACEHOLDER")}
        value={macAddress}
        onChangeText={setMacAddress}
        returnKeyType={RETURN_KEY.done}
        editable
        showClearButton
      />
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  inputTitle: {
    marginTop: SPACING.large,
  },
});

export default DeviceMACScreen;
