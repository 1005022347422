import {
  DOCUMENTATION_SEARCH_REQUEST,
  DOCUMENTATION_SEARCH_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { DocumentationData } from "~/shared/types/Models";

interface DocumentationReduxState {
  "@ranking": string;
  "@queryid": string;
  "@sessionid": string;
  result: DocumentationData[];
}

const initialState = {
  "@ranking": "",
  "@queryid": "",
  "@sessionid": "",
  result: [],
};

export default function documentation(state: DocumentationReduxState = initialState, action: any) {
  const response = action.response;
  switch (action.type) {
    case DOCUMENTATION_SEARCH_SUCCESS:
      return {
        ...state,
        ...{
          ...response,
          result:
            Array.isArray(response.result) || response.result === undefined
              ? response.result
              : [response.result],
        },
      };
    case DOCUMENTATION_SEARCH_REQUEST:
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
