import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { isEmpty } from "lodash";
import { StyleSheet } from "react-native";

import SecurityEventRow from "~/go/rows/SecurityEventRow";
import { clientName } from "~/lib/ClientUtils";
import SummaryCard from "~/shared/components/SummaryCard";
import TopItemsList from "~/shared/components/TopItemsList";
import { Client } from "~/shared/types/Client";
import { UmbrellaActivity } from "~/shared/types/Umbrella";

interface TopSecurityEventsListProps {
  securityEvents: UmbrellaActivity[];
  timezone: string;
  client: Client;
}

const MAX_ITEMS = 3;

const TopSecurityEventsList = ({
  securityEvents,
  timezone,
  client,
}: TopSecurityEventsListProps) => {
  const navigation = useNavigation();

  if (isEmpty(securityEvents)) {
    return null;
  }

  const pushSecurityEventsScreen = () => {
    navigation.navigate("SecurityEventsList", {
      ipAddress: client.hashedIp4,
    });
  };

  const renderSecurityEventRow = (activity: UmbrellaActivity) => {
    const onPress = () => {
      navigation.navigate("SecurityEventDetails", {
        securityEvent: activity,
        client,
      });
    };

    return (
      <SecurityEventRow
        clientName={clientName(client)}
        activity={activity}
        onPress={onPress}
        timezone={timezone}
      />
    );
  };

  return (
    <SummaryCard
      heading={I18n.t("TOP_SECURITY_EVENTS_LIST.HEADING")}
      subheading={I18n.t("TOP_SECURITY_EVENTS_LIST.SUBHEADING", {
        client_word: I18n.t("DEVICE_WORD"),
      })}
      contentContainerStyles={styles.devicesCard}
    >
      <TopItemsList
        customRenderRow={renderSecurityEventRow}
        items={securityEvents}
        maxItemsToDisplay={MAX_ITEMS}
        onPressSeeAll={pushSecurityEventsScreen}
      />
    </SummaryCard>
  );
};

const styles = StyleSheet.create({
  devicesCard: {
    borderBottomWidth: 0,
    marginHorizontal: 0,
  },
});

export default TopSecurityEventsList;
