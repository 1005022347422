import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface NetworkWirelessSettingsRequest {
  networkId?: string;
}
interface UpdateSettingRequest extends NetworkWirelessSettingsRequest {
  isEnabled: boolean;
}

interface UpdateSettingParams extends UpdateSettingRequest {
  settingType: SettingType;
}

enum SettingType {
  meshing = "meshingEnabled",
  stealthMode = "ledLightsOn",
  ipv6Bridging = "ipv6BridgeEnabled",
}

interface WirelessSettingsError {
  errors: string[];
}

const DHCPPoolMonitoring = z.object({
  enabled: z.boolean(),
  duration: z.number(),
});

const UpgradeStrategyTypes = z.union([
  z.literal("minimizeClientDowntime"),
  z.literal("minimizeUpgradeTime"),
]);

export const NetworkWirelessSettingsSchema = z
  .object({
    meshingEnabled: z.boolean(),
    ipv6BridgeEnabled: z.boolean(),
    locationAnalyticsEnabled: z.boolean(),
    upgradeStrategy: z.optional(UpgradeStrategyTypes),
    ledLightsOn: z.boolean(),
    namedVlans: z
      .object({
        poolDhcpMonitoring: DHCPPoolMonitoring,
      })
      .optional(),
    regulatoryDomain: z.object({
      name: z.string(),
      permits6e: z.boolean(),
    }),
  })
  .describe("NetworkWirelessSettingsSchema");

export type NetworkWirelessSettings = z.infer<typeof NetworkWirelessSettingsSchema>;

const buildUrl = ({ networkId }: NetworkWirelessSettingsRequest) => {
  return `/api/v1/networks/${networkId}/wireless/settings`;
};

const fetchWirelessSettings = ({ networkId }: NetworkWirelessSettingsRequest) => {
  return request(NetworkWirelessSettingsSchema, "GET", buildUrl({ networkId }));
};

const mutateWirelessSetting = ({ networkId, isEnabled, settingType }: UpdateSettingParams) => {
  if (settingType === SettingType.stealthMode) {
    isEnabled = !isEnabled;
  }
  const body = { [settingType]: JSON.stringify(isEnabled) };
  return request(NetworkWirelessSettingsSchema, "PUT", buildUrl({ networkId }), {
    body: JSON.stringify(body),
  });
};

export const useSetStealthMode = createMutation<
  UpdateSettingRequest,
  NetworkWirelessSettings,
  WirelessSettingsError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (parameters: UpdateSettingRequest) =>
    mutateWirelessSetting({ ...parameters, settingType: SettingType.stealthMode }),
});

export const useSetMeshing = createMutation<
  UpdateSettingRequest,
  NetworkWirelessSettings,
  WirelessSettingsError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (parameters: UpdateSettingRequest) =>
    mutateWirelessSetting({ ...parameters, settingType: SettingType.meshing }),
});

export const useSetIPv6Bridging = createMutation<
  UpdateSettingRequest,
  NetworkWirelessSettings,
  WirelessSettingsError
>({
  baseMutationKey: buildUrl({ networkId: "{networkId}" }),
  mutationFn: (parameters: UpdateSettingRequest) =>
    mutateWirelessSetting({ ...parameters, settingType: SettingType.ipv6Bridging }),
});

export const useGetWirelessSettings = createQuery<
  NetworkWirelessSettingsRequest,
  NetworkWirelessSettings
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (parameters: NetworkWirelessSettingsRequest) => fetchWirelessSettings(parameters),
  requiredVariables: ["networkId"],
});
