import {
  GET_INVENTORY_DEVICE_FAILURE,
  GET_INVENTORY_DEVICE_REQUEST,
  GET_INVENTORY_DEVICE_SUCCESS,
  GET_UNUSED_INVENTORY_FAILURE,
  GET_UNUSED_INVENTORY_REQUEST,
  GET_UNUSED_INVENTORY_SUCCESS,
  SEARCH_INVENTORY_FAILURE,
  SEARCH_INVENTORY_REQUEST,
  SEARCH_INVENTORY_SUCCESS,
} from "@meraki/shared/redux";

import { Device as PublicApiDevice } from "~/api/schemas/Device";
import { DEFAULT_PAGE_SIZE } from "~/constants/MkiConstants";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { getCurrentOrganization } from "~/selectors";
import { UsedState } from "~/shared/types/Inventory";
import { ApiAction, AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function getUnusedInventory(listId: string, perPage: number = DEFAULT_PAGE_SIZE) {
  const queryParams = {
    usedState: UsedState.unused,
    perPage,
  };

  return inventoryDevices({
    types: [
      GET_UNUSED_INVENTORY_REQUEST,
      GET_UNUSED_INVENTORY_SUCCESS,
      GET_UNUSED_INVENTORY_FAILURE,
    ],
    config: {
      method: Method.get,
      queryParams,
    },
    meta: {
      listId,
    },
  });
}

export function searchUnusedInventory(
  listId: string,
  search: string,
  perPage: number = DEFAULT_PAGE_SIZE,
) {
  const queryParams = {
    usedState: UsedState.unused,
    search,
    perPage,
  };

  return inventoryDevices({
    types: [SEARCH_INVENTORY_REQUEST, SEARCH_INVENTORY_SUCCESS, SEARCH_INVENTORY_FAILURE],
    config: {
      method: Method.get,
      queryParams,
    },
    meta: {
      listId,
    },
  });
}

function inventoryDevices(
  action: Omit<ApiAction[typeof CALL_API], "endpoint">,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const state = getState();
    const organizationId = getCurrentOrganization(state);

    return dispatch({
      [CALL_API]: {
        endpoint: `/api/v1/organizations/${organizationId}/inventoryDevices`,
        ...action,
      },
    });
  };
}

export function getInventoryDevice(
  serial: string,
): AppThunk<Promise<ApiResponseAction<PublicApiDevice>>> {
  return (dispatch, getState) => {
    const state = getState();
    const organizationId = getCurrentOrganization(state);

    return dispatch({
      [CALL_API]: {
        types: [
          GET_INVENTORY_DEVICE_REQUEST,
          GET_INVENTORY_DEVICE_SUCCESS,
          GET_INVENTORY_DEVICE_FAILURE,
        ],
        endpoint: `/api/v1/organizations/${organizationId}/inventoryDevices/${serial}`,
        config: {
          method: Method.get,
        },
      },
    });
  };
}
