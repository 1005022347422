import { ClientType, PoliciesByClient } from "@meraki/shared/api";
import { createContext, useContext } from "react";

export type blockSheetActions = "open" | "close";

type ClientDetailsContextValue = {
  client?: ClientType;
  clientPolicies?: PoliciesByClient;
  goToTab?: (key: string) => void;
  openBlockBottomSheet: () => void;
  openLimitBottomSheet: () => void;
};

export const ClientDetailsContext = createContext<ClientDetailsContextValue>({
  goToTab: () => undefined,
  openBlockBottomSheet: () => undefined,
  openLimitBottomSheet: () => undefined,
});

export function useClientDetails() {
  return useContext(ClientDetailsContext);
}
