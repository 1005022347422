import { I18n } from "@meraki/core/i18n";
import { Button, Numeral, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useLocalStatus } from "@meraki/shared/api";
import { Gauge } from "@meraki/shared/components";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { MS_IN_A_SECOND, useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useState } from "react";

import { useAPBanner } from "../store/useAPBanner";
import { useApPlacementRooms } from "../store/useApPlacementRooms";

const MAX_POINTS = 1000;
const REFRESH_TIME = MS_IN_A_SECOND * 1;

export const ApTestScreen = () => {
  const networkId = useCurrentNetworkId();
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();
  const route = useRoute<RouteProp<AccessPointPlacementGroupProps, "APTest">>();
  const { room } = route.params;
  const { removeAllUntestedAps } = useAPBanner();

  const [points, setPoints] = useState<number[]>([]);
  const [isRunning, setIsRunning] = useState(false);
  const { roomsByNetwork, setRoomsByNetwork } = useApPlacementRooms();
  const { data: rssi } = useLocalStatus(
    {},
    {
      select: (data) => +data.client.rssi,
      refetchInterval: () => REFRESH_TIME,
    },
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button.Nav
          text={I18n.t("CANCEL")}
          onPress={navigation.goBack}
          analytics={{
            event: "onPress",
            eventName: "ap_test_cancel",
            params: {
              is_running: isRunning,
            },
          }}
        />
      ),
      headerTitle: room,
    });
  }, [isRunning, navigation, room]);

  useEffect(() => {
    if (isRunning && rssi) {
      setPoints((points) => {
        if (points.length > MAX_POINTS) {
          points.shift();
        }
        return [...points, rssi];
      });
    }
  }, [isRunning, rssi]);

  const onFinishTesting = useCallback(() => {
    const average = points.length > 0 ? points.reduce((a, b) => a + b) / points.length : 0;
    setRoomsByNetwork(
      {
        ...(roomsByNetwork[networkId ?? ""] ?? {}),
        [room]: Math.round(average * 100) / 100,
      },
      networkId,
    );
    navigation.pop();
    removeAllUntestedAps();
    navigation.navigate("PlacementScore", { room });
  }, [
    navigation,
    networkId,
    points,
    removeAllUntestedAps,
    room,
    roomsByNetwork,
    setRoomsByNetwork,
  ]);

  const displayValue = isRunning ? rssi ?? 1 : 0;

  return (
    <Screen addDefaultPadding>
      <Box flex={1} flexDirection="column" alignContent="center">
        <Text size="p1">{I18n.t("ACCESS_POINT.AP_TEST.INFO", { room })}</Text>
        <Gauge
          size="lg"
          detailFormatter={() => ""}
          data={[{ value: displayValue, label: "dB" }]}
          splitNumber={5}
          min={0}
          max={50}
        />

        <Box flex={1} alignItems="center">
          <Numeral size="n1" value={displayValue} units="dB" />
        </Box>
      </Box>
      <Box flex={1} paddingTop="xl">
        <Button
          analytics={{
            event: "onPress",
            eventName: isRunning ? "ap_test_start" : "ap_test_finish",
          }}
          kind="primary"
          text={
            !isRunning
              ? I18n.t("ACCESS_POINT.AP_TEST.START")
              : I18n.t("ACCESS_POINT.AP_TEST.FINISH")
          }
          onPress={!isRunning ? () => setIsRunning(true) : onFinishTesting}
        />
      </Box>
    </Screen>
  );
};
