import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type RuntimeSchemaValidation = {
  enabled: boolean;
  flagEnabled: boolean;
  toggle: () => void;
  setFlag: (flagEnabled: boolean) => void;
};

export const useRuntimeSchemaValidation = create<RuntimeSchemaValidation>()(
  persist(
    (set) => ({
      enabled: false,
      flagEnabled: false,
      toggle: () => set((state) => ({ enabled: !state.enabled })),
      setFlag: (flagEnabled: boolean) => set(() => ({ flagEnabled })),
    }),
    {
      name: "runtime-schema-validation",
      storage: createJSONStorage(() => asyncStorageAdapter),
      partialize: (state) => ({ enabled: state.enabled }),
      version: 3,
      migrate(persistedState, version) {
        const updatedStore = persistedState as RuntimeSchemaValidation;

        if (version < 3) {
          // If we are before version 3 we wanted to reset the default value to false.
          return {
            ...updatedStore,
            enabled: false,
          };
        }

        return updatedStore;
      },
    },
  ),
);
