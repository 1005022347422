import { Device, SwitchPort } from "@meraki/shared/api";
import { createContext, useContext } from "react";

type SwitchPortDetailsContextValue = {
  device?: Device;
  switchPort?: SwitchPort;
  goToTab?: (key: string) => void;
  openAddTagBottomSheet: () => void;
  openDeletTagBottomSheet: () => void;
};

export const SwitchPortDetailsContext = createContext<SwitchPortDetailsContextValue>({
  goToTab: () => undefined,
  openAddTagBottomSheet: () => undefined,
  openDeletTagBottomSheet: () => undefined,
});

export function useSwitchPortDetails() {
  return useContext(SwitchPortDetailsContext);
}
