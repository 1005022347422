import { forwardRef, Ref, useCallback, useState } from "react";
import { NativeSyntheticEvent, TextInput, TextInputFocusEventData } from "react-native";

import { useSafeBottomSheetInternal } from "../util/useSafeBottomSheetInternal";
import { SearchBarInternal, SearchBarInternalProps } from "./SearchBarInternal";

export type SearchBarProps = Omit<SearchBarInternalProps, "focused">;

function SearchBarForward({ onFocus, onBlur, ...rest }: SearchBarProps, ref: Ref<TextInput>) {
  const [focused, setFocused] = useState(false);

  const bottomSheet = useSafeBottomSheetInternal(true);

  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(true);
      if (bottomSheet) {
        bottomSheet.shouldHandleKeyboardEvents.value = true;
      }
      onFocus && onFocus(e);
    },
    [onFocus, setFocused, bottomSheet],
  );

  const handleBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setFocused(false);
      if (bottomSheet) {
        bottomSheet.shouldHandleKeyboardEvents.value = false;
      }
      onBlur && onBlur(e);
    },
    [onBlur, setFocused, bottomSheet],
  );

  return (
    <SearchBarInternal
      {...rest}
      focused={focused}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      accessibilityRole="search"
    />
  );
}

export const SearchBar = forwardRef(SearchBarForward);
