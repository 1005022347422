import { NetworkNodeContainer } from "~/shared/types/Node";

export enum WirelessHealthPath {
  connectionStats = "connectionStats",
  latencyStats = "latencyStats",
  failedConnections = "failedConnections",
  channelUtilization = "channelUtilization",
}

export enum WirelessHealthContainerKeys {
  clients = "clients",
  nodes = "nodes",
  summary = "summary",
  raw = "raw",
}

export type ConnectionStats = {
  assoc: number;
  auth: number;
  dhcp: number;
  dns: number;
  success: number;
};

export interface ChannelUtilizationDataPoint {
  start_ts: string;
  end_ts: string;
  non_wifi: number;
  wifi: number;
  utilization: number;
}
export interface ChannelUtilizationStats {
  mac: string;
  serial: string;
  wifi0: ChannelUtilizationDataPoint[];
  wifi1: ChannelUtilizationDataPoint[];
}

export interface RawDistribution {
  [latencyBin: string]: number;
}

export interface Traffic {
  rawDistribution: RawDistribution;
  avg: number;
}

export interface LatencyStats {
  [trafficType: string]: Traffic;
}

export interface FailedConnection {
  ssidNumber: number;
  vlan: number;
  clientMac: string;
  serial: string;
  failureStep: string;
  type: string;
  ts: string;
}

export interface DisplayableFailedConnection extends FailedConnection {
  apName: string;
  apMac: string;
  clientName: string;
  ssidName: string;
}

export interface WirelessHealthReduxState {
  connectionStats: NetworkNodeContainer<ConnectionStats>;
  latencyStats: NetworkNodeContainer<LatencyStats>;
  failedConnections: NetworkNodeContainer<FailedConnection>;
  channelUtilization: NetworkNodeContainer<ChannelUtilizationStats>;
}
