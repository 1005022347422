import { I18n } from "@meraki/core/i18n";
import { DeviceIllustration } from "@meraki/go/device-illustration";
import { BottomSheetMethods, Button, Card, Loader } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useDeviceStatuses } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useRef } from "react";
import { ScrollView } from "react-native";

import { AppliancePortOverviewCard } from "../../components/AppliancePortOverviewCard";
import { ConnectivityCard } from "../../components/ConnectivityCard";
import { DeviceDetailsList } from "../../components/DeviceDetailsList";
import { BlinkLedBottomSheet } from "../../components/liveTools/BlinkLedBottomSheet";
import { PokeDeviceBottomSheet } from "../../components/liveTools/PokeDeviceBottomSheet";
import { RebootBottomSheet } from "../../components/liveTools/RebootBottomSheet";
import { TestConnectionBottomSheet } from "../../components/liveTools/TestConnectionBottomSheet";
import { SwitchPortOverviewCard } from "../../components/SwitchPortOverviewCard";
import { useDeviceDetails } from "../DeviceDetailsContext";

export const DetailsTab = () => {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const { device: deviceDetails } = useDeviceDetails();
  const { data: deviceStatus } = useDeviceStatuses(
    { organizationId, networkIds: networkId ? [networkId] : undefined },
    {
      select(data) {
        return data.find((device) => device.serial === deviceDetails?.serial);
      },
    },
  );

  const rebootBottomSheetRef = useRef<BottomSheetMethods>(null);
  const blinkLedBottomSheetRef = useRef<BottomSheetMethods>(null);
  const testConnectionBottomSheetRef = useRef<BottomSheetMethods>(null);
  const pokeDeviceBottomSheetRef = useRef<BottomSheetMethods>(null);

  if (!deviceDetails || !deviceStatus) {
    return (
      <Screen testID="DETAILS_TAB">
        <Loader.Spinner testID="DETAILS_LOADING_SPINNER" />
      </Screen>
    );
  }

  return (
    <Screen addDefaultPadding testID="DETAILS_TAB">
      <Box paddingBottom="sm" gap="sm">
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <Button
            kind="secondary"
            text={I18n.t("DEVICE_DETAILS_TAB.TEST_CONNECTION")}
            onPress={() => {
              testConnectionBottomSheetRef.current?.present();
            }}
          />
          <Box marginLeft="xs">
            <Button
              kind="secondary"
              text={I18n.t("REBOOT")}
              onPress={() => rebootBottomSheetRef.current?.present()}
            />
          </Box>
          <Box marginLeft="xs">
            <Button
              kind="secondary"
              text={I18n.t("LIVE_TOOLS.BLINK_LED")}
              onPress={() => blinkLedBottomSheetRef.current?.present()}
            />
          </Box>

          <Box marginLeft="xs">
            <Button
              kind="secondary"
              text={I18n.t("LIVE_TOOLS.POKE_DEVICE")}
              onPress={() => pokeDeviceBottomSheetRef.current?.present()}
            />
          </Box>
        </ScrollView>
        <ConnectivityCard deviceStatus={deviceStatus} deviceId={deviceDetails.id} />
        {deviceDetails.productType === "appliance" && (
          <AppliancePortOverviewCard device={deviceDetails} />
        )}
        {deviceDetails.productType === "switch" && (
          <SwitchPortOverviewCard device={deviceDetails} />
        )}
        <Card testID="PRODUCT_ICON">
          <Card.Well alignItems="center">
            <DeviceIllustration model={deviceDetails.model} size={"large"} />
          </Card.Well>
        </Card>
        <TestConnectionBottomSheet deviceId={deviceDetails.id} ref={testConnectionBottomSheetRef} />
        <DeviceDetailsList device={deviceDetails} deviceStatus={deviceStatus} />
      </Box>
      <RebootBottomSheet
        ref={rebootBottomSheetRef}
        serial={deviceDetails.serial}
        model={deviceDetails.model}
      />
      <BlinkLedBottomSheet
        serial={deviceDetails.serial}
        model={deviceDetails.model}
        ref={blinkLedBottomSheetRef}
      />
      <PokeDeviceBottomSheet
        model={deviceDetails.model}
        deviceId={deviceDetails.id}
        ref={pokeDeviceBottomSheetRef}
      />
    </Screen>
  );
};
