import { Device } from "@meraki/shared/api";
import { createContext, useContext } from "react";

import { CombinedAppliancePort } from "../../utils/PortUtils";

type AppliancePortDetailsContextValue = {
  device?: Device;
  appliancePort?: CombinedAppliancePort;
  goToTab?: (key: string) => void;
};

export const AppliancePortDetailsContext = createContext<AppliancePortDetailsContextValue>({
  goToTab: () => undefined,
});

export function useAppliancePortDetails() {
  return useContext(AppliancePortDetailsContext);
}
