import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import { Heading, List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";

import { ThemePreview } from "../components/ThemePreview";

export function ThemeSelectScreen() {
  const { theme, setTheme } = useTheme();

  return (
    <Screen addDefaultPadding>
      <Heading>{I18n.t("THEME_SELECT.HEADER.TITLE")}</Heading>
      <List>
        <List.RadioItem
          title={I18n.t("THEME_SELECT.LIGHT_THEME.LABEL")}
          radioValue={"light"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_LIGHT_THEME"
        />
        <List.RadioItem
          title={I18n.t("THEME_SELECT.DARK_THEME.LABEL")}
          radioValue={"dark"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_DARK_THEME"
        />
        <List.RadioItem
          title={I18n.t("THEME_SELECT.LIGHT_DEUTERANOMALY_THEME.LABEL")}
          radioValue={"lightDeuteranomaly"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_LIGHT_DEUT_THEME"
        />
        <List.RadioItem
          title={I18n.t("THEME_SELECT.DARK_DEUTERANOMALY_THEME.LABEL")}
          radioValue={"darkDeuteranomaly"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_DARK_DEUT_THEME"
        />
        <List.RadioItem
          title={I18n.t("THEME_SELECT.AMOLED_THEME.LABEL")}
          description={I18n.t("THEME_SELECT.AMOLED_THEME.DESCRIPTION")}
          radioValue={"amoled"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_AMOLED_THEME"
        />
        <List.RadioItem
          title={I18n.t("THEME_SELECT.SYSTEM_THEME.LABEL")}
          radioValue={"system"}
          checkedRadioValue={theme}
          onRadioValueChange={setTheme}
          testID="RADIO_SYSTEM_THEME"
        />
      </List>
      <ThemePreview />
    </Screen>
  );
}
