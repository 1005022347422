import { I18n } from "@meraki/core/i18n";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import { List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useOrgNetworks } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

export const NetworkSelectionScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<AlertsStackProps>>();
  const organizationId = useCurrentOrganizationId();

  const { data: networks, isLoading } = useOrgNetworks({ organizationId });

  return (
    <Screen.View>
      <List.FlashList
        data={networks}
        getItemData={(network) => {
          return {
            title: network.name,
            onPress: () => navigation.navigate("AlertsSettings", { networkId: network.id }),
          };
        }}
        label={I18n.t("ALERT_SETTINGS.SELECT_NETWORK")}
        loading={isLoading}
        emptyState={{
          title: I18n.t("ALERT_SETTINGS.EMPTY"),
        }}
      />
    </Screen.View>
  );
};
