import { I18n } from "@meraki/core/i18n";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import ConnectAndUpgradeBody from "~/go/components/onboarding/ConnectAndUpgradeBody";
import RoundedButton from "~/go/components/RoundedButton";
import { OnboardingStackProps } from "~/go/navigation/Types";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withOnboardingStatus from "~/hocs/OnboardingData";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig, getPluginHardwareMap } from "~/lib/OnboardingFullstackUtils";
import { hasConfiguredWiFiSelector, onboardingDevices } from "~/selectors";
import { ExitButton } from "~/shared/navigation/Buttons";
import { OnboardingScannedDevices, OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  hasConfiguredWiFi: boolean;
  scannedHardwareMap: OnboardingScannedDevices;
};

type Props = ForwardedNativeStackScreenProps<OnboardingStackProps, "ConnectAndUpgrade"> &
  ReduxProps &
  BasicActions &
  BaseOnboardingScreenProps;

export class ConnectAndUpgradeScreen extends BaseOnboardingScreen<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <ExitButton onPress={this.onClosePress} />,
    });
  }

  componentDidDisappear() {
    const { actions } = this.props;
    actions.setOnboardingStage(OnboardingStage.getStarted);
  }

  onClosePress = () => {
    const { hasConfiguredWiFi } = this.props;
    if (hasConfiguredWiFi || !this.hasScannedWifi()) {
      this.close();
    } else {
      showAlert(
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.TITLE"),
        I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.MESSAGE"),
        () => this.pushOnboardingScreen(OnboardingStage.wifiSetup),
        {
          negativeText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.EXIT_SETUP"),
          onNegativePress: this.close,
          positiveText: I18n.t("ONBOARDING_FULLSTACK.EXIT_ALERTS.NO_WIFI.CANCEL_BUTTON"),
        },
      );
    }
  };

  onLEDLegendPress = () => {
    const { navigation } = this.props;

    navigation.navigate("LEDColorLegend", {
      onboarding: true,
    });
  };

  renderBody = () => <ConnectAndUpgradeBody onLEDLegendPress={this.onLEDLegendPress} />;

  getFooterData = () => ({
    buttons: [
      <RoundedButton key="nextButton" onPress={() => this.onPrimaryPress()}>
        {this.nextStageConfig.nextButtonText}
      </RoundedButton>,
    ],
  });

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.connectUpgrade, {
    hasScannedWifi: this.hasScannedWifi(),
  });
}

function mapStateToProps(state: RootState): ReduxProps {
  return {
    hasConfiguredWiFi: hasConfiguredWiFiSelector(state),
    scannedHardwareMap: getPluginHardwareMap(onboardingDevices(state)),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withOnboardingStatus,
)(ConnectAndUpgradeScreen);
