import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { useVlans, VlanExport } from "@meraki/shared/api";
import { calculateSubnetRange, getNextValidIPv4Subnet } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { SPACING } from "~/constants/MkiConstants";
import ErrorMessageBanner from "~/go/components/networkTemplate/ErrorMessageBanner";
import NetworkCard from "~/go/components/networkTemplate/NetworkCard";
import TextInputBox from "~/go/components/networkTemplate/TextInputBox";
import { ExportType } from "~/lib/ExportTemplateUtils";
import { importFormatVlanId, StagedVlanMap, StagedVlanObject } from "~/lib/ImportTemplateUtils";
import { getAllSiteToSiteSettings } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useThemeColors } from "~/shared/hooks/useTheme";

const ID_ERRORS = ["duplicate_id", "default_id"];

interface VlanNetworkCardProps {
  vlan: VlanExport;
  existingVlanSubnetRanges: number[][];
  stagedValueMap: StagedVlanMap;
  stagedVlanUpdater: (value: StagedVlanObject | undefined) => void;
  isSelected: boolean;
  tapHandler: (type: ExportType, id: string) => void;
  getLocalVlanErrorState: (
    currentVlanId: number,
    vlanId: string,
    vlanIpAddress: string,
    forceClearVlanError: boolean,
  ) => string[];
}

const VlanNetworkCard = ({
  vlan,
  existingVlanSubnetRanges,
  stagedValueMap,
  stagedVlanUpdater,
  isSelected,
  tapHandler,
  getLocalVlanErrorState,
}: VlanNetworkCardProps) => {
  const colors = useThemeColors();
  const networkId = useCurrentNetworkId();
  const existingVlans = useVlans({ networkId }).data;
  const existingVlanIds = existingVlans?.map((existingVlan) => existingVlan.id);
  const orgSiteToSiteVPNSettings = errorMonitor.notifyNonOptional(
    "param 'orgSiteToSiteVPNSettings' undefined for ConfigureVlanScreen",
    useAppSelector(getAllSiteToSiteSettings),
  );

  const [droppedDown, setDroppedDown] = useState<boolean>(false);
  const [localErrors, setLocalErrors] = useState<string[]>([]);
  const [vlanId, setVlanId] = useState<string>(vlan.id.toString());
  const [vlanIpAddress, setVlanIpAddress] = useState<string>(`${vlan.subnet}`);

  // ensures that error states are uppdated on value changes
  useEffect(() => {
    if (isSelected) {
      const localErrors = getLocalVlanErrorState(vlan.id, vlanId, vlanIpAddress, false);
      setLocalErrors(localErrors);
    } else {
      getLocalVlanErrorState(vlan.id, vlanId, vlanIpAddress, true);
    }
  }, [getLocalVlanErrorState, isSelected, vlan.id, vlanId, vlanIpAddress]);

  const onPress = () => {
    tapHandler(ExportType.vlan, importFormatVlanId(vlan));
    setDroppedDown(!droppedDown);
  };

  const onChangeId = (newValue: string) => {
    stagedVlanUpdater({ ...stagedValueMap[vlan.id], id: parseInt(newValue) });
    setVlanId(newValue);
  };
  const onChangeIpAddress = (newValue: string) => {
    stagedVlanUpdater({
      ...stagedValueMap[vlan.id],
      subnet: newValue,
      subnetRange: calculateSubnetRange(newValue) ?? [],
    });
    setVlanIpAddress(newValue);
  };

  const suggestIp = () => {
    const importedSubnets: number[][] = [];
    Object.keys(stagedValueMap).forEach((key) => {
      if (key !== vlanId) {
        importedSubnets.push(stagedValueMap[key].subnetRange);
      }
    });
    const combinedSubnets = existingVlanSubnetRanges.concat(importedSubnets);
    const { subnet } = getNextValidIPv4Subnet(orgSiteToSiteVPNSettings, combinedSubnets);
    onChangeIpAddress(subnet);
  };

  const suggestId = () => {
    let newId = 1;
    const importedIds: number[] = [];
    Object.keys(stagedValueMap).forEach((key) => {
      if (key !== vlanId) {
        importedIds.push(stagedValueMap[key].id);
      }
    });
    const combinedIds = importedIds.concat(existingVlanIds ?? []);
    while (combinedIds.includes(newId)) {
      newId++;
    }
    onChangeId(newId.toString());
  };

  const suggestionPress = () => {
    if (localErrors.includes("ip_overlap")) {
      suggestIp();
    } else if (localErrors.some((error) => ID_ERRORS.includes(error))) {
      suggestId();
    }
  };

  const suggestionText = () => {
    if (localErrors.includes("ip_overlap")) {
      return I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.VLAN_SUGGEST_IP");
    } else if (localErrors.some((error) => ID_ERRORS.includes(error))) {
      return I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.CARD.VLAN_SUGGEST_ID");
    }

    return "";
  };
  const onDropDownPress = () => {
    setDroppedDown(!droppedDown);
  };

  // TODO: UDG-3699 - Should be revised once component library is ready
  return (
    <NetworkCard
      name={vlan.name}
      type="vlan"
      isSelected={isSelected}
      dropDown={droppedDown}
      onRowPress={onPress}
      onDropDownPress={onDropDownPress}
      testID={`VLAN_NETWORK_CARD.${vlan.id}`}
    >
      {
        <View testID={`VLAN_NETWORK_CARD.DROP_DOWN.${vlan.id}`}>
          <View style={styles.inputComponentRow}>
            <TextInputBox
              header={"Vlan ID"}
              value={vlanId.toString()}
              borderColor={
                localErrors.includes("default_id")
                  ? colors.iconBanner?.negative.color
                  : localErrors.includes("duplicate_id")
                    ? colors.iconBanner?.warning.color
                    : undefined
              }
              maxMinWidth={60}
              onChangeText={onChangeId}
            />
            <TextInputBox
              header={"IP Address"}
              value={vlanIpAddress}
              borderColor={
                localErrors.includes("ip_overlap") ? colors.iconBanner?.negative.color : undefined
              }
              maxMinWidth={200}
              onChangeText={onChangeIpAddress}
            />
            {localErrors.length > 0 && (
              <TouchableOpacity
                testID={`SUGGEST_BUTTON_${localErrors.includes("ip_overlap") ? "IP" : "ID"}`}
                onPress={suggestionPress}
              >
                <MkiText
                  textStyle="defaultBold"
                  screenStyles={{
                    backgroundColor: colors.navigation.backgroundPrimary,
                    color: colors.navigation.primary,
                  }}
                >
                  {suggestionText()}
                </MkiText>
              </TouchableOpacity>
            )}
          </View>
          <ErrorMessageBanner errorArr={localErrors} />
        </View>
      }
    </NetworkCard>
  );
};

const styles = StyleSheet.create({
  inputComponentRow: {
    flexDirection: "row",
    gap: SPACING.medium,
    alignItems: "center",
    paddingBottom: SPACING.meager,
  },
});

export default VlanNetworkCard;
