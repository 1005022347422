import { License } from "@meraki/shared/api";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MerakiIcon from "~/shared/components/icons";
import { LicenseStatuses } from "~/shared/types/License";

interface LicenseIconProps {
  license?: License | null;
  serial?: string;
  screenStyles?: ViewStyle;
}

function LicenseIcon({ license, serial, screenStyles }: LicenseIconProps) {
  let iconColor = MkiColors.offlineStatus;

  if (license != null && (serial == null || license.deviceSerial === serial)) {
    switch (license.state) {
      case LicenseStatuses.active:
        if (license.headLicenseId == null) {
          iconColor = MkiColors.goodBar;
        } else {
          iconColor = MkiColors.primaryButtonLight;
        }
        break;
      case LicenseStatuses.expiring:
      case LicenseStatuses.unusedActive:
        iconColor =
          license.durationInDays != null && license.durationInDays > 30
            ? MkiColors.warningStatus
            : MkiColors.badStatus;
        break;
      case LicenseStatuses.recentlyQueued:
        iconColor = MkiColors.primaryButtonLight;
        break;
    }
  }

  const iconStyles: StyleProp<ViewStyle>[] = [styles.base];
  if (screenStyles != null) {
    iconStyles.push(screenStyles);
  }

  return (
    <MerakiIcon
      name="license"
      size="s"
      color={iconColor}
      containerStyle={iconStyles}
      disableHeightWidth
      testID="LicenseIcon"
    />
  );
}

const styles = StyleSheet.create({
  base: {
    justifyContent: "center",
    paddingHorizontal: SPACING.large,
  },
});

export default LicenseIcon;
