import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import {
  getLimitIndex,
  getUsageLimitPolicyName,
  LimitUsageCard,
  LimitUsageCardForm,
  TRAFFIC_SHAPING_LIMIT_VALUES,
} from "@meraki/go/traffic-shaping";
import { Button, Checkbox, Heading, Loader, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  APIResponseError,
  IdentityPskInput,
  queryClient,
  useCreateGroupPolicy,
  useCreateIdentiyPsk,
  useDeleteIdentityPsk,
  useGroupPolicies,
  useIdentityPsks,
  useUpdateIdentityPsk,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { formatTransferBits, kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Pressable } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";

interface IpskForm extends LimitUsageCardForm {
  name: string;
  passphrase: string;
  expiresAt?: Date | null;
}

export function UniquePasswordScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "UniquePassword">>();
  const { params: props } = route;
  const { ssidNumber, ipsk } = props;

  const networkId = useCurrentNetworkId();
  const { data: groupPolicies } = useGroupPolicies({ networkId });

  const { mutate: createIpsK, isLoading: isCreating } = useCreateIdentiyPsk();
  const { mutate: updateIpsk, isLoading: isUpdating } = useUpdateIdentityPsk();
  const { mutate: deleteIpsk, isLoading: isDeleting } = useDeleteIdentityPsk();
  const { mutateAsync: createGroupPolicy, isLoading: isCreatingGroupPolicy } =
    useCreateGroupPolicy();

  const [expireChecked, setExpireChecked] = useState(ipsk?.expiresAt != null);
  const [revealPassphrase, setRevealPassphrase] = useState(false);
  const [showExpiresModal, setShowExpiresModal] = useState(false);

  const methods = useForm<IpskForm>({
    defaultValues: {
      name: ipsk?.name ?? "",
      passphrase: ipsk?.passphrase ?? "",
      expiresAt: ipsk?.expiresAt ? new Date(ipsk.expiresAt) : null,
    },
  });

  const responseHandler = useMemo(
    () => ({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: useIdentityPsks.queryKey({ networkId, ssidNumber: ssidNumber?.toString() }),
        });
        navigation.goBack();
      },
      onError: (error: APIResponseError) => showErrorAlert(String(error["errors"])),
    }),
    [navigation, networkId, ssidNumber],
  );

  const onDelete = () => {
    if (ssidNumber != null && ipsk != null) {
      deleteIpsk(
        {
          networkId,
          ssidNumber: ssidNumber.toString(),
          id: ipsk.id,
        },
        responseHandler,
      );
    }
  };

  const onSave = useCallback(
    async ({ name, passphrase, expiresAt }: IpskForm) => {
      if (ssidNumber != null) {
        const newIpsk: IdentityPskInput = {
          name,
          passphrase,
          expiresAt: expireChecked ? expiresAt?.toISOString() : null,
        };

        try {
          const groupPolicy = groupPolicies?.find(
            (policy) => policy.groupPolicyId === ipsk?.groupPolicyId,
          );
          const limitIndex = getLimitIndex(groupPolicy?.bandwidth?.bandwidthLimits.limitDown) ?? 0;

          if (limitIndex != null) {
            const limitInMB = TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex];
            const limitInMiB = kilobitsToMebibitsInt(limitInMB);
            if (limitInMB == null || limitInMiB == null) {
              return;
            }

            const existingGroupPolicy = groupPolicies?.find(
              (policy) => policy.bandwidth?.bandwidthLimits.limitDown === limitInMiB,
            );

            let newGroupPolicyId = existingGroupPolicy?.groupPolicyId;
            if (!existingGroupPolicy) {
              await createGroupPolicy(
                {
                  networkId: networkId,
                  name: getUsageLimitPolicyName(formatTransferBits(limitInMB)),
                  bandwidth: {
                    settings: "custom",
                    bandwidthLimits: {
                      limitUp: limitInMiB,
                      limitDown: limitInMiB,
                    },
                  },
                },
                {
                  onSuccess: (data) => {
                    if (data.groupPolicyId != null) {
                      newGroupPolicyId = data.groupPolicyId;
                    }
                  },
                  onError: (error) => showErrorAlert(String(error["errors"])),
                },
              );
            }

            if (newGroupPolicyId == null) {
              return;
            }

            newIpsk.groupPolicyId = newGroupPolicyId;
          }
        } catch (error) {
          // let onError handle error
        } finally {
          if (ipsk == null) {
            createIpsK(
              {
                networkId,
                ssidNumber: ssidNumber.toString(),
                body: newIpsk,
              },
              responseHandler,
            );
          } else {
            updateIpsk(
              {
                networkId,
                ssidNumber: ssidNumber.toString(),
                id: ipsk.id,
                body: newIpsk,
              },
              responseHandler,
            );
          }
        }
      }
    },
    [
      createGroupPolicy,
      createIpsK,
      expireChecked,
      groupPolicies,
      ipsk,
      networkId,
      responseHandler,
      ssidNumber,
      updateIpsk,
    ],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSave)}
          disabled={!methods.formState.isDirty}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, navigation, onSave]);

  const colorTokenVariable = expireChecked ? "base" : "disabled";
  const expiresAt = methods.watch("expiresAt");

  return (
    <Screen addDefaultPadding>
      {(isCreating || isDeleting || isCreatingGroupPolicy || isUpdating) && (
        <Loader.Spinner animate />
      )}
      <Form {...methods}>
        <Form.Input
          name="name"
          label={I18n.t("CONFIGURE_IPSK.NAME")}
          additionalContext={I18n.t("CONFIGURE_IPSK.NAME_DESCRIPTION")}
          rules={{ required: I18n.t("CONFIGURE_IPSK.NAME_REQUIRED") }}
          testID="UNIQUE_PASSWORD.NAME"
        />
        <Form.Input
          name="passphrase"
          label={I18n.t("CONFIGURE_IPSK.PASSWORD")}
          additionalContext={I18n.t("CONFIGURE_IPSK.PASSWORD_DESCRIPTION")}
          rules={{
            required: I18n.t("CONFIGURE_IPSK.PASSWORD_REQUIRED"),
            validate: (newPassphrase) =>
              newPassphrase.length < 8 ? I18n.t("CONFIGURE_IPSK.PASSWORD_INVALID") : undefined,
          }}
          showClear={false}
          secureTextEntry={!revealPassphrase}
          rightAccessory={
            <Button.Icon
              icon={revealPassphrase ? "EyeClosed" : "Eye"}
              onPress={() => setRevealPassphrase(!revealPassphrase)}
              testID="UNIQUE_PASSWORD.PASSPHRASE_REVEAL"
            />
          }
          testID="UNIQUE_PASSWORD.PASSPHRASE"
        />
        <LimitUsageCard title={I18n.t("CONFIGURE_IPSK.LIMITATIONS")} />
        <Box paddingHorizontal="xs">
          <Text color="light">{I18n.t("CONFIGURE_IPSK.LIMITATIONS_SUBHEADER")}</Text>
        </Box>
        <Box gap="sm">
          <Heading>{I18n.t("CONFIGURE_IPSK.EXPIRE_TITLE")}</Heading>
          <Box flexDirection="row" alignItems="flex-start" gap="xs">
            <Checkbox
              checked={expireChecked}
              onValueChange={setExpireChecked}
              testID="UNIQUE_PASSWORD.USE_EXPIRATION"
            />
            <Box width="90%">
              <Text>{I18n.t("CONFIGURE_IPSK.EXPIRE_SWITCH_SUBHEADER")}</Text>
            </Box>
          </Box>
          <Pressable
            onPress={() => expireChecked && setShowExpiresModal(true)}
            testID="UNIQUE_PASSWORD.EXPIRATION"
          >
            <Box
              padding="sm"
              marginHorizontal="2xs"
              backgroundColor={`control.bg.weak.${colorTokenVariable}`}
              borderColor={`control.border.${colorTokenVariable}`}
              borderRadius="sm"
              borderWidth="strong"
              flexDirection="row"
            >
              <Box flex={1}>
                <Text color={`default.text.${colorTokenVariable}`}>
                  {expiresAt
                    ? formatDate(expiresAt, {
                        dateFormat: "date",
                        timeFormat: "shortTime",
                        timeZoneFormat: "show",
                      })
                    : I18n.t("CONFIGURE_IPSK.DATE_PLACEHOLDER")}
                </Text>
              </Box>
              <Icon name="Clock" color={`default.text.${colorTokenVariable}`} size={18} />
            </Box>
          </Pressable>
        </Box>
        {ipsk != null && (
          <Box marginTop="lg" gap="sm">
            <Box paddingHorizontal="xs">
              <Text color="light">{I18n.t("CONFIGURE_IPSK.DELETE_SUBHEADER")}</Text>
            </Box>
            <Button
              text={I18n.t("DELETE")}
              kind="primaryDestructive"
              onPress={() => onDelete()}
              testID="UNIQUE_PASSWORD.DELETE"
            />
          </Box>
        )}
        <DateTimePickerModal
          mode={"datetime"}
          date={methods.watch("expiresAt") ?? undefined}
          onConfirm={(date) => {
            date.setMinutes(0);
            setShowExpiresModal(false);
            methods.setValue("expiresAt", date);
          }}
          minimumDate={new Date()}
          minuteInterval={30}
          isVisible={showExpiresModal}
          onHide={() => setShowExpiresModal(false)}
          onCancel={() => setShowExpiresModal(false)}
          modalPropsIOS={{ supportedOrientations: ["portrait", "landscape"] }}
        />
      </Form>
    </Screen>
  );
}
