import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = () => {
  return `/node_groups/`;
};
const buildUrlForNodeGroupId = ({ nodeGroupId }: NodeGroupsRequest) => {
  return `/node_groups/${nodeGroupId ?? ""}`;
};

interface NodeGroupsRequest {
  nodeGroupId?: string;
}

export const NodeGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  country_code: z.string(),
  organization_id: z.string(),
  locale_id: z.string(),
  tag: z.string(),
  eid: z.string(),
  txpow_control: z.string(),
  config_template_node_group_id: z.string(),
  is_config_template: z.boolean(),
  has_firmware_eco: z.boolean(),
  has_underdog: z.boolean(),
  time_zone: z.string(),
  push_url_enabled: z.boolean(),
  has_wireless: z.boolean(),
  has_wired: z.boolean(),
  has_switch: z.boolean(),
  has_vm_concentrator: z.boolean(),
  has_pcc: z.boolean(),
  has_phone: z.boolean().nullable(),
  ap_port_profiles: z.array(z.unknown()),
  supports_ap_port_profiles: z.boolean(),
  ap_port_profile_id: z.string(),
  supports_usb_configs: z.boolean(),
  has_6ghz_capability: z.boolean(),
  pcc_ad_config: z.object({
    active_directory_servers: z.array(z.unknown()),
    active_directory_available: z.boolean(),
    active_directory_groups: z.array(z.unknown()),
    active_directory_enabled: z.boolean(),
  }),
  is_missing_floorplans: z.boolean(),
  firmware: z
    .object({
      has_pending_upgrade: z.boolean(),
      has_available_upgrade: z.boolean(),
      current_version: z.string(),
      pending_version: z.string().nullable(),
      has_staggered_nodes: z.boolean().nullable(),
      staggered_by_meraki: z.boolean().nullable(),
      firmware_upgrade_batch_id: z.string().nullable(),
    })
    .optional(),
  ipv6_enabled: z.boolean(),
  has_ipv6_support: z.boolean(),
  ipv6_capable: z.boolean(),
  has_adaptive_policy_enabled: z.boolean(),
  has_mx_ipv6_internal: z.boolean(),
  ipv6_enabled_node: z.boolean().nullable(),
});

export const NodeGroupsSchema = z.array(NodeGroupSchema);

export type NodeGroup = z.infer<typeof NodeGroupSchema>;

export type NodeGroups = z.infer<typeof NodeGroupsSchema>;

const fetchNodeGroups = () => {
  return request(NodeGroupsSchema, "GET", buildUrl(), {
    queryParams: {
      "with[with_firmware]": true,
    },
  });
};

const fetchNodeGroup = ({ nodeGroupId }: NodeGroupsRequest) => {
  return request(NodeGroupSchema, "GET", buildUrlForNodeGroupId({ nodeGroupId }), {
    queryParams: {
      "with[with_firmware]": true,
    },
  });
};

export const useNodeGroups = createQuery({
  baseQueryKey: buildUrl(),
  queryFn: () => fetchNodeGroups(),
});

//"with[with_firmware]": true,
export const useNodeGroup = createQuery({
  baseQueryKey: buildUrlForNodeGroupId({ nodeGroupId: `{nodeGroupId}` }),
  queryFn: (parameters: NodeGroupsRequest) => fetchNodeGroup(parameters),
});
