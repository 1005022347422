import { I18n } from "@meraki/core/i18n";
import { OrgNetwork, useOrgNetworks, useOrgStatuses } from "@meraki/shared/api";
import sortBy from "lodash/sortBy";
import { useCallback, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";

import Organization from "~/api/models/Organization";
import useGoOrgSearch from "~/api/queries/admin/useGoOrgSearch";
import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { FontWeight } from "~/constants/MkiTextStyles";
import { ORGANIZATION_KEY } from "~/constants/SearchKeys";
import { appSelect } from "~/lib/PlatformUtils";
import {
  currentOrganization,
  getCurrentNetwork,
  getIsMerakiSSOAdmin,
  getOrgCount,
  getSSOAccessOrg,
} from "~/selectors";
import MkiRowSeparator from "~/shared/components/MkiRowSeparator";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import SearchBar from "~/shared/components/SearchBar";
import StatusIcon from "~/shared/components/StatusIcon";
import GeneralStatus, { StatusType } from "~/shared/constants/Status";
import useOrgs from "~/shared/hooks/orgs/useOrgs";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useRefreshControl from "~/shared/hooks/useRefreshControl";
import DropDownRow from "~/shared/rows/DropDownRow";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

interface OrgRowProps extends OrgListProps {
  org: Organization | GoOrgSearchResult;
  orgStatus: StatusType;
  testID: string;
  currentOrgId?: string;
  numberOfOrgs: number;
  currentNetworkId?: string;
  networkData?: OrgNetwork[];
  setReqPending?: (_: boolean) => void;
  setNetwork: (networkId: string) => void;
}

interface OrgRowEnterpriseProps extends OrgListProps {
  org: Organization | GoOrgSearchResult;
  orgStatus: StatusType;
  testID: string;
}

const OrgRowEnterprise = ({ org, onPress, orgStatus, testID }: OrgRowEnterpriseProps) => {
  return (
    <SimpleDisclosureRow testID={testID} onPress={() => onPress(org)} style={styles.container}>
      <StatusIcon status={orgStatus} screenStyles={styles.statusIcon} />
      <MkiText>{org.name}</MkiText>
    </SimpleDisclosureRow>
  );
};

export const OrgRowGo = ({
  org,
  orgStatus,
  currentOrgId,
  onPress,
  testID,
  setReqPending,
  numberOfOrgs,
  currentNetworkId,
  networkData,
  setNetwork,
}: OrgRowProps) => {
  const { id, name } = org;
  const isCurrentOrg = id === currentOrgId;

  if (isCurrentOrg) {
    const sortedNetworks = sortBy(networkData ?? [], (network) => network.id !== currentNetworkId);
    const networkRowSelect = async (networkId: string) => {
      setReqPending?.(true);
      await setNetwork(networkId);
    };

    const renderNetworkRow = (network: OrgNetwork) => {
      const { name, id: networkId } = network;
      const isCurrentNet = networkId === currentNetworkId;

      return (
        <SimpleDisclosureRow
          onPress={() => networkRowSelect(networkId)}
          hideDisclosureIcon
          style={[styles.container, styles.innerRow]}
        >
          <MkiText
            textStyle={isCurrentNet ? "activeTab" : "default"}
            screenStyles={isCurrentNet ? styles.currentNetworkName : undefined}
          >
            {name}
          </MkiText>
        </SimpleDisclosureRow>
      );
    };

    return (
      <View>
        <MkiText textStyle="label" screenStyles={styles.subheading}>
          {I18n.t("SETTINGS.ADVANCED.CHANGE_GO_ORG.CURRENT")}
        </MkiText>
        <MkiRowSeparator withHorizontalMargins withCustomWidth />
        <View style={styles.dropDownContainer}>
          <DropDownRow
            icon={<StatusIcon status={orgStatus} screenStyles={styles.statusIcon} />}
            title={name}
            noMargin={true}
            testID="ORG_LIST.NETWORK_DROPDOWN"
            useDefaultTextStyle
          >
            <MkiTable<OrgNetwork> data={sortedNetworks} renderRow={renderNetworkRow} />
          </DropDownRow>
        </View>
        {numberOfOrgs > 1 && (
          <View>
            <MkiText textStyle="label" screenStyles={styles.subheading}>
              {I18n.t("SETTINGS.ADVANCED.CHANGE_GO_ORG.OTHER")}
            </MkiText>
            <MkiRowSeparator withHorizontalMargins withCustomWidth />
          </View>
        )}
      </View>
    );
  }

  return (
    <SimpleDisclosureRow testID={testID} onPress={() => onPress(org)} style={styles.container}>
      <StatusIcon status={orgStatus} screenStyles={styles.innerStatusIcon} />
      <MkiText>{name}</MkiText>
    </SimpleDisclosureRow>
  );
};

function OrgSearchRow(orgCount: number) {
  const { setSearchText } = useActions();
  const [searchTextOnState, setSearchTextOnState] = useState("");

  const onSearchTextChange = useCallback(
    (text: string) => {
      setSearchTextOnState(text);
      setSearchText(ORGANIZATION_KEY, text);
    },
    [setSearchText, setSearchTextOnState],
  );

  if (orgCount < 10) {
    return null;
  }

  return (
    <SearchBar
      value={searchTextOnState}
      onChangeText={onSearchTextChange}
      placeholder={I18n.t("ORGANIZATION.SEARCH_PLACEHOLDER")}
      testID="ORG_LIST.ORG_SEARCH_BAR"
    />
  );
}

interface OrgListProps {
  onPress: (org: Organization | GoOrgSearchResult) => void;
  currentOrgId?: string;
  currentNetworkId?: string;
  setReqPending?: (_: boolean) => void;
}

const OrgList = ({ onPress, setReqPending }: OrgListProps) => {
  const actions = useActions();

  const orgCount = useAppSelector(getOrgCount) ?? 0;
  const organization = useAppSelector(currentOrganization);
  const network = useAppSelector(getCurrentNetwork);
  const isMerakiSSOAdmin = useAppSelector(getIsMerakiSSOAdmin);
  const ssoAccessOrg = useAppSelector(getSSOAccessOrg);

  const { id: currentOrgId } = organization;
  const [orgs, fetchOrgs] = useOrgs();
  const { data: orgStatuses, refetch: fetchOrgStatuses } = useOrgStatuses();
  const sortedOrgs: (Organization | GoOrgSearchResult)[] = sortBy(
    orgs ?? [],
    (org) => org.id !== currentOrgId,
  );
  if (isMerakiSSOAdmin && ssoAccessOrg != null) {
    sortedOrgs.unshift(ssoAccessOrg);
  }

  const refreshControl = useRefreshControl(fetchOrgs, fetchOrgStatuses);
  const { data: networkData } = useOrgNetworks(
    { organizationId: currentOrgId },
    {
      enabled: Boolean(currentOrgId),
    },
  );
  const [searchTerm, setSearchTerm] = useState("");
  const { data: goOrgSearchData } = useGoOrgSearch(searchTerm);

  const renderGlobalSearch = () => {
    return (
      <View style={styles.globalSearch}>
        <View style={styles.globalSearchLabel}>
          <MkiText textStyle="label">{I18n.t("ORGANIZATION.GLOBAL_SEARCH.LABEL")}</MkiText>
        </View>
        <SearchBar
          value={searchTerm}
          onChangeText={setSearchTerm}
          placeholder={I18n.t("ORGANIZATION.GLOBAL_SEARCH.PLACEHOLDER")}
          testID="GLOBAL_SEARCH_INPUT"
        />
      </View>
    );
  };

  return (
    <FlatList<Organization | GoOrgSearchResult>
      testID="ORG_LIST.TABLE"
      refreshControl={refreshControl}
      ListHeaderComponent={isMerakiSSOAdmin ? renderGlobalSearch() : OrgSearchRow(orgCount)}
      data={goOrgSearchData != null ? goOrgSearchData : sortedOrgs}
      renderItem={({ item, index }) =>
        appSelect({
          enterprise: (
            <OrgRowEnterprise
              testID={`org-${index}`}
              org={item}
              onPress={onPress}
              orgStatus={orgStatuses?.[item.id] ?? GeneralStatus.dormant}
            />
          ),
          go: (
            <OrgRowGo
              testID={`org-${index}`}
              org={item}
              onPress={onPress}
              orgStatus={orgStatuses?.[item.id] ?? GeneralStatus.dormant}
              currentOrgId={currentOrgId}
              numberOfOrgs={orgCount}
              currentNetworkId={network?.id}
              networkData={networkData}
              setReqPending={setReqPending}
              setNetwork={actions.setCurrentNetwork}
            />
          ),
        })
      }
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  dropDownContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: SPACING.default,
  },
  innerRow: {
    marginLeft: SPACING.default * 2,
  },
  subheading: {
    marginHorizontal: SPACING.default,
    marginTop: SPACING.medium,
    marginBottom: SPACING.small,
  },
  statusIcon: {
    marginRight: SPACING.default,
    marginLeft: SPACING.default,
  },
  innerStatusIcon: {
    marginRight: SPACING.default,
  },
  currentNetworkName: {
    fontWeight: FontWeight.W_600,
  },
  globalSearch: {
    marginHorizontal: SPACING.default,
  },
  globalSearchLabel: {
    paddingVertical: SPACING.small,
    marginBottom: SPACING.small,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
  },
});

export default OrgList;
