import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import { UmbrellaScreensPropMap } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import { showSaveWarning } from "~/lib/AlertUtils";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";

type Props = ForwardedNativeStackScreenProps<UmbrellaScreensPropMap, "UmbrellaExcludeURL">;

interface UmbrellaExcludeURLScreenState {
  url: undefined | string;
}

export default class UmbrellaExcludeURLScreen extends PureComponent<
  Props,
  UmbrellaExcludeURLScreenState
> {
  static renderSectionHeader() {
    return (
      <View style={styles.headerContainer}>
        <MkiText screenStyles={styles.headerStyle}>{I18n.t("UMBRELLA.EXCLUDE_URL.NOTE")}</MkiText>
      </View>
    );
  }

  constructor(props: Props) {
    super(props);
    const { passedURL, rowId } = props;

    this.state = { url: passedURL };
    this.setNavOptions(passedURL != null && rowId == null);
  }

  save = () => {
    const { addEditURL, rowId, navigation } = this.props;
    const { url } = this.state;

    addEditURL(rowId, url);
    navigation.goBack();
  };

  setNavOptions(saveEnabled: boolean) {
    const { navigation, rowId } = this.props;
    const { url } = this.state;
    const isEditing = rowId !== undefined && !isEmpty(url);

    navigation.setOptions({
      headerLeft: () =>
        !saveEnabled && isEditing ? (
          <CloseButton onPress={navigation.goBack} />
        ) : (
          <CancelButton onPress={this.cancelChanges} />
        ),
      headerRight: () => <SaveButton onPress={this.save} />,
    });
  }

  cancelChanges = () => {
    const { navigation, rowId } = this.props;
    const { url } = this.state;
    if (rowId == null && isEmpty(url)) {
      navigation.goBack();
    } else {
      showSaveWarning(this.save, navigation.goBack);
    }
  };

  setURL = (newValue: string) => {
    this.setState({ url: newValue });
    this.setNavOptions(true);
  };

  renderRow = () => {
    const { url } = this.state;

    return (
      <InputRow
        value={url}
        placeholder={I18n.t("BLOCK_CONTENT.BLOCK_WEBSITE_PLACEHOLDER")}
        onChangeText={this.setURL}
        keyboardType={KEYBOARD_TYPE.url}
        returnKeyType={RETURN_KEY.go}
        onSubmitEditing={this.save}
        testID="URL_INPUT"
        clearTestID="URL_CLEAR"
      >
        {I18n.t("UMBRELLA.EXCLUDE_URL.LIST_HEADER")}
      </InputRow>
    );
  };

  render() {
    const { url } = this.state;
    const data = { 0: [url] };

    return (
      <View style={styles.container}>
        <View style={styles.tableContainer}>
          <MkiTable
            data={data}
            renderSectionHeader={UmbrellaExcludeURLScreen.renderSectionHeader}
            renderRow={this.renderRow}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  headerContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: MkiColors.borderColor,
    paddingBottom: SPACING.small,
    marginHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
    marginHorizontal: SPACING.default,
  },
});
