import { I18n } from "@meraki/core/i18n";
import {
  Ssid,
  useCreateVlan,
  useUpdateSsid,
  useUpdateVlan,
  useVlans,
  Vlan,
  VlanExport,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";

import { errorMessageParser } from "~/api/util/error";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { showAlert } from "~/lib/AlertUtils";
import { prepSsidToUpload } from "~/lib/ImportTemplateUtils";
import useActions from "~/shared/hooks/redux/useActions";

interface ImportTemplateButtonProps {
  disabled: boolean;
  ssids: Ssid[];
  vlans?: VlanExport[];
  existingVlans?: Vlan[];
}

const ImportTemplateButton = ({
  disabled,
  ssids,
  vlans,
  existingVlans,
}: ImportTemplateButtonProps) => {
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const actions = useActions();

  const { mutateAsync: ssidMutate, isLoading: ssidIsLoading } = useUpdateSsid();
  const { refetch: refetchVlans } = useVlans({ networkId });
  const { mutateAsync: vlanCreationMutate, isLoading: vlanCreationIsLoading } = useCreateVlan();
  const { mutateAsync: vlanUpdateMutate, isLoading: vlanUpdateIsLoading } = useUpdateVlan();

  const importSsid = async (ssid: Ssid) => {
    const ssidNumber = ssid.number;
    const prepedSsid = prepSsidToUpload(ssid, ssidNumber);
    await ssidMutate({ networkId, ssidNumber, body: prepedSsid });
  };

  const importVlan = async (vlan: VlanExport) => {
    const vlanId = vlan.id;
    const doesExist = existingVlans?.find((existingvlan) => existingvlan.id === vlan.id);

    if (doesExist) {
      actions.removeVlanSecurity(doesExist.subnet);
      await vlanUpdateMutate({ networkId, vlanId, vlan });
    } else {
      await vlanCreationMutate({ networkId, vlan });
    }
  };

  const importNetworks = async () => {
    const networkNames: string[] = [];
    const errorsArr: string[] = [];

    for (const ssid of ssids ?? []) {
      networkNames.push(`SSID-${ssid.number + 1} -> ${ssid.name}`);

      try {
        await importSsid(ssid);
      } catch (error) {
        errorsArr.push(`${ssid.name} -> ${errorMessageParser(error, ", ")}`);
      }
    }

    for (const vlan of vlans ?? []) {
      networkNames.push(`VLAN-${vlan.id} -> ${vlan.name}`);
      try {
        await importVlan(vlan);
      } catch (error) {
        errorsArr.push(`${vlan.name} -> ${errorMessageParser(error, ", ")}`);
      }
    }

    if (errorsArr.length > 0) {
      showAlert(I18n.t("ERROR"), errorsArr.join("\n"));
    }
    showAlert(
      I18n.t("SUCCESS"),
      I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.IMPORT_SUCCESS", {
        networkNames: networkNames.join(", "),
      }),
      () => {
        actions.getSsids(networkId);
        refetchVlans();
        navigation.goBack();
      },
    );
  };

  return (
    <RoundedButton
      buttonType={ButtonType.primary}
      onPress={importNetworks}
      disabled={disabled || ssidIsLoading || vlanCreationIsLoading || vlanUpdateIsLoading}
      testID="IMPORT_TEMPLATE_BUTTON"
    >
      {I18n.t("IMPORT_EXPORT_NETWORKS.IMPORT.BUTTON")}
    </RoundedButton>
  );
};

export default ImportTemplateButton;
