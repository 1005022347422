import {
  GET_IPSEC_VPN_SETTINGS_FAILURE,
  GET_IPSEC_VPN_SETTINGS_REQUEST,
  GET_IPSEC_VPN_SETTINGS_SUCCESS,
  GET_SITE_TO_SITE_VPN_SETTINGS_FAILURE,
  GET_SITE_TO_SITE_VPN_SETTINGS_REQUEST,
  GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
  MONTH,
  ORG_VPN_STAT_FAILURE,
  ORG_VPN_STAT_REQUEST,
  ORG_VPN_STAT_SUCCESS,
  ORG_VPN_STATUS_FAILURE,
  ORG_VPN_STATUS_REQUEST,
  ORG_VPN_STATUS_SUCCESS,
  UPDATE_IPSEC_VPN_SETTINGS_FAILURE,
  UPDATE_IPSEC_VPN_SETTINGS_REQUEST,
  UPDATE_IPSEC_VPN_SETTINGS_SUCCESS,
  UPDATE_SITE_TO_SITE_VPN_SETTINGS_FAILURE,
  UPDATE_SITE_TO_SITE_VPN_SETTINGS_REQUEST,
  UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
  VPN_PEER_OUTAGE_FAILURE,
  VPN_PEER_OUTAGE_REQUEST,
  VPN_PEER_OUTAGE_SUCCESS,
  VPN_PEER_STATUS_FAILURE,
  VPN_PEER_STATUS_REQUEST,
  VPN_PEER_STATUS_SUCCESS,
  VPN_USAGE_DATA_FAILURE,
  VPN_USAGE_DATA_REQUEST,
  VPN_USAGE_DATA_SUCCESS,
} from "@meraki/shared/redux";
import { secondsToMilliseconds } from "date-fns";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { IpsecSettings } from "~/go/types/ClientVPN";
import { SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";
import { isGX50 } from "~/lib/DeviceUtils";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkState,
  getApplianceNodeGroupEid,
  getCurrentOrganization,
  getOrgNetworksState,
  getVpnPeerEids,
  gxDeviceSelector,
  timespanNameSelector,
  timespanState,
} from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const fetchVpnStatuses =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [ORG_VPN_STATUS_REQUEST, ORG_VPN_STATUS_SUCCESS, ORG_VPN_STATUS_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/appliance/vpn/statuses`,
        config: {
          method: Method.get,
          queryParams: {
            networkIds: [networkId],
          },
        },
      },
    });
  };

export const fetchVpnStats =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const organizationId = getCurrentOrganization(getState());
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [ORG_VPN_STAT_REQUEST, ORG_VPN_STAT_SUCCESS, ORG_VPN_STAT_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/appliance/vpn/stats`,
        config: {
          method: Method.get,
          queryParams: {
            networkIds: [networkId],
          },
        },
      },
    });
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 *
 * fetches numerical statuses for all the peers, can only be 1 or 4
 */
export const fetchPeerStatuses =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const eid = getApplianceNodeGroupEid(getState());
    const now = Date.now();
    const timespan = secondsToMilliseconds(MONTH.value); // timespan must be in milliseconds for this endpoint

    return dispatch({
      [CALL_API]: {
        types: [VPN_PEER_STATUS_REQUEST, VPN_PEER_STATUS_SUCCESS, VPN_PEER_STATUS_FAILURE],
        endpoint: `/n/${eid}/manage/vpn/fetch_details/${eid}`,
        meta: { eid },
        config: {
          method: Method.get,
          queryParams: {
            t0: now - timespan,
            t1: now,
          },
        },
      },
    });
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 *
 * requires fetchPeerStatuses to run first so that the eids to fetch are
 * loaded into the redux state
 */
export const fetchPeerOutageData =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const eid = getApplianceNodeGroupEid(getState());
    const ids = getVpnPeerEids(getState()) || [];

    const now = Date.now();
    const timespan = secondsToMilliseconds(MONTH.value); // timespan must be in milliseconds for this endpoint

    return dispatch({
      [CALL_API]: {
        types: [VPN_PEER_OUTAGE_REQUEST, VPN_PEER_OUTAGE_SUCCESS, VPN_PEER_OUTAGE_FAILURE],
        endpoint: `/n/${eid}/manage/vpn/peer_outages`,
        config: {
          method: Method.get,
          queryParams: {
            t0: now - timespan,
            t1: now,
            source: eid,
          },
          unencodedQueryParams: {
            ids: ids.join("+"),
          },
        },
      },
    });
  };

/*
  fetchPeerOutageData but input timespan from state
  requires fetchPeerStatuses to run first like fetchPeerOutageData
*/
/**
 * @privateapi Public endpoints should be used whenever possible
 *
 * fetchPeerOutageData but input timespan from state
 * requires fetchPeerStatuses to run first like fetchPeerOutageData
 */
export const fetchPeerOutageDataWithTimespan =
  (timespan: number): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const eid = getApplianceNodeGroupEid(getState());
    const ids = getVpnPeerEids(getState()) || [];

    return dispatch({
      [CALL_API]: {
        types: [VPN_PEER_OUTAGE_REQUEST, VPN_PEER_OUTAGE_SUCCESS, VPN_PEER_OUTAGE_FAILURE],
        endpoint: `/n/${eid}/manage/vpn/peer_outages`,
        config: {
          method: Method.get,
          queryParams: {
            timespan,
            source: eid,
          },
          unencodedQueryParams: {
            ids: ids.join("+"),
          },
        },
      },
    });
  };

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const fetchUsageData =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const eid = getApplianceNodeGroupEid(getState());
    const timespan = timespanState(getState());
    const timespanName = timespanNameSelector(getState());

    return dispatch({
      [CALL_API]: {
        types: [VPN_USAGE_DATA_REQUEST, VPN_USAGE_DATA_SUCCESS, VPN_USAGE_DATA_FAILURE],
        endpoint: `/n/${eid}/manage/vpn/network_graphs`,
        meta: { eid, timespan: timespanName },
        config: {
          method: Method.get,
          queryParams: {
            ts: timespan,
            ids: eid,
          },
        },
      },
    });
  };

export const fetchIpsecVPNSettings =
  (): AppThunk<Promise<void | ApiResponseAction<any>>> => (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    const gxDevice = gxDeviceSelector(getState());

    if (networkId == null) {
      return Promise.reject();
    } else if (!isGX50(gxDevice)) {
      return new Promise((resolve) => {
        dispatch({
          type: GET_IPSEC_VPN_SETTINGS_SUCCESS,
          response: {},
          meta: { networkId },
        });
        resolve();
      });
    }

    return dispatch({
      [CALL_API]: {
        types: [
          GET_IPSEC_VPN_SETTINGS_REQUEST,
          GET_IPSEC_VPN_SETTINGS_SUCCESS,
          GET_IPSEC_VPN_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/clientVpn/ipsec`,
        config: { method: Method.get },
        meta: { networkId },
      },
    });
  };

export const updateIpsecVPNSettings =
  (settings: IpsecSettings): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId == null) {
      return Promise.reject();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          UPDATE_IPSEC_VPN_SETTINGS_REQUEST,
          UPDATE_IPSEC_VPN_SETTINGS_SUCCESS,
          UPDATE_IPSEC_VPN_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/clientVpn/ipsec`,
        config: {
          method: Method.put,
          body: JSON.stringify(settings),
        },
        meta: { networkId },
      }),
    );
  };

export const fetchAllSiteToSiteVPNSettings =
  (): AppThunk<Promise<ApiResponseAction<any>[]>> => (dispatch, getState) => {
    const networks = getOrgNetworksState(getState());

    const reqs: Promise<ApiResponseAction<any>>[] = [];

    networks.forEach((network) => {
      reqs.push(dispatch(fetchSiteToSiteVPNSettings(network.id)));
    });

    return Promise.all(reqs);
  };

export const fetchSiteToSiteVPNSettings =
  (network?: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const networkId = network ?? currentNetworkState(getState());

    if (networkId == null) {
      return Promise.reject();
    }

    return dispatch({
      [CALL_API]: {
        types: [
          GET_SITE_TO_SITE_VPN_SETTINGS_REQUEST,
          GET_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
          GET_SITE_TO_SITE_VPN_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/vpn/siteToSiteVpn`,
        config: { method: Method.get },
        meta: { networkId },
      },
    });
  };

export const updateSiteToSiteVPNSettings =
  (settings: SiteToSiteSettings): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId == null) {
      return Promise.reject();
    }

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          UPDATE_SITE_TO_SITE_VPN_SETTINGS_REQUEST,
          UPDATE_SITE_TO_SITE_VPN_SETTINGS_SUCCESS,
          UPDATE_SITE_TO_SITE_VPN_SETTINGS_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/vpn/siteToSiteVpn`,
        config: {
          method: Method.put,
          body: JSON.stringify(settings),
        },
        meta: { networkId },
      }),
    );
  };
