import { createContext } from "react";

export type LiveToolCommand = "start" | "stop" | "restart";

export enum LiveToolStatus {
  idle = "Idle",
  connecting = "Connecting",
  running = "Running",
  completed = "Completed",
  error = "Error",
}

export interface LiveToolContext {
  command: LiveToolCommand;
  onStatusChange: (status: LiveToolStatus) => void;
}

// Default to start right away unless otherwise specified by context passed in
const LiveToolContext = createContext<LiveToolContext>({
  command: "start" as const,
  // no-op
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStatusChange: () => {},
});

export default LiveToolContext;
