import { I18n } from "@meraki/core/i18n";
import { Button, Card, Tag } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";

import { useSwitchPortDetails } from "../screens/ports/SwitchPortDetailsContext";

export const SwitchPortTagCard = () => {
  const { switchPort, openAddTagBottomSheet, openDeletTagBottomSheet } = useSwitchPortDetails();

  if (!switchPort || switchPort.tags === undefined) {
    return null;
  }

  return (
    <Card testID="SWITCH_PORT_TAG_CARD">
      <Card.Header
        title={I18n.t("NODE_INFO.TAGS")}
        rightAccessory={
          <Button
            kind="tertiary"
            text={I18n.t("PORTS.GOv3.TAGS.ADD_LABEL")}
            trailingIcon="Plus"
            onPress={openAddTagBottomSheet}
            testID="ADD_TAG_BUTTON"
          />
        }
      />
      <Card.Content paddingTop="2xs">
        {switchPort.tags.length > 0 && (
          <Box flexDirection="row" gap="sm" flexWrap="wrap">
            {switchPort.tags.map((tag) => (
              <Tag type="removable" onPress={openDeletTagBottomSheet} key={tag}>
                {tag}
              </Tag>
            ))}
          </Box>
        )}
      </Card.Content>
    </Card>
  );
};
