import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { Button, Loader } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import useSwitchPorts, { useUpdateSwitchPorts } from "~/api/queries/switches/useSwitchPorts";
import { SwitchPort } from "~/api/schemas/SwitchPort";
import { showSaveWarning } from "~/lib/AlertUtils";
import PermissionsAwareNavButton from "~/migrationZone/shared/nav/components/PermissionsAwareNavButton";
import ReadOnlyNotification from "~/migrationZone/shared/nav/components/ReadOnlyNotification";
import useEditSwitchPort from "~/shared/hooks/switch/useEditSwitchPort";
import { SharedScreensPropMap } from "~/shared/navigation/Types";

import { AdditionalConfigurations } from "./AdditionalConfigurations";
import { BasicSwitchportEdit } from "./BasicSwitchportEdit";
import { PortVlanConfig } from "./PortVlanConfig";

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "SwitchPortEditWrapper">;

interface SwitchPortEditScreenProps {
  serialNumber: string;
  switchPort: SwitchPort;
}

const PageLoading = () => (
  <Box gap="md" padding="md">
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
    <Loader.Skeleton height="large" />
  </Box>
);

export const SwitchPortEdit = ({ serialNumber, switchPort }: SwitchPortEditScreenProps) => {
  const navigation = useNavigation<Props["navigation"]>();
  const { portId, linkNegotiationCapabilities } = switchPort;
  const [stagedSwitchPort, _, updateStagedSwitchPortByKey, hasDiff] = useEditSwitchPort(switchPort);
  const { mutate, isLoading } = useUpdateSwitchPorts(serialNumber, [portId]);

  useLayoutEffect(() => {
    const saveCallback = () => {
      mutate(stagedSwitchPort, {
        onSuccess: () => {
          navigation.goBack();
        },
      });
    };

    navigation.setOptions({
      headerTitle: I18n.t("SWITCH_PORT_EDIT.EDIT_PORT_NUMBER", { portNumber: portId }),
      headerLeft: () => (
        <Button.Nav
          text={I18n.t("CANCEL")}
          onPress={() => {
            if (hasDiff) {
              showSaveWarning(saveCallback, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <PermissionsAwareNavButton text={I18n.t("SAVE")} onPress={saveCallback} />,
    });
  }, [navigation, portId, hasDiff, mutate, stagedSwitchPort]);

  if (isLoading) {
    return <PageLoading />;
  }

  const props = {
    updateByKey: updateStagedSwitchPortByKey,
    port: stagedSwitchPort,
  };

  // TODO DM-4272: Fix padding around top of basic configuration
  return (
    <Screen addDefaultPadding>
      <ReadOnlyNotification />
      <BasicSwitchportEdit {...props} linkNegotiationCapabilities={linkNegotiationCapabilities} />
      <PortVlanConfig {...props} />
      <AdditionalConfigurations {...props} />
    </Screen>
  );
};

export function SwitchPortEditScreen({ serialNumber, portIds }: Props) {
  const derivedPortIds = portIds.map((id) => {
    const stringId = id.toString(); // will investigate more, but looks like the private api comes back with numbers sometimes
    if (Number.parseInt(id).toString() === stringId) {
      return stringId;
    }

    const parsedId = stringId.match(/\d+_(\d+)/);
    if (!parsedId) {
      errorMonitor.notify(`${stringId} failed to parse in SwitchPortEditWrapper`);
      return "";
    }

    return parsedId[1];
  });

  const switchPortQueries = useSwitchPorts(serialNumber, derivedPortIds);
  const switchPort = switchPortQueries?.[0].data;

  if (switchPortQueries.some((r) => r.isError) || !switchPort) {
    return null;
  }

  if (switchPortQueries.some((r) => r.isLoading)) {
    return <PageLoading />;
  }

  return <SwitchPortEdit switchPort={switchPort} serialNumber={serialNumber} />;
}

export default SwitchPortEditScreen;
