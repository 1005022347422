import Svg, { G, Path } from "react-native-svg";

import { IconProps } from "./types";

export function WifiLightning({ color, x, y }: IconProps) {
  return (
    <G x={x} y={y}>
      <Svg fill="none" height="20" viewBox="0 0 20 20" width="20">
        <Path
          clipRule="evenodd"
          d="M18.1448 6.19655C18.1702 6.22548 18.1881 6.26024 18.1969 6.29776C18.2057 6.33527 18.2052 6.37437 18.1952 6.41161C18.1853 6.44884 18.1664 6.48306 18.1401 6.51123L14.8589 10.0269C14.8241 10.0641 14.7781 10.0891 14.7278 10.0978C14.6776 10.1066 14.6259 10.0988 14.5805 10.0755C14.5351 10.0522 14.4986 10.0147 14.4764 9.96881C14.4543 9.92289 14.4477 9.87098 14.4577 9.82098L14.8874 7.67266L13.199 7.03952C13.1629 7.02599 13.1307 7.00377 13.1052 6.97484C13.0798 6.9459 13.0619 6.91114 13.0531 6.87363C13.0443 6.83611 13.0449 6.79701 13.0548 6.75977C13.0647 6.72253 13.0836 6.68832 13.1099 6.66015L16.3912 3.14452C16.426 3.10725 16.472 3.08233 16.5222 3.07356C16.5724 3.06479 16.6241 3.07264 16.6695 3.09592C16.7149 3.11921 16.7514 3.15665 16.7736 3.20257C16.7957 3.2485 16.8023 3.3004 16.7923 3.3504L16.3627 5.49872L18.0511 6.13186C18.0871 6.14539 18.1193 6.16761 18.1448 6.19655ZM9.99723 5.31253C7.23445 5.30647 4.58145 6.39358 2.61732 8.33657C2.24923 8.7007 1.65565 8.69748 1.29152 8.32939C0.927388 7.9613 0.9306 7.36772 1.29869 7.00359C3.61461 4.71259 6.7427 3.43066 10.0003 3.43753L9.99723 5.31253ZM9.99956 12.8125C9.22013 12.8125 8.47069 13.1129 7.90707 13.6513C7.53266 14.0089 6.93922 13.9953 6.58159 13.6209C6.22396 13.2465 6.23757 12.6531 6.61198 12.2954C7.52444 11.4238 8.73773 10.9375 9.99956 10.9375C11.2614 10.9375 12.4747 11.4238 13.3871 12.2954C13.7616 12.6531 13.7752 13.2465 13.4175 13.6209C13.0599 13.9953 12.4665 14.0089 12.0921 13.6513C11.5284 13.1129 10.779 12.8125 9.99956 12.8125ZM5.2651 10.9906C6.52756 9.75052 8.22776 9.05783 9.99735 9.06252L10.0011 7.18753C7.73874 7.18185 5.56522 8.06757 3.95121 9.6529C3.58183 10.0157 3.57651 10.6093 3.93933 10.9787C4.30215 11.3481 4.89571 11.3534 5.2651 10.9906ZM10 16.875C10.6904 16.875 11.25 16.3154 11.25 15.625C11.25 14.9346 10.6904 14.375 10 14.375C9.30964 14.375 8.75 14.9346 8.75 15.625C8.75 16.3154 9.30964 16.875 10 16.875Z"
          fill={color}
          fillRule="evenodd"
        />
      </Svg>
    </G>
  );
}
