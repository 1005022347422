import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/constants/MkiFonts";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { normalizedFontSize } from "~/lib/themeHelper";
import MkiHelplinkedText from "~/shared/components/MkiHelplinkedText";
import MkiText from "~/shared/components/MkiText";

export interface DefaultHeaderProps {
  title: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
  hasHelplink?: boolean;
  context?: string;
  onPress?: () => void;
}

const DefaultHeader = (props: DefaultHeaderProps) => {
  const { title, description, style, hasHelplink, context, onPress } = props;

  const placeholderFunction = () => {
    /* do nothing */
  };

  const titleComponent = hasHelplink ? (
    <MkiHelplinkedText
      onPress={onPress || placeholderFunction}
      textStyle="heading"
      screenStyles={styles.title as ViewStyle}
    >
      {title}
    </MkiHelplinkedText>
  ) : (
    <MkiText textStyle="heading" screenStyles={styles.title}>
      {title}
    </MkiText>
  );

  return (
    <View style={[styles.container, style]}>
      <View style={styles.horizontalContainer}>
        {titleComponent}
        {context && <ContextHelp context={context} />}
      </View>

      <MkiText screenStyles={styles.description}>{description}</MkiText>
    </View>
  );
};

DefaultHeader.defaultProps = {
  hasHelplink: false,
  onPress: () => {
    /* do nothing */
  },
};

const styles = StyleSheet.create({
  container: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  title: {
    fontSize: normalizedFontSize(19),
  },
  description: {
    fontSize: FONT_SIZES.SUBHEADER.default,
    color: MkiColors.secondaryTextColor,
    marginTop: SPACING.tiny,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default DefaultHeader;
