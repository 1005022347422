import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { List, Text, Toggle } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Screen } from "@meraki/magnetic/layout";
import {
  PortVlanTypes,
  queryClient,
  useSwitchPorts,
  useUpdateSwitchPort,
} from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import Toast from "react-native-simple-toast";

import { getLinkSpeedTitleDescription } from "../../../constants/LinkSpeed";
import { useSwitchPortDetails } from "../SwitchPortDetailsContext";

const showToast = (message: string) => Toast.showWithGravity(message, Toast.SHORT, Toast.BOTTOM);

const NumberOfRules = ({ numberOfRules }: { numberOfRules?: number }) =>
  !numberOfRules || numberOfRules === 0 ? (
    <Text size="p1" color="light">
      {I18n.t("NONE")}
    </Text>
  ) : (
    <Text size="p1" color="interact.text.base">
      {I18n.t(`CONFIGURE.NETWORK.RULE${numberOfRules > 1 ? "S" : ""}`, { numberOfRules })}
    </Text>
  );

export const SwitchPortSettingsTab = () => {
  const { switchPort, device } = useSwitchPortDetails();
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();

  const serial = device?.serial;
  const portId = switchPort?.portId;

  const updateSwitchPort = useUpdateSwitchPort();

  const [stagedEnabled, setStagedEnabled] = useState<boolean>(!!switchPort?.enabled);
  const [stagedPOEEnabled, setStagedPOEEnabled] = useState<boolean>(!!switchPort?.poeEnabled);

  const updateEnabled = (enabled: boolean) => {
    if (!portId) {
      showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
      return;
    }

    setStagedEnabled(enabled);

    updateSwitchPort.mutate(
      {
        serial,
        portId,
        switchPort: { enabled },
      },
      {
        onError: () => {
          setStagedEnabled(stagedEnabled);
          showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSwitchPorts.queryKey({ serial }) });
          showToast(
            enabled
              ? I18n.t("PORTS.SETTINGS.ENABLED.ENABLED")
              : I18n.t("PORTS.SETTINGS.ENABLED.DISABLED"),
          );
        },
      },
    );
  };

  const updatePOEEnabled = (poeEnabled: boolean) => {
    if (!portId) {
      showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
      return;
    }

    setStagedPOEEnabled(poeEnabled);

    updateSwitchPort.mutate(
      {
        serial,
        portId,
        switchPort: { poeEnabled },
      },
      {
        onError: () => {
          setStagedPOEEnabled(stagedPOEEnabled);
          showToast(I18n.t("PORTS.SETTINGS.PROBLEM"));
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSwitchPorts.queryKey({ serial }) });
          showToast(I18n.t("PORTS.SETTINGS.POE.UPDATED"));
        },
      },
    );
  };

  const isAccess = switchPort?.type === "access";

  return (
    <Screen addDefaultPadding testID="SETTINGS_TAB">
      <List>
        <List.Item
          title={
            stagedEnabled
              ? I18n.t("PORTS.SETTINGS.ENABLED.ENABLED")
              : I18n.t("PORTS.SETTINGS.ENABLED.DISABLED")
          }
          rightAccessory={
            <Toggle
              checked={!!stagedEnabled}
              onValueChange={updateEnabled}
              testID="SETTINGS_TAB.ENABLED_TOGGLE"
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("PORTS.SETTINGS.POE.TITLE")}
          description={I18n.t("PORTS.SETTINGS.POE.DESCRIPTION")}
          leftAccessory={<Icon name="Gear" size={20} />}
          rightAccessory={
            <Toggle
              checked={!!stagedPOEEnabled}
              onValueChange={updatePOEEnabled}
              testID="SETTINGS_TAB.POE_TOGGLE"
            />
          }
        />
      </List>
      <List>
        <List.Item
          title={I18n.t("PORTS.SETTINGS.LINK_SPEED")}
          leftAccessory={<Icon name="Gauge" size={20} />}
          rightAccessory={
            switchPort && (
              <Text size="p1" color="light">
                {getLinkSpeedTitleDescription()[switchPort.linkNegotiation]?.title}
              </Text>
            )
          }
          onPress={() =>
            navigation.navigate("LinkSpeedSettings", { port: switchPort, serial: device?.serial })
          }
          testID="SETTINGS_TAB.LINK_SPEED"
        />
        <List.Item
          title={I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.TITLE")}
          leftAccessory={<Icon name="ArrowsLeftRight" size={20} />}
          rightAccessory={
            switchPort && (
              <Text size="p1" color="light">
                {switchPort.type === PortVlanTypes.trunk
                  ? I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.TRUNK")
                  : I18n.t("PORTS.SETTINGS.SWITCH_PORT_VLAN.DESCRIPTION.ACCESS")}
              </Text>
            )
          }
          onPress={() =>
            navigation.navigate("VLANConfiguration", {
              switchPortIds: switchPort ? [switchPort.portId] : [],
              serial: device?.serial,
            })
          }
          testID="SETTINGS_TAB.VLAN_CONFIG"
        />
        <List.Item
          title={I18n.t("PORTS.SETTINGS.SWITCH_PORT_ALLOW_LIST.TITLE")}
          leftAccessory={<Icon name="GearSix" size={20} />}
          rightAccessory={
            switchPort && <NumberOfRules numberOfRules={switchPort?.macAllowList?.length} />
          }
          description={I18n.t("PORTS.SETTINGS.SWITCH_PORT_ALLOW_LIST.DESCRIPTION")}
          onPress={
            isAccess
              ? () =>
                  navigation.navigate("MACAllowList", {
                    switchPortId: switchPort.portId,
                    serial,
                  })
              : undefined
          }
          testID="SETTINGS_TAB.MAC_ALLOW_LIST"
        />
      </List>
    </Screen>
  );
};
