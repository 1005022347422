import { I18n } from "@meraki/core/i18n";

export const getFirmwareMessage = (
  hasAvailableUpgrade: boolean,
  pendingVersion: string | null,
  firmwareVersion: string | null,
  hasEco: boolean,
): string => {
  if (hasEco) {
    return I18n.t("FIRMWARE.LOCKED");
  }
  // See: /manage/private/react/views/nodeDetails/leftSections/NodeFirmware.jsx lines 62-75
  const currentVersion = firmwareVersion || "";
  const actuallyHasPending = pendingVersion && firmwareVersion !== pendingVersion;

  const currentMessage = I18n.t("FIRMWARE.VERSION", { version: currentVersion });
  const pendingMessage = I18n.t("FIRMWARE.PENDING", {
    version: pendingVersion,
  });

  // See: /manage/private/react/views/nodeDetails/leftSections/NodeFirmware.jsx lines 41-57
  let status = I18n.t("FIRMWARE.STATUS.UP_TO_DATE");
  if (actuallyHasPending) {
    status = I18n.t("FIRMWARE.STATUS.SCHEDULED");
  } else if (hasAvailableUpgrade) {
    status = I18n.t("FIRMWARE.STATUS.AVAILABLE");
  }

  if (!currentVersion) {
    return I18n.t("FIRMWARE.UNKNOWN");
  }

  let formattedMessage = `${status}\n${currentMessage}`;
  if (actuallyHasPending) {
    formattedMessage += `\n${pendingMessage}`;
  }

  return formattedMessage;
};

// custom version is returned as `Custom version (997bd3a)`
// normal versioning is returned as devicetype-27-1-3
export const versionIsCustom = (version?: string) => {
  return !version?.includes("-");
};

export const getDeviceModelPrefix = (modelName: string) => {
  const modelRootPattern = /^[a-zA-Z]{2}/gm;
  const modelRootMatches = modelName?.match(modelRootPattern);
  return modelRootMatches ? modelRootMatches?.[0] : ""; //? modelRootMatches[0] : "";
};

export const getMinimalFirmwareMessage = (version?: string, model?: string) => {
  if (versionIsCustom(version)) {
    return version;
  }
  if (model) {
    return `${getDeviceModelPrefix(model)} ${version?.split("-").slice(1).join(".")}`;
  }
  return version?.split("-").slice(1).join(".");
};
