import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, Heading, Notification } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { useCreateGoOrganization, useTimezones } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showOkayAlert } from "@meraki/shared/native-alert";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";

import { COUNTRIES } from "../constants";

type CreateOrganizationForm = {
  name: string;
  country: string;
  timezone: string;
};

export function CreateOrganizationScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();

  const { data: timezones } = useTimezones();
  const createOrganization = useCreateGoOrganization();

  const [error, setError] = useState<undefined | string>(undefined);

  const methods = useForm<CreateOrganizationForm>();

  const country = methods.watch("country");
  const timezone = methods.watch("timezone");
  const name = methods.watch("name");

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          disabled={!(country && timezone && name && name !== "")}
          onPress={methods.handleSubmit(({ name, country, timezone }) => {
            createOrganization.mutate(
              { body: { name, region: COUNTRIES[country]?.region, time_zone: timezone } },
              {
                onError: (error) => setError(error.error),
                onSuccess: () => {
                  showOkayAlert(
                    I18n.t("CREATE_ORGANIZATION.ALERT.SUCCESS"),
                    I18n.t("CREATE_ORGANIZATION.ALERT.BODY"),
                    () => navigation.goBack(),
                  );
                },
              },
            );
          })}
        />
      ),
    });
  }, [country, createOrganization, methods, name, navigation, timezone]);

  return (
    <Screen addDefaultPadding>
      {error && (
        <Notification.Inline
          status="negative"
          message={error}
          onDismiss={() => setError(undefined)}
        />
      )}
      <Heading> {I18n.t("CREATE_ORGANIZATION.HEADER")} </Heading>
      <Form {...methods}>
        <Form.Input
          name="name"
          label={I18n.t("CREATE_ORGANIZATION.ORGANIZATION_NAME")}
          testID="ORGANIZATION_NAME"
        />
        <Form.PickerCard
          name="country"
          title={I18n.t("CREATE_ORGANIZATION.COUNTRY")}
          testID="COUNTRY_PICKER"
          options={Object.keys(COUNTRIES).map((countryCode) => {
            return {
              label: COUNTRIES[countryCode]?.name ?? "",
              value: countryCode,
            };
          })}
          snapPoints={["90%"]}
        />
        <Form.PickerCard
          name="timezone"
          title={I18n.t("CREATE_ORGANIZATION.TIMEZONE")}
          testID="TIMEZONE_PICKER"
          options={timezones?.options ?? []}
          snapPoints={["90%"]}
        />
      </Form>
    </Screen>
  );
}
