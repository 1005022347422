import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { BatchRequestAction, useActionBatchMutationWrapper } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { isEmpty, isEqual } from "lodash";
import { useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";
import { z } from "zod";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import DefaultHeader from "~/go/components/DefaultHeader";
import EditableSsidNameHeader from "~/go/components/EditableSsidNameHeader";
import HardwareListRow from "~/go/components/HardwareListRow";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { currentNetworkState, productTypeDevicesSelector, slimSsidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import useActions from "~/shared/hooks/redux/useActions";
import { CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import Device from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { ProductType } from "~/shared/types/Networks";
import { RootState } from "~/shared/types/Redux";
import { basicMapDispatchToProps } from "~/store";

interface ApAvailability {
  [name: string]: boolean;
}

type ReduxProps = {
  devices: Record<string, Device>;
  ssid: SSID;
  networkId: string;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "AccessPointAvailability"> &
  ReduxProps &
  PendingComponent;

export function AccessPointAvailabilityScreen({ ssid, devices, networkId, setReqPending }: Props) {
  const actions = useActions();
  const organizationId = useCurrentOrganizationId();
  const { mutate } = useActionBatchMutationWrapper();
  const ssidTag = `ssid-${ssid.number}`;

  const [stagedAcessPoints, setStagedAcessPoints] = useState<ApAvailability>({});
  const [accessPoints, setAccessPoints] = useState<ApAvailability>({});

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    const wasOriginallyAvailableOnAllAPs = () =>
      Object.values(accessPoints).every((isAvailable) => isAvailable);

    const isAvailableOnAllAPs = () =>
      Object.values(stagedAcessPoints).every((isAvailable) => isAvailable);

    const hasChanges = () => !isEqual(stagedAcessPoints, accessPoints);

    const needsBatchUpdates = () => {
      const availableOnAllAps = isAvailableOnAllAPs();
      const actions: BatchRequestAction[] = [];
      if (!availableOnAllAps) {
        const wasOnAllAPs = wasOriginallyAvailableOnAllAPs();

        for (const [serial, isTagged] of Object.entries(stagedAcessPoints)) {
          if (wasOnAllAPs || stagedAcessPoints[serial] !== accessPoints[serial]) {
            const tags = devices[serial].tags ?? [];
            const tagsAsArray = typeof tags === "string" ? [tags] : tags;

            const wasTagged = tagsAsArray.includes(ssidTag);

            const removedTag = wasTagged
              ? tagsAsArray.filter((deviceTag) => deviceTag !== ssidTag)
              : tagsAsArray;

            actions.push({
              resource: `/devices/${serial}`,
              operation: "update",
              body: { tags: isTagged ? [...tagsAsArray, ssidTag] : removedTag },
            });
          }
        }
      }
      return actions;
    };

    const updateTagsForSSID = async () => {
      const availableOnAllAps = isAvailableOnAllAPs();
      try {
        await actions.setSsid(networkId, {
          number: ssid.number,
          availableOnAllAps,
          availabilityTags: [ssidTag],
        });

        navigation.goBack();
        setReqPending(false);
      } catch (errorMessage) {
        showAlert(I18n.t("ERROR"), errorMessage);
        setReqPending(false);
      }
    };

    const onSave = async () => {
      setReqPending(true);
      const batchActions = needsBatchUpdates();

      if (batchActions.length > 0) {
        mutate(
          {
            organizationId,
            actions: batchActions,
            actionTypeSchema: z.object({ tags: z.array(z.string()) }),
          },
          {
            async onSettled(_, error) {
              if (error && error.errors.length > 0) {
                showAlert(I18n.t("ERROR"), I18n.t("ERROR_MESSAGE_REQUEST"));
                setReqPending(false);
              } else {
                await updateTagsForSSID();
              }
            },
          },
        );
      } else {
        await updateTagsForSSID();
      }
    };

    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
      headerRight: () => <SaveButton onPress={onSave} disabled={!hasChanges()} />,
    });
  }, [
    accessPoints,
    actions,
    devices,
    mutate,
    navigation,
    networkId,
    organizationId,
    setReqPending,
    ssid.number,
    ssidTag,
    stagedAcessPoints,
  ]);

  useEffect(() => {
    const availabilities: ApAvailability = {};
    const availableOnAllAps = ssid.availableOnAllAps;
    if (isEmpty(stagedAcessPoints)) {
      for (const device of Object.values(devices)) {
        const { serial: name, tags } = device;
        availabilities[name] = availableOnAllAps || !!tags?.includes(ssidTag);
      }
      setStagedAcessPoints({ ...availabilities });
      setAccessPoints({ ...availabilities });
    }
  }, [ssidTag, devices, ssid, stagedAcessPoints]);

  const renderRow = (rowData: Device, _rowId: unknown) => {
    const serial = rowData.serial;
    return (
      <HardwareListRow
        onPress={() =>
          setStagedAcessPoints((previousAccessPoints) => {
            return { ...previousAccessPoints, [serial]: !previousAccessPoints[serial] };
          })
        }
        deviceId={rowData.id}
        testID={`DeviceListRow - ${_rowId}`}
        screenStyles={styles.deviceContainer}
        rightAccessory={<CheckBox selected={!!stagedAcessPoints[serial]} />}
        showStatusIcon
      />
    );
  };

  const selectAllAPs = () => {
    const accessPoints = stagedAcessPoints;
    const isAvailableOnAllAPs = Object.values(stagedAcessPoints).every(
      (isAvailable) => isAvailable,
    );

    let setCheckboxTo = true;
    if (isAvailableOnAllAPs) {
      setCheckboxTo = false;
    }
    for (const device in accessPoints) {
      accessPoints[device] = setCheckboxTo;
    }
    setStagedAcessPoints({ ...accessPoints });
  };

  return (
    <FullScreenContainerView screenStyles={styles.container}>
      <EditableSsidNameHeader title={ssid.name} entity="network" enabled={ssid.enabled} />
      <DefaultHeader
        title={I18n.t("SSID_CONFIGURATION.PER_AP_AVAILABILITY.HEADER.TITLE")}
        description={I18n.t("SSID_CONFIGURATION.PER_AP_AVAILABILITY.HEADER.DESCRIPTION")}
      />
      <RoundedButton
        buttonType={ButtonType.secondary}
        screenStyles={styles.buttonStyle}
        onPress={selectAllAPs}
      >
        {I18n.t("SSID_CONFIGURATION.PER_AP_AVAILABILITY.SELECT_ALL")}
      </RoundedButton>
      <MkiTable<Device>
        data={Object.values(devices)}
        keyExtractor={(item) => item.serial}
        renderRow={renderRow}
      />
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  deviceContainer: {
    flex: 1,
    width: "100%",
  },
  buttonStyle: {
    alignSelf: "center",
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["AccessPointAvailability"],
): ReduxProps {
  return {
    devices: productTypeDevicesSelector(state, ProductType.wireless).reduce<Record<string, Device>>(
      (acc, device) => {
        acc[device.serial] = device;
        return acc;
      },
      {},
    ),
    ssid: slimSsidsSelector(state)[props.ssidNumber],
    networkId: errorMonitor.notifyNonOptional(
      "param `networkId` undefined for AccessPointAvailabilityScreen",
      currentNetworkState(state),
    ),
  };
}

export default compose<any>(connect(mapStateToProps, basicMapDispatchToProps))(
  withPendingComponent(AccessPointAvailabilityScreen),
);
