import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

export const LEDBrightnessSchema = z.union([
  z.literal("high"),
  z.literal("low"),
  z.literal("medium"),
  z.literal("off"),
]);

export const SmogoMetricSchema = z.union([
  z.literal("co2"),
  z.literal("humidity"),
  z.literal("indoorAirQuality"),
  z.literal("noise"),
  z.literal("pm25"),
  z.literal("temperature"),
  z.literal("tvoc"),
]);

export type SmogoMetricSchemaType = z.infer<typeof SmogoMetricSchema>;

export const SmogoMetrics: SmogoMetricSchemaType[] = [
  "co2",
  "humidity",
  "indoorAirQuality",
  "noise",
  "pm25",
  "temperature",
  "tvoc",
];

export const SensorSettingsSchema = z
  .object({
    batteryLedTimeout: z.optional(z.nullable(z.number())),
    batteryLowThreshold: z.optional(z.nullable(z.number())),
    bleAdvIdleTimeout: z.optional(z.nullable(z.number())),
    bleAdvInterval: z.optional(z.nullable(z.number())),
    bleAdvPower: z.optional(z.nullable(z.number())),
    bleConnPower: z.optional(z.nullable(z.number())),
    bleInactivityTimeout: z.optional(z.nullable(z.number())),
    bleMtuSize: z.optional(z.nullable(z.number())),
    debugMode: z.optional(z.nullable(z.boolean())),
    logLevel: z.optional(z.nullable(z.string())),
    transmissionInterval: z.optional(z.nullable(z.number())),
    watchdogTimeout: z.optional(z.nullable(z.number())),

    // optional fields
    si7210MtThreshold: z.optional(z.nullable(z.number())),
    si7210MtTamperThreshold: z.optional(z.nullable(z.number())),

    // only supported on MT10 - the temperature + humidity sensor
    hdc2010SampleRate: z.optional(z.nullable(z.number())),

    // only supported on MT11 - the temperature + humidity probe
    ds18b20SamplePeriod: z.optional(z.nullable(z.number())),

    // only supported on MT14 - the air quality sensor
    fastSampleMode: z.optional(z.nullable(z.boolean())),

    // only supported on MT15 - the air qulity sensor WITH CO2
    // add new fields from https://gerrit.ikarem.io/c/manage/+/226473
    ledIndicatorBrightness: z.optional(z.nullable(LEDBrightnessSchema)),
    ledIndicatorMetric: z.optional(z.nullable(SmogoMetricSchema)),

    // only supported on MT30 - the button
    autoReleaseTimeout: z.optional(z.nullable(z.number())),
    blinkOffDuration: z.optional(z.nullable(z.number())),
    diglettBlinkRepeats: z.optional(z.nullable(z.number())),
    diglettLongLedDuration: z.optional(z.nullable(z.number())),
    diglettShortLedDuration: z.optional(z.nullable(z.number())),
    longPressTimeout: z.optional(z.nullable(z.number())),
  })
  .describe("SensorSettingsSchema");

export type SensorSettings = z.infer<typeof SensorSettingsSchema>;

interface SensorSettingsRequest {
  serial: string;
}

interface UpdateSensorSettingsRequest {
  serial: string;
  sensorSettings: SensorSettings;
}

function buildUrl({ serial }: SensorSettingsRequest) {
  return `/api/v1/devices/${serial}/sensor/settings`;
}

function fetchSensorSettings(variables: SensorSettingsRequest): Promise<SensorSettings> {
  return request(SensorSettingsSchema, "GET", buildUrl(variables));
}

export const useAvailableSensorSettings = createQuery<SensorSettingsRequest, SensorSettings>({
  baseQueryKey: buildUrl({ serial: "{serial}" }),
  queryFn: (variables) => {
    return fetchSensorSettings(variables);
  },
  refetchInterval: 5000,
});

function mutateSensorSettings({
  serial,
  sensorSettings,
}: UpdateSensorSettingsRequest): Promise<SensorSettings> {
  return request(SensorSettingsSchema, "PUT", buildUrl({ serial }), {
    body: JSON.stringify(sensorSettings),
  });
}

export const useUpdateSensorSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: UpdateSensorSettingsRequest) => {
      return mutateSensorSettings(options);
    },
    onSuccess: (updatedSettings) => {
      queryClient.setQueryData([useAvailableSensorSettings.queryKey], updatedSettings);
    },
  });
};
