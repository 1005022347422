import { I18n } from "@meraki/core/i18n";
import { RUSAGE_KEY } from "@meraki/shared/api";
import { memo } from "react";

import { processClientRusage } from "~/lib/UsageUtils";
import SummaryList from "~/shared/components/SummaryList";
import UsageRow, { UsageRowProps } from "~/shared/rows/UsageRow.go";
import { ClientApplicationUsage } from "~/shared/types/ApplicationUsages";
import { ApplicationCounterSet } from "~/shared/types/CounterSets";

interface ClientApplicationUsageCardProps {
  applicationUsages: ClientApplicationUsage;
  counterSets: ApplicationCounterSet;
  loading: boolean;
}

const ClientApplicationUsageCard = memo<ClientApplicationUsageCardProps>(
  function ClientApplicationUsageCard(props) {
    const { applicationUsages, counterSets, loading } = props;
    // Magic number, used to grab the usage data from the payload correctly

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const set = counterSets?.[RUSAGE_KEY];
    if (!applicationUsages || set?.rules == null) {
      return null;
    }
    const entries = processClientRusage(applicationUsages, set) as UsageRowProps[];

    const renderRow = (rowData: UsageRowProps) => <UsageRow {...rowData} />;
    return (
      <SummaryList
        heading={I18n.t("CLIENT_USAGE.HEADING")}
        subheading={I18n.t("CLIENT_DETAILS.APPLICATIONS.SUBTITLE", {
          client_word: I18n.t("DEVICE_WORD"),
        })}
        contentRows={entries}
        customRenderRow={renderRow}
        disableBottomBorder
        loading={loading}
      />
    );
  },
);

export default ClientApplicationUsageCard;
