import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/enterprise/constants/Fonts";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";

const NewSupportCasesButton = () => {
  const navigation = useNavigation();

  return (
    <RoundedButton
      buttonType={ButtonType.primary}
      onPress={() => navigation.navigate("SearchSubject")}
      screenStyles={styles.openCaseButton}
      fontSize={FONT_SIZES.default}
      testID="OPEN_CASE_BUTTON"
    >
      {I18n.t("SUPPORT_CENTER.OPEN_CASE_BUTTON")}
    </RoundedButton>
  );
};

const styles = StyleSheet.create({
  openCaseButton: {
    paddingVertical: SPACING.meager,
    paddingHorizontal: SPACING.small,
  },
});

export default NewSupportCasesButton;
