import { I18n } from "@meraki/core/i18n";
import Slider from "@react-native-community/slider";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import PickerModalRow from "~/go/rows/PickerModalRow";
import {
  getChannelOptions,
  getFiveGhzChannelWidthOptions,
  getPowerLabel,
  getRadioPowerLimits,
} from "~/lib/RadioSettingsUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { CloseButton } from "~/shared/navigation/Buttons";
import {
  RadioSettingsBands,
  RadioSettingsOnBand,
  RadioSettingsPowerLimits,
} from "~/shared/types/RadioSettingsTypes";

import { NetworkScreensPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "RadioSettings">;

interface RadioSettingsState {
  showChannelModal: boolean;
  showWidthModal: boolean;
  powerLimits: RadioSettingsPowerLimits;
}

export default class RadioSettingsScreen extends PureComponent<
  Props,
  RadioSettingsState & RadioSettingsOnBand
> {
  constructor(props: Props) {
    super(props);
    const { band, radioSettings } = props;
    const powerLimits = getRadioPowerLimits(band);

    this.state = {
      showChannelModal: false,
      showWidthModal: false,
      powerLimits,
      ...radioSettings,
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation, band, updateSettings } = this.props;

    navigation.setOptions({
      headerTitle:
        band === RadioSettingsBands.twoFourGhzSettings
          ? I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.LABEL")
          : I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.LABEL"),
      headerLeft: () => (
        <CloseButton
          onPress={() => {
            const { channel, channelWidth, targetPower } = this.state;
            const settings =
              band === RadioSettingsBands.twoFourGhzSettings
                ? { channel, targetPower }
                : { channel, channelWidth, targetPower };
            updateSettings(band, settings);
            navigation.goBack();
          }}
        />
      ),
    });
  }

  toggleChannelModal = () => {
    const { showChannelModal } = this.state;
    this.setState({ showChannelModal: !showChannelModal });
  };

  toggleWidthModal = () => {
    const { showWidthModal } = this.state;
    this.setState({ showWidthModal: !showWidthModal });
  };

  setChannel = (channel?: string | number | null) => {
    this.setState({ channel: channel as number | null });
    this.toggleChannelModal();
  };

  setChannelWidth = (channelWidth?: string | number | null) => {
    this.setState({ channelWidth: channelWidth as number | null });
    this.toggleWidthModal();
  };

  setTargetPower = (targetPower?: number | null) => {
    const { powerLimits } = this.state;
    this.setState({
      targetPower: targetPower && targetPower > powerLimits.max ? null : targetPower,
    });
  };

  render() {
    const { band } = this.props;
    const { showChannelModal, showWidthModal, powerLimits, channel, channelWidth, targetPower } =
      this.state;
    const isFiveGhz = band === RadioSettingsBands.fiveGhzSettings;

    const channelOptions = getChannelOptions(band);
    const selectedChannelOption = channelOptions.find(({ value }) => channel === value);

    let channelWidthOptions, selectedChannelWidthOption;
    if (isFiveGhz) {
      channelWidthOptions = getFiveGhzChannelWidthOptions();
      selectedChannelWidthOption = channelWidthOptions.find(({ value }) => channelWidth === value);
    }

    const renderableTargetPower = targetPower == null ? powerLimits.max + 1 : targetPower;

    return (
      <FullScreenContainerView withScroll>
        <PickerModalRow
          selectedValue={selectedChannelOption?.value}
          label={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL.LABEL")}
          subtitle={selectedChannelOption?.label}
          visible={showChannelModal}
          title={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL.TITLE")}
          items={channelOptions}
          screenStyles={styles.pickerStyle}
          onValueSelect={this.setChannel}
          onExit={this.toggleChannelModal}
          onPress={this.toggleChannelModal}
          disableBottomBorder
        />
        {isFiveGhz && channelWidthOptions && (
          <PickerModalRow
            selectedValue={selectedChannelWidthOption?.value}
            label={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL_WIDTH.LABEL")}
            subtitle={selectedChannelWidthOption?.label}
            visible={showWidthModal}
            title={I18n.t("RADIO_SETTINGS.HARDWARE.CHANNEL_WIDTH.TITLE")}
            items={channelWidthOptions}
            screenStyles={styles.pickerStyle}
            onValueSelect={this.setChannelWidth}
            onExit={this.toggleWidthModal}
            onPress={this.toggleWidthModal}
            disableBottomBorder
          />
        )}
        <View style={styles.sliderHeader}>
          <MkiText textStyle="default">
            {I18n.t("RADIO_SETTINGS.HARDWARE.TARGET_POWER.LABEL")}
          </MkiText>
        </View>
        <View style={styles.sliderContainer}>
          <Slider
            maximumValue={powerLimits.max + 1}
            minimumValue={powerLimits.min}
            onValueChange={this.setTargetPower}
            step={1}
            value={renderableTargetPower}
            minimumTrackTintColor={MkiColors.goPurple}
          />
          <MkiText textStyle="secondary">{getPowerLabel(targetPower)}</MkiText>
        </View>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  pickerStyle: {
    paddingHorizontal: SPACING.large,
  },
  sliderHeader: {
    marginHorizontal: SPACING.large,
    marginTop: SPACING.default,
  },
  sliderContainer: {
    marginHorizontal: SPACING.large,
  },
});
