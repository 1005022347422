import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

interface MkiRowSeparatorProps {
  withHorizontalMargins?: boolean;
  withCustomWidth?: boolean;
  withTableStyle?: boolean;
}

// This is a very simple gray, hairline width separator. You can use it just about anywhere you need a little row separation.

const MkiRowSeparator = ({
  withHorizontalMargins,
  withCustomWidth,
  withTableStyle,
}: MkiRowSeparatorProps) => {
  const backgroundColor = themeColors(useTheme()).border.borderColor;
  const styleSheet = withCustomWidth ? styles.custom : styles.spacer;
  const style = {
    ...styleSheet,
    backgroundColor,
    marginHorizontal: withHorizontalMargins ? SPACING.default : 0,
  };

  if (withTableStyle) {
    return <View style={[styles.tableSeparator, { backgroundColor }]} />;
  }
  return <View style={style} />;
};

const styles = StyleSheet.create({
  spacer: {
    height: 1,
    borderBottomWidth: appSelect({
      go: StyleSheet.hairlineWidth,
      enterprise: undefined,
    }),
  },
  custom: {
    height: 1,
    borderBottomWidth: 0.1,
    marginBottom: SPACING.small,
  },
  tableSeparator: {
    height: StyleSheet.hairlineWidth,
    marginHorizontal: appSelect({
      enterprise: 0,
      go: SPACING.default,
    }),
  },
});

export default MkiRowSeparator;
