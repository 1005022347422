import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

type Props = {
  onPrimaryPress: () => void;
  primaryButtonText?: string;
  titleStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  title?: string;
  subtitle?: string;
};

const ConfirmAlert = (props: Props) => {
  const { primaryButtonText, onPrimaryPress, loading, title, titleStyle, subtitle } = props;
  const subtitleStyles: TextStyle[] = [styles.subtitle];
  if (title && subtitle) {
    subtitleStyles.push(styles.smallSpacer);
  } else if (!title && subtitle) {
    subtitleStyles.push(styles.largeSpacer);
  } else if (title && !subtitle) {
    subtitleStyles.push(styles.largeSpacer);
  }
  const titleElement = title ? (
    <MkiText textStyle="subheading" screenStyles={[styles.subheading, titleStyle]}>
      {title}
    </MkiText>
  ) : null;
  const subtitleElement = subtitle ? (
    <MkiText textStyle="default" screenStyles={subtitleStyles}>
      {subtitle}
    </MkiText>
  ) : null;
  return (
    <View style={styles.container}>
      <MerakiIcon name="check-circle" size="l" color={MkiColors.confirmAlertSuccess} />
      {titleElement}
      {subtitleElement}
      <View style={styles.buttonContainer}>
        <RoundedButton
          onPress={onPrimaryPress}
          screenStyles={styles.primaryButton}
          loading={loading}
          testID={"CONFIRM_BUTTON"}
        >
          {primaryButtonText}
        </RoundedButton>
      </View>
    </View>
  );
};

ConfirmAlert.defaultProps = {
  loading: false,
  title: undefined,
  subtitle: undefined,
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  primaryButton: {
    marginTop: SPACING.default,
    paddingHorizontal: SPACING.extraLarge,
  },
  subheading: {
    marginTop: SPACING.default,
  },
  subtitle: {
    color: MkiColors.formFieldLabel,
  },
  smallSpacer: {
    marginTop: SPACING.small,
  },
  largeSpacer: {
    marginTop: SPACING.default,
  },
});

export default ConfirmAlert;
