import { analytics } from "@meraki/core/firebase";
import { InlineNotificationProps, Notification, Text } from "@meraki/magnetic/components";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { isString } from "lodash";
import { createElement, isValidElement, ReactNode, useCallback, useMemo } from "react";
import { Linking, Text as RNText } from "react-native";
import rehypeParse from "rehype-parse";
import rehypeReact from "rehype-react";
import rehypeSanitize from "rehype-sanitize";
import unified from "unified";

export type InlineNotificationWithHtmlProps = {
  messageWithPossibleHtml: string;
} & Pick<InlineNotificationProps, "status">;

type ButtonWrapperProps = {
  href?: string;
  children?: ReactNode;
};
function ButtonWrapper({ href, children }: ButtonWrapperProps) {
  const theme = useMagneticTheme();
  const [child] = Array.isArray(children) ? children : [children];

  const handlePress = useCallback(() => {
    analytics.logEvent("app_errorbanner_redirect");
    Linking.openURL(href as string);
  }, [href]);

  // If there's not a full url href, then just return the text to be rendered in the parent MkiText element.
  // Some of the alert href's are relative so we're not supporting those right now.
  if ((isString(href) && !/^http/.test(href)) || !isString(href)) {
    return child;
  }

  return (
    <RNText style={{ color: theme.color.interact.text.base }} onPress={handlePress}>
      {child}
    </RNText>
  );
}
// Shh.... nothing to see here!
// For real though... Text complains if you pass anything other then a string or another Text component to it.
// This is for a really good reason... to drive consistency and not allow us to deviate from Magnetic as much.
// This entire component here in this file though? Nothing about it is consistent since we are rendering HTML to React Native...
ButtonWrapper.magneticTextWorkaroundMagic = true;

/**
 * If you find yourself using this component be _very very_ sure you want to be here.
 * This component exists due to the fact that device alerts are currently (as of 8/18/23) coming back
 * with inline HTML. This takes care of rendering that for us.
 */
export function InlineNotificationWithHtml({
  messageWithPossibleHtml,
  status,
}: InlineNotificationWithHtmlProps) {
  const parsed = useMemo(() => {
    const processor = unified()
      .use(rehypeParse)
      .use(rehypeSanitize, { tagNames: ["a"] })
      .use(rehypeReact, {
        components: { a: ButtonWrapper },
        createElement,
        Fragment: Text,
      });

    const parsed = processor.processSync(messageWithPossibleHtml).result as ReactNode;

    if (typeof parsed === "object" && isValidElement(parsed)) {
      return parsed;
    }

    return null;
  }, [messageWithPossibleHtml]);

  if (!parsed) {
    return null;
  }

  return <Notification.Inline status={status}>{parsed}</Notification.Inline>;
}
