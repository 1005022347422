import { CounterSets, NetworkUsageblocks } from "@meraki/shared/api";

import { BAR_GRAPH_COLOR_PROPS } from "./BarGraphHelper";

type CounterSetRule = {
  color?: string | undefined;
  group?: string | undefined;
  guid: string;
  name: string;
};

export const ApplicationTypes = {
  miscSecWeb: "Miscellaneous secure web",
  miscWeb: "Miscellaneous web",
  other: "Other",
};

export type Options = {
  noGroup?: boolean;
  numSections?: number;
};

export type RUsageApplicationData = {
  label: string;
  totalUsage: number;
  group?: string;
  hiUse: {
    hiUseAppId?: string;
    hiUseApp?: string;
    hiUseAppData?: number;
  };
  color?: (typeof BAR_GRAPH_COLOR_PROPS)[number];
};

type ApplicationRules = {
  [_: string]: RUsageApplicationData;
};

export const processRusage = (
  rusage: { [_: string]: number[] },
  rules: { [_: string]: CounterSetRule },
  options: Options = {},
): RUsageApplicationData[] => {
  const { noGroup, numSections } = options;

  // 1. combine usages into application groups if they exist, otherwise by name
  const applications: ApplicationRules = Object.keys(rusage).reduce(
    (result: ApplicationRules, key: string) => {
      const value = rusage[key] ?? [0, 0];
      const splitKey = key.split(",");
      const workingKey = (splitKey.length > 1 ? splitKey[0] : key) ?? "";
      const rule = rules?.[workingKey] || { group: "", name: "" };
      let label = "group" in rule && !noGroup ? (rule.group as string) : rule?.name;

      if (splitKey.length > 1) {
        label = `${label} - ${splitKey[splitKey.length - 1]}`;
      }
      const usage = (value[0] || 0) + (value[1] || 0);
      if (label in result) {
        const appRule: RUsageApplicationData = result[label] as RUsageApplicationData;
        appRule.totalUsage += usage;
      } else {
        result[label] = {
          label,
          totalUsage: usage,
          group: rule.group,
          hiUse: {
            hiUseAppId: workingKey,
            hiUseApp: rule.name,
            hiUseAppData: usage,
          },
        };
      }

      return result;
    },
    {},
  );

  // 2. sort usages into an array by descending usage
  let sortedApplications = Object.values(applications).sort((a, b) => b.totalUsage - a.totalUsage);

  // 3. combine bottom groups into one "other" if needed
  if (numSections !== undefined && numSections > 0 && sortedApplications.length > numSections) {
    const otherUsage = sortedApplications
      .slice(numSections - 1)
      .reduce((total, app) => total + app.totalUsage, 0);

    sortedApplications = sortedApplications
      .slice(0, numSections - 1)
      .concat([{ label: ApplicationTypes.other, totalUsage: otherUsage, hiUse: {} }]);
  }

  return sortedApplications;
};

type FormattedNetUseblocksType = {
  [_: string]: {
    download: number;
    upload: number;
  } & CounterSetRule;
};

export const formatUseNetblocksRusageWithCounterSet = (
  networkUseBlocksResponse: NetworkUsageblocks | undefined,
  counterSetResponse: CounterSets | undefined,
) => {
  if (!networkUseBlocksResponse || !counterSetResponse) {
    return {};
  }

  /*
    19 = "Application"
    20 = "Ports"
    21 = "HTTP content"
    31 = "IP version"
    32 = "Trusted Traffic"
  */

  const ret: FormattedNetUseblocksType = {};
  const currentNetworkUseblock = networkUseBlocksResponse.rusage?.["19"];
  const currentCounterset = counterSetResponse["19"];

  if (!currentNetworkUseblock || !currentCounterset) {
    return ret;
  }

  for (const ruleKey of Object.keys(currentNetworkUseblock)) {
    const [download, upload] = currentNetworkUseblock[ruleKey] ?? [0, 0];
    const ruleData = currentCounterset.rules?.[ruleKey] ?? { name: "Unknown", guid: "0" };

    ret[ruleData.name] = { download, upload, ...ruleData };
  }
  return ret;
};
