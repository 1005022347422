import { I18n } from "@meraki/core/i18n";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import {
  ClientType,
  useClients,
  useUmbrellaTopIps,
  useUmbrellaTotalRequests,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useUmbrellaAuthTokenStore } from "../screens/NetworkMonitorScreen";
import { calculateHighEventThreshold } from "../utils/UmbrellaUtils";

export function SecurityMeasuresCard() {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();
  const { accessToken } = useUmbrellaAuthTokenStore();
  const timespan = useGlobalTimespan();
  const networkId = useCurrentNetworkId();

  const { data: totalRequests, isLoading: totalRequestsLoading } = useUmbrellaTotalRequests({
    accessToken,
    timespan,
  });
  const { data: topIps, isLoading: topIpsLoading } = useUmbrellaTopIps({
    accessToken: accessToken,
    timespan,
  });
  const { data: clients, isLoading: clientsLoading } = useClients({ networkId, timespan });

  const threshold = calculateHighEventThreshold(topIps?.data.map((ip) => ip.count) ?? []);
  const highUsageIps: string[] = [];
  if (threshold != null) {
    topIps?.data?.forEach(({ count, ip }) => {
      if (count >= threshold && clients?.some((client) => client.hashedIp4 === ip)) {
        highUsageIps.push(ip);
      }
    });
  }

  const filterByIP = (clients: ClientType[]) =>
    clients.filter((client) => highUsageIps?.includes(client.hashedIp4));

  return (
    <Card
      loading={totalRequestsLoading || topIpsLoading || clientsLoading}
      loadingContentHeight={60}
      testID="SECURITY_MEASURES_CARD"
    >
      <Card.Header title={I18n.t("SECURITY_MEASURES_SUMMARY.HEADING")} />
      {totalRequests &&
      highUsageIps &&
      (totalRequests?.data.count > 0 || highUsageIps.length > 0) ? (
        <Card.Content flexDirection="row" gap="sm">
          <Card.Well
            flex={1}
            onPress={() => {
              navigation.navigate("SecurityEventsList");
            }}
          >
            <Heading size="h2">{totalRequests?.data.count}</Heading>
            <Text weight="bold">
              {I18n.t("SECURITY_MEASURES_SUMMARY.GOv3.SECURITY_EVENTS", {
                count: totalRequests?.data.count,
              })}
            </Text>
          </Card.Well>
          <Card.Well
            flex={1}
            onPress={() => {
              if (highUsageIps.length === 0) {
                return;
              }

              navigation.navigate("ClientList", { customFilter: filterByIP });
            }}
          >
            <Heading size="h2">{highUsageIps.length}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text weight="bold">
                {I18n.t("SECURITY_MEASURES_SUMMARY.GOv3.HIGH_EVENT_DEVICE", {
                  count: highUsageIps.length,
                })}
              </Text>
              <Status status="warning" />
            </Box>
          </Card.Well>
        </Card.Content>
      ) : (
        <Card.Content>
          <Card.Well>
            <Text>{I18n.t("SECURITY_MEASURES_SUMMARY.NO_SECURITY_EVENTS")}</Text>
          </Card.Well>
        </Card.Content>
      )}
    </Card>
  );
}
