import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { ReactNode } from "react";

import { DebuggingToolsProvider } from "../DebuggingToolsContext";
import { DebuggingToolsButton } from "./DebuggingToolsButton";

export type DebuggingToolsProps = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
  children: ReactNode;
};

export function DebuggingTools({ navigationRef, children }: DebuggingToolsProps) {
  return (
    <DebuggingToolsProvider navigationRef={navigationRef}>
      {children}
      <DebuggingToolsButton />
    </DebuggingToolsProvider>
  );
}
