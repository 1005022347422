import { Icon } from "@meraki/magnetic/icons";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useState } from "react";
import { useWindowDimensions } from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import ReactNativeHapticFeedback from "react-native-haptic-feedback";
import Animated, { runOnJS, useAnimatedStyle, useSharedValue } from "react-native-reanimated";

import { useDebuggingTools } from "../DebuggingToolsContext";
import { DebuggingToolsComplexView } from "./DebuggingToolsComplexView";

export function DebuggingToolsButton() {
  const theme = useMagneticTheme();
  const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>();

  const windowDimensions = useWindowDimensions();

  const [simpleView, setSimpleView] = useState(true);
  const toggleSimpleView = useCallback(() => {
    setSimpleView(!simpleView);
  }, [simpleView, setSimpleView]);

  const positionX = useSharedValue(windowDimensions.width - 70);
  const positionY = useSharedValue(windowDimensions.height - 130);

  const debuggerWidth = useSharedValue(0);
  const debuggerHeight = useSharedValue(0);

  const panGesture = Gesture.Pan().onUpdate((e) => {
    positionX.value = e.absoluteX - debuggerWidth.value / 2;
    positionY.value = e.absoluteY - debuggerHeight.value / 2;
  });

  const tapGesture = Gesture.Tap().onEnd(() => {
    // TODO: DM-4996 - Build debug screen and have it open in this situation.
    runOnJS(navigation.navigate)({ name: "DebuggingView", params: {} });
  });

  const longPressGesture = Gesture.LongPress().onStart(() => {
    runOnJS(ReactNativeHapticFeedback.trigger)("impactLight");
    runOnJS(toggleSimpleView)();
  });

  const gestures = Gesture.Simultaneous(panGesture, longPressGesture, tapGesture);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateX: positionX.value }, { translateY: positionY.value }],
    }),
    [positionX, positionY],
  );

  const { enabled } = useDebuggingTools();

  if (!enabled) {
    return null;
  }

  return (
    <GestureDetector gesture={gestures}>
      <Animated.View
        onLayout={(e) => {
          debuggerWidth.value = e.nativeEvent.layout.width;
          debuggerHeight.value = e.nativeEvent.layout.height;
        }}
        style={[
          {
            position: "absolute",
            top: 0,
            left: 0,
            maxWidth: windowDimensions.width / 2,
            backgroundColor: theme.color.default.bg.weak.base,
            borderRadius: 8,
            padding: 8,
            borderColor: theme.color.default.border.strong.base,
            borderWidth: 2,
          },
          animatedStyle,
        ]}
      >
        {simpleView && <Icon name="Bug" />}
        {!simpleView && <DebuggingToolsComplexView />}
      </Animated.View>
    </GestureDetector>
  );
}
