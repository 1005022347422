import { I18n } from "@meraki/core/i18n";
import { Card, Heading, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useGetLicenses } from "@meraki/shared/api";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { groupBy } from "lodash";

const UMB_LICENSE_TYPE = "GX-UMB";
const filterList = [UMB_LICENSE_TYPE];

export function LicenseSummaryCard() {
  const organizationId = useCurrentOrganizationId();

  const { data: licenses = [] } = useGetLicenses({ organizationId });

  const filteredLicenses = licenses?.filter((license) => {
    return filterList.includes(license.licenseType);
  });

  const numQueuedLicenses = licenses?.reduce(
    (sum, license) => sum + license.permanentlyQueuedLicenses.length,
    0,
  );

  const licenseByType = groupBy(filteredLicenses, "state");

  return (
    <Card testID="LICENSE_SUMMARY">
      <Card.Header title={I18n.t("MONITOR.LICENSE.LICENSE")} />
      {licenses && licenses.length > 0 ? (
        <Card.Content flexDirection="row" gap="xs">
          <Card.Well flex={1}>
            <Heading size="h2">{licenseByType["active"]?.length ?? 0}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text size="p3" weight="semiBold">
                {I18n.t("MONITOR.LICENSE.ACTIVE")}
              </Text>
            </Box>
          </Card.Well>
          <Card.Well flex={1}>
            <Heading size="h2">{licenseByType["unused"]?.length ?? 0}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text size="p3" weight="semiBold">
                {I18n.t("MONITOR.LICENSE.UNUSED")}
              </Text>
            </Box>
          </Card.Well>
          <Card.Well flex={1}>
            <Heading size="h2">{numQueuedLicenses}</Heading>
            <Box flexDirection="row" alignItems="center" gap="2xs">
              <Text size="p3" weight="semiBold">
                {I18n.t("MONITOR.LICENSE.QUEUED")}
              </Text>
            </Box>
          </Card.Well>
        </Card.Content>
      ) : (
        <Text>{I18n.t("MONITOR.LICENSE.NONE")}</Text>
      )}
    </Card>
  );
}
