import { I18n } from "@meraki/core/i18n";
import {
  ClientVPNEventLogScreen,
  ClientVPNInviteGuestScreen,
  ClientVPNViewGuestScreen,
} from "@meraki/go/vpn";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { SettingsStackProps } from "~/go/navigation/Types";
import AboutScreen from "~/go/screens/AboutScreen";
import AccountScreen from "~/go/screens/AccountScreen";
import AdminAndAuthUserScreen from "~/go/screens/admins/AdminAndAuthUserScreen";
import InviteAdministratorScreen from "~/go/screens/admins/InviteAdministratorScreen";
import ViewAdministratorScreen from "~/go/screens/admins/ViewAdministratorScreen";
import AlertsScreen from "~/go/screens/AlertsScreen";
import AlertsSettingsScreen from "~/go/screens/AlertsSettingsScreen";
import CreateNetworkScreen from "~/go/screens/CreateNetworkScreen";
import CreateOrganizationScreen from "~/go/screens/CreateOrganizationScreen";
import LSPPasswordScreen from "~/go/screens/LSPPasswordScreen";
import ManualDeviceInputScreen from "~/go/screens/ManualDeviceInputScreen";
import NetworkChooseScreen from "~/go/screens/NetworkChooseScreen";
import OrgDeleteScreen from "~/go/screens/OrgDeleteScreen";
import PasswordResetScreen from "~/go/screens/PasswordResetScreen";
import PortForwardingRuleScreen from "~/go/screens/PortForwardingRuleScreen";
import PortForwardingScreen from "~/go/screens/PortForwardingScreen";
import SettingsScreen from "~/go/screens/SettingsScreen";
import { SwitchQoSRuleScreen } from "~/go/screens/SwitchQoSRuleScreen";
import { SwitchQoSRulesListScreen } from "~/go/screens/SwitchQoSRulesListScreen";
import VlansListScreen from "~/go/screens/VlansListScreen";
import WirelessSettingsScreen from "~/go/screens/WirelessSettingsScreen";
import WishScreen from "~/go/screens/WishScreen";
import OpenSourceLicensesScreen from "~/shared/screens/OpenSourceLicensesScreen";
import OrgChooseScreen from "~/shared/screens/OrgChooseScreen";
import ThemeSelectScreen from "~/shared/screens/ThemeSelectScreen";

import ClientVPNSettingsScreenWrapper from "../screens/ClientVPNSettingsScreenWrapper";
import DeviceIpAssignmentScreen from "../screens/DeviceIpAssignmentScreen";
import SiteToSiteVPNSettingsScreenWrapper from "../screens/SiteToSiteVPNSettingsScreenWrapper";
import SpeedTestScreen from "../screens/SpeedTestScreen";
import SupportCaseDetailsScreen from "../screens/SupportCaseDetailsScreen";
import SupportCasesListScreen from "../screens/SupportCasesListScreen";
import ToggleLocationAnalyticsScreen from "../screens/ToggleLocationAnalyticsScreen";
import TrackedClientsScreen from "../screens/TrackedClientsScreen";
import WhatsNewDetailScreen from "../screens/whatsNew/WhatsNewDetailScreen";
import WhatsNewListScreen from "../screens/whatsNew/WhatsNewListScreen";
import { hardwareScreens } from "./HardwareScreens";
import { useDefaultScreenOptions } from "./Options";
import { twoFactorScreens } from "./TwoFactorScreens";
import { umbrellaScreens } from "./UmbrellaScreens";

const ForwardedCreateOrganizationScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "CreateOrganization">
  >()(CreateOrganizationScreen);
const ForwardedInviteAdministratorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "InviteAdministrator">
  >()(InviteAdministratorScreen);
const ForwardedViewAdministratorScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "ViewAdministrator">
  >()(ViewAdministratorScreen);
const ForwardedSettingsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "Settings">>()(
    SettingsScreen,
  );
const ForwardedWhatsNewListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "WhatsNewList">>()(
    WhatsNewListScreen,
  );
const ForwardedWhatsNewDetailScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "WhatsNewDetail">>()(
    WhatsNewDetailScreen,
  );
const ForwardedToggleLocationAnalyticsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<SettingsStackProps, "ToggleLocationAnalytics">
>()(ToggleLocationAnalyticsScreen);
const ForwardedSpeedTestScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "SpeedTest">>()(
    SpeedTestScreen,
  );
const ForwardedAccountScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "Account">>()(
    AccountScreen,
  );
const ForwardedAlertsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "Alerts">>()(
    AlertsScreen,
  );
const ForwardedAlertsSettingsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "AlertsSettings">>()(
    AlertsSettingsScreen,
  );
const ForwardedTrackedClientsScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "TrackedClients">>()(
    TrackedClientsScreen,
  );
const ForwardedAdminAndAuthUserScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "AdminAndAuthUser">
  >()(AdminAndAuthUserScreen);
const ForwardedOrgDeleteScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "OrgDelete">>()(
    OrgDeleteScreen,
  );
const ForwardedOrgChooseScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "OrgChoose">>()(
    OrgChooseScreen,
  );
const ForwardedCreateNetworkScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "CreateNetwork">>()(
    CreateNetworkScreen,
  );
const ForwardedNetworkChooseScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "NetworkChoose">>()(
    NetworkChooseScreen,
  );
const ForwardedAboutScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "About">>()(
    AboutScreen,
  );
const ForwardedSupportCasesListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "SupportCasesList">
  >()(SupportCasesListScreen);
const ForwardedSupportCaseDetailsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "SupportCaseDetails">
  >()(SupportCaseDetailsScreen);
const ForwardedWishScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "Wish">>()(
    WishScreen,
  );
const ForwardedThemeSelectScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "ThemeSelect">>()(
    ThemeSelectScreen,
  );
const ForwardedWirelessSettingsScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "WirelessSettings">
  >()(WirelessSettingsScreen);
const ForwardedLSPPasswordScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "LSPPassword">>()(
    LSPPasswordScreen,
  );
const ForwardedClientVPNSettingsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<SettingsStackProps, "ClientVPNSettingsWrapper">
>()(ClientVPNSettingsScreenWrapper);
const ForwardedSiteToSiteVPNSettingsScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<SettingsStackProps, "SiteToSiteVPNSettingsWrapper">
>()(SiteToSiteVPNSettingsScreenWrapper);
const ForwardedVlansListScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "VlansList">>()(
    VlansListScreen,
  );
const ForwardedPortForwardingScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "PortForwarding">>()(
    PortForwardingScreen,
  );
const ForwardedOpenSourceLicensesScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "OpenSourceLicenses">
  >()(OpenSourceLicensesScreen);
const ForwardedPasswordResetScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "PasswordReset">>()(
    PasswordResetScreen,
  );
const ForwardedPortForwardingRuleScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "PortForwardingRule">
  >()(PortForwardingRuleScreen);
const ForwardedDeviceIpAssignmentScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "DeviceIpAssignment">
  >()(DeviceIpAssignmentScreen);
const ForwardedManualDeviceInputScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "ManualDeviceInput">
  >()(ManualDeviceInputScreen);
const ForwardedSwitchQoSRuleScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "SwitchQoSRule">>()(
    SwitchQoSRuleScreen,
  );
const ForwardedSwitchQoSRulesListScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "SwitchQoSRulesList">
  >()(SwitchQoSRulesListScreen);
const ForwardedClientVPNViewGuestScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<SettingsStackProps, "ViewGuest">>()(
    ClientVPNViewGuestScreen,
  );
const ForwardedClientVPNInviteGuestScreen = withForwardedNavigationParams<
  ForwardedStackScreenProps<SettingsStackProps, "InviteGuest">
>()(ClientVPNInviteGuestScreen);
const ForwardedClientVPNEventLogScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<SettingsStackProps, "ClientVPNEventLog">
  >()(ClientVPNEventLogScreen);

const Stack = createNativeStackNavigator<SettingsStackProps>();

const SettingsStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="Settings"
        component={ForwardedSettingsScreen}
        options={{ headerTitle: I18n.t("ROOT_TITLES.SETTINGS") }}
      />
      <Stack.Screen
        name="WhatsNewList"
        component={ForwardedWhatsNewListScreen}
        options={{ headerTitle: I18n.t("WHATS_NEW.TITLE") }}
      />
      <Stack.Screen name="WhatsNewDetail" component={ForwardedWhatsNewDetailScreen} />
      {umbrellaScreens(Stack)}
      <Stack.Screen
        name="ToggleLocationAnalytics"
        component={ForwardedToggleLocationAnalyticsScreen}
        options={{ headerTitle: I18n.t("TOGGLE_LOCATION_ANALYTICS.TITLE") }}
      />
      <Stack.Screen
        name="SpeedTest"
        component={ForwardedSpeedTestScreen}
        options={{ headerTitle: I18n.t("SETTINGS.USAGE.SPEED_TEST.TITLE") }}
      />
      <Stack.Screen
        name="Account"
        component={ForwardedAccountScreen}
        options={{ headerTitle: I18n.t("ROOT_TITLES.ACCOUNT") }}
      />
      <Stack.Screen
        name="PasswordReset"
        component={ForwardedPasswordResetScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("PASSWORD_RESET.TITLE") }}
      />
      <Stack.Screen
        name="AlertsSettings"
        component={ForwardedAlertsSettingsScreen}
        options={{ headerTitle: I18n.t("SETTINGS.NOTIFICATIONS") }}
      />
      <Stack.Screen
        name="Alerts"
        component={ForwardedAlertsScreen}
        options={{ headerTitle: I18n.t("ROOT_TITLES.ALERTS") }}
      />
      <Stack.Screen
        name="TrackedClients"
        component={ForwardedTrackedClientsScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("TRACK_CLIENT.PAGE_TITLE"),
        }}
      />
      <Stack.Screen
        name="AdminAndAuthUser"
        component={ForwardedAdminAndAuthUserScreen}
        options={{ headerTitle: I18n.t("SETTINGS.ADMIN.TITLE") }}
      />
      <Stack.Screen
        name="ViewAdministrator"
        component={ForwardedViewAdministratorScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("ADMIN.TITLE.VIEW") }}
      />
      <Stack.Screen
        name="InviteAdministrator"
        component={ForwardedInviteAdministratorScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("ADMIN.TITLE.INVITE") }}
      />
      <Stack.Screen
        name="ViewGuest"
        component={ForwardedClientVPNViewGuestScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("GUEST.VIEW") }}
      />
      <Stack.Screen
        name="InviteGuest"
        component={ForwardedClientVPNInviteGuestScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("GUEST.INVITE.INVITE") }}
      />
      <Stack.Screen
        name="ClientVPNEventLog"
        component={ForwardedClientVPNEventLogScreen}
        options={{ headerTitle: I18n.t("CLIENT_VPN.EVENT_LOG.HEADER") }}
      />
      <Stack.Screen
        name="OrgDelete"
        component={ForwardedOrgDeleteScreen}
        options={{ headerTitle: I18n.t("DELETE_LOCATION.TITLE") }}
      />
      <Stack.Screen
        name="OrgChoose"
        component={ForwardedOrgChooseScreen}
        options={{ headerTitle: I18n.t("SETTINGS.ADVANCED.CHANGE_ORG") }}
      />
      <Stack.Screen
        name="CreateOrganization"
        component={ForwardedCreateOrganizationScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SETTINGS.ADVANCED.CREATE_ORG.TITLE"),
        }}
      />
      <Stack.Screen
        name="WirelessSettings"
        component={ForwardedWirelessSettingsScreen}
        options={{ headerTitle: I18n.t("SETTINGS.ADVANCED.SITE_WIDE_WIRELESS.TITLE") }}
      />
      <Stack.Screen
        name="CreateNetwork"
        component={ForwardedCreateNetworkScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("CREATE_SITE.TITLE") }}
      />
      <Stack.Screen
        name="NetworkChoose"
        component={ForwardedNetworkChooseScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="SupportCasesList"
        component={ForwardedSupportCasesListScreen}
        options={{ headerTitle: I18n.t("SUPPORT_CENTER.TITLE") }}
      />
      <Stack.Screen
        name="SupportCaseDetails"
        component={ForwardedSupportCaseDetailsScreen}
        options={{ headerTitle: I18n.t("SUPPORT_CENTER.TITLE") }}
      />
      <Stack.Screen
        name="Wish"
        component={ForwardedWishScreen}
        options={{ presentation: "fullScreenModal" }}
      />
      <Stack.Screen
        name="About"
        component={ForwardedAboutScreen}
        options={{ headerTitle: I18n.t("SETTINGS.GENERAL.ABOUT.TITLE") }}
      />
      <Stack.Screen
        name="OpenSourceLicenses"
        component={ForwardedOpenSourceLicensesScreen}
        options={{ headerTitle: I18n.t("OPEN_SOURCE") }}
      />
      <Stack.Screen
        name="ThemeSelect"
        component={ForwardedThemeSelectScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("THEME_SELECT.TITLE") }}
      />
      <Stack.Screen
        name="LSPPassword"
        component={ForwardedLSPPasswordScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("LSP_PASSWORD.TITLE") }}
      />
      <Stack.Screen
        name="ClientVPNSettingsWrapper"
        component={ForwardedClientVPNSettingsScreen}
        options={{ headerTitle: I18n.t("CLIENT_VPN.TITLE") }}
      />
      <Stack.Screen
        name="SiteToSiteVPNSettingsWrapper"
        component={ForwardedSiteToSiteVPNSettingsScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SITE_TO_SITE_VPN.TITLE") }}
      />
      <Stack.Screen
        name="VlansList"
        component={ForwardedVlansListScreen}
        options={{ headerTitle: I18n.t("SUBNETS_VLANS.TITLE") }}
      />
      <Stack.Screen
        name="PortForwarding"
        component={ForwardedPortForwardingScreen}
        options={{ headerTitle: I18n.t("PORT_FORWARDING.TITLE") }}
      />
      <Stack.Screen
        name="PortForwardingRule"
        component={ForwardedPortForwardingRuleScreen}
        options={{ headerTitle: I18n.t("PORT_FORWARDING_RULE.TITLE") }}
      />

      <Stack.Screen
        name="DeviceIpAssignment"
        component={ForwardedDeviceIpAssignmentScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("DEVICE_IP_ASSIGNMENT.TITLE"),
        }}
      />
      <Stack.Screen
        name="ManualDeviceInput"
        component={ForwardedManualDeviceInputScreen}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("MANUAL_DEVICE_INPUT.TITLE"),
        }}
      />
      {twoFactorScreens(Stack)}
      <Stack.Screen
        name="SwitchQoSRule"
        component={ForwardedSwitchQoSRuleScreen}
        options={{ headerTitle: "QoS Rule" }}
      />
      <Stack.Screen
        name="SwitchQoSRulesList"
        component={ForwardedSwitchQoSRulesListScreen}
        options={{ headerTitle: "QoS Rules" }}
      />
      {hardwareScreens(Stack)}
    </Stack.Navigator>
  );
};

export default SettingsStack;
