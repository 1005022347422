import { I18n } from "@meraki/core/i18n";
import { Button, Heading, List, Modal, SearchBar } from "@meraki/magnetic/components";
import { Status, StatusProps } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useOrgStatuses } from "@meraki/shared/api";
import { LoginGroupProps } from "@meraki/shared/navigation-type";
import { useNavigation } from "@react-navigation/native";
import { isEmpty, sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import Organization from "~/api/models/Organization";
import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import { ORGANIZATION_KEY } from "~/constants/SearchKeys";
import { showAlert } from "~/lib/AlertUtils";
import {
  currentOrganization,
  getIsMerakiSSOAdmin,
  getLoginOrgs,
  getSSOAccessOrg,
} from "~/selectors";
import { StatusType } from "~/shared/constants/Status";
import useOrgs from "~/shared/hooks/orgs/useOrgs";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import useAndroidBackButtonOverride from "~/shared/hooks/useAndroidBackButtonOverride";

type Props = ForwardedNativeStackScreenProps<LoginGroupProps, "OrgChoose">;

function getStatus(
  orgId: string,
  orgStatuses: Record<string, StatusType> | undefined,
): StatusProps["status"] {
  const orgStatus = orgStatuses?.[orgId];

  switch (orgStatus) {
    case "online":
      return "positive";
    case "alerting":
      return "warning";
    case "offline":
      return "negative";
    case "dormant":
    default:
      return "neutral";
  }
}

export function OrgChooseScreen({ initialLogin, allowClose }: Props) {
  const navigation = useNavigation<Props["navigation"]>();

  const {
    setSearchText,
    initialOrgChoose,
    orgChoose,
    orgOverview,
    setSSOAccessOrg,
    setEntitiesAdmin,
  } = useActions();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);
  const [hasDashAuthCookie, setHasDashAuthCookie] = useState(false);
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [searchTextOnState, setSearchTextOnState] = useState("");

  const loginOrgs = useAppSelector(getLoginOrgs);
  const organization = useAppSelector(currentOrganization);
  const isMerakiSSOAdmin = useAppSelector(getIsMerakiSSOAdmin);
  const ssoAccessOrg = useAppSelector(getSSOAccessOrg);

  const [orgs, fetchOrgs] = useOrgs();
  const orgStatusesQuery = useOrgStatuses();
  const { data: orgStatuses, refetch: fetchOrgStatuses } = orgStatusesQuery;

  const isInitialLogin = isEmpty(organization) || initialLogin;

  useEffect(() => {
    navigation.setOptions({
      headerShadowVisible: false,
      headerLeft: allowClose
        ? () => (
            <Button.Nav
              text={I18n.t("CLOSE")}
              onPress={navigation.goBack}
              disabled={disableCloseButton}
            />
          )
        : undefined,
      headerBackVisible: isInitialLogin,
    });
  }, [allowClose, disableCloseButton, isInitialLogin, navigation]);

  async function handleOrgChoose(org: Organization | GoOrgSearchResult) {
    setIsLoadingOrg(true);
    try {
      if (isInitialLogin) {
        await orgChoose(org.eid);
      } else {
        setDisableCloseButton(true);
        if ("url" in org) {
          await orgOverview(org.eid);
        } else {
          await setSSOAccessOrg(org);
          await setEntitiesAdmin(org.id, org.eid, org.shardId, org.networkId);
        }
        navigation.goBack();
      }
    } catch (error) {
      navigation.popToTop();
      showAlert(error || I18n.t("ORGANIZATION.ERROR"));
    }
  }

  function androidBackButtonCallback() {
    // block Android hardware back button if initial login
    if (!isInitialLogin) {
      navigation.goBack();
    }
  }
  useAndroidBackButtonOverride(androidBackButtonCallback);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([fetchOrgs(), fetchOrgStatuses()]);
    setIsLoading(false);
  }, [setIsLoading, fetchOrgs, fetchOrgStatuses]);

  const handleSearchTextChange = useCallback(
    (text: string) => {
      setSearchTextOnState(text);
      setSearchText(ORGANIZATION_KEY, text);
    },
    [setSearchText, setSearchTextOnState],
  );

  const eid = isInitialLogin ? loginOrgs[0]?.eid : organization?.eid;
  const preRenderSteps = useCallback(async () => {
    try {
      if (isInitialLogin && eid != null) {
        await initialOrgChoose(eid);
      }

      setHasDashAuthCookie(true);
      fetchData();
    } catch (error) {
      console.warn(error);
    }
  }, [initialOrgChoose, isInitialLogin, eid, setHasDashAuthCookie, fetchData]);

  useEffect(() => {
    if (!hasDashAuthCookie) {
      preRenderSteps();
    }
  }, [hasDashAuthCookie, preRenderSteps]);

  if (!hasDashAuthCookie) {
    return null;
  }

  const { id: currentOrgId } = organization;

  const sortedOrgs: (Organization | GoOrgSearchResult)[] = sortBy(
    orgs ?? [],
    (org) => org.id !== currentOrgId,
  );

  if (isMerakiSSOAdmin && ssoAccessOrg != null) {
    sortedOrgs.unshift(ssoAccessOrg);
  }

  return (
    <Screen.View gap="none">
      <Box padding="sm" paddingTop="none" bottomDividerBorder>
        <SearchBar
          placeholder={I18n.t("ORGANIZATION.SEARCH_PLACEHOLDER")}
          value={searchTextOnState}
          onChangeText={handleSearchTextChange}
        />
      </Box>
      <List.FlashList
        data={sortedOrgs}
        emptyState={{ title: "No Organizations Found" }}
        getItemData={(org) => {
          const status = getStatus(org.id, orgStatuses);

          return {
            title: org.name,
            leftAccessory: <Status status={status} testID={`${status} organization`} />,
            onPress: () => handleOrgChoose(org),
          };
        }}
        loading={isLoading && (orgs?.length ?? 0) === 0}
        refreshing={isLoading}
        onRefresh={fetchData}
      />
      <Modal visible={isLoadingOrg} dismissOnOverlayPress={false}>
        <Heading>Loading...</Heading>
      </Modal>
    </Screen.View>
  );
}
