import { I18n } from "@meraki/core/i18n";
import { StackActions, useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import PressableOpacity from "~/shared/components/PressableOpacity";
import { useThemeColors, useThemeMode } from "~/shared/hooks/useTheme";

import TabBarIcon, { TabBarIconProps } from "./TabBarIcon";

type TabButtonProps = {
  title?: string;
  tabId: string;
  focusedTabId?: string;
  onPress: (tabId: string) => void;
};

const TabButton = ({
  title,
  tabId,
  focusedTabId,
  onPress,
  overrideIcon,
}: TabButtonProps & Pick<TabBarIconProps, "overrideIcon">) => {
  const colors = useThemeColors();
  const focused = tabId === focusedTabId;
  return (
    <PressableOpacity onPress={() => onPress(tabId)} style={styles.tabButton}>
      <TabBarIcon routeName={tabId} focused={focused} overrideIcon={overrideIcon} />
      {title && (
        <Text style={{ color: focused ? colors.navigation.primary : colors.text.subheader.color }}>
          {title}
        </Text>
      )}
    </PressableOpacity>
  );
};

const SideNavBar = ({ children }: any) => {
  const navigation = useNavigation();
  const colors = useThemeColors();
  const themeMode = useThemeMode();

  const [focusedTabId, setfocusedTabId] = useState("HomeStack");

  const onPress = (tabId: string, screen?: string) => {
    if (focusedTabId === tabId) {
      screen ? navigation.navigate(screen) : navigation.dispatch(StackActions.popToTop());
    } else {
      setfocusedTabId(tabId);
      navigation.navigate(tabId, { screen, initial: false });
    }
  };

  return (
    <View style={styles.rowContainer}>
      <View
        style={[{ backgroundColor: colors.navigation.backgroundPrimary }, styles.columnContainer]}
      >
        <View>
          <Image
            source={
              themeMode === "light"
                ? require("~/images/logos/merakiGo/logoMerakiGoBetaLargeColor.png")
                : require("~/images/logos/merakiGo/logoMerakiGoBetaLargeWhite.png")
            }
            style={styles.logo}
            resizeMode="center"
          />
          <TabButton
            tabId="HomeStack"
            title={I18n.t("ROOT_TITLES.HOME")}
            focusedTabId={focusedTabId}
            onPress={onPress}
          />
          <TabButton
            tabId="NetworksStack"
            title={I18n.t("ROOT_TITLES.NETWORKS")}
            focusedTabId={focusedTabId}
            onPress={onPress}
          />
          <TabButton
            tabId="HealthStack"
            title={I18n.t("ROOT_TITLES.HEALTH")}
            focusedTabId={focusedTabId}
            onPress={onPress}
          />
          <TabButton
            tabId="HardwareStack"
            title={I18n.t("ROOT_TITLES.HARDWARE")}
            focusedTabId={focusedTabId}
            onPress={onPress}
          />
          <TabButton
            tabId="SettingsStack"
            title={I18n.t("ROOT_TITLES.SETTINGS")}
            focusedTabId={focusedTabId}
            onPress={onPress}
          />
        </View>
        <View>
          <TabButton
            tabId="SettingsStack"
            onPress={(tabId) => onPress(tabId, "Account")}
            overrideIcon={require("~/images/tabBar/account-M-web.png")}
          />
        </View>
      </View>
      <View
        style={{
          borderStartWidth: StyleSheet.hairlineWidth,
          borderStartColor: colors.border.borderColor,
        }}
      />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  logo: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: 60,
    height: 60,
    marginHorizontal: 10,
    marginTop: 10,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
    borderRadius: 4,
  },
  columnContainer: {
    justifyContent: "space-between",
  },
  tabButton: {
    height: 80,
    width: 80,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
});

export default SideNavBar;
