import { z } from "zod";

import { request } from "../../../api/request/request";
import { APIResponseError } from "../../../schemas";
import { createMutation } from "../../createMutation";

export const logOutSchema = z.object({
  success: z.boolean(),
});
type LogOut = z.infer<typeof logOutSchema>;

const logOut = () => {
  return request(logOutSchema, "POST", `/login/logout`);
};

export const useLogOut = createMutation<Record<string, never>, LogOut, APIResponseError>({
  baseMutationKey: `/login/logout`,
  mutationFn: () => logOut(),
});
