import { I18n } from "@meraki/core/i18n";
import { Button, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  License,
  useAdmins,
  useDeleteNetwork,
  useDeleteOrganization,
  useDeviceStatuses,
  useGetLicenses,
  useOrganization,
  useOrgNetworks,
} from "@meraki/shared/api";
import { showDeleteConfirmAlert, showErrorAlert } from "@meraki/shared/native-alert";
import { useCurrentOrganizationId, useCurrentUserEmail } from "@meraki/shared/redux";
import { useCallback } from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";

export const OrgDeleteScreen = () => {
  const organizationId = useCurrentOrganizationId();
  const email = useCurrentUserEmail();
  const { data: admins } = useAdmins({ organizationId });
  const { data: devices } = useDeviceStatuses({
    organizationId,
  });
  const { data: orgName } = useOrganization(
    { organizationId },
    {
      select: (org) => org.name,
    },
  );
  const { data: orgNetworks } = useOrgNetworks({ organizationId });
  const deleteOrgNetwork = useDeleteNetwork();
  const deleteOrg = useDeleteOrganization();

  const deleteNetwork = useCallback(
    (id: string) => {
      deleteOrgNetwork.mutate(
        { networkId: id },
        {
          onError: (error) => {
            Alert.alert(String(error["errors"]));
          },
        },
      );
    },
    [deleteOrgNetwork],
  );

  const deleteOrganization = useCallback(
    (organizationId: string) => {
      deleteOrg.mutate(
        { organizationId },
        {
          onSuccess: async () => {
            Toast.showWithGravity(
              I18n.t("DELETE_LOCATION.SUCCESS", { organization: orgName }),
              Toast.SHORT,
              Toast.TOP,
            );
            //Todo: UDG-3930 has a log out function, call here
          },
          onError: (error) => {
            Alert.alert(String(error["errors"]));
          },
        },
      );
    },
    [deleteOrg, orgName],
  );

  const { data: licenses } = useGetLicenses(
    { organizationId },
    {
      select: (data) => {
        const queuedLicenses = [];
        const filteredLicenses = data.filter((license) => {
          if (license.permanentlyQueuedLicenses) {
            license.permanentlyQueuedLicenses.forEach((queuedLicense) =>
              queuedLicenses.push({
                headLicenseKey: license.licenseKey ?? "",
                licenseKey: queuedLicense.licenseKey,
              }),
            );
          }
          return license.licenseType === "GX-UMB";
        });
        const activeLicenses: License[] = [];
        filteredLicenses.forEach((license) => {
          if (license.state !== "unused" && license.state !== "expired") {
            activeLicenses.push(license);
          }
        });
        return { active: activeLicenses };
      },
    },
  );

  const detectInvalidCases = () => {
    const errors: string[] = [];
    if (!admins?.find((a) => a.email === email)) {
      return [I18n.t("DELETE_LOCATION.ERRORS.ADMIN")];
    }
    if (admins && Object.keys(admins).length > 1) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.MORE_THAN_ONE_ADMINS"));
    }
    if (devices && Object.keys(devices).length > 0) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.DEVICES_PRESENT"));
    }
    if (licenses != null && licenses.active.length > 0) {
      errors.push(I18n.t("DELETE_LOCATION.ERRORS.ACTIVE_LICENSE"));
    }
    return errors;
  };

  const onDelete = async () => {
    if (orgNetworks != null) {
      for (const { id } of orgNetworks) {
        deleteNetwork(id);
      }
    }
    if (organizationId) {
      deleteOrganization(organizationId);
    }
  };

  const showDeleteConfirmation = () => {
    const errors = detectInvalidCases();

    if (errors.length > 0) {
      showErrorAlert(errors.join("\n\n"), I18n.t("ERROR"));
      return;
    }
    showDeleteConfirmAlert(
      I18n.t("WARNING"),
      I18n.t("DELETE_LOCATION.CONFIRMATION", { organization: orgName }),
      onDelete,
    );
  };

  return (
    <Screen addDefaultPadding>
      <Text size="p1" color="light">
        {I18n.t("DELETE_LOCATION.MESSAGE")}
      </Text>
      <Button
        text={I18n.t("DELETE_LOCATION.BUTTON", { organization: orgName })}
        kind="secondaryDestructive"
        onPress={showDeleteConfirmation}
      />
    </Screen>
  );
};
