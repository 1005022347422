import { uniqueId } from "lodash";
import { useMemo } from "react";
import { Insets, StyleProp, View, ViewStyle } from "react-native";
import Svg, { Defs, G, LinearGradient, Path, Stop } from "react-native-svg";

import MkiColors from "~/constants/MkiColors";
import { platformSelect } from "~/lib/PlatformUtils";
import { CustomGradientProps, FillProps } from "~/shared/components/icons/types";

export interface GradientProps {
  name: string;
  size: number;
  path: string;
  color?: string;
  hasGradient?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  hitSlop?: Insets;
  disableHeightWidth?: boolean;
  renderGradient?: ({ id }: { id: string }) => JSX.Element;
  customGradient?: CustomGradientProps;
  testID?: string;
  viewBox?: string;
}

const defaultGradient = {
  x1: "0%",
  y1: "0%",
  x2: "70%",
  y2: "0%",
  colorLeft: MkiColors.gradientStart,
  colorRight: MkiColors.gradientEnd,
};

export const Gradient = (props: GradientProps) => {
  const {
    size,
    color,
    hasGradient,
    disableHeightWidth,
    renderGradient,
    customGradient,
    testID,
    onPress,
    style,
    path,
    viewBox,
  } = props;

  const gradientId = useMemo(() => uniqueId("gradient"), []);
  const fillColor = hasGradient ? `url(#${gradientId})` : color || MkiColors.defaultIconColor;
  const basicStyle = onPress ? platformSelect({ android: style, ios: null }) : style;
  const styles = [basicStyle];

  if (!disableHeightWidth) {
    styles.push({ width: size, height: size });
  }

  return (
    <View style={styles}>
      <Svg width={size} height={size} testID={testID} focusable={false} viewBox={viewBox}>
        {hasGradient ? (
          <GradientFill
            renderGradient={renderGradient}
            customGradient={customGradient}
            gradientId={gradientId}
          />
        ) : null}
        <G fill={fillColor}>
          <Path d={path} />
        </G>
      </Svg>
    </View>
  );
};

const GradientFill = (props: FillProps) => {
  const { renderGradient, customGradient, gradientId } = props;

  if (renderGradient) {
    return renderGradient({ id: gradientId });
  }
  const gradient = customGradient ? customGradient : defaultGradient;
  const { x1, y1, x2, y2, colorLeft, colorRight } = gradient;
  return (
    <Defs>
      <LinearGradient x1={x1} y1={y1} x2={x2} y2={y2} id={gradientId}>
        <Stop stopColor={colorLeft} stopOpacity="1" offset="0%" />
        <Stop stopColor={colorRight} stopOpacity="1" offset="100%" />
      </LinearGradient>
    </Defs>
  );
};

export default Gradient;
