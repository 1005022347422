import {
  CONNECTION_STATS_SUCCESS,
  FAILED_CONNECTIONS_SUCCESS,
  LATENCY_STATS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { get, mapValues } from "lodash";

import { containsEntity, isNormalized } from "~/lib/NormalizrUtils";
import { reducerHelper2 } from "~/lib/ReducerUtils";
import { initialState } from "~/lib/WirelessHealthUtils";
import {
  WirelessHealthContainerKeys,
  WirelessHealthReduxState,
} from "~/shared/types/WirelessHealth";

export const KEY_SUCCESS = "success";

export default function health(state: WirelessHealthReduxState = initialState, action: any) {
  const { type, response, meta } = action;
  if (!meta) {
    return state;
  }

  const { networkId } = meta;

  const locations = {
    CONNECTION_STATS_SUCCESS: "connectionStats",
    LATENCY_STATS_SUCCESS: "latencyStats",
    FAILED_CONNECTIONS_SUCCESS: "failedConnections",
    CHANNEL_UTILIZATION_SUCCESS: "channelUtilization",
  };

  const reduce = (state: WirelessHealthReduxState, type: string, response: any) => {
    let child = WirelessHealthContainerKeys.summary;
    if (type === FAILED_CONNECTIONS_SUCCESS) {
      child = WirelessHealthContainerKeys.raw;
    } else if (isNormalized(response)) {
      child = containsEntity(response, "nodes")
        ? WirelessHealthContainerKeys.nodes
        : WirelessHealthContainerKeys.clients;

      response = get(response, ["entities", child]);
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      response = mapValues(response, (stat: any) => stat[locations[type]]);
    }
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return reducerHelper2(state, [locations[type], networkId, child], response);
  };

  switch (type) {
    case CONNECTION_STATS_SUCCESS:
    case LATENCY_STATS_SUCCESS:
    case FAILED_CONNECTIONS_SUCCESS:
      return reduce(state, type, response);

    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
