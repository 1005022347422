import DataMsg from "../types/DataMsg";

export interface Message extends DataMsg {
  data: {
    failure: boolean;
  };
}

export function formatMsg(message?: Message): boolean | undefined {
  const wakeFailure = message?.data?.failure;

  if (wakeFailure === undefined) {
    return undefined;
  }

  return wakeFailure;
}
