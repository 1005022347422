import { I18n } from "@meraki/core/i18n";
import {
  useAdmin,
  useAdmins,
  useAuthUsers,
  useDeleteAdmin,
  useDisableClientVPN,
  useEnableClientVPN,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useLayoutEffect } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { AlertSettings } from "~/api/schemas/alerts/AlertSettings";
import MkiColors from "~/constants/MkiColors";
import { LINE_HEIGHT, SPACING } from "~/constants/MkiConstants";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { useAuthIdByEmail } from "~/go/hooks/useAuthIdByEmail";
import { useAuthUsersByEmail } from "~/go/hooks/useAuthUsersByEmail";
import { SettingsStackProps } from "~/go/navigation/Types";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showActionSheet, showAlert, unsubscribeAdminFromAllAlerts } from "~/lib/AlertUtils";
import { sizeSelect } from "~/lib/themeHelper";
import {
  alertSettingsState,
  currentOrganization,
  currentUserState,
  getIpsecSettings,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CloseButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import { AuthUserType } from "~/shared/types/AdminTypes";
import { AlertSetting } from "~/shared/types/AlertTypes";

interface Row {
  label: string;
  value?: boolean;
  text?: string;
  switchRow?: boolean;
  onChange?: (value: boolean) => void;
}

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "ViewAdministrator"> &
  PendingComponent;

function ViewAdministratorScreen({ adminId, setReqPending, handleError }: Props) {
  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const networkId = useCurrentNetworkId();
  const actions = useActions();
  const organizationId = useAppSelector(currentOrganization).id ?? "";
  const { data: admin } = useAdmin({ organizationId, adminId });
  const deleteAdmin = useDeleteAdmin();
  const adminEmail = admin ? admin.email : "";
  const alertSettings: AlertSettings = useAppSelector(alertSettingsState);
  const currentUser = useAppSelector(currentUserState);
  const authId = useAuthIdByEmail(adminEmail, AuthUserType.CLIENT_VPN, networkId).data;
  const authUsersByEmail = useAuthUsersByEmail(networkId);
  const clientVPNEnabled =
    authUsersByEmail.data && authUsersByEmail.data[adminEmail]?.clientVPNEnabled;
  const clientVPNEnabledOnNetwork: boolean = useAppSelector(getIpsecSettings)?.enabled ?? false;

  const enableClientVPN = useEnableClientVPN();
  const disableClientVPN = useDisableClientVPN();

  const queryClient = useQueryClient();
  const refreshQueries = () => {
    queryClient.refetchQueries({ queryKey: useAuthUsers.queryKeyRoot });
    queryClient.refetchQueries({ queryKey: useAdmins.queryKeyRoot });
  };

  const toggleVPN = (enabled: boolean) => {
    if (enabled) {
      enableClientVPN.mutate(
        { email: adminEmail, isAdmin: true, networkId: networkId },
        {
          onError: (error) => showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT")),
          onSuccess: refreshQueries,
        },
      );
    } else {
      disableClientVPN.mutate(
        { id: authId, networkId: networkId },
        {
          onError: (error) =>
            showAlert(I18n.t("ERROR"), error || I18n.t("ERRORS.SERVER_ERROR_TEXT")),
          onSuccess: refreshQueries,
        },
      );
    }
  };

  const deleteAdministrator = async () => {
    setReqPending(true);
    try {
      //@ts-ignore
      const newAlertSettings: AlertSetting[] = unsubscribeAdminFromAllAlerts(admin, alertSettings);
      await actions.updateAlertSettings(newAlertSettings);
      deleteAdmin.mutate(
        { organizationId, adminId },
        {
          onSuccess: () => {
            refreshQueries();
            navigation.goBack();
          },
          onSettled: () => setReqPending(false),
        },
      );
    } catch (error) {
      if (typeof error === "string") {
        handleError(error);
      }
    } finally {
      refreshQueries();
      setReqPending(false);
    }
  };

  const confirmDeletion = () => {
    showActionSheet([I18n.t("DELETE")], deleteAdministrator, {
      title: I18n.t("ADMIN.DELETE_CONFIRMATION"),
      destructiveButtonIndex: 0,
    });
  };

  const getRows = () => {
    const rows: Row[] = [
      { label: I18n.t("ADMIN.NAME.TITLE"), text: admin?.name },
      { label: I18n.t("ADMIN.EMAIL.TITLE"), text: admin?.email },
    ];

    if (clientVPNEnabledOnNetwork) {
      rows.push({
        label: I18n.t("ADMIN.BUTTON.VPN_ENABLED"),
        value: clientVPNEnabled,
        switchRow: true,
        onChange: toggleVPN,
      });
    }

    return rows;
  };

  const renderRow = ({ label, value, switchRow, onChange, text }: Row) => {
    return switchRow ? (
      <SwitchRow
        value={value}
        onValueChange={onChange}
        screenStyles={styles.rowStyles}
        testID={"VIEW_ADMIN.VPN_ENABLED"}
      >
        <MkiText textStyle="secondary">{label}</MkiText>
      </SwitchRow>
    ) : (
      <ListRow
        rowStyles={styles.rowStyles}
        rightStyle={styles.rightContent}
        leftStyle={styles.leftContent}
        labelStyle={styles.label}
        value={text}
      >
        {label}
      </ListRow>
    );
  };

  const isCurrentUser = admin?.email === currentUser;

  return (
    <FullScreenContainerView defaultPadding>
      <SummaryList contentRows={getRows()} customRenderRow={renderRow} />
      <View style={styles.footerContainer}>
        {isCurrentUser && (
          <MkiText
            textStyle="smallSecondary"
            screenStyles={styles.helpText}
            testID={"VIEW_ADMIN.CURRENT_USER"}
          >
            {I18n.t("ADMIN.HELP.SELF_DELETION")}
          </MkiText>
        )}
        <RoundedButton
          buttonType={ButtonType.destructive}
          onPress={confirmDeletion}
          screenStyles={styles.button}
          disabled={isCurrentUser}
          testID={isCurrentUser ? "VIEW_ADMIN.DELETE_DISABLED" : "VIEW_ADMIN.DELETE_ENABLED"}
        >
          {I18n.t("DELETE")}
        </RoundedButton>
      </View>
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  footerContainer: {
    marginVertical: SPACING.extraLarge,
    marginHorizontal: SPACING.default,
  },
  helpText: {
    lineHeight: LINE_HEIGHT.default,
    marginBottom: SPACING.default,
    textAlign: "center",
  },
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
  rowStyles: {
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.default,
  },
  rightContent: {
    width: "80%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "20%",
  },
  label: {
    color: MkiColors.secondaryTextColor,
  },
});

export default withPendingComponent(ViewAdministratorScreen);
