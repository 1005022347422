import Svg, { Path } from "react-native-svg";

import { useThemeColors } from "~/shared/hooks/useTheme";

import { DEFAULT_ICON_SIZES } from "../types";

type AlertingIconProps = {
  color: string;
  size?: number;
  metric?: string;
};

export const AlertingIcon = ({ color, size = DEFAULT_ICON_SIZES.l, metric }: AlertingIconProps) => {
  const colors = useThemeColors();

  return (
    <Svg width={size} height={size} viewBox="0 0 12 14" fill="none" testID={metric}>
      <Path
        d="M5.16022 0.656539C5.67915 0.353826 6.32085 0.353826 6.83979 0.65654L11.1731 3.18432C11.6852 3.483 12 4.03117 12 4.62395V9.37605C12 9.96883 11.6851 10.517 11.1731 10.8157L6.83978 13.3435C6.32085 13.6462 5.67915 13.6462 5.16022 13.3435L0.826881 10.8157C0.314851 10.517 0 9.96883 0 9.37605V4.62395C0 4.03117 0.314852 3.483 0.826882 3.18432L5.16022 0.656539Z"
        fill={color}
      />
      <Path
        d="M5.33317 6.83333L5.33317 4C5.33317 3.63181 5.631647 3.333332 5.999837 3.333332C6.36803 3.333332 6.6665 3.63181 6.6665 4L6.6665 6.83333C6.6665 7.20152 6.36803 7.5 5.999837 7.5C5.631647 7.5 5.33317 7.20152 5.33317 6.83333Z"
        fill={colors.modal.background}
      />
      <Path
        d="M6.83317 9.16667C6.83317 9.6269 6.46007 10 5.999837 10C5.5396 10 5.166504 9.6269 5.166504 9.16667C5.166504 8.70643 5.5396 8.33333 5.999837 8.33333C6.46007 8.33333 6.83317 8.70643 6.83317 9.16667Z"
        fill={colors.modal.background}
      />
    </Svg>
  );
};

export default AlertingIcon;
