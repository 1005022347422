import { memo, ReactNode } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import ContextHelp from "~/go/components/contextHelp/ContextHelp";
import { appSelect, platformSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import Table from "~/shared/components/Table";
import { useTheme } from "~/shared/hooks/useTheme";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

export const SummaryCardHeadingText = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <MkiText textStyle="subheading" screenStyles={themeColors(theme).text?.subheading}>
      {children}
    </MkiText>
  );
};
export const renderLoading = () => (
  <View style={styles.loadingContainer}>
    <MkiSpinner />
  </View>
);

const renderSubheading = (subheading: string) => (
  <View style={styles.subheading}>
    <MkiText textStyle="small" screenStyles={styles.subheadingText}>
      {subheading}
    </MkiText>
  </View>
);

const renderDisclosureRows = (disclosureRows?: SummaryCardDisclosureRow[]) => {
  if (!disclosureRows?.length) {
    return null;
  }

  const renderRow = (rowData: SummaryCardDisclosureRow) => (
    <DisclosureRow onPress={rowData.onPress} isFullButton testID={rowData.testID}>
      {rowData.label}
    </DisclosureRow>
  );

  return (
    <Table<SummaryCardDisclosureRow>
      entries={disclosureRows}
      renderRow={(rowData) => renderRow(rowData)}
      separators={false}
      listStyles={styles.disclosureStyles}
    />
  );
};

export interface SummaryCardDisclosureRow {
  label: string;
  onPress: () => void;
  testID?: string;
}

interface SummaryCardProps {
  heading: ReactNode | string;
  subheading?: string;
  children: ReactNode | ReactNode[];
  disclosureRows?: SummaryCardDisclosureRow[];
  loading?: boolean;
  onPress?: () => void;
  contentContainerStyles?: StyleProp<ViewStyle>;
  removeBottomBorder?: boolean;
  context?: string;
  testID?: string;
}

const SummaryCard = (props: SummaryCardProps) => {
  const {
    heading,
    subheading,
    onPress,
    loading,
    children,
    disclosureRows,
    contentContainerStyles,
    removeBottomBorder,
    testID,
    context,
  } = props;

  const theme = useTheme();

  const headingContent =
    typeof heading === "string" ? (
      typeof context === "string" ? (
        <View style={styles.horizontalContainer}>
          <SummaryCardHeadingText>{heading}</SummaryCardHeadingText>
          {context && <ContextHelp context={context} />}
        </View>
      ) : (
        <SummaryCardHeadingText>{heading}</SummaryCardHeadingText>
      )
    ) : (
      heading
    );
  const headingStyle = subheading ? styles.headingWithSubheading : styles.heading;
  const contentContainerStyle = appSelect({
    enterprise: styles.enterpriseContentContainer,
    go: [
      styles.goContentContainer,
      { borderColor: themeColors(theme).border?.borderColor },
      removeBottomBorder ? { borderBottomWidth: 0 } : {},
    ],
  });
  const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
  const containerStyle = [{ backgroundColor }, styles.container];

  // TODO: Change how the enterpriseContentContainer applies padding/margin.
  // We want to make sure that we never need negative padding/margin for children.
  const insideComponet = (
    <View style={containerStyle} testID={testID}>
      <View style={styles.headingContainer}>
        <View style={headingStyle}>{headingContent}</View>
        {subheading ? renderSubheading(subheading) : null}
      </View>
      <View style={[contentContainerStyle, contentContainerStyles]}>
        {loading ? renderLoading() : children}
      </View>
      {!loading ? renderDisclosureRows(disclosureRows) : null}
    </View>
  );

  return onPress ? (
    <TouchableOpacity style={containerStyle} onPress={onPress}>
      {insideComponet}
    </TouchableOpacity>
  ) : (
    <View style={containerStyle}>{insideComponet}</View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: SPACING.small,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 24,
  },
  enterpriseContentContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
  },
  goContentContainer: {
    marginHorizontal: SPACING.default,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingBottom: SPACING.default,
  },
  headingContainer: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
  },
  heading: {
    marginBottom: appSelect({
      enterprise: 10,
      go: SPACING.small,
    }),
  },
  headingWithSubheading: {
    marginBottom: SPACING.tiny,
  },
  subheading: {
    marginBottom: SPACING.small,
  },
  subheadingText: {
    color: MkiColors.secondaryTextColor,
  },
  disclosureStyles: {
    borderTopWidth: 0,
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...platformSelect({
      ios: {
        marginVertical: -SPACING.tiny,
      },
      android: {
        marginVertical: 0,
      },
    }),
  },
});

export default memo(SummaryCard);
