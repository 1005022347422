import { memo } from "react";
import { StyleProp, TextStyle, TouchableWithoutFeedbackProps } from "react-native";

import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

interface LinkTextProps
  extends Pick<TouchableWithoutFeedbackProps, "accessibilityRole" | "onPress"> {
  children: React.ReactNode;
  disabled?: boolean;
  textStyles?: StyleProp<TextStyle>;
}

function LinkText(props: LinkTextProps) {
  const { accessibilityRole = "link", children, disabled = false, onPress, textStyles } = props;
  return (
    <Touchable
      accessibilityRole={accessibilityRole}
      disabled={disabled}
      onPress={onPress}
      transparentBackground
    >
      <MkiText screenStyles={textStyles}>{children}</MkiText>
    </Touchable>
  );
}

export default memo(LinkText);
