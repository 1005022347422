import { I18n } from "@meraki/core/i18n";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useFilePicker } from "use-file-picker";

import { SPACING } from "~/constants/MkiConstants";
import { showAlert } from "~/lib/AlertUtils";
import MerakiIcon from "~/shared/components/icons";
import { MerakiIconProps } from "~/shared/components/icons/MerakiIcon";
import { useThemeColors } from "~/shared/hooks/useTheme";

export interface FileAttachment {
  name: string;
  content: string;
}

interface AttachFileButtonProps {
  containerStyle?: StyleProp<ViewStyle>;
  hasFile: boolean;
  setFile: (file: FileAttachment) => void;
  testID: string;
}

const AttachFileButton = (props: AttachFileButtonProps) => {
  const { containerStyle, hasFile, setFile, testID } = props;

  const { openFilePicker: onPress } = useFilePicker({
    multiple: false,
    readAs: "DataURL",
    accept: "image/*",
    onFilesRejected: ({ errors }) => {
      if (errors.length !== 0) {
        showAlert(I18n.t("ERROR"), errors.join(", "));
      }
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      if (plainFiles.length === 1) {
        const selectedFile = plainFiles[0];
        const fileContent = filesContent[0];

        if (selectedFile.name != null) {
          setFile({
            name: selectedFile.name,
            content: fileContent.content,
          });
        }
      }
    },
  });

  const themeColors = useThemeColors();

  const iconProps: MerakiIconProps = {
    onPress,
    name: "attach",
    size: "s",
    color: themeColors.text.active.color,
    containerStyle: styles.container,
    hasBadge: hasFile,
    testID: `${testID}.ICON`,
  };

  return (
    <View style={containerStyle} testID={testID}>
      <MerakiIcon {...iconProps} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: SPACING.small,
  },
});

export default AttachFileButton;
