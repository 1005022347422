import { I18n } from "@meraki/core/i18n";

interface CountriesWithCode {
  [code: string]: {
    name: string;
    region: string;
    phoneCode?: string;
    emoji?: string;
  };
}

export const COUNTRIES: CountriesWithCode = {
  AF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.AFGHANISTAN"), region: "APAC" },
  AX: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ALAND_ISLANDS"), region: "EMEA" },
  AL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ALBANIA"), region: "EMEA" },
  DZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ALGERIA"), region: "EMEA" },
  AS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.AMERICAN_SAMOA"), region: "APAC" },
  AD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ANDORRA"), region: "EMEA" },
  AO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ANGOLA"), region: "EMEA" },
  AI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ANGUILLA"), region: "SA" },
  AQ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ANTARCTICA"), region: "APAC" },
  AG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ANTIGUA_AND_BARBUDA"), region: "SA" },
  AR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ARGENTINA"), region: "SA" },
  AM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ARMENIA"), region: "EMEA" },
  AW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ARUBA"), region: "SA" },
  AU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.AUSTRALIA"), region: "APAC" },
  AT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.AUSTRIA"), region: "EMEA" },
  AZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.AZERBAIJAN"), region: "APAC" },
  BS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BAHAMAS"), region: "SA" },
  BH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BAHRAIN"), region: "EMEA" },
  BD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BANGLADESH"), region: "APAC" },
  BB: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BARBADOS"), region: "SA" },
  BY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BELARUS"), region: "EMEA" },
  BE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BELGIUM"), region: "EMEA" },
  BZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BELIZE"), region: "SA" },
  BJ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BENIN"), region: "EMEA" },
  BM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BERMUDA"), region: "SA" },
  BT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BHUTAN"), region: "APAC" },
  BO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BOLIVIA"), region: "SA" },
  BA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BOSNIA_AND_HERZEGOVINA"), region: "EMEA" },
  BW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BOTSWANA"), region: "EMEA" },
  BV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BOUVET_ISLAND"), region: "SA" },
  BR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BRAZIL"), region: "SA" },
  IO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BRITISH_INDIAN_OCEAN_TERRITORY"), region: "APAC" },
  BN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BRUNEI_DARUSSALAM"), region: "APAC" },
  BG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BULGARIA"), region: "EMEA" },
  BF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BURKINA_FASO"), region: "EMEA" },
  BI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.BURUNDI"), region: "EMEA" },
  KH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CAMBODIA"), region: "APAC" },
  CM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CAMEROON"), region: "EMEA" },
  CA: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CANADA"),
    region: "NA",
    phoneCode: "+1",
    emoji: "🇨🇦",
  },
  CV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CAPE_VERDE"), region: "EMEA" },
  KY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CAYMAN_ISLANDS"), region: "SA" },
  CF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CENTRAL_AFRICAN_REPUBLIC"), region: "EMEA" },
  TD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CHAD"), region: "EMEA" },
  CL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CHILE"), region: "SA" },
  CN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CHINA"), region: "CN" },
  CX: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CHRISTMAS_ISLAND"), region: "APAC" },
  CC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COCOS_KEELING_ISLANDS"), region: "APAC" },
  CO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COLOMBIA"), region: "SA" },
  KM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COMOROS"), region: "EMEA" },
  CG: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CONGO_BRAZZAVILLE_REPUBLIC_OF_THE"),
    region: "EMEA",
  },
  CD: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE"),
    region: "EMEA",
  },
  CK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COOK_ISLANDS"), region: "APAC" },
  CR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COSTA_RICA"), region: "SA" },
  CI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.COTE_D’IVOIRE"), region: "EMEA" },
  HR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CROATIA"), region: "EMEA" },
  CU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CUBA"), region: "SA" },
  CY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CYPRUS"), region: "EMEA" },
  CZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.CZECH_REPUBLIC"), region: "EMEA" },
  DK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.DENMARK"), region: "EMEA" },
  DJ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.DJIBOUTI"), region: "EMEA" },
  DM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.DOMINICA"), region: "SA" },
  DO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.DOMINICAN_REPUBLIC"), region: "SA" },
  EC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ECUADOR"), region: "SA" },
  EG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.EGYPT"), region: "EMEA" },
  SV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.EL_SALVADOR"), region: "SA" },
  GQ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.EQUATORIAL_GUINEA"), region: "EMEA" },
  ER: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ERITREA"), region: "EMEA" },
  EE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ESTONIA"), region: "EMEA" },
  ET: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ETHIOPIA"), region: "EMEA" },
  FK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FALKLAND_ISLANDS_MALVINAS"), region: "SA" },
  FO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FAROE_ISLANDS"), region: "EMEA" },
  FJ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FIJI"), region: "APAC" },
  FI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FINLAND"), region: "EMEA" },
  FR: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FRANCE"),
    region: "EMEA",
    phoneCode: "+33",
    emoji: "🇫🇷",
  },
  GF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FRENCH_GUIANA"), region: "SA" },
  PF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FRENCH_POLYNESIA"), region: "APAC" },
  TF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.FRENCH_SOUTHERN_TERRITORIES"), region: "APAC" },
  GA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GABON"), region: "EMEA" },
  GM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GAMBIA"), region: "EMEA" },
  GE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GEORGIA"), region: "EMEA" },
  DE: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GERMANY"),
    region: "EMEA",
    phoneCode: "+49",
    emoji: "🇩🇪",
  },
  GH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GHANA"), region: "EMEA" },
  GI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GIBRALTAR"), region: "EMEA" },
  GR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GREECE"), region: "EMEA" },
  GL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GREENLAND"), region: "EMEA" },
  GD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GRENADA"), region: "SA" },
  GP: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUADELOUPE"), region: "SA" },
  GU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUAM"), region: "APAC" },
  GT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUATEMALA"), region: "SA" },
  GG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUERNSEY"), region: "EMEA" },
  GN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUINEA"), region: "EMEA" },
  GW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUINEABISSAU"), region: "EMEA" },
  GY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.GUYANA"), region: "SA" },
  HT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HAITI"), region: "SA" },
  HM: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HEARD_ISLAND_AND_MCDONALD_ISLANDS"),
    region: "APAC",
  },
  VA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HOLY_SEE_VATICAN_CITY_STATE"), region: "EMEA" },
  HN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HONDURAS"), region: "SA" },
  HK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HONG_KONG"), region: "APAC" },
  HU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.HUNGARY"), region: "EMEA" },
  IS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ICELAND"), region: "EMEA" },
  IN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.INDIA"), region: "APAC" },
  ID: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.INDONESIA"), region: "APAC" },
  IR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.IRAN_ISLAMIC_REPUBLIC_OF"), region: "EMEA" },
  IQ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.IRAQ"), region: "EMEA" },
  IE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.IRELAND"), region: "EMEA" },
  IM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ISLE_OF_MAN"), region: "EMEA" },
  IL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ISRAEL"), region: "EMEA" },
  IT: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ITALY"),
    region: "EMEA",
    phoneCode: "+39",
    emoji: "🇮🇹",
  },
  JM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.JAMAICA"), region: "SA" },
  JP: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.JAPAN"),
    region: "APAC",
    phoneCode: "+81",
    emoji: "🇯🇵",
  },
  JE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.JERSEY"), region: "EMEA" },
  JO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.JORDAN"), region: "EMEA" },
  KZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KAZAKHSTAN"), region: "APAC" },
  KE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KENYA"), region: "EMEA" },
  KI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KIRIBATI"), region: "APAC" },
  KP: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KOREA_DEMOCRATIC_PEOPLE'S_REPUBLIC_OF"),
    region: "APAC",
  },
  KR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KOREA_REPUBLIC_OF"), region: "APAC" },
  KW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KUWAIT"), region: "EMEA" },
  KG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.KYRGYZSTAN"), region: "APAC" },
  LA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LAO_PEOPLE'S_DEMOCRATIC_REPUBLIC"), region: "APAC" },
  LV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LATVIA"), region: "EMEA" },
  LB: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LEBANON"), region: "EMEA" },
  LS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LESOTHO"), region: "EMEA" },
  LR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LIBERIA"), region: "EMEA" },
  LY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LIBYAN_ARAB_JAMAHIRIYA"), region: "EMEA" },
  LI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LIECHTENSTEIN"), region: "EMEA" },
  LT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LITHUANIA"), region: "EMEA" },
  LU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.LUXEMBOURG"), region: "EMEA" },
  MO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MACAU"), region: "APAC" },
  MK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MACEDONIA_REPUBLIC_OF"), region: "EMEA" },
  MG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MADAGASCAR"), region: "EMEA" },
  MW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MALAWI"), region: "EMEA" },
  MY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MALAYSIA"), region: "APAC" },
  MV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MALDIVES"), region: "APAC" },
  ML: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MALI"), region: "EMEA" },
  MT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MALTA"), region: "EMEA" },
  MH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MARSHALL_ISLANDS"), region: "APAC" },
  MQ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MARTINIQUE"), region: "SA" },
  MR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MAURITANIA"), region: "EMEA" },
  MU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MAURITIUS"), region: "EMEA" },
  YT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MAYOTTE"), region: "EMEA" },
  MX: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MEXICO"),
    region: "SA",
    phoneCode: "+52",
    emoji: "🇲🇽",
  },
  FM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MICRONESIA_FEDERATED_STATES_OF"), region: "APAC" },
  MD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MOLDOVA_REPUBLIC_OF"), region: "EMEA" },
  MC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MONACO"), region: "EMEA" },
  MN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MONGOLIA"), region: "APAC" },
  ME: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MONTENEGRO"), region: "EMEA" },
  MS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MONTSERRAT"), region: "SA" },
  MA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MOROCCO"), region: "EMEA" },
  MZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MOZAMBIQUE"), region: "EMEA" },
  MM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.MYANMAR"), region: "APAC" },
  NA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NAMIBIA"), region: "EMEA" },
  NR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NAURU"), region: "APAC" },
  NP: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NEPAL"), region: "APAC" },
  NL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NETHERLANDS"), region: "EMEA" },
  AN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NETHERLANDS_ANTILLES"), region: "SA" },
  NC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NEW_CALEDONIA"), region: "APAC" },
  NZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NEW_ZEALAND"), region: "APAC" },
  NI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NICARAGUA"), region: "SA" },
  NE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NIGER"), region: "EMEA" },
  NG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NIGERIA"), region: "EMEA" },
  NU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NIUE"), region: "APAC" },
  NF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NORFOLK_ISLAND"), region: "APAC" },
  MP: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NORTHERN_MARIANA_ISLANDS"), region: "APAC" },
  NO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.NORWAY"), region: "EMEA" },
  OM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.OMAN"), region: "EMEA" },
  PK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PAKISTAN"), region: "APAC" },
  PW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PALAU"), region: "APAC" },
  PS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PALESTINIAN_TERRITORIES"), region: "EMEA" },
  PA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PANAMA"), region: "SA" },
  PG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PAPUA_NEW_GUINEA"), region: "APAC" },
  PY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PARAGUAY"), region: "SA" },
  PE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PERU"), region: "SA" },
  PH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PHILIPPINES"), region: "APAC" },
  PN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PITCAIRN"), region: "APAC" },
  PL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.POLAND"), region: "EMEA" },
  PT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PORTUGAL"), region: "EMEA" },
  PR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.PUERTO_RICO"), region: "SA" },
  QA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.QATAR"), region: "EMEA" },
  RE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.REUNION"), region: "APAC" },
  RO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ROMANIA"), region: "EMEA" },
  RU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.RUSSIAN_FEDERATION"), region: "EMEA" },
  RW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.RWANDA"), region: "EMEA" },
  BL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_BARTHELEMY"), region: "SA" },
  KN: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_CHRISTOPHER_ST_KITTS_AND_NEVIS"),
    region: "SA",
  },
  SH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_HELENA"), region: "EMEA" },
  LC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_LUCIA"), region: "SA" },
  MF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_MARTIN"), region: "SA" },
  PM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_PIERRE_AND_MIQUELON"), region: "NA" },
  VC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAINT_VINCENT_AND_THE_GRENADINES"), region: "SA" },
  WS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAMOA"), region: "APAC" },
  SM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAN_MARINO"), region: "EMEA" },
  ST: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAO_TOME_AND_PRINCIPE"), region: "EMEA" },
  SA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SAUDI_ARABIA"), region: "EMEA" },
  SN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SENEGAL"), region: "EMEA" },
  RS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SERBIA_REPUBLIC_OF"), region: "EMEA" },
  SC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SEYCHELLES"), region: "EMEA" },
  SL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SIERRA_LEONE"), region: "EMEA" },
  SG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SINGAPORE"), region: "APAC" },
  SK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SLOVAK_REPUBLIC"), region: "EMEA" },
  SI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SLOVENIA"), region: "EMEA" },
  SB: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SOLOMON_ISLANDS"), region: "APAC" },
  SO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SOMALIA"), region: "EMEA" },
  ZA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SOUTH_AFRICA"), region: "EMEA" },
  GS: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"),
    region: "SA",
  },
  SS: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SOUTH_SUDAN"), region: "EMEA" },
  ES: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SPAIN"),
    region: "EMEA",
    phoneCode: "+34",
    emoji: "🇪🇸",
  },
  LK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SRI_LANKA"), region: "APAC" },
  SD: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SUDAN"), region: "EMEA" },
  SR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SURINAME"), region: "SA" },
  SJ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SVALBARD_AND_JAN_MAYEN"), region: "EMEA" },
  SZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SWAZILAND"), region: "EMEA" },
  SE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SWEDEN"), region: "EMEA" },
  CH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SWITZERLAND"), region: "EMEA" },
  SY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.SYRIAN_ARAB_REPUBLIC"), region: "APAC" },
  TW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TAIWAN"), region: "APAC" },
  TJ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TAJIKISTAN"), region: "APAC" },
  TZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TANZANIA_UNITED_REPUBLIC_OF"), region: "EMEA" },
  TH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.THAILAND"), region: "APAC" },
  TL: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TIMORLESTE_EAST_TIMOR"), region: "APAC" },
  TG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TOGO"), region: "EMEA" },
  TK: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TOKELAU"), region: "APAC" },
  TO: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TONGA"), region: "APAC" },
  TT: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TRINIDAD_AND_TOBAGO"), region: "SA" },
  TN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TUNISIA"), region: "EMEA" },
  TR: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TURKEY"), region: "EMEA" },
  TM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TURKMENISTAN"), region: "APAC" },
  TC: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TURKS_AND_CAICOS_ISLANDS"), region: "SA" },
  TV: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.TUVALU"), region: "APAC" },
  UG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UGANDA"), region: "EMEA" },
  UA: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UKRAINE"), region: "EMEA" },
  AE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UNITED_ARAB_EMIRATES"), region: "EMEA" },
  GB: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UNITED_KINGDOM"),
    region: "EMEA",
    phoneCode: "+44",
    emoji: "🇬🇧",
  },
  US: {
    name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UNITED_STATES_OF_AMERICA"),
    region: "NA",
    phoneCode: "+1",
    emoji: "🇺🇸",
  },
  UY: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.URUGUAY"), region: "SA" },
  UZ: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.UZBEKISTAN"), region: "APAC" },
  VU: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.VANUATU"), region: "APAC" },
  VE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.VENEZUELA"), region: "SA" },
  VN: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.VIETNAM"), region: "APAC" },
  VG: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.VIRGIN_ISLANDS_BRITISH"), region: "SA" },
  VI: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.VIRGIN_ISLANDS_US"), region: "SA" },
  WF: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.WALLIS_AND_FUTUNA"), region: "APAC" },
  EH: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.WESTERN_SAHARA"), region: "EMEA" },
  YE: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.YEMEN"), region: "EMEA" },
  ZM: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ZAMBIA"), region: "EMEA" },
  ZW: { name: I18n.t("CREATE_ACCOUNT.COUNTRIES.ZIMBABWE"), region: "EMEA" },
};
