import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";

const buildUrl = ({ networkId }: RemoveDeviceRequest) => {
  return `/api/v1/networks/${networkId}/devices/remove`;
};

interface RemoveDeviceRequest {
  networkId?: string;
}

interface MutateRemoveDeviceRequest extends RemoveDeviceRequest {
  serial: string;
}

export const RemoveDeviceSchema = z.undefined().describe("RemoveDeviceSchema");

export type RemoveDevice = z.infer<typeof RemoveDeviceSchema>;

const mutateRemoveDevice = ({ networkId, serial }: MutateRemoveDeviceRequest) => {
  return request(RemoveDeviceSchema, "POST", buildUrl({ networkId }), {
    body: JSON.stringify({ serial }),
  });
};

export const useRemoveDevice = createMutation<
  MutateRemoveDeviceRequest,
  undefined,
  APIResponseError
>({
  baseMutationKey: buildUrl({ networkId: `{networkId}` }),
  mutationFn: (parameters: MutateRemoveDeviceRequest) => mutateRemoveDevice(parameters),
});
