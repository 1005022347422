import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { isWeb } from "~/lib/PlatformUtils";
import { getLatencyStats } from "~/selectors";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import MkiPieChart from "~/shared/components/MkiPieChart";
import MkiText from "~/shared/components/MkiText";
import SummaryCard from "~/shared/components/SummaryCard";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { LatencyStats, RawDistribution, Traffic } from "~/shared/types/WirelessHealth";

const GREEN_LATENCIES = new Set(["0", "1", "2", "4", "8", "16"]);
const YELLOW_LATENCIES = new Set(["32", "64", "128"]);

type LatencyStatsProp = {
  isLoading: boolean;
};

const buildData = (rawDistribution: RawDistribution) => {
  let greenLatencies = 0;
  let yellowLatencies = 0;
  let redLatencies = 0;

  for (const bucket of Object.keys(rawDistribution)) {
    const packets = rawDistribution[bucket];
    if (GREEN_LATENCIES.has(bucket)) {
      greenLatencies += packets;
    } else if (YELLOW_LATENCIES.has(bucket)) {
      yellowLatencies += packets;
    } else {
      redLatencies += packets;
    }
  }

  return [
    { x: 1, y: greenLatencies },
    { x: 2, y: yellowLatencies },
    { x: 3, y: redLatencies },
  ];
};

const NoData = () => {
  return (
    <MkiText textStyle="secondary" screenStyles={styles.noDataText}>
      {I18n.t("LATENCY_STATS.NO_DATA")}
    </MkiText>
  );
};

type LatencyPieChartProps = {
  name: string;
  data: Traffic;
};

const LatencyPieChart = ({ name, data }: LatencyPieChartProps) => {
  if (!data) {
    return <></>;
  }
  const { rawDistribution, avg } = data;

  return (
    <View style={styles.pieChartContainer}>
      <MkiPieChart
        data={buildData(rawDistribution)}
        x="x"
        y="y"
        innerText={I18n.t("LATENCY_STATS.AVERAGE_MS", { avg })}
        colorScale={[MkiColors.goodBar, MkiColors.alertingBar, MkiColors.badBar]}
        showLegend={false}
      />
      <MkiText>{name}</MkiText>
    </View>
  );
};

export const LatencyStatsCard = ({ isLoading }: LatencyStatsProp) => {
  const latencyStats: LatencyStats = useAppSelector(getLatencyStats);
  const hasLatencyStats = !isEmpty(latencyStats);
  return (
    <SummaryCard
      heading={I18n.t("LATENCY_STATS.HEADING")}
      subheading={I18n.t("LATENCY_STATS.SUBHEADING")}
      loading={isLoading}
    >
      <View style={isWeb() ? {} : styles.container}>
        {hasLatencyStats ? (
          <>
            <View style={styles.containerRow}>
              <LatencyPieChart
                name={I18n.t("LATENCY_STATS.LATENCY_TRAFFIC.BEST_EFFORT")}
                data={latencyStats?.bestEffortTraffic}
              />
              <LatencyPieChart
                name={I18n.t("LATENCY_STATS.LATENCY_TRAFFIC.BACKGROUND")}
                data={latencyStats?.backgroundTraffic}
              />
            </View>
            <View style={styles.containerRow}>
              <LatencyPieChart
                name={I18n.t("LATENCY_STATS.LATENCY_TRAFFIC.VOICE")}
                data={latencyStats?.voiceTraffic}
              />
              <LatencyPieChart
                name={I18n.t("LATENCY_STATS.LATENCY_TRAFFIC.VIDEO")}
                data={latencyStats?.videoTraffic}
              />
            </View>
          </>
        ) : (
          <NoData />
        )}
      </View>
      <MkiGraphLegend
        legendValues={[
          { label: I18n.t("LATENCY_STATS.LEGEND.GREEN_RANGE"), colors: [MkiColors.goodBar] },
          { label: I18n.t("LATENCY_STATS.LEGEND.YELLOW_RANGE"), colors: [MkiColors.alertingBar] },
          { label: I18n.t("LATENCY_STATS.LEGEND.RED_RANGE"), colors: [MkiColors.badBar] },
        ]}
        isElementSelected={() => true}
      />
    </SummaryCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  noDataText: {
    flex: 1,
    alignSelf: "center",
    padding: SPACING.default,
  },
  containerRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pieChartContainer: {
    flex: 1,
    alignItems: "center",
    padding: SPACING.small,
  },
});

export default LatencyStatsCard;
