import { memo } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

export interface SummaryHeaderProps {
  testID?: string;
  title?: string;
  withAsterisk?: boolean;
}

const renderSuperAsterik = () => <MkiText textStyle={"subheaderSuperScript"}>*</MkiText>;

export const SummaryHeader = memo<SummaryHeaderProps>((props: SummaryHeaderProps) =>
  props.title ? (
    <View style={styles.container}>
      <MkiText textStyle="subheader" screenStyles={styles.titleText}>
        {props.title}
      </MkiText>
      {props.withAsterisk && renderSuperAsterik()}
    </View>
  ) : null,
);

SummaryHeader.displayName = "SummaryHeader";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: SPACING.small,
    paddingTop: SPACING.default,
  },
  titleText: {
    paddingVertical: SPACING.small,
  },
});

export default SummaryHeader;
