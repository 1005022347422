import Svg, { Path } from "react-native-svg";

import { useThemeColors } from "~/shared/hooks/useTheme";

export const ElekidOffIcon = () => {
  const colors = useThemeColors();

  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Path
        d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C22.0893 2 24.0954 2.35595 25.961 3.01051C26.4391 2.43256 26.9985 1.92439 27.6217 1.50354C25.2727 0.534546 22.6988 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 17.3012 39.4655 14.7273 38.4965 12.3783C38.0756 13.0015 37.5674 13.561 36.9895 14.039C37.6441 15.9046 38 17.9108 38 20Z"
        fill="#999999"
      />
      <Path
        d="M32.8398 1.65654C32.3208 1.35383 31.6792 1.35383 31.1602 1.65654L26.8269 4.18432C26.3149 4.483 26 5.03117 26 5.62395V10.376C26 10.9688 26.3149 11.517 26.8269 11.8157L31.1602 14.3435C31.6792 14.6462 32.3208 14.6462 32.8398 14.3435L37.1731 11.8157C37.6851 11.517 38 10.9688 38 10.376V5.62395C38 5.03117 37.6852 4.483 37.1731 4.18432L32.8398 1.65654ZM31.3333 5.49998V8.33331C31.3333 8.7015 31.6318 8.99998 32 8.99998C32.3682 8.99998 32.6667 8.7015 32.6667 8.33331V5.49998C32.6667 5.13179 32.3682 4.83331 32 4.83331C31.6318 4.83331 31.3333 5.13179 31.3333 5.49998ZM32 11.5C32.4602 11.5 32.8333 11.1269 32.8333 10.6666C32.8333 10.2064 32.4602 9.83331 32 9.83331C31.5398 9.83331 31.1667 10.2064 31.1667 10.6666C31.1667 11.1269 31.5398 11.5 32 11.5Z"
        fill={colors.status.offline.color}
      />
      <Path
        d="M37.6852 4.483 37.1731 4.18432L32.8398 1.65654ZM31.3333 5.49998V8.33331C31.3333 8.7015 31.6318 8.99998 32 8.99998C32.3682 8.99998 32.6667 8.7015 32.6667 8.33331V5.49998C32.6667 5.13179 32.3682 4.83331 32 4.83331C31.6318 4.83331 31.3333 5.13179 31.3333 5.49998ZM32 11.5C32.4602 11.5 32.8333 11.1269 32.8333 10.6666C32.8333 10.2064 32.4602 9.83331 32 9.83331C31.5398 9.83331 31.1667 10.2064 31.1667 10.6666C31.1667 11.1269 31.5398 11.5 32 11.5Z"
        strokeWidth="3"
        fill={colors.modal.background}
      />
      <Path
        d="M13.0531 11.2437C12.9176 11.102 12.732 11.0189 12.536 11.0124C12.34 11.0058 12.1493 11.0763 12.0046 11.2087C11.86 11.3411 11.7729 11.5249 11.7621 11.7207C11.7513 11.9165 11.8177 12.1087 11.9469 12.2562L15.6219 16.2969L11.9563 20.2344C11.8702 20.3236 11.808 20.4331 11.7752 20.5526C11.7425 20.6722 11.7402 20.7981 11.7688 20.9187C11.7981 21.039 11.8561 21.1503 11.9377 21.2434C12.0193 21.3364 12.1221 21.4083 12.2375 21.4531L17.6375 23.4781L16.2688 30.35C16.2373 30.5092 16.2576 30.6742 16.3268 30.821C16.396 30.9678 16.5103 31.0886 16.6531 31.1656C16.7608 31.2199 16.8795 31.2488 17 31.25C17.1028 31.2504 17.2044 31.2289 17.2982 31.1868C17.392 31.1448 17.4757 31.0832 17.5438 31.0062L23.3469 24.8L26.9469 28.7562C27.0171 28.833 27.1026 28.8943 27.1978 28.9363C27.293 28.9783 27.396 29 27.5 29C27.6871 28.9982 27.8671 28.9282 28.0063 28.8031C28.08 28.7373 28.1398 28.6574 28.1822 28.5681C28.2246 28.4789 28.2486 28.382 28.253 28.2833C28.2574 28.1846 28.2419 28.086 28.2076 27.9933C28.1733 27.9007 28.1208 27.8158 28.0531 27.7437L13.0531 11.2437Z"
        fill="#999999"
      />
      <Path
        d="M24.8564 21.9969C24.9255 22.0725 25.0095 22.1332 25.103 22.1751C25.1965 22.217 25.2976 22.2393 25.4001 22.2406H25.4095C25.5123 22.2411 25.6139 22.2195 25.7077 22.1775C25.8015 22.1355 25.8852 22.0739 25.9533 21.9969L28.0439 19.7563C28.1293 19.6673 28.1912 19.5585 28.224 19.4396C28.2567 19.3206 28.2592 19.1954 28.2313 19.0753C28.2033 18.9552 28.1458 18.8439 28.064 18.7516C27.9822 18.6594 27.8786 18.589 27.7626 18.5469L22.3626 16.5219L23.7314 9.64064C23.7597 9.48268 23.7367 9.3198 23.6656 9.17592C23.5945 9.03205 23.4791 8.91477 23.3364 8.84133C23.1937 8.76788 23.0312 8.74215 22.8728 8.7679C22.7144 8.79365 22.5685 8.86953 22.4564 8.98439L18.1533 13.5969C18.0235 13.7329 17.9503 13.9133 17.9485 14.1013C17.9468 14.2893 18.0166 14.471 18.1439 14.6094L24.8564 21.9969Z"
        fill="#999999"
      />
    </Svg>
  );
};

export default ElekidOffIcon;
