import { I18n } from "@meraki/core/i18n";
import { List } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { License, useGetLicenses } from "@meraki/shared/api";
import { addHyphensForLicenseKey } from "@meraki/shared/formatters";
import { LicenseGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { getFormattedStringFromDays } from "../utils/LicenseUtils";

type QueuedLicense = {
  headLicenseKey: string;
  licenseKey: string;
};

export function LicensesListScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<LicenseGroupProps>>();
  const route = useRoute<RouteProp<LicenseGroupProps, "LicensesList">>();
  const { filterList } = route.params;
  const organizationId = useCurrentOrganizationId();
  const queuedLicenses: QueuedLicense[] = [];
  const {
    data: licenses,
    isLoading,
    refetch,
  } = useGetLicenses(
    { organizationId },
    {
      select: (data) => {
        const filteredLicenses = data.filter((license) => {
          if (license.permanentlyQueuedLicenses) {
            license.permanentlyQueuedLicenses.forEach((queuedLicense) =>
              queuedLicenses.push({
                headLicenseKey: license.licenseKey ?? "",
                licenseKey: queuedLicense.licenseKey,
              }),
            );
          }
          return filterList && filterList.length > 0
            ? filterList.includes(license.licenseType)
            : true;
        });
        const activeLicenses: License[] = [];
        const inactiveLicenses: License[] = [];
        filteredLicenses.forEach((license) => {
          if (license.state === "unused" || license.state === "expired") {
            inactiveLicenses.push(license);
          } else {
            activeLicenses.push(license);
          }
        });
        return { active: activeLicenses, inactive: inactiveLicenses };
      },
    },
  );

  const getHeadLicenseKey = (licenseKey: string | null) => {
    return queuedLicenses.find((queuedLicense) => queuedLicense.licenseKey === licenseKey)
      ?.headLicenseKey;
  };

  return (
    <Screen>
      {licenses?.active.length !== undefined && (
        <List.FlashList
          data={licenses.active}
          loading={isLoading}
          emptyState={{
            title: I18n.t("UMBRELLA.SUBSCRIPTION.NOT_ADDED"),
            enabled: !licenses?.active.length && !licenses?.inactive.length,
          }}
          onRefresh={refetch}
          label={I18n.t("UMBRELLA.SUBSCRIPTION.ACTIVE_AND_QUEUED")}
          paddingBottom="none"
          getItemData={(license) => {
            const queuedLicenseHeadKey = getHeadLicenseKey(license.licenseKey);
            return {
              title: license.licenseKey && addHyphensForLicenseKey(license.licenseKey),
              description: `${
                license.totalDurationInDays === null
                  ? 0
                  : I18n.t("UMBRELLA.SUBSCRIPTION.TOTAL_DURATION", {
                      totalDuration: getFormattedStringFromDays(license.totalDurationInDays),
                    })
              }${
                queuedLicenseHeadKey
                  ? `\n${I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.QUEUED", {
                      licenseKey: addHyphensForLicenseKey(queuedLicenseHeadKey),
                    })}`
                  : ""
              }`,
              onPress: () => {
                navigation.navigate("License", { license });
              },
              testID: `LICENSE.ACTIVE_QUEUED.${license.licenseKey}`,
            };
          }}
          testID={
            !licenses?.active.length && !licenses?.inactive.length
              ? "LICENSES_LIST_SCREEN.EMPTY"
              : ""
          }
        />
      )}
      {licenses?.inactive.length !== undefined && licenses.inactive.length > 0 && (
        <List.FlashList
          data={licenses.inactive}
          loading={isLoading}
          onRefresh={refetch}
          label={I18n.t("UMBRELLA.SUBSCRIPTION.INACTIVE_AND_EXPIRED")}
          paddingTop="none"
          getItemData={(license) => {
            const queuedLicenseHeadKey = getHeadLicenseKey(license.licenseKey);
            return {
              title: license.licenseKey && addHyphensForLicenseKey(license.licenseKey),
              description: `${
                license.totalDurationInDays === null
                  ? 0
                  : I18n.t("UMBRELLA.SUBSCRIPTION.TOTAL_DURATION", {
                      totalDuration: getFormattedStringFromDays(license.totalDurationInDays),
                    })
              }${
                queuedLicenseHeadKey
                  ? `\n${I18n.t("UMBRELLA.SUBSCRIPTION.ADD_KEY.QUEUED", {
                      licenseKey: addHyphensForLicenseKey(queuedLicenseHeadKey),
                    })}`
                  : ""
              }`,
              onPress: () => {
                navigation.navigate("License", { license });
              },
              testID: `LICENSE.INACTIVE_EXPIRED.${license.licenseKey}`,
            };
          }}
        />
      )}
    </Screen>
  );
}
