import { I18n } from "@meraki/core/i18n";
import { otpAuthUrl } from "@meraki/shared/links";
import { memo } from "react";
import { StyleSheet, View } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import DefaultHeader from "~/go/components/DefaultHeader";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import BaseOnboardingScreen, {
  BaseOnboardingScreenProps,
} from "~/go/screens/onboardingFullstack/BaseOnboardingScreen";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert } from "~/lib/AlertUtils";
import { getNextOnboardingStageConfig } from "~/lib/OnboardingFullstackUtils";
import { isWeb } from "~/lib/PlatformUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import { getTwoFactorAuthInfo } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import DropDownRow from "~/shared/rows/DropDownRow";
import { OnboardingStage } from "~/shared/types/OnboardingTypes";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { TwoFactorScreensPropMap } from "../navigation/Types";

const InfoRow = memo(function InfoRow(props: { label: string; value: string }) {
  const { label, value } = props;

  return (
    <View style={styles.infoView}>
      <MkiText screenStyles={styles.infoRowLabel}>{label}</MkiText>
      <MkiText selectable>{value}</MkiText>
    </View>
  );
});

type ReduxProps = {
  twoFactorInfo:
    | {
        twoFactorUsername: string;
        twoFactorSecret: string;
      }
    | Record<string, never>;
};

type Props = ForwardedNativeStackScreenProps<TwoFactorScreensPropMap, "SetupTwoFactor"> &
  BasicActions &
  BaseOnboardingScreenProps &
  ReduxProps &
  WithCancelablePromiseProps &
  PendingComponent;

export class SetupTwoFactorScreen extends BaseOnboardingScreen<Props> {
  componentDidMount() {
    this.getData();
  }

  handleError = (error: unknown) => {
    showAlert(I18n.t("ERROR"), error);
  };

  getData = async () => {
    const { actions, setReqPending, cancelablePromise } = this.props;

    setReqPending(true);
    try {
      await cancelablePromise(actions.getTwoFactorInfo());
    } catch (error) {
      this.handleError(error);
    }
    setReqPending(false);
  };

  renderHeader = () => {
    return (
      <DefaultHeader
        title={I18n.t("SETUP_TWO_FACTOR.HEADER.TITLE")}
        description={
          isWeb()
            ? I18n.t("SETUP_TWO_FACTOR.HEADER.WEB_DESCRIPTION")
            : I18n.t("SETUP_TWO_FACTOR.HEADER.DESCRIPTION")
        }
      />
    );
  };

  renderAuthLinkButton = () => {
    const { twoFactorInfo } = this.props;
    const { twoFactorUsername, twoFactorSecret } = twoFactorInfo;

    return (
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType={ButtonType.secondary}
          onPress={() => otpAuthUrl(twoFactorUsername, twoFactorSecret)}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("SETUP_TWO_FACTOR.AUTH_BUTTON")}
        </RoundedButton>
      </View>
    );
  };

  renderQRCode = () => {
    const { twoFactorInfo } = this.props;
    const { twoFactorUsername, twoFactorSecret } = twoFactorInfo;

    return (
      <View style={styles.qrContainer}>
        <QRCode
          value={`otpauth://totp/${twoFactorUsername}?secret=${twoFactorSecret}`}
          size={200}
          color="black"
          backgroundColor="white"
          quietZone={SPACING.tiny}
        />
      </View>
    );
  };

  renderExpandable = () => {
    return (
      <DropDownRow title={I18n.t("SETUP_TWO_FACTOR.EXPANDABLE.TITLE")}>
        {this.renderTwoFactorInfo()}
      </DropDownRow>
    );
  };

  renderTwoFactorInfo = () => {
    const { twoFactorInfo } = this.props;
    const { twoFactorUsername, twoFactorSecret } = twoFactorInfo;

    return (
      <View>
        <MkiText textStyle="secondary" screenStyles={styles.itemDescriptionText}>
          {I18n.t("SETUP_TWO_FACTOR.EXPANDABLE.DESCRIPTION")}
        </MkiText>
        <InfoRow
          label={I18n.t("SETUP_TWO_FACTOR.EXPANDABLE.ACCOUNT_NAME")}
          value={twoFactorUsername}
        />
        <InfoRow label={I18n.t("SETUP_TWO_FACTOR.EXPANDABLE.SECRET")} value={twoFactorSecret} />
      </View>
    );
  };

  getFooterData = () => {
    return {
      buttons: [
        <RoundedButton
          onPress={() => this.onPrimaryPress()}
          key={this.nextStageConfig.nextButtonText}
        >
          {this.nextStageConfig.nextButtonText}
        </RoundedButton>,
      ],
    };
  };

  renderBody = () => {
    return (
      <FullScreenContainerView withScroll>
        {this.renderHeader()}
        {isWeb() ? this.renderQRCode() : this.renderAuthLinkButton()}
        {this.renderExpandable()}
      </FullScreenContainerView>
    );
  };

  nextStageConfig = getNextOnboardingStageConfig(OnboardingStage.setupTwoFactor);
}

const styles = StyleSheet.create({
  infoView: {
    paddingHorizontal: SPACING.default,
    paddingVertical: SPACING.small,
  },
  infoRowLabel: {
    color: MkiColors.secondaryTextColor,
    fontSize: normalizedFontSize(13),
  },
  itemDescriptionText: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
  },
  qrContainer: {
    alignSelf: "center",
    margin: SPACING.large,
  },
  buttonContainer: {
    alignSelf: "center",
    margin: SPACING.large,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    twoFactorInfo: getTwoFactorAuthInfo(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withCancelablePromise,
  withPendingComponent,
)(SetupTwoFactorScreen);
