import Svg, { Path } from "react-native-svg";

export const WirelessIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M8.70947 15.5719C9.59453 14.7228 10.7736 14.2486 12.0001 14.2486C13.2266 14.2486 14.4057 14.7228 15.2907 15.5719"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.53125 12.3844C7.26132 10.6962 9.58278 9.75128 12 9.75128C14.4172 9.75128 16.7387 10.6962 18.4688 12.3844"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.35303 9.20629C4.92445 6.67225 8.38969 5.25174 11.9999 5.25174C15.6101 5.25174 19.0753 6.67225 21.6468 9.20629"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 19.875C12.6213 19.875 13.125 19.3713 13.125 18.75C13.125 18.1287 12.6213 17.625 12 17.625C11.3787 17.625 10.875 18.1287 10.875 18.75C10.875 19.3713 11.3787 19.875 12 19.875Z"
        fill={color}
      />
    </Svg>
  );
};

export default WirelessIcon;
