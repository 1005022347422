import {
  BATCH_UPDATE_SWITCH_PORT_SUCCESS,
  SWITCH_PORTS_JSON_SUCCESS,
  UPDATE_SWITCH_PORT_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { merge } from "lodash";

import { convertToPrivateAPIPort, normalizeSwitchPort } from "~/lib/SwitchPortUtils";

const RESOURCE_SPLIT_STRING = "/ports/";

const initialState: any = {};

export default function switchPorts(state = initialState, action: any) {
  switch (action.type) {
    case SWITCH_PORTS_JSON_SUCCESS:
      return {
        ...state,
        [action.meta.id]: normalizeSwitchPort(action.response),
      };
    case BATCH_UPDATE_SWITCH_PORT_SUCCESS: {
      const { serialNumber, portIds } = action.meta;
      const { actions } = action.response;

      const oldPortsState = state[serialNumber];
      const newPortsState = actions.reduce((accum: any, action: any) => {
        const switchPortNum = action.resource.split(RESOURCE_SPLIT_STRING)?.[1];
        const switchPortId = portIds.find((port: any) => port.endsWith(switchPortNum));

        return {
          ...accum,
          [switchPortId]: {
            ...oldPortsState?.[switchPortId],
            ...convertToPrivateAPIPort(action.body),
          },
        };
      }, {});

      return {
        ...state,
        [serialNumber]: merge({}, oldPortsState, newPortsState),
      };
    }
    case UPDATE_SWITCH_PORT_SUCCESS: {
      const { serialNumber, portId } = action.meta;

      return {
        ...state,
        [serialNumber]: {
          ...state?.[serialNumber],
          [portId]: {
            ...state?.[serialNumber]?.[portId],
            ...convertToPrivateAPIPort(action.response),
          },
        },
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
