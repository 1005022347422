import z from "zod";

import { request } from "../../api/request/request";
import { ProductType } from "../../schemas";
import { createInfiniteQuery } from "../createInfiniteQuery";

const ExpandedPortSchema = z.object({
  agg_group_id: z.string(),
  agg_group: z.record(z.string(), z.array(z.number())),
});

export const DeviceEventDataSchema = z
  .object({
    ip: z.optional(z.string()),
    mac: z.optional(z.string()),
    vlan: z.optional(z.string()),
    port: z.optional(z.array(ExpandedPortSchema.or(z.string())).or(z.string())),
    vap: z.optional(z.string()),
    msg: z.optional(z.string()),
    carrier: z.optional(z.string()),
    device: z.optional(z.string()),
    connectivity: z.optional(z.string()),
    vpn_type: z.optional(z.string()),
    contending_mac: z.optional(z.string()),
    pvd_id: z.optional(z.string()),
    extra: z.optional(z.string()),
    connected: z.optional(z.boolean().or(z.string())),
    local_ip: z.optional(z.string()),
    user_id: z.optional(z.string()),
    remote_ip: z.optional(z.string()),
  })
  .describe("EventDataSchema");

export type DeviceEventData = z.infer<typeof DeviceEventDataSchema>;

export const DeviceEventSchema = z
  .object({
    description: z.nullable(z.string()),
    clientId: z.nullable(z.string()),
    clientDescription: z.nullable(z.string()),
    clientMac: z.nullable(z.string()),
    deviceSerial: z.string(),
    occurredAt: z.string(),
    deviceName: z.string(),
    eventData: DeviceEventDataSchema,
    type: z.string(),
    networkId: z.string(),
  })
  .describe("EventSchema");

export const DeviceEventsSchema = z.array(DeviceEventSchema).describe("DeviceEventSchema");

export type Event = z.infer<typeof DeviceEventSchema>;

export const DeviceEventsResponseSchema = z
  .object({
    events: z.array(DeviceEventSchema),
    message: z.nullable(z.string()),
    pageEndAt: z.string(),
    pageStartAt: z.string(),
  })
  .describe("DeviceEventsResponseSchema");

export type DeviceEventsResponse = z.infer<typeof DeviceEventsResponseSchema>;

export type ClientTrackingMethod = "MAC address" | "IP address" | "Unique client identifier";

interface DeviceEventsRequestParams {
  networkId?: string;
  productType?: ProductType;
  productTypes?: ProductType[];
  trackingMethod?: ClientTrackingMethod;
  deviceSerial?: string;
  clientMac?: string;
  clientIp?: string;
  endingBefore?: string;
  startingAfter?: string;
  perPage?: number;
  includedEventTypes?: string[];
}

function buildUrl({ networkId }: DeviceEventsRequestParams) {
  return `/api/v1/networks/${networkId}/events`;
}

function fetchNetworkDeviceEvent({
  networkId,
  ...queryParams
}: DeviceEventsRequestParams): Promise<DeviceEventsResponse> {
  return request(DeviceEventsResponseSchema, "GET", buildUrl({ networkId: networkId }), {
    queryParams,
  });
}

export const useNetworkDeviceEvents = createInfiniteQuery<
  DeviceEventsRequestParams,
  DeviceEventsResponse
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchNetworkDeviceEvent(variables),
  requiredVariables: ["networkId"],
});
