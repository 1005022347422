import { I18n } from "@meraki/core/i18n";
import { SensorAlertingMetricsType, SensorMetricsSchemaType } from "@meraki/shared/api";
import { differenceInDays, formatDistanceToNow } from "date-fns";
import round from "lodash/round";
import numbro from "numbro";

import { MTSensorAlertProfileConditions } from "~/api/schemas/MTSensorAlertProfileSchema";
import { SensorMetric, SensorReading, SensorReadingType } from "~/api/schemas/SensorReading";
import { chartDateFormatter } from "~/lib/formatHelper";
import { discriminate, isNumber } from "~/lib/TypeHelper";
import {
  AMBIENT_NOISE_UNIT_LABEL,
  APPARENT_POWER_LABEL,
  CELSIUS_LABEL,
  CURRENT_LABEL,
  DOOR_CLOSED_LABEL,
  DOOR_OPEN_LABEL,
  DoorValues,
  ENERGY_USAGE,
  FAHRENHEIT_LABEL,
  FREQUENCY_LABEL,
  HUMIDITY_ABBREV_LABEL,
  NO_UNIT_LABEL,
  NO_VALUE_LABEL,
  PM25_UNIT_LABEL,
  POWER_FACTOR_LABEL,
  REAL_POWER_LABEL,
  SensorMetrics,
  SensorMetricsType,
  SensorReadingUnits,
  TEMPERATURE_ABBREV_LABEL,
  TemperatureUnits,
  TVOC_UNIT_LABEL,
  VOLTAGE_LABEL,
  WATER_DETECTION_DRY_LABEL,
  WATER_DETECTION_WET_LABEL,
  WaterDetectionValues,
} from "~/shared/constants/SensorMetrics";
import { SensorChartDatum, SensorChartMetaData, SensorDataValue } from "~/shared/types/SensorStats";

// Reading conversion helpers
export function celsiusToFahrenheit(celsiusVal: number) {
  return (celsiusVal * 9) / 5 + 32;
}
export function fahrenheitToCelsius(fahrenheitVal: number) {
  return ((fahrenheitVal - 32) * 5) / 9;
}

export const convertToTemperatureUnit = (value: number, unit: TemperatureUnits) => {
  switch (unit) {
    case TemperatureUnits.celsius:
      return fahrenheitToCelsius(value);
    case TemperatureUnits.fahrenheit:
      return celsiusToFahrenheit(value);
    default:
      return value;
  }
};

/**
 * @param iaqScore 0 <= iaqScore <= 100
 * @returns
 */

export const getIAQQualitativeText = (iaqScore: number) => {
  if (iaqScore >= 93) {
    return I18n.t("IAQ_SCORE.EXCELLENT");
  } else if (iaqScore >= 80) {
    return I18n.t("IAQ_SCORE.GOOD");
  } else if (iaqScore >= 60) {
    return I18n.t("IAQ_SCORE.FAIR");
  } else if (iaqScore >= 40) {
    return I18n.t("IAQ_SCORE.POOR");
  } else {
    return I18n.t("IAQ_SCORE.INADEQUATE");
  }
};

// Unit labels
export function getTemperatureUnitLabel(unit?: SensorReadingUnits): string {
  if (unit === TemperatureUnits.fahrenheit) {
    return FAHRENHEIT_LABEL;
  } else if (unit === TemperatureUnits.celsius) {
    return CELSIUS_LABEL;
  }
  return NO_UNIT_LABEL;
}

export function getUnitLabel(metric: SensorMetricsType, unit?: SensorReadingUnits): string {
  switch (metric) {
    case SensorMetrics.temperature:
      return getTemperatureUnitLabel(unit);
    case SensorMetrics.humidity:
      return I18n.t("SENSOR_UNIT_LABEL.HUMIDITY_UNIT_LABEL");
    case SensorMetrics.co2:
      return I18n.t("SENSOR_UNIT_LABEL.CO2_UNIT_LABEL");
    case SensorMetrics.tvoc:
      return TVOC_UNIT_LABEL;
    case SensorMetrics.pm25:
      return PM25_UNIT_LABEL;
    case SensorMetrics.ambientNoise:
      return AMBIENT_NOISE_UNIT_LABEL;
    case SensorMetrics.frequency:
      return FREQUENCY_LABEL;
    case SensorMetrics.current:
      return CURRENT_LABEL;
    case SensorMetrics.voltage:
      return VOLTAGE_LABEL;
    case SensorMetrics.apparentPower:
      return APPARENT_POWER_LABEL;
    case SensorMetrics.powerFactor:
      return POWER_FACTOR_LABEL;
    case SensorMetrics.realPower:
      return REAL_POWER_LABEL;
    case SensorMetrics.iaqIndex:
    default:
      return NO_UNIT_LABEL;
  }
}

export function getAbbreviatedUnitLabel(metric: SensorMetricsType): string {
  switch (metric) {
    case SensorMetrics.temperature:
      return TEMPERATURE_ABBREV_LABEL;
    case SensorMetrics.humidity:
      return HUMIDITY_ABBREV_LABEL;
    case SensorMetrics.co2:
      return I18n.t("SENSOR_UNIT_LABEL.CO2_UNIT_LABEL");
    case SensorMetrics.tvoc:
      return TVOC_UNIT_LABEL;
    case SensorMetrics.pm25:
      return PM25_UNIT_LABEL;
    case SensorMetrics.ambientNoise:
      return AMBIENT_NOISE_UNIT_LABEL;
    case SensorMetrics.apparentPower:
      return APPARENT_POWER_LABEL;
    case SensorMetrics.current:
      return CURRENT_LABEL;
    case SensorMetrics.frequency:
      return FREQUENCY_LABEL;
    case SensorMetrics.powerFactor:
      return POWER_FACTOR_LABEL;
    case SensorMetrics.realPower:
      return REAL_POWER_LABEL;
    case SensorMetrics.voltage:
      return VOLTAGE_LABEL;
    case SensorMetrics.energyUsage:
      return ENERGY_USAGE;
    default:
      return NO_UNIT_LABEL;
  }
}

// Value labels
function getRoundedValueLabel(value: SensorDataValue): string {
  if (!isNumber(value)) {
    return NO_VALUE_LABEL;
  }
  return numbro(value).format({ average: true });
}

function getRoundedHalfValueLabel(value: SensorDataValue): string {
  if (!isNumber(value)) {
    return NO_VALUE_LABEL;
  }
  return (Math.round(value * 2) / 2).toString();
}

function getWaterDetectionValueLabel(value: WaterDetectionValues | null): string {
  if (value === WaterDetectionValues.wet) {
    return WATER_DETECTION_WET_LABEL;
  } else if (value === WaterDetectionValues.dry) {
    return WATER_DETECTION_DRY_LABEL;
  }
  return NO_VALUE_LABEL;
}

function getDoorValueLabel(value: DoorValues | SensorDataValue): string {
  if (value === DoorValues.open) {
    return DOOR_OPEN_LABEL;
  } else if (value === DoorValues.closed) {
    return DOOR_CLOSED_LABEL;
  }
  return NO_VALUE_LABEL;
}

function getRoundedElekidLabel(value: SensorDataValue): string {
  if (!isNumber(value)) {
    return NO_VALUE_LABEL;
  }
  return round(value, 2).toString();
}

export function getSensorValueLabel(
  metric: SensorMetricsType,
  value: SensorDataValue,
  unit?: SensorReadingUnits,
): string {
  switch (metric) {
    case SensorMetrics.temperature:
      if (unit === TemperatureUnits.fahrenheit) {
        // We present farhrenheit as whole numbers
        return getRoundedValueLabel(value);
      } else {
        // Celsius may include a decimal place. Use the celsius presentation as
        // the fallback in case the unit parameter is undefined.
        return getRoundedHalfValueLabel(value);
      }
    case SensorMetrics.humidity:
    case SensorMetrics.co2:
    case SensorMetrics.pm25:
    case SensorMetrics.ambientNoise:
    case SensorMetrics.tvoc:
    case SensorMetrics.iaqIndex:
      return getRoundedValueLabel(value);
    case SensorMetrics.waterDetection:
      return getWaterDetectionValueLabel(value);
    case SensorMetrics.door:
      return getDoorValueLabel(value);
    case SensorMetrics.apparentPower:
    case SensorMetrics.realPower:
    case SensorMetrics.powerFactor:
    case SensorMetrics.voltage:
    case SensorMetrics.current:
    case SensorMetrics.frequency:
    case SensorMetrics.energyUsage:
      return getRoundedElekidLabel(value);
    default:
      return NO_VALUE_LABEL;
  }
}

export function getReadingLabel(value: SensorDataValue, metric: SensorMetricsType): string {
  const shortLabel =
    metric === SensorMetrics.pm25 || metric === SensorMetrics.tvoc || metric === SensorMetrics.co2;
  const valueLabel: string = getSensorValueLabel(metric, value);
  const unitLabel: string = getAbbreviatedUnitLabel(metric);
  let readingLabel = valueLabel;

  if (valueLabel !== NO_VALUE_LABEL && unitLabel !== NO_UNIT_LABEL) {
    if (!shortLabel) {
      readingLabel = `${readingLabel} ${unitLabel}`;
    }
  }
  return readingLabel;
}

function getReadingType(metric: SensorMetricsType) {
  switch (metric) {
    case SensorMetrics.iaqIndex:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.IAQ");
    case SensorMetrics.temperature:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.TEMPERATURE");
    case SensorMetrics.humidity:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.HUMIDITY");
    case SensorMetrics.co2:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.CO2");
    case SensorMetrics.tvoc:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.TVOC");
    case SensorMetrics.pm25:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.PM25");
    case SensorMetrics.ambientNoise:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.AMBIENT_NOISE");
    case SensorMetrics.frequency:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.FREQUENCY");
    case SensorMetrics.current:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.CURRENT");
    case SensorMetrics.voltage:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.VOLTAGE");
    case SensorMetrics.energyUsage:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.ENERGY");
    case SensorMetrics.apparentPower:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.APPARENT_POWER");
    case SensorMetrics.realPower:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.REAL_POWER");
    case SensorMetrics.powerFactor:
      return I18n.t("SENSORS_HOME_SCREEN.READING_ABBREVS.POWER_FACTOR");
    default:
      return "";
  }
}

// TODO: Refactor usages of SensorMetrics enum to SensorMetric type; https://jira.ikarem.io/browse/DM-3134
export function getReadingLabelLong(
  value: SensorDataValue,
  metric: SensorMetricsType,
  tempUnit?: SensorReadingUnits,
) {
  const readingTypeLabel = getReadingType(metric);
  const valueLabel = getSensorValueLabel(metric, value);
  const unitLabel = getUnitLabel(metric, tempUnit);
  if (valueLabel !== NO_VALUE_LABEL && (metric !== SensorMetrics.temperature || tempUnit)) {
    let readingLabel = `${valueLabel}${unitLabel}`;
    if (readingTypeLabel) {
      readingLabel = readingTypeLabel.concat(" ", readingLabel);
    }
    return readingLabel;
  }
  return NO_UNIT_LABEL;
}

export function getSensorReadingLabel(reading: SensorReading, tempUnit?: SensorReadingUnits) {
  switch (reading.metric) {
    case "apparentPower":
      return getReadingLabelLong(reading.apparentPower.draw, SensorMetrics.apparentPower);
    case "co2":
      return getReadingLabelLong(reading.co2.concentration, SensorMetrics.co2);
    case "current":
      return getReadingLabelLong(reading.current.draw, SensorMetrics.current);
    case "door":
      return getReadingLabelLong(+reading.door.open, SensorMetrics.door);
    case "frequency":
      return getReadingLabelLong(reading.frequency.level, SensorMetrics.frequency);
    case "humidity":
      return getReadingLabelLong(reading.humidity.relativePercentage, SensorMetrics.humidity);
    case "indoorAirQuality":
      return getReadingLabelLong(reading.indoorAirQuality.score, SensorMetrics.iaqIndex);
    case "noise":
      return getReadingLabelLong(reading.noise.ambient.level, SensorMetrics.ambientNoise);
    case "pm25":
      return getReadingLabelLong(reading.pm25.concentration, SensorMetrics.pm25);
    case "powerFactor":
      return getReadingLabelLong(reading.powerFactor.percentage, SensorMetrics.powerFactor);
    case "realPower":
      return getReadingLabelLong(reading.realPower.draw, SensorMetrics.realPower);
    case "temperature":
      if (tempUnit === TemperatureUnits.fahrenheit) {
        return getReadingLabelLong(
          reading.temperature.fahrenheit,
          SensorMetrics.temperature,
          tempUnit,
        );
      } else if (tempUnit === TemperatureUnits.celsius) {
        return getReadingLabelLong(
          reading.temperature.celsius,
          SensorMetrics.temperature,
          tempUnit,
        );
      }
      return NO_UNIT_LABEL;
    case "tvoc":
      return getReadingLabelLong(reading.tvoc.concentration, SensorMetrics.tvoc);
    case "voltage":
      return getReadingLabelLong(reading.voltage.level, SensorMetrics.voltage);
    case "water":
      return getReadingLabelLong(+reading.water.present, SensorMetrics.waterDetection);
    default:
      return NO_UNIT_LABEL;
  }
}

export const mapToSensorMetric = (metric: SensorMetricsType): SensorMetric => {
  switch (metric) {
    case SensorMetrics.waterDetection:
      return "water";
    case SensorMetrics.ambientNoise:
      return "noise";
    case SensorMetrics.iaqIndex:
      return "indoorAirQuality";
    case SensorMetrics.buttonRelease:
      return "button";
    case SensorMetrics.remoteLockoutSwitch:
      return "downstreamPower";
    default:
      return metric;
  }
};

export const mapToSensorMetricsType = (metric: SensorMetricsSchemaType): SensorMetricsType => {
  switch (metric) {
    case "realPower":
      return SensorMetrics.realPower;
    case "apparentPower":
      return SensorMetrics.apparentPower;
    case "powerFactor":
      return SensorMetrics.powerFactor;
    case "button":
      return SensorMetrics.buttonRelease;
    case "indoorAirQuality":
      return SensorMetrics.iaqIndex;
    case "noise":
      return SensorMetrics.ambientNoise;
    case "water":
      return SensorMetrics.waterDetection;
    default:
      return metric;
  }
};

const sortOrder: Map<SensorMetric | string, number> = new Map([
  ["indoorAirQuality", 1],
  ["co2", 2],
  ["pm25", 3],
  ["tvoc", 4],
  ["temperature", 5],
  ["humidity", 6],
  ["noise", 7],
  ["realPower", 8],
  ["apparentPower", 9],
  ["voltage", 10],
  ["current", 11],
  ["powerFactor", 12],
  ["frequency", 13],
]);

export const sortMetrics = (metrics: SensorMetricsType[]) => {
  const sortedReadings = metrics.sort((metric1, metric2) => {
    const reading1SortOrder = sortOrder.get(metric1);
    const reading2SortOrder = sortOrder.get(metric2);
    return reading1SortOrder && reading2SortOrder ? reading1SortOrder - reading2SortOrder : 0;
  });
  return sortedReadings;
};

const sortReadings = (readings: SensorReading[]) => {
  const sortedReadings = readings.slice().sort((reading1, reading2) => {
    const reading1SortOrder = sortOrder.get(reading1.metric);
    const reading2SortOrder = sortOrder.get(reading2.metric);
    return reading1SortOrder && reading2SortOrder ? reading1SortOrder - reading2SortOrder : 0;
  });
  return sortedReadings;
};

export const formatReadings = (readings: SensorReading[], tempUnit?: TemperatureUnits): string => {
  return sortReadings(readings)
    .map((reading) => {
      if (reading.metric === "indoorAirQuality") {
        return `${getSensorReadingLabel(reading, tempUnit)} ${getIAQQualitativeText(
          Number(makeReading(SensorMetrics.iaqIndex, readings).reading),
        )}`;
      }
      return getSensorReadingLabel(reading, tempUnit);
    })
    .filter(Boolean)
    .join(", ");
};

export const getXAxisFormatter =
  (timespan: number, timezone: string) =>
  (timestamp: number): string =>
    chartDateFormatter(timestamp, timespan, timezone);

export const getYAxisFormatter =
  (metric: SensorMetricsType) =>
  (value: SensorDataValue): string => {
    return getReadingLabel(value, metric);
  };

const makeRound = (mathFn: (x: number) => number, roundTo: number) =>
  function round(num: number) {
    return mathFn(num / roundTo) * roundTo;
  };
export const roundDown = makeRound(Math.floor, 10);
export const roundUp = makeRound(Math.ceil, 10);

export const toChartDomain = (
  sensorMetaData: SensorChartMetaData,
  alertProfileConditions: MTSensorAlertProfileConditions[] = [],
) => {
  const defaultYDomain = [20, 80];
  const domainBuffer = 10;
  const withDefaultZero = (val?: unknown) => (isNumber(val) ? val : 0);
  const { max, min, t0, t1 } = sensorMetaData;
  const thresholds = alertProfileConditions.map((condition) =>
    isNumber(condition.threshold) ? condition.threshold : min,
  );

  const lowerY = Math.min(min, ...thresholds);
  const upperY = Math.max(max, ...thresholds);
  // Sensor has no readings, so use a default range just for displaying an empty graph.
  if (lowerY === 0 && upperY === 0) {
    return {
      x: [t0, t1],
      y: defaultYDomain,
    };
  }
  return {
    x: [t0, t1],
    y: [
      roundDown(Math.ceil(withDefaultZero(lowerY)) - domainBuffer),
      roundUp(Math.floor(withDefaultZero(upperY)) + domainBuffer),
    ],
  };
};

export const getMetadataProperty = (dataPoint: SensorChartDatum, metadataProperty: string) => {
  switch (metadataProperty) {
    case "max":
      return dataPoint.max;
    case "min":
      return dataPoint.min;
    case "avg":
    default:
      return dataPoint.avg;
  }
};

export const getSensorName = (sensorMetric: SensorMetricsType | string): string => {
  switch (sensorMetric) {
    case SensorMetrics.iaqIndex:
      return I18n.t("SENSOR_NAME.INDOOR_AIR_QUALITY");
    case SensorMetrics.temperature:
      return I18n.t("SENSOR_NAME.TEMPERATURE");
    case SensorMetrics.humidity:
      return I18n.t("SENSOR_NAME.HUMIDITY");
    case SensorMetrics.co2:
      return I18n.t("SENSOR_NAME.CO2");
    case SensorMetrics.tvoc:
      return I18n.t("SENSOR_NAME.TVOC");
    case SensorMetrics.pm25:
      return I18n.t("SENSOR_NAME.PM25");
    case SensorMetrics.ambientNoise:
      return I18n.t("SENSOR_NAME.AMBIENT_NOISE");
    case SensorMetrics.frequency:
      return I18n.t("SENSOR_NAME.FREQUENCY");
    case SensorMetrics.current:
      return I18n.t("SENSOR_NAME.CURRENT");
    case SensorMetrics.voltage:
      return I18n.t("SENSOR_NAME.VOLTAGE");
    case SensorMetrics.energyUsage:
      return I18n.t("SENSOR_NAME.ENERGY_USAGE");
    case SensorMetrics.apparentPower:
      return I18n.t("SENSOR_NAME.APPARENT_POWER");
    case SensorMetrics.realPower:
      return I18n.t("SENSOR_NAME.REAL_POWER");
    case SensorMetrics.powerFactor:
      return I18n.t("SENSOR_NAME.POWER_FACTOR");
    default:
      return "";
  }
};

const lookupSensorMetric = (
  metric: SensorMetricsType,
  readings: SensorReadingType,
  tempUnit?: TemperatureUnits,
) => {
  switch (metric) {
    case SensorMetrics.realPower:
      const realPowerInfo = readings.find(discriminate("metric", "realPower"));
      return {
        reading: realPowerInfo?.realPower.draw,
        time: realPowerInfo ? new Date(realPowerInfo?.ts) : undefined,
      };
    case SensorMetrics.current:
      const currentInfo = readings.find(discriminate("metric", "current"));
      return {
        reading: currentInfo?.current.draw,
        time: currentInfo ? new Date(currentInfo?.ts) : undefined,
      };
    case SensorMetrics.voltage:
      const voltageInfo = readings.find(discriminate("metric", "voltage"));
      return {
        reading: voltageInfo?.voltage.level,
        time: voltageInfo ? new Date(voltageInfo?.ts) : undefined,
      };
    case SensorMetrics.apparentPower:
      const apparentPowerInfo = readings.find(discriminate("metric", "apparentPower"));
      return {
        reading: apparentPowerInfo?.apparentPower.draw,
        time: apparentPowerInfo ? new Date(apparentPowerInfo?.ts) : undefined,
      };
    case SensorMetrics.powerFactor:
      const powerFactorInfo = readings.find(discriminate("metric", "powerFactor"));
      return {
        reading: powerFactorInfo?.powerFactor.percentage,
        time: powerFactorInfo ? new Date(powerFactorInfo?.ts) : undefined,
      };
    case SensorMetrics.frequency:
      const frequencyInfo = readings.find(discriminate("metric", "frequency"));
      return {
        reading: frequencyInfo?.frequency.level,
        time: frequencyInfo ? new Date(frequencyInfo?.ts) : undefined,
      };
    case SensorMetrics.downstreamPower:
      const downstreamPowerInfo = readings.find(discriminate("metric", "downstreamPower"));
      return {
        reading: downstreamPowerInfo?.downstreamPower?.enabled,
        time: downstreamPowerInfo ? new Date(downstreamPowerInfo?.ts) : undefined,
      };
    case SensorMetrics.remoteLockoutSwitch:
      const remoteLockoutSwitchInfo = readings.find(discriminate("metric", "remoteLockoutSwitch"));
      return {
        reading: remoteLockoutSwitchInfo?.remoteLockoutSwitch?.locked,
        time: remoteLockoutSwitchInfo ? new Date(remoteLockoutSwitchInfo?.ts) : undefined,
      };

    case SensorMetrics.iaqIndex:
      const iaqIndexInfo = readings.find(discriminate("metric", "indoorAirQuality"));
      return {
        reading: iaqIndexInfo?.indoorAirQuality?.score,
        time: iaqIndexInfo ? new Date(iaqIndexInfo?.ts) : undefined,
      };
    case SensorMetrics.temperature:
      if (tempUnit === TemperatureUnits.fahrenheit) {
        const tempFahrenheitInfo = readings.find(discriminate("metric", "temperature"));
        return {
          reading: tempFahrenheitInfo?.temperature?.fahrenheit,
          time: tempFahrenheitInfo ? new Date(tempFahrenheitInfo?.ts) : undefined,
        };
      } else if (tempUnit === TemperatureUnits.celsius) {
        const tempCelsiusInfo = readings.find(discriminate("metric", "temperature"));
        return {
          reading: tempCelsiusInfo?.temperature?.celsius,
          time: tempCelsiusInfo ? new Date(tempCelsiusInfo?.ts) : undefined,
        };
      }
      return { reading: NO_UNIT_LABEL, time: undefined };
    case SensorMetrics.humidity:
      const humidityInfo = readings.find(discriminate("metric", "humidity"));
      return {
        reading: humidityInfo?.humidity?.relativePercentage,
        time: humidityInfo ? new Date(humidityInfo?.ts) : undefined,
      };
    case SensorMetrics.co2:
      const co2Info = readings.find(discriminate("metric", "co2"));
      return {
        reading: co2Info?.co2?.concentration,
        time: co2Info ? new Date(co2Info?.ts) : undefined,
      };
    case SensorMetrics.tvoc:
      const tvocInfo = readings.find(discriminate("metric", "tvoc"));
      return {
        reading: tvocInfo?.tvoc?.concentration,
        time: tvocInfo ? new Date(tvocInfo?.ts) : undefined,
      };
    case SensorMetrics.pm25:
      const pm25Info = readings.find(discriminate("metric", "pm25"));
      return {
        reading: pm25Info?.pm25?.concentration,
        time: pm25Info ? new Date(pm25Info?.ts) : undefined,
      };
    case SensorMetrics.ambientNoise:
      const ambientNoiseInfo = readings.find(discriminate("metric", "noise"));
      return {
        reading: ambientNoiseInfo?.noise?.ambient.level,
        time: ambientNoiseInfo ? new Date(ambientNoiseInfo?.ts) : undefined,
      };

    case SensorMetrics.door:
      const doorInfo = readings.find(discriminate("metric", "door"));
      return { reading: doorInfo?.door.open, time: doorInfo ? new Date(doorInfo?.ts) : undefined };

    case SensorMetrics.waterDetection:
      const waterDetectioninfo = readings.find(discriminate("metric", "water"));
      return {
        reading: waterDetectioninfo?.water.present,
        time: waterDetectioninfo ? new Date(waterDetectioninfo?.ts) : undefined,
      };
    default:
      return { reading: undefined, time: undefined };
  }
};

export const getSensorReadings = (
  metric: SensorMetricsType,
  readings: SensorReadingType,
  tempUnit?: TemperatureUnits,
) => {
  return lookupSensorMetric(metric, readings, tempUnit).reading;
};

const getReadingWithUnits = (
  sensorMetric: SensorMetricsType,
  value: number | string | boolean | undefined,
  tempUnit?: TemperatureUnits,
) => {
  const unit = getUnitLabel(sensorMetric, tempUnit);
  const precision = sensorMetric === SensorMetrics.iaqIndex ? 0 : 1;
  return isNumber(value) ? `${round(value ?? 0, precision)} ${unit}` : "";
};

export type FormattedReadingType = {
  label: SensorMetricsType;
  reading: string;
  time: string | Date;
};

export const makeReading = (
  label: SensorMetricsType,
  readings: SensorReadingType,
  tempUnit?: TemperatureUnits,
): FormattedReadingType => ({
  label,
  reading: getReadingWithUnits(label, getSensorReadings(label, readings, tempUnit), tempUnit),
  time: getMetricReadingTime(label, readings, tempUnit) ?? "",
});

export const makeMetricReadings = (
  metrics: SensorMetricsType[],
  readings: SensorReadingType,
  temperatureUnit?: TemperatureUnits,
) => {
  const sortedMetrics = sortMetrics(metrics);
  const metricReadings = sortedMetrics.map((metric) => {
    return makeReading(metric, readings, temperatureUnit);
  });
  return metricReadings;
};

export const getMetricReadingTime = (
  metric: SensorMetricsType,
  readings: SensorReadingType,
  tempUnit?: TemperatureUnits,
) => {
  return lookupSensorMetric(metric, readings, tempUnit).time;
};

export const getLatestReadingTime = (readings: FormattedReadingType[]) => {
  const allTimes = readings.map((reading) => {
    return new Date(reading.time).getTime();
  });
  const latestReadingTime = allTimes.reduce((readingTime1, readingTime2) => {
    if (readingTime2 > readingTime1) {
      return readingTime2;
    }
    return readingTime1;
  });

  return !isNaN(latestReadingTime)
    ? formatDistanceToNow(latestReadingTime, {
        includeSeconds: true,
        addSuffix: true,
      })
    : "...";
};

export const isStale = (metric: SensorMetricsType, readings: SensorReadingType) => {
  const filteredReadings = readings.filter((reading) => {
    const now = new Date();
    const readingTime = new Date(reading.ts);
    return differenceInDays(now, readingTime) > 1;
  });
  return filteredReadings
    .map((readings) => {
      return mapToSensorMetricsType(readings.metric);
    })
    .flat()
    .includes(metric);
};

const mapToAlertingMetricType = (metric: SensorMetricsType) => {
  switch (metric) {
    case SensorMetrics.iaqIndex:
      return "iaqIndex";
    case SensorMetrics.ambientNoise:
      return "ambientNoise";
    default:
      return metric;
  }
};

export const isAlerting = (
  metric: SensorMetricsType,
  alertingMetrics?: SensorAlertingMetricsType[],
) => {
  // ex: API response returns "iaqIndex" for alerting metrics but is "indoorAirQuality" in supported metrics
  const correctedMetric = mapToAlertingMetricType(metric);
  return !!alertingMetrics?.find(
    (alertingMetric) =>
      alertingMetric.toLowerCase().includes(correctedMetric.toLowerCase()) ||
      correctedMetric.toLowerCase().includes(alertingMetric.toLowerCase()),
  );
};
