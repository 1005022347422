import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { celsiusToFahrenheit } from "~/lib/sensor/SensorReadingUtils";
import { isNumber } from "~/lib/TypeHelper";
import { getAllSensorStats, getTemperatureUnit } from "~/selectors/getters";
import { timespanState } from "~/selectors/preferences";
import {
  Agg,
  DoorValues,
  SensorMetrics,
  SensorMetricsType,
  TemperatureUnits,
} from "~/shared/constants/SensorMetrics";
import { RootState } from "~/shared/types/Redux";
import { SensorChartData, SensorChartDatum, SensorStat } from "~/shared/types/SensorStats";

// Selectors shared by all sensors

const emptySensorStatsByMetric = {};
export const getSensorStatsByMetricForSerial = createSelector(
  getAllSensorStats,
  (_: RootState, serial: string) => serial,
  (allSensorStats, serial) => {
    return allSensorStats?.[serial] || emptySensorStatsByMetric;
  },
);

const emptySensorStat = {};
export const getSensorStatForMetric = (metric: SensorMetricsType) =>
  createSelector(
    getSensorStatsByMetricForSerial,
    (sensorStatsByMetric) => sensorStatsByMetric?.[metric] || emptySensorStat,
  );

export const getLatestSensorStat = createSelector(
  getSensorStatsByMetricForSerial,
  (_p1, _p2, metric) => metric,
  getTemperatureUnit,
  (sensorStatsByMetric, metric, temperatureUnit: TemperatureUnits | undefined) => {
    const value = sensorStatsByMetric?.[metric]?.latest?.value;
    const isFetchingLatest = sensorStatsByMetric?.[metric]?.isFetchingLatest;

    if (
      !!value &&
      metric === SensorMetrics.temperature &&
      temperatureUnit === TemperatureUnits.fahrenheit
    ) {
      return { value: celsiusToFahrenheit(value), isFetchingLatest };
    }
    return { value, isFetchingLatest };
  },
);

// Water sensor selectors
export const getSensorWaterDetectionStat = getSensorStatForMetric(SensorMetrics.waterDetection);

// Door sensor selectors and utils
export const getSensorDoorStat = getSensorStatForMetric(SensorMetrics.door);
export const getLatestDoorStatData = (state: RootState, serial: string) =>
  getSensorDoorStat(state, serial).latest;
export const getIsFetchingLatestDoorStatData = createSelector(
  getSensorDoorStat,
  (doorStat) => doorStat.isFetchingLatest,
);
export const getIsFetchingDoorCountByValue = createSelector(
  getSensorDoorStat,
  (doorStat) => doorStat.isFetchingCountByValue,
);
const emptyCountByValue = {};
export const getDoorCountByValue = createSelector(
  getSensorDoorStat,
  (doorStat) => doorStat.countByValue ?? emptyCountByValue,
);
const noOpenDoors = 0;
export const getNumOpenDoors = createSelector(
  getDoorCountByValue,
  (countByValue) => countByValue[DoorValues.open] || noOpenDoors,
);

// Selectors and utilities shared by temperature and humidity selectors
const emptyChartData: any = [];
const fromStatsToChartData = (
  averageStat?: SensorStat,
  maximumStat?: SensorStat,
  minimumStat?: SensorStat,
) => {
  if (!averageStat) {
    return emptyChartData;
  }

  const chartData: SensorChartData = (averageStat?.data || []).map(
    ({ value, timestamp }, index) => {
      const maximumDatum = (maximumStat?.data || [])[index];
      const minimumDatum = (minimumStat?.data || [])[index];
      const sensorChartDatum: SensorChartDatum = {
        avg: value,
        timestamp,
      };

      if (isNumber(maximumDatum?.value)) {
        sensorChartDatum.max = maximumDatum?.value;
      }
      if (isNumber(minimumDatum?.value)) {
        sensorChartDatum.min = minimumDatum?.value;
      }
      return sensorChartDatum;
    },
  );

  return chartData;
};

const fromStatsToMetaData = (
  averageStat?: SensorStat,
  maximumStat?: SensorStat,
  minimumStat?: SensorStat,
) => {
  if (!averageStat) {
    return;
  }
  const { t0, t1 } = averageStat;
  const current = averageStat.latest?.value;
  const avg = averageStat.avg;
  const max = (maximumStat || averageStat).max;
  const min = (minimumStat || averageStat).min;
  return { avg, current, max, min, t0, t1 };
};

// Temperature specific utils and selectors

const convertTemperature = (
  temperatureStat: SensorStat,
  temperatureUnit: TemperatureUnits | undefined,
) => {
  // Temperature stats are returned as Celsius from the API
  if (
    temperatureUnit === TemperatureUnits.celsius ||
    isEmpty(temperatureStat) ||
    isEmpty(temperatureUnit)
  ) {
    return temperatureStat;
  }
  const { avg, data, latest, max, min, ...other } = temperatureStat;
  return {
    avg: avg && celsiusToFahrenheit(avg),
    data: data.map(({ timestamp, value }) => ({ timestamp, value: celsiusToFahrenheit(value) })),
    latest: latest && { timestamp: latest.timestamp, value: celsiusToFahrenheit(latest.value) },
    max: max && celsiusToFahrenheit(max),
    min: min && celsiusToFahrenheit(min),
    ...other,
  };
};

const getSensorStatsByTimespan = (agg: Agg) =>
  createSelector(
    getSensorStatsByMetricForSerial,
    timespanState,
    (_p1, _p2, metricType) => metricType,
    (sensorStatsByMetric, timespan, metricType) =>
      sensorStatsByMetric[`${metricType}:${agg}:${timespan}`],
  );

const sensorStatsSelectors = [
  getSensorStatsByTimespan(Agg.Avg),
  getSensorStatsByTimespan(Agg.Max),
  getSensorStatsByTimespan(Agg.Min),
];

export const getSensorChartData = createSelector(sensorStatsSelectors, fromStatsToChartData);

export const getSensorMetaData = createSelector(sensorStatsSelectors, fromStatsToMetaData);

const temperatureStatSelectors = [
  createSelector(getSensorStatsByTimespan(Agg.Avg), getTemperatureUnit, convertTemperature),
  createSelector(getSensorStatsByTimespan(Agg.Max), getTemperatureUnit, convertTemperature),
  createSelector(getSensorStatsByTimespan(Agg.Min), getTemperatureUnit, convertTemperature),
];

export const getSensorTemperatureCharData = createSelector(
  temperatureStatSelectors,
  fromStatsToChartData,
);

export const getSensorTemperatureMetaData = createSelector(
  temperatureStatSelectors,
  fromStatsToMetaData,
);
