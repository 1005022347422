import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

export const twoFourGhzSettingsSchema = z.object({
  channel: z.number().nullable(),
  targetPower: z.number().nullable(),
});

export const fiveAndSixGhzSettingsSchema = twoFourGhzSettingsSchema.extend({
  // make just number if MR/ API team fix this
  channelWidth: z.number().or(z.string()).nullable(),
});

export const WirelessRadioSettingsSchema = z.object({
  serial: z.string(),
  rfProfileId: z.string().nullable(),
  twoFourGhzSettings: twoFourGhzSettingsSchema.optional().nullable(),
  fiveGhzSettings: fiveAndSixGhzSettingsSchema.optional().nullable(),
});

export type twoFourRadioBandSettings = z.infer<typeof twoFourGhzSettingsSchema>;
export type fiveAndSixRadioBandSettings = z.infer<typeof fiveAndSixGhzSettingsSchema>;

export type WirelessRadioSettings = z.infer<typeof WirelessRadioSettingsSchema>;

interface WirelessRadioSettingsRequest {
  serialNumber: string;
}

interface MutateWirelessRadioSettingsRequest extends WirelessRadioSettingsRequest {
  body: Omit<WirelessRadioSettings, "serial">;
}

const buildUrl = ({ serialNumber }: WirelessRadioSettingsRequest) => {
  return `/api/v1/devices/${serialNumber}/wireless/radio/settings`;
};

const fetchWirelessRadioSettings = ({ serialNumber }: WirelessRadioSettingsRequest) => {
  return request(WirelessRadioSettingsSchema, "GET", buildUrl({ serialNumber }));
};

export const useWirelessRadioSettings = createQuery({
  baseQueryKey: buildUrl({ serialNumber: `{serialNumber}` }),
  queryFn: (parameters: WirelessRadioSettingsRequest) => fetchWirelessRadioSettings(parameters),
  requiredVariables: ["serialNumber"],
});

const updateWirelessRadioSettings = ({
  serialNumber,
  body,
}: MutateWirelessRadioSettingsRequest) => {
  return request(WirelessRadioSettingsSchema, "PUT", buildUrl({ serialNumber }), {
    // fiveGhzSettings.channelWidth should be cast to string
    body: JSON.stringify(body),
  });
};

export const useUpdateWirelessRadioSettings = createMutation<
  MutateWirelessRadioSettingsRequest,
  WirelessRadioSettings,
  APIResponseError
>({
  baseMutationKey: buildUrl({ serialNumber: `{serialNumber}` }),
  mutationFn: (parameters) => updateWirelessRadioSettings({ ...parameters }),
});
