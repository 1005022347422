import {
  SM_CHECK_IN_FAILURE,
  SM_CHECK_IN_REQUEST,
  SM_CHECK_IN_SUCCESS,
  SM_LOCK_FAILURE,
  SM_LOCK_REQUEST,
  SM_LOCK_SUCCESS,
  SM_WIPE_FAILURE,
  SM_WIPE_REQUEST,
  SM_WIPE_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction } from "~/middleware/api";
import { currentNetworkState } from "~/selectors";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function wipe(deviceId: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    return dispatch(
      wrapApiActionWithCSRF({
        types: [SM_WIPE_REQUEST, SM_WIPE_SUCCESS, SM_WIPE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sm/device/wipe`,
        config: {
          method: Method.put,
          body: JSON.stringify({ id: deviceId }),
        },
      }),
    );
  };
}

export function lock(
  deviceId: string,
  lockPin?: number,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    const body: Record<string, string | number> = { ids: deviceId };
    if (lockPin) {
      body.pin = lockPin;
    }
    return dispatch(
      wrapApiActionWithCSRF({
        types: [SM_LOCK_REQUEST, SM_LOCK_SUCCESS, SM_LOCK_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sm/devices/lock`,
        config: {
          method: Method.put,
          body: JSON.stringify(body),
        },
      }),
    );
  };
}

export function checkin(deviceId: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    return dispatch(
      wrapApiActionWithCSRF({
        types: [SM_CHECK_IN_REQUEST, SM_CHECK_IN_SUCCESS, SM_CHECK_IN_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sm/devices/checkin`,
        config: {
          method: Method.put,
          body: JSON.stringify({ ids: deviceId }),
        },
      }),
    );
  };
}
