import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import { Button, Heading, List, Text } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSsid, useSsids } from "@meraki/shared/api";
import { queryClient, useUpdateSsid } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect } from "react";
import Toast from "react-native-simple-toast";

export function RadioSettingsScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const route = useRoute<RouteProp<SsidGroupProps, "RadioSettings">>();
  const networkId = useCurrentNetworkId();
  const updateSsid = useUpdateSsid();

  const { params: props } = route;
  const { data: ssid } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  useEffect(
    () =>
      navigation.setOptions({
        headerLeft: () => (
          <Button.Nav
            leadingIcon="CaretLeft"
            text={ssid?.name ?? ""}
            onPress={() => navigation.goBack()}
          />
        ),
      }),
    [navigation, ssid?.name],
  );

  const updateBand = (band: string) => {
    updateSsid.mutate(
      { networkId, ssidNumber: ssid?.number, body: { bandSelection: band } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: useSsid.queryKeyRoot });
          queryClient.invalidateQueries({ queryKey: useSsids.queryKeyRoot });
          Toast.showWithGravity(
            I18n.t("RADIO_SETTINGS.SUCCESS_TOAST", { band }),
            Toast.SHORT,
            Toast.TOP,
          );
        },
        onError: (error) =>
          Toast.showWithGravity(
            error?.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT"),
            Toast.SHORT,
            Toast.TOP,
          ),
      },
    );
  };
  return (
    <Screen addDefaultPadding>
      <Box bottomDividerBorder padding="xs">
        <Heading size="h1">{I18n.t("RADIO_SETTINGS.HEADER")}</Heading>
      </Box>
      <Box padding="xs">
        <Text size="p2">{I18n.t("RADIO_SETTINGS.MESSAGE")}</Text>
      </Box>
      <List>
        <List.RadioItem
          title={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND.LABEL")}
          description={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND.SUBLABEL")}
          onRadioValueChange={(band) => updateBand(band)}
          radioValue="Dual band operation"
          checkedRadioValue={String(ssid?.bandSelection)}
          rightAccessory={<Icon name="Gear" />}
          testID={`DUAL_BAND.${
            String(ssid?.bandSelection) === "Dual band operation" ? "SELECTED" : ""
          }`}
        />
        <List.RadioItem
          title={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.FIVE_GHZ_ONLY.LABEL")}
          description={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.FIVE_GHZ_ONLY.SUBLABEL")}
          onRadioValueChange={(band) => updateBand(band)}
          radioValue="5 GHz band only"
          checkedRadioValue={String(ssid?.bandSelection)}
          rightAccessory={<Icon name="Gear" />}
          testID={`FIVE_GHZ_BAND${
            String(ssid?.bandSelection) === "5 GHz band only" ? ".SELECTED" : ""
          }`}
        />
        <List.RadioItem
          title={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.TWO_GHZ_ONLY.LABEL")}
          description={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.TWO_GHZ_ONLY.SUBLABEL")}
          onRadioValueChange={(band) => updateBand(band)}
          radioValue="2.4 GHz band only"
          checkedRadioValue={String(ssid?.bandSelection)}
          rightAccessory={<Icon name="Gear" />}
        />
        <List.RadioItem
          title={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND_WITH_BAND_STEERING.LABEL")}
          description={I18n.t("RADIO_SETTINGS.BAND_OPTIONS.DUAL_BAND_WITH_BAND_STEERING.SUBLABEL")}
          onRadioValueChange={(band) => updateBand(band)}
          radioValue="Dual band operation with Band Steering"
          checkedRadioValue={String(ssid?.bandSelection)}
          rightAccessory={<Icon name="Gear" />}
        />
      </List>
    </Screen>
  );
}
