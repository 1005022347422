import { I18n } from "@meraki/core/i18n";
import { Input, Modal, Notification, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useCallback, useEffect, useRef, useState } from "react";
import { TextInput } from "react-native";

import {
  addSerialHyphens,
  validateDeviceSerialCharacters,
  validateSerialLength,
} from "../utils/DeviceUtils";

const singleDeviceWord = { single_device_word: I18n.t("DEVICE_WORD") };

type AddSerialModalProps = {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (serial: string) => void;
};

export function AddSerialModal({ visible, loading, onClose, onSubmit }: AddSerialModalProps) {
  const textInputRef = useRef<TextInput>(null);
  const [serial, setSerial] = useState("");
  const [serialError, setSerialError] = useState<string>();

  useEffect(() => {
    if (visible) {
      setSerial("");
      setSerialError(undefined);

      textInputRef?.current?.focus();
    }
  }, [visible]);

  const handleSerialChange = useCallback(
    (text: string) => {
      if (!validateSerialLength(text)) {
        setSerialError(undefined);
      }

      if (text.length > serial.length) {
        const error = validateDeviceSerialCharacters(text);
        if (!error) {
          setSerial(addSerialHyphens(text));
          return;
        }

        setSerialError(error);
      } else if (text.length < serial.length) {
        setSerial(text);
        setSerialError(undefined);
      }
    },
    [serial],
  );

  const handleSubmit = useCallback(() => {
    if (!validateSerialLength(serial)) {
      setSerialError(I18n.t("ENTER_SERIAL_INSUFFICIENT_LENGTH"));
      return;
    }

    onSubmit(serial);
  }, [serial, setSerialError, onSubmit]);

  return (
    <Modal
      title={I18n.t("ENTER_SERIAL_TEXT", singleDeviceWord)}
      visible={visible}
      okButtonProps={{ loading }}
      okText={I18n.t("ENTER_SERIAL_CLAIM")}
      onOk={handleSubmit}
      cancelText={I18n.t("CANCEL")}
      onCancel={onClose}
      onClose={onClose}
    >
      <Box gap="sm">
        <Text>{I18n.t("ENTER_SERIAL_MESSAGE", singleDeviceWord)}</Text>
        <Input
          ref={textInputRef}
          label={I18n.t("SERIAL_NUMBER")}
          placeholder={I18n.t("DASHES_INSTRUCTION")}
          autoCapitalize="characters"
          value={serial}
          errored={!!serialError}
          onChangeText={handleSerialChange}
          onSubmitEditing={handleSubmit}
          maxLength={14}
          testID="SERIAL.INPUT"
        />
        {!!serialError && <Notification.Inline status="negative" message={serialError} />}
      </Box>
    </Modal>
  );
}
