import { I18n } from "@meraki/core/i18n";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { ICON_HEADER_SIZE } from "~/constants/MkiConstants";
import IconSectionHeader from "~/go/components/IconSectionHeader";
import { productTypeDevicesSelector, slimSsidsSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { ProductType } from "~/shared/types/Networks";

export const BroadcastingAccessPointsCard = ({ onPress, ssidNumber }: any) => {
  const ssid = useSelector(slimSsidsSelector)[ssidNumber];
  const devices = useAppSelector((state) => {
    const { availabilityTags, availableOnAllAps } = ssid;
    let accessPoints = productTypeDevicesSelector(state, ProductType.wireless);
    if (!availableOnAllAps) {
      accessPoints = accessPoints.filter((device) => device.tags?.includes(availabilityTags[0]));
    }
    return accessPoints;
  });

  return (
    <View style={styles.container}>
      <IconSectionHeader
        customIcon={
          <MerakiIcon
            name="radio-tower"
            size={ICON_HEADER_SIZE}
            color={MkiColors.defaultIconColor}
          />
        }
        header={I18n.t("HOME.BROADCASTING_AP.TITLE")}
        subtitle={I18n.t("HOME.BROADCASTING_AP.SUBTITLE")}
      />
      <QuickStatusBox
        value={devices.length}
        subText={
          devices.length === 1
            ? I18n.t("HOME.BROADCASTING_AP.ACCESS_POINT")
            : I18n.t("HOME.BROADCASTING_AP.ACCESS_POINTS")
        }
        onPress={onPress}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
});

export default BroadcastingAccessPointsCard;
