import { I18n } from "@meraki/core/i18n";
import { List, Loader, SearchBar } from "@meraki/magnetic/components";
import { Status, StatusType } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import {
  formatOrgDeviceOverview,
  OrgChooseResponseType,
  standardizeLoginResponse,
  Status as OrgStatus,
  useGlobalOrgDeviceOverview,
  useOrgChoose,
} from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import { PostLoginProps } from "./Types";

const getStatus = (
  orgId: string,
  orgStatuses: Record<string, OrgStatus> | undefined,
): StatusType | undefined => {
  if (!orgStatuses) {
    return undefined;
  }
  const orgStatus = orgStatuses?.[orgId];

  switch (orgStatus) {
    case "online":
      return "positive";
    case "alerting":
      return "warning";
    case "offline":
      return "negative";
    case "dormant":
    default:
      return "neutral";
  }
};

export const OrgChoose = ({
  setLatestResponse,
  response,
}: PostLoginProps & { response: OrgChooseResponseType }) => {
  const navigation = useNavigation();
  const [searchText, setSearchText] = useState("");
  const [eid, setEid] = useState("");

  // we have to run an initial org choose call in order to get a valid auth in order to fetch org statuses.
  // sometimes the response of this will come back that the user *also* needs to do OTP, in which case
  // we have to redirect them to OTP. Otherwise we ignore the response for this.
  const { data: initialOrgChoose } = useOrgChoose({ eid: response.orgs[0]?.eid ?? "" });

  if (initialOrgChoose) {
    const standardResponse = standardizeLoginResponse(initialOrgChoose);
    if (standardResponse?.mode !== "success") {
      setLatestResponse(standardResponse);
    }
  }

  const { data: orgChooseResponse, isFetched } = useOrgChoose({ eid }, { enabled: eid !== "" });

  // this call is to a public API endpoint so can only occur after we've validated a user's login and they've
  // "selected" an org. Since we are showing this data on the org choose screen *before* they've selected an
  // org, we send through an login/org_choose call that is ignored by the app purely so the user is
  // authenticated, so we can then load org statuses.

  // todo: even with "enabled" this request runs immediately after the org choose on line 51 finishes and so the
  // first call always returns a 401 :(( it needs to wait like a second longer before running, but there
  // is no clean way to do that.
  const { data: orgStatuses } = useGlobalOrgDeviceOverview(
    {},
    { enabled: !!initialOrgChoose, select: (data) => formatOrgDeviceOverview(data) },
  );

  if (orgChooseResponse && isFetched) {
    const standardResponse = standardizeLoginResponse(orgChooseResponse);
    setLatestResponse(standardResponse);
  }

  useEffect(() => {
    navigation.setOptions({
      headerTitle: I18n.t("ORGANIZATION.CHOOSE_ORG"),
    });
  }, [navigation]);

  const organizations = searchText.length
    ? response.orgs.filter((org) => org.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
    : response.orgs;

  return (
    <Box>
      <Box padding="sm" paddingTop="none" bottomDividerBorder>
        <SearchBar
          placeholder={I18n.t("ORGANIZATION.SEARCH_PLACEHOLDER")}
          value={searchText}
          onChangeText={setSearchText}
        />
      </Box>
      <List.FlashList
        data={organizations}
        emptyState={{ title: "No Organizations Found" }}
        getItemData={(org) => {
          const status = getStatus(org.id, orgStatuses);

          return {
            title: org.name,
            leftAccessory: status ? <Status status={status} /> : <Loader.Spinner />,
            onPress: () => {
              setEid(org.eid);
            },
          };
        }}
      />
    </Box>
  );
};
