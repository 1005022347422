import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Wireless({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M9.417 16.512a4.756 4.756 0 0 1 5.166 0c.463.3.46.945.069 1.335l-1.945 1.945a1 1 0 0 1-1.414 0l-1.945-1.945c-.39-.39-.394-1.035.069-1.335Z"
        fill={color1}
      />
      <Path
        d="M17.48 15.019c-.39.39-1.02.383-1.463.054a6.755 6.755 0 0 0-8.034 0c-.443.33-1.073.336-1.463-.054l-1.06-1.06c-.391-.391-.394-1.029.032-1.38a10.253 10.253 0 0 1 13.016 0c.426.351.423.989.033 1.38l-1.06 1.06Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
      <Path
        d="M21.369 11.13c-.39.39-1.022.386-1.442.028-4.561-3.879-11.293-3.879-15.854 0-.42.358-1.052.362-1.442-.028L1.57 10.07c-.39-.391-.392-1.028.022-1.393 5.938-5.237 14.878-5.237 20.815 0 .415.365.413 1.002.023 1.392l-1.061 1.06Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color3}
      />
    </Svg>
  );
}
