import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { ReactNode } from "react";
import { Platform } from "react-native";
import {
  Route,
  TabBar as TabViewTabBar,
  TabBarProps as RNTabBarProps,
} from "react-native-tab-view";

import { Text } from "../Text/Text";

export type ExtendedRoute = Route & {
  iconNode?: ReactNode;
};

type TabBarProps<T extends ExtendedRoute> = Omit<RNTabBarProps<T>, "scrollEnabled">;

export function TabBar<T extends ExtendedRoute>(props: TabBarProps<T>) {
  const theme = useMagneticTheme();

  return (
    <TabViewTabBar
      {...props}
      style={{
        backgroundColor: theme.color.transparent,
        ...Platform.select({
          web: {
            boxShadow: "none",
          },
        }),
      }}
      tabStyle={{ width: "auto", minWidth: 100 }}
      indicatorStyle={{
        backgroundColor: theme.color.brandAccent.border.active,
        height: 3,
        borderRadius: 2,
      }}
      scrollEnabled
      renderLabel={({ route, focused }) => {
        return (
          <Box flexDirection="row" alignItems="center" gap="sm">
            <Text size="p1" color={focused ? "regular" : "light"}>
              {route.title}
            </Text>
            {route.iconNode}
          </Box>
        );
      }}
    />
  );
}
