import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";

import { failedConnectionsForSSIDs } from "~/selectors";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import SummaryCard from "~/shared/components/SummaryCard";
import GeneralStatus from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";

const MAX_FAILED_CONNECTIONS = 10;

const FailedConnectionForSSID = ({ ssidName, failedConnections, onPress }: any) => {
  const numberOfFailedConnections = failedConnections?.length || 0;
  return (
    <QuickStatusBox
      value={numberOfFailedConnections}
      subText={ssidName}
      status={
        numberOfFailedConnections < MAX_FAILED_CONNECTIONS ? GeneralStatus.good : GeneralStatus.bad
      }
      onPress={onPress}
    />
  );
};

const FailedConnectionsCount = () => {
  const ssidSortedFailedConnections = useAppSelector(failedConnectionsForSSIDs);
  const navigation = useNavigation();
  const pushFailedConnectionsList = (searchPrefill = "") =>
    navigation.navigate("FailedWirelessConnectionsList", { searchPrefill });
  return (
    <View>
      <View style={styles.statusBoxes}>
        {Object.keys(ssidSortedFailedConnections).map((ssidName, index) => (
          <FailedConnectionForSSID
            key={`${index}`}
            ssidName={ssidName}
            failedConnections={ssidSortedFailedConnections[ssidName]}
            onPress={() => pushFailedConnectionsList(ssidName)}
          />
        ))}
      </View>
      <DisclosureRow isFullButton onPress={() => pushFailedConnectionsList()}>
        {I18n.t("SEE_ALL")}
      </DisclosureRow>
    </View>
  );
};

export function FailedConnectionsCard({ isLoading }: any) {
  return (
    <SummaryCard
      heading={I18n.t("FAILED_CONNECTIONS_CARD.HEADING")}
      subheading={I18n.t("FAILED_CONNECTIONS_CARD.SUBHEADING")}
      loading={isLoading}
    >
      <FailedConnectionsCount />
    </SummaryCard>
  );
}

const styles = StyleSheet.create({
  statusBoxes: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default FailedConnectionsCard;
