export enum SupportCaseStatusOptions {
  supportPending = "Support Action Pending",
  customerPending = "Customer Action Pending",
  closed = "Closed",
}

export enum SupportCaseAbstractedIdentifiers {
  open = "OPEN",
  closed = "CLOSED",
  support = "SUPPORT",
  you = "YOU",
}

export enum CaseInfoInteractionTypes {
  comment = "comment",
  email = "email",
}

export interface Interaction {
  type: string;
  preview: string;
  createdDate: string;
  //comments
  createdByName?: string;
  commentBody?: string;
  //emails
  parentContactEmail: string;
  textBody: string;
  status: string;
  incoming: string;
  ccAddress: string;
  toAddress: string;
  fromAddress: string;
  fromName: string;
  subject: string;
}

export interface SupportCaseComment {
  commentBody: string;
  createdByName: string;
  createdDate: string;
}

export interface SupportCase {
  caseNumber: string;
  caseThreadId: string;
  contactEmail: string;
  contactName: string;
  createdDate: string;
  customerAssignedPriority: string;
  description: string;
  id: string;
  lastModifiedDate: string;
  organization: {
    id: string;
    name: string;
    shard_id: string;
    url: string;
  };
  organizationNumber: string;
  ownerName: string;
  status: string;
  statusDetails?: string;
  subject: string;
  suppliedEmail: string;
  suppliedName: string;
  comments?: SupportCaseComment[];
}
