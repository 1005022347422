import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type RoomsObject = {
  [key: string]: number | null;
};

type NetworkRoomObject = {
  [key: string]: RoomsObject;
};

type ApPlacementRooms = {
  roomsByNetwork: NetworkRoomObject;
  setRoomsByNetwork: (rooms: RoomsObject, networkId?: string) => void;
  removeRoomByNetwork: (room: string, networkId?: string) => void;
  resetRoomsByNetwork: (networkId?: string) => void;
};

export const useApPlacementRooms = create<ApPlacementRooms>()(
  persist(
    (set) => ({
      roomsByNetwork: {},
      setRoomsByNetwork: (rooms: RoomsObject, networkId?: string) =>
        set((state) => {
          if (!networkId) {
            return state;
          }
          return {
            ...state,
            roomsByNetwork: { ...state.roomsByNetwork, [networkId]: rooms },
          };
        }),
      removeRoomByNetwork: (room: string, networkId?: string) =>
        set((state) => {
          if (!networkId) {
            return state;
          }

          const newRooms = { ...state.roomsByNetwork[networkId] };
          delete newRooms[room];

          if (newRooms) {
            return {
              ...state,
              roomsByNetwork: {
                ...state.roomsByNetwork,
                [networkId]: newRooms,
              },
            };
          }
          return state;
        }),
      resetRoomsByNetwork: (networkId?: string) =>
        set((state) => {
          if (!networkId) {
            return state;
          }
          return {
            ...state,
            roomsByNetwork: {
              ...state.roomsByNetwork,
              [networkId]: {},
            },
          };
        }),
    }),
    {
      name: "ApPlacementRooms",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);
