import * as Association from "../handlers/Association2";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useAssociation(
  clientNodeGroupId?: string,
  clientMac?: string,
): LiveTool<Association.Result> {
  const msg = useLiveBroker<Association.Props, Association.Message>({
    type: "Association2",
    networkId: clientNodeGroupId,
    clientMac,
  });

  return useCachedFormatter(msg, Association.formatMsg);
}
