import { ReactElement, ReactNode } from "react";
import { ScrollView, ScrollViewProps, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MkiKeyboardAwareScrollView from "~/shared/components/MkiKeyboardAwareScrollView";

export interface FullScreenContainerViewProps extends ScrollViewProps {
  children?: ReactNode;
  screenStyles?: StyleProp<ViewStyle>;
  withScroll?: boolean;
  ignoreKeyboardAvoiding?: boolean;
  defaultMargins?: boolean;
  defaultPadding?: boolean;
  disableVerticalOffset?: boolean;
}

const FullScreenContainerView = ({
  children,
  screenStyles,
  withScroll,
  ignoreKeyboardAvoiding,
  defaultMargins = false,
  defaultPadding = false,
  disableVerticalOffset = false,
  ...rest
}: FullScreenContainerViewProps): ReactElement => {
  const containerStyles = [styles.container, screenStyles];
  if (defaultMargins) {
    containerStyles.push(styles.defaultMargins);
  }
  if (defaultPadding) {
    containerStyles.push(styles.defaultPadding);
  }

  if (!withScroll) {
    return (
      <View style={containerStyles} {...rest}>
        {children}
      </View>
    );
  }

  const derivedProps: ScrollViewProps = {
    style: containerStyles,
    keyboardDismissMode: "on-drag",
    keyboardShouldPersistTaps: "handled",
    ...rest,
  };

  return ignoreKeyboardAvoiding ? (
    <ScrollView {...derivedProps}>{children}</ScrollView>
  ) : (
    <MkiKeyboardAwareScrollView disableVerticalOffset={disableVerticalOffset} {...derivedProps}>
      {children}
    </MkiKeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  defaultMargins: {
    margin: SPACING.default,
  },
  defaultPadding: {
    padding: SPACING.default,
  },
});

export default FullScreenContainerView;
