import {
  FETCH_EXPORTS_FAILURE,
  FETCH_EXPORTS_REQUEST,
  FETCH_EXPORTS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { VideoExport } from "~/enterprise/types/VideoExport";
import { ApiResponseAction } from "~/middleware/api";

export const initialState = {
  exportList: [],
  isInitialFetch: false,
};

type MaybeVideoExport = VideoExport | null;

const isVideoExport = (maybeVideoExport: MaybeVideoExport): maybeVideoExport is VideoExport =>
  maybeVideoExport !== null;

export default function fetchAllExports(
  state: { exportList: VideoExport[]; isInitialFetch: boolean } = initialState,
  action: ApiResponseAction<MaybeVideoExport[]>,
): { exportList: VideoExport[]; isInitialFetch: boolean } {
  switch (action.type) {
    case FETCH_EXPORTS_REQUEST:
      return {
        ...state,
        isInitialFetch: !action.meta.forPolling,
      };
    case FETCH_EXPORTS_SUCCESS:
      let updatedArray;
      const safeResponseData = action.response.filter(isVideoExport);
      if (action.meta.forPolling) {
        updatedArray = state.exportList.map(
          (obj: VideoExport) => safeResponseData.find((o) => o.id == obj.id) || obj,
        );
      } else {
        const sortedResponse = safeResponseData.sort(function (a, b) {
          // TODO: Remove parseInt if scheduled_for_time is always a number
          // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          return parseInt(b.scheduled_for_time) - parseInt(a.scheduled_for_time);
        });

        const nonWorkingJobs = sortedResponse.filter(
          (obj: VideoExport) => !isWorkingJobs(obj.state),
        );
        const workingJobs = sortedResponse.filter((obj: VideoExport) => isWorkingJobs(obj.state));
        updatedArray = [...workingJobs, ...nonWorkingJobs];
      }

      return {
        ...state,
        exportList: updatedArray,
        isInitialFetch: false,
      };
    case FETCH_EXPORTS_FAILURE:
      return {
        ...state,
        isInitialFetch: false,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}

const isWorkingJobs = (export_state: any) =>
  export_state == "export_running" || export_state == "export_requested";
