import { KeyboardTypeOptions, StyleSheet } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import MultiPartUnderlinedTextInput, {
  MultiPartUnderlinedTextInputProps,
  MultiPartUnderlinedTextInputState,
} from "~/go/components/textInputs/MultiPartUnderlinedTextInput";
import I18n from "~/i18n/i18n";
import MkiText from "~/shared/components/MkiText";
import { PortRangeParts } from "~/shared/types/PortRange";

export interface PortRangeTextInputProps extends MultiPartUnderlinedTextInputProps, PortRangeParts {
  onPortsChange: (startingPort?: number, endingPort?: number) => void;
}

const DEFAULT_PLACEHOLDER_PORTS = ["80", "65535"];
const MAX_NETWORK_PORT_LENGTH = 5;
const PORT_RANGE_PART_COUNT = 2;

interface PortRangeTextInputState extends MultiPartUnderlinedTextInputState {
  ports: (number | undefined)[];
}

class PortRangeTextInput extends MultiPartUnderlinedTextInput<
  PortRangeTextInputProps,
  PortRangeTextInputState
> {
  static defaultProps = {
    footer: I18n.t("PORT_FORWARDING.PORT_RANGE_FOOTER"),
  };

  constructor(props: PortRangeTextInputProps) {
    super(props);

    const { startingPort, endingPort } = props;
    this.state = { ports: [startingPort, endingPort] };
  }

  numParts = PORT_RANGE_PART_COUNT;

  maxLength = MAX_NETWORK_PORT_LENGTH;

  keyboardType: KeyboardTypeOptions = "number-pad";

  separator = (
    <MkiText screenStyles={styles.hyphen} textStyle="default">
      -
    </MkiText>
  );

  value = (index: number) => {
    const { ports } = this.state;
    return ports[index] ? String(ports[index]) : "";
  };

  placeholder = (index: number) => {
    const { ports } = this.state;
    const placeholders = ports[0] || ports[1] ? ["", ""] : DEFAULT_PLACEHOLDER_PORTS;
    return placeholders[index];
  };

  placeholderTextColor = () => undefined;

  onChangeText = (text: string, index: number) => {
    const { onPortsChange } = this.props;
    const { ports } = this.state;

    ports[index] = Number(text);

    if (onPortsChange) {
      onPortsChange(ports[0], ports[1]);
    }

    if (text.length === 5) {
      this.endFocusAtIndex(index);
    }

    this.setState({ ports: [...ports] });
  };

  editable = () => true;

  styleForPart = () => styles.textInput;
}

const styles = StyleSheet.create({
  hyphen: {
    marginTop: SPACING.small,
    marginHorizontal: SPACING.small,
  },
  textInput: {
    minWidth: 65,
  },
});

export default PortRangeTextInput;
