import { PRESENCE_DATA_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";
import { Reducer } from "redux";

import { LocationAnalyticState } from "~/go/types/LocationAnalyticsTypes";

const initialState: LocationAnalyticState = {
  items: {},
};

const locationAnalytics: Reducer<LocationAnalyticState> = (state = initialState, action) => {
  switch (action.type) {
    case PRESENCE_DATA_SUCCESS: {
      const { networkId, timespan } = action.meta;
      const presenceData = action.response.map((element: any) => {
        return {
          timestamp: element[0],
          data: element[1],
        };
      });
      const presenceState = {
        t0: action.meta.t0,
        t1: action.meta.t1,
        presenceData,
      };

      let statePresenceData: any = { ...state.items[networkId] };
      if (timespan) {
        statePresenceData[timespan] = presenceState;
      } else {
        statePresenceData = { ...statePresenceData, ...presenceState };
      }

      return {
        ...state,
        items: {
          ...state.items,
          [networkId]: statePresenceData,
        },
      };
    }
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
};

export default locationAnalytics;
