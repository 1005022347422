// @ts-expect-error TS(7016): Could not find a declaration file for module 'psl'... Remove this comment to see the full error message
import psl from "psl";

type ParsedDomain = {
  tld: string;
  sld: string;
  domain: string;
  subdomain?: string;
};

export function isValidUrl(urlString: string) {
  return psl.isValid(urlString);
}

export function getHostFromUrl(urlString: string) {
  const newUrl = urlString.replace(/^https?:\/\//, "");
  const parsedDomain: ParsedDomain = psl.parse(newUrl);
  return parsedDomain.sld;
}
