import { useTheme } from "@meraki/core/theme";
import { cloneElement } from "react";
import { KeyboardTypeOptions, StyleSheet } from "react-native";

import {
  IP_ADDRESS_PART_COUNT,
  IP_ADDRESS_WITH_SUBNET_MAX_PART_COUNT,
  MAX_ADDRESS_PART_LENGTH,
  MAX_SUBNET_MASK_PART_LENGTH,
  SUBNET_MASK_INDEX,
} from "~/constants/IPAddress";
import { KEYBOARD_TYPE, SPACING } from "~/constants/MkiConstants";
import MultiPartUnderlinedTextInput, {
  MultiPartUnderlinedTextInputProps,
  MultiPartUnderlinedTextInputState,
} from "~/go/components/textInputs/MultiPartUnderlinedTextInput";
import {
  extractNetworkPrefixParts,
  extractSubnetMask,
  formatAddressPart,
  indexOfFirstEditableAddressPart,
} from "~/lib/IPAddressUtils";
import { themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";

export interface IPAddressTextInputProps<T> extends MultiPartUnderlinedTextInputProps {
  cidrAddress: string;
  leaveEditableBlank: boolean;
  name?: keyof T;
  onAddressChange: (address: string, name?: keyof T) => void;
  placeholders: readonly [string, string, string, string, string];
  editable: boolean;
  includeSubnetMask?: boolean;
}

interface IPAddressTextInputState extends MultiPartUnderlinedTextInputState {
  addressParts: string[];
  firstEditableIndex: number;
}

export class IPAddressTextInput<T> extends MultiPartUnderlinedTextInput<
  IPAddressTextInputProps<T>,
  IPAddressTextInputState
> {
  static defaultProps = {
    cidrAddress: "",
    leaveEditableBlank: true,
    placeholders: ["192", "168", "128", "20", "30"],
    editable: true,
  };

  constructor(props: IPAddressTextInputProps<T>) {
    super(props);
    this.state = this.extractStateFromProps(props);
  }

  componentDidUpdate = (prevProps: IPAddressTextInputProps<T>) => {
    if (prevProps.cidrAddress !== this.props.cidrAddress) {
      this.setState(this.extractStateFromProps(this.props));
    }
  };

  extractStateFromProps(props: IPAddressTextInputProps<T>) {
    const { cidrAddress, leaveEditableBlank, includeSubnetMask } = props;
    const addressParts = cidrAddress ? extractNetworkPrefixParts(cidrAddress) : ["", "", "", ""];
    let subnetMask: number | null = cidrAddress ? extractSubnetMask(cidrAddress) : NaN;
    if (isNaN(subnetMask)) {
      subnetMask = null;
    }

    let firstEditableIndex = 0;
    if (leaveEditableBlank) {
      firstEditableIndex = indexOfFirstEditableAddressPart(subnetMask);
      addressParts.splice(firstEditableIndex);
    }

    if (includeSubnetMask) {
      this.numParts = IP_ADDRESS_WITH_SUBNET_MAX_PART_COUNT;

      if (subnetMask != null) {
        addressParts.push(subnetMask.toString());
      }
    }

    return { addressParts, firstEditableIndex };
  }

  numParts = IP_ADDRESS_PART_COUNT;

  maxLength = (index: number) => {
    if (index === SUBNET_MASK_INDEX) {
      return MAX_SUBNET_MASK_PART_LENGTH;
    }

    return MAX_ADDRESS_PART_LENGTH;
  };

  keyboardType: KeyboardTypeOptions = KEYBOARD_TYPE.decimalPad;

  separator = (
    <MkiText screenStyles={styles.decimal} textStyle="default">
      .
    </MkiText>
  );

  subnetMaskSeparator = (
    <MkiText screenStyles={styles.slash} textStyle="default">
      /
    </MkiText>
  );

  separatorForIndex = (index: number) => {
    if (index === 0) {
      return null;
    }
    if (index === SUBNET_MASK_INDEX) {
      return cloneElement(this.subnetMaskSeparator, { key: `separator-${index}` });
    }

    return cloneElement(this.separator, { key: `separator-${index}` });
  };

  value = (index: number) => {
    const { addressParts } = this.state;
    return addressParts[index];
  };

  placeholder = (index: number) => {
    const { placeholders } = this.props;
    return placeholders[index];
  };

  placeholderTextColor = () => {
    const { theme } = useTheme.getState();
    return themeColors(theme).text?.placeholder?.color;
  };

  isFirstEditableTextInputIndex = (index: number) => index <= this.state.firstEditableIndex;

  onChangeText = (text: string, index: number) => {
    const { onAddressChange, name, includeSubnetMask } = this.props;
    const { addressParts } = this.state;
    const enteredDecimal = text.indexOf(".") !== -1;

    addressParts[index] = formatAddressPart(text);

    if (onAddressChange) {
      let ipAddressString = addressParts.slice(0, 4).join(".");
      if (includeSubnetMask) {
        ipAddressString += `/${addressParts[4]}`;
      }
      onAddressChange(ipAddressString, name);
    }

    if (text.length === MAX_ADDRESS_PART_LENGTH || enteredDecimal) {
      this.endFocusAtIndex(index);
    }

    this.setState({ addressParts: [...addressParts] });
  };

  editable = (index: number) => this.props.editable && index >= this.state.firstEditableIndex;

  styleForPart = () => styles.textInput;
}

const styles = StyleSheet.create({
  decimal: {
    marginTop: SPACING.small,
    marginHorizontal: SPACING.tiny,
  },
  slash: {
    marginTop: SPACING.small,
    marginHorizontal: SPACING.small,
  },
  textInput: {
    width: "12%",
  },
});

export default IPAddressTextInput;
