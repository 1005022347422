import { I18n } from "@meraki/core/i18n";
import { MonitorStackProps } from "@meraki/go/navigation-type";
import { LineChart } from "@meraki/magnetic/charts";
import { Card, Heading, List, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { UplinkUsage, useUplinkUsages } from "@meraki/react-live-broker";
import {
  ChannelUtilizationStats,
  ClientType,
  Device,
  UNCONFIGURED_REGEX,
  useChannelUtilizationByDevice,
  useClients,
  useSsids,
} from "@meraki/shared/api";
import { PickerCard } from "@meraki/shared/components";
import { highUsageClientFilter } from "@meraki/shared/filters";
import { formatKibibytesPerSecond } from "@meraki/shared/formatters";
import { useCachedTimestampFormatter } from "@meraki/shared/formatters";
import {
  oneHour,
  oneMonth,
  tenMinutes,
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useDeviceDetails } from "../DeviceDetailsContext";

type ApData = {
  timestamp: number;
  wifi0: number | null;
  wifi1: number | null;
};

const getDataPointsForAP = (apUtilization: ChannelUtilizationStats | undefined): ApData[] => {
  const data: ApData[] = [];

  if (apUtilization === undefined) {
    return data;
  }

  const data24GHz = apUtilization.wifi0.map((packet) => ({
    timestamp: Date.parse(packet.start_ts),
    wifi0: packet.utilization,
  }));
  const data5GHz = apUtilization.wifi1.map((packet) => ({
    timestamp: Date.parse(packet.start_ts),
    wifi1: packet.utilization,
  }));

  //converts AP data from data24GHz and data5GHz into a combined array
  for (let i = 0; i < Math.min(data24GHz.length, data5GHz.length); i++) {
    const wifiData0 = data24GHz[i];
    const wifiData1 = data5GHz[i];
    if (wifiData0 && wifiData1) {
      data.push({
        timestamp: wifiData0.timestamp,
        wifi0: wifiData0.wifi0,
        wifi1: wifiData1.wifi1,
      });
    }
  }
  return data;
};

const AccessPointCards = ({ device }: { device: Device }) => {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();
  const timespan = useGlobalTimespan();
  const timespanFormatter = useCachedTimestampFormatter(timespan);

  const { data: ssids, isLoading: isLoadingSSIDs } = useSsids(
    { networkId },
    {
      select: (data) => {
        return data.filter(
          ({ availableOnAllAps, availabilityTags, name }) =>
            !name.match(UNCONFIGURED_REGEX) &&
            (availableOnAllAps || availabilityTags?.every((tag) => device.tags.includes(tag))),
        );
      },
    },
  );

  const { data: apUtilization, isLoading: isLaodingApUtilization } = useChannelUtilizationByDevice({
    organizationId,
    networkIds: networkId ? [networkId] : [],
    serials: [device.serial],
    timespan,
    interval: timespan >= oneMonth ? oneHour : tenMinutes,
  });

  return (
    <>
      <List
        loading={isLoadingSSIDs}
        label={I18n.t("DEVICE_INSIGHTS_TAB.BROADCASTING")}
        testID="DEVICE_INSIGHTS_TAB.BROADCASTING"
      >
        {ssids != null &&
          ssids.map((ssid) => (
            <List.Item
              title={ssid.name}
              key={ssid.number}
              onPress={() => navigation.navigate("Ssid", { ssidNumber: ssid.number })}
            />
          ))}
      </List>
      <Card
        loading={isLaodingApUtilization}
        loadingContentHeight={60}
        label={I18n.t("DEVICE_INSIGHTS_TAB.CHANNEL_UTILIZATION")}
        testID="DEVICE_INSIGHTS_TAB.CHANNEL_UTILIZATION"
      >
        <Card.Content>
          <LineChart
            size="lg"
            data={getDataPointsForAP(apUtilization)}
            dimensions={[
              { name: "timestamp" },
              { name: "wifi0", displayName: "2.4GHz" },
              { name: "wifi1", displayName: "5GHz" },
            ]}
            yAxisMin={0}
            xAxisFormatter={timespanFormatter}
            yAxisFormatter={(value) => `${value}%`}
          />
        </Card.Content>
      </Card>
    </>
  );
};

const formatUplinkData = (uplink: UplinkUsage[] | undefined) => {
  let max = 0;

  const data: {
    timestamp: number;
    download: number;
    upload: number;
  }[] = [];

  function setNewMax(usageValue: number) {
    if (usageValue > max) {
      max = usageValue;
    }
    return usageValue;
  }

  if (uplink) {
    uplink.slice(-60).forEach((usage) => {
      data.push({
        timestamp: usage[0],
        download: setNewMax(usage[1].in),
        upload: setNewMax(usage[1].out),
      });
    });
  }
  return data;
};

const ApplianceUplinkCard = ({ device }: { device: Device }) => {
  const uplinkData = useUplinkUsages(device.id);
  const timespan = useGlobalTimespan();
  const timespanFormatter = useCachedTimestampFormatter(timespan);

  return (
    <Card label={I18n.t("DEVICE_INSIGHTS_TAB.LIVE_USAGE")} testID="DEVICE_INSIGHTS_TAB.LIVE_USAGE">
      <Card.Content>
        <LineChart
          size="lg"
          data={formatUplinkData(uplinkData?.wan0)}
          dimensions={[
            { name: "timestamp" },
            { name: "download", displayName: I18n.t("DEVICE_INSIGHTS_TAB.DOWNLOAD") },
            { name: "upload", displayName: I18n.t("DEVICE_INSIGHTS_TAB.UPLOAD") },
          ]}
          xAxisFormatter={timespanFormatter}
          yAxisFormatter={(value) => formatKibibytesPerSecond(Number(value))}
        />
      </Card.Content>
    </Card>
  );
};

export const InsightsTab = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MonitorStackProps>>();
  const { device: deviceDetails } = useDeviceDetails();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const filterBySerial = (clients: ClientType[]) =>
    clients.filter((client) => client.recentDeviceSerial === deviceDetails?.serial);

  const { data: clients, isLoading: isLoadingClients } = useClients(
    { networkId, timespan },
    {
      select: filterBySerial,
      enabled: !!deviceDetails,
    },
  );

  const numHighUsageClients = clients ? highUsageClientFilter(clients).length : 0;
  const numUniqueClients = clients?.length ?? 0;

  return (
    <Screen addDefaultPadding testID="INSIGHTS_TAB">
      <PickerCard.Time title={I18n.t("DEVICE_INSIGHTS_TAB.FILTER_BY")} />
      <Box paddingBottom="sm" gap="sm">
        <Card
          loading={isLoadingClients}
          loadingContentHeight={60}
          onPress={() => navigation.navigate("ClientList", { customFilter: filterBySerial })}
        >
          <Card.Header title={I18n.t("CLIENTS_OVERVIEW_CARD.TITLE")} />
          {clients == null && <Text size="p2">{I18n.t("CLIENTS_OVERVIEW_CARD.NO_CLIENTS")}</Text>}
          {clients != null && (
            <Card.Content flexDirection="row" gap="sm">
              <Card.Well
                flex={1}
                onPress={() =>
                  navigation.navigate("ClientList", {
                    title: I18n.t("CLIENTS_OVERVIEW_CARD.TITLE"),
                    customFilter: filterBySerial,
                    initialFilters: {
                      clientType: new Set(["networkOnly"]),
                      isHighUsage: new Set(["isHighUsage"]),
                    },
                  })
                }
              >
                <Heading size="h1">{numHighUsageClients}</Heading>
                <Text size="p3" weight="semiBold">
                  {I18n.t("CLIENTS_OVERVIEW_CARD.HIGH")}
                </Text>
                {numHighUsageClients > 0 && <Status status="negative" />}
              </Card.Well>
              <Card.Well flex={1}>
                <Heading size="h1">{numUniqueClients}</Heading>
                <Text size="p3" weight="semiBold">
                  {I18n.t("CLIENTS_OVERVIEW_CARD.UNIQUE")}
                </Text>
              </Card.Well>
            </Card.Content>
          )}
        </Card>
        {deviceDetails?.productType === "wireless" && <AccessPointCards device={deviceDetails} />}
        {deviceDetails?.productType === "appliance" && (
          <ApplianceUplinkCard device={deviceDetails} />
        )}
      </Box>
    </Screen>
  );
};
