import * as errorMonitor from "@meraki/core/errors";
import { LiveSwitchPorts, LiveToolMsg } from "@meraki/react-live-broker";

import { SwitchPort } from "~/api/schemas/SwitchPort";
import { DeviceChassis, PortBlockData, PortStatus, PortTypes } from "~/constants/PortLayouts";
import { switchportIsAlerting, switchportIsConnected } from "~/lib/SwitchPortStatusUtils";

import { generatePortBlock } from "./PortUtils";
import { startsWith } from "./StringUtils";

const getNumPorts = (model: string) => {
  const modelToUpperCase = model.toUpperCase();
  const matches =
    modelToUpperCase.match(/[M|G]S\d+\w?-*(\d*)\w*/) ?? modelToUpperCase.match(/C\w+-*(\d+)\w*/);

  if (!matches) {
    const errorMsg = `Unsupported switch model: ${modelToUpperCase}`;
    errorMonitor.notify(errorMsg, modelToUpperCase);
    throw Error(errorMsg);
  }

  // Exceptions to the regex above
  // Older EOL models, we should avoid adding too many conditionals here
  if (modelToUpperCase === "MS22" || modelToUpperCase === "MS22P") {
    return 24;
  } else if (modelToUpperCase === "MS42" || modelToUpperCase === "MS42P") {
    return 48;
  }

  // catalyst models
  if (modelToUpperCase === "C9800-L-C-K9") {
    return 6;
  } else if (modelToUpperCase === "C9800-L-F-K9") {
    return 4;
  }

  return Number.parseInt(matches[1]);
};

export function getSwitchLayout(model: string): PortBlockData {
  const numPorts = getNumPorts(model);

  if (startsWith(model, ["MS120-8", "GS110-8"])) {
    return {
      chassis: DeviceChassis.desktop,
      blocks: [
        { type: PortTypes.rj45, num: numPorts, rows: 1 },
        { type: PortTypes.sfp, num: 2, rows: 1 },
      ],
    };
  }

  if (startsWith(model, ["MS130-8"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.rj45, num: numPorts, rows: 1 },
        { type: PortTypes.sfp, num: 2, rows: 1 },
      ],
    };
  }

  if (startsWith(model, ["MS220-8"])) {
    return {
      chassis: DeviceChassis.desktop,
      blocks: [
        { type: PortTypes.rj45, num: numPorts, rows: 1 },
        { type: PortTypes.sfp, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS120", "MS125"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.rj45, numPorts, 12, 2),
        { type: PortTypes.sfp, num: 4, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["GS110"])) {
    return {
      chassis: DeviceChassis.desktop,
      blocks: [
        ...generatePortBlock(PortTypes.rj45, numPorts, 12, 2),
        { type: PortTypes.sfp, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS350-24X"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.rj45, num: 16, rows: 2 },
        { type: PortTypes.rj45, num: 8, rows: 2 },
        { type: PortTypes.rj45, num: 4, rows: 1 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS210", "MS225", "MS250", "MS350"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.rj45, numPorts, 12, 2),
        { type: PortTypes.sfp, num: 4, rows: 1 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS355-24X"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.rj45, num: 16, rows: 2 },
        { type: PortTypes.rj45, num: 8, rows: 2 },
        { type: PortTypes.sfp, num: 4, rows: 2 },
        { type: PortTypes.qsfp, num: 2, rows: 2 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS355-24X2"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.rj45, num: 8, rows: 2 },
        { type: PortTypes.rj45, num: 16, rows: 2 },
        { type: PortTypes.sfp, num: 4, rows: 2 },
        { type: PortTypes.qsfp, num: 2, rows: 2 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS355-48X", "MS355-48X2"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.rj45, num: 16, rows: 2 },
        { type: PortTypes.rj45, num: 8, rows: 2 },
        { type: PortTypes.rj45, num: 8, rows: 2 },
        { type: PortTypes.rj45, num: 16, rows: 2 },
        { type: PortTypes.sfp, num: 4, rows: 2 },
        { type: PortTypes.qsfp, num: 2, rows: 2 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS390", "C9300"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.rj45, numPorts, 12, 2),
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS410-16"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.sfp, num: numPorts, rows: 2 },
        { type: PortTypes.sfp, num: 2, rows: 1 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS410-32"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.sfp, numPorts, 16, 2),
        { type: PortTypes.sfp, num: 4, rows: 1 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS420"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: generatePortBlock(PortTypes.sfp, numPorts, 12, 2),
    };
  }

  if (startsWith(model, ["MS425"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.sfp, numPorts, 16, 2),
        { type: PortTypes.qsfp, num: 2, rows: 1 },
      ],
    };
  }

  if (startsWith(model, ["MS450-12"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        { type: PortTypes.sfp, num: numPorts, rows: 2 },
        { type: PortTypes.qsfp, num: 4, rows: 1 },
        { type: PortTypes.qsfp, num: 4, rows: 1 },
        { type: PortTypes.qsfp, num: 4, rows: 1 },
        { type: PortTypes.qsfp, num: 2, rows: 2 },
        { type: PortTypes.stacking, num: 2, rows: 2 },
      ],
    };
  }

  if (startsWith(model, ["MS22", "MS42", "MS320"])) {
    return {
      chassis: DeviceChassis.rack,
      blocks: [
        ...generatePortBlock(PortTypes.rj45, numPorts, 12, 2),
        { type: PortTypes.sfp, num: 4, rows: 1 },
      ],
    };
  }

  // TODO: if a switch is defaulting to 1 block it is an exception and should be handled in a new conditional above
  // Added to ensure we still have a valid array length for the generatedBlock for now
  // Should be removed once switches are handled
  const defaultPortsPerBlocks = numPorts % 12 !== 0 ? numPorts : 12;
  return {
    chassis: DeviceChassis.rack,
    blocks: generatePortBlock(PortTypes.rj45, numPorts, defaultPortsPerBlocks, 2),
  };
}

export const deriveSwitchPortStatuses = (
  switchPorts: SwitchPort[],
  livePortStatuses: LiveToolMsg<LiveSwitchPorts>,
) => {
  const portStatuses: Record<string, PortStatus> = {};
  switchPorts.forEach(({ portId, enabled }) => {
    if (!enabled) {
      portStatuses[portId] = PortStatus.disabled;
    } else {
      const portStatus = livePortStatuses?.[portId];
      if (!portStatus) {
        portStatuses[portId] = PortStatus.disconnected;
      } else {
        if (switchportIsAlerting(portStatus)) {
          portStatuses[portId] = PortStatus.alerting;
        } else if (portStatus.is_uplink) {
          portStatuses[portId] = PortStatus.uplink;
        } else if (portStatus.using_poe) {
          portStatuses[portId] = PortStatus.poe;
        } else if (switchportIsConnected(portStatus)) {
          portStatuses[portId] = PortStatus.active;
        }
      }
    }
  });

  return portStatuses;
};
