import { z } from "zod";

import { request } from "../../../api/request/request";
import { createMutation } from "../../createMutation";

const buildUrl = ({ encryptedNetworkId }: AddCaseCommentRequest) => {
  return `/n/${encryptedNetworkId}/manage/support/add_case_comment`;
};

interface AddCaseCommentRequest {
  encryptedNetworkId?: string;
}

interface MutateAddCaseCommentRequest extends AddCaseCommentRequest {
  caseNumber: string;
  commentText: string;
}

export const AddCaseCommentSchema = z.object({
  success: z.boolean(),
  status: z.string(),
});

export type AddCaseComment = z.infer<typeof AddCaseCommentSchema>;

const addSupportCaseComment = ({
  encryptedNetworkId,
  caseNumber,
  commentText,
}: MutateAddCaseCommentRequest) => {
  return request(AddCaseCommentSchema, "POST", buildUrl({ encryptedNetworkId }), {
    body: JSON.stringify({ comment_text: commentText, case_number: caseNumber }),
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useAddSupportCaseComment = createMutation({
  baseMutationKey: buildUrl({ encryptedNetworkId: `{encryptedNetworkId}` }),
  mutationFn: (parameters: MutateAddCaseCommentRequest) => addSupportCaseComment({ ...parameters }),
});
