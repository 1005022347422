import { I18n } from "@meraki/core/i18n";
import { get } from "lodash";
import { PureComponent } from "react";
import { LayoutAnimation, SectionListData } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SWITCH_PORTS_KEY } from "~/constants/SearchKeys";
import SectionListHeader from "~/go/components/SectionListHeader";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import SwitchPortListRow from "~/go/rows/ports/SwitchPortListRow";
import withHardwarePortStatusData from "~/hocs/HardwarePortStatus";
import { showNetworkErrorWithRetry } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { getChassisId, portNumberOfSwitchPort, switchPortHasTags } from "~/lib/SwitchPortUtils";
import {
  deviceByIdSelector,
  filteredSwitchPortsBySearch,
  searchablePortsDataForDevices,
  switchDevicesSelector,
  timespanState,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiTable from "~/shared/components/MkiTable";
import SwitchFront from "~/shared/components/portDiagrams/SwitchFront";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { TextButton } from "~/shared/navigation/Buttons";
import Device_DeprecatedType from "~/shared/types/Device";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type SwitchPort = {
  id: string;
  is_uplink: boolean;
  num: number[];
  type: string;
};

type ReduxProps = {
  ports: SwitchPort[];
  serialNumbers: string[];
  devices: Device_DeprecatedType[];
  device?: Device_DeprecatedType;
  getDeviceById: (id: string) => Device_DeprecatedType;
  timespan: number;
};

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "SwitchPortsList"> &
  BasicActions &
  ReduxProps;

type SwitchPortsListScreenComponentState = {
  device?: Device_DeprecatedType;
  selectMode: boolean;
  selectedType?: string;
  selectedPorts: string[];
};

type RowData = {
  switchport: SwitchPort;
  device: Device_DeprecatedType;
  serialNumber: string;
  onPress: () => void;
  selected: boolean;
  selectedType: string;
  selectMode: string;
  name: string;
  portVlanSettings: {
    enabled: boolean;
    number: number;
  };
};

export class SwitchPortsListScreenComponent extends PureComponent<
  Props,
  SwitchPortsListScreenComponentState
> {
  static defaultProps = {
    filter: CUSTOM_FILTERS.DEFAULT,
  };

  static renderSectionHeader = ({ section }: { section: SectionListData<RowData> }) => {
    return <SectionListHeader heading={section.key} withHorizontalMargin />;
  };

  constructor(props: Props) {
    super(props);
    const { serialNumbers, devices } = props;

    setupAndroidLayoutAnimation();
    this.state = {
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      device: serialNumbers.length === 1 ? devices[serialNumbers[0]] : undefined,
      selectMode: false,
      selectedPorts: [],
    };

    this.setNavOptions();
  }

  componentDidMount() {
    this.loadData(true);
  }

  loadData(force = false) {
    const { actions, ports, serialNumbers, deviceClients, timespan, devices } = this.props;

    const reqs: Promise<unknown>[] = [];
    if (!ports || force) {
      for (const serialNumber of serialNumbers) {
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        reqs.push(actions.getSwitchPortsJson(devices[serialNumber].id, serialNumber));
      }
    }
    if (!deviceClients || force) {
      for (const serialNumber of serialNumbers) {
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ "@@api/Call API": { types: str... Remove this comment to see the full error message
        reqs.push(actions.getDeviceClients(serialNumber, timespan));
      }
    }
    if (reqs.length > 0) {
      Promise.all(reqs).catch(() => showNetworkErrorWithRetry(() => this.loadData(true)));
    }
  }

  setNavOptions() {
    const { navigation, showAllPorts, title } = this.props;
    const { selectMode, selectedPorts } = this.state;

    let navButtonTitle: string | undefined = I18n.t("SETTINGS_WORD");
    if (selectMode && selectedPorts.length === 0) {
      navButtonTitle = I18n.t("CANCEL");
    }
    if (selectMode && selectedPorts.length > 0) {
      navButtonTitle = `${I18n.t("SETTINGS_WORD")} (${selectedPorts.length})`;
    }
    if (showAllPorts) {
      navButtonTitle = undefined;
    } else {
      navigation.setOptions({
        headerRight: () => (
          <TextButton
            title={navButtonTitle}
            onPress={() => {
              if (selectMode && selectedPorts.length > 0) {
                this.pushPortSettings();
              } else {
                LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                this.setState((prevState) => ({
                  selectMode: !prevState.selectMode,
                  selectedPorts: [],
                }));
              }
            }}
          />
        ),
      });
    }

    if (title) {
      navigation.setOptions({
        headerTitle: title,
      });
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: SwitchPortsListScreenComponentState) {
    const { selectMode, selectedPorts } = this.state;
    if (
      prevState.selectMode !== selectMode ||
      prevState.selectedPorts.length !== selectedPorts.length
    ) {
      this.setNavOptions();
    }
  }

  hasMultipleSerialNumbers = () => {
    const { serialNumbers } = this.props;
    return serialNumbers.length > 1;
  };

  pushPortSettings = () => {
    const { navigation, serialNumbers } = this.props;
    const { selectedPorts } = this.state;
    navigation.navigate("SwitchPortSettings", {
      serialNumber: serialNumbers[0],
      portIds: selectedPorts,
      onDismiss: () => this.loadData(true),
    });
  };

  pushSwitchPortDetails = (rowData: RowData) => {
    const { navigation } = this.props;
    const { device, serialNumber } = rowData;

    const portNumber = portNumberOfSwitchPort(get(rowData, "switchport"));

    navigation.navigate("SwitchPortsDetails", {
      deviceId: device.id,
      serialNumber: serialNumber,
      portNumber,
    });
  };

  selectPort = (id: string, type?: string) => {
    const { selectedPorts } = this.state;
    const mutatedPorts = Object.assign([], selectedPorts);
    const index = mutatedPorts.indexOf(id);
    if (index > -1) {
      mutatedPorts.splice(index, 1);
    } else {
      mutatedPorts.push(id);
    }
    let selectedType = type;
    if (mutatedPorts.length === 0) {
      selectedType = undefined;
    }
    this.setState({
      selectedType,
      selectedPorts: mutatedPorts,
    });
  };

  selectSwitchPort = (rowData: RowData) => {
    const { id, type } = rowData.switchport;
    this.selectPort(id, type);
  };

  onRefresh = () => this.loadData(true);

  onSearchChange = (value: string) => {
    const { actions } = this.props;
    actions.setSearchText(SWITCH_PORTS_KEY, value);
  };

  static renderRow(rowData: RowData) {
    const {
      onPress,
      selected,
      selectedType,
      selectMode,
      serialNumber,
      switchport,
      name,
      portVlanSettings,
    } = rowData;

    const hasTags = switchPortHasTags(switchport);
    const lldpMac = getChassisId(switchport);
    const { type, is_uplink: isUplink } = switchport;

    let selectable = true;
    selectable = !selectedType || type === selectedType;
    if (selectMode && isUplink) {
      selectable = false;
    }

    return (
      <SwitchPortListRow
        name={name}
        serialNumber={serialNumber}
        portNumber={portNumberOfSwitchPort(switchport)}
        switchPort={switchport}
        lldpMac={lldpMac}
        selectMode={selectMode}
        selected={selected}
        selectable={selectable}
        onPress={onPress}
        hasTags={hasTags}
        portVlanSettings={portVlanSettings}
      />
    );
  }

  getGSRowData = () => {
    const { ports } = this.props;
    return this.hasMultipleSerialNumbers() ? ports : this.getRowDataForDevice();
  };

  getRowDataForDevice = () => {
    const { ports, serialNumbers, filter } = this.props;
    const { selectMode, selectedPorts, selectedType, device } = this.state;

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return filter(ports).map((switchport) => ({
      switchport,
      selected: selectedPorts.includes(switchport.id),
      selectedType,
      selectMode,
      serialNumber: serialNumbers[0],
      device,
    }));
  };

  renderTable = () => {
    const { selectMode } = this.state;
    const onPress = selectMode ? this.selectSwitchPort : this.pushSwitchPortDetails;

    return (
      <MkiTable
        data={this.getGSRowData()}
        onSearchChange={this.onSearchChange}
        searchPlaceholder={I18n.t("SWITCH_PORTS_LIST.SEARCH_PLACEHOLER")}
        keyExtractor={(data) => data.switchport.id}
        renderRow={SwitchPortsListScreenComponent.renderRow}
        renderSectionHeader={SwitchPortsListScreenComponent.renderSectionHeader}
        onPress={onPress}
        onRefresh={this.onRefresh}
        testID="PORTS_LIST_SCROLL_VIEW"
      />
    );
  };

  renderPortDiagram = () => {
    const { serialNumbers } = this.props;

    if (this.hasMultipleSerialNumbers()) {
      return null;
    }
    return <SwitchFront serialNumber={serialNumbers[0]} />;
  };

  render() {
    return (
      <FullScreenContainerView>
        {this.renderPortDiagram()}
        {this.renderTable()}
      </FullScreenContainerView>
    );
  }
}

function mapStateToProps(
  state: RootState,
  props: HardwareStackPropMap["SwitchPortsList"],
): ReduxProps {
  // @ts-expect-error TS(2339) FIXME: Property 'serialNumber' does not exist on type '({... Remove this comment to see the full error message
  const { serialNumber, showAllPorts } = props;
  const getDeviceById = (id: string) => deviceByIdSelector(state, { id });

  const devices = switchDevicesSelector(state);
  let ports,
    serialNumbers: string[] = [];
  let device = undefined;
  if (!showAllPorts && serialNumber) {
    ports = filteredSwitchPortsBySearch(state, serialNumber);
    serialNumbers = [serialNumber];
    device = devices[serialNumber];
  } else {
    ports = searchablePortsDataForDevices(state, {
      ...props,
      filter: props.filter || CUSTOM_FILTERS.DEFAULT,
      getDeviceById,
    });
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    serialNumbers = Object.values(devices).map((device) => device.serial);
  }

  return {
    ports,
    serialNumbers,
    devices,
    device,
    getDeviceById,
    timespan: timespanState(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withHardwarePortStatusData,
)(SwitchPortsListScreenComponent);
