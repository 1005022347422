import { FieldValues, useForm as useReactHookForm, UseFormProps } from "react-hook-form";

import { FormAdditionalContext } from "./useFormContext";

export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = FormAdditionalContext,
  TTransformedValues extends FieldValues | undefined = undefined,
>(props?: UseFormProps<TFieldValues, TContext>) {
  return useReactHookForm<TFieldValues, TContext, TTransformedValues>(props);
}
