import { ExportTemplateResponseSchema, Ssid, Vlan, VlanExport } from "@meraki/shared/api";
import DocumentPicker from "react-native-document-picker";
import RNFS from "react-native-fs";

export const pickExportedFile = async () => {
  const pickedFile = await DocumentPicker.pickSingle({
    presentationStyle: "formSheet",
    copyTo: "cachesDirectory",
    type: DocumentPicker.types.allFiles,
  });

  if (pickedFile.name == null) {
    return;
  }

  try {
    const fileContent = await RNFS.readFile(pickedFile.uri);
    return fileContent;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const processFile = (fileContents: string) => {
  try {
    const contentsAsJson = JSON.parse(fileContents);
    const responseObject = ExportTemplateResponseSchema.parse(contentsAsJson);

    return responseObject;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const prepSsidToUpload = (ssidObject: Ssid, ssidNumber: number) => {
  ssidObject.number = ssidNumber;
  return ssidObject;
};

export const importFormatSsidId = (ssid: Ssid) => {
  return ssid ? `${ssid.name}.${ssid.number ?? -1}`.replace(/\s/g, "") : "";
};

export const importFormatVlanId = (vlan: VlanExport) => {
  return vlan ? `${vlan.name}.${vlan.id}`.replace(/\s/g, "") : "";
};

export interface StagedVlanObject {
  id: number;
  subnet: string;
  subnetRange: number[];
}
export interface StagedVlanMap {
  [key: string]: StagedVlanObject;
}

export type vlanErrors = "ip_overlap" | "default_id" | "duplicate_id";

export const getVlanErrors = (
  currentVlanId: number,
  vlanId: string,
  vlanIpAddress: string,
  existingVlans: Vlan[],
  stagedVlanObject: StagedVlanMap,
) => {
  const errorsArr = [] as vlanErrors[];
  existingVlans?.forEach((existingVlan) => {
    if (existingVlan.id === parseInt(vlanId)) {
      errorsArr.push("duplicate_id");
    }

    if (existingVlan.subnet === vlanIpAddress) {
      errorsArr.push("ip_overlap");
    }
  });

  Object.entries(stagedVlanObject).forEach(([key, stagedVlan]) => {
    if (key !== currentVlanId.toString()) {
      if (stagedVlan.id === parseInt(vlanId)) {
        errorsArr.push("duplicate_id");
      }

      if (stagedVlan.subnet === vlanIpAddress) {
        errorsArr.push("ip_overlap");
      }
    }
  });

  if (vlanId === "1") {
    errorsArr.push("default_id");
  }

  return errorsArr;
};
