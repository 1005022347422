import I18n from "~/i18n/i18n";
import SummaryList from "~/shared/components/SummaryList";
import DisclosureRow from "~/shared/rows/DisclosureRow";

type Props<T> = {
  customRenderRow: (item: T, id: number) => void;
  items?: T[];
  maxItemsToDisplay?: number;
  onPressSeeAll?: (() => void) | null;
  testID?: string;
};

const TopItemsList = <T,>(props: Props<T>) => {
  const { customRenderRow, items = [], maxItemsToDisplay = 5, onPressSeeAll } = props;

  const seeAllRow =
    onPressSeeAll && items.length > maxItemsToDisplay
      ? [
          {
            onPress: onPressSeeAll,
            children: I18n.t("SEE_ALL"),
            isFullButton: true,
            bottomSeparator: true,
          },
        ]
      : [];

  const topItems = maxItemsToDisplay ? items.slice(0, maxItemsToDisplay) : items;

  const renderRow = (rowData: T, rowID: number) => {
    return rowID < topItems.length ? (
      customRenderRow(rowData, rowID)
    ) : (
      // @ts-ignore disclosure is not gonna work with generic row data, but all row data we're
      // putting into these components has a different structure so it's difficult to
      // standardize
      <DisclosureRow {...rowData} />
    );
  };

  return (
    <SummaryList<T>
      contentRows={topItems}
      customRenderRow={renderRow}
      disclosureRows={seeAllRow}
      disableBottomBorder
    />
  );
};

export default TopItemsList;
