import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function Backward10Icon(props: Props) {
  const { size = BUTTON_SIZING.width.default } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M1.36659 3.89196L2.8773 5.07321C4.93712 2.44018 8.1407 0.75 11.738 0.75C17.9496 0.75 22.9773 5.77232 22.9853 11.9866C22.9934 18.2063 17.955 23.25 11.738 23.25C6.88177 23.25 2.74337 20.1696 1.16837 15.8545C1.1282 15.742 1.18712 15.6161 1.29962 15.5786L2.81837 15.0563C2.87133 15.0381 2.92928 15.0414 2.97984 15.0654C3.03039 15.0894 3.06954 15.1323 3.08891 15.1848C3.13712 15.3188 3.1907 15.4527 3.24695 15.5839C3.71034 16.6821 4.37462 17.6679 5.22105 18.5143C6.06065 19.3555 7.05473 20.0267 8.14873 20.4911C9.28177 20.9705 10.4898 21.2143 11.7327 21.2143C12.9782 21.2143 14.1836 20.9705 15.3166 20.4911C16.4117 20.0286 17.4061 19.3572 18.2443 18.5143C19.0861 17.6748 19.7566 16.6795 20.2184 15.5839C20.6978 14.4482 20.9416 13.2429 20.9416 11.9973C20.9416 10.7518 20.6978 9.54643 20.2184 8.41071C19.755 7.3125 19.0907 6.32679 18.2443 5.48036C17.3978 4.63393 16.4121 3.96964 15.3166 3.50357C14.1836 3.02411 12.9755 2.78036 11.7327 2.78036C10.4871 2.78036 9.28177 3.02411 8.14873 3.50357C7.05367 3.96601 6.05923 4.63746 5.22105 5.48036C4.95587 5.74554 4.70677 6.02679 4.47641 6.32143L6.08891 7.58036C6.12082 7.60504 6.14511 7.63822 6.15899 7.6761C6.17287 7.71397 6.17578 7.75499 6.16739 7.79444C6.159 7.8339 6.13964 7.87018 6.11154 7.89912C6.08344 7.92806 6.04774 7.94848 6.00855 7.95804L1.30498 9.10982C1.17105 9.14197 1.0398 9.04018 1.0398 8.90357L1.01837 4.05804C1.02105 3.88125 1.2273 3.78214 1.36659 3.89196V3.89196Z"
        fill="white"
      />
      <Path
        d="M9.77669 15.6875H8.01628V10.0833L6.28711 10.5885V9.25521L9.61523 8.10417H9.77669V15.6875Z"
        fill="white"
      />
      <Path
        d="M16.7954 12.5885C16.7954 13.6128 16.5645 14.4028 16.1027 14.9583C15.6444 15.5139 14.9968 15.7917 14.16 15.7917C13.3163 15.7917 12.6635 15.5122 12.2017 14.9531C11.7399 14.3941 11.509 13.6059 11.509 12.5885V11.2031C11.509 10.1788 11.7382 9.38889 12.1965 8.83333C12.6583 8.27778 13.3093 8 14.1496 8C14.9899 8 15.6409 8.27951 16.1027 8.83854C16.5645 9.39757 16.7954 10.1875 16.7954 11.2083V12.5885ZM15.0402 10.9792C15.0402 10.434 14.9691 10.0278 14.8267 9.76042C14.6843 9.48958 14.4586 9.35417 14.1496 9.35417C13.8475 9.35417 13.627 9.47917 13.4881 9.72917C13.3527 9.97917 13.2798 10.3576 13.2694 10.8646V12.8021C13.2694 13.3681 13.3406 13.783 13.4829 14.0469C13.6253 14.3073 13.851 14.4375 14.16 14.4375C14.4586 14.4375 14.6791 14.3108 14.8215 14.0573C14.9638 13.8003 15.0368 13.3993 15.0402 12.8542V10.9792Z"
        fill="white"
      />
    </Svg>
  );
}

export default Backward10Icon;
