import { Children, forwardRef } from "react";

import ViewPager, { ViewPagerProps } from "~/shared/components/viewPager/ViewPager";

type ViewPagerRef =
  | ((instance: ViewPager | null) => void)
  | React.MutableRefObject<ViewPager | null>
  | null;
const IndicatorViewPager = forwardRef(function IndicatorViewPager(
  props: ViewPagerProps,
  ref: ViewPagerRef,
) {
  const { children, ...rest } = props;
  const numPages = Children.count(children);
  const showPageIndicator = numPages > 1;

  return (
    <ViewPager {...rest} showPageIndicator={showPageIndicator} ref={ref}>
      {children}
    </ViewPager>
  );
});

export default IndicatorViewPager;
