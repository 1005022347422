import { usePortStatuses } from "@meraki/react-live-broker";
import { useAllAppliancePorts } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { StyleSheet, useWindowDimensions, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING, SWITCH_PANEL_WIDTH } from "~/constants/MkiConstants";
import { DeviceChassis } from "~/constants/PortLayouts";
import { deriveAppliancePortStatuses, getApplianceLayout } from "~/lib/ApplianceUtils";
import { appSelect, isWeb, platformSelect } from "~/lib/PlatformUtils";
import { sizeSelectWidth, themeColors } from "~/lib/themeHelper";
import { devicesState } from "~/selectors";
import MkiSpinner from "~/shared/components/MkiSpinner";
import PortBlocks from "~/shared/components/portDiagrams/PortBlocks";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useTheme } from "~/shared/hooks/useTheme";

const useAppliancePanelWidth = () => {
  const { width: windowWidth } = useWindowDimensions();
  const compactPanelWidth = windowWidth - SPACING.default;
  return sizeSelectWidth({
    small: compactPanelWidth,
    medium: compactPanelWidth,
    default: SWITCH_PANEL_WIDTH,
  });
};

interface ApplianceFrontProps {
  serialNumber: string;
  selectedPorts?: string[];
  hidePortStatusIcons?: boolean;
}

const ApplianceFront = ({
  serialNumber,
  selectedPorts,
  hidePortStatusIcons,
}: ApplianceFrontProps) => {
  const { id, model } = useAppSelector(devicesState)[serialNumber];

  const networkId = useCurrentNetworkId();
  const appliancePorts = useAllAppliancePorts({ networkId }).data;
  const livePortStatuses = usePortStatuses(id);
  const panelWidth = useAppliancePanelWidth();
  const theme = useTheme();

  if (!appliancePorts) {
    return null;
  }

  const applianceLayout = getApplianceLayout(model);
  if (!applianceLayout) {
    return null;
  }

  const panelStyle =
    applianceLayout.chassis === DeviceChassis.rack ? styles.frontPanel : styles.desktopPanel;
  const derivedPanelStyle = [styles.frontPanel, panelStyle, themeColors(theme).frontPanel];

  return (
    <View style={styles.container} testID="APPLIANCE_FRONT">
      <View style={derivedPanelStyle}>
        <PortBlocks
          portBlockData={applianceLayout.blocks}
          portsStatuses={deriveAppliancePortStatuses(appliancePorts, livePortStatuses)}
          selectedPorts={selectedPorts}
          panelWidth={panelWidth}
          hidePortStatusIcons={hidePortStatusIcons}
        />
      </View>
      {!livePortStatuses && <MkiSpinner style={styles.spinner} size="small" />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginVertical: 8,
    marginHorizontal: SPACING.default,
  },
  spinner: {
    marginStart: 6,
  },
  frontPanel: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    borderColor: MkiColors.panelShadowColor,
    borderWidth: appSelect({
      enterprise: 0,
      go: 1,
    }),
    borderBottomWidth: 1,
    borderRadius: appSelect({
      enterprise: 2,
      go: 4,
    }),
    paddingVertical: 6,
    paddingHorizontal: 8,
    ...appSelect({
      go: {
        ...platformSelect({
          ios: {
            shadowColor: MkiColors.tileShadowColor,
            shadowOpacity: 0.5,
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 2,
          },
          android: { elevation: 1 },
        }),
      },
      enterprise: {},
    }),
  },
  desktopPanel: {
    flex: isWeb() ? 1 : 0,
    paddingHorizontal: 16,
  },
});

export default ApplianceFront;
