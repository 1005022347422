export interface Cookie {
  name: string;
  value: string;
  path?: string;
  domain?: string;
  version?: string;
  expires?: string;
  secure?: boolean;
  httpOnly?: boolean;
}

export interface Cookies {
  [key: string]: Cookie;
}

const FAKE_COOKIE: Cookies = {
  fake: {
    name: "FAKE",
    value: "NONE",
  },
};

const CookieManagerStatic = {
  set: (_url: string, _cookie: Cookie, _useWebKit?: boolean): Promise<boolean> =>
    Promise.resolve(true),
  setFromResponse: (_url: string, _cookie: string): Promise<boolean> => Promise.resolve(true),
  get: (_url: string, _useWebKit?: boolean): Promise<Cookies> => Promise.resolve(FAKE_COOKIE),
  getFromResponse: (url: string): Promise<Cookies> => Promise.resolve(FAKE_COOKIE),

  clearAll: (useWebKit?: boolean): Promise<boolean> => Promise.resolve(true),
  flush: (): Promise<void> => Promise.resolve(),
  //iOS only
  getAll: (_useWebKit?: boolean): Promise<Cookies> => Promise.resolve(FAKE_COOKIE),
  clearByName: (url: string, name: string, useWebKit?: boolean): Promise<boolean> =>
    Promise.resolve(true),
};

export default CookieManagerStatic;
