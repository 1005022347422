type EvaluateEnabledOptions<TVariables = unknown> = {
  variables: TVariables;
  requiredVariables?: ReadonlyArray<keyof TVariables>;
  enabled?: boolean;
};

export function evaluateEnabled<TVariables = unknown>({
  variables,
  requiredVariables,
  enabled,
}: EvaluateEnabledOptions<TVariables>) {
  // We are specifically checking for false here, not falsey. Falsey could trip up if enabled is not passed at all.
  if (enabled === false) {
    return false;
  }

  if (typeof requiredVariables !== "undefined") {
    const someRequiredVariablesAreUndefined = requiredVariables.some(
      (rv) => typeof variables[rv] === "undefined" || variables[rv] === null,
    );
    // We are inversing the some since the some is going to be true if any of the required variables are undefined
    return !someRequiredVariablesAreUndefined;
  }

  // Nothing kept us from being enabled, so we are enabled!
  return true;
}
