import { useQuery } from "@tanstack/react-query";

import { applianceDelegatedPrefixesKeys } from "~/api/queries/appliances/keys";
import {
  ApplianceDelegatedPrefix,
  ApplianceDelegatedPrefixesSchema,
  ApplianceDelegatedPrefixVlanAssignment,
  ApplianceDelegatedPrefixVlanAssignmentsSchema,
} from "~/api/schemas/ApplianceDelegatedPrefixes";
import { validatedMerakiRequest } from "~/api/util/request";

const emptyPrefixes: ApplianceDelegatedPrefix[] = [];
const emptyAssignments: ApplianceDelegatedPrefixVlanAssignment[] = [];

const fetchApplianceDelegatedPrefixes = (serial?: string): Promise<ApplianceDelegatedPrefix[]> => {
  if (serial == null) {
    return Promise.resolve(emptyPrefixes);
  }

  return validatedMerakiRequest(
    ApplianceDelegatedPrefixesSchema,
    "GET",
    `/api/v1/devices/${serial}/appliance/prefixes/delegated`,
  );
};

const fetchApplianceDelegatedPrefixVlanAssignments = (
  serial?: string,
): Promise<ApplianceDelegatedPrefixVlanAssignment[]> => {
  if (serial == null) {
    return Promise.resolve(emptyAssignments);
  }

  return validatedMerakiRequest(
    ApplianceDelegatedPrefixVlanAssignmentsSchema,
    "GET",
    `/api/v1/devices/${serial}/appliance/prefixes/delegated/vlanAssignments`,
  );
};

export const useApplianceDelegatedPrefixes = (serial?: string) => {
  return useQuery({
    queryKey: applianceDelegatedPrefixesKeys.prefixes(serial),
    queryFn: () => fetchApplianceDelegatedPrefixes(serial),
  });
};

export const useApplianceDelegatedPrefixVlanAssignments = (serial?: string) => {
  return useQuery({
    queryKey: applianceDelegatedPrefixesKeys.vlanAssignments(serial),
    queryFn: () => fetchApplianceDelegatedPrefixVlanAssignments(serial),
  });
};
