import { Component, ReactNode } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";

export interface ExpandableContainerProps {
  children: ReactNode;
  onChange?: (isChanged: boolean) => void;
  noMargin?: boolean;
  testID?: string;
  alwaysExpanded?: boolean;
}

export interface ExpandableContainerState {
  isExpanded: boolean;
}

abstract class ExpandableContainer<P extends ExpandableContainerProps> extends Component<
  P,
  ExpandableContainerState
> {
  constructor(props: P) {
    super(props);

    this.state = { isExpanded: !!props.alwaysExpanded };
  }

  // Implement renderMain to return the header row for this component.
  abstract renderMain(): JSX.Element;

  renderChildren() {
    const { children } = this.props;
    const { isExpanded } = this.state;

    return isExpanded ? children : null;
  }

  onHeaderPress = () => {
    const { onChange, alwaysExpanded } = this.props;
    const { isExpanded } = this.state;
    if (alwaysExpanded) {
      return;
    }

    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));

    if (onChange) {
      onChange(isExpanded);
    }
  };

  render() {
    const { testID, noMargin } = this.props;
    return (
      <View style={noMargin ? undefined : styles.container} testID={testID}>
        {this.renderMain()}
        {this.renderChildren()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: SPACING.extraLarge * 2,
  },
});

export default ExpandableContainer;
