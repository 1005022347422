import { defaultTimespans } from "@meraki/shared/redux";
import { createSelector } from "reselect";

import { MAX_SSIDS } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { currentUserState, getTimespan, preferencesState } from "~/selectors/getters";
import {
  DEFAULT_HOME_SCREEN_CARDS_ORDER,
  ExpandableCards,
  HomeScreenCardsOrder,
  Preferences,
} from "~/shared/types/Preferences";
import { ReduxSelector, RootState } from "~/shared/types/Redux";
import { TimePickerElement } from "~/shared/types/TimeTypes";

const emptyUser = {};
export const currentUserPrefsState = createSelector(
  preferencesState,
  currentUserState,
  (preferences, currentUser) => preferences?.[currentUser] ?? emptyUser,
);

export const defaultNetworkState = (state: RootState) =>
  currentUserPrefsState(state).defaultNetwork;

export const homeSSIDState = (state: RootState) =>
  currentUserPrefsState(state)?.homeSSID ?? MAX_SSIDS;

export const timespanState = createSelector(getTimespan, (timespan): number => {
  const data: TimePickerElement[] = Object.values(defaultTimespans);
  const foundTimespan = data.find((d) => timespan === d.value);
  return foundTimespan?.value || defaultTimespans.WEEK.value;
});

// moved to a util function in PreferenceUtils.ts so it's not restricted to timespanState
export const timespanNameSelector = createSelector(timespanState, (timespan: number) => {
  const data: TimePickerElement[] = Object.values(defaultTimespans);
  const selectedData = data.find((d) => timespan === d?.value);
  return selectedData && I18n.t(selectedData.label);
});

const noBioAuthData = {};
export const biometricAuthState = createSelector(
  preferencesState,
  currentUserState,
  (preferences, currentUser) => preferences?.[currentUser]?.biometricAuth ?? noBioAuthData,
);

export const getPromptSetBioAuth = createSelector(
  preferencesState,
  currentUserState,
  (preferences, currentUser) => {
    return preferences?.[currentUser]?.promptSetBioAuth;
  },
);

export const isUserBioAuthEnabled = createSelector(
  biometricAuthState,
  (bioAuth) => !!bioAuth.enabled,
);

export const isLocationAnalyticsEnabled = createSelector(
  preferencesState,
  currentUserState,
  (preferences: Preferences, user: string): boolean =>
    !!preferences?.[user]?.locationAnalyticsEnabled,
);

export const currentMaintenanceWindow = createSelector(
  preferencesState,
  currentUserState,
  (preferences: Preferences, user: string): any => preferences?.[user]?.upgradeWindow,
);

export const getNumberOfHomeVisits = createSelector(currentUserPrefsState, (prefsState) => {
  return prefsState?.numberOfHomeVisits || 0;
});

export const getAppSurveyDismissals = createSelector(
  currentUserPrefsState,
  (prefsState): number => {
    return prefsState?.appSurveyDismissals || 0;
  },
);

export const getShouldAskForRating = createSelector(getNumberOfHomeVisits, (numberOfHomeVisits) => {
  return numberOfHomeVisits === 4;
});

export const getVisibleInlineAlertForID = createSelector(
  currentUserPrefsState,
  (_: RootState, name: any) => name,
  (prefsState, name) =>
    prefsState?.visibleInlineAlerts?.[name] === undefined
      ? true
      : prefsState?.visibleInlineAlerts?.[name],
);

export const getExpandableCards: ReduxSelector<ExpandableCards> = createSelector(
  preferencesState,
  currentUserState,
  (preferences: Preferences, user: string) => preferences?.[user]?.expandableCards || {},
);

export const getPdfInfo = createSelector(
  preferencesState,
  currentUserState,
  (preferences: Preferences, user: string): any => preferences?.[user]?.pdfInfo,
);

export const getAskForBioAuthType = createSelector(
  currentUserPrefsState,
  (currentUser) => currentUser.askForBioAuthType ?? false,
);

export const getHideAskForBioAuth = createSelector(
  currentUserPrefsState,
  (currentUser) => currentUser.hideAskForBioAuth ?? false,
);

export const getHideMessageBanner = createSelector(
  currentUserPrefsState,
  (currentUser) => currentUser.hideMessageBanner ?? false,
);

export const getCameraGridPreferred = createSelector(
  currentUserPrefsState,
  (currentUser) => currentUser.cameraGridPreferred ?? true,
);

export const getHomeScreenCardsOrder: ReduxSelector<HomeScreenCardsOrder> = createSelector(
  preferencesState,
  currentUserState,
  (preferences: Preferences, user: string) =>
    preferences?.[user]?.homeScreenCardsOrder ?? DEFAULT_HOME_SCREEN_CARDS_ORDER,
);
