import { useNavigation } from "@react-navigation/native";
import { ReactText } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { appSelect, isIOS } from "~/lib/PlatformUtils";
import MkiText from "~/shared/components/MkiText";
import useAndroidBackButtonOverride from "~/shared/hooks/useAndroidBackButtonOverride";

import { SharedScreensPropMap } from "../navigation/Types";
import MkiBottomSheet from "./MkiBottomSheet";

const makeSnapPoints = (length: number) => {
  const rowHeight = SPACING.large + SPACING.medium * 2;
  let bottomMargin = SPACING.large;

  if (isIOS()) {
    bottomMargin *= 2;
  }

  return [rowHeight * length + bottomMargin];
};

type Props = ForwardedNativeStackScreenProps<SharedScreensPropMap, "ActionSheet">;

const TextList = ({ data, onPress }: Pick<Props, "data" | "onPress">) => {
  const navigation = useNavigation();

  return (
    <>
      {data.map((textOrComponent: ReactText | React.ReactElement, index: number) => {
        if (typeof textOrComponent === "object") {
          return (
            <TouchableOpacity
              key={index}
              style={styles.textItem}
              onPress={() => {
                onPress(index);
                navigation.goBack();
              }}
            >
              {textOrComponent}
            </TouchableOpacity>
          );
        }

        return (
          <TouchableOpacity
            key={textOrComponent}
            style={styles.textItem}
            onPress={() => {
              onPress(index);
              navigation.goBack();
            }}
          >
            <MkiText screenStyles={styles.textStyle}>{textOrComponent}</MkiText>
          </TouchableOpacity>
        );
      })}
    </>
  );
};

const MkiActionSheet = ({ data, onPress }: Props) => {
  const navigation = useNavigation();

  useAndroidBackButtonOverride(navigation.goBack);

  return (
    <MkiBottomSheet
      showBackdrop
      showHandleIndicator={false}
      onClose={navigation.goBack}
      snapPoints={makeSnapPoints(data.length)}
    >
      <TextList data={data} onPress={onPress} />
    </MkiBottomSheet>
  );
};

const styles = StyleSheet.create({
  textItem: {
    paddingHorizontal: SPACING.medium,
    paddingVertical: SPACING.medium,
  },
  textStyle: appSelect({
    enterprise: {},
    go: {
      color: MkiColors.configHeadingColor,
    },
  }),
});

export default MkiActionSheet;
