import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { fetchCSRFToken, setupAndRegisterPushNotifications } from "~/actions";
import { requiresTwoFactor } from "~/lib/RequestUtils";
import { isAuthenticatedState, requiresTwoFactorReset } from "~/selectors";

import useAppDispatch from "../hooks/redux/useAppDispatch";
import useAppSelector from "../hooks/redux/useAppSelector";
import useLocaleChangeState from "./useLocaleChangeState";
import useUpdateAccessRoles from "./useUpdateAccessRoles";

const loginInitStates = {
  notAuthenticated: "notAuthenticated",
  notComplete: "notComplete",
  twoFactorReset: "twoFactorReset",
  twoFactor: "twoFactor",
  fetchingUserAccessRoles: "fetchingUserAccessRoles",
  localeChanging: "localeChanging",
  complete: "complete",
} as const;

export type LoginInitState = (typeof loginInitStates)[keyof typeof loginInitStates];

export type LoginInitStateReturn = readonly [
  loginInitState: LoginInitState,
  setPostLoginInitComplete: Dispatch<SetStateAction<boolean>>,
];

const useLoginInitState = (): LoginInitStateReturn => {
  const dispatch = useAppDispatch();
  const isAuthenticated = !!useAppSelector(isAuthenticatedState);
  const isTFAResetRequired = useAppSelector(requiresTwoFactorReset);
  const [isTFARequired, setIsTFARequired] = useState(false);
  const isFetchingUserAccessRoles = useUpdateAccessRoles(isAuthenticated);
  const isLocaleChanging = useLocaleChangeState();
  const [isLoginInitComplete, setPostLoginInitComplete] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      setPostLoginInitComplete(false);
      return;
    }

    if (isAuthenticated) {
      if (__MERAKI_GO__) {
        (async () => {
          const response = await dispatch(fetchCSRFToken());
          setIsTFARequired(requiresTwoFactor(response));
        })();
      } else {
        (async () => {
          await dispatch(setupAndRegisterPushNotifications());
        })();
      }
    }
  }, [dispatch, isAuthenticated]);

  if (!isAuthenticated) {
    return ["notAuthenticated", setPostLoginInitComplete] as const;
  }

  if (isTFAResetRequired) {
    return ["twoFactorReset", setPostLoginInitComplete] as const;
  }

  if (isTFARequired) {
    return ["twoFactor", setPostLoginInitComplete] as const;
  }

  if (isFetchingUserAccessRoles) {
    return ["fetchingUserAccessRoles", setPostLoginInitComplete] as const;
  }

  if (isLocaleChanging) {
    return ["localeChanging", setPostLoginInitComplete] as const;
  }

  if (!isLoginInitComplete) {
    return ["notComplete", setPostLoginInitComplete] as const;
  }

  return ["complete", setPostLoginInitComplete] as const;
};

export default useLoginInitState;
