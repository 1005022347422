import { I18n } from "@meraki/core/i18n";
import { Button, Card, Heading, List, RefreshControl, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { usePortForwardingRules } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import React from "react";

export const PortForwardingRulesListScreen = () => {
  const networkId = useCurrentNetworkId();
  const {
    data: portForwardingRules,
    isRefetching,
    refetch,
    isLoading,
  } = usePortForwardingRules({ networkId });

  return (
    <Screen
      addDefaultPadding
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
    >
      <Box padding="none" paddingBottom="xs" bottomDividerBorder>
        <Heading size="h1">{I18n.t("PORT_FORWARDING.TITLE")}</Heading>
      </Box>
      <Box padding="none">
        <Text size="p1">{I18n.t("PORT_FORWARDING.SUBTITLE")}</Text>
      </Box>
      <Box>
        <Box
          marginHorizontal="xs"
          paddingBottom="2xs"
          marginTop="md"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="h4">{I18n.t("PORT_FORWARDING.HEADING")}</Heading>
          <Button
            kind="tertiary"
            text={I18n.t("ADD")}
            trailingIcon="Plus"
            onPress={() => undefined}
            testID="ADD_DEVICE_BUTTON"
          />
        </Box>
        <List.FlashList
          loading={isLoading}
          paddingTop="none"
          paddingLeft="none"
          paddingRight="none"
          data={portForwardingRules?.rules}
          ListEmptyComponent={
            <Card>
              <Text color="light">{I18n.t("NOT_ADDED")}</Text>
            </Card>
          }
          getItemData={({ lanIp, localPort, publicPort, protocol }) => {
            const phraseKey =
              localPort.includes("-") && publicPort.includes("-")
                ? "PORT_FORWARDING.RULE_DESCRIPTION_PORT_RANGE"
                : "PORT_FORWARDING.RULE_DESCRIPTION_SINGLE_PORT";

            return {
              title: lanIp,
              description: I18n.t(phraseKey, {
                localPort,
                publicPort,
                protocol: protocol.toUpperCase(),
              }),
              rightAccessory: (
                <Button kind="tertiary" text={I18n.t("EDIT")} onPress={() => undefined} />
              ),
            };
          }}
        />
      </Box>
    </Screen>
  );
};
