import { License, useDeviceStatuses } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { StyleProp, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import HardwareRow from "~/go/components/HardwareRow";
import { useGXUMBLicense } from "~/go/hooks/useFilteredLicenses";
import { DeviceModelsGo } from "~/go/types/DevicesTypes";
import { deviceName, getIsMeshAccessPoint, isGXModel } from "~/lib/DeviceUtils";
import { getHardwareDescription } from "~/lib/HardwareUtils";
import { currentOrganization, deviceByIdSelector } from "~/selectors";
import MerakiIcon from "~/shared/components/icons";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

interface HardwareListRowProps {
  deviceId: string;
  license?: License | null;
  onPress: () => void;
  rightAccessory?: React.ReactNode;
  screenStyles?: StyleProp<ViewStyle>;
  showLicenseIcon?: boolean;
  testID: string;
  timestamp?: number;
  showStatusIcon?: boolean;
}

export const HardwareListRow = (props: HardwareListRowProps) => {
  const { deviceId, timestamp, onPress, rightAccessory, ...rest } = props;

  const rightRowAccessory = rightAccessory || (
    <MerakiIcon name="chevron-right" size="s" color={MkiColors.chevronColor} />
  );

  const device = useAppSelector((state) => deviceByIdSelector(state, { id: deviceId }));
  const organizationId = useAppSelector(currentOrganization).id;
  const networkId = useCurrentNetworkId();
  const { data: rawStatus } = useDeviceStatuses(
    { organizationId, networkIds: networkId ? [networkId] : undefined },
    {
      select(data) {
        return data.find((thisDevice) => thisDevice.serial === device.serial)?.status;
      },
    },
  );

  const { status, description } = getHardwareDescription(device, rawStatus);
  const isGX = isGXModel(device);
  const isMeshAccessPoint = getIsMeshAccessPoint(device);
  const { data: license } = useGXUMBLicense(device.serial, organizationId, networkId);
  const { model } = device;

  return (
    <HardwareRow
      {...rest}
      name={deviceName(device)}
      model={model as DeviceModelsGo}
      serial={device.serial}
      status={status}
      isMeshAccessPoint={isMeshAccessPoint}
      description={description}
      rightAccessory={rightRowAccessory}
      onPress={onPress}
      timestamp={timestamp}
      showLicenseIcon={isGX}
      license={license}
    />
  );
};

export default HardwareListRow;
