import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const ConnectivityMonitoringDestinationsSchema = z
  .object({
    destinations: z.array(
      z.object({
        ip: z.string(),
        description: z.string(),
        default: z.boolean(),
      }),
    ),
  })
  .describe("ConnectivityMonitoringDestinationsSchema");

type ConnectivityMonitoringDestinations = z.infer<typeof ConnectivityMonitoringDestinationsSchema>;

interface CMDRequest {
  networkId?: string;
}

function buildUrl({ networkId }: CMDRequest) {
  return `/api/v1/networks/${networkId}/appliance/connectivityMonitoringDestinations`;
}

const fetchConnectivityMonitoringDestinations = ({
  networkId,
}: CMDRequest): Promise<ConnectivityMonitoringDestinations> =>
  request(ConnectivityMonitoringDestinationsSchema, "GET", buildUrl({ networkId }));

export const useConnectivityMonitoringDestinations = createQuery<
  CMDRequest,
  ConnectivityMonitoringDestinations
>({
  baseQueryKey: buildUrl({ networkId: "{networkId}" }),
  queryFn: (variables) => fetchConnectivityMonitoringDestinations(variables),
  requiredVariables: ["networkId"],
});
