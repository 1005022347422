import { I18n } from "@meraki/core/i18n";
import { VlanGroupProps } from "@meraki/go/navigation-type";
import { Button, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { queryClient, useUpdateVlan, useVlan, useVlans } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { isIpReservationConflicting } from "@meraki/shared/ip-address";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Address4 } from "ip-address";
import React, { useCallback, useEffect } from "react";
import { Alert } from "react-native";

export type ReserveIpAddressForm = {
  comment: string;
  end: string;
  start: string;
};

const DEFAULT_RESERVE_IP_ADDRESS = {
  comment: "",
  end: "",
  start: "",
};

export function ReserveIpAddressScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<VlanGroupProps>>();
  const route = useRoute<RouteProp<VlanGroupProps, "ReserveIpAddress">>();
  const { vlanId, reservedIpIndex } = route.params;

  const networkId = useCurrentNetworkId();
  const { data: vlan, isLoading: vlanIsLoading } = useVlan({
    networkId,
    vlanId,
  });

  const { mutate: mutateVlan, isLoading: vlanIsUpdating } = useUpdateVlan();

  const methods = useForm<ReserveIpAddressForm>({
    values:
      reservedIpIndex !== undefined
        ? vlan?.reservedIpRanges[reservedIpIndex] ?? DEFAULT_RESERVE_IP_ADDRESS
        : DEFAULT_RESERVE_IP_ADDRESS,
  });

  const placeholderText = () => {
    const parts = vlan?.applianceIp.split(".");
    if (parts !== undefined && parts.length > 3) {
      const placeholder = parts.slice(0, -1).join(".");
      return placeholder + ".x";
    } else {
      return vlan?.applianceIp;
    }
  };

  const onSave = useCallback(
    ({ comment, end, start }: ReserveIpAddressForm) => {
      if (vlan) {
        const reservedIp = { comment, end, start };

        let updatedNewVlan = vlan;

        if (reservedIpIndex !== undefined) {
          const updatedReservedIPRanges = vlan.reservedIpRanges.filter(
            (_, index) => index !== reservedIpIndex,
          );
          updatedNewVlan = { ...vlan, reservedIpRanges: [...updatedReservedIPRanges, reservedIp] };
        } else {
          updatedNewVlan = { ...vlan, reservedIpRanges: [...vlan.reservedIpRanges, reservedIp] };
        }
        mutateVlan(
          { networkId, vlanId: vlanId, vlan: updatedNewVlan },
          {
            onError: (error) => {
              Alert.alert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
            },
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: useVlans.queryKey({ networkId }) });
              navigation.goBack();
            },
          },
        );
      }
    },
    [mutateVlan, networkId, navigation, reservedIpIndex, vlan, vlanId],
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav
          text={I18n.t("SAVE")}
          onPress={methods.handleSubmit(onSave)}
          disabled={!methods.formState.isDirty || vlanIsUpdating || vlanIsLoading}
        />
      ),
    });
  }, [methods, methods.formState.isDirty, onSave, navigation, vlanIsLoading, vlanIsUpdating, vlan]);

  const onDelete = () => {
    if (vlan && reservedIpIndex !== undefined) {
      const updatedReservedIPRanges = vlan.reservedIpRanges.filter(
        (_, index) => index !== reservedIpIndex,
      );
      const updatedVlan = { ...vlan, reservedIpRanges: updatedReservedIPRanges };
      mutateVlan(
        { networkId, vlanId: vlanId, vlan: updatedVlan },
        {
          onError: (error) => {
            Alert.alert(I18n.t("ERROR"), error.errors?.join(",") || I18n.t("SERVER_ERROR_TEXT"));
          },
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: useVlans.queryKey({ networkId }) });
            navigation.goBack();
          },
        },
      );
    }
  };

  const validateIpInputs = useCallback(
    (ip: string) => {
      const ipConflicts =
        isIpReservationConflicting(ip, vlan?.subnet, vlan?.applianceIp) ?? undefined;
      const ipValid = Address4.isValid(ip);
      if (!ipValid) {
        return I18n.t("RESERVE_IP_RANGE.GOv3.INVALID_IP");
      }
      return ipConflicts ?? undefined;
    },
    [vlan],
  );

  return (
    <Screen.View>
      <Box flex={1} justifyContent="space-between" paddingHorizontal="md" paddingVertical="md">
        <Box gap="md">
          <Text size="p1">{I18n.t("RESERVE_IP_RANGE.SUBTITLE")}</Text>

          <Form {...methods}>
            <Form.Input
              name="comment"
              label={I18n.t("NAME")}
              additionalContext={I18n.t("RESERVE_IP_RANGE.PLACEHOLDER")}
              showClear={false}
              testID="NAME_INPUT"
              rules={{
                required: I18n.t("RESERVE_IP_RANGE.GOv3.NAME_EMPTY_ERROR"),
              }}
            />
            <Form.Input
              name="start"
              rules={{
                required: I18n.t("RESERVE_IP_RANGE.GOv3.IP_EMPTY_ERROR"),
                validate: validateIpInputs,
              }}
              label={I18n.t("RESERVE_IP_RANGE.FIRST_IP")}
              placeholder={placeholderText()}
              showClear={false}
              testID="FIRST_IP_INPUT"
            />
            <Form.Input
              name="end"
              rules={{
                required: I18n.t("RESERVE_IP_RANGE.GOv3.IP_EMPTY_ERROR"),
                validate: validateIpInputs,
              }}
              label={I18n.t("RESERVE_IP_RANGE.LAST_IP")}
              placeholder={placeholderText()}
              showClear={false}
              testID="LAST_IP_INPUT"
            />
          </Form>
        </Box>
        {vlan?.reservedIpRanges && // checking this exists so that test has form values set
        vlan?.reservedIpRanges.length > 0 &&
        reservedIpIndex !== undefined ? (
          <Button
            text={I18n.t("DELETE")}
            kind="secondaryDestructive"
            onPress={() => onDelete()}
            disabled={vlanIsUpdating || vlanIsLoading}
            testID="DELETE_BUTTON"
          />
        ) : null}
      </Box>
    </Screen.View>
  );
}
