import { useTheme } from "@meraki/core/theme";
import { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

export interface InfoCardProps {
  children: ReactNode;
  visible: boolean;
  cardTitle?: string;
  onExit?: () => void;
  dismissible?: boolean;
  screenStyles?: StyleProp<ViewStyle>[] | StyleProp<ViewStyle>;
  testID?: string;
}

export const InfoCard = (props: InfoCardProps) => {
  const { children, visible, cardTitle, dismissible, onExit, screenStyles, testID } = props;
  const { theme } = useTheme.getState();
  if (!visible) {
    return null;
  }
  const style = [
    styles.infoCard,
    themeColors(theme).infoCard,
    dismissible ? styles.dismissibleInfoCard : {},
    screenStyles,
  ];

  return (
    <View style={style} testID={testID}>
      <View style={styles.titleContainer}>
        <MkiText textStyle="subheading" screenStyles={styles.titleText}>
          {cardTitle}
        </MkiText>
        {dismissible && (
          <MerakiIcon
            name="close"
            size="s"
            color={MkiColors.secondaryButton}
            onPress={onExit}
            testID="INFO.DISMISS"
          />
        )}
      </View>
      {children}
    </View>
  );
};

InfoCard.defaultProps = {
  cardTitle: undefined,
  onExit: undefined,
  dismissible: true,
  screenStyles: {},
  testID: undefined,
};

const styles = StyleSheet.create({
  infoCard: {
    paddingTop: SPACING.large,
    paddingBottom: SPACING.default,
    paddingHorizontal: SPACING.default,
    backgroundColor: MkiColors.infoCardColor,
    borderRadius: 6,
  },
  dismissibleInfoCard: {
    paddingTop: SPACING.small,
    paddingRight: SPACING.small,
  },
  titleText: {
    marginTop: SPACING.small,
    marginBottom: SPACING.default,
    flexShrink: 1,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default InfoCard;
