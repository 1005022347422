import { I18n } from "@meraki/core/i18n";
import { Card, List } from "@meraki/magnetic/components";
import { useNetworkConnectionsStats } from "@meraki/shared/api";
import { useCurrentNetworkId, useGlobalTimespan } from "@meraki/shared/redux";

const WEEK_IN_SECONDS = 86400 * 7;

const convertToPercent = (num: number, den: number) => {
  return den === 0 ? "0%" : `${((num / den) * 100).toFixed(1)}%`;
};

export function ConnectionStatsCard() {
  const networkId = useCurrentNetworkId();
  const timespan = Math.min(useGlobalTimespan(), WEEK_IN_SECONDS);

  const { data, isLoading } = useNetworkConnectionsStats({ networkId, timespan });
  const totalStats = Object.values(data ?? []).reduce((current, value) => current + value, 0);

  return (
    <Card loading={isLoading} loadingContentHeight={60}>
      <Card.Header title={I18n.t("ASSOCIATION_STATS.SUMMARY_HEADER.HEADING")} />
      <List>
        <List.Item
          title={I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.ASSOC")}
          value={convertToPercent(totalStats - (data?.assoc ?? 0), totalStats)}
        />
        <List.Item
          title={I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.PSK")}
          value={convertToPercent(totalStats - (data?.auth ?? 0), totalStats)}
        />
        <List.Item
          title={I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.DHCP")}
          value={convertToPercent(totalStats - (data?.dhcp ?? 0), totalStats)}
        />
        <List.Item
          title={I18n.t("ASSOCIATION_STATS.ASSOCIATION_STEPS.DNS")}
          value={convertToPercent(totalStats - (data?.dns ?? 0), totalStats)}
        />
      </List>
    </Card>
  );
}
