import {
  INITIATE_EXPORT_FAILURE,
  INITIATE_EXPORT_REQUEST,
  INITIATE_EXPORT_SUCCESS,
  SET_EXPORT_TRIGGERED_TO_IDLE,
  WIPE_REDUX,
} from "@meraki/shared/redux";

export const exportTriggerStatuses = {
  IDLE: "IDLE",
  TRIGGER_SUCCESS: "TRIGGER_SUCCESS",
  TRIGGER_FAIL: "TRIGGER_FAIL",
};

export const initialState = {
  exportTriggered: exportTriggerStatuses.IDLE,
  exportList: [],
  isOrgAdmin: false,
};

export default function initiateExport(
  state: { exportTriggered: string } = initialState,
  action: any,
): { exportTriggered: string; failureMessage?: string } {
  switch (action.type) {
    case INITIATE_EXPORT_REQUEST:
      return {
        ...state,
      };
    case INITIATE_EXPORT_SUCCESS:
      return {
        ...state,
        exportTriggered: exportTriggerStatuses.TRIGGER_SUCCESS,
      };
    case INITIATE_EXPORT_FAILURE:
      return {
        ...state,
        exportTriggered: exportTriggerStatuses.TRIGGER_FAIL,
        failureMessage: action.error,
      };
    case SET_EXPORT_TRIGGERED_TO_IDLE:
      return {
        ...state,
        exportTriggered: exportTriggerStatuses.IDLE,
      };

    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
