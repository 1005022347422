import Svg, { G, Mask, Path } from "react-native-svg";

import { IconProps } from "./types";

export function PlugPort({ x, y }: IconProps) {
  return (
    <G x={x} y={y}>
      <Svg fill="none" height="26" viewBox="0 0 27 26" width="27">
        <Mask fill="#000" height="26" id="a" maskUnits="userSpaceOnUse" width="26" x="-.667" y="0">
          <Path d="M.667 0h26v26h-26z" fill="#fff" />
          <Path d="M12.666 20.938v-1.883a3.802 3.802 0 0 1-2.8-3.666v-3.223a1 1 0 0 1 1-1h.267V8.845a.844.844 0 1 1 1.69 0v2.322h1.688V8.845a.844.844 0 1 1 1.689 0v2.322h.267a1 1 0 0 1 1 1v3.222a3.802 3.802 0 0 1-2.8 3.667v1.883a8.001 8.001 0 0 0-1-15.938 8 8 0 0 0-1 15.938Zm11-7.938c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10s4.478-10 10-10c5.523 0 10 4.477 10 10Z" />
        </Mask>
        <Path
          d="M12.666 20.938v-1.883a3.802 3.802 0 0 1-2.8-3.666v-3.223a1 1 0 0 1 1-1h.267V8.845a.844.844 0 1 1 1.69 0v2.322h1.688V8.845a.844.844 0 1 1 1.689 0v2.322h.267a1 1 0 0 1 1 1v3.222a3.802 3.802 0 0 1-2.8 3.667v1.883a8.001 8.001 0 0 0-1-15.938 8 8 0 0 0-1 15.938Zm11-7.938c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10s4.478-10 10-10c5.523 0 10 4.477 10 10Z"
          fill="#A16C03"
        />
        <Path
          d="M12.666 19.055h2.5v-1.91l-1.843-.502-.657 2.412Zm0 1.883-.309 2.48 2.81.351v-2.83h-2.5Zm-2.8-5.55h-2.5 2.5Zm1.267-4.222v2.5h2.5v-2.5h-2.5Zm1.69 0h-2.5v2.5h2.5v-2.5Zm1.688 0v2.5h2.5v-2.5h-2.5Zm1.689 0h-2.5v2.5h2.5v-2.5Zm1.267 4.223h2.5-2.5Zm-2.8 3.666-.657-2.412-1.844.502v1.91h2.5Zm0 1.883h-2.5v2.831l2.809-.35-.31-2.48Zm-4.5-1.883v1.883h5v-1.883h-5Zm-2.8-3.666a6.302 6.302 0 0 0 4.643 6.079l1.313-4.825a1.302 1.302 0 0 1-.957-1.255h-5Zm0 0h5-5Zm0-.845v.844h5v-.844h-5Zm0-2.378v2.378h5v-2.378h-5Zm3.5-3.5a3.5 3.5 0 0 0-3.5 3.5h5a1.5 1.5 0 0 1-1.5 1.5v-5Zm.266 0h-.266v5h.266v-5Zm-2.5.178v2.322h5V8.845h-5ZM11.978 5.5a3.344 3.344 0 0 0-3.345 3.344h5c0 .915-.741 1.656-1.655 1.656v-5Zm3.344 3.344A3.344 3.344 0 0 0 11.978 5.5v5a1.656 1.656 0 0 1-1.656-1.656h5Zm0 2.322V8.845h-5v2.322h5Zm-.811-2.5h-1.689v5h1.689v-5Zm-2.5.178v2.322h5V8.845h-5ZM15.355 5.5a3.344 3.344 0 0 0-3.344 3.344h5c0 .915-.741 1.656-1.656 1.656v-5ZM18.7 8.844A3.344 3.344 0 0 0 15.355 5.5v5A1.656 1.656 0 0 1 13.7 8.844h5Zm0 2.322V8.845h-5v2.322h5Zm-2.233-2.5H16.2v5h.267v-5Zm3.5 3.5a3.5 3.5 0 0 0-3.5-3.5v5a1.5 1.5 0 0 1-1.5-1.5h5Zm0 2.378v-2.378h-5v2.378h5Zm0 .844v-.844h-5v.844h5Zm0 0h-5 5Zm-4.644 6.08a6.302 6.302 0 0 0 4.643-6.08h-5c0 .597-.403 1.105-.956 1.255l1.313 4.825Zm1.843-.53v-1.883h-5v1.883h5Zm-2.19 2.48c5.182-.645 9.19-5.062 9.19-10.418h-5a5.501 5.501 0 0 1-4.809 5.457l.619 4.962ZM24.166 13c0-5.799-4.7-10.5-10.5-10.5v5a5.5 5.5 0 0 1 5.5 5.5h5Zm-10.5-10.5c-5.798 0-10.5 4.701-10.5 10.5h5a5.5 5.5 0 0 1 5.5-5.5v-5ZM3.166 13c0 5.356 4.01 9.773 9.191 10.419l.619-4.962A5.501 5.501 0 0 1 8.166 13h-5Zm10.5 12.5c6.904 0 12.5-5.596 12.5-12.5h-5a7.5 7.5 0 0 1-7.5 7.5v5ZM1.166 13c0 6.904 5.597 12.5 12.5 12.5v-5a7.5 7.5 0 0 1-7.5-7.5h-5ZM13.666.5C6.763.5 1.166 6.096 1.166 13h5a7.5 7.5 0 0 1 7.5-7.5v-5Zm12.5 12.5c0-6.904-5.596-12.5-12.5-12.5v5a7.5 7.5 0 0 1 7.5 7.5h5Z"
          fill="#F0E0A2"
          mask="url(#a)"
        />
      </Svg>
    </G>
  );
}
