import { memo } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import I18n from "~/i18n/i18n";
import { PING_RESULT_GROUP } from "~/lib/PingUtils";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

const icons = {
  [PING_RESULT_GROUP.ok]: (
    <MerakiIcon name="check-circle" size="xl" color={MkiColors.activeInputUnderline} />
  ),
  [PING_RESULT_GROUP.loss]: <MerakiIcon name="alert" size="xl" color={MkiColors.badStatus} />,
  [PING_RESULT_GROUP.unacceptable]: (
    <MerakiIcon name="exit-circle" size="xl" color={MkiColors.badStatus} />
  ),
};

export type PingResultGroup = (typeof PING_RESULT_GROUP)[keyof typeof PING_RESULT_GROUP];

type Props = {
  messages: Record<PingResultGroup, string>;
  onButtonPress: () => void;
  resultGroup: PingResultGroup;
  buttonText?: string;
};

const PingResultDescription = memo(function PingResultDescription(props: Props) {
  const { buttonText = I18n.t("LIVE_TOOLS.RETEST"), messages, onButtonPress, resultGroup } = props;

  return (
    <View style={styles.results}>
      {icons[resultGroup]}
      <MkiText screenStyles={styles.centerText} textStyle="subheading">
        {messages[resultGroup]}
      </MkiText>
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="secondary"
          onPress={onButtonPress}
          screenStyles={styles.buttonStyle}
        >
          {buttonText}
        </RoundedButton>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  centerText: {
    alignSelf: "center",
    textAlign: "center",
    paddingHorizontal: SPACING.extraLarge,
    paddingBottom: SPACING.small,
    paddingTop: SPACING.default,
  },
  results: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
});

export default PingResultDescription;
