import { z } from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const UmbrellaAuthTokenSchema = z
  .object({
    token: z.string(),
  })
  .describe("UmbrellaAuthTokenSchema");

export type UmbrellaTokenResponse = z.infer<typeof UmbrellaAuthTokenSchema>;

const buildUrl = () => {
  return `/manage/security/umbrella_oauth2_token`;
};

const fetchUmbrellaStatus = () => {
  return request(UmbrellaAuthTokenSchema, "GET", buildUrl(), {
    queryParams: {
      api_key_name: "reporting",
    },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useUmbrellaAuthToken = createQuery({
  baseQueryKey: buildUrl(),
  queryFn: fetchUmbrellaStatus,
});
