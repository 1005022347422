import { Ethernet } from "./ports/Ethernet";
import { Sfp } from "./ports/Sfp";
import { Stack } from "./ports/Stack";
import { Usb } from "./ports/Usb";
import { PortProps } from "./types";

export { PortProps } from "./types";

export function Port(props: PortProps) {
  switch (props.kind) {
    case "usb":
      return <Usb {...props} />;
    // TODO: Put the others!
    case "sfp":
      return <Sfp {...props} />;
    case "stack":
      return <Stack {...props} />;
    case "ethernet":
    default:
      return <Ethernet {...props} />;
  }
}
