import { I18n } from "@meraki/core/i18n";
import { Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";
import { SingleBarGraph } from "@meraki/shared/components";

type Strength = "WEAK" | "MEDIUM" | "STRONG";

/**
 * the only hard requirement for a password is to be 8+ characters long.
 * this function also checks if the password contains a number and special
 * character, but it is optional for password creation. This is the same
 * thing the web flow does.
 */
const getStrength = (password: string): Strength => {
  const containsNumber = new RegExp("[0-9]").test(password);
  const containsSpecialChar = new RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\\\\\]^_`{|}~]").test(password);

  if (password.length < 8) {
    return "WEAK";
  }

  if (containsNumber && containsSpecialChar) {
    return "STRONG";
  } else if (containsNumber || containsSpecialChar) {
    return "MEDIUM";
  }

  return "WEAK";
};

const getData = (strength: Strength): [number, string] => {
  switch (strength) {
    case "WEAK":
      return [0.3, "negative"];
    case "MEDIUM":
      return [0.6, "warning"];
    case "STRONG":
    default:
      return [1, "positive"];
  }
};

type Props = {
  password?: string;
  passwordRules?: string;
};

const PasswordStrengthInternal = ({ password }: { password: string }) => {
  const strength = getStrength(password);

  const [percentage, colorType] = getData(strength);

  const data = [
    {
      percentage,
      color: `${colorType}.icon.base` as ColorToken,
    },
    {
      percentage: 1 - percentage,
      color: "dormant.icon.base" as const,
    },
  ];

  const strengthText = `PASSWORD_STRENGTH.${strength}`;

  return (
    <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
      <Box width="50%">
        <Text color="light">
          {I18n.t("PASSWORD_STRENGTH.STRENGTH", { strength: I18n.t(strengthText) })}
        </Text>
      </Box>
      <Box width="50%">
        <SingleBarGraph data={data} testID={strength} />
      </Box>
    </Box>
  );
};

export const PasswordStrength = ({ password, passwordRules }: Props) => (
  <>
    {passwordRules && <Text color="light">{passwordRules}</Text>}
    {password && <PasswordStrengthInternal password={password} />}
  </>
);
