import { I18n } from "@meraki/core/i18n";
import { usePasswordResetRequest } from "@meraki/shared/api";
import { useCallback, useReducer } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE } from "~/constants/MkiConstants";
import InputModal from "~/shared/components/InputModal";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import { Alert } from "~/shared/types/AlertTypes";

interface Props {
  email: string;
}

interface States {
  alert?: Alert;
  resetEmail: string;
  showModal: boolean;
}

const update = (data: States, partial: Partial<States>) => ({ ...data, ...partial });

const ForgotPasswordButton = ({ email }: Props) => {
  const passwordResetRequest = usePasswordResetRequest();

  const [{ alert, resetEmail, showModal }, updateState] = useReducer(update, {
    alert: undefined,
    resetEmail: email,
    showModal: false,
  });

  const onSubmit = useCallback(() => {
    if (!resetEmail) {
      updateState({
        alert: {
          message: I18n.t("FORGOT_PASSWORD.ERROR_EMPTY"),
          status: GoStatus.bad,
        },
      });
      return;
    }

    passwordResetRequest.mutate(
      { email: resetEmail },
      {
        onSuccess: () =>
          updateState({
            alert: {
              message: I18n.t("FORGOT_PASSWORD.SUCCESS"),
              status: GoStatus.good,
            },
          }),
        onError: () =>
          updateState({
            alert: {
              message: I18n.t("FORGOT_PASSWORD.ERROR_NETWORK"),
              status: GoStatus.bad,
            },
          }),
      },
    );
  }, [passwordResetRequest, resetEmail]);

  return (
    <>
      <TouchableOpacity
        testID="PASSWORD_RESET_BUTTON"
        onPress={() => updateState({ showModal: true, alert: undefined })}
      >
        <MkiText textStyle="small" screenStyles={styles.forgotPasswordText}>
          {I18n.t("LOGIN.FORGOT_PASSWORD")}
        </MkiText>
      </TouchableOpacity>
      <InputModal
        value={resetEmail}
        title={I18n.t("FORGOT_PASSWORD.QUESTION")}
        subtitle={I18n.t("FORGOT_PASSWORD.PROMPT")}
        visible={showModal}
        primaryButtonText={I18n.t("FORGOT_PASSWORD.BUTTON")}
        onPrimaryPress={onSubmit}
        onChangeText={(newText: any) => updateState({ resetEmail: newText })}
        onSubmitEditing={onSubmit}
        onExit={() => updateState({ showModal: false })}
        alert={alert}
        keyboardType={KEYBOARD_TYPE.emailAddress}
        autoCapitalize="none"
        loading={passwordResetRequest.isLoading}
      />
    </>
  );
};

const styles = StyleSheet.create({
  forgotPasswordText: {
    backgroundColor: MkiColors.fullTransparent,
    color: MkiColors.primaryButton,
  },
});

export default ForgotPasswordButton;
