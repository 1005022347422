import { z } from "zod";

import { request } from "../../api/request/request";
import { ProductType } from "../../schemas";
import { StatusSchema } from "../../schemas/Device";
import { createQuery } from "../createQuery";

export const DeviceStatusSchema = z.object({
  name: z.string().nullable(),
  serial: z.string(),
  mac: z.string(),
  publicIp: z.string().nullable(),
  networkId: z.string(),
  status: StatusSchema,
  lastReportedAt: z.string().nullable(),
  lanIp: z.string().optional().nullable(),
  gateway: z.string().optional(),
  ipType: z.string().optional(),
  primaryDns: z.string().optional(),
  secondaryDns: z.string().optional().nullable(),
  productType: z.union([
    z.literal("wireless"),
    z.literal("appliance"),
    z.literal("switch"),
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("sensor"),
    z.literal("systemsManager"),
  ]),
  components: z
    .object({
      powerSupplies: z.array(
        z.object({
          model: z.string().optional(),
        }),
      ),
    })
    .optional(),
  model: z.string(),
  tags: z.array(z.string().optional()),
  wan1Gateway: z.string().optional(),
  wan1Ip: z.string().optional(),
  wan1IpType: z.string().optional(),
  wan1PrimaryDns: z.string().optional(),
  wan1SecondaryDns: z.string().optional().nullable(),
});

export const DeviceStatusesSchema = z.array(DeviceStatusSchema).describe("DeviceStatusSchema");

export type DeviceStatus = z.infer<typeof DeviceStatusSchema>;
export type DeviceStatuses = z.infer<typeof DeviceStatusesSchema>;

interface DeviceStatusesRequest {
  organizationId?: string;
  networkIds?: string[];
  serials?: string[];
  productTypes?: ProductType[];
}

function buildUrl({ organizationId }: { organizationId: DeviceStatusesRequest["organizationId"] }) {
  return `/api/v1/organizations/${organizationId}/devices/statuses`;
}

const fetchDeviceStatuses = ({ organizationId, ...queryParams }: DeviceStatusesRequest) => {
  if (!organizationId) {
    throw new Error("Undefined organization id when fetching devices");
  }

  return request(DeviceStatusesSchema, "GET", buildUrl({ organizationId }), { queryParams });
};

export const useDeviceStatuses = createQuery<DeviceStatusesRequest, DeviceStatuses>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: fetchDeviceStatuses,
  requiredVariables: ["organizationId"],
});
