import { ReactNode } from "react";
import { SafeAreaView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { DOT, SPACING } from "~/constants/MkiConstants";

interface ColoredDot {
  key: number;
  style?: StyleProp<ViewStyle>;
}

const ColoredDot: React.FC<ColoredDot> = ({ style }) => {
  return <View style={[styles.dot, style]} />;
};

interface PageIndicator {
  children: ReactNode;
  activeDotStyle?: StyleProp<ViewStyle>;
  inactiveDotStyle?: StyleProp<ViewStyle>;
  numPages: number;
  activePage: number;
}

const PageIndicator: React.FC<PageIndicator> = ({
  numPages,
  activePage,
  children,
  activeDotStyle,
  inactiveDotStyle,
}) => {
  const dots: React.ReactNode[] = [];

  const resolvedActiveDotStyle = activeDotStyle || styles.dotActive;
  const resolvedInactiveDotStyle = inactiveDotStyle || styles.dotInactive;

  for (let i = 1; i <= numPages; i++) {
    const style = i === activePage ? resolvedActiveDotStyle : resolvedInactiveDotStyle;
    dots.push(<ColoredDot key={i} style={style} />);
  }

  return (
    <View style={styles.container}>
      <View style={styles.childContainer}>{children}</View>
      <SafeAreaView style={styles.dotsContainer}>{dots}</SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 100,
  },
  childContainer: {
    flex: 96,
  },
  dot: {
    width: DOT.size,
    height: DOT.size,
    borderRadius: DOT.size / 2,
    marginHorizontal: DOT.spacing,
  },
  dotsContainer: {
    flexDirection: "row",
    flex: 4,
    justifyContent: "center",
    alignItems: "flex-end",
    marginBottom: SPACING.default,
  },
  dotInactive: {
    backgroundColor: MkiColors.pagerDotColor,
  },
  dotActive: {
    backgroundColor: MkiColors.primaryButton,
  },
});

export default PageIndicator;
