import { I18n } from "@meraki/core/i18n";
import { RefreshControl, Text } from "@meraki/magnetic/components";
import { Icon, Status, StatusProps } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useThemeMode } from "@meraki/magnetic/themes";
import {
  DeviceStatusOverviewByStatus,
  OrgNetwork,
  queryClient,
  useOrganizationDeviceStatusOverview,
  useOrganizations,
  useOrgNetworks,
} from "@meraki/shared/api";
import { DrawerSelector, DrawerSelectorMethods } from "@meraki/shared/components";
import { BorderedProductIcon } from "@meraki/shared/product-icons";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { DrawerContentComponentProps } from "@react-navigation/drawer";
import { useEffect, useRef } from "react";
import { Image, Pressable, StyleSheet } from "react-native";

import { analytics } from "~/lib/FirebaseModules";
import useActions from "~/shared/hooks/redux/useActions";

export const ALL_NETWORKS = {
  id: "ALL",
  name: I18n.t("HOME.ALL_NETWORKS"),
} as OrgNetwork;

export const getNetworkStatus = (
  byStatus?: DeviceStatusOverviewByStatus,
): StatusProps["status"] => {
  if (byStatus) {
    if (byStatus.offline) {
      return "negative";
    }

    if (byStatus.alerting) {
      return "warning";
    }

    if (byStatus.online) {
      return "positive";
    }
  }

  return "neutral";
};

interface NetworkStatusIconProps {
  organizationId?: string;
  id: string;
}

const NetworkStatusIcon = ({ organizationId, id }: NetworkStatusIconProps) => {
  const { data: networkStatus } = useOrganizationDeviceStatusOverview(
    {
      organizationId,
      networkId: id === ALL_NETWORKS.id ? undefined : id,
    },
    {
      select: (data) => getNetworkStatus(data.counts.byStatus),
    },
  );

  return <Status status={networkStatus ?? "neutral"} />;
};

export function LeftDrawer({ navigation }: DrawerContentComponentProps) {
  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();

  const actions = useActions();
  const theme = useThemeMode();

  const networkRef = useRef<DrawerSelectorMethods>(null);
  const organizationRef = useRef<DrawerSelectorMethods>(null);

  useEffect(() => {
    if (networkId) {
      networkRef?.current?.open?.();
    } else {
      organizationRef?.current?.open?.();
    }
  }, [networkId]);

  const navLogo =
    theme === "light"
      ? require("~/images/logos/merakiGo/logoMerakiGoLargeColor.png")
      : require("~/images/logos/merakiGo/logoMerakiGoMediumWhite.png");

  const {
    data: organizations,
    refetch: refetchOrganizations,
    isRefetching: isOrganizationsRefetching,
  } = useOrganizations({});

  const {
    data: fetchedNetworks,
    refetch: refetchNetworks,
    isRefetching: isNetworksRefetching,
  } = useOrgNetworks({ organizationId });

  const handleNetworkChange = (newNetworkId: string) => {
    if (newNetworkId === ALL_NETWORKS.id) {
      organizationRef?.current?.open?.();
      networkRef?.current?.close?.();
      actions.setCurrentNetwork(null);
    } else if (newNetworkId !== networkId) {
      organizationRef?.current?.close?.();
      networkRef?.current?.open?.();
      actions.setCurrentNetwork(newNetworkId);
    }

    navigation.navigate("Monitor");
    navigation.closeDrawer();
  };

  const handleOrganizationChange = async (newOrganizationId: string) => {
    const organization = organizations?.find(({ id }) => id === newOrganizationId);
    if (!organization) {
      return;
    }

    if (newOrganizationId === organizationId) {
      networkRef?.current?.open?.();
      return;
    }

    analytics.setUserProperty("mki_org_id", newOrganizationId);
    analytics.setUserProperty("organization_id", newOrganizationId);

    try {
      await actions.setOrg(organization);
      actions.setCurrentNetwork(null);
    } catch (e) {
      // Swallow for now.
    }
  };

  const networks = [ALL_NETWORKS, ...(fetchedNetworks || [])];
  return (
    <Screen.SafeAreaView edges={["top"]} gap="none">
      <Box padding="sm">
        <Image style={styles.logo} resizeMode="contain" source={navLogo} />
      </Box>
      <Box flex={1} justifyContent="space-between">
        <Box>
          <DrawerSelector
            ref={organizationRef}
            title={I18n.t("DRAWER.ORGANIZATION")}
            titleIcon={
              <BorderedProductIcon
                name="organization"
                color={organizationRef?.current?.isOpen?.()}
              />
            }
            refreshControl={
              <RefreshControl
                refreshing={isOrganizationsRefetching}
                onRefresh={refetchOrganizations}
              />
            }
            items={organizations}
            selected={organizationId}
            onSelect={handleOrganizationChange}
            testID="ORGANIZATION_SELECTOR"
          />
          <DrawerSelector
            ref={networkRef}
            title={I18n.t("DRAWER.NETWORK")}
            titleIcon={
              <BorderedProductIcon name="network" color={networkRef?.current?.isOpen?.()} />
            }
            refreshControl={
              <RefreshControl
                refreshing={isNetworksRefetching}
                onRefresh={() => {
                  refetchNetworks();
                  queryClient.invalidateQueries({
                    queryKey: useOrganizationDeviceStatusOverview.queryKeyRoot,
                  });
                }}
              />
            }
            items={networks}
            excludeAllInCount={true}
            renderItemIcon={(item) => (
              <NetworkStatusIcon id={item.id} organizationId={organizationId} />
            )}
            selected={networkId ? networkId : ALL_NETWORKS.id}
            onSelect={handleNetworkChange}
            testID="NETWORK_SELECTOR"
          />
        </Box>
        <Box gap="md" paddingLeft="lg" paddingBottom="lg">
          <Pressable
            onPress={() =>
              navigation.navigate("ConfigureTab", { screen: "Account", initial: false })
            }
          >
            <Box flexDirection="row" alignItems="center" gap="sm" borderRadius="md">
              <Icon name="User" />
              <Text size="p1" weight="semiBold">
                {I18n.t("ACCOUNT_SCREEN.ACCOUNT_TITLE")}
              </Text>
            </Box>
          </Pressable>
        </Box>
      </Box>
    </Screen.SafeAreaView>
  );
}

const styles = StyleSheet.create({
  logo: {
    height: 44,
    width: 125,
  },
});
