import {
  CLIENT_LIST_JSON_FAILURE,
  CLIENT_LIST_JSON_REQUEST,
  CLIENT_LIST_JSON_SUCCESS,
  CLIENTS_AND_USEBLOCKS_FINISH,
  CLIENTS_AND_USEBLOCKS_START,
  NETWORK_USEBLOCKS_FAILURE,
  NETWORK_USEBLOCKS_REQUEST,
  NETWORK_USEBLOCKS_SUCCESS,
  NODES_FAILURE,
  NODES_REQUEST,
  NODES_SUCCESS,
  RULE_USEBLOCKS_FAILURE,
  RULE_USEBLOCKS_REQUEST,
  RULE_USEBLOCKS_SUCCESS,
  SSID_USEBLOCKS_FAILURE,
  SSID_USEBLOCKS_REQUEST,
  SSID_USEBLOCKS_SUCCESS,
  SWITCH_PORTS_JSON_FAILURE,
  SWITCH_PORTS_JSON_REQUEST,
  SWITCH_PORTS_JSON_SUCCESS,
  UMBRELLA_FETCHING_FAILURE,
  UMBRELLA_FETCHING_REQUEST,
  UMBRELLA_FETCHING_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { UmbrellaFetchingState } from "~/shared/types/Umbrella";

const initialState = {
  applicationUseblocksFetching: false,
  networkUseblocksFetching: false,
  clientsFetching: false,
  clientsAndUseBlocksFetching: false,
  ssidUseblocksFetching: false,
  fetchingNodes: false,
  umbrellaFetching: UmbrellaFetchingState.finished,
};

export default function loading(state = initialState, action: any) {
  switch (action.type) {
    case NETWORK_USEBLOCKS_REQUEST:
      return {
        ...state,
        networkUseblocksFetching: true,
      };
    case NETWORK_USEBLOCKS_FAILURE:
    case NETWORK_USEBLOCKS_SUCCESS:
      return {
        ...state,
        networkUseblocksFetching: false,
      };
    case CLIENTS_AND_USEBLOCKS_START:
      return {
        ...state,
        clientsFetching: true,
        clientsAndUseBlocksFetching: true,
      };
    case CLIENTS_AND_USEBLOCKS_FINISH:
      return {
        ...state,
        clientsFetching: false,
        clientsAndUseBlocksFetching: false,
      };
    case CLIENT_LIST_JSON_REQUEST:
      return {
        ...state,
        clientsFetching: true,
      };
    case CLIENT_LIST_JSON_FAILURE:
    case CLIENT_LIST_JSON_SUCCESS:
      return {
        ...state,
        clientsFetching: false,
      };
    case RULE_USEBLOCKS_REQUEST:
      return {
        ...state,
        applicationUseblocksFetching: true,
      };
    case RULE_USEBLOCKS_FAILURE:
    case RULE_USEBLOCKS_SUCCESS:
      return {
        ...state,
        applicationUseblocksFetching: false,
      };
    case SSID_USEBLOCKS_REQUEST:
      return {
        ...state,
        ssidUseblocksFetching: true,
      };
    case SSID_USEBLOCKS_FAILURE:
    case SSID_USEBLOCKS_SUCCESS:
      return {
        ...state,
        ssidUseblocksFetching: false,
      };
    case SWITCH_PORTS_JSON_REQUEST:
      return {
        ...state,
        switchPortJsonFetching: true,
      };
    case SWITCH_PORTS_JSON_FAILURE:
    case SWITCH_PORTS_JSON_SUCCESS:
      return {
        ...state,
        switchPortJsonFetching: false,
      };
    case NODES_REQUEST:
      return {
        ...state,
        fetchingNodes: true,
      };
    case NODES_FAILURE:
    case NODES_SUCCESS:
      return {
        ...state,
        fetchingNodes: false,
      };
    case UMBRELLA_FETCHING_REQUEST:
      return {
        ...state,
        umbrellaFetching: UmbrellaFetchingState.loading,
      };
    case UMBRELLA_FETCHING_SUCCESS:
      return {
        ...state,
        umbrellaFetching: UmbrellaFetchingState.finished,
      };
    case UMBRELLA_FETCHING_FAILURE:
      return {
        ...state,
        umbrellaFetching: UmbrellaFetchingState.failed,
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
