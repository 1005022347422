import { createSelector } from "reselect";

import { SWITCH_PORTS_KEY } from "~/constants/SearchKeys";
import { convertTagsToArray, isValidMacAddress } from "~/lib/DeviceUtils";
import { portNumberOfSwitchPort, switchPortsToArray } from "~/lib/SwitchPortUtils";
import { isNumber } from "~/lib/TypeHelper";
import { devicesState, getSwitchPorts } from "~/selectors/getters";
import { searchTextState } from "~/selectors/search";
import { SwitchPort, SwitchPortState } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";

// Filters all ports such that each port contains at least some of the words in the search words
const allPortsMatchingAnySearchWord = (ports: any, searches: any) =>
  ports.filter((port: any) =>
    convertTagsToArray(port.tags).some((tag: any) =>
      searches.some((search: any) => tag.includes(search)),
    ),
  );

export const switchPortById = createSelector(
  getSwitchPorts,
  (_: RootState, id: any) => id,
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  (switchPorts, id) => Object.values(switchPorts).find((ports) => ports[id])?.[id],
);

const sortSwitchPorts = createSelector(
  getSwitchPorts,
  (_: RootState, serial: string) => serial,
  (switchPorts, serial) => {
    let derivedSwitchPorts = Object.values(switchPorts?.[serial] || []);
    // DM does not have support for non-integer port ids
    const shouldFilterPortsWithNonIntegerIds = !__MERAKI_GO__;

    if (shouldFilterPortsWithNonIntegerIds) {
      derivedSwitchPorts = derivedSwitchPorts.filter((port) =>
        isNumber(portNumberOfSwitchPort(port)),
      );
    }

    return derivedSwitchPorts.sort((a, b) => portNumberOfSwitchPort(a) - portNumberOfSwitchPort(b));
  },
);

const emptyArray: any = [];
export const switchPortsForSerial = createSelector(
  devicesState,
  sortSwitchPorts,
  (_, serial) => serial,
  (devices, switchPorts, serial) => {
    const device = devices?.[serial];
    if (!switchPorts) {
      return emptyArray;
    }
    return switchPorts.filter((switchPort) => switchPort?.switch_id === device?.id);
  },
);

// TODO: Update this selector for state, props
// and only take switch port id as input.
// https://jira.ikarem.io/browse/DM-880
export const switchPortForSerial = createSelector(
  switchPortsForSerial,
  (_, __, portNum) => portNum,
  (switchPorts, portNum) => switchPorts?.[portNum - 1],
);

export const switchPortTagsForPort = createSelector(
  switchPortById,
  (switchPort) => convertTagsToArray(switchPort?.tags) || [],
);

export const switchPortsForSerialWithIds = createSelector(
  switchPortsForSerial,
  (_, __, ids) => ids,
  (switchPort, ids) => switchPort.filter((port: any) => ids.includes(port.id)),
);

export const filteredAllPortsBySearch = createSelector(
  getSwitchPorts,
  searchTextState,
  (switchPorts: SwitchPortState, searchText) => {
    const ports: SwitchPort[] = Object.values(
      switchPortsToArray(switchPorts),
    ).flat() as SwitchPort[];
    const searches = convertTagsToArray(searchText(SWITCH_PORTS_KEY)).map((search: any) =>
      search.toLowerCase(),
    );
    if (!searches || searches.length === 0) {
      return ports;
    }
    return allPortsMatchingAnySearchWord(ports, searches);
  },
);

export const filteredSwitchPortsBySearch = createSelector(
  switchPortsForSerial,
  searchTextState,
  (ports, searchText) => {
    const searches = convertTagsToArray(searchText(SWITCH_PORTS_KEY)).map((search: any) =>
      search.toLowerCase(),
    );
    if (!searches || searches.length === 0) {
      return ports;
    }
    return allPortsMatchingAnySearchWord(ports, searches);
  },
);

export const searchablePortsDataForDevices = createSelector(
  filteredAllPortsBySearch,
  (_: RootState, props: any) => props,
  (ports, props) => {
    const { getDeviceById, filter } = props;
    const data = filter(ports).reduce((rowInfo: any, switchport: any) => {
      const device = getDeviceById(switchport.switch_id);
      const serialNumber = device.serial;
      const keyName = isValidMacAddress(switchport.switch) ? serialNumber : switchport.switch;
      const portEntry = {
        switchport,
        device,
        serialNumber,
      };
      rowInfo[keyName] = rowInfo[keyName] ? [...rowInfo[keyName], portEntry] : [portEntry];
      return rowInfo;
    }, {});

    return data;
  },
);
