import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import { Button, Loader } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useUpdateWirelessRadioSettings,
  useWirelessRadioSettings,
  WirelessRadioSettings,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { showErrorAlert } from "@meraki/shared/native-alert";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useCallback, useEffect } from "react";

import { ChannelCard } from "../components/wirelessRadio/ChannelCard";
import { ChannelWidthCard } from "../components/wirelessRadio/ChannelWidthCard";
import { TargetPowerCard } from "../components/wirelessRadio/TargetPowerCard";
import { DEFAULT_FIVE_SETTINGS, DEFAULT_TWO_FOUR_SETTINGS } from "../utils/RadioUtils";

export const WirelessRadioSettingsEditScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "WirelessRadioSettingsEdit">>();
  const { params } = route;
  const { radioBand, radioSettings, serial } = params;

  const { mutate, isLoading } = useUpdateWirelessRadioSettings();

  const methods = useForm<WirelessRadioSettings>({
    defaultValues: {
      twoFourGhzSettings: DEFAULT_TWO_FOUR_SETTINGS,
      fiveGhzSettings: DEFAULT_FIVE_SETTINGS,
    },
    values: radioSettings,
  });
  const channel = methods.watch(`${radioBand}.channel`);

  const onSave = useCallback(
    (body: WirelessRadioSettings) => {
      mutate(
        {
          serialNumber: serial,
          body,
        },
        {
          onError(error) {
            showErrorAlert(String(error["errors"]));
          },
          async onSuccess() {
            await queryClient.refetchQueries({
              queryKey: useWirelessRadioSettings.queryKey({ serialNumber: serial }),
            });
            navigation.goBack();
          },
        },
      );
    },
    [mutate, navigation, serial],
  );

  const onCancel = useCallback(() => {
    if (radioBand === "twoFourGhzSettings") {
      methods.resetField("twoFourGhzSettings");
    } else {
      methods.resetField("fiveGhzSettings");
    }
    navigation.goBack();
  }, [methods, radioBand, navigation]);

  useEffect(
    () =>
      navigation.setOptions({
        headerTitle:
          radioBand === "twoFourGhzSettings"
            ? I18n.t("RADIO_SETTINGS.HARDWARE.TWO_GHZ.LABEL")
            : I18n.t("RADIO_SETTINGS.HARDWARE.FIVE_GHZ.LABEL"),
        headerLeft: () => <Button.Nav text={I18n.t("CANCEL")} onPress={onCancel} />,
        headerRight: () => (
          <Button.Nav
            text={I18n.t("SAVE")}
            analytics={
              channel === null
                ? {
                    event: "onPress",
                    eventName: "wireless_radio_settings_edit_save",
                    params: {
                      band: radioBand === "twoFourGhzSettings" ? "2.4" : "5",
                      hasChangedToAutoChannel: channel === null,
                      originalChannel: radioSettings[radioBand]?.channel,
                    },
                  }
                : undefined
            }
            onPress={methods.handleSubmit(onSave)}
          />
        ),
      }),
    [channel, methods, navigation, onCancel, onSave, radioBand, radioSettings],
  );

  return (
    <Screen addDefaultPadding>
      {isLoading && <Loader.Spinner animate />}
      <Form {...methods}>
        <TargetPowerCard band={radioBand} />
        {radioBand === "fiveGhzSettings" && <ChannelWidthCard band={radioBand} />}
        <ChannelCard band={radioBand} />
      </Form>
    </Screen>
  );
};
