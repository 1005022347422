import {
  COMMUNITY_SEARCH_PARENT_SUCCESS,
  COMMUNITY_SEARCH_REQUEST,
  WIPE_REDUX,
} from "@meraki/shared/redux";

import { CommunityData } from "~/shared/types/Models";

interface CommunityReduxState {
  data?: {
    type: string;
    list_item_type: string;
    size: number;
    items: CommunityData[];
  };
}

const initialState = {
  data: {
    type: "",
    list_item_type: "",
    size: 0,
    items: [],
  },
};

export default function community(state: CommunityReduxState = initialState, action: any) {
  switch (action.type) {
    case COMMUNITY_SEARCH_PARENT_SUCCESS:
      return {
        ...state,
        data: action.response?.data,
      };
    case COMMUNITY_SEARCH_REQUEST:
    case WIPE_REDUX:
      return initialState;
    default:
      return state;
  }
}
