import z from "zod";

import { Method } from "./coreTypes";

const needsCSRFList: Method[] = ["POST", "PUT", "DELETE"];

export const requestNeedsCSRF = (method: Method): boolean => needsCSRFList.includes(method);

export const CSRFTokenSchema = z
  .object({
    csrf_token: z.string(),
  })
  .describe("CSRFTokenSchema");

export type CSRFToken = z.infer<typeof CSRFTokenSchema>;
