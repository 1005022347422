import { I18n } from "@meraki/core/i18n";
import { List } from "@meraki/magnetic/components";
import { useOrgNetworks, useSupportCases } from "@meraki/shared/api";
import { useCurrentOrganizationId, useCurrentUserEmail } from "@meraki/shared/redux";

export function SupportCaseSummaryCard() {
  const userEmail = useCurrentUserEmail();
  const organizationId = useCurrentOrganizationId();

  const { data: networks } = useOrgNetworks({ organizationId });

  const { data: openSupportCases = [] } = useSupportCases(
    { encryptedNetworkId: networks?.[0]?.eid, userEmail },
    {
      select(data) {
        return Object.values(data).filter((supportCase) => {
          return (
            supportCase.suppliedEmail === userEmail &&
            supportCase.status !== "Closed" &&
            supportCase.organization.id === organizationId
          );
        });
      },
    },
  );

  return (
    <List.FlashList
      label={I18n.t("MONITOR.OPEN_SUPPORT_CASES")}
      paddingLeft="none"
      paddingTop="none"
      paddingBottom="none"
      paddingRight="none"
      data={openSupportCases}
      getItemData={(supportCase) => {
        return {
          title: supportCase.description,
          description: supportCase.subject,
        };
      }}
      testID="SUPPORT_CASE"
    />
  );
}
