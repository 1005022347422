import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type AuthStore = {
  lastUser: string;
  setLastUser: (lastUser: string) => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      lastUser: "",
      setLastUser: (lastUser) =>
        set((state) => ({
          ...state,
          lastUser,
        })),
    }),
    {
      name: "AuthStore",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);
