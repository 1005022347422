import DataMsg from "../types/DataMsg";

export interface RegistryStatus {
  any_reg_connected: boolean;
  all_reg_connected: boolean;
  private_contact: string;
  nat_type: string;
  contact_registry2: string;
  public_contact: string;
  contact_registry1: string;
}

export interface Message extends DataMsg {
  data: {
    connection_failed?: boolean;
    registry_status: RegistryStatus;
  };
}

export interface Result {
  connectionFailed: boolean;
  status?: RegistryStatus;
}

export function formatMsg(message?: Message): Result | undefined {
  const data = message?.data;

  if (!data) {
    return undefined;
  }

  const { connection_failed, registry_status } = data;
  return { connectionFailed: !!connection_failed, status: registry_status };
}
