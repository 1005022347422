import z from "zod";

import { request } from "../../../api/request/request";
import { createQuery } from "../../createQuery";

export const SSOSchema = z
  .object({
    organization_name: z.string(),
    organization_id: z.string(),
    sp_saml_idp_auth_request: z.object({
      id: z.string(),
      saml_request_url: z.string(),
    }),
  })
  .describe("SSOSchema");
type SSO = z.infer<typeof SSOSchema>;

interface SSOUrlRequest {
  subdomain: string;
}

interface SSOError {
  error: string;
}

function buildUrl({ subdomain }: SSOUrlRequest) {
  return `/login/sso/${subdomain}`;
}

export function fetchSSO(variables: SSOUrlRequest): Promise<SSO> {
  return request(SSOSchema, "GET", buildUrl(variables));
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useSSO = createQuery<SSOUrlRequest, SSO, SSOError>({
  baseQueryKey: buildUrl({ subdomain: "{subdomain}" }),
  queryFn: (variables) => fetchSSO(variables),
  enabled: false,
  retry: false,
});
