import { I18n } from "@meraki/core/i18n";
import { asyncStorageAdapter } from "@meraki/core/secure-storage";
import { HelpStackProps } from "@meraki/go/navigation-type";
import { Button } from "@meraki/magnetic/components";
import { Icon, IconName } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useCreateWish, useOrgNetworks } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useCurrentUserEmail,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { Alert } from "react-native";
import Toast from "react-native-simple-toast";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type PersonaType =
  | "Tech Enthusiast"
  | "Small Business Owner"
  | "Consultant"
  | "Service Provider"
  | "Other";

type Persona = {
  label: string;
  description: string;
  value: string;
  icon: IconName;
};

type WishForm = {
  wish: string;
  category: string;
  persona: PersonaType;
  customPersona: string | undefined;
};

interface PersonaStore {
  persona: {
    [user: string]: {
      [organization: string]: {
        persona: PersonaType;
        customPersona: string | undefined;
      };
    };
  };
  setPersona: (
    user: string,
    organizationId: string,
    persona: PersonaType,
    customPersona: string | undefined,
  ) => void;
}

const usePersona = create<PersonaStore>()(
  persist(
    (set) => ({
      persona: {},
      setPersona: (user, organizationId, persona, customPersona) =>
        set((state) => ({
          persona: {
            ...state.persona,
            [user]: {
              ...(state.persona[user] ?? {}),
              [organizationId]: {
                persona: persona,
                customPersona: customPersona,
              },
            },
          },
        })),
    }),
    {
      name: "localeStorage",
      storage: createJSONStorage(() => asyncStorageAdapter),
    },
  ),
);

export function WishScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<HelpStackProps, "Wish">>();
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId() ?? "";
  const { data: network } = useOrgNetworks(
    { organizationId },
    {
      select(data) {
        return data.find((network) => network.id === networkId) ?? data[0];
      },
    },
  );

  const { persona, setPersona } = usePersona();

  const user = useCurrentUserEmail();

  const personaObj = persona[user]?.[organizationId] ?? {
    persona: "Tech Enthusiast",
    customPersona: undefined,
  };

  const methods = useForm<WishForm>({
    defaultValues: {
      persona: personaObj.persona,
      customPersona: personaObj.customPersona,
    },
  });

  const createWish = useCreateWish();

  const handleCreateWish = methods.handleSubmit(({ wish, category, persona, customPersona }) => {
    let feedback = `"Category: ${category}\nWish: ${wish}\nPersona: ${persona}"`;

    if (category === "Other") {
      feedback += `: ${customPersona}`;
    }

    createWish.mutate(
      {
        encryptedNetworkId: network?.eid,
        body: { page_name: "underdog_app", wish_hash: "Phoenix", wish: feedback },
      },
      {
        onSuccess: () => {
          Toast.showWithGravity(I18n.t("SAVED"), Toast.SHORT, Toast.TOP);
          setPersona(
            user,
            organizationId,
            persona,
            category === "Other" ? customPersona : undefined,
          );
          navigation.goBack();
        },
        onError: () => {
          Alert.alert(I18n.t("ERRORS.SERVER_ERROR_TEXT"));
        },
      },
    );
  });

  const WishOptions = [
    { label: I18n.t("WISH.CATEGORIES.UI"), value: "User interface" },
    { label: I18n.t("WISH.CATEGORIES.NEW_FEATURE"), value: "New feature" },
    { label: I18n.t("WISH.CATEGORIES.IMPROVEMENT"), value: "Improvement" },
    { label: I18n.t("WISH.CATEGORIES.OTHER"), value: "Other" },
  ];

  const personaData: Persona[] = [
    {
      label: I18n.t("WISH.PERSONA.TECH_ENTHUSIAST.TITLE"),
      description: I18n.t("WISH.PERSONA.TECH_ENTHUSIAST.DESCRIPTION"),
      value: "Tech Enthusiast",
      icon: "Wrench",
    },
    {
      label: I18n.t("WISH.PERSONA.SMALL_BUSINESS_OWNER.TITLE"),
      description: I18n.t("WISH.PERSONA.SMALL_BUSINESS_OWNER.DESCRIPTION"),
      value: "Small Business Owner",
      icon: "Buildings",
    },
    {
      label: I18n.t("WISH.PERSONA.CONSULTANT.TITLE"),
      description: I18n.t("WISH.PERSONA.CONSULTANT.DESCRIPTION"),
      value: "Consultant",
      icon: "Laptop",
    },
    {
      label: I18n.t("WISH.PERSONA.SERVICE_PROVIDER.TITLE"),
      description: I18n.t("WISH.PERSONA.SERVICE_PROVIDER.DESCRIPTION"),
      value: "Service Provider",
      icon: "ShareNetwork",
    },
    {
      label: I18n.t("WISH.PERSONA.OTHER.TITLE"),
      description: I18n.t("WISH.PERSONA.OTHER.DESCRIPTION"),
      value: "Other",
      icon: "User",
    },
  ];

  return (
    <Screen addDefaultPadding>
      <Box gap="sm">
        <Form {...methods}>
          <Form.FlashList
            label={I18n.t("WISH.PERSONA.LABEL")}
            name="persona"
            data={personaData}
            getItemData={(persona) => ({
              kind: "radio",
              title: persona.label,
              description: persona.description,
              radioValue: persona.value,
              rightAccessory: <Icon name={persona.icon} />,
              testID: persona.value.toUpperCase(),
              children: persona.value === "Other" && (
                <Form.Input
                  name="customPersona"
                  placeholder={I18n.t("WISH.PERSONA.OTHER.CUSTOM_INPUT")}
                />
              ),
            })}
            rules={{ required: I18n.t("WISH.CATEGORY_EMPTY") }}
            paddingBottom="none"
            paddingTop="none"
            paddingLeft="none"
            paddingRight="none"
          />
          <Form.Input
            rules={{ required: I18n.t("WISH.WISH_EMPTY") }}
            name={"wish"}
            label={I18n.t("WISH.TITLE")}
            multiline
            height={171}
            placeholder={I18n.t("WISH.PLACEHOLDER")}
            testID="WISH_INPUT"
          />
          <Form.PickerCard
            title={I18n.t("WISH.CATEGORY")}
            name={"category"}
            options={WishOptions}
            rules={{ required: I18n.t("WISH.CATEGORY_EMPTY") }}
          />
          <Button text={I18n.t("WISH.SUBMIT_BUTTON")} onPress={handleCreateWish} />
        </Form>
      </Box>
    </Screen>
  );
}
