import { ReactNode } from "react";

import { featureFlagClient } from "./client";
import { BooleanKeys } from "./providers/typeHelpers";
import { FlagMap, FlagProviders } from "./types";
import { useFlag } from "./useFlag";

export type GatedFeatureProps<
  TFlagProvider extends FlagProviders,
  TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
> = {
  children: ReactNode;

  provider: TFlagProvider;
  flag: TFlag;
  watched?: boolean;
};

export function GatedFeature<
  TFlagProvider extends FlagProviders,
  TFlag extends BooleanKeys<FlagMap[TFlagProvider]>,
>({ children, provider, flag, watched }: GatedFeatureProps<TFlagProvider, TFlag>) {
  const { value, isLoading } = useFlag<boolean, TFlagProvider, TFlag>(
    provider,
    flag as string,
    featureFlagClient.getDefaultBool(provider, flag),
    featureFlagClient.getBool,
    watched ?? false,
  );

  if (!value || isLoading) {
    return null;
  }

  // Disabling for now because I can't return `chidlren` or else all use locations are unhappy. Wrapping in a fragment makes those happy but this unhappy.
  // Its just tiring hearing ESLint fight with itself here so we are just silencing it for now.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
