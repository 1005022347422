import { useTheme } from "@meraki/core/theme";
import {
  KeyboardAvoidingView,
  Modal,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { sizeSelect, themeColors } from "~/lib/themeHelper";
import MerakiIcon from "~/shared/components/icons";

type InfoModalProps = {
  children: React.ReactNode;
  onExit?: () => void;
  visible: boolean;
  screenStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

export const InfoModal = (props: InfoModalProps) => {
  const { visible, onExit, screenStyles, children } = props;
  const { theme } = useTheme();
  if (!visible) {
    return null;
  }
  const exitButton = onExit ? (
    <View style={styles.iconContainer}>
      <MerakiIcon
        testID="INFO_MODAL.CANCEL"
        name="close"
        size="s"
        color={MkiColors.secondaryButton}
        // @ts-expect-error TS(2339) FIXME: Property 'exitStyle' does not exist on type '{ con... Remove this comment to see the full error message
        containerStyle={styles.exitStyle}
        onPress={onExit}
      />
    </View>
  ) : null;
  const cardStyle: StyleProp<ViewStyle>[] = onExit
    ? [styles.card]
    : [styles.card, styles.topSpacer];
  const deviceCardStyle = isWeb()
    ? styles.webCard
    : sizeSelect({
        default: styles.phoneCard,
        extraLarge: styles.tabletCard,
      });
  cardStyle.push(deviceCardStyle);
  const backgroundColor = themeColors(theme).modal.background;
  cardStyle.push({ backgroundColor });
  const keyboardAvoidingBehavior = {
    style: styles.keyboardAvoiding,
    enabled: true,
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ ios: string; }' is not assigna... Remove this comment to see the full error message
    behavior: platformSelect({
      ios: "position",
    }),
  };
  return (
    <Modal animationType="fade" transparent visible={visible} onRequestClose={onExit}>
      <TouchableOpacity style={[styles.container, screenStyles]} activeOpacity={1} onPress={onExit}>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <KeyboardAvoidingView {...keyboardAvoidingBehavior}>
          <TouchableWithoutFeedback>
            <View style={cardStyle}>
              {exitButton}
              {children}
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </TouchableOpacity>
    </Modal>
  );
};

InfoModal.defaultProps = {
  onExit: null,
  screenStyles: {},
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MkiColors.modalBackgroundColor,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoiding: {
    alignSelf: "stretch",
  },
  card: {
    paddingTop: SPACING.small,
    paddingBottom: SPACING.default,
    paddingHorizontal: SPACING.default,
    borderRadius: 6,
  },
  tabletCard: {
    width: "60%", // percentage estimated from App Store on iPad
    alignSelf: "center",
  },
  phoneCard: {
    alignSelf: "stretch",
    marginHorizontal: SPACING.default,
  },
  webCard: {
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  iconContainer: {
    alignItems: "flex-end",
  },
  topSpacer: {
    paddingTop: SPACING.extraLarge,
  },
});

export default InfoModal;
