import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  List,
  RefreshControl,
  Text,
  Toggle,
} from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  useApplianceL7FirewallRules,
  useApplianceTrafficShapingRules,
  useConfiguredSsids,
  useDeleteNetwork,
  useDeviceStatuses,
  useL3FirewallRules,
  useNetwork,
  usePoliciesByClient,
  usePortForwardingRules,
  useSsidL7FirewallRules,
  useSwitchQoSRules,
  useVlans,
} from "@meraki/shared/api";
import { useDebuggingTools } from "@meraki/shared/debugging-tools";
import { getProductType } from "@meraki/shared/devices";
import { showDeleteConfirmAlert } from "@meraki/shared/native-alert";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
  USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
} from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useQueries } from "@tanstack/react-query";
import { groupBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const NumberOfRules = ({ numberOfRules }: { numberOfRules: number }) =>
  !numberOfRules || numberOfRules === 0 ? (
    <Text size="p1" color="light">
      {I18n.t("NONE")}
    </Text>
  ) : (
    <Text size="p1" color="interact.text.base">
      {I18n.t(`CONFIGURE.NETWORK.RULE${numberOfRules > 1 ? "S" : ""}`, { numberOfRules })}
    </Text>
  );

export const NetworkConfigureScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps, "Configure">>();

  const dispatch = useDispatch();
  const { enabled: debuggingToolsEnabled, toggle: toggleDebuggingTools } = useDebuggingTools();

  const [isSsidsOpen, setIsSsidsOpen] = useState(false);
  const [isVlansOpen, setIsVlansOpen] = useState(false);

  const organizationId = useCurrentOrganizationId();
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();

  const { data: deviceStatuses = [] } = useDeviceStatuses(
    { organizationId, networkIds: networkId ? [networkId] : [] },
    { enabled: !!networkId },
  );

  const hasAp = deviceStatuses.some((d) => getProductType(d.model) === "wireless");

  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Icon
          icon="Plus"
          color="default.textIn.base"
          onPress={() => bottomSheetRef.current?.present()}
        />
      ),
    });
  }, [navigation]);

  const { data: network } = useNetwork({ networkId });
  const { mutate: deleteNetwork } = useDeleteNetwork();

  const {
    data: ssids,
    isLoading: isSsidsLoading,
    refetch: refetchSsids,
    isRefetching: isSsidsRefetching,
  } = useConfiguredSsids({ networkId });

  const {
    data: vlans,
    isLoading: isVlansLoading,
    refetch: refetchVlans,
    isRefetching: isVlansRefetching,
  } = useVlans({ networkId });

  const {
    data: applianceFirewallRules,
    isLoading: isApplianceFirewallRulesLoading,
    refetch: refetchApplianceFirewallRules,
    isRefetching: isApplianceFirewallRulesRefetching,
  } = useApplianceL7FirewallRules(
    { networkId },
    {
      select: (data) => {
        return data.rules.filter((rule) => rule.type === "host");
      },
    },
  );

  const ssidRulesResults = useQueries({
    queries:
      ssids?.map(({ number: ssidNumber }) =>
        useSsidL7FirewallRules.useQueries({ networkId, ssidNumber: ssidNumber }),
      ) ?? [],
  });
  const ssidFirewallRulesRaw = ssidRulesResults.flatMap(({ data }) => data?.rules);
  const ssidFirewallRules = groupBy(ssidFirewallRulesRaw, "value");

  const isLoadingSsidFirewallRules = ssidRulesResults.some(({ isLoading }) => isLoading);
  const isRefetchingSsidFirewallRules = ssidRulesResults.some(({ isRefetching }) => isRefetching);
  const refetchSsidFirewallRules = () => ssidRulesResults.forEach(({ refetch }) => refetch());

  const numOfWebBlockingRules =
    (applianceFirewallRules?.length ?? 0) + (Object.keys(ssidFirewallRules).length ?? 0);

  const {
    data: clientPolicies,
    isLoading: isClientPoliciesLoading,
    refetch: refetchClientPolicies,
    isRefetching: isClientPoliciesRefetching,
  } = usePoliciesByClient({ networkId, timespan });

  const {
    data: l3FirewallRules,
    isLoading: isL3FirewallRulesLoading,
    refetch: refetchL3FirewallRules,
    isRefetching: isL3FirewallRulesRefetching,
  } = useL3FirewallRules({ networkId });

  const {
    data: applianceTrafficShapingRules,
    isLoading: isApplianceTrafficShapingRulesLoading,
    refetch: refetchApplianceTrafficShapingRules,
    isRefetching: isApplianceTrafficShapingRulesRefetching,
  } = useApplianceTrafficShapingRules({ networkId });

  const {
    data: portForwardingRules,
    isLoading: isPortForwardingRulesLoading,
    refetch: refetchPortForwardingRules,
    isRefetching: isPortForwardingRulesRefetching,
  } = usePortForwardingRules({ networkId });

  const {
    data: switchQoSRules,
    isLoading: isSwitchQoSRulesLoading,
    refetch: refetchSwitchQoSRules,
    isRefetching: isSwitchQoSRulesRefetching,
  } = useSwitchQoSRules({ networkId });

  return (
    <Screen
      refreshControl={
        <RefreshControl
          refreshing={isSsidsRefetching || isVlansRefetching}
          onRefresh={() => {
            refetchSsids();
            refetchVlans();
            refetchApplianceFirewallRules();
            refetchSsidFirewallRules();
            refetchClientPolicies();
            refetchL3FirewallRules();
            refetchApplianceTrafficShapingRules();
            refetchPortForwardingRules();
            refetchSwitchQoSRules();
          }}
        />
      }
      addDefaultPadding
    >
      <List
        label={I18n.t("CONFIGURE.LOCAL.TITLE")}
        loading={isSsidsLoading || isVlansLoading}
        testID="LOCAL_SETTINGS"
      >
        <List.Item
          title={I18n.t("CONFIGURE.LOCAL.SSIDS")}
          description={`${ssids?.length ?? 0} wireless networks`}
          leftAccessory={<Icon name="WifiHigh" />}
          onPress={() => setIsSsidsOpen(!isSsidsOpen)}
          hidePressable
          rightAccessory={
            <Button.Icon
              icon={isSsidsOpen ? "CaretUp" : "CaretDown"}
              onPress={() => setIsSsidsOpen(!isSsidsOpen)}
              testID="LOCAL_SETTINGS.SSID_LIST.CARET"
            />
          }
        >
          {isSsidsOpen && (
            <List.FlashList
              data={ssids}
              getItemData={(ssid) => {
                return {
                  title: ssid.name,
                  description: ssid.encryptionMode,
                  onPress: () => navigation.navigate("Ssid", { ssidNumber: ssid.number }),
                };
              }}
              testID="LOCAL_SETTINGS.SSID_LIST"
            />
          )}
        </List.Item>
        <List.Item
          title={I18n.t("CONFIGURE.LOCAL.VLANS")}
          description={`${vlans?.length ?? 0} VLANs`}
          leftAccessory={<Icon name="TreeStructure" />}
          onPress={() => setIsVlansOpen(!isVlansOpen)}
          hidePressable
          rightAccessory={
            <Button.Icon
              icon={isVlansOpen ? "CaretUp" : "CaretDown"}
              onPress={() => setIsVlansOpen(!isVlansOpen)}
              testID="LOCAL_SETTINGS.VLAN_LIST.CARET"
            />
          }
        >
          {isVlansOpen && (
            <List.FlashList
              data={vlans}
              emptyState={{ title: I18n.t("CLIENT_USAGE.EMPTY") }}
              getItemData={(vlan) => {
                return {
                  title: vlan.name,
                  description: vlan.subnet,
                  onPress: () => navigation.navigate("Vlan", { vlanId: vlan.id }),
                };
              }}
              testID="LOCAL_SETTINGS.VLAN_LIST"
            />
          )}
        </List.Item>
      </List>
      <List label="Internal Alpha Features">
        <List.Item
          title="Magnetic Theme"
          rightAccessory={
            <Toggle
              checked={true}
              onValueChange={(magneticTheme: boolean) => {
                dispatch({
                  type: USER_TOGGLED_MAGNETIC_ALPHA_FEATURE,
                  payload: { magneticTheme },
                });
              }}
              testID="MAGNETIC_THEME_TOGGLE"
            />
          }
        />
        <List.Item
          title="Debugging Tools"
          rightAccessory={
            <Toggle checked={debuggingToolsEnabled} onValueChange={toggleDebuggingTools} />
          }
        />
      </List>
      <Box testID="NETWORK_WIDE_SETTINGS" gap="sm">
        <List
          label={I18n.t("CONFIGURE.NETWORK.TITLE")}
          loading={
            isApplianceFirewallRulesLoading ||
            isLoadingSsidFirewallRules ||
            isClientPoliciesLoading ||
            isApplianceFirewallRulesRefetching ||
            isRefetchingSsidFirewallRules ||
            isClientPoliciesRefetching
          }
        >
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.WEB_BLOCKING")}
            leftAccessory={<Icon name="Prohibit" />}
            rightAccessory={<NumberOfRules numberOfRules={numOfWebBlockingRules} />}
            onPress={() => navigation.navigate("WebBlocking", {})}
            testID="NETWORK_WIDE_SETTINGS.WEB_BLOCKING"
          />
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.CLIENT_POLICY")}
            leftAccessory={<Icon name="EyeSlash" />}
            rightAccessory={
              !clientPolicies || clientPolicies.length === 0 ? (
                <Text size="p1" color="light">
                  {I18n.t("NONE")}
                </Text>
              ) : (
                <Text size="p1" color="interact.text.base">
                  {I18n.t(
                    `CONFIGURE.NETWORK.${clientPolicies.length > 1 ? "POLICIES" : "POLICY"}`,
                    {
                      numberOfPolicies:
                        clientPolicies.reduce(
                          (result, data) => result + (data?.assigned?.length ?? 0),
                          0,
                        ) ?? 0,
                    },
                  )}
                </Text>
              )
            }
            onPress={() => navigation.navigate("LimitedBlockedClients", {})}
            testID="NETWORK_WIDE_SETTINGS.CLIENT_POLICY"
          />
        </List>
        <List
          loading={
            isL3FirewallRulesLoading ||
            isApplianceTrafficShapingRulesLoading ||
            isPortForwardingRulesLoading ||
            isSwitchQoSRulesLoading ||
            isL3FirewallRulesRefetching ||
            isApplianceTrafficShapingRulesRefetching ||
            isPortForwardingRulesRefetching ||
            isSwitchQoSRulesRefetching
          }
        >
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.L3_FIREWALL_RULES")}
            leftAccessory={<Icon name="Lock" />}
            rightAccessory={<NumberOfRules numberOfRules={l3FirewallRules?.rules?.length ?? 0} />}
            onPress={() => navigation.navigate("L3FirewallRulesList")}
            testID="NETWORK_WIDE_SETTINGS.L3_FIREWALL_RULES"
          />
          <List.Item
            title={I18n.t("NETWORK_CONFIGURE.NETWORK.TRAFFIC_SHAPING")}
            leftAccessory={<Icon name="ArrowsLeftRight" />}
            rightAccessory={
              applianceTrafficShapingRules?.defaultRulesEnabled ? (
                <Text size="p1" color="interact.text.base">
                  {I18n.t("DEFAULT")}
                </Text>
              ) : (
                <NumberOfRules numberOfRules={applianceTrafficShapingRules?.rules?.length ?? 0} />
              )
            }
            testID="NETWORK_WIDE_SETTINGS.TRAFFIC_SHAPPING"
            onPress={() =>
              navigation.navigate("TrafficShapingRulesList", {
                applianceOrSSID: "appliance",
              })
            }
          />
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.PORT_FORWARDING")}
            leftAccessory={<Icon name="ArrowSquareIn" />}
            rightAccessory={
              <NumberOfRules numberOfRules={portForwardingRules?.rules?.length ?? 0} />
            }
            onPress={() => navigation.navigate("PortForwardingRulesList")}
            testID="NETWORK_WIDE_SETTINGS.PORT_FORWARDING"
          />
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.QUALITY_OF_SERVICE")}
            leftAccessory={<Icon name="ArrowsDownUp" />}
            rightAccessory={<NumberOfRules numberOfRules={switchQoSRules?.length ?? 0} />}
            onPress={() => navigation.navigate("SwitchQoSRulesList")}
            testID="NETWORK_WIDE_SETTINGS.QUALITY_OF_SERVICE"
          />
        </List>
        <List>
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.TRAFFIC_ANALYSIS")}
            leftAccessory={<Icon name="MagnifyingGlass" />}
            testID="NETWORK_WIDE_SETTINGS.TRAFFIC_ANALYSIS"
          />
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.WIRELESS_SETTINGS")}
            leftAccessory={<Icon name="Gear" />}
            testID="NETWORK_WIDE_SETTINGS.WIRELESS_SETTINGS"
            onPress={() => navigation.navigate("WirelessSettings")}
          />
          {hasAp && (
            <List.Item
              title={I18n.t("CONFIGURE.NETWORK.AP_PLACEMENT")}
              leftAccessory={<Icon name="Broadcast" />}
              testID="NETWORK_WIDE_SETTINGS.AP_PLACEMENT"
              analytics={{
                event: "onPress",
                eventName: "network_configure_ap_placement_row",
              }}
              onPress={() => navigation.navigate("ApPlacementIntro")}
            />
          )}
          <List.Item
            title={I18n.t("CONFIGURE.NETWORK.SITE_TO_SITE_VPN_SETTINGS")}
            testID="SITE_TO_SITE_VPN_SETTINGS"
            onPress={() => navigation.navigate("SiteToSiteVPN")}
            leftAccessory={<Icon name="TreeStructure" />}
          />
          <List.Item
            title={I18n.t("CLIENT_VPN.TITLE")}
            testID="CLIENT_VPN"
            onPress={() => navigation.navigate("ClientVPN")}
            leftAccessory={<Icon name="User" />}
          />
          <List.Item
            title={I18n.t("SETTINGS.ADMIN.TITLE")}
            testID="ADMINS_SETTINGS"
            onPress={() => navigation.navigate("Administrators")}
            leftAccessory={<Icon name="User" />}
          />
        </List>
        <Button
          testID="NETWORK_WIDE_SETTINGS.DELETE"
          text={I18n.t("CONFIGURE.DELETE.BUTTON")}
          kind="secondaryDestructive"
          onPress={() =>
            showDeleteConfirmAlert(
              I18n.t("CONFIGURE.DELETE.TITLE", { networkName: network?.name }),
              I18n.t("CONFIGURE.DELETE.BODY"),
              () => deleteNetwork({ networkId }),
            )
          }
        />
        <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
          <BottomSheet.Header
            title={I18n.t("CONFIGURE.CREATE.ACTION_SHEET_TITLE")}
            onCancelPress={() => bottomSheetRef.current?.dismiss()}
          />
          <BottomSheet.Content>
            <Box gap="xs" paddingBottom="xs">
              <Button
                kind="tertiary"
                text={I18n.t("CONFIGURE.CREATE.SSID.TITLE")}
                onPress={() => {
                  bottomSheetRef.current?.close();
                  navigation.navigate("AddSsid");
                }}
              />
              <Button
                kind="tertiary"
                text={I18n.t("CONFIGURE.CREATE.VLAN.TITLE")}
                onPress={() => {
                  bottomSheetRef.current?.close();
                  navigation.navigate("AddVlan");
                }}
              />
            </Box>
          </BottomSheet.Content>
        </BottomSheet.Modal>
      </Box>
    </Screen>
  );
};
