import { PureComponent } from "react";
import { StyleSheet, TextProps, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import HiddenPWText from "~/shared/components/HiddenPWText";
import MerakiIcon from "~/shared/components/icons";
import ShowHidePasswordButton from "~/shared/components/ShowHidePasswordButton";

interface WiFiShowHidePWTextState {
  showPassword: boolean;
}

export interface WiFiShowHidePWTextProps extends TextProps {
  psk: string;
}

export default class WiFiShowHidePWText extends PureComponent<
  WiFiShowHidePWTextProps,
  WiFiShowHidePWTextState
> {
  constructor(props: WiFiShowHidePWTextProps) {
    super(props);
    this.state = { showPassword: false };
  }

  renderLockIcon = (
    <MerakiIcon
      name="lock"
      size="s"
      color={MkiColors.secondaryTextColor}
      containerStyle={styles.lockIcon}
      testID="SSID_DETAILS.PSK_LOCK"
    />
  );

  renderPasswordText = () => {
    const { psk } = this.props;
    const { showPassword } = this.state;
    return <HiddenPWText psk={psk} showPassword={showPassword} screenStyle={styles.passwordText} />;
  };

  onPressToggleShowPW = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  renderToggleButton = (<ShowHidePasswordButton onPress={this.onPressToggleShowPW} />);

  render() {
    return (
      <View style={styles.showHidePWRow}>
        {this.renderLockIcon}
        {this.renderPasswordText()}
        {this.renderToggleButton}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  showHidePWRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  lockIcon: {
    marginRight: 10,
  },
  passwordText: {
    flexShrink: 1,
  },
});
