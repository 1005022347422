import isLokiRunning from "@loki/is-loki-running";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import { useEffect, useRef } from "react";
import { Animated, StyleProp, ViewStyle } from "react-native";

export type SkeletonLoaderProps = {
  height?: "small" | "medium" | "large" | number;
  width?: `${number}%` | number;
  color?: "default" | "strong";
  animate?: boolean;
};

const HEIGHT_MAP = {
  small: 16,
  medium: 24,
  large: 36,
} as const;

function getStyle(
  { height = "small", width = "100%", color }: SkeletonLoaderProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  return {
    height: typeof height === "string" ? HEIGHT_MAP[height] : height,
    width: width,
    backgroundColor:
      color === "strong" ? theme.color.default.border.medium.base : theme.color.default.bg.base,
    borderRadius: theme.SizeContainBorderRadius,
  };
}

function useShimmerAnimation({ animate = true }: SkeletonLoaderProps) {
  const opacity = useRef(new Animated.Value(0.2)).current;

  useEffect(() => {
    if (!animate || isLokiRunning()) return;

    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 0.9,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 0.2,
          duration: 800,
          useNativeDriver: true,
        }),
      ]),
    ).start();
  }, [opacity, animate]);

  return opacity;
}

export function SkeletonLoader(props: SkeletonLoaderProps) {
  const theme = useMagneticTheme();
  const opacity = useShimmerAnimation(props);

  return (
    <Animated.View
      accessible
      accessibilityState={{ busy: true }}
      style={[getStyle(props, theme), { opacity }]}
    />
  );
}
