export enum SplashMethods {
  none = "None",
  clickThrough = "Click-through splash page",
  billing = "Billing",
  radiusMeraki = "Password-protected with Meraki RADIUS",
  radiusCustom = "Password-protected with custom RADIUS",
  activeDirectory = "Password-protected with Active Directory",
  ldap = "Password-protected with LDAP",
  sms = "SMS authentication",
  pcc = "Systems Manager Sentry",
  facebook = "Facebook Wi-Fi",
  oauth = "Google OAuth",
  sponsor = "Sponsored guest",
  ciscoISE = "Cisco ISE",
}

interface SplashImage {
  md5?: string;
  extension?: string;
  image?: {
    contents: string;
    format: string;
  };
}

export interface SplashSettings {
  splashMethod: SplashMethods;
  splashUrl: string;
  useSplashUrl: boolean;
  splashTimeout: number;
  redirectUrl: string;
  useRedirectUrl: boolean;
  welcomeMessage: string;
  splashLogo: SplashImage;
  splashImage: SplashImage;
  splashPrepaidFront: SplashImage;
}
