import { formatDate } from "@meraki/core/date";
import { isEmpty, isNil } from "lodash";
import { StyleSheet } from "react-native";

import { CLIENT, SPACING } from "~/constants/MkiConstants";
import ClientSignalStrength from "~/enterprise/screens/client/ClientSignalStrength";
import I18n from "~/i18n/i18n";
import { getConnectedNum } from "~/lib/formatHelper";
import { appSelect } from "~/lib/PlatformUtils";
import SummaryList from "~/shared/components/SummaryList";
import TwoColumnRow from "~/shared/rows/TwoColumnRow";
import { NetworkClientType, WirelessBand } from "~/shared/types/Client";
import DeviceInterface from "~/shared/types/Device";

interface ClientConnectionCardProps {
  client: NetworkClientType;
  ssidsByNumber: object;
  device: DeviceInterface;
  loading: boolean;
  isOnline: boolean;
  associatedAt: number;
  associatedBand?: number;
}

const renderRow = (rowData: any) => <TwoColumnRow title={rowData.label} value={rowData.value} />;

const ClientConnectionCard = (props: ClientConnectionCardProps) => {
  const { client, ssidsByNumber, device, isOnline, associatedAt, associatedBand, loading } = props;
  let heading;
  let deviceLabel;
  const deviceName = !isEmpty(device)
    ? device.name || device.serial || I18n.t("UNKNOWN")
    : I18n.t("DELETED_HARDWARE");
  let connectionType;
  let connectedBy;
  const lastSeen = client?.lastSeen
    ? new Date(client?.lastSeen)[Symbol.toPrimitive]?.("number") ?? client?.lastSeen
    : 0;

  const connectionTimestamp = isOnline ? associatedAt * 1000 : lastSeen;
  const timestampString =
    lastSeen === 0
      ? I18n.t("CLIENT_DETAILS.LONG_TIME_AGO")
      : formatDate(connectionTimestamp, {
          dateFormat: "longDate",
          timeFormat: "shortTime",
          unixTimestamp: false,
        });

  if (client.wireless && !isNil(client.connectedBy)) {
    heading = I18n.t("CLIENT_DETAILS.CONNECTION.WIRELESS.HEADING");
    deviceLabel = I18n.t("CLIENT_DETAILS.CONNECTION.WIRELESS.DEVICE_LABEL");
    connectionType = I18n.t("CLIENT_DETAILS.CONNECTION.WIRELESS.CONNECTION_TYPE");
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (ssidsByNumber[client.connectedBy]) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      connectedBy = ssidsByNumber[client.connectedBy].name;
    }
  } else if (client.switch) {
    heading = I18n.t("CLIENT_DETAILS.CONNECTION.SWITCH.HEADING");
    deviceLabel = I18n.t("CLIENT_DETAILS.CONNECTION.SWITCH.DEVICE_LABEL");
    connectionType = I18n.t("CLIENT_DETAILS.CONNECTION.SWITCH.CONNECTION_TYPE");
    connectedBy = getConnectedNum(client.connectedBy);
  } else if (client.mxWireless) {
    heading = I18n.t("CLIENT_DETAILS.CONNECTION.MX.HEADING_WIRELESS");
    deviceLabel = I18n.t("CLIENT_DETAILS.CONNECTION.MX.DEVICE_LABEL");
    connectionType = I18n.t("CLIENT_DETAILS.CONNECTION.MX.CONNECTION_TYPE_WIRELESS");
    connectedBy = client.ssid;
  } else {
    heading = I18n.t("CLIENT_DETAILS.CONNECTION.MX.HEADING_WIRED");
    deviceLabel = I18n.t("CLIENT_DETAILS.CONNECTION.MX.DEVICE_LABEL");
    connectionType = I18n.t("CLIENT_DETAILS.CONNECTION.MX.CONNECTION_TYPE_WIRED");
    connectedBy = getConnectedNum(client.connectedBy);
  }

  const label = isOnline
    ? I18n.t("CLIENT_DETAILS.CONNECTED_SINCE")
    : I18n.t("CLIENT_DETAILS.LAST_SEEN");

  const content = [
    { label: deviceLabel, value: deviceName },
    { label: connectionType, value: connectedBy },
    { label, value: timestampString },
  ];

  if (client.wireless) {
    if (client.band != null || associatedBand != null) {
      // associatedBand here is a number being compared to a string below, types need to be fixed here
      const bandValue = client.band ?? associatedBand;
      const bandKey =
        bandValue === WirelessBand.two || bandValue === WirelessBand.twoFour ? "TWO_FOUR" : "FIVE";

      content.unshift({
        label: I18n.t("CLIENT_DETAILS.RADIO_BAND.LABEL"),
        value: I18n.t(`CLIENT_DETAILS.RADIO_BAND.${bandKey}`),
      });
    }

    content.unshift({
      label: I18n.t("CLIENT_DETAILS.SIGNAL_STRENGTH"),
      value: <ClientSignalStrength clientMac={client.mac} networkId={client.networkId} />,
    });
  }

  const subheading = appSelect({
    enterprise: undefined,
    go: I18n.t("CLIENT_DETAILS.CONNECTION.SUBTITLE", { client_word: I18n.t(CLIENT) }),
  });

  const headingContainerStyles = appSelect({ enterprise: {}, go: styles.goHeading });
  const containerStyles = appSelect({ enterprise: {}, go: styles.goContainer });

  return (
    <SummaryList
      heading={heading}
      subheading={subheading}
      contentRows={content}
      customRenderRow={renderRow}
      headingContainerStyles={headingContainerStyles}
      containerStyles={containerStyles}
      loading={loading}
      disableBottomBorder
    />
  );
};

ClientConnectionCard.defaultProps = {
  ssidsByNumber: {},
  device: undefined,
  loading: false,
  isOnline: false,
  associatedAt: undefined,
  timezone: undefined,
};

const styles = StyleSheet.create({
  goHeading: {
    marginLeft: 0,
  },
  goContainer: {
    marginHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
    marginBottom: SPACING.small,
  },
});

export default ClientConnectionCard;
