import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps } from "@meraki/go/navigation-type";
import { Button, List, Notification, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { APIResponseError, useCreateAdmin } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { useCurrentOrganizationId } from "@meraki/shared/redux";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useCallback, useEffect, useState } from "react";

type AddAdminForm = {
  name: string;
  email: string;
  isReadOnly: boolean;
};

export const AddAdministratorScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<ConfigureStackProps, "AddAdministrator">>();
  const createAdmin = useCreateAdmin();
  const organizationId = useCurrentOrganizationId();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const addAdmin = useCallback(
    ({ email, name, isReadOnly }: AddAdminForm) => {
      createAdmin.mutate(
        {
          organizationId,
          params: {
            name,
            email,
            orgAccess: isReadOnly ? "read-only" : "full",
          },
        },
        {
          onSuccess: () => {
            navigation.dispatch(
              CommonActions.navigate({
                name: "Administrators",
                params: {
                  passedSuccessMessage: I18n.t("ADMIN.ADD.INVITED", { name: name }),
                },
                merge: true,
              }),
            );
          },
          onError: (error: APIResponseError) => {
            setErrorMessage(error.errors?.[0] ?? I18n.t("SERVER_ERROR_TEXT"));
          },
        },
      );
    },
    [createAdmin, navigation, organizationId],
  );

  const addMethods = useForm<AddAdminForm>({
    defaultValues: { name: "", email: "", isReadOnly: false },
  });

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button.Nav text={I18n.t("SAVE")} onPress={addMethods.handleSubmit(addAdmin)} />
      ),
    });
  }, [addAdmin, addMethods, navigation]);

  return (
    <Screen addDefaultPadding>
      {errorMessage && (
        <Notification.Inline
          status="negative"
          message={errorMessage}
          onDismiss={() => setErrorMessage(undefined)}
        />
      )}
      <Form {...addMethods}>
        <Form.Input
          name="name"
          disabled={createAdmin.isLoading}
          label={I18n.t("ADMIN.ADD.NAME")}
          placeholder={I18n.t("ADMIN.ADD.NAME_PLACEHOLDER")}
          testID="NAME_INPUT"
        />
        <Form.Input
          name="email"
          disabled={createAdmin.isLoading}
          label={I18n.t("ADMIN.ADD.EMAIL")}
          placeholder={I18n.t("ADMIN.ADD.EMAIL_PLACEHOLDER")}
          testID={"EMAIL_INPUT"}
        />
        <List.Item
          leftAccessory={<Form.Checkbox name="isReadOnly" testID="READ_ONLY.CHECKBOX" />}
          title={I18n.t("ADMIN.ADD.READ_ONLY.TITLE")}
          description={I18n.t("ADMIN.ADD.READ_ONLY.DESCRIPTION")}
          bottomDividerBorder={false}
          testID={"READ_ONLY.ROW"}
        />
      </Form>

      <Text size="p1" testID="DISCLAIMER">
        {I18n.t("ADMIN.ADD.DISCLAIMER")}
      </Text>
    </Screen>
  );
};
