import { I18n } from "@meraki/core/i18n";
import { StackType } from "@meraki/shared/navigation-type";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import LimitApplicationUsageList from "~/go/screens/LimitApplicationUsageListScreen";
import LimitApplicationUsage from "~/go/screens/LimitApplicationUsageScreen";
import LimitClientUsageScreen from "~/go/screens/LimitClientUsageScreen";
import LimitSSIDUsageScreen from "~/go/screens/LimitSSIDUsageScreen";
import SetUsageScreen from "~/go/screens/SetUsageScreen";

import { UsageScreensPropMap } from "./Types";

const ForwardedLimitClientUsageScreen =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<UsageScreensPropMap, "LimitClientUsage">
  >()(LimitClientUsageScreen);
const ForwardedLimitSSIDUsageScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<UsageScreensPropMap, "LimitSSIDUsage">>()(
    LimitSSIDUsageScreen,
  );
const ForwardedLimitApplicationUsageList =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<UsageScreensPropMap, "LimitApplicationUsageList">
  >()(LimitApplicationUsageList);
const ForwardedLimitApplicationUsage =
  withForwardedNavigationParams<
    ForwardedStackScreenProps<UsageScreensPropMap, "LimitApplicationUsage">
  >()(LimitApplicationUsage);
const ForwardedSetUsageScreen =
  withForwardedNavigationParams<ForwardedStackScreenProps<UsageScreensPropMap, "SetUsage">>()(
    SetUsageScreen,
  );

export function usageScreens<T extends UsageScreensPropMap>(Stack: StackType<T>) {
  return (
    <>
      <Stack.Screen
        name="SetUsage"
        component={ForwardedSetUsageScreen}
        options={{ headerTitle: I18n.t("SETTINGS.USAGE.LIMITS.TITLE") }}
      />
      <Stack.Screen
        name="LimitClientUsage"
        component={ForwardedLimitClientUsageScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SET_USAGE.TITLE") }}
      />
      <Stack.Screen
        name="LimitSSIDUsage"
        component={ForwardedLimitSSIDUsageScreen}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SET_USAGE.TITLE") }}
      />
      <Stack.Screen
        name="LimitApplicationUsageList"
        component={ForwardedLimitApplicationUsageList}
        options={{
          presentation: "fullScreenModal",
          headerTitle: I18n.t("SET_USAGE.SELECT_APPLICATION_ACTION"),
        }}
      />
      <Stack.Screen
        name="LimitApplicationUsage"
        component={ForwardedLimitApplicationUsage}
        options={{ presentation: "fullScreenModal", headerTitle: I18n.t("SET_USAGE.TITLE") }}
      />
    </>
  );
}
