import { ColorToken, getColorFromTokenName, useMagneticTheme } from "@meraki/magnetic/themes";
import {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  JSXElementConstructor,
  ReactElement,
} from "react";
import { Text as RNText, TextProps as RNTextProps, TextStyle } from "react-native";

const COLOR_MAP = {
  regular: "default.text.base",
  light: "default.text.medium.base",
} as const;

const SIZE_MAP = {
  p1: "Sm",
  p2: "Xs",
  p3: "2xs",
  p4: "3xs",
} as const;

const WEIGHT_MAP = {
  regular: "",
  semiBold: "Medium",
  bold: "Strong",
} as const;

export type TextSize = keyof typeof SIZE_MAP;
export type TextWeight = keyof typeof WEIGHT_MAP;

interface InternalProps {
  size?: TextSize;
  weight?: TextWeight;
  color?: keyof typeof COLOR_MAP | ColorToken;
  textAlign?: TextStyle["textAlign"];
  monospace?: boolean;
  noDefaultProps?: boolean;
}

type RootChildType = ReactElement<
  InternalChildrenProps,
  string | JSXElementConstructor<InternalChildrenProps>
>;
interface InternalChildrenProps extends InternalProps {
  children:
    | RootChildType
    | RootChildType[]
    | string
    | string[]
    | (RootChildType | string)[]
    | number
    | null
    | undefined;
}

export interface TextProps
  extends Partial<InternalChildrenProps>,
    Omit<
      RNTextProps,
      | "style"
      | "onPress"
      | "onPressIn"
      | "onPressOut"
      | "onLongPress"
      | "suppressHighlighting"
      | "children"
    > {}

function useStyles({ size, weight, color, monospace, textAlign }: InternalProps): TextStyle {
  const theme = useMagneticTheme();

  const styles: TextStyle = {};

  if (typeof monospace === "boolean") {
    styles.fontFamily = !monospace ? "Inter" : "Roboto Mono";
  }

  if (color) {
    const translatedColor = color === "regular" || color === "light" ? COLOR_MAP[color] : color;
    styles.color = getColorFromTokenName(translatedColor, theme);
  }

  if (weight) {
    styles.fontWeight = theme[
      `SizeFontWeight${WEIGHT_MAP[weight]}`
    ].toString() as TextStyle["fontWeight"];
  }

  if (size) {
    styles.fontSize = theme[`SizeFont${SIZE_MAP[size]}`];
    styles.lineHeight = theme[`SizeFontHeightLine${SIZE_MAP[size]}`];
  }

  if (textAlign) {
    styles.textAlign = textAlign;
  }

  return styles;
}

function getDefaultedProps({
  size,
  weight,
  color,
  textAlign,
  monospace,
  noDefaultProps,
}: InternalProps): InternalProps {
  if (noDefaultProps) {
    return { size, weight, color, monospace };
  }

  return {
    size: size ?? "p3",
    weight: weight ?? "regular",
    color: color ?? "regular",
    textAlign,
    monospace: monospace ?? false,
  };
}

// This function exists because as with any good set of rules... you sometimes have to break one or two.
// The purpose of the component is to allow InlineNotificationWithHtml and Notifications with onPress to inject
// a regular react-native Text component into this Text component. By React Native rules thats fine since nested Text
// components is A-OK. The problem is that we need to wrap the RN Text component in a wrapper to pass press handlers and such
// which means when we get to our runtime "DON'T PASS ANYTHING EXCEPT OTHER INSTANCES OF MYSELF TO ME" check... we are not looking at a Text
// or even a RN Text component. So we need to force our way to success here.
// *TO ANYONE READING THIS THINKING THEY SHOULD ADD THIS PROPERTY TO A COMPONENT. STOP. THINK ABOUT WHAT YOU'RE DOING. FIND ANOTHER WAY TO DO IT. IF THAT FAILS THEN TALK TO MOBILE PLATFORMS ABOUT EXPANDING THE USECASE HERE*
function hasMagneticTextWorkaroundMagic(child: unknown) {
  return Object.prototype.hasOwnProperty.call(child, "magneticTextWorkaroundMagic");
}

export function Text({
  size,
  weight,
  color,
  textAlign,
  monospace,
  noDefaultProps,
  children,
  ...rest
}: TextProps) {
  const updatedProps = getDefaultedProps({
    size,
    weight,
    color,
    textAlign,
    monospace,
    noDefaultProps,
  });
  const themedStyles = useStyles(updatedProps);

  // We are forcing out everything except actual children that we need to render so we need to let TS know that
  // fact as it is unable to implicitly figure that out in this case
  const childrenArray = Children.toArray(children) as RootChildType[];
  const styledChildren = Children.map(childrenArray, (child) => {
    if (!isValidElement(child)) {
      return child;
    }

    if (child.type === Fragment) {
      return <Text children={child.props.children ?? null} noDefaultProps />;
    }

    if (__DEV__ && child.type !== Text && !hasMagneticTextWorkaroundMagic(child.type)) {
      throw new Error("You should only pass in a string or Text components into a Text");
    }

    return cloneElement(child, { noDefaultProps: true });
  });

  return (
    <RNText {...rest} style={themedStyles}>
      {styledChildren}
    </RNText>
  );
}
