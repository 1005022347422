import { I18n } from "@meraki/core/i18n";
import { Card, Text } from "@meraki/magnetic/components";
import { Status } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";
import { useNetworkHealth, useOrganization, useOrgNetwork } from "@meraki/shared/api";
import { SingleBarGraph } from "@meraki/shared/components";
import {
  useCurrentNetworkId,
  useCurrentOrganizationId,
  useGlobalTimespan,
} from "@meraki/shared/redux";
import { useState } from "react";

const t = (path: string, options?: object) => I18n.t(path, { ...options });

export const getTimespans = (ts: number) => {
  const t1 = new Date().getTime();
  const t0 = t1 - ts * 1000;
  return { t0: t0 / 1000, t1: t1 / 1000 };
};

const getGraphColor = (value: number): ColorToken | undefined => {
  switch (value) {
    case 0:
      return "dormant.bg.base";
    case 1:
      return "negative.bg.base";
    case 2:
      return "severeWarning.bg.base";
    case 3:
      return "warning.bg.base";
    case 4:
      return "positive.bg.base";
    default:
      return undefined;
  }
};

export function NetworkUptime() {
  const organizationId = useCurrentOrganizationId();
  const { data: orgEid, isLoading: isOrgLoading } = useOrganization(
    { organizationId },
    {
      select: (org) => org.eid,
    },
  );
  const networkId = useCurrentNetworkId();
  const timespan = useGlobalTimespan();
  const [{ t0, t1 }] = useState(getTimespans(timespan));
  const { data: network, isLoading: isOrgNetworksLoading } = useOrgNetwork({
    organizationId,
    networkId,
  });

  const nodeGroupIds = Object.values(network?.ngIds ?? {}).map((node) => node.ngId) ?? [];

  const { data: uptimeInformation, isLoading: isNetworkHealthLoading } = useNetworkHealth(
    { encryptedOrgId: orgEid, ids: nodeGroupIds ?? [], t0, t1 },
    {
      enabled: !!orgEid,
      select: (data) => {
        const localeUptime = data.locale[networkId?.split("_")[1] ?? ""] ?? [];
        let minTime = localeUptime?.[0]?.[0] ?? 0;
        let maxTime = localeUptime?.[0]?.[0] ?? 0;
        localeUptime.map((uptime) => {
          const currentTime = uptime[0];
          minTime = Math.min(minTime, currentTime);
          maxTime = Math.max(maxTime, currentTime);
          return undefined;
        });
        const minDate = new Date(minTime);
        const midDate = new Date(maxTime - (maxTime - minTime) / 2);
        const maxDate = new Date(maxTime);

        if (localeUptime.length <= 1) {
          return {
            data: [],
            startDate: minDate ?? null,
            endDate: maxDate ?? null,
            percentPositive: 0,
          };
        }

        const totalTime = maxTime - minTime;
        const graphValues = [];
        let onlineTime = 0;
        for (let i = 0; i < localeUptime.length - 1; i++) {
          const uptime = localeUptime[i];
          if (!uptime) {
            continue;
          }

          const currentTime = uptime[0];
          const currentValue = uptime[1];

          const nextTime = localeUptime[i + 1]?.[0] ?? 0;
          const percentage = (nextTime - currentTime) / totalTime;
          if (currentValue === 4) {
            onlineTime += nextTime - currentTime;
          }
          graphValues.push({
            percentage,
            color: getGraphColor(currentValue),
          });
        }
        return {
          data: graphValues,
          startDate: minDate,
          middleDate: midDate,
          endDate: maxDate,
          percentPositive: onlineTime / totalTime,
        };
      },
    },
  );

  const data = uptimeInformation?.data ?? [];
  const percentPositive = uptimeInformation?.percentPositive ?? 0;

  const startDate =
    uptimeInformation?.startDate?.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    }) ?? "";
  const middleDate =
    uptimeInformation?.middleDate?.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    }) ?? "";
  const endDate =
    uptimeInformation?.endDate?.toLocaleDateString(undefined, { month: "short", day: "numeric" }) ??
    "";

  return (
    <Card
      loading={isNetworkHealthLoading || isOrgLoading || isOrgNetworksLoading}
      loadingContentHeight={60}
      testID="NETWORK_UPTIME_CARD"
    >
      <Card.Header title={t("NETWORK_WORD")} />
      <Card.Well flex={1} flexDirection="row" marginBottom="sm">
        <Text size="p1" weight="bold">
          {t("NETWORK_UPTIME.UPTIME_PERCENTAGE", {
            percentage_value: `${(percentPositive * 100).toFixed(1)}%`,
          })}
        </Text>
        <Status status="positive" />
      </Card.Well>

      <SingleBarGraph data={data} />
      <Box flex={1} flexDirection={"row"} justifyContent="space-between">
        <Text size="p4">{startDate}</Text>
        <Text size="p4">{middleDate}</Text>
        <Text size="p4">{endDate}</Text>
      </Box>
    </Card>
  );
}
