import { I18n } from "@meraki/core/i18n";
import { L3FirewallRule, useL3FirewallRules, useUpdateL3FirewallRules } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import DefaultHeader from "~/go/components/DefaultHeader";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import { removeElementAtIndex } from "~/lib/arrayHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import { CloseButton, DoneButton, EditButton } from "~/shared/navigation/Buttons";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

import { HardwareStackPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "L3FirewallRule">;

const filterDefaultRules = (rules: any) =>
  rules.filter(
    (rule: any) =>
      !(
        rule.comment === "Default rule" &&
        rule.policy.toLowerCase() === "allow" &&
        rule.protocol.toLowerCase() === "any" &&
        rule.srcPort.toLowerCase() === "any" &&
        rule.destPort.toLowerCase() === "any" &&
        rule.srcCidr.toLowerCase() === "any" &&
        rule.destCidr.toLowerCase() === "any"
      ),
  );

const L3FirewallRuleScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const networkId = useCurrentNetworkId();
  const { data: l3FirewallRulesWithDefaults, isLoading: isL3FirewallRulesLoading } =
    useL3FirewallRules({ networkId });
  const l3FirewallRules = filterDefaultRules(l3FirewallRulesWithDefaults?.rules ?? []);
  const updateFirewallRules = useUpdateL3FirewallRules();

  const queryClient = useQueryClient();

  const [isEditMode, setIsEditMode] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => setIsEditMode(true)} />;
        } else {
          return <DoneButton onPress={() => setIsEditMode(false)} />;
        }
      },
    });
  }, [isEditMode, navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const deleteList = useCallback(
    (idx: any) => {
      const newArr = removeElementAtIndex<L3FirewallRule>(l3FirewallRules, idx);
      updateFirewallRules.mutate(
        {
          networkId,
          params: { rules: newArr },
        },
        {
          onSuccess: () => console.log("successful"),
          onSettled: () =>
            queryClient.refetchQueries({ queryKey: useL3FirewallRules.queryKeyRoot }),
        },
      );
    },
    [l3FirewallRules, networkId, queryClient, updateFirewallRules],
  );

  return (
    <FullScreenContainerView>
      <DefaultHeader
        title={I18n.t("L3_FIREWALL_RULES_LIST.HEADER.TITLE")}
        description={I18n.t("L3_FIREWALL_RULES_LIST.HEADER.SUBTITLE")}
      />
      <MkiTable
        data={{ rules: l3FirewallRules }}
        renderSectionHeader={(_: any) => (
          <SectionListHeader
            heading={I18n.t("L3_FIREWALL_RULES_LIST.SECTION_HEADER")}
            onPress={() => navigation.navigate("L3FirewallRule", { rules: [...l3FirewallRules] })}
            withHorizontalMargin
          />
        )}
        renderRow={(rowData: any, rowId: number) => {
          const deleteIcon = (
            <DeleteButton
              show={isEditMode}
              onPress={() => deleteList(rowId)}
              testID={"deleteTestID"}
            />
          );

          return (
            <SimpleDisclosureRow
              icon={deleteIcon}
              onPress={() =>
                navigation.navigate("L3FirewallRule", { rules: [...l3FirewallRules], idx: rowId })
              }
              subtitle={I18n.t("L3_FIREWALL_RULES_LIST.RULE_INFO", {
                policy: rowData.policy.toLowerCase(),
                protocol: rowData.protocol.toLowerCase(),
                srcCidr: rowData.srcCidr.toLowerCase(),
                destCidr: rowData.destCidr.toLowerCase(),
              })}
            >
              {rowData.comment || I18n.t("L3_FIREWALL_RULE.COMMENT.PLACEHOLDER")}
            </SimpleDisclosureRow>
          );
        }}
      />
      <LoadingSpinner visible={isL3FirewallRulesLoading || updateFirewallRules.isLoading} />
    </FullScreenContainerView>
  );
};

export default L3FirewallRuleScreen;
