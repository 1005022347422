import { schema } from "normalizr";

const nodeSchema = new schema.Entity(
  "nodes",
  {},
  {
    idAttribute: "serial",
  },
);

const clientMacSchema = new schema.Entity(
  "clients",
  {},
  {
    idAttribute: "mac",
  },
);

const userSchema = new schema.Entity("users");

const networkUseBlocksSchema = new schema.Entity("networkUseblocks");

const ssidsSchema = new schema.Entity("ssids", {}, { idAttribute: "networkId" });

const groupPoliciesSchema = new schema.Entity(
  "groupPolicies",
  {},
  {
    idAttribute: "network_id",
  },
);

const deviceUsageSchema = new schema.Entity("deviceUsage");

const networkHealthSchema = new schema.Entity(
  "networkHealth",
  {},
  {
    idAttribute: (_value, _parent, key) => `N_${key}`,
  },
);

const localeHealthSchema = new schema.Entity(
  "localeHealth",
  {},
  {
    idAttribute: (_value, _parent, key) => `L_${key}`,
  },
);

const ruleUseblocksSchema = new schema.Entity("ruleUseblocks");

const switchPortsSchema = new schema.Entity("switchPorts");

const smClientDataSchema = new schema.Entity("smClientData");

const smClientProfileSchema = new schema.Entity(
  "smClientProfile",
  {},
  {
    idAttribute: "pcc_machine_id",
  },
);

const adminSchema = new schema.Entity("admins");

export const Schemas = {
  ADMIN_ARRAY: [adminSchema],
  USER: userSchema,
  NODE: nodeSchema,
  NODE_ARRAY: [nodeSchema],
  CLIENTS_MAC_ARRAY: [clientMacSchema],
  NETWORK_USEBLOCKS: networkUseBlocksSchema,
  SSIDS: ssidsSchema,
  GROUP_POLICIES: groupPoliciesSchema,
  DEVICE_USAGE: deviceUsageSchema,
  NETWORK_HEALTH_ARRAY: {
    locale: new schema.Values(localeHealthSchema),
    network: new schema.Values(networkHealthSchema),
  },
  RULE_USEBLOCKS: ruleUseblocksSchema,
  SWITCH_PORTS: switchPortsSchema,
  SM_CLIENT_DATA: smClientDataSchema,
  SM_CLIENT_PROFILE_ARRAY: [smClientProfileSchema],
};
