import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";
import { LoginDetailsResponseType } from "../private/login/types";

const ResendEmailSchema = z
  .object({
    success: z.boolean(),
  })
  .describe("ResendEmailSchema");
type ResendEmailResultType = z.infer<typeof ResendEmailSchema>;

const IsEmailVerifiedSchema = z
  .object({
    verified: z.boolean(),
  })
  .describe("IsEmailVerifiedSchema");
export type IsEmailVerifiedType = z.infer<typeof IsEmailVerifiedSchema>;

type Body = {
  orgEid: string;
};

export type LoginContext = {
  response: LoginDetailsResponseType;
  email?: string;
};

const buildResendEmailUrl = (orgEid: string) => `/o/${orgEid}/manage/users/verify_email`;
const buildIsEmailVerified = (orgEid: string) => `/o/${orgEid}/manage/users/unverified_email`;

const resendEmail = ({ orgEid }: Body) =>
  request(ResendEmailSchema, "POST", buildResendEmailUrl(orgEid));

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useResendEmail = createMutation<Body, ResendEmailResultType>({
  baseMutationKey: buildResendEmailUrl("{orgEid}"),
  mutationFn: (data) => resendEmail(data),
});

const checkIfVerified = ({ orgEid }: Body) =>
  request(IsEmailVerifiedSchema, "GET", buildIsEmailVerified(orgEid));

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const useCheckIfEmailVerified = createQuery<Body, IsEmailVerifiedType>({
  baseQueryKey: buildIsEmailVerified("{orgEid}"),
  queryFn: (data) => checkIfVerified(data),
});

type VerifyEmailBody = {
  key: string;
};

const VerifyEmailResponseSchema = z
  .object({
    success: z.boolean(),
    message: z.string(),
  })
  .describe("VerifyEmailResponseSchema");
type VerifyEmailResponse = z.infer<typeof VerifyEmailResponseSchema>;

export const verifyEmail = (queryParams: VerifyEmailBody) =>
  request(VerifyEmailResponseSchema, "GET", "/login/confirm_email", { queryParams });

export const useVerifyEmail = createMutation<VerifyEmailBody, VerifyEmailResponse>({
  baseMutationKey: "/login/confirm_email",
  mutationFn: (params) => verifyEmail(params),
});
