import { ThemeName } from "@meraki/core/theme";
import { createContext } from "react";
import { Appearance, ColorSchemeName, Dimensions } from "react-native";

import AmoledEnterprise from "~/enterprise/themes/Amoled";
import DarkEnterprise from "~/enterprise/themes/Dark";
import DarkDeuteranomalyEnterprise from "~/enterprise/themes/DarkDeuteranomaly";
import LightEnterprise from "~/enterprise/themes/Light";
import LightDeuteranomalyEnterprise from "~/enterprise/themes/LightDeuteranomaly";
import AmoledGo from "~/go/themes/Amoled";
import DarkGo from "~/go/themes/Dark";
import LightGo from "~/go/themes/Light";
import { appSelect } from "~/lib/PlatformUtils";
import SharedColors from "~/shared/constants/SharedColors";

export type Theme = ColorSchemeName | ThemeName;

interface ThemeContext {
  backgroundColor: string;
}

export const ThemeContext = createContext<Partial<ThemeContext>>({});

type DeviceBoundaries = {
  small: number;
  default: number;
  large: number;
};

const DEVICE_HEIGHT: DeviceBoundaries = {
  small: 600, // smaller phones
  default: 700, // medium phones are shorter, phablets are taller
  large: 1000, // tablets are taller than 1000
};

export const DEVICE_WIDTH: DeviceBoundaries = {
  small: 320, // smaller phones
  default: 375, // medium phones are thinner, phablets are wider
  large: 768, // tablets are wider than 768
};

const PLUS_FACTOR = 1.15;
const TABLET_FACTOR = 1.33;

/* This method determines which value to use when theming
 * based on the presence of the __MERAKI_GO__ env variable
 * The object is similar to the value used for the Platform
 * utility provided by ReactNative.
 *
 * - Example -
 * styleOptions: {
 *   enterprise: someOption,
 *   go: alternateOption,
 * }
 */

type StyleOptionsWithoutDefault<T> = {
  small?: T;
  medium?: T;
  large?: T;
  extraLarge?: T;
  default?: T;
};

type StyleOptionsWithDefault<T> = {
  default: T;
} & StyleOptionsWithoutDefault<T>;

type StyleOptions<T> = StyleOptionsWithoutDefault<T> | StyleOptionsWithDefault<T>;

// The return type accounts for the fact that if there is a non-undefined default supplied
// then the return value will never be undefined
// StyleOptionsReturn<{ small: number, medium: string }> -> number | string | undefined
// StyleOptionsReturn<{ small: number, medium: string, default: boolean }> -> number | string | boolean
type StyleOptionsReturn<SO extends StyleOptions<unknown>> = SO extends StyleOptions<infer T>
  ? SO extends StyleOptionsWithDefault<T>
    ? T
    : T | undefined // insert undefined in the return type if "default" is missing
  : never;

function sizeSelectHelper<SO extends StyleOptions<unknown>>(
  size: number,
  boundaries: DeviceBoundaries,
  styleOptions: SO,
): StyleOptionsReturn<SO> {
  // FIXME: as any typecasts can be removed when https://github.com/microsoft/TypeScript/pull/43183 is released
  const { small, medium, large, extraLarge, default: defaultOption } = styleOptions as any;

  if (size <= boundaries.small) {
    return (small === undefined ? defaultOption : small) as any;
  }
  if (size <= boundaries.default) {
    return (medium === undefined ? defaultOption : medium) as any;
  }
  if (size <= boundaries.large) {
    return (large === undefined ? defaultOption : large) as any;
  }

  return (extraLarge === undefined ? defaultOption : extraLarge) as any;
}

interface ColorSchemeOptions<T> {
  light: T;
  lightDeuteranomaly: T;
  dark: T;
  darkDeuteranomaly: T;
  amoled: T;
}

export function colorSchemeSelect<T>(options: ColorSchemeOptions<T>, theme: ThemeName): T {
  switch (theme) {
    case "amoled":
      return options.amoled;
    case "dark":
      return options.dark;
    case "darkDeuteranomaly":
      return options.darkDeuteranomaly;
    case "lightDeuteranomaly":
      return options.lightDeuteranomaly;
    case "light":
    default:
      return options.light;
  }
}

export function defineStyleKey<K extends string, V>(
  key: K,
  value: V,
): V extends null | undefined ? {} : { [key in K]: V } {
  if (value === null || value === undefined) {
    // FIXME: as any typecasts can be removed when https://github.com/microsoft/TypeScript/pull/43183 is released
    return {} as any;
  }
  return { [key]: value } as any;
}

export function sizeSelect<SO extends StyleOptions<unknown>>(
  styleOptions: SO,
): StyleOptionsReturn<SO> {
  const { height } = Dimensions.get("window");
  return sizeSelectHelper(height, DEVICE_HEIGHT, styleOptions);
}

export function sizeSelectWidth<SO extends StyleOptions<unknown>>(
  styleOptions: SO,
): StyleOptionsReturn<SO> {
  const { width } = Dimensions.get("window");
  return sizeSelectHelper(width, DEVICE_WIDTH, styleOptions);
}

export function normalizedFontSize(baseSize: number): number {
  const { width } = Dimensions.get("window");

  if (width >= DEVICE_WIDTH.large) {
    return Math.round(baseSize * TABLET_FACTOR); // 33% bump for tablet devices
  }
  if (width < DEVICE_WIDTH.large && width > DEVICE_WIDTH.default) {
    return Math.round(baseSize * PLUS_FACTOR); // 15% bump for phablet devices
  }
  return baseSize;
}

/**
 * as we move away from theme in redux we are getting rid of the case where theme
 * might be undefined. It's theme. It shouldn't be undefined.
 */
export const themeColors = (appTheme?: ThemeName | null) => {
  const systemTheme = Appearance.getColorScheme();
  const theme = (appTheme === "system" ? systemTheme : appTheme) ?? "light";

  const goColorsSchemes = colorSchemeSelect(
    {
      light: LightGo,
      dark: DarkGo,
      amoled: AmoledGo,
      lightDeuteranomaly: LightGo, // Change once Go has colors for Deuteranomaly
      darkDeuteranomaly: DarkGo,
    },
    theme,
  );

  const enterpriseColorSchemes = colorSchemeSelect(
    {
      light: LightEnterprise,
      dark: DarkEnterprise,
      amoled: AmoledEnterprise,
      lightDeuteranomaly: LightDeuteranomalyEnterprise,
      darkDeuteranomaly: DarkDeuteranomalyEnterprise,
    },
    theme,
  );

  return appSelect({
    enterprise: enterpriseColorSchemes,
    go: goColorsSchemes,
  });
};

export const themeMode = (theme?: ThemeName | null) => {
  const mainTheme = themeColors(theme).navigation.backgroundPrimary;
  return mainTheme === SharedColors.white ? "light" : "dark";
};
