import { I18n } from "@meraki/core/i18n";
import { communityUrl } from "@meraki/go/links";
import { PureComponent } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";

type Props = {
  onRestartPress: () => void;
};

type State = {
  showTroubleShooting: boolean;
};

class CableTestFail extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { showTroubleShooting: true };
  }

  pokeDevice = () => {
    // TODO: show poke device screen
  };

  closeTroubleShooting() {
    this.setState({ showTroubleShooting: false });
  }

  render() {
    const { onRestartPress } = this.props;
    const { showTroubleShooting } = this.state;
    return (
      <View style={styles.container}>
        <MerakiIcon name="exit-circle" size="xl" color={MkiColors.badStatus} />
        <MkiText screenStyles={styles.infoText} textStyle="default">
          {I18n.t("CABLE_TEST.FAIL.DESCRIPTION")}
        </MkiText>
        <View style={styles.buttonContainer}>
          <RoundedButton
            buttonType={ButtonType.secondary}
            onPress={onRestartPress}
            screenStyles={styles.buttonStyle}
          >
            {I18n.t("LIVE_TOOLS.RETEST")}
          </RoundedButton>
        </View>
        <InlineAlert
          visible={showTroubleShooting}
          onExit={() => this.closeTroubleShooting()}
          alertTitle={I18n.t("CABLE_TEST.FAIL.TROUBLESHOOTING.TITLE")}
          alertMessage={I18n.t("CABLE_TEST.FAIL.TROUBLESHOOTING.MESSAGE")}
          primaryButtonText={I18n.t("CABLE_TEST.POKE_DEVICE")}
          secondaryButtonText={I18n.t("LEARN_MORE")}
          onPrimaryPress={() => this.pokeDevice()}
          onSecondaryPress={communityUrl}
          screenStyles={styles.inlineAlert}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginVertical: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.extraLarge,
  },
  container: {
    alignItems: "center",
    paddingTop: SPACING.default,
  },
  infoText: {
    margin: SPACING.default,
    textAlign: "center",
  },
  inlineAlert: {
    marginVertical: SPACING.large,
    marginHorizontal: 0,
  },
});

export default CableTestFail;
