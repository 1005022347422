import { I18n } from "@meraki/core/i18n";
import { NodeType, Status, TopologyNodeKeys } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { PortMapById } from "~/api/queries/topology/useFormattedTopology";
import mkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { ProductIconSize } from "~/go/components/HardwareRow";
import ProductIcon from "~/go/components/ProductIcon";
import { getDeviceStatus, getIsMeshAccessPoint } from "~/lib/DeviceUtils";
import { getNodeRenderInfo } from "~/lib/TopologyUtils";
import { devicesState } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import StatusIcon from "~/shared/components/StatusIcon";
import { GoStatus, StatusType } from "~/shared/constants/Status";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useThemeColors } from "~/shared/hooks/useTheme";
import ThemeInterface from "~/shared/themes/ThemeInterface";
import { StatusKeys } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";

const ICON_SIZE = 8;
const ICON_GUTTER = SPACING.extraLarge;
interface TopologyNodeCardProps {
  node: NodeType;
  portMappings: PortMapById;
}

const getBorderColor = (themeColors: ThemeInterface, status?: Status) => {
  let borderColor = themeColors.status.dormant.color;
  switch (status) {
    case StatusKeys.online:
      borderColor = themeColors.status.online.color;
      break;
    case StatusKeys.alerting:
      borderColor = themeColors.status.alerting.color;
      break;
    case StatusKeys.offline:
      borderColor = themeColors.status.offline.color;
      break;
  }

  return { borderColor };
};

const TopologyNodeCard = (props: TopologyNodeCardProps) => {
  const { node, portMappings } = props;
  const devices = useAppSelector(devicesState);
  const navigation = useNavigation();
  const themeColors = useThemeColors();

  let onCardPress: () => void = () => undefined;

  const pushDeviceDetails = (serialNumber: string, deviceIsLive: boolean) =>
    navigation.navigate("HardwareDetails", { serialNumber, deviceIsLive });

  let borderColor = getBorderColor(themeColors, StatusKeys.dormant);
  let productIconStatus: StatusType | undefined = GoStatus.good;
  let productIconModel = "unknown";
  let isDeviceStatus;
  const details: React.ReactNode[] = [];
  const { name, mac, serial, connectedDevices, productType, uplinkIpAddress } =
    getNodeRenderInfo(node);

  switch (node.type) {
    case TopologyNodeKeys.device:
      if (serial === undefined) {
        console.warn("Topology endpoint may be broken");
        break;
      }
      const device = devices[serial];
      const uplinkPorts = portMappings[node.derivedId];
      const uplinkPortNumbers = uplinkPorts == null ? I18n.t("UNKNOWN") : uplinkPorts.join(", ");

      borderColor = getBorderColor(themeColors, device.status);
      const isLive = device.status != StatusKeys.offline ? true : false;
      const deviceStatus = getDeviceStatus(device) || GoStatus.offline;
      isDeviceStatus = deviceStatus;

      productIconStatus = deviceStatus;
      productIconModel = device.model;
      onCardPress = () => pushDeviceDetails(serial, isLive);

      if (productType === ProductType.switch) {
        details.push(
          <MkiText key="uplinkPorts">
            {I18n.t("TOPOLOGY.NODE_CARD.UPLINK_PORT", { uplinkPortNumbers })}
          </MkiText>,
        );
      } else {
        details.push(
          <MkiText key="clients">
            {I18n.t("TOPOLOGY.NODE_CARD.CONNECTED_DEVICES", { connectedDevices })}
          </MkiText>,
        );
      }

      if (productType === ProductType.appliance) {
        details.push(
          <MkiText key="uplinkIP">
            {I18n.t("TOPOLOGY.NODE_CARD.UPLINK_IP", { uplinkIpAddress })}
          </MkiText>,
        );
      }
      break;
    case TopologyNodeKeys.discovered:
      details.push(
        <MkiText key="macAddress">{I18n.t("TOPOLOGY.NODE_CARD.MAC", { macAddress: mac })}</MkiText>,
      );
      break;
  }

  return (
    <TouchableOpacity style={[styles.topoNodeCard, borderColor]} onPress={onCardPress}>
      <View style={styles.nodeCard}>
        <View style={styles.nameStatusRow}>
          {serial && isDeviceStatus && (
            <StatusIcon
              status={isDeviceStatus}
              isHollow={getIsMeshAccessPoint(devices[serial])}
              screenStyles={styles.statusIcon}
              testID={`${isDeviceStatus}_ICON`}
            />
          )}
          <MkiText truncation={20}>{name}</MkiText>
        </View>
        <ProductIcon
          status={productIconStatus}
          model={productIconModel}
          size={ProductIconSize.large}
        />
        <View>{details}</View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  topoNodeCard: {
    flex: 6,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: SPACING.default,
    paddingVertical: SPACING.default,
    borderRadius: SPACING.extraLarge,
    borderWidth: SPACING.meager,
    borderColor: mkiColors.alertStatus,
  },
  nodeCard: {
    alignItems: "center",
  },
  nameStatusRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  statusIcon: {
    marginHorizontal: Math.round((ICON_GUTTER - ICON_SIZE) / 2),
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
});

export default TopologyNodeCard;
