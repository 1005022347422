import { AdapterContext } from "@meraki/magnetic/adapter";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";
import { useContext } from "react";
import { TextStyle } from "react-native";

import { platformSelect } from "~/lib/PlatformUtils";

export const DefaultScreenOptions: NativeStackNavigationOptions = {
  headerShadowVisible: false,
  headerBackTitleVisible: false,
  headerTitleStyle: {
    fontFamily: platformSelect({
      android: "proximasoft_medium",
      ios: "ProximaSoft-Medium",
    }),
  },
  headerTitleAlign: "center",
  headerTitle: "",
  headerTransparent: false,
};

export function useDefaultScreenOptions(): NativeStackNavigationOptions {
  const magneticEnabled = useContext(AdapterContext);
  const theme = useMagneticTheme();

  return {
    ...DefaultScreenOptions,
    ...(magneticEnabled
      ? {
          headerShadowVisible: true,
          headerStyle: { backgroundColor: theme.color.default.bg.base },

          headerTintColor: theme.color.default.text.medium.base,
          headerTitleStyle: {
            fontFamily: "Sharp Sans",
            color: theme.color.default.text.medium.base,
            fontWeight: `${theme.TypographyWeightBold}` as TextStyle["fontWeight"],
            fontSize: theme.SizeFontMd,
          },
        }
      : {}),
  };
}
