import { I18n } from "@meraki/core/i18n";
import { Button, SearchBar } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { Keyboard } from "react-native";

type SearchFilterSectionProps = {
  placeholder?: string;
  searchText: string;
  onSearchTextChange: (text: string) => void;
  onFilterPress: () => void;
};

export function SearchFilterSection({
  placeholder = I18n.t("SEARCH_BAR.DEFAULTS.SEARCH_PLACEHOLDER"),
  searchText,
  onSearchTextChange,
  onFilterPress,
}: SearchFilterSectionProps) {
  return (
    <Box flexDirection="row" padding="sm" alignItems="center" gap="sm" bottomDividerBorder>
      <Box flex={1}>
        <SearchBar placeholder={placeholder} value={searchText} onChangeText={onSearchTextChange} />
      </Box>
      <Button
        kind="tertiary"
        onPress={() => {
          Keyboard.dismiss();
          onFilterPress();
        }}
        text={I18n.t("SEARCH_BAR.DEFAULTS.FILTER")}
      />
    </Box>
  );
}
