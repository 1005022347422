import { I18n } from "@meraki/core/i18n";
import { documentationUrl } from "@meraki/go/links";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import {
  BUTTON_SIZING,
  LATENCY_LIMIT,
  LINE_HEIGHT,
  LOSS_LIMIT,
  PING_TIMEOUT,
  SPACING,
} from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import ConnectingAnimation from "~/go/components/onboarding/ConnectingAnimation";
import RoundedButton from "~/go/components/RoundedButton";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { getHardwareTypeForLED } from "~/lib/DeviceUtils";
import { withCommandSubscription } from "~/lib/liveBroker";
import { nestedValueExists } from "~/lib/objectHelper";
import { normalizedFontSize } from "~/lib/themeHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import QuickStatusBox from "~/shared/components/QuickStatusBox";
import { GoStatus } from "~/shared/constants/Status";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { CloseButton } from "~/shared/navigation/Buttons";
import Device_DeprecatedType from "~/shared/types/Device";
import { ToolCommandProps } from "~/shared/types/liveTools/LiveTool";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "PingHost"> & {
  tool: ToolCommandProps;
};

type State = {
  isPinging: boolean;
  showTroubleshooting: boolean;
  pings: number[];
  lossRate: number;
  averageLatency: number;
};

export class PingHost extends PureComponent<Props, State> {
  static defaultProps = {
    pings: null,
    completed: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isPinging: true,
      showTroubleshooting: false,
      pings: [],
      lossRate: 0,
      averageLatency: 0,
    };

    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  componentDidMount() {
    this.start();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.completed) {
      this.setState({ isPinging: false, showTroubleshooting: true });
      return;
    }
    if (!nextProps.pings) {
      return;
    }

    this.setState((prevState) => ({
      pings: prevState.pings.concat(nextProps?.pings ?? []),
    }));
    this.updateStats();
  }

  componentWillUnmount() {
    const { tool } = this.props;
    tool.commandUnsubscribe();
  }

  showRestartModal = (serialNumber: string, device: Device_DeprecatedType) => {
    const { navigation } = this.props;
    navigation.navigate("Restart", {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ serialNumber: string; device: any... Remove this comment to see the full error message
      serialNumber,
      device,
      customFilter: CUSTOM_FILTERS.DEVICE_CLIENTS(device.id),
    });
  };

  start(resetState = false) {
    const { tool } = this.props;

    if (resetState) {
      this.setState({
        isPinging: true,
        showTroubleshooting: false,
        pings: [],
      });
    }
    tool.commandSubscribe();
    // @ts-expect-error TS(2339) FIXME: Property 'timer' does not exist on type 'PingHost'... Remove this comment to see the full error message
    this.timer = setTimeout(() => {
      this.stop();
    }, PING_TIMEOUT);
  }

  stop() {
    const { tool } = this.props;

    tool.commandUnsubscribe();
    this.setState({ isPinging: false, showTroubleshooting: true });
    // @ts-expect-error TS(2339) FIXME: Property 'timer' does not exist on type 'PingHost'... Remove this comment to see the full error message
    clearTimeout(this.timer);
    // @ts-expect-error TS(2339) FIXME: Property 'timer' does not exist on type 'PingHost'... Remove this comment to see the full error message
    this.timer = null;
  }

  updateStats() {
    const { pings } = this.state;
    if (pings.length === 0) {
      return;
    }

    const lossRate = (pings.filter((ping) => ping === -1).length * 100) / pings.length;
    const averageLatency =
      pings.reduce((total, ping) => {
        if (ping === -1) {
          return total;
        }
        return total + ping;
      }, 0) / pings.length;

    this.setState({ lossRate, averageLatency });
  }

  shouldRenderStats() {
    const { pings, lossRate, averageLatency } = this.state;
    if (pings.length === 0 || averageLatency === undefined || lossRate === undefined) {
      return false;
    }
    return true;
  }

  renderLoading = () => {
    const { isPinging } = this.state;

    if (!isPinging || this.shouldRenderStats()) {
      return null;
    }
    return (
      <View style={styles.loadingContainer}>
        <MkiSpinner />
      </View>
    );
  };

  renderStats() {
    if (!this.shouldRenderStats()) {
      return null;
    }
    const { lossRate, averageLatency, isPinging } = this.state;
    const results = (
      <View style={styles.stats}>
        <QuickStatusBox
          value={Math.round(averageLatency)}
          subText={I18n.t("AVERAGE_LATENCY")}
          status={averageLatency >= LATENCY_LIMIT ? GoStatus.bad : null}
          onPress={null}
          category="ms"
        />
        <QuickStatusBox
          value={Math.round(lossRate)}
          subText={I18n.t("LOSS_RATE")}
          status={lossRate >= LOSS_LIMIT ? GoStatus.bad : null}
          onPress={null}
          category="%"
        />
      </View>
    );

    if (isPinging) {
      return (
        <View>
          {results}
          <ConnectingAnimation />
          <View style={styles.buttonContainer}>
            <RoundedButton
              buttonType="destructive"
              onPress={() => this.stop()}
              screenStyles={styles.buttonStyle}
            >
              {I18n.t("STOP")}
            </RoundedButton>
          </View>
        </View>
      );
    }

    return (
      <View>
        <MkiText textStyle="subheading" screenStyles={styles.resultsHeader}>
          {"Test results"}
        </MkiText>
        {results}
      </View>
    );
  }

  renderTest() {
    const { isPinging } = this.state;

    if (!isPinging) {
      return null;
    }

    return (
      <View>
        <MkiText screenStyles={styles.infoText}>{I18n.t("LIVE_TOOLS.PING_HOST")}</MkiText>
        <MkiText screenStyles={[styles.testing, styles.centerText]} textStyle="default">
          {I18n.t("LIVE_TOOLS.TESTING_CONNECTION", { name: "Meraki Go" })}
        </MkiText>
      </View>
    );
  }

  renderTroubleshooting() {
    const { isPinging, showTroubleshooting } = this.state;

    if (isPinging) {
      return null;
    }

    const { navigation, device } = this.props;
    const { averageLatency, lossRate } = this.state;

    let message = I18n.t("LIVE_TOOLS.PINGHOST_DEFAULT");

    let primaryText = I18n.t("LIVE_TOOLS.TEST_WEBSITE");
    let primaryButton = () =>
      navigation.navigate("PingWebsite", {
        device,
      });

    const secondaryText = I18n.t("LIVE_TOOLS.LEARN_MORE");

    if (lossRate === 100) {
      // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      message = (
        <MkiText screenStyles={styles.messageText}>
          {I18n.t("LIVE_TOOLS.PING_LOSS_START")}
          <MkiText
            onPress={() => this.showRestartModal(device.serial, device)}
            screenStyles={styles.clickable}
          >
            {I18n.t("LIVE_TOOLS.PING_LOSS_REBOOT")}
          </MkiText>
          {I18n.t("LIVE_TOOLS.PING_LOSS_MIDDLE")}
          <MkiText
            onPress={() =>
              navigation.navigate("PingWebsite", {
                device,
              })
            }
            screenStyles={styles.clickable}
          >
            {I18n.t("LIVE_TOOLS.PING_LOSS_TEST")}
          </MkiText>
          {I18n.t("LIVE_TOOLS.PING_LOSS_END")}
        </MkiText>
      );
      primaryText = I18n.t("LIVE_TOOLS.CONTACT_SUPPORT");
      primaryButton = () => navigation.navigate("SearchSubject");
    } else if (averageLatency > LATENCY_LIMIT || lossRate > LOSS_LIMIT) {
      const productType = getHardwareTypeForLED(device.model);
      switch (productType) {
        case "appliance":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.APPLIANCE");
          break;
        case "indoor":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.INDOOR");
          break;
        case "outdoor":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.OUTDOOR");
          break;
        case "switch":
          message = I18n.t("LIVE_TOOLS.PINGHOST_UNACCEPTABLE.SWITCH");
          break;
      }

      primaryText = I18n.t("LIVE_TOOLS.CONTACT_SUPPORT");
      primaryButton = () => navigation.navigate("SearchSubject");
    }

    // TODO: Link to Ping AP specific documentation
    return (
      <InlineAlert
        visible={showTroubleshooting}
        onExit={() => this.setState({ showTroubleshooting: false })}
        alertTitle={I18n.t("LIVE_TOOLS.TROUBLESHOOT_TITLE")}
        screenStyles={styles.alert}
        alertMessage={message}
        primaryButtonText={primaryText}
        secondaryButtonText={secondaryText}
        onPrimaryPress={primaryButton}
        onSecondaryPress={documentationUrl}
      />
    );
  }

  renderResult() {
    const { isPinging } = this.state;
    if (isPinging) {
      return null;
    }

    const { averageLatency, lossRate } = this.state;

    let icon = <MerakiIcon name="check-circle" size="xl" color={MkiColors.activeInputUnderline} />;

    let messageText = I18n.t("LIVE_TOOLS.RESULT_OK", { name: "Meraki Go" });

    if (lossRate === 100) {
      icon = <MerakiIcon name="exit-circle" size="xl" color={MkiColors.badStatus} />;
      messageText = I18n.t("LIVE_TOOLS.RESULT_LOSS", { name: "Meraki Go" });
    } else if (
      averageLatency > LATENCY_LIMIT ||
      lossRate > LOSS_LIMIT ||
      !this.shouldRenderStats()
    ) {
      icon = <MerakiIcon name="alert" size="xl" color={MkiColors.badStatus} />;
      messageText = I18n.t("LIVE_TOOLS.RESULT_UNACCEPTABLE", { name: "Meraki Go" });
    }

    const message = (
      <MkiText screenStyles={styles.centerText} textStyle="subheading">
        {messageText}
      </MkiText>
    );

    return (
      <View style={styles.results}>
        {icon}
        {message}
        <View style={styles.buttonContainer}>
          <RoundedButton
            buttonType="secondary"
            onPress={() => this.start(true)}
            screenStyles={styles.buttonStyle}
          >
            {I18n.t("LIVE_TOOLS.RETEST")}
          </RoundedButton>
        </View>
      </View>
    );
  }

  render() {
    return (
      <FullScreenContainerView>
        <ScrollView contentContainerStyle={styles.contentContainer}>
          {this.renderTest()}
          {this.renderResult()}
          {this.renderStats()}
          {this.renderLoading()}
          {this.renderTroubleshooting()}
        </ScrollView>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  alert: {
    marginHorizontal: 0,
  },
  centerText: {
    alignSelf: "center",
    textAlign: "center",
    paddingHorizontal: SPACING.extraLarge,
    paddingBottom: SPACING.small,
  },
  clickable: {
    color: MkiColors.primaryButton,
  },
  contentContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.default,
  },
  infoText: {
    color: MkiColors.secondaryTextColor,
    textAlign: "left",
    width: "90%",
    paddingBottom: SPACING.extraLarge,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: SPACING.large,
  },
  messageText: {
    color: MkiColors.secondaryTextColor,
    marginBottom: SPACING.default,
    lineHeight: LINE_HEIGHT.default,
  },
  results: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: SPACING.extraLarge,
    paddingTop: SPACING.large,
  },
  resultsHeader: {
    color: MkiColors.primaryButton,
  },
  stats: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  buttonContainer: {
    alignSelf: "center",
    marginTop: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.default,
  },
  testing: {
    fontSize: normalizedFontSize(24),
  },
});

export default withCommandSubscription({
  getArgs: () => ({ broker: "PingAP" }),
  handler: (response: any) => {
    const pings = nestedValueExists(response, ["data", "pings"], null);
    return {
      pings: pings ? pings.map((ping: any) => parseInt(ping, 10)) : null,
      completed: nestedValueExists(response, ["data", "completed"], false),
    };
  },
  deviceId: ({ device }: any) => device.id,
})(PingHost);
