import { I18n } from "@meraki/core/i18n";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { MODAL_DISMISS_DURATION, SPACING } from "~/constants/MkiConstants";
import ConfirmAlert from "~/go/components/ConfirmAlert";
import InfoModal from "~/go/components/InfoModal";
import RoundedButton from "~/go/components/RoundedButton";
import InputRow from "~/go/rows/InputRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { isAuthenticatedState } from "~/selectors";
import BannerAlert from "~/shared/components/BannerAlert";
import FullscreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { GoStatus } from "~/shared/constants/Status";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CloseButton } from "~/shared/navigation/Buttons";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "PasswordReset"> &
  PendingComponent;

const bulletPoint = (numOfSpaces: number, text: string) =>
  `\n${" ".repeat(numOfSpaces)}\u2022 ${text}`;

const PasswordResetScreen = ({ url = "", setReqPending, reqPending }: Props) => {
  const isAuthenticated = useAppSelector(isAuthenticatedState);
  const { resetPassword, updateGoPassword } = useActions();

  const navigation = useNavigation<Props["navigation"]>();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => <CloseButton onPress={navigation.goBack} />,
    });
  }, [navigation]);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const closeModalAndDismiss = useCallback(() => {
    setShowSuccess(false);
    setTimeout(navigation.goBack, MODAL_DISMISS_DURATION);
  }, [setShowSuccess, navigation]);

  const onSuccessfulReset = useCallback(() => {
    setAlertMessage("");
    isAuthenticated ? setShowSuccess(true) : navigation.goBack();
  }, [navigation, isAuthenticated]);

  const onFailedUpdate = useCallback((error: unknown) => {
    if (typeof error === "string") {
      setAlertMessage(error);
    } else {
      setAlertMessage(I18n.t("PASSWORD_RESET.MEET_REQUIREMENTS"));
    }
  }, []);

  const onUpdatePassword = useCallback(() => {
    setReqPending(true);
    updateGoPassword(oldPassword, password, passwordConfirm)
      .then(onSuccessfulReset)
      .catch(onFailedUpdate)
      .finally(() => setReqPending(false));
  }, [
    setReqPending,
    updateGoPassword,
    oldPassword,
    password,
    passwordConfirm,
    onSuccessfulReset,
    onFailedUpdate,
  ]);

  const onSavePassword = useCallback(() => {
    setReqPending(true);
    resetPassword(url, password, passwordConfirm)
      .then(onSuccessfulReset)
      .catch(onFailedUpdate)
      .finally(() => setReqPending(false));
  }, [
    onFailedUpdate,
    onSuccessfulReset,
    password,
    passwordConfirm,
    resetPassword,
    setReqPending,
    url,
  ]);

  return (
    <FullscreenContainerView withScroll>
      <MkiText textStyle="smallSecondary" screenStyles={styles.description}>
        {I18n.t("PASSWORD_RESET.DESCRIPTION")}
        {bulletPoint(4, I18n.t("PASSWORD_RESET.REQUIREMENTS.MINIUM_CHARACTERS"))}
        {bulletPoint(4, I18n.t("PASSWORD_RESET.REQUIREMENTS.NO_COMMON"))}
        {bulletPoint(4, I18n.t("PASSWORD_RESET.REQUIREMENTS.DIFFERENT_FROM_CURRENT"))}
        {bulletPoint(4, I18n.t("PASSWORD_RESET.REQUIREMENTS.INCLUDES"))}
        {bulletPoint(8, I18n.t("PASSWORD_RESET.REQUIREMENTS.UPPER_LOWER_CASE"))}
        {bulletPoint(8, I18n.t("PASSWORD_RESET.REQUIREMENTS.NUMBER"))}
        {bulletPoint(8, I18n.t("PASSWORD_RESET.REQUIREMENTS.SYMBOL"))}
      </MkiText>
      {!!alertMessage && (
        <BannerAlert
          testID="PASSWORD_RESET.ALERT"
          alertType={GoStatus.bad}
          alertText={alertMessage}
          screenStyles={styles.alert}
        />
      )}
      {isAuthenticated && (
        <InputRow
          editable={!reqPending}
          value={oldPassword}
          onChangeText={setOldPassword}
          revealable
          secureTextEntry
        >
          {I18n.t("PASSWORD_RESET.OLD_PASSWORD")}
        </InputRow>
      )}
      <InputRow
        editable={!reqPending}
        value={password}
        onChangeText={setPassword}
        revealable
        secureTextEntry
      >
        {I18n.t("PASSWORD_RESET.PASSWORD")}
      </InputRow>
      <InputRow
        editable={!reqPending}
        value={passwordConfirm}
        onChangeText={setPasswordConfirm}
        revealable
        secureTextEntry
      >
        {I18n.t("PASSWORD_RESET.PASSWORD_CONFIRMATION")}
      </InputRow>
      {isAuthenticated && (
        <InfoModal visible={showSuccess} onExit={closeModalAndDismiss}>
          <ConfirmAlert
            title={I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.TITLE")}
            subtitle={I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.SUBTITLE")}
            primaryButtonText={I18n.t("PASSWORD_RESET.UPDATE_PASSWORD_SUCCESS.CONFIRM")}
            onPrimaryPress={closeModalAndDismiss}
          />
        </InfoModal>
      )}
      <RoundedButton
        disabled={!password || password !== passwordConfirm}
        onPress={isAuthenticated ? onUpdatePassword : onSavePassword}
        screenStyles={styles.button}
      >
        {I18n.t("PASSWORD_RESET.TITLE")}
      </RoundedButton>
    </FullscreenContainerView>
  );
};

const styles = StyleSheet.create({
  description: {
    padding: SPACING.default,
  },
  alert: {
    marginTop: SPACING.default,
    marginHorizontal: SPACING.default,
    marginBottom: SPACING.small,
    width: undefined,
  },
  button: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.extraLarge * 2,
  },
});

export default withPendingComponent(PasswordResetScreen);
