import { PureComponent } from "react";
import { StyleProp, ViewStyle } from "react-native";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import SwipeableViews from "react-swipeable-views";

import PageIndicator from "~/shared/components/viewPager/PageIndicator";

export interface PageSelectedEvent {
  nativeEvent: {
    position: number;
  };
}

export interface ViewPagerProps {
  onPageSelected?: (e: PageSelectedEvent) => void;
  style?: StyleProp<ViewStyle>;
  dotIndicatorStyle?: StyleProp<ViewStyle>;
  showPageIndicator?: boolean;
  children: React.ReactNode[];
}

interface PageViewState {
  activePage: number;
  pageIndex: number;
}

class ViewPager extends PureComponent<ViewPagerProps, PageViewState> {
  constructor(props: ViewPagerProps) {
    super(props);
    this.state = {
      activePage: 1,
      pageIndex: 0,
    };
  }

  setPage = (pageIndex: number) => {
    const { onPageSelected } = this.props;

    this.setState({
      pageIndex,
      activePage: pageIndex + 1,
    });

    if (onPageSelected != null) {
      onPageSelected({ nativeEvent: { position: pageIndex } });
    }
  };

  onPressPrevPage = () => {
    const { pageIndex } = this.state;

    if (pageIndex > 0) {
      this.setPage(pageIndex - 1);
    }
  };

  onPressNextPage = () => {
    const { children } = this.props;
    const { pageIndex } = this.state;

    if (pageIndex < children.length - 1) {
      this.setPage(pageIndex + 1);
    }
  };

  render() {
    const { children, showPageIndicator, style } = this.props;
    const { activePage, pageIndex } = this.state;

    const viewPager = (
      <SwipeableViews
        containerStyle={style}
        onChangeIndex={this.setPage}
        index={pageIndex}
        enableMouseEvents
      >
        {children}
      </SwipeableViews>
    );

    if (!showPageIndicator) {
      return viewPager;
    }

    return (
      <PageIndicator numPages={children.length} activePage={activePage}>
        {viewPager}
      </PageIndicator>
    );
  }
}

export default ViewPager;
