import { I18n } from "@meraki/core/i18n";
import { StatusProps } from "@meraki/magnetic/icons";
import { DeviceStatus, ProductType } from "@meraki/shared/api";

export const DEVICE_STATUS_MAP: Record<DeviceStatus["status"], StatusProps["status"]> = {
  online: "positive",
  alerting: "warning",
  dormant: "neutral",
  offline: "negative",
};

export const GoProductTypes: ProductType[] = ["wireless", "appliance", "switch"];

export const getProductTypeTranslation = (productType: ProductType) => {
  switch (productType) {
    case "appliance":
      return I18n.t("PRODUCT_LINES.GX.FAMILY");
    case "switch":
      return I18n.t("PRODUCT_LINES.GS.FAMILY");
    case "wireless":
      return I18n.t("PRODUCT_LINES.GR.FAMILY");
  }

  return productType;
};

export function getProductType(model: string | undefined): ProductType | undefined {
  if (!model) {
    return undefined;
  }
  // catalyst switches
  const catalystPrefix = model?.match(/(^C\d)\d*/)?.[1];

  if (catalystPrefix) {
    return "switch";
  }

  const modelPrefix = model?.match(/[vV]?([a-zA-Z]+)[-\d]*?/)?.[1];

  switch (modelPrefix) {
    case "MR":
    case "GR":
    case "CW":
      return "wireless";
    case "MS":
    case "GS":
      return "switch";
    case "MX":
    case "GX":
    case "Z":
      return "appliance";
    case "MV":
    case "GV":
      return "camera";
    case "MT":
    case "HS":
      return "sensor";
    case "MG":
      return "cellularGateway";
    case "MC":
    case "GC":
      return "phone";
    default:
      // Unknown model
      return undefined;
  }
}

export const INDOOR_APS = ["GR10", "GR12"];
export const OUTDOOR_APS = ["GR60", "GR62"];

export const INDOOR = "indoor";
export const OUTDOOR = "outdoor";

export function getIndoorOrOutdoor(model: string): "indoor" | "outdoor" | null {
  if (INDOOR_APS.includes(model)) {
    return "indoor";
  }
  if (OUTDOOR_APS.includes(model)) {
    return "outdoor";
  }
  return null;
}

export function addSerialHyphens(input: string) {
  if (input === undefined) {
    return "";
  }

  let newValue;
  switch (input.length) {
    case 4:
    case 9:
      newValue = `${input}-`;
      break;
    case 5:
    case 10:
      newValue = `${input.slice(0, -1)}-${input.slice(-1)}`;
      break;
    default:
      newValue = input;
      break;
  }
  return newValue;
}

// This regex searches for a three, 4-character combinations
// of capitalized letters & numbers, separated by dashes.
const SERIAL_REGEX = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
const CHAR_REGEX = /^[a-zA-Z0-9-_]+$/;
export const SERIAL_LENGTH = 14;

export const validateSerialLength = (serial: string) => {
  return serial?.length === SERIAL_LENGTH;
};

export function validateDeviceSerial(serial: string) {
  return validateSerialLength(serial) && SERIAL_REGEX.test(serial);
}

export function validateDeviceSerialCharacters(serial: string) {
  if (serial.length > SERIAL_LENGTH) {
    return I18n.t("DEVICES.REGEX_ERROR_TEXT_TOO_LONG");
  }
  if (serial.indexOf("—") !== -1 || serial.indexOf("--") !== -1) {
    return I18n.t("DEVICES.REGEX_ERROR_TEXT_DOUBLE_DASH");
  }
  if (!CHAR_REGEX.test(serial)) {
    return I18n.t("DEVICES.REGEX_ERROR_TEXT_BAD_CHARS");
  }
  return undefined;
}
