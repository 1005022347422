import { useMemo } from "react";
import { VictoryArea } from "victory-native";

import MkiColors from "~/constants/MkiColors";
import MkiChart, { MkiChartProps } from "~/shared/components/MkiChart";

export const MkiMultilineChart = (props: MkiChartProps) => {
  const { data, dataKeys, hasFill } = props;

  const dataArea = useMemo(
    () =>
      dataKeys?.map((lineKey, idx) => {
        const lineStyle = {
          data: {
            fillOpacity: hasFill ? 0.15 : 0,
            stroke: lineKey.color || MkiColors.primaryGraphLine,
            fill: lineKey.color || MkiColors.primaryGraphLine,
            strokeWidth: 2,
          },
        };

        return (
          <VictoryArea
            key={`VictoryArea${idx}`}
            data={data?.filter((datum) => lineKey.y in datum)}
            style={lineStyle}
            {...lineKey}
          />
        );
      }),
    [data, dataKeys, hasFill],
  );

  return <MkiChart {...props}>{dataArea}</MkiChart>;
};

export default MkiMultilineChart;
