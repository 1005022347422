/* eslint-disable @typescript-eslint/no-explicit-any */
export function formatURLQueryParams(value: object, key: undefined, options?: any): string;
export function formatURLQueryParams(value: string[] | string, key: string, options?: any): string;
export function formatURLQueryParams(value: any, key: any, options: any = {}): string {
  /* eslint-enable @typescript-eslint/no-explicit-any */

  // The initial call to this function must pass in a JSON object
  if (!key && (typeof value !== "object" || Array.isArray(value))) {
    if (__DEV__) {
      throw new Error("The value must be an object!");
    }
    return "";
  }

  if (Array.isArray(value)) {
    return value.map((v) => formatURLQueryParams(v, `${key}[]`, options)).join("&");
  }
  if (typeof value === "object") {
    return Object.keys(value).reduce((result, nestedKey) => {
      const nestedValue = value[nestedKey];
      if (nestedValue == null || (Array.isArray(nestedValue) && nestedValue.length === 0)) {
        return result;
      }

      return (result += `${!result ? "" : "&"}${formatURLQueryParams(
        nestedValue,
        key ? `${key}[${nestedKey}]` : nestedKey,
        options,
      )}`);
    }, "");
  }

  // for values that have to be left unencoded to maintain correct format
  if (options.unencoded) {
    return `${key}=${value}`;
  }

  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

export async function safeParseResponseJson(response: Response) {
  if (response.headers.get("Content-Type")?.includes("application/json")) {
    try {
      // .clone ensures the original object is not changed because it appears that .json consumes the body
      return await response.clone().json();
    } catch {
      // We are not interested in catching this since the caller of this method really wants either the parsed response or nothing at all. The caller is responsible to know if
      // they want to throw in the event of an error doing the parse.
      // Gulp
    }
  }
  return undefined;
}
