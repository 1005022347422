import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { getQueryKeyAsArray } from "./util";

type MutationCallbacks<TData, TError, TVariables, TContext> = {
  onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext,
    queryClient: QueryClient,
  ) => Promise<unknown> | void;
  onError?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined,
    queryClient: QueryClient,
  ) => Promise<unknown> | void;
  onSettled?: (
    data: TData | undefined,
    error: TError | null,
    variables: TVariables,
    context: TContext | undefined,
    queryClient: QueryClient,
  ) => Promise<unknown> | void;
};

type CreateMutationOptions<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  "mutationKey" | "onError" | "onSuccess" | "onSettled"
> &
  MutationCallbacks<TData, TError, TVariables, TContext> & {
    baseMutationKey: string | string[];
  };

type MutationEnhancedOptions<TData, TError, TVariables, TContext> = MutationCallbacks<
  TData,
  TError,
  TVariables,
  TContext
>;

function buildMutationKey<TQueryKey>(baseQueryKey: string | string[]): TQueryKey {
  const baseQueryKeyAsArray = getQueryKeyAsArray(baseQueryKey);
  return [...baseQueryKeyAsArray] as TQueryKey;
}

export function createMutation<
  TVariables = void,
  TData = unknown,
  TError = unknown,
  TContext = unknown,
>({
  baseMutationKey,
  onError,
  onSuccess,
  onSettled,
  ...options
}: CreateMutationOptions<TData, TError, TVariables, TContext>) {
  const useEnhancedMutation = (
    enhancedOptions?: MutationEnhancedOptions<TData, TError, TVariables, TContext>,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      ...options,
      onError(error, variables, context) {
        enhancedOptions?.onError?.(error, variables, context as TContext | undefined, queryClient);
        onError?.(error, variables, context as TContext | undefined, queryClient);
      },
      onSuccess(data, variables, context) {
        enhancedOptions?.onSuccess?.(data, variables, context as TContext, queryClient);
        onSuccess?.(data, variables, context as TContext, queryClient);
      },
      onSettled(data, error, variables, context) {
        enhancedOptions?.onSettled?.(
          data,
          error,
          variables,
          context as TContext | undefined,
          queryClient,
        );
        onSettled?.(data, error, variables, context as TContext | undefined, queryClient);
      },
      mutationKey: getQueryKeyAsArray(baseMutationKey),
    });
  };

  useEnhancedMutation.mutationKey = buildMutationKey(baseMutationKey);

  return useEnhancedMutation;
}
