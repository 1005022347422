import * as SiteToSiteVPN from "../handlers/SiteToSiteVPN";
import { BaseDeviceSubProps } from "../types/BaseSubProps";
import LiveTool from "../types/LiveToolMsg";
import useCachedFormatter from "./useCachedFormatter";
import useLiveBroker from "./useLiveBroker";

export default function useSiteToSiteVPN(deviceId: string): LiveTool<SiteToSiteVPN.Result> {
  const msg = useLiveBroker<BaseDeviceSubProps, SiteToSiteVPN.Message>({
    type: "SiteToSiteVPNPoder",
    deviceId,
  });

  return useCachedFormatter(msg, SiteToSiteVPN.formatMsg);
}
