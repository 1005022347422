export enum Method {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
}

export enum Accept {
  json = "application/json",
  text = "text/html",
}

export enum ContentType {
  multipart = "multipart/form-data",
  urlEncoded = "application/x-www-form-urlencoded",
  json = "application/json",
}

export interface Headers {
  Accept?: "application/json" | "text/html";
  "Content-Type"?: "multipart/form-data" | "application/x-www-form-urlencoded" | "application/json";
  "User-Agent"?: string;
  "X-Mobile-Device-Id"?: string;
  "X-Requested-With"?: "XMLHttpRequest";
  "X-Dashboard-Mobile-API-Level"?: "8";
  "Meraki-Go-Proxy-Region"?: string;
}

export type ApiActionConfig = {
  method: `${Method}`;
  headers?: Headers;
  body?: string | FormData | URLSearchParams;
  queryParams?: object;
  unencodedQueryParams?: { [key: string]: string };
  jsonPResponse?: boolean;
  noHeaderFill?: boolean;
  textResponse?: boolean;
  enableTimeout?: boolean;
};
