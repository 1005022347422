import { Ssid } from "@meraki/shared/api";
import { isEmpty } from "lodash";
// @ts-expect-error TS(7016): Could not find a declaration file for module 'psl'... Remove this comment to see the full error message
import psl from "psl";
import { PureComponent } from "react";
import { SectionListData, StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { KEYBOARD_TYPE, RETURN_KEY, SPACING } from "~/constants/MkiConstants";
import SectionListHeader from "~/go/components/SectionListHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import withPendingComponent from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { hasDuplicateUrls } from "~/lib/ApplianceUtils";
import { deviceName } from "~/lib/DeviceUtils";
import { platformSelect } from "~/lib/PlatformUtils";
import { getSsidNameFromNum, hasDuplicateDomain } from "~/lib/SSIDUtils";
import {
  firewallLayerRulesOnSSIDsSelector,
  gxDeviceSelector,
  securityWebBlockingRules,
  ssidsSelector,
} from "~/selectors";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import Device_DeprecatedType from "~/shared/types/Device";
import { FirewallLayerRule } from "~/shared/types/FirewallLayerRule";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { WebBlockingRule } from "../types/NetworksTypes";

type ReduxProps = {
  ssids: SSID[];
  gxDevice?: Device_DeprecatedType;
  firewallLayerRules: Record<string, FirewallLayerRule[]>;
  webBlockingRules?: WebBlockingRule[] | null;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "BlockWebsite"> &
  ReduxProps &
  BasicActions;

type BlockWebsiteState = {
  url: string;
};

type Row = {
  [x: number]: {
    host: string;
  };
};
export class BlockWebsite extends PureComponent<Props, BlockWebsiteState> {
  static defaultProps = {
    ruleNumber: undefined,
  };

  static renderHeader = () => (
    <MkiText screenStyles={styles.headerStyle}>{I18n.t("BLOCK_CONTENT.SUBDOMAINS")}</MkiText>
  );

  static renderSectionHeader({ section }: SectionListData<Row>, ssids: Ssid[], isGX: boolean) {
    if (isGX) {
      return BlockWebsite.renderGXSectionHeader();
    }
    return BlockWebsite.renderGRSectionHeader(section, ssids);
  }

  static renderGXSectionHeader() {
    const sectionName = I18n.t("BLOCK_CONTENT.EVERYWHERE");
    return <SectionListHeader heading={sectionName} withHorizontalMargin />;
  }

  static renderGRSectionHeader(section: SectionListData<Row>, ssids: Ssid[]) {
    const ssidNum = parseInt(section.key!, 10);
    const sectionName = I18n.t("ON_SSID_SECTION_HEADER", {
      section_name: getSsidNameFromNum(ssidNum, ssids),
    });
    return <SectionListHeader heading={sectionName} withHorizontalMargin />;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      url: this.getUrlFromRuleNumber(),
    };
    this.setNavOptions(false);
  }

  isGX() {
    const { ssidNumber } = this.props;
    return ssidNumber === undefined;
  }

  componentDidUpdate() {
    const { url } = this.state;
    const host = this.getUrlFromRuleNumber();
    this.setNavOptions(!!url && url !== host);
  }

  getUrlFromRuleNumber(): string {
    const { ssidNumber, ruleNumber, firewallLayerRules, webBlockingRules } = this.props;
    if (ruleNumber === undefined) {
      return "";
    }

    if (this.isGX() && webBlockingRules) {
      return webBlockingRules[ruleNumber].value;
    } else if (firewallLayerRules && ssidNumber != null) {
      return firewallLayerRules[ssidNumber][ruleNumber].value;
    }

    return "";
  }

  setNavOptions(saveEnabled: boolean) {
    const { navigation, ruleNumber } = this.props;
    const { url } = this.state;
    const isEditing = ruleNumber != null && !isEmpty(url);

    navigation.setOptions({
      headerLeft: () => {
        if (!saveEnabled && isEditing) {
          return <CloseButton onPress={navigation.goBack} />;
        } else {
          return <CancelButton onPress={this.cancelChanges} />;
        }
      },
      headerRight: () => <SaveButton onPress={this.save} disabled={!saveEnabled} />,
    });
  }

  cancelChanges = () => {
    const { navigation, ruleNumber } = this.props;
    const { url } = this.state;
    if (ruleNumber === undefined && isEmpty(url)) {
      navigation.goBack();
    } else {
      showSaveWarning(this.save, navigation.goBack);
    }
  };

  save = () => {
    const { url } = this.state;

    if (!psl.isValid(url)) {
      showAlert(I18n.t("ERROR"), I18n.t("BLOCK_CONTENT.INVALID_URL", { url }));
      return;
    }

    if (this.isGX()) {
      this.saveGXBlockedHost(url);
    } else {
      this.saveSSIDBlockedHost(url);
    }
  };

  saveGXBlockedHost(url: string) {
    const {
      actions,
      ruleNumber,
      // @ts-expect-error TS(2339) FIXME: Property 'handleError' does not exist on type 'Rea... Remove this comment to see the full error message
      handleError,
      gxDevice,
      webBlockingRules,
      // @ts-expect-error TS(2339) FIXME: Property 'setReqPending' does not exist on type 'R... Remove this comment to see the full error message
      setReqPending,
      navigation,
    } = this.props;
    if (hasDuplicateUrls(url, webBlockingRules ?? undefined)) {
      showAlert(
        I18n.t("ERROR"),
        I18n.t("BLOCK_CONTENT.DUPLICATE_WEBSITE", { name: gxDevice ? deviceName(gxDevice) : "" }),
      );
      return;
    }

    const newRule = { policy: "deny", type: "host", value: url };
    const updatedRules = webBlockingRules ?? [];
    if (ruleNumber != null) {
      updatedRules[ruleNumber] = newRule;
    } else {
      updatedRules.push(newRule);
    }

    setReqPending(true);
    actions.updateGXLayer7FirewallRules(updatedRules).then(navigation.goBack, handleError);
  }

  saveSSIDBlockedHost(url: string) {
    const {
      actions,
      ssidNumber,
      ssids,
      ruleNumber,
      firewallLayerRules,
      // @ts-expect-error TS(2339) FIXME: Property 'handleError' does not exist on type 'Rea... Remove this comment to see the full error message
      handleError,
      // @ts-expect-error TS(2339) FIXME: Property 'setReqPending' does not exist on type 'R... Remove this comment to see the full error message
      setReqPending,
      navigation,
    } = this.props;
    if (ssidNumber == null || hasDuplicateDomain(url, ssidNumber, firewallLayerRules)) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      const ssidName = getSsidNameFromNum(parseInt(ssidNumber, 10), ssids);
      showAlert(I18n.t("ERROR"), I18n.t("BLOCK_CONTENT.DUPLICATE_WEBSITE", { name: ssidName }));
      return;
    }

    const newRule = { policy: "deny", type: "host", value: url };
    const updatedRules = [...firewallLayerRules[ssidNumber]];
    if (ruleNumber != null) {
      updatedRules[ruleNumber] = newRule;
    } else {
      updatedRules.push(newRule);
    }

    setReqPending(true);
    actions
      .updateSSIDLayer7FirewallRules(ssidNumber, updatedRules)
      .then(navigation.goBack, handleError);
  }

  renderRow = () => {
    const { url } = this.state;
    const keyboardType = platformSelect({
      android: KEYBOARD_TYPE.emailAddress,
      ios: KEYBOARD_TYPE.url,
    });
    return (
      <InputRow
        value={url}
        placeholder={I18n.t("BLOCK_CONTENT.BLOCK_WEBSITE_PLACEHOLDER")}
        onChangeText={(newValue) => this.setState({ url: newValue })}
        keyboardType={keyboardType}
        returnKeyType={RETURN_KEY.go}
        onSubmitEditing={this.save}
        testID="url input"
      >
        {I18n.t("BLOCK_CONTENT.WEBSITE_URL")}
      </InputRow>
    );
  };

  render() {
    const { ssids, ssidNumber } = this.props;

    const host = this.getUrlFromRuleNumber();
    const data = {
      [ssidNumber ?? 0]: [{ host }],
    };
    return (
      <KeyboardAwareScrollView style={styles.container}>
        <View style={styles.tableContainer}>
          <MkiTable<Row>
            data={data}
            ListHeaderComponent={BlockWebsite.renderHeader}
            renderSectionHeader={(section) =>
              // @ts-expect-error missing type for section
              BlockWebsite.renderSectionHeader(section, ssids, this.isGX())
            }
            renderRow={this.renderRow}
          />
        </View>
      </KeyboardAwareScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tableContainer: {
    flex: 1,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
    marginHorizontal: SPACING.default,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    ssids: ssidsSelector(state),
    gxDevice: gxDeviceSelector(state),
    firewallLayerRules: firewallLayerRulesOnSSIDsSelector(state),
    webBlockingRules: securityWebBlockingRules(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(BlockWebsite);
