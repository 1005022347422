import { Button, Heading } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useNetwork, useOrganization } from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import {
  NativeStackNavigationOptions,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";
import { Platform, Pressable, StyleSheet, TextStyle } from "react-native";
import LinearGradient from "react-native-linear-gradient";

export const DefaultScreenOptions: NativeStackNavigationOptions = {
  headerBackTitleVisible: false,
  headerTitleStyle: {
    fontFamily: Platform.OS === "ios" ? "ProximaSoft-Medium" : "proximasoft_medium",
  },
  headerTitleAlign: "center",
  headerTitle: "",
  headerTransparent: false,
  headerShadowVisible: false,
  headerShown: true,
};

export const ModalScreenOptions: NativeStackNavigationOptions = {
  ...DefaultScreenOptions,
  headerShadowVisible: true,
  presentation: "modal",
  animation: "slide_from_bottom",
};

export function useDefaultScreenOptions(): NativeStackNavigationOptions {
  const theme = useMagneticTheme();

  return {
    ...DefaultScreenOptions,
    headerStyle: { backgroundColor: theme.color.default.bg.base },
    headerTintColor: theme.color.default.text.medium.base,
    headerTitleStyle: {
      fontFamily: "Sharp Sans",
      color: theme.color.default.text.medium.base,
      fontWeight: `${theme.TypographyWeightBold}` as TextStyle["fontWeight"],
      fontSize: theme.SizeFontMd,
    },
  };
}

export function useTabScreenOptions(): NativeStackNavigationOptions {
  const theme = useMagneticTheme();
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();

  const organizationId = useCurrentOrganizationId();
  const { data: organization } = useOrganization({ organizationId });

  return {
    ...DefaultScreenOptions,
    headerShadowVisible: true,
    headerStyle: { backgroundColor: "transparent" },
    headerTitle: () => (
      <Box flex={1} flexDirection="row">
        <Heading size="h2" color="default.textIn.base">
          {organization?.name ?? ""}
        </Heading>
      </Box>
    ),
    headerTintColor: theme.color.default.textIn.base,
    headerTitleStyle: {
      fontFamily: "Sharp Sans",
      color: theme.color.default.textIn.base,
      fontWeight: `${theme.TypographyWeightBold}` as TextStyle["fontWeight"],
      fontSize: theme.SizeFontMd,
    },
    headerBackground: () => (
      <LinearGradient
        colors={[theme.color.brandAccent.icon.active, theme.color.brandAccent.icon.weak.active]}
        start={{ x: 0, y: 0.0 }}
        end={{ x: 0.5, y: 1 }}
        style={styles.background}
      />
    ),
    headerLeft: () => (
      <Pressable onPress={() => navigation.openDrawer()}>
        {({ pressed }) => (
          <Box opacity={pressed ? 0.5 : 1}>
            <Icon name="List" color="default.textIn.base" />
          </Box>
        )}
      </Pressable>
    ),
  };
}

export function useNetworkBackButton(): NativeStackNavigationOptions {
  const navigation = useNavigation<NativeStackNavigationProp<ParamListBase>>();

  const networkId = useCurrentNetworkId();

  const { data } = useNetwork({ networkId });

  const defaultScreenOptions = useDefaultScreenOptions();
  return {
    ...defaultScreenOptions,
    headerLeft: () => (
      <Button.Nav
        text={data?.name ?? ""}
        leadingIcon="CaretLeft"
        onPress={() => navigation.pop()}
      />
    ),
  };
}

const styles = StyleSheet.create({
  background: {
    height: "100%",
    width: "100%",
  },
});
