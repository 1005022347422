import { ReactNode } from "react";
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import { Theme, themeColors } from "~/lib/themeHelper";
import MkiSpinner from "~/shared/components/MkiSpinner";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

export enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  destructive = "destructive",
  tertiary = "tertiary",
}

export interface EmbeddedSpinnerProps {
  color: string;
  visible: boolean;
}

const EmbeddedSpinner: React.FC<EmbeddedSpinnerProps> = (props) => {
  const { color, visible } = props;
  if (!visible) {
    return null;
  }
  return (
    <View style={styles.spinner}>
      <MkiSpinner size="small" color={color} />
    </View>
  );
};
const buttonStyle = (buttonType: ButtonType, theme: Theme) => {
  let buttonTypeStyle;
  const colors = themeColors(theme);
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const darkButtonStyles = colors.roundedButtons?.[buttonType]?.button;
  switch (buttonType) {
    case ButtonType.secondary:
      buttonTypeStyle = [styles.secondaryButton, colors.roundedButtons?.secondary?.button];
      break;
    case ButtonType.destructive:
      buttonTypeStyle = styles.destructiveButton;
      break;
    case ButtonType.tertiary:
      buttonTypeStyle = styles.tertiaryButton;
      break;
    case ButtonType.primary:
    default:
      buttonTypeStyle = [{ backgroundColor: colors.navigation.primary }, styles.primaryButton];
      break;
  }
  return [buttonTypeStyle, darkButtonStyles];
};

const buttonTextStyle = (buttonType: ButtonType, theme: Theme) => {
  let buttonTypeStyle;
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const darkButtonStyles = themeColors(theme).roundedButtons?.[buttonType]?.buttonText;

  switch (buttonType) {
    case ButtonType.secondary:
      buttonTypeStyle = styles.secondaryButtonText;
      break;
    case ButtonType.destructive:
      buttonTypeStyle = styles.destructiveButtonText;
      break;
    case ButtonType.tertiary:
      buttonTypeStyle = styles.tertiaryButtonText;
      break;
    case ButtonType.primary:
    default:
      buttonTypeStyle = styles.primaryButtonText;
      break;
  }
  return [buttonTypeStyle, darkButtonStyles];
};

export interface ButtonContentProps {
  children: ReactNode;
  loading: boolean;
  textStyle: StyleProp<TextStyle>;
  spinnerColor: string;
}

const ButtonContent = (props: ButtonContentProps) => {
  const { loading, textStyle, children, spinnerColor } = props;
  if (loading) {
    return <EmbeddedSpinner visible={loading} color={spinnerColor} />;
  }
  return <MkiText screenStyles={textStyle}>{children}</MkiText>;
};

export const RoundedButton = (props: RoundedButtonProps) => {
  const {
    children,
    buttonType,
    onPress,
    screenStyles,
    containerStyles,
    disabled,
    fontSize,
    height,
    testID,
    loading,
    textStyles,
  } = props;
  const theme = useTheme();
  const buttonTextStyleArray = [...buttonTextStyle(buttonType as ButtonType, theme), textStyles];
  const buttonStyleArray = [
    ...buttonStyle(buttonType as ButtonType, theme),
    screenStyles,
    styles.basicButton,
  ];
  if (height) {
    buttonStyleArray.push({ height });
  }
  if (disabled || loading) {
    buttonStyleArray.push(styles.disabled);
  }
  if (fontSize) {
    buttonTextStyleArray.push({ fontSize });
  }

  const spinnerColor =
    buttonType === ButtonType.primary ? MkiColors.spinnerWhite : MkiColors.spinner;

  // TODO: Ultimately, we'd like this button to utilize
  // Material Design. TouchableOpacity gets us the correct
  // appearance, but doesn't get us the right touch performance.
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[styles.basicButtonContainer, containerStyles]}
      onPress={onPress}
      disabled={disabled || loading}
      testID={testID}
    >
      <View style={buttonStyleArray}>
        <ButtonContent
          textStyle={buttonTextStyleArray}
          loading={loading as boolean}
          spinnerColor={spinnerColor}
        >
          {children}
        </ButtonContent>
      </View>
    </TouchableOpacity>
  );
};

export interface RoundedButtonProps {
  onPress?: () => void;
  buttonType?: keyof typeof ButtonType;
  disabled?: boolean;
  loading?: boolean;
  testID?: string;
  fontSize?: number;
  height?: number;
  containerStyles?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  screenStyles?: StyleProp<ViewStyle>;
  children?: string;
}

RoundedButton.defaultProps = {
  buttonType: ButtonType.primary,
  disabled: false,
  loading: false,
  containerStyles: {},
  textStyles: {},
  screenStyles: {},
};

const styles = StyleSheet.create({
  primaryButton: {
    borderRadius: BUTTON_SIZING.borderRadius.default,
    paddingVertical: BUTTON_SIZING.paddingVertical.primary,
  },
  secondaryButton: {
    backgroundColor: MkiColors.primaryButtonLighter,
    borderColor: MkiColors.primaryButtonLightBorder,
    borderWidth: BUTTON_SIZING.borderWidth,
    borderRadius: BUTTON_SIZING.borderRadius.default,
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
  destructiveButton: {
    backgroundColor: MkiColors.badStatusBackground,
    borderColor: MkiColors.portAlertingColor,
    borderWidth: BUTTON_SIZING.borderWidth,
    borderRadius: BUTTON_SIZING.borderRadius.default,
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
  tertiaryButton: {
    backgroundColor: MkiColors.fullTransparent,
    paddingVertical: BUTTON_SIZING.paddingVertical.default,
  },
  basicButtonContainer: {
    paddingVertical: SPACING.small,
    alignSelf: "stretch",
  },
  basicButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  primaryButtonText: {
    textAlign: "center",
    color: MkiColors.whiteText,
  },
  secondaryButtonText: {
    textAlign: "center",
    color: MkiColors.primaryButton,
  },
  destructiveButtonText: {
    textAlign: "center",
    color: MkiColors.portAlertingColor,
  },
  tertiaryButtonText: {
    textAlign: "center",
    color: MkiColors.primaryButton,
  },
  disabled: {
    opacity: 0.5,
  },
  spinner: {
    paddingHorizontal: SPACING.small,
  },
});

export default RoundedButton;
