import {
  DOOR_RELATED_EVENTS_FAILURE,
  DOOR_RELATED_EVENTS_REQUEST,
  DOOR_RELATED_EVENTS_SUCCESS,
  SENSOR_EVENTS_FAILURE,
  SENSOR_EVENTS_REQUEST,
  SENSOR_EVENTS_SUCCESS,
  WATER_RELATED_EVENTS_FAILURE,
  WATER_RELATED_EVENTS_REQUEST,
  WATER_RELATED_EVENTS_SUCCESS,
} from "@meraki/shared/redux";

import { ApiAction, CALL_API } from "~/middleware/api";
import { Method } from "~/shared/types/RequestTypes";
import { SensorEventType } from "~/shared/types/SensorEvents";

interface EnvironmentalEventsQueryParams {
  sensorSerial: string;
  includedEventTypes: string[];
  perPage?: number;
  endingBefore: string;
  verbose: boolean;
}
const getEnvironmentalEvents = (
  networkId: string,
  sensorSerial: string,
  includedEventTypes: string[],
  actionTypes: readonly [string, string, string],
  perPage?: number,
  endingBefore?: string,
  listId?: string,
): ApiAction => {
  const queryParams: EnvironmentalEventsQueryParams = {
    sensorSerial,
    includedEventTypes,
    verbose: true,
    endingBefore: endingBefore || new Date(Date.now()).toISOString(),
    perPage,
  };
  if (perPage === undefined) {
    delete queryParams.perPage;
  }
  return {
    [CALL_API]: {
      types: actionTypes,
      endpoint: `/api/v1/networks/${networkId}/environmental/events`,
      config: {
        method: Method.get,
        queryParams,
      },
      meta: {
        sensorSerial,
        ...(listId && { listId }),
      },
    },
  };
};

export const getAllEnvironmentalEvents = (
  networkId: string,
  sensorSerial: string,
  listId: string,
) => {
  return getEnvironmentalEvents(
    networkId,
    sensorSerial,
    [
      SensorEventType.Door,
      SensorEventType.DoorTamper,
      SensorEventType.WaterDetection,
      SensorEventType.WaterCableConnection,
      SensorEventType.Humidity,
      SensorEventType.Temperature,
      SensorEventType.Co2,
      SensorEventType.AmbientNoise,
      SensorEventType.IaqIndex,
      SensorEventType.Pm25,
      SensorEventType.Tvoc,
      SensorEventType.ButtonRelease,
      SensorEventType.RealPower,
      SensorEventType.Current,
      SensorEventType.Voltage,
      SensorEventType.ApparentPower,
      SensorEventType.PowerFactor,
      SensorEventType.Frequency,
    ], // TODO: Change this to fetch for all environmental event types once we have the view supported
    [SENSOR_EVENTS_REQUEST, SENSOR_EVENTS_SUCCESS, SENSOR_EVENTS_FAILURE],
    10,
    undefined,
    listId,
  );
};

export const getRecentWaterRelatedEvents = (networkId: string, sensorSerial: string) => {
  return getEnvironmentalEvents(
    networkId,
    sensorSerial,
    [SensorEventType.WaterDetection, SensorEventType.WaterCableConnection],
    [WATER_RELATED_EVENTS_REQUEST, WATER_RELATED_EVENTS_SUCCESS, WATER_RELATED_EVENTS_FAILURE],
    5,
  );
};

export const getRecentDoorRelatedEvents = (networkId: string, sensorSerial: string) => {
  return getEnvironmentalEvents(
    networkId,
    sensorSerial,
    [SensorEventType.Door, SensorEventType.DoorTamper],
    [DOOR_RELATED_EVENTS_REQUEST, DOOR_RELATED_EVENTS_SUCCESS, DOOR_RELATED_EVENTS_FAILURE],
    5,
  );
};
