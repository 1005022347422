import z from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const ProductTypeSchema = z
  .union([
    z.literal("wireless"),
    z.literal("appliance"),
    z.literal("switch"),
    z.literal("camera"),
    z.literal("cellularGateway"),
    z.literal("sensor"),
    z.literal("systemsManager"),
  ] as const)
  .describe("ProductTypeSchema");

export const AlertSeverities = {
  info: "info",
  warning: "warning",
  error: "error",
  critical: "critical",
} as const;
export type AlertSeverity = (typeof AlertSeverities)[keyof typeof AlertSeverities];

const Scope = z.object({
  devices: z.array(
    z.object({
      url: z.string(),
      name: z.string(),
      productType: ProductTypeSchema,
      serial: z.string(),
      mac: z.string(),
      lldp: z
        .object({
          portId: z.string(),
        })
        .optional(),
      clients: z
        .array(
          z.object({
            mac: z.string(),
          }),
        )
        .optional(),
      order: z.number().optional(),
    }),
  ),
  applications: z.array(
    z.object({
      url: z.string(),
      name: z.string(),
    }),
  ),
  peers: z.array(
    z.object({
      url: z.string().nullable(),
      network: z.object({
        name: z.string(),
        id: z.string().nullable(),
      }),
    }),
  ),
});

const HealthAlertSchema = z.object({
  id: z.string(),
  category: z.string(),
  type: z.string(),
  severity: z.string().nullable(),
  scope: Scope,
});

export const HealthAlertsSchema = z.array(HealthAlertSchema).describe("HealthAlertsSchema");

const OrgHealthAlertSchema = HealthAlertSchema.extend({
  network: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .nullable(),
  startedAt: z.string(),
  dismissedAt: z.nullable(z.string()),
  scopeCategory: z.nullable(z.string()),
  troubleshooting: z.object({
    hasGuidance: z.boolean(),
  }),
  isVisibleOnlyToMerakiAdmins: z.boolean(),
  typeCode: z.string().optional(),
});

export const OrgHealthAlertsSchema = z
  .array(OrgHealthAlertSchema)
  .describe("OrgHealthAlertsSchema");

export type HealthAlert = z.infer<typeof HealthAlertSchema>;
export type HealthAlerts = z.infer<typeof HealthAlertsSchema>;
export type OrgHealthAlert = z.infer<typeof OrgHealthAlertSchema>;
export type OrgHealthAlerts = z.infer<typeof OrgHealthAlertsSchema>;

type OrgHealthAlertRequest = {
  organizationId?: string;
};

type OrgHealthAlertParams = OrgHealthAlertRequest & {
  networkId?: string;
};

function buildUrl({ organizationId }: OrgHealthAlertRequest) {
  return `/api/v1/organizations/${organizationId}/health/alerts`;
}

function fetchOrgHealthAlert({
  organizationId,
  networkId,
}: OrgHealthAlertParams): Promise<OrgHealthAlerts> {
  if (!organizationId) {
    throw new Error("Undefined organization and/or network id when fetching health alerts");
  }
  return request(OrgHealthAlertsSchema, "GET", buildUrl({ organizationId }), {
    queryParams: {
      networkIds: [networkId],
      isMerakiMobileClientNeedsAlphaResponse: true,
    },
  });
}

export const useOrgHealthAlert = createQuery<OrgHealthAlertParams, OrgHealthAlerts>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: (variables) => fetchOrgHealthAlert(variables),
  requiredVariables: ["organizationId"],
});
