const applianceKeys = {
  appliances: ["appliances"],
  ports: ["ports"],
  settings: ["settings"],
  firewallRules: ["firewallRules"],
  uplinkSettings: ["uplinkSettings"],
  staticDelegatedPrefixes: ["staticDelegatedPrefixes"],
  delegatedPrefixes: ["delegatedPrefixes"],
  vlanAssignments: ["vlanAssignments"],
} as const;

export const appliancePortKeys = {
  port: (networkId: string, portId: string) =>
    [networkId, ...applianceKeys.appliances, ...applianceKeys.ports, portId] as const,
  ports: (networkId: string) =>
    [networkId, ...applianceKeys.appliances, ...applianceKeys.ports] as const,
};

export const applianceSettingsKeys = {
  settings: (networkId?: string) =>
    [networkId, ...applianceKeys.appliances, ...applianceKeys.settings] as const,
};

export const applianceFirewallRulestKeys = {
  orgFirewallRules: (organizationId?: string) =>
    [organizationId, ...applianceKeys.appliances, ...applianceKeys.firewallRules] as const,
  networkFirewallRules: (networkId?: string) =>
    [networkId, ...applianceKeys.appliances, ...applianceKeys.firewallRules] as const,
};

export const applianceUplinkSettingsKeys = {
  uplinkSettings: (serial: string) =>
    [...applianceKeys.appliances, serial, ...applianceKeys.uplinkSettings] as const,
};

export const applianceStaticDelegatedPrefixesKeys = {
  prefix: (prefixId: string, networkId?: string) =>
    [
      networkId,
      ...applianceKeys.appliances,
      ...applianceKeys.staticDelegatedPrefixes,
      prefixId,
    ] as const,
  prefixes: (networkId?: string) =>
    [networkId, ...applianceKeys.appliances, ...applianceKeys.staticDelegatedPrefixes] as const,
};

export const applianceDelegatedPrefixesKeys = {
  prefixes: (serial?: string) =>
    [...applianceKeys.appliances, serial ?? "unknown", ...applianceKeys.delegatedPrefixes] as const,
  vlanAssignments: (serial?: string) =>
    [
      ...applianceKeys.appliances,
      serial ?? "unknown",
      ...applianceKeys.delegatedPrefixes,
      ...applianceKeys.vlanAssignments,
    ] as const,
};

export default applianceKeys;
