import { I18n } from "@meraki/core/i18n";
import { useDeviceStatus } from "@meraki/react-live-broker";
import { useNavigation } from "@react-navigation/native";
import { Dispatch, SetStateAction } from "react";

import { Device } from "~/api/schemas/Device";
import HardwareDetailsHeader from "~/go/components/HardwareDetailsHeader";
import { showAlert } from "~/lib/AlertUtils";
import useActions from "~/shared/hooks/redux/useActions";
import Device_DeprecatedType from "~/shared/types/Device";
interface HardwareDetailsScreenWrapperProps {
  device: Device_DeprecatedType;
  setReqPending: Dispatch<SetStateAction<boolean>>;
  ngData?: Device[];
}

const HardwareDetailsHeaderWrapper = ({
  device,
  setReqPending,
  ngData,
}: HardwareDetailsScreenWrapperProps) => {
  const isLive = useDeviceStatus(device.serial);
  const actions = useActions();
  const navigation = useNavigation();

  const saveHardwareDeviceName = async (name: any) => {
    setReqPending(true);
    try {
      await actions.updateDevice(device.serial, { name });
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }
    setReqPending(false);
  };

  const showStaticIPConfiguration = () => {
    navigation.navigate("StaticIPConfiguration", {
      serialNumber: device.serial,
    });
  };

  return (
    <HardwareDetailsHeader
      saveName={saveHardwareDeviceName}
      isLive={isLive}
      device={device}
      ngData={ngData}
      showStaticIPConfiguration={showStaticIPConfiguration}
    />
  );
};

export default HardwareDetailsHeaderWrapper;
