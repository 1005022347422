import { I18n } from "@meraki/core/i18n";
import { TRAFFIC_SHAPING_USAGE_INCREMENTS } from "@meraki/go/traffic-shaping";
import Slider from "@react-native-community/slider";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

export const LIMIT_STRING_KEYS = [
  "SET_USAGE.LIMIT_EXAMPLES.STEP_0",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_1",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_2",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_3",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_4",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_5",
  "SET_USAGE.LIMIT_EXAMPLES.STEP_6",
] as const;

interface GroupPolicySliderProps {
  onValueChange: (newLimitIndex: number) => void;
  limitIndex: number;
  limitSpeed: string;
}

export const GroupPolicySlider = (props: GroupPolicySliderProps) => {
  const { onValueChange, limitIndex, limitSpeed } = props;
  return (
    <View style={styles.container}>
      <View style={styles.limitationsHeader}>
        <MkiText textStyle="secondary">{I18n.t("CONFIGURE_IPSK.LIMITATIONS")}</MkiText>
      </View>
      <View style={styles.limitationsContainer}>
        <MkiText textStyle="smallSecondary">
          {I18n.t("CONFIGURE_IPSK.LIMITATIONS_SUBHEADER")}
        </MkiText>
      </View>
      <Slider
        maximumValue={TRAFFIC_SHAPING_USAGE_INCREMENTS}
        onValueChange={onValueChange}
        step={1}
        value={limitIndex}
        style={styles.slider}
        minimumTrackTintColor={MkiColors.goPurple}
        testID="THROTTLE_PASSWORD.SLIDER"
      />
      <View style={styles.limitationsContainer}>
        <MkiText style={styles.limitationsContainer}>
          {I18n.t(LIMIT_STRING_KEYS[Math.min(limitIndex, 6)], { limit: limitSpeed })}
        </MkiText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  limitationsHeader: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
  limitationsContainer: {
    paddingHorizontal: SPACING.default,
    paddingBottom: SPACING.small,
  },
  slider: {
    marginHorizontal: SPACING.small,
  },
});
