import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Positive({ width, height }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 340 300" width={width} height={height}>
      <Rect
        fill={theme.color.positive.icon.weak.base}
        height="160"
        opacity=".2"
        rx="18"
        transform="rotate(25 114.619 16)"
        width="36"
        x="114.619"
        y="16"
      />
      <Rect
        fill={theme.color.positive.icon.weak.base}
        height="120"
        opacity=".5"
        rx="18"
        transform="rotate(25 281.714 89)"
        width="36"
        x="281.714"
        y="89"
      />
      <Rect
        fill={theme.color.positive.icon.weak.base}
        height="64.254"
        opacity=".3"
        rx="18"
        transform="rotate(25 59.155 198)"
        width="36"
        x="59.155"
        y="198"
      />
      <Path
        d="M254.5 160c0 46.669-37.832 84.5-84.5 84.5S85.5 206.669 85.5 160c0-46.668 37.832-84.5 84.5-84.5s84.5 37.832 84.5 84.5Z"
        fill={theme.color.positive.icon.base}
      />
      <Path
        d="M208.627 132.205a8.666 8.666 0 0 1 .002 12.256l-45.486 45.499a8.668 8.668 0 0 1-12.256.003l-19.513-19.499a8.667 8.667 0 0 1 12.252-12.261l13.384 13.375 39.361-39.372a8.665 8.665 0 0 1 12.256-.001Z"
        fill={theme.color.positive.iconIn.base}
      />
    </Svg>
  );
}
