export const CLIENTS_SEARCH_KEY = "CLIENTS";
export const CLIENTS_SEARCH_FIELDS = ["description", "mac", "ip", "os"];
export const NETWORKS_SEARCH_KEY = "NETWORKS";
export const NETWORKS_SEARCH_FIELDS = ["name"];
export const NODES_SEARCH_KEY = "NODES";
export const NODES_SEARCH_FIELDS = ["name", "serial", "lanIp", "mac", "model", "tags"];
export const SSIDS_SEARCH_KEY = "SSIDS";
export const SSIDS_SEARCH_FIELDS = ["name", "enabled"];
export const INVENTORY_SEARCH_KEY = "INVENTORY";
export const INVENTORY_SEARCH_FIELDS = ["mac", "serial", "model"];
export const SM_CLIENTS_SEARCH_KEY = "SM_CLIENTS";
export const SM_CLIENTS_SEARCH_FIELDS = ["name", "system_type", "tags"];
export const FAILED_WIRELESS_CLIENTS_SEARCH_KEY = "FAILED_WIRELESS_CLIENTS";
export const FAILED_WIRELESS_CLIENTS_SEARCH_FIELDS = [
  "apName",
  "apMac",
  "clientName",
  "clientMac",
  "failureStep",
  "ssidName",
  "serial",
];
export const FLOOR_PLAN_SEARCH_FIELDS = ["name"];
export const SENSORS_KEY = "SENSORS";
export const SENSORS_SEARCH_FIELDS = ["name", "serial", "mac", "model"];
export const SWITCH_PORTS_KEY = "SWITCH_PORTS";
export const ORGANIZATION_KEY = "ORGANIZATIONS";
export const ORGANIZATION_FIELDS = ["name"];

export const ADMIN_SEARCH_FIELDS = ["name", "email"];
export const NETWORK_SEARCH_FIELDS = ["name"];

export const VPN_KEY = "VPNS";

export const CLIENTS_LIST_SEARCH_KEY = "CLIENTS_LIST";
export const CLIENTS_LIST_SEARCH_FIELDS = ["description", "mac", "ip", "os", "name", "tags"];

export const ALERTS_LIST_SEARCH_FIELDS = ["description"];

export const CLIENT_VPN_SEARCH_FIELDS = ["name", "id", "email"];
