import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, ViewStyle } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { GoSharedScreensPropMap } from "~/go/navigation/Types";
import { normalizedFontSize } from "~/lib/themeHelper";
import { getWhatsNew } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import NoDataFooter from "~/shared/components/NoDataFooter";
import SummaryList from "~/shared/components/SummaryList";
import { CloseButton } from "~/shared/navigation/Buttons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { RootState } from "~/shared/types/Redux";
import { WhatsNewBreakdown, WhatsNewFeatures } from "~/shared/types/WhatsNewTypes";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  whatsNew: WhatsNewFeatures;
};

type Props = ForwardedNativeStackScreenProps<GoSharedScreensPropMap, "WhatsNewList"> &
  ReduxProps &
  BasicActions;

interface WhatsNewRowData {
  label: string;
  leftStyle: ViewStyle;
  rightStyle: ViewStyle;
  value?: React.ReactElement;
  onPress: () => void;
}

export class WhatsNewListScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;
    navigation.setOptions({
      headerRight: () => <CloseButton onPress={navigation.goBack} />,
    });
  }

  static getValueText(breakdown: WhatsNewBreakdown) {
    if (breakdown.read) {
      return undefined;
    }

    return <MkiText textStyle="activeTab">{I18n.t("WHATS_NEW.NEW")}</MkiText>;
  }

  showDetail = (feature: string, breakdown: WhatsNewBreakdown) => {
    return () => {
      const { navigation, actions } = this.props;
      const { featureScreen, presentedModally, message, showDoc, title, screenProps } = breakdown;

      actions.readNewFeature(feature);
      navigation.navigate("WhatsNewDetail", {
        title,
        featureScreen,
        presentedModally,
        message,
        showDoc,
        screenProps,
      });
    };
  };

  getRows = () => {
    const { whatsNew } = this.props;

    const rows: WhatsNewRowData[] = [];
    for (const feature in whatsNew) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const breakdown = whatsNew[feature];
      rows.push({
        label: breakdown.title,
        leftStyle: styles.rowLeftStyle,
        rightStyle: styles.rowRightStyle,
        value: WhatsNewListScreen.getValueText(breakdown),
        onPress: this.showDetail(feature, breakdown),
      });
    }

    return rows;
  };

  renderRow = (rowData: WhatsNewRowData, rowID: number) => (
    <DisclosureRow
      key={rowID}
      leftStyle={rowData.leftStyle}
      rightStyle={rowData.rightStyle}
      value={rowData.value}
      onPress={rowData.onPress}
    >
      {rowData.label}
    </DisclosureRow>
  );

  render() {
    const rows = this.getRows();
    return (
      <ScrollView testID="WHATS_NEW_LIST_SCREEN">
        <SummaryList<WhatsNewRowData>
          heading={I18n.t("WHATS_NEW.HIGHLIGHTED_FEATUERS")}
          headingTextStyle={styles.heading}
          contentRows={rows}
          customRenderRow={this.renderRow}
          disableBottomBorder
          hasSeparators
        />
        <NoDataFooter data={rows} noDataString={I18n.t("WHATS_NEW.NO_FEATURE")} />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    fontSize: normalizedFontSize(19),
    color: MkiColors.primaryButton,
  },
  rowLeftStyle: {
    width: "80%",
  },
  rowRightStyle: {
    width: "20%",
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    whatsNew: getWhatsNew(state),
  };
}

export default connect(mapStateToProps, basicMapDispatchToProps)(WhatsNewListScreen);
