import { I18n } from "@meraki/core/i18n";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

import { BUTTON_SIZING, SPACING } from "~/constants/MkiConstants";
import RoundedButton from "~/go/components/RoundedButton";
import MerakiIcon from "~/shared/components/icons";
import MkiText from "~/shared/components/MkiText";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  onRestartPress: () => void;
};

const CableTestPass = memo(function CableTestPass(props: Props) {
  const { onRestartPress } = props;
  const colors = useThemeColors();

  return (
    <View style={styles.container}>
      <MerakiIcon name="check-circle" size="xl" color={colors.status.online.color} />
      <MkiText screenStyles={styles.infoText} textStyle="default">
        {I18n.t("CABLE_TEST.PASS.DESCRIPTION")}
      </MkiText>
      <View style={styles.buttonContainer}>
        <RoundedButton
          buttonType="secondary"
          onPress={onRestartPress}
          screenStyles={styles.buttonStyle}
        >
          {I18n.t("LIVE_TOOLS.RETEST")}
        </RoundedButton>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: "center",
    marginVertical: SPACING.default,
  },
  buttonStyle: {
    borderRadius: BUTTON_SIZING.borderRadius.large,
    paddingHorizontal: SPACING.extraLarge,
  },
  container: {
    alignItems: "center",
    paddingTop: SPACING.default,
  },
  infoText: {
    margin: SPACING.default,
    textAlign: "center",
  },
});

export default CableTestPass;
