import { Screen } from "@meraki/magnetic/layout";
import { WebView } from "react-native-webview";

import { isWeb } from "~/lib/PlatformUtils";
import { LoadingScreen } from "~/migrationZone/shared/nav/screens/LoadingScreen";

export function OpenSourceLicensesScreen() {
  return (
    <Screen.View>
      <WebView
        startInLoadingState
        source={{ uri: "https://web.meraki-go.com/licenses" }}
        newWindow={isWeb()}
        renderLoading={() => <LoadingScreen />}
        testID="openSourceLicensesWebView"
      />
    </Screen.View>
  );
}
