import { isEmpty } from "lodash";
import { StyleProp, StyleSheet, TextProps, TextStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { LETTER_SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/enterprise/constants/Fonts";
import { appSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import { useTheme } from "~/shared/hooks/useTheme";

export interface HiddenPWTextTextProps extends TextProps {
  psk?: string;
  showPassword: boolean;
  screenStyle?: StyleProp<TextStyle>;
}

const HiddenPWText = ({ psk, showPassword, screenStyle }: HiddenPWTextTextProps) => {
  const colors = themeColors(useTheme());
  if (isEmpty(psk)) {
    return null;
  }

  const passwordTextDark = {
    color: appSelect({
      go: MkiColors.textColor,
      enterprise: colors.text.default.color,
    }),
  };

  const passwordTextLight = {
    color: appSelect({
      go: MkiColors.secondaryTextColor,
      enterprise: colors.text.default.color,
    }),
  };

  const textStyle = showPassword ? passwordTextDark : passwordTextLight;
  const password = showPassword ? psk : Array(9).join("●");

  const passwordText = appSelect({
    go: styles.passwordTextGo,
    enterprise: styles.passwordTextEnterprise,
  });
  return (
    <MkiText
      selectable={showPassword}
      textStyle="monospace"
      screenStyles={[passwordText, screenStyle, textStyle]}
      ellipsizeMode="tail"
    >
      {password}
    </MkiText>
  );
};

const styles = StyleSheet.create({
  passwordTextEnterprise: {
    fontSize: FONT_SIZES.default,
  },
  passwordTextGo: {
    fontSize: normalizedFontSize(16),
    letterSpacing: LETTER_SPACING.allCaps,
    backgroundColor: MkiColors.lightGrayBackground,
    padding: 6,
    borderRadius: 6,
    overflow: "hidden",
  },
});

export default HiddenPWText;
