import { I18n } from "@meraki/core/i18n";
import { Box } from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import Color from "color";
import { ReactNode } from "react";
import {
  KeyboardAvoidingView,
  Modal as RNModal,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { Button, ButtonProps } from "../Button/Button";
import { Heading } from "../Heading/Heading";

type BaseModalProps = {
  visible: boolean;
  title?: string;
  children: ReactNode;

  okText?: string;
  onOk?: () => void;
  okButtonProps?: Pick<ButtonProps, "loading">;
  cancelText?: string;
  onCancel?: () => void;
};

export type ModalProps =
  | ({ dismissOnOverlayPress?: true; onClose: () => void } & BaseModalProps)
  | ({ dismissOnOverlayPress: false; onClose?: never } & BaseModalProps);

function getContainerStyle(theme: Theme): StyleProp<ViewStyle> {
  return {
    backgroundColor: theme.color.default.bg.weak.base,
    paddingHorizontal: theme.SizeMd,
    paddingVertical: theme.SizeSm,
    borderRadius: theme.SizeRadiusBorderMd,
    gap: theme.SizeSm,

    // Shadow Stuffs...
    shadowColor: "#000",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 8,
    shadowOpacity: 0.2,
    elevation: 4,
  };
}

export function Modal({
  visible,
  title,
  children,
  onClose,
  okButtonProps,
  okText,
  onOk,
  cancelText,
  onCancel,
  dismissOnOverlayPress = true,
}: ModalProps) {
  const theme = useMagneticTheme();

  const handleOverlayPress = () => {
    if (!dismissOnOverlayPress) return;

    onClose?.();
  };

  const overlayBackgroundColor = Color(theme.color.default.bg.strong.base).alpha(0.8).hexa();

  return (
    <RNModal visible={visible} animationType="fade" transparent onRequestClose={handleOverlayPress}>
      <Pressable onPress={handleOverlayPress} style={StyleSheet.absoluteFill}>
        <View style={{ ...StyleSheet.absoluteFillObject, backgroundColor: overlayBackgroundColor }}>
          <Box {...StyleSheet.absoluteFillObject}>
            <KeyboardAvoidingView
              style={styles.keyboardAvoidingView}
              behavior={Platform.OS === "ios" ? "padding" : undefined}
            >
              <Pressable style={{ width: "90%" }}>
                <View style={getContainerStyle(theme)}>
                  {!!title && (
                    <Box>
                      <Heading size="h2">{title}</Heading>
                    </Box>
                  )}
                  <Box>{children}</Box>
                  {(onCancel || onOk) && (
                    <Box flexDirection="row" justifyContent="flex-end" alignItems="center" gap="sm">
                      {onCancel && (
                        <Button
                          text={cancelText ?? I18n.t("CANCEL")}
                          onPress={onCancel}
                          kind="tertiary"
                        />
                      )}
                      {onOk && !!okText && (
                        <Button {...okButtonProps} text={okText} onPress={onOk} />
                      )}
                    </Box>
                  )}
                </View>
              </Pressable>
            </KeyboardAvoidingView>
          </Box>
        </View>
      </Pressable>
    </RNModal>
  );
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
});
