import {
  ALERT_SETTINGS_FAILURE,
  ALERT_SETTINGS_REQUEST,
  ALERT_SETTINGS_SUCCESS,
  SET_ALERT_SETTINGS_FAILURE,
  SET_ALERT_SETTINGS_REQUEST,
  SET_ALERT_SETTINGS_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState } from "~/selectors";
import { AlertSetting } from "~/shared/types/AlertTypes";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export const fetchAlertSettings =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [ALERT_SETTINGS_REQUEST, ALERT_SETTINGS_SUCCESS, ALERT_SETTINGS_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/alerts/settings`,
        config: { method: Method.get },
        meta: { networkId },
      },
    });
  };

export const updateAlertSettings =
  (alerts: AlertSetting[]): AppThunk<Promise<ApiResponseAction<any>>> =>
  async (dispatch, getState) => {
    const state = getState();
    const networkId = currentNetworkState(state);

    // Bug on backend which wipes all MT alert profiles if we just send up the diff
    // Change that the introduced the bug: https://gerrit.ikarem.io/c/manage/+/88164
    // Issue to fix the bug: https://jira.ikarem.io/browse/MT-1941
    const sensorAlerts =
      (await dispatch(fetchAlertSettings()).then(
        (apiMiddlewareResult: any) =>
          apiMiddlewareResult?.response?.alerts?.filter(
            (alert: any) => alert.type === "sensorAlert",
          ),
      )) || [];

    return dispatch(
      wrapApiActionWithCSRF({
        types: [SET_ALERT_SETTINGS_REQUEST, SET_ALERT_SETTINGS_SUCCESS, SET_ALERT_SETTINGS_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/alerts/settings`,
        config: {
          method: Method.put,
          body: JSON.stringify({ alerts: [...alerts, ...sensorAlerts] }),
        },
        meta: { networkId },
      }),
    );
  };
