import { View } from "react-native";
import Svg, { Path } from "react-native-svg";

export const CheckedCircleFilled = ({ color, style }: any) => {
  return (
    <View style={style}>
      <Svg height={16} viewBox="0 96 960 960" width={16}>
        <Path
          fill={color}
          d="m421 758 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Z"
        />
      </Svg>
    </View>
  );
};

export default CheckedCircleFilled;
