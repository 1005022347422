import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

type Props = {
  size?: number;
};

function ButtonIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrower } = props;
  const { stroke } = useThemeColors().icon;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18.3607 6H5.63955C5.13771 6 4.7309 6.46494 4.7309 7.03846V12.2308C4.7309 12.8043 5.13771 13.2692 5.63955 13.2692H18.3607C18.8626 13.2692 19.2694 12.8043 19.2694 12.2308V7.03846C19.2694 6.46494 18.8626 6 18.3607 6Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M21.5455 13.2693H2.45455C1.92736 13.2693 1.5 13.5276 1.5 13.8462V16.7308C1.5 17.0495 1.92736 17.3078 2.45455 17.3078H21.5455C22.0727 17.3078 22.5 17.0495 22.5 16.7308V13.8462C22.5 13.5276 22.0727 13.2693 21.5455 13.2693Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default ButtonIcon;
