import { mkiconf, navigation } from "@meraki/shared/redux";
import { combineReducers } from "redux";

import api from "~/api/reducers/api";
import admins from "~/reducers/admins";
import alertSettings from "~/reducers/alertSettings";
import auth from "~/reducers/auth";
import cameras from "~/reducers/cameras";
import clients from "~/reducers/clients";
import community from "~/reducers/community";
import deviceClients from "~/reducers/deviceClients";
import deviceOnboarding from "~/reducers/deviceOnboarding";
import devices from "~/reducers/devices";
import documentation from "~/reducers/documentation";
import entities from "~/reducers/entities";
import events from "~/reducers/events";
import floorPlans from "~/reducers/floorPlans";
import gxPorts from "~/reducers/gxPorts";
import inventory from "~/reducers/inventory";
import liveBroker from "~/reducers/liveBroker";
import loading from "~/reducers/loading";
import localeUsage from "~/reducers/localeUsage";
import locationAnalytics from "~/reducers/locationAnalytics";
import mspOrgData from "~/reducers/mspOrgData";
import networks from "~/reducers/networks";
import networkUseblocks from "~/reducers/networkUseblocks";
import nfos from "~/reducers/nfos";
import nodeGroups from "~/reducers/nodeGroups";
import onboarding from "~/reducers/onboarding";
import pagination from "~/reducers/pagination";
import preferences from "~/reducers/preferences";
import registration from "~/reducers/registration";
import remoteConfig from "~/reducers/remoteConfig";
import search from "~/reducers/search";
import sensorAlertProfiles from "~/reducers/sensorAlertProfiles";
import sensorEvents from "~/reducers/sensorEvents";
import sensorRoles from "~/reducers/sensorRoles";
import sensors from "~/reducers/sensors";
import sensorStats from "~/reducers/sensorStats";
import speedTest from "~/reducers/speedTest";
import ssids from "~/reducers/ssids";
import ssidUseblocks from "~/reducers/ssidUseblocks";
import supportCases from "~/reducers/supportCases";
import switchPorts from "~/reducers/switchPorts";
import systemsManager from "~/reducers/systemsManager";
import timespans from "~/reducers/timespans";
import umbrella from "~/reducers/umbrella";
import uplink from "~/reducers/uplink";
import userData from "~/reducers/userData";
import videoExport from "~/reducers/videoExport";
import vpns from "~/reducers/vpns";
import warmSpareSettings from "~/reducers/warmSpareSettings";
import whatsNew from "~/reducers/whatsNew";
import wirelessHealth from "~/reducers/wirelessHealth";

export const rootReducer = combineReducers({
  admins,
  alertSettings,
  api,
  auth,
  cameras,
  clients,
  community,
  deviceClients,
  deviceOnboarding,
  devices,
  documentation,
  entities,
  events,
  floorPlans,
  gxPorts,
  inventory,
  liveBroker,
  loading,
  localeUsage,
  locationAnalytics,
  mkiconf,
  mspOrgData,
  navigation,
  networkUseblocks,
  networks,
  nfos,
  nodeGroups,
  onboarding,
  pagination,
  preferences,
  registration,
  remoteConfig,
  search,
  sensorAlertProfiles,
  sensors,
  sensorEvents,
  sensorRoles,
  sensorStats,
  speedTest,
  ssidUseblocks,
  ssids,
  supportCases,
  switchPorts,
  systemsManager,
  timespans,
  umbrella,
  uplink,
  userData,
  vpns,
  warmSpareSettings,
  whatsNew,
  wirelessHealth,
  videoExport,
});

export type RootReducer = typeof rootReducer;
