import { config } from "@meraki/core/dev-config";
import { createSelector } from "reselect";

import NFOs from "~/constants/NFOs";
import { getCurrentOrganization, getNFOsState } from "~/selectors/getters";
import { getNetworkNodeGroupIds } from "~/selectors/nodeGroups";
import { OrganizationNFO } from "~/shared/types/Organizations";

const EMPTY_ORG_NFOS: OrganizationNFO[] = [];

function addNFOOverrides(nfos: NFOs[]): Set<NFOs> {
  const nfoOverrides = Object.keys(config.nfoOverrides);
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const nfoKeyMapper = (key: string) => NFOs[key] ?? key;

  const nfoOverridesAllowed = nfoOverrides
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter((nfo) => config.nfoOverrides[nfo])
    .map(nfoKeyMapper);
  const nfoOverridesDenied = nfoOverrides
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .filter((nfo) => !config.nfoOverrides[nfo])
    .map(nfoKeyMapper);

  return new Set(
    nfos.concat(nfoOverridesAllowed).filter((nfo) => !nfoOverridesDenied.includes(nfo)),
  );
}

const getOrgNFOs = createSelector(getNFOsState, getCurrentOrganization, (nfos, orgId) => {
  if (orgId == null) {
    return EMPTY_ORG_NFOS;
  }

  return nfos[orgId]?.filter(({ enabled }: any) => enabled) ?? EMPTY_ORG_NFOS;
});

const getNetworkNFOs = createSelector(
  getOrgNFOs,
  getNetworkNodeGroupIds,
  (orgNFOs, nodeGroupIds) => {
    if (!nodeGroupIds) {
      return orgNFOs;
    }

    // If we are currently on a network/locale with the required networkIds
    return orgNFOs.filter(
      ({ org_wide, network_ids }: any) =>
        org_wide || network_ids.some((ngId: any) => nodeGroupIds.includes(ngId)),
    );
  },
);

export const getNFOs = createSelector(getNetworkNFOs, (nfos) => {
  const nfoHashes = nfos.map(({ feature }: any) => feature) as NFOs[];
  return addNFOOverrides(nfoHashes);
});

export const getHasAlphaFeatures = createSelector(getNFOs, (nfos): boolean =>
  nfos.has(NFOs.hasMobileAlphaFeatures),
);

export const getHasBetaFeatures = createSelector(
  getNFOs,
  (nfos): boolean => nfos.has(NFOs.hasMobileBetaFeatures) || nfos.has(NFOs.hasMobileAlphaFeatures),
);
