import { storage } from "@meraki/core/secure-storage";
import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { createContext, ReactNode, useCallback, useContext, useState } from "react";

const DEBUGGING_TOOLS_STORAGE_KEY = "@meraki/shared/debugging-tools";

type DebuggingToolsContextValue = {
  navigationRef?: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
  enabled: boolean;
  toggle: (enabled: boolean) => void;
};
export const DebuggingToolsContext = createContext<DebuggingToolsContextValue>({
  enabled: false,
  toggle: () => undefined,
});

export interface DebuggingToolsProviderProps {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
  children: ReactNode;
}

export function DebuggingToolsProvider({ navigationRef, children }: DebuggingToolsProviderProps) {
  const [debuggingToolsEnabled, setDebuggingToolsEnabled] = useState(
    storage.getBoolean(DEBUGGING_TOOLS_STORAGE_KEY) ?? false,
  );

  const toggleDebuggingTools = useCallback(
    (enabled: boolean) => {
      storage.set(DEBUGGING_TOOLS_STORAGE_KEY, enabled);
      setDebuggingToolsEnabled(enabled);
    },
    [setDebuggingToolsEnabled],
  );

  return (
    <DebuggingToolsContext.Provider
      value={{
        navigationRef,
        enabled: debuggingToolsEnabled,
        toggle: toggleDebuggingTools,
      }}
    >
      {children}
    </DebuggingToolsContext.Provider>
  );
}

export function useDebuggingTools() {
  const debuggingTools = useContext(DebuggingToolsContext);

  return debuggingTools;
}
