import {
  ChannelUtilizationPerInterval,
  ChannelUtilizationRequestParams,
  useChannelUtilization,
} from "@meraki/shared/api";
import { oneHour, oneMonth, tenMinutes } from "@meraki/shared/redux";
import { isEmpty } from "lodash";

import {
  ChannelUtilizationDataPoint,
  ChannelUtilizationStats,
} from "~/shared/types/WirelessHealth";

interface UtilizationsByBand {
  [key: string]: ChannelUtilizationDataPoint[];
}
const wifiUtilizationsByBand: UtilizationsByBand = {
  "2.4": [],
  "5": [],
};

export const filterChannelUtilizationByDevice = (
  utilizations?: ChannelUtilizationPerInterval[],
) => {
  if (!utilizations) {
    return;
  }
  utilizations.map((utilization) => {
    utilization.byBand.map(({ band, total, wifi, nonWifi }) => {
      if (band in wifiUtilizationsByBand) {
        wifiUtilizationsByBand[band].push({
          utilization: total.percentage,
          wifi: wifi.percentage,
          non_wifi: nonWifi.percentage,
          start_ts: utilization.startTs,
          end_ts: utilization.endTs,
        });
      }
    });
  });
  const reducedResponse: ChannelUtilizationStats = {
    mac: utilizations[0].mac,
    serial: utilizations[0].serial,
    wifi0: wifiUtilizationsByBand["2.4"],
    wifi1: wifiUtilizationsByBand["5"],
  };
  return reducedResponse;
};

export const useChannelUtilizationByDevice = ({
  organizationId,
  networkIds,
  serials,
  timespan,
}: ChannelUtilizationRequestParams) => {
  let interval = tenMinutes;
  if (timespan && timespan >= oneMonth) {
    interval = oneHour;
  }
  return useChannelUtilization(
    { organizationId, networkIds, serials, timespan, interval },
    {
      select: (data) => filterChannelUtilizationByDevice(data),
      enabled: !isEmpty(organizationId) && !isEmpty(networkIds) && !isEmpty(serials),
    },
  );
};
