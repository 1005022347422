import { I18n } from "@meraki/core/i18n";
import { isEmpty } from "lodash";

import {
  CLAIM_SUCCESS_MESSAGE,
  defaultOnboardingStatusCounts,
} from "~/constants/OnboardingConstants";
import type { HardwareRowData } from "~/go/components/HardwareRow";
import { DeviceModelsGo } from "~/go/types/DevicesTypes";
import { deviceName, getDescriptionByDeviceModel } from "~/lib/DeviceUtils";
import GeneralStatus, { DeviceStatus } from "~/shared/constants/Status";
import { DevicesBySerial } from "~/shared/types/Device";
import {
  OnboardingBatchClaimResult,
  OnboardingCounts,
  OnboardingNodeBreakdown,
  OnboardingNodes,
  OnboardingNodeStatus,
} from "~/shared/types/OnboardingTypes";

export const determineStatusLeadingFromCounts = (counts: OnboardingCounts) => {
  if (isEmpty(counts)) {
    return OnboardingNodeStatus.unstarted;
  }
  if (counts.finished > 0) {
    return OnboardingNodeStatus.finished;
  }
  if (counts.connecting > 0) {
    return OnboardingNodeStatus.connecting;
  }
  if (counts.upgrading > 0) {
    return OnboardingNodeStatus.upgrading;
  }
  return OnboardingNodeStatus.unstarted;
};

export const onboardingStatusForNode = (nodeStatus: OnboardingNodeBreakdown) => {
  const { config_up_to_date, firmware_up_to_date, active } = nodeStatus;

  let status: OnboardingNodeStatus = OnboardingNodeStatus.unstarted;

  if (config_up_to_date && firmware_up_to_date && active) {
    status = OnboardingNodeStatus.finished;
  }
  if (config_up_to_date && firmware_up_to_date && !active) {
    status = OnboardingNodeStatus.connecting;
  }
  if (config_up_to_date && !firmware_up_to_date) {
    status = OnboardingNodeStatus.upgrading;
  }
  return status;
};

export const determineStatusTrailingFromCounts = (counts: OnboardingCounts) => {
  if (isEmpty(counts)) {
    return OnboardingNodeStatus.unstarted;
  }
  if (counts.unstarted > 0) {
    return OnboardingNodeStatus.unstarted;
  }
  if (counts.upgrading > 0) {
    return OnboardingNodeStatus.upgrading;
  }
  if (counts.connecting > 0) {
    return OnboardingNodeStatus.connecting;
  }
  if (counts.finished > 0) {
    return OnboardingNodeStatus.finished;
  }
  return OnboardingNodeStatus.unstarted;
};

export function getStatusByOnboardingStatus(status: OnboardingNodeStatus) {
  if (status === OnboardingNodeStatus.unstarted) {
    return DeviceStatus.offline;
  }
  if (status === OnboardingNodeStatus.connecting) {
    return DeviceStatus.alerting;
  }
  if (status === OnboardingNodeStatus.upgrading) {
    return DeviceStatus.alerting;
  }
  if (status === OnboardingNodeStatus.finished) {
    return DeviceStatus.online;
  }
  return DeviceStatus.alerting;
}

export function getDescriptionByOnboardingStatus(status: OnboardingNodeStatus) {
  if (status === OnboardingNodeStatus.connecting) {
    return I18n.t("ONBOARDING_FULLSTACK.STATUS.CONNECTING");
  }
  if (status === OnboardingNodeStatus.upgrading) {
    return I18n.t("ONBOARDING_FULLSTACK.STATUS.UPGRADING");
  }
  if (status === OnboardingNodeStatus.finished) {
    return I18n.t("ONBOARDING_FULLSTACK.STATUS.FINISHED");
  }
  return "";
}

export const getNodesStatusTrailing = (nodes: OnboardingNodes) => {
  const counts = getScannedNodesStatusCounts(nodes);
  return determineStatusTrailingFromCounts(counts);
};

export const getScannedNodesStatusCounts = (nodes: OnboardingNodes) => {
  if (isEmpty(nodes)) {
    return defaultOnboardingStatusCounts;
  }
  return Object.entries(nodes).reduce((workingCounts, node) => {
    const status = node[1];
    return {
      ...workingCounts,
      [status]: workingCounts[status] + 1,
    };
  }, defaultOnboardingStatusCounts);
};

export const updateNodesOnboardingStatus = (
  nodesStatus: OnboardingNodeBreakdown[],
  scannedNodes: OnboardingNodes,
) => {
  let updatedScannedNodes = scannedNodes;

  nodesStatus.forEach((node: OnboardingNodeBreakdown) => {
    const scannedSerials = Object.keys(updatedScannedNodes);
    if (scannedSerials.includes(node.serial)) {
      updatedScannedNodes = {
        ...updatedScannedNodes,
        [node.serial]: onboardingStatusForNode(node),
      };
    }
  });

  return updatedScannedNodes;
};

export function mapOnboardingNodesToRowDataWithModel(
  devices: DevicesBySerial,
  onboardingNodes: OnboardingNodes,
) {
  const hardwareRowData: HardwareRowData[] = [];
  if (isEmpty(devices) || isEmpty(onboardingNodes)) {
    return hardwareRowData;
  }

  Object.entries(onboardingNodes).forEach(([serial, onboardingStatus]) => {
    const device = devices[serial];

    if (device) {
      const { name } = device;
      const model = device.model as DeviceModelsGo;

      if (model) {
        hardwareRowData.push({
          name: deviceName({ name, serial }),
          description: getDescriptionByDeviceModel(model),
          model: model,
          status: getStatusByOnboardingStatus(onboardingStatus),
        });
      }
    }
  });

  return hardwareRowData;
}

export function mapBatchClaimErrorsToRowData(
  devices: DevicesBySerial,
  batchClaimResult: OnboardingBatchClaimResult,
) {
  const erroredHardwareRowData: HardwareRowData[] = [];
  if (isEmpty(batchClaimResult)) {
    return erroredHardwareRowData;
  }

  Object.entries(batchClaimResult).forEach(([serial, result]) => {
    if (result !== CLAIM_SUCCESS_MESSAGE) {
      erroredHardwareRowData.push({
        name: serial,
        description: result,
        status: GeneralStatus.bad,
        model: devices[serial]?.model as DeviceModelsGo,
      });
    }
  });

  return erroredHardwareRowData;
}
