import { I18n } from "@meraki/core/i18n";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { Features } from "~/go/types/ContextHelpTypes";
import { MASK_ROWS } from "~/lib/IPAddressUtils";

export const INITIAL_SUBNET_MASK = "24";

interface SubnetMaskSelectionRow {
  value?: string;
  onApplySubnetMask: (value: string) => void;
}

export default function SubnetMaskSelectionRow({
  value,
  onApplySubnetMask,
}: SubnetMaskSelectionRow) {
  const [selectedSubnet, setSelectedSubnet] = useState(INITIAL_SUBNET_MASK);
  const [visible, setVisible] = useState(false);

  const hideMaskInput = useCallback(() => {
    setVisible(false);
  }, []);

  const showMaskInput = useCallback(() => {
    setVisible(true);
  }, []);

  const derivedValue = value || selectedSubnet;

  return (
    <View style={styles.maskInputRow}>
      <PickerModalRow
        selectedValue={derivedValue}
        label={I18n.t("SUBNET_MASK_ROW.LABEL")}
        subtitle={derivedValue}
        visible={visible}
        title={I18n.t("SUBNET_MASK_ROW.MODAL_TITLE")}
        items={MASK_ROWS}
        onValueSelect={(value) => {
          setSelectedSubnet(value as string);
          onApplySubnetMask(value as string);
          setVisible(false);
        }}
        onExit={hideMaskInput}
        onPress={showMaskInput}
        context={Features.subnetting}
        disableBottomBorder
      />
    </View>
  );
}

const styles = StyleSheet.create({
  maskInputRow: {
    marginTop: SPACING.large,
  },
});
