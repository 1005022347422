import { findSliderIndexForKB } from "@meraki/go/alert";
import { createSelector } from "reselect";

import { DOWN_ALERT_TIMEOUT, USAGE_ALERT_PERIOD } from "~/lib/AlertUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { hasGRDevices, hasGSDevices, hasGXDevices } from "~/selectors/devices";
import { alertSettingsSelector, currentNetworkState } from "~/selectors/getters";
import { UsageAlertID } from "~/shared/types/AlertTypes";
import { RootState } from "~/shared/types/Redux";

const emptyArray: any = [];

export const downAlertEnabled = (alert: any) =>
  alert?.enabled === true && alert?.filters?.timeout === DOWN_ALERT_TIMEOUT;

export const alertSettingsState = createSelector(
  alertSettingsSelector,
  currentNetworkState,
  (alertSettings, currentNetwork) =>
    nestedValueExists(alertSettings, ["items", currentNetwork], {}),
);

export const getTrackedClients = (alert: any) => alert?.filters?.clients || [];

export const getEmailRecipients = (alert: any) => alert?.alertDestinations?.emails;

export const getPushRecipients = (alert: any) => alert?.alertDestinations?.mobileAdminIds;

export const isDownAlertEnabledForId = createSelector(
  alertSettingsState,
  (_: RootState, alertId: any) => alertId,
  (alertSettings, alertId) => downAlertEnabled(alertSettings?.[alertId]),
);

export const isDownEnabled = createSelector(
  alertSettingsState,
  hasGRDevices,
  hasGSDevices,
  hasGXDevices,
  (alertSettings, hasGRDevice, hasGSDevice, hasGXDevice) => {
    if (!(hasGRDevice || hasGSDevice || hasGXDevice)) {
      return false;
    }

    const { repeaterDown, gatewayDown, applianceDown, switchDown } = alertSettings;
    return (
      (downAlertEnabled(repeaterDown) || !hasGRDevice) &&
      (downAlertEnabled(gatewayDown) || !hasGRDevice) &&
      (downAlertEnabled(applianceDown) || !hasGXDevice) &&
      (downAlertEnabled(switchDown) || !hasGSDevice)
    );
  },
);

export const getDownEmailRecipients = createSelector(
  alertSettingsState,
  hasGRDevices,
  hasGSDevices,
  hasGXDevices,
  (alertSettings, hasGRDevice, hasGSDevice, hasGXDevice) => {
    const { repeaterDown, gatewayDown, applianceDown, switchDown } = alertSettings;
    return (
      (hasGRDevice && getEmailRecipients(repeaterDown)) ||
      (hasGRDevice && getEmailRecipients(gatewayDown)) ||
      (hasGXDevice && getEmailRecipients(applianceDown)) ||
      (hasGSDevice && getEmailRecipients(switchDown)) ||
      emptyArray
    );
  },
);

export const getDownPushRecipients = createSelector(
  alertSettingsState,
  hasGRDevices,
  hasGSDevices,
  hasGXDevices,
  (alertSettings, hasGRDevice, hasGSDevice, hasGXDevice) => {
    const { repeaterDown, gatewayDown, applianceDown, switchDown } = alertSettings;
    return (
      (hasGRDevice && getPushRecipients(repeaterDown)) ||
      (hasGRDevice && getPushRecipients(gatewayDown)) ||
      (hasGXDevice && getPushRecipients(applianceDown)) ||
      (hasGSDevice && getPushRecipients(switchDown)) ||
      emptyArray
    );
  },
);

export const isClientConnectivitySupported = createSelector(hasGXDevices, (hasGXDevice) => {
  if (hasGXDevice) {
    return true;
  }
  return false;
});

export const isClientConnectivityEnabled = createSelector(alertSettingsState, (alertSettings) => {
  const { clientConnectivity } = alertSettings;
  return !!clientConnectivity && clientConnectivity.enabled;
});

export const getTrackedClientsList = createSelector(alertSettingsState, (alertSettings) => {
  const { clientConnectivity } = alertSettings;
  return getTrackedClients(clientConnectivity) || emptyArray;
});

export const getTrackingEmailRecipients = createSelector(alertSettingsState, (alertSettings) => {
  const { clientConnectivity } = alertSettings;
  return getEmailRecipients(clientConnectivity) || emptyArray;
});

export const getTrackingPushRecipients = createSelector(alertSettingsState, (alertSettings) => {
  const { clientConnectivity } = alertSettings;
  return getPushRecipients(clientConnectivity) || emptyArray;
});

export const isUsageEnabled = createSelector(alertSettingsState, (alertSettings) => {
  const { usageAlert } = alertSettings;
  return !!usageAlert && usageAlert.enabled && usageAlert.filters?.period === USAGE_ALERT_PERIOD;
});

export const getUsageEmailRecipients = createSelector(alertSettingsState, (alertSettings) => {
  const { usageAlert } = alertSettings;
  return getEmailRecipients(usageAlert) || emptyArray;
});

export const isGuestInsightsEnabled = createSelector(
  alertSettingsState,
  (alertSettings) => !!alertSettings?.weeklyPresence?.enabled,
);

export const getGuestInsightsEmailRecipients = createSelector(
  alertSettingsState,
  (alertSettings) => {
    const { weeklyPresence } = alertSettings;
    return getEmailRecipients(weeklyPresence) || emptyArray;
  },
);

export const getGuestInsightsPushRecipients = createSelector(
  alertSettingsState,
  (alertSettings) => {
    const { weeklyPresence } = alertSettings;
    return getPushRecipients(weeklyPresence) || emptyArray;
  },
);

export const isWeeklyUmbrellaEnabled = createSelector(
  alertSettingsState,
  (alertSettings) => !!alertSettings?.weeklyUmbrella?.enabled,
);

export const getWeeklyUmbrellaEmailRecipients = createSelector(
  alertSettingsState,
  (alertSettings) => {
    const { weeklyUmbrella } = alertSettings;
    return getEmailRecipients(weeklyUmbrella) || emptyArray;
  },
);

export const getWeeklyUmbrellaPushRecipients = createSelector(
  alertSettingsState,
  (alertSettings) => {
    const { weeklyUmbrella } = alertSettings;
    return getPushRecipients(weeklyUmbrella) || emptyArray;
  },
);

export const getUsageThreshold = createSelector(
  alertSettingsState,
  (alertSettings) => alertSettings?.[UsageAlertID.usageAlert]?.filters?.threshold,
);

export const getSliderIndexForUsageThreshold = createSelector(getUsageThreshold, (usageThreshold) =>
  findSliderIndexForKB(usageThreshold),
);
