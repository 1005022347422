import { I18n } from "@meraki/core/i18n";
import {
  AlertsHistoryScreen,
  AlertsSettingsScreen,
  NetworkSelectionScreen,
  TrackedClientsListScreen,
} from "@meraki/go/alert";
import { AlertsStackProps } from "@meraki/go/navigation-type";
import { Button } from "@meraki/magnetic/components";
import {
  ModalScreenOptions,
  useDefaultScreenOptions,
  useTabScreenOptions,
} from "@meraki/shared/navigation";
import { registerTags } from "@meraki/shared/search";
import { useNavigation } from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationProp,
} from "@react-navigation/native-stack";

import { AlertInsightGroup } from "../screenGroups/AlertInsightGroup";
import { ClientGroup } from "../screenGroups/ClientGroup";
import { DeviceGroup } from "../screenGroups/DeviceGroup";

const Stack = createNativeStackNavigator<AlertsStackProps>();

const AlertsStack = () => {
  const navigation = useNavigation<NativeStackNavigationProp<AlertsStackProps>>();
  const defaultScreenOptions = useDefaultScreenOptions();
  const tabScreenOptions = useTabScreenOptions();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="AlertsHistory"
        component={AlertsHistoryScreen}
        options={{
          ...tabScreenOptions,
          title: I18n.t("ROOT_TITLES.ALERTS"),
          headerRight: () => (
            <Button.Icon
              icon="Gear"
              color="default.textIn.base"
              onPress={() => navigation.navigate("NetworkSelection")}
            />
          ),
        }}
      />
      <Stack.Screen
        name="TrackedClients"
        component={TrackedClientsListScreen}
        options={{
          ...ModalScreenOptions,
          headerTitle: I18n.t("TRACK_CLIENT.PAGE_TITLE"),
        }}
      />
      {ClientGroup(Stack)}
      {DeviceGroup(Stack)}
      {AlertInsightGroup(Stack)}
      <Stack.Screen
        name="AlertsSettings"
        component={AlertsSettingsScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="NetworkSelection"
        component={NetworkSelectionScreen}
        options={{
          headerTitle: I18n.t("NETWORKS.TITLE"),
        }}
      />
    </Stack.Navigator>
  );
};

// search tag registrations
registerTags(
  {
    title: "TRACK_CLIENT.PAGE_TITLE",
    tab: "AlertsTab",
    scope: "network",
    screen: "TrackedClients",
  },
  ["alerts", "notifications", "tracked", "clients"],
);

export default AlertsStack;
