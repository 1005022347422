import { isEmpty } from "lodash";
import { PureComponent, ReactNode } from "react";
import { StyleSheet } from "react-native";

import WrappingRow from "~/shared/rows/WrappingRow";

interface WrappingButtonRowProps {
  children: ReactNode;
}

export default class WrappingButtonRow extends PureComponent<WrappingButtonRowProps> {
  render() {
    const { children } = this.props;
    if (isEmpty(children)) {
      return null;
    }

    return <WrappingRow screenStyles={styles.buttonContainer}>{children}</WrappingRow>;
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
});
