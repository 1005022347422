import {
  ORG_NETWORKS_GET_FAILURE,
  ORG_NETWORKS_GET_REQUEST,
  ORG_NETWORKS_GET_SUCCESS,
} from "@meraki/shared/redux";

import { ApiAction, CALL_API } from "~/middleware/api";
import { Method } from "~/shared/types/RequestTypes";

export function fetchOrgNetworks(orgId: string): ApiAction {
  // TODO: Remove this once we implement this in PaginatedList
  const PAGINATION_PER_PAGE = 10000; // valid range is 3 - 100000 as of api v1
  return {
    [CALL_API]: {
      types: [ORG_NETWORKS_GET_REQUEST, ORG_NETWORKS_GET_SUCCESS, ORG_NETWORKS_GET_FAILURE],
      endpoint: `/api/v1/organizations/${orgId}/networks?perPage=${PAGINATION_PER_PAGE}`,
      config: { method: Method.get },
    },
  };
}
