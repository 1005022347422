import { AdapterContext } from "@meraki/magnetic/adapter";
import { useMagneticTheme } from "@meraki/magnetic/themes";
import { useContext } from "react";
import { StatusBar } from "react-native";

import { useThemeColors, useThemeMode } from "../hooks/useTheme";

const AppStatusBar = () => {
  const useMagnetic = useContext(AdapterContext);
  const magneticTheme = useMagneticTheme();

  const colors = useThemeColors();
  const mode = useThemeMode();

  return (
    <StatusBar
      barStyle={mode === "light" ? "dark-content" : "light-content"}
      translucent={useMagnetic ? true : false}
      backgroundColor={
        useMagnetic ? magneticTheme.color.transparent : colors.navigation.backgroundPrimary
      }
    />
  );
};

export default AppStatusBar;
