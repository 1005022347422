import { TranslationKeys } from "@meraki/core/i18n";

export const LAYER_7_GROUP_FILTERS = [
  "WORK",
  "ENTERTAINMENT",
  "SOCIAL",
  "NETWORK",
  "BUSINESS",
] as const;

export type LAYER_7_GROUP_FILTER_TYPE = (typeof LAYER_7_GROUP_FILTERS)[number];

export interface Layer7GroupType {
  displayName?: TranslationKeys;
  name: TranslationKeys;
  keyName: string;
  group: string;
  tags: LAYER_7_GROUP_FILTER_TYPE[];
}

export const LAYER_7_GROUPS: Record<string, Layer7GroupType> = {
  "meraki:layer7/category/1": {
    name: "LAYER_7.EMAIL",
    keyName: "Email",
    group: "group:2",
    tags: ["WORK"],
  },
  "meraki:layer7/category/2": {
    name: "LAYER_7.BLOGGING",
    keyName: "Blogging",
    group: "group:1",
    tags: ["SOCIAL"],
  },
  "meraki:layer7/category/3": {
    name: "LAYER_7.FILE_SHARING",
    keyName: "File sharing",
    group: "group:3",
    tags: ["WORK"],
  },
  "meraki:layer7/category/4": {
    name: "LAYER_7.NEWS",
    keyName: "News",
    group: "group:7",
    tags: ["SOCIAL"],
  },
  "meraki:layer7/category/5": {
    name: "LAYER_7.SPORTS",
    keyName: "Sports",
    group: "group:11",
    tags: ["ENTERTAINMENT"],
  },
  "meraki:layer7/category/6": {
    name: "LAYER_7.GAMING",
    keyName: "Gaming",
    group: "group:4",
    tags: ["ENTERTAINMENT"],
  },
  "meraki:layer7/category/7": {
    name: "LAYER_7.ONLINE_BACKUP",
    keyName: "Online backup",
    group: "group:0",
    tags: ["NETWORK"],
  },
  "meraki:layer7/category/8": {
    displayName: "LAYER_7.P2P_SHARING.DISPLAY_NAME",
    name: "LAYER_7.P2P_SHARING.NAME",
    keyName: "Peer-to-peer (P2P)",
    group: "group:8",
    tags: ["NETWORK"],
  },
  "meraki:layer7/category/10": {
    name: "LAYER_7.SOCIAL_WEB",
    keyName: "Social web",
    group: "group:9",
    tags: ["SOCIAL"],
  },
  "meraki:layer7/category/11": {
    name: "LAYER_7.PHOTO_SHARING",
    keyName: "Photo sharing",
    group: "group:10",
    tags: ["SOCIAL"],
  },
  "meraki:layer7/category/12": {
    name: "LAYER_7.SOFTWARE_VIRUS",
    keyName: "Software & anti-virus updates",
    group: "group:15",
    tags: ["NETWORK"],
  },
  "meraki:layer7/category/14": {
    name: "LAYER_7.VIDEO",
    keyName: "Video",
    group: "group:12",
    tags: ["ENTERTAINMENT"],
  },
  "meraki:layer7/category/15": {
    name: "LAYER_7.MUSIC",
    keyName: "Music",
    group: "group:6",
    tags: ["ENTERTAINMENT"],
  },
  "meraki:layer7/category/16": {
    name: "LAYER_7.VOIP",
    keyName: "VoIP & video conferencing",
    group: "group:13",
    tags: ["WORK", "BUSINESS"],
  },
  "meraki:layer7/category/17": {
    name: "LAYER_7.WEB_FILE_SHARING",
    keyName: "Web file sharing",
    group: "group:3",
    tags: ["SOCIAL"],
  },
  "meraki:layer7/category/18": {
    name: "LAYER_7.SECURITY",
    keyName: "Security",
    group: "group:16",
    tags: ["NETWORK", "BUSINESS"],
  },
  "meraki:layer7/category/19": {
    name: "LAYER_7.PRODUCTIVITY",
    keyName: "Productivity",
    group: "group:17",
    tags: ["WORK", "BUSINESS"],
  },
  "meraki:layer7/category/20": {
    name: "LAYER_7.REMOTE_MONITORING",
    keyName: "Remote monitoring & management",
    group: "group:18",
    tags: ["NETWORK", "BUSINESS"],
  },
  "meraki:layer7/category/21": {
    name: "LAYER_7.BUSINESS_MANAGEMENT",
    keyName: "Business management",
    group: "group:18",
    tags: ["BUSINESS"],
  },
  "meraki:layer7/category/22": {
    name: "LAYER_7.HEALTH_CARE.DISPLAY_NAME",
    keyName: "Health care",
    group: "group:23",
    tags: ["BUSINESS"],
  },
  "meraki:layer7/category/23": {
    name: "LAYER_7.WEB_PAYMENTS",
    keyName: "Web payments",
    group: "group:20",
    tags: ["WORK", "BUSINESS"],
  },
  "meraki:layer7/category/25": {
    name: "LAYER_7.DATABASES",
    keyName: "Databases",
    group: "group:21",
    tags: ["NETWORK"],
  },
  "meraki:layer7/category/26": {
    name: "LAYER_7.CLOUD_SERVICES",
    keyName: "Cloud services",
    group: "group:22",
    tags: ["NETWORK"],
  },
  "meraki:layer7/category/27": {
    name: "LAYER_7.ADVERTISING",
    keyName: "Advertising",
    group: "group:24",
    tags: ["BUSINESS"],
  },
};
