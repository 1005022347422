import { I18n } from "@meraki/core/i18n";
import { BottomSheet, BottomSheetMethods, Button, Input, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  standardizeLoginResponse,
  StandardLoginResponse,
  useResend2FA,
  useSubmit2FA,
} from "@meraki/shared/api";
import { showErrorAlert, showOkayAlert } from "@meraki/shared/native-alert";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { Platform } from "react-native";

import { PostLoginProps } from "./Types";

export const SmsAuth = ({
  setLatestResponse,
  response,
}: PostLoginProps & { response: StandardLoginResponse }) => {
  const navigation = useNavigation();
  const [pinCode, setPinCode] = useState("");
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const submit2FA = useSubmit2FA();
  const resendSMSCode = useResend2FA();

  const handleSubmit2fa = () => {
    submit2FA.mutate(
      { code: pinCode },
      {
        onSuccess: (queryResponse) => {
          const newResponse = standardizeLoginResponse(queryResponse);
          // this if statement is a backend bug fix for when a user submits an incorrect sms code
          // - the response returned from the backend has the mode "two_factor" (implying two factor auth via app)
          // instead of "sms", which is the expected mode for making an sms two factor auth. We are fixing this here
          // temporarily because it will take much more time to fix in manage.
          if (newResponse?.mode === "two_factor") {
            newResponse.mode = "sms";
            newResponse.primary = response?.primary;
            newResponse.secondary = response?.secondary;
          }
          setLatestResponse(newResponse);
        },
        onError: (error) => {
          showErrorAlert(I18n.t("ERROR"), error as string);
        },
      },
    );
  };

  useEffect(() => {
    navigation.setOptions({
      headerTitle: I18n.t("TWO_FACTOR_AUTH.SMS_TITLE"),
    });
  });

  const otpProps = Platform.select({
    ios: { textContentType: "oneTimeCode" as const },
    android: { autoComplete: "sms-otp" as const },
  });

  return (
    <Box gap="md">
      <Text size="p1">{I18n.t("TWO_FACTOR_AUTH.SMS", { smsNumber: response?.primary })}</Text>
      <Input
        label={I18n.t("TWO_FACTOR_AUTH.CODE")}
        placeholder={I18n.t("TWO_FACTOR_AUTH.ENTER_CODE")}
        keyboardType="numeric"
        value={pinCode}
        onChangeText={setPinCode}
        disabled={submit2FA.isLoading}
        {...otpProps}
      />
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.VERIFY")}
        onPress={handleSubmit2fa}
        loading={submit2FA.isLoading}
      />
      <Button
        text={I18n.t("TWO_FACTOR_AUTH.RESEND.TITLE_SHORT")}
        kind="secondary"
        onPress={() => bottomSheetRef.current?.present()}
        loading={resendSMSCode.isLoading}
      />
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Content>
          <Box gap="md">
            <Button
              kind="secondary"
              text={I18n.t("TWO_FACTOR_AUTH.RESEND.PRIMARY", { smsPrimary: response?.primary })}
              onPress={() => {
                resendSMSCode.mutate(
                  { retry: "primary" },
                  {
                    onSuccess: () => {
                      showOkayAlert(
                        I18n.t("TWO_FACTOR_AUTH.RESEND.SUCCESS"),
                        I18n.t("TWO_FACTOR_AUTH.SMS", { smsNumber: response?.primary }),
                      );
                    },
                    onError: () => {
                      showErrorAlert(
                        I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE"),
                        I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE_ACTION"),
                      );
                    },
                  },
                );
                bottomSheetRef.current?.close();
              }}
            />
            {response?.secondary && (
              <Button
                kind="secondary"
                text={I18n.t("TWO_FACTOR_AUTH.RESEND.SECONDARY", {
                  smsSecondary: response.secondary,
                })}
                onPress={() => {
                  resendSMSCode.mutate(
                    { retry: "secondary" },
                    {
                      onSuccess: () => {
                        showOkayAlert(
                          I18n.t("TWO_FACTOR_AUTH.RESEND.SUCCESS"),
                          I18n.t("TWO_FACTOR_AUTH.SMS", { smsNumber: response?.secondary }),
                        );
                      },
                      onError: () => {
                        showErrorAlert(
                          I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE"),
                          I18n.t("TWO_FACTOR_AUTH.RESEND.FAILURE_ACTION"),
                        );
                      },
                    },
                  );
                  bottomSheetRef.current?.close();
                }}
              />
            )}
          </Box>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Box>
  );
};
