import { SET_SPEED_TEST_AGREEMENT, WIPE_REDUX } from "@meraki/shared/redux";
import { Reducer } from "redux";

interface SpeedTestState {
  agreement: boolean;
}

const initialState: SpeedTestState = {
  agreement: false,
};

const speedTest: Reducer<SpeedTestState> = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPEED_TEST_AGREEMENT:
      return { agreement: action.agreement };
    case WIPE_REDUX: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default speedTest;
