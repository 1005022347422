import { StyleSheet } from "react-native";

import { SPLASH_PAGE_ID } from "~/constants/Splash";
import PickerModalRow from "~/go/rows/PickerModalRow";
import { SPLASH_FEATURE_ID, SplashCustomStates } from "~/go/types/SplashTypes";
import I18n from "~/i18n/i18n";
import { convertFrequency, getTimeoutOptions } from "~/lib/SplashUtils";
import Table from "~/shared/components/Table";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";

export interface SplashConfigureRowsProps {
  isCustomURLValid?: boolean;
  onPressRedirectURL: (customStates: SplashCustomStates) => void;
  onPressWalledGarden?: (customStates: SplashCustomStates) => void;
  onPressFrequency: () => void;
  onExitFrequency: () => void;
  frequencyProps: any;
  customStates: SplashCustomStates;
  splashPage: string;
}

const renderRow = (row: any) => {
  const { isModalRow, onPress, onExit, label, subtitle, frequencyProps, testID, ...rest } = row;

  return isModalRow ? (
    <PickerModalRow
      visible={frequencyProps.visible}
      onPress={onPress}
      onExit={onExit}
      label={label}
      subtitle={convertFrequency(parseInt(frequencyProps.selectedValue), false)}
      title={I18n.t("SPLASH_CONFIG.CONFIGURE.TIMEOUT_FREQUENCY.PICKER_TITLE")}
      items={getTimeoutOptions()}
      containerStyles={styles.modalRowContainer}
      selectedValue={frequencyProps.selectedValue}
      onValueSelect={frequencyProps.onValueSelect}
      testID={testID}
      disableBottomBorder
    />
  ) : (
    <SimpleDisclosureRow onPress={onPress} subtitle={subtitle} testID={testID} {...rest}>
      {row.label}
    </SimpleDisclosureRow>
  );
};

const SplashConfigureRows: React.FC<SplashConfigureRowsProps> = (props) => {
  const {
    isCustomURLValid,
    frequencyProps,
    onPressRedirectURL,
    onPressWalledGarden,
    onPressFrequency,
    onExitFrequency,
    customStates,
    splashPage,
  } = props;

  const rows: Object[] = [];
  if (splashPage === SPLASH_PAGE_ID.customURL && !isCustomURLValid) {
    /* The custom URL splash page requires the user to enter the URL before other
    options can be shown. So, if invalid or empty, only show a prompt asking them to enter the URL. */
    rows.push({
      label: I18n.t("SPLASH_CONFIG.CONFIGURE.CUSTOM_URL.SET_CUSTOM_URL_PROMPT"),
      testID: "SET_CUSTOM_URL_PROMPT",
    });
  } else {
    if (splashPage === SPLASH_PAGE_ID.customURL) {
      rows.push({
        label: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.ROW_TITLE"),
        subtitle: I18n.t("SPLASH_CONFIG.CONFIGURE.WALLED_GARDEN.ROW_SUBTITLE"),
        onPress: onPressWalledGarden,
        testID: "WALLED_GARDEN",
      });
    }
    rows.push(
      {
        isModalRow: true,
        frequencyProps: frequencyProps,
        label: I18n.t("SPLASH_CONFIG.CONFIGURE.TIMEOUT_FREQUENCY.ROW_TITLE"),
        subtitle: convertFrequency(customStates[SPLASH_FEATURE_ID.timeout] || 0),
        onPress: () => onPressFrequency(),
        onExit: () => onExitFrequency(),
        testID: "SPLASH.FREQUENCY",
      },
      {
        label: I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.TOGGLE_ROW_TITLE"),
        subtitle:
          customStates[SPLASH_FEATURE_ID.redirectURL] != null
            ? customStates[SPLASH_FEATURE_ID.redirectURL]
            : I18n.t("SPLASH_CONFIG.NONE_OPT"),
        subtitle2: I18n.t("SPLASH_CONFIG.CONFIGURE.REDIRECT_URL.TOGGLE_ROW_SUBTITLE"),
        onPress: onPressRedirectURL,
        testID: "REDIRECT_URL",
      },
    );
  }

  return <Table renderRow={renderRow} entries={rows} separators={false} topPadding={false} />;
};

const styles = StyleSheet.create({
  modalRowContainer: {
    backgroundColor: undefined,
    marginTop: 0,
  },
});

SplashConfigureRows.defaultProps = {
  isCustomURLValid: true,
};

export default SplashConfigureRows;
