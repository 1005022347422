import { I18n } from "@meraki/core/i18n";
import { useAdmins, useCreateAdmin } from "@meraki/shared/api";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import {
  KEYBOARD_TYPE,
  LINE_HEIGHT,
  MODAL_DISMISS_DURATION,
  SPACING,
} from "~/constants/MkiConstants";
import InfoModal from "~/go/components/InfoModal";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import UnderlinedTextInput from "~/go/components/textInputs/UnderlinedTextInput";
import { SettingsStackProps } from "~/go/navigation/Types";
import { onFailInvite } from "~/lib/AdminUtils";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { stringIsEmail } from "~/lib/stringHelper";
import { sizeSelect } from "~/lib/themeHelper";
import { currentOrganization } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { OrgAccess } from "~/shared/types/AdminTypes";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "InviteAdministrator">;

export const InviteAdministratorScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const createAdmin = useCreateAdmin();

  const organizationId = useAppSelector(currentOrganization).id ?? "";
  const onChangeName = (newName: any) => setName(newName);
  const onChangeEmail = (newEmail: any) => setEmail(newEmail);
  const toggleReadOnly = () => setIsReadOnly(!isReadOnly);

  const queryClient = useQueryClient();

  const validate = useCallback(() => {
    if (!name) {
      showAlert(I18n.t("ERROR"), I18n.t("CREATE_ACCOUNT.ERRORS.ENTER_ALL_INFO"));
      return false;
    }

    if (!email || !stringIsEmail(email)) {
      showAlert(I18n.t("ERROR"), I18n.t("CREATE_ACCOUNT.ERRORS.INVALID_EMAIL"));
      return false;
    }

    return true;
  }, [email, name]);

  const save = useCallback(() => {
    if (validate()) {
      setIsLoading(true);
      createAdmin.mutate(
        {
          organizationId,
          params: {
            email,
            name,
            orgAccess: isReadOnly ? OrgAccess.read : OrgAccess.full,
          },
        },
        {
          onSuccess: () => setShowConfirmationModal(true),
          onError: (error: unknown) => {
            onFailInvite({ error });
          },
          onSettled: () => {
            queryClient.refetchQueries({ queryKey: useAdmins.queryKeyRoot });
            setIsLoading(false);
          },
        },
      );
    }
  }, [email, isReadOnly, name, organizationId, queryClient, createAdmin, validate]);

  useLayoutEffect(() => {
    const cancelChanges = () => {
      return showSaveWarning(save, navigation.goBack);
    };

    navigation.setOptions({
      // react-navigation expects a function which returns a React Element for headerLeft/Right
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <CancelButton onPress={cancelChanges} />,
    });
  }, [save, navigation]);

  const closeModalAndDismiss = () => {
    setShowConfirmationModal(false);
    setTimeout(navigation.goBack, MODAL_DISMISS_DURATION);
  };

  return (
    <FullScreenContainerView defaultPadding>
      <LoadingSpinner visible={isLoading} />
      <UnderlinedTextInput
        autoCorrect={false}
        onChangeText={onChangeName}
        showClearButton
        title={I18n.t("ADMIN.NAME.TITLE")}
        value={name}
        testID={"INVITE_ADMIN.NAME_INPUT"}
      />
      <UnderlinedTextInput
        autoCapitalize={"none"}
        autoCorrect={false}
        keyboardType={KEYBOARD_TYPE.emailAddress}
        onChangeText={onChangeEmail}
        showClearButton
        title={I18n.t("ADMIN.EMAIL.TITLE")}
        value={email}
        testID={"INVITE_ADMIN.EMAIL_INPUT"}
      />
      <SwitchRow
        subtitle={I18n.t("ADMIN.READ_ONLY.SUBTITLE")}
        screenStyles={styles.switchRow}
        value={isReadOnly}
        onValueChange={toggleReadOnly}
        testID="TOGGLE_READ_ONLY"
      >
        {I18n.t("ADMIN.READ_ONLY.TITLE")}
      </SwitchRow>
      <View style={styles.footerContainer}>
        <MkiText textStyle="secondary" screenStyles={styles.helpText}>
          {I18n.t("ADMIN.HELP.ORG_ADMIN")}
        </MkiText>
        <RoundedButton
          buttonType={ButtonType.primary}
          onPress={save}
          screenStyles={styles.button}
          testID={"INVITE_ADMIN.SEND_INVITE"}
        >
          {I18n.t("ADMIN.BUTTON.SEND_INVITE")}
        </RoundedButton>
        <InfoModal visible={showConfirmationModal} onExit={closeModalAndDismiss}>
          <View style={styles.confirmationModal}>
            <MerakiIcon name="mail" size="l" />
            <MkiText textStyle="subheading" screenStyles={styles.confirmationTitle}>
              {I18n.t("ADMIN.CONFIRM.TITLE")}
            </MkiText>
            <MkiText textStyle="smallSecondary" screenStyles={styles.helpText}>
              {I18n.t("ADMIN.CONFIRM.SUBTITLE")}
            </MkiText>
            <RoundedButton
              buttonType={ButtonType.primary}
              containerStyles={styles.doneButtonContainerStyle}
              onPress={closeModalAndDismiss}
              testID={"INVITE_ADMIN.INFO_MODAL.DONE"}
            >
              {I18n.t("ADMIN.BUTTON.CONFIRM")}
            </RoundedButton>
          </View>
        </InfoModal>
      </View>
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  button: {
    marginHorizontal: sizeSelect({
      small: SPACING.large,
      medium: SPACING.extraLarge,
      large: SPACING.extraLarge,
    }),
  },
  confirmationModal: {
    alignItems: "center",
    paddingTop: SPACING.small,
    paddingBottom: SPACING.large,
  },
  doneButtonContainerStyle: {
    paddingHorizontal: SPACING.large,
  },
  confirmationTitle: {
    marginTop: SPACING.small,
  },
  footerContainer: {
    marginHorizontal: SPACING.default,
    marginVertical: SPACING.extraLarge,
  },
  helpText: {
    lineHeight: LINE_HEIGHT.default,
    marginBottom: SPACING.default,
    marginTop: SPACING.default,
    textAlign: "center",
  },
  switchRow: {
    marginHorizontal: SPACING.tiny,
  },
});

export default InviteAdministratorScreen;
