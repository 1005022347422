import { useMagneticTheme } from "@meraki/magnetic/themes";
import {
  RefreshControl as RNRefreshControl,
  RefreshControlProps as RNRefreshControlProps,
} from "react-native";

export type RefreshControlProps = Pick<RNRefreshControlProps, "refreshing" | "onRefresh">;

export function RefreshControl(props: RefreshControlProps) {
  const theme = useMagneticTheme();

  return (
    <RNRefreshControl
      {...props}
      tintColor={theme.color.interact.icon.weak.base}
      colors={[theme.color.interact.icon.weak.base]}
    />
  );
}
