import { BaseNetworkSubProps } from "../types/BaseSubProps";
import DataMsg from "../types/DataMsg";

export interface Props extends BaseNetworkSubProps {
  clientMac?: string;
}

export interface Message extends DataMsg {
  data: {
    rssi: number;
    associated: boolean;
    assoc_at: number;
    band: number;
    vap: number; // virtual access point
    port: string;
    channel: number;
    node_id: number;
  };
}

export interface Result {
  rssi: number;
  associated: boolean;
  associatedAt: number;
  associationBand: number;
  deviceId: number;
  channel: number;
}

export function formatMsg(message?: Message): Result | undefined {
  const data = message?.data;

  if (!data) {
    return undefined;
  }

  const { rssi, associated, assoc_at, node_id, channel, band } = data;
  return {
    channel,
    rssi,
    associated,
    associatedAt: assoc_at,
    deviceId: node_id,
    associationBand: band,
  };
}
