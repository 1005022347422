import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  color: string;
  size?: number;
};

function SettingsIcon(props: Props) {
  const { color, size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.5062 7.56563C18.7319 7.88876 18.9295 8.23064 19.0968 8.58751L21.525 9.93751C21.8282 11.2954 21.8314 12.7032 21.5343 14.0625L19.0968 15.4125C18.9295 15.7694 18.7319 16.1113 18.5062 16.4344L18.5531 19.2188C17.524 20.1565 16.3061 20.8629 14.9812 21.2906L12.5906 19.8563C12.1973 19.8844 11.8026 19.8844 11.4093 19.8563L9.02809 21.2813C7.699 20.8614 6.47705 20.1576 5.44684 19.2188L5.49372 16.4438C5.26991 16.1161 5.07241 15.7713 4.90309 15.4125L2.47497 14.0625C2.17178 12.7046 2.16858 11.2968 2.46559 9.93751L4.90309 8.58751C5.07042 8.23064 5.26802 7.88876 5.49372 7.56563L5.44684 4.78126C6.47589 3.84354 7.69382 3.13707 9.01872 2.70938L11.4093 4.14376C11.8026 4.11563 12.1973 4.11563 12.5906 4.14376L14.9718 2.71876C16.3009 3.13866 17.5229 3.8424 18.5531 4.78126L18.5062 7.56563Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default SettingsIcon;
