import { PortForwardingRule } from "@meraki/shared/api";

import { IPAddressAssignment } from "~/go/types/NetworksTypes";
import { PortRangeParts } from "~/shared/types/PortRange";

const PORT_RANGE_DELIMITER = "-";
export const ALLOWED_IPS_ANY = "any";

export const parsePortRangeString = (portRange = ""): PortRangeParts => {
  const startingPort = parseInt(portRange.split(PORT_RANGE_DELIMITER)[0], 10);
  const endingPort = parseInt(portRange.split(PORT_RANGE_DELIMITER)[1], 10);

  return {
    startingPort: isNaN(startingPort) ? undefined : startingPort,
    endingPort: isNaN(endingPort) ? undefined : endingPort,
  };
};

export const createAllowedIPsTableData = (rule: PortForwardingRule) => {
  if (!rule || !rule.allowedIps) {
    return [];
  }

  const ipAddresses = rule.allowedIps.filter((address) => address !== ALLOWED_IPS_ANY);
  return ipAddresses.map((address) => ({ address }));
};

export const newPortForwardingRule = (): PortForwardingRule => ({
  allowedIps: [ALLOWED_IPS_ANY],
  lanIp: "",
  localPort: "",
  name: "",
  protocol: "",
  publicPort: "",
  uplink: "both",
});

export function checkForBadReservedIpAddress(
  selectedIpAddress: IPAddressAssignment,
  reservedIpAddress: string,
) {
  return isReservedIP(selectedIpAddress) && reservedIpAddress === "";
}

export function checkForBadMacAddress(selectedIpAddress: IPAddressAssignment, macAddress: string) {
  return isReservedIP(selectedIpAddress) && macAddress === "";
}

export function isReservedIP(assignment: IPAddressAssignment) {
  return assignment === IPAddressAssignment.reserved;
}
