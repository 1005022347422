import { I18n } from "@meraki/core/i18n";
import { ExportTemplateType, Ssid, useSsids, useVlans, Vlan } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";

import { useCurrentNetwork } from "~/api/queries/networks/useNetwork";
import { MAX_SSIDS, SPACING } from "~/constants/MkiConstants";
import ExportTemplateButton from "~/go/components/networkTemplate/ExportTemplateButton";
import SelectAllHeader from "~/go/components/networkTemplate/SelectAllHeader";
import SsidExportRow from "~/go/components/networkTemplate/SsidExportRow";
import VlanExportRow from "~/go/components/networkTemplate/VlanExportRow";
import InputRow from "~/go/rows/InputRow";
import { ExportType, exportTypeSelect } from "~/lib/ExportTemplateUtils";
import { isUnconfiguredSSID } from "~/lib/SSIDUtils";
import { validFilename } from "~/lib/stringHelper";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import CheckBoxRow from "~/shared/rows/CheckBoxRow";

const ExportTemplateScreen = () => {
  const networkId = useCurrentNetworkId();
  const ssids = useSsids({ networkId }).data ?? [];
  const filteredSSIDs = ssids.slice(0, MAX_SSIDS).filter((ssid) => !isUnconfiguredSSID(ssid));
  const vlans = useVlans({ networkId }).data ?? [];

  const { data: networkData, isSuccess: networkQuerySuccess } = useCurrentNetwork();

  const [filename, setFilename] = useState<string>();
  const [selectedSsids, setSelectedSsids] = useState<number[]>([]);
  const [selectedVlans, setSelectedVlans] = useState<number[]>([]);
  const [genericNames, setGenericNames] = useState<boolean>(false);
  const [keepDhcp, setKeepDhcp] = useState<boolean>(false);

  useEffect(() => {
    if (networkQuerySuccess) {
      const date = new Date(Date.now()).toDateString().split(" ");
      const formattedDate = ` ${date[2]}${date[1]}${date[3]}`;
      const formattedNetworkName = networkData.name.replace(/ /g, "");
      setFilename(`${formattedNetworkName}_${formattedDate}`);
    }
  }, [networkData, networkQuerySuccess]);

  const rowTapHandler = (type: ExportType, id: number) => {
    const [selectedIds, setSelectedIds] = exportTypeSelect(
      {
        ssid: [selectedSsids, setSelectedSsids],
        vlan: [selectedVlans, setSelectedVlans],
      },
      type,
    );

    if (selectedIds.includes(id)) {
      const filteredIds = selectedIds.filter((selectedId) => selectedId != id);
      setSelectedIds(filteredIds);
    } else {
      setSelectedIds([...selectedIds, id].sort());
    }
  };

  const selectAllTapHandler = (type: ExportType, isBoxFilled: boolean) => {
    const [valuesToUse, setSelectedIds] = exportTypeSelect(
      {
        ssid: [filteredSSIDs, setSelectedSsids],
        vlan: [vlans, setSelectedVlans],
      },
      type,
    );

    if (isBoxFilled) {
      setSelectedIds([]);
    } else {
      const newList = valuesToUse
        .flatMap((value) => {
          // @ts-expect-error TS(2339): Property 'number' does not exist on type '{ mask?:... Remove this comment to see the full error message
          return type === "ssid" ? value.number : value.id;
        })
        .sort();
      setSelectedIds(newList);
    }
  };

  const forceFilenameValidation = (newFilename: string) => {
    validFilename(newFilename) || newFilename == "" ? setFilename(newFilename) : undefined;
  };

  const getCombinedNames = () => {
    const ssidNames = ssids
      .filter((ssid) => selectedSsids.includes(ssid.number ?? -1))
      .flatMap((ssid) => ssid.name);

    const vlanNames = vlans
      .filter((vlan) => selectedVlans.includes(vlan.id))
      .flatMap((vlan) => vlan.name);

    return ssidNames.concat(vlanNames).join(", ");
  };

  return (
    <FullScreenContainerView withScroll>
      {filteredSSIDs.length != 0 && (
        <View>
          <SelectAllHeader
            type={ExportType.ssid}
            selectAllTapHandler={selectAllTapHandler}
            selectedSsids={selectedSsids}
            ssidsLength={filteredSSIDs.length}
            selectedVlans={selectedVlans}
            vlansLength={vlans.length}
          />
          <FlatList
            style={styles.listSpacing}
            data={filteredSSIDs}
            keyExtractor={(item: Ssid) => `wireless${item.number}`}
            renderItem={({ item }) => (
              <SsidExportRow
                ssid={item}
                isSelected={selectedSsids.includes(item.number ?? -1)}
                tapHandler={rowTapHandler}
              />
            )}
            testID="SSID_LIST.TABLE"
          />
        </View>
      )}

      <SelectAllHeader
        type={ExportType.vlan}
        selectAllTapHandler={selectAllTapHandler}
        selectedSsids={selectedSsids}
        ssidsLength={filteredSSIDs.length}
        selectedVlans={selectedVlans}
        vlansLength={vlans.length}
      />
      <FlatList
        data={vlans}
        keyExtractor={(item: Vlan) => `wired${item.id}`}
        renderItem={({ item }) => (
          <VlanExportRow
            vlan={item}
            isSelected={selectedVlans.includes(item.id)}
            tapHandler={rowTapHandler}
          />
        )}
        testID="VLAN_LIST.TABLE"
      />

      <InputRow
        value={filename}
        onChangeText={(newFilename: string) => forceFilenameValidation(newFilename)}
        testID={"EXPORT_FILENAME"}
        description={"Will be exported as a JSON file"}
        clearTestID={"CLEAR_FILENAME"}
      >
        {I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.FILENAME")}
      </InputRow>
      <CheckBoxRow
        testID="GENERIC_NAMES_CHECKBOX_ROW"
        selected={genericNames}
        subtitle={I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.GENERIC_NAMES.SUBTITLE")}
        onPress={() => setGenericNames(!genericNames)}
      >
        {I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.GENERIC_NAMES.TITLE")}
      </CheckBoxRow>
      <CheckBoxRow
        testID="KEEP_DHCP_CHECKBOX_ROW"
        selected={keepDhcp}
        subtitle={I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.KEEP_DHCP.SUBTITLE")}
        onPress={() => setKeepDhcp(!keepDhcp)}
      >
        {I18n.t("IMPORT_EXPORT_NETWORKS.EXPORT.KEEP_DHCP.TITLE")}
      </CheckBoxRow>

      <ExportTemplateButton
        networkId={networkId}
        type={
          selectedSsids.length > 0 && selectedVlans.length > 0
            ? ExportTemplateType.all
            : selectedSsids.length > 0
              ? ExportTemplateType.ssid
              : ExportTemplateType.vlan
        }
        cleanNames={genericNames}
        ssidId={selectedSsids.length !== filteredSSIDs.length ? selectedSsids.join(",") : undefined}
        vlanId={selectedVlans.length !== vlans.length ? selectedVlans.join(",") : undefined}
        keepDhcp={keepDhcp}
        disabled={selectedVlans.length === 0 && selectedSsids.length === 0}
        filename={filename ?? ""}
        combinedNetworksList={getCombinedNames()}
      />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  listSpacing: {
    paddingBottom: SPACING.large,
  },
});

export default ExportTemplateScreen;
