import { pick } from "lodash";
import { createSelector } from "reselect";

import { portConnectedClientsByMac } from "~/lib/SwitchPortUtils";
import { clientsByMac } from "~/selectors/clients";
import { deviceClientsForSerialByMac } from "~/selectors/deviceClients";
import { getGxPorts } from "~/selectors/getters";
import { GXPort } from "~/shared/types/ApplianceTypes";
import { RootState } from "~/shared/types/Redux";

export const gxPortsForSerialSelector = (state: ReturnType<typeof getGxPorts>, serial: string) =>
  state?.[serial] ?? [];

export const switchPortClientsSelector = createSelector(
  clientsByMac,
  (state: RootState, payload: any) => deviceClientsForSerialByMac(state, payload.serialNumber),
  (_: RootState, payload: any) => payload.portNumber,
  (clientsByMac, deviceClientsByMac, portNumber) => {
    const portClients = portConnectedClientsByMac(deviceClientsByMac, portNumber);
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    return pick(clientsByMac, Object.keys(portClients));
  },
);

export const gxPortsForSerial = createSelector(
  getGxPorts,
  (_: RootState, serial: string, __: any) => serial,
  (_: RootState, __: any, portNumber: any) => portNumber,
  (gxPorts, serial: string, portNumbers?: number[]) => {
    let ports: GXPort[] = gxPortsForSerialSelector(gxPorts, serial);
    ports = Object.values(ports).sort(
      (port1: GXPort, port2: GXPort) => port1.number - port2.number,
    );

    if (portNumbers) {
      ports = ports.filter((port: GXPort) => portNumbers.includes(port.number));
    }

    return ports;
  },
);
