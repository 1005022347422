import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path, Rect } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Overview({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;
  const color2 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color3 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Rect fill={color1} height="10" rx="1" width="8" x="3" y="4" />
      <Path
        d="M3 17a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2ZM13 5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V5Z"
        fill={color2}
      />
      <Rect fill={color3} height="8" rx="1" width="8" x="13" y="12" />
    </Svg>
  );
}
