import { Pressable, PressableProps, ViewProps } from "react-native";

export interface PressableOpacityProps extends PressableProps {
  style?: ViewProps["style"];
  pressedOpacity?: number;
}

const PressableOpacity = ({ style, pressedOpacity = 0.4, ...others }: PressableOpacityProps) => {
  return (
    <Pressable
      {...others}
      style={({ pressed }) => [style, { opacity: pressed ? pressedOpacity : 1.0 }]}
    />
  );
};

export default PressableOpacity;
