import { ReactElement } from "react";
import { StyleProp, StyleSheet, TextStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import MerakiIcon from "~/shared/components/icons";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import { EllipsizeMode } from "~/shared/rows/ListRow";

export interface LiveEditInputRowProps {
  label: string;
  value?: string;
  subtitle?: string;
  icon?: ReactElement;
  accessory?: ReactElement;
  leftStyle?: StyleProp<TextStyle>;
  rightStyle?: StyleProp<TextStyle>;
  labelStyle?: StyleProp<TextStyle>;
  testID?: string;
  isFullButton?: boolean;
  onPress?: () => void;
  numberOfLines?: number;
  ellipsizeMode?: EllipsizeMode;
}

const LiveEditInputRow = (props: LiveEditInputRowProps) => {
  const {
    label,
    value,
    subtitle,
    icon,
    accessory,
    leftStyle,
    rightStyle,
    labelStyle,
    testID,
    isFullButton,
    onPress,
    numberOfLines,
    ellipsizeMode,
  } = props;

  const editIcon = <MerakiIcon name="edit" size="s" color={MkiColors.secondaryButton} />;

  return (
    <DisclosureRow
      value={value}
      subtitle={subtitle}
      icon={icon}
      accessory={accessory || editIcon}
      leftStyle={leftStyle || styles.leftStyle}
      rightStyle={rightStyle || styles.rightStyle}
      labelStyle={labelStyle || styles.labelStyle}
      testID={testID}
      isFullButton={isFullButton}
      onPress={onPress}
      numberOfLines={numberOfLines || 1}
      ellipsizeMode={ellipsizeMode || ("tail" as const)}
    >
      {label}
    </DisclosureRow>
  );
};

// styling for editable input row that shows input before tapping
const styles = StyleSheet.create({
  leftStyle: { width: "40%" },
  rightStyle: {
    width: "60%",
    justifyContent: "flex-end",
  },
  labelStyle: {
    color: MkiColors.secondaryTextColor,
  },
});

export default LiveEditInputRow;
