import { I18n } from "@meraki/core/i18n";

const LOGIN = "/login/login";

interface EndpointErrorResponse {
  ok: boolean;
  status: number;
}

interface EndpointError {
  message: string;
  response: EndpointErrorResponse;
}

export const ENDPOINTS_TO_ERROR_CODES = {
  [LOGIN]: {
    500: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.INVALID_RESPONSE"),
    401: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.INVALID_EMAIL_PASSWORD"),
    422: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.NO_ENTERPRISE_ACCESS"),
    429: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.TOO_MANY_ATTEMPTS"),
    412: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.EMAIL_NOT_VERIFIED"),
    503: I18n.t("ENDPOINT_ERRORS.LOGIN_ERRORS.PLEASE_WAIT"),
  },
};

export function getErrorMessageForEndpoint(endpoint: string, error: EndpointError) {
  const { message, response } = error;
  if (response === undefined) {
    return message;
  }

  const { status } = response;
  if (endpoint in ENDPOINTS_TO_ERROR_CODES) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const possibleErrorCodes = ENDPOINTS_TO_ERROR_CODES[endpoint];
    return possibleErrorCodes[status] || message;
  }
  return message;
}
