import { Alert, AlertProps } from "@meraki/magnetic/icons";

export type StatusProps = {
  testID?: string;
  status?: AlertProps["status"];
  size?: AlertProps["size"];
};

export function Status({ testID, status, size }: StatusProps) {
  if (!status) return null;

  return <Alert status={status} size={size} bordered={true} testID={testID} />;
}
