import { millisecondsToSeconds } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { z } from "zod";

import { request } from "../../../api/request/request";
import { oneHour, sevenDays, twentyFourHours } from "../../../constants";
import { createQuery } from "../../createQuery";

export const ProximitySchema = z.object({
  connected: z.number(),
  passerby: z.number(),
  visitors: z.number(),
});

export const SessionsSchema = z.array(
  z.tuple([z.number(), z.number(), z.number(), z.number(), z.number()]),
);

export const VisitorsSchema = z.object({
  daily: z.number(),
  first_timers: z.number(),
  monthly: z.number(),
  occasional: z.number(),
  weekly: z.number(),
});

export const PresenceReportSchema = z
  .array(
    z.tuple([
      z.number(),
      z.object({
        proximity: ProximitySchema,
        sessions: SessionsSchema,
        visitors: VisitorsSchema,
      }),
    ]),
  )
  .describe("PresenceReportSchema");

export type PresenceReport = z.infer<typeof PresenceReportSchema>;

interface PresenceReportRequest {
  organizationId?: string;
  networkEid: string;
  timespan: number;
}

const buildUrl = ({ organizationId }: Partial<PresenceReportRequest>) => {
  return `/nmr/o/${organizationId}/manage/reports/presence`;
};

const fetchPresenceReport = ({ organizationId, networkEid, timespan }: PresenceReportRequest) => {
  // get timestamp for the start of the day in UTC
  const t1 = millisecondsToSeconds(
    zonedTimeToUtc(new Date(new Date().setHours(0, 0, 0, 0)), "UTC").getTime(),
  );
  const t0 = t1 - timespan;
  const dt = timespan < sevenDays ? oneHour : twentyFourHours;

  return request(PresenceReportSchema, "GET", buildUrl({ organizationId }), {
    queryParams: {
      networkId: networkEid,
      t0,
      t1,
      dt,
    },
  });
};

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const usePresenceReport = createQuery({
  baseQueryKey: buildUrl({ organizationId: `{organizationId}` }),
  queryFn: (parameters: PresenceReportRequest) => fetchPresenceReport(parameters),
  requiredVariables: ["organizationId"],
});
