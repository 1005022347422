import { ThemeName, useTheme } from "@meraki/core/theme";
import { launchAndroidBeta, launchIOSBeta, launchOpenSourceUrl } from "@meraki/go/links";
import { showAlert } from "@meraki/shared/native-alert";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import CodePush from "react-native-code-push";
import DeviceInfo from "react-native-device-info";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import NFOs from "~/constants/NFOs";
import { getRevision } from "~/env";
import MessageBanner from "~/go/components/MessageBanner";
import { SettingsStackProps } from "~/go/navigation/Types";
import I18n from "~/i18n/i18n";
import { isAndroid, isWeb } from "~/lib/PlatformUtils";
import { defineStyleKey, normalizedFontSize, themeColors } from "~/lib/themeHelper";
import { getNFOs } from "~/selectors";
import SummaryList from "~/shared/components/SummaryList";
import ListRow from "~/shared/rows/ListRow";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  nfos: Set<NFOs>;
};

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "About"> & ReduxProps;

type AboutScreenState = {
  codePushVersion: string;
};

type RowProps = {
  onPress?: () => void;
  label?: string;
  value: string;
  theme: ThemeName;
};

function alert() {
  showAlert(I18n.t("ABOUT.REDIRECT"), "", [
    {
      text: I18n.t("OK"),
      isPreferred: true,
      onPress: () => {
        isAndroid() ? launchAndroidBeta() : launchIOSBeta();
      },
      style: "default",
    },
    {
      text: I18n.t("CANCEL"),
      isPreferred: false,
    },
  ]);
}

export class AboutScreen extends PureComponent<Props, AboutScreenState> {
  state = {
    codePushVersion: I18n.t("NONE"),
  };

  constructor(props: Props) {
    super(props);
    this.getCodePushVersion();
  }

  static renderDisclosureRow = (rowData: RowProps) => {
    const { onPress, label } = rowData;
    return <SimpleDisclosureRow onPress={onPress}>{label}</SimpleDisclosureRow>;
  };

  static renderRow = (rowData: RowProps) => {
    const { label, value, theme } = rowData;

    const rowStyles = [
      styles.rowStyles,
      defineStyleKey("borderBottomColor", themeColors(theme).border?.borderColor),
    ];
    return (
      <ListRow
        value={value}
        rowStyles={rowStyles}
        rightStyle={styles.rightContent}
        leftStyle={styles.leftContent}
        labelStyle={styles.labelStyle}
      >
        {label}
      </ListRow>
    );
  };

  launchOpenSource = () => {
    if (isWeb()) {
      launchOpenSourceUrl();
    } else {
      const { navigation } = this.props;
      navigation.navigate("OpenSourceLicenses");
    }
  };

  getCodePushVersion = () => {
    if (!isWeb()) {
      CodePush.getUpdateMetadata().then((update) => {
        if (update) {
          this.setState({ codePushVersion: update.label });
        }
      });
    }
  };

  render() {
    const { codePushVersion } = this.state;
    const { nfos } = this.props;
    const { theme } = useTheme.getState();
    const version = DeviceInfo.getVersion();
    const buildNumber = DeviceInfo.getBuildNumber();
    const revision = getRevision();
    const content = [
      { label: I18n.t("VERSION"), value: `${version}`, theme },
      { label: I18n.t("BUILD"), value: `${buildNumber}`, theme },
      { label: I18n.t("REVISION"), value: `${revision}`, theme },
    ];

    if (nfos.has(NFOs.hasMobileAlphaFeatures)) {
      content.push({ label: I18n.t("CODE_PUSH_UPDATE"), value: `${codePushVersion}`, theme });
    }

    return [
      <MessageBanner message={I18n.t("ABOUT.JOIN_BETA")} onPress={alert} key="messageBanner" />,
      <SummaryList
        key="version"
        contentRows={content}
        customRenderRow={AboutScreen.renderRow}
        disableBottomBorder
        testID="ABOUT.VERSION"
      />,
      <SummaryList
        key="legal"
        contentRows={[]}
        customRenderRow={AboutScreen.renderDisclosureRow}
        disableBottomBorder
        disclosureRows={[
          {
            label: I18n.t("OPEN_SOURCE"),
            onPress: this.launchOpenSource,
            isDisclosureRow: true,
            theme,
          },
        ]}
        heading={I18n.t("LEGAL")}
        headingTextStyle={styles.heading}
        testID="ABOUT.OPEN_SOURCE_LICENSES"
      />,
    ];
  }
}

const styles = StyleSheet.create({
  heading: {
    fontSize: normalizedFontSize(19),
    color: MkiColors.primaryButton,
  },
  rowStyles: {
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.default,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  labelStyle: {
    color: MkiColors.secondaryTextColor,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    nfos: getNFOs(state),
  };
}

export default connect(mapStateToProps)(AboutScreen);
