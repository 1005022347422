import { Icon, IconName } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { ColorToken } from "@meraki/magnetic/themes";

import { Loader } from "../Loader/Loader";
import { Text } from "../Text/Text";

export type NavButtonInternalProps = {
  text: string;
  color?: ColorToken;
  pressed?: boolean;
  loading?: boolean;
  disabled?: boolean | null;

  leadingIcon?: IconName;
  trailingIcon?: IconName;
};

export function NavButtonInternal({
  text,
  color,
  pressed,
  disabled,
  loading,

  leadingIcon,
  trailingIcon,
}: NavButtonInternalProps) {
  // This is just used to keep the conditional rendering of the loading state a little more clear
  let leadingIconElement = leadingIcon && (
    <Icon name={leadingIcon} color={color ?? "default.text.base"} size={20} />
  );

  if (loading) {
    leadingIconElement = <Loader.Spinner />;
  }

  return (
    <Box
      flexDirection="row"
      padding="xs"
      gap="2xs"
      alignItems="center"
      opacity={pressed || disabled || loading ? 0.4 : 1}
    >
      {leadingIconElement}
      <Text size="p1" color={color}>
        {text}
      </Text>
      {trailingIcon && <Icon name={trailingIcon} color={color ?? "default.text.base"} size={20} />}
    </Box>
  );
}
