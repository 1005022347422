import { I18n } from "@meraki/core/i18n";
import { SsidGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Heading,
  Input,
  List,
  Notification,
  Text,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import {
  queryClient,
  useSsid,
  useSsidSplashSettings,
  useUpdateSsid,
  useUpdateSsidSplashSettings,
} from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { isValidUrl } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";

import { convertFrequency, SPLASH_TIMEOUT_OPTIONS } from "../utils/splashUtils";

type ExternallyHostedForm = {
  splashUrl: string;
  redirectUrl: string;
  timeoutFrequency: number;
  walledGardenUrls: string[];
};

export function ExternallyHostedScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<SsidGroupProps>>();
  const networkId = useCurrentNetworkId();
  const route = useRoute<RouteProp<SsidGroupProps, "ExternallyHosted">>();

  const { params: props } = route;
  const { data: splashSettings } = useSsidSplashSettings({
    networkId,
    ssidNumber: props?.ssidNumber,
  });
  const { data: ssid } = useSsid({
    networkId,
    ssidNumber: props?.ssidNumber,
  });

  const updateSSIDSplashSettings = useUpdateSsidSplashSettings();
  const updateSsid = useUpdateSsid();

  const [inputWalledGarden, setInputWalledGarden] = useState<string>("");
  const [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const bottomSheetRef = useRef<BottomSheetMethods>(null);

  const methods = useForm<ExternallyHostedForm>({
    values: {
      splashUrl: splashSettings?.splashUrl ?? "",
      redirectUrl: splashSettings?.redirectUrl ?? "",
      timeoutFrequency: (splashSettings?.splashTimeout ?? 30) * 60,
      walledGardenUrls: ssid?.walledGardenRanges ?? [],
    },
  });

  const gardenUrls = methods.watch("walledGardenUrls");

  useEffect(() => {
    const onSubmit = methods.handleSubmit(
      ({ splashUrl, redirectUrl, timeoutFrequency, walledGardenUrls }: ExternallyHostedForm) => {
        updateSsid.mutate(
          {
            networkId,
            ssidNumber: props?.ssidNumber,
            body: {
              splashPage: "Click-through splash page",
              walledGardenEnabled: walledGardenUrls.length > 0,
              walledGardenRanges: [...walledGardenUrls],
            },
          },
          {
            onSuccess: () => {
              updateSSIDSplashSettings.mutate(
                {
                  networkId,
                  ssidNumber: props?.ssidNumber,
                  body: {
                    redirectUrl,
                    splashUrl,
                    useRedirectUrl: !!redirectUrl,
                    useSplashUrl: true,
                    splashTimeout: timeoutFrequency / 60,
                  },
                },
                {
                  onSuccess: () => navigation.goBack(),
                  onError: (error) => setApiError(error.errors[0]),
                  onSettled: () => {
                    queryClient.refetchQueries({ queryKey: useSsid.queryKeyRoot });
                    queryClient.refetchQueries({ queryKey: useSsidSplashSettings.queryKeyRoot });
                  },
                },
              );
            },
            onError: (error) => setApiError(error.errors[0]),
          },
        );
      },
    );
    navigation.setOptions({
      headerRight: () => <Button.Nav text={I18n.t("SAVE")} onPress={onSubmit} />,
    });
  }, [methods, navigation, networkId, props?.ssidNumber, updateSSIDSplashSettings, updateSsid]);
  return (
    <Screen.View addDefaultPadding>
      {apiError && (
        <Notification.Inline
          status="negative"
          message={apiError}
          onDismiss={() => setApiError(undefined)}
        />
      )}
      <Form {...methods}>
        <Form.Input
          name="splashUrl"
          autoCapitalize="none"
          label={I18n.t("EXTERNALLY_HOSTED_SCREEN.SPLASH_URL.TITLE")}
          additionalContext={I18n.t("EXTERNALLY_HOSTED_SCREEN.SPLASH_URL.HELPER_TEXT")}
          testID={"SPLASH_URL_INPUT"}
        />
        <Form.PickerCard
          name="timeoutFrequency"
          title={I18n.t("EXTERNALLY_HOSTED_SCREEN.TIMEOUT_FREQUENCY.TITLE")}
          options={SPLASH_TIMEOUT_OPTIONS.map((timeout) => ({
            label: convertFrequency(timeout, false),
            value: timeout,
          }))}
          onSelectOption={({ value }) => {
            methods.setValue("timeoutFrequency", value);
          }}
          testID={"TIMEOUT_FREQUENCY_PICKER"}
        />
        <Form.Input
          autoCapitalize="none"
          label={I18n.t("EXTERNALLY_HOSTED_SCREEN.REDIRECT_URL.TITLE")}
          name="redirectUrl"
          testID="REDIRECT_URL_INPUT"
        />
        <Box>
          <Box flexDirection="row" justifyContent="space-between">
            <Heading>{I18n.t("EXTERNALLY_HOSTED_SCREEN.WALLED_GARDEN.TITLE")}</Heading>
            <Button.Icon
              icon="Plus"
              color="interact.icon.focus"
              onPress={() => bottomSheetRef?.current?.present()}
            />
          </Box>
          <Text>{I18n.t("EXTERNALLY_HOSTED_SCREEN.WALLED_GARDEN.INFO")}</Text>
        </Box>
        <List.FlashList
          data={gardenUrls}
          getItemData={(item) => {
            return {
              title: item,
              rightAccessory: (
                <Button.Icon
                  icon="Trash"
                  onPress={() => {
                    let urls = methods.getValues("walledGardenUrls");
                    urls = urls.filter((u) => u !== item);
                    methods.setValue("walledGardenUrls", urls);
                  }}
                />
              ),
            };
          }}
        />
        <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
          <BottomSheet.Header
            title={I18n.t("EXTERNALLY_HOSTED_SCREEN.WALLED_GARDEN.TITLE")}
            onCancelPress={() => bottomSheetRef.current?.dismiss()}
            onResetPress={() => {
              if (inputWalledGarden === undefined || inputWalledGarden.length === 0) {
                bottomSheetRef.current?.dismiss();
              } else if (!isValidUrl(inputWalledGarden)) {
                setIsInvalidUrl(true);
              } else {
                const urls = methods.getValues("walledGardenUrls");
                urls.push(inputWalledGarden);
                methods.setValue("walledGardenUrls", urls);
                bottomSheetRef.current?.dismiss();
              }
            }}
            resetLabel="Enter"
          />
          <BottomSheet.Content>
            <Card>
              {isInvalidUrl && (
                <Notification.Inline
                  status="negative"
                  message={I18n.t("EXTERNALLY_HOSTED_SCREEN.WALLED_GARDEN.ERROR")}
                  onDismiss={() => setIsInvalidUrl(false)}
                />
              )}
              <Text size="p4">{I18n.t("EXTERNALLY_HOSTED_SCREEN.WALLED_GARDEN.INFO")}</Text>
              <Input
                autoCapitalize="none"
                onChangeText={setInputWalledGarden}
                value={inputWalledGarden}
              />
            </Card>
          </BottomSheet.Content>
        </BottomSheet.Modal>
      </Form>
    </Screen.View>
  );
}
