import * as errorMonitor from "@meraki/core/errors";
import { PureComponent } from "react";
import { StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import { SPACING } from "~/constants/MkiConstants";
import ConfigHeader from "~/go/components/ConfigHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import InputRow from "~/go/rows/InputRow";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import I18n from "~/i18n/i18n";
import { showAlert, showSaveWarning } from "~/lib/AlertUtils";
import { IP_ASSIGNMENT, validateVlanId } from "~/lib/SSIDUtils";
import { currentNetworkState, ssidsSelector } from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MkiText from "~/shared/components/MkiText";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import SwitchRow from "~/shared/rows/SwitchRow";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

const getDefaultVlanId = (ssid: SSID) => {
  return ssid == null || ssid.defaultVlanId == null ? "" : ssid.defaultVlanId.toString();
};

type ReduxProps = {
  networkId: string;
  ssid: SSID;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "VlanTagging"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

interface VlanTaggingState {
  reqPending: boolean;
  useVlanTagging: boolean;
  vlanId: string;
}

export class VlanTaggingScreen extends PureComponent<Props, VlanTaggingState> {
  constructor(props: Props) {
    super(props);

    const { ssid } = props;
    this.state = {
      reqPending: false,
      useVlanTagging: !!ssid?.useVlanTagging,
      vlanId: getDefaultVlanId(ssid),
    };
    this.setNavOptions();
  }

  setNavOptions() {
    const { navigation } = this.props;

    navigation.setOptions({
      headerLeft: () => <CancelButton onPress={this.cancelChanges} />,
      headerRight: () => <SaveButton onPress={this.save} disabled={!this.hasChanges()} />,
    });
  }

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate() {
    this.setNavOptions();
  }

  hasChanges = () => {
    const { ssid } = this.props;
    const { useVlanTagging, vlanId } = this.state;

    const isVlanTaggingDifferent = useVlanTagging !== !!ssid?.useVlanTagging;
    const isVlanIdChanged = vlanId !== getDefaultVlanId(ssid);
    const isVlanOn = useVlanTagging === true;

    return isVlanTaggingDifferent || (isVlanIdChanged && isVlanOn);
  };

  getData() {
    const { actions, networkId, setReqPending, ssid } = this.props;

    if (!ssid) {
      setReqPending(true);
      actions
        .getSsids(networkId)
        .then(() => setReqPending(false))
        .catch(() => {
          setReqPending(false);
          showAlert(I18n.t("ERROR"), I18n.t("SERVER_ERROR_TEXT"));
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { ssid } = this.props;
    const { reqPending } = this.state;

    if (nextProps.ssid !== ssid && !reqPending) {
      this.setState({
        useVlanTagging: !!ssid?.useVlanTagging,
        vlanId: getDefaultVlanId(nextProps.ssid),
      });
    }
  }

  onToggle = (enabled: boolean) => {
    this.setState({
      useVlanTagging: enabled,
    });
  };

  onChangeVlanId = (newVlanId: string) => {
    this.setState({
      vlanId: newVlanId,
    });
  };

  cancelChanges = () => {
    const { navigation } = this.props;
    this.hasChanges() ? showSaveWarning(this.save, navigation.goBack) : navigation.goBack();
  };

  validate = () => {
    const { useVlanTagging, vlanId } = this.state;
    const errorMessage = useVlanTagging && validateVlanId(vlanId);

    if (errorMessage) {
      showAlert(I18n.t("ERROR"), errorMessage);
      return false;
    }

    return true;
  };

  save = () => {
    const { actions, networkId, setReqPending, ssid, navigation } = this.props;
    const { useVlanTagging, vlanId } = this.state;
    if (!this.validate()) {
      return;
    }

    if (this.hasChanges()) {
      setReqPending(true);
      const updatedVlan = useVlanTagging ? vlanId : "1";
      actions
        .setSsid(networkId, {
          number: ssid.number,
          useVlanTagging: useVlanTagging,
          defaultVlanId: updatedVlan,
        })
        .then(() => {
          setReqPending(false);
          navigation.goBack();
        })
        .catch((error: unknown) => {
          setReqPending(false);
          showAlert(I18n.t("ERROR"), error || I18n.t("SERVER_ERROR_TEXT"));
        });
    } else {
      navigation.goBack();
    }
  };

  render() {
    const { ssid } = this.props;
    const { vlanId, reqPending, useVlanTagging } = this.state;

    const useVlanTaggingTogglRow =
      ssid && ssid.ipAssignmentMode === IP_ASSIGNMENT.NAT ? (
        // VLAN tagging is only available for BRIDGE mode
        <MkiText screenStyles={styles.errorText}>{I18n.t("MUST_BE_IN_BRIDGE_ERROR")}</MkiText>
      ) : (
        <SwitchRow
          disabled={reqPending}
          onValueChange={this.onToggle}
          testID="VLAN_TAGGING"
          value={useVlanTagging}
        >
          {I18n.t("VLAN_TAGGING.SUBTITLE")}
        </SwitchRow>
      );

    const vlanIdInput = useVlanTagging && (
      <InputRow
        autoCorrect={false}
        editable={!reqPending}
        placeholder={I18n.t("VLAN_TAGGING.VLAN_ID.PLACEHOLDER")}
        onChangeText={this.onChangeVlanId}
        testID="VLAN_ID"
        value={vlanId}
      >
        {I18n.t("VLAN_TAGGING.VLAN_ID.SUBTITLE")}
      </InputRow>
    );

    return (
      <FullScreenContainerView>
        <ConfigHeader title={I18n.t("VLAN_TAGGING.TITLE")} />
        {useVlanTaggingTogglRow}
        {vlanIdInput}
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  errorText: {
    marginLeft: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["VlanTagging"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDDetailsScreen",
      currentNetworkState(state),
    ),
    ssid: ssidsSelector(state)[props.ssidNumber],
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
)(VlanTaggingScreen);
