import {
  ASK_FOR_BIO_AUTH,
  GET_FIRMWARE_UPGRADES_FAILURE,
  GET_FIRMWARE_UPGRADES_REQUEST,
  GET_FIRMWARE_UPGRADES_SUCCESS,
  GET_WIRELESS_SETTINGS_FAILURE,
  GET_WIRELESS_SETTINGS_REQUEST,
  GET_WIRELESS_SETTINGS_SUCCESS,
  HIDE_ASK_FOR_BIO_AUTH,
  HIDE_MESSAGE_BANNER,
  INCREASE_APP_SURVEY_DISMISSALS,
  INCREASE_HOME_VISITS,
  SET_CAMERA_GRID_PREFERRED,
  SET_CAMERA_LIST_PREFERRED,
  SET_FIRMWARE_UPGRADES_FAILURE,
  SET_FIRMWARE_UPGRADES_REQUEST,
  SET_FIRMWARE_UPGRADES_SUCCESS,
  SET_HOME_SCREEN_CARDS_ORDER,
  SET_IS_SAML_USER,
  SET_LOCATION_ANALYTICS_ENABLED_FAILURE,
  SET_LOCATION_ANALYTICS_ENABLED_REQUEST,
  SET_LOCATION_ANALYTICS_ENABLED_SUCCESS,
  SET_SSO_SUBDOMAIN,
  UNPROMPT_SET_BIOAUTH,
  UPDATE_EXPANDABLE_CARDS,
  UPDATE_PDF_INFO,
  UPDATE_VISIBLE_INLINE_ALERTS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { BioAuthTypes, getBioAuthType } from "~/lib/BioAuth";
import { isWeb } from "~/lib/PlatformUtils";
import { ApiAction, ApiResponseAction, CALL_API } from "~/middleware/api";
import { ExpandableCards, HomeScreenCardsOrder } from "~/shared/types/Preferences";
import { AppThunk } from "~/shared/types/Redux";

export function getWirelessSettings(networkId: any): ApiAction {
  return {
    [CALL_API]: {
      types: [
        GET_WIRELESS_SETTINGS_REQUEST,
        GET_WIRELESS_SETTINGS_SUCCESS,
        GET_WIRELESS_SETTINGS_FAILURE,
      ],
      endpoint: `/api/v1/networks/${networkId}/wireless/settings`,
      config: { method: "GET" },
    },
  };
}

export function setLocationAnalyticsEnabled(
  networkId: any,
  isEnabled: boolean,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          SET_LOCATION_ANALYTICS_ENABLED_REQUEST,
          SET_LOCATION_ANALYTICS_ENABLED_SUCCESS,
          SET_LOCATION_ANALYTICS_ENABLED_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/wireless/settings`,
        config: { method: "PUT", body: JSON.stringify({ locationAnalyticsEnabled: isEnabled }) },
      }),
    );
}

export function getFirmwareUpgrades(networkId: any) {
  return {
    [CALL_API]: {
      types: [
        GET_FIRMWARE_UPGRADES_REQUEST,
        GET_FIRMWARE_UPGRADES_SUCCESS,
        GET_FIRMWARE_UPGRADES_FAILURE,
      ],
      endpoint: `/api/v1/networks/${networkId}/firmwareUpgrades`,
      config: { method: "GET" },
    },
  };
}

export function setFirmwareUpgrades(
  networkId: any,
  settings: any,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          SET_FIRMWARE_UPGRADES_REQUEST,
          SET_FIRMWARE_UPGRADES_SUCCESS,
          SET_FIRMWARE_UPGRADES_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/firmwareUpgrades`,
        config: { method: "PUT", body: JSON.stringify(settings) },
      }),
    );
}

export function increaseHomeVisits() {
  return {
    type: INCREASE_HOME_VISITS,
  };
}

export function increaseAppSurveyDismissals(count?: number) {
  return {
    type: INCREASE_APP_SURVEY_DISMISSALS,
    dismissalCount: count || 1,
  };
}

export function hidelineAlert(inlineAlertKey: string) {
  return {
    type: UPDATE_VISIBLE_INLINE_ALERTS,
    visibleInlineAlerts: { [inlineAlertKey]: false },
  };
}

export function updateExpandableCards(expandableCards: ExpandableCards) {
  return {
    type: UPDATE_EXPANDABLE_CARDS,
    expandableCards,
  };
}

export const updatePDFInfo = (networkId: any, ssidNumber: any, pdfInfo: any): AppThunk => {
  return (dispatch) =>
    dispatch({
      type: UPDATE_PDF_INFO,
      networkId,
      ssidNumber,
      pdfInfo,
    });
};

export const askForBioAuth = (): AppThunk => {
  return (dispatch) => {
    if (!isWeb()) {
      getBioAuthType().then((biometryType: BioAuthTypes | undefined) => {
        dispatch({
          type: ASK_FOR_BIO_AUTH,
          payload: { biometryType },
        });
      });
    }
  };
};

export function hideAskForBioAuth() {
  return {
    type: HIDE_ASK_FOR_BIO_AUTH,
  };
}

export function hideMessageBanner() {
  return {
    type: HIDE_MESSAGE_BANNER,
  };
}

export function setCameraGridPreferred() {
  return {
    type: SET_CAMERA_GRID_PREFERRED,
  };
}

export function setCameraListPreferred() {
  return {
    type: SET_CAMERA_LIST_PREFERRED,
  };
}

export function setIsSamlUser(isSamlUser: any) {
  return {
    type: SET_IS_SAML_USER,
    isSamlUser: isSamlUser,
  };
}

export function setValidSsoSubdomain(subdomain: any) {
  return {
    type: SET_SSO_SUBDOMAIN,
    ssoSubdomain: subdomain,
  };
}

export function setHomeScreenCardsOrder(newOrder: HomeScreenCardsOrder) {
  return {
    type: SET_HOME_SCREEN_CARDS_ORDER,
    homeScreenCardsOrder: newOrder,
  };
}

export function unpromptSetBioAuth() {
  return {
    type: UNPROMPT_SET_BIOAUTH,
  };
}
