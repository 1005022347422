import { I18n } from "@meraki/core/i18n";
import { UplinkUsage, useUplinkUsages } from "@meraki/react-live-broker";

import MkiColors from "~/constants/MkiColors";
import { ChartPoint, formatBitsAxis } from "~/lib/MkiChartUtils";
import MkiGraphLegend from "~/shared/components/MkiGraphLegend";
import { MkiLegendElementProps } from "~/shared/components/MkiLegendElement";
import MkiLineChart from "~/shared/components/MkiLineChart";
import MkiSpinner from "~/shared/components/MkiSpinner";

interface UplinkTrafficGraphProps {
  deviceId: string;
}

const UplinkTrafficGraph = ({ deviceId }: UplinkTrafficGraphProps) => {
  const uplinkData = useUplinkUsages(deviceId);
  if (!uplinkData) {
    return <MkiSpinner />;
  }

  const { wan0 } = uplinkData;
  const { download, upload, max } = formatUplinkData(wan0);

  const legendElements: MkiLegendElementProps[] = [
    {
      label: I18n.t("USAGE_CHART.DOWNLOAD"),
      colors: [MkiColors.primaryGraphLine],
    },
    {
      label: I18n.t("USAGE_CHART.UPLOAD"),
      colors: [MkiColors.secondaryGraphLine],
    },
  ];

  return (
    <>
      <MkiLineChart
        primaryData={download}
        secondaryData={upload}
        secondaryDataColor={MkiColors.secondaryGraphLine}
        yAxisFormatter={formatBitsAxis(max)}
        fullWidth
      />
      <MkiGraphLegend legendValues={legendElements} isElementSelected={() => true} />
    </>
  );
};

const formatUplinkData = (uplink: UplinkUsage[]) => {
  const download: ChartPoint[] = [];
  const upload: ChartPoint[] = [];
  let max = 0;

  function setNewMax(usageValue: number) {
    if (usageValue > max) {
      max = usageValue;
    }

    return usageValue;
  }

  if (uplink) {
    uplink.slice(-60).forEach((usage, idx) => {
      download[idx] = { x: usage[0], y: setNewMax(usage[1].in) };
      upload[idx] = { x: usage[0], y: setNewMax(usage[1].out) };
    });
  }

  return {
    download,
    upload,
    max,
  };
};

export default UplinkTrafficGraph;
