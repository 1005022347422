import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { I18n } from "@meraki/core/i18n";
import { BottomSheetMethods, List, ListItemProps, Text } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import { useRef } from "react";
import { Keyboard } from "react-native";

import { Picker, PickerProps } from "./Picker";

export type PickerCardProps<T> = Omit<PickerProps<T>, "options"> &
  Partial<Pick<PickerProps<T>, "options">> &
  Pick<ListItemProps, "rightAccessory"> & {
    title?: string;
    description?: string;
    emptyTitle?: string;
    testID?: string;
    disabled?: boolean;
  } & AnalyticsProp<"onOpen" | "onChange">;

export function PickerCard<T>({
  analytics,
  options,
  onSelectOption,
  selectedOption,
  title,
  description,
  emptyTitle,
  rightAccessory,
  testID,
  snapPoints,
  disabled,
  initialScrollIndex,
}: PickerCardProps<T>) {
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const trackEvent = useAnalytics(analytics);

  if (!options || options.length < 1) {
    return emptyTitle ? (
      <List testID="EMPTY_PICKER_CARD">
        <List.Item title={emptyTitle} />
      </List>
    ) : null;
  }

  return (
    <>
      <Box>
        <List testID={testID}>
          <List.Item
            title={title || I18n.t("PICKER_TITLE")}
            rightAccessory={
              <Box flexDirection="row" alignItems="center" gap="2xs">
                <Text size="p2" color={disabled ? "interact.text.disabled" : "interact.text.base"}>
                  {selectedOption?.label}
                </Text>
                {rightAccessory}
              </Box>
            }
            onPress={() => {
              if (!disabled) {
                bottomSheetRef.current?.present();
                Keyboard.dismiss();
                trackEvent("onOpen", { interaction: "open" });
              }
            }}
          />
        </List>
        {description && (
          <Box paddingTop="2xs" paddingLeft="xs">
            <Text color="light" size="p3">
              {description}
            </Text>
          </Box>
        )}
      </Box>

      <Picker
        analytics={analytics}
        ref={bottomSheetRef}
        options={options}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        snapPoints={snapPoints ?? ["CONTENT_HEIGHT"]}
        initialScrollIndex={initialScrollIndex}
      />
    </>
  );
}
