import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { formatPercent } from "~/lib/formatHelper";
import SingleBarGraph from "~/shared/components/SingleBarGraph";
import ListRow from "~/shared/rows/ListRow";

import { PeerGraphData } from "../components/PeerGraphUtils";

type Props = {
  children: string;
  usage: number;
  percent: number;
  color?: PeerGraphData["color"];
  group?: string;
  onPress?: () => void;
  screenStyles?: StyleProp<ViewStyle>;
};

const UsageRow = (props: Props) => {
  const { onPress, screenStyles, percent, color, group, usage, children } = props;
  const rowStyles: StyleProp<ViewStyle>[] = [styles.row];
  if (screenStyles) {
    rowStyles.push(screenStyles);
  }
  const data = color ? [{ percentage: percent, color }] : [{ percentage: percent }];

  const { value, unit } = formatAndParseKibibytes(usage);
  const usageLabel = `${value} ${unit}`;
  return (
    <ListRow
      onPress={onPress}
      subtitle1={group ? I18n.t("USAGE_ROW.enterprise.CATEGORY", { category: group }) : undefined}
      subtitle2={I18n.t("USAGE_ROW.enterprise.PERCENT_OF_USAGE", {
        usage: usageLabel,
        percentage: formatPercent(percent),
      })}
      accessory={<SingleBarGraph roundCorners={true} data={data} graphStyles={styles.usageGraph} />}
      rowStyles={rowStyles}
      ellipsizeMode="middle"
    >
      {children}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  row: {
    paddingLeft: SPACING.default,
    paddingRight: SPACING.large,
  },
  usageGraph: {
    width: 120,
    height: 12,
  },
});

export default UsageRow;
