import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Security({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color2 = color ? theme.color.brandAccent.icon.active : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M20 14V5.658c0-.4-.238-.76-.606-.918l-5-2.143a1 1 0 0 0-1.394.92v16.966c0 .718.726 1.182 1.35.827C16.384 20.154 20 17.594 20 14Z"
        fill={color1}
      />
      <Path
        d="M4 5.658V14c0 3.594 3.616 6.154 5.65 7.31.624.355 1.35-.109 1.35-.826V3.517a1 1 0 0 0-1.394-.92l-5 2.143A.997.997 0 0 0 4 5.658Z"
        fill={color2}
      />
    </Svg>
  );
}
