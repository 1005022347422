import { I18n } from "@meraki/core/i18n";
import { useTheme } from "@meraki/core/theme";
import {
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_MEBIBITS_INTEGERED,
  TRAFFIC_SHAPING_USAGE_INCREMENTS,
} from "@meraki/go/traffic-shaping";
import {
  GroupPolicies,
  GroupPolicy,
  queryClient,
  useCreateGroupPolicy,
  useGroupPolicies,
} from "@meraki/shared/api";
import { kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import { isEqual } from "lodash";
import { useEffect, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import CheckBox from "~/go/components/CheckBox";
import {
  getChangedTrackedClients,
  showActionSheet,
  showAlert,
  showSaveWarning,
} from "~/lib/AlertUtils";
import { andCommaSeparatedString, formatTransferBits } from "~/lib/formatHelper";
import { getUsageLimitPolicyName } from "~/lib/GroupPolicyUtils";
import { getPolicyAffectedNetworkNames } from "~/lib/SSIDUtils";
import { themeColors } from "~/lib/themeHelper";
import {
  getBlockedNetworksForClient,
  getClientDataByMac,
  getLimitedNetworksForClient,
  getTrackedClientsList,
  getWirelessNodeGroupEid,
  gxDeviceSelector,
  isClientConnectivityEnabled,
  slimSsidsByIdSelector,
} from "~/selectors";
import EditableNameHeader from "~/shared/components/EditableNameHeader";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import MerakiIcon from "~/shared/components/icons";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiText from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { CancelButton, SaveButton } from "~/shared/navigation/Buttons";
import DropDownRow from "~/shared/rows/DropDownRow";
import SimpleDisclosureRow from "~/shared/rows/SimpleDisclosureRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import { BlockedNetworks, ExtendedClient, LimitedNetworks } from "~/shared/types/Client";
import { PredefiendDevicePolicyID } from "~/shared/types/ClientPolicy";
import { BandwidthSettings } from "~/shared/types/GroupPolicyTypes";

import { LIMIT_STRING_KEYS } from "../components/identityPsk/GroupPolicySlider";
import { ClientScreensPropMap } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<ClientScreensPropMap, "BlockClient">;

export interface GroupPolicyState {
  [groupPolicyId: string]: GroupPolicy;
}

const BlockClientScreen = (props: Props) => {
  const { id } = props;
  const actions = useActions();
  const { client } = useAppSelector((s) => getClientDataByMac(s, { id }));
  const networkClient = client as ExtendedClient;
  const { theme } = useTheme();
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const ssidsById = useAppSelector(slimSsidsByIdSelector);
  const wirelessEid = useAppSelector(getWirelessNodeGroupEid);
  const gxDevice = useAppSelector(gxDeviceSelector);
  const blockedNetworks = useAppSelector((s) =>
    getBlockedNetworksForClient(s, { client: networkClient }),
  );
  const limitedNetworks = useAppSelector((s) =>
    getLimitedNetworksForClient(s, { client: networkClient }),
  );
  const clientConnectivityEnabled = useAppSelector(isClientConnectivityEnabled);
  const trackedClientsList = useAppSelector(getTrackedClientsList);
  const createGroupPolicy = useCreateGroupPolicy();

  let currentLimitIndex = 0;

  const [reqPending, setReqPending] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [currentlyBlockedNetworks, setCurrentlyBlockedNetworks] =
    useState<BlockedNetworks>(blockedNetworks);
  const [currentlyLimitedNetworks, setCurrentlyLimitedNetworks] =
    useState<LimitedNetworks>(limitedNetworks);
  const [limitIndex, setLimitIndex] = useState(0);
  const [limitSpeed, setLimitSpeed] = useState<string>("");

  const orderGroupPoliciesById = (groupPolicies?: GroupPolicies) => {
    const orderedPolicies: GroupPolicyState = {};
    if (!groupPolicies) {
      return orderedPolicies;
    }
    groupPolicies.forEach((groupPolicy: GroupPolicy) => {
      orderedPolicies[groupPolicy.groupPolicyId] = groupPolicy;
    });
    return orderedPolicies;
  };

  const getPoliciesByIndex = (groupPoliciesById?: GroupPolicyState) => {
    const policyIDs: string[] = new Array(TRAFFIC_SHAPING_USAGE_INCREMENTS + 1).fill("");
    if (!groupPoliciesById) {
      return policyIDs;
    }
    Object.entries(groupPoliciesById).forEach(([policyID, policy]) => {
      if (policy.bandwidth?.bandwidthLimits?.limitDown != null) {
        const policyLimit =
          TRAFFIC_SHAPING_MEBIBITS_INTEGERED[policy.bandwidth.bandwidthLimits.limitDown];
        if (typeof policyLimit === "number") {
          const index = TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(policyLimit);

          if (index !== -1) {
            policyIDs[index] = policyID;
          }
        }
      }
    }, {});

    return policyIDs;
  };

  const { data: groupPoliciesById } = useGroupPolicies(
    { networkId },
    {
      select: (data) => orderGroupPoliciesById(data),
    },
  );
  const policyIDsByIndex = getPoliciesByIndex(groupPoliciesById);
  const currentPolicyNum = Object.values(limitedNetworks.ssids).find((policy) => policy != null);
  if (currentPolicyNum != null) {
    const foundIndex = policyIDsByIndex.indexOf(currentPolicyNum);

    if (foundIndex !== -1) {
      currentLimitIndex = foundIndex;
    }
  }

  useEffect(() => {
    setCurrentlyLimitedNetworks(limitedNetworks);
    setLimitIndex(currentLimitIndex);
    setLimitSpeed(formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[currentLimitIndex]));
  }, [currentLimitIndex, limitedNetworks]);

  useEffect(() => {
    updateSaveButton(clientPolicyChanged());
  });

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CancelButton
          onPress={() => {
            if (clientPolicyChanged()) {
              showSaveWarning(save, navigation.goBack);
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
      headerRight: () => <SaveButton onPress={showSaveConfirmation} disabled={!saveEnabled} />,
    });
  });
  const clientPolicyChanged = () => {
    return (
      !isEqual(blockedNetworks, currentlyBlockedNetworks) ||
      !isEqual(limitedNetworks, currentlyLimitedNetworks)
    );
  };

  const updateSaveButton = (saveEnabled: boolean) => {
    setSaveEnabled(saveEnabled);
  };

  const updateClientName = (name: string) => {
    return actions
      .updateClient({ id: networkClient.id, description: name })
      .catch((err: unknown) => Promise.reject(err || I18n.t("SERVER_ERROR_TEXT")));
  };

  const save = async () => {
    try {
      setReqPending(true);
      let policyID = policyIDsByIndex[limitIndex];
      if (Object.values(currentlyLimitedNetworks.ssids).some((policy) => policy != null)) {
        // if string is empty, create new
        if (!policyID) {
          const translatedLimit = kilobitsToMebibitsInt(TRAFFIC_SHAPING_LIMIT_VALUES[limitIndex]);
          // update this
          createGroupPolicy.mutate(
            {
              networkId: networkId,
              name: getUsageLimitPolicyName(limitSpeed),
              bandwidth: {
                settings: BandwidthSettings.custom,
                bandwidthLimits: {
                  limitUp: translatedLimit,
                  limitDown: translatedLimit,
                },
              },
            },
            {
              onSuccess: (data) => {
                if (data.groupPolicyId != null) {
                  policyID = data.groupPolicyId;
                }
                queryClient.refetchQueries({ queryKey: useGroupPolicies.queryKeyRoot });
              },
            },
          );
        }
      }

      const formData: any = {};
      formData.access = "custom";
      const customPolicy = Object.values(ssidsById).reduce((policy, ssid) => {
        if (currentlyBlockedNetworks.ssids[ssid.number]) {
          policy[ssid.number] = PredefiendDevicePolicyID.blocked;
        } else if (currentlyLimitedNetworks.ssids[ssid.number] != null) {
          policy[ssid.number] = policyID;
        } else {
          policy[ssid.number] = PredefiendDevicePolicyID.normal;
        }

        return policy;
      }, {});
      customPolicy["wired"] = currentlyBlockedNetworks.wired
        ? PredefiendDevicePolicyID.blocked
        : PredefiendDevicePolicyID.normal;
      formData.custom_policy = customPolicy;
      formData.ids = [networkClient.id];
      formData.blocked_msg = "";
      await actions.setManyPolicies(wirelessEid, formData);
      // await actions.getClientPoliciesNewEndpoint();
      navigation.goBack();
    } catch (error) {
      handleError(error);
    }
    await actions.getClients();
  };

  const markAllBlocked = () => {
    setCurrentlyBlockedNetworks({
      ...currentlyBlockedNetworks,
      wired: true,
      ssids: {
        0: true,
        1: true,
        2: true,
        3: true,
      },
    });

    unmarkAllLimited();
  };

  const unmarkAllBlocked = () => {
    setCurrentlyBlockedNetworks({
      ...currentlyBlockedNetworks,
      wired: false,
      ssids: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
    });
  };

  const markAllLimited = () => {
    const policyID = policyIDsByIndex[limitIndex];
    setCurrentlyLimitedNetworks({
      ...currentlyLimitedNetworks,
      ssids: {
        0: policyID,
        1: policyID,
        2: policyID,
        3: policyID,
      },
    });
    unmarkAllBlocked();
  };

  const unmarkAllLimited = () => {
    setCurrentlyLimitedNetworks({
      ...currentlyLimitedNetworks,
      ssids: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined,
      },
    });
  };

  const hasAnyBlocked = () => {
    return (
      currentlyBlockedNetworks.wired ||
      Object.values(currentlyBlockedNetworks.ssids).some((ssidBlocked) => ssidBlocked)
    );
  };

  const hasAnyLimited = () => {
    return Object.values(currentlyLimitedNetworks.ssids).some((ssidBlocked) => ssidBlocked != null);
  };

  const showSaveConfirmation = () => {
    if (hasAnyBlocked()) {
      showActionSheet(
        [I18n.t("BLOCK_CLIENT.CONFIRM.BLOCK", { devices_word: I18n.t("DEVICE_WORD") })],
        save,
        {
          title: I18n.t("BLOCK_CLIENT.WARNING.BLOCK", {
            client_name: `${networkClient.description}`,
            ssid_names: andCommaSeparatedString(
              getPolicyAffectedNetworkNames(
                Object.values(ssidsById),
                gxDevice,
                currentlyBlockedNetworks,
              ),
            ),
          }),
        },
      );
    } else if (hasAnyLimited()) {
      showActionSheet(
        [I18n.t("THROTTLE_CLIENT.CONFIRM.LIMIT", { devices_word: I18n.t("DEVICE_WORD") })],
        save,
        {
          title: I18n.t("THROTTLE_CLIENT.WARNING.LIMIT", {
            client_name: `${networkClient.description}`,
            ssid_names: andCommaSeparatedString(
              getPolicyAffectedNetworkNames(
                Object.values(ssidsById),
                gxDevice,
                currentlyLimitedNetworks,
              ),
            ),
          }),
        },
      );
    } else {
      showActionSheet(
        [I18n.t("BLOCK_CLIENT.CONFIRM.UNBLOCK", { devices_word: I18n.t("DEVICE_WORD") })],
        save,
        {
          title: I18n.t("BLOCK_CLIENT.WARNING.UNBLOCK", {
            client_name: `${networkClient.description}`,
          }),
        },
      );
    }
  };

  const handleError = (error: unknown) => {
    setReqPending(false);
    showAlert(I18n.t("ERROR"), error);
  };

  const isTrackingEnabled = () => {
    const clientIsTracked = trackedClientsList?.find(
      (thisClient: any) => thisClient.mac === networkClient.mac,
    );
    return clientIsTracked && clientConnectivityEnabled;
  };

  const removeClientTrackingData = async (clientMac: any) => {
    setReqPending(true);

    const clients = trackedClientsList.filter(
      (removeClient: any) => removeClient.mac !== clientMac,
    );

    const enabled = clients.length > 0;
    const changedTrackedClients = getChangedTrackedClients(enabled, clients);
    try {
      await actions.updateAlertSettings(changedTrackedClients);
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }

    setReqPending(false);
    actions.deleteConnectivityEvents(clientMac.toUpperCase());
  };

  const addClientTrackingData = async (clientMac: any, clientName: any) => {
    setReqPending(true);

    const thisClient = {
      mac: clientMac,
      name: clientName || clientMac,
    };
    const clients = [...trackedClientsList, thisClient];

    const enabled = clients.length > 0;
    const changedTrackedClients = getChangedTrackedClients(enabled, clients);
    try {
      await actions.updateAlertSettings(changedTrackedClients);
    } catch (error) {
      showAlert(I18n.t("ERROR"), error);
    }

    setReqPending(false);
  };

  const updateTrackedClientsList = (newValue: any) => {
    const { mac, description } = networkClient;
    newValue ? addClientTrackingData(mac, description) : removeClientTrackingData(mac);
  };

  const renderClientTrackingToggle = () => {
    if (!gxDevice) {
      return;
    }

    return (
      <>
        <View style={styles.clientTrackingRow}>
          <MkiText textStyle="subheading">{I18n.t("TRACK_CLIENT.HEADING")}</MkiText>
          <MerakiIcon
            name="tracked"
            size="s"
            color={themeColors(theme).text?.heading.color}
            style={styles.clientTrackingIcon}
          />
        </View>
        <SwitchRow
          value={isTrackingEnabled()}
          onValueChange={updateTrackedClientsList}
          subtitle={I18n.t("TRACK_CLIENT.SUBHEADING")}
        ></SwitchRow>
      </>
    );
  };

  const renderBlockClient = () => {
    const rows = Object.values(ssidsById).map((ssid) => {
      const ssidNum = ssid.number;
      const ssidToggle = (enabled: boolean) => {
        setCurrentlyBlockedNetworks({
          ...currentlyBlockedNetworks,
          ssids: {
            ...currentlyBlockedNetworks.ssids,
            [ssidNum]: enabled,
          },
        });
        setCurrentlyLimitedNetworks({
          ssids: {
            ...currentlyLimitedNetworks.ssids,
            [ssidNum]: undefined,
          },
        });
      };
      const row = {
        value: currentlyBlockedNetworks.ssids[ssidNum],
        onValueChange: ssidToggle,
        disabled: reqPending,
        children: ssid.name,
        testID: `SSID.BLOCK_TOGGLE - ${ssidNum}`,
      };
      return row;
    });

    if (gxDevice) {
      const gxRow = {
        value: currentlyBlockedNetworks.wired,
        onValueChange: (enabled: boolean) => {
          setCurrentlyBlockedNetworks({
            ...currentlyBlockedNetworks,
            wired: enabled,
          });
        },
        disabled: reqPending,
        children: gxDevice.name,
        testID: "SSID.BLOCK_TOGGLE - GX",
      };

      rows.unshift(gxRow);
    }

    const allSelected =
      currentlyBlockedNetworks.wired &&
      Object.values(currentlyBlockedNetworks.ssids).every((ssidBlocked) => ssidBlocked);

    return (
      <DropDownRow
        title={I18n.t("BLOCK_CLIENT.HEADING")}
        testID="BLOCK_CLIENT.HEADING"
        openInitially={hasAnyBlocked()}
        noMargin
      >
        <MkiText screenStyles={styles.subheading} textStyle="smallSecondary">
          {I18n.t("BLOCK_CLIENT.SUBHEADING")}
        </MkiText>
        <SummaryList
          contentRows={rows}
          customRenderRow={SwitchRow}
          disableBottomBorder
          disableHeadingTop
          disableContainerTop
          hasSeparators
        />
        <SimpleDisclosureRow
          onPress={allSelected ? () => unmarkAllBlocked() : () => markAllBlocked()}
          disclosureIcon={
            <CheckBox selected={allSelected} screenStyles={{ margin: SPACING.default }} />
          }
        >
          {I18n.t("BLOCK_CLIENT.SELECT_ALL_CHECKBOX")}
        </SimpleDisclosureRow>
      </DropDownRow>
    );
  };

  const renderThrottleClient = () => {
    const rows = Object.values(ssidsById).map((ssid) => {
      const ssidNum = ssid.number;

      const ssidToggle = (enabled: any) => {
        const policyID = enabled ? policyIDsByIndex[limitIndex] : undefined;
        setCurrentlyLimitedNetworks({
          ...currentlyLimitedNetworks,
          ssids: {
            ...currentlyLimitedNetworks.ssids,
            [ssidNum]: policyID,
          },
        });
        setCurrentlyBlockedNetworks({
          ...currentlyBlockedNetworks,
          ssids: {
            ...currentlyBlockedNetworks.ssids,
            [ssidNum]: false,
          },
        });
      };

      const row = {
        value: currentlyLimitedNetworks.ssids[ssidNum] != null,
        onValueChange: ssidToggle,
        disabled: reqPending,
        children: ssid.name,
        testID: `SSID.LIMIT_TOGGLE - ${ssidNum}`,
      };
      return row;
    });

    if (rows.length === 0) {
      return null;
    }

    const allSelected = Object.values(currentlyLimitedNetworks.ssids).every(
      (ssidLimited) => ssidLimited != null,
    );
    const setLimit = (newLimitIndex: any) => {
      setLimitIndex(newLimitIndex);
      setLimitSpeed(formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[newLimitIndex]));
    };

    return (
      <DropDownRow
        title={I18n.t("THROTTLE_CLIENT.HEADING")}
        testID="THROTTLE_CLIENT.HEADING"
        openInitially={hasAnyLimited()}
        noMargin
      >
        <View style={styles.subheading}>
          <MkiText textStyle="smallSecondary">{I18n.t("THROTTLE_CLIENT.SUBHEADING")}</MkiText>
          <Slider
            maximumValue={TRAFFIC_SHAPING_USAGE_INCREMENTS}
            onValueChange={setLimit}
            step={1}
            value={limitIndex}
            minimumTrackTintColor={MkiColors.goPurple}
            style={styles.slider}
            testID="THROTTLE_CLIENT.SLIDER"
          />
          <MkiText>
            {I18n.t(LIMIT_STRING_KEYS[Math.min(limitIndex, 6)], {
              limit: limitSpeed,
            })}
          </MkiText>
        </View>
        <SummaryList
          contentRows={rows}
          customRenderRow={SwitchRow}
          disableBottomBorder
          disableHeadingTop
          disableContainerTop
          hasSeparators
        />
        <SimpleDisclosureRow
          onPress={allSelected ? () => unmarkAllLimited() : () => markAllLimited()}
          disclosureIcon={
            <CheckBox selected={allSelected} screenStyles={{ margin: SPACING.default }} />
          }
        >
          {I18n.t("THROTTLE_CLIENT.SELECT_ALL_CHECKBOX")}
        </SimpleDisclosureRow>
      </DropDownRow>
    );
  };

  return (
    <FullScreenContainerView withScroll keyboardShouldPersistTaps="handled">
      <EditableNameHeader<{ type: string; client: ExtendedClient }>
        title={networkClient.description || networkClient.mac}
        entity={I18n.t("DEVICE_WORD")}
        save={updateClientName}
        containerStyles={styles.clientNameHeader}
      />
      {renderClientTrackingToggle()}
      {renderBlockClient()}
      {renderThrottleClient()}
      <LoadingSpinner visible={reqPending} />
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  clientNameHeader: {
    paddingRight: SPACING.default,
    paddingVertical: SPACING.default,
  },
  clientTrackingRow: {
    flexDirection: "row",
    marginHorizontal: SPACING.default,
  },
  clientTrackingIcon: {
    marginLeft: SPACING.small,
  },
  subheading: {
    marginLeft: SPACING.default,
  },
  slider: {
    marginRight: SPACING.default,
  },
});

export default BlockClientScreen;
