import { I18n } from "@meraki/core/i18n";
import { DeviceGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheet,
  BottomSheetMethods,
  Button,
  Card,
  Input,
  Text,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useManagementInterface, useMutateManagementInterface } from "@meraki/shared/api";
import { Form, useForm } from "@meraki/shared/form";
import { SUBNET_VALUES } from "@meraki/shared/ip-address";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useRef } from "react";
import { Keyboard, Linking } from "react-native";

import {
  DOT_REGEX,
  dottedDecimalFromSubnetMask,
  subnetMaskFromDottedDecimal,
} from "../utils/StaticIPUtils";

const ALLOWED_MASKS = SUBNET_VALUES.map((mask: number) => mask.toString());

const IP_PLACEHOLDER = "192.168.128.20";
const SUBNET_LINK =
  "https://documentation.meraki.com/Go/Features/Wired_Networks_(VLANs)_in_Meraki_Go";

type StaticIPForm = {
  vlanID: string;
  IP: string;
  subnetMask: string;
  gateway: string;
  primaryDNS: string;
  secondaryDNS: string;
};
export const AddStaticIPScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DeviceGroupProps>>();
  const route = useRoute<RouteProp<DeviceGroupProps, "AddStaticIP">>();
  const { device } = route.params;
  const bottomSheetRef = useRef<BottomSheetMethods>(null);
  const subnetBottomSheet = useRef<BottomSheetMethods>(null);

  const { data: mgmentInterface, refetch } = useManagementInterface(
    {
      serial: device.serial,
    },
    {
      enabled: device !== undefined,
    },
  );

  const methods = useForm<StaticIPForm>({
    defaultValues: {
      vlanID: String(mgmentInterface?.wan1?.vlan ?? ""),
      IP: mgmentInterface?.wan1?.staticIp ?? "",
      subnetMask:
        subnetMaskFromDottedDecimal(mgmentInterface?.wan1?.staticSubnetMask ?? "").toString() ?? "",
      gateway: mgmentInterface?.wan1?.staticGatewayIp ?? "",
      primaryDNS: mgmentInterface?.wan1?.staticDns?.[0] ?? "",
      secondaryDNS: mgmentInterface?.wan1?.staticDns?.[1] ?? "",
    },
    values: {
      vlanID: String(mgmentInterface?.wan1?.vlan ?? ""),
      IP: mgmentInterface?.wan1?.staticIp ?? "",
      subnetMask:
        subnetMaskFromDottedDecimal(mgmentInterface?.wan1?.staticSubnetMask ?? "").toString() ?? "",
      gateway: mgmentInterface?.wan1?.staticGatewayIp ?? "",
      primaryDNS: mgmentInterface?.wan1?.staticDns?.[0] ?? "",
      secondaryDNS: mgmentInterface?.wan1?.staticDns?.[1] ?? "",
    },
  });

  const mutateVlan = useMutateManagementInterface();

  const save = ({ vlanID, IP, subnetMask, gateway, primaryDNS, secondaryDNS }: StaticIPForm) => {
    const staticDns: string[] = [primaryDNS];

    if (secondaryDNS.replace(DOT_REGEX, "") !== "") {
      staticDns.push(secondaryDNS);
    }

    mutateVlan.mutate(
      {
        body: {
          wan1: {
            usingStaticIp: true,
            staticIp: IP,
            staticSubnetMask: dottedDecimalFromSubnetMask(parseInt(subnetMask)),
            staticGatewayIp: gateway,
            staticDns: staticDns,
            // only appliances can have vlanID === ""
            vlan: vlanID === "" ? null : Number(vlanID),
          },
        },
        serial: device.serial,
      },
      {
        onSettled: () => {
          navigation.goBack();
          refetch();
        },
        onError: (error) => {
          if (error !== null && typeof error === "object" && "errors" in error) {
            // @ts-expect-error - this works on typescript version 5.1.6, DM-4693
            Alert.alert(String(error["errors"]));
          }
        },
      },
    );
    bottomSheetRef.current?.dismiss();
  };

  navigation.setOptions({
    headerRight: () => (
      <Button.Nav
        text={I18n.t("IP_CONFIG.SAVE")}
        onPress={methods.handleSubmit(save)}
        disabled={!methods.formState.isValid}
      />
    ),
  });

  return (
    <Screen scrollEnabled addDefaultPadding>
      <Form {...methods}>
        <Form.Input
          name="vlanID"
          label={I18n.t("IP_CONFIG.VLAN_ID")}
          additionalContext={
            device.productType === "appliance"
              ? I18n.t("IP_CONFIG.ADD_STATIC_IP.VLAN_ID_APPLIANCE_HELPER")
              : I18n.t("IP_CONFIG.ADD_STATIC_IP.VLAN_ID_HELPER")
          }
          rules={{ required: device.productType === "appliance" ? false : true }}
          placeholder="1"
        />
        <Form.Input name="IP" label="IP" placeholder={IP_PLACEHOLDER} rules={{ required: true }} />
        <Input
          label={I18n.t("IP_CONFIG.ADD_STATIC_IP.SUBNET")}
          placeholder="24"
          value={methods.watch("subnetMask")}
          editable={false}
          showClear={false}
          onFocus={() => {
            Keyboard.dismiss();
            subnetBottomSheet.current?.present();
          }}
        />
        <Form.Picker
          ref={subnetBottomSheet}
          snapPoints={["95%"]}
          name="subnetMask"
          options={ALLOWED_MASKS.map((mask) => ({ label: mask, value: mask }))}
          rules={{ required: true }}
        />
        <Box alignItems="flex-start">
          <Button
            text={I18n.t("IP_CONFIG.ADD_STATIC_IP.SUBNET_BUTTON")}
            kind="tertiary"
            onPress={() => {
              bottomSheetRef.current?.present();
            }}
          />
        </Box>

        <Form.Input
          name="gateway"
          label={I18n.t("IP_CONFIG.ADD_STATIC_IP.GATEWAY")}
          placeholder={IP_PLACEHOLDER}
          rules={{ required: true }}
        />
        <Form.Input
          name="primaryDNS"
          label={I18n.t("IP_CONFIG.ADD_STATIC_IP.PRIMARY_DNS")}
          placeholder={IP_PLACEHOLDER}
          rules={{ required: true }}
        />
        <Form.Input
          name="secondaryDNS"
          label={I18n.t("IP_CONFIG.ADD_STATIC_IP.SECONDARY_DNS")}
          placeholder={IP_PLACEHOLDER}
          rules={{ required: true }}
        />
      </Form>
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <BottomSheet.Header title={I18n.t("IP_CONFIG.ADD_STATIC_IP.SUBNET")} />
        <BottomSheet.Content>
          <Card>
            <Card.Header title={I18n.t("IP_CONFIG.ADD_STATIC_IP.SUBNET_BUTTON")} />
            <Text size="p1">{I18n.t("IP_CONFIG.ADD_STATIC_IP.SUBNET_TEXT")} </Text>
            <Box flexDirection="row" alignItems="center" gap="sm" justifyContent="flex-end">
              <Button
                text={I18n.t("IP_CONFIG.ADD_STATIC_IP.LEARN_MORE")}
                kind="tertiary"
                onPress={() => {
                  Linking.openURL(SUBNET_LINK);
                }}
              />
              <Button
                text={I18n.t("IP_CONFIG.ADD_STATIC_IP.OK")}
                onPress={() => bottomSheetRef.current?.dismiss()}
              />
            </Box>
          </Card>
        </BottomSheet.Content>
      </BottomSheet.Modal>
    </Screen>
  );
};
