import { ProductType } from "@meraki/shared/api";
import {
  CHANGE_VLAN_ID_FAILURE,
  CHANGE_VLAN_ID_REQUEST,
  CHANGE_VLAN_ID_SUCCESS,
  DEVICE_UPLINK_FAILURE,
  DEVICE_UPLINK_REQUEST,
  DEVICE_UPLINK_SUCCESS,
  GET_L3_FIREWALL_RULES_FAILURE,
  GET_L3_FIREWALL_RULES_REQUEST,
  GET_L3_FIREWALL_RULES_SUCCESS,
  NETWORK_WARM_SPARE_FAILURE,
  NETWORK_WARM_SPARE_REQUEST,
  NETWORK_WARM_SPARE_SUCCESS,
  UPDATE_L3_FIREWALL_RULES_FAILURE,
  UPDATE_L3_FIREWALL_RULES_REQUEST,
  UPDATE_L3_FIREWALL_RULES_SUCCESS,
} from "@meraki/shared/redux";

import { batch } from "~/actions/actionBatches";
import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentNetworkState,
  getCurrentOrganization,
  getL3FirewallRulesWithoutDefault,
} from "~/selectors";
import { Policy, Protocol, Rule } from "~/shared/types/ApplianceTypes";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

const ANY = "any" as const;
const SECURE_CIDR_IPS = ["192.168.0.0/16", "172.16.0.0/12", "10.0.0.0/8"];
const SECURE_NETWORK = "Secure network";

export function fetchDeviceUplink(
  productType: Omit<
    ProductType,
    "switch" | "camera" | "wireless" | "phone" | "sensor" | "systemsManager"
  >,
  serialNumber?: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const queryParams = {
      serials: [serialNumber],
    };
    const organizationId = getCurrentOrganization(getState());
    return dispatch({
      [CALL_API]: {
        types: [DEVICE_UPLINK_REQUEST, DEVICE_UPLINK_SUCCESS, DEVICE_UPLINK_FAILURE],
        endpoint: `/api/v1/organizations/${organizationId}/${productType}/uplink/statuses`,
        config: { method: Method.get, queryParams },
        meta: { serialNumber },
      },
    });
  };
}

export function fetchWarmSpareSettings(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [NETWORK_WARM_SPARE_REQUEST, NETWORK_WARM_SPARE_SUCCESS, NETWORK_WARM_SPARE_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/appliance/warmSpare`,
        config: { method: Method.get },
        meta: { networkId },
      },
    });
  };
}

export function fetchL3FirewallRules(): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      [CALL_API]: {
        types: [
          GET_L3_FIREWALL_RULES_REQUEST,
          GET_L3_FIREWALL_RULES_SUCCESS,
          GET_L3_FIREWALL_RULES_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/firewall/l3FirewallRules`,
        config: { method: Method.get },
        meta: {
          networkId,
        },
      },
    });
  };
}

export function addVlanSecurity(srcCidr: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const rules = getL3FirewallRulesWithoutDefault(getState());

    const secureRules = SECURE_CIDR_IPS.map((destCidr) => ({
      comment: SECURE_NETWORK,
      policy: Policy.deny,
      protocol: Protocol.any,
      destPort: ANY,
      destCidr,
      srcPort: ANY,
      srcCidr,
      syslogEnabled: false,
    }));

    const newRules = rules.concat(secureRules);

    return dispatch(updateL3FirewallRules(newRules));
  };
}

export function removeVlanSecurity(srcCidr: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const rules = getL3FirewallRulesWithoutDefault(getState());
    const newRules = rules.filter((rule: any) => rule.srcCidr !== srcCidr);
    return dispatch(updateL3FirewallRules(newRules));
  };
}

export function updateL3FirewallRules(rules: Rule[]): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          UPDATE_L3_FIREWALL_RULES_REQUEST,
          UPDATE_L3_FIREWALL_RULES_SUCCESS,
          UPDATE_L3_FIREWALL_RULES_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/appliance/firewall/l3FirewallRules`,
        config: {
          method: Method.put,
          body: JSON.stringify({ rules }),
        },
        meta: {
          networkId,
        },
      }),
    );
  };
}

export function changeVlanId(
  previousId: any,
  vlan: any,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch(
      batch({
        types: [CHANGE_VLAN_ID_REQUEST, CHANGE_VLAN_ID_SUCCESS, CHANGE_VLAN_ID_FAILURE],
        config: {
          method: Method.post,
          body: JSON.stringify({
            confirmed: true,
            synchronous: true,
            actions: [
              {
                resource: `/networks/${networkId}/appliance/vlans/${previousId}`,
                operation: "destroy",
              },
              {
                resource: `/networks/${networkId}/appliance/vlans`,
                operation: "create",
                body: vlan,
              },
            ],
          }),
        },
        meta: { previousId, networkId },
      }),
    );
  };
}
