import { I18n } from "@meraki/core/i18n";
import { communityUrl } from "@meraki/go/links";
import { useUpdateAppliancePort } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect, useState } from "react";
import { LayoutAnimation, ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import { useCombinedAppliancePorts } from "~/api/queries/appliances/useCombinedAppliancePorts";
import { errorMessageParser } from "~/api/util/error";
import { SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import PortInfoList from "~/go/components/port/PortInfoList";
import { HardwareStackPropMap } from "~/go/navigation/Types";
import { showAlert } from "~/lib/AlertUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { VLAN_ID_GO } from "~/lib/ApplianceUtils";
import { gxPortVlanSettingsIndexForNum, isPortEnabled, isUplinkPort } from "~/lib/GXPortUtils";
import { portTitle } from "~/lib/SwitchPortUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import ApplianceFront from "~/shared/components/portDiagrams/ApplianceFront";
import { SettingsButton } from "~/shared/navigation/Buttons";

const UPLINK_INFO_ALERT_CARD_ID = "UPLINK_INFO_ALERT_CARD";

type Props = ForwardedNativeStackScreenProps<HardwareStackPropMap, "GXPortDetails">;

export const GXPortDetailsScreen = (props: Props) => {
  const { portNumber, device } = props;

  setupAndroidLayoutAnimation();

  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();

  const { mutate, isLoading } = useUpdateAppliancePort();

  const { combinedPortData, refetchApiData, isApiLoading } = useCombinedAppliancePorts(
    networkId,
    device?.id,
  );
  const port = combinedPortData[gxPortVlanSettingsIndexForNum(portNumber)];

  const [dismissedAlertCardIds, setDismissedAlertCardIds] = useState<string[]>([]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: portTitle(isUplinkPort(port), portNumber),
    });

    if (port && !isUplinkPort(port)) {
      navigation.setOptions({
        headerRight: () => (
          <SettingsButton
            onPress={() => {
              navigation.navigate("AppliancePortSettings", {
                serialNumber: device.serial,
                selectedPorts: [port],
                onDismiss: () => refetchApiData(),
              });
            }}
          />
        ),
      });
    }
  });

  const closeCard = (key: string) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setDismissedAlertCardIds([...dismissedAlertCardIds, key]);
  };

  const enablePort = () => {
    /*
     * Setting `vlan` and `dropUntaggedTraffic` is a workaround for MX-10919
     * https://jira.ikarem.io/browse/MX-10919
     */
    const newPortConfig = {
      ...port,
      vlan: VLAN_ID_GO,
      dropUntaggedTraffic: false,
      enabled: true,
      number: portNumber,
    };

    mutate(
      { networkId, portId: portNumber.toString(), appliancePort: newPortConfig },
      {
        onSettled(_, error) {
          refetchApiData();

          if (error) {
            const errorMessage = errorMessageParser(error);
            if (errorMessage) {
              showAlert(I18n.t("ERROR"), errorMessage);
            }
          }
        },
      },
    );
  };

  const closeUplinkInfoAlert = () => {
    closeCard(UPLINK_INFO_ALERT_CARD_ID);
  };

  if (!port || isLoading || isApiLoading) {
    return <LoadingSpinner visible />;
  }

  return (
    <FullScreenContainerView>
      <ScrollView testID="PORT_DETAILS_SCROLL_VIEW">
        <ApplianceFront
          serialNumber={device.serial}
          selectedPorts={[port.number.toString() ?? portNumber.toString()]}
          hidePortStatusIcons
        />
        {isUplinkPort(port) && !dismissedAlertCardIds.includes(UPLINK_INFO_ALERT_CARD_ID) && (
          <InlineAlert
            visible
            onExit={closeUplinkInfoAlert}
            alertTitle={I18n.t("PORTS.INTERNET_PORT.TITLE")}
            alertMessage={I18n.t("PORTS.INTERNET_PORT.GX_MESSAGE")}
            primaryButtonText={I18n.t("LEARN_MORE")}
            onPrimaryPress={communityUrl} // TODO: Replace with Uplink specific links
            screenStyles={styles.inlineAlertStyle}
            testID="PORT_CARD_UPLINK"
          />
        )}
        {port && !isPortEnabled(port) && (
          <InlineAlert
            dismissible={false}
            visible
            alertTitle={I18n.t("PORTS.DISABLED_PORT.TITLE")}
            alertMessage={I18n.t("PORTS.DISABLED_PORT.MESSAGE")}
            primaryButtonText={I18n.t("PORTS.DISABLED_PORT.ENABLE_PORT")}
            onPrimaryPress={enablePort}
            secondaryButtonText={I18n.t("LEARN_MORE")}
            onSecondaryPress={communityUrl} // TODO: Replace with specific link
            screenStyles={styles.inlineAlertStyle}
            testID="PORT_CARD_DISABLED"
          />
        )}
        <PortInfoList port={port} />
      </ScrollView>
      {(isLoading || isApiLoading) && <LoadingSpinner visible />}
    </FullScreenContainerView>
  );
};

const styles = StyleSheet.create({
  inlineAlertStyle: {
    marginVertical: SPACING.default,
  },
});

export default GXPortDetailsScreen;
