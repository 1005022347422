import { z } from "zod";

import { request } from "../../api/request/request";
import { createQuery } from "../createQuery";

const buildUrl = ({ networkId }: NetworkConnectionsStatsRequest) => {
  return `/api/v1/networks/${networkId}/wireless/connectionStats`;
};

interface NetworkConnectionsStatsRequestQueryParams {
  timespan?: number;
  ssid?: number;
  vlan?: number;
  band?: string;
  apTag?: string;
}

interface NetworkConnectionsStatsRequest {
  networkId?: string;
}

type NetworkConnectionsStatsType = NetworkConnectionsStatsRequest &
  NetworkConnectionsStatsRequestQueryParams;

export const NetworkConnectionsStatsSchema = z.object({
  assoc: z.number(),
  auth: z.number(),
  dhcp: z.number(),
  dns: z.number(),
  success: z.number(),
});

export type NetworkConnectionsStats = z.infer<typeof NetworkConnectionsStatsSchema>;

const fetchNetworkConnectionsStats = ({
  networkId,
  ...queryParams
}: NetworkConnectionsStatsType) => {
  return request(NetworkConnectionsStatsSchema, "GET", buildUrl({ networkId }), { queryParams });
};

export const useNetworkConnectionsStats = createQuery({
  baseQueryKey: buildUrl({ networkId: `{networkId}` }),
  queryFn: (parameters: NetworkConnectionsStatsType) => fetchNetworkConnectionsStats(parameters),
  requiredVariables: ["networkId"],
});
