import { Theme } from "@meraki/magnetic/themes";

import { mapSpacingPropsToStyles, SpacingStyle } from "../Box/Box";

function setDefaultStyle(
  style: SpacingStyle,
  defaultStyle: SpacingStyle,
  keysToWatch: (keyof SpacingStyle)[],
): SpacingStyle {
  if (keysToWatch.some((ktw) => !!style[ktw])) return {};
  return defaultStyle;
}

export function getScreenStyles(
  style: SpacingStyle,
  addDefaultPadding: boolean | undefined,
  theme: Theme,
) {
  const horizontalPadding = setDefaultStyle(
    style,
    { paddingHorizontal: addDefaultPadding ? "md" : undefined },
    ["padding", "paddingHorizontal", "paddingLeft", "paddingRight"],
  );
  const verticalPadding = setDefaultStyle(
    style,
    { paddingVertical: addDefaultPadding ? "md" : undefined },
    ["padding", "paddingVertical", "paddingTop", "paddingBottom"],
  );
  const gap = setDefaultStyle(style, { gap: "sm" }, ["gap"]);

  return mapSpacingPropsToStyles(
    { ...style, ...horizontalPadding, ...verticalPadding, ...gap },
    theme,
  );
}
