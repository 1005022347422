import { analytics } from "@meraki/core/firebase";
import { useCallback } from "react";

export type AnalyticsObject<T> = {
  event: T | T[];
  eventName: string;
  params?: Record<string, unknown>;
};

export type AnalyticsProp<T> = {
  analytics?: AnalyticsObject<T> | AnalyticsObject<T>[];
};

function getEventConfigs<TEventType>(
  eventType: TEventType,
  config: AnalyticsProp<TEventType>["analytics"],
) {
  if (!config) return [];

  const configs = Array.isArray(config) ? config : [config];

  return configs.filter((c) => {
    const trackedEvents = Array.isArray(c.event) ? c.event : [c.event];
    return trackedEvents.some((te) => te === eventType);
  });
}

export function useAnalytics<TEventType>(config: AnalyticsProp<TEventType>["analytics"]) {
  const trackEvent = useCallback(
    (eventType: TEventType, params?: Record<string, unknown>) => {
      if (!config) return;

      const configs = getEventConfigs(eventType, config);

      configs.forEach((c) => {
        const mergedParams = {
          ...(params || {}),
          ...(c.params || {}),
        };

        analytics.logEvent(c.eventName, mergedParams);
      });
    },
    [config],
  );

  return trackEvent;
}
