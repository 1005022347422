import { I18n } from "@meraki/core/i18n";
import { getProductType } from "@meraki/shared/devices";
import { isEmpty } from "lodash";

import {
  defaultOnboardingStatusCounts,
  getOnboardingFooterStatuses,
} from "~/constants/OnboardingConstants";
import { HardwareRowData } from "~/go/components/HardwareRow";
import { OnboardingStackProps } from "~/go/navigation/Types";
import { DeviceModelsGo } from "~/go/types/DevicesTypes";
import { deviceName } from "~/lib/DeviceUtils";
import {
  determineStatusLeadingFromCounts,
  determineStatusTrailingFromCounts,
  getDescriptionByOnboardingStatus,
  getStatusByOnboardingStatus,
  onboardingStatusForNode,
} from "~/lib/OnboardingMappings";
import { isIOS, isWeb } from "~/lib/PlatformUtils";
import { onboardingFlow } from "~/selectors";
import Device, { DevicesBySerial } from "~/shared/types/Device";
import {
  NextOnboardingStageConfig,
  OnboardingFlows,
  OnboardingFooterStatus,
  OnboardingNodeBreakdown,
  OnboardingNodes,
  OnboardingNodeStatus,
  OnboardingScannedDevices,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";
import { store } from "~/store/configureStore";

export interface OnboardingFooterStatusAttributes {
  gradientStartColor: string;
  gradientEndColor: string;
  text: string;
  textColor: string;
}

interface NextStageOptions {
  noScannedHardware?: boolean;
  hasScannedWifi?: boolean;
  showUmbrella?: boolean;
  onboardingComplete?: boolean;
  skipTwoFactor?: boolean;
  hasGXWithVPN?: boolean;
  hasEnabledClientVPN?: boolean;
}

export const nodesStatusToFooterStatus = (status: OnboardingNodeStatus): OnboardingFooterStatus => {
  if (status === OnboardingNodeStatus.unstarted) {
    return OnboardingFooterStatus.hardwareUnstarted;
  }
  if (status === OnboardingNodeStatus.connecting) {
    return OnboardingFooterStatus.hardwareConnecting;
  }
  if (status === OnboardingNodeStatus.upgrading) {
    return OnboardingFooterStatus.hardwareUpgrading;
  }
  if (status === OnboardingNodeStatus.finished) {
    return OnboardingFooterStatus.hardwareFinished;
  }
  return OnboardingFooterStatus.hardwareConnectFailed;
};

export const getOnboardingFooterStatusAttributes = (
  status?: OnboardingFooterStatus,
): OnboardingFooterStatusAttributes | undefined => {
  if (status === undefined) {
    return undefined;
  }
  return getOnboardingFooterStatuses()[status];
};

export const getOnboardingHeaderForStatus = (status: OnboardingNodeStatus) => {
  if (status === OnboardingNodeStatus.unstarted) {
    return I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE_STATUS.HEADER.UNSTARTED");
  }
  if (status === OnboardingNodeStatus.upgrading) {
    return I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE_STATUS.HEADER.UPGRADING");
  }
  if (status === OnboardingNodeStatus.finished) {
    return I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE_STATUS.HEADER.FINISHED");
  }
  return I18n.t("ONBOARDING_FULLSTACK.CONNECT_AND_UPGRADE_STATUS.HEADER.CONNECTING"); // return for unstarted, connecting, and failed states
};

export const getNodesOnboardingStatus = (nodesStatus: OnboardingNodeBreakdown[]) => {
  const counts = getNodesOnboardingStatusCounts(nodesStatus);
  return determineStatusLeadingFromCounts(counts);
};

export const getNodesBreakdownStatusTrailing = (nodesStatus: OnboardingNodeBreakdown[]) => {
  const counts = getNodesOnboardingStatusCounts(nodesStatus);
  return determineStatusTrailingFromCounts(counts);
};

export const getNodesOnboardingStatusCounts = (nodesStatus: OnboardingNodeBreakdown[]) => {
  if (isEmpty(nodesStatus)) {
    return defaultOnboardingStatusCounts;
  }
  return nodesStatus.reduce((workingCounts, nodeStatus) => {
    const computedStatus = onboardingStatusForNode(nodeStatus);
    return {
      ...workingCounts,
      [computedStatus]: workingCounts[computedStatus] + 1,
    };
  }, defaultOnboardingStatusCounts);
};

export function mapOnboardingNodesToRowData(
  devices: DevicesBySerial,
  onboardingNodes: OnboardingNodes,
) {
  const hardwareRowData: HardwareRowData[] = [];
  if (isEmpty(devices)) {
    return hardwareRowData;
  }

  Object.entries(onboardingNodes).forEach((onboardingNode) => {
    const serial = onboardingNode[0];
    const onboardingStatus = onboardingNode[1];

    if (devices[serial]) {
      const { model, name } = devices[serial];
      if (model) {
        hardwareRowData.push({
          name: deviceName({ name, serial }),
          description: getDescriptionByOnboardingStatus(onboardingStatus),
          model: model as DeviceModelsGo,
          status: getStatusByOnboardingStatus(onboardingStatus),
        });
      }
    }
  });

  return hardwareRowData;
}

// TODO: Reconcile with getNodesOnboardingStatusCounts
export function getOnboardingDeviceCounts(statuses?: OnboardingNodeStatus[]) {
  const nodeCounts = {
    CONNECTING_UPGRADING: 0,
    FAILED_TO_CONNECT: 0,
    COMPLETE: 0,
  };
  if (!statuses) {
    return nodeCounts;
  }

  const { unstarted, upgrading, connecting, finished } = OnboardingNodeStatus;
  statuses.forEach((nodeStage) => {
    if (nodeStage === upgrading || nodeStage === connecting) {
      nodeCounts.CONNECTING_UPGRADING += 1;
    } else if (nodeStage === unstarted) {
      nodeCounts.FAILED_TO_CONNECT += 1;
    } else if (nodeStage === finished) {
      nodeCounts.COMPLETE += 1;
    }
  });

  return nodeCounts;
}

export const shouldShowHomeScreenOnboarding = (nodes: OnboardingNodes) => {
  return (
    !isEmpty(nodes) && Object.values(nodes).some((stage) => stage !== OnboardingNodeStatus.finished)
  );
};

export const getPluginHardwareMap = (devices: Device[]) => {
  const pluginHardwareMap: OnboardingScannedDevices = {};
  if (!devices) {
    return pluginHardwareMap;
  }
  const productTypes = devices.map((device) => getProductType(device.model));
  productTypes.forEach((productType) => {
    if (productType) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      pluginHardwareMap[productType] = true;
    }
  });

  return pluginHardwareMap;
};

export const getOnboardingScreen = (stage: OnboardingStage): keyof OnboardingStackProps => {
  switch (stage) {
    case OnboardingStage.addHardware:
      return "AddHardware";
    case OnboardingStage.scannedHardware:
      return "ScannedHardware";
    case OnboardingStage.plugIn:
      return "PlugIn";
    case OnboardingStage.connectUpgrade:
      return "ConnectAndUpgrade";
    case OnboardingStage.umbrellaExplainer:
      return "UmbrellaExplainer";
    case OnboardingStage.clientVPNSetup:
      return "ClientVPNSetup";
    case OnboardingStage.clientVPNInstruction:
      return "ClientVPNInstruction";
    case OnboardingStage.notificationsRequest:
      return "OnboardingNotificationsRequest";
    case OnboardingStage.wifiSetup:
      return "WiFiSetupCreate";
    case OnboardingStage.wifiSuccess:
      return "WiFiSetupCreateSuccess";
    case OnboardingStage.introTwoFactor:
      return "IntroTwoFactor";
    case OnboardingStage.setupTwoFactor:
      return "SetupTwoFactor";
    case OnboardingStage.verifyTwoFactor:
      return "VerifyTwoFactor";
    case OnboardingStage.successTwoFactor:
      return "SuccessTwoFactor";
    case OnboardingStage.setupComplete:
      return "SetupComplete";
    case OnboardingStage.alertsSettings:
      return "OnboardingAlerts";
    default:
      return "GetStarted";
  }
};

export const getNextOnboardingStageConfig = (
  currentScreen: OnboardingStage,
  {
    noScannedHardware,
    hasScannedWifi,
    showUmbrella,
    onboardingComplete,
    skipTwoFactor,
    hasGXWithVPN,
    hasEnabledClientVPN,
  }: NextStageOptions = {},
): NextOnboardingStageConfig => {
  const ios = isIOS();
  const web = isWeb();
  const isOnMainOnboarding = onboardingFlow(store.getState()) === OnboardingFlows.mainOnboarding;
  const setupCompleteOrTwoFactor = isOnMainOnboarding
    ? OnboardingStage.introTwoFactor
    : OnboardingStage.setupComplete;

  switch (currentScreen) {
    case OnboardingStage.getStarted:
      return getNextButton(OnboardingStage.addHardware);
    case OnboardingStage.addHardware:
      return getNextButton(OnboardingStage.scannedHardware);
    case OnboardingStage.scannedHardware:
      if (web) {
        return getNextButton(OnboardingStage.setupComplete);
      }

      if (noScannedHardware) {
        if (ios) {
          return getNextButton(OnboardingStage.notificationsRequest);
        } else {
          return getNextButton(setupCompleteOrTwoFactor);
        }
      } else {
        return getNextButton(OnboardingStage.plugIn);
      }
    case OnboardingStage.plugIn:
      if (onboardingComplete) {
        if (showUmbrella) {
          return getNextButton(OnboardingStage.umbrellaExplainer);
        }
        return getNextButton(setupCompleteOrTwoFactor);
      }
      return getNextButton(OnboardingStage.connectUpgrade);
    case OnboardingStage.connectUpgrade:
      if (ios) {
        return getNextButton(OnboardingStage.notificationsRequest);
      } else {
        return getNextButton(OnboardingStage.alertsSettings);
      }
    case OnboardingStage.notificationsRequest:
      if (noScannedHardware) {
        return getNextButton(setupCompleteOrTwoFactor);
      } else {
        return getNextButton(OnboardingStage.alertsSettings);
      }
    case OnboardingStage.alertsSettings:
      if (hasScannedWifi) {
        return getNextButton(OnboardingStage.wifiSetup);
      } else {
        return getNextButton(setupCompleteOrTwoFactor);
      }
    case OnboardingStage.wifiSetup:
      return getNextButton(OnboardingStage.wifiSuccess);
    case OnboardingStage.wifiSuccess:
      if (showUmbrella) {
        return getNextButton(OnboardingStage.umbrellaExplainer);
      } else {
        return getNextButton(setupCompleteOrTwoFactor);
      }
    case OnboardingStage.umbrellaExplainer:
      if (hasGXWithVPN) {
        return getNextButton(OnboardingStage.clientVPNSetup);
      } else {
        return getNextButton(setupCompleteOrTwoFactor);
      }
    case OnboardingStage.clientVPNSetup:
      return hasEnabledClientVPN
        ? getNextButton(OnboardingStage.clientVPNInstruction)
        : getNextButton(setupCompleteOrTwoFactor);
    case OnboardingStage.clientVPNInstruction:
      return getNextButton(setupCompleteOrTwoFactor);
    case OnboardingStage.introTwoFactor:
      if (skipTwoFactor) {
        return getNextButton(OnboardingStage.setupComplete);
      } else {
        return getNextButton(OnboardingStage.setupTwoFactor);
      }
    case OnboardingStage.setupTwoFactor:
      return getNextButton(OnboardingStage.verifyTwoFactor);
    case OnboardingStage.verifyTwoFactor:
      return getNextButton(OnboardingStage.successTwoFactor);
    case OnboardingStage.successTwoFactor:
      return getNextButton(OnboardingStage.setupComplete);
    case OnboardingStage.setupComplete:
      return getNextButton(OnboardingStage.exit);
    default:
      return getNextButton(OnboardingStage.exit);
  }
};

export const getNextButton = (nextStage: OnboardingStage) => {
  switch (nextStage) {
    case OnboardingStage.addHardware:
      return {
        nextStage: OnboardingStage.addHardware,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.SCAN_YOUR_HARDWARE"),
      };
    case OnboardingStage.scannedHardware:
      return {
        nextStage: OnboardingStage.scannedHardware,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.REVIEW_SCANNED_HARDWARE"),
      };
    case OnboardingStage.notificationsRequest:
      return {
        nextStage: OnboardingStage.notificationsRequest,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.GET_NOTIFIED"),
      };
    case OnboardingStage.plugIn:
      return {
        nextStage: OnboardingStage.plugIn,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.PLUG_IN_HARDWARE"),
      };
    case OnboardingStage.connectUpgrade:
      return {
        nextStage: OnboardingStage.connectUpgrade,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.CONNECT_AND_UPGRADE"),
      };
    case OnboardingStage.umbrellaExplainer:
      return {
        nextStage: OnboardingStage.umbrellaExplainer,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.SECURE_NETWORK"),
      };
    case OnboardingStage.clientVPNSetup:
      return {
        nextStage: OnboardingStage.clientVPNSetup,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.CLIENT_VPN_SETUP"),
      };
    case OnboardingStage.clientVPNInstruction:
      return {
        nextStage: OnboardingStage.clientVPNInstruction,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.CLIENT_VPN_INSTRUCTION"),
      };
    case OnboardingStage.wifiSetup:
      return {
        nextStage: OnboardingStage.wifiSetup,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.SET_UP_WIFI"),
      };
    case OnboardingStage.wifiSuccess:
      return {
        nextStage: OnboardingStage.wifiSuccess,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.CREATE_NETWORK"),
      };
    case OnboardingStage.introTwoFactor:
      return {
        nextStage: OnboardingStage.introTwoFactor,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.INTRO_TWO_FACTOR"),
      };
    case OnboardingStage.setupTwoFactor:
      return {
        nextStage: OnboardingStage.setupTwoFactor,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.SETUP_TWO_FACTOR"),
      };
    case OnboardingStage.verifyTwoFactor:
      return {
        nextStage: OnboardingStage.verifyTwoFactor,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.VERIFY_TWO_FACTOR"),
      };
    case OnboardingStage.successTwoFactor:
      return {
        nextStage: OnboardingStage.successTwoFactor,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.VERIFY_TWO_FACTOR"),
      };
    case OnboardingStage.alertsSettings:
      return {
        nextStage: OnboardingStage.alertsSettings,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.ALERTS"),
      };
    case OnboardingStage.setupComplete:
      return {
        nextStage: OnboardingStage.setupComplete,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.FINISH"),
      };
    case OnboardingStage.exit:
      return {
        nextStage: OnboardingStage.exit,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.EXIT_SETUP"),
      };
    default:
      return {
        nextStage: OnboardingStage.exit,
        nextButtonText: I18n.t("ONBOARDING_FULLSTACK.NEXT_BUTTON.EXIT_SETUP"),
      };
  }
};
