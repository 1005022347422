import { Platform } from "react-native";
import { check, Permission, PERMISSIONS, request, RESULTS } from "react-native-permissions";

const isIOS = () => Platform.OS === "ios";

export const LOCATION_PERMISSION_WHEN_IN_USE: Permission = isIOS()
  ? PERMISSIONS.IOS.LOCATION_WHEN_IN_USE
  : PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION;

export const CAMERA_PERMISSION: Permission = isIOS()
  ? PERMISSIONS.ANDROID.CAMERA
  : PERMISSIONS.IOS.CAMERA;

export async function getPermission(permission: Permission): Promise<string> {
  try {
    const result = await check(permission);
    return await onPermissionResult(result, permission);
  } catch (error) {
    return await Promise.reject(error);
  }
}

/**
 * We "should" only request permssions if we know the permission status
 * to be "DENIED". That's why this function is private and should only
 * be called after checking the permission first.
 * Permissions flow: https://github.com/zoontek/react-native-permissions#understanding-permission-flow
 */
async function requestPermission(permission: Permission): Promise<string> {
  const result = await request(permission);
  return await onPermissionResult(result, permission);
}

const onPermissionResult = (status: string, permission: Permission): Promise<string> => {
  switch (status) {
    case RESULTS.UNAVAILABLE:
    // This feature is not available (on this device / in this context)
    // fallthrough
    case RESULTS.BLOCKED:
    // The permission is denied and not requestable anymore
    // fallthrough
    case RESULTS.LIMITED:
      // The permission is limited: some actions are possible
      return Promise.reject(status);
    case RESULTS.GRANTED:
      // The permission is granted
      return Promise.resolve(status);
    case RESULTS.DENIED:
    // The permission has not been requested / is denied but requestable
    // fallthrough
    default:
      return requestPermission(permission);
  }
};
