import { formatDate } from "@meraki/core/date";
import { I18n } from "@meraki/core/i18n";
import { getThreshold, HIGH_USAGE_MIN_CLIENTS, totalUsage } from "@meraki/shared/filters";
import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import sub from "date-fns/sub";

import { DeviceClient } from "~/api/schemas/DeviceClient";
import {
  CLIENTS_SEARCH_FIELDS,
  CLIENTS_SEARCH_KEY,
  SM_CLIENTS_SEARCH_FIELDS,
  SM_CLIENTS_SEARCH_KEY,
} from "~/constants/SearchKeys";
import { ClientTypes } from "~/enterprise/types/ClientTypes";
import { getTimeAgo } from "~/lib/formatHelper";
import {
  isNumber,
  isString,
  verifyIsNetworkClient,
  verifyIsNetworkClientSM,
  verifyIsSMDevice,
} from "~/lib/TypeHelper";
import GeneralStatus from "~/shared/constants/Status";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import {
  Client,
  ClientConnections,
  ClientList,
  ExtendedClient,
  ExtraNetworkClientFields,
  NetworkClientOrSMDevice,
  NetworkClientType,
  SMDeviceType,
} from "~/shared/types/Client";
import { ClientIconType, ClientOS } from "~/shared/types/ClientIconType";
import { PolicyOnClient, PolicyTypeOnClient } from "~/shared/types/ClientPolicy";
import { ConnectionType } from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { NetworkTypesWithId } from "~/shared/types/Networks";
import { SystemsManagerDevice } from "~/shared/types/SystemsManager";

const BASE_POLICIES = {
  "-1": "normal",
  0: "whitelisted",
  1: "blacklisted",
};

export function getClientOSIcon(client: NetworkClientOrSMDevice) {
  if (verifyIsNetworkClient(client)) {
    return null;
  }
  return getClientIconTypeByOS(client.osName);
}

export function getClientTypeIcon(client: NetworkClientOrSMDevice) {
  if (verifyIsSMDevice(client)) {
    return ClientIconType.sm;
  }
  if (client.clientVpnDetails) {
    return ClientIconType.vpn;
  }
  if (client.mxWireless || client.wireless || isNumber(client.connectedBy)) {
    return ClientIconType.wireless;
  }
  return ClientIconType.port;
}

export function getClientIconTypeByOS(os: string) {
  const osLower = os?.toLowerCase?.();

  if (osLower) {
    if (osLower.includes(ClientOS.android)) {
      return ClientIconType.android;
    } else if (osLower.includes(ClientOS.chrome)) {
      return ClientIconType.chrome;
    } else if (
      osLower.includes(ClientOS.ios) ||
      osLower.includes(ClientOS.mac) ||
      osLower.includes(ClientOS.osx) ||
      osLower.includes(ClientOS.apple) ||
      osLower.includes(ClientOS.ipad)
    ) {
      return ClientIconType.apple;
    } else if (osLower.includes(ClientOS.windows)) {
      return ClientIconType.windows;
    } else if (osLower.includes(ClientOS.linux)) {
      return ClientIconType.linux;
    } else if (osLower.includes(ClientOS.google)) {
      return ClientIconType.google;
    } else if (osLower.includes(ClientOS.voip)) {
      return ClientIconType.phone;
    }
  }

  return null;
}

/** @deprecated in favour of getClientOSIcon and getClientTypeIcon */
export function getClientIconType(client: NetworkClientOrSMDevice) {
  if (!client) {
    return null;
  }

  if (verifyIsSMDevice(client) || verifyIsNetworkClientSM(client)) {
    return getClientIconTypeByOS(client.osName);
  } else {
    if (client.clientVpnDetails) {
      return ClientIconType.vpn;
    }

    if (isString(client.connectedBy)) {
      if (client.mxWireless) {
        return ClientIconType.wireless;
      } else {
        return ClientIconType.port;
      }
    } else if (isNumber(client.connectedBy)) {
      return ClientIconType.wireless;
    }
  }

  return null;
}

export const clientConnectionType = (client: Client & ExtraNetworkClientFields) => {
  if (client.clientVpnDetails) {
    return ConnectionType.vpn;
  }
  if (client.mxWireless || client.wireless || isNumber(client.connectedBy)) {
    return ConnectionType.wireless;
  }
  return ConnectionType.wired;
};

const LOCK_PIN_LENGTH = 6;
const LOCK_PIN_REGEX = /^[0-9\b]+$/;

export function validateLockPinLength(pin: string) {
  return pin.length <= LOCK_PIN_LENGTH;
}

export function validateLockPin(pin: string) {
  if (pin.length !== LOCK_PIN_LENGTH) {
    return I18n.t("CLIENT_DETAILS.TOOLS.PIN_MODAL.INSUFFICIENT_LENGTH");
  }
  if (!LOCK_PIN_REGEX.test(pin) || !parseInt(pin)) {
    return I18n.t("CLIENT_DETAILS.TOOLS.PIN_MODAL.NOT_NUMERIC");
  }
  return null;
}

export function smSortByOnlineAndTotalUsage(clientA: SMDeviceType, clientB: SMDeviceType) {
  if (!clientA?.isOnline && clientB?.isOnline) {
    return 1;
  }
  if (clientA?.isOnline && !clientB?.isOnline) {
    return -1;
  }
  if (verifyIsNetworkClientSM(clientA) && verifyIsNetworkClientSM(clientB)) {
    return (clientB.totalUsage ?? 0) - (clientA.totalUsage ?? 0);
  }
  return -1;
}

export function mergeClients(
  smDevices?: SystemsManagerDevice[],
  networkMacs?: Set<string>,
  networkClients?: (Client & ExtraNetworkClientFields)[],
): NetworkClientOrSMDevice[] {
  const clients: NetworkClientOrSMDevice[] = [];
  const smAndNetworkMacs: any = {};

  // adding the sm-only clients to output array
  if (smDevices) {
    smDevices.forEach((device) => {
      if (!networkMacs?.has(device.wifiMac)) {
        clients.push({
          ...device,
          smId: device.id,
          clientType: ClientTypes.sm,
          connectionType: ConnectionType.none,
        });
      } else {
        smAndNetworkMacs[device.wifiMac] = device;
      }
    });
  }

  // adding sm+network hybrids & network-only clients to output array
  networkClients?.forEach((client: Client & ExtraNetworkClientFields) => {
    if (client.mac in smAndNetworkMacs) {
      const smDevice = smAndNetworkMacs[client.mac];
      clients.push({
        ...smDevice,
        smId: smDevice.id,
        ...client,
        description: client.description,
        clientType: ClientTypes.smAndNetwork,
        connectionType: clientConnectionType(client),
      });
    } else {
      clients.push({
        ...client,
        clientType: ClientTypes.network,
        connectionType: clientConnectionType(client),
      });
    }
  });

  return clients;
}

const getPortNumber = (port: string | number) => {
  if (typeof port === "string") {
    return `${parseInt(port.replace(/port/g, ""), 10) + 1}`;
  }
  return `${port + 1}`;
};

const getDescription = (client: Client) => {
  if (client.description) {
    return client.description.replace(/\.local$/, "");
  }
  return client.mac || "(unknown)";
};

/*
 * This code is mostly copied from manage/private/javascripts/meta_client.js
 * It has been changed to be static and broken out into helper functions without changing any logic
 */
const isSwitch = (client: Client) => (client.products?.indexOf("s") ?? -1) >= 0;

const isWired = (client: Client) => (client.products?.indexOf("x") ?? -1) >= 0;

const isWireless = (client: Client) => (client.products?.indexOf("r") ?? -1) === 0;

const ssidNum = (c: Client) => {
  const m = typeof c.connectedBy === "string" && c.connectedBy.match(/apr\dv(\d)/);
  return Number(m && m[1]);
};
const isMxWireless = (c: Client) => isWireless(c) && !!ssidNum(c);

const getPortAndSsid = (client: Client) => {
  let port: string | number = "";
  let ssid: string | number | undefined;
  if (client.connectedBy === undefined || client.connectedBy === null) {
    // do nothing.
  } else if (isWireless(client)) {
    ssid = client.connectedBy;
  } else if (isMxWireless(client)) {
    ssid = ssidNum(client);
  } else if (isWired(client) && !isSwitch(client)) {
    port = getPortNumber(client.connectedBy);
  } else {
    port = client.connectedBy;
  }
  return { port, ssid };
};

export function clientInfo(
  client: Client,
  ssids: SSID[],
  networkTypes: NetworkTypesWithId,
  isHighUsage: boolean,
) {
  const improvedClientFields: ExtraNetworkClientFields = {
    description: getDescription(client),
    totalUsage: client.usage?.total,
    os: osSanityFilter(client.os ?? "", client.manufacturer ?? ""),
    switch: isSwitch(client),
    wired: isWired(client),
    wireless: isWireless(client),
    mxWireless: isMxWireless(client),
    // @ts-expect-error TS(2322): Type 'PolicyOnClient[] | { policy: string | null; ... Remove this comment to see the full error message
    policies: buildPolicy(client, ssids, networkTypes),
    highUsage: isHighUsage,
    ...getPortAndSsid(client),
  };

  return {
    ...client,
    ...improvedClientFields,
  };
}

export const clientOrSmName = (client: NetworkClientOrSMDevice) => {
  if (verifyIsSMDevice(client)) {
    return client.name;
  }
  return clientName(client);
};

export const clientName = (client: Client | DeviceClient) => client.description || client.mac;

export const usageString = (client: NetworkClientOrSMDevice) => {
  if (verifyIsSMDevice(client) || !client.totalUsage) {
    return null;
  }
  const { value, unit } = formatAndParseKibibytes(client.totalUsage);
  return `${value} ${unit}`;
};

export const getLastSeenDate = (client: NetworkClientOrSMDevice) => {
  const lastSeen = verifyIsSMDevice(client) ? client.lastConnected : new Date(client.lastSeen);

  return formatDate(lastSeen, {
    dateFormat: "shortDate",
    timeFormat: "shortTime",
  });
};

export const getUser = (client: NetworkClientOrSMDevice) => {
  if (verifyIsNetworkClient(client)) {
    return null;
  }
  return client.lastUser ? I18n.t("CLIENTS_LIST.LAST_USER", { user: client.lastUser }) : undefined;
};

// Copied from Web Dashboard (managed) ClientDetailsUtils.js
export function clientOS(client: ExtendedClient) {
  const { os, manufacturer } = client;

  const deviceOs = os || "";
  const deviceMfr = manufacturer || "";

  return deviceOs.indexOf(deviceMfr) >= 0 ? deviceOs : `${deviceMfr} ${deviceOs}`.trim();
}

export function deriveStatusWithLivebroker(
  client: NetworkClientOrSMDevice,
  livebrokerIsOnline: boolean,
) {
  return livebrokerIsOnline || isClientOnline(client) ? GeneralStatus.good : GeneralStatus.dormant;
}

// A client is high usage if their usage is at or above two standard
// devitions from the mean usage.
export function calculateOutlierUsage(clients: ClientList) {
  const clientUsages = Object.values(clients);
  if (clientUsages.length < HIGH_USAGE_MIN_CLIENTS) {
    return clientUsages.map(() => false);
  }
  const thresholdUsage = getThreshold(clientUsages);
  return clientUsages.map((client) => totalUsage(client) >= thresholdUsage);
}

/* -------------------------- HELPERS -------------------------------*/

function buildPolicy(client: Client, ssids: SSID[], networkTypes: NetworkTypesWithId) {
  const num = policyNum(client, networkTypes);
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (num && BASE_POLICIES[num]) {
    return [{ policy: num }];
  }
  if (num === "group") {
    return [{ policy: groupPolicyNum(client) }];
  }
  return customPolicyDescription(client, ssids); // multiple policies

  // TODO: handle 8021x group policies
  // /* Include the 802.1x group, so it too can be searched for using the "acl:' prefix.
  //  * Policy is not "normal" if 802.1x policy is applied
  //  */
  // if (this.group_policy_8021x) {
  //   if (policyType === "normal") this.policy_text_description = this.group_policy_8021x;
  //   else this.policy_text_description += "," + this.group_policy_8021x;
  // }
}

function policyNum(client: Client, networkTypes: NetworkTypesWithId) {
  // shortcut for clients without policies, which is probably the common case
  if (
    (!client.wirelessGroupNums || client.wirelessGroupNums === "-1") &&
    (!client.wiredGroupNum || client.wiredGroupNum === "-1")
  ) {
    return "-1";
  }

  const wiredAndWirelessDiff =
    networkTypes.hasWireless &&
    networkTypes.hasWired &&
    client.wiredGroupNum !== client.wirelessGroupNums;
  const hasPerSsidSettings = client.ssidMasks && client.ssidMasks !== "1";
  if (wiredAndWirelessDiff || hasPerSsidSettings) {
    return "custom";
  }

  let group = client.wirelessGroupNums || client.wiredGroupNum;
  if (Number(group) > 1) {
    return "group";
  }
  [group] = group ? group.split(",") : null;

  return group;
}

function groupPolicyNum(client: Client) {
  return wirelessGroup(client) || wiredGroup(client);
}

function wirelessGroup(client: Client, s?: number) {
  const { ssid: ssidStr } = getPortAndSsid(client);
  if (!client.ssidMasks || !client.wirelessGroupNums) {
    return null;
  } // not a wireless client
  if (client.ssidMasks === "1") {
    return client.wirelessGroupNums;
  }

  const ssid = Number.isInteger(s) ? Number(s) : ssidStr ? parseInt(ssidStr.toString(), 10) : null;
  if (ssid === null) {
    return null;
  }
  // iterate through all the ssid_masks looking for a hit.
  const wgs = client.wirelessGroupNums.split(",");
  let group;
  const thisSsidMask = 1 << (ssid + 1);
  client.ssidMasks.split(",").forEach((mask, idx) => {
    if (thisSsidMask & Number(mask)) {
      group = wgs[idx];
    }
  });
  return group;
}

function wiredGroup(client: Client) {
  if (!client.wiredGroupNum) {
    return null;
  }
  return parseInt(client.wiredGroupNum, 10) >= 0 ? client.wiredGroupNum : null;
}

function customPolicyDescription(client: Client, ssids: SSID[]) {
  const policies: PolicyOnClient[] = [];
  const wiredPolicy = wiredGroup(client);
  if (wiredPolicy) {
    policies.push({ policy: wiredPolicy, type: PolicyTypeOnClient.wired });
  }
  if (!ssids) {
    return policies;
  }
  ssids.forEach((ssid, snum) => {
    if (!ssid.enabled) {
      return;
    }
    const group = wirelessGroup(client, snum);
    if (!group) {
      return;
    }
    policies.push({
      policy: group,
      ssidNumber: snum,
      ssidName: ssid.name,
      type: PolicyTypeOnClient.wireless,
    });
  });

  return policies;
}

function osSanityFilter(os?: string, manufacturer?: string) {
  if (!manufacturer) {
    return !os ? "" : os;
  }

  let saneOS = !os ? "" : os;
  if (manufacturer.match("Meraki")) {
    saneOS = "Meraki";
  } else if (!manufacturer.match(/Apple/) && os && os.match(/^(Apple )?(iPad|iPhone|iPod)$/)) {
    if (manufacturer.match(/Samsung|Motorola|LG Electronics|Huawei/)) {
      saneOS = "Android";
    } else {
      saneOS = "";
    }
  }

  return saneOS;
}

export function filterBlockedClients(clients: ClientList): ClientList {
  return CUSTOM_FILTERS.BLOCKED_CLIENT(Object.values(clients)).reduce(
    (obj, client) => ({ ...obj, [client.id]: client }),
    {},
  );
}

export function isClientConnectedWithConnections(
  client: ExtendedClient | Record<string, never> = {},
  connections: ClientConnections = [],
) {
  return connections.some((connectionList) => connectionList?.[client.id] === true);
}

export function filterClientsLastSeenWithinTimespan(
  clients: ClientList,
  timespan: number,
): ClientList {
  const filteredClients = CUSTOM_FILTERS.TIMESPAN_CLIENTS(timespan)(clients);
  const working = {};
  for (const filteredIndex in filteredClients) {
    const filteredClient = filteredClients[filteredIndex];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    working[filteredClient.id] = filteredClient;
  }
  return working;
}

export function isClientOnline(client: NetworkClientOrSMDevice) {
  if (verifyIsSMDevice(client)) {
    return client.lastConnected >= getTimeAgo(45);
  }
  return new Date(client.lastSeen) >= sub(new Date(), { minutes: 5 });
}

export function isClientSMMac(client: NetworkClientOrSMDevice) {
  if (verifyIsSMDevice(client) || verifyIsNetworkClientSM(client)) {
    const osLower = client.osName?.toLowerCase?.() ?? "";
    return osLower.includes(ClientOS.mac);
  }

  return false;
}

export const filterForNetworkClients = (clients: NetworkClientOrSMDevice[]) =>
  clients.filter((client): client is NetworkClientType => !verifyIsSMDevice(client));

export const filterForSmDevices = (clients: NetworkClientOrSMDevice[]) =>
  clients.filter((client): client is SMDeviceType => !verifyIsNetworkClient(client));

export const networkFunctions = {
  sortBy: (clientA: NetworkClientType, clientB: NetworkClientType) =>
    (clientB.totalUsage ?? 0) - (clientA.totalUsage ?? 0),
  filterBy: filterForNetworkClients,
  searchFields: CLIENTS_SEARCH_FIELDS,
  searchKey: CLIENTS_SEARCH_KEY,
};

export const smFunctions = {
  sortBy: smSortByOnlineAndTotalUsage,
  filterBy: filterForSmDevices,
  searchFields: SM_CLIENTS_SEARCH_FIELDS,
  searchKey: SM_CLIENTS_SEARCH_KEY,
};
