import { PropsWithChildren } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

import { FormCheckbox } from "./FormCheckbox";
import { FormFlashList } from "./FormFlashList";
import { FormInput } from "./FormInput";
import { FormPicker } from "./FormPicker";
import { FormPickerCard } from "./FormPickerCard";
import { FormSlider } from "./FormSlider";
import { FormToggle } from "./FormToggle";

export function Form<T extends FieldValues>({
  children,
  ...rest
}: PropsWithChildren<UseFormReturn<T>>) {
  return <FormProvider {...rest}>{children}</FormProvider>;
}

Form.Input = FormInput;
Form.Checkbox = FormCheckbox;
Form.Picker = FormPicker;
Form.PickerCard = FormPickerCard;
Form.Toggle = FormToggle;
Form.FlashList = FormFlashList;
Form.Slider = FormSlider;
