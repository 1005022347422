import { useTheme } from "@meraki/core/theme";
import { formatAndParseKibibytes } from "@meraki/shared/formatters";
import { memo } from "react";
import { StyleSheet, Text, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { BORDER_RADIUS, LETTER_SPACING, SPACING } from "~/constants/MkiConstants";
import { formatThousands } from "~/lib/formatHelper";
import { appSelect, isAndroid, isIOS, isWeb, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize, themeColors } from "~/lib/themeHelper";
import MkiText from "~/shared/components/MkiText";
import Touchable from "~/shared/components/Touchable";

import { useThemeColors } from "../hooks/useTheme";

const FORMATTED_REGEX = /^(\d+\.*\d)(\w+)$/;

type Props = {
  value?: number | string;
  subText: string;
  status?: "online" | "offline" | null;
  isData?: boolean;
  category?: string | null;
  onPress?: (() => void) | null;
  testID?: string;
};

const QuickStatusBox = memo(function QuickStatusBox(props: Props) {
  const { theme } = useTheme();
  const statusThemeColors = useThemeColors().status;

  let value: Props["value"] | null = null;
  let dataSize: React.ReactElement | null = null;

  const colorStyle = appSelect({
    go: "color",
    enterprise: "backgroundColor",
  });
  const statusColor = props.status ? { [colorStyle]: statusThemeColors[props.status].color } : {};

  if (props.category) {
    ({ value } = props);
    dataSize = (
      <MkiText
        textStyle="small"
        screenStyles={appSelect({ enterprise: null, go: styles.dataSizeGo })}
      >
        {props.category}
      </MkiText>
    );
  } else if (props.isData && typeof props.value === "number") {
    const { value: number, unit: category } = formatAndParseKibibytes(props.value);
    value = number >= 100 ? Math.round(number).toFixed(0) : number.toFixed(1);
    dataSize = (
      <MkiText
        textStyle="small"
        screenStyles={appSelect({ enterprise: null, go: styles.dataSizeGo })}
      >
        {category}
      </MkiText>
    );
  } else if (typeof props.value === "number" && props.value > 9999) {
    const matcher = formatThousands(props.value, "0.0a").match(FORMATTED_REGEX);
    if (matcher) {
      const [, number, category] = matcher;

      value = number === "100.0" || "1000.0" ? Math.round(Number.parseFloat(number)) : number; // 100.0 & 1000.0 cause weird wrapping
      dataSize = (
        <MkiText
          textStyle="small"
          screenStyles={appSelect({ enterprise: null, go: styles.dataSizeGo })}
        >
          {category}
        </MkiText>
      );
    }
  } else {
    value = props.value;
  }

  const statusBar = __MERAKI_GO__ ? null : (
    <View style={[styles.statusBar, statusColor]} testID={"STATUS_BAR"} />
  );

  const backgroundColor = themeColors(theme).navigation.backgroundPrimary;
  const borderColor = themeColors(theme).tileBorderColor;
  const borderStyle = props.onPress ? { ...styles.statusBoxBorder, borderColor } : null;
  const quickStatusBox = (
    <View testID={props.testID} style={[{ backgroundColor }, styles.statusBox, borderStyle]}>
      {statusBar}
      <View style={styles.content}>
        <Text style={styles.value}>
          <MkiText
            textStyle="bigNumber"
            screenStyles={appSelect({ enterprise: {}, go: statusColor })}
          >
            {value}
          </MkiText>
          {dataSize}
        </Text>
        <MkiText
          textStyle={appSelect({ enterprise: "label", go: "default" })}
          screenStyles={appSelect({
            enterprise: styles.subText,
            go: [styles.subText, styles.subTextGo],
          })}
        >
          {props.subText}
        </MkiText>
      </View>
    </View>
  );

  const basicStatusBox = <View style={styles.container}>{quickStatusBox}</View>;

  if (props.onPress && isAndroid()) {
    /* TouchableNativeFeedback doesn't have a "style" prop,
     * so we have to add the container style to the child
     */
    return (
      <Touchable onPress={props.onPress} transparentBackground>
        {basicStatusBox}
      </Touchable>
    );
  }
  if (props.onPress && (isIOS() || isWeb())) {
    return (
      <Touchable onPress={props.onPress} style={styles.container} transparentBackground>
        {quickStatusBox}
      </Touchable>
    );
  }

  return basicStatusBox;
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...platformSelect({
      mobile: {},
      web: { userSelect: "none" },
    }),
  },
  statusBoxBorder: {
    borderWidth: 1,
    borderRadius: appSelect({
      enterprise: 2,
      go: BORDER_RADIUS.default,
    }),
    marginHorizontal: appSelect({
      enterprise: 0,
      go: SPACING.tiny + SPACING.meager,
    }),
    // Since only iOS supports shadows, we'll only apply that style for iOS and use
    // elevation to get a similar effect on Android.
    ...platformSelect({
      ios: {
        shadowColor: MkiColors.tileShadowColor,
        shadowOpacity: __MERAKI_GO__ ? 0.02 : 0.08,
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
      },
      android: { elevation: 1 },
    }),
  },
  statusBox: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 96,
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: platformSelect({
      ios: 10,
      android: 0,
    }),
  },
  value: {
    flexDirection: "row",
    alignItems: "baseline",
    color: MkiColors.textColor,
    marginTop: 6,
    fontSize: appSelect({
      enterprise: 15,
      go: normalizedFontSize(15),
    }),
  },
  dataSizeGo: {
    fontWeight: "300",
  },
  subText: {
    width: "70%",
    marginBottom: 10,
    textAlign: "center",
    lineHeight: 16,
    letterSpacing: LETTER_SPACING.smallText,
    ...appSelect({
      enterprise: {
        color: MkiColors.secondaryTextColor,
      },
      go: {},
    }),
  },
  subTextGo: {
    fontSize: normalizedFontSize(11),
    lineHeight: 15,
  },
  statusBar: {
    height: 4,
    width: "100%",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
});

export default QuickStatusBox;
