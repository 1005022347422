import { ActivityIndicator, StyleSheet } from "react-native";
import Svg, { Path } from "react-native-svg";

import { SPACING } from "~/constants/MkiConstants";
import { useThemeColors } from "~/shared/hooks/useTheme";

export const ElekidLoadingIcon = () => {
  const colors = useThemeColors();

  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Path
        d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM2 20C2 29.9411 10.0589 38 20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20Z"
        fill={colors.spinner.color}
      />
      <ActivityIndicator color={colors.spinner.color} size={SPACING.large} style={styles.loader} />
    </Svg>
  );
};

const styles = StyleSheet.create({
  loader: {
    justifyContent: "center",
    marginTop: SPACING.small,
  },
});

export default ElekidLoadingIcon;
