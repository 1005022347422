import Svg, { Path } from "react-native-svg";

export const AndroidLogoIcon = (props: any) => {
  const { color } = props;
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M10.25 10.5C10.6642 10.5 11 10.1642 11 9.75C11 9.33579 10.6642 9 10.25 9C9.83579 9 9.5 9.33579 9.5 9.75C9.5 10.1642 9.83579 10.5 10.25 10.5Z"
        fill={color}
      />
      <Path
        d="M5.75 10.5C6.16421 10.5 6.5 10.1642 6.5 9.75C6.5 9.33579 6.16421 9 5.75 9C5.33579 9 5 9.33579 5 9.75C5 10.1642 5.33579 10.5 5.75 10.5Z"
        fill={color}
      />
      <Path
        d="M1.5 12V10.5706C1.5 6.97813 4.38437 4.0125 7.97687 4C8.83241 3.99696 9.68013 4.16284 10.4714 4.48813C11.2627 4.81343 11.982 5.29173 12.588 5.89561C13.1941 6.4995 13.6749 7.21708 14.003 8.00721C14.3311 8.79734 14.5 9.64446 14.5 10.5V12C14.5 12.1326 14.4473 12.2598 14.3536 12.3536C14.2598 12.4473 14.1326 12.5 14 12.5H2C1.86739 12.5 1.74021 12.4473 1.64645 12.3536C1.55268 12.2598 1.5 12.1326 1.5 12Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2 3.5L3.94187 5.44187"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.0001 3.5L12.0688 5.43125"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default AndroidLogoIcon;
