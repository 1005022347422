import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import {
  TRAFFIC_SHAPING_LIMIT_VALUES,
  TRAFFIC_SHAPING_USAGE_INCREMENTS,
} from "@meraki/go/traffic-shaping";
import { kilobitsToMebibitsInt } from "@meraki/shared/formatters";
import Slider from "@react-native-community/slider";
import { PureComponent } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import SectionListHeader from "~/go/components/SectionListHeader";
import TopClientsList from "~/go/components/TopClientsList";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import withCancelablePromise, { WithCancelablePromiseProps } from "~/hocs/CancelablePromise";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { showAlert, showNetworkErrorWithRetry, showSaveWarning } from "~/lib/AlertUtils";
import { formatTransferBits } from "~/lib/formatHelper";
import { clientLimitConflicts } from "~/lib/TrafficShapingUtils";
import {
  clientsSelector,
  currentNetworkState,
  securityTrafficShapingRules,
  trafficShapingRulesOnSSIDsSelector,
} from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import { CancelButton, CloseButton, SaveButton } from "~/shared/navigation/Buttons";
import { NetworkClientOrSMDevice } from "~/shared/types/Client";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

import { LIMIT_STRING_KEYS } from "../components/identityPsk/GroupPolicySlider";
import { ApplicationRule } from "../types/NetworksTypes";

const AffectedClients = ({
  ssidNumber,
  networkWideClients,
}: {
  ssidNumber?: number;
  networkWideClients: NetworkClientOrSMDevice[];
}) => {
  if (ssidNumber !== undefined) {
    return (
      <TopClientsList
        ssidNumber={ssidNumber}
        filter={CUSTOM_FILTERS.SSID_CLIENTS(ssidNumber)}
        maxClientsToDisplay={5}
        renderTimespan
      />
    );
  }

  return <TopClientsList clients={networkWideClients} maxClientsToDisplay={5} renderTimespan />;
};

type ReduxProps = {
  networkId: string;
  networkWideClients: NetworkClientOrSMDevice[];
  ssidApplicationLimits: ApplicationRule[];
  gxApplicationLimits: ApplicationRule[] | undefined | null;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "LimitClientUsage"> &
  ReduxProps &
  BasicActions &
  PendingComponent &
  WithCancelablePromiseProps;

type LimitClientUsageState = {
  value: number;
};

export class LimitClientUsage extends PureComponent<Props, LimitClientUsageState> {
  static defaultProps = {
    editLimit: undefined,
    ssidNumber: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { editLimit } = props;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    const limitIndex = TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimit);
    this.state = {
      value: limitIndex !== -1 ? limitIndex : 0,
    };
    this.setNavOptions(false);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    this.setNavOptions(this.isDirty());
  }

  getData(force = false) {
    const { actions, networkWideClients, setReqPending } = this.props;
    const reqs = [];

    if (networkWideClients.length === 0 || force) {
      reqs.push(actions.getClients);
    }
    if (reqs.length > 0) {
      setReqPending(true);
      Promise.all(reqs)
        .then(() => setReqPending(false))
        .catch(() => showNetworkErrorWithRetry(() => this.getData()));
    }
  }

  setNavOptions(saveEnabled: boolean) {
    const { navigation, editLimit } = this.props;

    if (editLimit !== undefined) {
      navigation.setOptions({
        headerLeft: () =>
          saveEnabled ? (
            <CancelButton onPress={this.handleClose} />
          ) : (
            <CloseButton onPress={this.handleClose} />
          ),
        headerRight: () => <SaveButton onPress={this.save} disabled={!saveEnabled} />,
      });
    } else {
      navigation.setOptions({
        headerLeft: () => <CancelButton onPress={this.handleClose} />,
        headerRight: () => <SaveButton onPress={this.save} />,
      });
    }
  }

  cancelChanges() {
    const { navigation } = this.props;
    showSaveWarning(this.save, navigation.goBack);
  }

  isDirty() {
    const { editLimit } = this.props;
    if (editLimit === undefined) {
      return false;
    }
    const { value } = this.state;
    return TRAFFIC_SHAPING_LIMIT_VALUES.indexOf(editLimit) !== value;
  }

  handleClose = () => {
    const { navigation } = this.props;

    if (this.isDirty()) {
      this.cancelChanges();
    } else {
      navigation.goBack();
    }
  };

  isGX = () => {
    const { ssidNumber } = this.props;
    return ssidNumber === undefined;
  };

  save = async () => {
    const {
      navigation,
      actions,
      gxApplicationLimits,
      handleError,
      networkId,
      setReqPending,
      ssidApplicationLimits,
      ssidNumber,
      cancelablePromise,
    } = this.props;

    const { value } = this.state;
    const proposedClientLimit = TRAFFIC_SHAPING_LIMIT_VALUES[value];
    const applicationLimits = this.isGX() ? gxApplicationLimits : ssidApplicationLimits;

    const conflictingLimits = clientLimitConflicts(proposedClientLimit, applicationLimits);
    if (conflictingLimits) {
      const translationOptions = {
        max_limit: conflictingLimits.limit,
        limited_app: conflictingLimits.group,
        client_word: I18n.t("DEVICE_WORD"),
      };

      showAlert(
        I18n.t("ERROR"),
        this.isGX()
          ? I18n.t("CLIENT_LIMITS.HIGHER_APPLICATION_RESTRICTION_GX_ERROR", translationOptions)
          : I18n.t("CLIENT_LIMITS.HIGHER_APPLICATION_RESTRICTION_ERROR", translationOptions),
      );
      return;
    }

    const translatedLimit = kilobitsToMebibitsInt(proposedClientLimit);
    try {
      setReqPending(true);
      if (this.isGX()) {
        await cancelablePromise(
          actions.setGXTrafficShapingSettings({
            globalBandwidthLimits: {
              // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              limitUp: translatedLimit,
              // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              limitDown: translatedLimit,
            },
          }),
        );
      } else {
        await cancelablePromise(
          actions.setSsid(networkId, {
            number: ssidNumber,
            perClientBandwidthLimitUp: translatedLimit,
            perClientBandwidthLimitDown: translatedLimit,
          }),
        );
      }

      navigation.goBack();
    } catch (error) {
      handleError(error);
    }
    setReqPending(false);
  };

  render() {
    const { onTitle, ssidNumber, networkWideClients } = this.props;
    const { value } = this.state;
    const limitSpeed = formatTransferBits(TRAFFIC_SHAPING_LIMIT_VALUES[value]);
    const descriptionText = this.isGX()
      ? I18n.t("CLIENT_LIMITS.DESCRIPTION_GX", { client_word: I18n.t("DEVICES_WORD") })
      : I18n.t("CLIENT_LIMITS.DESCRIPTION", { client_word: I18n.t("DEVICES_WORD") });
    return (
      <View style={styles.container}>
        <ScrollView>
          <View style={styles.headerContainer}>
            <MkiText screenStyles={styles.headerStyle}>
              {I18n.t("CLIENT_LIMITS.HEADER", { client_word: I18n.t("DEVICES_WORD") })}
            </MkiText>
            <SectionListHeader heading={onTitle} />
          </View>
          <View style={styles.sliderContainer}>
            <Slider
              maximumValue={TRAFFIC_SHAPING_USAGE_INCREMENTS}
              onValueChange={(value) => this.setState({ value })}
              step={1}
              value={value}
              minimumTrackTintColor={MkiColors.goPurple}
              testID="SET_USAGE.SLIDER"
            />
            <MkiText>
              {I18n.t(LIMIT_STRING_KEYS[Math.min(value, 6)], { limit: limitSpeed })}
            </MkiText>
          </View>
          <View style={styles.clientsSubtitle}>
            <MkiText screenStyles={styles.headerStyle}>{descriptionText}</MkiText>
          </View>
          <AffectedClients ssidNumber={ssidNumber} networkWideClients={networkWideClients} />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    paddingHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
  sliderContainer: {
    marginHorizontal: SPACING.large,
  },
  clientsSubtitle: {
    marginTop: SPACING.large,
    marginHorizontal: SPACING.default,
  },
});

const mapStateToProps = () => {
  return (state: RootState, props: NetworkScreensPropMap["LimitClientUsage"]): ReduxProps => ({
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for LimitClientUsageScreen",
      currentNetworkState(state),
    ),
    networkWideClients: clientsSelector(state),
    //@ts-ignore
    ssidApplicationLimits: trafficShapingRulesOnSSIDsSelector(state)[props.ssidNumber],
    gxApplicationLimits: securityTrafficShapingRules(state),
  });
};

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withCancelablePromise,
)(LimitClientUsage);
