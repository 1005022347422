import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { RootState } from "../reducers";

const getMkiconf = (state: RootState) => state?.mkiconf;

export const getCurrentNetworkId = createSelector(
  getMkiconf,
  (substate) => substate?.currentNetwork,
);
export const useCurrentNetworkId = () => useSelector(getCurrentNetworkId);

export const getCurrentShardId = createSelector(
  getMkiconf,
  (substate) => substate?.currentShardId ?? 1,
);
export const useCurrentShardId = () => useSelector(getCurrentShardId);

export const getCurrentCluster = createSelector(
  getMkiconf,
  (substate) => substate?.currentCluster ?? "default",
);
export const useCurrentCluster = () => useSelector(getCurrentCluster);

export const getCurrentOrganizationId = createSelector(
  getMkiconf,
  (substate) => substate.currentOrganization,
);
export const useCurrentOrganizationId = () => useSelector(getCurrentOrganizationId);

export const getCurrentLocale = createSelector(getMkiconf, (substate) => substate?.currentLocale);
