import { SENSORS_FAILURE, SENSORS_REQUEST, SENSORS_SUCCESS } from "@meraki/shared/redux";

import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";
const NUM_SENSORS_PER_PAGE = 1000;

export const getSensors =
  (networkId: string): AppThunk<Promise<ApiResponseAction<any>>> =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [SENSORS_REQUEST, SENSORS_SUCCESS, SENSORS_FAILURE],
        endpoint: `/api/v1/networks/${networkId}/sensors`,
        config: {
          method: Method.get,
          queryParams: {
            perPage: NUM_SENSORS_PER_PAGE,
          },
        },
        meta: { networkId },
      },
    });
  };
