import { SET_SSO_ACCESS_ORG } from "@meraki/shared/redux";

import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";

export function setSSOAccessOrg(organization: GoOrgSearchResult) {
  return {
    organization,
    type: SET_SSO_ACCESS_ORG,
  };
}
