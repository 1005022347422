import { formatDate } from "@meraki/core/date";
import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { isNil } from "lodash";
import { PureComponent } from "react";
import { LayoutAnimation, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import InlineAlert from "~/go/components/InlineAlert";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { HomeStackProps } from "~/go/navigation/Types";
import withPendingComponent, { PendingComponent } from "~/hocs/PendingUtils";
import { setupAndroidLayoutAnimation } from "~/lib/AnimationUtils";
import { clientName } from "~/lib/ClientUtils";
import { withClientSubscription } from "~/lib/liveBroker";
import { normalizedFontSize } from "~/lib/themeHelper";
import { eventDescriptionForSecurityEvent } from "~/lib/UmbrellaUtils";
import {
  getEventCountForIp,
  getNetworkTimezone,
  getUmbrellaProtection,
  timespanNameSelector,
  unfilteredSsidsByIdSelector,
} from "~/selectors";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import SummaryList from "~/shared/components/SummaryList";
import DisclosureRow from "~/shared/rows/DisclosureRow.go";
import ListRow from "~/shared/rows/ListRow";
import { Client } from "~/shared/types/Client";
import { SSIDsByNumber } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { UmbrellaProtection } from "~/shared/types/Umbrella";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  securityEventCount: number | null;
  timezone: string;
  ssidsByNumber: SSIDsByNumber;
  timespanName: string;
  umbrellaProtection?: UmbrellaProtection;
};

type Props = ForwardedNativeStackScreenProps<HomeStackProps, "SecurityEventDetails"> &
  ReduxProps &
  BasicActions &
  PendingComponent;

interface SecurityEventDetailsScreenState {
  isAlertVisible: boolean;
}

type RowData = {
  isDisclosureRow?: boolean;
  onPress?: () => void;
  value?: string | null;
  label: string;
};

export class SecurityEventDetailsScreen extends PureComponent<
  Props,
  SecurityEventDetailsScreenState
> {
  constructor(props: Props) {
    super(props);

    setupAndroidLayoutAnimation();
    this.state = { isAlertVisible: true };
  }

  static renderProductInfoRow = (rowData: RowData) => {
    if (rowData.isDisclosureRow) {
      return (
        <DisclosureRow onPress={rowData.onPress} isFullButton bottomSeparator>
          {rowData.label}
        </DisclosureRow>
      );
    }
    return (
      <ListRow
        value={rowData.value}
        rowStyles={styles.rowStyles}
        rightStyle={styles.rightContent}
        leftStyle={styles.leftContent}
        labelStyle={styles.productInfoLabel}
      >
        {rowData.label}
      </ListRow>
    );
  };

  pushClientDetails = () => {
    const { navigation, client } = this.props;
    if (!client) {
      return;
    }

    navigation.navigate("ClientDetails", {
      id: client.id,
    });
  };

  makeFormattedDate = (timestamp: Date) => {
    return formatDate(timestamp, { dateFormat: "longDate", timeFormat: "shortTime" });
  };

  closeAlertCard = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ isAlertVisible: false });
  };

  getClientSummaryData = (client: Client) => {
    const { associated, associatedAt, securityEventCount, ssidsByNumber, timespanName } =
      this.props;

    const whatDeviceContentRows = [
      { label: I18n.t("SECURITY_EVENT_DETAILS.NAME"), value: clientName(client) },
    ];

    if (securityEventCount != null) {
      whatDeviceContentRows.push({
        label: I18n.t("SECURITY_EVENT_DETAILS.BLOCKED_REQUESTS"),
        value: I18n.t("SECURITY_EVENT_DETAILS.PAST_TIMESPAN", { securityEventCount, timespanName }),
      });
    }

    if (
      associated &&
      !isNil(client.connectedBy) &&
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      ssidsByNumber[client.connectedBy] &&
      typeof associatedAt === "number"
    ) {
      whatDeviceContentRows.push(
        {
          label: I18n.t("SECURITY_EVENT_DETAILS.CONNECTED_TO"),
          // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
          value: ssidsByNumber[client.connectedBy].name,
        },
        {
          label: I18n.t("SECURITY_EVENT_DETAILS.CONNECTED_SINCE"),
          value: this.makeFormattedDate(new Date(associatedAt * 1000)),
        },
      );
    } else if (client.lastSeen) {
      whatDeviceContentRows.push({
        label: I18n.t("SECURITY_EVENT_DETAILS.LAST_SEEN"),
        value: this.makeFormattedDate(new Date(client.lastSeen)),
      });
    }

    const disclosureRow = [
      {
        label: I18n.t("SECURITY_EVENT_DETAILS.SEE_ALL"),
        onPress: this.pushClientDetails,
        isDisclosureRow: true,
      },
    ];

    return { whatDeviceContentRows, disclosureRow };
  };

  renderClientInfo = () => {
    const { client } = this.props;
    if (!client) {
      return null;
    }
    const { whatDeviceContentRows, disclosureRow } = this.getClientSummaryData(client);

    return (
      <SummaryList<RowData>
        headingTextStyle={styles.headerTextStyle}
        heading={I18n.t("SECURITY_EVENT_DETAILS.WHAT_DEVICES")}
        contentRows={whatDeviceContentRows}
        disclosureRows={disclosureRow}
        customRenderRow={SecurityEventDetailsScreen.renderProductInfoRow}
        disableBottomBorder
      />
    );
  };

  excludeURL = async (_: unknown, url: string) => {
    const { actions, handleError, setReqPending, umbrellaProtection } = this.props;

    const policyId = umbrellaProtection?.umbrellaPolicyId ?? null;
    const excludedDomains = umbrellaProtection?.excludedDomains ?? [];
    setReqPending(true);
    try {
      await actions.setUmbrellaProtection(policyId?.toString(), [...excludedDomains, url]);
    } catch (error) {
      if (typeof error === "string") {
        handleError(error);
      }
    } finally {
      setReqPending(false);
    }
  };

  openExcludeURL = () => {
    const { navigation, securityEvent } = this.props;

    navigation.navigate("UmbrellaExcludeURL", {
      passedURL: securityEvent.domain,
      addEditURL: this.excludeURL,
    });
  };

  render() {
    const { client, device, reqPending, securityEvent, umbrellaProtection } = this.props;
    const { isAlertVisible } = this.state;

    const whyBlockedContentRows = securityEvent && [
      { label: I18n.t("SECURITY_EVENT_DETAILS.WEBSITE_URL"), value: securityEvent.domain },
      {
        label: I18n.t("SECURITY_EVENT_DETAILS.EVENT_TIME"),
        value: this.makeFormattedDate(new Date(securityEvent.timestamp)),
      },
    ];

    const isDeviceEvent = device != null;
    const isClientEvent = client != null;
    const isUnblocked = umbrellaProtection?.excludedDomains?.some(
      (excludedDomain) => securityEvent?.domain?.includes(excludedDomain),
    );

    let alertTitle: string | undefined;
    let alertMessage: string | undefined;
    if (isDeviceEvent) {
      alertTitle = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING_DEVICE.TITLE");
      alertMessage = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING_DEVICE.MESSAGE", {
        client_word: I18n.t("DEVICE_WORD"),
      });
    } else if (isClientEvent) {
      alertTitle = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING.TITLE");
      alertMessage = I18n.t("SECURITY_EVENT_DETAILS.TROUBLESHOOTING.MESSAGE");
    }

    return (
      <FullScreenContainerView withScroll>
        <SummaryList
          headingTextStyle={styles.headerTextStyle}
          heading={I18n.t("SECURITY_EVENT_DETAILS.WHY_BLOCKED")}
          subheading={eventDescriptionForSecurityEvent(securityEvent)}
          contentRows={whyBlockedContentRows}
          customRenderRow={SecurityEventDetailsScreen.renderProductInfoRow}
          containerStyles={styles.summaryListContainer}
          disableBottomBorder
        />
        {isClientEvent && this.renderClientInfo()}
        {alertTitle && alertMessage && (
          <InlineAlert
            visible={isAlertVisible}
            onExit={this.closeAlertCard}
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            screenStyles={styles.inlineAlert}
          />
        )}
        <RoundedButton
          onPress={this.openExcludeURL}
          loading={reqPending}
          buttonType={ButtonType.primary}
          containerStyles={styles.button}
          disabled={isUnblocked}
          testID="SECURITY_EVENT_DETAIL.UNBLOCK_BUTTON"
        >
          {isUnblocked
            ? I18n.t("SECURITY_EVENT_DETAILS.UNBLOCKED")
            : I18n.t("SECURITY_EVENT_DETAILS.UNBLOCK")}
        </RoundedButton>
      </FullScreenContainerView>
    );
  }
}

const styles = StyleSheet.create({
  rowStyles: {
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: SPACING.default,
  },
  rightContent: {
    width: "60%",
    justifyContent: "flex-start",
  },
  leftContent: {
    width: "40%",
  },
  productInfoLabel: {
    color: MkiColors.secondaryTextColor,
  },
  headerTextStyle: {
    fontSize: normalizedFontSize(19),
  },
  inlineAlert: {
    marginVertical: SPACING.large,
  },
  summaryListContainer: {
    paddingBottom: SPACING.small,
    marginBottom: SPACING.small,
  },
  button: {
    paddingHorizontal: SPACING.default,
  },
});

function mapStateToProps(
  state: RootState,
  props: HomeStackProps["SecurityEventDetails"],
): ReduxProps {
  const { client } = props;

  return {
    securityEventCount: client ? getEventCountForIp(state, client.hashedIp4) : null,
    timezone: errorMonitor.notifyNonOptional(
      "param 'timezone' undefined for SecurityEventDetailsScreen",
      getNetworkTimezone(state),
    ),
    ssidsByNumber: unfilteredSsidsByIdSelector(state),
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    timespanName: timespanNameSelector(state),
    umbrellaProtection: getUmbrellaProtection(state),
  };
}

export default compose<any>(
  connect(mapStateToProps, basicMapDispatchToProps),
  withPendingComponent,
  withClientSubscription({
    type: "Association2",
    handler: ({ data }: any) => {
      const { associated, assoc_at } = data;
      return {
        associated,
        associatedAt: assoc_at,
        data,
      };
    },
    mac: (ownProps: Props) => ownProps?.client?.mac,
    nodeGroupId: (ownProps: Props) => ownProps?.client?.networkId,
  }),
)(SecurityEventDetailsScreen);
