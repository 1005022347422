import {
  GET_SSID_L7_FIREWALL_RULES_FAILURE,
  GET_SSID_L7_FIREWALL_RULES_REQUEST,
  GET_SSID_L7_FIREWALL_RULES_SUCCESS,
  GET_SSID_SCHEDULES_FAILURE,
  GET_SSID_SCHEDULES_REQUEST,
  GET_SSID_SCHEDULES_SUCCESS,
  GET_SSID_SPLASH_SETTINGS_FAILURE,
  GET_SSID_SPLASH_SETTINGS_REQUEST,
  GET_SSID_SPLASH_SETTINGS_SUCCESS,
  GET_SSID_TRAFFIC_SHAPING_RULES_FAILURE,
  GET_SSID_TRAFFIC_SHAPING_RULES_REQUEST,
  GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
  SET_SSID_QR_CODE,
  SSIDS_FAILURE,
  SSIDS_REQUEST,
  SSIDS_SUCCESS,
  UPDATE_SSID_L7_FIREWALL_RULES_FAILURE,
  UPDATE_SSID_L7_FIREWALL_RULES_REQUEST,
  UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS,
  UPDATE_SSID_SCHEDULES_FAILURE,
  UPDATE_SSID_SCHEDULES_REQUEST,
  UPDATE_SSID_SCHEDULES_SUCCESS,
  UPDATE_SSID_SPLASH_SETTINGS_FAILURE,
  UPDATE_SSID_SPLASH_SETTINGS_REQUEST,
  UPDATE_SSID_SPLASH_SETTINGS_SUCCESS,
  UPDATE_SSID_TRAFFIC_SHAPING_RULES_FAILURE,
  UPDATE_SSID_TRAFFIC_SHAPING_RULES_REQUEST,
  UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
  UPDATE_WARN_INVALID_BRIDGE_MODE,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import { ApplicationRule } from "~/go/types/NetworksTypes";
import { isUnconfiguredSSID } from "~/lib/SSIDUtils";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import { currentNetworkState, networkTypesSelector, ssidsForCurrentNetwork } from "~/selectors";
import { FirewallLayerRule } from "~/shared/types/FirewallLayerRule";
import { ApiAction, AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";
import { SplashSettings } from "~/shared/types/SplashSettings";

export const getAllSsidsForCurrentNetwork =
  (): AppThunk<Promise<ApiResponseAction<any>> | { response: {}; type: typeof SSIDS_SUCCESS }> =>
  //@ts-ignore
  (dispatch, getState) => {
    const { hasWireless, hasWired } = networkTypesSelector(getState()) || {};

    const ssidRequests: Promise<any>[] = [];
    if (hasWireless) {
      ssidRequests.push(dispatch(getSsidsForNetworkId(hasWireless, true)));
    }
    if (hasWired) {
      ssidRequests.push(dispatch(getSsidsForNetworkId(hasWired, true)));
    }

    return Promise.all(ssidRequests).then((allPayloads) => {
      const response = {};

      allPayloads.forEach((payload) => {
        Object.assign(response, payload.response);
      });

      return dispatch({
        response,
        type: SSIDS_SUCCESS,
      });
    });
  };

const getSsidsForNetworkId = (networkId: string, skipRedux?: boolean): ApiAction => {
  const callback = (json: any) => ({
    [networkId]: json,
  });
  return {
    [CALL_API]: {
      types: [SSIDS_REQUEST, SSIDS_SUCCESS, SSIDS_FAILURE],
      endpoint: `/api/v1/networks/${networkId}/wireless/ssids`,
      config: { method: Method.get },
      meta: {
        shouldReduxIgnoreResponse: skipRedux,
      },
      callback,
    },
  };
};

export const getSSIDLayer7FirewallRules = (
  ssidNumber: number,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            GET_SSID_L7_FIREWALL_RULES_REQUEST,
            GET_SSID_L7_FIREWALL_RULES_SUCCESS,
            GET_SSID_L7_FIREWALL_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/firewall/l7FirewallRules`,
          config: { method: Method.get },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const updateSSIDLayer7FirewallRules = (
  ssidNumber: number,
  rules: FirewallLayerRule[],
): AppThunk<Promise<ApiResponseAction<number>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF<number>({
          types: [
            UPDATE_SSID_L7_FIREWALL_RULES_REQUEST,
            UPDATE_SSID_L7_FIREWALL_RULES_SUCCESS,
            UPDATE_SSID_L7_FIREWALL_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/firewall/l7FirewallRules`,
          config: {
            method: Method.put,
            body: JSON.stringify({ rules }),
          },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const setQRCode = (networkId: any, ssidNumber: any, qrCode: any): AppThunk => {
  return (dispatch) =>
    dispatch({
      type: SET_SSID_QR_CODE,
      networkId,
      ssidNumber,
      qrCode,
    });
};

export const getSSIDTrafficShapingRules = (
  ssidNumber: number,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            GET_SSID_TRAFFIC_SHAPING_RULES_REQUEST,
            GET_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
            GET_SSID_TRAFFIC_SHAPING_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/trafficShaping/rules`,
          config: { method: Method.get },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const updateSSIDTrafficShapingRules = (
  ssidNumber: number,
  rules: FirewallLayerRule[] | ApplicationRule[],
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            UPDATE_SSID_TRAFFIC_SHAPING_RULES_REQUEST,
            UPDATE_SSID_TRAFFIC_SHAPING_RULES_SUCCESS,
            UPDATE_SSID_TRAFFIC_SHAPING_RULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/trafficShaping/rules`,
          config: {
            method: Method.put,
            body: JSON.stringify({ rules }),
          },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const getScheduleForSSID = (
  ssidNumber: number,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            GET_SSID_SCHEDULES_REQUEST,
            GET_SSID_SCHEDULES_SUCCESS,
            GET_SSID_SCHEDULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/schedules`,
          config: { method: Method.get },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }

    return Promise.reject();
  };
};

export const getSSIDSchedulesForCurrentNetwork = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const reqs: Promise<ApiResponseAction<any>>[] = [];
    const ssids = ssidsForCurrentNetwork(getState());
    for (const ssid of ssids) {
      if (!isUnconfiguredSSID(ssid)) {
        const { number } = ssid;
        reqs.push(dispatch(getScheduleForSSID(number)));
      }
    }
    await Promise.all(reqs);
  };
};

export const updateScheduleForSSID = (
  ssidNumber: number,
  schedule: any,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());
    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            UPDATE_SSID_SCHEDULES_REQUEST,
            UPDATE_SSID_SCHEDULES_SUCCESS,
            UPDATE_SSID_SCHEDULES_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/schedules`,
          config: { method: Method.put, body: JSON.stringify(schedule) },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const getSSIDSplashSettings = (ssidNumber: number): AppThunk<Promise<any> | undefined> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            GET_SSID_SPLASH_SETTINGS_REQUEST,
            GET_SSID_SPLASH_SETTINGS_SUCCESS,
            GET_SSID_SPLASH_SETTINGS_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/splash/settings`,
          config: { method: Method.get },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const updateSSIDSplashSettings = (
  ssidNumber: number,
  settings: Partial<SplashSettings>,
): AppThunk<Promise<ApiResponseAction<any>>> => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    if (networkId != null && ssidNumber != null) {
      const { splashImage, splashPrepaidFront, ...payload } = settings;

      return dispatch(
        wrapApiActionWithCSRF({
          types: [
            UPDATE_SSID_SPLASH_SETTINGS_REQUEST,
            UPDATE_SSID_SPLASH_SETTINGS_SUCCESS,
            UPDATE_SSID_SPLASH_SETTINGS_FAILURE,
          ],
          endpoint: `/api/v1/networks/${networkId}/wireless/ssids/${ssidNumber}/splash/settings`,
          config: {
            method: Method.put,
            body: JSON.stringify(payload),
          },
          meta: {
            networkId,
            ssidNumber,
          },
        }),
      );
    }
    return Promise.reject();
  };
};

export const setWarnInvalidBridgeMode = (warnInvalidBridgeMode: boolean): AppThunk => {
  return (dispatch, getState) => {
    const networkId = currentNetworkState(getState());

    return dispatch({
      type: UPDATE_WARN_INVALID_BRIDGE_MODE,
      networkId,
      warnInvalidBridgeMode,
    });
  };
};
