import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import MkiText from "~/shared/components/MkiText";

type Props = {
  title: string;
  description?: string;
  noTopPadding?: boolean;
  screenStyles?: StyleProp<ViewStyle>;
  testID?: string;
};

const ConfigHeader = (props: Props) => {
  const { noTopPadding, screenStyles, title, description, testID } = props;
  return (
    <View
      testID={testID}
      style={
        noTopPadding ? [styles.container, styles.noTopPadding] : [styles.container, screenStyles]
      }
    >
      <MkiText textStyle="heading" screenStyles={styles.title}>
        {title}
      </MkiText>
      {description ? <MkiText screenStyles={styles.description}>{description}</MkiText> : null}
    </View>
  );
};

ConfigHeader.defaultProps = {
  description: null,
  noTopPadding: false,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    paddingVertical: SPACING.default,
    marginRight: SPACING.default,
  },
  title: {
    paddingVertical: SPACING.tiny,
    paddingLeft: SPACING.default,
    color: MkiColors.configHeadingColor,
  },
  description: {
    paddingLeft: SPACING.default,
    color: MkiColors.secondaryTextColor,
    paddingTop: SPACING.default,
  },
  noTopPadding: {
    paddingTop: 0,
  },
});

export default ConfigHeader;
