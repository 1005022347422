import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { I18n } from "@meraki/core/i18n";
import { BottomSheet, Button, Input, Loader } from "@meraki/magnetic/components";
import { Box } from "@meraki/magnetic/layout";
import {
  ClientType,
  queryClient,
  useClients,
  useOrgNetwork,
  useUpdateClientName,
} from "@meraki/shared/api";
import { useCurrentNetworkId, useCurrentOrganizationId } from "@meraki/shared/redux";
import { useState } from "react";
import { Alert } from "react-native";

interface EditBottomSheetProps {
  client: ClientType;
  bottomSheetRef: React.RefObject<BottomSheetModalMethods>;
}

export function EditBottomSheet({ client, bottomSheetRef }: EditBottomSheetProps) {
  const [clientName, setClientName] = useState(client?.description ?? "");
  const networkId = useCurrentNetworkId();
  const organizationId = useCurrentOrganizationId();
  const { data: network } = useOrgNetwork({
    organizationId,
    networkId,
  });

  const { mutate: updateClientName } = useUpdateClientName();

  if (!client || !network) {
    return (
      <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
        <Loader.Spinner animate={true} />
      </BottomSheet.Modal>
    );
  }

  return (
    <BottomSheet.Modal ref={bottomSheetRef} snapPoints={["CONTENT_HEIGHT"]} index={0}>
      <BottomSheet.Header
        title={I18n.t("CLIENT_DETAILS_SCREEN.EDIT_SCREEN.HEADING")}
        cancelLabel={I18n.t("CANCEL")}
        onCancelPress={() => {
          setClientName(client?.description || "");
          bottomSheetRef?.current?.dismiss();
        }}
      />
      <BottomSheet.Content>
        <Box paddingBottom="sm">
          <Input
            label={I18n.t("CLIENT_DETAILS_SCREEN.EDIT_SCREEN.TITLE")}
            value={clientName}
            onChangeText={setClientName}
            testID="EDIT_CLIENT_NAME_INPUT"
          />
        </Box>
        <Button
          text={I18n.t("SAVE")}
          onPress={() => {
            updateClientName(
              {
                encryptedNetworkId: network.eid,
                clientId: client.id,
                name: clientName,
              },
              {
                onError: (error) => {
                  if (error !== null && typeof error === "object" && "errors" in error) {
                    Alert.alert(String(error["errors"]));
                  }
                },

                onSettled: () => {
                  queryClient.invalidateQueries({ queryKey: useClients.queryKeyRoot });
                  bottomSheetRef?.current?.dismiss();
                },
              },
            );
          }}
          disabled={clientName === client?.description}
          testID="SAVE_BUTTON"
        />
      </BottomSheet.Content>
    </BottomSheet.Modal>
  );
}
