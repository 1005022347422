import { I18n } from "@meraki/core/i18n";
import { Button, List, Numeral, Text } from "@meraki/magnetic/components";
import { Screen } from "@meraki/magnetic/layout";
import { AccessPointPlacementGroupProps } from "@meraki/shared/navigation-type";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { useApPlacementRooms } from "../store/useApPlacementRooms";

export const APSummaryScreen = () => {
  const networkId = useCurrentNetworkId() ?? "";
  const { roomsByNetwork } = useApPlacementRooms();
  const navigation = useNavigation<NativeStackNavigationProp<AccessPointPlacementGroupProps>>();

  const vals = Object.values(roomsByNetwork[networkId] ?? {}).filter((x) => x !== null) as number[];
  const numRooms = vals.length;

  const sumWithInitial = vals.reduce(
    (accumulator, currentValue) =>
      currentValue !== null ? accumulator + currentValue : accumulator,
    0,
  );

  return (
    <Screen addDefaultPadding>
      <List.FlashList
        label={I18n.t("ACCESS_POINT.SUMMARY.TITLE")}
        paddingTop="none"
        paddingLeft="none"
        paddingRight="none"
        paddingBottom="none"
        emptyState={{ title: I18n.t("ACCESS_POINT.ROOM_SELECT.EMPTY_LIST") }}
        data={networkId ? Object.keys(roomsByNetwork[networkId] ?? {}) : []}
        getItemData={(room) => {
          const roomData = roomsByNetwork[networkId];
          return {
            title: room,
            rightAccessory:
              roomData && roomData[room] !== null ? (
                <Numeral size="n2" value={roomData[room]} units="dB" />
              ) : (
                <Text>{I18n.t("ACCESS_POINT.SUMMARY.NO_SCORE")}</Text>
              ),
          };
        }}
      />
      <List>
        <List.Item
          title={I18n.t("ACCESS_POINT.SUMMARY.AVERAGE")}
          rightAccessory={
            <Numeral size="n2" value={(sumWithInitial / vals.length).toFixed(1)} units="dB" />
          }
        />
      </List>
      <Button
        text={I18n.t("ACCESS_POINT.SUMMARY.EXIT")}
        analytics={{
          event: "onPress",
          eventName: "ap_summary_exit",
          params: {
            numRooms,
          },
        }}
        onPress={() => navigation.popToTop()}
      />
    </Screen>
  );
};
