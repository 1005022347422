import { SENSOR_ROLES_SUCCESS, WIPE_REDUX } from "@meraki/shared/redux";
import { AnyAction, Reducer } from "redux";

import { SensorRole } from "~/api/models/Sensor";
import { WipeReduxAction } from "~/shared/types/Redux";

interface SensorRoleSuccessAction extends AnyAction {
  type: typeof SENSOR_ROLES_SUCCESS;
  response: SensorRole[];
}

type SensorRolesActions = SensorRoleSuccessAction | WipeReduxAction;

interface SensorRolesState {
  [sensorSerial: string]: SensorRole;
}

const initialState: Record<string, SensorRolesState> = {};

const sensorRoles: Reducer<typeof initialState, SensorRolesActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SENSOR_ROLES_SUCCESS: {
      const { response, meta } = action;
      const indexedRoles = response.reduce((accumulator, sensorRole) => {
        return {
          ...accumulator,
          [sensorRole.device.serial]: sensorRole,
        };
      }, {});

      return {
        ...state,
        [meta.networkId]: indexedRoles,
      };
    }
    case WIPE_REDUX: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default sensorRoles;
