import { I18n } from "@meraki/core/i18n";
import { DETECTING_IDLE_TIME } from "@meraki/shared/redux";

import { logoutUser } from "~/actions/auth";
import { MS_IN_A_MIN, WEB_TIMEOUT_LIMIT } from "~/constants/MkiConstants";
import { isWeb } from "~/lib/PlatformUtils";
import { removeWebAlertComponent, showWebAlert } from "~/lib/WebAlertUtils";
import { AppThunk } from "~/shared/types/Redux";

let idleTimeout: NodeJS.Timeout;
export function detectIdleTimeInWeb(): AppThunk {
  return (dispatch) => {
    if (!isWeb()) {
      return;
    }

    function logUserOut() {
      dispatch(logoutUser());

      // DOM Events
      window.onload = null;
      document.onmousemove = null;
      document.onkeyup = null;
    }

    function showTimeoutAlert() {
      clearTimeout(idleTimeout);

      showWebAlert(I18n.t("WEBAPP.TIMEOUT.TITLE"), I18n.t("WEBAPP.TIMEOUT.MESSAGE"), logUserOut, {
        positiveText: I18n.t("WEBAPP.TIMEOUT.SIGNOUT"),
        negativeText: I18n.t("WEBAPP.TIMEOUT.CONTINUE"),
        onNegativePress: resetTimer,
      });

      idleTimeout = global.setTimeout(() => {
        clearTimeout(idleTimeout);
        removeWebAlertComponent();
        logUserOut();
      }, MS_IN_A_MIN);
    }

    function resetTimer() {
      clearTimeout(idleTimeout);
      idleTimeout = global.setTimeout(showTimeoutAlert, WEB_TIMEOUT_LIMIT);
    }

    // DOM Events
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeyup = resetTimer;
    resetTimer();

    return dispatch({ type: DETECTING_IDLE_TIME });
  };
}
