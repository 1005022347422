import {
  NETWORK_CLAIM_DEVICE_FAILURE,
  NETWORK_CLAIM_DEVICE_REQUEST,
  NETWORK_CLAIM_DEVICE_SUCCESS,
  ONBAORDING_BATCH_CLAIM_FAILURE,
  ONBAORDING_BATCH_CLAIM_REQUEST,
  ONBAORDING_BATCH_CLAIM_SUCCESS,
  ONBOARDING_ADD_NODE,
  ONBOARDING_SET_FLOW,
  ONBOARDING_SET_IS_COMPLETE,
  ONBOARDING_SET_IS_FORCED,
  ONBOARDING_SET_NODES,
  ONBOARDING_SET_SKIPPED,
  ONBOARDING_SET_STAGE,
  ONBOARDING_SET_STATUS,
  setBottomBarVisibility,
  UDG_NODES_STATUS_FAILURE,
  UDG_NODES_STATUS_REQUEST,
  UDG_NODES_STATUS_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import I18n from "~/i18n/i18n";
import { ApiResponseAction, CALL_API } from "~/middleware/api";
import {
  currentUserState,
  encryptedNetworkIdSelector,
  getCurrentOrgEid,
  hasOnboardingNodesNeedingPolling,
  onboardingIsComplete,
} from "~/selectors";
import { ApiError } from "~/shared/types/Error";
import {
  BatchOfDevices,
  OnboardingNodeStatus,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";
import { AppThunk } from "~/shared/types/Redux";

let onboardingPollingInterval: any;
const ONBOARDING_POLLING_FREQUENCY = 10000; // 10 seconds
const BAD_REQUEST_STATUS = 400;
const ALREADY_CLAIMED_MESSAGE = /^Device\swith\sserial.+is\salready\sclaimed.*$/g;
const ALREADY_CLAIMED_WITHIN_ORG_MESSAGE = /^Device\swith\sserial.+is\salready\sclaimed\sand.*$/g;

export function setOnboardingIsComplete(isComplete: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_IS_COMPLETE,
      isComplete,
      user: currentUserState(getState()),
    });
}

export function setOnboardingIsForced(isForced: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_IS_FORCED,
      isForced,
      user: currentUserState(getState()),
    });
}

export function checkForOnboardingPolling(): AppThunk {
  return (dispatch, getState) => {
    // Only poll if the following are true.
    // The user has onboarding nodes, nodes.length > 0,
    // The status of these nodes is not finished, status !== OnboardingNodeStatus.finished,
    // There isn't already another poll running.

    if (hasOnboardingNodesNeedingPolling(getState())) {
      dispatch(startOnboardingPolling());
    } else {
      cleanupPolling();
    }
  };
}

export function skipOnboarding(): AppThunk {
  return (dispatch, getState) => {
    const isComplete = onboardingIsComplete(getState());
    if (!isComplete) {
      dispatch(setOnboardingSkipped(true));
    }
  };
}

export function completeOnboarding(): AppThunk {
  return (dispatch) => {
    dispatch(setOnboardingStage(OnboardingStage.exit));
    dispatch(cleanupOnboarding());
  };
}

export function cleanupOnboarding(): AppThunk {
  return (dispatch) => {
    dispatch(setOnboardingIsComplete(true));
    dispatch(setOnboardingIsForced(false));
    dispatch(setOnboardingNodes({}));
    dispatch(setOnboardingStatus(OnboardingNodeStatus.unstarted));
    dispatch(checkForOnboardingPolling());
    dispatch(setBottomBarVisibility("visible"));
  };
}

export function startOnboardingPolling(): AppThunk {
  return (dispatch, getState) => {
    if (!onboardingPollingInterval) {
      onboardingPollingInterval = setInterval(() => {
        if (hasOnboardingNodesNeedingPolling(getState())) {
          dispatch(getUdgNodesStatus());
        } else {
          cleanupPolling();
        }
      }, ONBOARDING_POLLING_FREQUENCY);
    }
  };
}

export function cleanupPolling() {
  clearInterval(onboardingPollingInterval);
  onboardingPollingInterval = null;
}

export function addOnboardingNode(serial: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_ADD_NODE,
      serial,
      user: currentUserState(getState()),
    });
}

export function setOnboardingStage(stage: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_STAGE,
      stage,
      user: currentUserState(getState()),
    });
}

export function setOnboardingFlow(flow: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_FLOW,
      flow,
      user: currentUserState(getState()),
    });
}

export function setOnboardingSkipped(skipped: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_SKIPPED,
      skipped,
      user: currentUserState(getState()),
    });
}

export function setOnboardingStatus(status: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_STATUS,
      status,
      user: currentUserState(getState()),
    });
}

export function setOnboardingNodes(nodes: any): AppThunk {
  return (dispatch, getState) =>
    dispatch({
      type: ONBOARDING_SET_NODES,
      nodes,
      user: currentUserState(getState()),
    });
}

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export const getUdgNodesStatus =
  (): AppThunk<Promise<ApiResponseAction<any>>> => (dispatch, getState) =>
    dispatch({
      [CALL_API]: {
        types: [UDG_NODES_STATUS_REQUEST, UDG_NODES_STATUS_SUCCESS, UDG_NODES_STATUS_FAILURE],
        endpoint: `/n/${encryptedNetworkIdSelector(
          getState(),
        )}/manage/node_group/underdog_nodes_status`,
        config: { method: "GET" },
        meta: { user: currentUserState(getState()) },
      },
    });

/**
 * @privateapi Public endpoints should be used whenever possible
 */
export function batchClaimDevices(
  batchOfDevices: BatchOfDevices,
  networkId: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch, getState) => {
    const orgEid = getCurrentOrgEid(getState());

    return dispatch(
      wrapApiActionWithCSRF({
        types: [
          ONBAORDING_BATCH_CLAIM_REQUEST,
          ONBAORDING_BATCH_CLAIM_SUCCESS,
          ONBAORDING_BATCH_CLAIM_FAILURE,
        ],
        endpoint: `/o/${orgEid}/manage/organization/add_nodes_with_initial_attributes`,
        config: {
          method: "POST",
          body: JSON.stringify({
            network_id: networkId,
            devices: batchOfDevices,
          }),
        },
        meta: { user: currentUserState(getState()) },
      }),
    );
  };
}

const GENERIC_ERROR_MESSAGE = "ENDPOINT_ERRORS.GENERIC_ERROR_MESSAGE";

export function claimDeviceToNetwork(
  networkId: any,
  serial: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  const parseError = (error: ApiError): string => {
    let errorKey: null | string = null;
    const message = error?.message;

    if (error?.response?.status === BAD_REQUEST_STATUS && message != null) {
      if (message.match(ALREADY_CLAIMED_WITHIN_ORG_MESSAGE)) {
        errorKey = "CLAIM_DEVICE_TO_NETWORK.DEVICE_ALREADY_CLAIMED_WITHIN_ORG";
      } else if (message.match(ALREADY_CLAIMED_MESSAGE)) {
        errorKey = "CLAIM_DEVICE_TO_NETWORK.DEVICE_ALREADY_CLAIMED";
      }
    }

    if (errorKey == null && message) {
      return message;
    }

    return I18n.t(errorKey ? `ENDPOINT_ERRORS.${errorKey}` : GENERIC_ERROR_MESSAGE);
  };

  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          NETWORK_CLAIM_DEVICE_REQUEST,
          NETWORK_CLAIM_DEVICE_SUCCESS,
          NETWORK_CLAIM_DEVICE_FAILURE,
        ],
        endpoint: `/api/v1/networks/${networkId}/devices/claim`,
        config: {
          method: "POST",
          body: JSON.stringify({ serials: [serial] }),
        },
        parseError,
      }),
    );
}
