import { BrowserTracing } from "@sentry/tracing";
import { Integration } from "@sentry/types";

export function registerNavigationContainer() {
  // Do nothing. This is a compatibility layer between mobile and web.
}

export default function getIntegrations() {
  return [new BrowserTracing() as unknown as Integration];
}
