import { ClusterNames } from "@meraki/shared/auth";
import { useBoolFlag } from "@meraki/shared/feature-flags";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, TouchableOpacity } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { getCurrentCluster } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";

const ChooseClusterButton: React.FC = () => {
  const navigation = useNavigation();
  const currentClusterOption = useAppSelector(getCurrentCluster);
  const { value: showClusters, isLoading } = useBoolFlag("ld", "show-cluster-dropdown");

  if (!showClusters || isLoading) {
    return null;
  }

  return (
    <TouchableOpacity
      testID="cluster choice button"
      onPress={() => navigation.navigate("ChooseCluster")}
    >
      <MkiText textStyle="small" screenStyles={styles.clusterChoiceText}>
        {ClusterNames[currentClusterOption]}
      </MkiText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  clusterChoiceText: {
    backgroundColor: MkiColors.fullTransparent,
    color: MkiColors.primaryButton,
  },
});

export default ChooseClusterButton;
