import {
  CREATE_GO_ORG_FAILURE,
  CREATE_GO_ORG_REQUEST,
  CREATE_GO_ORG_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  ORG_BY_ID_GET_FAILURE,
  ORG_BY_ID_GET_REQUEST,
  ORG_BY_ID_GET_SUCCESS,
  ORG_GET_FAILURE,
  ORG_GET_REQUEST,
  ORG_GET_SUCCESS,
  SET_ORGANIZATION_NAME_FAILURE,
  SET_ORGANIZATION_NAME_REQUEST,
  SET_ORGANIZATION_NAME_SUCCESS,
} from "@meraki/shared/redux";

import { wrapApiActionWithCSRF } from "~/actions/csrf";
import Organization from "~/api/models/Organization";
import { COUNTRIES } from "~/constants/MkiConstants";
import { ApiAction, ApiResponseAction, CALL_API } from "~/middleware/api";
import { URLPrepends } from "~/shared/types/Networks";
import { AppThunk } from "~/shared/types/Redux";
import { Method } from "~/shared/types/RequestTypes";

export function fetchOrgs(): ApiAction {
  return {
    [CALL_API]: {
      types: [ORG_GET_REQUEST, ORG_GET_SUCCESS, ORG_GET_FAILURE],
      endpoint: "/api/v1/organizations",
      config: { method: Method.get },
    },
  };
}

export function fetchOrg(orgId: string): AppThunk<Promise<ApiResponseAction<Organization>>> {
  return (dispatch) =>
    dispatch({
      [CALL_API]: {
        types: [ORG_BY_ID_GET_REQUEST, ORG_BY_ID_GET_SUCCESS, ORG_BY_ID_GET_FAILURE],
        endpoint: `/api/v1/organizations/${orgId}`,
        config: { method: Method.get },
      },
    });
}

export function changeOrganizationName(organizationId: string, newName: string): AppThunk {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          SET_ORGANIZATION_NAME_REQUEST,
          SET_ORGANIZATION_NAME_SUCCESS,
          SET_ORGANIZATION_NAME_FAILURE,
        ],
        endpoint: `/api/v1/organizations/${organizationId}`,
        config: {
          method: Method.put,
          body: JSON.stringify({ name: newName }),
        },
      }),
    );
}

export function deleteOrg(organizationId: string): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) =>
    dispatch(
      wrapApiActionWithCSRF({
        types: [
          DELETE_ORGANIZATION_REQUEST,
          DELETE_ORGANIZATION_SUCCESS,
          DELETE_ORGANIZATION_FAILURE,
        ],
        endpoint: `/api/v1/organizations/${organizationId}`,
        config: { method: Method.delete },
      }),
    );
}

export function createGoOrg(
  name: string,
  timezone: string,
  country: string,
): AppThunk<Promise<ApiResponseAction<any>>> {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [CREATE_GO_ORG_SUCCESS, CREATE_GO_ORG_REQUEST, CREATE_GO_ORG_FAILURE],
        endpoint: `/go/create_organization`,
        prepend: URLPrepends.portal,
        config: {
          method: Method.post,
          headers: {
            Accept: "application/json",
            "Meraki-Go-Proxy-Region": COUNTRIES[country].region,
          },
          body: JSON.stringify({
            name,
            time_zone: timezone,
          }),
        },
      },
    });
  };
}
