import { createSelector } from "reselect";

import { NodeGroupIds } from "~/api/models/Network";
import NetworkUtils from "~/lib/NetworkUtils";
import { deviceState } from "~/selectors/devices";
import { devicesState, getNodeGroups } from "~/selectors/getters";
import { getCurrentNetwork } from "~/selectors/orgNetworks";
import Device, { DeviceWithNodeGroupInfo } from "~/shared/types/Device";
import { ProductType } from "~/shared/types/Networks";
import { ReduxSelector, RootState } from "~/shared/types/Redux";

export const getNetworkNodeGroupData: ReduxSelector<NodeGroupIds> = createSelector(
  getCurrentNetwork,
  (network) => {
    if (!network) {
      return undefined;
    }

    const { type, id } = NetworkUtils.parseId(network.id);
    if (type === "L") {
      return network.ngIds;
    }

    // NodeGroups have only 1 product type
    const key = network.productTypes[0];
    return {
      [key]: {
        ngId: id,
        ngEid: network.eid,
      },
    };
  },
);

export const getNetworkNodeGroupIds: ReduxSelector<string[]> = createSelector(
  getNetworkNodeGroupData,
  (nodeGroupData) => {
    if (!nodeGroupData) {
      return undefined;
    }

    return Object.values(nodeGroupData).map((ids) => ids.ngId);
  },
);

export const getNetworkNodeGroupEids = createSelector(
  getNetworkNodeGroupData,
  (_: RootState, filterOutSM: boolean) => filterOutSM,
  (nodeGroupData, filterOutSM) => {
    if (!nodeGroupData) {
      return undefined;
    }

    if (!filterOutSM) {
      return Object.values(nodeGroupData).map((ids) => ids.ngEid);
    }

    const result: string[] = [];
    const keys = Object.keys(nodeGroupData).filter((k) => k !== ProductType.systemsManager);
    for (let i = 0; i < keys.length; i++) {
      result.push(nodeGroupData[keys[i]].ngEid);
    }

    return result;
  },
);

export const getNodeGroupIdWithSerial = createSelector(
  getNetworkNodeGroupData,
  devicesState,
  (_: RootState, props: any) => props.serialNumber,
  (nodeGroupData, devices, serialNumber) => {
    const nodeGroupEid = devices?.[serialNumber]?.networkEid;
    if (!nodeGroupEid || !nodeGroupData) {
      return undefined;
    }

    const ngIds = Object.values(nodeGroupData);
    for (let i = 0; i < ngIds.length; i++) {
      const ids = ngIds[i];
      if (ids.ngEid === nodeGroupEid) {
        return ids.ngId;
      }
    }

    return undefined;
  },
);

export const getNodeGroupWithSerial = createSelector(
  getNodeGroupIdWithSerial,
  getNodeGroups,
  (nodeGroupId, nodeGroups) => (nodeGroupId ? nodeGroups?.[nodeGroupId] : undefined),
);

export const getDeviceWithFirmwareFromSerial = createSelector(
  deviceState,
  getNodeGroupIdWithSerial,
  getNodeGroups,
  (device: Device, nodeGroupId, nodeGroups): DeviceWithNodeGroupInfo | Device | undefined => {
    if (!device) {
      return undefined;
    }
    if (!nodeGroups || !nodeGroupId) {
      return device;
    }
    const nodeGroup = nodeGroups?.[nodeGroupId];
    if (!nodeGroup) {
      return device;
    }
    return {
      ...device,
      firmwareDetails: nodeGroup.firmware,
      hasEco:
        nodeGroup.has_firmware_eco || device.has_firmware_eco || device.has_tagged_firmware_eco,
    };
  },
);

const makeGetNodeGroupEidSelector = (type: ProductType) =>
  createSelector(getNetworkNodeGroupData, (nodeGroupData) => nodeGroupData?.[type]?.ngEid);

export const getCameraNodeGroupEid: ReduxSelector<string> = makeGetNodeGroupEidSelector(
  ProductType.camera,
);

export const getApplianceNodeGroupEid: ReduxSelector<string> = makeGetNodeGroupEidSelector(
  ProductType.appliance,
);

export const getSwitchNodeGroupEid: ReduxSelector<string> = makeGetNodeGroupEidSelector(
  ProductType.switch,
);

// TODO: remove "environmental" type once issue with this endpoint is resolved:
// /api/v1/organizations/${orgId}/networks
export const getSensorNodeGroupEid: ReduxSelector<string> = createSelector(
  makeGetNodeGroupEidSelector(ProductType.sensor),
  makeGetNodeGroupEidSelector(ProductType.environmental),
  (sensorNodeGroupEid, environmentalNodeGroupEid) =>
    sensorNodeGroupEid ?? environmentalNodeGroupEid,
);

export const getWirelessNodeGroupEid: ReduxSelector<string> = makeGetNodeGroupEidSelector(
  ProductType.wireless,
);
