import { useSwitchPortStatuses } from "@meraki/react-live-broker";
import { memo, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { connect } from "react-redux";

import {
  switchportIsAlerting,
  switchportIsConnected,
  switchPortIsUplink,
  switchPortUsingPoe,
} from "~/lib/SwitchPortStatusUtils";
import { isSFPPortType } from "~/lib/SwitchPortUtils";
import { devicesState, switchPortById } from "~/selectors";
import PortIcon, {
  PortIconSizes,
  PortIconTypes,
} from "~/shared/components/portDiagrams/portIcons/PortIcon";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { SwitchPort } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";

type ReduxProps = {
  switchPort: SwitchPort;
};

export interface SwitchPortIconProps extends ReduxProps {
  switchPortId?: string;
  screenStyles?: StyleProp<ViewStyle>;
  size: PortIconSizes;
  serialNumber: string;
}

const SwitchPortIcon: React.FC<SwitchPortIconProps> = (props: SwitchPortIconProps) => {
  const { switchPort, screenStyles, size, serialNumber } = props;
  const switchDevice = useAppSelector(devicesState)[serialNumber];
  const livePortStatuses = useSwitchPortStatuses(switchDevice?.id ?? "");
  const [isConnected, setIsConnected] = useState(false);
  const [isUplink, setIsUplink] = useState(false);
  const [isUsingPoe, setIsUsingPoe] = useState(false);
  const [isAlerting, setIsAlerting] = useState(false);

  useEffect(() => {
    const portForStatus = livePortStatuses?.[switchPort.num[0]] ?? switchPort;
    if (portForStatus) {
      setIsConnected(switchportIsConnected(portForStatus));
      setIsUplink(switchPortIsUplink(portForStatus));
      setIsUsingPoe(switchPortUsingPoe(portForStatus));
      setIsAlerting(switchportIsAlerting(portForStatus));
    }
  }, [livePortStatuses, switchPort]);

  if (!switchPort) {
    return null;
  }

  const iconName = isSFPPortType(switchPort.type) ? PortIconTypes.sfp : PortIconTypes.rj45;

  return (
    <PortIcon
      alerting={isAlerting}
      portType={iconName}
      size={size}
      connected={isConnected}
      uplink={isUplink}
      poe={isUsingPoe}
      enabled={switchPort.enabled}
      screenStyles={screenStyles}
    />
  );
};

SwitchPortIcon.defaultProps = {
  screenStyles: {},
};

function mapStateToProps(state: RootState, props: SwitchPortIconProps): ReduxProps {
  // This component can be passed a switchPortId or switchPort
  // object prop. If an id is passed, let's use redux to pull
  // in a full switchPort object. If an id is not passed, we
  // will assume that the user has passed a switchPort object
  // and we will not look up data in redux.

  const { switchPortId } = props;
  if (!switchPortId) {
    //@ts-ignore
    return {};
  }

  return {
    switchPort: switchPortById(state, switchPortId),
  };
}

export default connect(mapStateToProps)(memo(SwitchPortIcon));
