import { AnalyticsProp, useAnalytics } from "@meraki/core/analytics";
import { BottomSheet, BottomSheetMethods, BottomSheetProps } from "@meraki/magnetic/components";
import { Icon } from "@meraki/magnetic/icons";
import { ForwardedRef, forwardRef, PropsWithoutRef, ReactElement, RefAttributes } from "react";
import { ScrollView } from "react-native-gesture-handler";

import { InternalPickerWithFilter } from "./PickerWithFilter";

export type PickerOption<T> = {
  label: string;
  description?: string;
  value: T;
};

export type PickerProps<T> = React.RefAttributes<BottomSheetMethods> &
  Pick<BottomSheetProps, "snapPoints"> & {
    options: PickerOption<T>[];
    onSelectOption: (option: PickerOption<T>) => void;
    selectedOption?: PickerOption<T>;
    testID?: string;
    initialScrollIndex?: number;
  } & AnalyticsProp<"onOpen" | "onChange">;

// Redecalare forwardRef
// used for generics with FC's
function forwardRefFC<T, P = Record<string, never>>(
  render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: PropsWithoutRef<P> & RefAttributes<T>) => ReactElement | null {
  return forwardRef(render);
}

function PickerInternal<T>(
  {
    options,
    onSelectOption,
    selectedOption,
    snapPoints,
    testID,
    analytics,
    initialScrollIndex,
  }: PickerProps<T>,
  ref: ForwardedRef<BottomSheetMethods>,
) {
  const trackEvent = useAnalytics(analytics);
  return (
    <BottomSheet.Modal ref={ref} snapPoints={snapPoints} index={0}>
      <BottomSheet.FlashList
        testID={testID}
        data={options}
        renderScrollComponent={ScrollView}
        initialScrollIndex={initialScrollIndex}
        getItemData={(option) => ({
          testID: `PICKER_OPTION_${option.label}`,
          title: option.label,
          description: option.description,
          rightAccessory: option.value === selectedOption?.value && (
            <Icon name="Check" color="brandAccent.icon.active" />
          ),
          onPress: () => {
            onSelectOption(option);
            trackEvent("onChange", { value: option.value });
            if (typeof ref !== "function") {
              setTimeout(() => ref?.current?.dismiss());
            }
          },
          hidePressable: true,
        })}
      />
    </BottomSheet.Modal>
  );
}

export const Picker = Object.assign(forwardRefFC(PickerInternal), {
  WithFilter: forwardRefFC(InternalPickerWithFilter),
});
