import { Icon } from "@meraki/magnetic/icons";
import { Box, BoxProps } from "@meraki/magnetic/layout";
import { ReactNode } from "react";

import { Text, TextProps } from "../Text/Text";

const MIN_ACCESSORY_HEIGHT = 22;

export type ListItemInternalProps = BoxProps & {
  title: TextProps["children"];
  value?: TextProps["children"] | (string | null | undefined)[] | null;
  description?: TextProps["children"] | (string | null | undefined)[] | null;
  children?: ReactNode;

  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;

  // A helper property that is used for list items that are "pressable" but not navigation.
  // This is useful for bottom sheets that have selections
  hidePressable?: boolean;

  // Calculated, passed in via List or ListItem, not to be used by everyone else.
  last?: boolean;
  first?: boolean;
  pressable?: boolean;
};

function isArrayOfStrings(
  value: ListItemInternalProps["value"],
): value is (string | null | undefined)[] {
  // This function is relying on TS a little bit since we are only validating the first item in the array is a string.
  // TS yells if you try and pass a component as an item and a string as an item in an array due to our typing.
  return Array.isArray(value) && value.length > 0 && typeof value[0] === "string";
}

function getValues(value: ListItemInternalProps["value"]): TextProps["children"][] {
  if (!value) {
    return [];
  }

  if (isArrayOfStrings(value)) {
    return value;
  }

  return [value];
}

export function ListItemInternal({
  title,
  value,
  description,
  children,
  first,
  last,
  leftAccessory,
  rightAccessory,
  hidePressable,
  pressable,
  minHeight,
  ...rest
}: ListItemInternalProps) {
  const descriptions = getValues(description);
  const values = getValues(value);

  return (
    <Box
      flex={1}
      overflow="hidden"
      backgroundColor="control.bg.base"
      borderTopLeftRadius={first ? "lg" : undefined}
      borderTopRightRadius={first ? "lg" : undefined}
      borderBottomLeftRadius={last ? "lg" : undefined}
      borderBottomRightRadius={last ? "lg" : undefined}
    >
      <Box flexDirection="row" padding="sm" paddingRight="none" paddingBottom="none" gap="xs">
        {!!leftAccessory && (
          <Box justifyContent="flex-start">
            <Box minHeight={MIN_ACCESSORY_HEIGHT} justifyContent="center">
              {leftAccessory}
            </Box>
          </Box>
        )}

        <Box
          flex={1}
          flexDirection="row"
          paddingBottom="sm"
          paddingRight="sm"
          gap="xs"
          bottomDividerBorder={!last && !children}
          minHeight={!children ? minHeight : undefined}
          {...rest}
        >
          <Box
            flex={1}
            // We only center in this row if we don't have a value. Reason: Value can be multiple lines and if thats the case we don't want the title centering in all the values
            justifyContent={typeof value === "undefined" ? "center" : "flex-start"}
          >
            <Text size="p1" numberOfLines={2} ellipsizeMode="tail">
              {title}
            </Text>
            {descriptions.map((value, index) => (
              <Text key={index} size="p3" color="light">
                {value}
              </Text>
            ))}
          </Box>

          <Box maxWidth="70%" flexDirection="row" gap="xs">
            {typeof value !== "undefined" && value !== null && (
              <Box alignItems="flex-end">
                {values.map((value, index) => (
                  <Text key={index} size="p1" color="light">
                    {value}
                  </Text>
                ))}
              </Box>
            )}
            {(rightAccessory || (pressable && !hidePressable)) && (
              <Box justifyContent="flex-start">
                <Box
                  flexDirection="row"
                  minHeight={MIN_ACCESSORY_HEIGHT}
                  alignItems="center"
                  gap="2xs"
                >
                  {rightAccessory}
                  {pressable && !hidePressable && (
                    <Icon name="CaretRight" size={MIN_ACCESSORY_HEIGHT} />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {children && (
        <Box
          marginLeft="sm"
          paddingRight="sm"
          paddingBottom="sm"
          minHeight={minHeight}
          bottomDividerBorder={!last}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
