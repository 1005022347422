import { memo } from "react";
import { ActivityIndicator, ActivityIndicatorProps, StyleSheet } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { appSelect } from "~/lib/PlatformUtils";
import { themeColors } from "~/lib/themeHelper";
import { useTheme } from "~/shared/hooks/useTheme";

interface MkiSpinnerProps extends ActivityIndicatorProps {
  fillHeight?: boolean;
}

const MkiSpinner = ({
  animating = true,
  size = "large",
  color,
  style,
  fillHeight,
}: MkiSpinnerProps) => {
  const navColors = themeColors(useTheme()).navigation.primary;

  const derivedColor =
    color ??
    appSelect({
      go: MkiColors.spinner,
      enterprise: navColors,
    });

  const derivedStyle = fillHeight ? [style, styles.spinner] : style;

  return (
    <ActivityIndicator
      animating={animating}
      color={derivedColor}
      size={size}
      style={derivedStyle}
      testID="MKI_SPINNER"
    />
  );
};

const styles = StyleSheet.create({
  spinner: {
    height: "100%",
  },
});

export default memo(MkiSpinner);
