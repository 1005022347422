import { z } from "zod";

import { request } from "../../api/request/request";
import { APIResponseError } from "../../schemas";
import { createMutation } from "../createMutation";
import { createQuery } from "../createQuery";

interface GroupPoliciesRequest {
  networkId?: string;
  groupPolicyId?: string;
}

interface CreatePolicyRequest {
  networkId?: string;
  name: string;
  bandwidth: {
    settings: BandwidthSettings;
    bandwidthLimits: {
      limitUp?: number;
      limitDown?: number;
    };
  };
}

interface UpdatePolicyRequest extends GroupPoliciesRequest {
  groupPolicy: GroupPolicy;
}

export const BandwidthSettings = z.union([
  z.literal("custom"),
  z.literal("network default"),
  z.literal("ignore"),
]);

export type BandwidthSettings = z.infer<typeof BandwidthSettings>;

export const SplashAuthSettings = z.union([z.literal("network default"), z.literal("bypass")]);

export const ContentFilteringSettings = z.union([
  z.literal("append"),
  z.literal("network default"),
  z.literal("override"),
]);

export const BonjourForwardingServices = z.union([
  z.literal("All Services"),
  z.literal("AirPlay"),
  z.literal("AFP"),
  z.literal("BitTorrent"),
  z.literal("FTP"),
  z.literal("iChat"),
  z.literal("iTunes"),
  z.literal("Printers"),
  z.literal("Samba"),
  z.literal("Scanners"),
  z.literal("SSH"),
]);

export const GroupPolicySchedulingDay = z.object({
  active: z.boolean(),
  from: z.string(),
  to: z.string(),
});

export const GroupPolicyBandwidthLimits = z.object({
  limitDown: z.number().nullable(),
  limitUp: z.number().nullable(),
});
export const GroupPolicyBandwidth = z.object({
  settings: BandwidthSettings,
  bandwidthLimits: GroupPolicyBandwidthLimits,
});

export const GroupPolicyScheduling = z.object({
  enabled: z.boolean(),
  monday: GroupPolicySchedulingDay,
  tuesday: GroupPolicySchedulingDay,
  wednesday: GroupPolicySchedulingDay,
  thursday: GroupPolicySchedulingDay,
  friday: GroupPolicySchedulingDay,
  saturday: GroupPolicySchedulingDay,
  sunday: GroupPolicySchedulingDay,
});

export const GroupPolicyVlanTagging = z.object({
  settings: BandwidthSettings,
  vlanId: z.optional(z.string()),
});

export const BonjourForwardingRule = z.object({
  description: z.string(),
  vlanId: z.string(),
  services: z.array(BonjourForwardingServices),
});

export const GroupPolicyBonjourForwarding = z.object({
  settings: BandwidthSettings,
  rules: z.array(BonjourForwardingRule),
});

export const GroupPolicyContentFiltering = z.object({
  allowedUrlPatterns: z.object({
    settings: ContentFilteringSettings,
    patterns: z.array(z.string()),
  }),
  blockedUrlPatterns: z.object({
    settings: ContentFilteringSettings,
    patterns: z.array(z.string()),
  }),
  blockedUrlCategories: z.object({
    settings: ContentFilteringSettings,
    categories: z.array(z.string()),
  }),
});

// update the any types before merging
export const GroupPolicySchema = z
  .object({
    groupPolicyId: z.string(),
    name: z.string(),
    scheduling: z.optional(GroupPolicyScheduling),
    bandwidth: z.optional(GroupPolicyBandwidth).nullable(),
    firewallAndTrafficShaping: z.optional(z.any()).nullable(),
    contentFiltering: z.optional(GroupPolicyContentFiltering).nullable(),
    splashAuthSettings: z.optional(SplashAuthSettings).nullable(),
    bonjourForwarding: z.optional(GroupPolicyBonjourForwarding).nullable(),
    vlanTagging: z.optional(GroupPolicyVlanTagging).nullable(),
  })
  .describe("GroupPolicySchema");

export type GroupPolicy = z.infer<typeof GroupPolicySchema>;

export const GroupPoliciesSchema = z.array(GroupPolicySchema).describe("GroupPoliciesSchema");

export type GroupPolicies = z.infer<typeof GroupPoliciesSchema>;

const buildMutationUrl = ({ networkId, groupPolicyId }: GroupPoliciesRequest) => {
  if (groupPolicyId) {
    return `/api/v1/networks/${networkId}/groupPolicies/${groupPolicyId}`;
  }
  return `/api/v1/networks/${networkId}/groupPolicies`;
};

function fetchGroupPolicies({ networkId }: GroupPoliciesRequest): Promise<GroupPolicies> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching group policies");
  }
  return request(GroupPoliciesSchema, "GET", buildMutationUrl({ networkId }));
}

function fetchGroupPolicy({
  networkId,
  groupPolicyId,
}: GroupPoliciesRequest): Promise<GroupPolicy> {
  if (!networkId) {
    throw new Error("Undefined network id when fetching group policies");
  }
  return request(GroupPolicySchema, "GET", buildMutationUrl({ networkId, groupPolicyId }));
}

function mutateGroupPolicy({
  networkId,
  name,
  bandwidth,
}: CreatePolicyRequest): Promise<GroupPolicy> {
  if (!networkId) {
    throw new Error("Undefined network id when creating group policy");
  }
  return request(GroupPolicySchema, "POST", buildMutationUrl({ networkId }), {
    body: JSON.stringify({
      name,
      bandwidth,
    }),
  });
}

function deleteGroupPolicy({ networkId, groupPolicyId }: GroupPoliciesRequest): Promise<unknown> {
  if (!networkId) {
    throw new Error("Undefined network id when creating group policy");
  }
  return request(GroupPolicySchema, "DELETE", buildMutationUrl({ networkId, groupPolicyId }));
}

function updateGroupPolicy({
  networkId,
  groupPolicyId,
  groupPolicy,
}: UpdatePolicyRequest): Promise<GroupPolicy> {
  if (!networkId) {
    throw new Error("Undefined network id when creating group policy");
  }
  return request(GroupPolicySchema, "POST", buildMutationUrl({ networkId, groupPolicyId }), {
    body: JSON.stringify(groupPolicy),
  });
}

export const useGroupPolicy = createQuery<GroupPoliciesRequest, GroupPolicy>({
  baseQueryKey: buildMutationUrl({ networkId: "{networkId}", groupPolicyId: "{groupPolicyId}" }),
  queryFn: (params: GroupPoliciesRequest) => fetchGroupPolicy(params),
  requiredVariables: ["networkId", "groupPolicyId"],
});

export const useGroupPolicies = createQuery<GroupPoliciesRequest, GroupPolicies>({
  baseQueryKey: buildMutationUrl({ networkId: "{networkId}" }),
  queryFn: (params: GroupPoliciesRequest) => fetchGroupPolicies(params),
  requiredVariables: ["networkId"],
});

export const useCreateGroupPolicy = createMutation<
  CreatePolicyRequest,
  GroupPolicy,
  APIResponseError
>({
  baseMutationKey: buildMutationUrl({ networkId: "{networkId}" }),
  mutationFn: (params: CreatePolicyRequest) => mutateGroupPolicy(params),
});

export const useUpdateGroupPolicy = createMutation<UpdatePolicyRequest, GroupPolicy>({
  baseMutationKey: buildMutationUrl({ networkId: "{networkId}", groupPolicyId: "{groupPolicyId}" }),
  mutationFn: (params: UpdatePolicyRequest) => updateGroupPolicy(params),
});

export const useDeleteGroupPolicy = createMutation<GroupPoliciesRequest, unknown>({
  baseMutationKey: buildMutationUrl({ networkId: "{networkId}", groupPolicyId: "{groupPolicyId}" }),
  mutationFn: (params: GroupPoliciesRequest) => deleteGroupPolicy(params),
});
