import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function PauseIcon(props: Props) {
  const { size = BUTTON_SIZING.width.default } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 28 28" fill="none">
      <Path
        d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7312 6.26875 28 14 28C21.7312 28 28 21.7312 28 14C28 6.26875 21.7312 0 14 0ZM11.5 18.75C11.5 18.8875 11.3875 19 11.25 19H9.75C9.6125 19 9.5 18.8875 9.5 18.75V9.25C9.5 9.1125 9.6125 9 9.75 9H11.25C11.3875 9 11.5 9.1125 11.5 9.25V18.75ZM18.5 18.75C18.5 18.8875 18.3875 19 18.25 19H16.75C16.6125 19 16.5 18.8875 16.5 18.75V9.25C16.5 9.1125 16.6125 9 16.75 9H18.25C18.3875 9 18.5 9.1125 18.5 9.25V18.75Z"
        fill="white"
      />
    </Svg>
  );
}

export default PauseIcon;
