/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum EnterpriseColors {
  blue = "#1470CC",
  fuschia = "#A6214D",
  gray10 = "#222325",
  gray20 = "#222325",
  gray30 = "#3D3E40",
  gray40Darker = "#8E959B",
  gray70Lighter = "#C5C5C7",
  gray90 = "#F2F2F2",
  gray90Darker = "#EBEBEB",
  gray90Lighter = "#FAFAFA",
  gray97 = "#F9F9F9",
  green = "#67B346",
  purple = "#424DFB",
  purpleDarker = "#3E52B3",
  red = "#FC5635",
  redDarker = "#C7290A",
  redLighter = "#F7A894",
  red10 = "#9E2C1B",
  red20 = "#C24632",
  teal = "#45B6E6",
  tealLighter = "#AADDF3",
  violet = "#45B6E6",
  yellow = "#FFBF3F",
  yellowLighter = "#FFDF9E",
  white = "#FFFFFF",
  mpurple = "#8D537C",
  lpurple = "#9659AF",
  mblue = "#1077B3",
  lblue = "#40B7D6",
  morange = "#F97821",
  mlorange = "#FFA43A",
  lorange = "#FCBF62",
  mpink = "#D67193",
  lpink = "#F2AAC2",
  mgreen = "#008C8D",
  lgreen = "#94B378",
  neonGreen = "#5ce600",
  neutral3 = "#F0F1F2",
  neutral10 = "#7E868F",
  neutral12 = "#656C75",
  neutral16 = "#23282E",

  // IAQ_COLORS
  excellent = "#41B840",
  good = "#8EBC42",
  fair = "#FFBF3F",
  poor = "#FF9000",
  inadequate = "#DA0000",

  // potential IAQ deuteranomally variants
  excellentDtmaly = "#41B8fc",
  goodDtmaly = "#8EBCff",

  // New Design System
  systemRed = "#EB6450",
  systemGreenDark = "#79CE4E",
  systemBlueDark = "#7AA7F5",
  systemYellowDark = "#F0D665",
  systemGrey1 = "#8F8F8F",

  backgroundPrimaryDark = "#161716",
  backgroundPrimaryElevated = "#242625",
  backgroundTertiaryDark = "#343635",

  textLight = "#EEEEEE",
  textSecondary = "#8F8F8F",
  textSecondaryDark = "#B2B2B2",
  textTertiary = "#D1D1D1",
  textTertiaryDark = "#494A4A",

  //Taken from manage/.../set_color_palette(), and conferted to Hex
  greenDtmaly = "#67b2fc",
  greenDarkerDtmaly = "#6486e5",
  lightGreenDtmaly = "#add8ff",
  darkGreenContastDtmaly = "#5a7cdb",
  lightGreenContastDtmaly = "#b7e2ff",
}

export default EnterpriseColors;
