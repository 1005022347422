import { Icon, IconProps } from "@meraki/magnetic/icons";
import { Box } from "@meraki/magnetic/layout";
import { Theme, useMagneticTheme } from "@meraki/magnetic/themes";
import Color from "color";
import { Pressable, PressableProps, StyleProp, ViewStyle } from "react-native";

import { Text } from "../Text/Text";

export type ViewSwitcherOptionObject =
  | {
      testID?: string;
      value: string;
      label: string;
      icon?: never;
    }
  | {
      testID?: string;
      value: string;
      label?: never;
      icon: IconProps["name"];
    };
export type ViewSwitcherOption = ViewSwitcherOptionObject | string;

type OptionProps = Omit<PressableProps, "onPress" | "style"> & {
  positionMeta: {
    first?: boolean;
    last?: boolean;
    leftOfSelected?: boolean;
    rightOfSelected?: boolean;
  };

  addFlexToOptions?: boolean;

  selected?: boolean;
  onChange: (value: string) => void;
  value: string;
  icon?: IconProps["name"];
  label?: string;
};

function getContainerStyle(
  { addFlexToOptions, positionMeta, selected }: OptionProps,
  theme: Theme,
): StyleProp<ViewStyle> {
  let style: StyleProp<ViewStyle> = {
    alignItems: "center",
    paddingVertical: 6,
    backgroundColor: selected
      ? Color(theme.color.control.bg.medium.active).alpha(0.3).hexa()
      : "transparent",

    borderColor: theme.color.control.border.weak.base,
    borderWidth: 2,
    borderRightWidth: 0,
  };

  if (addFlexToOptions) {
    style = { ...style, flex: 1 };
  }

  if (positionMeta.first) {
    style = {
      ...style,

      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    };
  }

  if (positionMeta.last) {
    style = {
      ...style,

      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderRightWidth: 2,
    };
  }

  if (positionMeta.rightOfSelected) {
    style = { ...style, borderLeftWidth: 0 };
  }

  if (selected) {
    style = {
      ...style,
      borderColor: theme.color.control.border.active,
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderRightWidth: 2,
    };
  }

  return style;
}

export function Option(props: OptionProps) {
  const theme = useMagneticTheme();

  const { selected, label, icon, value, onChange, ...rest } = props;

  return (
    <Pressable
      {...rest}
      onPress={() => onChange(value)}
      style={getContainerStyle(props, theme)}
      accessibilityRole="menuitem"
      accessibilityState={{ selected }}
    >
      {!!label && (
        <Text size="p2" color={selected ? "interact.text.weak.active" : "default.text.medium.base"}>
          {label}
        </Text>
      )}
      {!!icon && (
        <Box paddingHorizontal="2xs">
          <Icon name={icon} color={selected ? "interact.icon.active" : undefined} />
        </Box>
      )}
    </Pressable>
  );
}
