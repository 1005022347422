import { useUmbrellaPolicies } from "@meraki/shared/api";

import { SUPPORTED_UMBRELLA_POLICIES } from "~/constants/Umbrella";

export const useGoUmbrellaPolicies = (organizationId: string) => {
  return useUmbrellaPolicies(
    { organizationId },
    {
      select: (data) => data.filter(({ name }) => SUPPORTED_UMBRELLA_POLICIES.includes(name)),
    },
  );
};
