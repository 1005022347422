import GeneralStatus from "~/shared/constants/Status";

export const statusForUptimePercent = (goodPercent: number) => {
  switch (true) {
    case goodPercent >= 0.95:
      return GeneralStatus.good;
    case goodPercent >= 0.5 && goodPercent < 0.95:
      return GeneralStatus.alerting;
    case goodPercent < 0.5:
      return GeneralStatus.bad;
    default:
      return GeneralStatus.dormant;
  }
};
