import { StyleSheet, View, ViewStyle } from "react-native";

import { SPACING } from "~/constants/MkiConstants";
import SkeletonBlock from "~/shared/components/SkeletonBlock";

interface SkeletonLine {
  width?: number;
  height?: number;
  withMarginBottom?: boolean;
}

export default function SkeletonLine({ width, height, withMarginBottom }: SkeletonLine) {
  const style: ViewStyle[] = [
    styles.container,
    {
      width: width ? `${width}%` : undefined,
      height: height ? height : undefined,
    },
  ];

  if (withMarginBottom) {
    style.push(styles.withMarginBottom);
  }

  return (
    <View style={style}>
      <SkeletonBlock />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  withMarginBottom: {
    marginBottom: SPACING.default,
  },
});
