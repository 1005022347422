import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Licensing({ width, height, color }: IconProps) {
  const theme = useMagneticTheme();

  const color1 = color
    ? theme.color.brandAccent.icon.medium.active
    : theme.color.brandAccent.icon.medium.base;
  const color2 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.weak.base;
  const color3 = color
    ? theme.color.brandAccent.icon.weak.active
    : theme.color.brandAccent.icon.base;

  return (
    <Svg viewBox="0 0 24 24" width={width} height={height}>
      <Path
        d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8.5v-2H5V5h12v3.6a5.022 5.022 0 0 1 2 0V5a2 2 0 0 0-2-2H5Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
      <Path
        d="M15.5 15.95A3.49 3.49 0 0 0 18 17a3.49 3.49 0 0 0 2.5-1.05V14h-5v1.95Zm0 1.881V23l2.5-1.5 2.5 1.5v-5.169a4.977 4.977 0 0 1-2.5.669c-.91 0-1.765-.244-2.5-.669Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color2}
      />
      <Path d="M21.5 13.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" fill={color3} />
      <Path
        d="M23 13.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0ZM18 17a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill={color1}
      />
    </Svg>
  );
}
