import { z } from "zod";

import { request } from "../../api/request/request";
import { createMutation } from "../createMutation";

export type ProductTypes =
  | "combined"
  | "appliance"
  | "wireless"
  | "switch"
  | "systemsManager"
  | "camera"
  | "sensor"
  | "cellularGateway";

type NetworkRequest = {
  name: string;
  organizationId: string;
  productTypes: ProductTypes[];
};

const CreateNetworkSchema = z
  .object({
    id: z.string(),
    organizationId: z.string(),
    productTypes: z.array(z.string()),
    url: z.string(),
    name: z.string(),
    timeZone: z.string(),
    enrollmentString: z.string().nullable(),
    tags: z.array(z.string()),
    notes: z.string().nullable(),
    isBoundToConfigTemplate: z.boolean(),
  })
  .describe("CreateNetworkSchema");

type CreateNetworkResponse = z.infer<typeof CreateNetworkSchema>;

const buildUrl = ({ organizationId }: { organizationId: string }) => {
  return `/api/v1/organizations/${organizationId}/networks`;
};

const createNetwork = (data: NetworkRequest) =>
  request(CreateNetworkSchema, "POST", buildUrl(data), {
    body: JSON.stringify({
      name: data.name,
      productTypes: data.productTypes,
    }),
  });

export const useCreateNetwork = createMutation<NetworkRequest, CreateNetworkResponse>({
  baseMutationKey: buildUrl({ organizationId: "{organizationId}" }),
  mutationFn: (formData) => createNetwork(formData),
});
