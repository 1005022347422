import { I18n } from "@meraki/core/i18n";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  ForwardedStackScreenProps,
  withForwardedNavigationParams,
} from "react-navigation-props-mapper";

import { HardwareStackPropMap } from "~/go/navigation/Types";

import HardwareListScreen from "../screens/HardwareListScreen";
import { hardwareScreens } from "./HardwareScreens";
import { useDefaultScreenOptions } from "./Options";

const ForwardedHardwareList =
  withForwardedNavigationParams<ForwardedStackScreenProps<HardwareStackPropMap, "HardwareList">>()(
    HardwareListScreen,
  );

const Stack = createNativeStackNavigator<HardwareStackPropMap>();

const HardwareStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen
        name="HardwareList"
        component={ForwardedHardwareList}
        options={{ headerTitle: I18n.t("ROOT_TITLES.HARDWARE") }}
      />
      {hardwareScreens(Stack)}
    </Stack.Navigator>
  );
};

export default HardwareStack;
