import { RUSAGE_KEY } from "@meraki/shared/api";
import {
  ADMINS_SUCCESS,
  CLEAR_NESTED_MODAL_DATA,
  COUNTER_SETS_SUCCESS,
  NETWORK_HEALTH_SUCCESS,
  NETWORK_USEBLOCKS_SUCCESS,
  NODES_SUCCESS,
  SET_CLIENT_INFO,
  SET_NESTED_MODAL_DATA,
  SET_REDUX_SSIDS,
  SET_TIMESPAN,
  SSIDS_SUCCESS,
  WIPE_REDUX,
} from "@meraki/shared/redux";
import { merge } from "lodash";

import resolvedState from "~/lib/ReducerUtils";

const initialState = {
  users: {},
  groupPolicies: {},
  networkCounterSets: {},
  deviceUsage: {},
  events: {},
  networkHealth: {},
  ruleUseblocks: {},
  smClients: {},
  smClientData: {},
  smClientProfiles: {},
};

export default function entities(state = initialState, action: any) {
  // Actions that have been ported away from using lodash.merge and have shallow state.
  if (
    [
      ADMINS_SUCCESS,
      NETWORK_USEBLOCKS_SUCCESS,
      NODES_SUCCESS,
      SET_CLIENT_INFO,
      SET_REDUX_SSIDS,
      SSIDS_SUCCESS,
    ].includes(action.type)
  ) {
    return state;
  }
  const defaultResponse = () => {
    if (action.response && action.response.entities) {
      const nextState = merge({}, state, action.response.entities);
      return resolvedState(nextState, state);
    }
    return state;
  };

  // NOTE: shallow overwrites values in client with newClient's values
  switch (action.type) {
    case SET_NESTED_MODAL_DATA:
      return {
        ...state,
        nestedModalData: action.nestedModalData,
      };
    case CLEAR_NESTED_MODAL_DATA:
      return {
        ...state,
        nestedModalData: null,
      };
    case NETWORK_HEALTH_SUCCESS:
      return {
        ...state,
        networkHealth: {
          t0: action.meta.t0 * 1000,
          t1: action.meta.t1 * 1000,
          ...action.response.entities.networkHealth,
          ...action.response.entities.localeHealth,
        },
      };
    case SET_TIMESPAN:
      return {
        ...state,
        networkHealth: {},
      };
    case COUNTER_SETS_SUCCESS:
      const counterSets = action.response;
      const { networkId } = action.meta;

      const applicationLevel = {
        ...counterSets[RUSAGE_KEY],
        ruleIdsByName: {},
      };

      for (const [id, rule] of Object.entries(applicationLevel.rules)) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        applicationLevel.ruleIdsByName[rule.name] = id;
      }

      return {
        ...state,
        networkCounterSets: {
          ...state.networkCounterSets,
          [networkId]: {
            ...counterSets,
            [RUSAGE_KEY]: applicationLevel,
          },
        },
      };
    case WIPE_REDUX:
      return initialState;
    default:
      return defaultResponse();
  }
}
