import { LoginContext } from "@meraki/shared/api";
import { MkiconfState, TimeSpan, TWO_HOURS } from "@meraki/shared/redux";

import ClientsOverview from "~/api/models/ClientsOverview";
import Network from "~/api/models/Network";
import Organization from "~/api/models/Organization";
import { GoOrgSearchResult } from "~/api/schemas/GoOrgSearch";
import { VideoChannel } from "~/api/schemas/VideoChannel";
import { RequestStatus } from "~/constants/Requests";
import { PLAYBACK_RATES } from "~/enterprise/constants/Camera";
import { PlaybackRateId, SettingPageId, VideoStatus } from "~/enterprise/types/Cameras";
import { VideoExport } from "~/enterprise/types/VideoExport";
import { EidKeyedData, OutageData, Peer, Usage, Vpn } from "~/enterprise/types/VpnTypes";
import { IpsecSettings } from "~/go/types/ClientVPN";
import { SiteToSiteSettings } from "~/go/types/SiteToSiteVPN";
import { ORIGINAL_LOCALE } from "~/i18n/i18n";
import { FormattedConfig } from "~/lib/FirebaseUtils";
import { SupportCaseState } from "~/reducers/supportCases";
import { UserDataState } from "~/reducers/userData";
import { TemperatureUnits } from "~/shared/constants/SensorMetrics";
import { AdminsItems, AuthorizationsByEmail } from "~/shared/types/AdminTypes";
import { LoginOrg, LoginSecurityByOrg } from "~/shared/types/AuthTypes";
import {
  ClientApplicationUsages,
  ClientEvent,
  ClientList,
  ClientUsageHistories,
} from "~/shared/types/Client";
import { ClientPolicy } from "~/shared/types/ClientPolicy";
import { DevicesBySerial } from "~/shared/types/Device";
import { RemoteConfigParams } from "~/shared/types/Firebase";
import { SwitchPortState, WirelessProfile } from "~/shared/types/Models";
import { NetworkEvent } from "~/shared/types/NetworkEvent";
import { NetworksState } from "~/shared/types/Networks";
import { NodeGroupsState } from "~/shared/types/NodeGroups";
import { RootState } from "~/shared/types/Redux";
import { UmbrellaProtection } from "~/shared/types/Umbrella";
const EMPTY_OBJECT = {};

// Getter functions must:
//   - Have O(1) complexity
//   - Retrieve some value from the Redux state
//   - Have at most one getter dependency
//   - Not combine multiple state values
//   - Have state be the only argument
//
// All other selectors should go into a feature specific
// selectors file.
type Getter<T> = (state: RootState) => T;

// auth
export const errorMessageState: Getter<string> = (state) => state?.auth?.errorMessage;

export const requiresTwoFactorState: Getter<boolean> = (state) =>
  state?.auth?.requiresTwoFactor || false;

export const requiresTwoFactorReset: Getter<boolean> = (state) =>
  state?.auth?.resetTwoFactor || false;

export const isAuthenticatedState: Getter<boolean | undefined> = (state) =>
  state?.auth?.isAuthenticated;

export const isFetchingState: Getter<boolean | undefined> = (state) => state?.auth?.isFetching;

export const isResettingPasswordState: Getter<boolean | undefined> = (state) =>
  state?.auth?.isResettingPassword;

export const smsBackupState: Getter<string | null> = (state) => state?.auth?.smsBackup;

export const smsPrimaryState: Getter<string | null> = (state) => state?.auth?.smsPrimary;

export const smsSecondaryState: Getter<string | null> = (state) => state?.auth?.smsSecondary;

export const isUpdateRequired: Getter<boolean> = (state) => state?.auth?.updateRequired ?? false;

export const getTwoFactorSecret: Getter<string> = (state) => state?.auth?.twoFactorSecret ?? "";

export const getTwoFactorUsername: Getter<string> = (state) => state?.auth?.twoFactorUsername ?? "";

export const getLastTwoDigitOfBackupTFA: Getter<string> = (state) =>
  state?.auth?.lastTwoDigitOfBackup ?? "";

export const getLoginOrgs: Getter<LoginOrg[]> = (state) => state?.auth?.loginOrgs ?? [];

export const getLoginSecurity: Getter<LoginSecurityByOrg> = (state) => state?.auth?.loginSecurity;

export const showCaptchaState: Getter<boolean> = (state) => state?.auth?.showCaptcha;

export const getIsIdleTimeout: Getter<boolean> = (state) => state?.auth?.idleTimeoutSessionExpired;

export const getIsSSOInProgress: Getter<boolean> = (state) => !!state?.auth?.isSSOInProgress;

export const getLoginContext: Getter<LoginContext | undefined> = (state) =>
  state?.auth?.loginContext;

// entities
const ruleUseblocksEmptyObject = {};
export const ruleUseblocksState = (state: RootState) =>
  state.entities.ruleUseblocks ?? ruleUseblocksEmptyObject;

export const nestedModalDataState = (state: RootState) => state.entities.nestedModalData;

export const deviceUsageState = (state: RootState) => state.entities.deviceUsage;

export const networkHealthState = (state: RootState) => state.entities.networkHealth;

export const groupPoliciesState = (state: RootState) => state.entities.groupPolicies;

// alertSettings
export const alertSettingsSelector = (state: RootState) => state?.alertSettings;

export const alertSettingsFetching: Getter<boolean | undefined> = (state) =>
  alertSettingsSelector(state).isFetching;

// clients
const emptyClientPolicies: any = [];

export const clientsState: Getter<ClientList> = (state) => state?.clients?.items || {};

export const getClientPolicies: Getter<ClientPolicy[]> = (state) =>
  state?.clients?.clientPolicies ?? emptyClientPolicies;

export const getClientUsageHistories: Getter<ClientUsageHistories> = (state) =>
  state?.clients?.usageHistories;

export const getClientApplicationUsages: Getter<ClientApplicationUsages> = (state) =>
  state?.clients?.applicationUsages;

export const getClientRequestStatus: Getter<RequestStatus> = (state) => state?.clients?.status;

// devices
export const devicesState: Getter<DevicesBySerial> = (state) => state.devices;

export const searchText = (state: RootState, key: string) => state.search[key];

export const deviceClientsState = (state: RootState) => state.deviceClients;

// events
export const eventsState: Getter<NetworkEvent[] | ClientEvent[]> = (state) => state.events;

// mkiconf
const getMkiconf: Getter<MkiconfState> = (state) => state?.mkiconf;

export const currentNetworkState: Getter<string | undefined> = (state) =>
  getMkiconf(state)?.currentNetwork;

export const getCurrentOrganization: Getter<string | undefined> = (state) =>
  getMkiconf(state)?.currentOrganization;

export const currentShardIdState = (state: RootState) =>
  getMkiconf(state)?.currentShardId ?? undefined;

export const getCurrentCluster = (state: RootState) =>
  getMkiconf(state)?.currentCluster ?? ("default" as const);

export const getCurrentLocale = (state: RootState) =>
  getMkiconf(state)?.currentLocale ?? ORIGINAL_LOCALE;

export const currentNetworkClientTrackingMethod = (state: RootState) =>
  getMkiconf(state)?.currentNetworkClientTrackingMethod;

export const getLatestSupportCaseSubmitTime: Getter<number | undefined> = (state) =>
  getMkiconf(state)?.latestSupportCaseSubmitTime;

// networks
export const networksState: Getter<NetworksState> = (state) => state.networks;

// navigation
export const getBottomTabConfig = (state: RootState) => state.navigation.bottomTabBarConfig;

export const getPendingAppLink = (state: RootState) => state.navigation.pendingAppLink;

export const getSelectedVlanId = (state: RootState) => state.navigation.selectedVlanId;

// preferences
export const preferencesState = (state: RootState) => state?.preferences;

export const currentUserState: Getter<string> = (state) => state?.preferences?.currentUser;

export const getTimespan = (state: RootState) => state?.preferences.timespan;

export const getClientListTimespan: Getter<TimeSpan> = (state: RootState) =>
  state?.timespans.clientsList ?? TWO_HOURS.value;

export const getWirelessHubTimespan: Getter<TimeSpan> = (state: RootState) =>
  state?.timespans.wirelessHub ?? TWO_HOURS.value;

export const getIsSamlUser: Getter<boolean> = (state) => state?.preferences.isSamlUser;

export const getSsoSubdomain: Getter<string | undefined> = (state) =>
  state?.preferences.ssoSubdomain;

// onboarding
export const getOnboardingState = (state: RootState) => state?.onboarding ?? EMPTY_OBJECT;

// ssids
export const ssidsState = (state: RootState) => state.ssids;

// systemsManager
export const smState = (state: RootState) => state?.systemsManager?.devices;

export const getSmRequestStatus: Getter<RequestStatus> = (state) => state?.systemsManager?.status;

// umbrella
export const getUmbrellaState = (state: RootState) => state.umbrella;

export const getSecurityEvents = (state: RootState) => getUmbrellaState(state)?.activity ?? [];

export const getTotalBlockedRequests = (state: RootState) =>
  getUmbrellaState(state)?.totalBlockedRequests ?? 0;

export const getEventCountsByIp = (state: RootState) =>
  getUmbrellaState(state)?.eventCountsByIp ?? {};

export const getTopDestinationsByIp = (state: RootState) =>
  getUmbrellaState(state)?.topDestinationsByIp ?? {};

export const getActivitiesByIp = (state: RootState) =>
  getUmbrellaState(state)?.activitiesByIp ?? {};

export const getUmbrellaProtection: Getter<UmbrellaProtection | undefined> = (state) =>
  getUmbrellaState(state)?.protection;

export const getUmbrellaProtectionStatus = (state: RootState) =>
  getUmbrellaState(state)?.protectionStatus;

// uplink
export const getUplinkState = (state: RootState) => state.uplink;

// loading state
const loadingState = (state: RootState) => state.loading;

export const networkUseblocksLoadingState = (state: RootState) =>
  loadingState(state).networkUseblocksFetching;

export const clientsLoadingState = (state: RootState) => loadingState(state).clientsFetching;

export const ssidUseblocksLoadingState = (state: RootState) =>
  loadingState(state).ssidUseblocksFetching;

export const applicationUseblocksLoadingState = (state: RootState) =>
  loadingState(state).applicationUseblocksFetching;

export const getFetchingNodes: Getter<boolean> = (state) => loadingState(state).fetchingNodes;

// networkUseblocks
export const networkUseblocksState = (state: RootState) => state.networkUseblocks;

// nfos
export const getNFOsState = (state: RootState) => state.nfos;

// ssid useblocks
export const ssidUseblocks = (state: RootState) => state.ssidUseblocks;

// warm spare
export const getWarmSpareSettingsState = (state: RootState) => state.warmSpareSettings;

// whatsNew
export const getWhatsNewState = (state: RootState) => state.whatsNew;

export const hasUpdatedWhatsNew = (state: RootState) => getWhatsNewState(state).updated;

// userData
export const getUserData: Getter<UserDataState> = (state) => state.userData;

export const getAlphaTappableChartsFeature = (state: RootState) =>
  getUserData(state).alphaFeatures?.tappableCharts || false;

export const getAlphaMagneticThemeFeature = (state: RootState) =>
  getUserData(state).alphaFeatures?.magneticTheme || false;

export const getThumbnailScrubbingFeature = (state: RootState) =>
  getUserData(state).alphaFeatures?.thumbnailScrubbing || false;

export const getTemperatureUnit = (state: RootState) =>
  getUserData(state).temperature_unit ?? TemperatureUnits.fahrenheit;

export const getUserId: Getter<string | undefined> = (state) => getUserData(state)?.id;

export const getUserEmail: Getter<string | undefined> = (state) => getUserData(state)?.email;

export const isCameraOnlyAdmin: Getter<boolean> = (state) =>
  getUserData(state)?.networkAccessRoles?.is_camera_only_admin ?? false;

export const isSensorOnlyAdmin: Getter<boolean> = (state) =>
  getUserData(state)?.networkAccessRoles?.is_sensor_only_admin ?? false;

export const isReadOnlySensorAdmin: Getter<boolean> = (state) =>
  (!getUserData(state)?.networkAccessRoles?.can_write ?? false) &&
  (getUserData(state)?.networkAccessRoles?.is_sensor_only_admin ?? false);

export const isUserReadOnly: Getter<boolean> = (state) => getUserData(state)?.isReadOnly ?? false;

export const getIsMerakiSSOAdmin: Getter<boolean> = (state) =>
  getUserData(state)?.is_meraki_sso_admin ?? false;

export const getIsReadOnlyUser: Getter<boolean> = (state) =>
  !getUserData(state)?.networkAccessRoles?.can_write ?? true;

export const getSSOAccessOrg: Getter<GoOrgSearchResult | undefined> = (state) =>
  getUserData(state)?.ssoAccessOrg;

// sensors
const emptySensors = {};
export const getSensors = (state: RootState) => state?.sensors ?? emptySensors;

// sensorStats
const emptySensorStats = {};
export const getAllSensorStats = (state: RootState) => state?.sensorStats ?? emptySensorStats;

export const getIsFetchingLatestWaterDetectionStat = (state: RootState) =>
  getAllSensorStats(state).isFetchingLatestWaterDetectionStat;

// sensorEvents
const emptySensorEvents = {};
export const getAllSensorEvents = (state: RootState) => state?.sensorEvents ?? emptySensorEvents;

export const getIsFetchingWaterRelatedEvents = (state: RootState) =>
  getAllSensorEvents(state).isFetchingWaterRelatedEvents;

export const getIsFetchingLastWaterDetected = (state: RootState) =>
  getAllSensorEvents(state).isFetchingLastWaterDetected;

// sensorRoles
export const getSensorRoles = (state: RootState) => state.sensorRoles;

// sensor alert profiles
export const getSensorAlertProfilesState = (state: RootState) => state.sensorAlertProfiles;

// admins
export const isFetchingAdmins = (state: RootState) => state.admins.isFetching;

export const adminsState: Getter<AdminsItems> = (state: RootState) => state.admins.items;

export const authorizationsState: Getter<AuthorizationsByEmail> = (state: RootState) =>
  state.admins.authorizations;

// gxPorts
export const getGxPorts = (state: RootState) => state.gxPorts;

// switchPorts
export const getSwitchPorts: Getter<SwitchPortState> = (state) =>
  // TODO: remove type casting when switchPort reducer is coverted to typescript
  (state?.switchPorts as SwitchPortState) ?? {};

// floorPlans
export const getFloorPlansState = (state: RootState) => state?.floorPlans;

// mspOrgData
export const getMSPOrgData = (state: RootState) => state.mspOrgData;

// nodeGroups
export const getNodeGroups: Getter<NodeGroupsState> = (state) => state.nodeGroups;

// registration
export const getVerifiedEmail = (state: RootState) => state.registration.verifiedEmail;

// cameras
export const getWirelessProfiles: Getter<WirelessProfile[]> = (state) =>
  state.cameras.wirelessProfiles;

export const getVideoSettingMenuVisibility: Getter<boolean> = (state) =>
  state.cameras.videoSettings.showMenu;

export const getCurrentVideoSettingPageId: Getter<SettingPageId> = (state) =>
  state.cameras.videoSettings.currentVideoSettingPageId;

export const getPlaybackRateId: Getter<PlaybackRateId> = (state) =>
  state.cameras.videoSettings.rateId;

export const getPlaybackRate: Getter<number> = (state) =>
  PLAYBACK_RATES[getPlaybackRateId(state)].rate;

export const getVideoStatus: Getter<VideoStatus> = (state) => state.cameras.videoStatus;

// deviceOnboarding
export const getOnboardingDeviceDetails = (state: RootState) =>
  state.deviceOnboarding.deviceDetails ?? {};

export const getIsDeviceOnboardingContinued = (state: RootState) =>
  state.deviceOnboarding.isDeviceOnboardingContinued;

export const getIsWirelessConfigLaunchedFromAddDevice: Getter<boolean> = (state) =>
  state.deviceOnboarding.isWirelessConfigLaunchedFromAddFlow;

// remoteConfig
export const getRemoteConfig: Getter<Record<keyof RemoteConfigParams, FormattedConfig>> = (state) =>
  state?.remoteConfig;

// locationAnalytics
export const getLocationAnalyticsState = (state: RootState) => state.locationAnalytics;

// api
export const getClientsOverview: Getter<ClientsOverview> = (state) => state.api.clientsOverview;

export const getOrgNetworksState: Getter<Network[]> = (state) => state?.api?.orgNetworks;

export const getOrgsState: Getter<Organization[]> = (state) => state?.api?.orgs;

export const getVpns: Getter<{ [networkId: string]: Vpn }> = (state) => state.vpns?.vpns;
export const getPeerStatuses: Getter<EidKeyedData<Peer>> = (state) => state.vpns?.peerStatus;
export const getVpnUsage: Getter<{ [key: string]: Usage[] }> = (state) => state.vpns?.usageData;
export const getVpnOutageData: Getter<EidKeyedData<OutageData>> = (state) => state.vpns?.outageData;
export const getIpsecSettingsByNetworkId: Getter<{ [key: string]: IpsecSettings }> = (state) =>
  state.vpns?.ipsecSettings;
export const getSiteToSiteSettingsByNetworkId: Getter<{ [key: string]: SiteToSiteSettings }> = (
  state,
) => state.vpns?.siteToSiteSettings;

export const getExportList: Getter<VideoExport[]> = (state) =>
  state?.videoExport?.fetchAllExports?.exportList;
export const getIsExportDataFetching: Getter<boolean> = (state) =>
  state?.videoExport?.fetchAllExports?.isInitialFetch;
export const getChannels: Getter<{ [node_id: string]: VideoChannel }> = (state) =>
  state?.cameras?.channels;
export const getExportTriggered: Getter<string> = (state) =>
  state?.videoExport?.initiateExport?.exportTriggered;
export const getExportFailureMessage: Getter<string | undefined> = (state) =>
  state?.videoExport?.initiateExport?.failureMessage;

// speed test
export const getSpeedTestAgreement: Getter<boolean> = (state) =>
  state?.speedTest?.agreement ?? false;

// support cases
export const getUserSupportCases: Getter<SupportCaseState> = (state) => state?.supportCases;
