import { I18n } from "@meraki/core/i18n";
import { Ssid } from "@meraki/shared/api";
import { get, isEmpty, last } from "lodash";

import { MAX_SSIDS, SECONDS_IN_A_DAY, SECONDS_IN_AN_HOUR } from "~/constants/MkiConstants";
import { convert24HourTo12Hour } from "~/lib/formatHelper";
import NetworkUtils from "~/lib/NetworkUtils";
import { nestedValueExists } from "~/lib/objectHelper";
import { SSID } from "~/shared/types/Models";

const MAX_SSID_NAME_LENGTH = 32;
const TWELVE_AM = "12:00 am";
const MAX_END_TIME = SECONDS_IN_A_DAY * 7;
const SECONDS_TO_DAY_OF_WEEK = {
  sunday: 0,
  monday: SECONDS_IN_A_DAY * 1,
  tuesday: SECONDS_IN_A_DAY * 2,
  wednesday: SECONDS_IN_A_DAY * 3,
  thursday: SECONDS_IN_A_DAY * 4,
  friday: SECONDS_IN_A_DAY * 5,
  saturday: SECONDS_IN_A_DAY * 6,
};

const IPSK_SUPPORTED_MODELS = ["GR12", "GR62", "MR36H"];

const SECONDS_TO_HOUR_OF_DAY = {
  "12:00 am": SECONDS_IN_AN_HOUR * 0,
  "1:00 am": SECONDS_IN_AN_HOUR * 1,
  "2:00 am": SECONDS_IN_AN_HOUR * 2,
  "3:00 am": SECONDS_IN_AN_HOUR * 3,
  "4:00 am": SECONDS_IN_AN_HOUR * 4,
  "5:00 am": SECONDS_IN_AN_HOUR * 5,
  "6:00 am": SECONDS_IN_AN_HOUR * 6,
  "7:00 am": SECONDS_IN_AN_HOUR * 7,
  "8:00 am": SECONDS_IN_AN_HOUR * 8,
  "9:00 am": SECONDS_IN_AN_HOUR * 9,
  "10:00 am": SECONDS_IN_AN_HOUR * 10,
  "11:00 am": SECONDS_IN_AN_HOUR * 11,
  "12:00 pm": SECONDS_IN_AN_HOUR * 12,
  "1:00 pm": SECONDS_IN_AN_HOUR * 13,
  "2:00 pm": SECONDS_IN_AN_HOUR * 14,
  "3:00 pm": SECONDS_IN_AN_HOUR * 15,
  "4:00 pm": SECONDS_IN_AN_HOUR * 16,
  "5:00 pm": SECONDS_IN_AN_HOUR * 17,
  "6:00 pm": SECONDS_IN_AN_HOUR * 18,
  "7:00 pm": SECONDS_IN_AN_HOUR * 19,
  "8:00 pm": SECONDS_IN_AN_HOUR * 20,
  "9:00 pm": SECONDS_IN_AN_HOUR * 21,
  "10:00 pm": SECONDS_IN_AN_HOUR * 22,
  "11:00 pm": SECONDS_IN_AN_HOUR * 23,
};

const dayTimeToSeconds = (day: any, time: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const dayTime = SECONDS_TO_DAY_OF_WEEK[day];
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const hourTime = SECONDS_TO_HOUR_OF_DAY[time.replace(/\:(.*?)\ /g, ":00 ").toLowerCase()];
  const minuteTime = 60 * parseInt(time.split(":")[1].split(" ")[0]);
  return dayTime + hourTime + minuteTime;
};

const daysBetween = (startDay: any, endDay: any) => {
  const returnedDays = new Set();

  if (startDay === endDay) {
    return [startDay];
  }

  if (startDay === null) {
    return [];
  }

  if (endDay === null) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const startTime = SECONDS_TO_DAY_OF_WEEK[startDay];

    return Object.keys(SECONDS_TO_DAY_OF_WEEK).filter(
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (day) => SECONDS_TO_DAY_OF_WEEK[day] >= startTime,
    );
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const startTime = SECONDS_TO_DAY_OF_WEEK[startDay];
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const endTime = SECONDS_TO_DAY_OF_WEEK[endDay];

  returnedDays.add(startDay);
  returnedDays.add(endDay);

  for (const day in SECONDS_TO_DAY_OF_WEEK) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const dayTime = SECONDS_TO_DAY_OF_WEEK[day];
    if (dayTime >= startTime && dayTime <= endTime) {
      returnedDays.add(day);
    }
  }

  return [...returnedDays];
};

const isOutagesOverlap = (outage1: any, outage2: any) =>
  outage1.end >= outage2.start && outage1.start <= outage2.end;

const mergeOutages = (outages: Outages[]) => {
  const sortedOutages = outages.sort((outage1, outage2) => {
    return outage1.start !== outage2.start
      ? outage1.start - outage2.start
      : outage1.end - outage2.end;
  });
  const returnedOutages: Outages[] = [];
  for (const outage of sortedOutages) {
    if (isEmpty(returnedOutages)) {
      returnedOutages.push(outage);
      continue;
    }
    const previousOutage = last(returnedOutages)!;
    if (isOutagesOverlap(previousOutage, outage)) {
      returnedOutages.pop();
      returnedOutages.push({
        start: previousOutage.start,
        end: outage.end,
      });
    } else {
      returnedOutages.push(outage);
    }
  }
  return returnedOutages;
};

const removeMaxTime = (outages: Outages[]) => {
  const returnedOutages: Outages[] = [];
  for (const outage of outages) {
    const newOutage: Outages = {
      start: outage.start,
      end: outage.end,
    };
    if (outage.end === MAX_END_TIME) {
      newOutage.end = MAX_END_TIME - 1;
    }
    returnedOutages.push(newOutage);
  }
  return returnedOutages;
};

export function isUnconfiguredSSID(ssid: any) {
  const { name, number } = ssid;
  return name === `Unconfigured SSID ${number + 1}`;
}

export function nextSSID(current: any, offset = 1) {
  let newNum = current + offset;
  if (newNum > MAX_SSIDS) {
    newNum = 0;
  } else if (newNum < 0) {
    newNum = MAX_SSIDS;
  }

  return newNum;
}

export function getSSIDtoAdd(ssids: any) {
  return ssids.slice(0, MAX_SSIDS).findIndex((s: any) => isUnconfiguredSSID(s));
}

export function getNumberAvailableSSIDs(ssids: Ssid[]) {
  return ssids.slice(0, MAX_SSIDS).filter((s) => isUnconfiguredSSID(s)).length;
}

export function getSsidNameFromNum(ssidNum: number, ssids: Ssid[]) {
  return ssids.find((s) => s != undefined && s.number === ssidNum)?.name ?? "";
}

export function generateSSIDName(orgName: any) {
  const nameVariations = [
    `${orgName} ${I18n.t("SSID_CONFIGURATION.NAMES.MERAKI")} ${I18n.t(
      "SSID_CONFIGURATION.NAMES.GO",
    )} ${I18n.t("SSID_CONFIGURATION.NAMES.WIFI")}`,
    `${orgName} ${I18n.t("SSID_CONFIGURATION.NAMES.GO")} ${I18n.t(
      "SSID_CONFIGURATION.NAMES.WIFI",
    )}`,
    `${orgName} ${I18n.t("SSID_CONFIGURATION.NAMES.WIFI")}`,
    `${orgName} ${I18n.t("SSID_CONFIGURATION.NAMES.GO")}`,
    orgName,
    `${I18n.t("SSID_CONFIGURATION.NAMES.MERAKI")} ${I18n.t("SSID_CONFIGURATION.NAMES.GO")} ${I18n.t(
      "SSID_CONFIGURATION.NAMES.WIFI",
    )}`,
  ];
  let name;
  for (let i = 0; i < nameVariations.length; i += 1) {
    name = nameVariations[i];
    if (name.length <= MAX_SSID_NAME_LENGTH) {
      break;
    }
  }
  return name;
}

type Schedule = {
  enabled: boolean;
  rangesInSeconds: number;
  ranges: {
    startTime: string;
    endTime: string;
    startDay: string;
    endDay: string;
  }[];
};

export function convertSSIDSchedule(schedule: Schedule) {
  const convertedSchedule: Schedule["ranges"] = [];
  if (!schedule) {
    return undefined;
  }
  const ranges = schedule.ranges ?? [];
  for (const range of ranges) {
    const { startTime, endTime, startDay, endDay } = range;
    const timeframe = {
      startTime: convert24HourTo12Hour(startTime),
      endTime: convert24HourTo12Hour(endTime),
      startDay: startDay ? startDay.toLowerCase() : startDay,
      endDay: endDay ? endDay.toLowerCase() : endDay,
    };
    convertedSchedule.push(timeframe);
  }
  return {
    enabled: schedule.enabled || false,
    rangesInSeconds: schedule.rangesInSeconds || [],
    ranges: convertedSchedule,
  };
}

export function formatSSIDSchedule(schedule: any) {
  const convertedSchedule: any = {};
  const offlineRangesByDay: any = {};
  if (isEmpty(schedule)) {
    return {};
  }
  for (const offlineRange of schedule.ranges) {
    const { startDay, endDay, startTime, endTime } = offlineRange;

    // Edge case where they return a zero frame object
    if (startDay === endDay && startTime === endTime) {
      continue;
    }
    const offlineDays = daysBetween(startDay, endDay);

    // If there's only one day in this range, it's off line between start and end
    if (offlineDays.length === 1) {
      offlineRangesByDay[startDay] = [
        ...(offlineRangesByDay[startDay] ? offlineRangesByDay[startDay] : []),
        [startTime, endTime],
      ];
      continue;
    }
    for (const offlineDay of offlineDays) {
      if (!(offlineDay in offlineRangesByDay)) {
        offlineRangesByDay[offlineDay] = [];
      }

      if (offlineDay === startDay) {
        offlineRangesByDay[offlineDay].push([startTime, TWELVE_AM]);
      } else if (offlineDay === endDay) {
        if (endTime.toLowerCase() === TWELVE_AM) {
          if (!offlineRangesByDay[offlineDay].length) {
            delete offlineRangesByDay[offlineDay];
          } else {
            continue;
          }
        } else {
          offlineRangesByDay[offlineDay].push([TWELVE_AM, endTime]);
        }
      } else {
        offlineRangesByDay[offlineDay].push([TWELVE_AM, TWELVE_AM]);
      }
    }
  }

  for (const day in SECONDS_TO_DAY_OF_WEEK) {
    let scheduleInfo = {};
    if (!(day in offlineRangesByDay)) {
      scheduleInfo = {
        isActive: true,
        from: TWELVE_AM,
        to: TWELVE_AM,
      };
    } else {
      const offlineRanges = offlineRangesByDay[day];

      if (offlineRanges.length === 1) {
        scheduleInfo = {
          isActive: false,
          from: offlineRanges[0][0],
          to: offlineRanges[0][1],
        };
      } else if (offlineRanges.length === 2) {
        scheduleInfo = {
          isActive: true,
          from: offlineRanges[0][1],
          to: offlineRanges[1][0],
        };
      }
    }
    convertedSchedule[day] = scheduleInfo;
  }

  return convertedSchedule;
}

type Outages = { start: number; end: number };

export const formatScheduleToOutagesInSeconds = (schedule: any) => {
  const outages: Outages[] = [];
  for (const day in schedule) {
    const currentOutages: Outages[] = [];
    const { isActive, from, to } = schedule[day];
    if (!isActive) {
      if (from === to) {
        currentOutages.push({
          start: dayTimeToSeconds(day, TWELVE_AM),
          end: dayTimeToSeconds(day, TWELVE_AM) + SECONDS_IN_A_DAY,
        });
      } else if (to.toLowerCase() === TWELVE_AM) {
        currentOutages.push({
          start: dayTimeToSeconds(day, from),
          end: dayTimeToSeconds(day, TWELVE_AM) + SECONDS_IN_A_DAY,
        });
      } else {
        currentOutages.push({
          start: dayTimeToSeconds(day, from),
          end: dayTimeToSeconds(day, to),
        });
      }
    } else {
      if (from === to) {
        continue;
      }
      currentOutages.push(
        {
          start: dayTimeToSeconds(day, TWELVE_AM),
          end: dayTimeToSeconds(day, from),
        },
        {
          start: dayTimeToSeconds(day, to),
          end: dayTimeToSeconds(day, TWELVE_AM) + SECONDS_IN_A_DAY,
        },
      );
    }
    outages.push(...removeMaxTime(currentOutages));
  }

  return mergeOutages(outages);
};

export function validateName(name: any, otherSsids: SSID[] = []) {
  if (!name) {
    return I18n.t("SSID_NAME_ERRORS.NAME_EMPTY");
  }
  if (name.length > MAX_SSID_NAME_LENGTH) {
    return I18n.t("SSID_NAME_ERRORS.NAME_LENGTH");
  }
  const otherNames = otherSsids.map((ssid) => ssid.name);
  if (otherNames.length > 0 && otherNames.includes(name)) {
    return I18n.t("SSID_NAME_ERRORS.NAME_UNIQUE");
  }
  return null;
}

export function validatePSK(psk: any) {
  if (!psk) {
    return I18n.t("PSK_ERRORS.PSK_EMPTY");
  }
  if (psk.length < 8 || psk.length > 63) {
    return I18n.t("PSK_ERRORS.PSK_LENGTH");
  }
  if (psk.length === 8 && psk.endsWith(" ")) {
    return I18n.t("PSK_ERRORS.PSK_END_SPACE");
  }
  return null;
}

export function validateVlanId(vlanId: any) {
  const parsedId = Number.parseInt(vlanId);
  if (!Number.isInteger(parsedId) || parsedId < 1 || parsedId > 4096) {
    return I18n.t("VLAN_TAGGING.VLAN_ID.INVALID");
  }

  return null;
}

export function getSsidNamesForBlockedMac(ssids: any, mac: any) {
  return (
    ssids
      .filter((ssid: any) => nestedValueExists(ssid, ["blockedMacs"], []).includes(mac))
      .map((ssid: any) => ssid.name) || []
  );
}

export const getPolicyAffectedNetworkNames = (ssids: any, appliance: any, policyInfo: any) => {
  const names: string[] = [];
  if (appliance === undefined) {
    return names;
  }
  if (appliance && policyInfo.wired) {
    names.push(appliance.name || appliance.serial);
  }

  for (const ssid of ssids) {
    const ssidNum = ssid.number;
    if (policyInfo.ssids[ssidNum]) {
      names.push(ssid.name);
    }
  }
  return names;
};

export function getSSIDNameByNodeGroupId(ssidsByNumber: any, nodeGroupId: any) {
  return get(ssidsByNumber, [nodeGroupId, "name"]);
}

export function hasDuplicateDomain(url: any, ssidNumber: any, ssidfirewallRules: any) {
  const hosts = new Set(
    ssidfirewallRules[ssidNumber].map((item: any) => NetworkUtils.getDomainFromUrl(item.value)),
  );
  return hosts.has(NetworkUtils.getDomainFromUrl(url));
}

// These are the values returned by the API
export const IP_ASSIGNMENT = {
  NAT: "NAT mode",
  BRIDGE: "Bridge mode",
};

// These are the values returned by the API
export const WPA_ENCRYPTION_MODE = {
  WPA2: "WPA2 only",
  WPA2_3: "WPA3 Transition Mode",
  WPA3: "WPA3 only",
};

// These are the required parameters for users to configure WPA3
export const WPA3_ALLOWED = {
  AUTH_MODE: "psk",
  ENCRYPTION_MODE: "wpa",
};

// private IP ranges are 10.0.0.0/8, 172.16.0.0/12, and 192.168.0.0/16
// if a node's IP address is a private IP, then we know there must be an
// upstream NAT modem or router
export function hasNATRouter(device: any) {
  const ipAddr = device.lanIp || device.ip;
  if (!ipAddr) {
    return false;
  }
  const ipBytes = ipAddr.split(".").map((str: any) => Number(str));

  if (ipBytes[0] === 10) {
    return true;
  }
  if (ipBytes[0] === 192 && ipBytes[1] === 168) {
    return true;
  }
  if (ipBytes[0] === 172 && ipBytes[1] >= 16 && ipBytes[1] < 32) {
    return true;
  }
  return false;
}

export function supportsIpsk(accessPoints: any) {
  if (accessPoints && accessPoints.length == 0) {
    return false;
  }
  let isValid = true;

  accessPoints.forEach((accessPoint: any) => {
    if (!IPSK_SUPPORTED_MODELS.includes(accessPoint.model)) {
      isValid = false;
    }
  });
  return isValid;
}
