import Svg, { Path } from "react-native-svg";

import { BUTTON_SIZING } from "~/constants/MkiConstants";

type Props = {
  size?: number;
};

function FullScreenIcon(props: Props) {
  const { size = BUTTON_SIZING.width.narrow } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M15.75 4.5011H19.5V8.2511"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.24854 19.5H4.49854V15.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.5 15.75V19.5H15.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.49854 8.2489V4.4989H8.24854"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
export default FullScreenIcon;
