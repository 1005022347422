import { I18n } from "@meraki/core/i18n";
import { communityUrl, documentationUrl } from "@meraki/go/links";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import { FONT_SIZES } from "~/enterprise/constants/Fonts";
import SettingsAlphaFeatures from "~/enterprise/screens/SettingsAlphaFeatures";
import RoundedButton, { ButtonType } from "~/go/components/RoundedButton";
import { BioAuthTypes, getBioAuthType } from "~/lib/BioAuth";
import { isGX50 } from "~/lib/DeviceUtils";
import { analytics } from "~/lib/FirebaseModules";
import { FIREBASE_EVENTS } from "~/lib/FirebaseUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { isWeb, platformSelect } from "~/lib/PlatformUtils";
import { normalizedFontSize } from "~/lib/themeHelper";
import {
  currentOrganization,
  getOrgCount,
  gxDeviceSelector,
  hasGRDevices,
  hasGXDevices,
  isUmbrellaAccountReadySelector,
} from "~/selectors";
import { MkiText } from "~/shared/components/MkiText";
import SummaryList from "~/shared/components/SummaryList";
import useActions from "~/shared/hooks/redux/useActions";
import useAppSelector from "~/shared/hooks/redux/useAppSelector";
import { useHasAlphaFeatures } from "~/shared/hooks/useNFOs";
import { CUSTOM_FILTERS } from "~/shared/lib/Filters";
import DropDownRow from "~/shared/rows/DropDownRow";
import {
  OnboardingFlows,
  OnboardingNodeStatus,
  OnboardingStage,
} from "~/shared/types/OnboardingTypes";

import { SettingsStackProps } from "../navigation/Types";

type Props = ForwardedNativeStackScreenProps<SettingsStackProps, "Settings">;

const SettingsScreen = () => {
  const navigation = useNavigation<Props["navigation"]>();
  const actions = useActions();

  const currentOrg = useAppSelector(currentOrganization);
  const hasGR = useAppSelector(hasGRDevices);
  const hasGX = useAppSelector(hasGXDevices);
  const securityAppliance = useAppSelector(gxDeviceSelector);
  const isUmbrellaAccountReady = useAppSelector(isUmbrellaAccountReadySelector);
  const numOfOrganizations = useAppSelector(getOrgCount);
  const hasAlphaFeatures = useHasAlphaFeatures();

  const [bioAuthType, setBioAuthType] = useState<BioAuthTypes | undefined>();

  async function loadBioAuthType() {
    try {
      setBioAuthType(await getBioAuthType());
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    loadBioAuthType();
  }, []);

  const showDocumentation = () => {
    analytics.logEvent(FIREBASE_EVENTS.openedDocumentationFromSettings);
    documentationUrl();
  };

  const showCommunity = () => {
    analytics.logEvent(FIREBASE_EVENTS.openedCommunityFromSettings);
    communityUrl();
  };

  const pushAlertsScreen = () => {
    navigation.navigate("Alerts");
  };

  const launchOnboarding = () => {
    actions.setOnboardingStage(OnboardingStage.getStarted);
    actions.setOnboardingFlow(OnboardingFlows.mainOnboarding);
    actions.setOnboardingStatus(OnboardingNodeStatus.unstarted);
    actions.setOnboardingSkipped(false);
    actions.setOnboardingIsForced(true);
    actions.setOnboardingNodes({});
    actions.setOnboardingIsComplete(false);
  };

  const supportVPN = isGX50(securityAppliance);

  const featureRows = [
    {
      label: I18n.t("WHATS_NEW.TITLE"),
      subtitle: I18n.t("WHATS_NEW.HIGHLIGHTED_FEATUERS"),
      onPress: () => navigation.navigate("WhatsNewList"),
      testID: "SETTINGS.WHATS_NEW",
    },
    {
      label: I18n.t("SETTINGS.FEATURE.DOCS.TITLE"),
      subtitle: I18n.t("SETTINGS.FEATURE.DOCS.SUBTITLE"),
      onPress: showDocumentation,
      testID: "SETTINGS.DOCS",
    },
  ];

  const securityRows = [
    {
      label: I18n.t("SETTINGS.SECURITY.TITLE"),
      subtitle: I18n.t("SETTINGS.SECURITY.SUBTITLE"),
      onPress: () => navigation.navigate("UmbrellaSettings"),
      testID: "SETTINGS.SECURITY",
    },
  ];

  const accessRows = [
    {
      label: I18n.t("SETTINGS.ACCESS.LOCATION_ANALYTICS.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCESS.LOCATION_ANALYTICS.SUBTITLE"),
      onPress: () => navigation.navigate("ToggleLocationAnalytics"),
      testID: "SETTINGS.LOCATION_ANALYTICS",
    },
    {
      label: I18n.t("SETTINGS.ACCESS.BLOCKED_CLIENTS.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCESS.BLOCKED_CLIENTS.SUBTITLE"),
      onPress: () =>
        navigation.navigate(
          "ClientList",
          /* TODO: UDG-2239
          It seems how we pass in filters prevents this screen from being updated
          when blocked clients on ssids are updated.
          UDG-2239 should address this bug.
        */
          {
            title: I18n.t("SETTINGS.ACCESS.BLOCKED_CLIENTS.TITLE"),
            blockedClients: true,
            filter: CUSTOM_FILTERS.BLOCKED_CLIENT,
          },
        ),
      testID: "SETTINGS.BLOCKED_CLIENTS",
    },
    /*
    {
      label: "Internet service provider",
      subtitle: "Ex. Comcast, Verizon, AT&T",
      onPress: () => {},
    },
    */
  ];

  const alertsRows = [
    {
      label: I18n.t("SETTINGS.ALERTS.ALERTS_HISTORY.TITLE"),
      subtitle: I18n.t("SETTINGS.ALERTS.ALERTS_HISTORY.SUBTITLE"),
      onPress: pushAlertsScreen,
      testID: "SETTINGS.ALERTS_HISTORY",
    },
  ];

  const usageRows = [
    {
      label: I18n.t("SETTINGS.USAGE.BLOCKING.TITLE"),
      subtitle: I18n.t("SETTINGS.USAGE.BLOCKING.SUBTITLE"),
      onPress: () => navigation.navigate("BlockContent", {}),
      testID: "SETTINGS.WEB_BLOCKING",
    },
    {
      label: I18n.t("SETTINGS.USAGE.LIMITS.TITLE"),
      subtitle: I18n.t("SETTINGS.USAGE.LIMITS.SUBTITLE"),
      onPress: () => navigation.navigate("SetUsage", {}),
      testID: "SETTINGS.USAGE_LIMITS",
    },
    {
      label: I18n.t("SETTINGS.USAGE.SWITCH_QOS.TITLE"),
      subtitle: I18n.t("SETTINGS.USAGE.SWITCH_QOS.SUBTITLE"),
      onPress: () => navigation.navigate("SwitchQoSRulesList"),
      testID: "SETTINGS.SWITCH_QOS_RULES",
    },
    {
      label: I18n.t("SETTINGS.USAGE.SPEED_TEST.TITLE"),
      subtitle: I18n.t("SETTINGS.USAGE.SPEED_TEST.SUBTITLE"),
      onPress: () => navigation.navigate("SpeedTest"),
      testID: "SETTINGS.SPEED_TEST",
    },
    /*
    {
      label: "Optimize hardware products",
      subtitle: "Choose the right settings for your devices",
      onPress: () => {},
    },
    */
  ];

  let bioSubtitle = "";
  if (bioAuthType != null && Object.values(BioAuthTypes).includes(bioAuthType)) {
    bioSubtitle = platformSelect({
      ios:
        bioAuthType === BioAuthTypes.faceId
          ? I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.FACE_ID")
          : I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.TOUCH_ID"),
      android: I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.SUBTITLE.ANDROID_AUTH"),
    });
  }
  const yourAccountSubtitle =
    bioSubtitle.length > 0
      ? `${bioSubtitle}, ${I18n.t("ACCOUNT.SET_TIME_ZONE")}, ${I18n.t(
          "ACCOUNT.SET_LANGUAGE",
        )}, ${I18n.t("ACCOUNT.CHANGE_ORG_NAME")}, ${I18n.t("LOG_OUT")}`
      : capitalizeFirstLetter(
          `${I18n.t("ACCOUNT.SET_TIME_ZONE")}, ${I18n.t("ACCOUNT.SET_LANGUAGE")}, ${I18n.t(
            "ACCOUNT.CHANGE_ORG_NAME",
          )}, ${I18n.t("LOG_OUT")}`,
        );
  const accountRows = [
    {
      label: I18n.t("SETTINGS.ACCOUNT.YOUR_ACCOUNT.TITLE"),
      subtitle: yourAccountSubtitle,
      onPress: () => navigation.navigate("Account"),
      testID: "SETTINGS.YOUR_ACCOUNT",
    },
    {
      label: I18n.t("SETTINGS.ADMIN.TITLE"),
      subtitle: I18n.t("SETTINGS.ADMIN.SUBTITLE"),
      onPress: () => navigation.navigate("AdminAndAuthUser", { isFromClientVPN: false }),
      testID: "SETTINGS.ADMIN",
    },
    {
      label: I18n.t("SETTINGS.ACCOUNT.TWO_FACTOR_AUTH.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCOUNT.TWO_FACTOR_AUTH.SUBTITLE"),
      onPress: () => navigation.navigate("IntroTwoFactor", { isOnboarding: false }),
      testID: "SETTINGS.TWO_FACTOR_AUTH",
    },
    {
      label: I18n.t("SETTINGS.ACCOUNT.CREATE_SITE.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCOUNT.CREATE_SITE.SUBTITLE"),
      onPress: () => navigation.navigate("CreateNetwork"),
      testID: "SETTINGS.CREATE_NETWORK",
    },
    {
      label: I18n.t("SETTINGS.ACCOUNT.CHOOSE_NETWORK.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCOUNT.CHOOSE_NETWORK.SUBTITLE"),
      onPress: () => navigation.navigate("NetworkChoose"),
      testID: "SETTINGS.CHOOSE_NETWORK",
    },
    {
      label: I18n.t("SETTINGS.ACCOUNT.DELETE_LOCATION.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCOUNT.DELETE_LOCATION.SUBTITLE"),
      onPress: () => navigation.navigate("OrgDelete"),
      testID: "SETTINGS.DELETE_LOCATION",
    },
  ];

  if (numOfOrganizations != null && numOfOrganizations > 1) {
    accountRows.push({
      label: I18n.t("SETTINGS.ADVANCED.CHANGE_ORG"),
      subtitle: currentOrg?.name,
      onPress: () => navigation.navigate("OrgChoose", { showLocationTitle: true }),
      testID: "SETTINGS.CHANGE_ORG",
    });
  }

  const helpRows = [
    {
      label: (
        <View style={styles.horizontal}>
          <MkiText>{I18n.t("SETTINGS.ADVANCED.SUPPORT_CASES.TITLE")}</MkiText>
          <RoundedButton
            buttonType={ButtonType.primary}
            onPress={() => navigation.navigate("SearchSubject")}
            screenStyles={styles.openCaseTag}
            testID="OPEN_CASE_BUTTON"
            fontSize={FONT_SIZES.default}
          >
            {I18n.t("SUPPORT_CENTER.OPEN_CASE_BUTTON")}
          </RoundedButton>
        </View>
      ),
      subtitle: I18n.t("SETTINGS.ADVANCED.SUPPORT_CASES.SUBTITLE"),
      onPress: () => navigation.navigate("SupportCasesList"),
      testID: "SETTINGS.SUPPORT_CENTER",
    },
    {
      label: I18n.t("SETTINGS.HELP.COMMUNITY.TITLE"),
      subtitle: I18n.t("SETTINGS.HELP.COMMUNITY.SUBTITLE"),
      onPress: showCommunity,
      testID: "SETTINGS.COMMUNITY",
    },
    {
      label: I18n.t("SETTINGS.HELP.WISH.TITLE"),
      subtitle: I18n.t("SETTINGS.HELP.WISH.SUBTITLE"),
      onPress: () => navigation.navigate("Wish"),
      testID: "SETTINGS.WISH",
    },
  ];

  const generalRows = [
    {
      label: I18n.t("SETTINGS.GENERAL.ABOUT.TITLE"),
      subtitle: I18n.t("SETTINGS.GENERAL.ABOUT.SUBTITLE"),
      onPress: () => navigation.navigate("About"),
      testID: "SETTINGS.ABOUT",
    },
    {
      label: I18n.t("SETTINGS.GENERAL.THEME_SELECT.TITLE"),
      subtitle: I18n.t("SETTINGS.GENERAL.THEME_SELECT.SUBTITLE"),
      onPress: () => navigation.navigate("ThemeSelect"),
      testID: "SETTINGS.THEME_SELECT",
    },
  ];

  const advancedSettingsRows = [
    {
      label: I18n.t("SETTINGS.ADVANCED.FIRMWARE_UPDATES.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.FIRMWARE_UPDATES.SUBTITLE"),
      onPress: () => navigation.navigate("FirmwareUpgrades"),
      testID: "SETTINGS.FIRMWARE_UPGRADE",
    },
    {
      label: I18n.t("SETTINGS.ADVANCED.LSP_PASSWORD.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.LSP_PASSWORD.SUBTITLE"),
      onPress: () => navigation.navigate("LSPPassword"),
      testID: "SETTINGS.LSP_PASSWORD",
    },
  ];

  if (supportVPN) {
    advancedSettingsRows.push({
      label: I18n.t("SETTINGS.ADVANCED.CLIENT_VPN.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.CLIENT_VPN.SUBTITLE"),
      onPress: () => navigation.navigate("ClientVPNSettingsWrapper"),
      testID: "SETTINGS.CLIENT_VPN",
    });

    advancedSettingsRows.push({
      label: I18n.t("SETTINGS.ADVANCED.SITE_TO_SITE_VPN.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.SITE_TO_SITE_VPN.SUBTITLE"),
      onPress: () => navigation.navigate("SiteToSiteVPNSettingsWrapper"),
      testID: "SETTINGS.SITE_TO_SITE_VPN",
    });
  }

  if (hasGR) {
    accessRows.unshift({
      label: I18n.t("SETTINGS.ACCESS.NETWORK.TITLE"),
      subtitle: I18n.t("SETTINGS.ACCESS.NETWORK.SUBTITLE"),
      onPress: () =>
        navigation.navigate("SSIDsList", {
          detailsScreenOverride: {
            name: "SSIDConfigure",
          },
        }),
      testID: "SETTINGS.WIFI_NETWORK",
    });

    advancedSettingsRows.unshift({
      label: I18n.t("SETTINGS.ADVANCED.ADDRESS_TRANSLATION.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.ADDRESS_TRANSLATION.SUBTITLE"),
      onPress: () =>
        navigation.navigate("SSIDsList", {
          detailsScreenOverride: {
            name: "NATBridge",
            presentedModally: true,
          },
        }),
      testID: "SETTINGS.ADDRESS_TRANSLATION",
    });

    advancedSettingsRows.unshift({
      label: I18n.t("SETTINGS.ADVANCED.SITE_WIDE_WIRELESS.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.SITE_WIDE_WIRELESS.SUBTITLE"),
      onPress: () => navigation.navigate("WirelessSettings"),
      testID: "SETTINGS.SITE_WIDE_WIRELESS",
    });
  }

  if (hasGX) {
    advancedSettingsRows.push({
      label: I18n.t("SETTINGS.ADVANCED.LOCAL_ADDRESSING.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.LOCAL_ADDRESSING.SUBTITLE"),
      onPress: () => navigation.navigate("VlansList", { readOnly: true }),
      testID: "SETTINGS.LOCAL_ADDRESSING",
    });

    advancedSettingsRows.push({
      label: I18n.t("SETTINGS.ADVANCED.PORT_FORWARDING.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.PORT_FORWARDING.SUBTITLE"),
      onPress: () => navigation.navigate("PortForwarding"),
      testID: "SETTINGS.PORT_FORWARDING",
    });
  }

  if (isWeb()) {
    accountRows.push({
      label: I18n.t("SETTINGS.ADVANCED.CREATE_ORG.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.CREATE_ORG.SUBTITLE"),
      onPress: () => navigation.navigate("CreateOrganization"),
      testID: "SETTINGS.CREATE_ORG",
    });
  }

  if (hasAlphaFeatures) {
    advancedSettingsRows.push({
      label: I18n.t("SETTINGS.ADVANCED.ONBOARDING.TITLE"),
      subtitle: I18n.t("SETTINGS.ADVANCED.ONBOARDING.SUBTITLE"),
      onPress: launchOnboarding,
      testID: "SETTINGS.LAUNCH_ONBOARDING",
    });
  }

  const securitySection = isUmbrellaAccountReady ? (
    <SummaryList
      heading={I18n.t("SETTINGS.SECURITY.HEADING")}
      headingTextStyle={styles.heading}
      contentRows={securityRows}
      disableBottomBorder
      hasSeparators
    />
  ) : null;

  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <ScrollView ref={scrollViewRef} testID="settings scroll view">
      <SummaryList
        heading={I18n.t("SETTINGS.FEATURE.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={featureRows}
        disableBottomBorder
        hasSeparators
      />
      {!isWeb() && (
        <SummaryList
          heading={I18n.t("SETTINGS.ALERTS.HEADING")}
          headingTextStyle={styles.heading}
          contentRows={alertsRows}
          disableBottomBorder
          hasSeparators
        />
      )}
      {securitySection}
      <SummaryList
        heading={I18n.t("SETTINGS.ACCESS.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={accessRows}
        disableBottomBorder
        hasSeparators
      />
      <SummaryList
        heading={I18n.t("SETTINGS.USAGE.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={usageRows}
        disableBottomBorder
        hasSeparators
      />
      <SummaryList
        heading={I18n.t("SETTINGS.ACCOUNT.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={accountRows}
        disableBottomBorder
        hasSeparators
      />
      <SummaryList
        heading={I18n.t("SETTINGS.HELP.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={helpRows}
        disableBottomBorder
        hasSeparators
      />
      <SummaryList
        heading={I18n.t("SETTINGS.GENERAL.HEADING")}
        headingTextStyle={styles.heading}
        contentRows={generalRows}
        disableBottomBorder
        hasSeparators
      />
      <SettingsAlphaFeatures />
      <DropDownRow
        title={I18n.t("SETTINGS.ADVANCED.HEADING")}
        onChange={() => setTimeout(() => scrollViewRef?.current?.scrollToEnd({ animated: true }))}
        testID="SETTINGS.ADVANCED_SETTINGS"
      >
        <SummaryList contentRows={advancedSettingsRows} disableBottomBorder hasSeparators />
      </DropDownRow>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: normalizedFontSize(19),
    color: MkiColors.primaryButton,
  },
  horizontal: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  openCaseTag: {
    marginLeft: SPACING.small,
    marginVertical: -SPACING.small,
    paddingHorizontal: SPACING.small,
    paddingVertical: SPACING.tiny,
  },
});

export default SettingsScreen;
