import { Theme } from "@meraki/magnetic/themes";

const SIZE_MAP = {
  none: "None",
  "2xs": "2xs",
  xs: "Xs",
  sm: "Sm",
  md: "Md",
  lg: "Lg",
  xl: "Xl",
  "2xl": "2xl",
} as const;

export type Size = keyof typeof SIZE_MAP;

export interface SpacingStyle {
  gap?: Size;
  margin?: Size;
  marginHorizontal?: Size;
  marginVertical?: Size;
  marginBottom?: Size;
  marginLeft?: Size;
  marginRight?: Size;
  marginTop?: Size;
  padding?: Size;
  paddingHorizontal?: Size;
  paddingVertical?: Size;
  paddingBottom?: Size;
  paddingLeft?: Size;
  paddingRight?: Size;
  paddingTop?: Size;
}

function mapSize(size: Size | undefined, theme: Theme) {
  if (typeof size === "undefined") return undefined;

  return theme[`Size${SIZE_MAP[size]}`];
}

export function mapSpacingPropsToStyles(
  {
    gap,
    margin,
    marginHorizontal,
    marginVertical,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    ...rest
  }: SpacingStyle,
  theme: Theme,
) {
  const style = {
    gap: mapSize(gap, theme),
    margin: mapSize(margin, theme),
    marginHorizontal: mapSize(marginHorizontal, theme),
    marginVertical: mapSize(marginVertical, theme),
    marginBottom: mapSize(marginBottom, theme),
    marginLeft: mapSize(marginLeft, theme),
    marginRight: mapSize(marginRight, theme),
    marginTop: mapSize(marginTop, theme),
    padding: mapSize(padding, theme),
    paddingHorizontal: mapSize(paddingHorizontal, theme),
    paddingVertical: mapSize(paddingVertical, theme),
    paddingBottom: mapSize(paddingBottom, theme),
    paddingLeft: mapSize(paddingLeft, theme),
    paddingRight: mapSize(paddingRight, theme),
    paddingTop: mapSize(paddingTop, theme),
  };

  (Object.keys(style) as Array<keyof typeof style>).forEach(
    (key) => style[key] === undefined && delete style[key],
  );

  return { style, ...rest };
}
