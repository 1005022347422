import { I18n } from "@meraki/core/i18n";
import { StyleSheet } from "react-native";
import { compose } from "redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import PortListRow, { PortListRowProps } from "~/go/rows/ports/PortListRow";
import withLLDPData from "~/hocs/LLDPData";
import { clientName } from "~/lib/ClientUtils";
import { deviceName } from "~/lib/DeviceUtils";
import { capitalizeFirstLetter } from "~/lib/formatHelper";
import { switchportIsConnected } from "~/lib/SwitchPortStatusUtils";
import { portTitle } from "~/lib/SwitchPortUtils";
import MerakiIcon from "~/shared/components/icons";
import SwitchPortIcon from "~/shared/components/portDiagrams/portIcons/SwitchPortIcon";
import { ExtendedClient } from "~/shared/types/Client";
import Device_DeprecatedType from "~/shared/types/Device";
import { SwitchPort } from "~/shared/types/Models";

export interface SwitchPortListRowProps extends PortListRowProps {
  serialNumber: string;
  switchPort: SwitchPort;
  portNumber: number;
  lldpMac?: string;

  lldpClient?: ExtendedClient;
  lldpDevice?: Device_DeprecatedType;
  hasTags?: boolean;
}

export class SwitchPortListRowComponent extends PortListRow<SwitchPortListRowProps> {
  title = () => {
    const {
      switchPort: { is_uplink: isUplink, name: name },
      portNumber,
    } = this.props;
    return name ?? portTitle(isUplink, portNumber);
  };

  subtitle = () => {
    const { switchPort, lldpMac, lldpClient, lldpDevice } = this.props;
    const { connectedClients, enabled, is_uplink: isUplink } = switchPort;

    if (!enabled) {
      return I18n.t("DISABLED");
    }
    if (isUplink) {
      return I18n.t("ENABLED");
    }
    if (!switchportIsConnected(switchPort)) {
      return I18n.t("PORTS.NO_CONNECTED_CLIENTS", { client_word: I18n.t("DEVICES_WORD") });
    }

    if (lldpDevice) {
      return deviceName(lldpDevice) || lldpMac || undefined;
    }
    if (lldpClient) {
      return clientName(lldpClient) || lldpMac || undefined;
    }

    if (connectedClients) {
      const clients = Object.values(connectedClients);
      if (clients.length > 1) {
        return `${clients.length} ${capitalizeFirstLetter(I18n.t("PORTS.DEVICES"))}`;
      }
      if (clients.length === 1) {
        const client = clients[0];
        return clientName(client) ?? undefined;
      }
    }
    return undefined;
  };

  accessories = () => {
    const { hasTags } = this.props;

    return (
      <>
        <MerakiIcon
          key={"chevron-right-accessory"}
          name="chevron-right"
          size="s"
          color={MkiColors.secondaryButton}
        />
        {hasTags && <MerakiIcon name="tags" size="s" />}
      </>
    );
  };

  portIcon = () => {
    const { switchPort, serialNumber } = this.props;

    return (
      <SwitchPortIcon
        switchPort={switchPort}
        screenStyles={styles.iconStyle}
        serialNumber={serialNumber}
      />
    );
  };
}

const styles = StyleSheet.create({
  iconStyle: {
    marginRight: SPACING.default,
  },
});

export default compose<any>(withLLDPData)(SwitchPortListRowComponent);
