import * as errorMonitor from "@meraki/core/errors";
import { I18n } from "@meraki/core/i18n";
import { PureComponent } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { ForwardedNativeStackScreenProps } from "react-navigation-props-mapper";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import EditableSsidNameHeader from "~/go/components/EditableSsidNameHeader";
import { NetworkScreensPropMap } from "~/go/navigation/Types";
import { Features } from "~/go/types/ContextHelpTypes";
import { IP_ASSIGNMENT, validateName, WPA_ENCRYPTION_MODE, WPA3_ALLOWED } from "~/lib/SSIDUtils";
import { ApiResponseAction } from "~/middleware/api";
import {
  currentNetworkState,
  hasNATRouterSelector,
  onlineDevicesSelector,
  slimSsidsByIdSelector,
  slimSsidsSelector,
} from "~/selectors";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import SummaryList from "~/shared/components/SummaryList";
import DropDownRow from "~/shared/rows/DropDownRow";
import SwitchRow from "~/shared/rows/SwitchRow";
import Device_DeprecatedType from "~/shared/types/Device";
import { SSID } from "~/shared/types/Models";
import { RootState } from "~/shared/types/Redux";
import { BasicActions, basicMapDispatchToProps } from "~/store";

type ReduxProps = {
  networkId: string;
  ssid: SSID;
  ssids: SSID[];
  onlineDevices: Device_DeprecatedType[];
  hasNATRouter: boolean;
};

type Props = ForwardedNativeStackScreenProps<NetworkScreensPropMap, "SSIDConfigure"> &
  ReduxProps &
  BasicActions;

type SSIDConfigureState = {
  reqPending: boolean;
  active: boolean;
  visible: boolean;
  isGuestNetwork: boolean;
};

export class SSIDConfigure extends PureComponent<Props, SSIDConfigureState> {
  private scrollView: ScrollView | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      reqPending: false,
      active: props.ssid.enabled,
      // @ts-expect-error TS(2339) FIXME: Property 'visible' does not exist on type 'SSID'.
      visible: props.ssid.visible,
      isGuestNetwork: !!props.ssid.isGuestNetwork,
    };
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { ssid } = this.props;
    const { reqPending } = this.state;
    if (ssid !== nextProps.ssid && !reqPending) {
      this.setState({
        visible: !!nextProps.ssid?.visible,
        active: nextProps.ssid.enabled,
        isGuestNetwork: !!nextProps.ssid?.isGuestNetwork,
      });
    }
  }

  getData() {
    const { actions, networkId, onlineDevices } = this.props;

    const reqs: Promise<
      ApiResponseAction<unknown> | [ApiResponseAction<unknown>, void | ApiResponseAction<unknown>[]]
    >[] = [actions.getSsids(networkId)];

    if (!onlineDevices || onlineDevices.length === 0) {
      reqs.push(actions.loadNodesAndStatuses(networkId));
    }

    this.handleRequests(reqs);
  }

  handleRequests(reqs: unknown[]) {
    const reqDone = () => this.setState({ reqPending: false });
    this.setState({ reqPending: true });
    // TODO: proper error handling
    return Promise.all(reqs).then(reqDone, reqDone);
  }

  saveName(name: string) {
    const { actions, ssids, ssidNumber, ssid, networkId } = this.props;
    const otherSSIDs = ssids.filter((configuredSSID) => configuredSSID.number !== ssidNumber);
    const error = validateName(name, otherSSIDs);
    if (error) {
      return Promise.reject(error);
    }
    const { number } = ssid;
    return actions
      .setSsid(networkId, { number, name })
      .catch((err: unknown) => Promise.reject(err || I18n.t("SERVER_ERROR_TEXT")));
  }

  saveSSID(newVals: { enabled?: boolean; isGuestNetwork?: boolean; visible?: boolean }) {
    const { actions, networkId, ssid } = this.props;
    const { number } = ssid;

    return this.handleRequests([actions.setSsid(networkId, { number, ...newVals })]);
  }

  presentConfigureModal = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("SSIDPassword", {
      ssidNumber: ssid.number,
    });
  };

  pushSplashSelectScreen = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("SplashSelect", {
      ssidNumber: ssid.number,
    });
  };

  presentSchedulesModal = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("SSIDSchedules", {
      ssidNumber: ssid.number,
    });
  };

  presentAPAvailabilityModal = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("AccessPointAvailability", {
      ssidNumber: ssid.number,
    });
  };

  pushBlockContentScreen() {
    const { navigation, ssid } = this.props;
    navigation.navigate("BlockContent", {
      ssidNumber: ssid.number,
    });
  }

  pushSetUsageLimitScreen() {
    const { navigation, ssid } = this.props;
    navigation.navigate("SetUsage", {
      ssidNumber: ssid.number,
    });
  }

  showRadioSettingsScreen = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("SSIDRadioSettings", {
      ssidNumber: ssid.number,
    });
  };

  showVlanTaggingScreen = () => {
    const { navigation, ssid } = this.props;
    navigation.navigate("VlanTagging", {
      ssidNumber: ssid.number,
    });
  };

  renderAccessControl() {
    const rows = [
      {
        label: I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.PASSWORD.SUBTITLE"),
        onPress: () => this.presentConfigureModal(),
        testID: "ACCESS_CONTROL.PASSWORD",
      },
      {
        label: I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.SPLASH.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.SPLASH.SUBTITLE"),
        onPress: () => this.pushSplashSelectScreen(),
        testID: "ACCESS_CONTROL.SPLASH",
      },
    ];
    return (
      <SummaryList
        heading={I18n.t("SSID_CONFIGURATION.ACCESS_CONTROL.TITLE")}
        headingTextStyle={styles.sectionHeader}
        contentRows={rows}
        testID={"SSID_CONFIGURATION.ACCESS_CONTROL"}
        disableBottomBorder
        hasSeparators
      />
    );
  }

  renderBroadcasting() {
    const rows = [
      {
        label: I18n.t("SSID_CONFIGURATION.BROADCASTING.SCHEDULES.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.BROADCASTING.SCHEDULES.SUBTITLE"),
        onPress: () => this.presentSchedulesModal(),
        testID: "BROADCASTING.SCHEDULES",
      },
      {
        label: I18n.t("SSID_CONFIGURATION.BROADCASTING.AP_AVAILABILITY.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.BROADCASTING.AP_AVAILABILITY.SUBTITLE"),
        onPress: () => this.presentAPAvailabilityModal(),
        testID: "BROADCASTING.AP_AVAILABILITY",
      },
    ];
    return (
      <SummaryList
        heading={I18n.t("SSID_CONFIGURATION.BROADCASTING.TITLE")}
        headingTextStyle={styles.sectionHeader}
        contentRows={rows}
        testID={"SSID_CONFIGURATION.NETWORK_AVAILABILITY"}
        disableBottomBorder
        hasSeparators
      />
    );
  }

  renderStatusToggles() {
    const { ssid } = this.props;
    const { active, reqPending, isGuestNetwork, visible } = this.state;

    const activeToggle = (enabled: boolean) => {
      this.setState({ active: enabled });
      this.saveSSID({ enabled });
    };

    const discoverableToggle = (visible: boolean) => {
      this.setState({ visible });
      this.saveSSID({ visible });
    };

    const guestNetworkToggle = (enabled: boolean) => {
      this.setState({ isGuestNetwork: enabled });
      const guestSettings: { ipAssignmentMode?: string; c2cEnabled?: boolean } = {};
      if (enabled) {
        guestSettings.ipAssignmentMode = IP_ASSIGNMENT.NAT;
        if (ssid.c2cEnabled) {
          guestSettings.c2cEnabled = false;
        }
      }
      // TODO: Do we want to update the ssid to Bridge mode or NAT w/ c2c if
      // it is not enabled?
      this.saveSSID({ isGuestNetwork: enabled, ...guestSettings });
    };

    // don't show the discoverable switch until we get that data from the config
    const rows = [
      {
        value: !!active,
        onValueChange: activeToggle,
        disabled: reqPending,
        children: I18n.t("SSID_CONFIGURATION.STATUS.ENABLED.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.STATUS.ENABLED.SUBTITLE"),
        testID: "SSID.ACTIVE",
      },
      {
        value: !!isGuestNetwork,
        onValueChange: guestNetworkToggle,
        disabled: reqPending,
        children: I18n.t("SSID_CONFIGURATION.STATUS.GUEST_NETWORK.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.STATUS.GUEST_NETWORK.SUBTITLE"),
        context: Features.guestNetworks,
        testID: "SSID.GUEST",
      },
      {
        value: !!visible,
        onValueChange: discoverableToggle,
        disabled: reqPending,
        children: I18n.t("SSID_CONFIGURATION.STATUS.DISCOVERABLE.TITLE"),
        subtitle: I18n.t("SSID_CONFIGURATION.STATUS.DISCOVERABLE.SUBTITLE"),
        context: Features.hiddenNetworks,
        testID: "SSID.DISCOVERABLE",
      },
    ];

    return (
      <SummaryList
        heading={I18n.t("SSID_CONFIGURATION.STATUS.TITLE")}
        headingTextStyle={styles.sectionHeader}
        contentRows={rows}
        customRenderRow={SwitchRow}
        testID={"SSID_CONFIGURATION.STATUS"}
        disableBottomBorder
        hasSeparators
      />
    );
  }

  renderUsageSection() {
    const rows = [
      {
        label: I18n.t("SETTINGS.USAGE.BLOCKING.TITLE"),
        subtitle: I18n.t("SETTINGS.USAGE.BLOCKING.SUBTITLE"),
        testID: "SSID_CONFIGURATION.WEB_BLOCKING",
        onPress: () => this.pushBlockContentScreen(),
      },
      {
        label: I18n.t("SETTINGS.USAGE.LIMITS.TITLE"),
        subtitle: I18n.t("SETTINGS.USAGE.LIMITS.SUBTITLE"),
        testID: "SSID_CONFIGURATION.USAGE_LIMITS",
        onPress: () => this.pushSetUsageLimitScreen(),
      },
    ];
    return (
      <SummaryList
        heading={I18n.t("SETTINGS.USAGE.HEADING")}
        headingTextStyle={styles.sectionHeader}
        contentRows={rows}
        testID={"SSID_CONFIGURATION.USAGE_AND_SPEED"}
        disableBottomBorder
        hasSeparators
      />
    );
  }

  renderAdvancedSettings() {
    const { navigation, hasNATRouter, ssid } = this.props;
    const hasClientToClientConnectivity = ssid.c2cEnabled;
    // @ts-expect-error TS(7030) FIXME: Not all code paths return a value.
    const wpaEncryptionMode = (wpaMode) => {
      switch (wpaMode) {
        case WPA_ENCRYPTION_MODE.WPA2:
          return I18n.t("WPA_SETTINGS.WPA2.TITLE");
        case WPA_ENCRYPTION_MODE.WPA2_3:
          return I18n.t("WPA_SETTINGS.WPA2_3.TITLE");
        case WPA_ENCRYPTION_MODE.WPA3:
          return I18n.t("WPA_SETTINGS.WPA3.TITLE");
      }
    };

    const advancedSettingsRows = [
      {
        label: I18n.t("SSID_CONFIGURATION.ADVANCED_SETTINGS.NAT_BRIDGE.TITLE"),
        subtitle: `${ssid.ipAssignmentMode}`,
        testID: "SSID_CONFIGURATION.NAT_BRIDGE",
        onPress: () =>
          navigation.navigate("NATBridge", {
            ssidNumber: ssid.number,
            ssidName: ssid.name,
          }),
      },
      {
        label: I18n.t("SSID_CONFIGURATION.ADVANCED_SETTINGS.RADIO_SETTINGS.TITLE"),
        subtitle: `${ssid.bandSelection}`,
        testID: "SSID_CONFIGURATION.BAND_SELECTION",
        onPress: this.showRadioSettingsScreen,
      },
    ];

    if (
      ssid.authMode === WPA3_ALLOWED.AUTH_MODE &&
      ssid.encryptionMode === WPA3_ALLOWED.ENCRYPTION_MODE
    ) {
      advancedSettingsRows.push({
        label: I18n.t("SSID_CONFIGURATION.ADVANCED_SETTINGS.WPA_SETTINGS.TITLE"),
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        subtitle: wpaEncryptionMode(ssid.wpaEncryptionMode),
        testID: "SSID_CONFIGURATION.WPA_SETTINGS",
        onPress: () =>
          navigation.navigate("WPASettings", {
            ssid,
            ssidNumber: ssid.number,
          }),
      });
    }

    if (!hasNATRouter && ssid.ipAssignmentMode === IP_ASSIGNMENT.NAT && !ssid.isGuestNetwork) {
      advancedSettingsRows.push({
        label: I18n.t("CLIENT_ISOLATION.TITLE"),
        subtitle: hasClientToClientConnectivity ? I18n.t("ON") : I18n.t("OFF"),
        testID: "SSID_CONFIGURATION.CLIENT_ISOLATION",
        onPress: () =>
          navigation.navigate("ClientIsolation", {
            ssidNumber: ssid.number,
          }),
      });
    }

    if (ssid.ipAssignmentMode === IP_ASSIGNMENT.BRIDGE && !ssid.isGuestNetwork) {
      advancedSettingsRows.push({
        label: I18n.t("VLAN_TAGGING.TITLE"),
        subtitle: ssid.useVlanTagging ? I18n.t("ON") : I18n.t("OFF"),
        testID: "SSID_CONFIGURATION.VLAN_TAGGING",
        onPress: this.showVlanTaggingScreen,
      });
    }

    return (
      <DropDownRow
        title={I18n.t("SETTINGS.ADVANCED.HEADING")}
        testID={"SSID_CONFIGURATION.ADVANCED_SETTINGS"}
        onChange={() => setTimeout(() => this.scrollView?.scrollToEnd({ animated: true }))}
      >
        <SummaryList contentRows={advancedSettingsRows} disableBottomBorder hasSeparators />
      </DropDownRow>
    );
  }

  render() {
    const { ssid } = this.props;
    const { reqPending } = this.state;

    return (
      <ScrollView
        ref={(item) => {
          this.scrollView = item;
        }}
        keyboardShouldPersistTaps="handled"
        testID="SSID_CONFIGURATION_SCREEN_SCROLL_VIEW"
      >
        <EditableSsidNameHeader
          title={ssid.name}
          entity="network"
          enabled={ssid.enabled}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          save={(name) => this.saveName(name)}
        />
        {this.renderAccessControl()}
        {this.renderBroadcasting()}
        {this.renderUsageSection()}
        {this.renderStatusToggles()}
        {this.renderAdvancedSettings()}
        <LoadingSpinner visible={reqPending} />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  sectionHeader: {
    color: MkiColors.primaryButton,
  },
});

function mapStateToProps(
  state: RootState,
  props: NetworkScreensPropMap["SSIDConfigure"],
): ReduxProps {
  return {
    networkId: errorMonitor.notifyNonOptional(
      "param 'networkId' undefined for SSIDConfigureScreen",
      currentNetworkState(state),
    ),
    ssid: slimSsidsByIdSelector(state)[props.ssidNumber] || {},
    ssids: slimSsidsSelector(state),
    onlineDevices: onlineDevicesSelector(state),
    hasNATRouter: hasNATRouterSelector(state),
  };
}

export default connect(mapStateToProps, basicMapDispatchToProps)(SSIDConfigure);
