import { formatDate } from "@meraki/core/date";
import { PureComponent } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { connect } from "react-redux";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import I18n from "~/i18n/i18n";
import { isDateRangeEmpty } from "~/lib/LocationAnalyticsUtils";
import { getNetworkTimezone } from "~/selectors";
import MkiText from "~/shared/components/MkiText";
import { DateRange } from "~/shared/types/TimeTypes";

import { RootState } from "../types/Redux";

type ReduxProps = {
  networkTimeZoneOffset?: string;
};

export interface MkiDateRangeTextProps extends ReduxProps {
  dateRange?: DateRange;
  comparedDateRange?: DateRange;
  onPress?: () => void;
  testID?: string;
}

const formatDateForComparison = (date: Date) => {
  return formatDate(date, { dateFormat: "longDate" });
};

export class MkiDateRangeText extends PureComponent<MkiDateRangeTextProps> {
  renderCustomRangeText = (dateRange: DateRange | undefined) => {
    if (!dateRange || isDateRangeEmpty(dateRange)) {
      return <MkiText screenStyles={styles.purpleText}>{I18n.t("SELECT_DATE_RANGE")}</MkiText>;
    }
    const { startDate, endDate } = dateRange;
    const startDateText = formatDateForComparison(startDate);
    const endDateText = formatDateForComparison(endDate);
    return (
      <MkiText screenStyles={styles.purpleText}>{`${startDateText} - ${endDateText}`}</MkiText>
    );
  };

  renderComparisonRangeText = (
    dateRange: DateRange | undefined,
    comparedDateRange: DateRange | undefined,
  ) => {
    if (!comparedDateRange || isDateRangeEmpty(dateRange) || isDateRangeEmpty(comparedDateRange)) {
      return null;
    }
    const { startDate, endDate } = comparedDateRange;
    const versus = I18n.t("VERSUS_ABBR");
    const comparedStartDateText = formatDateForComparison(startDate);
    const comparedEndDateText = formatDateForComparison(endDate);
    return (
      <MkiText textStyle="smallSecondary">{`${versus} ${comparedStartDateText} - ${comparedEndDateText}`}</MkiText>
    );
  };

  render() {
    const { dateRange, comparedDateRange, onPress, testID } = this.props;
    return (
      <TouchableOpacity style={styles.container} onPress={onPress} testID={testID}>
        {this.renderCustomRangeText(dateRange)}
        {this.renderComparisonRangeText(dateRange, comparedDateRange)}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: SPACING.default,
    paddingHorizontal: SPACING.default,
    borderBottomColor: MkiColors.borderColor,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: SPACING.default,
  },
  purpleText: {
    color: MkiColors.goPurple,
  },
});

function mapStateToProps(state: RootState): ReduxProps {
  return {
    networkTimeZoneOffset: getNetworkTimezone(state),
  };
}

export default connect(mapStateToProps)(MkiDateRangeText);
