import { useState } from "react";

import { InternalGroup, InternalGroupProps } from "./InternalGroup";

export type GroupProps = Omit<InternalGroupProps, "calculateChildProps"> & {
  initialOpenIndexes?: number[];
};

export function Group({ initialOpenIndexes, ...rest }: GroupProps) {
  const [openIndex, setOpenIndex] = useState<Record<string, boolean>>(
    initialOpenIndexes?.reduce((acc, idx) => {
      return { ...acc, [idx]: true };
    }, {}) ?? {},
  );

  return (
    <InternalGroup
      {...rest}
      calculateChildProps={(idx) => ({
        open: openIndex[idx],
        onToggle: () => {
          setOpenIndex({
            ...openIndex,
            [idx]: !openIndex[idx],
          });
        },
      })}
    />
  );
}
