import { formatDate } from "@meraki/core/date";
import { analyticsLog } from "@meraki/core/firebase";
import { List, SearchBar, Text } from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useState } from "react";
import { getTimeZone } from "react-native-localize";

export function DebugAnalytics() {
  const [search, setSearch] = useState("");

  const filteredAnalyticsLog = analyticsLog.filter((item) => {
    if (search === "") {
      return true;
    }

    return (
      item.eventName.toLowerCase().includes(search.toLowerCase()) ||
      JSON.stringify(item.params).includes(search.toLowerCase())
    );
  });

  return (
    <Screen.View>
      <Box padding="sm" paddingBottom="none">
        <SearchBar placeholder="Search" value={search} onChangeText={(text) => setSearch(text)} />
      </Box>
      <List.FlashList
        paddingTop="none"
        data={filteredAnalyticsLog}
        getItemData={(item) => ({
          title: item.eventName,
          description: formatDate(item.occurred, {
            dateFormat: "longDate",
            timeFormat: "longTime",
            timeZone: getTimeZone(),
          }),
          children: <Text>{JSON.stringify(item.params, undefined, 2)}</Text>,
        })}
      />
    </Screen.View>
  );
}
