import { xMerakiUserAgentInfo } from "~/shared/constants/CustomUserAgent";

// These utils live outside of the other utils for now. The reason for this is these were created to help in the migration to the individual library
// In further cleanup efforts this file will likely change / be removed

export const IDLE_TIMEOUT_ERROR = "idle_timeout_session_expired";
export const LOGIN_ERROR_PLEASE_TRY_AGAIN = "Login error, please try again!";

export function buildAppHeaders() {
  return {
    "X-Dashboard-Mobile-API-Level": "8",
    ...xMerakiUserAgentInfo,
  };
}
