import { Status as StatusIcon, StatusProps as StatusIconProps } from "@meraki/magnetic/icons";

export type GeneralStatusProps = {
  testID?: string;
  status?: StatusIconProps["status"];
  size?: StatusIconProps["size"];
};

export function GeneralStatus({ testID, status, size }: GeneralStatusProps) {
  if (!status) return null;

  return <StatusIcon status={status} size={size} testID={testID} />;
}
