import { I18n } from "@meraki/core/i18n";
import { InsightsScreen } from "@meraki/go/insight";
import { InsightsStackProps } from "@meraki/go/navigation-type";
import { ApplicationUsageListScreen } from "@meraki/shared/applications";
import { useDefaultScreenOptions, useTabScreenOptions } from "@meraki/shared/navigation";
import { registerTags } from "@meraki/shared/search";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { AlertInsightGroup } from "../screenGroups/AlertInsightGroup";

const Stack = createNativeStackNavigator<InsightsStackProps>();

const InsightsStack = () => {
  const defaultScreenOptions = useDefaultScreenOptions();
  const tabScreenOptions = useTabScreenOptions();

  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen name="Insights" component={InsightsScreen} options={tabScreenOptions} />
      <Stack.Screen
        name="ApplicationUsageList"
        component={ApplicationUsageListScreen}
        options={{ headerTitle: I18n.t("CLIENT_USAGE.HEADING") }}
      />
      {AlertInsightGroup(Stack)}
    </Stack.Navigator>
  );
};

// search tag registrations
registerTags(
  {
    title: "CLIENT_USAGE.HEADING",
    tab: "InsightsTab",
    scope: "network",
    screen: "ApplicationUsageList",
  },
  ["network", "applications"],
);

export default InsightsStack;
