import {
  PortForwardingRule,
  usePortForwardingRules,
  useUpdatePortForwardingRules,
} from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { useNavigation } from "@react-navigation/native";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useLayoutEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import MkiColors from "~/constants/MkiColors";
import { SPACING } from "~/constants/MkiConstants";
import DeleteButton from "~/go/components/DeleteButton";
import SectionListHeader from "~/go/components/SectionListHeader";
import I18n from "~/i18n/i18n";
import { showAlert } from "~/lib/AlertUtils";
import FullScreenContainerView from "~/shared/components/FullScreenContainerView";
import LoadingSpinner from "~/shared/components/LoadingSpinner";
import MkiTable from "~/shared/components/MkiTable";
import MkiText from "~/shared/components/MkiText";
import NoDataFooter from "~/shared/components/NoDataFooter";
import { DoneButton, EditButton } from "~/shared/navigation/Buttons";
import ListRow from "~/shared/rows/ListRow";

interface PortForwardingRuleRowProps {
  rule: PortForwardingRule;
  deleteIcon: React.ReactNode;
  onPress: () => void;
}

const PortForwardingRuleRow = memo<PortForwardingRuleRowProps>(
  function PortForwardingRuleRow(props) {
    const { onPress, rule, deleteIcon } = props;

    const { localPort, name, publicPort, protocol } = rule;

    const phraseKey =
      localPort.includes("-") && publicPort.includes("-")
        ? "PORT_FORWARDING.RULE_DESCRIPTION_PORT_RANGE"
        : "PORT_FORWARDING.RULE_DESCRIPTION_SINGLE_PORT";

    const description = I18n.t(phraseKey, {
      localPort,
      publicPort,
      protocol: protocol.toUpperCase(),
    });

    return (
      <ListRow
        subtitle1={description}
        icon={deleteIcon}
        rowStyles={styles.rowStyles}
        onPress={onPress}
        leftStyle={styles.leftStyles}
      >
        {name}
      </ListRow>
    );
  },
);

function PortForwardingScreen() {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const networkId = useCurrentNetworkId();
  const { data: portForwardingRules, isLoading } = usePortForwardingRules({ networkId });
  const updatePortForwardingRules = useUpdatePortForwardingRules();

  const [isEditMode, setIsEditMode] = useState(false);

  const renderHeader = () => {
    return (
      <View style={styles.rowStyles}>
        <MkiText screenStyles={styles.headerStyle}>{I18n.t("PORT_FORWARDING.SUBTITLE")}</MkiText>
      </View>
    );
  };

  const renderSectionFooter = ({ section }: any) => {
    return <NoDataFooter data={section.data} noDataString={I18n.t("PORT_FORWARDING.NO_RULES")} />;
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (!isEditMode) {
          return <EditButton onPress={() => setIsEditMode(true)} />;
        } else {
          return <DoneButton onPress={() => setIsEditMode(false)} />;
        }
      },
    });
  });

  const showRuleDetails = (index: number | undefined) => {
    navigation.navigate("PortForwardingRule", {
      ruleIndex: index,
    });
  };

  const refreshQueries = () => {
    queryClient.invalidateQueries({ queryKey: usePortForwardingRules.queryKeyRoot });
  };

  const addRule = () => {
    showRuleDetails(undefined);
  };

  const editRule = (index: number) => {
    showRuleDetails(index);
  };

  const renderSectionHeader = () => {
    return (
      <SectionListHeader
        testID={"ADD_RULE"}
        heading={I18n.t("PORT_FORWARDING.SECTION_HEADER")}
        onPress={addRule}
        withHorizontalMargin
      />
    );
  };

  const deletePortForwardingRule = (deletedIndex: number) => {
    const updatedRules = portForwardingRules?.rules.filter(
      (_, index: number) => index !== deletedIndex,
    );

    updatePortForwardingRules.mutate(
      { networkId: networkId, rules: updatedRules },
      {
        onError: (error) => {
          showAlert(I18n.t("ERROR"), error);
        },
        onSuccess: () =>
          queryClient.invalidateQueries({ queryKey: usePortForwardingRules.queryKeyRoot }),
      },
    );
  };

  const renderRow = (rule: PortForwardingRule, index: number) => {
    return (
      <PortForwardingRuleRow
        rule={rule}
        onPress={() => editRule(index)}
        deleteIcon={
          <DeleteButton
            show={isEditMode}
            onPress={() => deletePortForwardingRule(index)}
            testID={`DELETE_RULE - ${index}`}
          />
        }
      />
    );
  };

  return (
    <FullScreenContainerView>
      <MkiTable<PortForwardingRule>
        keyboardShouldPersistTaps="handled"
        refreshing={isLoading}
        onRefresh={refreshQueries}
        data={portForwardingRules ?? []}
        ListHeaderComponent={renderHeader}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        renderRow={renderRow}
        keyExtractor={(item: PortForwardingRule, index: number) =>
          `${item.protocol},${item.publicPort},${index}`
        }
      />
      <LoadingSpinner visible={isLoading} />
    </FullScreenContainerView>
  );
}

const styles = StyleSheet.create({
  rowStyles: {
    paddingHorizontal: SPACING.default,
  },
  headerStyle: {
    color: MkiColors.secondaryTextColor,
  },
  leftStyles: {
    width: "100%",
  },
});

export default PortForwardingScreen;
