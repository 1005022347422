import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { KEYBOARD_TYPE } from "~/constants/MkiConstants";
import MkiTextInput from "~/shared/components/MkiTextInput";
import ListRow from "~/shared/rows/ListRow";

type Props = {
  children: string;
  value?: string;
  multiline?: boolean;
  placeholder?: string;
  autoCapitalize?: "none" | "sentences" | "words" | "characters";
  autoCorrect?: boolean;
  keyboardType?: (typeof KEYBOARD_TYPE)[keyof typeof KEYBOARD_TYPE];
  onChangeText: (text: string) => void;
  secureTextEntry?: boolean;
  onFocus?: () => void;
  subtitle?: string;
  leftStyle?: StyleProp<ViewStyle>;
  rightStyle?: StyleProp<ViewStyle>;
  testID?: string;
};

const InputRow = (props: Props) => {
  const {
    placeholder,
    value,
    autoCapitalize,
    keyboardType,
    multiline,
    autoCorrect,
    onChangeText,
    secureTextEntry,
    onFocus,
    leftStyle,
    rightStyle,
    subtitle,
    testID,
    children,
  } = props;
  return (
    <ListRow
      accessory={
        <MkiTextInput
          screenStyles={styles.textInput}
          placeholder={placeholder}
          value={value}
          multiline={multiline}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          keyboardType={keyboardType}
          onChangeText={onChangeText}
          secureTextEntry={secureTextEntry}
          onFocus={onFocus}
        />
      }
      rowStyles={styles.rowStyles}
      leftStyle={[styles.leftStyle, leftStyle]}
      rightStyle={[styles.rightStyle, rightStyle]}
      subtitle1={subtitle}
      testID={testID}
    >
      {children}
    </ListRow>
  );
};

const styles = StyleSheet.create({
  rowStyles: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  textInput: {
    width: "100%",
  },
  leftStyle: {
    width: "30%",
  },
  rightStyle: {
    width: "70%",
  },
});

InputRow.defaultProps = {
  value: undefined,
  multiline: false,
  placeholder: "",
  autoCapitalize: "none",
  autoCorrect: false,
  keyboardType: KEYBOARD_TYPE.default,
  secureTextEntry: false,
  onFocus: () => {},
  subtitle: undefined,
  leftStyle: {},
  rightStyle: {},
};

export default InputRow;
