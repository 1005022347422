import { I18n } from "@meraki/core/i18n";
import { ConfigureStackProps, SsidGroupProps } from "@meraki/go/navigation-type";
import {
  BottomSheetMethods,
  Button,
  Heading,
  TabView,
  TabViewMethods,
} from "@meraki/magnetic/components";
import { Box, Screen } from "@meraki/magnetic/layout";
import { useSsids } from "@meraki/shared/api";
import { useCurrentNetworkId } from "@meraki/shared/redux";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef } from "react";

import { EditBottomSheet } from "../components/EditBottomSheet";
import { PasswordBottomSheet } from "../components/PasswordBottomSheet";
import { ShareBottomSheet } from "../components/ShareBottomSheet";
import { SSIDContext } from "./SSIDContext";
import { PreferencesTab } from "./tabs/PreferencesTab";
import { SettingsTab } from "./tabs/SettingsTab";

type Props = RouteProp<SsidGroupProps, "Ssid">;

export const SSIDScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<ConfigureStackProps>>();
  const route = useRoute<Props>();
  const { params: props } = route;
  const editBottomSheetRef = useRef<BottomSheetMethods>(null);
  const shareBottomSheetRef = useRef<BottomSheetMethods>(null);
  const passwordBottomSheetRef = useRef<BottomSheetMethods>(null);

  const networkId = useCurrentNetworkId();
  const { data: ssid, isLoading } = useSsids(
    { networkId },
    { select: (data) => data.filter(({ number }) => number === props.ssidNumber)[0] },
  );

  const tabViewRef = useRef<TabViewMethods>(null);

  useEffect(
    () =>
      navigation.setOptions({
        headerRight: () => (
          <>
            <Button.Icon icon="Pencil" onPress={() => editBottomSheetRef.current?.present()} />
            <Button.Icon
              icon="ShareNetwork"
              onPress={() => shareBottomSheetRef.current?.present()}
            />
            {ssid?.psk && (
              <Button.Icon icon="Lock" onPress={() => passwordBottomSheetRef.current?.present()} />
            )}
          </>
        ),
      }),
    [navigation, ssid?.psk],
  );
  return (
    <Screen.View>
      <SSIDContext.Provider
        value={{
          ssid,
          isLoading,
        }}
      >
        <Box paddingVertical="none" padding="sm">
          <Heading size="h1">{ssid?.name}</Heading>
        </Box>
        <TabView
          tabs={{
            preferences: {
              title: I18n.t("SSID_SCREEN.TABS.PREFERENCES.TITLE"),
              view: <PreferencesTab />,
            },
            settings: {
              title: I18n.t("SSID_SCREEN.TABS.SETTINGS.TITLE"),
              view: <SettingsTab />,
            },
          }}
          ref={tabViewRef}
        />
        <EditBottomSheet bottomSheetRef={editBottomSheetRef} />
        <ShareBottomSheet bottomSheetRef={shareBottomSheetRef} />
        <PasswordBottomSheet bottomSheetRef={passwordBottomSheetRef} />
      </SSIDContext.Provider>
    </Screen.View>
  );
};
