import { useMagneticTheme } from "@meraki/magnetic/themes";
import Svg, { Path } from "react-native-svg";

import { IconProps } from "./IconProps";

export function Informational({ width, height, testID }: IconProps) {
  const theme = useMagneticTheme();

  return (
    <Svg viewBox="0 0 19 19" width={width} height={height} testID={testID}>
      <Path
        d="M1.97913 6.72925C1.97913 4.1059 4.10577 1.97925 6.72913 1.97925H12.2708C14.8941 1.97925 17.0208 4.1059 17.0208 6.72925V12.2709C17.0208 14.8943 14.8941 17.0209 12.2708 17.0209H6.72913C4.10577 17.0209 1.97913 14.8943 1.97913 12.2709V6.72925Z"
        fill={theme.color.info.icon.base}
      />
      <Path
        d="M8.70829 12.8647L8.70829 9.50008C8.70829 9.06286 9.06273 8.70842 9.49996 8.70842C9.93718 8.70842 10.2916 9.06286 10.2916 9.50008L10.2916 12.8647C10.2916 13.3019 9.93718 13.6563 9.49996 13.6563C9.06273 13.6563 8.70829 13.3019 8.70829 12.8647Z"
        fill={theme.color.info.iconIn.base}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <Path
        d="M10.4895 6.53133C10.4895 7.07786 10.0465 7.52091 9.49996 7.52091C8.95343 7.52091 8.51038 7.07786 8.51038 6.53133C8.51038 5.9848 8.95343 5.54175 9.49996 5.54175C10.0465 5.54175 10.4895 5.9848 10.4895 6.53133Z"
        fill={theme.color.info.iconIn.base}
      />
    </Svg>
  );
}
