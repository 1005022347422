import { z } from "zod";

import { request } from "../../api/request/request";
import { ProductType, Status } from "../../schemas";
import { createQuery } from "../createQuery";
import { DeviceStatusSchema } from "./useDeviceStatuses";

export const DeviceAvailabilitySchema = DeviceStatusSchema.pick({
  name: true,
  mac: true,
  productType: true,
  serial: true,
  status: true,
  tags: true,
})
  .and(z.object({ network: z.object({ id: z.string() }) }))
  .describe("DeviceAvailailitySchema");

export type DeviceAvailability = z.infer<typeof DeviceAvailabilitySchema>;

export const DeviceAvailabilitiesSchema = z
  .array(DeviceAvailabilitySchema)
  .describe("DeviceAvailabilitiesSchema");

export type DeviceAvailabilities = z.infer<typeof DeviceAvailabilitiesSchema>;

export type DeviceAvailabilitiesQueryParams = {
  search?: string;
  perPage?: string;
  startingAfter?: string;
  endingBefore?: string;
  networkIds?: string[];
  serials?: string[];
  tags?: string[];
  tagsFilterType?: string;
  productTypes?: Exclude<ProductType, "phone">[];
  statuses?: Status[];
};

type DeviceAvailabilitiesRequest = Omit<DeviceAvailabilitiesQueryParams, "networkIds"> & {
  organizationId?: string;
  networkId?: string;
};

const buildUrl = ({ organizationId }: Pick<DeviceAvailabilitiesRequest, "organizationId">) =>
  `/api/v1/organizations/${organizationId}/devices/availabilities`;

const fetchDeviceAvailabilities = ({
  organizationId,
  networkId,
  ...queryParams
}: DeviceAvailabilitiesRequest) => {
  if (!organizationId) {
    throw new Error("Undefined organization id when fetching devices");
  }

  const networkIds = networkId ? { networkIds: [networkId] } : undefined;

  return request(DeviceAvailabilitiesSchema, "GET", buildUrl({ organizationId }), {
    queryParams: {
      ...networkIds,
      ...queryParams,
    },
  });
};

export const useDeviceAvailabilities = createQuery<
  DeviceAvailabilitiesRequest,
  DeviceAvailabilities
>({
  baseQueryKey: buildUrl({ organizationId: "{organizationId}" }),
  queryFn: fetchDeviceAvailabilities,
  requiredVariables: ["organizationId"],
});
